import React, { Ref, useCallback, useState, useRef, useEffect } from "react";
import { TextFieldProps } from "@material-ui/core/TextField";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { SelectProps } from "@material-ui/core/Select";
import { TextField } from "../TextField";
import { AutoFillIcon } from "../images";
import CohereMenuItem from "./CohereMenuItem";
import { DropdownOption, useStylesMenu, useStylesMenuList, useStylesSelect, Warning } from "./shared";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import mergeRefs from "merge-refs";
import { Caption } from "../Typography";

export interface SingleSelectDropdownProps<T extends DropdownOption> {
  /** Label for the dropdown. */
  label?: React.ReactNode;
  /** If the label is external, provide its HTML id here to associate it with this input. */
  labelId?: string;
  /** Helper text for the dropdown. */
  helperText?: React.ReactNode;
  /** Array of options to display. `{ id: string, label?: ReactNode }` */
  options?: T[];
  /** 'id' of the selected option. */
  value?: string;
  /** Function: the 'id' of the selected option is provided as the first argument. */
  onChange?: (arg0: T["id"]) => any;
  /** No options custom display. */
  noOptionsText?: React.ReactNode;
  /** Function: used to display the option as the selection and in the options list (unless `renderOptionInList` is also provided).
   * By default, the option 'label' will be displayed.
   */
  renderOption?: (arg0: T) => React.ReactNode;
  /** Function: used to display the option in the options list. By default, the option 'label' will be displayed. */
  renderOptionInList?: (arg0: T) => React.ReactNode;
  /** Function: used to render the selected option in the options list. By default,  the option 'label' will be displayed.*/
  renderSelectedOptionInList?: (arg0: T) => React.ReactNode;
  /** Optionally set a fixed width for the dropdown menu options list. */
  menuWidth?: number;
  /** Optionally set a max height for the dropdown menu options list. */
  maxMenuHeight?: number;
  /** Set the height for each menu item on the list */
  menuItemHeight?: string;
  /** Set the font size for each menu item on the list */
  fontSize?: string;
  /** Set the color for each menu item on the list */
  menuItemColor?: string;
  /** remove check icon for each menu item on the list */
  noIcon?: boolean;
  /** optionally override default Select configuration */
  SelectProps?: SelectProps;
  /** pass a valid  React Ref to underlying Textfield component. */
  selectRef?: Ref<HTMLElement>;
  /** show circular progress when necessary. */
  showLoadingIcon?: boolean;
  /** a warning icon & tooltip will be shown when any id from this array is selected */
  optionIdsWithWarning?: string[];
  /** text to be shown in the dropdown list and select field warning tooltip */
  warningTextForOptions?: string;
  /** text to be displayed on the select field warning tooltip. will default to  warningTextForOptions*/
  warningTextForTextFieldSelection?: string;
  /** force warning icon and tooltip to show */
  addWarningToTextFieldSelection?: boolean;

  /** Optionally show the auto fill icon instead of the ExpandMore icon */
  showAutoFillIcon?: boolean;

  /** Optionally show width and color of dashboard sort component that filters out most recent SRs */
  isDashboardSortComponent?: boolean;
  /** used to define if the information within this control should be visible within LogRocket */
  dataPublic?: boolean;
}

// Temporary export for storybook documentation
export function SingleSelectDropdownForProps<T extends DropdownOption>(props: SingleSelectDropdownProps<T>) {}

export type AllSingleSelectDropdownProps<T extends DropdownOption> = SingleSelectDropdownProps<T> &
  Omit<TextFieldProps, "value" | "onChange" | "css">;

export default function SingleSelectDropdown<T extends DropdownOption>({
  classes,
  children,
  labelId,
  options = [],
  noOptionsText,
  onChange,
  renderOption,
  renderOptionInList,
  renderSelectedOptionInList,
  value,
  menuWidth,
  maxMenuHeight,
  menuItemHeight,
  noIcon,
  fontSize,
  menuItemColor,
  SelectProps,
  variant,
  selectRef,
  showLoadingIcon,
  optionIdsWithWarning,
  warningTextForOptions,
  warningTextForTextFieldSelection,
  addWarningToTextFieldSelection,
  showAutoFillIcon,
  isDashboardSortComponent,
  dataPublic = false,
  ...props
}: AllSingleSelectDropdownProps<T>) {
  const refWidth = useRef<HTMLInputElement>(null);
  const [width, setWidth] = useState<number>();
  const [selectedId, setSelectedId] = useState(value);
  const internalOnChange = useCallback(
    (event) => {
      if (event.target.value !== "NO_RESULTS") {
        onChange?.(event.target.value);
        setSelectedId(event.target.value);
      }
    },
    [onChange]
  );
  const dataPublicProps = dataPublic ? { "data-public": true } : {};
  const dropdownClasses = useSingleSelectStyles({ asSelectedItem: false });
  const renderSelectedOption = useCallback(
    (option: T) => {
      return (
        renderOption?.(option) || (
          <div className={dropdownClasses.selectOptionContainer}>
            <span className={dropdownClasses.selectOption}>
              {(option as any).label || option.id}
              {option.subLabel && <Caption className={dropdownClasses.subLabel}>{option.subLabel}</Caption>}
            </span>
            {optionIdsWithWarning?.includes(option.id || "") && <Warning warningText={warningTextForOptions || ""} />}
          </div>
        )
      );
    },
    [renderOption, warningTextForOptions, dropdownClasses, optionIdsWithWarning]
  );

  const renderOptionInMenuItem = useCallback(
    (option: T, selected: boolean) => {
      return ((selected && renderSelectedOptionInList) || renderOptionInList)?.(option) || renderSelectedOption(option);
    },
    [renderSelectedOption, renderOptionInList, renderSelectedOptionInList]
  );
  const selectedOptionClasses = useSingleSelectStyles({ asSelectedItem: true });
  const renderSelectedOptionFromId = useCallback(
    (selectedId) => {
      const option = options?.find(({ id }) => id === selectedId);
      return option
        ? renderOption?.(option) || (
            <div className={selectedOptionClasses.selectOptionContainer}>
              <span className={selectedOptionClasses.selectOption}>{(option as any).label || option.id}</span>
              {(optionIdsWithWarning?.includes(option.id || "") || addWarningToTextFieldSelection) && (
                <Warning warningText={warningTextForTextFieldSelection || warningTextForOptions || ""} asSelectedItem />
              )}
            </div>
          )
        : "";
    },
    [
      options,
      warningTextForTextFieldSelection,
      addWarningToTextFieldSelection,
      selectedOptionClasses,
      warningTextForOptions,
      optionIdsWithWarning,
      renderOption,
    ]
  );

  const selectClasses = useStylesSelect({ variant });
  const menuClasses = useStylesMenu({ maxMenuHeight, menuWidth: width });
  const menuListClasses = useStylesMenuList();

  const { MenuProps: otherMenuProps, ...otherSelectProps } = SelectProps || {};

  const selectProps: SelectProps = {
    classes: selectClasses,
    renderValue: renderSelectedOptionFromId,
    IconComponent: showLoadingIcon
      ? () => (
          <CircularProgress
            size={20}
            className={selectClasses.icon}
            style={{ position: "absolute", margin: "0 6px" }}
          />
        )
      : showAutoFillIcon
      ? () => <AutoFillIcon />
      : !props.disabled
      ? () => (
          <ExpandMore
            className={selectedOptionClasses.iconComponent}
            style={isDashboardSortComponent ? { color: "#212936", right: "1px" } : {}}
          />
        )
      : () => <></>,
    MenuProps: {
      classes: menuClasses,
      anchorOrigin: { vertical: "bottom", horizontal: "left" },
      getContentAnchorEl: null,
      autoFocus: false,
      PaperProps: {
        elevation: 1,
        style: {
          width: width,
          minWidth: width,
        },
      },
      MenuListProps: {
        classes: menuListClasses,
      },
      ...otherMenuProps,
    },
    onOpen: () => {
      if (!menuWidth) {
        setWidth(refWidth.current?.clientWidth);
      }
    },
    ...otherSelectProps,
  };

  if (labelId) {
    // Only set this prop if it's provided - otherwise breaks the internal label association
    selectProps.labelId = labelId;
  }

  useEffect(() => {
    if (refWidth.current && !menuWidth) {
      setWidth(refWidth.current.clientWidth);
    }
  }, [setWidth, menuWidth]);

  useEffect(() => {
    const resetWidth = () => {
      if (!menuWidth) {
        setWidth(refWidth.current?.clientWidth || 0);
      }
    };

    window.addEventListener("resize", resetWidth);

    // clean up listener on unmount
    return () => window.removeEventListener("resize", resetWidth);
  }, [menuWidth]);

  return (
    <TextField
      classes={classes}
      fullWidth
      value={value}
      {...props}
      select
      onChange={internalOnChange}
      variant={variant}
      SelectProps={selectProps}
      ref={refWidth && selectRef ? mergeRefs(refWidth, selectRef) : refWidth ? refWidth : selectRef}
      {...dataPublicProps}
    >
      {options.map((option: T) => {
        const selected = option.id === selectedId;
        return (
          <CohereMenuItem
            key={option.id}
            value={option.id}
            selected={selected}
            hasSublabel={!!option.subLabel}
            menuWidth={menuWidth}
            menuItemHeight={menuItemHeight}
            fontSize={fontSize}
            menuItemColor={menuItemColor}
            noIcon={noIcon}
            style={{ minWidth: width, width: width }}
          >
            {renderOptionInMenuItem(option, selected)}
          </CohereMenuItem>
        );
      })}
      {!options.length && (
        <CohereMenuItem key="NO_RESULTS" value="NO_RESULTS" menuWidth={menuWidth} style={{ padding: 16 }}>
          {noOptionsText ? noOptionsText : "No options available."}
        </CohereMenuItem>
      )}
    </TextField>
  );
}

interface SingleSelectStyleProps {
  asSelectedItem?: boolean;
}

export const useSingleSelectStyles = makeStyles((theme) => {
  return {
    selectOptionContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: ({ asSelectedItem }: SingleSelectStyleProps) => (asSelectedItem ? "98%" : "100%"), // to make space between truncated text and expand icon
      paddingRight: theme.spacing(1),
    },
    selectOption: ({ asSelectedItem }: SingleSelectStyleProps) =>
      asSelectedItem
        ? {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }
        : {
            display: "flex",
            flexDirection: "column",
          },
    subLabel: {
      marginTop: "4px",
      color: theme.palette.text.secondary,
    },
    iconComponent: {
      color: "#5E6572",
      top: "calc(50% - 12px)",
      position: "absolute",
      pointerEvents: "none",
      right: "16px",
      transition: "transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
  };
});
