import React, { useContext } from "react";
import { ServiceRequestCreatePayload, ServiceRequestResponse, UserResponse } from "@coherehealth/core-platform-api";
import ServiceRequestCardList from "../../DashboardPage/ServiceRequestCardList";
import { Card, colorsLight, TertiaryButton, Tooltip } from "@coherehealth/common";
import PotentialDuplicateModal from "./PotentialDuplicateModal";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { H2 } from "@coherehealth/design-system";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";
import { SimilarServiceRequest } from "@coherehealth/core-platform-api";
import { useTrackUserInteraction } from "util/userActivityTracker";
import { PotentialDuplicateContext } from "./PotentialDuplicateContextProvider";
import { ServiceRequestFormContent } from "../../../common/SharedServiceRequestFormComponents";

interface Props {
  duplicateAuthData: SimilarServiceRequest[] | null;
  loading: boolean;
  open: boolean;
  onClose?: () => void;
  serviceRequests?: ServiceRequestResponse[];
  user?: UserResponse | null;
  onClickNewSR: () => void;
  searchForPotentialDuplicates?: (srPayload: ServiceRequestCreatePayload) => Promise<boolean>;
  workflowId?: string;
  serviceRequestFormContents?: ServiceRequestFormContent[];
  getPotentialDuplicatesLoading?: boolean;
}

const useStyles = makeStyles((theme) => ({
  h2: {
    padding: "0 8px",
  },
  h2Container: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginTop: "8px",
    marginBottom: "0px",
  },
  cardStyle: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    paddingTop: theme.spacing(3),
    "&.MuiPaper-elevation24": {
      boxShadow: "0px 15px 12px rgba(0, 0, 0, 0.22), 0px 19px 38px rgba(0, 0, 0, 0.3)",
    },
  },
  headerContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

export default function PotentialDuplicate({
  duplicateAuthData,
  loading,
  open,
  onClose,
  serviceRequests,
  user,
  onClickNewSR,
  searchForPotentialDuplicates,
  serviceRequestFormContents,
  getPotentialDuplicatesLoading,
}: Props) {
  const classes = useStyles();
  const modalHeader =
    serviceRequests && serviceRequests.length > 1
      ? "Potential duplicate requests were found"
      : "A potential duplicate request was found";

  const trackUserActivityInteraction = useTrackUserInteraction();
  const workflowId = useContext(PotentialDuplicateContext);
  const handleCancelDuplicate = () => {
    trackUserActivityInteraction({
      event: "CONTINUE_WITH_DUPLICATE",
      stage: "DUPLICATE_SUBMISSION_REVIEW",
      activityContext: {
        workflowId: workflowId || undefined,
      },
    });
  };

  return (
    <PotentialDuplicateModal open={open} onClose={onClose}>
      <>
        <div className={classes.headerContainer}>
          <div className={classes.h2Container}>
            <H2 className={classes.h2}>{modalHeader}</H2>
            <Tooltip
              title={
                "Duplicate request = Same care setting (outpatient or inpatient), performing provider (if applicable), and facility; overlap in procedure codes and service dates."
              }
              placement="top"
            >
              <InfoOutlinedIcon fontSize="small" style={{ color: colorsLight.font.light }} />
            </Tooltip>
          </div>
        </div>
        <Card className={classes.cardStyle}>
          <ServiceRequestCardList
            onPotentialDuplicatesClose={onClose}
            cardStyles={{
              border: "1px solid #E5E5E5",
              boxShadow: "0px 2px 2px 0px rgba(207, 226, 231, 0.80)",
              "&:hover": {
                boxShadow: "0px 2px 2px 0px rgba(207, 226, 231, 0.80)",
              },
            }}
            viewableServiceRequests={
              duplicateAuthData
                ? duplicateAuthData
                    .map(({ serviceRequest }) => serviceRequest)
                    .filter((sr): sr is ServiceRequestResponse => !!sr)
                : []
            }
            error={false}
            gatherRequirements={true}
            displayMax={duplicateAuthData?.length || 0}
            isExistingAuthorization={true}
            loading={false}
            user={user}
            searchForPotentialDuplicates={searchForPotentialDuplicates}
            serviceRequestFormContents={serviceRequestFormContents}
            workflowId={workflowId || ""}
            getPotentialDuplicatesLoading={getPotentialDuplicatesLoading}
          />
          <InlineButtonContainer>
            <TertiaryButton
              disabled={loading}
              loading={loading}
              onClick={async () => {
                await handleCancelDuplicate();
                onClickNewSR();
              }}
            >
              I’d like to start a new request
            </TertiaryButton>
          </InlineButtonContainer>
        </Card>
      </>
    </PotentialDuplicateModal>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const InlineButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  paddingTop: theme.spacing(1.25),
  paddingBottom: theme.spacing(4.25),
}));
