import React from "react";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";

export interface ModalProps extends DialogProps {
  hideX?: boolean;
  contentClassName?: string;
  dialogTitleStyle?: string;
  withdrawAuthDecisionGroup?: boolean;

  header: JSX.Element;

  body: JSX.Element;
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  dialog: {
    "& .MuiPaper-root": {
      "&.MuiDialog-paperWidthLg": { maxWidth: "1024px" },
      borderRadius: "16px",
    },
  },
}));

const ExistingAuthorizationModal = ({
  hideX,
  children,
  open,
  onClose,
  contentClassName,
  dialogTitleStyle,
  withdrawAuthDecisionGroup,
  header,
  body,
  ...props
}: ModalProps) => {
  const { closeButton: closeButtonClass, dialog: dialogClass } = useStyles();

  return (
    <Dialog fullWidth maxWidth={"lg"} className={dialogClass} open={open} onClose={onClose} {...props}>
      <DialogTitle>
        {!hideX && (
          <IconButton
            className={closeButtonClass}
            aria-label="Dismiss modal"
            onClick={(event) => {
              onClose?.(event, "backdropClick");
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        {header}
      </DialogTitle>
      <DialogContent className={contentClassName}>{body}</DialogContent>
    </Dialog>
  );
};

ExistingAuthorizationModal.displayName = "Modal";
export default ExistingAuthorizationModal;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DialogTitle = styled(MuiDialogTitle)({
  position: "relative",
  border: "1px solid #E5E5E5",
  boxShadow: "inset 0px 4px 8px rgba(33, 41, 54, 0.1)",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(0),
  overflowY: "scroll",
}));
