import { Author, MongoChangeEventEntity } from "@coherehealth/audit-api";
import { AuthStatusHistoryQueryPayload } from "./useAuthStatusHistory";

export const processAuditHistoryData = (data: MongoChangeEventEntity[]) => {
  const processedData = [];
  const authStatusArray: string[] = [];
  const pendingReasonArray: string[] = [];
  let authStatusChange;
  let pendingReasonChange;

  const initialData = getInitialData(data[0]);
  if (initialData[0] !== "--") {
    authStatusArray.push(initialData[0]);
  }
  if (initialData[1] !== "--") {
    pendingReasonArray.push(initialData[1]);
  }

  for (let i = 1; i < data.length; i++) {
    const updatedFields = data[i]?.updateDescription?.updatedFields;
    if (updatedFields) {
      if ("authStatus" in updatedFields) {
        if (authStatusArray.length === 0) {
          authStatusArray.push(updatedFields.authStatus);
        } else {
          authStatusChange = processAuthStatusChangeEvent(authStatusArray, data[i], updatedFields);
          processedData.push(authStatusChange);
        }
      }
      if ("pendingReason" in updatedFields) {
        if (pendingReasonArray.length === 0) {
          pendingReasonArray.push(updatedFields.pendingReason);
        } else {
          pendingReasonChange = processPendingReasonChangeEvent(pendingReasonArray, data[i], updatedFields);
          processedData.push(pendingReasonChange);
        }
      }
    }
  }

  return processedData;
};

const getInitialData = (firstDocument: MongoChangeEventEntity) => {
  let initialAuthStatus;
  let initialPendingReason;

  const { fullDocument } = firstDocument || {};

  if (fullDocument) {
    const { authStatus, pendingReason } = fullDocument;

    "authStatus" in fullDocument ? (initialAuthStatus = authStatus) : (initialAuthStatus = "--");

    "pendingReason" in fullDocument ? (initialPendingReason = pendingReason) : (initialPendingReason = "--");
  }

  return [initialAuthStatus, initialPendingReason];
};

const processAuthStatusChangeEvent = (
  currentAuthStatusArray: string[],
  change: MongoChangeEventEntity,
  updatedFields: any
) => {
  const previousAuthStatus = currentAuthStatusArray[currentAuthStatusArray.length - 1];
  const currentAuthStatus = updatedFields.authStatus;
  currentAuthStatusArray.push(currentAuthStatus);
  const changeReason = updatedFields.withdrawnReason || "--";
  const changeBy = getAuthorName(change.author || ({ id: "unknown" } as Author));
  const changeDate = formatChangeDate(updatedFields);
  return [
    changeDate,
    "Status",
    authStatusStringLookup[previousAuthStatus] || "--",
    authStatusStringLookup[currentAuthStatus] || "--",
    changeReasonStringLookup[changeReason] || "--",
    changeBy,
  ];
};

const processPendingReasonChangeEvent = (
  currentPendingReasonArray: string[],
  change: MongoChangeEventEntity,
  updatedFields: any
) => {
  const previousPendingReason = currentPendingReasonArray[currentPendingReasonArray.length - 1];
  const currentPendingReason = updatedFields.pendingReason;
  currentPendingReasonArray.push(currentPendingReason);
  const changeBy = getAuthorName(change.author || ({ id: "unknown" } as Author));
  const changeDate = formatChangeDate(updatedFields);
  return [
    changeDate,
    "Pending reason",
    pendingReasonStringLookup[previousPendingReason] || "--",
    pendingReasonStringLookup[currentPendingReason] || "--",
    "--",
    changeBy,
  ];
};

const getAuthorName = ({ id, name }: Author): string => {
  if (name) {
    return `${name || "Unknown"}`;
  }
  if (id === "sub") {
    return "Unknown";
  }
  return `Okta user with id ${id}`;
};

const formatChangeDate = (updatedFields: any) => {
  const { lastUpdated, decisionTimestamp, revisedDecisionTimestamp, intakeTimestamp } = updatedFields;

  let chosenTimestamp;
  if (revisedDecisionTimestamp) {
    chosenTimestamp = revisedDecisionTimestamp;
  } else if (decisionTimestamp) {
    chosenTimestamp = decisionTimestamp;
  } else if (intakeTimestamp) {
    chosenTimestamp = intakeTimestamp;
  } else {
    chosenTimestamp = lastUpdated;
  }

  const formattedDate = new Date(chosenTimestamp);
  const options: Intl.DateTimeFormatOptions = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };

  const formattedString = formattedDate.toLocaleString("en-US", options);
  return formattedString;
};

const sortProcessedAuditHistoryData = (
  processedData: string[][],
  index: number,
  direction: string | undefined,
  isDate: boolean
) => {
  const sortedData = [...processedData];
  sortedData.sort((a, b) => {
    const aValue = isDate ? Date.parse(a[index]) : a[index];
    const bValue = isDate ? Date.parse(b[index]) : b[index];

    if (aValue === bValue) {
      return 0;
    }

    return direction === "ASC" ? (aValue < bValue ? -1 : 1) : aValue > bValue ? -1 : 1;
  });

  return sortedData;
};

export const queryProcessedAuditHistoryData = (
  queryParams: AuthStatusHistoryQueryPayload,
  processedData: string[][]
) => {
  const { offset, max, sortBy } = queryParams;
  if (offset !== undefined && max !== undefined && sortBy !== undefined) {
    return sortProcessedAuditHistoryData(processedData.slice(offset, offset + max), 0, sortBy[0].sortOrder, true);
  }
};

const authStatusStringLookup: Record<string, string> = {
  DRAFT: "Draft",
  PENDING_EXTERNAL_DETERMINATION: "Pending External Determination",
  INTAKE: "Intake",
  PENDING: "Pending",
  PENDING_ASSESSMENT: "Pending Assessment",
  RECOMMENDED_DENIAL: "Recommended Denial",
  RECOMMENDED_PARTIAL_APPROVAL: "Recommended Partial Approval",
  POST_DENIAL_PEER_TO_PEER: "Post Denial Peer to Peer",
  PARTIALLY_APPROVED: "Partially Approved",
  DENIED: "Denied",
  APPROVED: "Approved",
  VOIDED: "Voided",
  WITHDRAWN: "Withdrawn",
  DISMISSED: "Dismissed",
  INVALID_REASON_PROVIDED: "Invalid reason provided",
};

const pendingReasonStringLookup: Record<string, string> = {
  MISSING_CLINICAL_INFO: "Missing information (clinical)",
  MISSING_NON_CLINICAL_INFO: "Missing information (non-clinical)",
  RN_REVIEW: "Clinical Review",
  MD_REVIEW: "MD Review",
  PEER_TO_PEER_REVIEW: "P2P Review",
  PENDING_ASSESSMENT_SUBMISSION: "Pending assessment submission",
  OTHER: "Other",
  AUDIT_REVIEW: "Audit Review",
  NEW_HIRE_AUDIT_REVIEW: "New Hire Audit Review",
  OUT_OF_SCOPE: "Out of scope",
  OSCAR: "Oscar",
  NUDGE_OUTREACH: "Nudge Outreach",
  ADMIN_VOID: "Admin Void",
  CPP_REVIEW: "CPP Review",
  UNWORKABLE: "Unworkable",
  MISROUTE: "Misroute",
  SCHEDULING_OUTREACH: "Scheduling Outreach",
};

const changeReasonStringLookup: Record<string, string> = {
  DUPLICATE_REQUEST: "Duplicate Request",
  INACCURATE_REQUEST_DETAILS: "Request Details Inaccurate (e.g., units, dates, providers, facility, clinicals)",
  SERVICE_NO_LONGER_REQUIRED: "Service is No Longer Required",
  SERVICE_EXCLUDED_FROM_BENEFIT_PLAN: "Service is specifically excluded from the benefit plan",
  PLAN_CONTRACTUAL_GEOGRAPHIC_CONSTRICTION: "Plan/contractual geographic restriction",
  PLAN_CONTRACTUAL_GUIDELINES_NOT_FOLLOWED: "Plan/contractual guidelines not followed",
  PRIOR_AUTH_NOT_REQUIRED: "Prior Authorization Not Required for Service",
  REQUESTED_INFO_NOT_RECEIVED: "Requested Information Not Received",
  NON_COHERE_REVIEW_ORG_RESPONSIBILITY: "Responsibility of Non-Cohere Review Organization",
  REQUEST_DETAILS_INCONSISTENCY: "Service Inconsistent with Request Details",
  DOES_NOT_MEET_CLINICAL_POLICY: "Does Not Meet Plan, NCD/LCD, or other Clinical Policy",
  INAPPROPRIATE_LEVEL_OF_CARE: "Level of Care Not Appropriate",
  NOT_MEDICALLY_NECESSARY: "Service Not Medically Necessary",
  ALTERNATIVE_SERVICES_RECOMMENDED_BY_REVIEWER: "Alternative Service(s) Recommended By Reviewer",
  DIAGNOSIS_AGE_OR_GENDER_INCONSISTENCY: "Service Inconsistent with Patient Diagnosis, Age, or Gender",
  PRESCRIPTION_NO_LONGER_REQUIRED: "Prescription is no longer required",
  DUPLICATE_APPROVED_REQUEST: "Duplicate of a previously approved request",
  DUPLICATE_DENIED_REQUEST: "Duplicate of a previously denied request",
  OS_GEOGRAPHIC: "Plan/contractual geographic restriction",
  OS_CONCURRENT: "Service requires concurrent review",
  OS_OTHER: "Other reason service is out of Cohere’s Scope",
  EXCLUDED_FROM_AUTH_REQUEST: "Service is excluded from auth requirement",
  OTHER_REVIEW_ORG_RESPONSIBILITY: "Responsibility of other Review Organization",
  DISMISSAL_INVALID_REQUEST: "Dismissal invalid request",
  DISMISSAL_MEMBER_EXPIRED: "Dismissal member expired",
  DISMISSAL_IMPROPER_PARTY: "Dismissal not a proper party",
  PATIENT_HAS_BEEN_DISCHARGED: "Patient has been discharged",
  INTEGRATION_FAILURE: "Integration Failure",
};
