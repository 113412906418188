import {
  ProcedureCodeWithId,
  TargetFieldLabel,
  formatDateToISODate,
  isTargetForActionAmongOptions,
  today,
  useFeature,
  withDefaultUnits,
  plusDays,
} from "@coherehealth/common";
import {
  AdditionalInsurance,
  AuthBuilderWorkflowStep,
  AuthCategoryResponse,
  CarePath,
  CarePathJourney,
  ClinicalService,
  Coverage,
  DiagnosisCode,
  FacilityCategory,
  GetOutofNetworkCheckResponse,
  Location,
  OONCheckRequest,
  OscarServiceType,
  Patient,
  PlaceOfService,
  ProcedureCode,
  RuleActions,
  ServiceDeterminationProcedureCodeBucket,
  ServiceRequestResponse,
  useCrdCheck,
  useGetOutofNetworkCheck,
  useGetServiceRequestRuleActions,
  usePalCheck,
  useGetFaxAttachment,
  useUpdateServiceRequest,
  FaxAttachment,
  CdsResponse,
  CardExtensionProcedureCode,
  OutOfNetworkCheckConfiguration,
  CardExtensionDetails,
} from "@coherehealth/core-platform-api";
import {
  AdditionalCareParticipantFormContent,
  ServiceRequestFormContent,
} from "common/SharedServiceRequestFormComponents";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import {
  autoFillFormContent,
  dateProperlyFormatted,
  filterToAuthBucket,
  filterToAuthBucketCrd,
  filterToCodesAndVendor,
  getFieldSpecForAdditionalCareParticipant,
} from "util/serviceRequest";
import { error as logError, warn as logWarning } from "logger";
import { generateFormContentFromSuggestions, SuggestedFormContent } from "util/suggestionUtils";
import { getCombinedCoverageRanges, getCurrentPrimaryCoverage, getPatientHealthPlanName } from "util/patientUtils";
import { FormConfiguration } from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import isEqual from "lodash/isEqual";
import { constructProcedureCodes } from "../../util/clinicalAssessment";
import { omit } from "lodash";
import { generatePath, NavigateOptions, To, useLocation } from "react-router";
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from "notistack";
import { getPayloadForCRDCheck, getPayloadForPALCheck } from "./GatherRequirements/AuthRequirementsForm";
import { isRecommendChangeAction } from "util/rule";
import { stringify as stringifyQueryString } from "qs";
import routes from "routes";
import { useAuthorized } from "authorization";
import { isAfter, isBefore, addDays } from "date-fns";
import { useParams } from "react-router-dom";
import { usePrevious } from "@react-hookz/web";

export type AuthFlowType = "MEDICAL" | "PHARMACY";

const GEISINGER = "Geisinger";

export type PriorAuthRequirements = {
  primaryDiagnosis?: DiagnosisCode;
  secondaryDiagnoses?: DiagnosisCode[];
  startDate?: Date;
  encounterType?: FacilityCategory;
  desiredProcedureCodes?: ProcedureCode[]; //all procedure codes entered on AuthRequirementsForm
  showPxCheckbox: boolean;
  noPxServiceRequired: boolean;
  noPxService?: ClinicalService;
  homeHealth?: boolean;
  authCategory?: AuthCategoryResponse;
  authSubcategory?: string;
  userSelectedOutOfNetworkException?: boolean;
  userSelectedNonPalCode?: boolean;
  additionalInsurance?: AdditionalInsurance;
};

export interface ServiceRequestFormStateSetters {
  setServiceRequestFormContents: Dispatch<SetStateAction<ServiceRequestFormContent[]>>;
  setServiceRequestFormsCanBeSubmitted: Dispatch<SetStateAction<boolean[]>>;
  setServiceRequestFormsHaveNewEdits: Dispatch<SetStateAction<boolean[]>>;
}

export declare type NonCohereCodes = {
  authSubmissionVendor: string;
  procedureCode: ProcedureCode[];
};

export function isInpatient(
  defaultPlaceOfService: PlaceOfService | undefined,
  selectedEncounterType: FacilityCategory | undefined,
  placeOfService?: PlaceOfService
): boolean {
  if (placeOfService) {
    //get encounter types from given place of service
    const { encounterTypes } = placeOfService;
    if (encounterTypes && encounterTypes.length > 0) {
      //if the place of service encounter types include inpatient and the selected
      // encounter type is inpatient
      if (selectedEncounterType === "INPATIENT" && encounterTypes.includes("INPATIENT")) {
        return true;
      }
      //if there is only one possible encounter type and it is inpatient
      return encounterTypes.length === 1 && encounterTypes[0] === "INPATIENT";
    }
  }

  //get the encounter types from the default place of service
  const defaultEncounterTypes = defaultPlaceOfService?.encounterTypes;

  if (defaultEncounterTypes && defaultEncounterTypes.length > 0) {
    if (selectedEncounterType === "INPATIENT") {
      return true;
    }
    //if there is only one possible default encounter type and it is inpatient
    return defaultEncounterTypes.length === 1 && defaultEncounterTypes[0] === "INPATIENT";
  } else {
    //there are no defaul encounter types, so just check if the selected one is inpatient
    return selectedEncounterType === "INPATIENT";
  }
}

interface AuthBuilderContextParams {
  priorAuthRequirements: PriorAuthRequirements;
  patient?: Patient;
  clinicalService?: ClinicalService;
  clinicalServices?: ClinicalService[];
  nonPxClinicalServiceIds?: string[];
  carePath?: CarePath;
  carePathJourney?: CarePathJourney;
  serviceType?: OscarServiceType;
  placeOfService?: PlaceOfService;
  procedureCodes?: ProcedureCode[];
  mostRecentlyUsedFaxNumber?: string;
  simplifiedServiceFrequency?: boolean;
  importedEhrOrder?: ServiceRequestResponse;
  shouldPopulateAdmissionDates?: boolean;
  suggestedFormContent?: SuggestedFormContent;
  previousFormContent?: ServiceRequestFormContent;
  generalAuthSubmissionWorkflowEnabled?: boolean;
  facilityBasedFeatureEnabled?: boolean;
  workflowStep?: AuthBuilderWorkflowStep;
}

export function formContentFromAuthBuilderContext({
  priorAuthRequirements,
  patient,
  clinicalService,
  clinicalServices,
  nonPxClinicalServiceIds,
  carePath,
  carePathJourney,
  serviceType,
  placeOfService,
  procedureCodes,
  mostRecentlyUsedFaxNumber,
  simplifiedServiceFrequency,
  importedEhrOrder,
  suggestedFormContent,
  shouldPopulateAdmissionDates,
  previousFormContent,
  facilityBasedFeatureEnabled,
  workflowStep,
}: AuthBuilderContextParams): ServiceRequestFormContent {
  let placeOfServiceIsInpatient;
  if (clinicalServices) {
    placeOfServiceIsInpatient =
      clinicalServices && clinicalServices?.length > 0 ? clinicalServices[0].defaultPlaceOfService : undefined;
  } else {
    placeOfServiceIsInpatient = clinicalService?.defaultPlaceOfService;
  }
  const isInpatientRequest = isInpatient(
    placeOfServiceIsInpatient,
    priorAuthRequirements.encounterType,
    placeOfService
  );
  const startDate = priorAuthRequirements?.startDate || today();

  // todo COH-1948 add this as a useEffect somewhere
  // const shouldDefaultAdmissionDischargeDate =
  //   isInpatientRequest && formConfiguration.admissionDischargeDate.fieldSpec !== "OMIT";
  let formContent: ServiceRequestFormContent = {
    id: previousFormContent?.id || undefined,
    clinicalServices: clinicalServices,
    nonPxClinicalServiceIds: nonPxClinicalServiceIds,
    carePath,
    carePathJourney,
    serviceType,
    palCategory: previousFormContent?.palCategory || null,
    isInpatient: isInpatientRequest,
    primaryDiagnosisCode: priorAuthRequirements.primaryDiagnosis || null,
    secondaryDiagnosisCodes: priorAuthRequirements.secondaryDiagnoses || [],
    units: previousFormContent?.units || "1",
    unitType: undefined,
    startDate,
    endDate: previousFormContent?.endDate || undefined,
    procedureCodes: procedureCodes || [],
    placeOfService: placeOfService || previousFormContent?.placeOfService,
    orderingProvider: previousFormContent?.orderingProvider || null,
    orderingProviderSelectedTin: previousFormContent?.orderingProviderSelectedTin || null,
    performingProvider: previousFormContent?.performingProvider || null,
    performingProviderSelectedTin: previousFormContent?.performingProviderSelectedTin || null,
    facility: previousFormContent?.facility || null,
    facilitySelectedTin: previousFormContent?.facilitySelectedTin || null,
    isExpedited: previousFormContent?.isExpedited || false,
    expeditedReason: previousFormContent?.expeditedReason || "",
    followUpFaxNumber: mostRecentlyUsedFaxNumber ? { number: mostRecentlyUsedFaxNumber } : undefined,
    orderingProviderSelectedAddress: previousFormContent?.orderingProviderSelectedAddress || null,
    facilitySelectedAddress: previousFormContent?.facilitySelectedAddress || null,
    performingProviderSelectedAddress: previousFormContent?.performingProviderSelectedAddress || null,
    approvedUnits: "",
    authCategory: priorAuthRequirements?.authCategory,
    authSubcategory: priorAuthRequirements?.authSubcategory,
    userSelectedOONException: previousFormContent?.userSelectedOONException,
    userSelectedNonPalCode: priorAuthRequirements?.userSelectedNonPalCode,
    selectedFacility: previousFormContent?.selectedFacility,
    selectedOrderingProvider: previousFormContent?.selectedOrderingProvider,
    selectedPerformingProvider: previousFormContent?.selectedPerformingProvider,
    selectedPerformingProviderPractice: previousFormContent?.selectedPerformingProviderPractice,
    selectedPerformingProviderPracticeAddress: previousFormContent?.selectedPerformingProviderPracticeAddress,
    selectedPerformingProviderPracticeSelectedTIN: previousFormContent?.selectedPerformingProviderPracticeSelectedTIN,
    selectedPerformingProviderPracticeOONExceptionRequired:
      previousFormContent?.selectedPerformingProviderPracticeOONExceptionRequired,
    workflowStep: workflowStep,
    additionalCareParticipants: previousFormContent?.additionalCareParticipants || [],
    additionalInsurance: priorAuthRequirements.additionalInsurance,
    admissionDate: previousFormContent?.admissionDate,
    admissionTime: previousFormContent?.admissionTime,
    admissionSource: previousFormContent?.admissionSource,
    patientStatus: previousFormContent?.patientStatus,
    patientStayDateRanges: previousFormContent?.patientStayDateRanges,
    expectedAdmissionDate: previousFormContent?.expectedAdmissionDate,
    dischargeDate: previousFormContent?.dischargeDate,
    dischargeTime: previousFormContent?.dischargeTime,
    dischargedTo: previousFormContent?.dischargedTo,
  };

  if (clinicalServices) {
    //do not preset place of service for a facility based inpatient request
    //because in that scenario it does not depend on the clinical services
    if (
      !facilityBasedFeatureEnabled ||
      priorAuthRequirements?.encounterType === "OUTPATIENT" ||
      !previousFormContent?.isInpatient
    ) {
      const defaultPlaceOfService =
        clinicalServices && clinicalServices?.length > 0 ? clinicalServices[0].defaultPlaceOfService : null;
      formContent.placeOfService = defaultPlaceOfService || formContent?.placeOfService;
    }

    const initializedEndDate = calculateInitializedEndDate(
      formContent.startDate,
      clinicalServices,
      patient?.coverages || []
    );
    if (initializedEndDate) {
      formContent.endDate = initializedEndDate;
    }

    if (
      initializedEndDate &&
      (!facilityBasedFeatureEnabled ||
        priorAuthRequirements?.encounterType === "INPATIENT" ||
        previousFormContent?.isInpatient)
    ) {
      formContent.expectedDischargeDate = initializedEndDate;
    }
    formContent.isRecurring = undefined;
  } else {
    formContent.clinicalService = clinicalService;
    //do not preset place of service for a facility based inpatient request
    //because in that scenario it does not depend on the clinical service
    if (
      !facilityBasedFeatureEnabled ||
      priorAuthRequirements?.encounterType === "OUTPATIENT" ||
      !previousFormContent?.isInpatient
    ) {
      formContent.placeOfService =
        formContent?.placeOfService ||
        clinicalService?.defaultPlaceOfService ||
        previousFormContent?.placeOfService ||
        null;
    }
    formContent.isRecurring = simplifiedServiceFrequency ? undefined : clinicalService?.defaultAuthType === "RECURRING";
    formContent.units = `${clinicalService?.defaultUnits || 1}`;
    // Adding this in because I don't know if it's fully deprecated but just in case it's not.
    if (clinicalService?.defaultDuration && clinicalService?.defaultDuration > 0) {
      formContent.endDate = plusDays(clinicalService?.defaultDuration, startDate);
    }
    formContent.unitType = simplifiedServiceFrequency ? undefined : clinicalService?.defaultUnitType;
  }

  if (shouldPopulateAdmissionDates && isInpatientRequest) {
    // This logic initially added as part of the facility-based workflow feature
    shouldIncludeFacilityBasedFields(formContent, startDate);
  }

  if (suggestedFormContent) {
    formContent = generateFormContentFromSuggestions(suggestedFormContent, formContent);
  }

  if (importedEhrOrder) {
    return autoFillFormContent(formContent, importedEhrOrder);
  } else {
    return formContent;
  }
}

export const shouldIncludeFacilityBasedFields = (formContent: ServiceRequestFormContent, startDate: Date) => {
  if (isBefore(startDate, addDays(today(), 1))) {
    // If start date is before today then also pre-fill admission date
    formContent.admissionDate = formContent?.admissionDate ? formContent.admissionDate : startDate;
    formContent.patientStatus = formContent?.patientStatus ? formContent.patientStatus : "CURRENTLY_ADMITTED";
    formContent.patientStayDateRanges =
      formContent?.patientStayDateRanges && formContent?.patientStayDateRanges?.length > 0
        ? formContent.patientStayDateRanges
        : [
            {
              rangeStartDate: startDate,
              rangeEndDate: null,
              requestedLevelOfCareCode: null,
              approvedLevelOfCareCode: null,
              reviewStatus: null,
            },
          ];
  } else {
    formContent.expectedAdmissionDate = formContent?.expectedAdmissionDate
      ? formContent.expectedAdmissionDate
      : startDate;
    formContent.patientStatus = formContent?.patientStatus ? formContent.patientStatus : "NOT_YET_ADMITTED";
  }
};

export const fetchRuleActions = async (
  serviceRequests: ServiceRequestResponse[],
  refetchActions: ReturnType<typeof useGetServiceRequestRuleActions>["refetch"],
  targetsForAction: TargetFieldLabel[],
  dedicatedNudgeIds: string[],
  setDedicatedNudgeIds: Dispatch<SetStateAction<string[]>>,
  setCurrentDedicatedNudgeTargetForAction: Dispatch<SetStateAction<TargetFieldLabel | undefined>>,
  setDedicatedNudgeServiceRequestId: (s: string) => void,
  setGoToDedicatedNudgeStep?: (goToDedicatedNudgeStep: boolean) => void
) => {
  let foundDedicatedNudgeRules = false;
  //I think we can rip this loop out but not sure
  for (const s of serviceRequests) {
    const rulesActionsToCheckForDedicatedNudge: RuleActions | null = await refetchActions({
      pathParams: { id: s.id },
      queryParams: {
        authStage: convertStringToAuthBuilderWorkflowStep("REVIEW_NUDGES"),
        displayTarget: "REVIEW_NUDGES",
      },
    });
    if (rulesActionsToCheckForDedicatedNudge) {
      const dedicatedNudge = findDedicatedNudgeStepNudge(
        rulesActionsToCheckForDedicatedNudge,
        targetsForAction,
        dedicatedNudgeIds
      );

      foundDedicatedNudgeRules = !!dedicatedNudge && dedicatedNudge.found;
      if (foundDedicatedNudgeRules) {
        setDedicatedNudgeIds([...dedicatedNudgeIds, dedicatedNudge?.dedicatedNudgeId || ""]);
        setDedicatedNudgeServiceRequestId(s.id);
        setCurrentDedicatedNudgeTargetForAction(dedicatedNudge?.target);
      }
    }
  }
  if (setGoToDedicatedNudgeStep) {
    setGoToDedicatedNudgeStep(foundDedicatedNudgeRules);
  }
};

export const findDedicatedNudgeStepNudge = (
  ruleActions: RuleActions,
  targetsForAction: TargetFieldLabel[],
  dedicatedNudgeIds: string[]
) => {
  try {
    const filteredRuleActions = ruleActions?.filter(isRecommendChangeAction);

    if (filteredRuleActions && !!filteredRuleActions.length) {
      const targetForActionOptions = targetsForAction.map((target) => {
        const foundApplicableAction = filteredRuleActions.find((action) =>
          isTargetForActionAmongOptions([target], action)
        );
        return { target: target, found: !!foundApplicableAction, dedicatedNudgeId: foundApplicableAction?.ruleId };
      });

      const dedicatedNudge = targetForActionOptions.find(
        (action) => action.found && action.dedicatedNudgeId && !dedicatedNudgeIds.includes(action.dedicatedNudgeId)
      );

      return dedicatedNudge;
    }

    return undefined;
  } catch (err) {
    logError(err);
  }
};

/**
 * Source: https://javascript.plainenglish.io/how-to-add-a-timeout-limit-to-asynchronous-javascript-functions-3676d89c186d
 * Call an async function with a maximum time limit (in milliseconds) for the timeout
 * @param {Promise<any>} asyncPromise An asynchronous promise to resolve
 * @param {number} timeLimit Time limit to attempt function in milliseconds
 * @returns {Promise<any> | undefined} Resolved promise for async function call, or an error if time limit reached
 */
export const asyncCallWithTimeout = async (asyncPromise: Promise<any>, timeLimit: number): Promise<any> => {
  let timeoutHandle: NodeJS.Timeout;
  const timeoutPromise = new Promise((_resolve, reject) => {
    timeoutHandle = setTimeout(() => reject(new Error("Async call timeout limit reached")), timeLimit);
  });
  return Promise.race([asyncPromise, timeoutPromise]).then((result) => {
    clearTimeout(timeoutHandle);
    return result;
  });
};

const networkCheckTimeout = 10000;

interface UseOutOfNetworkCheckProps {
  formFieldConfigurations: FormConfiguration;
  outOfNetworkCheckConfiguration: OutOfNetworkCheckConfiguration;
  content: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  patient?: Patient;
  workflowId?: string;
  delegatedVendorName?: string;
  hideTinField?: boolean;
}

interface UseCareParticipantOutOfNetworkCheckProps extends UseOutOfNetworkCheckProps {
  careParticipant: AdditionalCareParticipantFormContent;
  rowIndex: number;
  hideTinField?: boolean;
}
// function to determine fields that should re-trigger a network check when changed
// comment and reason changing should not trigger a new network check
export const networkCheckDependentFields = (networkCheckPayload: OONCheckRequest) => {
  return {
    ...networkCheckPayload,
    location: {
      ...networkCheckPayload.location,
      outOfNetworkExceptionComment: undefined,
      outOfNetworkExceptionReason: undefined,
    },
    source: networkCheckPayload?.source,
  };
};

export const useFacilityOutOfNetworkCheck = ({
  formFieldConfigurations,
  outOfNetworkCheckConfiguration,
  content,
  setFormContent,
  patient,
  workflowId,
  delegatedVendorName,
  hideTinField,
}: UseOutOfNetworkCheckProps) => {
  const {
    loading: facilityOonCheckLoading,
    mutate: doFacilityOonCheck,
    cancel: cancelDoFacilityOonCheck,
  } = useGetOutofNetworkCheck({});

  // Stores the result of doFacilityOonCheck once it finishes
  const [facilityOonCheckData, setFacilityOonCheckData] = useState<GetOutofNetworkCheckResponse>();
  const [performedFacilityOONCheck, setPerformedFacilityOONCheck] = useState<boolean>(false);
  const facilityFieldsFilled = useMemo(() => {
    return formFieldConfigurations?.facilityNPI?.fieldSpec === "OPTIONAL"
      ? content?.facility
        ? !!content?.facility &&
          (hideTinField || (content?.facility?.tinList?.length === 0 ? true : !!content?.facilitySelectedTin))
        : false
      : formFieldConfigurations?.facilityNPI?.fieldSpec === "REQUIRED"
      ? !!content?.facility &&
        (hideTinField || (content?.facility?.tinList?.length === 0 ? true : !!content?.facilitySelectedTin))
      : false;
  }, [content?.facility, content?.facilitySelectedTin, formFieldConfigurations?.facilityNPI?.fieldSpec, hideTinField]);
  const facilityAddressFieldsFilled = useMemo(() => {
    return formFieldConfigurations?.facilityAddress?.fieldSpec === "OPTIONAL"
      ? !!content?.facility
        ? !!content?.facility &&
          (hideTinField || (content?.facility?.tinList?.length === 0 ? true : !!content?.facilitySelectedTin))
        : true
      : formFieldConfigurations?.facilityAddress?.fieldSpec === "REQUIRED"
      ? !!content?.facility &&
        (hideTinField || (content?.facility?.tinList?.length === 0 ? true : !!content?.facilitySelectedTin)) &&
        (content?.facility?.locations ? !!content?.facilitySelectedAddress?.address : true)
      : false;
  }, [
    content?.facility,
    content?.facilitySelectedAddress?.address,
    content?.facilitySelectedTin,
    formFieldConfigurations?.facilityAddress?.fieldSpec,
    hideTinField,
  ]);

  const facilityExceptionRequired =
    formFieldConfigurations.facilityOutOfNetworkExceptionReason.fieldSpec === "REQUIRED";

  const requiredFieldsFilled = facilityFieldsFilled && facilityAddressFieldsFilled;
  const getFacilityLocationIfNone = useCallback(
    (tin: string): Location | undefined => {
      let location: Location | undefined;
      if (content?.facility && content?.facility?.locations && !content?.facilitySelectedAddress) {
        const locationsFacility = content?.facility.locations.filter((loc) => loc.tin === tin);
        location = locationsFacility[0];
      } else {
        location = {
          tin: content?.facilitySelectedTin || undefined,
        };
      }
      return location;
    },
    [content?.facility, content?.facilitySelectedAddress, content?.facilitySelectedTin]
  );

  const oonCheckPayload: OONCheckRequest | null = useMemo(() => {
    if (!dateProperlyFormatted(content?.startDate)) {
      return null;
    }
    return {
      patientId: patient?.id,
      memberId: patient?.memberId,
      effectiveDate: formatDateToISODate(content?.startDate),
      npi: content?.facility?.npi || undefined,
      providerType: content?.facility?.providerType,
      location:
        content?.facilitySelectedAddress && content?.facilitySelectedAddress?.address
          ? content?.facilitySelectedAddress
          : content?.facilitySelectedTin
          ? getFacilityLocationIfNone(content?.facilitySelectedTin)
          : {
              tin: content?.facilitySelectedTin || undefined,
            },
      workflowId: workflowId,
      delegatedVendorName: delegatedVendorName,
      procedureCodes: content?.procedureCodes,
      recordType: content?.selectedFacility?.type,
      source: content?.selectedFacility?.source,
    };
  }, [
    content?.facility?.npi,
    content?.facility?.providerType,
    content?.facilitySelectedAddress,
    content?.facilitySelectedTin,
    content?.procedureCodes,
    content?.selectedFacility?.type,
    content?.selectedFacility?.source,
    content?.startDate,
    delegatedVendorName,
    getFacilityLocationIfNone,
    patient?.id,
    patient?.memberId,
    workflowId,
  ]);

  const previousPayload = usePrevious(oonCheckPayload);

  // checks if performing facility fields have been filled completely to start OON check
  useEffect(() => {
    const networkCheckFieldPreviousPayload = omitNonNetworkCheckFields(previousPayload);
    const networkCheckFieldOONCheckPayload = omitNonNetworkCheckFields(oonCheckPayload);
    const previousNetworkCheckDependentFields = networkCheckDependentFields(networkCheckFieldPreviousPayload);
    const currentNetworkCheckDependentFields = networkCheckDependentFields(networkCheckFieldOONCheckPayload);

    if (!isEqual(previousNetworkCheckDependentFields, currentNetworkCheckDependentFields)) {
      //clear out OON check data if payload has changed
      setFacilityOonCheckData(undefined);
      setPerformedFacilityOONCheck(false);
    }

    if (
      formFieldConfigurations?.outOfNetworkCheck?.fieldSpec !== "OMIT" &&
      requiredFieldsFilled &&
      !!oonCheckPayload &&
      !isEqual(previousNetworkCheckDependentFields, currentNetworkCheckDependentFields) &&
      (outOfNetworkCheckConfiguration.runOONCheckOnManuallyCreatedProviderFacility ||
        !content.facility?.manuallyCreated) // skip for manually created facility unless configured otherwise
    ) {
      const dataFacilityPromise = doFacilityOonCheck(oonCheckPayload);

      const checkFacilityOONWithTimeout = async () => {
        let facilityResult: GetOutofNetworkCheckResponse | undefined;
        await asyncCallWithTimeout(Promise.all([dataFacilityPromise]), networkCheckTimeout)
          .then((result) => {
            if (Object.keys(result[0]).length) {
              setFacilityOonCheckData(result[0]);
              facilityResult = result[0];
            } else {
              setFacilityOonCheckData(undefined);
              facilityResult = undefined;
            }
          })
          .catch(() => {
            // OON check timed out
            cancelDoFacilityOonCheck();
            setFacilityOonCheckData(undefined);
            facilityResult = undefined;
          });
        if (facilityResult?.networkType) {
          setFormContent((prev) => {
            return {
              ...prev,
              facilitySelectedAddress: {
                ...prev?.facilitySelectedAddress,
                isOutOfNetwork: facilityResult?.networkType === "OON",
                networkType: facilityResult?.networkType,
              },
              selectedFacility: {
                ...prev?.selectedFacility,
                id: prev?.selectedFacility?.id || "",
                dateCreated: prev?.selectedFacility?.dateCreated || "",
                lastUpdated: prev?.selectedFacility?.lastUpdated || "",
                selectedLocation: {
                  ...prev?.selectedFacility?.selectedLocation,
                  isOutOfNetwork: facilityResult?.networkType === "OON",
                  networkType: facilityResult?.networkType,
                },
                type: prev?.selectedFacility?.type || "FACILITY",
              },
              facilityOONExceptionRequired: facilityExceptionRequired,
            };
          });
        } else if (
          facilityResult?.isOutOfNetwork !== content?.facilitySelectedAddress?.isOutOfNetwork ||
          facilityExceptionRequired !== content?.facilityOONExceptionRequired
        ) {
          setFormContent((prev) => {
            return {
              ...prev,
              facilitySelectedAddress: {
                ...prev?.facilitySelectedAddress,
                isOutOfNetwork: facilityResult?.isOutOfNetwork,
              },
              selectedFacility: {
                ...prev?.selectedFacility,
                id: prev?.selectedFacility?.id || "",
                dateCreated: prev?.selectedFacility?.dateCreated || "",
                lastUpdated: prev?.selectedFacility?.lastUpdated || "",
                selectedLocation: {
                  ...prev?.selectedFacility?.selectedLocation,
                  isOutOfNetwork: facilityResult?.isOutOfNetwork,
                },
                type: prev?.selectedFacility?.type || "FACILITY",
              },
              facilityOONExceptionRequired: facilityExceptionRequired,
            };
          });
        }
      };
      setFacilityOonCheckData(undefined);
      checkFacilityOONWithTimeout().then(() => setPerformedFacilityOONCheck(true));
    }
  }, [
    content.facility?.manuallyCreated,
    content?.facilityOONExceptionRequired,
    content?.facilitySelectedAddress?.isOutOfNetwork,
    doFacilityOonCheck,
    formFieldConfigurations?.outOfNetworkCheck?.fieldSpec,
    facilityExceptionRequired,
    oonCheckPayload,
    previousPayload,
    requiredFieldsFilled,
    setFormContent,
    outOfNetworkCheckConfiguration.runOONCheckOnManuallyCreatedProviderFacility,
    cancelDoFacilityOonCheck,
  ]);

  return { facilityOonCheckData, facilityOonCheckLoading, performedFacilityOONCheck };
};

export const usePerformingProviderPracticeOutOfNetworkCheck = ({
  formFieldConfigurations,
  outOfNetworkCheckConfiguration,
  content,
  setFormContent,
  patient,
  workflowId,
  delegatedVendorName,
  hideTinField,
}: UseOutOfNetworkCheckProps) => {
  const {
    loading: performingProviderPracticeOonCheckLoading,
    mutate: doPerformingProviderPracticeOonCheck,
    cancel: cancelDoPerformingProviderPracticeOonCheck,
  } = useGetOutofNetworkCheck({});

  // Stores the result of doFacilityOonCheck once it finishes
  const [performingProviderPracticeOonCheckData, setPerformingProviderPracticeOonCheckData] =
    useState<GetOutofNetworkCheckResponse>();
  const [performedProviderPracticeOONCheck, setPerformedProviderPracticeOONCheck] = useState<boolean>(false);

  const perfProviderPracticeFieldsFilled = useMemo(() => {
    return formFieldConfigurations?.performingProviderPracticeSelectedNPI?.fieldSpec === "OPTIONAL"
      ? content?.selectedPerformingProviderPractice
        ? !!content?.selectedPerformingProviderPractice &&
          (hideTinField ||
            (content?.selectedPerformingProviderPractice?.tinList?.length === 0
              ? true
              : !!content?.selectedPerformingProviderPracticeSelectedTIN))
        : false
      : formFieldConfigurations?.performingProviderPracticeSelectedNPI?.fieldSpec === "REQUIRED"
      ? !!content?.selectedPerformingProviderPractice &&
        (hideTinField ||
          (content?.selectedPerformingProviderPractice?.tinList?.length === 0
            ? true
            : !!content?.selectedPerformingProviderPracticeSelectedTIN))
      : false;
  }, [
    content?.selectedPerformingProviderPractice,
    content?.selectedPerformingProviderPracticeSelectedTIN,
    formFieldConfigurations?.performingProviderPracticeSelectedNPI?.fieldSpec,
    hideTinField,
  ]);

  const perfProviderPracticeAddressFieldsFilled = useMemo(() => {
    return formFieldConfigurations?.performingProviderPracticeSelectedAddress?.fieldSpec === "OPTIONAL"
      ? !!content?.selectedPerformingProviderPractice
        ? !!content?.selectedPerformingProviderPractice &&
          (hideTinField ||
            (content?.selectedPerformingProviderPractice?.tinList?.length === 0
              ? true
              : !!content?.selectedPerformingProviderPracticeSelectedTIN))
        : true
      : formFieldConfigurations?.performingProviderPracticeSelectedAddress?.fieldSpec === "REQUIRED"
      ? !!content?.selectedPerformingProviderPractice &&
        (hideTinField ||
          (content?.selectedPerformingProviderPractice?.tinList?.length === 0
            ? true
            : !!content?.selectedPerformingProviderPracticeSelectedTIN)) &&
        (content?.selectedPerformingProviderPractice?.locations
          ? !!content?.selectedPerformingProviderPracticeAddress?.address
          : true)
      : false;
  }, [
    content?.selectedPerformingProviderPractice,
    content?.selectedPerformingProviderPracticeAddress?.address,
    content?.selectedPerformingProviderPracticeSelectedTIN,
    formFieldConfigurations?.performingProviderPracticeSelectedAddress?.fieldSpec,
    hideTinField,
  ]);

  const perfProviderPracticeExceptionRequired =
    formFieldConfigurations.performingProviderPracticeOutOfNetworkExceptionReason.fieldSpec === "REQUIRED";

  const requiredFieldsFilled = perfProviderPracticeFieldsFilled && perfProviderPracticeAddressFieldsFilled;
  const getPerformingProviderPracticeLocationIfNone = useCallback(
    (tin: string): Location | undefined => {
      let location: Location | undefined;
      if (
        content?.selectedPerformingProviderPractice &&
        content?.selectedPerformingProviderPractice?.locations &&
        !content?.selectedPerformingProviderPracticeAddress
      ) {
        const locationsPerfProviderPractice = content?.selectedPerformingProviderPractice.locations.filter(
          (loc) => loc.tin === tin
        );
        location = locationsPerfProviderPractice[0];
      } else {
        location = {
          tin: content?.selectedPerformingProviderPracticeSelectedTIN || undefined,
        };
      }
      return location;
    },
    [
      content?.selectedPerformingProviderPractice,
      content?.selectedPerformingProviderPracticeAddress,
      content?.selectedPerformingProviderPracticeSelectedTIN,
    ]
  );

  const oonCheckPayload: OONCheckRequest | null = useMemo(() => {
    if (!dateProperlyFormatted(content?.startDate)) {
      return null;
    }
    return {
      patientId: patient?.id,
      memberId: patient?.memberId,
      effectiveDate: formatDateToISODate(content?.startDate),
      npi: content?.selectedPerformingProviderPractice?.npi || undefined,
      providerType: content?.selectedPerformingProviderPractice?.providerType,
      location:
        content?.selectedPerformingProviderPracticeAddress &&
        content?.selectedPerformingProviderPractice?.selectedLocation?.address
          ? content?.selectedPerformingProviderPracticeAddress
          : content?.selectedPerformingProviderPracticeSelectedTIN
          ? getPerformingProviderPracticeLocationIfNone(content?.selectedPerformingProviderPracticeSelectedTIN)
          : {
              tin: content?.selectedPerformingProviderPracticeSelectedTIN || undefined,
            },
      workflowId: workflowId,
      delegatedVendorName: delegatedVendorName,
      procedureCodes: content?.procedureCodes,
      recordType: content?.selectedPerformingProviderPractice?.type,
      source: content?.selectedPerformingProviderPractice?.source,
    };
  }, [
    content?.startDate,
    content?.selectedPerformingProviderPractice?.npi,
    content?.selectedPerformingProviderPractice?.providerType,
    content?.selectedPerformingProviderPractice?.selectedLocation?.address,
    content?.selectedPerformingProviderPractice?.type,
    content?.selectedPerformingProviderPractice?.source,
    content?.selectedPerformingProviderPracticeAddress,
    content?.selectedPerformingProviderPracticeSelectedTIN,
    content?.procedureCodes,
    patient?.id,
    patient?.memberId,
    getPerformingProviderPracticeLocationIfNone,
    workflowId,
    delegatedVendorName,
  ]);

  const previousPayload = usePrevious(oonCheckPayload);

  // checks if performing facility fields have been filled completely to start OON check
  useEffect(() => {
    const networkCheckFieldPreviousPayload = omitNonNetworkCheckFields(previousPayload);
    const networkCheckFieldOONCheckPayload = omitNonNetworkCheckFields(oonCheckPayload);
    const previousNetworkCheckDependentFields = networkCheckDependentFields(networkCheckFieldPreviousPayload);
    const currentNetworkCheckDependentFields = networkCheckDependentFields(networkCheckFieldOONCheckPayload);

    if (!isEqual(previousNetworkCheckDependentFields, currentNetworkCheckDependentFields)) {
      //clear out OON check data if payload has changed
      setPerformingProviderPracticeOonCheckData(undefined);
      setPerformedProviderPracticeOONCheck(false);
    }

    if (
      formFieldConfigurations?.outOfNetworkCheck?.fieldSpec !== "OMIT" &&
      requiredFieldsFilled &&
      !!oonCheckPayload &&
      !isEqual(previousNetworkCheckDependentFields, currentNetworkCheckDependentFields) &&
      (outOfNetworkCheckConfiguration.runOONCheckOnManuallyCreatedProviderFacility ||
        !content.selectedPerformingProviderPractice?.manuallyCreated) // skip for manually created facility unless configured otherwise
    ) {
      const dataPerfProviderPracticePromise = doPerformingProviderPracticeOonCheck(oonCheckPayload);

      const checkPerfProviderPracticeOONWithTimeout = async () => {
        let perfProviderPracticeResult: GetOutofNetworkCheckResponse | undefined;
        await asyncCallWithTimeout(Promise.all([dataPerfProviderPracticePromise]), networkCheckTimeout)
          .then((result) => {
            if (Object.keys(result[0]).length) {
              setPerformingProviderPracticeOonCheckData(result[0]);
              perfProviderPracticeResult = result[0];
            } else {
              setPerformingProviderPracticeOonCheckData(undefined);
              perfProviderPracticeResult = undefined;
            }
          })
          .catch(() => {
            // OON check timed out
            cancelDoPerformingProviderPracticeOonCheck();
            setPerformingProviderPracticeOonCheckData(undefined);
            perfProviderPracticeResult = undefined;
          });
        if (perfProviderPracticeResult?.networkType) {
          setFormContent((prev) => {
            return {
              ...prev,
              selectedPerformingProviderPracticeAddress: {
                ...prev?.selectedPerformingProviderPracticeAddress,
                isOutOfNetwork: perfProviderPracticeResult?.networkType === "OON",
                networkType: perfProviderPracticeResult?.networkType,
              },
              selectedPerformingProviderPractice: {
                ...prev?.selectedPerformingProviderPractice,
                id: prev?.selectedPerformingProviderPractice?.id || "",
                dateCreated: prev?.selectedPerformingProviderPractice?.dateCreated || "",
                lastUpdated: prev?.selectedPerformingProviderPractice?.lastUpdated || "",
                selectedLocation: {
                  ...prev?.selectedPerformingProviderPractice?.selectedLocation,
                  isOutOfNetwork: perfProviderPracticeResult?.networkType === "OON",
                  networkType: perfProviderPracticeResult?.networkType,
                },
                type: prev?.selectedPerformingProviderPractice?.type || "PERFOMING PROVIDER PRACTICE",
              },
              selectedPerformingProviderPracticeOONExceptionRequired: perfProviderPracticeExceptionRequired,
            };
          });
        } else if (
          perfProviderPracticeResult?.isOutOfNetwork !==
            content?.selectedPerformingProviderPracticeAddress?.isOutOfNetwork ||
          perfProviderPracticeExceptionRequired !== content?.selectedPerformingProviderPracticeOONExceptionRequired
        ) {
          setFormContent((prev) => {
            return {
              ...prev,
              selectedPerformingProviderPracticeAddress: {
                ...prev?.selectedPerformingProviderPracticeAddress,
                isOutOfNetwork: perfProviderPracticeResult?.isOutOfNetwork,
              },
              selectedPerformingProviderPractice: {
                ...prev?.selectedPerformingProviderPractice,
                id: prev?.selectedPerformingProviderPractice?.id || "",
                dateCreated: prev?.selectedPerformingProviderPractice?.dateCreated || "",
                lastUpdated: prev?.selectedPerformingProviderPractice?.lastUpdated || "",
                selectedLocation: {
                  ...prev?.selectedPerformingProviderPractice?.selectedLocation,
                  isOutOfNetwork: perfProviderPracticeResult?.isOutOfNetwork,
                },
                type: prev?.selectedPerformingProviderPractice?.type || "PERFOMING PROVIDER PRACTICE",
              },
              selectedPerformingProviderPracticeOONExceptionRequired: perfProviderPracticeExceptionRequired,
            };
          });
        }
      };
      setPerformingProviderPracticeOonCheckData(undefined);
      checkPerfProviderPracticeOONWithTimeout().then(() => setPerformedProviderPracticeOONCheck(true));
    }
  }, [
    content.selectedPerformingProviderPractice?.manuallyCreated,
    content.selectedPerformingProviderPracticeOONExceptionRequired,
    content?.selectedPerformingProviderPracticeAddress?.isOutOfNetwork,
    doPerformingProviderPracticeOonCheck,
    formFieldConfigurations?.outOfNetworkCheck?.fieldSpec,
    perfProviderPracticeExceptionRequired,
    oonCheckPayload,
    previousPayload,
    requiredFieldsFilled,
    setFormContent,
    outOfNetworkCheckConfiguration.runOONCheckOnManuallyCreatedProviderFacility,
    cancelDoPerformingProviderPracticeOonCheck,
  ]);

  return {
    performingProviderPracticeOonCheckData,
    performingProviderPracticeOonCheckLoading,
    performedProviderPracticeOONCheck,
  };
};

export const usePerformingProviderOutOfNetworkCheck = ({
  formFieldConfigurations,
  outOfNetworkCheckConfiguration,
  content,
  setFormContent,
  patient,
  workflowId,
  delegatedVendorName,
  hideTinField,
}: UseOutOfNetworkCheckProps) => {
  const {
    loading: providerOonCheckLoading,
    mutate: doProviderOonCheck,
    cancel: cancelDoProviderOonCheck,
  } = useGetOutofNetworkCheck({});

  // Stores the result of doProviderOonCheck once it finishes
  const [providerOonCheckData, setProviderOonCheckData] = useState<GetOutofNetworkCheckResponse>();
  const [performedProviderOONCheck, setPerformedProviderOONCheck] = useState<boolean>(false);

  const perfProviderFieldsFilled: boolean = useMemo(() => {
    return formFieldConfigurations?.performingProviderNPI?.fieldSpec === "OPTIONAL"
      ? !!content?.performingProvider
        ? getPatientHealthPlanName(patient) === GEISINGER && !!content?.performingProviderSelectedTin
          ? !!content?.performingProvider &&
            !!content?.performingProviderSelectedTin &&
            !!content?.performingProviderSelectedAddress?.address &&
            !!content?.performingProviderSelectedAddress?.externalReferenceId
          : !!content?.performingProvider &&
            (hideTinField ||
              (content?.performingProvider?.tinList?.length === 0 ? true : !!content?.performingProviderSelectedTin))
        : false
      : formFieldConfigurations?.performingProviderNPI?.fieldSpec === "REQUIRED"
      ? !!content?.performingProvider &&
        (hideTinField ||
          (content?.performingProvider?.tinList?.length === 0 ? true : !!content?.performingProviderSelectedTin))
      : false;
  }, [
    content?.performingProvider,
    content?.performingProviderSelectedAddress?.address,
    content?.performingProviderSelectedAddress?.externalReferenceId,
    content?.performingProviderSelectedTin,
    formFieldConfigurations?.performingProviderNPI?.fieldSpec,
    patient,
    hideTinField,
  ]);

  const performingProviderExceptionRequired =
    formFieldConfigurations.performingProviderOutOfNetworkExceptionReason.fieldSpec === "REQUIRED";

  const perfProviderAddressFieldsFilled: boolean = useMemo(() => {
    return formFieldConfigurations?.performingProviderAddress?.fieldSpec === "OPTIONAL"
      ? !!content?.performingProvider
        ? !!content?.performingProvider &&
          (hideTinField ||
            (content?.performingProvider?.tinList?.length === 0 ? true : !!content?.performingProviderSelectedTin))
        : true
      : formFieldConfigurations?.performingProviderAddress.fieldSpec === "REQUIRED"
      ? !!content?.performingProvider &&
        (hideTinField ||
          (content?.performingProvider?.tinList?.length === 0 ? true : !!content?.performingProviderSelectedTin)) &&
        (content?.performingProvider?.locations ? !!content?.performingProviderSelectedAddress?.address : true)
      : false;
  }, [
    content?.performingProvider,
    content?.performingProviderSelectedAddress?.address,
    content?.performingProviderSelectedTin,
    formFieldConfigurations?.performingProviderAddress.fieldSpec,
    hideTinField,
  ]);

  const requiredFieldsFilled = perfProviderFieldsFilled && perfProviderAddressFieldsFilled;
  const getPerformingProviderLocationIfNone = useCallback(
    (tin: string): Location | undefined => {
      let location: Location | undefined;
      if (
        content?.performingProvider &&
        content?.performingProvider?.locations &&
        !content?.performingProviderSelectedAddress
      ) {
        const locationsPerformingProvider = content?.performingProvider?.locations.filter((loc) => loc.tin === tin);
        location = locationsPerformingProvider[0];
      } else {
        location = {
          tin: content?.performingProviderSelectedTin ? content?.performingProviderSelectedTin : undefined,
        };
      }
      return location;
    },
    [content?.performingProvider, content?.performingProviderSelectedAddress, content?.performingProviderSelectedTin]
  );

  const oonCheckPayload: OONCheckRequest | null = useMemo(() => {
    if (!dateProperlyFormatted(content?.startDate)) {
      return null;
    }
    return {
      patientId: patient?.id,
      memberId: patient?.memberId,
      effectiveDate: formatDateToISODate(content?.startDate),
      npi: content?.performingProvider?.npi || undefined,
      providerType: content?.performingProvider?.providerType,
      location:
        content?.performingProviderSelectedAddress && content?.performingProviderSelectedAddress?.address
          ? content?.performingProviderSelectedAddress
          : content?.performingProviderSelectedTin
          ? getPerformingProviderLocationIfNone(content?.performingProviderSelectedTin)
          : {
              tin: content?.performingProviderSelectedTin || undefined,
            },
      workflowId: workflowId,
      delegatedVendorName: delegatedVendorName,
      procedureCodes: content?.procedureCodes,
      recordType: content?.selectedPerformingProvider?.type,
      source: content?.selectedPerformingProvider?.source,
    };
  }, [
    content?.performingProvider?.npi,
    content?.performingProvider?.providerType,
    content?.selectedPerformingProvider?.source,
    content?.performingProviderSelectedAddress,
    content?.performingProviderSelectedTin,
    content?.procedureCodes,
    content?.selectedPerformingProvider?.type,
    content?.startDate,
    delegatedVendorName,
    getPerformingProviderLocationIfNone,
    patient?.id,
    patient?.memberId,
    workflowId,
  ]);

  const previousPayload = usePrevious(oonCheckPayload);

  // checks if performing provider fields have been filled completely to start OON check
  useEffect(() => {
    const networkCheckFieldPreviousPayload = omitNonNetworkCheckFields(previousPayload);
    const networkCheckFieldOONCheckPayload = omitNonNetworkCheckFields(oonCheckPayload);
    const previousNetworkCheckDependentFields = networkCheckDependentFields(networkCheckFieldPreviousPayload);
    const currentNetworkCheckDependentFields = networkCheckDependentFields(networkCheckFieldOONCheckPayload);

    if (!isEqual(previousNetworkCheckDependentFields, currentNetworkCheckDependentFields)) {
      //clear out OON check data if payload has changed
      setProviderOonCheckData(undefined);
      setPerformedProviderOONCheck(false);
    }
    if (
      formFieldConfigurations?.outOfNetworkCheck?.fieldSpec !== "OMIT" &&
      requiredFieldsFilled &&
      !!oonCheckPayload &&
      !isEqual(previousNetworkCheckDependentFields, currentNetworkCheckDependentFields) &&
      (outOfNetworkCheckConfiguration.runOONCheckOnManuallyCreatedProviderFacility ||
        !content.performingProvider?.manuallyCreated) //skip for manually created provider
    ) {
      const dataProviderPromise = doProviderOonCheck(oonCheckPayload);

      const checkProviderOON = async () => {
        let providerResult: GetOutofNetworkCheckResponse | undefined;
        await asyncCallWithTimeout(Promise.all([dataProviderPromise]), networkCheckTimeout)
          .then((result) => {
            if (Object.keys(result[0]).length) {
              setProviderOonCheckData(result[0]);
              providerResult = result[0];
            } else {
              setProviderOonCheckData(undefined);
              providerResult = undefined;
            }
          })
          .catch(() => {
            // OON check timed out
            cancelDoProviderOonCheck();
            setProviderOonCheckData(undefined);
            providerResult = undefined;
          });
        if (providerResult?.networkType) {
          setFormContent((prev) => {
            return {
              ...prev,
              performingProviderSelectedAddress: {
                ...prev?.performingProviderSelectedAddress,
                isOutOfNetwork: providerResult?.networkType === "OON",
                networkType: providerResult?.networkType,
              },
              selectedPerformingProvider: {
                ...prev?.selectedPerformingProvider,
                id: prev?.selectedPerformingProvider?.id || "",
                dateCreated: prev?.selectedPerformingProvider?.dateCreated || "",
                lastUpdated: prev?.selectedPerformingProvider?.lastUpdated || "",
                selectedLocation: {
                  ...prev?.selectedPerformingProvider?.selectedLocation,
                  isOutOfNetwork: providerResult?.networkType === "OON",
                  networkType: providerResult?.networkType,
                },
                type: prev?.selectedPerformingProvider?.type || "PROVIDER",
              },
              performingProviderOONExceptionRequired: performingProviderExceptionRequired,
            };
          });
        } else if (
          providerResult?.isOutOfNetwork !== content?.performingProviderSelectedAddress?.isOutOfNetwork ||
          performingProviderExceptionRequired !== content?.performingProviderOONExceptionRequired
        ) {
          setFormContent((prev) => {
            return {
              ...prev,
              performingProviderSelectedAddress: {
                ...prev?.performingProviderSelectedAddress,
                isOutOfNetwork: providerResult?.isOutOfNetwork,
              },
              selectedPerformingProvider: {
                ...prev?.selectedPerformingProvider,
                id: prev?.selectedPerformingProvider?.id || "",
                dateCreated: prev?.selectedPerformingProvider?.dateCreated || "",
                lastUpdated: prev?.selectedPerformingProvider?.lastUpdated || "",
                selectedLocation: {
                  ...prev?.selectedPerformingProvider?.selectedLocation,
                  isOutOfNetwork: providerResult?.isOutOfNetwork,
                },
                type: prev?.selectedPerformingProvider?.type || "PROVIDER",
              },
              performingProviderOONExceptionRequired: performingProviderExceptionRequired,
            };
          });
        }
      };
      setProviderOonCheckData(undefined);
      checkProviderOON().then(() => setPerformedProviderOONCheck(true));
    }
  }, [
    content.performingProvider?.manuallyCreated,
    content?.performingProviderOONExceptionRequired,
    content?.performingProviderSelectedAddress?.isOutOfNetwork,
    doProviderOonCheck,
    formFieldConfigurations?.outOfNetworkCheck?.fieldSpec,
    performingProviderExceptionRequired,
    oonCheckPayload,
    previousPayload,
    requiredFieldsFilled,
    setFormContent,
    outOfNetworkCheckConfiguration.runOONCheckOnManuallyCreatedProviderFacility,
    cancelDoProviderOonCheck,
  ]);

  return { providerOonCheckData, providerOonCheckLoading, performedProviderOONCheck };
};

export const useOrderingProviderOutOfNetworkCheck = ({
  formFieldConfigurations,
  outOfNetworkCheckConfiguration,
  content,
  setFormContent,
  patient,
  workflowId,
  delegatedVendorName,
  hideTinField,
}: UseOutOfNetworkCheckProps) => {
  const {
    loading: orderingProviderOonCheckLoading,
    mutate: doOrderingProviderOonCheck,
    cancel: cancelDoOrderingProviderOonCheck,
  } = useGetOutofNetworkCheck({});

  // Stores the result of doOrderingProviderOonCheck once it finishes
  const [orderingProviderOonCheckData, setOrderingProviderOonCheckData] = useState<GetOutofNetworkCheckResponse>();
  const [performedOrderingProviderOONCheck, setPerformedOrderingProviderOONCheck] = useState<boolean>(false);

  const orderingProviderFieldsFilled = useMemo(() => {
    return formFieldConfigurations?.orderingProviderNPI?.fieldSpec === "OPTIONAL"
      ? content?.orderingProvider
        ? !!content?.orderingProvider &&
          (hideTinField ||
            (content?.orderingProvider?.tinList?.length === 0 ? true : !!content?.orderingProviderSelectedTin))
        : false
      : formFieldConfigurations?.orderingProviderNPI?.fieldSpec === "REQUIRED"
      ? !!content?.orderingProvider &&
        (hideTinField ||
          (content?.orderingProvider?.tinList?.length === 0 ? true : !!content?.orderingProviderSelectedTin))
      : false;
  }, [
    content?.orderingProvider,
    content?.orderingProviderSelectedTin,
    formFieldConfigurations?.orderingProviderNPI?.fieldSpec,
    hideTinField,
  ]);

  const orderingProviderAddressFieldsFilled = useMemo(() => {
    return formFieldConfigurations?.orderingProviderAddress?.fieldSpec === "OPTIONAL"
      ? !!content?.orderingProvider
        ? !!content?.orderingProvider &&
          (hideTinField ||
            (content?.orderingProvider?.tinList?.length === 0 ? true : !!content?.orderingProviderSelectedTin))
        : true
      : formFieldConfigurations?.orderingProviderAddress?.fieldSpec === "REQUIRED"
      ? !!content?.orderingProvider &&
        (hideTinField ||
          (content?.orderingProvider?.tinList?.length === 0 ? true : !!content?.orderingProviderSelectedTin)) &&
        (content?.orderingProvider?.locations ? !!content?.orderingProviderSelectedAddress?.address : true)
      : false;
  }, [
    content?.orderingProvider,
    content?.orderingProviderSelectedAddress?.address,
    content?.orderingProviderSelectedTin,
    formFieldConfigurations?.orderingProviderAddress?.fieldSpec,
    hideTinField,
  ]);

  const orderingProviderExceptionRequired =
    formFieldConfigurations.orderingProviderOutOfNetworkExceptionReason.fieldSpec === "REQUIRED";
  const requiredFieldsFilled = orderingProviderFieldsFilled && orderingProviderAddressFieldsFilled;
  const getOrderingProviderLocationIfNone = useCallback(
    (tin: string): Location | undefined => {
      let location: Location | undefined;
      if (
        content?.orderingProvider &&
        content?.orderingProvider?.locations &&
        !content?.orderingProviderSelectedAddress
      ) {
        const locationsOrderingProvider = content?.orderingProvider.locations.filter((loc) => loc.tin === tin);
        location = locationsOrderingProvider[0];
      } else {
        location = {
          tin: content?.orderingProviderSelectedTin || undefined,
        };
      }
      return location;
    },
    [content?.orderingProvider, content?.orderingProviderSelectedAddress, content?.orderingProviderSelectedTin]
  );

  const oonCheckPayload: OONCheckRequest | null = useMemo(() => {
    if (!dateProperlyFormatted(content?.startDate)) {
      return null;
    }
    return {
      patientId: patient?.id,
      memberId: patient?.memberId,
      effectiveDate: formatDateToISODate(content?.startDate),
      npi: content?.orderingProvider?.npi || undefined,
      providerType: content?.orderingProvider?.providerType,
      location:
        content?.orderingProviderSelectedAddress && content?.orderingProviderSelectedAddress?.address
          ? content?.orderingProviderSelectedAddress
          : content?.orderingProviderSelectedTin
          ? getOrderingProviderLocationIfNone(content?.orderingProviderSelectedTin)
          : {
              tin: content?.orderingProviderSelectedTin || undefined,
            },
      workflowId: workflowId,
      delegatedVendorName: delegatedVendorName,
      procedureCodes: content?.procedureCodes,
      recordType: content?.selectedOrderingProvider?.type,
      source: content?.selectedOrderingProvider?.source,
    };
  }, [
    content?.orderingProvider?.npi,
    content?.orderingProvider?.providerType,
    content?.selectedOrderingProvider?.source,
    content?.orderingProviderSelectedAddress,
    content?.orderingProviderSelectedTin,
    content?.procedureCodes,
    content?.selectedOrderingProvider?.type,
    content?.startDate,
    delegatedVendorName,
    getOrderingProviderLocationIfNone,
    patient?.id,
    patient?.memberId,
    workflowId,
  ]);

  const previousPayload = usePrevious(oonCheckPayload);

  // checks if performing ordering provider fields have been filled completely to start OON check
  useEffect(() => {
    const networkCheckFieldPreviousPayload = omitNonNetworkCheckFields(previousPayload);
    const networkCheckFieldOONCheckPayload = omitNonNetworkCheckFields(oonCheckPayload);
    const previousNetworkCheckDependentFields = networkCheckDependentFields(networkCheckFieldPreviousPayload);
    const currentNetworkCheckDependentFields = networkCheckDependentFields(networkCheckFieldOONCheckPayload);

    if (!isEqual(previousNetworkCheckDependentFields, currentNetworkCheckDependentFields)) {
      //clear out OON check data if payload has changed
      setOrderingProviderOonCheckData(undefined);
      setPerformedOrderingProviderOONCheck(false);
    }
    if (
      formFieldConfigurations?.outOfNetworkOrderingProvider?.fieldSpec !== "OMIT" &&
      requiredFieldsFilled &&
      !!oonCheckPayload &&
      !isEqual(previousNetworkCheckDependentFields, currentNetworkCheckDependentFields) &&
      (outOfNetworkCheckConfiguration.runOONCheckOnManuallyCreatedProviderFacility ||
        !content.orderingProvider?.manuallyCreated) //skip for manually created ordering provider
    ) {
      const dataOrderingProviderPromise = doOrderingProviderOonCheck(oonCheckPayload);

      const checkOrderingProviderOONWithTimeout = async () => {
        let orderingProviderResult: GetOutofNetworkCheckResponse | undefined;
        await asyncCallWithTimeout(Promise.all([dataOrderingProviderPromise]), networkCheckTimeout)
          .then((result) => {
            if (Object.keys(result[0]).length) {
              setOrderingProviderOonCheckData(result[0]);
              orderingProviderResult = result[0];
            } else {
              setOrderingProviderOonCheckData(undefined);
              orderingProviderResult = undefined;
            }
          })
          .catch(() => {
            // OON check timed out
            cancelDoOrderingProviderOonCheck();
            setOrderingProviderOonCheckData(undefined);
            orderingProviderResult = undefined;
          });
        if (orderingProviderResult?.networkType) {
          setFormContent((prev) => {
            return {
              ...prev,
              orderingProviderSelectedAddress: {
                ...prev?.orderingProviderSelectedAddress,
                isOutOfNetwork: orderingProviderResult?.networkType === "OON",
                networkType: orderingProviderResult?.networkType,
              },
              selectedOrderingProvider: {
                ...prev?.selectedOrderingProvider,
                id: prev?.selectedOrderingProvider?.id || "",
                dateCreated: prev?.selectedOrderingProvider?.dateCreated || "",
                lastUpdated: prev?.selectedOrderingProvider?.lastUpdated || "",
                selectedLocation: {
                  ...prev?.selectedOrderingProvider?.selectedLocation,
                  isOutOfNetwork: orderingProviderResult?.networkType === "OON",
                  networkType: orderingProviderResult?.networkType,
                },
                type: prev?.selectedOrderingProvider?.type || "PROVIDER",
              },
              orderingProviderOONExceptionRequired: orderingProviderExceptionRequired,
            };
          });
        } else if (
          orderingProviderResult?.isOutOfNetwork !== content?.orderingProviderSelectedAddress?.isOutOfNetwork ||
          orderingProviderExceptionRequired !== content?.orderingProviderOONExceptionRequired
        ) {
          setFormContent((prev) => {
            return {
              ...prev,
              orderingProviderSelectedAddress: {
                ...prev?.orderingProviderSelectedAddress,
                isOutOfNetwork: orderingProviderResult?.isOutOfNetwork,
              },
              selectedOrderingProvider: {
                ...prev?.selectedOrderingProvider,
                id: prev?.selectedOrderingProvider?.id || "",
                dateCreated: prev?.selectedOrderingProvider?.dateCreated || "",
                lastUpdated: prev?.selectedOrderingProvider?.lastUpdated || "",
                selectedLocation: {
                  ...prev?.selectedOrderingProvider?.selectedLocation,
                  isOutOfNetwork: orderingProviderResult?.isOutOfNetwork,
                },
                type: prev?.selectedOrderingProvider?.type || "PROVIDER",
              },
              orderingProviderOONExceptionRequired: orderingProviderExceptionRequired,
            };
          });
        }
      };
      setOrderingProviderOonCheckData(undefined);
      checkOrderingProviderOONWithTimeout().then(() => setPerformedOrderingProviderOONCheck(true));
    }
  }, [
    cancelDoOrderingProviderOonCheck,
    content.orderingProvider?.manuallyCreated,
    content?.orderingProviderOONExceptionRequired,
    content?.orderingProviderSelectedAddress?.isOutOfNetwork,
    doOrderingProviderOonCheck,
    formFieldConfigurations?.outOfNetworkOrderingProvider?.fieldSpec,
    oonCheckPayload,
    orderingProviderExceptionRequired,
    outOfNetworkCheckConfiguration.runOONCheckOnManuallyCreatedProviderFacility,
    previousPayload,
    requiredFieldsFilled,
    setFormContent,
  ]);

  return { orderingProviderOonCheckData, orderingProviderOonCheckLoading, performedOrderingProviderOONCheck };
};
export const useCareParticipantOutOfNetworkCheck = ({
  formFieldConfigurations,
  outOfNetworkCheckConfiguration,
  content,
  careParticipant,
  setFormContent,
  patient,
  workflowId,
  delegatedVendorName,
  rowIndex,
  hideTinField,
}: UseCareParticipantOutOfNetworkCheckProps) => {
  const {
    loading: careParticipantOonCheckLoading,
    mutate: doCareParticipantOonCheck,
    cancel: cancelDoCareParticipantOonCheck,
  } = useGetOutofNetworkCheck({});
  const [careParticipantOonCheckData, setCareParticipantOonCheckData] = useState<GetOutofNetworkCheckResponse>();
  const [performedCareParticipantOonCheck, setPerformedCareParticipantOonCheck] = useState<boolean>(false);
  const isNpiFieldOptional = getFieldSpecForAdditionalCareParticipant(careParticipant, "npi") === "OPTIONAL";
  const isNpiFieldRequired = getFieldSpecForAdditionalCareParticipant(careParticipant, "npi") === "REQUIRED";
  const isAddressFieldOptional = getFieldSpecForAdditionalCareParticipant(careParticipant, "address") === "OPTIONAL";
  const isAddressFieldRequired = getFieldSpecForAdditionalCareParticipant(careParticipant, "address") === "REQUIRED";
  const isSelectedTinAvailable = !!careParticipant.selectedTin;
  const isSelectedAddressAvailable = !!careParticipant.selectedLocation?.address;
  const isExternalReferenceIdAvailable = !!careParticipant?.selectedLocation?.externalReferenceId;
  const isTinListEmpty = careParticipant?.tinList?.length === 0;

  const careParticipantFieldsFilled: boolean = isNpiFieldOptional
    ? !!content.additionalCareParticipants
      ? isSelectedTinAvailable
        ? !!careParticipant && isSelectedTinAvailable && isSelectedAddressAvailable && isExternalReferenceIdAvailable
        : !!careParticipant && (hideTinField || isTinListEmpty ? true : isSelectedTinAvailable)
      : false
    : isNpiFieldRequired
    ? !!careParticipant &&
      !!careParticipant.npi &&
      (hideTinField || isTinListEmpty ? true : !!careParticipant.selectedTin)
    : false;
  const careParticipantAddressFieldsFilled: boolean = isAddressFieldOptional
    ? !!careParticipant
      ? isTinListEmpty || hideTinField
        ? true
        : isSelectedTinAvailable
      : true
    : isAddressFieldRequired
    ? !!careParticipant && isSelectedTinAvailable && (careParticipant?.locations ? isSelectedAddressAvailable : true)
    : false;
  const requiredFieldsFilled = careParticipantFieldsFilled && careParticipantAddressFieldsFilled;

  const getCareParticipantLocationIfNone = useCallback(
    (tin: string): Location | undefined => {
      let location: Location | undefined;
      if (careParticipant && careParticipant?.locations && !careParticipant?.selectedLocation) {
        const locationsCareParticipant = careParticipant.locations.filter((loc) => loc.tin === tin);
        location = locationsCareParticipant[0];
      } else {
        location = {
          tin: careParticipant?.selectedTin ? careParticipant?.selectedTin : undefined,
        };
      }
      return location;
    },
    [careParticipant]
  );

  const oonCheckPayload: OONCheckRequest | null = useMemo(() => {
    if (!dateProperlyFormatted(content?.startDate)) {
      return null;
    }
    return {
      patientId: patient?.id,
      memberId: patient?.memberId,
      effectiveDate: formatDateToISODate(content?.startDate),
      npi: careParticipant?.npi || undefined,
      providerType: careParticipant?.providerType,
      location:
        careParticipant?.selectedLocation && careParticipant?.selectedLocation?.address
          ? careParticipant?.selectedLocation
          : careParticipant?.selectedTin
          ? getCareParticipantLocationIfNone(careParticipant?.selectedTin)
          : {
              tin: careParticipant?.selectedTin || undefined,
            },
      workflowId: workflowId,
      delegatedVendorName: delegatedVendorName,
      procedureCodes: content?.procedureCodes,
      source: careParticipant?.source,
    };
  }, [
    careParticipant?.npi,
    careParticipant?.providerType,
    careParticipant?.selectedLocation,
    careParticipant?.selectedTin,
    careParticipant?.source,
    content?.procedureCodes,
    content?.startDate,
    delegatedVendorName,
    getCareParticipantLocationIfNone,
    patient?.id,
    patient?.memberId,
    workflowId,
  ]);

  const previousPayload = usePrevious(oonCheckPayload);

  useEffect(() => {
    const networkCheckFieldPreviousPayload = omitNonNetworkCheckFields(previousPayload);
    const networkCheckFieldOONCheckPayload = omitNonNetworkCheckFields(oonCheckPayload);
    const previousNetworkCheckDependentFields = networkCheckDependentFields(networkCheckFieldPreviousPayload);
    const currentNetworkCheckDependentFields = networkCheckDependentFields(networkCheckFieldOONCheckPayload);

    if (!isEqual(previousNetworkCheckDependentFields, currentNetworkCheckDependentFields)) {
      setCareParticipantOonCheckData(undefined);
      setPerformedCareParticipantOonCheck(false);
    }
    if (
      formFieldConfigurations?.outOfNetworkCheck?.fieldSpec !== "OMIT" &&
      requiredFieldsFilled &&
      !!oonCheckPayload &&
      !isEqual(previousNetworkCheckDependentFields, currentNetworkCheckDependentFields) &&
      (outOfNetworkCheckConfiguration.runOONCheckOnManuallyCreatedProviderFacility || !careParticipant?.manuallyCreated)
    ) {
      const dataCareParticipantPromise = doCareParticipantOonCheck(oonCheckPayload);
      const checkCareParticipantOONWithTimeout = async () => {
        let careParticipantResult: GetOutofNetworkCheckResponse | undefined;
        await asyncCallWithTimeout(Promise.all([dataCareParticipantPromise]), networkCheckTimeout)
          .then((result) => {
            if (Object.keys(result[0]).length) {
              setCareParticipantOonCheckData(result[0]);
              careParticipantResult = result[0];
            } else {
              // OON check timed out
              cancelDoCareParticipantOonCheck();
              setCareParticipantOonCheckData(undefined);
              careParticipantResult = undefined;
            }
          })
          .catch(() => {
            setCareParticipantOonCheckData(undefined);
            careParticipantResult = undefined;
          });
        if (careParticipantResult?.networkType) {
          setFormContent((prev) => {
            return {
              ...prev,
              additionalCareParticipants: prev.additionalCareParticipants?.map((careParticipant, i) =>
                i === rowIndex
                  ? {
                      ...careParticipant,
                      id: prev.additionalCareParticipants?.at(rowIndex)?.id || "",
                      dateCreated: prev.additionalCareParticipants?.at(rowIndex)?.dateCreated || "",
                      lastUpdated: prev.additionalCareParticipants?.at(rowIndex)?.lastUpdated || "",
                      selectedLocation: {
                        ...prev.additionalCareParticipants?.at(rowIndex)?.selectedLocation,
                        isOutOfNetwork: careParticipantResult?.networkType === "OON",
                        networkType: careParticipantResult?.networkType,
                      },
                      type: prev.additionalCareParticipants?.at(rowIndex)?.type,
                      careParticipantOONExceptionRequired: careParticipantResult?.isExceptionRequired,
                    }
                  : careParticipant
              ),
            };
          });
        } else if (
          careParticipantResult?.isOutOfNetwork !== careParticipant?.selectedLocation?.isOutOfNetwork ||
          careParticipantResult?.isExceptionRequired !== careParticipant?.careParticipantOONExceptionRequired
        ) {
          setFormContent((prev) => {
            return {
              ...prev,
              additionalCareParticipants: prev.additionalCareParticipants?.map((careParticipant, i) =>
                i === rowIndex
                  ? {
                      ...careParticipant,
                      id: prev.additionalCareParticipants?.at(rowIndex)?.id || "",
                      dateCreated: prev.additionalCareParticipants?.at(rowIndex)?.dateCreated || "",
                      lastUpdated: prev.additionalCareParticipants?.at(rowIndex)?.lastUpdated || "",
                      selectedLocation: {
                        ...prev.additionalCareParticipants?.at(rowIndex)?.selectedLocation,
                        isOutOfNetwork: careParticipantResult?.isOutOfNetwork,
                      },
                      type: prev.additionalCareParticipants?.at(rowIndex)?.type,
                      careParticipantOONExceptionRequired: careParticipantResult?.isExceptionRequired,
                    }
                  : careParticipant
              ),
            };
          });
        }
      };
      setCareParticipantOonCheckData(undefined);
      checkCareParticipantOONWithTimeout().then(() => setPerformedCareParticipantOonCheck(true));
    }
  }, [
    cancelDoCareParticipantOonCheck,
    careParticipant?.careParticipantOONExceptionRequired,
    careParticipant?.manuallyCreated,
    careParticipant?.selectedLocation?.isOutOfNetwork,
    doCareParticipantOonCheck,
    formFieldConfigurations?.outOfNetworkCheck?.fieldSpec,
    oonCheckPayload,
    outOfNetworkCheckConfiguration.runOONCheckOnManuallyCreatedProviderFacility,
    previousPayload,
    requiredFieldsFilled,
    rowIndex,
    setFormContent,
  ]);

  return { careParticipantOonCheckData, careParticipantOonCheckLoading, performedCareParticipantOonCheck };
};

export function saveHomeHealthToStorage(priorAuthRequirements: PriorAuthRequirements) {
  // Right before continue, save HomeHealth selection to local storage
  if (priorAuthRequirements.homeHealth !== undefined) {
    const serializedHomeHealth = JSON.stringify(priorAuthRequirements.homeHealth);
    localStorage.setItem("lastHomeHealthSelection", serializedHomeHealth);
  }
}

export const procedureBucketDataModel = (
  procedureCodes: ProcedureCodeWithId[],
  filterProcedureCodes?: ProcedureCode[]
) => {
  let result: ProcedureCodeWithId[] = [];
  if (filterProcedureCodes) {
    result = procedureCodes.filter((pxCode) => {
      return filterProcedureCodes.find((filteredCode) => {
        return pxCode.code === filteredCode.code;
      });
    });
  } else {
    result = procedureCodes;
  }
  const servicesWithAssociatedCodes = new Map<string, ProcedureCodeWithId[]>();
  result.forEach((px) => {
    if (px.groupBy === "ClinicalService" && px.groupId) {
      if (servicesWithAssociatedCodes.get(px.groupId)) {
        servicesWithAssociatedCodes.get(px.groupId)?.push(px);
      } else {
        servicesWithAssociatedCodes.set(px.groupId, [px]);
      }
    } else {
      const uncategorizedServiceArray = servicesWithAssociatedCodes.get("Uncategorized Service");
      if (uncategorizedServiceArray) {
        const foundPxIndex: number = uncategorizedServiceArray.findIndex((item) => item.code === px.code);
        if (foundPxIndex !== -1) {
          const foundPx: ProcedureCodeWithId = uncategorizedServiceArray[foundPxIndex];
          foundPx.units = (foundPx.units ? foundPx.units : 0) + (px.units ? px.units : 0);
          if (foundPx.approvedUnits || px.approvedUnits) {
            foundPx.approvedUnits =
              (foundPx.approvedUnits ? foundPx.approvedUnits : 0) + (px.approvedUnits ? px.approvedUnits : 0);
          }

          uncategorizedServiceArray[foundPxIndex] = foundPx;
        } else {
          uncategorizedServiceArray.push(px);
        }
      } else {
        servicesWithAssociatedCodes.set("Uncategorized Service", [px]);
      }
    }
  });
  return servicesWithAssociatedCodes;
};

export interface ContinueToFillFormsFlexProps extends AuthBuilderContextParams {
  priorAuthRequirements: PriorAuthRequirements;
  palProcedureCodes: ProcedureCode[];
  setServiceRequestFormContents: Dispatch<SetStateAction<ServiceRequestFormContent[]>>;
  setServiceRequestFormsCanBeSubmitted: Dispatch<boolean[]>;
  setServiceRequestFormsHaveNewEdits: Dispatch<boolean[]>;
  setWorkflowStep: Dispatch<AuthBuilderWorkflowStep>;
  facilityBasedFeatureEnabled?: boolean;
}

export function useContinueToFillFormsFlex({
  priorAuthRequirements,
  palProcedureCodes,
  setServiceRequestFormContents,
  setServiceRequestFormsCanBeSubmitted,
  setServiceRequestFormsHaveNewEdits,
  setWorkflowStep,
  facilityBasedFeatureEnabled,
  ...authBuilderContext
}: ContinueToFillFormsFlexProps) {
  const featureFlagWorkflowStep = "PROVIDER_DETAILS";

  return async (buckets: ServiceDeterminationProcedureCodeBucket[]) => {
    const isInpatient = priorAuthRequirements.encounterType === "INPATIENT";
    const procedureCodes: ProcedureCode[] = [];
    const allClinicalServices: ClinicalService[] = [];
    const nonPxClinicalServiceIds: string[] = [];
    if (buckets.length > 0) {
      buckets.forEach((bucket, bucketIndex) => {
        const desiredProcedureCodes =
          bucket && bucket.procedureCodes
            ? bucket.procedureCodes
            : isInpatient
            ? priorAuthRequirements.desiredProcedureCodes || []
            : palProcedureCodes;
        /* Preprocessing procedure code list details */
        const desiredServiceLevelUnits = bucket.clinicalService?.defaultUnits
          ? `${bucket.clinicalService?.defaultUnits}`
          : "1";
        const groupedPxCodes = constructProcedureCodes({
          procedureCodes: desiredProcedureCodes,
          isInpatient: isInpatient,
          serviceLevelUnits: desiredServiceLevelUnits,
          clinicalServiceId: bucket?.clinicalService?.id || "",
          isUnitsOnPx: true,
        });

        procedureCodes.push(...groupedPxCodes);

        if (bucket.clinicalService) {
          allClinicalServices.push(bucket.clinicalService);
        }

        // add clinicalService Ids to nonPxClinicalServiceIds if there are no codes in the bucket. codes is [] or undefined meaning this clinicalService's are related to AuthCategory
        if (
          (bucket.procedureCodes === undefined || bucket.procedureCodes?.length === 0) &&
          bucket.clinicalService &&
          bucket.clinicalService?.id
        ) {
          nonPxClinicalServiceIds.push(bucket.clinicalService.id);
        }
      });
    }

    const carePathBucket = buckets.find((bucket) => {
      if (bucket.carePath) {
        return bucket;
      } else {
        return undefined;
      }
    });

    setServiceRequestFormContents((prevServiceRequestFormContents) => {
      const currentUpdatedServiceRequestForm = {
        ...prevServiceRequestFormContents[0], // ??This ensures that the previous serviceRequestFormContents are retained and updated with new data
        ...formContentFromAuthBuilderContext({
          priorAuthRequirements,
          procedureCodes,
          carePath: carePathBucket?.carePath ?? undefined,
          clinicalServices: allClinicalServices && allClinicalServices.length > 0 ? allClinicalServices : undefined,
          previousFormContent: prevServiceRequestFormContents ? prevServiceRequestFormContents[0] : undefined,
          facilityBasedFeatureEnabled,
          nonPxClinicalServiceIds,
          ...authBuilderContext,
        }),
      };
      return [currentUpdatedServiceRequestForm];
    });
    setServiceRequestFormsCanBeSubmitted([false]);
    setServiceRequestFormsHaveNewEdits([true]);
    saveHomeHealthToStorage(priorAuthRequirements);
    setWorkflowStep(featureFlagWorkflowStep);
  };
}

export const omitNonNetworkCheckFields = (checkRequest: OONCheckRequest | null | undefined) => {
  return {
    ...omit(checkRequest, ["workflowId", "procedureCodes"]),
    location: omit(checkRequest?.location, ["isOutOfNetwork", "networkType"]),
  };
};

export const onCareTypeSwitch = (
  isInpatientStr: string,
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>
) => {
  setFormContent((prev) => ({
    ...prev,
    isInpatient: isInpatientStr === "true",
    placeOfService: null,
    patientStatus: isInpatientStr === "true" ? "NOT_YET_ADMITTED" : undefined,
  }));
};

interface UseCreateRequirementsPalCheckProps {
  setAttemptedSubmit?: Dispatch<boolean>;
  canCheckPAL: boolean;
  patientId?: string;
  scrollToPALCheck?: () => Promise<void>;
  setPriorAuthRequirements: Dispatch<SetStateAction<PriorAuthRequirements>>;
  noPxService?: ClinicalService;
  setPalProcedureCodes: Dispatch<ProcedureCode[]>;
  setNonPalProcedureCodes: Dispatch<ProcedureCode[]>;
  setNonCohereCodes: Dispatch<NonCohereCodes[]>;
  patientData: Patient | null;
  setCrdLogId?: (newCrdLogId: string) => Promise<void>;
  setCrdProcedureCodeResults?: Dispatch<CardExtensionProcedureCode[] | undefined>;
  setCrdExtensionCardResults?: Dispatch<CardExtensionDetails | undefined>;
  serviceRequestFormContents?: ServiceRequestFormContent[];
}

const isGeisingerAuthorization = (patientData: Patient | null) => {
  if (!patientData) {
    return false;
  }
  if (!patientData.coverages) {
    return false;
  }
  if (patientData.coverages && patientData.coverages.length === 0) {
    return false;
  }
  return getCurrentPrimaryCoverage(patientData)?.healthPlanName === GEISINGER;
};

interface UseSetCrdLogIdAndUpdateServiceRequestProps {
  setCrdLogId: Dispatch<string>;
  serviceRequestId?: string;
  updateServiceRequest: ReturnType<typeof useUpdateServiceRequest>["mutate"];
}
export function useSetCrdLogIdAndUpdateServiceRequest({
  setCrdLogId,
  serviceRequestId,
  updateServiceRequest,
}: UseSetCrdLogIdAndUpdateServiceRequestProps): UseCreateRequirementsPalCheckProps["setCrdLogId"] {
  return useCallback(
    (newCrdLogId: string) => {
      setCrdLogId(newCrdLogId);
      if (serviceRequestId) {
        return updateServiceRequest(
          {
            crdLogId: newCrdLogId,
          },
          { pathParams: { id: serviceRequestId } }
        ).then(
          () => {},
          (error) => {
            logError(
              `Could not save crdLogId ${newCrdLogId} onto service request ${serviceRequestId}: ${JSON.stringify(
                error
              )}`
            );
          }
        );
      } else {
        return Promise.resolve();
      }
    },
    [setCrdLogId, serviceRequestId, updateServiceRequest]
  );
}

export const useDoPalCheck = ({
  setAttemptedSubmit,
  canCheckPAL,
  patientId,
  patientData,
  scrollToPALCheck,
  setPriorAuthRequirements,
  noPxService,
  setPalProcedureCodes,
  setNonPalProcedureCodes,
  setNonCohereCodes,
  setCrdLogId,
  setCrdProcedureCodeResults,
  setCrdExtensionCardResults,
  serviceRequestFormContents,
}: UseCreateRequirementsPalCheckProps) => {
  const replacePalCheckWithCrdCheckEnabled = useFeature("replacePalCheckWithCrdCheck");
  const callCrdCheckEndpoint = useCallCrdEndpoint({
    setAttemptedSubmit,
    canCheckPAL,
    patientId,
    patientData,
    scrollToPALCheck,
    setPriorAuthRequirements,
    noPxService,
    setPalProcedureCodes,
    setNonPalProcedureCodes,
    setNonCohereCodes,
    setCrdLogId,
    setCrdProcedureCodeResults,
    setCrdExtensionCardResults,
    serviceRequestFormContents,
  });
  const callPalCheckEndpoint = useCallPalCheckEndpoint({
    setAttemptedSubmit,
    canCheckPAL,
    patientId,
    patientData,
    scrollToPALCheck,
    setPriorAuthRequirements,
    noPxService,
    setPalProcedureCodes,
    setNonPalProcedureCodes,
    setNonCohereCodes,
  });
  if (replacePalCheckWithCrdCheckEnabled && !isGeisingerAuthorization(patientData)) {
    return callCrdCheckEndpoint;
  } else {
    return callPalCheckEndpoint;
  }
};

export type UseDoPalCheckResponse = ReturnType<typeof useDoPalCheck>;

const useCallPalCheckEndpoint = ({
  setAttemptedSubmit,
  canCheckPAL,
  patientId,
  patientData,
  scrollToPALCheck,
  setPriorAuthRequirements,
  noPxService,
  setPalProcedureCodes,
  setNonPalProcedureCodes,
  setNonCohereCodes,
}: UseCreateRequirementsPalCheckProps) => {
  const authSubmissionVendorOnPalCheckEnabled = useFeature("authSubmissionVendorOnPalCheckEnabled");
  const { mutate: palCheckMutate, loading: palCheckLoading, error: palCheckError } = usePalCheck({});

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (palCheckError) {
      enqueueSnackbar("Failed to check requirements, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [palCheckError, enqueueSnackbar]);
  const doPalCheck = useCallback(
    async (currentPriorAuthRequirements: PriorAuthRequirements) => {
      setAttemptedSubmit?.(true);
      if (canCheckPAL && !!patientId) {
        if (currentPriorAuthRequirements.noPxServiceRequired && noPxService) {
          setPriorAuthRequirements((prev) => ({ ...prev, noPxService: noPxService }));
        } else {
          const response = await palCheckMutate(getPayloadForPALCheck(currentPriorAuthRequirements, patientId));
          await scrollToPALCheck?.();

          if (response) {
            //The response only returns list of codes as strings, so we need to get the full codes
            let palCodes: string[] = [];
            let nonPalCodes: string[] = [];
            let nonCohereCodes = new Map<string, string[]>();
            filterToAuthBucket(
              palCodes,
              nonPalCodes,
              nonCohereCodes,
              authSubmissionVendorOnPalCheckEnabled,
              response.pxCodes
            );

            const withPriorAuthProcedureCodes = currentPriorAuthRequirements.desiredProcedureCodes?.filter((px) =>
              palCodes.includes(px?.code || "")
            );
            const noPriorAuthProcedureCodes = currentPriorAuthRequirements.desiredProcedureCodes?.filter((px) =>
              nonPalCodes.includes(px?.code || "")
            );
            const nonCoherePxCodes = filterToCodesAndVendor(
              nonCohereCodes,
              currentPriorAuthRequirements.desiredProcedureCodes || []
            );
            //Now we can set our state
            setPalProcedureCodes(withPriorAuthProcedureCodes || []);
            setNonPalProcedureCodes(noPriorAuthProcedureCodes?.map(withDefaultUnits) || []);
            setNonCohereCodes(nonCoherePxCodes);
          }
        }
      }
    },
    [
      authSubmissionVendorOnPalCheckEnabled,
      canCheckPAL,
      noPxService,
      palCheckMutate,
      patientId,
      scrollToPALCheck,
      setAttemptedSubmit,
      setNonCohereCodes,
      setNonPalProcedureCodes,
      setPalProcedureCodes,
      setPriorAuthRequirements,
    ]
  );

  return { doPalCheck, palCheckLoading };
};

type PalCheckExtendedProps = {
  serviceRequestFormContents?: ServiceRequestFormContent;
};

const useCallCrdEndpoint = ({
  setAttemptedSubmit,
  canCheckPAL,
  patientId,
  patientData,
  scrollToPALCheck,
  setPriorAuthRequirements,
  noPxService,
  setPalProcedureCodes,
  setNonPalProcedureCodes,
  setNonCohereCodes,
  setCrdLogId,
  setCrdProcedureCodeResults,
  setCrdExtensionCardResults,
  serviceRequestFormContents,
}: UseCreateRequirementsPalCheckProps) => {
  const authSubmissionVendorOnPalCheckEnabled = useFeature("authSubmissionVendorOnPalCheckEnabled");
  const { mutate: crdCheckMutate, loading: crdCheckLoading } = useCrdCheck({});
  const { mutate: palCheckMutate, loading: palCheckLoading, error: palCheckError } = usePalCheck({});
  const serviceRequestFormContent =
    serviceRequestFormContents && serviceRequestFormContents.length > 0 && serviceRequestFormContents[0]
      ? serviceRequestFormContents[0]
      : undefined;
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (palCheckError) {
      enqueueSnackbar("Failed to check requirements, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [palCheckError, enqueueSnackbar]);

  const doPalCheck = useCallback(
    async (currentPriorAuthRequirements: PriorAuthRequirements & PalCheckExtendedProps) => {
      setAttemptedSubmit?.(true);

      let palResponse: any;
      let crdResponse: any;
      let crdBody: any;
      let typedCrdResponse: CdsResponse = {};
      let crdLogId = "";
      let palCodes: string[] = [];
      let nonPalCodes: string[] = [];
      let nonCohereCodes = new Map<string, string[]>();
      if (canCheckPAL && !!patientId) {
        if (currentPriorAuthRequirements.noPxServiceRequired && noPxService) {
          setPriorAuthRequirements((prev) => ({ ...prev, noPxService: noPxService }));
        } else {
          try {
            typedCrdResponse = await crdCheckMutate(
              getPayloadForCRDCheck(
                currentPriorAuthRequirements,
                patientData,
                serviceRequestFormContent ?? currentPriorAuthRequirements?.serviceRequestFormContents
              )
            );
            crdResponse = typedCrdResponse;
            try {
              crdBody = await crdResponse.json();
            } catch (error: any) {
              crdBody = crdResponse;
            }
            crdLogId = crdBody?.extension?.crdLogId;
          } catch (error: any) {
            crdBody = error?.data?.response;
            crdLogId = error?.data?.crdLogId;
            palResponse = await palCheckMutate(getPayloadForPALCheck(currentPriorAuthRequirements, patientId));
            await scrollToPALCheck?.();
            if (palResponse) {
              //The response only returns list of codes as strings, so we need to get the full codes
              filterToAuthBucket(
                palCodes,
                nonPalCodes,
                nonCohereCodes,
                authSubmissionVendorOnPalCheckEnabled,
                palResponse.pxCodes
              );

              const withPriorAuthProcedureCodes = currentPriorAuthRequirements.desiredProcedureCodes?.filter((px) =>
                palCodes.includes(px?.code || "")
              );
              const noPriorAuthProcedureCodes = currentPriorAuthRequirements.desiredProcedureCodes?.filter((px) =>
                nonPalCodes.includes(px?.code || "")
              );
              const nonCoherePxCodes = filterToCodesAndVendor(
                nonCohereCodes,
                currentPriorAuthRequirements.desiredProcedureCodes || []
              );
              //Now we can set our state
              setPalProcedureCodes(withPriorAuthProcedureCodes || []);
              setNonPalProcedureCodes(noPriorAuthProcedureCodes?.map(withDefaultUnits) || []);
              setNonCohereCodes(nonCoherePxCodes);
            }
          }
          await scrollToPALCheck?.();
          if (crdBody && crdBody?.extension) {
            filterToAuthBucketCrd(
              palCodes,
              nonPalCodes,
              nonCohereCodes,
              authSubmissionVendorOnPalCheckEnabled,
              crdBody.extension.procedureCodes
            );
            setCrdProcedureCodeResults?.(typedCrdResponse?.extension?.procedureCodes);
            setCrdExtensionCardResults?.(typedCrdResponse?.extension?.extensionDetails);
          }

          if (crdLogId) {
            if (setCrdLogId) {
              await setCrdLogId(crdLogId);
            }
          }
        }
      }
      const withPriorAuthProcedureCodes = currentPriorAuthRequirements.desiredProcedureCodes?.filter((px) =>
        palCodes.includes(px?.code || "")
      );
      const noPriorAuthProcedureCodes = currentPriorAuthRequirements.desiredProcedureCodes?.filter((px) =>
        nonPalCodes.includes(px?.code || "")
      );
      const nonCoherePxCodes = filterToCodesAndVendor(
        nonCohereCodes,
        currentPriorAuthRequirements.desiredProcedureCodes || []
      );
      //Now we can set our state
      setPalProcedureCodes(withPriorAuthProcedureCodes || []);
      setNonPalProcedureCodes(noPriorAuthProcedureCodes?.map(withDefaultUnits) || []);
      setNonCohereCodes(nonCoherePxCodes);
    },
    [
      authSubmissionVendorOnPalCheckEnabled,
      canCheckPAL,
      noPxService,
      crdCheckMutate,
      palCheckMutate,
      patientId,
      patientData,
      scrollToPALCheck,
      setAttemptedSubmit,
      setNonCohereCodes,
      setNonPalProcedureCodes,
      setPalProcedureCodes,
      setPriorAuthRequirements,
      setCrdLogId,
      setCrdProcedureCodeResults,
      setCrdExtensionCardResults,
      serviceRequestFormContent,
    ]
  );
  const loading = palCheckLoading || crdCheckLoading;
  return { doPalCheck, palCheckLoading: loading };
};

const patientSummaryPath = (patientId: string): string => {
  return `${generatePath(routes.PATIENT_SUMMARY, {
    patientId: patientId,
  })}`;
};

const smartOnFhirServiceRequestSummaryPath = (serviceRequestId: string): string => {
  return `${generatePath(routes.SMART_ON_FHIR_SERVICE_REQUEST, {
    serviceRequestId: serviceRequestId,
  })}`;
};

const serviceRequestSummaryPath = (serviceRequestId: string): string => {
  return `${generatePath(routes.SERVICE_REQUEST, {
    serviceRequestId: serviceRequestId,
  })}`;
};

const patientSummarySearch = (serviceRequestId: string): string => {
  return `?${stringifyQueryString({ reviewServiceRequestId: serviceRequestId })}`;
};

export const authBuilderPath = (patientId: string | undefined): string => {
  return `${generatePath(routes.AUTH_BUILDER, {
    patientId: patientId,
  })}`;
};

export const faxAuthBuilderPath = (faxId: string, patientId: string | undefined): string => {
  return `${generatePath(routes.FAX_AUTH_BUILDER, {
    faxId: faxId,
    patientId: patientId,
  })}`;
};

export const faxAttachmentPath = (faxId: string): string => {
  return `${generatePath(routes.FAX, {
    faxId: faxId,
  })}`;
};

export const smartOnFhirAuthBuilderPath = (patientId: string | undefined): string => {
  return `${generatePath(routes.SMART_ON_FHIR_AUTH_BUILDER, {
    patientId: patientId,
  })}`;
};

// Navigates to the patient summary page (/patient_summary/:id)
export function navigateToPS(
  patientId: string,
  navigate: (to: To, options?: NavigateOptions | undefined) => void,
  serviceRequestId?: string
) {
  const pathname = patientSummaryPath(patientId);
  const search = serviceRequestId ? patientSummarySearch(serviceRequestId) : undefined;
  navigate({
    pathname,
    search,
  });
}

// Navigates to the service request summary page (/service_request/:id or /smart_on_fhir_service_request/:id)
export function navigateToSRSummary(
  serviceRequestId: string,
  navigate: (to: To, options?: NavigateOptions | undefined) => void,
  inSmartOnFhirWorkflow?: boolean
) {
  const pathname = inSmartOnFhirWorkflow
    ? smartOnFhirServiceRequestSummaryPath(serviceRequestId)
    : serviceRequestSummaryPath(serviceRequestId);
  navigate({
    pathname,
  });
}

export function servRequestFailedSnackbar(
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey
) {
  enqueueSnackbar("Failed to create service request, please try again", {
    variant: "error",
    preventDuplicate: true,
  });
}

export function convertStringToAuthBuilderWorkflowStep(str: string): AuthBuilderWorkflowStep {
  switch (str) {
    case "GATHER_REQUIREMENTS_MEDICAL":
      return "GATHER_REQUIREMENTS_MEDICAL";
    case "FILL_FORMS_CONTINUATION":
      return "FILL_FORMS_CONTINUATION";
    case "FILL_FORMS_FAX":
      //TODO: clean up by June 1, 2023 -- COH - 3422
      return "FILL_FORMS_FAX";
    case "CLINICAL_ASSESSMENT":
      return "CLINICAL_ASSESSMENT";
    case "VENDOR_CLINICAL_ASSESSMENT":
      return "VENDOR_CLINICAL_ASSESSMENT";
    case "ADD_ATTACHMENTS":
      return "ADD_ATTACHMENTS";
    case "REVIEW_NUDGES":
      return "REVIEW_NUDGES";
    case "REVIEW":
      return "REVIEW";
    case "ADD_ATTACHMENTS_CONTINUATION":
      return "ADD_ATTACHMENTS_CONTINUATION";
    case "CLINICAL_ASSESSMENT_CONTINUATION":
      return "CLINICAL_ASSESSMENT_CONTINUATION";
    case "REVIEW_CONTINUATION":
      return "REVIEW_CONTINUATION";
    case "FILL_FORMS_GENERAL_AUTH_SUBMISSION":
      return "FILL_FORMS_GENERAL_AUTH_SUBMISSION";
    case "PROVIDER_DETAILS":
      return "PROVIDER_DETAILS";
    case "SERVICE_DETAILS":
      return "SERVICE_DETAILS";
    case "SELECT_SERVICES":
      return "SELECT_SERVICES";
    case "PRE_APPROVED_CARE_DETAILS":
      return "PRE_APPROVED_CARE_DETAILS";
    default:
      logWarning("defaulting to fill forms. got invalid input ", str);
      return "GATHER_REQUIREMENTS_MEDICAL";
  }
}

export const useGetFaxAttachmentFromUrl = () => {
  const { faxId: paramFaxId } = useParams();
  const location = useLocation();
  const search = useMemo(() => new URLSearchParams(location.search), [location]);
  const queryFaxId = search.get("faxId");
  const faxId = queryFaxId ?? paramFaxId ?? "";

  const canAccessFaxAttachment = useAuthorized("VIEW_INCOMING_FAXES");

  const shouldFetchFaxData = canAccessFaxAttachment && Boolean(faxId);

  const [faxAttachment, setFaxAttachment] = useState<FaxAttachment | null>(null);

  const { refetch: refetchFaxData } = useGetFaxAttachment({ id: faxId || "", lazy: true });

  useEffect(() => {
    const fetchFaxAttachment = async () => {
      const faxAttachmentData = await refetchFaxData({ pathParams: { id: faxId } });
      setFaxAttachment(faxAttachmentData);
    };

    if (shouldFetchFaxData) {
      fetchFaxAttachment();
    }
  }, [faxId, refetchFaxData, shouldFetchFaxData]);

  return faxAttachment;
};

export const calculateInitializedEndDate = (
  startDate: Date,
  clinicalServices: (ClinicalService | null)[],
  coverages: Coverage[]
) => {
  const maxServiceConfiguredDuration = Math.max(...clinicalServices?.map((cs) => cs?.defaultDuration || 0));
  const maxServiceEndDate = plusDays(maxServiceConfiguredDuration, startDate);
  const patientCoverageDates = getCombinedCoverageRanges(coverages);
  if (coverages.length !== 0) {
    const coverageEndDate = new Date(patientCoverageDates[patientCoverageDates.length - 1].end);
    if (maxServiceConfiguredDuration > 0) {
      return isAfter(maxServiceEndDate, coverageEndDate) ? coverageEndDate : maxServiceEndDate;
    } else {
      return null;
    }
  } else {
    if (maxServiceConfiguredDuration > 0) {
      return maxServiceEndDate;
    } else {
      return null;
    }
  }
};

export const getSortedClinicalServices = (clinicalServices: ClinicalService[]) => {
  return clinicalServices.sort((a, b) => (a?.id || "").localeCompare(b?.id || ""));
};
