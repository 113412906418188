import React, { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useGetServiceRequestAuditHistory, Author } from "@coherehealth/audit-api";
import { H6, convertEpochToEastern, convertEpochToTimeStamps } from "@coherehealth/common";
import { useSnackbar } from "notistack";
import { useAuthorized } from "authorization";
import config from "api/config";
interface HistoryProps {
  serviceRequestId: string;
}

export default function ServiceRequestAuditHistory({ serviceRequestId }: HistoryProps) {
  const { data, error, loading } = useGetServiceRequestAuditHistory({
    base: `${config.AUDIT_SERVICE_API_URL}`,
    srId: serviceRequestId,
    queryParams: { embed_users: "true" },
  });

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (error) {
      enqueueSnackbar(`Error retrieving audit history: ${error.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, error]);

  if (!useAuthorized("VIEW_SERVICE_REQUEST_AUDIT_LOG")) {
    return null;
  }

  if (loading) {
    return <CircularProgress />;
  }
  if (!data) {
    return null;
  }

  const updateDescriptionEpochConv: string[] = [
    "updatedFields:lastUpdated",
    "updatedFields:autoApprovalSnapshot.timestamp",
    "updatedFields:intakeTimestamp",
    "updatedFields:integration:salesforce:lastUpdatedAt",
    "updatedFields:integration:welltok:lastUpdatedAt",
    "updatedFields:decisionTimestamp",
  ];

  const fullDocumentEpochConv: string[] = [
    "lastUpdated",
    "autoApprovalSnapshot.timestamp",
    "intakeTimestamp",
    "integration:salesforce:lastUpdatedAt",
    "integration:welltok:lastUpdatedAt",
    "decisionTimestamp",
  ];

  return (
    <div>
      <H6>Audit history</H6>
      <ul>
        {[...(data || [])].map((change, idx) => (
          <li key={change._id?._data || `fallback-${idx}`}>
            {change.operationType} - {formatAuthor(change.author || ({ id: "unknown" } as Author))} at{" "}
            {convertEpochToEastern(change.clusterTime || 0)}
            <pre>
              {JSON.stringify(
                change.updateDescription
                  ? convertEpochToTimeStamps(change.updateDescription, updateDescriptionEpochConv)
                  : convertEpochToTimeStamps(change.fullDocument, fullDocumentEpochConv),
                undefined,
                2
              )}
            </pre>
          </li>
        ))}
      </ul>
    </div>
  );
}

function formatAuthor({ id, name, email }: Author): string {
  if (name || email) {
    return `${name || "unknown name"} (${email || "unknown email"})`;
  }

  if (id === "sub") {
    return "unknown";
  }
  return `Okta user with id ${id}`;
}
