import React, { useCallback, useState, useRef, useEffect } from "react";
import { TextFieldProps } from "@material-ui/core/TextField";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { SelectProps } from "@material-ui/core/Select";
import { TextField } from "../TextField";
import { AutoFillIcon } from "../images";
import CohereMenuItem from "./CohereMenuItem";
import {
  DropdownOption,
  useStylesMenu,
  useStylesMenuList,
  useStylesSelect,
  useStylesListSubheader,
  Warning,
} from "./shared";
import { SingleSelectDropdownProps, useSingleSelectStyles } from "./SingleSelectDropdown";
import CircularProgress from "@material-ui/core/CircularProgress";
import mergeRefs from "merge-refs";
import { Caption } from "../Typography";
import { ListSubheader } from "@material-ui/core";
import { groupBy } from "lodash";

export interface CategorizedDropdownOption extends DropdownOption {
  type: string;
}

// Temporary export for storybook documentation
export function CategorizedSingleSelectDropdownForProps<T extends CategorizedDropdownOption>(
  props: SingleSelectDropdownProps<T>
) {}

export type AllCategorizedSingleSelectDropdownProps<T extends CategorizedDropdownOption> =
  SingleSelectDropdownProps<T> &
    Omit<TextFieldProps, "value" | "onChange" | "css"> & {
      /* Setting this to true will essentially treat this dropdown like a normal single select w/o categories
       * Useful if you want to sometimes conditionally hide the category labels w/o having to render a different component
       * */
      hideGroupHeaders?: boolean;
    };

export default function CategorizedSingleSelectDropdown<T extends CategorizedDropdownOption>({
  classes,
  children,
  labelId,
  options = [],
  noOptionsText,
  onChange,
  renderOption,
  renderOptionInList,
  renderSelectedOptionInList,
  value,
  menuWidth,
  maxMenuHeight,
  menuItemHeight,
  noIcon,
  fontSize,
  menuItemColor,
  SelectProps,
  variant,
  selectRef,
  showLoadingIcon,
  optionIdsWithWarning,
  warningTextForOptions,
  warningTextForTextFieldSelection,
  addWarningToTextFieldSelection,
  showAutoFillIcon,
  isDashboardSortComponent,
  dataPublic = false,
  hideGroupHeaders = false,
  ...props
}: AllCategorizedSingleSelectDropdownProps<T>) {
  const refWidth = useRef<HTMLInputElement>(null);
  const [width, setWidth] = useState<number>();
  const [selectedId, setSelectedId] = useState(value);
  const internalOnChange = useCallback(
    (event) => {
      if (event.target.value !== "NO_RESULTS" && event.target.value !== "CATEGORY") {
        onChange?.(event.target.value);
        setSelectedId(event.target.value);
      }
    },
    [onChange]
  );

  const categorizeOptionsByType = (dropdownOptions?: T[]): Record<string, T[]> => {
    return groupBy(dropdownOptions, (option) => option.type);
  };

  const categorizedOptions = categorizeOptionsByType(options);

  const dataPublicProps = dataPublic ? { "data-public": true } : {};
  const dropdownClasses = useSingleSelectStyles({ asSelectedItem: false });
  const listSubheaderClasses = useStylesListSubheader();
  const renderSelectedOption = useCallback(
    (option: T) => {
      return (
        renderOption?.(option) || (
          <div className={dropdownClasses.selectOptionContainer}>
            <span className={dropdownClasses.selectOption}>
              {(option as any).label || option.id}
              {option.subLabel && <Caption className={dropdownClasses.subLabel}>{option.subLabel}</Caption>}
            </span>
            {optionIdsWithWarning?.includes(option.id || "") && <Warning warningText={warningTextForOptions || ""} />}
          </div>
        )
      );
    },
    [renderOption, warningTextForOptions, dropdownClasses, optionIdsWithWarning]
  );

  const renderOptionInMenuItem = useCallback(
    (option: T, selected: boolean) => {
      return ((selected && renderSelectedOptionInList) || renderOptionInList)?.(option) || renderSelectedOption(option);
    },
    [renderSelectedOption, renderOptionInList, renderSelectedOptionInList]
  );
  const selectedOptionClasses = useSingleSelectStyles({ asSelectedItem: true });
  const renderSelectedOptionFromId = useCallback(
    (selectedId) => {
      const option = Object.values(categorizedOptions)
        .flatMap((arr) => arr)
        .find(({ id }) => id === selectedId);
      return option
        ? renderOption?.(option) || (
            <div className={selectedOptionClasses.selectOptionContainer}>
              <span className={selectedOptionClasses.selectOption}>{(option as any).label || option.id}</span>
              {(optionIdsWithWarning?.includes(option.id || "") || addWarningToTextFieldSelection) && (
                <Warning warningText={warningTextForTextFieldSelection || warningTextForOptions || ""} asSelectedItem />
              )}
            </div>
          )
        : "";
    },
    [
      categorizedOptions,
      warningTextForTextFieldSelection,
      addWarningToTextFieldSelection,
      selectedOptionClasses,
      warningTextForOptions,
      optionIdsWithWarning,
      renderOption,
    ]
  );

  const selectClasses = useStylesSelect({ variant });
  const menuClasses = useStylesMenu({ maxMenuHeight, menuWidth: width });
  const menuListClasses = useStylesMenuList();

  const { MenuProps: otherMenuProps, ...otherSelectProps } = SelectProps || {};
  const selectProps: SelectProps = {
    classes: selectClasses,
    renderValue: renderSelectedOptionFromId,
    IconComponent: showLoadingIcon
      ? () => (
          <CircularProgress
            size={20}
            className={selectClasses.icon}
            style={{ position: "absolute", margin: "0 6px" }}
          />
        )
      : showAutoFillIcon
      ? () => <AutoFillIcon />
      : !props.disabled
      ? () => (
          <ExpandMore
            className={selectedOptionClasses.iconComponent}
            style={isDashboardSortComponent ? { color: "#212936", right: "1px" } : {}}
          />
        )
      : () => <></>,
    MenuProps: {
      classes: menuClasses,
      anchorOrigin: { vertical: "bottom", horizontal: "left" },
      getContentAnchorEl: null,
      autoFocus: false,
      PaperProps: {
        elevation: 1,
        style: {
          width: width,
          minWidth: width,
        },
      },
      MenuListProps: {
        classes: menuListClasses,
      },
      ...otherMenuProps,
    },
    onOpen: () => setWidth(refWidth.current?.clientWidth),
    ...otherSelectProps,
  };

  if (labelId) {
    // Only set this prop if it's provided - otherwise breaks the internal label association
    selectProps.labelId = labelId;
  }

  useEffect(() => {
    if (refWidth.current) {
      setWidth(refWidth.current.clientWidth);
    }
  }, [setWidth]);

  useEffect(() => {
    const resetWidth = () => setWidth(refWidth.current?.clientWidth || 0);
    window.addEventListener("resize", resetWidth);

    // clean up listener on unmount
    return () => window.removeEventListener("resize", resetWidth);
  }, []);

  return (
    <TextField
      classes={classes}
      fullWidth
      value={value}
      {...props}
      select
      onChange={internalOnChange}
      variant={variant}
      SelectProps={selectProps}
      ref={selectRef ?? mergeRefs(refWidth, selectRef)}
      {...dataPublicProps}
    >
      {Object.entries(categorizedOptions).map(([groupKey, groupValues]) => [
        <ListSubheader
          classes={listSubheaderClasses}
          style={hideGroupHeaders ? { display: "none" } : {}}
          aria-disabled
          disableSticky
          key="CATEGORY"
          value="CATEGORY"
        >
          <Caption color="textPrimary">{groupKey}</Caption>
        </ListSubheader>,
        ...groupValues.map((option) => {
          const selected = option.id === selectedId;
          return (
            <CohereMenuItem
              key={option.id}
              value={option.id}
              selected={selected}
              hasSublabel={!!option.subLabel}
              menuWidth={menuWidth}
              menuItemHeight={menuItemHeight}
              fontSize={fontSize}
              menuItemColor={menuItemColor}
              noIcon={noIcon}
              style={{ minWidth: width, width: width }}
            >
              {renderOptionInMenuItem(option, selected)}
            </CohereMenuItem>
          );
        }),
      ])}
      {!Object.keys(categorizedOptions).length && (
        <CohereMenuItem key="NO_RESULTS" value="NO_RESULTS" menuWidth={menuWidth} style={{ padding: 16 }}>
          {noOptionsText ? noOptionsText : "No options available."}
        </CohereMenuItem>
      )}
    </TextField>
  );
}
