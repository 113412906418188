import { useState } from "react";
import { Checkbox, Tooltip } from "@coherehealth/common";
import { AuthStatus, useGetReviewStatus } from "@coherehealth/core-platform-api";
import { useTrackUserImpression } from "util/userActivityTracker";
import { useAuthorized } from "authorization";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import ExpeditedStatusModal from "./components/ExpeditedStatusModal";
import PharmacyExpeditedStatusModal from "./components/PharmacyExpeditedStatusModal";

interface Props {
  isExpedited: boolean;
  setIsExpedited(isExpedited: boolean, reason: string): void;
  authStatus: AuthStatus;
  canEditExpedited?: boolean;
  warningExpeditedMessage?: string;
  startDate?: Date;
  patientId?: string;
  serviceRequestId?: string;
  clinicalServiceId?: string;
  patientHealthPlanName?: string;
  disabled?: boolean;
}

const useTooltipStyles = makeStyles(() =>
  createStyles({
    customWidth: {
      maxWidth: 412,
    },
  })
);

export function RequestExpedited({
  isExpedited,
  setIsExpedited,
  authStatus,
  warningExpeditedMessage = "",
  canEditExpedited = true,
  startDate,
  patientId,
  serviceRequestId,
  clinicalServiceId,
  patientHealthPlanName,
}: Props) {
  const tooltipClasses = useTooltipStyles();
  const trackUserActivityImpression = useTrackUserImpression();

  const [showExpediteStatusModal, setShowExpeditedStatusModal] = useState<boolean>(false);

  return (
    <>
      <Tooltip
        title={warningExpeditedMessage}
        classes={{ tooltip: tooltipClasses.customWidth }}
        placement="right-end"
        arrow
      >
        <Checkbox
          checked={isExpedited}
          onChange={(expedited: boolean) => {
            if (expedited) {
              setShowExpeditedStatusModal(true);
              trackUserActivityImpression({
                event: "DISPLAY_EXPEDITED_CONFIRMATION_MODAL",
                stage: authStatus === "DRAFT" ? "AUTH_CREATION" : "AUTH_SUMMARY",
                field: "EXPEDITED",
                activityContext: { patientId, serviceRequestId, clinicalServiceId },
              });
            } else {
              setIsExpedited(false, "");
            }
          }}
          label="Expedite"
          disabled={!canEditExpedited}
        />
      </Tooltip>
      <ExpeditedStatusModal
        open={showExpediteStatusModal}
        handleClose={() => {
          setShowExpeditedStatusModal(false);
        }}
        setIsExpedited={setIsExpedited}
        patientId={patientId || ""}
        serviceRequestId={serviceRequestId}
        clinicalServiceId={clinicalServiceId}
        patientHealthPlanName={patientHealthPlanName}
      />
    </>
  );
}

export function ExistingRequestExpedited({
  isExpedited,
  setIsExpedited,
  patientId,
  serviceRequestId,
  authStatus,
  startDate,
  patientHealthPlanName,
  disabled,
}: Props & { serviceRequestId: string }) {
  const { data: reviewStatus } = useGetReviewStatus({
    id: serviceRequestId,
  });

  const canEditServiceRequestInReview = useAuthorized("EDIT_SERVICE_REQUEST_IN_REVIEW_STAGE");

  let canEditExpedited = false;
  let warningExpeditedMessage = "";

  if (
    ["INTAKE", "PENDING"].includes(authStatus) &&
    (!reviewStatus?.summary?.reviewPresent || canEditServiceRequestInReview)
  ) {
    canEditExpedited = isExpedited;
    warningExpeditedMessage = isExpedited
      ? ""
      : "To expedite, please withdraw this request and submit a new expedited request";
  } else {
    canEditExpedited = false;
    warningExpeditedMessage = isExpedited
      ? "This request cannot be downgraded"
      : "To expedite, please withdraw this request and submit a new expedited request";
  }

  return (
    <RequestExpedited
      authStatus={authStatus}
      isExpedited={isExpedited}
      setIsExpedited={setIsExpedited}
      canEditExpedited={canEditExpedited}
      warningExpeditedMessage={warningExpeditedMessage}
      patientId={patientId}
      startDate={startDate}
      serviceRequestId={serviceRequestId}
      patientHealthPlanName={patientHealthPlanName}
      disabled={disabled}
    />
  );
}

export function PharmacyExpedited({
  isExpedited,
  setIsExpedited,
}: {
  isExpedited: boolean;
  setIsExpedited: (isExpedited: boolean) => void;
}) {
  const [expeditedModalOpen, setExpeditedModalOpen] = useState(false);

  return (
    <>
      <Checkbox
        checked={isExpedited}
        onChange={(expedited: boolean) => {
          if (expedited) {
            setExpeditedModalOpen(true);
          } else {
            setIsExpedited(false);
          }
        }}
        label="Expedite"
      />
      <PharmacyExpeditedStatusModal
        open={expeditedModalOpen}
        handleClose={(expedited: boolean) => {
          setIsExpedited(expedited);
          setExpeditedModalOpen(false);
        }}
      />
    </>
  );
}
