import { registerRoute } from "../server";
import { ProcedureCode } from "../../index";

export default function registerRoutes() {
  registerRoute({
    id: "procedureCodeSearch",
    route: "procedureCode",
    method: "get", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      const queryParams = request.queryParams;
      let result = (schema as any).procedureCodes.all();
      if (queryParams) {
        if (queryParams.query) {
          result = (schema as any).procedureCodes.where(
            (item: ProcedureCode) => {
              return (
                item.code?.match(new RegExp(queryParams.query, "i")) ||
                item.description?.match(new RegExp(queryParams.query, "i"))
              );
            }
          );
        }
      }

      let offset = Number(queryParams?.offset) || 0;
      let max = Number(queryParams?.max) || 10;

      // Limit to first 10
      result.models = result.models.slice(offset, offset + max);
      return result;
    },
  });

  registerRoute({
    id: "palCheck",
    route: "/palCheck",
    method: "post", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      return [];
    },
  });
}
