import React, { useEffect } from "react";
import { InformativeModal } from "@coherehealth/common";
import MuiErrorIcon from "@material-ui/icons/Error";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, makeStyles } from "@material-ui/core";
import {
  ServiceRequestCreatePayload,
  ServiceRequestResponse,
  ServiceRequestSearchResponse,
  useDeleteServiceRequest,
} from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { routeToPatientSummaryFromReviewAfterDelete } from "../../../util/routeUtils/routeUtils";
import { payloadFromSRFormContent } from "../../../util/serviceRequest";
import { ServiceRequestFormContent } from "../../../common/SharedServiceRequestFormComponents";

interface Props {
  open: boolean;
  onClose: () => void;
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onDeleteDraft?: Function;
  serviceRequestId: string;
  serviceRequest?: ServiceRequestSearchResponse | ServiceRequestResponse;
  isInAuthBuilder?: boolean;
  searchForPotentialDuplicates?: (srPayload: ServiceRequestCreatePayload) => Promise<boolean>;
  workflowId?: string;
  serviceRequestFormContents?: ServiceRequestFormContent[];
  getPotentialDuplicatesLoading?: boolean;
}

export default function DeleteDraftServiceRequestModal({
  open,
  onClose,
  onDeleteDraft,
  serviceRequest,
  isInAuthBuilder,
  searchForPotentialDuplicates,
  serviceRequestId,
  workflowId,
  serviceRequestFormContents,
  getPotentialDuplicatesLoading,
}: Props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: deleteServiceRequest, loading: deleting, error: deleteError } = useDeleteServiceRequest({});

  let srPayload: ServiceRequestCreatePayload | undefined;
  if (serviceRequestFormContents && serviceRequestFormContents.length > 0) {
    srPayload = {
      ...payloadFromSRFormContent(
        serviceRequestFormContents[0],
        true,
        serviceRequest?.authStatus || "DRAFT",
        workflowId,
        false
      ),
      patient: serviceRequest?.patient?.id,
      requestType: serviceRequest?.requestType,
    };
  }

  const deleteSR = async () => {
    try {
      await deleteServiceRequest(serviceRequestId);
      if (srPayload && searchForPotentialDuplicates) {
        await searchForPotentialDuplicates(srPayload);
      }
      onClose();
      enqueueSnackbar(`Your request has been deleted`, { variant: "success" });
      if (onDeleteDraft) {
        onDeleteDraft();
      }
      if (isInAuthBuilder) {
        routeToPatientSummaryFromReviewAfterDelete(serviceRequest?.patient?.id, navigate);
      }
    } catch (error) {
      enqueueSnackbar("Error removing service request");
    }
  };

  useEffect(() => {
    if (deleteError) {
      enqueueSnackbar(`Error removing service request: ${deleteError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, deleteError]);

  return (
    <InformativeModal
      className={classes.modal}
      open={open}
      onClose={onClose}
      headerText={"Are you sure you want to delete this authorization request?"}
      subHeaderText={"You can't undo this action."}
      icon={<WarningIcon />}
      primaryButtonText={"Yes, delete"}
      primaryButtonLoading={deleting || getPotentialDuplicatesLoading}
      primaryButtonAction={deleteSR}
      tertiaryButtonText={"No, keep this request"}
      tertiaryButtonAction={onClose}
      data-testid="delete-draft-sr-modal"
    ></InformativeModal>
  );
}

const useStyles = makeStyles((theme) => ({
  modal: {
    "& .MuiButton-root": {
      minWidth: 195,
    },
    "& div:first-child": {
      paddingBottom: theme.spacing(0),
      borderRadius: 16,
    },
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const WarningIcon = styled(MuiErrorIcon)(({ theme }) => ({
  color: theme.palette.warning.dark,
  transform: "scale(0.6)",
}));
