import { H4 } from "@coherehealth/common";
import { Attachment, ServiceRequestResponse } from "@coherehealth/core-platform-api";

import { capitalizeString } from "util/serviceRequest";
import { getAttachmentString } from "util/attachmentUtil";

interface Props {
  attachmentsList?: Attachment[];
  serviceRequest?: ServiceRequestResponse;
}

export default function AttachmentSectionHeader({ attachmentsList = [], serviceRequest }: Props) {
  const numAttachments = attachmentsList?.length || 0;

  return (
    <H4 data-public>
      {capitalizeString(getAttachmentString(serviceRequest))}
      {numAttachments > 0 ? ` (${numAttachments})` : ""}
    </H4>
  );
}
