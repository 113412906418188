import uniqueId from "lodash/uniqueId";
import { useState } from "react";

/**
 * Returns a globally unique ID which is stable for the lifetime
 * of the calling component
 *
 * @param prefix An optional prefix for the unique id
 */
export function useStableUniqueId(prefix?: string) {
  const [id] = useState(uniqueId(prefix));
  return id;
}
