import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import {
  ClinicalAssessment,
  ServiceRequestResponse,
  useGetSingleServiceClinicalAssessment,
} from "@coherehealth/core-platform-api";
import {
  H5,
  HEALTH_HELP_NAME,
  incrementClickCount,
  useFeature,
  UserClickInfoClinicalReviewPage,
} from "@coherehealth/common";
import { Grid, CircularProgress } from "@material-ui/core";
import { ClinicalAssessmentContent } from "../../PatientSummary/ServiceRequestSummaryCard/ClinicalAssessmentSection";
import { useIsDelegatedToVendor } from "util/serviceRequest";
import { StyledBody1 } from "./ServiceRequestInformation";
import ClinicalReviewCard from "common/ClinicalReviewCard";

interface Props {
  serviceRequest: ServiceRequestResponse | null;
  serviceRequestId: string;
  userClickInfoTracking?: UserClickInfoClinicalReviewPage;
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
}

export default function ClinicalAssessmentInformation({
  serviceRequest,
  serviceRequestId,
  userClickInfoTracking,
  setUserClickInfoTracking,
}: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isQuestionsCalculated, setIsQuestionsCalculated] = useState<boolean>(false);
  const [numQuestionsAnswered, setNumQuestionsAnswered] = useState<number>(0);
  const [totalQuestions, setTotalQuestions] = useState<number>(0);
  const [isLoadingSpinner, setIsLoadingSpinner] = useState<boolean>(true);
  // TODO: we should move this into health plan config
  const isDelegatedToHealthHelp = useIsDelegatedToVendor(serviceRequest, [HEALTH_HELP_NAME]);

  const {
    data: clinicalAssessment,
    loading: clinicalAssessmentLoading,
    refetch: clinicalAssessmentRefetch,
  } = useGetSingleServiceClinicalAssessment({
    id: serviceRequestId,
    lazy: true,
  });

  useEffect(() => {
    if (serviceRequestId) {
      clinicalAssessmentRefetch();
      setTotalQuestions(clinicalAssessment?.questions?.length || 0);
    }
  }, [clinicalAssessmentRefetch, serviceRequestId, clinicalAssessment?.questions?.length]);

  const updateAssessmentCount = (ca: ClinicalAssessment) => {
    if (ca && ca.questions) {
      let numAnswered = 0;
      ca.questions.forEach((q) => {
        if (q.answers && q.answers.length > 0) {
          numAnswered++;
        }
      });
      setNumQuestionsAnswered(numAnswered);
    }
  };

  if (!isQuestionsCalculated && clinicalAssessment) {
    updateAssessmentCount(clinicalAssessment);
    // avoid extra updates
    setIsQuestionsCalculated(true);
    setIsLoadingSpinner(false);
  }

  const updateUserClickInfo = () => {
    setUserClickInfoTracking && incrementClickCount("ClinicalAssessmentCardToggle", "", -1, setUserClickInfoTracking);
  };

  const logrocketUnmasking = useFeature("logrocketUnmasking");

  return (
    <>
      <ClinicalReviewCard
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        updateUserClickInfo={updateUserClickInfo}
        header={
          <>
            <H5 data-public={!!logrocketUnmasking ? true : undefined}>Clinical Assessment</H5>
            <Grid container style={{ marginTop: 6, ...(isExpanded ? {} : { marginBottom: 4 }) }}>
              <div>
                {isLoadingSpinner ? (
                  <CircularProgress size={15} />
                ) : (
                  <StyledBody1>
                    {numQuestionsAnswered} of {totalQuestions} clinical assessment questions answered
                  </StyledBody1>
                )}
              </div>
            </Grid>
          </>
        }
      >
        {isExpanded && (
          <>
            <Grid container>
              {totalQuestions > 0 ? (
                <>
                  {serviceRequest && (
                    <ClinicalAssessmentContent
                      serviceRequest={serviceRequest}
                      showReadOnlyVendorSummary={isDelegatedToHealthHelp}
                      crrCardView
                      updateAssessmentCount={updateAssessmentCount}
                      clinicalAssessment={clinicalAssessment || null}
                      clinicalAssessmentLoading={clinicalAssessmentLoading}
                      clinicalAssessmentRefetch={clinicalAssessmentRefetch}
                    />
                  )}
                </>
              ) : (
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center", marginTop: 32, marginBottom: 20 }}
                >
                  {isLoadingSpinner ? (
                    <>
                      <CircularProgress size={30} />
                    </>
                  ) : (
                    <>
                      <StyledBody1>No clinical assessment questions for this Service Request</StyledBody1>
                    </>
                  )}
                </Grid>
              )}
            </Grid>
          </>
        )}
      </ClinicalReviewCard>
    </>
  );
}
