import React from "react";
import { Body1, H4 } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled, Theme } from "@material-ui/core";
interface Props {
  allowMultipleAttachmentFiles?: boolean;
}
export default function AddAttachmentHeaderText({ allowMultipleAttachmentFiles = true }: Props) {
  const styleClasses = dividerStyles();
  const MULTI_FILE_TEXT = allowMultipleAttachmentFiles ? "files" : "a file";
  const HEADER_TEXT = `Choose ${MULTI_FILE_TEXT} to upload`;

  /**
   * Returns appropriate body text based on if multiple or a single attachment is allowed
   */
  const getBodyTextContent = () =>
    allowMultipleAttachmentFiles ? (
      <>Please add clinical documentation to support this authorization and accelerate the review.</>
    ) : (
      <>
        Please upload a file to support your authorization request. Common files include doctors notes, medical history,
        or lab results.
        <VerticalSpacer space={2}>
          Max file size: <Bold>10MB</Bold>
        </VerticalSpacer>
      </>
    );

  return (
    <div>
      <H4 data-public>{HEADER_TEXT}</H4>
      <VerticalSpacer space={1}>
        <Body1 className={styleClasses.attachment} style={{ paddingBottom: "16px" }} component="div" data-public>
          {getBodyTextContent()}
        </Body1>
      </VerticalSpacer>
    </div>
  );
}

interface IProps {
  theme: Theme;
  space?: number;
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const VerticalSpacer = styled("div")(({ theme, space = 1 }: IProps) => ({
  margin: theme.spacing(space, 0),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Bold = styled(Body1)(() => ({
  fontWeight: 600,
  display: "inline",
}));

const dividerStyles = makeStyles((theme) => ({
  attachment: {
    color: theme.palette.text.secondary,
  },
}));
