import {
  appHeaderHeight,
  Accordion,
  DoubleDrawerExpandMoreButton,
  H5,
  H6,
  colorsLight,
  Body1,
  InlineButton,
  Body2,
  DoubleDrawerExpansionContext,
  InformativeModal,
  SingleSelectDropdown,
  Caption,
  TextField,
} from "@coherehealth/common";
import {
  HealthPlanHierarchyTree,
  useGetHealthPlanHierarchyApplicationConfigurations,
  useCreateHealthPlanApplicationConfiguration,
  useCreateDelegatedVendorApplicationConfiguration,
  useGetHealthPlansAvailableForConfiguration,
  HealthPlan,
} from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { CircularProgress, Divider, Drawer, Grid, makeStyles, styled, useTheme } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useSnackbar } from "notistack";
import { Dispatch, useEffect, useMemo, useState } from "react";
import { CONFIGURATION_MANAGEMENT_HEADER_HEIGHT } from "./Header";

type SelectConfigurationDrawerProps = {
  setSelectedApplicationConfigurationId: (id: string) => void;
  setSelectedApplicationConfigurationParentId: (id?: string) => void;
  selectedConfigurationButtonId: string | null;
  setSelectedConfigurationButtonId: (id: string | null) => void;
};

interface DoubleDrawerExpansionState {
  expandedMore: boolean;
  setExpandedMore: Dispatch<boolean>;
  selectedExpandButtonId: string | null;
  setSelectedExpandButtonId: Dispatch<string | null>;
}

const useConfigurationAccordionProps = makeStyles((theme) => ({
  card: {
    padding: 0,
    position: "relative",
    boxShadow: "none",
    border: "none",
    borderRadius: "none",
    backgroundColor: "transparent",
    "&:hover": {
      border: "none",
      borderRadius: "none",
      boxShadow: "none",
    },
  },
  contentRoot: {
    padding: 0,
  },
  summaryRoot: {
    "&.Mui-expanded": {
      minHeight: theme.spacing(6),
    },
  },
  contentDivider: {
    margin: 0,
  },
  "& $rotatingCarrot": {
    color: theme.palette.secondary.dark,
  },
}));

export const CONFIGURATION_MANAGEMENT_DRAWER_WIDTH = 300;
const CONFIGURATION_MANAGEMENT_DRAWER_FOOTER_HEIGHT = 55;

const useStylesConfigurationDrawer = makeStyles((theme) => ({
  paper: {
    flexDirection: "row",
    top: appHeaderHeight() + CONFIGURATION_MANAGEMENT_HEADER_HEIGHT,
    borderRight: "none",
    overflowY: "hidden",
  },
}));

export default function SelectConfigurationDrawer(props: SelectConfigurationDrawerProps) {
  const { selectedConfigurationButtonId, setSelectedConfigurationButtonId } = props;
  const drawerClasses = useStylesConfigurationDrawer();
  const theme = useTheme();
  const { spacing, palette } = theme;

  const [addHealthPlanModalOpen, setAddHealthPlanModelOpen] = useState(false);
  const [newHealthPlanId, setNewHealthPlanId] = useState<string>("");

  const contextValue = useMemo<DoubleDrawerExpansionState>(
    () => ({
      expandedMore: true,
      setExpandedMore: () => {},
      selectedExpandButtonId: selectedConfigurationButtonId,
      setSelectedExpandButtonId: setSelectedConfigurationButtonId,
    }),
    [selectedConfigurationButtonId, setSelectedConfigurationButtonId]
  );

  const { enqueueSnackbar } = useSnackbar();

  const {
    data: applicationConfigurations,
    loading: applicationConfigurationsLoading,
    error: applicationConfigurationsError,
    refetch: applicationConfigurationsRefetch,
  } = useGetHealthPlanHierarchyApplicationConfigurations({});

  useEffect(() => {
    if (applicationConfigurationsError) {
      enqueueSnackbar("Failed to get application configurations, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [applicationConfigurationsError, enqueueSnackbar]);

  const {
    data: configurableHealthPlans,
    error: configurableHealthPlansError,
    refetch: configurableHealthPlansRefetch,
  } = useGetHealthPlansAvailableForConfiguration({});

  const {
    mutate: createHealthPlanApplicationConfiguration,
    loading: createHealthPlanApplicationConfigurationLoading,
    error: createHealthPlanApplicationConfigurationError,
  } = useCreateHealthPlanApplicationConfiguration({});

  const onCreateNewHealthPlanConfiguration = async () => {
    await createHealthPlanApplicationConfiguration({
      healthPlan: newHealthPlanId,
    });
    setNewHealthPlanId("");
    setAddHealthPlanModelOpen(false);
    applicationConfigurationsRefetch();
    configurableHealthPlansRefetch();
  };

  useEffect(() => {
    if (createHealthPlanApplicationConfigurationError) {
      if (createHealthPlanApplicationConfigurationError.status === 422) {
        enqueueSnackbar("Vendor name must not be empty and must not already exist for this health plan", {
          variant: "error",
          preventDuplicate: true,
        });
      } else {
        enqueueSnackbar("Failed to create delegated vendor application, please try again", {
          variant: "error",
          preventDuplicate: true,
        });
      }
    }
  }, [createHealthPlanApplicationConfigurationError, enqueueSnackbar]);

  return (
    <DoubleDrawerExpansionContext.Provider value={contextValue}>
      <Drawer classes={drawerClasses} open anchor="left" hideBackdrop variant="persistent">
        <ConfigurationDrawerContainer>
          <ConfigurationTabContainer>
            {applicationConfigurations ? (
              <>
                {applicationConfigurations.length > 0 &&
                  applicationConfigurations?.map((configurationTree: HealthPlanHierarchyTree, hpIndex) => {
                    return (
                      <HealthPlanDelegatedVendorSection
                        key={hpIndex}
                        configurationTree={configurationTree}
                        refetchApplicationConfigurations={applicationConfigurationsRefetch}
                        {...props}
                      />
                    );
                  })}
                {applicationConfigurations.length === 0 && (
                  <Grid
                    container
                    spacing={2}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ margin: "auto" }}
                  >
                    <Grid item xs={12}>
                      <H6>No configurations yet</H6>
                    </Grid>
                  </Grid>
                )}
              </>
            ) : (
              <Grid
                container
                spacing={2}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ margin: "auto" }}
              >
                {applicationConfigurationsLoading ? (
                  <>
                    <Grid item xs={12}>
                      <H5>Loading configurations...</H5>
                    </Grid>
                    <Grid item xs={12}>
                      <CircularProgress />
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    <Body1>Could not load configurations</Body1>
                  </Grid>
                )}
              </Grid>
            )}
          </ConfigurationTabContainer>
          <AddHealthPlanConfigurationContainer>
            <AddNewHealthPlanButton startIcon={<AddIcon />}>
              <Body2 onClick={() => setAddHealthPlanModelOpen(true)}>Configure health plan</Body2>
            </AddNewHealthPlanButton>
            <InformativeModal
              headerText="Configure health plan"
              subHeaderText="Select a health plan to configure."
              TransitionProps={{ unmountOnExit: true }}
              additionalInfoElement={
                <Grid container spacing={2} style={{ marginTop: spacing(2) }}>
                  <Grid item xs={12}>
                    <SingleSelectDropdown
                      fullWidth
                      label="Health plan"
                      onChange={(option) => {
                        setNewHealthPlanId(option);
                      }}
                      options={healthPlanOptions(configurableHealthPlans || [])}
                      value={newHealthPlanId}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ textAlign: "center" }}>
                      <Caption>
                        This dropdown only shows health plans that have been added to our database that don't yet have a
                        configuration. If you don't see the health plan you are looking for here, reach out to
                        engineering to get a new health plan object added to the database.
                      </Caption>
                    </div>
                  </Grid>
                  {configurableHealthPlansError && (
                    <Grid item xs={12}>
                      <div style={{ textAlign: "center" }}>
                        <Caption style={{ color: palette.error.main }}>
                          Failed to retrieve health plans. Please refresh and try again.
                        </Caption>
                      </div>
                    </Grid>
                  )}
                </Grid>
              }
              primaryButtonText="Build new configuration"
              primaryButtonAction={onCreateNewHealthPlanConfiguration}
              primaryButtonLoading={createHealthPlanApplicationConfigurationLoading}
              primaryButtonDisabled={
                Boolean(createHealthPlanApplicationConfigurationError) || configurableHealthPlans?.length === 0
              }
              tertiaryButtonText="Cancel"
              tertiaryButtonAction={() => setAddHealthPlanModelOpen(false)}
              open={addHealthPlanModalOpen}
              onClose={() => setAddHealthPlanModelOpen(false)}
            />
          </AddHealthPlanConfigurationContainer>
        </ConfigurationDrawerContainer>
      </Drawer>
    </DoubleDrawerExpansionContext.Provider>
  );
}

type HealthPlanDelegatedVendorSectionProps = SelectConfigurationDrawerProps & {
  configurationTree: HealthPlanHierarchyTree;
  refetchApplicationConfigurations: (configs?: any) => Promise<HealthPlanHierarchyTree[] | null>;
};

function HealthPlanDelegatedVendorSection({
  configurationTree,
  refetchApplicationConfigurations,
  setSelectedApplicationConfigurationId,
  setSelectedApplicationConfigurationParentId,
}: HealthPlanDelegatedVendorSectionProps) {
  const accordionStyles = useConfigurationAccordionProps();
  const theme = useTheme();
  const { spacing } = theme;
  const [addDelegatedVendorModalOpen, setAddDelegatedVendorModalOpen] = useState(false);
  const [newDelegatedVendorName, setNewDelegatedVendorName] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: createDelegatedVendorApplicationConfiguration,
    loading: createDelegatedVendorApplicationConfigurationLoading,
    error: createDelegatedVendorApplicationConfigurationError,
  } = useCreateDelegatedVendorApplicationConfiguration({});

  const onCreateNewDelegatedVendorConfiguration = async () => {
    await createDelegatedVendorApplicationConfiguration({
      delegatedVendorName: newDelegatedVendorName,
      parentConfiguration: configurationTree.id,
    });
    setNewDelegatedVendorName("");
    setAddDelegatedVendorModalOpen(false);
    refetchApplicationConfigurations();
  };

  useEffect(() => {
    if (createDelegatedVendorApplicationConfigurationError) {
      if (createDelegatedVendorApplicationConfigurationError.status === 422) {
        enqueueSnackbar("Vendor name must not be empty and must not already exist for this health plan", {
          variant: "error",
          preventDuplicate: true,
        });
      } else {
        enqueueSnackbar("Failed to create delegated vendor application, please try again", {
          variant: "error",
          preventDuplicate: true,
        });
      }
    }
  }, [createDelegatedVendorApplicationConfigurationError, enqueueSnackbar]);

  return (
    <div>
      <DoubleDrawerExpandMoreButton
        showCarrot
        allowDeselection={false}
        identifierFromParent={configurationTree.id}
        onClick={() => {
          setSelectedApplicationConfigurationId(configurationTree.id);
          setSelectedApplicationConfigurationParentId(undefined);
        }}
      >
        <H5>{configurationTree.healthPlanName}</H5>
      </DoubleDrawerExpandMoreButton>
      <Accordion
        styleOverrides={accordionStyles}
        header={<DelegatedVendorTitleText>Delegated Vendor</DelegatedVendorTitleText>}
      >
        {configurationTree?.childConfigurations?.map((delegatedVendorConfiguration, dvIndex) => {
          return (
            <DoubleDrawerExpandMoreButton
              key={dvIndex}
              showCarrot
              allowDeselection={false}
              identifierFromParent={delegatedVendorConfiguration.id}
              onClick={() => {
                setSelectedApplicationConfigurationId(delegatedVendorConfiguration.id);
                setSelectedApplicationConfigurationParentId(configurationTree.id);
              }}
            >
              <DelegatedVendorText>
                {delegatedVendorConfiguration.delegatedVendorName || "Cohere (null)"}
              </DelegatedVendorText>
            </DoubleDrawerExpandMoreButton>
          );
        })}
        <AddNewVendorButton startIcon={<AddIcon />}>
          <Body2 onClick={() => setAddDelegatedVendorModalOpen(true)}>Configure new vendor</Body2>
          <InformativeModal
            headerText="Configure new delegated vendor"
            TransitionProps={{ unmountOnExit: true }}
            subHeaderText={`Create a new delegated vendor for ${configurationTree.healthPlanName}.`}
            additionalInfoElement={
              <Grid container spacing={2} style={{ marginTop: spacing(2) }}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Delegated vendor name"
                    value={newDelegatedVendorName}
                    onChangeValue={(value: string) => setNewDelegatedVendorName(value)}
                  />
                </Grid>
              </Grid>
            }
            primaryButtonText="Build new configuration"
            primaryButtonLoading={createDelegatedVendorApplicationConfigurationLoading}
            primaryButtonDisabled={createDelegatedVendorApplicationConfigurationLoading}
            primaryButtonAction={onCreateNewDelegatedVendorConfiguration}
            tertiaryButtonText="Cancel"
            tertiaryButtonAction={() => setAddDelegatedVendorModalOpen(false)}
            open={addDelegatedVendorModalOpen}
            onClose={() => setAddDelegatedVendorModalOpen(false)}
            showDivider={false}
          />
        </AddNewVendorButton>
      </Accordion>
      <Divider style={{ height: spacing(1), backgroundColor: colorsLight.gray.inactive }} />
      <Divider style={{ height: 2, backgroundColor: theme.palette.primary.dark }} />
    </div>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ConfigurationDrawerContainer = styled("div")(({ theme }) => ({
  width: CONFIGURATION_MANAGEMENT_DRAWER_WIDTH,
  display: "flex",
  flexDirection: "column",
  height: `calc(100% - ${appHeaderHeight()}px`,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ConfigurationTabContainer = styled("div")(({ theme }) => ({
  backgroundColor: colorsLight.gray.light,
  height: `calc(100% - ${appHeaderHeight()}px - ${CONFIGURATION_MANAGEMENT_HEADER_HEIGHT}px - ${CONFIGURATION_MANAGEMENT_DRAWER_FOOTER_HEIGHT}px)`,
  bottom: CONFIGURATION_MANAGEMENT_DRAWER_FOOTER_HEIGHT,
  overflowY: "auto",
  overflowX: "hidden",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const AddHealthPlanConfigurationContainer = styled("div")(({ theme }) => ({
  backgroundColor: colorsLight.gray.inactive,
  width: CONFIGURATION_MANAGEMENT_DRAWER_WIDTH,
  bottom: 0,
  left: 0,
  position: "fixed",
  display: "flex",
  flexDirection: "column",
  height: CONFIGURATION_MANAGEMENT_DRAWER_FOOTER_HEIGHT,
  alignItems: "center",
  borderTop: `3px solid ${theme.palette.primary.dark}`,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const AddIcon = styled(AddCircleIcon)(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const AddNewVendorButton = styled(InlineButton)(({ theme }) => ({
  color: theme.palette.primary.dark,
  margin: theme.spacing(1.25, 0),
  paddingLeft: theme.spacing(5),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const AddNewHealthPlanButton = styled(InlineButton)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  color: theme.palette.primary.dark,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DelegatedVendorTitleText = styled(H6)(({ theme }) => ({
  paddingLeft: theme.spacing(3.5),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DelegatedVendorText = styled(Body1)(({ theme }) => ({
  paddingLeft: theme.spacing(2.5),
  marginRight: "auto",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const healthPlanOptions = (healthPlans?: HealthPlan[]) => {
  return (
    healthPlans?.map((hp) => {
      return { id: hp.id || "", label: hp.name || "" };
    }) || []
  );
};
