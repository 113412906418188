import React from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, Theme } from "@material-ui/core/styles";
import { Caption } from "../Typography";
import { colorsDark } from "../../themes";

interface Props {
  caption?: string;
  containerFontColor?: string;
}

export default function EmptyDataContainer({ caption, containerFontColor }: Props) {
  return (
    <Container containerFontColor={containerFontColor}>
      <Caption>{caption || "No results found"}</Caption>
    </Container>
  );
}

interface IProps {
  theme: Theme;
  containerFontColor?: string;
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Container = styled("div")(({ theme, containerFontColor }: IProps) => {
  return {
    paddingTop: theme.spacing(2),
    color: containerFontColor ? containerFontColor : colorsDark.font.secondary,
  };
});
