import {
  useState,
  ReactElement,
  Dispatch,
  SetStateAction,
  useEffect,
  MutableRefObject,
  useRef,
  useContext,
  useCallback,
} from "react";
import { ToolbarProps, ToolbarSlot } from "@react-pdf-viewer/default-layout";
import { AugmentedDocSegmentation } from "./AttachmentViewerSidePanel";
import { HighlightStateContext, incrementClickCount, UserClickInfoClinicalReviewPage } from "../../util";
import { TextField, InlineButton } from "../../components";
import { Segment, SearchInfo, SearchInfoByPage } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled, Box, Theme, Fade, CircularProgress } from "@material-ui/core";
import { colorsLight } from "../../themes";
import clsx from "clsx";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import Rotate90DegreesCcwIcon from "@material-ui/icons/Rotate90DegreesCcw";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { KeyboardArrowUp, KeyboardArrowDown, ArrowBack, FormatListBulleted } from "@material-ui/icons";
import { SpecialZoomLevel, RotateDirection } from "@react-pdf-viewer/core";
import { Match, RenderSearchProps } from "@react-pdf-viewer/search";
import { RenderZoomInProps, RenderZoomProps } from "@react-pdf-viewer/zoom";
import { AttachmentViewerConfig } from "./util";
import { IAttachmentsMultiSearchHolder } from "./useAttachmentsMultiSearch";

export default function ToolbarControl(
  Toolbar: (props: ToolbarProps) => ReactElement,
  attachmentsMultiSearchHolder: IAttachmentsMultiSearchHolder | undefined,
  currentAttachmentId: string | undefined,
  openSidePanel?: boolean,
  setOpenSidePanel?: Dispatch<SetStateAction<boolean>>,
  hideSearchInAttachments?: boolean,
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>,
  docSegmentationInfo?: AugmentedDocSegmentation,
  setSearchInfos?: Dispatch<SetStateAction<SearchInfo[]>>,
  crrStyling?: boolean,
  currentPage?: MutableRefObject<number>,
  currentRotate?: MutableRefObject<number>,
  fileName?: string,
  textInputRef?: MutableRefObject<HTMLInputElement | undefined>,
  viewerConfig?: AttachmentViewerConfig,
  loadingRemainingAttachments?: boolean
) {
  const hasViewerConfig = !!viewerConfig;
  const searchClasses = useStylesSearchBar({ hasViewerConfig });
  const toolbarClasses = useToolbarStyles({ crrStyling });
  const [pageNumberSegMap, setPageNumberSegMap] = useState<Map<number, Segment>>();
  const rotateRef = useRef<HTMLButtonElement>(null);
  const [rotateLeft, setRotateLeft] = useState<number>(Math.abs(currentRotate?.current || 0) / 90);
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const [isSearchTextChanged, setIsSearchTextChanged] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  // Required to fix @react-pdf-viewer "Black sections when new documents are loaded" bug.
  // Internal library bug, already a Github issue, as a workaround ZoomIn/ZoomOut seems to fix it.
  const zoomInProps = useRef<RenderZoomInProps | null>(null);
  const zoomProps = useRef<RenderZoomProps | null>(null);
  useEffect(() => {
    setTimeout(() => {
      if (zoomInProps.current) {
        zoomInProps.current.onClick();
      }
      if (zoomProps.current) {
        zoomProps.current.onZoom(SpecialZoomLevel.PageWidth);
      }
    }, 1);
  }, []);

  const doSetSearchInfos = useCallback(
    (matches: Match[]) => {
      const pageNumberResultCount = new Map<number, number>();
      matches.forEach((match) => {
        const pageNumber = match.pageIndex + 1;
        if (!pageNumberResultCount.has(pageNumber)) {
          pageNumberResultCount.set(pageNumber, 0);
        }
        const result = pageNumberResultCount.get(pageNumber);
        result !== undefined && pageNumberResultCount.set(pageNumber, result + 1);
      });

      const searchInfoByPages: SearchInfoByPage[] = [];
      pageNumberResultCount.forEach((searchResult: number, pageNumber: number) => {
        const segment = pageNumberSegMap?.get(pageNumber);
        searchInfoByPages.push({
          docType: segment?.docType,
          docDate: segment?.docDate,
          pageNumber: pageNumber,
          searchResult: searchResult,
        });
      });

      setSearchInfos?.((infos) => {
        return [
          ...infos,
          {
            searchTerm: searchText,
            dateSearchTriggered: new Date().toISOString(),
            totalSearchResult: matches.length,
            attachmentId: docSegmentationInfo?.attachmentId,
            docSegmentationId: docSegmentationInfo?.id,
            searchInfoByPages: searchInfoByPages,
          },
        ];
      });
    },
    [docSegmentationInfo, pageNumberSegMap, searchText, setSearchInfos]
  );

  function doSearch(props: RenderSearchProps) {
    if (attachmentsMultiSearchHolder && currentAttachmentId) {
      attachmentsMultiSearchHolder.utilities.doSearch(currentAttachmentId);
    } else {
      props.search().then(doSetSearchInfos);
      setShowSearchResults(true);
      setIsSearchTextChanged(false);
    }
  }

  function doJumpToNextMatch({ props, autoFocusInput }: { props: RenderSearchProps; autoFocusInput: boolean }) {
    if (attachmentsMultiSearchHolder && currentAttachmentId) {
      attachmentsMultiSearchHolder.utilities.doJumpToNextMatch({
        activeAttachmentId: currentAttachmentId,
        autoFocusInput,
      });
    } else {
      props.jumpToNextMatch();
    }
  }

  function doJumpToPreviousMatch({ props, autoFocusInput }: { props: RenderSearchProps; autoFocusInput: boolean }) {
    if (attachmentsMultiSearchHolder && currentAttachmentId) {
      attachmentsMultiSearchHolder.utilities.doJumpToPreviousMatch({
        activeAttachmentId: currentAttachmentId,
        autoFocusInput,
      });
    } else {
      props.jumpToPreviousMatch();
    }
  }

  function onSearchInputChangeValue(text: string, props: RenderSearchProps) {
    if (attachmentsMultiSearchHolder) {
      attachmentsMultiSearchHolder.utilities.doChangeSearchValue(text);
    } else {
      props.setKeyword(text);
      setIsSearchTextChanged(true);
      setSearchText(text);
    }
  }

  function onSearchInputKeyDown(e: React.KeyboardEvent<HTMLDivElement>, props: RenderSearchProps) {
    if (!isSearchTextChanged) {
      !e.shiftKey && e.key === "Enter" && doJumpToNextMatch({ props, autoFocusInput: true });
      e.shiftKey && e.key === "Enter" && doJumpToPreviousMatch({ props, autoFocusInput: true });
    } else {
      if (e.key === "Enter") {
        doSearch(props);
      }
    }
  }

  function onClearSearchInputValue() {
    if (attachmentsMultiSearchHolder) {
      attachmentsMultiSearchHolder.utilities.doClearSearchValue();
    } else {
      setIsSearchTextChanged(false);
      setSearchText("");
    }
  }

  function calculateTotalMatchesNumber(props: RenderSearchProps) {
    if (attachmentsMultiSearchHolder) {
      return attachmentsMultiSearchHolder.stats.totalMatchesNumber;
    } else {
      return props.numberOfMatches;
    }
  }

  function calculateCurrentMatchNumber(props: RenderSearchProps) {
    if (attachmentsMultiSearchHolder) {
      return attachmentsMultiSearchHolder.stats.currentMatchNumber;
    } else {
      return props.currentMatch;
    }
  }

  useEffect(() => {
    if (attachmentsMultiSearchHolder) {
      setSearchText(attachmentsMultiSearchHolder.state.searchText);
      setShowSearchResults(attachmentsMultiSearchHolder.state.showSearchResults);
      setIsSearchTextChanged(attachmentsMultiSearchHolder.state.isSearchTextChanged);
    }
  }, [attachmentsMultiSearchHolder]);

  useEffect(() => {
    if (rotateRef && currentRotate && rotateLeft > 0) {
      rotateRef?.current?.click();
      setRotateLeft(rotateLeft - 1);
    }
  }, [currentRotate, rotateLeft]);

  useEffect(() => {
    const pageNumberSegMap = new Map<number, Segment>();
    docSegmentationInfo?.latestSegments?.forEach((segment: Segment) => {
      const startPage = segment?.docPages?.[0];
      const endPage = segment?.docPages?.[1];
      if (startPage && endPage) {
        for (let i = startPage; i <= endPage; i++) {
          pageNumberSegMap.set(i, segment);
        }
      }
    });
    setPageNumberSegMap(pageNumberSegMap);
  }, [docSegmentationInfo?.latestSegments]);
  const { linking } = useContext(HighlightStateContext);

  return (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const { CurrentPageLabel, NumberOfPages, ZoomIn, ZoomOut, Zoom, Rotate, Search } = slots;
        return (
          <ToolbarWrapper crrStyling={crrStyling}>
            {crrStyling && (
              <Box className={clsx(toolbarClasses.toolbarControls, toolbarClasses.toolbarLeftControls)}>
                <ToolbarItemWrapper>
                  <ToggleListButton
                    openSidePanel={openSidePanel}
                    setOpenSidePanel={setOpenSidePanel}
                    viewerConfig={viewerConfig}
                  />
                </ToolbarItemWrapper>
              </Box>
            )}
            <Box className={clsx(toolbarClasses.toolbarControls, toolbarClasses.toolbarRightControls)}>
              <ToolbarItemWrapper>
                <ToolbarBody3 disabled={!!linking}>
                  Page <CurrentPageLabel /> of <NumberOfPages />
                </ToolbarBody3>
              </ToolbarItemWrapper>
              <ToolbarItemWrapper>
                <Rotate direction={RotateDirection.Backward}>
                  {(props) => (
                    <ToolbarTertiaryInlineButton
                      ref={rotateRef}
                      disabled={!!linking}
                      onClick={() => {
                        props.onClick();
                        rotateLeft <= 0 &&
                          setUserClickInfoTracking &&
                          incrementClickCount(
                            "PageRotation",
                            fileName || "",
                            currentPage ? currentPage?.current + 1 : 1,
                            setUserClickInfoTracking
                          );
                      }}
                      title="Rotate PDF 90 degrees"
                      aria-label="Rotate PDF 90 degrees"
                    >
                      <Rotate90DegreesCcwIcon />
                    </ToolbarTertiaryInlineButton>
                  )}
                </Rotate>
              </ToolbarItemWrapper>
              <ToolbarItemWrapper>
                <ZoomOut>
                  {(props) => (
                    <ToolbarTertiaryInlineButton
                      onClick={() => {
                        props.onClick();
                        setUserClickInfoTracking &&
                          incrementClickCount(
                            "ZoomOut",
                            fileName || "",
                            currentPage ? currentPage?.current + 1 : 1,
                            setUserClickInfoTracking
                          );
                      }}
                      disabled={!!linking}
                      title="Zoom out"
                      aria-label="Zoom out"
                    >
                      <ZoomOutIcon />
                    </ToolbarTertiaryInlineButton>
                  )}
                </ZoomOut>
                <ZoomIn>
                  {(props) => {
                    zoomInProps.current = props;
                    return (
                      <ToolbarTertiaryInlineButton
                        onClick={() => {
                          props.onClick();
                          setUserClickInfoTracking &&
                            incrementClickCount(
                              "ZoomIn",
                              fileName || "",
                              currentPage ? currentPage?.current + 1 : 1,
                              setUserClickInfoTracking
                            );
                        }}
                        disabled={!!linking}
                        title="Zoom in"
                        aria-label="Zoom in"
                      >
                        <ZoomInIcon />
                      </ToolbarTertiaryInlineButton>
                    );
                  }}
                </ZoomIn>
                <Zoom>
                  {(props) => {
                    zoomProps.current = props;
                    return (
                      <ToolbarTertiaryInlineButton
                        onClick={() => {
                          props.onZoom(SpecialZoomLevel.PageWidth);
                          setUserClickInfoTracking &&
                            incrementClickCount(
                              "ZoomToFit",
                              fileName || "",
                              currentPage ? currentPage?.current + 1 : 1,
                              setUserClickInfoTracking
                            );
                        }}
                        disabled={!!linking}
                        title="Zoom to fit content"
                        aria-label="Zoom to fit content"
                      >
                        <FullscreenIcon />
                      </ToolbarTertiaryInlineButton>
                    );
                  }}
                </Zoom>
              </ToolbarItemWrapper>
              {!hideSearchInAttachments && (
                <Box className={toolbarClasses.toolbarControls}>
                  <Search>
                    {(props) => {
                      // The search props have to be up-to-date
                      // Therefore we need to register them on each re-render
                      if (attachmentsMultiSearchHolder && currentAttachmentId) {
                        attachmentsMultiSearchHolder.utilities.registerAttachmentSearchProps(
                          currentAttachmentId,
                          props
                        );
                        textInputRef &&
                          attachmentsMultiSearchHolder.utilities.registerAttachmentSearchInputRef(
                            currentAttachmentId,
                            textInputRef
                          );
                      }

                      return (
                        <>
                          <ToolbarItemWrapper style={{ flexShrink: 4 }}>
                            <TextField
                              inputRef={textInputRef}
                              value={searchText}
                              onChangeValue={(text) => onSearchInputChangeValue(text, props)}
                              placeholder={`Search text in ${viewerConfig?.entityLabel || "attachments"}`}
                              title={`Search text in ${viewerConfig?.entityLabel || "attachments"}`}
                              aria-label={`Search text in ${viewerConfig?.entityLabel || "attachments"}`}
                              disabled={!!linking}
                              onKeyDown={(e) => onSearchInputKeyDown(e, props)}
                              classes={searchClasses}
                              InputProps={{
                                endAdornment: (
                                  <>
                                    {searchText && loadingRemainingAttachments ? (
                                      <CircularProgress
                                        size={20}
                                        style={{ opacity: "0.6", display: "flex", alignItems: "center" }}
                                      />
                                    ) : showSearchResults && searchText ? (
                                      <>
                                        <ToolbarBody3
                                          style={{
                                            maxWidth: "max-content",
                                            whiteSpace: "nowrap",
                                            textAlign: "right",
                                            marginRight: "10px",
                                            paddingLeft: "10px",
                                          }}
                                        >
                                          {calculateCurrentMatchNumber(props)} of {calculateTotalMatchesNumber(props)}
                                        </ToolbarBody3>
                                        <SearchBarTertiaryInlineButton
                                          style={{ paddingTop: 12 }}
                                          onClick={onClearSearchInputValue}
                                          title="Clear search box"
                                        >
                                          <ClearIcon style={{ height: 20, width: 20 }} />
                                        </SearchBarTertiaryInlineButton>
                                      </>
                                    ) : (
                                      <SearchBarTertiaryInlineButton
                                        style={{ paddingTop: 12 }}
                                        disabled={!props.keyword}
                                        onClick={() => doSearch(props)}
                                        title="Search"
                                      >
                                        <SearchIcon style={{ height: 20, width: 20 }} />
                                      </SearchBarTertiaryInlineButton>
                                    )}
                                  </>
                                ),
                              }}
                            />
                          </ToolbarItemWrapper>
                          <ToolbarItemWrapper>
                            <ToolbarTertiaryInlineButton
                              disabled={calculateTotalMatchesNumber(props) === 0 || !searchText || !!linking}
                              onClick={() => doJumpToPreviousMatch({ props, autoFocusInput: false })}
                              title="Jump to previous match"
                              aria-label="Jump to previous match"
                            >
                              <KeyboardArrowUp />
                            </ToolbarTertiaryInlineButton>
                            <ToolbarTertiaryInlineButton
                              disabled={calculateTotalMatchesNumber(props) === 0 || !searchText || !!linking}
                              onClick={() => doJumpToNextMatch({ props, autoFocusInput: false })}
                              title="Jump to next match"
                              aria-label="Jump to next match"
                            >
                              <KeyboardArrowDown />
                            </ToolbarTertiaryInlineButton>
                          </ToolbarItemWrapper>
                        </>
                      );
                    }}
                  </Search>
                </Box>
              )}
            </Box>
            {viewerConfig?.closeDialogFunction && (
              <IconButton
                onClick={viewerConfig.closeDialogFunction}
                disableFocusRipple={true}
                size="small"
                style={{ marginRight: "4px", marginLeft: "16px" }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            )}
          </ToolbarWrapper>
        );
      }}
    </Toolbar>
  );
}

interface ToggleListButtonProps {
  openSidePanel?: boolean;
  setOpenSidePanel?: Dispatch<SetStateAction<boolean>>;
  viewerConfig?: AttachmentViewerConfig;
}
export const ToggleListButton = ({ setOpenSidePanel, openSidePanel, viewerConfig }: ToggleListButtonProps) => {
  const { linking } = useContext(HighlightStateContext);
  const labelCaption = `${openSidePanel ? "Hide" : "Show"} ${viewerConfig?.sidePanelLabel || "attachment list"}`;
  return (
    <ToolbarTertiaryInlineButton
      style={{ padding: 0 }}
      onClick={() => setOpenSidePanel?.((prevOpenSidePanel) => !prevOpenSidePanel)}
      disabled={!!linking}
      title={labelCaption}
      aria-label={labelCaption}
    >
      <Box display="flex" alignItems="center">
        <Box style={{ width: "15%" }}>
          <Fade in={openSidePanel} mountOnEnter unmountOnExit>
            <Box display="flex" alignItems="center" justifyContent="center" mr={1}>
              <ArrowBack />
            </Box>
          </Fade>
          <Fade in={!openSidePanel} mountOnEnter unmountOnExit>
            <Box display="flex" alignItems="center" justifyContent="center" mr={1}>
              <FormatListBulleted />
            </Box>
          </Fade>
        </Box>
      </Box>
    </ToolbarTertiaryInlineButton>
  );
};

type ToolbarStyles = {
  crrStyling?: boolean;
  fromImageViewer?: boolean;
};

export const useToolbarStyles = makeStyles((theme) => ({
  toolbarControls: {
    alignItems: "center",
    display: "flex",
  },
  toolbarLeftControls: {
    marginLeft: theme.spacing(2),
    justifyContent: "flex-start",
  },
  toolbarRightControls: {
    flexGrow: 2,
    justifyContent: ({ crrStyling }: ToolbarStyles) => (crrStyling ? "flex-end" : "center"),
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const ToolbarItemWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  paddingRight: theme.spacing(3),
  [theme.breakpoints.down("xl")]: {
    paddingRight: theme.spacing(1),
  },
  flexShrink: 2,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const ToolbarWrapper = styled(({ crrStyling, ...other }) => <div {...other} />)<Theme, { crrStyling?: boolean }>(
  ({ theme, crrStyling }) => ({
    alignItems: "center",
    display: "flex",
    justifyContent: crrStyling ? "flex-start" : "center",
    backgroundColor: colorsLight.primary.contrastText,
    height: "100%",
    width: "100%",
  })
);

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ToolbarTertiaryInlineButton = styled(InlineButton)(({ theme }) => ({
  color: colorsLight.font.light,
  padding: theme.spacing(0.75, 0.75, 0),
  minWidth: 20,
  "&:focus": {
    color: colorsLight.font.light,
  },
  "&:hover,&:active": {
    color: colorsLight.primary.main,
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SearchBarTertiaryInlineButton = styled(InlineButton)({
  minWidth: 20,
  color: colorsLight.font.light,
  "&:focus": {
    color: colorsLight.font.light,
  },
  "&:hover,&:active": {
    color: colorsLight.primary.main,
  },
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ToolbarBody3 = styled(({ disabled, ...other }) => <div {...other} />)<Theme, { disabled?: boolean }>(
  ({ theme, disabled }) => ({
    color: disabled ? colorsLight.gray.dark : colorsLight.font.light,
    fontFamily: "Gilroy-Medium",
    fontSize: "13px",
    fontWeight: 400,
  })
);

interface StyleProps {
  hasViewerConfig?: boolean;
}

const useStylesSearchBar = makeStyles((theme) => ({
  root: {
    verticalAlign: "middle",
    width: ({ hasViewerConfig }: StyleProps) => (hasViewerConfig ? "50vw" : "25vw"),
    [theme.breakpoints.down("lg")]: {
      width: "20vw",
    },
    height: 24,
    "& .MuiFilledInput-root": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      fontSize: 13,
    },
    "& .MuiFilledInput-input": {
      color: colorsLight.font.light,
    },
  },
}));
