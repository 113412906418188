import React, { useState, Dispatch, SetStateAction } from "react";
import DocumentNavigator from "components/DocumentViewer/DocumentNavigator";
import { ReactNode } from "react";
import Draggable from "react-draggable";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, Box, Grid, makeStyles } from "@material-ui/core";
import { colorsLight } from "@coherehealth/common";
import { NotificationViewerItem } from "../util/notificationUtil";
import { NotificationViewerConfig } from "../util/viewerUtil";

interface Props {
  url: string;
  name?: string;
  notifications?: NotificationViewerItem[];
  setNotifications?: React.Dispatch<React.SetStateAction<NotificationViewerItem[]>>;
  notificationIndexOpen?: number;
  handleNotificationClick?: (index: number, landingPage?: number) => void;
  openSidePanel?: boolean;
  setOpenSidePanel?: Dispatch<SetStateAction<boolean>>;
  onRotate?: (arg0: number[]) => void;
  rotation?: number[];
  sidePanel?: ReactNode;
  viewerConfig?: NotificationViewerConfig;
}

const ROTATION_DEGREES = [0, 270, 180, 90];

export default function NotificationImageViewer({
  url,
  name,
  notifications,
  setNotifications,
  notificationIndexOpen,
  handleNotificationClick,
  openSidePanel,
  setOpenSidePanel,
  onRotate,
  rotation,
  sidePanel,
  viewerConfig,
}: Props) {
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState((rotation && rotation[0]) || 0);
  const crrClasses = useCrrStyles();

  const doSetRotate = (r: number) => {
    setRotate(r);
    onRotate && onRotate([r]);
  };

  const SidePanelComponent = viewerConfig?.sidePanelComponent;

  return (
    <Grid container direction="column" className={crrClasses.imageViewerRoot}>
      <div className={crrClasses.toolbarContainer}>
        {/* DocumentNavigator is a simplified toolbar */}
        <DocumentNavigator
          currentPage={1}
          totalPages={1}
          scale={scale}
          setScale={setScale}
          rotate={rotate}
          setRotate={doSetRotate}
          rotateDegrees={ROTATION_DEGREES}
          crrStyling={true}
          openSidePanel={openSidePanel}
          setOpenSidePanel={setOpenSidePanel}
          viewerConfig={viewerConfig}
        />
      </div>
      <Box display="flex" className={crrClasses.viewerContainer}>
        <Box>
          {notifications && handleNotificationClick && SidePanelComponent && (
            <SidePanelComponent
              notifications={notifications}
              setNotifications={setNotifications}
              handleNotificationClick={handleNotificationClick}
              notificationIndexOpen={notificationIndexOpen}
              openSidePanel={openSidePanel}
            />
          )}
        </Box>
        <DocumentWrapper>
          <Draggable>
            <div>
              <img
                style={{
                  transform: `rotate(${rotate}deg) scale(${scale}, ${scale})`,
                  maxHeight: "100%",
                  maxWidth: "100%",
                }}
                src={url}
                alt={name || "unknown"}
              />
            </div>
          </Draggable>
        </DocumentWrapper>
      </Box>
    </Grid>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DocumentWrapper = styled("div")(({ theme }) => ({
  overflow: "auto",
  position: "relative",
  height: "100%",
  width: "100%",
  background: colorsLight.background.light,
  "& .react-draggable": {
    cursor: "grab",
    margin: "auto",
    marginTop: "5%",
    marginBottom: "15%",
    "&.react-draggable-dragging": {
      cursor: "grabbing",
    },
  },
}));

const useCrrStyles = makeStyles((theme) => ({
  imageViewerRoot: {
    borderRight: `1px solid ${colorsLight.gray.divider}`,
  },
  toolbarContainer: {
    height: "38px",
  },
  viewerContainer: {
    height: "calc(100vh - 38px)",
  },
}));
