import React, { useEffect, useState } from "react";

import SearchFields from "./SearchFields";
import { StatusCheckResponse } from "./checkStatusApi";
import { NoResults, FaxResult, ServiceRequestResult } from "./ResultCard";
import "./CheckStatusPageBody.css";

const calculateResultsHeight = (numCards: number) => {
  const cardHeight = 180 + 24; // height plus margin
  return cardHeight * numCards;
};

export default function CheckStatusPageBody() {
  const [statusResults, setStatusResults] = useState<StatusCheckResponse | null>(null);
  const [resultsHeight, setResultsHeight] = useState(0);
  const [resultsVisible, setResultsVisible] = useState(!!statusResults);

  useEffect(() => {
    const newHeight = calculateResultsHeight(!!statusResults ? Math.max(1, statusResults?.results?.length || 0) : 0);
    setTimeout(() => {
      setResultsHeight(newHeight);
      setResultsVisible(!!statusResults);
    }, 50);
  }, [statusResults]);

  return (
    <>
      <h1 className="statusBodyTitle">Check the status of your pre-authorizations</h1>
      <SearchFields
        setStatusResults={setStatusResults}
        onSearchClick={() => {
          // hide the results while the new search is loading
          setResultsHeight(0);
          setResultsVisible(false);
        }}
      />
      <div style={{ maxHeight: resultsHeight }} className={`statusBodyResults${resultsVisible ? " isVisible" : ""}`}>
        {statusResults?.results?.length === 0 && <NoResults />}
        {statusResults?.results?.map((result, idx) =>
          result.resultType === "serviceRequest" ? (
            <ServiceRequestResult key={result.cohereId} result={result} />
          ) : (
            <FaxResult key={result.cohereId} result={result} />
          )
        )}
      </div>
    </>
  );
}
