import { useContext } from "react";
import { HighlightStateContext } from "@coherehealth/common";
import { useClinicalReviewNext } from "components/ClinicalReview/next/clinical-review-store";
import { setLeftTabAction } from "components/ClinicalReview/next/actions";
import { LeftPanelTabs, tabProps } from "components/ClinicalReview/reviewUtils/utils";
import { InsightsPanel } from "components/ClinicalReview/reviewUtils/InsightsPanel";
import { useInsightsPanel } from "components/ClinicalReview/reviewUtils/useInsightsPanel";
import { useStyles as useAttachmentStyles } from "components/ClinicalReview/reviewUtils/useAttachment";
import { alpha } from "@mui/material/styles";
import { LightbulbOutlined } from "@mui/icons-material";
import { Box, IconButton, Badge } from "@mui/material";
import { Tab, Tabs, makeStyles } from "@material-ui/core";

interface LeftReviewTabsNextProps {
  aModalIsOpen?: boolean;
  showClaimsHistoryTab?: boolean;
  showRequestInfoTab?: boolean;
}

export const LeftReviewTabsNext = ({
  aModalIsOpen,
  showClaimsHistoryTab,
  showRequestInfoTab,
}: LeftReviewTabsNextProps) => {
  const { state, dispatch } = useClinicalReviewNext();
  const {
    serviceRequest,
    featureFlagData: { claimHistoryFeature, updatedClinicalReviewNudges },
    ruleResultsForClinicalReview,
    attachmentsProps: { onSwitchAttachmentTab, newAttachmentsNumber },
    isReviewEditMode,
    leftColumnTab,
  } = state;
  const { linking, rightColumnTab } = useContext(HighlightStateContext);
  const setLeftColumnTab = (tab: LeftPanelTabs) => dispatch(setLeftTabAction(tab));

  const showInsightsButton = !!updatedClinicalReviewNudges && isReviewEditMode;
  const {
    openInsightsPanel,
    handleToggleInsightsPanel,
    popperId,
    panelButtonRef,
    numOfInsights,
    hideBadge,
    insightsAnchor,
    handleCloseInsightsPanel,
    hasInsights,
    insights,
  } = useInsightsPanel({
    reviewNudges: ruleResultsForClinicalReview?.reviewNudges,
    aModalIsOpen,
    showInsightsButton,
    rightColumnTab,
    serviceRequest,
  });

  const tabClasses = useTabStyles({ showInsightsButton });
  const attachmentClasses = useAttachmentStyles();

  return (
    <>
      {linking ? (
        <div style={{ height: "48px", background: "#41477A" }} />
      ) : (
        <Box sx={{ display: "flex", position: "sticky", top: 0, zIndex: 2 }}>
          <Tabs
            value={leftColumnTab}
            onChange={(_, index) => {
              if (index !== "ATTACHMENTS") {
                onSwitchAttachmentTab();
              }
              setLeftColumnTab(index);
            }}
            aria-label="review tabs"
            classes={{
              root: tabClasses.tabRoot,
              indicator: tabClasses.tabIndicator,
            }}
          >
            {showRequestInfoTab !== false && (
              <Tab
                label={"Request Information"}
                {...tabProps("REQUEST_INFORMATION")}
                id="request-information-tab"
                className={tabClasses.panelTab}
              />
            )}
            <Tab
              label={
                <span>
                  {"Attachments"}
                  {newAttachmentsNumber > 0 && (
                    <span className={attachmentClasses.newAttachmentNumber}>{`(${newAttachmentsNumber} new)`}</span>
                  )}
                </span>
              }
              {...tabProps("ATTACHMENTS")}
              id="attachments-tab"
              className={tabClasses.panelTab}
            />
            {showClaimsHistoryTab !== false && claimHistoryFeature && (
              <Tab
                label={"Claims History"}
                {...tabProps("CLAIM_HISTORY")}
                id="claims-tab"
                className={tabClasses.panelTab}
              />
            )}
            <Tab
              label={"Event History"}
              {...tabProps("EVENT_HISTORY")}
              id="event-history-tab"
              className={tabClasses.panelTab}
            />
          </Tabs>
          {showInsightsButton && (
            <Box className={tabClasses.insightsButtonContainer}>
              <IconButton
                sx={{
                  color: "primary.dark",
                  backgroundColor: (theme) => (openInsightsPanel ? alpha(theme.palette.primary.dark, 0.2) : undefined),
                  "&:hover": {
                    backgroundColor: (theme) => alpha(theme.palette.primary.dark, 0.2),
                  },
                }}
                onClick={handleToggleInsightsPanel}
                aria-describedby={popperId}
                data-testid="insights-button"
                ref={panelButtonRef}
              >
                <Badge color="error" badgeContent={numOfInsights} invisible={hideBadge}>
                  <LightbulbOutlined />
                </Badge>
              </IconButton>
            </Box>
          )}
          <InsightsPanel
            openInsightsPanel={openInsightsPanel}
            insightsAnchor={insightsAnchor}
            panelId={popperId}
            handleCloseInsightsPanel={handleCloseInsightsPanel}
            hasInsights={hasInsights}
            insights={insights}
            numOfInsights={numOfInsights}
            fetchingSrRules={ruleResultsForClinicalReview?.fetchingSrRules}
          />
        </Box>
      )}
    </>
  );
};

interface TabStyleProps {
  showInsightsButton?: boolean;
}

export const useTabStyles = makeStyles((theme) => ({
  panelTab: {
    "&:hover span": {
      opacity: 0.7,
    },
  },
  tabRoot: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRight: ({ showInsightsButton }: TabStyleProps) =>
      showInsightsButton ? 0 : `1px solid ${theme.palette.divider}`,
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    zIndex: 2,
    width: ({ showInsightsButton }: TabStyleProps) => (showInsightsButton ? "90%" : "100%"),
  },
  tabIndicator: {
    backgroundColor: theme.palette.text.primary,
  },
  insightsButtonContainer: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    zIndex: 2,
    width: "10%",
    borderRight: ({ showInsightsButton }: TabStyleProps) =>
      showInsightsButton ? `1px solid ${theme.palette.divider}` : 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: `${theme.spacing(1)}px`,
  },
}));
