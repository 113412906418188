import { ProcedureCode, ServiceRequestResponse, ServiceRequestSearchResponse } from "@coherehealth/core-platform-api";
import PartialProcedureCodeApproval from "../PartialApproval/PartialProcedureCodeApproval";
import PartialUnitsApproval from "../PartialApproval/PartialUnitsApproval";
import { ChangeStatusFormState } from "../utils";

interface PartiallyApprovedFormProps {
  setFormState: (value: React.SetStateAction<ChangeStatusFormState>) => void;
  formState: ChangeStatusFormState;
  selectedServiceRequest: ServiceRequestResponse | ServiceRequestSearchResponse;
}

export default function PartiallyApprovedForm(props: PartiallyApprovedFormProps): JSX.Element {
  const { formState, selectedServiceRequest, setFormState } = props;
  return (
    <>
      <PartialProcedureCodeApproval
        requestedProcedureCodes={selectedServiceRequest.procedureCodes || []}
        approvedProcedureCodes={formState.partialApproval?.approvedProcedureCodes || []}
        setApprovedProcedureCodes={(procedureCodes: ProcedureCode[]) => {
          setFormState((prevFormState) => ({
            ...prevFormState,
            partialApproval: {
              ...prevFormState.partialApproval,
              approvedProcedureCodes: procedureCodes,
            },
          }));
        }}
      />
      {Boolean(selectedServiceRequest.units) && (
        <PartialUnitsApproval
          requestedUnits={selectedServiceRequest.units || 0}
          approvedUnits={formState.partialApproval?.approvedUnits || 0}
          setApprovedUnits={(units: number) => {
            setFormState((prevFormState) => ({
              ...prevFormState,
              partialApproval: {
                ...prevFormState.partialApproval,
                approvedUnits: units,
              },
            }));
          }}
          healthPlanName={selectedServiceRequest.healthPlanName || ""}
        />
      )}
    </>
  );
}
