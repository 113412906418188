import { Dispatch, SetStateAction, useContext, useRef, useState } from "react";
import { Grid, useTheme } from "@material-ui/core";
import {
  Caption,
  InlineButton,
  PrimaryButton,
  InformativeModal,
  responsiveFooterMaxWidthMdValue,
} from "@coherehealth/common";
import useResponsiveMdWidth from "hooks/useResponsiveMdWidth";
import useChangeFooterHeightIfError from "hooks/useChangeFooterHeightIfError";
import ClipboardMissingInfo from "components/images/ClipboardMissingInfo";
import FaxReferralsBackButton from "./FaxReferralsBackButton";
import { ReferralsNavigationContext } from "./ReferralsNavigationContext";
import useFaxFooterStyles from "hooks/useFaxFooterStyles";

interface Props {
  onPrimaryButtonClick: () => void;
  showError?: boolean;
  errorCaptionText?: string;
  primaryButtonDisabled: boolean;
  primaryButtonLoading?: boolean;
  primaryButtonText: string;
  setFooterHeight: Dispatch<SetStateAction<number>>;
}

const FaxReferralsFooter = ({
  primaryButtonDisabled,
  onPrimaryButtonClick,
  primaryButtonLoading,
  primaryButtonText,
  errorCaptionText,
  showError,
  setFooterHeight,
}: Props) => {
  const { workflowStep, navigateBack, handleCancel } = useContext(ReferralsNavigationContext);

  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const classes = useFaxFooterStyles();
  const theme = useTheme();

  const elementRef = useRef<HTMLDivElement>(null);
  const { responsiveMdWidth } = useResponsiveMdWidth(elementRef, responsiveFooterMaxWidthMdValue);

  const containerClass = responsiveMdWidth ? classes.fullSizeFooter : classes.wrappedFooter;
  const hideBackButton = workflowStep === "REFERRAL_REQUEST_DETAILS";

  useChangeFooterHeightIfError(elementRef, setFooterHeight, responsiveMdWidth, showError);

  return (
    <Grid container className={containerClass} alignItems={"center"} ref={elementRef}>
      <Grid
        item
        xs={responsiveMdWidth ? 2 : 12}
        container
        spacing={4}
        alignItems={"flex-start"}
        justifyContent={responsiveMdWidth ? "flex-start" : "center"}
      >
        <Grid item style={responsiveMdWidth ? undefined : { marginTop: 16 }}>
          <InlineButton
            data-tracking-id="cancel-fax-referral"
            onClick={() => setCancelModalOpen(true)}
            className={classes.cancelButton}
            warning
          >
            Cancel
          </InlineButton>
        </Grid>
      </Grid>
      <Grid item xs>
        <Grid
          container
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          style={{ flexWrap: "nowrap", flexDirection: "column" }}
        >
          {showError && (
            <Grid item className={classes.secondaryContainer} style={{ paddingLeft: 118, alignSelf: "flex-start" }}>
              <Caption className={classes.caption} data-public>
                {errorCaptionText}
              </Caption>
            </Grid>
          )}
          <Grid
            container
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
            style={{ display: "flex", flexDirection: "row", paddingTop: showError ? 16 : 0 }}
          >
            {!hideBackButton && (
              <Grid item className={classes.secondaryContainer} style={{ paddingRight: 24 }}>
                <FaxReferralsBackButton navigateBack={navigateBack} />
              </Grid>
            )}
            <Grid item>
              <PrimaryButton
                disabled={primaryButtonDisabled}
                onClick={() => {
                  onPrimaryButtonClick();
                }}
                loading={primaryButtonLoading}
                data-public
                className={classes.primaryWrappedButton}
              >
                {primaryButtonText}
              </PrimaryButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <InformativeModal
        open={cancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        icon={<ClipboardMissingInfo style={{ color: theme.palette.warning.dark }} />}
        headerText={"Are you sure you want to cancel this referral?"}
        subHeaderText="Your progress will not be saved."
        primaryButtonText="Yes, cancel"
        primaryButtonAction={() => {
          if (!!handleCancel) {
            handleCancel();
          }
        }}
        tertiaryButtonText={"No, return to referral"}
        tertiaryButtonAction={() => setCancelModalOpen(false)}
        showDivider
      />
    </Grid>
  );
};

export default FaxReferralsFooter;
