export default function CompassIcon() {
  return (
    <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42 84C65.196 84 84 65.196 84 42C84 18.804 65.196 0 42 0C18.804 0 0 18.804 0 42C0 65.196 18.804 84 42 84Z"
        fill="#D1C7E4"
      />
      <path
        d="M42 71.5311C58.3097 71.5311 71.5312 58.3095 71.5312 41.9999C71.5312 25.6902 58.3097 12.4686 42 12.4686C25.6903 12.4686 12.4688 25.6902 12.4688 41.9999C12.4688 58.3095 25.6903 71.5311 42 71.5311Z"
        fill="#6948A6"
      />
      <path
        d="M51.0158 64.3297C63.3505 59.3512 69.3138 45.3162 64.3353 32.9816C59.3568 20.647 45.3217 14.6836 32.9871 19.6621C20.6525 24.6406 14.6892 38.6757 19.6677 51.0103C24.6462 63.3449 38.6812 69.3082 51.0158 64.3297Z"
        fill="white"
      />
      <path
        d="M42.0003 45.3992L49.3635 49.363L45.3997 41.9998L49.3635 34.6367L42.0003 38.6005L34.6372 34.6367L38.601 41.9998L34.6372 49.363L42.0003 45.3992Z"
        fill="#DEDFE1"
      />
      <path
        d="M45.1174 45.1171L55.5059 41.9999L45.1174 38.8827L42.0003 28.4943L38.8831 38.8827L28.4946 41.9999L38.8831 45.1171L42.0003 55.5055L45.1174 45.1171Z"
        fill="#EA1D63"
      />
      <path
        d="M44.1004 41.9999C44.1004 43.1615 43.162 44.0999 42.0004 44.0999C40.8388 44.0999 39.9004 43.1615 39.9004 41.9999C39.9004 40.8383 40.8388 39.8999 42.0004 39.8999C43.162 39.8999 44.1004 40.8383 44.1004 41.9999Z"
        fill="white"
      />
    </svg>
  );
}
