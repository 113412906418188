export const LOOKUP_SEARCH = "LookupSearch";
export const DATE_PICKER = "DatePicker";
export const MULTI_SELECT = "MultiSelect";
export const SINGLE_SELECT = "SingleSelect";
export const TEXT_FIELD = "TextField";
export const DATE_RANGE = "DateRange";

export interface BaseFilterConfig {
  filterType: "LookupSearch" | "DatePicker" | "MultiSelect" | "SingleSelect" | "TextField" | "DateRange";
  key: string;
  label: string;
  apiParamName?: string;
}

export type FilterValues = { [key: string]: unknown };

export function dateFilterValue(value: Date | string | null) {
  if (!value) {
    return null;
  }

  const dateValue = typeof value === "string" ? new Date(value) : value;
  return dateValue.toJSON();
}
