import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MuiFormControl, { FormControlProps } from "@material-ui/core/FormControl";
import MuiFormControlLabel from "@material-ui/core/FormControlLabel";
import MuiFormHelperText from "@material-ui/core/FormHelperText";
import MuiRadio from "@material-ui/core/Radio";
import MuiRadioGroup from "@material-ui/core/RadioGroup";
import { colorsLight, colorsDark } from "../../themes/colors";
import uniqueId from "lodash/uniqueId";
import MuiFormLabel from "@material-ui/core/FormLabel";
import MuiGrid, { GridSize } from "@material-ui/core/Grid";

const useStylesLabel = makeStyles((theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  return createStyles({
    root: {
      ...theme.typography.caption,
      marginBottom: theme.spacing(1),
      color: isDarkTheme ? colorsDark.font.secondary : theme.palette.text.secondary,
      "&.Mui-focused": {
        color: isDarkTheme ? colorsDark.font.secondary : theme.palette.text.secondary,
      },
      "&.Mui-error": {
        color: isDarkTheme ? theme.palette.error.main : theme.palette.error.dark,
      },
    },
    focused: {},
    error: {},
  });
});
const useStylesHelper = makeStyles((theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  return createStyles({
    root: {
      "&.Mui-error": {
        color: isDarkTheme ? theme.palette.error.main : theme.palette.error.dark,
      },
    },
    error: {},
  });
});
const useStylesOptionLabel = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      marginRight: theme.spacing(3),
      "& .MuiTypography-root": {
        width: "100%",
      },
    },
  });
});

const useStylesRadio = makeStyles((theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  return createStyles({
    root: {
      color: isDarkTheme ? colorsDark.gray.light : colorsDark.gray.dark,
      "&.Mui-disabled": {
        color: isDarkTheme ? colorsLight.font.secondary : colorsLight.gray.dark,
      },
    },
    disabled: {},
  });
});

const useStylesFormControl = makeStyles(() => {
  return createStyles({
    root: {
      minWidth: "100%",
    },
  });
});

const useStylesRadioGroupOptionChildren = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(4),
    },
  });
});

export interface RadioGroupOption<T = string> {
  id: T;
  label: React.ReactNode;
  disabled?: boolean;
  widthOverride?: GridSize;
  children?: React.ReactNode;
  hasTooltipAsChild?: boolean;
}
export interface RadioGroupProps<T = string> {
  /** Label for the group of radio buttons. */
  label?: React.ReactNode;
  /** Style overrides for label */
  labelClasses?: object;
  /** Helper text for the group of radio buttons. */
  helperText?: React.ReactNode;
  /** Array of options to display as radio buttons. `{ id: string, label: string, disabled?: boolean }` */
  options: RadioGroupOption<T>[];
  /** 'id' of the selected option. */
  value?: T;
  /** Function: the 'id' of the selected option is provided as the first argument. */
  onChange: (arg0: T) => any;
  optionsLabelClasses?: object;
  additionalRadioClasses?: object;
  additionalChildClasses?: object;
  /** By default, radio buttons are displayed in a column. Set this to true to display in a row. */
  row?: boolean;
  nowrap?: boolean;
  /** The width of the radio group options, default is 12 */
  radioGroupWidth?: GridSize;
  hasTooltipAsChild?: boolean;
}

// Temporary export for storybook documentation
export const RadioGroupForProps = (props: RadioGroupProps<string>) => {};

// omit 'css' prop to avoid Typescript error making it required
const RadioGroup = <T extends string = string>(
  props: RadioGroupProps<T> & Omit<FormControlProps, "onChange" | "css">
) => {
  const {
    label,
    helperText,
    options,
    value,
    onChange,
    row,
    nowrap,
    radioGroupWidth,
    labelClasses: additionalLabelClasses,
    optionsLabelClasses: additionalOptionsLabelClasses,
    additionalChildClasses,
    additionalRadioClasses,
    ...formControlProps
  } = props;
  const [labelId] = useState(() => uniqueId("RadioGroup"));
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // This type coercion should be ok because the RadioGroupOption.id is type T
    onChange(event?.target?.value as T);
  };
  const labelClasses = { ...useStylesLabel(), ...additionalLabelClasses };
  const optionsLabelClasses = { ...useStylesOptionLabel(), ...additionalOptionsLabelClasses };
  const helperClasses = useStylesHelper();
  const radioClasses = { ...useStylesRadio(), ...additionalRadioClasses };
  const formClasses = useStylesFormControl();
  const childrenClasses = { ...useStylesRadioGroupOptionChildren(), ...additionalChildClasses };
  return (
    <MuiFormControl component="fieldset" classes={formClasses} {...(formControlProps as any)}>
      {label && (
        <MuiFormLabel id={labelId} classes={labelClasses} data-public>
          {label}
        </MuiFormLabel>
      )}
      <MuiRadioGroup
        aria-labelledby={labelId}
        row={row}
        value={value}
        onChange={handleChange}
        aria-required={Boolean(formControlProps?.required)}
        {...(nowrap ? { style: { flexWrap: "nowrap" } } : {})}
      >
        <MuiGrid container>
          {options.map((option) => (
            <MuiGrid
              item
              key={option.id}
              xs={!row && (option.widthOverride || radioGroupWidth || 12)}
              {...(option.hasTooltipAsChild ? { style: { display: "flex", alignItems: "center" } } : {})}
            >
              <MuiFormControlLabel
                key={option.id}
                value={option.id}
                control={<MuiRadio color="primary" classes={radioClasses} />}
                label={option.label}
                disabled={formControlProps.disabled || option.disabled}
                classes={optionsLabelClasses}
                data-public
              />
              {option.children && (
                <MuiGrid classes={option.hasTooltipAsChild ? undefined : childrenClasses}>{option.children}</MuiGrid>
              )}
            </MuiGrid>
          ))}
        </MuiGrid>
      </MuiRadioGroup>
      {Boolean(helperText) && <MuiFormHelperText classes={helperClasses}>{helperText}</MuiFormHelperText>}
    </MuiFormControl>
  );
};

export default RadioGroup;
