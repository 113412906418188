import React from "react";

const ClipboardErrorIcon = () => {
  return (
    <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 52C0 23.2812 23.2812 0 52 0C80.7188 0 104 23.2812 104 52C104 80.7188 80.7188 104 52 104C23.2812 104 0 80.7188 0 52Z"
        fill="#F9EAEA"
      />
      <path d="M72.2821 30.1274H29.9004V79.5581H72.2821V30.1274Z" fill="#A1AAB4" />
      <path d="M62.2878 33.1228H32.8994V76.5566H69.2667V40.1192L62.2878 33.1228Z" fill="white" />
      <path
        d="M60.6908 24.0065H55.0446C55.0446 21.5165 53.2736 19.5 51.0817 19.5C48.8899 19.5 47.1188 21.5165 47.1188 24.0065H41.4726L39.877 34.0013H62.2689L60.6908 24.0065Z"
        fill="#212936"
      />
      <path
        d="M44.0161 40.0172H41.0001V37.0012H38.931V40.0172H35.915V42.0863H38.931V45.1023H41.0001V42.0863H44.0161V40.0172Z"
        fill="#EA1D63"
      />
      <path d="M60.5159 48.4521H41.6484V49.8725H60.5159V48.4521Z" fill="#89D3DD" />
      <path d="M65.7764 71.1912H58.2539V72.6115H65.7764V71.1912Z" fill="#89D3DD" />
      <path d="M65.7761 53.6575H36.3877V55.0778H65.7761V53.6575Z" fill="#C2C2C2" />
      <path d="M65.7761 57.9177H36.3877V59.338H65.7761V57.9177Z" fill="#C2C2C2" />
      <path d="M65.7761 62.1833H36.3877V63.6037H65.7761V62.1833Z" fill="#C2C2C2" />
      <path d="M49.5213 66.4409H36.3877V67.8613H49.5213V66.4409Z" fill="#C2C2C2" />
      <path d="M62.2871 40.1192H69.266L62.2871 33.1228V40.1192Z" fill="#F5F5F5" />
      <g clipPath="url(#clip0_5946_17095)">
        <circle cx="71.514" cy="35.1136" r="15.6136" fill="white" />
        <path
          d="M71.514 19.5C62.8797 19.5 55.9004 26.4793 55.9004 35.1136C55.9004 43.748 62.8797 50.7272 71.514 50.7272C80.1483 50.7272 87.1276 43.748 87.1276 35.1136C87.1276 26.4793 80.1483 19.5 71.514 19.5ZM79.3208 40.7189L77.1193 42.9204L71.514 37.3151L65.9087 42.9204L63.7072 40.7189L69.3125 35.1136L63.7072 29.5083L65.9087 27.3068L71.514 32.9121L77.1193 27.3068L79.3208 29.5083L73.7155 35.1136L79.3208 40.7189Z"
          fill="#E53935"
        />
      </g>
      <defs>
        <clipPath id="clip0_5946_17095">
          <rect width="31.2272" height="31.2272" fill="white" transform="translate(55.9004 19.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ClipboardErrorIcon;
