import { registerRoute } from "../server";

export default function registerRoutes() {
  registerRoute({
    id: "getLicenseAgreement",
    route: "licenseAgreement",
    method: "get",
    generateResponse: function (config, schema, request) {
      return (schema as any).licenseAgreements.first();
    },
  });

  registerRoute({
    id: "licenseAgreementSign",
    route: "licenseAgreement/sign",
    method: "post",
    generateResponse: function (config, schema, request) {
      return undefined;
    },
  });
}
