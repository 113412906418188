import { registerRoute } from "../server";

export default function registerRoutes() {
  registerRoute({
    id: "userGet",
    route: "user/:id",
    method: "get",
    generateResponse: function (config, schema, request) {
      const urlParams = request.params;
      if (request.params.id === "user1") {
        return {
          id: urlParams.id,
          name: "Test User",
        };
      } else if (request.params.id === "user4") {
        return {
          id: urlParams.id,
          pendingOrganizationId: "123",
          name: "Test User",
        };
      } else {
        return {
          id: urlParams.id,
          organization: "123",
          name: "Test User",
        };
      }
    },
  });

  registerRoute({
    id: "userGetQueryNavinet",
    route: "user/",
    method: "get",
    generateResponse: function (config, schema, request) {
      if (request.queryParams?.query === "navinet-user@coherehealth.com") {
        return [
          {
            name: "Navinet User",
            email: "navinet-user@coherehealth.com",
            isSsoUser: false,
          },
        ];
      } else {
        return [];
      }
    },
  });
}
