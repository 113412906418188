import { Server, Request, Response, Registry, RestSerializer } from "miragejs";
import Schema from "miragejs/orm/schema";

declare global {
  interface Window {
    mirageServer?: Server;
    mirageRouteConfigs?: { [id: string]: RouteConfig };
  }
}

export interface RouteConfig {
  slow: boolean;
  slowMs: number;
  error: boolean;
  errorCode: number;
}

/* TODO figure out how to specify types for the Registry using the registered models/factories from above.
example https://github.com/miragejs/miragejs/pull/262/files/364ed6236a7dcdc8a5729ef9d55790c32f63221d
declare const schema: Schema<Registry<
  { person: typeof PersonModel },
  { person: typeof PersonFactory }
>>;
*/
interface RegisterRouteOptions {
  id: string;
  method: "get" | "post" | "patch" | "put" | "delete" | "options";
  route: string;
  routeConfig?: Partial<RouteConfig>;
  updateConfig?: (
    this: Server,
    config: RouteConfig,
    schema: Schema<Registry<any, any>>,
    request: Request
  ) => RouteConfig;
  generateResponse?: (
    this: Server,
    config: RouteConfig,
    schema: Schema<Registry<any, any>>,
    request: Request
  ) => any;
  generateErrorReponse?: (
    this: Server,
    config: RouteConfig,
    schema: Schema<Registry<any, any>>,
    request: Request
  ) => any;
}

const DEFAULT_ERROR_RESPONSE = {
  errorCode: -1,
  errorDescription: "Test Error Message",
};
export const registerRoute: (options: RegisterRouteOptions) => void =
  function ({
    id,
    method,
    route,
    routeConfig = {},
    updateConfig = null,
    generateResponse = () => null,
    generateErrorReponse = () => DEFAULT_ERROR_RESPONSE,
  }) {
    if (!window.mirageServer || !window.mirageRouteConfigs) {
      console.error("Please start the Mirage server.");
      return;
    }
    if (window.mirageRouteConfigs[id]) {
      console.error(`The route ${id} has already been registered.`);
      return;
    }
    window.mirageRouteConfigs[id] = {
      slow: false,
      slowMs: 2000,
      error: false,
      errorCode: 404,
      ...routeConfig,
    };

    window.mirageServer[method](
      route,
      function (this: Server, schema, request) {
        let config = window.mirageRouteConfigs![id];

        if (updateConfig) {
          config = updateConfig.apply(this, [{ ...config }, schema, request]);
        }

        let response: any;

        if (config.error) {
          if (config.errorCode === 404) {
            response = new Response(
              config.errorCode,
              {},
              { message: "Test raw error response from server" }
            );
          } else {
            const errorResponse = generateErrorReponse.apply(this, [
              config,
              schema,
              request,
            ]);
            response = new Response(config.errorCode, {}, errorResponse);
          }
        } else {
          response = generateResponse.apply(this, [config, schema, request]);
        }
        if (config.slow) {
          return new Promise((resolve) =>
            setTimeout(() => resolve(response), config.slowMs)
          );
        }
        return response;
      }
    );
  };

export const ApplicationSerializer = RestSerializer.extend({
  root: false,
  embed: true,
}) as typeof RestSerializer;
