import React from "react";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";

/** For Storybook */
export function TypographyWithProps(props: TypographyProps) {}

export const H1: typeof Typography = (props: TypographyProps) => <Typography variant="h1" {...props} />;
export const H2: typeof Typography = (props: TypographyProps) => <Typography variant="h2" {...props} />;
export const H3: typeof Typography = (props: TypographyProps) => <Typography variant="h3" {...props} />;
export const H4: typeof Typography = (props: TypographyProps) => <Typography variant="h4" {...props} />;
export const H5: typeof Typography = (props: TypographyProps) => <Typography variant="h5" {...props} />;
export const H6: typeof Typography = (props: TypographyProps) => <Typography variant="h6" {...props} />;
export const Subtitle1: typeof Typography = (props: TypographyProps) => <Typography variant="subtitle1" {...props} />;
export const Subtitle2: typeof Typography = (props: TypographyProps) => <Typography variant="subtitle2" {...props} />;
export const Body1: typeof Typography = (props: TypographyProps) => <Typography variant="body1" {...props} />;
export const Body2: typeof Typography = (props: TypographyProps) => <Typography variant="body2" {...props} />;
// eslint-disable-next-line cohere-react/no-mui-styled-import
export const Body3 = styled((props: TypographyProps) => <Typography variant="body1" {...props} />)({
  // When we move to MUI v5, then we can do this in a better way (see https://github.com/mui-org/material-ui/pull/22006)
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "15px",
  letterSpacing: "0px",
}) as typeof Typography;
export const ButtonTypography: typeof Typography = (props: TypographyProps) => (
  <Typography variant="button" {...props} />
);
export const Caption: typeof Typography = (props: TypographyProps) => <Typography variant="caption" {...props} />;
export const Overline: typeof Typography = (props: TypographyProps) => <Typography variant="overline" {...props} />;

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const VisuallyHidden = styled(Typography)({
  border: 0,
  clip: "rect(0 0 0 0)",
  height: 1,
  margin: -1,
  overflow: "hidden",
  padding: 0,
  position: "absolute",
  whiteSpace: "nowrap",
  width: 1,
}) as typeof Typography;
