import { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { colorsDark, colorsLight } from "../../themes";
import WarningIcon from "@material-ui/icons/Error";
import { Tooltip } from "../Tooltip";

interface MenuStyleProps {
  maxMenuHeight?: number | string | undefined;
  menuWidth?: number | undefined;
}

export const useStylesMenu = makeStyles(
  (theme) => {
    const isDarkTheme = theme.palette.type === "dark";
    return {
      paper: {
        backgroundColor: isDarkTheme ? "#171D26" : "#FFF",
        maxHeight: ({ maxMenuHeight }: MenuStyleProps) => maxMenuHeight,
        width: ({ menuWidth }: MenuStyleProps) => menuWidth,
        marginTop: 1 /* so it doesn't overlap with textField border */,
      },
    };
  },
  { index: 1 }
);

export const useStylesListSubheader = makeStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    "&:first-child": {
      borderTop: "none",
      display: "flex",
      margin: theme.spacing(2, 2, 2, 0),
      justifyContent: "space-between",
    },
  },
}));

export const useStylesMenuList = makeStyles({
  padding: {
    paddingTop: 0,
  },
});

interface SelectStyleProps {
  variant?: string | undefined;
}

export const useStylesSelect = makeStyles((theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  const colors = isDarkTheme ? colorsDark : colorsLight;
  return {
    select: ({ variant }: SelectStyleProps) => {
      const isStandardVariant = variant === "standard";
      return {
        "&:focus": {
          backgroundColor: isStandardVariant ? "transparent" : colors.background.input,
        },
      };
    },
    icon: ({ variant }: SelectStyleProps) => {
      const isStandardVariant = variant === "standard";
      return {
        color: isDarkTheme ? colorsDark.gray.light : theme.palette.text.primary,
        root: {
          "&$disabled": {
            color: colors.font.light,
          },
        },
        position: isStandardVariant ? "relative" : undefined,
        right: theme.spacing(2),
        transition: theme.transitions.create("transform"),
      };
    },
    disabled: {},
  };
});

export interface DropdownOption {
  id: string;
  label?: string | ReactNode;
  subLabel?: string;
  endAdornmentContent?: string;
}

interface WarningProps {
  warningText: string;
  asSelectedItem?: boolean;
}

interface WarningStyleProps {
  asSelectedItem?: boolean;
}
const useWarningIconStyles = makeStyles((theme) => {
  return {
    warningIcon: {
      marginRight: ({ asSelectedItem }: WarningStyleProps) => (asSelectedItem ? theme.spacing(1) : theme.spacing(2)),
      color: theme.palette.warning.dark,
      marginBottom: ({ asSelectedItem }: WarningStyleProps) => (asSelectedItem ? theme.spacing(1) : undefined),
    },
  };
});

export const Warning = ({ warningText, asSelectedItem }: WarningProps) => {
  const classes = useWarningIconStyles({ asSelectedItem });
  return (
    <Tooltip title={warningText} placement="top">
      <WarningIcon className={classes.warningIcon} />
    </Tooltip>
  );
};
