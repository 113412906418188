import React, { SVGProps } from "react";

export default function PurpleClipboardAuth(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
        fill="#D1C7E4"
      />
      <path d="M51.8972 20.33H16.6245V61.4842H51.8972V20.33Z" fill="#ACB3BA" />
      <path d="M43.5811 22.8282H19.1226V58.9704H49.3992V28.6463V22.8282H43.5811Z" fill="white" />
      <path
        d="M29.3527 44.0614L27.6926 31.9823L26.5068 35.3974H23.0286V34.3855H25.6847L28.1827 27.2393L29.5108 36.9784L30.9337 29.2314L32.5622 34.3855H33.8428L36.1985 27.5555L38.0641 35.9507L39.7084 30.0851L41.8428 34.3855H45.495V35.3974H41.0997L40.0088 33.1681L37.9377 40.5515L35.9614 31.6503L34.6807 35.3974H31.7084L31.2183 33.8638L29.3527 44.0614Z"
        fill="#CF1655"
      />
      <path
        d="M41.4772 16.9003H37.5563C37.5563 14.8291 36.0859 13.1532 34.2519 13.1532C32.4337 13.1532 30.9476 14.8291 30.9476 16.9003H27.0266C26.5839 16.9003 26.1887 17.2323 26.1254 17.675L25.082 24.1572C24.9871 24.7105 25.4298 25.2165 25.9831 25.2165H42.5049C43.0741 25.2165 43.5009 24.7105 43.4061 24.1572L42.3626 17.675C42.2994 17.2323 41.9199 16.9003 41.4772 16.9003Z"
        fill="#384358"
      />
      <path d="M46.5059 46.7191H22.0158V47.9681H46.5059V46.7191Z" fill="#C2C2C2" />
      <path d="M46.5058 50.5616H22.0157V51.8106H46.5058V50.5616Z" fill="#C2C2C2" />
      <path d="M34.2688 54.4185H22.0158V55.6676H34.2688V54.4185Z" fill="#C2C2C2" />
      <path
        d="M56.1964 62.1647C62.2563 62.1647 67.1688 57.2522 67.1688 51.1923C67.1688 45.1325 62.2563 40.22 56.1964 40.22C50.1366 40.22 45.2241 45.1325 45.2241 51.1923C45.2241 57.2522 50.1366 62.1647 56.1964 62.1647Z"
        fill="#6948A6"
      />
      <path
        d="M56.1982 59.6501C60.8698 59.6501 64.6567 55.8631 64.6567 51.1916C64.6567 46.5201 60.8698 42.7331 56.1982 42.7331C51.5267 42.7331 47.7397 46.5201 47.7397 51.1916C47.7397 55.8631 51.5267 59.6501 56.1982 59.6501Z"
        fill="white"
      />
      <path d="M64.6872 50.6551H62.6635V51.6828H64.6872V50.6551Z" fill="#212936" />
      <path d="M49.7459 50.6551H47.7222V51.6828H49.7459V50.6551Z" fill="#212936" />
      <path d="M56.7194 57.6268H55.6917V59.6506H56.7194V57.6268Z" fill="#212936" />
      <path d="M56.7194 42.6856H55.6917V44.7093H56.7194V42.6856Z" fill="#212936" />
      <path
        d="M57.762 50.7507L59.9755 45.3752L59.7699 45.2487L55.9596 49.644C55.5011 49.7072 55.0585 49.976 54.8055 50.4029C54.3312 51.1776 54.5683 52.1895 55.343 52.6638C56.1177 53.1381 57.1296 52.9009 57.6039 52.1262C57.8727 51.6993 57.9043 51.1934 57.762 50.7507Z"
        fill="#CF1655"
      />
    </svg>
  );
}
