import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { cohereDarkTheme, cohereLightTheme } from "../../themes";

// N.B. side effect of importing this is to load the font css
// amacleay note: unsure if this eslint-disable is necessary
// eslint-disable-next-line no-restricted-imports
import "@coherehealth/common/assets/themeFont.css";

export interface CommonProviderProps {
  /** Common theme to use. Defaults to "light" */
  theme?: "light" | "dark";
  children: React.ReactNode;
}

const DesignSystemProvider = ({ theme = "light", children }: CommonProviderProps) => {
  let themeToUse = theme === "dark" ? cohereDarkTheme : cohereLightTheme;
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={themeToUse}>
        {/* Use a react fragment to avoid invalid proptype error for the children*/}
        <>{children}</>
      </ThemeProvider>
    </>
  );
};

export default DesignSystemProvider;
