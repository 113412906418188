import React, { useEffect } from "react";
import HeaderAndProgressBar from "../HeaderAndProgressBar";
import { H1, InlineButton, Subtitle2 } from "@coherehealth/common";
import HourglassIcon from "../../images/HourglassIcon";
import FullScreenDisplay, { FullScreenDisplayProps } from "./FullScreenDisplay";
import { joinExistingOrganizationSurvey } from "util/alchemerURLs";
import { makeStyles } from "@material-ui/core";

export default function OnboardingVerificationUnderReview() {
  const link = joinExistingOrganizationSurvey;

  useEffect(() => {
    window.open(link);
  }, [link]);

  const classes = useStyles();

  const fullScreenDisplayProps: FullScreenDisplayProps = {
    headerAndProgressBar: <HeaderAndProgressBar showProgressBar={false} />,
    visualComponent: <HourglassIcon />,
    header: <H1>We're reviewing your account verification request</H1>,
    subtext: (
      <Subtitle2>
        This process may take a few days. You will receive an email notifying you if your account is verified. In the
        meantime, please complete your account registration in the new window, or{" "}
        <InlineButton component="a" href={link} rel="noreferrer" target={"_blank"} className={classes.clickHere}>
          click here
        </InlineButton>{" "}
        if the window hasn’t opened on it’s own.
      </Subtitle2>
    ),
    widthOverrides: {
      headerWidth: 950,
      subtextWidth: 653,
    },
  };

  return <FullScreenDisplay {...fullScreenDisplayProps} />;
}

const useStyles = makeStyles((theme) => ({
  clickHere: {
    padding: "0px 0px 1px 0px",
  },
}));
