import { UnitedStates } from "@coherehealth/core-platform-api";

export const POLICY_UNITED_STATES_OPTIONS: { id: UnitedStates; label: string }[] = [
  { id: "AK", label: "Alaska" },
  { id: "AL", label: "Alabama" },
  { id: "AR", label: "Arkansas" },
  { id: "AS", label: "American Samoa" },
  { id: "AZ", label: "Arizona" },
  { id: "CA", label: "California" },
  { id: "CO", label: "Colorado" },
  { id: "CT", label: "Connecticut" },
  { id: "DC", label: "District of Columbia" },
  { id: "DE", label: "Delaware" },
  { id: "FL", label: "Florida" },
  { id: "GA", label: "Georgia" },
  { id: "GU", label: "Guam" },
  { id: "HI", label: "Hawaii" },
  { id: "IA", label: "Iowa" },
  { id: "ID", label: "Idaho" },
  { id: "IL", label: "Illinois" },
  { id: "IN", label: "Indiana" },
  { id: "KS", label: "Kansas" },
  { id: "KY", label: "Kentucky" },
  { id: "LA", label: "Louisiana" },
  { id: "MA", label: "Massachusetts" },
  { id: "MD", label: "Maryland" },
  { id: "ME", label: "Maine" },
  { id: "MI", label: "Michigan" },
  { id: "MN", label: "Minnesota" },
  { id: "MO", label: "Missouri" },
  { id: "MP", label: "Northern Mariana Islands" },
  { id: "MS", label: "Mississippi" },
  { id: "MT", label: "Montana" },
  { id: "NC", label: "North Carolina" },
  { id: "ND", label: "North Dakota" },
  { id: "NE", label: "Nebraska" },
  { id: "NH", label: "New Hampshire" },
  { id: "NJ", label: "New Jersey" },
  { id: "NM", label: "New Mexico" },
  { id: "NV", label: "Nevada" },
  { id: "NY", label: "New York" },
  { id: "OH", label: "Ohio" },
  { id: "OK", label: "Oklahoma" },
  { id: "OR", label: "Oregon" },
  { id: "PA", label: "Pennsylvania" },
  { id: "PR", label: "Puerto Rico" },
  { id: "RI", label: "Rhode Island" },
  { id: "SC", label: "South Carolina" },
  { id: "SD", label: "South Dakota" },
  { id: "TN", label: "Tennessee" },
  { id: "TX", label: "Texas" },
  { id: "UT", label: "Utah" },
  { id: "VA", label: "Virginia" },
  { id: "VI", label: "Virgin Islands" },
  { id: "VT", label: "Vermont" },
  { id: "WA", label: "Washington" },
  { id: "WI", label: "Wisconsin" },
  { id: "WV", label: "West Virginia" },
  { id: "WY", label: "Wyoming" },
];

export const fullStateNameToAbbr = (fullStateName: string) => {
  return POLICY_UNITED_STATES_OPTIONS.find((obj) => obj.label === fullStateName)?.id;
};

export const stateAbbrToFullName = (abbreviation: string) => {
  return POLICY_UNITED_STATES_OPTIONS.find((obj) => obj.id === abbreviation)?.label;
};
