import React from "react";
import { Body2, useNetworkCheckStatusStyles } from "@coherehealth/common";
import { Grid } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { NetworkType } from "@coherehealth/core-platform-api";

interface Props {
  performingProviderOON: boolean;
  performingProviderPracticeOON: boolean;
  facilityOON: boolean;
  orderingProviderOON: boolean;
  careParticipantOON: boolean;
  careParticipantType?: string;
  oonExceptionConfig: "REQUIRED" | "OPTIONAL" | "OMIT";
  networkType?: NetworkType;
}

function getOONBannerText(
  orderingProviderOON: boolean,
  performingProviderOON: boolean,
  performingProviderPracticeOON: boolean,
  facilityOON: boolean,
  careParticipantOON: boolean,
  oonExceptionConfig: "REQUIRED" | "OPTIONAL" | "OMIT",
  careParticipantType?: string,
  networkType?: NetworkType
) {
  let oonPracticeCommon = "";

  oonPracticeCommon =
    performingProviderOON || orderingProviderOON
      ? "provider"
      : facilityOON
      ? "facility"
      : performingProviderPracticeOON
      ? "practice"
      : careParticipantOON
      ? careParticipantType
        ? careParticipantType
        : ""
      : "";

  let displayText = "";
  const capitalizedDisplayText = oonPracticeCommon.charAt(0).toUpperCase() + oonPracticeCommon.slice(1);
  if (networkType === undefined || networkType === "OON") {
    if (oonExceptionConfig === "REQUIRED") {
      displayText = `${capitalizedDisplayText}  is out-of-network. Please choose an in-network ${
        careParticipantOON ? oonPracticeCommon.toLowerCase() : oonPracticeCommon
      }  or provide reasoning for your out-of-network request below.`;
    } else if (oonExceptionConfig === "OPTIONAL") {
      displayText = `${capitalizedDisplayText} is out-of-network. You can switch to an in-network ${
        careParticipantOON ? oonPracticeCommon.toLowerCase() : oonPracticeCommon
      }, proceed with your out-of-network request, or ask for an exception below if you believe in-network rates should apply.`;
    } else if (oonExceptionConfig === "OMIT") {
      displayText = `${capitalizedDisplayText} is out-of-network. You can switch to an in-network ${
        careParticipantOON ? oonPracticeCommon.toLowerCase() : oonPracticeCommon
      } or proceed with your out-of-network request.`;
    }
  } else if (networkType === "OOA") {
    displayText = `${capitalizedDisplayText} is out-of-area`;
  }
  return displayText;
}

export default function OutOfNetworkBanner({
  performingProviderOON,
  facilityOON,
  performingProviderPracticeOON,
  orderingProviderOON,
  careParticipantOON,
  careParticipantType,
  oonExceptionConfig,
  networkType,
}: Props) {
  const classes = useNetworkCheckStatusStyles();
  return (
    <Grid item xs={12}>
      <div className={classes.oonStatusContainer}>
        <div className={classes.iconContainer}>
          <ErrorIcon className={classes.errorIcon} />
        </div>
        <div className={classes.verticallyCenteredContainer}>
          <div className={classes.verticallyCenteredItem}>
            <Body2 className={classes.warningOonStatusText}>
              {`${getOONBannerText(
                orderingProviderOON,
                performingProviderOON,
                performingProviderPracticeOON,
                facilityOON,
                careParticipantOON,
                oonExceptionConfig,
                careParticipantType,
                networkType
              )}`}
            </Body2>
          </div>
        </div>
      </div>
    </Grid>
  );
}
