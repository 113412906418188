import React, { useEffect, useState } from "react";
import SingleSelectDropdown from "../Dropdown/SingleSelectDropdown";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

interface IProps<T> {
  data: T[];
  onSegmentChange: (segment: T[]) => void;
  defaultRowsPerPage?: number;
}

export default function PaginationFooter<T>({ data, onSegmentChange, defaultRowsPerPage }: IProps<T>) {
  const [rowsPerPage, setRowsPerPage] = useState<number>(defaultRowsPerPage ?? 10);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const dataLength = data.length;
  const startIndex = Math.max(Math.min(rowsPerPage * pageNumber, dataLength - 1), 0);
  const endIndex = Math.max(Math.min(startIndex + rowsPerPage, dataLength), 0);

  useEffect(() => {
    onSegmentChange(data.slice(startIndex, endIndex));
  }, [pageNumber, data, onSegmentChange, startIndex, endIndex]);

  const rowsPerPageOptions = [
    { id: "5", label: "5" },
    { id: "10", label: "10" },
    { id: "25", label: "25" },
    { id: "50", label: "50" },
  ];

  if (dataLength < 1) {
    return null;
  }

  return (
    <TableFooter>
      <PageDropDown
        value={rowsPerPage.toString()}
        label="Rows per page"
        options={rowsPerPageOptions}
        onChange={(val) => setRowsPerPage(Number(val))}
      />
      {defaultRowsPerPage ? (
        <UoTPageText style={{ fontWeight: "400" }}>{`${startIndex + 1} to ${endIndex} of ${dataLength}`}</UoTPageText>
      ) : (
        <PageText>{`${startIndex + 1} to ${endIndex} of ${dataLength}`}</PageText>
      )}
      <IconButton onClick={() => setPageNumber(Math.max(0, pageNumber - 1))} disabled={pageNumber === 0}>
        <NavigateBeforeIcon />
      </IconButton>
      <IconButton
        onClick={() => setPageNumber(Math.min(Math.round(dataLength / rowsPerPage), pageNumber + 1))}
        disabled={pageNumber >= Math.ceil(dataLength / rowsPerPage) - 1}
      >
        <NavigateNextIcon />
      </IconButton>
    </TableFooter>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const TableFooter = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  paddingTop: theme.spacing(2),
  justifyContent: "flex-end",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PageDropDown = styled(SingleSelectDropdown)(({ theme }) => ({
  width: 140,
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
const PageText = styled("p")(({ theme }) => ({
  margin: theme.spacing(0, 2, 0, 4),
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
const UoTPageText = styled("p")(({ theme }) => ({
  fontFamily: "Gilroy-Medium",
  fontSize: "15px",
  fontStyle: "normal",
  lineHeight: "normal",
  margin: theme.spacing(0, 0, 0, 2.5),
}));
