import { Caption, colorsLight, InformativeModal, PrimaryButton, SingleSelectDropdown } from "@coherehealth/common";
import { Requestor, useUpdateServiceRequest } from "@coherehealth/core-platform-api";
import { Box, Grid } from "@material-ui/core";
import {
  AddressForm,
  ContactAddressModalContent,
} from "components/AuthBuilder/FillFormsAddNewPatient/FormSections/ContactAddressModal";
import {
  ContactInfoField,
  ContactInfoModalProps,
  elementIsNotUndefined,
  useRequestorInforStyle,
} from "components/ContactInfoModal";
import { DateTimePicker } from "components/Requestor/DateTimePicker";
import { RequestorTypeOptions } from "components/Requestor/RequestorEdit";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { formatPhone } from "util/demographicUtils";
import { error as logError } from "logger";

export interface EditRequestorInfoModalProps extends ContactInfoModalProps {
  serviceRequestId: string;
  requestor?: Requestor;
  serviceRequestRefetch?: () => Promise<void>;
}

export const EditRequestorInfoModal = ({
  serviceRequestId,
  open,
  handleClose,
  name,
  phones,
  fax,
  email,
  requestor,
  serviceRequestRefetch,
}: EditRequestorInfoModalProps) => {
  const classes = useRequestorInforStyle();
  const { enqueueSnackbar } = useSnackbar();
  const formatedPhones = phones?.map((p) => formatPhone(p)).filter(elementIsNotUndefined);
  const formattedFax = fax?.map((p) => formatPhone(p)).filter(elementIsNotUndefined);
  const [requestorContent, setRequestorContent] = useState<Requestor | undefined>({
    ...requestor,
  });
  const [requestorAddressModalContent, setRequestorAddressModalContent] = useState<ContactAddressModalContent>({
    address1: requestor?.aorAddress?.line1 ?? "",
    address2: requestor?.aorAddress?.line2 ?? "",
    city: requestor?.aorAddress?.city ?? "",
    state: requestor?.aorAddress?.state ?? "",
    zip: requestor?.aorAddress?.zipCode ?? "",
    contactNumber: null,
    email: null,
  });
  const [hasValidAorReceivedDate, setHasValidAorReceivedDate] = useState<boolean>(false); // DateTimePicker will set to true when date is valid

  const { loading: patchServiceRequestLoading, mutate: patchServiceRequest } = useUpdateServiceRequest({
    id: serviceRequestId,
  });

  const onSave = async () => {
    try {
      const requestorPayload: Requestor = {
        ...requestorContent,
        channel: requestorContent?.channel ?? "PORTAL",
        aorAddress: {
          line1: requestorAddressModalContent.address1 ?? undefined,
          line2: requestorAddressModalContent.address2 ?? undefined,
          city: requestorAddressModalContent.city ?? undefined,
          state: requestorAddressModalContent.state ?? undefined,
          zipCode: requestorAddressModalContent.zip ?? undefined,
        },
      };
      await patchServiceRequest(
        {
          requestor: requestorPayload,
        },
        { pathParams: { id: serviceRequestId } }
      );
      enqueueSnackbar(`Requestor info has been updated`, {
        variant: "success",
      });
      serviceRequestRefetch?.();
      handleClose();
    } catch (error) {
      const errorAny: any = error;
      const message = errorAny?.data?.message;
      const embeddedError = errorAny?.data?._embedded?.errors?.map((error: any) => error?.message).join("\n");
      const errorMessage = (
        <Box>
          <Box>Error while saving requestor information</Box>
          {embeddedError ? <Box>{embeddedError}</Box> : message && <Box>{message}</Box>}
        </Box>
      );
      logError("Error while saving requestor information");
      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
    }
  };

  const validateFields = (): boolean => {
    return hasValidAorReceivedDate;
  };

  return (
    <InformativeModal
      open={open}
      onClose={handleClose}
      headerText={"Requestor"}
      customContentStyle={classes.editRequestorModalContainer}
      data-testid="edit-requestor-info-modal"
    >
      <Grid container spacing={0} direction={"row"} style={{ padding: "16px 24px 0px 24px" }}>
        <Grid item xs={6} className={classes.requestorField}>
          <ContactInfoField name="Name" value={name} dataPubic={true} />
        </Grid>
        <Grid item xs={6} className={classes.requestorField}>
          <ContactInfoField
            name="Phone"
            value={formatedPhones && formatedPhones.length > 0 ? formatedPhones : "--"}
            dataPubic={true}
          />
        </Grid>
        <Grid item xs={6} className={classes.requestorField}>
          <ContactInfoField name="Email" value={email ?? "--"} dataPubic={true} />
        </Grid>
        <Grid item xs={6} className={classes.requestorField}>
          <ContactInfoField
            name="Fax"
            value={formattedFax && formattedFax.length > 0 ? formattedFax : "--"}
            dataPubic={true}
          />
        </Grid>
        <Grid item xs={12} className={classes.wideContentStyle}>
          <SingleSelectDropdown
            fullWidth
            label="Requestor type"
            value={requestorContent?.requestorType || ""}
            options={RequestorTypeOptions}
            onChange={(requestorType) => {
              setRequestorContent({
                ...requestorContent,
                requestorType,
              });
            }}
          />
        </Grid>
        {requestorContent?.requestorType === "MEMBER_REPRESENTATIVE" && (
          <Grid item xs={12}>
            <Caption style={{ color: colorsLight.font.light }}>Appointment of Representative details</Caption>
          </Grid>
        )}
        {requestorContent?.requestorType === "MEMBER_REPRESENTATIVE" && (
          <Grid item xs={12}>
            <DateTimePicker
              channel={"MEMBER_REPRESENTATIVE"}
              timestamp={requestorContent?.aorReceivedTimestamp}
              setTimestamp={(aorReceivedTimestamp) => {
                setRequestorContent({
                  ...requestorContent,
                  aorReceivedTimestamp: aorReceivedTimestamp?.toString(),
                });
              }}
              attemptedSave={false}
              setHasValidReceivedDate={setHasValidAorReceivedDate}
              hasValidReceivedDate={true}
              responsiveMdWidth={false}
              style={{
                dateSelectGridStyle: { flexGrow: undefined },
                dateTimeOuterGridStyle: { maxWidth: "200px" },
                dateHourGridStyle: { maxWidth: "88px" },
                dateMinuteGridStyle: { maxWidth: "88px" },
              }}
              hideLabel
            />
          </Grid>
        )}
      </Grid>
      {requestorContent?.requestorType === "MEMBER_REPRESENTATIVE" ? (
        <AddressForm
          onSave={onSave}
          onClose={() => {}}
          contactAddressModalContent={requestorAddressModalContent}
          setContactAddressModalContent={setRequestorAddressModalContent}
          style={{ addressFormGridStyle: { paddingInline: "24px", paddingTop: 0 } }}
          saveButtonText="Save"
          validateOuterForm={validateFields}
          isLoadingSave={patchServiceRequestLoading}
          aorAddress
        />
      ) : (
        // This is a mock button to show on UI it doesn't do anything just show user's there's nothing to save
        <Grid item xs={12} style={{ textAlign: "center", paddingInline: "24px" }}>
          <PrimaryButton className={classes.disabledSaveButtonStyle} disabled>
            Save
          </PrimaryButton>
        </Grid>
      )}
    </InformativeModal>
  );
};
