import { useCallback } from "react";
import { useAuth } from "./useAuth";
import { error as logError } from "logger";

interface CallbackProps {
  returnToSamePage?: boolean;
}

export default function useLogout() {
  const { oktaAuth } = useAuth();

  return useCallback(
    async (props?: CallbackProps) => {
      // https://github.com/okta/samples-js-react/issues/39#issuecomment-558310304
      // The logout call can fail, even if the user IS logged out,
      // and in that case we want to make sure they still reload the app and
      // get kicked to the login page.
      try {
        const { returnToSamePage } = props || {};
        const postLogoutRedirectUri = returnToSamePage ? window.location.toString() : `${window.location.origin}/`;
        await oktaAuth.signOut({ postLogoutRedirectUri });
      } catch (e) {
        logError(e);
        window.location.reload();
      }
    },
    [oktaAuth]
  );
}
