import { useOktaAuth } from "@okta/okta-react";
import { SessionObject, OktaAuth } from "@okta/okta-auth-js";
import { UserType } from "../types/userType";

export const mockUser = {
  email: "mockuser@example.com",
  email_verified: true,
  preferred_username: "mockuser@example.com",
  given_name: "Test",
  family_name: "User",
  name: "Test User",
  agreementSignedAt: "2020-01-01T00:00:00Z",
  groups: ["Everyone"],
  locale: "en-US",
  sub: "mocksubvalue",
  type: process.env.REACT_APP_USER_TYPE_OVERRIDE ? process.env.REACT_APP_USER_TYPE_OVERRIDE : UserType.Admin,
  roles: process.env.REACT_APP_USER_TYPE_OVERRIDE ? process.env.REACT_APP_USER_TYPE_OVERRIDE : UserType.Admin,
  updated_at: 1589256563,
  zoneinfo: "America/Los_Angeles",
};

export const useAuth = function () {
  let oktaAuth: OktaAuth | null = null;
  let authState = null;
  if (process.env.REACT_APP_OKTA_ENABLED === "false") {
    const session: SessionObject = {
      status: "ACTIVE",
      refresh: () => Promise.resolve(session),
    };
    // Mock okta
    // https://www.npmjs.com/package/@okta/okta-react#authservice
    oktaAuth = {
      getAccessToken: () => "FAKE_TOKEN",
      getOrRenewAccessToken: async () => "FAKE_TOKEN",
      isAuthenticated: () => Promise.resolve(true),
      getUser: () => Promise.resolve(mockUser),
      session: {
        close: () => Promise.resolve({}),
        exists: () => Promise.resolve(true),
        get: () => Promise.resolve(session),
        refresh: () => Promise.resolve(session),
        setCookieAndRedirect: (sessionToken?: string, redirectUrl?: string) => {},
      },
    } as unknown as OktaAuth;
    // https://www.npmjs.com/package/@okta/okta-react#authstate
    authState = {
      isPending: false,
      isAuthenticated: true,
      idToken: "mock_okta_id_token",
      accessToken: "mock_okta_access_token",
      error: null,
    };
  } else {
    // This if..else will be optimized out by the build depending on the environment
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const auth = useOktaAuth();
    oktaAuth = auth.oktaAuth;
    authState = auth.authState;
  }
  return { oktaAuth, authState };
};
