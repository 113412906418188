// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";
import HeadsetMic from "@material-ui/icons/HeadsetMic";
import MuiIconButton from "@material-ui/core/IconButton";

export const iconTooltipText = "This information only shows up for users with service ops credentials";

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const HeadsetMicIcon = styled(HeadsetMic)(({ theme }) => ({
  color: theme.palette.info.light,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  padding: 0,
}));
