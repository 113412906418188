import { Dispatch, FunctionComponent, SetStateAction, useRef, useState } from "react";

import Collapse from "@material-ui/core/Collapse";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import { iconTooltipText, IconButton, HeadsetMicIcon } from "./common";
import Tooltip from "@material-ui/core/Tooltip";
import { Body2, colorsLight, H4, TertiaryButton } from "@coherehealth/common";
import { Requestor } from "@coherehealth/core-platform-api";
import { createStyles, makeStyles, useTheme } from "@material-ui/core";
import RequestorEdit from "./RequestorEdit";
import useResponsiveMdWidth from "hooks/useResponsiveMdWidth";
import { useLocation } from "react-router";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";

export interface Props {
  title?: string;
  requestor?: Requestor | undefined;
  setRequestor: Dispatch<SetStateAction<Requestor | undefined>>;
  setUserUpdatedTat?: (wasUpdated: boolean) => void;
  attemptedSubmitServices?: boolean;
  collapseRequestorForm?: boolean;
  setCollapseRequestorForm?: (b: boolean) => void;
  isContinuationWorkflow?: boolean;
}

export interface CssProps {
  isFaxAuthBuilderFlow?: boolean;
  isContinuationWorkflow?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    summary: {
      marginInlineStart: "10px",
      marginTop: "4px",
    },
    edit: {
      marginInlineStart: "10px",
    },
    requestorPanel: {
      border: "1px solid",
      borderColor: colorsLight.gray.divider,
      backgroundColor: colorsLight.gray.light,
      boxShadow: "0 0 0 0px",
      marginBottom: (props: CssProps) =>
        props.isFaxAuthBuilderFlow
          ? props.isContinuationWorkflow
            ? theme.spacing(0)
            : theme.spacing(3)
          : theme.spacing(4),
      borderRadius: theme.spacing(0.5),
      "&:before": {
        content: "none",
      },
    },
    actionButton: {
      color: theme.palette.text.secondary,
      padding: 0,
      "&:hover, &:focus": {
        boxShadow: "none",
        backgroundColor: colorsLight.gray.divider,
      },
      "&:active": {
        color: theme.palette.text.primary,
      },
    },
  })
);

const RequestorCard: FunctionComponent<Props> = ({
  title,
  requestor,
  setRequestor,
  setUserUpdatedTat,
  attemptedSubmitServices,
  collapseRequestorForm,
  setCollapseRequestorForm,
  isContinuationWorkflow,
}) => {
  const [editState, setEditState] = useState(!collapseRequestorForm && true);
  const [attemptedSave, setAttemptedSave] = useState(false);
  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);
  const classes = useStyles({ isFaxAuthBuilderFlow, isContinuationWorkflow });
  const theme = useTheme();
  const cardPadding = editState ? 24 : 8;
  const divElementRef = useRef<HTMLDivElement>(null);
  const maxWidthForResponsiveMdValue = 960;
  const { responsiveMdWidth } = useResponsiveMdWidth(divElementRef, maxWidthForResponsiveMdValue);
  return (
    <div ref={divElementRef} className={classes.requestorPanel}>
      <Grid
        container
        spacing={3}
        item
        justifyContent="space-between"
        style={{
          transition: theme.transitions.create(["padding", "margin"]),
          padding: cardPadding,
          margin: 0,
        }}
        xs={12}
      >
        <Grid
          item
          style={{
            display: "flex",
            padding: editState ? 0 : theme.spacing(1.5),
            transition: theme.transitions.create(["padding"]),
          }}
        >
          <Tooltip title={iconTooltipText} placement="top" arrow>
            <IconButton style={{ paddingBottom: title ? theme.spacing(3) : 0 }}>
              <HeadsetMicIcon />
            </IconButton>
          </Tooltip>
          {editState ? (
            <H4 className={classes.edit} style={{ paddingBottom: title ? theme.spacing(3) : 0 }} data-public>
              {title ?? "Add information about the requestor"}
            </H4>
          ) : (
            <Body2 className={classes.summary} data-public>
              Submitting request for {requestor?.firstName || requestor?.user?.firstName}{" "}
              {requestor?.lastName || requestor?.user?.lastName}
            </Body2>
          )}
        </Grid>
        {!editState && (
          <Grid item style={{ alignSelf: "flex-end" }}>
            <TertiaryButton
              startIcon={<EditIcon />}
              className={classes.actionButton}
              onClick={() => {
                setEditState(true);
              }}
            >
              Edit
            </TertiaryButton>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          style={{
            padding: 0,
            margin: editState ? theme.spacing(1, 0, 0, 0) : 0,
            transition: theme.transitions.create(["margin"]),
          }}
        >
          <Collapse in={editState}>
            <RequestorEdit
              requestor={requestor}
              setRequestor={setRequestor}
              setUserUpdatedTat={setUserUpdatedTat}
              attemptedSave={attemptedSave}
              setAttemptedSave={setAttemptedSave}
              isOnPatientSummary={false}
              setEditState={setEditState}
              attemptedSubmitServices={attemptedSubmitServices}
              setCollapseRequestorForm={setCollapseRequestorForm}
              responsiveMdWidth={responsiveMdWidth}
            />
          </Collapse>
        </Grid>
      </Grid>
    </div>
  );
};

export default RequestorCard;
