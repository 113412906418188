import { ReviewType, useGetAllowedReviewOutcomes } from "@coherehealth/core-platform-api";
import { ReviewOutcomeOption } from "../../util/QueueManagementReviewUtil";
import { useEffect } from "react";
import { ReviewPageVersion } from "components/ClinicalReview/reviewUtils/utils";

interface FetchReviewOutcomeProps {
  serviceRequestId?: string | null;
  reviewId?: string;
  reviewType?: ReviewType["reviewType"];
  reviewPageVersion?: ReviewPageVersion;
}

export default function useFetchReviewOutcomes({
  serviceRequestId,
  reviewId,
  reviewType,
  reviewPageVersion,
}: FetchReviewOutcomeProps) {
  const { data: allowedReviewOutcomesResponse, refetch: refetchReviewOutcomes } = useGetAllowedReviewOutcomes({
    id: serviceRequestId || "undefined-sr-placeholder",
    queryParams: { reviewId },
    lazy: true,
  });

  useEffect(() => {
    if (serviceRequestId && reviewId) {
      refetchReviewOutcomes({ pathParams: { id: serviceRequestId }, queryParams: { reviewId, reviewType } });
    } else if ((reviewPageVersion === "AppealNotePage" || reviewPageVersion === "ViewOnlyPage") && serviceRequestId) {
      refetchReviewOutcomes({ pathParams: { id: serviceRequestId }, queryParams: { reviewType: "Appeal" } });
    }
  }, [refetchReviewOutcomes, serviceRequestId, reviewId, reviewType, reviewPageVersion]);
  return {
    allowedReviewOutcomes: allowedReviewOutcomesResponse as ReviewOutcomeOption[],
  };
}
