import {
  Body1,
  computeHoldReasonOptions,
  DropdownOption,
  InformativeModal,
  RadioGroup,
  SingleSelectDropdown,
  TertiaryButton,
  TextField,
} from "@coherehealth/common";
import { useAppendHold, useUnassignCases } from "@coherehealth/qm-api";
import { makeStyles } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import config from "api/config";
import { useSearchParams } from "react-router-dom";

interface Props {
  handleSaveAndExit?: () => void;
  handleDiscardAndExit?: () => void;
  saveAndExitDisabled: boolean;
  discardAndExitDisabled: boolean;
  saveAndExitLoading: boolean;
  submitting?: boolean;
  discarding?: boolean;
  holdReasons?: string[];
}

type Decision = "DISCARD" | "HOLD";

type HoldReason = "OTHER" | string;

export default function ReviewControlPanelExitActions({
  handleSaveAndExit,
  handleDiscardAndExit,
  discardAndExitDisabled,
  saveAndExitDisabled,
  saveAndExitLoading,
  submitting,
  discarding,
  holdReasons,
}: Props) {
  const classes = useStyles();
  const [isHoldCaseModalOpen, setIsHoldCaseModalOpen] = useState(false);
  const [decision, setDecision] = useState<Decision>("DISCARD");
  const [holdReason, setHoldReason] = useState<HoldReason>("");
  const [otherHoldReason, setOtherHoldReason] = useState<string>("");

  const [search] = useSearchParams();
  const caseId = search.get("caseId");

  const holdReasonOptions = useMemo(() => computeHoldReasonOptions(holdReasons), [holdReasons]);

  const {
    mutate: appendCaseHold,
    error: appendCaseHoldError,
    loading: appendCaseHoldLoading,
  } = useAppendHold({
    id: caseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
  });
  useEffect(() => {
    if (appendCaseHoldError) {
      enqueueSnackbar(`Error : ${appendCaseHoldError.message}`, { variant: "error" });
    }
  }, [appendCaseHoldError]);

  const {
    mutate: unassignCases,
    error: unassignCasesError,
    loading: isUnassigning,
  } = useUnassignCases({
    base: `${config.QM_SERVICE_API_URL}`,
  });
  useEffect(() => {
    if (unassignCasesError) {
      enqueueSnackbar(`Error : ${unassignCasesError.message}`, { variant: "error" });
    }
  }, [unassignCasesError]);

  const continueHasError = decision === "HOLD" && holdReason === "OTHER" && !otherHoldReason;

  const continueDisabled = continueHasError || discardAndExitDisabled || saveAndExitDisabled;
  const continueLoading = discarding || submitting || saveAndExitLoading || appendCaseHoldLoading || isUnassigning;

  function doContinue() {
    if (decision === "DISCARD" && handleDiscardAndExit) {
      const caseIds = caseId ? [caseId] : [""];
      unassignCases(caseIds).then(() => {
        setIsHoldCaseModalOpen(false);
        handleDiscardAndExit();
      });
    }
    if (decision === "HOLD" && handleSaveAndExit) {
      const holdReasonLabel = holdReasonOptions.find((reason) => reason.id === holdReason)?.label || holdReason;
      appendCaseHold({ reason: holdReasonLabel as string, otherReason: otherHoldReason }).then(() => {
        setIsHoldCaseModalOpen(false);
        handleSaveAndExit();
      });
    }
  }

  return (
    <>
      <TertiaryButton
        onClick={() => setIsHoldCaseModalOpen(true)}
        loading={continueLoading}
        disabled={discardAndExitDisabled || saveAndExitDisabled}
        className={classes.exitButton}
        data-testid="controls-exit"
      >
        Exit
      </TertiaryButton>
      <InformativeModal
        open={isHoldCaseModalOpen}
        onClose={() => setIsHoldCaseModalOpen(false)}
        headerText={"Would you like to save your progress?"}
        subHeaderElement={
          <ServiceCaseHoldBody
            decision={decision}
            setDecision={setDecision}
            holdReason={holdReason}
            setHoldReason={setHoldReason}
            otherHoldReason={otherHoldReason}
            setOtherHoldReason={setOtherHoldReason}
            classes={classes}
            holdReasonOptions={holdReasonOptions}
          />
        }
        primaryButtonText={"Continue"}
        primaryButtonAction={doContinue}
        primaryButtonDisabled={continueDisabled}
        primaryButtonLoading={continueLoading}
        customButtonStyle={classes.continueButton}
        tertiaryButtonText={"Cancel"}
        tertiaryButtonAction={() => setIsHoldCaseModalOpen(false)}
        tertiaryButtonDisabled={continueLoading}
        showDivider={false}
      />
    </>
  );
}

interface ServiceCaseHoldBodyProps {
  decision: Decision;
  setDecision: (decision: Decision) => void;
  holdReason: HoldReason;
  setHoldReason: (holdReason: HoldReason) => void;
  otherHoldReason: string;
  setOtherHoldReason: (otherHoldReson: string) => void;
  classes: ClassNameMap<"exitButton" | "continueButton" | "holdBody">;
  holdReasonOptions: DropdownOption[];
}

function ServiceCaseHoldBody({
  decision,
  setDecision,
  holdReason,
  setHoldReason,
  otherHoldReason,
  setOtherHoldReason,
  classes,
  holdReasonOptions,
}: ServiceCaseHoldBodyProps) {
  return (
    <div className={classes.holdBody}>
      <RadioGroup<Decision>
        options={[
          {
            id: "DISCARD",
            label: "Discard progress and unassign me from this case",
          },
          {
            id: "HOLD",
            label: "Save progress and place in holds",
          },
        ]}
        value={decision}
        onChange={(val) => setDecision(val)}
        row
      />
      {decision === "HOLD" && (
        <>
          <SingleSelectDropdown
            label="Hold reason"
            options={holdReasonOptions}
            onChange={setHoldReason}
            value={holdReason}
            data-testid={"hold-resaon-dropdown"}
          />
          {holdReason === "OTHER" && (
            <TextField label="Comment" value={otherHoldReason} onChangeValue={setOtherHoldReason} fullWidth />
          )}
          <Body1>Holds may be automatically reassigned or reassigned by supervisors.</Body1>
        </>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  exitButton: {
    width: 100,
    height: 48,
    padding: theme.spacing(0),
    marginRight: "10px",
  },
  continueButton: {
    width: "auto",
  },
  holdBody: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "16px",
    paddingBottom: "32px",
    gap: "32px",
  },
}));
