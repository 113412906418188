import AppBar from "@material-ui/core/AppBar";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { H3 } from "@coherehealth/common";
import PatientInfo from "components/AppHeader/PatientInfo";
import { Patient } from "@coherehealth/core-platform-api";

interface StyleProps {
  height: number;
  width: number;
  title?: string;
}

interface Props extends StyleProps {
  patient?: Patient | null;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  colorPrimary: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
  },
  root: (props) => ({
    height: props.height,
    width: props.width,
    transition: "height .5s ease",
    paddingTop: theme.spacing(1.25),
    paddingBottom: 0,
    boxShadow: "none",
    borderBottom: `1px solid ${theme.palette.divider}`,
  }),
}));

export default function FaxHeader({ patient, height, width, title }: Props) {
  const { colorPrimary, root } = useStyles({ height, width, title });
  const appBarClasses = { colorPrimary, root };
  return (
    <AppBar classes={appBarClasses}>
      <div style={{ display: "flex", alignItems: "center", marginLeft: 24, height: "100%" }}>
        <H3>{title ?? "New Service Request"}</H3>
      </div>
      <PatientInfo patient={patient ?? undefined} rightAlign />
    </AppBar>
  );
}
