import React, { Dispatch, SetStateAction, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { useTheme } from "@material-ui/core/styles";
import {
  FromCPTCodesRequest,
  PayerProcedureCode,
  ServiceRequestResponse,
  ProcedureCode,
  RuleActions,
  RecommendChangeRuleAction,
  AuthorizationResponse,
  AuthStatus,
} from "@coherehealth/core-platform-api";
import { getProcedureCodeListSortedByPalCategory, PROCEDURE_CODE_UNIT_TYPE_LABEL_MAP } from "util/serviceRequest";
import { MutateMethod } from "restful-react";
// TODO: migrate this to CohereTableWithPanels once Typography is fixed
import { TableWithPanels, TableWithPanelsColumn } from "@coherehealth/common";
import { isRecommendChangeActionWithAttribute } from "util/rule";
import { IGNORE_ERRORS, error as logError } from "logger";

interface Props {
  authorization?: AuthorizationResponse;
  serviceRequest: ServiceRequestResponse;
  getPayerProcedureCodes: MutateMethod<PayerProcedureCode[], FromCPTCodesRequest, void, void>;
  payerProcedureCodes: PayerProcedureCode[];
  setPayerProcedureCodes: Dispatch<SetStateAction<PayerProcedureCode[]>>;
  ruleActions?: RuleActions;
}
type PalCategoryProcedureCodeRow = ProcedureCode & {
  palCategory?: string;
  ruleNudge?: RecommendChangeRuleAction;
  isOutpatient?: boolean;
};

export default function ProcedureCodeList({
  authorization,
  serviceRequest,
  getPayerProcedureCodes,
  setPayerProcedureCodes,
  payerProcedureCodes,
  ruleActions,
}: Props) {
  const theme = useTheme();
  useEffect(() => {
    let componentIsMounted = true;
    const getProcedureCode = async () => {
      try {
        const response = await getPayerProcedureCodes({
          codes: serviceRequest.procedureCodes?.map((pc) => pc.code) || [],
          patientId: serviceRequest.patient?.id,
          startDate: serviceRequest.startDate,
        });
        if (componentIsMounted) {
          setPayerProcedureCodes(response);
        }
      } catch (error: any) {
        const ignoreEntry = IGNORE_ERRORS.includes(error.message ?? "");
        const msg = `Failed to fetch payer procedure codes`;
        console.error(msg, error);
        if (!ignoreEntry) {
          logError(msg);
        }
      }
    };

    getProcedureCode();

    return () => {
      componentIsMounted = false;
    };
  }, [serviceRequest, setPayerProcedureCodes, getPayerProcedureCodes]);
  const procedureCodes = authorization
    ? serviceRequest.authStatus === "DRAFT"
      ? serviceRequest.semanticProcedureCodes
      : authorization.semanticProcedureCodes
    : serviceRequest.semanticProcedureCodes;
  const procedureCodeList = getProcedureCodeListSortedByPalCategory(procedureCodes, payerProcedureCodes);
  const procedureCodeAsRows = procedureCodeList.map((palCatAndProcCode) => {
    const [firstProcCode, ...otherProcCodes] = palCatAndProcCode.procedureCodes;
    const firstProcCodeWithPalCat = {
      ...firstProcCode,
      palCategory: palCatAndProcCode.palCategory,
    };
    return [firstProcCodeWithPalCat, ...otherProcCodes];
  });
  const procedureCodeWithNudgeData = procedureCodeAsRows.map((palCatAndProcCode) => {
    const palCodesWithRuleNudge = palCatAndProcCode.map((px) => ({
      ...px,
      ruleNudge: ruleActions
        ?.filter(isRecommendChangeActionWithAttribute)
        .find((action) => action.onAcceptAttribute === "units"),
      isOutpatient: serviceRequest.encounterType === "OUTPATIENT",
    }));
    return palCodesWithRuleNudge;
  });
  // If there's no authorization, then gather the units on service data from the SR.
  // This is useful when creating a continuation. The pre-submission review screen will show units accurate to the SR.
  const procedureCodeWithSrUnits = procedureCodeWithNudgeData.map((px) => {
    const pxWithSrUnits = px.map((px) => ({
      ...px,
      units: serviceRequest.units,
      approvedUnits: serviceRequest.approvedUnits,
    }));
    return pxWithSrUnits;
  });

  const showUnitsOnPxStatus = serviceRequest.clinicalService?.isUnitsOnPx;
  const showUnitsOnServiceStatus = !serviceRequest.clinicalService?.isUnitsOnPx;

  const columns = pxTableColumnsContinuations.filter(
    ({ filterFunction }) =>
      !filterFunction ||
      filterFunction({
        procedureCodes: procedureCodes || [],
        showUnitsOnPxStatus: showUnitsOnPxStatus,
        showUnitsOnServiceStatus: showUnitsOnServiceStatus,
        authStatus: serviceRequest.authStatus,
      })
  );

  return (
    <>
      {payerProcedureCodes && procedureCodeList.length > 0 && (
        <>
          <Grid item xs={12} style={{ padding: theme.spacing(1, 0), paddingTop: theme.spacing(2) }}>
            <Divider />
          </Grid>
          <Grid item xs={12} style={{ padding: theme.spacing(1, 0) }}>
            <TableWithPanels
              columns={columns}
              panels={
                serviceRequest.clinicalService?.isUnitsOnPx || authorization
                  ? procedureCodeWithNudgeData
                  : procedureCodeWithSrUnits
              }
            />
          </Grid>
        </>
      )}
    </>
  );
}

interface PxTableColumnFilterParams {
  procedureCodes: ProcedureCode[];
  showRequestedUnitsOnPxTable?: boolean;
  showApprovedUnitsOnPxTable?: boolean;
  showUnitsOnPxStatus?: boolean;
  showUnitsOnServiceStatus?: boolean;
  authStatus?: AuthStatus;
}

interface PxTableColumnConfig extends TableWithPanelsColumn<PalCategoryProcedureCodeRow> {
  filterFunction?: (args: PxTableColumnFilterParams) => boolean;
}

export const unitsString = (unitCount: number | undefined, unitType: string) => {
  const unitMapping = PROCEDURE_CODE_UNIT_TYPE_LABEL_MAP[unitType]
    ? PROCEDURE_CODE_UNIT_TYPE_LABEL_MAP[unitType]
    : "unit";
  return unitCount && unitCount === 1
    ? `${unitCount} ${unitMapping.toLowerCase()}`
    : `${unitCount} ${unitMapping.toLowerCase()}s`;
};

// A new table without PAL category, with a new column Status
const pxTableColumnsContinuations: PxTableColumnConfig[] = [
  {
    columnName: "Code",
    value: (palCatProcCode) => palCatProcCode.code,
  },
  {
    /* 
    Units on PX Status column
    e.g. "1 unit approved"
    e.g. "2 units requested, 1 unit approved"
    */
    columnName: "Status",
    value: (palCatProcCode) => procedureCodeStatus(palCatProcCode, true),
    filterFunction: ({ showUnitsOnPxStatus, authStatus }) => !!showUnitsOnPxStatus,
  },
  {
    /* 
    Units on Service Status column
    e.g. "Approved/Denied/Requested"
    * If partially approved, display as if this were units on PX
    */
    columnName: "Status",
    value: (palCatProcCode) => procedureCodeStatus(palCatProcCode, false),
    filterFunction: ({ showUnitsOnServiceStatus, authStatus }) => !!showUnitsOnServiceStatus,
  },
  {
    columnName: "Description",
    value: (palCatProcCode) => palCatProcCode.description,
  },
];

/* ProcedureCodeStatus: Display the status for a procedure code */
const procedureCodeStatus = (pxCodeWithRuleNudge: PalCategoryProcedureCodeRow, isUnitsOnPx: boolean): string => {
  if (isUnitsOnPx) {
    if (
      (pxCodeWithRuleNudge.approvedUnits || pxCodeWithRuleNudge.approvedUnits === 0) &&
      (pxCodeWithRuleNudge.units || pxCodeWithRuleNudge.units === 0)
    ) {
      const approvedText = `${pxCodeWithRuleNudge.approvedUnits} ${
        pxCodeWithRuleNudge.unitType === "Number of visits"
          ? pluralizedVisits(pxCodeWithRuleNudge.approvedUnits)
          : pluralizedUnits(pxCodeWithRuleNudge.approvedUnits)
      } approved`;
      const requestedText = `${pxCodeWithRuleNudge.units} ${
        pxCodeWithRuleNudge.unitType === "Number of visits"
          ? pluralizedVisits(pxCodeWithRuleNudge.units)
          : pluralizedUnits(pxCodeWithRuleNudge.units)
      } requested`;
      const deniedText = `${pxCodeWithRuleNudge.units} ${
        pxCodeWithRuleNudge.unitType === "Number of visits"
          ? pluralizedVisits(pxCodeWithRuleNudge.units)
          : pluralizedUnits(pxCodeWithRuleNudge.units)
      } denied`;
      if (pxCodeWithRuleNudge.approvedUnits === pxCodeWithRuleNudge.units) {
        // Approval
        return approvedText;
      } else if (
        pxCodeWithRuleNudge.approvedUnits !== pxCodeWithRuleNudge.units &&
        pxCodeWithRuleNudge.approvedUnits > 0
      ) {
        // Partial Approval
        return `${requestedText}, ${approvedText}`;
      } else if (
        pxCodeWithRuleNudge.approvedUnits !== pxCodeWithRuleNudge.units &&
        pxCodeWithRuleNudge.approvedUnits === 0
      ) {
        // Denial
        return `${deniedText}`;
      }
    } else if (!pxCodeWithRuleNudge.approvedUnits && pxCodeWithRuleNudge.units) {
      // No Final Determination yet
      const requestedText = `${pxCodeWithRuleNudge.units} ${
        pxCodeWithRuleNudge.unitType === "Number of visits"
          ? pluralizedVisits(pxCodeWithRuleNudge.units)
          : pluralizedUnits(pxCodeWithRuleNudge.units)
      } requested`;
      return requestedText;
    }
    // Should not reach here, but bad data *can* occur
    return "--";
  } else {
    const approvedText = `Approved`;
    const requestedText = `Requested`;
    const deniedText = `Denied`;
    if (
      (pxCodeWithRuleNudge.approvedUnits || pxCodeWithRuleNudge.approvedUnits === 0) &&
      (pxCodeWithRuleNudge.units || pxCodeWithRuleNudge.units === 0)
    ) {
      if (pxCodeWithRuleNudge.approvedUnits === pxCodeWithRuleNudge.units) {
        // Approval
        return approvedText;
      } else if (
        pxCodeWithRuleNudge.approvedUnits !== pxCodeWithRuleNudge.units &&
        pxCodeWithRuleNudge.approvedUnits > 0
      ) {
        // Partial Approval - Delegate to units on PX result
        return procedureCodeStatus(pxCodeWithRuleNudge, true);
      } else if (
        pxCodeWithRuleNudge.approvedUnits !== pxCodeWithRuleNudge.units &&
        pxCodeWithRuleNudge.approvedUnits === 0
      ) {
        // Denial
        return deniedText;
      }
    } else if (!pxCodeWithRuleNudge.approvedUnits && pxCodeWithRuleNudge.units) {
      // No Final Determination yet

      return requestedText;
    }
    // Should not reach here, but bad data *can* occur
    return "--";
  }
};

const pluralizedUnits = (units: number) => (units && units === 1 ? `unit` : `units`);
const pluralizedVisits = (visits: number) => (visits && visits === 1 ? `visit` : `visits`);
