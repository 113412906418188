/*
 * What on earth is up with this?
 * We _should_ be able to get this polyfill as described here
 * https://github.com/facebook/create-react-app/issues/11756#issuecomment-1047253186
 * but for some reason, it was not actually working at runtime.
 *
 * Manually setting up the global polyfill does work.
 * No idea why.
 */

import { Buffer } from "buffer";
// @ts-ignore
import process from "process/browser";
// @ts-ignore
window.Buffer ||= Buffer;
window.process ||= process;
