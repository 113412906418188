import React, { ReactNode, useState } from "react";
import { RestfulProvider, RestfulReactProviderProps } from "restful-react";
import config from "./api/config";
import routes from "./routes";
import { useGetHealthcheck } from "@coherehealth/core-platform-api";
import { error as logError } from "./logger";
import { store, useFeature } from "@coherehealth/common";

/**
 * Passively monitor API calls and redirect to the maintenance page if
 * the last X calls and the health check return 5XXs.
 * Uses a cookie to track failures across page refreshes and tabs
 */
const limitReachedAfterMonitoringResponse = (response: Response) => {
  const { status, url } = response;
  const healthCheckFailed = url.includes("healthcheck") && status >= 500;
  let total5XXs = Number(store.get("total5XXs")) || 0;

  if (status >= 500) {
    total5XXs += 1; // increment counter after any 5XX api response
  } else {
    total5XXs = 0; // reset counter after any successful API call
  }

  // If the last API call was a failed healthcheck and we are above the limit then we strike out
  if (healthCheckFailed && total5XXs > config.LIMIT_5XX_RESPONSES) {
    window.location.assign(routes.MAINTENANCE);
    logError(
      new Error(
        `Fail whale page invoked. Cause: ${config.LIMIT_5XX_RESPONSES} consecutive 5XX responses and /healthcheck failed.`
      )
    );
    total5XXs = 0; // reset counter after showing fail whale page
  }

  store.set("total5XXs", total5XXs);
  return total5XXs > config.LIMIT_5XX_RESPONSES;
};

function CallHealthcheck() {
  useGetHealthcheck({});
  return null;
}

/**
 * A simple wrapper for Restful Provider to monitor API calls
 * conditional on a feature flag and maybe trigger a healthcheck call
 */
export default function MonitoringRestfulProvider({
  children,
  ...props
}: RestfulReactProviderProps & { children: ReactNode }) {
  const [limitReached, setLimitReached] = useState(false);
  const triggerMaintenancePage = useFeature("triggerMaintenancePage");
  const shouldGetHealthcheck = triggerMaintenancePage && limitReached;

  return (
    <RestfulProvider
      onResponse={(res) => {
        if (triggerMaintenancePage) {
          const newLimitReached = limitReachedAfterMonitoringResponse(res);
          setLimitReached(newLimitReached);
        }
      }}
      {...props}
    >
      <>
        {shouldGetHealthcheck && <CallHealthcheck />}
        {children}
      </>
    </RestfulProvider>
  );
}
