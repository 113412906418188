import { useContext } from "react";
import SmartOnFhirLoadingPage from "./SmartOnFhirLoadingPage";
import { SmartOnFhirContext } from "./SmartOnFhirSecurityProvider";
import ErrorPage from "components/ErrorPage";
import { error as logError } from "logger";
import RedirectToSmartOnFhirServiceRequestPage from "RedirectToServiceRequestPage";

export default function SmartOnFhirLandingPage() {
  const smartClient = useContext(SmartOnFhirContext);
  const cohereId = smartClient?.state?.tokenResponse?.cohereId; // can hardcode (e.g., "EHRS0747") for testing locally
  // TODO: Remove this once Epic platform is about to be integrated. This is just for dev testing purposes.
  const TEMP_COHERE_ID_OVERRIDE = new URLSearchParams(window.location.search).get("cohereId");

  // if (!!cohereId)
  if (!!cohereId || !!TEMP_COHERE_ID_OVERRIDE) {
    return (
      <>
        {/* <RedirectToSmartOnFhirServiceRequestPage cohereId={cohereId}> */}
        <RedirectToSmartOnFhirServiceRequestPage cohereId={cohereId ? cohereId : TEMP_COHERE_ID_OVERRIDE || ""}>
          <SmartOnFhirLoadingPage />
        </RedirectToSmartOnFhirServiceRequestPage>
      </>
    );
  } else {
    logError(`Missing 'cohereId' in smartClient: ", ${smartClient}`);
    return <ErrorPage />;
  }
}
