import { registerRoute } from "../server";

export default function registerRoutes() {
  registerRoute({
    id: "getSimpleFeatureFlags",
    route: "simpleFeatureFlag",
    method: "get",
    generateResponse: function (config, schema, request) {
      return [];
    },
  });
}
