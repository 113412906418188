import { Checkbox, colorsLight } from "@coherehealth/common";
import { NextReviewDateSelect } from "../common/NextReviewDateSelect";
import React, { Dispatch, SetStateAction } from "react";
import { Row } from "../../../AuthBuilder/FillForms/ServiceRequestForm";

interface InpatientNextReviewDateCollectionProps {
  nrdAcknowledged: boolean;
  setNrdAcknowledged: Dispatch<SetStateAction<boolean>>;
  nextReviewDate: Date | null;
  setNextReviewDate: Dispatch<SetStateAction<Date | null>>;
  fromNurseReviewSubmissionModal?: boolean;
}

export default function InpatientNextReviewDateCollection({
  nrdAcknowledged,
  setNrdAcknowledged,
  nextReviewDate,
  setNextReviewDate,
  fromNurseReviewSubmissionModal = false,
}: InpatientNextReviewDateCollectionProps) {
  const nrdAcknowledgementCheckbox = (
    <Checkbox
      checked={nrdAcknowledged}
      onChange={setNrdAcknowledged}
      label={
        <span style={{ fontSize: "15px", color: colorsLight.font.secondary, textAlign: "left" }}>
          I want to set a next review date and review the request whether or not there are additional attachments
        </span>
      }
    ></Checkbox>
  );

  const nextReviewSelector = (
    <NextReviewDateSelect nextReviewDate={nextReviewDate} setNextReviewDate={setNextReviewDate}></NextReviewDateSelect>
  );

  const elements = [nrdAcknowledgementCheckbox, nextReviewSelector];

  return (
    <>
      {fromNurseReviewSubmissionModal
        ? elements.map((element, idx) => {
            return (
              <Row key={idx} style={{ padding: "0 0 0 15px", textAlign: "left" }}>
                {element}
              </Row>
            );
          })
        : elements.map((element, idx) => {
            return (
              <div key={idx} style={{ padding: "5px 0 5px 0", lineHeight: "1.25", textAlign: "left" }}>
                {element}
              </div>
            );
          })}
    </>
  );
}
