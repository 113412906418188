import { SingleSelectDropdown } from "@coherehealth/common";

export type NetworkStatusOverrideOptionType = "INN" | "OON";

export interface NetworkStatusOverrideSingleSelectProps {
  label: string;
  selectedOption?: NetworkStatusOverrideOptionType;
  setSelectedOption: (networkStatus: NetworkStatusOverrideOptionType | undefined) => void;
  testId?: string;
}

export interface NetworkStatusOverridesOption {
  id: NetworkStatusOverrideOptionType;
  label?: string;
}

const networkStatusOptions: NetworkStatusOverridesOption[] = [
  { id: "INN", label: "In-network" },
  { id: "OON", label: "Out-of-network" },
];

export const NetworkStatusOverrideSingleSelect = (props: NetworkStatusOverrideSingleSelectProps): JSX.Element => {
  const { label, selectedOption, setSelectedOption, testId } = props;

  return (
    <SingleSelectDropdown<NetworkStatusOverridesOption>
      fullWidth
      label={label}
      onChange={(value) => setSelectedOption(value as NetworkStatusOverrideOptionType)}
      value={selectedOption ?? ""}
      options={networkStatusOptions}
      data-testid={testId}
    />
  );
};
