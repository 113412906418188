import { Model, Factory, Server, belongsTo, hasMany } from "miragejs";
import { ApplicationSerializer } from "../server";

const models = {
  serviceRequest: Model.extend({
    patient: belongsTo("patient"),
    carePathJourney: belongsTo("carePathJourney"),
    clinicalService: belongsTo("clinicalService"),
    palCategory: belongsTo("palCategory"),
    primaryDiagnosis: belongsTo("diagnosisCode"),
    secondaryDiagnoses: hasMany("diagnosisCode"),
    placeOfService: belongsTo("placeOfService"),
    procedureCodes: hasMany("procedureCode"),
    orderingProvider: belongsTo("provider"),
    performingProvider: belongsTo("provider"),
    facility: belongsTo("facility"),
    attachments: hasMany("serviceRequestAttachment"),
  }),
  serviceRequestAttachment: Model.extend({
    serviceRequest: belongsTo("serviceRequest"),
  }),
};

const isRecurring = (i: number) => i % 2;

const factories = {
  // ServiceRequestResponse
  serviceRequest: Factory.extend({
    // StandardFields
    dateCreated(i: number) {
      return "2020-01-20";
    },
    lastUpdated(i: number) {
      return "2020-01-20";
    },
    // ServiceRequestCommon
    cohereId(i: number) {
      return `cohereId${i}`;
    },
    authNumber(i: number) {
      return "";
    },
    authStatus(i: number) {
      return "DRAFT";
    },
    recurrenceType(i: number) {
      return isRecurring(i) ? "RECURRING" : "ONETIME";
    },
    startDate(i: number) {
      return "2020-01-20";
    },
    endDate(i: number) {
      return isRecurring(i) ? "2020-01-20" : "";
    },
    units(i: number) {
      return isRecurring(i) ? 2 : 1;
    },
    unitType(i: number) {
      return isRecurring(i) ? "PROCEDURE" : "";
    },
    urgency(i: number) {
      const isExpedited = i % 2 ? true : false;
      return {
        isExpedited,
        reasonNote: isExpedited ? `Reason Note ${i}` : "",
      };
    },
    // ServiceRequestExpansion
    patientId(i: number) {
      return `${i}`;
    },
    intakeTimestamp(i: number) {
      return "2020-01-20T12:01:31";
    },
  }),

  serviceRequestAttachment: Factory.extend({
    name(i: number) {
      return `filename${i}`;
    },
    size(i: number) {
      return 100 * i;
    },
    type(i: number) {
      return "Other";
    },
    attachmentSource(i: number) {
      return "USER";
    },
    contentType(i: number) {
      return "text/plain";
    },
  }),
};

const emptyServiceRequest = {
  attachments: [],
  performingProvider: null,
  orderingProvider: null,
  facility: null,
  placeOfService: null,
  procedureCodes: [],
  secondaryDiagnoses: [],
  primaryDiagnosis: null,
  patient: null,
  carePathJourney: null,
  clinicalService: null,
  palCategory: null,
  urgency: null,
  unitType: undefined,
  units: 1,
  endDate: "",
  startDate: "",
  recurrenceType: undefined,
  authStatus: undefined,
  authNumber: "",
  cohereId: "cohereIdEmpty",
  lastUpdated: "",
  dateCreated: "",
  intakeTimestamp: "",
};

// Assumes that other related models will be independently created
const populatedServiceRequest = {
  // attachments will be created after the service request
  attachmentIds: [],
  performingProviderId: "1",
  orderingProviderId: "2",
  facilityId: "1",
  placeOfServiceId: "1",
  procedureCodeIds: ["1", "2", "3"],
  // the following is not a typo. Mirage magic converts between this and secondaryDiagnoses
  secondaryDiagnosisIds: ["2", "3", "4", "5", "12", "13", "14", "15"],
  primaryDiagnosisId: "1",
  patientId: "1",
  carePathJourneyId: null,
  clinicalServiceId: null,
  palCategoryId: "1",
  urgency: {
    isExpedited: true,
    reasonNote: "Reason Note for urgency",
  },
  unitType: "NUMBER_OF_VISITS",
  units: 3,
  endDate: "2020-12-31",
  startDate: "2020-01-20",
  recurrenceType: "RECURRING",
  authStatus: "PENDING",
  authNumber: "authNumberPopulated",
  cohereId: "cohereIdPopulated",
  lastUpdated: "2020-05-10",
  dateCreated: "2020-01-20",
  intakeTimestamp: "2020-01-20T12:01:31",
};

const carePathServiceRequest = {
  ...populatedServiceRequest,
  // attachments will be created after the service request
  carePathJourneyId: "CAREPATH_JOURNEY_COMPLETE",
  clinicalServiceId: "SUBCATEGORY_COMPLETE_DME",
};

const scenarios = {
  standard(server: Server) {
    // Populated entry
    const populatedRequest = server.create("serviceRequest", {
      ...populatedServiceRequest,
      authStatus: "APPROVED",
      authNumber: "ABCD-1234",
      id: "SERVICEREQUEST_POPULATED_SINGLE_SERVICE",
    } as any);

    server.create("serviceRequest", {
      ...carePathServiceRequest,
      id: "SERVICEREQUEST_COMPLETE_WITH_SUBCATEGORY_DME",
    } as any);

    // Attachments for this entry
    server.create("serviceRequestAttachment", {
      serviceRequest: populatedRequest,
      name: "A Report.txt",
      type: "Report",
      size: 100,
    } as any);
    server.create("serviceRequestAttachment", {
      serviceRequest: populatedRequest,
      name: "Test Long Text Long Text Long Text Long Text Long Text Long Text Long Text Long Text Long Text.END",
      type: "Other",
      size: 2000000,
    } as any);
    server.create("serviceRequestAttachment", {
      serviceRequest: populatedRequest,
      name: "TestLongWordLongWordLongWordLongWordLongWordLongWordLongWordLongWordLongWordLongWordLongWordLongWord.END",
      type: "Other",
      size: 3000000000,
    } as any);

    // Test different auth statuses
    [
      "DRAFT",
      "INTAKE",
      "PENDING",
      "APPROVED",
      "WITHDRAWN",
      "RECOMMENDED_DENIAL",
      "DENIED",
    ].forEach((authStatus) => {
      server.create("serviceRequest", {
        ...populatedServiceRequest,
        authStatus,
        cohereId: `cohereIdStatus_${authStatus}`,
        authNumber: `authNumber_${authStatus}`,
        id: `SERVICE_REQUEST_WITH_STATUS_${authStatus}`,
      } as any);
    });

    // Test long procedure description
    const longProcCodeResult = (server.schema as any).procedureCodes.where({
      code: "PCODElongDesc",
    });
    if (longProcCodeResult.models.length) {
      server.create("serviceRequest", {
        ...populatedServiceRequest,
        procedureCodeIds: [longProcCodeResult.models[0].id],
      } as any);
    } else {
      console.error("ServiceRequest mirage setup: Can't find PCODElongDesc");
    }

    // Empty entry
    server.create("serviceRequest", emptyServiceRequest as any);

    server.create("serviceRequest", {
      ...populatedServiceRequest,
      id: "SERVICEREQUEST_POPULATED_SINGLE_SERVICE_READ_ONLY",
      authStatus: "APPROVED",
      authNumber: "authNumber_IsReadOnly",
      isReadOnly: true,
    } as any);

    server.createList("serviceRequest", 10, {
      // for the form to display, the request requires a primary diagnosis
      primaryDiagnosisId: "1",
    } as any);
  },
  empty(server: Server) {},
};

const activeScenario = "standard";
//const activeScenario = "short";
//const activeScenario = "empty";

const serializeAttachment = function (
  attachmentJson: any,
  serviceRequestId: string
) {
  return {
    ...attachmentJson,
    serviceRequest: {
      id: serviceRequestId,
    },
    url: `/attachments/${attachmentJson.id}`,
  };
};

const serializers = {
  serviceRequest: ApplicationSerializer.extend({
    include: [
      "patient",
      "carePathJourney",
      "clinicalService",
      "palCategory",
      "primaryDiagnosis",
      "secondaryDiagnoses",
      "placeOfService",
      "procedureCodes",
      "orderingProvider",
      "performingProvider",
      "facility",
      "attachments",
    ],
    serialize(model, request) {
      let json = (ApplicationSerializer.prototype as any).serialize.apply(
        this,
        arguments
      );
      if (json.attachments) {
        json.attachments = json.attachments.map((attachment: any) =>
          serializeAttachment(attachment, json.id)
        );
      }
      return json;
    },
  }),
  serviceRequestAttachment: ApplicationSerializer.extend({
    serialize(model, request) {
      let json = (ApplicationSerializer.prototype as any).serialize.apply(
        this,
        arguments
      );
      json = serializeAttachment(json, model.attrs.serviceRequestId);
      return json;
    },
  }),
};

export { models, factories, scenarios, activeScenario, serializers };
