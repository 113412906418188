import React, { useCallback, useEffect, useMemo, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import IntegrationLogDetailView from "../../ServiceRequest/ReadonlyDetail/IntegrationLogDetailView";
import {
  ServiceRequestResponse,
  IntegrationStatus,
  useGetServiceRequestIntegrationLogs,
  KafkaMessageDocumentResponse,
  KafkaIntegrationStatus,
  TATNotification,
  IntegrationLog,
  Attachment,
} from "@coherehealth/core-platform-api";
import { Chip, Modal, InlineButton, Tooltip, formatDateStrAsEastern } from "@coherehealth/common";
import { useSnackbar } from "notistack";
import {
  IntegrationExecution,
  IntegrationType,
  IntegrationStatus as ObservabilityIntegrationStatus,
  useGetBulkAllIntegrationExecutionLogs,
  useGetIntegrationExecutionLogByIdAndIntegrationType,
} from "@coherehealth/int-obs-api";
import config from "api/config";
import { SingleIntegrationChipTypeMap, STATUS_TEXT_MAP } from "../common/utils";

interface Props {
  serviceRequest: ServiceRequestResponse;
  mailVendorKafkaLogs?: KafkaMessageDocumentResponse[];
  tatNotification?: TATNotification | null;
}
export default function IntegrationStatusSummary({ serviceRequest, mailVendorKafkaLogs, tatNotification }: Props) {
  const { refetch: getIntegrationExecutionByIdAndIntegrationType } =
    useGetIntegrationExecutionLogByIdAndIntegrationType({
      base: `${config.INT_OBS_SERVICE_API_URL}`,
      objectId: serviceRequest?.id,
      queryParams: {
        integrationType: "",
      },
      lazy: true,
    });
  const { integration } = serviceRequest;

  const [expanded, setIsExpanded] = useState<boolean>();
  const [authorizationOutboundStatus, setAuthorizationOutboundStatus] = useState<
    ObservabilityIntegrationStatus | undefined
  >(serviceRequest?.integrationStatus);
  const [attachmentStatus, setAttachmentStatus] = useState<ObservabilityIntegrationStatus | undefined>();
  const [attachments, setAttachments] = useState<Attachment[]>();
  const { enqueueSnackbar } = useSnackbar();

  // The result is sorted in descending order by message time, so pull the most recent status message to display
  const currentMailVendorKafkaStatus =
    mailVendorKafkaLogs && mailVendorKafkaLogs.length > 0 ? mailVendorKafkaLogs[0]?.status : undefined;

  const corePlatformSRBasedIntegrations = currentMailVendorKafkaStatus
    ? ([
        ["welltok", "VAT"],
        ["salesforce", "Salesforce"],
        ["carewebqi", "CareWebQI"],
        ["fax_final_determination", "Final determination fax"],
      ] as const)
    : ([
        ["matrix", "Mail vendor"],
        ["welltok", "VAT"],
        ["salesforce", "Salesforce"],
        ["carewebqi", "CareWebQI"],
        ["fax_final_determination", "Final determination fax"],
      ] as const);

  useEffect(() => {
    let attachmentCollection: Attachment[] = [];
    if (serviceRequest.attachments && serviceRequest.attachments.length > 0) {
      attachmentCollection = attachmentCollection.concat(serviceRequest.attachments);
    }
    if (
      serviceRequest.finalDeterminationLetterAttachments &&
      serviceRequest.finalDeterminationLetterAttachments.length > 0
    ) {
      attachmentCollection = attachmentCollection.concat(serviceRequest.finalDeterminationLetterAttachments);
    }
    setAttachments(attachmentCollection);
  }, [serviceRequest.attachments, serviceRequest.finalDeterminationLetterAttachments]);
  const serviceRequestIntegrationStatus = serviceRequest?.integrationStatus;

  let attachmentsMap: { [key: string]: Attachment[] } = useMemo(() => {
    return {};
  }, []);

  attachments?.forEach((attachment) => {
    if (attachment?.integrationStatus) {
      if (attachmentsMap.hasOwnProperty(attachment.integrationStatus)) {
        attachmentsMap[attachment.integrationStatus].push(attachment);
      } else {
        attachmentsMap[attachment.integrationStatus] = [attachment];
      }
    }
  });

  /**
   * checkAttachmentMapAndUpdateAttachmentStatus: Checks and updates attachment status based on Attachment Map values
   */
  const checkAttachmentMapAndUpdateAttachmentStatus = useCallback(
    (
      attachmentsMap: {
        [key: string]: Attachment | IntegrationExecution[];
      },
      setAttachmentStatus: (value: React.SetStateAction<ObservabilityIntegrationStatus | undefined>) => void
    ): string | undefined => {
      let completeAttachments: any[] = [];
      if (attachmentsMap.hasOwnProperty("COMPLETE")) {
        completeAttachments = completeAttachments.concat(attachmentsMap["COMPLETE"]);
      }
      if (attachmentsMap.hasOwnProperty("INTERNALLY_COMPLETE")) {
        completeAttachments = completeAttachments.concat(attachmentsMap["INTERNALLY_COMPLETE"]);
      }

      let updatedAttachmentStatus: ObservabilityIntegrationStatus | undefined = undefined;
      if (attachmentsMap.hasOwnProperty("READY")) {
        updatedAttachmentStatus = "READY";
        setAttachmentStatus("READY");
      }

      if (attachmentsMap.hasOwnProperty("PROCESSING") || attachmentsMap.hasOwnProperty("REQUEST_ERROR")) {
        updatedAttachmentStatus = "PROCESSING";
        setAttachmentStatus("PROCESSING");
      }

      if (attachmentsMap.hasOwnProperty("FAILED")) {
        updatedAttachmentStatus = "FAILED";
        setAttachmentStatus("FAILED");
      }

      if (attachments?.length === completeAttachments.length) {
        updatedAttachmentStatus = "COMPLETE";
        setAttachmentStatus("COMPLETE");
      }

      if (attachmentsMap.hasOwnProperty("UNDELIVERABLE")) {
        updatedAttachmentStatus = undefined;
        setAttachmentStatus(undefined);
      }
      return updatedAttachmentStatus;
    },
    [attachments?.length]
  );

  /**
   * fetchAndSetIntegrationStatusForAttachments: Fetches from int observability service attachment status based on their ids and Sets Attachment Integration Status
   */
  const fetchAndSetIntegrationStatusForAttachments = useCallback(
    async (
      integrationType: IntegrationType,
      setIntegrationStatus: React.Dispatch<React.SetStateAction<ObservabilityIntegrationStatus | undefined>>
    ) => {
      let promises: Promise<IntegrationExecution | null>[] = [];
      attachments?.forEach((attachment) => {
        promises.push(
          getIntegrationExecutionByIdAndIntegrationType({
            pathParams: {
              objectId: attachment?.id,
            },
            queryParams: {
              integrationType: integrationType,
            },
          })
        );
      });
      const attachmentsResponseData = await Promise.all(promises);
      let intObsAttachmentsMap: { [key: string]: IntegrationExecution[] } = {};
      attachmentsResponseData.forEach((attachmentResponseData) => {
        if (attachmentResponseData?.status && intObsAttachmentsMap.hasOwnProperty(attachmentResponseData.status)) {
          intObsAttachmentsMap[attachmentResponseData.status].push(attachmentResponseData);
        } else if (attachmentResponseData?.status) {
          intObsAttachmentsMap[attachmentResponseData.status] = [attachmentResponseData];
        }
      });
      checkAttachmentMapAndUpdateAttachmentStatus(intObsAttachmentsMap, setIntegrationStatus);
    },
    [attachments, checkAttachmentMapAndUpdateAttachmentStatus, getIntegrationExecutionByIdAndIntegrationType]
  );

  /** fetchAndSetIntegrationStatus: Callback function fetches from int observability service and sets integrationStatus based on passed in integrationType
   *
   * @param integrationType Required integration type
   * @param setIntegrationStatus React set state method to update required state from response
   * @param objectId Used to fetch the object from int observability service
   *
   */
  const fetchAndSetIntegrationStatus = useCallback(
    async (
      integrationType: IntegrationType,
      setIntegrationStatus: React.Dispatch<React.SetStateAction<ObservabilityIntegrationStatus | undefined>>,
      objectId: string
    ) => {
      try {
        // Skip if still in Draft
        if (serviceRequest?.authStatus === "DRAFT") {
          setIntegrationStatus("NOT_STARTED");
          return;
        }

        if (integrationType === "ATTACHMENTS") {
          fetchAndSetIntegrationStatusForAttachments(integrationType, setIntegrationStatus);
        } else {
          const responseData = await getIntegrationExecutionByIdAndIntegrationType({
            pathParams: {
              objectId: serviceRequest?.id,
            },
            queryParams: {
              integrationType: integrationType,
            },
          });
          setIntegrationStatus(responseData?.status);
        }
      } catch (error) {
        setIntegrationStatus("REQUEST_ERROR");
        enqueueSnackbar(`Failed to get ${integrationType} from int observability service`, {
          variant: "error",
          preventDuplicate: true,
        });
      }
    },
    [
      enqueueSnackbar,
      fetchAndSetIntegrationStatusForAttachments,
      getIntegrationExecutionByIdAndIntegrationType,
      serviceRequest?.authStatus,
      serviceRequest?.id,
    ]
  );

  useEffect(() => {
    if (attachments) {
      const updatedAttachmentStatus = checkAttachmentMapAndUpdateAttachmentStatus(attachmentsMap, setAttachmentStatus);
      if (!updatedAttachmentStatus) {
        fetchAndSetIntegrationStatus("ATTACHMENTS", setAttachmentStatus, serviceRequest?.id);
      }
    }
  }, [
    attachments,
    attachmentsMap,
    checkAttachmentMapAndUpdateAttachmentStatus,
    fetchAndSetIntegrationStatus,
    serviceRequest?.id,
  ]);

  useEffect(() => {
    if (serviceRequest?.id && !serviceRequestIntegrationStatus) {
      fetchAndSetIntegrationStatus("AUTHORIZATION_OUTBOUND", setAuthorizationOutboundStatus, serviceRequest?.id);
    }
  }, [fetchAndSetIntegrationStatus, serviceRequest?.id, serviceRequestIntegrationStatus]);

  return (
    <>
      <SingleIntegration status={authorizationOutboundStatus} name="Payor" />
      {currentMailVendorKafkaStatus && (
        <SingleIntegration status={currentMailVendorKafkaStatus} name="Mail Vendor - Kafka" />
      )}
      <SingleIntegration status={attachmentStatus} name="Attachments" />
      {corePlatformSRBasedIntegrations.map(([key, name], index) => (
        <SingleIntegration
          status={integration?.[key]?.status}
          name={name}
          lastUpdated={integration?.[key]?.lastUpdatedAt}
          key={index}
        />
      ))}
      {tatNotification && (
        <SingleIntegration
          status={tatNotification.integration?.matrix?.status}
          lastUpdated={tatNotification.integration?.matrix?.lastUpdatedAt}
          name="TAT extension mail"
        />
      )}
      <InlineButton
        onClick={() => {
          setIsExpanded((prev) => !prev);
        }}
      >
        Integration log details
      </InlineButton>
      <Modal
        open={Boolean(expanded)}
        onClose={() => {
          setIsExpanded(false);
        }}
      >
        {expanded !== undefined && (
          <IntegrationLogDetails
            serviceRequestId={serviceRequest.id}
            serviceRequestAttachmentIds={serviceRequest?.attachments?.map((attachment) => attachment?.id) ?? []}
            serviceRequestAppealIds={serviceRequest?.appealIds ?? []}
          />
        )}
      </Modal>
    </>
  );
}

interface SingleIntegrationProps {
  status?: IntegrationStatus | KafkaIntegrationStatus | ObservabilityIntegrationStatus | "NOT_STARTED";
  lastUpdated?: string | undefined;
  name: string;
}
function SingleIntegration({ status, name, lastUpdated }: SingleIntegrationProps) {
  const chipType = SingleIntegrationChipTypeMap[status || "not started"];
  return (
    <Tooltip title={tooltipText(status) + (lastUpdated ? ` Last updated: ${formatDateStrAsEastern(lastUpdated)}` : "")}>
      <Chip
        style={{ marginRight: 8, marginBottom: 8 }}
        type={chipType}
        label={`${name} (${status?.toLocaleLowerCase() || "not started"})`}
        dataPublic={true}
      />
    </Tooltip>
  );
}

const tooltipText = (status?: IntegrationStatus | KafkaIntegrationStatus | ObservabilityIntegrationStatus): string =>
  (status && STATUS_TEXT_MAP[status]) ||
  "No integration status yet. Typically this means this is not ready to send to this integration. Otherwise, this indicates an application issue";

interface LogsComponentProps {
  serviceRequestId: string;
  serviceRequestAttachmentIds: string[];
  serviceRequestAppealIds: string[];
}
function IntegrationLogDetails({
  serviceRequestId,
  serviceRequestAttachmentIds,
  serviceRequestAppealIds,
}: LogsComponentProps) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: integrationLogsData,
    loading: integrationLogsLoading,
    error: integrationLogsError,
  } = useGetServiceRequestIntegrationLogs({ id: serviceRequestId });
  const {
    data: intObservabilityLogsData,
    loading: intObservabilityLogsLoading,
    error: intObservabilityLogsError,
  } = useGetBulkAllIntegrationExecutionLogs({
    base: `${config.INT_OBS_SERVICE_API_URL}`,
    queryParams: {
      objectIds: [serviceRequestId, ...serviceRequestAttachmentIds, ...serviceRequestAppealIds].join(","),
    },
  });
  useEffect(() => {
    if (integrationLogsError) {
      enqueueSnackbar(`Failed to log integration log details: ${integrationLogsError.message}`, { variant: "error" });
    }
    if (intObservabilityLogsError) {
      enqueueSnackbar(`Failed to log integration log details: ${intObservabilityLogsError.message}`, {
        variant: "error",
      });
    }
  }, [integrationLogsError, intObservabilityLogsError, enqueueSnackbar]);

  if (integrationLogsLoading || intObservabilityLogsLoading) {
    return <CircularProgress />;
  }
  let logs: (IntegrationExecution | IntegrationLog)[] = [];
  if (integrationLogsData && integrationLogsData.logs) {
    logs = [...logs, ...integrationLogsData.logs];
  }
  if (intObservabilityLogsData) {
    logs = [...logs, ...intObservabilityLogsData];
  }
  return <IntegrationLogDetailView logs={logs} />;
}
