import { stringify as stringifyQueryString, parse as parseQueryString } from "qs";
import { useFeature } from "@coherehealth/common";

export default function useOcrEnhancedPath(originalPath: string, orderBySegmentation?: boolean): string {
  if (useFeature("preferOcrEnhanced")) {
    return doEnhanceOcrPath(originalPath, orderBySegmentation);
  }

  return originalPath;
}

export function useMultipleOcrEnhancedPaths(originalPaths: string[], orderBySegmentation?: boolean): string[] {
  if (useFeature("preferOcrEnhanced")) {
    return originalPaths.map((originalPath) => doEnhanceOcrPath(originalPath, orderBySegmentation));
  }

  return originalPaths;
}

function doEnhanceOcrPath(originalPath: string, orderBySegmentation?: boolean): string {
  try {
    const url = new URL(originalPath);
    const query = parseQueryString(url.search.replace(/^[?]/, ""));
    query.preferOcrEnhanced = "true";
    if (orderBySegmentation) {
      query.orderBySegmentation = "true";
    }
    url.search = stringifyQueryString(query);
    return url.toString();
  } catch (e) {
    // This probably just indicates an invalid URL: should just return the original
    return originalPath;
  }
}
