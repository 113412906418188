import React, { Dispatch, SetStateAction, useContext } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { Caption, InlineButton, PrimaryButton, SecondaryButton } from "@coherehealth/common";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import { trackUserActivity } from "../../util/userActivityTracker";
import Divider from "@material-ui/core/Divider";
import { NavigationContext } from "./NavigationContext";
import FaxAuthBuilderFooter from "./FaxAuthBuilderFooter";
import FaxReferralsFooter from "components/DocumentViewer/FaxAttachment/FaxReferralsBuilderPage/FaxReferralsFooter";
import { ReferralsNavigationContext } from "components/DocumentViewer/FaxAttachment/FaxReferralsBuilderPage/ReferralsNavigationContext";

interface Props {
  onSaveAndExitClick?: () => void;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick?: Dispatch<SetStateAction<boolean>>;
  showError?: boolean;
  errorCaptionText?: string;
  primaryButtonDisabled: boolean;
  primaryButtonLoading?: boolean;
  primaryButtonText: string;
  secondaryButtonDisabled?: boolean;
  secondaryButtonLoading?: boolean;
  secondaryButtonText?: string;
  showSaveAndExitButton: boolean;
  saveAndExitButtonDisabled?: boolean;
  showSecondaryButton?: boolean;
  dataPendoHealthPlan?: string;
  showExitWithoutSavingButton?: boolean;
  exitWithoutSavingButtonDisabled?: boolean;
  onExitWithoutSavingClick?: () => void;
  setFooterHeight: Dispatch<SetStateAction<number>>;
  isContinuationWorkflow?: boolean;
}

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  secondaryContainer: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  caption: {
    color: theme.palette.error.dark,
  },
  primaryContainer: {
    minWidth: theme.spacing(25),
  },
  primaryWrappedContainer: {
    minWidth: 157,
  },
  divider: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  fullSizeFooter: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  wrappedFooter: {
    display: "flex",
    flexDirection: "column-reverse",
    flexWrap: "wrap",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  cancelButton: {
    color: theme.palette.error.dark,
  },
}));

const Footer = ({
  primaryButtonDisabled,
  onPrimaryButtonClick,
  primaryButtonLoading,
  primaryButtonText,
  errorCaptionText,
  showError,
  showSaveAndExitButton,
  showSecondaryButton,
  onSecondaryButtonClick,
  secondaryButtonText,
  onSaveAndExitClick,
  dataPendoHealthPlan,
  saveAndExitButtonDisabled,
  onExitWithoutSavingClick,
  exitWithoutSavingButtonDisabled,
  showExitWithoutSavingButton,
  secondaryButtonDisabled,
  setFooterHeight,
  isContinuationWorkflow,
}: Props) => {
  const { isFaxAuthBuilderFlow } = useContext(NavigationContext);
  const { isFaxReferralsFlow } = useContext(ReferralsNavigationContext);
  const classes = useStyles();

  const shouldUseFaxAuthBuilderFooter = Boolean(isFaxAuthBuilderFlow);
  const shouldUseFaxReferralsFooter = Boolean(isFaxReferralsFlow);

  if (shouldUseFaxReferralsFooter) {
    return (
      <FaxReferralsFooter
        onPrimaryButtonClick={onPrimaryButtonClick}
        showError={showError}
        errorCaptionText={errorCaptionText}
        primaryButtonDisabled={primaryButtonDisabled}
        primaryButtonLoading={primaryButtonLoading}
        primaryButtonText={primaryButtonText}
        setFooterHeight={setFooterHeight}
      />
    );
  } else if (shouldUseFaxAuthBuilderFooter) {
    return (
      <FaxAuthBuilderFooter
        onPrimaryButtonClick={onPrimaryButtonClick}
        showError={showError}
        errorCaptionText={errorCaptionText}
        primaryButtonDisabled={primaryButtonDisabled}
        primaryButtonLoading={primaryButtonLoading}
        primaryButtonText={primaryButtonText}
        dataPendoHealthPlan={dataPendoHealthPlan}
        setFooterHeight={setFooterHeight}
        isContinuationWorkflow={isContinuationWorkflow}
      />
    );
  }

  return (
    <Grid container className={classes.gridContainer} alignItems={"center"}>
      <Grid item xs={4}>
        <Grid container spacing={4} alignItems={"flex-start"} justifyContent={"flex-start"}>
          {showSaveAndExitButton && (
            <Grid item>
              <InlineButton
                disabled={saveAndExitButtonDisabled}
                data-tracking-id="save-and-exit"
                startIcon={<SaveIcon />}
                onClick={onSaveAndExitClick}
                data-public
              >
                Save and exit
              </InlineButton>
            </Grid>
          )}
          {showExitWithoutSavingButton && (
            <>
              {showExitWithoutSavingButton && showSaveAndExitButton && (
                <Divider orientation="vertical" flexItem className={classes.divider} />
              )}
              <Grid item className={classes.secondaryContainer}>
                <InlineButton
                  disabled={exitWithoutSavingButtonDisabled}
                  data-tracking-id="exit-without-saving"
                  startIcon={<DeleteIcon />}
                  onClick={onExitWithoutSavingClick}
                >
                  Exit without saving
                </InlineButton>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs>
        <Grid container spacing={4} alignItems={"flex-end"} justifyContent={"flex-end"}>
          {showError && (
            <Grid item className={classes.secondaryContainer}>
              <Caption className={classes.caption} data-public>
                {errorCaptionText}
              </Caption>
            </Grid>
          )}
          {showSecondaryButton && onSecondaryButtonClick && (
            <Grid item className={classes.secondaryContainer}>
              <SecondaryButton
                disabled={secondaryButtonDisabled}
                warning
                fullWidth
                onClick={() => {
                  onSecondaryButtonClick(true);
                }}
                data-public
              >
                {secondaryButtonText}
              </SecondaryButton>
            </Grid>
          )}
          <Grid item className={classes.primaryContainer}>
            <PrimaryButton
              disabled={primaryButtonDisabled}
              fullWidth
              onClick={() => {
                dataPendoHealthPlan
                  ? trackUserActivity(dataPendoHealthPlan + "-patient")
                  : trackUserActivity(dataPendoHealthPlan + "-noHealthPlan");
                onPrimaryButtonClick();
              }}
              loading={primaryButtonLoading}
              data-pendo-health-plan={dataPendoHealthPlan || ""}
              data-public
            >
              {primaryButtonText}
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
