import React from "react";

import {
  Subtitle2,
  H4,
  Modal,
  PrimaryButton,
  TertiaryButton,
  colorsLight,
  useStableUniqueId,
} from "@coherehealth/common";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ServiceRequestDuplicate from "components/images/ServiceRequestDuplicate.svg";
import ServiceRequestWithdraw from "components/images/ServiceRequestWithdraw.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {
      padding: theme.spacing(0, 1, 2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    title: {
      textAlign: "center",
      paddingTop: theme.spacing(3),
    },
    details: {
      textAlign: "center",
      padding: theme.spacing(2, 0, 4),
      color: colorsLight.font.light,
    },
    goBackButton: {
      marginTop: theme.spacing(1),
      color: colorsLight.black.mediumEmphasis,
      width: "75%",
    },
    continueButton: {
      width: "75%",
    },
    modalImage: {
      width: 200,
      height: 200,
      display: "block",
    },
  })
);

export interface Props {
  open: boolean;
  handleClose: () => void;
  onContinue: () => void;
  loading: boolean;
  variant: Variant;
}

type Variant = "WITHDRAW_AND_REPLACE" | "VOID_AND_REPLACE" | "AMEND";
type DisplayStringKeys = "header" | "subText" | "primaryButton" | "image";

const variantDisplayStrings: Record<Variant, Record<DisplayStringKeys, string>> = {
  WITHDRAW_AND_REPLACE: {
    header: "We will submit this change as a new service request.",
    subText: "Your new authorization number will replace your original request which will be withdrawn.",
    primaryButton: "Withdraw and replace",
    image: ServiceRequestWithdraw,
  },
  VOID_AND_REPLACE: {
    header: "We will submit this change as a new service request.",
    subText: "Your new authorization number will replace your original request which will be withdrawn.",
    primaryButton: "Void and replace",
    image: ServiceRequestWithdraw,
  },
  AMEND: {
    header: "This change will be submitted as an additional service request with a new authorization number.",
    subText: "Your original authorization will still be valid for those codes and units.",
    primaryButton: "Start new authorization",
    image: ServiceRequestDuplicate,
  },
};

export default function ApprovedSrEditRedirectionModal({ open, handleClose, onContinue, loading, variant }: Props) {
  const classes = useStyles();
  const titleId = useStableUniqueId("approved-sr-edit-redirection-modal");
  const instructionsId = useStableUniqueId("approved-sr-edit-redirection-modal-description");

  return (
    <Modal onClose={handleClose} fullWidth aria-labelledby={titleId} aria-describedby={instructionsId} open={open}>
      <div className={classes.modalContent}>
        <img className={classes.modalImage} src={variantDisplayStrings[variant].image} alt={""} />
        <H4 className={classes.title}>{variantDisplayStrings[variant].header}</H4>
        <Subtitle2 className={classes.details}>{variantDisplayStrings[variant].subText}</Subtitle2>
        <PrimaryButton className={classes.continueButton} onClick={onContinue} loading={loading}>
          {variantDisplayStrings[variant].primaryButton}
        </PrimaryButton>
        <TertiaryButton className={classes.goBackButton} onClick={handleClose}>
          Go Back
        </TertiaryButton>
      </div>
    </Modal>
  );
}
