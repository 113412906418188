import {
  SecondaryButton,
  PrimaryButton,
  TextField,
  CopyToClipboard,
  POLICY_UNITED_STATES_OPTIONS,
} from "@coherehealth/common";
import { Typography, Grid, Card } from "@mui/material";
import { AppBar, Grid as MainContent } from "@material-ui/core";

import HeaderContainer from "components/AppHeader/HeaderContainer";
import { ContactInfoFormSection } from "./FormSections/ContactInfoFormSection";
import { PatientInfoFormSection } from "./FormSections/PatientInfoFormSection";
import { CoveragesInfoFormSection } from "./FormSections/CoveragesInfoFormSection";
import {
  SectionContainer,
  OptionsContainer,
  useAddNewPatientFormStyles,
  ButtonsContainer,
} from "./addNewPatientStyles";
import {
  Address,
  Coverage,
  UserActivity,
  useGetTemporaryMemberId,
  useUpsertPlaceholderPatient,
} from "@coherehealth/core-platform-api";
import { useEffect, useReducer, useState } from "react";
import { FormError, initialFormState, isOptionalField } from "./formOptions";
import { generatePath, useNavigate } from "react-router";
import routes from "routes";
import { useTrackUserInteraction } from "util/userActivityTracker";
import { formReducer } from "./formReducer";
import { useGetUser } from "components/ClinicalReview/reviewUtils/utils";
import { useSnackbar } from "notistack";
import { useGetTemporaryMemberAuthsConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import { ContactAddressFormSection } from "./FormSections/ContactAddressFormSection";
import { error as logError, stringifyError } from "logger";

export const AddNewPatientForm = () => {
  const navigate = useNavigate();
  const addNewPatientStyles = useAddNewPatientFormStyles({});
  const { enqueueSnackbar } = useSnackbar();

  const currentUser = useGetUser();
  const userId = currentUser?.sub || "";
  const [formState, dispatch] = useReducer(formReducer, initialFormState);
  const { data: tempIdData, loading: loadingTempData } = useGetTemporaryMemberId({});
  const { mutate: upsertPlaceholderPatient, error: upsertPlaceholderPatientError } = useUpsertPlaceholderPatient({});
  const [tempMemberId, setTempMemberId] = useState<string | undefined>();
  const [patientId, setPatientId] = useState<string>();
  const [copied, setCopied] = useState<boolean>();
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
  const [isValidForm, setIsValidForm] = useState<boolean>(true);
  const [isCareRecommendationPatient, setIsCareRecommendationPatient] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (copied) {
        setCopied(false);
      }
    }, 10000);
    return () => clearTimeout(timer);
  }, [copied]);

  const payload: UserActivity = {
    event: "TEMPORARY_PATIENT_CREATION",
    stage: "ADD_TEMPORARY_PATIENT_FORM",
    type: "INTERACTION",
    interactionAccept: true,
    activityContext: {
      patientId: patientId,
      userId: userId,
    },
    beforeSnapshot: {
      mongoPatientId: patientId,
    },
    afterSnapshot: {
      mongoPatientId: patientId,
    },
  };
  const trackInteraction = useTrackUserInteraction();
  const trackCreatePatientActivity = async (): Promise<void> => {
    await trackInteraction(payload);
  };

  useEffect(() => {
    if (!loadingTempData && tempIdData && !tempMemberId) {
      let formattedData = JSON.parse(tempIdData?.toString());
      setTempMemberId(formattedData?.sequenceValue);
    }
  }, [tempIdData, loadingTempData, tempMemberId]);

  const handleCancel = () => {
    navigate(routes.DASHBOARD);
  };

  const validateForm = (formError: FormError): boolean => {
    const formIsInvalid = Object.entries(formError).some(([fieldName, hasError]) => {
      return hasError && !isOptionalField(fieldName, tempPatientFormOptionalFields);
    });
    return !formIsInvalid;
  };

  useEffect(() => {
    setIsValidForm(validateForm(formState.formError));

    // Instead of passing the "validateForm()" method as dependency
    // I'm passing the variable that causes that the dependency gets
    // updated to avoid a runaway useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, formState.formError, isCareRecommendationPatient]);

  const handleSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();
    setFormSubmitting(true);

    if (!validateForm(formState.formError)) {
      setIsValidForm(false);
      return;
    }
    setIsValidForm(true);
    const {
      ADDRESS_LINE_1: line1,
      ADDRESS_LINE_2: line2,
      CITY: city,
      STATE: state,
      ZIP_CODE: zipCode,
    } = formState.contactInfo;
    const address: Address = { line1, line2, city, state, zipCode };
    const memberId = tempMemberId;
    const { npi, address: pcpAddress, name } = formState.coveragesInfo.PRIMARY_CARE_PROVIDER || {};
    const pcp = { npi, ...pcpAddress, name };
    const {
      FIRST_NAME: firstName,
      LAST_NAME: lastName,
      MIDDLE_NAME: middleName,
      DATE_OF_BIRTH: dateOfBirth,
      GENDER: gender,
    } = formState.patientInfo;
    const {
      PLAN_START_DATE: planStart,
      PLAN_END_DATE: planEnd,
      HEALTH_PLAN: healthPlanName,
      LINE_OF_BUSINESS: lineOfBusiness,
      LINE_OF_BUSINESS_DESCRIPTION: lineOfBusinessDescription,
      PLAN_TYPE: planType,
      GROUP_ID: groupId,
      MARKET_NUMBER: marketNumber,
      STATE_OF_ISSUE: stateOfIssue,
      LINE_OF_BUSINESS_TYPE: coverageLineOfBusinessType,
      COVERAGE_PRODUCT_TYPE: coverageProductType,
      PRODUCT_ID: productId,
      MARKET: market,
      LEGAL_ENTITY: legalEntity,
    } = formState.coveragesInfo;
    const stateOfIssueId = POLICY_UNITED_STATES_OPTIONS.find((state) => state.label === stateOfIssue)?.id;
    const coverages: Coverage[] = [
      {
        healthPlanName,
        planStart,
        planEnd,
        lineOfBusiness,
        productId,
        market,
        legalEntity,
        primaryCareProvider: pcp,
        lineOfBusinessDescription,
        planType,
        groupId,
        marketNumber,
        stateOfIssue: stateOfIssueId,
        coverageLineOfBusinessType,
        coverageProductType,
      },
    ];

    const patientStateId = POLICY_UNITED_STATES_OPTIONS.some((state) => state.id === address.state)
      ? address.state
      : POLICY_UNITED_STATES_OPTIONS.find((state) => state.label === address.state)?.id;
    const submissionPayload = {
      firstName,
      lastName,
      middleName,
      dateOfBirth,
      gender,
      memberId,
      address: { ...address, state: patientStateId },
      coverages,
    };

    let response = null;
    try {
      response = await upsertPlaceholderPatient({
        ...submissionPayload,
      });
    } finally {
      setFormSubmitting(false);
    }

    if (response) {
      setPatientId(response.id);
      await trackCreatePatientActivity();
      window.location.assign(generatePath(routes.PATIENT_SUMMARY, { patientId: response.id }));
      setFormSubmitting(false);
    }
  };

  const { HEALTH_PLAN: healthPlanName } = formState.coveragesInfo;

  const { data: temporaryMemberAuthsConfig, error: temporaryMemberAuthsConfigError } =
    useGetTemporaryMemberAuthsConfigurationByPayer(healthPlanName ?? "");
  const {
    enabled: temporaryMemberAuthsEnabled,
    enableAddressValidation: addressValidationEnabled,
    formOptionalFields,
    formCareRecommendationOptionalFields,
    patientFormDefaultValues,
    formCareRecommendationDefaultValues,
  } = temporaryMemberAuthsConfig ?? {};

  const defaultValues = isCareRecommendationPatient ? formCareRecommendationDefaultValues : patientFormDefaultValues;
  /**
   * Updates the form fields if default value exists in the config.
   */
  useEffect(() => {
    if (defaultValues) {
      dispatch({
        type: "UPDATE_PATIENT_INFO",
        payload: { ...defaultValues },
      });
      dispatch({
        type: "UPDATE_COVERAGES_INFO",
        payload: { ...defaultValues },
      });
      dispatch({
        type: "UPDATE_CONTACT_INFO",
        payload: { ...defaultValues },
      });
      let fieldErrorPayload: FormError = {};
      Object.entries(defaultValues).forEach(([fieldName, fieldDefaultValue]) => {
        fieldErrorPayload = {
          ...fieldErrorPayload,
          [fieldName]: !fieldDefaultValue,
        };
      });
      dispatch({
        type: "UPDATE_FORM_ERROR",
        payload: fieldErrorPayload,
      });
    }
  }, [defaultValues]);

  useEffect(() => {
    if (temporaryMemberAuthsConfigError) {
      const errorMsg = "Error fetching temporaryMemberAuths configuration";
      enqueueSnackbar(errorMsg, { preventDuplicate: true, variant: "error" });
      logError(`${errorMsg} ${stringifyError(temporaryMemberAuthsConfigError.data)}`);
    }

    if (upsertPlaceholderPatientError) {
      enqueueSnackbar("Error creating temporary Patient, please validate if the patient already exists", {
        preventDuplicate: true,
        variant: "error",
      });
      logError(`Error creating temporary Patient ${stringifyError(upsertPlaceholderPatientError.data)}`);
    }
  }, [temporaryMemberAuthsConfigError, upsertPlaceholderPatientError, enqueueSnackbar]);

  const tempPatientFormOptionalFields = isCareRecommendationPatient
    ? formCareRecommendationOptionalFields
    : formOptionalFields;

  const temporaryMemberAuthsDisabled = !Boolean(temporaryMemberAuthsEnabled && healthPlanName);

  // If this is a care recommendation patient OR address validation config is false -> Do not perform address validation
  const addressValidationDisabled = !Boolean((addressValidationEnabled ?? true) && !isCareRecommendationPatient);

  return (
    <div>
      <HeaderContainer height={96}>
        <div className={addNewPatientStyles.headerTextContainer}>
          <Typography variant="h3" align="center" textAlign="center" data-public data-testid="patient-form-header">
            Add member with a temporary ID
          </Typography>
        </div>
      </HeaderContainer>
      <MainContent className={addNewPatientStyles.pageContainer}>
        <Card sx={{ alignItems: "center", width: "70%" }}>
          <SectionContainer margin="80px">
            <Typography variant="h6" sx={{ paddingBottom: 3 }}>
              Member information
            </Typography>
            <PatientInfoFormSection
              patientInfo={formState.patientInfo}
              dispatch={dispatch}
              formError={formState.formError}
              attemptedSubmit={formSubmitting}
              formOptionalFields={tempPatientFormOptionalFields}
              setIsCareRecommendationPatient={setIsCareRecommendationPatient}
              isCareRecommendationPatient={isCareRecommendationPatient}
            />
          </SectionContainer>
          <SectionContainer>
            <Typography variant="h6" sx={{ paddingBottom: 3, paddingTop: 3 }}>
              Contact information
            </Typography>
            {addressValidationDisabled ? (
              <ContactInfoFormSection
                contactInfo={formState.contactInfo}
                dispatch={dispatch}
                formOptionalFields={tempPatientFormOptionalFields}
              />
            ) : (
              <ContactAddressFormSection dispatch={dispatch} formOptionalFields={tempPatientFormOptionalFields} />
            )}
          </SectionContainer>
          <SectionContainer>
            <Typography variant="h6" sx={{ paddingBottom: 3, paddingTop: 3 }}>
              Temporary member ID
            </Typography>
            <OptionsContainer>
              <Grid container item xs={12}>
                <TextField label={tempMemberId} disabled name="tempid autofilled" data-testid="temp-id" />
                <div style={{ width: 10 }} />
                <CopyToClipboard
                  text={tempMemberId}
                  copyIcon={copied ? <>Copied</> : <>Copy ID</>}
                  title="Copy ID"
                  onClick={() => setCopied(!copied)}
                />
              </Grid>
            </OptionsContainer>
          </SectionContainer>
        </Card>
        <Card sx={{ marginTop: 3, width: "70%" }}>
          <SectionContainer>
            <Grid item xs={8}>
              <Typography variant="h6" sx={{ paddingBottom: 3 }}>
                Coverage details
              </Typography>
            </Grid>
            <CoveragesInfoFormSection
              coveragesInfo={formState.coveragesInfo}
              formError={formState.formError}
              attemptedSubmit={formSubmitting}
              dispatch={dispatch}
              formOptionalFields={tempPatientFormOptionalFields}
            />
          </SectionContainer>
        </Card>
      </MainContent>
      <AppBar data-testid="action-buttons" className={addNewPatientStyles.footer} component="footer">
        <ButtonsContainer>
          <Grid item>
            <PrimaryButton
              onClick={handleSubmit}
              fullWidth
              loading={formSubmitting && isValidForm}
              disabled={temporaryMemberAuthsDisabled || formSubmitting || !isValidForm}
            >
              Add member
            </PrimaryButton>
          </Grid>
          <Grid item>
            <SecondaryButton warning onClick={handleCancel} fullWidth>
              Cancel
            </SecondaryButton>
          </Grid>
        </ButtonsContainer>
      </AppBar>
    </div>
  );
};
