import { useCallback, useState } from "react";
import isSameDay from "date-fns/isSameDay";

import { parseDateFromISOStringWithoutFallback, useEffectDeepEquality, useFeature } from "@coherehealth/common";
import { PatientStayDate } from "@coherehealth/core-platform-api";
import { PatientStayDateRange } from "common/SharedServiceRequestFormComponents";
import { validatePatientStayRange } from "util/authorization";
import { dateRangesOverlap } from "./PatientStays";
import { PatientStaysErrorState } from "./usePatientStayDateOnCRR";

export interface PatientStayValidationProps {
  admissionDate?: string;
  patientStayDateRanges: PatientStayDateRange[];
  checkEmptyRequestedLoc?: boolean;
  previouslyDecisionedDays?: PatientStayDate[];
  version: "RequestedStay" | "DecisionStay";
}

interface PatientStayValidation {
  isStayDatesValid: () => boolean;
  patientStaysErrorStates?: PatientStaysErrorState[];
  autoUpdateErrorState: boolean;
}
export function usePatientStayValidation({
  previouslyDecisionedDays: unstablePreviouslyDecisionedDays,
  admissionDate: admissionDateStr,
  checkEmptyRequestedLoc,
  patientStayDateRanges,
  version,
}: PatientStayValidationProps): PatientStayValidation {
  const [patientStaysErrorStates, setPatientStaysErrorStates] = useState<PatientStaysErrorState[]>();
  const expandedReviewStayDatesEditsEnabled = useFeature("expandedReviewStayDatesEdits");

  const [autoUpdateErrorState, setAutoUpdateErrorState] = useState<boolean>(false);

  // We need a stable previouslyDecisionedDays otherwise we'll enter infinite render loop hell
  const [previouslyDecisionedDays, setPreviouslyDecisionedDays] = useState<PatientStayDate[] | undefined>(
    unstablePreviouslyDecisionedDays
  );
  useEffectDeepEquality(() => {
    // The previously decisioned days won't change during this components lifecycle, but it may be null until some API responses come back.
    if (!previouslyDecisionedDays && !!unstablePreviouslyDecisionedDays) {
      setPreviouslyDecisionedDays(unstablePreviouslyDecisionedDays);
    }
  }, [previouslyDecisionedDays, unstablePreviouslyDecisionedDays]);

  const getPatientStaysErrorStates = useCallback(
    (
      patientStayDateRanges: PatientStayDateRange[],
      _previouslyDecisionedDays?: PatientStayDate[]
    ): PatientStaysErrorState[] => {
      const rowDatesOverlap = dateRangesOverlap(patientStayDateRanges);
      const rowDatesGap = validatePatientStayRange(patientStayDateRanges, {
        patientStayDates: _previouslyDecisionedDays,
      });

      const admissionDate = parseDateFromISOStringWithoutFallback(admissionDateStr);

      return patientStayDateRanges.map((patientStayDateRange, index) => {
        const rangeStartDate = patientStayDateRange.rangeStartDate;
        const previousRangeEndDate = index > 0 ? patientStayDateRanges[index - 1].rangeEndDate : null;

        // verify stay dates are ok w.r.t. the admission date
        let admissionDateErrorForInitial = false;
        let admissionDateErrorForContinuation = false;
        if (previouslyDecisionedDays?.length) {
          // this is a continuation
          admissionDateErrorForContinuation = !!(admissionDate && rangeStartDate && rangeStartDate < admissionDate);
        } else if (index === 0) {
          // this is an initial, and the first range in the initial must be equal to the admissionDate
          admissionDateErrorForInitial = !!(
            admissionDate &&
            rangeStartDate &&
            !isSameDay(admissionDate, rangeStartDate)
          );
        }
        const errorState: PatientStaysErrorState = {
          dateEmptyError: !patientStayDateRange.rangeStartDate || !patientStayDateRange.rangeEndDate,
          nextToDatePriorToPrevBackDate:
            rangeStartDate && previousRangeEndDate ? rangeStartDate.getTime() < previousRangeEndDate.getTime() : false,
          datesOverlap: rowDatesOverlap[index],
          datesGap: rowDatesGap[index].gapRangeError || false,
          admissionDateErrorForInitial,
          admissionDateErrorForContinuation,
          fromDateError:
            !rowDatesGap[index].fromDateValid ||
            admissionDateErrorForInitial ||
            admissionDateErrorForContinuation ||
            false,
          toDateError: !rowDatesGap[index].toDateValid || false,
          requestedLocEmptyError: !patientStayDateRange.requestedLevelOfCareCode && checkEmptyRequestedLoc,
        };

        if (version === "DecisionStay") {
          return {
            ...errorState,
            decisionEmptyError: !patientStayDateRange.reviewStatus,
            approvedLocEmptyError:
              !!patientStayDateRange.requestedLevelOfCareCode &&
              patientStayDateRange.reviewStatus === "APPROVED" &&
              !patientStayDateRange.approvedLevelOfCareCode,
          };
        } else {
          return errorState;
        }
      });
    },
    [admissionDateStr, checkEmptyRequestedLoc, previouslyDecisionedDays?.length, version]
  );

  const isStayDatesValid = useCallback((): boolean => {
    const errors: PatientStaysErrorState[] = getPatientStaysErrorStates(
      patientStayDateRanges,
      previouslyDecisionedDays
    );
    setPatientStaysErrorStates(errors);
    const isPatientStaysErrorExist = errors.length
      ? errors.some((error) => {
          return !!Object.entries(error).find(([key, value]) => {
            if (expandedReviewStayDatesEditsEnabled && key === "nextToDatePriorToPrevBackDate") {
              // this is validated elsewhere w/ this feature
              return false;
            }
            return !!value;
          });
        })
      : false;
    setAutoUpdateErrorState(isPatientStaysErrorExist);
    //if no patient stay error is there the isStayDatesValid should be true
    return !isPatientStaysErrorExist;
  }, [
    expandedReviewStayDatesEditsEnabled,
    getPatientStaysErrorStates,
    patientStayDateRanges,
    previouslyDecisionedDays,
  ]);

  useEffectDeepEquality(() => {
    isStayDatesValid();
  }, [patientStayDateRanges]);

  return {
    patientStaysErrorStates,
    isStayDatesValid,
    autoUpdateErrorState,
  };
}
