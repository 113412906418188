import React from "react";
import NoResultsImage from "./NoResultsImage";
import { CONTACT_PHONE_US_FORMAT } from "contact";

export default function NoResults() {
  return (
    <div className="UnauthorizedCheckStatusResultCard-card">
      <NoResultsImage aria-label="No results" />
      <div className="UnauthorizedCheckStatusResultCard-textcontainer">
        <h1 className="UnauthorizedCheckStatusResultCard-title">No results found</h1>
        <p className="UnauthorizedCheckStatusResultCard-text-detail">
          Check your tracking number or try searching for a different patient.
          <br />
          Still need help? Contact us at{" "}
          <a className="UnauthorizedCheckStatusResultCard-phone-contact" href={`tel:${CONTACT_PHONE_US_FORMAT}`}>
            {CONTACT_PHONE_US_FORMAT}
          </a>
        </p>
      </div>
    </div>
  );
}
