import { Body1, PrimaryButton, themeLight } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled, Grid as CoreGrid } from "@material-ui/core";
import { GridProps, Grid } from "@mui/material";
import { ComponentProps } from "react";

export const usePatientManagementStyles = makeStyles((theme) => ({
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    overflowY: "scroll",
    overflowX: "hidden",
    paddingTop: 18,
    paddingBottom: 30,
    height: "100%",
  },
  searchInputCard: {
    width: "30%",
    marginTop: 140,
    minWidth: 544,
  },
  searchButton: {
    padding: "16px 56px",
    margin: "auto",
  },
  resultsWrapper: {
    width: "30%",
    minWidth: 544,
    marginTop: 32,
  },
}));

export const useEditPatientCoveragesStyles = makeStyles((theme) => ({
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    overflowY: "scroll",
    overflowX: "hidden",
    paddingTop: 150,
    paddingBottom: 30,
    height: "100%",
  },
  formInputCard: {
    width: "70%",
    height: "100%",
  },
  readOnlyInputStyles: {
    cursor: "none",
    pointerEvents: "none",
  },
  footer: {
    top: "auto",
    bottom: 0,
    height: "80px",
    backgroundColor: "white",
    position: "fixed",
    width: "100%",
    borderTop: `1px solid ${themeLight.palette.divider}`,
  },
  readOnly: {
    border: 0,
    "& fieldset": { border: "none" },
    "& label": {
      marginLeft: "0px",
      paddingBottom: "0px",
      paddingTop: "10px",
      pointerEvents: "none",
    },
    "& input": { paddingTop: "20px" },
  },
  snackbar: {
    marginBottom: 80,
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const HeaderTextContainer = styled("div")(({ theme }) => ({
  marginTop: 30,
  justifySelf: "center",
  width: "100%",
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
export const WideButton = styled(PrimaryButton)({
  width: "12%",
  marginTop: "16px",
  alignSelf: "center",
  height: "48px",
}) as typeof PrimaryButton;

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const FormContainer = styled(CoreGrid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  overflowY: "scroll",
  overflowX: "hidden",
  paddingTop: 115,
  paddingBottom: 200,

  height: "100%",
}));

export const SearchInputContainer = (props: GridProps) => (
  <Grid item xs={12}>
    {props.children}
  </Grid>
);
export const FormFieldContainer = (props: GridProps) => (
  <Grid item width="33%">
    {props.children}
  </Grid>
);
export const Row = (props: ComponentProps<typeof Grid>) => <Grid style={{ display: "flex" }} item xs={12} {...props} />;
export const FooterRow = (props: ComponentProps<typeof Grid>) => (
  <Grid style={{ textAlign: "right" }} marginRight={12} item xs={12} {...props} />
);
// eslint-disable-next-line cohere-react/no-mui-styled-import
export const PatientLookupSubHeader = styled(Body1)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: theme.spacing(1, 0),
}));
