import { colorsLight } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Box, makeStyles, styled } from "@material-ui/core";

export const useNotesCardStyles = makeStyles((theme) => ({
  header: {
    paddingRight: "2%",
    paddingBottom: "1%",
  },
  noteInfo: {
    fontFamily: "Gilroy-Medium",
    color: colorsLight.font.secondary,
    paddingBottom: "2px",
    paddingLeft: "2%",
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const NotesContainer = styled(Box)(({ theme }) => ({
  "& p": {
    marginTop: "4px",
  },
  color: colorsLight.font.main,
}));
