import { useEffect, useState } from "react";
import { oauth2 as SMART } from "fhirclient";
import { Helmet, HelmetProvider } from "react-helmet-async";
import routes from "routes";
import ErrorPage from "components/ErrorPage";
import SmartOnFhirLoadingPage from "./SmartOnFhirLoadingPage";
import { error as logError } from "logger";

export default function SmartOnFhirLaunchPage() {
  const [failedToAuthorize, setFailedToAuthorize] = useState(false);
  const clientId = process.env.REACT_APP_SMART_ON_FHIR_APP_EPIC_CLIENT_ID;

  // TODO: Remove this once Epic platform is about to be integrated. This is just for dev testing purposes.
  const TEMP_COHERE_ID_OVERRIDE = new URLSearchParams(window.location.search).get("cohereId");
  useEffect(() => {
    SMART.authorize({
      clientId,
      scope:
        "user/*.cruds patient/*.rs openid fhirUser fhirContext profile launch launch/*.r launch/patient online_access",
      // redirectUri: `${routes.SMART_ON_FHIR_LANDING}`, // change redirectUri to preview branch url (e.g., `${routes.SMART_ON_FHIR_LANDING}?preview=7421`) for testing with Epic Hyperspace simulator
      redirectUri: `${routes.SMART_ON_FHIR_LANDING}${
        TEMP_COHERE_ID_OVERRIDE ? `?cohereId=${TEMP_COHERE_ID_OVERRIDE}` : ""
      }`, // change redirectUri to preview branch url (e.g., `${routes.SMART_ON_FHIR_LANDING}?preview=7421`) for testing with Epic Hyperspace simulator
    }).catch((error) => {
      logError(error);
      setFailedToAuthorize(true);
    });
  }, [TEMP_COHERE_ID_OVERRIDE, clientId]);
  // }, [clientId]);

  return failedToAuthorize ? (
    <>
      <ErrorPage />
    </>
  ) : (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Smart on Fire Launch</title>
        </Helmet>
        <SmartOnFhirLoadingPage />
      </HelmetProvider>
    </>
  );
}
