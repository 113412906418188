import { Grid, Divider, GridSpacing, makeStyles } from "@material-ui/core";
import React from "react";

interface StyleOverrideProps {
  widthOverrides?: {
    headerWidth?: number;
    subtextWidth?: number;
  };
}

export interface FullScreenDisplayProps extends StyleOverrideProps {
  headerAndProgressBar?: JSX.Element;
  visualComponent: JSX.Element;
  header: JSX.Element;
  subtext?: JSX.Element;
  subHeader?: JSX.Element;
  secondarySubtext?: JSX.Element;
  hasDivider?: boolean;
  gridSpacing?: GridSpacing;
  gridContainerStyle?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(6),
  },
  header: {
    marginTop: theme.spacing(2),
    maxWidth: ({ widthOverrides }: StyleOverrideProps) => widthOverrides?.headerWidth || 690,
    margin: "auto",
  },
  subtext: {
    maxWidth: ({ widthOverrides }: StyleOverrideProps) => widthOverrides?.subtextWidth || 690,
    color: theme.palette.text.secondary,
    margin: "auto",
  },
  subHeader: {
    maxWidth: ({ widthOverrides }: StyleOverrideProps) => widthOverrides?.headerWidth || 690,
    margin: "auto",
  },
  visualComponent: {
    marginTop: theme.spacing(5.75),
    padding: theme.spacing(1),
  },
  gridItem: {
    textAlign: "center",
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export default function FullScreenDisplay({
  headerAndProgressBar,
  visualComponent,
  header,
  subtext,
  subHeader,
  secondarySubtext,
  widthOverrides,
  hasDivider,
  gridSpacing = 3,
  gridContainerStyle,
}: FullScreenDisplayProps) {
  const classes = useStyles({ widthOverrides });
  return (
    <div className={classes.container}>
      {headerAndProgressBar && <>{headerAndProgressBar}</>}
      <Grid container spacing={gridSpacing} className={gridContainerStyle ? gridContainerStyle : ""}>
        <Grid item xs={12} className={classes.gridItem}>
          <div className={classes.visualComponent}>{visualComponent}</div>
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <div className={classes.header}>{header}</div>
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <div className={classes.subtext}>{subtext}</div>
        </Grid>
        {hasDivider && (
          <Grid item xs={12} className={classes.gridItem}>
            <Divider className={classes.divider} />
          </Grid>
        )}
        <Grid item xs={12} className={classes.gridItem}>
          <div className={classes.subHeader}>{subHeader}</div>
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <div className={classes.subtext}>{secondarySubtext}</div>
        </Grid>
      </Grid>
    </div>
  );
}
