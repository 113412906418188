import React, { ForwardedRef, ForwardRefExoticComponent, RefAttributes } from "react";
import MuiLoadingButton, { LoadingButtonProps as MuiButtonProps } from "@mui/lab/LoadingButton";

export interface ButtonProps extends Omit<MuiButtonProps<React.ElementType>, "variant"> {
  loading?: boolean;
  priority: "primary" | "secondary" | "tertiary";
}

/** For Storybook */
export function ButtonWithProps(props: ButtonProps) {}

const priorityToMuiVariantMap: Record<ButtonProps["priority"], MuiButtonProps["variant"]> = {
  primary: "contained",
  secondary: "outlined",
  tertiary: "text",
};

const Button: ForwardRefExoticComponent<ButtonProps> & RefAttributes<HTMLButtonElement> = React.forwardRef(
  (props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    // pull out external props that we hardcode, only pass through otherProps
    const { children, priority, ...otherProps } = props;

    return (
      <MuiLoadingButton
        ref={ref}
        variant={priorityToMuiVariantMap[priority]}
        disableRipple={priority === "primary"}
        {...otherProps}
      >
        {children}
      </MuiLoadingButton>
    );
  }
);

export default Button;

export const PrimaryButton: ForwardRefExoticComponent<Omit<ButtonProps, "priority">> &
  RefAttributes<HTMLButtonElement> = React.forwardRef<HTMLButtonElement, Omit<ButtonProps, "priority">>(
  (props, ref) => {
    return <Button priority="primary" {...props} ref={ref} />;
  }
);

export const SecondaryButton: ForwardRefExoticComponent<Omit<ButtonProps, "priority">> &
  RefAttributes<HTMLButtonElement> = React.forwardRef<HTMLButtonElement, Omit<ButtonProps, "priority">>(
  (props, ref) => {
    return <Button priority="secondary" {...props} ref={ref} />;
  }
);

export const TertiaryButton: ForwardRefExoticComponent<Omit<ButtonProps, "priority">> &
  RefAttributes<HTMLButtonElement> = React.forwardRef<HTMLButtonElement, Omit<ButtonProps, "priority">>(
  (props, ref) => {
    return <Button priority="tertiary" {...props} ref={ref} />;
  }
);
