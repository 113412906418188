import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import MUICard, { CardProps } from "@material-ui/core/Card";
import { colorsLight, colorsDark } from "../../themes/colors";

interface UseStylesProps {
  setPosition?: boolean;
  noBorder?: boolean;
  noBoxShadow?: boolean;
}

export const useStylesCard = makeStyles((theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  const colors = isDarkTheme ? colorsDark : colorsLight;
  return {
    root: {
      position: ({ setPosition }: UseStylesProps) => (setPosition ? "relative" : "unset"),
      boxShadow: (props: UseStylesProps) =>
        props.noBoxShadow
          ? "none"
          : isDarkTheme
          ? "0px 5px 105px rgba(0, 0, 0, 0.05)"
          : "0px 2px 2px rgba(207, 226, 231, 0.8)",
      border: (props: UseStylesProps) =>
        props.noBorder ? "none" : isDarkTheme ? "1px solid #313C4E" : "1px solid transparent",
      padding: theme.spacing(2),
    },
    withHover: {
      "&:hover": {
        borderColor: colors.gray.divider,
        boxShadow: isDarkTheme ? "0px 5px 105px rgba(0, 0, 0, 0.05)" : "0px 3px 5px rgba(207, 226, 231, 0.3)",
      },
    },
  };
});

export interface CohereCardProps {
  /** Enable styling on hover. */
  withHoverStyles?: boolean;
  setPosition?: boolean;
  noBorder?: boolean;
  noBoxShadow?: boolean;
}
// Temporary export for storybook documentation
export function CardForProps(props: CohereCardProps) {}

const Card = React.forwardRef((props: CohereCardProps & Omit<CardProps, "css">, ref) => {
  const {
    withHoverStyles,
    setPosition = true,
    noBorder = false,
    noBoxShadow = false,
    className,
    children,
    ...otherProps
  } = props;
  const { withHover, ...classes } = useStylesCard({
    setPosition: setPosition,
    noBorder: noBorder,
    noBoxShadow: noBoxShadow,
  });
  const extraClasses = withHoverStyles ? withHover : "";
  return (
    <MUICard ref={ref} className={`${extraClasses} ${className}`} classes={classes} {...otherProps}>
      {children}
    </MUICard>
  );
});
Card.displayName = "Card";

export default Card;
