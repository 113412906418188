import { H2, H5, H6 } from "@coherehealth/common";
import { AuthorizationResponse, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { Grid, CircularProgress } from "@material-ui/core";
import { NotificationSummary } from "components/Notification/NotificationSummary";
import { useGetNotifications } from "components/NotificationViewer/hooks/useGetNotifications";
import { isLettersExpansionNotification } from "components/NotificationViewer/util/notificationUtil";
import React, { useEffect } from "react";

export interface NotificationsSummaryProps {
  authorization: AuthorizationResponse;
  serviceRequest: ServiceRequestResponse;
  serviceRequestsOnAuth?: ServiceRequestResponse[];
}

export function NotificationsSummary({
  authorization,
  serviceRequest,
  serviceRequestsOnAuth,
}: NotificationsSummaryProps) {
  const { fetchNotifications, notifications, notificationsLoading } = useGetNotifications({
    serviceRequest,
    authorization,
    serviceRequestsOnAuth,
  });
  useEffect(() => {
    if (authorization && serviceRequest && serviceRequestsOnAuth) {
      fetchNotifications();
    }
  }, [authorization, fetchNotifications, serviceRequest, serviceRequestsOnAuth]);
  const supportedNotifications = notifications.filter((notification) => isLettersExpansionNotification(notification));
  if (notificationsLoading) {
    return (
      <Grid container spacing={3} direction="column" alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <H2 data-public>Notification data loading...</H2>
        </Grid>
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <H5 data-public>{"Notifications (" + supportedNotifications.length.toString() + ")"}</H5>
      </Grid>
      <Grid item xs={12}>
        <>
          {supportedNotifications.length === 0 ? (
            <Grid item xs={12} style={{ padding: 0 }}>
              <H6 data-public>No notifications</H6>
            </Grid>
          ) : (
            <Grid item xs={12}>
              {supportedNotifications
                .slice()
                .sort(
                  (a, b) => new Date(b.data.dateCreated || "").valueOf() - new Date(a.data.dateCreated || "").valueOf()
                )
                .map((notification, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <NotificationSummary
                        notification={notification}
                        authorization={authorization}
                        serviceRequest={serviceRequest}
                        serviceRequestsOnAuth={serviceRequestsOnAuth}
                      />
                    </React.Fragment>
                  );
                })}
            </Grid>
          )}
        </>
      </Grid>
    </Grid>
  );
}
