import { createContext } from "react";
import {
  SuggestedRequestor,
  SuggestedPriorAuthRequirements,
  SuggestedFormContent,
  SuggestedMember,
} from "../../util/suggestionUtils";

interface SuggestionContextType {
  suggestedRequestor?: SuggestedRequestor;
  suggestedPriorAuthRequirements?: SuggestedPriorAuthRequirements;
  suggestedFormContent?: SuggestedFormContent;
  suggestedMember?: SuggestedMember;
}

export const SuggestionContext = createContext<SuggestionContextType>({});
