import { H5 } from "@coherehealth/design-system";
import { CircularProgress } from "@material-ui/core";
import { Grid } from "@mui/material";
import { StyledBody1 } from "../ServiceRequestInformation";
import { NotesProps } from "./notesCardTypes";
import { useFeature } from "@coherehealth/common";

export const NotesCardHeader = ({ notes, notesLoading, existingClaimsPaymentNoteData }: NotesProps) => {
  const claimsPaymentNoteLength =
    existingClaimsPaymentNoteData?.length !== 0 ? existingClaimsPaymentNoteData?.length : 0;
  const adminNotesLength = notes?.filter((note) => note.noteType === "ADMINISTRATIVE").length || 0;
  const otherNotesLength =
    notes?.filter((note) => note.noteType !== "ADMINISTRATIVE" && note.noteType !== "CLAIMS_PAYMENT").length || 0;
  const logrocketUnmasking = useFeature("logrocketUnmasking");

  return (
    <Grid container data-testid="notes-section-header">
      <Grid item xs={12} style={{ marginBottom: 6 }}>
        <H5 data-public={!!logrocketUnmasking ? true : undefined}>Notes</H5>
      </Grid>
      <Grid item xs={12}>
        {notesLoading ? (
          <CircularProgress size={15} data-testid="notes-loading-header" />
        ) : (
          <Grid container item>
            <Grid item paddingRight={2} data-testid="admin-notes-section">
              <StyledBody1>{`${adminNotesLength} Administrative note${adminNotesLength === 1 ? "" : "s"}`}</StyledBody1>
            </Grid>
            <Grid item paddingRight={2} data-testid="other-notes-section">
              <StyledBody1>{`${otherNotesLength} Other note${otherNotesLength === 1 ? "" : "s"}`}</StyledBody1>
            </Grid>

            <Grid item paddingRight={2} data-testid="claims-payment-note-section">
              <StyledBody1>{`${claimsPaymentNoteLength} Claims payment note${
                claimsPaymentNoteLength === 1 ? "" : "s"
              }`}</StyledBody1>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
