import { H6 } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { CoverageCheck, DateCheck } from "util/patientUtils";
import { PriorAuthRequirements } from "components/AuthBuilder/common";
import { Dispatch, SetStateAction } from "react";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";

export type PalCheckFormSection =
  | "DiagnosisCodes"
  | "ProcedureCodes"
  | "ProcedureCodesWithCheckbox"
  | "StartDate"
  | "EncounterType"
  | "HomeHealth"
  | "RevenueCodes"
  | "NoPxService";

export interface PalCheckFormSectionProps {
  priorAuthRequirements: PriorAuthRequirements;
  setPriorAuthRequirements: (reqs: PriorAuthRequirements) => void;
  attemptedSubmit: boolean;
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  resetPalResults: Function;
  startDateCoverage?: CoverageCheck;
  facilityBased?: boolean;
  generalAuthSubmissionStyling?: boolean;
  hideHeader?: boolean;
  isOutPatientEncounterType?: boolean;
  formContent?: ServiceRequestFormContent;
  setFormContent?: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  minDateCheck?: DateCheck;
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const SectionHeader = styled(H6)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
