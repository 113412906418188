import React, { SVGProps } from "react";

export default function ApprovedServiceRequestImage(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="40" cy="40" r="40" fill="#ECF9F3" />
      <path d="M53.5896 23.3633H20.9883V61.3869H53.5896V23.3633Z" fill="#A1AAB4" />
      <path d="M45.9014 25.668H23.2949V59.0786H51.2697V31.0498L45.9014 25.668Z" fill="white" />
      <path
        d="M44.6728 18.654H40.3295C40.3295 16.7387 38.9672 15.1875 37.2812 15.1875C35.5951 15.1875 34.2328 16.7387 34.2328 18.654H29.8895L28.6621 26.3424H45.8867L44.6728 18.654Z"
        fill="#384358"
      />
      <path
        d="M31.8468 30.9684H29.5269V28.6484H27.9352V30.9684H25.6152V32.5601H27.9352V34.88H29.5269V32.5601H31.8468V30.9684Z"
        fill="#EA1D63"
      />
      <path d="M44.5388 37.457H30.0254V38.5496H44.5388V37.457Z" fill="#89D3DD" />
      <path d="M48.5853 54.9492H42.7988V56.0418H48.5853V54.9492Z" fill="#89D3DD" />
      <path d="M48.585 41.4609H25.9785V42.5535H48.585V41.4609Z" fill="#C2C2C2" />
      <path d="M48.585 44.7383H25.9785V45.8308H48.585V44.7383Z" fill="#C2C2C2" />
      <path d="M48.585 48.0195H25.9785V49.1121H48.585V48.0195Z" fill="#C2C2C2" />
      <path d="M36.0813 51.2969H25.9785V52.3894H36.0813V51.2969Z" fill="#C2C2C2" />
      <path d="M45.9004 31.0498H51.2688L45.9004 25.668V31.0498Z" fill="#E6E9EE" />
      <circle cx="56.2361" cy="26.9939" r="9.69314" fill="white" />
      <path
        d="M56.2997 17.0508C50.7749 17.0508 46.291 21.5347 46.291 27.0595C46.291 32.5843 50.7749 37.0682 56.2997 37.0682C61.8246 37.0682 66.3085 32.5843 66.3085 27.0595C66.3085 21.5347 61.8246 17.0508 56.2997 17.0508ZM54.298 32.0639L49.2936 27.0595L50.7049 25.6483L54.298 29.2314L61.8946 21.6348L63.3059 23.056L54.298 32.0639Z"
        fill="#04B86C"
      />
    </svg>
  );
}
