import React from "react";
import { H1, H6 } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, useTheme, makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Container, Dialog, DialogProps } from "@material-ui/core";

interface Props extends DialogProps {
  open: boolean;
  headingText: string;
  subHeaderText?: string | React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function LoadingDialog({ open, headingText, subHeaderText }: Props) {
  const { spacing } = useTheme();
  const classes = useStyles();

  return (
    <Dialog fullScreen classes={{ paperFullScreen: classes.modal }} open={open}>
      <LoadingContainer>
        <CircularProgress size={128} />
        <div style={{ paddingTop: spacing(5), textAlign: "center" }}>
          <HeroHeading>{headingText}</HeroHeading>
          <HeroText>{subHeaderText}</HeroText>
        </div>
      </LoadingContainer>
    </Dialog>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const LoadingContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const HeroHeading = styled(H1)(() => ({
  color: "#384358",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const HeroText = styled(H6)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "16px",
  lineHeight: "20px",
  paddingTop: theme.spacing(2),
}));
