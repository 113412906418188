import React, { PropsWithChildren } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { isIE } from "../../util/envUtils";

interface Props {
  /** The width of the side rail */
  sideRailWidth: number;

  /** Space between side rail and main content, as a theme.spacing interval i.e. theme.spacing(columnSpacing) */
  columnSpacingRight?: number;

  /** Space between side rail and main content, as a theme.spacing interval i.e. theme.spacing(columnSpacing) */
  columnSpacingLeft?: number;

  /** Since the side rail uses position fixed, it will not be scrollable if its content extends past the
   * bottom of the viewport. In order to get around this we need to calculate the maximum height that the
   * side rail content can be to still fit on the page. Which is the viewport-height minus the height of other
   * elements. */
  verticalHeightAroundSideRail?: number;

  /** If the vertical height calculation should take into account header spacing */
  hasHeader?: boolean;

  /** Ignores the protection of overflow styling */
  ignoreOverflowStyles?: boolean;

  /** Aligns the top of the left rail with the main content */
  alignHeight?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  sideRailWrapper: {
    // This wrapper carves out space in the page for the fixed SideRail
    marginRight: ({ columnSpacingRight = 0 }: Props) => theme.spacing(columnSpacingRight),
    marginLeft: ({ columnSpacingLeft = 0 }: Props) => theme.spacing(columnSpacingLeft),
    minWidth: ({ sideRailWidth }) => sideRailWidth,
    height: ({ alignHeight }) => (alignHeight ? "fit-content" : "auto"),
  },
  sideRail: {
    position: isIE ? "fixed" : "sticky",
    width: ({ sideRailWidth }) => sideRailWidth,
    overflowY: "auto",
    scrollbarWidth: "none",
    "&:hover": {
      overflowY: "auto",
    },
  },
  body: {},
}));

/**
 * A page layout that takes a "Side rail" component which gets fixed to the side
 * while the right side of the page can scroll as normal
 */
export default function ScrollSideRail({
  children,
  verticalHeightAroundSideRail = 0,
  hasHeader = false,
  ...props
}: PropsWithChildren<Props>) {
  const classes = useStyles(props);
  const { spacing } = useTheme();

  // Sum of the height of other elements besides the side rail
  const sideRailHeightSubtract = verticalHeightAroundSideRail + (hasHeader ? spacing(0) : 0);

  return (
    <div className={classes.sideRailWrapper}>
      {/* calculate a height for the side rail based on the viewport, needed so side rail will scroll if content overflows */}
      <div
        className={classes.sideRail}
        style={{
          top: sideRailHeightSubtract,
          maxHeight: `calc(100vh - ${sideRailHeightSubtract}px)`,
          overflowX: props.ignoreOverflowStyles ? "inherit" : "hidden",
        }}
      >
        {children}
      </div>
    </div>
  );
}
