import { useGetSingleServiceClinicalAssessment } from "@coherehealth/core-platform-api";
import { usePrevious } from "@react-hookz/web";
import { useEffect, useRef, useState } from "react";

interface Props {
  serviceRequestId?: string;
  timeout?: number;
  pollingInterval?: number;
  lazy?: boolean;
}

interface UsePollForClinicalAssessmentReturn extends ReturnType<typeof useGetSingleServiceClinicalAssessment> {
  startPollForClinicalAssessment: (options?: RefetchOptions) => void;
  hasTimedOut: boolean;
}

const timeout = 5 * 60 * 1000; // 5 minutes
const pollingInterval = 1000; // 1 second

type RefetchOptions = Parameters<ReturnType<typeof useGetSingleServiceClinicalAssessment>["refetch"]>[0];

/**
 * A thin wrapper around useGetSingleServiceClinicalAssessment that allows for polling behavior
 *
 * todo we should make this generic for any useGet hook
 */
export default function usePollForClinicalAssessment({
  serviceRequestId,
  lazy = false,
}: Props): UsePollForClinicalAssessmentReturn {
  const { data, loading, refetch, error, response, cancel, ...other } = useGetSingleServiceClinicalAssessment({
    id: serviceRequestId || "",
    lazy: true,
  });

  const previousServiceRequestId = usePrevious(serviceRequestId);
  const [pollingActive, setPollingActive] = useState(false);
  const [hasTimedOut, setHasTimedOut] = useState(false);
  const pollingTimeoutId = useRef<ReturnType<typeof setTimeout>>();
  const waitForNextPollTimeoutId = useRef<ReturnType<typeof setTimeout>>();

  // const [queueRequest, setQueueRequest] = useState(true);

  function stopPolling() {
    setPollingActive(false);
    if (pollingTimeoutId.current) {
      clearTimeout(pollingTimeoutId.current);
      pollingTimeoutId.current = undefined;
    }
    if (waitForNextPollTimeoutId.current) {
      clearTimeout(waitForNextPollTimeoutId.current);
      waitForNextPollTimeoutId.current = undefined;
    }
  }

  function poll(options?: RefetchOptions) {
    refetch(options).then((clinicalAssessment) => {
      if (!!clinicalAssessment) {
        // Found clinical assessment, stop polling
        stopPolling();
      } else if (!hasTimedOut) {
        // Did not find clinical assessment, poll again after waiting some interval
        waitForNextPollTimeoutId.current = setTimeout(() => poll(options), pollingInterval);
      }
    });
  }

  function startPolling(options?: RefetchOptions) {
    if (!pollingTimeoutId.current) {
      poll(options);
      setPollingActive(true);
      pollingTimeoutId.current = setTimeout(() => {
        cancel();
        stopPolling();
        setHasTimedOut(true);
      }, timeout);
    }
  }

  function restartPolling(options?: RefetchOptions) {
    stopPolling();
    startPolling(options);
  }

  useEffect(() => {
    // cleanup timeouts on unmount
    return () => {
      if (pollingTimeoutId.current) {
        clearTimeout(pollingTimeoutId.current);
      }
      if (waitForNextPollTimeoutId.current) {
        clearTimeout(waitForNextPollTimeoutId.current);
      }
    };
  }, []);

  useEffect(() => {
    // stop polling if there is a request error
    if (error) {
      stopPolling();
    }
  }, [error]);

  useEffect(() => {
    // restart polling if the service request id changes and is non-empty
    if (serviceRequestId !== previousServiceRequestId && !!serviceRequestId) {
      restartPolling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceRequestId]);

  useEffect(() => {
    // kick off polling on mount unless if lazy == true
    if (!lazy) {
      startPolling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    loading: loading || pollingActive,
    refetch,
    error,
    hasTimedOut,
    response,
    cancel,
    startPollForClinicalAssessment: (options) => {
      restartPolling(options);
    },
    ...other,
  };
}
