import React, { useContext, useEffect, useState } from "react";

import { useAuthorized } from "authorization";
import { UserContext } from "UserContext";

import ManageSingleProviderOrg from "./OrganizationDetails/ManageSingleProviderOrg";
import { useGetUserOrganization } from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import { NotFoundPage } from "@coherehealth/common";

export default function ViewMyOrganizationPage() {
  const [userId, setUserId] = useState<string>();
  const [isBOUser, setIsBOUser] = useState<boolean>();
  const canBOUserViewPage = useAuthorized("VIEW_MY_ORGANIZATION");
  const { getUser } = useContext(UserContext);

  const {
    data: userOrgInfo,
    loading: loadingUserOrgInfo,
    error: getUserOrganizationError,
    refetch: refetchUserOrgInfo,
  } = useGetUserOrganization({});

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (getUserOrganizationError) {
      enqueueSnackbar(
        `There was an error getting information about your organization: ${getUserOrganizationError.message}`,
        {
          variant: "error",
        }
      );
    }
  }, [enqueueSnackbar, getUserOrganizationError]);

  useEffect(() => {
    getUser()?.then((user) => {
      setUserId(user.sub);
      setIsBOUser(user.roles?.includes("backOffice") && !user.roles?.includes("backOfficeAdmin"));
    });
  }, [getUser, setUserId]);

  if ((!canBOUserViewPage || !userOrgInfo) && !loadingUserOrgInfo) {
    return <NotFoundPage />;
  }

  return (
    <ManageSingleProviderOrg
      userId={userId || ""}
      org={userOrgInfo}
      loading={loadingUserOrgInfo}
      refetchOrg={refetchUserOrgInfo}
      internalUser={false}
      isReadOnlyView={canBOUserViewPage && isBOUser}
    />
  );
}
