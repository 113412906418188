import React from "react";
import { Divider, Box } from "@material-ui/core";
import { SecondaryButton, queueMgmtBaseUrl } from "@coherehealth/common";
import FaxMetaDataDisplay from "./FaxMetaDataDisplay";
import { CommonFaxSidebarViewProps } from "./common";
import { useQueueSidebarStyles, ControlPanelContainer } from "./QueueSupervisorSidebar";

export default function QueueAgentSidebar({ fileData, url, serviceCase }: CommonFaxSidebarViewProps) {
  const classes = useQueueSidebarStyles();

  return (
    <>
      <FaxMetaDataDisplay fileData={fileData} url={url} serviceCase={serviceCase} />
      <Divider className={classes.divider} />
      <ControlPanelContainer>
        <Box display="flex" alignItems="center" height="100%" mx="24px" justifyContent="space-between">
          <SecondaryButton className={classes.singleControlBtn} href={`${queueMgmtBaseUrl()}/dashboard`}>
            Back to queue
          </SecondaryButton>
        </Box>
      </ControlPanelContainer>
    </>
  );
}
