/*This modal redirects the user to save changes as an edit. 
Clicking save will land the user on patient summary with edits applied in read mode. */
import { InformativeModal } from "@coherehealth/common";
import { DialogProps } from "@material-ui/core/Dialog";
import ClipboardIllustrationWithPencil from "components/images/ClipboardIllustrationWithPencil";

interface EditModalProps extends DialogProps {
  open: boolean;
  primaryButtonActions: () => void;
  tertiaryButtonAction: () => void;
  primaryButtonLoading: boolean;
}

const EditModal = ({
  open,
  onClose,
  primaryButtonActions,
  tertiaryButtonAction,
  primaryButtonLoading,
}: EditModalProps) => {
  return (
    <InformativeModal
      open={open}
      onClose={onClose}
      icon={<ClipboardIllustrationWithPencil />}
      headerText="Edit your request to save these changes"
      subHeaderText="Since there are no additional days to review, you should edit your request instead of submitting a continuation."
      showDivider={false}
      primaryButtonText="Save changes"
      primaryButtonAction={primaryButtonActions}
      primaryButtonLoading={primaryButtonLoading}
      tertiaryButtonText="Go back"
      tertiaryButtonAction={tertiaryButtonAction}
    />
  );
};

export default EditModal;
