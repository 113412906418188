import { CloseOutlined } from "@mui/icons-material";
import { Box, IconButton, Popper, Card, Divider, ClickAwayListener, Zoom, CircularProgress } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { H4, H5, Body1 } from "@coherehealth/design-system";
import { Sanitized } from "@coherehealth/common";
import { Insight } from "./useInsightsPanel";

interface InsightsPanelProps {
  openInsightsPanel: boolean;
  insightsAnchor: HTMLElement | null;
  panelId: string | undefined;
  handleCloseInsightsPanel: (fromClickAway?: boolean) => void;
  hasInsights: boolean;
  insights: Insight[];
  numOfInsights: number;
  fetchingSrRules?: boolean;
}

const InsightsPanel = ({
  openInsightsPanel,
  insightsAnchor,
  panelId,
  handleCloseInsightsPanel,
  hasInsights,
  insights,
  numOfInsights,
  fetchingSrRules,
}: InsightsPanelProps) => {
  return (
    <Popper
      open={openInsightsPanel}
      anchorEl={insightsAnchor}
      id={panelId}
      placement="bottom-end"
      transition
      sx={{ zIndex: 1000 }}
    >
      {({ TransitionProps }) => (
        <Zoom {...TransitionProps} timeout={300} mountOnEnter unmountOnExit>
          <div>
            <ClickAwayListener onClickAway={() => handleCloseInsightsPanel(true)}>
              <Card
                sx={{
                  mt: "10px",
                  width: "600px",
                  padding: "24px 16px",
                  maxHeight: "80vh",
                  overflowY: "scroll",
                  border: (theme) => `1px solid ${alpha(theme.palette.primary.dark, 0.2)}`,
                }}
                elevation={4}
                data-testid="insights-panel"
              >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <H4>Insights</H4>
                  <IconButton sx={{ color: "font.secondary" }} onClick={() => handleCloseInsightsPanel()}>
                    <CloseOutlined />
                  </IconButton>
                </Box>
                <Box sx={{ my: 3 }}>
                  <Divider />
                </Box>
                {hasInsights ? (
                  insights.map((insight, idx) => (
                    <Box key={`${insight.message}_${idx}`} display="flex" flexDirection="column" mt={3}>
                      {insight.title && <H5 sx={{ mb: "10px" }}>{insight.title}</H5>}
                      {insight.message && (
                        <Body1 component="div" lineHeight={1.5}>
                          <Sanitized __html={insight.message} />
                        </Body1>
                      )}
                      {insight.messageElement}
                      {idx !== numOfInsights - 1 && <Divider sx={{ mt: 3 }} />}
                    </Box>
                  ))
                ) : (
                  <Box display="flex" justifyContent="center">
                    {fetchingSrRules ? <CircularProgress /> : <Body1>No available insights for this request</Body1>}
                  </Box>
                )}
              </Card>
            </ClickAwayListener>
          </div>
        </Zoom>
      )}
    </Popper>
  );
};

export { InsightsPanel };
