import { Dispatch, SetStateAction } from "react";
import { Theme, createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import { Body1, DateSelect } from "@coherehealth/common";
import { ServiceRequestFormContent } from "components/ServiceRequest";
import { DateSelectResponsiveWidth } from "common/SharedServiceRequestFormComponents";

interface OutPatientDateRangeSelectProps {
  formContent: ServiceRequestFormContent;
  minDuration?: false | 0 | Date | undefined;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  startDateCoverage: any;
  endDateCoverage: any;
  hasValidStartDate: boolean;
  startDateErrorMessage: string | undefined;
  isContinuation: boolean;
  expectedMaxStartDate: Date;
  attemptedSubmit: boolean;
  showExceededDurationLimitMessageErrorState: boolean | undefined;
  clearEndDate?: boolean;
  setClearEndDate?: (clearEndDate: boolean) => void;
  endDateCalculation: (startDate: Date) => Date | undefined;
  isStartDateInRange: boolean;
  setIsStartDateInRange: (date: boolean) => void;
  isEndDateInRange: boolean;
  setIsEndDateInRange: (date: boolean) => void;
  disabled?: boolean;
}

const MIN_START_DATE = new Date(2020, 0, 1);

const OutPatientDateRangeSelect: React.FC<OutPatientDateRangeSelectProps> = ({
  formContent,
  minDuration,
  setFormContent,
  startDateCoverage,
  endDateCoverage,
  hasValidStartDate,
  startDateErrorMessage,
  isContinuation,
  expectedMaxStartDate,
  attemptedSubmit,
  showExceededDurationLimitMessageErrorState,
  clearEndDate,
  setClearEndDate,
  endDateCalculation,
  isStartDateInRange,
  setIsStartDateInRange,
  isEndDateInRange,
  setIsEndDateInRange,
  disabled,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const startDateError =
    showExceededDurationLimitMessageErrorState ||
    (startDateCoverage ? !startDateCoverage?.inRange : attemptedSubmit && !formContent?.startDate);
  const endDateError =
    showExceededDurationLimitMessageErrorState ||
    (endDateCoverage ? !endDateCoverage?.inRange : attemptedSubmit && !formContent?.endDate);

  const startDateChangeValidator = () => {
    if (!startDateCoverage?.inRange) {
      setIsStartDateInRange(false);
    } else {
      setIsStartDateInRange(true);
    }
  };
  const endDateChangeValidator = () => {
    if (!endDateCoverage?.inRange) {
      setIsEndDateInRange(false);
    } else {
      setIsEndDateInRange(true);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item style={{ marginBottom: theme.spacing(1) }}>
        <DateSelect
          label="Start date"
          TextFieldProps={{ fullWidth: true }}
          value={formContent?.startDate}
          onDateChange={(newStartDate) => {
            const updatedEndDate = endDateCalculation(newStartDate);
            setFormContent((currentFormContent) => {
              return {
                ...currentFormContent,
                startDate: newStartDate,
                endDate: updatedEndDate,
              };
            });
            startDateChangeValidator();
          }}
          helperText={
            (!startDateCoverage?.inRange && startDateCoverage?.messageToDisplay) ||
            (!hasValidStartDate && startDateErrorMessage) ||
            (attemptedSubmit && !formContent?.startDate && "Required")
          }
          minDate={MIN_START_DATE}
          maxDate={isContinuation ? expectedMaxStartDate : formContent?.endDate}
          attemptedSubmit={attemptedSubmit || !isStartDateInRange || showExceededDurationLimitMessageErrorState}
          isContinuation={isContinuation}
          error={startDateError}
          inputProps={{
            width: DateSelectResponsiveWidth,
          }}
          disabled={disabled}
        />
      </Grid>
      <Grid item component={Box} display={{ xs: "none", sm: "block", md: "block" }}>
        <Body1 className={classes.cardsSpacing}>{"-"}</Body1>
      </Grid>
      <Grid item style={{ marginBottom: theme.spacing(1) }}>
        <DateSelect
          label="End date"
          error={endDateError}
          attemptedSubmit={attemptedSubmit || !isEndDateInRange || showExceededDurationLimitMessageErrorState}
          TextFieldProps={{ fullWidth: true }}
          value={clearEndDate ? null : formContent?.endDate || null}
          minDate={minDuration || formContent?.startDate}
          minDateMessage={
            minDuration && minDuration >= formContent?.startDate ? "" : "End date cannot be before start date"
          }
          onDateChange={(newEndDate) => {
            setFormContent((currentFormContent) => {
              return {
                ...currentFormContent,
                endDate: newEndDate,
              };
            });
            endDateChangeValidator();
            setClearEndDate?.(false);
          }}
          helperText={
            (!endDateCoverage?.inRange && endDateCoverage?.messageToDisplay) ||
            (attemptedSubmit && !formContent?.endDate && "Required")
          }
          inputProps={{
            width: DateSelectResponsiveWidth,
          }}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardsSpacing: {
      marginTop: theme.spacing(2.5),
    },
  })
);

export default OutPatientDateRangeSelect;
