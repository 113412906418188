import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import ChipInput from "common/ChipInput";
import listRemove from "util/listRemove";
import { IconButton, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useOnboardingCheckEmailCanAutoVerify } from "@coherehealth/core-platform-api";
import { Chip } from "@coherehealth/common";
import { useSnackbar } from "notistack";
import {
  isValidEmailDomain,
  emailDomainFormatter,
  isValidEmail,
  extractDomainFromEmail,
} from "../../util/providerUtils";

interface Props {
  domains: string[];
  setDomains: (domains: string[]) => void;
  invalidEmailDomainList: string[];
  setInvalidEmailDomainList: Dispatch<SetStateAction<string[]>>;
  label: string;
  required?: boolean;
  withIcon?: boolean;
}

const useStyles = makeStyles((theme) => ({
  searchIconButton: {
    position: "absolute",
    top: "20px",
    right: "12px",
  },
}));

export default function EmailDomainChipInput({
  domains,
  setDomains,
  invalidEmailDomainList,
  setInvalidEmailDomainList,
  label,
  required,
  withIcon,
}: Props) {
  // To display appropriate error message
  const [helperText, setHelperText] = useState<string>("");
  // To update the domain. It is used in query param to check generic email domain
  const [query, setQuery] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  // Trigger CheckEmailCanAutoVerify API on every query update to verify email domain
  const { refetch: vaildEmailDomain } = useOnboardingCheckEmailCanAutoVerify({
    queryParams: {
      email: query,
    },
    lazy: true,
  });

  useEffect(() => {
    return () => {
      // Perform cleanup operation on component unmount
      setHelperText("");
      setInvalidEmailDomainList([]);
      setQuery("");
    };
  }, [setInvalidEmailDomainList]);

  // Set invalid domain list, if the query is not valid email domain
  const fetchNewQueryEmailQuery = useCallback(
    async (newQuery: string) => {
      const response = await vaildEmailDomain();
      if (response && !response.canAutoVerify) {
        setInvalidEmailDomainList((prevState: string[]) => [...prevState, newQuery]);
      }
    },
    [setInvalidEmailDomainList, vaildEmailDomain]
  );

  useEffect(() => {
    fetchNewQueryEmailQuery(query);
  }, [query, fetchNewQueryEmailQuery]);

  // Update the helper text based on domain list and invalid domain list
  useEffect(() => {
    if (domains.length === 0) {
      setHelperText("Required");
    } else if (invalidEmailDomainList.length > 0) {
      const domainLists = Array.from(new Set(invalidEmailDomainList.map((a) => emailDomainFormatter(a)))).join(", ");
      setHelperText(`Generic email domains such as "${domainLists}" cannot be used`);
    } else {
      setHelperText("");
    }
  }, [domains, invalidEmailDomainList]);

  const handleInputChange = (newVal: string) => {
    if (query !== newVal) {
      // If valid email, extract the domain from it
      if (isValidEmailDomain(newVal)) {
        setQuery(newVal);
      } else if (isValidEmail(newVal)) {
        // Extract domain from valid email
        const domain = extractDomainFromEmail(newVal);
        setQuery(domain ? domain : "");
      }
    }
  };

  // Handling deleting the chip
  const handleDeleteChip = (value: string) => {
    // Get the index of the value
    const index = domains.indexOf(value);
    const invalidEmailDomainTextIndex = invalidEmailDomainList.indexOf(value);
    // Remove the item from the index
    const newDomains = listRemove(domains, index);
    const invalidEmailDomainText = listRemove(invalidEmailDomainList, invalidEmailDomainTextIndex);
    // Update the valid and invlid domain list
    setDomains(newDomains);
    setInvalidEmailDomainList(invalidEmailDomainText);
  };

  const classes = useStyles();

  return (
    <ChipInput
      handleDeleteChip={handleDeleteChip}
      chipList={domains}
      onAdd={(val: string) => {
        // Checking the condition, if entered email domain is valid or invalid email domain
        const newDomains = [...domains];
        if (isValidEmail(val)) {
          // Check if val is valid email, if so extract the domain and add it with existing domain
          const domain = extractDomainFromEmail(val);
          newDomains.push(domain);
          setDomains([...newDomains]);
          return;
        } else if (isValidEmailDomain(val)) {
          // Check if val is valid email domain, if so extract the domain and add it with existing domain
          newDomains.push(val);
          setDomains([...newDomains]);
          return;
        } else {
          // If email or email domain is invalid, throw an error
          enqueueSnackbar(`Enter a valid Email Domain: Failed to add ${val}.`, {
            variant: "warning",
            autoHideDuration: 10000,
          });
          return;
        }
      }}
      chipRenderer={(chipProps, key) => (
        <Chip
          key={key}
          label={emailDomainFormatter(chipProps.value)}
          onDelete={() => handleDeleteChip(chipProps.value)}
          type={invalidEmailDomainList.includes(chipProps.value) ? "error" : "default"}
        />
      )}
      required={required}
      error={!!helperText}
      label={label}
      helperText={helperText}
      InputProps={{
        endAdornment: withIcon && (
          <IconButton size="small" className={classes.searchIconButton}>
            <SearchIcon fontSize="medium" />
          </IconButton>
        ),
      }}
      shouldAutoAdd={(val: string, userPressedEnter: boolean) => {
        if (userPressedEnter) {
          return true;
        }
        handleInputChange(val);
        return isValidEmailDomain(val) || isValidEmail(val);
      }}
    />
  );
}
