import { InformativeModal } from "@coherehealth/common";
import React from "react";
import NewServiceRequestIcon from "./images/NewServiceRequestIcon";
import { DialogProps } from "@material-ui/core/Dialog";

interface Props extends DialogProps {
  open: boolean;
  primaryButtonActions: () => void;
  tertiaryButtonAction: () => void;
}

const StartNewServiceRequestModal = ({ open, onClose, primaryButtonActions, tertiaryButtonAction }: Props) => {
  return (
    <InformativeModal
      open={open}
      onClose={onClose}
      icon={<NewServiceRequestIcon />}
      headerText="The changes you’ve made need to be submitted in a new service request"
      subHeaderText="Note: A new auth number will be generated for the new request"
      showDivider={false}
      primaryButtonText="Start new service request"
      primaryButtonAction={primaryButtonActions}
      tertiaryButtonText="Go back"
      tertiaryButtonAction={tertiaryButtonAction}
    />
  );
};

export default StartNewServiceRequestModal;
