import React, { useContext, useEffect, useState } from "react";

// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";
import { useAuthorized } from "authorization";
import { NotFoundPage } from "@coherehealth/common";
import { useMatch } from "react-router-dom";
import routes from "routes";
import { UserContext } from "UserContext";

import ManageSingleProviderOrg from "./OrganizationDetails/ManageSingleProviderOrg";
import ManageManyProviderOrgs from "./OrganizationList/ManageManyProviderOrgs";
import { useGetOrganizationById } from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";

export default function OrganizationManagementPage() {
  const canViewPage = useAuthorized("ORGANIZATION_MANAGEMENT_PAGE");
  const match = useMatch(routes.ORGANIZATION_MANAGEMENT);
  const orgId = match?.params.orgId || "";
  const [userId, setUserId] = useState<string>("");

  const { getUser } = useContext(UserContext);

  useEffect(() => {
    getUser()?.then((user) => {
      setUserId(user.sub);
    });
  }, [getUser]);

  if (!userId || !canViewPage) {
    return <NotFoundPage />;
  }

  return (
    <PageWrapper>
      {orgId ? <SingleOrgProvider userId={userId} orgId={orgId} /> : <ManageManyProviderOrgs />}
    </PageWrapper>
  );
}

/**
 * Gets the requested org by it's id and renders the ManageSingleProviderOrg component
 */
function SingleOrgProvider({ userId, orgId }: { userId: string; orgId: string }) {
  const {
    data: organization,
    loading: loadingOrgInfo,
    refetch: refetchOrgInfo,
    error: getOrgError,
  } = useGetOrganizationById({
    id: orgId,
  });

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (getOrgError) {
      enqueueSnackbar(`There was an error getting information about this organization: ${getOrgError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, getOrgError]);

  return (
    <ManageSingleProviderOrg
      userId={userId}
      org={organization}
      loading={loadingOrgInfo}
      refetchOrg={refetchOrgInfo}
      internalUser
    />
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PageWrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: "100%",
}));
