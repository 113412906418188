import { Dispatch, SetStateAction, useContext, useEffect } from "react";

import { useFeature } from "@coherehealth/common";

import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";

import RecentPill from "./Pill";

import { createObjectIfNotExistsInLocalStorage, getAttributeInObjectInLocalStorage } from "util/localStorage";
import { FacilityOrAgencyOrProviderType } from "./types";
import { RecentProvidersContext } from "./context";
import { getRecentProvidersKey, useValidRecentProviders } from "./utility";
import { FaxAttachmentContext } from "components/DocumentViewer/FaxAttachment/FaxAttachmentContext";
import { ReferralRequestFormContent } from "components/ReferralManagement/RequestBuilder/ReferralRequestForm";

// * File tag RECENT_PROVIDERS_8B12CDB1

/*
 * Welcome to the Recent Providers feature, friend!
 * You should be able to find all related code using this tag:
 * RECENT_PROVIDERS_8B12CDB1
 *
 * Other files (untagged) affected within the frontend repo:
 * `packages/common/src/components/FeatureFlag.tsx` (feature flag)
 * `packages/core-platform-api/openapi.yaml` (tracking events)
 *
 * Files affected within the core-platform repo:
 * `grails-app/domain/com/coherehealth/analytics/Activity.groovy` (tracking events)
 */

function getShapedRecentFromRecentPillClick<FormContent extends ServiceRequestFormContent | ReferralRequestFormContent>(
  setFormContent: Dispatch<SetStateAction<FormContent>>,
  bucketName:
    | "PerformingFacilityOrAgency"
    | "OrderingProvider"
    | "PerformingProvider"
    | "ReferringProvider"
    | "SpecialistFacility"
    | "PerformingSpecialist"
    | "PerformingProviderPractice",
  recentId: string,
  healthPlanName: string
) {
  const currentBucket = getAttributeInObjectInLocalStorage(getRecentProvidersKey(healthPlanName), bucketName);
  if (!currentBucket) {
    return null;
  }
  const recentProvider = currentBucket.find((recent: FacilityOrAgencyOrProviderType) => {
    if (!recent) {
      return false;
    }

    return recent.id === recentId;
  });

  if (bucketName === "PerformingFacilityOrAgency") {
    setFormContent((prev: FormContent) => ({
      ...prev,
      facility: recentProvider.facility,
      facilitySelectedTin: recentProvider.facilitySelectedTin,
      facilitySelectedAddress: recentProvider.facilitySelectedAddress,
      selectedFacility: recentProvider.selectedFacility,
    }));
  } else if (bucketName === "OrderingProvider") {
    setFormContent((prev: FormContent) => ({
      ...prev,
      orderingProvider: recentProvider.orderingProvider,
      orderingProviderSelectedTin: recentProvider.orderingProviderSelectedTin,
      orderingProviderSelectedAddress: recentProvider.orderingProviderSelectedAddress,
      selectedOrderingProvider: recentProvider.selectedOrderingProvider,
    }));
  } else if (bucketName === "PerformingProvider") {
    setFormContent((prev: FormContent) => ({
      ...prev,
      performingProvider: recentProvider.performingProvider,
      performingProviderSelectedTin: recentProvider.performingProviderSelectedTin,
      performingProviderSelectedAddress: recentProvider.performingProviderSelectedAddress,
      selectedPerformingProvider: recentProvider.selectedPerformingProvider,
    }));
  } else if (bucketName === "PerformingProviderPractice") {
    setFormContent((prev: FormContent) => ({
      ...prev,
      selectedPerformingProviderPractice: recentProvider.selectedPerformingPracticeProvider,
      selectedPerformingProviderPracticeAddress: recentProvider.performingProviderPracticeSelectedAddress,
      selectedPerformingProviderPracticeSelectedTIN: recentProvider.performingProviderPracticeSelectedTin,
    }));
  } else if (bucketName === "ReferringProvider") {
    setFormContent((prev: FormContent) => ({
      ...prev,
      selectedReferringProvider: recentProvider.selectedReferringProvider,
      referringProviderSelectedTin: recentProvider.referringProviderSelectedTin,
      referringProviderSelectedAddress: recentProvider.referringProviderSelectedAddress,
    }));
  } else if (bucketName === "SpecialistFacility") {
    setFormContent((prev: FormContent) => ({
      ...prev,
      facility: recentProvider.facility,
      facilitySelectedTin: recentProvider.facilitySelectedTin,
      facilitySelectedAddress: recentProvider.facilitySelectedAddress,
      selectedFacility: recentProvider.selectedFacility,
    }));
  } else if (bucketName === "PerformingSpecialist") {
    setFormContent((prev: FormContent) => ({
      ...prev,
      performingSpecialistSelectedTin: recentProvider.performingSpecialistSelectedTin,
      performingSpecialistSelectedAddress: recentProvider.performingSpecialistSelectedAddress,
      selectedPerformingSpecialist: recentProvider.selectedPerformingSpecialist,
    }));
  }
}

interface RecentsProps<FormContentType extends ServiceRequestFormContent | ReferralRequestFormContent> {
  bucketName:
    | "PerformingFacilityOrAgency"
    | "OrderingProvider"
    | "PerformingProvider"
    | "ReferringProvider"
    | "SpecialistFacility"
    | "PerformingSpecialist"
    | "PerformingProviderPractice";
  setFormContent: Dispatch<SetStateAction<FormContentType>>;
  healthPlan: string;
  setSameProviders?: (b: boolean) => void;
  orderingProviderId?: string;
  performingProviderId?: string;
  performingProviderPracticeId?: string;
  facilityId?: string;
  temporarilyHidden?: boolean;
  referralProviderId?: string;
  specialistFacilityId?: string;
  performingSpecialistId?: string;
}

function Recents<FormContentType extends ServiceRequestFormContent | ReferralRequestFormContent>({
  bucketName,
  setFormContent,
  setSameProviders,
  orderingProviderId,
  performingProviderId,
  temporarilyHidden = false,
  healthPlan,
}: RecentsProps<FormContentType>) {
  // * Tracking context setters.
  const {
    setOrderingProviderTrackingState,
    setPerformingProviderTrackingState,
    setFacilityTrackingState,
    setReferringProviderTrackingState,
    setPerformingSpecialistTrackingState,
    setSpecialistFacilityTrackingState,
    setPerformingProviderPracticeTrackingState,
  } = useContext(RecentProvidersContext);

  /* Setup when the component mounts with a new health plan */
  useEffect(() => {
    // * Create the Recent Providers localStorage object for this health plan if it doesn't exist.
    createObjectIfNotExistsInLocalStorage(`RecentProviderDetails_${healthPlan}`, {
      PerformingFacilityOrAgency: [],
      OrderingProvider: [],
      PerformingProvider: [],
      ReferringProvider: [],
      SpecialistFacility: [],
      PerformingSpecialist: [],
      PerformingProviderPractice: [],
    });
  }, [healthPlan]);

  // * Filter out null and invalid elements and shape for the rendering of Pills.
  const recentProviderOptions: FacilityOrAgencyOrProviderType[] | undefined = useValidRecentProviders(
    healthPlan,
    bucketName
  );

  // * If something went wrong, bucket doesn't exist, or bucket has no providers yet, don't render this component.
  if (!recentProviderOptions || !recentProviderOptions.length) {
    return null;
  }

  return !Boolean(temporarilyHidden) && recentProviderOptions.length > 0 ? (
    <section
      style={{
        display: "flex",
        flexFlow: "row wrap",
        gap: "8px",
        paddingLeft: "8px",
        marginBottom: bucketName === "PerformingFacilityOrAgency" ? 8 : 4,
      }}
    >
      {recentProviderOptions.map((recent) => {
        return (
          <RecentPill
            key={recent?.id}
            onClick={async () => {
              // * Update the relevant form with the clicked Pill's information.
              await getShapedRecentFromRecentPillClick(setFormContent, bucketName, recent.id || "", healthPlan);

              // * Tracking information setup.
              if (setOrderingProviderTrackingState && bucketName === "OrderingProvider") {
                await setOrderingProviderTrackingState("RECENT_OP_CLICKED_NO_CHANGE");
              } else if (setPerformingProviderTrackingState && bucketName === "PerformingProvider") {
                await setPerformingProviderTrackingState("RECENT_PP_CLICKED_NO_CHANGE");
              } else if (setPerformingProviderPracticeTrackingState && bucketName === "PerformingProviderPractice") {
                await setPerformingProviderPracticeTrackingState("RECENT_PPP_CLICKED_NO_CHANGE");
              } else if (setFacilityTrackingState && bucketName === "PerformingFacilityOrAgency") {
                await setFacilityTrackingState("RECENT_F_CLICKED_NO_CHANGE");
              } else if (setReferringProviderTrackingState && bucketName === "ReferringProvider") {
                await setReferringProviderTrackingState("RECENT_RP_CLICKED_NO_CHANGE");
              } else if (setPerformingSpecialistTrackingState && bucketName === "PerformingSpecialist") {
                await setPerformingSpecialistTrackingState("RECENT_PS_CLICKED_NO_CHANGE");
              } else if (setSpecialistFacilityTrackingState && bucketName === "SpecialistFacility") {
                await setSpecialistFacilityTrackingState("RECENT_SF_CLICKED_NO_CHANGE");
              }

              // * If providers are the same, check the box accordingly.
              if (setSameProviders) {
                if (bucketName === "OrderingProvider") {
                  if (performingProviderId && recent.id === performingProviderId) {
                    setSameProviders(true);
                  } else {
                    setSameProviders(false);
                  }
                } else if (bucketName === "PerformingProvider") {
                  if (orderingProviderId && orderingProviderId === recent.id) {
                    setSameProviders(true);
                  } else {
                    setSameProviders(false);
                  }
                }
              }
            }}
            label={recent.name || ""}
          />
        );
      })}
    </section>
  ) : null;
}

/**
 * Decides whether or not to show the Recents component
 */
type RecentsPropsWithOptionalHealthPlan<
  FormContentType extends ServiceRequestFormContent | ReferralRequestFormContent
> = Omit<RecentsProps<FormContentType>, "healthPlan"> & { healthPlan?: string | null };

export default function RecentsWrapper<FormContentType extends ServiceRequestFormContent | ReferralRequestFormContent>(
  props: RecentsPropsWithOptionalHealthPlan<FormContentType>
) {
  const {
    bucketName,
    orderingProviderId,
    performingProviderId,
    facilityId,
    performingProviderPracticeId,
    referralProviderId,
    specialistFacilityId,
    performingSpecialistId,
  } = props;
  const { faxDbId: faxId } = useContext(FaxAttachmentContext);
  const isFaxIntakeFlow = Boolean(faxId);
  // * Feature Flag for Recent Providers
  const useRecentProviderDetails = useFeature("recentProviderDetails");
  if (!useRecentProviderDetails) {
    return null;
  }

  // * Prevent rendering if form for bucket already filled.
  if (bucketName === "OrderingProvider" && orderingProviderId) {
    return null;
  }
  if (bucketName === "ReferringProvider" && referralProviderId) {
    return null;
  }
  if (bucketName === "PerformingProvider" && performingProviderId) {
    return null;
  }
  if (bucketName === "PerformingProviderPractice" && performingProviderPracticeId) {
    return null;
  }

  if (bucketName === "PerformingFacilityOrAgency" && facilityId) {
    return null;
  }
  if (bucketName === "SpecialistFacility" && specialistFacilityId) {
    return null;
  }
  if (bucketName === "PerformingSpecialist" && performingSpecialistId) {
    return null;
  }
  if (!hasHealthPlan(props)) {
    return null;
  }

  if (isFaxIntakeFlow) {
    return null;
  }

  return <Recents {...props} healthPlan={props.healthPlan || ""} />;
}

function hasHealthPlan<FormContentType extends ServiceRequestFormContent | ReferralRequestFormContent>(
  props: RecentsPropsWithOptionalHealthPlan<FormContentType>
): props is RecentsProps<FormContentType> {
  return !!props.healthPlan;
}
