import { useState, Dispatch, useEffect, useCallback, SetStateAction, useContext, useMemo } from "react";
import * as datefns from "date-fns";
import { H4, Checkbox, formatDateToISODate, compareISODates, HUMANA_HEALTH_PLAN_NAME } from "@coherehealth/common";
import {
  AuthStatus,
  ClinicalService,
  Patient,
  ProcedureCode,
  AuthorizationResponse,
  ServiceRequestResponse,
  useGetPlaceOfServices,
} from "@coherehealth/core-platform-api";
import Divider from "@material-ui/core/Divider";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
  addFlexibleCodeIntakeFieldsToProcedureCodes,
  getPendingAuthStatuses,
  sameProviderCheckboxClick,
  validateExceptionReasonforEachCareParticipantsOnContinuation,
  validateOONExceptionCommentFieldForCareParticipants,
} from "util/serviceRequest";
import { Dialog } from "@material-ui/core";
import RemoveNonPALModal from "components/ServiceRequest/ServiceRequestForm/RemoveNonPALModal";
import DocumentViewer from "components/DocumentViewer";
import ServiceRequestFormRedirectModal, {
  useServiceRequestFormRedirectDispatch,
} from "components/ServiceRequest/ServiceRequestForm/ServiceRequestFormRedirectModal";

import ServiceFrequencySection from "components/ServiceRequest/ServiceRequestForm/components/ServiceFrequencySection";
import { MAX_DATE_SELECT_DATE } from "components/ServiceRequest/ServiceRequestForm/components/StartAndEndDateSelect";
import { ContinuationFormContent, FlexGridItem } from "common/SharedServiceRequestFormComponents";
import { ExistingRequestExpedited, RequestExpedited } from "components/ServiceRequest/ServiceRequestForm/Expedited";
import { formContentToContinuation } from "components/ServiceRequest/ServiceRequestForm/ServiceRequestFormRedirectModal";
import {
  checkOnetimeRequestCoverage,
  getCurrentPrimaryCoverage,
  getPatientHealthPlanName,
  getPatientRiskBearingEntity,
} from "util/patientUtils";
import {
  ContinuationConfiguration,
  ContinuationFieldName,
} from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import ProcedureCodeSelectTable from "../../ServiceRequest/ServiceRequestForm/components/ProcedureCodeSelectTable";
import { NonCohereCodes, onCareTypeSwitch } from "../common";
import { useCheckServiceRequestProcedureCodes, Row } from "../FillForms/ServiceRequestForm";
import ProcedureCodeSelectTableWithUnits from "../../ServiceRequest/ServiceRequestForm/components/ProcedureCodeSelectTableWithUnits";
import NonPalPDFProvider from "components/ServiceRequest/ServiceRequestForm/NonPalDocument/NonPalPDFProvider";
import {
  PrimaryDiagnosisCodeSelect,
  SecondaryDiagnosisCodeSelect,
} from "components/ServiceRequest/ServiceRequestForm/components/DiagnosisCodeSelect";
import { SuggestionContext } from "../SuggestionContext";
import { compareCodeListEqualityNoOrder, isNotNullOrUndefined } from "util/suggestionUtils";
import CareTypeSelect from "components/ServiceRequest/ServiceRequestForm/components/CareTypeSelect";
import PlaceOfServiceSelect from "components/ServiceRequest/ServiceRequestForm/components/PlaceOfServiceSelect";
import useDoPalCheckOnServiceRequestForm from "../../ServiceRequest/ServiceRequestForm/hooks/useDoPalCheckOnServiceRequestForm";
import { calculateStartEndDate } from "util/authorization";
import { error as logError } from "../../../logger";
import { useSnackbar } from "notistack";
import AdmissionAndExpectedDischargeDateSelect from "common/AdmissionAndExpectedDischargeDateSelect";
import {
  OrderingProviderSelectManual,
  PerformingProviderSelectManual,
} from "components/ServiceRequest/ServiceRequestForm/components/ProviderSelectManual";
import FacilitySelectManual from "components/ServiceRequest/ServiceRequestForm/components/FacilitySelectManual";
import { isExceptionCommentRequired } from "util/providerUtils";
import { useCalculateDateSelectionMessageDisplay } from "components/AuthBuilder/FillFormsGeneralAuthSubmission/utils";
import {
  validatePerformingProviderAddress,
  validatePerformingProviderNPI,
  validatePerformingProviderTin,
} from "../validationUtil";
import useSearchAndLocation from "hooks/useSearchAndLocation";
import { fieldIsValid, SRFormConfigFieldWrapper } from "common/FormConfigUtils";
import { useGetServiceRequestStateConfigurationByPayerAndAuthStatus } from "hooks/useGetFeatureConfigurations";

interface Props {
  formContent: ContinuationFormContent;
  setFormContent: Dispatch<SetStateAction<ContinuationFormContent>>;
  formConfiguration: ContinuationConfiguration;
  patient?: Patient;
  attemptedSubmit: boolean;
  setCanBeSubmitted: (b: boolean) => void;
  hideDiagnoses?: boolean;
  isCohereFaxForm?: boolean;
  authStatus: AuthStatus;
  delegatedVendorName?: string;
  userFaxExtension?: string;
  onUserEdit?: Dispatch<ContinuationFormContent>;
  workflowId?: string;
  dateCreated?: string;
  populateDefaults?: boolean;
  authorization?: AuthorizationResponse | null;
  onPatientSummary?: boolean;
  serviceRequest?: ServiceRequestResponse;
  requestType?: string;
  setAuthorization?: Dispatch<SetStateAction<AuthorizationResponse | null>>;
  hideExpeditedRequestCheckbox?: boolean;
}

const MIN_START_DATE = new Date(2020, 0, 1);

export default function ServiceRequestContinuationForm({
  formContent,
  setFormContent,
  formConfiguration,
  patient,
  attemptedSubmit,
  setCanBeSubmitted,
  authStatus: currAuthStatus,
  delegatedVendorName,
  dateCreated,
  onUserEdit,
  workflowId,
  populateDefaults = true,
  authorization,
  isCohereFaxForm,
  onPatientSummary = false,
  serviceRequest,
  requestType,
  setAuthorization,
  hideExpeditedRequestCheckbox,
}: Props) {
  const { location, search } = useSearchAndLocation();

  const [clinicalService] = useState<ClinicalService | undefined>(formContent?.clinicalService);
  const [nonPalModalOpen, setNonPalModalOpen] = useState(false);
  const [nonPalPDFOpen, setNonPalPDFOpen] = useState(false);
  const [onBlurServiceLevelUnits, setOnBlurServiceLevelUnits] = useState(0);
  const [nonPalProcedureCodes, setNonPalProcedureCodes] = useState<ProcedureCode[]>([]);
  const [nonCohereProcedureCodes, setNonCohereProcedureCodes] = useState<NonCohereCodes[]>([]);
  const [palPxCodes, setPalProcedureCodes] = useState<ProcedureCode[]>([]);
  const [sameProviders, setSameProviders] = useState(false);
  const [semanticProcedureCodes, setSemanticProcedureCodes] = useState<ProcedureCode[]>(
    authorization?.semanticProcedureCodes || []
  );
  const { spacing } = useTheme();
  const singleService = clinicalService?.isSingleService;

  // update default reference values whenever selecting new clinicalService within the form
  const { suggestedPriorAuthRequirements } = useContext(SuggestionContext);

  const doesNotExceedMaxSecondaryDiagnoses = !!formContent?.secondaryDiagnosisCodes;

  const patientHealthPlanName = getPatientHealthPlanName(patient || undefined, formContent.startDate) || "";
  const patientRiskBearingEntity = getPatientRiskBearingEntity(patient || undefined, formContent.startDate) || "";
  const [initialServiceRequest] = useState(() => ({
    ...formContentToContinuation(formContent),
    authStatus: currAuthStatus,
    workflowId: workflowId,
  }));

  // Logic and other helper functions related to validation
  // In most cases we don't want to default to showing an error state unless if the user tried to submit
  const [hasValidCoverageDates, setHasValidCoverageDates] = useState<boolean>(false);
  const startDateExceedsMinStartDate = formContent.startDate >= MIN_START_DATE;
  const hasStartDate = Boolean(formContent.startDate);
  let hasValidStartDate = false;
  try {
    const expectedMinStartDate = calculateStartEndDate(authorization, 1)?.startDate ?? MIN_START_DATE;
    const expectedMaxStartDate = calculateStartEndDate(authorization, 1)?.endDate ?? MAX_DATE_SELECT_DATE;
    const isValidStartDate = datefns.isValid(formContent.startDate)
      ? !!(expectedMinStartDate && expectedMaxStartDate)
      : false;
    const compareStartDates = () => {
      const resultStartDate = isValidStartDate
        ? compareISODates(formContent.startDate?.toISOString(), expectedMinStartDate.toISOString())
        : undefined;
      const resultEndDate = isValidStartDate
        ? compareISODates(formContent.startDate?.toISOString(), expectedMaxStartDate.toISOString())
        : undefined;
      return resultStartDate !== undefined && resultEndDate !== undefined
        ? resultStartDate <= 0 && resultEndDate >= 0
        : false;
    };
    hasValidStartDate = compareStartDates();
  } catch (error) {
    logError(new Error("Invalid date format"));
  }

  const hasValidEndDate = (formContent.endDate ?? MAX_DATE_SELECT_DATE) <= MAX_DATE_SELECT_DATE;
  const hasPlaceOfService = Boolean(formContent.placeOfService);
  const endDateExceedsStartDate = formContent.endDate ? formContent.endDate >= formContent.startDate : true;
  const minNumUnits = 1;
  //TODO remove all isRecurring validation logic with removal of simplifiedServiceFrequency
  const showEndDate = true;
  const hasEndDateIfApplicable = showEndDate ? Boolean(formContent.endDate) : true;
  const hasUnitsIfApplicable = Boolean(formContent.units);
  const validUnits =
    parseInt(formContent.units) > Math.max(0, minNumUnits - 1) && Number.isInteger(Number(formContent.units));
  const hasExpeditedReasonIfExpedited = !formContent.isExpedited || !!formContent.expeditedReason;
  const expectedDischargeExceedsAdmissionDate =
    formContent.expectedDischargeDate && formContent.admissionDate
      ? formContent.expectedDischargeDate >= formContent.admissionDate
      : false;
  const admissionDateExceedsMinAdmissionDate = formContent.admissionDate
    ? formContent.admissionDate >= MIN_START_DATE
    : false;

  const hasValidPxUnits =
    formContent &&
    formContent.procedureCodes.every(
      (procedureCode) =>
        procedureCode.units &&
        procedureCode.units > 0 &&
        (procedureCode.units <= (procedureCode.maxUnits ?? 1) * Number(formContent.units) ||
          formContent?.clinicalService?.isUnitsOnPx)
    );
  const hasValidProcedureCodeWithUnits = formContent && formContent.procedureCodes.length > 0 && hasValidPxUnits;
  const procedureCodesValid =
    formContent.procedureCodes.length > 0 &&
    !(formContent.procedureCodes.length > 10 && patientHealthPlanName === HUMANA_HEALTH_PLAN_NAME);
  const noNonPalProcedureCodesIfOutpatient =
    formContent.procedureCodes.filter((px) => nonPalProcedureCodes?.some(({ code }) => code === px.code)).length ===
      0 || formContent.isInpatient;
  const hasPrimaryDiagnosis = Boolean(formContent.primaryDiagnosisCode);
  const hasSecondaryDiagnoses = !!formContent?.secondaryDiagnosisCodes
    ? formContent?.secondaryDiagnosisCodes?.length > 0
    : true;
  const hasOrderingProvider = Boolean(formContent.orderingProvider);
  const hasPerformingProvider = Boolean(formContent.performingProvider);
  const hasFacility = Boolean(formContent.facility);
  const hasFacilityNpi = Boolean(formContent.facility?.npi);
  const hasPerformingProviderNpi = Boolean(formContent.performingProvider?.npi);
  const hasOrderingProviderNpi = Boolean(formContent.orderingProvider?.npi);
  const hasFacilityTin = formContent?.facility?.tinList?.length === 0 ? true : Boolean(formContent.facilitySelectedTin);
  const performingProviderTinLength = formContent.performingProvider?.tinList?.length;
  const hasPerformingProviderTin =
    performingProviderTinLength === 0 ? true : Boolean(formContent.performingProviderSelectedTin);
  const hasFacilityAddress =
    formContent?.facility?.locations === undefined ? true : Boolean(formContent.facilitySelectedAddress);
  const hasPerformingProviderAddress =
    formContent?.performingProvider?.locations === undefined
      ? true
      : Boolean(formContent.performingProviderSelectedAddress);
  const hasOrderingProviderTin =
    formContent?.orderingProvider?.tinList?.length === 0 ? true : Boolean(formContent.orderingProviderSelectedTin);
  const hasOrderingProviderAddress =
    formContent?.orderingProvider?.locations === undefined
      ? true
      : Boolean(formContent.orderingProviderSelectedAddress);
  const requiresFacilityOONExceptionComment =
    formContent.selectedFacility?.selectedLocation?.isOutOfNetwork &&
    formContent.selectedFacility?.selectedLocation?.outOfNetworkExceptionReason === "Other (comment is required)";

  const requiresPerformingProviderOONExceptionComment =
    formContent.selectedPerformingProvider?.selectedLocation?.isOutOfNetwork &&
    formContent.selectedPerformingProvider?.selectedLocation?.outOfNetworkExceptionReason ===
      "Other (comment is required)";

  const requiresPerformingProviderPracticeOONExceptionComment =
    formContent.selectedPerformingProviderPractice?.selectedLocation?.isOutOfNetwork &&
    formContent.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionReason ===
      "Other (comment is required)";

  const requiresOrderingProviderOONExceptionComment = isExceptionCommentRequired(formContent);

  const hasFacilityOONExceptionComment = formContent.selectedFacility?.selectedLocation?.outOfNetworkExceptionComment;

  const hasPerformingProviderOONExceptionComment =
    formContent.selectedPerformingProvider?.selectedLocation?.outOfNetworkExceptionComment;

  const hasPerformingProviderPracticeOONExceptionComment =
    formContent.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionComment;

  const hasOrderingProviderOONExceptionComment =
    formContent.selectedOrderingProvider?.selectedLocation?.outOfNetworkExceptionComment;

  const { showExceededDurationLimitMessageErrorState, showBelowDurationLimitMessageErrorState } =
    useCalculateDateSelectionMessageDisplay(
      formContent,
      patient?.coverages || [],
      patient ? patient : null,
      formConfiguration
    );

  const VALIDATION_MAP: Record<ContinuationFieldName, boolean> = {
    selectedDetails: true,
    expeditedTatUpdateTimestamp: true,
    procedureCodes: fieldIsValid(
      formConfiguration.procedureCodes,
      procedureCodesValid,
      noNonPalProcedureCodesIfOutpatient
    ),
    procedureCodeWithUnits: fieldIsValid(formConfiguration.procedureCodeWithUnits, hasValidProcedureCodeWithUnits),
    startEndDate: fieldIsValid(
      formConfiguration.startEndDate,
      hasStartDate && hasEndDateIfApplicable,
      startDateExceedsMinStartDate &&
        endDateExceedsStartDate &&
        hasValidCoverageDates &&
        hasValidStartDate &&
        hasValidEndDate
    ),
    units: fieldIsValid(formConfiguration.units, hasUnitsIfApplicable, validUnits),
    urgency: fieldIsValid(formConfiguration.urgency, hasExpeditedReasonIfExpedited),
    admissionDischargeDate: fieldIsValid(
      formConfiguration.admissionDischargeDate,
      expectedDischargeExceedsAdmissionDate && admissionDateExceedsMinAdmissionDate
    ),
    orderingProvider: onPatientSummary ? fieldIsValid(formConfiguration.orderingProvider, hasOrderingProvider) : true,
    performingProvider: onPatientSummary
      ? fieldIsValid(formConfiguration.performingProvider, hasPerformingProvider)
      : true,
    facility: onPatientSummary ? fieldIsValid(formConfiguration.facility, hasFacility) : true,
    facilityAddress: onPatientSummary ? fieldIsValid(formConfiguration.facilityAddress, hasFacilityAddress) : true,
    facilityNPI: onPatientSummary ? fieldIsValid(formConfiguration.facilityNPI, hasFacilityNpi) : true,
    facilityTIN: onPatientSummary ? fieldIsValid(formConfiguration.facilityTIN, hasFacilityTin) : true,
    performingProviderTIN: onPatientSummary
      ? validatePerformingProviderTin(
          formConfiguration,
          formContent,
          performingProviderTinLength,
          hasPerformingProviderTin
        )
      : true,
    performingProviderNPI: validatePerformingProviderNPI(formConfiguration, formContent, hasPerformingProviderNpi),
    performingProviderAddress: onPatientSummary
      ? validatePerformingProviderAddress(
          formConfiguration,
          formContent,
          patientHealthPlanName,
          hasPerformingProviderAddress
        )
      : true,
    orderingProviderAddress: onPatientSummary
      ? fieldIsValid(formConfiguration.orderingProviderAddress, hasOrderingProviderAddress)
      : true,
    orderingProviderTIN: onPatientSummary
      ? fieldIsValid(formConfiguration.orderingProviderTIN, hasOrderingProviderTin)
      : true,
    orderingProviderNPI: onPatientSummary
      ? fieldIsValid(formConfiguration.orderingProviderNPI, hasOrderingProviderNpi)
      : true,
    primaryDiagnosis:
      onPatientSummary && formContent?.isInpatient
        ? fieldIsValid(formConfiguration.primaryDiagnosis, hasPrimaryDiagnosis)
        : true,
    secondaryDiagnoses: onPatientSummary
      ? fieldIsValid(formConfiguration.secondaryDiagnoses, hasSecondaryDiagnoses, doesNotExceedMaxSecondaryDiagnoses)
      : true,
    placeOfService: fieldIsValid(formConfiguration.placeOfService, hasPlaceOfService),
    facilityOutOfNetworkExceptionComment:
      (requiresFacilityOONExceptionComment && !!hasFacilityOONExceptionComment) || !requiresFacilityOONExceptionComment,
    performingProviderOutOfNetworkExceptionComment:
      (requiresPerformingProviderOONExceptionComment && !!hasPerformingProviderOONExceptionComment) ||
      !requiresPerformingProviderOONExceptionComment,
    performingProviderPracticeOutOfNetworkExceptionComment:
      (requiresPerformingProviderPracticeOONExceptionComment && !!hasPerformingProviderPracticeOONExceptionComment) ||
      !requiresPerformingProviderPracticeOONExceptionComment,
    orderingProviderOutOfNetworkExceptionComment:
      (requiresOrderingProviderOONExceptionComment && !!hasOrderingProviderOONExceptionComment) ||
      !requiresOrderingProviderOONExceptionComment,
    // disabled fields
    encounterType: true,
    userDeclaredOONException: true,
    patientStayDateRanges: fieldIsValid(formConfiguration.patientStayDateRanges),
    authCategory: fieldIsValid(formConfiguration.authCategory),
    authSubCategory: fieldIsValid(formConfiguration.authSubCategory),
    admissionDate: fieldIsValid(formConfiguration.admissionDate),
    admissionTime: fieldIsValid(formConfiguration.admissionTime),
    dischargeTime: fieldIsValid(formConfiguration.dischargeTime),
    dischargeDate: fieldIsValid(formConfiguration.dischargeDate),
    dischargedTo: fieldIsValid(formConfiguration.dischargedTo),
    expectedAdmissionDate: fieldIsValid(formConfiguration.expectedAdmissionDate),
    expectedDischargeDate: fieldIsValid(formConfiguration.expectedDischargeDate),
    facilityOutOfNetworkExceptionReason: fieldIsValid(formConfiguration.facilityOutOfNetworkExceptionReason),
    performingProviderOutOfNetworkExceptionReason: fieldIsValid(
      formConfiguration.performingProviderOutOfNetworkExceptionReason
    ),
    performingProviderPracticeOutOfNetworkExceptionReason: fieldIsValid(
      formConfiguration.performingProviderOutOfNetworkExceptionReason
    ),
    orderingProviderOutOfNetworkExceptionReason: fieldIsValid(
      formConfiguration.orderingProviderOutOfNetworkExceptionReason
    ),
    careParticipantOutOfNetworkExceptionComment: formContent.additionalCareParticipants
      ? validateOONExceptionCommentFieldForCareParticipants(formContent.additionalCareParticipants)
      : true,
    careParticipantOutOfNetworkExceptionReason: formContent.additionalCareParticipants
      ? validateExceptionReasonforEachCareParticipantsOnContinuation(formContent.additionalCareParticipants)
      : true,
    additionalCareParticipants: fieldIsValid(formConfiguration.additionalCareParticipants),
    nonPalCheckbox: true,
    blockUserIfExceedsRecommendedEndDate: fieldIsValid(
      formConfiguration.blockUserIfExceedsRecommendedEndDate,
      !showExceededDurationLimitMessageErrorState
    ),
    blockUserIfLessThanRecommendedEndDate: fieldIsValid(
      formConfiguration.blockUserIfLessThanRecommendedEndDate,
      !showBelowDurationLimitMessageErrorState
    ),
    performingProviderPracticeSelectedTIN: true,
    performingProviderPracticeSelectedAddress: true,
    performingProviderPractice: true,
    performingProviderPracticeSelectedNPI: true,
    admissionSource: true,
    patientStatus: true,
    orderingProviderOutOfNetworkStatusDisplay: true,
    performingProviderOutOfNetworkStatusDisplay: true,
    performingProviderPracticeOutOfNetworkStatusDisplay: true,
    facilityOutOfNetworkStatusDisplay: true,
  };

  const { shouldWaitForRuleRunResult, dispatchUrgencyRuleCheck, redirectModalProps, dispatchRedirectRuleCheck } =
    useServiceRequestFormRedirectDispatch({
      workflowId,
      formContent,
      healthPlanName: patientHealthPlanName,
      patient,
      clinicalService,
    });

  const validateFields = () => {
    if (shouldWaitForRuleRunResult) {
      return false;
    }

    const formConfig = formConfiguration;
    if (formConfig) {
      const formConfigKeys = Object.keys(formConfig) as ContinuationFieldName[];
      for (const key of formConfigKeys) {
        if (VALIDATION_MAP[key] !== undefined && !VALIDATION_MAP[key]) {
          return false;
        }
      }
      return true;
    }
    //should never happen, but if it does we should block the user from trying to submit
    return false;
  };

  const isValid = validateFields();

  const handleFormValidation = useCallback(
    (isFormValid: boolean) => {
      setCanBeSubmitted(isFormValid);
    },
    [setCanBeSubmitted]
  );

  useEffect(() => {
    handleFormValidation(isValid);
  }, [isValid, handleFormValidation]);

  useEffect(() => {
    if (authorization) {
      setSemanticProcedureCodes(authorization.semanticProcedureCodes || []);
    }
  }, [authorization, setSemanticProcedureCodes]);

  useEffect(() => {
    if (semanticProcedureCodes) {
      if (setAuthorization) {
        setAuthorization((prev) => {
          return { ...prev, semanticProcedureCodes };
        });
      }
    }
  }, [semanticProcedureCodes, setAuthorization]);

  useEffect(() => {
    if (formContent.isInpatient && formConfiguration.admissionDischargeDate.fieldSpec !== "OMIT") {
      setFormContent((oldContents) => ({
        ...oldContents,
        admissionDate: oldContents.admissionDate || oldContents.startDate,
      }));
    }
    if (!formContent.isInpatient && formConfiguration.admissionDischargeDate.fieldSpec === "OMIT") {
      setFormContent((oldContents) => ({
        ...oldContents,
        admissionDate: undefined,
        expectedDischargeDate: undefined,
      }));
    }
  }, [formContent.isInpatient, formConfiguration.admissionDischargeDate.fieldSpec, setFormContent]);

  useEffect(() => {
    /*
      when units are <= 1, the end date does not display on the UI, and we should
      also remove it from state so that it is not persisted to the BE
    */
    if (Number(formContent.units) === 1) {
      setFormContent({ ...formContent, endDate: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContent.units]);

  useEffect(() => {
    setFormContent((prev) => {
      return { ...prev, placeOfService: authorization?.placeOfService };
    });
  }, [authorization?.placeOfService, setFormContent]);

  useCheckServiceRequestProcedureCodes({
    procedureCodes: formContent.procedureCodes,
    palPxCodes,
    isInpatient: formContent.isInpatient,
    clinicalService,
    dispatchRedirectRuleCheck,
    placeOfServiceId: authorization?.placeOfService?.id,
    startDate: formContent.startDate,
    patient,
    workflowId,
  });

  /**
   * This sets the service request form content, but it also runs the onUserEdit callback.
   *
   * This is useful if we want to do something after the user explicitly edits the service request form content, not
   * just whenever the serviceRequestForm content changes (like from a PAR check effect or whatever)
   * @param newFormContent
   */
  const setFormContentOnUserEdit: Dispatch<SetStateAction<ContinuationFormContent>> = useCallback(
    (setStateAction) => {
      // In order to get the next state value to pass to onUserEdit, we actually need to call setFormContent
      // because we might in fact have a function, not a realized value
      setFormContent((prev) => {
        const newFormContent: ContinuationFormContent =
          typeof setStateAction === "function" ? setStateAction(prev) : setStateAction;
        onUserEdit?.(newFormContent);
        newFormContent.procedureCodes = addFlexibleCodeIntakeFieldsToProcedureCodes(
          newFormContent?.procedureCodes || [],
          serviceRequest?.clinicalService || authorization?.clinicalService
        );
        return newFormContent;
      });
    },
    [onUserEdit, setFormContent, serviceRequest?.clinicalService, authorization?.clinicalService]
  );
  const {
    data: placeOfServiceData,
    loading: placeOfServiceLoading,
    error: getPlaceOfServiceError,
  } = useGetPlaceOfServices({
    queryParams: {
      healthPlanName: patientHealthPlanName || undefined,
      encounterType: formContent.isInpatient ? "INPATIENT" : "OUTPATIENT",
      clinicalServiceId: clinicalService?.id,
    },
  });

  useEffect(() => {
    // clear placeOfService if it is not in any of the available options
    if (
      formContent.placeOfService &&
      placeOfServiceData?.length &&
      !placeOfServiceData?.find(({ id }) => id === formContent.placeOfService?.id)
    ) {
      setFormContent((prev) => ({ ...prev, placeOfService: null }));
    }
  }, [formContent.placeOfService, placeOfServiceData, setFormContent]);

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (getPlaceOfServiceError) {
      enqueueSnackbar(`Error getting places of service ${getPlaceOfServiceError.message}`, { variant: "error" });
    }
  }, [getPlaceOfServiceError, enqueueSnackbar]);

  const { doPalCheck } = useDoPalCheckOnServiceRequestForm({
    patientId: patient?.id || "",
    isInpatient: formContent.isInpatient,
    startDate: formContent.startDate,
    palProcedureCodes: palPxCodes,
    setProcedureCodes: (codes) => {
      setFormContentOnUserEdit((prev) => ({ ...prev, procedureCodes: codes }));
      setSemanticProcedureCodes(codes);
    },
    setPalProcedureCodes,
    setNonPalProcedureCodes,
    setNonCohereProcedureCodes,
  });

  const [isStartDateInRange, setIsStartDateInRange] = useState(true);
  const [isEndDateInRange, setIsEndDateInRange] = useState(true);

  const startDateCoverage = useMemo(() => {
    if (patient?.coverages) {
      if (formContent?.admissionDate) {
        return checkOnetimeRequestCoverage(patient?.coverages, formContent?.admissionDate);
      } else if (formContent?.startDate) {
        return checkOnetimeRequestCoverage(patient?.coverages, formContent?.startDate);
      }
    }
    return undefined;
  }, [patient?.coverages, formContent?.startDate, formContent?.admissionDate]);

  const endDateCoverage = useMemo(() => {
    if (patient?.coverages) {
      if (formContent?.dischargeDate) {
        return checkOnetimeRequestCoverage(patient?.coverages, formContent?.dischargeDate);
      } else if (formContent?.endDate) {
        return checkOnetimeRequestCoverage(patient?.coverages, formContent?.endDate);
      }
    }
    return undefined;
  }, [patient?.coverages, formContent?.endDate, formContent?.dischargeDate]);

  const { isFieldDisabled } =
    useGetServiceRequestStateConfigurationByPayerAndAuthStatus({
      healthPlanName: patientHealthPlanName || "",
      authStatus: currAuthStatus,
      delegatedVendorName: delegatedVendorName || patientRiskBearingEntity || "",
      requestType: "CONTINUATION",
    }) || {};

  return (
    <>
      <Grid container spacing={2}>
        {onPatientSummary && (
          <>
            <SRFormConfigFieldWrapper {...formConfiguration.encounterType}>
              <Row style={{ marginTop: spacing(3) }}>
                <CareTypeSelect
                  inpatientValue={formContent.isInpatient}
                  formContent={formContent}
                  authStatus={currAuthStatus}
                  patientHealthPlanName={patientHealthPlanName || ""}
                  onContinuationPatientSummary={onPatientSummary}
                  editableFromPatientSummary={
                    getPendingAuthStatuses().some((status) => currAuthStatus === status) &&
                    authorization?.serviceRequestsOnAuth?.length === 1
                  }
                  onCareTypeSwitch={(val: string) => {
                    onCareTypeSwitch(val, setFormContentOnUserEdit);
                  }}
                  disabled={isFieldDisabled("encounterType")}
                />
              </Row>
            </SRFormConfigFieldWrapper>
            <SRFormConfigFieldWrapper {...formConfiguration.placeOfService}>
              <Row>
                <PlaceOfServiceSelect
                  error={attemptedSubmit && !VALIDATION_MAP["placeOfService"]}
                  isCohereFaxForm={Boolean(isCohereFaxForm && initialServiceRequest.placeOfService)}
                  authStatus={currAuthStatus}
                  isInpatient={formContent.isInpatient}
                  placeOfService={formContent?.placeOfService || null}
                  startDate={formatDateToISODate(formContent.startDate)}
                  setPlaceOfService={(pos) => {
                    setFormContentOnUserEdit({ ...formContent, placeOfService: pos });
                  }}
                  clinicalService={clinicalService || undefined}
                  patient={patient || undefined}
                  dispatchRedirectRuleCheck={dispatchRedirectRuleCheck("encounter type")}
                  procedureCodes={formContent.procedureCodes}
                  availablePlacesOfService={placeOfServiceData || undefined}
                  placeOfServiceLoading={placeOfServiceLoading}
                  onContinuationPatientSummary={onPatientSummary}
                  editableFromPatientSummary={
                    getPendingAuthStatuses().some((status) => currAuthStatus === status) &&
                    authorization?.serviceRequestsOnAuth?.length === 1
                  }
                  disabled={isFieldDisabled("placeOfService")}
                />
              </Row>
            </SRFormConfigFieldWrapper>
            <SRFormConfigFieldWrapper {...formConfiguration.primaryDiagnosis}>
              <Row>
                <PrimaryDiagnosisCodeSelect
                  isAutoFilled={
                    suggestedPriorAuthRequirements?.primaryDiagnosis?.diagnosisCodeEntity
                      ? suggestedPriorAuthRequirements.primaryDiagnosis.diagnosisCodeEntity.code ===
                        formContent?.primaryDiagnosisCode?.code
                      : false
                  }
                  isCohereFaxForm={Boolean(isCohereFaxForm && initialServiceRequest.primaryDiagnosis)}
                  error={attemptedSubmit && !VALIDATION_MAP["primaryDiagnosis"]}
                  initialPrimaryDiagnosisCode={initialServiceRequest.primaryDiagnosis}
                  singleService={singleService}
                  primaryDiagnosisCode={formContent?.primaryDiagnosisCode || null}
                  setPrimaryDiagnosisCode={(code) =>
                    setFormContentOnUserEdit({ ...formContent, primaryDiagnosisCode: code })
                  }
                  onCancelChange={() =>
                    setFormContentOnUserEdit({
                      ...formContent,
                      primaryDiagnosisCode: initialServiceRequest.primaryDiagnosis || null,
                    })
                  }
                  onContinuationPatientSummary={!formContent?.isInpatient}
                  disabled={isFieldDisabled("primaryDiagnosis")}
                />
              </Row>
            </SRFormConfigFieldWrapper>
            <SRFormConfigFieldWrapper {...formConfiguration.secondaryDiagnoses}>
              <Row>
                <SecondaryDiagnosisCodeSelect
                  isAutoFilled={
                    !!suggestedPriorAuthRequirements?.secondaryDiagnoses?.length
                      ? compareCodeListEqualityNoOrder(
                          suggestedPriorAuthRequirements.secondaryDiagnoses
                            .map((clientSV) => clientSV.diagnosisCodeEntity)
                            .filter(isNotNullOrUndefined),
                          formContent.secondaryDiagnosisCodes
                        )
                      : false
                  }
                  isCohereFaxForm={Boolean(
                    isCohereFaxForm && (initialServiceRequest?.secondaryDiagnoses?.length || 0) > 0
                  )}
                  error={
                    !doesNotExceedMaxSecondaryDiagnoses || (attemptedSubmit && !VALIDATION_MAP["secondaryDiagnoses"])
                  }
                  secondaryDiagnosisCodes={formContent?.secondaryDiagnosisCodes || []}
                  setSecondaryDiagnosisCodes={(codes) =>
                    setFormContentOnUserEdit({
                      ...formContent,
                      secondaryDiagnosisCodes: codes,
                    })
                  }
                  isOptional={formConfiguration.secondaryDiagnoses.fieldSpec === "OPTIONAL"}
                  disabled={isFieldDisabled("secondaryDiagnoses")}
                />
              </Row>
            </SRFormConfigFieldWrapper>
          </>
        )}
        {formContent.isInpatient && (
          <SRFormConfigFieldWrapper {...formConfiguration.admissionDischargeDate}>
            <Row>
              <H4 style={{ marginTop: spacing(3) }}>Service details</H4>
            </Row>
            <AdmissionAndExpectedDischargeDateSelect
              minAdmissionDate={MIN_START_DATE}
              admissionDate={formContent?.admissionDate}
              expectedDischargeDate={formContent?.expectedDischargeDate}
              expectedAdmissionDate={formContent?.expectedAdmissionDate}
              error={
                (attemptedSubmit && !VALIDATION_MAP["admissionDischargeDate"]) || startDateCoverage
                  ? !startDateCoverage?.inRange
                  : (attemptedSubmit && !formContent?.admissionDate) || endDateCoverage
                  ? !endDateCoverage?.inRange
                  : attemptedSubmit && !formContent?.dischargeDate
              }
              dateCreated={dateCreated}
              startDateCoverage={startDateCoverage}
              endDateCoverage={endDateCoverage}
              isStartDateInRange={isStartDateInRange}
              isEndDateInRange={isEndDateInRange}
              patient={patient || null}
              isDraftAuth={["DRAFT", undefined].includes(formContent.authStatus)}
              showExceededDurationLimitMessageErrorState={false}
              setFormContent={setFormContent}
              setIsStartDateInRange={setIsStartDateInRange}
              setIsEndDateInRange={setIsEndDateInRange}
              formConfiguration={formConfiguration}
              disabled={isFieldDisabled("admissionDischargeDate")}
            />
          </SRFormConfigFieldWrapper>
        )}
        <SRFormConfigFieldWrapper {...formConfiguration.userDeclaredOONException}>
          <Row>
            <Checkbox
              label="This is an out-of-network exception request"
              checked={formContent.userSelectedOONException || false}
              onChange={async (userSelectedOONExceptionValue) => {
                setFormContent((prev) => {
                  return { ...prev, userSelectedOONException: userSelectedOONExceptionValue };
                });
                await doPalCheck(
                  formContent.procedureCodes.concat(nonPalProcedureCodes),
                  userSelectedOONExceptionValue ||
                    formContent.performingProviderOONExceptionRequired ||
                    formContent.facilityOONExceptionRequired ||
                    false
                );
              }}
              disabled={isFieldDisabled("userDeclaredOONException")}
            />
          </Row>
        </SRFormConfigFieldWrapper>
        <SRFormConfigFieldWrapper {...formConfiguration.startEndDate}>
          {
            <>
              <ServiceFrequencySection
                formConfigurations={{
                  units: formConfiguration.units,
                  startEndDate: formConfiguration.startEndDate,
                }}
                units={formContent.units}
                setUnits={(units) => {
                  let procedureCodes = [...formContent.procedureCodes];
                  procedureCodes = formContent.procedureCodes.map((procedureCode) => {
                    return {
                      ...procedureCode,
                      units: Number(units),
                    };
                  });
                  setFormContent({ ...formContent, units: units, procedureCodes: procedureCodes });
                }}
                initialUnits={
                  initialServiceRequest?.units?.toString() || clinicalService?.defaultUnits?.toString() || "1"
                }
                minNumUnits={minNumUnits}
                defaultUnits={clinicalService?.defaultUnits}
                hasValidUnits={validUnits}
                hasUnitsIfApplicable={hasUnitsIfApplicable}
                attemptedSubmit={attemptedSubmit}
                hasValidEndDate={
                  (hasEndDateIfApplicable || formConfiguration.startEndDate.fieldSpec !== "REQUIRED") &&
                  endDateExceedsStartDate &&
                  hasValidEndDate
                }
                startDate={formContent.startDate}
                minStartDate={calculateStartEndDate(authorization, 1)?.startDate}
                maxStartDate={calculateStartEndDate(authorization, 1)?.endDate}
                serviceRequest={serviceRequest}
                setStartDate={(date) => {
                  setFormContentOnUserEdit({
                    ...formContent,
                    startDate: date,
                    admissionDate: formConfiguration.admissionDischargeDate?.fieldSpec !== "OMIT" ? date : undefined,
                  });
                }}
                endDate={formContent.endDate}
                setEndDate={(date) => setFormContentOnUserEdit({ ...formContent, endDate: date })}
                shouldShowEndDate={showEndDate}
                isExpedited={formContent.isExpedited}
                isInpatient={formContent.isInpatient}
                clinicalServiceId={clinicalService?.id}
                serviceRequestId={formContent?.id}
                cohereAuthId={formContent?.cohereId}
                patient={patient || undefined}
                setHasValidCoverageDates={setHasValidCoverageDates}
                onCancelStartDateChange={() =>
                  setFormContentOnUserEdit({
                    ...formContent,
                    startDate: formContent.startDate,
                  })
                }
                onBlurServiceLevelUnits={onBlurServiceLevelUnits}
                setOnBlurServiceLevelUnits={setOnBlurServiceLevelUnits}
                endDateLabel={"End date"}
                startDateLabel={"Start date"}
                previousUnits={authorization?.approvedUnits?.toString()}
                previousUnitsLabel={"Previously approved visits"}
                unitLabel={"Additional visits"}
                isContinuation={true}
                onPatientSummary={onPatientSummary}
                authStatus={currAuthStatus}
                disabled={isFieldDisabled("startEndDate")}
              />
            </>
          }
        </SRFormConfigFieldWrapper>
        <SRFormConfigFieldWrapper {...formConfiguration.procedureCodeWithUnits}>
          <Row style={{ marginTop: spacing(0) }}>
            <ProcedureCodeSelectTableWithUnits
              formContent={formContent}
              setFormContent={setFormContentOnUserEdit}
              patientId={patient?.id}
              clinicalServiceId={clinicalService?.id}
              palProcedureCodes={palPxCodes}
              setPalProcedureCodes={setPalProcedureCodes}
              setNonPalPDFOpen={setNonPalPDFOpen}
              attemptedSubmit={attemptedSubmit}
              onBlurServiceLevelUnits={onBlurServiceLevelUnits}
              nonPalProcedureCodes={nonPalProcedureCodes}
              setNonPalProcedureCodes={setNonPalProcedureCodes}
              nonCohereProcedureCodes={nonCohereProcedureCodes}
              setNonCohereProcedureCodes={setNonCohereProcedureCodes}
              isContinuation={true}
              onPatientSummary={onPatientSummary}
              authorization={authorization || undefined}
              authorizationProcedureCodes={semanticProcedureCodes}
              setSemanticProcedureCodes={setSemanticProcedureCodes}
              requestType={requestType}
              healthPlanName={patientHealthPlanName}
              authStatus={currAuthStatus}
              disabled={isFieldDisabled("procedureCodeWithUnits")}
            />
          </Row>
        </SRFormConfigFieldWrapper>
        <SRFormConfigFieldWrapper {...formConfiguration.procedureCodes}>
          <Row>
            <ProcedureCodeSelectTable
              formContent={formContent}
              authorizationProcedureCodes={semanticProcedureCodes}
              setSemanticProcedureCodes={setSemanticProcedureCodes}
              setFormContent={setFormContentOnUserEdit}
              patientId={patient?.id}
              palProcedureCodes={palPxCodes}
              setPalProcedureCodes={setPalProcedureCodes}
              setNonPalPDFOpen={setNonPalPDFOpen}
              nonPalProcedureCodes={nonPalProcedureCodes}
              setNonPalProcedureCodes={setNonPalProcedureCodes}
              nonCohereProcedureCodes={nonCohereProcedureCodes}
              setNonCohereProcedureCodes={setNonCohereProcedureCodes}
              isContinuation={true}
              onPatientSummary={onPatientSummary}
              authStatus={currAuthStatus}
              requestType={requestType}
              healthPlanName={patientHealthPlanName}
              disabled={isFieldDisabled("procedureCodes")}
            />
          </Row>
        </SRFormConfigFieldWrapper>
        {onPatientSummary && (
          <>
            <Row style={{ marginTop: spacing(3) }}>
              <H4>Provider details</H4>
            </Row>
            <SRFormConfigFieldWrapper {...formConfiguration.orderingProvider}>
              <FlexGridItem container spacing={2}>
                <OrderingProviderSelectManual
                  error={attemptedSubmit && !VALIDATION_MAP["orderingProvider"]}
                  npiError={
                    attemptedSubmit && VALIDATION_MAP["orderingProvider"] && !VALIDATION_MAP["orderingProviderNPI"]
                  }
                  tinError={attemptedSubmit && !VALIDATION_MAP["orderingProviderTIN"]}
                  addressError={attemptedSubmit && !VALIDATION_MAP["orderingProviderAddress"]}
                  isCohereFaxForm={Boolean(isCohereFaxForm && initialServiceRequest.orderingProvider)}
                  formContent={formContent}
                  setFormContent={setFormContent}
                  clinicalService={clinicalService || undefined}
                  patient={patient || undefined}
                  dispatchRedirectRuleCheck={dispatchRedirectRuleCheck("ordering provider")}
                  sameProviders={sameProviders}
                  setSameProviders={setSameProviders}
                  showTinAndAddressField={
                    patientHealthPlanName === "Geisinger"
                      ? formConfiguration.orderingProviderAddress.fieldSpec !== "OMIT"
                      : formConfiguration.orderingProviderAddress.fieldSpec === "REQUIRED"
                  }
                  disabled={isFieldDisabled("orderingProvider")}
                />
                <SRFormConfigFieldWrapper {...formConfiguration.performingProvider}>
                  <Checkbox
                    style={{ marginLeft: spacing(1), marginTop: spacing(-1), marginBottom: spacing(1) }}
                    checked={sameProviders}
                    onChange={(checked) => {
                      sameProviderCheckboxClick(setFormContentOnUserEdit, setSameProviders, checked);
                    }}
                    label="Performing provider is the same as ordering provider"
                    disabled={isFieldDisabled("performingProvider")}
                  />
                </SRFormConfigFieldWrapper>
              </FlexGridItem>
            </SRFormConfigFieldWrapper>
            <SRFormConfigFieldWrapper {...formConfiguration.performingProvider}>
              <FlexGridItem container spacing={2}>
                <PerformingProviderSelectManual
                  providerError={attemptedSubmit && !VALIDATION_MAP["performingProvider"]}
                  npiError={
                    attemptedSubmit && VALIDATION_MAP["performingProvider"] && !VALIDATION_MAP["performingProviderNPI"]
                  }
                  tinError={attemptedSubmit && !VALIDATION_MAP["performingProviderTIN"]}
                  addressError={attemptedSubmit && !VALIDATION_MAP["performingProviderAddress"]}
                  isCohereFaxForm={Boolean(isCohereFaxForm && initialServiceRequest.performingProvider)}
                  formContent={formContent}
                  setFormContent={setFormContent}
                  sameProviders={sameProviders}
                  patient={patient || undefined}
                  setSameProviders={setSameProviders}
                  authStatus={currAuthStatus}
                  isOptional={formConfiguration.performingProvider.fieldSpec === "OPTIONAL"}
                  showAddressField={
                    patientHealthPlanName === "Geisinger"
                      ? formConfiguration.performingProviderAddress.fieldSpec !== "OMIT"
                      : formConfiguration.performingProviderAddress.fieldSpec === "REQUIRED"
                  }
                  disabled={isFieldDisabled("performingProvider")}
                />
              </FlexGridItem>
            </SRFormConfigFieldWrapper>
            <SRFormConfigFieldWrapper {...formConfiguration.facility}>
              <FlexGridItem container spacing={2}>
                <FacilitySelectManual
                  facilityError={attemptedSubmit && !VALIDATION_MAP["facilityNPI"]}
                  tinError={attemptedSubmit && !VALIDATION_MAP["facilityTIN"]}
                  addressError={attemptedSubmit && !VALIDATION_MAP["facilityAddress"]}
                  isCohereFaxForm={Boolean(isCohereFaxForm && initialServiceRequest.facility)}
                  formContent={formContent}
                  setFormContent={setFormContent}
                  isSingleService={singleService}
                  patient={patient || undefined}
                  isOptional={formConfiguration.facility.fieldSpec === "OPTIONAL"}
                  showAddressField={formConfiguration.facilityAddress.fieldSpec === "REQUIRED"}
                  disabled={isFieldDisabled("facility")}
                />
              </FlexGridItem>
            </SRFormConfigFieldWrapper>
          </>
        )}
        {!hideExpeditedRequestCheckbox && (
          <>
            <Row style={{ marginTop: spacing(4) }}>
              <Divider />
            </Row>
            <SRFormConfigFieldWrapper {...formConfiguration.urgency}>
              <Row xs={4} style={{ paddingBottom: 0 }}>
                {formContent.id && (
                  <ExistingRequestExpedited
                    isExpedited={formContent.isExpedited}
                    setIsExpedited={(isExpedited: boolean, reason: string) => {
                      setFormContentOnUserEdit({ ...formContent, isExpedited: isExpedited, expeditedReason: reason });
                      dispatchUrgencyRuleCheck(isExpedited);
                    }}
                    authStatus={currAuthStatus}
                    serviceRequestId={formContent.id}
                    patientId={patient?.id}
                    startDate={formContent.startDate}
                    patientHealthPlanName={patientHealthPlanName || undefined}
                    disabled={isFieldDisabled("urgency")}
                  />
                )}
                {!Boolean(formContent.id) && (
                  <RequestExpedited
                    isExpedited={formContent.isExpedited}
                    setIsExpedited={(isExpedited: boolean, reason: string) => {
                      setFormContentOnUserEdit({ ...formContent, isExpedited: isExpedited, expeditedReason: reason });
                      dispatchUrgencyRuleCheck(isExpedited);
                    }}
                    authStatus={currAuthStatus}
                    startDate={formContent.startDate}
                    patientId={patient?.id}
                    clinicalServiceId={clinicalService?.id}
                    patientHealthPlanName={patientHealthPlanName || undefined}
                    disabled={isFieldDisabled("urgency")}
                  />
                )}
              </Row>
            </SRFormConfigFieldWrapper>
          </>
        )}
      </Grid>
      <ServiceRequestFormRedirectModal
        {...redirectModalProps}
        formContent={formContent}
        setFormContent={setFormContentOnUserEdit}
      />
      <RemoveNonPALModal
        open={nonPalModalOpen}
        onClose={() => setNonPalModalOpen(false)}
        procedureCodes={formContent.procedureCodes}
        setProcedureCodes={(pxs) => setFormContentOnUserEdit({ ...formContent, procedureCodes: pxs })}
        nonPalProcedureCodes={nonPalProcedureCodes}
        setNonPalPDFOpen={setNonPalPDFOpen}
      />
      <Dialog fullScreen open={nonPalPDFOpen} onClose={() => setNonPalPDFOpen(false)}>
        <NonPalPDFProvider
          patient={patient}
          nonPalProcedureCodes={nonPalProcedureCodes}
          coverage={getCurrentPrimaryCoverage(patient || undefined) || undefined}
        >
          {({ url, loading }) => (
            <DocumentViewer
              documentInfo={{
                name: `CohereAuthCheck_${patient?.memberId}.pdf`,
                contentType: "application/pdf",
              }}
              loading={loading}
              url={url || undefined}
              handleClose={() => setNonPalPDFOpen(false)}
              canDelete={false}
              location={location}
              search={search}
            />
          )}
        </NonPalPDFProvider>
      </Dialog>
    </>
  );
}
