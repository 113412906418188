export default function ClipboardIcon({ width = "41", height = "41", viewBox = "0 0 41 41" }) {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.2734 40.2734C31.3191 40.2734 40.2734 31.3191 40.2734 20.2734C40.2734 9.22774 31.3191 0.273437 20.2734 0.273437C9.22774 0.273437 0.273438 9.22774 0.273438 20.2734C0.273438 31.3191 9.22774 40.2734 20.2734 40.2734Z"
        fill="#F4F4F5"
      />
      <path d="M30.0481 9.40527L10.5312 9.40527L10.5313 32.1682L30.0481 32.1682L30.0481 9.40527Z" fill="#B1B4B9" />
      <path
        d="M25.4475 10.7847L11.9141 10.7847L11.9141 30.786L28.6612 30.786L28.6612 14.0065L25.4475 10.7847Z"
        fill="white"
      />
      <path
        d="M24.7098 6.58646L22.1097 6.58646C22.1097 5.43983 21.2942 4.51123 20.2848 4.51123C19.2755 4.51123 18.4599 5.43983 18.4599 6.58646L15.8598 6.58646L15.125 11.1891L25.4365 11.1891L24.7098 6.58646Z"
        fill="#424954"
      />
      <path
        d="M17.0313 13.9597H15.6425V12.5708L14.6896 12.5708V13.9597H13.3008L13.3008 14.9125H14.6896V16.3014H15.6425L15.6425 14.9125L17.0313 14.9125V13.9597Z"
        fill="#EA1D63"
      />
      <path d="M24.6299 17.8437L15.9414 17.8438V18.4978L24.6299 18.4978V17.8437Z" fill="#039EC3" />
      <path d="M27.0539 28.3149H23.5898V28.969H27.0539V28.3149Z" fill="#039EC3" />
      <path d="M27.0529 20.2407L13.5195 20.2407V20.8948L27.0529 20.8948V20.2407Z" fill="#B1B4B9" />
      <path d="M27.0529 22.2026L13.5195 22.2026V22.8567L27.0529 22.8567V22.2026Z" fill="#B1B4B9" />
      <path d="M27.0529 24.167L13.5195 24.167V24.8211L27.0529 24.8211V24.167Z" fill="#B1B4B9" />
      <path d="M19.5676 26.1274L13.5195 26.1274V26.7815L19.5676 26.7815V26.1274Z" fill="#B1B4B9" />
      <path d="M25.4453 14.0065L28.6591 14.0065L25.4453 10.7847V14.0065Z" fill="#F4F4F5" />
    </svg>
  );
}
