import React, { Dispatch, SetStateAction, MutableRefObject, useCallback } from "react";

import {
  Attachment,
  SearchInfo,
  Segment,
  CopyPasteInfo,
  AttachmentGuidelineTextHighlightsSnapshot,
  ServiceRequestResponse,
  AppealResponse,
} from "@coherehealth/core-platform-api";
import { AttachmentViewerConfig } from "./util";
import { AttachmentInfo, AugmentedDocSegmentation } from "./AttachmentViewerSidePanel";
import PdfViewer from "./PdfViewer";
import { UserClickInfoClinicalReviewPage } from "../../util";
import useAttachmentsMultiSearch from "./useAttachmentsMultiSearch";

interface MultiSearchPdfsViewerProps {
  attachments: Attachment[];
  handleAttachmentClick: (index: number, landingPage?: number) => void;
  appealsForAuth?: AppealResponse[];
  attachmentIndexOpen: number;
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
  setSearchInfos?: Dispatch<SetStateAction<SearchInfo[]>>;
  currentPage: MutableRefObject<number>;
  zoomLevel: MutableRefObject<number>;
  currentRotate: MutableRefObject<number>;
  openSidePanel?: boolean;
  setOpenSidePanel?: Dispatch<SetStateAction<boolean>>;
  withDocSegmentedSidePanel?: boolean;
  attachmentsInfo?: AttachmentInfo[];
  setAttachmentsInfo?: Dispatch<SetStateAction<AttachmentInfo[]>>;
  handleSegmentChange?: (
    updatedSegment: Segment,
    isDeletion: boolean,
    updatedPage: number,
    numberOfPages: number
  ) => void;
  savingFeedbackUpdates?: boolean;
  segmentsUpdated?: boolean;
  docSegmentationInfo?: AugmentedDocSegmentation[] | null;
  setCopiedAttachmentText?: Dispatch<SetStateAction<CopyPasteInfo | undefined>>;
  setAttachmentGuidelineTextHighlightsSnapshot?: Dispatch<SetStateAction<AttachmentGuidelineTextHighlightsSnapshot>>;
  previousAttachmentsExpanded?: boolean;
  setPreviousAttachmentsExpanded?: Dispatch<React.SetStateAction<boolean>>;
  isContinuationRequest?: boolean;
  viewerConfig?: AttachmentViewerConfig;
  serviceRequest?: ServiceRequestResponse | null;
  loadingRemainingAttachments?: boolean;
}

export default function MultiSearchPdfsViewer({
  attachments,
  handleAttachmentClick,
  appealsForAuth,
  attachmentIndexOpen,
  setUserClickInfoTracking,
  setSearchInfos,
  currentPage,
  zoomLevel,
  currentRotate,
  openSidePanel,
  setOpenSidePanel,
  withDocSegmentedSidePanel,
  attachmentsInfo,
  setAttachmentsInfo,
  handleSegmentChange,
  savingFeedbackUpdates,
  segmentsUpdated,
  docSegmentationInfo,
  setCopiedAttachmentText,
  setAttachmentGuidelineTextHighlightsSnapshot,
  previousAttachmentsExpanded,
  setPreviousAttachmentsExpanded,
  isContinuationRequest,
  viewerConfig,
  serviceRequest,
  loadingRemainingAttachments,
}: MultiSearchPdfsViewerProps) {
  const attachmentsMultiSearchHolder = useAttachmentsMultiSearch({
    attachmentsInfo,
    handleAttachmentClick,
    loadingRemainingAttachments,
  });

  const isPdfFile = useCallback((file: Attachment, fileInfo: AttachmentInfo) => {
    const splitFileName = file.name?.split(".") || "";
    const fileExtension = splitFileName[splitFileName.length - 1]?.toLowerCase();

    return (
      file.contentType === "application/pdf" ||
      (!file.contentType && fileExtension === "pdf") ||
      fileInfo?.convertedToPDF
    );
  }, []);

  return (
    <div
      id="MULTI_SEARCH_PDFS__WRAPPER"
      style={{
        position: "relative",
        height: !!viewerConfig ? "100vh" : "calc(100vh - 148px)",
      }}
    >
      {attachmentsInfo?.map((attachment, index) => {
        const currentFileUrl = attachment.fileUrl;
        const file = attachments[index];
        if (!isPdfFile(file, attachment) || !currentFileUrl) {
          return null;
        }
        const isCurrentViewedFile = index === attachmentIndexOpen;
        return (
          <div
            key={attachment.attachmentId}
            id={`MULTI_SEARCH_INNER__PDF__${index}__${attachment.attachmentId}`}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              visibility: !isCurrentViewedFile ? "hidden" : undefined,
            }}
          >
            <PdfViewer
              attachmentsMultiSearchHolder={attachmentsMultiSearchHolder}
              currentAttachmentId={attachment.attachmentId}
              fileName={file.name || ""}
              pdfUrl={currentFileUrl}
              attachments={attachments}
              handleAttachmentClick={handleAttachmentClick}
              appealsForAuth={appealsForAuth}
              attachmentIndexOpen={attachmentIndexOpen}
              setUserClickInfoTracking={setUserClickInfoTracking}
              previousAttachmentsExpanded={previousAttachmentsExpanded}
              setPreviousAttachmentsExpanded={setPreviousAttachmentsExpanded}
              setSearchInfos={setSearchInfos}
              currentPage={currentPage}
              zoomLevel={zoomLevel}
              currentRotate={currentRotate}
              crrStyling
              openSidePanel={openSidePanel}
              setOpenSidePanel={setOpenSidePanel}
              withDocSegmentedSidePanel={withDocSegmentedSidePanel}
              attachmentsInfo={attachmentsInfo}
              setAttachmentsInfo={setAttachmentsInfo}
              handleSegmentChange={handleSegmentChange}
              savingFeedbackUpdates={savingFeedbackUpdates}
              segmentsUpdated={segmentsUpdated}
              docSegmentationInfo={docSegmentationInfo}
              setCopiedAttachmentText={setCopiedAttachmentText}
              setAttachmentGuidelineTextHighlightsSnapshot={setAttachmentGuidelineTextHighlightsSnapshot}
              isContinuationRequest={isContinuationRequest}
              viewerConfig={viewerConfig}
              serviceRequest={serviceRequest}
              loadingRemainingAttachments={loadingRemainingAttachments}
            />
          </div>
        );
      })}
    </div>
  );
}
