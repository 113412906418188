import { useContext } from "react";
import { Attachment, ReviewType, useGetLatestAttachmentHighlightsForSR } from "@coherehealth/core-platform-api";
import { useFeature, HighlightStateContext, useEffectDeepEquality } from "@coherehealth/common";
import { useAuthorized } from "authorization";

export default function useFetchAttachmentHighlights(
  currentReview?: ReviewType,
  attachments?: Attachment[],
  hideUserFeedbackHighlights?: boolean
) {
  const isAuthorized = useAuthorized("EDIT_TEXT_HIGHLIGHTS");
  const canUseClinicalReviewRedesign = useAuthorized("USE_CLINICAL_REVIEW_REDESIGN_GENERAL");
  const mlHighlights = useFeature("MLPredictionHighlights");
  const { initializeHighlights, isInitialized } = useContext(HighlightStateContext);
  const { refetch: getAttachmentHighlightsForReview } = useGetLatestAttachmentHighlightsForSR({
    reviewId: currentReview?.id || "",
    lazy: true,
  });

  const initialization: boolean = Boolean(isInitialized && !isInitialized());
  const hasAttachments: boolean = Boolean(attachments?.length);
  const hasGuidlines: boolean = Boolean(currentReview?.guidelines?.length);

  useEffectDeepEquality(() => {
    const fetchHighlights = async () => {
      if (
        isAuthorized &&
        canUseClinicalReviewRedesign &&
        hasGuidlines &&
        hasAttachments &&
        initialization &&
        currentReview
      ) {
        getAttachmentHighlightsForReview().then((latestReviewHighlight) =>
          initializeHighlights?.(
            latestReviewHighlight,
            currentReview?.guidelines!,
            attachments || [],
            currentReview?.serviceRequest?.id || "",
            currentReview.id,
            mlHighlights,
            hideUserFeedbackHighlights ?? false
          )
        );
      }
    };
    fetchHighlights();
  }, [
    initializeHighlights,
    attachments,
    mlHighlights,
    canUseClinicalReviewRedesign,
    getAttachmentHighlightsForReview,
    isAuthorized,
    hideUserFeedbackHighlights,
    currentReview?.guidelines,
    currentReview?.id,
    currentReview?.serviceRequest?.id,
  ]);
}
