import { Dispatch, SetStateAction, useEffect } from "react";

function useChangeFooterHeightIfError(
  elementRef: React.MutableRefObject<HTMLDivElement | null>,
  setFooterHeight: Dispatch<SetStateAction<number>>,
  responsiveMdWidth: boolean,
  showError?: boolean
) {
  useEffect(() => {
    if (elementRef.current) {
      const height = elementRef.current.offsetHeight;
      setFooterHeight(height);
    }
    //footer should only change heights based on displaying an error
    //or the right hand panel resizing, hence these two additional deps
  }, [setFooterHeight, showError, responsiveMdWidth, elementRef]);
}

export default useChangeFooterHeightIfError;
