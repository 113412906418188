import React from "react";

import { SecondaryButton, TertiaryButton, H2, Modal, useStableUniqueId } from "@coherehealth/common";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {
      padding: theme.spacing(0, 1, 4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    title: {
      textAlign: "center",
      padding: theme.spacing(3, 0, 4),
    },
    removeButton: {
      marginBottom: theme.spacing(2),
      // error colors
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
      "&:hover,&:focus": {
        borderColor: theme.palette.error.main,
        // instead of a wide border, we use box shadow to avoid changing the button dimensions
        boxShadow: `0 0 0 1px ${theme.palette.error.main}`,
      },
      "&:active": {
        borderColor: theme.palette.warning.dark,
        boxShadow: `0 0 0 1px ${theme.palette.error.main}`,
      },
    },
  })
);

interface Props {
  open: boolean;
  handleClose: () => void;
  removing: boolean;
  onRemove?: () => void;
}

export default function RemoveAttachmentModal({ open, handleClose, removing, onRemove }: Props) {
  const classes = useStyles();
  const titleId = useStableUniqueId("title");

  return (
    <Modal onClose={handleClose} fullWidth aria-labelledby={titleId} open={open}>
      <div className={classes.modalContent}>
        <H2 className={classes.title} id={titleId}>
          Are you sure you want to remove this attachment from the service request?
        </H2>
        <SecondaryButton
          loading={removing}
          classes={{ outlined: classes.removeButton }}
          warning
          onClick={onRemove}
          disabled={!onRemove}
        >
          Remove attachment
        </SecondaryButton>
        <TertiaryButton onClick={handleClose}>Do not remove</TertiaryButton>
      </div>
    </Modal>
  );
}
