import React, { createContext } from "react";
import {
  FaxNoticeMissingInformation,
  FaxNoticeOutOfScopeInformation,
  ReasonForNoticeOptions,
  FaxSidebarView,
  FaxbackRequestType,
} from "../common";
import { Dialog, DialogProps, makeStyles, Slide } from "@material-ui/core";
import DocumentViewer from "../../index";
import MissingInformationPDFProvider from "./MissingInformationPDF/PDFProvider";
import OutOfScopePDFProvider from "./OutOfScopePDF/PDFProvider";
import { TransitionProps } from "@material-ui/core/transitions";
import replace from "lodash/replace";
import { FaxNoticeInfo } from "@coherehealth/core-platform-api";
import { useBelongsToOpsGroup } from "authorization";
import useSearchAndLocation from "hooks/useSearchAndLocation";

interface Props extends Pick<DialogProps, "open" | "onClose"> {
  faxbackRequestType: FaxbackRequestType | undefined;
  reasonForNotice: ReasonForNoticeOptions | undefined;
  patientName: string;
  dateRequested: string;
  missingInformation?: FaxNoticeMissingInformation | undefined;
  outOfScopeInformation?: FaxNoticeOutOfScopeInformation | undefined;
  setModalOpen: (b: boolean) => void;
  setSidebarView: (view: FaxSidebarView) => void;
}

const useStyles = makeStyles({
  modal: {
    position: "absolute",
    verticalAlign: "center",
    top: 0,
    height: "85%",
    width: "70%",
    transform: "translate(22.5%, 0)",
    "& .MuiBackdrop-root": {
      margin: "-300px",
    },
    "& .MuiDialog-paperFullScreen": {
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
    },
  },
});
export const FaxNoticeReasonsContext = createContext<FaxNoticeInfo | undefined>(undefined);

export default function SendFaxModal({
  faxbackRequestType,
  reasonForNotice,
  patientName,
  dateRequested,
  missingInformation,
  outOfScopeInformation,
  open,
  setModalOpen,
  setSidebarView,
  onClose = () => {},
}: Props) {
  const classes = useStyles();
  const isGhpUser = useBelongsToOpsGroup("GEISINGER");

  const { location, search } = useSearchAndLocation();

  if (!reasonForNotice) {
    return null;
  }
  if (!faxbackRequestType) {
    return null;
  }

  return (
    <Dialog
      fullScreen
      className={classes.modal}
      open={open}
      TransitionComponent={Transition}
      onClose={(e) => onClose(e, "backdropClick")}
    >
      <FaxNoticeReasonsContext.Provider
        value={{
          faxbackRequestType,
          reasonForNotice,
          ...outOfScopeInformation,
          ...missingInformation,
          additionalNotes: outOfScopeInformation?.additionalNotes || missingInformation?.additionalNotes,
        }}
      >
        {reasonForNotice === "MISSING_INFO" ? (
          <MissingInformationPDFProvider
            faxbackRequestType={faxbackRequestType}
            missingInformation={missingInformation}
            patientName={patientName}
            dateRequested={dateRequested}
            isGhpUser={isGhpUser}
          >
            {({ blob, url, loading }) => (
              <DocumentViewer
                documentInfo={{
                  name: `CohereMissingInfo_${formatPatientName(patientName)}.pdf`,
                  contentType: "application/pdf",
                }}
                loading={loading}
                url={url || undefined}
                handleClose={() => setModalOpen(false)}
                canDelete={false}
                faxType="OUTGOING_FAX"
                setFaxSidebarView={setSidebarView}
                blob={blob}
                location={location}
                search={search}
              />
            )}
          </MissingInformationPDFProvider>
        ) : (
          <OutOfScopePDFProvider
            outOfScopeInformation={outOfScopeInformation}
            patientName={patientName}
            dateRequested={dateRequested}
            isGhpUser={isGhpUser}
          >
            {({ blob, url, loading }) => (
              <DocumentViewer
                documentInfo={{
                  name: `CohereOutOfScope_${formatPatientName(patientName)}.pdf`,
                  contentType: "application/pdf",
                }}
                loading={loading}
                url={url || undefined}
                handleClose={() => setModalOpen(false)}
                canDelete={false}
                faxType="OUTGOING_FAX"
                setFaxSidebarView={setSidebarView}
                blob={blob}
                location={location}
                search={search}
              />
            )}
          </OutOfScopePDFProvider>
        )}
      </FaxNoticeReasonsContext.Provider>
    </Dialog>
  );
}

function formatPatientName(name: string) {
  return replace(replace(name, " ", "_"), ".", "");
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  const { timeout, ...otherProps } = { ...props };
  return <Slide direction="down" ref={ref} timeout={500} {...otherProps} unmountOnExit />;
});
