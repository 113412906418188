import { TemporaryPatientFormField } from "@coherehealth/core-platform-api";
import { ContactInfoT, NewPatientFormSectionT, addOptionalLabel, isOptionalField } from "../formOptions";

import { Grid } from "@mui/material";
import { contactInfoGridStyles } from "../addNewPatientStyles";
import { RenderFormSection } from "./RenderFormSection";
import { POLICY_UNITED_STATES_OPTIONS } from "@coherehealth/common";

interface ContactSectionFormState {
  contactInfo: ContactInfoT;
  dispatch: React.Dispatch<any>;
  formOptionalFields?: TemporaryPatientFormField[];
}
export const ContactInfoFormSection = ({ contactInfo, dispatch, formOptionalFields }: ContactSectionFormState) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    dispatch({
      type: "UPDATE_CONTACT_INFO",
      payload: { [fieldName]: fieldValue },
    });
    dispatch({
      type: "UPDATE_FORM_ERROR",
      payload: { [fieldName]: !fieldValue && !isOptionalField(fieldName, formOptionalFields) },
    });
  };
  const handleSelectChange = (fieldName: string, fieldValue?: string | null) => {
    dispatch({
      type: "UPDATE_CONTACT_INFO",
      payload: { [fieldName]: fieldValue },
    });
    dispatch({
      type: "UPDATE_FORM_ERROR",
      payload: { [fieldName]: !fieldValue && !isOptionalField(fieldName, formOptionalFields) },
    });
  };
  const contactSection: NewPatientFormSectionT[] = [
    {
      name: "ADDRESS_LINE_1",
      label: `Address line 1 ${addOptionalLabel("ADDRESS_LINE_1", formOptionalFields)}`,
      input: "text",
      handleInputChange: handleInputChange,
    },
    {
      name: "ADDRESS_LINE_2",
      label: `Address line 2 ${addOptionalLabel("ADDRESS_LINE_2", formOptionalFields)}`,
      input: "text",
      handleInputChange: handleInputChange,
    },
    {
      name: "CITY",
      label: `City ${addOptionalLabel("ADDRESS_LINE_2", formOptionalFields)}`,
      input: "text",
      handleInputChange: handleInputChange,
    },
    {
      name: "STATE",
      label: `State ${addOptionalLabel("STATE", formOptionalFields)}`,
      input: "singleSelect",
      value: contactInfo.STATE ?? null,
      useOptions: () => ({
        optionsLoading: false,
        options: POLICY_UNITED_STATES_OPTIONS.map((state) => state.label),
        filterOptions: (options, state) =>
          options.filter((option) => option.toLowerCase().includes(state.inputValue.toLowerCase())),
      }),
      setSelectedValue: handleSelectChange,
    },
    {
      name: "ZIP_CODE",
      label: `Zip ${addOptionalLabel("ZIP_CODE", formOptionalFields)}`,
      input: "text",
      handleInputChange: handleInputChange,
    },
  ];

  return (
    <>
      <Grid container item spacing={2} xs={12} marginRight={8} data-testid="contact-info-section">
        <RenderFormSection
          section={{
            sectionData: contactInfo,
          }}
          inputs={contactSection}
          inputItemGridStyleProps={contactInfoGridStyles}
        />
      </Grid>
    </>
  );
};
