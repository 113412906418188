import { formatDateToISODate, today, useFeature } from "@coherehealth/common";
import {
  AdditionalInsurance,
  Patient,
  ProcedureCode,
  useGetAuthCategories,
  useGetTransientServiceRequestRuleActions,
} from "@coherehealth/core-platform-api";
import { CohereCard } from "@coherehealth/design-system";
import { makeStyles, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { AuthCategorySelect } from "components/ServiceRequest/ServiceRequestForm/components/AuthCategorySelect";
import { AuthSubCategorySelect } from "components/ServiceRequest/ServiceRequestForm/components/AuthSubCategorySelect";
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from "react";
import { checkOnetimeRequestCoverage, getPatientHealthPlanName, checkMinDate } from "util/patientUtils";
import { PriorAuthRequirements, convertStringToAuthBuilderWorkflowStep } from "../common";
import DiagnosisCodesSection from "./DiagnosisCodesSection";
import EncounterTypeSection from "./EncounterTypeSection";
import ProcedureCodeSection from "./ProcedureCodeSection";
import StartDateSection from "./StartDateSection";
import { useSnackbar } from "notistack";
import ServiceRequestFormRedirectModal from "components/ServiceRequest/ServiceRequestForm/ServiceRequestFormRedirectModal";
import { ValidDisplayMessageAction, isRecommendChangeAction, isRedirectAction } from "util/rule";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";
import { FiredNudgesContext } from "../FiredNudgesContext";
import AdditionalInsuranceSection from "./AdditionalInsuranceSection";
import { useGetOtherInsuranceConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import { useLocation } from "react-router";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";

interface Props {
  priorAuthRequirements: PriorAuthRequirements;
  setPriorAuthRequirements: Dispatch<SetStateAction<PriorAuthRequirements>>;
  attemptedSubmit: boolean;
  resetPalResults: () => void;
  patientData: Patient | null;
  doPalCheck: (currentPriorAuthRequirements: PriorAuthRequirements) => Promise<void>;
  formContent?: ServiceRequestFormContent;
  setFormContent?: Dispatch<SetStateAction<ServiceRequestFormContent>>;
}

interface StyleProps {
  isFaxAuthBuilderFlow: boolean;
}

const useStyles = makeStyles(() => ({
  startDate: {
    //defined responsive design
    flex: (props: StyleProps) => `1 0 ${props.isFaxAuthBuilderFlow ? "208px" : "180px"}`,
    maxWidth: "300px",
  },
  authCategory: {
    //defined responsive design
    flex: (props: StyleProps) => `1 0 ${props.isFaxAuthBuilderFlow ? "160px" : "140px"}`,
    maxWidth: "300px",
  },
  authSubCategory: {
    //defined responsive design
    flex: (props: StyleProps) => `1 0 ${props.isFaxAuthBuilderFlow ? "160px" : "140px"}`,
    maxWidth: "300px",
  },
}));

export default function GeneralAuthSubmissionRequestDetails({
  priorAuthRequirements,
  setPriorAuthRequirements,
  attemptedSubmit,
  resetPalResults,
  patientData,
  doPalCheck,
  formContent,
  setFormContent,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);
  const styleClasses = useStyles({ isFaxAuthBuilderFlow });
  const facilityBasedFlowForOakStreetHealthPlanFF = useFeature("facilityBasedFlowForOakStreetHealthPlan");

  const healthPlanName = useMemo(
    () => getPatientHealthPlanName(patientData || undefined, priorAuthRequirements.startDate) || "",
    [patientData, priorAuthRequirements.startDate]
  );

  const { otherInsuranceEnabled, otherInsurance } = useGetOtherInsuranceConfigurationByPayer({
    healthPlanName: healthPlanName ?? "",
  });

  // Check if the current encounter is delegated to Oak Street Health for a Humana plan.
  // This is true if:
  // 1. The encounter type is "INPATIENT".
  // 2. The health plan name is "Humana".
  // 3. The patient's coverages include a risk-bearing entity named "Oak Street Health".
  const IsDelegatedVendorOakStreetHealth =
    priorAuthRequirements?.encounterType === "INPATIENT" &&
    healthPlanName === "Humana" &&
    patientData?.coverages?.some((coverage) => coverage.riskBearingEntity === "Oak Street Health");

  // Determine if the facility-based flow should be enabled for Oak Street Health.
  // This is true if:
  // 1. The feature flag for facility-based flow for Oak Street Health plans is enabled.
  // 2. The current encounter meets the criteria for Oak Street Health delegation.
  const enableFacilityBasedFlowForOakStreetHealthPlan =
    facilityBasedFlowForOakStreetHealthPlanFF && IsDelegatedVendorOakStreetHealth;

  const { firedRecommendedNudges, setFiredRecommendedNudges } = useContext(FiredNudgesContext);

  const [redirectModalOpen, setRedirectModalOpen] = useState<boolean>(false);
  const [redirectModalAction, setRedirectModalAction] = useState<ValidDisplayMessageAction>();
  const [shouldUpdatePxCodes, setShouldUpdatePxCodes] = useState<boolean>(false);
  const [shouldDoPALCheck, setShouldDoPALCheck] = useState<boolean>(false);
  const [transientSRFormContents, setTransientSRFormContents] = useState<ServiceRequestFormContent>({
    procedureCodes: priorAuthRequirements.desiredProcedureCodes || [],
    isInpatient: priorAuthRequirements.encounterType === "INPATIENT",
    startDate: priorAuthRequirements.startDate || today(),
    isExpedited: false,
    expeditedReason: "",
    units: "",
    approvedUnits: "",
    unitType: "NUMBER_OF_VISITS",
  });

  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName,
    encounterType: priorAuthRequirements.encounterType,
    skipRequestTimingCheck: true,
  });
  const {
    data: authCategoriesData,
    loading: isLoadingAuthCategories,
    error: authCategoriesError,
    refetch: getAuthCategoriesData,
  } = useGetAuthCategories({
    queryParams: {
      healthPlanName,
      // Conditionally add delegatedVendorName if the feature flag
      // 'enableFacilityBasedFlowForOakStreetHealthPlan' is enabled.
      // This adds "Oak Street Health" as the delegated vendor name
      // only when the feature is turned on, ensuring targeted functionality.
      ...(enableFacilityBasedFlowForOakStreetHealthPlan && {
        delegatedVendorName: "Oak Street Health",
      }),
    },
    lazy: true,
  });

  useEffect(() => {
    let isCancelled = false;
    if (authCategoriesError) {
      enqueueSnackbar(`Failed to load authorization categories: ${authCategoriesError.message}`, {
        variant: "error",
      });
    }
    if (!authCategoriesData && facilityBasedFeatureEnabled && priorAuthRequirements.encounterType === "INPATIENT") {
      // Only get auth category data once, if using inpatient and facility based workflow
      getAuthCategoriesData().then((response) => {
        if (!isCancelled) {
          // if only 1 option is returned, then automatically select that
          if (response?.length === 1) {
            setPriorAuthRequirements((prev) => ({ ...prev, authCategory: response[0] || undefined }));
          }
        }
      });
    }

    return () => {
      isCancelled = true; //if the query takes too long and the component unmounts before getting the response
    };
  }, [
    authCategoriesError,
    authCategoriesData,
    facilityBasedFeatureEnabled,
    getAuthCategoriesData,
    priorAuthRequirements.encounterType,
    setPriorAuthRequirements,
    enqueueSnackbar,
  ]);

  const startDateCoverage = useMemo(
    () =>
      patientData?.coverages && priorAuthRequirements.startDate
        ? checkOnetimeRequestCoverage(patientData.coverages, priorAuthRequirements.startDate)
        : undefined,
    [patientData?.coverages, priorAuthRequirements.startDate]
  );

  const minDateCheck = useMemo(
    () => (priorAuthRequirements.startDate ? checkMinDate(priorAuthRequirements.startDate) : undefined),
    [priorAuthRequirements.startDate]
  );

  const theme = useTheme();

  useEffect(() => {
    if (shouldUpdatePxCodes) {
      setPriorAuthRequirements((prev) => ({ ...prev, desiredProcedureCodes: transientSRFormContents.procedureCodes }));
      setShouldUpdatePxCodes(false);
      if (transientSRFormContents.procedureCodes?.length >= 1) {
        setShouldDoPALCheck(true);
      }
    }
  }, [
    transientSRFormContents,
    setPriorAuthRequirements,
    shouldUpdatePxCodes,
    setShouldUpdatePxCodes,
    setShouldDoPALCheck,
  ]);
  useEffect(() => {
    if (shouldDoPALCheck) {
      doPalCheck(priorAuthRequirements);
      setShouldDoPALCheck(false);
    }
  }, [setShouldDoPALCheck, shouldDoPALCheck, priorAuthRequirements, doPalCheck]);

  const displayAuthCategory =
    facilityBasedFeatureEnabled && priorAuthRequirements.encounterType === "INPATIENT" && authCategoriesData
      ? authCategoriesData.length > 0
      : false;

  const subcategoryOptions = priorAuthRequirements.authCategory?.subcategories || [];
  const displaySubcategory = displayAuthCategory && subcategoryOptions.length > 0;

  const handleRedirectModalClose = async (success: boolean) => {
    setRedirectModalOpen(false);
    if (success) {
      setShouldUpdatePxCodes(true);
    }
  };

  const { mutate: getTransientRuleActions } = useGetTransientServiceRequestRuleActions({});

  const runRulesOnPxCodes = async () => {
    if (patientData) {
      const ruleActions = await getTransientRuleActions({
        displayTarget: "PROCEDURE_CODE",
        serviceRequest: {
          patient: patientData,
          ...(!!formatDateToISODate(priorAuthRequirements.startDate)
            ? { startDate: formatDateToISODate(priorAuthRequirements.startDate) }
            : {}),
          encounterType: priorAuthRequirements.encounterType,
          semanticProcedureCodes: priorAuthRequirements.desiredProcedureCodes,
        },
        authStage: convertStringToAuthBuilderWorkflowStep("GATHER_REQUIREMENTS_MEDICAL"),
      });
      const redirect = ruleActions?.find(isRedirectAction);
      const recommendChange = ruleActions?.find(isRecommendChangeAction);
      if (
        (redirect || recommendChange) &&
        priorAuthRequirements.desiredProcedureCodes &&
        priorAuthRequirements.startDate
      ) {
        setTransientSRFormContents({
          procedureCodes: priorAuthRequirements.desiredProcedureCodes,
          isInpatient: priorAuthRequirements.encounterType === "INPATIENT",
          startDate: priorAuthRequirements.startDate,
          isExpedited: false,
          expeditedReason: "",
          units: "",
          approvedUnits: "",
          unitType: "NUMBER_OF_VISITS",
        });
        setRedirectModalOpen(true);
        setRedirectModalAction(redirect || recommendChange);
        if (recommendChange) {
          setFiredRecommendedNudges([...firedRecommendedNudges, recommendChange]);
        }
      }
    }
  };

  const gridStyle = { paddingBottom: theme.spacing(1) };

  const palProcedureCodeCheck = runRulesOnPxCodes;

  const prevDesiredProcedureCodes = useRef<ProcedureCode[] | undefined>();

  useEffect(() => {
    const { desiredProcedureCodes } = priorAuthRequirements;

    if (prevDesiredProcedureCodes.current && desiredProcedureCodes !== prevDesiredProcedureCodes.current) {
      palProcedureCodeCheck();
    }
    prevDesiredProcedureCodes.current = desiredProcedureCodes;
  }, [palProcedureCodeCheck, priorAuthRequirements, priorAuthRequirements.desiredProcedureCodes]);

  const gatherRequirementsCardContent = (
    <Grid container direction="row" spacing={2} alignItems="center">
      <Grid item xs={12}>
        <EncounterTypeSection
          priorAuthRequirements={priorAuthRequirements}
          setPriorAuthRequirements={setPriorAuthRequirements}
          attemptedSubmit={attemptedSubmit}
          resetPalResults={resetPalResults}
          generalAuthSubmissionStyling={true}
          formContent={formContent}
          setFormContent={setFormContent}
        />
      </Grid>
      <Grid
        direction="row"
        container
        item
        spacing={2}
        alignItems="flex-start"
        style={{ paddingBottom: theme.spacing(4) }}
      >
        <Grid item className={styleClasses.startDate}>
          <StartDateSection
            priorAuthRequirements={priorAuthRequirements}
            setPriorAuthRequirements={setPriorAuthRequirements}
            resetPalResults={resetPalResults}
            startDateCoverage={startDateCoverage}
            attemptedSubmit={attemptedSubmit}
            facilityBased={facilityBasedFeatureEnabled}
            generalAuthSubmissionStyling={true}
            formContent={formContent}
            setFormContent={setFormContent}
            minDateCheck={minDateCheck}
          />
        </Grid>
        {displayAuthCategory && (
          <Grid item className={styleClasses.authCategory}>
            <AuthCategorySelect
              selectedAuthCategory={priorAuthRequirements.authCategory?.enumName || null}
              onSelectAuthCategory={(selectedAuthCategoryName) =>
                setPriorAuthRequirements((prev) => ({
                  ...prev,
                  authCategory: authCategoriesData?.find(({ enumName }) => selectedAuthCategoryName === enumName),
                  authSubcategory: undefined, //reset subcategory when category is changed
                }))
              }
              authCategoryOptions={authCategoriesData || []}
              attemptedSubmit={attemptedSubmit}
              isLoading={isLoadingAuthCategories}
              onPatientSummary={false}
            />
          </Grid>
        )}
        {displaySubcategory && (
          <Grid
            item
            className={styleClasses.authSubCategory}
            style={{
              width: `${isFaxAuthBuilderFlow ? "160px" : "140px"}`,
            }}
          >
            <AuthSubCategorySelect
              selectedAuthSubCategory={priorAuthRequirements.authSubcategory ?? null}
              onSelectAuthSubCategory={(selectedSubcategory) => {
                setPriorAuthRequirements({ ...priorAuthRequirements, authSubcategory: selectedSubcategory });
              }}
              authSubCategoryOptions={subcategoryOptions}
              attemptedSubmit={attemptedSubmit}
              isLoading={isLoadingAuthCategories}
              onPatientSummary={false}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: theme.spacing(5) }}>
        <DiagnosisCodesSection
          priorAuthRequirements={priorAuthRequirements}
          setPriorAuthRequirements={setPriorAuthRequirements}
          attemptedSubmit={attemptedSubmit}
          resetPalResults={resetPalResults}
          generalAuthSubmissionStyling={true}
        />
      </Grid>
      <Grid item xs={12} style={gridStyle}>
        <ProcedureCodeSection
          patientData={patientData}
          priorAuthRequirements={priorAuthRequirements}
          setPriorAuthRequirements={setPriorAuthRequirements}
          attemptedSubmit={attemptedSubmit}
          resetPalResults={palProcedureCodeCheck} //TODO: need to remove the object once PA-check-redesign is off and replace with proper function
          generalAuthSubmissionStyling={true}
        />
      </Grid>
      {otherInsuranceEnabled && (
        <Grid item xs={12} style={{ paddingTop: theme.spacing(5) }}>
          <AdditionalInsuranceSection
            healthPlanName={healthPlanName}
            otherInsurance={otherInsurance}
            defaultAdditionalInsurance={
              priorAuthRequirements.additionalInsurance || {
                additionalInsurance: "NONE",
                additionalInsuranceText: "None",
              }
            }
            onChange={function (additionalInsurance: AdditionalInsurance | undefined): void {
              setPriorAuthRequirements({
                ...priorAuthRequirements,
                additionalInsurance,
              });
            }}
          />
        </Grid>
      )}
    </Grid>
  );

  return (
    <>
      <ServiceRequestFormRedirectModal
        open={redirectModalOpen}
        onClose={handleRedirectModalClose}
        redirectRuleAction={redirectModalAction}
        objectType={"after details"}
        formContent={transientSRFormContents}
        setFormContent={setTransientSRFormContents}
        isHardStop={redirectModalAction && redirectModalAction.onAcceptAction === "NONE"}
      />
      {!!isFaxAuthBuilderFlow && <Grid spacing={2}>{gatherRequirementsCardContent}</Grid>}
      {!isFaxAuthBuilderFlow && <CohereCard spacing={2}>{gatherRequirementsCardContent}</CohereCard>}
    </>
  );
}
