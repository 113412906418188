import React, { ComponentProps, useCallback, useEffect, useState } from "react";

import {
  Body1,
  colorsLight,
  TextField,
  DateTextField,
  DATE_FORMAT,
  formatDateToISODate,
  parseDateFromISOStringWithoutFallback,
  InformativeModal,
} from "@coherehealth/common";
import { Patient, usePatientSearch } from "@coherehealth/core-platform-api";
import { DialogProps } from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import isWithinInterval from "date-fns/isWithinInterval";
import parse from "date-fns/parse";
import { IGNORE_ERRORS, error as logError, stringifyError } from "logger";
import { assertIsApiError } from "util/api";
import { AuthorizationTransferModal } from "common/AuthorizationTransfer/AuthorizationTransferModal";

import { useAuthorized } from "authorization";
import { useSnackbar } from "notistack";
import { useGetTemporaryMemberAuthsConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import PatientSearchResult from "common/UnifiedPatientSearchModal/PatientSearchResult";

export interface AuthorizationTransferProps {
  displayAuthorizationTransfer?: boolean;
  authorizationId?: string;
}

interface Props extends Pick<DialogProps, "open" | "onClose"> {
  authorizationTransfer?: AuthorizationTransferProps;
  healthPlanName?: string;
}

const useModalStyles = makeStyles((theme) => ({
  customContent: {
    minWidth: 600,
    padding: theme.spacing(3, 7),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  customSubHeaderElement: {
    "&.MuiTypography-subtitle2": {
      paddingTop: `${theme.spacing(0)} !important`,
    },
  },
  primaryButton: {
    width: "33%",
    marginTop: theme.spacing(4),
    padding: theme.spacing(2, 5),
    minWidth: theme.spacing(8),
  },
  patientLookupSubHeader: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1, 0),
  },
  customMUIDialogContent: {
    background: colorsLight.background.light,
    padding: theme.spacing(3, 7),
  },
}));

const isDobInValidRange = (dateOfBirth: string) => {
  const date = parseDateFromISOStringWithoutFallback(dateOfBirth);
  return date && isWithinInterval(date, { start: new Date(1900, 1, 1), end: new Date(2100, 1, 1) });
};

const PatientSearchModal = (props: Props) => {
  const { authorizationTransfer, healthPlanName, open, onClose = () => {} } = props;
  const [showResults, setShowResults] = useState<boolean>(false);
  const [patients, setPatients] = useState<Patient[]>([]);
  const [memberId, setMemberId] = useState<string>("");
  const [dobStr, setDobStr] = useState<string>("");
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [openTransferAuthModal, setOpenTransferAuthModal] = useState<boolean>(false);
  const [receiverPatientId, setReceiverPatientId] = useState<string>();
  const { data: temporaryMemberAuthsConfig, error: temporaryMemberAuthsConfigError } =
    useGetTemporaryMemberAuthsConfigurationByPayer(healthPlanName ?? "");
  // The dashboard page is not limited to a single Health Plan for some users (it depends on the
  // opsGroup) so we need validate the visibility by Payor Config in pages that are limited to
  // a single Payor and by feature flag only for the multi-payor pages.
  const temporaryMemberAuthsEnabled = temporaryMemberAuthsConfig?.enabled || !healthPlanName;
  const canAddPlaceholderMember = useAuthorized("CREATE_PLACEHOLDER_PATIENT");
  // This validates the dobStr, if it is an invalid date this value will be an empty string
  const dateOfBirth = formatDateToISODate(parse(dobStr, DATE_FORMAT, new Date()));

  const { mutate: patientSearch, loading, error } = usePatientSearch({});

  const { enqueueSnackbar } = useSnackbar();

  const classes = useModalStyles();

  const onTransferAuth = useCallback(
    (event: React.MouseEvent, receiverPatientId: string): void => {
      event.stopPropagation();
      setOpenTransferAuthModal(true);
      onClose(event, "backdropClick");
      setReceiverPatientId(receiverPatientId);
    },
    [onClose, setOpenTransferAuthModal, setReceiverPatientId]
  );

  useEffect(() => {
    if (!open) {
      setShowResults(false);
      setMemberId("");
      setDobStr("");
      setAttemptedSubmit(false);
    }
  }, [open]);

  useEffect(() => {
    if (temporaryMemberAuthsConfigError) {
      const errorMsg = "Error fetching temporaryMemberAuths configuration";
      if (!IGNORE_ERRORS.includes(temporaryMemberAuthsConfigError.message)) {
        enqueueSnackbar(errorMsg, { preventDuplicate: true });
        logError(`${errorMsg} ${stringifyError(temporaryMemberAuthsConfigError.data)}`);
      }
    }
  }, [temporaryMemberAuthsConfigError, enqueueSnackbar]);

  return (
    <>
      <InformativeModal
        open={open}
        headerText={"Patient search"}
        subHeaderElement={
          <Grid container spacing={2} component="form">
            <Row data-public>
              <Body1 className={classes.patientLookupSubHeader}>Find a patient: all information required.</Body1>
            </Row>
            <Row>
              <TextField
                error={attemptedSubmit && !memberId}
                helperText={attemptedSubmit && !memberId ? "Required" : ""}
                fullWidth
                label="Health plan member ID"
                value={memberId}
                onChangeValue={setMemberId}
                onClick={(event) => event.stopPropagation()}
              />
            </Row>
            <Row>
              <DateTextField
                error={attemptedSubmit && !isDobInValidRange(dateOfBirth)}
                helperText={attemptedSubmit && !isDobInValidRange(dateOfBirth) ? "Must have a valid date" : ""}
                fullWidth
                addDatePatternToLabel
                label={`Member date of birth`}
                value={dobStr}
                onChangeValue={setDobStr}
                onClick={(event) => event.stopPropagation()}
              />
            </Row>
          </Grid>
        }
        noSubHeaderElementPadding
        primaryButtonText={"Search"}
        primaryButtonLoading={loading}
        primaryButtonAction={async (event?: MouseEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          event?.stopPropagation();
          setAttemptedSubmit(true);
          if (Boolean(memberId) && isDobInValidRange(dateOfBirth)) {
            try {
              setPatients(await patientSearch({ memberId, dateOfBirth }));
              setShowResults(true);
            } catch (e) {
              assertIsApiError(e);
              setShowResults(true);
              if (!IGNORE_ERRORS.includes(e.message ?? "")) {
                logError(e);
              }
            }
          }
        }}
        customButtonStyle={classes.primaryButton}
        tertiaryButtonText={"Add patient with a temporary ID"}
        tertiaryButtonAction={() => {
          window.location.assign("/add_new_patient");
        }}
        tertiaryButtonDisabled={!canAddPlaceholderMember && !temporaryMemberAuthsEnabled}
        showDivider={false}
        customContentStyle={classes.customContent}
        customDialogResults={
          <>
            {showResults && !loading ? (
              <>
                <Divider />
                <MuiDialogContent className={classes.customMUIDialogContent}>
                  <PatientSearchResult
                    authorizationTransfer={authorizationTransfer}
                    error={error}
                    onTransferAuth={onTransferAuth}
                    patients={patients}
                    showSummaryLink
                  />
                </MuiDialogContent>
              </>
            ) : null}
          </>
        }
        onClose={onClose}
      />
      {receiverPatientId && (
        <AuthorizationTransferModal
          authorizationId={authorizationTransfer?.authorizationId}
          open={openTransferAuthModal}
          receiverPatientId={receiverPatientId}
          setOpen={setOpenTransferAuthModal}
        />
      )}
    </>
  );
};

export default PatientSearchModal;

const Row = (props: ComponentProps<typeof Grid>) => <Grid style={{ textAlign: "center" }} item xs={12} {...props} />;
