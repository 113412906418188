import { H1, useFeature } from "@coherehealth/common";
import Grid from "@material-ui/core/Grid";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";

import UserInfoForm, { Props as UserInfoFormProps } from "./UserInfoForm";
import OrganizationInfoForm, { Props as OrganizationInfoFormProps } from "./OrganizationInfoForm";
import HeaderAndProgressBar from "./HeaderAndProgressBar";
import React from "react";
import SpecialtiesMultiSelect from "./SpecialtiesMultiSelect";

interface Props extends UserInfoFormProps, OrganizationInfoFormProps {
  isAvailityUser: boolean;
}

export default function UserOrgInfoPage({
  organizationInfo,
  setOrganizationInfo,
  orgNameIsDuplicate,
  isAvailityUser,
  ...userInfoFormProps
}: Props) {
  const onboardingRedesign = useFeature("onboardingRedesign");
  const setSpecialties = (specialties: string[]) => {
    userInfoFormProps.setUserInfo((prev) => ({ ...prev, userSpecialties: specialties }));
  };

  return (
    <Container>
      <HeaderAndProgressBar stepNumber={2} isAvailityUser={isAvailityUser} />
      <Title>Share more about you and your organization</Title>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <UserInfoForm {...userInfoFormProps} />
        </Grid>
        {onboardingRedesign && (
          <Grid item xs={12}>
            <SpecialtiesMultiSelect
              questionTitle={
                "Which specialties do you personally submit auths for (to any vendor or payer, not just Cohere)?"
              }
              admin
              setSpecialties={setSpecialties}
              highlightInfoFormErrors={userInfoFormProps.highlightInfoFormErrors}
              specialties={userInfoFormProps.userInfo.userSpecialties || []}
              userInfo={userInfoFormProps.userInfo}
              setUserInfo={userInfoFormProps.setUserInfo}
              orgSpecialties={organizationInfo.organizationSpecialties || []}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <OrganizationInfoForm
            organizationInfo={organizationInfo}
            setOrganizationInfo={setOrganizationInfo}
            orgNameIsDuplicate={orgNameIsDuplicate}
            {...userInfoFormProps}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Title = styled(H1)(({ theme }) => ({
  margin: theme.spacing(3, 0, 6),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Container = styled("div")({ paddingBottom: 200 });
