import React, { useState, Dispatch, SetStateAction } from "react";
import { Tab, makeStyles } from "@material-ui/core";
import { StyledTabs } from "../Review/ClinicalReviewPage";
import {
  ServiceRequestResponse,
  AuthorizationResponse,
  Patient,
  FaxAuditReview,
  CopyPasteInfo,
  SearchInfo,
} from "@coherehealth/core-platform-api";
import ClinicalReviewInfoPanel from "../ClinicalReviewInfoPanel";
import { UserClickInfoClinicalReviewPage, colorsLight } from "@coherehealth/common";
import { tabProps } from "../reviewUtils/utils";
import useOtherAuthorizations from "../ClinicalReviewInfoPanel/OtherServiceRequests/useOtherAuthorization";
import AuditReviewComponent from "components/ServiceRequest/ReviewSection/AuditReview/AuditReview";
import { useReview } from "../reviewUtils/useReviews";

type RightPanelTabs = "REQUEST_INFORMATION" | "FAX_AUDIT";

interface FaxAuditPageSidebarProps {
  serviceRequest: ServiceRequestResponse;
  review: FaxAuditReview;
  authorization: AuthorizationResponse;
  userClickInfoTracking?: UserClickInfoClinicalReviewPage;
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
  patient: Patient | null;
  patientLoading: boolean;
  refreshAuthorization: () => Promise<void>;
  serviceRequestRefetch: () => Promise<ServiceRequestResponse | null>;
  serviceRequestLoading: boolean;
  searchInfos: SearchInfo[];
  setSearchInfos: Dispatch<React.SetStateAction<SearchInfo[]>>;
  setPastedAttachmentTexts: Dispatch<React.SetStateAction<CopyPasteInfo[] | undefined>>;
  copiedAttachmentText?: CopyPasteInfo;
  pastedAttachmentTexts?: CopyPasteInfo[];
}

const FaxAuditPageSidebar = ({
  serviceRequest,
  review,
  authorization,
  userClickInfoTracking,
  setUserClickInfoTracking,
  patient,
  patientLoading,
  refreshAuthorization,
  serviceRequestRefetch,
  serviceRequestLoading,
  searchInfos,
  setSearchInfos,
  setPastedAttachmentTexts,
  copiedAttachmentText,
  pastedAttachmentTexts,
}: FaxAuditPageSidebarProps) => {
  const [rightColumnIndex, setRightColumnIndex] = useState<RightPanelTabs>("REQUEST_INFORMATION");
  const [currentReview] = useReview({ reviewId: review.id, reviewType: "FaxAuditReview" });
  const classes = useStyles();
  const useOtherAuthorizationProps = useOtherAuthorizations({ serviceRequest });
  return (
    <>
      <StyledTabs
        value={rightColumnIndex}
        onChange={(_, index) => {
          setRightColumnIndex(index);
        }}
        aria-label="Clinical Review tabs"
        style={{ margin: "0px", position: "sticky", top: 0, backgroundColor: "white", zIndex: 2 }}
      >
        <Tab
          label={"Service Request Details"}
          {...tabProps("REQUEST_INFORMATION")}
          id="request-details-tab"
          className={classes.panelTab}
        />
        <Tab label={"Fax Audit"} {...tabProps("FAX_AUDIT")} id="fax-audit-tab" className={classes.panelTab} />
      </StyledTabs>
      <div
        style={{
          minHeight: "100%",
          padding: rightColumnIndex === "REQUEST_INFORMATION" ? "0 16px" : undefined,
          backgroundColor: rightColumnIndex === "REQUEST_INFORMATION" ? colorsLight.background.mid : undefined,
        }}
      >
        {rightColumnIndex === "REQUEST_INFORMATION" && (
          <ClinicalReviewInfoPanel
            id="tabpanel-REQUEST_INFORMATION"
            patient={patient}
            patientLoading={patientLoading}
            userClickInfoTracking={userClickInfoTracking}
            setUserClickInfoTracking={setUserClickInfoTracking}
            authorization={authorization}
            refreshAuthorization={refreshAuthorization}
            serviceRequest={serviceRequest}
            serviceRequestLoading={serviceRequestLoading}
            serviceRequestRefetch={serviceRequestRefetch}
            existingReviews={null}
            outreachOpportunities={null}
            otherServiceRequests={null}
            otherServiceRequestsLoading={false}
            serviceRequestId={serviceRequest.id}
            {...useOtherAuthorizationProps}
            faxAudit
          />
        )}
        {rightColumnIndex === "FAX_AUDIT" && currentReview && (
          <AuditReviewComponent
            serviceRequest={serviceRequest}
            auditReview={currentReview}
            expanded
            userClickInfoTracking={userClickInfoTracking}
            setUserClickInfoTracking={setUserClickInfoTracking}
            searchInfos={searchInfos}
            setSearchInfos={setSearchInfos}
            copiedAttachmentText={copiedAttachmentText}
            pastedAttachmentTexts={pastedAttachmentTexts}
            setPastedAttachmentTexts={setPastedAttachmentTexts}
          />
        )}
      </div>
    </>
  );
};

const useStyles = makeStyles(() => ({
  panelTab: {
    "&:hover span": {
      opacity: 0.7,
    },
    whiteSpace: "nowrap",
  },
}));

export default FaxAuditPageSidebar;
