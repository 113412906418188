import Collapse from "@material-ui/core/Collapse";
import { Box, Divider, useTheme } from "@material-ui/core";
import { Body2, Caption, H4 } from "@coherehealth/design-system";
import { Body1, formatDateStr, formatDateStrAsEastern, InlineButton } from "@coherehealth/common";
import { ReferralRequestResponse, RequestorChannel } from "@coherehealth/core-platform-api";
import React, { useState } from "react";
import ContactInfoModal, { ContactInfoModalProps, RequestorInfoModal } from "../../ContactInfoModal";
import ReferralRequestAuditHistory from "./ReferralRequestAuditHistory";
import { useAuthorized } from "../../../authorization";
import Grid from "@material-ui/core/Grid";
import { DetailsField, getDetailFields, useDetailsFieldStyles } from "util/referralRequest";
import ReferralRequestIntegrationSummary from "../../IntegrationManagement/ReferralRequestIntegrationSummary";
import { LocationNetworkStatusPill } from "components/ServiceRequest/utils/serviceRequestUtils";
import {
  ReferralFormConfiguration,
  useGetReferralRequestFormConfiguration,
} from "../FormContentSpecification/ReferralRequestFormContentSpecifications";
import AttachmentSection from "components/Attachments/AttachmentSection";

interface Props {
  expanded: boolean;
  referralRequest: ReferralRequestResponse;
  onEditIntegration: (id: string) => void;
}

export default function ReferralRequestSummaryDetails({ expanded, referralRequest, onEditIntegration }: Props) {
  const {
    startDate,
    endDate,
    primarySemanticDiagnosisCode,
    secondarySemanticDiagnosisCodes,
    selectedReferringProvider,
    selectedPerformingSpecialist,
    selectedFacility,
    requestor,
    specialty,
  } = referralRequest;

  const requestorName =
    requestor?.firstName && requestor.lastName ? requestor?.firstName + " " + requestor?.lastName : undefined;
  const detailFields = getDetailFields();
  const canShowHistory = useAuthorized("VIEW_SERVICE_REQUEST_AUDIT_LOG");
  const canViewReferralRequestIntegration = useAuthorized("VIEW_PATIENT_SUMMARY_INTEGRATIONS_UI");

  const [showHistory, setShowHistory] = useState<boolean>(false);
  const closeModal = () => setModalState((prev) => ({ ...prev, open: false }));
  const [modalState, setModalState] = useState<ContactInfoModalProps>({
    open: false,
    handleClose: () => {
      setModalState((prev) => ({ ...prev, open: false }));
    },
    type: "FACILITY",
  });
  const [showRequestorModal, setShowRequestorModal] = useState<boolean>(false);

  const formConfiguration: ReferralFormConfiguration | null = useGetReferralRequestFormConfiguration(
    referralRequest.patient ?? null,
    referralRequest?.isAdministrative || false
  );

  const channelDisplayName: Record<RequestorChannel, string> = {
    FAX: "Fax",
    PHONE: "Phone",
    EMAIL: "Email",
    MAIL: "Mail",
    PORTAL: "Portal",
    INTERNAL_API_REQUEST: "Internal Request",
    PORTAL_EHR: "Portal - EHR",
  };

  const modeOfSubmission = channelDisplayName[requestor?.channel || "PORTAL"];

  // Specialist practice related fields
  const facilityOutOfNetworkStatusDisplayOff =
    formConfiguration?.facilityOutOfNetworkStatusDisplay?.fieldSpec === "OMIT";
  const facilityIsOutOfNetwork = selectedFacility?.selectedLocation?.isOutOfNetwork;
  const oonExceptionFacilityReason = selectedFacility?.selectedLocation?.outOfNetworkExceptionReason;
  const oonExceptionFacilityComment = selectedFacility?.selectedLocation?.outOfNetworkExceptionComment;

  // PerformingSpecialist related fields
  const selectedPerformingSpecialistOutOfNetworkStatusDisplayOff =
    formConfiguration?.performingSpecialistOutOfNetworkStatusDisplay?.fieldSpec === "OMIT";
  const selectedPerformingSpecialistIsOutOfNetwork = selectedPerformingSpecialist?.selectedLocation?.isOutOfNetwork;
  const oonExceptionSelectedPerformingSpecialistReason =
    selectedPerformingSpecialist?.selectedLocation?.outOfNetworkExceptionReason;
  const oonExceptionSelectedPerformingSpecialistComment =
    selectedPerformingSpecialist?.selectedLocation?.outOfNetworkExceptionComment;

  const classes = useDetailsFieldStyles();
  const theme = useTheme();

  return (
    <>
      <Collapse in={expanded} timeout={"auto"} unmountOnExit>
        <Divider className={classes.divider} />
        <H4 className={classes.cardHeader}>Details</H4>
        {detailFields.includes("DATES_VALID") && (
          <DetailsField label={"Dates valid"} value={formatDateStr(startDate) + " - " + formatDateStr(endDate)} />
        )}
        {detailFields.includes("PRIMARY_DIAGNOSIS") && (
          <DetailsField
            label={"Primary diagnosis"}
            value={
              primarySemanticDiagnosisCode
                ? primarySemanticDiagnosisCode.code + " - " + primarySemanticDiagnosisCode?.description
                : "-"
            }
          />
        )}
        {detailFields.includes("SECONDARY_DIAGNOSIS") && (
          <DetailsField
            label={"Secondary diagnosis"}
            value={
              secondarySemanticDiagnosisCodes && secondarySemanticDiagnosisCodes?.length !== 0
                ? secondarySemanticDiagnosisCodes?.map((d) => d.code).join(", ")
                : "-"
            }
          />
        )}
        {detailFields.includes("REFERRING_PROVIDER") && (
          <DetailsField
            label={"Referring provider"}
            bodyChildren={
              <>
                {selectedReferringProvider ? (
                  <>
                    <Body1 className={classes.bodyChildrenContainer} data-public>
                      <span>{selectedReferringProvider?.name?.concat(" / ")}</span>
                      <span>{`NPI - ${selectedReferringProvider?.npi || "N/A"}`}</span>
                    </Body1>
                  </>
                ) : undefined}
              </>
            }
          >
            <InlineButton
              onClick={() => {
                setModalState({
                  open: true,
                  name: selectedReferringProvider?.name || "None",
                  phones: selectedReferringProvider?.selectedLocation?.phoneNumbers,
                  tinList: [
                    referralRequest.healthPlanName === "BCBS South Carolina"
                      ? "--"
                      : selectedReferringProvider?.selectedLocation?.tin || "None",
                  ],
                  npi: selectedReferringProvider?.npi,
                  addresses: [selectedReferringProvider?.selectedLocation?.address],
                  type: "REFERRING_PROVIDER",
                  npiLabel: selectedReferringProvider?.npi || "N/A",
                  handleClose: closeModal,
                });
              }}
              className={classes.viewContactInfo}
              id={"referringProviderViewInfoBtn"}
            >
              View info
            </InlineButton>
          </DetailsField>
        )}
        {detailFields.includes("SPECIALTY") && <DetailsField label={"Specialty"} value={specialty} />}
        {detailFields.includes("SPECIALIST") && (
          <DetailsField
            label={"Specialist"}
            subTextChildren={
              <>
                {oonExceptionSelectedPerformingSpecialistReason && (
                  <Caption className={classes.outOfNetworkExceptionReasonStyle} data-public>
                    Exception reason: {oonExceptionSelectedPerformingSpecialistReason}
                  </Caption>
                )}
                {oonExceptionSelectedPerformingSpecialistComment && (
                  <Caption className={classes.labelContainer} data-public>
                    Comment: {oonExceptionSelectedPerformingSpecialistComment}
                  </Caption>
                )}
              </>
            }
            bodyChildren={
              <>
                {selectedPerformingSpecialist ? (
                  <>
                    <Body1 className={classes.bodyChildrenContainer} data-public>
                      <span>{selectedPerformingSpecialist?.name?.concat(" / ")}</span>
                      <span>{`NPI - ${selectedPerformingSpecialist?.npi || "N/A"}`}</span>
                    </Body1>
                  </>
                ) : (
                  <Body1>--</Body1>
                )}
              </>
            }
          >
            {selectedPerformingSpecialist ? (
              <>
                <InlineButton
                  className={classes.viewContactInfo}
                  onClick={() => {
                    setModalState({
                      open: true,
                      name: selectedPerformingSpecialist?.name || "None",
                      phones: selectedPerformingSpecialist?.selectedLocation?.phoneNumbers,
                      type: "SPECIALIST",
                      tinList: [
                        referralRequest.healthPlanName === "BCBS South Carolina"
                          ? "--"
                          : selectedPerformingSpecialist?.selectedLocation?.tin || "None",
                      ],
                      npi: selectedPerformingSpecialist?.npi,
                      addresses: [selectedPerformingSpecialist?.selectedLocation?.address],
                      npiLabel: selectedPerformingSpecialist?.npi || "N/A",
                      handleClose: closeModal,
                    });
                  }}
                  id={"specialistViewInfoBtn"}
                >
                  View info
                </InlineButton>
                <LocationNetworkStatusPill
                  location={selectedPerformingSpecialist?.selectedLocation ?? undefined}
                  config={{
                    outOfNetworkStatusDisplayOff: selectedPerformingSpecialistOutOfNetworkStatusDisplayOff,
                    locationIsOutOfNetwork: selectedPerformingSpecialistIsOutOfNetwork,
                    style: { padding: theme.spacing(0.625, 1, 0.5, 1) },
                  }}
                />
              </>
            ) : (
              <></>
            )}
          </DetailsField>
        )}
        {detailFields.includes("SPECIALIST_PRACTICE") && (
          <DetailsField
            label={"Specialist practice"}
            subTextChildren={
              <>
                {oonExceptionFacilityReason && (
                  <Caption className={classes.outOfNetworkExceptionReasonStyle} data-public>
                    Exception reason: {oonExceptionFacilityReason}
                  </Caption>
                )}
                {oonExceptionFacilityComment && (
                  <Caption className={classes.labelContainer} data-public>
                    Comment: {oonExceptionFacilityComment}
                  </Caption>
                )}
              </>
            }
            bodyChildren={
              <>
                {selectedFacility ? (
                  <>
                    <Body1 className={classes.bodyChildrenContainer} data-public>
                      <span>{selectedFacility?.name?.concat(" / ")}</span>
                      <span>{`NPI - ${selectedFacility?.npi || "N/A"}`}</span>
                    </Body1>
                  </>
                ) : (
                  <Body1>--</Body1>
                )}
              </>
            }
          >
            <>
              <InlineButton
                className={classes.viewContactInfo}
                onClick={() => {
                  setModalState({
                    open: true,
                    name: selectedFacility?.name || "None",
                    phones: selectedFacility?.selectedLocation?.phoneNumbers,
                    type: "SPECIALIST_PRACTICE",
                    tinList: [
                      referralRequest.healthPlanName === "BCBS South Carolina"
                        ? "--"
                        : selectedFacility?.selectedLocation?.tin || "None",
                    ],
                    npi: selectedFacility?.npi,
                    addresses: [selectedFacility?.selectedLocation?.address],
                    npiLabel: selectedFacility?.npi || "N/A",
                    handleClose: closeModal,
                  });
                }}
                id={"specialistPracticeViewInfoBtn"}
              >
                View info
              </InlineButton>
              <LocationNetworkStatusPill
                location={selectedFacility?.selectedLocation ?? undefined}
                config={{
                  outOfNetworkStatusDisplayOff: facilityOutOfNetworkStatusDisplayOff,
                  locationIsOutOfNetwork: facilityIsOutOfNetwork,
                  style: { padding: theme.spacing(0.625, 1, 0.5, 1) },
                }}
              />
            </>
          </DetailsField>
        )}
        <Divider className={classes.divider} style={{ marginTop: theme.spacing(2) }} />
        <AttachmentSection referralRequest={referralRequest} />
        <Divider className={classes.divider} style={{ marginTop: theme.spacing(2) }} />
        {canViewReferralRequestIntegration && (
          <>
            <ReferralRequestIntegrationSummary referralRequest={referralRequest} onEdit={onEditIntegration} />
          </>
        )}
        {canShowHistory && (
          <Grid item xs={12} style={{ marginTop: theme.spacing(3) }}>
            <InlineButton
              title="Toggle audit history"
              onClick={() => {
                if (canShowHistory) {
                  setShowHistory(!showHistory);
                }
              }}
            >
              <Caption color="primary" style={{ fontWeight: 600, fontSize: "13px" }}>
                {"Submitted " + formatDateStrAsEastern(referralRequest.intakeTimestamp)}
              </Caption>
            </InlineButton>
          </Grid>
        )}
        {canShowHistory && showHistory && <ReferralRequestAuditHistory referralRequest={referralRequest} />}
        {(canShowHistory || canViewReferralRequestIntegration) && (
          <Divider className={classes.divider} style={{ marginTop: theme.spacing(2) }} />
        )}
        <Box style={{ display: "flex" }}>
          <Caption className={classes.footerText}>
            Requested by {requestorName || "Unknown"} - {modeOfSubmission}
          </Caption>
          <InlineButton
            onClick={() => {
              setShowRequestorModal(true);
            }}
            className={classes.viewInfoButton}
            id={"requestorViewInfoBtn"}
          >
            <Body2 style={{ fontSize: theme.spacing(1.625) }}>View info</Body2>
          </InlineButton>
        </Box>
      </Collapse>
      <ContactInfoModal
        open={modalState.open}
        handleClose={modalState.handleClose}
        type={modalState.type}
        name={modalState.name}
        phones={modalState.phones}
        fax={modalState.fax}
        email={modalState.email}
        npi={modalState.npi}
        tinList={modalState.tinList}
        addresses={modalState.addresses}
        npiLabel={modalState.npiLabel}
      />
      <RequestorInfoModal
        open={showRequestorModal}
        handleClose={() => {
          setShowRequestorModal(false);
        }}
        type={"REQUESTOR"}
        name={requestorName || "--"}
        phones={[requestor?.phoneNumber]}
        email={requestor?.user?.email || "--"}
        fax={[requestor?.faxNumber]}
        addresses={[requestor?.aorAddress]}
      />
    </>
  );
}
