import {
  Attachment,
  useDeleteServiceRequest,
  useDeleteServiceRequestAttachment,
} from "@coherehealth/core-platform-api";
import { useNavigate } from "react-router";

export default function useDeleteDraftSrAndRedirect(
  redirectPath: string,
  serviceRequestId: string,
  serviceRequestAttachments?: Attachment[]
) {
  const navigate = useNavigate();
  const {
    mutate: deleteServiceRequest,
    loading: deleteServiceRequestLoading,
    error: deleteError,
  } = useDeleteServiceRequest({});

  const {
    mutate: deleteAttachment,
    loading: deleteAttachmentLoading,
    error: deleteAttachmentError,
  } = useDeleteServiceRequestAttachment({
    id: "",
  });

  const deleteAndNavigateCallback = async () => {
    //delete service request attachments if they exist
    if (serviceRequestId && serviceRequestAttachments && serviceRequestAttachments.length > 0) {
      await Promise.all(
        serviceRequestAttachments.map((file) =>
          deleteAttachment(file.id, {
            pathParams: { id: serviceRequestId },
          })
        )
      );
    }

    //delete service request if we have an id to reference
    if (Boolean(serviceRequestId)) {
      await deleteServiceRequest(serviceRequestId);
    }

    navigate(redirectPath);
  };

  return {
    deleteServiceRequestLoading,
    deleteError,
    deleteAttachmentLoading,
    deleteAttachmentError,
    deleteAndNavigateCallback,
  };
}
