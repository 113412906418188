import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  Body1,
  Caption,
  colorsDark,
  colorsLight,
  formatDateStr,
  InlineButton,
  PrimaryButton,
} from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Coverage, Patient, ProcedureCode } from "@coherehealth/core-platform-api";
import { useTheme } from "@material-ui/core/styles";
import NonPalPDFProvider from "components/ServiceRequest/ServiceRequestForm/NonPalDocument/NonPalPDFProvider";
import { Dialog } from "@material-ui/core";
import DocumentViewer from "components/DocumentViewer";
import useSearchAndLocation from "hooks/useSearchAndLocation";

interface Props {
  diagnosisCode?: string;
  procedureCodes?: ProcedureCode[];
  orderingProvider?: string;
  serviceStartDate?: string;
  patient?: Patient;
  activeCoverage?: Coverage;
  authNeeded?: boolean;
  onImportServiceRequest?: () => void;
  onDownloadPdf?: () => void;
}

const EMPTY_VALUE_DISPLAY = "--";

export const OrderRow = ({
  diagnosisCode,
  onImportServiceRequest,
  orderingProvider,
  procedureCodes,
  patient,
  activeCoverage,
  authNeeded,
  serviceStartDate,
}: Props) => {
  const { location, search } = useSearchAndLocation();

  const theme = useTheme();
  const procedureCodesList = procedureCodes?.map((px) => px.code).join(", ") || EMPTY_VALUE_DISPLAY;
  const procedureCodesTitle = procedureCodes && procedureCodes.length <= 1 ? "Procedure" : "Procedures";

  const [nonPalPDFOpen, setNonPalPDFOpen] = useState(false);

  return (
    <ItemContainer
      style={{
        display: "flex",
        backgroundColor: authNeeded ? colorsLight.background.card : colorsLight.background.panel,
        border: authNeeded ? `1px solid ${theme.palette.divider}` : "none",
        borderRadius: theme.spacing(0.5),
      }}
    >
      <DiagnosisItem item xs={1}>
        <LightCaption>Diagnosis</LightCaption>
        <ItemDetails>{diagnosisCode || EMPTY_VALUE_DISPLAY}</ItemDetails>
      </DiagnosisItem>
      <ProcedureItem item>
        <LightCaption>{procedureCodesTitle}</LightCaption>
        <ItemDetails>{procedureCodesList || EMPTY_VALUE_DISPLAY}</ItemDetails>
      </ProcedureItem>
      <ProviderItem item xs={3}>
        <LightCaption>Requesting provider</LightCaption>
        <ItemDetails>{orderingProvider || EMPTY_VALUE_DISPLAY}</ItemDetails>
      </ProviderItem>
      <ServiceStartDateLabel item xs={2}>
        <LightCaption>Service start date</LightCaption>
        <ItemDetails>{formatDateStr(serviceStartDate) || EMPTY_VALUE_DISPLAY}</ItemDetails>
      </ServiceStartDateLabel>
      <ButtonItem item xs={3}>
        {authNeeded ? (
          <ImportOrderButton data-tracking-id="import-ehr-order" onClick={onImportServiceRequest}>
            Import
          </ImportOrderButton>
        ) : (
          <PDFOrderButton
            data-tracking-id="ehr-no-auth-required-pdf"
            onClick={() => setNonPalPDFOpen(true)}
            startIcon={<GetAppIcon />}
          >
            Download PDF
          </PDFOrderButton>
        )}
      </ButtonItem>
      <Dialog fullScreen open={nonPalPDFOpen} onClose={() => setNonPalPDFOpen(false)}>
        <NonPalPDFProvider patient={patient} nonPalProcedureCodes={procedureCodes || []} coverage={activeCoverage}>
          {({ url, loading }) => (
            <DocumentViewer
              documentInfo={{
                name: `CohereAuthCheck_${patient?.memberId}.pdf`,
                contentType: "application/pdf",
              }}
              loading={loading}
              url={url || undefined}
              handleClose={() => setNonPalPDFOpen(false)}
              canDelete={false}
              location={location}
              search={search}
            />
          )}
        </NonPalPDFProvider>
      </Dialog>
    </ItemContainer>
  );
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ItemContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  width: "100%",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const LightCaption = styled(Caption)(({ theme }) => ({
  color: theme.palette.type === "dark" ? colorsDark.font.secondary : colorsLight.font.light,
  margin: "16px 0px 0px 0px",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ItemDetails = styled(Body1)(({ theme }) => ({
  whiteSpace: "nowrap",
  maxWidth: "100%",
  marginTop: theme.spacing(0.5),
  textOverflow: "ellipsis",
  overflow: "hidden",
}));

// using pixel values because adding multiple theme atts is acting weird
// eslint-disable-next-line cohere-react/no-mui-styled-import
const DiagnosisItem = styled(Grid)({
  margin: "20px 24px 20px 20px",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ProcedureItem = styled(Grid)({
  minWidth: "180px",
  margin: "20px 24px 20px 0px",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ProviderItem = styled(Grid)({
  margin: "20px 24px 20px 0px",
  minWidth: "210px",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ServiceStartDateLabel = styled(Grid)({
  margin: "20px 12px 20px 0px",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ButtonItem = styled(Grid)({
  margin: "16px 16px 16px 0px",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ImportOrderButton = styled(PrimaryButton)({
  float: "right",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PDFOrderButton = styled(InlineButton)({
  margin: "12px 4px 12px 0px",
  float: "right",
});
