import { useState } from "react";
import { ReferralRequestResponse } from "@coherehealth/core-platform-api";
import { Body1, Caption, InlineButton, formatDateStr } from "@coherehealth/common";
import { Grid, useTheme } from "@material-ui/core";
import ContactInfoModal, { ContactInfoModalProps } from "components/ContactInfoModal";
import {
  ReferralFormConfiguration,
  useGetReferralRequestFormConfiguration,
} from "components/ReferralManagement/FormContentSpecification/ReferralRequestFormContentSpecifications";
import { SRFormConfigFieldWrapper } from "common/FormConfigUtils";
import { LocationNetworkStatusPill } from "components/ServiceRequest/utils/serviceRequestUtils";
import { DetailsField, useDetailsFieldStyles } from "util/referralRequest";

interface Props {
  referralRequest: ReferralRequestResponse;
  isAdministrative: boolean;
}

const contactInfoButtonText = "View info";

export default function ReferralPreReviewDetails({ referralRequest, isAdministrative }: Props) {
  const formConfiguration: ReferralFormConfiguration | null = useGetReferralRequestFormConfiguration(
    referralRequest.patient ?? null, // the hook expects Patient | null but the object is Patient | undefined
    isAdministrative
  );
  const closeModal = () => setModalState((prev) => ({ ...prev, open: false }));
  const [modalState, setModalState] = useState<ContactInfoModalProps>({
    open: false,
    handleClose: () => {
      setModalState((prev) => ({ ...prev, open: false }));
    },
    type: "FACILITY",
  });

  const theme = useTheme();

  const facilityOutOfNetworkStatusDisplayOff =
    formConfiguration?.facilityOutOfNetworkStatusDisplay?.fieldSpec === "OMIT";
  const selectedPerformingSpecialistOutOfNetworkStatusDisplayOff =
    formConfiguration?.performingSpecialistOutOfNetworkStatusDisplay?.fieldSpec === "OMIT";

  const facilityIsOutOfNetwork = referralRequest.selectedFacility?.selectedLocation?.isOutOfNetwork;
  const oonExceptionFacilityReason = referralRequest.selectedFacility?.selectedLocation?.outOfNetworkExceptionReason;
  const oonExceptionFacilityComment = referralRequest.selectedFacility?.selectedLocation?.outOfNetworkExceptionComment;

  const selectedPerformingSpecialistIsOutOfNetwork =
    referralRequest.selectedPerformingSpecialist?.selectedLocation?.isOutOfNetwork;
  const oonExceptionSelectedPerformingSpecialistReason =
    referralRequest.selectedPerformingSpecialist?.selectedLocation?.outOfNetworkExceptionReason;
  const oonExceptionSelectedPerformingSpecialistComment =
    referralRequest.selectedPerformingSpecialist?.selectedLocation?.outOfNetworkExceptionComment;

  const classes = useDetailsFieldStyles();

  return (
    <Grid container item xs={12} spacing={3} style={{ margin: 0 }}>
      <DetailsField
        label={"Dates valid"}
        value={`${formatDateStr(referralRequest.startDate)} - ${formatDateStr(referralRequest.endDate)}`}
      />
      {formConfiguration?.primarySemanticDiagnosisCode.fieldSpec !== "OMIT" && (
        <SRFormConfigFieldWrapper {...formConfiguration?.primarySemanticDiagnosisCode}>
          <DetailsField
            label={"Primary diagnosis"}
            value={`${referralRequest.primarySemanticDiagnosisCode?.code} - ${referralRequest.primarySemanticDiagnosisCode?.description}`}
          />
        </SRFormConfigFieldWrapper>
      )}

      {formConfiguration?.secondarySemanticDiagnosisCodes.fieldSpec !== "OMIT" && (
        <SRFormConfigFieldWrapper {...formConfiguration?.secondarySemanticDiagnosisCodes}>
          <DetailsField
            label={"Secondary diagnosis"}
            value={
              referralRequest.secondarySemanticDiagnosisCodes?.length !== 0
                ? referralRequest.secondarySemanticDiagnosisCodes?.map((d) => d.code).join(", ")
                : "--"
            }
          />
        </SRFormConfigFieldWrapper>
      )}

      <SRFormConfigFieldWrapper {...formConfiguration?.selectedReferringProvider}>
        <DetailsField
          label={"Referring provider"}
          bodyChildren={
            <>
              {referralRequest?.selectedReferringProvider ? (
                <>
                  <Body1 className={classes.bodyChildrenContainer} data-public>
                    <span>{referralRequest.selectedReferringProvider?.name?.concat(" / ")}</span>
                    <span>{`NPI - ${referralRequest.selectedReferringProvider?.npi || "N/A"}`}</span>
                  </Body1>
                </>
              ) : undefined}
            </>
          }
        >
          {referralRequest.selectedReferringProvider && (
            <>
              <InlineButton
                className={classes.viewContactInfo}
                onClick={() => {
                  setModalState({
                    open: true,
                    name: referralRequest.selectedReferringProvider?.name || "None",
                    phones: referralRequest.selectedReferringProvider?.selectedLocation?.phoneNumbers,
                    tinList: [
                      referralRequest.healthPlanName === "BCBS South Carolina"
                        ? "--"
                        : referralRequest.selectedReferringProvider?.selectedLocation?.tin || "None",
                    ],
                    npi: referralRequest.selectedReferringProvider?.npi,
                    addresses: [referralRequest.selectedReferringProvider?.selectedLocation?.address],
                    type: "REFERRING_PROVIDER",
                    npiLabel: referralRequest.selectedReferringProvider?.npi || "N/A",
                    handleClose: closeModal,
                  });
                }}
                id={"referringProviderViewInfoBtn"}
              >
                {contactInfoButtonText}
              </InlineButton>
            </>
          )}
        </DetailsField>
      </SRFormConfigFieldWrapper>

      <DetailsField label={"Specialty"} value={referralRequest.specialty ? referralRequest.specialty : " - "} />

      <SRFormConfigFieldWrapper {...formConfiguration?.selectedPerformingSpecialist}>
        <DetailsField
          label={"Specialist"}
          subTextChildren={
            <>
              {oonExceptionSelectedPerformingSpecialistReason && (
                <Caption className={classes.outOfNetworkExceptionReasonStyle} data-public>
                  Exception reason: {oonExceptionSelectedPerformingSpecialistReason}
                </Caption>
              )}
              {oonExceptionSelectedPerformingSpecialistComment && (
                <Caption className={classes.labelContainer} data-public>
                  Comment: {oonExceptionSelectedPerformingSpecialistComment}
                </Caption>
              )}
            </>
          }
          bodyChildren={
            <>
              {referralRequest.selectedPerformingSpecialist ? (
                <>
                  <Body1 className={classes.bodyChildrenContainer} data-public>
                    <span>{referralRequest.selectedPerformingSpecialist?.name?.concat(" / ")}</span>
                    <span>{`NPI - ${referralRequest.selectedPerformingSpecialist?.npi || "N/A"}`}</span>
                  </Body1>
                </>
              ) : (
                <Body1>--</Body1>
              )}
            </>
          }
        >
          {referralRequest.selectedPerformingSpecialist ? (
            <>
              <InlineButton
                className={classes.viewContactInfo}
                onClick={() => {
                  setModalState({
                    open: true,
                    name: referralRequest.selectedPerformingSpecialist?.name || "None",
                    phones: referralRequest.selectedPerformingSpecialist?.selectedLocation?.phoneNumbers,
                    type: "SPECIALIST",
                    tinList: [
                      referralRequest.healthPlanName === "BCBS South Carolina"
                        ? "--"
                        : referralRequest.selectedPerformingSpecialist?.selectedLocation?.tin || "None",
                    ],
                    npi: referralRequest.selectedPerformingSpecialist?.npi,
                    addresses: [referralRequest.selectedPerformingSpecialist?.selectedLocation?.address],
                    npiLabel: referralRequest.selectedPerformingSpecialist?.npi || "N/A",
                    handleClose: closeModal,
                  });
                }}
                id={"specialistViewInfoBtn"}
              >
                {contactInfoButtonText}
              </InlineButton>
              <LocationNetworkStatusPill
                location={referralRequest.selectedPerformingSpecialist?.selectedLocation ?? undefined}
                config={{
                  outOfNetworkStatusDisplayOff: selectedPerformingSpecialistOutOfNetworkStatusDisplayOff,
                  locationIsOutOfNetwork: selectedPerformingSpecialistIsOutOfNetwork,
                  style: { padding: theme.spacing(0.625, 1, 0.5, 1) },
                  //outOfNetworkStatusOverride: referralRequest?.networkStatusOverride?.isOutOfNetworkFacility,
                  //outOfNetworkStatusOverrideEnabled,
                }}
              />
            </>
          ) : (
            <></>
          )}
        </DetailsField>
      </SRFormConfigFieldWrapper>

      <SRFormConfigFieldWrapper {...formConfiguration?.selectedFacility}>
        <DetailsField
          label={"Specialist practice"}
          subTextChildren={
            <>
              {oonExceptionFacilityReason && (
                <Caption className={classes.outOfNetworkExceptionReasonStyle} data-public>
                  Exception reason: {oonExceptionFacilityReason}
                </Caption>
              )}
              {oonExceptionFacilityComment && (
                <Caption className={classes.labelContainer} data-public>
                  Comment: {oonExceptionFacilityComment}
                </Caption>
              )}
            </>
          }
          bodyChildren={
            <>
              {referralRequest.selectedFacility ? (
                <>
                  <Body1 className={classes.bodyChildrenContainer} data-public>
                    <span>{referralRequest.selectedFacility?.name?.concat(" / ")}</span>
                    <span>{`NPI - ${referralRequest.selectedFacility?.npi || "N/A"}`}</span>
                  </Body1>
                </>
              ) : (
                <Body1>--</Body1>
              )}
            </>
          }
        >
          <>
            <InlineButton
              className={classes.viewContactInfo}
              onClick={() => {
                setModalState({
                  open: true,
                  name: referralRequest.selectedFacility?.name || "None",
                  phones: referralRequest.selectedFacility?.selectedLocation?.phoneNumbers,
                  type: "SPECIALIST_PRACTICE",
                  tinList: [
                    referralRequest.healthPlanName === "BCBS South Carolina"
                      ? "--"
                      : referralRequest.selectedFacility?.selectedLocation?.tin || "None",
                  ],
                  npi: referralRequest.selectedFacility?.npi,
                  addresses: [referralRequest.selectedFacility?.selectedLocation?.address],
                  npiLabel: referralRequest.selectedFacility?.npi || "N/A",
                  handleClose: closeModal,
                });
              }}
              id={"specialistPracticeViewInfoBtn"}
            >
              {contactInfoButtonText}
            </InlineButton>
            <LocationNetworkStatusPill
              location={referralRequest.selectedFacility?.selectedLocation ?? undefined}
              config={{
                outOfNetworkStatusDisplayOff: facilityOutOfNetworkStatusDisplayOff,
                locationIsOutOfNetwork: facilityIsOutOfNetwork,
                style: { padding: theme.spacing(0.625, 1, 0.5, 1) },
                //outOfNetworkStatusOverride: referralRequest?.networkStatusOverride?.isOutOfNetworkFacility,
                //outOfNetworkStatusOverrideEnabled,
              }}
            />
          </>
        </DetailsField>
      </SRFormConfigFieldWrapper>

      <ContactInfoModal
        open={modalState.open}
        handleClose={modalState.handleClose}
        type={modalState.type}
        name={modalState.name}
        phones={modalState.phones}
        fax={modalState.fax}
        email={modalState.email}
        npi={modalState.npi}
        tinList={modalState.tinList}
        addresses={modalState.addresses}
        npiLabel={modalState.npiLabel}
      />
    </Grid>
  );
}
