import React, { useCallback } from "react";

import FormGroup from "@material-ui/core/FormGroup";

import QuestionHeader, { Props as QuestionHeaderProps } from "./QuestionHeader";
import QuestionCard, { Props as QuestionCardProps } from "./QuestionCard";
import Grid from "@material-ui/core/Grid";
import { useStableUniqueId } from "../../hooks";
import { Caption } from "../Typography";
import { colorsLight } from "../../themes";
import { Checkbox } from "../Checkbox";

interface MultiSelectAnswer {
  id: string;
  label: React.ReactNode;
}

interface Props extends QuestionHeaderProps, QuestionCardProps {
  /** The list of answers for this question */
  options: MultiSelectAnswer[];
  /** The selected answers for this question */
  selections: string[];
  /** A function which updates the selected answer when a new option is clicked */
  setSelections: (arg0: string[]) => any;
}

export default function MultiSelectQuestion({
  options,
  selections,
  setSelections,
  relevantLinks,
  required,
  ordinal,
  subtext,
  questionText,
  hideQuestionAnswerDivider,
  ...questionCardProps
}: Props) {
  const setChecked = useCallback(
    (id: string, checked: boolean) => {
      const isCurrentlySelected = selections.includes(id);
      if (checked && !isCurrentlySelected) {
        setSelections([...selections, id]);
      }
      if (!checked && isCurrentlySelected) {
        setSelections(selections.filter((selection) => selection !== id));
      }
    },
    [selections, setSelections]
  );

  const labelId = useStableUniqueId("MultiSelectQuestion");

  return (
    <QuestionCard {...questionCardProps}>
      <label id={labelId}>
        <QuestionHeader
          relevantLinks={relevantLinks}
          required={required}
          questionText={questionText}
          ordinal={ordinal}
          hideQuestionAnswerDivider={hideQuestionAnswerDivider}
        />
      </label>
      <Grid item xs={10} style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
        <Caption style={{ color: colorsLight.font.secondary }} data-public>
          {subtext || "Select all that apply."}
        </Caption>
      </Grid>
      <FormGroup aria-labelledby={labelId} role="group">
        {options.map(({ id, label }) => (
          <Checkbox
            key={id}
            label={label}
            checked={selections.includes(id)}
            onChange={(checked) => setChecked(id, checked)}
          />
        ))}
      </FormGroup>
    </QuestionCard>
  );
}
