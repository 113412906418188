import { Grid, makeStyles } from "@material-ui/core";

export interface MiddleLabelProps {
  leftLabel: string;
  rightLabel: string;
  className?: string;
}

export const useLabelStyles = makeStyles((theme) => ({
  container: {
    flexWrap: "nowrap",
  },
  containerWithEndornment: {
    paddingRight: theme.spacing(5),
  },
  leftLabel: {
    flex: "0 1 auto",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  rightLabel: {
    flex: "1 0 auto",
    whiteSpace: "nowrap",
    marginLeft: theme.spacing(0.5),
  },
}));

export default function MiddleTruncateLabel({ leftLabel, rightLabel, className }: MiddleLabelProps) {
  const classes = useLabelStyles();
  const containerClassName = `${classes.container} ${className || ""}`.trim();
  return (
    <Grid container className={containerClassName}>
      <Grid item className={classes.leftLabel}>
        {leftLabel}
      </Grid>
      <Grid item className={classes.rightLabel}>
        {rightLabel}
      </Grid>
    </Grid>
  );
}
