import React, { SVGProps } from "react";

export default function ServiceRequestNotRequired(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40Z"
        fill="black"
        opacity="0.04"
      />
      <path d="M55.6013 23.175H23V61.1986H55.6013V23.175Z" fill="#A1AAB4" />
      <path d="M47.9131 25.4792H25.3066V58.8898H53.2814V30.861L47.9131 25.4792Z" fill="white" />
      <path
        d="M46.6845 18.4665H42.3412C42.3412 16.5512 40.9789 15 39.2929 15C37.6068 15 36.2445 16.5512 36.2445 18.4665H31.9013L30.6738 26.1549H47.8984L46.6845 18.4665Z"
        fill="#212936"
      />
      <path
        d="M33.8586 30.7825H31.5386V28.4625H29.9469V30.7825H27.627V32.3741H29.9469V34.6941H31.5386V32.3741H33.8586V30.7825Z"
        fill="#EA1D63"
      />
      <path d="M46.5506 37.2708H32.0371V38.3634H46.5506V37.2708Z" fill="#89D3DD" />
      <path d="M50.597 54.7625H44.8105V55.8551H50.597V54.7625Z" fill="#89D3DD" />
      <path d="M50.5967 41.275H27.9902V42.3676H50.5967V41.275Z" fill="#C2C2C2" />
      <path d="M50.5967 44.5521H27.9902V45.6446H50.5967V44.5521Z" fill="#C2C2C2" />
      <path d="M50.5967 47.8333H27.9902V48.9259H50.5967V47.8333Z" fill="#C2C2C2" />
      <path d="M38.093 51.1083H27.9902V52.2009H38.093V51.1083Z" fill="#C2C2C2" />
      <path d="M47.9121 30.861H53.2805L47.9121 25.4792V30.861Z" fill="#F5F5F5" />
      <circle cx="55.0107" cy="27.0105" r="10.0087" fill="white" />
      <path
        d="M55.0107 17.0018C49.4859 17.0018 45.002 21.4857 45.002 27.0105C45.002 32.5353 49.4859 37.0192 55.0107 37.0192C60.5355 37.0192 65.0194 32.5353 65.0194 27.0105C65.0194 21.4857 60.5355 17.0018 55.0107 17.0018ZM55.0107 35.0175C50.5868 35.0175 47.0037 31.4344 47.0037 27.0105C47.0037 25.1589 47.6343 23.4574 48.6952 22.1062L59.915 33.326C58.5638 34.3869 56.8623 35.0175 55.0107 35.0175ZM61.3262 31.9148L50.1064 20.695C51.4576 19.6341 53.1591 19.0035 55.0107 19.0035C59.4345 19.0035 63.0177 22.5866 63.0177 27.0105C63.0177 28.8621 62.3871 30.5636 61.3262 31.9148Z"
        fill="#6F7580"
      />
    </svg>
  );
}
