import React, { useEffect } from "react";
import { SingleSelect, patientDisplayName } from "@coherehealth/common";
import { User, useGetUsers } from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";

interface Props {
  user?: User | undefined;
  email?: string | undefined;
  setUser: (user: User | undefined) => void;
  setEmail: (email: string | undefined) => void;
  autoFilled?: boolean;
}

const SEARCH_CHARS_MIN = 4;

export default function UserEmailInput({ user, email, setUser, setEmail, autoFilled }: Props) {
  const { data, loading, error, refetch } = useGetUsers({
    queryParams: { query: email || "" },
    lazy: true,
  });
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (error) {
      enqueueSnackbar(`Error searching users: ${error.message}`, { variant: "warning" });
    }
  }, [error, enqueueSnackbar]);

  const shouldQuery = email && email.length >= SEARCH_CHARS_MIN;
  useEffect(() => {
    if (shouldQuery) {
      refetch();
    }
    // The identity of `refetch` is unstable: https://github.com/contiamo/restful-react/issues/186
  }, [shouldQuery, email]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SingleSelect<UserOption | undefined, true>
      freeSolo
      autoFilledIcon={autoFilled}
      label={`Email`}
      markSelectedOptions
      useOptions={() => ({
        options: addNoMatchOption(data || undefined, email),
        optionsLoading: loading,
      })}
      getOptionLabel={(user) => user?.email || "No email"}
      renderOption={optionRenderer(email)}
      selectOnFocus
      loading={loading}
      handleHomeEndKeys
      selectedValue={user || null}
      setSelectedValue={(maybeUser) => {
        if (maybeUser && typeof maybeUser !== "string" && !maybeUser.noMatchOption && maybeUser.email) {
          // It is a real user object
          setUser(maybeUser);
          setEmail("");
        }
      }}
      inputValue={email || user?.email || ""}
      onInputChange={(event, newInputValue) => {
        if (event) {
          setEmail(newInputValue);
          if (newInputValue && user && newInputValue !== user.email) {
            setUser(undefined);
          }
        }
      }}
      data-public
    />
  );
}

interface UserOption extends User {
  noMatchOption?: true;
}

function addNoMatchOption(data: User[] | undefined, email: string | undefined): UserOption[] | undefined {
  return [...(data || []), { noMatchOption: true, email }];
}

function optionRenderer(email: string | undefined) {
  return function renderOption(user: UserOption | undefined) {
    if (!user) {
      return "-";
    }

    if (user.noMatchOption) {
      if (user && (user.email || "").length < SEARCH_CHARS_MIN) {
        return `Enter an email`;
      }
      return `${user.email} (new)`;
    }
    return `${patientDisplayName(user)} - ${user.email}`;
  };
}
