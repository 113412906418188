import React, { PropsWithChildren } from "react";
import { VisuallyHidden } from "../Typography";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import styled from "@material-ui/core/styles/styled";
import { Link } from "react-router-dom";

export default function HiddenNavLink({ children, href, ...props }: PropsWithChildren<{ href: string }>) {
  return (
    <NavLink href={href} {...props}>
      {children}
    </NavLink>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const NavLink = styled(({ children, href, ...props }: PropsWithChildren<{ href: string }>) => (
  <Link to={href} {...props}>
    <VisuallyHidden>{children}</VisuallyHidden>
  </Link>
))({
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
  },
});
