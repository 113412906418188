interface ApiError {
  message?: string;
  data?: {
    message?: string;
  };
  status?: number;
}
export function assertIsApiError(e: unknown): asserts e is ApiError {
  const message = (e as ApiError).message;
  if (message && typeof message !== "string") {
    throw new Error(`Unexpected API error type ${e}`);
  }
}
