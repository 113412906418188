import React, { FunctionComponent } from "react";
import {
  Body2,
  Chip,
  SelectOptionsHook,
  MultipleSelect,
  useLazyLoadingQueryOptionsHook,
  useFeature,
} from "@coherehealth/common";
import { useGetProcedureCodes, ProcedureCode, FacilityCategory } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { AutocompleteGetTagProps } from "@material-ui/lab/Autocomplete";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";
import { PriorAuthRequirements } from "./common";

interface Props {
  procedureCodes: ProcedureCode[];
  setProcedureCodes: (pxs: ProcedureCode[]) => void;
  attemptedSubmit: boolean;
  /**
   * Cannot delete these procedure codes
   */
  readOnlyPxCodes?: string[];
  label?: string | React.ReactNode;
  filteredProcedureCodes?: ProcedureCode[];
  disable?: boolean;
  /**
   * When enabled, if a user selects an already-selected procedure code that code will be unselected instead
   * of the default behavior of adding a duplicate selection
   */
  blockDuplicateSelections?: boolean;
  isAutoFilled?: boolean;
  encounterType?: FacilityCategory;
  healthPlanName?: string | null;
  priorAuthRequirements?: PriorAuthRequirements;
}

function useProcedures(
  systemsQuery: string,
  getOnlyActiveCodes: boolean,
  serviceStartDate: string
): SelectOptionsHook<ProcedureCode | null> {
  const useProceduresInner: SelectOptionsHook<ProcedureCode | null> = (selectOptionsParams) =>
    useLazyLoadingQueryOptionsHook<any, unknown>({
      useGetHook: useGetProcedureCodes,
      additionalQueryParams: {
        systems: systemsQuery,
        getOnlyActiveCodes: getOnlyActiveCodes,
        serviceStartDate: serviceStartDate,
      },
      ...selectOptionsParams,
    });
  return useProceduresInner;
}

const ProcedureCodeSelect: FunctionComponent<Props> = ({
  procedureCodes,
  setProcedureCodes,
  readOnlyPxCodes,
  attemptedSubmit,
  label,
  filteredProcedureCodes,
  disable,
  blockDuplicateSelections,
  isAutoFilled,
  encounterType,
  healthPlanName,
  priorAuthRequirements,
}) => {
  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName: healthPlanName || undefined,
    encounterType,
    skipRequestTimingCheck: true,
  });
  const filterOnlyActiveCodes = useFeature("filterProcedureCodeByStartAndEndDate");

  const hideIcd10PxCodes = useFeature("hideIcd10PxCodes");

  const systemsQuery = hideIcd10PxCodes ? "cpt,hcpcs,cdt" : "cpt,hcpcs,cdt,icd10";

  const notEnoughProcedureCodesInpatient =
    encounterType &&
    encounterType === "INPATIENT" &&
    attemptedSubmit &&
    !procedureCodes.length &&
    !disable &&
    !facilityBasedFeatureEnabled;

  const notEnoughProcedureCodesOutpatient =
    encounterType && encounterType === "OUTPATIENT" && attemptedSubmit && !procedureCodes.length && !disable;

  const useProceduresFunction = useProcedures(
    systemsQuery,
    filterOnlyActiveCodes ? true : false,
    priorAuthRequirements?.startDate instanceof Date && !isNaN(priorAuthRequirements.startDate.getTime())
      ? priorAuthRequirements.startDate.toISOString()
      : new Date().toISOString()
  );

  const helperText = () => {
    return notEnoughProcedureCodesOutpatient || notEnoughProcedureCodesInpatient ? "Required" : undefined;
  };

  return (
    <MultipleSelect
      disablePortal
      autoFilledIcon={isAutoFilled}
      fullWidth
      autoHighlight
      disabled={disable}
      helperText={helperText()}
      label="CPT/HCPCS codes"
      emptyLabel={label || "Enter 5 digit procedure codes"}
      placeholder={"Enter another procedure code"}
      customGetOptionSelected={(aCode, bCode) => {
        return blockDuplicateSelections ? aCode?.code === bCode?.code : false;
      }}
      useOptions={
        filteredProcedureCodes
          ? () => ({
              options: filteredProcedureCodes,
              optionsLoading: false,
              filterOptions: (options, state) => options.filter((option) => option?.code.includes(state.inputValue)),
            })
          : useProceduresFunction
      }
      selectedValue={procedureCodes}
      getOptionLabel={(px) => `${px?.code}`}
      renderTags={(value, getTagProps) =>
        value.map((procedureCode, index) =>
          procedureCode ? (
            <PxChip
              disabled={readOnlyPxCodes?.includes(procedureCode.code)}
              px={procedureCode}
              index={index}
              key={`${procedureCode.code}-${index}`}
              getTagProps={getTagProps}
              procedureCodes={procedureCodes}
            />
          ) : null
        )
      }
      renderOption={(px) => (
        <Body2Unbold>
          <b>{px?.code}</b> - {px?.description || px?.label}
        </Body2Unbold>
      )}
      setSelectedValue={(pxs) => {
        // Filter out nulls
        const realPxs = pxs.filter((p): p is ProcedureCode => Boolean(p));
        setProcedureCodes(realPxs);
      }}
      data-public
    />
  );
};

const PxChip: FunctionComponent<{
  px: ProcedureCode;
  index: number;
  getTagProps: AutocompleteGetTagProps;
  procedureCodes: ProcedureCode[];
  disabled?: boolean;
}> = ({ px, index, getTagProps, disabled }) => {
  // set onDelete to undefined to remove the "X"
  const disabledProps = disabled ? { disabled: true, onDelete: undefined } : {};

  return <Chip size="small" label={px.code} key={px.code} data-public {...getTagProps({ index })} {...disabledProps} />;
};

export default ProcedureCodeSelect;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Body2Unbold = styled(Body2)({
  fontWeight: "normal",
});
