import { useEffect, useMemo, useState } from "react";
import {
  Requestor,
  ServiceRequestResponse,
  Attachment,
  useCreateServiceRequestAttachment,
  useUpdateServiceRequestAttachment,
  AttachmentUpdatePayload,
  useGetPatient,
} from "@coherehealth/core-platform-api";
import { useLocation, useMatch } from "react-router-dom";
import routes from "routes";
import Container from "@material-ui/core/Container";
import Header from "./Header";
import Footer from "./Footer";
import { useAuthorized } from "authorization";
import { today, useFeature, useMuiContainerStyles, useGetServiceRequestByIdWithFallback } from "@coherehealth/common";
import { requestorValid } from "components/Requestor/RequestorEdit";
import { FaxFlow } from "./FaxFlow";
import { useSnackbar } from "notistack";
import { error as logError } from "logger";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import CircularProgress from "@material-ui/core/CircularProgress";
import { formContentFromResponse } from "util/serviceRequest";
import { ClinicalAssessmentContextProvider } from "components/ClinicalAssessment";

export type FaxStep = "CLINICAL_ASSESSMENT" | "SAVE_AND_REVIEW" | "SUBMIT_REQUEST";

function FaxServiceRequestPage() {
  // both cohere and non-cohere routes contain the faxId information, which is used to automatically attach the fax to the service request.
  const match = useMatch(routes.FAX_SERVICE_REQUEST);
  const faxId = match?.params.faxId || "";

  // get both the sr id and patient id from the url, if an srId is present, then its a cohere fax else its a non-cohere fax.
  const location = useLocation();
  const search = useMemo(() => new URLSearchParams(location.search), [location]);
  const serviceRequestId = search.get("serviceRequestId") || undefined;
  const patientId = search.get("patientId") || undefined;
  const caseId = search.get("caseId") || undefined;

  const { data: patient, loading: patientDataLoading } = useGetPatient({
    id: patientId || "",
  });

  const { enqueueSnackbar } = useSnackbar();

  const simplifiedServiceFrequency = useFeature("simplifiedServiceFrequency");
  const [step, setStep] = useState<FaxStep>("SAVE_AND_REVIEW");

  const [formContent, setFormContent] = useState<ServiceRequestFormContent>({
    clinicalService: undefined,
    palCategory: null,
    isInpatient: false,
    placeOfService: null,
    primaryDiagnosisCode: null,
    secondaryDiagnosisCodes: [],
    procedureCodes: [],
    isRecurring: simplifiedServiceFrequency ? undefined : false,
    startDate: today(),
    endDate: undefined,
    units: "0",
    unitType: undefined,
    orderingProvider: null,
    orderingProviderSelectedTin: null,
    performingProvider: null,
    performingProviderSelectedTin: null,
    facility: null,
    facilitySelectedTin: null,
    isExpedited: false,
    expeditedReason: "",
    facilitySelectedAddress: null,
    performingProviderSelectedAddress: null,
    orderingProviderSelectedAddress: null,
    approvedUnits: "",
  });

  const [serviceRequest, setServiceRequest] = useState<ServiceRequestResponse>();

  const [requestor, setRequestor] = useState<Requestor | undefined>(serviceRequest?.requestor);

  const [attachment, setAttachment] = useState<Attachment>();
  const [updateAttachment, setUpdateAttachment] = useState<boolean>(false);
  const [docType, setDocType] = useState<string>("Other");
  const [attemptedSubmitService, setAttemptedSubmitService] = useState<boolean>(false);
  const [canBeSubmitted, setCanBeSubmitted] = useState(false);
  const [showFooter, setShowFooter] = useState<boolean>(true);
  const [faxAdded, setFaxAdded] = useState<boolean>(false);

  const containerClasses = useMuiContainerStyles();
  const isCohereFax = Boolean(serviceRequestId);
  const loadingCohereSR = !formContent && isCohereFax;

  const requestorFormPermitted = useAuthorized("REQUESTOR_FORM");
  const requestorFormValid = (requestorFormPermitted && requestorValid(requestor)) || !requestorFormPermitted;
  const missingRequestor = !requestor || !requestorFormValid;
  const invalidForm = attemptedSubmitService && !canBeSubmitted;

  const {
    mutate: uploadFile,
    loading: fileLoading,
    error: fileError,
  } = useCreateServiceRequestAttachment({
    id: serviceRequest?.id || "",
    requestOptions: { headers: { Accept: "application/json" } },
  });

  const {
    mutate: attachementUpdate,
    loading: updateLoading,
    error: updateError,
  } = useUpdateServiceRequestAttachment({
    id: serviceRequest?.id || "",
    attachmentId: attachment?.id || "",
  });

  const {
    data: sr,
    loading: serviceRequestLoading,
    error: serviceRequestError,
    refetch: refetchSr,
  } = useGetServiceRequestByIdWithFallback({
    id: serviceRequestId || "",
    lazy: true,
  });

  useEffect(() => {
    if (serviceRequestId && !sr && !serviceRequestLoading && !serviceRequestError) {
      refetchSr();
    }
  }, [serviceRequestId, refetchSr, sr, serviceRequestLoading, serviceRequestError]);

  useEffect(() => {
    if (sr) {
      setServiceRequest(sr);
      setFormContent(formContentFromResponse(sr));
      setRequestor(sr?.requestor);
    }
  }, [setServiceRequest, sr]);

  useEffect(() => {
    if (serviceRequestError) {
      enqueueSnackbar(`Error loading service request: ${serviceRequestError.message}`, { variant: "error" });
    }
  }, [serviceRequestError, enqueueSnackbar, serviceRequestId]);

  useEffect(() => {
    if (updateError) {
      enqueueSnackbar(`Failed to update attachment: ${updateError.message}`, { variant: "error" });
    }
  }, [updateError, enqueueSnackbar]);

  useEffect(() => {
    if (fileError) {
      const errorMessage =
        typeof fileError.data === "object" ? fileError.data.message || fileError.message : fileError.message;
      enqueueSnackbar(`Failed to load attachment: ${errorMessage}`, { variant: "error" });
    }
  }, [fileError, enqueueSnackbar]);

  useEffect(() => {
    if (missingRequestor && attemptedSubmitService) {
      window.scrollTo(0, 0);
      enqueueSnackbar(`Missing requestor info`, { variant: "warning" });
    }
  }, [missingRequestor, enqueueSnackbar, attemptedSubmitService]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onAddFaxId = (faxId: string) => {
    const formData = new FormData();
    formData.set("faxId", faxId);
    formData.set("type", docType);
    try {
      const res = uploadFile(formData as unknown as void);
      res.then((faxAttachment) => {
        setAttachment(faxAttachment);
      });
      setFaxAdded(true);
    } catch (e) {
      logError(e);
      enqueueSnackbar(`Unable to attach fax ID ${faxId}`, { variant: "error" });
    }
  };

  useEffect(() => {
    if (!faxAdded && Boolean(serviceRequest?.id)) {
      onAddFaxId(faxId);
    }
  }, [faxAdded, faxId, onAddFaxId, serviceRequest?.id]);

  useEffect(() => {
    if (updateAttachment) {
      if (serviceRequest?.id && attachment?.id) {
        const res = attachementUpdate({ type: docType as AttachmentUpdatePayload["type"] });
        res.then((faxAttachment) => {
          setAttachment(faxAttachment);
        });
      }
      setUpdateAttachment(false);
    }
  }, [attachementUpdate, attachment?.id, docType, serviceRequest?.id, updateAttachment]);

  const onSaveAndReview = async () => {
    if (isCohereFax) {
      setUpdateAttachment(true);
    }
    setStep("CLINICAL_ASSESSMENT");
  };

  const showLoading =
    serviceRequestLoading || patientDataLoading || loadingCohereSR || (!serviceRequest && isCohereFax);

  return (
    <Container classes={containerClasses} maxWidth="lg">
      {showLoading ? (
        <CircularProgress size={24} />
      ) : (
        <>
          <Header />
          <FaxFlow
            formContent={formContent}
            setFormContent={setFormContent}
            setCanBeSubmitted={setCanBeSubmitted}
            faxId={faxId || ""}
            serviceRequest={serviceRequest}
            step={step}
            setStep={setStep}
            patient={patient || undefined}
            attemptedSubmitService={attemptedSubmitService}
            requestor={requestor}
            setRequestor={setRequestor}
            setServiceRequest={setServiceRequest}
            setShowFooter={setShowFooter}
            attachment={attachment}
            docType={docType}
            setDocType={setDocType}
            setUpdateAttachment={setUpdateAttachment}
            loading={fileLoading || updateLoading}
            setAttachment={setAttachment}
            isCohereFaxForm={isCohereFax}
          />
        </>
      )}
      {showFooter && (
        <Footer
          setStep={setStep}
          formContent={formContent}
          setFormContent={setFormContent}
          isCohereFax={isCohereFax}
          canBeSubmitted={canBeSubmitted}
          faxId={faxId || ""}
          step={step}
          patientId={patientId || ""}
          setAttemptedSubmitService={setAttemptedSubmitService}
          serviceRequest={serviceRequest}
          requestor={requestor}
          loading={fileLoading || updateLoading}
          setServiceRequest={setServiceRequest}
          setShowFooter={setShowFooter}
          missingRequestor={missingRequestor}
          onSaveAndReview={onSaveAndReview}
          cannotSubmit={invalidForm}
          caseId={caseId}
        />
      )}
    </Container>
  );
}

export default function FaxServiceRequestPageWithContext() {
  return (
    <ClinicalAssessmentContextProvider>
      <FaxServiceRequestPage />
    </ClinicalAssessmentContextProvider>
  );
}
