import React from "react";
import {
  Body1,
  fullStateNameToAbbr,
  POLICY_UNITED_STATES_OPTIONS,
  SingleSelect,
  SingleSelectProps,
  stateAbbrToFullName,
} from "@coherehealth/common";

interface Props {
  state: string;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  setState: (state: string) => void;
}

function isMatchingOption(option: string, inputValue: string) {
  return option.toUpperCase().includes(inputValue.toUpperCase());
}

function isMatchingStateAbbr(option: string, inputValue: string) {
  return fullStateNameToAbbr(option)?.includes(inputValue.toUpperCase());
}

function getMatchingOptions(option: string, inputValue: string) {
  const isTwoCharsAndUpperCase = inputValue.length === 2 && inputValue.toUpperCase() === inputValue;
  const matchesOption = isMatchingOption(option, inputValue);
  const matchesAbbr = isMatchingStateAbbr(option, inputValue);

  return (matchesOption && !isTwoCharsAndUpperCase) || (matchesAbbr && isTwoCharsAndUpperCase);
}

export default function UnitedStatesStateSelection({
  state,
  setState,
  required = false,
  error = false,
  helperText,
  ...props
}: Props & Omit<SingleSelectProps<string, false>, "selectedValue" | "setSelectedValue" | "useOptions">) {
  return (
    <>
      <SingleSelect
        {...props}
        fullWidth
        required={required}
        error={error}
        helperText={helperText}
        disableCloseOnSelect
        markSelectedOptions={false}
        noAdornment
        useOptions={() => {
          return {
            options: POLICY_UNITED_STATES_OPTIONS.map(({ id, label }) => {
              return label;
            }),
            optionsLoading: false,
            filterOptions: (options, state) => options.filter((option) => getMatchingOptions(option, state.inputValue)),
          };
        }}
        renderOption={(option) => <Body1>{option}</Body1>}
        selectedValue={stateAbbrToFullName(state) || ""}
        setSelectedValue={(selection) => {
          setState(fullStateNameToAbbr(selection || "") || "");
        }}
        label="State"
        getOptionLabel={(option) => option || ""}
        blurOnSelect
        autoHighlight
        autoSelect
      />
    </>
  );
}
