import { useState } from "react";
import { useTheme } from "@mui/material";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles } from "@material-ui/core/styles";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { CohereCard, Subtitle2 } from "@coherehealth/design-system";
import ContinueOrWithdraw from "./ContinueOrWithdraw";
import { Body3, formatDateStr, HEALTH_HELP_NAME, InlineButton, useFeature } from "@coherehealth/common";
import {
  isVoidedContinuationWithoutStayDates,
  canBeWithdrawn,
  useIsDelegatedToVendor,
  useServiceRequestWithdrawn,
} from "util/serviceRequest";
import { Grid, Divider } from "@material-ui/core";
import WithdrawModal from "components/PatientSummary/ServiceRequestSummaryCard/WithdrawModal";
import DeleteDraftServiceRequestModal from "components/PatientSummary/ServiceRequestSummaryCard/DeleteDraftServiceRequestModal";
import RequestedByInfo from "../../PatientSummary/AuthorizationPatientSummary/RequestedByInfo";
import ClinicalAssessmentSection from "../../PatientSummary/ServiceRequestSummaryCard/ClinicalAssessmentSection";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";
import { useGetServiceRequestEditConfigurationByPayerAndAuthStatus } from "hooks/useGetFeatureConfigurations";
import useOpsEditDisabled from "hooks/useOpsEditDisabled";
import { ExpandedServiceRequestContent } from "./ExpandedServiceRequestContent";

interface ExpandedCardProps {
  positionInSrList?: number;
  id?: string;
  sr: ServiceRequestResponse;
  // the most recent service request prior to this one for an auth (needed to calculate which procedure codes are new)
  priorSr?: ServiceRequestResponse;
  nextSr?: ServiceRequestResponse;
  clinicalReviewView?: boolean;
  continueDraft: () => void;
  onEdit?: () => void;
  shouldShowWithdrawButton?: boolean;
  isFacilityBased?: boolean;
  hideSidePadding?: boolean;
}

const useStyles = makeStyles(() => ({
  topRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  expandedRowClickable: {
    borderRadius: 0,
    boxShadow: "none !important",
    cursor: "pointer",
    "&:active": {
      backgroundColor: "#F5F5F5",
    },
  },
  expandedRow: {
    borderRadius: 0,
    boxShadow: "none !important",
    cursor: "",
  },
}));

const ExpandedServiceRequestCard = ({
  positionInSrList,
  id,
  sr,
  priorSr,
  nextSr,
  clinicalReviewView,
  continueDraft,
  onEdit,
  shouldShowWithdrawButton,
  isFacilityBased,
  hideSidePadding = false,
}: ExpandedCardProps) => {
  const classes = useStyles();
  const { healthPlanName, encounterType, delegatedVendor } = sr;
  const { facilityBasedFeatureEnabled, includePatientStayDatesOnPlannedAdmission } =
    useGetFacilityBasedRequestConfigurationByPayer({
      healthPlanName,
      encounterType,
      skipRequestTimingCheck: true,
      delegatedVendorName: delegatedVendor,
    });
  const hasFacilityBased = isFacilityBased ?? facilityBasedFeatureEnabled;

  const isIntegrateAuthDecisionGroups = useFeature("integrateAuthDecisionGroups");
  const limitVoidsAfterApprovalFF = useFeature("LimitVoidsAfterApproval");
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [showClinicalAssessment, setShowClinicalAssessment] = useState<boolean>(false);
  const isSingleService = !Boolean(sr?.carePathJourney?.id);
  const isDelegatedToHealthHelp = useIsDelegatedToVendor(sr, [HEALTH_HELP_NAME]);
  const hasNoAssessment = (isDelegatedToHealthHelp && !sr?.vendorIdentifier) || (isSingleService && sr.palCategory);

  const {
    withdrawModalOpen,
    setWithdrawModalOpen,
    withdrawRequestor,
    setWithdrawRequestor,
    withdrawnReason,
    setWithdrawnReason,
    onWithdraw,
    submitting,
    handleWithdrawButtonClick,
  } = useServiceRequestWithdrawn(sr, undefined, onEdit, undefined);

  const theme = useTheme();
  let isFromReview: boolean = false;
  let isNotFirstElement: boolean = false;
  if (positionInSrList !== undefined) {
    isFromReview = true;
    isNotFirstElement = positionInSrList > 0;
  }
  const opsEditEnabled = !useOpsEditDisabled(sr);

  const { srEditConfig } = useGetServiceRequestEditConfigurationByPayerAndAuthStatus({
    healthPlanName: sr?.healthPlanName,
    authStatus: sr.authStatus,
  });

  const srNoLongerNeededButton = srEditConfig?.srNoLongerNeededButton && limitVoidsAfterApprovalFF;

  return isVoidedContinuationWithoutStayDates(sr) ? null : (
    <Grid style={{ width: "100%" }} id={id} data-testid={!!sr.id && `expanded-sr-card-${sr.id}`}>
      {(!isFromReview || (isFromReview && isNotFirstElement)) && (
        <Grid item xs={12}>
          <Divider style={{ height: 2 }} />
        </Grid>
      )}
      <CohereCard
        style={
          clinicalReviewView || hideSidePadding
            ? { padding: theme.spacing(3, 0), border: "none", boxShadow: "none" }
            : { padding: theme.spacing(3), border: "none", boxShadow: "none" }
        }
      >
        <div className={classes.topRow}>
          <Body3 style={{ color: theme.palette.text.secondary, display: "flex", alignItems: "center" }} data-public>
            {formatDateStr(sr.dateCreated)}
          </Body3>
          <ContinueOrWithdraw
            sr={sr}
            withdrawRequest={handleWithdrawButtonClick}
            continueDraft={continueDraft}
            deleteDraft={() => {
              setDeleteModalOpen(true);
            }}
            shouldShowWithdrawButton={shouldShowWithdrawButton}
            srNoLongerNeededButton={srNoLongerNeededButton}
          />
        </div>
        <ExpandedServiceRequestContent
          sr={sr}
          isFacilityBased={hasFacilityBased}
          limitVoidsAfterApproval={limitVoidsAfterApprovalFF}
          clinicalReviewView={clinicalReviewView}
          includePatientStayDatesOnPlannedAdmission={includePatientStayDatesOnPlannedAdmission}
          classes={classes}
          priorSr={priorSr}
          nextSr={nextSr}
        />
        {!hasNoAssessment && (
          <>
            <div className={classes.topRow} style={{ marginTop: theme.spacing(0.5) }}>
              <InlineButton
                onClick={() => {
                  setShowClinicalAssessment(!showClinicalAssessment);
                }}
              >
                <Subtitle2>
                  {showClinicalAssessment ? "Hide clinical assessment" : "Show clinical assessment"}
                </Subtitle2>
              </InlineButton>
            </div>
            {showClinicalAssessment && !hasNoAssessment && (
              <div className={classes.topRow} style={{ marginTop: showClinicalAssessment ? theme.spacing(3) : "0px" }}>
                <Grid container item xs={12}>
                  <ClinicalAssessmentSection serviceRequest={sr} showReadOnlyVendorSummary={isDelegatedToHealthHelp} />
                </Grid>
              </div>
            )}
          </>
        )}
        {showClinicalAssessment && (
          <div className={classes.topRow} style={{ marginTop: theme.spacing(3) }}>
            <Grid item xs={12}>
              <Divider style={{ height: 1 }} />
            </Grid>
          </div>
        )}
        <div
          className={classes.row}
          style={{ marginTop: showClinicalAssessment ? theme.spacing(3) : theme.spacing(0.5) }}
        >
          <RequestedByInfo
            serviceRequest={sr}
            serviceRequestRefetch={async () => {
              onEdit?.();
            }}
          />
        </div>
      </CohereCard>
      {opsEditEnabled && canBeWithdrawn(sr) && (
        <WithdrawModal
          open={withdrawModalOpen}
          onClose={() => {
            setWithdrawModalOpen(false);
            setWithdrawnReason("");
          }}
          onWithdraw={onWithdraw}
          withdrawnReason={withdrawnReason}
          setWithdrawnReason={setWithdrawnReason}
          withdrawRequestor={withdrawRequestor}
          setWithdrawRequestor={setWithdrawRequestor}
          submitting={submitting}
          serviceRequestId={sr.id}
          serviceRequest={sr}
          authDecisionGroupId={
            (sr?.authDecisionGroup?.id && isIntegrateAuthDecisionGroups) || sr?.authDecisionGroup?.authNumber
              ? sr.authDecisionGroup?.id
              : undefined
          }
          withdrawnReasonOptionsList={sr.reviewOutcomeWithdrawOptions}
          srNoLongerNeededButton={srNoLongerNeededButton}
        />
      )}
      <DeleteDraftServiceRequestModal
        serviceRequestId={sr.id}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDeleteDraft={() => {
          onEdit?.();
        }}
      />
    </Grid>
  );
};

// export for storybook
export function ExpandedServiceRequestCardProps(props: ExpandedCardProps) {}
export default ExpandedServiceRequestCard;
