import { colorsLight, H2, H6 } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";
import ApprovedServiceRequestImage from "../components/images/ApprovedServiceRequestImage";

interface Props {
  mainText: string;
  subText: string;
}

export default function NoItemsToDisplay({ mainText, subText }: Props) {
  return (
    <NoQuestionWrapper>
      <InnerNoQuestionContentWrapper>
        <ClipboardWrapper>
          <GiantApprovedServiceRequestImage />
        </ClipboardWrapper>
        <NoQuestionsH2>{mainText}</NoQuestionsH2>
        <NoQuestionsH6>{subText}</NoQuestionsH6>
      </InnerNoQuestionContentWrapper>
    </NoQuestionWrapper>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const NoQuestionWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 3, 0, 3),
  backgroundColor: colorsLight.white.highEmphasis,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "8px",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ClipboardWrapper = styled("div")(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(4),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const NoQuestionsH2 = styled(H2)(({ theme }) => ({
  textAlign: "center",
  color: colorsLight.font.main,
  marginBottom: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const NoQuestionsH6 = styled(H6)(({ theme }) => ({
  textAlign: "center",
  color: colorsLight.font.light,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const InnerNoQuestionContentWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(14, 0, 17),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const GiantApprovedServiceRequestImage = styled(ApprovedServiceRequestImage)(({ theme }) => ({
  height: theme.spacing(20),
  width: theme.spacing(20),
}));
