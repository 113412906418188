export type Application = "QM" | "AUTH";
export type Category = "WORKING" | "NON_WORKING";
type AggregationStatus = "SKIPPED" | "NOT_STARTED" | "COMPLETE";
export interface TimeTracker {
  id: string;
  category: Category;
  caseNumber?: string;
  caseId?: string;
  application: Application;
  component: string;
  active: boolean;
  duration: number;
  stoppedReason: string;
  dateCreated: string;
  dateCreatedAsLong?: number;
  lastUpdated: string;
  lastUpdatedAsLong?: number;
  createdBy: string;
  updatedBy: string;
  subComponent?: string;
  healthPlan?: string;
  delegatedVendor?: string;
  aggregationStatus?: AggregationStatus;
  timeStoppedAsLong?: number;
  queueId?: string;
}
export interface TimeTrackerRequestBody {
  category: Category;
  caseId?: string;
  application: Application;
  component: string;
}
interface TrackConfig {
  application: Application;
  component: string;
  category: Category;
  active: boolean;
  /* Optionally define a list of query param names that must exist in path in order for tracker to act */
  requiredParams?: string[];
}
export interface ServiceCase {
  id: string;
}
const config: Record<string, TrackConfig> = {
  "/dashboard": {
    application: "QM",
    component: "Dashboard",
    category: "NON_WORKING",
    active: true,
    requiredParams: ["queueId"],
  },
  "/case_complete/:caseId": {
    application: "QM",
    component: "Case Complete",
    category: "NON_WORKING",
    active: true,
    requiredParams: ["queueId"],
  },
  "/:serviceRequestId/review": {
    application: "AUTH",
    component: "Clinical Review",
    category: "WORKING",
    active: true,
  },
  "/denial_review/:serviceRequestId": {
    application: "AUTH",
    component: "Denial Review",
    category: "WORKING",
    active: true,
    requiredParams: ["reviewId"],
  },
  "/:serviceRequestId/outreach": {
    application: "AUTH",
    component: "Outreach",
    category: "WORKING",
    active: true,
    requiredParams: ["caseId"],
  },
  "/fax/:faxId": {
    application: "AUTH",
    component: "Fax Attachment",
    category: "WORKING",
    active: true,
    requiredParams: ["caseId"],
  },
  "/fax_service_request/:faxId": {
    application: "AUTH",
    component: "Fax New SR",
    category: "WORKING",
    active: true,
    requiredParams: ["caseId"],
  },
  "/auth_builder/:patientId": {
    application: "AUTH",
    component: "Fax New SR Auth Builder",
    category: "WORKING",
    active: true,
    requiredParams: ["faxId", "caseId"],
  },
};
export default config;
