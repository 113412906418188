import React, { FunctionComponent, ComponentProps } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { PrimaryButton, SecondaryButton, H3, Body2 } from "@coherehealth/common";
import { DialogProps, Divider } from "@material-ui/core";
import { Column, FieldValue, Label } from "./ReadonlyDetail/FormElements";
import { Provider, Facility } from "@coherehealth/core-platform-api";
import MuiIconButton from "@material-ui/core/IconButton";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { useAuthorized } from "authorization";
import { formatPhone } from "util/demographicUtils";

interface Props extends Pick<DialogProps, "onClose"> {
  serviceCategory: string;
  isProvider: boolean;
  practice: Provider | Facility | null;
  setIsEditable: React.Dispatch<React.SetStateAction<boolean>>;
  isDraft: boolean;
  hideTinField?: boolean;
}

const ProviderFacilityReadonly: FunctionComponent<Props> = ({
  serviceCategory,
  isProvider,
  practice,
  setIsEditable,
  isDraft,
  onClose = () => {},
  hideTinField = false,
}) => {
  const useCanEditPractice = useAuthorized("ADMIN_CREATED_PROVIDER_EDIT");

  const practiceAddress = practice && practice.addresses && practice.addresses[0];

  return (
    <>
      <DialogTitle>
        <IconButton
          onClick={(e) => {
            onClose(e, "backdropClick");
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Row>
                <Body2Secondary>{serviceCategory}</Body2Secondary>
                <H3>{isProvider ? "Provider" : "Facility"}</H3>
              </Row>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {practice && (
              <Grid item container xs={12} spacing={2}>
                <BorderlessColumn xs={12}>
                  <Label>Name</Label>
                  <FieldValue>{practice.name}</FieldValue>
                  {!hideTinField && <Label hidden={!practice.tinList || practice.tinList.length === 0}>TIN</Label>}
                  {!hideTinField && practice.tinList && (
                    <FieldValue hidden={practice.tinList.length === 0}>{practice.tinList[0]}</FieldValue>
                  )}

                  <Label hidden={!Boolean(practice.npi)}>NPI</Label>
                  <FieldValue hidden={!Boolean(practice.npi)}>{practice.npi}</FieldValue>

                  <Label hidden={!practiceAddress?.line1}>Address</Label>
                  {practiceAddress && practiceAddress.line1 !== "" && (
                    <FieldValue hidden={practiceAddress?.line1 === ""}>
                      {practiceAddress.line1} {practiceAddress.line2}
                      <br />
                      {practiceAddress.city}, {practiceAddress.state} {practiceAddress.zipCode}
                    </FieldValue>
                  )}
                  {isProvider ? (
                    <Label hidden={!practice.phoneNumbers || practice.phoneNumbers.length === 0}>Phone Number</Label>
                  ) : (
                    <Label hidden={!practice.phoneNumbers || practice.phoneNumbers.length === 0}>Fax Number</Label>
                  )}
                  {practice.phoneNumbers && practice.phoneNumbers.length > 0 && (
                    <LastFieldValue hidden={practice.phoneNumbers.length === 0}>
                      {formatPhone(practice.phoneNumbers[0])}
                    </LastFieldValue>
                  )}
                </BorderlessColumn>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ButtonGrid container item xs={12} spacing={3} alignItems="center" justify="center">
              {isDraft && (useCanEditPractice || practice?.createdByUser) && (
                <Grid item>
                  <EditButton onClick={() => setIsEditable(true)}>Edit entry</EditButton>
                </Grid>
              )}
              <Grid item>
                <PrimaryButton onClick={(e) => onClose(e, "backdropClick")}>Done</PrimaryButton>
              </Grid>
            </ButtonGrid>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default ProviderFacilityReadonly;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: `${theme.spacing(3)}px ${theme.spacing(5)}px`,
  overflow: "visible",
}));

const Row = (props: ComponentProps<typeof Grid>) => <Grid style={{ textAlign: "center" }} item xs={12} {...props} />;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const BorderlessColumn = styled(Column)(({ theme }) => ({
  borderRightWidth: 0,
  borderRightStyle: "none",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ButtonGrid = styled(Grid)(({ theme }) => ({
  margin: 0,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Body2Secondary = styled(Body2)(({ theme }) => ({
  color: theme.palette.text.secondary,
  paddingBottom: theme.spacing(0.5),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const LastFieldValue = styled(FieldValue)(({ theme }) => ({
  marginBottom: 0,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const EditButton = styled(SecondaryButton)(({ theme }) => ({
  padding: theme.spacing(2, 3),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  padding: 0,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const IconButton = styled(MuiIconButton)(({ theme }) => ({
  float: "right",
  right: theme.spacing(1),
  top: theme.spacing(1),
}));
