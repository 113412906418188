import { RuleActions } from "@coherehealth/core-platform-api";
import { Dispatch, SetStateAction, createContext } from "react";

interface FiredNudgesContextType {
  firedRecommendedNudges: RuleActions;
  setFiredRecommendedNudges: Dispatch<SetStateAction<RuleActions>>;
}

const initialFiredNudgesContextType: FiredNudgesContextType = {
  firedRecommendedNudges: [],
  setFiredRecommendedNudges: () => {},
};

export const FiredNudgesContext = createContext(initialFiredNudgesContextType);
