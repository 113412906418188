import React, { useContext, useEffect, Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { Caption, SecondaryButton, colorsLight, TextField, SingleSelectDropdown } from "@coherehealth/common";
import {
  Attachment,
  RecommendChangeRuleAction,
  ServiceRequestResponse,
  AttachmentUpdatePayload,
  CreateCarePathJourneyAttachmentPathParams,
  CreateServiceRequestAttachmentPathParams,
  useUpdateServiceRequest,
  AuthBuilderWorkflowStep,
  PossibleAttachmentNudgeReasons,
} from "@coherehealth/core-platform-api";
import { navigateToPS, navigateToSRSummary } from "../common";
import { makeStyles } from "@material-ui/core";
import { H6 } from "@coherehealth/design-system";
import { UploadAttachmentOnNudge } from "./UploadAttachmentOnNudge";
import { MutateMethod } from "restful-react";
import { UploadFile } from "components/AddAttachments/FileUploadItem";
import SaveIcon from "@mui/icons-material/Save";
import { SmartOnFhirContext } from "components/SmartOnFhir/SmartOnFhirSecurityProvider";
import { useSnackbar } from "notistack";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import ImpressionTracking from "common/ImpressionTracking";
import { useTrackUserInteraction } from "util/userActivityTracker";

interface Props {
  ruleNudge: RecommendChangeRuleAction;
  answerToNudge: string;
  chosenOption?: string;
  handleNudgeClick: (accept: boolean, groupId: string, optionId?: string, customAnswer?: string) => number | undefined;
  nudgeCopy: {
    title: string;
    subtitle: string;
    message: string;
    affirmativeText: string;
    negativeText: string;
    affirmativeBoldedText: string;
    affirmativeAdditionalText: string;
    negativeBoldedText: string;
    additionalText: string;
    negativeUnits: string;
    negativePxCode: string;
  };
  serviceRequest: ServiceRequestResponse;
  loadingSRAttachment: boolean;
  afterUpload?: (arg0: Attachment, totalFiles: number) => void;
  workflowId?: string;
  uploadFile: MutateMethod<
    Attachment,
    void,
    void,
    CreateCarePathJourneyAttachmentPathParams | CreateServiceRequestAttachmentPathParams
  >;
  files: UploadFile[];
  setFiles: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  onRemoveFile: ({ id }: { id: string }) => void;
  onUpdateFile: (file: UploadFile) => void;
  updateAttachmentGroup: (fileId: string, attachmentUpdatePayload: AttachmentUpdatePayload) => Promise<void>;
  updateLoading: boolean;
  onUpdateAttachments?: (arg0: Attachment[]) => void;
  deleteAttachment: MutateMethod<void | void[], string, void, any>;
  workflowStep?: AuthBuilderWorkflowStep;
  patientId?: string;
  missingAttachmentsReason?: string;
  setMissingAttachmentsReason?: Dispatch<SetStateAction<string | undefined>>;
  missingAttachmentsReasonText?: string;
  setMissingAttachmentsReasonText?: Dispatch<SetStateAction<string | undefined>>;
  continueDisabledForAddAttachmentsDNS?: boolean;
  setContinueDisabledForAddAttachmentsDNS?: Dispatch<SetStateAction<boolean | undefined>>;
  initalAttachmentsCount?: number;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  oldAttachmentReasons?: PossibleAttachmentNudgeReasons[];
}

export const MissingAttachmentOptions = ({
  ruleNudge,
  answerToNudge,
  chosenOption,
  handleNudgeClick,
  serviceRequest,
  loadingSRAttachment,
  afterUpload,
  workflowId,
  files,
  setFiles,
  onRemoveFile,
  onUpdateFile,
  updateLoading,
  updateAttachmentGroup,
  uploadFile,
  deleteAttachment,
  onUpdateAttachments,
  workflowStep,
  patientId,
  missingAttachmentsReason,
  missingAttachmentsReasonText,
  continueDisabledForAddAttachmentsDNS,
  setContinueDisabledForAddAttachmentsDNS,
  initalAttachmentsCount,
  setFormContent,
  oldAttachmentReasons,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const possibleAttachmentNudgeReasonOptions = [
    { id: "DONT_KNOW_WHAT_INFO", label: "I don't know what info is needed" },
    { id: "INFO_NOT_SPECIFIC_ENOUGH", label: "The info that I have isn’t specific enough" },
    { id: "NO_ACCESS_TO_INFO", label: "My office has the info, but I don’t have access to it" },
    { id: "DONT_HAVE_INFO", label: "My office doesn’t have the info" },
    { id: "OTHER", label: "Other (comment is required)" },
  ];
  const smartClient = useContext(SmartOnFhirContext);
  const inSmartOnFhirWorkflow = Boolean(smartClient);

  const trackUserActivityInteraction = useTrackUserInteraction();

  const {
    mutate: updateServiceRequest,
    loading: updateServiceRequestLoading,
    error: updateServiceRequestError,
  } = useUpdateServiceRequest({
    id: "",
  });

  useEffect(() => {
    if (updateServiceRequestError) {
      enqueueSnackbar("Failed to update service request, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [updateServiceRequestError, enqueueSnackbar]);

  const updateServiceRequestWorkflowStep = async () => {
    await updateServiceRequest({ workflowStep: workflowStep }, { pathParams: { id: serviceRequest?.id } });
  };

  const selectReasonOnChange = async (value: string) => {
    if (isAllowedReason(value)) {
      if (
        (oldAttachmentReasons && oldAttachmentReasons?.[0] && oldAttachmentReasons?.[0]?.reason === undefined) ||
        (oldAttachmentReasons && oldAttachmentReasons?.length === 0)
      ) {
        await trackUserActivityInteraction({
          stage: "DELAY_REQUEST_SUBMIT",
          event: "DELAY_SUBMIT_NUDGE_IGNORED",
          activityContext: {
            serviceRequestId: serviceRequest?.id,
          },
        });
      }
      setFormContent((previousFormContent) => {
        const oldObject = previousFormContent.possibleAttachmentNudgeReasons?.[0];
        return {
          ...previousFormContent,
          possibleAttachmentNudgeReasons: [
            {
              ruleId: oldObject?.ruleId || ruleNudge.ruleId,
              reason: value,
              reasonText: oldObject?.reasonText,
            },
          ],
        };
      });
    }
  };

  const trackAndAfterUpload = async (arg0: Attachment, totalFiles: number) => {
    await trackUserActivityInteraction({
      stage: "DELAY_REQUEST_SUBMIT",
      event: "DELAY_SUBMIT_ADDED_ATTACHMENTS_AT_NUDGE",
      activityContext: {
        serviceRequestId: serviceRequest?.id,
        attachmentsLength: serviceRequest?.attachments?.length,
      },
    });
    if (afterUpload) {
      afterUpload(arg0, totalFiles);
    }
  };

  const onSaveAndExitClickButton = async () => {
    await trackUserActivityInteraction({
      stage: "DELAY_REQUEST_SUBMIT",
      event: "DELAY_SUBMIT_SAVED_AND_EXITED",
      activityContext: {
        serviceRequestId: serviceRequest?.id,
      },
    });
    await updateServiceRequestWorkflowStep();
    if (inSmartOnFhirWorkflow) {
      navigateToSRSummary(serviceRequest?.id || "", navigate, true);
    } else {
      if (patientId) {
        navigateToPS(patientId, navigate, serviceRequest?.id);
      }
    }
  };

  useEffect(() => {
    if (setContinueDisabledForAddAttachmentsDNS) {
      if (answerToNudge === "accept") {
        if ((chosenOption === "ADD" && initalAttachmentsCount === files.length) || chosenOption === "SAVE") {
          setContinueDisabledForAddAttachmentsDNS(true);
        } else {
          setContinueDisabledForAddAttachmentsDNS(false);
        }
      } else {
        if (!missingAttachmentsReason || (missingAttachmentsReason === "OTHER" && !missingAttachmentsReasonText)) {
          setContinueDisabledForAddAttachmentsDNS(true);
        } else {
          setContinueDisabledForAddAttachmentsDNS(false);
        }
      }
    }
  }, [
    setContinueDisabledForAddAttachmentsDNS,
    answerToNudge,
    chosenOption,
    initalAttachmentsCount,
    files.length,
    missingAttachmentsReason,
    missingAttachmentsReasonText,
  ]);

  return (
    <ImpressionTracking
      stage="DELAY_REQUEST_SUBMIT"
      event="DELAY_SUBMIT_NUDGE_SEEN"
      activityContext={{
        serviceRequestId: serviceRequest.id,
      }}
    >
      <div className={classes.buttonContainer} style={{ width: "100%", display: "block" }}>
        <div
          className={`${classes.fullWidthButton} ${
            answerToNudge === "accept" && chosenOption === "ADD" ? "highlightOption" : ""
          }`}
          onClick={() => {
            handleNudgeClick(true, ruleNudge.groupId!, "ADD");
          }}
          key={"ADD"}
        >
          <div
            className={classes.nudgeButtonContainer}
            style={
              files.length >= 1 && answerToNudge === "accept" && chosenOption === "ADD" ? { paddingBottom: "4px" } : {}
            }
          >
            <div style={{ display: "flex" }}>
              <div className={classes.buttonAndLabel}>
                {answerToNudge === "accept" && chosenOption === "ADD" ? (
                  <IconButton className={classes.radioButton}>
                    <RadioButtonCheckedIcon color="primary" />
                  </IconButton>
                ) : (
                  <IconButton
                    data-tracking-id="accept-nudge-option"
                    className={classes.radioButton}
                    onClick={() => handleNudgeClick(true, ruleNudge.groupId!, "ADD")}
                  >
                    <RadioButtonUncheckedIcon />
                  </IconButton>
                )}
                <div>
                  <H6 className={classes.selectionText} style={textStyles}>
                    <b style={{ color: colorsLight.font.main }}>Add documentation </b> to avoid delays to decisioning
                  </H6>
                </div>
              </div>
              <div className={classes.captionFloatRightContainer}>
                <Caption className={`${classes.caption} ${"acceptNudgeBanner"}`}>Most recommended</Caption>
              </div>
            </div>
            {answerToNudge === "accept" && chosenOption === "ADD" && (
              <div>
                <UploadAttachmentOnNudge
                  serviceRequest={serviceRequest}
                  loadingSRAttachment={loadingSRAttachment}
                  afterUpload={trackAndAfterUpload}
                  workflowId={workflowId}
                  uploadFile={uploadFile}
                  files={files}
                  setFiles={setFiles}
                  onRemoveFile={onRemoveFile}
                  onUpdateFile={onUpdateFile}
                  updateLoading={updateLoading}
                  updateAttachmentGroup={updateAttachmentGroup}
                  deleteAttachment={deleteAttachment}
                  onUpdateAttachments={onUpdateAttachments}
                  excludeHeader
                  renderedFromMissingAttachmentOptions
                />
              </div>
            )}
          </div>
        </div>

        <div style={{ height: 16 }} />

        <div
          className={`${classes.fullWidthButton} ${
            answerToNudge === "accept" && chosenOption === "SAVE" ? "highlightOption" : ""
          }`}
          onClick={() => {
            handleNudgeClick(true, ruleNudge.groupId!, "SAVE");
          }}
          key={"SAVE"}
        >
          <div className={classes.nudgeButtonContainer}>
            <div>
              <div className={classes.buttonAndLabel}>
                <div style={{ display: "flex" }}>
                  {answerToNudge === "accept" && chosenOption === "SAVE" ? (
                    <IconButton className={classes.centeredRadioButton}>
                      <RadioButtonCheckedIcon color="primary" />
                    </IconButton>
                  ) : (
                    <IconButton
                      data-tracking-id="accept-nudge-option"
                      className={classes.centeredRadioButton}
                      onClick={() => handleNudgeClick(true, ruleNudge.groupId!, "SAVE")}
                    >
                      <RadioButtonUncheckedIcon />
                    </IconButton>
                  )}
                </div>
                <div>
                  <H6 className={classes.selectionText} style={textStyles}>
                    <b style={{ color: colorsLight.font.main }}>Save this request as a draft</b> and submit after
                    gathering information
                  </H6>
                  {serviceRequest.cohereId && (
                    <Caption className={classes.selectionText} style={textStyles}>
                      Draft tracking #{serviceRequest.cohereId}
                    </Caption>
                  )}
                </div>
                <div className={classes.captionFloatRightContainer}>
                  <Caption className={`${classes.caption} ${"acceptNudgeBanner"}`}>Recommended</Caption>
                </div>
              </div>
              {answerToNudge === "accept" && chosenOption === "SAVE" && (
                <SecondaryButton
                  className={classes.saveAndExitButton}
                  startIcon={<SaveIcon />}
                  onClick={onSaveAndExitClickButton}
                  loading={updateServiceRequestLoading}
                  style={{ marginTop: "8px", marginLeft: "35px" }}
                >
                  Save and exit
                </SecondaryButton>
              )}
            </div>
          </div>
        </div>

        <div style={{ height: 16 }} />

        <div
          className={`${classes.fullWidthButton} ${answerToNudge === "decline" ? "highlightOption" : ""}`}
          onClick={() => {
            handleNudgeClick(false, ruleNudge.groupId!);
          }}
        >
          <div className={classes.nudgeButtonContainer}>
            <div style={{ display: "flex" }}>
              <div className={classes.buttonAndLabel}>
                {answerToNudge === "decline" ? (
                  <IconButton className={classes.radioButton}>
                    <RadioButtonCheckedIcon color="primary" />
                  </IconButton>
                ) : (
                  <IconButton
                    data-tracking-id="decline-nudge-option"
                    className={classes.radioButton}
                    onClick={() => {
                      handleNudgeClick(false, ruleNudge.groupId!);
                    }}
                  >
                    <RadioButtonUncheckedIcon />
                  </IconButton>
                )}
                <div>
                  <H6 className={classes.selectionText} style={textStyles}>
                    <b style={{ color: colorsLight.font.main }}>Continue without changes</b>
                  </H6>
                </div>
              </div>
              <div className={classes.captionFloatRightContainer}>
                <Caption className={`${classes.caption} ${"rejectNudgeBanner"}`}>
                  Request will pend for missing info
                </Caption>
              </div>
            </div>
            {answerToNudge === "decline" && (
              <div
                style={{
                  marginTop: "16px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "16px",
                }}
              >
                <SingleSelectDropdown
                  label="Select reason"
                  value={missingAttachmentsReason}
                  onChange={selectReasonOnChange}
                  fullWidth
                  options={possibleAttachmentNudgeReasonOptions}
                />
                <TextField
                  label={
                    missingAttachmentsReason === "OTHER" || missingAttachmentsReasonText
                      ? "Comment"
                      : "Comment (optional)"
                  }
                  value={missingAttachmentsReasonText}
                  onChangeValue={(value) => {
                    setFormContent((previousFormContent) => {
                      const oldObject = previousFormContent.possibleAttachmentNudgeReasons?.[0];
                      return {
                        ...previousFormContent,
                        possibleAttachmentNudgeReasons: [
                          {
                            ruleId: oldObject?.ruleId || ruleNudge.ruleId,
                            reason: oldObject?.reason,
                            reasonText: value,
                          },
                        ],
                      };
                    });
                  }}
                  fullWidth
                  multiline
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </ImpressionTracking>
  );
};

const useStyles = makeStyles((theme) => ({
  optionContainer: {
    paddingTop: theme.spacing(2),
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
  },
  radioButton: {
    padding: "0px",
  },
  centeredRadioButton: {
    padding: "0px",
    marginTop: "auto",
    marginBottom: "auto",
  },
  button: {
    "&.highlightOption": {
      border: `1px solid ${colorsLight.brand.blue}`,
    },
    width: "50%",
    borderRadius: theme.spacing(1),
    border: `1px solid ${colorsLight.gray.divider}`,
    cursor: "pointer",
  },
  fullWidthButton: {
    "&.highlightOption": {
      border: `2px solid ${colorsLight.brand.blue}`,
    },
    width: "100%",
    borderRadius: theme.spacing(1),
    border: `1px solid ${colorsLight.gray.divider}`,
    cursor: "pointer",
  },
  buttonAndLabel: {
    display: "flex",
    verticalAlign: "top",
  },

  title: {
    color: colorsLight.font.primary,
    paddingBottom: theme.spacing(2),
  },
  valueText: {
    display: "inline-block",
    wordWrap: "break-word",
    fontFamily: "Gilroy-Light",
    paddingTop: "2px",
  },
  grid: {
    wordWrap: "break-word",
  },
  component: {
    minHeight: theme.spacing(4),
    alignItems: "center",
    display: "flex",
    paddingBottom: theme.spacing(2),
  },
  container: {
    minHeight: theme.spacing(4),
  },
  captionContainer: {
    paddingBottom: theme.spacing(2),
    display: "flex",
    paddingLeft: theme.spacing(2),
  },
  nudgeButtonContainer: {
    display: "block",
    padding: "16px",
  },
  captionFloatRightContainer: {
    verticalAlign: "top",
    display: "flex",
    marginLeft: "auto",
    marginTop: "auto",
    marginBottom: "auto",
  },
  caption: {
    "&.acceptNudgeBanner": {
      backgroundColor: colorsLight.success.light,
      color: colorsLight.success.dark,
    },
    "&.rejectNudgeBanner": {
      backgroundColor: colorsLight.warning.light,
      color: colorsLight.warning.dark,
    },
    paddingTop: "5px",
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: "flex",
    borderRadius: theme.spacing(0.5),
  },
  optionText: {
    paddingBottom: theme.spacing(2),
  },
  selectionText: {
    color: colorsLight.font.secondary,
    paddingLeft: theme.spacing(1.5),
    alignItems: "center",
    height: theme.spacing(3),
    paddingTop: "2px",
    display: "inline-block",
    paddingRight: theme.spacing(2),
  },
  selectionCaption: {
    display: "inline-block",
    paddingLeft: theme.spacing(1.5),
    alignItems: "center",
    paddingTop: "2px",
    paddingRight: theme.spacing(2),
  },
  message: {
    color: colorsLight.font.secondary,
    paddingBottom: theme.spacing(3),
  },
  expandDateContainer: {
    width: "193.8%",
  },
  saveAndExitButton: {
    display: "flex",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1.5),
    marginTop: "2px",
    height: 54,
    width: 220,
  },
}));

const textStyles = {
  fontFamily: "Gilroy-Medium, sans-serif",
  fontWeight: "normal",
};

function isAllowedReason(reason?: string): reason is PossibleAttachmentNudgeReasons["reason"] {
  if (reason) {
    return ["DONT_KNOW_WHAT_INFO", "INFO_NOT_SPECIFIC_ENOUGH", "NO_ACCESS_TO_INFO", "DONT_HAVE_INFO", "OTHER"].includes(
      reason
    );
  }
  return false;
}
