import React from "react";
import { Body1, Caption } from "@coherehealth/common";
import Grid from "@material-ui/core/Grid";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const Column = styled((props) => <Grid item xs={4} {...props} />)(({ theme }) => ({
  borderRightWidth: 1,
  borderRightStyle: "solid",
  borderRightColor: theme.palette.divider,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  "&:first-child": {
    paddingLeft: theme.spacing(0),
  },
  "&:last-child": {
    border: "none",
    paddingRight: theme.spacing(0),
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const AttachmentTypeLabel = styled((props) => <Body1 color="textSecondary" {...props} />)(({ theme }) => ({
  paddingBottom: theme.spacing(0.5),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const Label = styled(Caption)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: "inline-block",
  paddingBottom: theme.spacing(0.5),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const FieldValue = styled((props) => <Body1 component="div" {...props} />)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  wordWrap: "break-word",
}));
