import { Checkbox } from "@coherehealth/common";
import { OOSCheckboxProps } from "../../mdReviewModalTypes";
import { Row, useModalStyles } from "../../../mdReviewStyles";
import { useTheme } from "@material-ui/core";

export const OOSCheckbox = ({ OOSCheck, setOOSCheck }: OOSCheckboxProps) => {
  const modalStyles = useModalStyles({});
  const theme = useTheme();
  return (
    <Row className={modalStyles.cbRow} style={{ padding: theme.spacing("5px", "0px", "0px", "0") }}>
      <Checkbox
        checked={OOSCheck}
        onChange={setOOSCheck}
        label={
          <span className={modalStyles.cbLabel}>
            I confirm that this case is OOS and there is more than 50% of TAT remaining
          </span>
        }
        data-testid={"review-modal-OOS-checkbox"}
      ></Checkbox>
    </Row>
  );
};
