import OktaAuth, { SessionObject } from "@okta/okta-auth-js";
import { CustomSessionObject } from "../types/oktaTypes";

/*
 * There are undocumented fields in practice on the Okta session which
 * we need to use to validate user identity. Check if it exists before proceeding.
 */
export function isCustomSessionObject(sessionObject: SessionObject): sessionObject is CustomSessionObject {
  if (!sessionObject.hasOwnProperty("userId")) {
    return false;
  }
  return true;
}

export const compareLocalToOktaUser = async (userSub: string | undefined, oktaAuth: OktaAuth): Promise<void> => {
  try {
    const userSession = await oktaAuth.session.get();
    if (!userSession || (userSession && !isCustomSessionObject(userSession))) {
      return;
    }
    if (userSub && userSession.userId && userSub !== userSession.userId) {
      oktaAuth.tokenManager.clear();
    }
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
};
