import { Body3, Caption, Sanitized, Subtitle2, formatDateStr, formatDateStrWithTz } from "@coherehealth/common";
import { Grid } from "@mui/material";
import { Body2 } from "@coherehealth/design-system";
import { NoteType } from "@coherehealth/core-platform-api";
import { Box, useTheme } from "@material-ui/core";
import { NotesContainer, useNotesCardStyles } from "../notesCardStyles";
import { buildSrGroupingTitle } from "../../infoPanelUtils";
import { NoteDisplay } from "../notesCardTypes";
import { optionIdToConfigLabel } from "components/ClinicalReview/reviewUtils/utils";
import { toSentenceCase } from "../noteUtils";

export const IndividualNote = ({
  note,
  view,
  index,
  serviceRequest,
  noteConfig,
  hasContinuations,
  isNewServiceRequest,
  isInFilteredState,
}: NoteDisplay) => {
  const notesCardStyles = useNotesCardStyles({});
  const theme = useTheme();

  const noteCaption = `${formatDateStrWithTz(note?.dateCreated)} by ${note?.createdByName}`;

  return (
    <>
      <Box marginTop={index === 0 ? "2px" : "16px"}>
        {serviceRequest && isNewServiceRequest && !isInFilteredState && (
          <Subtitle2 style={{ color: theme.palette.info.light, marginBottom: "8px" }}>
            {buildSrGroupingTitle(serviceRequest, hasContinuations)}
          </Subtitle2>
        )}
        {note?.noteType === "CLAIMS_PAYMENT" &&
          view === "REVIEW" &&
          note?.serviceRequest &&
          serviceRequest?.id === note.serviceRequest.id &&
          serviceRequest?.encounterType === "INPATIENT" && (
            <Grid container style={{ marginTop: "2px" }} key={`${note.id}_${note.dateCreated}_${view}`}>
              <Grid item key={note?.id} style={{ width: "100%" }}>
                <Subtitle2 style={{ color: theme.palette.info.light }}>
                  {buildSrGroupingTitle(serviceRequest, hasContinuations)}
                </Subtitle2>
              </Grid>
            </Grid>
          )}

        <Box display="flex" alignItems="flex-end">
          <Body2>{`${generateNoteHeader(note?.noteType) || ""} note`}</Body2>
          <Caption className={notesCardStyles.noteInfo}>{noteCaption}</Caption>
        </Box>
        <Box marginBottom="16px" marginTop={"2px"}>
          {note?.noteType === "D_SNP" && (
            <Grid container item xs={12} spacing={1} paddingTop={1}>
              <Grid item xs={4}>
                <Caption style={{ whiteSpace: "nowrap", overflow: "hidden" }}>D-SNP CH Code</Caption>
                <Grid>
                  <Body3>
                    {optionIdToConfigLabel(note.dSnpChCode, noteConfig?.dSnpChCodeOptions) || note?.dSnpChCode}
                  </Body3>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Caption style={{ whiteSpace: "nowrap", overflow: "hidden" }}>D-SNP MCO Confirmation</Caption>
                <Grid>
                  <Body3>
                    {optionIdToConfigLabel(note.dSnpMcoConfirmation, noteConfig?.dSnpMcoConfirmationOptions) ||
                      note.dSnpMcoConfirmation}
                  </Body3>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Caption style={{ whiteSpace: "nowrap", overflow: "hidden" }}>D-SNP Medicaid Recipient Number</Caption>
                <Grid>
                  <Body3>{note?.dSnpMedicaidRecipientNumber}</Body3>
                </Grid>
              </Grid>
            </Grid>
          )}
          {["ADMINISTRATIVE", "CONTINUITY_OF_CARE"].includes(note?.noteType || "") && (
            <NotesContainer>
              <Body2 component="div" sx={{ fontWeight: 500, lineHeight: "16px", color: "font.main", paddingTop: 1 }}>
                <Sanitized __html={note?.noteContent} />
              </Body2>
            </NotesContainer>
          )}
          {note?.noteType === "APPEALS" && (
            <>
              <Grid container item xs={12} spacing={1} paddingBottom={1} paddingTop={1}>
                <Grid item xs={4}>
                  <Caption style={{ whiteSpace: "nowrap", overflow: "hidden" }}>Requestor</Caption>
                  <Grid>
                    <Body3>{toSentenceCase(note.requestor)}</Body3>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Caption style={{ whiteSpace: "nowrap", overflow: "hidden" }}>Appeal requested on</Caption>
                  <Grid>
                    <Body3>{formatDateStr(note.appealRequestedOn)}</Body3>
                  </Grid>
                </Grid>
              </Grid>
              <NotesContainer>
                <Caption style={{ whiteSpace: "nowrap", overflow: "hidden" }}>Appeals note</Caption>
                <Body2 component="div" sx={{ fontWeight: 500, lineHeight: "16px", color: "font.main" }}>
                  <Sanitized __html={note?.noteContent} />
                </Body2>
              </NotesContainer>
            </>
          )}
          {note?.noteType === "SNF_THREE_DAY_WAIVER_NOTE" && (
            <Grid item xs={12} paddingBottom={0.3} paddingTop={1}>
              <Body3>{`This request ${note.hasSNFWaiver ? "has" : "does not have"} an SNF 3-day waiver`}</Body3>
            </Grid>
          )}
          {note?.noteType === "CLAIMS_PAYMENT" && (
            <Grid item xs={12} paddingBottom={0.3} paddingTop={1}>
              <Body3>{note?.claimsPaymentNote}</Body3>
            </Grid>
          )}
          {note?.noteType === "LIVANTA" && (
            <>
              <Grid container item xs={12} spacing={1} paddingBottom={1} paddingTop={1}>
                <Grid item xs={4}>
                  <Caption style={{ whiteSpace: "nowrap", overflow: "hidden" }}>Coverage ended by</Caption>
                  <Grid>
                    <Body3>{toSentenceCase(note.coverageEndedBy)}</Body3>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Caption style={{ whiteSpace: "nowrap", overflow: "hidden" }}>Appeal requested on</Caption>
                  <Grid>
                    <Body3>{formatDateStr(note.appealRequestedOn) || "--"}</Body3>
                  </Grid>
                </Grid>
              </Grid>
              <NotesContainer>
                <Caption style={{ whiteSpace: "nowrap", overflow: "hidden" }}>Appeals note</Caption>
                <Body2 component="div" sx={{ fontWeight: 500, lineHeight: "16px", color: "font.main" }}>
                  <Sanitized __html={note?.noteContent} />
                </Body2>
              </NotesContainer>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const generateNoteHeader = (noteType: NoteType | undefined) => {
  switch (noteType) {
    case "ADMINISTRATIVE":
      return "Administrative";
    case "D_SNP":
      return "Dual Special Needs Program (D-SNP)";
    case "SNF_THREE_DAY_WAIVER_NOTE":
      return "SNF 3-day waiver";
    case "CLAIMS_PAYMENT":
      return "Claims payment";
    case "APPEALS":
      return "Appeals";
    case "CONTINUITY_OF_CARE":
      return "Continuity of care";
    case "LIVANTA":
      return "Livanta";
  }
};
