import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Caption } from "@coherehealth/design-system";
import { getCreatedByName, isTerminalStatus } from "../../../util/serviceRequest";
import { getRequestorPhone } from "../../ServiceRequest/ReadonlyDetail/RequestDetails";
import { Link } from "@mui/material";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import { colorsLight, InlineButton, useFeature } from "@coherehealth/common";
import { ContactInfoModalProps, RequestorInfoModal } from "../../ContactInfoModal";
import {
  RequestorChannel,
  ServiceRequestResponse,
  ServiceRequestSearchResponse,
  UserResponse,
  useGetUserById,
} from "@coherehealth/core-platform-api";
import { strToPhoneNumber } from "util/phoneUtils";
import { CircularProgress } from "@material-ui/core";
import { EditRequestorInfoModal } from "components/RequestorInfoModal/EditRequestorInfoModal";
import { useAuthorized } from "authorization";

interface RequestedInfoProps {
  serviceRequest: ServiceRequestResponse;
  clinicalReviewView?: boolean;
  setIsContactInfoModalOpen?: Dispatch<SetStateAction<boolean>>;
  serviceRequestRefetch: () => Promise<void>;
}

interface OpenModalProps {
  serviceRequest: ServiceRequestResponse | ServiceRequestSearchResponse;
  userDetails: UserResponse | null;
  setModalState: React.Dispatch<React.SetStateAction<ContactInfoModalProps>>;
  closeModal: () => void;
}

function openRequestorModal({ serviceRequest, userDetails, setModalState, closeModal }: OpenModalProps) {
  const requestorName = getCreatedByName(serviceRequest);
  const requestorPhoneNumber = getRequestorPhone(serviceRequest);
  if (!!userDetails) {
    const userPhone = userDetails.phone ? strToPhoneNumber(userDetails.phone) : undefined;
    setModalState({
      open: true,
      name: userDetails.name ?? requestorName,
      phones: [userPhone ?? requestorPhoneNumber],
      type: "REQUESTOR",
      fax: undefined,
      email: userDetails.email,
      requestorType: undefined,
      aorReceivedTimestamp: undefined,
      handleClose: closeModal,
    });
  } else {
    setModalState({
      open: true,
      name: requestorName,
      phones: [requestorPhoneNumber],
      type: "REQUESTOR",
      fax: [serviceRequest.requestor?.faxNumber],
      email: serviceRequest.requestor?.email ?? serviceRequest.requestor?.user?.email,
      requestorType: serviceRequest.requestor?.requestorType,
      aorReceivedTimestamp: serviceRequest.requestor?.aorReceivedTimestamp,
      handleClose: closeModal,
    });
  }
}
export default function RequestedByInfo({
  serviceRequest,
  clinicalReviewView,
  setIsContactInfoModalOpen,
  serviceRequestRefetch,
}: RequestedInfoProps) {
  const requestorName = getCreatedByName(serviceRequest);
  const modeOfSubmission = channelDisplayName[serviceRequest.requestor?.channel || "PORTAL"];
  const useEpisodicAuthFunctionality = useFeature("episodicAuth");
  const isEpisodicAuth = serviceRequest.episodeGeneratedFromServiceRequest && useEpisodicAuthFunctionality;

  const isLegacyPortalSubmission = !Boolean(serviceRequest.requestor);
  const isUpdatedPortalSubmission = Boolean(serviceRequest.requestor?.isFormSubmittedWithoutUserInput);
  const isPortalSubmission = isLegacyPortalSubmission || isUpdatedPortalSubmission;

  const requestorLine = `Requested ${!isPortalSubmission ? "for" : "by"} ${requestorName} - ${modeOfSubmission}`;
  return (
    <div>
      <Caption sx={{ color: colorsLight.font.light }} data-public>
        {requestorLine}
      </Caption>
      <ViewInfoButton
        serviceRequest={serviceRequest}
        clinicalReviewView={clinicalReviewView}
        isEpisodicAuth={isEpisodicAuth}
        setIsContactInfoModalOpen={setIsContactInfoModalOpen}
        serviceRequestRefetch={serviceRequestRefetch}
      />
    </div>
  );
}

interface ViewInfoButtonProps {
  serviceRequest: ServiceRequestResponse | ServiceRequestSearchResponse;
  fontSize?: string;
  isEpisodicAuth?: boolean;
  clinicalReviewView?: boolean;
  setIsContactInfoModalOpen?: Dispatch<SetStateAction<boolean>>;
  serviceRequestRefetch?: () => Promise<void>;
}

export const ViewInfoButton = ({
  serviceRequest,
  fontSize,
  clinicalReviewView,
  isEpisodicAuth,
  setIsContactInfoModalOpen,
  serviceRequestRefetch,
}: ViewInfoButtonProps) => {
  const { loading: loadingUser, refetch: getUserDetails } = useGetUserById({
    id: serviceRequest?.createdBy ?? "",
    lazy: true,
  });
  const [modalState, setModalState] = useState<ContactInfoModalProps>({
    open: false,
    handleClose: () => {
      setModalState((prev) => ({ ...prev, open: false }));
    },
    type: "FACILITY",
  });
  useEffect(() => {
    if (setIsContactInfoModalOpen) {
      setIsContactInfoModalOpen(modalState.open);
    }
  }, [modalState, setIsContactInfoModalOpen]);

  const closeModal = () => setModalState((prev) => ({ ...prev, open: false }));
  const classes = useStyles();
  const requestorFormAuthorized = useAuthorized("REQUESTOR_FORM");
  const isRequestInTerminalStatus = isTerminalStatus(serviceRequest);
  const isAllowedToEditRequestorInfo = requestorFormAuthorized && !isRequestInTerminalStatus;

  const viewInfoOnClick = async () => {
    if (!serviceRequest?.requestor) {
      try {
        // if there is no requestor data get user info if possible
        const userDetails = await getUserDetails({ pathParams: { id: serviceRequest.createdBy } });
        openRequestorModal({ serviceRequest, userDetails, setModalState, closeModal });
      } catch (e) {
        openRequestorModal({ serviceRequest, userDetails: null, setModalState, closeModal });
      }
    } else {
      openRequestorModal({ serviceRequest, userDetails: null, setModalState, closeModal });
    }
  };

  return (
    <>
      {!isEpisodicAuth && (
        <>
          <Link
            hidden={
              !(serviceRequest.requestor?.firstName && serviceRequest.requestor.lastName) &&
              !serviceRequest.createdByName
            }
            underline="none"
            sx={{ marginLeft: "6px" }}
          >
            {loadingUser && <CircularProgress color="inherit" style={{ marginLeft: "10px" }} size={20} />}
            {!loadingUser && (
              <ContactButton fontSize={fontSize ?? "13px"} onClick={viewInfoOnClick} className={classes.viewInfoBtn}>
                View info
              </ContactButton>
            )}
          </Link>
          {isAllowedToEditRequestorInfo ? (
            <EditRequestorInfoModal
              {...modalState}
              serviceRequestId={serviceRequest.id}
              requestor={serviceRequest.requestor}
              serviceRequestRefetch={serviceRequestRefetch}
            />
          ) : (
            <RequestorInfoModal {...modalState} />
          )}
        </>
      )}
    </>
  );
};

interface ContactButtonProps {
  fontSize: string;
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ContactButton = styled(InlineButton)(({ fontSize }: ContactButtonProps) => ({
  padding: "0",
  fontSize: fontSize,
}));

export const channelDisplayName: Record<RequestorChannel, string> = {
  FAX: "Fax",
  PHONE: "Phone",
  EMAIL: "Email",
  MAIL: "Mail",
  PORTAL: "Portal",
  INTERNAL_API_REQUEST: "Internal Request",
  PORTAL_EHR: "Portal - EHR",
};

const useStyles = makeStyles((theme) => ({
  viewInfoBtn: {
    "& :hover": {
      backgroundColor: colorsLight.action.hoverBackground,
      borderRadius: 4,
    },
    "& :focus": {
      backgroundColor: colorsLight.action.hoverBackground,
      borderRadius: 4,
    },
  },
}));
