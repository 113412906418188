import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";
import { Patient } from "@coherehealth/core-platform-api";

// Define the interface for the context
export interface PatientContextInterface {
  patientData: Patient | undefined;
  setPatientData: (patientData: Patient | undefined) => void;
}

// Default state for the context
const patientDefaultState = {
  patientData: undefined,
  setPatientData: (patientData: Patient | undefined) => {},
} as PatientContextInterface;

// Create the context
export const PatientContext = createContext(patientDefaultState);

type PatientContextProviderProps = {
  children: ReactNode;
};

// Context provider component
const PatientContextProvider = ({ children }: PatientContextProviderProps) => {
  const [patientData, setPatientData] = useState<Patient | undefined>(undefined);

  const setPatientDataContext = useCallback((patientData: Patient | undefined) => {
    setPatientData(patientData);
  }, []);

  const value = useMemo(
    () => ({ patientData, setPatientData: setPatientDataContext }),
    [patientData, setPatientDataContext]
  );

  return <PatientContext.Provider value={value}>{children}</PatientContext.Provider>;
};

export default PatientContextProvider;

// Custom hook to use the PatientContext
export const usePatientContext = () => {
  const context = useContext(PatientContext);
  if (!context) {
    throw new Error("usePatientContext must be used within a PatientContextProvider");
  }
  return context;
};
