import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import {
  ServiceRequestResponse,
  UserActivity,
  PlaceOfService,
  useUpdateServiceRequest,
  useGetPlaceOfServices,
  useGetServiceRequestRuleActions,
} from "@coherehealth/core-platform-api";

import { CircularProgress, Link, makeStyles } from "@material-ui/core";
import {
  colorsLight,
  Pill,
  H5,
  InlineButton,
  Body2,
  Body1,
  Sanitized,
  parseDateFromISOString,
} from "@coherehealth/common";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PlaceOfServiceSelect from "components/ServiceRequest/ServiceRequestForm/components/PlaceOfServiceSelect";
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from "notistack";
import { GetDataError } from "restful-react";
import { Autorenew, DeleteOutline, AddCircleOutline } from "@material-ui/icons";
import {
  activitySnapshotFromServiceRequest,
  convertRequestFieldsToTrackingField,
  useTrackUserInteraction,
} from "util/userActivityTracker";
import applyActionToServiceRequest from "util/rule/applyActionToServiceRequest";
import { isRecommendChangeAction, ValidDisplayMessageAction } from "util/rule";
import { oldValueText, newValueText, procedureCodesText, getRuleNudgeProcedureCode } from "util/NudgeUtils";
import { getPatientHealthPlanName } from "../../util/patientUtils";
import { convertStringToAuthBuilderWorkflowStep } from "components/AuthBuilder/common";

interface Props {
  originalRequest: ServiceRequestResponse;
  updatedRequest: ServiceRequestResponse;
  setServiceRequest: (sr: ServiceRequestResponse) => void;
  action?: ValidDisplayMessageAction;
  onEdit?: (sr: ServiceRequestResponse) => void;
  updateServiceRequest: ReturnType<typeof useUpdateServiceRequest>["mutate"];
  updateRequestError?: GetDataError<unknown> | null;
  refetchActions?: ReturnType<typeof useGetServiceRequestRuleActions>["refetch"];
  context?: UserActivity["activityContext"];
  addAcceptedNudge: (newAcceptedNudge: ValidDisplayMessageAction) => void;
  refetchServiceRequest: () => void;
  serviceRequestSubmitted: boolean;
}

const useStyles = makeStyles((theme) => ({
  card: {
    transition: `background-color 1.5s ease`,
    padding: theme.spacing(2),
    backgroundColor: colorsLight.white.highEmphasis,
    flexDirection: "column",
    border: `1px solid ${colorsLight.background.light}`,
    boxSizing: "border-box",
    boxShadow: "0px 2px 2px rgba(207, 226, 231, 0.8)",
    borderRadius: theme.spacing(0.5),
    "&.accepted": {
      backgroundColor: colorsLight.success.light,
      border: `1px solid ${colorsLight.success.light}`,
    },
  },
  nonBoldH5: {
    fontFamily: "Gilroy-Light",
    paddingTop: "2px",
  },
  container: {
    transition: theme.transitions.create(["padding", "maxHeight"]),
    paddingTop: "3px",
    display: "flex",
    flexDirection: "row",
    maxHeight: 100,
    "&.buttons": {
      paddingTop: "15px",
    },
    overflowY: "hidden",
    "&.accepted": {
      padding: 0,
      maxHeight: 0,
    },
  },
  nudgeText: {
    paddingTop: "2px",
    display: "flex",
    flexDirection: "row",
    height: "fit-content",
    width: "100%",
    alignItems: "center",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
    animation: "fadeIn 1.5s ease",
    transition: "display 1.5s ease",
    "&.notLoading": {
      display: "none",
      animation: "fadeOut 1.5s ease",
    },
  },
  acceptPill: {
    backgroundColor: colorsLight.success.transparent,
  },
  pill: {
    whiteSpace: "initial",
  },
  posDropdown: {
    transition: theme.transitions.create(["padding", "maxHeight"]),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0),
    maxHeight: 100,
    overflowY: "hidden",
    "&.accepted": {
      padding: 0,
      maxHeight: 0,
    },
  },
  arrow: {
    margin: theme.spacing(0, 0.5, 0, 0.5),
    color: colorsLight.primary.main,
    "&.accepted": {
      color: colorsLight.success.main,
    },
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    maxHeight: 100,
    overflowY: "hidden",
    paddingTop: "12px",
  },
  acceptButton: {
    marginTop: "auto",
    marginRight: 0,
    padding: 0,
    color: colorsLight.primary.dark,
    "&.noInfo": {
      marginLeft: "auto",
    },
    "&.MuiButton-root": {
      minWidth: "0px",
    },
  },
  infoButton: {
    marginTop: "auto",
    marginRight: theme.spacing(2),
    marginLeft: "auto",
    padding: 0,
    color: colorsLight.font.light,
  },
  acceptIcon: {
    opacity: 0,
    "&.accepted": {
      marginTop: "auto",
      marginLeft: "auto",
      color: colorsLight.success.main,
      transition: theme.transitions.create("opacity"),
      opacity: 1,
    },
  },
  acceptCPT: {
    marginRight: theme.spacing(1),
    color: colorsLight.primary.main,
    "&.accepted": {
      color: colorsLight.success.main,
      transition: theme.transitions.create("opacity"),
    },
  },
}));

export default function NudgeCard({
  originalRequest,
  updatedRequest,
  setServiceRequest,
  action,
  onEdit,
  refetchActions,
  updateServiceRequest,
  updateRequestError,
  context,
  addAcceptedNudge,
  refetchServiceRequest,
  serviceRequestSubmitted,
}: Props) {
  // Accepted is used for post validation (when we accept it).
  // InitialAccpet is used to initialize loading transition while we set the field for them.
  const [accepted, setAccepted] = useState(false);
  const [initialAccept, setInitialAccept] = useState(false);
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const isEncounterTypeNudge = action?.onAcceptAttribute === "encounterType";
  const [staticServiceRequest] = useState<ServiceRequestResponse>(originalRequest);
  const [dynamicPlaceOfService, setDynamicPlaceOfService] = useState<PlaceOfService | undefined>(
    isEncounterTypeNudge ? undefined : originalRequest.placeOfService
  );
  const validAccept = !isEncounterTypeNudge || Boolean(dynamicPlaceOfService);
  const classes = useStyles({ accepted });
  const { enqueueSnackbar } = useSnackbar();
  const pillLabelText =
    convertGroupIdToTitle(action?.groupId || "", originalRequest) +
    convertRequestFieldsToStrings(action?.onAcceptAttribute || "Field", originalRequest, action!) +
    (initialAccept && !accepted ? " updating" : accepted ? " changed" : "");
  const trackUserInteraction = useTrackUserInteraction();

  useEffect(() => {
    if (updateRequestError) {
      enqueueSnackbar(`Error updating service request: ${updateRequestError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, updateRequestError]);

  const onAccept = async () => {
    setInitialAccept(true);
    const newSR = applyActionToServiceRequest(
      {
        ...updatedRequest,
        placeOfService: dynamicPlaceOfService,
        urgency: staticServiceRequest.urgency,
        procedureCodes: staticServiceRequest.procedureCodes,
      },
      action
    );

    if (newSR) {
      delete updatedRequest.formConfiguration;
      const retVal = await updateServiceRequest({
        ...newSR,
        patient: updatedRequest.patient?.id,
        carePathJourney: updatedRequest.carePathJourney?.id,
        clinicalService: updatedRequest.clinicalService?.id,
        palCategory: (newSR.palCategory || updatedRequest.palCategory)?.id,
        primarySemanticDiagnosisCode: newSR.primaryDiagnosis || updatedRequest.primaryDiagnosis,
        placeOfService: (newSR.placeOfService || updatedRequest.placeOfService)?.id,
        orderingProvider: undefined,
        performingProvider: undefined,
        facility: undefined,
        reconClaim: undefined,
        semanticProcedureCodes: newSR.semanticProcedureCodes || updatedRequest.semanticProcedureCodes,
      });
      onEdit?.(retVal);
      setServiceRequest(retVal);
      await onHandleInteraction(retVal, true);
      refetchServiceRequest();
    }
    setAttemptedSubmit(true);
    if (validAccept) {
      setAccepted(true);
      if (action?.onAcceptAttribute) {
        addAcceptedNudge(action);
      }
    } else {
      setInitialAccept(false);
    }
    setTimeout(
      () =>
        refetchActions?.({
          pathParams: {
            id: originalRequest.id,
          },
          queryParams: {
            displayTarget: "PRE_SUBMISSION_REVIEW",
            authStage: convertStringToAuthBuilderWorkflowStep("REVIEW"),
          },
        }),
      1500
    );
  };

  const onHandleInteraction = useCallback(
    async (retVal: ServiceRequestResponse, interactionAccept: boolean) => {
      await trackUserInteraction({
        event: "FIELD_RECOMMENDED_CHANGE",
        stage: "AUTH_SUMMARY",
        interactionAccept: interactionAccept,
        activityContext: context,
        field: convertRequestFieldsToTrackingField(action?.onAcceptAttribute),
        beforeSnapshot: activitySnapshotFromServiceRequest(staticServiceRequest),
        afterSnapshot: activitySnapshotFromServiceRequest(retVal),
      });
    },
    [action?.onAcceptAttribute, context, staticServiceRequest, trackUserInteraction]
  );

  useEffect(() => {
    if (serviceRequestSubmitted) {
      onHandleInteraction(updatedRequest, false);
    }
  }, [onHandleInteraction, serviceRequestSubmitted, updatedRequest]);

  const procedureCodeNudgeIcon = () => {
    switch (action?.onAcceptAction) {
      case "REPLACE_VALUE":
        return <Autorenew className={classes.acceptCPT} />;
      case "LIMIT_TO_VALUES":
        return <DeleteOutline className={classes.acceptCPT} />;
      case "ADD_VALUES":
        return <AddCircleOutline className={classes.acceptCPT} />;
      case "REMOVE_VALUES":
        return <DeleteOutline className={classes.acceptCPT} />;
    }
  };

  return (
    <div className={`${classes.card}${accepted ? " accepted" : ""}`}>
      {action?.onAcceptAttribute === "units" && (
        <Pill
          label={pillLabelText}
          variant={accepted ? "success" : "info"}
          className={accepted ? classes.acceptPill : classes.pill}
          dataPublic={true}
        />
      )}
      <div className={classes.container}>
        {action?.onAcceptAttribute === "procedureCodes" ? (
          <>
            {procedureCodeNudgeIcon()}
            <H5 data-public>{procedureCodesText(action, staticServiceRequest)}</H5>
          </>
        ) : (
          <div className={classes.nudgeText}>
            <H5 className={classes.nonBoldH5} data-public>
              {oldValueText(action, staticServiceRequest)}
            </H5>
            {accepted ? (
              <ArrowForwardIcon
                aria-label="Success Arrow"
                className={`${classes.arrow}${accepted ? " accepted" : ""}`}
              />
            ) : (
              <ArrowForwardIcon
                aria-label="Forward Arrow"
                className={`${classes.arrow}${accepted ? " accepted" : ""}`}
              />
            )}
            <H5 data-public>{newValueText(action, updatedRequest)}</H5>
          </div>
        )}
        <CheckCircleIcon className={`${classes.acceptIcon}${accepted ? " accepted" : ""}`} />
      </div>
      {action?.message && (
        <div className={`${classes.container}${accepted ? " accepted" : ""}`}>
          <Body1 style={{ color: colorsLight.font.light }} data-public>
            {initialAccept && !accepted ? (
              "Updating your request to provide higher value care..."
            ) : (
              <Sanitized __html={action.message} />
            )}
          </Body1>
        </div>
      )}
      {isEncounterTypeNudge && (
        <div className={`${classes.posDropdown}${initialAccept ? " accepted" : ""}`}>
          <PlaceOfServiceSelectWrapper
            action={action}
            enqueueSnackbar={enqueueSnackbar}
            dynamicPlaceOfService={dynamicPlaceOfService}
            setDynamicPlaceOfService={setDynamicPlaceOfService}
            attemptedSubmit={attemptedSubmit}
            originalRequest={originalRequest}
            updatedRequest={updatedRequest}
          />
        </div>
      )}
      {action && isRecommendChangeAction(action) && (
        <div className={`${classes.container}${initialAccept ? " accepted" : ""}${" buttons"}`}>
          {action?.guidelineUrl && (
            <InlineButton
              className={classes.infoButton}
              disabled={attemptedSubmit && !validAccept}
              href={action?.guidelineUrl}
              target="_blank"
              rel="noopener"
              component={Link}
            >
              <Body2>More information</Body2>
            </InlineButton>
          )}
          <InlineButton
            className={`${classes.acceptButton}${Boolean(action?.guidelineUrl) ? "" : " noInfo"}`}
            disabled={attemptedSubmit && !validAccept}
            onClick={onAccept}
          >
            <Body2 style={{ paddingRight: "0px" }}>Accept</Body2>
          </InlineButton>
        </div>
      )}
      <div className={`${classes.loading}${!initialAccept || accepted ? " notLoading" : ""}`}>
        <CircularProgress />
      </div>
    </div>
  );
}

const convertRequestFieldsToStrings = (
  field: string,
  serviceRequest: ServiceRequestResponse,
  action?: ValidDisplayMessageAction
) => {
  const nudgeClinicalService = getRuleNudgeProcedureCode(action, serviceRequest);
  if (nudgeClinicalService && nudgeClinicalService.unitType === "Number of visits") {
    return "Visits";
  } else if (nudgeClinicalService && nudgeClinicalService.unitType === "Units") {
    return "Units";
  } else if (field === "procedureCodes") {
    return "Procedure codes";
  } else if (field === "encounterType") {
    return "Place type";
  } else if (field === "placeOfService") {
    return "Place of service";
  } else if (field === "urgency.isExpedited") {
    return "Expedited";
  } else {
    return field;
  }
};

const convertGroupIdToTitle = (groupId: string, serviceRequest: ServiceRequestResponse) => {
  if (groupId === "parent") {
    return "";
  } else {
    const cs = serviceRequest?.clinicalServices?.find((cs) => cs?.id === groupId);
    return cs ? `${cs?.name}: ` : "";
  }
};

const PlaceOfServiceSelectWrapper = ({
  action,
  enqueueSnackbar,
  dynamicPlaceOfService,
  setDynamicPlaceOfService,
  attemptedSubmit,
  originalRequest,
  updatedRequest,
}: {
  action: ValidDisplayMessageAction;
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey;
  dynamicPlaceOfService: PlaceOfService | undefined;
  setDynamicPlaceOfService: Dispatch<SetStateAction<PlaceOfService | undefined>>;
  attemptedSubmit: boolean;
  originalRequest: ServiceRequestResponse;
  updatedRequest: ServiceRequestResponse;
}) => {
  const patientHealthPlanName =
    getPatientHealthPlanName(originalRequest.patient || undefined, parseDateFromISOString(updatedRequest.startDate)) ||
    "";

  const {
    data: placeOfServiceData,
    loading: placeOfServiceLoading,
    error: getPlaceOfServiceError,
  } = useGetPlaceOfServices({
    queryParams: {
      healthPlanName: patientHealthPlanName || undefined,
      encounterType: action?.onAcceptValue?.value === "INPATIENT" ? "INPATIENT" : "OUTPATIENT",
      clinicalServiceId: updatedRequest.clinicalService?.id,
    },
  });

  const availablePlacesOfService: PlaceOfService[] | undefined = placeOfServiceData ? placeOfServiceData : undefined;

  useEffect(() => {
    if (getPlaceOfServiceError) {
      enqueueSnackbar(`Error getting places of service ${getPlaceOfServiceError.message}`, { variant: "error" });
    }
  }, [getPlaceOfServiceError, enqueueSnackbar]);

  return (
    <PlaceOfServiceSelect
      isInpatient={action?.onAcceptValue?.value === "INPATIENT"}
      placeOfService={dynamicPlaceOfService || null}
      setPlaceOfService={(pos) => setDynamicPlaceOfService(pos || undefined)}
      error={attemptedSubmit && !Boolean(dynamicPlaceOfService)}
      authStatus="DRAFT"
      startDate={originalRequest.startDate}
      patient={originalRequest.patient}
      availablePlacesOfService={availablePlacesOfService}
      placeOfServiceLoading={placeOfServiceLoading}
    />
  );
};
