import { Dispatch, useContext } from "react";

import { queueMgmtBaseUrl, SelectedPatientInfo, useFeature } from "@coherehealth/common";
import { Patient, ReferralRequestResponse, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import { CommonFaxSidebarViewProps, backToMenuView, getServiceRequestsFaxAttachedTo } from "../common";
import { FaxAttachmentContext } from "../FaxAttachmentContext";
import { useCompleteServiceCase } from "@coherehealth/qm-api";
import config from "api/config";
import CommonFaxFooter from "../CommonFaxFooter";
import { AttachedServiceRequestCard } from "./AttachedServiceRequestCard";

interface Props extends CommonFaxSidebarViewProps {
  selectedPatient?: Patient;
  setSelectedPatient: Dispatch<Patient | undefined>;
  attachedToServiceRequests: ServiceRequestResponse[];
  attachedToReferralRequest: ReferralRequestResponse;
}

const useStyles = makeStyles((theme) => ({
  serviceRequestResultsContainer: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
  },
  fullWidthDivider: {
    margin: theme.spacing(4, -3, 3),
  },
  fullWidthBottomDivider: {
    margin: theme.spacing(2, -3, -2),
  },
  scrollableResultList: {
    overflowY: "auto",
    overflowX: "hidden",
    flex: "1 1 0",
    paddingBottom: theme.spacing(15.5),
    padding: theme.spacing(4, 3, 3),
    margin: theme.spacing(0, -3),
  },
  resultListHeader: {
    paddingBottom: theme.spacing(1),
  },
  restartButtonHeader: {
    marginTop: theme.spacing(6),
  },
  restartButton: {
    margin: theme.spacing(2, 0),
  },
  buttonContainer: {
    display: "flex",
    padding: theme.spacing(3, 3),
    margin: theme.spacing(0, -3),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

export default function AttachedServiceRequests({
  selectedPatient,
  setSelectedPatient,
  fileData,
  setSidebarView,
  refreshFileData,
  attachedToServiceRequests,
  attachedToReferralRequest,
}: Props) {
  const classes = useStyles();
  const attachedToData = getServiceRequestsFaxAttachedTo(fileData);
  const addAttachmentToServiceRequestRefactorFF = useFeature("addAttachmentToServiceRequestRefactor");

  const { caseId } = useContext(FaxAttachmentContext);
  const { mutate: updateServiceCase, loading: serviceCaseLoading } = useCompleteServiceCase({
    id: caseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
    onMutate: () => {
      window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${caseId}`);
    },
  });
  function onFinish() {
    if (attachedToServiceRequests.length > 0) {
      updateServiceCase({
        outcome: "FAX_ATTACHED",
        dateCompleted: new Date().toISOString(),
        description: "Fax attached",
        serviceRequests: attachedToServiceRequests.map((attachedToServiceRequest) => {
          return {
            serviceRequestId: attachedToServiceRequest.id,
            cohereId: attachedToServiceRequest.cohereId,
            dateCreated: new Date(attachedToServiceRequest.dateCreated).toISOString(),
            status: attachedToServiceRequest.authStatus,
          };
        }),
      });
    } else {
      updateServiceCase({
        outcome: "FAX_ATTACHED_TO_RF",
        dateCompleted: new Date().toISOString(),
        description: "Fax attached to RF",
        referralRequest: {
          referralRequestId: attachedToReferralRequest.id,
          cohereId: attachedToReferralRequest.cohereId,
          dateCreated: new Date(attachedToReferralRequest.dateCreated).toISOString(),
          status: attachedToReferralRequest.referralStatus,
        },
      });
    }
  }
  const disabledFinish = attachedToServiceRequests.some((sr) => sr.authStatus === "DRAFT");
  return (
    <div className={classes.serviceRequestResultsContainer}>
      <Divider className={classes.fullWidthDivider} />
      {selectedPatient && (
        <>
          <SelectedPatientInfo patient={selectedPatient} />
          <Divider className={classes.fullWidthBottomDivider} />
        </>
      )}
      <div className={classes.scrollableResultList}>
        <Grid container spacing={2}>
          {attachedToServiceRequests.map((attachedToServiceRequest, idx) => {
            const srId = attachedToServiceRequest.id;
            const srAttachmentId = attachedToData.find(
              (srData) => srData.id === attachedToServiceRequest.id
            )?.linkedAttachment;

            if (!srId || !srAttachmentId) {
              return null;
            }

            return (
              <Grid item xs={12} key={srId}>
                <AttachedServiceRequestCard
                  serviceRequest={attachedToServiceRequest}
                  serviceRequestAttachmentId={srAttachmentId}
                  afterRemoveAttachment={() => {
                    refreshFileData?.();
                    if (!Boolean(addAttachmentToServiceRequestRefactorFF)) {
                      if (attachedToServiceRequests.length === 1) {
                        // redirect back to actions if removing the only attachedServiceRequest
                        setSelectedPatient(undefined);
                        setSidebarView(backToMenuView);
                      }
                    }
                  }}
                  selectedPatient={selectedPatient}
                  setPatient={idx === 0 ? setSelectedPatient : undefined}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
      <CommonFaxFooter
        primaryButtonLoading={serviceCaseLoading}
        onPrimaryButtonClick={onFinish}
        disabledFinish={disabledFinish}
        setSidebarView={setSidebarView}
        showBackButton={true}
        onCancelButtonClick={() => {
          setSelectedPatient(undefined);
          setSidebarView(backToMenuView);
        }}
      />
    </div>
  );
}
