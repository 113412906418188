import { IntegrationStatus, KafkaIntegrationStatus } from "@coherehealth/core-platform-api";
import { IntegrationStatus as ObservabilityIntegrationStatus } from "@coherehealth/int-obs-api";
import { Chip, ChipType, formatDateStrAsEastern, Tooltip } from "@coherehealth/common";
import React from "react";

interface SingleIntegrationProps {
  status?: IntegrationStatus | KafkaIntegrationStatus | ObservabilityIntegrationStatus;
  lastUpdated?: string | undefined;
  name: string;
}

export function SingleIntegration({ status, name, lastUpdated }: SingleIntegrationProps) {
  const chipType = SingleIntegrationChipTypeMap[status || "not started"];
  return (
    <Tooltip title={tooltipText(status) + (lastUpdated ? ` Last updated: ${formatDateStrAsEastern(lastUpdated)}` : "")}>
      <Chip
        style={{ marginRight: 8, marginBottom: 8 }}
        type={chipType}
        label={`${name} (${status?.toLocaleLowerCase() || "not started"})`}
        dataPublic={true}
      />
    </Tooltip>
  );
}

export const SingleIntegrationChipTypeMap: Record<
  IntegrationStatus | KafkaIntegrationStatus | "not started" | ObservabilityIntegrationStatus | "NOT_STARTED",
  ChipType
> = {
  // Core-platform integration statuses
  READY: "default",
  SENT: "default",
  REQUEST_ERROR: "error",
  FAILED: "error",
  PROCESSING: "default",
  COMPLETE: "success",
  INTERNALLY_COMPLETE: "success",
  WITHDRAWN: "disabled",

  //Kafka integration statuses
  FAILURE: "error",
  SUCCESS: "success",
  RETRIED: "default",

  "not started": "default",
  PENDING: "default",
  ALARMED: "error",
  STAGED: "default",
  UNDELIVERABLE: "error",
  SKIPPED: "default",
  AWAITING_CALLBACK: "default",
  NOT_STARTED: "default",
};

export const STATUS_TEXT_MAP: Record<
  IntegrationStatus | KafkaIntegrationStatus | ObservabilityIntegrationStatus,
  string
> = {
  // Core-platform integration statuses
  READY: "Ready to send, but not yet sent. If this persists for too long, it indicates an application issue.",
  SENT: "Sending complete, waiting on response from vendor.",
  REQUEST_ERROR: "An application error occurred on our side which prevented sending. This should automatically retry.",
  FAILED: "Permanently failed, and will not automatically resend. This requires intervention.",
  PROCESSING: "Currently attempting to send this. If this persists for too long, it indicates an application issue.",
  COMPLETE: "Sending complete, no action needed.",
  INTERNALLY_COMPLETE: "Internally generated letter, no action needed",
  WITHDRAWN: "Withdrawn from being eligible for integration.",

  //Kafka integration statuses
  FAILURE: "Permanently failed, and will not automatically resend. This requires intervention.",
  SUCCESS: "Sending complete, no action needed.",
  RETRIED:
    "In the process of retrying. Refresh and check back on this status. If this persists for too long, it indicates an application issue.",
  PENDING: "Pending",
  ALARMED: "Alarmed",
  STAGED: "Staged",
  UNDELIVERABLE: "Undeliverable",
  SKIPPED: "Skipped",
  AWAITING_CALLBACK: "Awaiting Callback",
  NOT_STARTED:
    "No integration status yet. Typically this means this is not ready to send to this integration. Otherwise, this indicates an application issue",
};

const tooltipText = (status?: IntegrationStatus | KafkaIntegrationStatus | ObservabilityIntegrationStatus): string =>
  (status && STATUS_TEXT_MAP[status]) ||
  "No integration status yet. Typically this means this is not ready to send to this integration. Otherwise, this indicates an application issue";
