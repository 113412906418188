import React, { Dispatch, SetStateAction, MutableRefObject, useState, useEffect, useContext } from "react";
import {
  ServiceRequestResponse,
  OutreachOpportunity,
  Patient,
  SearchInfo,
  CopyPasteInfo,
  AttachmentGuidelineTextHighlightsSnapshot,
  useSearchOutreachAttempts,
  ReviewType,
  AuthorizationResponse,
  Attachment,
  MdReview,
  PeerToPeerReview,
  NurseReview,
  useGetServiceRequestNotes,
  AppealResponse,
} from "@coherehealth/core-platform-api";
import {
  AttachmentInfo,
  parseDateFromISOString,
  useFeature,
  UserClickInfoClinicalReviewPage,
} from "@coherehealth/common";
import ClinicalReviewInfoPanel from "../ClinicalReviewInfoPanel";
import { ServiceCase, useSearchServiceCase } from "@coherehealth/qm-api";
import config from "api/config";
import CaseHistoryContent from "../ViewOnlyReview/CaseHistory";
import ClinicalReviewAttachmentViewer from "../AttachmentViewer/AttachmentViewerShell";
import { useSnackbar } from "notistack";
import {
  extractErrorDetails,
  getIsMedicaidUnder21,
} from "components/ServiceRequest/ReviewSection/util/ReviewSectionUtil";
import { makeStyles } from "@material-ui/core";
import { ReturnRequestDetailsBanner } from "../ReturnRequestDetails/ReturnRequestDetailsBanner";
import ClinicalConversationsReviewBanner from "../ClinicalConversationsReviewBanner/ClinicalConversationsReviewBanner";
import { isPostDenialP2P, LeftPanelTabs } from "./utils";
import { ClinicalReviewContext } from "../Review/ClinicalReviewPage";
import ClaimHistory from "components/ClaimHistory/ClaimHistory";
import { UseClaimHistoryProps } from "components/ClaimHistory/useClaimHistory";
import { UseOtherAuthorizationProps } from "../ClinicalReviewInfoPanel/OtherServiceRequests/useOtherAuthorization";
import ReadmissionBanner from "common/ReadmissionBanner";
import PurpleClipboard from "components/images/PurpleClipboard";
import ServiceCaseAttachmentViewerShell from "../AttachmentViewer/ServiceCaseAttachmentViewerShell";

interface LeftPanelProps extends Partial<UseClaimHistoryProps>, UseOtherAuthorizationProps {
  authorization?: AuthorizationResponse;
  refreshAuthorization?: () => Promise<void>;
  authorizationFetchLoading?: boolean;
  serviceRequest: ServiceRequestResponse | null;
  attachments: Attachment[] | undefined;
  appealsForAuth?: AppealResponse[] | undefined;
  tabOpen: LeftPanelTabs;
  setTabOpen: React.Dispatch<React.SetStateAction<LeftPanelTabs>>;
  userClickInfoTracking?: UserClickInfoClinicalReviewPage;
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
  setSearchInfos?: Dispatch<SetStateAction<SearchInfo[]>>;
  attachmentIndexOpen: number;
  previousAttachmentsExpanded?: boolean;
  setPreviousAttachmentsExpanded?: Dispatch<React.SetStateAction<boolean>>;
  currentPage: MutableRefObject<number>;
  zoomLevel: MutableRefObject<number>;
  currentRotate: MutableRefObject<number>;
  serviceRequestLoading: boolean;
  attachmentsLoading?: boolean;
  existingReviews: ReviewType[] | null;
  serviceRequestRefetch: () => Promise<ServiceRequestResponse | null>;
  patient: Patient | null;
  patientLoading: boolean;
  outreachOpportunities: OutreachOpportunity[] | null;
  otherServiceRequests: ServiceRequestResponse[] | null;
  otherServiceRequestsLoading: boolean;
  newDenialsWorkflow?: boolean;
  viewOnly?: boolean;
  setCopiedAttachmentText?: Dispatch<SetStateAction<CopyPasteInfo | undefined>>;
  serviceCases?: ServiceCase[] | null;
  trackingNumberSearch?: boolean;
  caseId?: string;
  serviceRequestId: string;
  setAttachmentGuidelineTextHighlightsSnapshot?: Dispatch<SetStateAction<AttachmentGuidelineTextHighlightsSnapshot>>;
  crrDenials?: boolean;
  denialReview?: MdReview | PeerToPeerReview | NurseReview | null;
  canViewGenericNotes?: boolean;
  onViewAttachment: (index: number, landingPage?: number) => void;
  clinicalReview?: ReviewType;
  attachmentsInfo: AttachmentInfo[];
  setAttachmentsInfo: React.Dispatch<React.SetStateAction<AttachmentInfo[]>>;
  canUseClaimHistoryFeature?: boolean;
  isDenialReviewPage?: boolean;
  currServiceRequestID?: string;
  currentServiceCase?: ServiceCase | null;
  attachmentSeachAcrossAttachmentsAllowed?: boolean;
}

const ReviewLeftPanel = (props: LeftPanelProps) => {
  const {
    authorization,
    refreshAuthorization,
    authorizationFetchLoading,
    serviceRequest,
    attachments,
    appealsForAuth,
    tabOpen,
    setTabOpen,
    userClickInfoTracking,
    setUserClickInfoTracking,
    setSearchInfos,
    attachmentIndexOpen,
    previousAttachmentsExpanded,
    setPreviousAttachmentsExpanded,
    currentPage,
    zoomLevel,
    currentRotate,
    serviceRequestLoading,
    attachmentsLoading,
    existingReviews,
    serviceRequestRefetch,
    patient,
    patientLoading,
    outreachOpportunities,
    otherServiceRequests,
    otherServiceRequestsLoading,
    newDenialsWorkflow,
    viewOnly,
    setCopiedAttachmentText,
    serviceCases,
    trackingNumberSearch,
    caseId,
    serviceRequestId,
    setAttachmentGuidelineTextHighlightsSnapshot,
    crrDenials,
    denialReview,
    canViewGenericNotes,
    onViewAttachment,
    clinicalReview,
    attachmentsInfo,
    setAttachmentsInfo,
    canUseClaimHistoryFeature,
    isDenialReviewPage = false,
    currServiceRequestID,
    currentServiceCase,
    otherAuthsProps,
    claimHistoryProps,
    attachmentSeachAcrossAttachmentsAllowed,
  } = props;

  // All completed service cases other than FAX_INTAKE
  const [otherCompletedServiceCases, setOtherCompletedServiceCases] = useState<ServiceCase[]>([]);
  const [isReadmissionBannerOpen, setIsisReadmissionBannerOpen] = useState<boolean>(true);

  const { enqueueSnackbar } = useSnackbar();

  const returnLetterCaseToReviewerFF = useFeature("returnLetterCaseToReviewer");
  const medicaidUnder21ClinicalConversationsFF = useFeature("medicaidUnder21ClinicalConversations");
  const isReadmissionFeatureEnabled = useFeature("readmissionIdentification");
  const postDecisionP2PExpanded = useFeature("postDecisionP2PExpanded");

  const classes = useStyles();

  const { patientPrimaryCoverage } = useContext(ClinicalReviewContext);

  const isP2PReview = clinicalReview?.reviewType === "PeerToPeerReview";
  const postDenialP2P = isPostDenialP2P(clinicalReview, serviceRequest?.authStatus);
  const isMedicaidUnder21 = getIsMedicaidUnder21(patient, patientPrimaryCoverage);
  const notifyMdAboutClinicalReview = isP2PReview && !postDenialP2P && isMedicaidUnder21;
  let readmissionBannerHeader = "";
  let readmissionBannerBodyText = "";
  if (clinicalReview?.reviewType === "MdReview") {
    readmissionBannerHeader = "This SR was marked as a readmission by the clinical reviewer";
    readmissionBannerBodyText = "Please verify this as part of your review";
  } else if (clinicalReview?.reviewType === "PeerToPeerReview") {
    if (
      serviceRequest?.authStatus === "POST_DENIAL_PEER_TO_PEER" &&
      serviceRequest?.initialDecisionDisposition === "APPROVED"
    ) {
      readmissionBannerHeader = "This SR was approved, but was indicated to be a readmission";
      readmissionBannerBodyText =
        "The readmission decision may be upheld or overturned, but the auth status will stay approved";
    }
  }

  const {
    loading: searchServiceCaseLoading,
    error: getSearchServiceCasesError,
    mutate: SearchServiceCase,
  } = useSearchServiceCase({
    base: `${config.QM_SERVICE_API_URL}`,
  });

  const { refetch: searchOutreachAttemptsByServiceRequestId, data: outreachAttempts } = useSearchOutreachAttempts({
    queryParams: {
      serviceRequestId: serviceRequestId || "",
    },
    lazy: true,
  });

  const reviewTimelineCardFeature = useFeature("reviewTimelineCard");

  const {
    data: serviceRequestNotes,
    loading: notesLoading,
    error: notesError,
    refetch: fetchSrNotes,
  } = useGetServiceRequestNotes({ serviceRequestId: serviceRequestId, lazy: true });

  useEffect(() => {
    if (notesError) {
      enqueueSnackbar(`Error fetching notes: ${extractErrorDetails(notesError).message}`, { variant: "error" });
    }
  }, [notesError, enqueueSnackbar]);

  useEffect(() => {
    const maybeFetchNotes = async () => {
      if ((serviceRequestId && canViewGenericNotes && !reviewTimelineCardFeature) || !authorization?.id) {
        fetchSrNotes();
      }
    };
    maybeFetchNotes();
  }, [fetchSrNotes, serviceRequestId, canViewGenericNotes, reviewTimelineCardFeature, authorization]);

  useEffect(() => {
    if (serviceRequestId) {
      searchOutreachAttemptsByServiceRequestId();
    }
  }, [searchOutreachAttemptsByServiceRequestId, serviceRequestId]);

  useEffect(() => {
    let componentIsMounted = true;
    const getCompletedServiceCases = async () => {
      const retrievedCompletedServiceCases = await SearchServiceCase({
        serviceRequestId: serviceRequestId,
        caseStatusList: ["COMPLETE"],
        max: 100,
        isPaginationOff: true,
      });
      if (componentIsMounted) {
        setOtherCompletedServiceCases(retrievedCompletedServiceCases);
      }
    };
    if (serviceRequestId && serviceRequestId.length > 0 && config.HAS_QM_ENVIRONMENT) {
      getCompletedServiceCases();
    }

    return () => {
      componentIsMounted = false;
    };
  }, [SearchServiceCase, serviceRequestId]);

  const allCompleteServiceCases =
    serviceCases && Array.isArray(serviceCases) && serviceCases?.length <= 1000
      ? serviceCases
          .concat(otherCompletedServiceCases)
          .sort(
            (a, b) =>
              parseDateFromISOString(b.outcome?.dateCompleted).getTime() -
              parseDateFromISOString(a.outcome?.dateCompleted).getTime()
          )
      : null;

  const completedServiceCase = allCompleteServiceCases?.find((item) => item.id === caseId) || null;

  switch (tabOpen) {
    case "REQUEST_INFORMATION":
      return (
        <>
          {notifyMdAboutClinicalReview && medicaidUnder21ClinicalConversationsFF && (
            <ClinicalConversationsReviewBanner notificationBannerClass={classes.notificationBanner} />
          )}
          {returnLetterCaseToReviewerFF && (
            <ReturnRequestDetailsBanner
              currentClinicalReview={clinicalReview ?? null}
              notificationBannerClass={classes.notificationBanner}
            />
          )}
          {isReadmissionFeatureEnabled &&
            serviceRequest?.isReadmission &&
            serviceRequest?.encounterType === "INPATIENT" &&
            (clinicalReview?.reviewType === "MdReview" || clinicalReview?.reviewType === "PeerToPeerReview") &&
            postDecisionP2PExpanded &&
            readmissionBannerHeader !== "" &&
            readmissionBannerBodyText !== "" && (
              <ReadmissionBanner
                header={readmissionBannerHeader}
                bodyText={readmissionBannerBodyText}
                isOpen={isReadmissionBannerOpen}
                onDismissedClicked={setIsisReadmissionBannerOpen}
                icon={<PurpleClipboard />}
                bannerClassName={classes.notificationBanner}
              />
            )}
          <ClinicalReviewInfoPanel
            id="tabpanel-REQUEST_INFORMATION"
            authorization={authorization}
            refreshAuthorization={refreshAuthorization}
            authorizationFetchLoading={authorizationFetchLoading}
            patient={patient}
            patientLoading={patientLoading}
            serviceRequest={serviceRequest}
            currServiceRequestID={currServiceRequestID}
            serviceRequestLoading={serviceRequestLoading}
            existingReviews={existingReviews}
            outreachOpportunities={outreachOpportunities}
            otherServiceRequests={otherServiceRequests}
            otherServiceRequestsLoading={otherServiceRequestsLoading}
            serviceRequestId={serviceRequest?.id || ""}
            userClickInfoTracking={userClickInfoTracking}
            setUserClickInfoTracking={setUserClickInfoTracking}
            serviceRequestRefetch={serviceRequestRefetch}
            newDenialsWorkflow={newDenialsWorkflow}
            viewOnly={viewOnly}
            crrDenials={crrDenials}
            review={denialReview}
            notes={
              reviewTimelineCardFeature && authorization?.id
                ? authorization?.notes?.filter((note) => note.serviceRequest.id === serviceRequestId)
                : serviceRequestNotes || []
            }
            notesLoading={reviewTimelineCardFeature ? authorizationFetchLoading : notesLoading}
            canViewGenericNotes={canViewGenericNotes}
            isDenialReviewPage={isDenialReviewPage}
            otherAuthsProps={otherAuthsProps}
            setTabOpen={setTabOpen}
            outreachAttempts={outreachAttempts}
          />
        </>
      );
    case "ATTACHMENTS":
      return (
        <>
          {serviceRequestId ? (
            <ClinicalReviewAttachmentViewer
              id="tabpanel-ATTACHMENTS"
              serviceRequestId={serviceRequestId}
              attachments={attachments}
              appealsForAuth={appealsForAuth}
              attachmentIndexOpen={attachmentIndexOpen}
              previousAttachmentsExpanded={previousAttachmentsExpanded}
              setPreviousAttachmentsExpanded={setPreviousAttachmentsExpanded}
              setUserClickInfoTracking={setUserClickInfoTracking}
              setSearchInfos={setSearchInfos}
              currentPage={currentPage}
              zoomLevel={zoomLevel}
              currentRotate={currentRotate}
              orderBySegmentation
              setCopiedAttachmentText={setCopiedAttachmentText}
              setAttachmentGuidelineTextHighlightsSnapshot={setAttachmentGuidelineTextHighlightsSnapshot}
              isContinuationRequest={serviceRequest?.requestType === "CONTINUATION"}
              attachmentsLoading={attachmentsLoading}
              onViewAttachment={onViewAttachment}
              attachmentsInfo={attachmentsInfo}
              setAttachmentsInfo={setAttachmentsInfo}
              serviceRequest={serviceRequest}
              attachmentSeachAcrossAttachmentsAllowed={attachmentSeachAcrossAttachmentsAllowed}
            />
          ) : (
            currentServiceCase &&
            currentServiceCase.caseType === "FAX_INTAKE" && (
              <ServiceCaseAttachmentViewerShell
                id="tabpanel-ATTACHMENTS"
                serviceCase={currentServiceCase}
                userClickInfoTracking={userClickInfoTracking}
                setUserClickInfoTracking={setUserClickInfoTracking}
                attachmentIndexOpen={attachmentIndexOpen}
                currentPage={currentPage}
                zoomLevel={zoomLevel}
                currentRotate={currentRotate}
                onViewAttachment={onViewAttachment}
                attachments={attachments}
                attachmentsLoading={attachmentsLoading}
              />
            )
          )}
        </>
      );
    case "CLAIM_HISTORY":
      return (
        <>
          {patient && canUseClaimHistoryFeature && claimHistoryProps && (
            <ClaimHistory claimHistoryProps={claimHistoryProps} />
          )}
        </>
      );
    case "EVENT_HISTORY":
      return (
        <>
          {
            <CaseHistoryContent
              id="tabpanel-EVENT_HISTORY"
              serviceCase={completedServiceCase}
              trackingNumberSearch={trackingNumberSearch}
              serviceCases={allCompleteServiceCases ? allCompleteServiceCases : null}
              serviceCasesLoading={searchServiceCaseLoading}
              getServiceRequestCasesError={getSearchServiceCasesError}
              outreachAttempts={outreachAttempts || []}
              serviceRequestId={serviceRequestId}
            />
          }
        </>
      );
    default:
      return null;
  }
};

const useStyles = makeStyles((theme) => ({
  notificationBanner: {
    marginTop: theme.spacing(1.5),
  },
}));

export default ReviewLeftPanel;
