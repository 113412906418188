/**
 * Wrapper around printjs, to allow "printing" (aka downloading) on IE11
 */

import { downloadOrOpenUrl, useIE11Download } from "@coherehealth/common";
import printJS from "print-js";

const print = (url: string, type: printJS.PrintTypes = "pdf") => {
  if (type === "pdf" && useIE11Download) {
    downloadOrOpenUrl(url, "print.pdf");
  } else {
    printJS({ printable: url, type });
  }
};

export default print;
