import { Caption, H2, Modal } from "@coherehealth/common";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  open: boolean;
}

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    padding: theme.spacing(5, 1, 10),
  },
  header: {
    padding: theme.spacing(3, 0),
  },
}));

export default function LoadingAssessmentModal({ open }: Props) {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={() => {}} hideX contentClassName={classes.content}>
      <CircularProgress size={64} />
      <H2 className={classes.header}>
        We’re finding the relevant assessment for your request. This could take a minute.
      </H2>
      <Caption>Your request has been saved as a draft in the meantime</Caption>
    </Modal>
  );
}
