import React, { useEffect, useState } from "react";

import { usePostClinicalAssessment, ClinicalAssessment, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { H4, H5, PrimaryButton, SecondaryButton } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { useTheme, styled } from "@material-ui/core/styles";
import {
  ClinicalAssessmentContext,
  EditWithAssessmentContext,
  useClinicalAssessmentReducer,
} from "components/ClinicalAssessment";
import { error as logError } from "logger";
import { useSnackbar } from "notistack";
import { clearNotShownClinicalQuestions, incompleteRequiredQuestions } from "util/clinicalAssessment";

import { SectionHeaderContainer } from "./ServiceRequestSummaryDetails";
import { ClinicalAssessmentContextProviderProps } from "components/ClinicalAssessment/ClinicalAssessmentContext";

interface Props {
  clinicalAssessment: ClinicalAssessment;
  setAssessmentState: (state: "EDIT" | "VIEW") => void;
  onComplete: (clinicalAssessment: ClinicalAssessment) => void;
  crrCardView?: boolean;
  serviceRequest: Pick<ServiceRequestResponse, "id" | "authStatus" | "delegatedVendor" | "healthPlanName" | "type">;
}

export default function ClinicalAssessmentEdit({
  clinicalAssessment: initialClinicalAssessment,
  setAssessmentState,
  onComplete,
  crrCardView,
  serviceRequest,
  ...props
}: Props & ClinicalAssessmentContextProviderProps) {
  const [clinicalAssessment, updateClinicalAssessment] = useClinicalAssessmentReducer({ initialClinicalAssessment });
  const [subQuestions, updateSubQuestions] = useState(props.initialSubQuestions);
  const { loading, error, mutate } = usePostClinicalAssessment({});
  const { enqueueSnackbar } = useSnackbar();
  const { spacing } = useTheme();
  useEffect(() => {
    if (error) {
      enqueueSnackbar(`Failed to post assessment: ${error.message}`, { variant: "error" });
    }
  }, [error, enqueueSnackbar]);
  const submitAndContinue = async () => {
    try {
      if (clinicalAssessment && clinicalAssessment.questions) {
        const answeredClinicalAssessmentQuestions = clearNotShownClinicalQuestions(clinicalAssessment.questions);
        const updatedClinicalAssessment = await mutate({
          ...clinicalAssessment,
          questions: answeredClinicalAssessmentQuestions,
        });
        onComplete(updatedClinicalAssessment);
      }
    } catch (e) {
      logError(new Error(`Error submitting clinical assessment ${e}`));
    }
  };

  return (
    <ClinicalAssessmentContext.Provider
      value={{ clinicalAssessment, updateClinicalAssessment, subQuestions, updateSubQuestions }}
    >
      <SectionHeaderContainer style={{ paddingTop: 0, paddingBottom: crrCardView ? 20 : 24 }}>
        {crrCardView ? <H5>Assessment questions</H5> : <H4>Clinical assessment</H4>}
        <ButtonContainer>
          <SecondaryButton
            warning
            style={{ width: "100%", marginRight: spacing(2) }}
            onClick={() => setAssessmentState("VIEW")}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            disabled={Boolean(loading || incompleteRequiredQuestions(clinicalAssessment, subQuestions).length > 0)}
            style={{ width: "100%" }}
            onClick={() => {
              submitAndContinue().then(() => setAssessmentState("VIEW"));
            }}
          >
            Save
          </PrimaryButton>
        </ButtonContainer>
      </SectionHeaderContainer>
      <EditWithAssessmentContext doNotWrapQuestionsWithCard />
    </ClinicalAssessmentContext.Provider>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ButtonContainer = styled("div")({
  display: "flex",
});
