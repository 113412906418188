import { Theme, makeStyles } from "@material-ui/core";
import { NoticationBanner } from "common/NotificationBanner";
import InfoIcon from "@mui/icons-material/Info";
import { Caption } from "@coherehealth/common";

export function ClinicalConversationBanner(): JSX.Element {
  const classes = useStyles();

  const ClinicalConversationMessage = (
    <div className={classes.bodyWrapper} data-testid={"clinical-conversation-banner"}>
      <div className={classes.iconWrapper}>
        <InfoIcon className={classes.icon} />
      </div>
      <Caption className={classes.caption}>
        This authorization is for a Geisinger Medicaid under 21 member. Pending to MD review will send the request to
        clinical conversation scheduling.
      </Caption>
    </div>
  );

  return <NoticationBanner bannerClassName={classes.banner} contentComponent={ClinicalConversationMessage} />;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  bodyWrapper: {
    display: "flex",
    width: "auto",
    alignItems: "center",
    margin: "0",
  },
  icon: {
    maxWidth: 24,
    color: theme.palette.info.light,
  },
  banner: {
    padding: 8,
    textAlign: "left",
  },
  iconWrapper: {
    display: "flex",
    maxWidth: 32,
    paddingRight: 8,
    alignItems: "center",
  },
  caption: {
    alignSelf: "initial",
  },
}));
