import React from "react";
import Grid from "@material-ui/core/Grid";
import { Body1, H2, Modal, PrimaryButton } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";

interface Props {
  loading: boolean;
  isOpen: boolean;
  setIsOpen(arg0: boolean): void;
  onFinalizeDenial: (event: any) => Promise<void>;
}

export default function DenyServiceRequestModal({ loading, isOpen, setIsOpen, onFinalizeDenial }: Props) {
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <Grid container spacing={4} justify="center" alignItems="center" style={{ textAlign: "center" }}>
        <Grid item>
          <H2>Warning</H2>
        </Grid>
        <Grid item>
          <Body1>
            You are about to make a final denial determination. Only finalize Denial if a Peer-to-Peer Review has not
            been scheduled in the appropriate time frame.
          </Body1>
        </Grid>
        <Grid item>
          <PrimaryErrorButton loading={loading} onClick={onFinalizeDenial}>
            Finalize Denial
          </PrimaryErrorButton>
        </Grid>
      </Grid>
    </Modal>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PrimaryErrorButton = styled(PrimaryButton)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  "&:hover,&:focus": {
    backgroundColor: theme.palette.error.main,
  },
}));
