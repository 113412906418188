import { registerRoute } from "../server";
import { Response } from "miragejs";
import getPath from "lodash/get";

// Mirage expects relationships to be specified with *Id properties, so rename some of the submitted fields
function transformForSchema(submittedProperties: any) {
  const {
    patient,
    palCategory,
    primaryDiagnosis,
    placeOfService,
    orderingProvider,
    performingProvider,
    facility,
    ...newProperties
  } = submittedProperties;

  Object.assign(newProperties, {
    patientId: patient,
    palCategoryId: palCategory,
    primaryDiagnosisId: primaryDiagnosis,
    placeOfServiceId: placeOfService,
    orderingProviderId: orderingProvider,
    performingProviderId: performingProvider,
    facilityId: facility,
  });
  return newProperties;
}

export default function registerRoutes() {
  registerRoute({
    id: "serviceRequestCreate",
    route: "serviceRequest",
    method: "post", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      const requestBody = JSON.parse(request.requestBody);
      const newProperties = transformForSchema(requestBody);
      return (schema as any).serviceRequests.create(newProperties);
    },
  });

  registerRoute({
    id: "serviceRequestGet",
    route: "serviceRequest/:id",
    method: "get", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      const urlParams = request.params;
      return (schema as any).serviceRequests.find(urlParams.id);
    },
  });

  registerRoute({
    id: "serviceRequestUpdate",
    route: "serviceRequest/:id",
    method: "patch", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      const urlParams = request.params;
      const requestBody = JSON.parse(request.requestBody);
      //console.log("Patch", urlParams.id, requestBody);
      const model = (schema as any).serviceRequests.find(urlParams.id);
      if (model) {
        const newProperties = transformForSchema(requestBody);
        //console.log("With properties", newProperties);
        model.update(newProperties);
      }
      return (schema as any).serviceRequests.find(urlParams.id);
    },
  });

  registerRoute({
    id: "serviceRequestSearch",
    route: "serviceRequest",
    method: "get", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      const queryParams = request.queryParams;
      let result = (schema as any).serviceRequests.all();

      for (const parameterKey in queryParams) {
        const filterKey =
          parameterKey === "patient" ? "patient.id" : parameterKey;
        const [operator, value] = queryParams[parameterKey].split(":");
        if (operator === "eq") {
          result.models = result.models.filter(
            (s: any) => getPath(s, filterKey) === value
          );
        } else if (operator === "in") {
          result.models = result.models.filter((s: any) =>
            value.split(",").includes(getPath(s, filterKey))
          );
        }
      }
      return result;
    },
  });

  registerRoute({
    id: "serviceRequestTrackingNumber-post",
    route: "serviceRequest/generateTrackingNumber",
    method: "post", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      return { trackingNumber: "ABCD1234" };
    },
  });

  registerRoute({
    id: "serviceRequestSearch-post",
    route: "serviceRequest/search",
    method: "post", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      const queryParams = JSON.parse(request.requestBody);
      let result = (schema as any).serviceRequests.all();

      for (const parameterKey in queryParams) {
        const filterKey =
          parameterKey === "patient" ? "patient.id" : parameterKey;
        const s = queryParams[parameterKey];
        if (s instanceof String) {
          const [operator, value] = s.split(":");
          if (operator === "eq") {
            result.models = result.models.filter(
              (s: any) => getPath(s, filterKey) === value
            );
          } else if (operator === "in") {
            result.models = result.models.filter((s: any) =>
              value.split(",").includes(getPath(s, filterKey))
            );
          }
        }
      }
      return result;
    },
  });

  registerRoute({
    id: "serviceRequestAttachmentsGet",
    route: "serviceRequest/:id/attachment",
    method: "get",
    generateResponse: function (config, schema, request) {
      const urlParams = request.params;
      let name = `newAttachment${Date.now()}`;
      return [
        (schema as any).serviceRequestAttachments.create({
          serviceRequestId: urlParams.id,
          attachmentSource: "USER",
          createdByName: "Lolo Jones",
          dateCreated: "1982-08-05T00:00:00Z",
          name,
          size: 806298,
          type: "Clinical note",
          contentType: "image/jpeg",
          url: "/static/media/highResMri.9866c6d3.jpg",
        }),
      ];
    },
  });

  registerRoute({
    id: "serviceRequestAttachmentCreate",
    route: "serviceRequest/:id/attachment",
    method: "post", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      const urlParams = request.params;
      let name = `newAttachment${Date.now()}`;
      let size = 0;
      let contentType = "";
      const requestBody: unknown = request.requestBody;
      if (requestBody instanceof FormData) {
        const file = requestBody.get("file");
        if (file) {
          name = (file as File).name;
          size = (file as File).size;
          contentType = (file as File).type;
        }
      }
      return (schema as any).serviceRequestAttachments.create({
        serviceRequestId: urlParams.id,
        attachmentSource: "USER",
        name,
        size,
        contentType,
        type: undefined,
      });
    },
  });

  registerRoute({
    id: "serviceRequestAttachmentUpdate",
    route: "serviceRequest/:id/attachment/:attachmentId",
    method: "patch", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      const { attachmentId } = request.params;
      const requestBody = JSON.parse(request.requestBody);
      const model = (schema as any).serviceRequestAttachments.find(
        attachmentId
      );
      if (model) {
        model.update({
          type: requestBody.type,
        });
        return model;
      } else {
        return new Response(
          404,
          {},
          "Raw error response from server, don't display this!"
        );
      }
    },
  });

  registerRoute({
    id: "serviceRequestAttachmentDelete",
    route: "serviceRequest/:id/attachment/:attachmentId",
    method: "delete", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      const { attachmentId } = request.params;
      const model = (schema as any).serviceRequestAttachments.find(
        attachmentId
      );
      if (model) {
        model.destroy();
        return null;
      } else {
        return new Response(
          404,
          {},
          "Raw error response from server, don't display this!"
        );
      }
    },
  });
}
