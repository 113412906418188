import React, { useEffect } from "react";

import { Patient } from "@coherehealth/core-platform-api";
import { makeStyles } from "@material-ui/core/styles";
import { H4, SecondaryButton, Subtitle2 } from "@coherehealth/common";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import PatientSearchResultCard from "common/PatientSearchCard/PatientSearchResultCard";
import { useAuthorized } from "authorization";
import { useGetTemporaryMemberAuthsConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

interface Props {
  createFaxNotice: () => void;
  hideResults: boolean;
  onPatientSelect: (patient: Patient) => void;
  onTransferAuth?: (event: React.MouseEvent, receiverPatientId: string) => void;
  patients?: Patient[];
  patientSelectLoading: boolean;
  healthPlanName: string;
  isAttachmentSidebar?: boolean;
}

const useStyles = makeStyles((theme) => ({
  searchResultsContainer: {
    opacity: 1,
    transition: theme.transitions.create("opacity"),
    "&.hideResults": {
      opacity: 0,
    },
    flex: 1,
  },
  patientSearchResultCard: {
    alignItems: "center",
    border: `1px solid ${theme.palette.divider}`,
    minHeight: 100,
  },
  patientResultsDivider: {
    margin: theme.spacing(4, -3),
  },
  patientListNoResults: {
    paddingBottom: theme.spacing(4),
  },
  patientListWithResults: {
    marginBottom: theme.spacing(4),
  },
}));

export default function PatientResultsList({
  createFaxNotice,
  hideResults,
  onPatientSelect,
  onTransferAuth,
  patients,
  patientSelectLoading,
  healthPlanName,
  isAttachmentSidebar,
}: Props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { data: temporaryMemberAuthsConfig, error: temporaryMemberAuthsConfigError } =
    useGetTemporaryMemberAuthsConfigurationByPayer(healthPlanName);
  const temporaryMemberAuthsEnabled = temporaryMemberAuthsConfig?.enabled;
  const canAddPlaceholderMember = useAuthorized("CREATE_PLACEHOLDER_PATIENT");
  useEffect(() => {
    if (temporaryMemberAuthsConfigError) {
      const errorMsg = "Error fetching temporaryMemberAuths configuration";
      enqueueSnackbar(errorMsg, { preventDuplicate: true });
    }
  }, [temporaryMemberAuthsConfigError, enqueueSnackbar]);

  return (
    <div className={`${classes.searchResultsContainer}${hideResults ? " hideResults" : ""}`}>
      <Divider className={classes.patientResultsDivider} />
      {!patients || patients.length === 0 ? (
        <Grid container spacing={2} className={classes.patientListNoResults}>
          <Grid item xs={8}>
            <H4>No patients match your search criteria</H4>
          </Grid>
          {canAddPlaceholderMember && temporaryMemberAuthsEnabled ? (
            <>
              <Grid item xs={12}>
                <Subtitle2>
                  Check your information and search again, or create a fax notice, or add a patient with a temporary ID
                </Subtitle2>
              </Grid>
              <Grid item xs={12}>
                <SecondaryButton
                  startIcon={<OpenInNewIcon />}
                  fullWidth
                  component={Link}
                  to="/add_new_patient"
                  target={"_blank"}
                >
                  Add patient with a temporary ID
                </SecondaryButton>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Subtitle2>
                  Check your information and search again, or send a fax notice back to the provider
                </Subtitle2>
              </Grid>
              <Grid item xs={12}>
                <SecondaryButton fullWidth onClick={createFaxNotice} disabled={hideResults}>
                  Create fax notice
                </SecondaryButton>
              </Grid>
            </>
          )}
        </Grid>
      ) : (
        <Grid container spacing={2} className={`${classes.searchResultsContainer} ${classes.patientListWithResults}`}>
          {patients.map((patient) => (
            <Grid item xs={12} key={patient.id}>
              <PatientSearchResultCard
                key={patient.id}
                cardProps={{ className: classes.patientSearchResultCard }}
                onTransferAuth={onTransferAuth}
                patient={patient}
                selectOnClick={onPatientSelect}
                selectActionLoading={patientSelectLoading}
                showCoverageEndDate
                isAttachmentSidebar={isAttachmentSidebar}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
}
