import React, { forwardRef } from "react";
import { lighten, makeStyles, Theme } from "@material-ui/core/styles";
import { SnackbarProvider as BaseProvider, CustomContentProps, SnackbarContent } from "notistack";
import CancelIcon from "@material-ui/icons/Cancel";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@material-ui/icons/Info";
import { colorsDark } from "../../themes";
import { Alert } from "../Alert";

export interface StyleProps {
  style: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  variantSuccess: (props) => ({
    backgroundColor:
      props.style === "light"
        ? notistackImportantHack(theme.palette.success.light)
        : notistackImportantHack(colorsDark.success.light),
    color:
      props.style === "light"
        ? notistackImportantHack(theme.palette.success.main)
        : notistackImportantHack(colorsDark.success.dark),
    iconColor: colorsDark.warning.light,
  }),

  variantError: (props) => ({
    backgroundColor:
      props.style === "light"
        ? notistackImportantHack(theme.palette.error.light)
        : notistackImportantHack(colorsDark.error.light),
    color:
      props.style === "light"
        ? notistackImportantHack(theme.palette.error.main)
        : notistackImportantHack(colorsDark.error.dark),
    message:
      props.style === "light"
        ? notistackImportantHack(theme.palette.error.main)
        : notistackImportantHack(colorsDark.error.dark),
  }),

  variantWarning: (props) => ({
    backgroundColor:
      props.style === "light"
        ? notistackImportantHack(theme.palette.warning.light)
        : notistackImportantHack(colorsDark.warning.light),
    color:
      props.style === "light"
        ? notistackImportantHack(theme.palette.warning.dark)
        : notistackImportantHack(colorsDark.warning.dark),
  }),

  variantInfo: (props) => ({
    backgroundColor:
      props.style === "light"
        ? notistackImportantHack(lighten(theme.palette.info.light, 0.8))
        : notistackImportantHack(colorsDark.info.faint10),
    color:
      props.style === "light"
        ? notistackImportantHack(theme.palette.info.main)
        : notistackImportantHack(colorsDark.info.dark),
    message:
      props.style === "light"
        ? notistackImportantHack(theme.palette.info.main)
        : notistackImportantHack(colorsDark.info.dark),
  }),

  message: {
    ...theme.typography.body2,
  },
  lessPadding: {
    paddingLeft: 16,
    borderRadius: 5,
  },
  shadow: {
    boxShadow: `
      0 2px 4px 0 rgba(0, 0, 0, 0.14),
      0 3px 4px 0 rgba(0, 0, 0, 0.12),
      0 1px 5px 0 rgba(0, 0, 0, 0.2)
`,
  },
}));

// Hackaround to https://github.com/iamhosseindhv/notistack/issues/305
const notistackImportantHack = (ipt: string): string => `${ipt} !important`;

// compare with https://github.com/iamhosseindhv/notistack/blob/master/src/utils/defaultIconVariants.tsx
const iconStyles = {
  fontSize: 20,
  marginRight: 16,
  width: 24,
  height: 24,
};

const getIconStyle = (color: string) => ({
  ...iconStyles,
  color: notistackImportantHack(color),
});

const SnackbarProvider = ({ children, theme }: { children: React.ReactNode; theme: Theme }) => {
  const getColor = (colorType: "success" | "warning" | "error" | "info") => {
    switch (colorType) {
      case "success":
        return theme.palette.type === "dark" ? colorsDark.success.main : theme.palette.success.main;
      case "warning":
        return theme.palette.type === "dark" ? colorsDark.warning.dark : theme.palette.warning.main;
      case "error":
        return theme.palette.type === "dark" ? colorsDark.error.main : theme.palette.error.main;
      case "info":
        return theme.palette.type === "dark" ? colorsDark.info.dark : theme.palette.info.dark;
      default:
        return theme.palette.text.primary; // Blue default color
    }
  };

  const successStyle = getIconStyle(getColor("success"));
  const warningStyle = getIconStyle(getColor("warning"));
  const errorStyle = getIconStyle(getColor("error"));
  const infoStyle = getIconStyle(getColor("info"));

  const iconVariants = {
    success: <CheckCircleIcon style={successStyle} />,
    warning: <ErrorIcon style={warningStyle} />,
    error: <CancelIcon style={errorStyle} />,
    info: <InfoIcon style={infoStyle} />,
  };

  interface CustomSnackbarProps extends CustomContentProps {
    theme?: Theme;
  }
  const CustomError = forwardRef<HTMLDivElement, CustomSnackbarProps>((props, ref) => {
    const { message, theme } = props;
    const styleProps = { style: theme?.palette?.type ?? "light" };
    const classes = useStyles(styleProps);

    return (
      <SnackbarContent ref={ref}>
        <Alert severity="error" message={message} className={`${classes.lessPadding} ${classes.shadow}`} />
      </SnackbarContent>
    );
  });
  const CustomSuccess = forwardRef<HTMLDivElement, CustomSnackbarProps>((props, ref) => {
    const { message, theme } = props;
    const styleProps = { style: theme?.palette?.type ?? "light" };
    const classes = useStyles(styleProps);
    return (
      <SnackbarContent ref={ref}>
        <Alert severity="success" message={message} className={` ${classes.lessPadding} ${classes.shadow}`}></Alert>
      </SnackbarContent>
    );
  });
  const CustomWarning = forwardRef<HTMLDivElement, CustomSnackbarProps>((props, ref) => {
    const { message, theme } = props;
    const styleProps = { style: theme?.palette?.type ?? "light" };
    const classes = useStyles(styleProps);
    return (
      <SnackbarContent ref={ref}>
        <Alert severity="warning" message={message} className={`${classes.lessPadding} ${classes.shadow}`} />
      </SnackbarContent>
    );
  });
  const CustomInfo = forwardRef<HTMLDivElement, CustomSnackbarProps>((props, ref) => {
    const { message, theme } = props;
    const styleProps = { style: theme?.palette?.type ?? "light" };
    const classes = useStyles(styleProps);
    return (
      <SnackbarContent ref={ref}>
        <Alert severity="info" message={message} className={`${classes.lessPadding} ${classes.shadow} `} />
      </SnackbarContent>
    );
  });

  return (
    <BaseProvider
      maxSnack={3}
      Components={{ error: CustomError, success: CustomSuccess, warning: CustomWarning, info: CustomInfo }}
      iconVariant={iconVariants}
    >
      {children}
    </BaseProvider>
  );
};

export default SnackbarProvider;
