import { Dispatch, SetStateAction } from "react";

import { InformativeModal, Sanitized } from "@coherehealth/common";
import { RedirectRuleAction, RecommendChangeRuleAction } from "@coherehealth/core-platform-api";
import ServiceRequestNotRequired from "components/images/ServiceRequestNotRequired";

interface ReviewRedirectModalProps {
  redirectRule: RedirectRuleAction | RecommendChangeRuleAction;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function ReviewRedirectModal({ redirectRule, open, setOpen }: ReviewRedirectModalProps) {
  return (
    <InformativeModal
      headerText={redirectRule.title || ""}
      subHeaderText={<Sanitized __html={redirectRule.message} />}
      icon={<ServiceRequestNotRequired />}
      open={open}
      onClose={() => setOpen(false)}
      primaryButtonText="Continue to review"
      primaryButtonAction={() => setOpen(false)}
      data-testid="review-redirect-modal"
    />
  );
}
