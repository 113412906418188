import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { SingleSelectDropdown, DateTextField, Tooltip } from "../../components";
import { ButtonBase, Box, IconButton } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Segment, DocumentType } from "@coherehealth/core-platform-api";
import AddIcon from "@material-ui/icons/Add";
import { formatDateStr } from "../../util/DateUtils";
import { colorsLight, colorsDark } from "../../themes";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { DocTypeItem } from "./PdfViewer";

interface Props {
  pageNumber?: number;
  segment?: Segment;
  isOpenHeaderComponent?: boolean;
  isActiveHeaderComponent?: boolean;
  onSegmentChange?: (latestSegment: Segment, isDeletion: boolean) => void;
  setOpenHeaderComponent?: Dispatch<SetStateAction<boolean>>;
  savingUpdates?: boolean;
  headerPositionRight?: boolean;
  setHeaderPositionRight?: Dispatch<SetStateAction<boolean>>;
  docTypeOptions?: DocTypeItem[];
}

const formatDocDate = (docDate: string | undefined) => {
  const splitDate = docDate?.split("/");
  const updatedDate = splitDate ? [splitDate?.[2], splitDate?.[0], splitDate?.[1]].join("-") : docDate;
  return updatedDate;
};

export default function HeaderComponent({
  pageNumber,
  segment,
  isOpenHeaderComponent,
  onSegmentChange,
  setOpenHeaderComponent,
  isActiveHeaderComponent,
  savingUpdates,
  headerPositionRight,
  setHeaderPositionRight,
  docTypeOptions,
}: Props) {
  const classes = useStyles({ isActiveHeaderComponent });
  const [docTypeValue, setDocTypeValue] = useState<DocumentType | "">(
    segment?.docType ? docTypeOptions?.find(({ id }) => id === segment.docType)?.id || "" : ""
  );
  const [docDateValue, setDocDateValue] = useState(segment?.docDate ? formatDateStr(segment.docDate) : "");
  const [dateActive, setDateActive] = useState<boolean>(false);

  const theme = useTheme();

  useEffect(() => {
    setDocDateValue(segment?.docDate ? formatDateStr(segment.docDate) : "");
    setDocTypeValue(segment?.docType ? docTypeOptions?.find(({ id }) => id === segment.docType)?.id || "" : "");
  }, [segment, docTypeOptions]);

  const isValidDocumentType = (documentType: string): documentType is DocumentType => {
    return (docTypeOptions?.map((option) => option.id) ?? []).includes(documentType as DocumentType);
  };

  const handleCreateSegment = () => {
    onSegmentChange?.({ ...segment }, false);
    setOpenHeaderComponent?.(true);
  };

  const handleDeleteSegment = () => {
    onSegmentChange?.({ ...segment }, true);
    setOpenHeaderComponent?.(false);
  };

  if (!isOpenHeaderComponent) {
    return (
      <ButtonBase
        className={classes.docInfoRow1}
        onClick={handleCreateSegment}
        disabled={savingUpdates}
        title="Add document type"
        aria-label="Add document type"
      >
        <div className={classes.iconButton}>
          {savingUpdates ? <CircularProgress size={10} style={{ color: colorsLight.font.secondary }} /> : <AddIcon />}
        </div>
      </ButtonBase>
    );
  }
  return (
    <Box className={classes.docInfoRow}>
      <Tooltip title="Reposition document label">
        <div
          className={classes.selectedIndicator}
          onClick={() => {
            if (setHeaderPositionRight) {
              setHeaderPositionRight(!headerPositionRight);
            }
          }}
        />
      </Tooltip>
      <Box className={classes.headerContentContainer}>
        <div className={classes.docTypeColumn}>
          <SingleSelectDropdown
            InputLabelProps={{ shrink: false }}
            className={classes.label}
            label={!docTypeValue && "Document type"}
            value={docTypeValue}
            options={docTypeOptions}
            renderOption={(pos: DocTypeItem) => pos.label}
            onChange={(newValue) => {
              if (isValidDocumentType(newValue)) {
                setDocTypeValue(newValue);
                if (onSegmentChange) {
                  onSegmentChange(
                    {
                      ...segment,
                      docType: newValue,
                    },
                    false
                  );
                }
              }
            }}
            style={{ height: theme.spacing(4), width: 150 }}
            menuWidth={160}
            menuItemHeight="24px"
            fontSize="13px"
            menuItemColor="#5E6572"
            noIcon
            SelectProps={{ IconComponent: ExpandMore }}
            title="Select document type"
            aria-label="Select document type"
          />
        </div>
        <div className={classes.docReportDateColumn}>
          <DateTextField
            onFocus={() => setDateActive(true)}
            onBlur={() => setDateActive(false)}
            className={classes.label}
            label={!segment?.docDate && !dateActive && "MM/DD/YYYY"}
            value={docDateValue}
            style={{ height: theme.spacing(4), width: "108px" }}
            onChange={(e) => {
              if (e.target.value !== docDateValue) {
                setDocDateValue(e.target.value);
                if (e.target.value.length === 10) {
                  if (onSegmentChange) {
                    onSegmentChange(
                      {
                        ...segment,
                        docDate: formatDocDate(e.target.value),
                      },
                      false
                    );
                  }
                }
              }
            }}
            title="Set date value"
            aria-label="Set date value"
          />
        </div>
        <div className={classes.closeIconButton}>
          <IconButton
            onClick={handleDeleteSegment}
            disabled={savingUpdates || pageNumber === 1}
            title="Close"
            aria-label="Close"
          >
            {savingUpdates ? (
              <CircularProgress size={10} style={{ color: colorsLight.font.secondary }} />
            ) : (
              <CloseIcon />
            )}
          </IconButton>
        </div>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  docInfoRow: {
    display: "flex",
    color: colorsDark.gray.dark,
    width: "306px",
    height: theme.spacing(6),
    fontFamily: "Gilroy-Medium",
    border: `1px solid ${theme.palette.action.selected}`,
    borderRadius: "2px",
    alignItems: "center",
    background: "#FFFFFF",
    boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.05)",
  },
  headerContentContainer: {
    display: "flex",
    alignItems: "center",
  },
  docInfoRow1: {
    display: "flex",
    background: colorsLight.background.dark,
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontFamily: "Gilroy-Medium",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "2px",
    opacity: "30%",
    "&:hover": {
      opacity: "100%",
      background: "#FFFFFF",
      boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.05)",
    },
  },
  docTypeColumn: {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: "5px",
    "& .MuiInputBase-root": {
      fontSize: "13px",
      border: "none",
      "& .MuiSelect-root": {
        color: colorsLight.font.secondary,
      },
    },
    "& .MuiSelect-icon": {
      visibility: "hidden",
    },
    "& .MuiSelect-iconOpen": {
      visibility: "visible",
      color: colorsLight.font.secondary,
      right: "0px",
    },
    "&:hover": {
      "& .MuiSelect-icon": {
        visibility: "visible",
        color: colorsLight.font.secondary,
        right: "0px",
      },
    },
  },
  iconButton: {
    display: "flex",
    color: colorsLight.font.secondary,
    justifyContent: "flex-start",
    "& > .MuiIconButton-root": {
      padding: "0px 8px 0px 8px",
    },
    "& .MuiSvgIcon-root": {
      width: theme.spacing(2),
      height: theme.spacing(2),
      "&:hover": {
        color: colorsLight.font.secondary,
      },
    },
  },
  closeIconButton: {
    display: "flex",
    color: colorsLight.font.secondary,
    justifyContent: "flex-start",
    "& > .MuiIconButton-root": {
      padding: "0px",
    },
    "& .MuiSvgIcon-root": {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  },
  docReportDateColumn: {
    display: "flex",
    paddingRight: theme.spacing(1),
    justifyContent: "flex-start",
    "& input": {
      color: colorsLight.font.secondary,
      fontSize: "13px",
    },
    "& .MuiInputBase-root": {
      border: "none",
    },
  },
  selectedIndicator: {
    width: "6px",
    backgroundColor: theme.palette.primary.light,
    height: "30px",
    borderRadius: "1px",
    position: "absolute",
    left: "-3px",
    cursor: "pointer",
    display: ({ isActiveHeaderComponent }: StyleProps) => (isActiveHeaderComponent ? "block" : "none"),
  },
  label: {
    "& .MuiFormLabel-root": {
      top: -14,
      fontSize: 13,
    },
    "& .MuiSelect-filled": {
      paddingRight: "1px",
    },
    padding: "1px",
    "&:hover": {
      padding: 0,
      borderRadius: "4px",
      border: `1px solid #00A9D1`,
    },
  },
}));

type StyleProps = {
  isActiveHeaderComponent?: boolean;
};
