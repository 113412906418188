import { useState, useEffect, useCallback } from "react";

function useIsOverflow(textElementRef: React.MutableRefObject<HTMLInputElement | null>) {
  const [hoverStatus, setHover] = useState<boolean>(false);
  const element = textElementRef.current;

  const compareSize = useCallback(() => {
    const compare = element
      ? element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight
      : false;
    setHover(compare);
  }, [element]);

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
  }, [compareSize]);

  // remove resize listener again on "componentWillUnmount"
  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize);
    },
    [compareSize]
  );

  useEffect(() => {
    if (element) {
      const observer = new MutationObserver(() => {
        compareSize();
      });

      observer.observe(element, { characterData: true, childList: true, subtree: true });

      return () => {
        observer.disconnect();
      };
    }
  }, [element, compareSize]);

  return {
    hoverStatus,
  };
}

export { useIsOverflow };
