import { StyledTextField } from "./UnitTextField";

export interface PreviousUnitProps {
  previousUnits?: string;
  previousUnitsLabel?: string;
}

export function PreviousUnitTextField({ previousUnits, previousUnitsLabel }: PreviousUnitProps) {
  return (
    <StyledTextField
      InputLabelProps={{ className: "MuiInputLabel-filled MuiInputLabel-shrink" }}
      type="text"
      fullWidth
      label={previousUnitsLabel}
      data-tracking-id="previous-units-field"
      value={previousUnits}
      disabled={true}
    />
  );
}
