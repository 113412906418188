import React, { createContext, Dispatch, SetStateAction, useState, useCallback, useEffect, useRef } from "react";
import {
  HighlightArea,
  RenderHighlightTargetProps,
  highlightPlugin,
  RenderHighlightsProps,
  HighlightPlugin,
} from "@react-pdf-viewer/highlight";
import {
  Attachment,
  GuidelinePolicyMap,
  AttachmentGuidelineTextHighlight,
  Match,
  IndicationMatchStatus,
} from "@coherehealth/core-platform-api";
import { createHash } from "crypto";
import { ButtonBase } from "@material-ui/core";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import { Body3 } from "../components/Typography";
import { colorsLight } from "../themes";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";
import { useFeature } from "../components";

type GuidelineId = string;
type AttachmentId = string;
type ServiceRequestId = string;
type ReviewId = string;
type SpanHash = string;
export type IndicationId = string;

export interface Highlight {
  highlightAreas: HighlightArea[];
  text: string;
  existing: boolean;
  indicationMatches?: IndicationMatch[];
  editText?: string;
  editStartPage?: number;
  editEndPage?: number;
  isCited?: boolean;
  isExpanded?: boolean;
}

interface IndicationMatch {
  indicationUid: IndicationId;
  status: IndicationMatchStatus;
  matchCreator?: string;
}

export interface DocumentedSpan {
  hash: SpanHash;
  text: string;
  attachmentId: AttachmentId;
  startPage: string;
  endPage: string;
  isCited?: boolean;
}

interface ClickedHighlight {
  hash: SpanHash;
  mouseX: number;
  mouseY: number;
  isCited: boolean;
}

export interface IndicationHighlight {
  hash: SpanHash;
  indicationId: IndicationId;
}

interface HighlightProviderProps {
  children: React.ReactNode;
  currentUserId?: string;
}

export type HighlightState = Record<GuidelineId, Record<AttachmentId, Record<SpanHash, Highlight>>>;
type IndicationsDocumentationState = Record<GuidelineId, Record<IndicationId, DocumentedSpan[]>>;
export type CurrentReviewTabTypes = "GUIDELINES" | "REVIEW" | "OOSEMAIL" | "OON_REVIEW";

export interface HighlightStateContextProps {
  shouldUpdatePdfSpans: React.MutableRefObject<boolean> | undefined;
  zoomScale: React.MutableRefObject<number> | undefined;
  selectedGuideline: GuidelineId | undefined;
  setSelectedGuideline: Dispatch<SetStateAction<GuidelineId | undefined>> | undefined;
  setSelectedGuidelineIds: Dispatch<SetStateAction<GuidelineId[] | undefined>> | undefined;
  readOnlySelectedGuideline: GuidelineId | undefined;
  setReadOnlySelectedGuideline: Dispatch<SetStateAction<GuidelineId | undefined>> | undefined;
  attachmentId: AttachmentId | undefined;
  setAttachmentId: Dispatch<SetStateAction<AttachmentId | undefined>> | undefined;
  linking: SpanHash | undefined;
  setLinking: Dispatch<SetStateAction<SpanHash | undefined>> | undefined;
  initializeHighlights:
    | ((
        initialHighlights: AttachmentGuidelineTextHighlight[] | null,
        guidelines: GuidelinePolicyMap[],
        attachments: Attachment[],
        serviceRequestId: string,
        reviewId: string,
        mlHighlights: boolean,
        hideUserFeedbackHighlights: boolean
      ) => void)
    | undefined;
  addHighlight: ((highlight: Highlight) => SpanHash) | undefined;
  getHighlights: (() => [SpanHash, Highlight][]) | undefined;
  getCurrentLinked: (() => number) | undefined;
  addLink: ((indicationId: IndicationId | undefined) => boolean) | undefined;
  removeLink:
    | ((indicationId: IndicationId | undefined, hash: SpanHash, targetAttachmentId?: AttachmentId) => void)
    | undefined;
  getIndicationMatches: ((indicationId: IndicationId | undefined) => DocumentedSpan[]) | undefined;
  closeLinking: (() => void) | undefined;
  clicked: ClickedHighlight | undefined;
  setClicked: Dispatch<SetStateAction<ClickedHighlight | undefined>> | undefined;
  getClickedLinked: (() => string) | undefined;
  deleteClicked: (() => void) | undefined;
  highlightPluginInstance: HighlightPlugin | undefined;
  jumpToHighlight: ((hash: SpanHash, attachmentId: AttachmentId) => void) | undefined;
  getSubmitPayload: ((warn?: (...args: any[]) => void) => AttachmentGuidelineTextHighlight[] | null) | undefined;
  rightColumnTab: CurrentReviewTabTypes | undefined;
  setRightColumnTab: Dispatch<SetStateAction<CurrentReviewTabTypes | undefined>> | undefined;
  editing: IndicationHighlight | undefined;
  setEditing: Dispatch<SetStateAction<IndicationHighlight | undefined>> | undefined;
  saveEdit:
    | ((attachmentId: AttachmentId, editText: string, editStartPage: string, editEndPage: string | undefined) => void)
    | undefined;
  isInitialized: (() => boolean) | undefined;
  promoteHighlight:
    | ((indicationId: IndicationId | undefined, hash: SpanHash, targetAttachmentId?: AttachmentId) => void)
    | undefined;
  toggleExpandIndication:
    | ((indicationId: IndicationId | undefined, expanded: boolean, targetAttachmentId?: AttachmentId) => void)
    | undefined;
  isExpanded: ((indicationId: IndicationId | undefined, targetAttachmentId?: AttachmentId) => boolean) | undefined;
}

const defaultHighlightState = {
  shouldUpdatePdfSpans: undefined,
  zoomScale: undefined,
  selectedGuideline: undefined,
  setSelectedGuideline: undefined,
  setSelectedGuidelineIds: undefined,
  readOnlySelectedGuideline: undefined,
  setReadOnlySelectedGuideline: undefined,
  attachmentId: undefined,
  setAttachmentId: undefined,
  linking: undefined,
  setLinking: undefined,
  initializeHighlights: undefined,
  addHighlight: undefined,
  getHighlights: undefined,
  getCurrentLinked: undefined,
  addLink: undefined,
  removeLink: undefined,
  getIndicationMatches: undefined,
  closeLinking: undefined,
  clicked: undefined,
  setClicked: undefined,
  getClickedLinked: undefined,
  deleteClicked: undefined,
  highlightPluginInstance: undefined,
  jumpToHighlight: undefined,
  getSubmitPayload: undefined,
  rightColumnTab: undefined,
  setRightColumnTab: undefined,
  editing: undefined,
  setEditing: undefined,
  saveEdit: undefined,
  isInitialized: undefined,
  promoteHighlight: undefined,
  toggleExpandIndication: undefined,
  isExpanded: undefined,
};

export const HighlightStateContext = createContext<HighlightStateContextProps>(defaultHighlightState);

export const HighlightProvider = ({ children, currentUserId }: HighlightProviderProps) => {
  const [selectedGuideline, setSelectedGuideline] = useState<GuidelineId | undefined>();
  const [selectedGuidelineIds, setSelectedGuidelineIds] = useState<GuidelineId[] | undefined>();
  const [readOnlySelectedGuideline, setReadOnlySelectedGuideline] = useState<GuidelineId | undefined>();
  const [attachmentId, setAttachmentId] = useState<AttachmentId | undefined>();
  const [serviceRequestId, setServiceRequestId] = useState<ServiceRequestId | undefined>();
  const [reviewId, setReviewId] = useState<ReviewId | undefined>();
  const [highlights, setHighlights] = useState<HighlightState>({});
  const [currHighlight, setCurrHighlight] = useState<Highlight | undefined>();
  const [indicationDocumentations, setIndicationDocumentations] = useState<IndicationsDocumentationState>({});
  const [linking, setLinking] = useState<SpanHash | undefined>();
  const [clicked, setClicked] = useState<ClickedHighlight | undefined>();
  const [rightColumnTab, setRightColumnTab] = useState<CurrentReviewTabTypes | undefined>();
  const [editing, setEditing] = useState<IndicationHighlight | undefined>();
  const isHighlightImprovementsEnabled = useFeature("textHighlightImprovements");
  const initializeHighlights = useCallback(
    (
      initialHighlights: AttachmentGuidelineTextHighlight[] | null,
      guidelines: GuidelinePolicyMap[],
      attachments: Attachment[],
      serviceRequestId: string,
      reviewId: string,
      mlHighlights: boolean,
      hideUserFeedbackHighlights: boolean
    ) => {
      const { initializedHighlights, initializedIndicationDocs } = transformToStateShape(
        initialHighlights,
        guidelines,
        attachments,
        mlHighlights,
        hideUserFeedbackHighlights
      );

      setHighlights(initializedHighlights);
      setIndicationDocumentations(initializedIndicationDocs);
      setReviewId(reviewId);
      setServiceRequestId(serviceRequestId);
    },
    []
  );
  const addHighlight = useCallback(
    (highlight: Highlight): SpanHash => {
      const hash = createHighlightHash(highlight);
      if (selectedGuideline && attachmentId && !(hash in (highlights?.[selectedGuideline]?.[attachmentId] || {}))) {
        setHighlights({
          ...highlights,
          [selectedGuideline]: {
            ...highlights[selectedGuideline],
            [attachmentId]: { ...highlights[selectedGuideline][attachmentId], [hash]: highlight },
          },
        });
      }
      return hash;
    },
    [attachmentId, highlights, selectedGuideline]
  );

  // associate multiple selected guidelines with the same highlight object
  useEffect(() => {
    if (linking && selectedGuideline && !currHighlight && attachmentId) {
      setCurrHighlight(highlights?.[selectedGuideline]?.[attachmentId]?.[linking]);
    } else if (!linking) {
      setCurrHighlight(undefined);
    }

    if (currHighlight) {
      addHighlight(currHighlight);
    }
  }, [currHighlight, linking, selectedGuideline, attachmentId, highlights, addHighlight]);

  const getHighlights = (): [SpanHash, Highlight][] => {
    if (!isInitialized()) {
      return [];
    }
    if (selectedGuideline && attachmentId && highlights) {
      return Object.entries(highlights?.[selectedGuideline]?.[attachmentId] || {}).filter(([hash, highlight]) => {
        return hash === linking || linkedCount(highlight) > 0;
      });
    } else if (selectedGuidelineIds && !selectedGuideline && attachmentId && highlights) {
      const uniqueHighlights: [SpanHash, Highlight][] = [];
      const uniqueHashes = new Set<SpanHash>();
      selectedGuidelineIds.forEach((guideline) => {
        Object.entries(highlights?.[guideline]?.[attachmentId]).forEach(([hash, highlight]) => {
          if (linkedCount(highlight) > 0 && !uniqueHashes.has(hash)) {
            uniqueHashes.add(hash);
            uniqueHighlights.push([hash, highlight]);
          }
        });
      });

      return uniqueHighlights;
    }
    return [];
  };

  // get number of indications currently linked to a highlight in linking mode
  const getCurrentLinked = (): number => {
    if (linking && attachmentId && highlights && selectedGuidelineIds) {
      let numIndications = 0;
      selectedGuidelineIds.forEach((guideline) => {
        numIndications += linkedCount(highlights?.[guideline]?.[attachmentId]?.[linking]);
      });
      return numIndications;
    }
    return 0;
  };

  // link indication to current highlight in linking mode.
  // returns boolean indicating if linking was successful
  const addLink = (indicationId: IndicationId | undefined): boolean => {
    if (indicationId && linking && selectedGuideline && attachmentId && highlights) {
      const highlight = highlights?.[selectedGuideline]?.[attachmentId]?.[linking];
      const indicationMatch = highlight?.indicationMatches?.find(
        (indicationMatch) => indicationMatch.indicationUid === indicationId
      );
      const { existing, text } = highlight || {};
      if (indicationMatch && indicationMatch.status !== "UNLINKED" && indicationMatch.status !== "REMOVED") {
        return false;
      } else if (indicationMatch) {
        setHighlights({
          ...highlights,
          [selectedGuideline]: {
            ...highlights[selectedGuideline],
            [attachmentId]: {
              ...highlights[selectedGuideline][attachmentId],
              [linking]: {
                ...highlights[selectedGuideline][attachmentId][linking],
                isExpanded: true,
                indicationMatches: highlights[selectedGuideline][attachmentId][linking].indicationMatches?.map(
                  (indicationMatch) =>
                    indicationMatch.indicationUid === indicationId
                      ? { ...indicationMatch, status: "PRESERVED" }
                      : indicationMatch
                ),
              },
            },
          },
        });
      } else {
        setHighlights({
          ...highlights,
          [selectedGuideline]: {
            ...highlights[selectedGuideline],
            [attachmentId]: {
              ...highlights[selectedGuideline][attachmentId],
              [linking]: {
                ...highlights[selectedGuideline][attachmentId][linking],
                isExpanded: true,
                indicationMatches: [
                  ...(highlights[selectedGuideline][attachmentId][linking].indicationMatches || []),
                  { indicationUid: indicationId, status: existing ? "LINKED" : "ADDED", matchCreator: currentUserId },
                ],
              },
            },
          },
        });
      }
      setIndicationDocumentations({
        ...indicationDocumentations,
        [selectedGuideline]: {
          ...indicationDocumentations[selectedGuideline],
          [indicationId]: [
            ...(indicationDocumentations[selectedGuideline][indicationId] || []),
            {
              hash: linking,
              text: text || "",
              attachmentId: attachmentId,
              startPage: (
                highlights[selectedGuideline][attachmentId][linking].highlightAreas[0].pageIndex + 1
              ).toString(),
              endPage: (
                (highlights[selectedGuideline][attachmentId][linking].highlightAreas.at(-1)?.pageIndex || 0) + 1
              ).toString(),
              isCited: true,
            },
          ],
        },
      });
      return true;
    }
    return false;
  };

  const removeLink = (
    indicationId: IndicationId | undefined,
    hash: SpanHash,
    specifiedAttachmentId?: AttachmentId
  ): void => {
    const targetAttachmentId: AttachmentId | undefined = specifiedAttachmentId || attachmentId;
    if (indicationId && selectedGuideline && targetAttachmentId) {
      setIndicationDocumentations({
        ...indicationDocumentations,
        [selectedGuideline]: {
          ...indicationDocumentations[selectedGuideline],
          [indicationId]: indicationDocumentations[selectedGuideline][indicationId].filter(
            (documentedSpan) => documentedSpan.hash !== hash || documentedSpan.attachmentId !== targetAttachmentId
          ),
        },
      });
      setHighlights({
        ...highlights,
        [selectedGuideline]: {
          ...highlights[selectedGuideline],
          [targetAttachmentId]: {
            ...highlights[selectedGuideline][targetAttachmentId],
            [hash]: {
              ...highlights[selectedGuideline][targetAttachmentId][hash],
              indicationMatches: highlights[selectedGuideline][targetAttachmentId][hash].indicationMatches
                ?.map((indicationMatch) => {
                  const highlight = highlights[selectedGuideline][targetAttachmentId][hash];
                  const status: IndicationMatchStatus = highlight.existing ? "UNLINKED" : "MISTAKE";
                  return indicationMatch.indicationUid === indicationId
                    ? {
                        ...indicationMatch,
                        status,
                      }
                    : indicationMatch;
                })
                .filter((indicationMatch) => indicationMatch.status !== "MISTAKE"),
            },
          },
        },
      });
      !linking &&
        linkedCount(highlights[selectedGuideline][targetAttachmentId][hash]) === 0 &&
        cleanupHighlights(hash, targetAttachmentId);
    }
  };

  const getIndicationMatches = (indicationId: IndicationId | undefined): DocumentedSpan[] => {
    if (indicationDocumentations && indicationId && selectedGuideline) {
      return indicationDocumentations?.[selectedGuideline]?.[indicationId] || [];
    }
    return [];
  };

  const cleanupHighlights = (hash: SpanHash, specifiedAttachmentId?: AttachmentId): void => {
    const targetAttachmentId: AttachmentId | undefined = specifiedAttachmentId || attachmentId;
    if (selectedGuideline && targetAttachmentId) {
      const indicationMatches = highlights?.[selectedGuideline]?.[targetAttachmentId]?.[hash]?.indicationMatches;
      if (indicationMatches?.length === 0) {
        let attachmentState = { ...highlights[selectedGuideline][targetAttachmentId] };
        delete attachmentState[hash];
        setHighlights({
          ...highlights,
          [selectedGuideline]: {
            ...highlights[selectedGuideline],
            [targetAttachmentId]: attachmentState,
          },
        });
      } else if ((indicationMatches?.length || 0) > 0) {
        setHighlights({
          ...highlights,
          [selectedGuideline]: {
            ...highlights[selectedGuideline],
            [targetAttachmentId]: {
              ...highlights[selectedGuideline][targetAttachmentId],
              [hash]: {
                ...highlights[selectedGuideline][targetAttachmentId][hash],
                indicationMatches: highlights[selectedGuideline][targetAttachmentId][hash].indicationMatches?.map(
                  (indicationMatch) => {
                    return {
                      ...indicationMatch,
                      status: "REMOVED",
                    };
                  }
                ),
              },
            },
          },
        });
      }
    }
  };

  const closeLinking = (): void => {
    linking && getCurrentLinked() === 0 && cleanupHighlights(linking);
    setLinking(undefined);
  };

  const getClickedLinked = (): string => {
    let numIndications = 0;

    if (clicked && selectedGuideline && attachmentId) {
      numIndications = linkedCount(highlights?.[selectedGuideline]?.[attachmentId]?.[clicked.hash]);

      return `Linked to ${numIndications || 0} indication${numIndications !== 1 ? "s" : ""} in expanded guideline`;
    } else if (!selectedGuideline && clicked && selectedGuidelineIds && attachmentId) {
      selectedGuidelineIds.forEach((guideline) => {
        numIndications += linkedCount(highlights?.[guideline]?.[attachmentId]?.[clicked.hash]);
      });
      return `Linked to ${numIndications || 0} indication${numIndications !== 1 ? "s" : ""} ${
        selectedGuidelineIds.length !== 1 ? "across all guidelines" : ""
      }`;
    }

    return "";
  };

  const deleteClicked = (): void => {
    if (selectedGuideline) {
      deleteHighlight([selectedGuideline]);
    } else if (!selectedGuideline && selectedGuidelineIds) {
      deleteHighlight(selectedGuidelineIds);
    }
  };

  const deleteHighlight = (guidelines: string[]): void => {
    let updatedIndicationDocumentations: IndicationsDocumentationState = JSON.parse(
      JSON.stringify(indicationDocumentations)
    );
    let updatedHighlights: HighlightState = JSON.parse(JSON.stringify(highlights));

    guidelines.forEach((guideline) => {
      if (clicked && guideline && attachmentId) {
        updatedIndicationDocumentations = {
          ...updatedIndicationDocumentations,
          [guideline]: Object.entries(updatedIndicationDocumentations[guideline]).reduce(
            (acc, [indicationId, indicationDocumentations]) => {
              const filteredMatches = indicationDocumentations.filter(
                (documentedSpan) => documentedSpan.hash !== clicked.hash
              );
              return filteredMatches.length > 0
                ? {
                    ...acc,
                    [indicationId]: filteredMatches,
                  }
                : { ...acc };
            },
            {}
          ),
        };

        if (!updatedHighlights?.[guideline]?.[attachmentId]?.[clicked.hash]?.existing) {
          let attachmentState = { ...updatedHighlights[guideline][attachmentId] };
          delete attachmentState[clicked.hash];
          updatedHighlights = {
            ...updatedHighlights,
            [guideline]: {
              ...updatedHighlights[guideline],
              [attachmentId]: attachmentState,
            },
          };
        } else {
          updatedHighlights = {
            ...updatedHighlights,
            [guideline]: {
              ...updatedHighlights[guideline],
              [attachmentId]: {
                ...updatedHighlights[guideline][attachmentId],
                [clicked.hash]: {
                  ...updatedHighlights[guideline][attachmentId][clicked.hash],
                  indicationMatches: updatedHighlights[guideline][attachmentId][clicked.hash].indicationMatches?.map(
                    (indicationMatch: IndicationMatch) => {
                      return {
                        ...indicationMatch,
                        status: "REMOVED",
                      };
                    }
                  ),
                },
              },
            },
          };
        }
      }
    });
    setIndicationDocumentations(updatedIndicationDocumentations);
    setHighlights(updatedHighlights);
    setClicked(undefined);
  };

  type ClickDragInfo = { node: Node; offset: number } | undefined;
  const startSelect = useRef<ClickDragInfo>();
  const endSelect = useRef<ClickDragInfo>();
  const shouldUpdatePdfSpans = useRef<boolean>(true);
  const zoomScale = useRef<number>(1);

  useEffect(() => {
    const selectionHandler = (event: Event) => {
      const selection = window.getSelection();
      if (selection) {
        if (selection.anchorNode && selection.focusNode) {
          startSelect.current = { node: selection.anchorNode, offset: selection.anchorOffset };
          endSelect.current = { node: selection.focusNode, offset: selection.focusOffset };
        }
        if (selection.toString().length > 0) {
          if (shouldUpdatePdfSpans?.current) {
            document.querySelectorAll<HTMLElement>("span[data-highlight-text-page]").forEach((el) => {
              el.style.fontSize = `${
                parseFloat(window.getComputedStyle(el, null).getPropertyValue("font-size")) + 0.7 * zoomScale.current
              }px`;
            });
            shouldUpdatePdfSpans.current = false;
          }
          document.querySelectorAll<HTMLElement>(".highlight").forEach((el) => {
            el.style.pointerEvents = "none";
          });
        } else {
          document.querySelectorAll<HTMLElement>(".highlight").forEach((el) => {
            el.style.pointerEvents = "auto";
          });
        }
      } else {
        document.querySelectorAll<HTMLElement>(".highlight").forEach((el) => {
          el.style.pointerEvents = "auto";
        });
      }
    };

    const rightClickHandler = (event: MouseEvent) => {
      const selection = window.getSelection();
      if (selection) {
        if (startSelect.current?.node && endSelect.current?.node) {
          const range = new Range();
          range.setStart(startSelect.current.node, startSelect.current.offset);
          range.setEnd(endSelect.current.node, endSelect.current.offset);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    };

    document.addEventListener("selectionchange", selectionHandler);
    document.addEventListener("contextmenu", rightClickHandler);

    return () => {
      document.removeEventListener("selectionchange", selectionHandler);
      document.removeEventListener("contextmenu", rightClickHandler);
    };
  }, []);

  const renderHighlightTarget = (props: RenderHighlightTargetProps) => {
    const addNote = () => {
      const highlight: Highlight = {
        highlightAreas: props.highlightAreas,
        text: props.selectedText,
        existing: false,
        isCited: true,
      };
      const hash = addHighlight?.(highlight);
      setLinking?.(hash);
      props.cancel();
    };
    if (!selectedGuideline || linking || rightColumnTab !== "GUIDELINES" || props.selectedText.trim().length === 0) {
      return <></>;
    }
    return (
      <HighlightTooltip
        style={{
          display: "flex",
          position: "absolute",
          left: `${props.selectionRegion.left}%`,
          top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
          transform:
            "translate(" +
            (props.selectionRegion.left > 60 ? "-145px" : "0") +
            ", " +
            (props.selectionRegion.top > 85 ? "-70px" : "8px") +
            ")",
          zIndex: 2,
        }}
      >
        <ButtonBase onClick={addNote}>
          <InsertLinkIcon />
          <Body3 style={{ marginLeft: "8px" }}>Link to indication</Body3>
        </ButtonBase>
      </HighlightTooltip>
    );
  };

  const renderHighlights = (props: RenderHighlightsProps) => (
    <div>
      {getHighlights?.().map(
        ([hash, highlight]) =>
          (highlight.isCited ||
            highlight.isCited === undefined ||
            highlight.isExpanded ||
            !isHighlightImprovementsEnabled) && (
            <React.Fragment key={hash}>
              {highlight.highlightAreas
                .filter((area) => area.pageIndex === props.pageIndex)
                .map((area, idx) => (
                  <div
                    key={idx}
                    className="highlight"
                    style={Object.assign(
                      {},
                      linking === undefined && clicked?.hash !== hash
                        ? {
                            background:
                              (highlight.isCited || highlight.isCited === undefined) && isHighlightImprovementsEnabled
                                ? "#FFDFA1"
                                : "#FFF1D4",
                            mixBlendMode: "multiply",
                            zIndex: rightColumnTab === "GUIDELINES" ? 1 : 0,
                            cursor: rightColumnTab === "GUIDELINES" ? "pointer" : "default",
                            userSelect: rightColumnTab === "GUIDELINES" ? "auto" : "none",
                            border:
                              (highlight.isCited || highlight.isCited === undefined) && isHighlightImprovementsEnabled
                                ? "1px solid #DE7800"
                                : undefined,
                            borderRadius:
                              (highlight.isCited || highlight.isCited === undefined) && isHighlightImprovementsEnabled
                                ? "1px"
                                : undefined,
                          }
                        : clicked?.hash === hash
                        ? {
                            background: "#FFC960",
                            mixBlendMode: "multiply",
                          }
                        : linking === hash
                        ? {
                            background:
                              (highlight.isCited || highlight.isCited === undefined) && isHighlightImprovementsEnabled
                                ? "#FFDFA1"
                                : "#FFF1D4",
                            mixBlendMode: "multiply",
                            border: "2px solid #FFC960",
                            borderRadius: "1px",
                          }
                        : {
                            background: "#E8E8E8",
                            mixBlendMode: "multiply",
                          },
                      props.getCssProperties(area, props.rotation)
                    )}
                    onClick={(event) =>
                      rightColumnTab === "GUIDELINES" &&
                      setClicked?.({
                        hash,
                        mouseX: event.clientX,
                        mouseY: event.clientY,
                        isCited: highlight.isCited || highlight.isCited === undefined,
                      })
                    }
                  />
                ))}
            </React.Fragment>
          )
      )}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget,
    renderHighlights,
  });

  const { jumpToHighlightArea } = highlightPluginInstance;

  const jumpToHighlight = (hash: SpanHash, attachmentId: AttachmentId): void => {
    if (selectedGuideline) {
      jumpToHighlightArea(highlights[selectedGuideline][attachmentId][hash].highlightAreas[0]);
    }
  };

  const transformLogMatches = (attachmentHighlights: Highlight) => {
    return {
      highlightAreas: attachmentHighlights.highlightAreas?.length || 0,
      text: attachmentHighlights.text,
      existing: attachmentHighlights.existing,
      editText: attachmentHighlights.editText,
      indicationMatches: attachmentHighlights.indicationMatches?.length || 0,
      editStartPage: attachmentHighlights.editStartPage,
      editEndPage: attachmentHighlights.editEndPage,
      isCited: attachmentHighlights.isCited,
      isExpanded: attachmentHighlights.isExpanded,
    };
  };

  const getSubmitPayload = (warn?: (...args: any[]) => void): AttachmentGuidelineTextHighlight[] | null => {
    if (serviceRequestId && reviewId) {
      const transformedHighlights = transformHighlightsToPayload(highlights, reviewId, serviceRequestId);
      if (transformedHighlights.length > 0) {
        return transformedHighlights;
      }
      if (
        warn &&
        Object.entries(highlights || {})?.some(([guidelineId, guidelineHighlights]) =>
          Object.entries(guidelineHighlights)?.some(
            ([attachmentId, attachmentHighlights]) => (Object.keys(attachmentHighlights)?.length || 0) > 0
          )
        )
      ) {
        const logObject = Object.entries(highlights || {}).reduce((acc, [guidelineId, guidelineHighlights]) => {
          const guidelineEntries = Object.entries(guidelineHighlights).reduce(
            (guidelineAcc, [attachmentId, attachmentHighlights]) => {
              const matches = Object.entries(attachmentHighlights).reduce((matchAcc, [spanHash, highlight]) => {
                return { ...matchAcc, [spanHash]: transformLogMatches(highlight) };
              }, {});
              return { ...guidelineAcc, [attachmentId]: matches };
            },
            {}
          );
          return { ...acc, [guidelineId]: guidelineEntries };
        }, {});
        const logString = JSON.stringify(logObject, null, 4);
        for (let i = 0, o = 0; i < Math.ceil(logString.length / 1900); ++i, o += 1900) {
          warn(
            (i + 1).toString() +
              ": Review: " +
              reviewId +
              " failed to generate payload with highlights: " +
              logString.substring(o, o + 1900)
          );
        }
      }
    }
    return null;
  };
  const saveEdit = (
    attachmentId: AttachmentId,
    editText: string,
    editStartPage: string,
    editEndPage: string | undefined
  ): void => {
    if (editing && selectedGuideline) {
      setIndicationDocumentations({
        ...indicationDocumentations,
        [selectedGuideline]: {
          ...indicationDocumentations[selectedGuideline],
          [editing.indicationId]: indicationDocumentations[selectedGuideline][editing.indicationId].map(
            (documentedSpan) => {
              if (documentedSpan.hash === editing.hash) {
                return {
                  ...documentedSpan,
                  text: editText,
                  startPage: editStartPage,
                  endPage: editEndPage || editStartPage,
                };
              } else {
                return documentedSpan;
              }
            }
          ),
        },
      });
      setHighlights({
        ...highlights,
        [selectedGuideline]: {
          ...highlights[selectedGuideline],
          [attachmentId]: {
            ...highlights[selectedGuideline][attachmentId],
            [editing.hash]: {
              ...highlights[selectedGuideline][attachmentId][editing.hash],
              editText:
                highlights[selectedGuideline][attachmentId][editing.hash].text === editText ? undefined : editText,
              editStartPage:
                highlights[selectedGuideline][attachmentId][editing.hash].highlightAreas[0].pageIndex + 1 ===
                parseInt(editStartPage)
                  ? undefined
                  : parseInt(editStartPage),
              editEndPage:
                !editEndPage ||
                (highlights[selectedGuideline][attachmentId][editing.hash].highlightAreas.at(-1)?.pageIndex || 0) +
                  1 ===
                  parseInt(editEndPage)
                  ? undefined
                  : parseInt(editEndPage),
            },
          },
        },
      });
      setEditing(undefined);
    }
  };

  const isInitialized = () => {
    return !!reviewId;
  };

  const promoteHighlight = (
    indicationId: IndicationId | undefined,
    hash: SpanHash,
    specifiedAttachmentId?: AttachmentId
  ): void => {
    const targetAttachmentId: AttachmentId | undefined = specifiedAttachmentId || attachmentId;
    if (indicationId && selectedGuideline && targetAttachmentId) {
      setIndicationDocumentations({
        ...indicationDocumentations,
        [selectedGuideline]: {
          ...indicationDocumentations[selectedGuideline],
          [indicationId]: indicationDocumentations[selectedGuideline][indicationId].map((documentedSpan) =>
            documentedSpan.hash === hash && documentedSpan.attachmentId === targetAttachmentId
              ? { ...documentedSpan, isCited: true }
              : documentedSpan
          ),
        },
      });
      setHighlights({
        ...highlights,
        [selectedGuideline]: {
          ...highlights[selectedGuideline],
          [targetAttachmentId]: {
            ...highlights[selectedGuideline][targetAttachmentId],
            [hash]: {
              ...highlights[selectedGuideline][targetAttachmentId][hash],
              isCited: true,
            },
          },
        },
      });
    }
  };

  const toggleExpandIndication = (
    indicationId: IndicationId | undefined,
    expanded: boolean,
    specifiedAttachmentId?: AttachmentId
  ): void => {
    const targetAttachmentId: AttachmentId | undefined = specifiedAttachmentId || attachmentId;
    if (indicationId && selectedGuideline && targetAttachmentId) {
      setHighlights({
        ...highlights,
        [selectedGuideline]: {
          ...highlights[selectedGuideline],
          [targetAttachmentId]: indicationDocumentations[selectedGuideline]?.[indicationId]?.reduce(
            (acc, documentedSpan) => {
              const hash = documentedSpan.hash;
              return {
                ...acc,
                [hash]: {
                  ...highlights[selectedGuideline][targetAttachmentId][hash],
                  isExpanded: expanded,
                },
              };
            },
            { ...highlights[selectedGuideline][targetAttachmentId] }
          ),
        },
      });
    }
  };

  const isExpanded = (indicationId: IndicationId | undefined, specifiedAttachmentId?: AttachmentId): boolean => {
    const targetAttachmentId: AttachmentId | undefined = specifiedAttachmentId || attachmentId;
    if (indicationId && selectedGuideline && targetAttachmentId) {
      const documentations = indicationDocumentations[selectedGuideline]?.[indicationId];
      if (documentations && documentations.length > 0) {
        for (const documentation of documentations) {
          if (
            highlights[selectedGuideline][targetAttachmentId][documentation.hash] &&
            !highlights[selectedGuideline][targetAttachmentId][documentation.hash].isExpanded &&
            !highlights[selectedGuideline][targetAttachmentId][documentation.hash].isCited
          ) {
            return false;
          }
        }
      }
    }
    return true;
  };

  return (
    <HighlightStateContext.Provider
      value={{
        shouldUpdatePdfSpans,
        zoomScale,
        selectedGuideline,
        setSelectedGuideline,
        setSelectedGuidelineIds,
        readOnlySelectedGuideline,
        setReadOnlySelectedGuideline,
        attachmentId,
        setAttachmentId,
        linking,
        setLinking,
        initializeHighlights,
        addHighlight,
        getHighlights,
        getCurrentLinked,
        addLink,
        removeLink,
        getIndicationMatches,
        closeLinking,
        clicked,
        setClicked,
        getClickedLinked,
        deleteClicked,
        highlightPluginInstance,
        jumpToHighlight,
        getSubmitPayload,
        rightColumnTab,
        setRightColumnTab,
        editing,
        setEditing,
        saveEdit,
        isInitialized,
        promoteHighlight,
        toggleExpandIndication,
        isExpanded,
      }}
    >
      {children}
    </HighlightStateContext.Provider>
  );
};

export const transformToStateShape = (
  initialHighlights: AttachmentGuidelineTextHighlight[] | null,
  guidelines: GuidelinePolicyMap[],
  attachments: Attachment[],
  mlHighlights: boolean,
  hideUserFeedbackHighlights?: boolean
): { initializedHighlights: HighlightState; initializedIndicationDocs: IndicationsDocumentationState } => {
  const attachmentIds = new Set();
  const guidelineIds = new Set();
  const initializedHighlights: HighlightState = {};
  guidelines.forEach((guidelinePolicyMap) => {
    guidelinePolicyMap.guidelineIds?.forEach((guidelineId) => {
      guidelineIds.add(guidelineId);
      if (!(guidelineId in initializedHighlights)) {
        initializedHighlights[guidelineId] = {};
      }
      attachments.forEach((attachment) => {
        attachmentIds.add(extractUploadId(attachment.url));
        if (!(attachment.id in initializedHighlights[guidelineId])) {
          initializedHighlights[guidelineId][extractUploadId(attachment.url)] = {};
        }
      });
    });
  });

  const initializedIndicationDocs: IndicationsDocumentationState = {};
  guidelines.forEach((guidelinePolicyMap) => {
    guidelinePolicyMap.guidelineIds?.forEach((guidelineId) => {
      if (!(guidelineId in initializedIndicationDocs)) {
        initializedIndicationDocs[guidelineId] = {};
      }
    });
  });

  // To maintain audit impartiality, we filter out user highlights
  const filteredInitialHighlights =
    initialHighlights?.filter((highlight) =>
      hideUserFeedbackHighlights ? highlight.authorType !== "USER_FEEDBACK" : true
    ) || [];

  if (filteredInitialHighlights && filteredInitialHighlights?.length > 0) {
    filteredInitialHighlights.forEach((existingHighlight) => {
      existingHighlight.matches
        ?.filter(
          (indicationMatch) =>
            indicationMatch.highlightAreas &&
            indicationMatch.highlightAreas.length > 0 &&
            indicationMatch.status !== "REMOVED" &&
            indicationMatch.status !== "UNLINKED" &&
            (mlHighlights || existingHighlight.authorType !== "ML_PREDICTION")
        )
        .forEach((match) => {
          const newHighlight: Highlight = {
            highlightAreas: match.highlightAreas || [],
            text: match.text || "",
            existing: true,
            editText: match.editText,
            editStartPage: match.editStartPage,
            editEndPage: match.editEndPage,
            isCited: match.isCited,
            isExpanded: true,
          };
          const highlightHash = createHighlightHash(newHighlight);

          // populate highlight object
          const attachmentGuidelineHighlights =
            initializedHighlights[existingHighlight.guidelineId]?.[existingHighlight.attachmentId];
          const indicationMatch: IndicationMatch = {
            indicationUid: match.indicationUid,
            status: "PRESERVED",
            matchCreator: match.matchCreator,
          };
          if (attachmentGuidelineHighlights?.[highlightHash]) {
            attachmentGuidelineHighlights[highlightHash].indicationMatches?.push(indicationMatch);
          } else if (
            attachmentIds.has(existingHighlight.attachmentId) &&
            guidelineIds.has(existingHighlight.guidelineId)
          ) {
            attachmentGuidelineHighlights[highlightHash] = {
              ...newHighlight,
              indicationMatches: [indicationMatch],
            };
          }

          // populate indications DocumentedSpan
          const guidelineIndicationDocs = initializedIndicationDocs[existingHighlight.guidelineId];
          const documentedSpan: DocumentedSpan = {
            hash: highlightHash,
            attachmentId: existingHighlight.attachmentId,
            text: match.editText || match.text || "",
            startPage: match.editStartPage?.toString() || ((match.highlightAreas?.[0].pageIndex || 0) + 1).toString(),
            endPage:
              match.editEndPage?.toString() ||
              match.editStartPage?.toString() ||
              ((match.highlightAreas?.at(-1)?.pageIndex || 0) + 1).toString(),
            isCited: match.isCited,
          };
          if (guidelineIndicationDocs?.[match.indicationUid]) {
            guidelineIndicationDocs[match.indicationUid].push(documentedSpan);
          } else if (
            attachmentIds.has(existingHighlight.attachmentId) &&
            guidelineIds.has(existingHighlight.guidelineId)
          ) {
            guidelineIndicationDocs[match.indicationUid] = [documentedSpan];
          }
        });
    });
  }

  return { initializedHighlights, initializedIndicationDocs };
};

const transformMatches = (attachmentHighlights: Highlight): Match[] => {
  return (
    attachmentHighlights.indicationMatches?.map((indicationMatch: IndicationMatch) => ({
      indicationUid: indicationMatch.indicationUid,
      status: indicationMatch.status,
      highlightAreas: attachmentHighlights.highlightAreas,
      matchCreator: indicationMatch.matchCreator,
      text: attachmentHighlights.text,
      editText: attachmentHighlights.editText,
      editStartPage: attachmentHighlights.editStartPage,
      editEndPage: attachmentHighlights.editEndPage,
      isCited: attachmentHighlights.isCited,
    })) || []
  );
};

const uniqueMatches = (matches: Match[]): Match[] => {
  const uniqueMatchSet = new Set<string>();
  const uniqueMatches: Match[] = [];

  matches.forEach((match) => {
    const matchIdentifier = JSON.stringify({
      indicationUid: match.indicationUid,
      status: match.status,
      text: match.text,
      editText: match.editText,
      editStartPage: match.editStartPage,
      editEndPage: match.editEndPage,
    });

    if (!uniqueMatchSet.has(matchIdentifier)) {
      uniqueMatchSet.add(matchIdentifier);
      uniqueMatches.push(match);
    }
  });

  return uniqueMatches;
};

export const transformHighlightsToPayload = (
  highlights: HighlightState,
  reviewId: string,
  serviceRequestId: string
): AttachmentGuidelineTextHighlight[] => {
  return Object.entries(highlights || {}).reduce((acc, [guidelineId, guidelineHighlights]) => {
    const guidelineEntries = Object.entries(guidelineHighlights).reduce(
      (guidelineAcc, [attachmentId, attachmentHighlights]) => {
        const matches = Object.entries(attachmentHighlights).reduce((matchAcc, [spanHash, highlight]) => {
          const transformedMatches = transformMatches(highlight);
          return matchAcc.concat(transformedMatches);
        }, [] as Match[]);

        if (matches.length > 0) {
          const dedupedMatches = uniqueMatches(matches);
          guidelineAcc.push({
            serviceRequestId,
            reviewId,
            guidelineId,
            attachmentId,
            matches: dedupedMatches,
          });
        }

        return guidelineAcc;
      },
      [] as AttachmentGuidelineTextHighlight[]
    );

    return acc.concat(guidelineEntries);
  }, [] as AttachmentGuidelineTextHighlight[]);
};

const createHighlightHash = (highlight: Highlight): SpanHash => {
  const hash = createHash("sha256")
    .update(JSON.stringify({ highlightAreas: highlight.highlightAreas, text: highlight.text }))
    .digest("hex");
  return hash;
};

const linkedCount = (highlight: Highlight): number => {
  if (!highlight?.indicationMatches) {
    return 0;
  }
  return highlight.indicationMatches.filter(
    (indicationMatch) => indicationMatch.status !== "REMOVED" && indicationMatch.status !== "UNLINKED"
  ).length;
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const HighlightTooltip = styled("div")(({ theme }) => ({
  background: colorsLight.font.main,
  color: theme.palette.primary.contrastText,
  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)",
  borderRadius: "4px",
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  display: "inline-flex",
}));

export const extractUploadId = (attachmentUrl?: string) => {
  const attachmentUrlPath = attachmentUrl?.split("/");
  const attachmentId = attachmentUrlPath?.[attachmentUrlPath?.length - 1];
  return attachmentId ? attachmentId : "";
};
