import React, { HTMLAttributes, PropsWithChildren } from "react";

import { makeStyles } from "@material-ui/core/styles";

const useStylesExpandedMoreDrawerContent = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.drawer.expanded,
    padding: theme.spacing(9, 4, 4, 4),
    height: "100%",
  },
}));

export default function DoubleDrawerExpandedMoreContent({
  children,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  const { container } = useStylesExpandedMoreDrawerContent();

  return (
    <div className={container} {...props}>
      {children}
    </div>
  );
}
