import React from "react";
import Grid from "@material-ui/core/Grid";
import {
  RecommendChangeRuleAction,
  RuleActions,
  ServiceRequestResponse,
  useGetServiceRequestRuleActions,
  useUpdateServiceRequest,
} from "@coherehealth/core-platform-api";
import NudgeHeader from "./NudgeHeader";
import { Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NudgeCard from "./NudgeCard";
import { GetDataError } from "restful-react";
import ImpressionTracking from "common/ImpressionTracking";
import {
  activityContextFromServiceRequest,
  activitySnapshotFromServiceRequest,
  convertRequestFieldsToTrackingField,
} from "util/userActivityTracker";
import { ValidDisplayMessageAction, isRecommendChangeActionWithAttribute } from "util/rule";

interface Props {
  originalRequest: ServiceRequestResponse;
  updatedRequest: ServiceRequestResponse;
  setServiceRequest: (sr: ServiceRequestResponse) => void;
  onEdit?: (sr: ServiceRequestResponse) => void;
  ruleActions: RuleActions;
  refetchActions: ReturnType<typeof useGetServiceRequestRuleActions>["refetch"];
  loadingActions: boolean;
  updateServiceRequest: ReturnType<typeof useUpdateServiceRequest>["mutate"];
  updateRequestError: GetDataError<unknown> | null;
  hasMessageBanners: boolean;
  workflowId?: string;
  addAcceptedNudge: (action: ValidDisplayMessageAction) => void;
  refetchServiceRequest: () => void;
  serviceRequestSubmitted: boolean;
}

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "rgba(209, 199, 228, 0.35)",
    display: "flex",
    border: "none",
    boxShadow: "none",
    padding: theme.spacing(2),
    alignItems: "center",
    borderRadius: "8px",
  },
}));

export default function NudgeContainer({
  setServiceRequest,
  originalRequest,
  updatedRequest,
  onEdit,
  ruleActions,
  refetchActions,
  loadingActions,
  updateRequestError,
  updateServiceRequest,
  hasMessageBanners,
  workflowId,
  addAcceptedNudge,
  refetchServiceRequest,
  serviceRequestSubmitted,
}: Props) {
  const classes = useStyles();
  const unitReductionRuleNudges = ruleActions
    .filter(isRecommendChangeActionWithAttribute)
    .filter((rule) => rule.onAcceptAttribute === "units");
  const nonUnitReductionRuleNudges = ruleActions
    .filter(isRecommendChangeActionWithAttribute)
    .filter((rule) => rule.onAcceptAttribute !== "units");

  let uniqueNonUnitReductionRules: RecommendChangeRuleAction[] = [];
  nonUnitReductionRuleNudges.forEach((rule) => {
    const hasSameRule = uniqueNonUnitReductionRules.filter((uniqueRule) => uniqueRule.ruleId === rule.ruleId);
    if (hasSameRule.length === 0) {
      uniqueNonUnitReductionRules.push(rule);
    }
  });
  return (
    <Card className={classes.card}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NudgeHeader
            originalRequest={originalRequest}
            updatedRequest={updatedRequest}
            amountOfNudges={ruleActions.length}
            loadingActions={ruleActions.length === 0 && loadingActions}
            hasMessageBanners={hasMessageBanners}
          />
        </Grid>
        {[...uniqueNonUnitReductionRules, ...unitReductionRuleNudges].map((action) => (
          <Grid
            item
            key={JSON.stringify([action.onAcceptAttribute, action.onAcceptValue?.value, action?.groupId])}
            xs={12}
          >
            <ImpressionTracking
              event="FIELD_RECOMMENDED_CHANGE"
              stage="AUTH_SUMMARY"
              field={convertRequestFieldsToTrackingField(action.onAcceptAttribute)}
              beforeSnapshot={activitySnapshotFromServiceRequest(updatedRequest)}
              activityContext={{
                ...activityContextFromServiceRequest(updatedRequest),
                ruleId: action.ruleId,
                actionId: action.actionId,
                workflowId: workflowId,
              }}
            >
              <NudgeCard
                originalRequest={originalRequest}
                updatedRequest={updatedRequest}
                onEdit={onEdit}
                setServiceRequest={setServiceRequest}
                action={action}
                updateRequestError={updateRequestError}
                updateServiceRequest={updateServiceRequest}
                refetchActions={refetchActions}
                context={{
                  ...activityContextFromServiceRequest(updatedRequest),
                  ruleId: action.ruleId,
                  actionId: action.actionId,
                  workflowId: workflowId,
                }}
                addAcceptedNudge={addAcceptedNudge}
                refetchServiceRequest={refetchServiceRequest}
                serviceRequestSubmitted={serviceRequestSubmitted}
              />
            </ImpressionTracking>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
}
