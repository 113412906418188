import React, { Dispatch, SetStateAction } from "react";
import { TextField, DenialLetterHeader, DenialLetterBody, ParsedBlock, WithTemplateTitle } from "@coherehealth/common";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";

interface DenialLetterContentProps<DenialTemplateType extends WithTemplateTitle> {
  serviceRequest: ServiceRequestResponse | null;
  isBlob: boolean;
  setIsBlob: Dispatch<SetStateAction<boolean>>;
  selectedTemplate: DenialTemplateType | null | undefined;
  setSelectTemplate: Dispatch<SetStateAction<boolean>>;
  parsedMarkupText: ParsedBlock[] | undefined;
  denialLanguage: string;
  setDenialLanguage: Dispatch<SetStateAction<string>>;
  setCanSubmitForReview: Dispatch<SetStateAction<boolean>>;
  mdNote: string;
  setMdNote: Dispatch<SetStateAction<string>>;
  setHasMadeChanges: Dispatch<SetStateAction<boolean>>;
  hideCustomLetterButton?: boolean;
}

export default function AdhocLetterContent<DenialTemplateType extends WithTemplateTitle>({
  serviceRequest,
  isBlob,
  setIsBlob,
  selectedTemplate,
  setSelectTemplate,
  parsedMarkupText,
  denialLanguage,
  setDenialLanguage,
  setCanSubmitForReview,
  mdNote,
  setMdNote,
  setHasMadeChanges,
  hideCustomLetterButton = false,
}: DenialLetterContentProps<DenialTemplateType>) {
  const denialLetterView = selectedTemplate ? "LETTER_EDIT" : "LETTER_WRITE";
  return (
    <>
      <DenialLetterHeader
        isBlob={isBlob}
        setIsBlob={setIsBlob}
        denialsTemplate={selectedTemplate}
        denialLetterView={denialLetterView}
        canSelectTemplate={true}
        setSelectTemplate={setSelectTemplate}
        setRestoreTemplateOpen={() => {
          setIsBlob(false);
          if (!selectedTemplate) {
            setSelectTemplate(true);
          }
        }}
        hideCustomLetterButton={hideCustomLetterButton}
      />
      {selectedTemplate ? (
        <DenialLetterBody
          parsedMarkupText={parsedMarkupText}
          denialLanguage={denialLanguage}
          setDenialLanguage={setDenialLanguage}
          isBlob={isBlob}
          setCanSubmitForReview={setCanSubmitForReview}
          serviceRequest={serviceRequest}
          mdNote={mdNote}
          setMdNote={setMdNote}
        />
      ) : (
        <TemplateField
          fullWidth
          multiline
          rows={35}
          label="Insert and edit letter here"
          value={denialLanguage}
          onChangeValue={(value) => {
            setDenialLanguage(value);
            if (value === "") {
              setCanSubmitForReview(false);
            } else {
              setCanSubmitForReview(true);
            }
            setHasMadeChanges(true);
          }}
        />
      )}
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const TemplateField = styled(TextField)(({ theme }) => ({
  height: 664,
  "& input": {
    paddingBottom: theme.spacing(72),
    "&.Mui-disabled": {
      cursor: "default",
    },
  },
}));
