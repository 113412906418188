import config from "api/config";
import { Attachment } from "@coherehealth/core-platform-api";
import { AttachmentInfo, downloadUrl } from "@coherehealth/common";
import { SupportedFileType } from "components/DocumentViewer/DocumentViewer";
import { PrintTypes } from "print-js";
import print from "util/print";
import { stringify as stringifyQueryString, parse as parseQueryString } from "qs";

export const createAttachmentOpenPath = (attachment?: Attachment) => {
  return `${config.SERVICE_API_URL}serviceRequest/${attachment?.serviceRequest?.id}/attachment/${attachment?.id}/download`;
};

export const parseServiceSummaryUrl = (attachment?: Attachment): string => {
  if (attachment?.id?.startsWith("service_summary_servicerequest_")) {
    const srId = attachment.id.replace(/^service_summary_servicerequest_/, "");
    return `${config.SERVICE_API_URL}serviceRequest/${srId}/serviceSummary`;
  } else if (attachment?.id?.startsWith("service_summary_authorization_")) {
    const authId = attachment.id.replace(/^service_summary_authorization_/, "");
    return `${config.SERVICE_API_URL}authorization/${authId}/serviceSummary`;
  } else {
    return "";
  }
};

export const convertPathToOcrEnhanced = (originalPath: string) => {
  try {
    const url = new URL(originalPath);
    const query = parseQueryString(url.search.replace(/^[?]/, ""));
    query.preferOcrEnhanced = "true";
    url.search = stringifyQueryString(query);
    return url.toString();
  } catch {
    return originalPath;
  }
};

export const getFileTypeForPrint = (attachment: Attachment, attachmentInfo: AttachmentInfo): PrintTypes | null => {
  let fileType;
  const fileExtension = attachment.name?.split(".")[1];

  if (
    (attachment.contentType === "image/jpeg" ||
      (!attachment.contentType && (fileExtension === "jpg" || fileExtension === "jpeg"))) &&
    !attachmentInfo.convertedToPDF
  ) {
    fileType = SupportedFileType.JPG;
  } else if (
    attachment.contentType === "application/pdf" ||
    (!attachment.contentType && fileExtension === "pdf") ||
    attachmentInfo.convertedToPDF
  ) {
    fileType = SupportedFileType.PDF;
  }

  switch (fileType) {
    case SupportedFileType.JPG:
      return "image";
    case SupportedFileType.PDF:
      return "pdf";
    default:
      return null;
  }
};

export const downloadSingleAttachment = (attachment: Attachment, attachmentInfo?: AttachmentInfo) => {
  if (attachment.name && attachmentInfo?.fileUrl) {
    if (attachmentInfo.convertedToPDF) {
      const splitFileName = attachment.name.split(".");
      splitFileName.splice(splitFileName.length - 1, 1, "pdf");
      const pdfConvertedFileName = splitFileName.join(".");
      downloadUrl(attachmentInfo.fileUrl, pdfConvertedFileName);
    } else {
      downloadUrl(attachmentInfo.fileUrl, attachment.name);
    }
  }
};

export const printSingleAttachment = (attachment: Attachment, attachmentInfo?: AttachmentInfo) => {
  if (attachment && attachmentInfo?.fileUrl) {
    const fileTypeForPrint = getFileTypeForPrint(attachment, attachmentInfo);
    if (fileTypeForPrint) {
      print(attachmentInfo.fileUrl, fileTypeForPrint);
    }
  }
};
