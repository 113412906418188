import { RadioGroupOption } from "@coherehealth/common";
import { PendingReasonsWithLabel, useGetAllowedPendingReasons } from "@coherehealth/core-platform-api";
import { useEffect } from "react";
import { IGNORE_ERRORS, error as logError } from "logger";

interface FetchError {
  data?: string | FetchError;
  message?: string;
}

export default function useFetchPendingReason(serviceRequestId: string) {
  const { data: allowedPendingReasonsResponse, refetch: refetchPendingReasons } = useGetAllowedPendingReasons({
    id: serviceRequestId,
    lazy: true,
  });

  useEffect(() => {
    const shouldIgnoreErrorEntry = (error: FetchError, depth = 0): boolean => {
      const ignoreEntry = IGNORE_ERRORS.includes(error.message ?? "");
      if (!ignoreEntry && error.data && typeof error.data !== "string" && depth < 5) {
        return shouldIgnoreErrorEntry(error.data, depth + 1);
      }
      return ignoreEntry;
    };

    let unmounted = false;
    const getConfig = async () => {
      try {
        if (serviceRequestId && !unmounted) {
          refetchPendingReasons();
        }
      } catch (err) {
        if (!shouldIgnoreErrorEntry(err as FetchError)) {
          logError(`Error retrieving the allowed pending reasons for service request: ${serviceRequestId}`);
        }
      }
    };

    if (!unmounted) {
      getConfig();
    }
    return () => {
      unmounted = true;
    };
  }, [serviceRequestId, refetchPendingReasons]);

  const pendingReasonOptions = (allowedPendingReasonsResponse as PendingReasonsWithLabel[]) || [];

  return {
    allowedPendingReasons: pendingReasonOptions.map(
      ({ id, label, disabled }) => ({ id, label, disabled } as RadioGroupOption)
    ),
  };
}
