import React, { Dispatch, SetStateAction, useContext } from "react";

import {
  Body1,
  colorsLight,
  DateTextField,
  formatDateStr,
  InlineButton,
  PrimaryButton,
  TextField,
} from "@coherehealth/common";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import AutoFillIcon from "../../../images/AutoFillIcon";
import { SuggestionContext } from "../../../AuthBuilder/SuggestionContext";
import { H4 } from "@coherehealth/design-system";

export interface SearchFormState {
  trackingNumber?: string;
  memberId: string;
  memberDob: string;
  memberLastName: string;
  patientSearchFieldsError: boolean;
}

interface Props {
  search: (event: React.FormEvent<HTMLFormElement>) => void;
  searchFormState: SearchFormState;
  setSearchFormState: Dispatch<SetStateAction<SearchFormState>>;
  loadingSearch: boolean;
  collapsed: boolean;
  resetSearch: () => void;
  searchHeaderText: string;
  collapsedHeaderText?: string;
  hideTrackingNumberSearch?: boolean;
  isReferralRequestFeatureFlagEnabled?: boolean;
}

const patientSearchFieldErrorText = "Must enter 2 of 3 fields";

const useStyles = makeStyles((theme) => ({
  searchAgainClickTarget: {
    cursor: "pointer",
    "& input": {
      cursor: "pointer",
    },
  },
  patientInfoHeader: {
    padding: theme.spacing(3, 1, 1),
  },
  searchHeader: {
    marginBottom: theme.spacing(1),
    color: "textSecondary",
  },
  submitButtonWrapper: {
    margin: theme.spacing(2, 0, 3),
  },
}));

export default function SearchFields({
  search,
  searchFormState,
  setSearchFormState,
  loadingSearch,
  collapsed,
  resetSearch,
  searchHeaderText,
  collapsedHeaderText,
  hideTrackingNumberSearch,
  isReferralRequestFeatureFlagEnabled = false,
}: Props) {
  const { trackingNumber, memberId, memberDob, memberLastName, patientSearchFieldsError } = searchFormState;

  const classes = useStyles();
  const { suggestedMember } = useContext(SuggestionContext);

  const setFieldValue: (
    fieldName: keyof Omit<SearchFormState, "patientSearchFieldsError">
  ) => (value: string) => void = (fieldName) => (value) =>
    setSearchFormState({ ...searchFormState, [fieldName]: value });
  if (collapsed) {
    let label, value;
    if (trackingNumber) {
      label = "Showing results for auth or tracking number";
      value = trackingNumber;
    } else {
      label = "Search by auth number, tracking number, or patient info";
      value = [memberId, memberDob, memberLastName].filter((v) => !!v).join(" • "); // &#8226; = bullet point
    }

    return (
      <>
        {collapsedHeaderText && <Body1 className={classes.searchHeader}>{collapsedHeaderText}</Body1>}
        <div onClick={resetSearch} className={classes.searchAgainClickTarget}>
          <TextField
            disabled
            fullWidth
            color="secondary"
            value={value}
            label={label}
            InputProps={{
              endAdornment: (
                <InlineButton style={{ width: 200, color: colorsLight.font.secondary }} startIcon={<SearchIcon />}>
                  Search again
                </InlineButton>
              ),
            }}
          />
        </div>
      </>
    );
  }

  const placeholder = isReferralRequestFeatureFlagEnabled ? "eg. AXXXXXX or R-XXXXXXXX" : "";

  return (
    <>
      <Body1 className={classes.searchHeader}>{searchHeaderText}</Body1>
      <Grid container spacing={2} component="form" onSubmit={search}>
        {!hideTrackingNumberSearch && (
          <>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={"Auth or tracking number"}
                placeholder={placeholder}
                value={trackingNumber}
                onChangeValue={setFieldValue("trackingNumber")}
                data-public
              />
            </Grid>
            <H4 className={classes.patientInfoHeader}>OR search by member info (2 of 3 fields required)</H4>
          </>
        )}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={"Member ID"}
            value={memberId}
            onChangeValue={setFieldValue("memberId")}
            error={patientSearchFieldsError && !memberId}
            helperText={patientSearchFieldsError && !memberId && patientSearchFieldErrorText}
            InputProps={{
              endAdornment:
                suggestedMember?.memberId?.predictedValue && suggestedMember.memberId.predictedValue === memberId ? (
                  <AutoFillIcon />
                ) : undefined,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <DateTextField
            fullWidth
            label={"Member date of birth"}
            value={memberDob}
            autoFillIcon={
              suggestedMember?.memberDob?.predictedValue &&
              formatDateStr(suggestedMember.memberDob.predictedValue) === memberDob ? (
                <AutoFillIcon />
              ) : undefined
            }
            onChangeValue={setFieldValue("memberDob")}
            error={patientSearchFieldsError && !memberDob}
            helperText={patientSearchFieldsError && !memberDob && patientSearchFieldErrorText}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={"Member last name"}
            value={memberLastName}
            onChangeValue={setFieldValue("memberLastName")}
            error={patientSearchFieldsError && !memberLastName}
            helperText={patientSearchFieldsError && !memberLastName && patientSearchFieldErrorText}
            InputProps={{
              endAdornment:
                suggestedMember?.memberLastName?.predictedValue &&
                suggestedMember.memberLastName.predictedValue === memberLastName ? (
                  <AutoFillIcon />
                ) : undefined,
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.submitButtonWrapper}>
          <PrimaryButton fullWidth loading={loadingSearch} disabled={loadingSearch} type="submit" data-public>
            Search
          </PrimaryButton>
        </Grid>
      </Grid>
    </>
  );
}
