import { CohereTable, H5 } from "@coherehealth/common";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import ClinicalReviewCard from "common/ClinicalReviewCard";
import { StyledBody1 } from "components/ClinicalReview/ClinicalReviewInfoPanel/ServiceRequestInformation";
import { CardContainer } from "components/ClinicalReview/ClinicalReviewInfoPanel";
import { UseAuthStatusHistoryProps } from "./useAuthStatusHistory";
import { useCallback, useState } from "react";
import { queryProcessedAuditHistoryData } from "./AuthStatusHistoryUtil";

const AuthStatusHistory = ({
  loadingAuthStatusHistory,
  authStatusHistory,
  queryParams,
  setQueryParams,
  tableColumns,
}: UseAuthStatusHistoryProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const sortHandler = useCallback(
    (columnName: string) => {
      setQueryParams((prevQueryParams) => {
        const sortBy = prevQueryParams.sortBy?.[0];
        return {
          ...prevQueryParams,
          sortBy: [
            {
              key: columnName,
              sortOrder: sortBy?.sortOrder === "ASC" ? "DESC" : "ASC",
            },
          ],
        };
      });
    },
    [setQueryParams]
  );

  if (loadingAuthStatusHistory) {
    return <CircularProgress />;
  }
  const finalData = queryProcessedAuditHistoryData(queryParams, authStatusHistory);

  return (
    <Box sx={{ width: "100%" }}>
      <CardContainer>
        <ClinicalReviewCard
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          header={
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: 6 }}>
                <H5>Status change history</H5>
              </Grid>
              <div>
                <StyledBody1>
                  {authStatusHistory?.length || 0}{" "}
                  {authStatusHistory?.length === 1 ? "status change" : "status changes"}
                </StyledBody1>
              </div>
            </Grid>
          }
        >
          <CohereTable<any>
            loading={loadingAuthStatusHistory}
            tableColumns={tableColumns}
            data={finalData || []}
            paginateViaParams
            paginateParams={queryParams}
            setPaginateParams={setQueryParams}
            displayMax={queryParams.max ? queryParams.max - 1 : 10}
            totalCount={authStatusHistory?.length}
            cardHeight={"short"}
            sortingColumnName={queryParams.sortBy?.[0]?.key}
            sortingAscending={queryParams.sortBy?.[0]?.sortOrder === "ASC"}
            onSortChange={sortHandler}
            readonly={true}
            showFullInfo={true}
            authHistorySpacing={true}
          />
        </ClinicalReviewCard>
      </CardContainer>
    </Box>
  );
};

export default AuthStatusHistory;
