import { RequestFormConfigurationFieldSpecification } from "@coherehealth/core-platform-api";
import { PropsWithChildren } from "react";

type ConfigWrapperProps = {
  forceShow?: boolean;
};

export default function SRFormConfigFieldWrapper({
  fieldSpec,
  forceShow,
  children,
}: PropsWithChildren<Partial<RequestFormConfigurationFieldSpecification> & ConfigWrapperProps>) {
  if (forceShow) {
    return <>{children}</>;
  }
  if (!fieldSpec || fieldSpec === "OMIT") {
    return null;
  }
  return <>{children}</>;
}
