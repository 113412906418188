import React, { Dispatch } from "react";
import { Container, useTheme } from "@material-ui/core";
import BucketedServiceContainer from "./BucketedServiceContainer";
import AdditionalServiceContainer from "./AdditionalServiceContainer";
import PatientAuthHistory from "./PatientAuthHistory";
import {
  CarePath,
  ClinicalService,
  ServiceDeterminationProcedureCodeBucket,
  ServiceRequestResponse,
  Patient,
  HealthPlan,
} from "@coherehealth/core-platform-api";
import RequestorCard from "components/Requestor/RequestorCard";
import { AuthBuilderRequestorProps } from "../index";
import { useFeature, plusDays, today, useMuiContainerStyles } from "@coherehealth/common";
import { getPatientHealthPlanName } from "util/patientUtils";

interface Props extends AuthBuilderRequestorProps {
  primaryCarePath?: CarePath;
  buckets: ServiceDeterminationProcedureCodeBucket[];
  setBuckets: Dispatch<ServiceDeterminationProcedureCodeBucket[]>;
  serviceRequestHistory: ServiceRequestResponse[];
  patientData: Patient | null;
  faxId?: string;
}

export default function ServiceSelectionContainer({
  primaryCarePath,
  buckets,
  setBuckets,
  serviceRequestHistory,
  requestorFormAuthorized,
  patientData,
  ...requestorProps
}: Props) {
  const otherProcedureOptionEnabled = useFeature("authBuilderOtherProcedureSelect");
  const containerClasses = useMuiContainerStyles();

  const { spacing } = useTheme();

  const removeBucket = (bucketToRemove: ServiceDeterminationProcedureCodeBucket) =>
    setBuckets(buckets?.filter((bucket) => bucket.clinicalService?.id !== bucketToRemove.clinicalService?.id));

  const addBucket = (bucketToAdd: ServiceDeterminationProcedureCodeBucket) => setBuckets([...buckets, bucketToAdd]);

  const clinicalServices: (ClinicalService | undefined)[] =
    primaryCarePath?.serviceCategories?.flatMap((sc) =>
      sc?.clinicalServices?.map((service) => ({ ...service, serviceCategoryName: sc.name }))
    ) || [];

  // RR authorizations should not be visible as “previous services” in Streamlined Care Path Service Selection steps
  const serviceRequestHistoryPastYear = serviceRequestHistory.filter(
    (sr) => plusDays(365, sr.startDate).getTime() > today().getTime() && !sr.reconClaim
  );

  const isPreSelectedBucketService = (service: ClinicalService | undefined) =>
    buckets
      .filter((bucket) => bucket?.procedureCodes && bucket.procedureCodes.length > 0)
      .map((bucket) => bucket.clinicalService?.id)
      .includes(service?.id);
  const hasPatientHistory = (service: ClinicalService) =>
    serviceRequestHistoryPastYear.map((sr) => sr.clinicalService?.id).includes(service?.id);

  const preSelectedBuckets = buckets.filter((bucket) => isPreSelectedBucketService(bucket?.clinicalService));

  const patientHealthPlan = patientData && getPatientHealthPlanName(patientData);

  const healthPlanFilteredCarePathServices = clinicalServices?.filter((service) =>
    service?.allowedHealthPlans?.some((plan: HealthPlan) => {
      return patientHealthPlan && patientHealthPlan === plan.name;
    })
  );

  //filter all care path services not bucketed and with patient auth history
  const unselectedCarePathServicesWithHistory = healthPlanFilteredCarePathServices?.filter(
    (service) => service && !isPreSelectedBucketService(service) && hasPatientHistory(service)
  );

  const uniqueServicesWithAuthHistory: ClinicalService[] = [];

  //de-dupe all clinical services of historical service requests
  for (const sr of serviceRequestHistoryPastYear) {
    if (sr.clinicalService?.id && !uniqueServicesWithAuthHistory.map((cs) => cs?.id).includes(sr.clinicalService?.id)) {
      uniqueServicesWithAuthHistory.push(sr?.clinicalService);
    }
  }

  //find all non care path services that have auth history
  const nonCarePathServicesWithHistory = uniqueServicesWithAuthHistory.filter(
    (service) =>
      service &&
      !isPreSelectedBucketService(service) &&
      !unselectedCarePathServicesWithHistory.map((cs) => cs?.id).includes(service?.id)
  );

  //filter out remaining care path services to list in additional procedures section
  const remainingServices: (ClinicalService | undefined)[] = healthPlanFilteredCarePathServices.filter(
    (service) => service && !isPreSelectedBucketService(service) && !hasPatientHistory(service)
  );

  //determines whether or not we should show the "Other procedures" option at the bottom
  // (if true, it should already be preselected at the top)
  const hasBucketedSingleService = preSelectedBuckets.find((bucket) => !bucket.clinicalService);

  return (
    <Container classes={containerClasses} maxWidth="lg">
      {requestorFormAuthorized && (
        <div style={{ paddingTop: spacing(5) }}>
          <RequestorCard {...requestorProps} />
        </div>
      )}
      {preSelectedBuckets.length > 0 && (
        <BucketedServiceContainer
          buckets={preSelectedBuckets}
          serviceRequests={serviceRequestHistoryPastYear}
          removeBucket={removeBucket}
          addBucket={addBucket}
        />
      )}
      {(unselectedCarePathServicesWithHistory.length > 0 || nonCarePathServicesWithHistory) && (
        <PatientAuthHistory
          selectedBuckets={buckets}
          carePathServices={unselectedCarePathServicesWithHistory}
          nonCarePathServices={nonCarePathServicesWithHistory}
          serviceRequests={serviceRequestHistoryPastYear}
          primaryCarePath={primaryCarePath || undefined}
          removeBucket={removeBucket}
          addBucket={addBucket}
        />
      )}
      {remainingServices && remainingServices.length > 0 && primaryCarePath && (
        <AdditionalServiceContainer
          primaryCarePath={primaryCarePath}
          remainingServices={remainingServices || []}
          buckets={buckets}
          removeBucket={removeBucket}
          addBucket={addBucket}
          displayOtherProcedureOption={!hasBucketedSingleService && otherProcedureOptionEnabled}
        />
      )}
    </Container>
  );
}
