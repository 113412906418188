import React from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, Theme } from "@material-ui/core/styles";
import { H3 } from "../Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import { Helmet } from "react-helmet-async";

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PreviewIdContainer = styled(({ status, ...other }) => <H3 {...other} />)<
  Theme,
  { status: "success" | "error" | "primary" }
>(({ theme, status }) => ({
  verticalAlign: "middle",
  display: "inherit",
  position: "absolute",
  fontWeight: "bold",
  color: theme.palette[status].main,
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
}));

const PreviewStatus = ({
  previewId,
  gitHash,
  pullRequestNumber,
  style,
}: {
  previewId: string;
  gitHash?: string;
  pullRequestNumber?: string;
  style?: React.CSSProperties;
}) => {
  const previewDisplay = [pullRequestNumber, gitHash].includes(previewId)
    ? {
        status: "success",
        titleText: `${gitHash === previewId && "Git hash"}${
          pullRequestNumber === previewId && "PR number"
        } matches preview parameter`,

        icon: CheckCircleIcon,
        text: `Preview ${previewId}`,
      }
    : {
        status: "error",
        titleText: "Neither git hash or git pr match preview parameter",
        icon: ErrorIcon,
        text: `Failed to load preview ${previewId} current hash: ${gitHash}`,
      };

  const { icon: Icon, text, status, titleText } = previewDisplay;
  return (
    <PreviewIdContainer status={status as any} title={titleText} style={style}>
      <Helmet>
        <title>Cohere Auth - {text}</title>
      </Helmet>
      <Icon /> {text}
    </PreviewIdContainer>
  );
};

export default PreviewStatus;
