import {
  Body2,
  Caption,
  H2,
  InlineButton,
  Modal,
  ModalHeader,
  ModalDescription,
  PrimaryButton,
  TextField,
  useStableUniqueId,
} from "@coherehealth/common";
import { CreateOrganizationRequestBody, NameTinPair } from "@coherehealth/core-platform-api";
import Box from "@material-ui/core/Box";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import listReplace from "util/listReplace";
import listRemove from "util/listRemove";
import { getTINDisplayValue, updateTinFromDisplayValue, isTinValid } from "util/providerUtils";
import { ComponentProps, HTMLAttributes, useEffect, useState } from "react";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";

interface Props {
  open: boolean;
  handleClose: () => void;
  onSubmit: (arg0: CreateOrganizationRequestBody) => void;
  submitting?: boolean;
}

export default function CreateOrganizationModal({ open, handleClose, onSubmit, submitting }: Props) {
  const titleId = useStableUniqueId("add-organization");
  const instructionsId = useStableUniqueId("add-organization-instructions");
  const formId = useStableUniqueId("add-organization-form");
  const [newOrgName, setNewOrgName] = useState("");
  const [primaryTin, setPrimaryTin] = useState("");
  const [otherTins, setOtherTins] = useState<NameTinPair[]>([]);

  // todo are TINs required? TIN validation?
  const requiredFieldsFilled =
    Boolean(newOrgName) &&
    isTinValid(primaryTin) &&
    otherTins.every(({ name, tin }) => !!name && isTinValid(tin || ""));

  const handleSubmit: HTMLAttributes<HTMLFormElement>["onSubmit"] = (e) => {
    e.preventDefault();

    if (requiredFieldsFilled) {
      onSubmit({
        name: newOrgName,
        tinList: [primaryTin, ...otherTins.map(({ tin }) => tin)],
        associatedTins: otherTins,
        status: "ACTIVE",
      });
    }
  };

  useEffect(() => {
    if (!open) {
      // clear fields
      setNewOrgName("");
      setPrimaryTin("");
      setOtherTins([]);
    }
  }, [open, setNewOrgName, setPrimaryTin, setOtherTins]);

  const { spacing } = useTheme();

  return (
    <Modal
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      aria-labelledby={titleId}
      aria-describedby={instructionsId}
      open={open}
    >
      <ModalHeader>
        <H2 id={titleId} data-public>
          Add new organization
        </H2>
        <ModalDescription id={instructionsId} data-public>
          Create a new organization by providing the information below
        </ModalDescription>
      </ModalHeader>
      <Grid
        container
        id={formId}
        component="form"
        onSubmit={handleSubmit}
        spacing={2}
        alignItems="center"
        justify="center"
      >
        <Row>
          <TextField
            fullWidth
            label="Main organization name"
            value={newOrgName}
            onChangeValue={setNewOrgName}
            data-public
          />
        </Row>
        <Row>
          <TextField
            fullWidth
            label={"Main taxpayer identification number (TIN)"}
            value={getTINDisplayValue(primaryTin)}
            onChangeValue={updateTinFromDisplayValue((formattedTin) => setPrimaryTin(formattedTin))}
          />
        </Row>
        {otherTins.length > 0 && (
          <Row style={{ paddingBottom: 0, paddingTop: spacing(2) }}>
            <Caption color="textSecondary" data-public>
              Subsidiary organizations
            </Caption>
          </Row>
        )}
        {otherTins.map(({ name, tin }, idx) => {
          const setName = (newName: string) => {
            setOtherTins(listReplace(otherTins, idx, { name: newName, tin }));
          };

          const setTin = (newTin: string) => {
            setOtherTins(listReplace(otherTins, idx, { name, tin: newTin }));
          };

          return (
            <Row key={`extra-organization-tin-${idx}`}>
              <Box display="flex" alignItems="center">
                <TextField
                  label="Organization name"
                  value={name}
                  onChangeValue={setName}
                  style={{ flexGrow: 1, paddingRight: spacing(1) }}
                  fullWidth
                  data-public
                />
                <TextField
                  label={"TIN"}
                  value={getTINDisplayValue(tin)}
                  onChangeValue={updateTinFromDisplayValue(setTin)}
                  style={{ flexGrow: 1, paddingRight: spacing(1), maxWidth: 140 }}
                  fullWidth
                />
                <IconButton
                  onClick={() => {
                    setOtherTins(listRemove(otherTins, idx));
                  }}
                  data-public
                >
                  <DeleteIcon data-public />
                </IconButton>
              </Box>
            </Row>
          );
        })}
        <Row>
          <InlineButton
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setOtherTins([...otherTins, { name: "", tin: "" }]);
            }}
          >
            <Body2>Add another TIN</Body2>
          </InlineButton>
        </Row>
        <CreateButton loading={submitting} disabled={!requiredFieldsFilled} type="submit" form={formId} data-public>
          Create
        </CreateButton>
      </Grid>
    </Modal>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CreateButton = styled(PrimaryButton)(({ theme }) => ({
  padding: theme.spacing(2, 10),
  margin: theme.spacing(2, 0),
}));

const Row = (props: ComponentProps<typeof Grid>) => <Grid item xs={12} {...props} />;
