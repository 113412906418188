import { Guideline, Indication } from "@coherehealth/core-platform-api";
import { useEffect, useMemo, useReducer } from "react";
import { DynamicIndication, populateDepthFirstNodeList, runLogicForCheckedIndications } from "../util";
import { DynamicElemBlock, generateDynamicElementBlocks } from "../util/guidelineIndicationOperatorParser";

export interface GuidelineProp {
  guideline: Guideline | null;
  checkedIndications: Indication[];
  unmetIndications: Indication[];
}

interface GuidelineValues {
  indicationBlocks: DynamicElemBlock[];
  isCriteriaMet?: boolean; // Not sure if isCriteriaMet can be undefined, Open for discussion
  guidelineIndication?: DynamicIndication[];
  hasOnlyOneTopIndication: boolean;
  guidelineElemRefs: Element[];
}

type GuidelineState = {
  elementBlocks?: DynamicElemBlock[];
  indications?: DynamicIndication[];
  checkedIndications?: Indication[];
  unmetIndications?: Indication[];
  guideline: DynamicGuideline | null;
  dynamicIndication?: DynamicIndication[];
  guidelineElemRefs?: Element[];
};

type RunIndicationsAction = {
  type: "RUN_INDICATIONS";
  guideline: DynamicGuideline | null;
  checkedIndications: Indication[];
  unmetIndications: Indication[];
};

export type DynamicGuideline = {
  active?: boolean;
  indications: DynamicIndication[];
  guidelineHtml?: string;
};

type GuidelineIndicationAction = RunIndicationsAction;

const guidelineIndicationReducer = (state: GuidelineState, action: GuidelineIndicationAction): GuidelineState => {
  switch (action.type) {
    case "RUN_INDICATIONS":
      const { guideline } = action;
      if (guideline?.active) {
        return runIndications(state, action);
      } else {
        return state;
      }
    default:
      return state;
  }
};

const initializeGuidelineState = (initialState: GuidelineState): GuidelineState => {
  const { guideline } = initialState;
  const guidelineElemRefs: Element[] = populateDepthFirstNodeList(guideline?.guidelineHtml ?? "");
  const initialBlocks: DynamicElemBlock[] = generateDynamicElementBlocks(
    guidelineElemRefs,
    Array.from(Array(guidelineElemRefs.length).keys()),
    guideline?.indications ?? []
  );
  const indications: DynamicIndication[] | undefined = guideline?.indications;
  return {
    ...initialState,
    elementBlocks: initialBlocks,
    indications: indications,
    guidelineElemRefs,
  };
};

const runIndications = (state: GuidelineState, action: RunIndicationsAction): GuidelineState => {
  const { checkedIndications, unmetIndications, guideline } = action;
  const evaluateIndication =
    guideline?.indications &&
    runLogicForCheckedIndications(guideline.indications, checkedIndications, unmetIndications);
  const guidelineElemRefs: Element[] = populateDepthFirstNodeList(guideline?.guidelineHtml ?? "");
  const initialBlocks: DynamicElemBlock[] = generateDynamicElementBlocks(
    guidelineElemRefs,
    Array.from(Array(guidelineElemRefs.length).keys()),
    evaluateIndication ?? []
  );
  return { ...state, indications: evaluateIndication, elementBlocks: initialBlocks };
};

// Guideline indication reducer
export const useGuidelineIndication = ({
  guideline,
  checkedIndications,
  unmetIndications,
}: GuidelineProp): GuidelineValues => {
  const dynamicGuideline: DynamicGuideline = {
    active: guideline?.active,
    guidelineHtml: guideline?.guidelineHtml,
    indications: guideline?.indications as DynamicIndication[],
  };
  const initialState: GuidelineState = { guideline: dynamicGuideline };
  const [guidelineState, guidelineDispatch] = useReducer(
    guidelineIndicationReducer,
    initialState,
    initializeGuidelineState
  );
  useEffect(() => {
    if (guideline) {
      const dynamicGuideline: DynamicGuideline = {
        active: guideline?.active,
        guidelineHtml: guideline?.guidelineHtml,
        indications: guideline?.indications as DynamicIndication[],
      };
      guidelineDispatch({ type: "RUN_INDICATIONS", guideline: dynamicGuideline, checkedIndications, unmetIndications });
    }
  }, [checkedIndications, guideline, unmetIndications]);

  const hasOnlyOneTopIndication = guidelineState.indications?.length === 1;

  return {
    indicationBlocks: useMemo(() => guidelineState.elementBlocks || [], [guidelineState.elementBlocks]),
    isCriteriaMet: guidelineState.indications?.every((ind) => ind.dynamicOutcome === "PASS"),
    guidelineIndication: guidelineState.indications,
    hasOnlyOneTopIndication,
    guidelineElemRefs: guidelineState.guidelineElemRefs || [],
  };
};
