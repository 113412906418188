import React, { useContext, useEffect, useState } from "react";

import {
  PrimaryButton,
  useFeature,
  queueMgmtBaseUrl,
  useMuiContainerStyles,
  TertiaryButton,
} from "@coherehealth/common";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useCompleteServiceCase } from "@coherehealth/qm-api";
import {
  Requestor,
  ServiceRequestCreatePayload,
  ServiceRequestResponse,
  useBatchSubmitServiceRequests,
  useCreateMedicalServiceRequest,
  usePostClinicalAssessment,
  useUpdateServiceRequest,
} from "@coherehealth/core-platform-api";
import SubmissionModal, { SubmissionStatus } from "components/ServiceRequest/Submission/SubmissionModal";
import { useAuthorized } from "authorization";
import { requestorValid } from "components/Requestor/RequestorEdit";
import { useSnackbar } from "notistack";
import { FaxStep } from ".";
import { activityContextFromServiceRequest, useTrackUserInteraction } from "util/userActivityTracker";
import { payloadFromSRFormContent, formContentFromResponse } from "util/serviceRequest";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import AutocompleteModal from "./AutocompleteModal";
import config from "api/config";
import { Grid } from "@material-ui/core";
import { clearNotShownClinicalQuestions, incompleteRequiredQuestions } from "util/clinicalAssessment";
import { ClinicalAssessmentContext } from "components/ClinicalAssessment";
import ClinicalSkipOrIncompleteModal from "components/ClinicalAssessment/ClinicalSkipOrIncompleteModal";

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    top: "auto",
    bottom: 0,
  },
  colorPrimary: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
  },
}));

interface Props {
  step: FaxStep;
  setStep: (step: FaxStep) => void;
  setAttemptedSubmitService: (b: boolean) => void;
  patientId: string;
  canBeSubmitted: boolean;
  serviceRequest: ServiceRequestResponse | undefined;
  requestor: Requestor | undefined;
  loading: boolean;
  formContent: ServiceRequestFormContent;
  setFormContent: (form: ServiceRequestFormContent) => void;
  setServiceRequest: React.Dispatch<React.SetStateAction<ServiceRequestResponse | undefined>>;
  onEdit?: any;
  setShowFooter: React.Dispatch<React.SetStateAction<boolean>>;
  missingRequestor: boolean;
  faxId: string;
  isCohereFax: boolean;
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSaveAndReview: Function;
  cannotSubmit: boolean;
  caseId?: string;
}

export default function Footer({
  isCohereFax,
  step,
  setStep,
  setAttemptedSubmitService,
  patientId,
  canBeSubmitted,
  requestor,
  loading,
  formContent,
  setFormContent,
  serviceRequest,
  setServiceRequest,
  setShowFooter,
  missingRequestor,
  onSaveAndReview,
  faxId,
  cannotSubmit,
  caseId,
}: Props) {
  const theme = useTheme();
  const classes = useStyles();
  const containerClasses = useMuiContainerStyles();
  const { enqueueSnackbar } = useSnackbar();
  const simplifiedServiceFrequency = useFeature("simplifiedServiceFrequency");
  const { clinicalAssessment, subQuestions: subQuestionsVisibility } = useContext(ClinicalAssessmentContext);
  const requestorFormPermitted = useAuthorized("REQUESTOR_FORM");
  const requestorFormValid = (requestorFormPermitted && requestorValid(requestor)) || !requestorFormPermitted;
  const [showSubmissionModal, setShowSubmissionModal] = useState(false);
  const [autocompleteModalOpen, setAutocompleteModalOpen] = useState(false);
  const [errorDetails, setErrorDetails] = useState<string>();
  const [skipModalOpen, setSkipModalOpen] = useState<boolean>(false);
  const [modalState, setModalState] = useState<"SKIP_CHECK" | "INCOMPLETE_CHECK">();
  let submissionStatus: SubmissionStatus;

  const {
    mutate: submitServiceRequests,
    loading: submitServiceRequestLoading,
    error: submitServiceRequestsError,
  } = useBatchSubmitServiceRequests({});

  const {
    mutate: createMedicalServiceRequest,
    loading: createMedicalServiceRequestLoading,
    error: createMedicalServiceRequestError,
  } = useCreateMedicalServiceRequest({});

  const {
    mutate: patch,
    loading: patchLoading,
    error: patchError,
  } = useUpdateServiceRequest({
    id: serviceRequest?.id || "",
  });

  const { mutate: updateServiceCase, loading: serviceCaseLoading } = useCompleteServiceCase({
    id: caseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
    onMutate: () => {
      window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${caseId}`);
    },
  });

  const { loading: clinicalAssessmentLoading, error, mutate } = usePostClinicalAssessment({});

  useEffect(() => {
    if (createMedicalServiceRequestError) {
      enqueueSnackbar(`Error creating service request: ${createMedicalServiceRequestError.message}`, {
        variant: "error",
      });
    }
  }, [createMedicalServiceRequestError, enqueueSnackbar]);

  useEffect(() => {
    if (patchError) {
      if (!(typeof patchError?.data === "string" || patchError?.data instanceof String) && patchError?.data.message) {
        setErrorDetails(stripHTML(patchError.data.message));
      }
      enqueueSnackbar(`Error updating service request: ${patchError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, patchError]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(`Failed to post assessment: ${error.message}`, { variant: "error" });
    }
  }, [error, enqueueSnackbar]);

  const trackUserActivityInteraction = useTrackUserInteraction();

  const onFinalSubmission = async (requestor: Requestor) => {
    if (serviceRequest) {
      const serviceRequestToSubmit = {
        ...serviceRequest,
        requestor,
      };
      const srList = await submitServiceRequests([serviceRequestToSubmit]);
      const retVal = srList[0];
      trackUserActivityInteraction({
        event: "SUBMIT_FAX_SERVICE_REQUEST",
        stage: isCohereFax ? "COHERE_TEMPLATE_FAX_FLOW" : "NON_COHERE_TEMPLATE_FAX_FLOW",
        activityContext: {
          ...(retVal && activityContextFromServiceRequest(retVal)),
          faxId,
        },
      });
      setServiceRequest(retVal);
      setFormContent(formContentFromResponse(retVal));
      if (caseId) {
        const outcome = retVal.requestType === "CONTINUATION" ? "NEW_CONTINUATION_SR" : "NEW_SR";
        const description =
          outcome === "NEW_CONTINUATION_SR" ? "New continuation request created" : "New request created";
        updateServiceCase({
          outcome: outcome,
          dateCompleted: new Date().toISOString(),
          description: description,
          serviceRequests: [{ serviceRequestId: retVal.id, status: retVal.authStatus, cohereId: retVal.cohereId }],
        });
      }
    }
  };

  const saveAndReview = async () => {
    let saveError = false;
    if (canBeSubmitted) {
      let response;
      try {
        response = await submitServiceRequestForm(formContent);
      } catch (e) {
        enqueueSnackbar("Failed to save service request", { variant: "error" });
        saveError = true;
      }
      if (!saveError) {
        onSaveAndReview();
      }
      if (response) {
        setServiceRequest(response);
        setFormContent(formContentFromResponse(response));
      }
    }
  };

  const submitServiceRequestForm = async (sr: ServiceRequestFormContent) => {
    const payload: ServiceRequestCreatePayload = {
      ...payloadFromSRFormContent(sr, simplifiedServiceFrequency, serviceRequest ? serviceRequest.authStatus : "DRAFT"),
      patient: patientId,
      requestor: requestor,
      caseId,
      requestType: "INITIAL",
    };
    //if the form content has an id, it has already been saved, and we need to patch; else create
    if (sr?.id) {
      return await patch({ ...payload }, { pathParams: { id: sr?.id } });
    } else {
      return await createMedicalServiceRequest(payload);
    }
  };

  if (submitServiceRequestsError) {
    submissionStatus = SubmissionStatus.Failure;
  } else if (submitServiceRequestLoading) {
    submissionStatus = SubmissionStatus.Loading;
  } else {
    submissionStatus = SubmissionStatus.Success;
  }

  const submitClinicalAssessmentAndContinue = async () => {
    if (clinicalAssessment && clinicalAssessment.questions) {
      const answeredClinicalAssessmentQuestions = clearNotShownClinicalQuestions(clinicalAssessment.questions);
      await mutate({ ...clinicalAssessment, questions: answeredClinicalAssessmentQuestions });
      setStep("SUBMIT_REQUEST");
      setSkipModalOpen(false);
    }
  };

  const onTryClinicalAssessmentSubmit = async () => {
    if (incompleteRequiredQuestions(clinicalAssessment, subQuestionsVisibility).length === 0) {
      // OK, you can submit
      await submitClinicalAssessmentAndContinue();
    } else {
      setModalState("INCOMPLETE_CHECK");
      setSkipModalOpen(true);
    }
  };

  if (submitServiceRequestLoading && !showSubmissionModal && step === "SUBMIT_REQUEST") {
    setShowSubmissionModal(true);
  }

  return (
    <AppBar classes={classes} component="footer" elevation={0}>
      <Container classes={containerClasses} maxWidth="lg">
        <Grid
          container
          style={{ height: theme.spacing(10.5) }}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          {step === "CLINICAL_ASSESSMENT" && (
            <TertiaryButton
              style={{ height: theme.spacing(6), marginRight: theme.spacing(2) }}
              onClick={() => {
                setModalState("SKIP_CHECK");
                setSkipModalOpen(true);
              }}
              disabled={incompleteRequiredQuestions(clinicalAssessment, subQuestionsVisibility).length === 0}
            >
              Skip clinical assessment
            </TertiaryButton>
          )}
          <PrimaryButton
            style={{ width: theme.spacing(37), height: theme.spacing(6) }}
            onClick={() => {
              setAttemptedSubmitService(true);
              switch (step) {
                case "SAVE_AND_REVIEW":
                  if (!missingRequestor) {
                    if (isCohereFax && canBeSubmitted) {
                      setAutocompleteModalOpen(true);
                    } else {
                      saveAndReview();
                    }
                  }
                  break;
                case "CLINICAL_ASSESSMENT":
                  onTryClinicalAssessmentSubmit();
                  break;
                case "SUBMIT_REQUEST":
                  if (requestor && requestorFormValid) {
                    onFinalSubmission(requestor);
                  }
                  break;
              }
            }}
            loading={
              loading ||
              patchLoading ||
              createMedicalServiceRequestLoading ||
              serviceCaseLoading ||
              clinicalAssessmentLoading ||
              submitServiceRequestLoading
            }
            disabled={
              loading ||
              patchLoading ||
              createMedicalServiceRequestLoading ||
              serviceCaseLoading ||
              clinicalAssessmentLoading ||
              (cannotSubmit && step === "SAVE_AND_REVIEW") ||
              submitServiceRequestLoading
            }
          >
            {step === "SUBMIT_REQUEST" ? "Submit request" : "Continue"}
          </PrimaryButton>
        </Grid>
        <SubmissionModal
          open={showSubmissionModal}
          handleClose={() => {
            setShowFooter(false);
            setShowSubmissionModal(false);
          }}
          onSuccessAction={() => {
            setShowFooter(false);
            setShowSubmissionModal(false);
          }}
          onFailureAction={() => {
            if (requestor) {
              onFinalSubmission(requestor);
            }
          }}
          status={submissionStatus}
          errorDetails={submissionStatus === SubmissionStatus.Failure ? errorDetails : undefined}
        />
      </Container>
      <AutocompleteModal
        open={autocompleteModalOpen}
        onClose={() => setAutocompleteModalOpen(false)}
        onSave={saveAndReview}
        loading={createMedicalServiceRequestLoading || patchLoading}
      />
      <ClinicalSkipOrIncompleteModal
        canSkip={true}
        modalOpen={skipModalOpen}
        onClose={() => setSkipModalOpen(false)}
        modalState={modalState}
        loading={loading}
        submitAndContinue={submitClinicalAssessmentAndContinue}
      />
    </AppBar>
  );
}

function stripHTML(htmlString: string) {
  const elemStr = document.createElement("DIV");
  elemStr.innerHTML = htmlString;
  return elemStr.textContent || elemStr.innerText;
}
