import { useEffect } from "react";
import { useSnackbar } from "notistack";
import config from "api/config";
import {
  useUpdateCarePathJourneyAttachment,
  useDeleteCarePathJourneyAttachment,
  AttachmentUpdatePayload,
} from "@coherehealth/core-platform-api";

import FileUploadItem, { UploadFile } from "./FileUploadItem";

interface UploadAttachmentProps {
  file: UploadFile;
  carePathJourneyId: string;
  serviceRequestId: string;
  afterDelete: (f: UploadFile) => void;
  afterUpdate: (f: UploadFile) => void;
  canEditViewable: boolean;
  noDivider?: boolean;
}

export default function UploadCarePathJourneyAttachment({
  file,
  carePathJourneyId,
  serviceRequestId,
  afterDelete,
  afterUpdate,
  canEditViewable,
  noDivider,
}: UploadAttachmentProps) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: updateAttachmentOriginal,
    loading: updateLoading,
    error: updateError,
  } = useUpdateCarePathJourneyAttachment({
    id: carePathJourneyId,
    attachmentId: file.id,
  });
  const updateAttachment = async (fileId: string, attachmentUpdatePayload: AttachmentUpdatePayload) => {
    await updateAttachmentOriginal(attachmentUpdatePayload);
  };

  useEffect(() => {
    if (updateError) {
      enqueueSnackbar(`Failed to update attachment: ${updateError.message}`, { variant: "error" });
    }
  }, [updateError, enqueueSnackbar]);

  const {
    mutate: deleteAttachment,
    loading: deleteLoading,
    error: deleteError,
  } = useDeleteCarePathJourneyAttachment({
    id: carePathJourneyId,
  });

  useEffect(() => {
    if (deleteError) {
      enqueueSnackbar(`Failed to delete attachment: ${deleteError.message}`, { variant: "error" });
    }
  }, [deleteError, enqueueSnackbar]);

  const downloadAttachmentPath = `${config.SERVICE_API_URL}/carePathJourney/${carePathJourneyId}/attachment/${file.id}/download`;

  return FileUploadItem({
    file,
    updateAttachment,
    updateLoading,
    deleteAttachment,
    deleteLoading,
    downloadAttachmentPath,
    afterDelete,
    afterUpdate,
    canEditViewable,
    serviceRequestId,
    noDivider,
  });
}
