import React, { useCallback, useMemo, useState } from "react";

import FormGroup from "@material-ui/core/FormGroup";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { createStyles, makeStyles, styled, Theme } from "@material-ui/core/styles";
import MuiErrorIcon from "@material-ui/icons/Error";
import Divider from "@material-ui/core/Divider";

import {
  Modal,
  PrimaryButton,
  colorsLight,
  Body1,
  InlineButton,
  H2,
  Caption,
  Checkbox,
  useStableUniqueId,
  HUMANA_HEALTH_PLAN_NAME,
} from "@coherehealth/common";
import { useTrackUserInteraction, TrackUserActivityProps } from "util/userActivityTracker";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {
      padding: theme.spacing(0, 0, 1),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    modalPaper: {
      width: 612,
      height: ({ isHumanaPatient }: StyleProps) => (isHumanaPatient ? 700 : 650),
    },
    title: {
      width: "100%",
      textAlign: "center",
      paddingBottom: theme.spacing(2),
    },
    icon: {
      paddingBottom: theme.spacing(2),
    },
    details: {
      textAlign: "center",
      padding: theme.spacing(2, 0, 4),
      color: colorsLight.font.light,
    },
    alertMessage: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    list: {
      width: "100%",
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      color: colorsLight.font.main,
    },
    listPoint: {
      paddingBottom: theme.spacing(2),
    },
    goBackButton: {
      marginTop: theme.spacing(1),
      width: "75%",
    },
    continueButton: {
      width: "50%",
      marginTop: theme.spacing(2),
    },
    checkboxList: {
      paddingBottom: theme.spacing(1),
    },
    divider: {
      paddingRight: theme.spacing(32),
      paddingLeft: theme.spacing(32),
      border: `1px solid ${theme.palette.divider}`,
    },
  })
);

interface Props {
  open: boolean;
  handleClose: () => void;
  setIsExpedited(isExpedited: boolean, reason: string): void;
  patientId: string;
  serviceRequestId?: string;
  clinicalServiceId?: string;
  patientHealthPlanName?: string;
}

interface StyleProps {
  isHumanaPatient?: boolean;
}

export default function ExpeditedStatusModal({
  open,
  handleClose,
  setIsExpedited,
  patientId,
  serviceRequestId,
  clinicalServiceId,
  patientHealthPlanName,
}: Props) {
  const isHumanaPatient = patientHealthPlanName === HUMANA_HEALTH_PLAN_NAME;
  const classes = useStyles({ isHumanaPatient });
  const titleId = useStableUniqueId("expedited-status-change-modal");
  const instructionsId = useStableUniqueId("expedited-status-change-modal-description");
  const trackUserActivityInteraction = useTrackUserInteraction();
  const [selections, setSelections] = useState<string[]>([]);

  const setChecked = useCallback(
    (id: string, checked: boolean) => {
      const isCurrentlySelected = selections.includes(id);
      if (checked && !isCurrentlySelected) {
        setSelections([...selections, id]);
      }
      if (!checked && isCurrentlySelected) {
        setSelections(selections.filter((selection) => selection !== id));
      }
    },
    [selections, setSelections]
  );

  const getExpeditedReason = () => {
    const numberOfReasonsSelected = selections.length;
    if (numberOfReasonsSelected) {
      if (numberOfReasonsSelected === 1) {
        return EXPEDITE_STATUS_OPTIONS.find((reason) => reason.id === selections?.[0])?.label || "";
      } else if (numberOfReasonsSelected === 2) {
        return "Expedited - Applying standard turnaround times could seriously jeopardize the life or health of the enrollee and seriously jeopardize the enrollee's ability to regain maximum function.";
      }
    } else {
      // if there is no selection, then there shouldn't be any reason. not possible to enter this state, but just accouting for that case.
      return "";
    }
  };

  const userActivityInteractionPayload: TrackUserActivityProps = useMemo(() => {
    return {
      event: "DISPLAY_EXPEDITED_CONFIRMATION_MODAL",
      stage: "AUTH_CREATION",
      field: "EXPEDITED",
      activityContext: { patientId, serviceRequestId, clinicalServiceId },
    };
  }, [clinicalServiceId, patientId, serviceRequestId]);

  const labelId = useStableUniqueId("MultiSelectQuestion");

  const setFailedInteraction = useCallback(async () => {
    trackUserActivityInteraction({
      ...userActivityInteractionPayload,
      afterSnapshot: { isExpedited: false },
      interactionAccept: false,
    });
    handleClose();
    setIsExpedited(false, "");
    setChecked("EXPEDITED_REASON_I", false);
    setChecked("EXPEDITED_REASON_II", false);
  }, [handleClose, setIsExpedited, setChecked, trackUserActivityInteraction, userActivityInteractionPayload]);

  return (
    <Modal
      onClose={setFailedInteraction}
      aria-labelledby={titleId}
      aria-describedby={instructionsId}
      open={open}
      classes={{ paper: classes.modalPaper }}
    >
      <div className={classes.modalContent}>
        <WarningIcon className={classes.icon} />
        <H2 className={classes.title}>Please read the following before changing status to expedited:</H2>
        <ul className={classes.list}>
          <li className={classes.listPoint}>
            <StyledBody>
              If the clinical documentation does not reflect the urgency of the request, it may be flagged for review
              and changed to standard.
            </StyledBody>
          </li>
          <li>
            <StyledBody>
              Therapy and imaging requests do not typically meet criteria for expedited processing.
            </StyledBody>
          </li>
        </ul>
        <Divider className={classes.divider} />
        <StyledCaptionSecondary>
          In order to submit a request as expedited, it must meet at least one of the following criteria. If none apply,
          please submit your request as standard.
        </StyledCaptionSecondary>
        <FormGroup aria-labelledby={labelId} role="group" style={{ width: "100%" }}>
          {EXPEDITE_STATUS_OPTIONS.map(({ id, label }) => (
            <Checkbox
              key={id}
              label={label}
              checked={selections.includes(id)}
              onChange={(checked) => setChecked(id, checked)}
              className={classes.checkboxList}
            />
          ))}
        </FormGroup>
        <PrimaryButton
          className={classes.continueButton}
          onClick={() => {
            setIsExpedited(true, getExpeditedReason() || "");
            trackUserActivityInteraction({
              ...userActivityInteractionPayload,
              afterSnapshot: {
                isExpedited: true,
                expeditedReason: `${getExpeditedReason()} (${selections?.length})` || "",
              },
              interactionAccept: true,
            });
            handleClose();
          }}
          disabled={!Boolean(selections.length)}
        >
          Expedite request
        </PrimaryButton>
        <InlineButton
          className={classes.goBackButton}
          onClick={() => {
            setFailedInteraction();
          }}
        >
          Cancel
        </InlineButton>
      </div>
    </Modal>
  );
}

export const EXPEDITE_STATUS_OPTIONS: { id: string; label: string }[] = [
  {
    id: "EXPEDITED_REASON_I",
    label:
      "Expedited - Applying standard turnaround times could seriously jeopardize the life or health of the enrollee.",
  },
  {
    id: "EXPEDITED_REASON_II",
    label:
      "Expedited - Applying standard turnaround times could seriously jeopardize the enrollee’s ability to regain maximum function.",
  },
];

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledBody = styled(Body1)({
  color: colorsLight.font.main,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledCaptionSecondary = styled(Caption)(({ theme }) => ({
  color: colorsLight.font.light,
  marginTop: theme.spacing(4),
  paddingBottom: theme.spacing(1),
  width: "100%",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const WarningIcon = styled(MuiErrorIcon)(({ theme }) => ({
  color: theme.palette.warning.dark,
  width: theme.spacing(7),
  height: theme.spacing(7),
}));
