import React, { Dispatch } from "react";

import { H4, RadioGroup, colorsLight } from "@coherehealth/common";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { makeStyles } from "@material-ui/core/styles";
import { BehavioralHealthReviewType } from "@coherehealth/core-platform-api";

interface Props {
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<ServiceRequestFormContent>;
  error: boolean;
  disabled?: boolean;
}

export default function BehavioralHealthReviewTypeSelect({ formContent, setFormContent, error, disabled }: Props) {
  const classes = useStyles();
  return (
    <RadioGroup<BehavioralHealthReviewType>
      className={classes.secondaryText}
      row
      error={error}
      helperText={error && "Required"}
      disabled={disabled}
      label={<H4 className={classes.titleText}>Select behavioral health review type</H4>}
      options={[
        { id: "SUBSTANCE_ABUSE", label: "Substance abuse" },
        { id: "MENTAL_HEALTH", label: "Mental health" },
      ]}
      value={formContent?.bHReviewType}
      onChange={(val) => {
        setFormContent({ ...formContent, bHReviewType: val });
      }}
    />
  );
}

const useStyles = makeStyles({
  titleText: {
    color: colorsLight.font.main,
  },
  secondaryText: {
    color: colorsLight.font.secondary,
  },
});
