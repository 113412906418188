import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useAuthorized } from "../../authorization";
import { IntegrationExecution, useSearch, IntegrationStatus, IntegrationType } from "@coherehealth/int-obs-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";
import { DateSelect, H1, SingleSelectDropdown, useMuiContainerStyles } from "@coherehealth/common";
import HeaderContainer from "../AppHeader/HeaderContainer";
import { headerHeight } from "../../util/StyleConstants";
import { MainContent, SearchHeader } from "../ProviderOrganization/OrganizationList/ManageManyProviderOrgs";
import CircularProgress from "@material-ui/core/CircularProgress";
import IntegrationExecutionTable from "../IntegrationObservability/IntegrationExecutionTable";
import config from "api/config";

export const ObsIntegrationTypeNameMapping: Record<IntegrationType, string> = {
  APPEAL_INBOUND: "APPEAL_INBOUND",
  APPEAL_OUTBOUND: "APPEAL_OUTBOUND",
  ATTACHMENTS: "ATTACHMENTS",
  AUTHORIZATION_INBOUND: "AUTHORIZATION_INBOUND",
  AUTHORIZATION_OUTBOUND_ENRICHMENT: "AUTHORIZATION_OUTBOUND_ENRICHMENT",
  AUTHORIZATION_OUTBOUND: "AUTHORIZATION_OUTBOUND",
  CAREWEBQI: "CAREWEBQI",
  FINAL_DETERMINATION_FAX: "FINAL_DETERMINATION_FAX",
  MAIL_VENDOR_INBOUND: "MAIL_VENDOR_INBOUND",
  MAIL_VENDOR_OUTBOUND: "MAIL_VENDOR_OUTBOUND",
  OUTBOUND_NOTE_ATTACHMENT: "OUTBOUND_NOTE_ATTACHMENT",
  PROVIDER_FACILITY_OUTBOUND: "PROVIDER_FACILITY_OUTBOUND",
  REFERRALS_INBOUND: "REFERRALS_INBOUND",
  REFERRALS_OUTBOUND_ENRICHMENT: "REFERRALS_OUTBOUND_ENRICHMENT",
  REFERRALS_OUTBOUND: "REFERRALS_OUTBOUND",
  SALESFORCE: "SALESFORCE",
  VAT_INBOUND: "VAT_INBOUND",
  VAT_OUTBOUND: "VAT_OUTBOUND",
};

const IntegrationStatuses: Record<IntegrationStatus, string> = {
  PENDING: "PENDING",
  SENT: "SENT",
  FAILED: "FAILED",
  RETRIED: "RETRIED",
  ALARMED: "ALARMED",
  STAGED: "STAGED",
  READY: "READY",
  PROCESSING: "PROCESSING",
  REQUEST_ERROR: "REQUEST_ERROR",
  COMPLETE: "COMPLETE",
  WITHDRAWN: "WITHDRAWN",
  UNDELIVERABLE: "UNDELIVERABLE",
  SKIPPED: "SKIPPED",
  INTERNALLY_COMPLETE: "INTERNALLY_COMPLETE",
  AWAITING_CALLBACK: "AWAITING_CALLBACK",
  NOT_STARTED: "NOT_STARTED",
};

export default function IntegrationObservabilityPage() {
  const maxLimit: number = 100;
  const containerClasses = useMuiContainerStyles();
  const canViewPage = useAuthorized("INTEGRATION_OBSERVABILITY_PAGE");
  const { enqueueSnackbar } = useSnackbar();
  const [integrationType, setIntegrationType] = useState<string>("MATRIX");
  const [integrationStatus, setIntegrationStatus] = useState<string>("FAILED");
  const [dateMin, setDateMin] = useState<Date | null>(null);
  const [dateMax, setDateMax] = useState<Date | null>(null);
  const {
    loading: searchIntegrationExecutionLoading,
    error: searchIntegrationExecutionError,
    mutate: searchIntegrationExecution,
  } = useSearch({
    base: `${config.INT_OBS_SERVICE_API_URL}`,
  });
  const [integrationExecutions, setIntegrationExecutions] = useState<IntegrationExecution[]>();

  useEffect(() => {
    if (searchIntegrationExecutionError) {
      enqueueSnackbar("Failed to get list of integration executions from int-observability service, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [searchIntegrationExecutionError, enqueueSnackbar]);

  const handleRefetch = (integrationType: string) => {
    searchIntegrationExecution({
      integrationType: integrationType,
      status: integrationStatus,
      dateMin: dateMin?.toISOString() ?? "",
      dateMax: dateMax?.toISOString() ?? "",
      max: maxLimit.toString(),
    }).then((response) => setIntegrationExecutions(response));
  };

  useEffect(() => {
    handleRefetch(integrationType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateMax, dateMin, integrationType, integrationStatus]);

  if (!canViewPage) {
    return null;
  }

  return (
    <PageWrapper>
      <Container classes={containerClasses} maxWidth="lg">
        <HeaderContainer height={headerHeight}>
          <H1>Integrations Observability</H1>
        </HeaderContainer>
        <MainContent>
          <SearchHeader>Filter by :</SearchHeader>
          <Grid container spacing={2} justify="space-between" direction="row">
            <Grid item xs="auto">
              <SingleSelectDropdown
                label="Integration Type"
                value={integrationType}
                onChange={setIntegrationType}
                defaultValue={ObsIntegrationTypeNameMapping.AUTHORIZATION_OUTBOUND}
                style={{ width: "240px" }}
                options={Object.entries(ObsIntegrationTypeNameMapping).map(([id, label]) => ({ id, label }))}
              />
            </Grid>
            <Grid item xs="auto">
              <SingleSelectDropdown
                label="Integration Status"
                value={integrationStatus}
                onChange={setIntegrationStatus}
                defaultValue={IntegrationStatuses.FAILED}
                style={{ width: "240px" }}
                options={Object.entries(IntegrationStatuses).map(([id, label]) => ({ id, label }))}
              />
            </Grid>
            <Grid item xs="auto">
              <DateSelect label="Start Date" onDateChange={setDateMin} value={dateMin} />
            </Grid>
            <Grid item xs="auto">
              <DateSelect label="End Date" onDateChange={setDateMax} value={dateMax} />
            </Grid>
          </Grid>
          {searchIntegrationExecutionLoading ? (
            <Grid container alignItems="center" justify="center">
              <CircularProgress />
            </Grid>
          ) : (
            <IntegrationExecutionTable integrationExecutions={integrationExecutions || []} />
          )}
        </MainContent>
      </Container>
    </PageWrapper>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PageWrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(3, 7),
  height: "100%",
}));
