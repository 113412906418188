import { InformativeModal } from "@coherehealth/common";
import WarningIcon from "./WarningIcon";

interface TatConfirmationModalProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export default function TatConfirmationModal({ open, onConfirm, onCancel }: TatConfirmationModalProps) {
  return (
    <InformativeModal
      icon={<WarningIcon />}
      headerText="Are you sure you want to change turnaround time for this request?"
      subHeaderText="The fax you are adding was received earlier than other documents on this request. This will change the turnaround time of the request to this earlier date. This action cannot be reversed."
      open={open}
      primaryButtonAction={onConfirm}
      primaryButtonText="Continue"
      tertiaryButtonAction={onCancel}
      tertiaryButtonText="Go back"
      onClose={onCancel}
    />
  );
}
