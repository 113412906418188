import { useEffect, useMemo } from "react";
import { useGetReferralRequest, useGetReferralRequests } from "@coherehealth/core-platform-api";

export default function useReferralPatientSummary(patientId: string, reviewReferralRequestId?: string) {
  const maxCountPerPage = 10;

  const {
    data: referralRequestsData,
    loading: referralRequestsLoading,
    error: referralRequestsError,
    refetch: referralRequestsRefetch,
  } = useGetReferralRequests({
    lazy: true,
    queryParams: {
      patient: `eq:${patientId}`,
      sort: "lastUpdated:desc",
      max: maxCountPerPage,
    },
  });

  const {
    data: selectedReferralRequestData,
    loading: selectedReferralRequestLoading,
    error: selectedReferralRequestError,
    refetch: selectedReferralRequestRefetch,
  } = useGetReferralRequest({
    lazy: true,
    id: reviewReferralRequestId || "",
  });

  useEffect(() => {
    selectedReferralRequestRefetch();
    referralRequestsRefetch();
  }, [patientId, reviewReferralRequestId, selectedReferralRequestRefetch, referralRequestsRefetch]);

  const filteredReferralRequests = useMemo(() => {
    return !!selectedReferralRequestData?.id
      ? referralRequestsData?.filter((referral) => referral.id !== selectedReferralRequestData.id)
      : referralRequestsData;
  }, [referralRequestsData, selectedReferralRequestData?.id]);

  return {
    referralRequestsLoading,
    referralRequestsError,
    filteredReferralRequests,
    selectedReferralRequestLoading,
    selectedReferralRequestError,
    selectedReferralRequestData,
  };
}
