import React, { Dispatch } from "react";
import { OutOfScopeAuditReview } from "@coherehealth/core-platform-api";
import { useTheme } from "@material-ui/core/styles";
import ReviewHeader from "../ReviewHeader/ReviewHeader";
import { Body1, CopyToClipboard, TextField } from "@coherehealth/common";
import Grid from "@material-ui/core/Grid";
import { useSnackbar } from "notistack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface OOSEmailComponentProps {
  auditReview: OutOfScopeAuditReview;
  expanded: boolean;
  toggleExpanded?(): void;
  oosEmailSubject: string;
  setOOSEmailSubject: Dispatch<React.SetStateAction<string>>;
  oosEmailBody: string;
  setOOSEmailBody: Dispatch<React.SetStateAction<string>>;
}

const OOSEmailComponent = ({
  auditReview,
  expanded,
  toggleExpanded,
  oosEmailSubject,
  setOOSEmailSubject,
  oosEmailBody,
  setOOSEmailBody,
}: OOSEmailComponentProps) => {
  const theme = useTheme();

  return (
    <div style={{ backgroundColor: theme.palette.background.paper, paddingBottom: 8 }}>
      <ReviewHeader
        toggleExpanded={toggleExpanded}
        expanded={expanded}
        reviewDateCreated={auditReview.dateCreated}
        reviewLastUpdated={auditReview.lastUpdated}
        reviewStatus={auditReview.reviewStatus}
        reviewType={auditReview.reviewType}
        reviewCreatedByName={auditReview.createdByName}
        reviewCompletedByName={auditReview.completedByName}
        reviewDateCompleted={auditReview.dateCompleted}
        clinicalReviewView
      />
      <OOSEmailEditor
        oosEmailSubject={oosEmailSubject}
        setOOSEmailSubject={setOOSEmailSubject}
        oosEmailBody={oosEmailBody}
        setOOSEmailBody={setOOSEmailBody}
      />
    </div>
  );
};

interface OOSEmailEditorProps {
  oosEmailSubject: string;
  setOOSEmailSubject: Dispatch<React.SetStateAction<string>>;
  oosEmailBody: string;
  setOOSEmailBody: Dispatch<React.SetStateAction<string>>;
}

function OOSEmailEditor({ oosEmailSubject, setOOSEmailSubject, oosEmailBody, setOOSEmailBody }: OOSEmailEditorProps) {
  const theme = useTheme();

  return (
    <>
      <Grid container spacing={2} style={{ padding: theme.spacing(0, 2, 0, 2) }} component="form">
        <OOSEmailLabel displayText="Subject" message="email subject" copyText={oosEmailSubject} />
        <Grid item xs={12} style={{ paddingTop: "2px" }}>
          <TextField fullWidth hiddenLabel value={oosEmailSubject} onChangeValue={setOOSEmailSubject} />
        </Grid>
        <OOSEmailLabel displayText="Body of email" message="email body" copyText={oosEmailBody} />
        <Grid item xs={12} style={{ paddingTop: "2px" }}>
          <TextField
            fullWidth
            hiddenLabel
            multiline
            maxRows={33}
            value={oosEmailBody}
            onChangeValue={setOOSEmailBody}
          />
        </Grid>
      </Grid>
    </>
  );
}

interface OOSEmailLabelProps {
  displayText: string;
  message: string;
  copyText: string;
}

function OOSEmailLabel({ displayText, message, copyText }: OOSEmailLabelProps) {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Grid item container alignItems="center" style={{ paddingBottom: 0 }}>
      <div>
        <Body1>{displayText}</Body1>
      </div>
      <div>
        <CopyToClipboard
          text={copyText}
          onClick={() => {
            enqueueSnackbar(`Copied ${message} to clipboard`, { variant: "success" });
          }}
          copyIcon={<ContentCopyIcon style={{ fontSize: "18px" }} sx={{ color: "text.primary" }} />}
        />
      </div>
    </Grid>
  );
}

export default OOSEmailComponent;
