import { ServiceDeterminationQuestionOption } from "@coherehealth/core-platform-api";
import { ButtonSelectGroup } from "@coherehealth/design-system";
import { useTheme } from "@material-ui/core";
import { Dispatch, SetStateAction } from "react";

interface Props {
  initialResponse: {
    serviceOptions: ServiceDeterminationQuestionOption[] | undefined;
    categoryOptions: ServiceDeterminationQuestionOption[] | undefined;
  };
  setUpdatedClinicalServices: Dispatch<SetStateAction<string[] | undefined>>;
  selectedServiceOptionIds: string[];
  setSelectedServiceOptionIds: Dispatch<SetStateAction<string[]>>;
  selectedCategoryOptionId: string;
  setSelectedCategoryOptionId: Dispatch<SetStateAction<string>>;
}

export const SlimServiceSelect = ({
  initialResponse,
  setUpdatedClinicalServices,
  selectedServiceOptionIds,
  setSelectedServiceOptionIds,
  selectedCategoryOptionId,
  setSelectedCategoryOptionId,
}: Props) => {
  const theme = useTheme();

  return (
    <div
      style={{
        marginTop:
          initialResponse.serviceOptions || initialResponse.categoryOptions ? theme.spacing(3) : theme.spacing(1),
      }}
    >
      {initialResponse?.serviceOptions && (
        <ButtonSelectGroup
          options={
            initialResponse?.serviceOptions
              ?.sort(({ name: name0 }, { name: name1 }) => ((name0 || "") < (name1 || "") ? -1 : 1))
              ?.map(({ id, name }) => ({
                id: id || "",
                label: name || "",
                disabled: (initialResponse?.serviceOptions?.length || 0) < 2,
              })) || []
          }
          type="multiSelect"
          values={selectedServiceOptionIds}
          onChangeValues={(selectedServiceOptionIds) => {
            setSelectedServiceOptionIds(selectedServiceOptionIds);
            setUpdatedClinicalServices(selectedServiceOptionIds);
          }}
        />
      )}
      {initialResponse?.categoryOptions && (
        <ButtonSelectGroup
          options={
            initialResponse?.categoryOptions
              ?.sort(({ name: name0 }, { name: name1 }) => ((name0 || "") < (name1 || "") ? -1 : 1))
              ?.map(({ id, name }) => ({ id: id || "", label: name })) || []
          }
          type="singleSelect"
          value={selectedCategoryOptionId}
          onChangeValue={(selectedCategoryOptionId) => {
            setSelectedCategoryOptionId(selectedCategoryOptionId);
            const selectedCategoryOption = initialResponse.categoryOptions?.find(
              (co) => co.id === selectedCategoryOptionId
            );
            setUpdatedClinicalServices([selectedCategoryOption?.id || ""]);
          }}
        />
      )}
    </div>
  );
};
