import { registerRoute } from "../server";

export default function registerRoutes() {
  registerRoute({
    id: "patientSearch",
    route: "patient/search",
    method: "post", // the OPTIONS request won't be sent when Mirage is handling the route
    updateConfig: function (config, schema, request) {
      const { memberId } = JSON.parse(request.requestBody);
      if (memberId === "error500") {
        return { ...config, error: true, errorCode: 500 };
      } else if (memberId === "error404" || memberId === "error") {
        return { ...config, error: true, errorCode: 404 };
      } else if (memberId === "slow") {
        return { ...config, slow: true };
      }
      return config;
    },
    generateResponse: function (config, schema, request) {
      const { memberId } = JSON.parse(request.requestBody);
      let result = (schema as any).patients.all();

      if (memberId) {
        if (memberId === "empty") {
          result.models = [];
        } else {
          const actualResults = (schema as any).patients.where({
            memberId,
          });
          if (actualResults.models.length) {
            // To make testing easier, only return the real matches if there are some
            // Otherwise default to returning 'all' matches
            result = actualResults;
          }
        }
      }

      // Limit to 5
      //console.log("Returning", result);
      if (result.models.length > 5) {
        result.models = result.models.slice(0, 5);
      }
      return result;
    },
  });

  registerRoute({
    id: "patient",
    route: "patient/:id",
    method: "get", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      const urlParams = request.params;
      return (schema as any).patients.find(urlParams.id);
    },
  });

  registerRoute({
    id: "patientSearchWithoutId",
    route: "patient/",
    method: "get", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      return (schema as any).patients.where({})[0];
    },
  });
}
