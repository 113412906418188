import React, { ComponentProps, FunctionComponent, useCallback, useEffect, useState } from "react";

import {
  Body1,
  colorsLight,
  H2,
  PrimaryButton,
  TextField,
  DateTextField,
  DATE_FORMAT,
  formatDateToISODate,
  parseDateFromISOStringWithoutFallback,
  TertiaryButton,
  useFeature,
} from "@coherehealth/common";
import { Patient } from "@coherehealth/core-platform-api";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import MuiIconButton from "@material-ui/core/IconButton";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import isWithinInterval from "date-fns/isWithinInterval";
import parse from "date-fns/parse";
import { error as logError, stringifyError } from "logger";
import { assertIsApiError } from "util/api";

import PatientSearchResult from "../UnifiedPatientSearchModal/PatientSearchResult";
import { AuthorizationTransferModal } from "common/AuthorizationTransfer/AuthorizationTransferModal";

import { useAuthorized } from "authorization";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { useGetTemporaryMemberAuthsConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import { useFFPatientSearch } from "components/ReferralManagement/common/PatientSearchUtils";

export interface AuthorizationTransferProps {
  displayAuthorizationTransfer?: boolean;
  authorizationId?: string;
}

interface Props extends Pick<DialogProps, "open" | "onClose"> {
  authorizationTransfer?: AuthorizationTransferProps;
  healthPlanName?: string;
}

const isDobInValidRange = (dateOfBirth: string) => {
  const date = parseDateFromISOStringWithoutFallback(dateOfBirth);
  return date && isWithinInterval(date, { start: new Date(1900, 1, 1), end: new Date(2100, 1, 1) });
};

const PatientSelectionModal: FunctionComponent<Props> = (props) => {
  const { authorizationTransfer, healthPlanName, open, onClose = () => {} } = props;
  const [showResults, setShowResults] = useState<boolean>(false);
  const [patients, setPatients] = useState<Patient[]>([]);
  const [memberId, setMemberId] = useState<string>("");
  const [dobStr, setDobStr] = useState<string>("");
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [openTransferAuthModal, setOpenTransferAuthModal] = useState<boolean>(false);
  const [receiverPatientId, setReceiverPatientId] = useState<string>();
  const { data: temporaryMemberAuthsConfig, error: temporaryMemberAuthsConfigError } =
    useGetTemporaryMemberAuthsConfigurationByPayer(healthPlanName ?? "");
  // The dashboard page is not limited to a single Health Plan for some users (it depends on the
  // opsGroup) so we need validate the visibility by Payor Config in pages that are limited to
  // a single Payor and by feature flag only for the multi-payor pages.
  const temporaryMemberAuthsEnabled = temporaryMemberAuthsConfig?.enabled || !healthPlanName;
  const canAddPlaceholderMember = useAuthorized("CREATE_PLACEHOLDER_PATIENT");
  // This validates the dobStr, if it is an invalid date this value will be an empty string
  const dateOfBirth = formatDateToISODate(parse(dobStr, DATE_FORMAT, new Date()));
  const unifyPatientSearch = useFeature("unifyPatientSearch");

  const { mutate: patientSearch, loading, error } = useFFPatientSearch(unifyPatientSearch, {});

  const { enqueueSnackbar } = useSnackbar();

  const onTransferAuth = useCallback(
    (event: React.MouseEvent, receiverPatientId: string): void => {
      event.stopPropagation();
      setOpenTransferAuthModal(true);
      onClose(event, "backdropClick");
      setReceiverPatientId(receiverPatientId);
    },
    [onClose, setOpenTransferAuthModal, setReceiverPatientId]
  );

  useEffect(() => {
    if (!open) {
      setShowResults(false);
      setMemberId("");
      setDobStr("");
      setAttemptedSubmit(false);
    }
  }, [open]);

  const updateSearch = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setAttemptedSubmit(true);
    if (Boolean(memberId) && isDobInValidRange(dateOfBirth)) {
      try {
        setPatients(await patientSearch({ memberId, dateOfBirth }));
        setShowResults(true);
      } catch (e) {
        assertIsApiError(e);
        setShowResults(true);
        if (e.message !== "Failed to fetch: Aborted") {
          logError(e);
        }
      }
    }
  };

  useEffect(() => {
    if (temporaryMemberAuthsConfigError) {
      const errorMsg = "Error fetching temporaryMemberAuths configuration";
      enqueueSnackbar(errorMsg, { preventDuplicate: true });
      logError(`${errorMsg} ${stringifyError(temporaryMemberAuthsConfigError.data)}`);
    }
  }, [temporaryMemberAuthsConfigError, enqueueSnackbar]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          style: { borderRadius: "10px" },
        }}
      >
        <DialogTitle>
          <IconButton
            onClick={(event) => {
              onClose(event, "backdropClick");
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} component="form" onSubmit={updateSearch}>
            <Row data-public>
              <H2>Patient search</H2>
              <PatientLookupSubHeader>Find a patient: all information required.</PatientLookupSubHeader>
            </Row>
            <Row>
              <TextField
                error={attemptedSubmit && !memberId}
                helperText={attemptedSubmit && !memberId ? "Required" : ""}
                fullWidth
                label="Health plan member ID"
                value={memberId}
                onChangeValue={setMemberId}
                onClick={(event) => event.stopPropagation()}
              />
            </Row>
            <Row>
              <DateTextField
                error={attemptedSubmit && !isDobInValidRange(dateOfBirth)}
                helperText={attemptedSubmit && !isDobInValidRange(dateOfBirth) ? "Must have a valid date" : ""}
                fullWidth
                addDatePatternToLabel
                label={`Member date of birth`}
                value={dobStr}
                onChangeValue={setDobStr}
                onClick={(event) => event.stopPropagation()}
              />
            </Row>
            <Row data-public>
              <WideButton loading={loading} type="submit" onClick={(event) => event.stopPropagation()}>
                Search
              </WideButton>
            </Row>
            {canAddPlaceholderMember && temporaryMemberAuthsEnabled && (
              <Row>
                <TertiaryButton to="/add_new_patient" component={Link}>
                  Add patient with a temporary ID
                </TertiaryButton>
              </Row>
            )}
          </Grid>
        </DialogContent>
        {showResults && !loading ? (
          <>
            <Divider />
            <ResultContent>
              <PatientSearchResult
                authorizationTransfer={authorizationTransfer}
                error={error}
                onTransferAuth={onTransferAuth}
                patients={patients}
                showSummaryLink
              />
            </ResultContent>
          </>
        ) : null}
      </Dialog>
      {receiverPatientId && (
        <AuthorizationTransferModal
          authorizationId={authorizationTransfer?.authorizationId}
          open={openTransferAuthModal}
          receiverPatientId={receiverPatientId}
          setOpen={setOpenTransferAuthModal}
        />
      )}
    </>
  );
};

export default PatientSelectionModal;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const WideButton = styled(PrimaryButton)({
  width: "33%",
  marginTop: "16px",
  alignSelf: "center",
}) as typeof PrimaryButton;

const Row = (props: ComponentProps<typeof Grid>) => <Grid style={{ textAlign: "center" }} item xs={12} {...props} />;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DialogTitle = styled(MuiDialogTitle)({});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: `${theme.spacing(3)}px ${theme.spacing(7)}px`,
  overflow: "visible",

  // IE hack: for some reason that's hard to discern, the minWidth put on by material-ui isn't being
  // filled up by the DialogContent: explicitly putting a width is a bit of an unfortunate hack
  // and, dear future people, feel free to make this better
  width: 600,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ResultContent = styled(MuiDialogContent)(({ theme }) => ({
  background: colorsLight.background.light,
  padding: theme.spacing(3, 7),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const IconButton = styled(MuiIconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(1),
  top: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PatientLookupSubHeader = styled(Body1)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: theme.spacing(1, 0),
}));
