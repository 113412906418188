import { ChangeEvent, ComponentProps, Dispatch, SetStateAction } from "react";
import { Box, Grid } from "@material-ui/core";
import { Checkbox, TextField } from "@coherehealth/common";
import { validateAnchorAuthNumber } from "../util/ReviewSectionUtil";

interface Props {
  isReadmission: boolean;
  setIsReadmission: Dispatch<SetStateAction<boolean>>;
  anchorAuthNumber: string;
  onAnchorAuthNumberChange: Dispatch<SetStateAction<string>>;
  hasErrorWithAnchorAuth: boolean;
  setHasErrorWithAnchorAuth: Dispatch<SetStateAction<boolean>>;
  attemptedSubmitted?: boolean;
  setHasReadmissionChanged?: Dispatch<SetStateAction<boolean>>;
}

const ReadmissionSelection = ({
  isReadmission,
  setIsReadmission,
  anchorAuthNumber,
  onAnchorAuthNumberChange,
  hasErrorWithAnchorAuth,
  setHasErrorWithAnchorAuth,
  attemptedSubmitted,
  setHasReadmissionChanged,
}: Props) => {
  const handleAuthNumber = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!validateAnchorAuthNumber(event.target.value)) {
      setHasErrorWithAnchorAuth(true);
      onAnchorAuthNumberChange(event.target.value);
    } else {
      setHasErrorWithAnchorAuth(false);
      onAnchorAuthNumberChange(event.target.value);
    }
  };

  return (
    <>
      <Box component="div" style={{ paddingLeft: "5px", marginTop: "13px" }}>
        <Grid container>
          <Grid item style={{ marginTop: "7px" }}>
            <Checkbox
              label={"Readmission"}
              onChange={(event) => {
                setIsReadmission(event);
                setHasReadmissionChanged?.((hasReadmissionChanged) => !hasReadmissionChanged);
                if (event === false) {
                  onAnchorAuthNumberChange("");
                  setHasErrorWithAnchorAuth(false);
                }
              }}
              checked={isReadmission}
            />
          </Grid>
          {isReadmission && (
            <Grid item>
              <AutoShrinkLabelTextField
                fullWidth
                variant="filled"
                type="text"
                label="Anchor Auth number"
                onChange={(event) => handleAuthNumber(event)}
                value={anchorAuthNumber}
                helperText={
                  isReadmission && anchorAuthNumber === "" && attemptedSubmitted
                    ? "Required"
                    : hasErrorWithAnchorAuth && attemptedSubmitted
                    ? "Must be in format ABCD1234"
                    : ""
                }
                error={Boolean(
                  (isReadmission && anchorAuthNumber === "" && attemptedSubmitted) ||
                    (hasErrorWithAnchorAuth && attemptedSubmitted)
                )}
                data-public
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

const AutoShrinkLabelTextField = (props: ComponentProps<typeof TextField>) => (
  <TextField InputLabelProps={props.value ? { shrink: true } : {}} {...props} />
);

export default ReadmissionSelection;
