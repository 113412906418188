import { Grid } from "@mui/material";
import { BaseFilterConfig, dateFilterValue } from "./shared";
import { formatDateStr } from "../../util/DateUtils";
import { DateSelect } from "../DateSelect";

export interface DateRangeFilterConfig extends BaseFilterConfig {
  filterType: "DateRange";
  startApiParamName: string;
  endApiParamName: string;
  initialFilterValue?: unknown;
}

export interface DateRange {
  start?: Date | null;
  end?: Date | null;
}

export interface DateRangeFilterProps {
  filterConfig: DateRangeFilterConfig;
  value: DateRange | null;
  onChange?: (key: string, newState: DateRange) => void;
}

export function formatDateRange(rangeValue: DateRange | null | undefined) {
  const { start, end } = rangeValue || {};
  if (start && end) {
    return `${formatDateStr(start)} - ${formatDateStr(end)}`;
  } else if (start) {
    return `after ${formatDateStr(start)}`;
  } else if (end) {
    return `before ${formatDateStr(end)}`;
  } else {
    // Should not reach here, but the explicit if check makes
    // typescript happy
    return null;
  }
}

export function apiParams(config: DateRangeFilterConfig, value: DateRange | null) {
  const { startApiParamName, endApiParamName } = config;
  const { start, end } = value || {};
  const result: { [key: string]: string | null } = {};

  if (start && startApiParamName) {
    result[startApiParamName] = dateFilterValue(start);
  }

  if (end && endApiParamName) {
    result[endApiParamName] = dateFilterValue(end);
  }
  return result;
}

export function DateRangeFilter({ filterConfig, value, onChange }: DateRangeFilterProps) {
  const { key, label } = filterConfig;
  const dateRange: DateRange = value || {};
  const { start, end } = value || {};

  const handleChange = (prop: string, newDate: Date | null) => {
    const newRange = {
      ...dateRange,
      [prop]: newDate,
    };
    if (onChange) {
      onChange(key, newRange);
    }
  };

  const startLabel = `${label} after`;
  const endLabel = `${label} before`;

  const gridStyles = {
    marginLeft: "auto",
    marginRight: "auto",
  };

  return (
    <Grid container xs={12} sx={gridStyles}>
      <Grid item xs={6} sx={{ pr: 1 }}>
        <DateSelect
          label={startLabel}
          value={start || null}
          onDateChange={(date: Date | null) => handleChange("start", date)}
          TextFieldProps={{
            style: { width: "100%" },
          }}
        />
      </Grid>
      <Grid item xs={6} sx={{ pl: 1 }}>
        <DateSelect
          label={endLabel}
          value={end || null}
          onDateChange={(date: Date | null) => handleChange("end", date)}
          TextFieldProps={{
            style: { width: "100%" },
          }}
        />
      </Grid>
    </Grid>
  );
}
