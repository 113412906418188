import { NotFoundPage, useFeature } from "@coherehealth/common";
import { useMatch } from "react-router";
import routes from "routes";
import FaxAttachmentPageContent from "../FaxAttachmentPageContent";

export default function FaxEditServiceRequestPage() {
  const match = useMatch(routes.EDIT_SR_SIDE_BY_SIDE);

  const sideBySideEditSrFaxIntakeWorkflow = useFeature("sideBySideEditSrFaxIntakeWorkflow");
  if (!sideBySideEditSrFaxIntakeWorkflow) {
    return <NotFoundPage />;
  }
  return <FaxAttachmentPageContent match={match} />;
}
