import { AuthStatus, FacilityCategory, PatientStatus } from "@coherehealth/core-platform-api";
import { capitalize, lowerCase } from "lodash";
import { isNegationAuthStatus } from "util/serviceRequest";

export interface NRDVisibilityCheck {
  encounterType: FacilityCategory | undefined;
  authStatus: AuthStatus | undefined;
  patientStatus: PatientStatus | undefined;
}

export const shouldShowNextReviewDate = ({ encounterType, authStatus, patientStatus }: NRDVisibilityCheck) => {
  if (encounterType !== "INPATIENT") {
    return false;
  }
  if (isNegationAuthStatus(authStatus)) {
    return false;
  }
  if (patientStatus === "DISCHARGED" && authStatus !== "PENDING") {
    return false;
  }
  return true;
};

export const toSentenceCase = (str: string | undefined) => {
  if (!str) {
    return str;
  }
  return capitalize(lowerCase(str));
};
