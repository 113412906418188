import React, { Dispatch, FunctionComponent, SetStateAction } from "react";
import FileUploadIcon from "../images/FileUploadIcon";
import { FeatureFlagStatus } from "../FeatureFlag";
import { RadioGroup } from "../RadioGroup";
import { InlineButton } from "../InlineButton";
import { Body2 } from "../Typography";
import { makeStyles } from "@material-ui/core";
import { useStableUniqueId } from "../../hooks";
import { colorsLight, colorsDark } from "../../themes/colors";

const useStyles = makeStyles((theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  return {
    header: { width: "100%", textAlign: "left" },
    CsvFileName: {
      color: isDarkTheme ? colorsDark.font.secondary : colorsLight.font.secondary,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      ...theme.typography.caption,
    },
  };
});

interface Props {
  selectedStatus: FeatureFlagStatus;
  setSelectedStatus: Dispatch<SetStateAction<FeatureFlagStatus>>;
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  allowedFileExtensions: string[];
  csvName?: string;
  setIsSaveEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const FeatureFlagStatusDisplay: FunctionComponent<Props> = ({
  selectedStatus,
  setSelectedStatus,
  onFileChange,
  allowedFileExtensions,
  csvName,
  setIsSaveEnabled,
}) => {
  const id = useStableUniqueId();
  const classes = useStyles();
  return (
    <div>
      <div className={classes.header}>
        <Body2>Overall status</Body2>
      </div>
      <div style={{ display: "flex" }}>
        <RadioGroup
          style={{ minWidth: "0" }}
          row
          options={[
            { id: "ON", label: "On" },
            { id: "OFF", label: "Off" },
            { id: "LIMITED", label: "Limited" },
          ]}
          value={selectedStatus}
          onChange={(e) => {
            setIsSaveEnabled(true);
            setSelectedStatus(e);
          }}
        />
        {selectedStatus === "LIMITED" && (
          <>
            <input
              hidden
              id={id}
              name={id}
              type="file"
              accept={allowedFileExtensions.join(", ")}
              onChange={onFileChange}
            />
            <label htmlFor={id}>
              <InlineButton
                component="span"
                onClick={() => {
                  setIsSaveEnabled(true);
                }}
                startIcon={<FileUploadIcon />}
              >
                Upload CSV
              </InlineButton>
            </label>
          </>
        )}
      </div>
      <div>{csvName && selectedStatus === "LIMITED" && <div className={classes.CsvFileName}>{csvName}</div>}</div>
    </div>
  );
};

export default FeatureFlagStatusDisplay;
