import { colorsLight, today, formatDateStr } from "@coherehealth/common";
import { Patient, Coverage, ProcedureCode, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";

import { CohereServiceRequestPDFHeader, CohereDisclaimerFooter, TextField } from "util/PDFUtils";
import { getHealthPlanContactInfo } from "../../../../contact";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";

interface Props {
  requestContent?: ServiceRequestFormContent | ServiceRequestResponse | undefined;
  patient?: Patient;
  nonPalProcedureCodes: ProcedureCode[];
  coverage?: Coverage;
  nonPalSubmissionEnabled?: boolean;
  showInpatientSection?: boolean | undefined;
}

const NON_APPLICABLE = "n/a";

/**
 * Renders a pdf with @react-pdf package https://react-pdf.org/components
 * For some reason the logo image does not get imported with storybook, but works on the actual package.
 * @constructor
 */
export default function NonPalPDF({
  requestContent,
  patient,
  nonPalProcedureCodes,
  coverage,
  showInpatientSection,
}: Props) {
  const patientDOB = formatDateStr(patient?.dateOfBirth);
  const patientSex = patient?.gender || NON_APPLICABLE;
  const payer = coverage?.healthPlanName || NON_APPLICABLE;
  const patientMembershipType = coverage?.coverageLineOfBusinessType || coverage?.lineOfBusinessType || NON_APPLICABLE;
  const patientPlanType = coverage?.planType || NON_APPLICABLE;
  const patientPlanStartDate = coverage?.planStart || undefined;
  const patientPlanEndDate = coverage?.planEnd || undefined;
  const patientPlanDateRange =
    patientPlanStartDate && patientPlanEndDate
      ? [formatDateStr(patientPlanStartDate), formatDateStr(patientPlanEndDate)].join(" - ")
      : NON_APPLICABLE;
  const patientGroupId = coverage?.groupId || NON_APPLICABLE;

  const todayDateDisplay = formatDateStr(today().toISOString());

  const { email, phone, contactName } = getHealthPlanContactInfo(coverage?.healthPlanName);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <CohereServiceRequestPDFHeader phone={phone} contactName={contactName} email={email} />
        <View style={styles.headerSection}>
          <Text style={styles.heading}>The following care doesn’t require submission in most cases</Text>
          <Text style={[styles.label, { paddingTop: 2 }]}>
            {`All services are subject to members benefits, exclusions, limitations, and other applicable conditions.`}
          </Text>
          <Text style={[styles.label, { paddingTop: 8 }]}>Created on {todayDateDisplay}</Text>
        </View>
        <View style={[styles.bodySection, { flexDirection: "row" }]}>
          <View style={[styles.column, { paddingRight: 12 }]}>
            <TextField label="Sex" value={patientSex} />
            <TextField label="DOB" value={patientDOB} />
          </View>
          <View style={[styles.column, { paddingLeft: 12 }]}>
            <TextField label="Payer" value={payer} />
            <TextField label="Membership type" value={patientMembershipType} />
          </View>
          <View style={[styles.column, { paddingLeft: 12 }]}>
            <TextField label="Plan Type" value={patientPlanType} />
            <TextField label="Plan Year" value={patientPlanDateRange} />
          </View>
          <View style={[styles.column, { paddingLeft: 12 }]}>
            <TextField label="Group ID" value={patientGroupId} />
          </View>
        </View>
        <View style={styles.bodySectionBorderless}>
          <Text style={styles.subheading}>Service info</Text>
          {nonPalProcedureCodes && (
            <>
              {nonPalProcedureCodes.map((px: ProcedureCode | null) => {
                return (
                  <View style={styles.bodySection}>
                    <Text style={styles.category}>{px?.code}</Text>
                    <Text style={styles.body}>{px?.description}</Text>
                  </View>
                );
              })}
              {showInpatientSection && requestContent && (
                <View style={styles.bodySectionBorderless}>
                  <Text style={styles.subheading}>Inpatient stay</Text>
                  <View style={styles.bodySection}>
                    <Text style={styles.category}>
                      {requestContent.authSubcategory
                        ? requestContent.authSubcategory
                        : requestContent.authCategory?.name}
                    </Text>
                  </View>
                </View>
              )}
            </>
          )}
        </View>
        <CohereDisclaimerFooter />
      </Page>
    </Document>
  );
}

/**
 * Styles used for the pdf.
 * The numeric values are in "pt" units, (not "px").
 * This is pretty similar to css, but there are some quirks, see https://react-pdf.org/styling
 */
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    padding: 56,
  },
  headerSection: {
    paddingBottom: 24,
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: colorsLight.gray.divider,
  },
  bodySection: {
    width: "100%",
    padding: "24pt 0",
    borderBottomWidth: 1,
    borderBottomColor: colorsLight.gray.divider,
  },
  bodySectionBorderless: {
    width: "100%",
    padding: "24pt 0",
  },
  column: {
    flexDirection: "column",
    display: "flex",
    width: "25%",
  },
  textField: {
    paddingBottom: 12,
  },
  heading: {
    fontSize: 12,
    fontWeight: 600,
  },
  subheading: {
    fontSize: 12,
    fontWeight: 500,
  },
  category: {
    fontSize: 11,
    fontWeight: 600,
    paddingBottom: 12,
  },
  label: {
    color: colorsLight.font.secondary,
    fontSize: 10,
    paddingBottom: 4,
  },
  body: {
    color: colorsLight.font.main,
    fontWeight: 600,
    fontSize: 10,
  },
});
