import React from "react";

import "./SearchFields.css";
import SearchByTrackingNumber from "./SearchByTrackingNumber";
import SearchByMemberInfo from "./SearchByMemberInfo";
import { StatusCheckResponse } from "./checkStatusApi";

interface Props {
  setStatusResults: (results: StatusCheckResponse | null) => void;
  onSearchClick: () => void;
}

export default function SearchFields({ setStatusResults, onSearchClick }: Props) {
  return (
    <div className="searchFieldsContainer">
      <div className="searchFieldSectionLeft">
        <h2 className="searchFieldHeader">Search by Cohere tracking number</h2>
        <SearchByTrackingNumber setStatusResults={setStatusResults} onSearchClick={onSearchClick} />
      </div>
      <div className="searchFieldSectionRight">
        <h2 className="searchFieldHeader">Search by member information</h2>
        <SearchByMemberInfo setStatusResults={setStatusResults} onSearchClick={onSearchClick} />
      </div>
    </div>
  );
}
