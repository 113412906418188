import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { Grid, useTheme } from "@material-ui/core";
import {
  Caption,
  InlineButton,
  PrimaryButton,
  InformativeModal,
  responsiveFooterMaxWidthMdValue,
} from "@coherehealth/common";
import { trackUserActivity } from "../../util/userActivityTracker";
import BackButton from "./BackButton";
import { NavigationContext } from "./NavigationContext";
import { FaxAttachmentContext } from "components/DocumentViewer/FaxAttachment/FaxAttachmentContext";
import { faxAttachmentPath } from "./common";
import useDeleteDraftSrAndRedirect from "hooks/useDeleteDraftSrAndRedirect";
import { useSnackbar } from "notistack";
import useResponsiveMdWidth from "hooks/useResponsiveMdWidth";
import useChangeFooterHeightIfError from "hooks/useChangeFooterHeightIfError";
import useFaxFooterStyles from "hooks/useFaxFooterStyles";
import ClipboardMissingInfo from "components/images/ClipboardMissingInfo";

interface Props {
  onPrimaryButtonClick: () => void;
  showError?: boolean;
  errorCaptionText?: string;
  primaryButtonDisabled: boolean;
  primaryButtonLoading?: boolean;
  primaryButtonText: string;
  dataPendoHealthPlan?: string;
  setFooterHeight: Dispatch<SetStateAction<number>>;
  isContinuationWorkflow?: boolean;
}

const FaxAuthBuilderFooter = ({
  primaryButtonDisabled,
  onPrimaryButtonClick,
  primaryButtonLoading,
  primaryButtonText,
  errorCaptionText,
  showError,
  dataPendoHealthPlan,
  setFooterHeight,
  isContinuationWorkflow,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    workflowStep,
    authFlowType,
    draftServiceRequest,
    navigateBack,
    navigateBackContinuations,
    isFaxAuthBuilderFlow,
  } = useContext(NavigationContext);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const { faxDbId } = useContext(FaxAttachmentContext);
  const classes = useFaxFooterStyles();
  const theme = useTheme();

  const elementRef = useRef<HTMLDivElement>(null);
  const { responsiveMdWidth } = useResponsiveMdWidth(elementRef, responsiveFooterMaxWidthMdValue);

  const containerClass = responsiveMdWidth ? classes.fullSizeFooter : classes.wrappedFooter;

  const primaryButtonFullWidth = responsiveMdWidth;

  const returnToUrl = faxAttachmentPath(faxDbId);

  useChangeFooterHeightIfError(elementRef, setFooterHeight, responsiveMdWidth, showError);

  const {
    deleteServiceRequestLoading,
    deleteError,
    deleteAttachmentLoading,
    deleteAttachmentError,
    deleteAndNavigateCallback,
  } = useDeleteDraftSrAndRedirect(returnToUrl, draftServiceRequest?.id || "", draftServiceRequest?.attachments);

  const deletionInProgress = deleteServiceRequestLoading || deleteAttachmentLoading;
  const hideBackButton = workflowStep === "GATHER_REQUIREMENTS_MEDICAL" || workflowStep === "FILL_FORMS_CONTINUATION";

  useEffect(() => {
    if (deleteError || deleteAttachmentError) {
      const error = deleteError ?? deleteAttachmentError;
      enqueueSnackbar(`Error deleting this service request: ${error?.message}`);
    }
  }, [deleteAttachmentError, deleteError, enqueueSnackbar]);

  return (
    <Grid container className={containerClass} alignItems={"center"} ref={elementRef}>
      <Grid
        item
        xs={responsiveMdWidth ? 2 : 12}
        container
        spacing={4}
        alignItems={"flex-start"}
        justifyContent={responsiveMdWidth ? "flex-start" : "center"}
      >
        <Grid item style={responsiveMdWidth ? undefined : { marginTop: 16 }}>
          <InlineButton
            data-tracking-id="cancel-fax-auth-builder"
            onClick={() => setCancelModalOpen(true)}
            disabled={deletionInProgress}
            loading={deletionInProgress}
            className={classes.cancelButton}
            warning
          >
            Cancel
          </InlineButton>
        </Grid>
      </Grid>
      <Grid item xs>
        <Grid
          container
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          style={isFaxAuthBuilderFlow ? { flexWrap: "nowrap", flexDirection: "column" } : undefined}
        >
          {showError && (
            <Grid item className={classes.secondaryContainer} style={{ paddingLeft: 118, alignSelf: "flex-start" }}>
              <Caption className={classes.caption} data-public>
                {errorCaptionText}
              </Caption>
            </Grid>
          )}
          <Grid
            container
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
            style={{ display: "flex", flexDirection: "row", paddingTop: showError ? 16 : 0 }}
          >
            {!hideBackButton && (
              <Grid item className={classes.secondaryContainer} style={{ paddingRight: 24 }}>
                <BackButton
                  workflowStep={workflowStep}
                  authFlowType={authFlowType}
                  draftServiceRequest={draftServiceRequest}
                  navigateBack={navigateBack}
                  navigateBackContinuations={navigateBackContinuations}
                  isFaxAuthBuilderFlow={isFaxAuthBuilderFlow}
                  disabled={deletionInProgress}
                />
              </Grid>
            )}
            <Grid item className={primaryButtonFullWidth ? classes.primaryContainer : undefined}>
              <PrimaryButton
                disabled={primaryButtonDisabled || deletionInProgress}
                fullWidth={primaryButtonFullWidth}
                onClick={() => {
                  dataPendoHealthPlan
                    ? trackUserActivity(dataPendoHealthPlan + "-patient")
                    : trackUserActivity(dataPendoHealthPlan + "-noHealthPlan");
                  onPrimaryButtonClick();
                }}
                loading={primaryButtonLoading}
                data-pendo-health-plan={dataPendoHealthPlan || ""}
                data-public
                className={primaryButtonFullWidth ? undefined : classes.primaryWrappedButton}
              >
                {primaryButtonText}
              </PrimaryButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <InformativeModal
        open={cancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        icon={<ClipboardMissingInfo style={{ color: theme.palette.warning.dark }} />}
        headerText={
          isContinuationWorkflow
            ? "Are you sure you want to cancel this continuation request?"
            : "Are you sure you want to cancel this authorization?"
        }
        subHeaderText="Your progress will not be saved."
        primaryButtonText="Yes, cancel"
        primaryButtonAction={deleteAndNavigateCallback}
        tertiaryButtonText={
          isContinuationWorkflow ? "No, return to continuation request" : "No, return to authorization"
        }
        tertiaryButtonAction={() => setCancelModalOpen(false)}
        showDivider
      />
    </Grid>
  );
};

export default FaxAuthBuilderFooter;
