import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { Box, BoxProps, Button, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import remove from "lodash/remove";
import { Caption } from "../Typography";
import { Tooltip } from "../Tooltip";

interface ButtonSelectGroupOption {
  id: string;
  label: ReactNode;
  disabled?: boolean;
}

interface ButtonSelectGroupOptionWrapper extends ButtonSelectGroupOption {
  selected: boolean;
  type: string;
  onOptionClick: any;
  styleClass: string;
}

export interface ButtonSelectGroupSingleSelectProps extends BoxProps {
  options: ButtonSelectGroupOption[];
  type: "singleSelect";
  value: string;
  values?: never;
  onChangeValue: (newValue: string) => void;
  onChangeValues?: never;
}

export interface ButtonSelectGroupMultiSelectProps extends BoxProps {
  options: ButtonSelectGroupOption[];
  type: "multiSelect";
  value?: never;
  values: string[];
  onChangeValue?: never;
  onChangeValues: (newValues: string[]) => void;
}

type ButtonSelectGroupProps = ButtonSelectGroupSingleSelectProps | ButtonSelectGroupMultiSelectProps;

// This type allows a caller to construct an object that might be either one of those types more generically
export type ButtonSelectGroupUnionProps = { [P in keyof ButtonSelectGroupProps]: ButtonSelectGroupProps[P] };
function isButtonSelectGroupSingleSelectProps(
  ipt: ButtonSelectGroupUnionProps
): ipt is ButtonSelectGroupSingleSelectProps {
  return ipt.type === "singleSelect";
}

function isButtonSelectGroupMultiSelectProps(
  ipt: ButtonSelectGroupUnionProps
): ipt is ButtonSelectGroupMultiSelectProps {
  return ipt.type === "multiSelect";
}

const useStyles = makeStyles((theme) => ({
  optionButton: {
    "& .MuiButton-root": {
      padding: theme.spacing(2, 3),
      borderRadius: 8,
      color: theme.palette.text.primary,
      border: "none",
      boxShadow: `inset 0 0 0 1px ${theme.palette.divider}`,
      "&.selected": {
        boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main}`,
        "&.Mui-disabled": {
          boxShadow: `inset 0 0 0 2px ${theme.palette.divider}`,
          backgroundColor: theme.palette.grey[100],
          color: theme.palette.primary.main,
        },
      },
      "&:hover, &:focus": {
        boxShadow: `inset 0 0 0 1px ${theme.palette.divider}`,
        "&.selected": {
          boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main}`,
        },
      },
      "& .MuiTypography-root": {
        color: theme.palette.text.primary,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontWeight: 600,
        fontSize: theme.spacing(2),
        lineHeight: "20px",
        letterSpacing: "0.25px",
      },
    },
  },
}));

export function ButtonSelectGroup(props: ButtonSelectGroupSingleSelectProps): ReactNode;
export function ButtonSelectGroup(props: ButtonSelectGroupMultiSelectProps): ReactNode;
export default function ButtonSelectGroup(props: ButtonSelectGroupUnionProps) {
  const { options, type, value, values, onChangeValue, onChangeValues, ...boxProps } = props;
  const styleClasses = useStyles();
  const onOptionClick = (id: string) => {
    if (isButtonSelectGroupSingleSelectProps(props)) {
      props.onChangeValue(props.value === id ? "" : id);
    } else if (isButtonSelectGroupMultiSelectProps(props)) {
      props.onChangeValues(
        props.values.includes(id) ? remove(props.values, (elem) => elem !== id) : [...props.values, id]
      );
    }
  };

  return (
    <Box display="flex" flexDirection="column" {...boxProps}>
      {props.options.some(({ disabled }) => !disabled) && (
        <Caption color="font.secondary" pb={1} data-public>
          {props.type === "singleSelect" ? "Select one" : "Select all that apply"}
        </Caption>
      )}
      <Box display="flex" flexWrap="wrap" m={-1} role={props.type === "singleSelect" ? "radioGroup" : undefined}>
        {props.options.map(({ id, label, disabled }) => {
          const selected = props.type === "singleSelect" ? id === props.value : props.values?.includes(id);
          return (
            <Box p={1} key={id} style={{ maxWidth: "272px" }}>
              <OptionButtonWrapper
                id={id}
                selected={selected ?? false}
                type={props.type}
                onOptionClick={onOptionClick}
                disabled={disabled}
                label={label}
                styleClass={styleClasses.optionButton}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export const OptionButtonWrapper = ({
  id,
  selected,
  type,
  onOptionClick,
  disabled,
  label,
  styleClass,
}: ButtonSelectGroupOptionWrapper) => {
  const textElementRef = useRef<HTMLInputElement | null>(null);
  const { hoverStatus } = useIsOverflow(textElementRef);
  return (
    <Tooltip title={label || ""} placement="top" disableHoverListener={!hoverStatus}>
      <span className={styleClass}>
        <Button
          fullWidth
          className={selected ? "selected " : ""}
          role={type === "singleSelect" ? "radio" : "checkbox"}
          aria-checked={selected}
          onClick={() => onOptionClick(id)}
          color="neutral"
          disabled={disabled}
          data-public
        >
          <Typography ref={textElementRef}> {label}</Typography>
        </Button>
      </span>
    </Tooltip>
  );
};

function useIsOverflow(textElementRef: React.MutableRefObject<HTMLInputElement | null>) {
  const [hoverStatus, setHover] = useState<boolean>(false);

  const compareSize = useCallback(() => {
    const element = textElementRef.current;
    const compare = element
      ? element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight
      : false;
    setHover(compare);
  }, [textElementRef]);

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
  }, [compareSize]);

  // remove resize listener again on "componentWillUnmount"
  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize);
    },
    [compareSize]
  );

  return {
    hoverStatus,
  };
}
