import React from "react";
import { makeStyles } from "@material-ui/core";
import { Alert, InlineButton, useConfiguration } from "@coherehealth/common";
import { ReferralRequestResponse, ReferralRequestSearchResponse } from "@coherehealth/core-platform-api";

interface Props {
  referralRequest: ReferralRequestSearchResponse | ReferralRequestResponse;
}

const useStyles = makeStyles((theme) => ({
  refreshDetailStyle: {
    padding: theme.spacing(0),
    lineHeight: theme.spacing(2, 0),
    marginBottom: theme.spacing(0.25),
  },
  paddedAlert: {
    userSelect: "text",
    flex: 1,
    textAlign: "left",
    padding: theme.spacing(1, 2),
    borderRadius: `${theme.spacing(0.6)}px !important`,
    whiteSpace: "pre-line",
  },
}));

export default function ReferralRequestStatusDisplay({ referralRequest }: Props) {
  const refreshPage = (e: any) => {
    e.stopPropagation();
    window.location.reload();
  };
  const classes = useStyles();
  const { referralStatus, healthPlanDisplayName, healthPlanName, cohereId, isAdministrative } = referralRequest;
  const authNumber = "authNumber" in referralRequest ? referralRequest.authNumber : undefined;
  const trackingInfo = authNumber ? (
    <div>
      Referral authorization #{authNumber} • Tracking #{cohereId || "UNKNOWN"}
    </div>
  ) : (
    <div>
      Tracking #{cohereId || "UNKNOWN"} • Please check back later for the auth number or
      <InlineButton component="span" className={classes.refreshDetailStyle} onClick={refreshPage}>
        refresh
      </InlineButton>
      the page now.
    </div>
  );
  const requestStatusConfiguration = useConfiguration("requestStatusConfiguration", healthPlanName) ?? {};
  let message = "Status Unknown";
  let severity: "error" | "warning" | undefined;

  switch (referralStatus) {
    case "APPROVED":
      message = isAdministrative ? "Approved administrative referral" : "Covered";
      break;
    case "DENIED":
      message = "Non-covered";
      severity = "error";
      break;
    case "PENDING":
      const healthPlan =
        healthPlanDisplayName && requestStatusConfiguration?.pendingWithDisplayHealthPlan
          ? healthPlanDisplayName
          : healthPlanName;
      message = `Pending review by ${healthPlan}`;
      severity = "warning";
      break;
  }

  return (
    <Alert message={message} className={classes.paddedAlert} severity={severity}>
      {trackingInfo}
    </Alert>
  );
}
