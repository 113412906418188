import React from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { createStyles, styled } from "@material-ui/core/styles";
import { Body1, InformativeModal } from "@coherehealth/common";
import MuiErrorIcon from "@material-ui/icons/Error";

import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      color: theme.palette.text.secondary,
      textAlign: "center",
    },
  })
);

interface Props {
  open: boolean;
  handleClose: (expedited: boolean) => void;
}

export default function PharmacyExpeditedStatusModal({ open, handleClose }: Props) {
  const classes = useStyles();
  return (
    <InformativeModal
      open={open}
      onClose={handleClose}
      headerText={"Is this an urgent request?"}
      subHeaderElement={
        <Body1 className={classes.message}>
          An urgent request is defined as:
          <br />
          <br />
          When the physician believes that waiting for a decision under the standard time frame could place the
          enrollee's life, health, or ability to regain maximum function in serious jeopardy.
        </Body1>
      }
      primaryButtonText={"Yes, expedite this request"}
      primaryButtonAction={() => {
        handleClose(true);
      }}
      tertiaryButtonText={"Cancel"}
      tertiaryButtonAction={() => {
        handleClose(false);
      }}
      icon={<WarningIcon />}
    />
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const WarningIcon = styled(MuiErrorIcon)(({ theme }) => ({
  color: theme.palette.warning.dark,
  maxWidth: "48px",
  maxHeight: "48px",
}));
