import React, { SVGProps } from "react";

export default function PersonIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="56" height="56" rx="8" fill="black" opacity="0.04" />
      <path
        d="M28 27.9998C31.6833 27.9998 34.6667 25.0165 34.6667 21.3332C34.6667 17.6498 31.6833 14.6665 28 14.6665C24.3167 14.6665 21.3333 17.6498 21.3333 21.3332C21.3333 25.0165 24.3167 27.9998 28 27.9998ZM28 31.3332C23.55 31.3332 14.6667 33.5665 14.6667 37.9998V41.3332H41.3333V37.9998C41.3333 33.5665 32.45 31.3332 28 31.3332Z"
        fill="#5E6572"
      />
    </svg>
  );
}
