import { Body2 } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core";

interface TemporaryPatientChipProps {
  temporaryMemberId: string;
}

export function TemporaryPatientChip(props: TemporaryPatientChipProps): JSX.Element {
  const { temporaryMemberId } = props;
  const classes = useStyles();
  return (
    <Body2 className={classes.temporaryPatientChip} data-testid="temporaryPatientChip">
      This member’s ID was auto-updated from the temporary ID: {temporaryMemberId || "--"}
    </Body2>
  );
}

const useStyles = makeStyles((theme) => ({
  temporaryPatientChip: {
    padding: "4px 8px",
    backgroundColor: "#F5F5F5",
    color: "#5E6572",
    borderRadius: 4,
    fontFamily: "Gilroy-Regular",
    fontSize: 13,
    fontWeight: 400,
    lineHeight: 15 / 13,
    letterSpacing: 0.15,
  },
}));
