import React, { FunctionComponent, ComponentProps, useState, useEffect, useRef, useCallback } from "react";
import {
  Provider,
  Facility,
  PracticeUpdatePayload,
  Address,
  PracticeCreatePayload,
  Location,
  PhoneNumber,
  useGetPostgridAddressVerification,
  PostgridAddressVerificationResponse,
  PostgridAddressVerificationResponseData,
  PostgridAddressVerificationBody,
  ProviderFacilityCatalogue,
  useGetProvidersOrFacilities,
  GetProvidersOrFacilitiesQueryParams,
} from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { TextField, PrimaryButton, colorsLight, H2, Checkbox, useFeature } from "@coherehealth/common";
import { ProviderFacilityModalContent } from "./ProviderFacilityModalManual";
import { DialogProps, Divider } from "@material-ui/core";
import PhoneInput from "common/PhoneInput";
import UnitedStatesStateSelection from "../ProviderOrganization/UnitedStatesStateSelection";
import MuiDialogContent from "@material-ui/core/DialogContent";
import PostGridAddressSearchResult from "./PostGridAddressSearchResult";
import { useSnackbar } from "notistack";
import { ModalStatus } from "util/providerUtils";
import CloseIcon from "@material-ui/icons/Close";
import MuiIconButton from "@material-ui/core/IconButton";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { Body1 } from "@coherehealth/design-system";
import { useAuthorized } from "authorization";

interface Props extends Pick<DialogProps, "onClose"> {
  practice: Provider | Facility | null;
  setPractice: (practice: Provider | Facility | null) => void;
  isProvider: boolean;
  setSelection?: (params: PracticeCreatePayload, practice: Provider | Facility) => Promise<void>;
  setIsEditable: (b: boolean) => void;
  onSave?(payload: PracticeCreatePayload, setEntryId: (practice: Provider | Facility | null) => void): Promise<any>;
  modalStatus: ModalStatus;
  patientHealthPlan?: string;
  selectedTin?: string;
  selectedLocation?: Location | null;
  hideTinField?: boolean;
  isPerfomingProviderPractice?: boolean;
}

const removeAndAddAddress = (
  selectedAddressValue: Location | null | undefined,
  practice: Provider | Facility | null | undefined,
  userEnteredAddress: Address
): Address[] => {
  if (!practice?.addresses) {
    return [userEnteredAddress];
  }

  if (!selectedAddressValue) {
    return [...practice.addresses, userEnteredAddress];
  }
  const addresses = practice.addresses.slice(); // Create a copy of the original array

  const addressToCompareTo = selectedAddressValue.address;
  const indexOfMatchingAddress = addresses.findIndex(
    (address) =>
      address.line1 === addressToCompareTo?.line1 &&
      address.line2 === addressToCompareTo?.line2 &&
      address.state === addressToCompareTo?.state &&
      address.city === addressToCompareTo?.city &&
      address.zipCode === addressToCompareTo?.zipCode
  );

  if (indexOfMatchingAddress !== -1) {
    addresses.splice(indexOfMatchingAddress, 1);
  }

  addresses.push(userEnteredAddress);

  return addresses;
};

const updateTinList = (
  practice: Provider | Facility | null,
  selectedTinValue: string | undefined,
  userEnteredTin: string | undefined
): string[] => {
  let tinList: string[] = [];

  if (practice?.tinList && practice.tinList.length >= 1 && selectedTinValue) {
    const indexOfMatchingTin = practice.tinList.indexOf(selectedTinValue);
    if (indexOfMatchingTin !== -1) {
      practice.tinList.splice(indexOfMatchingTin, 1);
    }
    if (userEnteredTin) {
      tinList = [...practice.tinList, userEnteredTin];
    } else {
      tinList = [...practice.tinList];
    }
  } else {
    if (userEnteredTin) {
      tinList = [userEnteredTin];
    }
  }

  return tinList;
};

const addPhoneNumberIfNotDuplicate = (
  practice: Provider | Facility | null,
  contactNumber: PhoneNumber[] | undefined
): PhoneNumber[] | undefined => {
  if (!contactNumber) {
    return practice?.phoneNumbers;
  }

  if (!practice?.phoneNumbers) {
    return contactNumber;
  }

  const indexOfMatchingPhoneNumber = practice.phoneNumbers.findIndex(
    (phoneNumber) =>
      phoneNumber.number === contactNumber[0].number && phoneNumber.extension === contactNumber[0].extension
  );

  if (indexOfMatchingPhoneNumber === -1) {
    return [contactNumber[0], ...practice.phoneNumbers]; // If no duplicate found, return a new array with the additional phone number
  }

  return practice.phoneNumbers; // If a duplicate is found, return the original phone numbers array
};

const addFaxNumberIfNotDuplicate = (
  practice: Provider | Facility | null,
  faxNumber: string[] | undefined
): string[] | undefined => {
  if (!faxNumber) {
    return practice?.selectedLocation?.faxNumbers;
  }

  if (!practice?.selectedLocation?.faxNumbers) {
    return faxNumber;
  }

  const indexOfMatchingFaxNumber = practice.selectedLocation.faxNumbers.indexOf(faxNumber[0]);

  if (indexOfMatchingFaxNumber === -1) {
    return [faxNumber[0], ...practice.selectedLocation.faxNumbers]; // If no duplicate found, return a new array with the additional fax number
  }

  return practice.selectedLocation.faxNumbers; // If a duplicate is found, return the original fax numbers array
};

const ProviderFacilityEdit: FunctionComponent<Props> = ({
  practice,
  setPractice,
  isProvider,
  isPerfomingProviderPractice,
  setSelection,
  setIsEditable,
  onSave,
  modalStatus,
  patientHealthPlan,
  onClose,
  selectedTin,
  selectedLocation,
  hideTinField = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [showAddressResults, setShowAddressResults] = useState<boolean>(false);
  const isSummary = modalStatus === ModalStatus.Summary;
  const firstGridRef = useRef<HTMLDivElement>(null); //for scrolling purposes
  const prefillModalContent = convertEntryToContent(practice, isProvider, selectedTin, selectedLocation, modalStatus);
  const [postGridAddress, setPostGridAddress] = useState<PostgridAddressVerificationResponseData | null>(null);
  const [isLoadingSuggestedAddress, setIsLoadingSuggestedAddress] = useState<boolean>(false);
  const [npiExists, setNpiExists] = useState(false);
  const [nameExists, setNameExists] = useState(false);
  const { mutate: verify } = useGetPostgridAddressVerification({});
  const postGridValidation = async (): Promise<void> => {
    const requestBody: PostgridAddressVerificationBody = {
      //Get user entered address
      line1: providerFacilityModalContent.address1 ? providerFacilityModalContent.address1 : undefined,
      line2: providerFacilityModalContent.address2 ? providerFacilityModalContent.address2 : undefined,
      country: "us",
      postalOrZip: providerFacilityModalContent.zip ? providerFacilityModalContent.zip : undefined,
      provinceOrState: providerFacilityModalContent.state ? providerFacilityModalContent.state : undefined,
      city: providerFacilityModalContent.city ? providerFacilityModalContent.city : undefined,
    };
    //set loading to true
    setIsLoadingSuggestedAddress(true);
    //send request
    try {
      let result: PostgridAddressVerificationResponse = await verify(requestBody);
      //set loading to false after receiving result
      setIsLoadingSuggestedAddress(false);
      if (result && result.status === "success" && result.data) {
        //if verified, we dont do anything and proceed with save
        if (result.data.status && result.data.status.toLowerCase() === "verified") {
          setModifiedAddress(false);
          setPostGridAddress(result.data);
          setShowAddressResults(true);
          setTimeout(() => {
            if (firstGridRef.current) {
              firstGridRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
            }
          }, 0);
          return;
        }
        //if address was corrected or address verification failed, display suggestion or no results.
        setPostGridAddress(result.data);
        //Show results
        setShowAddressResults(true);
        //Timeout becauase I was having to double click to trigger scrolling
        setTimeout(() => {
          if (firstGridRef.current) {
            firstGridRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 0);
      }
    } catch (e) {
      let errorMessage = "";
      if (typeof e === "string") {
        errorMessage = e.toUpperCase();
      } else if (e instanceof Error) {
        errorMessage = e.message;
      }
      enqueueSnackbar(`Error occured during address verfication ${errorMessage}`, { variant: "error" });
      setIsLoadingSuggestedAddress(false);
      setModifiedAddress(false);
    }
  };

  const [providerFacilityModalContent, setProviderFacilityModalContent] = useState<ProviderFacilityModalContent>({
    name: prefillModalContent.name,
    lastName: prefillModalContent?.lastName,
    firstName: prefillModalContent?.firstName,
    npi: prefillModalContent?.npi,
    tin: prefillModalContent?.tin,
    address1: prefillModalContent?.address1,
    address2: prefillModalContent?.address2,
    city: prefillModalContent?.city,
    state: prefillModalContent?.state,
    zip: prefillModalContent?.zip,
    contactNumber: prefillModalContent?.contactNumber,
    faxNumber: prefillModalContent?.faxNumber,
    npiNotApplicable: prefillModalContent?.npiNotApplicable,
    tinNotApplicable: prefillModalContent?.tinNotApplicable,
  });

  useEffect(() => {
    if (practice && practice.id !== "") {
      setProviderFacilityModalContent(
        convertEntryToContent(practice, isProvider, selectedTin, selectedLocation, modalStatus)
      );
    }
  }, [isProvider, setProviderFacilityModalContent, practice, selectedTin, selectedLocation, modalStatus]);

  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  const [modifiedAddress, setModifiedAddress] = useState(false);

  const hasFirstName = Boolean(providerFacilityModalContent.firstName);
  const hasLastName = Boolean(providerFacilityModalContent.lastName);
  const hasName = Boolean(providerFacilityModalContent.name);
  const hasFirstAndLastName = hasFirstName && hasLastName;

  const phoneNumberValid = Boolean(
    !isProvider && !!!providerFacilityModalContent.contactNumber?.number
      ? true
      : providerFacilityModalContent.contactNumber &&
          providerFacilityModalContent?.contactNumber?.number &&
          providerFacilityModalContent?.contactNumber?.number?.length === 10
  );

  const faxNumberValid = Boolean(
    !!!providerFacilityModalContent.faxNumber ||
      (providerFacilityModalContent.faxNumber && providerFacilityModalContent?.faxNumber.length === 10)
  );

  const TIN_LENGTH = 9;
  const NPI_LENGTH = 10;
  const ZIP_LENGTH = 5;
  const NUM_REGEX = /^\d+$/;

  const tinExists = Boolean(
    providerFacilityModalContent.tin &&
      providerFacilityModalContent.tin.length === TIN_LENGTH &&
      providerFacilityModalContent.tin !== selectedTin &&
      practice?.tinList?.includes(providerFacilityModalContent.tin)
  );

  const hasValidTinLength =
    providerFacilityModalContent.tinNotApplicable ||
    (providerFacilityModalContent.tin &&
      providerFacilityModalContent.tin.length === TIN_LENGTH &&
      Number.isInteger(Number(providerFacilityModalContent.tin)) &&
      !providerFacilityModalContent.tin.includes("."));

  const hasValidNpiLength =
    providerFacilityModalContent.npiNotApplicable ||
    (providerFacilityModalContent.npi &&
      providerFacilityModalContent.npi.length === NPI_LENGTH &&
      Number.isInteger(Number(providerFacilityModalContent.npi)) &&
      !providerFacilityModalContent.npi.includes("."));

  const hasValidZip =
    providerFacilityModalContent.zip &&
    Number.isInteger(Number(providerFacilityModalContent.zip)) &&
    providerFacilityModalContent.zip.length === ZIP_LENGTH;

  const hasValidAddress =
    providerFacilityModalContent.address1 &&
    providerFacilityModalContent.city &&
    providerFacilityModalContent.state &&
    hasValidZip;

  const getParams = (): PracticeUpdatePayload | ProviderFacilityCatalogue => {
    const address: Address = {
      line1: providerFacilityModalContent?.address1 || "",
      line2: providerFacilityModalContent?.address2 || "",
      city: providerFacilityModalContent?.city || "",
      state: providerFacilityModalContent?.state || "",
      zipCode: providerFacilityModalContent?.zip || "",
    };

    let faxNumber;
    if (providerFacilityModalContent.faxNumber) {
      faxNumber = [providerFacilityModalContent.faxNumber];
    }

    let savedName = providerFacilityModalContent?.name || "";
    if (isProvider) {
      savedName = providerFacilityModalContent?.lastName + ", " + providerFacilityModalContent?.firstName;
      setProviderFacilityModalContent({ ...providerFacilityModalContent, name: savedName });
    }

    let contactNumber;
    if (providerFacilityModalContent.contactNumber) {
      contactNumber = [providerFacilityModalContent.contactNumber];
    }

    const loc: Location = {
      name: savedName || "",
      faxNumbers: !!faxNumber ? faxNumber : undefined,
      phoneNumbers: contactNumber,
      address: address,
      tin: !!providerFacilityModalContent.tin ? providerFacilityModalContent.tin : undefined,
      manuallyEditedAddress: true,
    };

    const type: "PROVIDER" | "FACILITY" = isProvider ? "PROVIDER" : "FACILITY";

    if (modalStatus === ModalStatus.Edit) {
      //address
      const addresses = removeAndAddAddress(selectedLocation, practice, address);
      //tin
      const tinList = updateTinList(practice, selectedTin, providerFacilityModalContent?.tin);

      //phone number
      const phoneNumbers = addPhoneNumberIfNotDuplicate(practice, contactNumber);
      loc.phoneNumbers = phoneNumbers;

      //fax number
      const faxNumbers = addFaxNumberIfNotDuplicate(practice, faxNumber);
      loc.faxNumbers = faxNumbers;

      //locations
      //find location obj in practice that matches the userSelected location
      let locations = practice?.locations;
      if (practice?.locations && practice.locations.length >= 1 && !!selectedLocation?.address) {
        let addressToCompare = selectedLocation.address;
        let indexOfMatchingLocation = practice.locations.findIndex(
          (location) =>
            location.address?.line1 === addressToCompare.line1 &&
            location.address?.line2 === addressToCompare.line2 &&
            location.address?.city === addressToCompare.city &&
            location.address?.state === addressToCompare.state &&
            location.address?.zipCode === addressToCompare.zipCode &&
            location.tin === selectedTin
        );
        if (indexOfMatchingLocation === -1) {
          if (!!locations) {
            locations = [...locations, loc];
          } else {
            locations = [loc];
          }
        } else {
          locations?.splice(indexOfMatchingLocation, 1);
          locations?.push(loc);
        }
      }

      // Filter by locations and tinlist by manually entered name
      const modifiedLocationNames: Location[] = [];
      const selectedLocationName: string = loc.name || "";

      if (locations) {
        for (let currLoc of locations) {
          currLoc.name = selectedLocationName;
          modifiedLocationNames.push(currLoc);
        }
      }

      return {
        npi: providerFacilityModalContent?.npi || "",
        name: savedName || "",
        addresses: addresses,
        phoneNumbers: phoneNumbers || [],
        tinList: tinList || [],
        selectedLocation: loc,
        manuallyCreated: true,
        locations: modifiedLocationNames,
        createdByUser: false,
        type: undefined,
        recordType: type,
      };
    } else {
      let tinList;
      if (providerFacilityModalContent?.tin) {
        tinList = [providerFacilityModalContent.tin];
      }

      return {
        npi: providerFacilityModalContent?.npi || "",
        name: savedName || "",
        addresses: [address],
        phoneNumbers: contactNumber || [],
        tinList: tinList || [],
        selectedLocation: loc,
        locations: [loc],
        manuallyCreated: true,
        createdByUser: false,
        type: undefined,
        recordType: type,
      };
    }
  };

  const validateFields = () => {
    return (
      ((isProvider && hasFirstAndLastName) || hasName) &&
      (hasValidTinLength || hideTinField) &&
      hasValidNpiLength &&
      hasValidAddress &&
      phoneNumberValid &&
      faxNumberValid &&
      (!npiExists || !nameExists) &&
      !tinExists
    );
  };

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (validateFields() && onSave) {
      onSave(getParams(), setPractice);
    }
  };

  const submitUpdate = async (
    params: PracticeUpdatePayload,
    practice: Provider | Facility,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (setSelection) {
      try {
        await setSelection(params, practice);
        if (isSummary) {
          setIsEditable(false);
        }
      } catch (e) {
        let errorMessage = "";
        if (typeof e === "string") {
          errorMessage = e.toUpperCase();
        } else if (e instanceof Error) {
          errorMessage = e.message;
        }
        enqueueSnackbar(`Error occured while updating record: ${errorMessage}`, { variant: "error" });
      }
    }
  };

  //Fetch providers having npi as entered in TextField
  const { data: npiProviderCataloguesResult, refetch: refetchNpiProviderCatalogue } = useGetProvidersOrFacilities({
    queryParams: { query: "" },
  });
  const { data: npiFacilityCataloguesResult, refetch: refetchNpiFacilityCatalogue } = useGetProvidersOrFacilities({
    queryParams: { query: "" },
  });

  const isNPIFilled =
    !!providerFacilityModalContent.npi &&
    hasValidNpiLength &&
    (modalStatus === ModalStatus.Add ||
      (modalStatus === ModalStatus.Edit && providerFacilityModalContent.npi !== practice?.npi));

  const isNameFilled =
    !!providerFacilityModalContent.name ||
    (!!providerFacilityModalContent.firstName && !!providerFacilityModalContent.lastName);

  const [queryParams, setQueryParams] = useState<GetProvidersOrFacilitiesQueryParams>({});

  const { spacing } = useTheme();
  const skipManualNpiTin = useFeature("skipManualNpiTin");
  const canSkipManualNpiTin = useAuthorized("CAN_SKIP_MANUAL_NPI_TIN");
  const isGeisingerPatient = patientHealthPlan === "Geisinger";
  const showNotApplicableCheckbox = skipManualNpiTin && isGeisingerPatient && canSkipManualNpiTin;

  useEffect(() => {
    if (isNPIFilled && queryParams.query !== providerFacilityModalContent.npi) {
      const currQueryParams: GetProvidersOrFacilitiesQueryParams = {
        query: !!providerFacilityModalContent.npi ? providerFacilityModalContent.npi : undefined,
        healthPlanName: patientHealthPlan,
        useCMSProviders: "true",
        recordType: isProvider ? "PROVIDER" : "FACILITY",
      };
      setQueryParams(currQueryParams);
      const queryOptions = {
        queryParams: currQueryParams,
        requestOptions: {
          headers: { "Cache-Control": "no-cache", Pragma: "no-cache", Expires: "0" },
        },
      };

      if (isProvider) {
        refetchNpiProviderCatalogue(queryOptions);
      } else {
        refetchNpiFacilityCatalogue(queryOptions);
      }
    }
  }, [
    isNPIFilled,
    isProvider,
    npiFacilityCataloguesResult?.length,
    npiProviderCataloguesResult?.length,
    patientHealthPlan,
    providerFacilityModalContent.npi,
    queryParams,
    refetchNpiFacilityCatalogue,
    refetchNpiProviderCatalogue,
  ]);

  useEffect(() => {
    if (isNPIFilled && (npiProviderCataloguesResult?.length !== 0 || npiFacilityCataloguesResult?.length !== 0)) {
      setNpiExists(true);
    }

    if (isNameFilled) {
      if (isProvider) {
        if (
          !!npiProviderCataloguesResult?.filter(({ name }: Provider) =>
            //use undefined as locales here to not specify the language locale and instead rely on base sensitivity when matching characters ("A" will match any variation of "a", including with accents)
            !!name
              ? name?.localeCompare(
                  providerFacilityModalContent?.lastName?.trim() +
                    ", " +
                    providerFacilityModalContent?.firstName?.trim(),
                  undefined,
                  { sensitivity: "base" }
                ) === 0
              : false
          ).length
        ) {
          setNameExists(true);
        }
      } else {
        if (
          !!npiFacilityCataloguesResult?.filter(({ name }: Facility) =>
            //use undefined as locales here to not specify the language locale and instead rely on base sensitivity when matching characters ("A" will match any variation of "a", including with accents)
            !!name
              ? name?.localeCompare(providerFacilityModalContent?.name?.trim() ?? "", undefined, {
                  sensitivity: "base",
                }) === 0
              : false
          ).length
        ) {
          setNameExists(true);
        }
      }
    }
  }, [
    providerFacilityModalContent.npi,
    patientHealthPlan,
    isProvider,
    npiProviderCataloguesResult,
    npiFacilityCataloguesResult,
    providerFacilityModalContent.name,
    providerFacilityModalContent.lastName,
    providerFacilityModalContent.firstName,
    isNameFilled,
    isNPIFilled,
  ]);

  const handleSavedAddressIndex = useCallback(() => {
    setModifiedAddress(false);
    setShowAddressResults(false);
    const selectedAddress: PostgridAddressVerificationResponseData | null = postGridAddress;
    if (selectedAddress) {
      setProviderFacilityModalContent({
        ...providerFacilityModalContent,
        address1: !!selectedAddress.line1 ? selectedAddress.line1 : "",
        address2: !!selectedAddress.line2 ? selectedAddress.line2 : "",
        city: !!selectedAddress.city ? selectedAddress.city : "",
        state: !!selectedAddress.provinceOrState ? selectedAddress.provinceOrState : "",
        zip: !!selectedAddress.postalOrZip ? selectedAddress.postalOrZip : "",
      });
    }
  }, [postGridAddress, providerFacilityModalContent]);

  return (
    <>
      <DialogTitle>
        <IconButton
          onClick={(e) => {
            onClose?.(e, "backdropClick");
          }}
          data-public
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {isProvider ? (
            <Row>
              <H2 data-public>
                {modalStatus === ModalStatus.Edit ? "Edit provider information" : "Add provider information"}
              </H2>
            </Row>
          ) : isPerfomingProviderPractice ? (
            <Row>
              <H2 data-public>
                {modalStatus === ModalStatus.Edit
                  ? "Edit performing provider practice information"
                  : "Add performing provider practice information"}
              </H2>
            </Row>
          ) : (
            <Row>
              <H2 data-public>
                {modalStatus === ModalStatus.Edit ? "Edit facility information" : "Add facility information"}
              </H2>
            </Row>
          )}
        </Grid>
      </DialogContent>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={hideTinField ? 12 : 6}>
            <TextField
              fullWidth
              error={!!providerFacilityModalContent.npi ? !hasValidNpiLength || (npiExists && nameExists) : false}
              helperText={
                providerFacilityModalContent.npi && !hasValidNpiLength
                  ? "NPI should be 10 digits long"
                  : npiExists && nameExists
                  ? "NPI + name already exists. Please enter a new NPI or new name or go back."
                  : ""
              }
              label={isProvider ? "Provider NPI" : isPerfomingProviderPractice ? "Practice NPI" : "Facility NPI"}
              name="npi"
              value={providerFacilityModalContent.npi}
              onChangeValue={(newNpi) => {
                setNpiExists(false);
                if (newNpi === "" || NUM_REGEX.test(newNpi)) {
                  setProviderFacilityModalContent({ ...providerFacilityModalContent, npi: newNpi });
                }
              }}
              style={{ bottom: showNotApplicableCheckbox ? spacing(-1) : spacing(0) }}
              disabled={providerFacilityModalContent.npiNotApplicable}
              data-public
            />
            {showNotApplicableCheckbox && (
              <StyledCheckBox
                label={<Body1 color={"text.secondary"}>NPI is not applicable</Body1>}
                checked={providerFacilityModalContent.npiNotApplicable}
                onChange={(checked) => {
                  setProviderFacilityModalContent({
                    ...providerFacilityModalContent,
                    npiNotApplicable: checked,
                    npi: checked ? "" : providerFacilityModalContent.npi,
                  });
                }}
                data-public
              />
            )}
          </Grid>
          {!hideTinField && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                error={!!providerFacilityModalContent.tin ? !hasValidTinLength || tinExists : false}
                helperText={
                  providerFacilityModalContent.tin && !hasValidTinLength
                    ? "TIN should be 9 digits long"
                    : tinExists
                    ? "TIN already exists. Please enter a new TIN or go back."
                    : ""
                }
                label={isProvider ? "Provider TIN" : isPerfomingProviderPractice ? "Practice TIN" : "Facility TIN"}
                name="tin"
                value={providerFacilityModalContent.tin === "N/A" ? "" : providerFacilityModalContent.tin}
                onChangeValue={(newTin) => {
                  if (newTin === "" || NUM_REGEX.test(newTin)) {
                    setProviderFacilityModalContent({ ...providerFacilityModalContent, tin: newTin });
                  }
                }}
                style={{ bottom: showNotApplicableCheckbox ? spacing(-1) : spacing(0) }}
                disabled={providerFacilityModalContent.tinNotApplicable}
              />
              {showNotApplicableCheckbox && (
                <StyledCheckBox
                  label={<Body1 color={"text.secondary"}>TIN is not applicable</Body1>}
                  checked={providerFacilityModalContent.tinNotApplicable}
                  onChange={(checked) => {
                    setProviderFacilityModalContent({
                      ...providerFacilityModalContent,
                      tinNotApplicable: checked,
                      tin: checked ? "N/A" : "",
                    });
                  }}
                  data-public
                />
              )}
            </Grid>
          )}
          {isProvider ? (
            <>
              <Row>
                <TextField
                  fullWidth
                  label="First name"
                  name="firstName"
                  error={attemptedSubmit && !hasFirstName}
                  value={providerFacilityModalContent.firstName}
                  onChangeValue={(newFirstName) => {
                    setNameExists(false);
                    setProviderFacilityModalContent({ ...providerFacilityModalContent, firstName: newFirstName });
                  }}
                  data-public
                />
              </Row>
              <Row>
                <TextField
                  fullWidth
                  label="Last name"
                  name="lastName"
                  error={attemptedSubmit && !hasLastName}
                  value={providerFacilityModalContent.lastName}
                  onChangeValue={(newLastName) => {
                    setNameExists(false);
                    setProviderFacilityModalContent({ ...providerFacilityModalContent, lastName: newLastName });
                  }}
                  data-public
                />
              </Row>
            </>
          ) : isPerfomingProviderPractice ? (
            <>
              <Row>
                <TextField
                  fullWidth
                  label="Practice name"
                  name="practiceName"
                  error={attemptedSubmit && !hasName}
                  value={providerFacilityModalContent.name || ""}
                  onChangeValue={(newName) => {
                    setNameExists(false);
                    setProviderFacilityModalContent({ ...providerFacilityModalContent, name: newName });
                  }}
                  data-public
                />
              </Row>
            </>
          ) : (
            <>
              <Row>
                <TextField
                  fullWidth
                  label="Facility name"
                  name="facilityName"
                  error={attemptedSubmit && !hasName}
                  value={providerFacilityModalContent.name || ""}
                  onChangeValue={(newName) => {
                    setNameExists(false);
                    setProviderFacilityModalContent({ ...providerFacilityModalContent, name: newName });
                  }}
                  data-public
                />
              </Row>
            </>
          )}

          <Row>
            <TextField
              fullWidth
              label="Address line 1"
              name="address1"
              value={providerFacilityModalContent.address1}
              error={attemptedSubmit && !providerFacilityModalContent.address1}
              onChangeValue={(newAddress1) => {
                setModifiedAddress(true);
                setShowAddressResults(false);
                setProviderFacilityModalContent({ ...providerFacilityModalContent, address1: newAddress1 });
              }}
              data-public
            />
          </Row>
          <Row>
            <TextField
              fullWidth
              label="Address line 2 (optional)"
              name="address2"
              value={providerFacilityModalContent.address2}
              onChangeValue={(newAddress2) => {
                setModifiedAddress(true);
                setShowAddressResults(false);
                setProviderFacilityModalContent({ ...providerFacilityModalContent, address2: newAddress2 });
              }}
              data-public
            />
          </Row>
          <HalfRow>
            <TextField
              fullWidth
              label="City"
              name="city"
              value={providerFacilityModalContent.city}
              onChangeValue={(newCity) => {
                setModifiedAddress(true);
                setShowAddressResults(false);
                setProviderFacilityModalContent({ ...providerFacilityModalContent, city: newCity });
              }}
              error={attemptedSubmit && !providerFacilityModalContent.city}
              dataPublic
            />
          </HalfRow>
          <StateComponent>
            <UnitedStatesStateSelection
              error={attemptedSubmit && !providerFacilityModalContent.state}
              state={providerFacilityModalContent.state || ""}
              setState={(state) => {
                setModifiedAddress(true);
                setShowAddressResults(false);
                setProviderFacilityModalContent((prev) => {
                  return { ...prev, state };
                });
              }}
              data-public
            />
          </StateComponent>
          <ZipComponent>
            <TextField
              fullWidth
              label="Zip"
              name="zip"
              value={providerFacilityModalContent.zip}
              error={!!providerFacilityModalContent.zip && !hasValidZip}
              helperText={!!providerFacilityModalContent.zip && !hasValidZip && "Zip must be 5 digits"}
              onChangeValue={(newZip) => {
                setModifiedAddress(true);
                setShowAddressResults(false);
                setProviderFacilityModalContent({ ...providerFacilityModalContent, zip: newZip });
              }}
              data-public
            />
          </ZipComponent>
          <Row>
            <PhoneInput
              fullWidth
              label={`Phone number ${isProvider ? "" : "(optional)"}`}
              name="contactNumber"
              onChange={(phoneNumber?: PhoneNumber) => {
                setProviderFacilityModalContent({
                  ...providerFacilityModalContent,
                  contactNumber: phoneNumber,
                });
              }}
              value={providerFacilityModalContent.contactNumber || {}}
              error={!!providerFacilityModalContent.contactNumber?.number ? !phoneNumberValid : false}
              helperText={
                providerFacilityModalContent.contactNumber &&
                !!providerFacilityModalContent.contactNumber.number &&
                !phoneNumberValid &&
                "Phone number must be 10 digits"
              }
              data-public
            />
          </Row>
          <Row>
            <PhoneInput
              fullWidth
              label="Fax number (optional)"
              name="faxNumber"
              onChange={(faxNumber?: PhoneNumber) =>
                setProviderFacilityModalContent({
                  ...providerFacilityModalContent,
                  faxNumber: faxNumber?.number,
                })
              }
              value={{
                number: providerFacilityModalContent.faxNumber ? providerFacilityModalContent.faxNumber : undefined,
                extension: "",
              }}
              error={!faxNumberValid}
              hideExtensionField={true}
              helperText={
                providerFacilityModalContent.faxNumber &&
                !!providerFacilityModalContent.faxNumber &&
                !faxNumberValid &&
                "Fax number must be 10 digits or empty"
              }
              data-public
            />
          </Row>
          <Row>
            {modifiedAddress && !isLoadingSuggestedAddress ? (
              <WideButton
                disabled={!validateFields()}
                onClick={() => {
                  postGridValidation();
                }}
                data-public
              >
                Validate Address
              </WideButton>
            ) : modifiedAddress && isLoadingSuggestedAddress ? (
              <WideButton loading disabled={!validateFields()} data-public>
                Loading Button
              </WideButton>
            ) : modalStatus === ModalStatus.Add ? (
              <WideButton
                disabled={!validateFields()}
                onClick={(e) => {
                  setAttemptedSubmit(true);
                  if (validateFields()) {
                    if (onSubmit) {
                      onSubmit(e);
                    }
                    onClose?.(e, "backdropClick");
                  }
                }}
                data-public
              >
                Save
              </WideButton>
            ) : (
              <WideButton
                disabled={!validateFields()}
                onClick={async (e) => {
                  setAttemptedSubmit(true);
                  if (practice && validateFields()) {
                    await submitUpdate(getParams(), practice, e);
                  }
                  onClose?.(e, "backdropClick");
                }}
                data-public
              >
                Save
              </WideButton>
            )}
          </Row>
        </Grid>
      </DialogContent>
      <DialogContent style={{ padding: "24px 0 0 0" }}>
        {showAddressResults && !isLoadingSuggestedAddress ? (
          <div ref={firstGridRef}>
            <Divider />
            <ResultContent>
              <PostGridAddressSearchResult
                addressData={postGridAddress}
                onSaveAddressFromSearchResults={handleSavedAddressIndex}
              />
            </ResultContent>
          </div>
        ) : null}
      </DialogContent>
    </>
  );
};

export default ProviderFacilityEdit;

const StateComponent = (props: ComponentProps<typeof Grid>) => (
  <Grid style={{ textAlign: "center" }} item xs={4} {...props} />
);

const ZipComponent = (props: ComponentProps<typeof Grid>) => (
  <Grid style={{ textAlign: "center" }} item xs={2} {...props} />
);

const HalfRow = (props: ComponentProps<typeof Grid>) => <Grid style={{ textAlign: "center" }} item xs={6} {...props} />;

const Row = (props: ComponentProps<typeof Grid>) => <Grid style={{ textAlign: "center" }} item xs={12} {...props} />;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const WideButton = styled(PrimaryButton)(({ theme }) => ({
  width: "40%",
  marginTop: theme.spacing(2),
  whiteSpace: "nowrap",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ResultContent = styled(MuiDialogContent)(({ theme }) => ({
  background: colorsLight.background.light,
  padding: theme.spacing(3, 7),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: `${theme.spacing(3)}px ${theme.spacing(5)}px`,
  overflow: "visible",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  padding: 0,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const IconButton = styled(MuiIconButton)(({ theme }) => ({
  float: "right",
  right: theme.spacing(1),
  top: theme.spacing(1),
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
  left: theme.spacing(0.5),
  bottom: theme.spacing(-1.5),
}));

function getPhoneNumber(entry: Provider | Facility | null, selectedLocation: Location | null | undefined) {
  if (entry?.selectedLocation?.phoneNumbers) {
    return entry.selectedLocation.phoneNumbers[0];
  } else if (selectedLocation?.phoneNumbers) {
    return selectedLocation.phoneNumbers[0];
  } else if (entry?.phoneNumbers) {
    return entry.phoneNumbers[0];
  } else {
    return undefined;
  }
}
function getFaxNumber(entry: Provider | Facility | null, selectedLocation: Location | null | undefined) {
  if (entry?.selectedLocation?.faxNumbers) {
    return entry.selectedLocation.faxNumbers[0];
  } else if (selectedLocation?.faxNumbers) {
    return selectedLocation.faxNumbers[0];
  } else {
    return undefined;
  }
}

function convertEntryToContent(
  entry: Provider | Facility | null,
  isProvider: boolean,
  selectedTin: string | null | undefined,
  selectedLocation: Location | null | undefined,
  modalStatus: ModalStatus
): ProviderFacilityModalContent {
  if (modalStatus === ModalStatus.Add) {
    entry = null;
  }
  const splicedProviderName = isProvider ? entry?.name?.split(", ") : undefined;
  const [lastName, firstName] = splicedProviderName || [undefined, undefined];

  const entryAddress = selectedLocation?.address;
  const address1 = entryAddress?.line1;
  const address2 = entryAddress?.line2;
  const city = entryAddress?.city;
  const state = entryAddress?.state;
  const zip = entryAddress?.zipCode;
  const phoneNumber = getPhoneNumber(entry, selectedLocation);
  const faxNumber = getFaxNumber(entry, selectedLocation);
  return {
    name: entry?.name,
    lastName,
    firstName,
    npi: entry?.npi || "",
    tin: !!selectedTin ? selectedTin : "",
    address1: address1 || "",
    address2: address2 || "",
    city: city || "",
    state: state || "",
    zip: zip || "",
    contactNumber: phoneNumber,
    faxNumber: faxNumber,
    npiNotApplicable: modalStatus === ModalStatus.Edit ? !entry?.npi : false,
    tinNotApplicable: modalStatus === ModalStatus.Edit ? selectedTin === "N/A" : false,
  };
}
