import {
  ServiceRequestResponse,
  usePostClinicalAssessment,
  ServiceRequestCreatePayload,
  useStartHealthHelpIntegration,
  HealthHelpPASRequestResponse,
  useWithdrawHealthHelpCase,
  Requestor,
  useBatchSubmitServiceRequests,
} from "@coherehealth/core-platform-api";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import React, { Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import ClinicalSkipOrIncompleteModal from "components/ClinicalAssessment/ClinicalSkipOrIncompleteModal";
import SubmissionModal, { SubmissionStatus } from "components/ServiceRequest/Submission/SubmissionModal";
import { ClinicalAssessmentContext } from "components/ClinicalAssessment";
import { PrimaryButton, useFeature, useMuiContainerStyles, HEALTH_HELP_NAME } from "@coherehealth/common";
import { WithdrawAndReplaceStep } from "./WithdrawAndReplacePageContents";
import { error as logError } from "logger";
import { useNavigate } from "react-router-dom";
import { clearNotShownClinicalQuestions, incompleteRequiredQuestions } from "util/clinicalAssessment";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { ClinicalAssessmentProvider } from "components/AuthBuilder";
import { formContentFromResponse, payloadFromSRFormContent, useIsDelegatedToVendor } from "util/serviceRequest";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { redirectToPatientSummaryFromReview } from "util/routeUtils/routeUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    top: "auto",
    bottom: 0,
  },
  colorPrimary: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RightButton = styled(PrimaryButton)(({ theme }) => ({
  float: "right",
  margin: `${theme.spacing(2)}px 0`,
}));

interface Props {
  setCurrentStep: (arg: WithdrawAndReplaceStep) => void;
  currentStep: WithdrawAndReplaceStep;
  formContent: ServiceRequestFormContent;
  setFormContent: (formContent: ServiceRequestFormContent) => void;
  setClinicalAssessmentProvider: (provider: ClinicalAssessmentProvider) => void;
  nextButtonLabel: ReactNode;
  patientId?: string;
  serviceRequest: ServiceRequestResponse;
  serviceRequestEdited: boolean;
  setServiceRequestEdited: (b: boolean) => void;
  serviceRequestCanBeSubmitted: boolean;
  requestor?: Requestor;
  attemptedSubmitService: boolean;
  setAttemptedSubmitService: Dispatch<SetStateAction<boolean>>;
  attachmentsReady: boolean;
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  patchServiceRequest: Function;
  patchServiceRequestError: boolean;
  patchServiceRequestLoading: boolean;
  refetchServiceRequest: () => Promise<unknown>;
  refetchClinicalAssessment: () => Promise<unknown>;
  refetchAttachments: () => Promise<unknown>;
  setHealthHelpPASResponse: (response: HealthHelpPASRequestResponse) => void;
  vendorQuestionnaireInvalid?: boolean;
  setVendorAssessmentError: (b: boolean) => void;
  setVendorQuestionnaireComplete: (b: boolean) => void;
}

/**
 * A footer shown on the CreateJourney flow that helps with navigation
 *
 * Note: Some navigation (like going back a step) is also in the JourneyProgress header
 */
export default function WithdrawAndReplaceFooter({
  setCurrentStep,
  currentStep,
  formContent,
  setFormContent,
  setClinicalAssessmentProvider,
  nextButtonLabel,
  patientId,
  serviceRequest,
  serviceRequestEdited,
  setServiceRequestEdited,
  serviceRequestCanBeSubmitted,
  requestor,
  attemptedSubmitService,
  setAttemptedSubmitService,
  attachmentsReady,
  patchServiceRequest,
  patchServiceRequestLoading,
  refetchServiceRequest,
  refetchClinicalAssessment,
  refetchAttachments,
  setHealthHelpPASResponse,
  vendorQuestionnaireInvalid,
  setVendorAssessmentError,
  setVendorQuestionnaireComplete,
}: Props) {
  const classes = useStyles();
  const containerClasses = useMuiContainerStyles();

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const isDelegatedToHealthHelp = useIsDelegatedToVendor(serviceRequest, [HEALTH_HELP_NAME]);
  const simplifiedServiceFrequency = useFeature("simplifiedServiceFrequency");

  const {
    mutate: submitServiceRequests,
    loading: submitServiceRequestLoading,
    error: submitServiceRequestsError,
  } = useBatchSubmitServiceRequests({});

  const {
    loading: loadingClinicalAssessment,
    error: errorSubmitClinicalAssessment,
    mutate,
  } = usePostClinicalAssessment({});

  const {
    mutate: startHealthHelpIntegration,
    loading: startHealthHelpIntegrationLoading,
    error: startHealthHelpIntegrationError,
  } = useStartHealthHelpIntegration({ id: "" });

  const {
    mutate: withdrawHealthHelpCase,
    loading: withdrawHealthHelpCaseLoading,
    error: withdrawHealthHelpCaseError,
  } = useWithdrawHealthHelpCase({ id: "" });

  useEffect(() => {
    if (startHealthHelpIntegrationError) {
      setVendorAssessmentError(true);
      setCurrentStep("CLINICAL_ASSESSMENT");
      enqueueSnackbar("Could not connect to HealthHelp, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [startHealthHelpIntegrationError, enqueueSnackbar, setCurrentStep, setVendorAssessmentError]);

  useEffect(() => {
    if (withdrawHealthHelpCaseError) {
      enqueueSnackbar("Could not withdraw previous case from HealthHelp, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [withdrawHealthHelpCaseError, enqueueSnackbar]);

  const [clinicalIncompleteModalOpen, setClinicalIncompleteModalOpen] = useState<boolean>(false);
  const [showSubmissionModal, setShowSubmissionModal] = useState(false);

  const { clinicalAssessment, subQuestions: subQuestionsVisibility } = useContext(ClinicalAssessmentContext);

  const closeClinicalIncompleteModal = () => setClinicalIncompleteModalOpen(false);
  const openClinicalIncompleteModal = () => {
    setClinicalIncompleteModalOpen(true);
  };

  const submitServiceRequest = () => {
    setShowSubmissionModal(true);
    const serviceRequestToSubmit = {
      ...serviceRequest,
      requestor,
    };
    submitServiceRequests([serviceRequestToSubmit]);
  };

  useEffect(() => {
    if (errorSubmitClinicalAssessment) {
      enqueueSnackbar(`Failed to post assessment: ${errorSubmitClinicalAssessment.message}`, { variant: "error" });
    }
  }, [errorSubmitClinicalAssessment, enqueueSnackbar]);

  const saveClinicalAssessment = async () => {
    try {
      if (clinicalAssessment && clinicalAssessment.questions) {
        const answeredClinicalAssessmentQuestions = clearNotShownClinicalQuestions(clinicalAssessment.questions);
        await mutate({ ...clinicalAssessment, questions: answeredClinicalAssessmentQuestions });
        setCurrentStep("REVIEW");
        setClinicalIncompleteModalOpen(false);
      }
    } catch (e) {
      logError(e);
    }
  };

  const goToPatientSummary = () => {
    if (serviceRequest?.patient?.id) {
      redirectToPatientSummaryFromReview({ serviceRequest, navigate });
    }
  };

  const continueFromAttachmentsPage = async () => {
    await refetchAttachments();
    setCurrentStep("CLINICAL_ASSESSMENT");
  };

  const onTrySubmitClinicalAssessment = () => {
    if (incompleteRequiredQuestions(clinicalAssessment, subQuestionsVisibility).length === 0) {
      // OK, you can submit
      if ((clinicalAssessment?.questions?.length || 0) > 0) {
        saveClinicalAssessment();
      } else {
        setCurrentStep("REVIEW");
      }
    } else {
      openClinicalIncompleteModal();
    }
  };

  const onSubmitEditDetails = async () => {
    setAttemptedSubmitService(true);
    if (serviceRequestCanBeSubmitted) {
      const clinicalAssessmentProvider = await submitServiceRequestForm(formContent);
      setClinicalAssessmentProvider(clinicalAssessmentProvider);
      //refetch the SR to keep the top level SR state up to date
      refetchServiceRequest();
      if (clinicalAssessmentProvider === "Cohere") {
        await refetchClinicalAssessment();
      }
      setAttemptedSubmitService(false);
      setCurrentStep("ADD_ATTACHMENT");
    }
  };

  const submitServiceRequestForm = async (sr: ServiceRequestFormContent): Promise<ClinicalAssessmentProvider> => {
    const payload: ServiceRequestCreatePayload = {
      ...payloadFromSRFormContent(sr, simplifiedServiceFrequency),
      patient: patientId,
    };
    const response = await patchServiceRequest({ ...payload }, { pathParams: { id: sr?.id } });
    if (isDelegatedToHealthHelp) {
      if (serviceRequestEdited) {
        if (serviceRequest?.vendorIdentifier) {
          // withdraw existing health help case
          await withdrawHealthHelpCase(
            {},
            {
              pathParams: { id: response?.id },
            }
          );
        }
        const healthHelpIntegrationResponse = await startHealthHelpIntegration(
          {},
          {
            pathParams: { id: response?.id },
          }
        );
        setHealthHelpPASResponse(healthHelpIntegrationResponse);
        setVendorQuestionnaireComplete(false);
      }
    }

    if (response) {
      setFormContent(formContentFromResponse(response));
      setServiceRequestEdited(false);
      if (isDelegatedToHealthHelp) {
        return "HealthHelp";
      } else {
        return "Cohere";
      }
    }
  };

  let submissionStatus: SubmissionStatus;
  if (submitServiceRequestsError) {
    submissionStatus = SubmissionStatus.Failure;
  } else if (submitServiceRequestLoading) {
    submissionStatus = SubmissionStatus.Loading;
  } else {
    submissionStatus = SubmissionStatus.Success;
  }

  let footerContents;

  const blockSubmission = attemptedSubmitService && !serviceRequestCanBeSubmitted;

  switch (currentStep) {
    case "EDIT_DETAILS":
      footerContents = (
        <>
          <RightButton
            onClick={onSubmitEditDetails}
            loading={patchServiceRequestLoading || startHealthHelpIntegrationLoading || withdrawHealthHelpCaseLoading}
            disabled={
              blockSubmission ||
              patchServiceRequestLoading ||
              startHealthHelpIntegrationLoading ||
              withdrawHealthHelpCaseLoading
            }
          >
            {nextButtonLabel}
          </RightButton>
        </>
      );
      break;
    case "CLINICAL_ASSESSMENT":
      footerContents = (
        <>
          <RightButton
            disabled={vendorQuestionnaireInvalid || loadingClinicalAssessment}
            loading={loadingClinicalAssessment}
            onClick={async () => await onTrySubmitClinicalAssessment()}
          >
            {nextButtonLabel}
          </RightButton>
          <ClinicalSkipOrIncompleteModal
            modalOpen={clinicalIncompleteModalOpen}
            onClose={closeClinicalIncompleteModal}
            modalState={"INCOMPLETE_CHECK"}
            loading={loadingClinicalAssessment}
            submitAndContinue={saveClinicalAssessment}
          />
        </>
      );
      break;

    case "ADD_ATTACHMENT":
      footerContents = (
        <>
          <RightButton onClick={continueFromAttachmentsPage} disabled={!attachmentsReady}>
            {nextButtonLabel}
          </RightButton>
        </>
      );
      break;

    case "REVIEW":
    case "SKIP_TO_REVIEW":
      footerContents = (
        <>
          <RightButton onClick={submitServiceRequest}>{nextButtonLabel}</RightButton>
          <SubmissionModal
            open={showSubmissionModal}
            handleClose={() => setShowSubmissionModal(false)}
            onSuccessAction={goToPatientSummary}
            onFailureAction={submitServiceRequest} /* allow a resubmit on failure */
            status={submissionStatus}
          />
        </>
      );
      break;
  }

  if (!footerContents) {
    return null;
  }

  return (
    <AppBar classes={classes} component="footer" elevation={0}>
      <Container classes={containerClasses} maxWidth="lg">
        {footerContents}
      </Container>
    </AppBar>
  );
}
