import { extractDateTimeMeridianStrings, formatDateTimeStrToISO, RadioGroupOption } from "@coherehealth/common";
import {
  AuthStatus,
  OutOfNetworkReviewConfigurationResponse,
  PeerToPeerOutreachOutcome,
  PendingReason,
  ProcedureCode,
  ReviewOutcomeWithdrawOption,
  ServiceRequestResponse,
  ServiceRequestSearchResponse,
  WithdrawRequestor,
} from "@coherehealth/core-platform-api";
import { makeStyles } from "@material-ui/core";
import Grid, { GridSize } from "@material-ui/core/Grid";
import { ComponentProps } from "react";
import { determinePostDenialP2PEligibility } from "util/serviceRequest";

export interface ChangeStatusFormState {
  authStatus: AuthStatus | undefined;
  pendingReason: PendingReason | undefined;
  authorizationNote: string | undefined;
  tatExtension: {
    eligible: boolean | undefined;
    decision: "YES" | "NO";
  };
  partialApproval: {
    approvedUnits: number | undefined;
    approvedProcedureCodes: ProcedureCode[] | undefined;
  };
  peerToPeer: {
    attempted: boolean | undefined;
    lastAttempt?: {
      date?: string;
      time?: string;
      meridian?: string;
      outcome?: PeerToPeerOutreachOutcome | "";
    };
    isPostDenialEligible?: boolean;
  };
  withdrawVoid: {
    reason: string | undefined;
    requester: WithdrawRequestor | undefined;
  };
  claimsPaymentNote: string | undefined;
  nextReviewDate: Date | null;
}

export interface FilteredPendingReason {
  id: string;
  label: React.ReactNode;
  children?: React.ReactNode;
  disabled?: boolean;
  widthOverride?: GridSize;
  hasTooltipAsChild?: boolean;
}

export type CANCELLATION_TYPE = "VOIDED" | "WITHDRAWN" | "DISMISSED";

export const Row = (props: ComponentProps<typeof Grid>) => {
  const classes = useStyles();
  return <Grid item className={classes.row} xs={12} {...props} />;
};

export function getISODateStringFromStateFields(dateStr: string, timeStr: string, mValue: boolean) {
  const [hours, minutes] = timeStr.split(":");
  return formatDateTimeStrToISO(dateStr, Number(hours) || 0, Number(minutes) || 0, mValue);
}

export const isLetterDecision = (
  authStatus: AuthStatus,
  pendingReason: PendingReason | undefined,
  TATExtensionDecision: "YES" | "NO"
): boolean => {
  if (TATExtensionDecision === "YES") {
    return true;
  }
  switch (authStatus) {
    case "APPROVED":
    case "WITHDRAWN":
    case "DENIED":
    case "VOIDED":
    case "DISMISSED":
    case "PARTIALLY_APPROVED":
      return true;
    case "PENDING":
      return pendingReason === "MD_REVIEW";
    default:
      return false;
  }
};

export const CenteredRow = (props: ComponentProps<typeof Grid>) => {
  const classes = useStyles();
  return <Grid item xs={12} className={classes.centeredRow} {...props} />;
};

export const pendingReasonOptions: Record<PendingReason, Omit<RadioGroupOption, "id">> = {
  MISSING_CLINICAL_INFO: { label: "Missing information (clinical)" },
  MISSING_NON_CLINICAL_INFO: { label: "Missing information (non-clinical)" },
  RN_REVIEW: { label: "In clinical review" },
  MD_REVIEW: { label: "In MD review" },
  NEW_HIRE_AUDIT_REVIEW: { label: "In new hire audit review" },
  PEER_TO_PEER_REVIEW: { label: "In peer-to-peer review" },
  OSCAR: { label: "Oscar" },
  PENDING_ASSESSMENT_SUBMISSION: { label: "Pending assessment submission" },
  OTHER: {
    label: "Other",
    disabled: true,
  },
  AUDIT_REVIEW: { label: "Audit Review" },
  NUDGE_OUTREACH: { label: "Nudge outreach" },
  ADMIN_VOID: { label: "Admin void" },
  OUT_OF_SCOPE: { label: "Out of scope - Send to Humana" },
  SCHEDULING_OUTREACH: { label: "Peer to peer scheduling" },
  OUT_OF_NETWORK_REVIEW: { label: "In out of network review" },
  LETTER_WRITING: { label: "Letter writing" },
};

export function isPendingReason(ipt: unknown): ipt is PendingReason {
  return typeof ipt === "string" && pendingReasonOptions[ipt as PendingReason] !== undefined;
}

export const srHasPatientStatus = (sr: any): sr is ServiceRequestResponse => {
  return !!sr.patientStatus;
};

interface FilteredPendingReasonsConfig {
  diagnosisOutOfScopeEnabled?: boolean;
  formState: ChangeStatusFormState;
  isGHPServiceRequest?: boolean;
  isHumanaServiceRequest?: boolean;
  ghpPendingReasons: string[];
  oonReviewConfig?: OutOfNetworkReviewConfigurationResponse;
}

export const getFilteredPendingReasons = (
  pendingReasonOptions: Record<PendingReason, Omit<RadioGroupOption<string>, "id">>,
  config: FilteredPendingReasonsConfig
): FilteredPendingReason[] => {
  const {
    diagnosisOutOfScopeEnabled,
    formState,
    ghpPendingReasons,
    isGHPServiceRequest,
    isHumanaServiceRequest,
    oonReviewConfig,
  } = config;
  return (
    Object.entries(pendingReasonOptions)
      // "Other" shouldn't be used anymore, but it may still be present on requests,
      // so show it as an option only if it is the selected value (pendingReason)
      .filter(([id]) => {
        const { pendingReason } = formState;
        if (isGHPServiceRequest && !ghpPendingReasons.includes(id)) {
          return false;
        }
        if (id === "OTHER") {
          return pendingReason === "OTHER";
          // filter out ADMIN_VOID if it's not already selected or FF is disabled
        }
        if (id === "OUT_OF_SCOPE") {
          return isHumanaServiceRequest && diagnosisOutOfScopeEnabled;
        }
        if (id === "SCHEDULING_OUTREACH") {
          return isGHPServiceRequest;
        }
        if (id === "OUT_OF_NETWORK_REVIEW") {
          return oonReviewConfig?.enabled;
        }
        return true;
      })
      .map(([id, option]) => ({ ...option, id }))
  );
};

interface FormStatusFromSelectionConfig {
  postDenialWorkflowEnabled: boolean;
  postDecisionP2PExpanded: boolean;
}

export const getFormStatusFromSelection = (
  selection: ServiceRequestResponse | ServiceRequestSearchResponse,
  config: FormStatusFromSelectionConfig
): ChangeStatusFormState => {
  const { postDenialWorkflowEnabled, postDecisionP2PExpanded } = config;
  return {
    authStatus: selection.authStatus,
    pendingReason: selection.pendingReason,
    authorizationNote: selection.authorizationNote,
    tatExtension: {
      eligible: !!selection.turnAroundTimeExtensionEligible,
      decision: "NO",
    },
    partialApproval: {
      approvedUnits: selection.approvedUnits,
      approvedProcedureCodes: Boolean(selection.approvedProcedureCodes)
        ? selection.approvedProcedureCodes
        : selection.procedureCodes,
    },
    peerToPeer: {
      attempted: undefined,
      lastAttempt: {
        ...extractDateTimeMeridianStrings(new Date()),
        outcome: "",
      },
      isPostDenialEligible:
        postDenialWorkflowEnabled &&
        determinePostDenialP2PEligibility({
          currentAuthStatus: selection.authStatus,
          initialDecisionDisposition: selection.initialDecisionDisposition,
          revisedDecisionTimestamp: selection.revisedDecisionTimestamp,
          postDecisionP2PExpanded,
        }),
    },
    withdrawVoid: {
      reason: selection.withdrawnReason,
      requester: selection.withdrawRequestor,
    },
    claimsPaymentNote: "",
    nextReviewDate: null,
  };
};

export const getAuthStatusDropdownLabel = ({
  isPostDenialEligible,
  postDecisionP2PExpanded,
}: {
  isPostDenialEligible?: boolean;
  postDecisionP2PExpanded?: boolean;
}): string => {
  if (isPostDenialEligible && postDecisionP2PExpanded) {
    return "Choose post-decision status";
  }
  if (isPostDenialEligible && !postDecisionP2PExpanded) {
    return "Choose post-denial status";
  }
  return "Choose status";
};

export const getWithdrawnReasonsForRadioGroup = (
  reasons: ReviewOutcomeWithdrawOption[],
  NotificationDek: JSX.Element
) => {
  return reasons.map((voidingReason) => ({
    id: getWithdrawalReasonDropdownOptionID(voidingReason),
    label: voidingReason.label ?? "",
    children: !voidingReason.permission ? NotificationDek : undefined,
  }));
};

export const getWithdrawalReasonDropdownOptionID = (withdrawalOption?: ReviewOutcomeWithdrawOption): string => {
  if (!withdrawalOption) {
    return "";
  }
  let id = `${withdrawalOption.id ?? ""}`;
  if (withdrawalOption.withdrawalRequestorId) {
    id += `#${withdrawalOption.withdrawalRequestorId}`;
  }
  return id;
};

interface WithdrawalReasonDropdownOptionChangeHandlerProps {
  dropdownEntryId: string;
  setFormState?: (value: React.SetStateAction<ChangeStatusFormState>) => void;
  withdrawalOptions: ReviewOutcomeWithdrawOption[];
  setStateOverride?: (reason?: string, requester?: WithdrawRequestor) => void;
}

interface WithdrawalOptionDropdownMappingEntry {
  id: string;
  cancellationType: AuthStatus;
  withdrawalRequestorId?: WithdrawRequestor;
}

export const withdrawalReasonToDropdownOptionMappings = (
  withdrawalOptions: ReviewOutcomeWithdrawOption[]
): Map<string, WithdrawalOptionDropdownMappingEntry> => {
  const dropDownIdToEntryMapping: Map<string, WithdrawalOptionDropdownMappingEntry> = new Map(
    withdrawalOptions.map((withdrawalOption) => {
      const dropdownEntryId = getWithdrawalReasonDropdownOptionID(withdrawalOption);
      return [
        dropdownEntryId,
        {
          id: withdrawalOption.id,
          cancellationType: withdrawalOption.cancellationType!,
          withdrawalRequestorId: withdrawalOption.withdrawalRequestorId,
        },
      ];
    })
  );
  return dropDownIdToEntryMapping;
};

/**
 * This function handles the scenario in which not all of the configurations will have a withdrawRequestor defined,
 * but some places in the application are setting a default value for the requestor by default, i.e. backOffice users
 * get the requestor set to "PROVIDER" during the authbuilder workflow. This introduces an editability issue, because
 * after the first time the SR gets the withdrawnReason modified/set the requestor value will vary on the SRs that got
 * submitted by backOffice (DUPLICATED_REQUEST#PROVIDER) users and by serviceOps users (DUPLICATED_REQUEST), so we need
 * to normalize the output to handle both scenarios.
 * @param value
 * @param withdrawnReasonSelectOptions
 * @returns normalized withdrawnOption
 */
export const calculateWithdrawnOptionNormalizedValue = (
  value: string | undefined,
  withdrawnReasonSelectOptions: () => ReviewOutcomeWithdrawOption[]
): string | undefined => {
  // If the compound value of withdrawnReason + withdrawnRequestor doesn't exist in the configured values
  // from the serviceRequestWithdrawalConfig then that means that the requestor was defaulted
  const valueTokens = value?.split("#");
  let [selectedWithdrawnReason, selectedWithdrawRequestor] = [valueTokens?.[0], valueTokens?.[1]];
  const selectedValueExists = withdrawnReasonSelectOptions()?.filter((withdrawnOption) => {
    return (
      withdrawnOption.id === selectedWithdrawnReason &&
      withdrawnOption.withdrawalRequestorId === selectedWithdrawRequestor
    );
  });

  let normalizedValued = value;
  if (!selectedValueExists || selectedValueExists.length < 1) {
    normalizedValued = selectedWithdrawnReason;
  }

  return normalizedValued;
};

export const withdrawalReasonDropdownOptionChangeHandler = (
  args: WithdrawalReasonDropdownOptionChangeHandlerProps
): void => {
  const { dropdownEntryId, setFormState, setStateOverride, withdrawalOptions } = args;
  const dropDownIdToEntryMapping: Map<string, WithdrawalOptionDropdownMappingEntry> =
    withdrawalReasonToDropdownOptionMappings(withdrawalOptions);
  const mappedEntry = dropDownIdToEntryMapping.get(dropdownEntryId);

  const withdrawnReason = mappedEntry ? mappedEntry.id : dropdownEntryId?.split("#")?.[0];
  const withdrawnRequester = mappedEntry ? mappedEntry.withdrawalRequestorId : undefined;

  if (!mappedEntry) {
    return;
  }

  if (!setStateOverride) {
    setFormState?.((prevFormState) => ({
      ...prevFormState,
      withdrawVoid: {
        reason: withdrawnReason,
        requester: withdrawnRequester,
      },
    }));
  }

  if (setStateOverride) {
    setStateOverride(withdrawnReason, withdrawnRequester);
  }
};

const useStyles = makeStyles({
  centeredRow: {
    display: "flex",
    justifyContent: "center",
  },
  row: {
    textAlign: "center",
  },
});
