import { useMatch } from "react-router-dom";
import routes from "routes";
import Header from "components/FaxServiceRequest/Header";
import { RequestForm } from "./RequestForm";
import { FaxStep } from "components/FaxServiceRequest";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { ServiceRequestResponse, useGetProcedureCodes, useGetClaimById } from "@coherehealth/core-platform-api";
import {
  parseDateFromISOString,
  parseDateFromISOStringWithoutFallback,
  today,
  useFeature,
  useMuiContainerStyles,
} from "@coherehealth/common";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSnackbar } from "notistack";

export default function ClaimServiceRequestPage() {
  const match = useMatch(routes.CLAIM_SERVICE_REQUEST);
  const [formClaimId, setFormClaimId] = useState<string>("");
  const [procedureCodeString, setProcedureCodeString] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();
  const claimId = match?.params.claimId || "";
  const { data: claim, loading: loadingClaim, error } = useGetClaimById({ id: claimId });

  useEffect(() => {
    if (error) {
      enqueueSnackbar(`Error fetching claim: ${error.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, error]);

  useEffect(() => {
    if (claim && !claim.patient) {
      enqueueSnackbar(`Claim not linked to a patient`, { variant: "error" });
    }
  }, [claim, enqueueSnackbar]);

  const [step, setStep] = useState<FaxStep>("SAVE_AND_REVIEW");

  const simplifiedServiceFrequency = useFeature("simplifiedServiceFrequency");

  const [formContent, setFormContent] = useState<ServiceRequestFormContent>({
    clinicalService: undefined,
    palCategory: null,
    isInpatient: false,
    placeOfService: null,
    primaryDiagnosisCode: null,
    secondaryDiagnosisCodes: [],
    procedureCodes: [],
    isRecurring: simplifiedServiceFrequency ? undefined : false,
    startDate: today(),
    endDate: undefined,
    units: "0",
    unitType: undefined,
    orderingProvider: null,
    orderingProviderSelectedTin: null,
    performingProvider: null,
    performingProviderSelectedTin: null,
    facility: null,
    facilitySelectedTin: null,
    isExpedited: false,
    expeditedReason: "",
    facilitySelectedAddress: null,
    performingProviderSelectedAddress: null,
    orderingProviderSelectedAddress: null,
    approvedUnits: "",
  });

  const [serviceRequest, setServiceRequest] = useState<ServiceRequestResponse | undefined>();
  //when creating a new request, we default to today as the start date
  //user think these are suggested values instead of defaults, so we are clearing the values
  //and requiring the user to select the values they need
  const [clearDefaultStartDateValue, setClearDefaultStartDateValue] = useState<boolean>(true);

  const [attemptedSubmitService, setAttemptedSubmitService] = useState<boolean>(false);
  const [canBeSubmitted, setCanBeSubmitted] = useState(false);
  const [showFooter, setShowFooter] = useState<boolean>(true);
  const containerClasses = useMuiContainerStyles();

  const invalidForm = attemptedSubmitService && !canBeSubmitted;
  const { data: procedureCode, refetch: refetchProcedureCode } = useGetProcedureCodes({
    queryParams: { code: procedureCodeString, max: 1 },
    lazy: true,
  });

  useEffect(() => {
    if (procedureCodeString && procedureCode) {
      setFormContent({ ...formContent, procedureCodes: procedureCode.map((sc) => ({ ...sc, code: sc?.code || "" })) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [procedureCode, procedureCodeString, setFormContent]);

  useEffect(() => {
    if (claim) {
      if (claim.claimId) {
        setFormClaimId(claim.claimId.toString());
      }
      if (claim.hcpcsBaseCode1) {
        setProcedureCodeString(`in:${claim.hcpcsBaseCode1}`);
        refetchProcedureCode();
      }
      const fromDate = parseDateFromISOStringWithoutFallback(claim.serviceFromDate);
      if (fromDate) {
        setClearDefaultStartDateValue(false);
      }
      let isRecurring = false;
      let toDate = undefined;
      if (claim.serviceToDate && claim.serviceFromDate && claim.serviceFromDate > claim.serviceToDate) {
        isRecurring = true;
        toDate = parseDateFromISOString(claim.serviceToDate);
      }
      setFormContent({
        ...formContent,
        startDate: fromDate || today(),
        endDate: toDate ? toDate : undefined,
        isRecurring: simplifiedServiceFrequency ? undefined : isRecurring,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFormContent, setFormClaimId, claim, refetchProcedureCode, simplifiedServiceFrequency]);

  const onSaveAndReview = () => {
    setStep("SUBMIT_REQUEST");
  };

  return (
    <Container classes={containerClasses} maxWidth="lg">
      {loadingClaim ? (
        <CircularProgress size={24} />
      ) : (
        <>
          <Header />
          {claim && (
            <RequestForm
              formContent={formContent}
              setFormContent={setFormContent}
              setCanBeSubmitted={setCanBeSubmitted}
              serviceRequest={serviceRequest}
              step={step}
              setStep={setStep}
              attemptedSubmitService={attemptedSubmitService}
              setServiceRequest={setServiceRequest}
              setShowFooter={setShowFooter}
              loading={false}
              claimId={formClaimId}
              patient={claim.patient || undefined}
              clearDefaultStartDateValue={clearDefaultStartDateValue}
              allowCareTypeTranisition={true}
            />
          )}
          {showFooter && claim && claim.patient && (
            <Footer
              formContent={formContent}
              setFormContent={setFormContent}
              canBeSubmitted={canBeSubmitted}
              step={step}
              patientId={claim.patientId || ""}
              setAttemptedSubmitService={setAttemptedSubmitService}
              serviceRequest={serviceRequest}
              loading={loadingClaim}
              setServiceRequest={setServiceRequest}
              setShowFooter={setShowFooter}
              onSaveAndReview={onSaveAndReview}
              cannotSubmit={invalidForm}
              reconClaim={claim}
            />
          )}
        </>
      )}
    </Container>
  );
}
