import { NotesProps, sortByDateCreatedDesc } from "../notesCardTypes";
import { Box } from "@material-ui/core";
import { IndividualNote } from "./IndividualNote";

export const NotesDisplay = ({ notes, serviceRequest, reviewClaimsPaymentNotes, view, noteConfig }: NotesProps) => {
  const notesToDisplay = [notes || [], reviewClaimsPaymentNotes];

  const flattened = notesToDisplay.flat() !== undefined ? notesToDisplay.flat() : [];
  const sortedNotes = [...flattened].sort(sortByDateCreatedDesc);

  return (
    <>
      {sortedNotes.map((n, idx) => (
        <Box display="flex" flexDirection="column" key={`${n?.id}_${view}`}>
          <IndividualNote note={n} view={"NOTES"} index={idx} serviceRequest={serviceRequest} noteConfig={noteConfig} />
        </Box>
      ))}
    </>
  );
};
