import React from "react";
import { H1, H6 } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, useTheme, makeStyles } from "@material-ui/core/styles";
import { Container, Dialog, DialogProps, Slide, SlideProps } from "@material-ui/core";
import { SecondaryButton } from "@coherehealth/common";

interface Props extends DialogProps {
  open: boolean;
  iconSrc?: string;
  headingText: string;
  subHeaderText?: string | React.ReactNode;
  tertiaryCenterButton?: React.ReactNode;
  primaryBottomButton?: React.ReactNode;
  onPrimaryBottomButtonClick?: () => void;
  primaryBottomButtonText?: string;
  slideDirection?: SlideProps["direction"];
  isAdhoc?: boolean;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const SlideDown = React.forwardRef((props: SlideProps, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

const SlideUp = React.forwardRef((props: SlideProps, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SlideLeft = React.forwardRef((props: SlideProps, ref) => {
  return <Slide direction="left" ref={ref} {...props} />;
});

const SlideRight = React.forwardRef((props: SlideProps, ref) => {
  return <Slide direction="right" ref={ref} {...props} />;
});

const getSlider = (slideDirection: SlideProps["direction"]) => {
  switch (slideDirection) {
    case "up":
      return SlideUp;
    case "down":
      return SlideDown;
    case "left":
      return SlideLeft;
    case "right":
      return SlideRight;
    default:
      return SlideDown;
  }
};

export default function FullScreenDialog({
  open,
  iconSrc,
  headingText,
  subHeaderText,
  tertiaryCenterButton,
  primaryBottomButton,
  onPrimaryBottomButtonClick,
  primaryBottomButtonText,
  slideDirection,
  isAdhoc,
}: Props) {
  const { spacing } = useTheme();
  const classes = useStyles();
  const Slider = getSlider(slideDirection);
  return (
    <Dialog
      fullScreen
      classes={{ paperFullScreen: classes.modal }}
      open={open}
      TransitionComponent={Slider}
      transitionDuration={450}
    >
      <DenialSavedForReviewContainer>
        <IconAndTextContainter>
          <div>
            <img alt="" src={iconSrc} />
          </div>
          <div style={{ paddingTop: spacing(5) }}>
            <HeroHeading>{headingText}</HeroHeading>
            <HeroText>{subHeaderText}</HeroText>
            {tertiaryCenterButton}
          </div>
        </IconAndTextContainter>
        <div>
          {isAdhoc ? (
            <></>
          ) : (
            primaryBottomButton ?? (
              <ReturnToPatientSummary onClick={onPrimaryBottomButtonClick} fullWidth data-testid="modal-primary-cta">
                {primaryBottomButtonText}
              </ReturnToPatientSummary>
            )
          )}
        </div>
      </DenialSavedForReviewContainer>
    </Dialog>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DenialSavedForReviewContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const IconAndTextContainter = styled("div")(({ theme }) => ({
  display: "flex",
  flexGrow: 0.8,
  justifyContent: "center",
  alignItems: "center",
  "& div": {
    margin: theme.spacing(3),
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const HeroHeading = styled(H1)(() => ({
  color: "#384358",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const HeroText = styled(H6)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "16px",
  lineHeight: "20px",
  paddingTop: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ReturnToPatientSummary = styled(SecondaryButton)(({ theme }) => ({
  padding: theme.spacing(2, 7),
}));
