import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import {
  PriorAuthRequirements,
  useDoPalCheck,
  useSetCrdLogIdAndUpdateServiceRequest,
} from "components/AuthBuilder/common";
import { Grid } from "@material-ui/core";
import {
  formatDateToISODate,
  useFeature,
  parseDateFromISOString,
  HUMANA_HEALTH_PLAN_NAME,
  useGeneralAuthSubmissionWorflowOn,
} from "@coherehealth/common";
import {
  ProcedureCode,
  Patient,
  ClinicalService,
  useGetOrders,
  ServiceRequestResponse,
  PalCheckRequestBody,
  Provider,
  CardExtensionProcedureCode,
  useUpdateServiceRequest,
  Facility,
  CardExtensionDetails,
} from "@coherehealth/core-platform-api";
import RequestorCard from "components/Requestor/RequestorCard";
import { checkOnetimeRequestCoverage, getPatientHealthPlanName } from "util/patientUtils";
import { useSnackbar } from "notistack";
import { AuthBuilderRequestorProps } from "components/AuthBuilder";
import { validateSecondaryDiagnoses } from "./DiagnosisCodesSection";
import { NonCohereCodes } from "../common";
import { ExistingOrdersModal } from "../../EHRIntegration/ExistingOrdersModal";
import { User } from "../../../UserContext";
import GeneralAuthSubmissionRequestDetails from "./GeneralAuthSubmissionRequestDetails";
import { ServiceRequestFormContent } from "../../../common/SharedServiceRequestFormComponents";

interface Props extends AuthBuilderRequestorProps {
  priorAuthRequirements: PriorAuthRequirements;
  setPriorAuthRequirements: Dispatch<SetStateAction<PriorAuthRequirements>>;
  setPalProcedureCodes: (codes: ProcedureCode[]) => void;
  setNonPalProcedureCodes: (codes: ProcedureCode[]) => void;
  setNonCohereCodes: (codesAndVendor: NonCohereCodes[]) => void;
  noPxService?: ClinicalService;
  setNoPxService: (service: ClinicalService | undefined) => void;
  setCrdLogId: Dispatch<string>;
  setCrdProcedureCodeResults: Dispatch<CardExtensionProcedureCode[] | undefined>;
  setCrdExtensionCardResults: Dispatch<CardExtensionDetails | undefined>;
  scrollToPALCheck: () => Promise<void>;
  patientData: Patient | null;
  importedEhrOrder?: ServiceRequestResponse;
  setImportedEhrOrder?: (sr: ServiceRequestResponse) => void;
  currUser?: User;
  serviceRequestFormContents?: ServiceRequestFormContent[];
  formContent?: ServiceRequestFormContent;
  setFormContent?: Dispatch<SetStateAction<ServiceRequestFormContent>>;
}

export default function AuthRequirementsForm({
  priorAuthRequirements,
  setPriorAuthRequirements,
  setPalProcedureCodes,
  setNonPalProcedureCodes,
  setNonCohereCodes,
  serviceRequestFormContents,
  noPxService,
  setNoPxService,
  setCrdLogId,
  setCrdProcedureCodeResults,
  setCrdExtensionCardResults,
  patientData,
  scrollToPALCheck,
  requestorFormAuthorized,
  importedEhrOrder,
  setImportedEhrOrder,
  currUser,
  formContent,
  setFormContent,
  ...requestorProps
}: Props) {
  const healthPlanName = getPatientHealthPlanName(patientData || undefined) || "";
  const generalAuthSubmissionWorkflowEnabled = useGeneralAuthSubmissionWorflowOn(healthPlanName);

  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  const hasValidSecondaryDiagnoses = validateSecondaryDiagnoses(priorAuthRequirements.secondaryDiagnoses);

  const startDateCoverage = useMemo(
    () =>
      patientData?.coverages && priorAuthRequirements.startDate
        ? checkOnetimeRequestCoverage(patientData.coverages, priorAuthRequirements.startDate)
        : undefined,
    [patientData?.coverages, priorAuthRequirements.startDate]
  );

  const isHumanaPatient = healthPlanName === HUMANA_HEALTH_PLAN_NAME;
  const inpatientPxOptional =
    priorAuthRequirements.encounterType === "INPATIENT"
      ? priorAuthRequirements.desiredProcedureCodes
        ? priorAuthRequirements.desiredProcedureCodes?.length <= 10 || !isHumanaPatient
        : true
      : priorAuthRequirements.desiredProcedureCodes &&
        priorAuthRequirements?.desiredProcedureCodes?.length > 0 &&
        (priorAuthRequirements?.desiredProcedureCodes?.length <= 10 || !isHumanaPatient);

  const canCheckPAL =
    !!priorAuthRequirements.primaryDiagnosis &&
    !!hasValidSecondaryDiagnoses &&
    (priorAuthRequirements.noPxServiceRequired ? !!noPxService : inpatientPxOptional) &&
    !!priorAuthRequirements.startDate &&
    !!startDateCoverage?.inRange &&
    !!priorAuthRequirements.encounterType;

  const serviceRequestId = serviceRequestFormContents?.[0]?.id;
  const { mutate: updateDraftServiceRequest } = useUpdateServiceRequest({ id: "" });
  const setCrdLogIdAndUpdateServiceRequest = useSetCrdLogIdAndUpdateServiceRequest({
    setCrdLogId,
    serviceRequestId,
    updateServiceRequest: updateDraftServiceRequest,
  });
  const { doPalCheck } = useDoPalCheck({
    canCheckPAL: canCheckPAL || false,
    noPxService,
    patientId: patientData?.id || "",
    patientData: patientData,
    scrollToPALCheck,
    setCrdLogId: setCrdLogIdAndUpdateServiceRequest,
    setCrdProcedureCodeResults,
    setCrdExtensionCardResults,
    setAttemptedSubmit,
    setNonCohereCodes,
    setNonPalProcedureCodes,
    setPalProcedureCodes,
    setPriorAuthRequirements,
    serviceRequestFormContents: serviceRequestFormContents,
  });

  const resetPalResults = useCallback(() => {
    setPalProcedureCodes([]);
    setNonPalProcedureCodes([]);
    setNoPxService(undefined);
    setNonCohereCodes([]);
  }, [setPalProcedureCodes, setNonPalProcedureCodes, setNoPxService, setNonCohereCodes]);

  //ehrModal
  const [ehrOrderModalOpen, setEhrOrderModalOpen] = useState(false);

  const ehrViewEnabled = useFeature("ehrViewEnabled");
  const shouldQueryEHRForOrders =
    currUser &&
    currUser?.ehrVendor &&
    currUser?.ehrVendor &&
    patientData?.ehrReferences &&
    patientData.ehrReferences.filter((obj) => {
      return obj.vendor === currUser?.ehrVendor && currUser?.ehrPracticeId?.toString() === obj.practiceId;
    })?.length > 0;

  const {
    data: orderData,
    loading: orderDataLoading,
    refetch: refetchOrders,
  } = useGetOrders({
    lazy: true,
    patientId:
      shouldQueryEHRForOrders && patientData?.ehrReferences
        ? patientData.ehrReferences.filter((obj) => {
            return obj.vendor === currUser?.ehrVendor && currUser?.ehrPracticeId?.toString() === obj.practiceId;
          })[0].patientId || ""
        : "",
    departmentId: currUser?.ehrDepartmentId || "",
    practiceId: currUser?.ehrPracticeId || "",
    queryParams: { orderStatus: "OPEN" },
  });

  useEffect(() => {
    if (patientData && shouldQueryEHRForOrders && ehrViewEnabled) {
      refetchOrders();
    }
  }, [ehrViewEnabled, patientData, refetchOrders, shouldQueryEHRForOrders]);

  useEffect(() => {
    if (orderDataLoading && ehrViewEnabled) {
      setEhrOrderModalOpen(true);
    }
  }, [orderDataLoading, ehrViewEnabled, setEhrOrderModalOpen]);

  useEffect(() => {
    if (orderData && orderData.length === 0 && !orderDataLoading) {
      setEhrOrderModalOpen(false);
    }
  }, [orderData, orderDataLoading, setEhrOrderModalOpen]);

  const [ehrOrderImported, setEhrOrderImported] = useState(false);

  const onOrderImport = async (sr: ServiceRequestResponse) => {
    setPriorAuthRequirements({
      ...priorAuthRequirements,
      primaryDiagnosis: sr.primaryDiagnosis,
      secondaryDiagnoses: sr.secondaryDiagnoses || [],
      desiredProcedureCodes: sr.procedureCodes || [],
      startDate: parseDateFromISOString(sr?.startDate),
      encounterType: sr.encounterType || "OUTPATIENT",
    });
    setEhrOrderModalOpen(false);
    setImportedEhrOrder?.(sr);
    setEhrOrderImported(true);
  };

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    const delayCreateRequirementsPalCheck = async () => {
      await new Promise((r) => setTimeout(r, 2000));
      await doPalCheck(priorAuthRequirements);
    };
    if (ehrOrderImported) {
      enqueueSnackbar(`Import from EHR complete`, {
        variant: "success",
        preventDuplicate: true,
      });
      if (
        importedEhrOrder?.primaryDiagnosis &&
        importedEhrOrder?.procedureCodes &&
        importedEhrOrder.procedureCodes?.length > 0 &&
        importedEhrOrder?.startDate &&
        importedEhrOrder?.encounterType
      ) {
        delayCreateRequirementsPalCheck();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ehrOrderImported, enqueueSnackbar, importedEhrOrder]);

  return (
    <Grid container spacing={5} style={{ marginBottom: 0 }}>
      {requestorFormAuthorized && (
        /* negate marginBottom in RequestorCard with negative value here */
        <Grid item xs={12} style={{ marginBottom: -40 }}>
          <RequestorCard {...requestorProps} />
        </Grid>
      )}
      {generalAuthSubmissionWorkflowEnabled && (
        <Grid item xs={12}>
          <GeneralAuthSubmissionRequestDetails
            priorAuthRequirements={priorAuthRequirements}
            setPriorAuthRequirements={setPriorAuthRequirements}
            attemptedSubmit={attemptedSubmit}
            resetPalResults={resetPalResults}
            patientData={patientData}
            doPalCheck={doPalCheck}
            formContent={formContent}
            setFormContent={setFormContent}
          />
        </Grid>
      )}

      <ExistingOrdersModal
        open={ehrOrderModalOpen}
        onModalClose={() => setEhrOrderModalOpen(false)}
        serviceRequests={orderData || []}
        onOrderImport={onOrderImport}
        loading={orderDataLoading}
      />
    </Grid>
  );
}

export function getPayloadForPALCheck(
  priorAuthRequirements: PriorAuthRequirements,
  patientId: string
): PalCheckRequestBody {
  let startDate = formatDateToISODate(priorAuthRequirements.startDate);
  return {
    procedureCodes: priorAuthRequirements.desiredProcedureCodes?.map((code) => code?.code),
    patientId,
    startDate: startDate,
    encounterType: priorAuthRequirements.encounterType,
    isOONExceptionRequest: priorAuthRequirements.userSelectedOutOfNetworkException,
  };
}

export function getPayloadForCRDCheck(
  priorAuthRequirements: PriorAuthRequirements,
  patientData: Patient | null,
  serviceRequestFormContent: ServiceRequestFormContent | undefined
): any {
  const encounterTypeToClassSystem = {
    INPATIENT: "IMP",
    OUTPATIENT: "AMB",
  };
  const encounterTypeToSentenceCase = {
    INPATIENT: "Inpatient",
    OUTPATIENT: "Outpatient",
  };
  const primaryDiagnosesCodes = {
    system: priorAuthRequirements.primaryDiagnosis?.system,
    code: priorAuthRequirements.primaryDiagnosis?.code,
    display: priorAuthRequirements.primaryDiagnosis?.description,
  };
  const secondaryDiagnosesCodes = priorAuthRequirements.secondaryDiagnoses?.map((code) => ({
    system: code?.system,
    code: code?.code,
    display: code?.description,
  }));
  const diagnosesCodes = secondaryDiagnosesCodes
    ? [primaryDiagnosesCodes, ...secondaryDiagnosesCodes]
    : [primaryDiagnosesCodes];
  const crdRequestPayload: any = {
    resourceType: "Bundle",
    entry: [
      {
        resource: {
          resourceType: "Patient",
          identifier: [
            {
              type: {
                text: "Member ID",
              },
              value: patientData?.memberId,
            },
          ],
          name: [
            {
              given: [patientData?.firstName],
            },
          ],
          birthDate: patientData?.dateOfBirth,
        },
      },
      {
        resource: {
          resourceType: "Encounter",
          class: {
            system: encounterTypeToClassSystem[priorAuthRequirements?.encounterType!],
          },
          period: {
            start: priorAuthRequirements?.startDate,
          },
        },
      },
      {
        resource: {
          resourceType: "Practitioner",
          id: serviceRequestFormContent?.selectedOrderingProvider?.id,
          identifier: [
            {
              type: {
                text: "TIN",
              },
              value: serviceRequestFormContent?.selectedOrderingProvider?.selectedLocation?.tin,
            },
            {
              type: {
                text: "NPI",
              },
              value: serviceRequestFormContent?.selectedOrderingProvider?.npi,
            },
          ],
          name: [
            {
              use: "official",
              text: serviceRequestFormContent?.selectedOrderingProvider?.name,
            },
          ],
          address: [
            {
              line: [
                serviceRequestFormContent?.selectedOrderingProvider?.selectedLocation?.address?.line1,
                serviceRequestFormContent?.selectedOrderingProvider?.selectedLocation?.address?.line2,
              ],
              city: serviceRequestFormContent?.selectedOrderingProvider?.selectedLocation?.address?.city,
              state: serviceRequestFormContent?.selectedOrderingProvider?.selectedLocation?.address?.state,
              postalCode: serviceRequestFormContent?.selectedOrderingProvider?.selectedLocation?.address?.zipCode,
            },
          ],
        },
      },
      {
        resource: {
          physicalType: {
            coding: [
              {
                system: "http://hit.coherehealth.com/placeOfService",
                code: serviceRequestFormContent?.placeOfService?.code,
              },
            ],
            text: serviceRequestFormContent?.placeOfService?.name,
          },
          resourceType: "Location",
          id: serviceRequestFormContent?.selectedFacility?.id,
          identifier: [
            {
              type: {
                text: "TIN",
              },
              value: serviceRequestFormContent?.selectedFacility?.selectedLocation?.tin,
            },
            {
              type: {
                text: "NPI",
              },
              value: serviceRequestFormContent?.selectedFacility?.npi,
            },
          ],
          name: serviceRequestFormContent?.selectedFacility?.selectedLocation?.name,
          address: {
            line: [
              serviceRequestFormContent?.selectedFacility?.selectedLocation?.address?.line1,
              serviceRequestFormContent?.selectedFacility?.selectedLocation?.address?.line2,
            ],
            city: serviceRequestFormContent?.selectedFacility?.selectedLocation?.address?.city,
            state: serviceRequestFormContent?.selectedFacility?.selectedLocation?.address?.state,
            postalCode: serviceRequestFormContent?.selectedFacility?.selectedLocation?.address?.zipCode,
          },
        },
      },
      {
        resource: {
          resourceType: "Claim",
          id: serviceRequestFormContent?.id,
          item: [
            {
              detail: [
                {
                  sequence: 1,
                  category: {
                    coding: [
                      {
                        system: "http://coherehealth.com/benefitCategory",
                        code: serviceRequestFormContent?.authSubcategory
                          ? serviceRequestFormContent?.authSubcategory
                          : serviceRequestFormContent?.authCategory?.name,
                      },
                    ],
                    text: serviceRequestFormContent?.authCategory?.name,
                  },
                },
              ],
            },
          ],
          type: {
            coding: [
              {
                system: "http://test.payor.com/authTypes",
                code: encounterTypeToSentenceCase[priorAuthRequirements?.encounterType!],
              },
            ],
          },
          provider: {
            reference: `Practitioner/${serviceRequestFormContent?.selectedOrderingProvider?.id}`,
          },
          careTeam: [
            {
              sequence: 1,
              provider: {
                reference: serviceRequestFormContent?.selectedPerformingProvider?.id
                  ? `Practitioner/${serviceRequestFormContent?.selectedPerformingProvider?.id}`
                  : null,
              },
              responsible: true,
            },
          ],
          supportingInfo: outOfNetworkPayloadGenerator(Boolean(serviceRequestFormContent?.userSelectedOONException)),
        },
      },
      {
        resource: {
          resourceType: "Condition",
          code: {
            coding: [...diagnosesCodes],
          },
        },
      },
    ],
  };

  if (serviceRequestFormContent?.selectedPerformingProvider) {
    crdRequestPayload.entry.push({
      resource: generatePractitionerResource(serviceRequestFormContent?.selectedPerformingProvider),
    });
  }
  function generatePractitionerResource(providerData: Provider) {
    return {
      resourceType: "Practitioner",
      id: providerData?.id,
      identifier: [
        {
          type: {
            text: "TIN",
          },
          value: providerData?.selectedLocation?.tin,
        },
        {
          type: {
            text: "NPI",
          },
          value: providerData?.npi,
        },
      ],
      address: [
        {
          line: [providerData?.selectedLocation?.address?.line1, providerData?.selectedLocation?.address?.line2],
          city: providerData?.selectedLocation?.address?.city,
          state: providerData?.selectedLocation?.address?.state,
          postalCode: providerData?.selectedLocation?.address?.zipCode,
        },
      ],
      name: [
        {
          use: "official",
          text: providerData?.name,
        },
      ],
    };
  }

  function generateLocationResource(facilityData: Facility) {
    return {
      resourceType: "Location",
      id: facilityData?.id,
      identifier: [
        {
          type: {
            text: "TIN",
          },
          value: facilityData?.selectedLocation?.tin,
        },
        {
          type: {
            text: "NPI",
          },
          value: facilityData?.npi,
        },
      ],
      name: facilityData?.selectedLocation?.name,
      address: {
        line: [facilityData?.selectedLocation?.address?.line1, facilityData?.selectedLocation?.address?.line2],
        city: facilityData?.selectedLocation?.address?.city,
        state: facilityData?.selectedLocation?.address?.state,
        postalCode: facilityData?.selectedLocation?.address?.zipCode,
      },
    };
  }

  const procedureCodes = priorAuthRequirements.desiredProcedureCodes?.map((code) => ({
    resource: {
      resourceType: "Procedure",
      code: {
        coding: [
          {
            system: code?.system,
            code: code?.code,
          },
        ],
      },
    },
  }));

  if (procedureCodes) {
    crdRequestPayload.entry.push(...procedureCodes);
  }

  if (serviceRequestFormContent?.selectedPerformingProviderPractice) {
    crdRequestPayload.entry.push({
      resource: generateLocationResource(serviceRequestFormContent?.selectedPerformingProviderPractice),
    });

    const claim = (crdRequestPayload.entry as Array<any>).find(
      (entry) => (entry as any)?.resource?.resourceType === "Claim"
    );
    claim?.resource?.item?.push({
      locationReference: {
        reference: `Location/${serviceRequestFormContent?.selectedPerformingProviderPractice?.id}`,
      },
    });
  }

  if (
    serviceRequestFormContent?.additionalCareParticipants &&
    serviceRequestFormContent?.additionalCareParticipants.length > 0
  ) {
    const additionalCareParticipant = serviceRequestFormContent.additionalCareParticipants[0];
    const claim = (crdRequestPayload.entry as Array<any>).find(
      (entry) => (entry as any)?.resource?.resourceType === "Claim"
    );
    const isAcpProvider = additionalCareParticipant?.configuredEntityType === "PROVIDER";
    if (isAcpProvider) {
      crdRequestPayload.entry.push({
        resource: generatePractitionerResource(additionalCareParticipant),
      });
    } else {
      crdRequestPayload.entry.push({
        resource: generateLocationResource(additionalCareParticipant),
      });
    }
    const referenceProviderType = isAcpProvider ? `Practitioner` : `Location`;
    claim?.resource?.careTeam?.push({
      sequence: 2,
      provider: {
        reference: additionalCareParticipant.id ? `${referenceProviderType}/${additionalCareParticipant.id}` : null,
      },
      responsible: false,
    });
  }
  return crdRequestPayload;
}

const outOfNetworkPayloadGenerator = (isOutOfNetwork: boolean) =>
  isOutOfNetwork
    ? [
        {
          sequence: 1,
          category: {
            coding: [
              {
                system: "http://test.payor.com/informationTypes",
                code: "exception",
              },
            ],
            text: "out-of-network",
          },
        },
      ]
    : undefined;
