import React, {
  FunctionComponent,
  ComponentProps,
  ReactText,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import AppHeader from "./AppHeader";
import retry from "p-retry";
import AppProviders from "AppProviders";
import CircularProgress from "@material-ui/core/CircularProgress";
import LicenseAgreement from "./LicenseAgreement";
import Paper from "@material-ui/core/Paper";
import addYears from "date-fns/addYears";
import { Helmet } from "react-helmet-async";
import { RouteProps } from "react-router-dom";
import { Security as OktaSecurity } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { appHeaderHeight } from "util/StyleConstants";
import { useTheme } from "@material-ui/core/styles";
import { environmentAbbreviation, compareLocalToOktaUser } from "@coherehealth/common";
import { useAuth } from "hooks/useAuth";
import { setUser, warn } from "logger";
import { useSnackbar } from "notistack";
import { useGetAccessToken } from "@coherehealth/common";
import SSORedirectComponent from "SSORedirectComponent";
import { User, UserContext } from "UserContext";
import LogRocket from "logrocket";
import { useMediaQuery } from "@material-ui/core";
import { useRerender, useTimeoutEffect } from "@react-hookz/web";

interface Appcues {
  identify: (id: string, properties: Record<string, string | boolean | number>) => void;
  group: (id: string, properties: Record<string, string | boolean | number>) => void;
}
declare global {
  interface Window {
    Appcues: Appcues;
  }
}

export const Security =
  process.env.REACT_APP_OKTA_ENABLED === "false"
    ? ({ oktaAuth, restoreOriginalUri, ...passthrough }: ComponentProps<typeof OktaSecurity>) => (
        <div {...passthrough} />
      )
    : OktaSecurity;

const oktaEnabled = process.env.REACT_APP_OKTA_ENABLED !== "false";

function trackingGroupName(groups: string[]) {
  const realGroups = groups.filter((g) => g && g !== "Everyone");
  if (realGroups.length === 0) {
    return "none";
  }
  // We should never actually see more than one group on a user, nor should we see
  // no groups, but this allows for paranoia
  return realGroups.sort().join(";");
}

interface SecureRouteProps extends RouteProps {
  hideAppBar?: boolean;
  useSlimHeader?: boolean;
  initializeLogRocket?: boolean;
  logRocketDelay?: number;
}

export function SecureRoute({
  hideAppBar,
  useSlimHeader,
  initializeLogRocket,
  logRocketDelay,
  ...props
}: SecureRouteProps) {
  const { authState, oktaAuth } = useAuth();
  const [userPromise, setUserPromise] = useState<Promise<User>>();
  const theme = useTheme();

  useEffect(() => {
    if (authState?.isAuthenticated) {
      if (userPromise === undefined) {
        setUserPromise(() => retry(() => oktaAuth.getUser(), { retries: 5 }));
      }
    }
  }, [oktaAuth, userPromise, authState?.isAuthenticated]);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      userPromise?.then((user) => {
        compareLocalToOktaUser(user?.sub, oktaAuth);
      });
    }
  }, [authState?.isAuthenticated, userPromise, oktaAuth]);

  useEffect(() => {
    let active = true;
    if (authState?.isAuthenticated) {
      userPromise?.then((result: any) => {
        if (!active || !LogRocket) {
          return;
        }
        if (initializeLogRocket) {
          //check user here to make sure LogRocket recording is not initialized for cypress tests.
          const regExp = /engineering\+cypress/;
          if (regExp.test(result?.email ?? "")) {
            console.warn("LogRocket initialization skipped because Cypress bots are in use.");
            return;
          }
          const logRocketId = process.env.REACT_APP_LOGROCKET_APP_ID ?? null;
          //use sessionURL to detect if LogRocket init has been called (initialized). although sessionURL is nullable, it will return string with error message instead of null when called before init so check contents for valid url which will contain logRocketId
          const isLogRocketInitialized =
            LogRocket.sessionURL && LogRocket.sessionURL.includes(logRocketId ?? "https://app.logrocket.com");
          if (!isLogRocketInitialized) {
            const isEnvironmentUsingLogRocket: boolean = process.env.REACT_APP_LOGROCKET_INTEGRATION === "true";
            //to try and keep recording sessions below quotas, only a portion of sessions will be recorded
            const randomNumber = Math.random();
            //currently only ~60% of sessions will be recorded. Update below to increase/decrease sessions being recorded
            if (isEnvironmentUsingLogRocket && logRocketId && randomNumber < 0.6) {
              //set identity of user in current recording
              LogRocket.identify(result?.sub, {
                email: result?.email,
                full_name: result?.name,
                given_name: result?.given_name,
                family_name: result?.family_name,
                role: result?.roles,
                opsGroup: result?.opsGroup,
              });
              //check if data (labels, inputs) in recordings should be obfuscated based off environment variable
              const initOptions =
                process.env.REACT_APP_LOGROCKET_INTEGRATION_DESANITIZATION === "true"
                  ? {}
                  : {
                      dom: {
                        inputSanitizer: true,
                        textSanitizer: true,
                      },
                      network: {
                        isEnabled: false,
                      },
                    };
              //delay init call to not record short sessions and to cutout initial loading/login within recordings
              const initDelay = logRocketDelay ?? 1000;
              setTimeout(() => {
                LogRocket.init(logRocketId, initOptions);
              }, initDelay);
            }
          }
        }
      });
    }
    return () => {
      active = false;
    };
  }, [authState?.isAuthenticated, userPromise, initializeLogRocket, logRocketDelay]);

  const appcues = window.Appcues;
  useEffect(() => {
    let active = true;
    if (authState?.isAuthenticated) {
      userPromise?.then((result: any) => {
        if (!active) {
          return;
        }
        setUser({
          id: result?.sub,
          email: result?.email,
        });

        if (!appcues) {
          return;
        }
        const accountName = trackingGroupName(result?.groups || []);
        const env = environmentAbbreviation();
        const regExp = /engineering\+cypress/;
        if (regExp.test(result?.email)) {
          console.warn("Appcues initialization skipped because Cypress bots are in use.");
          return;
        }
        appcues.identify(result?.sub, {
          email: result?.email,
          full_name: result?.name,
          given_name: result?.given_name,
          family_name: result?.family_name,
          role: result?.roles,
          opsGroup: result?.opsGroup,
        });
        appcues.group(`${accountName}-${env}`, {
          name: `${accountName}-${env}`,
        });
      });
    }
    return () => {
      active = false;
    };
  }, [authState?.isAuthenticated, userPromise, appcues]);

  const [eulaAttestation, setEulaAttestation] = React.useState<"NEEDED" | "UNKNOWN" | "COMPLETE">("UNKNOWN");

  useEffect(() => {
    if (authState?.isAuthenticated) {
      userPromise?.then((user) => {
        if (user) {
          if (user.agreementSignedAt && addYears(new Date(user.agreementSignedAt), 1) > new Date()) {
            setEulaAttestation("COMPLETE");
          } else {
            setEulaAttestation("NEEDED");
          }
        }
      });
    }
  }, [authState?.isAuthenticated, userPromise]);
  const getAccessToken = useGetAccessToken(oktaAuth);
  const getUser = useCallback(() => userPromise, [userPromise]);

  // Checks if the screen width is 1024px or larger
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up(1024));

  // Checks if the screen width is 200px or larger
  const isVerySmallScreen = useMediaQuery(theme.breakpoints.up(200));

  return (
    <AppProviders getAccessToken={getAccessToken} getUser={getUser}>
      <Helmet>
        <title>Cohere | Patient Authorizations</title>
      </Helmet>

      {!hideAppBar && !!authState?.isAuthenticated && <AppHeader />}
      {eulaAttestation === "UNKNOWN" && <ApplicationLoading />}
      {eulaAttestation === "NEEDED" && <LicenseAgreement />}
      <Paper
        style={{
          backgroundColor: theme.palette.background.default,
          minHeight: "100%",
          paddingTop: useSlimHeader ? 0 : appHeaderHeight(isDesktopScreen, isVerySmallScreen),
          ...(!!authState?.isAuthenticated && eulaAttestation === "COMPLETE"
            ? { visibility: "visible" }
            : { visibility: "hidden", display: "none" }),
        }}
        elevation={0}
      >
        <SSORedirectComponent getAccessToken={getAccessToken}>
          {oktaEnabled ? <RequireAuth>{props.children}</RequireAuth> : props.children}
        </SSORedirectComponent>
      </Paper>
    </AppProviders>
  );
}

/**
 * Only show a full-page loading indicator if it's taking a weirdly long time
 */
const UNUSUALLY_LONG_LOAD_MS = 3000;
const EXTRAORDINARILY_LONG_LOAD_MS = 12000;

const ApplicationLoading = () => {
  const rerender = useRerender();
  const showProgress = useTimeoutEffect(() => {
    rerender();
  }, UNUSUALLY_LONG_LOAD_MS);

  const [openSnackbar, setOpenSnackbar] = useState<ReactText>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useTimeoutEffect(() => {
    const key = enqueueSnackbar(<LongLoadTimeMessage />, { variant: "warning", persist: true, preventDuplicate: true });
    setOpenSnackbar(key);
    warn(`Unusually long load time`);
  }, EXTRAORDINARILY_LONG_LOAD_MS);

  // Clear the snackbar on unmount
  useEffect(() => {
    return () => {
      if (openSnackbar) {
        closeSnackbar(openSnackbar);
      }
    };
  }, [openSnackbar, closeSnackbar]);

  return (
    <div style={{ display: "flex", height: "100%", width: "100%", alignItems: "center", justifyContent: "center" }}>
      {showProgress && <CircularProgress />}
    </div>
  );
};

const LongLoadTimeMessage: FunctionComponent = () => (
  <div>
    It's taking an unusually long time to load.
    <br />
    Try refreshing the page, or use the My Account menu to log out, then log back in again.
  </div>
);

const RequireAuth: FunctionComponent = ({ children }) => {
  const { oktaAuth, authState } = useAuth();

  const [loading, setLoading] = useState<boolean>(true);
  const { getUser } = useContext(UserContext);
  useEffect(() => {
    getUser?.()?.then((user) => {
      if (user) {
        setLoading(false);
      }
    });
  }, [getUser]);
  const originalUri = toRelativeUrl(window.location.href, window.location.origin);

  if (authState && !authState.isAuthenticated) {
    if (!authState.isPending) {
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
    return null;
  } else if (!authState) {
    return null;
  }

  return <>{loading ? <CircularProgress /> : children}</>;
};
