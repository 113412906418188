import React from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { OrderRow } from "./OrderRow";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@material-ui/icons/Info";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { Body2, colorsLight } from "@coherehealth/common";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { AuthHeaderType } from "./ExistingOrdersModal";

type headerColor = "success" | "warning" | "disabled";

interface Props {
  serviceRequests?: ServiceRequestResponse[];
  type: AuthHeaderType;
  onOrderImport: (sr: ServiceRequestResponse) => void;
}

function useHeaderColor(color: string) {
  if (color === "success") {
    return {
      color: colorsLight.success.main,
    };
  } else if (color === "warning") {
    return {
      color: colorsLight.warning.dark,
    };
  } else {
    return {
      color: colorsLight.font.secondary,
    };
  }
}

type AuthHeaderProps = {
  color: headerColor;
  icon: JSX.Element;
  title: string;
};

export const OrderRowContainer = ({ serviceRequests, type, onOrderImport }: Props) => {
  const AuthHeaderConfig: Record<AuthHeaderType, AuthHeaderProps> = {
    AUTH_REQUIRED_BY_COHERE: {
      color: "success",
      icon: <CohereAuthorizedIcon />,
      title: "Requires submission through Cohere",
    },
    AUTH_REQUIRED_BY_VENDOR: {
      color: "warning",
      icon: <VendorAuthorizedIcon />,
      title: "Requires submission through another organization",
    },
    INDETERMINATE: {
      color: "disabled",
      icon: <QuestionMarkIcon />,
      title: "Import to check authorization requirements",
    },
    AUTH_NOT_REQUIRED: {
      color: "disabled",
      icon: <NotAuthorizedIcon />,
      title: "Does not require submission",
    },
  };

  const authHeaderConfig = AuthHeaderConfig[type];

  return (
    <Grid container style={{ marginBottom: "16px" }}>
      <ContainerHeader item xs={12} style={useHeaderColor(authHeaderConfig.color)}>
        {authHeaderConfig.icon}
        <HeaderText>{authHeaderConfig.title}</HeaderText>
      </ContainerHeader>
      {serviceRequests?.map((sr, idx) => (
        <OrderRow
          key={`order-${idx}`}
          onImportServiceRequest={async () => {
            await onOrderImport(sr);
          }}
          diagnosisCode={sr.primaryDiagnosis?.code}
          serviceStartDate={sr.startDate}
          procedureCodes={sr.procedureCodes}
          patient={sr.patient}
          activeCoverage={sr.palCheck?.activeCoverage}
          authNeeded={type !== "AUTH_NOT_REQUIRED"}
          orderingProvider={sr.orderingProvider?.name}
        />
      ))}
    </Grid>
  );
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CohereAuthorizedIcon = styled(SuccessIcon)(({ theme }) => ({
  fontSize: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const VendorAuthorizedIcon = styled(InfoIcon)(({ theme }) => ({
  fontSize: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const NotAuthorizedIcon = styled(DoNotDisturbIcon)(({ theme }) => ({
  fontSize: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const QuestionMarkIcon = styled(HelpOutlineIcon)(({ theme }) => ({
  fontSize: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ContainerHeader = styled(Grid)({
  display: "flex",
  alignItems: "center",
  padding: "8px 0px !important",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const HeaderText = styled(Body2)({
  marginLeft: "8px",
});
