import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useReducer, useState } from "react";
import { SubQuestionVisibility } from "util/clinicalAssessment";
import {
  Action as ClinicalAssessmentAction,
  clinicalAssessmentReducer,
  State as ClinicalAssessmentState,
} from "./ClinicalAssessmentReducer";

export type SubQuestionVisibilityState = SubQuestionVisibility[] | undefined;
interface ClinicalAssessmentContextType {
  clinicalAssessment: ClinicalAssessmentState;
  updateClinicalAssessment: Dispatch<ClinicalAssessmentAction>;
  subQuestions: SubQuestionVisibilityState;
  updateSubQuestions: Dispatch<SetStateAction<SubQuestionVisibilityState>>;
}

const initialClinicalAssessmentContext: ClinicalAssessmentContextType = {
  clinicalAssessment: undefined,
  updateClinicalAssessment: () => {},
  subQuestions: undefined,
  updateSubQuestions: () => {},
};

const ClinicalAssessmentContext = createContext<ClinicalAssessmentContextType>(initialClinicalAssessmentContext);

export interface ClinicalAssessmentContextProviderProps {
  initialClinicalAssessment?: ClinicalAssessmentState;
  initialSubQuestions?: SubQuestionVisibilityState;
}

export const useClinicalAssessmentReducer = ({ initialClinicalAssessment }: ClinicalAssessmentContextProviderProps) =>
  useReducer(clinicalAssessmentReducer, initialClinicalAssessment);

export const ClinicalAssessmentContextProvider = ({
  children,
  ...reducerProps
}: PropsWithChildren<ClinicalAssessmentContextProviderProps>) => {
  const [clinicalAssessment, updateClinicalAssessment] = useClinicalAssessmentReducer(reducerProps);
  const [subQuestions, updateSubQuestions] = useState(reducerProps.initialSubQuestions);

  return (
    <ClinicalAssessmentContext.Provider
      value={{ clinicalAssessment, updateClinicalAssessment, subQuestions, updateSubQuestions }}
    >
      {children}
    </ClinicalAssessmentContext.Provider>
  );
};

export default ClinicalAssessmentContext;
