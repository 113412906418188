import React from "react";

import PrintIcon from "@material-ui/icons/Print";
import { formatDateStr } from "@coherehealth/common";

import { FaxAttachmentStatus } from "../checkStatusApi";
import { print } from "./ServiceRequestResult";
import FaxResultImage from "./FaxResultImage";

import StatusSummaryPdfProvider from "../Printout/StatusSummaryPdfProvider";

interface Props {
  result: FaxAttachmentStatus;
}

export default function FaxResult({ result }: Props) {
  return (
    <div className="UnauthorizedCheckStatusResultCard-card">
      <FaxResultImage aria-label="We received your fax" />
      <div className="UnauthorizedCheckStatusResultCard-textcontainer">
        <div className="UnauthorizedCheckStatusResultCard-header">
          <h1 className="UnauthorizedCheckStatusResultCard-title UnauthorizedCheckStatusResultCard-warning">
            Fax received:&nbsp;
            <span className="UnauthorizedCheckStatusResultCard-subtitle">We will be processing it soon</span>
          </h1>
          <StatusSummaryPdfProvider statusResult={result}>
            {({ url }) =>
              Boolean(url) && (
                <button className="UnauthorizedCheckStatusResultCard-print-button" onClick={() => print(url)}>
                  <PrintIcon className="UnauthorizedCheckStatusResultCard-print-icon" />
                  Print
                </button>
              )
            }
          </StatusSummaryPdfProvider>
        </div>
        <div className="UnauthorizedCheckStatusResultCard-text-table">
          <div className="UnauthorizedCheckStatusResultCard-text-column">
            <div className="UnauthorizedCheckStatusResultCard-text-columnheader">Received date</div>
            <div className="UnauthorizedCheckStatusResultCard-text-columndata">
              {!result.dateCreated ? "Unknown" : formatDateStr(result.dateCreated)}
            </div>
          </div>

          <div className="UnauthorizedCheckStatusResultCard-text-column">
            <div className="UnauthorizedCheckStatusResultCard-text-columnheader">Tracking #</div>
            <div className="UnauthorizedCheckStatusResultCard-text-columndata">{result.cohereId}</div>
          </div>

          <div className="UnauthorizedCheckStatusResultCard-text-column">
            <div className="UnauthorizedCheckStatusResultCard-text-columnheader">Authorization #</div>
            <div className="UnauthorizedCheckStatusResultCard-text-columndata UnauthorizedCheckStatusResultCard-warning">
              Not available
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
