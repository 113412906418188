import React, { useState, useRef } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress, { LinearProgressProps } from "@material-ui/core/LinearProgress";
import Tooltip from "@material-ui/core/Tooltip";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import Zoom from "@material-ui/core/Zoom";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";
import {
  Attachment,
  AttachmentClassification,
  usePostMachineLearningBooleanFeedback,
} from "@coherehealth/core-platform-api";
import { colorsLight, Body2, Caption } from "@coherehealth/common";

interface Props {
  attachment: Attachment;
  classificationResults?: AttachmentClassification[];
}

type Timeout = ReturnType<typeof setTimeout>;

export default function ClassificationIndicator({ attachment, classificationResults }: Props) {
  const showClassificationResults = Boolean(classificationResults);
  const classificationResultForThisAttachment = classificationResults?.find(
    (result) => result.systemObject.systemId === attachment.id
  );

  const { mutate: postFeedback, loading: feedbackLoading } = usePostMachineLearningBooleanFeedback({});
  const [savedFeedbackResult, setSavedFeedbackResult] = useState<boolean>();

  // Whenever the mouse or focus is on either the main Button or on the child IconButtons
  // that open up when it's engaged, keep the feedback panel open, and give it a bit before closing.
  // This can be tricky, but the way we're doing it here is by setting a timeout to close
  // on disengaging any of them, but clearing that when reengaging.
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const closeTimeoutRef = useRef<Timeout>();

  const onFocus = () => {
    setFeedbackOpen(true);
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
  };
  const onFocusLeave = () => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
    const newTimeout = setTimeout(() => {
      setFeedbackOpen(false);
    }, 500);
    closeTimeoutRef.current = newTimeout;
  };

  if (!classificationResultForThisAttachment || !showClassificationResults) {
    return null;
  }

  const percent = (classificationResultForThisAttachment?.probabilityIsClinicalNote || 0) * 100;
  return (
    <RelativeContainer>
      <Tooltip
        title={`Based on comparison with other attachments, we think there is a ${percent}% chance this is a clinical note.`}
      >
        <Button onFocus={onFocus} onMouseEnter={onFocus} onBlur={onFocusLeave} onMouseLeave={onFocusLeave}>
          <LinearProgressWithLabel variant="determinate" value={percent} />
        </Button>
      </Tooltip>
      <PopupButtonsContainer onFocus={onFocus} onMouseEnter={onFocus} onBlur={onFocusLeave} onMouseLeave={onFocusLeave}>
        <CenteringZoom in={feedbackOpen} unmountOnExit>
          <div>
            <Tooltip title="This looks like a Clinical note">
              <IconButton
                style={{ color: savedFeedbackResult === true ? colorsLight.success.main : "inherit" }}
                disabled={feedbackLoading}
                onClick={async () => {
                  await postFeedback({
                    isPositiveResult: true,
                    classificationResult: classificationResultForThisAttachment,
                    systemObject: classificationResultForThisAttachment.systemObject,
                  });
                  setSavedFeedbackResult(true);
                }}
              >
                <ThumbUpIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="This does not look like a Clinical note">
              <IconButton
                disabled={feedbackLoading}
                style={{ color: savedFeedbackResult === false ? colorsLight.error.main : "inherit" }}
                onClick={async () => {
                  await postFeedback({
                    isPositiveResult: false,
                    classificationResult: classificationResultForThisAttachment,
                    systemObject: classificationResultForThisAttachment.systemObject,
                  });
                  setSavedFeedbackResult(false);
                }}
              >
                <ThumbDownIcon />
              </IconButton>
            </Tooltip>
          </div>
        </CenteringZoom>
      </PopupButtonsContainer>
    </RelativeContainer>
  );
}

// This is an arbitrary number at which we're making a cutoff between showing "this looks favorable"
// vs. "this looks unfavorable"
const CLINICAL_NOTE_PERCENT_PROBABILITY_THRESHOLD = 60;

// https://material-ui.com/components/progress/#LinearWithValueLabel.tsx
function LinearProgressWithLabel(props: LinearProgressProps) {
  // Default to color prop passed in; otherwise infer from value
  const color =
    props.color ?? ((props.value || 0) > CLINICAL_NOTE_PERCENT_PROBABILITY_THRESHOLD ? "primary" : "secondary");

  return (
    <Box display="flex" flexDirection="column">
      <Caption>Is this a clinical note?</Caption>
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress color={color} variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Body2 color="textSecondary">{`${Math.round(props.value || 0)}%`}</Body2>
        </Box>
      </Box>
    </Box>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RelativeContainer = styled("div")({ position: "relative" });

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PopupButtonsContainer = styled("div")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 24,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CenteringZoom = styled(Zoom)({ display: "flex", justifyContent: "center" });
