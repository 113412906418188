import {
  AuthStatus,
  ReviewOutcomeWithdrawOption,
  ServiceRequestResponse,
  ServiceRequestSearchResponse,
} from "@coherehealth/core-platform-api";
import {
  ChangeStatusFormState,
  getWithdrawalReasonDropdownOptionID,
  Row,
  withdrawalReasonDropdownOptionChangeHandler,
} from "../utils";
import { SingleSelectDropdown, RadioGroup, useFeature } from "@coherehealth/common";
import { useAuthorized } from "authorization";
import EnhancedWithdrawalReasonForm from "../EnhancedWithdrawalReasonForm/EnhancedWithdrawalReasonForm";
import { WithdrawnModalFooter } from "components/ServiceRequest/ReviewSection/ReviewFooter/WithdrawnModalFooter";

interface NegationStatusFormProps {
  setFormState: (value: React.SetStateAction<ChangeStatusFormState>) => void;
  formState: ChangeStatusFormState;
  nextNegationStatus?: AuthStatus;
  persistedServiceRequest: ServiceRequestResponse | null;
  selectedServiceRequest: ServiceRequestResponse | ServiceRequestSearchResponse;
  withdrawnReasonSelectOptions: () => ReviewOutcomeWithdrawOption[];
}

export default function NegationStatusForm(props: NegationStatusFormProps): JSX.Element {
  const {
    formState,
    nextNegationStatus,
    persistedServiceRequest,
    selectedServiceRequest,
    setFormState,
    withdrawnReasonSelectOptions,
  } = props;

  const reviewerWithdrawalModalEnhanced = useFeature("reviewerWithdrawalModalEnhanced");
  const canViewEnhancedWithdrawalModal = useAuthorized("VIEW_ENHANCED_WITHDRAWAL_MODAL");
  const canViewAllWithdrawnReasons = useAuthorized("VIEW_ALL_WITHDRAWAL_REASONS");

  const onWithdrawnReasonChange = (reason: string) => {
    withdrawalReasonDropdownOptionChangeHandler({
      dropdownEntryId: reason,
      setFormState: setFormState,
      withdrawalOptions: withdrawnReasonSelectOptions(),
    });
  };

  const getSingleDropdownWithdrawnReasonOptions = (): ReviewOutcomeWithdrawOption[] => {
    const withdrawOptions = withdrawnReasonSelectOptions();
    // The configuration currently supports withdrawal reasons for users that visualize the new user experience
    // and users that will continue visualizing the old user experience, so we need to filter those options
    // accordingly.
    let filteredWithdrawOptions: ReviewOutcomeWithdrawOption[] = withdrawOptions?.filter(
      (withdrawOption) => !Boolean(withdrawOption.withdrawalRequestorId)
    );
    return filteredWithdrawOptions;
  };

  return reviewerWithdrawalModalEnhanced && canViewEnhancedWithdrawalModal ? (
    <>
      <EnhancedWithdrawalReasonForm
        value={getWithdrawalReasonDropdownOptionID({
          withdrawalRequestorId: formState.withdrawVoid?.requester,
          id: formState.withdrawVoid?.reason ?? "",
        })}
        onChange={onWithdrawnReasonChange}
        serviceRequest={selectedServiceRequest ?? persistedServiceRequest}
      />
    </>
  ) : (
    <>
      {(!canViewEnhancedWithdrawalModal || !reviewerWithdrawalModalEnhanced) && (
        <>
          <div style={{ marginBottom: "8px" }}>
            <SingleSelectDropdown
              fullWidth
              label="Select reason for withdrawal"
              options={getSingleDropdownWithdrawnReasonOptions()}
              value={formState.withdrawVoid?.reason || ""}
              onChange={onWithdrawnReasonChange}
              maxMenuHeight={200}
              data-testid="withdrawal-reason-select-dropdown"
            />
          </div>
          {(selectedServiceRequest.withdrawRequestorOption || persistedServiceRequest?.withdrawRequestorOption) &&
            nextNegationStatus === "WITHDRAWN" &&
            formState.withdrawVoid.reason === "SERVICE_NO_LONGER_REQUIRED" && (
              <Row style={{ textAlign: "start", paddingTop: 0 }}>
                <RadioGroup
                  label="Select who requested the withdrawal"
                  options={[
                    { id: "PATIENT", label: "Patient" },
                    { id: "PROVIDER", label: "Provider" },
                  ]}
                  value={formState.withdrawVoid?.requester}
                  onChange={(requester) => {
                    setFormState((prevFormState) => ({
                      ...prevFormState,
                      withdrawVoid: {
                        ...prevFormState.withdrawVoid,
                        requester,
                      },
                    }));
                  }}
                  data-testid="withdrawal-requestor-radio-group"
                />
              </Row>
            )}
          {formState?.withdrawVoid?.reason && (
            <WithdrawnModalFooter
              reviewerWithdrawalModalEnhanced={reviewerWithdrawalModalEnhanced}
              nextState={nextNegationStatus}
              canViewAllWithdrawnReasons={canViewAllWithdrawnReasons}
            />
          )}
        </>
      )}
    </>
  );
}
