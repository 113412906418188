import React, { useState } from "react";

import { H1, PrimaryButton, formatDateToISODate } from "@coherehealth/common";
import { Grid } from "@material-ui/core";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";

import { useAuthorized } from "../../../authorization";
import IntegrationBulkFunctionModal from "../../IntegrationBulkFunctionModal";
import {
  useBulkMarkIntegrationStatus,
  IntegrationMarkIndicator,
  useRetryKafkaIntegration,
} from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";

interface Props {
  rerunList: Array<IntegrationMarkIndicator>;
  integrationInfrastructure: string;
  onRefetch: () => void;
}

export default function IntegrationListHeader({ rerunList, onRefetch, integrationInfrastructure }: Props) {
  const [integrationModalOpen, setIntegrationModalOpen] = useState(false);
  const canViewIntegrationFeature = useAuthorized("INTEGRATION_MANAGEMENT_PAGE");
  const { mutate: retryKafkaIntegration } = useRetryKafkaIntegration({});
  const { mutate } = useBulkMarkIntegrationStatus({});
  const { enqueueSnackbar } = useSnackbar();
  const currentMonth = formatDateToISODate(new Date()).slice(0, -3);
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 1);
  const previousMonth = formatDateToISODate(currentDate).slice(0, -3);

  return (
    <Wrapper>
      <H1>Integration Management</H1>
      {canViewIntegrationFeature && (
        <Grid container xs={8} justify="flex-end" spacing={1}>
          {integrationInfrastructure === "Core-Platform" ? (
            <Grid item xs={4}>
              <PrimaryButton fullWidth onClick={() => mutate(rerunList).then(() => onRefetch())}>
                Rerun Selected
              </PrimaryButton>
            </Grid>
          ) : (
            <Grid item xs={4}>
              <PrimaryButton
                fullWidth
                onClick={() =>
                  retryKafkaIntegration({
                    retryType: "bulkRetry",
                    index: "phi-log-kafka-consumer-dlq-" + currentMonth,
                    jobName: "mail-vendor-consumer",
                  }).then(() =>
                    retryKafkaIntegration({
                      retryType: "bulkRetry",
                      index: "phi-log-kafka-consumer-dlq-" + previousMonth,
                      jobName: "mail-vendor-consumer",
                    }).then(() =>
                      enqueueSnackbar("Bulk retry initiated, process may take a few seconds.", {
                        variant: "success",
                        preventDuplicate: true,
                      })
                    )
                  )
                }
              >
                Bulk Retry
              </PrimaryButton>
            </Grid>
          )}
          <Grid item xs={4}>
            <PrimaryButton fullWidth onClick={() => setIntegrationModalOpen(true)}>
              Upload Error File
            </PrimaryButton>
          </Grid>
        </Grid>
      )}
      {integrationModalOpen && (
        <IntegrationBulkFunctionModal open={integrationModalOpen} onClose={() => setIntegrationModalOpen(false)} />
      )}
    </Wrapper>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Wrapper = styled("div")({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});
