import React from "react";

const ClipboardWarningIcon = () => {
  return (
    <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="104" height="104" rx="52" fill="#FFF5E5" />
      <path d="M72.2816 30.1274H29.8999V79.5581H72.2816V30.1274Z" fill="#A1AAB4" />
      <path d="M62.2873 33.1229H32.8989V76.5567H69.2662V40.1193L62.2873 33.1229Z" fill="white" />
      <path
        d="M60.6908 24.0065H55.0446C55.0446 21.5165 53.2736 19.5 51.0817 19.5C48.8899 19.5 47.1188 21.5165 47.1188 24.0065H41.4726L39.877 34.0013H62.2689L60.6908 24.0065Z"
        fill="#212936"
      />
      <path
        d="M44.0156 40.0172H40.9997V37.0012H38.9305V40.0172H35.9146V42.0863H38.9305V45.1023H40.9997V42.0863H44.0156V40.0172Z"
        fill="#EA1D63"
      />
      <path d="M60.5154 48.452H41.6479V49.8723H60.5154V48.452Z" fill="#89D3DD" />
      <path d="M65.7759 71.1913H58.2534V72.6116H65.7759V71.1913Z" fill="#89D3DD" />
      <path d="M65.7756 53.6575H36.3872V55.0778H65.7756V53.6575Z" fill="#C2C2C2" />
      <path d="M65.7756 57.9177H36.3872V59.338H65.7756V57.9177Z" fill="#C2C2C2" />
      <path d="M65.7756 62.1833H36.3872V63.6037H65.7756V62.1833Z" fill="#C2C2C2" />
      <path d="M49.5208 66.4408H36.3872V67.8611H49.5208V66.4408Z" fill="#C2C2C2" />
      <path d="M62.2861 40.1193H69.265L62.2861 33.1229V40.1193Z" fill="#F5F5F5" />
      <g clipPath="url(#clip0_5456_2528)">
        <ellipse cx="71.4999" cy="35.1" rx="15.6" ry="15.6" fill="white" />
        <path
          d="M71.4999 19.5C62.8887 19.5 55.8999 26.4888 55.8999 35.1C55.8999 43.7112 62.8887 50.7 71.4999 50.7C80.1111 50.7 87.0999 43.7112 87.0999 35.1C87.0999 26.4888 80.1111 19.5 71.4999 19.5ZM73.0599 42.9H69.9399V39.78H73.0599V42.9ZM73.0599 36.66H69.9399V27.3H73.0599V36.66Z"
          fill="#CB7F00"
        />
      </g>
      <defs>
        <clipPath id="clip0_5456_2528">
          <rect width="31.2" height="31.2" fill="white" transform="translate(55.8999 19.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ClipboardWarningIcon;
