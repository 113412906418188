import { Alert, Caption, CopyToClipboard, H4, H6, InformativeModal, PrimaryButton } from "@coherehealth/common";
import {
  ProcedureCode,
  ServiceRequestResponse,
  useGetAuthDecisionGroup,
  useRemoveAuthFromAuthDecisionGroup,
} from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { CircularProgress, Grid, styled, useTheme } from "@material-ui/core";
import { AlertProps } from "@material-ui/lab";
import { AuthDecisionGroupServiceRequestSummary } from "components/PatientSummary/ServiceRequestSummaryCard/WithdrawAndReplaceServiceRequests";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

interface Props {
  serviceRequest: ServiceRequestResponse;
}

export default function AuthDecisionGroupSummary({ serviceRequest }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const [removeAuthFromGroupConfirmationModalOpen, setRemoveAuthFromGroupConfirmationModalOpen] = useState(false);

  const authDecisionGroupId = serviceRequest?.authDecisionGroup?.id;

  const {
    data: authDecisionGroup,
    loading: authDecisionGroupLoading,
    error: authDecisionGroupError,
  } = useGetAuthDecisionGroup({ id: authDecisionGroupId || "" });

  useEffect(() => {
    if (authDecisionGroupError) {
      enqueueSnackbar(`Error loading AuthDecisionGroup ${authDecisionGroupId}: ${authDecisionGroupError.message}`, {
        variant: "error",
      });
    }
  }, [authDecisionGroupError, enqueueSnackbar, authDecisionGroupId]);

  const {
    mutate: removeAuthFromAuthDecisionGroup,
    loading: removeAuthFromAuthDecisionGroupLoading,
    error: removeAuthFromAuthDecisionGroupError,
  } = useRemoveAuthFromAuthDecisionGroup({ serviceRequestId: serviceRequest.id || "" });

  useEffect(() => {
    if (removeAuthFromAuthDecisionGroupError) {
      enqueueSnackbar(
        `Error removing auth from AuthDecisionGroup ${authDecisionGroupId}: ${removeAuthFromAuthDecisionGroupError.message}`,
        {
          variant: "error",
        }
      );
    }
  }, [removeAuthFromAuthDecisionGroupError, enqueueSnackbar, authDecisionGroupId]);

  if (authDecisionGroupLoading) {
    return (
      <Grid container spacing={3} direction="column" alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <H4>Auth decision group data loading...</H4>
        </Grid>
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  const onRemoveAuthFromGroup = async () => {
    await removeAuthFromAuthDecisionGroup();
    //refresh the page so that the deleted data updates (will 404 if we just refetch and the ADG was deleted)
    window.location.reload();
  };

  const otherServiceRequestsInAuthDecisionGroup =
    authDecisionGroup?.serviceRequests?.filter((sr) => sr?.id !== serviceRequest?.id) || [];

  const alertConfig =
    AuthStatusAlertConfigMapping[authDecisionGroup?.authStatus || serviceRequest?.authStatus || "PENDING"];

  if (!authDecisionGroup) {
    return null;
  }
  return (
    <>
      <Grid container direction="row">
        <Grid item>
          <StyledH6>
            {`Auth Decision Group (${authDecisionGroup.id})`}
            {authDecisionGroup.id && <CopyToClipboard text={authDecisionGroup.id} />}
          </StyledH6>
        </Grid>
      </Grid>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item xs={authDecisionGroup.authNumber ? 12 : 7}>
          <Alert
            style={{
              userSelect: "text",
              flex: 1,
              textAlign: "left",
              padding: theme.spacing(1, 2),
              borderRadius: `${theme.spacing(0.6)}px !important`,
              whiteSpace: "pre-line",
            }}
            severity={alertConfig.severity}
            message={alertConfig.message}
          >
            {authDecisionGroup.authNumber
              ? `Authorization #${authDecisionGroup.authNumber}`
              : "Auth number not available"}
          </Alert>
        </Grid>
        {(!authDecisionGroup.authNumber ||
          (serviceRequest.delegatedVendor === "HealthHelp" && authDecisionGroup.authStatus === "PENDING")) && (
          <Grid item xs={4}>
            <StyledPrimaryButton
              onClick={() => setRemoveAuthFromGroupConfirmationModalOpen(true)}
              disabled={
                Boolean(authDecisionGroup.authNumber) &&
                !(serviceRequest.delegatedVendor === "HealthHelp" && authDecisionGroup.authStatus === "PENDING")
              }
            >
              Remove from group
            </StyledPrimaryButton>
            <InformativeModal
              open={removeAuthFromGroupConfirmationModalOpen}
              onClose={() => setRemoveAuthFromGroupConfirmationModalOpen(false)}
              icon={<WarningIcon />}
              headerText="Are you sure you want to remove this auth from the group?"
              subHeaderText="This will integrate the authorization separate from all other auths in the auth decision group."
              additionalInfoText="If the auth decision group has 2 or less auths in it, the auth decision group will effectively be deleted. Otherwise, just this authorization will be removed, and the rest of the group will be preserved."
              primaryButtonText="Remove"
              primaryButtonAction={onRemoveAuthFromGroup}
              primaryButtonLoading={removeAuthFromAuthDecisionGroupLoading}
              tertiaryButtonText="Go back"
              tertiaryButtonAction={() => setRemoveAuthFromGroupConfirmationModalOpen(false)}
            />
          </Grid>
        )}
        {otherServiceRequestsInAuthDecisionGroup.length > 0 && (
          <>
            <Grid item xs={12} style={{ paddingTop: 16 }}>
              <Caption>Other Service Requests in Group</Caption>
            </Grid>
            {otherServiceRequestsInAuthDecisionGroup?.map((sr, idx) => (
              <>
                <Grid item xs={12}>
                  <AuthDecisionGroupServiceRequestSummary
                    key={idx}
                    serviceTitle={sr?.clinicalService?.name || ""}
                    procedureCode={
                      sr?.procedureCodes?.map((procedureCode: ProcedureCode) => {
                        return procedureCode?.code;
                      }) || []
                    }
                    trackingNumber={sr?.cohereId || ""}
                    patientId={sr?.patient?.id || ""}
                    serviceRequestId={sr?.id || ""}
                  />
                </Grid>
              </>
            ))}
          </>
        )}
      </Grid>
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledH6 = styled(H6)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledPrimaryButton = styled(PrimaryButton)(({ theme }) => ({
  width: "100%",
  //matches the height of the adjacent alert
  minHeight: theme.spacing(7),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const WarningIcon = styled(HelpOutlineIcon)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  color: theme.palette.warning.dark,
}));

interface AuthDecisionGroupAuthStatusAlertProps {
  severity: AlertProps["severity"] | "draft" | "withdrawn";
  message: string;
}

const AuthStatusAlertConfigMapping: Record<string, AuthDecisionGroupAuthStatusAlertProps> = {
  DRAFT: {
    severity: "error",
    message: "Draft (should not happen!)",
  },
  PENDING: {
    severity: "warning",
    message: "Pending",
  },
  PENDING_EXTERNAL_DETERMINATION: {
    severity: "warning",
    message: "Pending external determination",
  },
  PENDING_ASSESSMENT: {
    severity: "error",
    message: "Pending assessment (should not happen!)",
  },
  PENDING_FACILITY_VERIFICATION: {
    severity: "error",
    message: "Pending facility verification (should not happen!)",
  },
  INTAKE: {
    severity: "warning",
    message: "Pending (intake)",
  },
  WITHDRAWN: {
    severity: "withdrawn",
    message: "Withdrawn",
  },
  VOIDED: {
    severity: "withdrawn",
    message: "Voided",
  },
  DISMISSED: {
    severity: "withdrawn",
    message: "Dismissed",
  },
  APPROVED: {
    severity: "success",
    message: "Approved",
  },
  PARTIALLY_APPROVED: {
    severity: "error",
    message: "Partially approved",
  },
  RECOMMENDED_DENIAL: {
    severity: "warning",
    message: "Recommended denial",
  },
  RECOMMENDED_PARTIAL_APPROVAL: {
    severity: "warning",
    message: "Recommended partial approval",
  },
  DENIED: {
    severity: "error",
    message: "Denied",
  },
};
