import { generatePath, useNavigate } from "react-router";
import routes from "../../../routes";
import MuiErrorIcon from "@material-ui/icons/Error";
import { InformativeModal } from "@coherehealth/common";
import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    padding: theme.spacing(3, 7, 7, 7),
    minWidth: 600,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));
export interface Props {
  pharmErrorRedirectModalOpen: boolean;
  singleServiceClinicalAssessmentErrorMessage: string | null;
  patientId: string | undefined;
}

export default function AssessmentErrorModal({
  pharmErrorRedirectModalOpen,
  singleServiceClinicalAssessmentErrorMessage,
  patientId,
}: Props) {
  const navigate = useNavigate();

  const theme = useTheme();
  const classes = useStyles();
  return (
    <InformativeModal
      hideX
      open={pharmErrorRedirectModalOpen}
      onClose={() => {}}
      headerText={"Oops! Something went wrong."}
      subHeaderText={
        <div>
          {"We encountered an unexpected error. Please try again. If the problem continues, "}
          <a
            href="https://payerinfo.zendesk.com/hc/en-us/articles/11791915498647-How-can-I-contact-Cohere"
            target="_blank"
            rel="noreferrer"
          >
            contact our support team
          </a>
          .
        </div>
      }
      primaryButtonText={"Go to patient summary"}
      primaryButtonAction={() => {
        navigate(
          `${generatePath(routes.PATIENT_SUMMARY, {
            patientId: patientId,
          })}`
        );
      }}
      icon={<MuiErrorIcon style={{ height: "39px", width: "39px", color: theme.palette.warning.dark }} />}
      customContentStyle={classes.modal}
      data-testid="error-modal"
    />
  );
}
