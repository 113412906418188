import React, { PropsWithChildren } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";

interface Props {
  /** The height of a fixed header above this page-layout in px */
  headerHeight?: number;
}

/** Spacing between a header and page */
export const headerSpacing = 5;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    height: "100%",
    paddingTop: ({ headerHeight = 0 }: Props) => (headerHeight > 0 ? 0 + headerHeight : 0),
  },
}));

/**
 * A page layout that takes a "Left rail" component which gets fixed to the left side
 * while the right side of the page can scroll as normal
 */
export default function FullPageLayout({ children, ...props }: PropsWithChildren<Props>) {
  const classes = useStyles(props);

  return <div className={classes.root}>{children}</div>;
}
