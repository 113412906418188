import React, { useState } from "react";
import ServiceRequestSummaryDetails from "components/PatientSummary/ServiceRequestSummaryCard/ServiceRequestSummaryDetails";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { SRFormViewState } from "components/PatientSummary/ServiceRequestSummaryCard/ServiceRequestFormSection";
import { Caption, Card, colorsLight, H4, HEALTH_HELP_NAME } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import { useIsDelegatedToVendor } from "util/serviceRequest";

interface Props {
  serviceRequest: ServiceRequestResponse;
  refetchData: () => Promise<void>;
}

interface StyleProps {
  hasServiceCategory?: boolean;
}

const useStyles = makeStyles((theme) => ({
  serviceRequestDetails: {
    display: "inline-block",
    padding: theme.spacing(4),
  },
  serviceDescription: {
    marginBottom: theme.spacing(3),
    maxHeight: 50,
    display: "block",
    "&.hidden": {
      maxHeight: 0,
      overflow: "hidden",
      marginBottom: 0,
    },
  },
  serviceCategoryCaption: {
    color: colorsLight.font.light,
    paddingBottom: theme.spacing(1),
  },
  headerRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  captionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: ({ hasServiceCategory }: StyleProps) => (hasServiceCategory ? "space-between" : "center"),
  },
  submissionTime: {
    display: "inline-block",
    marginBottom: theme.spacing(3),
  },
}));

export default function ReviewStep({ serviceRequest, refetchData }: Props) {
  const [srFormViewState, setSRFormViewState] = useState<SRFormViewState>("READ_ONLY");

  const serviceCategoryName = serviceRequest.clinicalService?.serviceCategoryName;
  const hasServiceCategory = !!serviceCategoryName;
  const classes = useStyles({ hasServiceCategory });

  const isDelegatedToHealthHelp = useIsDelegatedToVendor(serviceRequest, [HEALTH_HELP_NAME]);

  return (
    <>
      <Card className={classes.serviceRequestDetails}>
        <div className={classes.headerRow}>
          <div className={classes.captionContainer}>
            {hasServiceCategory && (
              <Caption className={classes.serviceCategoryCaption}> {serviceCategoryName || ""}</Caption>
            )}
            <Header>{serviceRequest.clinicalService?.name || serviceRequest.palCategory?.name}</Header>
          </div>
        </div>
        {serviceRequest.clinicalService?.description && (
          <Caption className={classes.serviceDescription}>{serviceRequest.clinicalService?.description}</Caption>
        )}
        <ServiceRequestSummaryDetails
          serviceRequest={serviceRequest}
          onEdit={refetchData}
          srFormViewState={srFormViewState}
          setSRFormViewState={setSRFormViewState}
          /* Any edits made here should keep the authStatus as DRAFT */
          submitEditsOnDraftSrsToIntake={false}
          hideServiceRequestEditWithdrawButtons={isDelegatedToHealthHelp}
        />
      </Card>
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Header = styled(H4)(({ theme }) => ({
  padding: theme.spacing(0),
}));
