import {
  DiagnosisCode,
  FacilityCategory,
  ProcedureCode,
  UnitedStates,
  GuidelineType,
  GuidelineBlock,
  Indication,
} from "@coherehealth/core-platform-api";
import Grid from "@material-ui/core/Grid";
import { colorsLight } from "../themes";
import { Caption } from "../components/Typography";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import { useStableUniqueId } from "../hooks";

export function stateMatch(matchedStateFilters: string): JSX.Element {
  return (
    <>
      {matchedStateFilters && (
        <CommonMatch caption={"State match:"} listItem={[matchedStateFilters] as UnitedStates[]} />
      )}
    </>
  );
}

export function facilityCategoryMatch(matchedFacilityCategoryFilters: string): JSX.Element | null {
  return (
    <>
      {matchedFacilityCategoryFilters && (
        <CommonMatch
          caption={"Facility category match:"}
          listItem={[matchedFacilityCategoryFilters] as FacilityCategory[]}
        />
      )}
    </>
  );
}

export function codeMatch(
  matchedPxCodes: ProcedureCode[] | undefined,
  matchedDxCodes: DiagnosisCode[] | undefined
): JSX.Element {
  return (
    <>
      {!!matchedDxCodes?.length && <CommonMatch caption={"Diagnosis match:"} listItem={matchedDxCodes} isPxDxCode />}
      {!!matchedPxCodes?.length && <CommonMatch caption={"Procedure match:"} listItem={matchedPxCodes} isPxDxCode />}
    </>
  );
}

interface Props {
  caption: string;
  listItem: DiagnosisCode[] | ProcedureCode[] | UnitedStates[] | FacilityCategory[];
  isPxDxCode?: boolean;
}
const useStyles = makeStyles((theme) => ({
  codesContainer: {
    marginBottom: theme.spacing(0.5),
  },
  codes: {
    marginBottom: theme.spacing(1),
  },
}));

export function CommonMatch({ caption, listItem, isPxDxCode }: Props) {
  const classes = useStyles();
  return (
    <Grid item container xs={12} alignItems="flex-start" className={classes.codesContainer}>
      <Grid item xs={3}>
        <MatchCaption>{caption}</MatchCaption>
      </Grid>
      {isPxDxCode ? (
        <Grid item xs={9} style={{ maxWidth: "75%", flexBasis: "0%" }}>
          {listItem.map((code: any, index: number) => (
            <DxPxCode
              key={`${code.id || code.code}_${index}`}
              code={code}
              haveMarginBottom={!Boolean(index + 1 === listItem.length)}
            />
          ))}
        </Grid>
      ) : (
        <Grid item>
          <InfoPill>
            <StyledBody3>{listItem}</StyledBody3>
          </InfoPill>
        </Grid>
      )}
    </Grid>
  );
}

export function DxPxCode({
  code,
  haveMarginBottom,
}: {
  code: ProcedureCode | DiagnosisCode;
  haveMarginBottom: boolean;
}) {
  const uniqueId = useStableUniqueId();
  return (
    <InfoPill key={`pill-${uniqueId}`} style={{ marginBottom: haveMarginBottom ? 8 : 0 }}>
      <TextOverflowBody id={uniqueId}>{`${code.code} - ${code.description}`}</TextOverflowBody>
    </InfoPill>
  );
}

// Return RTE formatting for everything outside of individual list items.
export function getIndicationText(htmlString: string): string {
  const listItems = htmlString.match(/<li/g) || [];
  if (listItems.length !== 1) {
    return htmlString;
  } else {
    const listItemSubstring = htmlString.substring(htmlString.indexOf("<li"), htmlString.indexOf("</li"));
    const replaceableListItemString = listItemSubstring.substring(0, listItemSubstring.indexOf(">"));
    return htmlString.replace(replaceableListItemString, "<div").replace("</li", "</div");
  }
}

export function getIndicationTextForRuleBuild(
  indication: Indication,
  guidelineBlocks: GuidelineBlock[]
): string | undefined {
  if (!indication.indications || indication.indications.length === 0) {
    return getIndicationText(indication.indicationHtml || "- -");
  } else if (indication.guidelineHtmlIndices && guidelineBlocks) {
    const index = Math.min.apply(null, indication.guidelineHtmlIndices);
    if (guidelineBlocks[index]) {
      return guidelineBlocks[index].text;
    } else {
      return "- -";
    }
  } else {
    return "- -";
  }
}

// Finds if a list exists as a wrapper (index 5 because it would be after the initial <div>).
// If it does, then we don't need to add the top/bottom padding to the text. Else, we need it.
export function findList(htmlString: string): boolean {
  const firstUnorderedListIndex = htmlString.indexOf("<ul");
  const firstOrderedListIndex = htmlString.indexOf("<ol");
  return firstUnorderedListIndex === 5 || firstOrderedListIndex === 5;
}

interface URLInfo {
  href: string;
  hostname: string;
}

export function getURLInfo(urlString: string): string | JSX.Element {
  let urlInfo: URLInfo;
  try {
    const isURL = new URL(urlString);
    urlInfo = {
      href: isURL.href,
      hostname: isURL.hostname,
    };
  } catch (error) {
    urlInfo = {
      href: "",
      hostname: urlString,
    };
  }
  if (urlInfo.href === "") {
    return <StyledBody3>{`Policy URL: ${urlInfo.hostname || "--"}`}</StyledBody3>;
  } else {
    return (
      <StyledBody3>
        {"Policy URL: "}
        <PolicyLink href={urlInfo.href} rel="noopener" target="_blank">
          {urlInfo.hostname}
        </PolicyLink>
      </StyledBody3>
    );
  }
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const MatchCaption = styled(Caption)({
  color: colorsLight.font.secondary,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const InfoPill = styled("div")(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0.04)",
  padding: theme.spacing(1),
  boxShadow: "0px",
  borderRadius: theme.spacing(0.5),
  "&:before": {
    content: "none",
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PolicyLink = styled("a")(() => ({
  textDecoration: "none",
  color: colorsLight.brand.blue,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const StyledBody3 = styled("div")({
  color: colorsLight.font.main,
  fontFamily: "Gilroy-Medium",
  fontSize: "13px",
});

export const OrderedPolicyTypes: Record<GuidelineType, string> = {
  COHERE_NUDGE: "Cohere Nudge",
  NCD: "NCD",
  LCD: "LCD",
  PAYOR: "Health Plan Guidelines",
  COHERE: "Cohere Guidelines",
  ALTERNATIVE: "Alternative Guidelines",
  NASS: "NASS",
  MCG: "MCG",
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const TextOverflowBody = styled(StyledBody3)(() => ({
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
}));
