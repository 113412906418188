import React, { useCallback } from "react";

import {
  Body1,
  Caption,
  H5,
  PrimaryButton,
  TextField,
  formatDateStrWithTime,
  fileSizeString,
} from "@coherehealth/common";
import Grid from "@material-ui/core/Grid";
import { DocumentInfo } from "./DocumentViewer";
import copy from "copy-to-clipboard";

export default function FileMetaDataDisplay({
  name = "Unknown File",
  size,
  dateCreated,
  createdByName,
  cohereId,
}: Partial<DocumentInfo>) {
  const copyCohereId = useCallback(() => {
    if (cohereId) {
      copy(cohereId);
    }
  }, [cohereId]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <H5>{name || "Unknown File"}</H5>
      </Grid>
      {size && (
        <Grid item xs={12}>
          <Caption data-public>Size</Caption>
          <Body1 data-public>{fileSizeString(size)}</Body1>
        </Grid>
      )}
      {dateCreated && (
        <Grid item xs={12}>
          <Caption data-public>Added</Caption>
          <Body1 data-public>{formatDateStrWithTime(dateCreated)}</Body1>
        </Grid>
      )}
      {createdByName && (
        <Grid item xs={12}>
          <Caption data-public>Uploaded by</Caption>
          <Body1 data-public>{createdByName}</Body1>
        </Grid>
      )}
      {cohereId && (
        <>
          <Grid item xs={6}>
            <TextField label="Fax ID" value={cohereId} />
          </Grid>
          <Grid item xs={6}>
            <PrimaryButton fullWidth onClick={copyCohereId}>
              Copy ID
            </PrimaryButton>
          </Grid>
        </>
      )}
    </Grid>
  );
}
