import { SVGProps } from "react";

// x-checkbox item mimicking material ui checkbox item but with an x inside.
const UnmetCheckboxIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="m 19 3 h -14 c -1.11 0 -2 0.9 -2 2 V 19 C 3 20.1 3.89 21 5 21 H 19 C 20.11 21 21 20.1 21 19 v -14 C 21 3.9 20.11 3 19 3 Z M 8.55 16.85 l -1.41 -1.41 l 3.44 -3.44 l -3.44 -3.44 l 1.41 -1.41 l 3.44 3.44 l 3.44 -3.44 l 1.41 1.41 l -3.44 3.44 l 3.44 3.44 l -1.41 1.41 l -3.44 -3.44 z" />
    </svg>
  );
};

export default UnmetCheckboxIcon;
