import {
  GetAuthorizationByIdPathParams,
  GetServiceRequestPathParams,
  useGetAuthorizationById,
  useGetServiceRequest,
  GetReferralRequestPathParams,
  useGetReferralRequest,
} from "@coherehealth/core-platform-api";
import { useFeature } from "../components";
import { useCallback, useMemo } from "react";

export const useGetAuthorizationByIdWithFallback: typeof useGetAuthorizationById = ({ id, ...props }) => {
  const shouldUseFallbacks = useFeature("getByIdWithFallbacks");

  // "unknown-id" will result in a 404, but it is preferable to sending an empty string, which would instead invoke the "index" api and return a list
  const idWithFallback = id || "unknown-id";

  const originalGetAuthorizationReturn = useGetAuthorizationById({
    id: shouldUseFallbacks ? idWithFallback : id,
    ...props,
  });

  // Also need to apply the fallback to the re-fetch function
  const originalRefetch = useMemo(() => originalGetAuthorizationReturn.refetch, [originalGetAuthorizationReturn]);
  const refetchWithFallback: typeof originalRefetch = useCallback(
    (refetchOptions) => {
      // Some weird stuff here to satisfy typescript
      let pathParams = refetchOptions?.pathParams;
      if (verifyPathParamsType<GetAuthorizationByIdPathParams>(pathParams)) {
        if (!pathParams.id) {
          pathParams.id = "unknown-id"; // this is the fallback
        }
        return originalRefetch({
          ...refetchOptions,
          pathParams: {
            ...pathParams,
            id: pathParams.id || "unknown-id",
          },
        });
      } else {
        return originalRefetch(refetchOptions);
      }
    },
    [originalRefetch]
  );

  const getAuthorizationReturnWithFallback = useMemo(
    () => ({ ...originalGetAuthorizationReturn, refetch: refetchWithFallback }),
    [originalGetAuthorizationReturn, refetchWithFallback]
  );

  if (!shouldUseFallbacks) {
    return originalGetAuthorizationReturn;
  } else {
    return getAuthorizationReturnWithFallback;
  }
};

export const useGetServiceRequestByIdWithFallback: typeof useGetServiceRequest = ({ id, ...props }) => {
  const shouldUseFallbacks = useFeature("getByIdWithFallbacks");

  // "unknown-id" will result in a 404, but it is preferable to sending an empty string, which would instead invoke the "index" api and return a list
  const idWithFallback = id || "unknown-id";

  const originalGetServiceRequestReturn = useGetServiceRequest({
    id: shouldUseFallbacks ? idWithFallback : id,
    ...props,
  });

  // Also need to apply the fallback to the re-fetch function
  const originalRefetch = useMemo(() => originalGetServiceRequestReturn.refetch, [originalGetServiceRequestReturn]);
  const refetchWithFallback: typeof originalRefetch = useCallback(
    (refetchOptions) => {
      // Some weird stuff here to satisfy typescript
      let pathParams = refetchOptions?.pathParams;
      if (verifyPathParamsType<GetServiceRequestPathParams>(pathParams)) {
        if (!pathParams.id) {
          pathParams.id = "unknown-id"; // this is the fallback
        }
        return originalRefetch({
          ...refetchOptions,
          pathParams: {
            ...pathParams,
            id: pathParams.id || "unknown-id",
          },
        });
      } else {
        return originalRefetch(refetchOptions);
      }
    },
    [originalRefetch]
  );

  const getServiceRequestReturnWithFallback = useMemo(
    () => ({ ...originalGetServiceRequestReturn, refetch: refetchWithFallback }),
    [originalGetServiceRequestReturn, refetchWithFallback]
  );

  if (!shouldUseFallbacks) {
    return originalGetServiceRequestReturn;
  } else {
    return getServiceRequestReturnWithFallback;
  }
};

export const useGetReferralRequestByIdWithFallback: typeof useGetReferralRequest = ({ id, ...props }) => {
  const shouldUseFallbacks = useFeature("getByIdWithFallbacks");

  // "unknown-id" will result in a 404, but it is preferable to sending an empty string, which would instead invoke the "index" api and return a list
  const idWithFallback = id || "unknown-id";

  const originalGetReferralRequestReturn = useGetReferralRequest({
    id: shouldUseFallbacks ? idWithFallback : id,
    ...props,
  });

  // Also need to apply the fallback to the re-fetch function
  const originalRefetch = useMemo(() => originalGetReferralRequestReturn.refetch, [originalGetReferralRequestReturn]);
  const refetchWithFallback: typeof originalRefetch = useCallback(
    (refetchOptions) => {
      // Some weird stuff here to satisfy typescript
      let pathParams = refetchOptions?.pathParams;
      if (verifyPathParamsType<GetReferralRequestPathParams>(pathParams)) {
        if (!pathParams.id) {
          pathParams.id = "unknown-id"; // this is the fallback
        }
        return originalRefetch({
          ...refetchOptions,
          pathParams: {
            ...pathParams,
            id: pathParams.id || "unknown-id",
          },
        });
      } else {
        return originalRefetch(refetchOptions);
      }
    },
    [originalRefetch]
  );

  const getReferralRequestReturnWithFallback = useMemo(
    () => ({ ...originalGetReferralRequestReturn, refetch: refetchWithFallback }),
    [originalGetReferralRequestReturn, refetchWithFallback]
  );

  if (!shouldUseFallbacks) {
    return originalGetReferralRequestReturn;
  } else {
    return getReferralRequestReturnWithFallback;
  }
};

function verifyPathParamsType<T extends { id: string }>(x: unknown): x is T {
  return !!(x && typeof x == "object" && x.hasOwnProperty("id"));
}
