import { Theme } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import merge from "lodash/merge";
import { colorsLight, colorsLight as colors } from "./colors";
import common from "./common";

// https://material-ui.com/customization/default-theme/
const themeLight: Theme = createTheme(
  merge({}, common, {
    palette: {
      divider: colors.gray.divider,
      text: {
        primary: colors.black.highEmphasis,
        secondary: colors.black.mediumEmphasis,
        tertiary: colorsLight.black.mediumEmphasis,
        disabled: colors.black.disabled,
        hint: colors.black.disabled,
      },
      background: {
        paper: colors.background.card,
        default: colors.background.light,
      },
    },
  })
);
export { themeLight };
