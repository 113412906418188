import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import QuestionHeader, { Props as QuestionHeaderProps } from "./QuestionHeader";
import QuestionCard, { Props as QuestionCardProps } from "./QuestionCard";
import Grid from "@material-ui/core/Grid";
import { useStableUniqueId } from "../../hooks";
import { Caption } from "../Typography";
import { colorsLight } from "../../themes";
import { TextField } from "../TextField";

interface Props extends QuestionHeaderProps, QuestionCardProps {
  /** The selected answers for this question */
  answerText: string;
  /** A function which updates the selected answer when a new option is clicked */
  setAnswerText: (arg0: string) => any;
  error?: boolean;
  helperText?: string;
}

const useLabelStyles = makeStyles({
  asterisk: {
    display: "none",
  },
});

export default function ShortAnswerQuestion({
  answerText,
  setAnswerText,
  relevantLinks,
  required,
  subtext,
  ordinal,
  questionText,
  error,
  helperText,
  hideQuestionAnswerDivider,
  ...questionCardProps
}: Props) {
  const fieldId = useStableUniqueId("ShortAnswerQuestion");
  const labelClasses = useLabelStyles();

  return (
    <QuestionCard {...questionCardProps}>
      <label htmlFor={fieldId}>
        <QuestionHeader
          hideQuestionAnswerDivider={hideQuestionAnswerDivider}
          relevantLinks={relevantLinks}
          required={required}
          ordinal={ordinal}
          questionText={questionText}
        />
      </label>
      {subtext && (
        <Grid
          item
          xs={10}
          style={{ display: "flex", justifyContent: "left", alignItems: "center", paddingBottom: "20px" }}
        >
          <Caption style={{ color: colorsLight.font.secondary }}>{subtext}</Caption>
        </Grid>
      )}
      <TextField
        role={"textField"}
        error={error}
        helperText={helperText}
        id={fieldId}
        labelClasses={labelClasses}
        required={required}
        fullWidth
        multiline
        label="Type Answer"
        value={answerText}
        onChangeValue={setAnswerText}
      />
    </QuestionCard>
  );
}
