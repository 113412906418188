import { registerRoute } from "../server";

export default function registerRoutes() {
  registerRoute({
    id: "getClinicalAssessment",
    route: "carePathJourney/:journeyId/clinicalAssessment",
    method: "get",
    generateResponse: function (config, schema, request) {
      const { serviceRequestIds } = request.queryParams || [];
      return {
        serviceRequestIds,
        deleted: false,
        carePathJourneyId: "5f32aafadc8167476e7afd68",
        questions: [
          {
            clinicalQuestionIds: ["5f32f15bdc816776cb60fccb"],
            clinicalQuestion: {
              id: "5f32f15bdc816776cb60fccb",
              questionText: "Surgical risk factors?",
              dateCreated: "2020-08-11T19:28:27.910Z",
              internalDescription: "surgical risk factors",
              lastUpdated: "2020-08-13T19:41:52.307Z",
              deleted: false,
              updatedBy: "sub",
              responseOptions: [
                {
                  responseOptionText: "Obese",
                  observationCodes: [
                    {
                      id: "7181d103a94a41d686438398261dcc6d",
                      code: "10148013",
                      deleted: false,
                      system: "http://snomed.info/sct",
                      releaseDate: "2020-03-01",
                      description: "Simple obesity",
                    },
                  ],
                },
                {
                  responseOptionText: "Is a smoker",
                  observationCodes: [
                    {
                      id: "eca68727a58d48fb948d41460465917f",
                      code: "148820017",
                      deleted: false,
                      system: "http://snomed.info/sct",
                      releaseDate: "2020-03-01",
                      description: "Tobacco dependence syndrome",
                    },
                  ],
                },
              ],
              type: "MULTI_SELECT",
              required: true,
              createdBy: "sub",
              clinicalServiceId: "abc",
            },
          },
          {
            clinicalQuestionIds: ["5f31494adc816709c2703ad5"],
            clinicalQuestion: {
              id: "5f31494adc816709c2703ad5",
              questionText: "Shoulder range of motion",
              dateCreated: "2020-08-10T13:19:07.011Z",
              observationCodes: [
                {
                  id: "33aab5d905fb46e19c4f37abbeb6194f",
                  code: "41295-7",
                  deleted: false,
                  system: "http://loinc.org",
                  releaseDate: "2020-06-17",
                  description:
                    "Shoulder - left Flexion Active Range of Motion Quantitative",
                },
              ],
              internalDescription: "typically measured in degrees",
              lastUpdated: "2020-08-13T19:42:09.271Z",
              deleted: false,
              updatedBy: "sub",
              type: "MEASUREMENT",
              required: true,
              createdBy: "00ujft16rWqAmKRXA4x6",
              measurementUnitClass: "plane angle",
              clinicalServiceId: "abc",
            },
          },
          {
            clinicalQuestionIds: ["5f31494adc816709c2703ad6"],
            clinicalQuestion: {
              id: "5f31494adc816709c2703ad6",
              questionText: "Shoulder range of motion duplicate",
              dateCreated: "2020-08-10T13:19:07.011Z",
              observationCodes: [
                {
                  id: "33aab5d905fb46e19c4f37abbeb6194f",
                  code: "41295-7",
                  deleted: false,
                  system: "http://loinc.org",
                  releaseDate: "2020-06-17",
                  description:
                    "Shoulder - left Flexion Active Range of Motion Quantitative",
                },
              ],
              internalDescription: "typically measured in degrees",
              lastUpdated: "2020-08-13T19:42:09.271Z",
              deleted: false,
              updatedBy: "sub",
              type: "MEASUREMENT",
              required: true,
              createdBy: "00ujft16rWqAmKRXA4x6",
              measurementUnitClass: "plane angle",
              clinicalServiceId: "abc",
            },
          },
          {
            clinicalQuestionIds: ["5f3595d0dc8167129fbf596f"],
            clinicalQuestion: {
              id: "5f3595d0dc8167129fbf596f",
              questionText: "Date of birth",
              dateCreated: "2020-08-13T19:34:40.203Z",
              observationCodes: [
                {
                  id: "e3e9d14028f54582b25b95273f46efa0",
                  code: "21112-8",
                  deleted: false,
                  system: "http://loinc.org",
                  releaseDate: "2020-06-17",
                  description: "Birth date",
                },
              ],
              lastUpdated: "2020-08-13T19:44:40.161Z",
              deleted: false,
              updatedBy: "sub",
              type: "DATE",
              required: true,
              createdBy: "sub",
              clinicalServiceId: "abc",
            },
          },
          {
            clinicalQuestionIds: ["5f359815dc8167352308b924"],
            clinicalQuestion: {
              id: "5f359815dc8167352308b924",
              questionText: "Select a random code",
              dateCreated: "2020-08-13T19:44:21.805Z",
              lastUpdated: "2020-08-13T19:46:27.426Z",
              deleted: false,
              updatedBy: "sub",
              responseOptions: [
                {
                  responseOptionText: "Plan of care includes diabetes",
                  observationCodes: [
                    {
                      id: "29de043e95494e67a4e6e62eb14e222a",
                      code: "57270-1",
                      deleted: false,
                      system: "http://loinc.org",
                      releaseDate: "2020-06-17",
                      description:
                        "Plan of care includes diabetic foot care [CMS Assessment]",
                    },
                  ],
                },
                {
                  responseOptionText: "Family history of depression",
                  observationCodes: [
                    {
                      id: "98ec5c9606c64af3866ab002473c4e94",
                      code: "249862019",
                      deleted: false,
                      system: "http://snomed.info/sct",
                      releaseDate: "2020-03-01",
                      description: "Family history of depression",
                    },
                  ],
                },
                {
                  responseOptionText: "Pronation of foot",
                  observationCodes: [
                    {
                      id: "7be7af7c057f4f299589c269da04d42e",
                      code: "19010010",
                      deleted: false,
                      system: "http://snomed.info/sct",
                      releaseDate: "2020-03-01",
                      description: "Pronation of foot",
                    },
                  ],
                },
              ],
              type: "SINGLE_SELECT",
              required: false,
              createdBy: "sub",
              clinicalServiceId: "abc",
            },
          },
        ],
      };
    },
  });
}
