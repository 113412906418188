import { useCallback, Dispatch } from "react";
import {
  Body1,
  Caption,
  H5,
  formatDateStrWithTime,
  InlineButton,
  downloadUrl,
  SingleSelect,
  Chip,
  SelectOptionsHook,
} from "@coherehealth/common";
import { Grid, Box, withStyles, makeStyles } from "@material-ui/core";
import { DocumentInfo } from "../DocumentViewer";
import parsePhoneNumber from "libphonenumber-js";
import { GetApp, KeyboardArrowDown } from "@material-ui/icons";
import { useAuth } from "hooks/useAuth";
import { User as QMAgent, ServiceCase } from "@coherehealth/qm-api";

interface IProps {
  fileData: Partial<DocumentInfo>;
  url?: string;
  isChipVisible?: boolean;
  serviceCase?: ServiceCase | null;
  showExternalId?: boolean;
  showCaseId?: boolean;
  showFaxId?: boolean;
}

interface ChipProps {
  selectedAgent: QMAgent | null;
  showDropdown: boolean;
  setShowDropdown: Dispatch<React.SetStateAction<boolean>>;
  handleChipClick: () => void;
  useUsers: SelectOptionsHook<QMAgent>;
  handleOnSelect: (option: QMAgent | null) => void;
}

type ConditionalChipProps =
  | ({ isChipVisible?: false } & IProps & { [property in keyof ChipProps]+?: never })
  | ({ isChipVisible: true } & IProps & ChipProps);

const inlineButtonStyles = () => {
  return {
    root: {
      minWidth: "inherit",
      padding: 0,
    },
    startIcon: {
      marginLeft: 0,
      marginRight: 0,
    },
  };
};
const SpecialInlineButton = withStyles(inlineButtonStyles)(InlineButton);

const useStyles = makeStyles(() => ({
  chip: {
    width: "200px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    rowGap: "8px",
  },
  faxDetailsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    columnGap: "4px",
    rowGap: "16px",
  },
}));

export default function FaxMetaDataDisplay({
  fileData,
  serviceCase,
  selectedAgent,
  url,
  isChipVisible,
  showDropdown,
  setShowDropdown,
  handleChipClick,
  useUsers,
  handleOnSelect,
  showExternalId = true,
  showCaseId = true,
  showFaxId = true,
}: ConditionalChipProps) {
  const { oktaAuth } = useAuth();
  const classes = useStyles();
  const onDownloadAttachment = useCallback(async () => {
    if (!url) {
      return;
    } else {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
        },
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        downloadUrl(url, fileData?.name);
      }
    }
  }, [fileData?.name, oktaAuth, url]);

  return (
    <Grid container spacing={3}>
      <Grid item container xs={12} className={classes.header}>
        <Box mr="10px" maxWidth="100%" style={{ wordWrap: "break-word" }}>
          <H5 data-public>{fileData?.name || "Unknown File"}</H5>
        </Box>
        {isChipVisible && (
          <Box>
            {!showDropdown ? (
              <Chip
                clickable
                label={serviceCase?.assignee?.name || "Unassigned"}
                type={!serviceCase?.assignee?.name ? "neutral" : "default"}
                size="small"
                onClick={handleChipClick}
                Icon={KeyboardArrowDown}
                className={classes.chip}
                data-public
              />
            ) : (
              <SingleSelect
                clearable
                markSelectedOptions={false}
                placeholder="Search agents"
                useOptions={useUsers}
                selectedValue={selectedAgent}
                getOptionLabel={(option: QMAgent) => option.name || ""}
                setSelectedValue={handleOnSelect}
                openOnFocus
                onClose={() => setShowDropdown(false)}
                TextFieldProps={{ autoFocus: true, style: { height: "40px", width: "200px" } }}
                ChipProps={{ width: "200px" }}
                data-public
              />
            )}
          </Box>
        )}
        {!isChipVisible && (
          <Box>
            <SpecialInlineButton startIcon={<GetApp />} onClick={onDownloadAttachment} data-public />
          </Box>
        )}
      </Grid>
      <Grid item container xs={12} className={classes.faxDetailsContainer}>
        <div>
          <Caption data-public>Fax from</Caption>
          <Body1 data-public>
            {fileData?.createdByFaxNumber
              ? parsePhoneNumber(fileData?.createdByFaxNumber, "US")?.formatNational()
              : "- -"}
          </Body1>
        </div>
        {fileData?.dateCreated && (
          <div>
            <Caption data-public>Added</Caption>
            <Body1 data-public>{formatDateStrWithTime(fileData.dateCreated)}</Body1>
          </div>
        )}
        {showFaxId && serviceCase?.faxDetail?.cohereId && (
          <div>
            <Caption data-public>Fax ID</Caption>
            <Body1 data-public>{serviceCase?.faxDetail?.cohereId}</Body1>
          </div>
        )}
        {showCaseId && serviceCase?.caseNumber && (
          <div>
            <Caption data-public>Case ID</Caption>
            <Body1 data-public>{serviceCase?.caseNumber}</Body1>
          </div>
        )}
        {showExternalId && (serviceCase?.externalSources?.at(0) || fileData.externalReferenceId) && (
          <div>
            <Caption data-public>External ID</Caption>
            <Body1 data-public>
              {serviceCase?.externalSources?.at(0)?.externalReferenceId || fileData.externalReferenceId}
            </Body1>
          </div>
        )}
      </Grid>
      {url && isChipVisible && (
        <Grid item xs={12}>
          <InlineButton startIcon={<GetApp />} onClick={onDownloadAttachment} data-public>
            Download
          </InlineButton>
        </Grid>
      )}
    </Grid>
  );
}
