import React from "react";
import { H2 } from "@coherehealth/common";
import {
  CarePath,
  ClinicalService,
  ServiceDeterminationProcedureCodeBucket,
  ServiceRequestResponse,
} from "@coherehealth/core-platform-api";
import { makeStyles } from "@material-ui/core";
import ServiceCard from "./ServiceCard";

interface Props {
  selectedBuckets: ServiceDeterminationProcedureCodeBucket[];
  carePathServices: (ClinicalService | undefined)[];
  nonCarePathServices: (ClinicalService | undefined)[];
  serviceRequests: ServiceRequestResponse[];
  primaryCarePath: CarePath | undefined;
  removeBucket: (bucket: ServiceDeterminationProcedureCodeBucket) => void;
  addBucket: (bucket: ServiceDeterminationProcedureCodeBucket) => void;
}

const useStyles = makeStyles((theme) => ({
  h2: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
  },
}));

export default function PatientAuthHistory({
  selectedBuckets,
  carePathServices,
  nonCarePathServices,
  serviceRequests,
  primaryCarePath,
  removeBucket,
  addBucket,
}: Props) {
  const classes = useStyles();

  return (
    <>
      {carePathServices.length > 0 && (
        <>
          <H2 className={classes.h2}>Previously requested for this patient for {primaryCarePath?.name}</H2>
          {carePathServices?.map((service) => (
            <ServiceCard
              key={service?.id}
              clinicalService={service}
              isSelected={Boolean(selectedBuckets?.find((bucket) => bucket.clinicalService?.id === service?.id))}
              patientAuthHistory={serviceRequests.filter((sr) => sr.clinicalService?.id === service?.id)}
              removeBucket={removeBucket}
              addBucket={addBucket}
              primaryCarePath={primaryCarePath}
            />
          ))}
        </>
      )}
      {nonCarePathServices.length > 0 && (
        <>
          <H2 className={classes.h2}>Other procedures previously requested for this patient</H2>
          {nonCarePathServices?.map((service) => (
            <ServiceCard
              key={service?.id}
              clinicalService={service}
              isSelected={Boolean(selectedBuckets?.find((bucket) => bucket.clinicalService?.id === service?.id))}
              patientAuthHistory={serviceRequests.filter((sr) => sr.clinicalService?.id === service?.id)}
              removeBucket={removeBucket}
              addBucket={addBucket}
              primaryCarePath={primaryCarePath}
              hideSelectButton
            />
          ))}
        </>
      )}
    </>
  );
}
