import React from "react";
import { Body1, Card, colorsLight, H4 } from "@coherehealth/common";
import BuildingIcon from "../images/BuildingIcon.svg";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";

export default function EmptyOrganizationResultCard() {
  return (
    <>
      <Card>
        <CardContents>
          <img src={BuildingIcon} alt={""} />
          <TextContainer>
            <H4>No Organizations Found...</H4>
            <LightBody1 display={"inline"}>
              Try adding additional TINs to locate an existing organization, or create a new one below
            </LightBody1>
          </TextContainer>
        </CardContents>
      </Card>
    </>
  );
}
// eslint-disable-next-line cohere-react/no-mui-styled-import
const CardContents = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
});
// eslint-disable-next-line cohere-react/no-mui-styled-import
const TextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  paddingLeft: theme.spacing(2),
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
const LightBody1 = styled(Body1)(({ theme }) => ({
  color: colorsLight.font.light,
  marginTop: theme.spacing(1),
}));
