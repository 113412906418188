import { Factory, Server, Model } from "miragejs";

export const models = { licenseAgreement: Model };

export const factories = {
  licenseAgreement: Factory.extend({
    text() {
      return "## ATTENTION\n\nPlease just do not sue us\n\n**Here are some reasons:**\n\n* We are nice\n\n* Seriously it would make us sad";
    },
    textHash() {
      return "ABC123";
    },
  }),
};

export const scenarios = {
  standard(server: Server) {
    server.create("licenseAgreement");
  },
};

export const activeScenario = "standard";
