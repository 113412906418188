import React, { PropsWithChildren } from "react";
import { Card } from "../Card";
import { makeStyles } from "@material-ui/core/styles";
import { SecondaryButton } from "../SecondaryButton";
import { CohereSecondaryButtonProps } from "../SecondaryButton/SecondaryButton";
import { ButtonProps } from "@material-ui/core/Button";

interface Props {
  bannerIcon: JSX.Element;
  backgroundColor: string;
  padding: number;
  buttonLabel?: string;
  buttonProps?: CohereSecondaryButtonProps & ButtonProps;
}

export default function FullPageBanner({
  bannerIcon,
  backgroundColor,
  padding,
  children,
  buttonLabel,
  buttonProps,
}: PropsWithChildren<Props>) {
  const classes = useStyles({ backgroundColor, padding });
  return (
    <Card className={classes.infoBanner} elevation={0}>
      <div className={classes.iconSpacing}>{bannerIcon}</div>
      <div className={classes.textContainer}>{children}</div>
      {buttonLabel && buttonProps && (
        <SecondaryButton {...buttonProps} className={classes.buttonContainer}>
          {buttonLabel}
        </SecondaryButton>
      )}
    </Card>
  );
}

type StyleProps = Pick<Props, "backgroundColor" | "padding">;

const useStyles = makeStyles((theme) => ({
  infoBanner: {
    backgroundColor: ({ backgroundColor }: StyleProps) => backgroundColor,
    padding: ({ padding }: StyleProps) => padding,
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    boxShadow: "none",
    borderRadius: theme.spacing(1),
  },
  iconSpacing: {
    marginLeft: theme.spacing(3),
    opacity: "100%",
  },
  buttonContainer: {
    marginLeft: "auto",
    marginRight: theme.spacing(3),
    opacity: "100%",
  },
  textContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(4),
  },
}));
