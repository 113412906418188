import { registerRoute } from "../server";

export default function registerRoutes() {
  registerRoute({
    id: "userActivity",
    route: "userActivity",
    method: "post", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: () => ({}),
  });
}
