import { ValidDisplayMessageAction } from "util/rule";
import MuiErrorIcon from "@material-ui/icons/Error";
import { Body1, Body2, Sanitized } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core";

interface PatientSearchRedirectMessageProps {
  action: ValidDisplayMessageAction;
}

export function PatientSearchRedirectMessage(props: PatientSearchRedirectMessageProps): JSX.Element {
  const { action } = props;
  const classes = useStyles();
  const messageHtml = action.message || "--";
  return (
    <div className={classes.redirectMessageContainer}>
      <MuiErrorIcon className={classes.errorIcon} />
      <div className={classes.redirectMessageText}>
        {action.title && action.message ? (
          <>
            <Body2 className={classes.warningBody}>{action.title}</Body2>
            <Body1 className={classes.secondaryBody}>
              <Sanitized __html={messageHtml} />
            </Body1>
          </>
        ) : action.message ? (
          <Body2 className={classes.warningBody}>
            <Sanitized __html={messageHtml} />
          </Body2>
        ) : (
          <Body2 className={classes.warningBody}>
            This member cannot be selected. Please contact Cohere for more information
          </Body2>
        )}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  redirectMessageContainer: {
    paddingTop: theme.spacing(2),
    display: "flex",
  },
  redirectMessageText: {
    padding: theme.spacing(0, 1, 1, 1),
  },
  errorIcon: {
    color: theme.palette.warning.dark,
  },
  warningBody: {
    color: theme.palette.warning.dark,
  },
  secondaryBody: {
    paddingTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));
