import { AppBar, Container, Divider, Grid, makeStyles, useTheme } from "@material-ui/core";
import { InformativeModal, PrimaryButton, SecondaryButton, useMuiContainerStyles } from "@coherehealth/common";
import CommonFaxFooter from "components/DocumentViewer/FaxAttachment/CommonFaxFooter";
import { useState } from "react";
import ClipboardMissingInfo from "components/images/ClipboardMissingInfo";

interface FaxServiceEditFooterProps {
  onSaveClick: () => void;
  onCancelClick: () => void;
  saveButtonDisabled?: boolean;
  saveButtonLoading?: boolean;
  showCancelButton?: boolean;
  cancelButtonLoading?: boolean;
  cancelButtonDisabled?: boolean;
  isFaxEditSRFlow?: boolean;
}

interface Props {
  isFaxEditSRFlow?: boolean;
}

const useFooterStyles = makeStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    top: "auto",
    bottom: 0,
    width: ({ isFaxEditSRFlow }: Props) => (isFaxEditSRFlow ? 608 : undefined),
    boxShadow: ({ isFaxEditSRFlow }: Props) =>
      isFaxEditSRFlow
        ? "0px 1px 10px 0px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14)"
        : undefined,
  },
  colorPrimary: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
  },
}));

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    maxWidth: "100%",
  },
  caption: {
    color: theme.palette.error.dark,
  },
  buttonContainer: {
    minWidth: theme.spacing(25),
  },
  cancelButton: {
    alignItems: "flex-start",
    margin: "0px 8px 0px 12px",
    color: theme.palette.error.dark,
  },
  rightButton: {
    minWidth: theme.spacing(25),
  },
  divider: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function EditSRFooter({
  isFaxEditSRFlow,
  showCancelButton,
  onCancelClick,
  cancelButtonDisabled,
  cancelButtonLoading,
  saveButtonDisabled,
  saveButtonLoading,
  onSaveClick,
}: FaxServiceEditFooterProps) {
  const classes = useStyles();
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const theme = useTheme();
  return isFaxEditSRFlow ? (
    <>
      <CommonFaxFooter
        onCancelButtonClick={() => setCancelModalOpen(true)}
        cancelButtonLoading={cancelButtonLoading}
        disabledFinish={saveButtonDisabled}
        primaryButtonLoading={saveButtonLoading}
        onPrimaryButtonClick={onSaveClick}
      />
      <InformativeModal
        open={cancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        icon={<ClipboardMissingInfo style={{ color: theme.palette.warning.dark }} />}
        headerText="Are you sure you want to cancel this edit?"
        subHeaderText="Your progress will not be saved."
        primaryButtonText="Yes, cancel"
        primaryButtonAction={onCancelClick}
        tertiaryButtonText="No, return to edit request"
        tertiaryButtonAction={() => setCancelModalOpen(false)}
        showDivider
      />
    </>
  ) : (
    <Grid container className={classes.gridContainer} alignItems={"center"}>
      <Grid container spacing={4} alignItems={"flex-end"} justifyContent={"flex-end"}>
        {showCancelButton && onCancelClick && (
          <Grid item className={classes.buttonContainer}>
            <SecondaryButton
              disabled={cancelButtonDisabled}
              warning
              fullWidth
              onClick={() => {
                onCancelClick();
              }}
              loading={cancelButtonLoading}
            >
              Cancel
            </SecondaryButton>
          </Grid>
        )}
        <Divider className={classes.divider} />
        <Grid item className={classes.buttonContainer}>
          <PrimaryButton
            disabled={saveButtonDisabled}
            fullWidth
            onClick={() => {
              onSaveClick();
            }}
            loading={saveButtonLoading}
          >
            Done
          </PrimaryButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

const FaxServiceEditFooter = ({
  onSaveClick,
  onCancelClick,
  saveButtonDisabled,
  showCancelButton,
  saveButtonLoading,
  cancelButtonLoading,
  cancelButtonDisabled,
  isFaxEditSRFlow,
}: FaxServiceEditFooterProps) => {
  const footerClasses = useFooterStyles({ isFaxEditSRFlow });
  const containerClasses = useMuiContainerStyles();
  return (
    <AppBar classes={footerClasses} component="footer" elevation={0}>
      <Container classes={containerClasses}>
        <EditSRFooter
          isFaxEditSRFlow={isFaxEditSRFlow}
          onSaveClick={onSaveClick}
          onCancelClick={onCancelClick}
          saveButtonDisabled={saveButtonDisabled}
          showCancelButton={showCancelButton}
          saveButtonLoading={saveButtonLoading}
          cancelButtonLoading={cancelButtonLoading}
          cancelButtonDisabled={cancelButtonDisabled}
        />
      </Container>
    </AppBar>
  );
};

export default FaxServiceEditFooter;
