import { useContext, useEffect, useState, useMemo, useCallback, useRef, SetStateAction } from "react";
import { useNavigate, useMatch, useLocation, Location, PathMatch } from "react-router";
import { ObjectID } from "bson";
import routes from "routes";
import { AppBar, Container, makeStyles } from "@material-ui/core";
import { headerHeight, slimFaxHeaderHeight } from "util/StyleConstants";
import {
  useFeature,
  useMuiContainerStyles,
  COHERE_NAME,
  useParsedQueryString,
  parseDateFromISOStringWithoutFallback,
  TargetFieldLabel,
  containerSmallBreakpoint,
  useGetAuthorizationByIdWithFallback,
  useGetServiceRequestByIdWithFallback,
} from "@coherehealth/common";
import CircularProgress from "@material-ui/core/CircularProgress";
import GatherRequirementsContainer from "./GatherRequirements";
import GatherRequirementsFooter from "./GatherRequirements/Footer";
import ServiceSelectionContainer from "./ServiceSelection";
import ServiceSelectionFooter from "./ServiceSelection/Footer";
import { GeneralAuthSubmissionForms } from "./FillFormsGeneralAuthSubmission";
import FillFormsFooter from "./FillForms/Footer";
import ClinicalAssessmentContainer from "./ClinicalAssessment";
import ClinicalAssessmentFooter from "./ClinicalAssessment/Footer";
import AttachmentsContainer from "./Attachments";
import AttachmentsFooter from "./Attachments/Footer";
import ReviewContainer from "./Review";
import ReviewFooter from "./Review/Footer";
import GatherRequirementsPharmacyContainer from "./GatherRequirementsPharmacy";
import ReviewNudgeContainer from "./ReviewNudges";
import ReviewNudgeFooter from "./ReviewNudges/Footer";
import {
  Attachment,
  AuthBuilderWorkflowStep,
  CarePathJourney,
  ClinicalService,
  HealthHelpPASRequestResponse,
  ProcedureCode,
  Requestor,
  RuleActions,
  ServiceDeterminationProcedureCodeBucket,
  ServiceRequestResponse,
  useGetCarePath,
  useGetClinicalAssessment,
  useGetPatient,
  useGetServiceRequests,
  Vendor,
  useGetUserById,
  useGetServiceRequestRuleActions,
  PlaceOfService,
  useGetServiceRequestAttachments,
  useGetCarePathJourneyAttachments,
  useGetBulkServiceRequestRuleActions,
  useGetBulkSearchableStatements,
  ClinicalAssessmentResponse,
  useGetMultiSingleServiceClinicalAssessment,
  useGetCandidateSuggestedValues,
  useGetDiagnosisCodes,
  useGetProcedureCodes,
  SuggestedValue,
  useGetPlaceOfServices,
  useGetProvidersOrFacilities,
  RequestorChannel,
  ServiceDeterminationResponse,
  FaxAttachment,
  CardExtensionProcedureCode,
  FacilityCategory,
  useUpdateServiceRequest,
  RecommendChangeRuleAction,
  CardExtensionDetails,
} from "@coherehealth/core-platform-api";
import ServiceDetailsContainer from "./ServiceDetails/ServiceDetailsContainer";
import {
  PriorAuthRequirements,
  ServiceRequestFormStateSetters,
  NonCohereCodes,
  AuthFlowType,
  convertStringToAuthBuilderWorkflowStep,
  useDoPalCheck,
  navigateToPS,
  navigateToSRSummary,
  shouldIncludeFacilityBasedFields,
  useGetFaxAttachmentFromUrl,
  fetchRuleActions,
  useSetCrdLogIdAndUpdateServiceRequest,
} from "./common";
import { formContentFromResponse } from "util/serviceRequest";
import { error as logError, stringifyError } from "logger";
import {
  ServiceRequestFormContent,
  AdditionalCareParticipantFormContent,
} from "common/SharedServiceRequestFormComponents";
import { useSnackbar } from "notistack";
import { useAuthorized, useBelongsToOpsGroup } from "authorization";
import { ClinicalAssessmentContext, ClinicalAssessmentContextProvider } from "components/ClinicalAssessment";
import { Props as RequestorProps } from "components/Requestor/RequestorCard";
import listRemove from "util/listRemove";
import { getCoverageBasedOnDate, getPatientHealthPlanName, getPatientRiskBearingEntity } from "util/patientUtils";
import Box from "@material-ui/core/Box";
import VendorClinicalAssessmentFooter from "./VendorClinicalAssessment/Footer";
import { UserContext, User } from "UserContext";
import VendorAssessmentContainer from "./VendorClinicalAssessment/VendorAssessmentContainer";
import { useTheme } from "@material-ui/core/styles";
import GatherRequirementsPharmacyFooter from "./GatherRequirementsPharmacy/Footer";
import usePollForClinicalAssessment from "hooks/usePollForClinicalAssessment";
import AssessmentErrorModal from "./ClinicalAssessment/AssessmentErrorModal";
import { groupAuthAutoApproves, isProviderFastTrack } from "util/rule";
import { FaxAttachmentContext } from "../DocumentViewer/FaxAttachment/FaxAttachmentContext";
import { SuggestionContext } from "./SuggestionContext";
import { warn as logWarning } from "logger";

// * Code tag RECENT_PROVIDERS_8B12CDB1
import { RecentProvidersProvider } from "common/RecentProviders/context";

import {
  initSuggestedRequestor,
  initSuggestedPriorAuthRequirements,
  generateInitialPriorAuthRequirementsWithSuggestions,
  initSuggestedFormContent,
  SuggestedRequestor,
  SuggestedPriorAuthRequirements,
  SuggestedFormContent,
} from "../../util/suggestionUtils";
import FillFormsContinuationContainer from "./FillFormsContinuation";
//TODO: clean up by June 1, 2023 -- COH - 3422
import FillFormsFaxContainer from "./FillFormsFax";
import FillFormsFaxFooter from "./FillFormsFax/Footer";
import { ServiceSelectionFlexibleIntake } from "./ServiceSelectionFlexibleIntake";
import ServiceSelectionFlexibleCodeFooter from "./ServiceSelectionFlexibleIntake/Footer";
import { HEALTH_HELP_V2_NAME } from "@coherehealth/common";
import { HEALTH_HELP_NAME } from "@coherehealth/common";
import useGetFacilityBasedRequestConfigurationByPayer, {
  useGetServiceRequestEditConfigurationByPayerAndAuthStatus,
  useGetTemporaryMemberAuthsConfigurationByPayer,
  useSetScrubTinsContextByHealthPlanConfiguration,
} from "hooks/useGetFeatureConfigurations";
import ProviderDetailsFooter from "./ProviderDetails/ProviderDetailsFooter";
import ServiceDetailsFooter from "./ServiceDetails/ServiceDetailsFooter";
import ProviderDetailsContainer from "./ProviderDetails/ProviderDetailsContainer";
import { FiredNudgesContext } from "./FiredNudgesContext";
import { SmartOnFhirContext } from "components/SmartOnFhir/SmartOnFhirSecurityProvider";
import {
  getIntersectionObservationCodesAndSearchStatements,
  setStateBeforeBackFromAttachments,
  setStateBeforeBackFromAttachmentsContinuation,
} from "util/workflowUtils";
import { getTargetsForAction } from "util/NudgeUtils";
import ScrubTinsContextProvider from "../ScrubTinsContext";
import { getParametersFaxIntake, usePathWithAuthBuilderStep } from "util/queryParams";
import PreApprovedCareContainer from "./PreApprovedCare";
import PreApprovedCareFooter from "./PreApprovedCare/Footer";
import listReplace from "../../util/listReplace";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";
import { strToPhoneNumber } from "util/phoneUtils";
import AuthBuilderHeader from "./Header";
import { NavigationContext } from "./NavigationContext";
import { useHealthPlanDisplayNameContext } from "../../util/context/HealthPlanDisplayNameContext";
import { ParsedQs } from "qs";
import { Helmet } from "react-helmet-async";
import { addBreadcrumb } from "@sentry/react";
import { usePatientContext } from "util/context/PatientContext";

export type ClinicalAssessmentProvider = Vendor | undefined;

interface FooterStyleProps {
  isFaxAuthBuilderFlow?: boolean;
}

const useFooterStyles = makeStyles((theme) => ({
  root: ({ isFaxAuthBuilderFlow }: FooterStyleProps) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    top: "auto",
    bottom: 0,
    width: isFaxAuthBuilderFlow ? containerSmallBreakpoint : "100%",
  }),
  colorPrimary: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
  },
}));

function AuthBuilderPage() {
  //patient route match
  const match = useMatch(routes.AUTH_BUILDER);
  const smartOnFhirMatch = useMatch(routes.SMART_ON_FHIR_AUTH_BUILDER);

  // Fax info received as url params when redirected from Fax intake workflow
  const location = useLocation();
  const search = useMemo(() => new URLSearchParams(location.search), [location]);
  const qmCaseId = search.get("caseId");
  const queueId = search.get("queueId") || "";
  const faxId = search.get("faxId") || "";

  return (
    <>
      <Helmet>
        <title>Cohere | New Auth Request</title>
      </Helmet>

      <AuthBuilderContentWithContext
        match={match}
        smartOnFhirMatch={smartOnFhirMatch}
        location={location}
        search={search}
        qmCaseId={qmCaseId}
        queueId={queueId}
        faxId={faxId}
      />
    </>
  );
}

interface AuthBuilderUrlParams {
  qmCaseId: string | null;
  queueId: string;
  faxId: string;
}

interface Props extends AuthBuilderUrlParams {
  match: PathMatch<string> | null;
  smartOnFhirMatch: PathMatch<string> | null;
  location: Location;
  search: URLSearchParams;
}

function AuthBuilderContent({ match, smartOnFhirMatch, location, search, qmCaseId, queueId, faxId }: Props) {
  //general use hooks
  const { enqueueSnackbar } = useSnackbar();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);
  const footerClasses = useFooterStyles({ isFaxAuthBuilderFlow });
  const containerClasses = useMuiContainerStyles();
  const { setPatientData } = usePatientContext();
  const { setHealthPlanDisplayNameContext } = useHealthPlanDisplayNameContext();

  //feature flags and authorization flags
  const disableContinueOONCheck = useFeature("disableContinueOON");
  const requestorFormAuthorized = useAuthorized("REQUESTOR_FORM");
  const pharmacyAuthBuilderFlowEnabled = useFeature("pharmacyAuthBuilderFlow");
  const autoApprovalSkipCAQ = useFeature("autoApprovalSkipCAQ");
  const fastTrackProvider = useFeature("fastTrackProvider");
  const multiSingleService = useFeature("multiSingleService");
  const slimServiceSelect = useFeature("slimServiceSelect");

  //patient route match
  const smartClient = useContext(SmartOnFhirContext);
  const inSmartOnFhirWorkflow = Boolean(smartClient);

  const workflowLength = search.get("workflowLength") || "";
  const isNewContinuationSR = useMemo(() => location?.state?.isContinuationWorkflow, [location]);
  const isFaxIntakeFlow = Boolean(faxId);
  const faxData: FaxAttachment | null = useGetFaxAttachmentFromUrl();

  // Suggestions Hook
  const cohereSuggestedValueFeatureEnabled = useFeature("cohereSuggestedValueFeatureEnabled");
  const geisingerSuggestedValueFeatureEnabled = useFeature("geisingerSuggestedValueFeatureEnabled");
  const isGhpUser = useBelongsToOpsGroup("GEISINGER");

  const { data: suggestions, refetch: fetchSuggestions } = useGetCandidateSuggestedValues({
    attachmentId: faxId || "",
    queryParams: { rankResults: true },
    lazy: true,
  });

  const shouldUseSuggestions: boolean =
    isFaxIntakeFlow &&
    ((isGhpUser && geisingerSuggestedValueFeatureEnabled) ||
      (isGhpUser === false && cohereSuggestedValueFeatureEnabled));

  useEffect(() => {
    if (shouldUseSuggestions) {
      fetchSuggestions();
    }
  }, [shouldUseSuggestions, fetchSuggestions]);

  const patientId = inSmartOnFhirWorkflow ? smartOnFhirMatch?.params.patientId : match?.params.patientId;

  const { data: patientData, loading: loadingPatientData } = useGetPatient({
    id: patientId || "",
  });

  const healthPlan = getPatientHealthPlanName(patientData || undefined);
  const riskBearingEntity = getPatientRiskBearingEntity(patientData || undefined);
  useEffect(() => {
    //set patient data to context
    if (patientData) {
      setPatientData(patientData);
    }
    //set setHealthPlanDisplayNameContext after patient data has been set to ensure only single request is made
    if (healthPlan) {
      setHealthPlanDisplayNameContext(healthPlan);
    }
  }, [patientData, healthPlan, setPatientData, setHealthPlanDisplayNameContext]);

  const { data: temporaryMemberAuthsConfig } = useGetTemporaryMemberAuthsConfigurationByPayer(healthPlan ?? "");

  // Unique identifier created to correlate service request to transient rule runs and select variable actions
  const [workflowId] = useState(new ObjectID().toString());
  const [hasDuplicates, setHasDuplicates] = useState(false);

  //requestor state
  const [collapseRequestorForm, setCollapseRequestorForm] = useState(false);
  const [requestor, setRequestor] = useState<Requestor | undefined>({
    requestorType: "ORDERING_PROVIDER",
    channel: "PORTAL",
  });
  const [selectedClinicalServiceIds, setSelectedClinicalServiceIds] = useState<Record<string, string[]>>({});
  const [previousClinicalServiceIds, setPreviousClinicalServiceIds] = useState<Record<string, string[]>>({});
  const [isFormContentOnContinuationUpdated, setIsFormContentOnContinuationUpdated] = useState(false);
  const [selectedCarePathId, setSelectedCarePathId] = useState<string | undefined>(undefined);

  const [footerHeight, setFooterHeight] = useState(0);

  // useRef() won't trigger a re-rendering and lives for the lifetime of the component
  const suggestedRequestor = useRef<SuggestedRequestor>();

  useEffect(() => {
    if (suggestions) {
      setRequestor((currentRequestor) => {
        suggestedRequestor.current = initSuggestedRequestor(suggestions);
        return (
          currentRequestor || {
            firstName: suggestedRequestor.current?.firstName?.predictedValue,
            lastName: suggestedRequestor.current?.lastName?.predictedValue,
            email: suggestedRequestor.current?.email?.predictedValue,
            faxNumber: suggestedRequestor.current?.faxNumber?.predictedValue
              ? { number: suggestedRequestor.current?.faxNumber?.predictedValue }
              : undefined,
            phoneNumber: suggestedRequestor.current?.phoneNumber?.predictedValue
              ? { number: suggestedRequestor.current?.phoneNumber?.predictedValue }
              : undefined,
            channel: "FAX" as RequestorChannel,
          }
        );
      });
    }
  }, [suggestions, setRequestor]);

  useEffect(() => {
    if (inSmartOnFhirWorkflow) {
      setRequestor((currentRequestor) => ({
        ...currentRequestor,
        channel: "PORTAL_EHR",
      }));
    }
  }, [inSmartOnFhirWorkflow, setRequestor]);

  const [userUpdatedTat, setUserUpdatedTat] = useState<boolean>(false);

  const requestorProps = useMemo(() => {
    return {
      requestor: requestor,
      setRequestor: setRequestor,
      requestorFormAuthorized: requestorFormAuthorized,
      setCollapseRequestorForm: setCollapseRequestorForm,
      collapseRequestorForm: collapseRequestorForm,
      setUserUpdatedTat: setUserUpdatedTat,
    };
  }, [collapseRequestorForm, requestor, requestorFormAuthorized]);

  //high level auth builder state
  const [carePathJourney, setCarePathJourney] = useState<CarePathJourney>();
  const [workflowStep, setWorkflowStepInternal] = useState<AuthBuilderWorkflowStep>("GATHER_REQUIREMENTS_MEDICAL");
  const [authFlowType, setAuthFlowType] = useState<AuthFlowType>("MEDICAL");
  const [draftSRId, setDraftSRId] = useState<string>("");
  const [queueMgmtCaseId, setQueueMgmtCaseId] = useState<string>("");

  const navigate = useNavigate();

  const highmarkWorkflow = usePathWithAuthBuilderStep("FILL_FORMS_FAX");
  const { draftServiceRequestId, caseId: qmServiceCaseId, faxStep, authBuilderStep } = useParsedQueryString();
  const pathWithUpdatedWorkflowStep = usePathWithAuthBuilderStep(workflowStep);

  //gather requirements state
  // stateful suggestedPAR due to dependency on numerous async API calls
  const [suggestedPriorAuthRequirements, setSuggestedPriorAuthRequirements] = useState<SuggestedPriorAuthRequirements>({
    showPxCheckbox: false,
    noPxServiceRequired: false,
  });
  // primary dx code fetch hooks
  const { data: suggestedPrimaryDxCodes, refetch: fetchSuggestedPrimaryDxCode } = useGetDiagnosisCodes({
    lazy: true,
  });
  const getSuggestedPrimaryDxCode = useCallback(
    async (code) => {
      await fetchSuggestedPrimaryDxCode({
        queryParams: {
          code: code,
          max: 1,
          isBillable: true,
        },
      });
    },
    [fetchSuggestedPrimaryDxCode]
  );

  // secondary dx codes fetch hooks
  const { data: suggestedSecondaryDxCodes, refetch: fetchSuggestedSecondaryDxCodes } = useGetDiagnosisCodes({
    lazy: true,
  });
  const getSuggestedSecondaryDxCodes = useCallback(
    async (codes: string[]) => {
      await fetchSuggestedSecondaryDxCodes({
        queryParams: {
          termList: `${codes.join(",")}`,
          max: 9,
        },
      });
    },
    [fetchSuggestedSecondaryDxCodes]
  );

  // px codes fetch hooks
  const { data: suggestedPxCodes, refetch: fetchSuggestedPxCodes } = useGetProcedureCodes({
    lazy: true,
  });
  const getSuggestedPxCodes = useCallback(
    async (codes: string[]) => {
      await fetchSuggestedPxCodes({
        queryParams: {
          termList: `${codes.join(",")}`,
          max: 10,
        },
      });
    },
    [fetchSuggestedPxCodes]
  );

  useEffect(() => {
    if (suggestions?.primarySemanticDiagnosisCode?.length) {
      getSuggestedPrimaryDxCode(suggestions.primarySemanticDiagnosisCode[0].predictedValue);
    }
    if (suggestions?.secondarySemanticDiagnosisCodes?.length) {
      const codes: string[] = suggestions.secondarySemanticDiagnosisCodes.map(
        (suggestion: SuggestedValue) => suggestion.predictedValue
      );
      getSuggestedSecondaryDxCodes(codes);
    }
    if (suggestions?.semanticProcedureCodes?.length) {
      const codes: string[] = suggestions.semanticProcedureCodes.map(
        (suggestion: SuggestedValue) => suggestion.predictedValue
      );
      getSuggestedPxCodes(codes);
    }
  }, [suggestions, getSuggestedPrimaryDxCode, getSuggestedSecondaryDxCodes, getSuggestedPxCodes]);

  // Only when suggestions are fetched and when external references finish fetching
  useEffect(() => {
    setSuggestedPriorAuthRequirements((currentSuggestedPAR) => {
      return initSuggestedPriorAuthRequirements(
        suggestions,
        suggestedPrimaryDxCodes,
        suggestedSecondaryDxCodes,
        suggestedPxCodes,
        currentSuggestedPAR
      );
    });
  }, [suggestions, suggestedPrimaryDxCodes, suggestedSecondaryDxCodes, suggestedPxCodes]);

  const [priorAuthRequirements, setPriorAuthRequirements] = useState<PriorAuthRequirements>(
    generateInitialPriorAuthRequirementsWithSuggestions()
  );

  // Only sets suggestions when changes in suggestedPriorAuthRequirements occur (implicitly only when relevant field suggestion is pulled)
  // and when the priorAuthRequirements field at hand is in its initial state
  useEffect(() => {
    setPriorAuthRequirements((currentPAR) => {
      return generateInitialPriorAuthRequirementsWithSuggestions(suggestedPriorAuthRequirements, currentPAR, true);
    });
  }, [suggestedPriorAuthRequirements]);

  const [palProcedureCodes, setPalProcedureCodes] = useState<ProcedureCode[]>([]);
  const [nonPalProcedureCodes, setNonPalProcedureCodes] = useState<ProcedureCode[]>([]);
  const [nonCohereCodes, setNonCohereCodes] = useState<NonCohereCodes[]>([]);
  const [primaryCarePathId, setPrimaryCarePathId] = useState<string>();
  const [noPxService, setNoPxService] = useState<ClinicalService | undefined>();
  const [crdLogId, setCrdLogId] = useState<string>();
  const [crdProcedureCodeResults, setCrdProcedureCodeResults] = useState<CardExtensionProcedureCode[] | undefined>();
  const [crdExtensionCardResults, setCrdExtensionCardResults] = useState<CardExtensionDetails | undefined>();

  // nudge state
  const [updatedClinicalServices, setUpdatedClinicalServices] = useState<string[] | undefined>();
  const [firedRecommendedNudges, setFiredRecommendedNudges] = useState<RuleActions>([]);

  //gather requirements pharmacy state
  const [attemptedGatherRequirementsSubmit, setAttemptedGatherRequirementsSubmit] = useState<boolean>(false);

  //service selection & fill forms state: three lists below are connected by index
  const [suggestedFormContent, setSuggestedFormContent] = useState<SuggestedFormContent>({});

  //flexible code intake: service selections
  const [serviceDeterminationResponse, setServiceDeterminationResponse] = useState<ServiceDeterminationResponse>();
  const { data: suggestedPlaceOfService, refetch: fetchSuggestedPosByCode } = useGetPlaceOfServices({
    lazy: true,
  });

  const getSuggestedPosByCode = useCallback(
    async (code) => {
      await fetchSuggestedPosByCode({
        queryParams: {
          query: code,
        },
      });
    },
    [fetchSuggestedPosByCode]
  );
  const { data: suggestedOrderingProviderCatalogue, refetch: fetchOrderingProviderCatalogueBySuggestedNpiOrTin } =
    useGetProvidersOrFacilities({
      lazy: true,
    });

  const getSuggestedOrderingProviderByNpiOrTin = useCallback(
    async (npiOrTin) => {
      await fetchOrderingProviderCatalogueBySuggestedNpiOrTin({
        queryParams: {
          query: npiOrTin,
          recordType: "PROVIDER",
          healthPlanName: healthPlan || undefined,
        },
      });
    },
    [fetchOrderingProviderCatalogueBySuggestedNpiOrTin, healthPlan]
  );

  const { data: suggestedPerformingProviderCatalogue, refetch: fetchPerformingProviderCatalogueBySuggestedNpiOrTin } =
    useGetProvidersOrFacilities({
      lazy: true,
    });

  const getSuggestedPerformingProviderByNpiOrTin = useCallback(
    async (npiOrTin) => {
      await fetchPerformingProviderCatalogueBySuggestedNpiOrTin({
        queryParams: {
          query: npiOrTin,
          recordType: "PROVIDER",
          healthPlanName: healthPlan || undefined,
        },
      });
    },
    [fetchPerformingProviderCatalogueBySuggestedNpiOrTin, healthPlan]
  );

  const { data: suggestedFacilityCatalogue, refetch: fetchFacilityCatalogueBySuggestedNpiOrTin } =
    useGetProvidersOrFacilities({
      lazy: true,
    });

  const getSuggestedFacilityByNpiOrTin = useCallback(
    async (npiOrTin) => {
      await fetchFacilityCatalogueBySuggestedNpiOrTin({
        queryParams: {
          query: npiOrTin,
          recordType: "FACILITY",
          healthPlanName: healthPlan || undefined,
        },
      });
    },
    [fetchFacilityCatalogueBySuggestedNpiOrTin, healthPlan]
  );

  // get relevant suggestions' referential entities
  useEffect(() => {
    if (suggestions?.placeOfServiceCode?.length) {
      getSuggestedPosByCode(suggestions.placeOfServiceCode[0].predictedValue);
    }

    if (suggestions?.orderingProviderNpi?.length) {
      getSuggestedOrderingProviderByNpiOrTin(suggestions.orderingProviderNpi[0].predictedValue);
    } else if (suggestions?.orderingProviderSelectedTin?.length) {
      getSuggestedOrderingProviderByNpiOrTin(suggestions.orderingProviderSelectedTin[0].predictedValue);
    }

    if (suggestions?.performingProviderNpi?.length) {
      getSuggestedPerformingProviderByNpiOrTin(suggestions.performingProviderNpi[0].predictedValue);
    } else if (suggestions?.performingProviderSelectedTin?.length) {
      getSuggestedPerformingProviderByNpiOrTin(suggestions.performingProviderSelectedTin[0].predictedValue);
    }

    if (suggestions?.facilityNpi?.length) {
      getSuggestedFacilityByNpiOrTin(suggestions.facilityNpi[0].predictedValue);
    } else if (suggestions?.facilitySelectedTin?.length) {
      getSuggestedFacilityByNpiOrTin(suggestions.facilitySelectedTin[0].predictedValue);
    }
  }, [
    suggestions,
    getSuggestedPosByCode,
    getSuggestedOrderingProviderByNpiOrTin,
    getSuggestedPerformingProviderByNpiOrTin,
    getSuggestedFacilityByNpiOrTin,
  ]);
  useEffect(() => {
    setSuggestedFormContent((currentSuggestedFormContent) => {
      return initSuggestedFormContent(
        suggestions,
        suggestedPlaceOfService,
        suggestedOrderingProviderCatalogue,
        suggestedPerformingProviderCatalogue,
        suggestedFacilityCatalogue,
        currentSuggestedFormContent
      );
    });
  }, [
    suggestions,
    suggestedPlaceOfService,
    suggestedOrderingProviderCatalogue,
    suggestedPerformingProviderCatalogue,
    suggestedFacilityCatalogue,
  ]);

  const [buckets, setBuckets] = useState<ServiceDeterminationProcedureCodeBucket[]>([]);
  const [serviceRequestFormsCanBeSubmitted, setServiceRequestFormsCanBeSubmitted] = useState<boolean[]>([]);
  const [serviceRequestFormsHaveNewEdits, setServiceRequestFormsHaveNewEdits] = useState<boolean[]>([]);
  const [serviceRequestFormContents, setServiceRequestFormContents] = useState<ServiceRequestFormContent[]>([]);
  const [serviceRequests, setServiceRequests] = useState<ServiceRequestResponse[]>([]);
  const [attemptedServiceRequestFormsSubmit, setAttemptedServiceRequestFormsSubmit] = useState(false);
  const [attemptedServiceDetailsSubmit, setAttemptedServiceDetailsSubmit] = useState(false);
  const { getUser } = useContext(UserContext);
  const [currUser, setUser] = useState<User>();
  const [pharmErrorRedirectModalOpen, setPharmErrorRedirectModalOpen] = useState(false);
  const [providerDetailsFormsCanBeSubmitted, setProviderDetailsFormsCanBeSubmitted] = useState<boolean[]>([]);
  const [allowDischargeModalToOpen, setAllowDischargeModalToOpen] = useState(false);
  const [serviceDetailsFormsCanBeSubmitted, setServiceDetailsFormsCanBeSubmitted] = useState<boolean[]>([]);
  const activeCoverage =
    getCoverageBasedOnDate(priorAuthRequirements?.startDate ?? new Date(), patientData ?? undefined) ?? undefined;

  /**
   * Whenever temporaryMemberAuthsConfig loads and activeCoverage?.planType === careRecommendationPlanType
   * set isCareRecommendationRequest to true
   */
  useEffect(() => {
    const { enabled, careRecommendationPlanType } = temporaryMemberAuthsConfig ?? {};
    if (enabled && activeCoverage?.planType && careRecommendationPlanType) {
      setRequestor((currentRequestor) => ({
        ...currentRequestor,
        isCareRecommendationRequest: activeCoverage?.planType === careRecommendationPlanType,
      }));
    }
  }, [activeCoverage?.planType, temporaryMemberAuthsConfig]);

  const serviceRequestFormStateSetters: ServiceRequestFormStateSetters = useMemo(
    () => ({
      setServiceRequestFormContents,
      setServiceRequestFormsCanBeSubmitted,
      setServiceRequestFormsHaveNewEdits,
      setProviderDetailsFormsCanBeSubmitted,
    }),
    [
      setServiceRequestFormContents,
      setServiceRequestFormsCanBeSubmitted,
      setServiceRequestFormsHaveNewEdits,
      setProviderDetailsFormsCanBeSubmitted,
    ]
  );

  const setFirstServiceRequestFormContent = useCallback(
    (setter: SetStateAction<ServiceRequestFormContent>) => {
      if (typeof setter === "function") {
        setServiceRequestFormContents((curr) => [setter(curr[0])]);
      } else {
        setServiceRequestFormContents([setter]);
      }
    },
    [setServiceRequestFormContents]
  );

  const { data: draftServiceRequest, refetch: refetchDraftServiceRequest } = useGetServiceRequestByIdWithFallback({
    id: draftSRId,
    lazy: true,
  });

  const hasPxCodes =
    draftServiceRequest &&
    draftServiceRequest?.semanticProcedureCodes &&
    draftServiceRequest?.semanticProcedureCodes?.length > 0;
  const isInpatientDraft = draftServiceRequest && draftServiceRequest?.encounterType === "INPATIENT";

  useEffect(() => {
    if (isInpatientDraft) {
      setPriorAuthRequirements((prevRequirements) => {
        return { ...prevRequirements, encounterType: "INPATIENT" };
      });
    }
  }, [isInpatientDraft]);

  const { mutate: updateDraftServiceRequest } = useUpdateServiceRequest({ id: draftSRId });
  const setCrdLogIdAndUpdateServiceRequest = useSetCrdLogIdAndUpdateServiceRequest({
    setCrdLogId,
    serviceRequestId: draftSRId,
    updateServiceRequest: updateDraftServiceRequest,
  });

  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName: healthPlan ?? "",
    encounterType: priorAuthRequirements?.encounterType,
    skipRequestTimingCheck: true,
  });

  // Do PalCheck when redirecting to Drafts
  const { doPalCheck, palCheckLoading } = useDoPalCheck({
    canCheckPAL: hasPxCodes || (isInpatientDraft && facilityBasedFeatureEnabled) || false,
    noPxService,
    patientId: patientId || "",
    patientData: patientData,
    setNonCohereCodes,
    setNonPalProcedureCodes,
    setPalProcedureCodes,
    setPriorAuthRequirements,
    setCrdLogId: setCrdLogIdAndUpdateServiceRequest,
    setCrdProcedureCodeResults,
    setCrdExtensionCardResults,
    serviceRequestFormContents: serviceRequestFormContents,
  });

  //clinical assessment state
  const { clinicalAssessment, updateClinicalAssessment } = useContext(ClinicalAssessmentContext);
  const [caqSkipped, setCaqSkipped] = useState<boolean>(false);
  const emptyClinicalAssessmentContext = clinicalAssessment?.questions?.length === 0;

  //vendor assessment state
  const [vendorAssessmentError, setVendorAssessmentError] = useState<boolean>(false);

  const [canContinueToClinicalAssessment, setCanContinueToClinicalAssessment] = useState(false);

  // Attachments rule actions
  const [attachmentsRuleActions, setAttachmentsRuleActions] = useState<RuleActions>();

  //Clinical assessment questions bulk searchable statements
  const [clinicalAssessmentSnomedCodes, setClinicalAssessmentSnomedCodes] = useState<Set<string>>();
  const [serviceRequestPatientAttributes, setServiceRequestPatientAttributes] = useState<Set<string>>();

  const initialRenderForDraftServiceRequestsID = useRef("");

  //review state
  const [preSubmissionRedirectActions, setPreSubmissionRedirectActions] = useState<RuleActions>();
  const [attemptedSubmitServices, setAttemptedSubmitServices] = useState(false);

  //review editing state
  const [reviewEdit, setReviewEdit] = useState<boolean>(false);
  const [disableContinueButtonOnContinuation, setDisableContinueButtonOnContinuation] = useState<boolean>(false);

  //clinical assessment navigation state
  //the clinical assessment provider is either one of our vendors, or Cohere
  const [clinicalAssessmentProviders, setClinicalAssessmentProviders] = useState<ClinicalAssessmentProvider[]>([]);
  const [healthHelpPASToken, setHealthHelpPASToken] = useState<HealthHelpPASRequestResponse>();
  const [vendorAssessmentCompleted, setVendorAssessmentCompleted] = useState(false);

  //dedicated nudge
  const [dedicatedNudgeIds, setDedicatedNudgeIds] = useState<string[]>([]);
  const [currentDedicatedNudgeTargetForAction, setCurrentDedicatedNudgeTargetForAction] = useState<TargetFieldLabel>();
  const [dnsNudgeIndex, setDnsNudgeIndex] = useState<number>(0);
  const [dedicatedNudgeResponses, setDedicatedNudgeResponses] = useState<boolean[]>([]);
  const [dedicatedNudgeServiceRequestId, setDedicatedNudgeServiceRequestId] = useState<string>();
  const [canContinueToReviewPage, setCanContinueToReviewPage] = useState(false);
  const [placeOfServiceToUpdateTo, setPlaceOfServiceToUpdateTo] = useState<PlaceOfService | undefined>(undefined);
  const [careTypeToUpdateTo, setCareTypeToUpdateTo] = useState<FacilityCategory | undefined>(undefined);

  const [dedicatedNudgeAccepted, setDedicatedNudgeAccepted] = useState(false);
  const [hasRuleRuns, setHasRuleRuns] = useState<boolean>(false);
  const [acceptedDedicatedNudges, setAcceptedDedicatedNudges] = useState<string[] | undefined>([]);
  const [declinedNudges, setDeclinedNudges] = useState<string[] | undefined>([]);
  const addAttachmentDedicatedNudgeFF = useFeature("addAttachmentDedicatedNudge");
  const [showServiceChangeBanner, setShowServiceChangeBanner] = useState<boolean>(false);

  const [visibilityToggleStatus, setVisibilityToggleStatus] = useState(false);

  const targetsForAction = getTargetsForAction(addAttachmentDedicatedNudgeFF);

  const [currentRuleNudge, setCurrentRuleNudge] = useState<RecommendChangeRuleAction>();
  const [continueDisabledForAddAttachmentsDNS, setContinueDisabledForAddAttachmentsDNS] = useState<boolean | undefined>(
    false
  );
  //hooks
  const {
    data: primaryCarePath,
    loading: fetchCarePathLoading,
    refetch: fetchCarePath,
  } = useGetCarePath({ carePathId: primaryCarePathId || "", lazy: true });

  const {
    data: serviceRequestData,
    loading: fetchServiceRequestsLoading,
    refetch: fetchServiceRequests,
  } = useGetServiceRequests({
    queryParams: { patient: `eq:${patientId}`, view: slimServiceSelect ? "serviceRequestAuthBuilderSlim" : "" },
    lazy: true,
  });

  const {
    data: carePathClinicalAssessmentData,
    loading: loadingCarePathClinicalAssessment,
    error: carePathClinicalAssessmentError,
    refetch: getCarePathClinicalAssessment,
  } = useGetClinicalAssessment({
    journeyId: "",
    lazy: true,
    queryParamStringifyOptions: { indices: false },
  });

  const {
    data: singleServiceClinicalAssessmentData,
    loading: loadingSingleServiceClinicalAssessment,
    error: singleServiceClinicalAssessmentError,
    refetch: getSingleServiceClinicalAssessment,
    startPollForClinicalAssessment,
  } = usePollForClinicalAssessment({
    serviceRequestId: "",
    lazy: true,
  });

  const {
    data: multiSingleServiceClinicalAssessmentData,
    loading: loadingMultiSingleServiceClinicalAssessment,
    error: multiSingleServiceClinicalAssessmentError,
    refetch: getMultiSingleServiceClinicalAssessment,
  } = useGetMultiSingleServiceClinicalAssessment({
    lazy: true,
    queryParamStringifyOptions: { indices: false },
  });

  const { mutate: getBulkRuleActions } = useGetBulkServiceRequestRuleActions({});

  const { mutate: getBulkSearchableStatements } = useGetBulkSearchableStatements({});

  const { data: authorizationData, refetch: refetchAuthorization } = useGetAuthorizationByIdWithFallback({
    id: draftServiceRequest?.authorization?.id || "",
  });
  useEffect(() => {
    if (authorizationData) {
      setVisibilityToggleStatus(
        authorizationData?.restrictedDataSettings?.allowEditForAuthorizationParticipants || false
      );
    }
  }, [authorizationData]);

  const {
    data: serviceRequestAttachments,
    loading: loadingServiceRequestAttachments,
    refetch: refetchServiceRequestAttachments,
  } = useGetServiceRequestAttachments({
    id: draftSRId,
    lazy: true,
  });

  const { refetch: refetchServiceRequest } = useGetServiceRequestByIdWithFallback({
    id: "",
    lazy: true,
  });

  const getDraftServiceRequest = useCallback(async () => {
    await refetchDraftServiceRequest();
    await refetchServiceRequestAttachments();
    if (draftServiceRequest?.authorization?.id) {
      await refetchAuthorization();
    }
  }, [
    draftServiceRequest?.authorization?.id,
    refetchAuthorization,
    refetchDraftServiceRequest,
    refetchServiceRequestAttachments,
  ]);

  useEffect(() => {
    if (draftSRId) {
      getDraftServiceRequest();
    }
  }, [draftSRId, getDraftServiceRequest]);

  const {
    data: carePathJourneyAttachments,
    loading: loadingCarePathJourneyAttachments,
    refetch: refetchCarePathJourneyAttachments,
  } = useGetCarePathJourneyAttachments({
    id: carePathJourney?.id || "none",
  });

  const { srEditConfig } =
    useGetServiceRequestEditConfigurationByPayerAndAuthStatus({
      healthPlanName: healthPlan || "",
    }) || {};
  const allowedPxCodePerPayer = srEditConfig?.maxAllowedPxCodePerPayer;

  const getDraftClinicalAssessment = useCallback(
    async (draftServiceRequest: ServiceRequestResponse) => {
      const { id } = draftServiceRequest;
      await getSingleServiceClinicalAssessment({
        pathParams: { id },
      });
    },
    [getSingleServiceClinicalAssessment]
  );

  const {
    data: ruleActions,
    loading: loadingActions,
    refetch: getServiceRequestRuleActionsResponse,
    error: ruleActionError,
  } = useGetServiceRequestRuleActions({
    id: "",
    queryParams: {
      displayTarget: "PRE_SUBMISSION_REVIEW",
      authStage: convertStringToAuthBuilderWorkflowStep("REVIEW"),
    },
    lazy: true,
  });

  useEffect(() => {
    if (ruleActionError) {
      addBreadcrumb({
        category: "ruleActionError",
        message: `${ruleActionError.message}`,
        level: "error",
        data: {
          errorData: ruleActionError.data,
          time: new Date().toISOString(),
        },
      });
      logError(`Error occurred while fetching rule actions`);
    }
  }, [ruleActionError]);

  useSetScrubTinsContextByHealthPlanConfiguration({
    healthPlanName: healthPlan ?? "",
  });

  const getAttachmentsBulkRuleActions = useCallback(async () => {
    if (serviceRequests) {
      const ruleActions = await getBulkRuleActions({
        serviceRequestIds: serviceRequests.map((sr) => sr.id),
        displayTarget: "ATTACHMENTS",
      });
      setAttachmentsRuleActions(ruleActions);
    }
  }, [getBulkRuleActions, serviceRequests]);

  const getCaqBulkSearchableStatements = useCallback(
    async (serviceRequestIds: string[]) => {
      if (serviceRequestIds.length > 0 && autoApprovalSkipCAQ) {
        const searchableStatements = await getBulkSearchableStatements({
          serviceRequestIds: serviceRequestIds,
          displayTarget: "CLINICAL_ASSESSMENT",
          statement: "serviceRequestPatientAttributes",
        });
        const extractedServiceRequestPatientAttributes: Set<string> = new Set();
        searchableStatements.forEach((ss) => {
          const { serviceRequestPatientAttributes } = ss;
          if (serviceRequestPatientAttributes && serviceRequestPatientAttributes.length > 0) {
            serviceRequestPatientAttributes.forEach((s) => extractedServiceRequestPatientAttributes.add(s));
          }
        });
        setServiceRequestPatientAttributes(extractedServiceRequestPatientAttributes);
      }
    },
    [autoApprovalSkipCAQ, getBulkSearchableStatements]
  );

  const doGetPalCheck = useCallback(
    async (priorAuthRequirements) => {
      await doPalCheck(priorAuthRequirements);
    },
    [doPalCheck]
  );

  useEffect(() => {
    if (
      draftServiceRequest &&
      draftServiceRequest?.id &&
      draftServiceRequest?.id !== initialRenderForDraftServiceRequestsID.current &&
      serviceRequestAttachments &&
      authorizationData &&
      patientData
    ) {
      if (draftServiceRequest.authStatus !== "DRAFT") {
        enqueueSnackbar(`This request has already been submitted`, {
          variant: "info",
        });
        if (inSmartOnFhirWorkflow) {
          navigateToSRSummary(draftSRId || "", navigate, true);
        } else {
          navigateToPS(draftServiceRequest?.patient?.id || patientData?.id || "", navigate, draftSRId);
        }
      }
      const serviceRequest = { ...draftServiceRequest, attachments: serviceRequestAttachments };
      setServiceRequests([serviceRequest]);

      const formContents = formContentFromResponse(
        draftServiceRequest,
        false,
        initialRenderForDraftServiceRequestsID.current !== draftServiceRequest.id,
        isNewContinuationSR,
        crdLogId,
        authorizationData
      );
      if (facilityBasedFeatureEnabled) {
        shouldIncludeFacilityBasedFields(formContents, formContents.startDate);
      }
      setServiceRequestFormContents([formContents]);
      const draftSrCrdLogId = draftServiceRequest?.crdLogId;
      if (draftSrCrdLogId) {
        setCrdLogId(draftSrCrdLogId);
      }
      const draftPriorAuthRequirements = {
        authCategory: draftServiceRequest?.authCategory,
        authSubcategory: draftServiceRequest?.authSubcategory,
        homeHealth: false,
        noPxService: undefined,
        noPxServiceRequired: false,
        showPxCheckbox: false,
        userSelectedNonPalCode: draftServiceRequest?.userSelectedNonPalCode || false,
        userSelectedOutOfNetworkException: draftServiceRequest?.userSelectedOONException || false,
        primaryDiagnosis: draftServiceRequest?.primaryDiagnosis,
        secondaryDiagnoses: draftServiceRequest?.secondaryDiagnoses ? draftServiceRequest?.secondaryDiagnoses : [],
        startDate: parseDateFromISOStringWithoutFallback(draftServiceRequest.startDate),
        encounterType: draftServiceRequest?.encounterType,
        desiredProcedureCodes: draftServiceRequest?.semanticProcedureCodes,
        additionalInsurance: draftServiceRequest?.additionalInsurance,
        patientData: patientData,
        serviceRequestFormContents: formContents,
      };
      setPriorAuthRequirements(draftPriorAuthRequirements);
      if (
        workflowStep !== "PROVIDER_DETAILS" &&
        initialRenderForDraftServiceRequestsID.current !== draftServiceRequest.id
      ) {
        doGetPalCheck(draftPriorAuthRequirements);
      }
      getDraftClinicalAssessment(draftServiceRequest);
      getCaqBulkSearchableStatements([draftServiceRequest.id]);
      if (workflowStep !== "ADD_ATTACHMENTS" && draftServiceRequest) {
        getAttachmentsBulkRuleActions();
      }
      if (draftServiceRequest.delegatedVendor && draftServiceRequest.delegatedVendor === HEALTH_HELP_NAME) {
        setClinicalAssessmentProviders(["HealthHelp"]);
      } else if (draftServiceRequest.delegatedVendor && draftServiceRequest.delegatedVendor === HEALTH_HELP_V2_NAME) {
        setClinicalAssessmentProviders(["HealthHelpV2"]);
      } else {
        setClinicalAssessmentProviders(["Cohere"]);
      }
      if (draftServiceRequest.delegatedVendor && draftServiceRequest.delegatedVendor === "Agadia") {
        setAuthFlowType("PHARMACY");
      } else {
        setAuthFlowType("MEDICAL");
      }
      if (workflowStep === "REVIEW_NUDGES" && draftServiceRequest && draftServiceRequest.id) {
        fetchRuleActions(
          [serviceRequest],
          getServiceRequestRuleActionsResponse,
          targetsForAction,
          dedicatedNudgeIds,
          setDedicatedNudgeIds,
          setCurrentDedicatedNudgeTargetForAction,
          setDedicatedNudgeServiceRequestId
        );
      }
      initialRenderForDraftServiceRequestsID.current = draftServiceRequest.id;
    }
  }, [
    draftSRId,
    doGetPalCheck,
    draftServiceRequest,
    enqueueSnackbar,
    getAttachmentsBulkRuleActions,
    getCaqBulkSearchableStatements,
    getDraftClinicalAssessment,
    getServiceRequestRuleActionsResponse,
    isNewContinuationSR,
    navigate,
    patientData?.id,
    priorAuthRequirements,
    serviceRequestAttachments,
    workflowStep,
    crdLogId,
    inSmartOnFhirWorkflow,
    facilityBasedFeatureEnabled,
    authorizationData,
    patientData,
    dedicatedNudgeIds,
    currentDedicatedNudgeTargetForAction,
    serviceRequests,
    targetsForAction,
  ]);

  const singleServiceClinicalAssessmentErrorMessage = singleServiceClinicalAssessmentError
    ? typeof singleServiceClinicalAssessmentError?.data === "string"
      ? singleServiceClinicalAssessmentError.data
      : typeof singleServiceClinicalAssessmentError?.data?.message === "string"
      ? singleServiceClinicalAssessmentError?.data?.message
      : ""
    : null;

  const multiSingleServiceClinicalAssessmentErrorMessage = multiSingleServiceClinicalAssessmentError
    ? typeof multiSingleServiceClinicalAssessmentError?.data === "string"
      ? multiSingleServiceClinicalAssessmentError.data
      : null
    : null;

  const setWorkflowStep = useCallback(
    (step: AuthBuilderWorkflowStep) => {
      // Have to refetch attachments every time we enter that workflow step
      if ((step === "ADD_ATTACHMENTS_CONTINUATION" || step === "ADD_ATTACHMENTS") && refetchServiceRequestAttachments) {
        if (draftSRId && !carePathJourney?.id) {
          refetchServiceRequestAttachments();
        } else if (!!carePathJourney?.id) {
          refetchCarePathJourneyAttachments();
        } else {
          serviceRequests.forEach(async (sr) => {
            const response = await refetchServiceRequest({ pathParams: { id: sr.id } });
            if (response) {
              editServiceRequest(response);
            }
          });
        }
      }
      setWorkflowStepInternal(step);
    },
    [
      refetchServiceRequestAttachments,
      draftSRId,
      carePathJourney?.id,
      refetchCarePathJourneyAttachments,
      serviceRequests,
      refetchServiceRequest,
    ]
  );

  const updatedSearchParams = useMemo(() => {
    let searchParams = isFaxIntakeFlow
      ? getParametersFaxIntake(undefined, faxId, !!qmCaseId ? qmCaseId : undefined, queueId)
      : "";

    if (!!faxId) {
      //Need to come up better name for fax step with side by side view
      searchParams += `&faxStep=SIDE_BY_SIDE_VIEW`;
    }
    searchParams += `&authBuilderStep=${workflowStep}`;
    return searchParams;
  }, [faxId, isFaxIntakeFlow, qmCaseId, queueId, workflowStep]);

  useEffect(() => {
    const handleDraftServiceRequest = (
      draftServiceRequestId: SetStateAction<string>,
      qmServiceCaseId: string[] | ParsedQs | ParsedQs[] | SetStateAction<string> | undefined
    ) => {
      setDraftSRId(draftServiceRequestId);
      typeof qmServiceCaseId === "string" && setQueueMgmtCaseId(qmServiceCaseId);

      const workflowStepStr = authBuilderStep?.toString() || "";

      setWorkflowStep(convertStringToAuthBuilderWorkflowStep(workflowStepStr));
      const path = `?${updatedSearchParams}`;
      navigate(path, { replace: true, state: location.state });
    };

    const handleShortWorkflow = () => {
      setWorkflowStep(convertStringToAuthBuilderWorkflowStep("FILL_FORMS_FAX"));
      navigate(highmarkWorkflow, { replace: true, state: location.state });
    };

    const handleSpecialWorkflow = () => {
      /* This might seem weird but we are checking if the workflowStep is
       * not the same as the authBuilderStep, since workflowStep can change
       */
      if (authBuilderStep !== pathWithUpdatedWorkflowStep) {
        navigate(pathWithUpdatedWorkflowStep, { replace: true, state: location.state });
      }
    };

    if (typeof draftServiceRequestId === "string") {
      handleDraftServiceRequest(draftServiceRequestId, qmServiceCaseId);
    } else if (workflowLength === "short") {
      handleShortWorkflow();
    } else {
      handleSpecialWorkflow();
    }
  }, [
    draftServiceRequestId,
    qmServiceCaseId,
    highmarkWorkflow,
    navigate,
    setWorkflowStep,
    workflowLength,
    isFaxIntakeFlow,
    faxId,
    qmCaseId,
    queueId,
    workflowStep,
    location.state,
    location.search,
    authBuilderStep,
    faxStep,
    updatedSearchParams,
    pathWithUpdatedWorkflowStep,
  ]);

  useEffect(() => {
    //SENTRY LOGGING
    if (location.hash) {
      logWarning(`Hash usage found in AuthBuilder URL: ${location.hash}`);
    }
  }, [location.hash]);

  const loadingClinicalAssessment =
    loadingCarePathClinicalAssessment ||
    loadingSingleServiceClinicalAssessment ||
    loadingMultiSingleServiceClinicalAssessment;

  useEffect(() => {
    // Handle errors
    if (carePathClinicalAssessmentError) {
      enqueueSnackbar(`Error loading clinical assessment: ${carePathClinicalAssessmentError.message}`, {
        variant: "error",
      });
    }
    if (
      multiSingleService && authFlowType !== "PHARMACY"
        ? multiSingleServiceClinicalAssessmentError
        : singleServiceClinicalAssessmentError
    ) {
      setPharmErrorRedirectModalOpen(true);
    }
  }, [
    enqueueSnackbar,
    carePathClinicalAssessmentError,
    singleServiceClinicalAssessmentError,
    singleServiceClinicalAssessmentErrorMessage,
    multiSingleService,
    multiSingleServiceClinicalAssessmentError,
    authFlowType,
  ]);

  useEffect(() => {
    // Populate context with data from ClinicalAssessment, after fetch/refetch was triggered on the auth build flow
    if (multiSingleService) {
      if (multiSingleServiceClinicalAssessmentData && !loadingMultiSingleServiceClinicalAssessment) {
        updateClinicalAssessment({ type: "populate", clinicalAssessment: multiSingleServiceClinicalAssessmentData });
        const observationCodes = extractObservationalSnomedCodes(multiSingleServiceClinicalAssessmentData.questions);
        setClinicalAssessmentSnomedCodes(observationCodes);
      } else if (carePathClinicalAssessmentData && !loadingCarePathClinicalAssessment) {
        updateClinicalAssessment({ type: "populate", clinicalAssessment: carePathClinicalAssessmentData });
        const observationCodes = extractObservationalSnomedCodes(carePathClinicalAssessmentData.questions);
        setClinicalAssessmentSnomedCodes(observationCodes);
      } else if (singleServiceClinicalAssessmentData && !loadingSingleServiceClinicalAssessment) {
        updateClinicalAssessment({ type: "populate", clinicalAssessment: singleServiceClinicalAssessmentData });
        const observationCodes = extractObservationalSnomedCodes(singleServiceClinicalAssessmentData.questions);
        setClinicalAssessmentSnomedCodes(observationCodes);
      }
    } else {
      if (singleServiceClinicalAssessmentData && !loadingSingleServiceClinicalAssessment) {
        updateClinicalAssessment({ type: "populate", clinicalAssessment: singleServiceClinicalAssessmentData });
        const observationCodes = extractObservationalSnomedCodes(singleServiceClinicalAssessmentData.questions);
        setClinicalAssessmentSnomedCodes(observationCodes);
      } else if (carePathClinicalAssessmentData && !loadingCarePathClinicalAssessment) {
        updateClinicalAssessment({ type: "populate", clinicalAssessment: carePathClinicalAssessmentData });
        const observationCodes = extractObservationalSnomedCodes(carePathClinicalAssessmentData.questions);
        setClinicalAssessmentSnomedCodes(observationCodes);
      }
    }
  }, [
    carePathClinicalAssessmentData,
    loadingCarePathClinicalAssessment,
    loadingMultiSingleServiceClinicalAssessment,
    loadingSingleServiceClinicalAssessment,
    multiSingleService,
    multiSingleServiceClinicalAssessmentData,
    singleServiceClinicalAssessmentData,
    updateClinicalAssessment,
  ]);

  const extractObservationalSnomedCodes = (questions: ClinicalAssessmentResponse[] | undefined) => {
    const observationCodes: Set<string> = new Set();
    questions?.forEach((q) => {
      q.clinicalQuestion?.responseOptions?.forEach((opt) =>
        opt.observationCodes?.forEach(
          (_obCode) =>
            _obCode.system && _obCode.system?.toLowerCase().includes("snomed") && observationCodes.add(_obCode.code)
        )
      );
    });
    return observationCodes;
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, [workflowStep]);

  const { data: user, refetch: refetchUser } = useGetUserById({
    id: currUser?.sub || "",
    lazy: true,
    queryParams: {
      includeOrganizationName: true,
    },
  });

  useEffect(() => {
    getUser()
      ?.then((currentUser) => {
        setUser(currentUser);
      })
      .catch((error): void => {
        logError(`error loading current user ${stringifyError(error)}`);
      });
  }, [getUser]);

  useEffect(() => {
    if (currUser) {
      refetchUser();
    }
  }, [currUser, refetchUser]);

  useEffect(() => {
    if (user && user.isSsoUser) {
      setRequestor((prev) => ({
        ...prev,
        channel: "PORTAL",
        email: user?.email,
        phoneNumber: { number: user?.phone },
      }));
    }
  }, [user]);

  // Update the requestor so the Fax radio button is auto-selected on page load.
  useEffect(() => {
    if (!!faxId) {
      setRequestor((currentRequestor) => ({
        ...currentRequestor,
        channel: "FAX",
      }));
    }
  }, [faxId]);

  useEffect(() => {
    if (requestorFormAuthorized === false) {
      setRequestor((currentRequestor) => ({
        ...currentRequestor,
        firstName: user?.firstName,
        lastName: user?.lastName,
        phoneNumber: user?.phone ? strToPhoneNumber(user.phone) : undefined,
        faxNumber: user?.faxNumber,
        email: user?.email,
        user: user ?? undefined,
      }));
    }
  }, [requestorFormAuthorized, user]);

  useEffect(() => {
    setRequestor((currentRequestor) => ({
      ...currentRequestor,
      isFormSubmittedWithoutUserInput: !requestorFormAuthorized,
    }));
  }, [requestorFormAuthorized]);

  useEffect(() => {
    if (dnsNudgeIndex !== 1 && workflowStep !== "REVIEW_NUDGES") {
      //if we've moved beyond the DNS page, reset the applicable state fields
      setDnsNudgeIndex(0);
      setCurrentDedicatedNudgeTargetForAction(undefined);
    }
  }, [dnsNudgeIndex, workflowStep]);

  const [importedEhrOrder, setImportedEhrOrder] = useState<ServiceRequestResponse>();
  //service request helper functions
  const addServiceRequest = (sr: ServiceRequestResponse, newDraftSrId = "") => {
    setServiceRequests((prev) => [...prev, sr]);
    if (newDraftSrId) {
      setDraftSRId(newDraftSrId);
    }
  };

  const editServiceRequest = (sr: ServiceRequestResponse, facilityBasedWorkflow?: boolean) => {
    setServiceRequests((prev) =>
      prev.map((currentRequest) => {
        if (currentRequest?.id === sr?.id) {
          return sr;
        }
        return currentRequest;
      })
    );
    setServiceRequestFormContents((prev) =>
      prev.map((currentRequest) => {
        if (currentRequest?.id === sr?.id) {
          let request = formContentFromResponse(sr, true);
          if (request.procedureCodes.length < (currentRequest?.procedureCodes || [])?.length) {
            request = {
              ...request,
              procedureCodes: currentRequest?.procedureCodes || request.procedureCodes || [],
            };
          }
          if ((request.clinicalServices || []).length < (currentRequest?.clinicalServices || []).length) {
            request = {
              ...request,
              clinicalServices: currentRequest.clinicalServices || request.clinicalServices || [],
            };
          }
          if ((request.nonPxClinicalServiceIds || []).length < (currentRequest?.nonPxClinicalServiceIds || []).length) {
            request = {
              ...request,
              nonPxClinicalServiceIds: currentRequest.nonPxClinicalServiceIds || request.nonPxClinicalServiceIds || [],
            };
          }
          if (request.additionalCareParticipants) {
            let newAddtionalCareParticipants: AdditionalCareParticipantFormContent[] = [];
            request?.additionalCareParticipants?.forEach((additionalCare) => {
              if (additionalCare.tinList === undefined || additionalCare.tinList.length === 0) {
                newAddtionalCareParticipants.push({
                  ...additionalCare,
                  tinList: currentRequest.additionalCareParticipants?.find(
                    (currCareParticipant) =>
                      currCareParticipant.careParticipantType === additionalCare.careParticipantType
                  )?.tinList,
                  selectedTin: currentRequest.additionalCareParticipants?.find(
                    (currCareParticipant) =>
                      currCareParticipant.careParticipantType === additionalCare.careParticipantType
                  )?.selectedTin,
                  selectedLocation: currentRequest.additionalCareParticipants?.find(
                    (currCareParticipant) =>
                      currCareParticipant.careParticipantType === additionalCare.careParticipantType
                  )?.selectedLocation,
                  locations: currentRequest.additionalCareParticipants?.find(
                    (currCareParticipant) =>
                      currCareParticipant.careParticipantType === additionalCare.careParticipantType
                  )?.locations,
                });
              } else {
                newAddtionalCareParticipants.push(additionalCare);
              }
            });
            request = {
              ...request,
              additionalCareParticipants: newAddtionalCareParticipants,
            };
            if (facilityBasedWorkflow && request.isInpatient) {
              shouldIncludeFacilityBasedFields(request, request.startDate);
            }
          }
          return request;
        }
        return currentRequest;
      })
    );
  };

  const deleteServiceRequest = (sr: ServiceRequestResponse) => {
    setServiceRequests(serviceRequests?.filter((serviceRequest) => serviceRequest?.cohereId !== sr?.cohereId));

    const deletedIndexFormContents = serviceRequestFormContents.findIndex(
      (serviceRequest) => serviceRequest?.cohereId === sr?.cohereId
    );

    //remove the service request index from both the list of form contents and the list of validations
    setServiceRequestFormContents(listRemove(serviceRequestFormContents, deletedIndexFormContents));
    setServiceRequestFormsCanBeSubmitted(listRemove(serviceRequestFormsCanBeSubmitted, deletedIndexFormContents));
    setServiceRequestFormsHaveNewEdits(listRemove(serviceRequestFormsHaveNewEdits, deletedIndexFormContents));
    setProviderDetailsFormsCanBeSubmitted(listRemove(providerDetailsFormsCanBeSubmitted, deletedIndexFormContents));
  };

  const serviceRequestIds: string = serviceRequests.map((sr) => sr.id).join(",");
  // a list of attachments that have been deleted and must not be included in the state, because we don't always refetch single services
  const [removedAttachments, setRemovedAttachments] = useState<string[]>([]);
  const serviceRequestStateAttachments = serviceRequests
    .map(
      (sr) =>
        sr.attachments
          // Don't include attachments that have been removed
          ?.filter((attachment) => !removedAttachments.includes(attachment.id))
          .map((attachment) => {
            return {
              ...attachment,
              hasMultiSingleServices: (serviceRequests.length > 1 && !sr.carePathJourney?.id) || false,
              serviceRequest: { id: sr.id, clinicalService: { name: sr.clinicalService?.name || "" } },
            };
          })
          ?.flat() || []
    )
    .flat();

  const delegatedVendorName: string | undefined = useMemo(() => {
    const delegatedVendor = serviceRequests && serviceRequests.find((sr) => sr?.delegatedVendor)?.delegatedVendor;
    if (delegatedVendor && delegatedVendor !== COHERE_NAME) {
      if (vendorAssessmentError && [HEALTH_HELP_NAME, HEALTH_HELP_V2_NAME].includes(delegatedVendor)) {
        return undefined;
      }
      return delegatedVendor.toString();
    } else {
      return undefined;
    }
  }, [serviceRequests, vendorAssessmentError]);

  const {
    data: srRuleActions,
    loading: srLoadingActions,
    refetch: srRuleActionsRefetch,
  } = useGetServiceRequestRuleActions({
    id: serviceRequests && serviceRequests.length > 0 ? serviceRequests[0].id : "",
    queryParams: {
      displayTarget: "ATTACHMENTS_ON_PAGE",
      authStage: convertStringToAuthBuilderWorkflowStep("ADD_ATTACHMENTS"),
    },
    lazy: true,
  });

  const serviceRequestId = serviceRequests[0]?.id;

  useEffect(() => {
    if (Boolean(serviceRequestId)) {
      srRuleActionsRefetch({
        pathParams: {
          id: serviceRequestId,
        },
      });
    }
  }, [srRuleActionsRefetch, serviceRequestId]);

  // Health Help requires that we dont auto fill the follow up fax number based on the user. We can auto fill
  // based on the fax number that the user enters in the requestor box
  const autoFillUserFaxNumber = requestor?.faxNumber?.number;

  const addAttachmentToServiceRequests = (attachment: Attachment) => {
    if (multiSingleService && attachment.serviceRequest?.id) {
      setServiceRequests((prev) =>
        prev.map((sr) => {
          if (sr.id === attachment.serviceRequest?.id) {
            return { ...sr, attachments: [...(sr.attachments || []), attachment] };
          } else {
            return { ...sr };
          }
        })
      );
    } else {
      setServiceRequests((prev) =>
        prev.map((sr) => {
          return { ...sr, attachments: [...(sr.attachments || []), attachment] };
        })
      );
    }
  };

  const hasCode = (procedureCodes: ProcedureCode[], code: string): boolean => {
    return procedureCodes.some((px) => px.code === code);
  };

  const doesServiceDeterminationResponseHaveAllCurrentCodes = (
    response: ServiceDeterminationResponse | undefined,
    pxCodes: ProcedureCode[]
  ): boolean => {
    if (response) {
      let pxCodesFromResponse: string[] = [];
      response.procedureCodeGroupOptions?.forEach((group) => {
        if (group.codes) {
          pxCodesFromResponse = pxCodesFromResponse.concat(group.codes.map((code) => code.code));
        }
      });
      response.buckets?.forEach((bucket) => {
        if (bucket.procedureCodes) {
          pxCodesFromResponse = pxCodesFromResponse.concat(bucket.procedureCodes.map((code) => code.code));
        }
      });

      return pxCodesFromResponse
        .filter((item, index) => pxCodesFromResponse.indexOf(item) === index)
        .every((c: string) => hasCode(pxCodes, c));
    } else {
      return false;
    }
  };
  const isClearerDraftWorkflow = useFeature("clearerDraftWorkflow");
  const displayClearerDraftComponents =
    isClearerDraftWorkflow &&
    authFlowType !== "PHARMACY" &&
    (draftServiceRequest
      ? draftServiceRequest.delegatedVendor !== "HealthHelp"
      : !Boolean(
          serviceRequests.find(
            (sr) => sr.delegatedVendor === HEALTH_HELP_NAME || sr.delegatedVendor === HEALTH_HELP_V2_NAME
          )
        ));

  const [facilityOonCheckLoading, setFacilityOonCheckLoading] = useState<boolean>(false);
  const [providerOonCheckLoading, setProviderOonCheckLoading] = useState<boolean>(false);
  const [performingProviderPracticeOonCheckLoading, setPerformingProviderPracticeOonCheckLoading] =
    useState<boolean>(false);
  const [orderingProviderOonCheckLoading, setOrderingProviderOonCheckLoading] = useState<boolean>(false);
  const [additionalCareOonCheckLoading, setAdditionalCareOonCheckLoading] = useState<boolean>(false);
  const [openNonPalConfirmationModal, setOpenNonPalConfirmationModal] = useState(false);
  const setUserSelectedNonPalCode = (value: boolean) => {
    setPriorAuthRequirements((prevRequirements) => {
      return { ...prevRequirements, userSelectedNonPalCode: value };
    });
  };

  const [performingProviderExceptionRequest, setPerformingProviderExceptionRequest] = useState(false);

  const [performingProviderPracticeExceptionRequest, setPerformingProviderPracticeExceptionRequest] = useState(false);

  const [facilityExceptionRequest, setFacilityExceptionRequest] = useState(false);

  const [orderingProviderExceptionRequest, setOrderingProviderExceptionRequest] = useState(false);
  const [careParticipantExceptionRequest, setCareParticipantExceptionRequest] = useState(false);

  const setExceptionRequest = useCallback(() => {
    if (serviceRequestFormContents[0]?.performingProviderSelectedAddress?.outOfNetworkExceptionReason) {
      setPerformingProviderExceptionRequest(
        !!serviceRequestFormContents[0]?.performingProviderSelectedAddress?.outOfNetworkExceptionReason || false
      );
    }

    if (serviceRequestFormContents[0]?.facilitySelectedAddress?.outOfNetworkExceptionReason) {
      setFacilityExceptionRequest(
        !!serviceRequestFormContents[0]?.facilitySelectedAddress?.outOfNetworkExceptionReason || false
      );
    }

    if (serviceRequestFormContents[0]?.selectedPerformingProviderPracticeAddress?.outOfNetworkExceptionReason) {
      setPerformingProviderPracticeExceptionRequest(
        !!serviceRequestFormContents[0]?.selectedPerformingProviderPracticeAddress?.outOfNetworkExceptionReason || false
      );
    }

    if (serviceRequestFormContents[0]?.orderingProviderSelectedAddress?.outOfNetworkExceptionReason) {
      setOrderingProviderExceptionRequest(
        !!serviceRequestFormContents[0]?.orderingProviderSelectedAddress?.outOfNetworkExceptionReason || false
      );
    }

    if (serviceRequestFormContents[0]?.additionalCareParticipants) {
      serviceRequestFormContents[0]?.additionalCareParticipants?.forEach(
        (careParticipant: AdditionalCareParticipantFormContent) => {
          if (careParticipant?.selectedLocation?.outOfNetworkExceptionReason) {
            setCareParticipantExceptionRequest(
              !!careParticipant?.selectedLocation?.outOfNetworkExceptionReason || false
            );
          }
        }
      );
    }
  }, [
    serviceRequestFormContents,
    setPerformingProviderExceptionRequest,
    setFacilityExceptionRequest,
    setPerformingProviderPracticeExceptionRequest,
    setOrderingProviderExceptionRequest,
    setCareParticipantExceptionRequest,
  ]);

  useEffect(() => {
    setExceptionRequest();
  }, [serviceRequestFormContents, setExceptionRequest]);

  const updateSR = useCallback((updatedSR: ServiceRequestResponse) => {
    setServiceRequests((prevState) => listReplace(prevState, 0, updatedSR));
  }, []);

  useEffect(() => {
    if (
      serviceRequests?.[0]?.possibleAttachmentNudgeReasons?.length !==
      serviceRequestFormContents?.[0]?.possibleAttachmentNudgeReasons?.length
    ) {
      const updatedSR: ServiceRequestResponse = {
        ...serviceRequests[0],
        possibleAttachmentNudgeReasons: serviceRequestFormContents?.[0]?.possibleAttachmentNudgeReasons,
      };
      updateSR(updatedSR);
    }
  }, [updateSR, serviceRequestFormContents, serviceRequests]);

  const [serviceDeterminationLoading, setServiceDeterminationLoading] = useState<boolean>(false);

  const navigateBack = () => {
    switch (workflowStep) {
      case "SELECT_SERVICES":
        setWorkflowStep("GATHER_REQUIREMENTS_MEDICAL");
        setPrimaryCarePathId(undefined);
        setCarePathJourney(undefined);
        setPriorAuthRequirements(priorAuthRequirements);
        setBuckets([]);
        if (selectedClinicalServiceIds) {
          setPreviousClinicalServiceIds({ ...selectedClinicalServiceIds });
        }
        setSelectedClinicalServiceIds({});
        break;
      case "PROVIDER_DETAILS":
        if (selectedClinicalServiceIds) {
          setPreviousClinicalServiceIds({ ...selectedClinicalServiceIds });
        }
        const currentServiceDeterminationResponseHasCodes = doesServiceDeterminationResponseHaveAllCurrentCodes(
          serviceDeterminationResponse,
          priorAuthRequirements.desiredProcedureCodes || []
        );
        if (
          (serviceDeterminationResponse &&
            serviceDeterminationResponse.procedureCodeGroupOptions === undefined &&
            serviceDeterminationResponse.buckets &&
            serviceDeterminationResponse.buckets.length) ||
          !serviceDeterminationResponse ||
          !currentServiceDeterminationResponseHasCodes
        ) {
          setWorkflowStep("GATHER_REQUIREMENTS_MEDICAL");
          setPriorAuthRequirements(priorAuthRequirements);
          setSelectedClinicalServiceIds({});
          setSelectedCarePathId(undefined);
        } else {
          (priorAuthRequirements &&
            priorAuthRequirements.desiredProcedureCodes &&
            priorAuthRequirements?.desiredProcedureCodes?.length > 0) ||
          (serviceRequestFormContents[0]?.nonPxClinicalServiceIds &&
            serviceRequestFormContents[0].nonPxClinicalServiceIds?.length > 0)
            ? setWorkflowStep("SELECT_SERVICES")
            : setWorkflowStep("GATHER_REQUIREMENTS_MEDICAL");
          setPriorAuthRequirements(priorAuthRequirements);
        }
        break;
      case "SERVICE_DETAILS":
        setWorkflowStep("PROVIDER_DETAILS");
        break;
      case "FILL_FORMS_FAX":
        //TODO: clean up by June 1, 2023 -- COH - 3422
        setWorkflowStep("FILL_FORMS_FAX");
        break;
      case "ADD_ATTACHMENTS":
        setStateBeforeBackFromAttachments(
          authFlowType,
          setClinicalAssessmentProviders,
          setServiceRequestPatientAttributes,
          setAttemptedServiceRequestFormsSubmit,
          setWorkflowStep
        );
        break;
      case "CLINICAL_ASSESSMENT":
        if (isFaxIntakeFlow) {
          setStateBeforeBackFromAttachments(
            authFlowType,
            setClinicalAssessmentProviders,
            setServiceRequestPatientAttributes,
            setAttemptedServiceRequestFormsSubmit,
            setWorkflowStep
          );
        } else {
          setWorkflowStep("ADD_ATTACHMENTS");
        }
        break;
      case "VENDOR_CLINICAL_ASSESSMENT":
        // check if a non-HH code exists. if so, go to Clinical Assessment; else Fill Forms
        if (clinicalAssessmentProviders.includes("Cohere") && !emptyClinicalAssessmentContext) {
          setWorkflowStep("CLINICAL_ASSESSMENT");
        } else {
          setWorkflowStep("ADD_ATTACHMENTS");
        }

        break;
      case "REVIEW_NUDGES":
        if (clinicalAssessmentProviders.includes("HealthHelp")) {
          setWorkflowStep("VENDOR_CLINICAL_ASSESSMENT");
        } else if (
          (autoApprovalSkipCAQ && attachmentsRuleActions && groupAuthAutoApproves(attachmentsRuleActions)) ||
          !!emptyClinicalAssessmentContext ||
          (fastTrackProvider && attachmentsRuleActions && isProviderFastTrack(attachmentsRuleActions))
        ) {
          if (isFaxIntakeFlow) {
            //skipped CAQs and attachments pages because both conditions apply
            setStateBeforeBackFromAttachments(
              authFlowType,
              setClinicalAssessmentProviders,
              setServiceRequestPatientAttributes,
              setAttemptedServiceRequestFormsSubmit,
              setWorkflowStep
            );
          } else {
            setWorkflowStep("ADD_ATTACHMENTS");
          }
        } else {
          setWorkflowStep("CLINICAL_ASSESSMENT");
        }
        setCanContinueToReviewPage(false);
        setPlaceOfServiceToUpdateTo(undefined);
        setCareTypeToUpdateTo(undefined);
        break;
      case "REVIEW":
        //resetting usedDedicatedNudgeIds for users to see dns page again back and forth in the workflow
        setDedicatedNudgeIds([]);
        setDedicatedNudgeResponses([]);
        /**
         * If intersection exists don't skip caq questions, auth could pend overall
         * Else if auth would approve, it's safe to skip caq questions
         */
        const hasCaqSearchableStatementsIntersect = getIntersectionObservationCodesAndSearchStatements(
          serviceRequestPatientAttributes,
          clinicalAssessmentSnomedCodes
        );

        if (clinicalAssessmentProviders.includes("HealthHelp")) {
          // check if HH code exists. if so, go to Vendor Assessment; else Clinical Assessment
          setWorkflowStep("VENDOR_CLINICAL_ASSESSMENT");
        } else if (
          (((autoApprovalSkipCAQ && !hasCaqSearchableStatementsIntersect) || fastTrackProvider) &&
            attachmentsRuleActions &&
            groupAuthAutoApproves(attachmentsRuleActions)) ||
          !!emptyClinicalAssessmentContext ||
          (fastTrackProvider && attachmentsRuleActions && isProviderFastTrack(attachmentsRuleActions))
        ) {
          if (isFaxIntakeFlow) {
            //skipped CAQs and attachments pages because both conditions apply
            setStateBeforeBackFromAttachments(
              authFlowType,
              setClinicalAssessmentProviders,
              setServiceRequestPatientAttributes,
              setAttemptedServiceRequestFormsSubmit,
              setWorkflowStep
            );
          } else {
            setWorkflowStep("ADD_ATTACHMENTS");
          }
        } else {
          setWorkflowStep("CLINICAL_ASSESSMENT");
        }
        break;
      default:
        setWorkflowStep("GATHER_REQUIREMENTS_MEDICAL");
        setPrimaryCarePathId(undefined);
        setCarePathJourney(undefined);
        setBuckets([]);
        setSelectedClinicalServiceIds({});
        setSelectedCarePathId(undefined);
        setPriorAuthRequirements(priorAuthRequirements);
        break;
    }
  };

  const navigateBackContinuations = () => {
    switch (workflowStep) {
      case "ADD_ATTACHMENTS_CONTINUATION":
        setStateBeforeBackFromAttachmentsContinuation(draftSRId, getDraftServiceRequest, setWorkflowStep);
        break;
      case "CLINICAL_ASSESSMENT_CONTINUATION":
        if (isFaxIntakeFlow) {
          //skipped CAQs and attachments pages because both conditions apply
          setStateBeforeBackFromAttachmentsContinuation(draftSRId, getDraftServiceRequest, setWorkflowStep);
        } else {
          setWorkflowStep("ADD_ATTACHMENTS_CONTINUATION");
        }
        break;
      case "REVIEW_CONTINUATION":
        if (!emptyClinicalAssessmentContext) {
          setWorkflowStep("CLINICAL_ASSESSMENT_CONTINUATION");
        } else if (isFaxIntakeFlow) {
          //skipped CAQs and attachments pages because both conditions apply
          setStateBeforeBackFromAttachmentsContinuation(draftSRId, getDraftServiceRequest, setWorkflowStep);
        } else {
          setWorkflowStep("ADD_ATTACHMENTS_CONTINUATION");
        }
        break;
      default:
        setWorkflowStep("GATHER_REQUIREMENTS_MEDICAL");
        setPrimaryCarePathId(undefined);
        setCarePathJourney(undefined);
        setBuckets([]);
        setSelectedClinicalServiceIds({});
        setSelectedCarePathId(undefined);
        setPriorAuthRequirements(priorAuthRequirements);
        break;
    }
  };
  let pageContents;
  let footerContents;

  switch (workflowStep) {
    case "GATHER_REQUIREMENTS_MEDICAL":
      pageContents = (
        <FiredNudgesContext.Provider value={{ firedRecommendedNudges, setFiredRecommendedNudges }}>
          <SuggestionContext.Provider
            value={{
              suggestedRequestor: suggestedRequestor.current,
              suggestedPriorAuthRequirements: suggestedPriorAuthRequirements,
            }}
          >
            <GatherRequirementsContainer
              priorAuthRequirements={priorAuthRequirements}
              setPriorAuthRequirements={setPriorAuthRequirements}
              palProcedureCodes={palProcedureCodes}
              setPalProcedureCodes={setPalProcedureCodes}
              nonPalProcedureCodes={nonPalProcedureCodes}
              setNonPalProcedureCodes={setNonPalProcedureCodes}
              nonCohereCodes={nonCohereCodes}
              setNonCohereCodes={setNonCohereCodes}
              setCrdLogId={setCrdLogId}
              noPxService={noPxService}
              setNoPxService={setNoPxService}
              patientData={patientData}
              importedEhrOrder={importedEhrOrder}
              setImportedEhrOrder={setImportedEhrOrder}
              currUser={currUser}
              setOpenNonPalConfirmationModal={setOpenNonPalConfirmationModal}
              setUserSelectedNonPalCode={setUserSelectedNonPalCode}
              setCrdProcedureCodeResults={setCrdProcedureCodeResults}
              setCrdExtensionCardResults={setCrdExtensionCardResults}
              facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
              formContent={serviceRequestFormContents[0]}
              setFormContent={setFirstServiceRequestFormContent}
              isFaxAuthBuilderFlow={isFaxAuthBuilderFlow}
              {...requestorProps}
            />
          </SuggestionContext.Provider>
        </FiredNudgesContext.Provider>
      );
      footerContents = (
        <SuggestionContext.Provider
          value={{
            suggestedFormContent: suggestedFormContent,
          }}
        >
          <GatherRequirementsFooter
            patient={patientData || undefined}
            palProcedureCodes={palProcedureCodes || []}
            nonPalProcedureCodes={nonPalProcedureCodes || []}
            noPxService={noPxService}
            priorAuthRequirements={priorAuthRequirements}
            mostRecentlyUsedFaxNumber={autoFillUserFaxNumber}
            buckets={buckets}
            setBuckets={setBuckets}
            primaryCarePathId={primaryCarePathId}
            setPrimaryCarePathId={setPrimaryCarePathId}
            setWorkflowStep={setWorkflowStep}
            fetchServiceRequests={fetchServiceRequests}
            fetchCarePath={fetchCarePath}
            setHasDuplicates={setHasDuplicates}
            loading={fetchCarePathLoading || fetchServiceRequestsLoading}
            workflowId={workflowId}
            importedEhrOrder={importedEhrOrder}
            serviceRequestFormContents={serviceRequestFormContents}
            setNonCohereProcedureCodes={setNonCohereCodes}
            setNonPalProcedureCodes={setNonPalProcedureCodes}
            setServiceDeterminationResponse={setServiceDeterminationResponse}
            setOpenNonPalConfirmationModal={setOpenNonPalConfirmationModal}
            openNonPalConfirmationModal={openNonPalConfirmationModal}
            setUserSelectedNonPalCode={setUserSelectedNonPalCode}
            userUpdatedTat={userUpdatedTat}
            serviceRequest={draftServiceRequest || undefined}
            authorizationId={draftServiceRequest?.authorization?.id}
            editServiceRequest={editServiceRequest}
            addServiceRequest={addServiceRequest}
            {...serviceRequestFormStateSetters}
            attachments={
              carePathJourney && carePathJourneyAttachments
                ? carePathJourneyAttachments || []
                : serviceRequestAttachments
                ? serviceRequestAttachments
                : serviceRequestStateAttachments || []
            }
            userResponse={user || undefined}
            selectedClinicalServiceIds={selectedClinicalServiceIds}
            selectedCarePathId={selectedCarePathId}
            setDraftSrId={setDraftSRId}
            setFooterHeight={setFooterHeight}
          />
        </SuggestionContext.Provider>
      );
      break;
    case "GATHER_REQUIREMENTS_PHARMACY":
      pageContents = (
        <GatherRequirementsPharmacyContainer
          priorAuthRequirements={priorAuthRequirements}
          setPriorAuthRequirements={setPriorAuthRequirements}
          attemptedGatherRequirementsSubmit={attemptedGatherRequirementsSubmit}
          serviceRequestFormContents={serviceRequestFormContents}
          setServiceRequestFormContents={serviceRequestFormStateSetters.setServiceRequestFormContents}
          patient={patientData}
          {...requestorProps}
        />
      );
      footerContents = (
        <GatherRequirementsPharmacyFooter
          priorAuthRequirements={priorAuthRequirements}
          setAttemptedGatherRequirementsSubmit={setAttemptedGatherRequirementsSubmit}
          serviceRequestFormContents={serviceRequestFormContents}
          workflowId={workflowId}
          patient={patientData || undefined}
          setWorkflowStep={setWorkflowStep}
          setServiceRequestFormContents={serviceRequestFormStateSetters.setServiceRequestFormContents}
          addServiceRequest={(response) => {
            addServiceRequest(response);

            // todo this should be moved to ServiceRequestConfigurationSpecification and fetched from the backend I think, same with HealthHelp requests
            setClinicalAssessmentProviders(["Agadia"]);

            startPollForClinicalAssessment({ pathParams: { id: response.id } });
          }}
          setFooterHeight={setFooterHeight}
        />
      );
      break;
    case "SELECT_SERVICE":
      pageContents = (
        <ServiceSelectionContainer
          patientData={patientData}
          primaryCarePath={primaryCarePath || undefined}
          buckets={buckets}
          setBuckets={setBuckets}
          serviceRequestHistory={serviceRequestData || []}
          {...requestorProps}
        />
      );
      footerContents = (
        <SuggestionContext.Provider
          value={{
            suggestedFormContent: suggestedFormContent,
          }}
        >
          <ServiceSelectionFooter
            priorAuthRequirements={priorAuthRequirements}
            buckets={buckets}
            mostRecentlyUsedFaxNumber={autoFillUserFaxNumber}
            primaryCarePathId={primaryCarePathId}
            setWorkflowStep={setWorkflowStep}
            carePathJourney={carePathJourney}
            setCarePathJourney={setCarePathJourney}
            patient={patientData || undefined}
            importedEhrOrder={importedEhrOrder}
            {...serviceRequestFormStateSetters}
            serviceRequestFormContents={serviceRequestFormContents}
            setFooterHeight={setFooterHeight}
          />
        </SuggestionContext.Provider>
      );
      break;
    case "SELECT_SERVICES":
      pageContents = (
        <ServiceSelectionFlexibleIntake
          patientId={patientData?.id || ""}
          priorAuthRequirements={priorAuthRequirements}
          procedureCodes={palProcedureCodes}
          nonPalProcedureCodes={nonPalProcedureCodes}
          primaryDiagnosisCode={priorAuthRequirements.primaryDiagnosis}
          secondaryDiagnosisCodes={priorAuthRequirements.secondaryDiagnoses || []}
          initialResponse={serviceDeterminationResponse}
          setServiceDeterminationResponse={setServiceDeterminationResponse}
          setBuckets={setBuckets}
          selectedClinicalServiceIds={selectedClinicalServiceIds}
          setServiceDeterminationLoading={setServiceDeterminationLoading}
          setSelectedClinicalServiceIds={setSelectedClinicalServiceIds}
          selectedCarePathId={selectedCarePathId}
          setSelectedCarePathId={setSelectedCarePathId}
          {...requestorProps}
        />
      );
      footerContents = (
        <ServiceSelectionFlexibleCodeFooter
          buckets={buckets}
          priorAuthRequirements={priorAuthRequirements}
          palProcedureCodes={palProcedureCodes}
          setServiceRequestFormContents={serviceRequestFormStateSetters.setServiceRequestFormContents}
          setServiceRequestFormsCanBeSubmitted={setServiceRequestFormsCanBeSubmitted}
          setServiceRequestFormsHaveNewEdits={setServiceRequestFormsHaveNewEdits}
          setWorkflowStep={setWorkflowStep}
          patient={patientData || undefined}
          mostRecentlyUsedFaxNumber={autoFillUserFaxNumber}
          importedEhrOrder={importedEhrOrder}
          suggestedFormContent={suggestedFormContent}
          serviceDeterminationLoading={serviceDeterminationLoading}
          setFooterHeight={setFooterHeight}
          facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
        />
      );
      break;
    case "PROVIDER_DETAILS":
      pageContents = (
        <SuggestionContext.Provider
          value={{
            suggestedRequestor: suggestedRequestor.current,
            suggestedFormContent: suggestedFormContent,
          }}
        >
          {serviceRequestFormContents && serviceRequestFormContents[0] && (
            <ProviderDetailsContainer
              formContent={serviceRequestFormContents[0]}
              setFormContent={setFirstServiceRequestFormContent}
              attemptedSubmit={attemptedServiceRequestFormsSubmit}
              patientHealthPlanName={healthPlan || ""}
              patient={patientData}
              delegatedVendorName={delegatedVendorName}
              workflowId={workflowId}
              authStatus="DRAFT"
              showRequestorCard
              requestorProps={requestorProps}
              userFaxExtension={autoFillUserFaxNumber}
              setPriorAuthRequirements={setPriorAuthRequirements}
              setProviderDetailsFormsCanBeSubmitted={setProviderDetailsFormsCanBeSubmitted}
              setPerformingProviderExceptionRequest={setPerformingProviderExceptionRequest}
              performingProviderExceptionRequest={performingProviderExceptionRequest}
              setFacilityExceptionRequest={setFacilityExceptionRequest}
              setPerformingProviderPracticeExceptionRequest={setPerformingProviderPracticeExceptionRequest}
              performingProviderPracticeExceptionRequest={performingProviderPracticeExceptionRequest}
              facilityExceptionRequest={facilityExceptionRequest}
              setOrderingProviderExceptionRequest={setOrderingProviderExceptionRequest}
              orderingProviderExceptionRequest={orderingProviderExceptionRequest}
              setCareParticipantExceptionRequest={setCareParticipantExceptionRequest}
              careParticipantExceptionRequest={careParticipantExceptionRequest}
              onProviderDetailsPage={true}
              setFacilityOonCheckLoading={setFacilityOonCheckLoading}
              setPerformingProviderPracticeOonCheckLoading={setPerformingProviderPracticeOonCheckLoading}
              setProviderOonCheckLoading={setProviderOonCheckLoading}
              setOrderingProviderOonCheckLoading={setOrderingProviderOonCheckLoading}
              setAdditionalCareOonCheckLoading={setAdditionalCareOonCheckLoading}
              {...requestorProps}
            />
          )}
        </SuggestionContext.Provider>
      );
      footerContents = (
        <ProviderDetailsFooter
          setWorkflowStep={setWorkflowStep}
          patient={patientData || null}
          setPriorAuthRequirements={setPriorAuthRequirements}
          setPalProcedureCodes={setPalProcedureCodes}
          setNonPalProcedureCodes={setNonPalProcedureCodes}
          setNonCohereCodes={setNonCohereCodes}
          noPxService={noPxService}
          priorAuthRequirements={priorAuthRequirements}
          attemptedServiceRequestFormsSubmit={attemptedServiceRequestFormsSubmit}
          setAttemptedServiceRequestFormsSubmit={setAttemptedServiceRequestFormsSubmit}
          serviceRequestFormContents={serviceRequestFormContents}
          oonLoading={
            !!disableContinueOONCheck &&
            (facilityOonCheckLoading ||
              providerOonCheckLoading ||
              performingProviderPracticeOonCheckLoading ||
              orderingProviderOonCheckLoading ||
              additionalCareOonCheckLoading)
          }
          setCrdLogId={setCrdLogId}
          setCrdProcedureCodeResults={setCrdProcedureCodeResults}
          setCrdExtensionCardResults={setCrdExtensionCardResults}
          providerDetailsFormsCanBeSubmitted={providerDetailsFormsCanBeSubmitted}
          workflowStep={workflowStep}
          carePathJourney={carePathJourney}
          editServiceRequest={editServiceRequest}
          userFaxExtension={user?.extension?.mostRecentlyUsedFaxNumber}
          currUser={currUser}
          workflowId={workflowId}
          patientData={patientData}
          setFooterHeight={setFooterHeight}
        />
      );
      break;
    case "SERVICE_DETAILS":
      pageContents = (
        <SuggestionContext.Provider
          value={{
            suggestedRequestor: suggestedRequestor.current,
            suggestedFormContent: suggestedFormContent,
          }}
        >
          {serviceRequestFormContents && serviceRequestFormContents[0] && (
            <ServiceDetailsContainer
              setPriorAuthRequirements={setPriorAuthRequirements}
              priorAuthRequirements={priorAuthRequirements}
              setOpenNonPalConfirmationModal={setOpenNonPalConfirmationModal}
              openNonPalConfirmationModal={openNonPalConfirmationModal}
              setUserSelectedNonPalCode={setUserSelectedNonPalCode}
              activeCoverage={activeCoverage}
              formContent={serviceRequestFormContents[0]}
              setFormContent={setFirstServiceRequestFormContent}
              attemptedSubmit={attemptedServiceDetailsSubmit}
              patientHealthPlanName={healthPlan || ""}
              patientRiskBearingEntity={riskBearingEntity || ""}
              patient={patientData}
              workflowId={workflowId}
              authStatus="DRAFT"
              palProcedureCodes={palProcedureCodes}
              setPalProcedureCodes={setPalProcedureCodes}
              nonPalProcedureCodes={nonPalProcedureCodes}
              setNonPalProcedureCodes={setNonPalProcedureCodes}
              nonCohereProcedureCodes={nonCohereCodes}
              setNonCohereProcedureCodes={setNonCohereCodes}
              setServiceDetailsFormsCanBeSubmitted={setServiceDetailsFormsCanBeSubmitted}
              requestorProps={requestorProps}
              allowMutablePxCodesWithNonPal={true}
              showRequestorCard
              crdProcedureCodeResults={crdProcedureCodeResults}
              crdExtensionCardResults={crdExtensionCardResults}
              allowedPxCodePerPayer={allowedPxCodePerPayer}
              isContinuationWorkflow={isNewContinuationSR}
              serviceRequests={serviceRequests}
              {...requestorProps}
              {...serviceRequestFormStateSetters}
            />
          )}
        </SuggestionContext.Provider>
      );
      footerContents = (
        <ServiceDetailsFooter
          clinicalAssessmentProviders={clinicalAssessmentProviders}
          crdLogId={crdLogId}
          setWorkflowStep={setWorkflowStep}
          serviceRequestFormContent={serviceRequestFormContents[0]}
          setServiceRequestFormContent={setFirstServiceRequestFormContent}
          authorizationId={draftServiceRequest?.authorization?.id}
          serviceRequestId={serviceRequestFormContents[0]?.id ?? serviceRequests[0]?.id}
          workflowId={workflowId}
          importedEhrOrder={importedEhrOrder}
          patient={patientData}
          editServiceRequest={editServiceRequest}
          setClinicalAssessmentProviders={setClinicalAssessmentProviders}
          carePathJourney={carePathJourney}
          getCarePathClinicalAssessment={getCarePathClinicalAssessment}
          getSingleServiceClinicalAssessment={getSingleServiceClinicalAssessment}
          getMultiSingleServiceClinicalAssessment={getMultiSingleServiceClinicalAssessment}
          nonPalProcedureCodes={nonPalProcedureCodes}
          nonCohereCodes={nonCohereCodes}
          palProcedureCodes={palProcedureCodes}
          serviceRequestFormHasNewEdits={serviceRequestFormsHaveNewEdits[0]}
          setHealthHelpPASToken={setHealthHelpPASToken}
          vendorAssessmentError={vendorAssessmentError}
          setVendorAssessmentError={setVendorAssessmentError}
          setVendorAssessmentCompleted={setVendorAssessmentCompleted}
          workflowStep={workflowStep}
          priorAuthRequirements={priorAuthRequirements}
          previousClinicalServiceIds={previousClinicalServiceIds}
          selectedClinicalServiceIds={selectedClinicalServiceIds}
          setPreviousClinicalServiceIds={setPreviousClinicalServiceIds}
          setAttemptedServiceDetailsSubmit={setAttemptedServiceDetailsSubmit}
          attemptedServiceRequestFormsSubmit={attemptedServiceDetailsSubmit}
          serviceDetailsFormsCanBeSubmitted={serviceDetailsFormsCanBeSubmitted}
          attachments={
            carePathJourney && carePathJourneyAttachments
              ? carePathJourneyAttachments || []
              : serviceRequestAttachments
              ? serviceRequestAttachments
              : serviceRequestStateAttachments || []
          }
          serviceRequests={serviceRequests}
          authFlowType={authFlowType}
          setCaqSkipped={setCaqSkipped}
          serviceRequestPatientAttributes={serviceRequestPatientAttributes}
          clinicalAssessmentSnomedCodes={clinicalAssessmentSnomedCodes}
          setFooterHeight={setFooterHeight}
          crdExtensionCardResults={crdExtensionCardResults}
          palCheckLoading={palCheckLoading}
        />
      );
      break;
    case "FILL_FORMS_GENERAL_AUTH_SUBMISSION":
      pageContents = (
        <FiredNudgesContext.Provider value={{ firedRecommendedNudges, setFiredRecommendedNudges }}>
          <SuggestionContext.Provider
            value={{
              suggestedRequestor: suggestedRequestor.current,
              suggestedFormContent: suggestedFormContent,
            }}
          >
            {serviceRequestFormContents && serviceRequestFormContents[0] && (
              <GeneralAuthSubmissionForms
                formContent={serviceRequestFormContents[0]}
                setFormContent={setFirstServiceRequestFormContent}
                attemptedSubmit={attemptedServiceRequestFormsSubmit}
                patientHealthPlanName={healthPlan || ""}
                patient={patientData}
                setServiceRequestFormsCanBeSubmitted={setServiceRequestFormsCanBeSubmitted}
                delegatedVendorName={delegatedVendorName}
                workflowId={workflowId}
                authStatus="DRAFT"
                /* Procedure Code Buckets */
                palProcedureCodes={palProcedureCodes}
                setPalProcedureCodes={setPalProcedureCodes}
                nonPalProcedureCodes={nonPalProcedureCodes}
                setNonPalProcedureCodes={setNonPalProcedureCodes}
                nonCohereProcedureCodes={nonCohereCodes}
                setNonCohereProcedureCodes={setNonCohereCodes}
                userFaxExtension={autoFillUserFaxNumber}
                showRequestorCard
                requestorProps={requestorProps}
                setRequestor={() => {}}
                priorAuthRequirements={priorAuthRequirements}
                setPriorAuthRequirements={setPriorAuthRequirements}
                onFillFormsPage={true}
              />
            )}
          </SuggestionContext.Provider>
        </FiredNudgesContext.Provider>
      );
      footerContents = (
        <FillFormsFooter
          currUser={currUser}
          patientId={patientData?.id || ""}
          serviceRequestFormContents={serviceRequestFormContents}
          setWorkflowStep={setWorkflowStep}
          workflowStep={workflowStep}
          serviceRequestFormsCanBeSubmitted={serviceRequestFormsCanBeSubmitted}
          healthPlanName={healthPlan || ""}
          workflowId={workflowId}
          importedEhrOrder={importedEhrOrder}
          requestor={requestor}
          setVendorAssessmentCompleted={setVendorAssessmentCompleted}
          setVendorAssessmentError={setVendorAssessmentError}
          vendorAssessmentError={vendorAssessmentError}
          serviceRequestFormsHaveNewEdits={serviceRequestFormsHaveNewEdits}
          setHealthHelpPASToken={setHealthHelpPASToken}
          editServiceRequest={editServiceRequest}
          addServiceRequest={addServiceRequest}
          getCaqBulkSearchableStatements={getCaqBulkSearchableStatements}
          crdLogId={crdLogId}
          setServiceRequestFormContents={setServiceRequestFormContents}
          setServiceRequestFormsCanBeSubmitted={setServiceRequestFormsCanBeSubmitted}
          setServiceRequestFormsHaveNewEdits={setServiceRequestFormsHaveNewEdits}
          carePathJourney={carePathJourney}
          loadingClinicalAssessment={loadingClinicalAssessment}
          attemptedServiceRequestFormsSubmit={attemptedServiceRequestFormsSubmit}
          oonLoading={
            facilityOonCheckLoading ||
            providerOonCheckLoading ||
            orderingProviderOonCheckLoading ||
            performingProviderPracticeOonCheckLoading
          }
          setAttemptedServiceRequestFormsSubmit={setAttemptedServiceRequestFormsSubmit}
          getCarePathClinicalAssessment={getCarePathClinicalAssessment}
          getSingleServiceClinicalAssessment={getSingleServiceClinicalAssessment}
          getMultiSingleServiceClinicalAssessment={getMultiSingleServiceClinicalAssessment}
          setClinicalAssessmentProviders={setClinicalAssessmentProviders}
          nonPalProcedureCodes={nonPalProcedureCodes}
          setFooterHeight={setFooterHeight}
        />
      );
      break;
    case "FILL_FORMS_CONTINUATION":
      //props only needed for continuation - fax intake
      const clinicalAssessmentProps = {
        serviceRequests,
        authFlowType,
        setCaqSkipped,
        serviceRequestPatientAttributes,
        clinicalAssessmentSnomedCodes,
        clinicalAssessmentProviders,
      };

      pageContents = (
        <>
          {serviceRequestFormContents && serviceRequestFormContents[0] && (
            <FillFormsContinuationContainer
              serviceRequestFormContents={serviceRequestFormContents}
              serviceRequestsCanBeSubmitted={serviceRequestFormsCanBeSubmitted}
              attemptedSubmit={attemptedServiceRequestFormsSubmit}
              patient={patientData || undefined}
              userFaxExtension={autoFillUserFaxNumber}
              workflowId={workflowId}
              {...serviceRequestFormStateSetters}
              {...requestorProps}
              serviceRequest={draftServiceRequest || undefined}
              authorizationId={draftServiceRequest?.authorization?.id}
              authorization={authorizationData}
              priorAuthRequirements={priorAuthRequirements}
              setPriorAuthRequirements={setPriorAuthRequirements}
              onUpdateDiagnosisCodes={async () => {
                // Can get away with a single SR refetch since this is a continuation and we're only editing 1 SR at a time
                if (draftSRId) {
                  getDraftServiceRequest();
                }
              }}
              setAllowDischargeModalToOpen={setAllowDischargeModalToOpen}
              setIsFormContentOnContinuationUpdated={setIsFormContentOnContinuationUpdated}
              isFormContentOnContinuationUpdated={isFormContentOnContinuationUpdated}
              setAttemptedSubmit={setAttemptedServiceRequestFormsSubmit}
              facilityExceptionRequest={facilityExceptionRequest}
              performingProviderPracticeExceptionRequest={performingProviderPracticeExceptionRequest}
              performingProviderExceptionRequest={performingProviderExceptionRequest}
              careParticipantExceptionRequest={careParticipantExceptionRequest}
              setDisableContinueButtonOnContinuation={setDisableContinueButtonOnContinuation}
              setFacilityExceptionRequest={setFacilityExceptionRequest}
              setPerformingProviderPracticeExceptionRequest={setPerformingProviderPracticeExceptionRequest}
              setPerformingProviderExceptionRequest={setPerformingProviderExceptionRequest}
              setCareParticipantExceptionRequest={setCareParticipantExceptionRequest}
              allowedPxCodePerPayer={allowedPxCodePerPayer}
            />
          )}
        </>
      );
      footerContents = (
        <FillFormsFooter
          patientId={patientId || ""}
          authorizationId={draftServiceRequest?.authorization?.id}
          carePathJourney={carePathJourney}
          serviceRequestFormContents={serviceRequestFormContents}
          setWorkflowStep={setWorkflowStep}
          workflowStep={workflowStep}
          serviceRequestFormsCanBeSubmitted={serviceRequestFormsCanBeSubmitted}
          attemptedServiceRequestFormsSubmit={attemptedServiceRequestFormsSubmit}
          setAttemptedServiceRequestFormsSubmit={setAttemptedServiceRequestFormsSubmit}
          addServiceRequest={addServiceRequest}
          editServiceRequest={editServiceRequest}
          getCarePathClinicalAssessment={getCarePathClinicalAssessment}
          getSingleServiceClinicalAssessment={getSingleServiceClinicalAssessment}
          getMultiSingleServiceClinicalAssessment={getMultiSingleServiceClinicalAssessment}
          loadingClinicalAssessment={loadingClinicalAssessment}
          setClinicalAssessmentProviders={setClinicalAssessmentProviders}
          setHealthHelpPASToken={setHealthHelpPASToken}
          currUser={currUser}
          userFaxExtension={user?.extension?.mostRecentlyUsedFaxNumber}
          serviceRequestFormsHaveNewEdits={serviceRequestFormsHaveNewEdits}
          setVendorAssessmentCompleted={setVendorAssessmentCompleted}
          requestor={requestor}
          setVendorAssessmentError={setVendorAssessmentError}
          vendorAssessmentError={vendorAssessmentError}
          workflowId={workflowId}
          importedEhrOrder={importedEhrOrder}
          {...serviceRequestFormStateSetters}
          oonLoading={
            facilityOonCheckLoading ||
            providerOonCheckLoading ||
            orderingProviderOonCheckLoading ||
            performingProviderPracticeOonCheckLoading
          }
          getCaqBulkSearchableStatements={getCaqBulkSearchableStatements}
          isContinuationWorkflow={true}
          healthPlanName={healthPlan || ""}
          nonPalProcedureCodes={nonPalProcedureCodes}
          allowDischargeModalToOpen={allowDischargeModalToOpen}
          setAllowDischargeModalToOpen={setAllowDischargeModalToOpen}
          priorAuthRequirements={priorAuthRequirements}
          disableContinueButtonOnContinuation={disableContinueButtonOnContinuation}
          clinicalAssessmentProps={clinicalAssessmentProps}
          setFooterHeight={setFooterHeight}
        />
      );
      break;
    //TODO: clean up by June 1, 2023 -- COH - 3422
    case "FILL_FORMS_FAX":
      if (serviceRequestFormContents && serviceRequestFormContents.length === 0) {
        setServiceRequestFormContents([
          {
            expeditedReason: "",
            facility: null,
            facilitySelectedTin: null,
            isExpedited: false,
            isInpatient: false,
            orderingProvider: null,
            orderingProviderSelectedTin: null,
            palCategory: null,
            performingProvider: null,
            performingProviderSelectedTin: null,
            placeOfService: null,
            primaryDiagnosisCode: null,
            procedureCodes: [],
            secondaryDiagnosisCodes: [],
            startDate: new Date(),
            unitType: undefined,
            units: "",
            approvedUnits: "",
            facilitySelectedAddress: null,
            performingProviderSelectedAddress: null,
            possibleAttachmentNudgeReasons: [],
            orderingProviderSelectedAddress: null,
            authorizationType: undefined,
          },
        ]);
      }
      pageContents = (
        <SuggestionContext.Provider
          value={{
            suggestedFormContent: suggestedFormContent,
            suggestedPriorAuthRequirements: suggestedPriorAuthRequirements,
          }}
        >
          <FillFormsFaxContainer
            serviceRequestFormContents={serviceRequestFormContents}
            serviceRequestsCanBeSubmitted={serviceRequestFormsCanBeSubmitted}
            attemptedSubmit={attemptedServiceRequestFormsSubmit}
            requestorFormAuthorized={false}
            setRequestor={() => {}}
            setServiceRequestFormContents={setServiceRequestFormContents}
            setServiceRequestFormsCanBeSubmitted={setServiceRequestFormsCanBeSubmitted}
            setServiceRequestFormsHaveNewEdits={setServiceRequestFormsHaveNewEdits}
            patient={patientData || undefined}
            patientHealthPlanName={healthPlan || undefined}
          />
        </SuggestionContext.Provider>
      );
      footerContents = (
        <SuggestionContext.Provider
          value={{
            suggestedFormContent: suggestedFormContent,
            suggestedPriorAuthRequirements: suggestedPriorAuthRequirements,
          }}
        >
          <FillFormsFaxFooter
            patientId={patientId || ""}
            serviceRequestFormContents={serviceRequestFormContents}
            setServiceRequestFormContents={setServiceRequestFormContents}
            setServiceRequestFormsCanBeSubmitted={setServiceRequestFormsCanBeSubmitted}
            setServiceRequestFormsHaveNewEdits={setServiceRequestFormsHaveNewEdits}
            serviceRequestFormsCanBeSubmitted={serviceRequestFormsCanBeSubmitted}
            healthPlanName={healthPlan || ""}
            setFooterHeight={setFooterHeight}
          />
        </SuggestionContext.Provider>
      );
      break;
    case "CLINICAL_ASSESSMENT":
      pageContents = (
        <SuggestionContext.Provider
          value={{
            suggestedRequestor: suggestedRequestor.current,
          }}
        >
          <ClinicalAssessmentContainer
            loadingCarePathClinicalAssessment={loadingCarePathClinicalAssessment}
            loadingSingleServiceClinicalAssessment={
              multiSingleService && authFlowType !== "PHARMACY"
                ? loadingMultiSingleServiceClinicalAssessment
                : loadingSingleServiceClinicalAssessment
            }
            {...requestorProps}
          />
        </SuggestionContext.Provider>
      );
      footerContents = (
        <ClinicalAssessmentFooter
          serviceRequests={serviceRequests}
          setWorkflowStep={setWorkflowStep}
          workflowStep={workflowStep}
          patientId={patientId || ""}
          carePathJourney={carePathJourney}
          singleService={!Boolean(carePathJourney) ? serviceRequests[0] : undefined}
          clinicalAssessmentProviders={clinicalAssessmentProviders}
          workflowId={workflowId}
          refetchActions={getServiceRequestRuleActionsResponse}
          loadingActions={loadingActions}
          setDedicatedNudgeServiceRequestId={setDedicatedNudgeServiceRequestId}
          dedicatedNudgeIds={dedicatedNudgeIds}
          setDedicatedNudgeIds={setDedicatedNudgeIds}
          setCurrentDedicatedNudgeTargetForAction={setCurrentDedicatedNudgeTargetForAction}
          authFlowType={authFlowType}
          displayClearerDraftComponents={displayClearerDraftComponents}
          dedicatedNudgeResponses={dedicatedNudgeResponses}
          setFooterHeight={setFooterHeight}
        />
      );
      break;
    case "CLINICAL_ASSESSMENT_CONTINUATION":
      pageContents = (
        <ClinicalAssessmentContainer
          loadingCarePathClinicalAssessment={loadingCarePathClinicalAssessment}
          loadingSingleServiceClinicalAssessment={
            multiSingleService && authFlowType !== "PHARMACY"
              ? loadingMultiSingleServiceClinicalAssessment
              : loadingSingleServiceClinicalAssessment
          }
          {...requestorProps}
        />
      );
      footerContents = (
        <ClinicalAssessmentFooter
          serviceRequests={serviceRequests}
          setWorkflowStep={setWorkflowStep}
          workflowStep={workflowStep}
          patientId={patientId || ""}
          carePathJourney={carePathJourney}
          singleService={!Boolean(carePathJourney) ? serviceRequests[0] : undefined}
          clinicalAssessmentProviders={clinicalAssessmentProviders}
          workflowId={workflowId}
          refetchActions={getServiceRequestRuleActionsResponse}
          loadingActions={loadingActions}
          setDedicatedNudgeServiceRequestId={setDedicatedNudgeServiceRequestId}
          dedicatedNudgeIds={dedicatedNudgeIds}
          setDedicatedNudgeIds={setDedicatedNudgeIds}
          setCurrentDedicatedNudgeTargetForAction={setCurrentDedicatedNudgeTargetForAction}
          authFlowType={authFlowType}
          displayClearerDraftComponents={displayClearerDraftComponents}
          isContinuationWorkflow={true}
          dedicatedNudgeResponses={dedicatedNudgeResponses}
          setFooterHeight={setFooterHeight}
        />
      );
      break;
    case "VENDOR_CLINICAL_ASSESSMENT":
      pageContents = (
        <VendorAssessmentContainer
          delegatedVendorName={delegatedVendorName}
          cohereId={serviceRequests?.find((sr) => sr?.delegatedVendor === delegatedVendorName)?.cohereId}
          healthHelpPASRequestResponse={healthHelpPASToken}
          setWorkflowStep={() => setWorkflowStep("REVIEW")}
          questionnaireComplete={vendorAssessmentCompleted}
          setQuestionnaireComplete={setVendorAssessmentCompleted}
          serviceRequestId={serviceRequestIds}
          vendorAssessmentError={vendorAssessmentError}
          setVendorAssessmentError={setVendorAssessmentError}
        />
      );
      footerContents = (
        <VendorClinicalAssessmentFooter
          vendorAssessmentCompleted={vendorAssessmentCompleted}
          setWorkflowStep={setWorkflowStep}
          serviceRequestId={serviceRequests?.find((sr) => sr?.delegatedVendor === delegatedVendorName)?.id}
          workflowStep={workflowStep}
          patientId={patientId || ""}
          serviceRequests={serviceRequests}
          displayClearerDraftComponents={displayClearerDraftComponents}
          setFooterHeight={setFooterHeight}
        />
      );
      break;
    case "ADD_ATTACHMENTS":
      pageContents = (
        <SuggestionContext.Provider
          value={{
            suggestedRequestor: suggestedRequestor.current,
          }}
        >
          <AttachmentsContainer
            loadingAttachments={loadingServiceRequestAttachments || loadingCarePathJourneyAttachments}
            srLoadingActions={srLoadingActions}
            srRuleActions={srRuleActions}
            carePathJourneyId={carePathJourney?.id || ""}
            serviceRequestIds={serviceRequests.map((sr) => sr.id)}
            attachments={
              carePathJourney && carePathJourneyAttachments
                ? carePathJourneyAttachments || []
                : serviceRequestAttachments
                ? serviceRequestAttachments
                : serviceRequestStateAttachments || []
            }
            addAttachmentToServiceRequests={addAttachmentToServiceRequests}
            possibleAttachmentNudgeReasons={serviceRequestFormContents[0]?.possibleAttachmentNudgeReasons}
            serviceRequests={serviceRequests}
            setFormContent={setFirstServiceRequestFormContent}
            setCanContinue={setCanContinueToClinicalAssessment}
            workflowId={workflowId}
            authFlowType={authFlowType}
            {...requestorProps}
            setRemovedAttachments={setRemovedAttachments}
          />
        </SuggestionContext.Provider>
      );
      footerContents = (
        <AttachmentsFooter
          clinicalAssessmentProviders={clinicalAssessmentProviders}
          serviceRequests={serviceRequests}
          setWorkflowStep={setWorkflowStep}
          workflowStep={workflowStep}
          serviceRequestId={serviceRequestFormContents[0]?.id || serviceRequests[0]?.id}
          possibleAttachmentNudgeReasons={serviceRequestFormContents[0]?.possibleAttachmentNudgeReasons}
          patientId={patientId || ""}
          canContinueToClinicalAssessment={canContinueToClinicalAssessment}
          carePathJourney={carePathJourney}
          workflowId={workflowId}
          authFlowType={authFlowType}
          displayClearerDraftComponents={displayClearerDraftComponents}
          loadingAction={loadingActions}
          setAttachmentsRuleActions={setAttachmentsRuleActions}
          refetchActions={getServiceRequestRuleActionsResponse}
          setDedicatedNudgeServiceRequestId={setDedicatedNudgeServiceRequestId}
          dedicatedNudgeIds={dedicatedNudgeIds}
          setDedicatedNudgeIds={setDedicatedNudgeIds}
          setCurrentDedicatedNudgeTargetForAction={setCurrentDedicatedNudgeTargetForAction}
          setCaqSkipped={setCaqSkipped}
          serviceRequestPatientAttributes={serviceRequestPatientAttributes}
          clinicalAssessmentSnomedCodes={clinicalAssessmentSnomedCodes}
          vendorAssessmentError={vendorAssessmentError}
          delegatedVendorName={delegatedVendorName}
          dedicatedNudgeResponses={dedicatedNudgeResponses}
          srLoadingActions={srLoadingActions}
          setFooterHeight={setFooterHeight}
        />
      );
      break;
    case "ADD_ATTACHMENTS_CONTINUATION":
      pageContents = (
        <AttachmentsContainer
          loadingAttachments={loadingServiceRequestAttachments || loadingCarePathJourneyAttachments}
          carePathJourneyId={carePathJourney?.id || ""}
          serviceRequestIds={serviceRequests.map((sr) => sr.id)}
          attachments={
            carePathJourney && carePathJourneyAttachments
              ? carePathJourneyAttachments || []
              : serviceRequestAttachments
              ? serviceRequestAttachments
              : serviceRequestStateAttachments || []
          }
          addAttachmentToServiceRequests={addAttachmentToServiceRequests}
          possibleAttachmentNudgeReasons={serviceRequestFormContents[0]?.possibleAttachmentNudgeReasons}
          setFormContent={setFirstServiceRequestFormContent}
          serviceRequests={serviceRequests}
          setCanContinue={setCanContinueToClinicalAssessment}
          workflowId={workflowId}
          authFlowType={authFlowType}
          {...requestorProps}
          setRemovedAttachments={setRemovedAttachments}
        />
      );
      footerContents = (
        <AttachmentsFooter
          clinicalAssessmentProviders={clinicalAssessmentProviders}
          serviceRequests={serviceRequests}
          setWorkflowStep={setWorkflowStep}
          workflowStep={workflowStep}
          serviceRequestId={serviceRequestFormContents[0]?.id || serviceRequests[0]?.id}
          possibleAttachmentNudgeReasons={serviceRequestFormContents[0]?.possibleAttachmentNudgeReasons}
          patientId={patientId || ""}
          canContinueToClinicalAssessment={canContinueToClinicalAssessment}
          carePathJourney={carePathJourney}
          workflowId={workflowId}
          authFlowType={authFlowType}
          displayClearerDraftComponents={displayClearerDraftComponents}
          loadingAction={loadingActions}
          setAttachmentsRuleActions={setAttachmentsRuleActions}
          refetchActions={getServiceRequestRuleActionsResponse}
          setDedicatedNudgeServiceRequestId={setDedicatedNudgeServiceRequestId}
          dedicatedNudgeIds={dedicatedNudgeIds}
          setDedicatedNudgeIds={setDedicatedNudgeIds}
          setCurrentDedicatedNudgeTargetForAction={setCurrentDedicatedNudgeTargetForAction}
          setCaqSkipped={setCaqSkipped}
          serviceRequestPatientAttributes={serviceRequestPatientAttributes}
          clinicalAssessmentSnomedCodes={clinicalAssessmentSnomedCodes}
          isContinuationWorkflow={true}
          vendorAssessmentError={vendorAssessmentError}
          delegatedVendorName={delegatedVendorName}
          dedicatedNudgeResponses={dedicatedNudgeResponses}
          setFooterHeight={setFooterHeight}
        />
      );
      break;
    case "REVIEW_NUDGES":
      pageContents = (
        <ReviewNudgeContainer
          serviceRequests={serviceRequests}
          dedicatedNudgeIds={dedicatedNudgeIds}
          currentDedicatedNudgeTargetForAction={currentDedicatedNudgeTargetForAction}
          rulesActionsData={ruleActions}
          setCanContinueToReviewPage={setCanContinueToReviewPage}
          placeOfServiceToUpdateTo={placeOfServiceToUpdateTo}
          setPlaceOfServiceToUpdateTo={setPlaceOfServiceToUpdateTo}
          setCareTypeToUpdateTo={setCareTypeToUpdateTo}
          dedicatedNudgeServiceRequestId={dedicatedNudgeServiceRequestId}
          setDedicatedNudgeAccepted={setDedicatedNudgeAccepted}
          canContinueToReviewPage={canContinueToReviewPage}
          workflowId={workflowId}
          setHasRuleRuns={setHasRuleRuns}
          loadingActions={loadingActions}
          setDedicatedNudgeServiceRequestId={setDedicatedNudgeServiceRequestId}
          acceptedDedicatedNudges={acceptedDedicatedNudges}
          setAcceptedDedicatedNudges={setAcceptedDedicatedNudges}
          declinedNudges={declinedNudges}
          setDeclinedNudges={setDeclinedNudges}
          formContent={serviceRequestFormContents[0]}
          setFormContent={setFirstServiceRequestFormContent}
          attemptedSubmit={attemptedServiceRequestFormsSubmit}
          workflowStep={workflowStep}
          patientId={patientId || ""}
          possibleAttachmentNudgeReasons={serviceRequestFormContents?.[0]?.possibleAttachmentNudgeReasons}
          currentRuleNudge={currentRuleNudge}
          setCurrentRuleNudge={setCurrentRuleNudge}
          continueDisabledForAddAttachmentsDNS={continueDisabledForAddAttachmentsDNS}
          setContinueDisabledForAddAttachmentsDNS={setContinueDisabledForAddAttachmentsDNS}
          setUpdatedClinicalServices={setUpdatedClinicalServices}
          updatedClinicalServices={updatedClinicalServices}
          selectedCarePathId={selectedCarePathId}
        />
      );
      footerContents = serviceRequestFormContents[0] ? (
        <ReviewNudgeFooter
          setWorkflowStep={setWorkflowStep}
          workflowStep={workflowStep}
          serviceRequestId={serviceRequestFormContents[0]?.id}
          patientId={patientId || ""}
          serviceRequests={serviceRequests}
          dedicatedNudgeServiceRequestId={dedicatedNudgeServiceRequestId || ""}
          canContinueToReviewPage={canContinueToReviewPage}
          placeOfServiceToUpdateTo={placeOfServiceToUpdateTo}
          careTypeToUpdateTo={careTypeToUpdateTo}
          dedicatedNudgeIds={dedicatedNudgeIds}
          currentDedicatedNudgeTargetForAction={currentDedicatedNudgeTargetForAction}
          setCurrentDedicatedNudgeTargetForAction={setCurrentDedicatedNudgeTargetForAction}
          setDedicatedNudgeServiceRequestId={setDedicatedNudgeServiceRequestId}
          setDedicatedNudgeIds={setDedicatedNudgeIds}
          dedicatedNudgeResponses={dedicatedNudgeResponses}
          setDedicatedNudgeResponses={setDedicatedNudgeResponses}
          rulesActionsData={ruleActions}
          workflowId={workflowId}
          editServiceRequest={editServiceRequest}
          dedicatedNudgeAccepted={dedicatedNudgeAccepted}
          displayClearerDraftComponents={displayClearerDraftComponents}
          refetchActions={getServiceRequestRuleActionsResponse}
          loadingActions={loadingActions}
          dnsNudgeIndex={dnsNudgeIndex}
          setDnsNudgeIndex={setDnsNudgeIndex}
          acceptedDedicatedNudges={acceptedDedicatedNudges}
          setShowServiceChangeBanner={setShowServiceChangeBanner}
          formContent={serviceRequestFormContents[0]}
          possibleAttachmentNudgeReasons={serviceRequestFormContents?.[0]?.possibleAttachmentNudgeReasons}
          currentRuleNudge={currentRuleNudge}
          continueDisabledForAddAttachmentsDNS={continueDisabledForAddAttachmentsDNS}
          setFormContent={setFirstServiceRequestFormContent}
          setFooterHeight={setFooterHeight}
          updatedClinicalServices={updatedClinicalServices}
        />
      ) : null;
      break;
    case "PRE_APPROVED_CARE_DETAILS":
      pageContents = (
        <SuggestionContext.Provider
          value={{
            suggestedRequestor: suggestedRequestor.current,
            suggestedPriorAuthRequirements: suggestedPriorAuthRequirements,
            suggestedFormContent: suggestedFormContent,
          }}
        >
          <PreApprovedCareContainer
            serviceRequests={serviceRequests}
            formContent={serviceRequestFormContents[0]}
            setFormContent={setFirstServiceRequestFormContent}
            attemptedSubmit={attemptedServiceRequestFormsSubmit}
            patientHealthPlanName={healthPlan || ""}
            patient={patientData || undefined}
            delegatedVendorName={delegatedVendorName}
            workflowId={workflowId}
            authStatus={serviceRequests[0]?.authStatus || "DRAFT"}
            setProviderDetailsFormsCanBeSubmitted={setProviderDetailsFormsCanBeSubmitted}
            setFacilityOonCheckLoading={setFacilityOonCheckLoading}
            setProviderOonCheckLoading={setProviderOonCheckLoading}
            setPerformingProviderPracticeOonCheckLoading={setPerformingProviderPracticeOonCheckLoading}
            setOrderingProviderOonCheckLoading={setOrderingProviderOonCheckLoading}
            {...requestorProps}
          />
        </SuggestionContext.Provider>
      );
      footerContents = (
        <SuggestionContext.Provider
          value={{
            suggestedRequestor: suggestedRequestor.current,
          }}
        >
          <PreApprovedCareFooter
            requestor={requestor}
            serviceRequests={serviceRequests}
            attemptedServiceRequestFormsSubmit={attemptedServiceRequestFormsSubmit}
            setAttemptedServiceRequestFormsSubmit={setAttemptedServiceRequestFormsSubmit}
            serviceRequestFormContents={serviceRequestFormContents}
            oonLoading={
              facilityOonCheckLoading ||
              providerOonCheckLoading ||
              orderingProviderOonCheckLoading ||
              performingProviderPracticeOonCheckLoading
            }
            providerDetailsFormsCanBeSubmitted={providerDetailsFormsCanBeSubmitted}
            workflowStep={workflowStep}
            editServiceRequest={editServiceRequest}
            workflowId={workflowId}
            setWorkflowStep={setWorkflowStep}
            priorAuthRequirements={priorAuthRequirements}
            setPriorAuthRequirements={setPriorAuthRequirements}
            setServiceRequestFormContents={setServiceRequestFormContents}
            setFooterHeight={setFooterHeight}
          />
        </SuggestionContext.Provider>
      );
      break;
    case "REVIEW":
      pageContents = (
        <FiredNudgesContext.Provider value={{ firedRecommendedNudges, setFiredRecommendedNudges }}>
          <SuggestionContext.Provider
            value={{
              suggestedRequestor: suggestedRequestor.current,
              suggestedPriorAuthRequirements: suggestedPriorAuthRequirements,
              suggestedFormContent: suggestedFormContent,
            }}
          >
            <ReviewContainer
              ruleActionError={ruleActionError}
              serviceRequests={serviceRequests}
              editServiceRequest={editServiceRequest}
              deleteServiceRequest={deleteServiceRequest}
              setPreSubmissionRedirectActions={setPreSubmissionRedirectActions}
              workflowId={workflowId}
              setReviewEdit={setReviewEdit}
              dedicatedNudgeIds={dedicatedNudgeIds}
              hasRuleRuns={hasRuleRuns}
              ruleActionsData={ruleActions ?? []}
              loadingAction={loadingActions}
              getServiceRequestRuleActionsResponse={getServiceRequestRuleActionsResponse}
              caqSkipped={caqSkipped}
              serviceRequestSubmitted={attemptedSubmitServices}
              performingProviderExceptionRequest={performingProviderExceptionRequest}
              setperformingProviderExceptionRequest={setPerformingProviderExceptionRequest}
              facilityExceptionRequest={facilityExceptionRequest}
              setFacilityExceptionRequest={setFacilityExceptionRequest}
              performingProviderPracticeExceptionRequest={performingProviderPracticeExceptionRequest}
              setPerformingProviderPracticeExceptionRequest={setPerformingProviderPracticeExceptionRequest}
              setPerformingProviderPracticeOonCheckLoading={setPerformingProviderPracticeOonCheckLoading}
              careParticipantExceptionRequest={careParticipantExceptionRequest}
              setCareParticipantExceptionRequest={setCareParticipantExceptionRequest}
              formContent={serviceRequestFormContents || []}
              setFormContent={setFirstServiceRequestFormContent}
              setDisableContinueButtonOnContinuation={setDisableContinueButtonOnContinuation}
              orderingProviderExceptionRequest={orderingProviderExceptionRequest}
              setOrderingProviderExceptionRequest={setOrderingProviderExceptionRequest}
              declinedNudges={declinedNudges}
              showServiceChangeBanner={showServiceChangeBanner}
              setShowServiceChangeBanner={setShowServiceChangeBanner}
              user={user}
              visibilityToggleStatus={visibilityToggleStatus}
              setVisibilityToggleStatus={setVisibilityToggleStatus}
              {...requestorProps}
            />
          </SuggestionContext.Provider>
        </FiredNudgesContext.Provider>
      );
      footerContents = (
        <SuggestionContext.Provider
          value={{
            suggestedRequestor: suggestedRequestor.current,
            suggestedPriorAuthRequirements: suggestedPriorAuthRequirements,
            suggestedFormContent: suggestedFormContent,
          }}
        >
          <ReviewFooter
            hasDuplicates={hasDuplicates}
            requestor={requestor}
            serviceRequests={serviceRequests}
            workflowId={workflowId}
            workflowStep={workflowStep}
            loadingActions={loadingActions}
            preSubmissionRedirectActions={preSubmissionRedirectActions}
            attemptedSubmitServices={attemptedSubmitServices}
            setAttemptedSubmitServices={setAttemptedSubmitServices}
            authFlowType={authFlowType}
            reviewEdit={reviewEdit}
            importedEhrOrder={importedEhrOrder}
            displayClearerDraftComponents={displayClearerDraftComponents}
            facilityBasedFeatureEnabled={!!facilityBasedFeatureEnabled}
            disableContinueButtonOnContinuation={disableContinueButtonOnContinuation}
            setFooterHeight={setFooterHeight}
            visibilityToggleStatus={visibilityToggleStatus}
          />
        </SuggestionContext.Provider>
      );
      break;
    case "REVIEW_CONTINUATION":
      pageContents = (
        <ReviewContainer
          serviceRequests={serviceRequests}
          editServiceRequest={editServiceRequest}
          deleteServiceRequest={deleteServiceRequest}
          setPreSubmissionRedirectActions={setPreSubmissionRedirectActions}
          workflowId={workflowId}
          setReviewEdit={setReviewEdit}
          dedicatedNudgeIds={dedicatedNudgeIds}
          hasRuleRuns={hasRuleRuns}
          ruleActionsData={ruleActions ?? []}
          loadingAction={loadingActions}
          getServiceRequestRuleActionsResponse={getServiceRequestRuleActionsResponse}
          caqSkipped={caqSkipped}
          isContinuationWorkflow={true}
          serviceRequestSubmitted={attemptedSubmitServices}
          formContent={serviceRequestFormContents || []}
          setFormContent={setFirstServiceRequestFormContent}
          setDisableContinueButtonOnContinuation={setDisableContinueButtonOnContinuation}
          performingProviderExceptionRequest={performingProviderExceptionRequest}
          setperformingProviderExceptionRequest={setPerformingProviderExceptionRequest}
          facilityExceptionRequest={facilityExceptionRequest}
          setFacilityExceptionRequest={setFacilityExceptionRequest}
          careParticipantExceptionRequest={careParticipantExceptionRequest}
          setCareParticipantExceptionRequest={setCareParticipantExceptionRequest}
          showServiceChangeBanner={showServiceChangeBanner}
          setShowServiceChangeBanner={setShowServiceChangeBanner}
          user={user}
          visibilityToggleStatus={visibilityToggleStatus}
          setVisibilityToggleStatus={setVisibilityToggleStatus}
          {...requestorProps}
        />
      );
      footerContents = (
        <SuggestionContext.Provider
          value={{
            suggestedRequestor: suggestedRequestor.current,
            suggestedPriorAuthRequirements: suggestedPriorAuthRequirements,
            suggestedFormContent: suggestedFormContent,
          }}
        >
          <ReviewFooter
            hasDuplicates={hasDuplicates}
            requestor={requestor}
            serviceRequests={serviceRequests}
            workflowId={workflowId}
            workflowStep={workflowStep}
            preSubmissionRedirectActions={preSubmissionRedirectActions}
            attemptedSubmitServices={attemptedSubmitServices}
            setAttemptedSubmitServices={setAttemptedSubmitServices}
            loadingActions={loadingActions}
            authFlowType={authFlowType}
            reviewEdit={reviewEdit}
            importedEhrOrder={importedEhrOrder}
            displayClearerDraftComponents={displayClearerDraftComponents}
            facilityBasedFeatureEnabled={!!facilityBasedFeatureEnabled}
            disableContinueButtonOnContinuation={disableContinueButtonOnContinuation}
            setFooterHeight={setFooterHeight}
            visibilityToggleStatus={visibilityToggleStatus}
          />
        </SuggestionContext.Provider>
      );
      break;
  }

  if (!patientId) {
    logError(new Error("No patientId found in route"));
  }
  const navButtonsHeight = 115;

  const [atTopOfPage, setAtTopOfPage] = useState(true);
  useEffect(() => {
    const onScroll = () => setAtTopOfPage(window.scrollY === 0);

    window.removeEventListener("scroll", onScroll); //remove listener if one already exists

    window.addEventListener("scroll", onScroll, { passive: true }); //add scroll listener

    return () => window.removeEventListener("scroll", onScroll); //remove listener when this component is unmounted
  }, []);

  const hasPharmacy = Boolean(
    patientData &&
      patientData?.allowedServiceRequestTypes &&
      patientData?.allowedServiceRequestTypes.includes("PHARMACY")
  );

  const theme = useTheme();
  const requireNavButtonHeaderSpace =
    pharmacyAuthBuilderFlowEnabled &&
    hasPharmacy &&
    atTopOfPage &&
    ["GATHER_REQUIREMENTS_MEDICAL", "GATHER_REQUIREMENTS_PHARMACY"].includes(workflowStep);

  const headerHeightWithNavButtons = isFaxAuthBuilderFlow
    ? slimFaxHeaderHeight
    : requireNavButtonHeaderSpace
    ? headerHeight + navButtonsHeight
    : headerHeight;

  const footerOffset = isFaxAuthBuilderFlow
    ? footerHeight + 40
    : theme.spacing(10.625) + (atTopOfPage ? 0 : navButtonsHeight);

  const currentServiceRequest: ServiceRequestResponse | null = serviceRequests.length
    ? serviceRequests[0]
    : draftServiceRequest;

  return (
    <FaxAttachmentContext.Provider
      value={{
        faxDbId: faxId,
        caseId: qmCaseId || queueMgmtCaseId,
        queueId,
        faxAttachment: faxData,
        healthPlanName: healthPlan ?? undefined,
      }}
    >
      <NavigationContext.Provider
        value={{
          workflowStep: workflowStep,
          authFlowType: authFlowType,
          draftServiceRequest: currentServiceRequest,
          navigateBack: navigateBack,
          navigateBackContinuations: navigateBackContinuations,
          isFaxAuthBuilderFlow: isFaxAuthBuilderFlow,
        }}
      >
        <div>
          <AuthBuilderHeader
            headerHeightWithNavButtons={headerHeightWithNavButtons}
            authFlowType={authFlowType}
            setAuthFlowType={setAuthFlowType}
            setServiceRequestFormContents={setServiceRequestFormContents}
            setPriorAuthRequirements={setPriorAuthRequirements}
            workflowStep={workflowStep}
            setWorkflowStep={setWorkflowStep}
            pharmacyAuthBuilderFlowEnabled={pharmacyAuthBuilderFlowEnabled}
            hasPharmacy={hasPharmacy}
            atTopOfPage={atTopOfPage}
            draftServiceRequest={draftServiceRequest}
            navigateBack={navigateBack}
            navigateBackContinuations={navigateBackContinuations}
            isFaxAuthBuilderFlow={isFaxAuthBuilderFlow}
            patient={patientData}
          />
          {/* Code tag RECENT_PROVIDERS_8B12CDB1 */}
          <RecentProvidersProvider>
            <div
              style={{
                backgroundColor: isFaxAuthBuilderFlow ? theme.palette.common.white : theme.palette.background.default,
                paddingTop: headerHeightWithNavButtons,
                transition: "padding-top .5s ease",
                /* leave space for footer, (button top/bottom margin/padding + font height + extra spacing */
                paddingBottom: footerOffset,
              }}
            >
              {loadingPatientData || (isInpatientDraft && palCheckLoading) ? (
                <Box display="flex" justifyContent="center" width="100%" marginTop={10}>
                  <CircularProgress size={40} />
                </Box>
              ) : (
                pageContents
              )}
            </div>
            <AppBar classes={footerClasses} component="footer" elevation={0}>
              <Container classes={isFaxAuthBuilderFlow ? undefined : containerClasses}>
                <div>{footerContents}</div>
              </Container>
            </AppBar>
          </RecentProvidersProvider>
          <AssessmentErrorModal
            pharmErrorRedirectModalOpen={pharmErrorRedirectModalOpen}
            singleServiceClinicalAssessmentErrorMessage={
              multiSingleService && authFlowType !== "PHARMACY"
                ? multiSingleServiceClinicalAssessmentErrorMessage
                : singleServiceClinicalAssessmentErrorMessage
            }
            patientId={patientId}
          />
        </div>
      </NavigationContext.Provider>
    </FaxAttachmentContext.Provider>
  );
}

export function AuthBuilderContentWithContext({
  match,
  smartOnFhirMatch,
  location,
  search,
  qmCaseId,
  queueId,
  faxId,
}: Props) {
  return (
    <ClinicalAssessmentContextProvider>
      <ScrubTinsContextProvider>
        <AuthBuilderContent
          match={match}
          smartOnFhirMatch={smartOnFhirMatch}
          location={location}
          search={search}
          qmCaseId={qmCaseId}
          queueId={queueId}
          faxId={faxId}
        />
      </ScrubTinsContextProvider>
    </ClinicalAssessmentContextProvider>
  );
}

export default function AuthBuilderPageWithContext() {
  return <AuthBuilderPage />;
}

export interface AuthBuilderRequestorProps extends RequestorProps {
  requestorFormAuthorized: boolean;
}
