import { SecondaryButton } from "@coherehealth/common";

interface Props {
  disabled?: boolean;
  loading?: boolean;
  navigateBack?: () => void;
}

export default function FaxReferralsBackButton({ disabled, loading, navigateBack }: Props) {
  return (
    <SecondaryButton
      style={{ minWidth: 124, maxWidth: 150 }}
      disabled={disabled}
      loading={loading}
      onClick={navigateBack}
      data-public
    >
      Back
    </SecondaryButton>
  );
}
