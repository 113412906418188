import React, { ForwardedRef, ForwardRefExoticComponent } from "react";
import Card, { CardProps as MuiCardProps } from "@mui/material/Card";
import { makeStyles } from "@material-ui/core";

export interface CardProps extends Omit<MuiCardProps<React.ElementType>, "css"> {
  /** Enable styling on hover. */
  withHoverStyles?: boolean;
  darkBorder?: boolean;
  noBoxShadow?: boolean;
}

// export for storybook
export function CardForProps(props: CardProps) {}

const useStyles = makeStyles((theme) => ({
  root: {
    border: (props: CardProps) => (props.darkBorder ? `1px solid ${theme.palette.divider} !important` : undefined),
    boxShadow: (props: CardProps) => (props.noBoxShadow ? "none !important" : undefined),
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
  },
}));

const CohereCard: ForwardRefExoticComponent<CardProps> = React.forwardRef(
  (props: CardProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, withHoverStyles, darkBorder, noBoxShadow, ...otherProps } = props;
    const classes = useStyles({ darkBorder, noBoxShadow });

    return (
      <Card ref={ref} {...otherProps} className={classes.root}>
        {children}
      </Card>
    );
  }
);

export default CohereCard;
