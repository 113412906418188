import React, { useMemo, useState, useEffect, useRef } from "react";
import CohereTable from "../../components/CohereTable/CohereTable";
import { Body1, Caption } from "../Typography";
import { FeatureFlagCurrentValue, LocalFeatureFlagProps } from "../FeatureFlag";
import { booleanSort, stringSort } from "../../util/SortUtils";
import { formatDateStr } from "../../util/DateUtils";
import { colorsLight, colorsDark } from "../../themes/colors";
import { IconButton, makeStyles, Typography, useTheme } from "@material-ui/core";
import { useIsOverflow } from "../../hooks/useIsOverflow";
import Tooltip from "../Tooltip/Tooltip";
import Chip from "../Chip/Chip";
import SyncIcon from "@mui/icons-material/Sync";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Switch from "../Switch/Switch";
import DownloadIcon from "@mui/icons-material/Download";
import { store } from "../../../src/util/store";

interface FeatureFlagTableProps {
  featureFlagValues: FeatureFlagCurrentValue[];
  localFeatureFlags: LocalFeatureFlagProps | undefined;
  updateLocalFeatureFlags: (update: LocalFeatureFlagProps) => void;
  onFileDownload: (flagName: string) => Promise<void>;
  setEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEditingFlag: React.Dispatch<React.SetStateAction<FeatureFlagCurrentValue | undefined>>;
}

const FeatureFlagTable = ({
  featureFlagValues,
  localFeatureFlags,
  updateLocalFeatureFlags,
  onFileDownload,
  setEditModalOpen,
  setEditingFlag,
}: FeatureFlagTableProps) => {
  const [sortingColumnName, setSortingColumnName] = useState("Date created");
  const [sortingAscending, setSortingAscending] = useState(false);
  const [sortedArray, setSortedArray] = useState<FeatureFlagCurrentValue[]>([]);
  const theme = useTheme();
  const tableColumns = useMemo(
    () => [
      {
        name: "Name",
        width: "650px",
        themedPaddingRight: 0,
        value: (option: FeatureFlagCurrentValue) => (
          <NameAndDescription flagName={option.flagName} description={option.description} />
        ),
        sort: (a: FeatureFlagCurrentValue, b: FeatureFlagCurrentValue, sortingAscending: boolean) =>
          stringSort(a.flagName || "", b.flagName || "", sortingAscending),
      },
      {
        name: "Date created",
        width: "200px",
        themedPaddingRight: 0,
        value: (option: FeatureFlagCurrentValue) => <Date date={option.dateCreated} />,
        sort: (a: FeatureFlagCurrentValue, b: FeatureFlagCurrentValue, sortingAscending: boolean) =>
          stringSort(a.dateCreated || "", b.dateCreated || "", sortingAscending),
      },
      {
        name: "Last modified",
        width: "200px",
        themedPaddingRight: 0,
        value: (option: FeatureFlagCurrentValue) => <Date date={option.lastUpdated} />,
        sort: (a: FeatureFlagCurrentValue, b: FeatureFlagCurrentValue, sortingAscending: boolean) =>
          stringSort(a.lastUpdated || "", b.lastUpdated || "", sortingAscending),
      },
      {
        name: "Status",
        width: "220px",
        themedPaddingRight: 0,
        value: (option: FeatureFlagCurrentValue) => <Status flag={option} localFeatureFlags={localFeatureFlags} />,
        sort: (a: FeatureFlagCurrentValue, b: FeatureFlagCurrentValue, sortingAscending: boolean) =>
          stringSort(a.setting || "", b.setting || "", sortingAscending),
      },
      {
        name: "Local overrides",
        width: "180px",
        themedPaddingRight: 0,
        value: (option: FeatureFlagCurrentValue) => (
          <Override
            flag={option}
            localFeatureFlags={localFeatureFlags}
            updateLocalFeatureFlags={updateLocalFeatureFlags}
          />
        ),
        sort: (a: FeatureFlagCurrentValue, b: FeatureFlagCurrentValue, sortingAscending: boolean) =>
          booleanSort(
            localFeatureFlags?.hasOwnProperty(a.flagName) || false,
            localFeatureFlags?.hasOwnProperty(b.flagName) || false,
            sortingAscending
          ),
      },
      {
        name: "",
        width: "140px",
        themedPaddingRight: 0,
        value: (option: FeatureFlagCurrentValue) => (
          <ActionButtons
            featureFlag={option}
            onFileDownload={onFileDownload}
            setEditModalOpen={setEditModalOpen}
            setEditingFlag={setEditingFlag}
          />
        ),
        unSortable: true,
      },
    ],
    [localFeatureFlags, updateLocalFeatureFlags, onFileDownload, setEditModalOpen, setEditingFlag]
  );

  useEffect(() => {
    const sorted = [...featureFlagValues];
    const column = tableColumns.find((col) => col.name === sortingColumnName);
    if (column && column.sort) {
      sorted.sort((a, b) => column.sort(a, b, sortingAscending));
    }
    setSortedArray(sorted);
  }, [tableColumns, sortingColumnName, sortingAscending, featureFlagValues]);

  return (
    <div style={{ marginBottom: theme.spacing(5) }}>
      <CohereTable
        headerMargin="0px 0px 16px 0px"
        tableColumns={tableColumns}
        data={sortedArray}
        sortingColumnName={sortingColumnName}
        sortingAscending={sortingAscending}
        onSortChange={(columnName, sortAscending) => {
          if (sortingColumnName !== columnName) {
            setSortingColumnName(columnName);
          } else {
            setSortingAscending(sortAscending);
          }
        }}
        hideFinalDivider
        hideFinalDividerMarginTop
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  return {
    nameAndDescription: {
      height: theme.spacing(5),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    description: {
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      ...theme.typography.caption,
      color: isDarkTheme ? colorsDark.font.secondary : colorsLight.font.secondary,
    },
    status: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
    },
    numOrgs: {
      color: isDarkTheme ? colorsDark.font.secondary : colorsLight.font.secondary,
      marginLeft: theme.spacing(1),
    },
    dateText: {
      color: isDarkTheme ? colorsDark.font.secondary : colorsLight.font.secondary,
    },
    actionButtons: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-end",
      width: "100%",
    },
  };
});

interface NameAndDescriptionProps {
  flagName: string;
  description: string | undefined;
}
const NameAndDescription = ({ flagName, description }: NameAndDescriptionProps) => {
  const classes = useStyles();
  const textElementRef = useRef<HTMLInputElement | null>(null);
  const { hoverStatus } = useIsOverflow(textElementRef);

  // dynamically size the tooltip width depending on screen size
  const [tooltipMaxWidth, setTooltipMaxWidth] = useState(460);

  const updateTooltipMaxWidth = () => {
    if (textElementRef.current) {
      const textElementWidth = textElementRef.current.offsetWidth;
      const maxWidth = Math.min(460, textElementWidth);
      setTooltipMaxWidth(maxWidth);
    }
  };

  useEffect(() => {
    updateTooltipMaxWidth();

    window.addEventListener("resize", updateTooltipMaxWidth);
    return () => {
      window.removeEventListener("resize", updateTooltipMaxWidth);
    };
  }, []);

  return (
    <div className={classes.nameAndDescription}>
      <Body1>{flagName}</Body1>
      {description && (
        <Tooltip
          maxWidth={`${tooltipMaxWidth}px`}
          title={description}
          disableHoverListener={!hoverStatus}
          placement="top"
        >
          <Typography className={classes.description} ref={textElementRef}>
            {description}
          </Typography>
        </Tooltip>
      )}
    </div>
  );
};

interface DateProps {
  date: string | undefined;
}
const Date = ({ date }: DateProps) => {
  const classes = useStyles();
  return <Body1 className={classes.dateText}>{date ? formatDateStr(date) : "--"}</Body1>;
};

interface StatusProps {
  flag: FeatureFlagCurrentValue;
  localFeatureFlags: LocalFeatureFlagProps | undefined;
}
const Status = ({ flag, localFeatureFlags }: StatusProps) => {
  const classes = useStyles();
  const flagName = flag.flagName;
  const limitedOrgsCount = flag.limitedOrgsCount;
  const status = flag.setting;
  const iconRef = useRef<SVGSVGElement>(null);

  // update local storage without updateLocalFeatureFlags() from FeatureFlagPage to prevent chain of re-renders -- eliminating animation lag
  // FF page header tracks only which FFs are in local storage, updating localFeatureFlag state in the parent component FeatureFlagPage is unnecessary
  const [booleanSettingValue, setBooleanSettingValue] = useState(flag.booleanSettingValue);

  useEffect(() => {
    if (localFeatureFlags?.hasOwnProperty(flagName)) {
      setBooleanSettingValue(localFeatureFlags[flagName]["booleanSettingValue"]);
    }
  }, [flagName, localFeatureFlags]);

  if (localFeatureFlags?.hasOwnProperty(flagName)) {
    return (
      <div className={classes.status}>
        {booleanSettingValue ? <Chip label="On (local)" type="success" /> : <Chip label="Off (local)" type="error" />}
        <IconButton
          aria-label="Switch local flag status"
          onClick={() => {
            if (iconRef.current) {
              // turn clockwise to turn FF status ON
              // turn counter-clockwise to turn FF status OFF
              const currentRotation = iconRef.current.style.transform;
              iconRef.current.style.transform =
                status === "ON"
                  ? currentRotation === "rotate(180deg)"
                    ? "rotate(0deg)"
                    : "rotate(180deg)"
                  : currentRotation === "rotate(-180deg)"
                  ? "rotate(0deg)"
                  : "rotate(-180deg)";
            }
            const newLocalFeatureFlags = { ...localFeatureFlags };
            newLocalFeatureFlags[flagName] = { booleanSettingValue: !booleanSettingValue };

            setBooleanSettingValue(!booleanSettingValue);
            store.set("localFeatureFlags", newLocalFeatureFlags);
          }}
        >
          <SyncIcon ref={iconRef} style={{ transition: "transform 0.3s ease" }} />
        </IconButton>
      </div>
    );
  } else {
    return status ? (
      status === "ON" ? (
        <Chip label="On" type="success" />
      ) : status === "OFF" ? (
        <Chip label="Off" type="error" />
      ) : (
        <div className={classes.status}>
          <Chip label="Limited" type="warning" />
          <div>
            <Caption className={classes.numOrgs}>{`${limitedOrgsCount} org${
              limitedOrgsCount === 1 ? "" : "s"
            }`}</Caption>
          </div>
        </div>
      )
    ) : (
      <Caption>--</Caption>
    );
  }
};

interface OverrideProps {
  flag: FeatureFlagCurrentValue;
  localFeatureFlags: LocalFeatureFlagProps | undefined;
  updateLocalFeatureFlags: (update: LocalFeatureFlagProps) => void;
}
const Override = ({ flag, localFeatureFlags, updateLocalFeatureFlags }: OverrideProps) => {
  const overridden = localFeatureFlags?.hasOwnProperty(flag.flagName) || false;
  const currLocalFeatureFlags = { ...localFeatureFlags };

  return (
    <Switch
      aria-label="Desync flag from server"
      style={{ marginLeft: "12px" }}
      checked={overridden}
      onChange={() => {
        if (overridden) {
          delete currLocalFeatureFlags[flag.flagName];
        } else {
          currLocalFeatureFlags[flag.flagName] = { booleanSettingValue: !flag.booleanSettingValue };
        }
        updateLocalFeatureFlags(currLocalFeatureFlags);
      }}
    />
  );
};

interface ActionButtonProps {
  featureFlag: FeatureFlagCurrentValue;
  onFileDownload: (flagName: string) => Promise<void>;
  setEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEditingFlag: React.Dispatch<React.SetStateAction<FeatureFlagCurrentValue | undefined>>;
}
const ActionButtons = ({ featureFlag, onFileDownload, setEditModalOpen, setEditingFlag }: ActionButtonProps) => {
  const classes = useStyles();
  return (
    <div className={classes.actionButtons}>
      {featureFlag.setting === "LIMITED" && (
        <IconButton
          aria-label="Download CSV file"
          onClick={() => {
            onFileDownload(featureFlag.flagName);
          }}
        >
          <DownloadIcon />
        </IconButton>
      )}
      <IconButton
        aria-label="Open flag edit modal"
        onClick={() => {
          setEditModalOpen(true);
          setEditingFlag(featureFlag);
        }}
      >
        <ModeEditIcon />
      </IconButton>
    </div>
  );
};

export default FeatureFlagTable;
