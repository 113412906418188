import { SmartOnFhirContext } from "components/SmartOnFhir/SmartOnFhirSecurityProvider";
import { useContext, useMemo } from "react";
import { useAuth } from "./useAuth";

export const useBearerToken = function () {
  const smartClient = useContext(SmartOnFhirContext);
  const { oktaAuth } = useAuth();

  const smartToken = smartClient?.state?.tokenResponse?.access_token;
  const bearerToken = useMemo(() => smartToken || oktaAuth.getAccessToken(), [oktaAuth, smartToken]);

  return `Bearer ${bearerToken}`;
};
