import React, { Dispatch, PropsWithChildren, useRef, useEffect, useState, useCallback, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import compact from "lodash/compact";
import routes from "../../../routes";
import {
  Body1,
  Body2,
  Caption,
  colorsDark,
  colorsLight,
  H5,
  Tooltip,
  formatDateStr,
  formatDateStrWithCustomFormat,
  SummaryCardMedicalIcon,
  SummaryCardDrugIcon,
  extractClinicalServicesText,
} from "@coherehealth/common";
import LaunchIcon from "@material-ui/icons/Launch";
import { Link } from "react-router-dom";
import {
  ServiceRequestCreatePayload,
  ServiceRequestSearchResponse,
  UserResponse,
} from "@coherehealth/core-platform-api";
import { generatePath } from "react-router";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import AuthVisibilityMessage from "common/AuthVisibilityMessage";
import { checkIsColleague } from "common/AuthVisibilityMessage";
import ImpressionTracking from "common/ImpressionTracking";
import { getCreatedByName } from "util/serviceRequest";
import { PotentialDuplicateContext } from "./PotentialDuplicateContextProvider";
import { ServiceRequestFormContent } from "../../../common/SharedServiceRequestFormComponents";
import { ServiceRequestStatusDisplay } from "components/ServiceRequestStatusDisplay/StatusBanner/ServiceRequestStatusDisplay";

export interface Props {
  serviceRequest: ServiceRequestSearchResponse;
  // TO DO HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onEdit?: Function;
  hover: boolean;
  setHover: Dispatch<boolean>;
  // TO DO HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onDeleteDraft?: Function;
  isExistingAuthorization?: boolean;
  user?: UserResponse | null;
  searchForPotentialDuplicates?: (srPayload: ServiceRequestCreatePayload) => Promise<boolean>;
  workflowId?: string;
  serviceRequestFormContents?: ServiceRequestFormContent[];
  getPotentialDuplicatesLoading?: boolean;
  onPotentialDuplicatesClose?: () => void;
}

interface ReviewServiceRequest {
  id: string;
  createdBy: string;
  authStatus: string;
}

const M_DASH = "\u2014";

export default function DuplicateServiceRequestSummary({
  serviceRequest,
  onEdit,
  hover,
  setHover,
  onDeleteDraft,
  isExistingAuthorization,
  user,
  searchForPotentialDuplicates,
  workflowId,
  serviceRequestFormContents,
  getPotentialDuplicatesLoading,
  onPotentialDuplicatesClose,
}: Props) {
  const textElementRef = useRef<HTMLInputElement | null>(null);
  const lineHeight = 18;
  const webkitLineClamp = 3;
  const heightOverride = lineHeight * webkitLineClamp;

  // set custom height based on max as offset height is always returning a value less than the scroll height
  const { hoverStatus } = useIsOverflow(textElementRef, heightOverride);
  const {
    intakeTimestamp,
    tatStartTimestamp,
    patient,
    procedureCodes,
    startDate,
    endDate,
    type,
    prescribedDrug,
    dateCreated,
    orderingProvider,
    authorization,
  } = serviceRequest;

  const serviceDetail = extractClinicalServicesText(
    serviceRequest?.clinicalServices || [],
    serviceRequest?.authCategory,
    authorization?.authSubcategory,
    serviceRequest?.palCategory
  );

  const theme = useTheme();
  const serviceDatesTitle = compact([startDate, endDate]).length <= 1 ? "Date of service" : "Dates of service";
  const formattedServiceDates = compact([startDate, endDate]).map(formatDateStr).join(` ${M_DASH} `) || "none";

  const procecureCodesTitle = procedureCodes && procedureCodes.length <= 1 ? "Procedure code" : "Procedure codes";
  const procedureCodesText = procedureCodes?.map((px) => px.code).join(", ") || "--";

  const requestFrom = () => {
    if (user?.id === serviceRequest.createdBy) {
      return "you";
    } else if (user && checkIsColleague(serviceRequest, user)) {
      return `${getCreatedByName(serviceRequest)} (internal)`;
    } else {
      return `${getCreatedByName(serviceRequest)} (external)`;
    }
  };

  // eslint-disable-next-line cohere-react/no-mui-styled-import
  const H5WithTooltip = styled(Typography)(({ theme }) => ({
    maxWidth: "100%",
    maxHeight: "100%",
    overflow: "hidden",
    lineHeight: `${lineHeight}px`,
    display: "-webkit-box",
    WebkitLineClamp: webkitLineClamp,
    WebkitBoxOrient: "vertical",
    "&:hover": {
      background: hoverStatus ? "rgba(0, 0, 0, 0.04)" : undefined,
      "background-blend-mode": hoverStatus ? "multiply" : undefined,
    },
  }));

  const submissionDate =
    type === "Pharmacy"
      ? formatDateStrWithCustomFormat(dateCreated, "M/d/yyyy h:mm a")
      : intakeTimestamp
      ? formatDateStrWithCustomFormat(tatStartTimestamp ?? intakeTimestamp, "M/d/yyyy h:mm a")
      : M_DASH;
  const canCreateContinuation =
    (isExistingAuthorization && !serviceRequest.clinicalService?.hasUnitsOnPxChanged) || false;

  const showSubmissionDate = submissionDate ? submissionDate : "--";
  const providerName = orderingProvider ? orderingProvider.name : "--";
  const providerNPI = (orderingProvider as { npi?: string | undefined })?.npi || "--";
  const orderProvider = `${providerName} / NPI - ${providerNPI}`;

  const serviceDetailFields =
    type === "Pharmacy" ? (
      <>
        <Grid item xs={3} style={{ marginRight: 24 }} data-public>
          <LightCaption>Ordering provider</LightCaption>
          <WrappingBody>{orderProvider}</WrappingBody>
        </Grid>
        <Grid item style={{ marginRight: 24 }} xs={5}>
          <LightCaption>Submission date</LightCaption>
          <WrappingBody>{showSubmissionDate}</WrappingBody>
        </Grid>
      </>
    ) : (
      <>
        <Grid item xs={3} data-public>
          <LightCaption>Ordering provider</LightCaption>
          <WrappingBody>{orderProvider}</WrappingBody>
        </Grid>
        <Grid item xs={3} data-public>
          <LightCaption>{procecureCodesTitle}</LightCaption>
          <WrappingBody>{procedureCodesText}</WrappingBody>
        </Grid>
        <Grid item xs={3} data-public>
          <LightCaption>Submission date</LightCaption>
          <WrappingBody>{submissionDate}</WrappingBody>
        </Grid>
        <Grid item xs={3} data-public>
          <LightCaption>{serviceDatesTitle}</LightCaption>
          <WrappingBody>{formattedServiceDates}</WrappingBody>
        </Grid>
      </>
    );

  const summaryCardIconAndTitle =
    type === "Pharmacy" ? (
      <Box display="flex" marginRight={3}>
        <SummaryCardDrugIcon
          style={{
            marginRight: theme.spacing(1),
            height: theme.spacing(4),
            width: theme.spacing(4),
          }}
        />
        <WrappingBody>
          <H5>{prescribedDrug?.drugDescription || M_DASH}</H5>
        </WrappingBody>
      </Box>
    ) : (
      <Box display="flex" marginRight={3}>
        <SummaryCardMedicalIcon
          style={{
            marginRight: theme.spacing(1),
            minWidth: "30px",
            height: theme.spacing(4),
            width: theme.spacing(4),
          }}
        />
        <WrappingBody>
          <Tooltip
            title={serviceDetail}
            placement="top"
            arrow
            disableHoverListener={!hoverStatus}
            data-testid="cs-tooltip"
            style={{ zIndex: 9999 }}
          >
            <div>
              <H5WithTooltip variant="h5" ref={textElementRef}>
                {serviceDetail}
              </H5WithTooltip>
            </div>
          </Tooltip>
        </WrappingBody>
      </Box>
    );

  const serviceRequestIsRestricted = serviceRequest.isRestricted;
  const serviceRequestIsViewable = !serviceRequest?.isRestricted && serviceRequest?.hasRestrictedCodes;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between">
          {summaryCardIconAndTitle}
          <ServiceRequestNavLink
            patientId={patient?.id}
            reviewServiceRequest={serviceRequest as ReviewServiceRequest}
            data-testid={`summary-card-link-top-${serviceRequest.id}`}
            userId={user?.id}
          >
            <>
              <LaunchIcon color="primary" style={{ marginRight: 8 }} />
              <Body2 color="primary">View details</Body2>
            </>
          </ServiceRequestNavLink>
        </Box>
      </Grid>
      <Grid item xs={12} container spacing={3}>
        {!serviceRequestIsRestricted && serviceDetailFields}
      </Grid>
      <Grid item xs={12}>
        <ServiceRequestStatusDisplay
          serviceRequest={serviceRequest}
          onEdit={onEdit}
          setHover={setHover}
          onDeleteDraft={onDeleteDraft}
          canCreateContinuation={canCreateContinuation}
          searchForPotentialDuplicates={searchForPotentialDuplicates}
          workflowId={workflowId}
          serviceRequestFormContents={serviceRequestFormContents}
          getPotentialDuplicatesLoading={getPotentialDuplicatesLoading}
          actionConfiguration={{
            printActionConfiguration: {
              disabled: Boolean(serviceRequestIsRestricted),
              hidden: Boolean(!hover || serviceRequestIsRestricted),
            },
            startContinuationActionConfiguration: {
              disabled: Boolean(serviceRequestIsRestricted),
              hidden: Boolean(!hover || serviceRequestIsRestricted),
            },
            draftContinueActionConfiguration: {
              disabled: Boolean(serviceRequestIsRestricted),
              hidden: Boolean(serviceRequestIsRestricted),
            },
            draftDeleteActionConfiguration: {
              disabled: Boolean(serviceRequestIsRestricted),
              hidden: Boolean(serviceRequestIsRestricted),
            },
            editStatusActionConfiguration: {
              disabled: Boolean(serviceRequestIsRestricted),
              hidden: Boolean(!hover || serviceRequestIsRestricted),
            },
          }}
          onPotentialDuplicatesClose={onPotentialDuplicatesClose}
        />
      </Grid>
      <Grid item xs={12}>
        <Body1 style={{ color: "#5E6572" }}>{`Requested by ${requestFrom()}`}</Body1>
      </Grid>
      <AuthVisibilityMessage
        serviceRequest={serviceRequest}
        user={user}
        isRestricted={serviceRequestIsRestricted}
        isViewable={serviceRequestIsViewable}
        visibilityToggleStatus={serviceRequest?.restrictedDataSettings?.allowEditForAuthorizationParticipants}
      />
    </Grid>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const LightCaption = styled(Caption)(({ theme }) => ({
  color: theme.palette.type === "dark" ? colorsDark.font.secondary : colorsLight.font.light,
  margin: "2px 0px",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const WrappingBody = styled(Body1)(({ theme }) => ({
  maxWidth: "100%",
  overflow: "hidden",
  whiteSpace: "break-spaces",
  marginTop: theme.spacing(1),
  lineHeight: "18px",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ServiceRequestNavLink = styled(
  ({
    children,
    patientId,
    reviewServiceRequest,
    style,
    userId,
    ...props
  }: PropsWithChildren<{
    patientId?: string;
    reviewServiceRequest: ReviewServiceRequest;
    style?: React.CSSProperties;
    userId: string | undefined;
  }>) => {
    const workflowId = useContext(PotentialDuplicateContext);
    // add to the path so that we can reference what workflow it was reached from
    const linkUrl = patientId
      ? `${generatePath(routes.PATIENT_SUMMARY, {
          patientId,
        })}?reviewServiceRequestId=${reviewServiceRequest.id}${`&duplicate=${workflowId}`}`
      : "#";
    return (
      <ImpressionTracking
        event="DUPLICATE_MODAL_OPEN"
        stage="DUPLICATE_SUBMISSION_REVIEW"
        activityContext={{
          serviceRequestId: reviewServiceRequest.id,
          cohereAuthId: userId,
          workflowId: workflowId || undefined,
          duplicateAuthInfo: {
            createdBy: reviewServiceRequest.createdBy,
            authStatus: reviewServiceRequest.authStatus,
          },
        }}
      >
        <Link
          to={linkUrl}
          {...props}
          target="_blank"
          style={{ display: "flex", alignItems: "center", minWidth: "116px", textDecoration: "none", ...style }}
        >
          {children}
        </Link>
      </ImpressionTracking>
    );
  }
)({
  color: "inherit",
  textDecoration: "none",
  "&:focus, &:hover, &:visited, &:link, &:active": {
    textDecoration: "none",
  },
});

function useIsOverflow(
  textElementRef: React.MutableRefObject<HTMLInputElement | null>,
  heightOverride: number | undefined
) {
  const [hoverStatus, setHover] = useState<boolean>(false);

  const compareSize = useCallback(() => {
    const element = textElementRef.current;
    const compare = element
      ? element.offsetWidth < element.scrollWidth || (heightOverride || element.offsetHeight) < element.scrollHeight
      : false;

    setHover(compare);
  }, [textElementRef, heightOverride]);

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
  }, [compareSize]);

  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize);
    },
    [compareSize]
  );

  return {
    hoverStatus,
  };
}
