import { ProcedureCode, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { useTheme } from "@mui/material";
import getCodesInfo, { awaitingTerminalStatus } from "./CodesInfo";
import { authorizeBaseUrl, Body3, formatDateStr } from "@coherehealth/common";
import ApprovalBadge from "./ApprovalBadge";
import { H6 } from "@coherehealth/design-system";
import { aggregateStayDateByCommonFields } from "../PatientStay/PatientStays";
import { compact } from "lodash";
import { PatientStayDateRange } from "common/SharedServiceRequestFormComponents";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { isVoidedContinuationWithoutStayDates } from "util/serviceRequest";

interface ExpandedContentProps {
  sr: ServiceRequestResponse;
  priorSr?: ServiceRequestResponse;
  nextSr?: ServiceRequestResponse;
  clinicalReviewView?: boolean;
  isFacilityBased?: boolean;
  limitVoidsAfterApproval?: boolean;
  includePatientStayDatesOnPlannedAdmission?: boolean;
  classes: ClassNameMap;
}

export const ExpandedServiceRequestContent = ({
  sr,
  isFacilityBased,
  includePatientStayDatesOnPlannedAdmission,
  limitVoidsAfterApproval = false,
  clinicalReviewView = false,
  priorSr,
  nextSr,
  classes,
}: ExpandedContentProps) => {
  const theme = useTheme();
  const isInitial = sr.requestType === "INITIAL";
  const isOnPx = sr.clinicalServices?.some((obj) => obj?.isUnitsOnPx === true);

  let unitsOnPxCodes: ProcedureCode[] =
    (sr?.semanticProcedureCodes || []).map((semanticProcedureCode) => {
      if (sr.authStatus === "APPROVED" && !semanticProcedureCode.approvedUnits) {
        semanticProcedureCode.approvedUnits = semanticProcedureCode.units;
      }
      return semanticProcedureCode;
    }) || [];

  if (sr?.encounterType) {
    unitsOnPxCodes = unitsOnPxCodes.filter((pxCode) =>
      sr?.clinicalServices?.find((cs) => cs?.id === pxCode.groupId && cs?.isUnitsOnPx === true)
    );
  }

  let unitsOnservicePxCode: ProcedureCode[] =
    (sr?.semanticProcedureCodes || []).map((semanticProcedureCode) => {
      if (sr.authStatus === "APPROVED" && !semanticProcedureCode.approvedUnits) {
        semanticProcedureCode.approvedUnits = semanticProcedureCode.units;
      }
      return semanticProcedureCode;
    }) || [];

  if (sr?.encounterType) {
    unitsOnservicePxCode = unitsOnservicePxCode.filter((pxCode) =>
      sr?.clinicalServices?.find((cs) => cs?.id === pxCode.groupId && cs?.isUnitsOnPx === false)
    );
  }

  const codesInfo = getCodesInfo({
    priorCodes: priorSr?.procedureCodes || [],
    isOnPx: isOnPx || false,
    authStatus: sr.authStatus,
    unitsOnPxCodes: unitsOnPxCodes,
    unitsOnservicePxCode: unitsOnservicePxCode,
  });
  return (
    <>
      <div
        className={`${classes.row} ${clinicalReviewView === true ? classes.expandedRowClickable : classes.expandedRow}`}
        onClick={() => {
          const clincialReviewUrl = `${authorizeBaseUrl()}/view_only_review?serviceRequestId=${
            sr.id
          }&origin=expandedSRCard`;
          clinicalReviewView && window.open(clincialReviewUrl);
        }}
      >
        <H6 sx={{ color: theme.palette.text.primary }} data-public>
          {isInitial ? "Initial" : "Continuation"}
        </H6>
        <ApprovalBadge
          authStatus={
            limitVoidsAfterApproval && sr.authStatus === "APPROVED" && sr.isSrNoLongerNeeded
              ? "NO_LONGER_NEEDED"
              : sr.authStatus
          }
          cohereId={sr.cohereId}
        />
      </div>
      <div className={classes.row} style={{ marginTop: theme.spacing(1.5) }}>
        <Body3 style={{ color: theme.palette.text.primary }} data-public>
          {isFacilityBased
            ? InpatientDetails(sr, priorSr, nextSr, codesInfo, includePatientStayDatesOnPlannedAdmission || false)
            : OutpatientDetails(isOnPx, sr, isInitial, codesInfo, unitsOnPxCodes, unitsOnservicePxCode)}
        </Body3>
      </div>
    </>
  );
};

const VisitsInfo = ({ sr, isInitial }: { sr: ServiceRequestResponse; isInitial: boolean }) => {
  const isUnapprovedUnits = (sr.units || 0) > (sr.approvedUnits || 0);
  if (sr.units) {
    return (
      <>
        Number of visits: {isInitial ? "" : "Additional "}
        <>
          {isUnapprovedUnits && sr.approvedUnits && sr.approvedUnits > 0 && !awaitingTerminalStatus(sr.authStatus)
            ? `${sr.units} requested, ${sr.approvedUnits} approved `
            : isUnapprovedUnits || awaitingTerminalStatus(sr.authStatus)
            ? `${sr.units} requested `
            : `${sr.approvedUnits} approved `}
          &bull;{" "}
        </>
      </>
    );
  } else {
    return <></>;
  }
};

const InpatientDetails = (
  sr: ServiceRequestResponse,
  priorSr: ServiceRequestResponse | undefined,
  nextSr: ServiceRequestResponse | undefined,
  codesInfo: string,
  includePatientStayDatesOnPlannedAdmission: boolean
) => {
  if (sr.patientStatus === "NOT_YET_ADMITTED") {
    const previousExpectedDateString = formatDateStr(priorSr?.expectedAdmissionDate);
    const expectedAdmissionDateString = formatDateStr(sr.expectedAdmissionDate);

    /* Should show expected admission date in the following scenarios:
     * - No prior SR / Current SR: has expectedAdmissionDate
     * - Prior SR was: previousExpectedDate / Current SR: expectedAdmissionDate - previousExpectedDate!== expectedAdmissionDate
     */
    const shouldShowExpectedAdmission =
      (!priorSr?.expectedAdmissionDate && !!expectedAdmissionDateString && expectedAdmissionDateString.length > 0) ||
      (!!previousExpectedDateString &&
        !!expectedAdmissionDateString &&
        expectedAdmissionDateString.length > 0 &&
        previousExpectedDateString !== expectedAdmissionDateString);

    const patientStayDateRange = sr?.patientStayDates ? aggregateStayDateByCommonFields(sr.patientStayDates) : [];
    const patientStayDateRangeString = getStayDateRangeString(patientStayDateRange);

    return (
      <>
        {[
          shouldShowExpectedAdmission ? `Expected admission date: ${expectedAdmissionDateString}` : "",
          includePatientStayDatesOnPlannedAdmission &&
          patientStayDateRange !== undefined &&
          patientStayDateRange.length > 0
            ? `Review dates: ${patientStayDateRangeString}`
            : "",
          codesInfo,
          `Expedited: ${sr.urgency?.isExpedited ? "Yes" : "No"}`,
        ]
          .filter((item) => item !== "")
          .join(" \u2022 ")}
      </>
    );
  } else {
    const previousAdmissionDateString = formatDateStr(priorSr?.admissionDate);
    const admissionDateString = formatDateStr(sr.admissionDate);
    const previousDischargeDateString = formatDateStr(priorSr?.dischargeDate);
    const discharageDateString = formatDateStr(sr.dischargeDate);
    const nextDischargeDateString = formatDateStr(nextSr?.dischargeDate);

    const patientStayDateRange = sr?.patientStayDates ? aggregateStayDateByCommonFields(sr.patientStayDates) : [];
    const patientStayDateRangeString = getStayDateRangeString(patientStayDateRange);

    /* Should show admission date in the following scenarios:
     * - Prior SR was : patientStatus=NOT_YET_ADMITTED / Current SR: has admissionDate
     * - Current SR is: requestType=INITIAL, has admissionDate
     * - Prior SR was: previousAdmissionDate / Current SR: newAdmissionDate - previousAdmissionDate!== newAdmissionDate
     * - Prior SR was : authStatus=(DENIED", "DISMISSED","VOIDED","WITHDRAWN) / Current SR: has admissionDate
     */
    const shouldShowAdmissionDate =
      ((!priorSr || priorSr.patientStatus === "NOT_YET_ADMITTED") &&
        !!admissionDateString &&
        admissionDateString.length > 0) ||
      (sr.requestType === "INITIAL" && !!admissionDateString && admissionDateString.length > 0) ||
      (!!previousAdmissionDateString &&
        previousAdmissionDateString.length > 0 &&
        !!admissionDateString &&
        admissionDateString.length > 0 &&
        previousAdmissionDateString !== admissionDateString) ||
      (["DENIED", "DISMISSED", "VOIDED", "WITHDRAWN"].includes(priorSr?.authStatus ?? "") &&
        !!admissionDateString &&
        admissionDateString.length > 0);

    /* Should show discharge date in the following scenarios:
     * - Prior SR was :  patientStatus!==DISCHARGED / Current SR: has patientStatus===DISCHARGED
     * - Prior SR was: previousDischargeDate / Current SR: newDischargeDate - previousDischargeDate!== newDischargeDate
     * - Prior SR was : authStatus=(DENIED", "DISMISSED","VOIDED","WITHDRAWN) / Current SR: has dischargeDate
     */
    const shouldShowDischargeDateFromCurrentSr =
      ((!priorSr || priorSr.patientStatus !== "DISCHARGED") &&
        sr.patientStatus === "DISCHARGED" &&
        !!discharageDateString &&
        discharageDateString.length > 0) ||
      (!!previousDischargeDateString &&
        previousDischargeDateString.length > 0 &&
        !!discharageDateString &&
        discharageDateString.length > 0 &&
        previousDischargeDateString !== discharageDateString) ||
      (["DENIED", "DISMISSED", "VOIDED", "WITHDRAWN"].includes(priorSr?.authStatus ?? "") &&
        !!discharageDateString &&
        discharageDateString.length > 0);

    /*
     * We hide voided Continuations without stay dates, and show their
     * discharge date on the previous request.
     */
    const shouldShowDischargeDateFromNextSr = isVoidedContinuationWithoutStayDates(nextSr) && !!nextDischargeDateString;

    let dischargeDateStringToDisplay = "";
    if (shouldShowDischargeDateFromCurrentSr) {
      dischargeDateStringToDisplay = discharageDateString;
    } else if (shouldShowDischargeDateFromNextSr) {
      dischargeDateStringToDisplay = nextDischargeDateString;
    }

    return (
      <>
        {[
          shouldShowAdmissionDate ? `Admission date: ${admissionDateString}` : "",
          dischargeDateStringToDisplay ? `Discharge date: ${dischargeDateStringToDisplay}` : "",
          patientStayDateRange !== undefined && patientStayDateRange.length > 0
            ? `Review dates: ${patientStayDateRangeString}`
            : "",
          codesInfo,
        ]
          .filter((item) => item !== "")
          .join(" \u2022 ")}
      </>
    );
  }
};

const OutpatientDetails = (
  isOnPx: boolean | undefined,
  sr: ServiceRequestResponse,
  isInitial: boolean,
  codesInfo: string,
  unitsOnPxCodes?: ProcedureCode[],
  unitsOnservicePxCode?: ProcedureCode[]
) => {
  const serviceDatesTitle = compact([sr.startDate, sr.endDate]).length <= 1 ? "Date of service" : "Dates of service";
  const startDateString = formatDateStr(sr.startDate);
  const endDateString = formatDateStr(sr.endDate);
  //sets to true if SR has both units on service and units on px codes
  const checkSrIsonUnitsOnPxAndUnitsOnService =
    sr.encounterType === "OUTPATIENT" &&
    unitsOnPxCodes &&
    unitsOnPxCodes.length > 0 &&
    unitsOnservicePxCode &&
    unitsOnservicePxCode.length > 0;
  return (
    <>
      {checkSrIsonUnitsOnPxAndUnitsOnService || !isOnPx ? <VisitsInfo sr={sr} isInitial={isInitial}></VisitsInfo> : ""}
      {[
        `${serviceDatesTitle}: ${startDateString}${
          endDateString !== undefined && endDateString.length > 0 ? " - " : ""
        }${endDateString}`,
        codesInfo,
        `Expedited: ${sr.urgency?.isExpedited ? "Yes" : "No"}`,
      ]
        .filter((item) => item !== "")
        .join(" \u2022 ")}
    </>
  );
};

function getStayDateRangeString(patientStayDateRange: PatientStayDateRange[]) {
  const stayDateRangeLength = patientStayDateRange.length;

  const rangeStartDate =
    patientStayDateRange !== undefined && stayDateRangeLength > 0 && patientStayDateRange[0].rangeStartDate !== null
      ? patientStayDateRange[0].rangeStartDate
      : undefined;
  const rangeEndDate =
    patientStayDateRange !== undefined &&
    stayDateRangeLength > 0 &&
    patientStayDateRange[stayDateRangeLength - 1].rangeEndDate !== null
      ? patientStayDateRange[stayDateRangeLength - 1].rangeEndDate
      : undefined;
  const dates =
    rangeStartDate !== rangeEndDate
      ? `${formatDateStr(rangeStartDate ?? undefined)} - ${formatDateStr(rangeEndDate ?? undefined)}`
      : `${formatDateStr(rangeStartDate ?? undefined)}`;

  return dates;
}
