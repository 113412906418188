import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MUIAvatar, { AvatarTypeMap } from "@material-ui/core/Avatar";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

const styles = (theme: any) => ({
  root: {
    color: theme.palette.primary.main,
    backgroundColor: "#E2F2F8",
    ...theme.typography.body2,
  },
});

const Avatar = React.forwardRef((props: any, ref) => {
  const { classes, children, ...otherProps } = props;

  return (
    <MUIAvatar ref={ref} classes={classes} {...otherProps}>
      {children}
    </MUIAvatar>
  );
});
Avatar.displayName = "Avatar";

export default withStyles(styles)(Avatar) as OverridableComponent<AvatarTypeMap>;
