import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState, useContext } from "react";

import {
  Body1,
  H2,
  PrimaryButton,
  SecondaryButton,
  PdfViewer,
  colorsLight,
  H4,
  queueMgmtBaseUrl,
  Checkbox,
  formatDateToISODate,
  parseDateFromISOString,
} from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import {
  Box,
  CircularProgress,
  createStyles,
  Dialog,
  Divider,
  IconButton,
  makeStyles,
  Slide,
  styled,
  Theme,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import DenialLetterReadyForDelivery from "components/images/DenialLetterReadyForDelivery.svg";
import CloseIcon from "@material-ui/icons/Close";
import { TransitionProps } from "@material-ui/core/transitions";
import React from "react";
import { CaseOutcome, useUpdateServiceCase, ServiceCase, CompleteServiceCasePathParams } from "@coherehealth/qm-api";
import { MutateMethod } from "restful-react";
import config from "api/config";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
import {
  NextReviewDateSelect,
  handlePickersModalClick,
} from "components/ServiceRequest/ReviewSection/common/NextReviewDateSelect";
import {
  AuthorizationResponse,
  ServiceRequestResponse,
  useGetLatestReviewLeadingToDenial,
  PreferredLabelFeedback,
  usePostPreferredLabelFeedback,
  ServiceRequestCommandPayload,
  useAddServiceRequestOnAuthorization,
  ContinuationConfigurationResponse,
} from "@coherehealth/core-platform-api";
import { useAuthorizationFetch } from "components/ClinicalReview/reviewUtils/utils";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";
import { useGetAutomatedDenialLetterConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import { UserContext } from "UserContext";
import { useLocation, useNavigate } from "react-router";
import { useUpdateAuthorization } from "components/ClinicalReview/reviewUtils/utils";
import InpatientNextReviewDateCollection from "../components/ServiceRequest/ReviewSection/AuditReview/InpatientNextReviewDateCollection";
import { patientNotYetAdmittedForInpatient } from "../util/serviceRequest";
import { format } from "date-fns";
import { redirectToPatientSummaryFromReview, routeToPatientSummaryFromReview } from "util/routeUtils/routeUtils";

interface Props {
  denialPreviewModalOpen: boolean;
  setDenialPreviewModalOpen: Dispatch<SetStateAction<boolean>>;
  onFinalizeDenial: (
    event: any,
    authorization: Partial<AuthorizationResponse>,
    reviewerCreateContinuationAcknowledgement?: boolean
  ) => Promise<void>;
  finalizingDenial: boolean;
  determinationLetterError?: boolean;
  denialLetterPreviewResponse?: Response;
  serviceRequest: ServiceRequestResponse;
  reviewOutcome: string;
  reviewId: string;
  incorrectReviewForDenialLetter?: boolean;
  setIncorrectReviewForDenialLetterModal?: Dispatch<SetStateAction<boolean>>;
  disableDueToWrongReview?: boolean;
  setDisableDueToWrongReview?: Dispatch<SetStateAction<boolean>>;
  updateServiceCase?: MutateMethod<ServiceCase, CaseOutcome, void, CompleteServiceCasePathParams>;
  caseId: string | null;
  preferredLabelFeedback?: PreferredLabelFeedback[];
  populateProcedureCodeDescription?: boolean;
  continuationConfiguration?: ContinuationConfigurationResponse | null;
}

const DenialPreviewModal = ({
  denialPreviewModalOpen,
  setDenialPreviewModalOpen,
  onFinalizeDenial,
  finalizingDenial,
  determinationLetterError,
  denialLetterPreviewResponse,
  serviceRequest,
  reviewOutcome,
  reviewId,
  incorrectReviewForDenialLetter,
  setIncorrectReviewForDenialLetterModal,
  disableDueToWrongReview,
  setDisableDueToWrongReview,
  updateServiceCase,
  caseId,
  preferredLabelFeedback,
  populateProcedureCodeDescription = false,
  continuationConfiguration,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [nrdAcknowledged, setNrdAcknowledged] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { authorization, authorizationFetchLoading } = useAuthorizationFetch(serviceRequest);
  const { updateAuthorization } = useUpdateAuthorization({ id: serviceRequest?.authorization?.id });
  const [fileUrl, setFileUrl] = useState<string>();
  const [hasError, setHasError] = useState<boolean>(false);
  const [readyToSend, setReadyToSend] = useState<boolean>(false);
  const [nextReviewDate, setNextReviewDate] = useState<Date | null>(null);
  const currentPage = useRef<number>(0);
  const { healthPlanName, encounterType, requestTiming, delegatedVendor } = serviceRequest;
  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName,
    encounterType,
    requestTiming,
    skipRequestTimingCheck: true,
    delegatedVendorName: delegatedVendor,
  });
  const isPatientNotYetAdmitted = patientNotYetAdmittedForInpatient(serviceRequest);
  const fetchAttachment = useCallback(async () => {
    setHasError(false);

    if (denialLetterPreviewResponse?.ok) {
      const blob = await denialLetterPreviewResponse.blob();
      const url = window.URL.createObjectURL(blob);
      setFileUrl(url);
    } else {
      setHasError(true);
      enqueueSnackbar("Error downloading preview letter", { variant: "error" });
    }
  }, [denialLetterPreviewResponse, enqueueSnackbar]);

  useEffect(() => {
    setHasError(!!determinationLetterError);
  }, [determinationLetterError]);

  useEffect(() => {
    if (authorization?.nextReviewDate) {
      setNextReviewDate(parseDateFromISOString(authorization?.nextReviewDate));
    }
  }, [authorization?.nextReviewDate]);

  useEffect(() => {
    if (denialPreviewModalOpen && denialLetterPreviewResponse) {
      fetchAttachment();
    } else {
      setFileUrl("");
    }
  }, [denialPreviewModalOpen, denialLetterPreviewResponse, fetchAttachment]);

  const classes = useStyles();

  const { mutate: updateServiceCaseById } = useUpdateServiceCase({
    id: caseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
  });
  const { data: letterConfig } = useGetAutomatedDenialLetterConfigurationByPayer(serviceRequest?.healthPlanName || "");

  const isNrdShown =
    facilityBasedFeatureEnabled &&
    ["CURRENTLY_ADMITTED", "NOT_YET_ADMITTED"].includes(serviceRequest.patientStatus as string);
  const isNrdRequired = isNrdShown && serviceRequest.patientStatus === "CURRENTLY_ADMITTED";
  const shouldCreateContinuation = isNrdShown && (isNrdRequired || nrdAcknowledged);
  const autoCreateContinuation = continuationConfiguration?.autoCreateFinalDecisionContinuations ?? false;

  const missingNextReviewDate = isNrdRequired && !nextReviewDate;
  const [saveDeterminationLetterLoading, setSaveDeterminationLetterLoading] = useState<boolean>(false);
  const disableSaveOrSendButton =
    finalizingDenial ||
    missingNextReviewDate ||
    disableDueToWrongReview ||
    authorizationFetchLoading ||
    (isNrdRequired && !nextReviewDate) ||
    saveDeterminationLetterLoading ||
    (nrdAcknowledged && nextReviewDate === null);

  // Retrieve the latest review leading to denial for this Service Request
  const { refetch: getLatestReviewLeadingToDenial } = useGetLatestReviewLeadingToDenial({
    id: serviceRequest?.id || "",
    lazy: true,
  });

  const { mutate: postPreferredLabelFeedback } = usePostPreferredLabelFeedback({});

  const { getUser } = useContext(UserContext);

  const redirectBackToOrigin = () => {
    const search = new URLSearchParams(location.search);
    const urlCaseId = search.get("caseId");
    const targetURL = urlCaseId
      ? `${queueMgmtBaseUrl()}/case_complete/${urlCaseId}`
      : routeToPatientSummaryFromReview({
          serviceRequestId: serviceRequest?.id,
          patientId: serviceRequest?.patient?.id,
        });
    window.location.assign(targetURL);
  };

  const handleSendDeterminationLetter = async (
    event: React.MouseEvent<HTMLElement>,
    shouldUpdateCase: boolean = false
  ) => {
    setSaveDeterminationLetterLoading(true);
    if (populateProcedureCodeDescription && preferredLabelFeedback?.length) {
      const user = await getUser();
      const userEmail = user?.email || "";
      await postPreferredLabelFeedback(
        preferredLabelFeedback.map((feedback) => ({ ...feedback, username: userEmail }))
      );
    }
    const updatedLatestReview = await getLatestReviewLeadingToDenial();
    if (updatedLatestReview) {
      const incorrectReviewForDenialLetter = updatedLatestReview.id !== reviewId;

      if (incorrectReviewForDenialLetter) {
        setDenialPreviewModalOpen(false);
        if (setIncorrectReviewForDenialLetterModal) {
          setIncorrectReviewForDenialLetterModal(true);
        }
        if (setDisableDueToWrongReview) {
          setDisableDueToWrongReview(true);
        }
        setSaveDeterminationLetterLoading(false);
      } else {
        // when autoCreateContinuation is ON then FE will not create continuation
        if (shouldCreateContinuation && nextReviewDate && !autoCreateContinuation) {
          await createContinuationUsingNextReviewDate();
        }

        await onFinalizeDenial(
          event,
          {
            nextReviewDate: nextReviewDate ? format(nextReviewDate, "yyyy-MM-dd") : undefined,
          },
          nrdAcknowledged
        );
        if (shouldUpdateCase) {
          try {
            await updateServiceCase?.({
              outcome: "LETTER_QUEUED_FOR_DELIVERY",
              dateCompleted: new Date().toISOString(),
              reviewId,
            });
          } catch (err) {
            window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${caseId}`);
          } finally {
            setSaveDeterminationLetterLoading(false);
          }
        }
      }
    }
  };

  const handleSaveDeterminationLetter = async (event: React.MouseEvent<HTMLElement>) => {
    const updatedLatestReview = await getLatestReviewLeadingToDenial();
    if (updatedLatestReview) {
      const incorrectReviewForDenialLetter = updatedLatestReview.id !== reviewId;
      if (incorrectReviewForDenialLetter) {
        setDenialPreviewModalOpen(false);
        if (setIncorrectReviewForDenialLetterModal) {
          setIncorrectReviewForDenialLetterModal(true);
        }
        if (setDisableDueToWrongReview) {
          setDisableDueToWrongReview(true);
        }
      } else {
        if (nextReviewDate) {
          // when autoCreateContinuation is ON then FE will not create continuation
          if (facilityBasedFeatureEnabled && isPatientNotYetAdmitted && nrdAcknowledged && !autoCreateContinuation) {
            await createContinuationUsingNextReviewDate();
          }
          await updateAuthorization({ nextReviewDate: nextReviewDate.toISOString() });
        }
        if (caseId) {
          await updateServiceCaseById({
            caseStatus: "OPEN",
            assignee: {
              name: "Unassigned - hold in queue",
              userName: "Unassigned - hold in queue",
              salesforceId: "",
            },
          });
          await updateServiceCase?.({
            outcome: "UNASSIGNED_HOLD_IN_QUEUE",
            dateCompleted: new Date().toISOString(),
            reviewId,
          });
          redirectBackToOrigin();
        } else {
          redirectToPatientSummaryFromReview({
            serviceRequest,
            navigate,
          });
        }
      }
    }
  };

  //Start a continuation
  const { mutate: createNewServiceRequestOnAuth, error: createContinuationError } = useAddServiceRequestOnAuthorization(
    {
      id: serviceRequest?.authorization?.id || "",
      requestOptions: { headers: { Accept: "application/json" } },
    }
  );

  const createContinuationUsingNextReviewDate = async () => {
    const formattedNextReviewDate = formatDateToISODate(nextReviewDate || undefined);
    let payload: ServiceRequestCommandPayload = {
      authStatus: "PENDING",
      pendingReason: "RN_REVIEW",
      requestType: "CONTINUATION",
      requestTiming: "CONCURRENT",
      urgency: { isExpedited: false },
      semanticProcedureCodes: serviceRequest?.procedureCodes,
      encounterType: serviceRequest?.encounterType,
      nextReviewDate: formattedNextReviewDate,
      patientStatus: serviceRequest?.patientStatus,
      units: 0,
      continuationGeneratedFromServiceRequest: {
        id: serviceRequest.id,
      },
    };
    return await createNewServiceRequestOnAuth(payload);
  };

  useEffect(() => {
    if (createContinuationError) {
      enqueueSnackbar(
        typeof createContinuationError.data === "object"
          ? createContinuationError.data?.message
          : createContinuationError.message,
        { variant: "error" }
      );
    }
  }, [createContinuationError, enqueueSnackbar]);

  return (
    <Dialog
      open={denialPreviewModalOpen}
      disableEscapeKeyDown
      TransitionComponent={Transition}
      PaperProps={{
        className: classes.modalWrapper,
      }}
      onClose={() => setDenialPreviewModalOpen(false)}
      onClick={handlePickersModalClick}
    >
      <Box className={classes.innerModalWrapper}>
        <Box className={classes.determinationLetterHeader} component="div">
          {!!fileUrl && !hasError ? (
            <PdfViewer
              fileName={"Final Determination Letter"}
              pdfUrl={fileUrl}
              currentPage={currentPage}
              openSidePanel={false}
              hideSearchInAttachments
              hideBorders
              modalView
            />
          ) : hasError ? (
            <Box className={classes.errorWrapper}>
              <H4 style={{ color: colorsLight.font.light }}>{"Unexpected server response. Please try again"}</H4>
            </Box>
          ) : (
            <CircularProgressContainer>
              <CircularProgress size={60} />
            </CircularProgressContainer>
          )}
        </Box>
        <Divider orientation="vertical" />
        <Box component="div" className={classes.determinationLetterBody}>
          <Box>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => setDenialPreviewModalOpen(false)}
            >
              {<CloseIcon />}
            </IconButton>
          </Box>
          <H2 className={classes.message}>Determination letter preview</H2>
          <Body1 className={classes.details} id="sr-detail-message" component="div">
            <Box component="div">
              Review determination letter to ensure that there are no errors with the language or service request
              details.
            </Box>
            {isNrdRequired && (
              <Box component="div" className={classes.boxWrapper}>
                <Checkbox label={"Continued stay must be reviewed"} checked disabled onChange={(checked) => {}} />
                <NextReviewDateSelect nextReviewDate={nextReviewDate} setNextReviewDate={setNextReviewDate} />
              </Box>
            )}
          </Body1>
          {isNrdShown && !isNrdRequired && (
            <InpatientNextReviewDateCollection
              nrdAcknowledged={nrdAcknowledged}
              setNrdAcknowledged={setNrdAcknowledged}
              nextReviewDate={nextReviewDate}
              setNextReviewDate={setNextReviewDate}
            />
          )}
          <img
            alt="Determination letter icon"
            src={DenialLetterReadyForDelivery}
            className={classes.sendDenialLetterIcon}
          />
          {letterConfig?.submissionQA ? (
            <>
              <Checkbox
                label={"Letter is ready to send now"}
                checked={readyToSend}
                className={classes.checkBox}
                onChange={() => setReadyToSend(!readyToSend)}
              />
              {readyToSend ? (
                <PrimaryButton
                  loading={finalizingDenial}
                  className={classes.primaryButtonforQM}
                  startIcon={<SendIcon />}
                  onClick={(event) => handleSendDeterminationLetter(event, !!caseId)}
                  fullWidth
                  disabled={disableSaveOrSendButton}
                >
                  Send determination letter
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  loading={saveDeterminationLetterLoading}
                  className={classes.primaryButtonforQM}
                  startIcon={<SaveIcon />}
                  onClick={handleSaveDeterminationLetter}
                  fullWidth
                  disabled={disableSaveOrSendButton}
                >
                  Save determination letter
                </PrimaryButton>
              )}
            </>
          ) : (
            <PrimaryButton
              loading={saveDeterminationLetterLoading}
              className={classes.primaryButton}
              onClick={handleSendDeterminationLetter}
              fullWidth
              disabled={disableSaveOrSendButton}
            >
              Send determination letter
            </PrimaryButton>
          )}
          <SecondaryButton onClick={() => setDenialPreviewModalOpen(false)} fullWidth>
            Cancel
          </SecondaryButton>
        </Box>
      </Box>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  const { timeout, ...otherProps } = { ...props };
  return <Slide direction="down" ref={ref} timeout={500} {...otherProps} unmountOnExit />;
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CircularProgressContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: theme.spacing(50),
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      float: "right",
      color: theme.palette.text.primary,
      padding: 0,
      width: "24px",
      marginRight: "6px",
    },
    message: {
      paddingTop: "46px",
    },
    details: {
      paddingTop: theme.spacing(1),
      color: theme.palette.text.secondary,
      textAlign: "left",
      width: "100%",
    },
    primaryButton: {
      marginTop: "auto",
      marginBottom: theme.spacing(2),
    },
    primaryButtonforQM: {
      marginBottom: theme.spacing(2),
    },
    checkBox: {
      marginTop: "201px",
      marginLeft: theme.spacing(12),
      marginBottom: theme.spacing(2),
    },
    sendDenialLetterIcon: {
      margin: "auto",
      height: "184px",
    },
    innerModalWrapper: {
      display: "flex",
      justifyContent: "center",
      height: "100%",
      overflow: "hidden",
    },
    determinationLetterHeader: {
      display: "flex",
      flexDirection: "column",
      width: "648px",
    },
    errorWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    determinationLetterBody: {
      display: "flex",
      flexDirection: "column",
      width: "440px",
      padding: "34px 24px",
    },
    modalWrapper: {
      maxWidth: "none",
      maxHeight: "none",
      height: "100vh",
      width: "1088px",
      margin: "0",
      borderRadius: "0 0 4px 4px",
      position: "absolute",
      top: "0",
      background: theme.palette.background.default,
    },
    boxWrapper: {
      padding: "16px 0 0 0",
    },
  })
);

export default DenialPreviewModal;
