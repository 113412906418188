import { ContactInfoT, CoveragesSectionT, FormError, PatientInfoT } from "./formOptions";

export interface FormState {
  patientInfo: PatientInfoT;
  contactInfo: ContactInfoT;
  coveragesInfo: CoveragesSectionT;
  formError: FormError;
}
export type FormAction =
  | {
      type: "UPDATE_PATIENT_INFO";
      payload: PatientInfoT;
    }
  | { type: "UPDATE_CONTACT_INFO"; payload: ContactInfoT }
  | {
      type: "UPDATE_COVERAGES_INFO";
      payload: CoveragesSectionT;
    }
  | {
      type: "UPDATE_FORM_ERROR";
      payload: FormError;
    };

export const formReducer = (state: FormState, action: FormAction): FormState => {
  switch (action.type) {
    case "UPDATE_PATIENT_INFO":
      return {
        ...state,
        patientInfo: { ...state.patientInfo, ...action.payload },
      };
    case "UPDATE_CONTACT_INFO":
      return {
        ...state,
        contactInfo: { ...state.contactInfo, ...action.payload },
      };
    case "UPDATE_COVERAGES_INFO":
      return {
        ...state,
        coveragesInfo: { ...state.coveragesInfo, ...action.payload },
      };
    case "UPDATE_FORM_ERROR":
      return {
        ...state,
        formError: { ...state.formError, ...action.payload },
      };
  }
};
