import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Popper, { PopperProps } from "@material-ui/core/Popper";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, useTheme } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import MuiErrorIcon from "@material-ui/icons/Error";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import SearchIcon from "@material-ui/icons/Search";
import MUIAutocomplete, { AutocompleteProps } from "@material-ui/lab/Autocomplete";
import React, { Ref, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { colorsLight } from "../../themes";
import { TextField, TextFieldProps } from "../TextField";
import { AutoFillIcon } from "../images";
import LazyLoadingOptions, { LOAD_MORE_BATCH_SIZE, useLoadMoreItems } from "./LazyLoadingOptions";
import { SelectOptionsHook } from "./SelectOptionsHook";
import { AutocompleteChangeSubscriberContext } from "./autocompleteContext";
import {
  defaultFilterOptions,
  defaultGetOptionLabel,
  defaultGetOptionSelected,
  useStylesAutocomplete,
  useStylesLookupEndAdornment,
} from "./commonAutocomplete";

type OnChangeParams<T, FreeSolo extends boolean> = Parameters<
  Required<AutocompleteProps<T, false, false, FreeSolo>>["onChange"]
>;
export interface SingleSelectProps<T, FreeSolo extends boolean> {
  selectedValue: T | null;
  setSelectedValue: (option: OnChangeParams<T, FreeSolo>[1]) => void;
  useOptions: SelectOptionsHook<T>;
  reactLabel?: React.ReactNode;
  multiline?: boolean;
  /** Label shown when there is no selected value */
  emptyLabel?: React.ReactNode;
  helperText?: React.ReactNode;
  required?: boolean;
  /** @Deprecated */
  withIcon?: boolean;
  useLargeSearchIcon?: boolean;
  autoFilledIcon?: boolean;
  withEditAdornment?: boolean;
  withWarnAdornment?: boolean;
  withTooltipInfoAdornment?: boolean;
  endAdornmentRef?: Ref<HTMLDivElement>;
  TextFieldProps?: Partial<TextFieldProps>;
  editAction?: () => void;
  error?: boolean;
  // styling applied to label and border color specific for validation error use cases
  useValidationFormat?: boolean;
  clearable?: boolean;
  /** Optionally set a fixed width for the options list. By default this is the same size as the inline UI of the component. */
  optionsWidth?: number;
  /** If this is true (default) then we will render a checkmark in the options list next to the selected option
   * Note: This behavior is buggy as currently implemented. If we use a rest-hook to dynamically fetch new options based
   *       on a query (which is true in most use-cases) then the selected option comparison doesn't really work and we
   *       don't mark selected options. TODO We should create a variant that properly marks selections (and de-dupes for multi-select)
   */
  markSelectedOptions?: boolean;
  /**
   * Will not add adornment when set
   * Overrides all other adornment params
   */
  noAdornment?: boolean;
  /**
   * Will enter the disabled state when there is only one option
   */
  disableWhenOneOption?: boolean;

  disablePortal?: boolean;
  /** determines if the contents of this element will be displayed within LogRocket*/
  dataPublic?: boolean;
  isLazyLoaded?: boolean;
}

// Temporary export for storybook documentation
export function SingleSelectForProps<T>(props: SingleSelectProps<T, false>) {}

interface IProps<T, FreeSolo extends boolean>
  extends Omit<
      AutocompleteProps<T, false, false, FreeSolo>,
      "value" | "onChange" | "renderInput" | "options" | "disableClearable"
    >,
    SingleSelectProps<T, FreeSolo> {}

export function SingleSelect<T, FreeSolo extends boolean = false>({
  selectedValue,
  setSelectedValue,
  useOptions,
  reactLabel,
  label,
  multiline = false,
  emptyLabel,
  helperText,
  autoFilledIcon,
  withEditAdornment,
  withWarnAdornment,
  withTooltipInfoAdornment,
  endAdornmentRef,
  TextFieldProps,
  editAction,
  error,
  useValidationFormat,
  // MuiAutocomplete doesn't pass through `placeholder` as advertised:
  // https://github.com/mui-org/material-ui/issues/21304
  placeholder,
  // props we will process manually
  className,
  renderOption,
  clearable,
  optionsWidth,
  markSelectedOptions = true,
  withIcon,
  useLargeSearchIcon,
  noAdornment,
  required = false,
  disableWhenOneOption,
  disablePortal = false,
  onInputChange,
  dataPublic = false,
  isLazyLoaded = true,
  ...props
}: React.HTMLProps<HTMLDivElement> & IProps<T, FreeSolo>) {
  const [isOpen, setIsOpen] = React.useState(false);

  // Autocomplete query is the search text the user has typed
  const [query, setQuery] = useState("");
  const handleInputChange = (event: any) => {
    const query = event?.target?.value;
    setQuery(query);
    onInputChange?.(event, query, "input");
  };

  // Keep latest props in a ref
  const latestProps = useRef(props);
  useEffect(() => {
    latestProps.current = props;
  });
  // make a stable onError function to avoid unnecessary options fetches
  const onError = useCallback((error) => {
    latestProps.current.onError?.(error);
    setIsOpen(false);
  }, []);

  const {
    options = [],
    optionsLoading,
    filterOptions = defaultFilterOptions,
    refetch: refetchOptions,
    hasMoreOptions,
  } = useOptions({
    query: query || "",
    offset: 0,
    max: LOAD_MORE_BATCH_SIZE,
    onError,
  });

  const { withoutLabel, withLabelAndSelections, validationErrorColors, ...autocompleteClasses } = useStylesAutocomplete(
    {
      markSelectedOptions,
      isLazyLoaded,
    }
  );
  const extraClasses = !label ? `${withoutLabel}` : useValidationFormat ? `${validationErrorColors}` : "";

  // custom popper to set options width separately from the input width
  const popperComponent = useCallback(
    (props: PopperProps) => (
      <Popper {...props} placement="bottom-start" {...(optionsWidth ? { style: { width: optionsWidth } } : null)} />
    ),
    [optionsWidth]
  );

  const { onChange: onChangeFromContext } = useContext(AutocompleteChangeSubscriberContext);

  const loadMoreItems = useLoadMoreItems(query, refetchOptions);
  const onChange = useCallback(
    (...[event, value]: OnChangeParams<T, FreeSolo>) => {
      setSelectedValue(value);
      onChangeFromContext({
        fieldName: label || placeholder || props.name || props.id || "unknown",
        selectedValue: value,
      });
      event.stopPropagation();
    },
    [setSelectedValue, onChangeFromContext, label, placeholder, props.name, props.id]
  );

  const { spacing } = useTheme();
  const [focused, setFocused] = useState(TextFieldProps?.focused || false);
  const [hovered, setHovered] = useState(false);

  // when true, grays out the background and disables input. Also disables end adornments.
  const disabled = disableWhenOneOption && options?.length === 1 && !!selectedValue;

  // icon "order of preference" is loading -> clear -> warning -> edit -> model training -> tooltip -> search
  const showLoadingIcon = optionsLoading && isOpen && !disabled;
  const showClearIcon = !showLoadingIcon && clearable && (focused || hovered) && !disabled;
  const notLoadingOrClearIcon = !optionsLoading && !showClearIcon && !disabled;
  const showWarningIcon = notLoadingOrClearIcon && withWarnAdornment && !disabled;
  const showEditIcon =
    notLoadingOrClearIcon && !showWarningIcon && withEditAdornment && Boolean(editAction) && !disabled;
  const showModelTrainingIcon =
    notLoadingOrClearIcon && !showWarningIcon && !showEditIcon && autoFilledIcon && !disabled;
  const showTooltipIcon =
    notLoadingOrClearIcon &&
    !showWarningIcon &&
    !showEditIcon &&
    !showModelTrainingIcon &&
    withTooltipInfoAdornment &&
    !disabled;
  const showLargeSearchIcon =
    useLargeSearchIcon &&
    notLoadingOrClearIcon &&
    !showWarningIcon &&
    !showEditIcon &&
    !showModelTrainingIcon &&
    !showTooltipIcon &&
    !disabled;
  const showSearchIcon =
    notLoadingOrClearIcon &&
    !showWarningIcon &&
    !showEditIcon &&
    !showModelTrainingIcon &&
    !showTooltipIcon &&
    !showLargeSearchIcon &&
    !disabled;
  const endAdornmentClasses = useStylesLookupEndAdornment();
  const textFieldLabel = !selectedValue && !focused && emptyLabel ? emptyLabel : reactLabel ? reactLabel : label;
  const endAdornment = useMemo(
    () => (
      <>
        <Fade in={showLoadingIcon} style={{ transitionDelay: showLoadingIcon ? "75ms" : "0ms" }}>
          <CircularProgress className={endAdornmentClasses.loadingSpinner} color="inherit" size={20} />
        </Fade>
        <Fade in={showClearIcon} style={{ transitionDelay: showClearIcon ? "75ms" : "0ms" }}>
          <IconButton
            className={endAdornmentClasses.iconButton}
            aria-label="Clear"
            title="Clear"
            disableRipple
            onClick={(event) => {
              event.stopPropagation();
              onChange(event, null, "clear");
              setQuery("");
            }}
          >
            <ClearIcon />
          </IconButton>
        </Fade>
        <Fade in={showWarningIcon} style={{ transitionDelay: showWarningIcon ? "75ms" : "0ms" }}>
          <WarningIcon className={endAdornmentClasses.normalIcon} />
        </Fade>
        <Fade in={showEditIcon} style={{ transitionDelay: showEditIcon ? "75ms" : "0ms" }}>
          <IconButton
            className={endAdornmentClasses.iconButton}
            disableRipple
            onClick={(event) => {
              event.stopPropagation();
              editAction?.();
            }}
          >
            <EditIcon />
          </IconButton>
        </Fade>
        <Fade in={showModelTrainingIcon} style={{ transitionDelay: showModelTrainingIcon ? "75ms" : "0ms" }}>
          <span className={endAdornmentClasses.autoFilledIcon}>
            <AutoFillIcon />
          </span>
        </Fade>
        <Fade in={showTooltipIcon} style={{ transitionDelay: showTooltipIcon ? "75ms" : "0ms" }}>
          <InfoOutlinedIcon className={endAdornmentClasses.normalIcon} />
        </Fade>
        <Fade in={showSearchIcon} style={{ transitionDelay: showSearchIcon ? "75ms" : "0ms" }}>
          <SearchIcon className={endAdornmentClasses.normalIcon} />
        </Fade>
        <Fade in={showLargeSearchIcon} style={{ transitionDelay: showLargeSearchIcon ? "75ms" : "0ms" }}>
          <SearchIcon className={endAdornmentClasses.largeIcon} />
        </Fade>
      </>
    ),
    [
      editAction,
      endAdornmentClasses.autoFilledIcon,
      endAdornmentClasses.iconButton,
      endAdornmentClasses.loadingSpinner,
      endAdornmentClasses.normalIcon,
      endAdornmentClasses.largeIcon,
      onChange,
      showClearIcon,
      showEditIcon,
      showLoadingIcon,
      showModelTrainingIcon,
      showSearchIcon,
      showLargeSearchIcon,
      showTooltipIcon,
      showWarningIcon,
    ]
  );
  return (
    <MUIAutocomplete
      disablePortal={disablePortal}
      disabled={disabled}
      className={`${extraClasses} ${className}`}
      classes={autocompleteClasses}
      disableClearable={!clearable}
      forcePopupIcon={false}
      open={isOpen && !(optionsLoading && !options.length)}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      onBlur={() => {
        if (props?.clearOnBlur) {
          setQuery("");
        }
      }}
      onInputChange={handleInputChange}
      options={options}
      filterOptions={filterOptions}
      getOptionSelected={defaultGetOptionSelected}
      getOptionLabel={defaultGetOptionLabel}
      ListboxComponent={
        isLazyLoaded
          ? (LazyLoadingOptions as unknown as React.ComponentType<React.HTMLAttributes<HTMLElement>>)
          : undefined
      }
      ListboxProps={{
        numOptions: options.length,
        loadMoreItems,
        isNextPageLoading: optionsLoading,
        hasNextPage: Boolean(hasMoreOptions),
        markSelectedOptions,
        query,
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          multiline={multiline}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          label={textFieldLabel}
          placeholder={placeholder}
          helperText={helperText}
          error={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: !noAdornment ? (
              <InputAdornment ref={endAdornmentRef} position="end" className={endAdornmentClasses.endAdornment}>
                {endAdornment}
              </InputAdornment>
            ) : undefined,
          }}
          dataPublic={dataPublic}
          {...TextFieldProps}
        />
      )}
      renderOption={(option, state) => (
        <>
          {markSelectedOptions && state.selected && (
            <DoneIcon style={{ margin: spacing("auto", 1, "auto", 0), color: colorsLight.primary.main }} />
          )}
          {(renderOption && renderOption(option, state)) || (props.getOptionLabel || defaultGetOptionLabel)(option)}
        </>
      )}
      {
        ...props
        /* value and onChange must be passed after passthrough props in order to satisfy the type inference gods */
      }
      value={selectedValue}
      onChange={onChange}
      /* To customise options popper width https://github.com/mui-org/material-ui/issues/19376 */
      PopperComponent={popperComponent}
    />
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const WarningIcon = styled(MuiErrorIcon)(({ theme }) => ({
  color: theme.palette.warning.dark,
}));
