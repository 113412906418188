import React from "react";

import CardContent from "@material-ui/core/CardContent";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { AlertProps } from "@material-ui/lab/Alert";
import { Card } from "../Card";
import { Alert } from "../Alert";

export interface Props {
  /** Show an alert with a given severity */
  alertSeverity?: AlertProps["severity"] | "draft" | "withdrawn";
  /** Message for alert shown (must have alertSeverity prop set) */
  alertMessage?: React.ReactNode;
  /** Omits the Card wrapper */
  noWrapWithCard?: boolean;

  noTopBorders?: boolean;
  noBottomBorders?: boolean;
}

/**
 * Wrapper for a question card with components common among all questions
 */
export default function QuestionCard({
  alertSeverity,
  alertMessage,
  noWrapWithCard,
  noBottomBorders,
  noTopBorders,
  children,
}: React.PropsWithChildren<Props>) {
  if (noWrapWithCard) {
    return (
      <div>
        {children}
        {alertSeverity && <StyledAlert severity={alertSeverity} message={alertMessage} />}
      </div>
    );
  }

  return (
    <Card
      style={
        noTopBorders && noBottomBorders
          ? { borderRadius: "0px" }
          : noTopBorders
          ? { borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }
          : noBottomBorders
          ? { borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px" }
          : { borderRadius: "4px" }
      }
    >
      <CardContent>
        {children}
        {alertSeverity && <StyledAlert severity={alertSeverity} message={alertMessage} />}
      </CardContent>
    </Card>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledAlert = styled(Alert)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
