import React, { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import HeaderContainer from "../AppHeader/HeaderContainer";
import routes from "../../routes";
import { H1, useMuiContainerStyles } from "@coherehealth/common";
import { headerHeight } from "../../util/StyleConstants";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { useMatch } from "react-router-dom";
import { useSnackbar } from "notistack";

import { useCarewebqiDocumentation, CarewebqiDocumentationResponse } from "@coherehealth/core-platform-api";

export default function CarewebqiRedirectPage() {
  const match = useMatch(routes.CAREWEBQI);
  const serviceRequestId = match?.params.serviceRequestId || "";

  if (!serviceRequestId) {
    throw new Error("No serviceRequestId found in route");
  }

  const returnUrl = document.referrer;

  const { mutate: carewebqiDocumentation, error: carewebqiDocumentationError } = useCarewebqiDocumentation({
    serviceRequestId,
  });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (carewebqiDocumentationError) {
      enqueueSnackbar(`Error redirecting to CareWebQI: ${carewebqiDocumentationError.message}`, { variant: "error" });
    }
  }, [carewebqiDocumentationError, enqueueSnackbar]);

  useEffect(() => {
    carewebqiDocumentation({
      returnUrl,
    }).then((response: CarewebqiDocumentationResponse) => {
      const url = new URL(response.url);
      if (!url.hostname.endsWith(".carewebqi.com")) {
        enqueueSnackbar(`Hostname "${url.hostname}" must end with "carewebqi.com"`, { variant: "error" });
        return;
      }

      postInThisWindow(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const containerClasses = useMuiContainerStyles();
  return (
    <Container classes={containerClasses} maxWidth="lg" data-testid={`carewebqi-redirect-${serviceRequestId}`}>
      <HeaderContainer height={headerHeight}>
        <Title>
          <H1>Redirecting to CareWebQI...</H1>
        </Title>
      </HeaderContainer>
      <MainContent>
        <CircularProgress />
      </MainContent>
    </Container>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Title = styled("div")({
  display: "flex",
  alignItems: "center",
});
// eslint-disable-next-line cohere-react/no-mui-styled-import
const MainContent = styled("div")(({ theme }) => ({
  height: "100%",
  paddingTop: theme.spacing(5) + headerHeight,
}));

/**
 * From https://stackoverflow.com/a/133997/12880
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} url the url to send the post request to
 * @param {object} params the parameters to add to the url
 * @param {string} [method=post] the method to use on the form
 */
function postInThisWindow({ url, params }: CarewebqiDocumentationResponse) {
  // The rest of this code assumes you are not using a library.
  // It can be made less wordy if you use one.
  const form = document.createElement("form");
  form.method = "post";
  form.action = url;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}
