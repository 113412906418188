import { makeStyles } from "@material-ui/core/styles";
import MuiErrorIcon from "@material-ui/icons/Error";

const useWarningIconStyles = makeStyles((theme) => ({
  root: {
    marginTop: 4,
    color: theme.palette.warning.dark,
  },
}));

const WarningIcon = () => {
  const classes = useWarningIconStyles();
  return <MuiErrorIcon className={classes.root} />;
};

export default WarningIcon;
