import {
  CohereTable,
  FilterValues,
  FiltersPopover,
  H5,
  filterApiParams,
  initialFilterValues,
} from "@coherehealth/common";
import { FindingRecord } from "@coherehealth/core-platform-api";
import { Box, Grid } from "@material-ui/core";
import { UseClaimHistoryProps } from "./useClaimHistory";
import { useCallback } from "react";
import ClinicalReviewCard from "common/ClinicalReviewCard";
import { StyledBody1 } from "components/ClinicalReview/ClinicalReviewInfoPanel/ServiceRequestInformation";
import { CardContainer } from "components/ClinicalReview/ClinicalReviewInfoPanel";

const ClaimHistory = ({
  claimHistoryProps,
}: Omit<UseClaimHistoryProps, "setClaimsHistory" | "getClaimsData" | "filterValues">) => {
  const {
    loadingClaimHistory,
    claimsHistory,
    filterConfigs,
    setFilterValues,
    setFilterParams,
    queryParams,
    setQueryParams,
    tableColumns,
    rootCompId = "tabpanel-CLAIM_HISTORY",
  } = claimHistoryProps;
  const initialValues = initialFilterValues(filterConfigs, false);

  const sortHandler = useCallback(
    (columnName: string) => {
      setQueryParams((prevQueryParams) => {
        const sortBy = prevQueryParams.sortBy?.[0];
        return {
          ...prevQueryParams,
          sortBy: [
            {
              key: columnName,
              sortOrder: sortBy?.sortOrder === "ASC" ? "DESC" : "ASC",
            },
          ],
        };
      });
    },
    [setQueryParams]
  );

  return (
    <Box sx={{ width: "100%" }} id={rootCompId} data-testid={rootCompId}>
      <CardContainer>
        <ClinicalReviewCard
          header={
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: 6 }}>
                <H5>Claims History</H5>
              </Grid>
              <div>
                <StyledBody1>{claimsHistory?.count || 0} previous claims</StyledBody1>
              </div>
            </Grid>
          }
          isExpanded={true}
          hideExpandIcon
          style={{ minHeight: "80vh" }}
        >
          <FiltersPopover
            filterConfigs={filterConfigs}
            initialValues={initialValues}
            onFiltersChange={(filterValues: FilterValues) => {
              const filterParams = filterApiParams(filterConfigs, filterValues);
              setFilterParams(filterParams);
              setFilterValues(filterValues);
              setQueryParams({ ...queryParams, offset: 0 });
            }}
          />
          <CohereTable<FindingRecord>
            loading={loadingClaimHistory}
            tableColumns={tableColumns}
            data={claimsHistory?.results || []}
            paginateViaParams
            paginateParams={queryParams}
            setPaginateParams={setQueryParams}
            displayMax={queryParams.max ? queryParams.max - 1 : 10}
            totalCount={claimsHistory?.count}
            hideRowHoverBorderColor={true}
            cardHeight={"short"}
            sortingColumnName={queryParams.sortBy?.[0]?.key}
            sortingAscending={queryParams.sortBy?.[0]?.sortOrder === "ASC"}
            onSortChange={sortHandler}
            readonly={true}
          />
        </ClinicalReviewCard>
      </CardContainer>
    </Box>
  );
};

export default ClaimHistory;
