import { useGetServiceRequestOutreachOpportunities, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import NudgeOpportunity from "components/Nudges/NudgeOpportunity";
import OutreachOpportunity from "components/Nudges/OutreachOpportunity";
import compact from "lodash/compact";
import { Caption, H4, useFeature } from "@coherehealth/common";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTheme } from "@material-ui/core/styles";
import { useAuthorized } from "authorization";
import { StyledBody1 } from "components/ClinicalReview/ClinicalReviewInfoPanel/ServiceRequestInformation";
import { countSuccessfulAttempts, countOutreachTypeAttempts } from "util/NudgeUtils";

interface Props {
  serviceRequest: ServiceRequestResponse;
}

export default function NudgeOpportunitySection({ serviceRequest }: Props) {
  const missingInformationFF = useFeature("missingInformationFF");
  const { data: outreachOpportunities, loading } = useGetServiceRequestOutreachOpportunities({
    id: serviceRequest.id,
  });

  // Not all users can see the set auth status nudge opportunities, because we don't want folks in service ops
  // changing their behavior if they see there is a case which has some automated actions associated,
  // because that may incentivize improper care admin decisions
  const canSeeSetAuthStatusOpportunities = useAuthorized("VIEW_SET_AUTH_STATUS_OUTREACH_OPPORTUNITIES");
  const filteredOutreachOpportunities = compact(
    outreachOpportunities?.map(({ outreachAttempts, ...rest }) => {
      const filteredAttempts = outreachAttempts?.filter(
        ({ channel }) => canSeeSetAuthStatusOpportunities || channel !== "SET_AUTH_STATUS"
      );
      if (filteredAttempts && filteredAttempts.length > 0) {
        return { ...rest, outreachAttempts: filteredAttempts };
      }
      return undefined;
    })
  );

  const successMIAttempt = countSuccessfulAttempts(outreachOpportunities);
  const NudgeAttemptNumber = countOutreachTypeAttempts(outreachOpportunities, "NUDGES");

  const { spacing } = useTheme();
  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {missingInformationFF ? (
          <>
            <H4 style={{ paddingBottom: 3 }} data-public>
              Clinical Outreach
            </H4>
            <Grid item xs={12} direction="row" alignItems="center" container>
              <div style={{ marginRight: 24 }}>
                <StyledBody1 data-public>
                  {NudgeAttemptNumber} nudge attempt
                  {NudgeAttemptNumber !== 1 && "s"}
                </StyledBody1>
              </div>
              <div>
                <StyledBody1 data-public>
                  {successMIAttempt} successful missing info attempt
                  {successMIAttempt !== 1 && "s"}
                </StyledBody1>
              </div>
            </Grid>
            <Divider style={{ marginTop: spacing(2) }} />
          </>
        ) : (
          <H4 style={{ paddingBottom: 3 }} data-public>
            Nudges
          </H4>
        )}
        {loading ? (
          <CircularProgress style={{ marginTop: spacing(2) }} size={40} />
        ) : !filteredOutreachOpportunities?.length ? (
          <div style={{ marginTop: 5 }}>
            <Caption color="textSecondary" data-public>
              {missingInformationFF ? "No outreach yet" : "No nudges yet"}
            </Caption>
          </div>
        ) : missingInformationFF ? (
          <OutreachOpportunity
            outreachOpportunities={outreachOpportunities}
            fromNudgeOpportunitySection
            serviceRequestId={serviceRequest.id}
            authStatus={serviceRequest.authStatus}
          />
        ) : (
          outreachOpportunities?.map((outreachOpportunity) => (
            <NudgeOpportunity
              nudgeOpportunity={outreachOpportunity}
              serviceRequest={serviceRequest}
              key={outreachOpportunity.id}
            />
          ))
        )}
      </Grid>
    </>
  );
}
