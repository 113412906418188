import { createTheme } from "@mui/material/styles";
import merge from "lodash/merge";
import cohereBaseTheme from "./cohereBaseTheme";

const cohereDarkTheme = createTheme(
  merge({}, cohereBaseTheme, {
    palette: {
      text: {
        /* todo ask design about their desired text colors */
        primary: "#FFFFFF",
        secondary: "rgba(255, 255, 255, 0.7)",
        tertiary: "rgba(255, 255, 255, 0.7)",
        disabled: "rgba(255, 255, 255, 0.6)",
      },
      background: {
        paper: "#212936",
        default: "#0C1016",
      },
      action: {
        disabledBackground: "#D7D5D5",
        disabled: "rgba(255, 255, 255, 0.6)",
      },
      gray: {
        dark: "#56657F",
        light: "#AFBDD1",
        divider: "#56657F",
      },
      switch: {
        trackBackground: "#BBDEFB",
      },
    },
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            padding: "24px", //was unable to interpret 'theme' to do theme.spacing(x) - figure out why
            boxShadow: "0px 5px 105px rgba(0, 0, 0, 0.05)",
            border: "1px solid #313C4E",
          },
          // To-do: implement hover styles
        },
      },
    },
  })
);

export default cohereDarkTheme;
