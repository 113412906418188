import React, { ComponentProps, HTMLAttributes, useContext } from "react";

import { H2, Modal, PrimaryButton } from "@coherehealth/common";
import { OutOfNetworkReview, OutOfNetworkReviewConfigurationResponse } from "@coherehealth/core-platform-api";
import Grid from "@material-ui/core/Grid";
import { ClinicalReviewContext } from "components/ClinicalReview/Review/ClinicalReviewPage";
import OutOfNetworkReviewModalForm from "./OutOfNetworkReviewModalForm";

interface Props {
  loading: boolean;
  isOpen: boolean;
  setIsOpen(arg0: boolean): void;
  onSubmit(): void;
  review: OutOfNetworkReview;
  setReview: (outOfNetworkReviewUpdate: Partial<OutOfNetworkReview>) => void;
  oonReviewConfig: OutOfNetworkReviewConfigurationResponse | undefined;
  disableFinishOonModalButton: () => boolean;
}

export default function OutOfNetworkReviewSubmissionModal({
  loading,
  isOpen,
  setIsOpen,
  onSubmit,
  review: { reviewOutcome, outcomeReason },
  setReview,
  oonReviewConfig,
  disableFinishOonModalButton,
}: Props) {
  const handleSubmit: HTMLAttributes<HTMLFormElement>["onSubmit"] = async (e) => {
    e.preventDefault();
    onSubmit();
  };

  const { allowedReviewOutcomes } = useContext(ClinicalReviewContext);

  return (
    <>
      <Modal
        open={isOpen}
        maxWidth="lg"
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Grid
          container
          data-testid="oon-review-submission-modal"
          spacing={4}
          component="form"
          onSubmit={handleSubmit}
          style={{ maxWidth: 736 }}
        >
          <Row>
            <H2>Select Out-of-Network Review outcome</H2>
          </Row>
          <OutOfNetworkReviewModalForm
            allowedReviewOutcomes={allowedReviewOutcomes}
            reviewOutcome={reviewOutcome}
            setReview={setReview}
            oonReviewConfig={oonReviewConfig}
            outcomeReason={outcomeReason}
          />

          <Row>
            <PrimaryButton
              data-testid="oon-review-submission-modal-button-finish"
              loading={loading}
              disabled={disableFinishOonModalButton()}
              type="submit"
            >
              Finish review
            </PrimaryButton>
          </Row>
        </Grid>
      </Modal>
    </>
  );
}

export const Row = (props: ComponentProps<typeof Grid>) => (
  <Grid style={{ textAlign: "center" }} item xs={12} {...props} />
);
