import React, { Dispatch, FunctionComponent, HTMLProps, SetStateAction } from "react";
import { colorsLight, H6 } from "@coherehealth/common";
import { Requestor, ServiceRequestResponse } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { createStyles, Grid, makeStyles, styled, Tooltip, useTheme } from "@material-ui/core";
import { iconTooltipText, IconButton, HeadsetMicIcon } from "components/Requestor/common";
import RequestorReadOnly from "./RequestorReadOnly";
import RequestorEdit from "components/Requestor/RequestorEdit";

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      marginInlineStart: "10px",
      marginTop: "2px",
    },
  })
);

interface Props extends HTMLProps<HTMLDivElement> {
  serviceRequest?: ServiceRequestResponse;
  requestor?: Requestor | undefined;
  setRequestor: Dispatch<SetStateAction<Requestor | undefined>>;
  isReadOnly: boolean;
}

const RequestorSummary: FunctionComponent<Props> = ({
  serviceRequest,
  requestor,
  setRequestor,
  isReadOnly,
  ...rest
}) => {
  const classes = useStyles();
  const isLegacyPortalSubmission = !Boolean(requestor);
  const isUpdatedPortalSubmission = Boolean(requestor?.isFormSubmittedWithoutUserInput);

  const isPortalSubmission = isLegacyPortalSubmission || isUpdatedPortalSubmission;
  const theme = useTheme();

  return (
    <RequestorSection {...rest}>
      <Grid container spacing={3} style={{ margin: 0, width: "100%" }}>
        <Grid item xs={12} style={{ display: "flex", padding: 0 }}>
          <Tooltip title={iconTooltipText} placement="top" arrow>
            <IconButton>
              <HeadsetMicIcon />
            </IconButton>
          </Tooltip>
          <H6 className={classes.header}>{isPortalSubmission ? "Requested by" : "Requested for user"}</H6>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            padding: 0,
            margin: isPortalSubmission ? theme.spacing(0.5, 1, 0, 0) : theme.spacing(1, 1, 0, 0),
          }}
        >
          {isReadOnly || isPortalSubmission ? (
            <RequestorReadOnly serviceRequest={serviceRequest} requestor={requestor} />
          ) : (
            <RequestorEdit requestor={requestor} setRequestor={setRequestor} isOnPatientSummary={true} />
          )}
        </Grid>
      </Grid>
    </RequestorSection>
  );
};

export default RequestorSummary;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RequestorSection = styled("div")(({ theme }) => ({
  backgroundColor: colorsLight.gray.light,
  padding: theme.spacing(3, 3),
  borderRadius: 4,
  border: `1px solid ${theme.palette.divider}`,
}));
