import React from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { withStyles, makeStyles, Theme, styled } from "@material-ui/core/styles";
import Button, { ButtonProps, ButtonTypeMap } from "@material-ui/core/Button";
import MuiCircularProgress from "@material-ui/core/CircularProgress";
import { ExtendButtonBase } from "@material-ui/core/ButtonBase";
import { colorsLight, colorsDark } from "../../themes/colors";

const styles = (theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  return {
    root: {
      padding: theme.spacing(2, 5),
    },
    disabled: {},
    outlined: {
      backgroundColor: "transparent",
      "&:hover,&:focus": {
        backgroundColor: "transparent",
      },
      "&:active": {
        backgroundColor: "transparent",
      },
      "&$disabled": {
        borderColor: isDarkTheme ? colorsDark.background.card : "#D7D5D5",
        backgroundColor: "transparent",
        color: isDarkTheme ? "rgba(255, 255, 255, 0.32)" : colorsLight.gray.dark,
      },
    },
    // default mode
    outlinedPrimary: {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      "&:hover,&:focus": {
        borderColor: theme.palette.primary.main,
        // instead of a wide border, we use box shadow to avoid changing the button dimensions
        boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
      },
      "&:active": {
        borderColor: theme.palette.primary.dark,
        boxShadow: `0 0 0 1px ${theme.palette.primary.dark}`,
      },
    },
  };
};

const useWarningStyles = makeStyles((theme: Theme) => {
  return {
    outlinedSecondary: {
      borderColor: theme.palette.warning.dark,
      color: theme.palette.warning.dark,
      "&:hover,&:focus": {
        borderColor: theme.palette.warning.dark,
        // instead of a wide border, we use box shadow to avoid changing the button dimensions
        boxShadow: `0 0 0 1px ${theme.palette.warning.dark}`,
      },
      "&:active": {
        borderColor: theme.palette.warning.dark,
        boxShadow: `0 0 0 1px ${theme.palette.warning.dark}`,
      },
    },
  };
});

const useErrorStyles = makeStyles((theme: Theme) => {
  return {
    outlinedSecondary: {
      borderColor: theme.palette.error.dark,
      color: theme.palette.error.dark,
      "&:hover,&:focus": {
        borderColor: theme.palette.error.dark,
        // instead of a wide border, we use box shadow to avoid changing the button dimensions
        boxShadow: `0 0 0 1px ${theme.palette.error.dark}`,
      },
      "&:active": {
        borderColor: theme.palette.error.dark,
        boxShadow: `0 0 0 1px ${theme.palette.error.dark}`,
      },
    },
  };
});
const useInfoStyles = makeStyles((theme: Theme) => {
  return {
    outlinedSecondary: {
      borderColor: theme.palette.info.dark,
      color: theme.palette.info.dark,
      "&:hover,&:focus": {
        borderColor: theme.palette.info.dark,
        // instead of a wide border, we use box shadow to avoid changing the button dimensions
        boxShadow: `0 0 0 1px ${theme.palette.info.dark}`,
      },
      "&:active": {
        borderColor: theme.palette.info.dark,
        boxShadow: `0 0 0 1px ${theme.palette.info.dark}`,
      },
    },
  };
});

export interface WarningButtonProps {
  /** Alternate styling for warning buttons */
  warning?: boolean;
  error?: never;
  info?: never;
  loading?: boolean;
}
export interface ErrorButtonProps {
  warning?: never;
  error?: boolean;
  info?: boolean;
  loading?: boolean;
}
export interface InfoButtonProps {
  warning?: never;
  error?: never;
  info?: boolean;
  loading?: boolean;
}
export type CohereSecondaryButtonProps = WarningButtonProps | ErrorButtonProps | InfoButtonProps;

// Temporary export for storybook documentation
export function SecondaryButtonForProps(props: CohereSecondaryButtonProps) {}

const SecondaryButton = React.forwardRef((props: CohereSecondaryButtonProps & ButtonProps, ref: any) => {
  // pull out external props that we hardcode, only pass through otherProps
  const { warning, error, info, classes, children, variant, loading, color: ignoredColor, ...otherProps } = props;

  // For both warning and error variants, we override the colors on the outlinedSecondary style
  const color = warning || error || info ? "secondary" : "primary";
  const warningStyles = useWarningStyles();
  const errorStyles = useErrorStyles();
  const infoStyles = useInfoStyles();
  const buttonClasses = {
    ...(error ? errorStyles : {}),
    ...(warning ? warningStyles : {}),
    ...(info ? infoStyles : {}),
    ...classes,
  };

  return (
    <Button ref={ref} classes={buttonClasses} variant="outlined" {...otherProps} color={color} data-public>
      {Boolean(loading) && <CircularProgress color="inherit" size={32} />}
      <div style={loading ? { visibility: "hidden" } : {}}>{children}</div>
    </Button>
  );
});
SecondaryButton.displayName = "SecondaryButton";

export default withStyles(styles)(SecondaryButton) as ExtendButtonBase<ButtonTypeMap<CohereSecondaryButtonProps>>;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CircularProgress = styled(MuiCircularProgress)({
  position: "absolute",
});
