import { AuthStatusWithLabel } from "@coherehealth/core-platform-api";

export const authStatusOptions: AuthStatusWithLabel[] = [
  {
    id: "DRAFT",
    label: "Draft",
  },
  {
    id: "PENDING",
    label: "Pending",
  },
  {
    id: "RECOMMENDED_DENIAL",
    label: "Recommended Denial",
  },
  {
    id: "RECOMMENDED_PARTIAL_APPROVAL",
    label: "Recommended Partial Approval",
  },
  {
    id: "POST_DENIAL_PEER_TO_PEER",
    label: "Post Denial Peer To Peer",
  },
  {
    id: "PARTIALLY_APPROVED",
    label: "Partially Approved",
  },
  {
    id: "DENIED",
    label: "Denied",
  },
  {
    id: "APPROVED",
    label: "Approved",
  },
  {
    id: "VOIDED",
    label: "Voided",
  },
  {
    id: "WITHDRAWN",
    label: "Withdrawn",
  },
  {
    id: "DISMISSED",
    label: "Dismissed",
  },
];
