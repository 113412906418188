import React, { useEffect, useState } from "react";

import { PrimaryButton, useFeature, useMuiContainerStyles } from "@coherehealth/common";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import { getPatientHealthPlanName } from "util/patientUtils";
import {
  AuthorizationCreatePayload,
  Claim,
  useGetPatient,
  ServiceRequestCreatePayload,
  ServiceRequestResponse,
  useBatchSubmitServiceRequests,
  useCreateAuthorization,
  useUpdateServiceRequest,
} from "@coherehealth/core-platform-api";
import SubmissionModal, { SubmissionStatus } from "components/ServiceRequest/Submission/SubmissionModal";
import { useSnackbar } from "notistack";
import { FaxStep } from "components/FaxServiceRequest";
import { payloadFromSRFormContent, formContentFromResponse } from "util/serviceRequest";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import AutocompleteModal from "components/FaxServiceRequest/AutocompleteModal";

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    top: "auto",
    bottom: 0,
  },
  colorPrimary: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RightButton = styled(PrimaryButton)(({ theme }) => ({
  float: "right",
  margin: theme.spacing(2, 0),
}));

const today = new Date();

interface Props {
  step: FaxStep;
  setAttemptedSubmitService: (b: boolean) => void;
  patientId: string;
  canBeSubmitted: boolean;
  serviceRequest: ServiceRequestResponse | undefined;
  loading: boolean;
  formContent: ServiceRequestFormContent;
  setFormContent: (form: ServiceRequestFormContent) => void;
  setServiceRequest: React.Dispatch<React.SetStateAction<ServiceRequestResponse | undefined>>;
  setShowFooter: React.Dispatch<React.SetStateAction<boolean>>;
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSaveAndReview: Function;
  cannotSubmit: boolean;
  reconClaim: Claim;
}

export default function Footer({
  step,
  setAttemptedSubmitService,
  patientId,
  canBeSubmitted,
  loading,
  formContent,
  setFormContent,
  serviceRequest,
  setServiceRequest,
  setShowFooter,
  onSaveAndReview,
  cannotSubmit,
  reconClaim,
}: Props) {
  const simplifiedServiceFrequency = useFeature("simplifiedServiceFrequency");
  const classes = useStyles();
  const containerClasses = useMuiContainerStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [autocompleteModalOpen, setAutocompleteModalOpen] = useState(false);

  const { data: patient } = useGetPatient({ id: patientId || "" });

  const {
    mutate: createNewAuthorization,
    loading: createNewAuthorizationLoading,
    error: createNewAuthorizationError,
  } = useCreateAuthorization({});

  const {
    mutate: submitServiceRequests,
    loading: submitServiceRequestLoading,
    error: submitServiceRequestsError,
  } = useBatchSubmitServiceRequests({});

  const {
    mutate: patch,
    loading: patchLoading,
    error: patchError,
  } = useUpdateServiceRequest({
    id: serviceRequest?.id || "",
  });

  useEffect(() => {
    if (createNewAuthorizationError) {
      enqueueSnackbar(`Error creating service request: ${createNewAuthorizationError.message}`, {
        variant: "error",
      });
    }
  }, [createNewAuthorizationError, enqueueSnackbar]);

  useEffect(() => {
    if (patchError) {
      enqueueSnackbar(`Error updating service request: ${patchError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, patchError]);

  const onFinalSubmission = async () => {
    if (serviceRequest) {
      const serviceRequestToSubmit = {
        ...serviceRequest,
        reconClaim: reconClaim,
      };
      const srList = await submitServiceRequests([serviceRequestToSubmit]);
      const retVal = srList[0];
      setServiceRequest(retVal);
      setFormContent(formContentFromResponse(retVal));
    }
  };

  const saveAndReview = async () => {
    setAttemptedSubmitService(true);
    let saveError = false;
    if (canBeSubmitted) {
      let response;
      try {
        response = await submitServiceRequestForm(formContent);
      } catch (e) {
        enqueueSnackbar("Failed to save service request", { variant: "error" });
        saveError = true;
      }
      if (!saveError) {
        onSaveAndReview();
      }
      if (response) {
        setServiceRequest(response);
        setFormContent(formContentFromResponse(response));
      }
    }
  };

  const submitServiceRequestForm = async (sr: ServiceRequestFormContent) => {
    const notNullPatient = patient !== null ? patient : undefined;
    const healthPlanName = patientId ? getPatientHealthPlanName(notNullPatient, today) : undefined;
    const payload: ServiceRequestCreatePayload = {
      ...payloadFromSRFormContent(sr, simplifiedServiceFrequency),
      patient: patientId,
      requestType: "INITIAL",
    };
    const authorizationPayload: AuthorizationCreatePayload = {
      patient: patientId,
      healthPlanName: healthPlanName !== null ? healthPlanName : undefined, //healthPlanName,
      userSelectedOONException: sr.userSelectedOONException || false,
      serviceRequests: [payload],
    };
    //if the form content has an id, it has already been saved, and we need to patch; else create
    if (sr?.id) {
      return await patch({ ...payload }, { pathParams: { id: sr?.id } });
    } else {
      const authResponse = await createNewAuthorization(authorizationPayload);
      if (authResponse && authResponse.serviceRequestsOnAuth?.length && authResponse.serviceRequestsOnAuth[0].id) {
        return authResponse.serviceRequestsOnAuth[0];
      }
      return undefined;
    }
  };

  const [showSubmissionModal, setShowSubmissionModal] = useState(false);
  if (submitServiceRequestLoading && !showSubmissionModal && step === "SUBMIT_REQUEST") {
    setShowSubmissionModal(true);
  }
  let submissionStatus: SubmissionStatus;
  if (submitServiceRequestsError) {
    submissionStatus = SubmissionStatus.Failure;
  } else if (submitServiceRequestLoading) {
    submissionStatus = SubmissionStatus.Loading;
  } else {
    submissionStatus = SubmissionStatus.Success;
  }

  return (
    <AppBar classes={classes} component="footer" elevation={0}>
      <Container classes={containerClasses} maxWidth="lg">
        <RightButton
          onClick={() => {
            if (step === "SAVE_AND_REVIEW") {
              if (canBeSubmitted) {
                setAutocompleteModalOpen(true);
              } else {
                saveAndReview();
              }
            } else {
              setAttemptedSubmitService(true);
            }
            if (step === "SUBMIT_REQUEST") {
              onFinalSubmission();
            }
          }}
          loading={loading || patchLoading || createNewAuthorizationLoading}
          disabled={
            loading || patchLoading || createNewAuthorizationLoading || (cannotSubmit && step === "SAVE_AND_REVIEW")
          }
        >
          {step === "SAVE_AND_REVIEW" ? "Save and review" : "Submit request"}
        </RightButton>
        <SubmissionModal
          open={showSubmissionModal}
          handleClose={() => {
            setShowFooter(false);
            setShowSubmissionModal(false);
          }}
          onSuccessAction={() => {
            setShowFooter(false);
            setShowSubmissionModal(false);
          }}
          onFailureAction={() => {
            onFinalSubmission();
          }}
          status={submissionStatus}
        />
      </Container>
      <AutocompleteModal
        open={autocompleteModalOpen}
        onClose={() => setAutocompleteModalOpen(false)}
        onSave={saveAndReview}
        loading={createNewAuthorizationLoading || patchLoading}
      />
    </AppBar>
  );
}
