import { useCallback, useEffect, useMemo, useState } from "react";
import NoResults from "components/ReferralManagement/Dashboard/NoResults";
import { Helmet } from "react-helmet-async";
import Grid from "@material-ui/core/Grid";
import ReferralRequestSummaryCard from "./ReferralRequestSummaryCard";
import { useLocation } from "react-router-dom";
import useReferralPatientSummary from "./useReferralPatientSummary";
import { ReferralRequestResponse, useGetReferralRequest } from "@coherehealth/core-platform-api";
import useSetCobrandingHealthPlanName from "../common/useSetCobrandingHealthPlanName";

interface Props {
  patientId: string;
}

export default function ReferralRequestPatientSummaryPage({ patientId }: Props) {
  const location = useLocation();
  const search = useMemo(() => new URLSearchParams(location.search), [location]);
  const reviewReferralRequestId = search.get("reviewReferralRequestId") || undefined;
  const { referralRequestsLoading, selectedReferralRequestData, filteredReferralRequests } = useReferralPatientSummary(
    patientId,
    reviewReferralRequestId
  );

  const [referralRequests, setReferralRequests] = useState<(ReferralRequestResponse | null)[]>([]);

  const [rrRefetchId, setRrRefetchId] = useState<string>("");
  const { refetch: refetchReferralRequest } = useGetReferralRequest({ id: rrRefetchId, lazy: true });

  useSetCobrandingHealthPlanName(patientId);

  const refetchReferralRequestCallback = useCallback(async (id) => {
    setRrRefetchId(id);
  }, []);

  useEffect(() => {
    if (selectedReferralRequestData || filteredReferralRequests) {
      setReferralRequests([selectedReferralRequestData].concat(filteredReferralRequests || []));
    }
  }, [filteredReferralRequests, selectedReferralRequestData]);

  useEffect(() => {
    if (rrRefetchId) {
      refetchReferralRequest().then((rrData) => {
        if (rrData) {
          setReferralRequests((prev) => prev.map((rr) => (rr && rr.id === rrRefetchId ? rrData : rr)));
        }
        setRrRefetchId("");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rrRefetchId]);

  const showEmptyResults = Array.isArray(referralRequests) && referralRequests.length === 0;

  return (
    <>
      <Helmet>
        <title>Cohere | Patient Referrals</title>
      </Helmet>
      {referralRequestsLoading ? (
        <NoResults heading="Loading your referrals" message="This might take a second." />
      ) : showEmptyResults ? (
        <NoResults heading="No referrals yet" message="Get started by adding a referral above." />
      ) : (
        referralRequests.map(
          (referralRequest, idx) =>
            referralRequest && (
              <Grid key={referralRequest.id}>
                {
                  <ReferralRequestSummaryCard
                    referralRequest={referralRequest}
                    expand={referralRequest.id === reviewReferralRequestId}
                    onEditIntegration={refetchReferralRequestCallback}
                  />
                }
              </Grid>
            )
        )
      )}
    </>
  );
}
