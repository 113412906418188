import { WithAcceptAction } from "@coherehealth/core-platform-api";

export type OnAcceptAction = NonNullable<WithAcceptAction["onAcceptAction"]>;

export type OnAcceptAttribute =
  | "units"
  | "procedureCodes"
  | "placeOfService"
  | "encounterType"
  | "urgency.isExpedited"
  | "withdraw"
  | "attachments"
  | "none"
  | "rangeOfOptions?SERVICE_CHANGE";

export type TargetFieldLabel =
  | "Site of service change"
  | "Place of service change"
  | "Procedure code change"
  | "Swap service"
  | "Unit reduction"
  | "Urgency change"
  | "Add attachments"
  | "Does not submit / withdraw"
  | "Other (message only)";

export type TargetFieldOption = {
  label: TargetFieldLabel;
  onAcceptAction?: OnAcceptAction;
  onAcceptAttribute?: OnAcceptAttribute;
};

export const TARGET_FIELD_OPTIONS_MAP: Record<string, TargetFieldOption> = {
  add_attachments: {
    label: "Add attachments",
    onAcceptAction: "ADD_ATTACHMENTS",
  },
  withdraw: {
    label: "Does not submit / withdraw",
    onAcceptAction: "WITHDRAW",
  },
  place_of_service: {
    label: "Place of service change",
    onAcceptAttribute: "placeOfService",
    onAcceptAction: "REPLACE_VALUE",
  },
  procedure_codes: {
    label: "Procedure code change",
    onAcceptAttribute: "procedureCodes",
  },
  encounter_type: {
    label: "Site of service change",
    onAcceptAttribute: "encounterType",
    onAcceptAction: "REPLACE_VALUE",
  },
  service_change: {
    label: "Swap service",
    onAcceptAttribute: "rangeOfOptions?SERVICE_CHANGE",
    onAcceptAction: "REPLACE_VALUE",
  },
  units: {
    label: "Unit reduction",
    onAcceptAttribute: "units",
    onAcceptAction: "REPLACE_VALUE",
  },
  expedited: {
    label: "Urgency change",
    onAcceptAttribute: "urgency.isExpedited",
    onAcceptAction: "REPLACE_VALUE",
  },
  no_action: {
    label: "Other (message only)",
    onAcceptAction: "NONE",
  },
};

export const BODY_AREAS = [
  { id: "WholeBody", label: "Whole Body" },
  { id: "Head", label: "Head" },
  { id: "Face", label: "Face" },
  { id: "OrbitEarFossa", label: "Orbit/Ear/Fossa" },
  { id: "TMJ", label: "TMJ" },
  { id: "Neck", label: "Neck" },
  { id: "Shoulder", label: "Shoulder" },
  { id: "Arm", label: "Arm" },
  { id: "UpperArm", label: "Upper Arm" },
  { id: "Elbow", label: "Elbow" },
  { id: "Forearm", label: "Forearm" },
  { id: "Wrist", label: "Wrist" },
  { id: "Hand", label: "Hand" },
  { id: "Chest", label: "Chest" },
  { id: "Heart", label: "Heart" },
  { id: "Abdomen", label: "Abdomen" },
  { id: "Pelvis", label: "Pelvis" },
  { id: "CervicalSpine", label: "Cervical Spine" },
  { id: "ThoracicSpine", label: "Thoracic Spine" },
  { id: "LumbarSpine", label: "Lumbar Spine" },
  { id: "LowerExtremity", label: "Lower Extremity" },
  { id: "Hip", label: "Hip" },
  { id: "Leg", label: "Leg" },
  { id: "Thigh", label: "Thigh" },
  { id: "Knee", label: "Knee" },
  { id: "LowerLeg", label: "Lower Leg" },
  { id: "Ankle", label: "Ankle" },
  { id: "Foot", label: "Foot" },
];

export const SPECIALTY_OPTIONS = [
  {
    id: "Musculoskeletal",
    label: "Musculoskeletal",
  },
  {
    id: "Cardiology",
    label: "Cardiology",
  },
  {
    id: "Radiology",
    label: "Radiology",
  },
  {
    id: "SurgicalServices",
    label: "Surgical services",
  },
  {
    id: "HomeHealth",
    label: "Home health",
  },
  {
    id: "OPRehab",
    label: "OP rehab",
  },
  {
    id: "DiagnosticImaging",
    label: "Diagnostic imaging",
  },
  {
    id: "Sleep",
    label: "Sleep",
  },
];
