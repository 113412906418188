export default function LinkingModeBorder() {
  return (
    <>
      <div
        style={{
          height: "calc(100vh - 155px)",
          width: "8px",
          position: "absolute",
          left: 0,
          top: "155px",
          background: "#5C65B1",
          zIndex: 2,
        }}
      />
      <div
        style={{
          height: "8px",
          width: "100vw",
          position: "absolute",
          left: 0,
          top: "155px",
          background: "#5C65B1",
          zIndex: 2,
        }}
      />
      <div
        style={{
          height: "calc(100vh - 155px)",
          width: "8px",
          position: "absolute",
          right: 0,
          top: "155px",
          background: "#5C65B1",
          zIndex: 2,
        }}
      />
      <div
        style={{
          height: "8px",
          width: "100vw",
          position: "absolute",
          left: 0,
          bottom: 0,
          background: "#5C65B1",
          zIndex: 2,
        }}
      />
    </>
  );
}
