import { Body1, InformativeModal, colorsLight } from "@coherehealth/common";
import EpisodicAuthModalIcon from "components/images/EpisodicAuthModalIcon";
import { Grid, makeStyles, useTheme } from "@material-ui/core";
import useDraftContinue from "components/ServiceRequest/DraftContinue";
import { ProcedureCode, ServiceRequestResponse, Patient } from "@coherehealth/core-platform-api";

interface Props {
  serviceRequest: ServiceRequestResponse;
  unitType?: string;
  unitText?: string;
  procedureCodes?: ProcedureCode[];
  clinicalServiceName?: string;
  patient?: Patient;
}

interface ModalProps extends Props {
  open: boolean;
  onClose: () => void;
  onClickNewSR?: () => void;
}

const Subheader = ({ serviceRequest, unitType, unitText, procedureCodes, clinicalServiceName }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Body1 className={classes.row} style={{ paddingBottom: theme.spacing(4), paddingRight: theme.spacing(2) }}>
          We've streamlined the patient's care by automatically authorizing {unitText} of {clinicalServiceName}.
        </Body1>

        <Body1 className={classes.row} style={{ paddingBottom: theme.spacing(4), paddingRight: theme.spacing(2) }}>
          This is the fastest way to get the patient care - simply provide provider and facility details on the next
          page to get an authorization number.
        </Body1>
      </Grid>
    </Grid>
  );
};

export default function EpisodicAuthsModal({ open, onClose, serviceRequest, onClickNewSR, patient }: ModalProps) {
  const { units, clinicalServices, semanticProcedureCodes } = serviceRequest;
  const serviceName = clinicalServices && clinicalServices[0]?.name;
  const unitType =
    semanticProcedureCodes && semanticProcedureCodes[0]?.unitType === "Number of visits" ? "visit" : "unit";
  const unitText = units === 1 ? `1 ${unitType}` : `${units} ${unitType}s`;
  const patientName = patient?.lastName + ", " + patient?.firstName;
  const theme = useTheme();
  const getHeaderText = () => {
    return (
      <h2
        className="MuiTypography-root makeStyles-headerText-112 MuiTypography-h2"
        style={{ paddingBottom: theme?.spacing(4), paddingRight: theme?.spacing(2) }}
      >
        {patientName} is pre-approved for all the {serviceName} codes you entered!
      </h2>
    );
  };
  const draftContinue = useDraftContinue(serviceRequest);
  const subHeaderText = (
    <Subheader
      serviceRequest={serviceRequest}
      unitType={unitType}
      unitText={unitText}
      procedureCodes={semanticProcedureCodes}
      clinicalServiceName={serviceName}
    />
  );

  return (
    <InformativeModal
      open={open}
      icon={<EpisodicAuthModalIcon style={{ width: 148, height: 125 }} />}
      headerText={getHeaderText()}
      subHeaderText={subHeaderText}
      primaryButtonAction={() => {
        draftContinue("PRE_APPROVED_CARE_DETAILS");
        onClose();
      }}
      primaryButtonText={"Get authorization number"}
      tertiaryButtonAction={() => {
        if (onClickNewSR) {
          onClickNewSR();
        }
        onClose();
      }}
      tertiaryButtonText={"I’d like to start a new request"}
      showDivider={false}
      onClose={onClose}
      leftAlignText
      dialogWidth="md"
    />
  );
}

const useStyles = makeStyles((theme) => ({
  styledDoneIcon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    verticalAlign: "middle",
  },
  row: {
    color: colorsLight.font.main,
    paddingBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));
