import { registerRoute } from "../server";
import { PALCategory } from "../../index";

export default function registerRoutes() {
  registerRoute({
    id: "palCategorySearch",
    route: "palCategory",
    method: "get", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      const queryParams = request.queryParams;
      let result = (schema as any).palCategories.all();
      if (queryParams) {
        if (queryParams.query) {
          result = (schema as any).palCategories.where((item: PALCategory) => {
            return (
              item.name?.match(new RegExp(queryParams.query, "i")) ||
              item.description?.match(new RegExp(queryParams.query, "i"))
            );
          });
        }
      }
      // Limit to first 10
      result.models = result.models.slice(0, 10);
      return result;
    },
  });
}
