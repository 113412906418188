interface IColors {
  [key: string]: {
    [key: string]: string;
  };
}

const whiteHighEmphasis = "#FFFFFF";

const colorsLight: IColors = {
  primary: {
    dark: "#027691",
    main: "#039EC3",
    light: "#00A9D1",
    faint10: "#F2FAFC",
    faint20: "#E0F4FC",
    transparent: "rgba(3, 158, 195, 0.15)",
    transparentDark: "rgba(2, 145, 178, 0.21)",
    contrastText: whiteHighEmphasis,
  },
  secondary: {
    dark: "#CF1655",
    main: "#EA1D63",
    light: "#F83275",
    contrastText: whiteHighEmphasis,
  },
  info: {
    dark: "#55358F",
    main: "#6948A6",
    light: "#8162B9",
    contrastText: whiteHighEmphasis,
  },
  error: {
    dark: "#C62828",
    main: "#E53935",
    transparent: "rgba(229, 57, 53, 0.15)",
    light: "#F9EAEA",
    contrastText: whiteHighEmphasis,
  },
  warning: {
    dark: "#CB7F00",
    main: "#9E6300",
    transparent: "rgba(255, 193, 7, 0.15)",
    light: "#FAF2E6",
    contrastText: whiteHighEmphasis,
  },
  success: {
    dark: "#03804B",
    main: "#04B86C",
    transparent: "rgba(4, 184, 104, 0.15)",
    light: "#ECF9F3",
    contrastText: whiteHighEmphasis,
  },
  font: {
    main: "#212936",
    secondary: "#5E6572",
    light: "#6F7580",
    tertiary: "#7B8FAD",
  },
  brand: {
    blue: "#039EC3",
    lightBlue: "#89D3DD",
    purple: "#55358F",
    pink: "#EA1D63",
    yellow: "#FED205",
  },
  black: {
    highEmphasis: "rgba(0, 0, 0, 0.87)",
    mediumEmphasis: "rgba(0, 0, 0, 0.6)",
    disabled: "#9E9E9E",
    inactive: "rgba(0, 0, 0, 0.54)",
  },
  white: {
    highEmphasis: whiteHighEmphasis,
    mediumEmphasis: "rgba(255, 255, 255, 0.7)",
    disabled: "rgba(255, 255, 255, 0.6)",
    inactive: "rgba(255, 255, 255, 0.54)",
  },
  background: {
    dark: "#E8E8E8",
    mid: "#F8FBFC",
    light: "#F8FBFC",
    input: whiteHighEmphasis,
    card: whiteHighEmphasis,
    panel: "#F5F5F5",
  },
  gray: {
    dark: "#C2C2C2",
    light: "rgba(0, 0, 0, 0.04)",
    divider: "#E5E5E5",
    inactive: "#E0E0E0",
  },
  action: {
    selected: "#F5F5F5",
    hoverBackground: "#E3F2FD",
  },
};

const colorsDark: IColors = {
  ...colorsLight,
  background: {
    dark: "#0C1016",
    mid: "#121821",
    light: "#2B3648",
    input: "rgba(25, 31, 41, 0.5)",
    card: "#212936",
    cardLight: "#313C4E",
    flyout: "#171D26",
  },
  gray: {
    dark: "#56657F",
    light: "#AFBDD1",
    divider: "#56657F",
    inactive: "#3C4B62",
  },
  action: {
    selected: "#212936",
  },
  pink: {
    text: "#CBABFF",
    tint: "#443D6D",
    dark: "#CF1655",
    main: "#EA1D63",
    light: "#F83275",
  },
  success: {
    dark: "#04B86C",
    main: "#04A15F",
    tint10: "#04B86C10",
    transparent: "rgba(4, 184, 104, 0.15)",
    light: "#1E4E50",
  },
  error: {
    tint10: "#E5393510",
    dark: "#E53935",
    main: "#C62828",
    light: "#633743",
  },
  neutral: {
    tint10: "rgba(25, 31, 41, 0.50)",
  },
  warning: {
    dark: "#FFC107",
    main: "#9E6300",
    light: "#605838",
    tint10: "#FFC10710",
  },
  primary: {
    dark: "#00A9D1",
    main: "#039EC3",
    light: "#027691",
    faint20: "#085064",
    faint10: "#F2FAFC",
    tint16: "#039EC316",
    tint8: "#039EC308",
  },
  info: {
    dark: "#EAE9F4",
    main: "#CBABFF",
    light: "#AC6AFF",
    faint10: "#443D6D",
    tint8: "#6948A6",
  },
  font: {
    primary: "#FFFFFF",
    secondary: "#AFBDD1",
    tertiary: "#56657F",
    disabled: "#5D6570",
  },
};

export { colorsLight, colorsDark };
