import React, { useMemo } from "react";
import { Body1, Caption } from "../Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, useTheme } from "@material-ui/core/styles";

export interface Column<T> {
  columnName: string;
  value: (arg: T) => React.ReactNode;
  fillPanel?: boolean /* If true then the first cell of this column will fill the entire panel */;
  width?: string;
  customWidth?: (panels: T[][] | T[]) => string;
}

interface TableWithPanelsProps<T> {
  /* displays panels with multiple rows in each if
    supplied a 2D array. displays one row per panel if given
    a flat array. */
  panels: T[][] | T[];
  columns: Column<T>[];
  noSpacing?: boolean;
}

export default function TableWithPanels<T>({ panels, columns, noSpacing }: TableWithPanelsProps<T>) {
  return (
    <TableContainer>
      <StyledTable>
        <TableHead>
          {noSpacing ? (
            <TableRow>
              {columns.map((column, idx) => (
                <StyledTableCellHeadNoSpacing key={`${column.columnName}_${idx}`}>
                  <Caption color="textSecondary" data-public>
                    {column.columnName}
                  </Caption>
                </StyledTableCellHeadNoSpacing>
              ))}
            </TableRow>
          ) : (
            <TableRow>
              {columns.map((column, idx) => (
                <StyledTableCellHead
                  width={column?.customWidth ? column?.customWidth(panels) : column.width ? column.width : ""}
                  key={`${column.columnName}_${idx}`}
                >
                  <Caption color="textSecondary" data-public>
                    {column.columnName}
                  </Caption>
                </StyledTableCellHead>
              ))}
            </TableRow>
          )}
        </TableHead>
        {panels.map((panel, panel_idx) => (
          <TableBody key={`panel_${panel_idx}`}>
            {isMultiRowPanel(panel) ? (
              <>
                {panel.map((row, row_idx) => (
                  <PanelRow
                    key={`row_${row_idx}`}
                    rowData={row}
                    columns={columns}
                    row_idx={row_idx}
                    numRows={panel.length}
                  />
                ))}
              </>
            ) : (
              <PanelRow rowData={panel} columns={columns} row_idx={0} numRows={1} />
            )}
          </TableBody>
        ))}
      </StyledTable>
    </TableContainer>
  );
}

// bitmasks for cell position within a panel
const TOP = 1 << 3;
const BOTTOM = 1 << 2;
const LEFT = 1 << 1;
const RIGHT = 1 << 0;

interface PanelRowProps<T> {
  rowData: T;
  columns: Column<T>[];
  row_idx: number;
  numRows: number;
}

function PanelRow<T>({ rowData, columns, row_idx, numRows }: PanelRowProps<T>) {
  return (
    <TableRow>
      {columns.map((column, col_idx) => {
        let position = 0;
        if (row_idx === 0) {
          position |= TOP;
        }
        if (row_idx === numRows - 1 || column.fillPanel) {
          position |= BOTTOM;
        }
        if (col_idx === 0) {
          position |= LEFT;
        }
        if (col_idx === columns.length - 1) {
          position |= RIGHT;
        }
        return (
          <PanelCell
            key={`row_${row_idx}_column_${col_idx}`}
            column={column}
            position={position}
            rowData={rowData}
            numRows={numRows}
          />
        );
      })}
    </TableRow>
  );
}

interface PanelCellProps<T> {
  column: Column<T>;
  position: number;
  rowData: T;
  numRows: number;
}

function PanelCell<T>({ column, position, rowData, numRows }: PanelCellProps<T>) {
  const cellValue = useMemo(() => column.value(rowData), [rowData, column]);
  const borderStyles = usePanelTableCellBorderStyles(position);

  if (column.fillPanel && !cellValue) {
    return null;
  }

  return (
    <PanelTableCell rowSpan={column.fillPanel ? numRows : undefined} style={borderStyles}>
      <Body1 style={{ fontSize: 13, lineHeight: "15px" }} component="span" data-public>
        {cellValue}
      </Body1>
    </PanelTableCell>
  );
}

function usePanelTableCellBorderStyles(position: number) {
  const theme = useTheme();

  return {
    borderTop: position & TOP ? `1px solid ${theme.palette.divider}` : "none",
    borderBottom: position & BOTTOM ? `1px solid ${theme.palette.divider}` : "none",
    borderLeft: position & LEFT ? `1px solid ${theme.palette.divider}` : "none",
    borderRight: position & RIGHT ? `1px solid ${theme.palette.divider}` : "none",
    paddingTop: position & TOP ? theme.spacing(1) : undefined,
    paddingBottom: position & BOTTOM ? theme.spacing(1) : undefined,
    paddingLeft: position & LEFT ? theme.spacing(1) : undefined,
    paddingRight: position & RIGHT ? theme.spacing(1) : undefined,
    borderTopLeftRadius: position & TOP && position & LEFT ? 4 : undefined,
    borderTopRightRadius: position & TOP && position & RIGHT ? 4 : undefined,
    borderBottomLeftRadius: position & BOTTOM && position & LEFT ? 4 : undefined,
    borderBottomRightRadius: position & BOTTOM && position & RIGHT ? 4 : undefined,
  };
}

function isMultiRowPanel<T>(panel: T[] | T): panel is T[] {
  return Array.isArray(panel);
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1, 1.5),
  whiteSpace: "nowrap",
  "&:first-child": {
    paddingLeft: theme.spacing(1),
  },
  "&.MuiTableCell-head": {
    lineHeight: "1rem",
  },
  border: "none",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledTableCellHeadNoSpacing = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1, 1.5),
  whiteSpace: "nowrap",
  "&:first-child": {
    paddingLeft: theme.spacing(0),
  },
  "&.MuiTableCell-head": {
    lineHeight: "1rem",
  },
  border: "none",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledTable = styled(Table)(({ theme }) => ({
  borderCollapse: "separate",
  "& .MuiTableBody-root:nth-last-child(n+2):after": {
    // prettier-ignore
    content: "''",
    display: "block",
    height: theme.spacing(1),
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PanelTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.table.panelBackground,
  padding: theme.spacing(1.5),
  verticalAlign: "top",
}));
