import React, { Dispatch, SetStateAction, useCallback } from "react";
import OnboardingQuestionCard from "./OnboardingQuestionCard";
import { Caption, Checkbox, colorsLight, H6, TextField } from "@coherehealth/common";
import { FormGroup, Grid, makeStyles } from "@material-ui/core";
import { FormTitleDivider, UserInfoState } from "./shared";
import { DEFAULT_SPECIALTY_OPTIONS as defaultSpecialtyOptions } from "../../util/organization";

interface Props {
  questionTitle: string;
  highlightInfoFormErrors: boolean;
  specialties: string[];
  setSpecialties: (specialties: string[]) => void;
  orgSpecialties?: string[];
  userInfo?: UserInfoState;
  setUserInfo?: Dispatch<SetStateAction<UserInfoState>>;
  admin?: boolean;
}

export default function SpecialtiesMultiSelect({
  questionTitle,
  highlightInfoFormErrors,
  specialties,
  setSpecialties,
  orgSpecialties,
  userInfo,
  setUserInfo,
  admin,
}: Props) {
  const classes = useStyles();
  const ABOUT_YOUR_ROLE: string = "About your role";
  const YOUR_TITLE: string = "Your title (e.g. Pre-cert manager)";
  const SELECT_ALL: string = "Select all that apply";
  const REQUIRED: string = "Required";

  const notApplicable: { id: string; label: string } = {
    id: "Not applicable, I don’t submit authorizations",
    label: "Not applicable, I don’t submit authorizations",
  };

  const organizationSpecialties: { id: string; label: string }[] =
    admin && orgSpecialties ? defaultSpecialtyOptions.filter((s) => orgSpecialties.includes(s.id)) : [];

  const specialtyOptions: { id: string; label: string }[] = admin
    ? [...organizationSpecialties, notApplicable]
    : [...defaultSpecialtyOptions];

  const middleIndex = Math.ceil(specialtyOptions.length / 2);

  const evenSpecialties = specialtyOptions.slice(0, middleIndex);
  const oddSpecialties = specialtyOptions.slice(middleIndex);

  const setUserInfoField = (field: keyof UserInfoState) => (newVal: UserInfoState[typeof field]) => {
    if (userInfo && setUserInfo) {
      setUserInfo((prev) => ({ ...prev, [field]: newVal }));
    }
  };

  const setChecked = useCallback(
    (id: string, checked: boolean) => {
      const isCurrentlySelected: boolean = specialties.includes(id);
      const isNotApplicableSelected: boolean = specialties.includes(notApplicable.id);
      if (checked && !isCurrentlySelected) {
        /**
         *  If "Not applicable",
         *  select "Not applicable" and deselect the other options;
         *  else if another option is selected and "Not applicable" was previously selected,
         *  deselect "Not applicable";
         **/
        let newSpecialties: string[] = [];
        if (id === notApplicable.id) {
          newSpecialties = [id];
        } else if (isNotApplicableSelected) {
          newSpecialties = [...specialties.filter((id) => id !== notApplicable.id), id];
        } else {
          newSpecialties = [...specialties, id];
        }
        setSpecialties(newSpecialties);
      }
      if (!checked && isCurrentlySelected) {
        const newSpecialties: string[] = specialties.filter((sId) => sId !== id);
        setSpecialties(newSpecialties);
      }
    },
    [specialties, notApplicable.id, setSpecialties]
  );

  return (
    <OnboardingQuestionCard
      titleText={userInfo ? ABOUT_YOUR_ROLE : questionTitle}
      displayQuestionSubtext={!userInfo ? SELECT_ALL : ""}
    >
      {userInfo && (
        <>
          <FormTitleDivider className={classes.formGroupDivider} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <H6>Your title</H6>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={YOUR_TITLE}
                value={userInfo.title}
                onChangeValue={setUserInfoField("title")}
                fullWidth
                error={highlightInfoFormErrors && !userInfo.title}
                helperText={highlightInfoFormErrors && !userInfo.title && REQUIRED}
              />
            </Grid>
          </Grid>
        </>
      )}
      <FormGroup className={userInfo ? classes.formGroup : ""}>
        <Grid container spacing={1}>
          {userInfo && (
            <Grid item xs={12}>
              <H6>{questionTitle}</H6>
            </Grid>
          )}
          {userInfo && (
            <>
              <Grid item xs={12}>
                {highlightInfoFormErrors && specialties.length === 0 && (
                  <Caption className={classes.errorRequired}>{REQUIRED}</Caption>
                )}
              </Grid>
              <Grid item xs={12}>
                <Caption className={classes.questionSubtext}>{SELECT_ALL}</Caption>
              </Grid>
            </>
          )}
          <Grid item xs={6} className={classes.grayAnswerText}>
            <Grid container>
              {evenSpecialties.map(({ id, label }) => (
                <Grid key={id} item xs={12}>
                  <Checkbox
                    key={id}
                    label={label}
                    checked={specialties.includes(id)}
                    onChange={(checked) => {
                      setChecked(id, checked);
                    }}
                    muiCheckboxProps={{
                      className: classes.checkbox,
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.grayAnswerText}>
            <Grid container>
              {oddSpecialties.map(({ id, label }) => (
                <Grid key={id} item xs={12}>
                  <Checkbox
                    key={id}
                    label={label}
                    checked={specialties.includes(id)}
                    onChange={(checked) => {
                      setChecked(id, checked);
                    }}
                    muiCheckboxProps={{
                      className: classes.checkbox,
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </FormGroup>
    </OnboardingQuestionCard>
  );
}

const useStyles = makeStyles((theme) => ({
  titleText: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
    display: "flex",
  },
  grayAnswerText: {
    color: colorsLight.font.secondary,
  },
  checkbox: {
    // tightens up the vertical spacing between checkboxes
    margin: theme.spacing(-0.5, 0),
  },
  questionSubtext: {
    paddingTop: theme.spacing(1),
    color: colorsLight.font.secondary,
    paddingBottom: theme.spacing(2),
  },
  errorRequired: {
    paddingTop: theme.spacing(1),
    color: theme.palette.error.dark,
    paddingBottom: theme.spacing(2),
  },
  formGroup: {
    marginTop: theme.spacing(3),
  },
  formGroupDivider: {
    marginTop: theme.spacing(1),
  },
}));
