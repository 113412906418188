import React from "react";
import { ServiceRequestResponse, UserResponse } from "@coherehealth/core-platform-api";
import ServiceRequestCardList from "../../DashboardPage/ServiceRequestCardList";
import { Card, colorsLight, ExistingAuthorizationModal, InlineButton } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core/styles";
import { H2, Subtitle2 } from "@coherehealth/design-system";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";

interface Props {
  open: boolean;
  onClose?: () => void;
  serviceRequests?: ServiceRequestResponse[];
  user?: UserResponse | null;
  onClickNewSR: () => void;
}

const useStyles = makeStyles((theme) => ({
  headerText: {
    textAlign: "center",
  },
  subHeaderText: {
    paddingTop: theme.spacing(1),
    color: theme.palette.text.tertiary,
    textAlign: "center",
  },
  cardStyle: {
    background: colorsLight.background.light,
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    paddingTop: theme.spacing(3),
  },
  headerContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  bodyContainer: {
    color: colorsLight.font.secondary,
    fontSize: "12px",
    lineHeight: "15px",
    marginBottom: theme.spacing(2),
  },
}));

export default function CheckExistingAuthorization({ open, onClose, serviceRequests, user, onClickNewSR }: Props) {
  const classes = useStyles();

  const header = () => (
    <div className={classes.headerContainer}>
      <H2 className={classes.headerText}>Would you like to continue an existing request?</H2>
      <Subtitle2 className={classes.subHeaderText}>
        This patient has existing service requests which are in draft and/or eligible for continuation of care
      </Subtitle2>
    </div>
  );

  const body = () => (
    <Card className={classes.cardStyle}>
      <div className={classes.bodyContainer}>{`${serviceRequests?.length} RESULTS:`}</div>
      <ServiceRequestCardList
        viewableServiceRequests={serviceRequests}
        error={false}
        displayMax={serviceRequests?.length || 0}
        loading={false}
        isExistingAuthorization={true}
        user={user}
      />
      <InlineButtonContainer>
        <InlineButton onClick={onClickNewSR}>I’d like to start a new service request</InlineButton>
      </InlineButtonContainer>
    </Card>
  );

  return (
    <ExistingAuthorizationModal
      open={open}
      onClose={onClose}
      header={header()}
      body={body()}
    ></ExistingAuthorizationModal>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const InlineButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  paddingTop: theme.spacing(1.25),
  paddingBottom: theme.spacing(4.25),
}));
