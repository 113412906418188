import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from "react";

import {
  ServiceRequestResponse,
  useUpdateServiceRequest,
  Patient,
  WithdrawRequestor,
  AuthStatus,
} from "@coherehealth/core-platform-api";
import { Body1, Body2, Caption, Card, H4, InlineButton, Tooltip, statusCopy } from "@coherehealth/common";
import { useAuthorized } from "authorization";
import { useTheme } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import PencilIcon from "@material-ui/icons/Create";

import { useSnackbar } from "notistack";

import { headerHeight } from "util/StyleConstants";

import { PatientEdit } from "components/FaxServiceRequest/PatientInfo";
import ServiceRequestFormSection from "components/FaxServiceRequest/ServiceRequestFormSection";
import ServiceRequestFormReadOnly from "components/PatientSummary/ServiceRequestSummaryCard/ServiceRequestFormReadOnly";

import { canBeWithdrawn } from "util/serviceRequest";
import { FaxStep } from "components/FaxServiceRequest";
import WithdrawModal from "components/PatientSummary/ServiceRequestSummaryCard/WithdrawModal";

import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import WithdrawButton from "components/ServiceRequest/WithdrawButton";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";
import { ServiceRequestStatusDisplay } from "components/ServiceRequestStatusDisplay/StatusBanner/ServiceRequestStatusDisplay";

interface Props {
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  setCanBeSubmitted: (b: boolean) => void;
  step: FaxStep;
  setStep: React.Dispatch<React.SetStateAction<FaxStep>>;
  attemptedSubmitService: boolean;
  serviceRequest: ServiceRequestResponse | undefined;
  setServiceRequest: React.Dispatch<React.SetStateAction<ServiceRequestResponse | undefined>>;
  setShowFooter: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  claimId: string;
  patient?: Patient;
  //only show a start date on a new request if available on the claim request otherwise no startDate
  clearDefaultStartDateValue?: boolean;
  allowCareTypeTranisition?: boolean;
}

export const RequestForm: FunctionComponent<Props> = ({
  formContent,
  setFormContent,
  setCanBeSubmitted,
  step,
  setStep,
  attemptedSubmitService,
  serviceRequest,
  setServiceRequest,
  setShowFooter,
  loading,
  claimId,
  patient,
  clearDefaultStartDateValue,
  allowCareTypeTranisition,
}) => {
  return (
    <MainContent>
      {patient && <PatientEdit patientId={patient.id} patientInput={patient} />}
      {patient && (
        <>
          {step === "SAVE_AND_REVIEW" ? (
            <ServiceRequestFormSection
              claimId={claimId}
              formContent={formContent}
              setFormContent={setFormContent}
              attemptedSubmitService={attemptedSubmitService}
              setCanBeSubmitted={setCanBeSubmitted}
              patient={patient}
              authStatus={serviceRequest?.authStatus || "DRAFT"}
              delegatedVendorName={serviceRequest?.delegatedVendor}
              isCohereFaxForm
              clearDefaultStartDateValue={clearDefaultStartDateValue}
              allowCareTypeTransition={allowCareTypeTranisition}
            />
          ) : (
            <ServiceRequestSummary
              setStep={setStep}
              serviceRequest={serviceRequest}
              setServiceRequest={setServiceRequest}
              setShowFooter={setShowFooter}
              loading={loading}
              claimId={claimId}
              patient={patient}
            />
          )}
        </>
      )}
    </MainContent>
  );
};

interface SummaryProps {
  setStep: React.Dispatch<React.SetStateAction<FaxStep>>;
  serviceRequest: ServiceRequestResponse | undefined;
  setServiceRequest: React.Dispatch<React.SetStateAction<ServiceRequestResponse | undefined>>;
  setShowFooter: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  claimId: string;

  patient: Patient;
}

const ServiceRequestSummary: FunctionComponent<SummaryProps> = ({
  setStep,
  serviceRequest,
  setServiceRequest,
  setShowFooter,
  loading,
  claimId,
  patient,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: patch, error: withdrawError } = useUpdateServiceRequest({
    id: serviceRequest?.id || "",
  });
  const { healthPlanName, encounterType, requestTiming, delegatedVendor } = serviceRequest || {};
  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName,
    encounterType,
    requestTiming,
    delegatedVendorName: delegatedVendor,
  });

  useEffect(() => {
    if (withdrawError) {
      enqueueSnackbar(`Error updating service request: ${withdrawError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, withdrawError]);
  const canEditWithdrawalRequestor = useAuthorized("EDIT_WITHDRAWAL_REQUESTOR");
  const onWithdraw =
    serviceRequest && canBeWithdrawn(serviceRequest)
      ? async (authStatus?: AuthStatus) => {
          const retVal = await patch({
            authStatus: authStatus ? authStatus : "WITHDRAWN",
            withdrawnReason,
            withdrawRequestor:
              serviceRequest?.withdrawRequestorOption && authStatus?.includes("WITHDRAWN")
                ? !canEditWithdrawalRequestor
                  ? "PROVIDER"
                  : withdrawRequestor
                : undefined,
          });
          setServiceRequest(retVal);
          setShowFooter(false);
        }
      : undefined;
  const theme = useTheme();

  const [withdrawnReason, setWithdrawnReason] = useState<string | undefined>(serviceRequest?.withdrawnReason);
  const [withdrawRequestor, setWithdrawRequestor] = useState<WithdrawRequestor | undefined>(
    serviceRequest?.withdrawRequestor
  );
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);

  return (
    <ServiceRequestCard>
      {serviceRequest && patient && (
        <>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ paddingBottom: 16 }}
            spacing={1}
          >
            {serviceRequest.authStatus !== "DRAFT" && (
              <Grid item xs={12}>
                <ServiceRequestStatusDisplay
                  onSubmission={(sr: ServiceRequestResponse) => {
                    setServiceRequest(sr);
                  }}
                  serviceRequest={serviceRequest}
                  actionConfiguration={{}}
                />
              </Grid>
            )}
            <Grid item>
              <H4 style={{ paddingBottom: 8 }}>Claim details</H4>
              <Caption>Claim number</Caption>
              <Body1>{claimId}</Body1>
            </Grid>
            <Grid item>
              {serviceRequest.authStatus === "DRAFT" && (
                <div>
                  <InlineButton
                    disabled={false}
                    startIcon={<PencilIcon />}
                    onClick={() => {
                      setStep("SAVE_AND_REVIEW");
                      setShowFooter(true);
                    }}
                  >
                    <Body2>Edit request</Body2>
                  </InlineButton>
                </div>
              )}
              <Tooltip
                title={
                  !canBeWithdrawn(serviceRequest)
                    ? `You cannot withdraw this service request when it has a status of ${
                        statusCopy(serviceRequest?.delegatedVendor, serviceRequest?.healthPlanName)[
                          serviceRequest.authStatus || "PENDING"
                        ]
                      }`
                    : ""
                }
                placement="top"
                arrow
              >
                <div>
                  <WithdrawButton
                    serviceRequest={serviceRequest}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      setWithdrawModalOpen(true);
                      event.stopPropagation();
                    }}
                    styles={{ paddingLeft: theme.spacing(4) }}
                  />
                </div>
              </Tooltip>
            </Grid>
          </Grid>
          <Divider />
          <ServiceRequestFormReadOnly
            serviceRequest={serviceRequest}
            isFacilityBasedAuth={facilityBasedFeatureEnabled}
          />
          {canBeWithdrawn(serviceRequest) && (
            <WithdrawModal
              open={withdrawModalOpen}
              onClose={() => {
                setWithdrawModalOpen(false);
                setWithdrawnReason("");
              }}
              onWithdraw={onWithdraw}
              withdrawnReason={withdrawnReason}
              setWithdrawnReason={setWithdrawnReason}
              withdrawRequestor={withdrawRequestor}
              setWithdrawRequestor={setWithdrawRequestor}
              submitting={loading}
              withdrawnReasonOptionsList={serviceRequest.reviewOutcomeWithdrawOptions}
              serviceRequestId={serviceRequest.id}
              serviceRequest={serviceRequest}
            />
          )}
        </>
      )}
    </ServiceRequestCard>
  );
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ServiceRequestCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const MainContent = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingTop: theme.spacing(3) + headerHeight,
  /* leave space for footer, (button top/bottom margin/padding + font height + extra spacing */
  paddingBottom: theme.spacing(8) + 20 + theme.spacing(5),
}));
