import { Grid, makeStyles } from "@material-ui/core";
import { ReferralRequestResponse } from "@coherehealth/core-platform-api";
import ReferralPreReviewContent from "./ReferralPreReviewContent";
import { useLocation } from "react-router";
import { useIsFaxReferralsWorkflow } from "util/attachmentUtil";
import { Card } from "@coherehealth/common";

interface ReferralReviewProps {
  referralRequest: ReferralRequestResponse;
  isAdministrative: boolean;
}

export default function ReviewContainer({ referralRequest, isAdministrative }: ReferralReviewProps) {
  const location = useLocation();
  const isFaxReferralsFlow = useIsFaxReferralsWorkflow(location);
  const classes = useStyles({ isFaxReferralsFlow });
  return (
    <div className={classes.includeRequestorSpace}>
      <div className={classes.innerContainer}>
        <Grid key={referralRequest.id} item xs>
          <Card
            className={classes.referralRequestCardContainer}
            noBorder={isFaxReferralsFlow}
            noBoxShadow={isFaxReferralsFlow}
          >
            <ReferralPreReviewContent referralRequest={referralRequest} isAdministrative={isAdministrative} />
          </Card>
        </Grid>
      </div>
    </div>
  );
}

interface StyleProps {
  isFaxReferralsFlow?: boolean;
}

const useStyles = makeStyles((theme) => ({
  innerContainer: ({ isFaxReferralsFlow }: StyleProps) => ({
    display: "flex",
    flexDirection: "column",
    paddingTop: isFaxReferralsFlow ? theme.spacing(0.25) : "40px",
    paddingBottom: isFaxReferralsFlow ? theme.spacing(4.75) : theme.spacing(0),
  }),
  referralRequestCardContainer: ({ isFaxReferralsFlow }: StyleProps) => ({
    padding: isFaxReferralsFlow ? theme.spacing(3, 0, 0, 0) : theme.spacing(3),
  }),
  includeRequestorSpace: {
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(10),
  },
}));
