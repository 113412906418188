// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Box, Grid, makeStyles, styled, useTheme } from "@material-ui/core";
import UnitTextField, { UnitProps } from "./UnitTextField";
import StartDateSelect, { StartDateProps } from "./StartDateSelect";
import EndDateSelect, { EndDateProps } from "./EndDateSelect";
import {
  AuthStatus,
  FormConfigurationFieldSpec,
  OscarServiceType,
  ServiceRequestResponse,
} from "@coherehealth/core-platform-api";
import { Body3, Caption, colorsLight, H4, today } from "@coherehealth/common";
import { DateSelectWidth, FullUnitsWidth } from "common/SharedServiceRequestFormComponents";
import { checkOnetimeRequestCoverage, checkRecurringRequestCoverage, CoverageCheck } from "util/patientUtils";
import useAuthValidityWindowText from "hooks/useAuthValidityWindowText";
import { PreviousUnitProps, PreviousUnitTextField } from "./PreviousUnitTextField";
import { useRef } from "react";
import { SRFormConfigFieldWrapper } from "common/FormConfigUtils";

type ServiceFrequencySectionProps = UnitProps &
  PreviousUnitProps &
  StartDateProps &
  EndDateProps & {
    shouldShowEndDate: boolean;
    isInpatient: boolean;
    serviceType?: OscarServiceType;
    serviceRequestId?: string;
    cohereAuthId?: string;
    formConfigurations: {
      units?: { fieldSpec: FormConfigurationFieldSpec };
      startEndDate?: { fieldSpec: FormConfigurationFieldSpec };
    };
    isContinuation?: boolean;
    mainText?: string;
    caption?: string;
    description?: string;
    onPatientSummary?: boolean;
    authStatus: AuthStatus;
    serviceRequest?: ServiceRequestResponse;
    unitsDisabled?: boolean;
    startEndDateDisabled?: boolean;
  };

interface StyleProps {
  hasCaption: boolean;
}

export default function ServiceFrequencySection(props: ServiceFrequencySectionProps) {
  const {
    startDate,
    isInpatient,
    serviceType,
    shouldShowEndDate,
    formConfigurations,
    endDate,
    patient,
    isContinuation,
    endDateLabel,
    mainText,
    caption,
    description,
    onPatientSummary = true,
    authStatus,
    serviceRequest,
    unitsDisabled,
    startEndDateDisabled,
  } = props;
  const { message: authWindowText } = useAuthValidityWindowText({
    patientId: patient?.id,
    startDate,
    isInpatient,
    serviceType,
  });
  const { spacing } = useTheme();
  const hasCaption = !!caption;
  const classes = useStyles({ hasCaption });

  let coverageCheck: CoverageCheck = { inRange: true };
  let hasCoverageError: boolean | undefined = false;
  if (formConfigurations?.startEndDate?.fieldSpec && formConfigurations?.startEndDate?.fieldSpec !== "OMIT") {
    const memberCoverages = patient?.coverages || (patient?.coverage ? [patient.coverage] : undefined);
    const date = startDate || today();
    if (memberCoverages) {
      if (shouldShowEndDate && endDate) {
        coverageCheck = Boolean(endDate)
          ? checkRecurringRequestCoverage(memberCoverages, date, endDate)
          : { inRange: true };
      } else {
        coverageCheck = checkOnetimeRequestCoverage(memberCoverages, date);
      }
    }
    hasCoverageError = coverageCheck && !coverageCheck.inRange;
  }

  // make the additional units field empty when continuation is initially created
  let shouldBeEmpty = useRef({ renderCount: 0, empty: props.units === "0" });
  const updateRenderCount = () => {
    shouldBeEmpty.current.renderCount += 1;
  };
  const modifiedUnitsProps = { ...props, updateRenderCount };
  if (shouldBeEmpty.current.empty && shouldBeEmpty.current.renderCount === 0) {
    modifiedUnitsProps.units = "";
  }

  if (isInpatient) {
    return null;
  }
  return (
    <>
      <div className={classes.captionContainer}>
        {caption && <Caption className={classes.serviceCategoryCaption}>{caption || ""}</Caption>}
        {mainText ? (
          <H4 className={classes.serviceName}>{mainText}</H4>
        ) : (
          <H4 style={{ marginTop: spacing(4), marginBottom: spacing(1), marginLeft: spacing(1) }}>Service details</H4>
        )}
        {description && <Body3 className={classes.serviceDescription}>{description}</Body3>}
      </div>
      <Section>
        {isContinuation && (
          <SRFormConfigFieldWrapper {...formConfigurations?.units}>
            <UnitContainer item style={{ paddingLeft: 0 }}>
              <PreviousUnitTextField {...props} />
            </UnitContainer>
          </SRFormConfigFieldWrapper>
        )}
        {!isContinuation && (
          <SRFormConfigFieldWrapper {...formConfigurations?.units}>
            <UnitContainer item style={{ paddingLeft: 0 }}>
              <UnitTextField {...props} isContinuationWorkflow={isContinuation} disabled={unitsDisabled} />
            </UnitContainer>
          </SRFormConfigFieldWrapper>
        )}
        {isContinuation && (!onPatientSummary || authStatus === "PENDING") && (
          <SRFormConfigFieldWrapper {...formConfigurations?.units}>
            <UnitContainer
              item
              style={{ paddingRight: 0, paddingLeft: formConfigurations?.units?.fieldSpec === "OMIT" ? 0 : undefined }}
            >
              <UnitTextField {...modifiedUnitsProps} isContinuationWorkflow={isContinuation} disabled={unitsDisabled} />
            </UnitContainer>
          </SRFormConfigFieldWrapper>
        )}
        {!isInpatient && (
          <StartDateContainer
            item
            style={{ paddingRight: 0, paddingLeft: formConfigurations?.units?.fieldSpec === "OMIT" ? 0 : undefined }}
          >
            <StartDateSelect
              {...props}
              coverageCheck={coverageCheck}
              hasCoverageError={hasCoverageError}
              disabled={!onPatientSummary || startEndDateDisabled}
              serviceRequest={serviceRequest}
            />
          </StartDateContainer>
        )}
        {shouldShowEndDate && !isInpatient ? (
          <>
            <Box component="span" m="20px 0 0" display="flex" justifyContent="center" width={spacing(1)}>
              -
            </Box>
            <EndDateContainer>
              <EndDateSelect
                {...props}
                hasCoverageError={hasCoverageError}
                endDateLabel={endDateLabel}
                disabled={startEndDateDisabled}
              />
            </EndDateContainer>
          </>
        ) : (
          startDate && (
            <OneTimeDateNote>
              {authWindowText}
              <br /> No action needed if service occurs between these dates
            </OneTimeDateNote>
          )
        )}
      </Section>
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Section = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: theme.spacing(1),
  paddingLeft: "0px",
  width: "100%",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const UnitContainer = styled(Grid)(({ theme }) => ({
  width: FullUnitsWidth,
  marginRight: theme.spacing(1.5),
  marginBottom: theme.spacing(0.5),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StartDateContainer = styled(Grid)(({ theme }) => ({
  width: DateSelectWidth,
  marginLeft: theme.spacing(1.5),
  marginRight: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const EndDateContainer = styled("div")(({ theme }) => ({
  width: DateSelectWidth,
  marginLeft: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const OneTimeDateNote = styled(Caption)(({ theme }) => ({
  color: theme.palette.text.secondary,
  paddingLeft: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  height: 56,
  maxWidth: "33.33%",
  width: 500,
}));

const useStyles = makeStyles((theme) => ({
  captionContainer: {
    justifyContent: ({ hasCaption }: StyleProps) => (hasCaption ? "space-between" : "center"),
    marginBottom: theme.spacing(2),
    marginTop: ({ hasCaption }: StyleProps) => (hasCaption ? theme.spacing(4) : "0px"),
  },
  serviceName: {
    marginTop: ({ hasCaption }: StyleProps) => (hasCaption ? "8px" : "40px"),
  },
  serviceCategoryCaption: {
    color: colorsLight.font.light,
    fontFamily: "Gilroy-Regular",
  },
  serviceDescription: {
    paddingTop: theme.spacing(1),
    maxHeight: 50,
    color: colorsLight.font.light,
    fontFamily: "Gilroy-Medium",
  },
}));
