import { useEffect, useState } from "react";
import { Application } from "./trackConfig";
import TrackRoute, { TrackRouteProps } from "./TrackRoute";
import { matchPath, useLocation } from "react-router-dom";
import config from "./trackConfig";

export default function TrackUsage({ baseUrl, application }: { baseUrl: string; application: Application }) {
  const location = useLocation();
  const path = location.pathname;
  const search = location.search;
  const [trackProps, setProps] = useState<TrackRouteProps>();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const caseIdParams = searchParams.get("caseId");
    for (const [trackPath, currentConfig] of Object.entries(config)) {
      if (currentConfig.active && currentConfig.application === application) {
        const pattern = matchPath({ path: trackPath }, path);
        if (pattern) {
          const queryParamNames = Array.from(searchParams.keys());
          const requiredParamsMet = currentConfig.requiredParams?.every((elem) => queryParamNames.includes(elem));
          if (!currentConfig.requiredParams || requiredParamsMet) {
            const caseIdPath = pattern.params.caseId;
            const queueIdParams = searchParams.get("queueId") || undefined;
            const reviewIdParams = searchParams.get("reviewId") || undefined;
            setProps({
              baseUrl,
              application,
              component: currentConfig.component,
              category: currentConfig.category,
              serviceRequestId: pattern.params.serviceRequestId,
              caseId: caseIdPath || caseIdParams,
              queueId: queueIdParams,
              reviewId: reviewIdParams,
            });
            return;
          }
        }
      }
    }
    setProps(undefined);
  }, [application, baseUrl, path, search]);

  return trackProps ? <TrackRoute {...trackProps} /> : null;
}
