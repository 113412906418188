import { Body1, H2, SecondaryButton } from "@coherehealth/common";
import MuiLink from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import ServiceRequestExclamation from "components/images/ServiceRequestExclamation.svg";

import { CommonFaxSidebarViewProps, backToMenuView } from "./common";
import { useGenerateFaxWorkflowRedirectPath } from "./AttachToServiceRequest/ExistingServiceRequestResults";
import { Patient } from "@coherehealth/core-platform-api";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "inherit",
  },
  centered: {
    margin: "auto 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: theme.spacing(10),
  },
  headerImage: {
    filter: "drop-shadow(0px 2px 2px rgba(207, 226, 231, 0.8))",
  },
  header: {
    padding: theme.spacing(4, 0, 1),
  },
  backButton: {
    margin: theme.spacing(3, 0),
    alignSelf: "flex-end",
  },
}));

interface Props extends CommonFaxSidebarViewProps {
  selectedPatient: Patient | undefined;
  ocrServiceRequestId: string;
}

export default function CreateServiceRequestConfirmationSidebar({
  selectedPatient,
  ocrServiceRequestId,
  setSidebarView,
  serviceCase,
}: Props) {
  const classes = useStyles();
  const faxWorkflowRedirectPath = useGenerateFaxWorkflowRedirectPath(
    selectedPatient,
    ocrServiceRequestId,
    serviceCase?.specialty
  );

  return (
    <div className={classes.container}>
      <div className={classes.centered}>
        <img alt="" src={ServiceRequestExclamation} />
        <H2 className={classes.header}>Complete request in new window</H2>
        <Body1>A new service request has been started in a new window</Body1>
        <MuiLink variant="body1" color="primary" underline="none" href={faxWorkflowRedirectPath} target="_blank">
          Click here to open the draft again
        </MuiLink>
      </div>
      <SecondaryButton fullWidth className={classes.backButton} onClick={() => setSidebarView(backToMenuView)}>
        Back
      </SecondaryButton>
    </div>
  );
}
