import { makeStyles } from "@material-ui/core/styles";
import { Card } from "@coherehealth/common";
import DuplicateServiceRequestSummary from "../AuthBuilder/GatherRequirements/DuplicateServiceRequestSummary";
import ServiceRequestSummary from "./ServiceRequestSummary";
import React, { useState } from "react";
import {
  ServiceRequestCreatePayload,
  ServiceRequestSearchResponse,
  UserResponse,
} from "@coherehealth/core-platform-api";
import { ServiceRequestFormContent } from "../../common/SharedServiceRequestFormComponents";

const useStyles = makeStyles((theme) => ({
  summaryCard: ({
    isExistingAuthorization,
    cardStyles,
  }: {
    isExistingAuthorization?: boolean;
    cardStyles?: AdditionalStyles;
  }) => ({
    marginBottom: isExistingAuthorization ? theme.spacing(2) : theme.spacing(3),
    padding: theme.spacing(3),
    borderRadius: `${theme.spacing(0.5)}px !important`,
    "&:hover": {
      boxShadow: "0px 4px 13px rgba(0,0,0,0.08)",
    },
    border: "none",
    ...cardStyles,
  }),
}));

interface Props {
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onEdit?: Function;
  serviceRequest: ServiceRequestSearchResponse;
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onDeleteDraft?: Function;
  isExistingAuthorization?: boolean;
  user?: UserResponse | null;
  cardStyles?: object;
  gatherRequirements?: boolean;
  searchForPotentialDuplicates?: (srPayload: ServiceRequestCreatePayload) => Promise<boolean>;
  workflowId?: string;
  serviceRequestFormContents?: ServiceRequestFormContent[];
  getPotentialDuplicatesLoading?: boolean;
  onPotentialDuplicatesClose?: () => void;
}

const ServiceRequestCard = ({
  gatherRequirements,
  onEdit,
  serviceRequest,
  onDeleteDraft,
  isExistingAuthorization,
  cardStyles,
  user,
  searchForPotentialDuplicates,
  workflowId,
  serviceRequestFormContents,
  getPotentialDuplicatesLoading,
  onPotentialDuplicatesClose,
}: Props) => {
  const [hover, setHover] = useState(false);
  const classes = useStyles({ isExistingAuthorization, cardStyles });
  const mouseEnter = () => {
    // do not show hover state for duplicate service requests
    !gatherRequirements && setHover(true);
  };
  const mouseLeave = () => {
    setHover(false);
  };
  return (
    <Card withHoverStyles className={classes.summaryCard} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      {gatherRequirements ? (
        <DuplicateServiceRequestSummary
          onEdit={onEdit}
          serviceRequest={serviceRequest}
          hover={hover}
          setHover={setHover}
          onDeleteDraft={onDeleteDraft}
          isExistingAuthorization={isExistingAuthorization}
          user={user}
          searchForPotentialDuplicates={searchForPotentialDuplicates}
          workflowId={workflowId}
          serviceRequestFormContents={serviceRequestFormContents}
          getPotentialDuplicatesLoading={getPotentialDuplicatesLoading}
          onPotentialDuplicatesClose={onPotentialDuplicatesClose}
        />
      ) : (
        <ServiceRequestSummary
          onEdit={onEdit}
          serviceRequest={serviceRequest}
          hover={hover}
          setHover={setHover}
          onDeleteDraft={onDeleteDraft}
          isExistingAuthorization={isExistingAuthorization}
          user={user}
        />
      )}
    </Card>
  );
};

interface AdditionalStyles {
  border?: string;
  boxShadow?: string;
}

export default ServiceRequestCard;
