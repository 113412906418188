import { ArrayElements, Body1, H1, H6, PrimaryButton, TextField, useMuiContainerStyles } from "@coherehealth/common";
import { useAddRoleToUsers, useRemoveRoleFromUsers, useUpdateUserOpsGroups } from "@coherehealth/core-platform-api";
import { Collapse, Grid, makeStyles, MenuItem } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import csvParse from "csv-parse/lib/browser/sync";
import csvStringify from "csv-stringify/lib/browser/sync";
import isObject from "lodash/isObject";
import reduce from "lodash/reduce";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { assertIsApiError } from "util/api";
import { headerHeight } from "util/StyleConstants";
import HeaderContainer from "../AppHeader/HeaderContainer";

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    overflowY: "hidden",
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(5) + headerHeight,
  },
}));

const UserManagementActions = ["ADD_ROLE", "REMOVE_ROLE", "CHANGE_OPS_GROUP"] as const;
type UserManagementAction = ArrayElements<typeof UserManagementActions>;
function isUserManagementAction(ipt: string): ipt is UserManagementAction {
  return UserManagementActions.includes(ipt as UserManagementAction);
}

export default function OpsUserManagementPage() {
  const classes = useStyles();
  const containerClasses = useMuiContainerStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [fileContents, setFileContents] = useState<Record<string, any>[]>();
  const [mode, setMode] = useState<UserManagementAction>("ADD_ROLE");
  const [loginField, setLoginField] = useState("login");
  const [userRoleInput, setUserRoleInput] = useState("");
  const [userOpsGroupInput, setUserOpsGroupInput] = useState("");

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    if (input && input.files && input.files[0]) {
      const file = input.files[0];

      assertIsFile(file);

      try {
        const contents = await file.text();

        setFileContents(csvParse(contents, { columns: true }));
      } catch (e) {
        assertIsApiError(e);
        enqueueSnackbar(`Failed to parse contents: ${e.message}`, { variant: "error" });
      }
    }
  };
  const [results, setResults] = useState<{
    successCount: number;
    successCsvBlobUrl: string;
    failedCsvBlobUrl: string;
    failedCount: number;
  }>();

  const { mutate: updateUserOpsGroups } = useUpdateUserOpsGroups({});
  const { mutate: addRoleToUsers } = useAddRoleToUsers({});
  const { mutate: removeRoleFromUsers } = useRemoveRoleFromUsers({});

  function validatedLogins(contents: Array<Record<string, any>> | undefined): string[] {
    if (!contents || contents.length < 1) {
      throw new Error("Empty file, cannot proceed");
    }
    return contents.map((val, index) => {
      if (!val[loginField]) {
        throw new Error(`Missing login field [${loginField}] from record index ${index}`);
      }

      return val[loginField];
    });
  }

  const [submitting, setSubmitting] = useState(false);
  const onSubmitAction = async () => {
    setSubmitting(true);
    setResults(undefined);
    try {
      const logins = validatedLogins(fileContents);
      const res = await (mode === "CHANGE_OPS_GROUP"
        ? updateUserOpsGroups({
            newOpsGroup: userOpsGroupInput,
            logins,
          })
        : mode === "ADD_ROLE"
        ? addRoleToUsers({
            role: userRoleInput,
            logins,
          })
        : removeRoleFromUsers({
            role: userRoleInput,
            logins,
          }));
      setResults({
        successCount: res.successfulUpdates.length,
        successCsvBlobUrl: objectsToBlobUrl(res.successfulUpdates),
        failedCount: res.failedUpdates.length,
        failedCsvBlobUrl: objectsToBlobUrl(res.failedUpdates),
      });
    } catch (e) {
      // This generally shouldn't happen: these bulk APIs should return results in a structured form even when there is no success
      console.error(e);
      assertIsApiError(e);
      enqueueSnackbar(`Encountered error making this change: ${e.message}`);
    }
    setSubmitting(false);
  };

  return (
    <div className={classes.pageWrapper}>
      <Container classes={containerClasses} maxWidth="lg">
        <HeaderContainer height={headerHeight}>
          <H1>Ops user management</H1>
        </HeaderContainer>
        <div className={classes.mainContent}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Body1>
                Select the mode and upload a CSV containing a login column (which is usually an email address). Other
                columns are ignored. You may change the selected header name below.
              </Body1>
            </Grid>
            <Grid item xs={12}>
              <Body1>Results will be available as a downloadable CSV when complete.</Body1>
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                fullWidth
                label="Mode"
                value={mode}
                onChange={(event) => {
                  if (isUserManagementAction(event.target.value)) {
                    setMode(event.target.value);
                  }
                }}
              >
                <MenuItem value="ADD_ROLE">Add role</MenuItem>
                <MenuItem value="REMOVE_ROLE">Remove role</MenuItem>
                <MenuItem value="CHANGE_OPS_GROUP">Change ops group</MenuItem>
              </TextField>
            </Grid>
            {(mode === "ADD_ROLE" || mode === "REMOVE_ROLE") && (
              <Grid item xs={6}>
                <TextField label="Role" value={userRoleInput} onChangeValue={setUserRoleInput} />
              </Grid>
            )}
            {mode === "CHANGE_OPS_GROUP" && (
              <Grid item xs={6}>
                <TextField label="Ops group" value={userOpsGroupInput} onChangeValue={setUserOpsGroupInput} />
              </Grid>
            )}
            <Grid item xs={6}>
              <input accept=".csv" data-testid="csv-choose-file-button" type="file" onChange={onFileChange} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Login field (usually an email address)"
                value={loginField}
                onChangeValue={setLoginField}
              />
            </Grid>
            <Grid item xs={6}>
              <PrimaryButton
                data-testid="upload-button"
                disabled={!fileContents || submitting}
                onClick={() => {
                  onSubmitAction();
                }}
              >
                Start upload{fileContents ? ` (${fileContents.length} records)` : ""}
              </PrimaryButton>
            </Grid>
            <Collapse in={Boolean(results)}>
              <Grid item xs={12}>
                <H6>Results</H6>
                <a
                  href={results?.successCsvBlobUrl || "#"}
                  target="_blank"
                  rel="noreferrer"
                  download="success-results.csv"
                >
                  Successes ({results?.successCount}) as csv
                </a>
                <div />
                <a
                  href={results?.failedCsvBlobUrl || "#"}
                  target="_blank"
                  rel="noreferrer"
                  download="failed-results.csv"
                >
                  Failures ({results?.failedCount}) as csv
                </a>
              </Grid>
            </Collapse>
          </Grid>
        </div>
      </Container>
    </div>
  );
}

function assertIsFile(ipt: File | string | null): asserts ipt is File {
  if (!ipt || typeof ipt === "string") {
    throw new Error("Not a file");
  }
}

function flattenObject(obj: Record<string, any>, prefix = ""): Record<string, string> {
  return reduce(
    obj,
    (acc, value, key) => {
      const nestedPrefix = prefix ? `${prefix}.${key}` : key;
      if (isObject(value)) {
        return { ...acc, ...flattenObject(value, nestedPrefix) };
      } else {
        return { ...acc, [nestedPrefix]: value };
      }
    },
    {}
  );
}
function objectsToCsv(data: Record<string, any>[]) {
  const flattenedData = data.map((d) => flattenObject(d));
  return csvStringify(flattenedData, { header: true });
}

function objectsToBlobUrl(data: Record<string, any>[]) {
  const resultsCsv = objectsToCsv(data);
  const resultsBlob = new Blob([resultsCsv], { type: "text/csv;charset=utf-8" });
  return URL.createObjectURL(resultsBlob);
}
