import React, { FunctionComponent, useState } from "react";
import { useAuthorized } from "authorization";
import {
  useFeatureFlagCurrentValues,
  FeatureFlagPageComponent,
  NotFoundPage,
  LocalFeatureFlagProps,
} from "@coherehealth/common";
import {
  useUpdateSimpleFeatureFlag,
  useGetFeatureFlagAdminView,
  useDownloadReleaseLimitedOrganizations,
} from "@coherehealth/core-platform-api";
import config from "../api/config";
import DropServiceRequestCollectionComponent from "./DropServiceRequestCollectionComponent";
import { store } from "@coherehealth/common";

const FeatureFlagPage: FunctionComponent = () => {
  const [localFeatureFlags, setLocalFeatureFlags] = useState<LocalFeatureFlagProps | undefined>(
    store.get("localFeatureFlags")
  );

  const updateLocalFeatureFlags = (update: LocalFeatureFlagProps) => {
    store.set("localFeatureFlags", update);
    setLocalFeatureFlags(update);
  };

  const canDropServiceRequestCollection =
    useAuthorized("CAN_DELETE_SERVICE_REQUEST_COLLECTION") && config.CAN_DELETE_SERVICE_REQUEST_COLLECTION;
  const canViewPage = useAuthorized("FEATURE_FLAG_PAGE");
  // Fetches and caches a simple list of feature flags and their derived on/off values
  const flagCurrentValues = useFeatureFlagCurrentValues();

  const { mutate: updateFeatureFlag, error: updateFeatureFlagError } = useUpdateSimpleFeatureFlag({});

  const { mutate: downloadReleaseLimitedOrganizations, error: downloadReleaseLimitedOrganizationsError } =
    useDownloadReleaseLimitedOrganizations({});

  // Fetches all the server feature flag overrides WITH additional admin-only data
  const { data: featureFlagsAdminView, refetch: refetchFeatureFlagsAdminView } = useGetFeatureFlagAdminView({});

  if (!canViewPage) {
    return <NotFoundPage />;
  }

  const DropSRCollectionComponent = canDropServiceRequestCollection ? <DropServiceRequestCollectionComponent /> : null;

  return (
    <FeatureFlagPageComponent
      dropServiceRequestCollectionComponent={DropSRCollectionComponent}
      flagCurrentValues={flagCurrentValues}
      flagAdditionalData={featureFlagsAdminView || []}
      downloadReleaseLimitedOrganizations={downloadReleaseLimitedOrganizations}
      downloadReleaseLimitedOrganizationsError={downloadReleaseLimitedOrganizationsError}
      updateFeatureFlag={updateFeatureFlag}
      updateFeatureFlagError={updateFeatureFlagError}
      refetchFeatureFlagsAdminView={refetchFeatureFlagsAdminView}
      localFeatureFlags={localFeatureFlags}
      updateLocalFeatureFlags={updateLocalFeatureFlags}
    />
  );
};

export default FeatureFlagPage;
