import { useEffect, useState } from "react";
import { ReviewType } from "@coherehealth/core-platform-api";
import {
  UserClickInfoClinicalReviewPage,
  getEmptyUserClickTracking,
  Body2,
  useGetServiceRequestByIdWithFallback,
} from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { useAuthorized } from "authorization";
import LoadingDialog from "../LoadingDialog";
import { useFeature } from "@coherehealth/common";
import SlimHeader from "../SlimHeader";
import ClinicalReviewShell from "common/ClinicalReviewShell";
import { GetDataError } from "restful-react";
import ViewOnlyReviewLeftPanel from "./ViewOnlyReviewLeftPanel";
import config from "api/config";
import { useGetServiceRequestCases, useGetServiceCase, ServiceCase } from "@coherehealth/qm-api";
import QueueSupervisorSidebar from "components/DocumentViewer/FaxAttachment/QueueSupervisorSidebar";
import { LeftPanelTabs, useAuthorizationFetch } from "../reviewUtils/utils";
import { useAttachments } from "../reviewUtils/useAttachment";
import { UseClaimHistoryProps } from "components/ClaimHistory/useClaimHistory";
import ReadmissionBanner from "common/ReadmissionBanner";
import PurpleClipboard from "components/images/PurpleClipboard";
import { UseOtherAuthorizationProps } from "../ClinicalReviewInfoPanel/OtherServiceRequests/useOtherAuthorization";
import { LeftReviewTabs } from "../reviewUtils/LeftReviewTabs";
import { ClinicalReviewContext, ClinicalReviewContextType } from "../Review/ClinicalReviewPage";
import useFetchReviewOutcomes from "components/ServiceRequest/ReviewSection/common/hooks/useFetchReviewOutcomes";

interface ClinicalPageProps extends UseOtherAuthorizationProps, UseClaimHistoryProps {
  existingReviews: ReviewType[] | null;
  existingReviewsError: GetDataError<unknown> | null;
  loadingExistingReviews: boolean;
  caseId: string | undefined;
  serviceRequestId: string;
  trackingNumberSearch?: boolean;
  isReviewQueue?: boolean;
}
export default function ViewOnlyReviewPage({
  existingReviews,
  existingReviewsError,
  loadingExistingReviews,
  caseId,
  serviceRequestId,
  trackingNumberSearch,
  isReviewQueue,
  otherAuthsProps,
  claimHistoryProps,
}: ClinicalPageProps) {
  const notificationClasses = useBannerStyles();
  const [leftColumnTab, setLeftColumnTab] = useState<LeftPanelTabs>(
    serviceRequestId ? "REQUEST_INFORMATION" : "ATTACHMENTS"
  );
  const [userClickInfoTracking, setUserClickInfoTracking] = useState<UserClickInfoClinicalReviewPage>(
    getEmptyUserClickTracking()
  );
  const [isReadmissionBannerOpen, setIsisReadmissionBannerOpen] = useState<boolean>(true);
  const isReadmissionFeatureEnabled = useFeature("readmissionIdentification");
  const postDecisionP2PExpanded = useFeature("postDecisionP2PExpanded");
  const claimHistoryFeature = useFeature("claimHistoryFeature");
  const canViewNurseReviewerCase = useAuthorized("VIEW_NURSE_REVIEWER_CASE");
  const canViewMDReviewerCase = useAuthorized("VIEW_MD_REVIEWER_CASE");
  const canViewLetterWritingCase = useAuthorized("VIEW_LETTER_WRITING_CASE");
  const canViewOutreachCase = useAuthorized("VIEW_OUTREACH_CASE");
  const genericNotesEnabled = useFeature("genericNotes");
  const viewNoteAuthorized = useAuthorized("VIEW_NOTES");
  const canViewGenericNotes = genericNotesEnabled && viewNoteAuthorized;
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: serviceRequest,
    error: serviceRequestFetchError,
    loading: serviceRequestLoading,
    refetch: serviceRequestRefetch,
  } = useGetServiceRequestByIdWithFallback({
    id: serviceRequestId || "none",
    lazy: true,
  });
  const {
    data: serviceCases,
    error: getServiceRequestCasesError,
    refetch: getServiceCases,
  } = useGetServiceRequestCases({
    id: serviceRequestId,
    base: `${config.QM_SERVICE_API_URL}`,
    lazy: true,
  });

  const {
    data: initialServiceCase,
    loading: serviceCaseLoading,
    error: getServiceCaseError,
    refetch: getServiceCase,
  } = useGetServiceCase({
    id: caseId || "none",
    base: `${config.QM_SERVICE_API_URL}`,
    lazy: true,
  });

  const serviceCase: ServiceCase | null =
    serviceCases?.find((item) => item.id === caseId) || initialServiceCase || null;
  const [supervisorServiceCase, setSupervisorServiceCase] = useState<ServiceCase | null>(serviceCase);

  let readmissionBannerHeader = "";
  let readmissionBannerBodyText = "";
  if (
    serviceRequest?.authStatus === "POST_DENIAL_PEER_TO_PEER" &&
    serviceRequest?.initialDecisionDisposition === "APPROVED"
  ) {
    readmissionBannerHeader = "This SR was approved, but was indicated to be a readmission";
    readmissionBannerBodyText =
      "The readmission decision may be upheld or overturned, but the auth status will stay approved";
  }

  useEffect(() => {
    if (serviceCase) {
      setSupervisorServiceCase(serviceCase);
    }
  }, [serviceCase]);
  useEffect(() => {
    if (serviceRequestId) {
      serviceRequestRefetch();
    }
  }, [serviceRequestId, serviceRequestRefetch]);
  useEffect(() => {
    if (serviceRequest && config.HAS_QM_ENVIRONMENT) {
      getServiceCases();
    }
  }, [getServiceCases, serviceRequest]);
  useEffect(() => {
    if (caseId && (isReviewQueue || !serviceRequestId || serviceRequestFetchError)) {
      getServiceCase();
    }
  }, [caseId, getServiceCase, serviceRequestFetchError, serviceRequestId, isReviewQueue]);

  useEffect(() => {
    if (existingReviewsError && serviceRequestId) {
      enqueueSnackbar(`Failed to load reviews: ${existingReviewsError.message}`, { variant: "error" });
    }
    if (serviceRequestFetchError && serviceRequestId) {
      enqueueSnackbar(`Failed to load service request: ${serviceRequestFetchError.message}`, { variant: "error" });
    }
    if (getServiceCaseError && serviceRequestId) {
      enqueueSnackbar(`Failed to load service request: ${getServiceCaseError.message}`, { variant: "error" });
    }
    if (getServiceRequestCasesError && caseId) {
      enqueueSnackbar(`Error fetching service case: ${getServiceRequestCasesError.message}`, { variant: "error" });
    }
  }, [
    caseId,
    enqueueSnackbar,
    existingReviewsError,
    serviceRequestFetchError,
    getServiceRequestCasesError,
    serviceRequestId,
    getServiceCaseError,
  ]);

  const { authorization, fetchAuthorization, authorizationFetchLoading } = useAuthorizationFetch(serviceRequest);

  const {
    attachments,
    attachmentListFetchLoading,
    appealsForAuth,
    onSwitchAttachmentTab,
    onViewAttachment,
    previousAttachmentsExpanded,
    setPreviousAttachmentsExpanded,
    attachmentIndexOpen,
    currentPage,
    zoomLevel,
    currentRotate,
    attachmentsInfo,
    setAttachmentsInfo,
    newAttachmentsNumber,
  } = useAttachments({
    serviceRequest,
    authorization,
    faxId: serviceRequest ? undefined : serviceCase?.faxDetail?.attachmentId, //don't want to fetch faxAttachment with faxId when serviceRequest is present
    existingReviews: existingReviews || [],
    currentCase: serviceCase ? serviceCase : undefined,
    isCaseLoading: serviceCaseLoading,
  });

  useEffect(() => {
    if (!serviceRequestId || (serviceCase && serviceRequest && !trackingNumberSearch && !isReviewQueue)) {
      setLeftColumnTab("ATTACHMENTS");
    } else {
      setLeftColumnTab("REQUEST_INFORMATION");
    }
  }, [isReviewQueue, serviceCase, serviceRequest, serviceRequestId, setLeftColumnTab, trackingNumberSearch]);

  const { allowedReviewOutcomes } = useFetchReviewOutcomes({
    serviceRequestId: serviceRequest?.id,
    reviewPageVersion: "ViewOnlyPage",
  });

  if (!useAuthorized("VIEW_REVIEW")) {
    return null;
  }

  const clinicalReviewContext: ClinicalReviewContextType = {
    latestReviewLeadingToDenial: null,
    latestChangeRequest: null,
    isMultiCoverageReviewRequired: serviceRequest?.isMultiCoverageReviewRequired || false,
    allowedReviewOutcomes,
  };

  return (
    <ClinicalReviewContext.Provider value={clinicalReviewContext}>
      <ClinicalReviewShell
        attachmentView={leftColumnTab === "ATTACHMENTS"}
        header={<SlimHeader serviceRequest={serviceRequest} viewOnly={true} />}
        leftColumnTabs={
          <LeftReviewTabs
            leftColumnTab={leftColumnTab}
            setLeftColumnTab={setLeftColumnTab}
            onSwitchAttachmentTab={onSwitchAttachmentTab}
            newAttachmentsNumber={newAttachmentsNumber}
            showClaimsHistoryTab={claimHistoryFeature && !!serviceRequestId}
            showRequestInfoTab={!!serviceRequestId}
          />
        }
        leftColumn={
          <>
            {isReadmissionFeatureEnabled &&
              serviceRequest?.isReadmission &&
              serviceRequest?.encounterType === "INPATIENT" &&
              postDecisionP2PExpanded &&
              readmissionBannerHeader !== "" &&
              readmissionBannerBodyText !== "" && (
                <ReadmissionBanner
                  header={readmissionBannerHeader}
                  bodyText={readmissionBannerBodyText}
                  isOpen={isReadmissionBannerOpen}
                  onDismissedClicked={setIsisReadmissionBannerOpen}
                  icon={<PurpleClipboard />}
                  bannerClassName={notificationClasses.notificationBanner}
                />
              )}
            <ViewOnlyReviewLeftPanel
              otherAuthsProps={otherAuthsProps}
              authorization={authorization}
              refreshAuthorization={fetchAuthorization}
              authorizationFetchLoading={authorizationFetchLoading}
              serviceRequest={serviceRequest}
              attachments={attachments}
              appealsForAuth={appealsForAuth}
              tabOpen={leftColumnTab}
              setTabOpen={setLeftColumnTab}
              userClickInfoTracking={userClickInfoTracking}
              setUserClickInfoTracking={setUserClickInfoTracking}
              attachmentIndexOpen={attachmentIndexOpen}
              previousAttachmentsExpanded={previousAttachmentsExpanded}
              setPreviousAttachmentsExpanded={setPreviousAttachmentsExpanded}
              currentPage={currentPage}
              zoomLevel={zoomLevel}
              currentRotate={currentRotate}
              serviceRequestLoading={serviceRequestLoading}
              existingReviews={existingReviews}
              serviceRequestRefetch={serviceRequestRefetch}
              serviceCases={serviceCases}
              trackingNumberSearch={trackingNumberSearch}
              caseId={caseId}
              serviceRequestId={serviceRequestId}
              attachmentsLoading={attachmentListFetchLoading}
              canViewGenericNotes={canViewGenericNotes}
              onViewAttachment={onViewAttachment}
              attachmentsInfo={attachmentsInfo}
              setAttachmentsInfo={setAttachmentsInfo}
              canUseClaimHistoryFeature={claimHistoryFeature}
              claimHistoryProps={claimHistoryProps}
              currentServiceCase={serviceCase}
            />
          </>
        }
        rightColumn={
          (canViewNurseReviewerCase || canViewMDReviewerCase || canViewLetterWritingCase || canViewOutreachCase) &&
          isReviewQueue ? (
            <QueueSupervisorSidebar
              serviceRequest={serviceRequest}
              serviceCase={supervisorServiceCase}
              serviceCaseLoading={serviceCaseLoading}
              setServiceCase={setSupervisorServiceCase}
              isReviewQueue={isReviewQueue}
            />
          ) : (
            <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
              <CenterStyleDiv>
                <Body2>
                  Guideline selection and review creation are not available on the read-only version of this page
                </Body2>
              </CenterStyleDiv>
            </div>
          )
        }
      />
      <LoadingDialog
        open={loadingExistingReviews}
        headingText={"Loading your clinical review"}
        subHeaderText={"This may take a few seconds"}
      />
    </ClinicalReviewContext.Provider>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CenterStyleDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  margin: theme.spacing(0, 12),
  color: theme.palette.text.secondary,
  textAlign: "center",
}));

const useBannerStyles = makeStyles((theme) => ({
  notificationBanner: {
    marginTop: theme.spacing(1.5),
  },
}));
