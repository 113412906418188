import { Body1, H6, RadioGroup, RadioGroupOption } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core";

interface ReasoningRadioGroupProps {
  className?: string;
  dek: string | JSX.Element;
  options: RadioGroupOption<string>[];
  title: string;
  value?: string;
  onChange: (arg0: string) => any;
}

export default function ReasoningRadioGroup(props: ReasoningRadioGroupProps): JSX.Element {
  const { className, dek, options, title, value, onChange } = props;
  const classes = styles();

  return (
    <>
      <H6>{title}</H6>
      <Body1 className={classes.dek}>{dek}</Body1>
      <RadioGroup
        className={className}
        options={options}
        value={value || ""}
        onChange={onChange}
        additionalRadioClasses={radioButtonStyles()}
        additionalChildClasses={notificationBannerStyles()}
        optionsLabelClasses={optionsLabelStyles()}
      />
    </>
  );
}

const styles = makeStyles((theme) => ({
  dek: {
    paddingBottom: theme.spacing(1.5),
  },
}));

const radioButtonStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: theme.spacing(6 / 8),
    paddingRight: theme.spacing(6 / 8),
  },
}));

const notificationBannerStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(-6 / 8),
    paddingLeft: theme.spacing(27 / 8),
    paddingBottom: theme.spacing(6 / 8),
  },
}));

const optionsLabelStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(6 / 8),
    paddingTop: theme.spacing(6 / 8),
  },
}));
