import React from "react";

// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";
import { useAuthorized } from "../../authorization";

import ManageManyIntegrations from "./IntegrationList/ManageManyIntegrations";

export default function IntegrationManagementPage() {
  const canViewPage = useAuthorized("INTEGRATION_MANAGEMENT_PAGE");

  if (!canViewPage) {
    return null;
  }

  return <PageWrapper>{<ManageManyIntegrations />}</PageWrapper>;
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PageWrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: "100%",
}));
