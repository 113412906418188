import React from "react";
import { Link, generatePath } from "react-router-dom";
import routes from "routes";
import { patientGenderDisplay } from "util/demographicUtils";
import { Body1, H5, H6, InlineButton, formatDateStr, patientDisplayName, useFeature } from "@coherehealth/common";
import { Coverage, Patient } from "@coherehealth/core-platform-api";
import { usePatientSearchHeadlineStyles } from "./PatientSearchHeadlineStyles";
import { getPatientHealthDisplayName } from "./utils/getPatientHealthPlanDisplayName";
import { Grid } from "@material-ui/core";

interface PatientSearchHeadlineProps {
  allowSelect?: boolean;
  latestCoverage?: Coverage | null;
  patient: Patient;
  showCoverageEndDate?: boolean;
  showSummaryLink?: boolean;
}

export function PatientSearchHeadline(props: PatientSearchHeadlineProps): JSX.Element {
  const { allowSelect, latestCoverage, patient, showCoverageEndDate, showSummaryLink } = props;
  const classes = usePatientSearchHeadlineStyles();
  const unifyPatientSearch = useFeature("unifyPatientSearch");

  const healthPlanDisplayName = getPatientHealthDisplayName({
    patient,
    latestCoverage: latestCoverage ?? undefined,
    showCoverageEndDate,
  });

  if (showSummaryLink) {
    return unifyPatientSearch ? (
      <Grid container>
        <Grid container justifyContent="flex-start" alignItems="center">
          <Grid item xs={8}>
            <H5>{patientDisplayName(patient)}</H5>
          </Grid>
          <Grid item xs={4}>
            {allowSelect && (
              <div className={classes.secondaryTextContainer}>
                <InlineButton
                  className={classes.patientSummaryLink}
                  // Link of PatientSummary must always redirect the user to Auth's PS page
                  onClick={() =>
                    (window.location.href = generatePath(routes.PATIENT_SUMMARY, {
                      patientId: patient.id,
                    }))
                  }
                >
                  Patient summary
                </InlineButton>
              </div>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {Boolean(patient.coverage?.healthPlanName) && (
            <Body1 className={classes.patientDetails}>{healthPlanDisplayName}</Body1>
          )}
        </Grid>
      </Grid>
    ) : (
      <>
        <H6>{patientDisplayName(patient)}</H6>
        {Boolean(patient.coverage?.healthPlanName) && (
          <Body1 className={classes.patientDetails}>{healthPlanDisplayName}</Body1>
        )}
        {allowSelect && (
          <div className={classes.deprecatedSecondaryTextContainer}>
            <Link
              className={classes.deprecatedPatientSummaryLink}
              to={generatePath(routes.PATIENT_SUMMARY, { patientId: patient.id })}
            >
              Patient summary
            </Link>
          </div>
        )}
      </>
    );
  }

  if (showCoverageEndDate && latestCoverage) {
    return (
      <>
        <H6>{patientDisplayName(patient)}</H6>
        {patient.memberId && <Body1 className={classes.patientDetails}>Member ID: {patient.memberId}</Body1>}
        <Body1 className={classes.patientDetails}>{healthPlanDisplayName}</Body1>
        <Body1 className={classes.patientDetails}>
          {latestCoverage?.coverageLineOfBusinessType || latestCoverage?.lineOfBusinessType}{" "}
          {latestCoverage?.coverageProductType || latestCoverage?.productType} (through{" "}
          {formatDateStr(latestCoverage.planEnd)})
        </Body1>
      </>
    );
  }

  return (
    <>
      <H6>{patientDisplayName(patient)}</H6>
      <Body1 className={classes.patientDetails}>
        {patientGenderDisplay(patient.gender)} - {formatDateStr(patient.dateOfBirth)}
      </Body1>
      {Boolean(patient.coverage?.healthPlanName) && (
        <Body1 className={classes.patientDetails}>{healthPlanDisplayName}</Body1>
      )}
    </>
  );
}
