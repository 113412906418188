export enum UserType {
  Admin = "admin" /* Admin user with full permissions */,
  BackOffice = "backOffice" /* Non-Cohere employee that uses Cohere in back office setting */,
  ServiceOpsGeneral = "serviceOpsGeneral" /* Non-Cohere employee who reviews service requests and makes approval decisions */,
  ServiceOps = "serviceOps" /* Cohere employee who reviews service requests and makes approval decisions */,
  ServiceOpsLeadership = "serviceOpsLeadership" /* Cohere ServiceOps Super User, with power to edit incorrectly input manual Auth # entries */,
  User = "user" /* Default user, lowest level of permissions */,
  ClinicalContent = "clinicalContent" /* Clinical content team, does not have access to do things in the app */,
  BackOfficeAdmin = "backOfficeAdmin" /* Non-cohere employee that has privileges to "My organization" page, otherwise same as backoffice */,
  OpsUserAdmin = "opsUserAdmin" /* aka Cohere IT team who manage Ops users, both Cohere ops and PaaS customer users */,
  InternalUserAdmin = "internalUserAdmin" /* aka Provider Relations: Cohere employee who only has access to org management pages in app */,
  NurseReviewer = "nurseReviewer" /* Cohere employee that has nurse review edit privileges, can view all reviews */,
  MDReviewer = "mdReviewer" /* Cohere employee that has MD and P2P review edit privileges, can view all reviews */,
  CohereEngOnCall = "cohereEngOnCall" /* Cohere engineer on call - can see tons of stuff, can edit some config and service ops stuff */,
  CoherePm = "coherePm" /* Cohere product manager - can see tons of stuff, can edit some config stuff */,
  FinalReviewAuditor = "finalReviewAuditor" /* Extra special Cohere employee who can modify finalized reviews; basically just Compliance Officer */,
  ReviewDenialSubmitter = "reviewDenialSubmitter" /* Extra special Cohere employee who can click the button to finalize a denial; head of MD team and/or Compliance Officer */,
  AuthViewOnly = "authViewOnly" /* External user that only has read access in the Authorize app */,
  IntegrationViewer = "integrationViewer" /* add-on role */,
  RetroRuleRunner = "runRulesRetroactively" /* add-on role */,
  FeatureFlagEditor = "featureFlagEditor" /* add-on role */,
  ClinicalReviewRedesignReviewerBeta = "newClinicalReviewerBeta" /* allows a specific group to try out our clinical review redesign */,
  QMPilot = "qmPilot" /* allows pilot users to use queue management page */,
  Assigner = "qmAssigner" /* QM Assigner role, supervisor*/,
  QMManager = "qmManager" /* qmManager, will manage queues, assignments and users, can access QM user management */,
  ReportViewer = "qmReportViewer" /* Can productivity reports */,
  AuditReviewer = "auditReviewer" /* allows users to create and view audit reviews*/,
  DocTypeandOrderingBetaUsers = "docSegmentationBeta" /* allows pilot users to use document segmentation features*/,
  ClinicalViewer = "clinicalViewer" /* role so fewer people are granted the clinicalContent editing role */,
  LetterManager = "letterManager" /* DEPRECATED */,
  ConfigurationManager = "configurationManager" /* DEPRECATED */,
  PreprodAccess = "preprodAccess" /* grants the user access to the preprod environment */,
  UMReadOnly = "umReadOnly" /* grants read only access to the app, includes viewing clinical ops info that may not be visible to authViewOnly role  */,
  PatientCoverageEditor = "patientCoverageEditor" /* grants the user write permission to patient coverages */,
  PalManagement = "palManagement" /* add-on role */,
  ViewSrAuditHistory = "viewSrAuditHistory" /* add-on role */,
  BypassRedirects = "bypassRedirects" /* add-on role */,

  // The next 4 are duplicates, but do no harm - result of consolidating this file across projects
  CLINICAL_CONTENT = "clinicalContent",
  CLINICAL_VIEWER = "clinicalViewer" /* role so fewer people are granted the clinicalContent editing role */,
  RETROACTIVE_RULE_RUNNER = "runRulesRetroactively",
  PALManager = "palManagement" /* DEPRECATED */,

  DeterminationTemplateManager = "letterManager" /* DEPRECATED */,
  ClinicalCoder = "clinicalCoder",

  TransferAuthorization = "transferAuthorization" /* allows users to use the features to transfer non-decisioned authorizations between users of the same health plan */,

  PlaceholderPatientCreator = "createPlaceholderPatient" /* allows users to use the features to create placeholder/temporary patient records creation */,

  Cypress = "cypress" /* Internal Cypress role */,

  AutoContinuationCreationJob = "autoContinuationCreationJob" /* User type of job creating continuations */,

  FhirPriorAuthorizationSubmit = "fhirPriorAuthorizationSubmit" /* allows to create and submit authorization. this role used for API communication only - ideally will not be assigned to users*/,

  FhirCrdCheck = "fhirCrdCheck" /* allows to submit a request for CRD Check. this role used for API communication only - ideally will not be assigned to users*/,

  TechnicalServices = "technicalServices" /* allows users to implement configurations for new and existing clients*/,

  AccessExternalGuidelineContent = "accessExternalGuidelineContent" /*allows users to access the external partner guideline workflow */,
}
