import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";

import {
  ClinicalAssessment,
  ServiceRequestResponse,
  UnitedStates,
  useGetSingleServiceClinicalAssessment,
} from "@coherehealth/core-platform-api";
import {
  ClinicalAssessmentResponseWithSubQuestions,
  assessmentWithOnlyServiceRequestCurrentFacilityStateValue,
  H4,
  H6,
  selectAnswersForQuestion,
  SubQuestion,
} from "@coherehealth/common";
import CircularProgress from "@material-ui/core/CircularProgress";

import ClinicalAssessmentEdit from "./ClinicalAssessmentEdit";
import ClinicalAssessmentReadOnly from "./ClinicalAssessmentReadOnly";
import VendorAssessmentSummary from "../../AuthBuilder/VendorClinicalAssessment/VendorAssessmentSummary";
import { SectionHeaderContainer } from "./ServiceRequestSummaryDetails";
import { makeStyles } from "@material-ui/core";
import {
  incompleteRequiredQuestions,
  mapClinicalResponseWithSubQuestion,
  updateSubQuestionsVisibility,
} from "util/clinicalAssessment";
import { SubQuestionVisibilityState } from "components/ClinicalAssessment/ClinicalAssessmentContext";
import { useAuthorized } from "authorization";
import { useIsEditDisabledForReview, getPendingAuthStatuses } from "util/serviceRequest";
import { useLocation } from "react-router";
import { User, UserContext } from "../../../UserContext";

interface Props {
  serviceRequest: ServiceRequestResponse;
  showReadOnlyVendorSummary: boolean;
  simulateRuleRun?: () => void;
  crrCardView?: boolean;
  updateAssessmentCount?: (clinicalAssessment: ClinicalAssessment) => void;
  initialIsExpanded?: boolean;
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onEditAssessment?: Function;
  onAssessmentUpdate?: (clinicalAssessment: ClinicalAssessment) => void;
  setCaqSkippedAA?: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
}));

function ClinicalAssessmentSection({
  serviceRequest,
  showReadOnlyVendorSummary,
  simulateRuleRun,
  crrCardView = false,
  updateAssessmentCount,
  initialIsExpanded,
  onEditAssessment,
  onAssessmentUpdate,
  setCaqSkippedAA,
}: Props) {
  const { getUser } = useContext(UserContext);

  const [user, setUser] = useState<User>();

  useEffect(() => {
    getUser()?.then((user) => setUser(user));
  }, [getUser]);

  const id = serviceRequest.id;

  const {
    data: clinicalAssessment,
    loading,
    refetch,
  } = useGetSingleServiceClinicalAssessment({
    id,
  });

  return (
    <ClinicalAssessmentContent
      serviceRequest={serviceRequest}
      showReadOnlyVendorSummary={showReadOnlyVendorSummary}
      simulateRuleRun={simulateRuleRun}
      crrCardView={crrCardView}
      updateAssessmentCount={updateAssessmentCount}
      clinicalAssessment={clinicalAssessment || null}
      clinicalAssessmentLoading={loading}
      clinicalAssessmentRefetch={refetch}
      initialIsExpanded={initialIsExpanded}
      onEditAssessment={onEditAssessment}
      onAssessmentUpdate={onAssessmentUpdate}
      setCaqSkippedAA={setCaqSkippedAA}
      user={user}
    />
  );
}

export default ClinicalAssessmentSection;

interface ClinicalAssessmentContentProps {
  serviceRequest: ServiceRequestResponse;
  showReadOnlyVendorSummary: boolean;
  simulateRuleRun?: () => void;
  crrCardView?: boolean;
  updateAssessmentCount?: (clinicalAssessment: ClinicalAssessment) => void;
  clinicalAssessment: ClinicalAssessment | null;
  clinicalAssessmentLoading: boolean;
  clinicalAssessmentRefetch?: () => Promise<unknown>;
  initialIsExpanded?: boolean;
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onEditAssessment?: Function;
  onAssessmentUpdate?: (clinicalAssessment: ClinicalAssessment) => void;
  setCaqSkippedAA?: Dispatch<SetStateAction<boolean>>;
  user?: User;
}

export function ClinicalAssessmentContent({
  serviceRequest,
  showReadOnlyVendorSummary,
  simulateRuleRun,
  crrCardView,
  updateAssessmentCount,
  clinicalAssessment,
  clinicalAssessmentLoading,
  clinicalAssessmentRefetch,
  initialIsExpanded,
  onEditAssessment,
  onAssessmentUpdate,
  setCaqSkippedAA,
  user,
}: ClinicalAssessmentContentProps) {
  const [assessmentState, setAssessmentState] = useState<"EDIT" | "VIEW">("VIEW");
  const classes = useStyles();
  const [subQuestionsVisibility, setSubQuestionsVisibility] = useState<SubQuestionVisibilityState>();
  const location = useLocation();
  // Backoffice users cannot edit the clinical assessment once a review has started
  //caq should not be edited if service request is not pending or a draft in authbuilder
  const canEditClinicalAssessment =
    useAuthorized("EDIT_CLINICAL_ASSESSMENT") &&
    (getPendingAuthStatuses().includes(serviceRequest.authStatus || "") || location.pathname.includes("/auth_builder"));
  const editDisabledUnderReview = useIsEditDisabledForReview(serviceRequest);

  if (clinicalAssessment && clinicalAssessment.questions && clinicalAssessment.questions.length > 0) {
    let noAnswers = true;
    clinicalAssessment.questions.forEach((question) => {
      if (question.answers && question.answers.length > 0) {
        noAnswers = false;
      }
    });
    if (noAnswers && setCaqSkippedAA && serviceRequest.authStatus === "APPROVED") {
      setCaqSkippedAA(noAnswers);
    }
  }

  const updateSubQuestionsVisibilityState = useCallback(() => {
    const questionsForClinicalAssessment = mapClinicalResponseWithSubQuestion(clinicalAssessment?.questions || []);

    questionsForClinicalAssessment.forEach((currentQuestion: ClinicalAssessmentResponseWithSubQuestions) => {
      (currentQuestion.subQuestions || []).forEach((subQuestion: SubQuestion) => {
        const mainQuestion = questionsForClinicalAssessment.find((q) => q.id === subQuestion.questionToRenderOn);

        const multiSelectOptions =
          mainQuestion !== undefined
            ? selectAnswersForQuestion(mainQuestion, clinicalAssessment?.type || "").some(
                (a: any) => a.optionId === subQuestion.answerToRenderOn
              )
            : false;

        const showSubQuestion =
          (mainQuestion?.clinicalQuestion?.type === "FREE_TEXT" &&
            (mainQuestion !== undefined && mainQuestion.answers !== undefined
              ? mainQuestion?.answers[0]?.valueString || ""
              : ""
            ).length > 0) ||
          multiSelectOptions;

        updateSubQuestionsVisibility({
          sq: subQuestion,
          subQuestions: subQuestionsVisibility,
          updateSubQuestions: setSubQuestionsVisibility,
          setIsVisible: showSubQuestion,
        });
      });
    });
  }, [clinicalAssessment?.questions, clinicalAssessment?.type, subQuestionsVisibility]);
  let clinicalAssessmentContent = null;

  useEffect(() => {
    if (
      initialIsExpanded &&
      serviceRequest.type === "Pharmacy" &&
      clinicalAssessment &&
      serviceRequest.authStatus === "PENDING" &&
      serviceRequest.pendingReason === "PENDING_ASSESSMENT_SUBMISSION" &&
      canEditClinicalAssessment &&
      !editDisabledUnderReview
    ) {
      updateSubQuestionsVisibilityState();
      if (incompleteRequiredQuestions(clinicalAssessment, subQuestionsVisibility).length !== 0) {
        setAssessmentState("EDIT");
      } else {
        setAssessmentState("VIEW");
      }
    }
  }, [
    canEditClinicalAssessment,
    clinicalAssessment,
    editDisabledUnderReview,
    initialIsExpanded,
    serviceRequest,
    subQuestionsVisibility,
    updateSubQuestionsVisibilityState,
  ]);

  if (showReadOnlyVendorSummary) {
    clinicalAssessmentContent = (
      <>
        <SectionHeaderContainer>
          <H4 data-public>Clinical Assessment</H4>
        </SectionHeaderContainer>
        <div className={classes.container}>
          <VendorAssessmentSummary cohereId={serviceRequest?.cohereId} />
        </div>
      </>
    );
  } else if (!clinicalAssessment && clinicalAssessmentLoading) {
    return <CircularProgress />;
  } else if (clinicalAssessment?.questions?.length === 0 || !clinicalAssessment) {
    return <H6 data-public>No clinical assessment</H6>;
  } else {
    clinicalAssessmentContent = (
      <>
        {assessmentState === "VIEW" ? (
          <ClinicalAssessmentReadOnly
            clinicalAssessment={clinicalAssessment}
            setAssessmentState={setAssessmentState}
            serviceRequest={serviceRequest}
            crrCardView={crrCardView}
            onEditAssessment={onEditAssessment}
            canEditClinicalAssessment={canEditClinicalAssessment}
            user={user}
          />
        ) : (
          <ClinicalAssessmentEdit
            clinicalAssessment={
              serviceRequest.authStatus && serviceRequest.authStatus !== "DRAFT"
                ? assessmentWithOnlyServiceRequestCurrentFacilityStateValue(
                    clinicalAssessment,
                    serviceRequest.facilityLocation?.address?.state as UnitedStates
                  )
                : clinicalAssessment
            }
            setAssessmentState={setAssessmentState}
            serviceRequest={serviceRequest}
            onComplete={async (ca: ClinicalAssessment) => {
              simulateRuleRun?.();
              onAssessmentUpdate?.(ca);
              await clinicalAssessmentRefetch?.();
              updateAssessmentCount?.(ca);
            }}
            crrCardView={crrCardView}
          />
        )}
      </>
    );
  }
  return <>{clinicalAssessmentContent}</>;
}
