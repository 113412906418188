import { SetStateAction, Dispatch, useState, useEffect, useRef, ReactNode } from "react";
import { InlineButton, colorsLight, useStylesCard } from "@coherehealth/common";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled, Theme } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import Divider from "@material-ui/core/Divider";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { scrollToRef } from "components/ClinicalReview/reviewUtils/utils";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

interface Props {
  isExpanded?: boolean;
  setIsExpanded?: Dispatch<SetStateAction<boolean>>;
  hideExpandIcon?: boolean;
  children?: ReactNode;
  header?: ReactNode;
  updateUserClickInfo?: () => void;
  controlExpansion?: boolean | undefined;
  regionContainerPadding?: string;
  dividerContainerMargin?: string;
  autoScroll?: boolean;
  style?: CSSProperties;
  carrotContainerProps?: CarrotContainerProps;
}

const useStyles = makeStyles((theme) => ({
  rotatingCarrot: ({ isExpanded }: Partial<Props>) => ({
    transition: theme.transitions.create("transform"),
    transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
    height: theme.spacing(4),
    width: theme.spacing(4),
    marginRight: 0,
  }),
  clinicalReviewCard: {
    padding: 0,
    position: "relative",
  },
  hoverable: {
    "& :hover svg": {
      backgroundColor: colorsLight.action.hoverBackground,
      borderRadius: 4,
    },
  },
  clinicalReviewSummary: {
    display: "inline-block",
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
  summaryRoot: {
    cursor: "pointer",
    userSelect: "text",
    padding: theme.spacing(1.5, 2),
    "&.Mui-expanded": {
      minHeight: "49px",
    },
  },
  expanded: {},
}));

export default function ClinicalReviewCard({
  isExpanded,
  setIsExpanded,
  hideExpandIcon,
  children,
  header,
  updateUserClickInfo,
  controlExpansion,
  regionContainerPadding,
  dividerContainerMargin,
  autoScroll,
  style,
  carrotContainerProps,
}: Props) {
  const [expanded, setExpanded] = useState(isExpanded);
  const classes = useStyles({ isExpanded });
  const { withHover, ...cardStyles } = useStylesCard({});
  const accordionRef = useRef<HTMLElement>(null);
  const ariaLabel = `${isExpanded ? "Collapse" : "Expand"} this accordion card`;
  useEffect(() => {
    if (isExpanded && !expanded) {
      const timeout = setTimeout(() => {
        setIsExpanded?.(false);
      }, 300);
      return () => {
        clearTimeout(timeout);
      };
    }
    if (!isExpanded && expanded) {
      setIsExpanded?.(true);
      if (autoScroll) {
        const timeout = setTimeout(() => scrollToRef(accordionRef), 300);
        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [autoScroll, isExpanded, expanded, setIsExpanded]);
  useEffect(() => {
    if (controlExpansion !== undefined) {
      setExpanded(controlExpansion);
      if (autoScroll && controlExpansion) {
        const timeoutId = setTimeout(() => scrollToRef(accordionRef), 300);
        return () => {
          clearTimeout(timeoutId);
        };
      }
    }
  }, [controlExpansion, autoScroll]);
  return (
    <Accordion
      className={`${classes.clinicalReviewCard} ${withHover}`}
      classes={cardStyles}
      style={{ ...style }}
      defaultExpanded={isExpanded}
      expanded={expanded}
      TransitionProps={{ unmountOnExit: true }}
      ref={accordionRef}
    >
      <AccordionSummary
        classes={{
          root: classes.summaryRoot,
          content: classes.clinicalReviewSummary,
          expanded: classes.expanded,
        }}
        onClick={() => {
          if (!((window.getSelection()?.toString().length || 0) > 0)) {
            updateUserClickInfo?.();
            setIsExpanded && setExpanded(!expanded);
          }
        }}
      >
        <RelativeContainer>
          <div style={{ width: "100%" }}>{header}</div>
          {!hideExpandIcon && (
            <CarrotContainer {...carrotContainerProps}>
              <CarrotIcon title={ariaLabel} aria-hidden="true" tabIndex="-1" className={classes.hoverable}>
                <ExpandMoreIcon className={classes.rotatingCarrot} />
              </CarrotIcon>
            </CarrotContainer>
          )}
        </RelativeContainer>
      </AccordionSummary>
      <RegionContainer padding={regionContainerPadding}>
        <DividerContainer margin={dividerContainerMargin}>
          <Divider />
        </DividerContainer>
        {children}
      </RegionContainer>
    </Accordion>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RegionContainer = styled(({ padding, ...other }) => <div {...other} />)<Theme, { padding?: string }>(
  ({ theme, padding }) => ({
    padding: padding ? padding : theme.spacing(0, 2, 1.5),
  })
);

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CarrotIcon = styled(InlineButton)({
  position: "absolute",
  right: 0,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RelativeContainer = styled("div")({
  display: "flex",
  position: "relative",
  justifyContent: "space-between",
});

type CarrotContainerProps = { right?: number; top?: number; marginLeft?: number };

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CarrotContainer = styled("div")<Theme, CarrotContainerProps>(({ right, top, marginLeft }) => ({
  position: "relative",
  right: right ?? -10,
  top: top ?? -14,
  marginLeft: marginLeft ?? 60,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const DividerContainer = styled(({ margin, ...other }) => <div {...other} />)<Theme, { margin?: string }>(
  ({ theme, margin }) => ({
    width: "100%",
    margin: margin ? margin : theme.spacing(0, 0, 2),
  })
);
