import { createContext } from "react";
import { UserClaims } from "@okta/okta-auth-js";
import { EhrVendor } from "@coherehealth/core-platform-api";

export type User = UserClaims &
  AvailityUserInfo &
  EhrUserInfo & {
    agreementSignedAt?: string;
    groups?: Array<string>;
    type?: string /* will be one of the values from UserType */;
    roles?: string /* will be comma separated values from UserType */;
    opsGroup?: string;
    phoneNumber?: string;
  };

export declare type AvailityUserInfo = {
  AvailityCustomerPhone?: string;
  AvailityCustomerId?: string;
  AvailityCustomerAddress?: string;
  AvailityCustomerNPIs?: string;
  AvailityCustomerTINs?: string;
  AvailityCustomerName?: string;
  AvailityCustomerFax?: string;
  AvailityUserPhone?: string;
  AvailityUserName?: string;
  AvailityUserLastName?: string;
  AvailityUserFirstName?: string;
  AvailityCohereRoles?: string;
};

export declare type EhrUserInfo = {
  ehrVendor?: EhrVendor;
  ehrPracticeId?: string;
  ehrDepartmentId?: string;
};

interface UserContextType {
  getUser: () => Promise<User> | undefined;
}
export const UserContext = createContext<UserContextType>({ getUser: () => Promise.reject("No user set") });

export const UserProvider = UserContext.Provider;
