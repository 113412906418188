import { Model, Factory, Server } from "miragejs";

const models = {
  provider: Model,
};
const factories = {
  provider: Factory.extend({
    npi(i: number) {
      return `NPI${i}`;
    },
    name(i: number) {
      return `Provider Name ${i}`;
    },
    addresses(i: number) {
      return [];
    },
    phoneNumbers(i: number) {
      return [];
    },
    tinList(i: number) {
      return [];
    },
  }),
};

const scenarios = {
  standard(server: Server) {
    server.create("provider", {
      npi: "1234567890",
      name: "Emmett Lathrop Brown, Ph.D",
      addresses: [
        {
          line1: "Address Line 1",
          line2: "Address Line 2",
          city: "City",
          state: "AB",
          zipCode: "12345",
          latitude: 0,
          longitude: 0,
        },
      ],
      phoneNumbers: [],
      tinList: ["123456789", "111111111"],
    } as any);

    server.create("provider", {
      npi: "NPIlongName",
      name: "Test Long Name Long Name Long Name Long Name Long Name Long Name Long Name Long Name Long Name Long Name Long Name Long Name.",
    } as any);

    server.createList("provider", 100);
  },
};

const activeScenario = "standard";
//const activeScenario = "short";
//const activeScenario = "empty";

export { models, factories, scenarios, activeScenario };
