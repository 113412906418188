import { registerRoute } from "../server";

export default function registerRoutes() {
  registerRoute({
    id: "getDenialsTemplate",
    route: "/denialsTemplate",
    method: "get",
    generateResponse: function (config, schema, request) {
      return [
        {
          id: "60886ea2607dfa0001806dec",
          templateTitle: "Miragy Template",
          active: true,
          authStatus: ["APPROVED", "DENIED", "WITHDRAWN", "PENDING", "DRAFT"],
          guidelineIds: [
            "60118eebbfae1300019ef3b7",
            "608880a1c6cbc8000161e694",
          ],
          denialLanguage: "You shall be denied",
          documentVersion: "1",
          published: false,
          isCurrentDraft: true,
          updatedBy: "Regina Phalange",
          lastUpdated: "2021-04-27T21:22:41.478Z",
        },
        {
          id: "608880a1c6cbc8000161e694",
          templateTitle: "Middle Child",
          active: true,
          authStatus: ["APPROVED", "DENIED"],
          guidelineIds: [
            "60886ea2607dfa0001806dec",
            "608880a1c6cbc8000161e694",
          ],
          denialLanguage: "Too many injections",
          documentVersion: "1",
          published: true,
          publishedAt: "2021-07-26T14:23:37.086Z",
          publishedBy: "00ums8ykxLzD7zFvO4x6",
          isCurrentDraft: true,
          isCurrentPublished: true,
          updatedBy: "engineering mdReviewDenialsBeta",
          lastUpdated: "2020-12-15T15:40:36.207Z",
        },
        {
          id: "608880a1c6cbc8000161e694",
          templateTitle: "Stampable Letter",
          active: false,
          authStatus: ["WITHDRAWN", "PENDING", "DRAFT"],
          guidelineIds: ["604a9524768b3e000140f4f6"],
          denialLanguage: "Draft letter template",
          documentVersion: "1",
          published: false,
          isCurrentDraft: true,
          updatedBy: "Letter Writer",
          lastUpdated: "2021-07-16T16:51:33.311Z",
        },
      ];
    },
  });
}
