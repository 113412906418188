export default function ClipboardPenIcon() {
  return (
    <svg width="109" height="108" viewBox="0 0 109 108" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_6313_13703)">
        <path
          d="M54.5 104C83.2188 104 106.5 80.7188 106.5 52C106.5 23.2812 83.2188 0 54.5 0C25.7812 0 2.5 23.2812 2.5 52C2.5 80.7188 25.7812 104 54.5 104Z"
          fill="white"
        />
      </g>
      <path d="M85.9788 30.4033H83.2812V33.7916H85.9788V51.5283H89.0013V30.4033H85.9788Z" fill="#2B3B4E" />
      <path
        d="M82.8262 26.2924H76.5293V22.6524C76.5293 20.9137 77.9431 19.5 79.6818 19.5C81.4206 19.5 82.8343 20.9137 82.8343 22.6524V26.2924H82.8262Z"
        fill="#00A9D1"
      />
      <path d="M79.6814 84.5L84.4914 73.125H74.8633L79.6814 84.5Z" fill="#ACB3BA" />
      <path d="M84.4914 26.292H74.8633V73.1246H84.4914V26.292Z" fill="#324A5E" />
      <path d="M80.0389 31.9072H77.3008V69.144H80.0389V31.9072Z" fill="#2B3B4E" />
      <path d="M67.501 29.4307H23.9727V80.1987H67.501V29.4307Z" fill="#A1AAB4" />
      <path d="M57.4804 33.2021H27.3105V77.2628H64.0278V39.3695L57.4804 33.2021Z" fill="white" />
      <path d="M57.4805 39.3695H64.0277L57.4805 33.2021L57.4805 39.3695Z" fill="#F5F5F5" />
      <path
        d="M39.9942 38.3518H37.5134V35.873H35.7949V38.3518H33.3184V40.0702H35.7949V42.5489H37.5134V40.0702H39.9942V38.3518Z"
        fill="#EA1D63"
      />
      <path d="M59.9355 49.1843V47.4551L40.1432 47.4551V49.1843H59.9355Z" fill="#ACB3BA" />
      <path d="M59.9375 57.033V55.3037L40.1453 55.3037V57.033H59.9375Z" fill="#ACB3BA" />
      <path d="M59.9395 64.8816V63.1523L40.1471 63.1523V64.8816H59.9395Z" fill="#ACB3BA" />
      <path d="M59.9355 72.7302V71.001H40.1433V72.7302H59.9355Z" fill="#ACB3BA" />
      <path
        d="M33.3493 50.7715L31.3848 48.4541L32.2788 47.6953L33.0964 48.6659C33.7434 47.6248 35.0962 45.9132 37.0019 45.8721L37.0254 47.0484C35.0785 47.0896 33.7434 49.9128 33.7316 49.9422L33.3493 50.7715Z"
        fill="#00A9D1"
      />
      <path
        d="M33.3493 58.6174L31.3848 56.3L32.2788 55.5412L33.0964 56.5116C33.7434 55.4706 35.0962 53.7589 37.0019 53.7178L37.0254 54.8941C35.0785 54.9353 33.7434 57.7587 33.7316 57.7881L33.3493 58.6174Z"
        fill="#00A9D1"
      />
      <path
        d="M33.3493 66.4698L31.3848 64.1524L32.2788 63.3936L33.0964 64.3642C33.7434 63.3231 35.0962 61.6115 37.0019 61.5703L37.0254 62.7467C35.0785 62.7878 33.7434 65.6111 33.7316 65.6405L33.3493 66.4698Z"
        fill="#00A9D1"
      />
      <path
        d="M33.3493 74.3156L31.3848 71.9982L32.2788 71.2394L33.0964 72.2099C33.7434 71.1688 35.0962 69.4572 37.0019 69.416L37.0254 70.5924C35.0785 70.6335 33.7434 73.4569 33.7316 73.4863L33.3493 74.3156Z"
        fill="#00A9D1"
      />
      <path
        d="M55.5957 23.144H49.7967C49.7967 20.5867 47.9778 18.5156 45.7266 18.5156C43.4755 18.5156 41.6566 20.5867 41.6566 23.144H35.8576L34.2188 33.4093H57.2165L55.5957 23.144Z"
        fill="#212936"
      />
      <defs>
        <filter
          id="filter0_d_6313_13703"
          x="0.5"
          y="0"
          width="108"
          height="108"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.809983 0 0 0 0 0.886017 0 0 0 0 0.904167 0 0 0 0.8 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6313_13703" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6313_13703" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
