import React, { useCallback, useEffect, useState } from "react";

import { H1, PrimaryButton } from "@coherehealth/common";
import { CreateOrganizationRequestBody, useCreateOrganization } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";

import CreateOrganizationModal from "./CreateOrganizationModal";
import UploadListFromFile from "./UploadListFromFile";
import { useSnackbar } from "notistack";
import { useAuthorized } from "authorization";

interface Props {
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onAddOrganization?: Function;
}

export default function OrganizationListHeader({ onAddOrganization }: Props) {
  const [newOrgModalOpen, setNewOrgModalOpen] = useState(false);
  const openCreateOrgModal = useCallback(() => {
    setNewOrgModalOpen(true);
  }, []);

  const { mutate: createOrg, loading: creatingOrg, error: orgCreateError } = useCreateOrganization({});
  const createOrgCallback = useCallback(
    async (org: CreateOrganizationRequestBody) => {
      await createOrg(org);
      if (onAddOrganization) {
        onAddOrganization();
      }
      setNewOrgModalOpen(false);
    },
    [createOrg, onAddOrganization, setNewOrgModalOpen]
  );

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (orgCreateError) {
      if (orgCreateError.status === 409) {
        enqueueSnackbar("An organization already exists with this name", { variant: "error" });
      } else if (orgCreateError.status === 403) {
        enqueueSnackbar("You do not have permission to create this organization", { variant: "error" });
      } else {
        enqueueSnackbar(`There was an error creating the organization ${orgCreateError.message}`, {
          variant: "error",
        });
      }
    }
  }, [enqueueSnackbar, orgCreateError]);

  const canUpdateFromFile = useAuthorized("UPLOAD_ORGS_FROM_FILE");
  const [fileModalOpen, setFileModalOpen] = useState(false);

  return (
    <Wrapper>
      <H1 data-public>Management</H1>
      {canUpdateFromFile && (
        <PrimaryButton onClick={() => setFileModalOpen(true)} data-public>
          Upload file
        </PrimaryButton>
      )}
      <PrimaryButton onClick={openCreateOrgModal} data-public>
        + New Organization
      </PrimaryButton>
      <CreateOrganizationModal
        open={newOrgModalOpen}
        handleClose={() => setNewOrgModalOpen(false)}
        onSubmit={createOrgCallback}
        submitting={creatingOrg}
      />
      <UploadListFromFile open={fileModalOpen} onClose={() => setFileModalOpen(false)} onComplete={onAddOrganization} />
    </Wrapper>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Wrapper = styled("div")({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});
