import { environmentAbbreviation, shouldDisplayEnvironmentIndicator } from "@coherehealth/common";

/*
 * A List of some Auth-App specific constants for padding/spacing/etc.
 *
 * We should consider moving this to common (or maybe making part of the theme?) as we start to build out other apps
 */

// If there is an environment indicator, we need to add height to the header
export const appHeaderHeight = (isDesktopScreen?: boolean, isVerySmallScreen?: boolean) => {
  const env = environmentAbbreviation();
  if (shouldDisplayEnvironmentIndicator(env)) {
    if (isVerySmallScreen && !isDesktopScreen) {
      return 119;
    } else {
      return 109;
    }
  } else {
    if (isVerySmallScreen && !isDesktopScreen) {
      return 84;
    } else {
      return 74;
    }
  }
};
export const headerHeight = 96;
export const slimFaxHeaderHeight = 80;
export const leftRailWidth = 248;
export const rightRailWidth = 362;
