import { Attachment, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { Location } from "react-router";

export function removeEmptyAttachments(attachments?: Attachment[]) {
  if (attachments) {
    return attachments.filter((attachment) => attachment.size && attachment.size > 0);
  }
  return attachments;
}

export const getAttachmentString = (serviceRequest?: ServiceRequestResponse): string => {
  return serviceRequest?.type && serviceRequest.type === "Pharmacy" ? "attachment" : "attachments";
};

export const useIsFaxAuthBuilderWorkflow = (location: Location) => location.pathname.includes("/fax_auth_builder/");

export const useIsFaxEditSRWorkflow = (location: Location) => location.pathname.includes("/fax_edit_service_request/");

export const useIsFaxReferralsWorkflow = (location: Location) => location.pathname.includes("/fax_referral_builder/");
