import AppProviders from "AppProviders";
import ErrorPage from "components/ErrorPage";
import { oauth2 as SMART } from "fhirclient";
import Client from "fhirclient/lib/Client";
import { createContext, useEffect, useMemo, useState } from "react";
import { RouteProps } from "react-router-dom";
import SmartOnFhirLoadingPage from "./SmartOnFhirLoadingPage";
import { error as logError } from "logger";

export const SmartOnFhirContext = createContext<Client | undefined>(undefined);

export default function SmartOnFhirSecurityProvider({ children }: RouteProps) {
  const [smartClient, setSmartClient] = useState<Client>();
  const [smartClientToken, setSmartClientToken] = useState("");
  const [failedToInitialize, setFailedToInitialize] = useState(false);

  useEffect(() => {
    SMART.ready()
      .then((client) => {
        setSmartClientToken(client.state.tokenResponse?.access_token || "");
        setSmartClient(client);
      })
      .catch((error) => {
        logError(error);
        setFailedToInitialize(true);
      });
  }, []);

  const value = useMemo(() => ({ smartClient, smartClientToken }), [smartClient, smartClientToken]);
  if (failedToInitialize) {
    return <ErrorPage />;
  } else if (Boolean(smartClient)) {
    return (
      <>
        <SmartOnFhirContext.Provider value={value.smartClient}>
          <AppProviders getAccessToken={() => Promise.resolve(value.smartClientToken)} getUser={() => undefined}>
            {children}
          </AppProviders>
        </SmartOnFhirContext.Provider>
      </>
    );
  } else {
    return <SmartOnFhirLoadingPage />;
  }
}
