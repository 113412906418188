import React, { SVGProps } from "react";

export default function ClipboardMissingInfo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40Z"
        fill="#FFF5E5"
      />
      <path d="M55.6013 23.175H23V61.1986H55.6013V23.175Z" fill="#A1AAB4" />
      <path d="M47.9131 25.4792H25.3066V58.8898H53.2814V30.861L47.9131 25.4792Z" fill="white" />
      <path
        d="M46.685 18.4665H42.3417C42.3417 16.5512 40.9794 15 39.2934 15C37.6073 15 36.245 16.5512 36.245 18.4665H31.9018L30.6743 26.1549H47.8989L46.685 18.4665Z"
        fill="#212936"
      />
      <path
        d="M33.8581 30.7825H31.5381V28.4625H29.9465V30.7825H27.6265V32.3741H29.9465V34.6941H31.5381V32.3741H33.8581V30.7825Z"
        fill="#EA1D63"
      />
      <path d="M46.5506 37.2708H32.0371V38.3634H46.5506V37.2708Z" fill="#89D3DD" />
      <path d="M50.597 54.7625H44.8105V55.8551H50.597V54.7625Z" fill="#89D3DD" />
      <path d="M50.5967 41.275H27.9902V42.3676H50.5967V41.275Z" fill="#C2C2C2" />
      <path d="M50.5967 44.5521H27.9902V45.6446H50.5967V44.5521Z" fill="#C2C2C2" />
      <path d="M50.5967 47.8333H27.9902V48.9259H50.5967V47.8333Z" fill="#C2C2C2" />
      <path d="M38.093 51.1083H27.9902V52.2009H38.093V51.1083Z" fill="#C2C2C2" />
      <path d="M47.9126 30.861H53.281L47.9126 25.4792V30.861Z" fill="#F5F5F5" />
      <g clipPath="url(#clip0_3999_78)">
        <circle cx="55.0105" cy="27.0105" r="12.0105" fill="white" />
        <path
          d="M55.0105 15C48.3807 15 43 20.3807 43 27.0105C43 33.6403 48.3807 39.021 55.0105 39.021C61.6403 39.021 67.021 33.6403 67.021 27.0105C67.021 20.3807 61.6403 15 55.0105 15ZM56.2115 33.0157H53.8094V30.6136H56.2115V33.0157ZM56.2115 28.2115H53.8094V21.0052H56.2115V28.2115Z"
          fill="#CB7F00"
        />
      </g>
      <defs>
        <clipPath id="clip0_3999_78">
          <rect width="24.021" height="24.021" fill="white" transform="translate(43 15)" />
        </clipPath>
      </defs>
    </svg>
  );
}
