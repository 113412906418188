import React, { useState, useEffect, useRef, Dispatch, SetStateAction, useContext } from "react";
import { TargetFieldLabel, useFeature } from "@coherehealth/common";
import {
  CarePathJourney,
  RuleActions,
  ServiceRequestResponse,
  AuthBuilderWorkflowStep,
  useGetBulkServiceRequestRuleActions,
  useGetServiceRequestRuleActions,
  useUpdateServiceRequest,
} from "@coherehealth/core-platform-api";
import {
  convertStringToAuthBuilderWorkflowStep,
  fetchRuleActions,
  navigateToPS,
  navigateToSRSummary,
} from "components/AuthBuilder/common";
import { ClinicalAssessmentProvider } from "components/AuthBuilder/index";
import {
  groupAuthAutoApproves,
  isProviderFastTrack,
  isRecommendChangeAction,
  isRedirectAction,
  ValidDisplayMessageAction,
} from "util/rule";
import ServiceRequestFormRedirectModal from "components/ServiceRequest/ServiceRequestForm/ServiceRequestFormRedirectModal";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTrackUserImpression } from "util/userActivityTracker";
import { useHasAcceptedAllNudges, getTargetsForAction } from "util/NudgeUtils";
import { SmartOnFhirContext } from "components/SmartOnFhir/SmartOnFhirSecurityProvider";
import ClinicalAssessmentContext from "components/ClinicalAssessment/ClinicalAssessmentContext";
import {
  getIntersectionObservationCodesAndSearchStatements,
  navigateStepEvalClinicalAssessment,
} from "util/workflowUtils";

interface Props {
  serviceRequests: ServiceRequestResponse[];
  setWorkflowStep: (step: AuthBuilderWorkflowStep) => void;
  carePathJourney: CarePathJourney | undefined;
  canContinueToClinicalAssessment: boolean;
  clinicalAssessmentProviders: ClinicalAssessmentProvider[];
  workflowId?: string;
  workflowStep: AuthBuilderWorkflowStep;
  patientId: string;
  serviceRequestId?: string;
  authFlowType: string;
  displayClearerDraftComponents: boolean;
  loadingAction: boolean;
  refetchActions: ReturnType<typeof useGetServiceRequestRuleActions>["refetch"];
  dedicatedNudgeIds: string[];
  setDedicatedNudgeIds: Dispatch<SetStateAction<string[]>>;
  setCurrentDedicatedNudgeTargetForAction: Dispatch<SetStateAction<TargetFieldLabel | undefined>>;
  setDedicatedNudgeServiceRequestId: (s: string) => void;
  setAttachmentsRuleActions: (actions: RuleActions | undefined) => void;
  setCaqSkipped: Dispatch<SetStateAction<boolean>>;
  serviceRequestPatientAttributes: Set<string> | undefined;
  clinicalAssessmentSnomedCodes: Set<string> | undefined;
  possibleAttachmentNudgeReasons?: Array<{ ruleId?: string; reasonText?: string }>;
  isContinuationWorkflow?: boolean;
  vendorAssessmentError?: boolean;
  delegatedVendorName: string | undefined;
  dedicatedNudgeResponses: boolean[];
  srLoadingActions?: boolean;
  setFooterHeight: Dispatch<SetStateAction<number>>;
}

export default function AttachmentsFooter({
  serviceRequests,
  setWorkflowStep,
  carePathJourney,
  canContinueToClinicalAssessment,
  clinicalAssessmentProviders,
  workflowId,
  workflowStep,
  patientId,
  serviceRequestId,
  authFlowType,
  displayClearerDraftComponents,
  loadingAction,
  refetchActions,
  dedicatedNudgeIds,
  possibleAttachmentNudgeReasons,
  setDedicatedNudgeIds,
  setCurrentDedicatedNudgeTargetForAction,
  setDedicatedNudgeServiceRequestId,
  setAttachmentsRuleActions,
  setCaqSkipped,
  serviceRequestPatientAttributes,
  clinicalAssessmentSnomedCodes,
  isContinuationWorkflow,
  vendorAssessmentError,
  delegatedVendorName,
  dedicatedNudgeResponses,
  srLoadingActions,
  setFooterHeight,
}: Props) {
  const { mutate: getBulkRuleActions, loading: loadingBulkActions } = useGetBulkServiceRequestRuleActions({});
  const trackUserImpression = useTrackUserImpression();
  const [goToDedicatedNudgeStep, setGoToDedicatedNudgeStep] = useState<boolean>(false);
  const [attachmentModalAction, setAttachmentModalAction] = useState<ValidDisplayMessageAction>();
  const [attachmentModalOpen, setAttachmentModalOpen] = useState<boolean>(false);
  const [willAutoApprove, setWillAutoApprove] = useState<boolean>(false);
  const isClearerDraftWorkflow = useFeature("clearerDraftWorkflow");
  const autoApprovalSkipCAQ = useFeature("autoApprovalSkipCAQ");
  const fastTrackProvider = useFeature("fastTrackProvider");
  const goToDedicatedNudgeStepRef = useRef(false);
  goToDedicatedNudgeStepRef.current = goToDedicatedNudgeStep;
  const { clinicalAssessment } = useContext(ClinicalAssessmentContext);
  const noAssessmentQuestions =
    (!clinicalAssessment?.questions || clinicalAssessment?.questions?.length === 0) &&
    !clinicalAssessmentProviders.includes("HealthHelp");
  //during the authbuild flow delegatedVendorName is set to undefined when HH assessment fails
  const skipWhenVendorAssessmentFails = !delegatedVendorName && vendorAssessmentError;
  const hasAcceptedAllNudges = useHasAcceptedAllNudges(dedicatedNudgeResponses);
  const serviceRequest = serviceRequests?.length && serviceRequests.length > 0 ? serviceRequests[0] : undefined;
  const addAttachmentDedicatedNudgeFF = useFeature("addAttachmentDedicatedNudge");

  const targetsForAction = getTargetsForAction(addAttachmentDedicatedNudgeFF);

  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: updateServiceRequest,
    error: updateServiceRequestError,
    loading: updateServiceRequestLoading,
  } = useUpdateServiceRequest({ id: "" });

  const navigate = useNavigate();

  useEffect(() => {
    if (updateServiceRequestError) {
      enqueueSnackbar("Failed to update service request, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [updateServiceRequestError, enqueueSnackbar]);

  const handleAttachmentModalClose = async (success: boolean) => {
    if (success) {
      setAttachmentModalOpen(false);
      setAttachmentModalAction(undefined);
    } else {
      const canContinueToReview = willAutoApprove || noAssessmentQuestions;
      if (canContinueToReview) {
        setAttachmentModalOpen(false);
        if (!hasAcceptedAllNudges) {
          await fetchRuleActions(
            serviceRequests,
            refetchActions,
            targetsForAction,
            dedicatedNudgeIds,
            setDedicatedNudgeIds,
            setCurrentDedicatedNudgeTargetForAction,
            setDedicatedNudgeServiceRequestId,
            setGoToDedicatedNudgeStep
          );
        }
      }
      navigateStepEvalClinicalAssessment({
        trackUserImpression,
        noAssessmentQuestions,
        setCaqSkipped,
        isContinuationWorkflow,
        isCurrentDedicatedNudge: goToDedicatedNudgeStepRef.current,
        setWorkflowStep,
        clinicalAssessmentProviders,
        goToReview: canContinueToReview,
        skipWhenVendorAssessmentFails,
        serviceRequest,
      });
    }
  };

  const continueToPreSubmissionOrDedicatedNudgeStep = async () => {
    let continueFlow = true;
    const ruleTimeout = setTimeout(() => {
      if (autoApprovalSkipCAQ) {
        navigateStepEvalClinicalAssessment({
          trackUserImpression,
          noAssessmentQuestions,
          setCaqSkipped,
          isContinuationWorkflow,
          isCurrentDedicatedNudge: goToDedicatedNudgeStepRef.current,
          setWorkflowStep,
          clinicalAssessmentProviders,
          goToReview: willAutoApprove,
          skipWhenVendorAssessmentFails,
          serviceRequest,
        });
      }
    }, 5000);
    const ruleActions = await getBulkRuleActions({
      serviceRequestIds: serviceRequests.map((sr) => sr.id),
      displayTarget: "ATTACHMENTS",
      authStage: convertStringToAuthBuilderWorkflowStep("ADD_ATTACHMENTS"),
    });
    setAttachmentsRuleActions(ruleActions);
    if (autoApprovalSkipCAQ || fastTrackProvider) {
      clearTimeout(ruleTimeout);
    }

    if (isClearerDraftWorkflow && authFlowType !== "PHARMACY") {
      await updateServiceRequestWorkflowStep();
    }
    const redirect = ruleActions.find(isRedirectAction);
    const recommendChange = ruleActions.find(isRecommendChangeAction);

    /**
     * If intersection exists don't skip caq questions, auth could pend overall
     * Else if auth would approve, it's safe to skip caq questions
     */
    const hasCaqSearchableStatementsIntersect = getIntersectionObservationCodesAndSearchStatements(
      serviceRequestPatientAttributes,
      clinicalAssessmentSnomedCodes
    );

    const canAutoApprove =
      (autoApprovalSkipCAQ && !hasCaqSearchableStatementsIntersect) || fastTrackProvider
        ? groupAuthAutoApproves(ruleActions)
        : false;

    let willAutoApproveAndIsFastTrack = false;
    if (fastTrackProvider) {
      const isFastTrackProvider = isProviderFastTrack(ruleActions);
      willAutoApproveAndIsFastTrack = canAutoApprove && isFastTrackProvider;
      setWillAutoApprove(willAutoApproveAndIsFastTrack || (autoApprovalSkipCAQ && canAutoApprove));
    } else {
      setWillAutoApprove(canAutoApprove);
    }
    if ((redirect && !willAutoApprove) || (recommendChange && !willAutoApprove)) {
      setAttachmentModalOpen(true);
      setAttachmentModalAction(redirect ?? recommendChange);
      continueFlow = false;
    }
    if (continueFlow) {
      const canContinueToReview = willAutoApprove || willAutoApproveAndIsFastTrack || noAssessmentQuestions;
      if (canContinueToReview) {
        await fetchRuleActions(
          serviceRequests,
          refetchActions,
          targetsForAction,
          dedicatedNudgeIds,
          setDedicatedNudgeIds,
          setCurrentDedicatedNudgeTargetForAction,
          setDedicatedNudgeServiceRequestId,
          setGoToDedicatedNudgeStep
        );
      }
      await navigateStepEvalClinicalAssessment({
        trackUserImpression,
        noAssessmentQuestions,
        setCaqSkipped,
        isContinuationWorkflow,
        isCurrentDedicatedNudge: goToDedicatedNudgeStepRef.current,
        setWorkflowStep,
        clinicalAssessmentProviders,
        goToReview: canContinueToReview,
        skipWhenVendorAssessmentFails,
        serviceRequest,
        skippedCAQFastTrack: willAutoApproveAndIsFastTrack,
      });
    }
  };

  const updateServiceRequestWorkflowStep = async () => {
    for (const sr of serviceRequests) {
      await updateServiceRequest(
        {
          workflowStep: workflowStep,
          possibleAttachmentNudgeReasons: possibleAttachmentNudgeReasons,
        },
        { pathParams: { id: sr?.id } }
      );
    }
  };

  const smartClient = useContext(SmartOnFhirContext);
  const inSmartOnFhirWorkflow = Boolean(smartClient);

  const onSaveAndExitClickButton = async () => {
    await updateServiceRequestWorkflowStep();
    if (inSmartOnFhirWorkflow) {
      navigateToSRSummary(serviceRequestId || "", navigate, true);
    } else {
      navigateToPS(patientId, navigate, serviceRequestId);
    }
  };

  return (
    <>
      <Footer
        onPrimaryButtonClick={continueToPreSubmissionOrDedicatedNudgeStep}
        primaryButtonLoading={loadingBulkActions || updateServiceRequestLoading || loadingAction || srLoadingActions}
        primaryButtonDisabled={
          loadingBulkActions ||
          !canContinueToClinicalAssessment ||
          attachmentModalOpen ||
          updateServiceRequestLoading ||
          srLoadingActions ||
          loadingAction
        }
        primaryButtonText={"Continue"}
        showSaveAndExitButton={displayClearerDraftComponents}
        onSaveAndExitClick={onSaveAndExitClickButton}
        saveAndExitButtonDisabled={
          loadingBulkActions || attachmentModalOpen || updateServiceRequestLoading || loadingAction
        }
        setFooterHeight={setFooterHeight}
      />
      <ServiceRequestFormRedirectModal
        open={attachmentModalOpen}
        onClose={handleAttachmentModalClose}
        redirectRuleAction={attachmentModalAction}
        objectType={"attachments"}
        context={{
          ruleId: attachmentModalAction?.ruleId,
          actionId: attachmentModalAction?.actionId,
          patientId: carePathJourney?.patient?.id,
          careJourneyId: carePathJourney?.id,
          workflowId: workflowId,
          serviceRequestId: serviceRequestId || serviceRequests[0]?.id,
        }}
        isHardStop={attachmentModalAction && isRedirectAction(attachmentModalAction)}
      />
    </>
  );
}
