import { Coverage, Patient } from "@coherehealth/core-platform-api";
import { Grid } from "@material-ui/core";
import { Dispatch, SetStateAction } from "react";
import ReferralRequestForm, { ReferralRequestFormContent, Row, useRRFormStyles } from "../ReferralRequestForm";
import { ReferralFormConfiguration } from "components/ReferralManagement/FormContentSpecification/ReferralRequestFormContentSpecifications";
import { Card } from "@coherehealth/common";
import { useIsFaxReferralsWorkflow } from "util/attachmentUtil";
import { useLocation } from "react-router";

interface Props {
  patientData?: Patient;
  coverage: Coverage | null;
  attemptSubmit: boolean;
  rrformContent: ReferralRequestFormContent;
  setRRFormContent: Dispatch<SetStateAction<ReferralRequestFormContent>>;
  rrFormConfiguration: ReferralFormConfiguration;
  setReferralRequestCanBeSubmitted: (b: boolean) => void;
  isAdministrative: boolean;
  hasPerformingSpecialistAttestation: boolean;
  setHasPerformingSpecialistAttestation: Dispatch<SetStateAction<boolean>>;
  hasFacilityAttestation: boolean;
  setHasFacilityAttestation: Dispatch<SetStateAction<boolean>>;
}

export default function ReferralRequestDetailsContainer({
  patientData,
  attemptSubmit,
  rrformContent,
  setRRFormContent,
  rrFormConfiguration,
  setReferralRequestCanBeSubmitted,
  isAdministrative,
  hasPerformingSpecialistAttestation,
  setHasPerformingSpecialistAttestation,
  hasFacilityAttestation,
  setHasFacilityAttestation,
  coverage,
}: Props) {
  const location = useLocation();
  const isFaxReferralsFlow = useIsFaxReferralsWorkflow(location);
  const classes = useRRFormStyles({ isFaxReferralsFlow });
  return (
    <>
      <Card className={classes.card} setPosition={false} noBorder={isFaxReferralsFlow} noBoxShadow={isFaxReferralsFlow}>
        <Grid container>
          <Row>
            <ReferralRequestForm
              patient={patientData}
              rrFormContent={rrformContent}
              setRRFormContent={setRRFormContent}
              rrFormConfiguration={rrFormConfiguration}
              attemptSubmit={attemptSubmit}
              setReferralRequestCanBeSubmitted={setReferralRequestCanBeSubmitted}
              isAdministrative={isAdministrative}
              hasPerformingSpecialistAttestation={hasPerformingSpecialistAttestation}
              setHasPerformingSpecialistAttestation={setHasPerformingSpecialistAttestation}
              hasFacilityAttestation={hasFacilityAttestation}
              setHasFacilityAttestation={setHasFacilityAttestation}
              coverage={coverage}
            />
          </Row>
        </Grid>
      </Card>
    </>
  );
}
