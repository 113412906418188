import React from "react";
import { generatePath } from "react-router";
import { useNavigate } from "react-router-dom";
import routes from "../../../routes";
import { formatDateStrWithMonthName } from "@coherehealth/common";
import { OrganizationResponse } from "@coherehealth/core-platform-api";
import CohereTable from "../CohereTable";
import OrgStatusChip from "../OrgStatusChip";

interface IProps {
  organizations: Array<OrganizationResponse>;
}

export default function OrganizationList({ organizations }: IProps) {
  const orgAddressString = (org: OrganizationResponse) => {
    return (
      `${org.address?.city || ""} ${org.address?.city && org.address?.state ? "," : ""} ${org.address?.state || ""}` ||
      ""
    );
  };

  const tableColumns = [
    {
      name: "Name",
      width: "20%",
      value: (org: OrganizationResponse) => org.name || "",
    },
    {
      name: "Location",
      width: "20%",
      value: (org: OrganizationResponse) => orgAddressString(org),
    },
    {
      name: "Last Modified",
      width: "20%",
      value: (org: OrganizationResponse) => formatDateStrWithMonthName(org.lastUpdated) || "",
    },
    {
      name: "Created By",
      width: "20%",
      value: (org: OrganizationResponse) => org.createdByName || "",
    },
    {
      name: "Status",
      width: "20%",
      value: (org: OrganizationResponse) => <OrgStatusChip orgStatus={org.status} />,
    },
  ];

  const navigate = useNavigate();
  return (
    <CohereTable
      tableColumns={tableColumns}
      data={organizations}
      onRowClick={(org, idx) => {
        navigate(generatePath(routes.ORGANIZATION_MANAGEMENT, { orgId: org?.id || "" }));
      }}
      dataPublic
    />
  );
}
