import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Author, useGetAuthorizationAuditHistory, useGetServiceRequestAuditHistory } from "@coherehealth/audit-api";
import { H6, InlineButton, convertEpochToTimeStamps, convertEpochToEastern } from "@coherehealth/common";
import { useSnackbar } from "notistack";
import { useAuthorized } from "authorization";
import config from "api/config";
import { AuthorizationResponse } from "@coherehealth/core-platform-api";
interface HistoryProps {
  authorization: AuthorizationResponse;
}

export default function AuthorizationAuditHistory({ authorization }: HistoryProps) {
  const [showSRHistory, setShowSRHistory] = useState(false);
  const {
    data: authorizationData,
    error: authorizationDataError,
    loading: authorizationDataLoading,
  } = useGetAuthorizationAuditHistory({
    base: `${config.AUDIT_SERVICE_API_URL}`,
    authId: authorization.id || "",
    queryParams: { embed_users: "true" },
  });

  const {
    data: serviceRequestData,
    error: serviceRequestDataError,
    refetch: serviceRequestDataRefetch,
  } = useGetServiceRequestAuditHistory({
    base: `${config.AUDIT_SERVICE_API_URL}`,
    srId: "",
    queryParams: { embed_users: "true" },
    lazy: true,
  });

  const { enqueueSnackbar } = useSnackbar();

  const AuthUpdateDescriptionEpochConv: string[] = ["updatedFields:lastUpdated", "updatedFields:decisionTimestamp"];

  const AuthFullDocumentEpochConv: string[] = ["lastUpdated", "decisionTimestamp"];

  const SRUpdateDescriptionEpochConv: string[] = [
    "updatedFields:lastUpdated",
    "updatedFields:autoApprovalSnapshot.timestamp",
    "updatedFields:intakeTimestamp",
    "updatedFields:integration:salesforce:lastUpdatedAt",
    "updatedFields:integration:welltok:lastUpdatedAt",
    "updatedFields:decisionTimestamp",
  ];

  const SRFullDocumentEpochConv: string[] = [
    "lastUpdated",
    "autoApprovalSnapshot.timestamp",
    "intakeTimestamp",
    "integration:salesforce:lastUpdatedAt",
    "integration:welltok:lastUpdatedAt",
    "decisionTimestamp",
  ];

  useEffect(() => {
    if (authorizationDataError) {
      enqueueSnackbar(`Error retrieving audit history: ${authorizationDataError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, authorizationDataError]);

  useEffect(() => {
    if (serviceRequestDataError) {
      enqueueSnackbar(`Error retrieving audit history: ${serviceRequestDataError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, serviceRequestDataError]);

  if (!useAuthorized("VIEW_SERVICE_REQUEST_AUDIT_LOG")) {
    return null;
  }

  if (authorizationDataLoading) {
    return <CircularProgress />;
  }
  if (!authorizationData) {
    return null;
  }

  return (
    <div>
      <H6>Audit history</H6>
      {authorization.serviceRequestsOnAuth?.map((sr) => {
        return (
          <ul>
            <InlineButton
              id={sr.id}
              onClick={() => {
                serviceRequestDataRefetch({ pathParams: { srId: sr.id } });
                setShowSRHistory(!showSRHistory);
              }}
            >
              {sr.id} - {sr.requestType}
            </InlineButton>
          </ul>
        );
      })}
      <ul>
        {showSRHistory &&
          [...(serviceRequestData || [])].map((change) => (
            <li>
              {change.operationType} - {formatAuthor(change.author || ({ id: "unknown" } as Author))} at{" "}
              {convertEpochToEastern(change?.clusterTime || 0)}
              <pre>
                {JSON.stringify(
                  change.updateDescription
                    ? convertEpochToTimeStamps(change.updateDescription, SRUpdateDescriptionEpochConv)
                    : convertEpochToTimeStamps(change.fullDocument, SRFullDocumentEpochConv),
                  undefined,
                  2
                )}
              </pre>
            </li>
          ))}
      </ul>
      {/* to simply divide between SR and authorization logs*/}
      <ul>-------</ul>
      <ul>
        {[...(authorizationData || [])].map((change) => (
          <li>
            {change.operationType} - {formatAuthor(change.author || ({ id: "unknown" } as Author))} at{" "}
            {convertEpochToEastern(change?.clusterTime || 0)}
            <pre>
              {JSON.stringify(
                change.updateDescription
                  ? convertEpochToTimeStamps(change.updateDescription, AuthUpdateDescriptionEpochConv)
                  : convertEpochToTimeStamps(change.fullDocument, AuthFullDocumentEpochConv),
                undefined,
                2
              )}
            </pre>
          </li>
        ))}
      </ul>
    </div>
  );
}

function formatAuthor({ id, name, email }: Author): string {
  if (name || email) {
    return `${name || "unknown name"} (${email || "unknown email"})`;
  }

  if (id === "sub") {
    return "unknown";
  }
  return `Okta user with id ${id}`;
}
