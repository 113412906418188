import React, { ComponentProps, useState, useEffect } from "react";

import {
  Body1,
  InformativeModal,
  MultiSelectDropdown,
  PrimaryButton,
  Switch,
  TextField,
  Tooltip,
  useStableUniqueId,
  onCloseBackDrop,
} from "@coherehealth/common";
import { OktaUserStatus, PhoneNumber, User, useValidateIfEmailAlreadyExists } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { createStyles, makeStyles, styled, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PhoneInput from "common/PhoneInput";
import parsePhoneNumber from "libphonenumber-js";
import { UserType } from "types/userType";
import { DEFAULT_SPECIALTY_OPTIONS as defaultSpecialtyOptions } from "../../../util/organization";
import { isEmailValid } from "components/OrganizationOnboardingPage/shared";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    switchLabel: {
      display: "flex",
      justifyContent: "space-between",
      paddingRight: theme.spacing(1),
      width: "100%",
    },
    switchLabelPlacementStartOverrides: {
      marginLeft: 0,
    },
  })
);

export type UserInput = Pick<
  User,
  "id" | "userType" | "roles" | "firstName" | "lastName" | "phone" | "email" | "specialties" | "title" | "extension"
>;

interface Props {
  open: boolean;
  handleClose: () => void;
  onSubmit: (arg0: UserInput) => void;
  submitting?: boolean;
  member?: UserInput;
  oktaUserStatus?: OktaUserStatus;
  requireAdmin?: boolean;
  disableAdminSwitch?: boolean;
  enableUserToEditInfo?: boolean;
}

const BLANK_MEMBER: UserInput = {
  id: "",
  userType: UserType.BackOffice,
  roles: [UserType.BackOffice],
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
};

export default function EditMemberModal({
  open,
  handleClose,
  onSubmit,
  submitting,
  member = BLANK_MEMBER,
  requireAdmin = false,
  disableAdminSwitch = false,
  oktaUserStatus = "PROVISIONED",
  enableUserToEditInfo = false,
}: Props) {
  const classes = useStyles();
  const titleId = useStableUniqueId("add-member");
  const instructionsId = useStableUniqueId("add-member-instructions");
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [newMemberInfo, setNewMemberInfo] = useState<UserInput>(member);
  const hasValidPhoneNumber = parseAsPhoneNumber(newMemberInfo.phone)?.number?.match(/^\d{10}$/);
  const hasValidFaxNumber = Boolean(newMemberInfo.extension?.mostRecentlyUsedFaxNumber)
    ? newMemberInfo.extension?.mostRecentlyUsedFaxNumber?.length === 10
    : true;

  const { data: verifyIfEmailAlreadyExists, refetch: checkVerifyIfEmailAlreadyExists } =
    useValidateIfEmailAlreadyExists({
      queryParams: {
        email: newMemberInfo.email,
      },
      lazy: true,
    });

  useEffect(() => {
    if (member?.id) {
      setNewMemberInfo(member);
    } else {
      const newUser = BLANK_MEMBER;
      newUser.roles = requireAdmin ? [UserType.BackOfficeAdmin] : [UserType.BackOffice];
      setNewMemberInfo(newUser);
    }
  }, [member, requireAdmin, setNewMemberInfo]);

  const allFieldsFilled =
    Boolean(newMemberInfo.firstName) && Boolean(newMemberInfo.lastName) && Boolean(newMemberInfo.email) && !emailError;
  Boolean(newMemberInfo.phone) && Boolean(newMemberInfo.title) && Boolean(newMemberInfo.specialties);

  const handleSubmit = () => {
    setAttemptedSubmit(true);

    if (isEmailValid(newMemberInfo.email || "")) {
      // if email hasn't changed while editing the user, don't bother checking if the email exists (as it will be the same)
      // else if the email has changed, check if it already exists before updating
      if (member.email === newMemberInfo.email) {
        if (allFieldsFilled && hasValidPhoneNumber && hasValidFaxNumber) {
          onSubmit(newMemberInfo);
          setAttemptedSubmit(false);
        }
      } else {
        checkVerifyIfEmailAlreadyExists().then((result) => {
          if (!result?.userAlreadyExists) {
            if (allFieldsFilled && hasValidPhoneNumber && hasValidFaxNumber) {
              onSubmit(newMemberInfo);
              setAttemptedSubmit(false);
            }
          }
        });
      }
    }
  };

  useEffect(() => {
    if (verifyIfEmailAlreadyExists?.userAlreadyExists) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }, [verifyIfEmailAlreadyExists]);

  const resetAndClose = () => {
    setEmailError(false);
    handleClose();
  };

  return (
    <InformativeModal
      headerText={
        enableUserToEditInfo
          ? "Edit profile"
          : newMemberInfo.id
          ? `${newMemberInfo?.firstName} ${newMemberInfo?.lastName}`
          : "Add user"
      }
      subHeaderText={
        !enableUserToEditInfo ? (newMemberInfo.id ? "Edit user information" : "Fill in the new user information") : ""
      }
      onClose={onCloseBackDrop(resetAndClose)}
      fullWidth
      aria-labelledby={titleId}
      aria-describedby={instructionsId}
      open={open}
      dataPublic
    >
      <Grid container spacing={2} alignItems="center" justifyContent="center" style={{ marginTop: "16px" }}>
        <Row>
          <TextField
            fullWidth
            name="First name"
            label="First name"
            value={newMemberInfo.firstName}
            onChangeValue={(firstName) => setNewMemberInfo({ ...newMemberInfo, firstName })}
            data-public
          />
        </Row>
        <Row>
          <TextField
            fullWidth
            name="Last name"
            label="Last name"
            value={newMemberInfo.lastName}
            onChangeValue={(lastName) => setNewMemberInfo({ ...newMemberInfo, lastName })}
            data-public
          />
        </Row>
        <Row>
          <PhoneInput
            fullWidth
            name="Phone Number"
            label="Phone number"
            value={parseAsPhoneNumber(newMemberInfo.phone)}
            onChange={(phone: PhoneNumber) => {
              setNewMemberInfo({
                ...newMemberInfo,
                phone: phone.extension ? `${phone.number} ext. ${phone.extension}` : phone.number,
              });
            }}
            error={attemptedSubmit && !hasValidPhoneNumber}
            helperText={attemptedSubmit && !hasValidPhoneNumber && "Phone number must be 10 digits"}
            type="tel"
            data-public
          />
        </Row>
        <Row>
          <PhoneInput
            fullWidth
            name="Fax number"
            label="Fax number (optional)"
            value={parseAsPhoneNumber(newMemberInfo.extension?.mostRecentlyUsedFaxNumber)}
            onChange={(mostRecentlyUsedFaxNumber: PhoneNumber) =>
              setNewMemberInfo({
                ...newMemberInfo,
                extension: {
                  id: newMemberInfo.extension?.id,
                  mostRecentlyUsedFaxNumber: mostRecentlyUsedFaxNumber.number,
                },
              })
            }
            hideExtensionField={true}
            error={attemptedSubmit && !hasValidFaxNumber}
            helperText={attemptedSubmit && !hasValidFaxNumber && "Fax number must be 10 digits or empty"}
            type="faxNumber"
            data-public
          />
        </Row>
        <Row>
          {oktaUserStatus !== "PROVISIONED" ? (
            <Tooltip title="Email can't be changed after account activation" data-public>
              <div>
                <TextField value={newMemberInfo.email} disabled fullWidth data-public />
              </div>
            </Tooltip>
          ) : (
            <TextField
              fullWidth
              name="Email Address"
              label="Email address"
              value={newMemberInfo.email}
              onChangeValue={(email) => {
                setNewMemberInfo({ ...newMemberInfo, email });
                setEmailError(false);
              }}
              type="email"
              error={emailError && verifyIfEmailAlreadyExists?.userAlreadyExists}
              helperText={
                verifyIfEmailAlreadyExists?.userAlreadyExists && emailError
                  ? "An account with this email already exists."
                  : ""
              }
              data-public
            />
          )}
        </Row>
        <Row>
          <TextField
            fullWidth
            name="Title"
            label="Title (e.g. Pre-cert manager)"
            value={newMemberInfo.title}
            onChangeValue={(title) => setNewMemberInfo({ ...newMemberInfo, title })}
            data-public
          />
        </Row>
        <Row>
          <MultiSelectDropdown
            label="User specialties (for any vendor, not just Cohere)"
            options={defaultSpecialtyOptions}
            onChange={(specialties) => setNewMemberInfo({ ...newMemberInfo, specialties })}
            selectedValues={newMemberInfo.specialties}
            maxMenuHeight={"389px"}
            data-public
          />
        </Row>
        <Row>
          <SwitchContainer>
            <Switch
              fullWidth
              disabled={requireAdmin || disableAdminSwitch}
              checked={newMemberInfo.roles?.includes(UserType.BackOfficeAdmin)}
              label={<SwitchLabel isAdmin={newMemberInfo.roles?.includes(UserType.BackOfficeAdmin) || false} />}
              onChange={(checked) =>
                setNewMemberInfo({
                  ...newMemberInfo,
                  roles: checked ? [UserType.BackOfficeAdmin] : [UserType.BackOffice],
                })
              }
              FormControlLabelProps={{
                classes: {
                  label: classes.switchLabel,
                  labelPlacementStart: classes.switchLabelPlacementStartOverrides,
                },
                labelPlacement: "start",
              }}
              data-public
            />
          </SwitchContainer>
        </Row>
      </Grid>
      <ButtonContainer>
        <CreateButton loading={submitting} disabled={!allFieldsFilled} onClick={handleSubmit} data-public>
          {newMemberInfo.id ? "Save" : "Create"}
        </CreateButton>
      </ButtonContainer>
    </InformativeModal>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ButtonContainer = styled("div")(({ theme }) => ({
  justifyContent: "center",
  padding: theme.spacing(3, 1, 4),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CreateButton = styled(PrimaryButton)(({ theme }) => ({
  padding: `${theme.spacing(2)}px ${theme.spacing(10)}px`,
}));

const Row = (props: ComponentProps<typeof Grid>) => <Grid item xs={12} {...props} />;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SwitchContainer = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 4,
  padding: theme.spacing(1.5),
}));

const SwitchLabel = ({ isAdmin }: { isAdmin: boolean }) => (
  <>
    <Body1>Organization admin</Body1>
    <Body1>{isAdmin ? "Yes" : "No"}</Body1>
  </>
);

const parseAsPhoneNumber = (number?: string): PhoneNumber => {
  const parsed = parsePhoneNumber(number || "", "US");
  const phone = {
    number: parsed?.nationalNumber?.toString(),
    extension: parsed?.ext?.toString(),
  };
  return phone;
};
