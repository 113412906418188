import { DateSelect } from "../DateSelect";
import { BaseFilterConfig } from "./shared";

export interface DatePickerFilterConfig extends BaseFilterConfig {
  filterType: "DatePicker";
  initialValue?: Date;
  initialFilterValue?: unknown;
}

export interface DatePickerFilterProps {
  filterConfig: DatePickerFilterConfig;
  value: Date | null | undefined;
  onChange?: (key: string, newDate: Date) => void;
}

export function DatePickerFilter({ filterConfig, value, onChange }: DatePickerFilterProps) {
  const { key, label, initialValue } = filterConfig;
  const handleChange: (newDate: Date) => void = (newDate: Date) => {
    if (onChange) {
      onChange(key, newDate);
    }
  };
  const textFieldStyle = {
    width: "100%",
  };
  return (
    <DateSelect
      label={label}
      value={value || initialValue || null}
      onDateChange={handleChange}
      TextFieldProps={{
        style: textFieldStyle,
      }}
    />
  );
}
