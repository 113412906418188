import React from "react";
import { Theme } from "@material-ui/core/styles";
import { colorsDark } from "../../themes/colors";
import { makeStyles } from "@material-ui/core";
import MUITooltip from "@material-ui/core/Tooltip";

export interface StyleProps {
  maxWidth?: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  arrow: {
    color: colorsDark.background.light,
  },
  tooltip: {
    boxShadow: "0px 2px 4px rgba(33, 36, 41, 0.05)",
    borderRadius: 8, // border-radius > 8px starts to look disconnected in start/end placements
    padding: theme.spacing(2, 3),
    backgroundColor: colorsDark.background.light,
    ...theme.typography.body1,
    maxWidth: ({ maxWidth }) => maxWidth,
  },
}));
const Tooltip = React.forwardRef((props: any, ref) => {
  const { children, maxWidth, ...otherProps } = props;

  const classes = useStyles({ maxWidth });

  return (
    <MUITooltip ref={ref} classes={{ tooltip: classes.tooltip, arrow: classes.arrow }} arrow {...otherProps}>
      {children}
    </MUITooltip>
  );
});

export default Tooltip;
