import { useEffect } from "react";
import { useValidateEmailToken } from "@coherehealth/core-platform-api";
import { useNavigate, useLocation } from "react-router-dom";
import routes from "routes";
import { useSnackbar } from "notistack";
import { LinearProgress } from "@material-ui/core";

export default function VerifyUserPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: validateEmailToken } = useValidateEmailToken({});

  useEffect(() => {
    const fetchVerification = async () => {
      const urlParams = new URLSearchParams(location.search);
      const token = urlParams.get("token");

      if (token) {
        try {
          const response = await validateEmailToken({ token: token });
          if (response.status) {
            // Redirect to dashboard if successful
            navigate(routes.DASHBOARD);
          } else {
            enqueueSnackbar(`Invalid token`, {
              variant: "error",
            });
          }
        } catch (error) {
          enqueueSnackbar(`Failed to validate token`, {
            variant: "error",
          });
        } finally {
        }
      } else {
        enqueueSnackbar(`No token provided`, {
          variant: "error",
        });
      }
    };

    fetchVerification();
  }, [enqueueSnackbar, location.search, navigate, validateEmailToken]);

  return <LinearProgress />;
}
