import React, { useState } from "react";
import {
  ServiceRequestResponse,
  Attachment,
  AuthorizationResponse,
  ReferralRequestResponse,
} from "@coherehealth/core-platform-api";
import { Grid, useTheme } from "@material-ui/core";
import { PrimaryButton } from "@coherehealth/common";
import { AddAttachmentsToRequest } from "../AddAttachments";
import { removeEmptyAttachments } from "util/attachmentUtil";
import AttachmentSectionHeader from "./AttachmentSectionHeader";
import { ReferralAttachmentsWrapper } from "components/AddAttachments/AddAttachmentsToRequest";

interface Props {
  authorization?: AuthorizationResponse;
  // If this is an Authorization, this is the latest SR
  serviceRequest?: ServiceRequestResponse;
  //referralRequest and serviceRequest should be mutually exclusive and should not be both available
  referralRequest?: ReferralRequestResponse;
  setViewState: (state: "READ_ONLY" | "EDIT") => void;
  onUpdateAttachments: (attachments: Attachment[]) => void | undefined;
  allowMultipleAttachmentFiles?: boolean;
  fileSizeLimit?: number;
  onSaveAttachments?: () => void;
}

export default function AttachmentSectionEdit({
  authorization,
  serviceRequest,
  referralRequest,
  setViewState,
  onUpdateAttachments,
  allowMultipleAttachmentFiles,
  fileSizeLimit,
  onSaveAttachments,
}: Props) {
  const [attachmentsCompleted, setAttachmentsCompleted] = useState(true);
  const theme = useTheme();

  // Gather attachments from all ServiceRequests in the Authorization
  const authorizationAttachments: Attachment[] = [];
  if (authorization && authorization.serviceRequestsOnAuth) {
    authorization.serviceRequestsOnAuth.forEach((sr) => {
      sr.attachments?.forEach((attachment) => {
        authorizationAttachments.push(attachment);
      });
    });
  }
  //remove empty attachments from either attachments gathered from auth above, or use attachments associated with serviceRequest or referralRequest based on props passed in
  const srAttachmentList = !!authorization
    ? removeEmptyAttachments(authorizationAttachments)
    : referralRequest?.id
    ? removeEmptyAttachments(referralRequest?.attachments)
    : removeEmptyAttachments(serviceRequest?.attachments);

  const referralAttachments: Attachment[] = [];
  if (referralRequest) {
    referralRequest.attachments?.forEach((attachment) => {
      referralAttachments.push(attachment);
    });
  }

  return (
    <Grid container item xs={12} justifyContent="space-between" spacing={3} style={{ margin: 0 }}>
      <Grid item xs={6} style={{ padding: 0 }}>
        <AttachmentSectionHeader attachmentsList={srAttachmentList} serviceRequest={serviceRequest} />
      </Grid>
      <Grid item style={{ padding: 0 }}>
        <PrimaryButton
          onClick={() => {
            setViewState("READ_ONLY");
            onSaveAttachments?.();
          }}
          disabled={!attachmentsCompleted}
        >
          Save
        </PrimaryButton>
      </Grid>
      {serviceRequest?.id && (
        <Grid item xs={12} style={{ padding: theme.spacing(1.5, 0) }}>
          <AddAttachmentsToRequest
            serviceRequestIds={[serviceRequest.id]}
            workflowId={serviceRequest?.workflowId}
            initialAttachments={srAttachmentList}
            onUpdateAttachments={onUpdateAttachments}
            setAttachmentsCompleted={setAttachmentsCompleted}
            stage="PATIENT_SUMMARY"
            allowMultipleAttachmentFiles={allowMultipleAttachmentFiles}
            fileSizeLimit={fileSizeLimit}
          />
        </Grid>
      )}
      {referralRequest?.id && (
        <Grid item xs={12} style={{ padding: theme.spacing(1.5, 0, 0, 0) }}>
          <ReferralAttachmentsWrapper
            referralRequestIds={[referralRequest.id]}
            initialAttachments={referralAttachments}
            onUpdateAttachments={onUpdateAttachments}
            setAttachmentsCompleted={setAttachmentsCompleted}
            stage="REFERRAL_PATIENT_SUMMARY"
            allowMultipleAttachmentFiles={allowMultipleAttachmentFiles}
            fileSizeLimit={fileSizeLimit}
          />
        </Grid>
      )}
    </Grid>
  );
}
