import React, { useEffect } from "react";
import { Body1, Card, H1, H4, H6, Caption, colorsLight, formatDateStr, patientDisplayName } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import { useGetPatient, Coverage, Patient } from "@coherehealth/core-platform-api";

import MissingInfo from "components/images/MissingInfo.svg";
import CircularProgress from "@material-ui/core/CircularProgress";

import { formatAnyPhone, patientGenderDisplay, singleLineAddress } from "util/demographicUtils";
import { getSortedListOfCoverages } from "util/patientUtils";

interface Props {
  patientId: string;
  patientInput?: Patient;
}
export function PatientEdit({ patientId, patientInput }: Props) {
  let {
    data: patient,
    refetch: refetchPatient,
    loading,
  } = useGetPatient({
    id: patientId,
    lazy: true,
  });

  useEffect(() => {
    if (!patientInput) {
      console.log("fetching");
      refetchPatient();
    }
  }, [refetchPatient, patientInput]);

  if (patientInput) {
    patient = patientInput;
  }

  return (
    <PatientDemographicsCard>
      {loading ? (
        <CircularProgress size={24} />
      ) : patient ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div>
              <H4 style={{ paddingBottom: 4 }}>{patientDisplayName(patient)}</H4>
              <Body1 style={{ color: colorsLight.font.secondary }}>Member ID {patient.memberId}</Body1>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <PatientInfoSection patient={patient} />
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </PatientDemographicsCard>
  );
}

export function NoPatientDisplay() {
  return (
    <PatientDemographicsCard>
      <Grid container spacing={1} direction="row" justify="space-between" alignItems="center">
        <Grid item xs={2}>
          <img alt="" src={MissingInfo} />
        </Grid>
        <Grid item xs={6}>
          <H1>No patient selected</H1>
          <H6>Select a patient to continue</H6>
        </Grid>
      </Grid>
    </PatientDemographicsCard>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PatientDemographicsCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

interface IProps {
  patient: Patient;
}

function PatientInfoSection({ patient }: IProps) {
  let coverages = getSortedListOfCoverages(patient);
  let activePrimaryCoverage = null;
  if (coverages?.[0]?.planActive) {
    activePrimaryCoverage = coverages.shift();
  }

  return (
    <>
      <Grid container direction="row" spacing={1}>
        <Grid container item xs={5}>
          <PatientInfoField label="DOB" value={formatDateStr(patient.dateOfBirth)} xs={4} />
          <PatientInfoField label="Sex" value={patientGenderDisplay(patient.gender)} xs={4} />
          <PatientInfoField label="Phone" value={formatAnyPhone(patient.phones)} xs={4} />
        </Grid>
        <Grid container item xs={7}>
          <PatientInfoField
            label="Preferred written language"
            value={patient.languagePreference?.written}
            xs={4}
            style={{ marginLeft: -30, paddingTop: 0 }}
          />
          <PatientInfoField
            label="PCP grouper ID"
            value={activePrimaryCoverage?.grouperId}
            xs={3}
            style={{ paddingTop: 0, paddingLeft: 16, paddingRight: 20 }}
          />
          <PatientInfoField label="Address" value={singleLineAddress(patient.address)} xs={5} />
        </Grid>

        {activePrimaryCoverage && (
          <>
            <DividerSection />
            <CoverageInfoSection coverage={activePrimaryCoverage} isPrimary />
          </>
        )}
        {coverages?.map((additionalCoverage, idx) => (
          <React.Fragment key={idx}>
            <DividerSection />
            <CoverageInfoSection coverage={additionalCoverage} />
          </React.Fragment>
        ))}
      </Grid>
    </>
  );
}

const DividerSection = () => (
  <Grid item xs={12}>
    <Divider />
  </Grid>
);

const PatientInfoField = ({ label, value, xs, style }: { label: string; value?: string; xs?: any; style?: any }) => (
  <Grid item xs={xs} style={style ? { ...style } : { paddingTop: 0, paddingBottom: 0 }}>
    <Caption style={{ color: colorsLight.font.secondary }}>{label}</Caption>
    <Body1>{value || "-"}</Body1>
  </Grid>
);

const CoverageInfoSection = ({ coverage, isPrimary = false }: { coverage?: Coverage; isPrimary?: boolean }) => (
  <>
    <Grid container item xs={5}>
      <PatientInfoField
        label={isPrimary ? "Primary plan" : "Additional plan"}
        value={(coverage?.platformCode || coverage?.sourceSystem) === "HP" ? "Author" : "Humana"}
        xs={4}
      />
      <PatientInfoField label="Membership type" value={coverage?.lineOfBusinessType} xs={4} />
      <PatientInfoField label="Plan type" value={coverage?.productType} xs={3} />
    </Grid>

    <PatientInfoField
      label="Plan year"
      value={`${formatDateStr(coverage?.planStart)} - ${formatDateStr(coverage?.planEnd)}`}
      xs={3}
      style={{ marginLeft: -30, paddingTop: 0 }}
    />
  </>
);
