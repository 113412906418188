import { Dispatch, SetStateAction, useState, useEffect } from "react";
import ClinicalReviewCard from "common/ClinicalReviewCard";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Grid, styled } from "@material-ui/core";
import {
  H5,
  Body1,
  incrementClickCount,
  UserClickInfoClinicalReviewPage,
  Sanitized,
  MCGCiteLogo,
  useFeature,
} from "@coherehealth/common";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useGetEpisodeSummary } from "@coherehealth/core-platform-api";
import { useTheme } from "@material-ui/core/styles";
interface Props {
  episodeSummaryId: string | undefined;
  objectType: "ServiceRequest" | "Authorization";
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
}

export default function CWQIEpisode({ episodeSummaryId, objectType, setUserClickInfoTracking }: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { spacing } = useTheme();
  const {
    data: episodeSummary,
    refetch: getSummary,
    loading,
  } = useGetEpisodeSummary({
    episodeSummaryId: episodeSummaryId || "",
    queryParams: {
      objectType: objectType || "ServiceRequest",
    },
    requestOptions: { headers: { Connection: `keep-alive` } },
    lazy: true,
  });
  useEffect(() => {
    if (episodeSummaryId && isExpanded) {
      getSummary();
    }
  }, [getSummary, isExpanded, episodeSummaryId]);
  const updateUserClickInfo = () => {
    //TODO click backend
    setUserClickInfoTracking && incrementClickCount("CarewebQICardToggle", "", -1, setUserClickInfoTracking);
  };
  const logrocketUnmasking = useFeature("logrocketUnmasking");

  return (
    <ClinicalReviewCard
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      updateUserClickInfo={updateUserClickInfo}
      header={
        <Grid container>
          <Grid item xs={12} style={{ marginBottom: 6 }}>
            <H5 data-public={!!logrocketUnmasking ? true : undefined}>CareWebQI Episode Summary</H5>
          </Grid>
        </Grid>
      }
    >
      {isExpanded && (
        <>
          {!episodeSummaryId || loading || !episodeSummary ? (
            <CircularProgress />
          ) : (
            <>
              {(episodeSummary as string).includes("Episode does not exist.") ? (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: spacing(2),
                      marginBottom: spacing(2.5),
                    }}
                  >
                    <StyledBody1>An MCG episode does not exist for this service request</StyledBody1>
                  </Grid>
                </Grid>
              ) : (
                <>
                  <div style={{ marginBottom: "8px" }}>
                    <MCGCiteLogo episodeCard />
                  </div>
                  <div style={{ marginBottom: "-18px" }}>
                    <Sanitized __html={episodeSummary} forceBody />
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </ClinicalReviewCard>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledBody1 = styled(Body1)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
