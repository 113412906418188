import { useCallback, useEffect, useState, forwardRef } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { User, UserResponse, useUpdateUser, useUpdateUserExtension } from "@coherehealth/core-platform-api";
import { Avatar, Body2, Caption, H4 } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core/styles";
import EditMemberModal from "components/ProviderOrganization/OrganizationDetails/EditMemberModal";
import { useSnackbar } from "notistack";
import { useTrackUserInteraction } from "util/userActivityTracker";
const useStyles = makeStyles((theme) => ({
  // We need a container with position relative so we can absolute position the HeaderDrowndownContainer dropdown on the right
  userAvatar: {
    marginRight: theme.spacing(2),
  },
  menuItem: {
    padding: theme.spacing(1), // default is 2
  },
}));
interface Props {
  handleClose(): void;
  currentUserInfo: UserResponse | undefined;
  getUserData: (arg0: string | undefined) => void;
}
const CurrentUserMenuItem = forwardRef(({ handleClose, currentUserInfo, getUserData }: Props, ref) => {
  const [editMemberModalOpen, setEditMemberModalOpen] = useState(false);
  const [editingMember, setEditingMember] = useState<User | undefined>({});
  const { enqueueSnackbar } = useSnackbar();
  const trackInteraction = useTrackUserInteraction();

  const classes = useStyles();

  const {
    mutate: updateUser,
    loading: updatingUser,
    error: userUpdateError,
  } = useUpdateUser({
    id: editingMember?.id || "",
  });

  const {
    mutate: updateUserFaxExtension,
    loading: userUpdateFaxExtensionLoading,
    error: userUpdateFaxExtensionError,
  } = useUpdateUserExtension({
    id: editingMember?.id || "",
  });

  const updateMember = useCallback(
    async (user: User, userId: string) => {
      await updateUser({ ...user }, { pathParams: { id: userId } });
      setEditMemberModalOpen(false);
      trackInteraction({
        event: "PROFILE_UPDATED",
        stage: "USER_DASHBOARD",
        activityContext: {
          userEmail: user.email,
          userId: user.id,
          organizationId: user.organization,
        },
      });
      if (user.extension?.mostRecentlyUsedFaxNumber !== editingMember?.extension?.mostRecentlyUsedFaxNumber) {
        let mostRecentlyUsedFaxNumber = user.extension?.mostRecentlyUsedFaxNumber;
        await updateUserFaxExtension({ mostRecentlyUsedFaxNumber }, { pathParams: { id: userId } });
      }
      setEditingMember({});
      getUserData(user.id);
      enqueueSnackbar(`Profile saved`, {
        variant: "success",
        preventDuplicate: true,
      });
    },
    [
      updateUser,
      trackInteraction,
      editingMember?.extension?.mostRecentlyUsedFaxNumber,
      getUserData,
      enqueueSnackbar,
      updateUserFaxExtension,
    ]
  );

  useEffect(() => {
    if (userUpdateError) {
      enqueueSnackbar(`There was an error updating the user ${userUpdateError.message}`, {
        variant: "error",
      });
    }
    if (userUpdateFaxExtensionError) {
      enqueueSnackbar(`There was an error updating the fax extension of user ${userUpdateFaxExtensionError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, updatingUser, userUpdateError, userUpdateFaxExtensionError, userUpdateFaxExtensionLoading]);

  const checkIfNameUpdated = (user: User, editingMember: User | undefined) => {
    if (user.firstName !== editingMember?.firstName) {
      user.name = editingMember?.lastName ? user.firstName?.concat(" ").concat(editingMember?.lastName) : user.name;
    } else if (user.lastName !== editingMember?.lastName) {
      user.name = user.lastName ? editingMember?.firstName?.concat(" ").concat(user.lastName) : user.name;
    }

    return user.name;
  };

  return (
    <>
      <EditMemberModal
        open={editMemberModalOpen}
        handleClose={() => {
          setEditMemberModalOpen(false);
          setEditingMember({});
        }}
        onSubmit={async (user: User) => {
          user.name = checkIfNameUpdated(user, editingMember);
          if (user.id) {
            await updateMember(user, user.id);
          }
        }}
        submitting={updatingUser}
        member={editingMember || undefined}
        requireAdmin={editingMember?.roles?.includes("admin")}
        oktaUserStatus={editingMember?.oktaStatus || undefined}
        disableAdminSwitch={true}
        enableUserToEditInfo={true}
      />
      <MenuItem
        className={classes.menuItem}
        onClick={() => {
          handleClose();
          setEditingMember(currentUserInfo);
          setEditMemberModalOpen(true);
        }}
        innerRef={ref}
      >
        <Avatar className={classes.userAvatar}>
          <H4>{(currentUserInfo && `${currentUserInfo.firstName?.[0]}${currentUserInfo.lastName?.[0]}`) || "?"}</H4>
        </Avatar>
        {currentUserInfo && (
          <div>
            <Body2>{currentUserInfo.name}</Body2>
            <Caption>{currentUserInfo.email}</Caption>
          </div>
        )}
        {!Boolean(currentUserInfo) && (
          <div>
            <Body2>Cannot determine who you are</Body2>
            <Caption>Refresh, or logout if this persists</Caption>
          </div>
        )}
      </MenuItem>
    </>
  );
});

export default CurrentUserMenuItem;
