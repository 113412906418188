import React from "react";

export interface ModelTrainingIconProps {
  height?: string;
  width?: string;
}

const ModelTrainingIconBlue = ({ height, width }: ModelTrainingIconProps) => {
  return (
    <svg
      width={width ? width : "32"}
      height={height ? height : "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0C7.13573 0 0 7.18872 0 16C0 24.8092 7.1887 32 16 32C24.8091 32 32 24.8113 32 16C32 7.19088 24.8648 0 16 0Z"
        fill="#039EC3"
      />
      <path
        d="M19.5 17.5C19.5 19.5 17 21 17 22.5H15C15 21 12.5 19.5 12.5 17.5C12.5 15.57 14.07 14 16 14C17.93 14 19.5 15.57 19.5 17.5ZM17 23.5H15V25H17V23.5ZM23 17C23 18.68 22.41 20.21 21.42 21.42L22.84 22.84C24.18 21.27 25 19.23 25 17C25 14.26 23.77 11.81 21.84 10.16L20.42 11.58C21.99 12.86 23 14.82 23 17ZM20 9L16 5V8C11.03 8 7 12.03 7 17C7 19.23 7.82 21.27 9.16 22.84L10.58 21.42C9.59 20.21 9 18.68 9 17C9 13.14 12.14 10 16 10V13L20 9Z"
        fill="#F5F5F5"
      />
    </svg>
  );
};

export default ModelTrainingIconBlue;
