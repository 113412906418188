import React, { SVGProps } from "react";

export default function BuildingIconInfo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40 79.2188C61.6599 79.2188 79.2188 61.6599 79.2188 40C79.2188 18.3401 61.6599 0.78125 40 0.78125C18.3401 0.78125 0.78125 18.3401 0.78125 40C0.78125 61.6599 18.3401 79.2188 40 79.2188Z"
        fill="#D1C7E4"
      />
      <path d="M64.5156 26.7495H46.6562V62.062H64.5156V26.7495Z" fill="#5E6572" />
      <path d="M54.125 29.2026H49.2188V34.1089H54.125V29.2026Z" fill="#EAE9F4" />
      <path d="M61.9375 29.2026H57.0312V34.1089H61.9375V29.2026Z" fill="#EAE9F4" />
      <path d="M61.4531 33.6094L61.0625 30.0625L57.5156 29.6875H61.4531V33.6094Z" fill="#8162B9" />
      <path d="M54.125 37.5781H49.2188V42.4844H54.125V37.5781Z" fill="#EAE9F4" />
      <path d="M61.9375 37.5781H57.0312V42.4844H61.9375V37.5781Z" fill="#EAE9F4" />
      <path d="M54.125 45.9526H49.2188V50.8589H54.125V45.9526Z" fill="#EAE9F4" />
      <path d="M61.9375 45.9526H57.0312V50.8589H61.9375V45.9526Z" fill="#EAE9F4" />
      <path d="M54.125 54.3281H49.2188V59.2344H54.125V54.3281Z" fill="#EAE9F4" />
      <path d="M61.9375 54.3281H57.0312V59.2344H61.9375V54.3281Z" fill="#EAE9F4" />
      <path d="M33.3438 26.7495H15.4844V62.062H33.3438V26.7495Z" fill="#5E6572" />
      <path d="M22.9688 29.2026H18.0625V34.1089H22.9688V29.2026Z" fill="#EAE9F4" />
      <path d="M30.7812 29.2026H25.875V34.1089H30.7812V29.2026Z" fill="#EAE9F4" />
      <path d="M22.9688 37.5781H18.0625V42.4844H22.9688V37.5781Z" fill="#EAE9F4" />
      <path d="M30.7812 37.5781H25.875V42.4844H30.7812V37.5781Z" fill="#EAE9F4" />
      <path d="M22.9688 45.9526H18.0625V50.8589H22.9688V45.9526Z" fill="#EAE9F4" />
      <path d="M30.7812 45.9526H25.875V50.8589H30.7812V45.9526Z" fill="#EAE9F4" />
      <path d="M22.9688 54.3281H18.0625V59.2344H22.9688V54.3281Z" fill="#EAE9F4" />
      <path d="M30.7812 54.3281H25.875V59.2344H30.7812V54.3281Z" fill="#EAE9F4" />
      <path d="M61.4531 41.9995L61.0625 38.437L57.5156 38.062H61.4531V41.9995Z" fill="#8162B9" />
      <path d="M61.4531 50.375L61.0625 46.8125L57.5156 46.4375H61.4531V50.375Z" fill="#8162B9" />
      <path d="M61.4531 58.749L61.0625 55.1865L57.5156 54.8115H61.4531V58.749Z" fill="#8162B9" />
      <path d="M53.6406 33.6094L53.25 30.0625L49.7031 29.6875H53.6406V33.6094Z" fill="#8162B9" />
      <path d="M53.6406 41.9995L53.25 38.437L49.7031 38.062H53.6406V41.9995Z" fill="#8162B9" />
      <path d="M53.6406 50.375L53.25 46.8125L49.7031 46.4375H53.6406V50.375Z" fill="#8162B9" />
      <path d="M53.6406 58.749L53.25 55.1865L49.7031 54.8115H53.6406V58.749Z" fill="#8162B9" />
      <path d="M30.2969 33.6094L29.8906 30.0625L26.3594 29.6875H30.2969V33.6094Z" fill="#8162B9" />
      <path d="M30.2969 41.9995L29.8906 38.437L26.3594 38.062H30.2969V41.9995Z" fill="#8162B9" />
      <path d="M30.2969 50.375L29.8906 46.8125L26.3594 46.4375H30.2969V50.375Z" fill="#8162B9" />
      <path d="M30.2969 58.749L29.8906 55.1865L26.3594 54.8115H30.2969V58.749Z" fill="#8162B9" />
      <path d="M22.4844 33.6094L22.0937 30.0625L18.5469 29.6875H22.4844V33.6094Z" fill="#8162B9" />
      <path d="M22.4844 41.9995L22.0937 38.437L18.5469 38.062H22.4844V41.9995Z" fill="#8162B9" />
      <path d="M22.4844 50.375L22.0937 46.8125L18.5469 46.4375H22.4844V50.375Z" fill="#8162B9" />
      <path d="M22.4844 58.749L22.0937 55.1865L18.5469 54.8115H22.4844V58.749Z" fill="#8162B9" />
      <path d="M49.8125 13.0308H30.2031V62.062H49.8125V13.0308Z" fill="#6F7580" />
      <path
        d="M44.625 62.062H35.375V50.5308C35.375 49.8745 35.9062 49.3589 36.5469 49.3589H43.4375C44.0937 49.3589 44.6094 49.8901 44.6094 50.5308V62.062H44.625Z"
        fill="#5E6572"
      />
      <path d="M46.2656 15.4526H33.7344V19.7495H46.2656V15.4526Z" fill="#F5F5F5" />
      <path d="M35.4531 19.7495H35.0938L37.0937 15.4526H37.4531L35.4531 19.7495Z" fill="#C2C2C2" />
      <path d="M36.5156 19.7495H35.9375L37.9375 15.4526H38.5156L36.5156 19.7495Z" fill="#C2C2C2" />
      <path d="M46.2656 24.5464H33.7344V28.8433H46.2656V24.5464Z" fill="#F5F5F5" />
      <path d="M46.2656 33.6396H33.7344V37.9365H46.2656V33.6396Z" fill="#F5F5F5" />
      <path d="M46.2656 42.7183H33.7344V47.0151H46.2656V42.7183Z" fill="#F5F5F5" />
      <path d="M35.4531 28.8433H35.0938L37.0937 24.5464H37.4531L35.4531 28.8433Z" fill="#C2C2C2" />
      <path d="M36.5156 28.8433H35.9375L37.9375 24.5464H38.5156L36.5156 28.8433Z" fill="#C2C2C2" />
      <path d="M35.4531 37.9209H35.0938L37.0937 33.6396H37.4531L35.4531 37.9209Z" fill="#C2C2C2" />
      <path d="M36.5156 37.9209H35.9375L37.9375 33.6396H38.5156L36.5156 37.9209Z" fill="#C2C2C2" />
      <path d="M35.4531 47.0151H35.0938L37.0937 42.7183H37.4531L35.4531 47.0151Z" fill="#C2C2C2" />
      <path d="M36.5156 47.0151H35.9375L37.9375 42.7183H38.5156L36.5156 47.0151Z" fill="#C2C2C2" />
      <path d="M42.3594 19.7495H41.9844L43.9844 15.4526H44.3594L42.3594 19.7495Z" fill="#C2C2C2" />
      <path d="M42.3594 28.8433H41.9844L43.9844 24.5464H44.3594L42.3594 28.8433Z" fill="#C2C2C2" />
      <path d="M42.3594 37.9209H41.9844L43.9844 33.6396H44.3594L42.3594 37.9209Z" fill="#C2C2C2" />
      <path d="M42.3594 47.0151H41.9844L43.9844 42.7183H44.3594L42.3594 47.0151Z" fill="#C2C2C2" />
    </svg>
  );
}
