import config from "api/config";
import { compact, uniqBy, differenceBy } from "lodash";
import {
  ServiceRequestResponse,
  AuthorizationResponse,
  Attachment,
  TatExtensionLetterAttachment,
  InternalFinalDeterminationLetterAttachment,
  InternalFinalDeterminationFaxAttachment,
  FinalDeterminationLetterAttachment,
  InternallyGeneratedLetterNotification,
  ExternallyGeneratedLetterNotification,
  FaxNotification,
  WelltokVATNotification,
} from "@coherehealth/core-platform-api";
import {
  getTatExtensionLetterByLetterAddressee,
  getFinalDeterminationLetterByRecipientType,
  getInternalFinalDeterminationLetterByLetterAddressee,
} from "util/serviceRequest";
import sortBy from "lodash/sortBy";

// API response data types for various *Notification domains in Core Platform
export type NotificationResponse =
  | InternallyGeneratedLetterNotification
  | ExternallyGeneratedLetterNotification
  | FaxNotification
  | WelltokVATNotification;

// The above, but wrapped inside NotificationData
export type WrappedNotificationResponse =
  | InternalLetterNotification
  | ExternalLetterNotification
  | InternalFaxNotification
  | WelltokVatLetterNotification;

export type WrappedPDFTemplatedNotificationResponse =
  | InternalLetterNotification
  | ExternalLetterNotification
  | InternalFaxNotification;

export type WrappedLetterNotificationResponse = InternalLetterNotification | ExternalLetterNotification;

// Type predicates to help with narrowing, when processing an Array of mixed
// notification types
export const isInternallyGeneratedLetterNotification = (
  notificationResponse: NotificationResponse
): notificationResponse is InternallyGeneratedLetterNotification => {
  return notificationResponse.notificationType === "InternallyGeneratedLetterNotification";
};
export const isExternallyGeneratedLetterNotification = (
  notificationResponse: NotificationResponse
): notificationResponse is ExternallyGeneratedLetterNotification => {
  return notificationResponse.notificationType === "ExternallyGeneratedLetterNotification";
};
export const isFaxNotification = (
  notificationResponse: NotificationResponse
): notificationResponse is FaxNotification => {
  return notificationResponse.notificationType === "FaxNotification";
};
export const isWelltokVATNotification = (
  notificationResponse: NotificationResponse
): notificationResponse is WelltokVATNotification => {
  return notificationResponse.notificationType === "WelltokVATNotification";
};

// Identifies the appropriate type and wraps the API response in NotificationData
export const wrapNotificationResponse = (
  notificationResponse: NotificationResponse,
  serviceRequest?: ServiceRequestResponse
): NotificationData | null => {
  const srData = serviceRequest
    ? { serviceRequest: { id: serviceRequest.id, cohereId: serviceRequest.cohereId } }
    : undefined;

  if (isInternallyGeneratedLetterNotification(notificationResponse)) {
    return {
      variant: "INTERNAL_LETTER",
      data: notificationResponse,
      ...srData,
    };
  }
  if (isExternallyGeneratedLetterNotification(notificationResponse)) {
    return {
      variant: "EXTERNAL_LETTER",
      data: notificationResponse,
      ...srData,
    };
  }
  if (isFaxNotification(notificationResponse)) {
    return {
      variant: "INTERNAL_FAX",
      data: notificationResponse,
      ...srData,
    };
  }
  if (isWelltokVATNotification(notificationResponse)) {
    return {
      variant: "WELLTOK_VAT_LETTER",
      data: notificationResponse,
      ...srData,
    };
  }

  return null;
};

// Used to determine if we can display data specific to the *Notification types
// from Core Platform, like statusList
export const isLettersExpansionNotification = (
  notification: NotificationData
): notification is WrappedNotificationResponse => {
  return (
    isInternalLetterNotification(notification) ||
    isExternalLetterNotification(notification) ||
    isInternalFaxNotification(notification) ||
    isWelltokVatLetterNotification(notification)
  );
};

export const hasAssociatedPDFTemplate = (
  notification: NotificationData
): notification is WrappedPDFTemplatedNotificationResponse => {
  return (
    isInternalLetterNotification(notification) ||
    isExternalLetterNotification(notification) ||
    isInternalFaxNotification(notification)
  );
};

export const isLetterNotification = (
  notification: NotificationData
): notification is WrappedLetterNotificationResponse => {
  return isInternalLetterNotification(notification) || isExternalLetterNotification(notification);
};

/*
 * In this context, "Notification" is a general term including the *Notification
 * domains as well as any letter, attachment, fax, or other form of
 * commmunication (mostly PDFs) we want to display in the Viewer.
 *
 * NotificationData is a wrapper around all these different types. It contains
 * some common metadata which helps us work with them somewhat interchangeably.
 *
 * Therefore the Viewer mostly works with NotificationData objects instead
 * of the original API response types.
 */
export type NotificationData =
  | AttachmentNotification
  | ServiceSummaryNotification
  | FinalDeterminationLetterNotification
  | InternalFinalDeterminationFaxNotification
  | InternalFaxNotification
  | InternalFinalDeterminationLetterNotification
  | TatExtensionLetterNotification
  | InternalLetterNotification
  | ExternalLetterNotification
  | WelltokVatLetterNotification;

/*
 * Wrappers around the various types of Notifications, with `variant` for
 * discriminated union.
 */

// Any SR info useful to bring along with a notification - notably the cohereId
type ServiceRequestBase = {
  serviceRequest?: Partial<ServiceRequestResponse>;
};

// SR attachments uploaded by users or jobs
export type AttachmentNotification = ServiceRequestBase & {
  variant: "ATTACHMENT";
  data: Attachment;
};
export const isAttachmentNotification = (notification: NotificationData): notification is AttachmentNotification => {
  return notification.variant === "ATTACHMENT";
};

// Auth and SR Service Summary PDFs, generated on-demand by Cohere
export type ServiceSummaryNotification = ServiceRequestBase & {
  variant: "SERVICE_SUMMARY";
  data: Attachment;
};
export const isServiceSummaryNotification = (
  notification: NotificationData
): notification is ServiceSummaryNotification => {
  return notification.variant === "SERVICE_SUMMARY";
};

// Determination letter PDFs received from Matrix, our mail vendor
export type FinalDeterminationLetterNotification = ServiceRequestBase & {
  variant: "FINAL_DETERMINATION_LETTER";
  data: FinalDeterminationLetterAttachment;
};

// Determination letter PDFs generated by Cohere, and sent to the GHP print shop
export type InternalFinalDeterminationLetterNotification = ServiceRequestBase & {
  variant: "INTERNAL_FINAL_DETERMINATION_LETTER";
  data: InternalFinalDeterminationLetterAttachment;
};

// Determination Fax PDFs generated by Cohere
export type InternalFinalDeterminationFaxNotification = ServiceRequestBase & {
  variant: "INTERNAL_FINAL_DETERMINATION_FAX";
  data: InternalFinalDeterminationFaxAttachment;
};

// TAT Extension PDFs generated by Cohere
export type TatExtensionLetterNotification = ServiceRequestBase & {
  variant: "TAT_EXTENSION_LETTER";
  data: TatExtensionLetterAttachment;
};

// Ad hoc Fax PDFs, including QIO/appeals, generated by Cohere
export type InternalFaxNotification = ServiceRequestBase & {
  variant: "INTERNAL_FAX";
  data: FaxNotification;
};
export const isInternalFaxNotification = (notification: NotificationData): notification is InternalFaxNotification => {
  return notification.variant === "INTERNAL_FAX";
};

// Ad hoc (and eventually final determination) letter PDFs generated by Cohere
export type InternalLetterNotification = ServiceRequestBase & {
  variant: "INTERNAL_LETTER";
  data: InternallyGeneratedLetterNotification;
};
export const isInternalLetterNotification = (
  notification: NotificationData
): notification is InternalLetterNotification => {
  return notification.variant === "INTERNAL_LETTER";
};

// Ad hoc (and eventually final determination) letter PDFs, generated by Matrix and others
export type ExternalLetterNotification = ServiceRequestBase & {
  variant: "EXTERNAL_LETTER";
  data: ExternallyGeneratedLetterNotification;
};
export const isExternalLetterNotification = (
  notification: NotificationData
): notification is ExternalLetterNotification => {
  return notification.variant === "EXTERNAL_LETTER";
};

// VAT notification PDF received from external integrations
export type WelltokVatLetterNotification = ServiceRequestBase & {
  variant: "WELLTOK_VAT_LETTER";
  data: WelltokVATNotification;
};
export const isWelltokVatLetterNotification = (
  notification: NotificationData
): notification is WelltokVatLetterNotification => {
  return notification.variant === "WELLTOK_VAT_LETTER";
};

// For each notification we augment the core data with metadata about
// PDF viewing and downloading.
export type NotificationViewerItem = NotificationData & {
  fileName?: string;
  // Backend endpoint to download file
  downloadPath?: string;
  // Local blob URL, after download
  fileUrl?: string;
  // Usually PDF, but sometimes JPG
  contentType?: string;
  // If the JPG was converted to an OCR-enhanced PDF
  convertedToPDF?: boolean;
  // Currently unused in this component
  isOcr?: boolean;
};

/*
 * Creating placeholder Notifications for Service Summaries
 *
 * Service Summaries are not pre-existing files in S3; rather, they are PDFs
 * generated on-demand. So we construct a "shell" Notification and make it look
 * like a regular Attachment.
 *
 * There are 2 types of summaries to support:
 * 1) Authorization Service Summary, which includes info about all the child SRs
 *   - Pass args `authorization` and `serviceRequestOnAuth`
 * 2) Individual Service Request Service Summary
 *   - Pass arg `serviceRequest`
 */
export const createNotificationForServiceSummary = (
  serviceRequest?: ServiceRequestResponse,
  authorization?: AuthorizationResponse,
  serviceRequestsOnAuth?: ServiceRequestResponse[]
): NotificationData => {
  const currentTime = new Date().toISOString();

  const attachment: Attachment = {
    id: "",
    name: "",
    dateCreated: currentTime,
    lastUpdated: currentTime,
    contentType: "application/pdf",
    serviceRequest: {
      id: "",
    },
  };

  if (authorization && serviceRequestsOnAuth) {
    const cohereId =
      authorization.authNumber || serviceRequestsOnAuth[0]?.cohereId || serviceRequestsOnAuth[0]?.authNumber || "";

    attachment.id = `service_summary_authorization_${authorization.id}`;
    attachment.name = `CohereAuthorization_${cohereId}.pdf`;
    attachment.serviceRequest = {
      id: serviceRequestsOnAuth?.[0]?.id || "",
    };
  } else if (serviceRequest) {
    const cohereId = serviceRequest.cohereId || "";

    attachment.id = `service_summary_servicerequest_${serviceRequest.id}`;
    attachment.name = `CohereServiceRequest_${cohereId}.pdf`;
    attachment.serviceRequest = {
      id: serviceRequest.id || "",
    };
  }

  const notification: ServiceSummaryNotification = {
    variant: "SERVICE_SUMMARY",
    data: attachment,
  };

  return notification;
};

/*
 * Functions to select the latest letters out of a service request.
 * Organizes them into Cohere's preferred order of presenting letters.
 */

// The latest TAT Extension letters, by addressee
const getLatestTatExtensionLetters = (serviceRequest: ServiceRequestResponse): NotificationData[] => {
  const attachments = serviceRequest.tatExtensionLetterAttachments;
  if (!attachments?.length) {
    return [];
  }

  /*
   * If these get* functions don't find a letter for the specified addressee,
   * they return the first letter in the array, as a fallback.
   * For example, if there are no PCP letters, the function might
   * return a PATIENT letter as a fallback.
   *
   * It is also possible for the functions to return `undefined`.
   * Therefore we compact (remove undefined and null) and dedupe the array.
   */

  // TAT extension letters have letterAddressee (PATIENT, PROVIDER, PCP)
  const patientLetter = getTatExtensionLetterByLetterAddressee("PATIENT", attachments);
  const providerLetter = getTatExtensionLetterByLetterAddressee("PROVIDER", attachments);
  const pcpLetter = getTatExtensionLetterByLetterAddressee("PCP", attachments);

  const letters = uniqBy(compact([patientLetter, providerLetter, pcpLetter]), "id");
  return letters.map((letter) => ({
    variant: "TAT_EXTENSION_LETTER",
    data: letter,
    serviceRequest: { id: serviceRequest.id, cohereId: serviceRequest.cohereId },
  }));
};

// The latest Internal FDL letters, by addressee
const getLatestInternalFinalDeterminationLetters = (serviceRequest: ServiceRequestResponse): NotificationData[] => {
  const attachments = serviceRequest.internalFinalDeterminationLetterAttachments;
  if (!attachments?.length) {
    return [];
  }

  // Internal FDL letters have letterAddressee (PATIENT, PROVIDER, PCP)
  const patientLetter = getInternalFinalDeterminationLetterByLetterAddressee("PATIENT")(attachments);
  const providerLetter = getInternalFinalDeterminationLetterByLetterAddressee("PROVIDER")(attachments);
  const pcpLetter = getInternalFinalDeterminationLetterByLetterAddressee("PCP")(attachments);

  let letters = uniqBy(compact([patientLetter, providerLetter, pcpLetter]), "id");
  return letters.map((letter) => ({
    variant: "INTERNAL_FINAL_DETERMINATION_LETTER",
    data: letter,
    serviceRequest: { id: serviceRequest.id, cohereId: serviceRequest.cohereId },
  }));
};

const getLatestInternalFinalDeterminationFaxes = (
  serviceRequest: ServiceRequestResponse
): NotificationData | undefined => {
  const attachments = serviceRequest.internalFinalDeterminationFaxAttachments;
  if (!attachments?.length) {
    return undefined;
  }
  const requestorLetter = sortBy(attachments || [], (a) => a.dateCreated).reverse()[0];
  return {
    variant: "INTERNAL_FINAL_DETERMINATION_FAX",
    data: requestorLetter,
    serviceRequest: { id: serviceRequest.id, cohereId: serviceRequest.cohereId },
  };
};

// The latest FDL letters, by addressee
const getLatestFinalDeterminationLetters = (serviceRequest: ServiceRequestResponse): NotificationData[] => {
  const attachments = serviceRequest.finalDeterminationLetterAttachments;
  if (!attachments?.length) {
    return [];
  }

  // FDL letters have recipientType (PATIENT and PROVIDER only)
  const patientLetter = getFinalDeterminationLetterByRecipientType("PATIENT")(attachments);
  const providerLetter = getFinalDeterminationLetterByRecipientType("PROVIDER")(attachments);

  let letters = uniqBy(compact([patientLetter, providerLetter]), "id");
  return letters.map((letter) => ({
    variant: "INTERNAL_FINAL_DETERMINATION_LETTER",
    data: letter,
    serviceRequest: { id: serviceRequest.id, cohereId: serviceRequest.cohereId },
  }));
};

// Prepares the final determination and TAT letters for display in the Notification Viewer
export const createNotificationsToShow = (serviceRequest: ServiceRequestResponse): NotificationData[] => {
  const notifications = [];

  const latestTatExtensionLetters = getLatestTatExtensionLetters(serviceRequest);
  const latestInternalFinalDeterminationLetters = getLatestInternalFinalDeterminationLetters(serviceRequest);
  const latestInternalFinalDeterminationFax = getLatestInternalFinalDeterminationFaxes(serviceRequest); //use this
  const latestFinalDeterminationLetters = getLatestFinalDeterminationLetters(serviceRequest);

  // An SR will have either internal or non-internal determination letters, but not both.
  const hasInternalLetters =
    (serviceRequest.internalFinalDeterminationLetterAttachments?.length || 0) +
      (serviceRequest.internalFinalDeterminationFaxAttachments?.length || 0) >
    0;

  if (hasInternalLetters) {
    const allInternalFinalDeterminationLetters: NotificationData[] = (
      serviceRequest.internalFinalDeterminationLetterAttachments || []
    ).map((letter) => ({
      variant: "INTERNAL_FINAL_DETERMINATION_LETTER",
      data: letter,
      serviceRequest: { id: serviceRequest.id, cohereId: serviceRequest.cohereId },
    }));

    // If we have space, show a few older internal letters as well.
    const NUM_OLDER_LETTERS_TO_SHOW = 12;

    const olderInternalFinalDeterminationLetters = differenceBy(
      allInternalFinalDeterminationLetters,
      latestInternalFinalDeterminationLetters,
      (letter) => letter.data.id
    ).slice(0, NUM_OLDER_LETTERS_TO_SHOW);

    if (latestInternalFinalDeterminationFax) {
      notifications.push(latestInternalFinalDeterminationFax);
    }

    // TAT letters come third
    notifications.push(
      ...latestInternalFinalDeterminationLetters,
      ...latestTatExtensionLetters,
      ...olderInternalFinalDeterminationLetters
    );
  } else {
    // We don't show older non-internal letters.
    notifications.push(...latestFinalDeterminationLetters, ...latestTatExtensionLetters);
  }

  return notifications;
};

/*
 * Utility functions
 */

// The Attachment Viewer uses fetch() instead of restful-react GET hooks.
// So we construct the download URLs here.
export const createDownloadPath = (notification: NotificationData) => {
  const baseUrl = config.SERVICE_API_URL;

  let serviceRequestId: string | undefined = "";
  let attachmentId: string | undefined = "";
  let notificationId: string | undefined = "";
  let downloadPath = "";

  switch (notification.variant) {
    case "SERVICE_SUMMARY":
      serviceRequestId = notification.data?.serviceRequest?.id;
      attachmentId = notification.data?.id;
      // Any missing id will result in a URL that will 404.
      if (!serviceRequestId || !attachmentId) {
        break;
      }

      if (attachmentId.startsWith("service_summary_servicerequest_")) {
        const srId = attachmentId.replace(/^service_summary_servicerequest_/, "");
        downloadPath = `${baseUrl}serviceRequest/${srId}/serviceSummary`;
      } else if (attachmentId?.startsWith("service_summary_authorization_")) {
        const authId = attachmentId.replace(/^service_summary_authorization_/, "");
        downloadPath = `${baseUrl}authorization/${authId}/serviceSummary`;
      }
      break;
    case "FINAL_DETERMINATION_LETTER":
      serviceRequestId = notification.data?.serviceRequest?.id;
      attachmentId = notification.data?.id;
      if (!serviceRequestId || !attachmentId) {
        break;
      }
      downloadPath = `${baseUrl}serviceRequest/${serviceRequestId}/finalDeterminationLetterAttachment/${attachmentId}/download`;
      break;
    case "INTERNAL_FINAL_DETERMINATION_LETTER":
      serviceRequestId = notification.data?.serviceRequest?.id;
      attachmentId = notification.data?.id;
      if (!serviceRequestId || !attachmentId) {
        break;
      }
      downloadPath = `${baseUrl}internalFinalDeterminationLetter/${serviceRequestId}/${attachmentId}/download`;
      break;
    case "INTERNAL_FINAL_DETERMINATION_FAX":
      serviceRequestId = notification.data?.serviceRequest?.id;
      attachmentId = notification.data?.id;
      if (!serviceRequestId || !attachmentId) {
        break;
      }
      downloadPath = `${baseUrl}internalFinalDeterminationFax/${serviceRequestId}/${attachmentId}/download`;
      break;
    case "TAT_EXTENSION_LETTER":
      attachmentId = notification.data?.id;
      if (!attachmentId) {
        break;
      }
      downloadPath = `${baseUrl}tatExtensionLetterAttachment/${attachmentId}/download`;
      break;
    case "INTERNAL_LETTER":
    case "EXTERNAL_LETTER":
    case "INTERNAL_FAX":
      notificationId = notification.data.id;
      // If there was an error in PDF generation, we shouldn't try to download a file that doesn't exist.
      if (!notification.data.pdfUrl || !notificationId) {
        return "";
      }
      downloadPath = `${baseUrl}notification/${notificationId}/download`;
      break;
    case "WELLTOK_VAT_LETTER":
      notificationId = notification.data.id;
      if (!notificationId) {
        break;
      }
      // WARNING: WelltokVATNotification is not fully implemented yet.
      // Until it is updated to have a pdfUrl, no file willl be downloaded.
      downloadPath = `${baseUrl}notification/${notificationId}/download`;
      break;
    // Attachment is also the default.
    case "ATTACHMENT":
    default:
      serviceRequestId = notification.data?.serviceRequest?.id;
      attachmentId = notification.data?.id;
      if (!serviceRequestId || !attachmentId) {
        break;
      }
      downloadPath = `${baseUrl}serviceRequest/${serviceRequestId}/attachment/${attachmentId}/download`;
  }

  return downloadPath;
};

// A naive way of preserving acronyms.
const acronymsToRemainCapitalized = ["PCP", "TAT", "VAT", "GHP", "PDF", "JSON", "RRD"];
const capitalize = (str: string) => {
  if (acronymsToRemainCapitalized.includes(str)) {
    return str;
  } else {
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
  }
};
export const capitalizeUnderscoredString = (str: string) => str.split("_").map(capitalize).join("_");
export const capitalizeAndRemoveUnderscore = (str: string) => str.split("_").map(capitalize).join(" ");

// Attachments already have file names, but Notifications need one created.
// Used by file downloading and printing functions.
// If we start using non-PDFs (e.g. JPG) for Notifications, this logic needs to be updated.
export const createFileName = (notification: NotificationData) => {
  let fileName = "";
  const idString = `${notification.serviceRequest?.cohereId ?? notification.serviceRequest?.id ?? ""}_${
    notification.data.id
  }`;

  switch (notification.variant) {
    case "INTERNAL_LETTER":
      fileName = `Internal_Letter_${capitalizeUnderscoredString(
        notification.data.purpose || ""
      )}_${capitalizeUnderscoredString(notification.data.recipient || "")}_${idString}.pdf`;
      break;
    case "EXTERNAL_LETTER":
      fileName = `External_Letter_${capitalizeUnderscoredString(
        notification.data.purpose || ""
      )}_${capitalizeUnderscoredString(notification.data.recipient || "")}_${idString}.pdf`;
      break;
    case "INTERNAL_FAX":
      fileName = `Fax_${capitalizeUnderscoredString(notification.data.purpose || "")}_${capitalizeUnderscoredString(
        notification.data.recipient || ""
      )}_${idString}.pdf`;
      break;
    case "WELLTOK_VAT_LETTER":
      fileName = `Welltok_VAT_Letter_${capitalizeUnderscoredString(
        notification.data.purpose || ""
      )}_${capitalizeUnderscoredString(notification.data.recipient || "")}_${idString}.pdf`;
      break;
    case "SERVICE_SUMMARY":
      fileName = notification.data.name || `Service_Summary_${idString}.pdf`;
      break;
    case "FINAL_DETERMINATION_LETTER":
      fileName = notification.data.name || `Final_Determination_Letter_${idString}.pdf`;
      break;
    case "INTERNAL_FINAL_DETERMINATION_LETTER":
      fileName = notification.data.name || `Internal_Final_Determination_Letter_${idString}.pdf`;
      break;
    case "INTERNAL_FINAL_DETERMINATION_FAX":
      fileName = notification.data.name || `Internal_Final_Determination_Fax_${idString}.pdf`;
      break;
    case "TAT_EXTENSION_LETTER":
      fileName = notification.data.name || `TAT_Extension_Letter_${idString}.pdf`;
      break;
    case "ATTACHMENT":
      fileName = notification.data.name || `Attachment_${idString}.pdf`;
      break;
    default:
      fileName = "";
  }

  return fileName;
};

// Display name in the viewer left side panel
export const createDisplayName = (notification: NotificationViewerItem) => {
  const cohereId = notification.serviceRequest?.cohereId;
  const cohereIdSuffix = cohereId ? ` #${cohereId}` : "";

  let displayName = "";

  switch (notification.variant) {
    case "INTERNAL_LETTER":
      displayName = `${capitalizeAndRemoveUnderscore(notification.data.purpose || "Internal")} Letter${cohereIdSuffix}`;
      break;
    case "EXTERNAL_LETTER":
      displayName = `${capitalizeAndRemoveUnderscore(notification.data.purpose || "External")} Letter${cohereIdSuffix}`;
      break;
    case "INTERNAL_FAX":
      displayName = `${capitalizeAndRemoveUnderscore(notification.data.purpose || "Internal")} Fax${cohereIdSuffix}`;
      break;
    case "WELLTOK_VAT_LETTER":
      displayName = `${capitalizeAndRemoveUnderscore(notification.data.purpose || "VAT")} Letter${cohereIdSuffix}`;
      break;
    case "FINAL_DETERMINATION_LETTER":
    case "INTERNAL_FINAL_DETERMINATION_LETTER":
      displayName = `Decision Letter${cohereIdSuffix}`;
      break;
    case "TAT_EXTENSION_LETTER":
      displayName = `Pend (TAT Extension) Letter${cohereIdSuffix}`;
      break;
    case "SERVICE_SUMMARY":
      displayName = "Service Summary";
      break;
    case "ATTACHMENT":
      if (notification.data.name) {
        displayName = notification.data.name;
      }
      break;
  }

  return (
    displayName ||
    notification.fileName ||
    `${capitalizeAndRemoveUnderscore(notification.variant)} ${notification.data.id}`
  );
};

// We display the recipient under each file.
export const getRecipientOfNotification = (notification: NotificationData) => {
  let recipient: string | undefined;

  switch (notification.variant) {
    case "INTERNAL_LETTER":
    case "EXTERNAL_LETTER":
    case "INTERNAL_FAX":
    case "WELLTOK_VAT_LETTER":
      recipient = notification.data.recipient;
      break;
    case "FINAL_DETERMINATION_LETTER":
      recipient = notification.data.recipientType;
      break;
    case "INTERNAL_FINAL_DETERMINATION_LETTER":
    case "TAT_EXTENSION_LETTER":
      recipient = notification.data.letterAddressee;
      break;
  }

  return recipient ? capitalizeAndRemoveUnderscore(recipient) : null;
};
