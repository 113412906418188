interface ConfigInterface {
  AUTH_API_URL: string;
  AUTH_CLIENT_ID: string;
  ENV_RESTRICTED_ROLE_PREFIX: string;
  HEALTH_HELP_URL: string;
  PREVIEW_ID: string | null;
  PR_NUMBER: string;
  SERVICE_API_URL: string;
  QM_SERVICE_API_URL: string;
  AUDIT_SERVICE_API_URL: string;
  INT_OBS_SERVICE_API_URL: string;
  STORYBOOK_URL: string;
  SENTRY_ENABLED: boolean;
  CURRENT_BRANCH: string;
  CURRENT_GIT_HASH: string;
  CURRENT_GIT_HASH_TIMESTAMP: string;
  SENTRY_DSN: string;
  SENTRY_ENVIRONMENT: string;
  SENTRY_SAMPLE_RATE: number;
  LIMIT_5XX_RESPONSES: number;
  CAN_DELETE_SERVICE_REQUEST_COLLECTION: boolean;
  UNAUTH_STATUS_CHECK_URL: string;
  HAS_QM_ENVIRONMENT: boolean;
}

const config: ConfigInterface = {
  AUTH_API_URL: `https://${process.env.REACT_APP_OKTA_DOMAIN}/`,
  AUTH_CLIENT_ID: `${process.env.REACT_APP_OKTA_CLIENT_ID}`,
  ENV_RESTRICTED_ROLE_PREFIX: `${process.env.REACT_APP_ENV_RESTRICTED_ROLE_PREFIX || ""}`,
  HEALTH_HELP_URL: `${process.env.REACT_APP_HEALTH_HELP_URL}`,
  PREVIEW_ID: new URLSearchParams(window?.location?.search?.substring(1)).get("preview"),
  PR_NUMBER: `${process.env.REACT_APP_PR_NUMBER}`,
  SERVICE_API_URL: `${process.env.REACT_APP_API_URL}`,
  QM_SERVICE_API_URL: `${process.env.REACT_APP_QM_API_URL}`,
  AUDIT_SERVICE_API_URL: `${process.env.REACT_APP_AUDIT_API_URL}`,
  INT_OBS_SERVICE_API_URL: `${process.env.REACT_APP_INT_OBS_API_URL}`,
  UNAUTH_STATUS_CHECK_URL:
    process.env.REACT_APP_UNAUTH_STATUS_CHECK_URL ||
    (process.env.REACT_APP_API_URL === "http://localhost:8080/"
      ? "https://core-platform-dev.coherehealth.com/"
      : `${process.env.REACT_APP_API_URL}`),
  STORYBOOK_URL: "http://localhost:9010/",
  SENTRY_ENABLED: process.env.REACT_APP_SENTRY_ENABLED === "true",
  CURRENT_BRANCH: `${process.env.REACT_APP_CURRENT_BRANCH}`,
  CURRENT_GIT_HASH: `${process.env.REACT_APP_CURRENT_GIT_HASH}`,
  CURRENT_GIT_HASH_TIMESTAMP: `${process.env.REACT_APP_CURRENT_GIT_HASH_TIMESTAMP}`,
  SENTRY_DSN: `${process.env.REACT_APP_SENTRY_DSN}`,
  SENTRY_ENVIRONMENT: `${process.env.REACT_APP_SENTRY_ENVIRONMENT}`,
  SENTRY_SAMPLE_RATE: [undefined, ""].includes(process.env.REACT_APP_SENTRY_SAMPLE_RATE)
    ? 0.0001
    : Number(process.env.REACT_APP_SENTRY_SAMPLE_RATE),
  LIMIT_5XX_RESPONSES: 3,
  CAN_DELETE_SERVICE_REQUEST_COLLECTION: process.env.REACT_APP_OKTA_DOMAIN === "dev-208672.okta.com",
  HAS_QM_ENVIRONMENT: process.env.REACT_APP_QM_API_URL !== "null" && process.env.REACT_APP_QM_API_URL !== "/",
};
export default config;
