import { makeStyles } from "@material-ui/core/styles";

import { authGutterWidth, authContentMaxWidth, authContentMinWidth } from "../util/StyleConstants";

/**
 * Style overrides for top-level Mui containers
 *
 */
export const useMuiContainerStyles = makeStyles({
  root: {
    paddingLeft: authGutterWidth,
    paddingRight: authGutterWidth,
    // Defining this under the maxWidthLg key causes a console error
    // saying only 'root' is a valid key, so use the documented marker class instead
    "&.MuiContainer-maxWidthLg": {
      maxWidth: authContentMaxWidth,
      paddingLeft: authGutterWidth,
      paddingRight: authGutterWidth,
      minWidth: authContentMinWidth,
    },
  },
});
