import React, { useState } from "react";
import {
  AuthStatus,
  ProcedureCode,
  ServiceDeterminationProcedureCodeBucket,
  ServiceRequestResponse,
  ClinicalService,
  CarePath,
} from "@coherehealth/core-platform-api";
import {
  Card,
  H5,
  Caption,
  PrimaryButton,
  SecondaryButton,
  Tooltip,
  Chip,
  colorsLight,
  Body1,
  InformativeModal,
  formatDateStr,
} from "@coherehealth/common";
import ButtonBase from "@material-ui/core/ButtonBase";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import LaunchIcon from "@material-ui/icons/Launch";
import HistoryIcon from "@material-ui/icons/History";
import { useTheme } from "@material-ui/core";
import { generatePath } from "react-router-dom";
import routes from "routes";

interface Props {
  bucket?: ServiceDeterminationProcedureCodeBucket;
  patientAuthHistory?: ServiceRequestResponse[];
  clinicalService?: ClinicalService;
  isSelected?: boolean;
  isOffCarePathService?: boolean;
  primaryCarePath?: CarePath;
  removeBucket: (bucket: ServiceDeterminationProcedureCodeBucket) => void;
  addBucket?: (bucket: ServiceDeterminationProcedureCodeBucket) => void;
  hideSelectButton?: boolean;
}

export default function ServiceCard({
  bucket: initialBucket,
  clinicalService,
  patientAuthHistory,
  isSelected: initialIsSelected,
  isOffCarePathService,
  primaryCarePath,
  removeBucket,
  addBucket,
  hideSelectButton,
}: Props) {
  const newBucket: ServiceDeterminationProcedureCodeBucket = {
    clinicalService: clinicalService,
    procedureCodes: [],
    carePath: primaryCarePath,
  };

  const [isSelected, setIsSelected] = useState(initialIsSelected || false);
  //if a clinical service was passed in, create a new bucket for it
  const bucket = initialBucket || newBucket;
  const modalText = {
    headerText: "Are you sure you want to remove this procedure?",
    subHeaderLead: "This procedure matches the code(s) you entered. Procedure code(s) ",
    subHeaderFill: bucket?.procedureCodes?.map((codeObj: ProcedureCode) => codeObj.code).join(", ") || "",
    subHeaderEnd: " will be removed from your request",
  };
  const [showModal, setShowModal] = useState(false);
  const classes = useStyles({ isSelected });

  const { palette } = useTheme();

  const hasOtherCarePath = clinicalService?.carePathName && clinicalService.carePathName !== primaryCarePath?.name;

  const ButtonType = isSelected ? PrimaryButtonGreen : SecondaryButton;
  return (
    <>
      <Card className={classes.card}>
        <div className={classes.topRowContainer}>
          <div className={classes.textContainer}>
            {!isOffCarePathService && (
              <Caption className={classes.caption}>
                {hasOtherCarePath ? clinicalService?.carePathName + " - " : ""}
                {bucket?.clinicalService?.serviceCategoryName || clinicalService?.serviceCategoryName}
              </Caption>
            )}
            <div className={classes.serviceTitle}>
              <H5>
                {isOffCarePathService ? "Other procedures" : bucket?.clinicalService?.name || clinicalService?.name}
              </H5>
              {bucket?.clinicalService?.description && (
                <Tooltip
                  title={bucket?.clinicalService?.description || clinicalService?.description || ""}
                  placement="top"
                  className={classes.toolTip}
                  arrow
                >
                  <InfoButton>
                    <InfoOutlinedIcon color={"primary"} />
                  </InfoButton>
                </Tooltip>
              )}
            </div>
            {bucket?.procedureCodes && bucket?.procedureCodes.length > 0 && (
              <div className={classes.pxCodes}>
                {bucket?.procedureCodes.map((code) => (
                  <Chip className={classes.chip} label={code?.code} key={code?.code} type="success" size="small" />
                ))}
              </div>
            )}
          </div>
          {!hideSelectButton && (
            <div className={classes.buttonSpacing}>
              <ButtonType
                style={{ width: 180 }}
                onClick={() => {
                  if (isSelected) {
                    if (bucket.procedureCodes && bucket.procedureCodes.length > 0) {
                      setShowModal(true);
                    } else {
                      removeBucket(bucket);
                      setIsSelected(false);
                    }
                  } else {
                    addBucket?.(bucket);
                    setIsSelected(true);
                  }
                }}
              >
                {isSelected ? "Selected" : patientAuthHistory ? "Select again" : "Select"}
              </ButtonType>
            </div>
          )}
        </div>
        {patientAuthHistory && (
          <div className={classes.patientAuthHistory}>
            {patientAuthHistory.map((auth) => (
              <ButtonBase
                className={classes.linkBox}
                href={
                  generatePath(routes.PATIENT_SUMMARY, {
                    patientId: auth.patient?.id || "",
                  }) +
                  "?reviewServiceRequestId=" +
                  auth.id
                }
                target="_bank"
                rel="noopener"
                key={auth.id}
              >
                <HistoryIcon className={classes.patientAuthHistoryIcon} />
                <Body1>
                  Authorization {authStatusCopy[auth.authStatus || "PENDING"]} for {formatDateStr(auth.startDate)}
                  {auth.endDate ? " - " + formatDateStr(auth.endDate) : ""}
                </Body1>
                <Body1 className={classes.bullet}> {BULLET} </Body1>
                <Body1>Requested by {auth.createdByName || ""}</Body1>
                <div className={classes.patientAuthHistoryLinkContainer}>
                  <LaunchIcon className={classes.launchIcon} />
                  <Body1 className={classes.seeDetailsText}>See details</Body1>
                </div>
              </ButtonBase>
            ))}
          </div>
        )}
      </Card>
      <InformativeModal
        icon={<HelpOutlineIcon style={{ color: palette.warning.dark }} />}
        headerText={modalText.headerText}
        subHeaderElement={
          <div>
            {modalText.subHeaderLead}
            <strong>{modalText.subHeaderFill}</strong>
            {modalText.subHeaderEnd}
          </div>
        }
        open={showModal}
        primaryButtonText="Remove procedure"
        tertiaryButtonText="Don't remove"
        primaryButtonAction={() => {
          removeBucket?.(bucket);
          setIsSelected(false);
          setShowModal(false);
        }}
        tertiaryButtonAction={() => {
          setShowModal(false);
        }}
        onClose={() => {
          setShowModal(false);
        }}
      />
    </>
  );
}

type StyleProps = Pick<Props, "isSelected">;

const useStyles = makeStyles((theme) => ({
  card: {
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    border: ({ isSelected }: StyleProps) =>
      isSelected ? `1px solid ${colorsLight.success.main}` : `1px solid ${colorsLight.background.card}`,
  },
  topRowContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
  },
  buttonSpacing: {
    alignItems: "flex-start",
    marginLeft: "auto",
  },
  serviceTitle: {
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(1),
  },
  pxCodes: {
    display: "flex",
    flexDirection: "row",
    paddingTop: theme.spacing(1.5),
  },
  chip: {
    marginRight: theme.spacing(1),
  },
  patientAuthHistory: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(1),
  },
  linkBox: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    padding: theme.spacing(2),
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: theme.spacing(1),
    width: "100%",
    "&:hover": {
      boxShadow: "0px 3px 5px rgba(207, 226, 231, 0.3)",
      transition: theme.transitions.create("box-shadow"),
    },
  },
  patientAuthHistoryRow: {
    display: "flex",
    flexDirection: "row",
  },
  patientAuthHistoryIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
  },
  patientAuthHistoryLinkContainer: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
  },
  launchIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
  },
  seeDetailsText: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  bullet: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 1),
  },
  toolTip: {
    maxWidth: 412,
  },
  caption: {
    color: colorsLight.font.secondary,
  },
}));

const authStatusCopy: Record<AuthStatus, string> = {
  PENDING: "pending",
  WITHDRAWN: "withdrawn",
  APPROVED: "approved",
  NO_LONGER_NEEDED: "approved",
  PARTIALLY_APPROVED: "partially approved",
  DENIED: "denied",
  PENDING_FACILITY_VERIFICATION: "pending facility verification",
  PENDING_EXTERNAL_DETERMINATION: "in external review",
  PENDING_ASSESSMENT: "pending assessment",
  RECOMMENDED_PARTIAL_APPROVAL: "recommended partial approval",
  RECOMMENDED_DENIAL: "recommended denial",
  INTAKE: "pending",
  DRAFT: "in draft",
  VOIDED: "voided",
  DISMISSED: "dismissed",
  POST_DENIAL_PEER_TO_PEER: "post-denial peer-to-peer",
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const InfoButton = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  padding: theme.spacing(0),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PrimaryButtonGreen = styled(PrimaryButton)({
  backgroundColor: colorsLight.success.main,
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: colorsLight.success.main,
  "&:hover": {
    backgroundColor: "#2AC382",
  },
  "&:focus": {
    backgroundColor: colorsLight.success.main,
  },
});

const BULLET = "\u2022";
