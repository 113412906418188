import { Dispatch, SetStateAction } from "react";
import { InformativeModal, InformativeModalProps, PrimaryButton, TertiaryButton } from "@coherehealth/common";
import ClipboardWarningIcon from "../components/images/ClipboardWarningIcon";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, CircularProgress, useTheme } from "@material-ui/core";
import MuiErrorIcon from "@material-ui/icons/Error";

interface Props {
  denialSubmissionModalOpen: boolean;
  setDenialSubmissionModalOpen: Dispatch<SetStateAction<boolean>>;
  onFinalizeDenial: (event: any) => Promise<void>;
  finalizeDenialLoading?: boolean;
  finalizeDenialError?: boolean;
  setFinalizeDenialError?: Dispatch<SetStateAction<boolean>>;
}
type ModalStates = "CONFIRM" | "LOADING" | "ERROR";

export default function DenialSubmissionModal({
  denialSubmissionModalOpen,
  setDenialSubmissionModalOpen,
  onFinalizeDenial,
  finalizeDenialLoading,
  finalizeDenialError,
  setFinalizeDenialError,
}: Props) {
  let modalState: ModalStates = "CONFIRM";
  if (finalizeDenialLoading) {
    modalState = "LOADING";
  } else if (finalizeDenialError) {
    modalState = "ERROR";
  }

  const theme = useTheme();
  const iconSize = theme.spacing(12);

  const modalStateConfigs: Record<ModalStates, Omit<InformativeModalProps, "open">> = {
    CONFIRM: {
      icon: <ClipboardWarningIcon />,
      onClose: () => setDenialSubmissionModalOpen(false),
      headerText: (
        <>
          Are you sure you want to <br /> send this denial letter?
        </>
      ),
      subHeaderText: "This action cannot be undone",
      primaryButtonText: "Go back",
      showDivider: true,
      children: (
        <>
          <PrimaryErrorButton onClick={onFinalizeDenial}>Yes, send denial letter</PrimaryErrorButton>
          <TertiaryButton onClick={() => setDenialSubmissionModalOpen(false)}>Go back</TertiaryButton>
        </>
      ),
    },
    LOADING: {
      icon: <CircularProgress size={iconSize} />,
      headerText: "Sending denial letter...",
      subHeaderText: "Please don't click anywhere.",
      hideX: true,
    },
    ERROR: {
      icon: <ErrorIcon />,
      headerText: "Error sending denial letter",
      subHeaderText: "There was an error sending the denial letter. Please try again.",
      onClose: () => {
        setFinalizeDenialError?.(false);
        setDenialSubmissionModalOpen(false);
      },
    },
  };

  return <InformativeModal open={denialSubmissionModalOpen} {...modalStateConfigs[modalState]} />;
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PrimaryErrorButton = styled(PrimaryButton)(({ theme }) => ({
  width: 280,
  height: 48,
  backgroundColor: theme.palette.error.main,
  "&:hover,&:focus": {
    backgroundColor: theme.palette.error.dark,
  },
  margin: theme.spacing(3, 0, 3),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ErrorIcon = styled(MuiErrorIcon)(({ theme }) => ({
  color: theme.palette.error.main,
}));
