import React from "react";
import { ClinicalAssessment, ServiceRequestResponse, UnitedStates } from "@coherehealth/core-platform-api";
import {
  H4,
  H5,
  H6,
  Caption,
  InlineButton,
  Tooltip,
  AGADIA_NAME,
  ClinicalAssessmentResponseWithSubQuestions,
  SubQuestion,
  filterClinicalQuestionsByDisplayCondition,
  determineCorrectFacilityState,
} from "@coherehealth/common";
import Grid from "@material-ui/core/Grid";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, useTheme } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import AnswerText from "./getClinicalAssessmentAnswerText";
import { useIsEditDisabledForReview } from "util/serviceRequest";
import { canEditAgadiaAssessment, mapClinicalResponseWithSubQuestion } from "util/clinicalAssessment";
import { SectionHeaderContainer } from "./ServiceRequestSummaryDetails";
import { SortQuestionsByService } from "components/ClinicalAssessment/EditWithAssessmentContext";
import { User } from "../../../UserContext";
import { useGetServiceRequestEditConfigurationByPayerAndAuthStatus } from "../../../hooks/useGetFeatureConfigurations";
import { isBackOfficeUser } from "../../../util/user";
import useOpsEditDisabled from "hooks/useOpsEditDisabled";

interface Props {
  clinicalAssessment: ClinicalAssessment;
  setAssessmentState: (state: "EDIT" | "VIEW") => void;
  serviceRequest: Pick<
    ServiceRequestResponse,
    "id" | "authStatus" | "delegatedVendor" | "healthPlanName" | "type" | "pendingReason" | "facilityLocation"
  >;
  crrCardView?: boolean;
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onEditAssessment?: Function;
  canEditClinicalAssessment: boolean;
  user?: User;
}

export default function ClinicalAssessmentReadOnly({
  clinicalAssessment,
  setAssessmentState,
  serviceRequest,
  crrCardView,
  onEditAssessment,
  canEditClinicalAssessment,
  user,
}: Props) {
  const { spacing } = useTheme();

  const isBackOffice = isBackOfficeUser(user);
  const { srEditConfig } = useGetServiceRequestEditConfigurationByPayerAndAuthStatus({
    healthPlanName: serviceRequest?.healthPlanName,
    authStatus: serviceRequest.authStatus,
  });

  const editDisabledUnderReview =
    useIsEditDisabledForReview(serviceRequest) ||
    (isBackOffice && serviceRequest.authStatus !== "DRAFT" && srEditConfig?.disableClinicalAssessmentQuestionsEdits);

  const editClinicalAssessmentTooltipTitle = (): string => {
    return srEditConfig?.disableClinicalAssessmentQuestionsMessage || "";
  };

  const questionChecker = (question: ClinicalAssessmentResponseWithSubQuestions, index: number) => {
    const subQuestionsExist = question.subQuestions && question.subQuestions.length > 0;
    const alphabet = Array.from(Array(26)).map((e, i) => String.fromCharCode(i + 97));
    const subQuestionIdxValue = (index + 1).toString();
    const answerIds: string[] = [];
    const answeredSubQuestions: SubQuestion[] = [];
    const addAnswerId = (question: ClinicalAssessmentResponseWithSubQuestions | SubQuestion) => {
      question.answers?.forEach((answer) => {
        answer?.codes?.forEach((code) => {
          answerIds.push(code.system);
        });
      });
    };
    addAnswerId(question);
    question.subQuestions?.forEach((question) => {
      const subQuestionShouldShow = question.answerToRenderOn && answerIds.includes(question.answerToRenderOn);
      addAnswerId(question);

      if (subQuestionShouldShow) {
        answeredSubQuestions.push(question);
      }
    });

    if (!subQuestionsExist || answeredSubQuestions.length === 0) {
      const idxValue = (index + 1).toString() + ".";
      return (
        <>
          <Grid
            item
            xs={1}
            style={{ alignSelf: "start", paddingLeft: spacing(4) }}
            key={question.clinicalQuestion?.id || `fallback-${index}`}
            data-public
          >
            {idxValue}
          </Grid>
          <Grid item xs={11}>
            <H6 style={{ marginBottom: spacing(0), marginTop: spacing(0), alignSelf: "start" }} data-public>
              {question.clinicalQuestion?.questionText || "No question text"}
            </H6>
            {question.clinicalQuestion?.type === "MULTI_SELECT" && (
              <Caption color="textSecondary" data-public>
                {question.answers?.length || 0} of {question.clinicalQuestion?.responseOptions?.length || 0} answer
                options selected
              </Caption>
            )}
            <AnswerText
              type={serviceRequest.delegatedVendor?.toUpperCase()}
              clinicalQuestion={question.clinicalQuestion}
              answers={question.answers}
            />
          </Grid>
        </>
      );
    } else {
      answeredSubQuestions.unshift(question);
      const subQuestionsToRender = answeredSubQuestions.map((question, idx) => {
        return (
          <>
            <Grid
              item
              xs={1}
              style={{ alignSelf: "start", paddingLeft: spacing(4) }}
              key={question.clinicalQuestion?.id || `fallback-${idx}`}
            >
              {subQuestionIdxValue + alphabet[idx] + "."}
            </Grid>
            <Grid item xs={11}>
              <H6 style={{ marginBottom: spacing(0), marginTop: spacing(0), alignSelf: "start" }}>
                {question.clinicalQuestion?.questionText || "No question text"}
              </H6>
              {question.clinicalQuestion?.type === "MULTI_SELECT" && (
                <Caption color="textSecondary">
                  {question.answers?.length || 0} of {question.clinicalQuestion?.responseOptions?.length || 0} answer
                  options selected
                </Caption>
              )}
              <AnswerText
                type={serviceRequest.delegatedVendor?.toUpperCase()}
                clinicalQuestion={question.clinicalQuestion}
                answers={question.answers}
              />
            </Grid>
          </>
        );
      });
      return subQuestionsToRender;
    }
  };

  const questionsByService = SortQuestionsByService(clinicalAssessment);

  const serviceNamesAndQuestions = Array.from(questionsByService.values());
  // Display the service name if there's more than 1 service
  const displayServiceNames = serviceNamesAndQuestions.length > 1;
  const opsEditDisabled = useOpsEditDisabled(serviceRequest);

  return (
    <>
      <SectionHeaderContainer style={{ paddingTop: 0, paddingBottom: crrCardView ? 20 : 24 }}>
        {crrCardView ? <H5 data-public>Assessment questions</H5> : <H4 data-public>Clinical assessment</H4>}
        {canEditClinicalAssessment && (
          <Tooltip title={editDisabledUnderReview ? editClinicalAssessmentTooltipTitle() : ""} placement="top" arrow>
            <div>
              <InlineButton
                disabled={
                  opsEditDisabled ||
                  editDisabledUnderReview ||
                  (serviceRequest?.delegatedVendor === AGADIA_NAME &&
                    !canEditAgadiaAssessment(serviceRequest.authStatus, serviceRequest.pendingReason))
                }
                startIcon={<EditIcon />}
                onClick={() => {
                  if (onEditAssessment) {
                    onEditAssessment();
                  } else {
                    setAssessmentState("EDIT");
                  }
                }}
              >
                Edit
              </InlineButton>
            </div>
          </Tooltip>
        )}
      </SectionHeaderContainer>
      {Array.from(questionsByService.keys()).map((k) => {
        const serviceName = questionsByService.get(k)?.[0];
        const serviceQuestions = questionsByService.get(k)?.[1];
        if (!serviceQuestions || serviceQuestions.length === 0) {
          return null;
        }
        let questionsForClinicalAssessment: ClinicalAssessmentResponseWithSubQuestions[] = [];
        if (clinicalAssessment.questions !== undefined) {
          if (clinicalAssessment.type === "AGADIA") {
            questionsForClinicalAssessment = mapClinicalResponseWithSubQuestion(serviceQuestions);
          } else if (serviceRequest.authStatus && serviceRequest.authStatus !== "DRAFT") {
            questionsForClinicalAssessment = determineCorrectFacilityState(
              serviceQuestions,
              serviceRequest.facilityLocation?.address?.state as UnitedStates
            );
          } else {
            questionsForClinicalAssessment = filterClinicalQuestionsByDisplayCondition(serviceQuestions);
          }
        }
        return (
          <ServiceContainer key={k}>
            {displayServiceNames && <ServiceHeader>{serviceName}</ServiceHeader>}
            {questionsForClinicalAssessment.map((question, idx) => {
              return (
                <Grid
                  key={idx}
                  container
                  direction="row"
                  spacing={3}
                  alignItems="center"
                  style={{ marginLeft: -spacing(3) }}
                >
                  {questionChecker(question, idx)}
                </Grid>
              );
            })}
          </ServiceContainer>
        );
      })}
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ServiceHeader = styled(H6)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ServiceContainer = styled("div")(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(5),
}));
