import React, { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useGetServiceRequestHistory } from "@coherehealth/core-platform-api";
import { H6, formatDateStrWithTz } from "@coherehealth/common";
import { useSnackbar } from "notistack";
import { useAuthorized } from "authorization";
interface HistoryProps {
  serviceRequestId: string;
}

export default function ServiceRequestHistory({ serviceRequestId }: HistoryProps) {
  const { data, error, loading } = useGetServiceRequestHistory({
    srId: serviceRequestId,
  });
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (error) {
      enqueueSnackbar(`Error retrieving audit history: ${error.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, error]);

  if (!useAuthorized("VIEW_SERVICE_REQUEST_AUDIT_LOG")) {
    return null;
  }

  if (loading) {
    return <CircularProgress />;
  }
  if (!data) {
    return null;
  }

  return (
    <div>
      <H6>Audit history</H6>
      <ul>
        {[...(data.changes || [])]
          .sort((a, b) =>
            (a.commitMetadata.commitDateInstant || "").localeCompare(b.commitMetadata.commitDateInstant || "")
          )
          .map((change) => (
            <li>
              {change.type} - {formatAuthor(change.commitMetadata)} at{" "}
              {formatDateStrWithTz(change.commitMetadata.commitDateInstant)}
              <pre>{JSON.stringify(change.changes, undefined, 2)}</pre>
            </li>
          ))}
      </ul>
    </div>
  );
}

type CommitMetadata = NonNullable<
  NonNullable<ReturnType<typeof useGetServiceRequestHistory>["data"]>["changes"]
>[0]["commitMetadata"];
function formatAuthor({ authorName, authorEmail, author }: CommitMetadata): string {
  if (authorName || authorEmail) {
    return `${authorName || "unknown name"} (${authorEmail || "unknown email"})`;
  }

  if (author === "sub") {
    return "unknown";
  }
  return `Okta user with id ${author}`;
}
