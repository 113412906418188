import { DateSelect, SingleSelectDropdown, TimeTextField } from "@coherehealth/common";
import { PatientDischargeStatusResponse, useGetPatientDischargeStatus } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Box, Grid, styled } from "@material-ui/core";

interface Props {
  dischargeDetails: DischargeDetailsType;
  setDischargeDetails: (newDischargeDetails: DischargeDetailsType) => void;
  isMock?: boolean;
  patientDischargeStatuses?: PatientDischargeStatusResponse[];
  attemptedSubmit: boolean;
}

export interface DischargeDetailsType {
  actualDischargeDate?: Date;
  actualDischargeTime?: string;
  dischargedTo?: PatientDischargeStatusResponse;
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DischargeDetailsRowContainer = styled("div")(({ theme }) => ({
  transition: theme.transitions.create("background-color"),
  borderRadius: 4,
  padding: theme.spacing(1),
  display: "flex",
}));

export default function DischargedToDetails({
  dischargeDetails,
  setDischargeDetails,
  isMock,
  patientDischargeStatuses,
  attemptedSubmit,
}: Props) {
  const { actualDischargeDate, actualDischargeTime, dischargedTo } = dischargeDetails;
  const { data: patientDischargeStatusResponse } = useGetPatientDischargeStatus({
    queryParams: {
      max: 30,
    },
    mock: !!isMock ? { data: patientDischargeStatuses } : undefined,
  });
  const dischargedToOptions = (patientDischargeStatusResponse || [])
    .map(({ code, dischargedTo }) => ({
      id: code || "",
      label: dischargedTo,
    }))
    .sort((a, b) => {
      if (a.label && b.label) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
      }
      return 0;
    });

  return (
    <DischargeDetailsRowContainer>
      <Box display="flex" alignItems="flex-start" justifyContent="space-between" width="100%">
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <DateSelect
              TextFieldProps={{ fullWidth: true }}
              maxDate={new Date()}
              maxDateMessage="Date must be today or earlier"
              minDate="Date of stay"
              label="Discharge date"
              value={actualDischargeDate || null}
              onDateChange={(newDischargeDate) => {
                setDischargeDetails({ ...dischargeDetails, actualDischargeDate: newDischargeDate });
              }}
              inputProps={{
                width: 160,
              }}
              error={attemptedSubmit && !actualDischargeDate}
              attemptedSubmit={attemptedSubmit}
              helperText={attemptedSubmit && !actualDischargeDate && "Required"}
            />
          </Grid>
          <Grid item style={{ width: "176px" }}>
            <TimeTextField
              fullWidth
              label="Discharge time"
              timeFormat="24"
              placeholder="hh:mm"
              value={actualDischargeTime || null}
              onChangeValue={(newDischargeTime) => {
                setDischargeDetails({ ...dischargeDetails, actualDischargeTime: newDischargeTime });
              }}
            />
          </Grid>
          <Grid item style={{ width: "200px" }}>
            <SingleSelectDropdown
              label="Discharged to"
              options={dischargedToOptions}
              value={dischargedTo?.code}
              onChange={(newPatientDischargeStatusId) => {
                const newDischargedTo = patientDischargeStatusResponse?.find(
                  ({ code }) => code === newPatientDischargeStatusId
                );
                setDischargeDetails({ ...dischargeDetails, dischargedTo: newDischargedTo });
              }}
              error={attemptedSubmit && !dischargedTo?.code}
              helperText={attemptedSubmit && !dischargedTo?.code && "Required"}
            />
          </Grid>
        </Grid>
      </Box>
    </DischargeDetailsRowContainer>
  );
}
