import React, { useState, MouseEvent, useEffect } from "react";

import {
  Body1,
  Body2,
  useStableUniqueId,
  formatDateStr,
  patientDisplayName,
  useGetServiceRequestByIdWithFallback,
} from "@coherehealth/common";
import { Patient, useGetPatient } from "@coherehealth/core-platform-api";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import MuiExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { PatientInfoSection } from "../PatientInfo";

interface Props {
  patientId?: string;
  patient?: Patient;
  serviceRequestId?: string;
  rightAlign?: boolean;
}
type StyleProps = {
  isRotated: boolean;
};

const useStyles = makeStyles((theme) => ({
  expandIcon: {
    transition: theme.transitions.create("transform"),
    transform: (props: StyleProps) => (props.isRotated ? "rotate(180deg)" : "rotate(0deg)"),
  },
  patientInfoContainer: {
    verticalAlign: "middle",
    display: "inherit",
    position: "absolute",
    maxWidth: "314px",
    top: 0,
    bottom: 0,
  },
}));

export default function PatientInfo({ patientId, patient, serviceRequestId, rightAlign }: Props) {
  const popoverId = useStableUniqueId();
  const [patientAnchor, setPatientAnchor] = useState<HTMLButtonElement>();
  const handlePatientClick = (event: MouseEvent<HTMLButtonElement>) => {
    setPatientAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setPatientAnchor(undefined);
  };

  const classes = useStyles({ isRotated: Boolean(patientAnchor) });

  const { data: serviceRequest, refetch: getServiceRequest } = useGetServiceRequestByIdWithFallback({
    id: serviceRequestId || "",
    lazy: true,
  });

  const patientDbId = patient?.id || patientId || serviceRequest?.patient?.id;

  const {
    data: patientData,
    refetch: refetchPatientData,
    error: fetchPatientError,
  } = useGetPatient({
    lazy: true,
    id: patientDbId || "",
  });

  useEffect(() => {
    // Only fetch the service request if serviceRequestId is provided
    if (serviceRequestId) {
      // Fetch the service request
      getServiceRequest();
    }
  }, [getServiceRequest, serviceRequestId]);

  const renderPatient = patient || patientData;

  useEffect(() => {
    if (!renderPatient && !fetchPatientError && Boolean(patientDbId)) {
      refetchPatientData();
    }
  }, [renderPatient, patientDbId, fetchPatientError, refetchPatientData]);

  if (!renderPatient) {
    return null;
  }

  return (
    <div className={classes.patientInfoContainer} style={rightAlign ? { right: 16 } : { left: 0 }}>
      <PatientButton
        aria-describedby={popoverId}
        type="button"
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          handlePatientClick(e);
        }}
      >
        <>
          <PatientText>
            <EllipsizedBody2>{patientDisplayName(renderPatient)}</EllipsizedBody2>
            <EllipsizedBody1>DOB: {formatDateStr(renderPatient.dateOfBirth)}</EllipsizedBody1>
          </PatientText>
          <IconHolder>
            <ExpandMoreIcon className={classes.expandIcon} />
          </IconHolder>
        </>
      </PatientButton>
      <Popover
        id={popoverId}
        open={Boolean(patientAnchor)}
        anchorEl={patientAnchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            border: "1px solid #E5E5E5",
            boxSizing: "border-box",
            boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.08)",
            borderRadius: "10px",
            padding: 24,
            width: 314,
          },
        }}
      >
        <PatientInfoSection patient={renderPatient} />
      </Popover>
    </div>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const IconHolder = styled("div")({
  float: "right",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PatientText = styled("div")(({ theme }) => ({
  maxWidth: "90%",
  marginRight: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PatientButton = styled(Button)({
  textAlign: "left",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ExpandMoreIcon = styled(MuiExpandMoreIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const EllipsizedBody2 = styled(Body2)({
  textOverflow: "ellipsis",
  maxWidth: "100%",
  whiteSpace: "nowrap",
  overflow: "hidden",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const EllipsizedBody1 = styled(Body1)({
  textOverflow: "ellipsis",
  maxWidth: "100%",
  whiteSpace: "nowrap",
  overflow: "hidden",
});
