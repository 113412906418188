import { InlineButton, useFeature } from "@coherehealth/common";
import { useTheme } from "@material-ui/core";
import useDraftContinue from "components/ServiceRequest/DraftContinue";
import { isDelegatedToHealthHelp } from "util/serviceRequest";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ActionButtonT, animatedHiddenStyle, animatedShownStyle } from "../StatusAlert/statusAlertUtils";
export const InlineActionButton = ({
  serviceRequest,
  isInAuthBuilder,
  isAuthViewOnlyUser,
  actionConfiguration,
  setDeleteModalOpen,
  isDraftSr,
  onPotentialDuplicatesClose,
}: ActionButtonT) => {
  const theme = useTheme();
  const isHealthHelpDelegated = isDelegatedToHealthHelp(serviceRequest);
  const useEpisodicAuthFunctionality = useFeature("episodicAuth");
  const draftContinue = useDraftContinue(serviceRequest);
  const isEpisodicAuth = serviceRequest.episodeGeneratedFromServiceRequest && useEpisodicAuthFunctionality;
  const shouldDisplayInlineActionButton = isDraftSr && !isHealthHelpDelegated && !isInAuthBuilder;
  const displayInlineDeleteBtn = !actionConfiguration.draftDeleteActionConfiguration?.noRender && !isEpisodicAuth;
  const displayInlineContinueBtn =
    actionConfiguration.draftContinueActionConfiguration &&
    !actionConfiguration.draftContinueActionConfiguration?.noRender;
  const shouldDisplayInlineDeleteDraftBtn = isDraftSr && !isAuthViewOnlyUser;

  if (shouldDisplayInlineActionButton) {
    return (
      <>
        {displayInlineDeleteBtn && (
          <InlineButton
            data-tracking-id="delete-draft"
            data-testid={`delete-draft-button-${serviceRequest.cohereId}`}
            startIcon={<DeleteIcon />}
            style={{
              paddingRight: 24,
              color: `${theme.palette.error.main}`,
              ...(actionConfiguration.draftDeleteActionConfiguration?.hidden
                ? animatedHiddenStyle
                : animatedShownStyle),
            }}
            onClick={() => {
              setDeleteModalOpen(true);
            }}
            disabled={actionConfiguration.draftDeleteActionConfiguration?.disabled}
            aria-label="Delete Service Request"
            title="Delete Service Request"
          >
            Delete
          </InlineButton>
        )}
        {displayInlineContinueBtn && (
          <InlineButton
            data-tracking-id="continue-draft"
            data-testid="continue-draft-button"
            endIcon={<ArrowForwardIcon />}
            style={{
              paddingRight: 8,
              ...(actionConfiguration.draftContinueActionConfiguration?.hidden
                ? animatedHiddenStyle
                : animatedShownStyle),
            }}
            onClick={() => {
              if (onPotentialDuplicatesClose) {
                onPotentialDuplicatesClose();
              }
              serviceRequest.workflowStep && draftContinue(serviceRequest.workflowStep);
            }}
            disabled={actionConfiguration.draftContinueActionConfiguration?.disabled}
            aria-label="Continue working on draft"
            title="Continue working on draft"
          >
            Continue
          </InlineButton>
        )}
      </>
    );
  } else if (shouldDisplayInlineDeleteDraftBtn) {
    return (
      <>
        {!actionConfiguration.draftDeleteActionConfiguration?.noRender && (
          <InlineButton
            data-tracking-id="delete-draft"
            data-testid={`delete-draft-button-${serviceRequest.cohereId}`}
            startIcon={<DeleteIcon />}
            style={{
              paddingRight: 24,
              color: `${theme.palette.error.main}`,
              ...(actionConfiguration.draftDeleteActionConfiguration?.hidden
                ? animatedHiddenStyle
                : animatedShownStyle),
            }}
            onClick={() => {
              setDeleteModalOpen(true);
            }}
            disabled={actionConfiguration.draftDeleteActionConfiguration?.disabled}
            aria-label="Delete draft"
            title="Delete draft"
          >
            Delete
          </InlineButton>
        )}
      </>
    );
  } else {
    return <></>;
  }
};
