import React, { SVGProps } from "react";

export default function ClipboardIllustrationWithPencil(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="104" height="104" rx="52" fill="#039EC3" fill-opacity="0.1" />
      <path d="M72.2817 30.1274H29.9V79.5581H72.2817V30.1274Z" fill="#A1AAB4" />
      <path d="M62.2872 33.123H32.8988V76.5568H69.266V40.1194L62.2872 33.123Z" fill="white" />
      <path
        d="M60.6907 24.0065H55.0445C55.0445 21.5165 53.2734 19.5 51.0816 19.5C48.8897 19.5 47.1187 21.5165 47.1187 24.0065H41.4725L39.8768 34.0013H62.2688L60.6907 24.0065Z"
        fill="#212936"
      />
      <path
        d="M44.0156 40.0175H40.9997V37.0015H38.9305V40.0175H35.9146V42.0866H38.9305V45.1026H40.9997V42.0866H44.0156V40.0175Z"
        fill="#EA1D63"
      />
      <path d="M60.5156 48.4521H41.6481V49.8725H60.5156V48.4521Z" fill="#89D3DD" />
      <path d="M65.776 71.1914H58.2535V72.6117H65.776V71.1914Z" fill="#89D3DD" />
      <path d="M65.7756 53.6577H36.3872V55.078H65.7756V53.6577Z" fill="#C2C2C2" />
      <path d="M65.7756 57.9175H36.3872V59.3378H65.7756V57.9175Z" fill="#C2C2C2" />
      <path d="M65.7756 62.1831H36.3872V63.6034H65.7756V62.1831Z" fill="#C2C2C2" />
      <path d="M49.5208 66.4409H36.3872V67.8612H49.5208V66.4409Z" fill="#C2C2C2" />
      <path d="M62.2863 40.1194H69.2651L62.2863 33.123V40.1194Z" fill="#F5F5F5" />
      <ellipse cx="71.5" cy="35.1" rx="15.6" ry="15.6" fill="#039EC3" />
      <path
        d="M63.7 39.65V42.9H66.95L76.5353 33.3147L73.2853 30.0647L63.7 39.65ZM79.0486 30.8014C79.3866 30.4634 79.3866 29.9174 79.0486 29.5794L77.0206 27.5514C76.6826 27.2134 76.1366 27.2134 75.7986 27.5514L74.2126 29.1374L77.4626 32.3874L79.0486 30.8014Z"
        fill="white"
      />
    </svg>
  );
}
