import { SetStateAction, Dispatch, useEffect, useState, useMemo } from "react";
import { TextField, TextFieldProps } from "../TextField";
import { Body1, Caption } from "../Typography";
import {
  ParsedBlock,
  renderDenialLanguage,
  renderAsMarkup,
  SimulatedDenialLetterInstructiveText,
} from "./DenialLetterUtil";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";
import { colorsLight } from "../../themes";
import { MdReview, NurseReview, PeerToPeerReview, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import Mustache from "mustache";
import { useConfiguration, useFeature } from "../../components";
import { calculateFleschKincaidGradeLevel } from "../../util/fleschKincaidUtils";

interface Props {
  parsedMarkupText?: ParsedBlock[];
  denialLanguage?: string;
  setDenialLanguage?: Dispatch<SetStateAction<string>>;
  setEditedMarkupText?: Dispatch<SetStateAction<string>>;
  isBlob?: boolean;
  serviceRequest?: ServiceRequestResponse | null;
  review?: MdReview | PeerToPeerReview | NurseReview | null;
  isReadOnly?: boolean;
  setCanSubmitForReview?: Dispatch<SetStateAction<boolean>>;
  mdNote?: string;
  setMdNote?: Dispatch<SetStateAction<string>>;
}

export default function DenialLetterBody({
  parsedMarkupText,
  denialLanguage,
  setDenialLanguage,
  setEditedMarkupText,
  isBlob,
  serviceRequest,
  review,
  isReadOnly,
  setCanSubmitForReview,
  mdNote,
  setMdNote,
}: Props) {
  const [letterState, setLetterState] = useState<{ [key: number]: string }>({});
  const translatedMdNoteEnabled = useFeature("translatedMdNote");
  const logrocketUnmasking = useFeature("logrocketUnmasking");

  const denialReadingLevelConfig = useConfiguration(
    "reviewConfiguration",
    serviceRequest?.healthPlanName,
    serviceRequest?.delegatedVendor
  );

  const denialReadingLevelConfigEnabled = Boolean(denialReadingLevelConfig?.denialReadingLevel?.enabled);

  const approvedPxCodes = review?.approvedSemanticProcedureCodes?.map((item) => item?.code).join(", ");

  const deniedPxCodes = serviceRequest?.semanticProcedureCodes
    ?.filter((item) => {
      if (review?.approvedSemanticProcedureCodes?.find((element) => item.code === element.code)) {
        return "";
      } else {
        return item.code;
      }
    })
    .map((item) => item?.code)
    .join(", ");

  const templateMapping: { [key: string]: string | number | undefined } = useMemo(() => {
    return {
      approvedQuantity: review?.approvedUnits || "Approved Units",
      unitType: serviceRequest?.unitType?.toLocaleLowerCase().replaceAll("_", " ") || "Number of Service Dates",
      approvedProcedureCodes: approvedPxCodes || "Approved Procedure Codes",
      deniedQuantity:
        review?.approvedUnits && serviceRequest?.units
          ? serviceRequest?.units - review?.approvedUnits
          : "Denied Quantity",
      deniedProcedureCodes: deniedPxCodes,
      startDate: serviceRequest?.startDate || "Start Date",
      endDate: serviceRequest?.endDate || "End Date",
      units: serviceRequest?.units,
      translatedMdNote: mdNote,
    };
  }, [
    review?.approvedUnits,
    serviceRequest?.unitType,
    serviceRequest?.units,
    serviceRequest?.startDate,
    serviceRequest?.endDate,
    approvedPxCodes,
    deniedPxCodes,
    mdNote,
  ]);

  useEffect(() => {
    let compiled: { [key: number]: string } = {};
    parsedMarkupText?.forEach((item, index) => {
      compiled[index] = item.text || "";
    });
    setLetterState(compiled);
  }, [parsedMarkupText]);

  useEffect(() => {
    if (!isBlob) {
      const compiledEditedDenialText = renderDenialLanguage({
        parsedMarkupText: parsedMarkupText || [],
        templateMapping,
        translatedMdNoteEnabled,
        letterState,
      });
      setDenialLanguage?.(compiledEditedDenialText);
    }
    denialLanguage?.length ? setCanSubmitForReview?.(true) : setCanSubmitForReview?.(false);
  }, [
    denialLanguage?.length,
    isBlob,
    letterState,
    parsedMarkupText,
    setCanSubmitForReview,
    setDenialLanguage,
    templateMapping,
    translatedMdNoteEnabled,
  ]);

  useEffect(() => {
    if (parsedMarkupText) {
      setEditedMarkupText?.(renderAsMarkup(parsedMarkupText, letterState));
    }
  }, [letterState, parsedMarkupText, setEditedMarkupText]);

  return (
    <div style={{ whiteSpace: "pre-wrap", width: "100%", marginBottom: 50 }}>
      {isReadOnly ? (
        <ReadOnlyLetterText>{denialLanguage}</ReadOnlyLetterText>
      ) : isBlob ? (
        <DenialLetterTextBoxWithGradeLevel
          fullWidth
          multiline
          minRows={"3"}
          value={denialLanguage || ""}
          onChangeValue={(value) => {
            setDenialLanguage?.(value);
          }}
          denialReadingLevelConfigEnabled={denialReadingLevelConfigEnabled}
        />
      ) : (
        parsedMarkupText?.map((item, index) => {
          if (item.type === "STATIC") {
            return <ReadOnlyLetterText key={index}>{item.text}</ReadOnlyLetterText>;
          } else if (item.type === "INSTRUCTIVE") {
            return (
              <SimulatedDenialLetterInstructiveText data-public={!!logrocketUnmasking ? true : undefined} key={index}>
                {item.text}
              </SimulatedDenialLetterInstructiveText>
            );
          } else if (item.type === "INJECTED") {
            return <InjectedText key={index}>{Mustache.render("{{" + item.text + "}}", templateMapping)}</InjectedText>;
          } else if (item.type === "MDNOTE") {
            return (
              translatedMdNoteEnabled && (
                <DenialLetterTextBoxWithGradeLevel
                  fullWidth
                  multiline
                  key={index}
                  minRows={"3"}
                  value={mdNote}
                  onChangeValue={(value) => {
                    setMdNote?.(value);
                    setLetterState({ ...letterState, [index]: value });
                  }}
                  denialReadingLevelConfigEnabled={denialReadingLevelConfigEnabled}
                />
              )
            );
          } else {
            return (
              <DenialLetterTextBoxWithGradeLevel
                fullWidth
                multiline
                key={index}
                minRows={"3"}
                value={letterState[index] || ""}
                onChangeValue={(value) => {
                  setLetterState({ ...letterState, [index]: value });
                }}
                denialReadingLevelConfigEnabled={denialReadingLevelConfigEnabled}
              />
            );
          }
        })
      )}
    </div>
  );
}

interface DenialLetterTextBoxWithGradeLevelProps extends TextFieldProps {
  denialReadingLevelConfigEnabled: boolean;
}

function DenialLetterTextBoxWithGradeLevel({
  denialReadingLevelConfigEnabled,
  ...props
}: DenialLetterTextBoxWithGradeLevelProps) {
  const denialLetterReadingLevelFeatureEnabled = useFeature("denialLetterReadingLevel");
  const denialLetterReadingLevelEnabled = Boolean(
    denialLetterReadingLevelFeatureEnabled && denialReadingLevelConfigEnabled
  );
  const shouldDisplayGradeLevel =
    denialLetterReadingLevelEnabled && props.value && String(props.value).trim().length > 0;
  return (
    <div
      style={{
        marginBottom: "24px",
      }}
    >
      <DenialLetterTextBox {...props} />
      {shouldDisplayGradeLevel && <Caption>{calculateFleschKincaidGradeLevel(props.value as string)}</Caption>}
    </div>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ReadOnlyLetterText = styled(Body1)({
  display: "inline",
  color: colorsLight.font.main,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const InjectedText = styled(Body1)({
  display: "inline",
  color: colorsLight.font.main,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DenialLetterTextBox = styled(TextField)(({ theme }) => ({
  "& .MuiFilledInput-root": {
    paddingTop: "10px",
    borderColor: colorsLight.gray.divider,
    backgroundColor: colorsLight.background.card,
    "&:hover,&.Mui-focused": {
      backgroundColor: colorsLight.background.card,
    },
  },
  "& .MuiInputBase-input": {
    color: colorsLight.font.main,
  },
}));
