import { DropdownOption } from "@coherehealth/common";
import { ProcedureCode, useGetObservationCodes } from "@coherehealth/core-platform-api";
import { useEffect, useMemo, useState } from "react";

interface PxAttributeOptionResponse {
  options: Map<string, DropdownOption[]>;
  loading: boolean;
}

export default function usePxAttributeOptions(procedureCodes: ProcedureCode[]): PxAttributeOptionResponse {
  const [options, setOptions] = useState<Map<string, DropdownOption[]>>(new Map());

  // Map snomedCode -> list of pxCodes related to that snomedCode
  const pxCodesPerRelation = useMemo(() => {
    const mapping = new Map<string, string[]>();
    procedureCodes.forEach((px) => {
      if (px.relationshipTo) {
        px.relationshipTo.forEach((relation) => {
          // Add unique codes to list
          const [system, code] = relation.split("/");
          if (system === "snomed" && !!code) {
            // Add procedure code to the mapping of this snomed code (i.e. this snomed code is used by these procedure codes)
            const pxs = mapping.get(code) || [];
            if (px.code) {
              pxs?.push(px.code);
            }
            mapping.set(code, pxs);
          }
        });
      }
    });
    return mapping;
  }, [procedureCodes]);
  const termListQuery = useMemo(() => Array.from(pxCodesPerRelation.keys()).join(","), [pxCodesPerRelation]);

  const {
    data: observationCodeResponse,
    refetch: getObservationCodes,
    loading,
  } = useGetObservationCodes({ lazy: true });
  useEffect(() => {
    // Whenever termListQuery changes then fetch the snomed/observation codes
    if (termListQuery.length) {
      getObservationCodes({ queryParams: { termList: termListQuery } });
    }
  }, [getObservationCodes, termListQuery]);

  useEffect(() => {
    // This runs whenver there are new procedure codes or observation code data
    const newOptions = new Map<string, DropdownOption[]>();
    if (observationCodeResponse) {
      observationCodeResponse.forEach((observationCode) => {
        const pxCodesToUpdate = pxCodesPerRelation.get(observationCode.code) || [];
        const newOption: DropdownOption = {
          id: observationCode.preferredLabel || observationCode.label || observationCode.code,
          label: observationCode.preferredLabel || observationCode.label || observationCode.code,
        };
        pxCodesToUpdate.forEach((pxCode) => {
          const codeOptions = newOptions.get(pxCode) || [];
          codeOptions.push(newOption);
          newOptions.set(pxCode, codeOptions);
        });
      });
      setOptions(newOptions);
    }
  }, [observationCodeResponse, pxCodesPerRelation]);

  return { options, loading };
}
