import React from "react";
import {
  today,
  formatDateStr,
  formatDateStrWithCustomFormat,
  colorsLight,
  patientDisplayName,
} from "@coherehealth/common";
import { LanguagePreference, ServiceRequestSearchResponse, VatNotification } from "@coherehealth/core-platform-api";
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";

import { CohereServiceRequestPDFHeader } from "util/PDFUtils";
import { getHealthPlanContactInfo } from "../../../contact";
// import { styles } from "../DetailsPrintoutProvider/DetailsPrintoutPdfDocument";

interface Props {
  serviceRequest: ServiceRequestSearchResponse;
  vatNotification: VatNotification;
  languagePreference?: LanguagePreference;
  callDispositionMessage?: string;
}

const NON_APPLICABLE = "n/a";

/**
 * Renders a pdf with @react-pdf package https://react-pdf.org/components
 * For some reason the logo image does not get imported with storybook, but works on the actual package.
 *
 * @param serviceRequest
 * @constructor
 */
export default function CallLogPrintoutPdfDocument({
  serviceRequest: { patient, healthPlanName, orderingProvider, procedureCodes, authNumber },
  vatNotification,
  callDispositionMessage,
  languagePreference,
}: Props) {
  if (!vatNotification) {
    return null;
  }
  const procedureCodeDisplay = procedureCodes?.map((px) => `${px.code} ${px.description}`)?.join(", ") || "";
  const authorizationNumber = authNumber || NON_APPLICABLE;
  const memberIdDisplay = patient?.memberId || "";
  const patientNameDisplay = (patient && patientDisplayName(patient)) || "";
  const provider = orderingProvider?.name || "";
  const language = languagePreference?.spoken ? languagePreference?.spoken : languagePreference?.written || "";
  const languageOfDelivery = language === "SPA" ? "SPA" : "ENG";
  const numberDialed = vatNotification.phoneNumber || "";
  const callDisposition = callDispositionMessage || "";
  const todayDateDisplay = formatDateStr(today().toISOString());
  const timeStamp =
    formatDateStrWithCustomFormat(vatNotification.processTime, "hh:mm:ss a") +
    " on " +
    formatDateStr(vatNotification.processTime);
  const { email, phone, contactName } = getHealthPlanContactInfo(healthPlanName);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <CohereServiceRequestPDFHeader phone={phone} contactName={contactName} email={email} />
        <View style={[styles.headerSection, { fontFamily: "GilroySemiBold" }]}>
          <Text style={{ fontFamily: "GilroySemiBold" }}>Final determination call log</Text>
          <Text style={callLogStyles.label}>Created on {todayDateDisplay}</Text>
        </View>
        <View style={styles.bodySection}>
          <Text style={callLogStyles.label}>Member ID</Text>
          <Text style={callLogStyles.infoBoldText}>{memberIdDisplay}</Text>
          <Text style={callLogStyles.label}>Patient name</Text>
          <Text style={callLogStyles.infoBoldText}>{patientNameDisplay}</Text>
          <Text style={callLogStyles.label}>Provider</Text>
          <Text style={callLogStyles.infoBoldText}>{provider}</Text>
          <Text style={callLogStyles.label}>Procedure</Text>
          <Text style={[callLogStyles.infoBoldText, { paddingBottom: 0 }]}>{procedureCodeDisplay}</Text>
        </View>
        <View style={callLogStyles.bodySection}>
          <Text style={callLogStyles.label}>Authorization number</Text>
          <Text style={callLogStyles.infoBoldText}>{authorizationNumber}</Text>
          <Text style={callLogStyles.label}>Party contacted</Text>
          <Text style={callLogStyles.infoBoldText}>{"Patient"}</Text>
          <Text style={callLogStyles.label}>Number dialed</Text>
          <Text style={callLogStyles.infoBoldText}>{numberDialed}</Text>
          <Text style={callLogStyles.label}>Call disposition</Text>
          <Text style={callLogStyles.infoBoldText}>{callDisposition}</Text>
          <Text style={callLogStyles.label}>Number of attempts</Text>
          <Text style={callLogStyles.infoBoldText}>{vatNotification.attempts as unknown as string}</Text>
          <Text style={callLogStyles.label}>Language of delivery</Text>
          <Text style={callLogStyles.infoBoldText}>{languageOfDelivery}</Text>
          <Text style={callLogStyles.label}>Timestamp</Text>
          <Text style={[callLogStyles.infoBoldText, { paddingBottom: 0 }]}>{timeStamp}</Text>
        </View>
        <View style={{ width: "100%" }}>
          <Text style={[styles.disclaimer, { fontFamily: "GilroySemiBold" }]}>
            This document contains confidential information and is protected by the Health Insurance Portability and
            Accountability Act (HIPAA), the Health Information Technology for Economic and Clinical Health Act (HITECH)
            and a number of other federal and state privacy laws. This document and its contents may only be accessed,
            used or disclosed by duly authorized individuals in the course of the subject’s treatment, claims processing
            or as otherwise required or permitted by law. Any other access, use or disclosure is strictly prohibited and
            may result in civil or criminal penalties.
          </Text>
        </View>
      </Page>
    </Document>
  );
}

const callLogStyles = StyleSheet.create({
  infoBoldText: {
    fontSize: 10,
    fontFamily: "GilroySemiBold",
    paddingBottom: 12,
  },
  bodySection: {
    width: "100%",
    padding: "24pt 0",
  },
  label: {
    color: colorsLight.font.secondary,
    fontSize: 10,
    paddingBottom: 4,
    fontFamily: "GilroyRegular",
  },
});

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    padding: 56,
  },
  headerSection: {
    paddingBottom: 24,
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: colorsLight.gray.divider,
  },
  bodySection: {
    width: "100%",
    padding: "24pt 0",
    borderBottomWidth: 1,
    borderBottomColor: colorsLight.gray.divider,
  },
  column: {
    flexDirection: "column",
    display: "flex",
    width: "50%",
  },
  footerSection: {
    paddingTop: 24,
    width: "100%",
  },
  textField: {
    paddingBottom: 12,
  },
  heading: {
    fontSize: 12,
    fontWeight: 600,
  },
  label: {
    color: colorsLight.font.secondary,
    fontSize: 10,
    paddingBottom: 4,
  },
  contactLink: {
    color: colorsLight.font.main,
    fontSize: 10,
    paddingTop: 4,
  },
  disclaimer: {
    color: colorsLight.font.light,
    fontSize: 10,
  },
  body: {
    color: colorsLight.font.main,
    fontWeight: 600,
    fontSize: 10,
  },
});
