import React, { SVGProps } from "react";

export default function PurpleClipboard(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="84" height="84" rx="42" fill="#D1C7E4" />
      <path d="M58.3813 24.334H24.1499V64.2588H58.3813V24.334Z" fill="#A1AAB4" />
      <path d="M50.3088 26.7539H26.572V61.835H55.9456V32.4048L50.3088 26.7539Z" fill="white" />
      <path
        d="M49.0192 19.3898H44.4588C44.4588 17.3787 43.0283 15.75 41.258 15.75C39.4877 15.75 38.0572 17.3787 38.0572 19.3898H33.4968L32.208 27.4626H50.2938L49.0192 19.3898Z"
        fill="#212936"
      />
      <path
        d="M35.551 32.3208H33.115V29.8848H31.4438V32.3208H29.0078V33.992H31.4438V36.428H33.115V33.992H35.551V32.3208Z"
        fill="#EA1D63"
      />
      <path d="M48.8778 39.1348H33.6387V40.2819H48.8778V39.1348Z" fill="#89D3DD" />
      <path d="M53.1266 57.5H47.0508V58.6472H53.1266V57.5Z" fill="#89D3DD" />
      <path d="M53.1262 43.3379H29.3894V44.4851H53.1262V43.3379Z" fill="#C2C2C2" />
      <path d="M53.1262 46.7793H29.3894V47.9265H53.1262V46.7793Z" fill="#C2C2C2" />
      <path d="M53.1262 50.2246H29.3894V51.3718H53.1262V50.2246Z" fill="#C2C2C2" />
      <path d="M39.9973 53.6641H29.3894V54.8113H39.9973V53.6641Z" fill="#C2C2C2" />
      <path d="M50.3081 32.4048H55.9449L50.3081 26.7539V32.4048Z" fill="#F5F5F5" />
      <circle cx="57.5" cy="27.5" r="9.5" fill="white" />
      <path
        d="M57.1499 17.75C51.6299 17.75 47.1499 22.23 47.1499 27.75C47.1499 33.27 51.6299 37.75 57.1499 37.75C62.6699 37.75 67.1499 33.27 67.1499 27.75C67.1499 22.23 62.6699 17.75 57.1499 17.75ZM58.1499 32.75H56.1499V26.75H58.1499V32.75ZM58.1499 24.75H56.1499V22.75H58.1499V24.75Z"
        fill="#6948A6"
      />
    </svg>
  );
}
