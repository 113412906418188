import { PeerToPeerOutreachOutcome } from "@coherehealth/core-platform-api";
import { Grid, useTheme } from "@material-ui/core";
import { SingleSelectDropdown, RadioGroup, DateTextField, TimeTextField } from "@coherehealth/common";
import { ChangeStatusFormState } from "../utils";

interface PendingMDReviewFormProps {
  setFormState: (value: React.SetStateAction<ChangeStatusFormState>) => void;
  formState: ChangeStatusFormState;
}

export default function PendingMDReviewForm(props: PendingMDReviewFormProps): JSX.Element {
  const { spacing } = useTheme();
  const { formState, setFormState } = props;
  return (
    <>
      <Grid item xs={12} style={{ paddingTop: spacing(3) }}>
        <RadioGroup
          row
          label="Was a Peer-to-peer offered?"
          options={[
            { id: "YES", label: "Yes" },
            { id: "NO", label: "No" },
          ]}
          onChange={(val) => {
            setFormState((prevFormState) => ({
              ...prevFormState,
              peerToPeer: {
                ...prevFormState.peerToPeer,
                attempted: val === "YES",
              },
            }));
          }}
          value={formState.peerToPeer?.attempted ? "YES" : "NO"}
          data-testid="peer-to-peer-offered-radiogroup"
        />
      </Grid>
      {formState.peerToPeer?.attempted && (
        <>
          <Grid container item xs={12} spacing={1} style={{ padding: spacing(1, 1, 2) }}>
            <Grid item xs={3}>
              <DateTextField
                label="Last attempt date"
                value={formState.peerToPeer?.lastAttempt?.date}
                onChangeValue={(date) => {
                  setFormState((prevFormState) => ({
                    ...prevFormState,
                    peerToPeer: {
                      ...prevFormState.peerToPeer,
                      lastAttempt: {
                        ...prevFormState.peerToPeer?.lastAttempt,
                        date,
                      },
                    },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TimeTextField
                label="Time (EST)"
                value={formState.peerToPeer?.lastAttempt?.time}
                onChangeValue={(time) => {
                  setFormState((prevFormState) => ({
                    ...prevFormState,
                    peerToPeer: {
                      ...prevFormState.peerToPeer,
                      lastAttempt: {
                        ...prevFormState.peerToPeer?.lastAttempt,
                        time,
                      },
                    },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SingleSelectDropdown
                options={[
                  { id: "AM", label: "AM" },
                  { id: "PM", label: "PM" },
                ]}
                label="AM/PM"
                value={formState.peerToPeer?.lastAttempt?.meridian}
                onChange={(meridian) => {
                  setFormState((prevFormState) => ({
                    ...prevFormState,
                    peerToPeer: {
                      ...prevFormState.peerToPeer,
                      lastAttempt: {
                        ...prevFormState.peerToPeer?.lastAttempt,
                        meridian,
                      },
                    },
                  }));
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup<PeerToPeerOutreachOutcome | "">
              label="What action did the practice take?"
              options={[
                { id: "UNRESPONSIVE", label: "Practice was unresponsive" },
                { id: "DECLINED", label: "Practice declined peer to peer" },
              ]}
              value={formState.peerToPeer?.lastAttempt?.outcome}
              onChange={(outcome) => {
                setFormState((prevFormState) => ({
                  ...prevFormState,
                  peerToPeer: {
                    ...prevFormState.peerToPeer,
                    lastAttempt: {
                      ...prevFormState.peerToPeer?.lastAttempt,
                      outcome,
                    },
                  },
                }));
              }}
            />
          </Grid>
        </>
      )}
    </>
  );
}
