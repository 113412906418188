import { Grid, Divider, makeStyles } from "@material-ui/core";
import { ReactNode } from "react";

export const pillStyle = {
  borderRadius: "4px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "inline-block",
};

export const readmissionNoticeContainer = {
  display: "flex",
  marginTop: "8px",
  alignItems: "center",
};
export const readmissionNoticeText = {
  color: "#55358F",
  marginLeft: "8px",
};

export const useOtherSRsStyles = makeStyles((theme) => ({
  body1: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.75),
  },
  serviceRequestPath: {
    marginBottom: theme.spacing(0.5),
  },
}));

export const CustomDivider = ({ children }: { children: ReactNode }) => {
  return (
    <Grid container alignItems="center" spacing={3} style={{ paddingLeft: "28px" }}>
      <Grid item>{children}</Grid>
      <Grid item xs>
        <Divider />
      </Grid>
    </Grid>
  );
};
