import React from "react";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { H2, InlineButton, Modal } from "@coherehealth/common";
import { OrderRowContainer } from "./OrderRowContainer";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Box, Grid, CircularProgress, styled } from "@material-ui/core";
import OrderLoadingIcon from "../images/OrderLoadingIcon.svg";

interface Props {
  serviceRequests?: ServiceRequestResponse[];
  open: boolean;
  onModalClose: () => void;
  onOrderImport: (sr: ServiceRequestResponse) => void;
  loading?: boolean;
}

export type AuthHeaderType =
  | "AUTH_REQUIRED_BY_COHERE"
  | "AUTH_REQUIRED_BY_VENDOR"
  | "INDETERMINATE"
  | "AUTH_NOT_REQUIRED";

// conditions
const palCheckPxCodesExists = (serviceRequest: ServiceRequestResponse) => {
  return serviceRequest?.palCheck?.pxCodes && serviceRequest?.palCheck?.pxCodes?.length > 0;
};
// 1. all palCheck pxCodes have isAuthRequired == false
const authIsNotRequired = (serviceRequest: ServiceRequestResponse) => {
  return (
    !authWithInsufficientInfo(serviceRequest) &&
    palCheckPxCodesExists(serviceRequest) &&
    serviceRequest?.palCheck?.pxCodes?.every((c) => !c.isAuthRequired)
  );
};
// 2. auths where auth is required and no submissionVendor
const authIsRequiredByCohere = (serviceRequest: ServiceRequestResponse) => {
  return (
    !authWithInsufficientInfo(serviceRequest) &&
    serviceRequest?.palCheck?.pxCodes?.some(
      (c) => c.isAuthRequired && (!c.authSubmissionVendor || c.authSubmissionVendor === "Cohere")
    )
  );
};
// 3. every pxCode has authSubmission vendor that is not null, and at least one that auth is required for
const authIsRequiredByOtherVendor = (serviceRequest: ServiceRequestResponse) => {
  return (
    !authIsNotRequired(serviceRequest) &&
    !authIsRequiredByCohere(serviceRequest) &&
    !authWithInsufficientInfo(serviceRequest)
  );
};
// 4. auths with insufficient information (no pxCodes and/or no placeOfService)
const authWithInsufficientInfo = (serviceRequest: ServiceRequestResponse) => {
  return !serviceRequest?.palCheck;
};

export const ExistingOrdersModal = ({ serviceRequests, onModalClose, open, loading, onOrderImport }: Props) => {
  const cohereAuthRequired = serviceRequests?.filter((sr) => authIsRequiredByCohere(sr));
  const vendorAuthRequired = serviceRequests?.filter((sr) => authIsRequiredByOtherVendor(sr));
  const authNotRequired = serviceRequests?.filter((sr) => authIsNotRequired(sr));
  const authNotEnoughInfo = serviceRequests?.filter((sr) => authWithInsufficientInfo(sr));

  return (
    <EHRModal open={open} onClose={onModalClose} maxWidth={false}>
      {loading ? (
        <LoadingContainer>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12}>
              <Box sx={{ position: "relative", display: "inline-flex", left: 300 }}>
                <CircularProgress size={120} />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={OrderLoadingIcon} alt="" />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <ModalTitle>Looking for matching orders in the patient's chart...</ModalTitle>
            </Grid>
          </Grid>
        </LoadingContainer>
      ) : (
        <>
          <ModalTitle>Would you like to import order details?</ModalTitle>
          {cohereAuthRequired && cohereAuthRequired?.length > 0 && (
            <OrderRowContainer
              type="AUTH_REQUIRED_BY_COHERE"
              serviceRequests={cohereAuthRequired}
              onOrderImport={onOrderImport}
            />
          )}
          {vendorAuthRequired && vendorAuthRequired?.length > 0 && (
            <OrderRowContainer
              type="AUTH_REQUIRED_BY_VENDOR"
              serviceRequests={vendorAuthRequired}
              onOrderImport={onOrderImport}
            />
          )}
          {authNotEnoughInfo && authNotEnoughInfo?.length > 0 && (
            <OrderRowContainer type="INDETERMINATE" serviceRequests={authNotEnoughInfo} onOrderImport={onOrderImport} />
          )}
          {authNotRequired && authNotRequired?.length > 0 && (
            <OrderRowContainer
              type="AUTH_NOT_REQUIRED"
              serviceRequests={authNotRequired}
              onOrderImport={onOrderImport}
            />
          )}
          <FooterButtonContainer>
            <InlineButton data-tracking-id="start-without-ehr-order" onClick={onModalClose}>
              Start a request without importing
            </InlineButton>
          </FooterButtonContainer>
        </>
      )}
    </EHRModal>
  );
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ModalTitle = styled(H2)(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const FooterButtonContainer = styled("div")(({ theme }) => ({
  textAlign: "center",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const EHRModal = styled(Modal)({
  margin: "auto",
  width: "1024px",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const LoadingContainer = styled(Grid)(({ theme }) => ({
  width: "100%",
}));
