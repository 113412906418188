import React from "react";
import { Body1, colorsLight, H2, InlineButton, ModelTrainingIconBlue, PrimaryButton } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { createStyles, makeStyles, styled, Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(5),
    },
  })
);

interface IProps {
  open: boolean;
  onClose: () => void;
  onSave: () => Promise<any>;
  loading: boolean;
}

export default function AutocompleteModal({ open, onClose, onSave, loading }: IProps) {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      fullWidth
      onClose={onClose}
      aria-labelledby="sr-submission-message"
      aria-describedby={"sr-detail-message"}
      open={open}
    >
      <RedirectContent>
        <div style={{ textAlign: "center" }}>
          <ModelTrainingIconBlue height={"56"} width={"56"} />
        </div>
        <Grid container direction="column" alignItems="center">
          <RedirectMessageHeader id="sr-detail-message">
            Did you check all autofilled content is correct?
          </RedirectMessageHeader>
          <RedirectMessageDetails id="sr-submission-message">
            We autofilled some fields from the intake document. Please make sure all this information is correct before
            proceeding.
          </RedirectMessageDetails>
        </Grid>
      </RedirectContent>
      <RedirectDialogActions>
        <ActionButton
          onClick={async () => {
            await onSave();
            onClose();
          }}
          disabled={loading}
          loading={loading}
        >
          Continue anyway
        </ActionButton>
      </RedirectDialogActions>
      <DashboardDialogActions>
        <InlineButton onClick={onClose} style={{ minWidth: 0, color: colorsLight.black.mediumEmphasis }}>
          Go back
        </InlineButton>
      </DashboardDialogActions>
    </Dialog>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RedirectContent = styled(DialogContent)({
  padding: 0,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RedirectMessageHeader = styled(H2)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  textAlign: "center",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RedirectMessageDetails = styled(Body1)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  color: colorsLight.black.mediumEmphasis,
  textAlign: "center",
  margin: theme.spacing(0, 8),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RedirectDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: `${theme.spacing(4)}px 0 0`,
  justifyContent: "center",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DashboardDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: `${theme.spacing(4)}px 0 0`,
  justifyContent: "center",
  margin: theme.spacing(0, 0, 4),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ActionButton = styled(PrimaryButton)(({ theme }) => ({
  padding: `${theme.spacing(2)}px ${theme.spacing(10)}px`,
}));
