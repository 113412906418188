import { Dispatch, SetStateAction } from "react";
import { useFeature, InlineButton, Body1 } from "../../components";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, Grid } from "@material-ui/core";
import { colorsLight } from "../../themes";
import PencilIcon from "@material-ui/icons/Create";
import UndoIcon from "@material-ui/icons/Undo";
import {
  SimulatedDenialLetterHeader,
  SimulatedDenialLetterDivider,
  SimulatedDenialLetterCaption,
  ReviewTimeDateAuthorCaption,
} from "./DenialLetterUtil";
import { MdReview, NurseReview, PeerToPeerReview } from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import { CopyToClipboard } from "../CopyToClipboard";

export interface WithTemplateTitle {
  templateTitle?: string;
}
interface Props<DenialTemplateType extends WithTemplateTitle> {
  isBlob?: boolean;
  setIsBlob: Dispatch<SetStateAction<boolean>>;
  isReadOnly?: boolean;
  denialsTemplate?: DenialTemplateType | null;
  review?: MdReview | PeerToPeerReview | NurseReview | null;
  conveneStyling?: boolean;
  hideRevertButton?: boolean;
  denialLetterView?: string;
  letterPath?: string;
  canSelectTemplate?: boolean;
  setSelectTemplate?: Dispatch<boolean>;
  setRestoreTemplateOpen?: Dispatch<boolean>;
  hideCustomLetterButton?: boolean;
}

export default function DenialLetterHeader<DenialTemplateType extends WithTemplateTitle>({
  isBlob,
  setIsBlob,
  isReadOnly,
  denialsTemplate,
  review,
  conveneStyling,
  hideRevertButton,
  denialLetterView,
  letterPath,
  canSelectTemplate,
  setSelectTemplate,
  setRestoreTemplateOpen,
  hideCustomLetterButton = false,
}: Props<DenialTemplateType>) {
  const prePopulateDenialsTemplate = useFeature("prePopulateDenialsTemplate");
  const logrocketUnmasking = useFeature("logrocketUnmasking");

  const showCustomLetterButton = !isReadOnly && !hideCustomLetterButton;

  return (
    <>
      {isBlob ? (
        !prePopulateDenialsTemplate ? (
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <SimulatedDenialLetterHeader>
                {denialLetterView === "LETTER_WRITE"
                  ? noTemplateWriteHeader
                  : denialLetterView === "LETTER_EDIT"
                  ? noTemplateEditHeader
                  : noTemplateReadHeader}
              </SimulatedDenialLetterHeader>
              {isReadOnly && (
                <DenialDateContainer>
                  <ReviewTimeDateAuthorCaption
                    reviewDateCreated={review?.dateCreated || ""}
                    reviewLastUpdated={review?.lastUpdated || ""}
                    reviewCreatedByName={review?.createdByName || ""}
                    firstLineText="Last edited on "
                    conveneStyling={conveneStyling}
                  />
                </DenialDateContainer>
              )}
            </Grid>
            <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
              {denialLetterView === "READ_ONLY" && <CopyLetterLinkButton letterPath={letterPath} />}
            </Grid>
          </Grid>
        ) : (
          <>
            <HeaderFlexContainer>
              {denialsTemplate ? (
                denialLetterView === "LETTER_EDIT" || denialLetterView === "READ_ONLY" ? (
                  <SimulatedDenialLetterHeader>{noTemplateEditHeader}</SimulatedDenialLetterHeader>
                ) : (
                  <SimulatedDenialLetterHeader>Custom letter</SimulatedDenialLetterHeader>
                )
              ) : (
                <ReadOnlyLetterText>No template found</ReadOnlyLetterText>
              )}
              <InnerHeaderFlexContainer>
                {!isReadOnly && !hideRevertButton && (
                  <InlineButton
                    style={{ width: 285 }}
                    disabled={isReadOnly}
                    onClick={() => {
                      setRestoreTemplateOpen?.(true);
                    }}
                    startIcon={<UndoIcon />}
                  >
                    Revert to suggested template
                  </InlineButton>
                )}
              </InnerHeaderFlexContainer>
            </HeaderFlexContainer>
            {!denialsTemplate ? (
              denialLetterView === "LETTER_EDIT" || denialLetterView === "READ_ONLY" ? (
                <SimulatedDenialLetterHeader>{noTemplateEditHeader}</SimulatedDenialLetterHeader>
              ) : (
                <SimulatedDenialLetterHeader>Custom letter</SimulatedDenialLetterHeader>
              )
            ) : (
              <></>
            )}
          </>
        )
      ) : (
        <>
          <HeaderFlexContainer>
            <ReadOnlyLetterText>Template:</ReadOnlyLetterText>
            <div>
              {canSelectTemplate && setSelectTemplate && (
                <InlineButton
                  style={{ whiteSpace: "nowrap", marginRight: showCustomLetterButton ? 16 : 0 }}
                  disabled={isReadOnly}
                  onClick={() => {
                    setSelectTemplate(true);
                  }}
                  startIcon={<PencilIcon />}
                >
                  Select a new template
                </InlineButton>
              )}
              {showCustomLetterButton && (
                <InlineButton
                  style={{ whiteSpace: "nowrap" }}
                  disabled={isReadOnly}
                  onClick={() => {
                    setIsBlob(!isBlob);
                  }}
                  startIcon={<PencilIcon />}
                >
                  Custom letter
                </InlineButton>
              )}
            </div>
          </HeaderFlexContainer>
          <div>
            <SimulatedDenialLetterHeader data-public={!!logrocketUnmasking ? true : undefined}>
              {denialsTemplate?.templateTitle || "Denial letter"}
            </SimulatedDenialLetterHeader>
          </div>
        </>
      )}
      {isReadOnly && prePopulateDenialsTemplate && (
        <DenialDateContainer>
          <ReviewTimeDateAuthorCaption
            reviewDateCreated={review?.dateCreated || ""}
            reviewLastUpdated={review?.lastUpdated || ""}
            reviewCreatedByName={review?.createdByName || ""}
            firstLineText="Last edited on "
            conveneStyling={conveneStyling}
          />
        </DenialDateContainer>
      )}
      {(prePopulateDenialsTemplate || denialLetterView !== "READ_ONLY") && (
        <DividerContainer>
          <SimulatedDenialLetterDivider />
        </DividerContainer>
      )}
      {isBlob && (
        <CustomLanguageCaptionContainer>
          <SimulatedDenialLetterCaption>
            {denialLetterView === "LETTER_EDIT"
              ? noTemplateEditSubheader
              : denialLetterView === "READ_ONLY"
              ? ""
              : prePopulateDenialsTemplate
              ? "Write custom language below"
              : noTemplateWriteSubheader}
          </SimulatedDenialLetterCaption>
        </CustomLanguageCaptionContainer>
      )}
    </>
  );
}

interface CopyButtonLinkProps {
  letterPath?: string;
}

function CopyLetterLinkButton({ letterPath }: CopyButtonLinkProps) {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <CopyToClipboard
      text={letterPath}
      message={"Copy denial letter link"}
      onClick={() => {
        enqueueSnackbar("Copied to clipboard", { variant: "success" });
      }}
    />
  );
}

const noTemplateWriteHeader = "Insert denial language here";
const noTemplateEditHeader = "Final determination preview";
const noTemplateReadHeader = "Denial Letter";
const noTemplateWriteSubheader =
  "Locate the appropriate denial language template using the information on the left, and use it to write your denial letter below";
const noTemplateEditSubheader =
  "Preview and edit language below. Edits made here may not be visible to MD Reviewers in subsequent reviews.";

// eslint-disable-next-line cohere-react/no-mui-styled-import
const HeaderFlexContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const InnerHeaderFlexContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const ReadOnlyLetterText = styled(Body1)(({ theme }) => ({
  color: colorsLight.font.secondary,
  paddingTop: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DividerContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(2, 0, 3),
  width: "100%",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CustomLanguageCaptionContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DenialDateContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 0, 1),
  width: "100%",
}));
