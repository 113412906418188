import { Body1, Alert } from "@coherehealth/common";

interface Props {
  cohereId?: string;
}

export default function VendorAssessmentSummary({ cohereId }: Props) {
  return (
    <Alert severity={"info"} message={"Clinical questions were submitted to HealthHelp"}>
      <Body1>
        To see the clinical assessment from HealthHelp, please call Cohere at 1-833-283-0033 to be redirected to
        HealthHelp.
        <br></br>
        <br></br>
        Your tracking number for this authorization is #{cohereId}
      </Body1>
    </Alert>
  );
}
