import config from "api/config";
import { Attachment } from "@coherehealth/core-platform-api";
import { downloadUrl } from "@coherehealth/common";
import { SupportedFileType } from "components/DocumentViewer/DocumentViewer";
import { PrintTypes } from "print-js";
import print from "util/print";
import { stringify as stringifyQueryString, parse as parseQueryString } from "qs";
import { NotificationViewerItem } from "./notificationUtil";

export const createAttachmentOpenPath = (attachment?: Attachment) => {
  return `${config.SERVICE_API_URL}serviceRequest/${attachment?.serviceRequest?.id}/attachment/${attachment?.id}/download`;
};

export const convertPathToOcrEnhanced = (originalPath: string) => {
  try {
    const url = new URL(originalPath);
    const query = parseQueryString(url.search.replace(/^[?]/, ""));
    query.preferOcrEnhanced = "true";
    url.search = stringifyQueryString(query);
    return url.toString();
  } catch {
    return originalPath;
  }
};

export const determineFileType = (notification: NotificationViewerItem): SupportedFileType | null => {
  // convertedToPDF is determined during file fetching, by comparing the blob
  // type to the contentType.
  if (notification.convertedToPDF) {
    return SupportedFileType.PDF;
  }

  // In useGetNotifications, contentType defaults to PDF, but is replaced if
  // the file has its own contentType.
  switch (notification.contentType) {
    case "application/pdf":
      return SupportedFileType.PDF;
    case "image/jpeg":
      return SupportedFileType.JPG;
  }

  // Fallback: Check by file name
  const splitFileName = notification.fileName?.split(".") || "";
  const fileExtension = splitFileName[splitFileName.length - 1].toLowerCase();

  if (fileExtension === "pdf") {
    return SupportedFileType.PDF;
  }
  if (fileExtension === "jpg" || fileExtension === "jpeg") {
    return SupportedFileType.JPG;
  }

  return null;
};

export const getFileTypeForPrint = (notification: NotificationViewerItem): PrintTypes | null => {
  const fileType = determineFileType(notification);

  switch (fileType) {
    case SupportedFileType.JPG:
      return "image";
    case SupportedFileType.PDF:
      return "pdf";
    default:
      return null;
  }
};

export const downloadSingleNotification = (notification: NotificationViewerItem) => {
  if (notification.fileName && notification?.fileUrl) {
    if (notification.convertedToPDF) {
      const splitFileName = notification.fileName.split(".");
      splitFileName.splice(splitFileName.length - 1, 1, "pdf");
      const pdfConvertedFileName = splitFileName.join(".");
      downloadUrl(notification.fileUrl, pdfConvertedFileName);
    } else {
      downloadUrl(notification.fileUrl, notification.fileName);
    }
  }
};

export const printSingleNotification = (notification: NotificationViewerItem) => {
  if (notification && notification?.fileUrl) {
    const fileTypeForPrint = getFileTypeForPrint(notification);
    if (fileTypeForPrint) {
      print(notification.fileUrl, fileTypeForPrint);
    }
  }
};
