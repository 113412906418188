import { isValid } from "date-fns";
import { DateSelect, formatDateStr } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core";
import { differenceInCalendarDays } from "date-fns";
import { useCallback, useEffect, useRef, useState, MouseEvent as ReactMouseEvent } from "react";

interface Props {
  label?: string;
  nextReviewDate: Date | null;
  setNextReviewDate: (nextReviewDate: Date | null) => void;
  minDate?: Date;
}

export const NextReviewDateSelect = (props: Props) => {
  const daysCounterHandlerPID = useRef<NodeJS.Timeout | null>(null);
  const classes = useStyles();
  const inpRef = useRef<HTMLInputElement>(null);
  const [formattedInputDate, setFormattedInputDate] = useState<string>("");
  const [isValidDateInput, setIsValidDateInput] = useState<boolean>(true);
  const { nextReviewDate, setNextReviewDate, minDate, label } = props;
  const currentDate = new Date();
  const minNextReviewDate = minDate ? minDate : currentDate;

  const isValidNextReviewDate = (date: Date | null) => {
    return date && isValid(date) && minNextReviewDate && differenceInCalendarDays(date, minNextReviewDate) >= 0;
  };

  const daysCounterHandler = useCallback(() => {
    daysCounterHandlerPID.current = setTimeout(() => {
      if (inpRef.current) {
        inpRef.current.value = formattedInputDate;
      }
    }, 100);
  }, [formattedInputDate]);

  useEffect(() => {
    if (inpRef?.current !== document.activeElement) {
      daysCounterHandler();
    }

    return () => {
      if (!!daysCounterHandlerPID.current) {
        clearTimeout(daysCounterHandlerPID.current);
      }
    };
  });

  useEffect(() => {
    if (nextReviewDate) {
      setFormattedInputDate(formatInputDate(nextReviewDate, minNextReviewDate));
    }
  }, [minNextReviewDate, nextReviewDate]);

  return (
    <DateSelect
      label={label || "Update next review date"}
      minDate={minNextReviewDate}
      minDateMessage="The Next Review Date cannot be earlier than the current date"
      onDateChange={(date: Date | null) => {
        setIsValidDateInput(!!isValidNextReviewDate(date));
        if (date && isValidNextReviewDate(date)) {
          setFormattedInputDate(formatInputDate(date, minNextReviewDate));
          setNextReviewDate(date);
        }
      }}
      value={nextReviewDate}
      TextFieldProps={{
        className: `${classes.fullWidth}`,
      }}
      inputProps={{
        ref: inpRef,
        onBlur: daysCounterHandler,
        onFocus: (event: React.FocusEvent<HTMLInputElement>) => {
          if (nextReviewDate) {
            event.target.value = formatDateStr(nextReviewDate);
          }
        },
        "data-testid": "next-review-date-inp",
      }}
      disablePortal={true}
      // Trigger days counter on calendar blur as well as input blur
      onBlur={daysCounterHandler}
      error={!isValidDateInput}
      helperText={!isValidDateInput && "Invalid date, please enter a valid next review date."}
      attemptedSubmit={true}
    />
  );
};

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: "100%",
  },
}));

function formatInputDate(date: Date, minDate: Date) {
  const datesDiff = differenceInCalendarDays(date, minDate);
  const dateDiffSuffix = pluralDaysCount(datesDiff) ? "days" : "day";
  const formattedDate = formatDateStr(date);
  return `${formattedDate} • in ${datesDiff} ${dateDiffSuffix}`;
}

function pluralDaysCount(duration: number): boolean {
  return duration !== 1;
}

// Traverse upward through the DOM tree until we find a parent element with the
// specified class.
const hasParentWithClass = (element?: Element | null, classname?: string, maxDepth: number = 10_000): boolean => {
  if (!element || !classname) {
    return false;
  }

  let depth = 0;
  let nextElement: Element | null = element.parentElement ?? null;

  while (nextElement && depth < maxDepth) {
    if (nextElement.classList?.contains(classname)) {
      return true;
    }
    nextElement = nextElement.parentElement ?? null;
    depth++;
  }

  return false;
};

/*
  Modals and Dialogs use FocusTrap to prevent users from focusing outside their
  children components. When we click outside the date picker's Popper, the blur
  method doesn't activate. We need to invoke blur manually.

  To use this, pass `onClick={handlePickersModalClick}` to the Modal or Dialog
  inside which you are using NextReviewDateSelect.
  
  https://github.com/mui/material-ui-pickers/issues/1852#issuecomment-682521200
*/
export function handlePickersModalClick(e: ReactMouseEvent) {
  const targetElement = e?.target;

  // Used Element instead of HTMLElement because clicking on the calendar icon
  // (an SVG) should still close the popup.
  if (
    targetElement &&
    targetElement instanceof Element &&
    !hasParentWithClass(targetElement, "MuiPickersPopper-paper")
  ) {
    const activeElement = document?.activeElement;
    if (
      activeElement &&
      activeElement instanceof HTMLElement &&
      activeElement?.classList?.contains("MuiPickersPopper-paper")
    ) {
      activeElement.blur();
    }
  }
}
