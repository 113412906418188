import React, { forwardRef, useEffect, useState } from "react";
import Cleave from "cleave.js/react";
import { InputBaseComponentProps } from "@material-ui/core/InputBase/InputBase";

import { TextField, TextFieldProps } from "../TextField";

type CleaveTimePattern = Array<"h" | "m" | "s">;

export interface InternalProps {
  /**
   * The pattern to match the time against, see https://github.com/nosir/cleave.js/blob/master/doc/options.md#timepattern
   * example: for June 4th, 1996, ['m', 'd', 'Y'] = "06/04/1996", ['y', 'm'] = "96/06", etc.
   * default: ["m", "d", "Y"]
   */
  timePattern?: CleaveTimePattern;
  timeFormat?: "12" | "24";
  /**
   * If a human readable date pattern should be appended to the label
   */
  addTimePatternToLabel?: boolean;
}

export type Props = InternalProps & TextFieldProps;

const TimeTextField = forwardRef(
  ({ timePattern = ["h", "m"], timeFormat, addTimePatternToLabel, label, value, ...textFieldProps }: Props, ref) => {
    let labelDisplay = label;
    if (addTimePatternToLabel) {
      labelDisplay = `${label} (${getFormatText(timePattern)})`;
    }

    return (
      <TextField
        {...textFieldProps}
        label={labelDisplay}
        ref={ref}
        value={value}
        // Sometimes material doesn't put these classes here w/ a controlled value, which causes the label to overlap
        InputLabelProps={{
          shrink: !!value || !!textFieldProps.placeholder,
        }}
        InputProps={{
          inputComponent: TimeAutoFormatterInput,
          inputProps: {
            timePattern,
            timeFormat,
          },
        }}
      />
    );
  }
);

export default TimeTextField;

type TimeAutoFormatterProps = Omit<InternalProps, "addTimePatternToLabel"> & InputBaseComponentProps;

const TimeAutoFormatterInput = ({
  timePattern,
  timeFormat = "12",
  inputRef,
  value,
  ...inputAttributes
}: TimeAutoFormatterProps) => {
  const [cleaveJS, setCleaveJS] = useState<any>(null);

  // A bit of a hack because Cleave doesn't really support controlled components
  // https://github.com/nosir/cleave.js/blob/master/doc/reactjs-component-usage.md#how-to-update-raw-value
  useEffect(() => {
    if (!!value && !!cleaveJS) {
      cleaveJS.setRawValue(value);
    }
  }, [value, cleaveJS]);

  return (
    <Cleave
      htmlRef={(internalRef) => (inputRef = internalRef)}
      options={{
        time: true,
        timePattern,
        timeFormat,
      }}
      onInit={setCleaveJS}
      value={value}
      defaultValue={value}
      {...inputAttributes}
    />
  );
};

const getFormatText = (pattern: CleaveTimePattern) => {
  const partToPlaceholder: Record<"h" | "m" | "s", string> = {
    h: "hh",
    m: "mm",
    s: "ss",
  };
  return pattern.map((part) => partToPlaceholder[part]).join(":");
};
