import {
  Body1,
  Body3,
  Caption,
  Column,
  DatePickerFilterConfig,
  FilterConfig,
  Pill,
  SelectOptionsHook,
  formatDateStr,
  parseDateFromISOString,
  useLazyLoadingQueryOptionsHook,
} from "@coherehealth/common";
import InfoIcon from "@material-ui/icons/Info";
import {
  AuthStatusWithLabel,
  AuthorizationResponse,
  ProcedureCode,
  ServiceRequestResponse,
  useGetProcedureCodes,
} from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Box, IconButton, Link, Theme, Tooltip, styled } from "@material-ui/core";
import { ReactNode } from "react";
import { differenceInDays } from "date-fns";
import { compact } from "lodash";
import { generatePath } from "react-router";
import routes from "routes";
import { formatServiceName, isNegationAuthStatus, sortServiceRequestByDateField } from "util/serviceRequest";
import { getVariant, getAuthStatusText } from "../ServiceRequestTable";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { readmissionNoticeContainer, pillStyle, readmissionNoticeText } from "./otherServiceRequestsStyles";
import { authStatusOptions } from "util/authStatusesWithLabel";

export const isReadmission = (otherDate: Date): boolean => {
  const dateToday = new Date();
  const diffMs = dateToday.getTime() - otherDate.getTime();
  const diffDays = Math.ceil(diffMs / (1000 * 3600 * 24));
  return diffDays >= 0 && diffDays <= 30;
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const SecondaryText = styled(Body1)(() => ({
  fontFamily: "Gilroy-Regular",
  fontSize: "13px",
  marginLeft: "4px",
  maxWidth: "210px",
}));
export const authStatusFilter = {
  filterType: "LookupSearch",
  useOptions: () => ({
    optionsLoading: false,
    options: authStatusOptions,
    filterOptions: (options: AuthStatusWithLabel[], state) =>
      options.filter((option) => option.label.toLowerCase().includes(state.inputValue.toLowerCase())),
  }),
  renderOption: ({ label }: AuthStatusWithLabel) => {
    return <div>{label}</div>;
  },
  isLazyLoaded: false,
  optionToLabel: ({ label }: AuthStatusWithLabel) => label,
  optionToParamValue: ({ id }: AuthStatusWithLabel) => id,
  key: "authStatus",
  label: "Status",
} as FilterConfig;
const useProcedures: SelectOptionsHook<ProcedureCode | null> = (selectOptionsParams) =>
  useLazyLoadingQueryOptionsHook<any, unknown>({
    useGetHook: useGetProcedureCodes,
    ...selectOptionsParams,
  });

export const cptCodeFilter = {
  filterType: "LookupSearch",
  key: "cptCodes",
  useOptions: useProcedures as SelectOptionsHook<ProcedureCode>,
  optionToLabel: ({ code }: ProcedureCode) => code || "--",
  renderOption: ({ code, description }: ProcedureCode) => {
    return (
      <>
        <strong>{code}</strong> - {description}
      </>
    );
  },
  apiParamName: "procedureCodes",
  optionToParamValue: ({ code }: ProcedureCode) => code,
  label: "CPT codes",
} as FilterConfig;
export const serviceDateFilter: DatePickerFilterConfig = {
  filterType: "DatePicker",
  key: "serviceDateAfter",
  label: "Service date after",
};
export const columnValueConfig = (
  columnName: string,
  showPreviouslyApprovedRequest?: boolean
): ((authorization: AuthorizationResponse, isExpanded?: boolean) => ReactNode) => {
  switch (columnName) {
    case "expand":
      return (authorization: AuthorizationResponse, isExpanded?: boolean) => (
        <>
          {authorization.serviceRequests && authorization.serviceRequests?.length > 1 ? (
            <IconButton style={{ padding: 0 }} color="primary" size="small">
              {isExpanded ? <ExpandLess style={{ fontSize: "20px" }} /> : <ExpandMore style={{ fontSize: "20px" }} />}
            </IconButton>
          ) : null}
        </>
      );
    case "authNumber":
      return ({ authNumber, serviceRequests }: AuthorizationResponse, isExpanded?: boolean) => {
        const descServiceRequests = sortServiceRequestByDateField("dateCreated", serviceRequests || [], true);
        const lastSR = descServiceRequests?.[0];
        const cohereId = authNumber ?? lastSR?.cohereId ?? null;
        return (
          <Body3>
            {cohereId ? (
              <Link
                style={{ paddingTop: 0, fontFamily: isExpanded ? "Gilroy-Bold" : "" }}
                href={generatePath(routes.VIEW_ONLY_REVIEW).concat(`?serviceRequestId=${lastSR?.id}`)}
                target="_blank"
              >
                #{cohereId}
              </Link>
            ) : (
              "--"
            )}
          </Body3>
        );
      };
    case "serviceName":
      return ({ encounterType, clinicalServices, serviceRequests }: AuthorizationResponse, isExpanded?: boolean) => {
        const descServiceRequests = sortServiceRequestByDateField("dateCreated", serviceRequests || [], true);
        const { authCategory, authSubcategory, palCategory, prescribedDrug } = descServiceRequests?.[0] || {};
        const serviceName = formatServiceName({
          encounterType,
          clinicalServices,
          authCategory,
          authSubcategory,
          palCategory,
          prescribedDrug,
        });
        return <Body3 style={{ fontFamily: isExpanded ? "Gilroy-Bold" : "" }}>{serviceName || "--"}</Body3>;
      };
    case "serviceDates":
      return (
        { startDate, endDate, patientStatus, encounterType, actualDischargeDateTime }: AuthorizationResponse,
        isExpanded?: boolean
      ) => {
        const M_DASH = "\u2014";
        const isInpatient = encounterType === "INPATIENT";
        const totalDays = differenceInDays(parseDateFromISOString(endDate), parseDateFromISOString(startDate)) + 1;
        const authNotYetAdmitted = patientStatus === "NOT_YET_ADMITTED";
        return (
          <>
            <Body3 style={{ fontFamily: isExpanded ? "Gilroy-Bold" : "" }}>
              {compact([startDate, endDate]).map(formatDateStr).join(` ${M_DASH} `) || "--"}
              {!authNotYetAdmitted && ` (` + totalDays + (totalDays === 1 ? ` day)` : ` days)`)}
            </Body3>
            {actualDischargeDateTime && isInpatient && isReadmission(new Date(actualDischargeDateTime)) && (
              <div style={readmissionNoticeContainer}>
                <InfoIcon style={{ fontSize: "18px", color: "#55358F" }} />
                <Body3 style={readmissionNoticeText}>Discharged in last 30 days</Body3>
              </div>
            )}
          </>
        );
      };
    case "facility":
      return (authorization: AuthorizationResponse, isExpanded?: boolean) => {
        return (
          <Body3 style={{ fontFamily: isExpanded ? "Gilroy-Bold" : "", whiteSpace: "nowrap" }}>
            {authorization.selectedFacility?.name || "--"}
          </Body3>
        );
      };
    case "procedureCodes":
      return (authorization: AuthorizationResponse, isExpanded?: boolean) => {
        return (
          <Body3 style={{ fontFamily: isExpanded ? "Gilroy-Bold" : "", whiteSpace: "nowrap" }}>
            {authorization.semanticProcedureCodes?.map((px) => px.code).join(", ") || "--"}
          </Body3>
        );
      };
    case "status":
      return ({ authStatus, serviceRequests }: AuthorizationResponse) => {
        const descServiceRequests = sortServiceRequestByDateField("dateCreated", serviceRequests || [], true);
        const { pendingReason, initialDecisionDisposition } = descServiceRequests?.[0] || {};
        const label = getAuthStatusText(authStatus, pendingReason);
        const variant = getVariant(authStatus);
        return (
          <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            <Tooltip title={label} placement="top">
              <Box>
                <Pill variant={variant} label={label} style={pillStyle} />
              </Box>
            </Tooltip>
            {isNegationAuthStatus(authStatus) &&
              initialDecisionDisposition === "APPROVED" &&
              showPreviouslyApprovedRequest && <SecondaryText>Previously approved</SecondaryText>}
          </div>
        );
      };
    default:
      return (authorization: AuthorizationResponse) => authorization.authNumber;
  }
};

export const generateOtherAuthorizationTableColumns = (
  isInpatientUnplanned: boolean,
  showPreviouslyApprovedRequest: boolean,
  theme?: Theme
): Column<AuthorizationResponse>[] => {
  return [
    {
      name: "expand",
      value: columnValueConfig("expand"),
      width: "28px",
      minWidth: "28px",
      themedPaddingRight: 2,
      alignItems: "center",
      header: <></>,
    },
    {
      name: "authNumber",
      value: columnValueConfig("authNumber"),
      width: "20%",
      minWidth: theme?.spacing(6),
      themedPaddingRight: 0,
      alignItems: "center",
      header: <Caption color="textSecondary">Tracking #</Caption>,
    },
    {
      name: "serviceName",
      value: columnValueConfig("serviceName"),
      width: "35%",
      minWidth: theme?.spacing(8),
      themedPaddingRight: 2,
      alignItems: "center",
      header: <Caption color="textSecondary">Service Name</Caption>,
    },
    {
      name: "serviceDates",
      value: columnValueConfig("serviceDates"),
      width: isInpatientUnplanned ? "45%" : "30%",
      minWidth: theme?.spacing(10),
      themedPaddingRight: 2,
      alignItems: "center",
      header: <Caption color="textSecondary">Service Date(s)</Caption>,
    },
    ...(isInpatientUnplanned
      ? [
          {
            name: "facility",
            value: columnValueConfig("facility"),
            width: "33%",
            minWidth: theme?.spacing(8),
            themedPaddingRight: 2,
            unSortable: true,
            alignItems: "center",
            header: <Caption color="textSecondary">Facility</Caption>,
          },
        ]
      : [
          {
            name: "procedureCodes",
            value: columnValueConfig("procedureCodes"),
            width: "20%",
            minWidth: theme?.spacing(6),
            themedPaddingRight: 2,
            unSortable: true,
            alignItems: "center",
            header: <Caption color="textSecondary">CPT Codes</Caption>,
          },
        ]),
    {
      name: "status",
      value: columnValueConfig("status", showPreviouslyApprovedRequest),
      width: "30%",
      minWidth: theme?.spacing(8),
      alignItems: "center",
      themedPaddingRight: 3,
      unSortable: true,
      header: <Caption color="textSecondary">Status</Caption>,
    },
  ];
};

export const generateOtherAuthorizationRowChildTableColumns = (
  isInpatientUnplanned: boolean,
  showPreviouslyApprovedRequest: boolean,
  theme?: Theme
): Column<ServiceRequestResponse>[] => {
  return [
    {
      name: "gap",
      value: () => null,
      width: "24px",
      minWidth: "24px",
      themedPaddingRight: 2,
      alignItems: "center",
      header: <></>,
    },
    {
      name: "Tracking #",
      value: ({ id, cohereId }: ServiceRequestResponse) => {
        return (
          <Body3>
            <Link
              style={{ paddingTop: 0 }}
              href={generatePath(routes.VIEW_ONLY_REVIEW).concat(`?serviceRequestId=${id}`)}
              target="_blank"
            >
              #{cohereId}
            </Link>
          </Body3>
        );
      },
      width: "20%",
      minWidth: theme?.spacing(6),
      themedPaddingRight: 0,
      alignItems: "center",
      header: <Caption color="textSecondary">Tracking #</Caption>,
    },
    {
      name: "serviceName",
      value: ({
        encounterType,
        clinicalServices,
        authCategory,
        authSubcategory,
        palCategory,
        prescribedDrug,
      }: ServiceRequestResponse) => {
        const serviceName = formatServiceName({
          encounterType,
          clinicalServices,
          authCategory,
          authSubcategory,
          palCategory,
          prescribedDrug,
        });
        return <Body3>{serviceName || "--"}</Body3>;
      },
      width: "35%",
      minWidth: theme?.spacing(8),
      themedPaddingRight: 2,
      alignItems: "center",
      header: <Caption color="textSecondary">Service Name</Caption>,
    },
    {
      name: "serviceDates",
      value: ({ startDate, endDate, patientStatus }: ServiceRequestResponse) => {
        const M_DASH = "\u2014";
        const totalDays = differenceInDays(parseDateFromISOString(endDate), parseDateFromISOString(startDate)) + 1;
        const authNotYetAdmitted = patientStatus === "NOT_YET_ADMITTED";
        return (
          <Body3>
            {compact([startDate, endDate]).map(formatDateStr).join(` ${M_DASH} `) || "--"}
            {!authNotYetAdmitted && ` (` + totalDays + (totalDays === 1 ? ` day)` : ` days)`)}
          </Body3>
        );
      },
      width: "45%",
      minWidth: theme?.spacing(10),
      themedPaddingRight: 2,
      alignItems: "center",
      header: <Caption color="textSecondary">Service Date(s)</Caption>,
    },
    ...(isInpatientUnplanned
      ? [
          {
            name: "facility",
            value: (serviceRequest: ServiceRequestResponse) => {
              return <Body3>{serviceRequest.selectedFacility?.name || "--"}</Body3>;
            },
            width: "33%",
            minWidth: theme?.spacing(8),
            themedPaddingRight: 2,
            unSortable: true,
            alignItems: "center",
            header: <Caption color="textSecondary">Facility</Caption>,
          },
        ]
      : [
          {
            name: "procedureCodes",
            value: (serviceRequest: ServiceRequestResponse) => {
              return <Body3>{serviceRequest.semanticProcedureCodes?.map((px) => px.code).join(", ") || "--"}</Body3>;
            },
            width: "20%",
            minWidth: theme?.spacing(6),
            themedPaddingRight: 2,
            unSortable: true,
            alignItems: "center",
            header: <Caption color="textSecondary">CPT Codes</Caption>,
          },
        ]),
    {
      name: "status",
      value: ({ authStatus, pendingReason, initialDecisionDisposition }: ServiceRequestResponse) => {
        const variant = getVariant(authStatus);
        const label = getAuthStatusText(authStatus, pendingReason);
        return (
          <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            <Tooltip title={label} placement="top">
              <Box>
                <Pill variant={variant} label={label} style={pillStyle} />
              </Box>
            </Tooltip>
            {isNegationAuthStatus(authStatus) &&
              initialDecisionDisposition === "APPROVED" &&
              showPreviouslyApprovedRequest && <SecondaryText>Previously approved</SecondaryText>}
          </div>
        );
      },
      width: "30%",
      minWidth: theme?.spacing(8),
      alignItems: "center",
      themedPaddingRight: 3,
      unSortable: true,
      header: <Caption color="textSecondary">Status</Caption>,
    },
  ];
};
