import { useCallback, useState } from "react";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { useFeature } from "@coherehealth/common";
import { isTerminalStatus } from "util/serviceRequest";

export const useAttachmentTatValidation = (serviceRequests: ServiceRequestResponse[] | null) => {
  const [showTatConfirmationModal, setShowTatConfirmationModal] = useState(false);
  const [pendingUploadAction, setPendingUploadAction] = useState<(() => Promise<void>) | null>(null);

  const updateTatStartTimeToEarliestFax = useFeature("updateTatStartTimeToEarliestFax");

  const validateTatTimestamps = useCallback(
    (attachmentSubmissionDate: string): boolean => {
      if (!serviceRequests) {
        return false;
      }

      return serviceRequests.some(
        (sr) =>
          sr.tatStartTimestamp &&
          new Date(sr.tatStartTimestamp).setMilliseconds(0) > new Date(attachmentSubmissionDate).setMilliseconds(0) &&
          sr.requestor?.channel === "FAX" &&
          !isTerminalStatus(sr)
      );
    },
    [serviceRequests]
  );

  const handleConfirmWithTatValidation = useCallback(
    async (uploadAction: () => Promise<void>, attachmentSubmissionDate: string | undefined) => {
      const needsConfirmation = attachmentSubmissionDate ? validateTatTimestamps(attachmentSubmissionDate) : false;

      if (needsConfirmation && updateTatStartTimeToEarliestFax) {
        setShowTatConfirmationModal(true);
        setPendingUploadAction(() => uploadAction);
      } else {
        await uploadAction();
      }
    },
    [validateTatTimestamps, updateTatStartTimeToEarliestFax]
  );

  const handleConfirmTatChange = useCallback(async () => {
    if (pendingUploadAction) {
      setShowTatConfirmationModal(false);
      await pendingUploadAction();
      setPendingUploadAction(null);
    }
  }, [pendingUploadAction]);

  const handleCancelTatChange = useCallback(() => {
    setShowTatConfirmationModal(false);
    setPendingUploadAction(null);
  }, []);

  return {
    showTatConfirmationModal,
    handleConfirmWithTatValidation,
    handleConfirmTatChange,
    handleCancelTatChange,
  };
};
