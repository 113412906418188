import {
  Checkbox,
  H6,
  SelectOptionsHook,
  SingleSelect,
  SingleSelectDropdown,
  TextField,
  useLazyLoadingQueryOptionsHook,
} from "@coherehealth/common";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import { RxnormDrug, useGetRxnormConcepts, useGetRxnormSemanticDrugs } from "@coherehealth/core-platform-api";
import { DrugSelection, RxnormStrength } from "common/SharedServiceRequestFormComponents";

interface RxnormDrugWithRequirements extends RxnormStrength {
  id: string;
}
interface Props {
  drugSelection: DrugSelection;
  setDrugSelection: (drugSelection: DrugSelection) => void;
}
const useLazyDrugs: SelectOptionsHook<RxnormDrug> = (selectOptionsParams) =>
  useLazyLoadingQueryOptionsHook({
    useGetHook: useGetRxnormConcepts,
    ...selectOptionsParams,
  });

export default function DrugSelect({ drugSelection, setDrugSelection, ...props }: Props) {
  const theme = useTheme();
  const [drugLabel, setDrugLabel] = useState("Search for a drug by name");
  let {
    data: drugInfos,
    refetch: refetchDrugInfos,
    loading: drugInfosLoading,
  } = useGetRxnormSemanticDrugs({ cui: drugSelection.drug?.cui || "", lazy: true });

  useEffect(() => {
    if (drugSelection.drug) {
      refetchDrugInfos();
    }
  }, [drugSelection.drug, refetchDrugInfos]);

  const drugList: RxnormDrugWithRequirements[] = useMemo(() => {
    const NOT_PERIOD_NOT_NUMBER = /[^\d.]/g;
    return (
      drugInfos
        ?.map((drugInfo, idx) => {
          return {
            id: idx.toString(),
            strength: drugInfo.strength || "",
            strengthForm: drugInfo.rxn_dose_form || "",
            dosageLabel: drugInfo.label || "",
            dosageCode: drugInfo.code || "",
            dosageCui: drugInfo.cui || "",
            dosageUri: drugInfo.uri || "",
            dosageTty: drugInfo.tty || "",
            ndc: drugInfo.ndc || "",
            route: drugInfo.route || "",
          };
        })
        ?.sort((a, b) => {
          if (a.strengthForm === b.strengthForm) {
            const A = Number.parseFloat(a.strength.replace(NOT_PERIOD_NOT_NUMBER, ""));
            const B = Number.parseFloat(b.strength.replace(NOT_PERIOD_NOT_NUMBER, ""));
            if (A < B) {
              return -1;
            }
            if (A > B) {
              return 1;
            }
            return 0;
          }
          if (a.strengthForm < b.strengthForm) {
            return -1;
          }
          return 1;
        }) || []
    );
  }, [drugInfos]);

  const drugIsNameBrand: boolean | undefined = drugInfos?.some((drugInfo) => drugInfo?.tty === "SBD");
  const singleSelectTextEditRef = useRef<HTMLDivElement | null>(null);
  const numberValidator = (val: any, field: string) => {
    let strVal = val && val.trim();
    if (!strVal || strVal === "") {
      setDrugSelection({ ...drugSelection, [field]: "" });
    } else {
      const numVal = Math.trunc(Number(val));
      if (!isNaN(numVal)) {
        strVal = Math.max(0, numVal).toString();
        setDrugSelection({ ...drugSelection, [field]: strVal });
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} style={{ paddingTop: theme.spacing(4) }}>
        <Grid item xs={12}>
          <H6>Drug information</H6>
        </Grid>
        <Grid item xs={3}>
          <SingleSelect<RxnormDrug>
            withIcon
            useOptions={useLazyDrugs}
            onFocus={() => {
              setDrugLabel("Drug name");
            }}
            onBlur={() => {
              if (!drugSelection.drug) {
                setDrugLabel("Search for a drug by name");
              }
            }}
            label={drugLabel}
            selectedValue={drugSelection.drug}
            setSelectedValue={(drug) => {
              setDrugSelection({ ...drugSelection, drug, strengthId: "" });
            }}
            getOptionLabel={(option) => option?.preferredLabel || option?.label || ""}
          />
        </Grid>
        <Grid item xs={5}>
          <SingleSelectDropdown
            options={drugList}
            disabled={!drugSelection.drug}
            showLoadingIcon={drugInfosLoading}
            label={"Strength / dose form"}
            value={drugSelection.strengthId || ""}
            onChange={(strengthId) => {
              const drug = drugList.find((drug) => drug.id === strengthId);
              return setDrugSelection({
                ...drugSelection,
                strengthId,
                strengthDetails: drug,
                genericAllowed: drugIsNameBrand ? true : null,
              });
            }}
            renderOption={(drugInfo) => `${drugInfo.dosageLabel} `}
            fullWidth
            selectRef={singleSelectTextEditRef}
            menuWidth={
              singleSelectTextEditRef.current?.offsetWidth
                ? singleSelectTextEditRef.current?.offsetWidth - 1
                : undefined
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            type="text"
            label="Quantity"
            value={drugSelection.quantity}
            onChangeValue={(quantity) => numberValidator(quantity, "quantity")}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            type="text"
            label="Days supply"
            value={drugSelection.days}
            onChangeValue={(newDays) => numberValidator(newDays, "days")}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {drugIsNameBrand && (
          <Checkbox
            checked={drugSelection?.genericAllowed || false}
            onChange={(genericAllowed: boolean) => {
              setDrugSelection({ ...drugSelection, genericAllowed });
            }}
            label="Generic allowed"
          />
        )}
      </Grid>
    </>
  );
}
