import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { parseDateFromISOString } from "@coherehealth/common";
import { PatientStayDate } from "@coherehealth/core-platform-api";
import { PatientStayDateRange } from "common/SharedServiceRequestFormComponents";
import { aggregateStayDateByCommonFields } from "./PatientStays";
import { PatientStayValidationProps, usePatientStayValidation } from "./usePatientStayValidation";
import { PatientStayManger } from "components/ClinicalReview/Review/hooks/usePatientStayCardControl";

// we can add more error state in future
export interface PatientStaysErrorState {
  dateEmptyError?: boolean;
  datesOverlap?: boolean;
  nextToDatePriorToPrevBackDate?: boolean;
  decisionEmptyError?: boolean;
  approvedLocEmptyError?: boolean;
  datesGap?: boolean;
  fromDateError?: boolean;
  toDateError?: boolean;
  requestedLocEmptyError?: boolean;
  admissionDateErrorForInitial?: boolean;
  admissionDateErrorForContinuation?: boolean;
}

export interface UsePatientStayReturnValueProps {
  patientStayDateRanges: PatientStayDateRange[];
  setPatientStayDateRanges: Dispatch<SetStateAction<PatientStayDateRange[]>>;
  patientStaysErrorStates: PatientStaysErrorState[] | undefined;
  isStayDatesValid: () => boolean;
  isPatientStayDatesError: boolean;
  setAdmissionDate: Dispatch<SetStateAction<string | undefined>>;
  patientStayManager?: PatientStayManger;
}

interface CommonProps {
  serviceStartDate?: string;
  initialStayDateRanges?: PatientStayDateRange[];
}

type ConditionalProps =
  | {
      version: "RequestedStay";
      patientStayDates?: PatientStayDate[];
    }
  | {
      version: "DecisionStay";
      patientStayDates?: PatientStayDate[];
    };

type Props = CommonProps & ConditionalProps & Omit<PatientStayValidationProps, "patientStayDateRanges">;

const usePatientStayDateOnCRR = ({
  serviceStartDate,
  patientStayDates,
  initialStayDateRanges,
  ...validationProps
}: Props) => {
  const [patientStayDateRanges, setPatientStayDateRanges] = useState<PatientStayDateRange[]>(
    initialStayDateRanges ? initialStayDateRanges : []
  );
  const [admissionDate, setAdmissionDate] = useState<string | undefined>(validationProps.admissionDate);

  const { patientStaysErrorStates, isStayDatesValid, autoUpdateErrorState } = usePatientStayValidation({
    ...validationProps,
    admissionDate,
    patientStayDateRanges,
  });

  useEffect(() => {
    if (patientStayDates?.length && !initialStayDateRanges) {
      const patientStayDateRanges = aggregateStayDateByCommonFields(patientStayDates);
      const initialStayDateRanges = !!patientStayDateRanges.length
        ? patientStayDateRanges
        : [
            {
              rangeStartDate: parseDateFromISOString(serviceStartDate),
              rangeEndDate: null,
              requestedLevelOfCareCode: null,
              approvedLevelOfCareCode: null,
              reviewStatus: null,
            },
          ];
      setPatientStayDateRanges(initialStayDateRanges);
    } else if (initialStayDateRanges) {
      setPatientStayDateRanges(initialStayDateRanges);
    }
  }, [initialStayDateRanges, patientStayDates, serviceStartDate, setPatientStayDateRanges]);

  return {
    patientStayDateRanges,
    setPatientStayDateRanges,
    patientStaysErrorStates,
    isStayDatesValid,
    isPatientStayDatesError: autoUpdateErrorState,
    setAdmissionDate,
  };
};

export default usePatientStayDateOnCRR;
