import { useCallback, useEffect, useState } from "react";
import {
  useGetDocSegmentations,
  Segment,
  useUpdateDocSegmentation,
  DocSegmentation,
  Attachment,
} from "@coherehealth/core-platform-api";
import {
  getTheLatestSegments,
  AugmentedDocSegmentation,
  AugmentedSegment,
  extractUploadId,
  useFeature,
} from "@coherehealth/common";
import { useSnackbar } from "notistack";
import { v4 as uuid } from "uuid";

interface UseDocSegmentation {
  docSegmentationInfo: AugmentedDocSegmentation[] | null;
  handleSegmentChange: (
    updatedSegment: Segment,
    isDeletion: boolean,
    updatedPage: number,
    numberOfPages: number
  ) => void;
  savingFeedbackUpdates: boolean;
  segmentsUpdated: boolean;
}

const useDocSegmentation = (
  withDocSegmentedSidePanel: boolean,
  attachmentIndexOpen: number,
  attachments?: Attachment[]
): UseDocSegmentation => {
  const [docSegmentationInfo, setDocSegmentationInfo] = useState<AugmentedDocSegmentation[] | null>(null);
  const documentSegmentationFF = useFeature("documentSegmentation");
  const {
    refetch: getDocSegmentations,
    error: errorFetchingDocSegmentations,
    loading: fetchingDocSegmentations,
  } = useGetDocSegmentations({
    lazy: true,
    queryParamStringifyOptions: { indices: false },
  });
  const { enqueueSnackbar } = useSnackbar();
  const attachmentIds = attachments?.map((attachment) => extractUploadId(attachment.url));
  const openAttachmentId = attachmentIds?.[attachmentIndexOpen];
  useEffect(() => {
    if (!!documentSegmentationFF) {
      let componentIsMounted = true;
      const getDocSegData = async () => {
        if (withDocSegmentedSidePanel) {
          const attachmentIds = attachments?.map((attachment) => extractUploadId(attachment.url));
          const docSegmentations = await getDocSegmentations({
            queryParams: {
              attachmentIds: attachmentIds ? attachmentIds : [],
            },
          });
          const augmentedDocSegments: AugmentedDocSegmentation[] =
            attachmentIds?.map((attachmentId) => {
              const docSegmentation = docSegmentations?.find((doc) => doc.attachmentId === attachmentId);
              return docSegmentation
                ? { ...docSegmentation, latestSegments: addUids(getTheLatestSegments(docSegmentation)) }
                : { attachmentId: attachmentId, id: `noDocSeg_${attachmentId}` };
            }) || [];
          if (componentIsMounted) {
            setDocSegmentationInfo(augmentedDocSegments);
          }
        }
      };
      getDocSegData();
      return () => {
        componentIsMounted = false;
      };
    }
  }, [withDocSegmentedSidePanel, getDocSegmentations, attachments, documentSegmentationFF]);

  const {
    mutate: updateDocSegmentation,
    loading: savingFeedbackUpdates,
    error: errorUpdating,
  } = useUpdateDocSegmentation({
    attachmentId: openAttachmentId || "",
  });

  useEffect(() => {
    if (errorUpdating) {
      enqueueSnackbar("There was an error making changes to the document", {
        variant: "error",
      });
    }
    if (errorFetchingDocSegmentations) {
      enqueueSnackbar("Error fetching document segmentations", {
        variant: "error",
      });
    }
  }, [errorUpdating, errorFetchingDocSegmentations, enqueueSnackbar]);

  const handleSegmentChange = useCallback(
    async (updatedSegment: Segment, isDeletion: boolean, updatedPage: number, numberOfPages: number) => {
      if (!!documentSegmentationFF) {
        const currentDocSeg = docSegmentationInfo?.find((docSeg) => docSeg.attachmentId === openAttachmentId);
        const latestSegments = currentDocSeg?.latestSegments;

        const existingSegment = latestSegments?.find((segment) => segment.docPages?.[0] === updatedPage);

        const handleCreateSegment = () => {
          // find upper bound
          let closestStartPage = 1;
          let closestSegmentIndex = -1;
          latestSegments?.forEach((segment, index) => {
            if (segment.docPages) {
              const beginningOfSegment = segment.docPages[0];
              if (beginningOfSegment < updatedPage) {
                if (beginningOfSegment >= closestStartPage) {
                  closestStartPage = beginningOfSegment;
                  closestSegmentIndex = index;
                }
              }
            }
          });
          //find lower bound
          let closestEndPage = numberOfPages;
          latestSegments?.forEach((segment) => {
            if (segment.docPages) {
              const beginningOfSegment = segment.docPages[0];
              if (beginningOfSegment > updatedPage) {
                if (beginningOfSegment - 1 < closestEndPage) {
                  closestEndPage = beginningOfSegment - 1;
                }
              }
            }
          });
          const withUpdatedAbutting: Segment[] | undefined = latestSegments?.map((segment, index) => {
            if (index === closestSegmentIndex && segment.docPages) {
              return { ...segment, docPages: [closestStartPage, updatedPage - 1] };
            } else {
              return segment;
            }
          });
          const newSegment: AugmentedSegment = { docPages: [updatedPage, closestEndPage], uid: uuid() };
          const withNewSegment = [...(withUpdatedAbutting || []), newSegment];
          const updatedDocSegmentations = docSegmentationInfo?.map((docSeg) => {
            if (docSeg.attachmentId === openAttachmentId) {
              return { ...docSeg, latestSegments: withNewSegment };
            } else {
              return docSeg;
            }
          });
          updatedDocSegmentations && setDocSegmentationInfo(updatedDocSegmentations);
        };

        const handleDeleteSegment = async () => {
          let closestEndPage = 1;
          let closestEndSegmentIndex = -1;
          latestSegments?.forEach((segment, index) => {
            if (segment.docPages) {
              const endOfOfSegment = segment.docPages[1];
              if (endOfOfSegment < updatedPage) {
                if (endOfOfSegment >= closestEndPage) {
                  closestEndPage = endOfOfSegment;
                  closestEndSegmentIndex = index;
                }
              }
            }
          });
          let updatedAbuttingSegment =
            closestEndSegmentIndex !== -1 ? latestSegments?.[closestEndSegmentIndex] : undefined;
          if (updatedAbuttingSegment?.docPages && updatedSegment.docPages) {
            updatedAbuttingSegment = {
              ...updatedAbuttingSegment,
              docPages: [updatedAbuttingSegment.docPages[0], updatedSegment.docPages[1]],
            };
          }
          const withUpdatedAbutting = latestSegments?.map((segment, index) => {
            if (index === closestEndSegmentIndex && updatedAbuttingSegment) {
              return updatedAbuttingSegment;
            } else {
              return segment;
            }
          });
          const withoutDeletedSegment = withUpdatedAbutting?.filter((segment) => segment.docPages?.[0] !== updatedPage);
          const withoutEmptySegments = withoutDeletedSegment?.filter(
            (segment) => segment.docDate !== undefined || segment.docType !== undefined
          );
          const wasEmptySegment = updatedSegment.docDate === undefined && updatedSegment.docType === undefined;
          if (withoutEmptySegments) {
            let updatedDocSeg: DocSegmentation | void;
            if (!wasEmptySegment) {
              updatedDocSeg = await updateDocSegmentation({ segments: withoutEmptySegments }).catch(resetSegments);
            }

            const updatedDocSegmentations = docSegmentationInfo?.map((docSeg) => {
              if (docSeg.attachmentId === openAttachmentId) {
                if (wasEmptySegment) {
                  return { ...docSeg, latestSegments: withoutDeletedSegment };
                } else if (updatedDocSeg) {
                  const latestSegmentsWithUids = getLatestSegmentsWithUids(updatedDocSeg, withoutEmptySegments);
                  return { ...docSeg, latestSegments: latestSegmentsWithUids, segmentsUpdated: true };
                } else {
                  return docSeg;
                }
              } else {
                return docSeg;
              }
            });
            updatedDocSegmentations && setDocSegmentationInfo(updatedDocSegmentations);
          }
        };

        const handleUpdateSegment = async () => {
          const updatedSegments = latestSegments?.map((segment) => {
            if (segment.docPages?.[0] === updatedPage) {
              return { ...segment, docType: updatedSegment.docType, docDate: updatedSegment.docDate };
            } else {
              return segment;
            }
          });
          const withoutEmptySegments = updatedSegments?.filter(
            (segment) => segment.docDate !== undefined || segment.docType !== undefined
          );
          if (withoutEmptySegments) {
            const updatedDocSeg = await updateDocSegmentation({ segments: withoutEmptySegments }).catch(resetSegments);
            if (updatedDocSeg) {
              const updatedDocSegmentations = docSegmentationInfo?.map((docSeg) => {
                if (docSeg.attachmentId === openAttachmentId) {
                  const latestSegmentsWithUids = getLatestSegmentsWithUids(updatedDocSeg, withoutEmptySegments);
                  return { ...updatedDocSeg, latestSegments: latestSegmentsWithUids, segmentsUpdated: true };
                } else {
                  return docSeg;
                }
              });
              updatedDocSegmentations && setDocSegmentationInfo(updatedDocSegmentations);
            }
          }
        };

        const resetSegments = () => {
          const resetDocSegmentations = docSegmentationInfo?.map((docSeg) => {
            if (docSeg.attachmentId === openAttachmentId) {
              const latestSegments = getTheLatestSegments(docSeg);
              const withResetSegments = latestSegments.map((segment) => ({ ...segment }));
              return { ...docSeg, latestSegments: withResetSegments };
            } else {
              return docSeg;
            }
          });
          resetDocSegmentations && setDocSegmentationInfo(resetDocSegmentations);
        };

        if (!existingSegment) {
          handleCreateSegment();
        } else if (isDeletion) {
          handleDeleteSegment();
        } else {
          handleUpdateSegment();
        }
      }
    },
    [docSegmentationInfo, openAttachmentId, documentSegmentationFF, updateDocSegmentation]
  );

  const segmentsUpdated = docSegmentationInfo?.some((docSeg) => docSeg.segmentsUpdated) || false;
  const loadingDocSegments = fetchingDocSegmentations || savingFeedbackUpdates;

  return {
    docSegmentationInfo,
    handleSegmentChange,
    savingFeedbackUpdates: loadingDocSegments,
    segmentsUpdated,
  };
};

const addUids = (segments: Segment[]): AugmentedSegment[] => {
  return segments.map((segment) => ({ ...segment, uid: uuid() }));
};

const getLatestSegmentsWithUids = (
  docSegmentation: DocSegmentation,
  previousSegments: AugmentedSegment[]
): AugmentedSegment[] => {
  const latestSegments = getTheLatestSegments(docSegmentation);
  const latestSegmentsWithUids = latestSegments.map((segment, index) => {
    return { ...segment, uid: previousSegments[index]?.uid || uuid() };
  });
  return latestSegmentsWithUids;
};

export default useDocSegmentation;
