import React, { useState } from "react";

import { Body1, H6, PrimaryButton, TextField, useStableUniqueId } from "@coherehealth/common";
import Grid from "@material-ui/core/Grid";
import { useCreateOrganization, useCreateUser } from "@coherehealth/core-platform-api";
import { Modal, ModalProps } from "@coherehealth/common";
import csvParse from "csv-parse/lib/browser/sync";
import { generatePath, Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { assertIsApiError } from "util/api";
import routes from "routes";

interface Props extends ModalProps {
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onComplete?: Function;
}
export default function UploadListFromFile({ open, onClose, onComplete }: Props) {
  const [fileContents, setFileContents] = useState<Record<string, any>[]>();
  const { enqueueSnackbar } = useSnackbar();

  const [orgNameField, setOrgNameField] = useState("Organization_Name");
  const [cityField, setCityField] = useState("City");
  const [emailField, setEmailField] = useState("Email");
  const [userFirstNameField, setUserFirstNameField] = useState("First_Name");
  const [userLastNameField, setUserLastNameField] = useState("Last_Name");
  const [phoneField, setPhoneField] = useState("Phone_Number");
  const [stateField, setStateField] = useState("State");
  const [address1Field, setAddress1Field] = useState("Street_Address");
  const [tinField, setTinField] = useState("Taxpayer_Identification_Number");
  const [zipField, setZipField] = useState("Zip_Code");

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    if (input && input.files && input.files[0]) {
      const file = input.files[0];
      assertIsFile(file);

      try {
        const contents = await file.text();
        setFileContents(csvParse(contents, { columns: true }));
      } catch (e) {
        assertIsApiError(e);
        enqueueSnackbar(`Failed to parse contents: ${e.message}`, { variant: "error" });
      }
    }
  };

  const [creationErrors, setCreationErrors] = useState<
    { rowNumber: number; orgName: string; orgId?: string; errorMessage: string }[]
  >([]);
  const [creationResults, setCreationResults] = useState<
    { rowNumber: number; orgName: string; orgId: string; adminEmail: string }[]
  >([]);
  const { mutate: createOrg } = useCreateOrganization({});
  const { mutate: createUser } = useCreateUser({});
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const doTheUpload = async () => {
    if (!fileContents || fileContents.length === 0) {
      enqueueSnackbar("No file contents to upload!", { variant: "warning" });
      return;
    }
    setUploadInProgress(true);
    setCreationResults([]);
    setCreationErrors([]);
    for (const index in fileContents) {
      const record = fileContents[index];
      const result: { rowNumber: number; orgName: string; orgId?: string } = {
        rowNumber: Number(index) + 1,
        orgName: record[orgNameField],
      };
      try {
        const tinList = record[tinField] instanceof Array ? record[tinField] : record[tinField]?.split(",");
        const orgResult = await createOrg({
          name: record[orgNameField],
          tinList,
          address: {
            line1: record[address1Field],
            city: record[cityField],
            state: record[stateField],
            zipCode: record[zipField],
          },
        });
        result.orgId = orgResult.id;
        const userResult = await createUser({
          userType: "admin",
          firstName: record[userFirstNameField],
          lastName: record[userLastNameField],
          phone: record[phoneField],
          email: record[emailField],
          roles: ["backOfficeAdmin"],
          organizationName: orgResult.name,
        });
        setCreationResults((prev) => [
          ...prev,
          {
            ...result,
            orgId: orgResult.id,
            adminEmail: userResult.email || record[emailField],
          },
        ]);
      } catch (e) {
        assertIsApiError(e);
        setCreationErrors((prev) => {
          assertIsApiError(e);
          return [
            ...prev,
            {
              ...result,
              errorMessage: `Failed: ${e.message}`,
            },
          ];
        });
      }
    }

    setUploadInProgress(false);
    enqueueSnackbar("Finished!");
    onComplete?.();
  };

  const inputId = useStableUniqueId();
  return (
    <Modal open={open} onClose={onClose}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField label="Organization name field" value={orgNameField} onChangeValue={setOrgNameField} data-public />
          <TextField label="Tax ID field" value={tinField} onChangeValue={setTinField} />
          <TextField
            label="Street address field (line 1)"
            value={address1Field}
            onChangeValue={setAddress1Field}
            data-public
          />
          <TextField label="City field" value={cityField} onChangeValue={setCityField} data-public />
          <TextField label="State field" value={stateField} onChangeValue={setStateField} data-public />
          <TextField label="Zip field" value={zipField} onChangeValue={setZipField} data-public />
          <TextField label="User email field" value={emailField} onChangeValue={setEmailField} data-public />
          <TextField
            label="User first name field"
            value={userFirstNameField}
            onChangeValue={setUserFirstNameField}
            data-public
          />
          <TextField
            label="User last name field"
            value={userLastNameField}
            onChangeValue={setUserLastNameField}
            data-public
          />
          <TextField label="User phone number field" value={phoneField} onChangeValue={setPhoneField} data-public />
        </Grid>
        <Grid item xs={12}>
          <input accept=".csv" name={inputId} id={inputId} type="file" onChange={onFileChange} />
          <PrimaryButton disabled={!fileContents || uploadInProgress} onClick={doTheUpload} data-public>
            Start upload{fileContents ? ` (${fileContents.length} records)` : ""}
          </PrimaryButton>
        </Grid>
        <Grid item xs={12}>
          <H6 data-public>Errors</H6>
          <ul>
            {[...creationErrors].reverse().map(({ rowNumber, orgName, orgId, errorMessage }) => (
              <li key={rowNumber}>
                <Body1 data-public>
                  row {rowNumber}: <OrgLink orgId={orgId} orgName={orgName} />: {orgId ? "user failed" : "org failed"}:{" "}
                  {errorMessage}
                </Body1>
              </li>
            ))}
          </ul>
        </Grid>
        <Grid item xs={12}>
          <H6 data-public>Creation results</H6>
          <ul>
            {[...creationResults].reverse().map(({ rowNumber, orgName, orgId, adminEmail }) => (
              <li key={rowNumber}>
                <Body1 data-public>
                  row {rowNumber}: <OrgLink orgId={orgId} orgName={orgName} />
                </Body1>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
    </Modal>
  );
}

function OrgLink({ orgId, orgName }: { orgId?: string; orgName: string }) {
  if (orgId) {
    return (
      <Link target="__blank" to={generatePath(routes.ORGANIZATION_MANAGEMENT, { orgId })}>
        {orgId}: {orgName}
      </Link>
    );
  }
  return <>{orgName}</>;
}

function assertIsFile(ipt: File | string | null): asserts ipt is File {
  if (!ipt || typeof ipt === "string") {
    throw new Error("Not a file");
  }
}
