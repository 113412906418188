import Grid from "@material-ui/core/Grid";
import HeaderContainer from "components/AppHeader/HeaderContainer";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { Dispatch } from "react";
import routes from "routes";
import { H3, H6, InlineButton } from "@coherehealth/common";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { StepDisplay, WithdrawAndReplaceStep } from "./WithdrawAndReplacePageContents";
import { useNavigate } from "react-router-dom";
import { headerHeight } from "util/StyleConstants";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { redirectToPatientSummaryFromReview } from "util/routeUtils/routeUtils";

const BULLET = "\u2022";

interface Props {
  currentStep: WithdrawAndReplaceStep;
  setCurrentStep: Dispatch<WithdrawAndReplaceStep>;
  serviceRequest: ServiceRequestResponse;
  stepConfig: StepDisplay;
  refetchClinicalAssessment?: () => Promise<unknown>;
  refetchAttachments: () => Promise<unknown>;
}

/**
 * Used as a header on the /WithdrawandReplace  editing decisioned auths flow to display progress and other information
 */
export default function WithdrawAndReplaceHeader({
  currentStep,
  setCurrentStep,
  serviceRequest,
  stepConfig,
  refetchClinicalAssessment,
  refetchAttachments,
}: Props) {
  const navigate = useNavigate();

  const goToPatientSummary = () => {
    if (serviceRequest?.patient?.id) {
      redirectToPatientSummaryFromReview({ serviceRequest, navigate });
    } else {
      navigate(routes.DASHBOARD);
    }
  };

  const navigateBack = async () => {
    switch (currentStep) {
      case "EDIT_DETAILS":
      case "SKIP_TO_REVIEW":
        goToPatientSummary();
        break;
      case "CLINICAL_ASSESSMENT":
        refetchAttachments().then(() => setCurrentStep("ADD_ATTACHMENT"));
        break;
      case "ADD_ATTACHMENT":
        setCurrentStep("EDIT_DETAILS");
        break;
      case "REVIEW":
        if (refetchClinicalAssessment) {
          await refetchClinicalAssessment();
          setCurrentStep("CLINICAL_ASSESSMENT");
        }
        break;
    }
  };

  return (
    <HeaderContainer height={headerHeight}>
      <Grid container>
        <Grid item container xs={3} alignItems="center">
          <InlineButton onClick={navigateBack} startIcon={<NavigateBeforeIcon fontSize="large" />}>
            {stepConfig.backButtonLabel}
          </InlineButton>
        </Grid>
        <Grid container item direction="column" alignItems="center" xs={6} data-testid="journey-progress-header">
          <JourneyProgressTextWrapper>
            <StepHeading>Void and replace</StepHeading>
          </JourneyProgressTextWrapper>
          <JourneyProgressTextWrapper>
            <H3>
              Step {stepConfig.ordinal}
              <StepSpacer>{BULLET}</StepSpacer>
              {stepConfig.label}
              <StepSpacer />
            </H3>
          </JourneyProgressTextWrapper>
        </Grid>
      </Grid>
    </HeaderContainer>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const JourneyProgressTextWrapper = styled("div")({
  margin: 4,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StepHeading = styled(H6)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StepSpacer = styled("span")({
  padding: 12,
});
