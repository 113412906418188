import React from "react";
import { H2, Subtitle2 } from "@coherehealth/common";
import FullScreenDisplay, { FullScreenDisplayProps } from "./FullScreenDisplay";
import ServiceRequestWarning from "../../images/ServiceRequestWarning";
import HeaderAndProgressBar from "../HeaderAndProgressBar";

export default function NonAdminWarning() {
  const fullScreenDisplayProps: FullScreenDisplayProps = {
    headerAndProgressBar: <HeaderAndProgressBar showProgressBar={false} />,
    visualComponent: <ServiceRequestWarning />,
    header: <H2>Please contact an administrator to set up your organization with Cohere</H2>,
    subtext: (
      <Subtitle2>
        We’ll need to get an administrator set up before we can activate this organization and get you processing
        authorizations.
      </Subtitle2>
    ),
    widthOverrides: {
      headerWidth: 464,
      subtextWidth: 464,
    },
  };

  return <FullScreenDisplay {...fullScreenDisplayProps} />;
}
