import diagnosisCode from "./diagnosisCode/mirage.routes";
import procedureCode from "./procedureCode/mirage.routes";
import placeOfService from "./placeOfService/mirage.routes";
import facility from "./facility/mirage.routes";
import provider from "./provider/mirage.routes";
import palCategory from "./palCategory/mirage.routes";
import carePath from "./carePath/mirage.routes";
import patient from "./patient/mirage.routes";
import policyGuideline from "./policyGuideline/mirage.routes";
import serviceRequest from "./serviceRequest/mirage.routes";
import carePathJourney from "./carePathJourney/mirage.routes";
import licenseAgreement from "./licenseAgreement/mirage.routes";
import clinicalAssessment from "./clinicalAssessment/mirage.routes";
import simpleFeatureFlag from "./simpleFeatureFlag/mirage.routes";
import tag from "./tag/mirage.routes";
import userActivity from "./userActivity/mirage.routes";
import denialTemplates from "./denialTemplates/mirage.routes";
import user from "./user/mirage.routes";
const routes = [
  diagnosisCode,
  procedureCode,
  placeOfService,
  facility,
  provider,
  palCategory,
  carePath,
  patient,
  policyGuideline,
  serviceRequest,
  carePathJourney,
  licenseAgreement,
  clinicalAssessment,
  simpleFeatureFlag,
  tag,
  userActivity,
  denialTemplates,
  user,
];
export default routes;
