import { useEffect, useRef, useCallback, useLayoutEffect } from "react";
import { IAttachmentsMultiSearchHolder } from "./useAttachmentsMultiSearch";

interface Props {
  attachmentIndexOpen?: number;
  isCurrentAttachmentActive?: boolean;
  attachmentsMultiSearchHolder?: IAttachmentsMultiSearchHolder;
}

interface IUseAttachmentsSidePanelScrollResponse {
  sidePanelInnerDivRef: React.RefObject<HTMLDivElement>;
}

export const ATTACHMENT_SIDE_PANEL_ITEM_ID_PREFIX = "Attachment__Side__Panel__Item__";

export default function useAttachmentsSidePanelScroll({
  attachmentIndexOpen,
  isCurrentAttachmentActive,
  attachmentsMultiSearchHolder,
}: Props): IUseAttachmentsSidePanelScrollResponse {
  const sidePanelInnerDivRef = useRef<HTMLDivElement>(null);
  const hasAlreadyRegisteredScrollListener = useRef<boolean>(false);
  const hasAlreadySyncedScroll = useRef<boolean>(false);

  const registerCommonScrollOffset = useCallback(
    (e: Event) => {
      if (attachmentsMultiSearchHolder && sidePanelInnerDivRef.current) {
        attachmentsMultiSearchHolder.utilities.setSidePanelScrollOffset(sidePanelInnerDivRef.current.scrollTop);
      }
    },
    [attachmentsMultiSearchHolder]
  );

  // Listen and Register the scroll offset of the current active attachment
  useEffect(() => {
    // Ignore when not in multi-search
    if (isCurrentAttachmentActive === undefined) {
      return;
    }
    if (sidePanelInnerDivRef.current == null) {
      return;
    }
    if (isCurrentAttachmentActive === false) {
      sidePanelInnerDivRef.current.removeEventListener("scroll", registerCommonScrollOffset);
      hasAlreadyRegisteredScrollListener.current = false;
      hasAlreadySyncedScroll.current = false;
      return;
    }
    if (hasAlreadyRegisteredScrollListener.current) {
      return;
    }

    sidePanelInnerDivRef.current.addEventListener("scroll", registerCommonScrollOffset, { passive: true });
    hasAlreadyRegisteredScrollListener.current = true;
  }, [registerCommonScrollOffset, isCurrentAttachmentActive]);

  const scrollCurrentAttachmentItemIntoView = useCallback(() => {
    const sidePanelItemId = `${ATTACHMENT_SIDE_PANEL_ITEM_ID_PREFIX}${attachmentIndexOpen}`;
    const sidePanelItemElement = document.getElementById(sidePanelItemId);
    if (!sidePanelItemElement) {
      return;
    }
    sidePanelItemElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
  }, [attachmentIndexOpen]);

  // After attachment change, scroll to the common registered offset, then scroll the current item to view.
  // Any manual DOM manipulation (ele.scrollTo(...)) needs to be done synchronously to avoid the scroll to be visible on mount
  // Hence the useLayoutEffect
  useLayoutEffect(() => {
    // Ignore when not in multi-search
    if (!attachmentsMultiSearchHolder) {
      return;
    }
    if (hasAlreadySyncedScroll.current || !isCurrentAttachmentActive || !sidePanelInnerDivRef.current) {
      return;
    }

    const scrollOffset = attachmentsMultiSearchHolder.utilities.getSidePanelScrollOffset();
    sidePanelInnerDivRef.current.scrollTo({ top: scrollOffset });
    scrollCurrentAttachmentItemIntoView();
    hasAlreadySyncedScroll.current = true;
  }, [isCurrentAttachmentActive, attachmentsMultiSearchHolder, scrollCurrentAttachmentItemIntoView]);

  return {
    sidePanelInnerDivRef,
  };
}
