import React, { PropsWithChildren } from "react";
import { FeatureFlagsProvider } from "@coherehealth/common";
import config from "api/config";
import { Helmet, HelmetProvider } from "react-helmet-async";
import CohereLogo from "components/images/CohereLogo.svg";
import { SnackbarProvider } from "notistack";

import CheckStatusPageBody from "./CheckStatusPageBody";
import RegisterMessage from "./RegisterMessage";
import "./CheckStatusPage.css";

// N.B. side effect of importing this is to load the font css
import "@coherehealth/common/assets/checkStatusFont.css";

export const registerLink = "https://coherehealth.com/register/";
export const registerLinkDisplay = "www.coherehealth.com/register";
export const portalLink = "https://next.coherehealth.com/";
export const portalLinkDisplay = "next.coherehealth.com";

/**
 * Root component for the unauthorized /checkStatus page
 * This page is *not* secured, anyone can access it w/o an okta account
 * We also want this to look like the corporate site, so no common components or material-ui
 */
export default function CheckStatusPage() {
  return (
    <Providers>
      <Helmet>
        <title>Cohere Status Check</title>
      </Helmet>
      <div className="containerWithGutters">
        <div className="appBar">
          <div className="appBarContent containerWithGutters">
            <img className="logo" src={CohereLogo} alt="" />
            <a className="registerLinkButton" href={registerLink} rel="noreferrer" target="_blank">
              Register
            </a>
          </div>
        </div>
        <div className="bodyWrapper">
          <CheckStatusPageBody />
          <RegisterMessage />
        </div>
      </div>
    </Providers>
  );
}

// HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
// eslint-disable-next-line @typescript-eslint/ban-types
const Providers = ({ children }: PropsWithChildren<{}>) => (
  <FeatureFlagsProvider fetchSimpleFlags={() => fetch(`${config.SERVICE_API_URL}simpleFeatureFlag`)}>
    <SnackbarProvider>
      <HelmetProvider>{children}</HelmetProvider>
    </SnackbarProvider>
  </FeatureFlagsProvider>
);
