import React from "react";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import { DocumentInfo, SupportedFileType } from "./DocumentViewer";
import FileMetaDataDisplay from "./FileMetaDataDisplay";
import FileActions from "./FileActions";
import { FaxSidebarView } from "./FaxAttachment/common";

interface Props {
  /**
   * Called when the close icon is clicked
   */
  onClose?: () => void;
  /**
   * Meta data about the file attachment to show
   */
  fileData: Partial<DocumentInfo>;
  /**
   * If this file can be deleted
   */
  canDelete: boolean;
  /**
   * Called when the delete file button is clicked
   */
  onDelete?: () => void;
  /**
   * The width of the sidebar
   */
  width: number;
  /**
   * The path to the document resource
   */
  url?: string;
  /**
   * Mocks the document to show (for storybook)
   */
  mockDoc?: string;
  /**
   * The type of the file
   */
  fileType?: SupportedFileType;
  /**
   * Boolean that trigger style changes if true
   */
  isOutgoingFax?: boolean;
  /**
   * Sets the fax sidebar view
   */
  setFaxSidebarView?: (view: FaxSidebarView) => void;

  convertedToPDF?: boolean;
}

const useStyles = makeStyles((theme) => ({
  drawer: {
    "& .MuiDrawer-paperAnchorDockedRight": {
      borderBottomRightRadius: 4,
    },
  },
  button: {
    float: "right",
    marginTop: theme.spacing(2),
  },
  container: {
    width: ({ width }: { width: number }) => width,
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
  box: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}));

export default function SideBar({
  onClose,
  fileData,
  canDelete,
  onDelete,
  url,
  width,
  fileType,
  isOutgoingFax,
  setFaxSidebarView,
  convertedToPDF,
}: Props) {
  const classes = useStyles({ width });

  return (
    <Drawer variant="permanent" anchor="right" className={isOutgoingFax ? classes.drawer : undefined}>
      <Container className={classes.container}>
        {onClose ? (
          <IconButton className={classes.button} edge="end" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        ) : (
          <Box className={classes.box} />
        )}
        <FileMetaDataDisplay {...fileData} />
        <Divider className={classes.divider} />
        <FileActions
          canDelete={canDelete}
          onDelete={onDelete}
          url={url}
          fileName={fileData.name}
          fileType={fileType}
          hidePrintButton={isOutgoingFax}
          downloadButtonOnBottom={isOutgoingFax}
          setFaxSidebarView={setFaxSidebarView}
          closeFaxModal={onClose}
          convertedToPDF={convertedToPDF}
        />
      </Container>
    </Drawer>
  );
}
