import React, { Dispatch, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { generatePath } from "react-router";
import routes from "../../../routes";
import {
  ExternalIntegration,
  IntegrationMarkIndicator,
  IntegrationStatus,
  useRetryKafkaIntegration,
  KafkaMessageDocumentResponse,
} from "@coherehealth/core-platform-api";
import CohereTable from "../../ProviderOrganization/CohereTable";
import { Checkbox, formatDateStrWithMonthName, PrimaryButton } from "@coherehealth/common";
import { Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import listRemove from "../../../util/listRemove";

export declare type ServiceRequestESObject = {
  id: string;
  integrationStatus?: IntegrationStatus;
  authNumber?: string;
  carePathName?: string;
  integration?: {
    salesforce?: ExternalIntegration;
    matrix?: ExternalIntegration;
    carewebqi?: ExternalIntegration;
    fax_final_determination?: ExternalIntegration;
    message?: string;
  };
  patientId?: string;
  cohereId?: string;
  dateCreated?: string;
  lastUpdated?: string;
};

interface IProps {
  serviceRequests: Array<ServiceRequestESObject>;
  kafkaMessages: Array<KafkaMessageDocumentResponse>;
  integrationType: string;
  rerunList: Array<IntegrationMarkIndicator>;
  setRerunList?: Dispatch<IntegrationMarkIndicator[]>;
  integrationInfrastructure: string;
}

export default function IntegrationList({
  serviceRequests,
  kafkaMessages,
  integrationType,
  rerunList,
  setRerunList,
  integrationInfrastructure,
}: IProps) {
  const { error: retryKafkaIntegrationError, mutate: retryKafkaIntegration } = useRetryKafkaIntegration({});

  const displayRetryCount = (log: ServiceRequestESObject) => {
    if (integrationType === "salesforce") {
      return log.integration?.salesforce?.retryCount?.toString();
    } else if (integrationType === "matrix") {
      return log.integration?.matrix?.retryCount?.toString();
    }
  };

  const displayDate = (log: ServiceRequestESObject) => {
    if (integrationType === "salesforce") {
      return formatDateStrWithMonthName(log.integration?.salesforce?.lastUpdatedAt);
    } else if (integrationType === "matrix") {
      return formatDateStrWithMonthName(log.integration?.matrix?.lastUpdatedAt);
    } else if (integrationType === "payor") {
      return formatDateStrWithMonthName(log.lastUpdated);
    }
  };

  const displayIntegrationStatus = (log: ServiceRequestESObject) => {
    if (integrationType === "salesforce") {
      return log.integration?.salesforce?.status;
    } else if (integrationType === "matrix") {
      return log.integration?.matrix?.status;
    } else if (integrationType === "payor") {
      return log.integrationStatus;
    }
  };

  const tableColumns = [
    {
      name: "Patient ID",
      width: "20%",
      value: (log: ServiceRequestESObject) => log.patientId || "",
    },
    {
      name: "cohere ID",
      width: "8%",
      value: (log: ServiceRequestESObject) => log.cohereId || "",
    },
    {
      name: "Auth ID",
      width: "8%",
      value: (log: ServiceRequestESObject) => log.authNumber || "",
    },
    {
      name: "Integration Status",
      width: "10%",
      value: (log: ServiceRequestESObject) => displayIntegrationStatus(log) || "",
    },
    {
      name: "Error Message",
      width: "25%",
      value: (log: ServiceRequestESObject) => log.integration?.message || "",
    },
    {
      name: "Date Created",
      width: "8%",
      value: (log: ServiceRequestESObject) => formatDateStrWithMonthName(log.dateCreated) || "",
    },
    {
      name: "Last Updated",
      width: "8%",
      value: (log: ServiceRequestESObject) => displayDate(log) || "",
    },
    {
      name: "Rerun?",
      width: "6.5%",
      value: (log: ServiceRequestESObject) => (
        <Checkbox
          checked={rerunList?.some((e) => e.serviceRequestId === log.id)}
          onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
          onChange={(newChecked) => {
            if (setRerunList) {
              if (newChecked) {
                setRerunList([
                  ...rerunList,
                  {
                    serviceRequestId: log.id,
                    integrationType: integrationType,
                    integrationStatus: "READY",
                  },
                ]);
              } else {
                setRerunList(
                  listRemove<IntegrationMarkIndicator>(
                    rerunList,
                    rerunList.map((e) => e.serviceRequestId).indexOf(log.id)
                  )
                );
              }
            }
          }}
        />
      ),
    },
    {
      name: "# of Runs",
      width: "6.5%",
      value: (log: ServiceRequestESObject) => displayRetryCount(log) || "",
    },
  ];

  const kafkaTableColumns = [
    {
      name: "Document ID",
      width: "16%",
      value: (log: KafkaMessageDocumentResponse) => log.id || "",
    },
    {
      name: "Service Request ID",
      width: "15%",
      value: (log: KafkaMessageDocumentResponse) => log.serviceRequest?.id || "",
    },
    {
      name: "Integration Status",
      width: "8%",
      value: (log: KafkaMessageDocumentResponse) => log.status || "",
    },
    {
      name: "Error Type",
      width: "14%",
      value: (log: KafkaMessageDocumentResponse) => log.errorType || "",
    },
    {
      name: "Error Message",
      width: "16%",
      value: (log: KafkaMessageDocumentResponse) => log.errorDetails || "",
    },
    {
      name: "Date Created",
      width: "8%",
      value: (log: KafkaMessageDocumentResponse) => formatDateStrWithMonthName(log.messageSentTimestamp) || "",
    },
    {
      name: "Date Processed",
      width: "8%",
      value: (log: KafkaMessageDocumentResponse) => formatDateStrWithMonthName(log.messageSentTimestamp) || "",
    },
    {
      name: "Retry?",
      width: "9%",
      value: (log: KafkaMessageDocumentResponse) => (
        <Grid item xs={1}>
          {log.status === "FAILURE" ? (
            <PrimaryButton
              style={{ width: 40 }}
              onClick={(e) => {
                e.stopPropagation();
                retryKafkaIntegration({ retryType: "singleMessage", index: log.index, documentId: log.id }).then(() =>
                  enqueueSnackbar("Retry of single message initiated, process will take a few seconds to complete", {
                    variant: "success",
                    preventDuplicate: true,
                  })
                );
              }}
            >
              Retry
            </PrimaryButton>
          ) : (
            <PrimaryButton style={{ width: 40 }} color="secondary" disabled={true}>
              Retry
            </PrimaryButton>
          )}
        </Grid>
      ),
    },
    {
      name: "# of Retries",
      width: "5%",
      value: (log: KafkaMessageDocumentResponse) => log.originalMessageRetryCount || "",
    },
  ];

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (retryKafkaIntegrationError) {
      enqueueSnackbar("Failed to retry integration of single message, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [retryKafkaIntegrationError, enqueueSnackbar]);

  return (
    <>
      {integrationInfrastructure === "Core-Platform" ? (
        <CohereTable
          tableColumns={tableColumns}
          data={serviceRequests}
          onRowClick={(serviceRequest) => {
            navigate(generatePath(routes.SERVICE_REQUEST, { serviceRequestId: serviceRequest.id || "" }));
          }}
        />
      ) : (
        <CohereTable
          tableColumns={kafkaTableColumns}
          data={kafkaMessages}
          onRowClick={(kafkaMessage) => {
            navigate(generatePath(routes.SERVICE_REQUEST, { serviceRequestId: kafkaMessage.serviceRequest?.id || "" }));
          }}
        />
      )}
    </>
  );
}
