export const stableSort = (a: any, b: any, ascending: boolean) => {
  if (a < b) {
    return ascending ? -1 : 1;
  } else if (a > b) {
    return ascending ? 1 : -1;
  }
  return 0;
};

export const stringSort = (a: string, b: string, ascending: boolean) => {
  const aUp = a.toUpperCase();
  const bUp = b.toUpperCase();
  return stableSort(aUp, bUp, ascending);
};

export const booleanSort = (a: boolean, b: boolean, ascending: boolean) => {
  return stableSort(a, b, ascending);
};

export const dateStringSort = (a: string, b: string, ascending: boolean) => {
  const an = Date.parse(a);
  const bn = Date.parse(b);
  if (Number.isNaN(an) || Number.isNaN(bn)) {
    return stringSort(a, b, ascending);
  } else {
    return stableSort(an, bn, ascending);
  }
};

export function reorder<T>(ray: T[], sourceIndex: number, destinationIndex: number): T[] {
  const reorderedArray = Array.from(ray);
  const target = reorderedArray.splice(sourceIndex, 1)[0];
  reorderedArray.splice(destinationIndex, 0, target);
  return reorderedArray;
}
