import { InlineButton, Tooltip, useFeature } from "@coherehealth/common";
import UndoIcon from "@mui/icons-material/Undo";
import { useTheme } from "@mui/material";
import { Body2 } from "@coherehealth/design-system";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { useGetPostDecisionConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import { canBeWithdrawn, isFinallyDeterminedUtility } from "util/serviceRequest";
import React from "react";
import { useAuthorized } from "authorization";
import { useCustomWidth } from "util/tooltipUtil";
import { styled } from "@mui/material/styles";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

type paddingValue = string | number;
type Props = {
  serviceRequest: ServiceRequestResponse;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  buttonText?: string;
  styles?: { paddingLeft?: paddingValue; padding?: paddingValue };
  srNoLongerNeededButton?: boolean;
};

const WithdrawButton = ({ serviceRequest, onClick, buttonText, styles, srNoLongerNeededButton }: Props) => {
  const theme = useTheme();

  // Custom width for the withdraw button tooltip due to longer text, just for styling purpose
  const customWidthTooltip = useCustomWidth();

  // Only with external user roles: Backofficeadmin, Backoffice that the
  // withdraw button will be disabled under some conditions
  const canEditWithdrawalRequestor = useAuthorized("EDIT_WITHDRAWAL_REQUESTOR");

  const postDecisionConfigEnabled = useFeature("usePostDecisionConfiguration");
  const { data: postDecisionConfig } = useGetPostDecisionConfigurationByPayer(serviceRequest?.healthPlanName || "");

  // Conditions that have to be met in order for the withdraw button
  // to be disabled, only when the FF usePostDecisionConfiguration is ON
  const disableWithdrawOption = postDecisionConfigEnabled
    ? postDecisionConfig?.disableWithdrawOption &&
      isFinallyDeterminedUtility(serviceRequest.authStatus) &&
      !canEditWithdrawalRequestor
    : false;

  const withdrawTooltipText = disableWithdrawOption ? (
    <div>
      You can’t withdraw or void a request that has been decisioned
      <br />
      Contact {serviceRequest.healthPlanName} to withdraw a decisioned authorization
    </div>
  ) : (
    ""
  );

  const isDisabled = !canBeWithdrawn(serviceRequest) || serviceRequest?.isRestricted || disableWithdrawOption;

  return !srNoLongerNeededButton ? (
    <Tooltip
      classes={{ tooltip: customWidthTooltip.customWidth }}
      title={withdrawTooltipText}
      placement="top-end"
      arrow
    >
      <span>
        <InlineButton
          startIcon={<UndoIcon />}
          disabled={isDisabled}
          style={{
            ...styles,
            color: isDisabled ? "#C2C2C2" : theme.palette.error.main,
          }}
          onClick={onClick}
        >
          <Body2>{buttonText || "Withdraw Request"}</Body2>
        </InlineButton>
      </span>
    </Tooltip>
  ) : (
    <span>
      <InlineButton
        startIcon={<NotAuthorizedIcon />}
        style={{
          ...styles,
          color: theme.palette.error.main,
        }}
        onClick={onClick}
      >
        <Body2>{buttonText}</Body2>
      </InlineButton>
    </span>
  );
};

export default WithdrawButton;

const NotAuthorizedIcon = styled(DoNotDisturbIcon)(({ theme }) => ({
  fontSize: theme.spacing(2),
  color: theme.palette.error.main,
}));
