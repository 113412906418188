import { makeStyles } from "@material-ui/core";

export const useAuthorizationTransferModalStyles = makeStyles((theme) => ({
  warningIcon: {
    color: theme.palette.warning.dark,
    transform: "scale(0.6)",
  },
  sucessIcon: {
    transform: "scale(0.6)",
    color: theme.palette.success.main,
  },
  modal: {
    "& .MuiButton-root": {
      minWidth: 195,
    },
    "& div:first-child": {
      paddingBottom: theme.spacing(0),
      borderRadius: 16,
    },
  },
}));
