import { makeStyles } from "@material-ui/core";

interface StyleProps {
  forceOneLine?: boolean;
}

export const useDateRowStyles = makeStyles((theme) => ({
  gridContainer: {
    flexWrap: (props: StyleProps) => (props.forceOneLine ? "nowrap" : "wrap"),
  },
}));
