import { debounce } from "@material-ui/core/utils";
import { useCallback, useEffect, useMemo, useState } from "react";

function useResponsiveMdWidth(divElementRef: React.MutableRefObject<HTMLDivElement | null>, maxWidth: number) {
  const [responsiveMdWidth, setResponsiveMdWidth] = useState<boolean>(false);

  const compareSize = useCallback(() => {
    const element = divElementRef.current;
    const compare = element ? element.offsetWidth > maxWidth : false;
    setResponsiveMdWidth(compare);
  }, [divElementRef, maxWidth]);

  const debouncedCompareSize = useMemo(() => debounce(compareSize, 300), [compareSize]);

  useEffect(() => {
    window.addEventListener("resize", debouncedCompareSize);
    compareSize();
  }, [compareSize, debouncedCompareSize]);

  // remove resize listener again on "componentWillUnmount"
  useEffect(
    () => () => {
      window.removeEventListener("resize", debouncedCompareSize);
    },
    [debouncedCompareSize]
  );

  return {
    responsiveMdWidth,
  };
}

export default useResponsiveMdWidth;
