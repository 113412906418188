import React, { useEffect } from "react";
import HeaderAndProgressBar from "../HeaderAndProgressBar";
import { H1, H6 } from "@coherehealth/common";
import HourglassIcon from "../../images/HourglassIcon";
import FullScreenDisplay, { FullScreenDisplayProps } from "./FullScreenDisplay";
import { joinExistingOrganizationSurvey } from "util/alchemerURLs";

export default function AdminAssistance() {
  const link = joinExistingOrganizationSurvey;

  useEffect(() => {
    window.open(link);
  }, [link]);

  const fullScreenDisplayProps: FullScreenDisplayProps = {
    headerAndProgressBar: <HeaderAndProgressBar stepNumber={3} />,
    visualComponent: <HourglassIcon />,
    header: <H1>Admin assistance needed to grant access</H1>,
    subtext: (
      <H6>
        To ensure the security of the organization account, we ask that you contact your admin and ask that they grant
        you access. <br /> <br /> If you do not know who your admin is, Cohere will be reaching out to them with the
        information you provided in 1-2 business days.
      </H6>
    ),
    widthOverrides: {
      headerWidth: 950,
      subtextWidth: 653,
    },
  };

  return <FullScreenDisplay {...fullScreenDisplayProps} />;
}
