import { AuthorizationResponse, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { UserType } from "./userType";
/**
 * Roles granted access to each feature type
 */
function isUserType(g: string): g is UserType {
  return Object.values(UserType).includes(g as UserType);
}

export const calculateAllGrantsForRoles = (grants: Record<string, readonly string[]>) => {
  function recursiveGetGrants(key: string): readonly string[] {
    const directGrants: readonly string[] = grants[key] || [];
    if (directGrants.length > 0) {
      const indirectGrants = directGrants.map(recursiveGetGrants).flat();
      return [...directGrants, ...indirectGrants];
    } else {
      return directGrants;
    }
  }

  const roleToAllGrants: Record<string, Record<string, 1>> = Object.keys(grants).reduce((acc, key) => {
    if (isUserType(key)) {
      const assignedGrants = recursiveGetGrants(key);
      const allGrants = [key, ...assignedGrants];
      return {
        ...acc,
        // values are Object literals for fast lookup; roleToAllGrants[UserType.Admin]["EDIT_PATIENT_COVERAGE"] === 1
        [key]: objectify(allGrants),
      };
    } else {
      return acc;
    }
  }, {});

  return roleToAllGrants;
};

export function generateAuthorizer<T extends string>(
  grants: Record<string, readonly string[]>
): (feature: T, userRoles: string[] | undefined) => boolean {
  const roleToFeatureMap = calculateAllGrantsForRoles(grants);
  return (feature: T, userRoles: string[] | undefined) => {
    if (userRoles === undefined || userRoles.length === 0) {
      return false;
    }
    return userRoles.some((r) => roleToFeatureMap[r]?.[feature]);
  };
}

// Little utility to convert a list of strings to an object where keys are those strings...
// handy for faster lookup
function objectify(arr: readonly string[]): Record<string, 1> {
  return arr.reduce((acc, val) => ({ ...acc, [val]: 1 }), {});
}

// This validation avoids unwanted behavior when there is no authorizationId. Used to fix bug
// caused by the authorization API returning a list of unrelated authorizations when no auth id
// is sent as part of the API call
export function evalValidAuthorization(
  serviceRequest: ServiceRequestResponse,
  authorization: AuthorizationResponse | null
) {
  if (serviceRequest?.authorization?.id && !!authorization) {
    return authorization;
  } else {
    return undefined;
  }
}
