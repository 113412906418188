import { ReactNode } from "react";
import { Box, BoxProps } from "@mui/material";
import { H2, H6 } from "../Typography";

interface Props extends BoxProps {
  title: string;
  subtitle?: string;
  icon?: ReactNode;
  color?: "primary" | "secondary" | "warning" | "error" | "info" | "success";
}

export default function Banner({ title, subtitle, icon, color, ...boxProps }: Props) {
  return (
    <Box px={5} py={3} borderRadius={2} bgcolor={`${color}.light`} display="flex" {...boxProps}>
      {icon && (
        <Box mr={5} lineHeight={0}>
          {icon}
        </Box>
      )}
      <Box justifyContent="center" display="flex" flexDirection="column">
        <H2 data-public>{title}</H2>
        {subtitle && (
          <H6 mt={1} data-public>
            {subtitle}
          </H6>
        )}
      </Box>
    </Box>
  );
}
