import React from "react";
import { SecondaryButton } from "@coherehealth/common";
import { useGetDiagnosisCodes } from "@coherehealth/core-platform-api";

export default function HomePage() {
  // temporary to test service/mirage works
  const { data, error, loading, refetch } = useGetDiagnosisCodes({ lazy: true });

  return (
    <div>
      <h1>Home page</h1>
      <SecondaryButton onClick={() => refetch()}>Test Button</SecondaryButton>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {data && (
        <ul>
          {data.map((code) => (
            <li key={`${code.code}:${code.description}`}>
              {code.code} {code.description}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
