import React, { Dispatch, SetStateAction } from "react";
import clsx from "clsx";
import { Box, Grid, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { colorsLight } from "@coherehealth/common";
import { H4 } from "@coherehealth/common";
import { LoadError } from "@react-pdf-viewer/core";
import { ToggleListButton, ToolbarItemWrapper, ToolbarWrapper, useToolbarStyles } from "./ToolbarControl";
import { NotificationViewerConfig } from "../util/viewerUtil";
import { NotificationViewerItem } from "../util/notificationUtil";

interface Props {
  error: LoadError;
  notifications?: NotificationViewerItem[];
  setNotifications?: React.Dispatch<React.SetStateAction<NotificationViewerItem[]>>;
  handleNotificationClick?: (index: number) => void;
  notificationIndexOpen?: number;
  openSidePanel?: boolean;
  setOpenSidePanel?: Dispatch<SetStateAction<boolean>>;
  viewerConfig?: NotificationViewerConfig;
}

const PdfViewerRenderError = ({
  error,
  notifications,
  setNotifications,
  handleNotificationClick,
  notificationIndexOpen,
  openSidePanel,
  setOpenSidePanel,
  viewerConfig,
}: Props) => {
  const getErrorMessage = (error: LoadError) => {
    switch (error.name) {
      case "InvalidPDFException":
        return "The selected notification is invalid or corrupted";
      case "MissingPDFException":
        return "The selected notification is missing";
      case "UnexpectedResponseException":
        return "Unexpected server response";
      case "NoFileException":
        return "No file to display";
      default:
        return "The selected notification cannot be opened";
    }
  };

  const useRenderErrorStyles = makeStyles((theme) => ({
    imageViewerRoot: {
      borderRight: `1px solid ${colorsLight.gray.divider}`,
    },
    sidePanelContainer: {
      width: !openSidePanel ? "0px" : viewerConfig ? "400px" : "264px",
      height: viewerConfig ? "calc(100vh - 40px)" : "calc(100vh - 180px)",
      backgroundColor: "white",
    },
    errorContainer: {
      height: viewerConfig ? "calc(100vh - 40px)" : "calc(100vh - 180px)",
      textAlign: "center",
      paddingTop: "calc(50vh - 90px)",
      width: !openSidePanel ? "100%" : viewerConfig ? "calc(100% - 400px)" : "calc(100% - 264px)",
    },
  }));

  const renderErrorClasses = useRenderErrorStyles();
  const toolbarClasses = useToolbarStyles({});

  const SidePanelComponent = viewerConfig?.sidePanelComponent;

  return (
    <>
      <Grid container direction="column" className={renderErrorClasses.imageViewerRoot}>
        <div style={{ height: "2.5rem" }}>
          <ToolbarWrapper crrStyling={true} style={{ borderBottom: "1px solid #E5E5E5" }}>
            <Box className={clsx(toolbarClasses.toolbarControls, toolbarClasses.toolbarLeftControls)}>
              <ToolbarItemWrapper>
                <ToggleListButton openSidePanel={openSidePanel} setOpenSidePanel={setOpenSidePanel} />
              </ToolbarItemWrapper>
            </Box>
            {viewerConfig?.closeDialogFunction && (
              <Box style={{ marginRight: "4px", marginLeft: "auto" }}>
                <IconButton
                  onClick={viewerConfig.closeDialogFunction}
                  disableFocusRipple={true}
                  size="small"
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
          </ToolbarWrapper>
        </div>
        <Box display={"flex"} width="100%">
          <Box flexDirection={"column"} className={renderErrorClasses.sidePanelContainer}>
            {notifications && handleNotificationClick && SidePanelComponent && (
              <SidePanelComponent
                notifications={notifications}
                setNotifications={setNotifications}
                handleNotificationClick={handleNotificationClick}
                notificationIndexOpen={notificationIndexOpen}
                openSidePanel={openSidePanel}
              />
            )}
          </Box>
          <Box flexDirection={"column"} className={renderErrorClasses.errorContainer}>
            <H4 style={{ color: colorsLight.font.light }}>{getErrorMessage(error)}</H4>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default PdfViewerRenderError;
