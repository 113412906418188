import React from "react";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

import "./RegisterMessage.css";
import { portalLink, portalLinkDisplay, registerLink, registerLinkDisplay } from "./index";

export default function RegisterMessage() {
  return (
    <div className="registerMessageContainer">
      <div className="registerMessageHeader">
        <AssignmentTurnedInIcon className="registerMessageIcon" />
        Register for the portal
      </div>
      <p className="registerMessageBody">
        Did you know that the fastest way to submit and check the status of pre-auths is via the CohereNext Portal?
        Visit{" "}
        <a className="registerMessageLink" href={portalLink} rel="noreferrer" target="_blank">
          {portalLinkDisplay}
        </a>{" "}
        to sign in.
      </p>
      <p className="registerMessageBody">
        If you do not have an account with Cohere, you can register for one at{" "}
        <a className="registerMessageLink" href={registerLink} rel="noreferrer" target="_blank">
          {registerLinkDisplay}
        </a>
      </p>
    </div>
  );
}
