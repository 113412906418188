import React, { Dispatch, SetStateAction, useEffect, useState, useContext } from "react";
import { ReferralRequestSearchRequestBody, useGetHealthPlan } from "@coherehealth/core-platform-api";
import { useTheme } from "@material-ui/core";
import { UserContext } from "UserContext";

import { useGetUserById } from "@coherehealth/core-platform-api";
import { appHeaderHeight, headerHeight, leftRailWidth, ScrollSideRail } from "@coherehealth/common";
import { RadioOptionsWithExpandedButton } from "common/RadioOptionsWithExpandedButton";
import { ssoToHealthPlanDisplayNameMap } from "common/CoBrandedLogo";

interface Props {
  queryParams: ReferralRequestSearchRequestBody;
  setQueryParams: Dispatch<SetStateAction<ReferralRequestSearchRequestBody>>;
  selectedHealthPlan?: string;
  setSelectedHealthPlan?: React.Dispatch<React.SetStateAction<string>>;
  coBrandingPayerLogosFF?: boolean;
}
interface ActiveHealthPlan {
  id: string;
  label: string;
  displayNames?: string[];
}

interface HealthPlanOption {
  id: string;
  label: string;
  parentHealthPlanName?: string;
}

export default function ReferralRequestFilter({
  queryParams,
  setQueryParams,
  selectedHealthPlan,
  setSelectedHealthPlan,
  coBrandingPayerLogosFF,
}: Props) {
  const { data: healthPlans } = useGetHealthPlan({});
  const theme = useTheme();
  let showAllOptions = false;
  const [ssoValue, setSSOValue] = useState("");
  //this is created first and represents a collection of healthPlans with a status of ACTIVE. These are also conditionally filtered down based on the user's organization.healthPlans list
  const [activeHealthPlans, setActiveHealthPlans] = useState<ActiveHealthPlan[]>();
  //these options are created from the activeHealthPlans and represent what will be used as the data source for the UI filters
  const [healthPlanFilterOptions, setHealthPlanFilterOptions] = useState<HealthPlanOption[]>();
  //these options are created from the healthPlanFilterOptions and represent the search query strings that will be used by the dashboard search
  const [healthPlanSearchOptions, setHealthPlanSearchOptions] =
    useState<Record<string, { healthPlanName: string; healthPlanDisplayName?: string | undefined }>>();
  const { getUser } = useContext(UserContext);
  const { refetch: fetchUserDetails } = useGetUserById({
    id: "",
    lazy: true,
  });

  useEffect(() => {
    const location = window.location;
    const urlParams = new URLSearchParams(location.search);
    const ssoValue = urlParams.get("ssoCode");
    setSSOValue(ssoValue || "");
  }, []);

  useEffect(() => {
    if (coBrandingPayerLogosFF) {
      if (healthPlans) {
        //construct currentActiveHealthPlans as base
        let currentActiveHealthPlans: ActiveHealthPlan[] = [
          { id: "allHealthPlan", label: "All", displayNames: [] }, // includes all health plans
          ...healthPlans
            .filter((plan) => plan.status?.toLowerCase() === "active")
            .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
            .map((plan) => ({
              id: plan.name || "",
              label: plan.name || "",
              displayNames: plan.displayNames,
            })),
        ];
        getUser()
          ?.then((currentUser) => {
            if (
              currentUser?.sub &&
              currentUser.groups &&
              currentUser.groups.filter((x) => x !== "Everyone").length > 0
            ) {
              // Only bother fetching user details if the user belongs to groups; otherwise we won't find one anyway
              fetchUserDetails({ pathParams: { id: currentUser.sub } })
                .then((userWithOrg) => {
                  if (userWithOrg) {
                    if (userWithOrg.organizationHealthPlans) {
                      //filter the currentActiveHealthPlans by the health plans associated with the user's organization so the user only sees relevant filter options
                      //also check if any of the items within the user's org's health plans match any of the display names in a given health plan
                      currentActiveHealthPlans = currentActiveHealthPlans.filter(
                        (plan) =>
                          userWithOrg?.organizationHealthPlans &&
                          (userWithOrg?.organizationHealthPlans.includes(plan?.label || "") ||
                            plan?.displayNames?.some((displayName) =>
                              userWithOrg?.organizationHealthPlans?.includes(displayName)
                            ))
                      );
                      //remove displayNames which are not included in the users org
                      currentActiveHealthPlans.forEach((healthPlan) => {
                        healthPlan.displayNames = healthPlan.displayNames?.filter((displayName) =>
                          userWithOrg?.organizationHealthPlans?.includes(displayName)
                        );
                      });
                    }
                  }
                  setActiveHealthPlans(currentActiveHealthPlans);
                })
                .catch((error) => {
                  console.warn("useGetUserById() error: ", error);
                });
            } else {
              setActiveHealthPlans(currentActiveHealthPlans);
            }
          })
          .catch((error) => {
            console.warn("getUser() error: ", error);
          });
      }
    }
  }, [healthPlans, coBrandingPayerLogosFF, fetchUserDetails, getUser]);

  useEffect(() => {
    if (activeHealthPlans && !healthPlanFilterOptions) {
      // deep copy activeHealthPlans to avoid mutation
      const healthPlansCopy: ActiveHealthPlan[] = JSON.parse(JSON.stringify(activeHealthPlans));
      //create option for each healthPlan.displayName entry
      const currentHealthPlanFilterOptions = healthPlansCopy.reduce(
        (acc: HealthPlanOption[], curr: ActiveHealthPlan) => {
          if (curr.displayNames && curr.displayNames.length > 0) {
            curr.displayNames.forEach((displayName) => {
              //skip entry for BCBS South Carolina since Payer does not want to see that name within UI. BlueCrossBlueShield of South Carolina will be used instead (which will also be within the healthPlan's displayNames)
              if (displayName === "BCBS South Carolina") {
                return;
              }
              const option = { id: displayName, label: displayName, parentHealthPlanName: curr.id };
              acc = [...acc, option];
            });
          }
          return acc;
        },
        Array.of({ id: "allHealthPlan", label: "All", displayNames: [] })
      );

      //check if there is an ssoCode to make sure corresponding healthPlanDisplayName is part of currentHealthPlanFilterOptions
      if (ssoValue) {
        const healthPlanDisplayName = ssoToHealthPlanDisplayNameMap[ssoValue];
        if (healthPlanDisplayName) {
          const ssoResult = currentHealthPlanFilterOptions.find((hp) => hp.label === healthPlanDisplayName);
          //if filter does not have the healthPlanDisplayValue for this ssoCode, then add it
          if (!ssoResult) {
            currentHealthPlanFilterOptions.push({
              id: `${healthPlanDisplayName}`,
              label: healthPlanDisplayName,
            });
            //sort options after new entry is added
            currentHealthPlanFilterOptions.sort((a, b) => (a.label || "").localeCompare(b.label || ""));
          }
          //set selected filter value to ssoCode displayName
          setSelectedHealthPlan?.(healthPlanDisplayName);
        }
      }
      setHealthPlanFilterOptions(currentHealthPlanFilterOptions);
    }
    if (activeHealthPlans?.length === 1) {
      setSelectedHealthPlan?.(activeHealthPlans[0].id);
    }
  }, [activeHealthPlans, ssoValue, setSelectedHealthPlan, healthPlanFilterOptions]);

  useEffect(() => {
    if (healthPlanFilterOptions && !healthPlanSearchOptions) {
      const options = healthPlanFilterOptions.reduce((acc, curr) => {
        if (curr.id === "allHealthPlan") {
          acc[curr.id] = { healthPlanName: `in:${healthPlanFilterOptions.map((plan) => plan.label)}` };
        } else {
          acc[curr.id] = {
            healthPlanName: `eq:${curr.parentHealthPlanName ?? curr.id}`,
            healthPlanDisplayName: `eq:${curr.label}`,
          };
        }
        return acc;
      }, {} as Record<string, { healthPlanName: string; healthPlanDisplayName?: string }>);

      //if ssoCode value exists, set search parameter based off values linked to ssoCode
      if (ssoValue) {
        const healthPlanDisplayName = ssoToHealthPlanDisplayNameMap[ssoValue];
        if (healthPlanDisplayName) {
          //grab the parent health plan whose displayNames contain the displayName associated with the ssoCode
          const parentHealthPlan = activeHealthPlans?.find((healthPlan) =>
            healthPlan?.displayNames?.includes(healthPlanDisplayName)
          );
          let queryParam: ReferralRequestSearchRequestBody = {};
          queryParam = {
            ...queryParams,
            healthPlanName: `eq:${parentHealthPlan?.id ?? healthPlanDisplayName}`,
            healthPlanDisplayName: `eq:${healthPlanDisplayName}`,
          };
          //set the query params which power the dashboard search
          setQueryParams(queryParam);
        }
      }
      setHealthPlanSearchOptions(options);
    }
  }, [healthPlanFilterOptions, activeHealthPlans, queryParams, setQueryParams, ssoValue, healthPlanSearchOptions]);

  const handleHealthPlanChange = (val: string) => {
    let queryParam: ReferralRequestSearchRequestBody = {};
    if (val === "allHealthPlan") {
      setSelectedHealthPlan?.("allHealthPlan");
      queryParam = {
        ...queryParams,
        healthPlanName: undefined,
        healthPlanDisplayName: undefined,
      };
    } else {
      setSelectedHealthPlan?.(val);
      queryParam = {
        ...queryParams,
        healthPlanName: healthPlanSearchOptions ? healthPlanSearchOptions[val]?.healthPlanName : undefined,
        healthPlanDisplayName: healthPlanSearchOptions
          ? healthPlanSearchOptions[val]?.healthPlanDisplayName
          : undefined,
      };
    }
    setQueryParams(queryParam);
  };

  const renderRRAuthRadioOptionWithHealthplan = () => {
    return (
      <>
        <ScrollSideRail
          sideRailWidth={leftRailWidth}
          columnSpacingRight={5}
          verticalHeightAroundSideRail={appHeaderHeight() + headerHeight - theme.spacing(1)}
          hasHeader
        >
          <RadioOptionsWithExpandedButton
            options={healthPlanFilterOptions ?? []}
            selectedOption={selectedHealthPlan}
            onOptionChange={handleHealthPlanChange}
            heading="Health plan"
            showAllInitially={showAllOptions}
            numberOfItemsToShow={4}
          />
        </ScrollSideRail>
      </>
    );
  };

  return (
    <div>
      {coBrandingPayerLogosFF &&
        healthPlanFilterOptions?.length &&
        healthPlanFilterOptions.length > 1 &&
        renderRRAuthRadioOptionWithHealthplan()}
    </div>
  );
}
