import { colorsLight } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core";

export const useDropdownMenuStyles = makeStyles((theme) => ({
  moreVertIcon: {
    color: colorsLight.primary.main,
    height: 20,
    width: 20,
  },
  menuListStyles: {
    padding: 0,
  },
  menuItem: {
    display: "inline-flex",
    flexDirection: "row",
    width: "100%",
    minWidth: "200px",
    minHeight: "48px",
  },
  printIcon: {
    color: colorsLight.font.main,
    marginRight: "10%",
  },
  keyboardArrowRight: {
    color: colorsLight.font.main,
    marginLeft: "45%",
  },
  syncAltIcon: {
    color: colorsLight.font.main,
    marginRight: "10%",
  },

  libraryBooksIcon: {
    color: colorsLight.font.main,
    marginRight: "5%",
  },

  moreVertIconBtn: {
    boxShadow: "none",
    height: theme.spacing(4),
    width: theme.spacing(4),
    borderRadius: 5,

    backgroundColor: "transparent",
    "&:hover, &:active": {
      boxShadow: "none",
      backgroundColor: colorsLight.action.hoverBackground,
    },
  },
}));
