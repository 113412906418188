import React, { useEffect } from "react";

import { Body3, InlineButton, Subtitle2, Caption } from "@coherehealth/common";
import HistoryIcon from "@material-ui/icons/History";
import LaunchIcon from "@material-ui/icons/Launch";
import Grid from "@material-ui/core/Grid";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ProcedureCode, useGetAuthDecisionGroup } from "@coherehealth/core-platform-api";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useWithdrawAndReplaceStyles } from "../patientSummaryStyles";
import { routeToPatientSummaryFromReview } from "util/routeUtils/routeUtils";

interface WithdrawAndReplaceServiceRequestProps {
  serviceTitle: string;
  procedureCode: Array<string>;
  trackingNumber: string;
  patientId: string;
  serviceRequestId: string;
}
export function AuthDecisionGroupServiceRequestSummary(props: WithdrawAndReplaceServiceRequestProps) {
  const withdrawAndReplaceStyles = useWithdrawAndReplaceStyles();
  return (
    <ButtonBase
      component="a"
      className={withdrawAndReplaceStyles.box}
      href={routeToPatientSummaryFromReview({ serviceRequestId: props.serviceRequestId, patientId: props.patientId })}
      target="_blank"
    >
      <Grid container spacing={1} className={withdrawAndReplaceStyles.gridContainer}>
        <Grid item xs={5} className={withdrawAndReplaceStyles.titleWidth}>
          <div className={withdrawAndReplaceStyles.historyIconContainer}>
            <HistoryIcon className={withdrawAndReplaceStyles.historyIcon} />
            <Body3 className={withdrawAndReplaceStyles.serviceTitle}>{props.serviceTitle} </Body3>
          </div>
        </Grid>
        <Grid item xs={2}>
          <Body3 className={withdrawAndReplaceStyles.serviceTitle}>{props.procedureCode.join(", ")}</Body3>
        </Grid>
        <Grid item xs={2}>
          <Subtitle2 color="textSecondary">Tracking #{props.trackingNumber}</Subtitle2>
        </Grid>
        <Grid item xs={3}>
          <InlineButton style={{ float: "right" }} startIcon={<LaunchIcon />}>
            See details
          </InlineButton>
        </Grid>
      </Grid>
    </ButtonBase>
  );
}
interface WithdrawAndReplaceServiceRequestsProps {
  authDecisionGroupId: string;
}
export default function WithdrawAndReplaceServiceRequests(props: WithdrawAndReplaceServiceRequestsProps) {
  const withdrawAndReplaceStyles = useWithdrawAndReplaceStyles();

  const { enqueueSnackbar } = useSnackbar();
  const {
    data: sr,
    loading: serviceRequestLoading,
    error: serviceRequestError,
  } = useGetAuthDecisionGroup({ id: props.authDecisionGroupId });

  useEffect(() => {
    if (serviceRequestError) {
      enqueueSnackbar(`Error loading service request: ${serviceRequestError.message}`, { variant: "error" });
    }
  }, [serviceRequestError, enqueueSnackbar, props.authDecisionGroupId]);

  return (
    <div>
      {serviceRequestLoading ? (
        <div className={withdrawAndReplaceStyles.container}>
          <CircularProgress size={24} />
        </div>
      ) : (
        <div className={withdrawAndReplaceStyles.requestsContainer}>
          <Caption className={withdrawAndReplaceStyles.captionColor}>{sr?.serviceRequests?.length} requests</Caption>
          {sr &&
            sr?.serviceRequests
              ?.filter(({ authStatus }) => authStatus !== "DENIED")
              ?.map((serviceRequest): JSX.Element => {
                return (
                  <AuthDecisionGroupServiceRequestSummary
                    key={serviceRequest.id}
                    serviceTitle={serviceRequest?.clinicalService?.name || ""}
                    procedureCode={
                      serviceRequest?.procedureCodes?.map((procedureCode: ProcedureCode) => {
                        return procedureCode?.code;
                      }) || []
                    }
                    trackingNumber={serviceRequest?.cohereId || ""}
                    patientId={serviceRequest?.patient?.id || ""}
                    serviceRequestId={serviceRequest?.id || ""}
                  />
                );
              })}
        </div>
      )}
    </div>
  );
}
