import React, { PropsWithChildren } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "fit-content",
    width: ({ sideRailWidth, columnSpacing = 5 }: Props) => `calc(100% - ${sideRailWidth + columnSpacing}px)`,
  },
}));

interface Props {
  /** The width of the side rail */
  sideRailWidth: number;

  /** Space between side rail and main content, as a theme.spacing interval i.e. theme.spacing(columnSpacing) */
  columnSpacing?: number;
}

export default function ScrollableMainPanel(props: PropsWithChildren<Props>) {
  const classes = useStyles(props);
  const { sideRailWidth, columnSpacing, ...rest } = props;

  return <div className={classes.root} {...rest} />;
}
