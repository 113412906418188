import React, { useContext, useMemo, useState } from "react";

import ButtonBase, { ButtonBaseProps } from "@material-ui/core/ButtonBase";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import classnames from "classnames";
import uniqueId from "lodash/uniqueId";

import { Body1 } from "../Typography";
import DoubleDrawerExpansionContext, {
  DoubleDrawerExpandMoreButtonContext,
  DoubleDrawerExpandMoreButtonState,
} from "./DoubleDrawerExpansionContext";
import { InlineButton } from "../InlineButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const BORDER_RADIUS = 0.5;

const useStylesDrawerExpandMoreButton = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.drawer.button,
    display: "flex",
    padding: theme.spacing(2, 3, 2, 3),
    justifyContent: "flex-start",
    width: "100%",

    /*
     * Oh, borders.
     *
     * A whole pile of these buttons together should have
     * rounded corners at the outer edges, and a divider
     * between each button.
     * EXCEPT selected buttons should not have a border ever
     * (to prevent the left-side sticking out section from looking funny)
     * and the left side of selected buttons should not be rounded.
     */
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRadius: 0,
    "&:first-child": {
      // The top: rounded top left and right
      borderRadius: theme.spacing(BORDER_RADIUS, BORDER_RADIUS, 0, 0),
    },
    "&:last-child": {
      // The bottom: rounded bottom left and right, and no border on the bottom
      borderBottom: "none",
      borderRadius: theme.spacing(0, 0, BORDER_RADIUS, BORDER_RADIUS),
    },
    "& $carrotClass": {
      opacity: 0.7,
      transition: "opacity 0.2s ease-in-out",
    },
    "&:hover": {
      "& $carrotClass": {
        opacity: 1,
        transition: "opacity 0.2s ease-in-out",
      },
    },
  },
  selected: {
    backgroundColor: theme.palette.drawer.expanded,
    marginLeft: theme.spacing(-4),
    paddingLeft: theme.spacing(7),
    width: `calc(100% + ${theme.spacing(4)}px)`,
    borderBottom: `1px solid ${theme.palette.drawer.button}`, // to prevent jumpiness
    "&:first-child": {
      // Selected top: rounded only top right
      borderRadius: theme.spacing(0, BORDER_RADIUS, 0, 0),
    },
    "&:last-child": {
      // Selected bottom: rounded only bottom right
      borderRadius: theme.spacing(0, 0, BORDER_RADIUS, 0),
    },
    "& $carrotClass": {
      visibility: "hidden",
    },
    "&:hover": {
      "& $carrotClass": {
        opacity: 0.7,
        transition: "opacity 0.2s ease-in-out",
      },
    },
  },
  carrotClass: {
    padding: 0,
    height: theme.spacing(4),
    width: theme.spacing(4),
    marginRight: 0,
    marginTop: 2,
    color: theme.palette.secondary.dark,
  },
}));

interface Props extends ButtonBaseProps {
  identifierFromParent?: string;
  showCarrot?: boolean;
  allowDeselection?: boolean;
}

/**
 * Button specifically designed to control the extra expansion state of the double drawer.
 */
export default function DoubleDrawerExpandMoreButton({
  children,
  className,
  onClick,
  identifierFromParent,
  showCarrot,
  allowDeselection = true,
  ...props
}: Props) {
  const [id] = useState(identifierFromParent || uniqueId());
  const { expandedMore, setExpandedMore, selectedExpandButtonId, setSelectedExpandButtonId } =
    useContext(DoubleDrawerExpansionContext);
  const internalOnClick = (event: any) => {
    if (allowDeselection && expandedMore && selectedExpandButtonId === id) {
      // The current expansion is due to this button, so close the expansion
      setSelectedExpandButtonId(null);
      setExpandedMore(false);
    } else {
      // Expand for this button
      setSelectedExpandButtonId(id);
      setExpandedMore(true);
    }
    onClick?.(event);
  };

  const contextValue = useMemo<DoubleDrawerExpandMoreButtonState>(() => ({ currentExpandButtonId: id }), [id]);

  const { selected: selectedClass, carrotClass, ...classes } = useStylesDrawerExpandMoreButton();

  return (
    <ButtonBase
      className={classnames(className, { [selectedClass]: selectedExpandButtonId === id })}
      classes={classes}
      onClick={internalOnClick}
      {...props}
    >
      <DoubleDrawerExpandMoreButtonContext.Provider value={contextValue}>
        <Body1>{children}</Body1>
      </DoubleDrawerExpandMoreButtonContext.Provider>
      {showCarrot && (
        <CarrotContainer>
          <CarrotIcon>
            <ExpandMoreIcon className={carrotClass} />
          </CarrotIcon>
        </CarrotContainer>
      )}
    </ButtonBase>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CarrotContainer = styled("div")({
  position: "relative",
  width: "100%",
  height: "100%",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CarrotIcon = styled(InlineButton)(({ theme }) => ({
  position: "absolute",
  right: -20,
  top: -24,
  transform: "rotate(270deg)",
}));
