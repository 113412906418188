import React from "react";
import { Box } from "@mui/material";
import { ArrowForward, Delete } from "@mui/icons-material";
import { Body2 } from "@coherehealth/design-system";
import { useTheme } from "@mui/material";
import { InlineButton } from "@coherehealth/common";
import WithdrawButton from "../WithdrawButton";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { useAuthorized } from "authorization";
import useOpsEditDisabled from "hooks/useOpsEditDisabled";

type Props = {
  sr: ServiceRequestResponse;
  withdrawRequest: () => void;
  deleteDraft: () => void;
  continueDraft: () => void;
  shouldShowWithdrawButton?: boolean;
  srNoLongerNeededButton?: boolean;
};

const ContinueOrWithdraw = ({
  sr,
  withdrawRequest,
  deleteDraft,
  continueDraft,
  shouldShowWithdrawButton,
  srNoLongerNeededButton,
}: Props) => {
  const theme = useTheme();
  const isAuthViewOnlyUser = useAuthorized("AUTH_VIEW_ONLY");
  const opsEditEnabled = !useOpsEditDisabled(sr);

  if (sr.authStatus === "DRAFT") {
    return !isAuthViewOnlyUser ? (
      <div style={{ height: theme.spacing(3) }}>
        <InlineButton
          data-tracking-id="delete-draft"
          data-testid={`delete-draft-button-${sr.cohereId}`}
          startIcon={<Delete />}
          style={{ color: theme.palette.error.main }}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            deleteDraft();
            event.stopPropagation();
          }}
        >
          <Body2>Delete</Body2>
        </InlineButton>
        <InlineButton
          data-tracking-id="continue-draft"
          data-testid="continue-draft-button"
          endIcon={<ArrowForward />}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            continueDraft();
            event.stopPropagation();
          }}
          color="primary"
          style={{
            padding: theme.spacing(0),
            marginLeft: theme.spacing(2),
          }}
        >
          <Body2>Continue</Body2>
        </InlineButton>
      </div>
    ) : (
      <></>
    );
  }

  if (opsEditEnabled && sr.authStatus !== "WITHDRAWN" && sr.authStatus !== "VOIDED" && shouldShowWithdrawButton) {
    return !isAuthViewOnlyUser ? (
      <Box display="flex" alignItems="center">
        <WithdrawButton
          serviceRequest={sr}
          buttonText={!srNoLongerNeededButton ? "Withdraw" : "Mark as no longer needed"}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            withdrawRequest();
            event.stopPropagation();
          }}
          styles={{ padding: theme.spacing(0) }}
          srNoLongerNeededButton={srNoLongerNeededButton}
        />
      </Box>
    ) : (
      <></>
    );
  }
  return <></>;
};

export default ContinueOrWithdraw;
