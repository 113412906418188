import {
  Column,
  PaginateProps,
  useFeature,
  FilterValues,
  formatDateToISODate,
  FilterConfig,
} from "@coherehealth/common";
import {
  AuthorizationResponse,
  AuthorizationsWithCountResponse,
  GetAuthorizationsQueryParams,
  ServiceRequestResponse,
  useGetAuthorizations,
} from "@coherehealth/core-platform-api";

import { useEffect, useMemo, useState } from "react";

import { useTheme } from "@material-ui/core/styles";
import { useAuthorized } from "authorization";

import { useSnackbar } from "notistack";
import {
  authStatusFilter,
  cptCodeFilter,
  generateOtherAuthorizationRowChildTableColumns,
  generateOtherAuthorizationTableColumns,
  serviceDateFilter,
} from "./otherServiceRequestsConfig";

interface OtherAuthorizationProps {
  serviceRequest: ServiceRequestResponse | null;
}

export type UseOtherAuthorizationProps = {
  otherAuthsProps: {
    otherAuthorizationsResponse?: AuthorizationsWithCountResponse | AuthorizationResponse[] | null;
    otherAuthorizationsLoading?: boolean;
    otherAuthorizationTableColumns?: Column<AuthorizationResponse>[];
    otherAuthorizationRowChildTableColumns: Column<ServiceRequestResponse>[];
    paginateParams: PaginateProps;
    setPaginatedParams: React.Dispatch<React.SetStateAction<PaginateProps>>;
    otherSRsFilterValues: FilterValues;
    setOtherSRsFilterValues: React.Dispatch<React.SetStateAction<FilterValues>>;
    authFilterConfigs: FilterConfig[];
  };
};

const useOtherAuthorizations = ({ serviceRequest }: OtherAuthorizationProps): UseOtherAuthorizationProps => {
  const theme = useTheme();
  const groupOtherSrsByAuthorization = useFeature("authGroupingForOtherSrs");
  const isPlanned = serviceRequest?.requestTiming === "PRE_SERVICE";
  const isInpatientUnplanned = serviceRequest?.encounterType === "INPATIENT" && !isPlanned;
  const { enqueueSnackbar } = useSnackbar();
  const [paginateParams, setPaginatedParams] = useState<PaginateProps>({
    max: 6,
    offset: 0,
  });
  const showPreviouslyApprovedRequestFeatureFlag = useFeature("showPreviouslyApprovedRequest");
  const canViewPreviouslyApprovedRequestUsers = useAuthorized("CAN_VIEW_PREVIOUSLY_APPROVED_REQUESTS");
  const showPreviouslyApprovedRequest =
    showPreviouslyApprovedRequestFeatureFlag && canViewPreviouslyApprovedRequestUsers;
  const [otherSRsFilterValues, setOtherSRsFilterValues] = useState<FilterValues>({});
  const authStatusParams = otherSRsFilterValues["authStatus"] as string[];
  const cptCodeParams = otherSRsFilterValues["cptCodes"] as string[];
  const startDateAfterParams = otherSRsFilterValues["serviceDateAfter"] as Date;
  const cptCodes = cptCodeParams?.join(",");
  const authStatus = authStatusParams?.join(",");

  const queryParams = useMemo(() => {
    const params: GetAuthorizationsQueryParams = {
      view: "otherAuthorizationRequest",
      patient: `eq:${serviceRequest?.patient?.id}`,
      sort: "dateCreated:desc",
      renderSR: true,
      max: paginateParams.max ? paginateParams.max - 1 : 5,
      offset: paginateParams.offset ?? 0,
      id: `ne:${serviceRequest?.authorization?.id}`,
    };
    if (authStatus && authStatus !== "") {
      params.authStatus = `in:${authStatus}`;
    }
    if (cptCodes && cptCodes !== "") {
      params["semanticProcedureCodes.code"] = `in:${cptCodes}`;
    }
    if (startDateAfterParams && startDateAfterParams !== null) {
      params.startDate = `ge:${formatDateToISODate(startDateAfterParams)}`;
    }
    return params;
  }, [
    authStatus,
    cptCodes,
    paginateParams.max,
    paginateParams.offset,
    serviceRequest?.authorization?.id,
    serviceRequest?.patient?.id,
    startDateAfterParams,
  ]);

  const {
    data: otherAuthorizationsResponse,
    loading: otherAuthorizationsLoading,
    error: otherAuthorizationsError,
    refetch: fetchOtherAuthorizations,
  } = useGetAuthorizations({
    lazy: true,
  });

  useEffect(() => {
    if (otherAuthorizationsError) {
      enqueueSnackbar("Failed to get other related authorization to service request", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [otherAuthorizationsError, enqueueSnackbar]);

  useEffect(() => {
    let isMounted = true;
    if (serviceRequest && groupOtherSrsByAuthorization && isMounted) {
      fetchOtherAuthorizations({
        queryParams,
      });
    }
    return () => {
      isMounted = false;
    };
  }, [
    fetchOtherAuthorizations,
    groupOtherSrsByAuthorization,
    paginateParams.max,
    paginateParams.offset,
    queryParams,
    serviceRequest,
  ]);
  const otherAuthorizationTableColumns = generateOtherAuthorizationTableColumns(
    isInpatientUnplanned,
    showPreviouslyApprovedRequest,
    theme
  );
  const otherAuthorizationRowChildTableColumns = generateOtherAuthorizationRowChildTableColumns(
    isInpatientUnplanned,
    showPreviouslyApprovedRequest,
    theme
  );
  const authFilterConfigs: FilterConfig[] = [serviceDateFilter, cptCodeFilter, authStatusFilter];

  return {
    otherAuthsProps: {
      otherAuthorizationsResponse,
      otherAuthorizationsLoading,
      otherAuthorizationTableColumns,
      otherAuthorizationRowChildTableColumns,
      paginateParams,
      setPaginatedParams,
      otherSRsFilterValues,
      setOtherSRsFilterValues,
      authFilterConfigs,
    },
  };
};

export default useOtherAuthorizations;
