import { colorsLight, TextField } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, Theme } from "@material-ui/core";
import { ChangeEvent, useMemo, Dispatch, SetStateAction } from "react";

export interface UnitProps {
  units: string;
  setUnits: (units: string) => void;
  updateRenderCount?: () => void;
  onBlurServiceLevelUnits: number;
  setOnBlurServiceLevelUnits: (onBlurServiceLevelUnits: number) => void;
  initialUnits: string;
  defaultUnits?: number;
  minNumUnits?: number;
  hasValidUnits?: boolean;
  hasUnitsIfApplicable?: boolean;
  showApprovedSrEditWarning?: boolean;
  attemptedSubmit?: boolean;
  disabled?: boolean;
  unitLabel: string;
  isContinuationWorkflow?: boolean;
  requestType?: string;
  setIsAdditionalUnitsRequested?: Dispatch<SetStateAction<boolean>>;
}

export default function UnitTextField({
  units,
  setUnits,
  updateRenderCount,
  onBlurServiceLevelUnits,
  setOnBlurServiceLevelUnits,
  initialUnits,
  defaultUnits,
  minNumUnits,
  hasValidUnits,
  hasUnitsIfApplicable,
  showApprovedSrEditWarning,
  attemptedSubmit,
  disabled,
  unitLabel,
  isContinuationWorkflow,
  requestType,
  setIsAdditionalUnitsRequested,
}: UnitProps) {
  const recommendedSelected = !!defaultUnits && !!units && units !== "0" && Math.trunc(Number(units)) <= defaultUnits;
  const invalidUnitsHelperText = useMemo(() => {
    if (!hasValidUnits) {
      if (units === "") {
        return "Required";
      }
      return requestType === "CONTINUATION" ? "" : `Must be greater than ${!!minNumUnits && minNumUnits - 1}`;
    }
  }, [hasValidUnits, units, requestType, minNumUnits]);

  const helperTextUnits = useMemo(() => {
    if (!hasValidUnits && units !== "" && !Number.isInteger(Number(units)) && (units !== "0" || minNumUnits !== 0)) {
      return `Must be greater than ${!!minNumUnits && minNumUnits - 1}`;
    } else if (recommendedSelected) {
      return "Recommended for auto approval";
    }
  }, [hasValidUnits, units, minNumUnits, recommendedSelected]);
  return (
    <>
      <StyledTextField
        error={(!hasValidUnits || (isContinuationWorkflow && !hasUnitsIfApplicable)) && attemptedSubmit}
        helperText={attemptedSubmit && !hasValidUnits ? invalidUnitsHelperText : helperTextUnits}
        type="text"
        fullWidth
        label={unitLabel}
        data-tracking-id="number-of-service-dates"
        value={units === "undefined" ? "" : units}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          let strVal = e.currentTarget.value;
          setUnits(strVal);
          const numVal = Math.trunc(Number(strVal));
          if (setIsAdditionalUnitsRequested) {
            setIsAdditionalUnitsRequested(!isNaN(numVal) && numVal !== 0);
          }
          updateRenderCount?.();
        }}
        onBlur={() => {
          const onBlurChange = onBlurServiceLevelUnits + 1;
          setOnBlurServiceLevelUnits(onBlurChange);
        }}
        warningHelperText={showApprovedSrEditWarning}
        recommendedSelected={recommendedSelected}
        showApprovedSrEditWarning={showApprovedSrEditWarning}
        hasValidUnits={hasValidUnits}
        disabled={disabled}
        dataPublic={true}
      />
    </>
  );
}

interface StyleProps {
  recommendedSelected?: boolean;
  showApprovedSrEditWarning?: boolean;
  hasValidUnits?: boolean;
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const StyledTextField = styled(({ recommendedSelected, showApprovedSrEditWarning, hasValidUnits, ...props }) => (
  <TextField {...props} />
))<Theme, StyleProps>(({ theme, recommendedSelected, showApprovedSrEditWarning, hasValidUnits }) => {
  const color = () => {
    // Yes, the error and default colors should be controlled by the error prop/styles in the TextField component
    // But if we set those values as undefined here, JSS doesn't seem to clear the previously applied color :(
    if (!hasValidUnits) {
      return theme.palette.error.dark;
    } else if (showApprovedSrEditWarning) {
      return theme.palette.warning.dark;
    } else if (recommendedSelected) {
      return theme.palette.success.dark;
    } else {
      return colorsLight.black.mediumEmphasis;
    }
  };
  return {
    "& .MuiFormHelperText-root": {
      color: color(),
      // width is 100% so that the helper text will not extend past the text field container
      width: "100%",
    },
    "& .MuiFilledInput-adornedEnd": {
      paddingRight: 0,
    },
  };
});
