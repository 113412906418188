import { Address, PhoneNumber } from "@coherehealth/core-platform-api";
import compact from "lodash/compact";
import { differenceInDays, differenceInMonths, differenceInYears } from "date-fns";
import { parseDateFromISOString, today } from "@coherehealth/common";

export function patientGenderDisplay(gender: string | undefined) {
  if (gender?.startsWith("F")) {
    return "Female";
  }
  if (gender?.startsWith("M")) {
    return "Male";
  } else {
    return undefined;
  }
}

export function singleLineAddress(address: Address | undefined) {
  if (!address) {
    return undefined;
  }
  const { line1, line2, city, state, zipCode } = address;
  const cityState = compact([city, state]).join(", ");
  return compact([line1, line2, cityState, zipCode]).join(" ");
}

export const formatAnyPhone = (phones: PhoneNumber[] | undefined) => {
  const firstDefinedPhoneNumber = phones?.find((phoneNum) => !!phoneNum.number);
  return firstDefinedPhoneNumber && formatPhone(firstDefinedPhoneNumber);
};

export function formatPhone(phone: PhoneNumber | undefined) {
  let displayNumber;
  if (phone?.number) {
    // Deprecated removes country code (we are only US for now). Look into moving everything to libphonenumber
    if (phone?.number.length > 10) {
      displayNumber = `(${phone.number.slice(1, 4)}) ${phone.number.slice(4, 7)}-${phone.number.slice(7)}`;
    } else {
      displayNumber = `(${phone.number.slice(0, 3)}) ${phone.number.slice(3, 6)}-${phone.number.slice(6)}`;
    }
  }
  if (phone?.extension) {
    displayNumber = displayNumber?.concat(` ext. ${phone.extension}`);
  }
  return displayNumber;
}

export function ageStringFromDobString(dob: string): string {
  // formatDistanceStrict from date-fns should handle this perfectly,
  // but it's completely, insanely wrong:
  // https://github.com/date-fns/date-fns/issues/2715
  const now = today();
  const dobAsDate = parseDateFromISOString(dob);
  if (differenceInMonths(now, dobAsDate) < 1) {
    const dayDiff = differenceInDays(now, dobAsDate);
    return `${dayDiff} ${pluralize("day", dayDiff)}`;
  } else if (differenceInYears(now, dobAsDate) < 1) {
    const monthDiff = differenceInMonths(now, dobAsDate);
    return `${monthDiff} ${pluralize("month", monthDiff)}`;
  } else {
    const yearDiff = differenceInYears(now, dobAsDate);
    return `${yearDiff} ${pluralize("year", yearDiff)}`;
  }
}

export function preferredWrittenLanguageDisplay(language?: string) {
  if (!language) {
    return " - ";
  } else if (language === "ENG") {
    return "English";
  } else if (language === "SPAN") {
    return "Spanish";
  }
}

const pluralize = (word: string, amount: number) => (amount === 1 ? word : `${word}s`);
