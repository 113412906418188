import React, { FunctionComponent } from "react";

import { DateSelect } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core/styles";

export interface EndDateProps {
  hasValidEndDate: boolean;
  startDate?: Date;
  endDate?: Date;
  setEndDate: (date: Date) => void;
  hasCoverageError?: boolean;
  attemptedSubmit: boolean;
  disabled?: boolean;
  endDateLabel: string;
}

const EndDateSelect: FunctionComponent<EndDateProps> = ({
  hasValidEndDate,
  startDate,
  endDate,
  setEndDate,
  hasCoverageError,
  attemptedSubmit,
  disabled,
  endDateLabel,
}) => {
  const dateSelectClasses = useDateSelectStyles({});
  const handlingError = (!hasValidEndDate && attemptedSubmit) || hasCoverageError;
  const getHelperTextIfNull = () => !endDate && handlingError && "Required";

  return (
    <DateSelect
      label={endDateLabel}
      data-tracking-id="service-end-date"
      value={endDate || null}
      onDateChange={setEndDate}
      minDate={startDate}
      minDateMessage={"End date cannot be before start date"}
      error={(!hasValidEndDate && attemptedSubmit) || hasCoverageError}
      TextFieldProps={{
        className: dateSelectClasses.textField,
      }}
      disabled={disabled}
      helperText={getHelperTextIfNull()}
      attemptedSubmit={attemptedSubmit}
    />
  );
};

export default EndDateSelect;

const useDateSelectStyles = makeStyles({
  textField: {
    height: "fit-content",
    width: "100%",
  },
});
