import { Requestor, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import React, { FunctionComponent } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Grid, styled, useTheme } from "@material-ui/core";
import { Column, FieldValue, Label } from "components/ServiceRequest/ReadonlyDetail/FormElements";
import { Caption } from "@coherehealth/common";
import { formatPhone } from "util/demographicUtils";

interface Props {
  serviceRequest: ServiceRequestResponse | undefined;
  requestor: Requestor | undefined;
}

const RequestorReadOnly: FunctionComponent<Props> = ({ serviceRequest, requestor }) => {
  const isPortalSubmission = !Boolean(requestor);
  const theme = useTheme();

  // TODO this should display the requestor info from the SR if available instead of the createdBy info COH-693
  return (
    <>
      <Grid container direction="row" spacing={2}>
        {isPortalSubmission && (
          <Grid item xs={12} style={{ padding: theme.spacing(0.5, 0, 0, 0), marginLeft: theme.spacing(1) }}>
            <RedCaption>Go to provider organization page to edit requested by details</RedCaption>
          </Grid>
        )}
        <Grid item container style={{ padding: theme.spacing(2, 0) }}>
          <BorderlessColumn style={{ marginLeft: "8px" }}>
            <Label>{isPortalSubmission ? "Requested by" : "User email"}</Label>
            <FieldValue>
              {(isPortalSubmission ? serviceRequest?.createdByName : requestor?.email || requestor?.user?.email) ||
                "---"}
            </FieldValue>
            <Label>{isPortalSubmission ? "Organization" : "User first name"}</Label>
            <BottomValue>
              {(isPortalSubmission
                ? serviceRequest?.createdByGroups
                  ? serviceRequest?.createdByGroups[0]
                  : null
                : requestor?.firstName || requestor?.user?.firstName) || "---"}
            </BottomValue>
          </BorderlessColumn>
          <BorderlessColumn>
            <Label>{isPortalSubmission ? "Phone number" : "User fax number"}</Label>
            <FieldValue>
              {formatPhone(
                isPortalSubmission ? { number: serviceRequest?.createdByPhoneNumber } : requestor?.faxNumber
              ) || "---"}
            </FieldValue>
            <Label>{isPortalSubmission ? "Contact type" : "User last name"} </Label>
            <BottomValue>
              {(isPortalSubmission ? "Portal" : requestor?.lastName || requestor?.user?.lastName) || "---"}
            </BottomValue>
          </BorderlessColumn>
          {!isPortalSubmission && (
            <BorderlessColumn xs={3}>
              <Label>User phone number</Label>
              <FieldValue>
                {formatPhone(requestor?.phoneNumber || { number: requestor?.user?.phone }) || "---"}
              </FieldValue>
              <Label>Contact type</Label>
              <BottomValue>{channelToString(requestor?.channel)}</BottomValue>
            </BorderlessColumn>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default RequestorReadOnly;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const BorderlessColumn = styled(Column)(({ theme }) => ({
  borderRight: "0px",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const BottomValue = styled(FieldValue)(({ theme }) => ({
  margin: 0,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RedCaption = styled(Caption)(({ theme }) => ({
  color: theme.palette.error.main,
}));

function channelToString(channel: string | undefined) {
  if (channel) {
    return channel[0] + channel.slice(1).toLowerCase();
  }
  return "---";
}
