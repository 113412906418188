import {
  Attachment,
  AttachmentUpdatePayload,
  useDeleteReferralRequestAttachment,
  useUpdateReferralRequestAttachment,
} from "@coherehealth/core-platform-api";
import { TableContainer, Table } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { UploadFile } from "../FileUploadItem";
import AttachmentTableBody from "./AttachmentTableBody";
import { TableContainerProps as MuiTableContainerProps } from "@material-ui/core";

interface Props extends MuiTableContainerProps {
  files: UploadFile[];
  setFiles: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  canUserEditViewable: boolean;
  carePathJourneyId?: string;
  serviceRequestIds?: string[];
  referralRequestIds?: string[];
  stage?: string;
  onUpdateAttachments?: (arg0: Attachment[]) => void;
  setRemovedAttachments: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function ReferralAttachmentsTable({
  files,
  canUserEditViewable,
  setFiles,
  stage,
  referralRequestIds,
  onUpdateAttachments,
  setRemovedAttachments,
  ...muiTableContainerProps
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: deleteAttachment,
    loading: deleteLoading,
    error: deleteError,
  } = useDeleteReferralRequestAttachment({
    id: "",
  });

  useEffect(() => {
    if (deleteError) {
      enqueueSnackbar(`Failed to delete attachment: ${deleteError.message}`, { variant: "error" });
    }
  }, [deleteError, enqueueSnackbar]);

  const {
    mutate: updateAttachment,
    loading: updateLoading,
    error: updateError,
  } = useUpdateReferralRequestAttachment({
    id: "",
    attachmentId: "",
  });

  useEffect(() => {
    if (updateError) {
      enqueueSnackbar(`Failed to update attachment: ${updateError.message}`, { variant: "error" });
    }
  }, [updateError, enqueueSnackbar]);

  const deleteAttachmentGroup = async (fileId: string): Promise<void> => {
    await deleteAttachment(fileId, {
      pathParams: { id: files.find((f) => f.id === fileId)?.referralRequest?.id || "" },
    });
  };

  const updateAttachmentGroup = async (
    fileId: string,
    attachmentUpdatePayload: AttachmentUpdatePayload
  ): Promise<void> => {
    const response = await updateAttachment(attachmentUpdatePayload, {
      pathParams: { id: files.find((f) => f.id === fileId)?.referralRequest?.id || "", attachmentId: fileId },
    });
    onUpdateFile(response);
  };

  // Called after the file deletion http requests finish
  const onRemoveFile = ({ id }: { id: string }) => {
    setFiles((orig) => [...orig.filter((f) => f.id !== id)]);
    setRemovedAttachments([id]);
  };

  const onUpdateFile = (file: UploadFile) => {
    setFiles((orig) => {
      const existingIdx = orig.findIndex((f) => f.id === file.id);
      if (existingIdx >= 0) {
        // Return a copy of the array with the existing entry merged with `file` and all other elements untouched
        return [...orig.slice(0, existingIdx), { ...orig[existingIdx], ...file }, ...orig.slice(existingIdx + 1)];
      } else {
        // This probably shouldn't happen... oh well, just append it
        return [...orig, file];
      }
    });
  };

  return (
    <TableContainer {...muiTableContainerProps}>
      <Table>
        <AttachmentTableBody
          files={files}
          setFiles={setFiles}
          referralRequestIds={referralRequestIds}
          canUserEditViewable={canUserEditViewable}
          stage={stage}
          onUpdateAttachments={onUpdateAttachments}
          deleteAttachment={deleteAttachmentGroup}
          deleteLoading={deleteLoading}
          onRemoveFile={onRemoveFile}
          onUpdateFile={onUpdateFile}
          updateAttachmentGroup={updateAttachmentGroup}
          updateLoading={updateLoading}
          data-testid="attachment-table-body"
        />
      </Table>
    </TableContainer>
  );
}
