import { Dispatch, SetStateAction } from "react";
import { InformativeModal, InformativeModalProps } from "@coherehealth/common";
import ClipboardWarningIcon from "../components/images/ClipboardWarningIcon";
import { routeToPatientSummaryFromReview } from "util/routeUtils/routeUtils";

interface Props extends InformativeModalProps {
  patientId?: string;
  open: boolean;
  setReviewErrorModalOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;
  subHeaderText?: string;
  serviceRequestId?: string;
}

export default function ReviewErrorModal({
  patientId,
  open,
  setReviewErrorModalOpen,
  headerText,
  subHeaderText,
  serviceRequestId,
  tertiaryButtonText,
  ...props
}: Props) {
  return (
    <InformativeModal
      headerText={headerText}
      subHeaderText={subHeaderText}
      icon={<ClipboardWarningIcon />}
      tertiaryButtonText={tertiaryButtonText || "Back to patient summary"}
      tertiaryButtonRoute={patientId && routeToPatientSummaryFromReview({ serviceRequestId, patientId })}
      showDivider
      open={open}
      onClose={() => setReviewErrorModalOpen(false)}
      {...props}
    />
  );
}
