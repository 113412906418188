import { useMemo, useState, ComponentProps, Dispatch, useEffect, useCallback, SetStateAction, useContext } from "react";
import {
  Checkbox,
  H4,
  RadioGroup,
  useFeature,
  Caption,
  formatDateToISODate,
  colorsLight,
  HUMANA_HEALTH_PLAN_NAME,
  useGeneralAuthSubmissionWorflowOn,
} from "@coherehealth/common";
import {
  AuthStatus,
  ClinicalService,
  Patient,
  ProcedureCode,
  useGetPlaceOfServices,
  GetOutofNetworkCheckResponse,
} from "@coherehealth/core-platform-api";
import Divider from "@material-ui/core/Divider";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { useTheme, styled } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
  HUMANA_OON_PERFORMING_PROVIDER_EXCEPTION_REASONS,
  HUMANA_OON_FACILITY_EXCEPTION_REASONS,
  OonExceptionReasonOptions,
  validateOONExceptionCommentFieldForCareParticipants,
  validateExceptionReasonOnSRforCareParticipants,
} from "util/serviceRequest";

import { sameProviderCheckboxClick } from "util/serviceRequest";
import CareTypeSelect from "components/ServiceRequest/ServiceRequestForm/components/CareTypeSelect";
import PlaceOfServiceSelect from "components/ServiceRequest/ServiceRequestForm/components/PlaceOfServiceSelect";
import {
  PrimaryDiagnosisCodeSelect,
  SecondaryDiagnosisCodeSelect,
} from "components/ServiceRequest/ServiceRequestForm/components/DiagnosisCodeSelect";
import SingleClinicalServiceSelect from "components/ServiceRequest/ServiceRequestForm/components/SingleClinicalServiceSelect";
import ServiceFrequencySection from "components/ServiceRequest/ServiceRequestForm/components/ServiceFrequencySection";
import StartAndEndDateSelect, {
  MAX_DATE_SELECT_DATE,
} from "components/ServiceRequest/ServiceRequestForm/components/StartAndEndDateSelect";
import UnitWithUnitTypeSelect from "components/ServiceRequest/ServiceRequestForm/components/UnitWithUnitTypeSelect";
import {
  FlexGridItem,
  RedirectRuleCheckFn,
  ServiceRequestFormContent,
} from "common/SharedServiceRequestFormComponents";
import { ExistingRequestExpedited, RequestExpedited } from "components/ServiceRequest/ServiceRequestForm/Expedited";
import useSRFormRadioGroupHeaderStyles from "components/ServiceRequest/ServiceRequestForm/hooks/useSRFormRadioGroupHeaderStyles";
import ServiceRequestFormRedirectModal, {
  RedirectModalErrorObjectType,
  formContentToSR,
  useServiceRequestFormRedirectDispatch,
} from "components/ServiceRequest/ServiceRequestForm/ServiceRequestFormRedirectModal";
import { Dialog } from "@material-ui/core";
import RemoveNonPALModal from "components/ServiceRequest/ServiceRequestForm/RemoveNonPALModal";
import NonPalPDFProvider from "components/ServiceRequest/ServiceRequestForm/NonPalDocument/NonPalPDFProvider";
import {
  checkOnetimeRequestCoverage,
  getCurrentPrimaryCoverage,
  getPatientHealthPlanName,
  getPatientRiskBearingEntity,
} from "util/patientUtils";
import DocumentViewer from "components/DocumentViewer";
import useEditsOnApprovedSRState from "components/ServiceRequest/ServiceRequestForm/hooks/useEditsOnApprovedSRState";
import {
  FormConfiguration,
  ServiceRequestFieldName,
} from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import BehavioralHealthAdmissionTypeSelect from "components/ServiceRequest/ServiceRequestForm/components/BehavioralHealthAdmissionTypeSelect";
import BehavioralHealthReviewTypeSelect from "components/ServiceRequest/ServiceRequestForm/components/BehavioralHealthReviewTypeSelect";
import AdmissionAndExpectedDischargeDateSelect from "common/AdmissionAndExpectedDischargeDateSelect";
import PhoneInput from "common/PhoneInput";
import ProcedureCodeSelectTableWithUnits from "../../ServiceRequest/ServiceRequestForm/components/ProcedureCodeSelectTableWithUnits";
import ProcedureCodeSelectTable from "../../ServiceRequest/ServiceRequestForm/components/ProcedureCodeSelectTable";
import { NonCohereCodes, onCareTypeSwitch } from "../common";
import { useSnackbar } from "notistack";
import OONStatusDisplay from "components/ServiceRequest/ServiceRequestForm/components/OONStatusDisplay";
import OonExceptionReason from "components/ServiceRequest/ServiceRequestForm/components/OonExceptionReason";
import { useAuthorized } from "authorization";
import { SuggestionContext } from "../SuggestionContext";
import { compareCodeListEqualityNoOrder, isNotNullOrUndefined } from "util/suggestionUtils";
import useDoPalCheckOnServiceRequestForm from "../../ServiceRequest/ServiceRequestForm/hooks/useDoPalCheckOnServiceRequestForm";
import {
  OrderingProviderSelectManual,
  PerformingProviderSelectManual,
} from "components/ServiceRequest/ServiceRequestForm/components/ProviderSelectManual";
import FacilitySelectManual from "components/ServiceRequest/ServiceRequestForm/components/FacilitySelectManual";
import { isExceptionCommentRequired } from "util/providerUtils";
import { useCalculateDateSelectionMessageDisplay } from "components/AuthBuilder/FillFormsGeneralAuthSubmission/utils";
import {
  validatePerformingProviderAddress,
  validatePerformingProviderNPI,
  validatePerformingProviderTin,
} from "../validationUtil";
import useSearchAndLocation from "hooks/useSearchAndLocation";
import { fieldIsValid, SRFormConfigFieldWrapper } from "common/FormConfigUtils";
import { useGetServiceRequestStateConfigurationByPayerAndAuthStatus } from "hooks/useGetFeatureConfigurations";
import { useDebouncedCallback } from "@react-hookz/web";

const MIN_START_DATE = new Date(2020, 0, 1);
const MAX_SECONDARY_DIAGNOSES = 9;

interface Props {
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  formConfiguration: FormConfiguration;
  patient?: Patient;
  attemptedSubmit: boolean;
  setCanBeSubmitted: (b: boolean) => void;
  hideDiagnoses?: boolean;
  isCohereFaxForm?: boolean;
  authStatus: AuthStatus;
  delegatedVendorName?: string;
  userFaxExtension?: string;
  onUserEdit?: Dispatch<ServiceRequestFormContent>;
  workflowId?: string;
  dateCreated?: string;
  oonCheckLoading?: boolean;
  providerOonCheckData?: GetOutofNetworkCheckResponse | undefined;
  performingProviderPracticeOonCheckData?: GetOutofNetworkCheckResponse | undefined;
  facilityOonCheckData?: GetOutofNetworkCheckResponse | undefined;
  orderingProviderOonCheckData?: GetOutofNetworkCheckResponse | undefined;
  careParticipantOonCheckData?: GetOutofNetworkCheckResponse | undefined;
  oonCheckFieldsFilled?: boolean;
  populateDefaults?: boolean;
  //only show a start date on a new request if available on the claim request otherwise no startDate
  clearDefaultStartDateValue?: boolean;
  allowCareTypeTransitions?: boolean;
  hideExpeditedRequestCheckbox?: boolean;
}

export default function ServiceRequestForm({
  formContent,
  setFormContent,
  formConfiguration,
  patient,
  attemptedSubmit,
  setCanBeSubmitted,
  hideDiagnoses = false,
  isCohereFaxForm = false,
  authStatus: currAuthStatus,
  delegatedVendorName,
  userFaxExtension,
  onUserEdit,
  workflowId,
  dateCreated,
  oonCheckLoading,
  providerOonCheckData,
  facilityOonCheckData,
  orderingProviderOonCheckData,
  careParticipantOonCheckData,
  oonCheckFieldsFilled,
  populateDefaults = true,
  clearDefaultStartDateValue,
  allowCareTypeTransitions,
  hideExpeditedRequestCheckbox,
  performingProviderPracticeOonCheckData,
}: Props) {
  //Feature flags
  const simplifiedServiceFrequency = useFeature("simplifiedServiceFrequency");
  const [clinicalService, setClinicalService] = useState<ClinicalService | undefined>(formContent?.clinicalService);
  const [nonPalModalOpen, setNonPalModalOpen] = useState(false);
  const [nonPalPDFOpen, setNonPalPDFOpen] = useState(false);
  const [onBlurServiceLevelUnits, setOnBlurServiceLevelUnits] = useState(0);
  const [nonPalProcedureCodes, setNonPalProcedureCodes] = useState<ProcedureCode[]>([]);
  const [nonCohereProcedureCodes, setNonCohereProcedureCodes] = useState<NonCohereCodes[]>([]);
  const [palPxCodes, setPalProcedureCodes] = useState<ProcedureCode[]>([]);
  const canSkipOon = useAuthorized("CAN_SKIP_OON");

  //if we are not showing the default values for start date
  //then this state will hold the initial value when the screen loading and the user selected value
  const [actualStartDateValue, setActualStartDateValue] = useState<Date | undefined>(undefined);

  const { spacing } = useTheme();
  const { location, search } = useSearchAndLocation();

  const patientHealthPlanName = getPatientHealthPlanName(patient || undefined, formContent.startDate) || "";
  const patientRiskBearingEntity = getPatientRiskBearingEntity(patient || undefined, formContent.startDate) || "";
  const generalAuthSubmissionWorkflowEnabled = useGeneralAuthSubmissionWorflowOn(patientHealthPlanName);

  const nonPalSubmissionEnabled = patientHealthPlanName === "Geisinger" && generalAuthSubmissionWorkflowEnabled;

  const authCategoryClinicalService = formContent?.clinicalServices?.find((clinicalService) =>
    Boolean(clinicalService.authCategory)
  );
  const clinicalServiceId = authCategoryClinicalService
    ? authCategoryClinicalService.id
    : formContent?.clinicalServices && formContent?.clinicalServices.length > 0
    ? formContent.clinicalServices[0].id
    : undefined;

  const {
    data: placeOfServiceData,
    loading: placeOfServiceLoading,
    error: getPlaceOfServiceError,
  } = useGetPlaceOfServices({
    queryParams: {
      healthPlanName: patientHealthPlanName || undefined,
      encounterType: formContent.isInpatient ? "INPATIENT" : "OUTPATIENT",
      clinicalServiceId: generalAuthSubmissionWorkflowEnabled ? clinicalServiceId : clinicalService?.id,
    },
  });

  useEffect(() => {
    // clear placeOfService if it is not in any of the available options
    if (
      formContent.placeOfService &&
      (placeOfServiceData?.length || 0) > 0 &&
      !placeOfServiceData?.find(({ id }) => id === formContent.placeOfService?.id)
    ) {
      setFormContent((prev) => ({ ...prev, placeOfService: null }));
    }
  }, [formContent.placeOfService, placeOfServiceData, setFormContent]);

  // General use variables
  const singleService = clinicalService?.isSingleService;
  const shouldShowSingleServiceSelect = !Boolean(clinicalService) || singleService;

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (getPlaceOfServiceError) {
      enqueueSnackbar(`Error getting places of service ${getPlaceOfServiceError.message}`, { variant: "error" });
    }
  }, [getPlaceOfServiceError, enqueueSnackbar]);

  // update default reference values whenever selecting new clinicalService within the form
  const { suggestedPriorAuthRequirements, suggestedFormContent } = useContext(SuggestionContext);
  useEffect(() => {
    if (singleService && clinicalService && populateDefaults) {
      setFormContent({
        ...formContent,
        // If we suggested a pos and it exists in the available pos' for the newly selected
        // clinical service, autofill the suggested pos
        placeOfService:
          suggestedFormContent?.placeOfService &&
          placeOfServiceData?.find(({ id }) => id === formContent.placeOfService?.id)
            ? formContent?.placeOfService
            : clinicalService?.defaultPlaceOfService || null,
        isRecurring: simplifiedServiceFrequency ? undefined : false,
        units: `${clinicalService?.defaultUnits ? clinicalService?.defaultUnits : 0}`,
        unitType: simplifiedServiceFrequency ? undefined : clinicalService?.defaultUnitType,
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicalService, singleService]);

  const [initialServiceRequest] = useState(() => ({
    ...formContentToSR(formContent, simplifiedServiceFrequency),
    authStatus: currAuthStatus,
    workflowId: workflowId,
  }));
  const { fieldsToShowEditingApprovedSRWarning, minNumUnits } = useEditsOnApprovedSRState({
    currentFormContent: formContent,
    initialServiceRequest,
  });

  // Logic and other helper functions related to validation
  // In most cases we don't want to default to showing an error state unless if the user tried to submit
  const [hasValidCoverageDates, setHasValidCoverageDates] = useState<boolean>(false);
  const hasClinicalService = Boolean(clinicalService);
  const hasEncounterType = typeof formContent.isInpatient !== "undefined";
  const hasPlaceOfService = Boolean(formContent.placeOfService);
  const hasPrimaryDiagnosis = Boolean(formContent.primaryDiagnosisCode);
  const doesNotExceedMaxSecondaryDiagnoses = !!formContent?.secondaryDiagnosisCodes
    ? formContent?.secondaryDiagnosisCodes?.length <= MAX_SECONDARY_DIAGNOSES
    : true;
  const hasSecondaryDiagnoses = !!formContent?.secondaryDiagnosisCodes
    ? formContent?.secondaryDiagnosisCodes?.length > 0
    : true;
  const procedureCodesValid =
    formContent.procedureCodes.length > 0 &&
    (formContent.procedureCodes.length <= 10 || patientHealthPlanName !== HUMANA_HEALTH_PLAN_NAME);
  const noNonPalProcedureCodesIfOutpatient =
    formContent.procedureCodes.filter((px) => nonPalProcedureCodes?.some(({ code }) => code === px.code)).length ===
      0 ||
    formContent.isInpatient ||
    formContent.userSelectedNonPalCode;
  const startDateExceedsMinStartDate = formContent.startDate >= MIN_START_DATE;
  const hasActualStartDateValue = clearDefaultStartDateValue ? Boolean(actualStartDateValue) : true;
  const hasStartDate = hasActualStartDateValue && Boolean(formContent.startDate);
  const hasValidStartDate = (formContent.startDate ?? MAX_DATE_SELECT_DATE) <= MAX_DATE_SELECT_DATE;
  const hasValidEndDate = (formContent.endDate ?? MAX_DATE_SELECT_DATE) <= MAX_DATE_SELECT_DATE;
  const endDateExceedsStartDate = formContent.endDate ? formContent.endDate >= formContent.startDate : true;
  //TODO remove all isRecurring validation logic with removal of simplifiedServiceFrequency
  const showEndDate =
    formContent.isRecurring ||
    (simplifiedServiceFrequency && Number(formContent?.units) !== 1) ||
    (formContent.procedureCodes.some((code) => code.units && code.units > 1) &&
      formConfiguration.units.fieldSpec === "OMIT");
  const showUnits = formContent.isRecurring || simplifiedServiceFrequency;
  const hasRecurrenceType = typeof formContent.isRecurring !== "undefined";
  const hasEndDateIfApplicable = !showEndDate || Boolean(formContent.endDate);
  const hasUnitsIfApplicable = !showUnits || Boolean(formContent.units);
  const validUnits =
    !showUnits ||
    (parseInt(formContent.units) > Math.max(0, minNumUnits - 1) && Number.isInteger(Number(formContent.units)));
  const hasUnitTypeIfApplicable = simplifiedServiceFrequency || !showUnits || Boolean(formContent.unitType);
  const hasExpeditedReasonIfExpedited = !formContent.isExpedited || !!formContent.expeditedReason;
  const hasOrderingProvider = Boolean(formContent.orderingProvider);
  const hasPerformingProvider = Boolean(formContent.performingProvider);
  const hasFacility = Boolean(formContent.facility);
  const hasValidBehavioralHealthAdmissionType = formContent?.bHAdmissionType !== undefined;
  const hasValidBehavioralHealthReviewType = formContent?.bHReviewType !== undefined;
  const expectedDischargeExceedsAdmissionDate =
    formContent.expectedDischargeDate && formContent.admissionDate
      ? formContent.expectedDischargeDate >= formContent.admissionDate
      : false;
  const admissionDateExceedsMinAdmissionDate = formContent.admissionDate
    ? formContent.admissionDate >= MIN_START_DATE
    : false;
  const hasFollowUpFaxNumber = Boolean(formContent?.followUpFaxNumber?.number);
  const followUpFaxNumberHasAcceptableDigits =
    !hasFollowUpFaxNumber || formContent?.followUpFaxNumber?.number?.length === 10;
  const faxNumberIsAutofilled =
    (userFaxExtension?.length || 0) > 0 && userFaxExtension === formContent.followUpFaxNumber?.number;
  // This weird condition may be due to something broken within react. Refer https://github.com/facebook/react/issues/21328 or https://github.com/facebook/react/issues/19476
  // Upgrading typescript eslint did not do the trick

  const hasValidPxUnits =
    formContent &&
    formContent.procedureCodes.every(
      (procedureCode) =>
        procedureCode.units &&
        procedureCode.units > 0 &&
        (procedureCode.units <= (procedureCode.maxUnits ?? 1) * Number(formContent.units) ||
          formContent?.clinicalService?.isUnitsOnPx)
    );
  const hasValidProcedureCodeWithUnits = formContent && formContent.procedureCodes.length > 0 && hasValidPxUnits;
  const hasFacilityNpi = Boolean(formContent.facility?.npi);
  const hasPerformingProviderNpi = Boolean(formContent.performingProvider?.npi);
  const hasOrderingProviderNpi = Boolean(formContent.orderingProvider?.npi);
  const hasFacilityTin = formContent?.facility?.tinList?.length === 0 ? true : Boolean(formContent.facilitySelectedTin);
  const performingProviderTinLength = formContent.performingProvider?.tinList?.length;
  const hasPerformingProviderTin =
    performingProviderTinLength === 0 ? true : Boolean(formContent.performingProviderSelectedTin);
  const hasFacilityAddress =
    formContent?.facility?.locations === undefined ? true : Boolean(formContent.facilitySelectedAddress);
  const hasPerformingProviderAddress =
    formContent?.performingProvider?.locations === undefined
      ? true
      : Boolean(formContent.performingProviderSelectedAddress);
  const hasOrderingProviderTin =
    formContent?.orderingProvider?.tinList?.length === 0 ? true : Boolean(formContent.orderingProviderSelectedTin);
  const hasOrderingProviderAddress =
    formContent?.orderingProvider?.locations === undefined
      ? true
      : Boolean(formContent.orderingProviderSelectedAddress);
  const hasFacilityOutOfNetworkExceptionReason =
    !!facilityOonCheckData?.isExceptionRequired && !canSkipOon
      ? Boolean(formContent?.facilitySelectedAddress?.outOfNetworkExceptionReason) &&
        facilityOonCheckData?.isExceptionRequired
      : true;
  const hasPPOutOfNetworkExceptionReason =
    !!providerOonCheckData?.isExceptionRequired && !canSkipOon
      ? Boolean(formContent?.performingProviderSelectedAddress?.outOfNetworkExceptionReason) &&
        providerOonCheckData?.isExceptionRequired
      : true;

  const hasPPPracticeOutOfNetworkExceptionReason =
    !!performingProviderPracticeOonCheckData?.isExceptionRequired && !canSkipOon
      ? Boolean(formContent?.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionReason) &&
        performingProviderPracticeOonCheckData?.isExceptionRequired
      : true;
  const hasOrderingProviderOutOfNetworkExceptionReason =
    !!orderingProviderOonCheckData?.isExceptionRequired && !canSkipOon
      ? Boolean(formContent?.orderingProviderSelectedAddress?.outOfNetworkExceptionReason) &&
        orderingProviderOonCheckData?.isExceptionRequired
      : true;

  const requiresFacilityOONExceptionComment =
    formContent.selectedFacility?.selectedLocation?.isOutOfNetwork &&
    formContent.selectedFacility?.selectedLocation?.outOfNetworkExceptionReason === "Other (comment is required)";

  const requiresPerformingProviderOONExceptionComment =
    formContent.selectedPerformingProvider?.selectedLocation?.isOutOfNetwork &&
    formContent.selectedPerformingProvider?.selectedLocation?.outOfNetworkExceptionReason ===
      "Other (comment is required)";

  const requiresPerformingProviderPracticeOONExceptionComment =
    formContent.selectedPerformingProviderPractice?.selectedLocation?.isOutOfNetwork &&
    formContent.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionReason ===
      "Other (comment is required)";

  const requiresOrderingProviderOONExceptionComment = isExceptionCommentRequired(formContent);

  const hasFacilityOONExceptionComment = formContent.selectedFacility?.selectedLocation?.outOfNetworkExceptionComment;

  const hasPerformingProviderOONExceptionComment =
    formContent.selectedPerformingProvider?.selectedLocation?.outOfNetworkExceptionComment;

  const hasPerformingProviderPracticeOONExceptionComment =
    formContent.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionComment;

  const hasOrderingProviderOONExceptionComment =
    formContent.selectedOrderingProvider?.selectedLocation?.outOfNetworkExceptionComment;

  const { showExceededDurationLimitMessageErrorState } = useCalculateDateSelectionMessageDisplay(
    formContent,
    patient?.coverages || [],
    patient ? patient : null,
    formConfiguration
  );

  const VALIDATION_MAP: Record<ServiceRequestFieldName, boolean> = {
    selectedDetails: true,
    expeditedTatUpdateTimestamp: true,
    singleClinicalService: fieldIsValid(formConfiguration.singleClinicalService, hasClinicalService),
    encounterType: fieldIsValid(formConfiguration.encounterType, hasEncounterType),
    placeOfService: fieldIsValid(formConfiguration.placeOfService, hasPlaceOfService),
    primaryDiagnosis: fieldIsValid(formConfiguration.primaryDiagnosis, hasPrimaryDiagnosis),
    secondaryDiagnoses: fieldIsValid(
      formConfiguration.secondaryDiagnoses,
      hasSecondaryDiagnoses,
      doesNotExceedMaxSecondaryDiagnoses
    ),
    procedureCodes: fieldIsValid(
      formConfiguration.procedureCodes,
      procedureCodesValid,
      noNonPalProcedureCodesIfOutpatient
    ),
    recurring: fieldIsValid(formConfiguration.recurring, hasRecurrenceType) || simplifiedServiceFrequency,
    startEndDate: fieldIsValid(
      formContent.isInpatient ? { fieldSpec: "OMIT" } : formConfiguration.startEndDate,
      hasStartDate && hasEndDateIfApplicable,
      startDateExceedsMinStartDate &&
        endDateExceedsStartDate &&
        hasValidCoverageDates &&
        hasValidStartDate &&
        hasValidEndDate
    ),
    units: fieldIsValid(
      formContent.isInpatient ? { fieldSpec: "OMIT" } : formConfiguration.units,
      hasUnitsIfApplicable && hasUnitTypeIfApplicable,
      validUnits
    ),
    orderingProvider: fieldIsValid(formConfiguration.orderingProvider, hasOrderingProvider),
    performingProvider: fieldIsValid(formConfiguration.performingProvider, hasPerformingProvider),
    facility: fieldIsValid(formConfiguration.facility, hasFacility),
    urgency: fieldIsValid(
      formConfiguration.urgency,
      suggestedFormContent?.isExpedited !== undefined || hasExpeditedReasonIfExpedited
    ),
    behavioralHealthAdmissionType: fieldIsValid(
      formConfiguration.behavioralHealthAdmissionType,
      hasValidBehavioralHealthAdmissionType
    ),
    behavioralHealthReviewType: fieldIsValid(
      formConfiguration.behavioralHealthReviewType,
      hasValidBehavioralHealthReviewType
    ),
    admissionDischargeDate: fieldIsValid(
      formConfiguration.admissionDischargeDate,
      expectedDischargeExceedsAdmissionDate && admissionDateExceedsMinAdmissionDate
    ),
    faxInputField: fieldIsValid(
      formConfiguration.faxInputField,
      hasFollowUpFaxNumber,
      followUpFaxNumberHasAcceptableDigits
    ),
    procedureCodeWithUnits: fieldIsValid(formConfiguration.procedureCodeWithUnits, hasValidProcedureCodeWithUnits),
    prescribedDrug: fieldIsValid(formConfiguration.prescribedDrug),
    facilityAddress: fieldIsValid(formConfiguration.facilityAddress, hasFacilityAddress),
    facilityNPI: fieldIsValid(formConfiguration.facilityNPI, hasFacilityNpi),
    facilityTIN: fieldIsValid(formConfiguration.facilityTIN, hasFacilityTin),
    performingProviderAddress: validatePerformingProviderAddress(
      formConfiguration,
      formContent,
      patientHealthPlanName,
      hasPerformingProviderAddress
    ),
    performingProviderTIN: validatePerformingProviderTin(
      formConfiguration,
      formContent,
      performingProviderTinLength,
      hasPerformingProviderTin
    ),
    performingProviderNPI: validatePerformingProviderNPI(formConfiguration, formContent, hasPerformingProviderNpi),
    orderingProviderAddress: fieldIsValid(formConfiguration.orderingProviderAddress, hasOrderingProviderAddress),
    orderingProviderTIN: fieldIsValid(formConfiguration.orderingProviderTIN, hasOrderingProviderTin),
    orderingProviderNPI: fieldIsValid(formConfiguration.orderingProviderNPI, hasOrderingProviderNpi),
    outOfNetworkCheck: true,
    facilityOutOfNetworkStatusDisplay: true,
    performingProviderOutOfNetworkStatusDisplay: true,
    orderingProviderOutOfNetworkStatusDisplay: true,
    careParticipantOutOfNetworkStatusDisplay: true,
    performingProviderPracticeOutOfNetworkStatusDisplay: true,
    outOfNetworkOrderingProvider: true,
    facilityOutOfNetworkExceptionReason:
      fieldIsValid(formConfiguration.facilityOutOfNetworkExceptionReason) && hasFacilityOutOfNetworkExceptionReason,
    performingProviderOutOfNetworkExceptionReason:
      fieldIsValid(formConfiguration.performingProviderOutOfNetworkExceptionReason) && hasPPOutOfNetworkExceptionReason,
    performingProviderPracticeOutOfNetworkExceptionReason:
      fieldIsValid(formConfiguration.performingProviderPracticeOutOfNetworkExceptionReason) &&
      hasPPPracticeOutOfNetworkExceptionReason,
    orderingProviderOutOfNetworkExceptionReason:
      fieldIsValid(formConfiguration.orderingProviderOutOfNetworkExceptionReason) &&
      hasOrderingProviderOutOfNetworkExceptionReason,
    facilityOutOfNetworkExceptionComment:
      (requiresFacilityOONExceptionComment && !!hasFacilityOONExceptionComment) || !requiresFacilityOONExceptionComment,
    performingProviderOutOfNetworkExceptionComment:
      (requiresPerformingProviderOONExceptionComment && !!hasPerformingProviderOONExceptionComment) ||
      !requiresPerformingProviderOONExceptionComment,
    performingProviderPracticeOutOfNetworkExceptionComment:
      (requiresPerformingProviderPracticeOONExceptionComment && !!hasPerformingProviderPracticeOONExceptionComment) ||
      !requiresPerformingProviderPracticeOONExceptionComment,
    orderingProviderOutOfNetworkExceptionComment:
      (requiresOrderingProviderOONExceptionComment && !!hasOrderingProviderOONExceptionComment) ||
      !requiresOrderingProviderOONExceptionComment,
    careParticipantOutOfNetworkExceptionReason: formContent.additionalCareParticipants
      ? validateExceptionReasonOnSRforCareParticipants(
          formContent.additionalCareParticipants,
          canSkipOon,
          careParticipantOonCheckData
        )
      : true,
    careParticipantOutOfNetworkExceptionComment: formContent.additionalCareParticipants
      ? validateOONExceptionCommentFieldForCareParticipants(formContent.additionalCareParticipants)
      : true,
    userDeclaredOONException: true,
    patientStayDateRanges: fieldIsValid(formConfiguration.patientStayDateRanges),
    authCategory: fieldIsValid(formConfiguration.authCategory),
    authSubCategory: fieldIsValid(formConfiguration.authSubCategory),
    admissionDate: fieldIsValid(formConfiguration.admissionDate),
    admissionTime: fieldIsValid(formConfiguration.admissionTime),
    dischargeTime: fieldIsValid(formConfiguration.dischargeTime),
    dischargeDate: fieldIsValid(formConfiguration.dischargeDate),
    dischargedTo: fieldIsValid(formConfiguration.dischargedTo),
    expectedAdmissionDate: fieldIsValid(formConfiguration.expectedAdmissionDate),
    expectedDischargeDate: fieldIsValid(formConfiguration.expectedDischargeDate),
    additionalCareParticipants: true,
    nonPalCheckbox: true,
    blockUserIfExceedsRecommendedEndDate: fieldIsValid(
      formConfiguration.blockUserIfExceedsRecommendedEndDate,
      !showExceededDurationLimitMessageErrorState
    ),
    blockUserIfLessThanRecommendedEndDate: fieldIsValid(
      formConfiguration.blockUserIfLessThanRecommendedEndDate,
      !showExceededDurationLimitMessageErrorState
    ),
    userSelectedOONException: true,
    performingProviderPracticeSelectedTIN: true,
    performingProviderPracticeSelectedAddress: true,
    performingProviderPractice: true,
    performingProviderPracticeSelectedNPI: true,
    admissionSource: true,
    patientStatus: true,
  };

  const { shouldWaitForRuleRunResult, dispatchUrgencyRuleCheck, redirectModalProps, dispatchRedirectRuleCheck } =
    useServiceRequestFormRedirectDispatch({
      workflowId,
      formContent,
      healthPlanName: patientHealthPlanName,
      patient,
      clinicalService,
    });

  const validateFields = () => {
    if (shouldWaitForRuleRunResult) {
      return false;
    }

    const formConfig = formConfiguration;
    if (formConfig) {
      const formConfigKeys = Object.keys(formConfig) as ServiceRequestFieldName[];
      for (const key of formConfigKeys) {
        if (!VALIDATION_MAP[key]) {
          return false;
        }
      }
      return true;
    }
    //should never happen, but if it does we should block the user from trying to submit
    return false;
  };

  const isValid = validateFields();

  const handleFormValidation = useCallback(
    (isFormValid: boolean) => {
      if (attemptedSubmit) {
        if (
          formContent.procedureCodes.filter((px) => nonPalProcedureCodes?.some(({ code }) => code === px.code))
            .length !== 0 &&
          !formContent.isInpatient &&
          !formContent.userSelectedNonPalCode
        ) {
          setNonPalModalOpen(true);
        }
      }
      setCanBeSubmitted(isFormValid);
    },
    [setCanBeSubmitted, attemptedSubmit, setNonPalModalOpen, formContent, nonPalProcedureCodes]
  );

  useEffect(() => {
    handleFormValidation(isValid);
  }, [isValid, handleFormValidation]);

  const [sameProviders, setSameProviders] = useState(false);

  useEffect(() => {
    if (formContent.isInpatient && formConfiguration.admissionDischargeDate.fieldSpec !== "OMIT") {
      setFormContent((oldContents) => ({
        ...oldContents,
        admissionDate: oldContents.admissionDate || oldContents.startDate,
      }));
    }
    if (!formContent.isInpatient && formConfiguration.admissionDischargeDate.fieldSpec === "OMIT") {
      setFormContent((oldContents) => ({
        ...oldContents,
        admissionDate: undefined,
        expectedDischargeDate: undefined,
      }));
    }
    if (formConfiguration.facility.fieldSpec === "OMIT") {
      setFormContent((oldContents) => ({
        ...oldContents,
        facility: null,
        facilitySelectedTin: null,
        facilitySelectedAddress: null,
      }));
    }
    if (formConfiguration.performingProvider.fieldSpec === "OMIT") {
      setFormContent((oldContents) => ({
        ...oldContents,
        performingProvider: null,
        performingProviderSelectedTin: null,
        performingProviderSelectedAddress: null,
      }));
    }
  }, [
    formContent.isInpatient,
    formConfiguration.admissionDischargeDate.fieldSpec,
    setFormContent,
    formConfiguration.facility.fieldSpec,
    formConfiguration.performingProvider.fieldSpec,
    suggestedFormContent,
  ]);

  useEffect(() => {
    if (!formContent.isRecurring && !simplifiedServiceFrequency) {
      setFormContent({ ...formContent, units: "0", unitType: undefined, endDate: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContent.isRecurring, simplifiedServiceFrequency]);

  useEffect(() => {
    /*
      when units are <= 1, the end date does not display on the UI, and we should
      also remove it from state so that it is not persisted to the BE

      wehn units are > 1, ensure to set the suggested endDate if it exists
    */
    if (simplifiedServiceFrequency && Number(formContent.units) === 1) {
      setFormContent({ ...formContent, endDate: undefined });
    } else if (simplifiedServiceFrequency && Number(formContent.units) > 1 && !formContent.endDate) {
      setFormContent({ ...formContent, endDate: suggestedFormContent?.endDate?.dateEntity });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContent.units, simplifiedServiceFrequency]);

  useEffect(() => {
    if (
      formContent.performingProvider?.manuallyCreated &&
      formContent.orderingProvider?.manuallyCreated &&
      formContent.orderingProvider.id === formContent.performingProvider.id
    ) {
      setSameProviders(true);
    }
  }, [formContent, setSameProviders]);

  const availablePlacesOfService = placeOfServiceData && placeOfServiceData.length > 0;
  const { tooltipIcon, ...radioGroupLabelClasses } = useSRFormRadioGroupHeaderStyles();

  useCheckServiceRequestProcedureCodes({
    procedureCodes: formContent.procedureCodes,
    palPxCodes,
    isInpatient: formContent.isInpatient,
    clinicalService,
    dispatchRedirectRuleCheck,
    placeOfServiceId: formContent.placeOfService?.id,
    startDate: formContent.startDate,
    patient,
    workflowId,
  });

  /**
   * This sets the service request form content, but it also runs the onUserEdit callback.
   *
   * This is useful if we want to do something after the user explicitly edits the service request form content, not
   * just whenever the serviceRequestForm content changes (like from a PAR check effect or whatever)
   * @param newFormContent
   */
  const setFormContentOnUserEdit: Dispatch<SetStateAction<ServiceRequestFormContent>> = useCallback(
    (setStateAction) => {
      // In order to get the next state value to pass to onUserEdit, we actually need to call setFormContent
      // because we might in fact have a function, not a realized value
      setFormContent((prev) => {
        const newFormContent: ServiceRequestFormContent =
          typeof setStateAction === "function" ? setStateAction(prev) : setStateAction;
        onUserEdit?.(newFormContent);
        return newFormContent;
      });
    },
    [onUserEdit, setFormContent]
  );

  // checks if the performing provider has been cleared out to clear out the peforming provider outOfNetworkExceptionReason content
  useEffect(() => {
    if (!formContent.performingProvider && !!formContent?.performingProviderSelectedAddress) {
      setFormContent({
        ...formContent,
        performingProviderSelectedTin: null,
        performingProviderSelectedAddress: null,
      });
    }
  }, [formContent, setFormContent]);

  // checks if the facility has been cleared out to clear out the facility outOfNetworkExceptionReason content
  useEffect(() => {
    if (!formContent.facility && !!formContent?.facilitySelectedAddress) {
      setFormContent({ ...formContent, facilitySelectedTin: null, facilitySelectedAddress: null });
    }
  });

  // checks if the ordering provider has been cleared out to clear out the ordering provider outOfNetworkExceptionReason content
  useEffect(() => {
    if (!formContent.orderingProvider && !!formContent?.orderingProviderSelectedAddress) {
      setFormContent({
        ...formContent,
        orderingProviderSelectedTin: null,
        orderingProviderSelectedAddress: null,
        orderingProviderOONExceptionRequired: false,
      });
    }
  });

  // if the performing provider is changed while the sameProviders checkbox is checked, uncheck the checkbox
  useEffect(() => {
    const providersDiffNPI = formContent.orderingProvider?.npi !== formContent.performingProvider?.npi;
    const providersDiffTIN = formContent.orderingProviderSelectedTin !== formContent.performingProviderSelectedTin;
    const providersDiffAddress =
      formContent.orderingProviderSelectedAddress !== formContent.performingProviderSelectedAddress;
    if (sameProviders && (providersDiffNPI || providersDiffTIN || providersDiffAddress)) {
      setSameProviders(false);
    }
  }, [
    formContent.orderingProviderSelectedTin,
    formContent.performingProviderSelectedTin,
    formContent.orderingProviderSelectedAddress,
    formContent.performingProviderSelectedAddress,
    sameProviders,
    formContent.orderingProvider?.npi,
    formContent.performingProvider?.npi,
  ]);

  const { doPalCheck } = useDoPalCheckOnServiceRequestForm({
    patientId: patient?.id || "",
    isInpatient: formContent.isInpatient,
    startDate: formContent.startDate,
    palProcedureCodes: palPxCodes,
    setProcedureCodes: (codes) => setFormContentOnUserEdit((prev) => ({ ...prev, procedureCodes: codes })),
    setPalProcedureCodes,
    setNonPalProcedureCodes,
    setNonCohereProcedureCodes,
  });
  const disableForApprovedOutpatient = !formContent.isInpatient && currAuthStatus === "APPROVED";

  const [isStartDateInRange, setIsStartDateInRange] = useState(true);
  const [isEndDateInRange, setIsEndDateInRange] = useState(true);

  const startDateCoverage = useMemo(() => {
    if (patient?.coverages) {
      if (formContent?.admissionDate) {
        return checkOnetimeRequestCoverage(patient?.coverages, formContent?.admissionDate);
      } else if (formContent?.startDate) {
        return checkOnetimeRequestCoverage(patient?.coverages, formContent?.startDate);
      }
    }
    return undefined;
  }, [patient?.coverages, formContent?.startDate, formContent?.admissionDate]);

  const endDateCoverage = useMemo(() => {
    if (patient?.coverages) {
      if (formContent?.dischargeDate) {
        return checkOnetimeRequestCoverage(patient?.coverages, formContent?.dischargeDate);
      } else if (formContent?.endDate) {
        return checkOnetimeRequestCoverage(patient?.coverages, formContent?.endDate);
      }
    }
    return undefined;
  }, [patient?.coverages, formContent?.endDate, formContent?.dischargeDate]);

  const dateChangeValidator = () => {
    if (!startDateCoverage?.inRange) {
      setIsStartDateInRange(false);
    }
    if (!endDateCoverage?.inRange) {
      setIsEndDateInRange(false);
    }
  };

  const { isFieldDisabled } =
    useGetServiceRequestStateConfigurationByPayerAndAuthStatus({
      healthPlanName: patientHealthPlanName || "",
      authStatus: currAuthStatus,
      delegatedVendorName: delegatedVendorName || patientRiskBearingEntity || "",
      requestType: "INITIAL",
    }) || {};

  return (
    <>
      <Grid container spacing={2}>
        {shouldShowSingleServiceSelect && (
          <SRFormConfigFieldWrapper {...formConfiguration.singleClinicalService}>
            <Row>
              <SingleClinicalServiceSelect
                disableHelperText={!(attemptedSubmit && !VALIDATION_MAP["singleClinicalService"])}
                error={attemptedSubmit && !VALIDATION_MAP["singleClinicalService"]}
                clinicalService={formContent.clinicalService || null}
                setClinicalService={(clinicalService) => {
                  setClinicalService(clinicalService || undefined);
                  setFormContentOnUserEdit({ ...formContent, clinicalService: clinicalService || undefined });
                }}
                patient={patient || undefined}
                startDate={formContent.startDate}
                codes={formContent.procedureCodes}
                disabled={isFieldDisabled("singleClinicalService")}
              />
            </Row>
          </SRFormConfigFieldWrapper>
        )}
        <SRFormConfigFieldWrapper {...formConfiguration.encounterType}>
          <Row style={{ marginTop: spacing(3) }}>
            <CareTypeSelect
              inpatientValue={formContent.isInpatient}
              formContent={formContent}
              authStatus={currAuthStatus}
              patientHealthPlanName={patientHealthPlanName || ""}
              onCareTypeSwitch={(val: string) => {
                onCareTypeSwitch(val, setFormContentOnUserEdit);
              }}
              disabled={isFieldDisabled("encounterType")}
            />
            {!availablePlacesOfService && !placeOfServiceLoading && (
              <>
                <Caption style={{ color: colorsLight.error.dark }}>
                  {`${formContent.isInpatient ? "Inpatient" : "Outpatient"}` +
                    ` places of service are not accepted for this procedure. `}
                </Caption>
                <br />
                <Caption style={{ color: colorsLight.error.dark }}>
                  {`Please update the request to ${formContent.isInpatient ? "outpatient" : "inpatient"}.`}
                </Caption>
              </>
            )}
          </Row>
        </SRFormConfigFieldWrapper>
        <SRFormConfigFieldWrapper {...formConfiguration.behavioralHealthAdmissionType}>
          <Row style={{ marginTop: spacing(3) }}>
            <BehavioralHealthAdmissionTypeSelect
              formContent={formContent}
              setFormContent={setFormContentOnUserEdit}
              error={attemptedSubmit && !VALIDATION_MAP["behavioralHealthAdmissionType"]}
              disabled={isFieldDisabled("behavioralHealthAdmissionType")}
            />
          </Row>
        </SRFormConfigFieldWrapper>
        <SRFormConfigFieldWrapper {...formConfiguration.behavioralHealthReviewType}>
          <Row style={{ marginTop: spacing(3) }}>
            <BehavioralHealthReviewTypeSelect
              formContent={formContent}
              setFormContent={setFormContentOnUserEdit}
              error={attemptedSubmit && !VALIDATION_MAP["behavioralHealthReviewType"]}
              disabled={isFieldDisabled("behavioralHealthReviewType")}
            />
          </Row>
        </SRFormConfigFieldWrapper>
        <SRFormConfigFieldWrapper {...formConfiguration.placeOfService}>
          {availablePlacesOfService && (
            <Row>
              <PlaceOfServiceSelect
                error={attemptedSubmit && !VALIDATION_MAP["placeOfService"]}
                isCohereFaxForm={Boolean(isCohereFaxForm && initialServiceRequest.placeOfService)}
                authStatus={currAuthStatus}
                isInpatient={formContent.isInpatient}
                placeOfService={formContent?.placeOfService || null}
                startDate={formatDateToISODate(formContent.startDate)}
                setPlaceOfService={(pos) => setFormContentOnUserEdit({ ...formContent, placeOfService: pos })}
                clinicalService={clinicalService || undefined}
                patient={patient || undefined}
                dispatchRedirectRuleCheck={dispatchRedirectRuleCheck("encounter type")}
                procedureCodes={formContent.procedureCodes}
                availablePlacesOfService={placeOfServiceData || undefined}
                placeOfServiceLoading={placeOfServiceLoading}
                disabled={isFieldDisabled("placeOfService")}
              />
            </Row>
          )}
        </SRFormConfigFieldWrapper>
        {!Boolean(hideDiagnoses) && (
          <>
            <SRFormConfigFieldWrapper {...formConfiguration.primaryDiagnosis}>
              <Row>
                <PrimaryDiagnosisCodeSelect
                  isAutoFilled={
                    suggestedPriorAuthRequirements?.primaryDiagnosis?.diagnosisCodeEntity
                      ? suggestedPriorAuthRequirements.primaryDiagnosis.diagnosisCodeEntity.code ===
                        formContent?.primaryDiagnosisCode?.code
                      : false
                  }
                  isCohereFaxForm={Boolean(isCohereFaxForm && initialServiceRequest.primaryDiagnosis)}
                  error={attemptedSubmit && !VALIDATION_MAP["primaryDiagnosis"]}
                  initialPrimaryDiagnosisCode={initialServiceRequest.primaryDiagnosis}
                  singleService={singleService}
                  primaryDiagnosisCode={formContent?.primaryDiagnosisCode || null}
                  setPrimaryDiagnosisCode={(code) =>
                    setFormContentOnUserEdit({ ...formContent, primaryDiagnosisCode: code })
                  }
                  showApprovedSrEditWarning={fieldsToShowEditingApprovedSRWarning.includes("primaryDiagnosisCode")}
                  onCancelChange={() =>
                    setFormContentOnUserEdit({
                      ...formContent,
                      primaryDiagnosisCode: initialServiceRequest.primaryDiagnosis || null,
                    })
                  }
                  disableForApprovedOutpatient={disableForApprovedOutpatient}
                  disabled={isFieldDisabled("primaryDiagnosis")}
                />
              </Row>
            </SRFormConfigFieldWrapper>
            <SRFormConfigFieldWrapper {...formConfiguration.secondaryDiagnoses}>
              <Row>
                <SecondaryDiagnosisCodeSelect
                  isAutoFilled={
                    !!suggestedPriorAuthRequirements?.secondaryDiagnoses?.length
                      ? compareCodeListEqualityNoOrder(
                          suggestedPriorAuthRequirements.secondaryDiagnoses
                            .map((clientSV) => clientSV.diagnosisCodeEntity)
                            .filter(isNotNullOrUndefined),
                          formContent.secondaryDiagnosisCodes
                        )
                      : false
                  }
                  isCohereFaxForm={Boolean(
                    isCohereFaxForm && (initialServiceRequest?.secondaryDiagnoses?.length || 0) > 0
                  )}
                  error={
                    !doesNotExceedMaxSecondaryDiagnoses || (attemptedSubmit && !VALIDATION_MAP["secondaryDiagnoses"])
                  }
                  secondaryDiagnosisCodes={formContent?.secondaryDiagnosisCodes || []}
                  setSecondaryDiagnosisCodes={(codes) =>
                    setFormContentOnUserEdit({
                      ...formContent,
                      secondaryDiagnosisCodes: codes,
                    })
                  }
                  isOptional={formConfiguration.secondaryDiagnoses.fieldSpec === "OPTIONAL"}
                  disabled={isFieldDisabled("secondaryDiagnoses")}
                />
              </Row>
            </SRFormConfigFieldWrapper>
          </>
        )}
        {generalAuthSubmissionWorkflowEnabled && (
          <SRFormConfigFieldWrapper {...formConfiguration.userDeclaredOONException}>
            <Row>
              <Checkbox
                label="This is an out-of-network exception request"
                checked={formContent.userSelectedOONException || false}
                onChange={async (userSelectedOONExceptionValue) => {
                  setFormContent((prev) => {
                    return { ...prev, userSelectedOONException: userSelectedOONExceptionValue };
                  });
                  await doPalCheck(
                    formContent.procedureCodes.concat(nonPalProcedureCodes),
                    userSelectedOONExceptionValue ||
                      formContent.performingProviderOONExceptionRequired ||
                      formContent.facilityOONExceptionRequired ||
                      false
                  );
                }}
                disabled={isFieldDisabled("userDeclaredOONException")}
              />
            </Row>
          </SRFormConfigFieldWrapper>
        )}
        {formContent.isInpatient && (
          <>
            <Row>
              <H4 style={{ marginTop: spacing(3) }}>Service details</H4>
            </Row>
            <AdmissionAndExpectedDischargeDateSelect
              minAdmissionDate={MIN_START_DATE}
              admissionDate={formContent?.admissionDate}
              expectedAdmissionDate={formContent?.expectedAdmissionDate}
              expectedDischargeDate={formContent?.expectedDischargeDate}
              error={
                (attemptedSubmit && !VALIDATION_MAP["admissionDischargeDate"]) || startDateCoverage
                  ? !startDateCoverage?.inRange
                  : (attemptedSubmit && !formContent?.admissionDate) || endDateCoverage
                  ? !endDateCoverage?.inRange
                  : attemptedSubmit && !formContent?.dischargeDate
              }
              dateCreated={dateCreated}
              serviceType={formContent?.serviceType}
              formContent={formContent}
              attemptedSubmit={attemptedSubmit}
              startDateCoverage={startDateCoverage}
              endDateCoverage={endDateCoverage}
              dateChangeValidator={dateChangeValidator}
              isStartDateInRange={isStartDateInRange}
              isEndDateInRange={isEndDateInRange}
              patient={patient || null}
              isDraftAuth={["DRAFT", undefined].includes(formContent.authStatus)}
              showExceededDurationLimitMessageErrorState={false}
              setFormContent={setFormContent}
              setIsStartDateInRange={setIsStartDateInRange}
              setIsEndDateInRange={setIsEndDateInRange}
              formConfiguration={formConfiguration}
              disabled={isFieldDisabled("admissionDischargeDate")}
            />
          </>
        )}
        {simplifiedServiceFrequency && (
          <>
            <ServiceFrequencySection
              formConfigurations={{
                units: formConfiguration.units,
                startEndDate: formConfiguration.startEndDate,
              }}
              units={formContent.units}
              setUnits={(units) => {
                let procedureCodes = [...formContent.procedureCodes];
                procedureCodes = formContent.procedureCodes.map((procedureCode) => {
                  return {
                    ...procedureCode,
                    units: Number(units),
                  };
                });
                setFormContent({ ...formContent, units: units, procedureCodes: procedureCodes });
              }}
              initialUnits={
                initialServiceRequest?.units?.toString() || clinicalService?.defaultUnits?.toString() || "1"
              }
              defaultUnits={clinicalService?.defaultUnits}
              minNumUnits={minNumUnits}
              hasValidUnits={validUnits}
              showApprovedSrEditWarning={fieldsToShowEditingApprovedSRWarning.includes("units")}
              attemptedSubmit={attemptedSubmit}
              hasValidEndDate={
                (hasEndDateIfApplicable || formConfiguration.startEndDate.fieldSpec !== "REQUIRED") &&
                endDateExceedsStartDate &&
                hasValidEndDate
              }
              startDate={clearDefaultStartDateValue ? actualStartDateValue : formContent.startDate}
              minStartDate={MIN_START_DATE}
              setStartDate={(date) => {
                setActualStartDateValue(date);
                setFormContentOnUserEdit({
                  ...formContent,
                  startDate: date,
                  admissionDate: formConfiguration.admissionDischargeDate?.fieldSpec !== "OMIT" ? date : undefined,
                });
              }}
              endDate={formContent.endDate}
              setEndDate={(date) =>
                setFormContentOnUserEdit({ ...formContent, endDate: date || suggestedFormContent?.endDate })
              }
              shouldShowEndDate={showEndDate}
              isExpedited={formContent.isExpedited}
              isInpatient={formContent.isInpatient}
              serviceType={formContent.serviceType}
              clinicalServiceId={clinicalService?.id}
              serviceRequestId={formContent?.id}
              cohereAuthId={formContent?.cohereId}
              patient={patient || undefined}
              setHasValidCoverageDates={setHasValidCoverageDates}
              onCancelStartDateChange={() =>
                setFormContentOnUserEdit({
                  ...formContent,
                  startDate: formContent.startDate,
                })
              }
              onBlurServiceLevelUnits={onBlurServiceLevelUnits}
              setOnBlurServiceLevelUnits={setOnBlurServiceLevelUnits}
              endDateLabel={"Expected service end date"}
              startDateLabel={"Expected service start date"}
              unitLabel={"Number of service dates"}
              authStatus={currAuthStatus}
              unitsDisabled={isFieldDisabled("units")}
              startEndDateDisabled={isFieldDisabled("startEndDate")}
            />
          </>
        )}
        <SRFormConfigFieldWrapper {...formConfiguration.procedureCodeWithUnits}>
          {!simplifiedServiceFrequency && (
            <Row style={{ marginTop: spacing(3), marginBottom: spacing(3) }}>
              <H4>Procedure code details</H4>
            </Row>
          )}
          <Row style={{ marginTop: spacing(0) }}>
            <ProcedureCodeSelectTableWithUnits
              formContent={formContent}
              setFormContent={setFormContentOnUserEdit}
              patientId={patient?.id}
              clinicalServiceId={clinicalService?.id}
              palProcedureCodes={palPxCodes}
              setPalProcedureCodes={setPalProcedureCodes}
              setNonPalPDFOpen={setNonPalPDFOpen}
              showApprovedSrEditWarning={fieldsToShowEditingApprovedSRWarning.includes("procedureCodes")}
              attemptedSubmit={attemptedSubmit}
              onBlurServiceLevelUnits={onBlurServiceLevelUnits}
              nonPalProcedureCodes={nonPalProcedureCodes}
              setNonPalProcedureCodes={setNonPalProcedureCodes}
              nonCohereProcedureCodes={nonCohereProcedureCodes}
              setNonCohereProcedureCodes={setNonCohereProcedureCodes}
              healthPlanName={patientHealthPlanName}
              authStatus={currAuthStatus}
              disabled={isFieldDisabled("procedureCodeWithUnits")}
            />
          </Row>
        </SRFormConfigFieldWrapper>
        <SRFormConfigFieldWrapper {...formConfiguration.procedureCodes}>
          {!simplifiedServiceFrequency && (
            <Row style={{ marginTop: spacing(3), marginBottom: spacing(3) }}>
              <H4>Procedure code details</H4>
            </Row>
          )}
          <Row>
            <ProcedureCodeSelectTable
              formContent={formContent}
              setFormContent={setFormContentOnUserEdit}
              patientId={patient?.id}
              palProcedureCodes={palPxCodes}
              setPalProcedureCodes={setPalProcedureCodes}
              setNonPalPDFOpen={setNonPalPDFOpen}
              showApprovedSrEditWarning={fieldsToShowEditingApprovedSRWarning.includes("procedureCodes")}
              nonPalProcedureCodes={nonPalProcedureCodes}
              setNonPalProcedureCodes={setNonPalProcedureCodes}
              nonCohereProcedureCodes={nonCohereProcedureCodes}
              setNonCohereProcedureCodes={setNonCohereProcedureCodes}
              healthPlanName={patientHealthPlanName}
              authStatus={currAuthStatus}
              disabled={isFieldDisabled("procedureCodes")}
            />
          </Row>
        </SRFormConfigFieldWrapper>
        {!simplifiedServiceFrequency && (
          <>
            <SRFormConfigFieldWrapper {...formConfiguration.recurring}>
              <Row style={{ marginTop: spacing(3) }}>
                <RadioGroup
                  row
                  labelClasses={radioGroupLabelClasses}
                  label={<H4 style={{ marginBottom: spacing(2) }}>Service frequency</H4>}
                  options={[
                    { id: "false", label: "One-time" },
                    { id: "true", label: "Recurring" },
                  ]}
                  value={formContent.isRecurring ? "true" : "false"}
                  onChange={(val) => setFormContentOnUserEdit({ ...formContent, isRecurring: val === "true" })}
                  disabled={isFieldDisabled("recurring")}
                />
              </Row>
            </SRFormConfigFieldWrapper>
            <SRFormConfigFieldWrapper {...formConfiguration.startEndDate}>
              <StartAndEndDateSelect
                hasValidEndDate={
                  (hasEndDateIfApplicable || formConfiguration.startEndDate.fieldSpec !== "REQUIRED") &&
                  endDateExceedsStartDate &&
                  hasValidEndDate
                }
                startDate={formContent.startDate}
                minStartDate={MIN_START_DATE}
                setStartDate={(date) => {
                  setFormContentOnUserEdit({
                    ...formContent,
                    startDate: date,
                    admissionDate: formConfiguration.admissionDischargeDate?.fieldSpec !== "OMIT" ? date : undefined,
                  });
                }}
                endDate={formContent.endDate}
                setEndDate={(date) => setFormContentOnUserEdit({ ...formContent, endDate: date })}
                shouldShowEndDate={showEndDate}
                isExpedited={formContent.isExpedited}
                isInpatient={formContent.isInpatient}
                healthPlanName={patientHealthPlanName}
                serviceType={formContent.serviceType}
                clinicalServiceId={clinicalService?.id}
                serviceRequestId={formContent?.id}
                cohereAuthId={formContent?.cohereId}
                patient={patient || undefined}
                setHasValidCoverageDates={setHasValidCoverageDates}
                showApprovedSrEditWarning={fieldsToShowEditingApprovedSRWarning.includes("startDate")}
                onCancelStartDateChange={() =>
                  setFormContentOnUserEdit({
                    ...formContent,
                    startDate: formContent.startDate,
                  })
                }
                attemptedSubmit={attemptedSubmit}
                disabled={isFieldDisabled("startEndDate")}
              />
            </SRFormConfigFieldWrapper>
            {showUnits && (
              <SRFormConfigFieldWrapper {...formConfiguration.units}>
                <UnitWithUnitTypeSelect
                  hasValidUnits={validUnits}
                  hasValidUnitType={formConfiguration.units.fieldSpec !== "REQUIRED" || hasUnitTypeIfApplicable}
                  attemptedSubmit={attemptedSubmit}
                  units={formContent.units}
                  setUnits={(units) => setFormContentOnUserEdit({ ...formContent, units: units })}
                  unitType={formContent.unitType}
                  setUnitType={(type) => setFormContentOnUserEdit({ ...formContent, unitType: type })}
                  defaultUnits={clinicalService?.defaultUnits}
                  defaultUnitType={clinicalService?.defaultUnitType}
                  showApprovedSrEditWarning={fieldsToShowEditingApprovedSRWarning.includes("units")}
                  minNumUnits={minNumUnits}
                  initialUnits={
                    initialServiceRequest?.units?.toString() || clinicalService?.defaultUnits?.toString() || "0"
                  }
                  disabled={isFieldDisabled("units")}
                />
              </SRFormConfigFieldWrapper>
            )}
          </>
        )}
        <Row style={{ marginTop: spacing(3) }}>
          <H4>Provider details</H4>
        </Row>
        <SRFormConfigFieldWrapper {...formConfiguration.orderingProvider}>
          <FlexGridItem container spacing={2}>
            <OrderingProviderSelectManual
              error={attemptedSubmit && !VALIDATION_MAP["orderingProvider"]}
              npiError={attemptedSubmit && VALIDATION_MAP["orderingProvider"] && !VALIDATION_MAP["orderingProviderNPI"]}
              tinError={attemptedSubmit && !VALIDATION_MAP["orderingProviderTIN"]}
              addressError={attemptedSubmit && !VALIDATION_MAP["orderingProviderAddress"]}
              isCohereFaxForm={Boolean(isCohereFaxForm && initialServiceRequest.orderingProvider)}
              formContent={formContent}
              setFormContent={setFormContent}
              clinicalService={clinicalService || undefined}
              patient={patient || undefined}
              dispatchRedirectRuleCheck={dispatchRedirectRuleCheck("ordering provider")}
              sameProviders={sameProviders}
              setSameProviders={setSameProviders}
              showTinAndAddressField={
                patientHealthPlanName === "Geisinger"
                  ? formConfiguration.orderingProviderAddress.fieldSpec !== "OMIT"
                  : formConfiguration.orderingProviderAddress.fieldSpec === "REQUIRED"
              }
              formConfiguration={formConfiguration}
              disabled={isFieldDisabled("orderingProvider")}
            />
            <SRFormConfigFieldWrapper {...formConfiguration.performingProvider}>
              <Checkbox
                style={{ marginLeft: spacing(1), marginTop: spacing(-1), marginBottom: spacing(1) }}
                checked={sameProviders}
                onChange={(checked) => {
                  sameProviderCheckboxClick(setFormContentOnUserEdit, setSameProviders, checked);
                }}
                label="Performing is the same as ordering"
                disabled={isFieldDisabled("performingProvider")}
              />
            </SRFormConfigFieldWrapper>
          </FlexGridItem>
        </SRFormConfigFieldWrapper>
        <SRFormConfigFieldWrapper {...formConfiguration.performingProvider}>
          <FlexGridItem container spacing={2}>
            <PerformingProviderSelectManual
              providerError={attemptedSubmit && !VALIDATION_MAP["performingProvider"]}
              npiError={
                attemptedSubmit && VALIDATION_MAP["performingProvider"] && !VALIDATION_MAP["performingProviderNPI"]
              }
              tinError={attemptedSubmit && !VALIDATION_MAP["performingProviderTIN"]}
              addressError={attemptedSubmit && !VALIDATION_MAP["performingProviderAddress"]}
              isCohereFaxForm={Boolean(isCohereFaxForm && initialServiceRequest.performingProvider)}
              formContent={formContent}
              setFormContent={setFormContent}
              sameProviders={sameProviders}
              patient={patient || undefined}
              setSameProviders={setSameProviders}
              authStatus={currAuthStatus}
              isOptional={formConfiguration.performingProvider.fieldSpec === "OPTIONAL"}
              showAddressField={
                patientHealthPlanName === "Geisinger"
                  ? formConfiguration.performingProviderAddress.fieldSpec !== "OMIT"
                  : formConfiguration.performingProviderAddress.fieldSpec === "REQUIRED"
              }
              formConfiguration={formConfiguration}
              disabled={isFieldDisabled("performingProvider")}
            />
          </FlexGridItem>
        </SRFormConfigFieldWrapper>
        <SRFormConfigFieldWrapper {...formConfiguration.facility}>
          <FlexGridItem container spacing={2}>
            <FacilitySelectManual
              facilityError={attemptedSubmit && !VALIDATION_MAP["facilityNPI"]}
              tinError={attemptedSubmit && !VALIDATION_MAP["facilityTIN"]}
              addressError={attemptedSubmit && !VALIDATION_MAP["facilityAddress"]}
              isCohereFaxForm={Boolean(isCohereFaxForm && initialServiceRequest.facility)}
              formContent={formContent}
              setFormContent={setFormContent}
              isSingleService={singleService}
              patient={patient || undefined}
              isOptional={formConfiguration.facility.fieldSpec === "OPTIONAL"}
              showAddressField={formConfiguration.facilityAddress.fieldSpec === "REQUIRED"}
              formConfiguration={formConfiguration}
              disabled={isFieldDisabled("facility")}
            />
          </FlexGridItem>
        </SRFormConfigFieldWrapper>
        {(formConfiguration.facilityOutOfNetworkStatusDisplay.fieldSpec !== "OMIT" ||
          formConfiguration.orderingProviderOutOfNetworkStatusDisplay.fieldSpec !== "OMIT" ||
          formConfiguration.performingProviderOutOfNetworkStatusDisplay.fieldSpec !== "OMIT" ||
          formConfiguration.careParticipantOutOfNetworkStatusDisplay.fieldSpec !== "OMIT") && (
          <OONStatusDisplay
            performingProviderOON={formContent.performingProviderSelectedAddress?.isOutOfNetwork || false}
            performingProviderPracticeOON={
              formContent.selectedPerformingProviderPracticeAddress?.isOutOfNetwork || false
            }
            facilityOON={formContent.facilitySelectedAddress?.isOutOfNetwork || false}
            orderingProviderOON={formContent.orderingProviderSelectedAddress?.isOutOfNetwork || false}
            oonLoading={oonCheckLoading || false}
            inNetwork={
              // "in network" if the OON checks have completed, and none are out of network
              (oonCheckFieldsFilled || false) &&
              !formContent.performingProviderSelectedAddress?.isOutOfNetwork &&
              !formContent.facilitySelectedAddress?.isOutOfNetwork &&
              !formContent.orderingProviderSelectedAddress?.isOutOfNetwork
            }
            oonExceptionConfig={
              (providerOonCheckData?.isExceptionRequired && !canSkipOon) ||
              (facilityOonCheckData?.isExceptionRequired && !canSkipOon) ||
              (orderingProviderOonCheckData?.isExceptionRequired && !canSkipOon) ||
              false
                ? "REQUIRED"
                : "OPTIONAL"
            }
          ></OONStatusDisplay>
        )}
        {formContent.performingProviderSelectedAddress?.isOutOfNetwork && (
          <SRFormConfigFieldWrapper {...formConfiguration.performingProviderOutOfNetworkExceptionReason}>
            <Row style={{ marginTop: spacing(1) }}>
              <OonExceptionReason
                exceptionPracticeCommon="Performing Provider"
                exceptionReasons={OonExceptionReasonOptions(HUMANA_OON_PERFORMING_PROVIDER_EXCEPTION_REASONS)}
                isExceptionRequired={
                  (providerOonCheckData?.isExceptionRequired && !canSkipOon) ||
                  (facilityOonCheckData?.isExceptionRequired && !canSkipOon) ||
                  false
                }
                isCommentRequired={requiresPerformingProviderOONExceptionComment}
                data-tracking-id="performing-provider-exception-reason"
                error={attemptedSubmit && !VALIDATION_MAP["performingProviderOutOfNetworkExceptionReason"]}
                dropDownValue={formContent.performingProviderSelectedAddress?.outOfNetworkExceptionReason || ""}
                commentValue={formContent.performingProviderSelectedAddress?.outOfNetworkExceptionComment || ""}
                dropDownOnChange={(reason) =>
                  setFormContent({
                    ...formContent,
                    performingProviderSelectedAddress: {
                      ...formContent.performingProviderSelectedAddress,
                      outOfNetworkExceptionReason: reason,
                    },
                  })
                }
                commentOnChange={(comment) =>
                  setFormContent({
                    ...formContent,
                    performingProviderSelectedAddress: {
                      ...formContent.performingProviderSelectedAddress,
                      outOfNetworkExceptionComment: comment,
                    },
                  })
                }
                disabled={isFieldDisabled("performingProviderOutOfNetworkExceptionReason")}
              />
            </Row>
          </SRFormConfigFieldWrapper>
        )}
        {formContent.facilitySelectedAddress?.isOutOfNetwork && (
          <SRFormConfigFieldWrapper {...formConfiguration.facilityOutOfNetworkExceptionReason}>
            <Row style={{ marginTop: spacing(1) }}>
              <OonExceptionReason
                exceptionPracticeCommon="Facility"
                exceptionReasons={OonExceptionReasonOptions(HUMANA_OON_FACILITY_EXCEPTION_REASONS)}
                isExceptionRequired={(facilityOonCheckData?.isExceptionRequired && !canSkipOon) || false}
                isCommentRequired={requiresFacilityOONExceptionComment}
                data-tracking-id="facility-exception-reason"
                error={attemptedSubmit && !VALIDATION_MAP["facilityOutOfNetworkExceptionReason"]}
                dropDownValue={formContent.facilitySelectedAddress?.outOfNetworkExceptionReason || ""}
                commentValue={formContent.facilitySelectedAddress?.outOfNetworkExceptionComment || ""}
                dropDownOnChange={(reason) =>
                  setFormContent({
                    ...formContent,
                    facilitySelectedAddress: {
                      ...formContent.facilitySelectedAddress,
                      outOfNetworkExceptionReason: reason,
                    },
                  })
                }
                commentOnChange={(comment) =>
                  setFormContent({
                    ...formContent,
                    facilitySelectedAddress: {
                      ...formContent.facilitySelectedAddress,
                      outOfNetworkExceptionComment: comment,
                    },
                  })
                }
                disabled={isFieldDisabled("facilityOutOfNetworkExceptionReason")}
              />
            </Row>
          </SRFormConfigFieldWrapper>
        )}

        {formContent.orderingProviderSelectedAddress?.isOutOfNetwork && (
          <SRFormConfigFieldWrapper {...formConfiguration.orderingProviderOutOfNetworkExceptionReason}>
            <Row style={{ marginTop: spacing(1) }}>
              <OonExceptionReason
                exceptionPracticeCommon="Ordering Provider"
                exceptionReasons={OonExceptionReasonOptions(HUMANA_OON_PERFORMING_PROVIDER_EXCEPTION_REASONS)}
                isExceptionRequired={(orderingProviderOonCheckData?.isExceptionRequired && !canSkipOon) || false}
                isCommentRequired={requiresOrderingProviderOONExceptionComment}
                data-tracking-id="ordering-provider-exception-reason"
                error={attemptedSubmit && !VALIDATION_MAP["orderingProviderOutOfNetworkExceptionReason"]}
                dropDownValue={formContent.orderingProviderSelectedAddress?.outOfNetworkExceptionReason || ""}
                commentValue={formContent.orderingProviderSelectedAddress?.outOfNetworkExceptionComment || ""}
                dropDownOnChange={(reason) =>
                  setFormContent({
                    ...formContent,
                    orderingProviderSelectedAddress: {
                      ...formContent.orderingProviderSelectedAddress,
                      outOfNetworkExceptionReason: reason,
                    },
                  })
                }
                commentOnChange={(comment) =>
                  setFormContent({
                    ...formContent,
                    orderingProviderSelectedAddress: {
                      ...formContent.orderingProviderSelectedAddress,
                      outOfNetworkExceptionComment: comment,
                    },
                  })
                }
                disabled={isFieldDisabled("orderingProviderOutOfNetworkExceptionReason")}
              />
            </Row>
          </SRFormConfigFieldWrapper>
        )}

        <SRFormConfigFieldWrapper {...formConfiguration.faxInputField}>
          <Row style={{ marginTop: spacing(3) }}>
            <H4>Follow-up fax number</H4>
          </Row>
          <FlexGridItem container wrap="nowrap">
            <PhoneInput
              label="Follow-up fax number (10 digits)"
              value={formContent?.followUpFaxNumber || {}}
              onChange={(faxNumber) => setFormContentOnUserEdit({ ...formContent, followUpFaxNumber: faxNumber })}
              error={attemptedSubmit && !VALIDATION_MAP["faxInputField"]}
              helperText={attemptedSubmit && !VALIDATION_MAP["faxInputField"] && "Fax number must be 10 digits"}
              hideExtensionField
              isAutofilled={faxNumberIsAutofilled}
              width={274}
              disabled={isFieldDisabled("faxInputField")}
            />
            <StyledOscarFaxInputCaption>
              Enter the fax number intended to receive follow up questions and determination letters (typically the
              requesting provider’s fax number)
            </StyledOscarFaxInputCaption>
          </FlexGridItem>
        </SRFormConfigFieldWrapper>
        {!hideExpeditedRequestCheckbox && (
          <>
            <Row style={{ marginTop: spacing(4) }}>
              <Divider />
            </Row>
            <SRFormConfigFieldWrapper {...formConfiguration.urgency}>
              <Row xs={4} style={{ paddingBottom: 0 }}>
                {formContent.id && (
                  <ExistingRequestExpedited
                    isExpedited={formContent.isExpedited}
                    setIsExpedited={(isExpedited: boolean, reason: string) => {
                      dispatchUrgencyRuleCheck(isExpedited);
                      setFormContentOnUserEdit({ ...formContent, isExpedited: isExpedited, expeditedReason: reason });
                    }}
                    authStatus={currAuthStatus}
                    serviceRequestId={formContent.id}
                    patientId={patient?.id}
                    startDate={formContent.startDate}
                    patientHealthPlanName={patientHealthPlanName || undefined}
                    disabled={isFieldDisabled("urgency")}
                  />
                )}
                {!Boolean(formContent.id) && (
                  <RequestExpedited
                    isExpedited={formContent.isExpedited}
                    setIsExpedited={(isExpedited: boolean, reason: string) => {
                      dispatchUrgencyRuleCheck(isExpedited);
                      setFormContentOnUserEdit({ ...formContent, isExpedited: isExpedited, expeditedReason: reason });
                    }}
                    authStatus={currAuthStatus}
                    startDate={formContent.startDate}
                    patientId={patient?.id}
                    clinicalServiceId={clinicalService?.id}
                    patientHealthPlanName={patientHealthPlanName || undefined}
                    disabled={isFieldDisabled("urgency")}
                  />
                )}
              </Row>
            </SRFormConfigFieldWrapper>
          </>
        )}
      </Grid>
      <ServiceRequestFormRedirectModal
        {...redirectModalProps}
        formContent={formContent}
        setFormContent={setFormContentOnUserEdit}
      />
      <RemoveNonPALModal
        open={nonPalModalOpen}
        onClose={() => setNonPalModalOpen(false)}
        procedureCodes={formContent.procedureCodes}
        setProcedureCodes={(pxs) => setFormContentOnUserEdit({ ...formContent, procedureCodes: pxs })}
        nonPalProcedureCodes={nonPalProcedureCodes}
        setNonPalPDFOpen={setNonPalPDFOpen}
      />
      <Dialog fullScreen open={nonPalPDFOpen} onClose={() => setNonPalPDFOpen(false)}>
        <NonPalPDFProvider
          patient={patient}
          nonPalProcedureCodes={nonPalProcedureCodes}
          coverage={getCurrentPrimaryCoverage(patient || undefined) || undefined}
          nonPalSubmissionEnabled={nonPalSubmissionEnabled}
        >
          {({ url, loading }) => (
            <DocumentViewer
              documentInfo={{
                name: `CohereAuthCheck_${patient?.memberId}.pdf`,
                contentType: "application/pdf",
              }}
              loading={loading}
              url={url || undefined}
              handleClose={() => setNonPalPDFOpen(false)}
              canDelete={false}
              location={location}
              search={search}
            />
          )}
        </NonPalPDFProvider>
      </Dialog>
    </>
  );
}

export const Row = (props: ComponentProps<typeof Grid>) => <Grid item xs={12} {...props} />;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledOscarFaxInputCaption = styled(Caption)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginLeft: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  maxWidth: 450,
}));

export function useCheckServiceRequestProcedureCodes({
  procedureCodes,
  palPxCodes,
  isInpatient,
  clinicalService,
  dispatchRedirectRuleCheck,
  placeOfServiceId,
  startDate,
  patient,
  workflowId,
}: {
  procedureCodes: ProcedureCode[];
  palPxCodes: ProcedureCode[];
  isInpatient: boolean;
  dispatchRedirectRuleCheck: (errorObjectType: RedirectModalErrorObjectType) => RedirectRuleCheckFn;
  placeOfServiceId: string | undefined;
  startDate: Date;
  patient: Patient | undefined;
  workflowId: string | undefined;
  clinicalService?: ClinicalService | undefined;
}) {
  // For outpatient, filter out non-PAL procedures so we don't have two alerts for the same codes
  const procedureCodesForRuleCheck = useMemo(
    () =>
      isInpatient
        ? procedureCodes
        : procedureCodes.filter(({ code }) => palPxCodes.map(({ code }) => code).includes(code)),
    [palPxCodes, isInpatient, procedureCodes]
  );

  const maybeDoProcedureCodeRuleCheck = useCallback(() => {
    if (patient && procedureCodesForRuleCheck.length !== 0) {
      dispatchRedirectRuleCheck("procedure code")({
        displayTarget: "PROCEDURE_CODE",
        serviceRequest: {
          patient: patient,
          clinicalService: clinicalService,
          ...(!!formatDateToISODate(startDate) ? { startDate: formatDateToISODate(startDate) } : {}),
          encounterType: isInpatient ? "INPATIENT" : "OUTPATIENT",
          placeOfService: placeOfServiceId,
          semanticProcedureCodes: procedureCodesForRuleCheck,
          workflowId: workflowId,
        },
      });
    }
  }, [
    clinicalService,
    dispatchRedirectRuleCheck,
    isInpatient,
    placeOfServiceId,
    startDate,
    patient,
    procedureCodesForRuleCheck,
    workflowId,
  ]);

  const maybeDoProcedureCodeRuleCheckDebounced = useDebouncedCallback(
    maybeDoProcedureCodeRuleCheck,
    [
      clinicalService,
      dispatchRedirectRuleCheck,
      isInpatient,
      placeOfServiceId,
      startDate,
      patient,
      procedureCodesForRuleCheck,
      workflowId,
    ],
    300
  );

  useEffect(() => {
    maybeDoProcedureCodeRuleCheckDebounced();
  }, [maybeDoProcedureCodeRuleCheckDebounced]);
}
