import { Grid } from "@material-ui/core";
import { H4 } from "@coherehealth/common";
import {
  PrimaryDiagnosisCodeSelect,
  SecondaryDiagnosisCodeSelect,
} from "components/ServiceRequest/ServiceRequestForm/components/DiagnosisCodeSelect";
import React, { useContext } from "react";
import { DiagnosisCode } from "@coherehealth/core-platform-api";
import { PalCheckFormSectionProps } from "./HealthPlanPalCheckFormSectionConfigs";
import { SuggestionContext } from "../SuggestionContext";
import { compareCodeListEqualityNoOrder, isNotNullOrUndefined } from "util/suggestionUtils";

export default function DiagnosisCodesSection({
  priorAuthRequirements,
  setPriorAuthRequirements,
  attemptedSubmit,
  resetPalResults,
  generalAuthSubmissionStyling,
  hideHeader,
  isOutPatientEncounterType,
}: PalCheckFormSectionProps) {
  const { suggestedPriorAuthRequirements } = useContext(SuggestionContext);
  return (
    <Grid container spacing={2}>
      {!hideHeader && (
        <Grid item xs={12}>
          <H4 data-public>Diagnosis codes</H4>
        </Grid>
      )}
      <Grid item xs={12}>
        <PrimaryDiagnosisCodeSelect
          label="Primary diagnosis code"
          emptyLabel="Search for primary diagnosis code"
          error={attemptedSubmit && !priorAuthRequirements.primaryDiagnosis}
          primaryDiagnosisCode={priorAuthRequirements.primaryDiagnosis || null}
          setPrimaryDiagnosisCode={(code) => {
            setPriorAuthRequirements({ ...priorAuthRequirements, primaryDiagnosis: code || undefined });
            resetPalResults();
          }}
          isAutoFilled={
            suggestedPriorAuthRequirements?.primaryDiagnosis?.diagnosisCodeEntity
              ? suggestedPriorAuthRequirements.primaryDiagnosis.diagnosisCodeEntity.code ===
                priorAuthRequirements?.primaryDiagnosis?.code
              : false
          }
          disableForApprovedOutpatient={isOutPatientEncounterType ? true : false}
          dataPublic={true}
        />
      </Grid>
      <Grid item xs={12}>
        <SecondaryDiagnosisCodeSelect
          disablePlaceholder
          label="Secondary diagnosis codes"
          emptyLabel="Search for secondary diagnosis codes"
          isOptional
          error={!validateSecondaryDiagnoses(priorAuthRequirements.secondaryDiagnoses)}
          secondaryDiagnosisCodes={priorAuthRequirements.secondaryDiagnoses || []}
          setSecondaryDiagnosisCodes={(codes) => {
            setPriorAuthRequirements({ ...priorAuthRequirements, secondaryDiagnoses: codes || undefined });
            resetPalResults();
          }}
          isAutoFilled={
            !!suggestedPriorAuthRequirements?.secondaryDiagnoses?.length
              ? compareCodeListEqualityNoOrder(
                  suggestedPriorAuthRequirements.secondaryDiagnoses
                    .map((clientSV) => clientSV.diagnosisCodeEntity)
                    .filter(isNotNullOrUndefined),
                  priorAuthRequirements?.secondaryDiagnoses
                )
              : false
          }
          dataPublic={true}
        />
      </Grid>
    </Grid>
  );
}

export const validateSecondaryDiagnoses = (secondaryDiagnoses?: DiagnosisCode[]) =>
  secondaryDiagnoses && secondaryDiagnoses.length < 10;
