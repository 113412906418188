import React, { Dispatch, SetStateAction } from "react";
import clsx from "clsx";
import { Box, Grid, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { colorsLight } from "../../themes";
import { H4 } from "../../components/Typography";
import { Attachment } from "@coherehealth/core-platform-api";
import AttachmentViewerSidePanel, { AttachmentInfo } from "./AttachmentViewerSidePanel";
import { LoadError } from "@react-pdf-viewer/core";
import { ToggleListButton, ToolbarItemWrapper, ToolbarWrapper, useToolbarStyles } from "./ToolbarControl";
import { AttachmentViewerConfig } from "./util";

interface Props {
  error: LoadError;
  attachments?: Attachment[];
  handleAttachmentClick?: (index: number) => void;
  attachmentIndexOpen?: number;
  openSidePanel?: boolean;
  setOpenSidePanel?: Dispatch<SetStateAction<boolean>>;
  crrStyling?: boolean;
  previousAttachmentsExpanded?: boolean;
  setPreviousAttachmentsExpanded?: Dispatch<React.SetStateAction<boolean>>;
  attachmentsInfo?: AttachmentInfo[];
  isContinuationRequest?: boolean;
  withDocSegmentedSidePanel?: boolean;
  viewerConfig?: AttachmentViewerConfig;
  setAttachmentsInfo?: Dispatch<SetStateAction<AttachmentInfo[]>>;
}

const PdfViewerRenderError = ({
  error,
  attachments,
  handleAttachmentClick,
  attachmentIndexOpen,
  openSidePanel,
  setOpenSidePanel,
  crrStyling,
  previousAttachmentsExpanded,
  setPreviousAttachmentsExpanded,
  attachmentsInfo,
  isContinuationRequest,
  withDocSegmentedSidePanel,
  viewerConfig,
  setAttachmentsInfo,
}: Props) => {
  const getErrorMessage = (error: LoadError) => {
    switch (error.name) {
      case "InvalidPDFException":
        return "The selected attachment is invalid or corrupted";
      case "MissingPDFException":
        return "The selected attachment is missing";
      case "UnexpectedResponseException":
        return "Unexpected server response";
      default:
        return "The selected attachment cannot be opened";
    }
  };

  const useRenderErrorStyles = makeStyles((theme) => ({
    imageViewerRoot: {
      borderRight: `1px solid ${colorsLight.gray.divider}`,
    },
    sidePanelContainer: {
      width: !openSidePanel ? "0px" : viewerConfig ? "400px" : "264px",
      height: viewerConfig ? "calc(100vh - 40px)" : "calc(100vh - 180px)",
      backgroundColor: "white",
    },
    errorContainer: {
      height: viewerConfig ? "calc(100vh - 40px)" : "calc(100vh - 180px)",
      textAlign: "center",
      paddingTop: "calc(50vh - 90px)",
      width: !openSidePanel ? "100%" : viewerConfig ? "calc(100% - 400px)" : "calc(100% - 264px)",
    },
  }));

  const renderErrorClasses = useRenderErrorStyles();
  const toolbarClasses = useToolbarStyles({});

  const SidePanelComponent = viewerConfig?.sidePanelComponent;

  return (
    <>
      <Grid container direction="column" className={renderErrorClasses.imageViewerRoot}>
        <div style={{ height: "2.5rem" }}>
          <ToolbarWrapper crrStyling style={{ borderBottom: "1px solid #E5E5E5" }}>
            {crrStyling && (
              <Box className={clsx(toolbarClasses.toolbarControls, toolbarClasses.toolbarLeftControls)}>
                <ToolbarItemWrapper>
                  <ToggleListButton openSidePanel={openSidePanel} setOpenSidePanel={setOpenSidePanel} />
                </ToolbarItemWrapper>
              </Box>
            )}
            {viewerConfig?.closeDialogFunction && (
              <Box style={{ marginRight: "4px", marginLeft: "auto" }}>
                <IconButton
                  onClick={viewerConfig.closeDialogFunction}
                  disableFocusRipple={true}
                  size="small"
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
          </ToolbarWrapper>
        </div>
        <Box display={"flex"} width="100%">
          <Box flexDirection={"column"} className={renderErrorClasses.sidePanelContainer}>
            {attachments &&
              handleAttachmentClick &&
              (SidePanelComponent ? (
                <SidePanelComponent
                  attachments={attachments}
                  handleAttachmentClick={handleAttachmentClick}
                  attachmentIndexOpen={attachmentIndexOpen}
                  previousAttachmentsExpanded={previousAttachmentsExpanded}
                  openSidePanel={openSidePanel}
                  attachmentsInfo={attachmentsInfo}
                />
              ) : (
                <AttachmentViewerSidePanel
                  attachments={attachments}
                  withDocSegmentedSidePanel={withDocSegmentedSidePanel}
                  attachmentsInfo={attachmentsInfo}
                  handleAttachmentClick={handleAttachmentClick}
                  previousAttachmentsExpanded={previousAttachmentsExpanded}
                  setPreviousAttachmentsExpanded={setPreviousAttachmentsExpanded}
                  isContinuationRequest={isContinuationRequest}
                  openSidePanel={openSidePanel}
                  attachmentIndexOpen={attachmentIndexOpen}
                  setAttachmentsInfo={setAttachmentsInfo}
                  fromImageViewer
                />
              ))}
          </Box>
          <Box flexDirection={"column"} className={renderErrorClasses.errorContainer}>
            <H4 style={{ color: colorsLight.font.light }}>{getErrorMessage(error)}</H4>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default PdfViewerRenderError;
