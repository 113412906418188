import { useMemo } from "react";

import isBefore from "date-fns/isBefore";

import {
  PartialServiceRequest,
  ServiceRequestFormContent,
} from "../../../../common/SharedServiceRequestFormComponents";
import { parseDateFromISOStringWithoutFallback, removeTimeFromDate } from "@coherehealth/common";
import { ProcedureCode } from "@coherehealth/core-platform-api";

interface SRProps {
  currentFormContent: ServiceRequestFormContent;
  initialServiceRequest: PartialServiceRequest;
}

interface EditsOnApprovedSRState {
  fieldsToShowEditingApprovedSRWarning: Array<keyof ServiceRequestFormContent>;
  readOnlySemanticProcedureCodes: Array<ProcedureCode>;
  minNumUnits: number;
}

export default function useEditsOnApprovedSRState({
  currentFormContent,
  initialServiceRequest,
}: SRProps): EditsOnApprovedSRState {
  // todo replace this logic with a backend call that Brian is making
  // Destructure things we need
  const {
    intakeTimestamp,
    startDate: initialStartDateStr,
    primaryDiagnosis: initialPrimaryDiagnosis,
    procedureCodes: initialProcedureCodes,
    units: initialUnits,
  } = initialServiceRequest || {};
  const {
    startDate: currStartDate,
    primaryDiagnosisCode: currentPrimaryDiagnosis,
    procedureCodes: currentProcedureCodes,
    units: currentUnits,
  } = currentFormContent;

  // Memoized values (so we don't have to compute every render)
  const retroStatusChanged = useMemo(() => {
    const intakeTimestampDate = parseDateFromISOStringWithoutFallback(intakeTimestamp);
    const initialStartDate = parseDateFromISOStringWithoutFallback(initialStartDateStr);
    if (intakeTimestampDate && initialStartDate) {
      // strip time out of intake timestamp, only compare date
      const intakeTimestampDateOnly = removeTimeFromDate(intakeTimestampDate);

      return isBefore(initialStartDate, intakeTimestampDateOnly) !== isBefore(currStartDate, intakeTimestampDateOnly);
    }
    return false;
  }, [intakeTimestamp, initialStartDateStr, currStartDate]);

  // If auth status is approved then just return the empty array
  if (initialServiceRequest?.authStatus !== "APPROVED") {
    return { fieldsToShowEditingApprovedSRWarning: [], readOnlySemanticProcedureCodes: [], minNumUnits: 0 };
  }

  // Otherwise calculate which fields we need to show a warning on
  const fieldsToShowEditingApprovedSRWarning: Array<keyof ServiceRequestFormContent> = [];
  if (currentPrimaryDiagnosis?.code !== initialPrimaryDiagnosis?.code) {
    // primary diagnosis field changed
    fieldsToShowEditingApprovedSRWarning.push("primaryDiagnosisCode");
  }
  if (retroStatusChanged) {
    fieldsToShowEditingApprovedSRWarning.push("startDate");
  }
  if (currentProcedureCodes.length > (initialProcedureCodes?.length || 0)) {
    fieldsToShowEditingApprovedSRWarning.push("procedureCodes");
  }
  if (Math.trunc(Number(currentUnits)) > (initialUnits || 0)) {
    // number of units has increased
    fieldsToShowEditingApprovedSRWarning.push("units");
  }

  // There is also some logic surrounding providers/facilities (changing to a non-par option requires a new SR)
  // That logic lives in ProviderSelect.tsx, because there's some par check stuff that I didn't want to pull into
  // this hook (seemed redundant). At least for now I'll keep it that way...

  return {
    fieldsToShowEditingApprovedSRWarning,
    readOnlySemanticProcedureCodes: initialProcedureCodes || [],
    minNumUnits: initialUnits || 0,
  };
}
