import { warn } from "logger";
import { UserType } from "@coherehealth/common";
export { UserType };

export function assertIsUserType(ipt: unknown) {
  if (
    typeof ipt !== "string" ||
    !Object.values(UserType)
      .map((x) => x.toString())
      .includes(ipt)
  ) {
    warn(`Unknown user type: ${ipt}`);
  }
}
