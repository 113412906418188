import React from "react";
import SingleSelectDropdown, { AllSingleSelectDropdownProps } from "./SingleSelectDropdown";
import { DropdownOption } from "./shared";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

interface SingleSelectDropdownCancelProps {
  onCancel: () => void;
  canCancel: boolean;
  //Workaround for bug with styled components, types generic parameters as what it extends
  renderOption?: (arg0: DropdownOption) => React.ReactNode;
  renderOptionInList?: (arg0: DropdownOption) => React.ReactNode;
  renderSelectedOptionInList?: (arg0: DropdownOption) => React.ReactNode;
}
type AllSingleSelectDropdownCancelProps<T extends DropdownOption> = SingleSelectDropdownCancelProps &
  AllSingleSelectDropdownProps<T>;

const SingleSelectDropdownCancel = <T extends DropdownOption>({
  onCancel,
  canCancel,
  InputProps,
  ...props
}: AllSingleSelectDropdownCancelProps<T>) => {
  return (
    <StyledCancelDropdown
      {...props}
      InputProps={{
        endAdornment: canCancel && (
          <IconButton
            onMouseDown={(event) => event.stopPropagation()}
            onClick={onCancel}
            sx={{ position: "absolute", right: 40, color: "neutral.dark" }}
            disableRipple
            disableFocusRipple
            size="small"
            className="cancelButton"
          >
            <CloseIcon />
          </IconButton>
        ),
        ...InputProps,
      }}
    />
  );
};

const StyledCancelDropdown = styled(SingleSelectDropdown)(({ theme }) => ({
  "& .cancelButton": {
    opacity: 0,
    transition: theme.transitions.create("opacity"),
  },
  "&:hover .cancelButton": {
    opacity: 1,
  },
  "& .MuiFilledInput-adornedEnd": {
    paddingRight: 0,
  },
}));

export default SingleSelectDropdownCancel;
