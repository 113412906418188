import { useMemo } from "react";
import { useGetOnboardingHealthPlans } from "@coherehealth/core-platform-api";

const useHealthPlanOptions = () => {
  const { data: onboardingHPs } = useGetOnboardingHealthPlans({});

  const healthPlanOptionsWithNone = useMemo(() => {
    return onboardingHPs
      ? onboardingHPs.concat({ name: "None of the above", id: "none" })
      : [{ name: "None of the above", id: "none" }];
  }, [onboardingHPs]);

  return healthPlanOptionsWithNone;
};

export default useHealthPlanOptions;
