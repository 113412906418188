import { useCallback, createContext, useContext, Dispatch } from "react";

export interface DoubleDrawerExpansionState {
  expandedMore: boolean;
  setExpandedMore: Dispatch<boolean>;
  selectedExpandButtonId: string | null;
  setSelectedExpandButtonId: Dispatch<string | null>;
}

const DoubleDrawerExpansionContext = createContext<DoubleDrawerExpansionState>({
  expandedMore: false,
  setExpandedMore: () => {},
  selectedExpandButtonId: null,
  setSelectedExpandButtonId: () => {},
});

export interface DoubleDrawerExpandMoreButtonState {
  currentExpandButtonId: string | null;
}

export const DoubleDrawerExpandMoreButtonContext = createContext<DoubleDrawerExpandMoreButtonState>({
  currentExpandButtonId: null,
});

export function useDoubleDrawerSelectCurrentButton() {
  const { setExpandedMore, setSelectedExpandButtonId } = useContext(DoubleDrawerExpansionContext);
  const { currentExpandButtonId } = useContext(DoubleDrawerExpandMoreButtonContext);
  return useCallback(() => {
    setSelectedExpandButtonId(currentExpandButtonId);
    setExpandedMore(true);
  }, [currentExpandButtonId, setSelectedExpandButtonId, setExpandedMore]);
}

export default DoubleDrawerExpansionContext;
