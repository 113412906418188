import React from "react";
import routes from "routes";
import { useMatch } from "react-router-dom";
import WithdrawAndReplacePageContents from "./WithdrawAndReplacePageContents";
import { NotFoundPage } from "@coherehealth/common";

export default function WithdrawAndReplacePage() {
  const match = useMatch(routes.WITHDRAW_AND_REPLACE);
  const serviceRequestId = match?.params.serviceRequestId || "";

  if (serviceRequestId == null) {
    return <NotFoundPage />;
  }

  return <WithdrawAndReplacePageContents serviceRequestId={serviceRequestId} />;
}
