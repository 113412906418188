import React, { useEffect } from "react";
import HeaderAndProgressBar from "../HeaderAndProgressBar";
import { H1, H6, InlineButton } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core";
import ClipboardSuccessIcon from "../../images/ClipboardSuccessIcon";
import FullScreenDisplay, { FullScreenDisplayProps } from "./FullScreenDisplay";
import { joinExistingOrganizationSurvey } from "util/alchemerURLs";

export default function OnboardingVerificationSuccess() {
  const classes = useStyles();

  useEffect(() => {
    // wait 1.5 secs before sending user to survey tab
    setTimeout(() => window.open(joinExistingOrganizationSurvey), 1500);
  });

  const fullScreenDisplayProps: FullScreenDisplayProps = {
    headerAndProgressBar: <HeaderAndProgressBar stepNumber={3} />,
    visualComponent: <ClipboardSuccessIcon />,
    header: <H1>You’re verified! Complete your registration in the new window.</H1>,
    subtext: (
      <H6>
        We just need a little more info from you to get your account set up. Finish this process in the new tab, or
        <InlineButton component="a" href={joinExistingOrganizationSurvey} className={classes.surveyButton}>
          &nbsp;click here&nbsp;
        </InlineButton>
        if the window hasn’t opened on it’s own.
      </H6>
    ),
    widthOverrides: {
      headerWidth: 950,
      subtextWidth: 653,
    },
  };

  return <FullScreenDisplay {...fullScreenDisplayProps} />;
}

const useStyles = makeStyles((theme) => ({
  surveyButton: {
    marginBottom: "2px",
  },
}));
