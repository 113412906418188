import { NetworkStatusOverride } from "@coherehealth/core-platform-api";
import { NetworkStatusOverrideOptionType } from "./NeworkStatusOverrideSingleSelect";

export const isOutOfNetworkStatus = (
  networkStatus: NetworkStatusOverrideOptionType | undefined
): boolean | undefined => {
  if (networkStatus === undefined) {
    return undefined;
  }
  return networkStatus === "OON";
};

export const getNetworkStatusOverridePayload = (
  facilityNetworkStatusOverride: NetworkStatusOverrideOptionType | undefined,
  performingProviderNetworkStatusOverride: NetworkStatusOverrideOptionType | undefined
): NetworkStatusOverride => {
  return {
    isOutOfNetworkFacility: isOutOfNetworkStatus(facilityNetworkStatusOverride),
    isOutOfNetworkPerformingProvider: isOutOfNetworkStatus(performingProviderNetworkStatusOverride),
  };
};

export const getNetworkStatusOverride = (
  networkStatus: boolean | undefined
): NetworkStatusOverrideOptionType | undefined => {
  if (networkStatus === undefined) {
    return undefined;
  }
  return networkStatus ? "OON" : "INN";
};
