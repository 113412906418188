import React, { useEffect, Dispatch, SetStateAction, useState } from "react";

import TaxIdChipInput from "../TaxIdChipInput";
import { Grid, useTheme } from "@material-ui/core";
import { OrgInfoInternalState } from "./ProviderOrgInfo";
import { TextField, MultiSelectDropdown, Body1, Switch, Body2, Tooltip } from "@coherehealth/common";
import UnitedStatesStateSelection from "../UnitedStatesStateSelection";
import { DEFAULT_SPECIALTY_OPTIONS as defaultSpecialtyOptions } from "../../../util/organization";
import { isZipcodeValid } from "util/providerUtils";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { makeStyles } from "@material-ui/core/styles";
import AutoVerifyInformativeModalEdit from "./AutoInformativeModalEdit";
import EmailDomainChipInput from "../EmailDomainChipInput";
import { colorsLight as colors } from "@coherehealth/common";
import useHealthPlanOptions from "hooks/useHealthPlanOptions";

interface Props {
  orgInfo: OrgInfoInternalState;
  setOrgInfo: Dispatch<SetStateAction<OrgInfoInternalState>>;
  invalidEmailDomainList: string[];
  setInvalidEmailDomainList: Dispatch<SetStateAction<string[]>>;
  onboardingRedesign: boolean;
}

const useStyles = makeStyles((theme) => ({
  divContainer: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1.5),
  },
  headingStyle: {
    color: colors.font.secondary,
    paddingRight: theme.spacing(1.25),
  },
  toolTipStyle: {
    color: colors.font.light,
  },
  labelStyle: {
    color: colors.font.secondary,
    paddingLeft: theme.spacing(0.5),
  },
  label2Style: {
    color: colors.font.secondary,
    paddingRight: theme.spacing(0.5),
  },
}));

export default function ProviderOrgEdit({
  orgInfo,
  setOrgInfo,
  invalidEmailDomainList,
  setInvalidEmailDomainList,
  onboardingRedesign,
}: Props) {
  const theme = useTheme();
  const classes = useStyles();
  const [checked, setChecked] = useState(orgInfo.emailAutoVerificationEnabled || false);
  const [autoVerifyEmail, setAutoVerifyEmail] = useState(orgInfo.emailAutoVerificationEnabled || false);
  const [autoVerifyModal, setAutoVerifyModal] = useState(false);
  const healthPlanOptionsWithNone = useHealthPlanOptions();

  useEffect(() => {
    if (!checked) {
      setOrgInfo((prevState) => ({ ...prevState, emailDomains: [] }));
    }
  }, [checked, setOrgInfo]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            error={!orgInfo?.name}
            helperText={!orgInfo.name ? "Required" : ""}
            type="text"
            label="Organization name"
            value={orgInfo.name}
            onChangeValue={(name) => setOrgInfo({ ...orgInfo, name })}
            data-public
          />
        </Grid>

        <Grid item xs={6}>
          <MultiSelectDropdown
            error={orgInfo?.healthPlans && orgInfo?.healthPlans?.length === 0}
            helperText={orgInfo.healthPlans.length === 0 ? "Required" : ""}
            maxMenuHeight={theme.spacing(50)}
            label="Health plans"
            selectedValues={orgInfo.healthPlans}
            options={healthPlanOptionsWithNone
              .filter((healthPlan: { id: string }) => healthPlan.id !== "none")
              .map((healthPlan) => ({
                id: healthPlan.name,
                label: healthPlan.name,
              }))}
            onChange={(healthPlans) => setOrgInfo((prev) => ({ ...prev, healthPlans }))}
            data-public
          />
        </Grid>

        <Grid item xs={6}>
          <MultiSelectDropdown
            error={orgInfo?.specialties && orgInfo?.specialties?.length === 0}
            helperText={orgInfo.specialties.length === 0 ? "Required" : ""}
            maxMenuHeight={theme.spacing(50)}
            label="Specialties"
            selectedValues={orgInfo.specialties}
            options={defaultSpecialtyOptions}
            onChange={(specialties) => setOrgInfo((prev) => ({ ...prev, specialties }))}
            data-public
          />
        </Grid>
        <Grid item xs={12}>
          <TaxIdChipInput
            label={"Taxpayer identification number(s) (TIN)"}
            tinList={orgInfo.tinList}
            setTinList={(tinList) => {
              setOrgInfo({ ...orgInfo, tinList });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            error={!orgInfo.addressLine1}
            helperText={!orgInfo?.addressLine1 ? "Required" : ""}
            type="text"
            label="Street address"
            value={orgInfo.addressLine1}
            onChangeValue={(addressLine1) => setOrgInfo({ ...orgInfo, addressLine1 })}
            data-public
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            type="text"
            label="Street address 2"
            value={orgInfo.addressLine2}
            onChangeValue={(addressLine2) => setOrgInfo({ ...orgInfo, addressLine2 })}
            data-public
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            error={!orgInfo.city}
            helperText={!orgInfo.city ? "Required" : ""}
            type="text"
            label="City"
            value={orgInfo.city}
            onChangeValue={(city) => setOrgInfo({ ...orgInfo, city })}
            data-public
          />
        </Grid>
        <Grid item xs={2}>
          <UnitedStatesStateSelection
            state={orgInfo.state}
            error={!orgInfo.state}
            helperText={!orgInfo.state ? "Required" : ""}
            setState={(state) => {
              setOrgInfo((prev) => {
                return { ...prev, state };
              });
            }}
            data-public
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            error={!isZipcodeValid(orgInfo.zipCode)}
            helperText={!isZipcodeValid(orgInfo.zipCode) ? "You must provide a 5 digit zip code" : ""}
            type="text"
            label="Zip code"
            value={orgInfo.zipCode}
            onChangeValue={(zipCode) => setOrgInfo({ ...orgInfo, zipCode })}
            data-public
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.divContainer}>
            <Body1 className={classes.headingStyle} data-public>
              Auto-verify new users by email domain
            </Body1>
            <Tooltip
              title={
                "Auto-verify new users by email domain allows new users to join your organization’s Cohere account and replaces the need of manual review by an organization administrator. "
              }
              placement="top"
              className={classes.toolTipStyle}
              data-public
            >
              <InfoOutlinedIcon data-public />
            </Tooltip>
          </div>
          <Switch
            label={
              <Body2 className={classes.labelStyle} data-public>
                On
              </Body2>
            }
            label2={
              <Body1 className={classes.label2Style} data-public>
                Off
              </Body1>
            }
            doubleSidedSwitch
            checked={checked}
            onChange={(emailAutoVerificationEnabled) => {
              setChecked((prevState) => !prevState);
              setAutoVerifyModal((prevState) => !prevState);
              setOrgInfo({ ...orgInfo, emailAutoVerificationEnabled });
            }}
            data-public
          />
          {autoVerifyEmail && (
            <Grid item xs={12} style={{ paddingTop: "12px" }}>
              <EmailDomainChipInput
                domains={orgInfo.emailDomains}
                setDomains={(emailDomains) => setOrgInfo({ ...orgInfo, emailDomains })}
                invalidEmailDomainList={invalidEmailDomainList}
                setInvalidEmailDomainList={setInvalidEmailDomainList}
                label={"Auto-verifiable email domain(s)"}
                data-public
              />
            </Grid>
          )}
        </Grid>
        <AutoVerifyInformativeModalEdit
          checked={checked}
          setChecked={setChecked}
          setAutoVerifyEmail={setAutoVerifyEmail}
          autoVerifyModal={autoVerifyModal}
          setAutoVerifyModal={setAutoVerifyModal}
        />
      </Grid>
    </>
  );
}
