import { Body1, filterClinicalQuestionsByDisplayCondition, InformativeModal } from "@coherehealth/common";
import { incompleteRequiredQuestions } from "util/clinicalAssessment";
import React, { useContext, useEffect, useState } from "react";
import { ClinicalAssessmentContext } from "components/ClinicalAssessment";
import { Grid, useTheme } from "@material-ui/core";

interface Props {
  modalOpen: boolean;
  onClose: () => void;
  modalState: any;
  loading: boolean;
  submitAndContinue: () => void;
  canSkip?: boolean;
}

export default function ClinicalSkipOrIncompleteModal({
  modalOpen,
  onClose,
  modalState,
  loading,
  submitAndContinue,
  canSkip,
}: Props) {
  const { clinicalAssessment, subQuestions: subQuestionsVisibility } = useContext(ClinicalAssessmentContext);
  const [questionsIndexs, setQuestionsIndexs] = useState<Map<string, number>>();
  const theme = useTheme();

  useEffect(() => {
    let questionsIndexMap = new Map<string, number>();
    filterClinicalQuestionsByDisplayCondition(clinicalAssessment?.questions).forEach((question, index) => {
      question?.clinicalQuestion?.id && questionsIndexMap.set(question?.clinicalQuestion?.id, index + 1);
    });
    setQuestionsIndexs(questionsIndexMap);
  }, [clinicalAssessment?.questions]);

  return (
    <>
      {modalState === "INCOMPLETE_CHECK" && (
        <InformativeModal
          headerText={"Some information is missing"}
          subHeaderText={"Complete all required information to expedite the approval process"}
          primaryButtonText={"Return to clinical assessment"}
          primaryButtonAction={onClose}
          primaryButtonLoading={loading}
          tertiaryButtonText={canSkip ? "Continue anyway" : undefined}
          tertiaryButtonAction={canSkip ? submitAndContinue : undefined}
          tertiaryButtonLoading={loading}
          additionalInfoElement={
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="stretch"
              style={{ paddingTop: theme.spacing(4) }}
            >
              {incompleteRequiredQuestions(clinicalAssessment, subQuestionsVisibility).map((question) => (
                <Body1 key={question?.id} style={{ lineHeight: "17.5px", paddingBottom: theme.spacing(1) }}>
                  Question {question?.clinicalQuestion?.id && questionsIndexs?.get(question?.clinicalQuestion?.id)}:
                  {" " + question.clinicalQuestion?.questionText}
                </Body1>
              ))}
            </Grid>
          }
          open={modalOpen}
          onClose={onClose}
          data-testid="incomplete-assessment-modal"
        />
      )}
      {modalState === "SKIP_CHECK" && (
        <InformativeModal
          headerText={"Are you sure you want to skip?"}
          subHeaderText={"Skipping clinical assessment will delay the approval process"}
          primaryButtonText={"Return to clinical assessment"}
          primaryButtonAction={onClose}
          primaryButtonLoading={loading}
          tertiaryButtonText={"Continue anyway"}
          tertiaryButtonAction={() => {
            submitAndContinue();
            onClose();
          }}
          tertiaryButtonLoading={loading}
          open={modalOpen}
          onClose={onClose}
        />
      )}
    </>
  );
}
