import React, { useContext } from "react";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { useTrackUserInteraction } from "util/userActivityTracker";
import { PotentialDuplicateContext } from "./PotentialDuplicateContextProvider";

export interface ModalProps extends DialogProps {
  hideX?: boolean;
  contentClassName?: string;
  dialogTitleStyle?: string;
  withdrawAuthDecisionGroup?: boolean;

  children: JSX.Element;
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  customDialog: {
    padding: "24px",
  },
  dialog: {
    "& .MuiPaper-root": {
      "&.MuiDialog-paperWidthLg": { maxWidth: "1024px" },
      borderRadius: "16px",
    },
  },
}));

const PotentialDuplicateModal = ({
  hideX,
  children,
  open,
  onClose,
  contentClassName,
  dialogTitleStyle,
  withdrawAuthDecisionGroup,
  ...props
}: ModalProps) => {
  const { closeButton: closeButtonClass, dialog: dialogClass, customDialog: customDialogClass } = useStyles();
  const trackUserActivityInteraction = useTrackUserInteraction();
  const workflowId = useContext(PotentialDuplicateContext);

  return (
    <Dialog
      fullWidth
      maxWidth={"lg"}
      className={dialogClass}
      open={open}
      onClose={onClose}
      classes={{ paper: customDialogClass }}
      {...props}
    >
      <>
        {!hideX && (
          <IconButton
            className={closeButtonClass}
            aria-label="Dismiss modal"
            onClick={async (event) => {
              await trackUserActivityInteraction({
                event: "CLOSE_DUPLICATE_MODAL",
                stage: "DUPLICATE_SUBMISSION_REVIEW",
                activityContext: {
                  workflowId: workflowId || undefined,
                },
              });
              onClose?.(event, "backdropClick");
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        <DialogContent className={contentClassName}>{children}</DialogContent>
      </>
    </Dialog>
  );
};

PotentialDuplicateModal.displayName = "Modal";
export default PotentialDuplicateModal;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(0),
  marginTop: theme.spacing(3),
  overflowY: "scroll",
}));
