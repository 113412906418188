import React from "react";
import { InformativeModal } from "@coherehealth/common";
import { DialogProps } from "@material-ui/core/Dialog";
import StartContinuationIcon from "./images/StartContinuationIcon";

interface ContinuationProps extends DialogProps {
  open: boolean;
  primaryButtonActions: () => void;
  tertiaryButtonAction: () => void;
  primaryButtonLoading: boolean;
}

const StartContinuationModal = ({
  open,
  onClose,
  primaryButtonActions,
  tertiaryButtonAction,
  primaryButtonLoading,
}: ContinuationProps) => {
  return (
    <InformativeModal
      open={open}
      onClose={onClose}
      icon={<StartContinuationIcon />}
      headerText="The changes you’ve made need to be submitted as a continuation request"
      subHeaderText="Note: Your auth number will remain the same"
      showDivider={false}
      primaryButtonText="Start continuation"
      primaryButtonAction={primaryButtonActions}
      primaryButtonLoading={primaryButtonLoading}
      tertiaryButtonText="Go back"
      tertiaryButtonAction={tertiaryButtonAction}
    />
  );
};

export default StartContinuationModal;
