import React from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";

/** For Storybook */
export function TypographyWithProps(props: TypographyProps) {}

export const H1: typeof Typography = (props: TypographyProps) => <Typography variant="h1" {...props} />;
export const H2: typeof Typography = (props: TypographyProps) => <Typography variant="h2" {...props} />;
export const H3: typeof Typography = (props: TypographyProps) => <Typography variant="h3" {...props} />;
export const H4: typeof Typography = (props: TypographyProps) => <Typography variant="h4" {...props} />;
export const H5: typeof Typography = (props: TypographyProps) => <Typography variant="h5" {...props} />;
export const H6: typeof Typography = (props: TypographyProps) => <Typography variant="h6" {...props} />;
export const Subtitle1: typeof Typography = (props: TypographyProps) => <Typography variant="subtitle1" {...props} />;
export const Subtitle2: typeof Typography = (props: TypographyProps) => <Typography variant="subtitle2" {...props} />;
export const Body1: typeof Typography = (props: TypographyProps) => <Typography variant="body1" {...props} />;
export const Body2: typeof Typography = (props: TypographyProps) => <Typography variant="body2" {...props} />;
// TODO: At some point migrate Body3
export const ButtonTypography: typeof Typography = (props: TypographyProps) => (
  <Typography variant="button" {...props} />
);
export const Caption: typeof Typography = (props: TypographyProps) => <Typography variant="caption" {...props} />;
export const Overline: typeof Typography = (props: TypographyProps) => <Typography variant="overline" {...props} />;
// TODO: At some point migrate VisuallyHidden
