import {
  Checkbox,
  colorsLight,
  DateSelect,
  getNoTimezoneDateTimeString,
  H6,
  PrimaryButton,
  RadioGroup,
  RichTextEditor,
  SecondaryButton,
  SingleSelectDropdown,
  stripHTMl,
  TextField,
  TimeTextField,
  useConfiguration,
} from "@coherehealth/common";
import {
  AppealPayload,
  DecisionAppealsConfigurationResponse,
  ErrorMessageEmbedded,
  FormFieldConfiguration,
  RequestorType,
  ServiceRequestResponse,
  useCreateAppeal,
  useGetAppeals,
} from "@coherehealth/core-platform-api";
import { Box, Grid, makeStyles, Tab, useMediaQuery, useTheme } from "@material-ui/core";
import { navigateToPS } from "components/AuthBuilder/common";
import { StyledTabs } from "components/ClinicalReview/Review/ClinicalReviewPage";
import { RIGHT_HAND_PANEL_SIZE, configToDropDownOption } from "components/ClinicalReview/reviewUtils/utils";
import {
  ReviewControlBoxInnerContainer,
  ReviewControlBoxOuterContainer,
} from "components/ServiceRequest/ReviewSection/MDReview/MDReviewEdit";
import { useEffect, useReducer, useState, PropsWithChildren } from "react";
import { useNavigate } from "react-router";
import { AddAppealAttachment } from "./AddAppealAttachment";
import { AppealNoteSubmissionModal } from "./AppealNoteSubmissionModal";
import { timePattern } from "util/dateUtils";
import { error as logError } from "logger";
import { useSnackbar } from "notistack";
import { isValid } from "date-fns";
import { AppealNoteFormState, appealNoteReducer, initialAppealNote } from "./AppealNoteReducer";

interface AppealNoteProps {
  serviceRequest: ServiceRequestResponse;
  mockAppealNote?: AppealNoteFormState; // used for unit test don't repurpose for anything else
}

const useAppealNoteStyle = makeStyles((theme) => ({
  panelTab: {
    "&:hover span": {
      opacity: 0.7,
    },
  },
  appealInitiationTimeStamp: {
    marginBottom: theme.spacing(2),
  },
  postAppealDate: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(2),
  },
  appealDetails: {
    marginBottom: theme.spacing(2),
  },
  // Appeal note type: Initiation | Post-appeal note
  appealType: {
    marginBottom: theme.spacing(2),
  },
  requestorType: {
    marginBottom: theme.spacing(3),
  },
  // Appeal type: Standard | QIO
  postAppealType: {
    marginBottom: theme.spacing(1),
  },
  formatReceivedRadioGroup: {
    marginBottom: theme.spacing(3),
  },
  coverageEndedBy: {
    marginBottom: theme.spacing(3),
  },
  isExpeditedCheckbox: {
    marginBottom: theme.spacing(3),
  },
  caseDescriptionTextField: {
    marginBottom: theme.spacing(2),
  },
  discardButton: {
    width: 258,
    height: 48,
    padding: theme.spacing(0),
    marginRight: theme.spacing(3),
  },
  finishButton: {
    width: 258,
    height: 48,
    marginLeft: theme.spacing(3),
  },
}));

const validateAppealNote = ({
  state,
  isAttachmentRequired,
}: {
  state: AppealNoteFormState;
  isAttachmentRequired?: boolean;
}): boolean => {
  if (!state.appealType) {
    return false;
  }

  if (state.appealType === "APPEAL_INITIATION") {
    return (
      Boolean(state.appealInitiationDate) &&
      Boolean(state.appealInitiationTime && timePattern.test(state.appealInitiationTime)) &&
      Boolean(state.appealChannel) &&
      Boolean(state.requestorType) &&
      Boolean(state.caseDescription) &&
      ((state.attachments && state.attachments.length > 0) || !isAttachmentRequired)
    );
  } else if (state.appealType === "APPEAL_LOG") {
    return (
      Boolean(state.postAppealNote && stripHTMl(state.postAppealNote)) &&
      ((state.attachments && state.attachments.length > 0) || !isAttachmentRequired)
    );
  }
  return true;
};

const AppealTypeOptions: { id: string; label: string }[] = [
  {
    id: "APPEAL_INITIATION",
    label: "Appeal initiation",
  },
  {
    id: "APPEAL_LOG",
    label: "Post-appeal note",
  },
];

export const RequestorTypeOptions: { id: RequestorType; label: string }[] = [
  { id: "PROVIDER", label: "Provider" },
  { id: "MEMBER", label: "Member" },
  { id: "MEMBER_REPRESENTATIVE", label: "Member representative" },
];

export const PostAppealFormConfigFieldWrapper = ({
  fieldSpec,
  children,
}: PropsWithChildren<Partial<FormFieldConfiguration>>) => {
  if (!fieldSpec || fieldSpec === "OMIT") {
    return null;
  }
  return <>{children}</>;
};

export const AppealNote = ({ serviceRequest, mockAppealNote }: AppealNoteProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useAppealNoteStyle();
  const theme = useTheme();
  const matchesRight = useMediaQuery(theme.breakpoints.up(RIGHT_HAND_PANEL_SIZE));
  const navigate = useNavigate();
  const [openSubmissionModal, setOpenSubmissionModal] = useState<boolean>(false);
  const { data: appeals } = useGetAppeals({
    queryParams: {
      serviceRequest: serviceRequest.id,
    },
  });
  const currentAppeal = appeals?.find((appeal) => appeal.appealStatus === "IN_PROGRESS");

  const [appealNoteFormState, updateAppealNoteFormState] = useReducer(appealNoteReducer, {
    ...initialAppealNote,
    ...(process.env.NODE_ENV === "test" && mockAppealNote),
  });
  const [attemptedSubmit, setAttemptedSubmit] = useState<boolean>(false);

  const decisionAppealsConfiguration: DecisionAppealsConfigurationResponse | undefined = useConfiguration(
    "decisionAppealsConfiguration",
    serviceRequest.healthPlanName,
    serviceRequest.delegatedVendor
  );

  const { appealNoteForm } = decisionAppealsConfiguration ?? {};
  const { formFieldConfigurations } = appealNoteForm ?? {};

  const FilteredAppealTypeOptions = AppealTypeOptions.filter(({ id }) => {
    if (id === "APPEAL_INITIATION") {
      return decisionAppealsConfiguration?.allowAppealInitiation && !Boolean(currentAppeal);
    } else if (id === "APPEAL_LOG") {
      return true;
    }
    return true;
  });

  const getCoverageEndedByOptions = () =>
    appealNoteForm?.coverageEndedByOptions ? configToDropDownOption(appealNoteForm.coverageEndedByOptions) : [];

  useEffect(() => {
    if (currentAppeal) {
      updateAppealNoteFormState({
        type: "updateAppealNoteForm",
        value: {
          attachments: currentAppeal.attachments?.filter((attachment) => attachment !== null) ?? [],
        },
      });
    }
  }, [appealNoteFormState.appealType, currentAppeal]);

  const isValidAppealNote = validateAppealNote({
    state: appealNoteFormState,
    isAttachmentRequired: decisionAppealsConfiguration?.appealNoteForm?.isAttachmentRequired,
  });

  const { mutate: createAppeal, loading: createAppealLoading } = useCreateAppeal({
    serviceRequestId: serviceRequest.id,
  });

  const startAppealInitiation = async () => {
    try {
      const {
        appealType,
        appealInitiationDate,
        appealInitiationTime,
        requestorType,
        appealChannel,
        caseDescription,
        isExpedited,
        attachments,
      } = appealNoteFormState;
      const appealInitiationPayload: AppealPayload = {
        appealType,
        appealStatus: "IN_PROGRESS",
        requestorType,
        appealChannel,
        isManualAppeal: false,
        isExpedited,
        caseDescription,
        attachments,
        appealInitiationTimestamp: new Date(
          getNoTimezoneDateTimeString(appealInitiationDate ?? undefined, appealInitiationTime)
        ).toISOString(),
      };
      await createAppeal(appealInitiationPayload);
      enqueueSnackbar("Successfully initiated appeal", { variant: "success" });
      navigateToPS(serviceRequest?.patient?.id ?? "", navigate, serviceRequest.id);
    } catch (error) {
      const errorAny: ErrorMessageEmbedded = error as ErrorMessageEmbedded;
      const message = errorAny?.data?.message;
      const embeddedError = errorAny?.data?._embedded?.errors?.map((error) => error?.message).join("\n");
      const errorMessage = (
        <Box>
          <Box>Error while saving appeal initiation</Box>
          {embeddedError ? <Box>{embeddedError}</Box> : message && <Box>{message}</Box>}
        </Box>
      );
      logError("Error while saving appeal initiation");
      logError(error);
      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
    }
  };

  const onDiscard = async () => {
    navigateToPS(serviceRequest?.patient?.id ?? "", navigate, serviceRequest.id);
  };

  const onFinishNote = () => {
    setAttemptedSubmit(true);

    if (!isValidAppealNote) {
      return;
    }

    if (appealNoteFormState.appealType === "APPEAL_INITIATION") {
      startAppealInitiation();
    } else if (appealNoteFormState.appealType === "APPEAL_LOG") {
      setOpenSubmissionModal(true);
    }
  };

  const appealInitiationTimeValidation = Boolean(
    appealNoteFormState.appealInitiationTime && timePattern.test(appealNoteFormState.appealInitiationTime)
  );

  const appealInitiationTimeStamp = isValid(appealNoteFormState.appealInitiationDate)
    ? getNoTimezoneDateTimeString(
        appealNoteFormState.appealInitiationDate ?? undefined,
        appealNoteFormState.appealInitiationTime
      )
    : undefined;
  const isPreService =
    serviceRequest.startDate && appealInitiationTimeStamp && appealInitiationTimeStamp < serviceRequest.startDate;

  // in case appeal initiation timestamp changes and appeal is no longer preservice reset isExpedited value to false
  if (!isPreService && appealNoteFormState.isExpedited) {
    updateAppealNoteFormState({ type: "updateIsExpedited", value: false });
  }

  return (
    <Grid data-testid="appeal-note-form" container direction="column">
      <StyledTabs
        value={"APPEAL"}
        aria-label="note-tabs"
        style={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 2 }}
      >
        <Tab
          key={"appeal-note"}
          style={{ minWidth: "180px" }}
          label={"Appeal note"}
          value={"APPEAL"}
          className={classes.panelTab}
        />
      </StyledTabs>
      <Grid container direction="column" style={{ padding: theme.spacing(3), marginBottom: theme.spacing(9) }}>
        <SingleSelectDropdown
          data-testid="appeal-note-type"
          fullWidth
          label="Appeal note type"
          onChange={(val) => {
            updateAppealNoteFormState({ type: "updateAppealType", value: val as AppealPayload["appealType"] });
          }}
          error={attemptedSubmit && !appealNoteFormState.appealType}
          options={FilteredAppealTypeOptions}
          renderOption={(pos: any) => pos.name}
          value={appealNoteFormState.appealType}
          className={classes.appealType}
        />
        {appealNoteFormState.appealType === "APPEAL_INITIATION" && (
          <>
            <H6 className={classes.appealDetails}>Appeal details</H6>
            <Grid container spacing={2} className={classes.appealInitiationTimeStamp}>
              <Grid item xs={6}>
                <DateSelect
                  testId="appeal-received-date"
                  attemptedSubmit={attemptedSubmit}
                  TextFieldProps={{ fullWidth: true }}
                  maxDateMessage={"Date must be today or earlier"}
                  label="Received date"
                  value={appealNoteFormState.appealInitiationDate ?? null}
                  error={attemptedSubmit && !appealNoteFormState.appealInitiationDate}
                  showInternalErrorsOnBlur
                  helperText={attemptedSubmit && "Required"}
                  onDateChange={(newAdmissionDate) =>
                    updateAppealNoteFormState({ type: "updateAppealInitiationDate", value: newAdmissionDate })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TimeTextField
                  data-testid="appeal-received-time"
                  fullWidth
                  label="Received time (24-hour format)"
                  timeFormat="24"
                  placeholder="hh:mm"
                  value={appealNoteFormState.appealInitiationTime}
                  error={
                    attemptedSubmit && (!appealNoteFormState.appealInitiationTime || !appealInitiationTimeValidation)
                  }
                  helperText={
                    attemptedSubmit &&
                    (!appealNoteFormState.appealInitiationTime
                      ? "Required"
                      : !appealInitiationTimeValidation && "Invalid format")
                  }
                  onChangeValue={(receivedTime) =>
                    updateAppealNoteFormState({ type: "updateAppealInitiationTime", value: receivedTime })
                  }
                />
              </Grid>
            </Grid>
            <SingleSelectDropdown
              data-testid="requestor-type"
              fullWidth
              label="Requestor type"
              value={appealNoteFormState.requestorType}
              options={RequestorTypeOptions}
              onChange={(requestorType) =>
                updateAppealNoteFormState({ type: "updateRequestorType", value: requestorType })
              }
              className={classes.requestorType}
            />
            <H6 className={classes.appealDetails}>Format received</H6>
            <RadioGroup
              data-testid="appeal-channel"
              className={classes.formatReceivedRadioGroup}
              error={attemptedSubmit && !appealNoteFormState.appealChannel}
              helperText={attemptedSubmit && !appealNoteFormState.appealChannel && "Required"}
              row
              options={[
                { id: "EMAIL", label: "Email" },
                { id: "FAX", label: "Fax" },
                { id: "MAIL", label: "Mail" },
              ]}
              value={appealNoteFormState.appealChannel}
              onChange={(appealChannel) =>
                updateAppealNoteFormState({ type: "updateAppealChannel", value: appealChannel })
              }
              data-public
            />
            <H6 className={classes.appealDetails}>Case details</H6>
            <TextField
              data-testid="case-description"
              label="Case description"
              value={appealNoteFormState.caseDescription}
              className={classes.caseDescriptionTextField}
              onChangeValue={(caseDescription) =>
                updateAppealNoteFormState({ type: "updateCaseDescription", value: caseDescription })
              }
            />
            <Checkbox
              data-testid="is-expedited"
              checked={appealNoteFormState.isExpedited}
              onChange={(expedited: boolean) =>
                updateAppealNoteFormState({ type: "updateIsExpedited", value: expedited })
              }
              label="Expedited"
              style={{ color: colorsLight.font.secondary }}
              className={classes.isExpeditedCheckbox}
              disabled={!isPreService}
            />
          </>
        )}
        {appealNoteFormState.appealType === "APPEAL_LOG" && (
          <>
            <div style={{ marginBottom: "8px" }}>
              <RichTextEditor
                readonly={false}
                htmlValue={appealNoteFormState.postAppealNote ?? ""}
                setHtmlValue={(postAppealNote) =>
                  updateAppealNoteFormState({ type: "updateAppealNote", value: postAppealNote })
                }
                label="Post-appeal note"
                testid="post-appeal-note-text-editor"
                error={
                  attemptedSubmit &&
                  !Boolean(appealNoteFormState.postAppealNote && stripHTMl(appealNoteFormState.postAppealNote))
                }
                helperText={
                  attemptedSubmit &&
                  !Boolean(appealNoteFormState.postAppealNote && stripHTMl(appealNoteFormState.postAppealNote))
                    ? "Required"
                    : undefined
                }
              />
            </div>
            <H6 className={classes.postAppealType}>Appeal Type</H6>
            <RadioGroup
              data-testid="post-appeal-type"
              className={classes.appealType}
              error={attemptedSubmit && !appealNoteFormState.postAppealType}
              helperText={attemptedSubmit && !appealNoteFormState.postAppealType && "Required"}
              row
              options={[
                { id: "STANDARD", label: "Standard" },
                { id: "QIO", label: "QIO" },
              ]}
              value={appealNoteFormState.postAppealType}
              onChange={(postAppealType) =>
                updateAppealNoteFormState({ type: "updatePostAppealType", value: postAppealType })
              }
              data-public
            />
            <PostAppealFormConfigFieldWrapper fieldSpec={formFieldConfigurations?.requestorType?.fieldSpec}>
              <SingleSelectDropdown
                data-testid="requestor-type"
                fullWidth
                label={formFieldConfigurations?.requestorType?.fieldName || "Requestor type"}
                value={appealNoteFormState.requestorType}
                options={RequestorTypeOptions}
                onChange={(requestorType) =>
                  updateAppealNoteFormState({ type: "updateRequestorType", value: requestorType })
                }
                className={classes.requestorType}
              />
            </PostAppealFormConfigFieldWrapper>
            <Grid container spacing={2} className={classes.postAppealDate}>
              <PostAppealFormConfigFieldWrapper fieldSpec={formFieldConfigurations?.appealInitiationDate?.fieldSpec}>
                <Grid item xs={6}>
                  <DateSelect
                    testId="appeal-received-date"
                    attemptedSubmit={attemptedSubmit}
                    TextFieldProps={{ fullWidth: true }}
                    maxDate={new Date()}
                    maxDateMessage={"Date must be today or earlier"}
                    label={formFieldConfigurations?.appealInitiationDate?.fieldName || "Initiation date"}
                    value={appealNoteFormState.appealInitiationDate ?? null}
                    error={attemptedSubmit && !appealNoteFormState.appealInitiationDate}
                    showInternalErrorsOnBlur
                    onDateChange={(initiationDate) =>
                      updateAppealNoteFormState({ type: "updateAppealInitiationDate", value: initiationDate })
                    }
                  />
                </Grid>
              </PostAppealFormConfigFieldWrapper>
              <PostAppealFormConfigFieldWrapper fieldSpec={formFieldConfigurations?.appealDeterminationDate?.fieldSpec}>
                <Grid item xs={6}>
                  <DateSelect
                    testId="appeal-determination-date"
                    attemptedSubmit={attemptedSubmit}
                    TextFieldProps={{ fullWidth: true }}
                    minDate={appealNoteFormState.appealInitiationDate}
                    minDateMessage={"Determination date must be later than initiation date"}
                    label={formFieldConfigurations?.appealDeterminationDate?.fieldName || "Determination date"}
                    value={appealNoteFormState.appealDeterminationDate ?? null}
                    onDateChange={(determinationDate) =>
                      updateAppealNoteFormState({ type: "updateAppealDeterminationDate", value: determinationDate })
                    }
                  />
                </Grid>
              </PostAppealFormConfigFieldWrapper>
            </Grid>
            {appealNoteFormState.postAppealType === "QIO" && (
              <PostAppealFormConfigFieldWrapper fieldSpec={formFieldConfigurations?.coverageEndedBy?.fieldSpec}>
                <SingleSelectDropdown
                  data-testid="coverage-ended-by"
                  fullWidth
                  label={formFieldConfigurations?.coverageEndedBy?.fieldName || "Coverage ended by"}
                  value={appealNoteFormState.coverageEndedBy}
                  options={getCoverageEndedByOptions()}
                  onChange={(coverageEndedBy) =>
                    updateAppealNoteFormState({ type: "updateCoverageEndedBy", value: coverageEndedBy })
                  }
                  className={classes.coverageEndedBy}
                />
              </PostAppealFormConfigFieldWrapper>
            )}
          </>
        )}
        {appealNoteFormState.appealType && (
          <AddAppealAttachment
            serviceRequestId={serviceRequest.id}
            attachments={appealNoteFormState.attachments ?? []}
            appealNoteFormState={appealNoteFormState}
            updateAppealNoteFormState={updateAppealNoteFormState}
            allowedFileExtensions={decisionAppealsConfiguration?.appealNoteForm?.allowedFileExtensions}
          />
        )}
      </Grid>
      <ReviewControlBoxOuterContainer matchesRight={matchesRight} note>
        <ReviewControlBoxInnerContainer center>
          <SecondaryButton
            className={classes.discardButton}
            warning
            disabled={openSubmissionModal || createAppealLoading}
            onClick={onDiscard}
            data-testid="post-appeal-note-discard-changes-button"
          >
            Discard changes
          </SecondaryButton>
          <PrimaryButton
            className={classes.finishButton}
            disabled={!isValidAppealNote || openSubmissionModal || createAppealLoading}
            loading={createAppealLoading}
            data-testid="post-appeal-note-save-button"
            onClick={onFinishNote}
          >
            Finish note
          </PrimaryButton>
        </ReviewControlBoxInnerContainer>
      </ReviewControlBoxOuterContainer>
      {serviceRequest && (
        <AppealNoteSubmissionModal
          currentAppeal={currentAppeal}
          openSubmissionModal={openSubmissionModal}
          setOpenSubmissionModal={setOpenSubmissionModal}
          serviceRequest={serviceRequest}
          appealNoteFormState={appealNoteFormState}
          updateAppealNoteFormState={updateAppealNoteFormState}
          appeals={appeals}
        />
      )}
    </Grid>
  );
};
