import { SVGProps } from "react";

export default function MedicalIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20 39.7656C30.9163 39.7656 39.7656 30.9163 39.7656 20C39.7656 9.08375 30.9163 0.234375 20 0.234375C9.08375 0.234375 0.234375 9.08375 0.234375 20C0.234375 30.9163 9.08375 39.7656 20 39.7656Z"
        fill="black"
        opacity="0.04"
      />
      <path
        d="M28.3906 27.8594V14.8437C28.3906 12.9062 27.7578 12.5625 26.9766 12.5625C26.1953 12.5625 25.5625 13.1953 25.5625 13.9766V21.6562C25.5625 24.1406 23.5391 26.1641 21.0547 26.1641H18.4063C16 26.1641 14.0312 24.2734 13.9062 21.8906H15.0937C15.2187 23.6094 16.6563 24.9766 18.4063 24.9766H21.0547C22.8828 24.9766 24.375 23.4844 24.375 21.6562V13.9766C24.375 12.5391 25.5391 11.375 26.9766 11.375C28.4141 11.375 29.5781 12.25 29.5781 14.8437V27.8594H28.3906Z"
        fill="#56657F"
        stroke="#56657F"
        strokeWidth="0.5"
      />
      <path
        d="M11.8672 21.375V19.7812H17.1172V21.375C17.1172 21.9688 16.6406 22.4453 16.0469 22.4453H12.9375C12.3438 22.4453 11.8672 21.9688 11.8672 21.375Z"
        fill="#F83275"
      />
      <path
        d="M28.9844 31.8281C30.5075 31.8281 31.7422 30.5934 31.7422 29.0703C31.7422 27.5472 30.5075 26.3125 28.9844 26.3125C27.4613 26.3125 26.2266 27.5472 26.2266 29.0703C26.2266 30.5934 27.4613 31.8281 28.9844 31.8281Z"
        fill="#00A9D1"
        stroke="#00A9D1"
        strokeWidth="0.5"
      />
      <path
        d="M28.9844 30.2969C29.6618 30.2969 30.2109 29.7477 30.2109 29.0703C30.2109 28.3929 29.6618 27.8438 28.9844 27.8438C28.307 27.8438 27.7578 28.3929 27.7578 29.0703C27.7578 29.7477 28.307 30.2969 28.9844 30.2969Z"
        fill="#56657F"
      />
      <path
        d="M20.4609 14.5853V9.91346V9.90565C20.4609 9.89783 20.4609 9.89002 20.4609 9.89002C20.4609 9.17908 19.3047 8.58533 17.7578 8.4369C17.3594 8.39783 17.0156 8.71033 17.0156 9.10877V10.6635C17.0156 11.0619 17.3594 11.3744 17.7578 11.3353C18.3281 11.2806 18.8438 11.1635 19.2656 11.0072V14.5853C19.2656 14.7572 19.2578 14.9369 19.2344 15.1088H20.4219C20.4453 14.9369 20.4609 14.765 20.4609 14.5853Z"
        fill="#56657F"
        stroke="#56657F"
        strokeWidth="0.5"
      />
      <path
        d="M9.71094 14.5853V11.0072C10.1328 11.1635 10.6563 11.2806 11.2188 11.3353C11.6172 11.3744 11.9609 11.0619 11.9609 10.6635V9.10877C11.9609 8.71033 11.6172 8.39783 11.2188 8.4369C9.78906 8.56971 8.6875 9.09315 8.53906 9.73377C8.52344 9.76502 8.51562 9.78846 8.51562 9.82752V14.5931C8.51562 14.765 8.52344 14.9447 8.53906 15.1166H9.72656C9.71875 14.9369 9.71094 14.765 9.71094 14.5853Z"
        fill="#56657F"
        stroke="#56657F"
        strokeWidth="0.5"
      />
      <path
        d="M19.2422 15.1094C18.9844 17.5078 16.9531 19.3672 14.4922 19.3672C12.0312 19.3672 10 17.5 9.74219 15.1094H8.55469C8.82031 18.1641 11.375 20.5547 14.5 20.5547C17.6172 20.5547 20.1797 18.1562 20.4453 15.1094H19.2422Z"
        fill="#56657F"
        stroke="#56657F"
        strokeWidth="0.5"
      />
    </svg>
  );
}
