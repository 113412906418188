import {
  ContactInfoT,
  CoveragesSectionT,
  NewPatientFormSectionT,
  PatientInfoT,
  ProviderOption,
  formattedDate,
} from "../formOptions";
import { DateSelect, SelectOptionsHook, SingleSelect, SingleSelectDropdown, TextField } from "@coherehealth/common";

import { GridProps, Grid, TextField as DependentCode } from "@mui/material";
import { useAddNewPatientFormStyles } from "../addNewPatientStyles";

type PatientInfo = {
  sectionData: PatientInfoT;
};
type CoveragesInfo = {
  sectionData: CoveragesSectionT;
};
type ContactInfo = {
  sectionData: ContactInfoT;
};
interface FormSection {
  section: PatientInfo | CoveragesInfo | ContactInfo;
  inputs: NewPatientFormSectionT[];
  inputItemGridStyleProps?: GridProps;
}

export const RenderFormSection = ({ section, inputs, inputItemGridStyleProps }: FormSection) => {
  const addNewPatientStyles = useAddNewPatientFormStyles({});

  const renderInputs = (options: NewPatientFormSectionT[]) => {
    return options.map((opt) => {
      switch (opt.input) {
        case "date":
          return (
            <Grid
              key={opt.name}
              item
              xs={inputItemGridStyleProps?.xs}
              paddingRight={inputItemGridStyleProps?.paddingRight}
            >
              <DateSelect
                onDateChange={(e) => {
                  opt?.onDateChange?.(e);
                  opt?.handleSelectChange?.(opt.name, formattedDate(e));
                }}
                value={(opt.value as Date) || null}
                TextFieldProps={{
                  fullWidth: true,
                  name: opt.name,
                  value: opt.value,
                  onChange: (e) => {
                    opt?.onChange?.(e.target.value);
                    opt?.handleSelectChange?.(opt.name, formattedDate(opt?.value as Date));
                  },
                }}
                label={opt.label}
                error={opt.error}
                attemptedSubmit={opt.error}
                maxDate={opt.name === "PLAN_END_DATE" ? new Date("12/31/10000") : null}
              />
            </Grid>
          );
        case "select":
          return (
            <Grid
              key={opt.name}
              item
              xs={inputItemGridStyleProps?.xs}
              paddingRight={inputItemGridStyleProps?.paddingRight}
            >
              <SingleSelectDropdown
                label={opt.label}
                options={opt?.options}
                name={opt.name}
                value={opt.value as string}
                onChange={(e) => {
                  opt?.handleSelectChange?.(opt.name, e);
                }}
                error={opt.error}
              />
            </Grid>
          );
        case "text":
          return (
            <Grid
              key={opt.name}
              item
              xs={inputItemGridStyleProps?.xs}
              paddingRight={inputItemGridStyleProps?.paddingRight}
            >
              <TextField
                fullWidth
                name={opt.name}
                label={opt.label}
                value={opt.value}
                autoFocus={Boolean(opt.value)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => opt?.handleInputChange?.(e)}
                type="text"
                error={opt.error}
              />
            </Grid>
          );
        case "providerOption":
          return (
            <Grid
              key={opt.name}
              item
              xs={inputItemGridStyleProps?.xs}
              paddingRight={inputItemGridStyleProps?.paddingRight}
            >
              <SingleSelect<ProviderOption>
                disablePortal
                markSelectedOptions={false}
                clearable
                label={opt.label}
                selectedValue={opt.value as ProviderOption}
                setSelectedValue={(value: ProviderOption | null) => opt?.setSelectedValue?.(value as ProviderOption)}
                useOptions={opt.useOptions as SelectOptionsHook<ProviderOption>}
                getOptionLabel={opt.getOptionLabel}
                error={opt.error}
              />
            </Grid>
          );
        case "none":
          return (
            <Grid
              key={opt.name}
              item
              xs={inputItemGridStyleProps?.xs}
              paddingRight={inputItemGridStyleProps?.paddingRight}
            >
              <DependentCode
                className={addNewPatientStyles.dependentCode}
                fullWidth
                name={opt.name}
                label={opt.label}
                value={opt.value}
                InputProps={{ readOnly: true, sx: { cursor: "none", pointerEvents: "none" } }}
                error={opt.error}
              />
            </Grid>
          );
        case "singleSelect":
          return (
            <Grid
              key={opt.name}
              item
              xs={inputItemGridStyleProps?.xs}
              paddingRight={inputItemGridStyleProps?.paddingRight}
            >
              <SingleSelect
                id={opt.name}
                label={opt.label}
                selectedValue={opt.value}
                useOptions={opt.useOptions}
                setSelectedValue={(option) => {
                  opt.setSelectedValue(opt.name, option);
                }}
                renderOption={opt.renderOption}
                isLazyLoaded={false}
                error={opt.error}
                disablePortal
              />
            </Grid>
          );
        default:
          return <Grid key="noOptions" />;
      }
    });
  };

  return <>{renderInputs(inputs)}</>;
};
