import { H4, Checkbox, formatDateToISODate, DateSelect, MAX_DATE_SELECT_DATE, useFeature } from "@coherehealth/common";
import { Divider, Grid, useTheme } from "@material-ui/core";
import { ServiceRequestFormContent } from "components/ServiceRequest";
import ServiceRequestFormRedirectModal, {
  useServiceRequestFormRedirectDispatch,
} from "components/ServiceRequest/ServiceRequestForm/ServiceRequestFormRedirectModal";
import { ObjectID } from "bson";
import debounce from "lodash/debounce";
import CareTypeSelect from "components/ServiceRequest/ServiceRequestForm/components/CareTypeSelect";
import { ExistingRequestExpedited, RequestExpedited } from "components/ServiceRequest/ServiceRequestForm/Expedited";
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import AuthTypeSelect from "./AuthTypeSelect";
import { sameProviderCheckboxClick } from "util/serviceRequest";
import { checkOnetimeRequestCoverage, getPatientHealthPlanName } from "util/patientUtils";
import { Patient } from "@coherehealth/core-platform-api";
import { SuggestionContext } from "../SuggestionContext";
import { convertStringToAuthBuilderWorkflowStep, onCareTypeSwitch } from "../common";
import {
  OrderingProviderSelectManual,
  PerformingProviderSelectManual,
} from "components/ServiceRequest/ServiceRequestForm/components/ProviderSelectManual";
import FacilitySelectManual from "components/ServiceRequest/ServiceRequestForm/components/FacilitySelectManual";
import { EXPEDITE_STATUS_OPTIONS } from "components/ServiceRequest/ServiceRequestForm/components/ExpeditedStatusModal";

interface Props {
  content: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  onUserEdit?: Dispatch<ServiceRequestFormContent>;
  setCanBeSubmitted: (b: boolean) => void;
  attemptedSubmit: boolean;
  patientHealthPlanName?: string | null;
  patient?: Patient;
  defaultStartDate: Date | null;
  setDefaultStartDate: (date: Date | null) => void;
  defaultIsInpatient?: boolean;
  setDefaultIsInpatient: (b: boolean | undefined) => void;
}
const MIN_START_DATE = new Date(2020, 0, 1);

export default function ServiceRequestFaxForm({
  content,
  setFormContent,
  onUserEdit,
  setCanBeSubmitted,
  attemptedSubmit,
  patientHealthPlanName,
  patient,
  defaultStartDate,
  defaultIsInpatient,
  setDefaultIsInpatient,
  setDefaultStartDate,
}: Props) {
  const { spacing } = useTheme();

  const hasOrderingProvider = Boolean(content.orderingProvider);
  const hasPerformingProvider = Boolean(content.performingProvider);
  const hasFacility = Boolean(content.facility) || !content.isInpatient;
  const hasAuthType = Boolean(content.authorizationType);
  const highmarkSuggestedValueFeatureEnabled = useFeature("highmarkSuggestedValueFeatureEnabled");

  const [sameProviders, setSameProviders] = useState(false);
  /**
   * This sets the service request form content, but it also runs the onUserEdit callback.
   *
   * This is useful if we want to do something after the user explicitly edits the service request form content, not
   * just whenever the serviceRequestForm content changes (like from a PAR check effect or whatever)
   * @param newFormContent
   */
  const setFormContentOnUserEdit: Dispatch<SetStateAction<ServiceRequestFormContent>> = useCallback(
    (setStateAction) => {
      // In order to get the next state value to pass to onUserEdit, we actually need to call setFormContent
      // because we might in fact have a function, not a realized value
      setFormContent((prev) => {
        const newFormContent: ServiceRequestFormContent =
          typeof setStateAction === "function" ? setStateAction(prev) : setStateAction;
        onUserEdit?.(newFormContent);
        return newFormContent;
      });
    },
    [onUserEdit, setFormContent]
  );

  const coverageCheck = checkOnetimeRequestCoverage(patient?.coverages || [], content.startDate);

  const [workflowId] = useState(new ObjectID().toString());

  const {
    shouldWaitForRuleRunResult,
    dispatchUrgencyRuleCheck,
    redirectModalProps,
    dispatchRedirectRuleCheck,
    redirectModalProps: { redirectRuleAction },
  } = useServiceRequestFormRedirectDispatch({
    workflowId,
    formContent: content,
    healthPlanName: getPatientHealthPlanName(patient, content.startDate) || "",
    patient,
    clinicalService: undefined,
    authStage: convertStringToAuthBuilderWorkflowStep("FILL_FORMS_FAX"),
  });

  // The react exhaustive deps rule, through no fault of its own, is unable to parse this syntax
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const runPatientSearchRuleCheck = useCallback(
    debounce(
      (newDate: Date) => {
        dispatchRedirectRuleCheck("patient search")({
          // This is a bit of a hack: since we do not have a "start date" target in rule actions,
          // use this one (closest field to this)
          displayTarget: "FACILITY_CATEGORY",
          authStage: convertStringToAuthBuilderWorkflowStep("FILL_FORMS_FAX"),
          serviceRequest: {
            patient,
            ...(!!formatDateToISODate(newDate) ? { startDate: formatDateToISODate(newDate) } : {}),
            clinicalService: undefined,
            workflowId,
          },
        });
      },
      500,
      { trailing: true }
    ),
    [dispatchRedirectRuleCheck, patient, workflowId]
  );
  const onChangeDate = useCallback(
    (newDate: Date) => {
      setDefaultStartDate(newDate);
      if (newDate) {
        setFormContentOnUserEdit((prev) => ({ ...prev, startDate: newDate }));
        runPatientSearchRuleCheck(newDate);
      }
    },
    [runPatientSearchRuleCheck, setDefaultStartDate, setFormContentOnUserEdit]
  );

  // Run the patient rule run once on load
  const [haveRunInitialRuleRun, setHaveRunInitialRuleRun] = useState(false);
  useEffect(() => {
    if (!haveRunInitialRuleRun && content.startDate) {
      runPatientSearchRuleCheck(content.startDate);
      setHaveRunInitialRuleRun(true);
    }
  }, [content.startDate, haveRunInitialRuleRun, runPatientSearchRuleCheck]);

  const validateFields = () => {
    if (shouldWaitForRuleRunResult) {
      return false;
    }

    // Cannot submit with an active redirect firing
    if (Boolean(redirectRuleAction)) {
      return false;
    }
    // Cannot submit if no coverage
    if (!coverageCheck.inRange) {
      return false;
    }

    if (
      content.authorizationType !== undefined &&
      content.performingProvider !== null &&
      content.orderingProvider !== null &&
      defaultIsInpatient !== undefined &&
      defaultStartDate !== null
    ) {
      if (content.isInpatient) {
        return content.facility !== null;
      } else {
        return true;
      }
    }
    //should never happen, but if it does we should block the user from trying to submit
    return false;
  };
  const isValid = validateFields();

  const handleFormValidation = useCallback(
    (isFormValid: boolean) => {
      setCanBeSubmitted(isFormValid);
    },
    [setCanBeSubmitted]
  );

  useEffect(() => {
    handleFormValidation(isValid);
  }, [isValid, handleFormValidation]);

  const { suggestedPriorAuthRequirements, suggestedFormContent } = useContext(SuggestionContext);
  const setFormContentCallBack = useCallback(
    (formContentStuff: any) => {
      setFormContent(formContentStuff);
    },
    [setFormContent]
  );

  const setDefaultIsInpatientCallBack = useCallback(
    (defaultIsInpatientObj: boolean) => {
      setDefaultIsInpatient(defaultIsInpatientObj);
    },
    [setDefaultIsInpatient]
  );

  const setDefaultStartDateCallBack = useCallback(
    (defaultStartDateObj: Date | null) => {
      setDefaultStartDate(defaultStartDateObj);
    },
    [setDefaultStartDate]
  );

  useEffect(() => {
    if (highmarkSuggestedValueFeatureEnabled) {
      const suggestedEncounterType = suggestedPriorAuthRequirements?.encounterType?.predictedValue?.toLowerCase();
      const suggestedIsInpatientOrOutpatient =
        suggestedEncounterType === "outpatient" || suggestedEncounterType === "inpatient";
      const suggestedStartDate = suggestedPriorAuthRequirements?.startDate?.dateEntity;
      const suggestedOrderingProvider = suggestedFormContent?.orderingProvider?.providerEntity;
      const suggestedPerformingProvider = suggestedFormContent?.performingProvider?.providerEntity;
      const suggestedFacility = suggestedFormContent?.facility?.facilityEntity;
      const suggestedIsExpedited = suggestedFormContent?.isExpedited?.booleanEntity;
      const suggestedCaseType = suggestedFormContent?.caseType?.stringEntity?.toUpperCase();

      setFormContentCallBack((prev: ServiceRequestFormContent) => ({
        ...prev,
        isInpatient: suggestedIsInpatientOrOutpatient ? suggestedEncounterType === "inpatient" : prev.isInpatient,
        startDate: suggestedStartDate ? suggestedStartDate : prev.startDate,
        orderingProvider: suggestedOrderingProvider ? suggestedOrderingProvider : prev.orderingProvider,
        performingProvider: suggestedPerformingProvider ? suggestedPerformingProvider : prev.performingProvider,
        facility: suggestedFacility ? suggestedFacility : prev.facility,
        isExpedited: suggestedIsExpedited ? suggestedIsExpedited : prev.isExpedited,
        expeditedReason: suggestedIsExpedited ? EXPEDITE_STATUS_OPTIONS[0].label : prev.expeditedReason,
        authorizationType: suggestedCaseType ? suggestedCaseType : prev.authorizationType,
      }));

      setSameProviders(
        suggestedOrderingProvider?.npi && suggestedPerformingProvider?.npi
          ? suggestedOrderingProvider?.npi === suggestedPerformingProvider?.npi
          : false
      );

      if (suggestedIsInpatientOrOutpatient) {
        setDefaultIsInpatientCallBack(suggestedEncounterType === "inpatient");
      }
      if (suggestedStartDate) {
        setDefaultStartDateCallBack(suggestedStartDate || null);
      }
    }
  }, [
    suggestedPriorAuthRequirements,
    suggestedFormContent,
    setFormContentCallBack,
    highmarkSuggestedValueFeatureEnabled,
    setDefaultIsInpatientCallBack,
    setDefaultStartDateCallBack,
  ]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CareTypeSelect
            inpatientValue={defaultIsInpatient}
            formContent={content}
            onCareTypeSwitch={(val: string) => {
              if (val && val !== "") {
                setDefaultIsInpatient(val === "true");
                onCareTypeSwitch(val, setFormContentOnUserEdit);
              }
            }}
            patientHealthPlanName={patientHealthPlanName}
            authStatus="DRAFT"
            label="Request details"
          />
        </Grid>
        <Grid item xs={12}>
          <DateSelect
            label="Expected start date"
            value={defaultStartDate}
            onDateChange={onChangeDate}
            minDate={MIN_START_DATE}
            maxDate={MAX_DATE_SELECT_DATE}
            error={!coverageCheck.inRange}
            warning={Boolean(coverageCheck.messageToDisplay?.[0])}
            helperText={coverageCheck.messageToDisplay?.[0]}
          />
        </Grid>
        <Grid item xs={12}>
          <AuthTypeSelect
            error={attemptedSubmit && !hasAuthType}
            authType={content.authorizationType}
            requestUpdateAuthType={(authType) => setFormContent({ ...content, authorizationType: authType })}
            fullWidth={false}
          />
        </Grid>
        <Grid item>
          <H4 style={{ marginTop: "16px" }}>Providers</H4>
        </Grid>
        <Grid item xs={12}>
          <OrderingProviderSelectManual
            error={attemptedSubmit && !hasOrderingProvider}
            formContent={content}
            setFormContent={setFormContent}
            patient={patient}
            dispatchRedirectRuleCheck={() => Promise.resolve()}
            sameProviders={sameProviders}
            setSameProviders={setSameProviders}
            showTinAndAddressField={false}
          />
          <Checkbox
            style={{ marginBottom: "-9px" }}
            checked={sameProviders}
            onChange={(checked) => {
              if (content.orderingProvider !== null) {
                sameProviderCheckboxClick(setFormContentOnUserEdit, setSameProviders, checked);
              }
            }}
            label="Performing is the same as ordering"
          />
        </Grid>
        <Grid item xs={12}>
          <PerformingProviderSelectManual
            providerError={attemptedSubmit && !hasPerformingProvider}
            formContent={content}
            setFormContent={setFormContent}
            sameProviders={sameProviders}
            patient={patient}
            setSameProviders={setSameProviders}
            authStatus={"DRAFT"}
            isOptional={false}
            showAddressField={false}
            hideTinField={true}
          />
        </Grid>
        <Grid item xs={12}>
          <FacilitySelectManual
            facilityError={attemptedSubmit && !hasFacility}
            formContent={content}
            setFormContent={setFormContent}
            patient={patient}
            isOptional={!content.isInpatient}
            showAddressField={false}
            hideTinField={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ marginTop: spacing(3), marginBottom: "8px" }} />
        </Grid>
        <Grid item xs={12}>
          {content.id && (
            <ExistingRequestExpedited
              isExpedited={content.isExpedited}
              setIsExpedited={(isExpedited: boolean, reason: string) => {
                setFormContent({ ...content, isExpedited: isExpedited, expeditedReason: reason });
                dispatchUrgencyRuleCheck(isExpedited);
              }}
              authStatus={"DRAFT"}
              serviceRequestId={content.id}
              startDate={content.startDate}
              patientHealthPlanName={undefined}
            />
          )}
          {!Boolean(content.id) && (
            <RequestExpedited
              isExpedited={content.isExpedited}
              setIsExpedited={(isExpedited: boolean, reason: string) => {
                setFormContent({ ...content, isExpedited: isExpedited, expeditedReason: reason });
                dispatchUrgencyRuleCheck(isExpedited);
              }}
              authStatus={"DRAFT"}
              startDate={content.startDate}
              patientHealthPlanName={undefined}
            />
          )}
        </Grid>
      </Grid>
      <ServiceRequestFormRedirectModal
        {...redirectModalProps}
        formContent={content}
        setFormContent={setFormContentOnUserEdit}
      />
    </>
  );
}
