import React from "react";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { Body1, Sanitized } from "@coherehealth/common";
import { Label } from "components/ServiceRequest/ReadonlyDetail/FormElements";
import { GenericAuditReview } from "components/ClinicalReview/reviewUtils/useReviews";

interface Props {
  auditReview: GenericAuditReview;
  hideAuditNotes?: boolean;
}

export default function AuditReviewReadOnly({ auditReview, hideAuditNotes }: Props) {
  return (
    <Grid container spacing={2}>
      {!hideAuditNotes && (
        <>
          <Grid item xs={12}>
            <Label>Audit notes</Label>
            <Body1 style={{ marginTop: -16, marginBottom: -16 }} component="div">
              <Sanitized __html={auditReview?.auditNotes || "<p>- -</p>"} />
            </Body1>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Label>Audit outcome</Label>
        <Body1>{auditReview.auditOutcome}</Body1>
      </Grid>
    </Grid>
  );
}
