import { Model, Factory, Server } from "miragejs";

const models = {
  palCategory: Model,
};
const factories = {
  palCategory: Factory.extend({
    name(i: number) {
      return `PAL Category ${i}`;
    },
    description(i: number) {
      return `Description ${i}`;
    },
  }),
};

const scenarios = {
  standard(server: Server) {
    server.create("palCategory", {
      name: "Therapy: physical, speech, and occupational",
    } as any);
    server.create("palCategory", {
      name: "Test empty description",
      description: "",
    } as any);
    server.create("palCategory", {
      name: "Test Long Name Long Name Long Name Long Name Long Name Long Name Long Name Long Name Long Name Long Name Long Name Long Name.",
      description:
        "Test Long Desc Long Desc Long Desc Long Desc Long Desc Long Desc Long Desc Long Desc Long Desc Long Desc Long Desc Long Desc.",
    } as any);
    server.createList("palCategory", 30);
  },
};

const activeScenario = "standard";
//const activeScenario = "short";
//const activeScenario = "empty";

export { models, factories, scenarios, activeScenario };
