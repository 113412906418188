import { RadioGroupOption, SingleSelectDropdown } from "@coherehealth/common";
import { ChangeStatusFormState, FilteredPendingReason, isPendingReason } from "../utils";

interface PendingReasonSelectDropdownProps {
  allowedPendingReasons: RadioGroupOption<string>[];
  filteredPendingReasons: FilteredPendingReason[];
  formState: ChangeStatusFormState;
  setFormState: (value: React.SetStateAction<ChangeStatusFormState>) => void;
}

export function PendingReasonSelectDropdown(props: PendingReasonSelectDropdownProps): JSX.Element {
  const { allowedPendingReasons, filteredPendingReasons, formState, setFormState } = props;

  return (
    <SingleSelectDropdown
      label="Choose pending reason"
      value={formState.pendingReason}
      options={allowedPendingReasons.length ? allowedPendingReasons : filteredPendingReasons}
      onChange={(reason) => {
        if (isPendingReason(reason)) {
          setFormState((prevFormState) => ({
            ...prevFormState,
            pendingReason: reason,
          }));
        }
      }}
      data-testid="pending-reason-select-dropdown"
    />
  );
}
