import React, { ComponentProps, FunctionComponent, useCallback, useRef, useState } from "react";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import {
  PhoneNumber,
  Address,
  PostgridAddressVerificationResponseData,
  useGetPostgridAddressVerification,
  PostgridAddressVerificationBody,
  PostgridAddressVerificationResponse,
} from "@coherehealth/core-platform-api";
import CloseIcon from "@material-ui/icons/Close";
import UnitedStatesStateSelection from "components/ProviderOrganization/UnitedStatesStateSelection";
import PhoneInput from "common/PhoneInput";
import { isValidEmail, isZipcodeValid } from "util/providerUtils";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { H2, TextField, PrimaryButton, colorsLight } from "@coherehealth/common";
import MuiIconButton from "@material-ui/core/IconButton";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { useSnackbar } from "notistack";
import { Divider } from "@material-ui/core";
import PostGridAddressSearchResult from "components/ServiceRequest/PostGridAddressSearchResult";
import { ContactAddressFields } from "components/AuthBuilder/FillFormsAddNewPatient/FormSections/ContactAddressFormSection";

interface Props extends Pick<DialogProps, "open" | "onClose"> {
  onSave(address: Address): void;
  aorAddress?: boolean;
}

export type ContactAddressModalContent = {
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  contactNumber: PhoneNumber | null;
  email: string | null;
};

const ContactAddressModal: FunctionComponent<Props> = ({ onSave, open, onClose = () => {}, aorAddress }) => {
  const [contactAddressModalContent, setContactAddressModalContent] = useState<ContactAddressModalContent>({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    contactNumber: null,
    email: "",
  });

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          style: { borderRadius: "10px" },
        }}
      >
        <DialogTitle>
          <IconButton
            onClick={(e) => {
              onClose(e, "backdropClick");
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Row>
              <H2>{aorAddress ? "Add Representative's address" : "Add contact information"}</H2>
            </Row>
          </Grid>
        </DialogContent>
        <DialogContent style={{ padding: 0 }}>
          <AddressForm
            onSave={onSave}
            onClose={onClose}
            contactAddressModalContent={contactAddressModalContent}
            setContactAddressModalContent={setContactAddressModalContent}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export const AddressForm = ({
  onSave,
  onClose,
  contactAddressModalContent,
  setContactAddressModalContent,
  aorAddress,
  style,
  saveButtonText,
  validateOuterForm,
  isLoadingSave,
}: {
  onSave: (address: Address) => void;
  onClose: (event: any, reason: "backdropClick" | "escapeKeyDown") => void;
  contactAddressModalContent: ContactAddressModalContent;
  setContactAddressModalContent: React.Dispatch<React.SetStateAction<ContactAddressModalContent>>;
  aorAddress?: boolean;
  style?: {
    addressFormGridStyle?: React.CSSProperties;
  };
  saveButtonText?: string;
  validateOuterForm?: () => boolean;
  isLoadingSave?: boolean;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const firstGridRef = useRef<HTMLDivElement>(null);
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [modifiedAddress, setModifiedAddress] = useState<boolean>(false);
  const [postGridAddress, setPostGridAddress] = useState<PostgridAddressVerificationResponseData | null>(null);
  const [showAddressResults, setShowAddressResults] = useState<boolean>(false);
  const [isLoadingSuggestedAddress, setIsLoadingSuggestedAddress] = useState<boolean>(false);

  const { mutate: verify } = useGetPostgridAddressVerification({});
  const postGridValidation = async (): Promise<void> => {
    const requestBody: PostgridAddressVerificationBody = {
      line1: contactAddressModalContent.address1 ?? undefined,
      line2: contactAddressModalContent.address2 ?? undefined,
      country: "us",
      postalOrZip: contactAddressModalContent.zip ?? undefined,
      provinceOrState: contactAddressModalContent.state ?? undefined,
      city: contactAddressModalContent.city ?? undefined,
    };
    setIsLoadingSuggestedAddress(true);
    try {
      let result: PostgridAddressVerificationResponse = await verify(requestBody);
      setIsLoadingSuggestedAddress(false);
      if (result && result.status === "success" && result.data) {
        if (result.data.status && result.data.status.toLowerCase() === "verified") {
          setModifiedAddress(false);
          setPostGridAddress(result.data);
          setShowAddressResults(true);
          setTimeout(() => {
            if (firstGridRef.current) {
              firstGridRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
            }
          }, 0);
          return;
        }
        setPostGridAddress(result.data);
        setShowAddressResults(true);
        setTimeout(() => {
          if (firstGridRef.current) {
            firstGridRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 0);
      }
    } catch (e) {
      let errorMessage = "";
      if (typeof e === "string") {
        errorMessage = e.toUpperCase();
      } else if (e instanceof Error) {
        errorMessage = e.message;
      }
      enqueueSnackbar(`Error occured during address verfication ${errorMessage}`, {
        variant: "error",
        preventDuplicate: true,
      });
      setIsLoadingSuggestedAddress(false);
      setModifiedAddress(false);
    }
  };
  const hasValidZip = isZipcodeValid(contactAddressModalContent?.zip ?? undefined);

  const hasValidAddress =
    contactAddressModalContent.address1 &&
    contactAddressModalContent.city &&
    contactAddressModalContent.state &&
    hasValidZip;

  const getFieldsFromModal = (): ContactAddressFields => {
    const fields: ContactAddressFields = {
      line1: contactAddressModalContent?.address1 || "",
      line2: contactAddressModalContent?.address2 || "",
      city: contactAddressModalContent?.city || "",
      state: contactAddressModalContent?.state || "",
      zipCode: contactAddressModalContent?.zip || "",
      number: contactAddressModalContent?.contactNumber?.number || "",
      extension: contactAddressModalContent?.contactNumber?.extension || "",
      email: contactAddressModalContent?.email || "",
    };
    return fields;
  };

  const validateFields = () => {
    const isOuterFormValid = validateOuterForm?.() ?? true;
    return (
      hasValidAddress &&
      hasValidContact(contactAddressModalContent) &&
      hasValidEmail(contactAddressModalContent) &&
      isOuterFormValid
    );
  };

  const handleSavedAddressIndex = useCallback(() => {
    setModifiedAddress(false);
    setShowAddressResults(false);
    const selectedAddress: PostgridAddressVerificationResponseData | null = postGridAddress;
    if (selectedAddress) {
      setContactAddressModalContent((currentContactAddressModalContent) => ({
        ...currentContactAddressModalContent,
        address1: selectedAddress.line1,
        address2: !!selectedAddress.line2 ? selectedAddress.line2 : "",
        city: selectedAddress.city,
        state: selectedAddress.provinceOrState,
        zip: selectedAddress.details?.usPostnetBarcode?.slice(1, 6) || selectedAddress.postalOrZip,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postGridAddress, contactAddressModalContent]);

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (validateFields() && onSave) {
      onSave(getFieldsFromModal());
    }
    onClose(e, "backdropClick");
  };

  const setAttemptedSubmitTrue = () => {
    setAttemptedSubmit(true);
  };

  return (
    <>
      <Grid container spacing={2} style={{ padding: "24px", ...style?.addressFormGridStyle }}>
        <Row>
          <TextField
            fullWidth
            label="Address line 1"
            name="address1"
            value={contactAddressModalContent.address1}
            onChangeValue={(newAddress1) => {
              setModifiedAddress(true);
              setShowAddressResults(false);
              setContactAddressModalContent((currentContactAddressModalContent) => ({
                ...currentContactAddressModalContent,
                address1: newAddress1,
              }));
            }}
            error={attemptedSubmit && !contactAddressModalContent.address1}
            helperText={
              attemptedSubmit &&
              !contactAddressModalContent.address1 &&
              "Address line 1 is required for the address you entered"
            }
          />
        </Row>
        <Row>
          <TextField
            fullWidth
            label="Address line 2 (optional)"
            name="address2"
            value={contactAddressModalContent.address2}
            onChangeValue={(newAddress2) => {
              setModifiedAddress(true);
              setShowAddressResults(false);
              setContactAddressModalContent((currentContactAddressModalContent) => ({
                ...currentContactAddressModalContent,
                address2: newAddress2,
              }));
            }}
          />
        </Row>
        <HalfRow>
          <TextField
            fullWidth
            label="City"
            name="city"
            value={contactAddressModalContent.city}
            onChangeValue={(newCity) => {
              setModifiedAddress(true);
              setShowAddressResults(false);
              setContactAddressModalContent((currentContactAddressModalContent) => ({
                ...currentContactAddressModalContent,
                city: newCity,
              }));
            }}
            error={attemptedSubmit && !contactAddressModalContent.city}
            helperText={
              attemptedSubmit && !contactAddressModalContent.city && "City is required for the address you entered"
            }
          />
        </HalfRow>
        <StateComponent>
          <UnitedStatesStateSelection
            error={attemptedSubmit && !contactAddressModalContent.state}
            state={contactAddressModalContent.state || ""}
            setState={(state) => {
              setModifiedAddress(true);
              setShowAddressResults(false);
              setContactAddressModalContent((prev) => {
                return { ...prev, state };
              });
            }}
          />
        </StateComponent>
        <ZipComponent>
          <TextField
            fullWidth
            label="Zip"
            name="zip"
            value={contactAddressModalContent.zip}
            onChangeValue={(newZip) => {
              setModifiedAddress(true);
              setShowAddressResults(false);
              setContactAddressModalContent((currentContactAddressModalContent) => ({
                ...currentContactAddressModalContent,
                zip: newZip,
              }));
            }}
            error={!!contactAddressModalContent.zip && !hasValidZip}
            helperText={!!contactAddressModalContent.zip && !hasValidZip && "Zip must be 5 digits"}
          />
        </ZipComponent>
        {!aorAddress && (
          <>
            <Row>
              <PhoneInput
                fullWidth
                label="Phone number (optional)"
                name="contactNumber"
                onChange={(phoneNumber: PhoneNumber) =>
                  setContactAddressModalContent((currentContactAddressModalContent) => ({
                    ...currentContactAddressModalContent,
                    contactNumber: phoneNumber,
                  }))
                }
                value={contactAddressModalContent.contactNumber || {}}
                error={!hasValidContact(contactAddressModalContent)}
                helperText={!hasValidContact(contactAddressModalContent) && "Phone number value must be 10 digits"}
              />
            </Row>
            <Row>
              <TextField
                fullWidth
                label="Email (optional)"
                name="email"
                value={contactAddressModalContent.email}
                onChangeValue={(newEmail) => {
                  setContactAddressModalContent((currentContactAddressModalContent) => ({
                    ...currentContactAddressModalContent,
                    email: newEmail,
                  }));
                }}
                error={!hasValidEmail(contactAddressModalContent)}
                helperText={!hasValidEmail(contactAddressModalContent) && "Invalid email format"}
              />
            </Row>
          </>
        )}
        <Row>
          <ValidateAndSaveButton
            modifiedAddress={modifiedAddress}
            isLoadingSuggestedAddress={isLoadingSuggestedAddress}
            validateFields={validateFields}
            postGridValidation={postGridValidation}
            setAttemptedSubmitTrue={setAttemptedSubmitTrue}
            onSubmit={onSubmit}
            onClose={onClose}
            saveButtonText={saveButtonText}
            isLoadingSave={isLoadingSave}
          />
        </Row>
      </Grid>
      {showAddressResults && !isLoadingSuggestedAddress ? <Divider /> : null}
      <ResultDialogContent style={{ padding: "24px 0 0 0" }}>
        {showAddressResults && !isLoadingSuggestedAddress ? (
          <div ref={firstGridRef}>
            <ResultContent>
              <PostGridAddressSearchResult
                addressData={postGridAddress}
                onSaveAddressFromSearchResults={handleSavedAddressIndex}
              />
            </ResultContent>
          </div>
        ) : null}
      </ResultDialogContent>
    </>
  );
};

const hasValidEmail = (contactAddressModalContent: ContactAddressModalContent): boolean => {
  if (contactAddressModalContent?.email) {
    return isValidEmail(contactAddressModalContent.email);
  }
  return true;
};

const contactNumberValid = (contactNumber: PhoneNumber | null): boolean => {
  return Boolean(contactNumber && contactNumber.number && contactNumber?.number.length === 10);
};

const hasValidContact = (contactAddressModalContent: ContactAddressModalContent): boolean => {
  if (contactAddressModalContent?.contactNumber && contactAddressModalContent.contactNumber?.number) {
    return contactNumberValid(contactAddressModalContent.contactNumber);
  }
  return true;
};

export default ContactAddressModal;

interface ValidateAndSaveButtonProps {
  modifiedAddress: boolean;
  isLoadingSuggestedAddress: boolean;
  validateFields: () => boolean | "" | null;
  postGridValidation: () => Promise<void>;
  setAttemptedSubmitTrue: () => void;
  onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClose: (event: React.MouseEvent<HTMLButtonElement>, reason: "escapeKeyDown" | "backdropClick") => void;
  saveButtonText?: string;
  isLoadingSave?: boolean;
}

const ValidateAndSaveButton = ({
  modifiedAddress,
  isLoadingSuggestedAddress,
  validateFields,
  postGridValidation,
  setAttemptedSubmitTrue,
  onSubmit,
  onClose,
  saveButtonText = "Save address",
  isLoadingSave,
}: ValidateAndSaveButtonProps) => {
  let buttonContent;

  if (modifiedAddress && !isLoadingSuggestedAddress) {
    buttonContent = (
      <WideButton
        disabled={!validateFields()}
        onClick={() => {
          postGridValidation();
        }}
      >
        Validate address
      </WideButton>
    );
  } else if (modifiedAddress && isLoadingSuggestedAddress) {
    buttonContent = (
      <WideButton loading disabled={!validateFields()}>
        Loading Button
      </WideButton>
    );
  } else {
    buttonContent = (
      <WideButton
        loading={isLoadingSave}
        disabled={!validateFields()}
        onClick={(e) => {
          setAttemptedSubmitTrue();
          if (validateFields()) {
            if (onSubmit) {
              onSubmit(e);
            }
            onClose(e, "backdropClick");
          }
        }}
      >
        {saveButtonText}
      </WideButton>
    );
  }

  return buttonContent;
};

const StateComponent = (props: ComponentProps<typeof Grid>) => (
  <Grid style={{ textAlign: "center" }} item xs={4} {...props} />
);

const ZipComponent = (props: ComponentProps<typeof Grid>) => (
  <Grid style={{ textAlign: "center" }} item xs={2} {...props} />
);

const HalfRow = (props: ComponentProps<typeof Grid>) => <Grid style={{ textAlign: "center" }} item xs={6} {...props} />;

const Row = (props: ComponentProps<typeof Grid>) => <Grid style={{ textAlign: "center" }} item xs={12} {...props} />;

const WideButton = (props: ComponentProps<typeof PrimaryButton>) => {
  const theme = useTheme();
  return (
    <PrimaryButton
      {...props}
      style={{
        width: "40%",
        marginTop: theme.spacing(2),
        whiteSpace: "nowrap",
      }}
    />
  );
};

const ResultContent = (props: ComponentProps<typeof MuiDialogContent>) => {
  const theme = useTheme();

  return (
    <MuiDialogContent
      {...props}
      style={{
        background: colorsLight.background.light,
        padding: `${theme.spacing(3)}px`,
      }}
    />
  );
};

const ResultDialogContent = (props: ComponentProps<typeof MuiDialogContent>) => {
  return (
    <MuiDialogContent
      {...props}
      style={{
        background: colorsLight.background.light,
        overflow: "visible",
      }}
    />
  );
};

const DialogContent = (props: ComponentProps<typeof MuiDialogContent>) => {
  const theme = useTheme();

  return (
    <MuiDialogContent
      {...props}
      style={{
        padding: `${theme.spacing(3)}px`,
        overflow: "visible",
        ...props.style,
      }}
    />
  );
};

const DialogTitle = (props: ComponentProps<typeof MuiDialogTitle>) => (
  <MuiDialogTitle
    {...props}
    style={{
      padding: 0,
    }}
  />
);

const IconButton = (props: ComponentProps<typeof MuiIconButton>) => {
  const theme = useTheme();

  return (
    <MuiIconButton
      {...props}
      style={{
        float: "right",
        right: theme.spacing(1),
        top: theme.spacing(1),
      }}
    />
  );
};
