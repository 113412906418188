import { DropdownOption } from "../components/Dropdown";

const DEFAULT_HOLD_REASON: DropdownOption = { id: "OTHER", label: "Other" };

/**
 * Compute hold reason options from the hold reasons queue config and adds default option "OTHER".
 */
export const computeHoldReasonOptions = (holdReasons?: string[]): DropdownOption[] => {
  const optionsFromConfig =
    holdReasons?.map((reason) => {
      const id = reason
        .toUpperCase()
        .replace(/\s+/g, "_")
        .replace(/[^\w_]/g, "");

      return { id: id, label: reason };
    }) || [];

  return [...optionsFromConfig, DEFAULT_HOLD_REASON];
};
