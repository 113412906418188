import { useEffect, useState, createContext } from "react";
import {
  PeerToPeerOutreach,
  ReviewType,
  useGetPatient,
  MdReview,
  NurseReview,
  PeerToPeerReview,
  ClinicalReviewChangeRequest,
  Coverage,
  Patient,
  useGetAllReviews,
} from "@coherehealth/core-platform-api";
import { GetDataError } from "restful-react";
import {
  InformativeModal,
  useFeature,
  UserClickInfoClinicalReviewPage,
  getEmptyUserClickTracking,
  NotFoundPage,
  useGetServiceRequestByIdWithFallback,
} from "@coherehealth/common";
import { useInitReview } from "../reviewUtils/useInitCurrentReview";
import { useSnackbar } from "notistack";
import { useAuthorized } from "authorization";
import LoadingDialog from "../LoadingDialog";
import SlimHeader from "../SlimHeader";
import ClinicalReviewShell from "common/ClinicalReviewShell";
import ReviewLeftPanel from "../reviewUtils/ReviewLeftPanel";
import { useGetServiceRequestCases, ServiceCase, useGetServiceCase } from "@coherehealth/qm-api";
import config from "api/config";
import { LeftPanelTabs, useAuthorizationFetch } from "components/ClinicalReview/reviewUtils/utils";
import { useAttachments } from "../reviewUtils/useAttachment";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";
import useClaimHistory from "components/ClaimHistory/useClaimHistory";
import useOtherAuthorizations from "../ClinicalReviewInfoPanel/OtherServiceRequests/useOtherAuthorization";
import { useParams, useSearchParams } from "react-router-dom";
import FaxAuditPageSidebar from "./FaxAuditPageSidebar";
import { ReviewsProvider } from "../reviewUtils/useReviews";
import { LeftReviewTabs } from "../reviewUtils/LeftReviewTabs";

export interface PeerToPeerOutreachReviewType extends PeerToPeerOutreach {
  reviewType: "P2POutreach";
  reviewStatus: "";
}

export type ReviewListTypes = (ReviewType | PeerToPeerOutreachReviewType)[];

type ClinicalReviewContextType = {
  latestReviewLeadingToDenial: MdReview | NurseReview | PeerToPeerReview | null;
  latestChangeRequest: ClinicalReviewChangeRequest | null;
  latestChangeRequestReady?: boolean;
  clinicalReviewChangeRequestReady?: boolean;
  patientPrimaryCoverage?: Coverage | null;
  patient?: Patient | null;
};

export const ClinicalReviewContext = createContext<ClinicalReviewContextType>({
  latestReviewLeadingToDenial: null,
  latestChangeRequest: null,
  latestChangeRequestReady: false,
  clinicalReviewChangeRequestReady: false,
  patientPrimaryCoverage: null,
  patient: null,
});

interface FaxAuditPageProps {
  serviceRequestId: string;
  caseId: string | null;
  trackingNumberSearch: boolean;
  existingReviewsError: GetDataError<unknown> | null;
  loadingExistingReviews: boolean;
  existingReviews: ReviewType[] | null;
}

export default function FaxAuditPage() {
  const { serviceRequestId } = useParams();
  const [search] = useSearchParams();

  const caseId = search.get("caseId");
  const trackingNumberSearch = !!search.get("trackingNumberSearch");
  const canAuditFax = useAuthorized("AUDIT_REVIEW");
  const faxAuditQueueEnabled = useFeature("faxAuditQueue");

  const {
    data: existingReviews,
    error: existingReviewsError,
    loading: loadingExistingReviews,
  } = useGetAllReviews({
    serviceRequestId: serviceRequestId || "",
    queryParams: { max: 200 },
  });

  if (canAuditFax && faxAuditQueueEnabled && serviceRequestId) {
    return (
      <ReviewsProvider initialReviews={existingReviews}>
        <FaxAuditPageAuthorized
          serviceRequestId={serviceRequestId}
          caseId={caseId}
          trackingNumberSearch={trackingNumberSearch}
          existingReviewsError={existingReviewsError}
          loadingExistingReviews={loadingExistingReviews}
          existingReviews={existingReviews}
        />
      </ReviewsProvider>
    );
  } else {
    return <NotFoundPage />;
  }
}

function FaxAuditPageAuthorized({
  serviceRequestId,
  caseId,
  trackingNumberSearch,
  existingReviewsError,
  loadingExistingReviews,
  existingReviews,
}: FaxAuditPageProps) {
  const [leftColumnTab, setLeftColumnTab] = useState<LeftPanelTabs>("ATTACHMENTS");
  const [userClickInfoTracking, setUserClickInfoTracking] = useState<UserClickInfoClinicalReviewPage>(
    getEmptyUserClickTracking()
  );
  const [currentCase, setCurrentCase] = useState<ServiceCase>();

  const { enqueueSnackbar } = useSnackbar();

  const {
    data: serviceRequest,
    error: serviceRequestFetchError,
    loading: serviceRequestLoading,
    refetch: serviceRequestRefetch,
  } = useGetServiceRequestByIdWithFallback({
    id: serviceRequestId,
  });

  const {
    data: patient,
    error: patientFetchError,
    loading: patientLoading,
    refetch: patientRefetch,
  } = useGetPatient({
    id: serviceRequest?.patient?.id || "",
    lazy: true,
  });
  const { healthPlanName, encounterType, requestTiming, delegatedVendor } = serviceRequest || {};
  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName,
    encounterType,
    requestTiming,
    delegatedVendorName: delegatedVendor,
  });

  const { data: serviceCases, refetch: getServiceCases } = useGetServiceRequestCases({
    id: serviceRequest?.id || "none",
    base: `${config.QM_SERVICE_API_URL}`,
    lazy: true,
  });

  const { refetch: refetchQmCase } = useGetServiceCase({
    id: caseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
    lazy: true,
  });

  useEffect(() => {
    if (serviceRequest && config.HAS_QM_ENVIRONMENT) {
      getServiceCases();
    }
  }, [getServiceCases, serviceRequest]);

  useEffect(() => {
    const fetchCaseInformation = async () => {
      const activeCase = await refetchQmCase();
      if (activeCase) {
        setCurrentCase(activeCase);
      }
    };
    if (caseId && config.HAS_QM_ENVIRONMENT) {
      fetchCaseInformation();
    }
  }, [caseId, refetchQmCase]);

  useEffect(() => {
    if (serviceRequestFetchError) {
      enqueueSnackbar(`Failed to load service request: ${serviceRequestFetchError.message}`, { variant: "error" });
    }
    if (patientFetchError) {
      enqueueSnackbar(`Failed to load patient information: ${patientFetchError.message}`, { variant: "error" });
    }
    if (existingReviewsError) {
      enqueueSnackbar(`Failed to load existing reviews: ${existingReviewsError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, patientFetchError, serviceRequestFetchError, existingReviewsError]);

  useEffect(() => {
    if (serviceRequest) {
      patientRefetch();
    }
  }, [patientRefetch, serviceRequest]);

  const { authorization, fetchAuthorization, authorizationFetchLoading } = useAuthorizationFetch(serviceRequest);
  const {
    attachments,
    attachmentListFetchLoading,
    onSwitchAttachmentTab,
    onViewAttachment,
    setCopiedAttachmentText,
    setSearchInfos,
    searchInfos,
    previousAttachmentsExpanded,
    setPreviousAttachmentsExpanded,
    currentPage,
    zoomLevel,
    currentRotate,
    attachmentIndexOpen,
    openAttachmentWarningModal,
    setOpenAttachmentWarningModal,
    newAttachmentsNumber,
    attachmentsInfo,
    setAttachmentsInfo,
    copiedAttachmentText,
    pastedAttachmentTexts,
    setPastedAttachmentTexts,
  } = useAttachments({
    serviceRequest,
  });

  const { currentReview, loadingCurrentReview, setOpenWarningModal, openWarningModal } = useInitReview({
    serviceRequestId,
    existingReviews,
  });

  const claimHistoryProps = useClaimHistory({ patientId: serviceRequest?.patient?.id });
  const useOtherAuthorizationProps = useOtherAuthorizations({ serviceRequest });

  return (
    <>
      <ClinicalReviewShell
        attachmentView={leftColumnTab === "ATTACHMENTS"}
        header={
          <SlimHeader
            serviceRequest={serviceRequest}
            facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
            serviceCase={currentCase}
          />
        }
        leftColumnTabs={
          <LeftReviewTabs
            leftColumnTab={leftColumnTab}
            setLeftColumnTab={setLeftColumnTab}
            onSwitchAttachmentTab={onSwitchAttachmentTab}
            newAttachmentsNumber={newAttachmentsNumber}
            showClaimsHistoryTab={false}
            showRequestInfoTab={false}
          />
        }
        leftColumn={
          <ReviewLeftPanel
            serviceRequestId={serviceRequestId}
            attachments={attachments}
            attachmentIndexOpen={attachmentIndexOpen}
            previousAttachmentsExpanded={previousAttachmentsExpanded}
            setPreviousAttachmentsExpanded={setPreviousAttachmentsExpanded}
            setUserClickInfoTracking={setUserClickInfoTracking}
            setSearchInfos={setSearchInfos}
            currentPage={currentPage}
            zoomLevel={zoomLevel}
            currentRotate={currentRotate}
            setCopiedAttachmentText={setCopiedAttachmentText}
            serviceRequest={serviceRequest}
            attachmentsLoading={attachmentListFetchLoading}
            onViewAttachment={onViewAttachment}
            attachmentsInfo={attachmentsInfo}
            setAttachmentsInfo={setAttachmentsInfo}
            serviceCases={serviceCases}
            trackingNumberSearch={trackingNumberSearch}
            tabOpen={leftColumnTab}
            setTabOpen={setLeftColumnTab}
            serviceRequestLoading={false}
            serviceRequestRefetch={serviceRequestRefetch}
            existingReviews={null}
            patient={patient}
            patientLoading={patientLoading}
            outreachOpportunities={null}
            otherServiceRequests={null}
            otherServiceRequestsLoading={false}
            {...claimHistoryProps}
            {...useOtherAuthorizationProps}
          />
        }
        rightColumn={
          serviceRequest &&
          authorization &&
          currentReview?.reviewType === "FaxAuditReview" && (
            <FaxAuditPageSidebar
              serviceRequest={serviceRequest}
              review={currentReview}
              serviceRequestLoading={serviceRequestLoading}
              serviceRequestRefetch={serviceRequestRefetch}
              authorization={authorization}
              refreshAuthorization={fetchAuthorization}
              patient={patient}
              patientLoading={patientLoading}
              userClickInfoTracking={userClickInfoTracking}
              setUserClickInfoTracking={setUserClickInfoTracking}
              searchInfos={searchInfos}
              setSearchInfos={setSearchInfos}
              copiedAttachmentText={copiedAttachmentText}
              pastedAttachmentTexts={pastedAttachmentTexts}
              setPastedAttachmentTexts={setPastedAttachmentTexts}
            />
          )
        }
      />
      <LoadingDialog
        open={serviceRequestLoading || authorizationFetchLoading || loadingExistingReviews || loadingCurrentReview}
        headingText={`Loading your fax audit`}
        subHeaderText={"This may take a few seconds"}
      />
      <InformativeModal
        open={openWarningModal}
        onClose={() => {
          setOpenWarningModal(false);
        }}
        headerText={"Review in progress"}
        additionalInfoText={`There is a review in progress for this SR started by ${
          currentReview?.createdByName || "unknown"
        }`}
        primaryButtonText={"Continue to review"}
        primaryButtonAction={() => {
          setOpenWarningModal(false);
        }}
      />
      <InformativeModal
        open={openAttachmentWarningModal}
        onClose={() => {
          setOpenAttachmentWarningModal(false);
        }}
        headerText={"New attachments uploaded"}
        additionalInfoText={`The submitter has uploaded ${newAttachmentsNumber} new attachments`}
        primaryButtonText={"View new attachments"}
        primaryButtonAction={() => {
          setLeftColumnTab("ATTACHMENTS");
          setOpenAttachmentWarningModal(false);
        }}
      />
    </>
  );
}
