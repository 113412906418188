import React from "react";
import { H6 } from "@coherehealth/design-system";
import AddAFile from "components/AddAttachments/AddAFile";
import AttachmentTableBody from "components/AddAttachments/AttachmentsTable/AttachmentTableBody";
import { makeStyles } from "@material-ui/core";
import {
  Attachment,
  AttachmentUpdatePayload,
  CreateCarePathJourneyAttachmentPathParams,
  CreateServiceRequestAttachmentPathParams,
  ServiceRequestResponse,
} from "@coherehealth/core-platform-api";
import { MutateMethod } from "restful-react";
import { UploadFile } from "components/AddAttachments/FileUploadItem";

interface Props {
  serviceRequest: ServiceRequestResponse;
  loadingSRAttachment: boolean;
  afterUpload?: (arg0: Attachment, totalFiles: number) => void;
  workflowId?: string;
  uploadFile: MutateMethod<
    Attachment,
    void,
    void,
    CreateCarePathJourneyAttachmentPathParams | CreateServiceRequestAttachmentPathParams
  >;
  files: UploadFile[];
  setFiles: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  onRemoveFile: ({ id }: { id: string }) => void;
  onUpdateFile: (file: UploadFile) => void;
  updateAttachmentGroup: (fileId: string, attachmentUpdatePayload: AttachmentUpdatePayload) => Promise<void>;
  updateLoading: boolean;
  onUpdateAttachments?: (arg0: Attachment[]) => void;
  deleteAttachment: MutateMethod<void | void[], string, void, any>;
  excludeHeader?: boolean;
  // This changes some styling to allow the component to line up with the MissingAttachmentOptions nudge card
  renderedFromMissingAttachmentOptions?: boolean;
}

export const UploadAttachmentOnNudge = ({
  serviceRequest,
  loadingSRAttachment,
  afterUpload,
  workflowId,
  uploadFile,
  files,
  setFiles,
  onRemoveFile,
  onUpdateFile,
  updateLoading,
  updateAttachmentGroup,
  onUpdateAttachments,
  deleteAttachment,
  excludeHeader,
  renderedFromMissingAttachmentOptions = false,
}: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.optionContainer}>
      {!excludeHeader && (
        <H6 className={classes.optionText}>
          If you haven't already, please upload documentation to support your request.
        </H6>
      )}
      <div
        style={
          renderedFromMissingAttachmentOptions
            ? files.length > 0
              ? { paddingLeft: "35px", marginBottom: "12px" }
              : { paddingLeft: "35px" }
            : {}
        }
      >
        <AddAFile
          serviceRequestIds={[serviceRequest.id]}
          uploading={loadingSRAttachment}
          afterUpload={afterUpload}
          isOnCarePathJourney={false}
          workflowId={workflowId}
          uploadFile={uploadFile}
          files={files}
          setFiles={setFiles}
        />
      </div>

      <AttachmentTableBody
        files={files}
        setFiles={setFiles}
        canUserEditViewable={false}
        serviceRequestIds={[serviceRequest.id]}
        onUpdateAttachments={onUpdateAttachments}
        noDivider={renderedFromMissingAttachmentOptions ? false : true}
        hideDownloadIcon={renderedFromMissingAttachmentOptions ? false : true}
        deleteAttachment={deleteAttachment}
        deleteLoading={false}
        onRemoveFile={onRemoveFile}
        onUpdateFile={onUpdateFile}
        updateAttachmentGroup={updateAttachmentGroup}
        updateLoading={updateLoading}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  optionContainer: {
    paddingTop: theme.spacing(2),
  },
  optionText: {
    paddingBottom: theme.spacing(2),
  },
}));
