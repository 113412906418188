import {
  AuthorizationResponse,
  ServiceRequestResponse,
  useGetAuthorizations,
  useGetServiceRequests,
} from "@coherehealth/core-platform-api";
import { useGetServiceRequestByIdWithFallback, useGetAuthorizationByIdWithFallback } from "@coherehealth/common";
import { useAuthorized } from "authorization";
import { useCallback, useEffect, useMemo, useState } from "react";

export default function useAuthorizationPatientSummary(patientId: string, reviewServiceRequestId?: string) {
  const isAuthViewOnlyUser = useAuthorized("AUTH_VIEW_ONLY");
  const [authorizations, setAuthorizations] = useState<AuthorizationResponse[]>([]);
  const [serviceRequests, setServiceRequests] = useState<ServiceRequestResponse[]>([]);
  const [isLoadMoreButtonEnabled, setIsLoadMoreButtonEnabled] = useState(false);
  const [authorizationsOffset, setAuthorizationsOffset] = useState<number>(0);
  const [serviceRequestsOffset, setServiceRequestsOffset] = useState<number>(0);
  const [isAtLastAuthorizationPage, setIsAtLastAuthorizationPage] = useState(false);
  const [hasLoadedAuthsOnce, setHasLoadedAuthsOnce] = useState(false);
  const maxCountPerPage = 10;

  const {
    data: authorizationsResponse,
    loading: authorizationsLoading,
    error: authorizationsError,
    refetch: authorizationsRefetch,
  } = useGetAuthorizations({
    queryParams: {
      view: "patientSummarySlim",
      patient: `eq:${patientId}`,
      max: maxCountPerPage,
      offset: authorizationsOffset,
      sort: "lastUpdated:desc",
    },
  });

  const authorizationsData: AuthorizationResponse[] | null = useMemo(() => {
    if (Array.isArray(authorizationsResponse)) {
      return authorizationsResponse;
    } else {
      return null;
    }
  }, [authorizationsResponse]);

  const {
    data: serviceRequestsData,
    loading: serviceRequestsLoading,
    error: serviceRequestsError,
    refetch: serviceRequestsRefetch,
  } = useGetServiceRequests({
    lazy: true,
    queryParams: {
      patient: `eq:${patientId}`,
      view: "patientSummarySlim",
      max: maxCountPerPage,
      offset: serviceRequestsOffset,
      includeOnlyEmptyAuth: true,
      sort: "lastUpdated:desc",
    },
  });

  const {
    data: selectedServiceRequestData,
    loading: selectedServiceRequestLoading,
    error: selectedServiceRequestError,
    refetch: selectedServiceRequestRefetch,
  } = useGetServiceRequestByIdWithFallback({
    lazy: true,
    id: reviewServiceRequestId || "",
    queryParams: {
      expandAuthorization: true,
    },
  });

  // Need to filter out the selected service request/auth since we also have it
  // returned from the authorization/service request API and do not want to duplicate
  // in the result set
  const filteredAuthorizations = useMemo(() => {
    return !!selectedServiceRequestData?.authorization?.id
      ? authorizations.filter((auth) => auth.id !== selectedServiceRequestData.authorization?.id)
      : authorizations;
  }, [authorizations, selectedServiceRequestData?.authorization?.id]);
  const filteredServiceRequests = useMemo(() => {
    return !!reviewServiceRequestId
      ? serviceRequests.filter((sr) => sr.id !== reviewServiceRequestId)
      : serviceRequests;
  }, [serviceRequests, reviewServiceRequestId]);

  const { refetch: serviceRequestRefetch } = useGetServiceRequestByIdWithFallback({ id: "", lazy: true });
  const { refetch: authRefetch } = useGetAuthorizationByIdWithFallback({ id: "", lazy: true });

  const isAtEndOfServiceRequestData = !!serviceRequestsData && serviceRequestsData.length < maxCountPerPage;
  const showLoadMoreButton = !isAtEndOfServiceRequestData && hasLoadedAuthsOnce;

  const fetchNewServiceRequests = useCallback(async () => {
    await serviceRequestsRefetch({
      queryParams: {
        patient: `eq:${patientId}`,
        view: "patientSummarySlim",
        max: maxCountPerPage,
        offset: serviceRequestsOffset,
        includeOnlyEmptyAuth: true,
      },
    });
    setServiceRequestsOffset((currentOffset) => currentOffset + maxCountPerPage);
  }, [patientId, serviceRequestsOffset, serviceRequestsRefetch]);

  const refreshAuthorization = useCallback(
    async (authorizationId: string) => {
      const shouldRefreshFocusedSr = selectedServiceRequestData?.authorization?.id === authorizationId;
      let authResponse;
      if (shouldRefreshFocusedSr) {
        const srResponse = await selectedServiceRequestRefetch({
          pathParams: { id: selectedServiceRequestData.id },
          queryParams: {
            expandAuthorization: true,
          },
        });
        authResponse = srResponse?.authorization;
      }

      const authIndex = authorizations.findIndex((auth) => auth.id === authorizationId);
      if (authIndex >= 0) {
        if (!authResponse) {
          authResponse = await authRefetch({ pathParams: { id: authorizationId } });
        }

        let updatedAuthorizations;
        if (authResponse) {
          updatedAuthorizations = [
            ...authorizations.slice(0, authIndex),
            authResponse,
            ...authorizations.slice(authIndex + 1),
          ];
        } else {
          updatedAuthorizations = authorizations.filter((auth) => auth.id !== authorizationId);
        }
        setAuthorizations(updatedAuthorizations);
      }
    },
    [
      authRefetch,
      authorizations,
      selectedServiceRequestData?.authorization?.id,
      selectedServiceRequestData?.id,
      selectedServiceRequestRefetch,
    ]
  );

  const refreshServiceRequest = useCallback(
    async (serviceRequestId: string) => {
      const shouldRefreshFocusedSr = selectedServiceRequestData?.id === serviceRequestId;
      const srIndex = serviceRequests.findIndex((sr) => sr.id === serviceRequestId);
      let srResponse;
      if (shouldRefreshFocusedSr) {
        srResponse = await selectedServiceRequestRefetch({
          pathParams: { id: selectedServiceRequestData.id },
          queryParams: {
            expandAuthorization: true,
          },
        });
      }
      if (srIndex >= 0) {
        if (!srResponse) {
          srResponse = await serviceRequestRefetch({ pathParams: { id: serviceRequestId } });
        }

        let updatedServiceRequests;
        if (srResponse) {
          updatedServiceRequests = [
            ...serviceRequests.slice(0, srIndex),
            srResponse,
            ...serviceRequests.slice(srIndex + 1),
          ];
        } else {
          updatedServiceRequests = serviceRequests.filter((sr) => sr.id !== serviceRequestId);
        }
        setServiceRequests(updatedServiceRequests);
      }
    },
    [serviceRequests, selectedServiceRequestData?.id, selectedServiceRequestRefetch, serviceRequestRefetch]
  );

  useEffect(() => {
    if (authorizationsData && !authorizationsLoading) {
      setHasLoadedAuthsOnce(true);
      if (!isAuthViewOnlyUser) {
        setAuthorizations((previousAuths) => [...previousAuths, ...authorizationsData]);
        setIsLoadMoreButtonEnabled(true);
      } else {
        // Do we need this logic still?
        const authData = authorizationsData.filter((auths) =>
          auths.serviceRequestsOnAuth?.some((sr) => sr.authStatus !== "DRAFT")
        );
        setAuthorizations((previousAuths) => [...previousAuths, ...authData]);
        setIsLoadMoreButtonEnabled(true);
      }
      if (authorizationsData.length < maxCountPerPage) {
        setIsAtLastAuthorizationPage(true);
      }
    }
  }, [authorizationsData, authorizationsLoading, isAuthViewOnlyUser]);

  useEffect(() => {
    if (isAtLastAuthorizationPage && serviceRequestsOffset === 0) {
      fetchNewServiceRequests();
    }
  }, [fetchNewServiceRequests, isAtLastAuthorizationPage, serviceRequestsOffset]);

  useEffect(() => {
    if (serviceRequestsData && !serviceRequestsLoading) {
      setServiceRequests((prevServiceRequests) => [...prevServiceRequests, ...serviceRequestsData]);
      setIsLoadMoreButtonEnabled(!isAtEndOfServiceRequestData);
    }
  }, [serviceRequestsData, serviceRequestsLoading, isAtEndOfServiceRequestData]);

  useEffect(() => {
    if (reviewServiceRequestId && !serviceRequestsLoading && selectedServiceRequestData === null) {
      selectedServiceRequestRefetch();
    }
  }, [selectedServiceRequestRefetch, reviewServiceRequestId, serviceRequestsLoading, selectedServiceRequestData]);

  const loadMorePatientSummary = () => {
    setIsLoadMoreButtonEnabled(false);
    if (isAtLastAuthorizationPage) {
      fetchNewServiceRequests();
    } else {
      setAuthorizationsOffset((currentOffset) => currentOffset + maxCountPerPage);
    }
  };

  return {
    actions: {
      loadMorePatientSummary,
      authorizationsRefetch,
      serviceRequestsRefetch,
      setAuthorizations,
      setServiceRequests,
      refreshAuthorization,
      refreshServiceRequest,
    },
    data: {
      filteredAuthorizations,
      filteredServiceRequests,
      isLoadMoreButtonEnabled,
      authorizationsData,
      authorizationsLoading,
      authorizationsError,
      serviceRequestsData,
      serviceRequestsLoading,
      serviceRequestsError,
      isAtEndOfServiceRequestData,
      selectedServiceRequestData,
      selectedServiceRequestLoading,
      selectedServiceRequestError,
      showLoadMoreButton,
    },
  };
}
