import React from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, Theme } from "@material-ui/core/styles";
import { Body1, Card, colorsLight } from "@coherehealth/common";
import { TableCell as MUITableCell } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

interface Column<T> {
  name: string;
  width: string;
  value: (arg: T) => React.ReactNode;
}

interface IProps<T> {
  tableColumns: Array<Column<T>>;
  data: Array<T>;
  sortingColumnName?: string;
  sortingAscending?: boolean;
  onSortChange?: (columnName: string, sortAscending: boolean) => void;
  onRowClick?: (row: T, index: number) => void;
  onDelete?: (row: T, index: number) => void;
  omitHeader?: boolean;
  dataPublic?: boolean;
}

export default function CohereTable<T>({
  tableColumns,
  data,
  onRowClick,
  onDelete,
  omitHeader,
  dataPublic,
}: IProps<T>) {
  return (
    <Table>
      {!omitHeader && (
        <Header>
          {tableColumns.map((column, index) => (
            <TableCell header width={column.width} key={index}>
              <HeaderName data-public={dataPublic}>{column.name}</HeaderName>
            </TableCell>
          ))}
        </Header>
      )}
      {data.map((item, index) => (
        <TableCard
          withHoverStyles
          key={index}
          onClick={() => {
            if (onRowClick) {
              onRowClick(item, index);
            }
          }}
          clickable={!!onRowClick}
        >
          {tableColumns.map((column, index) => {
            const val = column.value(item);
            return (
              <TableCell disabled={!val} width={column.width} key={index} data-public={dataPublic}>
                {typeof val === "string" || !val ? <Body1>{val || "--"}</Body1> : val}
              </TableCell>
            );
          })}
          {!!onDelete && (
            <IconButton
              onClick={() => onDelete(item, index)}
              style={{ alignSelf: "flex-end" }}
              data-public={dataPublic}
            >
              <DeleteIcon data-public={dataPublic} />
            </IconButton>
          )}
        </TableCard>
      ))}
    </Table>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Table = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  overflowY: "hidden",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const HeaderName = styled(Body1)({
  color: colorsLight.font.light,
});

interface StyledTableCellProps {
  header?: boolean;
  disabled?: boolean;
  width: string;
  align?: string;
  justifyContent?: string;
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const TableCell = styled(({ header, disabled, width, align, justifyContent, ...other }) => (
  <MUITableCell component="div" {...other} />
))<Theme, StyledTableCellProps>(({ theme, header, disabled, width, justifyContent = "left" }) => ({
  width: width,
  display: "flex",
  alignItems: "center",
  justifyContent: justifyContent,
  overflow: "hidden",
  fontWeight: 500,
  borderBottom: "none",
  padding: theme.spacing(0, 2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const TableCard = styled(({ clickable, ...other }) => <Card {...other} />)<Theme, { clickable: boolean }>(
  ({ theme, clickable }) => ({
    display: "flex",
    padding: theme.spacing(3),
    marginBottom: 12,
    position: "relative",
    cursor: clickable ? "pointer" : "",
  })
);

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Header = styled(Card)(({ theme }) => ({
  textAlign: "left",
  padding: `0px ${theme.spacing(2)}px`,
  border: "none",
  boxShadow: "none",
  marginBottom: 18,
  marginTop: theme.spacing(4),
  background: "transparent",
  display: "flex",
  color: "textSecondary",
}));
