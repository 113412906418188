import React, { ComponentProps, FunctionComponent } from "react";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Grid, styled } from "@material-ui/core";
import MuiIconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiErrorIcon from "@material-ui/icons/Error";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { Body1, H4, SecondaryButton, TertiaryButton } from "@coherehealth/common";
import { ProcedureCode } from "@coherehealth/core-platform-api";

interface Props extends Pick<DialogProps, "open" | "onClose"> {
  procedureCodes: ProcedureCode[];
  setProcedureCodes: (pxCodes: ProcedureCode[]) => void;
  nonPalProcedureCodes: ProcedureCode[];
  setNonPalPDFOpen: (b: boolean) => void;
}

const RemoveNonPALModal: FunctionComponent<Props> = ({
  open,
  onClose = () => {},
  procedureCodes,
  setProcedureCodes,
  nonPalProcedureCodes,
  setNonPalPDFOpen,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { borderRadius: "10px" },
      }}
    >
      <DialogTitle>
        <IconButton
          onClick={(e) => {
            onClose(e, "backdropClick");
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4} alignItems="center">
          <Row>
            <WarningIcon />
          </Row>
          <Row>
            <H4>You've entered codes that don't require</H4>
            <H4>pre-authorization. Please remove to proceed.</H4>
          </Row>
          <Row>
            {nonPalProcedureCodes.map((pxCode) => (
              <Body1 key={pxCode.code}>
                {pxCode.code} {BULLET} {pxCode.description}
              </Body1>
            ))}
          </Row>
          <Row>
            <SecondaryErrorButton
              onClick={(e) => {
                onClose(e, "backdropClick");
                setProcedureCodes(
                  procedureCodes.filter((pc) => !nonPalProcedureCodes.some(({ code }) => code === pc.code))
                );
              }}
            >
              Remove codes now
            </SecondaryErrorButton>
          </Row>
          <Row>
            <TertiaryButton
              onClick={() => {
                setNonPalPDFOpen(true);
              }}
            >
              Download summary PDF for your records
            </TertiaryButton>
          </Row>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default RemoveNonPALModal;

const BULLET = "\u2022";

// eslint-disable-next-line cohere-react/no-mui-styled-import
const IconButton = styled(MuiIconButton)(({ theme }) => ({
  float: "right",
  right: theme.spacing(1),
  top: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  padding: 0,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: `${theme.spacing(3)}px ${theme.spacing(5)}px`,
  overflow: "visible",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const WarningIcon = styled(MuiErrorIcon)(({ theme }) => ({
  color: theme.palette.warning.dark,
  width: theme.spacing(7),
  height: theme.spacing(7),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SecondaryErrorButton = styled(SecondaryButton)(({ theme }) => ({
  borderColor: theme.palette.error.main,
  width: "80%",
  color: theme.palette.error.main,
  "&:hover,&:focus": {
    borderColor: theme.palette.error.main,
    boxShadow: `0 0 0 1px ${theme.palette.error.main}`,
  },
  "&:active": {
    borderColor: theme.palette.error.main,
    boxShadow: `0 0 0 1px ${theme.palette.error.main}`,
  },
}));

const Row = (props: ComponentProps<typeof Grid>) => <Grid style={{ textAlign: "center" }} item xs={12} {...props} />;
