import React from "react";
import Grid from "@material-ui/core/Grid";
import { Body1 } from "@coherehealth/common";
import { OutOfNetworkReview, OutOfNetworkReviewConfigurationResponse } from "@coherehealth/core-platform-api";
import { Label } from "components/ServiceRequest/ReadonlyDetail/FormElements";
import {
  ReviewOutcomes,
  getIsOutOfNetworkCaption,
  optionIdToConfigLabel,
  optionIdToConfigLabelReview,
} from "components/ClinicalReview/reviewUtils/utils";
import { makeStyles } from "@material-ui/core";
import { ReviewOutcomeOption } from "../util/QueueManagementReviewUtil";

interface OutOfNetworkReviewReadOnlyProps {
  outOfNetworkReview: OutOfNetworkReview;
  oonReviewConfig: OutOfNetworkReviewConfigurationResponse | undefined;
  allowedReviewOutcomes: ReviewOutcomeOption[];
}

export default function OutOfNetworkReviewReadOnly({
  outOfNetworkReview,
  oonReviewConfig,
  allowedReviewOutcomes,
}: OutOfNetworkReviewReadOnlyProps) {
  const classes = useStyles();

  const getOutcomeReasonLabel = () => {
    const optionsFromStatusConfig = allowedReviewOutcomes?.find(
      (option) => option.id === outOfNetworkReview.reviewOutcome
    )?.submenu;
    const labelFromStatusConfig = optionIdToConfigLabelReview(
      outOfNetworkReview.outcomeReason,
      optionsFromStatusConfig
    );
    const labelFromOONConfig = optionIdToConfigLabel(
      outOfNetworkReview.outcomeReason,
      oonReviewConfig?.exceptionReasonOptions
    );
    if (labelFromStatusConfig) {
      return labelFromStatusConfig;
    } else if (labelFromOONConfig) {
      return labelFromOONConfig;
    } else {
      return outOfNetworkReview.outcomeReason;
    }
  };
  return (
    <Grid data-testid="out-of-network-review-read-only-container" container spacing={2}>
      <Grid item xs={12}>
        <Label>Review outcome</Label>
        <Body1>
          {outOfNetworkReview.reviewStatus === "DISCARDED"
            ? "Discarded"
            : ReviewOutcomes[outOfNetworkReview.reviewOutcome || ""]}
        </Body1>
      </Grid>
      {outOfNetworkReview.outcomeReason && (
        <Grid item xs={12}>
          <Label>Outcome reasoning</Label>
          <Body1>{getOutcomeReasonLabel()}</Body1>
        </Grid>
      )}
      {outOfNetworkReview?.claimsPaymentNote && (
        <Grid item xs={12}>
          <Label>Claims payment instructions</Label>
          <Body1 component="div">{outOfNetworkReview?.claimsPaymentNote}</Body1>
        </Grid>
      )}
      {outOfNetworkReview?.networkStatusOverride && (
        <>
          {outOfNetworkReview?.networkStatusOverride?.isOutOfNetworkPerformingProvider !== undefined && (
            <Grid item xs={6} className={classes.statusOverrideTile}>
              <Label>Performing provider network status</Label>
              <Body1 component="div">
                {getIsOutOfNetworkCaption(outOfNetworkReview?.networkStatusOverride?.isOutOfNetworkPerformingProvider)}
              </Body1>
            </Grid>
          )}
          {outOfNetworkReview?.networkStatusOverride?.isOutOfNetworkFacility !== undefined && (
            <Grid item xs={6} className={classes.statusOverrideTile}>
              <Label>Facility network status</Label>
              <Body1 component="div">
                {getIsOutOfNetworkCaption(outOfNetworkReview?.networkStatusOverride?.isOutOfNetworkFacility)}
              </Body1>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  statusOverrideTile: {
    marginBottom: theme.spacing(2),
  },
}));
