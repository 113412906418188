import { Grid, makeStyles } from "@material-ui/core";
import { Chip, H6, withId } from "@coherehealth/common";
import { AuthorizationResponse, ClinicalService, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { procedureBucketDataModel } from "components/AuthBuilder/common";
import { useMemo } from "react";
import { DetailsField } from "util/referralRequest";
import { isTerminalStatus } from "util/serviceRequest";
import GeneralAuthProcedureCodeList from "./GeneralAuthProcedureCodeList";

interface ClinicalServicesPxProps {
  showServiceChangeBanner?: boolean;
  onReviewPage?: boolean;
  serviceRequest: ServiceRequestResponse;
  authorization: AuthorizationResponse | undefined;
}

const useStyles = makeStyles((theme) => ({
  pxCodeSection: {
    width: "100%",
  },
  clinicalServiceTitle: {
    padding: theme.spacing(2, 0, 0, 0),
    display: "flex",
    alignItems: "center",
  },
  updatedTitleBadge: {
    borderRadius: theme.spacing(0.5),
    fontSize: "13px",
    padding: theme.spacing(0.5, 1),
    paddingTop: "5px",
    marginLeft: theme.spacing(2),
  },
}));

export const ClinicalServicesWithProcedures = ({
  showServiceChangeBanner = false,
  onReviewPage = false,
  serviceRequest,
  authorization,
}: ClinicalServicesPxProps) => {
  const classes = useStyles();

  const clinicalServices =
    serviceRequest.requestType === "CONTINUATION" && authorization
      ? authorization.clinicalServices
      : serviceRequest.clinicalServices;

  const procedureCodes =
    !onReviewPage &&
    authorization &&
    authorization.semanticProcedureCodes &&
    authorization.semanticProcedureCodes.length > 0
      ? authorization.semanticProcedureCodes
      : serviceRequest.semanticProcedureCodes;

  // If all PXs have an originalGroupId, it's a better bet to use the originalGroupIds instead of just id
  // This is because if a clinical service was updated, the PXs and SR may reference different versions of the same clinical service
  const useOriginalVersionIds = procedureCodes?.every((px) => px.groupOriginalVersionId);
  let pxsWithOriginalGroupIdInsteadOfId = useOriginalVersionIds
    ? procedureCodes?.map((px) => {
        let result = { ...px };
        if (result.groupOriginalVersionId) {
          result.groupId = result.groupOriginalVersionId;
        }
        return result;
      })
    : procedureCodes;

  const clinicalServiceWithPxs = useMemo(() => {
    return procedureBucketDataModel(pxsWithOriginalGroupIdInsteadOfId?.map(withId) || []);
  }, [pxsWithOriginalGroupIdInsteadOfId]);
  const clinicalServiceIds = Array.from(clinicalServiceWithPxs.keys());
  return (
    <>
      {clinicalServiceIds.sort().map((csId) => {
        const clinicalService = clinicalServices?.find((cs): cs is ClinicalService => {
          return cs?.id === csId || cs?.originalVersionId === csId;
        });
        const units =
          clinicalService && !clinicalService.isUnitsOnPx && clinicalServiceWithPxs
            ? clinicalServiceWithPxs
                ?.get(csId)
                ?.reduce((a, b) => (a > (b.units ? b.units : 0) ? a : b?.units || 0), 0)
                .toString()
            : "";
        const approvedUnits =
          clinicalService && !clinicalService.isUnitsOnPx && clinicalServiceWithPxs
            ? clinicalServiceWithPxs
                ?.get(csId)
                ?.reduce((a, b) => (a > (b.approvedUnits ? b.approvedUnits : 0) ? a : b?.approvedUnits || 0), 0)
                .toString()
            : "";
        const showNumberOfVisits = clinicalService && !clinicalService.isUnitsOnPx;
        return (
          <Grid container spacing={2} key={csId}>
            <Grid item xs={12}>
              <H6 className={classes.clinicalServiceTitle} data-public>
                {clinicalService?.name || "Uncategorized Service"}
                {showServiceChangeBanner && (
                  <Chip className={classes.updatedTitleBadge} type="success" label="Updated" />
                )}
              </H6>
            </Grid>
            {showNumberOfVisits && (
              <Grid item xs={12} style={{ paddingBottom: 0 }}>
                <DetailsField
                  label="Number of visits"
                  value={isTerminalStatus(serviceRequest) ? approvedUnits : units}
                />
              </Grid>
            )}
            <GeneralAuthProcedureCodeList
              authorization={authorization}
              serviceRequest={serviceRequest}
              procedureCodes={clinicalServiceWithPxs.get(csId) || []}
              clinicalService={clinicalService}
            />
          </Grid>
        );
      })}
    </>
  );
};
