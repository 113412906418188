import { Model, Factory, Server, belongsTo } from "miragejs";
import { ApplicationSerializer } from "../server";

const models = {
  carePathJourney: Model.extend({
    patient: belongsTo("patient"),
    carePath: belongsTo("carePath"),
    primaryDiagnosis: belongsTo("diagnosisCode"),
  }),
};

const factories = {
  carePathJourney: Factory.extend({
    dateCreated(i: number) {
      return "2020-02-20T14:30Z";
    },
    lastUpdated(i: number) {
      return "2020-02-20T14:30Z";
    },
  }),
};

const initialCarePathJourney = {
  patient: null,
  carePath: null,
  primaryDiagnosis: null,
};

const completeCarePathJourney = {
  id: "CAREPATH_JOURNEY_COMPLETE",
  patientId: "1",
  carePathId: "CAREPATH_COMPLETE",
  primaryDiagnosisId: "1",
};

const scenarios = {
  standard(server: Server) {
    server.createList("carePathJourney", 15);
    server.create("carePathJourney", initialCarePathJourney as any);
    server.create("carePathJourney", {
      ...completeCarePathJourney,
    });
  },
  empty(server: Server) {},
};

const activeScenario = "standard";
// const activeScenario = "empty";

const serializers = {
  carePathJourney: ApplicationSerializer.extend({
    include: ["patient", "carePath", "primaryDiagnosis"],
  }),
};

export { models, factories, scenarios, activeScenario, serializers };
