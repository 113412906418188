import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MuiFormControl, { FormControlProps } from "@material-ui/core/FormControl";
import MuiFormControlLabel, { FormControlLabelProps } from "@material-ui/core/FormControlLabel";
import MuiFormHelperText from "@material-ui/core/FormHelperText";
import MuiSwitch from "@material-ui/core/Switch";
import Stack from "@mui/material/Stack";
import { colorsLight } from "../../themes/colors";

const useStylesSwitch = makeStyles((theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  return createStyles({
    root: {
      "& $checked + $track": {
        opacity: 1,
        backgroundColor: isDarkTheme ? "#56657F" : "#BBDEFB",
      },
      "& $disabled + $track": {
        opacity: 1,
        backgroundColor: isDarkTheme ? "#56657F" : "rgba(0, 0, 0, 0.12)",
      },
    },
    switchBase: {
      /* The font color is set on the base and inherits through to the thumb background */
      color: "#F1F1F1",
      "&$checked": {
        color: theme.palette.primary.main,
      },
      "&$disabled": {
        color: isDarkTheme ? colorsLight.font.secondary : "#BDBDBD",
      },
    },
    thumb: {
      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)",
    },
    track: {
      opacity: 1,
      background: isDarkTheme ? "#56657F" : "#C2C2C2",
    },
    disabled: {
      "& $thumb": {
        boxShadow: "none",
      },
    },
    checked: {},
  });
});

const useStylesHelper = makeStyles((theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  return createStyles({
    root: {
      "&$error": {
        color: isDarkTheme ? theme.palette.error.main : theme.palette.error.dark,
      },
    },
    error: {},
  });
});

export interface SwitchProps {
  /** Text to display with the switch. */
  label?: React.ReactNode;
  /** Helper text for hint or error messages. */
  helperText?: React.ReactNode;
  /** Switch state. */
  checked?: boolean;
  /** Function: the 'checked' state is provided as the first argument. */
  onChange: (arg0: boolean) => any;
  /** Props passed to the FormControlLabel */
  FormControlLabelProps?: Omit<FormControlLabelProps, "control" | "label" | "onChange" | "checked">;
  /** Props to display double-sided switch */
  doubleSidedSwitch?: boolean;
  /** Text to display with the switch */
  label2?: React.ReactNode;
}

// Temporary export for storybook documentation
export const SwitchForProps = (props: SwitchProps) => {};

// omit 'css' prop to avoid Typescript error making it required
const Switch = (props: SwitchProps & Omit<FormControlProps, "onChange" | "css">) => {
  const {
    checked,
    onChange,
    label,
    helperText,
    FormControlLabelProps,
    doubleSidedSwitch,
    label2,
    ...formControlProps
  } = props;
  const handleChange = (event: any, newChecked: boolean) => {
    onChange(newChecked);
  };
  const switchClasses = useStylesSwitch();
  const helperClasses = useStylesHelper();
  if (doubleSidedSwitch) {
    return (
      <Stack direction="row" alignItems="center">
        {label2}
        <MuiSwitch checked={checked} onChange={handleChange} color="primary" classes={switchClasses} defaultChecked />
        {label}
      </Stack>
    );
  }
  return (
    <MuiFormControl {...formControlProps}>
      <MuiFormControlLabel
        checked={checked}
        control={<MuiSwitch color="primary" classes={switchClasses} />}
        onChange={handleChange}
        label={label}
        {...FormControlLabelProps}
      />
      {helperText && <MuiFormHelperText classes={helperClasses}>{helperText}</MuiFormHelperText>}
    </MuiFormControl>
  );
};

export default Switch;
