import { useGetServiceRequestAutoApproval } from "@coherehealth/core-platform-api";
import { makeStyles } from "@material-ui/core/styles";
import { Card, H1, H5 } from "@coherehealth/common";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  circularProgress: {
    margin: "auto",
  },
  card: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

export default function MobileDashboardPage() {
  const classes = useStyles();
  const { data: serviceRequestAutoApprovalCounts, loading } = useGetServiceRequestAutoApproval({});

  const autoApprovalRateSinceLaunch = (
    ((serviceRequestAutoApprovalCounts?.autoApprovalCount || 0) / (serviceRequestAutoApprovalCounts?.totalCount || 1)) *
    100
  ).toFixed(2);

  const autoApprovalRateToday = (
    ((serviceRequestAutoApprovalCounts?.todayAutoApprovalCount || 0) /
      (serviceRequestAutoApprovalCounts?.todayTotalCount || 1)) *
    100
  ).toFixed(2);

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress className={classes.circularProgress} />;
      </div>
    );
  }

  return (
    <>
      <Card className={classes.card}>
        <H5>Auto Approval % Today</H5>
        <H1>{autoApprovalRateToday}%</H1>
      </Card>
      <Card className={classes.card}>
        <H5>Auto Approval % Since Launch</H5>
        <H1>{autoApprovalRateSinceLaunch}%</H1>
      </Card>
    </>
  );
}
