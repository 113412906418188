import React, { useContext, useEffect, useState } from "react";
import {
  ClinicalAssessmentContext,
  ClinicalAssessmentContextProvider,
  EditWithAssessmentContext,
} from "components/ClinicalAssessment";
import { usePostClinicalAssessment } from "@coherehealth/core-platform-api";
import { useMatch } from "react-router-dom";
import routes from "routes";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled, useTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import { useAuthorized } from "../../authorization";
import { Alert, H2, PrimaryButton, NotFoundPage, useMuiContainerStyles } from "@coherehealth/common";
import Box from "@material-ui/core/Box";
import usePollForClinicalAssessment from "hooks/usePollForClinicalAssessment";
import LoadingAssessmentModal from "components/AuthBuilder/ClinicalAssessment/LoadingAssessmentModal";
import { clearNotShownClinicalQuestions } from "util/clinicalAssessment";

const useStyles = makeStyles({
  errorButton: { backgroundColor: "red" },
});

function AssessmentTestPage() {
  const match = useMatch(routes.CLINICAL_ASSESSMENT_TEST);
  const serviceRequestId = match?.params.serviceRequestId || "";

  const containerClasses = useMuiContainerStyles();
  const classes = useStyles();
  const { spacing } = useTheme();

  const {
    data: singleServiceClinicalAssessmentData,
    loading: loadingSingleServiceClinicalAssessment,
    error: singleServiceClinicalAssessmentError,
    refetch: getSingleServiceClinicalAssessment,
    hasTimedOut,
    startPollForClinicalAssessment,
  } = usePollForClinicalAssessment({
    serviceRequestId,
  });

  const {
    loading: clinicalAssessmentSubmitLoading,
    error: clinicalAssessmentSubmitError,
    mutate: clinicalAssessmentSubmit,
  } = usePostClinicalAssessment({});

  const { updateClinicalAssessment, clinicalAssessment } = useContext(ClinicalAssessmentContext);

  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  useEffect(() => {
    /* TODO (minor) incremental update of assessment. This overwrites the whole thing and will blow away previous
     *   answers if a SR is added/removed. Note: This issue has been around since 8/13/20 and I haven't heard major
     *   complaints, so fixing is not top priority. */
    // Populate context with data from ClinicalAssessment fetch
    if (singleServiceClinicalAssessmentData && !loadingSingleServiceClinicalAssessment) {
      updateClinicalAssessment({ type: "populate", clinicalAssessment: singleServiceClinicalAssessmentData });
    }
  }, [loadingSingleServiceClinicalAssessment, singleServiceClinicalAssessmentData, updateClinicalAssessment]);

  return (
    <>
      <Wrapper classes={containerClasses} maxWidth="lg">
        <LoadingAssessmentModal open={loadingSingleServiceClinicalAssessment} />
        {clinicalAssessmentSubmitLoading ? (
          <CircularProgressContainer>
            {loadingSingleServiceClinicalAssessment && (
              <H2 style={{ marginBottom: spacing(4) }}>Loading Clinical Assessment Data</H2>
            )}
            {clinicalAssessmentSubmitLoading && (
              <H2 style={{ marginBottom: spacing(4) }}>Submitting Clinical Assessment </H2>
            )}
            <CircularProgress size={400} />
          </CircularProgressContainer>
        ) : (
          <>
            <Alert
              style={{ marginBottom: spacing(3) }}
              severity={singleServiceClinicalAssessmentError ? "error" : hasTimedOut ? "warning" : "info"}
            >
              {singleServiceClinicalAssessmentError
                ? `Error fetching assessment from SR: ${serviceRequestId}`
                : hasTimedOut
                ? `Assessment fetch timed out for SR: ${serviceRequestId}`
                : `Successfully got assessment from SR: ${serviceRequestId}`}
            </Alert>

            {clinicalAssessmentSubmitError && <ErrorAlert />}

            {!singleServiceClinicalAssessmentError && (
              <>
                <EditWithAssessmentContext />
                {!clinicalAssessmentSubmitError && attemptedSubmit && (
                  <Alert severity={"success"} style={{ marginBottom: spacing(3) }}>
                    Submitted assessment successfully
                  </Alert>
                )}

                {clinicalAssessmentSubmitError && <ErrorAlert />}
                <Box p={2} pl={0} display="flex">
                  <PrimaryButton
                    onClick={async () => {
                      if (clinicalAssessment && clinicalAssessment.questions) {
                        const answeredClinicalAssessmentQuestions = clearNotShownClinicalQuestions(
                          clinicalAssessment.questions
                        );
                        await clinicalAssessmentSubmit({
                          ...clinicalAssessment,
                          questions: answeredClinicalAssessmentQuestions,
                        });
                        await getSingleServiceClinicalAssessment();
                      }
                      setAttemptedSubmit(true);
                    }}
                    className={clinicalAssessmentSubmitError ? classes.errorButton : undefined}
                  >
                    Submit Assessment
                  </PrimaryButton>
                  {hasTimedOut && (
                    <Box ml={2}>
                      <PrimaryButton onClick={() => startPollForClinicalAssessment()}>Reset Timeout</PrimaryButton>
                    </Box>
                  )}
                </Box>
              </>
            )}
          </>
        )}
      </Wrapper>
    </>
  );
}

export default function AssessmentTestPageWithAssessmentContext() {
  if (!useAuthorized("VIEW_CLINICAL_ASSESSMENT_TEST_PAGE")) {
    return <NotFoundPage />;
  }

  return (
    <ClinicalAssessmentContextProvider>
      <AssessmentTestPage />
    </ClinicalAssessmentContextProvider>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CircularProgressContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: theme.spacing(5),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Wrapper = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(5),
}));

const ErrorAlert = () => {
  const { spacing } = useTheme();

  return (
    <Alert style={{ marginBottom: spacing(3) }} severity={"error"}>
      {"Error submitting assessment"}
    </Alert>
  );
};
