import React, { Dispatch } from "react";

import { Patient, ReferralRequestResponse, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { makeStyles } from "@material-ui/core/styles";

import { CommonFaxSidebarViewProps } from "./common";
import FaxMetaDataDisplay from "./FaxMetaDataDisplay";
import AttachedServiceRequests from "./AttachToServiceRequest/AttachedServiceRequests";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "inherit",
  },
  header: {
    padding: theme.spacing(2, 0, 1),
  },
  searchHeader: {
    marginBottom: theme.spacing(3),
  },
  cancelButton: {
    margin: theme.spacing(3, 0),
  },
}));

interface Props extends CommonFaxSidebarViewProps {
  selectedPatient?: Patient;
  setSelectedPatient: Dispatch<Patient | undefined>;
  url?: string;
  attachedToServiceRequests: ServiceRequestResponse[];
  attachedToReferralRequest: ReferralRequestResponse;
}

export default function CurrentlyAttachedServiceRequestsSidebar({ fileData, url, serviceCase, ...passthrough }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <FaxMetaDataDisplay fileData={fileData} url={url} serviceCase={serviceCase} />
      <AttachedServiceRequests fileData={fileData} {...passthrough} />
    </div>
  );
}
