import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";

export interface HealthPlanDisplayNameContextInterface {
  healthPlanDisplayName: string;
  setHealthPlanDisplayNameContext: (healthPlanDisplayName: string) => void;
}

const healthPlanDisplayNameDefaultState = {
  healthPlanDisplayName: "",
  setHealthPlanDisplayNameContext: (healthPlanDisplayName: string) => {},
} as HealthPlanDisplayNameContextInterface;

export const HealthPlanDisplayNameContext = createContext(healthPlanDisplayNameDefaultState);

type HealthPlanDisplayNameContextProps = {
  children: ReactNode;
};

const HealthPlanDisplayNameContextProvider = ({ children }: HealthPlanDisplayNameContextProps) => {
  const [healthPlanDisplayName, setHealthPlanDisplayName] = useState("");

  const setHealthPlanDisplayNameContext = useCallback((healthPlanName: string) => {
    setHealthPlanDisplayName(healthPlanName);
  }, []);

  const value = useMemo(
    () => ({ healthPlanDisplayName, setHealthPlanDisplayNameContext }),
    [healthPlanDisplayName, setHealthPlanDisplayNameContext]
  );

  return <HealthPlanDisplayNameContext.Provider value={value}>{children}</HealthPlanDisplayNameContext.Provider>;
};

export default HealthPlanDisplayNameContextProvider;

export const useHealthPlanDisplayNameContext = () => {
  const context = useContext(HealthPlanDisplayNameContext);
  if (!context) {
    throw new Error("useHealthPlanDisplayNameContext must be used within a HealthPlanDisplayNameContextProvider");
  }
  return context;
};
