import {
  useUpdateServiceRequest,
  ServiceRequestResponse,
  AuthBuilderWorkflowStep,
} from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import React, { Dispatch, SetStateAction, useContext, useEffect } from "react";
import Footer from "../Footer";
import { useNavigate } from "react-router";
import { navigateToPS, navigateToSRSummary } from "../common";
import { SmartOnFhirContext } from "components/SmartOnFhir/SmartOnFhirSecurityProvider";

interface Props {
  vendorAssessmentCompleted: boolean;
  setWorkflowStep: (step: AuthBuilderWorkflowStep) => void;
  serviceRequestId?: string;
  workflowStep: AuthBuilderWorkflowStep;
  patientId: string;
  serviceRequests: ServiceRequestResponse[];
  displayClearerDraftComponents: boolean;
  setFooterHeight: Dispatch<SetStateAction<number>>;
}

export default function VendorClinicalAssessmentFooter({
  vendorAssessmentCompleted,
  setWorkflowStep,
  serviceRequestId,
  workflowStep,
  patientId,
  serviceRequests,
  displayClearerDraftComponents,
  setFooterHeight,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: updateServiceRequest,
    loading: updateServiceRequestLoading,
    error: updateServiceRequestError,
  } = useUpdateServiceRequest({ id: "" });

  const navigate = useNavigate();

  useEffect(() => {
    if (updateServiceRequestError) {
      enqueueSnackbar("Failed to update service request, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [updateServiceRequestError, enqueueSnackbar]);

  const onTryVendorAssessmentSubmit = async () => {
    await updateServiceRequest({ workflowStep: workflowStep }, { pathParams: { id: serviceRequestId || "" } });
    setWorkflowStep("REVIEW");
  };

  const smartClient = useContext(SmartOnFhirContext);
  const inSmartOnFhirWorkflow = Boolean(smartClient);

  const onSaveAndExitClickButton = async () => {
    await updateServiceRequest({ workflowStep: workflowStep }, { pathParams: { id: serviceRequestId || "" } });
    if (inSmartOnFhirWorkflow) {
      navigateToSRSummary(serviceRequestId || "", navigate, true);
    } else {
      navigateToPS(patientId, navigate, serviceRequestId);
    }
  };

  return (
    <Footer
      onPrimaryButtonClick={onTryVendorAssessmentSubmit}
      primaryButtonDisabled={!vendorAssessmentCompleted}
      primaryButtonText={"Save and Continue"}
      showSaveAndExitButton={displayClearerDraftComponents}
      onSaveAndExitClick={onSaveAndExitClickButton}
      saveAndExitButtonDisabled={updateServiceRequestLoading}
      setFooterHeight={setFooterHeight}
    />
  );
}
