import { DynamicIndication } from "./IndicationOperators";

export interface DynamicElemBlock {
  htmlString: string;
  indication?: DynamicIndication;
  blocks?: DynamicElemBlock[];
  depth?: number;
  indicationIndex?: number;
}

/**
 *
 * @param guidelineElemRefs guideline html elements in depth first order.
 * @param refBlockIndices flatten list of both static and indication indices relative to the current container.
 * @param indicationsAtCurrentLevel
 * @returns
 */
export const generateDynamicElementBlocks = (
  guidelineElemRefs: Element[],
  refBlockIndices: number[],
  indicationsAtCurrentLevel: DynamicIndication[]
): DynamicElemBlock[] => {
  /**
   * walkthrough of the code:
   * refBlockIndices: [0, 1, 2, 3, 4, 5, 6, 7, 8]
   * staticTextIndices: [[0,1], [4,5], [8]]
   * indicationIndices: [[2, 3], [6], [7]]
   * allElemsIndices: [[0,1], [2, 3], [4,5], [6], [7], [8]]
   * combinedBlockElems: [
   *    {
   *        htmlString: "html string determined from [0, 1] indices"
   *    },
   *    {
   *        htmlString: "html string determined from [2, 3] indices",
   *        indication: Indication.findByGuidelineHtmlIndices([2, 3])
   *    },
   *    {
   *        htmlString: "html string determined from [4, 5] indices",
   *    },
   *    {
   *        htmlString: "html string determined from [6] indices",
   *        indication: Indication.findByGuidelineHtmlIndices([6])
   *    },
   *    {
   *        htmlString: "html string determined from [7] indices",
   *        indication: Indication.findByGuidelineHtmlIndices([7])
   *    },
   *    {
   *        htmlString: "html string determined from [8] indices"
   *    },
   * ]
   */
  const indicationIndices: number[][] = indicationsAtCurrentLevel
    .map((ind: DynamicIndication) => [...(ind.guidelineHtmlIndices ?? [])].sort((val1, val2) => val1 - val2))
    .filter((val: number[]) => val.length);

  const flattenIndicationIndices: number[] = Array.prototype.concat(...indicationIndices);

  const staticTextIndices: number[][] = groupIndices(
    refBlockIndices.filter((index) => !flattenIndicationIndices.includes(index)).sort((val1, val2) => val1 - val2)
  );

  // combined list of static text indices and indications, sorted wrt the first element.
  const allElemIndices = staticTextIndices.concat(indicationIndices).sort((val1, val2) => val1[0] - val2[0]);

  // loop through the combined list of indices and build the static and indication ElemBlock list.
  const combinedBlockElems: DynamicElemBlock[] = [];
  let indicationIndex = 0;
  allElemIndices.forEach((block: number[]) => {
    // if the block is not an indication, then detemine the static text from guidelineElemRefs.
    if (staticTextIndices.includes(block)) {
      const staticBlocks: Element[] = [];
      guidelineElemRefs.forEach((element, index) => {
        if (element && block.includes(index)) {
          staticBlocks.push(element);
        }
      });
      combinedBlockElems.push({
        htmlString: buildAggregatedHtml(staticBlocks),
      });
    } else {
      // if the block is part of an indication, use the indication html.
      const indication: DynamicIndication | undefined = indicationsAtCurrentLevel.find((ind: DynamicIndication) =>
        hasSameItems(ind.guidelineHtmlIndices ?? [], block)
      );
      combinedBlockElems.push({
        htmlString: indication?.indicationHtml ?? "",
        indication: indication,
        indicationIndex: indicationIndex,
      });
      indicationIndex++;
    }
  });
  return combinedBlockElems;
};

/**
 * @param sortedList [1, 2, 5, 6, 9]
 * @returns [[1, 2], [5, 6], [9]]
 */
export const groupIndices = (sortedList: number[]): number[][] => {
  return sortedList.reduce((accumulator: number[][], currentValue: number) => {
    const lastSubArray = accumulator[accumulator.length - 1];
    if (!lastSubArray || lastSubArray[lastSubArray.length - 1] !== currentValue - 1) {
      accumulator.push([]);
    }
    accumulator[accumulator.length - 1].push(currentValue);
    return accumulator;
  }, []);
};

/**
 * taken from @admin code from packages/admin/src/components/Policies/Policy/PolicyGuideline/richTextUtil.ts, and made modifications to suit the use case.
 */
export const populateDepthFirstNodeList = (htmlString: string): Element[] => {
  const root = new DOMParser().parseFromString(htmlString, "text/html");
  const nodeList: Element[] = [];
  if (root && root.body?.children?.[0]?.children) {
    const topLevelNodes = Array.from(root.body.children[0].children);
    let index = 0;
    for (const node of topLevelNodes) {
      if (node.tagName === "UL" || node.tagName === "OL") {
        for (const subNode of Array.from(node.children)) {
          if (subNode.tagName === "LI") {
            nodeList[index++] = subNode;
          }
        }
      } else {
        nodeList[index++] = node;
      }
    }
  }
  return nodeList;
};

/**
 * taken from @admin code from packages/admin/src/components/Policies/Policy/PolicyGuideline/richTextUtil.ts.
 */
export const buildAggregatedHtml = (elements: Element[]) => {
  const wrapper = document.createElement("div");

  // keeps track of the <li> elements, also a <ul> wrapper.
  let currentListEl: Element | undefined;

  // combines all the <li> elements under a <ul> wrapper and appends that element to the parent div wrapper.
  // called when there is a transition from an <li> item to a <div> element.
  const commitList = () => {
    if (currentListEl !== undefined) {
      safelyAppend(wrapper, currentListEl);
      currentListEl = undefined;
    }
  };
  for (const element of elements) {
    if (element.tagName === "LI") {
      // check if there is an existing <ul> wrapper, if not create one, else append the <li> element to the <ul> wrapper ie the currentListEl.
      if (currentListEl === undefined) {
        currentListEl = document.createElement("ul");
      }
      safelyAppend(currentListEl, element);
    } else {
      // if the element is not a list item, make sure to append the <ul> wrapper, if any, to the parent before appending the current element to the parent div wrapper.
      commitList();
      safelyAppend(wrapper, element);
    }
  }
  commitList();
  return wrapper.outerHTML;
};

/**
 * taken from @admin code from packages/admin/src/components/Policies/Policy/PolicyGuideline/richTextUtil.ts.
 */
const safelyAppend = (toEl: Element, fromEl: Element) => {
  toEl.appendChild(document.body.contains(fromEl) ? fromEl.cloneNode(true) : fromEl);
};

export function hasSameItems<T>(a: T[], b: T[]) {
  return JSON.stringify(a.sort()) === JSON.stringify(b.sort());
}
