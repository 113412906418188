import React, { useCallback, useEffect, useState } from "react";

import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Dialog from "@material-ui/core/Dialog";
import DocumentViewer from "../DocumentViewer";
import { UploadFile } from "./FileUploadItem";
import useOcrEnhancedPath from "./useOcrEnhancedPath";
import { useSnackbar } from "notistack";
import { useBearerToken } from "hooks/useBearerToken";
import useSearchAndLocation from "hooks/useSearchAndLocation";

interface FileViewProps {
  file: UploadFile;
  disabled?: boolean;
  viewAttachmentPath: string;
  canDelete: boolean;
  onDelete?: () => void;
}

export default function FileView({
  file,
  disabled = false,
  viewAttachmentPath: originalViewAttachmentPath,
  onDelete,
  canDelete,
}: FileViewProps) {
  const { location, search } = useSearchAndLocation();

  const [fileViewOpen, setFileViewOpen] = React.useState(false);
  const [fileUrl, setFileUrl] = React.useState<string>();
  const [convertedToPDF, setConvertedToPDF] = useState<boolean>(false);
  const [loadError, setLoadError] = useState<boolean>(false);
  const viewAttachmentPath = useOcrEnhancedPath(originalViewAttachmentPath);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (loadError) {
      enqueueSnackbar("Error downloading attachment", {
        variant: "error",
      });
    }
  }, [loadError, enqueueSnackbar]);

  const bearerToken = useBearerToken();
  const handleClose = useCallback(() => {
    setFileViewOpen(false);
  }, [setFileViewOpen]);

  const onViewAttachment = useCallback(async () => {
    setFileViewOpen(true);

    const response = await fetch(viewAttachmentPath, {
      headers: {
        Authorization: bearerToken,
      },
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      if (blob.type === "application/pdf" && file.contentType !== "application/pdf") {
        setConvertedToPDF(true);
      }
      setFileUrl(url);
    } else {
      setLoadError(true);
    }
  }, [viewAttachmentPath, file.contentType, bearerToken]);

  const deleteAndClose = useCallback(async () => {
    if (canDelete && onDelete) {
      await onDelete();
      setFileViewOpen(false);
    }
  }, [onDelete, canDelete, setFileViewOpen]);

  return (
    <>
      <IconButton aria-label="View" onClick={onViewAttachment} disabled={disabled}>
        <VisibilityIcon />
      </IconButton>
      <Dialog fullScreen open={fileViewOpen} onClose={handleClose}>
        <DocumentViewer
          documentInfo={file}
          url={fileUrl}
          handleClose={handleClose}
          canDelete={canDelete}
          onDelete={deleteAndClose}
          convertedToPDF={convertedToPDF}
          loadError={loadError}
          location={location}
          search={search}
        />
      </Dialog>
    </>
  );
}
