import React, { useState } from "react";
import { Card } from "../Card";
import { Checkbox } from "../Checkbox";
import { Body1, Caption } from "../Typography";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, Theme, useTheme } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "./TableCell";
import { Delete } from "@material-ui/icons";
import { colorsDark, colorsLight } from "../../themes";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { CheckboxProps as MuiCheckboxProps } from "@material-ui/core/Checkbox";

interface Column<T> {
  name: string;
  label?: string;
  width: string;
  themedPaddingRight: number;
  value: (arg: T) => React.ReactNode;
  unSortable?: boolean;
}

interface HeaderProps<T> {
  allChecked: boolean;
  onCheckAll: (checked: boolean) => void;
  onDeleteAll: () => void;
  tableColumns: Array<Column<T>>;
  deleteIconColor?: string;
  deleteOptionTextColor?: string;
  deleteOptionText?: string;
  numberOfItemsToBeDeleted?: number;
  sortingColumnName?: string;
  readOnly?: boolean;
  sortingAscending?: boolean;
  onSortChange?: (columnName: string, sortAscending: boolean) => void;
  width?: string[];
  muiCheckboxProps?: MuiCheckboxProps;
  customHeaderTextColor?: object;
  customHeaderSpacing?: object;
}

export default function TertiaryHeader<T>({
  allChecked,
  onCheckAll,
  onDeleteAll,
  tableColumns,
  deleteIconColor,
  deleteOptionTextColor,
  deleteOptionText,
  numberOfItemsToBeDeleted,
  readOnly,
  sortingColumnName,
  sortingAscending,
  onSortChange,
  width,
  muiCheckboxProps,
  customHeaderTextColor,
  customHeaderSpacing,
}: HeaderProps<T>) {
  const [anchorKebab, setAnchorKebab] = useState<HTMLElement | null>();
  const theme = useTheme();
  return (
    <Header readonly={readOnly} style={customHeaderSpacing}>
      {tableColumns.map((column, index) => (
        <TableCell
          width={width && width[index] ? width[index] : column.width}
          themedPaddingRight={column.themedPaddingRight}
          key={index}
        >
          {column.name === "Selected" ? (
            <Checkbox
              checked={allChecked}
              onChange={() => onCheckAll(!allChecked)}
              muiCheckboxProps={muiCheckboxProps}
            />
          ) : column.name === "Kebab" ? (
            numberOfItemsToBeDeleted && numberOfItemsToBeDeleted > 0 ? (
              <IconButtonTableCell
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorKebab(event.currentTarget)}
              >
                <MoreVertIcon />
              </IconButtonTableCell>
            ) : null
          ) : !column.unSortable && !!onSortChange ? (
            <StyledTableSortLabel
              active={column.name === sortingColumnName}
              direction={sortingAscending ? "asc" : "desc"}
              onClick={() => {
                if (onSortChange) {
                  if (column.name === sortingColumnName) {
                    onSortChange(column.name, !sortingAscending);
                  } else {
                    onSortChange(column.name, !!sortingAscending);
                  }
                }
              }}
            >
              {readOnly ? (
                <Caption>{column.label || column.name}</Caption>
              ) : (
                <Body1>{column.label || column.name}</Body1>
              )}
            </StyledTableSortLabel>
          ) : readOnly ? (
            <Caption style={customHeaderTextColor}>{column.label || column.name}</Caption>
          ) : (
            <Body1 style={customHeaderTextColor}>{column.label || column.name}</Body1>
          )}
        </TableCell>
      ))}
      <Menu
        id="tertiary-simple-menu"
        anchorEl={anchorKebab}
        keepMounted
        open={Boolean(anchorKebab)}
        onClose={() => setAnchorKebab(null)}
      >
        <MenuItem
          onClick={() => {
            onDeleteAll();
            setAnchorKebab(null);
          }}
        >
          <Delete style={deleteIconColor ? { color: deleteIconColor, marginRight: theme.spacing(1) } : {}} />
          <Body1 style={deleteOptionTextColor ? { color: deleteOptionTextColor } : {}}>
            {`${deleteOptionText ? deleteOptionText : "Delete"} (${numberOfItemsToBeDeleted})`}
          </Body1>
        </MenuItem>
      </Menu>
    </Header>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Header = styled(({ readOnly, ...other }) => <Card {...other} />)<Theme, { readOnly?: boolean }>(
  ({ theme, readOnly }) => ({
    color: readOnly ? colorsDark.font.secondary : colorsDark.primary.main,
    padding: readOnly ? 0 : theme.spacing(0, 3),
    paddingBottom: readOnly ? 0 : theme.spacing(0.75),
    textAlign: "left",
    border: "none",
    boxShadow: "none",
    background: "transparent",
    display: "flex",
  })
);

// eslint-disable-next-line cohere-react/no-mui-styled-import
const IconButtonTableCell = styled(IconButton)(({ theme }) => ({
  alignItems: "center",
  position: "absolute",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  "&:hover": {
    color: colorsLight.primary.light,
  },
  "&.MuiTableSortLabel-active": {
    "&:hover": {
      color: theme.palette.type === "dark" ? theme.palette.common.white : "inherit",
    },
  },
}));
