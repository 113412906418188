import { Model, Factory, Server, hasMany, belongsTo } from "miragejs";
import { ApplicationSerializer } from "../server";

const models = {
  carePath: Model.extend({
    diseaseArea: belongsTo("diagnosisCode"),
    diagnosisCodes: hasMany("diagnosisCode"),
    serviceCategories: hasMany("serviceCategory"),
  }),
  serviceCategory: Model.extend({
    carePath: belongsTo("carePath"),
    clinicalServices: hasMany("clinicalService"),
  }),
  clinicalService: Model.extend({
    serviceCategory: belongsTo("serviceCategory"),
    serviceType: belongsTo("serviceType"),
    procedureCodes: hasMany("procedureCode"),
    defaultPlaceOfService: belongsTo("placeOfService"),
    allowedPlaceOfServices: hasMany("placeOfService"),
    clinicalQuestions: hasMany("clinicalQuestion"),
  }),
  serviceType: Model,
  clinicalQuestion: Model.extend({
    clinicalService: belongsTo("clinicalService"),
    responseOptions: hasMany("responseOption"),
  }),
  responseOption: Model.extend({
    clinicalQuestion: belongsTo("clinicalQuestion"),
    observationCodes: hasMany("observationCode"),
  }),
};
const factories = {
  carePath: Factory.extend({
    name(i: number) {
      return `CarePath ${i + 1}`;
    },
    description(i: number) {
      return `Description of CarePath ${i + 1}`;
    },
    groupName(i: number) {
      return `Group Name ${i + 1}`;
    },
    isCurrentPublished() {
      return true;
    },
    isCurrentDraft() {
      return true;
    },
    abbreviation(i: number) {
      return `Abbrev${i + 1}`;
    },
    lastUpdated(i: number) {
      return "2020-02-20T14:30Z";
    },
    filters() {
      return {};
    },
  }),

  serviceCategory: Factory.extend({
    name(i: number) {
      return `Category ${i + 1}`;
    },
  }),

  clinicalService: Factory.extend({
    name(i: number) {
      return `ClinicalService ${i + 1}`;
    },
    description(i: number) {
      return `Description of clinicalService ${i + 1}`;
    },
    internalDescription(i: number) {
      return `Internal description of clinicalService ${i + 1}`;
    },
    defaultAuthType(i: number) {
      return undefined;
    },
    defaultUnits(i: number) {
      return undefined;
    },
    defaultDuration(i: number) {
      return undefined;
    },
    defaultUnitType(i: number) {
      return undefined;
    },
    requireAttachments(i: number) {
      return false;
    },
    typeOfAttachments(i: number) {
      return "";
    },
    allowAutoAuthorization(i: number) {
      return false;
    },
    isCurrentPublished() {
      return true;
    },
    isCurrentDraft() {
      return true;
    },
  }),

  serviceType: Factory.extend({
    code(i: number) {
      return `STYPECODE${i}`;
    },
    name(i: number) {
      return `Service Type ${i}`;
    },
    description(i: number) {
      return `Description ${i}`;
    },
  }),

  clinicalQuestion: Factory.extend({
    internalDescription(i: number) {
      return `Question Name ${i}`;
    },
    questionText(i: number) {
      return `Question Text ${i}`;
    },
    type(i: number) {
      return "TEXT";
    },
    required(i: number) {
      return false;
    },
    isCurrentPublished() {
      return true;
    },
    isCurrentDraft() {
      return true;
    },
  }),

  responseOption: Factory.extend({
    answerText(i: number) {
      return `Answer Text ${i}`;
    },
  }),

  observationCode: Factory.extend({
    code(i: number) {
      return `Observation Code ${i}`;
    },
    description(i: number) {
      return `Description ${i}`;
    },
  }),
};

const initialCarePath = {
  name: "",
  description: "",
  groupName: "",
  documentVersion: 1,
  abbreviation: "",
  isCurrentDraft: true,
  isCurrentPublished: false,
  diseaseArea: null,
  lastUpdated: new Date().toISOString(),
  diagnosisCodes: [],
  filters: { SUPPORTED_GENDERS: "BOTH" },
  serviceCategories: [],
};

const completeCarePath = {
  id: "CAREPATH_COMPLETE",
  name: "Complete CarePath",
  documentVersion: 1,
  description: "A few sentences to describe the care path here.",
  groupName: "Knee",
  abbreviation: "THA",
  isCurrentDraft: true,
  isCurrentPublished: true,
  diseaseAreaId: "1",
  lastUpdated: new Date().toISOString(),
  diagnosisCodeIds: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
  ],
  filters: {
    AGE_RANGE: { minAge: 15, maxAge: 120 },
    SUPPORTED_GENDERS: "BOTH",
  },
};

const completeCategory = {
  name: "Conservative Therapy",
};

const completeClinicalService1 = {
  id: "SUBCATEGORY_COMPLETE_DME",
  name: "DME",
  description: "Description of DME",
  internalDescription: "This is just for us",
  serviceTypeId: "1",
  procedureCodeIds: ["1", "2"],
  defaultPlaceOfServiceId: "2",
  documentVersion: 2,
  isCurrentDraft: true,
  isCurrentPublished: false,
  allowedPlacesOfServiceIds: ["1", "2"],
  defaultAuthType: "RECURRING",
  defaultUnitType: "DAY",
  defaultUnits: 2,
  defaultDuration: 6,
  requireAttachments: true,
  typeOfAttachments: "Text about the types of attachments here",
  allowAutoAuthorization: true,
};

const completeClinicalService2 = {
  name: "Initial ClinicalService",
  description: "",
  serviceType: null,
  procedureCodes: [],
  defaultPlaceOfService: null,
  allowedPlacesOfServices: [],
  defaultAuthType: "ONETIME",
  defaultUnitType: "DAY",
  documentVersion: 2,
  isCurrentDraft: true,
  isCurrentPublished: false,
  defaultUnits: 1,
  defaultDuration: 1,
  requireAttachments: false,
  typeOfAttachments: "",
  allowAutoAuthorization: false,
  clinicalQuestions: [],
};

const completeClinicalQuestion = {
  id: "QUESTION_COMPLETE_BMI",
  name: "BMI",
  description: "BMI of Patient",
  internalDescription: "Need this for patient info",
  questionText: "What is the patient's BMI?",
  type: "SINGLE_SELECT",
  required: true,
  documentVersion: 2,
  isCurrentDraft: true,
  isCurrentPublished: false,
  clinicalServiceId: "SUBCATEGORY_COMPLETE_DME",
};

const completeResponseOption1 = {
  id: "RESPONSE1_COMPLETE_BMI",
  responseOptionText: "Over 40",
};

const completeResponseOption2 = {
  id: "RESPONSE2_COMPLETE_BMI",
  responseOptionText: "Under 40",
};

const scenarios = {
  short(server: Server) {
    server.createList("carePath", 5);
  },
  standard(server: Server) {
    server.createList("carePath", 15);

    server.createList("serviceType", 10);

    // A CarePath with children
    const carePath = server.create("carePath", {
      ...completeCarePath,
      name: "Osteoarthritis of the Knee",
    });
    const serviceCategory = server.create("serviceCategory", {
      ...completeCategory,
      carePath,
    } as any);

    const clinicalService = server.create("clinicalService", {
      ...completeClinicalService1,
      serviceCategory,
    } as any);

    server.create("clinicalService", {
      ...completeClinicalService2,
      serviceCategory,
    } as any);

    const clinicalQuestion = server.create("clinicalQuestion", {
      ...completeClinicalQuestion,
      clinicalService,
    } as any);

    server.create("responseOption", {
      ...completeResponseOption1,
      clinicalQuestion,
    } as any);

    server.create("responseOption", {
      ...completeResponseOption2,
      clinicalQuestion,
    } as any);

    server.create("serviceCategory", { carePath } as any);
    server.create("serviceCategory", { carePath } as any);

    // Empty CarePath (initial state)
    server.create("carePath", {
      ...initialCarePath,
      name: "Empty Initial State",
    } as any);
  },
};

const activeScenario = "standard";
//const activeScenario = "short";
//const activeScenario = "empty";

const serializers = {
  carePath: ApplicationSerializer.extend({
    include: ["diseaseArea", "diagnosisCodes", "serviceCategories"],
  }),
  serviceCategory: ApplicationSerializer.extend({
    include: ["clinicalServices"],
  }),
  clinicalService: ApplicationSerializer.extend({
    include: [
      "serviceType",
      "procedureCodes",
      "defaultPlaceOfService",
      "allowedPlaceOfServices",
      "clinicalQuestions",
    ],
    // https://github.com/miragejs/ember-cli-mirage/issues/1002
    // Mirage only applies the "serialize" function to the top-level resource requested.
    // However we want this custom processing for all clinicalServices, including embedded ones.
    _hashForModel(model: any, removeForeignKeys: any, didSerialize = {}) {
      let attrs = (ApplicationSerializer.prototype as any)._hashForModel.apply(
        this,
        arguments
      );
      // Return properties derived from relationships
      // TODO: do we actually need all these?
      // - carepathName and serviceCategoryName were displayed on the clinicalService page, but are currently disabled in the template
      const serviceCategory = model.serviceCategory;
      attrs.serviceCategoryName = serviceCategory?.name || null;
      attrs.serviceCategory = serviceCategory?.id || null;
      const carePath = serviceCategory?.carePath;
      attrs.carePathId = carePath?.id || null;
      attrs.carePathName = carePath?.name || null;

      return attrs;
    },
  }),
  clinicalQuestion: ApplicationSerializer.extend({
    include: ["answers"],
    // https://github.com/miragejs/ember-cli-mirage/issues/1002
    // Mirage only applies the "serialize" function to the top-level resource requested.
    // However we want this custom processing for all clinicalQuestions, including embedded ones.
    _hashForModel(model: any, removeForeignKeys: any, didSerialize = {}) {
      let attrs = (ApplicationSerializer.prototype as any)._hashForModel.apply(
        this,
        arguments
      );
      // 'clinicalService' in response should contain the clinicalServiceId
      // We have to do it here because "serializeIds" doesn't work in combination with "embed: true" (for the answers)
      // https://stackoverflow.com/questions/60980497/is-there-a-way-to-show-related-model-ids-without-sideloading-or-embedding-data
      // TODO: do we actually need to return 'clinicalService' here?
      attrs.clinicalService = model.attrs.clinicalServiceId;
      return attrs;
    },
  }),
  clinicalQuestionAnswer: ApplicationSerializer.extend({
    include: ["observationCodes"],
  }),
};

export { models, factories, scenarios, activeScenario, serializers };
