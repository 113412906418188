import React, { useState } from "react";

import ChipInput from "common/ChipInput";
import listRemove from "util/listRemove";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  searchIconButton: {
    position: "absolute",
    top: "20px",
    right: "12px",
  },
}));

interface Props {
  tinList: string[];
  setTinList: (tinList: string[]) => void;
  label: string;
  withIcon?: boolean;
  required?: boolean;
}

const TIN_LENGTH = 9;

const tinFormatter = (tin: string) => {
  if (/^\d{9}$/.test(tin)) {
    // regex matches on 9 digit strings
    return [tin.slice(0, 2), "-", tin.slice(2, tin.length)].join("");
  }
  return tin;
};

export default function TaxIdChipInput({ tinList, setTinList, label, withIcon, required }: Props) {
  const [chipInputHelperText, setChipInputHelperText] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const handleDeleteChip = (value: string) => {
    const index = tinList.indexOf(value);
    const newTinList = listRemove(tinList, index);
    setTinList(newTinList);
    if (newTinList.length === 0) {
      setChipInputHelperText("Required");
    }
  };
  const classes = useStyles();

  return (
    <ChipInput
      required={required}
      valueFormatter={tinFormatter}
      chipList={tinList}
      error={chipInputHelperText.length > 0}
      handleDeleteChip={handleDeleteChip}
      label={label}
      placeholder={tinList.length ? "Enter another TIN" : ""}
      helperText={chipInputHelperText}
      InputProps={{
        endAdornment: withIcon && (
          <IconButton size="small" className={classes.searchIconButton}>
            <SearchIcon fontSize="medium" />
          </IconButton>
        ),
      }}
      fullWidth={false}
      fullWidthInput={false}
      shouldAutoAdd={(val: string, userPressedEnter: boolean) => {
        if (userPressedEnter) {
          return true;
        }
        if (val.length >= TIN_LENGTH) {
          return true;
        }

        return false;
      }}
      onAdd={(val: string) => {
        const newTins = val
          .split(/[,\s]+/) // split on commas and whitespace
          .map((v) => v.trim())
          .filter(Boolean);
        const goodTins = newTins.filter((t) => t.length === TIN_LENGTH);
        const badTins = newTins.filter((t) => t.length !== TIN_LENGTH);
        if (badTins.length > 0) {
          enqueueSnackbar(`TIN should be ${TIN_LENGTH} digits long: failed to add ${badTins.join(",")}.`, {
            variant: "warning",
            autoHideDuration: 10000,
          });
        } else {
          setChipInputHelperText("");
        }
        setTinList([...tinList, ...goodTins]);
      }}
    />
  );
}
