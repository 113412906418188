/*
  Workaround for IE11 to download a URL, especially blob.
  Inspired by https://github.com/jelmerdemaat/dwnld-attr-polyfill
*/

// https://stackoverflow.com/a/69491367
declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
    msSaveOrOpenBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

const downloadAttributeSupport = "download" in document.createElement("a");
const msSaveBlob = typeof window.navigator.msSaveBlob !== "undefined";
export const useIE11Download = !downloadAttributeSupport && msSaveBlob;

function downloadForIE11(openF?: (response: any, filename?: string) => void) {
  return (url: string, filename?: string) => {
    filename ||= "unknown";

    const xhr = new XMLHttpRequest();

    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 4) {
        return;
      }

      if (openF && xhr.status === 200) {
        openF(xhr.response, filename);
      } else {
        console.error("downloadBlob:", xhr.status, xhr.statusText);
      }
    };

    xhr.send();
  };
}

const downloadUrlEvergreen = (download: boolean) => (url: string, filename?: string) => {
  filename ||= "unknown";

  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  if (download) {
    downloadLink.download = filename;
  }
  downloadLink.click();
};

export const downloadUrl = useIE11Download
  ? downloadForIE11(window.navigator.msSaveBlob?.bind(window.navigator))
  : downloadUrlEvergreen(true);

export const downloadOrOpenUrl = useIE11Download
  ? downloadForIE11(window.navigator.msSaveOrOpenBlob?.bind(window.navigator))
  : downloadUrlEvergreen(false);
