import React from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";
import { colorsLight, H6, InlineButton } from "@coherehealth/common";

interface Props {
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClick: Function;
}

export default function ManuallyVerifyButton({ onClick }: Props) {
  return (
    <Container>
      <Text>Don’t have member data?</Text>
      <StyledButton onClick={() => onClick()}>Verify manually</StyledButton>
      <Subtext>It often takes 1-2 business days to verify manually</Subtext>
    </Container>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Container = styled("div")(({ theme }) => ({ paddingTop: theme.spacing(1), paddingBottom: 200 }));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Text = styled(H6)(({ theme }) => ({
  fontFamily: "Gilroy-SemiBold",
  fontWeight: 400,
  paddingBottom: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Subtext = styled("div")(({ theme }) => ({
  color: colorsLight.font.secondary,
  fontFamily: "Gilroy-Regular",
  fontSize: "13px",
  lineHeight: "15px",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledButton = styled(InlineButton)(({ theme }) => ({
  fontSize: "15px",
  fontFamily: "Gilroy-SemiBold",
  lineHeight: "18px",
  paddingBottom: theme.spacing(0.5),
  paddingTop: theme.spacing(1),
  fontWeight: 400,
}));
