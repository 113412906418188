import { useContext, useEffect, useState } from "react";
import { parseRolesFromUser } from "util/user";
import { UserContext } from "../UserContext";

export default function useUserRoles() {
  const [userRoles, setUserRoles] = useState<Array<string> | undefined>();
  const { getUser } = useContext(UserContext);

  useEffect(() => {
    getUser()?.then((user) => {
      setUserRoles(parseRolesFromUser(user));
    });
  }, [getUser]);

  return userRoles;
}
