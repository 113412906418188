import { useFeature } from "@coherehealth/common";
import { useBearerToken } from "hooks/useBearerToken";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useRef, useState } from "react";
import React from "react";
import { Attachment } from "@coherehealth/core-platform-api";
import { AttachmentInfo } from "@coherehealth/common";
import useOcrEnhancedPath, { useMultipleOcrEnhancedPaths } from "components/AddAttachments/useOcrEnhancedPath";
import { createAttachmentOpenPath, parseServiceSummaryUrl } from "./util";

interface Props {
  attachmentSeachAcrossAttachmentsAllowed?: boolean;
  attachments?: Attachment[];
  attachmentIndexOpen: number;
  orderBySegmentation?: boolean;
  attachmentsInfo: AttachmentInfo[];
  setAttachmentsInfo: React.Dispatch<React.SetStateAction<AttachmentInfo[]>>;
}

type IAttachmentFetchResponse = { fileUrl: string; convertedToPDF: boolean; nonOcr: boolean; index: number };

interface UseAttachmentsLoadResponse {
  downloadError: Error | undefined;
  attachmentSeachAcrossAttachmentsActivated: boolean | undefined;
  loadingRemainingAttachments: boolean | undefined;
}

function useAttachmentsLoad({
  attachmentSeachAcrossAttachmentsAllowed,
  attachmentIndexOpen,
  attachmentsInfo,
  setAttachmentsInfo,
  attachments,
  orderBySegmentation,
}: Props): UseAttachmentsLoadResponse {
  const attachmentSeachAcrossAttachmentsEnabled = useFeature("attachmentSeachAcrossAttachments");

  // FF enabled and Page supports multi search
  const attachmentSeachAcrossAttachmentsActivated =
    attachmentSeachAcrossAttachmentsAllowed && attachmentSeachAcrossAttachmentsEnabled;

  const { enqueueSnackbar } = useSnackbar();

  const [loadingRemainingAttachments, setLoadingRemainingAttachments] = useState<boolean | undefined>(undefined);
  const [downloadError, setDownloadError] = useState<Error>();

  const alreadyQueriedCurrentAttachmentRef = useRef(false);
  const attachmentIndexOpenRef = useRef(attachmentIndexOpen);

  // Compute currentAttachmentOpenPath or Use the /serviceSummary endpoint instead of /attachment
  const currentAttachment = attachments?.[attachmentIndexOpen];
  let currentAttachmentOpenPath = useOcrEnhancedPath(createAttachmentOpenPath(currentAttachment), orderBySegmentation);
  if (currentAttachment && !currentAttachment?.url) {
    currentAttachmentOpenPath = parseServiceSummaryUrl(currentAttachment);
  }

  // Compute allAttachmentsOpenPaths or Use the /serviceSummary endpoint instead of /attachment
  let allAttachmentsOpenPaths = useMultipleOcrEnhancedPaths(
    attachments?.map((att) => createAttachmentOpenPath(att)) || [],
    orderBySegmentation
  );
  allAttachmentsOpenPaths =
    attachments?.map((attachment, index) => {
      if (!attachment.url) {
        return parseServiceSummaryUrl(attachment);
      } else {
        return allAttachmentsOpenPaths[index];
      }
    }) || [];

  const handleError = useCallback(
    (error: Error) => {
      setDownloadError(error);
      enqueueSnackbar("Error downloading attachment", {
        variant: "error",
        preventDuplicate: true,
      });
    },
    [enqueueSnackbar]
  );

  const bearerToken = useBearerToken();

  const download = useCallback(
    async (filePath: string, index: number): Promise<IAttachmentFetchResponse> => {
      // Only handle error for current attachment
      if (attachmentIndexOpenRef.current === index) {
        setDownloadError(undefined);
      }
      if (!attachments) {
        throw new Error("Empty Attachments");
      }
      try {
        const response = await fetch(filePath, {
          headers: {
            Authorization: bearerToken,
          },
        });

        if (response.ok) {
          const nonOcr = response.headers.get("content-disposition")?.endsWith('ml-ocrenhance.pdf"') ? false : true;
          const blob = await response.blob();
          const file = attachments[index];
          const convertedToPDF = blob.type === "application/pdf" && file.contentType !== "application/pdf";
          const fileUrl = window.URL.createObjectURL(blob);

          return {
            fileUrl,
            convertedToPDF,
            nonOcr,
            index,
          };
        } else {
          if (attachmentIndexOpenRef.current === index) {
            handleError({ name: "UnexpectedResponseException", message: "Error downloading attachment" });
          }
          throw new Error("Error downloading attachment : UnexpectedResponseException");
        }
      } catch (error) {
        if (error instanceof Error) {
          if (attachmentIndexOpenRef.current === index) {
            handleError(error);
          }
        }
        throw error;
      }
    },
    [attachments, handleError, bearerToken]
  );

  const fetchCurrentAttachment = useCallback(() => {
    if (!attachments || attachments.length === 0) {
      return;
    }
    alreadyQueriedCurrentAttachmentRef.current = true;
    download(currentAttachmentOpenPath, attachmentIndexOpen).then((res) => {
      const { fileUrl, convertedToPDF, nonOcr } = res;

      setAttachmentsInfo((prevAttachmentsInfo) =>
        prevAttachmentsInfo.map((attachmentInfo, index) => {
          if (index === attachmentIndexOpen) {
            return { ...attachmentInfo, fileUrl, convertedToPDF, nonOcr };
          } else {
            return attachmentInfo;
          }
        })
      );
    });
  }, [download, setAttachmentsInfo, attachmentIndexOpen, attachments, currentAttachmentOpenPath]);

  const fetchRemainingAttachments = useCallback(() => {
    if (!attachments || attachments.length === 0) {
      return;
    }

    const attachmentsPromises = allAttachmentsOpenPaths
      .map((filePath, index) => {
        const isCurrentAttachmentPath = attachmentIndexOpenRef.current === index;
        const hasAlreadySuccessfullyLoaded = attachmentsInfo?.[index]?.fileUrl;

        if (!isCurrentAttachmentPath && !hasAlreadySuccessfullyLoaded) {
          return download(filePath, index);
        } else {
          return undefined;
        }
      })
      .filter((promise) => !!promise);

    if (attachmentsPromises.length === 0) {
      return;
    }

    setLoadingRemainingAttachments(true);
    Promise.allSettled(attachmentsPromises)
      .then((results) => {
        const resolvedResponses = results
          .filter((result) => result.status === "fulfilled")
          .map((result) => (result as PromiseFulfilledResult<IAttachmentFetchResponse>).value);

        setAttachmentsInfo((prev) =>
          prev.map((attachmentInfo, index) => {
            const response = resolvedResponses.find((res) => res.index === index);
            if (!response) {
              return attachmentInfo;
            }
            return {
              ...attachmentInfo,
              fileUrl: response.fileUrl,
              convertedToPDF: response.convertedToPDF,
              nonOcr: response.nonOcr,
            };
          })
        );
      })
      .finally(() => setLoadingRemainingAttachments(false));
  }, [attachments, allAttachmentsOpenPaths, attachmentsInfo, setAttachmentsInfo, download]);

  const doFetch = useCallback(() => {
    fetchCurrentAttachment();
    if (attachmentSeachAcrossAttachmentsActivated) {
      fetchRemainingAttachments();
    }
  }, [fetchCurrentAttachment, fetchRemainingAttachments, attachmentSeachAcrossAttachmentsActivated]);

  useEffect(() => {
    attachmentIndexOpenRef.current = attachmentIndexOpen;
    alreadyQueriedCurrentAttachmentRef.current = false;
  }, [attachmentIndexOpen]);

  useEffect(() => {
    if (alreadyQueriedCurrentAttachmentRef.current || loadingRemainingAttachments) {
      return;
    }
    const hasSuccessfullyLoaded =
      attachmentsInfo?.[attachmentIndexOpen] && attachmentsInfo?.[attachmentIndexOpen]?.fileUrl;
    if (hasSuccessfullyLoaded) {
      return;
    }
    doFetch();
  }, [doFetch, attachmentsInfo, attachmentIndexOpen, loadingRemainingAttachments]);

  return { downloadError, attachmentSeachAcrossAttachmentsActivated, loadingRemainingAttachments };
}

export default useAttachmentsLoad;
