import React from "react";
import { ClinicalAssessmentResponse } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";
import { Body1, Body2, formatDateToISODate, encodeCodesAsString, selectAnswersForQuestion } from "@coherehealth/common";
import { selectedOptions, nonSelectAnswerForQuestion } from "util/clinicalAssessment";
import MuiCheckIcon from "@material-ui/icons/Check";

interface IProps {
  type?: string;
}
type Props = IProps & ClinicalAssessmentResponse;

export default function ClinicalAssessmentAnswerText({ clinicalQuestion, answers, type }: Props) {
  if (!clinicalQuestion) {
    return null;
  }
  const requiredText = clinicalQuestion.required ? " (This question is required)" : undefined;

  if (clinicalQuestion.type === "SINGLE_SELECT") {
    const answersMatchingResponseOptions = selectAnswersForQuestion({ clinicalQuestion, answers }, type);
    const answer =
      answersMatchingResponseOptions.length > 0
        ? answersMatchingResponseOptions[answersMatchingResponseOptions.length - 1]
        : undefined;

    let hasAnswer = false;

    const optionsDisplayWithAnswer = clinicalQuestion.responseOptions?.map(
      ({ optionId, observationCodes, responseOptionText }) => {
        const respOptEncoding = type && type === "AGADIA" ? optionId : encodeCodesAsString(observationCodes);
        const answerEncoding = type && type === "AGADIA" ? answer?.optionId : encodeCodesAsString(answer?.codes);
        if (respOptEncoding === answerEncoding) {
          // this option is the selected answer
          hasAnswer = true;
          return (
            <SelectedAnswerText key={respOptEncoding} data-public>
              <SelectedAnswerCheck fontSize="small" data-public />
              {responseOptionText}
            </SelectedAnswerText>
          );
        } else {
          // this option is _not_ the selected answer
          return (
            <UnselectedAnswerText key={respOptEncoding} data-public>
              {responseOptionText}
            </UnselectedAnswerText>
          );
        }
      }
    );

    return hasAnswer ? (
      <>{optionsDisplayWithAnswer}</>
    ) : (
      <NoResponseQuestionText data-public>Did not answer{requiredText}</NoResponseQuestionText>
    );
  }

  if (clinicalQuestion.type === "DATE") {
    const answer = nonSelectAnswerForQuestion({ clinicalQuestion, answers }, type);
    return answer?.valueDate ? (
      <Body1 data-public>{formatDateToISODate(answer.valueDate)}</Body1>
    ) : (
      <NoResponseQuestionText data-public>Did not answer{requiredText}</NoResponseQuestionText>
    );
  }

  if (clinicalQuestion.type === "MULTI_SELECT") {
    const answerEncodings = selectedOptions({ clinicalQuestion, answers }, type).map(({ optionId, observationCodes }) =>
      type && type === "AGADIA" ? optionId : encodeCodesAsString(observationCodes)
    );

    const optionsAnswersDisplay = clinicalQuestion.responseOptions?.map(
      ({ optionId, observationCodes, responseOptionText }) => {
        const optionEncoding = type && type === "AGADIA" ? optionId : encodeCodesAsString(observationCodes);

        if (answerEncodings.includes(optionEncoding)) {
          // this option is a selected answer
          return (
            <SelectedAnswerText key={optionEncoding} data-public>
              <SelectedAnswerCheck fontSize="small" data-public />
              {responseOptionText}
            </SelectedAnswerText>
          );
        } else {
          // this option is _not_ the selected answer
          return (
            <UnselectedAnswerText key={optionEncoding} data-public>
              {responseOptionText}
            </UnselectedAnswerText>
          );
        }
      }
    );

    return <>{optionsAnswersDisplay}</>;
  }

  if (clinicalQuestion.type === "MEASUREMENT" || clinicalQuestion.type === "FREE_TEXT") {
    const answer = nonSelectAnswerForQuestion({ clinicalQuestion, answers }, type);
    return answer?.valueString ? (
      <Body1 data-public>{answer.valueString}</Body1>
    ) : (
      <NoResponseQuestionText data-public>Did not answer{requiredText}</NoResponseQuestionText>
    );
  }

  return null;
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const NoResponseQuestionText = styled(Body1)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SelectedAnswerCheck = styled(MuiCheckIcon)(({ theme }) => ({
  color: theme.palette.success.main,
  width: theme.spacing(4),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SelectedAnswerText = styled(Body2)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: theme.spacing(-4),
  paddingTop: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const UnselectedAnswerText = styled(Body1)(({ theme }) => ({
  marginLeft: theme.spacing(-4),
  paddingLeft: theme.spacing(4),
  paddingTop: theme.spacing(1),
}));
