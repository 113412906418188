import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { useTrackUserInteraction } from "util/userActivityTracker";
import { guidelineSnapshotFromReview } from "../../../ServiceRequest/ReviewSection";
import { InlineButton, MCGCiteLogo } from "@coherehealth/common";
import { BaseReview } from "@coherehealth/core-platform-api";
import { generatePath } from "react-router";
import { useEffect, useState } from "react";
import { useAuth } from "hooks/useAuth";
import routes from "routes";

interface CareWebQIProps {
  hasInAppGuidelines: boolean;
  serviceRequestId: string;
  review: BaseReview;
}

const POLL_INTERVAL_MS = 10 * 1000; // Poll once every 10s; renews if AT about to expire

export default function CareWebQILink({ hasInAppGuidelines, serviceRequestId, review }: CareWebQIProps) {
  const trackInteraction = useTrackUserInteraction();
  const { oktaAuth } = useAuth();

  // While CWQI is open, we will continuously ping Okta to keep the Cohere session alive
  const [pollFn, setPollFn] = useState<() => void>();
  const openCareWebQI = (openLinkReason: string) => {
    if (openLinkReason !== "") {
      const snap = review.guidelines ? guidelineSnapshotFromReview(review) : undefined;
      trackInteraction({
        event: "USE_CAREWEBQI",
        stage: "GUIDELINE_REVIEW",
        activityContext: { serviceRequestId: serviceRequestId, reviewId: review.id, careWebQiReason: openLinkReason },
        beforeGuidelineSnapshot: snap,
      });
      const path = generatePath(routes.CAREWEBQI, { serviceRequestId: serviceRequestId });

      const cwqi_win = window.open("", "", "width=1400, height=600");
      if (cwqi_win) {
        cwqi_win.opener = null;
        cwqi_win.location = path;

        const renewTokenWhileWindowOpen = () => {
          if (cwqi_win?.closed) {
            // Clear this poll if the window is closed...
            setPollFn(undefined);
          } else {
            // Otherwise CWQI is still open. Ask for the most recent AT and renew if about to expire.
            void oktaAuth.getOrRenewAccessToken();
          }
        };
        setPollFn((_) => renewTokenWhileWindowOpen);
      }
    }
  };

  useEffect(() => {
    if (pollFn) {
      const interval = setInterval(pollFn, POLL_INTERVAL_MS);
      return () => {
        clearInterval(interval);
      };
    }
  }, [pollFn]);

  return (
    <div>
      <InlineButton
        data-testid="care-web-qi-link"
        startIcon={<OpenInNewIcon />}
        onClick={() => {
          hasInAppGuidelines
            ? openCareWebQI("Additional Guidelines Needed")
            : openCareWebQI("No In-App Guidelines Presented");
        }}
        endIcon={<MCGCiteLogo />}
      >
        Open episode
      </InlineButton>
    </div>
  );
}
