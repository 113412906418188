import React from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";

import RequestorCard from "../Requestor/RequestorCard";

import { useAuthorized } from "authorization";
import { Requestor, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { FaxStep } from ".";
import RequestorSummary from "./RequestorSummary";

interface Props {
  serviceRequest: ServiceRequestResponse | undefined;
  currentStep: FaxStep;
  requestor: Requestor | undefined;
  setRequestor: React.Dispatch<React.SetStateAction<Requestor | undefined>>;
  attemptedSubmitService: boolean;
}
export function RequestorSection({
  serviceRequest,
  currentStep,
  requestor,
  setRequestor,
  attemptedSubmitService,
}: Props) {
  const requestorSummaryPermitted = useAuthorized("REQUESTOR_FORM");
  return (
    <>
      {requestorSummaryPermitted && (
        <>
          {Boolean(serviceRequest?.authStatus && serviceRequest.authStatus !== "DRAFT") ||
          currentStep === "SUBMIT_REQUEST" ? (
            <StyledRequestorSummary
              serviceRequest={serviceRequest}
              requestor={requestor}
              setRequestor={setRequestor}
              isReadOnly={true}
            />
          ) : (
            <StyledRequestorCard
              requestor={requestor}
              setRequestor={setRequestor}
              attemptedSubmitServices={attemptedSubmitService}
            />
          )}
        </>
      )}
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledRequestorSummary = styled(RequestorSummary)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledRequestorCard = styled(RequestorCard)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
}));
