import { ClinicalReviewChangeRequest, ReturnRequestTo, ReviewType } from "@coherehealth/core-platform-api";
import { useContext } from "react";
import { NoticationBanner } from "common/NotificationBanner";
import PurpleClipboard from "components/images/PurpleClipboard";
import { ReturnedServiceRequestCard } from "components/ServiceRequest/ReviewSection/ReturnedServiceRequestCard/ReturnedServiceRequestCard";
import { ClinicalReviewContext } from "../Review/ClinicalReviewPage";
import { makeStyles } from "@material-ui/core";

interface ReturnRequestDetailsBannerProps {
  currentClinicalReview?: ReviewType | null;
  notificationBannerClass?: string;
}

export function ReturnRequestDetailsBanner(props: ReturnRequestDetailsBannerProps): JSX.Element {
  const { currentClinicalReview, notificationBannerClass } = props;

  const classes = useStyles();

  const { latestChangeRequestReady, clinicalReviewChangeRequestReady, latestChangeRequest } =
    useContext(ClinicalReviewContext);

  // Validate if there's a Clinical Review that marked this request as "Denied" for the same Clinical Review type and if the
  // Service Request was marked as returned to the Clinical by a Letter Writer

  const wasServiceRequestReturned = checkIsServiceRequestReturnedToCurrentClinicalReview(
    latestChangeRequest,
    currentClinicalReview,
    latestChangeRequestReady,
    clinicalReviewChangeRequestReady
  );

  if (!wasServiceRequestReturned || !latestChangeRequest) {
    return <></>;
  }

  return (
    <NoticationBanner
      header="This request needs another review"
      icon={<PurpleClipboard />}
      contentComponent={<ReturnedServiceRequestCard clinicalReviewChangeRequest={latestChangeRequest} />}
      bannerClassName={notificationBannerClass}
      iconClassName={classes.iconWrapper}
    />
  );
}

const isReturnToRequestAndReviewTypeMatch = (
  returnRequestTo?: ReturnRequestTo,
  reviewType?: ReviewType["reviewType"]
) => {
  return (
    (returnRequestTo === "MD_REVIEW" && reviewType === "MdReview") ||
    (returnRequestTo === "NURSE_REVIEW" && reviewType === "NurseReview")
  );
};

export const checkIsServiceRequestReturnedToCurrentClinicalReview = (
  latestChangeRequest: ClinicalReviewChangeRequest | null,
  currentClinicalReview?: ReviewType | null,
  latestChangeRequestReady?: boolean,
  clinicalReviewChangeRequestReady?: boolean
) => {
  if (
    !latestChangeRequest?.resolved &&
    latestChangeRequestReady &&
    clinicalReviewChangeRequestReady &&
    isReturnToRequestAndReviewTypeMatch(latestChangeRequest?.returnRequestTo, currentClinicalReview?.reviewType)
  ) {
    return true;
  }
  return false;
};

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    maxWidth: 60,
    paddingRight: 20,
  },
}));
