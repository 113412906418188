import { H3 } from "@coherehealth/common";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { purple } from "@material-ui/core/colors";

import { Dispatch, SetStateAction } from "react";

const useStyles = makeStyles((theme) => ({
  bannerWrapper: {
    backgroundColor: "#EAE9F4",
    borderRadius: 8,
    padding: theme.spacing(2.5),
  },
  header: {
    fontFamily: "Gilroy-SemiBold",
    fontSize: 24,
    fontWeight: 400,
    lineHeight: 26 / 24,
    letterSpacing: "0.15px",
    marginBottom: theme.spacing(1.5),
  },
  icon: {
    display: "flex",

    "& svg": {
      width: 40,
      margin: "0 auto",
      height: "min-content",
    },
  },
  iconWrapper: {
    maxWidth: 60,
    paddingRight: 20,
  },
  bodyText: {
    fontFamily: "Gilroy-Medium",
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 17 / 15,
    letterSpacing: "0.15px",
  },
}));

interface ReadmissionBannerProps {
  icon?: JSX.Element;
  header?: string;
  bodyText?: string;
  isOpen: boolean;
  onDismissedClicked: Dispatch<SetStateAction<boolean>>;
  bannerClassName?: string;
  headerClassName?: string;
}

const ReadmissionBanner = ({
  icon,
  header,
  bodyText,
  isOpen,
  onDismissedClicked,
  bannerClassName,
  headerClassName,
}: ReadmissionBannerProps) => {
  const classes = useStyles();

  return (
    <>
      {isOpen ? (
        <>
          <Grid className={`${bannerClassName} ${classes.bannerWrapper}`} container>
            {!!icon && (
              <Grid className={`${classes.icon} ${classes.iconWrapper}`} item xs={2}>
                {icon}
              </Grid>
            )}
            <Grid xs={!!icon ? 10 : 12} item>
              {!!header && (
                <Grid xs={12} item>
                  <H3 className={`${classes.header} ${headerClassName}`}>{header}</H3>
                </Grid>
              )}
              <Grid className={classes.bodyText} xs={12} item>
                {bodyText}
              </Grid>
            </Grid>
            <Grid xs={12} item container justifyContent="flex-end">
              <DismissButton
                color="primary"
                variant="contained"
                disableRipple
                onClick={() => {
                  onDismissedClicked(false);
                }}
              >
                Dismiss
              </DismissButton>
            </Grid>
          </Grid>
        </>
      ) : (
        <> </>
      )}
    </>
  );
};

const DismissButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[900],
    marginTop: theme.spacing(1),
    "&:hover": {
      backgroundColor: purple[800],
    },
  },
}))(Button);
export default ReadmissionBanner;
