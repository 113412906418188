import React, { Dispatch, SetStateAction } from "react";
import { InformativeModal } from "@coherehealth/common";
import ClipboardGrayWarningIcon from "../images/ClipboardGrayWarningIcon.svg";
import { OnboardingSteps } from "./shared";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onClickPrimary: () => void;
  onClickSecondary: () => void;
  onContinueFrom: (step: OnboardingSteps) => void;
  tertiaryButtonLoading?: boolean;
}

export default function ConfirmationModal({
  open,
  setOpen,
  onClickPrimary,
  onClickSecondary,
  onContinueFrom,
  tertiaryButtonLoading,
}: Props) {
  return (
    <InformativeModal
      headerText={"Are you sure you want to proceed with manual verification?"}
      subHeaderText={
        "It often takes 1-2 business days for Cohere to verify manually. If you’d like to start using our portal today go back and use our automatic verification."
      }
      icon={<img src={ClipboardGrayWarningIcon} alt="" />}
      open={open}
      primaryButtonText={"Go back to auto-verification"}
      primaryButtonAction={() => {
        onClickPrimary();
      }}
      tertiaryButtonText={"Continue with manual review"}
      tertiaryButtonAction={() => {
        onContinueFrom("VERIFICATION_UNDER_REVIEW");
        onClickSecondary();
      }}
      tertiaryButtonLoading={tertiaryButtonLoading}
      onClose={() => setOpen(false)}
    />
  );
}
