import React from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";
import { colorsLight, H2, H6 } from "@coherehealth/common";

interface Props {
  trackingNumber?: string;
}

export default function VendorAssessmentCompleteCard({ trackingNumber }: Props) {
  const trackingNumberText = trackingNumber
    ? `Your tracking number for this authorization is #${trackingNumber}`
    : `Check back later for your tracking number.`;
  return (
    <RowContainer>
      <div>
        <TitleText>Vendor clinical assessment complete</TitleText>
        <BodyText>
          {"There are no further questions. To see the clinical assessment from HealthHelp, please call Cohere at "}
          <PhoneNumber>1-833-283-0033</PhoneNumber>
          {" to be redirected to HealthHelp."}
          <br />
          <br />
          {trackingNumberText}
        </BodyText>
      </div>
    </RowContainer>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PhoneNumber = styled("span")(({ theme }) => ({
  whiteSpace: "nowrap",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RowContainer = styled("div")(({ theme }) => ({
  marginTop: "13px",
  padding: theme.spacing(1, 3, 1, 3),
  display: "flex",
  flexDirection: "row",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const BodyText = styled(H6)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  color: colorsLight.font.main,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const TitleText = styled(H2)(({ theme }) => ({
  color: colorsLight.font.main,
}));
