import { NotFoundPage } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";
import { useAuthorized } from "authorization";
import { useState } from "react";
import ConfigurationEditor from "./ConfigurationEditor";
import ConfigurationManagementHeader from "./Header";
import SelectConfigurationDrawer from "./SelectConfigurationDrawer";

export default function ConfigurationManagementPage() {
  const pageAuthorized = useAuthorized("VIEW_CONFIGURATIONS");

  const [selectedFeatureConfigurationName, setSelectedFeatureConfigurationName] = useState<string>("");
  const [selectedApplicationConfigurationId, setSelectedApplicationConfigurationId] = useState<string>("");
  const [selectedApplicationConfigurationParentId, setSelectedApplicationConfigurationParentId] = useState<string>();
  const [selectedConfigurationButtonId, setSelectedConfigurationButtonId] = useState<string | null>(null);

  const navigateToParentConfigurationButton = () => {
    if (selectedApplicationConfigurationParentId) {
      setSelectedConfigurationButtonId(selectedApplicationConfigurationParentId);
      setSelectedApplicationConfigurationId(selectedApplicationConfigurationParentId);
      setSelectedApplicationConfigurationParentId(undefined);
    }
  };

  if (!pageAuthorized) {
    return <NotFoundPage />;
  }

  return (
    <StyledContainer>
      <ConfigurationManagementHeader
        selectedFeatureConfigurationName={selectedFeatureConfigurationName}
        setSelectedFeatureConfigurationName={setSelectedFeatureConfigurationName}
      />
      <SelectConfigurationDrawer
        setSelectedApplicationConfigurationId={setSelectedApplicationConfigurationId}
        setSelectedApplicationConfigurationParentId={setSelectedApplicationConfigurationParentId}
        selectedConfigurationButtonId={selectedConfigurationButtonId}
        setSelectedConfigurationButtonId={setSelectedConfigurationButtonId}
      />
      <ConfigurationEditor
        featureConfigurationFieldName={selectedFeatureConfigurationName}
        applicationConfigurationId={selectedApplicationConfigurationId}
        parentApplicationConfigurationId={selectedApplicationConfigurationParentId}
        navigateToParentConfigurationButton={navigateToParentConfigurationButton}
      />
    </StyledContainer>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledContainer = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  display: "flex",
}));
