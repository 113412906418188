import React from "react";
import { Body1, H2, PrimaryButton, useStableUniqueId } from "@coherehealth/common";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { onCloseBackDrop } from "@coherehealth/common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
    },
    closeButton: {
      float: "right",
      color: theme.palette.text.primary,
      padding: 0,
    },
    title: {
      textAlign: "center",
    },
    icon: {
      alignItems: "center",
      marginBottom: theme.spacing(3),
    },
    details: {
      paddingTop: theme.spacing(2),
    },
    actions: {
      justifyContent: "center",
      padding: theme.spacing(3, 1, 4),
    },
    cancel: {
      padding: `${theme.spacing(2)}px ${theme.spacing(10)}px`,
      backgroundColor: theme.palette.grey[400],
      "&:hover": {
        backgroundColor: theme.palette.grey[400],
      },
    },
    unlock: {
      padding: `${theme.spacing(2)}px ${theme.spacing(10)}px`,
    },
  })
);
interface Props {
  open: boolean;
  handleClose: () => void;
  onUnlock: () => Promise<void>;
  unlocking?: boolean;
}

export default function UnlockMemberModal({ open, handleClose, onUnlock, unlocking }: Props) {
  const classes = useStyles();
  const titleId = useStableUniqueId("unlock-member");
  const instructionsId = useStableUniqueId("unlock-member-instructions");

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      fullWidth
      aria-labelledby={titleId}
      aria-describedby={instructionsId}
      open={open}
      onClose={onCloseBackDrop(handleClose)}
    >
      <DialogTitle className={classes.title} disableTypography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose} data-public>
          <CloseIcon data-public />
        </IconButton>
        <H2 id={titleId} data-public>
          Unlock Account
        </H2>
        <Body1 color="textSecondary" className={classes.details} id={instructionsId} data-public>
          {
            "This member is currently locked out of their account because they exceeded the amount of failed login attempts."
          }
        </Body1>
      </DialogTitle>
      <DialogActions className={classes.actions}>
        <PrimaryButton className={classes.cancel} onClick={handleClose} disableFocusRipple={true} data-public>
          {"Cancel"}
        </PrimaryButton>
        <PrimaryButton
          disabled={unlocking}
          className={classes.unlock}
          loading={unlocking}
          onClick={async () => {
            await onUnlock();
            handleClose();
          }}
          data-public
        >
          {"Unlock"}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}
