import React, { useEffect, useState } from "react";
import {
  Modal,
  H2,
  H4,
  colorsLight,
  Subtitle2,
  Body1,
  Switch,
  PrimaryButton,
  TertiaryButton,
} from "@coherehealth/common";
import { useGetOrganizationById, useUpdateOrganization } from "@coherehealth/core-platform-api";
import { Divider, makeStyles } from "@material-ui/core";
import EnvironmentallyFriendlyIcon from "common/EnvironmentallyFriendlyIcon";
import TrashIcon from "common/TrashIcon";
import { useSnackbar } from "notistack";

interface Props {
  orgId: string;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    height: theme.spacing(17),
    width: theme.spacing(17),
    paddingBottom: theme.spacing(3),
  },
  headerText: {
    paddingBottom: theme.spacing(1),
    textAlign: "center",
  },
  subtitleText: {
    color: colorsLight.font.light,
    textAlign: "center",
    paddingBottom: theme.spacing(3),
    width: "90%",
  },
  divider: {
    color: colorsLight.gray.divider,
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  disabledText: {
    color: colorsLight.font.light,
    marginTop: theme.spacing(1.25),
    marginRight: theme.spacing(1),
    "&.disabled": {
      color: colorsLight.error.dark,
    },
  },
  enabledText: {
    color: colorsLight.font.main,
    marginTop: theme.spacing(1.25),
    paddingRight: theme.spacing(1),
    "&.disabled": {
      color: colorsLight.font.light,
    },
  },
  switch: {
    marginLeft: theme.spacing(0.5),
    "&.disabled": {
      color: colorsLight.gray.dark,
    },
    width: 50,
  },
  primaryButton: {
    marginTop: theme.spacing(1),
    width: "90%",
    marginBottom: theme.spacing(3),
  },
  tertiaryButton: {
    padding: 0,
    color: colorsLight.error.dark,
  },
}));

export default function PaperlessSettingsModal({ orgId, open, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const { data: organization } = useGetOrganizationById({ id: orgId });

  const { mutate: patchOrg, error: patchOrgError } = useUpdateOrganization({ id: orgId });

  const [optedIn, setOptedIn] = useState<boolean>();
  useEffect(() => {
    if (patchOrgError) {
      enqueueSnackbar(`Error saving organization information: ${patchOrgError.message}`, { variant: "error" });
    }
    if (organization) {
      setOptedIn(organization.electronicLettersOptedIn);
    }
  }, [enqueueSnackbar, patchOrgError, organization]);

  const [attemptedDisable, setAttemptedDisable] = useState(false);

  const classes = useStyles({ optedIn });

  const handleClose = () => {
    setAttemptedDisable(false);
    onClose();
  };

  return (
    <Modal onClose={handleClose} open={open}>
      <div className={classes.container}>
        {optedIn && !attemptedDisable ? (
          <EnvironmentallyFriendlyIcon className={classes.image} />
        ) : (
          <TrashIcon className={classes.image} />
        )}
        {!attemptedDisable ? (
          <H2 className={classes.headerText}>Electronic notices{!optedIn && " off"}</H2>
        ) : (
          <H4 className={classes.headerText}>
            Are you sure you want to turn off electronic notices for final determination letters?
          </H4>
        )}
        {attemptedDisable ? (
          <Subtitle2 className={classes.subtitleText}>
            Turning off electronic notices will send a physical copy of every final determination letter to be sent to
            your organization in the mail. As an admin user, you are controlling these settings for all members of your
            organization.
          </Subtitle2>
        ) : (
          <Subtitle2 className={classes.subtitleText}>
            Toggle electronic notice settings to{" "}
            {
              <Subtitle2 style={{ color: colorsLight.font.main }} component="span">
                {optedIn ? "Off" : "On"}
              </Subtitle2>
            }{" "}
            to {optedIn ? "receive" : "stop receiving"} physical copies of the final determination letters in the mail
            when permitted.
          </Subtitle2>
        )}
        <Divider className={classes.divider} />
        {attemptedDisable ? (
          <>
            <PrimaryButton className={classes.primaryButton} fullWidth onClick={() => setAttemptedDisable(false)}>
              No, I want to continue to reduce waste
            </PrimaryButton>
            <TertiaryButton
              className={classes.tertiaryButton}
              onClick={async () => {
                await patchOrg({ electronicLettersOptedIn: false });
                setAttemptedDisable(false);
                setOptedIn(false);
              }}
            >
              Turn off electronic notices
            </TertiaryButton>
          </>
        ) : (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Body1 className={`${classes.disabledText}${optedIn ? "" : " disabled"}`}>Off</Body1>
            <Switch
              checked={optedIn || false}
              onChange={async (val) => {
                if (!val) {
                  setAttemptedDisable(true);
                } else {
                  await patchOrg({ electronicLettersOptedIn: val });
                  setOptedIn(true);
                }
              }}
              className={`${classes.switch}${optedIn ? "" : " disabled"}`}
            />
            <Body1 className={`${classes.enabledText}${optedIn ? "" : " disabled"}`}>On</Body1>
          </div>
        )}
      </div>
    </Modal>
  );
}
