import React, { useEffect } from "react";
import { MutateMethod } from "restful-react";
import { useSnackbar } from "notistack";

import {
  Attachment,
  CreateCarePathJourneyAttachmentPathParams,
  CreateServiceRequestAttachmentPathParams,
  useCreateCarePathJourneyAttachment,
  useCreateReferralRequestAttachment,
  useCreateServiceRequestAttachment,
  useGetCarePathJourneyAttachmentAllowedFileTypes,
  useGetReferralRequestAttachmentAllowedFileTypes,
  useGetServiceRequestAttachmentAllowedFileTypes,
} from "@coherehealth/core-platform-api";
import AddAttachments from "./AddAttachments";

// this name can be potentially cleaned up
export interface AddServiceRequestAttachmentProps {
  carePathJourneyId?: string;
  serviceRequestIds?: string[];
  referralRequestIds?: string[];
  workflowId?: string;
  mockUpload?: (...args: any[]) => Promise<Attachment>;
  initialAttachments?: Attachment[];
  onUpdateAttachments?: (arg0: Attachment[]) => void;
  setAttachmentsCompleted?: (arg0: boolean) => void;
  afterUploadAttachments?: (attachment: Attachment) => void;
  stage?: string;
  allowMultipleAttachmentFiles?: boolean;
  fileSizeLimit?: number;
  setRemovedAttachments?: React.Dispatch<React.SetStateAction<string[]>>;
}

export interface AddServiceRequestAttachmentPropsWithHooks extends AddServiceRequestAttachmentProps {
  uploadFile: MutateMethod<
    Attachment,
    void,
    void,
    CreateCarePathJourneyAttachmentPathParams | CreateServiceRequestAttachmentPathParams
  >;
  uploading: boolean;
  allowedFileExtensions?: string[];
}

function AddAttachmentsToRequest({
  carePathJourneyId,
  serviceRequestIds,
  referralRequestIds,
  workflowId,
  mockUpload,
  initialAttachments = [],
  onUpdateAttachments,
  setAttachmentsCompleted,
  afterUploadAttachments = () => {},
  uploadFile,
  uploading,
  allowedFileExtensions,
  stage,
  allowMultipleAttachmentFiles,
  fileSizeLimit,
  setRemovedAttachments,
}: AddServiceRequestAttachmentPropsWithHooks) {
  const isOnCarePathJourney = Boolean(carePathJourneyId);

  const afterUpload = (response: Attachment, totalFiles: number) => {
    afterUploadAttachments(response || undefined);
  };

  return (
    <AddAttachments
      carePathJourneyId={carePathJourneyId}
      serviceRequestIds={serviceRequestIds}
      referralRequestIds={referralRequestIds}
      uploadFile={uploadFile}
      uploading={uploading}
      afterUpload={afterUpload}
      mockUpload={mockUpload}
      initialAttachments={initialAttachments}
      onUpdateAttachments={onUpdateAttachments}
      setAttachmentsCompleted={setAttachmentsCompleted}
      allowedFileExtensions={allowedFileExtensions}
      isOnCarePathJourney={isOnCarePathJourney}
      workflowId={workflowId}
      stage={stage}
      allowMultipleAttachmentFiles={allowMultipleAttachmentFiles}
      fileSizeLimit={fileSizeLimit}
      setRemovedAttachments={setRemovedAttachments ? setRemovedAttachments : () => {}}
    />
  );
}

export default function AddAttachmentsWrapper(props: AddServiceRequestAttachmentProps) {
  if (props.carePathJourneyId) {
    return <CarePathJourneyAttachmentsWrapper {...props} />;
  } else {
    return <ServiceRequestAttachmentsWrapper {...props} />;
  }
}

function ServiceRequestAttachmentsWrapper({
  serviceRequestIds,
  workflowId,
  mockUpload,
  initialAttachments = [],
  onUpdateAttachments,
  setAttachmentsCompleted,
  afterUploadAttachments = () => {},
  stage,
  allowMultipleAttachmentFiles,
  fileSizeLimit,
  setRemovedAttachments,
}: AddServiceRequestAttachmentProps) {
  const {
    mutate: uploadSRAttachment,
    loading: loadingSRAttachment,
    error: uploadSRAttachmentError,
  } = useCreateServiceRequestAttachment({
    id: "",
    requestOptions: { headers: { Accept: "application/json" } },
  });

  const { data: allowedFileTypeRespSRAttachment } = useGetServiceRequestAttachmentAllowedFileTypes({
    serviceRequestId: serviceRequestIds && serviceRequestIds.length > 0 ? serviceRequestIds[0] : "",
  });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (uploadSRAttachmentError) {
      const errorMessage =
        typeof uploadSRAttachmentError.data === "object"
          ? uploadSRAttachmentError.data.message || uploadSRAttachmentError.message
          : uploadSRAttachmentError.message;
      enqueueSnackbar(`Failed to create service request attachment: ${errorMessage}`, {
        variant: "error",
      });
    }
  }, [uploadSRAttachmentError, enqueueSnackbar]);

  return (
    <AddAttachmentsToRequest
      serviceRequestIds={serviceRequestIds}
      workflowId={workflowId}
      mockUpload={mockUpload}
      initialAttachments={initialAttachments}
      onUpdateAttachments={onUpdateAttachments}
      setAttachmentsCompleted={setAttachmentsCompleted}
      afterUploadAttachments={afterUploadAttachments}
      uploadFile={uploadSRAttachment}
      uploading={loadingSRAttachment}
      allowedFileExtensions={allowedFileTypeRespSRAttachment?.allowedFileExtensions}
      stage={stage}
      allowMultipleAttachmentFiles={allowMultipleAttachmentFiles}
      fileSizeLimit={fileSizeLimit}
      setRemovedAttachments={setRemovedAttachments}
    />
  );
}

function CarePathJourneyAttachmentsWrapper({
  carePathJourneyId,
  serviceRequestIds,
  workflowId,
  mockUpload,
  initialAttachments = [],
  onUpdateAttachments,
  setAttachmentsCompleted,
  afterUploadAttachments = () => {},
}: AddServiceRequestAttachmentProps) {
  const {
    mutate: uploadCarePathJourneyAttachment,
    loading: loadingCarePathJourneyAttachment,
    error: uploadCarePathJourneyAttachmentError,
  } = useCreateCarePathJourneyAttachment({ id: carePathJourneyId || "" });
  const { data: allowedFileTypeRespCarePathJourneyAttachment } = useGetCarePathJourneyAttachmentAllowedFileTypes({
    carePathJourneyId: carePathJourneyId || "",
  });

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (uploadCarePathJourneyAttachmentError) {
      enqueueSnackbar(
        `Failed to create care path journey attachment: ${uploadCarePathJourneyAttachmentError.message}`,
        { variant: "error" }
      );
    }
  }, [uploadCarePathJourneyAttachmentError, enqueueSnackbar]);

  return (
    <AddAttachmentsToRequest
      carePathJourneyId={carePathJourneyId}
      serviceRequestIds={serviceRequestIds}
      workflowId={workflowId}
      mockUpload={mockUpload}
      initialAttachments={initialAttachments}
      onUpdateAttachments={onUpdateAttachments}
      setAttachmentsCompleted={setAttachmentsCompleted}
      afterUploadAttachments={afterUploadAttachments}
      uploadFile={uploadCarePathJourneyAttachment}
      uploading={loadingCarePathJourneyAttachment}
      allowedFileExtensions={allowedFileTypeRespCarePathJourneyAttachment?.allowedFileExtensions}
    />
  );
}

export const ReferralAttachmentsWrapper = ({
  referralRequestIds,
  workflowId,
  mockUpload,
  initialAttachments = [],
  onUpdateAttachments,
  setAttachmentsCompleted,
  afterUploadAttachments = () => {},
  stage,
  allowMultipleAttachmentFiles,
  fileSizeLimit,
  setRemovedAttachments,
}: AddServiceRequestAttachmentProps) => {
  const { data: allowedFileTypeRespRRAttachment } = useGetReferralRequestAttachmentAllowedFileTypes({
    referralRequestId: referralRequestIds && referralRequestIds.length > 0 ? referralRequestIds[0] : "",
  });

  const {
    mutate: uploadRRAttachment,
    loading: loadingRRAttachment,
    error: uploadRRAttachmentError,
  } = useCreateReferralRequestAttachment({
    id: "",
    requestOptions: { headers: { Accept: "application/json" } },
  });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (uploadRRAttachmentError) {
      const errorMessage =
        typeof uploadRRAttachmentError.data === "object"
          ? uploadRRAttachmentError.data.message || uploadRRAttachmentError.message
          : uploadRRAttachmentError.message;
      enqueueSnackbar(`Failed to create referral request attachment: ${errorMessage}`, {
        variant: "error",
      });
    }
  }, [uploadRRAttachmentError, enqueueSnackbar]);

  return (
    <AddAttachmentsToRequest
      referralRequestIds={referralRequestIds}
      workflowId={workflowId}
      mockUpload={mockUpload}
      initialAttachments={initialAttachments}
      onUpdateAttachments={onUpdateAttachments}
      setAttachmentsCompleted={setAttachmentsCompleted}
      afterUploadAttachments={afterUploadAttachments}
      uploadFile={uploadRRAttachment}
      uploading={loadingRRAttachment}
      allowedFileExtensions={allowedFileTypeRespRRAttachment?.allowedFileExtensions}
      stage={stage}
      allowMultipleAttachmentFiles={allowMultipleAttachmentFiles}
      fileSizeLimit={fileSizeLimit}
      setRemovedAttachments={setRemovedAttachments}
    />
  );
};
