import React, { SVGProps } from "react";

export default function ServiceRequestMoreInfoIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M64 127.125C98.863 127.125 127.125 98.863 127.125 64C127.125 29.137 98.863 0.875 64 0.875C29.137 0.875 0.875 29.137 0.875 64C0.875 98.863 29.137 127.125 64 127.125Z"
        fill="black"
        opacity="0.04"
      />
      <path d="M94.85 29.6967H33.25V101.542H94.85V29.6967Z" fill="#A1AAB4" />
      <path d="M80.3241 34.0503H37.6094V97.1795H90.4676V44.2193L80.3241 34.0503Z" fill="white" />
      <path
        d="M78.004 20.7999H69.7975C69.7975 17.1809 67.2234 14.25 64.0376 14.25C60.8518 14.25 58.2777 17.1809 58.2777 20.7999H50.0712L47.752 35.327H80.2977L78.004 20.7999Z"
        fill="#5E6572"
      />
      <path
        d="M53.7668 44.071H49.3832V39.6874H46.3758V44.071H41.9922V47.0784H46.3758V51.462H49.3832V47.0784H53.7668V44.071Z"
        fill="#F83275"
      />
      <path d="M77.7493 56.3306H50.3262V58.3949H77.7493V56.3306Z" fill="#89D3DD" />
      <path d="M85.3945 89.3811H74.4609V91.4455H85.3945V89.3811Z" fill="#89D3DD" />
      <path d="M85.3945 63.8965H42.6797V65.9609H85.3945V63.8965Z" fill="#C2C2C2" />
      <path d="M85.3945 70.0885H42.6797V72.1529H85.3945V70.0885Z" fill="#C2C2C2" />
      <path d="M85.3945 76.2885H42.6797V78.3528H85.3945V76.2885Z" fill="#C2C2C2" />
      <path d="M61.7688 82.4766H42.6797V84.541H61.7688V82.4766Z" fill="#C2C2C2" />
      <path d="M80.3223 44.2193H90.4658L80.3223 34.0503V44.2193Z" fill="#F5F5F5" />
      <path d="M70.775 88.3501L67.625 85.2251L65.75 90.2251L70.775 88.3501Z" fill="#212936" />
      <path d="M80.225 84.8249L71.175 75.7749L67.625 85.2249L70.775 88.3499L80.225 84.8249Z" fill="#E5E5E5" />
      <path d="M73.8 78.4249L71.15 75.7749L70.125 78.4999C71.2 79.4499 72.8 79.4249 73.8 78.4249Z" fill="#89D3DD" />
      <path
        d="M73.8 82.1751C74.85 83.2251 76.525 83.2251 77.55 82.1751L73.8 78.425C72.775 79.4501 72.775 81.1501 73.8 82.1751Z"
        fill="#039EC3"
      />
      <path
        d="M77.5759 82.175C76.5759 83.175 76.5509 84.8 77.5009 85.85L80.2259 84.825L77.5759 82.175Z"
        fill="#89D3DD"
      />
      <path d="M98.6121 53.6279L73.8105 78.4294L77.5759 82.1947L102.377 57.3932L98.6121 53.6279Z" fill="#039EC3" />
      <path
        d="M95.9508 50.9751L71.1758 75.7501V75.7751L73.8008 78.4251L98.6008 53.6251L95.9508 50.9751Z"
        fill="#89D3DD"
      />
      <path d="M102.374 57.375L77.5742 82.175L80.2242 84.825L105.024 60.025L102.374 57.375Z" fill="#89D3DD" />
      <path
        d="M109.025 52.725L103.275 46.975C102.35 46.05 100.875 46.05 99.9746 46.975L95.9746 50.975L105.025 60.025L109.025 56.025C109.95 55.1 109.95 53.625 109.025 52.725Z"
        fill="#F83275"
      />
      <path d="M97.6797 49.2392L95.9473 50.9716L105.016 60.0401L106.748 58.3077L97.6797 49.2392Z" fill="#F83275" />
    </svg>
  );
}
