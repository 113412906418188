import React from "react";
import { CircularProgress } from "@material-ui/core";
import { Body1 } from "../Typography";
import { Card } from "../Card";
import { ClassNameMap } from "@material-ui/styles";

export interface ErrorCardProps {
  loadingServiceRequest: boolean;
  serviceRequestSelectionCardStyles: (props?: any) => ClassNameMap;
}

export function ErrorSelectionCard(props: ErrorCardProps): JSX.Element {
  const { loadingServiceRequest, serviceRequestSelectionCardStyles } = props;

  const classes = serviceRequestSelectionCardStyles();

  return (
    <Card className={classes.serviceRequestSelectionCard}>
      {loadingServiceRequest ? (
        <CircularProgress className={classes.loadingSpinner} size={40} />
      ) : (
        <Body1 color="error">There was an issue displaying this service request</Body1>
      )}
    </Card>
  );
}
