import { Model, Factory, Server } from "miragejs";

const models = {
  observationCode: Model,
};
const factories = {
  observationCode: Factory.extend({
    code(i: number) {
      return `OBSCODE${i}`;
    },
    description(i: number) {
      return `Description ${i}`;
    },
    system(i: number) {
      return "SYSTEM";
    },
  }),
};

const scenarios = {
  standard(server: Server) {
    server.createList("observationCode", 100);
  },
};

const activeScenario = "standard";
//const activeScenario = "short";
//const activeScenario = "empty";

export { models, factories, scenarios, activeScenario };
