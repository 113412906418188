import React from "react";

import { Body1, Caption, colorsLight, Subtitle2 } from "@coherehealth/common";
import { ServiceRequestRelation, ServiceRequestRelationType } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useNavigate } from "react-router-dom";
import ForkRelationshipIcon from "./icons/ForkRelationshipIcon.svg";
import ReplacementRelationshipIcon from "./icons/ReplacementRelationIcon.svg";
import { trackUserActivity } from "util/userActivityTracker";
import { routeToPatientSummaryFromReview } from "util/routeUtils/routeUtils";

interface Props {
  /**
   * Id of the service request the contextual service request
   */
  sourceServiceRequestId: string;
  serviceRequestRelation: ServiceRequestRelation;
  patientId?: string;
  className?: string;
  isOnPatientSummary?: boolean;
}

interface DisplayConfig {
  relationshipTypeText: string;
  icon: string;
}

const DisplayConfigMap: Record<ServiceRequestRelationType, Record<"sourceIsTo" | "sourceIsFrom", DisplayConfig>> = {
  FORK: {
    sourceIsTo: {
      relationshipTypeText: "Details copied from",
      icon: ForkRelationshipIcon,
    },
    sourceIsFrom: {
      relationshipTypeText: "Details copied to",
      icon: ForkRelationshipIcon,
    },
  },
  REPLACEMENT: {
    sourceIsTo: {
      relationshipTypeText: "Replaces",
      icon: ReplacementRelationshipIcon,
    },
    sourceIsFrom: {
      relationshipTypeText: "Replaced by",
      icon: ReplacementRelationshipIcon,
    },
  },
  GROUP: {
    sourceIsTo: {
      relationshipTypeText: "Grouped with",
      icon: ReplacementRelationshipIcon,
    },
    sourceIsFrom: {
      relationshipTypeText: "Grouped with",
      icon: ReplacementRelationshipIcon,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  linkBox: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    padding: theme.spacing(1.5),
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: theme.spacing(1),
    minHeight: 44,
    width: "100%",
    "&:hover": {
      boxShadow: "0px 3px 5px rgba(207, 226, 231, 0.3)",
    },
  },
}));

export default function ServiceRequestRelationDisplay({
  sourceServiceRequestId,
  serviceRequestRelation: { relation: relationType, to, from },
  patientId,
  className,
  isOnPatientSummary,
}: Props) {
  const navigate = useNavigate();
  const classes = useStyles();

  if (!relationType || !to || !from) {
    return null;
  }

  // get the service request that is the "Related" one (not the source)
  const relatedServiceRequest = sourceServiceRequestId === to.id ? from : to;
  const sourceServiceRequest = sourceServiceRequestId === to.id ? to : from;
  const displayConfig =
    DisplayConfigMap[relationType][sourceServiceRequestId === to.id ? "sourceIsTo" : "sourceIsFrom"];

  let rightText = "";
  if (relationType === "FORK") {
    const numCodesOnToSr = to.semanticProcedureCodes?.length;
    if (
      relatedServiceRequest.semanticProcedureCodes?.[0].code !==
        sourceServiceRequest.semanticProcedureCodes?.[0].code &&
      numCodesOnToSr
    ) {
      // If 1 procedure code is different than we know the forked SR has Pxs added
      rightText = `${numCodesOnToSr} CPT code${numCodesOnToSr > 1 ? "s" : ""}`;
    }
    if (relatedServiceRequest.units !== sourceServiceRequest.units && to.units) {
      // If the unit count is different then we assume the units on the new service request are "additive"
      if (rightText) {
        rightText = rightText + " and ";
      }
      rightText = `${rightText}${to.units} unit${to.units > 1 ? "s" : ""}`;
    }
    if (rightText) {
      rightText = rightText + " added";
    }
  }

  const link = patientId && routeToPatientSummaryFromReview({ serviceRequestId: relatedServiceRequest.id, patientId });

  return (
    <div className={className}>
      <RelationshipTypeCaption>{displayConfig.relationshipTypeText}</RelationshipTypeCaption>
      <ButtonBase
        component={isOnPatientSummary ? "button" : "a"}
        className={classes.linkBox}
        onClick={() => {
          trackUserActivity(`click - linkage card (${isOnPatientSummary ? "patient summary" : "dashboard"})`);
          isOnPatientSummary && link && navigate(link);
        }}
        href={isOnPatientSummary ? undefined : link}
        color="textPrimary"
      >
        <RelationshipTypeIcon src={displayConfig.icon} alt="" />
        {relatedServiceRequest.clinicalService?.name && (
          <RelationshipLinkBoxBody1>{relatedServiceRequest.clinicalService?.name}</RelationshipLinkBoxBody1>
        )}
        <RelationshipLinkBoxSubtitle2>Tracking #{relatedServiceRequest.cohereId}</RelationshipLinkBoxSubtitle2>
        {relatedServiceRequest.authNumber && (
          <RelationshipLinkBoxSubtitle2>Authorization #{relatedServiceRequest.authNumber}</RelationshipLinkBoxSubtitle2>
        )}
        {rightText && <RightAlignedText>{rightText}</RightAlignedText>}
      </ButtonBase>
    </div>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RelationshipTypeIcon = styled("img")(({ theme }) => ({
  height: 20,
  width: 20,
  marginRight: theme.spacing(1.5),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RelationshipLinkBoxBody1 = styled(Body1)(({ theme }) => ({
  marginRight: theme.spacing(1.5),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RelationshipTypeCaption = styled(Caption)({
  color: colorsLight.font.secondary,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RelationshipLinkBoxSubtitle2 = styled(Subtitle2)(({ theme }) => ({
  marginRight: theme.spacing(1.5),
  color: colorsLight.font.light,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RightAlignedText = styled(Body1)({
  marginLeft: "auto",
});
