import { makeStyles } from "@material-ui/core";

type StyleProps = {
  saveReviews: boolean;
};
export const useReviewControlPanelStyles = makeStyles((theme) => ({
  finishButton: {
    width: ({ saveReviews }: StyleProps) => (saveReviews ? "250px" : 258),
    height: 48,
    padding: theme.spacing(0),
    marginLeft: ({ saveReviews }: StyleProps) => (saveReviews ? "20px" : ""),
  },
  discardAndExitButton: {
    width: 138,
    height: 48,
    padding: theme.spacing(0),
    marginRight: "10px",
  },
  saveAndExitButton: {
    width: 138,
    height: 48,
    padding: theme.spacing(0),
    marginLeft: "10px",
  },

  discardReviewButton: {
    width: 258,
    height: 48,
    padding: theme.spacing(0),
    marginRight: theme.spacing(3),
  },
}));
