import React, { FunctionComponent } from "react";
import OutOfNetworkBanner from "components/ServiceRequest/ServiceRequestForm/components/OutOfNetworkBanner";
import { Body2, Caption, useNetworkCheckStatusStyles } from "@coherehealth/common";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import QuestionIcon from "@material-ui/icons/HelpRounded";
import { CircularProgress } from "@material-ui/core";
import { FormConfigurationFieldSpecification, NetworkType } from "@coherehealth/core-platform-api";
import CancelIcon from "@material-ui/icons/Cancel";

export type EntityTypeFieldName =
  | "orderingProvider"
  | "performingProvider"
  | "performingProviderPractice"
  | "facility"
  | "careParticipant";

export interface NetworkCheckStatusDisplayProps {
  oonLoading?: boolean;
  oonExceptionConfig: FormConfigurationFieldSpecification;
  networkType?: NetworkType;
  entityType: EntityTypeFieldName;
  careParticipantType?: string;
  isOonExceptionRequired?: boolean;
  isOonExceptionOmit?: boolean;
  shouldBlockOonChanges?: boolean;
  isBlockingOonChange?: boolean;
}

function getEntityTypeText(entityType: EntityTypeFieldName, careParticipantType?: string): string {
  switch (entityType) {
    case "orderingProvider":
      return "provider";
    case "performingProvider":
      return "provider";
    case "performingProviderPractice":
      return "practice";
    case "facility":
      return "facility";
    case "careParticipant":
      return careParticipantType ? careParticipantType : "";
    default:
      return "";
  }
}

function getUnknownNetworkText(
  entityType: EntityTypeFieldName,
  isOonExceptionRequired?: boolean,
  isOonExceptionOmit?: boolean,
  careParticipantType?: string
) {
  let unknownDisplayText = "Network status unknown, and your request may pend for review.";
  const entityTypeText = getEntityTypeText(entityType, careParticipantType);

  if (isOonExceptionRequired) {
    unknownDisplayText = unknownDisplayText.concat(
      ` If the ${entityTypeText} is out-of-network, please provide an exception reason below. If the ${entityTypeText} is in-network, select “This ${entityTypeText} should be listed as in-network for this patient“.`
    );
  } else {
    if (!isOonExceptionOmit) {
      unknownDisplayText = unknownDisplayText.concat(
        ` Ask for an exception if you believe this ${entityTypeText} is out-of-network and in-network rates should apply.`
      );
    } else {
      return unknownDisplayText;
    }
  }

  return unknownDisplayText;
}
export const NetworkCheckStatusDisplay: FunctionComponent<NetworkCheckStatusDisplayProps> = ({
  oonLoading,
  oonExceptionConfig,
  networkType,
  entityType,
  careParticipantType,
  isOonExceptionRequired,
  isOonExceptionOmit,
  shouldBlockOonChanges,
  isBlockingOonChange,
}) => {
  const classes = useNetworkCheckStatusStyles();
  if (!oonLoading) {
    if (isBlockingOonChange) {
      return <BlockingOutOfNetworkDisplay entityType={entityType} careParticipantType={careParticipantType} />;
    } else if (networkType === "OON" || networkType === "OOA") {
      return (
        <div className={classes.oonStatusContainerRoot}>
          <OutOfNetworkBanner
            performingProviderOON={entityType === "performingProvider"}
            performingProviderPracticeOON={entityType === "performingProviderPractice"}
            facilityOON={entityType === "facility"}
            orderingProviderOON={entityType === "orderingProvider"}
            oonExceptionConfig={oonExceptionConfig}
            networkType={networkType || undefined}
            careParticipantOON={entityType === "careParticipant"}
            careParticipantType={careParticipantType}
          ></OutOfNetworkBanner>
        </div>
      );
    } else if (networkType === "INN") {
      return <InNetworkDisplay showOonChangesCaption={shouldBlockOonChanges} entityType={entityType} />;
    } else if (networkType === "Unknown") {
      const unknownDisplayText = `${getUnknownNetworkText(
        entityType,
        isOonExceptionRequired,
        isOonExceptionOmit,
        careParticipantType
      )}`;
      return <UnknownNetworkDisplay unknownDisplayText={unknownDisplayText} />;
    } else {
      return <></>;
    }
  } else {
    return <OonCheckLoadingDisplay />;
  }
};

export const OonCheckLoadingDisplay = () => {
  const classes = useNetworkCheckStatusStyles();
  return (
    <div className={classes.oonStatusContainerRoot}>
      <div className={classes.oonStatusContainer}>
        <div className={classes.iconContainer}>
          <CircularProgress size={24} />
        </div>
        <div className={classes.verticallyCenteredContainer}>
          <div className={classes.verticallyCenteredItem}>
            <Body2 className={classes.networkCheckRunningText}>Running network check</Body2>
          </div>
        </div>
      </div>
    </div>
  );
};

interface InNetworkDisplayProps {
  entityType?: EntityTypeFieldName;
  showOonChangesCaption?: boolean;
}

export const InNetworkDisplay = ({ showOonChangesCaption, entityType }: InNetworkDisplayProps) => {
  const classes = useNetworkCheckStatusStyles();
  return (
    <div>
      <div className={classes.oonStatusContainerRoot}>
        <div className={classes.oonStatusContainer}>
          <div className={classes.iconContainer}>
            <CheckCircleIcon className={classes.successIcon} />
          </div>
          <div className={classes.verticallyCenteredContainer}>
            <div className={classes.verticallyCenteredItem}>
              <Body2 className={classes.oonStatusText}>Network check complete</Body2>
            </div>
          </div>
        </div>
      </div>
      {showOonChangesCaption && entityType && (
        <div className={classes.networkCheckCaptionContainer}>
          <Caption className={classes.networkCheckCaption}>
            Since this request has been approved, any {getEntityTypeText(entityType)} changes must stay in-network.
          </Caption>
        </div>
      )}
    </div>
  );
};

interface OONDisplayProps {
  unknownDisplayText: string;
}

export const UnknownNetworkDisplay = ({ unknownDisplayText }: OONDisplayProps) => {
  const classes = useNetworkCheckStatusStyles();
  return (
    <div className={classes.oonStatusContainerRoot}>
      <div className={classes.oonStatusContainer}>
        <div className={classes.iconContainer}>
          <QuestionIcon className={classes.unknownIcon} />
        </div>
        <div className={classes.verticallyCenteredContainer}>
          <div className={classes.verticallyCenteredItem}>
            <Body2 className={classes.unknownStatusText}>{unknownDisplayText}</Body2>
          </div>
        </div>
      </div>
    </div>
  );
};

interface BlockingOutOfNetworkDisplayProps {
  entityType: EntityTypeFieldName;
  careParticipantType?: string;
}

export const BlockingOutOfNetworkDisplay = ({ entityType, careParticipantType }: BlockingOutOfNetworkDisplayProps) => {
  const classes = useNetworkCheckStatusStyles();
  const entityTypeText = getEntityTypeText(entityType, careParticipantType);
  const capitalizedEntityTypeText = entityTypeText.charAt(0).toUpperCase() + entityTypeText.slice(1);
  const displayText = `${capitalizedEntityTypeText} is out-of-network. Since this request has been approved, any ${entityTypeText} changes must stay in-network.`;
  return (
    <div className={classes.oonStatusContainerRoot}>
      <div className={classes.oonStatusContainer}>
        <div className={classes.iconContainer}>
          <CancelIcon className={classes.cancelIcon} />
        </div>
        <div className={classes.verticallyCenteredContainer}>
          <div className={classes.verticallyCenteredItem}>
            <Body2 className={classes.errorOonStatusText}>{displayText}</Body2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NetworkCheckStatusDisplay;
