import React from "react";

import routes from "routes";
import { useMatch } from "react-router-dom";

import FaxAttachmentPageContent from "../FaxAttachmentPageContent";

export default function FaxReferralsBuilderPage() {
  const match = useMatch(routes.FAX_REFERRAL_BUILDER);

  return <FaxAttachmentPageContent match={match} />;
}
