import React from "react";

import { Caption, SecondaryButton, PrimaryButton, H3, Modal, useStableUniqueId } from "@coherehealth/common";
import CheckIcon from "@material-ui/icons/Done";

import { useAutoApproveErrorAlertModalStyles } from "../patientSummaryStyles";

interface Props {
  open: boolean;
  handleClose: () => void;
  scrollToReview: () => void;
}

export default function AutoApproveAlertModal({ open, handleClose, scrollToReview }: Props) {
  const autoApproveErrorAlertModalStyles = useAutoApproveErrorAlertModalStyles();
  const titleId = useStableUniqueId("auto-approve-alert");
  const instructionsId = useStableUniqueId("auto-approve-alert-instructions");

  return (
    <Modal onClose={handleClose} fullWidth aria-labelledby={titleId} aria-describedby={instructionsId} open={open}>
      <div className={autoApproveErrorAlertModalStyles.modalContent}>
        <CheckIcon className={autoApproveErrorAlertModalStyles.icon} />
        <H3 className={autoApproveErrorAlertModalStyles.title} id={titleId}>
          This change to the service details would make this service request auto-approve.
        </H3>
        <Caption className={autoApproveErrorAlertModalStyles.details} id={instructionsId}>
          Go to review to approve the request now.
        </Caption>
        <div>
          <SecondaryButton className={autoApproveErrorAlertModalStyles.leftButton} onClick={handleClose}>
            Dismiss
          </SecondaryButton>
          <PrimaryButton
            onClick={() => {
              handleClose();
              scrollToReview();
            }}
          >
            Go to review
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
}
