import React, { PropsWithChildren } from "react";
import { Body1, Card, colorsLight, H4 } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  titleText: string;
  topContent?: JSX.Element;
  displayQuestionSubtext?: string;
}

export default function OnboardingQuestionCard({
  titleText,
  topContent,
  displayQuestionSubtext,
  children,
}: PropsWithChildren<Props>) {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <H4 className={classes.titleText}>{titleText}</H4>
      {topContent && <div className={classes.topContent}>{topContent}</div>}
      {displayQuestionSubtext && <Body1 className={classes.questionSubtext}>{displayQuestionSubtext}</Body1>}
      <div>{children}</div>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
  },
  titleText: {
    paddingBottom: theme.spacing(2),
  },
  questionSubtext: {
    paddingTop: theme.spacing(1),
    color: colorsLight.font.secondary,
    paddingBottom: theme.spacing(2),
  },
  topContent: {
    paddingBottom: theme.spacing(2),
  },
}));
