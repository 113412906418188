import { DropdownOption, SingleSelectDropdown } from "../Dropdown";
import { BaseFilterConfig } from "./shared";

export interface SingleSelectFilterConfig extends BaseFilterConfig {
  filterType: "SingleSelect";
  options: DropdownOption[];
  optionToLabel?: (option: unknown) => string;
  optionToParamValue?: (option: DropdownOption) => string;
  initialFilterValue?: unknown;
}

export interface SingleSelectFilterProps {
  filterConfig: SingleSelectFilterConfig;
  value: DropdownOption | null | undefined;
  onChange?: (key: string, newValue: DropdownOption | null) => void;
}

export function SingleSelectFilter({ filterConfig, value, onChange }: SingleSelectFilterProps) {
  const { key, label, options } = filterConfig;
  const handleChange = (selectedId: string) => {
    const selectedOption = options.find(({ id }) => id === selectedId);
    if (onChange) {
      onChange(key, selectedOption || null);
    }
  };
  return <SingleSelectDropdown label={label} options={options} value={value?.id || ""} onChange={handleChange} />;
}
