import { MultipleSelect, SelectOptionsHook } from "../Autocomplete";
import { BaseFilterConfig } from "./shared";

export interface TextFieldFilterConfig extends BaseFilterConfig {
  filterType: "TextField";
  postProcessValue?: (value: string[]) => string[];
  initialFilterValue?: unknown;
}

export interface TextFieldFilterProps {
  filterConfig: TextFieldFilterConfig;
  value: string[];
  onChange?: (key: string, newState: string[]) => void;
}

export function TextFieldFilter({ filterConfig, value, onChange }: TextFieldFilterProps) {
  const { key, label, postProcessValue } = filterConfig;

  const handleChange = (option: string[]) => {
    if (onChange) {
      const finalOption = postProcessValue ? postProcessValue(option) : option;
      onChange(key, finalOption);
    }
  };

  const useOptions: SelectOptionsHook<string> = ({ query }) => ({
    optionsLoading: false,
    options: query ? [query] : [],
  });

  return (
    <MultipleSelect<string>
      label={label}
      selectedValue={value || []}
      useOptions={useOptions}
      setSelectedValue={handleChange}
      selectOnEnter
    />
  );
}
