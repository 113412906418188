import { AuthStatus, ProcedureCode } from "@coherehealth/core-platform-api";

const getCodesInfo = ({
  priorCodes,
  isOnPx,
  authStatus,
  unitsOnPxCodes,
  unitsOnservicePxCode,
}: {
  priorCodes?: ProcedureCode[];
  isOnPx: boolean;
  authStatus?: AuthStatus;
  unitsOnPxCodes?: ProcedureCode[];
  unitsOnservicePxCode?: ProcedureCode[];
}) => {
  const approvedCodes = unitsOnservicePxCode?.filter((code) => code.approvedUnits) || [];
  const unapprovedCodes = unitsOnservicePxCode?.filter((code) => !code.approvedUnits) || [];
  const isPartiallyApproved = unapprovedCodes?.length > 0 && approvedCodes?.length > 0;
  let codesStr =
    (unitsOnservicePxCode && unitsOnservicePxCode?.length > 0) || (unitsOnPxCodes && unitsOnPxCodes?.length > 0)
      ? unitsOnservicePxCode?.length === 1 || unitsOnPxCodes?.length === 1
        ? " Code: "
        : " Codes: "
      : "";

  let containsUnitsOnPx = false;
  if (isOnPx && unitsOnPxCodes && unitsOnPxCodes?.length > 0) {
    codesStr += unitsOnPxCodes
      ?.map((code) => {
        const units = code.units || 0;
        let individualCodeStr = `${code.code} `;
        if (!code.approvedUnits || !code.units || awaitingTerminalStatus(authStatus)) {
          individualCodeStr += `(${getUnitsStr(units)} requested)`;
          containsUnitsOnPx = true;
        } else if (code.approvedUnits === code.units) {
          individualCodeStr += `(${getUnitsStr(units)} approved)`;
          containsUnitsOnPx = true;
        } else if (code.approvedUnits !== code.units) {
          individualCodeStr += `(${getUnitsStr(units)} requested, ${getUnitsStr(code.approvedUnits)} approved)`;
          containsUnitsOnPx = true;
        } else {
          individualCodeStr += `(${getUnitsStr(units)} requested)`;
          containsUnitsOnPx = true;
        }
        return individualCodeStr;
      })
      .join(", ");
  }

  if (awaitingTerminalStatus(authStatus) && !isOnPx) {
    codesStr +=
      `${containsUnitsOnPx ? "; " : ""}` + unitsOnservicePxCode?.map((code) => code.code).join(", ") + " requested ";
  } else {
    if (approvedCodes.length > 0) {
      codesStr += `${containsUnitsOnPx ? "; " : ""}` + approvedCodes.map((code) => code.code).join(", ") + " approved ";
    }
    codesStr += isPartiallyApproved ? ", " : "";
    if (unapprovedCodes.length > 0) {
      codesStr +=
        `${containsUnitsOnPx ? "; " : ""}` + unapprovedCodes.map((code) => code.code).join(", ") + " requested ";
    }
  }

  return codesStr;
};

function getUnitsStr(units: number) {
  return `${units} unit${units !== 1 ? "s" : ""}`;
}

export function awaitingTerminalStatus(authStatus: AuthStatus | undefined) {
  return authStatus === "RECOMMENDED_PARTIAL_APPROVAL" || authStatus === "RECOMMENDED_DENIAL";
}

export default getCodesInfo;
