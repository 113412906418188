import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import Container from "@material-ui/core/Container";
import { headerHeight } from "util/StyleConstants";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import HeaderContainer from "../../AppHeader/HeaderContainer";
import OrganizationListHeader from "./OrganizationListHeader";
import OrganizationSearchBar from "./OrganizationSearch";
import OrganizationTable from "./OrganizationTable";

import { useGetOrganizations, GetOrganizationsQueryParams } from "@coherehealth/core-platform-api";
import { H4, useMuiContainerStyles } from "@coherehealth/common";

export default function ManageManyProviderOrgs() {
  const containerClasses = useMuiContainerStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useState<GetOrganizationsQueryParams>({});
  const [debounce, setDebounce] = useState(0);

  const {
    loading,
    error,
    data: organizations,
    refetch: refetchOrganizations,
  } = useGetOrganizations({
    queryParams: { ...searchParams, max: 100 },
    queryParamStringifyOptions: { indices: false },
    debounce: debounce,
  });

  useEffect(() => {
    if (error) {
      enqueueSnackbar("Failed to get list of organizations, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [error, enqueueSnackbar]);

  return (
    <Container classes={containerClasses} maxWidth="lg">
      <HeaderContainer height={headerHeight}>
        <OrganizationListHeader onAddOrganization={refetchOrganizations} />
      </HeaderContainer>
      <MainContent>
        <SearchHeader data-public>Search for an organization</SearchHeader>
        <OrganizationSearchBar setSearchParams={setSearchParams} setDebounce={setDebounce} />
        {loading ? (
          <Grid container alignItems="center" justify="center">
            <CircularProgress />
          </Grid>
        ) : (
          <OrganizationTable organizations={organizations || []} />
        )}
      </MainContent>
    </Container>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const MainContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  overflowY: "hidden",
  backgroundColor: theme.palette.background.default,
  paddingTop: theme.spacing(5) + headerHeight,
  /* leave space for footer, (button top/bottom margin/padding + font height + extra spacing */
  paddingBottom: theme.spacing(8) + 20 + theme.spacing(5),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const SearchHeader = styled(H4)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
