import { useAuthorized, useBelongsToOpsGroup } from "authorization";
import { useGetIsUsingClinicalConversation } from "components/ServiceRequest/ReviewSection/util/ReviewSectionUtil";
import { getLatestDeterminedServiceRequest } from "util/authorization";
import { Alert, useConfiguration } from "@coherehealth/common";
import { useTheme } from "@material-ui/core";
import { StatusAlertT, getBannerDetails } from "./statusAlertUtils";
import { isBackOfficeUser, isInternalUser } from "util/user";
import { useGetUser } from "components/ClinicalReview/reviewUtils/utils";

export const StatusAlert = ({
  serviceRequest,
  authorization,
  useServiceRequestAuthStatus,
  children,
  authStatus,
  allowContinuations,
  canViewOutreachOpportunities,
  limitVoidsAfterApprovalFF,
  isAuthViewOnlyUser,
  action,
}: StatusAlertT) => {
  const theme = useTheme();
  const currentUser = useGetUser();
  const isInternalOrProviderCohereUser = currentUser && (isInternalUser(currentUser) || isBackOfficeUser(currentUser));
  const canEditOONReview = useAuthorized("EDIT_OUT_OF_NETWORK_REVIEW");
  const isCohereOpsGroup = useBelongsToOpsGroup("COHERE");
  const showClinicalConversation = useGetIsUsingClinicalConversation(serviceRequest);
  const latestDeterminedSr = authorization && getLatestDeterminedServiceRequest(authorization);
  const { pendingReason } = serviceRequest;
  const shouldHidePendingReason =
    authStatus === "PENDING" &&
    (pendingReason === "ADMIN_VOID" || pendingReason === "AUDIT_REVIEW") &&
    !canViewOutreachOpportunities;
  const requestStatusConfiguration =
    useConfiguration("requestStatusConfiguration", serviceRequest.healthPlanName) ?? {};
  const pendingWithDisplayHealthPlan = requestStatusConfiguration?.pendingWithDisplayHealthPlan;
  const bannerDetails = getBannerDetails(
    shouldHidePendingReason,
    limitVoidsAfterApprovalFF,
    canEditOONReview,
    allowContinuations,
    isAuthViewOnlyUser,
    showClinicalConversation,
    serviceRequest,
    latestDeterminedSr,
    isCohereOpsGroup,
    authorization,
    authStatus,
    isInternalOrProviderCohereUser,
    pendingWithDisplayHealthPlan
  );

  const { message, severity } = bannerDetails;
  return (
    <Alert
      style={{
        userSelect: "text",
        flex: 1,
        textAlign: "left",
        padding: theme.spacing(1, 2),
        borderRadius: `${theme.spacing(0.6)}px`,
        whiteSpace: "pre-line",
      }}
      data-testid={`service-request-status-display-${authorization?.authNumber}`}
      severity={severity}
      message={message}
      action={action}
      pendingWithDisplayHealthPlan={pendingWithDisplayHealthPlan}
    >
      {children}
    </Alert>
  );
};
