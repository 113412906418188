import React from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled, Theme } from "@material-ui/core/styles";
import { Body1, Caption } from "../Typography";
import { Card } from "../Card";
import { colorsDark, colorsLight } from "../../themes";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableCell from "./TableCell";

interface Column<T> {
  name: string;
  label?: string;
  width: string;
  minWidth?: string | number;
  themedPaddingRight: number;
  value: (arg: T) => React.ReactNode;
  unSortable?: boolean;
  header?: JSX.Element;
}

export interface HeaderProps<T> {
  tableColumns: Array<Column<T>>;
  sortingColumnName?: string;
  readonly?: boolean;
  sortingAscending?: boolean;
  onSortChange?: (columnName: string, sortAscending: boolean) => void;
  withDivider?: boolean;
  wide?: boolean;
  deleteSpacing?: boolean;
  authHistorySpacing?: boolean;
  headerMargin?: string;
  stickyHeader?: boolean;
  stickyHeaderStyle?: string;
  renderSelectAllCheckbox?: () => React.ReactNode;
}

export const stickyHeaderStyle = makeStyles<Theme, { top?: string | number; stickyHeaderBackgroundColor?: string }>(
  (theme) => ({
    sticky: ({ top, stickyHeaderBackgroundColor }) => ({
      position: "sticky",
      top: top ?? 0,
      background: stickyHeaderBackgroundColor,
      zIndex: 2,
    }),
  })
);

export default function DefaultHeader<T>({
  tableColumns,
  sortingColumnName,
  sortingAscending,
  onSortChange,
  readonly,
  withDivider,
  wide,
  deleteSpacing,
  authHistorySpacing,
  headerMargin,
  stickyHeader,
  stickyHeaderStyle,
  renderSelectAllCheckbox,
}: HeaderProps<T>) {
  return (
    <Header
      readonly={readonly}
      withDivider={withDivider}
      wide={wide}
      deleteSpacing={deleteSpacing}
      authHistorySpacing={authHistorySpacing}
      headerMargin={headerMargin}
      data-testid="default-header"
      className={stickyHeader ? stickyHeaderStyle : undefined}
      stickyHeader={stickyHeader}
    >
      {renderSelectAllCheckbox && (
        <TableCell width="48px" themedPaddingRight={0} className="checkbox-column">
          {renderSelectAllCheckbox()}
        </TableCell>
      )}
      {tableColumns.map((column, index) => (
        <TableCell
          width={column.width}
          themedPaddingRight={column.themedPaddingRight}
          minWidth={column.minWidth}
          key={index}
        >
          {!column.unSortable && !!onSortChange ? (
            <StyledTableSortLabel
              active={column.name === sortingColumnName}
              direction={sortingAscending ? "asc" : "desc"}
              onClick={() => {
                if (onSortChange) {
                  if (column.name === sortingColumnName) {
                    onSortChange(column.name, !sortingAscending);
                  } else {
                    onSortChange(column.name, !!sortingAscending);
                  }
                }
              }}
            >
              {column.header ? (
                column.header
              ) : readonly ? (
                <Caption>{column.label || column.name}</Caption>
              ) : (
                <Body1>{column.label || column.name}</Body1>
              )}
            </StyledTableSortLabel>
          ) : column.header ? (
            column.header
          ) : readonly ? (
            <Caption>{column.label || column.name}</Caption>
          ) : (
            <Body1>{column.label || column.name}</Body1>
          )}
        </TableCell>
      ))}
    </Header>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  "&:hover": {
    color: colorsLight.primary.light,
  },
  "&.MuiTableSortLabel-active": {
    "&:hover": {
      color: theme.palette.type === "dark" ? theme.palette.common.white : "inherit",
    },
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Header = styled(
  ({ readonly, withDivider, wide, deleteSpacing, authHistorySpacing, headerMargin, stickyHeader, ...other }) => (
    <Card {...other} />
  )
)<
  Theme,
  {
    readonly?: boolean;
    withDivider?: boolean;
    wide?: boolean;
    deleteSpacing?: boolean;
    authHistorySpacing?: boolean;
    headerMargin?: string;
    stickyHeader?: boolean;
  }
>(({ theme, readonly, withDivider, wide, deleteSpacing, authHistorySpacing, headerMargin, stickyHeader }) => ({
  color: readonly ? colorsDark.font.secondary : colorsDark.primary.main,
  padding: readonly ? 0 : deleteSpacing ? theme.spacing(0, 9, 0, wide ? 2 : 3) : theme.spacing(0, wide ? 2 : 3),
  textAlign: "left",
  border: "none",
  boxShadow: "none",
  borderBottom: stickyHeader ? `1px solid ${colorsDark.gray.divider}` : "none",
  borderRadius: stickyHeader ? 0 : undefined,
  marginBottom: !headerMargin
    ? theme.spacing(authHistorySpacing ? 1.5 : withDivider ? 1.5 : stickyHeader ? 0 : 3)
    : undefined,
  marginTop: !headerMargin ? theme.spacing(authHistorySpacing ? 1 : stickyHeader ? 0 : 4) : undefined,
  margin: headerMargin,
  background: "transparent",
  display: "flex",
}));
