import { useContext, useEffect, useState } from "react";
import {
  getEmptyUserClickTracking,
  HighlightProvider,
  HighlightStateContext,
  InformativeModal,
  useFeature,
  useParsedQueryString,
  UserClickInfoClinicalReviewPage,
} from "@coherehealth/common";
import {
  Props as LegacyWrapperProps,
  ClinicalReviewPageWrapper as LegacyClinicalReviewPageWrapper,
} from "components/ClinicalReview/reviewUtils/ReviewPageWrapper";
import { useClinicalReviewNext } from "./clinical-review-store";
import { useParams } from "react-router";
import { initCurrentReview, initQMCase, initServiceRequest, setLeftTabAction } from "./actions";
import { LeftPanelTabs } from "components/ClinicalReview/reviewUtils/utils";
import SlimHeader from "./SlimHeader";
import ClinicalReviewShell from "common/ClinicalReviewShell";
import { LeftReviewTabsNext } from "./LeftReviewTabsNext/LeftReviewTabsNext";
import { ReviewLeftPanelNext } from "./ReviewLeftPanelNext/ReviewLeftPanelNext";
import { useInitReview } from "components/ClinicalReview/reviewUtils/useInitCurrentReview";
import { CaseType } from "@coherehealth/qm-api";
import {
  CREATE_NURSE_REVIEW_PARAM,
  CREATE_OUT_OF_NETWORK_REVIEW_PARAM,
  CREATE_MD_REVIEW_PARAM,
  CREATE_P2P_REVIEW_PARAM,
  CREATE_AUDIT_REVIEW_PARAM,
} from "util/queryParams";
import { ReviewsProvider } from "components/ClinicalReview/reviewUtils/useReviews";
import { RightColumn } from "./ClinicalReviewPageRightColumn";
import { useSearchParams } from "react-router-dom";
import LoadingDialog from "components/ClinicalReview/LoadingDialog";
import ClipboardMissingInfo from "components/images/ClipboardMissingInfo";
import UnsolvableCaseModal from "components/DocumentViewer/FaxAttachment/UnworkableFax/UnsolvableCaseModal";
import LinkingModeBorder from "components/ClinicalReview/reviewUtils/LinkingModeBorder";
import useFetchAttachmentHighlights from "components/ClinicalReview/Review/hooks/useFetchAttachmentHighlights";
import { useSnackbar } from "notistack";
import { doesCaseMatchSrStatus } from "components/ClinicalReview/Review/ClinicalReviewPage";

export function ClinicalReviewPage({ viewOnly, reviewPageVersion }: LegacyWrapperProps) {
  // State from context
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state, dispatch, dispatchAsync } = useClinicalReviewNext();
  const {
    serviceRequest,
    existingReviews,
    loading: { serviceRequestLoading, existingReviewsLoading: loadingExistingReviews },
    errors: {
      serviceRequestFetchError,
      otherServiceRequestsFetchError,
      patientFetchError,
      isCoverageActiveFetchError,
      existingReviewsFetchError,
    },
    ruleResultsForClinicalReview,
    attachmentsProps: { attachments, openAttachmentWarningModal, setOpenAttachmentWarningModal, newAttachmentsNumber },
    currentCase,
    leftColumnTab,
  } = state;
  const { linking } = useContext(HighlightStateContext);

  //Local state
  const [openMemberCoverageExpiresModal, setOpenMemberCoverageExpiresModal] = useState<boolean>(false);
  const [assignmentErrorModalOpen, setAssignmentErrorModalOpen] = useState<boolean>(false);
  const [userClickInfoTracking, setUserClickInfoTracking] = useState<UserClickInfoClinicalReviewPage>(
    getEmptyUserClickTracking()
  );

  // Other hooks
  const { serviceRequestId } = useParams<{ serviceRequestId: string }>();
  const { caseId } = useParsedQueryString();
  const [search] = useSearchParams();
  const { isReviewEditMode, currentReview, loadingCurrentReview, openWarningModal, setOpenWarningModal } =
    useInitReview({
      serviceRequestId: serviceRequestId || "",
      existingReviews: existingReviews || [],
    });
  const isAuditReview = search.has(CREATE_AUDIT_REVIEW_PARAM);
  useFetchAttachmentHighlights(currentReview, attachments, isAuditReview);

  // Computed values
  const aModalIsOpen =
    openWarningModal ||
    openAttachmentWarningModal ||
    openMemberCoverageExpiresModal ||
    assignmentErrorModalOpen ||
    ruleResultsForClinicalReview?.showAutoApprovalModal;

  const caseType = determineCaseTypeFromSearchParams(search);

  // TODO: Add cancelCase API call
  const hasCorrectCurrentCaseType =
    currentCase && serviceRequest ? doesCaseMatchSrStatus(currentCase, serviceRequest) : true;
  const caseIsCancelled = currentCase && currentCase.caseStatus === "CANCELLED";
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const shouldCancelCase = caseId && !hasCorrectCurrentCaseType && !caseIsCancelled;

  // Dispatching actions
  const setLeftColumnTab = (tab: LeftPanelTabs) => dispatch(setLeftTabAction(tab));
  useEffect(() => {
    if (serviceRequestId) {
      dispatch(initServiceRequest(serviceRequestId));
    }
  }, [dispatch, serviceRequestId]);
  useEffect(() => {
    if (caseId) {
      dispatch(initQMCase(caseId as string, caseType));
    }
  }, [caseId, caseType, dispatch]);
  useEffect(() => {
    if (currentReview) {
      dispatch(initCurrentReview(currentReview, isReviewEditMode));
    }
  }, [currentReview, isReviewEditMode, dispatch]);

  // Error snackbars
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (existingReviewsFetchError) {
      enqueueSnackbar(`Failed to load reviews: ${existingReviewsFetchError.message}`, { variant: "error" });
    }
    if (serviceRequestFetchError) {
      enqueueSnackbar(`Failed to load service request: ${serviceRequestFetchError.message}`, { variant: "error" });
    }
    if (patientFetchError) {
      enqueueSnackbar(`Failed to load patient information: ${patientFetchError.message}`, { variant: "error" });
    }
    if (isCoverageActiveFetchError) {
      enqueueSnackbar(`Failed to load active coverage information: ${isCoverageActiveFetchError.message}`, {
        variant: "error",
      });
    }
    if (otherServiceRequestsFetchError) {
      enqueueSnackbar(`Failed to load patient's other service requests: ${otherServiceRequestsFetchError.message}`, {
        variant: "error",
      });
    }
    // if (updateCaseError && shouldCancelCase) {
    //   enqueueSnackbar(`Failed to update service case: ${updateCaseError.message}`, {
    //     variant: "error",
    //   });
    // }
  }, [
    enqueueSnackbar,
    existingReviewsFetchError,
    serviceRequestFetchError,
    patientFetchError,
    isCoverageActiveFetchError,
    otherServiceRequestsFetchError,
  ]);

  return (
    <>
      <ClinicalReviewShell
        attachmentView={leftColumnTab === "ATTACHMENTS"}
        header={<SlimHeader />}
        leftColumnTabs={<LeftReviewTabsNext aModalIsOpen={aModalIsOpen} />}
        leftColumn={
          <ReviewLeftPanelNext
            userClickInfoTracking={userClickInfoTracking}
            setUserClickInfoTracking={setUserClickInfoTracking}
          />
        }
        rightColumn={
          <RightColumn
            reviewPageVersion={reviewPageVersion}
            userClickInfoTracking={userClickInfoTracking}
            setUserClickInfoTracking={setUserClickInfoTracking}
          />
        }
      />

      {linking && <LinkingModeBorder />}

      <LoadingDialog
        open={loadingExistingReviews || serviceRequestLoading || loadingCurrentReview}
        headingText={`Loading your ${
          reviewPageVersion === "NewOutReachPage" || reviewPageVersion === "LogOutreachPage"
            ? "outreach page"
            : reviewPageVersion === "NewNotePage"
            ? "new note"
            : reviewPageVersion === "AppealNotePage"
            ? "post appeal page"
            : "clinical review"
        }`}
        subHeaderText={"This may take a few seconds"}
      />
      <InformativeModal
        open={openWarningModal}
        onClose={() => {
          setOpenWarningModal(false);
        }}
        headerText={"Review in progress"}
        additionalInfoText={`There is a review in progress for this SR started by ${
          currentReview?.createdByName || "unknown"
        }`}
        primaryButtonText={"Continue to review"}
        primaryButtonAction={() => {
          setOpenWarningModal(false);
        }}
      />
      <InformativeModal
        open={openAttachmentWarningModal && !openWarningModal}
        onClose={() => {
          setOpenAttachmentWarningModal(false);
        }}
        headerText={"New attachments uploaded"}
        additionalInfoText={`The submitter has uploaded ${newAttachmentsNumber} new attachments`}
        primaryButtonText={"View new attachments"}
        primaryButtonAction={() => {
          setLeftColumnTab("ATTACHMENTS");
          setOpenAttachmentWarningModal(false);
        }}
      />
      <InformativeModal
        icon={<ClipboardMissingInfo />}
        open={openMemberCoverageExpiresModal && !openWarningModal && !assignmentErrorModalOpen}
        onClose={() => {
          setOpenMemberCoverageExpiresModal(false);
        }}
        headerText={"This member’s coverage expires before the Date of Service"}
        additionalInfoText={`You may not be able to complete this ${
          reviewPageVersion === "NewOutReachPage" ? "outreach attempt" : "review"
        }`}
        primaryButtonText={`Continue to ${reviewPageVersion === "NewOutReachPage" ? "outreach attempt" : "review"}`}
        primaryButtonAction={() => {
          setOpenMemberCoverageExpiresModal(false);
        }}
      />
      <UnsolvableCaseModal
        open={assignmentErrorModalOpen}
        setOpen={() => setAssignmentErrorModalOpen(true)}
        assignmentErrorVariant
        caseType={caseType}
      />
    </>
  );
}

/* Wrapper component that renders legacy component when FF is off */
export function ClinicalReviewPageWrapper(props: LegacyWrapperProps) {
  const isEnabled = useFeature("clinicalReviewNext");
  const { state } = useClinicalReviewNext();
  const { user } = state;
  return isEnabled ? (
    <ReviewsProvider initialReviews={state?.existingReviews}>
      <HighlightProvider currentUserId={user?.sub}>
        <ClinicalReviewPage {...props} />
      </HighlightProvider>
    </ReviewsProvider>
  ) : (
    <LegacyClinicalReviewPageWrapper {...props} />
  );
}

// Utilities
const paramToCaseType: Record<string, CaseType> = {
  [CREATE_NURSE_REVIEW_PARAM]: "RN_REVIEW",
  [CREATE_OUT_OF_NETWORK_REVIEW_PARAM]: "OUT_OF_NETWORK_REVIEW",
  [CREATE_MD_REVIEW_PARAM]: "MD_REVIEW",
  [CREATE_P2P_REVIEW_PARAM]: "P2P_REVIEW",
};

const determineCaseTypeFromSearchParams = (searchParams?: URLSearchParams): CaseType | undefined => {
  for (const [param, type] of Object.entries(paramToCaseType)) {
    if (searchParams?.has(param)) {
      return type;
    }
  }
  return undefined;
};
