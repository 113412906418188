import { InformativeModal, queueMgmtBaseUrl } from "@coherehealth/common";
import { useCompleteServiceCase } from "@coherehealth/qm-api";
import { FaxAttachmentContext } from "components/DocumentViewer/FaxAttachment/FaxAttachmentContext";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { useGetReturnToUrl } from "util/queryParams";
import config from "api/config";
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import Footer from "../Footer";
import SubmissionModal, { SubmissionStatus } from "components/ServiceRequest/Submission/SubmissionModal";
import {
  AuthorizationCreatePayload,
  Requestor,
  ServiceRequestCreatePayload,
  ServiceRequestResponse,
  useBatchSubmitServiceRequests,
  useCreateAuthorization,
  useUpdateAuthorizationById,
  useCreateServiceRequestAttachment,
  AuthorizationResponse,
  BulkUpdateCandidateFeedbackRequestBody,
  useBulkUpdateCandidateFeedback,
} from "@coherehealth/core-platform-api";
import { ServiceRequestFormContent } from "components/ServiceRequest";
import { ServiceRequestFormStateSetters } from "../common";
import { payloadFromSRFormContent } from "util/serviceRequest";
import { useSnackbar } from "notistack";
import { useTheme } from "@material-ui/core";
import { compareSuggestionsToFinalSR } from "util/suggestionUtils";
import { SuggestionContext } from "../SuggestionContext";

interface Props extends ServiceRequestFormStateSetters {
  patientId: string;
  serviceRequestFormContents: ServiceRequestFormContent[];
  serviceRequestFormsCanBeSubmitted: boolean[];
  requestor?: Requestor;
  workflowId?: string;
  healthPlanName: string;
  setFooterHeight: Dispatch<SetStateAction<number>>;
}

//TODO: clean up by June 1, 2023 -- COH - 3422
export default function FillFormsFaxFooter({
  patientId,
  workflowId,
  serviceRequestFormContents,
  serviceRequestFormsCanBeSubmitted,
  requestor,
  healthPlanName,
  setFooterHeight,
}: Props) {
  const { mutate: submitServiceRequests, loading: submitServiceRequestLoading } = useBatchSubmitServiceRequests({});
  const [submitError, setSubmitError] = useState(false);
  const canSubmitServiceRequestForms = !serviceRequestFormsCanBeSubmitted.includes(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const { palette } = useTheme();
  const {
    mutate: createNewServiceRequestOnAuth,
    loading: createNewServiceRequestOnAuthLoading,
    error: createNewServiceRequestOnAuthError,
  } = useCreateAuthorization({});
  const {
    mutate: updateAuthorization,
    loading: updateAuthorizationLoading,
    error: updateAuthorizationError,
  } = useUpdateAuthorizationById({ id: "" });
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: uploadSrAttachment,
    loading: uploadAttachmentLoading,
    error: uploadAttachmentError,
  } = useCreateServiceRequestAttachment({
    id: "",
    requestOptions: { headers: { Accept: "application/json" } },
  });

  const [showSubmissionModal, setShowSubmissionModal] = useState(false);
  const [serviceRequests, setServiceRequests] = useState<ServiceRequestResponse[]>([]);
  const [authorizationId, setAuthorizationId] = useState<string>();
  const [submissionComplete, setSubmissionComplete] = useState(false);
  const { faxDbId: defaultFaxId, caseId: qmCaseId } = useContext(FaxAttachmentContext);

  useEffect(() => {
    if (createNewServiceRequestOnAuthError || updateAuthorizationError || uploadAttachmentError) {
      enqueueSnackbar("Failed to create service request, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
    if (uploadAttachmentError) {
      const errorMessage =
        typeof uploadAttachmentError.data === "object"
          ? uploadAttachmentError.data.message || uploadAttachmentError.message
          : uploadAttachmentError.message;
      enqueueSnackbar(`Failed to create service request attachment: ${errorMessage}`, {
        variant: "error",
      });
    }
  }, [createNewServiceRequestOnAuthError, enqueueSnackbar, updateAuthorizationError, uploadAttachmentError]);

  useEffect(() => {
    if (submitError) {
      enqueueSnackbar("Failed to submit service requests", { variant: "error" });
    }
  }, [submitError, enqueueSnackbar]);

  const { mutate: batchUpdateCandidateFeedback } = useBulkUpdateCandidateFeedback({});
  const { suggestedRequestor, suggestedPriorAuthRequirements, suggestedFormContent } = useContext(SuggestionContext);

  const attemptedSubmitServices =
    createNewServiceRequestOnAuthLoading ||
    updateAuthorizationLoading ||
    uploadAttachmentLoading ||
    submitServiceRequestLoading;

  let submissionStatus: SubmissionStatus;
  if (attemptedSubmitServices) {
    submissionStatus = SubmissionStatus.Loading;
  } else if (submitError || createNewServiceRequestOnAuthError || uploadAttachmentError || updateAuthorizationError) {
    submissionStatus = SubmissionStatus.Failure;
  } else {
    submissionStatus = SubmissionStatus.Success;
  }

  const { mutate: updateServiceCase } = useCompleteServiceCase({
    id: qmCaseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
    onMutate: () => {
      window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${qmCaseId}`);
    },
  });
  const onSubmitFaxServices = async () => {
    setShowSubmissionModal(true);
    let submissionPayload: ServiceRequestResponse[] = [];
    // Create drafts first
    let serviceRequestPayloads: ServiceRequestCreatePayload[] = [];
    serviceRequestFormContents.forEach((sr) => {
      const payload: ServiceRequestCreatePayload = {
        ...payloadFromSRFormContent(sr, true),
        patient: patientId,
        workflowId: workflowId,
        authorizationType: sr?.authorizationType,
        workflowStep: "FILL_FORMS_FAX",
        requestor: { channel: "FAX" },
        requestType: "INITIAL",
      };
      serviceRequestPayloads.push(payload);
    });

    const authorizationPayload: AuthorizationCreatePayload = {
      patient: patientId,
      healthPlanName,
      type: serviceRequestFormContents[0]?.authorizationType,
      userSelectedOONException: serviceRequestFormContents[0]?.userSelectedOONException || false,
      serviceRequests: [...serviceRequestPayloads],
    };
    let response: AuthorizationResponse = {};
    if (!authorizationId) {
      response = await createNewServiceRequestOnAuth(authorizationPayload);
    } else {
      response = await updateAuthorization({ ...authorizationPayload }, { pathParams: { id: authorizationId } });
    }
    setAuthorizationId(response?.id);
    setServiceRequests(response?.serviceRequestsOnAuth || []);
    if (response?.serviceRequestsOnAuth?.length && response?.serviceRequestsOnAuth[0].id && defaultFaxId) {
      const formData = new FormData();
      formData.set("faxId", defaultFaxId);
      // create a ServiceRequestAttachment copy of the FaxAttachment, which will hold onto the fields needed to link SR to case info
      await uploadSrAttachment(formData as unknown as void, {
        pathParams: { id: response?.serviceRequestsOnAuth[0]?.id || "" },
      });
      submissionPayload = response.serviceRequestsOnAuth;

      const batchUpdateCandidateFeedbackRequestPayload: BulkUpdateCandidateFeedbackRequestBody[] = [];
      submissionPayload.forEach((sr) => {
        const feedbackRequestBody: BulkUpdateCandidateFeedbackRequestBody = compareSuggestionsToFinalSR(
          sr,
          requestor ? requestor : {},
          suggestedRequestor,
          suggestedPriorAuthRequirements,
          suggestedFormContent
        );
        batchUpdateCandidateFeedbackRequestPayload.push(feedbackRequestBody);
      });
      batchUpdateCandidateFeedbackRequestPayload.forEach(
        async (requestPayload) => await batchUpdateCandidateFeedback(requestPayload)
      );
      await submitServices(submissionPayload);
      setSubmissionComplete(true);
    } else {
      setSubmitError(true);
      enqueueSnackbar("Failed to submit service requests", { variant: "error" });
    }
  };

  const submitServices = async (serviceRequests: ServiceRequestResponse[]) => {
    let submissionError = false;
    try {
      await setRequestorAndSubmit(serviceRequests);
    } catch (e) {
      try {
        await setRequestorAndSubmit(serviceRequests);
      } catch (e) {
        submissionError = true;
      }
    }
    setSubmitError(submissionError);
  };

  const setRequestorAndSubmit = async (serviceRequests: ServiceRequestResponse[]) => {
    const serviceRequestsToSubmit = serviceRequests.map((sr): ServiceRequestResponse => {
      return {
        ...sr,
        requestor,
      };
    });
    const response = await submitServiceRequests(serviceRequestsToSubmit);
    setServiceRequests(response);
  };

  const backToQueueManagement = useCallback(() => {
    if (submissionComplete) {
      const outcome = serviceRequests.every((sr) => sr.requestType === "CONTINUATION")
        ? "NEW_CONTINUATION_SR"
        : "NEW_SR";
      const description =
        outcome === "NEW_CONTINUATION_SR" ? "New continuation request created" : "New request created";
      updateServiceCase({
        outcome: outcome,
        dateCompleted: new Date().toISOString(),
        description: description,
        serviceRequests: serviceRequests.map((sr) => {
          return {
            serviceRequestId: sr?.id,
            status: sr?.authStatus,
            cohereId: sr?.cohereId,
          };
        }),
      });
    }
  }, [serviceRequests, submissionComplete, updateServiceCase]);

  const returnToUrl = useGetReturnToUrl();

  return (
    <>
      <Footer
        onPrimaryButtonClick={onSubmitFaxServices}
        onSecondaryButtonClick={() => {
          setCancelModalOpen(true);
        }}
        primaryButtonDisabled={
          !canSubmitServiceRequestForms ||
          createNewServiceRequestOnAuthLoading ||
          updateAuthorizationLoading ||
          uploadAttachmentLoading ||
          submitServiceRequestLoading
        }
        primaryButtonLoading={
          createNewServiceRequestOnAuthLoading ||
          uploadAttachmentLoading ||
          submitServiceRequestLoading ||
          updateAuthorizationLoading
        }
        primaryButtonText={"Submit"}
        secondaryButtonText={"Cancel"}
        errorCaptionText={"There are some errors with the details above."}
        showError={false}
        showSaveAndExitButton={false}
        showSecondaryButton={true}
        setFooterHeight={setFooterHeight}
      />
      <SubmissionModal
        open={showSubmissionModal}
        handleClose={() => setShowSubmissionModal(false)}
        onSuccessAction={backToQueueManagement}
        status={submissionStatus}
        onFailureAction={onSubmitFaxServices}
      />
      <InformativeModal
        open={cancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        icon={<HelpOutlineIcon style={{ color: palette.warning.dark }} />}
        headerText="Are you sure you want to cancel this authorization?"
        subHeaderText="Your progress will not be saved."
        primaryButtonText="Yes, cancel"
        primaryButtonRoute={returnToUrl}
        tertiaryButtonText="Continue with authorization"
        tertiaryButtonAction={() => setCancelModalOpen(false)}
      />
    </>
  );
}
