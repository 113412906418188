import { ProcedureCode, SemanticCode } from "@coherehealth/core-platform-api";

export interface ProcedureCodeWithId extends ProcedureCode {
  id: string;
}
export interface SemanticCodeWithCode extends SemanticCode {
  code: string;
}
export interface ProcedureCodeWithAggregate extends ProcedureCode {
  id: string;
  authUnits?: number;
  authApprovedUnits?: number;
}
export const withId = (px: ProcedureCode): ProcedureCodeWithId => ({ ...px, id: px.id || "" });
export const withCode = (sc: SemanticCode): SemanticCodeWithCode => ({ ...sc, code: sc.code || "" });
export const withDefaultUnits = (px: ProcedureCode): ProcedureCode => ({ ...px, units: px.units ?? 1 });
export const withAggregate = (px: ProcedureCode, authorizationPxs?: ProcedureCode[]): ProcedureCodeWithAggregate => ({
  ...px,
  id: px.id || `${px.groupId ? px.groupId : "uncategorized"}${px.code}`,
  authUnits: authorizationPxs?.find((authPx) => authPx.code === px.code)?.units,
  authApprovedUnits: authorizationPxs?.find((authPx) => authPx.code === px.code)?.approvedUnits,
});

export const onCloseBackDrop = (callback: () => void) => {
  return (event: Event, reason: string) => {
    if (event.type !== "mouseup") {
      callback();
    }
  };
};

export interface NonPalPxCodesWithGroup {
  group: string;
  nonPalCodes: ProcedureCodeWithId[];
}
