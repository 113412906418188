import { SVGProps } from "react";

export default function PatientSummaryIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
      <path
        d="M20.2734 40.2734C31.3191 40.2734 40.2734 31.3191 40.2734 20.2734C40.2734 9.22774 31.3191 0.273437 20.2734 0.273437C9.22774 0.273437 0.273438 9.22774 0.273438 20.2734C0.273438 31.3191 9.22774 40.2734 20.2734 40.2734Z"
        fill="#F4F4F5"
      />
      <svg x="10" y="6" xmlns="http://www.w3.org/2000/svg" width="21" height="29" viewBox="0 0 21 29" fill="none">
        <path d="M20.0481 5.40527L0.53125 5.40527L0.53125 28.1682L20.0481 28.1682L20.0481 5.40527Z" fill="#B1B4B9" />
        <path
          d="M15.4475 6.78467L1.91406 6.78467L1.91406 26.786L18.6612 26.786L18.6612 10.0065L15.4475 6.78467Z"
          fill="white"
        />
        <path
          d="M14.7098 2.58646L12.1097 2.58646C12.1097 1.43983 11.2942 0.51123 10.2848 0.51123C9.27545 0.51123 8.4599 1.43983 8.4599 2.58646L5.85981 2.58646L5.125 7.18911L15.4365 7.18911L14.7098 2.58646Z"
          fill="#424954"
        />
        <path
          d="M7.03135 9.95967H5.64248V8.5708L4.68965 8.5708L4.68965 9.95967H3.30078L3.30078 10.9125H4.68965L4.68965 12.3014H5.64248L5.64248 10.9125L7.03135 10.9125L7.03135 9.95967Z"
          fill="#EA1D63"
        />
        <path d="M14.6299 13.8437L5.94141 13.8438V14.4978L14.6299 14.4978V13.8437Z" fill="#039EC3" />
        <path d="M17.0539 24.3149H13.5898V24.969H17.0539V24.3149Z" fill="#039EC3" />
        <path d="M17.0529 16.2407L3.51953 16.2407L3.51953 16.8948L17.0529 16.8948V16.2407Z" fill="#B1B4B9" />
        <path d="M17.0529 18.2026L3.51953 18.2026L3.51953 18.8567L17.0529 18.8567V18.2026Z" fill="#B1B4B9" />
        <path d="M17.0529 20.167L3.51953 20.167L3.51953 20.8211L17.0529 20.8211V20.167Z" fill="#B1B4B9" />
        <path d="M9.56757 22.1274L3.51953 22.1274L3.51953 22.7815L9.56757 22.7815V22.1274Z" fill="#B1B4B9" />
        <path d="M15.4453 10.0065L18.6591 10.0065L15.4453 6.78467L15.4453 10.0065Z" fill="#F4F4F5" />
      </svg>
    </svg>
  );
}
