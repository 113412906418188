import React, { Dispatch, SetStateAction } from "react";
import { SecondarySingleSelectDropdown } from "@coherehealth/common";
import isMatch from "lodash/isMatch";
import { ReferralRequestSearchRequestBody } from "@coherehealth/core-platform-api";

interface Props {
  queryParams: ReferralRequestSearchRequestBody;
  setQueryParams: Dispatch<SetStateAction<ReferralRequestSearchRequestBody>>;
}

// TODO: implement
const sortByOptions: Record<string, ReferralRequestSearchRequestBody> = {
  "Most recent": { sort: "lastUpdated:desc" },
  "Dates valid": { sort: "startDate:desc" },
};

const ReferralRequestSort = ({ queryParams, setQueryParams }: Props) => {
  const sortByOptionLabels = Object.keys(sortByOptions);

  const value =
    sortByOptionLabels.find((optionLabel) => isMatch(queryParams, sortByOptions[optionLabel])) || "Most recent";

  return (
    <SecondarySingleSelectDropdown
      options={sortByOptionLabels.map((optionLabel) => ({ id: optionLabel, label: optionLabel }))}
      label="Sort by:"
      value={value}
      isDashboardSortComponent
      onChange={(id) => {
        const { sort, ...restOfQueryParams } = queryParams;
        setQueryParams({
          ...restOfQueryParams,
          ...sortByOptions[id],
        });
      }}
    />
  );
};

export default ReferralRequestSort;
