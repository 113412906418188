import { createTheme, Theme } from "@mui/material";
import merge from "lodash/merge";
import cohereBaseTheme from "./cohereBaseTheme";

const cohereLightTheme: Theme = createTheme(
  merge({}, cohereBaseTheme, {
    palette: {
      text: {
        primary: "#212936",
        secondary: "#5E6572",
        tertiary: "#6F7580",
        disabled: "rgba(255, 255, 255, 0.6)",
      },
      background: {
        default: "#F8FBFC",
        paper: "#FFFFFF",
      },
      gray: {
        dark: "#C2C2C2",
        light: "rgba(0, 0, 0, 0.04)",
        divider: "#E5E5E5",
        inactive: "#E0E0E0",
      },
      switch: {
        trackBackground: "#BBDEFB",
      },
    },
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            padding: "24px", //was unable to interpret 'theme' to do theme.spacing(x) - figure out why
            boxShadow: "0px 2px 2px rgba(207, 226, 231, 0.8)",
            border: "1px solid transparent",
          },
          // TO-DO: implement hover styles
        },
      },
    },
  })
);

export default cohereLightTheme;
