import { colorsLight } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core";

export const usePatientSearchHeadlineStyles = makeStyles((theme) => ({
  patientDetails: {
    color: colorsLight.font.secondary,
    paddingTop: theme.spacing(1),
  },
  deprecatedPatientSummaryLink: {
    ...theme.typography.body1,
    textDecoration: "none",
    color: theme.palette.primary.main,
    "&:visited": {
      color: theme.palette.primary.main,
    },
  },
  patientSummaryLink: {
    ...theme.typography.body1,
    textDecoration: "none",
    color: theme.palette.primary.main,
    "&:visited": {
      color: theme.palette.primary.main,
    },
    fontFamily: "Gilroy-SemiBold",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  deprecatedSecondaryTextContainer: {
    paddingTop: theme.spacing(0.5),
  },
  secondaryTextContainer: {
    textAlign: "right",
  },
}));
