import { SVGProps } from "react";

export default function BlankDashboard(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="200" height="200" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="1">
        <path
          d="M150 298.242C231.872 298.242 298.242 231.872 298.242 150C298.242 68.1281 231.872 1.75781 150 1.75781C68.1281 1.75781 1.75781 68.1281 1.75781 150C1.75781 231.872 68.1281 298.242 150 298.242Z"
          fill="black"
          opacity="0.04"
        />
        <path d="M220.84 78.2227H79.2188V243.398H220.84V78.2227Z" fill="#ACB3BA" />
        <path d="M187.441 88.2422H89.2383V233.379H210.762V111.621L187.441 88.2422Z" fill="white" />
        <path
          d="M182.109 57.7734H163.242C163.242 49.4531 157.324 42.7148 150 42.7148C142.676 42.7148 136.758 49.4531 136.758 57.7734H117.891L112.559 91.1719H187.383L182.109 57.7734Z"
          fill="#384358"
        />
        <path
          d="M126.387 111.27H116.309V101.191H109.395V111.27H99.3164V118.184H109.395V128.262H116.309V118.184H126.387V111.27Z"
          fill="#CF1655"
        />
        <path d="M181.523 139.453H118.477V144.199H181.523V139.453Z" fill="#89D3DD" />
        <path d="M199.102 215.449H173.965V220.195H199.102V215.449Z" fill="#89D3DD" />
        <path d="M199.102 156.855H100.898V161.602H199.102V156.855Z" fill="black" opacity="0.24" />
        <path d="M199.102 171.094H100.898V175.84H199.102V171.094Z" fill="black" opacity="0.24" />
        <path d="M199.102 185.332H100.898V190.078H199.102V185.332Z" fill="black" opacity="0.24" />
        <path d="M144.785 199.57H100.898V204.316H144.785V199.57Z" fill="black" opacity="0.24" />
        <path d="M187.441 111.621H210.762L187.441 88.2422V111.621Z" fill="#E6E9EE" />
      </g>
    </svg>
  );
}
