import React, { ReactNode } from "react";

import { LanguagePreference, ServiceRequestSearchResponse, VatNotification } from "@coherehealth/core-platform-api";
import CallLogPrintoutPdfDocument from "./CallLogPrintoutPdfDocument";
import { BlobProvider } from "@react-pdf/renderer";
import useMemoDeepCompare from "util/useDeepMemo";

interface Error {
  name: string;
  message: string;
}

export interface PrintoutParams {
  error: Error | null;
  url: string | null;
  loading: boolean;
}

interface Props {
  serviceRequest?: ServiceRequestSearchResponse;
  vatNotification?: VatNotification | null;
  callDispositionMessage?: string;
  languagePreference?: LanguagePreference;
  children: (params: PrintoutParams) => ReactNode;
}

/**
 * Given a serviceRequest, this provider creates a details printout for it and provides a url to
 * that resource. Right now this resource will be a pdf file (rendered by @react-pdf/renderer),
 * although theoretically we could provide other resource types if we have a renderer for that.
 *
 * @param serviceRequest The service request to convert to a print-out
 * @param children A consumer of that printout
 */
export default function CallLogLetter({
  serviceRequest,
  vatNotification,
  callDispositionMessage,
  languagePreference,
  children,
}: Props) {
  if (!serviceRequest || !vatNotification) {
    return null;
  }

  const Memoed = () => {
    return useMemoDeepCompare(
      () => (
        <BlobProvider
          key={Math.random()}
          document={
            <CallLogPrintoutPdfDocument
              serviceRequest={serviceRequest}
              vatNotification={vatNotification}
              callDispositionMessage={callDispositionMessage}
              languagePreference={languagePreference}
            />
          }
        >
          {children}
        </BlobProvider>
      ),
      [children, serviceRequest]
    );
  };

  return <Memoed />;
}
