import { useState, useEffect, useCallback } from "react";

function useIsOverflow(textElementRef: React.MutableRefObject<HTMLInputElement | null>) {
  const [hoverStatus, setHover] = useState<boolean>(false);

  const compareSize = useCallback(() => {
    const element = textElementRef.current;
    const compare = element
      ? element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight
      : false;
    setHover(compare);
  }, [textElementRef]);

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
  }, [compareSize]);

  // remove resize listener again on "componentWillUnmount"
  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize);
    },
    [compareSize]
  );

  return {
    hoverStatus,
  };
}

export default useIsOverflow;
