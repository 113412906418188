import React, { FunctionComponent, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useRenameServiceRequestCollection } from "@coherehealth/core-platform-api";
import Grid from "@material-ui/core/Grid";
import { PrimaryButton, Modal, Body1, InlineButton } from "@coherehealth/common";

const DropServiceRequestComponent: FunctionComponent = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: remove, loading, error } = useRenameServiceRequestCollection({});
  useEffect(() => {
    if (error) {
      enqueueSnackbar(`Error updating: ${error.message}`, { variant: "error" });
    }
  }, [error, enqueueSnackbar]);

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    await remove();
    setOpen(false);
  };
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <PrimaryButton onClick={() => setOpen(true)} loading={loading}>
          Drop service request collection
        </PrimaryButton>
      </Grid>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Body1>Are you sure you want to drop the service request collection?</Body1>
          </Grid>
          <Grid item xs={6}>
            <InlineButton onClick={onSubmit}>Yes</InlineButton>
          </Grid>
          <Grid item xs={6}>
            <InlineButton
              onClick={() => {
                setOpen(false);
              }}
            >
              No
            </InlineButton>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
};

export default DropServiceRequestComponent;
