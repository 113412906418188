import React, { ReactNode, useEffect, useState } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import {
  HealthHelpPASRequestResponse,
  useGetServiceRequestAttachments,
  useGetSingleServiceClinicalAssessment,
  useUpdateServiceRequest,
  Requestor,
} from "@coherehealth/core-platform-api";
import { ClinicalAssessmentContext, useClinicalAssessmentReducer } from "components/ClinicalAssessment";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Body1,
  useMuiContainerStyles,
  HEALTH_HELP_NAME,
  useGetServiceRequestByIdWithFallback,
} from "@coherehealth/common";
import WithdrawAndReplaceHeader from "./WithdrawAndReplaceHeader";
import Container from "@material-ui/core/Container";
import VendorAssessmentContainer from "components/AuthBuilder/VendorClinicalAssessment/VendorAssessmentContainer";
import CohereClinicalAssessmentStep from "./CohereClinicalAssessmentStep";
import ReviewStep from "./ReviewStep";
import WithdrawAndReplaceFooter from "./WithdrawAndReplaceFooter";
import { headerHeight } from "util/StyleConstants";
import { incompleteQuestions } from "util/clinicalAssessment";
import WithdrawAndReplaceEditDetailsStep from "./WithdrawAndReplaceEditDetailsStep";
import { AddAttachmentsCard } from "components/AddAttachments";
import { Grid } from "@material-ui/core";
import RequestorCard from "components/Requestor/RequestorCard";
import { useAuthorized } from "authorization";
import { formContentFromResponse, useIsDelegatedToVendor } from "util/serviceRequest";
import {
  ServiceRequestFormContent,
  ServiceRequestFormContentInitialization,
} from "common/SharedServiceRequestFormComponents";
import { ClinicalAssessmentProvider } from "components/AuthBuilder";
import { ClinicalAssessmentContextProviderProps } from "components/ClinicalAssessment/ClinicalAssessmentContext";
import { removeEmptyAttachments } from "util/attachmentUtil";
import { error as logError } from "logger";

export type WithdrawAndReplaceStep =
  | "EDIT_DETAILS"
  | "CLINICAL_ASSESSMENT"
  | "ADD_ATTACHMENT"
  | "REVIEW"
  | "SKIP_TO_REVIEW";

interface Props {
  serviceRequestId: string;
}
export interface StepDisplay {
  ordinal: number;
  label: ReactNode;
  backButtonLabel: ReactNode;
  nextButtonLabel: ReactNode;
}

function STEPS(): Record<WithdrawAndReplaceStep, StepDisplay> {
  return {
    EDIT_DETAILS: {
      ordinal: 1,
      label: "Edit details",
      backButtonLabel: "Back to patient summary",
      nextButtonLabel: "Continue to add attachments",
    },
    CLINICAL_ASSESSMENT: {
      ordinal: 3,
      label: "Clinical assessment",
      backButtonLabel: "Back to add attachments",
      nextButtonLabel: "Continue to review",
    },
    ADD_ATTACHMENT: {
      ordinal: 2,
      label: "Add attachments",
      backButtonLabel: "Back to edit details",
      nextButtonLabel: "Continue to clinical assessment",
    },
    REVIEW: {
      ordinal: 4,
      label: "Review services before submitting",
      backButtonLabel: "Back to clinical assessment",
      nextButtonLabel: "Submit services",
    },
    SKIP_TO_REVIEW: {
      ordinal: 1,
      label: "Review services before submitting",
      backButtonLabel: "Back to patient summary",
      nextButtonLabel: "Submit services",
    },
  };
}

const useStyles = makeStyles({
  loadingSpinner: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "30%",
  },
});

export default function WithdrawAndReplacePageContents({
  serviceRequestId,
  ...props
}: Props & ClinicalAssessmentContextProviderProps) {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const {
    data: serviceRequest,
    error: getServiceRequestError,
    loading: loadingServiceRequest,
    refetch: serviceRequestsRefetch,
  } = useGetServiceRequestByIdWithFallback({
    id: serviceRequestId,
  });

  const {
    data: clinicalAssessmentResponse,
    error: getClinicalAssessmentError,
    loading: loadingClinicalAssessment,
    refetch: refetchClinicalAssessment,
  } = useGetSingleServiceClinicalAssessment({
    id: serviceRequestId,
  });

  const {
    data: serviceRequestAttachments,
    error: getAttachmentsError,
    refetch: refetchAttachments,
  } = useGetServiceRequestAttachments({
    id: serviceRequestId,
  });

  const {
    mutate: patchServiceRequest,
    error: submitServiceRequestError,
    loading: submittingServiceRequest,
  } = useUpdateServiceRequest({
    id: serviceRequestId,
  });

  useEffect(() => {
    if (getClinicalAssessmentError) {
      enqueueSnackbar(`Failed to load clinical assessment: ${getClinicalAssessmentError.message}`, {
        variant: "error",
      });
    }
    if (getAttachmentsError) {
      enqueueSnackbar(`Failed to load attachments: ${getAttachmentsError.message}`, {
        variant: "error",
      });
    }
    if (submitServiceRequestError) {
      enqueueSnackbar(`Failed to submit service request: ${submitServiceRequestError.message}`, {
        variant: "error",
      });
    }
    if (getServiceRequestError) {
      enqueueSnackbar(`Failed to load service request: ${getServiceRequestError.message}`, {
        variant: "error",
      });
    }
  }, [
    getClinicalAssessmentError,
    getAttachmentsError,
    enqueueSnackbar,
    submitServiceRequestError,
    getServiceRequestError,
  ]);

  const containerClasses = useMuiContainerStyles();

  const [currentStep, setCurrentStep] = useState<WithdrawAndReplaceStep>("EDIT_DETAILS");
  const [hasPrefilledAnswers, setHasPrefilledAnswers] = useState<boolean | null>(null);
  const [attemptedSubmitService, setAttemptedSubmitService] = useState<boolean>(true);
  const [serviceRequestCanBeSubmitted, setServiceRequestCanBeSubmitted] = useState(false);
  const [attachmentsReady, setAttachmentsReady] = useState<boolean>(true);
  const [initialPageLoad, setInitialPageLoad] = useState(true);
  const [vendorAssessmentError, setVendorAssessmentError] = useState(false);

  const requestorFormAuthorized = useAuthorized("REQUESTOR_FORM");

  const [formContent, setFormContent] = useState<ServiceRequestFormContent>({
    ...ServiceRequestFormContentInitialization,
  });
  //default this value to true when component is rendered so that the footer interprets first page load as a change to the SR
  const [serviceRequestEdited, setServiceRequestEdited] = useState(true);

  const isDelegatedToHealthHelp = useIsDelegatedToVendor(serviceRequest, [HEALTH_HELP_NAME]);

  const [subQuestions, updateSubQuestions] = useState(props.initialSubQuestions);

  useEffect(() => {
    if (serviceRequest) {
      setFormContent(formContentFromResponse(serviceRequest));
    }
  }, [setFormContent, serviceRequest]);

  const [clinicalAssessmentProvider, setClinicalAssessmentProvider] = useState<ClinicalAssessmentProvider>();
  const [clinicalAssessment, updateClinicalAssessment] = useClinicalAssessmentReducer({
    initialClinicalAssessment: clinicalAssessmentResponse || undefined,
  });
  const [vendorQuestionnaireComplete, setVendorQuestionnaireComplete] = useState(false);
  const [healthHelpPASResponse, setHealthHelpPASResponse] = useState<HealthHelpPASRequestResponse>();

  useEffect(() => {
    if (clinicalAssessmentResponse && clinicalAssessmentProvider === "Cohere") {
      updateClinicalAssessment({ type: "populate", clinicalAssessment: clinicalAssessmentResponse });
      if (hasPrefilledAnswers === null) {
        setHasPrefilledAnswers(
          incompleteQuestions(clinicalAssessmentResponse).length !== clinicalAssessmentResponse?.questions?.length &&
            clinicalAssessmentResponse?.questions?.length !== 0
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicalAssessmentResponse, clinicalAssessmentProvider]);

  useEffect(() => {
    if (serviceRequest) {
      setRequestor(serviceRequest?.requestor);
      if (initialPageLoad) {
        setInitialPageLoad(false);
        if (isDelegatedToHealthHelp) {
          setCurrentStep("EDIT_DETAILS");
        } else if (serviceRequest?.clinicalService) {
          setCurrentStep("SKIP_TO_REVIEW");
        }
      }
    }
    if (initialPageLoad && serviceRequest) {
      setInitialPageLoad(false);
    }
  }, [initialPageLoad, serviceRequest, isDelegatedToHealthHelp]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const [requestor, setRequestor] = useState<Requestor>();

  if (serviceRequest === null || !formContent) {
    return (
      <>
        {loadingServiceRequest ? (
          <CircularProgress className={classes.loadingSpinner} size={40} />
        ) : (
          <Body1 color="error">There was an issue displaying this service request</Body1>
        )}
      </>
    );
  }

  const stepConfig = STEPS()[currentStep];
  if (!stepConfig) {
    logError("Undefined workflow step");
    return null;
  }

  const refetchData = async () => {
    await serviceRequestsRefetch();
    await refetchClinicalAssessment();
    await refetchAttachments();
  };

  return (
    <ClinicalAssessmentContext.Provider
      value={{ clinicalAssessment, updateClinicalAssessment, subQuestions, updateSubQuestions }}
    >
      <WithdrawAndReplaceHeader
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        serviceRequest={serviceRequest}
        stepConfig={stepConfig}
        refetchClinicalAssessment={clinicalAssessmentProvider === "Cohere" ? refetchClinicalAssessment : undefined}
        refetchAttachments={refetchAttachments}
      />
      <PageWrapper>
        <Container classes={containerClasses} maxWidth="lg">
          {requestorFormAuthorized && (
            <Grid container justify="center" spacing={4}>
              <Grid item xs={12}>
                <RequestorCard requestor={requestor} setRequestor={setRequestor} attemptedSubmitServices={undefined} />
              </Grid>
            </Grid>
          )}
          {currentStep === "EDIT_DETAILS" && (
            <WithdrawAndReplaceEditDetailsStep
              formContent={formContent}
              setFormContent={setFormContent}
              authStatus={serviceRequest?.authStatus}
              patient={serviceRequest?.patient}
              setCanBeSubmitted={setServiceRequestCanBeSubmitted}
              attemptedSubmitService={attemptedSubmitService}
              setServiceRequestEdited={setServiceRequestEdited}
            />
          )}
          {currentStep === "CLINICAL_ASSESSMENT" &&
            (loadingClinicalAssessment ? (
              <CircularProgress className={classes.loadingSpinner} size={40} />
            ) : (
              <>
                {clinicalAssessmentProvider !== "Cohere" ? (
                  <VendorAssessmentContainer
                    delegatedVendorName={clinicalAssessmentProvider}
                    cohereId={serviceRequest?.cohereId}
                    healthHelpPASRequestResponse={healthHelpPASResponse}
                    setWorkflowStep={() => setCurrentStep("REVIEW")}
                    questionnaireComplete={vendorQuestionnaireComplete}
                    setQuestionnaireComplete={setVendorQuestionnaireComplete}
                    serviceRequestId={serviceRequestId}
                    vendorAssessmentError={vendorAssessmentError}
                    setVendorAssessmentError={setVendorAssessmentError}
                  />
                ) : (
                  <CohereClinicalAssessmentStep displayPrefilledCard={hasPrefilledAnswers} />
                )}
              </>
            ))}

          {currentStep === "ADD_ATTACHMENT" && (
            <AddAttachmentsCard
              serviceRequestIds={[serviceRequest.id]}
              setAttachmentsCompleted={setAttachmentsReady}
              initialAttachments={removeEmptyAttachments(serviceRequestAttachments || undefined)}
            />
          )}
          {(currentStep === "REVIEW" || currentStep === "SKIP_TO_REVIEW") && (
            <ReviewStep
              serviceRequest={{ ...serviceRequest, attachments: serviceRequestAttachments || [] }}
              refetchData={refetchData}
            />
          )}
        </Container>
        <WithdrawAndReplaceFooter
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          formContent={formContent}
          setFormContent={setFormContent}
          setClinicalAssessmentProvider={setClinicalAssessmentProvider}
          nextButtonLabel={stepConfig.nextButtonLabel}
          patientId={serviceRequest?.patient?.id}
          serviceRequest={serviceRequest}
          serviceRequestEdited={serviceRequestEdited}
          setServiceRequestEdited={setServiceRequestEdited}
          serviceRequestCanBeSubmitted={serviceRequestCanBeSubmitted}
          requestor={requestor}
          attemptedSubmitService={attemptedSubmitService}
          setAttemptedSubmitService={setAttemptedSubmitService}
          attachmentsReady={attachmentsReady}
          patchServiceRequestError={Boolean(submitServiceRequestError)}
          patchServiceRequest={patchServiceRequest}
          patchServiceRequestLoading={submittingServiceRequest}
          refetchServiceRequest={serviceRequestsRefetch}
          refetchClinicalAssessment={refetchClinicalAssessment}
          refetchAttachments={refetchAttachments}
          setHealthHelpPASResponse={setHealthHelpPASResponse}
          vendorQuestionnaireInvalid={(healthHelpPASResponse && !vendorQuestionnaireComplete) || vendorAssessmentError}
          setVendorAssessmentError={setVendorAssessmentError}
          setVendorQuestionnaireComplete={setVendorQuestionnaireComplete}
        />
      </PageWrapper>
    </ClinicalAssessmentContext.Provider>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PageWrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: "100%",
  paddingTop: theme.spacing(5) + headerHeight,
  paddingBottom: theme.spacing(5) + headerHeight,
}));
