import { Model, Factory, Server } from "miragejs";

const models = {
  placeOfService: Model,
};
const factories = {
  placeOfService: Factory.extend({
    name(i: number) {
      return `Place of Service ${i}`;
    },
    code(i: number) {
      return `POSCODE${i}`;
    },
    description(i: number) {
      return `Description ${i}`;
    },
  }),
};

const scenarios = {
  standard(server: Server) {
    server.create("placeOfService", {
      name: "Pharmacy",
      code: "1",
      description: "Description of pharmacy",
    } as any);
    server.create("placeOfService", {
      name: "Office",
      code: "11",
      description: "Description of office",
    } as any);
    server.create("placeOfService", {
      name: "Test empty description",
      code: "POSemptyDesc",
      description: "",
    } as any);
    server.create("placeOfService", {
      name: "Test Long Name Long Name Long Name Long Name Long Name Long Name Long Name Long Name Long Name Long Name Long Name.",
      code: "POSlongName",
      description: "",
    } as any);
    server.createList("placeOfService", 100);
  },
};

const activeScenario = "standard";
//const activeScenario = "short";
//const activeScenario = "empty";

export { models, factories, scenarios, activeScenario };
