import { ReactNode, useCallback } from "react";
import CopyIcon from "../images/Copy";
import { InlineButton } from "../InlineButton";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";

interface Props {
  text: string | undefined;
  message?: string;
  title?: string;
  onClick?: () => void;
  copyIcon?: ReactNode;
  style?: React.CSSProperties;
}

export default function CopyToClipboard({
  text,
  message,
  title = "Copy to clipboard",
  onClick,
  copyIcon,
  style,
}: Props) {
  const copyToClipboard = useCallback(() => {
    if (text) {
      navigator.clipboard.writeText(text);
    }
    onClick?.();
  }, [text, onClick]);

  return (
    <CopyLinkButton
      disableRipple
      startIcon={copyIcon || <CopyIcon />}
      style={style}
      title={title}
      onClick={() => {
        copyToClipboard();
      }}
    >
      {message}
    </CopyLinkButton>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CopyLinkButton = styled(InlineButton)(() => ({
  "& div": {
    marginLeft: "0px",
  },
}));
