import { OscarServiceType, useGetAuthValidityWindow } from "@coherehealth/core-platform-api";
import { useEffect } from "react";
import { formatDateStr, formatDateToISODate } from "@coherehealth/common";
import { dateProperlyFormatted } from "util/serviceRequest";

interface Props {
  patientId?: string;
  startDate?: Date;
  isInpatient?: boolean;
  serviceType?: OscarServiceType;
}

export default function useAuthValidityWindowText({ patientId, startDate, isInpatient, serviceType }: Props): {
  message: string | null;
  loading: boolean;
} {
  const { data, loading, refetch } = useGetAuthValidityWindow({
    lazy: true,
  });

  useEffect(() => {
    if (!!patientId && !!startDate && dateProperlyFormatted(startDate)) {
      refetch({
        queryParams: {
          patient: patientId,
          encounterType: isInpatient ? "INPATIENT" : "OUTPATIENT",
          serviceType,
          startDate: formatDateToISODate(startDate),
        },
      });
    }
  }, [isInpatient, patientId, refetch, serviceType, startDate]);

  return {
    loading,
    message:
      data &&
      `Authorization valid for ${data.numberOfDays} days (${formatDateStr(startDate)} - ${formatDateStr(
        data.validityWindowEndDate
      )})`,
  };
}
