import React, { Dispatch, FunctionComponent, SetStateAction } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, makeStyles, IconButton } from "@material-ui/core";
import { Caption, Body1 } from "../Typography";
import SingleSelectDropdown from "../Dropdown/SingleSelectDropdown";
import { colorsLight } from "../../themes";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(1, 0),
  },
  dropdown: {
    "& .MuiFilledInput-root": {
      width: "80px",
    },
    "& .MuiSelect-filled.MuiSelect-select": {
      paddingRight: 0,
      minWidth: 20,
    },
  },
  marginAuto: {
    margin: "auto",
  },
  rightAligned: {
    marginLeft: "auto",
  },
  centeredVertically: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  secondaryFont: {
    color: colorsLight.font.secondary,
  },
  black: {
    color: theme.palette.text.primary,
  },
  numbers: {
    minWidth: 50,
  },
  leftArrow: {
    marginRight: theme.spacing(-2),
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
    "& .MuiSvgIcon-root": {
      marginLeft: theme.spacing(2),
    },
  },
}));

export interface PaginateProps {
  max?: number;
  offset?: number;
}

export interface PaginateResultsProps {
  paginateParams: PaginateProps;
  count: number;
  setPaginateParams: Dispatch<SetStateAction<PaginateProps>>;
  displayMax: number;
  RESULTS_PER_PAGE?: { id: string; label: string }[];
  totalCount?: number;
  hideRowPerPageDropDown?: boolean;
}

export const DEFAULT_RESULTS_PER_PAGE = [
  { id: "5", label: "5" },
  { id: "10", label: "10" },
  { id: "20", label: "20" },
  { id: "30", label: "30" },
];
export const DEFAULT_MAX_DISPLAY = Number(DEFAULT_RESULTS_PER_PAGE[2].id);

const PaginateResults: FunctionComponent<PaginateResultsProps> = ({
  paginateParams: queryParams,
  count,
  setPaginateParams: setQueryParams,
  displayMax,
  RESULTS_PER_PAGE = DEFAULT_RESULTS_PER_PAGE,
  totalCount,
  hideRowPerPageDropDown,
}: PaginateResultsProps) => {
  const { offset = 0 } = queryParams;

  const resultsPerPage = RESULTS_PER_PAGE.find((label) => Number(label.id) === Number(displayMax));
  const startNumber = count !== 0 ? Number(offset) + 1 : 0;
  const endNumber = count !== 0 ? Number(offset) + Math.min(count, displayMax) : 0;
  const classes = useStyles();
  return (
    <Container className={`${classes.rightAligned} ${classes.container}`}>
      {!hideRowPerPageDropDown && (
        <>
          <Caption className={`${classes.secondaryFont} ${classes.centeredVertically}`}>Rows per page</Caption>
          <MenuDropDown>
            <SingleSelectDropdown
              menuWidth={78}
              options={RESULTS_PER_PAGE}
              value={resultsPerPage?.id}
              onChange={(val) => {
                setQueryParams({ ...queryParams, offset: 0, max: Number(val) + 1 });
              }}
              className={classes.dropdown}
            />
          </MenuDropDown>
        </>
      )}
      <PageNumberAndArrows>
        <Body1 className={`${classes.secondaryFont} ${classes.marginAuto} ${classes.numbers}`}>
          {startNumber}-{endNumber}
          {totalCount ? ` of ${totalCount}` : ""}
        </Body1>
        <IconButton
          className={`${classes.black} ${classes.leftArrow}`}
          disabled={startNumber === 1 || offset === 0}
          onClick={() => {
            setQueryParams({
              ...queryParams,
              offset: offset - displayMax,
            });
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton
          className={classes.black}
          disabled={count <= displayMax && (totalCount === undefined || totalCount <= offset + displayMax)}
          onClick={() => {
            setQueryParams({
              ...queryParams,
              offset: offset + displayMax,
            });
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </PageNumberAndArrows>
    </Container>
  );
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Container = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  height: theme.spacing(7),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PageNumberAndArrows = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const MenuDropDown = styled("div")(({ theme }) => ({
  marginLeft: theme.spacing(2),
  paddingRight: theme.spacing(3),
}));

export default PaginateResults;
