import React, { SVGProps } from "react";

export default function WithdrawnServiceRequestImage(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="40" cy="40" r="40" fill="#F5F5F5" />
      <path d="M53.988 23.3633H21.3867V61.3869H53.988V23.3633Z" fill="#A1AAB4" />
      <path d="M46.2998 25.668H23.6934V59.0786H51.6682V31.0498L46.2998 25.668Z" fill="white" />
      <path
        d="M45.0712 18.654H40.728C40.728 16.7387 39.3656 15.1875 37.6796 15.1875C35.9935 15.1875 34.6312 16.7387 34.6312 18.654H30.288L29.0605 26.3424H46.2851L45.0712 18.654Z"
        fill="#384358"
      />
      <path
        d="M32.2453 30.9684H29.9253V28.6484H28.3337V30.9684H26.0137V32.5601H28.3337V34.88H29.9253V32.5601H32.2453V30.9684Z"
        fill="#EA1D63"
      />
      <path d="M44.9373 37.457H30.4238V38.5496H44.9373V37.457Z" fill="#89D3DD" />
      <path d="M48.9838 54.9492H43.1973V56.0418H48.9838V54.9492Z" fill="#89D3DD" />
      <path d="M48.9834 41.4609H26.377V42.5535H48.9834V41.4609Z" fill="#C2C2C2" />
      <path d="M48.9834 44.7383H26.377V45.8308H48.9834V44.7383Z" fill="#C2C2C2" />
      <path d="M48.9834 48.0195H26.377V49.1121H48.9834V48.0195Z" fill="#C2C2C2" />
      <path d="M36.4797 51.2969H26.377V52.3894H36.4797V51.2969Z" fill="#C2C2C2" />
      <path d="M46.2988 31.0498H51.6672L46.2988 25.668V31.0498Z" fill="#E6E9EE" />
      <circle cx="56.6365" cy="26.9978" r="9.69314" fill="white" />
      <path
        d="M56.6982 17.0547C51.1734 17.0547 46.6895 21.5386 46.6895 27.0634C46.6895 32.5882 51.1734 37.0722 56.6982 37.0722C62.223 37.0722 66.7069 32.5882 66.7069 27.0634C66.7069 21.5386 62.223 17.0547 56.6982 17.0547ZM57.6991 32.0678H55.6973V30.066H57.6991V32.0678ZM57.6991 28.0643H55.6973V22.0591H57.6991V28.0643Z"
        fill="#6E7580"
      />
    </svg>
  );
}
