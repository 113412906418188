import { registerRoute } from "../server";
import { Response } from "miragejs";
import { CarePath, ObservationCode } from "../../index";

const generateErrorResponse = (message: string = "") => {
  return new Response(
    404,
    {},
    {
      errorCode: 404,
      errorDescription: `Raw error response from server, don't display this! ${message}`,
    }
  );
};

export default function registerRoutes() {
  registerRoute({
    id: "carePathList",
    route: "carePath",
    method: "get", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      const queryParams = request.queryParams;
      let result = (schema as any).carePaths.all();
      if (queryParams) {
        if (queryParams.query) {
          result = (result as any).filter((item: CarePath) => {
            return (
              item.name?.match(new RegExp(queryParams.query, "i")) ||
              item.groupName?.match(new RegExp(queryParams.query, "i")) ||
              item.description?.match(new RegExp(queryParams.query, "i"))
            );
          });
        }
        if (queryParams.offset) {
          result = (result as any).slice(queryParams.offset);
        }
        if (queryParams.limit) {
          result = (result as any).slice(0, queryParams.limit);
        }
      }
      return result;
    },
    routeConfig: {
      //slow: true,
      //slowMs: 5000,
      //error: true,
      //errorCode: 500,
    },
  });

  registerRoute({
    id: "carePathCreate",
    route: "carePath",
    method: "post", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      const { name, groupName } = JSON.parse(request.requestBody);
      const date = new Date().toISOString();
      let result = schema.create("carePath", {
        name,
        groupName,
        description: "",
        abbreviation: "",
        lastUpdated: date,
        filters: [{ SUPPORTED_GENDERS: "BOTH" }],
      } as Partial<CarePath>);
      return (schema as any).carePaths.find(result.id);
    },
  });

  registerRoute({
    id: "carePathFetch",
    route: "carePath/:id",
    method: "get", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      return (schema as any).carePaths.find(request.params.id);
    },
  });

  // Mirage expects relationships to be specified with *Id properties, so rename some of the submitted fields
  function transformCarePathForSchema(submittedProperties: any) {
    const newProperties = { ...submittedProperties };

    if (newProperties.hasOwnProperty("diseaseArea")) {
      newProperties.diseaseAreaId = newProperties.diseaseArea?.id;
      delete newProperties.diseaseArea;
    }
    if (newProperties.hasOwnProperty("serviceCategories")) {
      newProperties.serviceCategoryIds =
        newProperties.serviceCategories?.map((c: any) => c.id) || [];
      delete newProperties.serviceCategories;
    }

    return newProperties;
  }

  registerRoute({
    id: "carePathUpdate",
    route: "carePath/:id",
    method: "put", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      const { id, ...updateFields } = JSON.parse(request.requestBody);
      const existing = (schema as any).carePaths.find(request.params.id);
      if (existing) {
        // Merge filters object
        if (updateFields.filters && existing?.filters) {
          updateFields.filters = {
            ...existing.filters,
            ...updateFields.filters,
          };
        }
        const newProperties = transformCarePathForSchema(updateFields);

        // See if serviceCategories were changed
        if (newProperties.serviceCategoryIds) {
          const newCategoryIds = newProperties.serviceCategoryIds || [];
          const existingCategoryIds = existing.serviceCategoryIds || [];
          if (newCategoryIds.join(",") !== existingCategoryIds.join(",")) {
            // IDs are different: we must be deleting one, adding one, or reordering
            existingCategoryIds.forEach((serviceCategoryId: any) => {
              if (!newCategoryIds.includes(serviceCategoryId)) {
                const serviceCategory = (schema as any).serviceCategories.find(
                  serviceCategoryId
                );
                // TODO also destroy the serviceCategory's clinicalServices etc
                serviceCategory.destroy();
              }
            });
            // New serviceCategory is submitted as an entry in the list
            if (newCategoryIds.includes("will-be-replaced")) {
              const { id, ...submittedCategory } =
                updateFields.serviceCategories.find(
                  (c: any) => c.id === "will-be-replaced"
                );
              const newCategory = (schema as any).serviceCategories.create(
                submittedCategory
              );
              newProperties.serviceCategoryIds = [
                ...newProperties.serviceCategoryIds.filter(
                  (id: any) => id !== "will-be-replaced"
                ),
                newCategory.id,
              ];
            }
          } else {
            // IDs are the same: probably updating a name
            updateFields.serviceCategories?.forEach((serviceCategory: any) => {
              const existingCategory = (schema as any).serviceCategories.find(
                serviceCategory.name
              );
              if (existingCategory.name !== serviceCategory.name) {
                existingCategory.update({ name: serviceCategory.name });
              }
            });
          }
        }

        existing.update({
          ...newProperties,
          lastUpdated: new Date().toISOString(),
        });

        existing.reload();
        return existing;
      } else {
        return generateErrorResponse("Carepath not found");
      }
    },
  });

  registerRoute({
    id: "clinicalServiceCreate",
    route: "clinicalService",
    method: "post", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      const { serviceCategory: serviceCategoryId, name } = JSON.parse(
        request.requestBody
      );
      const serviceCategory = (schema as any).serviceCategories.find(
        serviceCategoryId
      );
      if (!serviceCategory) {
        return generateErrorResponse("Category not found");
      }

      const newClinicalService = serviceCategory.createClinicalService({
        // Do not include the "serviceCategory" here, otherwise Mirage adds the relationship twice!
        name,
      });

      return newClinicalService;
    },
  });

  registerRoute({
    id: "clinicalServiceSearch",
    route: "clinicalService",
    method: "get", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      return (schema as any).clinicalServices.where({});
    },
  });

  registerRoute({
    id: "clinicalServiceFetch",
    route: "clinicalService/:id",
    method: "get", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      const id = request.params.id;
      const clinicalService = (schema as any).clinicalServices.find(id);
      if (!clinicalService) {
        return generateErrorResponse("ClinicalService not found");
      }
      return clinicalService;
    },
  });

  registerRoute({
    id: "clinicalServiceUpdate",
    route: "clinicalService/:id",
    method: "put", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      const { id, ...updateFields } = JSON.parse(request.requestBody);
      const clinicalService = (schema as any).clinicalServices.find(id);
      if (!clinicalService) {
        return generateErrorResponse("ClinicalService not found");
      }

      // Future TODO: procedure codes and place of service relationships
      // will need custom handling here
      clinicalService.update(updateFields);
      clinicalService.reload();
      return clinicalService;
    },
  });

  registerRoute({
    id: "clinicalQuestionFetch",
    route: "clinicalQuestion/:id",
    method: "get", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      const id = request.params.id;
      return (
        (schema as any).clinicalQuestions.find(id) ||
        generateErrorResponse("Clinical question not found")
      );
    },
  });

  // Mirage expects relationships to be specified with *Id properties, so rename some of the submitted fields
  function transformClinicalQuestionForSchema(submittedProperties: any) {
    const { clinicalService, ...newProperties } = submittedProperties;

    // TODO answers UI implementation. Currently they are submitted in full so just remove them
    if (newProperties.hasOwnProperty("responseOptions")) {
      delete newProperties.responseOptions;
    }

    return {
      clinicalServiceId: clinicalService,
      ...newProperties,
    };
  }

  registerRoute({
    id: "clinicalQuestionCreate",
    route: "clinicalQuestion",
    method: "post", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      // 'id' is submitted as a blank string - ignore it
      const { id, ...updateFields } = JSON.parse(request.requestBody);

      const newProperties = transformClinicalQuestionForSchema(updateFields);
      if (!newProperties.clinicalServiceId) {
        return generateErrorResponse("ClinicalService not specified");
      }
      const clinicalService = (schema as any).clinicalServices.find(
        newProperties.clinicalServiceId
      );
      if (!clinicalService) {
        return generateErrorResponse("ClinicalService not found");
      }
      return (schema as any).clinicalQuestions.create(newProperties);
    },
  });

  registerRoute({
    id: "clinicalQuestionUpdate",
    route: "clinicalQuestion/:id",
    method: "put", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      const { id, ...updateFields } = JSON.parse(request.requestBody);
      const question = (schema as any).clinicalQuestions.find(id);
      if (!question) {
        return generateErrorResponse("Clinical question not found");
      }
      const newProperties = transformClinicalQuestionForSchema(updateFields);
      question.update(newProperties);
      question.reload();

      return question;
    },
  });

  registerRoute({
    id: "observationCodeFetch",
    route: "observationCode",
    method: "get", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      const queryParams = request.queryParams;
      let result = (schema as any).observationCodes.all();
      if (queryParams) {
        if (queryParams.query) {
          result = (result as any).filter((item: ObservationCode) => {
            return item.code?.match(new RegExp(queryParams.query, "i"));
          });
        }
        if (queryParams.offset) {
          result = (result as any).slice(queryParams.offset);
        }
        if (queryParams.limit) {
          result = (result as any).slice(0, queryParams.limit);
        }
      }
      if (!result) {
        return generateErrorResponse("Unable to fetch observation codes");
      }
      return result;
    },
  });
}
