import { useAuthorized } from "authorization";
import { EditPatientCoverages } from "./EditPatientCoverages";
import { NotFoundPage } from "@coherehealth/common";

export default function EditPatientCoveragesPage() {
  const canViewPatientManagementPage = useAuthorized("PATIENT_MANAGEMENT_PAGE");

  if (canViewPatientManagementPage) {
    return <EditPatientCoverages />;
  } else {
    return <NotFoundPage />;
  }
}
