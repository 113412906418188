import React, { FunctionComponent, useEffect, useState } from "react";

import {
  DateSelect,
  formatDateStr,
  formatDateToISODate,
  parseDateFromISOStringWithoutFallback,
  plusDays,
  removeTimeFromDate,
  today,
} from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, makeStyles } from "@material-ui/core/styles";
import { Patient, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { CoverageCheck } from "util/patientUtils";
import { useTrackUserImpression } from "util/userActivityTracker";

export interface StartDateProps {
  hasValidEndDate: boolean;
  minStartDate?: Date;
  maxStartDate?: Date;
  startDate?: Date;
  setStartDate: (date: Date) => void;
  isExpedited: boolean;
  clinicalServiceId?: string;
  serviceRequestId?: string;
  cohereAuthId?: string;
  intakeTimestamp?: string;
  patient?: Patient;
  coverageCheck?: CoverageCheck;
  hasCoverageError?: boolean;
  setHasValidCoverageDates: React.Dispatch<React.SetStateAction<boolean>>;
  showApprovedSrEditWarning?: boolean;
  onCancelStartDateChange: () => void;
  disabled?: boolean;
  startDateLabel: string;
  serviceRequest?: ServiceRequestResponse;
  attemptedSubmit: boolean;
}

const StartDateSelect: FunctionComponent<StartDateProps> = ({
  hasValidEndDate,
  minStartDate,
  startDate,
  setStartDate,
  isExpedited,
  clinicalServiceId,
  serviceRequestId,
  cohereAuthId,
  intakeTimestamp,
  patient,
  coverageCheck,
  hasCoverageError,
  setHasValidCoverageDates,
  showApprovedSrEditWarning,
  onCancelStartDateChange,
  disabled,
  maxStartDate,
  startDateLabel,
  serviceRequest,
  attemptedSubmit,
}) => {
  const trackUserActivityImpression = useTrackUserImpression();
  const patientId = patient?.id || "";

  const getIntakeTimestampDate = (intakeTimestamp: string | undefined) => {
    const intakeTimestampDate = parseDateFromISOStringWithoutFallback(intakeTimestamp);
    if (intakeTimestampDate) {
      return removeTimeFromDate(intakeTimestampDate);
    }
  };

  const intakeTimestampDate = getIntakeTimestampDate(intakeTimestamp);
  const displayExpeditedStartDateWarning =
    isExpedited && plusDays(3, intakeTimestampDate ? intakeTimestampDate : today()) <= (startDate || today());

  let helperMessage;

  if (displayExpeditedStartDateWarning) {
    helperMessage = `Requests submitted 3 or more days before the request's start date aren't typically processed as expedited. 
    The request may be flagged for review and downgraded to standard.${
      intakeTimestampDate ? ` This request was originally submitted on ${formatDateStr(intakeTimestampDate)}.` : ""
    }`;
  }

  const [expeditedWarningSeen, setExpeditedWarningSeen] = useState(false);
  useEffect(() => {
    if (displayExpeditedStartDateWarning && !expeditedWarningSeen) {
      setExpeditedWarningSeen(true);
      trackUserActivityImpression({
        event: "FIELD_RECOMMENDED_CHANGE",
        stage: "AUTH_CREATION",
        field: "EXPEDITED",
        activityContext: { patientId, clinicalServiceId, serviceRequestId, cohereAuthId },
        beforeSnapshot: {
          startDate: formatDateToISODate(startDate),
        },
      });
    }
  }, [
    patientId,
    clinicalServiceId,
    startDate,
    displayExpeditedStartDateWarning,
    trackUserActivityImpression,
    expeditedWarningSeen,
    setExpeditedWarningSeen,
    serviceRequestId,
    cohereAuthId,
  ]);

  if (coverageCheck && !coverageCheck.inRange) {
    helperMessage = (
      <>
        {coverageCheck.messageToDisplay?.map((m, idx) => (
          <HelperText key={idx}>{m}</HelperText>
        ))}
      </>
    );
  }

  useEffect(() => {
    // hoists the coverage error value up to the parent
    // the dates are valid if there is NO coverage error
    setHasValidCoverageDates(!hasCoverageError);
  }, [hasCoverageError, setHasValidCoverageDates]);

  const dateSelectClasses = useDateSelectStyles({});
  const handlingError = (!startDate && attemptedSubmit) || hasCoverageError;
  const getHelperTextIfNull = () => handlingError && "Required";

  return (
    <DateSelect
      label={startDateLabel}
      data-tracking-id="service-start-date"
      value={startDate || null}
      onDateChange={setStartDate}
      minDate={minStartDate}
      minDateMessage={
        serviceRequest?.requestType === "CONTINUATION"
          ? `Date cannot be before ${formatDateStr(minStartDate)}`
          : "Selected date is before the minimum date"
      }
      maxDate={maxStartDate}
      maxDateMessage={
        serviceRequest?.requestType === "CONTINUATION"
          ? `Date cannot be after ${formatDateStr(maxStartDate)}`
          : "Date too far in the future"
      }
      error={handlingError}
      warning={hasValidEndDate && displayExpeditedStartDateWarning}
      helperText={(hasValidEndDate && helperMessage) || getHelperTextIfNull()}
      TextFieldProps={{
        warningHelperText: showApprovedSrEditWarning,
        className: dateSelectClasses.startDateTextField,
      }}
      attemptedSubmit={attemptedSubmit}
    />
  );
};

export default StartDateSelect;

const useDateSelectStyles = makeStyles({
  startDateTextField: {
    height: "fit-content",
    width: "100%",
    "& .MuiFormHelperText-root": {
      // width is 275% so that the helper text will extend past the text field container
      width: "275%",
    },
  },
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const HelperText = styled("span")(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  display: "block",
  width: "110%",
}));
