import React from "react";

import { Body1, Caption, H2, PrimaryButton, onCloseBackDrop } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { createStyles, makeStyles, styled, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MuiErrorIcon from "@material-ui/icons/Error";
import { cohereBaseTheme } from "@coherehealth/design-system";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 3, 7, 3),
      borderRadius: theme.spacing(2),
    },
    loading: {
      padding: theme.spacing(2, 5),
      borderRadius: theme.spacing(1.25),
    },
    title: {
      padding: 0,
    },
    closeButton: {
      float: "right",
      color: theme.palette.text.primary,
      padding: 0,
    },
    content: {
      padding: 0,
      overflowY: "hidden",
    },
    message: {
      paddingTop: theme.spacing(3),
      fontFamily: "Gilroy-SemiBold",
      fontWeight: 400,
      textAlign: "center",
    },
    details: {
      textAlign: "center",
      width: "100%",
      padding: theme.spacing(2, 3),
      fontSize: theme.spacing(1.875),
      fontFamily: "Gilroy-Medium",
      lineHeight: "normal",
      color: cohereBaseTheme.palette.font.secondary,
    },
    caption: {
      padding: theme.spacing(0, 3),
      fontSize: theme.spacing(1.875),
      textAlign: "center",
      fontFamily: "Gilroy-Medium",
      width: "100%",
      lineHeight: "normal",
      color: cohereBaseTheme.palette.font.secondary,
    },
    actions: {
      paddingTop: theme.spacing(3),
      justifyContent: "center",
    },
  })
);

const iconSize = 80;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SuccessIcon = styled(CheckCircleIcon)(({ theme }) => ({
  fontSize: iconSize,
  color: theme.palette.success.main,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ErrorIcon = styled(MuiErrorIcon)(({ theme }) => ({
  fontSize: iconSize,
  color: theme.palette.error.main,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ActionButton = styled(PrimaryButton)(({ theme }) => ({
  padding: `${theme.spacing(2)}px ${theme.spacing(10)}px`,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DoneButton = styled(PrimaryButton)(({ theme }) => ({
  padding: theme.spacing(2, 5),
}));

export enum SubmissionStatus {
  Loading,
  Success,
  Failure,
}

interface Props {
  open: boolean;
  handleClose: () => void;
  onSuccessAction: () => void;
  onFailureAction: () => void;
  status: SubmissionStatus;
  errorDetails?: string | undefined;
  containsSensitiveInformation?: boolean;
  visibilityToggleStatus?: boolean;
}

const displayOptions = [
  {
    status: SubmissionStatus.Loading,
    icon: <CircularProgress size={64} />,
    message: "Submitting",
  },
  {
    status: SubmissionStatus.Success,
    icon: <SuccessIcon />,
    message: "Successfully submitted",
    details:
      "Authorization is based on information provided; it is not a guarantee of payment. Billed services are subject to medical necessity, appropriate setting, billing/coding, plan limits, eligibility at time of service. (Not applicable for Colorado members.) Verify benefits online or call Customer Service.",
  },
  {
    status: SubmissionStatus.Failure,
    icon: <ErrorIcon />,
    message: "Submission Unsuccessful",
    details: "An error occurred while trying to submit the request. Please try again.",
  },
];

export default function SubmissionModal({
  open,
  handleClose,
  onSuccessAction,
  onFailureAction,
  status,
  errorDetails,
  containsSensitiveInformation,
  visibilityToggleStatus,
}: Props) {
  const classes = useStyles();

  const { icon, message, details } = displayOptions.find((opt) => opt.status === status) || {};

  return (
    <Dialog
      classes={{ paper: status === SubmissionStatus.Loading ? classes.loading : classes.paper }}
      fullWidth
      onClose={onCloseBackDrop(handleClose)}
      aria-labelledby="sr-submission-message"
      aria-describedby={details && "sr-detail-message"}
      open={open}
      disableEscapeKeyDown
      data-testid="submission-modal"
    >
      {status !== SubmissionStatus.Success && status !== SubmissionStatus.Loading && (
        <DialogTitle className={classes.title}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            {<CloseIcon />}
          </IconButton>
        </DialogTitle>
      )}

      <DialogContent className={classes.content}>
        <Grid container direction="column" alignItems="center">
          {icon}
          <H2 className={classes.message} id="sr-submission-message">
            {message}
          </H2>
          {status === SubmissionStatus.Success && containsSensitiveInformation ? (
            <>
              <Body1 className={classes.details} id="sr-detail-message">
                {!visibilityToggleStatus ? (
                  <span>
                    <b>Only you</b> can view the sensitive information in this request. Others involved in this
                    patient’s care will only be able to check the status.
                  </span>
                ) : (
                  <span>
                    <b>Individuals associated with the providers and facility</b> can view the details of this request.
                  </span>
                )}
                <br />
                <br />
                You can change this setting or download the service summary from the Patient Summary to share as needed.
              </Body1>
              <Caption className={classes.caption} id="sr-detail-caption">
                Authorization is based on information provided; it is not a guarantee of payment. Billed services are
                subject to medical necessity, appropriate setting, billing/coding, plan limits, eligibility at time of
                service. (Not applicable for Colorado members.) Verify benefits online or call Customer Service.
              </Caption>
            </>
          ) : (
            (errorDetails || details) && (
              <Body1 className={classes.details} id="sr-detail-message">
                {errorDetails || details}
              </Body1>
            )
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        {status === SubmissionStatus.Success && <DoneButton onClick={onSuccessAction}>Done</DoneButton>}
        {status === SubmissionStatus.Failure && <ActionButton onClick={onFailureAction}>Retry submission</ActionButton>}
      </DialogActions>
    </Dialog>
  );
}
