import { DropdownOption, MultiSelectDropdown } from "../Dropdown";
import { BaseFilterConfig } from "./shared";

export interface MultiSelectFilterConfig extends BaseFilterConfig {
  filterType: "MultiSelect";
  options: DropdownOption[];
  optionToLabel?: (option: unknown) => string;
  initialFilterValues?: unknown;
}

export interface MultiSelectFilterProps {
  filterConfig: MultiSelectFilterConfig;
  value: DropdownOption[] | null | undefined;
  onChange?: (key: string, newValue: DropdownOption[]) => void;
  setPreventClose?: (val: boolean) => void;
}

export function MultiSelectFilter({ filterConfig, value, onChange, setPreventClose }: MultiSelectFilterProps) {
  const { key, label, options } = filterConfig;
  const handleChange = (selectedIds: string[]) => {
    const selectedOptions = options.filter(({ id }) => selectedIds.includes(id));
    if (onChange) {
      onChange(key, selectedOptions);
    }
  };
  const selectedIds = !value ? [] : value.map(({ id }) => id);
  const onMenuOpen = () => {
    if (setPreventClose) {
      setPreventClose(true);
    }
  };
  const onMenuClose = () => {
    if (setPreventClose) {
      setPreventClose(false);
    }
  };
  return (
    <MultiSelectDropdown
      label={label}
      options={options}
      selectedValues={selectedIds}
      onChange={handleChange}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
    />
  );
}
