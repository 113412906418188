import React, { Dispatch } from "react";
import { H2, H6, FullPageBanner } from "@coherehealth/common";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { CarePath, ClinicalService, ServiceDeterminationProcedureCodeBucket } from "@coherehealth/core-platform-api";
import ServiceCard from "./ServiceCard";
import PurpleClipboard from "components/images/PurpleClipboard";

interface Props {
  primaryCarePath?: CarePath;
  remainingServices?: (ClinicalService | undefined)[];
  buckets: ServiceDeterminationProcedureCodeBucket[];
  removeBucket: Dispatch<ServiceDeterminationProcedureCodeBucket>;
  addBucket: Dispatch<ServiceDeterminationProcedureCodeBucket>;
  displayOtherProcedureOption: boolean;
}

export default function AdditionalServiceContainer({
  primaryCarePath,
  remainingServices,
  buckets,
  removeBucket,
  addBucket,
  displayOtherProcedureOption,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.sectionMargins}>
      <FullPageBanner bannerIcon={<PurpleClipboard />} backgroundColor={"#EAE9F4"} padding={theme.spacing(3)}>
        <H2 className={classes.bannerTextPaddingBottom}>
          Patients with {primaryCarePath?.name} may require these additional procedures.
        </H2>
        <H6>Make your authorization process more efficient by requesting additional procedures in advance.</H6>
      </FullPageBanner>
      <H2 className={classes.paddingBottom}>Additional procedures for {primaryCarePath?.name}</H2>
      {remainingServices?.map((clinicalService) => {
        return (
          <ServiceCard
            key={clinicalService?.id}
            clinicalService={clinicalService}
            isSelected={Boolean(buckets?.find((bucket) => bucket.clinicalService?.id === clinicalService?.id))}
            primaryCarePath={primaryCarePath}
            removeBucket={removeBucket}
            addBucket={addBucket}
          />
        );
      })}
      {displayOtherProcedureOption && (
        <ServiceCard
          isOffCarePathService
          isSelected={Boolean(buckets?.find((bucket) => bucket.clinicalService?.id === undefined))}
          bucket={{ clinicalService: undefined, carePath: primaryCarePath, procedureCodes: undefined }}
          removeBucket={removeBucket}
          addBucket={addBucket}
        />
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  bannerTextPaddingBottom: {
    marginBottom: theme.spacing(1),
  },
  paddingBottom: {
    marginBottom: theme.spacing(2),
  },
  sectionMargins: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));
