import React from "react";
import { ReferralRequestSearchResponse } from "@coherehealth/core-platform-api";
import {
  Body1,
  Caption,
  Card,
  colorsDark,
  colorsLight,
  formatDateStr,
  H4,
  patientDisplayName,
} from "@coherehealth/common";
import { Grid, makeStyles, useTheme } from "@material-ui/core";
import { Divider } from "@mui/material";
import ReferralRequestStatusDisplay from "../common/ReferralRequestStatusDisplay";
import routes from "../../../routes";
import { generatePath, Link } from "react-router-dom";

interface Props {
  referralRequest: ReferralRequestSearchResponse;
}

const useStyles = makeStyles((theme) => ({
  referralRequestCard: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1.5),
  },
  patientInfoRow: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: theme.spacing(0, 1.5),
  },
  lightCaption: {
    color: theme.palette.type === "dark" ? colorsDark.font.secondary : colorsLight.font.light,
    margin: "2px 0px",
  },
  patientInfo: {
    paddingLeft: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    "& span": {
      marginRight: theme.spacing(1),
    },
  },
  divider: {
    margin: theme.spacing(0, 1.5),
    color: colorsLight.gray.divider,
  },
}));

export default function ReferralRequestCard({ referralRequest }: Props) {
  const {
    startDate,
    endDate,
    selectedPerformingSpecialist,
    patient,
    healthPlanDisplayName,
    healthPlanName,
    selectedFacility,
    specialty,
  } = referralRequest;

  const classes = useStyles();
  const theme = useTheme();
  const patientId = referralRequest.patient?.id;

  return (
    <Link
      to={generatePath(routes.REFERRAL_PATIENT_SUMMARY, { patientId }).concat(
        `?reviewReferralRequestId=${referralRequest.id}`
      )}
      style={{ textDecoration: "none" }}
    >
      <Card className={classes.referralRequestCard}>
        <Grid container spacing={3} style={{ paddingTop: theme.spacing(1.5) }}>
          <Grid item xs={12}>
            <div className={classes.patientInfoRow}>
              <H4>{patient && patientDisplayName(patient)}</H4>
              <div style={{ display: "flex" }}>
                <div className={classes.patientInfo}>
                  <Caption className={classes.lightCaption}>DOB</Caption>
                  <Body1>{formatDateStr(patient?.dateOfBirth)}</Body1>
                </div>
                <div className={classes.patientInfo}>
                  <Caption className={classes.lightCaption}>Member ID</Caption>
                  <Body1>{patient?.memberId || "Unknown"}</Body1>
                </div>
                <div className={classes.patientInfo} data-public>
                  <Caption className={classes.lightCaption}>Health plan</Caption>
                  <Body1>{healthPlanDisplayName || healthPlanName || "Unknown"}</Body1>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.divider}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.patientInfoRow}>
              <div style={{ width: "25%" }}>
                <Caption className={classes.lightCaption}>Specialty</Caption>
                <Body1>{specialty || "-"}</Body1>
              </div>
              <div style={{ width: "34%" }}>
                <Caption className={classes.lightCaption}>Specialist practice</Caption>
                <Body1>{selectedFacility?.name || "-"}</Body1>
              </div>
              <div style={{ width: "25%" }}>
                <Caption className={classes.lightCaption}>Specialist</Caption>
                <Body1>{selectedPerformingSpecialist?.name || "-"}</Body1>
              </div>
              <div style={{ width: "16%" }}>
                <Caption className={classes.lightCaption}>Dates valid</Caption>
                <Body1>{formatDateStr(startDate) + " - " + formatDateStr(endDate)}</Body1>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ padding: theme.spacing(1.5), paddingTop: 0 }}>
              <ReferralRequestStatusDisplay referralRequest={referralRequest} />
            </div>
          </Grid>
        </Grid>
      </Card>
    </Link>
  );
}
