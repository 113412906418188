import { SVGProps } from "react";

// - checkbox item mimicking material ui checkbox item but with a dash inside.
const UndecidedCheckboxIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="m 19 3 h -14 c -1.11 0 -2 0.9 -2 2 V 19 C 3 20.1 3.89 21 5 21 H 19 C 20.11 21 21 20.1 21 19 v -14 C 21 3.9 20.11 3 19 3 Z M 6.86 13 l 0 -2 l 10.28 0 l 0 2 z" />
    </svg>
  );
};

export default UndecidedCheckboxIcon;
