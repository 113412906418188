import { Body1, Caption, Tooltip } from "@coherehealth/common";
import { Chip } from "@material-ui/core";
import { Grid } from "@mui/material";
import { usePatientSummarySharedStyles } from "components/PatientSummary/patientSummaryStyles";
import { useAuthorizationSummaryCardAdornmentsStyles } from "./authorizationSummaryAdornmentsStyles";

export const CareCoordinationBadge = () => {
  const patientSummaryStyles = usePatientSummarySharedStyles({ hasServiceCategory: false });
  const careCoordinationStyles = useAuthorizationSummaryCardAdornmentsStyles({});
  return (
    <Grid paddingLeft={2} paddingTop={0.3} alignItems="center" data-testid="care-rec-badge">
      <Tooltip
        placement="top"
        classes={{ tooltip: patientSummaryStyles.customWidth }}
        title={
          <Body1 className={careCoordinationStyles.tooltipText} data-public>
            Care recommendation requests are used to coordinate <br /> patient care outside of the standard
            authorization process.
          </Body1>
        }
      >
        <div>
          <Chip
            size="small"
            label={<Caption className={careCoordinationStyles.chipLabel}>Care Recommendation</Caption>}
            className={careCoordinationStyles.chip}
            data-public
          />
        </div>
      </Tooltip>
    </Grid>
  );
};
