import {
  ReviewType,
  useCreateAuditReview,
  useCreateMdReview,
  useCreateNewHireAuditReview,
  useCreateNurseReview,
  useCreateOutOfNetworkReview,
  useCreateOutOfScopeAuditReview,
  useCreatePeerToPeerReview,
  useCreateFaxAuditReview,
} from "@coherehealth/core-platform-api";
import {
  CREATE_MD_REVIEW_PARAM,
  CREATE_NURSE_REVIEW_PARAM,
  CREATE_OUT_OF_NETWORK_REVIEW_PARAM,
  CREATE_P2P_REVIEW_PARAM,
  CREATE_AUDIT_REVIEW_PARAM,
  CREATE_NEW_HIRE_AUDIT_PARAM,
  CREATE_CPP_REVIEW_PARAM,
  CREATE_DX_OOS_REVIEW_PARAM,
  CREATE_FAX_AUDIT_REVIEW_PARAM,
} from "util/queryParams";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { TrackUserActivityProps, useTrackUserInteraction } from "util/userActivityTracker";
import { useReviewsDispatch } from "./useReviews";
import { guidelineSnapshotFromReview } from "../../ServiceRequest/ReviewSection";
import { useSearchParams } from "react-router-dom";
import { extractErrorDetails } from "../../ServiceRequest/ReviewSection/util/ReviewSectionUtil";

interface UseInitReviewProps {
  serviceRequestId: string;
  existingReviews: ReviewType[] | null;
}

export interface UseInitReviewReturnProps {
  currentReview: ReviewType | undefined;
  openWarningModal: boolean;
  loadingCurrentReview: boolean;
  setOpenWarningModal: React.Dispatch<React.SetStateAction<boolean>>;
  isReviewEditMode: boolean;
}

const useInitReview = ({ serviceRequestId, existingReviews }: UseInitReviewProps): UseInitReviewReturnProps => {
  const { enqueueSnackbar } = useSnackbar();
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [currentReview, setCurrentReview] = useState<ReviewType>();
  const reviewsDispatch = useReviewsDispatch();
  const trackUserActivityInteraction = useTrackUserInteraction();
  const [searchParams] = useSearchParams();
  const isNurseReview = searchParams.has(CREATE_NURSE_REVIEW_PARAM);
  const isOutOfNetworkReview = searchParams.has(CREATE_OUT_OF_NETWORK_REVIEW_PARAM);
  const isCppReview = searchParams.has(CREATE_CPP_REVIEW_PARAM);
  const isMdReview = searchParams.has(CREATE_MD_REVIEW_PARAM);
  const isApproveWithoutP2P = searchParams.has("approveWithoutP2P");
  const isP2PReview = searchParams.has(CREATE_P2P_REVIEW_PARAM);
  const isPostDenialP2P = searchParams.has("isPostDenialP2P");
  const isAuditReview = searchParams.has(CREATE_AUDIT_REVIEW_PARAM);
  const isNewHireAuditReview = searchParams.has(CREATE_NEW_HIRE_AUDIT_PARAM);
  const isOutOfScopeAuditReview = searchParams.has(CREATE_DX_OOS_REVIEW_PARAM);
  const isFaxAuditReview = searchParams.has(CREATE_FAX_AUDIT_REVIEW_PARAM);
  const isReviewEditMode =
    isNurseReview ||
    isOutOfNetworkReview ||
    isCppReview ||
    isMdReview ||
    isApproveWithoutP2P ||
    isP2PReview ||
    isPostDenialP2P ||
    isAuditReview ||
    isNewHireAuditReview ||
    isOutOfScopeAuditReview ||
    isFaxAuditReview;

  const [autoCreatedReview, setAutoCreatedReview] = useState(false);

  const {
    mutate: nurseReviewCreate,
    loading: nurseReviewCreateLoading,
    error: nurseReviewCreateError,
  } = useCreateNurseReview({ serviceRequestId: serviceRequestId });

  const {
    mutate: outOfNetworkReviewCreate,
    loading: outOfNetworkReviewCreateLoading,
    error: outOfNetworkReviewCreateError,
  } = useCreateOutOfNetworkReview({ serviceRequestId: serviceRequestId });

  const {
    mutate: mdReviewCreate,
    loading: mdReviewCreateLoading,
    error: mdReviewCreateError,
  } = useCreateMdReview({
    serviceRequestId: serviceRequestId,
    queryParams: { approveWithoutP2P: isApproveWithoutP2P },
  });

  const {
    mutate: peerToPeerReviewCreate,
    loading: peerToPeerReviewCreateLoading,
    error: peerToPeerReviewCreateError,
  } = useCreatePeerToPeerReview({ serviceRequestId: serviceRequestId });

  const {
    mutate: auditReviewCreate,
    loading: auditReviewCreateLoading,
    error: auditReviewCreateError,
  } = useCreateAuditReview({ serviceRequestId: serviceRequestId });

  const {
    mutate: newHireAuditReviewCreate,
    loading: newHireAuditReviewLoading,
    error: newHireAuditReviewCreateError,
  } = useCreateNewHireAuditReview({ serviceRequestId: serviceRequestId });

  const {
    mutate: outOfScopeAuditReviewCreate,
    loading: outOfScopeAuditReviewLoading,
    error: outOfScopeAuditReviewError,
  } = useCreateOutOfScopeAuditReview({ serviceRequestId: serviceRequestId });

  const {
    mutate: faxAuditReviewCreate,
    loading: faxAuditReviewLoading,
    error: faxAuditReviewError,
  } = useCreateFaxAuditReview({ serviceRequestId: serviceRequestId });

  type FindFunc = (review: ReviewType) => boolean;
  type EventType = TrackUserActivityProps["event"];
  type CreateAPI =
    | typeof nurseReviewCreate
    | typeof outOfNetworkReviewCreate
    | typeof mdReviewCreate
    | typeof peerToPeerReviewCreate
    | typeof auditReviewCreate
    | typeof newHireAuditReviewCreate
    | typeof outOfScopeAuditReviewCreate
    | typeof faxAuditReviewCreate;

  // extract the type of the first argument/request body of an API function.
  type RequestBody<T extends CreateAPI> = T extends (data: infer FirstArg, ...args: any[]) => Promise<any>
    ? FirstArg
    : never;

  const initializeReview = useCallback(
    <T extends CreateAPI>(event: EventType, createApi: T, requestBody: RequestBody<T>, findExisting: FindFunc) => {
      const existingReview = existingReviews?.find(findExisting);
      if (existingReview && !(existingReview?.reviewType === "MdReview" && isApproveWithoutP2P)) {
        setOpenWarningModal(true);
        setCurrentReview(existingReview);
        setAutoCreatedReview(true);
      } else {
        setAutoCreatedReview(true);
        createApi(requestBody).then((newReview) => {
          reviewsDispatch?.({
            type: "ADD_REVIEW",
            reviewId: newReview.id,
            payload: { review: newReview },
          });
          setCurrentReview(newReview);
          const snap = newReview.guidelines ? guidelineSnapshotFromReview(newReview) : undefined;
          trackUserActivityInteraction({
            event: event,
            stage: "REVIEW_CREATION",
            activityContext: { reviewId: newReview.id, serviceRequestId },
            beforeGuidelineSnapshot: snap,
          });
        });
      }
    },
    [existingReviews, isApproveWithoutP2P, reviewsDispatch, serviceRequestId, trackUserActivityInteraction]
  );

  useEffect(() => {
    if (existingReviews && !autoCreatedReview) {
      if (isNurseReview || isCppReview) {
        initializeReview(
          "NURSE_REVIEW",
          nurseReviewCreate,
          { isClinicalPreprocessingReview: isCppReview },
          (review) => review.reviewType === "NurseReview" && review.reviewStatus === "DRAFT"
        );
      } else if (isOutOfNetworkReview) {
        initializeReview(
          "OUT_OF_NETWORK_REVIEW",
          outOfNetworkReviewCreate,
          {},
          (review) => review.reviewType === "OutOfNetworkReview" && review.reviewStatus === "DRAFT"
        );
      } else if (isMdReview) {
        initializeReview(
          "MD_REVIEW",
          mdReviewCreate,
          {},
          (review) => review.reviewType === "MdReview" && review.reviewStatus === "DRAFT"
        );
      } else if (isP2PReview) {
        initializeReview(
          "PTP_REVIEW",
          peerToPeerReviewCreate,
          { isPostDenialP2P: isPostDenialP2P },
          (review) =>
            review.reviewType === "PeerToPeerReview" &&
            review.reviewStatus === "DRAFT" &&
            review.isPostDenialP2P === isPostDenialP2P
        );
      } else if (isAuditReview) {
        initializeReview(
          "AUDIT_REVIEW",
          auditReviewCreate,
          {},
          (review) => review.reviewType === "AuditReview" && review.reviewStatus === "DRAFT"
        );
      } else if (isNewHireAuditReview) {
        initializeReview(
          "NEW_HIRE_AUDIT_REVIEW",
          newHireAuditReviewCreate,
          {},
          (review) => review.reviewType === "NewHireAuditReview" && review.reviewStatus === "DRAFT"
        );
      } else if (isOutOfScopeAuditReview) {
        initializeReview(
          "DX_OOS_AUDIT_REVIEW",
          outOfScopeAuditReviewCreate,
          {},
          (review) => review.reviewType === "OutOfScopeAuditReview" && review.reviewStatus === "DRAFT"
        );
      } else if (isFaxAuditReview) {
        initializeReview(
          "FAX_AUDIT_REVIEW",
          faxAuditReviewCreate,
          {},
          (review) => review.reviewType === "FaxAuditReview" && review.reviewStatus === "DRAFT"
        );
      }
    }
  }, [
    nurseReviewCreate,
    outOfNetworkReviewCreate,
    mdReviewCreate,
    peerToPeerReviewCreate,
    auditReviewCreate,
    newHireAuditReviewCreate,
    faxAuditReviewCreate,
    autoCreatedReview,
    existingReviews,
    isCppReview,
    isNurseReview,
    isOutOfNetworkReview,
    isMdReview,
    isP2PReview,
    isPostDenialP2P,
    isAuditReview,
    isNewHireAuditReview,
    isFaxAuditReview,
    initializeReview,
    isOutOfScopeAuditReview,
    outOfScopeAuditReviewCreate,
  ]);

  useEffect(() => {
    if (nurseReviewCreateError) {
      const errorDetails = extractErrorDetails(nurseReviewCreateError, serviceRequestId, "");
      enqueueSnackbar(`Failed to create review: ${errorDetails.message}`, { variant: "error" });
    }
    if (outOfNetworkReviewCreateError) {
      const errorDetails = extractErrorDetails(outOfNetworkReviewCreateError, serviceRequestId, "");
      enqueueSnackbar(`Failed to create review: ${errorDetails.message}`, { variant: "error" });
    }
    if (mdReviewCreateError) {
      const errorDetails = extractErrorDetails(mdReviewCreateError, serviceRequestId, "");
      enqueueSnackbar(`Failed to create review: ${errorDetails.message}`, { variant: "error" });
    }
    if (peerToPeerReviewCreateError) {
      const errorDetails = extractErrorDetails(peerToPeerReviewCreateError, serviceRequestId, "");
      enqueueSnackbar(`Failed to create review: ${errorDetails.message}`, { variant: "error" });
    }
    if (auditReviewCreateError) {
      const errorDetails = extractErrorDetails(auditReviewCreateError, serviceRequestId, "");
      enqueueSnackbar(`Failed to create review: ${errorDetails.message}`, { variant: "error" });
    }
    if (newHireAuditReviewCreateError) {
      const errorDetails = extractErrorDetails(auditReviewCreateError, serviceRequestId, "");
      enqueueSnackbar(`Failed to create review: ${errorDetails.message}`, {
        variant: "error",
        persist: true,
      });
    }
    if (outOfScopeAuditReviewError) {
      const errorDetails = extractErrorDetails(outOfScopeAuditReviewError, serviceRequestId, "");
      enqueueSnackbar(`Failed to create review: ${errorDetails.message}`, {
        variant: "error",
      });
    }
    if (faxAuditReviewError) {
      const errorDetails = extractErrorDetails(faxAuditReviewError, serviceRequestId, "");
      enqueueSnackbar(`Failed to create review: ${errorDetails.message}`, {
        variant: "error",
      });
    }
  }, [
    enqueueSnackbar,
    nurseReviewCreateError,
    outOfNetworkReviewCreateError,
    mdReviewCreateError,
    peerToPeerReviewCreateError,
    auditReviewCreateError,
    newHireAuditReviewCreateError,
    outOfScopeAuditReviewError,
    serviceRequestId,
    faxAuditReviewError,
  ]);

  const loadingCurrentReview =
    nurseReviewCreateLoading ||
    outOfNetworkReviewCreateLoading ||
    mdReviewCreateLoading ||
    peerToPeerReviewCreateLoading ||
    auditReviewCreateLoading ||
    newHireAuditReviewLoading ||
    outOfScopeAuditReviewLoading ||
    faxAuditReviewLoading;

  return {
    currentReview,
    openWarningModal,
    loadingCurrentReview,
    setOpenWarningModal,
    isReviewEditMode,
  };
};

export { useInitReview };
