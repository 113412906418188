import { useMemo } from "react";
import { useLocation } from "react-router";

const useSearchAndLocation = () => {
  const location = useLocation();
  const search = useMemo(() => new URLSearchParams(location.search), [location]);

  return { search, location };
};

export default useSearchAndLocation;
