import Container from "@material-ui/core/Container";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { EditWithAssessmentContext } from "components/ClinicalAssessment";
import { useMuiContainerStyles } from "@coherehealth/common";
import RequestorCard from "components/Requestor/RequestorCard";
import { AuthBuilderRequestorProps } from "components/AuthBuilder/index";
import LoadingAssessmentModal from "./LoadingAssessmentModal";

interface Props extends AuthBuilderRequestorProps {
  loadingCarePathClinicalAssessment: boolean;
  loadingSingleServiceClinicalAssessment: boolean;
  hideRequestorCard?: boolean;
}

export default function ClinicalAssessment({
  loadingCarePathClinicalAssessment,
  loadingSingleServiceClinicalAssessment,
  requestorFormAuthorized,
  hideRequestorCard,
  ...requestorProps
}: Props) {
  const containerClasses = useMuiContainerStyles();

  // TODO style loading icon (maybe talk to design and see if they want something other than a spinner)
  return (
    <Wrapper classes={containerClasses} maxWidth="lg">
      {!hideRequestorCard && requestorFormAuthorized && <RequestorCard {...requestorProps} />}
      <LoadingAssessmentModal open={loadingSingleServiceClinicalAssessment || loadingCarePathClinicalAssessment} />
      {!loadingSingleServiceClinicalAssessment && !loadingCarePathClinicalAssessment && <EditWithAssessmentContext />}
    </Wrapper>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Wrapper = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(5),
}));
