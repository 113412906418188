export const getReviewOutcomeTitle = (reviewOutcome: string) => {
  switch (reviewOutcome) {
    case "APPROVED":
      return "Approved";
    case "WITHDRAWN":
      return "Withdrawn";
    case "PENDING_ADMIN_VOID":
      return "Pending admin void";
    case "PENDING_MISSING_CLINICAL_INFO":
      return "Pending missing info outreach";
    case "PENDING_NUDGE_OUTREACH":
      return "Pending nudge outreach";
    case "PENDING_MD_REVIEW":
      return "Pending MD review";
    case "PENDING_RN_REVIEW":
      return "Pending clinical review";
    case "PARTIALLY_APPROVED":
      return "Partially Approved";
    case "RECOMMENDED_PARTIAL_APPROVAL":
      return "Recommended Partial Approval";
    case "RECOMMENDED_DENIAL":
      return "Recommended Denial";
    case "DENIED":
      return "Denied";
  }
};

export const parseMillisecondsIntoReadableTime = (milliseconds: number) => {
  const hours = milliseconds / (1000 * 60 * 60);
  const absoluteHours = Math.floor(hours);
  const h = absoluteHours > 9 ? absoluteHours : "0" + absoluteHours;
  const minutes = (hours - absoluteHours) * 60;
  const absoluteMinutes = Math.floor(minutes);
  const m = absoluteMinutes > 9 ? absoluteMinutes : "0" + absoluteMinutes;
  const seconds = (minutes - absoluteMinutes) * 60;
  const absoluteSeconds = Math.floor(seconds);
  const s = absoluteSeconds > 9 ? absoluteSeconds : "0" + absoluteSeconds;
  return h + ":" + m + ":" + s;
};
