import { Dispatch, SetStateAction, useState } from "react";
import ClinicalReviewCard from "common/ClinicalReviewCard";
import { Box, Grid, useTheme, CircularProgress } from "@material-ui/core";
import {
  H5,
  Body1,
  incrementClickCount,
  UserClickInfoClinicalReviewPage,
  useFeature,
  CohereTable,
  Caption,
  H6,
} from "@coherehealth/common";
import {
  AuthorizationResponse,
  AuthorizationsWithCountResponse,
  RequestType,
  ServiceRequestResponse,
} from "@coherehealth/core-platform-api";
import { sortServiceRequestByDateField, splitServiceRequests } from "util/serviceRequest";
import ServiceRequestTable from "../ServiceRequestTable";
import { UseOtherAuthorizationProps } from "./useOtherAuthorization";
import { StyledBody1 } from "../ServiceRequestInformation";
import { CustomDivider, useOtherSRsStyles } from "./otherServiceRequestsStyles";
import { OtherServiceRequestsFilter } from "./OtherServiceRequestsFilter";

interface OtherServiceRequestsProps extends UseOtherAuthorizationProps {
  otherServiceRequests: ServiceRequestResponse[] | null;
  otherServiceRequestsLoading: boolean;
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
  viewOnly: boolean | undefined;
  crrDenials: boolean | undefined;
}

const OtherServiceRequests = ({
  otherServiceRequests,
  otherServiceRequestsLoading,
  otherAuthsProps,
  setUserClickInfoTracking,
}: OtherServiceRequestsProps) => {
  const {
    otherAuthorizationsResponse,
    otherAuthorizationsLoading,
    otherAuthorizationTableColumns,
    otherAuthorizationRowChildTableColumns,
    paginateParams,
    setPaginatedParams,
  } = otherAuthsProps;
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { journeys, singleServiceRequests } = splitServiceRequests(otherServiceRequests || [], true);
  const { resultList: authorizations, totalCount } =
    (otherAuthorizationsResponse as AuthorizationsWithCountResponse) || {};
  const groupOtherSrsByAuthorization = useFeature("authGroupingForOtherSrs");
  const logrocketUnmasking = useFeature("logrocketUnmasking");

  const updateUserClickInfo = () => {
    setUserClickInfoTracking && incrementClickCount("OtherServiceRequestsCardToggle", "", -1, setUserClickInfoTracking);
  };

  const rowExpansion = ({ serviceRequests }: AuthorizationResponse) => {
    if (!serviceRequests || serviceRequests.length === 1) {
      return undefined;
    }
    const sortedSrs =
      (sortServiceRequestByDateField("dateCreated", serviceRequests || []) as ServiceRequestResponse[]) || [];

    const initialSr = sortedSrs.find((sr) => sr.requestType === "INITIAL");
    const continuations = sortedSrs.filter((sr) => sr.requestType === "CONTINUATION");
    return (
      <div style={{ backgroundColor: "#F5F5F5", borderLeft: "4px solid #039EC3" }}>
        <OtherAuthorizationRowChildTable serviceRequests={initialSr ? [initialSr] : []} requestType="INITIAL" />
        <OtherAuthorizationRowChildTable serviceRequests={continuations} requestType="CONTINUATION" />{" "}
      </div>
    );
  };

  const OtherAuthorizationRowChildTable = ({
    serviceRequests,
    requestType,
  }: {
    serviceRequests: ServiceRequestResponse[];
    requestType: RequestType;
  }) => {
    const hideDividers = [0];
    return (
      <Box>
        <CustomDivider>
          <Caption style={{ fontFamily: "Gilroy-Medium" }}>
            {requestType === "INITIAL" ? "INITIAL" : "CONTINUATIONS"}
          </Caption>
        </CustomDivider>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <CohereTable<ServiceRequestResponse>
              tableColumns={otherAuthorizationRowChildTableColumns}
              data={serviceRequests}
              hideRowHoverBorderColor={true}
              cardHeight={"short"}
              readonly={true}
              omitHeader
              hideRowDividers={hideDividers}
              hideFinalDivider={true}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const RESULTS_PER_PAGE = [{ id: "5", label: "5" }];
  const classes = useOtherSRsStyles();

  return (
    <>
      {/* Remove the else condition whenever we remove groupOtherSrsByAuthorization FF */}
      {groupOtherSrsByAuthorization ? (
        <ClinicalReviewCard
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          updateUserClickInfo={updateUserClickInfo}
          header={
            <Box display="flex" flexDirection="column">
              <H5 data-public={!!logrocketUnmasking ? true : undefined}>Other Service Requests</H5>
              {otherAuthorizationsLoading ? (
                <CircularProgress size={15} />
              ) : (
                <Body1 className={classes.body1}>{totalCount || 0} other service requests</Body1>
              )}
            </Box>
          }
          dividerContainerMargin={"0px"}
          regionContainerPadding={"0px 16px"}
        >
          {isExpanded && (
            <>
              <Grid style={{ padding: theme.spacing(1.5, 0, 0, 1) }}>
                <OtherServiceRequestsFilter otherAuthsProps={otherAuthsProps} />
              </Grid>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ width: "100%", minHeight: "30vh" }}
                data-testid="other-service-req-card"
              >
                {otherAuthorizationsLoading ? (
                  <CircularProgress />
                ) : authorizations?.length === 0 ? (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ marginTop: 20, marginBottom: 20 }}
                  >
                    <Body1 style={{ color: theme.palette.text.secondary }}>
                      There are no other Service Requests for this patient.
                    </Body1>
                  </Grid>
                ) : (
                  <>
                    {!!authorizations?.length && (
                      <CohereTable<AuthorizationResponse>
                        tableColumns={otherAuthorizationTableColumns || []}
                        data={authorizations || []}
                        hideRowHoverBorderColor={true}
                        cardHeight={"short"}
                        readonly={true}
                        rowExpansion={rowExpansion}
                        paginateParams={paginateParams}
                        setPaginateParams={setPaginatedParams}
                        displayMax={paginateParams.max ? paginateParams.max - 1 : 5}
                        totalCount={totalCount}
                        paginateViaParams
                        RESULTS_PER_PAGE={RESULTS_PER_PAGE}
                        hideRowPerPageDropDown={true}
                        headerMargin="16px 0px 16px 0px"
                      />
                    )}
                  </>
                )}
              </Grid>
            </>
          )}
        </ClinicalReviewCard>
      ) : (
        <ClinicalReviewCard
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          updateUserClickInfo={updateUserClickInfo}
          header={
            <Box display="flex" flexDirection="column">
              <H5 data-public={!!logrocketUnmasking ? true : undefined}>Other Service Requests</H5>
              {otherServiceRequestsLoading && <CircularProgress size={15} />}
              {!otherServiceRequestsLoading && (
                <StyledBody1>{otherServiceRequests?.length || 0} other service requests</StyledBody1>
              )}
            </Box>
          }
        >
          {isExpanded && (
            <Grid container>
              {otherServiceRequestsLoading ? (
                <CircularProgress />
              ) : otherServiceRequests?.length === 0 ? (
                <Grid container justifyContent="center" alignItems="center" style={{ marginTop: 20, marginBottom: 20 }}>
                  <Body1 style={{ color: theme.palette.text.secondary }}>
                    There are no other Service Requests for this patient.
                  </Body1>
                </Grid>
              ) : (
                <>
                  {journeys.map((journey) => (
                    <Grid key={journey.id} item xs={12}>
                      <Box mb={2}>
                        <H6 className={classes.serviceRequestPath} color="textPrimary">
                          {journey.serviceRequests[0]?.carePathName}
                        </H6>
                        <ServiceRequestTable serviceRequests={journey.serviceRequests} />
                      </Box>
                    </Grid>
                  ))}
                  {singleServiceRequests.length > 0 && (
                    <Grid item xs={12}>
                      <Box mb={2}>
                        <H6 className={classes.serviceRequestPath} color="textPrimary">
                          Other procedures
                        </H6>
                        <ServiceRequestTable serviceRequests={singleServiceRequests} />
                      </Box>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          )}
        </ClinicalReviewCard>
      )}
    </>
  );
};

export default OtherServiceRequests;
