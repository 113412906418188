import React, { Dispatch } from "react";
import { CaseStatus, ServiceCase, User as QMAgent } from "@coherehealth/qm-api";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import {
  Body1,
  Caption,
  Chip,
  formatDateStrWithCustomFormat,
  H5,
  SelectOptionsHook,
  SingleSelect,
  caseTypeLabel,
  commaSeparatedStrings,
} from "@coherehealth/common";
import { KeyboardArrowDown } from "@material-ui/icons";

interface IProps {
  serviceRequest?: ServiceRequestResponse | null;
  serviceCase: ServiceCase | null | undefined;
  isChipVisible?: boolean;
}

interface ChipProps {
  selectedAgent: QMAgent | null;
  showDropdown: boolean;
  setShowDropdown: Dispatch<React.SetStateAction<boolean>>;
  handleChipClick: () => void;
  useUsers: SelectOptionsHook<QMAgent>;
  handleOnSelect: (option: QMAgent | null) => void;
  disableCaseChanges: boolean;
  updatingServiceCase?: boolean;
}

type ConditionalChipProps =
  | ({ isChipVisible?: false } & IProps & { [property in keyof ChipProps]+?: never })
  | ({ isChipVisible: true } & IProps & ChipProps);

export default function SupervisorQueueView({
  serviceRequest,
  serviceCase,
  selectedAgent,
  isChipVisible,
  showDropdown,
  setShowDropdown,
  handleChipClick,
  useUsers,
  handleOnSelect,
  updatingServiceCase,
  disableCaseChanges,
}: ConditionalChipProps) {
  const { encounterType, requestTiming, semanticProcedureCodes } = serviceCase?.serviceRequest || {};
  const isUnplannedInpatientRequest =
    encounterType === "INPATIENT" && (requestTiming === "CONCURRENT" || requestTiming === "POST_SERVICE");
  return (
    <Grid container spacing={5}>
      <Grid
        item
        container
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "30px",
          marginTop: "30px",
        }}
      >
        <Box display="flex" flexWrap="wrap" mr="10px" maxWidth={isChipVisible ? "370px" : "100%"}>
          <H5 style={{ marginLeft: "24px", top: "40px" }}>
            Tracking {serviceRequest ? "# " + serviceRequest.cohereId : "N/A"}
          </H5>
        </Box>
        {isChipVisible && (
          <Box marginRight="24px" width="32%" display="flex" justifyContent="flex-end">
            {updatingServiceCase ? (
              <CircularProgress size={24} />
            ) : !showDropdown ? (
              <Chip
                clickable
                label={serviceCase?.assignee?.name || "Unassigned"}
                type={disableCaseChanges ? "neutral" : !serviceCase?.assignee?.name ? "neutral" : "default"}
                size="small"
                onClick={handleChipClick}
                Icon={disableCaseChanges ? undefined : KeyboardArrowDown}
                disabled={disableCaseChanges}
              />
            ) : (
              <SingleSelect
                clearable
                fullWidth
                markSelectedOptions={false}
                placeholder="Search agents"
                useOptions={useUsers}
                selectedValue={selectedAgent}
                getOptionLabel={(option: QMAgent) => option.name || ""}
                setSelectedValue={handleOnSelect}
                openOnFocus
                onClose={() => setShowDropdown(false)}
                TextFieldProps={{ autoFocus: true, style: { height: "40px" } }}
                disabled={disableCaseChanges}
              />
            )}
          </Box>
        )}
      </Grid>
      <Grid item xs={4} style={{ marginLeft: "24px", padding: "30px", marginTop: "-30px" }}>
        <Caption>Request date</Caption>
        <Body1>{formatDateStrWithCustomFormat(serviceCase?.dateCreated, "MM/dd/yyyy - hh:mma") || "- -"}</Body1>
      </Grid>
      {serviceCase?.dateCreated && (
        <Grid item xs={4} style={{ marginLeft: "-15px", padding: "30px", marginTop: "-30px" }}>
          <Caption>Work due</Caption>
          <Body1>{formatDateStrWithCustomFormat(serviceCase.dueDate, "MM/dd/yyyy - hh:mma") || "- -"}</Body1>
        </Grid>
      )}
      {serviceCase && (
        <>
          <Grid item xs={4} style={{ marginLeft: "-15px", padding: "30px", marginTop: "-30px" }}>
            <Caption>Case status</Caption>
            <Body1>{serviceCase.caseStatus ? caseStatusLabel(serviceCase.caseStatus) : "- -"}</Body1>
          </Grid>
          <Grid item xs={4} style={{ marginLeft: "24px", padding: "30px", marginTop: "-30px" }}>
            <Caption>Case type</Caption>
            <Body1>{serviceCase.caseType ? caseTypeLabel(serviceCase.caseType) : "- -"}</Body1>
          </Grid>
          {isUnplannedInpatientRequest ? (
            <Grid item xs={4} style={{ marginLeft: "-15px", padding: "30px", marginTop: "-30px" }}>
              <Caption>Auth category</Caption>
              <Body1>{serviceRequest?.authCategory?.name || "- -"}</Body1>
            </Grid>
          ) : semanticProcedureCodes?.length && semanticProcedureCodes?.length > 0 ? (
            <Grid item xs={4} style={{ marginLeft: "-15px", padding: "30px", marginTop: "-30px" }}>
              <Caption>Skill category</Caption>
              <Body1>{serviceCase?.requiredSkills ? commaSeparatedStrings(serviceCase?.requiredSkills) : "- -"}</Body1>
            </Grid>
          ) : null}
          <Grid item xs={4} style={{ marginLeft: "-15px", padding: "30px", marginTop: "-30px" }}>
            <Caption>Case ID</Caption>
            <Body1>{serviceCase.caseNumber || "- -"}</Body1>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export const caseStatusLabel = (caseStatus: CaseStatus) => {
  return caseStatus.slice(0, 1) + caseStatus.slice(1).toLowerCase().replaceAll("_", " ");
};
