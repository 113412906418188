import React, { SVGProps } from "react";
import { Tooltip } from "@coherehealth/common";

export default function CopyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <Tooltip
      style={{ cursor: "auto" }}
      title="This was filled by an automatic scan of the fax"
      placement="bottom"
      arrow
    >
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="16" fill="#039EC3" />
        <path
          d="M21.66 13.53L14.59 20.6L10.35 16.36L11.76 14.95L14.59 17.78L20.25 12.12L21.66 13.53ZM8 16C8 13.67 9.02 11.58 10.62 10.12L13 12.5V6.50005H7L9.2 8.70005C7.24 10.52 6 13.11 6 16C6 21.19 9.95 25.4501 15 25.9501V23.93C11.06 23.4401 8 20.07 8 16ZM26 16C26 10.81 22.05 6.55005 17 6.05005V8.07005C20.94 8.56005 24 11.93 24 16C24 18.33 22.98 20.42 21.38 21.88L19 19.5V25.5H25L22.8 23.3C24.76 21.48 26 18.89 26 16Z"
          fill="white"
        />
      </svg>
    </Tooltip>
  );
}
