import { ReferralRequestResponse, useUpdateReferralRequest } from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { PrimaryButton, TextField } from "@coherehealth/common";

interface Props {
  referralRequest: ReferralRequestResponse;
  onEdit: () => void;
}

const useStyles = makeStyles(() => ({
  CenteredGridContainer: {
    alignItems: "center",
  },
  Centered: {
    textAlign: "center",
  },
}));

export default function ReferralsAuthNumberEntryForm({ referralRequest, onEdit }: Props) {
  const { mutate, error, loading } = useUpdateReferralRequest({ id: referralRequest.id });
  const { enqueueSnackbar } = useSnackbar();
  const [authNumber, setAuthNumber] = useState<string>(referralRequest?.authNumber || "");

  const classes = useStyles();

  useEffect(() => {
    if (error) {
      enqueueSnackbar(`Error saving changes: ${error.message}`, { variant: "error" });
    }
  }, [error, enqueueSnackbar]);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await mutate({
      authNumber: authNumber,
      manualAuthNumberEntry: true,
    });
    onEdit();
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid className={classes.CenteredGridContainer} container direction="row" spacing={6}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Auth number"
            value={authNumber}
            onChangeValue={(num) => setAuthNumber(num)}
            data-testid="auth-number-input"
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.Centered}>
            <PrimaryButton
              type="submit"
              disabled={loading || authNumber === ""}
              loading={loading}
              data-testid="auth-number-submit"
            >
              Save changes
            </PrimaryButton>
          </div>
        </Grid>
      </Grid>
    </form>
  );
}
