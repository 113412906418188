import { Card } from "../Card";
import { makeStyles } from "@material-ui/core";
import React, { PropsWithChildren } from "react";
import { colorsLight } from "../../themes";
import { useTheme } from "@material-ui/core/styles";

interface Props {
  selected?: boolean;
  icon: JSX.Element;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}
export default function NavigationButton({ selected = false, icon, onClick, children }: PropsWithChildren<Props>) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card className={`${classes.card} ${selected ? "selected" : ""}`} component={"button"} onClick={onClick}>
      <div style={{ alignSelf: "center", marginRight: theme.spacing(2) }}>{icon}</div>
      <div className={classes.container}>{children}</div>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  card: {
    margin: "5px",
    borderRadius: "8px",
    padding: theme.spacing(3),
    width: "424px", //TODO made button more squishy?
    boxShadow: `inset 0 0 0 1px ${colorsLight.gray.divider}`,
    display: "flex",
    "&.selected": {
      boxShadow: `inset 0 0 0 2px ${colorsLight.primary.main}`,
    },
    "&:hover .selected": {
      boxShadow: `inset 0 0 0 2px ${colorsLight.primary.main}`,
    },
    "&:hover": {
      cursor: "pointer",
      boxShadow: "0px 4px 13px rgba(0,0,0,0.08)",
      borderColor: theme.palette.primary.main,
    },
  },
  container: {
    alignItems: "center",
    display: "flex",
    justifyContent: "left",
    padding: theme.spacing(0, 0, 0, 0),
  },
}));
