import { Model, Factory, Server } from "miragejs";

const models = {
  tag: Model,
};
const factories = {
  tag: Factory.extend({
    name(i: number) {
      return `Tag ${i}`;
    },
  }),
};

const scenarios = {
  standard(server: Server) {
    server.createList("tag", 100);
  },
};

const activeScenario = "standard";
//const activeScenario = "short";
//const activeScenario = "empty";

export { models, factories, scenarios, activeScenario };
