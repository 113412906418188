import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Modal, PrimaryButton } from "@coherehealth/common";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import PartialUnitsApproval from "components/ServiceRequestStatusDisplay/PartialApproval/PartialUnitsApproval";

const useModalStyles = makeStyles({
  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 750,
    },
  },
});

interface Props {
  isReadonly?: boolean;
  loading: boolean;
  isOpen: boolean;
  setIsOpen(arg0: boolean): void;
  onFinalizeDenial: (event: any) => Promise<void>;
  serviceRequest: ServiceRequestResponse;
}

export default function PartiallyApproveServiceRequestModal({
  isReadonly,
  loading,
  isOpen,
  setIsOpen,
  onFinalizeDenial,
  serviceRequest,
}: Props) {
  const [approvedUnits, setApprovedUnits] = useState<number>(serviceRequest.approvedUnits || 0);

  const modalClasses = useModalStyles();

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      classes={modalClasses}
    >
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        <Grid item>
          {Boolean(serviceRequest.units) && (
            <PartialUnitsApproval
              isReadonly={isReadonly}
              requestedUnits={serviceRequest.units || 0}
              approvedUnits={approvedUnits}
              setApprovedUnits={setApprovedUnits}
              healthPlanName={serviceRequest.healthPlanName || ""}
            />
          )}
        </Grid>
        <Grid item>
          <PrimaryErrorButton loading={loading} onClick={onFinalizeDenial}>
            Finalize Partial Approval
          </PrimaryErrorButton>
        </Grid>
      </Grid>
    </Modal>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PrimaryErrorButton = styled(PrimaryButton)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  "&:hover,&:focus": {
    backgroundColor: theme.palette.error.main,
  },
}));
