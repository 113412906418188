import React from "react";
import HeaderContainer from "components/AppHeader/HeaderContainer";
import { H3 } from "@coherehealth/common";
import { headerHeight } from "util/StyleConstants";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";

export default function Header() {
  return (
    <HeaderContainer height={headerHeight}>
      <RelativeContainer>
        <Title>
          <RightButton>New Service Request</RightButton>
        </Title>
      </RelativeContainer>
    </HeaderContainer>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Title = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RightButton = styled(H3)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RelativeContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  height: "100%",
  width: "100%",
});
