import { formatDateStr, dateStringSort, colorsLight, Tooltip } from "@coherehealth/common";
import { UserResponse, useApprovePendingOrgUser, useDenyPendingOrgUser } from "@coherehealth/core-platform-api";
import { PrimaryButton, CohereTable } from "@coherehealth/common";
import { User } from "@sentry/react";
import { useAuthorized } from "authorization";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import AdmitMemberModal from "./AdmitMemberModal";
import { GetEmailWrapper, GetNameWrapper, GetPhoneNumberColumnWrapper } from "./ProviderOrgUsers";

interface IUnverifiedOrgUsersTableProps {
  users: UserResponse[];
  refetchPendingUsers: () => Promise<unknown>;
  refetchUsers: () => Promise<unknown>;
  isReadOnlyView?: boolean;
}

interface IUnverifiedOrgUserProps {
  user: UserResponse;
  canReviewAndEditUnverifiedUsers: boolean;
  approvingPendingUser: boolean;
  denyingPendingUser: boolean;
  approvePendingMember: (user: User) => Promise<void>;
  denyPendingMember: (user: User) => Promise<void>;
  isReadOnlyView?: boolean;
}

const useStyles = makeStyles((theme) => ({
  dividerBorder: {
    borderColor: colorsLight.gray.divider,
  },
  switchLabel: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: theme.spacing(1),
    width: "100%",
  },
  switchLabelPlacementStartOverrides: {
    marginLeft: 0,
  },
  tableColumn: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  actionsMenu: { display: "flex", alignItems: "center", justifyContent: "center" },
  newUserChip: { marginLeft: theme.spacing(1.5) },
  statusChip: { padding: theme.spacing(1.5, 2) },
  styledBodyContainer: {
    width: "80%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

export function UnverifiedProviderOrgUsersTable({
  users,
  refetchPendingUsers,
  refetchUsers,
  isReadOnlyView,
}: Readonly<IUnverifiedOrgUsersTableProps>) {
  const canReviewAndEditUnverifiedUsers = useAuthorized("CAN_REVIEW_AND_EDIT_UNVERIFIED_USERS");
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: approvePendingUser,
    loading: approvingPendingUser,
    error: approvePendingUserError,
  } = useApprovePendingOrgUser({
    id: "",
  });

  const approvePendingMember = useCallback(
    async (user: User) => {
      await approvePendingUser({ ...user }, { pathParams: { id: user.id ?? "" } });
      await refetchPendingUsers();
      await refetchUsers();
    },
    [approvePendingUser, refetchPendingUsers, refetchUsers]
  );

  useEffect(() => {
    if (approvePendingUserError) {
      enqueueSnackbar(`There was an error approving this user ${approvePendingUserError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, approvePendingUserError]);

  const {
    mutate: denyPendingUser,
    loading: denyingPendingUser,
    error: denyPendingUserError,
  } = useDenyPendingOrgUser({
    id: "",
  });

  const denyPendingMember = useCallback(
    async (user: User) => {
      await denyPendingUser({}, { pathParams: { id: user.id ?? "" } });
      await refetchPendingUsers();
    },
    [denyPendingUser, refetchPendingUsers]
  );

  useEffect(() => {
    if (denyPendingUserError) {
      enqueueSnackbar(`There was an error removing this user from the org ${denyPendingUserError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, denyPendingUserError]);

  const styleClasses = useStyles();
  const tableColumns = useMemo(
    () => [
      {
        name: "",
        width: "260px",
        themedPaddingRight: 0,
        value: (user: UserResponse) => GetNameWrapper(user, styleClasses),
      },
      {
        name: "",
        width: "250px",
        themedPaddingRight: 3,
        unSortable: true,
        value: (user: UserResponse) => GetEmailWrapper(user, styleClasses),
      },
      {
        name: "",
        width: "350px",
        themedPaddingRight: 0,
        unSortable: true,
        value: (user: UserResponse) => GetPhoneNumberColumnWrapper(user, styleClasses),
      },
      {
        name: "",
        width: "190px",
        themedPaddingRight: 0,
        value: (user: UserResponse) => (user.dateCreated ? formatDateStr(user.dateCreated) : ""),
        sort: (a: UserResponse, b: UserResponse, sortingAscending: boolean) =>
          dateStringSort(a.dateCreated ?? "", b.dateCreated ?? "", sortingAscending),
      },
      {
        name: "",
        width: "190px",
        themedPaddingRight: 0,
        value: (user: UserResponse) => (
          <VerifyButton
            user={user}
            canReviewAndEditUnverifiedUsers={canReviewAndEditUnverifiedUsers}
            approvingPendingUser={approvingPendingUser}
            denyingPendingUser={denyingPendingUser}
            approvePendingMember={approvePendingMember}
            denyPendingMember={denyPendingMember}
            isReadOnlyView={isReadOnlyView}
          />
        ),
      },
    ],
    [
      approvePendingMember,
      approvingPendingUser,
      canReviewAndEditUnverifiedUsers,
      denyPendingMember,
      denyingPendingUser,
      isReadOnlyView,
      styleClasses,
    ]
  );

  return (
    <CohereTable<UserResponse>
      hideFinalDivider={true}
      show3linesAndThenEllipsis
      tableColumns={tableColumns}
      omitHeader={true}
      data={users}
      showWarningBorder
      dataPublic
    />
  );
}

const VerifyButton = ({
  user,
  canReviewAndEditUnverifiedUsers,
  approvingPendingUser,
  denyingPendingUser,
  approvePendingMember,
  denyPendingMember,
  isReadOnlyView,
}: Readonly<IUnverifiedOrgUserProps>) => {
  const [admitUserModalOpen, setAdmitUserModalOpen] = useState(false);
  const tooltipTitle = !canReviewAndEditUnverifiedUsers
    ? isReadOnlyView
      ? "Only admins can review unverified users. Contact your admins from the list below to get them verified."
      : "Only admins can review unverified members"
    : "";

  return (
    <Tooltip title={tooltipTitle} placement="top" data-public>
      <div style={{ display: "flex", height: "45px" }}>
        <PrimaryButton
          fullWidth
          style={{ padding: "0" }}
          onClick={() => {
            if (!admitUserModalOpen) {
              setAdmitUserModalOpen(true);
            }
          }}
          disabled={!canReviewAndEditUnverifiedUsers}
          data-public
        >
          Review
        </PrimaryButton>
        <AdmitMemberModal
          open={admitUserModalOpen}
          member={user}
          onClose={() => {
            setAdmitUserModalOpen(false);
          }}
          approvingPendingUser={approvingPendingUser}
          denyingPendingUser={denyingPendingUser}
          approvePendingMember={async (updatedUser: User) => {
            await approvePendingMember(updatedUser);
          }}
          denyPendingMember={async (updatedUser: User) => {
            await denyPendingMember(updatedUser);
          }}
        />
      </div>
    </Tooltip>
  );
};
