import { H3 } from "@coherehealth/common";
import { Grid, makeStyles } from "@material-ui/core";

interface NoticationBannerProps {
  icon?: JSX.Element;
  header?: string;
  contentComponent: JSX.Element;
  iconClassName?: string;
  contentClassName?: string;
  headerClassName?: string;
  bannerClassName?: string;
}

export function NoticationBanner(props: NoticationBannerProps): JSX.Element {
  const { bannerClassName, contentComponent, contentClassName, iconClassName, headerClassName, icon, header } = props;
  const classes = useStyle();
  return (
    <Grid className={`${bannerClassName} ${classes.bannerWrapper}`} container>
      {!!icon && (
        <Grid className={`${classes.icon} ${iconClassName}`} item xs={2}>
          {icon}
        </Grid>
      )}
      <Grid xs={!!icon ? 10 : 12} item>
        {!!header && (
          <Grid xs={12} item>
            <H3 className={`${classes.header} ${headerClassName}`}>{header}</H3>
          </Grid>
        )}
        <Grid className={contentClassName} xs={12} item>
          {contentComponent}
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyle = makeStyles((theme) => ({
  bannerWrapper: {
    backgroundColor: "#EAE9F4",
    borderRadius: 8,
    padding: theme.spacing(2.5),
  },
  header: {
    fontFamily: "Gilroy-SemiBold",
    fontSize: 24,
    fontWeight: 400,
    lineHeight: 26 / 24,
    letterSpacing: "0.15px",
    marginBottom: theme.spacing(1.5),
  },
  icon: {
    display: "flex",

    "& svg": {
      width: 40,
      margin: "0 auto",
      height: "min-content",
    },
  },
}));
