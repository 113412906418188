import { AutocompleteRenderOptionState } from "@material-ui/lab";
import { MultipleSelect, MultipleSelectAllProps } from "../Autocomplete";
import { BaseFilterConfig } from "./shared";

export interface LookupSearchFilterConfig<T> extends BaseFilterConfig {
  filterType: "LookupSearch";
  initialFilterValues?: unknown;
  isLazyLoaded?: boolean;
  useOptions: MultipleSelectAllProps<T>["useOptions"];
  optionToLabel?: (option: T) => string;
  renderOption?: ((option: T, state: AutocompleteRenderOptionState) => React.ReactNode) | undefined;
  optionToParamValue?: (option: T) => string | undefined | null;
}

export interface LookupSearchFilterProps {
  filterConfig: LookupSearchFilterConfig<unknown>;
  value: unknown[];
  onChange?: (key: string, newState: unknown[]) => void;
}

export function LookupSearchFilter({ filterConfig, value, onChange }: LookupSearchFilterProps) {
  const { key, label, isLazyLoaded, useOptions, optionToLabel, renderOption } = filterConfig;
  const handleChange: (option: unknown[]) => void = (selectedValue: unknown[]) => {
    if (onChange) {
      onChange(key, selectedValue);
    }
  };
  return (
    <MultipleSelect<unknown>
      label={label}
      selectedValue={value || []}
      useOptions={useOptions}
      getOptionLabel={optionToLabel}
      renderOption={renderOption}
      setSelectedValue={handleChange}
      isLazyLoaded={isLazyLoaded}
    />
  );
}
