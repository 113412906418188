import React, { SVGProps } from "react";

export default function WarningFlag(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="20" height="20" rx="10" fill="#CB7F00" />
      <path
        d="M11.6002 6.00008L11.3335 4.66675H5.3335V16.0001H6.66683V11.3334H10.4002L10.6668 12.6667H15.3335V6.00008H11.6002Z"
        fill="white"
      />
    </svg>
  );
}
