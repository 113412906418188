import React, { FunctionComponent } from "react";

import {
  Body2,
  SelectOptionsHook,
  SingleSelect,
  MultipleSelect,
  useLazyLoadingQueryOptionsHook,
  MiddleTruncateLabelWithEndornment,
} from "@coherehealth/common";
import { DiagnosisCode, useGetDiagnosisCodes } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";

import { ApprovedSREditWarning, ReplaceWarning } from "common/SharedServiceRequestFormComponents";

const useDiagnoses: SelectOptionsHook<DiagnosisCode> = (selectOptionsParams) =>
  useLazyLoadingQueryOptionsHook({
    useGetHook: useGetDiagnosisCodes,
    additionalQueryParams: { isBillable: true },
    ...selectOptionsParams,
  });

export const PrimaryDiagnosisCodeSelect: FunctionComponent<{
  error: boolean;
  label?: string;
  emptyLabel?: string;
  initialPrimaryDiagnosisCode?: DiagnosisCode | undefined;
  singleService?: boolean;
  primaryDiagnosisCode: DiagnosisCode | null;
  setPrimaryDiagnosisCode: (code: DiagnosisCode | null) => void;
  isCohereFaxForm?: boolean;
  showApprovedSrEditWarning?: boolean;
  onCancelChange?: () => void;
  isAutoFilled?: boolean;
  onContinuationPatientSummary?: boolean;
  disableForApprovedOutpatient?: boolean;
  dataPublic?: boolean;
  disabled?: boolean;
}> = ({
  error,
  label,
  emptyLabel,
  initialPrimaryDiagnosisCode,
  singleService,
  primaryDiagnosisCode,
  setPrimaryDiagnosisCode,
  isCohereFaxForm,
  showApprovedSrEditWarning,
  onCancelChange,
  isAutoFilled,
  onContinuationPatientSummary = false,
  disableForApprovedOutpatient = false,
  dataPublic = false,
  disabled,
}) => {
  const dataPublicProps = dataPublic ? { "data-public": true } : {};
  const helperText = error ? (
    "Required"
  ) : showApprovedSrEditWarning ? (
    <ApprovedSREditWarning message={ReplaceWarning} onCancelChange={onCancelChange} />
  ) : (
    ""
  );

  return (
    <SingleSelect
      disablePortal
      autoFilledIcon={isCohereFaxForm || isAutoFilled}
      disabled={
        (Boolean(initialPrimaryDiagnosisCode) && singleService) ||
        onContinuationPatientSummary ||
        disableForApprovedOutpatient ||
        disabled
      }
      error={error}
      markSelectedOptions={false}
      helperText={helperText}
      TextFieldProps={{ warningHelperText: showApprovedSrEditWarning }}
      label={label || "Primary diagnosis"}
      emptyLabel={emptyLabel || ""}
      useOptions={useDiagnoses}
      getOptionLabel={({ code }) => `${code}`}
      renderOption={({ code, description }) => (
        <Body2Unbold>
          <b>{code}</b> - {description}
        </Body2Unbold>
      )}
      selectedValue={primaryDiagnosisCode}
      setSelectedValue={setPrimaryDiagnosisCode}
      {...dataPublicProps}
    />
  );
};

export const SecondaryDiagnosisCodeSelect: FunctionComponent<{
  error: boolean;
  disablePlaceholder?: boolean;
  label?: string;
  emptyLabel?: string;
  secondaryDiagnosisCodes: DiagnosisCode[];
  setSecondaryDiagnosisCodes: (code: DiagnosisCode[]) => void;
  isCohereFaxForm?: boolean;
  isOptional: boolean;
  isAutoFilled?: boolean;
  dataPublic?: boolean;
  disabled?: boolean;
}> = ({
  error,
  disablePlaceholder,
  label,
  emptyLabel,
  secondaryDiagnosisCodes,
  setSecondaryDiagnosisCodes,
  isCohereFaxForm,
  isOptional,
  isAutoFilled,
  dataPublic = false,
  disabled,
}) => {
  const getHelperText = () => {
    if (secondaryDiagnosisCodes.length > 9) {
      return "Max of 9 secondary diagnoses";
    } else {
      return "Required";
    }
  };
  const dataPublicProps = dataPublic ? { "data-public": true } : {};

  return (
    <MultipleSelect
      disablePortal
      placeholder={disablePlaceholder ? "" : "Enter another diagnosis code"}
      autoFilledIcon={isCohereFaxForm || isAutoFilled}
      error={error}
      helperText={error && getHelperText()}
      useOptions={useDiagnoses}
      label={`${label || "Secondary diagnosis"} ${isOptional ? "(optional)" : ""}`}
      reactLabel={
        <MiddleTruncateLabelWithEndornment
          leftLabel={label || "Secondary diagnosis"}
          rightLabel={isOptional ? "(optional)" : ""}
        />
      }
      emptyLabel={
        <MiddleTruncateLabelWithEndornment
          leftLabel={emptyLabel || "Search for secondary diagnosis codes"}
          rightLabel={isOptional ? "(optional)" : ""}
        />
      }
      getOptionLabel={({ code }) => `${code}`}
      renderOption={({ code, description }) => (
        <Body2Unbold>
          <b>{code}</b> - {description}
        </Body2Unbold>
      )}
      selectedValue={secondaryDiagnosisCodes}
      setSelectedValue={setSecondaryDiagnosisCodes}
      disabled={disabled}
      {...dataPublicProps}
    />
  );
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Body2Unbold = styled(Body2)({
  fontWeight: "normal",
});
