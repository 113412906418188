import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Grid } from "@material-ui/core";

import {
  H1,
  Card,
  Caption,
  PrimaryButton,
  colorsLight,
  SecondaryButton,
  TertiaryButton,
  useFeature,
  formatDateStrWithCustomFormat,
} from "@coherehealth/common";
import { OrganizationResponse, useDeleteOrganization, useUpdateOrganization } from "@coherehealth/core-platform-api";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuthorized } from "authorization";
import { isEqual, isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import routes from "routes";
import { isValidEmailDomainList, isZipcodeValid } from "util/providerUtils";

import ProviderOrgReadOnly from "./ProviderOrgReadOnly";
import ProviderOrgEdit from "./ProviderOrgEdit";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import OrgStatusChip from "../OrgStatusChip";
import PencilIcon from "@material-ui/icons/Create";

interface Props {
  orgInfo: OrganizationResponse | null;
  loading: boolean;
  refetchOrg: () => void;
  canEdit: boolean;
  canDelete: boolean;
}

/**
 * This will be a mutable copy of the organization info in state
 * We will make changes here when editing, and then patch to the server when the user saves.
 */
export interface OrgInfoInternalState {
  name: string;
  createdByName: string;
  tinList: Array<string>;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  status: string;
  specialties: string[];
  emailDomains: string[];
  emailAutoVerificationEnabled: boolean | undefined;
  healthPlans: string[];
}

type UpdatedOrgData = {
  name: string;
  tinList: Array<string>;
  address: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    zipCode: string;
  };
  specialties: string[];
  emailDomains: string[];
  emailAutoVerificationEnabled: boolean | undefined;
  healthPlans?: string[];
};

export const orgResponseToInternalState = (org: OrganizationResponse | null) => {
  return {
    name: org?.name || "",
    createdByName: org?.createdByName || "",
    tinList: org?.tinList || [],
    addressLine1: org?.address?.line1 || "",
    addressLine2: org?.address?.line2 || "",
    city: org?.address?.city || "",
    state: org?.address?.state || "",
    zipCode: org?.address?.zipCode || "",
    status: org?.status || "",
    specialties: org?.specialties || [],
    emailDomains: org?.emailDomains || [],
    emailAutoVerificationEnabled: org?.emailAutoVerificationEnabled,
    healthPlans: org?.healthPlans || [],
  };
};

export default function ProviderOrgInfo({ orgInfo, loading, refetchOrg, canEdit, canDelete }: Props) {
  const {
    mutate: patch,
    loading: submitting,
    error: submitOrgError,
  } = useUpdateOrganization({
    id: orgInfo?.id || "",
  });
  const { mutate: deleteOrg, loading: deleting, error: deleteOrgError } = useDeleteOrganization({});
  const onboardingRedesign = useFeature("onboardingRedesign");

  const canViewOrgMgmtPage = useAuthorized("ORGANIZATION_MANAGEMENT_PAGE");
  const navigate = useNavigate();

  const [editing, setEditing] = useState(false);
  const [orgInfoState, setOrgInfoState] = useState<OrgInfoInternalState>(orgResponseToInternalState(orgInfo));
  const [invalidEmailDomainList, setInvalidEmailDomainList] = useState<string[]>([]);

  const originalOrgInfo = useMemo(() => orgResponseToInternalState(orgInfo), [orgInfo]);
  const noChanges = isEqual(originalOrgInfo, orgInfoState);

  let disabled = false;

  if (
    noChanges ||
    isEmpty(orgInfoState.name) ||
    isEmpty(orgInfoState.specialties) ||
    isEmpty(orgInfoState.tinList) ||
    isEmpty(orgInfoState.addressLine1) ||
    isEmpty(orgInfoState.city) ||
    isEmpty(orgInfoState.state) ||
    isEmpty(orgInfoState.healthPlans) ||
    isEmpty(orgInfoState.zipCode) ||
    !isZipcodeValid(orgInfoState.zipCode) ||
    (orgInfoState.emailAutoVerificationEnabled &&
      (isEmpty(orgInfoState.emailDomains) || isValidEmailDomainList(orgInfoState.emailDomains, invalidEmailDomainList)))
  ) {
    disabled = true;
  }

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (submitOrgError) {
      enqueueSnackbar(`There was an error saving the organization information ${submitOrgError.message}`, {
        variant: "error",
      });
    }
    if (deleteOrgError) {
      enqueueSnackbar(`There was an error deleting the organization ${deleteOrgError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, submitOrgError, deleteOrgError]);

  const saveChanges = useCallback(async () => {
    const createOrgData = (orgInfoState: OrgInfoInternalState): UpdatedOrgData => {
      const {
        name,
        tinList,
        addressLine1,
        addressLine2,
        city,
        state,
        zipCode,
        specialties = [],
        emailDomains,
        emailAutoVerificationEnabled,
        healthPlans,
      } = orgInfoState;

      const orgData: UpdatedOrgData = {
        name,
        tinList,
        address: {
          line1: addressLine1,
          line2: addressLine2,
          city,
          state,
          zipCode,
        },
        specialties,
        healthPlans,
        emailDomains,
        emailAutoVerificationEnabled,
      };

      return orgData;
    };

    await patch(createOrgData(orgInfoState));

    setEditing(false);
    refetchOrg();
  }, [patch, orgInfoState, refetchOrg]);

  const deleteOrgAndRedirect = useCallback(async () => {
    if (orgInfo?.id) {
      await deleteOrg(orgInfo.id);
    } else {
      return;
    }
    if (canViewOrgMgmtPage) {
      navigate(routes.ORGANIZATION_MANAGEMENT_LIST);
    } else {
      navigate(routes.DASHBOARD);
    }
  }, [orgInfo, canViewOrgMgmtPage, deleteOrg, navigate]);

  const cancelChanges = useCallback(() => {
    // reset state and go to read-only
    setOrgInfoState(originalOrgInfo);
    setEditing(false);
  }, [setOrgInfoState, setEditing, originalOrgInfo]);
  const [deleteOrgModalOpen, setDeleteOrgModalOpen] = useState(false);
  const deleteButtonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <OrgCard>
      <HeaderContainer container>
        <Grid item xs>
          <SearchHeader data-public>
            {orgInfo?.name} <OrgStatusChip orgStatus={orgInfo?.status} data-public />
          </SearchHeader>
        </Grid>
        {canEdit && (
          <EditActionContainer item>
            <EditActionButtons cancelChanges={cancelChanges} editing={editing} setEditing={setEditing} data-public />
          </EditActionContainer>
        )}
        {orgInfo && (
          <CaptionContainer container>
            <GrayCaption data-public>
              Created on {formatDateStrWithCustomFormat(orgInfo?.dateCreated, "M/d/yyyy h:mm a")} by{" "}
              {orgInfo.createdByName}
            </GrayCaption>
            <GrayCaption data-public>
              Last modified on {formatDateStrWithCustomFormat(orgInfo?.lastUpdated, "M/d/yyyy h:mm a")} by{" "}
              {orgInfo.updatedByName ? orgInfo.updatedByName : orgInfo.createdByName}
            </GrayCaption>
          </CaptionContainer>
        )}
      </HeaderContainer>
      <HeaderBodyDivider />
      {loading && <CircularProgress />}
      {!loading &&
        (editing ? (
          <ProviderOrgEdit
            orgInfo={orgInfoState}
            setOrgInfo={setOrgInfoState}
            invalidEmailDomainList={invalidEmailDomainList}
            setInvalidEmailDomainList={setInvalidEmailDomainList}
            onboardingRedesign={onboardingRedesign}
          />
        ) : (
          <ProviderOrgReadOnly orgInfo={orgInfoState} onboardingRedesign={onboardingRedesign} />
        ))}
      {editing && (
        <SaveDeleteButtonContainer container>
          <SaveButton onClick={saveChanges} loading={submitting} disabled={disabled} data-public>
            Save
          </SaveButton>
          {canDelete && (
            <DeleteButton ref={deleteButtonRef} onClick={() => setDeleteOrgModalOpen(true)} warning data-public>
              <DeleteButtonContent>
                <DeleteButtonIcon data-public />
                Delete Organization
              </DeleteButtonContent>
            </DeleteButton>
          )}
        </SaveDeleteButtonContainer>
      )}
      <DeleteConfirmationModal
        open={deleteOrgModalOpen}
        handleClose={() => {
          setDeleteOrgModalOpen(false);
          if (deleteButtonRef.current) {
            // ugly behavior where canceling will still keep delete button focused
            setTimeout(() => deleteButtonRef.current?.blur(), 10);
          }
        }}
        onDelete={deleteOrgAndRedirect}
        deleting={deleting}
        subject="ORGANIZATION"
      />
    </OrgCard>
  );
}

interface EditActionButtonsProps {
  cancelChanges: () => void;
  editing: boolean;
  setEditing: (arg0: boolean) => void;
}

const EditActionButtons = ({ cancelChanges, editing, setEditing }: EditActionButtonsProps) => {
  return editing ? (
    <EditActionButtonContainer container>
      <SecondaryButton onClick={cancelChanges} warning>
        Cancel
      </SecondaryButton>
    </EditActionButtonContainer>
  ) : (
    <TertiaryButton startIcon={<PencilIcon />} style={{ padding: 0 }} onClick={() => setEditing(true)}>
      Edit
    </TertiaryButton>
  );
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const HeaderContainer = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const EditActionButtonContainer = styled(Grid)({
  justifyContent: "end",
});
// eslint-disable-next-line cohere-react/no-mui-styled-import
const EditActionContainer = styled(Grid)(({ theme }) => ({
  textAlign: "end",
  height: theme.spacing(7),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SaveDeleteButtonContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  gap: theme.spacing(3),
  marginTop: theme.spacing(3),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SaveButton = styled(PrimaryButton)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1),
  minWidth: theme.spacing(15),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(1.5),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DeleteButton = styled(TertiaryButton)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1),
  minWidth: theme.spacing(15),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DeleteButtonContent = styled("div")({
  display: "flex",
  alignItems: "center",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DeleteButtonIcon = styled(DeleteIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const HeaderBodyDivider = styled(Divider)(({ theme }) => ({
  width: theme.spacing(148),
  height: "1px",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const GrayCaption = styled(Caption)({
  color: colorsLight.font.light,
  alignSelf: "stretch",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const OrgCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  gap: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SearchHeader = styled(H1)(({ theme }) => ({
  marginRight: theme.spacing(5),
  marginBottom: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CaptionContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
  alignItems: "flex-start",
}));
