import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import Container from "@material-ui/core/Container";
import { headerHeight } from "../../../util/StyleConstants";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import HeaderContainer from "../../AppHeader/HeaderContainer";
import IntegrationListHeader from "./IntegrationListHeader";
import { MainContent, SearchHeader } from "../../ProviderOrganization/OrganizationList/ManageManyProviderOrgs";
import IntegrationTable from "./IntegrationTable";
import { SingleSelectDropdown, DateSelect, TextSearch, useMuiContainerStyles } from "@coherehealth/common";
import {
  IntegrationTypeOptions,
  IntegrationStatuses,
  KafkaIntegrationStatuses,
} from "../ServiceRequestIntegrationSummary/EditIntegrationStatus";
import {
  useSearchServiceRequestIntegrations,
  useSearchKafkaIntegration,
  IntegrationMarkIndicator,
  KafkaMessageDocumentResponse,
} from "@coherehealth/core-platform-api";
import { ServiceRequestESObject } from "./IntegrationTable";
import { IGNORE_ERRORS, error as logError } from "logger";

const IntegrationTypeInfrastructure: Record<string, string> = {
  payor: "Core-Platform",
  matrix: "Core-Platform",
  "mail-vendor-consumer": "Kafka",
  welltok: "Core-Platform",
  carewebqi: "Core-Platform",
  salesforce: "Core-Platform",
  fax_final_determination: "Core-Platform",
};

export default function ManageManyIntegrations() {
  const containerClasses = useMuiContainerStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [query, setQuery] = useState<string>("");
  const [integrationType, setIntegrationType] = useState<string>("payor");
  const [integrationStatus, setIntegrationStatus] = useState<string>("FAILED");
  const [kafkaIntegrationStatus, setKafkaIntegrationStatus] = useState<string>("FAILURE");
  const [dateMin, setDateMin] = useState<Date | null>(null);
  const [dateMax, setDateMax] = useState<Date | null>(null);
  const [serviceRequests, setServiceRequests] = useState<ServiceRequestESObject[]>();
  const [kafkaMessages, setKafkaMessages] = useState<KafkaMessageDocumentResponse[]>();
  const [rerunList, setRerunList] = useState<IntegrationMarkIndicator[]>([]);
  const { loading, error, mutate: searchServiceRequestIntegrations } = useSearchServiceRequestIntegrations({});
  const { mutate: searchKafkaIntegrations } = useSearchKafkaIntegration({});

  useEffect(() => {
    if (error) {
      enqueueSnackbar("Failed to get list of integrations, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [error, enqueueSnackbar]);

  const handleRefetch = (integrationType: string) => {
    if (integrationType === "mail-vendor-consumer") {
      searchKafkaIntegrations({
        integrationType,
        integrationStatus: kafkaIntegrationStatus,
        dateMin: dateMin?.toDateString() ?? "",
        dateMax: dateMax?.toDateString() ?? "",
        query,
        max: 10,
      }).then((response) => setKafkaMessages(response));
    } else {
      searchServiceRequestIntegrations({
        integrationType,
        integrationStatus,
        dateMin: dateMin?.toDateString() ?? "",
        dateMax: dateMax?.toDateString() ?? "",
        query,
        max: 100,
      })
        .then((response) => setServiceRequests(response))
        .catch((reason) => {
          const ignoreEntry = IGNORE_ERRORS.includes(reason.message ?? "");
          const msg = `Failed to fetch Service Request integrations`;
          console.error(msg, reason);
          if (!ignoreEntry) {
            logError(msg);
          }
        });
    }
  };

  useEffect(() => {
    handleRefetch(integrationType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateMax, dateMin, integrationStatus, kafkaIntegrationStatus, integrationType, query]);

  return (
    <Container classes={containerClasses} maxWidth="lg">
      <HeaderContainer height={headerHeight}>
        <IntegrationListHeader
          rerunList={rerunList}
          integrationInfrastructure={IntegrationTypeInfrastructure[integrationType || ""]}
          onRefetch={() => {
            handleRefetch(integrationType);
            setRerunList([]);
          }}
        />
      </HeaderContainer>
      <MainContent>
        <TextSearch
          fullWidth
          label="Search for an integration"
          value={query || ""}
          onChangeValue={(newInputValue) => {
            setQuery(newInputValue);
          }}
          loading={false}
          style={{ paddingBottom: 16 }}
        />
        <SearchHeader>Filter by :</SearchHeader>
        <Grid container spacing={2} justify="space-between" direction="row">
          <Grid item xs="auto">
            <SingleSelectDropdown
              label="Integration Type"
              value={integrationType}
              onChange={setIntegrationType}
              defaultValue={IntegrationTypeOptions.payor}
              style={{ width: "240px" }}
              options={Object.entries(IntegrationTypeOptions).map(([id, label]) => ({ id, label }))}
            />
          </Grid>
          <Grid item xs="auto">
            {IntegrationTypeInfrastructure[integrationType] === "Core-Platform" ? (
              <SingleSelectDropdown
                label="Integration Status"
                value={integrationStatus}
                onChange={setIntegrationStatus}
                defaultValue={IntegrationStatuses.FAILED}
                style={{ width: "240px" }}
                options={Object.entries(IntegrationStatuses).map(([id, label]) => ({ id, label }))}
              />
            ) : (
              <SingleSelectDropdown
                label="Integration Status"
                value={kafkaIntegrationStatus}
                onChange={setKafkaIntegrationStatus}
                defaultValue={KafkaIntegrationStatuses.FAILURE}
                style={{ width: "240px" }}
                options={Object.entries(KafkaIntegrationStatuses).map(([id, label]) => ({ id, label }))}
              />
            )}
          </Grid>
          <Grid item xs="auto">
            <DateSelect label="Start Date" onDateChange={setDateMin} value={dateMin} />
          </Grid>
          <Grid item xs="auto">
            <DateSelect label="End Date" onDateChange={setDateMax} value={dateMax} />
          </Grid>
        </Grid>
        {loading ? (
          <Grid container alignItems="center" justify="center">
            <CircularProgress />
          </Grid>
        ) : (
          <IntegrationTable
            serviceRequests={serviceRequests || []}
            kafkaMessages={kafkaMessages || []}
            integrationType={integrationType}
            rerunList={rerunList}
            setRerunList={setRerunList}
            integrationInfrastructure={IntegrationTypeInfrastructure[integrationType || ""]}
          />
        )}
      </MainContent>
    </Container>
  );
}
