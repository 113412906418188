import React, { useEffect } from "react";

import {
  AuthorizationResponse,
  ServiceRequestSearchResponse,
  useGetAuthorizationServiceSummaryPDF,
  useGetServiceSummaryPDF,
} from "@coherehealth/core-platform-api";
import useMemoDeepCompare from "util/useDeepMemo";
import { useBinaryFile } from "@coherehealth/common";
import { useSnackbar } from "notistack";

interface Error {
  name: string;
  message: string;
}

export interface PrintoutParams {
  error: Error | null;
  url: string | null | undefined;
  loading: boolean;
}

interface Props {
  authorization?: AuthorizationResponse;
  serviceRequest?: ServiceRequestSearchResponse | undefined;
  children: (params: PrintoutParams) => JSX.Element;
}

/**
 * Given a serviceRequest, this provider creates a details printout for it and provides a url to
 * that resource. Right now this resource will be a pdf file (rendered by @react-pdf/renderer),
 * although theoretically we could provide other resource types if we have a renderer for that.
 *
 * @param serviceRequest The service request to convert to a print-out
 * @param children A consumer of that printout
 */
export default function DetailsPrintoutProvider({ serviceRequest, authorization, children }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    loading: serviceSummaryPDFLoading,
    error: serviceSummaryPDFError,
    refetch: serviceSummaryPDFRefetch,
  } = useGetServiceSummaryPDF({
    id: serviceRequest?.id || authorization?.id || "",
    lazy: true,
  });

  const {
    loading: authorizationServiceSummaryPDFLoading,
    error: authorizationServiceSummaryPDFError,
    refetch: authorizationServiceSummaryPDFRefetch,
  } = useGetAuthorizationServiceSummaryPDF({
    id: authorization?.id || "",
    lazy: true,
  });

  useEffect(() => {
    if (serviceSummaryPDFError || authorizationServiceSummaryPDFError) {
      enqueueSnackbar("Failed to retrieve summary details, please try later", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [serviceSummaryPDFError, enqueueSnackbar, authorizationServiceSummaryPDFError]);

  const { fileUrl } = useBinaryFile(
    authorization
      ? authorization.serviceRequestsOnAuth
        ? authorizationServiceSummaryPDFRefetch
        : serviceSummaryPDFRefetch
      : serviceRequest
      ? serviceSummaryPDFRefetch
      : undefined
  );

  if (!serviceRequest && !authorization) {
    return null;
  }

  const Memoed = () => {
    return useMemoDeepCompare(() => {
      return children({
        url: fileUrl,
        loading: serviceSummaryPDFLoading || authorizationServiceSummaryPDFLoading,
        error: null,
      });
    }, [children, serviceRequest]);
  };

  return <Memoed />;
}
