import React, { useCallback, useState } from "react";
import { H5 } from "@coherehealth/common";
import {
  Patient,
  VatNotification,
  AuthorizationResponse,
  ServiceRequestResponse,
  UserResponse,
  AuthorizationsWithCountResponse,
} from "@coherehealth/core-platform-api";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

import { useAuthorized } from "authorization";
import NoResults from "components/DashboardPage/NoResults";
import DocumentViewer from "components/DocumentViewer";
import DetailsPrintoutProvider from "components/ServiceRequest/DetailsPrintoutProvider";
import { getInitialServiceRequest, getLatestServiceRequest } from "util/authorization";
import AuthorizationSummaryCard from "./AuthorizationSummaryCard";
import CallLogLetter from "components/ServiceRequest/CallLogLetter";
import ServiceRequestSummaryCard from "../ServiceRequestSummaryCard";
import { Box } from "@material-ui/core";
import { useSRPatientSummaryPageStyles } from "../patientSummaryStyles";
import {
  useGetLettersExpansionConfigurationByPayer,
  useSetScrubTinsContextByHealthPlanConfiguration,
} from "hooks/useGetFeatureConfigurations";
import useSearchAndLocation from "hooks/useSearchAndLocation";

interface Props {
  patient: Patient;
  authorizations: AuthorizationResponse[];
  setAuthorizations?: React.Dispatch<React.SetStateAction<AuthorizationResponse[]>>;
  refresh?: () => Promise<AuthorizationResponse[] | AuthorizationsWithCountResponse | null | void>;
  refreshAuthorization?: (authorizationId: string) => Promise<void>;
  refreshServiceRequest?: (serviceRequestId: string) => Promise<void>;
  user?: UserResponse | null;
  selectedServiceRequest?: ServiceRequestResponse | null;
  healthPlanName?: string | null;
}

export default function AuthorizationPatientSummary({
  authorizations,
  setAuthorizations,
  patient,
  refresh,
  refreshAuthorization,
  refreshServiceRequest,
  user,
  selectedServiceRequest,
  healthPlanName,
}: Props) {
  const { location, search } = useSearchAndLocation();

  const REVIEW_TO_SCROLL_TO_PARAM = "reviewId";
  const targetReviewId = search.get(REVIEW_TO_SCROLL_TO_PARAM);
  const [showAuthDetailsPrintout, setShowAuthDetailsPrintout] = useState(false);
  const [authForDetailsPrintout, setAuthForDetailsPrintout] = useState<AuthorizationResponse>();
  const [srForDetailsPrintout, setSrForDetailsPrintout] = useState<ServiceRequestResponse>();
  const [showCallLogDetailsPrintout, setShowCallLogDetailsPrintout] = useState(false);
  const [vatForDetailsPrintout, setVatForDetailsPrintout] = useState<VatNotification | null>();
  const [callDispositionMessage, setCallDispositionMessage] = useState<string>();
  const canViewFinalDeterminationLetter = useAuthorized("FINAL_DETERMINATION_LETTER_DISPLAY");
  const authPatientSummaryStyles = useSRPatientSummaryPageStyles();
  const { data: lettersExpansionConfigResponse } = useGetLettersExpansionConfigurationByPayer(healthPlanName || "");

  const showSummaryPrintout = useCallback(
    (auth: AuthorizationResponse) => {
      setShowAuthDetailsPrintout(true);
      setAuthForDetailsPrintout(auth);
    },
    [setShowAuthDetailsPrintout, setAuthForDetailsPrintout]
  );

  const showCallLogPrintout = useCallback(
    (sr: ServiceRequestResponse, vn?: VatNotification | null, callDispositionMessage?: string) => {
      setShowCallLogDetailsPrintout(true);
      setVatForDetailsPrintout(vn);
      setCallDispositionMessage(callDispositionMessage);
      setSrForDetailsPrintout(sr);
    },
    []
  );

  let showEmptyResults = Boolean(authorizations.length === 0 && !authorizations);

  const restrictedAuthorizations = authorizations.filter((authorization) => authorization.isRestricted);
  const showOtherRequestsLabel = Boolean(authorizations.length > 0);

  useSetScrubTinsContextByHealthPlanConfiguration({
    healthPlanName: healthPlanName ?? "",
  });

  const authorizationSummaryCardOnEdit = (serviceRequestId?: string, authorizationId?: string) => {
    refresh?.();
    if (serviceRequestId) {
      refreshServiceRequest?.(serviceRequestId);
    }
    if (authorizationId) {
      refreshAuthorization?.(authorizationId);
    }
  };

  return (
    <>
      {!showCallLogDetailsPrintout && (
        <Dialog fullScreen keepMounted open={showAuthDetailsPrintout} onClose={() => setShowAuthDetailsPrintout(false)}>
          <DetailsPrintoutProvider
            authorization={authForDetailsPrintout}
            serviceRequest={authForDetailsPrintout ? getLatestServiceRequest(authForDetailsPrintout)?.[1] : undefined}
          >
            {({ url, loading }) => (
              <DocumentViewer
                documentInfo={{
                  name: `CohereAuthorization_${
                    authForDetailsPrintout
                      ? authForDetailsPrintout.authNumber
                        ? authForDetailsPrintout.authNumber
                        : getInitialServiceRequest(authForDetailsPrintout)[0]?.cohereId
                      : "undefined"
                  }.pdf`,
                  contentType: "application/pdf",
                }}
                loading={loading}
                url={url || undefined}
                handleClose={() => setShowAuthDetailsPrintout(false)}
                canDelete={false}
                location={location}
                search={search}
              />
            )}
          </DetailsPrintoutProvider>
        </Dialog>
      )}
      {canViewFinalDeterminationLetter && callDispositionMessage && !showAuthDetailsPrintout && (
        <Dialog
          fullScreen
          keepMounted
          open={showCallLogDetailsPrintout}
          onClose={() => setShowCallLogDetailsPrintout(false)}
        >
          <CallLogLetter
            serviceRequest={srForDetailsPrintout}
            vatNotification={vatForDetailsPrintout}
            callDispositionMessage={callDispositionMessage}
            languagePreference={patient?.languagePreference}
          >
            {({ url, loading }) => (
              <DocumentViewer
                documentInfo={{
                  name: `CohereServiceRequest_${srForDetailsPrintout?.cohereId}.pdf`,
                  contentType: "application/pdf",
                }}
                loading={loading}
                url={url || undefined}
                handleClose={() => setShowCallLogDetailsPrintout(false)}
                canDelete={false}
                location={location}
                search={search}
              />
            )}
          </CallLogLetter>
        </Dialog>
      )}

      {showEmptyResults ? (
        <NoResults message="Get started by adding a care path above." />
      ) : (
        <Grid container spacing={2} className={authPatientSummaryStyles.authSummaryPanel}>
          {selectedServiceRequest && (
            <Grid item xs={12} data-testid="focused-service-request">
              {selectedServiceRequest.authorization?.id ? (
                <AuthorizationSummaryCard
                  id="focused-authorization-summary-card"
                  onEdit={() => {
                    authorizationSummaryCardOnEdit(
                      selectedServiceRequest?.id,
                      selectedServiceRequest?.authorization?.id
                    );
                  }}
                  loadExtendedAuthView={false}
                  authorization={selectedServiceRequest.authorization}
                  setAuthorizations={setAuthorizations}
                  onShowCallLog={showCallLogPrintout}
                  onShowSummaryPrintout={showSummaryPrintout}
                  targetReviewId={targetReviewId}
                  user={user}
                  allowContinuations={selectedServiceRequest?.allowContinuations}
                  lettersExpansionConfig={lettersExpansionConfigResponse}
                  {...(!selectedServiceRequest.isRestricted
                    ? {
                        initialIsExpanded: true,
                      }
                    : {})}
                />
              ) : (
                <ServiceRequestSummaryCard
                  id="focused-service-request-summary-card"
                  onEdit={async () => {
                    await refresh?.();
                    if (selectedServiceRequest.id) {
                      await refreshServiceRequest?.(selectedServiceRequest.id);
                    }
                  }}
                  serviceRequest={selectedServiceRequest}
                  onShowCallLog={showCallLogPrintout}
                  onShowSummaryPrintout={showSummaryPrintout}
                  targetReviewId={targetReviewId}
                  user={user}
                  loadExtendedSrView={false}
                  {...(!selectedServiceRequest.isRestricted
                    ? {
                        initialIsExpanded: true,
                      }
                    : {})}
                />
              )}
            </Grid>
          )}

          {showOtherRequestsLabel && (
            <Box marginTop={4} marginBottom={2} marginX={1}>
              <H5 data-public>Other requests</H5>
            </Box>
          )}

          {authorizations
            .filter((authorization) => !authorization?.isRestricted)
            .map((authorization, idx) => (
              <Grid key={authorization.id || `sr-fallback-${idx}`} item xs={12}>
                <AuthorizationSummaryCard
                  data-testid="authorization-summary-card"
                  onEdit={() => {
                    authorizationSummaryCardOnEdit(selectedServiceRequest?.id, authorization.id);
                  }}
                  authorization={authorization}
                  setAuthorizations={setAuthorizations}
                  onShowCallLog={showCallLogPrintout}
                  onShowSummaryPrintout={showSummaryPrintout}
                  targetReviewId={targetReviewId}
                  allowContinuations={selectedServiceRequest?.allowContinuations}
                  lettersExpansionConfig={lettersExpansionConfigResponse}
                  user={user}
                />
              </Grid>
            ))}
          {authorizations &&
            authorizations.length > 0 &&
            restrictedAuthorizations &&
            restrictedAuthorizations.length > 0 && (
              <Grid item xs={12}>
                <Divider className={authPatientSummaryStyles.carePathSingleServiceDivider} />
              </Grid>
            )}
          {restrictedAuthorizations && restrictedAuthorizations.length > 0 && (
            <Grid item xs={12}>
              <H5>Restricted</H5>
            </Grid>
          )}
          {restrictedAuthorizations.map((authorization, idx) => (
            <Grid key={authorization.id || `sr-fallback-${idx}`} item xs={12}>
              <AuthorizationSummaryCard
                data-testid="restricted-authorization-summary-card"
                onEdit={() => {
                  refresh?.();
                  if (selectedServiceRequest?.id) {
                    refreshServiceRequest?.(selectedServiceRequest.id);
                  }
                  if (authorization.id) {
                    refreshAuthorization?.(authorization.id);
                  }
                }}
                authorization={authorization}
                setAuthorizations={setAuthorizations}
                onShowCallLog={showCallLogPrintout}
                onShowSummaryPrintout={showSummaryPrintout}
                targetReviewId={targetReviewId}
                allowContinuations={selectedServiceRequest?.allowContinuations}
                lettersExpansionConfig={lettersExpansionConfigResponse}
                user={user}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}
