import React from "react";
import CohereTable from "../ProviderOrganization/CohereTable";
import { formatDateStrWithMonthName } from "@coherehealth/common";
import { IntegrationExecution } from "@coherehealth/int-obs-api";
interface IProps {
  integrationExecutions: Array<IntegrationExecution>;
}
export default function IntegrationList({ integrationExecutions }: IProps) {
  const tableColumns = [
    {
      name: "Integration Type",
      width: "18%",
      value: (log: IntegrationExecution) => log.integrationName || "",
    },
    {
      name: "Integration Status",
      width: "14%",
      value: (log: IntegrationExecution) => log.status || "",
    },
    {
      name: "Object Id",
      width: "10%",
      value: (log: IntegrationExecution) => log.objectId || "",
    },
    {
      name: "Object Type",
      width: "10%",
      value: (log: IntegrationExecution) => log.objectType || "",
    },
    {
      name: "Date Created",
      width: "10%",
      value: (log: IntegrationExecution) => formatDateStrWithMonthName(log.dateCreated) || "",
    },
    {
      name: "Last Updated",
      width: "10%",
      value: (log: IntegrationExecution) => formatDateStrWithMonthName(log.lastUpdated) || "",
    },
    {
      name: "Source",
      width: "10%",
      value: (log: IntegrationExecution) => log.sourceTopic || "",
    },
    {
      name: "Retry Count",
      width: "10%",
      value: (log: IntegrationExecution) => log.retryCount || "",
    },
    {
      name: "Max retries",
      width: "10%",
      value: (log: IntegrationExecution) => log.maxRetries || "",
    },
  ];

  return (
    <>
      <CohereTable tableColumns={tableColumns} data={integrationExecutions} />
    </>
  );
}
