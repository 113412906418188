export default function ClipboardWarningIcon() {
  return (
    <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 52C0 23.2812 23.2812 0 52 0C80.7188 0 104 23.2812 104 52C104 80.7188 80.7188 104 52 104C23.2812 104 0 80.7188 0 52Z"
        fill="#F9EAEA"
      />
      <path d="M72.2817 30.1275H29.9V79.5582H72.2817V30.1275Z" fill="#A1AAB4" />
      <path d="M62.2872 33.1229H32.8988V76.5567H69.266V40.1193L62.2872 33.1229Z" fill="white" />
      <path
        d="M60.6907 24.0065H55.0444C55.0444 21.5165 53.2734 19.5 51.0816 19.5C48.8897 19.5 47.1187 21.5165 47.1187 24.0065H41.4725L39.8768 34.0013H62.2688L60.6907 24.0065Z"
        fill="#212936"
      />
      <path
        d="M44.0156 40.0172H40.9996V37.0013H38.9305V40.0172H35.9145V42.0864H38.9305V45.1023H40.9996V42.0864H44.0156V40.0172Z"
        fill="#EA1D63"
      />
      <path d="M60.5156 48.4521H41.6481V49.8724H60.5156V48.4521Z" fill="#89D3DD" />
      <path d="M65.776 71.1913H58.2535V72.6116H65.776V71.1913Z" fill="#89D3DD" />
      <path d="M65.7755 53.6575H36.3871V55.0778H65.7755V53.6575Z" fill="#C2C2C2" />
      <path d="M65.7755 57.9177H36.3871V59.338H65.7755V57.9177Z" fill="#C2C2C2" />
      <path d="M65.7755 62.1833H36.3871V63.6036H65.7755V62.1833Z" fill="#C2C2C2" />
      <path d="M49.5207 66.4408H36.3871V67.8612H49.5207V66.4408Z" fill="#C2C2C2" />
      <path d="M62.2862 40.1193H69.2651L62.2862 33.1229V40.1193Z" fill="#F5F5F5" />
      <g clipPath="url(#clip0)">
        <ellipse cx="71.5136" cy="35.1136" rx="15.6136" ry="15.6136" fill="white" />
        <path
          d="M71.5136 19.5C62.8949 19.5 55.9 26.4949 55.9 35.1136C55.9 43.7323 62.8949 50.7273 71.5136 50.7273C80.1324 50.7273 87.1273 43.7323 87.1273 35.1136C87.1273 26.4949 80.1324 19.5 71.5136 19.5ZM73.075 42.9204H69.9523V39.7977H73.075V42.9204ZM73.075 36.675H69.9523V27.3068H73.075V36.675Z"
          fill="#E53935"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="31.2273" height="31.2273" fill="white" transform="translate(55.9 19.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
