import * as React from "react";
import { ReferralRequestResponse } from "@coherehealth/core-platform-api";
import ReferralHeaderIcon from "components/images/ReferralHeaderIcon";
import { ReferralRequestSelectionCard } from "./ReferralRequestSelectionCard";
import { makeStyles } from "@material-ui/core";
import { Caption, getReviewDatesRange } from "@coherehealth/common";
import { splitCamelToString } from "components/ReferralManagement/common/stringutils";

interface EmptyReferralRequestViewProps {
  message: string;
}

export interface PatientReferralRequestsListProps {
  referralRequests: Array<ReferralRequestResponse> | undefined;
  healthPlanName?: string;
  selectedPatientReferralEligibility?: boolean;
  patientId?: string;
  isRRSelected: (id: string) => boolean;
  isRRAttached: (id: string) => boolean;
  selectRR: (id: string) => void;
  unselectRR: (id: string) => void;
  canAttach: boolean;
  selectedReferralRequestId?: string | null;
  selectedServiceRequestIds?: string[] | null;
}

const useStyles = makeStyles((theme) => ({
  ineligibleForReferralsContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
  referralRequestSelectionCardContainer: {
    marginTop: "1rem",
  },
}));

export const EmptyReferralRequestView: React.FC<EmptyReferralRequestViewProps> = ({ message }) => {
  const classes = useStyles();
  return (
    <div className={classes.ineligibleForReferralsContainer}>
      <ReferralHeaderIcon width={100} height={100} opacity={".5"} />
      <Caption variant="h5" color="textSecondary">
        {message}
      </Caption>
    </div>
  );
};

export default function PatientReferralRequestsList(props: PatientReferralRequestsListProps) {
  const {
    patientId,
    referralRequests,
    healthPlanName,
    selectedPatientReferralEligibility: patientHasReferralEligibility,
    selectedReferralRequestId,
    selectedServiceRequestIds,
    isRRSelected,
    isRRAttached,
    canAttach,
    selectRR,
    unselectRR,
  } = props;
  const classes = useStyles();

  const handleSelect = (id: string) => {
    selectRR(id);
  };

  const handleUnselect = (id: string) => {
    unselectRR(id);
  };

  if (!patientId) {
    return <></>;
  }

  if (patientHasReferralEligibility && referralRequests && referralRequests.length === 0) {
    return <EmptyReferralRequestView message={"No referrals yet."} />;
  }

  if (!patientHasReferralEligibility) {
    return <EmptyReferralRequestView message={"This patient is not eligible for referrals"} />;
  }

  const generateReferralTitle = (name: string | undefined) => {
    if (!name) {
      return "No title";
    }
    return `${splitCamelToString(name)} Referral Request`;
  };

  return (
    <>
      {referralRequests?.map((rr) => {
        return (
          <div className={classes.referralRequestSelectionCardContainer} key={`ref_req_${rr.cohereId}`}>
            <ReferralRequestSelectionCard
              referralTitle={generateReferralTitle(rr?.specialty)}
              status={rr.referralStatus ?? "Unkown Status"}
              authNumber={rr.authNumber}
              trackingNumber={rr.cohereId!.toString()}
              primaryDiagnosisCode={rr.primarySemanticDiagnosisCode?.code || ""}
              secondaryDiagnosisCodes={getDiagnosisCodesText(rr)}
              datesValid={getReviewDatesRange([{ date: rr.startDate }, { date: rr.endDate }])}
              referringProvider={rr.selectedReferringProvider}
              specialty={rr.specialty || ""}
              specialistPractice={rr.selectedFacility}
              specialist={rr.selectedPerformingSpecialist}
              onSelect={() => handleSelect(rr.id)}
              healthPlanName={healthPlanName || ""}
              selected={isRRSelected(rr.id) || isRRAttached(rr.id)}
              canAttach={canAttach}
              onUnSelect={() => handleUnselect(rr.id)}
              selectedReferralRequestId={selectedReferralRequestId}
              selectedServiceRequestIds={selectedServiceRequestIds}
              referralId={rr.id}
            />
          </div>
        );
      })}
    </>
  );
}
const getDiagnosisCodesText = (rr: ReferralRequestResponse) => {
  return rr.secondarySemanticDiagnosisCodes
    ? rr.secondarySemanticDiagnosisCodes.map((diagnosis) => diagnosis.code).join(",")
    : "--";
};
