import { InlineButton, SecondaryButton } from "@coherehealth/common";
import { AuthBuilderWorkflowStep, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { AuthFlowType } from "./common";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { onContinuationWorkFlow } from "util/workflowUtils";

interface Props {
  disabled?: boolean;
  loading?: boolean;
  workflowStep?: AuthBuilderWorkflowStep;
  authFlowType?: AuthFlowType;
  draftServiceRequest?: ServiceRequestResponse | null;
  navigateBack?: () => void;
  navigateBackContinuations?: () => void;
  isFaxAuthBuilderFlow?: boolean;
}

export default function BackButton({
  disabled,
  loading,
  workflowStep,
  authFlowType,
  draftServiceRequest,
  navigateBack,
  navigateBackContinuations,
  isFaxAuthBuilderFlow,
}: Props) {
  const clickHandler = onContinuationWorkFlow() ? navigateBackContinuations : navigateBack;

  return isFaxAuthBuilderFlow ? (
    <SecondaryButton
      style={{ minWidth: 124, maxWidth: 150 }}
      disabled={disabled || workflowStep === "GATHER_REQUIREMENTS_MEDICAL"}
      loading={loading}
      onClick={clickHandler}
      data-public
    >
      Back
    </SecondaryButton>
  ) : (
    <>
      {workflowStep !== "FILL_FORMS_FAX" ? (
        <InlineButton
          disabled={
            disabled ||
            (authFlowType === "PHARMACY" && workflowStep === "ADD_ATTACHMENTS") ||
            workflowStep === "FILL_FORMS_CONTINUATION"
          }
          loading={loading}
          onClick={clickHandler}
          startIcon={<NavigateBeforeIcon fontSize="large" />}
          data-public
        >
          Back
        </InlineButton>
      ) : null}
    </>
  );
}
