import React, { ComponentProps, FunctionComponent, useState } from "react";
import { LoginCallback } from "@okta/okta-react";
import { warn, error as logError } from "logger";
import { useAuth } from "hooks/useAuth";
import { useTimeoutEffect } from "@react-hookz/web";

/**
 * Sometimes, for reasons that are unclear,
 * users may get stuck on implicit/callback after
 * some obscure Okta error.
 * Generally, this is always a retryable condition, and
 * they will recover if we simply try to kick them back into the application.
 */
const WAIT_MS = 5000;
const MonitoringLoginCallback: FunctionComponent<ComponentProps<typeof LoginCallback>> = (props) => {
  const [shouldShowHelper, setShouldShowHelper] = useState(false);
  const { oktaAuth } = useAuth();
  useTimeoutEffect(() => {
    warn("stuck on implicit/callback");
    setShouldShowHelper(true);
  }, WAIT_MS);

  return (
    <>
      {shouldShowHelper && <a href="/">Back to home</a>}
      <LoginCallback
        {...props}
        // Using a workaround to avoid users getting stuck on a deadend page when accessing Convene
        // due to workaround/bug described here: https://github.com/okta/okta-oidc-js/issues/898#issuecomment-884204267
        errorComponent={() => {
          const user = oktaAuth.getUser();
          user.then((user: any) => {
            logError(new Error(`Okta callbacks login error user email is: ${user?.email}`));
          });
          const id = setTimeout(() => {
            localStorage.clear();
            window.location.assign("/");
          }, 2000);
          oktaAuth.signOut().then(() => {
            clearTimeout(id);
          });
          return null;
        }}
      />
    </>
  );
};
export default MonitoringLoginCallback;
