import { Model, Factory, Server } from "miragejs";

const models = {
  diagnosisCode: Model,
};
const factories = {
  diagnosisCode: Factory.extend({
    code(i: number) {
      return `CODE${i}`;
    },
    description(i: number) {
      return `Description ${i}`;
    },
    system(i: number) {
      return "SYSTEM";
    },
  }),
};

const scenarios = {
  standard(server: Server) {
    server.createList("diagnosisCode", 300);
  },
};

const activeScenario = "standard";
//const activeScenario = "short";
//const activeScenario = "empty";

export { models, factories, scenarios, activeScenario };
