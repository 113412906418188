import { AttachmentInfo, extractUploadId, parseDateFromISOString, diffDates, useFeature } from "@coherehealth/common";
import {
  Attachment,
  CopyPasteInfo,
  ReviewType,
  SearchInfo,
  ServiceRequestResponse,
  useGetAllServiceRequestAttachments,
  useGetFaxAttachment,
  useGetAppealsForAuthorization,
  AuthorizationResponse,
  AppealResponse,
} from "@coherehealth/core-platform-api";
import { ServiceCase, useUpdateServiceCase } from "@coherehealth/qm-api";
import { makeStyles } from "@material-ui/core";
import config from "api/config";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export interface AttachmentProps {
  attachmentListFetchLoading: boolean;
  fetchAttachments: () => Promise<void>;
  attachments: Attachment[];
  onSwitchAttachmentTab: () => void;
  appealsForAuth: AppealResponse[];
  onViewAttachment: (index: number, landingPage?: number) => void;
  copiedAttachmentText: CopyPasteInfo | undefined;
  setCopiedAttachmentText: React.Dispatch<React.SetStateAction<CopyPasteInfo | undefined>>;
  pastedAttachmentTexts: CopyPasteInfo[] | undefined;
  setPastedAttachmentTexts: React.Dispatch<React.SetStateAction<CopyPasteInfo[] | undefined>>;
  searchInfos: SearchInfo[];
  setSearchInfos: React.Dispatch<React.SetStateAction<SearchInfo[]>>;
  previousAttachmentsExpanded: boolean;
  setPreviousAttachmentsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  currentPage: React.MutableRefObject<number>;
  zoomLevel: React.MutableRefObject<number>;
  currentRotate: React.MutableRefObject<number>;
  attachmentIndexOpen: number;
  openAttachment: (attachmentId: string) => void;
  openAttachmentWarningModal: boolean;
  setOpenAttachmentWarningModal: React.Dispatch<React.SetStateAction<boolean>>;
  setHasShownOpenAttachmentWarningModal: React.Dispatch<React.SetStateAction<boolean>>;
  newAttachmentsNumber: number;
  attachmentsInfo: AttachmentInfo[];
  setAttachmentsInfo: React.Dispatch<React.SetStateAction<AttachmentInfo[]>>;
}

type AttachmentParams = {
  serviceRequest: ServiceRequestResponse | null;
  authorization?: AuthorizationResponse;
  faxId?: string;
  existingReviews?: ReviewType[];
  currentCase?: ServiceCase;
  isCaseLoading?: boolean;
};

export const useAttachments = ({
  serviceRequest,
  authorization,
  faxId,
  existingReviews = [],
  currentCase,
  isCaseLoading,
}: AttachmentParams): AttachmentProps => {
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [copiedAttachmentText, setCopiedAttachmentText] = useState<CopyPasteInfo>();
  const [pastedAttachmentTexts, setPastedAttachmentTexts] = useState<CopyPasteInfo[]>();
  const [searchInfos, setSearchInfos] = useState<SearchInfo[]>([]);
  const [attachmentIndexOpen, setAttachmentIndexOpen] = useState<number>(-1);
  const [previousAttachmentsExpanded, setPreviousAttachmentsExpanded] = useState<boolean>(false);
  const [, setAttachmentPages] = useState<number[]>([]);
  const currentPage = useRef<number>(0);
  const [, setZoomLevel] = useState<number>(1);
  const zoomLevel = useRef<number>(1);
  const [, setCurrentRotates] = useState<number[]>([]);
  const currentRotate = useRef<number>(0);
  const { enqueueSnackbar } = useSnackbar();
  const [openAttachmentWarningModal, setOpenAttachmentWarningModal] = useState<boolean>(false);
  const [hasShownOpenAttachmentWarningModal, setHasShownOpenAttachmentWarningModal] = useState<boolean>(false);
  const [attachmentsInfo, setAttachmentsInfo] = useState<AttachmentInfo[]>([]);
  const newAttachmentsNumber = countNewAttachments(attachmentsInfo);
  const latestClinicalReviewTime = getLatestClinicalReviewTime(existingReviews);
  const newAttachmentChipEnhancementsEnabled = useFeature("newAttachmentChipEnhancements");

  const { refetch: refetchAppealsForAuth } = useGetAppealsForAuthorization({
    authorizationId: authorization?.id || "",
    lazy: true,
  });

  const [appealsForAuth, setAppealsForAuth] = useState<AppealResponse[]>([]);
  const onViewAttachment = useCallback(
    (index: number, landingPage?: number): void => {
      let nextPage = 0;
      setAttachmentPages((attachmentPages) =>
        attachmentPages.map((page, idx) => {
          if (idx === index) {
            nextPage = page;
          }
          return idx === attachmentIndexOpen ? currentPage.current : page;
        })
      );
      currentPage.current = landingPage !== undefined && landingPage >= 0 ? landingPage : nextPage;
      const openAttachmentRotate = currentRotate.current;
      setCurrentRotates((rotates) =>
        rotates.map((rotate, idx) => {
          if (idx === index) {
            currentRotate.current = rotate;
          }
          return idx === attachmentIndexOpen ? openAttachmentRotate : rotate;
        })
      );
      setAttachmentIndexOpen(index);
    },
    [setAttachmentPages, setCurrentRotates, setAttachmentIndexOpen, attachmentIndexOpen]
  );

  const onSwitchAttachmentTab = useCallback((): void => {
    setAttachmentPages((attachmentPages) =>
      attachmentPages.map((page, idx) => {
        return idx === attachmentIndexOpen ? currentPage.current : page;
      })
    );
    setZoomLevel(zoomLevel.current);
    setCurrentRotates((rotates) =>
      rotates.map((rotate, idx) => {
        return idx === attachmentIndexOpen ? currentRotate.current : rotate;
      })
    );
  }, [attachmentIndexOpen]);

  const openAttachment = useCallback(
    (attachmentId: string) => {
      if (!attachments?.length) {
        return;
      }
      const index = attachments
        ?.sort((a: Attachment, b: Attachment) => {
          return parseDateFromISOString(b.dateCreated).getTime() - parseDateFromISOString(a.dateCreated).getTime();
        })
        .findIndex((attachment) => extractUploadId(attachment.url) === attachmentId);
      onViewAttachment(index);
    },
    [attachments, onViewAttachment]
  );

  const { loading: attachmentListFetchLoading, refetch: getAllServiceRequestAttachments } =
    useGetAllServiceRequestAttachments({
      serviceRequestId: serviceRequest?.id || "",
      lazy: true,
    });

  const {
    loading: faxInfoLoading,
    error: faxInfoError,
    refetch: getFaxAttachment,
  } = useGetFaxAttachment({
    id: faxId || "",
    lazy: true,
  });

  useEffect(() => {
    if (faxInfoError) {
      enqueueSnackbar(`Error downloading fax information: ${faxInfoError.message}`, { variant: "error" });
    }
  }, [faxInfoError, enqueueSnackbar]);

  const fetchAttachments: () => Promise<void> = useCallback(async () => {
    let attachmentsList: Attachment[] = [];

    if (faxId) {
      const faxAttachment = await getFaxAttachment();
      attachmentsList = faxAttachment ? [faxAttachment] : [];
    } else if (serviceRequest?.id) {
      attachmentsList = (await getAllServiceRequestAttachments()) || [];
    }

    setAttachments(attachmentsList ?? []);
  }, [faxId, getAllServiceRequestAttachments, getFaxAttachment, serviceRequest?.id]);

  useEffect(() => {
    fetchAttachments();
  }, [fetchAttachments]);

  useEffect(() => {
    if (authorization?.id) {
      refetchAppealsForAuth().then((data: AppealResponse[] | null) => {
        if (data) {
          setAppealsForAuth(data);
        }
      });
    }
  }, [attachments, authorization?.id, refetchAppealsForAuth]);

  const attachmentToAppealMap = useMemo(() => mapAttachmentsToAppeals(appealsForAuth), [appealsForAuth]);

  useEffect(() => {
    const initialAttachmentsInfo = () => {
      if (!isCaseLoading) {
        let currentCaseLastUpdated = currentCase?.lastUpdated ? new Date(currentCase.lastUpdated) : new Date();
        return (
          attachments?.map((attachment) => {
            const diff = diffDates(new Date(attachment.dateCreated), currentCaseLastUpdated);
            const isNewAttachment =
              (!!latestClinicalReviewTime &&
                new Date(attachment.dateCreated).getTime() > new Date(latestClinicalReviewTime).getTime()) ||
              (currentCase?.hasNewAttachments &&
                newAttachmentChipEnhancementsEnabled &&
                !!currentCase?.lastUpdated &&
                diff.days <= 0 &&
                diff.hours <= 0 &&
                diff.minutes <= 0);
            return {
              attachmentId: attachment.id,
              uploadId: extractUploadId(attachment.url),
              appealId: attachmentToAppealMap[attachment.id],
              isCurrent: !!attachment?.serviceRequest?.id && attachment.serviceRequest.id === serviceRequest?.id,
              showNewChip: isNewAttachment,
              isNewAttachment,
            };
          }) || []
        );
      } else {
        return [];
      }
    };
    setAttachmentsInfo(initialAttachmentsInfo);
  }, [
    attachments,
    currentCase?.hasNewAttachments,
    currentCase?.lastUpdated,
    isCaseLoading,
    latestClinicalReviewTime,
    newAttachmentChipEnhancementsEnabled,
    serviceRequest?.id,
    attachmentToAppealMap,
  ]);

  useEffect(() => {
    // Only show the modal the first time when there are new attachments
    if (newAttachmentsNumber > 0 && !hasShownOpenAttachmentWarningModal) {
      setOpenAttachmentWarningModal(true);
      setHasShownOpenAttachmentWarningModal(true);
    }
  }, [newAttachmentsNumber, hasShownOpenAttachmentWarningModal]);

  useEffect(() => {
    if (attachments) {
      const firstPDFIndex = attachments?.findIndex((attachment) => attachment.contentType === "application/pdf");
      const caseFaxIdx = faxId
        ? attachments?.findIndex((attachment) => attachment?.linkedFaxAttachment?.id === faxId)
        : -1;
      const firstAttachmentIndexOpen = caseFaxIdx > -1 ? caseFaxIdx : firstPDFIndex > -1 ? firstPDFIndex : 0;
      setAttachmentIndexOpen(firstAttachmentIndexOpen);
      setAttachmentPages(attachments.map(() => 0));
      setCurrentRotates(attachments.map(() => 0));
    }
  }, [attachments, faxId]);

  useEffect(() => {
    return () => {
      attachmentsInfo.forEach(
        (attachmentInfo) => attachmentInfo.fileUrl && URL.revokeObjectURL(attachmentInfo.fileUrl)
      );
    };
    /* objectURLs should only be revoked when component unmounts */
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { mutate: updateServiceCase } = useUpdateServiceCase({
    id: currentCase?.id || "none",
    base: `${config.QM_SERVICE_API_URL}`,
  });

  const updateCaseToNoNewAttachments = useCallback(async () => {
    const updatedToNoNewAttachments = await updateServiceCase({
      hasNewAttachments: false,
    });
    return updatedToNoNewAttachments;
  }, [updateServiceCase]);

  useEffect(() => {
    if (
      currentCase &&
      currentCase?.hasNewAttachments &&
      attachmentsInfo.length > 0 &&
      attachmentsInfo.every((ai) => !ai.isNewAttachment) &&
      newAttachmentChipEnhancementsEnabled
    ) {
      updateCaseToNoNewAttachments();
    }
  }, [attachmentsInfo, newAttachmentChipEnhancementsEnabled, currentCase, updateCaseToNoNewAttachments]);

  return {
    attachmentListFetchLoading: attachmentListFetchLoading || faxInfoLoading,
    fetchAttachments,
    attachments,
    onSwitchAttachmentTab,
    appealsForAuth,
    onViewAttachment,
    copiedAttachmentText,
    setCopiedAttachmentText,
    pastedAttachmentTexts,
    setPastedAttachmentTexts,
    searchInfos,
    setSearchInfos,
    previousAttachmentsExpanded,
    setPreviousAttachmentsExpanded,
    currentPage,
    zoomLevel,
    currentRotate,
    attachmentIndexOpen,
    openAttachment,
    openAttachmentWarningModal,
    setOpenAttachmentWarningModal,
    setHasShownOpenAttachmentWarningModal,
    newAttachmentsNumber,
    attachmentsInfo,
    setAttachmentsInfo,
  };
};

export const mapAttachmentsToAppeals = (appeals: AppealResponse[]): Record<string, string> => {
  const map: Record<string, string> = {};
  appeals.forEach((appeal) => {
    appeal.attachments?.forEach((attachment: { id: string }) => {
      if (attachment?.id) {
        map[attachment.id] = appeal.appealId || "";
      }
    });
  });
  return map;
};

export const countNewAttachments = (attachmentsInfo: AttachmentInfo[]): number => {
  return attachmentsInfo.filter((attachmentInfo) => attachmentInfo.isNewAttachment).length;
};

export const getLatestClinicalReviewTime = (existingReviews: ReviewType[]): string => {
  if (existingReviews.length === 0) {
    return "";
  }

  const clinicalReviewTypes = ["NurseReview", "OutOfNetworkReview", "MdReview", "PeerToPeerReview"];
  const latestReview = existingReviews
    .filter((review) => clinicalReviewTypes.includes(review.reviewType) && review.dateCompleted)
    .sort((a, b) => Date.parse(b.dateCompleted!) - Date.parse(a.dateCompleted!))[0];

  return latestReview?.dateCompleted || "";
};

export const useStyles = makeStyles((theme) => ({
  newAttachmentNumber: {
    marginLeft: "5px",
    color: theme.palette.primary.dark,
    fontFamily: "Gilroy-SemiBold",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.15px",
  },
}));
