import {
  InformativeModal,
  useFeature,
  HEALTH_HELP_NAME,
  HEALTH_HELP_V2_NAME,
  formatDateToISODate,
  useGetAuthorizationByIdWithFallback,
  useGetServiceRequestByIdWithFallback,
} from "@coherehealth/common";
import {
  AuthBuilderWorkflowStep,
  AuthorizationCreatePayload,
  CarePathJourney,
  HealthHelpPASRequestResponse,
  ProcedureCode,
  Requestor,
  ServiceRequestCreatePayload,
  ServiceRequestResponse,
  StartHealthHelpIntegrationPathParams,
  StartHealthHelpIntegrationRequestBody,
  Vendor,
  useCreateAuthorization,
  useCreateCarePathJourneyAttachment,
  useCreateServiceRequestAttachment,
  useDeleteServiceRequest,
  useGetClinicalAssessment,
  useGetMultiSingleServiceClinicalAssessment,
  useGetSingleServiceClinicalAssessment,
  useStartHealthHelpIntegration,
  useUpdateAuthorizationById,
  useUpdateServiceRequest,
  useUpdateServiceRequestOnAuthorization,
  useUpdateUserExtension,
  useWithdrawHealthHelpCase,
} from "@coherehealth/core-platform-api";
import { useTheme } from "@material-ui/core/styles";
import { User } from "UserContext";
import { useBelongsToOpsGroup } from "authorization";
import {
  navigateToPS,
  navigateToSRSummary,
  PriorAuthRequirements,
  ServiceRequestFormStateSetters,
} from "components/AuthBuilder/common";
import { ClinicalAssessmentProvider } from "components/AuthBuilder/index";
import { FaxAttachmentContext } from "components/DocumentViewer/FaxAttachment/FaxAttachmentContext";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { error as logError } from "logger";
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import listReplace from "util/listReplace";
import { useGetReturnToUrl } from "util/queryParams";
import {
  formContentFromResponse,
  getHealthCareManager,
  payloadFromSRFormContent,
  useIsHealthHelpEnabled,
} from "util/serviceRequest";
import {
  activityContextFromServiceRequest,
  TrackUserActivityProps,
  useTrackUserImpression,
  useTrackUserInteraction,
} from "util/userActivityTracker";
import ClipboardMissingInfo from "../../images/ClipboardMissingInfo";
import Footer from "../Footer";
import { MutateMethod } from "restful-react";
import EditModal from "./EditModal";
import { getLatestDeterminedServiceRequest } from "util/authorization";
import { SmartOnFhirContext } from "components/SmartOnFhir/SmartOnFhirSecurityProvider";
import {
  navigateStepEvalClinicalAssessment,
  getIntersectionObservationCodesAndSearchStatements,
} from "util/workflowUtils";
import ClinicalAssessmentContext from "components/ClinicalAssessment/ClinicalAssessmentContext";

//Properties used on fax intake flow to know if attachments/caqs need to be skipped
interface ClinicalAssessmentProps {
  authFlowType: string;
  serviceRequests: ServiceRequestResponse[];
  setCaqSkipped: Dispatch<SetStateAction<boolean>>;
  serviceRequestPatientAttributes: Set<string> | undefined;
  clinicalAssessmentSnomedCodes: Set<string> | undefined;
  clinicalAssessmentProviders: ClinicalAssessmentProvider[];
}

interface Props extends ServiceRequestFormStateSetters {
  patientId: string;
  carePathJourney?: CarePathJourney;
  serviceRequestFormContents: ServiceRequestFormContent[];
  setWorkflowStep: (step: AuthBuilderWorkflowStep) => void;
  serviceRequestId?: string;
  workflowStep: AuthBuilderWorkflowStep;
  attemptedServiceRequestFormsSubmit: boolean;
  setAttemptedServiceRequestFormsSubmit: (b: boolean) => void;
  serviceRequestFormsCanBeSubmitted: boolean[];
  addServiceRequest: (serviceRequest: ServiceRequestResponse, newDraftSrId?: string) => void;
  editServiceRequest: Dispatch<ServiceRequestResponse>;
  getCarePathClinicalAssessment: ReturnType<typeof useGetClinicalAssessment>["refetch"];
  getSingleServiceClinicalAssessment: ReturnType<typeof useGetSingleServiceClinicalAssessment>["refetch"];
  getMultiSingleServiceClinicalAssessment: ReturnType<typeof useGetMultiSingleServiceClinicalAssessment>["refetch"];
  loadingClinicalAssessment: boolean;
  setClinicalAssessmentProviders: Dispatch<SetStateAction<ClinicalAssessmentProvider[]>>;
  setHealthHelpPASToken: Dispatch<SetStateAction<HealthHelpPASRequestResponse | undefined>>;
  currUser?: User;
  userFaxExtension?: string;
  serviceRequestFormsHaveNewEdits: boolean[];
  requestor?: Requestor;
  crdLogId?: string;
  setVendorAssessmentCompleted: Dispatch<boolean>;
  setVendorAssessmentError: Dispatch<boolean>;
  workflowId?: string;
  importedEhrOrder?: ServiceRequestResponse;
  oonLoading: boolean;
  getCaqBulkSearchableStatements: (serviceRequestIds: string[]) => Promise<void>;
  isContinuationWorkflow?: boolean;
  authorizationId?: string;
  healthPlanName: string;
  nonPalProcedureCodes: ProcedureCode[];
  vendorAssessmentError: boolean;
  allowDischargeModalToOpen?: boolean;
  setAllowDischargeModalToOpen?: Dispatch<SetStateAction<boolean>>;
  priorAuthRequirements?: PriorAuthRequirements;
  disableContinueButtonOnContinuation?: boolean;
  clinicalAssessmentProps?: ClinicalAssessmentProps;
  setFooterHeight: Dispatch<SetStateAction<number>>;
}

export default function FillFormsFooter({
  patientId,
  carePathJourney,
  serviceRequestFormContents,
  setServiceRequestFormContents,
  crdLogId,
  setWorkflowStep,
  serviceRequestId,
  workflowStep,
  attemptedServiceRequestFormsSubmit,
  setAttemptedServiceRequestFormsSubmit,
  serviceRequestFormsCanBeSubmitted,
  addServiceRequest,
  editServiceRequest,
  getCarePathClinicalAssessment,
  getSingleServiceClinicalAssessment,
  getMultiSingleServiceClinicalAssessment,
  loadingClinicalAssessment,
  setClinicalAssessmentProviders,
  setHealthHelpPASToken,
  currUser,
  userFaxExtension,
  serviceRequestFormsHaveNewEdits,
  setServiceRequestFormsHaveNewEdits,
  requestor,
  setVendorAssessmentCompleted,
  setVendorAssessmentError,
  workflowId,
  importedEhrOrder,
  oonLoading,
  getCaqBulkSearchableStatements,
  isContinuationWorkflow,
  healthPlanName,
  authorizationId,
  nonPalProcedureCodes,
  vendorAssessmentError,
  allowDischargeModalToOpen = false,
  setAllowDischargeModalToOpen,
  priorAuthRequirements,
  disableContinueButtonOnContinuation = false,
  clinicalAssessmentProps,
  setFooterHeight,
}: Props) {
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [delegatedVendorName, setDelegatedVendorName] = useState<Vendor | undefined>(undefined);

  //**Fax intake values that determine if 'Add Attachments'/'Clinical Assessment' steps should be skipped
  const { faxDbId: faxId } = useContext(FaxAttachmentContext);
  const isFaxIntakeFlow = Boolean(faxId);
  const [shouldEvalPreSubmissionStep, setShouldEvalPreSubmissionStep] = useState<boolean>(false);
  const autoApprovalSkipCAQ = useFeature("autoApprovalSkipCAQ");
  const trackUserImpression = useTrackUserImpression();
  //during the authbuild flow delegatedVendorName is set to undefined when HH assessment fails
  const skipWhenVendorAssessmentFails = !delegatedVendorName && vendorAssessmentError;
  const serviceRequest =
    clinicalAssessmentProps?.serviceRequests?.length && clinicalAssessmentProps?.serviceRequests.length > 0
      ? clinicalAssessmentProps.serviceRequests[0]
      : undefined;
  const { clinicalAssessment } = useContext(ClinicalAssessmentContext);
  const noAssessmentQuestions = !clinicalAssessment?.questions || clinicalAssessment?.questions?.length === 0;
  //**End of fax intake values that determine if 'Add Attachments'/'Clinical Assessment' steps should be skipped

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const simplifiedServiceFrequency = useFeature("simplifiedServiceFrequency");
  const isClearerDraftWorkflow = useFeature("clearerDraftWorkflow");
  const multiSingleService = useFeature("multiSingleService");
  const serviceRequestIds = serviceRequestFormContents?.map((sr) => sr.id || "").filter((id) => id !== "");
  const navigate = useNavigate();

  const {
    mutate: createNewServiceRequestOnAuth,
    loading: createNewServiceRequestOnAuthLoading,
    error: createNewServiceRequestOnAuthError,
  } = useCreateAuthorization({});

  const {
    mutate: updateServiceRequest,
    loading: updateServiceRequestLoading,
    error: updateServiceRequestError,
  } = useUpdateServiceRequest({ id: "" });
  const {
    mutate: startHealthHelpIntegration,
    loading: startHealthHelpIntegrationLoading,
    error: startHealthHelpIntegrationError,
  } = useStartHealthHelpIntegration({ id: "" });
  const {
    mutate: withdrawHealthHelpCase,
    loading: withdrawHealthHelpCaseLoading,
    error: withdrawHealthHelpCaseError,
  } = useWithdrawHealthHelpCase({ id: "" });
  const {
    mutate: updateUser,
    loading: userUpdateLoading,
    error: userUpdateError,
  } = useUpdateUserExtension({
    id: currUser?.sub || "",
  });
  const { refetch: getAuthorizationById, loading: loadingAuth } = useGetAuthorizationByIdWithFallback({
    id: authorizationId ?? "",
    lazy: true,
  });
  const { data: serviceRequestFromDb } = useGetServiceRequestByIdWithFallback({ id: serviceRequestIds[0] ?? "" });

  const { mutate: uploadSrAttachment } = useCreateServiceRequestAttachment({ id: "" });
  const { mutate: uploadCpjAttachment } = useCreateCarePathJourneyAttachment({ id: "" });
  const { mutate: updateSROnAuth, loading: updatingSR } = useUpdateServiceRequestOnAuthorization({
    id: authorizationId || "",
    serviceRequestId: serviceRequestIds[0],
  });
  const { mutate: patchAuthorization, loading: patchingAuth } = useUpdateAuthorizationById({ id: "" });
  useEffect(() => {
    if (createNewServiceRequestOnAuthError) {
      servRequestFailedSnackbar(enqueueSnackbar);
    }
  }, [createNewServiceRequestOnAuthError, enqueueSnackbar]);

  useEffect(() => {
    if (updateServiceRequestError) {
      servRequestFailedSnackbar(enqueueSnackbar);
    }
  }, [updateServiceRequestError, enqueueSnackbar]);

  useEffect(() => {
    if (startHealthHelpIntegrationError) {
      setVendorAssessmentError(true);
      logError(
        new Error(
          `${delegatedVendorName} start integration failed. Service Request IDs: [${serviceRequestIds.join(", ")}]`
        )
      );
      delegatedVendorName === HEALTH_HELP_V2_NAME
        ? setWorkflowStep("ADD_ATTACHMENTS")
        : setWorkflowStep("VENDOR_CLINICAL_ASSESSMENT");
    }
  }, [
    delegatedVendorName,
    enqueueSnackbar,
    serviceRequestIds,
    setVendorAssessmentError,
    setWorkflowStep,
    startHealthHelpIntegrationError,
  ]);

  useEffect(() => {
    if (withdrawHealthHelpCaseError) {
      enqueueSnackbar("Could not withdraw previous case from HealthHelp, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [withdrawHealthHelpCaseError, enqueueSnackbar]);

  const { mutate: deleteServiceRequest, loading: deleting, error: deleteError } = useDeleteServiceRequest({});
  useEffect(() => {
    if (deleteError) {
      enqueueSnackbar(`Error removing service request: ${deleteError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, deleteError]);

  const serviceRequestFormsSubmitLoading =
    updateServiceRequestLoading ||
    withdrawHealthHelpCaseLoading ||
    startHealthHelpIntegrationLoading ||
    userUpdateLoading ||
    loadingClinicalAssessment ||
    createNewServiceRequestOnAuthLoading ||
    serviceRequestFormContents.length === 0 ||
    deleting ||
    updatingSR ||
    patchingAuth ||
    loadingAuth;

  const canSubmitServiceRequestForms = !serviceRequestFormsCanBeSubmitted.includes(false);
  const invalidForms = !canSubmitServiceRequestForms && attemptedServiceRequestFormsSubmit;
  const canContinue = !invalidForms && !serviceRequestFormsSubmitLoading && !oonLoading;
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const useHealthHelpIntegration = useIsHealthHelpEnabled();

  const trackUserActivityInteraction = useTrackUserInteraction();
  const addNonPalTracking = async (serviceRequest: ServiceRequestResponse) => {
    if (serviceRequest?.id && nonPalProcedureCodes?.length > 0) {
      //add tracking when the service request has an id and has non pal px
      const userActivityInteractionPayload: TrackUserActivityProps = {
        event: "NON_PAL_CODES_ADDED",
        stage: "AUTH_CREATION",
        activityContext: {
          patientId: patientId,
          serviceRequestId: serviceRequest?.id,
          workflowId: workflowId,
          procedureCodes: nonPalProcedureCodes,
        },
      };
      await trackUserActivityInteraction({ ...userActivityInteractionPayload });
    }
  };
  const onSubmitServiceRequestForms = async (saveAndExit: boolean) => {
    setAttemptedServiceRequestFormsSubmit(!saveAndExit);
    if (allowDischargeModalToOpen) {
      setEditModalOpen(true);
      return;
    }
    let mostRecentlyUsedFaxNumber;
    let mostRecentlyUsedFaxNumberSRId;
    let submitError = false;
    let providers: ClinicalAssessmentProvider[] = [];
    if (saveAndExit || canSubmitServiceRequestForms) {
      for (const index in serviceRequestFormContents) {
        let provider: ClinicalAssessmentProvider = "Cohere";
        if (serviceRequestFormContents[index]?.followUpFaxNumber?.number) {
          mostRecentlyUsedFaxNumber = serviceRequestFormContents[index]?.followUpFaxNumber?.number;
          mostRecentlyUsedFaxNumberSRId = serviceRequestFormContents[index]?.id;
        }
        try {
          provider = await submitServiceRequestForm({
            sr: { ...serviceRequestFormContents[index], workflowStep: workflowStep, crdLogId: crdLogId },
            index: Number(index),
            ids: serviceRequestIds,
            requestor: requestor,
            saveAndExit,
            importedEhrOrder,
          });
          if (!providers.includes(provider)) {
            providers.push(provider);
          }
        } catch (e) {
          enqueueSnackbar("Failed to save service request", { variant: "error" });
          submitError = true;
        }
      }
      setClinicalAssessmentProviders(providers);
      if (providers.includes("Cohere")) {
        await fetchClinicalAssessment();
      }
      if (!submitError) {
        if (mostRecentlyUsedFaxNumber && mostRecentlyUsedFaxNumber !== userFaxExtension) {
          await updateUser({ mostRecentlyUsedFaxNumber }, { pathParams: { id: currUser?.sub || "" } });
          if (!userUpdateError) {
            const userActivityInteractionPayload: TrackUserActivityProps = {
              event: "UPDATE_REQUESTOR_FAX",
              stage: "AUTH_CREATION",
              beforeSnapshot: { requestorFaxNumber: userFaxExtension },
              afterSnapshot: { requestorFaxNumber: mostRecentlyUsedFaxNumber },
            };
            userActivityInteractionPayload.activityContext = {
              patientId: patientId,
              serviceRequestId: mostRecentlyUsedFaxNumberSRId,
              workflowId: workflowId,
            };
            trackUserActivityInteraction({ ...userActivityInteractionPayload });
          }
        }
        if (!saveAndExit) {
          if (workflowStep === "FILL_FORMS_GENERAL_AUTH_SUBMISSION") {
            setWorkflowStep("ADD_ATTACHMENTS");
          } else {
            if (editModalOpen) {
              navigate("/");
            } else {
              if (isFaxIntakeFlow && clinicalAssessmentProps) {
                const props: TrackUserActivityProps = {
                  event: "ADD_ATTACHMENTS_SKIPPED",
                  stage: "ADD_ATTACHMENTS",
                };
                if (!skipWhenVendorAssessmentFails && !!serviceRequest) {
                  props.activityContext = activityContextFromServiceRequest(serviceRequest);
                }
                await trackUserImpression(props);
                setShouldEvalPreSubmissionStep(true);
              } else {
                setWorkflowStep("ADD_ATTACHMENTS_CONTINUATION");
              }
            }
          }
        }
      }
    }
  };

  // Fax ID of fax intake when redirected from Fax intake workflow to auth builder
  // Used to automatically link it to the Auth
  const { faxDbId: defaultFaxId } = useContext(FaxAttachmentContext);
  const isGhpUser = useBelongsToOpsGroup("GEISINGER");
  const allUsersUpdatedFaxWorkflow = useFeature("updatedFaxWorkflowAllUsers");
  const shouldAutoAttach = allUsersUpdatedFaxWorkflow || Boolean(isGhpUser);

  const getPayload = (sr: ServiceRequestFormContent): ServiceRequestCreatePayload => {
    const requestType = isContinuationWorkflow ? "CONTINUATION" : "INITIAL";
    return {
      ...payloadFromSRFormContent(
        sr,
        simplifiedServiceFrequency,
        undefined,
        workflowId,
        requestType === "CONTINUATION"
      ),
      patient: patientId,
      workflowId: workflowId,
      ehrOrderId: importedEhrOrder?.ehrOrderId,
      ehrPracticeId: importedEhrOrder?.ehrPracticeId,
      ehrVendor: importedEhrOrder?.ehrVendor,
      ehrPatientId: importedEhrOrder?.ehrPatientId,
      ehrDepartmentId: importedEhrOrder?.ehrDepartmentId,
      ehrEncounterId: importedEhrOrder?.ehrEncounterId,
      requestType: requestType,
      tatStartTimestamp: requestor?.tatStartTimestamp,
    };
  };

  const smartClient = useContext(SmartOnFhirContext);
  const inSmartOnFhirWorkflow = Boolean(smartClient);

  const skipAutoAttach = useMemo(
    () =>
      Boolean(
        serviceRequestFromDb?.attachments?.find((attachment) => attachment.linkedFaxAttachment?.id === defaultFaxId)
      ),
    [defaultFaxId, serviceRequestFromDb?.attachments]
  );

  const attemptFaxAutoAttach = useCallback(
    async (srId: string) => {
      if (skipAutoAttach) {
        return;
      }
      const formData = new FormData();
      formData.set("faxId", defaultFaxId);
      // Do not block auth submission if unable to auto attach fax
      try {
        if (carePathJourney?.id) {
          await uploadCpjAttachment(formData as unknown as void, { pathParams: { id: carePathJourney?.id || "" } });
        } else {
          await uploadSrAttachment(formData as unknown as void, {
            pathParams: { id: srId },
          });
        }
      } catch (e) {
        logError(new Error(`Unable to auto attach fax to new service request: ${e}`));
      }
    },
    [carePathJourney?.id, defaultFaxId, skipAutoAttach, uploadCpjAttachment, uploadSrAttachment]
  );

  // Submits a Service Request to the back-end. Either updates or creates the SR.
  // Returns the SR's delegated vendor (or empty string if none)
  const submitServiceRequestForm = async ({
    sr,
    index,
    ids,
    requestor,
    saveAndExit,
    importedEhrOrder,
  }: {
    sr: ServiceRequestFormContent;
    index: number;
    ids: string[];
    requestor: Requestor | undefined;
    saveAndExit: boolean;
    importedEhrOrder?: ServiceRequestResponse;
  }): Promise<ClinicalAssessmentProvider | undefined> => {
    const payload = getPayload(sr);
    const authorizationPayload: AuthorizationCreatePayload = {
      patient: patientId,
      healthPlanName,
      userSelectedOONException: sr.userSelectedOONException ?? false,
      serviceRequests: [payload],
      dischargedTo: sr.dischargedTo?.enumName ?? undefined,
    };
    let isDelegatedToHealthHelp = false;
    if (sr?.id) {
      let response: ServiceRequestResponse;
      if (isContinuationWorkflow) {
        if (shouldAutoAttach && defaultFaxId) {
          await attemptFaxAutoAttach(sr.id);
        }
        response = await updateSROnAuth({ ...payload });
        if (sr?.dischargedTo && response?.authorization?.id && sr?.patientStatus) {
          const authRes = await patchAuthorization(
            { dischargedTo: sr.dischargedTo.enumName },
            { pathParams: { id: response.authorization.id } }
          );
          response.dischargedTo = authRes?.dischargedTo;
        }
      } else {
        response = await updateServiceRequest(
          { ...payload, carePathJourney: carePathJourney?.id },
          { pathParams: { id: sr?.id } }
        );
      }
      await addNonPalTracking(response);
      setDelegatedVendorName(response.delegatedVendor);
      if (
        useHealthHelpIntegration &&
        (response.delegatedVendor === HEALTH_HELP_NAME || response.delegatedVendor === HEALTH_HELP_V2_NAME) &&
        !saveAndExit
      ) {
        isDelegatedToHealthHelp = true;
        if (serviceRequestFormsHaveNewEdits[index]) {
          setVendorAssessmentError(false);
          // withdraw existing health help case and start a new one
          if (response.vendorIdentifier) {
            await withdrawHealthHelpCase(
              {},
              {
                pathParams: { id: sr.id },
              }
            );
          }
          await integrateWithHealthHelp(
            startHealthHelpIntegration,
            sr.id,
            setHealthHelpPASToken,
            vendorAssessmentError,
            delegatedVendorName
          );
          setVendorAssessmentCompleted(false);
        }
      }
      if (response) {
        editServiceRequest(response);
      }
      if (saveAndExit) {
        if (inSmartOnFhirWorkflow) {
          navigateToSRSummary(response?.id, navigate, true);
        } else {
          navigateToPS(patientId, navigate, response?.id);
        }
      }
    } else {
      const authorizationResp = await createNewServiceRequestOnAuth(authorizationPayload);
      // If this SR case was created from faxId, then immediately
      // link the fax attachment to the newly created SR
      let newDraftSrId = "";
      if (
        shouldAutoAttach &&
        authorizationResp?.serviceRequestsOnAuth?.length &&
        authorizationResp.serviceRequestsOnAuth[0].id &&
        defaultFaxId
      ) {
        await attemptFaxAutoAttach(authorizationResp?.serviceRequestsOnAuth[0]?.id || "");
        newDraftSrId = authorizationResp.serviceRequestsOnAuth[0].id;
      }

      if (authorizationResp?.serviceRequestsOnAuth?.length) {
        setDelegatedVendorName(authorizationResp?.serviceRequestsOnAuth[0].delegatedVendor);
        addServiceRequest(authorizationResp.serviceRequestsOnAuth[0], newDraftSrId);
        await addNonPalTracking(authorizationResp.serviceRequestsOnAuth[0]);
      }
      if (
        useHealthHelpIntegration &&
        authorizationResp?.serviceRequestsOnAuth?.length &&
        authorizationResp?.serviceRequestsOnAuth[0]?.delegatedVendor &&
        (authorizationResp?.serviceRequestsOnAuth[0]?.delegatedVendor === HEALTH_HELP_NAME ||
          authorizationResp?.serviceRequestsOnAuth[0]?.delegatedVendor === HEALTH_HELP_V2_NAME) &&
        !saveAndExit
      ) {
        isDelegatedToHealthHelp = true;
        await integrateWithHealthHelp(
          startHealthHelpIntegration,
          authorizationResp?.serviceRequestsOnAuth[0].id || "",
          setHealthHelpPASToken,
          vendorAssessmentError,
          delegatedVendorName
        );
      } else if (authorizationResp?.serviceRequestsOnAuth?.length) {
        ids.push(authorizationResp?.serviceRequestsOnAuth[0]?.id);
      }

      if (authorizationResp) {
        setServiceRequestFormContents((prev) => {
          if (authorizationResp?.serviceRequestsOnAuth?.length) {
            return prev
              .slice(0, index)
              .concat([formContentFromResponse(authorizationResp.serviceRequestsOnAuth[0])])
              .concat(prev.slice(index + 1));
          }
          return prev;
        });
        setServiceRequestFormsHaveNewEdits((prev) => listReplace(prev, index, false));
        await getCaqBulkSearchableStatements(serviceRequestIds);
        if (authorizationResp?.serviceRequestsOnAuth?.length) {
          if (saveAndExit) {
            if (inSmartOnFhirWorkflow) {
              navigateToSRSummary(authorizationResp?.serviceRequestsOnAuth?.[0]?.id, navigate, true);
            } else {
              navigateToPS(patientId, navigate, authorizationResp?.serviceRequestsOnAuth?.[0]?.id);
            }
          }
        }
      }
    }

    return getHealthCareManager(isDelegatedToHealthHelp);
  };

  const returnToUrl = useGetReturnToUrl();

  const fetchClinicalAssessment = async () => {
    if (carePathJourney) {
      await getCarePathClinicalAssessment({
        pathParams: { journeyId: carePathJourney.id || "" },
        queryParams: { serviceRequestIds: serviceRequestIds },
      });
    } else {
      if (multiSingleService) {
        if (serviceRequestIds.length > 0) {
          await getMultiSingleServiceClinicalAssessment({ queryParams: { serviceRequestIds: serviceRequestIds } });
        } else {
          await getSingleServiceClinicalAssessment({
            pathParams: { id: serviceRequestIds[serviceRequestIds.length - 1] },
          });
        }
      }
    }
  };

  const onSaveAndExitClickButton = async () => {
    await onSubmitServiceRequestForms(true);
  };

  const handleDischargeModalSaveChanges = async () => {
    // fetch the draft SR from serviceRequestFormContents
    const srToDelete = serviceRequestFormContents[0];
    let latestSrId;
    try {
      if (srToDelete?.id) {
        // delete the draft SR
        await deleteServiceRequest(srToDelete.id);
        // get the authorization response
        const authorization = await getAuthorizationById();
        // retrieve the latest determined SR from authorization
        const latestSr = authorization && getLatestDeterminedServiceRequest(authorization);
        latestSrId = latestSr?.id;
        // update the discharge fields
        const dischargedFieldsChanged =
          !!srToDelete?.dischargeDate || (!!srToDelete.dischargeTime && srToDelete.dischargeTime !== "");
        const notYetAdmittedExpectedAdmissionDate = srToDelete && srToDelete.expectedAdmissionDate !== null;

        if (authorization?.id && latestSr?.id) {
          if (dischargedFieldsChanged || notYetAdmittedExpectedAdmissionDate) {
            // create a SR payload
            const modifiedSRPayload: ServiceRequestCreatePayload = {};

            if (latestSr.patientStatus !== srToDelete.patientStatus) {
              modifiedSRPayload.patientStatus = srToDelete.patientStatus;
            }

            const srToDeleteAdmissionDate = formatDateToISODate(srToDelete.admissionDate);
            if (latestSr.admissionDate !== srToDeleteAdmissionDate) {
              modifiedSRPayload.admissionDate = srToDeleteAdmissionDate;
            }

            const srToDeleteAdmissionTime =
              srToDelete?.patientStatus !== "NOT_YET_ADMITTED" ? srToDelete.admissionTime + ":00" : "";
            if (latestSr.admissionTime !== srToDeleteAdmissionTime) {
              modifiedSRPayload.admissionTime = srToDeleteAdmissionTime;
            }

            if (latestSr.admissionSource !== srToDelete.admissionSource?.enumName) {
              modifiedSRPayload.admissionSource = srToDelete.admissionSource?.enumName;
            }

            const srToDeleteDischargeDate = formatDateToISODate(srToDelete.dischargeDate);
            if (srToDeleteDischargeDate && latestSr.dischargeDate !== srToDeleteDischargeDate) {
              modifiedSRPayload.dischargeDate = formatDateToISODate(srToDelete.dischargeDate);
            }
            const srToDeleteDischargeTime =
              srToDelete?.patientStatus !== "NOT_YET_ADMITTED" ? srToDelete.dischargeTime + ":00" : "";
            if (srToDelete.dischargeTime && latestSr.dischargeTime !== srToDeleteDischargeDate) {
              modifiedSRPayload.dischargeTime = srToDeleteDischargeTime;
            }
            const srToDeleteExpectedAdmissionDate = formatDateToISODate(srToDelete.expectedAdmissionDate);
            if (latestSr.expectedAdmissionDate !== srToDeleteExpectedAdmissionDate) {
              modifiedSRPayload.expectedAdmissionDate = srToDeleteExpectedAdmissionDate;
            }
            if (latestSr.primarySemanticDiagnosisCode !== priorAuthRequirements?.primaryDiagnosis) {
              modifiedSRPayload.primarySemanticDiagnosisCode = priorAuthRequirements?.primaryDiagnosis;
            }

            if (latestSr.secondarySemanticDiagnosisCodes !== priorAuthRequirements?.secondaryDiagnoses) {
              modifiedSRPayload.secondarySemanticDiagnosisCodes = priorAuthRequirements?.secondaryDiagnoses;
            }

            if (latestSr.selectedOrderingProvider?.id !== srToDelete.selectedOrderingProvider) {
              modifiedSRPayload.selectedOrderingProvider = srToDelete.selectedOrderingProvider;
            }

            if (latestSr.selectedPerformingProvider?.id !== srToDelete.selectedPerformingProvider) {
              modifiedSRPayload.selectedPerformingProvider = srToDelete.selectedPerformingProvider;
            }

            if (latestSr.selectedFacility?.id !== srToDelete.selectedFacility) {
              modifiedSRPayload.selectedFacility = srToDelete.selectedFacility;
            }
            // update the SR
            await updateServiceRequest({ ...modifiedSRPayload }, { pathParams: { id: latestSr?.id } });
          }
          // update the authorization
          if (
            srToDelete?.dischargedTo ||
            (srToDelete?.patientStatus === "NOT_YET_ADMITTED" && srToDelete?.expectedAdmissionDate)
          ) {
            await patchAuthorization(
              {
                dischargedTo: srToDelete?.dischargedTo?.enumName,
                expectedAdmissionDate: formatDateToISODate(srToDelete.expectedAdmissionDate),
              },
              { pathParams: { id: authorization.id } }
            );
          }
        }
      }
    } catch (error) {
      enqueueSnackbar(`Could not save changes ${error instanceof Error ? error.message : ""}`, {
        variant: "error",
        preventDuplicate: true,
      });
    } finally {
      // navigate to patient summary
      navigate(`/patient_summary/${patientId}${latestSrId ? "?reviewServiceRequestId=" + latestSrId : ""}`);
    }
  };

  const updateServiceRequestWorkflowStep = useCallback(async () => {
    if (!!clinicalAssessmentProps?.serviceRequests) {
      for (const sr of clinicalAssessmentProps.serviceRequests) {
        await updateServiceRequest({ workflowStep: workflowStep }, { pathParams: { id: sr?.id } });
      }
    }
  }, [clinicalAssessmentProps, updateServiceRequest, workflowStep]);

  const evalContinueToPreSubmissionStep = useCallback(async () => {
    if (!!clinicalAssessmentProps) {
      if (isClearerDraftWorkflow && clinicalAssessmentProps.authFlowType !== "PHARMACY") {
        await updateServiceRequestWorkflowStep();
      }

      /**
       * If intersection exists don't skip caq questions, auth could pend overall
       * Else if auth would approve, it's safe to skip caq questions
       */
      const hasCaqSearchableStatementsIntersect = getIntersectionObservationCodesAndSearchStatements(
        clinicalAssessmentProps.serviceRequestPatientAttributes,
        clinicalAssessmentProps.clinicalAssessmentSnomedCodes
      );

      const canAutoApprove = !!(autoApprovalSkipCAQ && !hasCaqSearchableStatementsIntersect);

      const canContinueToReview = canAutoApprove || noAssessmentQuestions;

      await navigateStepEvalClinicalAssessment({
        trackUserImpression,
        noAssessmentQuestions,
        setCaqSkipped: clinicalAssessmentProps.setCaqSkipped,
        isContinuationWorkflow,
        isCurrentDedicatedNudge: false, //fax intake flow does not use dedicated nudges
        setWorkflowStep,
        clinicalAssessmentProviders: clinicalAssessmentProps.clinicalAssessmentProviders,
        goToReview: canContinueToReview,
        skipWhenVendorAssessmentFails,
        serviceRequest,
      });
    }
  }, [
    clinicalAssessmentProps,
    autoApprovalSkipCAQ,
    isClearerDraftWorkflow,
    trackUserImpression,
    noAssessmentQuestions,
    isContinuationWorkflow,
    setWorkflowStep,
    skipWhenVendorAssessmentFails,
    serviceRequest,
    updateServiceRequestWorkflowStep,
  ]);

  useEffect(() => {
    if (shouldEvalPreSubmissionStep && !!clinicalAssessment?.questions && !!clinicalAssessmentProps) {
      evalContinueToPreSubmissionStep();
    }
  }, [clinicalAssessment, shouldEvalPreSubmissionStep, evalContinueToPreSubmissionStep, clinicalAssessmentProps]);

  return (
    <>
      <Footer
        onPrimaryButtonClick={() => onSubmitServiceRequestForms(false)}
        onSecondaryButtonClick={() => setCancelModalOpen(true)}
        primaryButtonDisabled={!canContinue || serviceRequestFormsSubmitLoading || disableContinueButtonOnContinuation}
        primaryButtonLoading={serviceRequestFormsSubmitLoading}
        primaryButtonText={"Continue"}
        secondaryButtonText={"Cancel"}
        secondaryButtonDisabled={disableContinueButtonOnContinuation}
        errorCaptionText={!disableContinueButtonOnContinuation ? "There are some errors with the details above." : ""}
        showError={invalidForms}
        showSaveAndExitButton={isClearerDraftWorkflow}
        showSecondaryButton={isContinuationWorkflow}
        onSaveAndExitClick={onSaveAndExitClickButton}
        saveAndExitButtonDisabled={serviceRequestFormsSubmitLoading || disableContinueButtonOnContinuation}
        setFooterHeight={setFooterHeight}
        isContinuationWorkflow={isContinuationWorkflow}
      />
      <InformativeModal
        open={cancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        icon={<ClipboardMissingInfo style={{ color: theme.palette.warning.dark }} />}
        headerText={
          isContinuationWorkflow
            ? "Are you sure you want to cancel this continuation request?"
            : "Are you sure you want to cancel this authorization?"
        }
        subHeaderText="Your progress will not be saved."
        primaryButtonText="Yes, cancel"
        primaryButtonAction={async () => {
          for (const index in serviceRequestFormContents) {
            if (serviceRequestFormContents[index]?.id) {
              await deleteServiceRequest(serviceRequestFormContents[index].id || "");
            }
          }
          navigate(returnToUrl);
        }}
        tertiaryButtonText={
          isContinuationWorkflow ? "No, return to continuation request" : "Continue with authorization"
        }
        tertiaryButtonAction={() => setCancelModalOpen(false)}
        showDivider={!isContinuationWorkflow}
      />
      <EditModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        primaryButtonActions={handleDischargeModalSaveChanges}
        tertiaryButtonAction={() => setEditModalOpen(false)}
        primaryButtonLoading={deleting || updatingSR || patchingAuth || loadingAuth}
      />
    </>
  );
}

export async function integrateWithHealthHelp(
  startHealthHelpIntegration: MutateMethod<
    HealthHelpPASRequestResponse,
    StartHealthHelpIntegrationRequestBody,
    void,
    StartHealthHelpIntegrationPathParams
  >,
  serviceRequestId: string,
  setHealthHelpPASToken: Dispatch<SetStateAction<HealthHelpPASRequestResponse | undefined>>,
  vendorAssessmentError: boolean,
  delegatedVendorName: string | undefined
) {
  const hasNoHealthHelpV2Error = [HEALTH_HELP_V2_NAME, undefined].includes(delegatedVendorName as Vendor | undefined)
    ? !vendorAssessmentError
    : true;
  if (hasNoHealthHelpV2Error) {
    const healthHelpIntegrationResponse = await startHealthHelpIntegration(
      {},
      {
        pathParams: { id: serviceRequestId },
      }
    );
    setHealthHelpPASToken(healthHelpIntegrationResponse);
  }
}

function servRequestFailedSnackbar(
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey
) {
  enqueueSnackbar("Failed to create service request, please try again", {
    variant: "error",
    preventDuplicate: true,
  });
}
