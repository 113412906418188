/** @jsxRuntime classic */
// IE11 needs "jsxRuntime classic" enabled for this file,
// see https://github.com/facebook/create-react-app/issues/9906

// Polyfill things required by @react-pdf/viewer - this was once included in webpack, but is not as of webpack v5
import "./react-pdf-render-polyfill";

// Polyfills for stupid IE11
import "intersection-observer-polyfill/index.global";
import "@juggle/resize-observer";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "formdata-polyfill";
import "ts-replace-all";
import "@okta/okta-auth-js/polyfill";

import "date-time-format-timezone/build/browserified/date-time-format-timezone-golden-zones-golden-locales-min";

import React from "react";
import { init } from "./logger";

import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import config from "./api/config";
import App from "./App";

init();

if (process.env.REACT_APP_MIRAGE && process.env.REACT_APP_MIRAGE !== "false") {
  import("@coherehealth/core-platform-api").then((mirage) => {
    mirage.loadDb();
    mirage.startServer({
      urlPrefix: config.SERVICE_API_URL,
      passthrough: [`${config.AUTH_API_URL}**`, `${config.SERVICE_API_URL}**`],
    });
    mirage.loadAllRoutes();
  });
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
