import React from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { withStyles, Theme, styled } from "@material-ui/core/styles";
import Button, { ButtonProps, ButtonTypeMap } from "@material-ui/core/Button";
import MuiCircularProgress from "@material-ui/core/CircularProgress";
import { ExtendButtonBase } from "@material-ui/core/ButtonBase";
import { colorsLight, colorsDark } from "../../themes/colors";

const styles = (theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  return {
    root: {
      padding: theme.spacing(2, 5),
    },
    disabled: {},
    containedPrimary: {
      boxShadow: "none",
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      "&:hover, &:focus": {
        boxShadow: "none",
        backgroundColor: isDarkTheme ? colorsDark.background.light : colorsLight.action.hoverBackground,
      },
      "&:active": {
        backgroundColor: isDarkTheme ? colorsDark.background.card : colorsLight.action.hoverBackground,
        color: theme.palette.primary.dark,
      },
      "&$disabled": {
        backgroundColor: "transparent",
        color: isDarkTheme ? "#313C4E" : colorsLight.black.disabled,
      },
    },
    // warning mode
    containedSecondary: {
      boxShadow: "none",
      color: theme.palette.error.main,
      backgroundColor: "transparent",
      "&:hover, &:focus": {
        boxShadow: "none",
        backgroundColor: theme.palette.error.light,
      },
      "&:active": {
        backgroundColor: theme.palette.error.light,
      },
      "&$disabled": {
        backgroundColor: "transparent",
        color: isDarkTheme ? "#313C4E" : colorsLight.black.disabled,
      },
    },
    // small button
    containedSizeSmall: {
      color: theme.palette.text.secondary,
      boxShadow: "none",
      padding: theme.spacing(1, 2),
      "&:hover, &:focus": {
        boxShadow: "none",
        backgroundColor: colorsLight.gray.light,
      },
      "&:active": {
        color: theme.palette.text.primary,
      },
      "&$disabled": {
        backgroundColor: "transparent",
        color: isDarkTheme ? "#313C4E" : theme.palette.text.disabled,
      },
    },
  };
};

export interface ExtraButtonProps {
  loading?: boolean;
  warning?: boolean;
}
const TertiaryButton = React.forwardRef((props: ButtonProps & ExtraButtonProps, ref: any) => {
  // pull out external props that we hardcode, only pass through otherProps
  const { classes, children, color, variant, loading, warning, ...otherProps } = props;
  return (
    <Button
      ref={ref}
      classes={classes}
      variant="contained"
      color={warning ? "secondary" : "primary"}
      {...otherProps}
      data-public
    >
      {Boolean(loading) && <CircularProgress color="inherit" size={32} />}
      <div style={loading ? { visibility: "hidden" } : {}}>{children}</div>
    </Button>
  );
});
TertiaryButton.displayName = "TertiaryButton";

export default withStyles(styles)(TertiaryButton) as ExtendButtonBase<ButtonTypeMap<ExtraButtonProps>>;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CircularProgress = styled(MuiCircularProgress)({
  position: "absolute",
});
