import { Card } from "@coherehealth/common";
import { Box, Button, makeStyles, useTheme } from "@material-ui/core";
import { H4 } from "@coherehealth/design-system";
import { ReferralRequestResponse } from "@coherehealth/core-platform-api";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReferralRequestStatusDisplay from "../common/ReferralRequestStatusDisplay";
import { useState } from "react";
import ReferralRequestSummaryDetails from "./ReferralRequestSummaryDetails";
import { splitCamelToString } from "../common/stringutils";

interface Props {
  referralRequest: ReferralRequestResponse;
  expand?: boolean;
  onEditIntegration: (id: string) => void;
}

const useStyles = makeStyles((theme) => ({
  summaryCard: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  expandIconButton: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    padding: 0,
    minWidth: 0,
    marginLeft: "auto",
  },
  expandIconNormal: {
    color: theme.palette.primary.main,
    transition: theme.transitions.create("transform"),
  },
  expandIconRotated: {
    color: theme.palette.primary.main,
    transition: theme.transitions.create("transform"),
    transform: "rotate(180deg)",
  },
}));

export default function ReferralRequestSummaryCard({ referralRequest, expand, onEditIntegration }: Props) {
  const [expanded, setExpanded] = useState<boolean>(expand || false);

  const classes = useStyles();
  const theme = useTheme();

  const toggleExpandIcon = () => {
    setExpanded((prevState) => !prevState);
  };

  return (
    <>
      <Card className={classes.summaryCard}>
        <Box style={{ display: "flex", marginBottom: theme.spacing(3) }}>
          <H4 style={{ paddingTop: theme.spacing(0.5) }}>
            {splitCamelToString(referralRequest.specialty)} Referral Request
          </H4>
          <Button className={classes.expandIconButton} onClick={toggleExpandIcon}>
            <ExpandMoreIcon className={expanded ? classes.expandIconRotated : classes.expandIconNormal} />
          </Button>
        </Box>
        <ReferralRequestStatusDisplay referralRequest={referralRequest} />
        <ReferralRequestSummaryDetails
          referralRequest={referralRequest}
          expanded={expanded}
          onEditIntegration={onEditIntegration}
        />
      </Card>
    </>
  );
}
