import React from "react";
import { H1, H4 } from "../Typography";
import { PrimaryButton } from "../PrimaryButton";
import FourOFour from "../images/FourOFour";
import Container from "@material-ui/core/Container";
import { useMuiContainerStyles } from "../../hooks";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";

export default function NotFoundPage() {
  const containerClasses = useMuiContainerStyles();

  return (
    <Container classes={containerClasses} maxWidth="lg" data-testid="dashboard-page">
      <CenteredContainer style={{ marginTop: 60 }}>
        <FourOFour />
      </CenteredContainer>
      <CenteredContainer style={{ marginTop: 50, textAlign: "center" }}>
        <Title>Sorry, this page is missing.</Title>
      </CenteredContainer>
      <CenteredContainer style={{ marginTop: 12, textAlign: "center" }}>
        <H4 color="textSecondary">Looks like we sent you down the wrong path. Let’s get you back!</H4>
      </CenteredContainer>
      <CenteredContainer style={{ marginTop: 114 }}>
        <PrimaryButton color="primary" onClick={() => window.location.assign("/")}>
          Back to dashboard
        </PrimaryButton>
      </CenteredContainer>
    </Container>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Title = styled(H1)({
  fontWeight: 600,
  fontSize: 50,
  lineHeight: "80px",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CenteredContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
});
