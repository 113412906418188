import React, { useCallback } from "react";

import { downloadUrl, PrimaryButton, TertiaryButton, Tooltip } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Grid, styled, makeStyles } from "@material-ui/core";
import GetApp from "@material-ui/icons/GetApp";
import Print from "@material-ui/icons/Print";
import { PrintTypes } from "print-js";
import print from "util/print";
import { SupportedFileType } from "./DocumentViewer";
import { FaxSidebarView } from "./FaxAttachment/common";

interface Props {
  /**
   * If the associated file can be deleted
   */
  canDelete: boolean;
  /**
   * Called when the delete button is pressed
   */
  onDelete?: () => void;
  /**
   * The url to the document resource
   */
  url?: string;
  /**
   * The name of the file
   */
  fileName?: string;
  /**
   * The type of the file
   */
  fileType?: SupportedFileType;
  /**
   * If true, the print button will not show
   */
  hidePrintButton?: boolean;
  /**
   * If true, the download button will display at the bottom of the sidebar
   */
  downloadButtonOnBottom?: boolean;
  /**
   * Sets the fax sidebar view (if applicable)
   */
  setFaxSidebarView?: (view: FaxSidebarView) => void;
  /**
   * Closes the fax modal (if applicable)
   */
  closeFaxModal: (() => void) | undefined;

  convertedToPDF?: boolean;
}

const useStyles = makeStyles({
  downloadBottomGrid: {
    paddingTop: "auto",
    position: "absolute",
    bottom: 16,
    width: "90%",
  },
});

export default function FileActions({
  canDelete,
  onDelete,
  url,
  fileName,
  fileType,
  hidePrintButton,
  downloadButtonOnBottom,
  setFaxSidebarView,
  closeFaxModal,
  convertedToPDF,
}: Props) {
  const downloadAttachment = useCallback(() => {
    if (!url) {
      return;
    } else if (convertedToPDF) {
      const splitFileName = fileName?.split(".");
      splitFileName?.splice(splitFileName.length - 1, 1, "pdf");
      const pdfConvertedFileName = splitFileName?.join(".");
      downloadUrl(url, pdfConvertedFileName);
    } else {
      downloadUrl(url, fileName);
    }
  }, [url, convertedToPDF, fileName]);

  // fileType is an enum and can have a value of "0", so we have to check for !==undefined instead of js truthy check
  const canPrint = Boolean(url && fileType !== undefined && getFileTypeForPrint(fileType));

  const classes = useStyles();

  const printAttachment = useCallback(() => {
    if (!canPrint) {
      return;
    }
    // Can coerce url + fileType to non-undefined b/c it is checked in canPrint
    print(url!, getFileTypeForPrint(fileType!));
  }, [url, fileType, canPrint]);

  return (
    <Grid container direction="row" spacing={1}>
      {url ? (
        <>
          <Grid item xs={12} className={downloadButtonOnBottom ? classes.downloadBottomGrid : undefined}>
            <PrimaryButton
              fullWidth
              onClick={() => {
                downloadAttachment();
                if (setFaxSidebarView) {
                  setFaxSidebarView("DOWNLOAD_CONFIRMATION");
                }
                if (closeFaxModal) {
                  closeFaxModal();
                }
              }}
              endIcon={<GetApp />}
            >
              Download
            </PrimaryButton>
          </Grid>
          {canPrint && !hidePrintButton && (
            <Grid item xs={12}>
              <PrimaryButton fullWidth onClick={printAttachment} endIcon={<Print />}>
                Print
              </PrimaryButton>
            </Grid>
          )}
        </>
      ) : (
        <Grid item xs={12}>
          <Tooltip title="Attachment is not available for download">
            <ButtonTooltipContainer>
              <PrimaryButton disabled fullWidth>
                Download
              </PrimaryButton>
            </ButtonTooltipContainer>
          </Tooltip>
        </Grid>
      )}
      {onDelete && (
        <Grid item xs={12}>
          <TertiaryButton
            disabled={!canDelete}
            fullWidth
            warning
            onClick={(e) => {
              e.preventDefault();
              onDelete();
            }}
          >
            Delete
          </TertiaryButton>
        </Grid>
      )}
    </Grid>
  );
}

/* https://material-ui.com/components/tooltips/#disabled-elements */
// eslint-disable-next-line cohere-react/no-mui-styled-import
const ButtonTooltipContainer = styled("span")({ display: "block" });

const getFileTypeForPrint = (fileType: SupportedFileType): PrintTypes => {
  switch (fileType) {
    case SupportedFileType.JPG:
      return "image";
    case SupportedFileType.PDF:
      return "pdf";
  }
};
