import React, { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetServiceRequests } from "@coherehealth/core-platform-api";
import routes from "routes";

const RedirectToSmartOnFhirServiceRequestPage: FunctionComponent<{
  cohereId: string;
}> = ({ cohereId, children }) => {
  const urlSlug = "smart_on_fhir_service_request";
  const navigate = useNavigate();
  const { data: serviceRequests } = useGetServiceRequests({ queryParams: { cohereId: `eq:${cohereId}` } });
  useEffect(() => {
    if (serviceRequests && serviceRequests.length > 0) {
      navigate(`/${urlSlug}/${serviceRequests[0].id}`);
    } else if (serviceRequests?.length === 0) {
      navigate(routes.SMART_ON_FHIR_NOT_FOUND);
    }
  }, [serviceRequests, navigate]);

  return <>{children}</>;
};

export default RedirectToSmartOnFhirServiceRequestPage;
