import { ResponseOption } from "@coherehealth/core-platform-api";
import {
  Bundle,
  Endpoint,
  OperationOutcome,
  OperationOutcome_Issue,
  Questionnaire,
  Questionnaire_Item,
} from "@coherehealth/fhir-schema-types";
import { Dispatch, SetStateAction } from "react";
import { error as logError, warn as logWarn } from "logger";
import { addBreadcrumb } from "@sentry/react";

type AllowableQuestionType =
  | "boolean"
  | "decimal"
  | "integer"
  | "date"
  | "dateTime"
  | "time"
  | "string"
  | "text"
  | "url"
  | "choice"
  | "open-choice"
  | "quantity"
  | "multi-choice"; // todo this isn't part of fhir, fhir just sends a group of boolean types for multi-choice, but that isn't useful
interface Question {
  id: string;
  questionText: string;
  subQuestionText?: string;
  type?: AllowableQuestionType;
  options?: HealthHelpQuestionAnswerOption[];
  required?: boolean;
}
interface HealthHelpQuestionAnswerOption extends ResponseOption {
  id: string;
}

const NON_COLON_THEN_COLON_AND_MAYBE_WHITESPACE_THEN_THE_REST = /^([^:]+):\s*(.+$)/;

//TODO band aid for the question shape affecting the userAnswers affecting the questionnaire response to health help
export const questionnaireToQuestionBundleForQuestionRendering = (
  questionnaire: Questionnaire,
  setError: Dispatch<boolean>,
  getQuestionnaireLoading: boolean
): Question[] => {
  addBreadcrumb({ level: "info", data: { "health help transaction token": questionnaire?.identifier } });
  if (getQuestionnaireLoading) {
    return [];
  }
  if (!questionnaire.item) {
    logError(new Error("FHIR Questionnaire bundle malformed. Group type items must have children items."));
    setError(true);
    return [];
  }
  let retQuestions: Array<Question> = [];

  const questionItems = flattenQuestionItems(questionnaire.item);
  questionItems.forEach((item, index) => {
    if (item && item.type) {
      // Todo find a better solution: We don't want to continue flattening question displayItems
      // if that question is a multi-select checkbox thing. But we do want to flatten them if the
      // question just contains true/false or single select radio buttons.
      // It's confusing and there must be a better way, but this should satisfy the display
      // requirements for now
      const isMultiSelectQuestion = item.item?.every(
        (question) =>
          question.extension?.find(({ url }) => url?.includes("questionnaire-itemControl"))?.valueCodeableConcept
            ?.text === "Check Box"
      );

      if (isMultiSelectQuestion) {
        retQuestions.push({
          id: item.linkId || index.toString(),
          questionText: item.text || "",
          type: "multi-choice",
          required: item.required,
          options: getMultiSelectOptions(item),
        });
      } else {
        if (item.item) {
          item.item.forEach((question) => {
            if (question) {
              if (isAllowableQuestionType(question.type)) {
                try {
                  retQuestions.push({
                    id: question.linkId || index.toString(),
                    questionText: questionText(item.text || "", question.text || "") || "",
                    type: question.type,
                    required: question.required,
                    options: getAnswerOptions(item.item![0] || undefined),
                  });
                } catch (e) {
                  logError(new Error(`Error parsing questions from Questionnaire Bundle: ${e}`));
                  return;
                }
              } else {
                logError(new Error(`Invalid question type: ${question.type}`));
              }
            }
          });
        } else {
          if (isAllowableQuestionType(item.type)) {
            try {
              retQuestions.push({
                id: item.linkId || index.toString(),
                questionText: item.text || "",
                type: item.type,
                required: item.required,
                options: getAnswerOptions(item || undefined),
              });
            } catch (e) {
              logError(new Error(`Error parsing questions from Questionnaire Bundle: ${e}`));
              return;
            }
          } else {
            logError(new Error(`Invalid question type: ${item.type}`));
          }
        }
      }
    }
  });

  return retQuestions;
};

export const questionnaireToQuestionBundle = (
  questionnaire: Questionnaire,
  setError: Dispatch<boolean>
): Question[] => {
  addBreadcrumb({ level: "info", data: { id: questionnaire?.identifier } });

  if (!questionnaire.item) {
    logError(new Error("FHIR Questionnaire bundle malformed. Group type items must have children items."));
    setError(true);
    return [];
  }
  let retQuestions: Array<Question> = [];

  const questionItems = flattenQuestionItems(questionnaire.item);
  questionItems.forEach((item, index) => {
    if (item && item.type) {
      // Todo find a better solution: We don't want to continue flattening question displayItems

      if (item.item) {
        item.item.forEach((question) => {
          if (question) {
            if (isAllowableQuestionType(question.type)) {
              try {
                retQuestions.push({
                  id: question.linkId || index.toString(),
                  questionText: questionText(item.text || "", question.text || "") || "",
                  type: question.type,
                  required: question.required,
                  options: getAnswerOptions(item.item![0] || undefined),
                });
              } catch (e) {
                logError(new Error(`Error parsing questions from Questionnaire Bundle: ${e}`));
                return;
              }
            } else {
              logError(new Error(`Invalid question type: ${question.type}`));
            }
          }
        });
      } else {
        if (isAllowableQuestionType(item.type)) {
          try {
            retQuestions.push({
              id: item.linkId || index.toString(),
              questionText: item.text || "",
              type: item.type,
              required: item.required,
              options: getAnswerOptions(item || undefined),
            });
          } catch (e) {
            logError(new Error(`Error parsing questions from Questionnaire Bundle: ${e}`));
            return;
          }
        } else {
          logError(new Error(`Invalid question type: ${item.type}`));
        }
      }
    }
  });

  return retQuestions;
};

const isAllowableQuestionType = (ipt: Questionnaire_Item["type"] | "multi-choice"): ipt is AllowableQuestionType => {
  if (!ipt) {
    return false;
  }
  return !["group", "display", "attachment", "reference"].includes(ipt);
};

const questionText = (outerQuestionText: string, innerQuestionText: string): string => {
  let ret = innerQuestionText;
  if (outerQuestionText && outerQuestionText !== innerQuestionText) {
    ret = outerQuestionText.concat(": ", innerQuestionText);
  }
  return ret;
};

const flattenQuestionItems = (
  items: Questionnaire_Item[],
  questionText: string | undefined = ""
): Questionnaire_Item[] => {
  return items.flatMap((item) => {
    if (item.type === "group") {
      if (!item.item) {
        logError(new Error(`FHIR Questionnaire bundle malformed. Group type items must have children items.`));
        return [];
      }
      let itemQuestion = item?.item[0]?.type !== "group" ? item.text : "";
      return flattenQuestionItems(item.item, itemQuestion);
    } else {
      item.text = questionText;
      return [item].flat();
    }
  });
};
const getAnswerOptions = (
  displayItem: Questionnaire_Item | undefined
): HealthHelpQuestionAnswerOption[] | undefined => {
  if (displayItem && displayItem.answerOption) {
    return displayItem.answerOption.map((answerOption, idx) => {
      if (answerOption.valueCoding?.display === undefined) {
        //display is the question text so we cant have a question without question text ;)
        throw new Error(
          `Answer with (id) code: ${answerOption?.valueCoding?.code || "undefined"} in group  ${
            displayItem.text
          } has an answer with undefined text.`
        );
      }
      return {
        id: answerOption.valueCoding.code || idx.toString(),
        responseOptionText: answerOption.valueCoding.display,
      };
    });
  } else {
    return undefined;
  }
};

const getMultiSelectOptions = (
  displayItem: Questionnaire_Item | undefined
): HealthHelpQuestionAnswerOption[] | undefined => {
  if (displayItem && displayItem.item) {
    return displayItem.item.map((option, idx) => ({
      id: option.linkId || displayItem.linkId || idx.toString(),
      responseOptionText: option.text,
    }));
  } else {
    return undefined;
  }
};

/**
 * Checks a FHIR Bundle for a `FinalSubmit` LocationCode header
 * Health help sends a LocationCode: FinalSubmit to signal that the questionnaire is complete.
 * @param bundle
 */
export const checkForFinalSubmit = (bundle: Bundle) => {
  let isFinalSubmit = false;
  const endpoint: any = bundle?.entry?.find((e: any) => e?.resource?.resourceType === "Endpoint")?.resource;
  endpoint?.header?.forEach((header: string) => {
    const headerRegexpMatch = header.match(NON_COLON_THEN_COLON_AND_MAYBE_WHITESPACE_THEN_THE_REST);
    if (headerRegexpMatch) {
      const [, , headerValue] = headerRegexpMatch;
      if (headerValue === "FinalSubmit") {
        isFinalSubmit = true;
      }
    }
  });
  return isFinalSubmit;
};

export const extractAndUpdateHeadersAndPathFromBundle = (
  bundle: Bundle | undefined,
  accessToken: string,
  vendorIdentifier: string,
  setRequestHeaders: Dispatch<SetStateAction<HeadersInit>>,
  setRequestPath: Dispatch<SetStateAction<string>>
): void => {
  if (bundle) {
    const endpoint: any = bundle?.entry?.find((e: any) => e?.resource?.resourceType === "Endpoint")?.resource;

    setRequestHeaders(headersFromEndpoint(endpoint, accessToken));

    // verb + url base + endpoint + transaction token placeholder
    const VERB_THEN_URL_THEN_ENDPOINT_THEN_TRANSACTION_TOKEN = /^((POST )|(GET ))(\/HHFhirServer\/api)([/\w]+)(@.+@)$/;
    const tokenEndpointRegexMatch = endpoint?.address?.match(VERB_THEN_URL_THEN_ENDPOINT_THEN_TRANSACTION_TOKEN);
    if (tokenEndpointRegexMatch) {
      setRequestPath(tokenEndpointRegexMatch[5]?.concat(vendorIdentifier));
    } else {
      //if there's not a token, validate the URL then send it along as is
      const VERB_THEN_URL_THEN_ENDPOINT = /^((POST )|(GET ))(\/HHFhirServer\/api)([/\w/-]+)/;
      const nonTokenRegexMatch = endpoint?.address?.match(VERB_THEN_URL_THEN_ENDPOINT);
      if (nonTokenRegexMatch) {
        setRequestPath(nonTokenRegexMatch[5]);
      }
    }
  }
};

export const headersFromEndpoint = (endpoint: Endpoint, accessToken: string): HeadersInit => {
  const requestHeaders: HeadersInit = new Headers({
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  });

  endpoint?.header?.forEach((header: string) => {
    const headerRegexpMatch = header.match(NON_COLON_THEN_COLON_AND_MAYBE_WHITESPACE_THEN_THE_REST);
    if (headerRegexpMatch) {
      const [, headerName, headerValue] = headerRegexpMatch;
      requestHeaders.set(headerName, headerValue);
    }
  });
  return requestHeaders;
};

export const buildUserAnswerResponseBundle = (
  userAnswers: Map<string, string>,
  questions: Question[] | undefined,
  //TODO this should be a Bundle if not for the undefined ResourceList :(
  setError: Dispatch<boolean>
): Bundle => {
  addBreadcrumb({
    level: "info",
    message: "User submitted  questionnaire",
  });
  const itemList: Questionnaire_Item[] = [];
  userAnswers.forEach((answer, id) => {
    if (!questions) {
      logError(new Error(`buildUserAnswerResponseBundle called with undefined Questions[]`));
      return {};
    }

    const questionType = questions.find((q) => q.id === id)?.type;
    if (!questionType) {
      logError(new Error(`Question Type for question id: ${id} not found. Response will probably be the wrong shape.`));
      setError(true);
    }
    // we only support boolean and choice (multiple choice) question types but
    // in the future this will probably expand
    if (questionType && questionType === "boolean") {
      itemList.push({
        linkId: id,
        answer: [{ valueBoolean: answer }],
      } as Questionnaire_Item);
    } else {
      itemList.push({
        linkId: id,
        answer: [{ valueCoding: { code: answer } }],
      } as Questionnaire_Item);
    }
  });

  return {
    resourceType: "Bundle",
    type: "batch",
    // @ts-ignore TODO the ResourceList undefined error
    entry: [{ resource: { resourceType: "QuestionnaireResponse", status: "completed", item: itemList } }],
  };
};

export const fhirBundleOperationOutcomeErrorHandler = (
  bundle: any,
  setError: Dispatch<boolean>,
  serviceRequestId: string
): void => {
  const operationOutcome: OperationOutcome | undefined = bundle?.entry?.find(
    (e: any) => e.resource.resourceType === "OperationOutcome"
  )?.resource;
  const issues: OperationOutcome_Issue[] | undefined = operationOutcome?.issue;
  issues?.forEach((issue) => {
    const logText = `Bundle OperationOutcome: for Service Request [${serviceRequestId}]
      ${issue.severity && `Severity: ${issue.severity}`}
      ${issue.code && `Code: ${issue.code}`}
      ${issue.details && `Details: ${issue.details?.id} | ${issue.details?.text}`}
      ${issue.diagnostics && `Diagnostics: ${issue.diagnostics}`}`;
    if (issue.severity === "information") {
      addBreadcrumb({
        level: "info",
        message: logText,
      });
    }
    if (issue.severity === "warning") {
      logWarn(logText);
    }
    if (issue.severity === "fatal" || issue.severity === "error") {
      logError(new Error(logText));
      setError(true);
    }
  });
};
