import { User } from "UserContext";
import { assertIsUserType, UserType } from "types/userType";
import { environmentAbbreviation } from "@coherehealth/common";
import config from "api/config";
import { v4 as uuid } from "uuid";
import { UserResponse } from "@coherehealth/core-platform-api";

export function isBackOfficeWithoutOrganization(user: User): boolean {
  const roles = parseRolesFromUser(user);

  if (roles.includes(UserType.BackOffice) || roles.includes(UserType.BackOfficeAdmin)) {
    if (user.groups && user.groups.length === 1 && user.groups[0] === "Everyone") {
      return true;
    }
  }

  return false;
}

export function isInternalUser(user: User): boolean {
  const roles = parseRolesFromUser(user);
  const internalRoles = [
    UserType.ServiceOps,
    UserType.ServiceOpsGeneral,
    UserType.NurseReviewer,
    UserType.MDReviewer,
    UserType.Admin,
  ];
  return internalRoles.some((internalRole) => roles.includes(internalRole));
}

export function parseRolesFromUser(user: User): string[] {
  if (user.roles) {
    let roles = filterRolesForEnvPrefix(user.roles.split(","));
    const env = environmentAbbreviation();
    const stagingRoles = [
      "nurseReviewer",
      "mdReviewer",
      "finalReviewAuditor",
      "reviewDenialSubmitter",
      "auditReviewer",
      "serviceOpsLeadership",
      "backOfficeAdmin",
    ];
    if (roles.includes("clinicalContent") && env === "staging") {
      roles = roles.concat(stagingRoles);
    }
    if (roles.includes("coherePm") && env === "preprod") {
      roles = roles.concat(stagingRoles);
    }
    roles.forEach((role) => {
      assertIsUserType(role);
    });
    return roles;
  }
  return [];
}

function filterRolesForEnvPrefix(input: string[]): string[] {
  const anyEnvRestrictedPrefix = "envRestricted_";
  const thisEnvRestriced = `${anyEnvRestrictedPrefix}${config.ENV_RESTRICTED_ROLE_PREFIX ?? uuid()}_`;

  const result: string[] = [];
  input.forEach((rawRole) => {
    if (rawRole.startsWith(anyEnvRestrictedPrefix)) {
      if (rawRole.startsWith(thisEnvRestriced)) {
        const finalAssignableRole = rawRole.substring(thisEnvRestriced.length);
        result.push(finalAssignableRole);
      } else {
        // Do nothing! This role should be discared
      }
    } else {
      // Regular role, does not need special treatment
      result.push(rawRole);
    }
  });

  return result;
}

export function getDomainFromEmail(email: string): string {
  return email.substring(email.lastIndexOf("@") + 1);
}

export function isBackOfficeUser(user?: User | UserResponse | null): boolean {
  return user?.roles?.includes("backOffice") || user?.roles?.includes("backOfficeAdmin") || false;
}
