import React from "react";

import routes from "routes";
import { useMatch } from "react-router-dom";

import FaxAttachmentPageContent from "./FaxAttachmentPageContent";

export default function FaxAttachmentPage() {
  const match = useMatch(routes.FAX);

  return <FaxAttachmentPageContent match={match} />;
}
