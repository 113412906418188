import React, { useEffect } from "react";
import HeaderAndProgressBar from "../HeaderAndProgressBar";
import { H2, H6, InlineButton, Subtitle2 } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core";
import ClipboardSuccessIcon from "../../images/ClipboardSuccessIcon";
import FullScreenDisplay, { FullScreenDisplayProps } from "./FullScreenDisplay";
import { joinExistingOrganizationSurvey } from "util/alchemerURLs";

export default function OnboardingAutoverificationSuccessComponent() {
  const classes = useStyles();

  useEffect(() => {
    // wait 1.5 secs before sending user to survey tab
    setTimeout(() => window.open(joinExistingOrganizationSurvey), 1500);
  });

  const fullScreenDisplayProps: FullScreenDisplayProps = {
    headerAndProgressBar: <HeaderAndProgressBar showProgressBar={false} />,
    visualComponent: <ClipboardSuccessIcon />,
    header: <H2 className={classes.header}>Please check your email to activate your account.</H2>,
    gridSpacing: 0,
    gridContainerStyle: classes.gridContainerStyle,
    subtext: (
      <Subtitle2 className={classes.subtitle}>
        You now have access the Cohere portal to submit new authorizations.
      </Subtitle2>
    ),
    hasDivider: true,
    subHeader: <H6 className={classes.header}>Please complete your registration in the new window.</H6>,
    secondarySubtext: (
      <Subtitle2 className={classes.secondarySubtext}>
        We just need a little more info from you to get your account set up. Finish this process in the new tab, or
        <InlineButton component="a" href={joinExistingOrganizationSurvey} className={classes.surveyButton}>
          <Subtitle2>click here</Subtitle2>
        </InlineButton>
        if the window hasn’t opened on it’s own.
      </Subtitle2>
    ),
    widthOverrides: {
      headerWidth: 950,
      subtextWidth: 653,
    },
  };

  return <FullScreenDisplay {...fullScreenDisplayProps} />;
}

const useStyles = makeStyles((theme) => ({
  gridContainerStyle: {
    width: "662px",
    margin: "auto",
  },
  header: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  subtitle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  secondarySubtext: {
    paddingTop: theme.spacing(1),
  },
  surveyButton: {
    padding: 0,
  },
}));
