// General local storage functionality

function overwriteInLocalStorage(key: string, value: any) {
  if (isWindowScopeAvailable()) {
    localStorage?.setItem(key, JSON.stringify(value));
    return true;
  }
  return false;
}

export function getFromLocalStorage(key: string) {
  if (isWindowScopeAvailable()) {
    let returnedJSONString = localStorage?.getItem(key);
    if (returnedJSONString) {
      return JSON.parse(returnedJSONString);
    }
    return undefined;
  }
  return undefined;
}

// Object keyed local storage functionality

export function createObjectIfNotExistsInLocalStorage(key: string, value: any) {
  if (keyExistsInLocalStorage(key)) {
    return false;
  } else {
    overwriteInLocalStorage(key, value);
    return true;
  }
}

export function getAttributeInObjectInLocalStorage(key: string, attribute: string) {
  if (isWindowScopeAvailable()) {
    if (attributeExistsInObjectInLocalStorage(key, attribute)) {
      const objectInLocalStorage = getFromLocalStorage(key);
      return objectInLocalStorage[attribute];
    }
    return undefined;
  }
  return undefined;
}

export function overwriteAttributeOnObjectInLocalStorage(key: string, attribute: string, value: any) {
  if (isWindowScopeAvailable()) {
    const objectInLocalStorage = getFromLocalStorage(key);
    if (objectInLocalStorage === undefined) {
      overwriteInLocalStorage(key, { [attribute]: value });
    } else {
      const newObjectValue = {
        ...objectInLocalStorage,
        [attribute]: value,
      };
      overwriteInLocalStorage(key, newObjectValue);
    }
    return true;
  }
  return false;
}

function attributeExistsInObjectInLocalStorage(key: string, attribute: string) {
  if (isWindowScopeAvailable()) {
    const objectInLocalStorage = getFromLocalStorage(key);
    if (objectInLocalStorage === undefined) {
      return false;
    }
    if (valueIsAnObject(objectInLocalStorage) && objectInLocalStorage.hasOwnProperty(attribute)) {
      return true;
    }
    return false;
  }
  return false;
}

// Internal helper functions

function valueIsAnObject(value: any) {
  return typeof value === "object" && value !== null && !Array.isArray(value) && !(value instanceof Function);
}

function keyExistsInLocalStorage(key: string) {
  if (isWindowScopeAvailable()) {
    let returnedJSONString = localStorage?.getItem(key);
    return Boolean(returnedJSONString);
  }
  return false;
}

function isWindowScopeAvailable() {
  if (window === undefined) {
    return false;
  }
  return true;
}
