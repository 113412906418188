import {
  addOptionalLabel,
  FormError,
  genderOptions,
  isOptionalField,
  NewPatientFormSectionT,
  PatientInfoT,
} from "../formOptions";
import { useState } from "react";
import { Grid } from "@mui/material";
import { RenderFormSection } from "./RenderFormSection";
import { patientInfoGridStyles } from "../addNewPatientStyles";
import { TemporaryPatientFormField } from "@coherehealth/core-platform-api";
import { CareRecommendationSection } from "./CareRecommendationSection";
import { FormAction } from "../formReducer";

interface PatientFormState {
  patientInfo: PatientInfoT;
  formError: FormError;
  attemptedSubmit?: boolean;
  dispatch: React.Dispatch<FormAction>;
  setIsCareRecommendationPatient: React.Dispatch<boolean>;
  isCareRecommendationPatient: boolean;
  formOptionalFields?: TemporaryPatientFormField[];
}
export const PatientInfoFormSection = ({
  patientInfo,
  formError,
  attemptedSubmit,
  dispatch,
  setIsCareRecommendationPatient,
  isCareRecommendationPatient,
  formOptionalFields,
}: PatientFormState) => {
  const [memberDob, setMemberDob] = useState<Date | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    dispatch({
      type: "UPDATE_PATIENT_INFO",
      payload: { ...patientInfo, [fieldName]: fieldValue },
    });
    dispatch({
      type: "UPDATE_FORM_ERROR",
      payload: { ...formError, [fieldName]: !fieldValue && !isOptionalField(fieldName, formOptionalFields) },
    });
  };
  const handleSelectChange = (fieldName: string, fieldValue?: string | Date) => {
    dispatch({
      type: "UPDATE_PATIENT_INFO",
      payload: { ...patientInfo, [fieldName]: fieldValue },
    });
    dispatch({
      type: "UPDATE_FORM_ERROR",
      payload: { ...formError, [fieldName]: !fieldValue && !isOptionalField(fieldName, formOptionalFields) },
    });
  };
  const patientSection: NewPatientFormSectionT[] = [
    {
      name: "FIRST_NAME",
      label: `First Name ${addOptionalLabel("FIRST_NAME", formOptionalFields)}`,
      input: "text",
      handleInputChange: handleInputChange,
      error: formError?.FIRST_NAME && attemptedSubmit,
    },
    {
      name: "MIDDLE_NAME",
      label: `Middle Name ${addOptionalLabel("MIDDLE_NAME", formOptionalFields)}`,
      input: "text",
      handleInputChange: handleInputChange,
    },
    {
      name: "LAST_NAME",
      label: `Last Name ${addOptionalLabel("LAST_NAME", formOptionalFields)}`,
      input: "text",
      handleInputChange: handleInputChange,
      error: formError?.LAST_NAME && attemptedSubmit,
    },
    {
      name: "DATE_OF_BIRTH",
      label: `Date of birth ${addOptionalLabel("DATE_OF_BIRTH", formOptionalFields)}`,
      input: "date",
      value: memberDob,
      handleSelectChange: handleSelectChange,
      onDateChange: (e) => setMemberDob(e),
      error: formError?.DATE_OF_BIRTH && attemptedSubmit,
    },
    {
      name: "GENDER",
      label: `Gender ${addOptionalLabel("GENDER", formOptionalFields)}`,
      input: "select",
      value: patientInfo.GENDER,
      options: genderOptions,
      handleSelectChange: handleSelectChange,
      error: formError?.GENDER && attemptedSubmit,
    },
  ];

  return (
    <Grid container item spacing={2} xs={12} marginRight={8} data-testid="patient-info-section">
      <CareRecommendationSection
        setIsCareRecommendationPatient={setIsCareRecommendationPatient}
        isCareRecommendationPatient={isCareRecommendationPatient}
      />
      <RenderFormSection
        section={{
          sectionData: patientInfo,
        }}
        inputs={patientSection}
        inputItemGridStyleProps={patientInfoGridStyles}
      />
    </Grid>
  );
};
