import React from "react";

// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Grid, styled } from "@material-ui/core";
import { Body1, Chip, Tooltip } from "@coherehealth/common";
import { Label, FieldValue } from "components/ServiceRequest/ReadonlyDetail/FormElements";

import { OrgInfoInternalState } from "./ProviderOrgInfo";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

interface Props {
  orgInfo: OrgInfoInternalState;
  onboardingRedesign: boolean;
}

function healthPlansToSentence(array: string[]): string {
  if (!array || array.length === 0) {
    return "-";
  }
  return array.join(", ");
}

export default function ProviderOrgReadOnly({ orgInfo, onboardingRedesign }: Props) {
  return (
    <OrgReadOnly container>
      <OrgNameInfoContainer container>
        <Grid item xs={6} style={{ padding: "12px 12px 12px 0px" }}>
          <Label data-public>Organization name</Label>
          <OrgNameValue data-public>{orgInfo.name}</OrgNameValue>
        </Grid>

        <Grid item xs={6} style={{ padding: "12px 40px 12px 16px" }}>
          <>
            <OrgSpecialties data-public>Health plans</OrgSpecialties>
            <OrgCreatedByValue data-public>{healthPlansToSentence(orgInfo.healthPlans)}</OrgCreatedByValue>
          </>
        </Grid>

        <Grid item xs={6} style={{ padding: "12px 12px 12px 0px" }}>
          <>
            <OrgSpecialties data-public>Specialties</OrgSpecialties>
            <OrgCreatedByValue data-public>
              {orgInfo.specialties && orgInfo.specialties.length > 0
                ? orgInfo.specialties.map((spec, idx) => {
                    return `${spec}${idx !== orgInfo.specialties.length - 1 ? `, ` : ``}`;
                  })
                : "-"}
            </OrgCreatedByValue>
          </>
        </Grid>
      </OrgNameInfoContainer>
      <Grid item xs={12}>
        <Label data-public>Taxpayer identification number(s) (TIN)</Label>
        {orgInfo.tinList ? (
          <ChipValue>
            {orgInfo.tinList.map((tin) => (
              <InputChip key={tin} label={tin} />
            ))}
          </ChipValue>
        ) : (
          <FieldValue>-</FieldValue>
        )}
      </Grid>
      <OrgInfoAddressContainer container>
        <Grid item xs={3} style={{ padding: "12px 12px 12px 0px" }}>
          <Label data-public>Street address</Label>
          <AddressLine1Value data-public>{orgInfo.addressLine1 || "-"}</AddressLine1Value>
        </Grid>
        <Grid item xs={3} style={{ padding: "12px 12px 12px 16px" }}>
          <Label data-public>Street address 2</Label>
          <AddressLine2Value data-public>{orgInfo.addressLine2 || "-"}</AddressLine2Value>
        </Grid>
        <Grid item xs={2} style={{ padding: "12px 12px 12px 16px" }}>
          <Label data-public>City</Label>
          <CityValue data-public>{orgInfo.city || "-"}</CityValue>
        </Grid>
        <Grid item xs={2} style={{ padding: "12px 12px 12px 16px" }}>
          <Label data-public>State</Label>
          <StateValue data-public>{orgInfo.state || "-"}</StateValue>
        </Grid>
        <Grid item xs={2} style={{ padding: "12px 12px 12px 16px" }}>
          <Label data-public>Zip code</Label>
          <ZipCodeValue data-public>{orgInfo.zipCode || "-"}</ZipCodeValue>
        </Grid>
      </OrgInfoAddressContainer>
      <Grid item xs={12}>
        <AutoVerifiedEmailDomain>
          <Body1 style={{ paddingRight: 10 }} data-public>
            Auto-verify new users by email domain
          </Body1>
          <Tooltip
            title={
              "Auto-verify new users by email domain allows new users to join your organization’s Cohere account and replaces the need of manual review by an organization administrator. "
            }
            placement="top"
            data-public
          >
            <InfoOutlinedIcon data-public />
          </Tooltip>
        </AutoVerifiedEmailDomain>
        {orgInfo.emailAutoVerificationEnabled && (
          <Grid item xs={12} style={{ paddingTop: 12 }}>
            <Label data-public>Auto-verifiable email domain(s)</Label>
            <ChipValue>
              {orgInfo.emailDomains.map((domain) => (
                <InputChip key={`@${domain}`} label={`@${domain}`} data-public />
              ))}
            </ChipValue>
          </Grid>
        )}
      </Grid>
    </OrgReadOnly>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const OrgReadOnly = styled(Grid)(({ theme }) => ({
  marginLeft: theme.spacing(0.125),
  gap: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const OrgNameValue = styled(FieldValue)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  marginBottom: theme.spacing(0),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const OrgNameInfoContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  width: theme.spacing(148),
  alignItems: "flex-start",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const OrgInfoAddressContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  width: theme.spacing(148),
  alignItems: "flex-start",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const OrgSpecialties = styled(Label)({
  paddingLeft: 7,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const OrgCreatedByValue = styled(FieldValue)(({ theme }) => ({
  paddingLeft: 7,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  marginBottom: theme.spacing(0),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ChipValue = styled(FieldValue)(({ theme }) => ({
  marginBottom: 10,
  paddingTop: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const InputChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const AutoVerifiedEmailDomain = styled(Label)({
  display: "flex",
  alignItems: "center",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const AddressLine1Value = styled(FieldValue)({
  paddingTop: 3,
  marginBottom: 12,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const AddressLine2Value = styled(FieldValue)({
  paddingTop: 3,
  marginBottom: 12,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CityValue = styled(FieldValue)({
  paddingTop: 3,
  marginBottom: 12,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StateValue = styled(FieldValue)({
  paddingTop: 3,
  marginBottom: 12,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ZipCodeValue = styled(FieldValue)({
  paddingTop: 3,
  marginBottom: 12,
});
