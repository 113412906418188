import React, { forwardRef } from "react";
import { TextField, TextFieldProps, isIE, AutoFillIcon } from "@coherehealth/common";
import { PhoneNumber } from "@coherehealth/core-platform-api";
import ReactPhoneNumberInput from "react-phone-number-input/input";
import { DefaultInputComponentProps } from "react-phone-number-input";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";

type FinalPhoneInputProps = Omit<DefaultInputComponentProps, "onChange" | "value" | "error"> & {
  onChange: (phone: PhoneNumber) => void;
  value?: PhoneNumber;
  hideExtensionField?: boolean;
  isAutofilled?: boolean;
  width?: number;
  dataPublic?: boolean;
};
type FinalTextFieldProps = Omit<TextFieldProps, "onChange" | "value" | "onChangeValue">;
type Props = FinalPhoneInputProps & FinalTextFieldProps;

// todo move this into the common package
export default function PhoneInput({
  value,
  onChange,
  hideExtensionField,
  isAutofilled,
  width,
  dataPublic = false,
  ...rest
}: Props) {
  // Type shenanigan warning:
  // react-phone-number-input/input actually passes through all
  // unclaimed props to the underlying inputComponent,
  // but its type definitions do not reflect this.
  const typedRest = rest as Omit<FinalPhoneInputProps, "onChange" | "value">;
  const dataPublicProps = dataPublic ? { "data-public": true } : {};
  return (
    <div
      style={{
        display: isIE ? "-ms-grid" : "grid",
        gridTemplateColumns: hideExtensionField ? "1fr" : "2fr 1fr",
        msGridColumns: hideExtensionField ? "1fr" : "2fr 1fr",
        width: width || undefined,
      }}
    >
      <ReactPhoneNumberInput
        country="US"
        inputComponent={
          // Type bug: sorry for the hacky code, remove when this issue fixed
          // https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/110
          (isAutofilled ? InputComponentWithAutofill : InputComponentWithoutAutofill) as unknown as (
            x: any
          ) => JSX.Element
        }
        value={value ? `+1${value.number}` : ""}
        onChange={(phoneNumber: string) =>
          onChange({
            ...value,
            number: isAutofilled ? "" : phoneNumber?.replace("+1", "") || "",
          })
        }
        style={{ msGridColumn: 1 }}
        {...dataPublicProps}
        {...typedRest}
      />
      {!hideExtensionField && (
        <TextField
          value={value?.extension || ""}
          label="Ext."
          name="Ext."
          onChange={(e) => {
            onChange({
              ...value,
              extension: e.target.value,
            });
          }}
          style={{ marginLeft: 8, gridColumn: 2 }}
          {...dataPublicProps}
        />
      )}
    </div>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledEndAdornmentContainer = styled("div")(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
}));

const InputComponentWithoutAutofill = forwardRef((props, ref) => (
  <TextField inputProps={{ maxLength: 14 }} inputRef={ref} {...props} />
));

const InputComponentWithAutofill = forwardRef((props, ref) => (
  <TextField
    inputRef={ref}
    {...props}
    InputProps={{
      endAdornment: (
        <StyledEndAdornmentContainer data-testId="autofill-icon">
          <AutoFillIcon />
        </StyledEndAdornmentContainer>
      ),
      disableUnderline: true,
    }}
    inputProps={{ maxLength: 14 }}
  />
));
