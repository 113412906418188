import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useGetAllReviews,
  useGetPatient,
  useGetServiceRequestOutreachOpportunities,
  useGetServiceRequests,
  ServiceRequestResponse,
  Coverage,
  DenialsTemplate,
  AdhocNotificationType,
  useGetLatestReviewLeadingToDenial,
} from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import routes from "routes";
import {
  InformativeModal,
  useFeature,
  POLICY_UNITED_STATES_OPTIONS,
  NotFoundPage,
  InlineButton,
  ParsedBlock,
  parseMarkup,
  SecondaryButton,
  parseDateFromISOStringWithoutFallback,
  CategorizedSingleSelectDropdown,
  useGetServiceRequestByIdWithFallback,
} from "@coherehealth/common";
import PencilIcon from "@material-ui/icons/Create";
import { useAuthorized } from "authorization";
import ClinicalReviewShell from "common/ClinicalReviewShell";
import Tab from "@material-ui/core/Tab";
import { CircularProgress, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { licenseDependentStates } from "components/ServiceRequest/ReviewSection/MDReview/DenialLetterSummaryCard";
import ClipboardWarningIcon from "components/images/ClipboardWarningIcon";
import { getCoverageBasedOnDate, getSortedListOfCoverages } from "util/patientUtils";
import { isPostLetterStatus } from "util/serviceRequest";
import { generatePath } from "react-router-dom";
import config from "api/config";
import { useGetServiceRequestCases } from "@coherehealth/qm-api";
import useClaimHistory from "components/ClaimHistory/useClaimHistory";
import { useAttachments } from "components/ClinicalReview/reviewUtils/useAttachment";
import {
  LeftPanelTabs,
  useAuthorizationFetch,
  tabProps,
  RIGHT_HAND_PANEL_SIZE,
} from "components/ClinicalReview/reviewUtils/utils";
import SlimHeader from "components/ClinicalReview/SlimHeader";
import { StyledTabs } from "components/ClinicalReview/Review/ClinicalReviewPage";
import ReviewLeftPanel from "components/ClinicalReview/reviewUtils/ReviewLeftPanel";
import LoadingDialog from "components/ClinicalReview/LoadingDialog";
import {
  ReviewControlBoxInnerContainer,
  ReviewControlBoxOuterContainer,
} from "components/ServiceRequest/ReviewSection/MDReview/MDReviewEdit";
import AdhocLetterContent from "components/ServiceRequest/ReviewSection/common/AdhocLetterContent";
import {
  PrimaryDeterminationLetterButton,
  TertiaryCustomButton,
} from "components/ServiceRequest/ReviewSection/MDReview/DenialLetterControlPanel";
import AdHocLetterRecipientModal from "common/AdHocLetterRecipientModal";
import FullScreenDialog from "components/ServiceRequest/ReviewSection/MDReview/FullScreenDialog";
import DenialLetterQueuedForDelivery from "components/images/DenialLetterQueuedForDelivery.svg";
import useAdhocApiCalls from "./hooks/useAdhocApiCalls";
import { useFetchLetterTemplates } from "./hooks/useFetchLetterTemplates";
import { usePatchDenialsLetterTemples } from "./hooks/usePatchDenialsLetterTemplates";
import { useGetLettersExpansionConfigurationByPayer } from "hooks/useGetFeatureConfigurations";

import useOtherAuthorizations from "components/ClinicalReview/ClinicalReviewInfoPanel/OtherServiceRequests/useOtherAuthorization";
import { redirectToPatientSummaryFromReview, routeToPatientSummaryFromReview } from "util/routeUtils/routeUtils";
import { LeftReviewTabs, useTabStyles } from "components/ClinicalReview/reviewUtils/LeftReviewTabs";
import { CheckBoxValueAndLabel } from "common/AdHocLetterRecipientModal";
import { APPEAL_LETTER_PARAM } from "util/queryParams";
type DenialRightTabs = "ADHOC_LETTER_CONTENT" | "APPEAL_LETTER_CONTENT";

export default function AdhocLetterPage() {
  const canViewReview = useAuthorized("VIEW_REVIEW");
  const { serviceRequestId } = useParams();
  if (canViewReview && serviceRequestId) {
    return <AdhocLetterPageAuthorized serviceRequestId={serviceRequestId} />;
  } else {
    return <NotFoundPage />;
  }
}

function AdhocLetterPageAuthorized({ serviceRequestId }: { serviceRequestId: string }) {
  const theme = useTheme();
  const matchesRight = useMediaQuery(theme.breakpoints.up(RIGHT_HAND_PANEL_SIZE));
  const tabClasses = useTabStyles({});
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const search = useMemo(() => new URLSearchParams(location.search), [location]);
  const expandedBehavioralHealthNotifications = useFeature("expandedBehavioralHealthNotifications");

  const isAppealLetter = search.has(APPEAL_LETTER_PARAM);

  const {
    data: existingReviews,
    error: existingReviewsError,
    loading: loadingExistingReviews,
  } = useGetAllReviews({
    serviceRequestId: serviceRequestId,
    queryParams: { max: 200 },
  });

  const {
    data: serviceRequest,
    error: serviceRequestFetchError,
    loading: serviceRequestLoading,
    refetch: serviceRequestRefetch,
  } = useGetServiceRequestByIdWithFallback({
    id: serviceRequestId,
  });

  const { data: currentReview, loading: latestReviewLeadingToDenialLoading } = useGetLatestReviewLeadingToDenial({
    id: serviceRequestId,
  });

  const {
    data: patient,
    error: patientFetchError,
    loading: patientLoading,
    refetch: patientRefetch,
  } = useGetPatient({
    id: serviceRequest?.patient?.id || "",
    lazy: true,
  });

  const { data: outreachOpportunities } = useGetServiceRequestOutreachOpportunities({
    id: serviceRequestId,
  });

  const {
    data: otherServiceRequests,
    error: otherServiceRequestsError,
    loading: otherServiceRequestsLoading,
    refetch: fetchOtherServiceRequests,
  } = useGetServiceRequests({
    queryParams: { patient: `eq:${serviceRequest?.patient?.id || ""}` },
    lazy: true,
    resolve: (patientServiceRequests: ServiceRequestResponse[]) =>
      patientServiceRequests.filter((patientServiceRequest) => patientServiceRequest.id !== serviceRequest?.id),
  });

  const isLicenseNeeded = useMemo(
    () =>
      !!licenseDependentStates.find(
        (state) =>
          state ===
          getCoverageBasedOnDate(
            parseDateFromISOStringWithoutFallback(serviceRequest?.startDate),
            serviceRequest?.patient
          )?.stateOfIssue
      ),
    [serviceRequest?.patient, serviceRequest?.startDate]
  );

  const [leftColumnTab, setLeftColumnTab] = useState<LeftPanelTabs>("REQUEST_INFORMATION");
  const [rightColumnTab, setRightColumnTab] = useState<DenialRightTabs>(
    isAppealLetter ? "APPEAL_LETTER_CONTENT" : "ADHOC_LETTER_CONTENT"
  );
  const [submissionError, setSubmissionError] = useState<string>();
  const claimHistoryFeature = useFeature("claimHistoryFeature");
  const showLicensePopUpFF = useFeature("showLicensePopup");
  const requireLicensePopUp = showLicensePopUpFF && isLicenseNeeded && serviceRequest?.lobType === "Commercial";

  const [licensedModalOpen, setLicensedModalOpen] = useState<boolean>(false);
  const [licensedState, setLicensedState] = useState<string>();
  const genericNotesEnabled = useFeature("genericNotes");
  const viewNoteAuthorized = useAuthorized("VIEW_NOTES");
  const canViewGenericNotes = genericNotesEnabled && viewNoteAuthorized;
  const trackingNumberSearch = !!search.get("trackingNumberSearch");
  const caseId = search.get("caseId") || "";
  const { data: serviceCases, refetch: getServiceCases } = useGetServiceRequestCases({
    id: serviceRequest?.id || "none",
    base: `${config.QM_SERVICE_API_URL}`,
    lazy: true,
  });

  useEffect(() => {
    if (serviceRequest && config.HAS_QM_ENVIRONMENT) {
      getServiceCases();
    }
  }, [getServiceCases, serviceRequest]);

  useEffect(() => {
    if (requireLicensePopUp) {
      const coverages = getSortedListOfCoverages(serviceRequest?.patient);
      let activePrimaryCoverage: Coverage | null | undefined = null;
      if (coverages?.[0]?.planActive) {
        //If the first coverage in the sorted list is active, then that is the primary coverage
        // we'll then set it aside from the other (non-primary) coverages and display them separately
        activePrimaryCoverage = coverages.shift();
        if (activePrimaryCoverage) {
          POLICY_UNITED_STATES_OPTIONS?.forEach((opt) => {
            if (opt.id === activePrimaryCoverage?.stateOfIssue) {
              setLicensedState(opt.label);
            }
          });
        }
      }
      setLicensedModalOpen(true);
    }
  }, [requireLicensePopUp, serviceRequest?.patient]);

  useEffect(() => {
    if (existingReviewsError) {
      enqueueSnackbar(`Failed to load reviews: ${existingReviewsError.message}`, { variant: "error" });
    }
    if (serviceRequestFetchError) {
      enqueueSnackbar(`Failed to load service request: ${serviceRequestFetchError.message}`, { variant: "error" });
    }
    if (patientFetchError) {
      enqueueSnackbar(`Failed to load patient information: ${patientFetchError.message}`, { variant: "error" });
    }
    if (otherServiceRequestsError) {
      enqueueSnackbar(`Failed to load patient's other service requests: ${otherServiceRequestsError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, existingReviewsError, serviceRequestFetchError, patientFetchError, otherServiceRequestsError]);

  useEffect(() => {
    if (serviceRequest) {
      patientRefetch();
      fetchOtherServiceRequests();
    }
  }, [patientRefetch, fetchOtherServiceRequests, serviceRequest]);

  useEffect(() => {
    if (submissionError) {
      enqueueSnackbar(`${submissionError}`, { variant: "error" });
      setSubmissionError("");
    }
  }, [submissionError, enqueueSnackbar]);

  const { authorization, fetchAuthorization, authorizationFetchLoading } = useAuthorizationFetch(serviceRequest);
  const {
    attachments,
    attachmentListFetchLoading,
    onSwitchAttachmentTab,
    onViewAttachment,
    previousAttachmentsExpanded,
    setPreviousAttachmentsExpanded,
    attachmentIndexOpen,
    currentPage,
    currentRotate,
    zoomLevel,
    attachmentsInfo,
    setAttachmentsInfo,
    openAttachmentWarningModal,
    setOpenAttachmentWarningModal,
    newAttachmentsNumber,
  } = useAttachments({
    serviceRequest,
    existingReviews: existingReviews || [],
  });

  const claimHistoryProps = useClaimHistory({ patientId: serviceRequest?.patient?.id });

  const useOtherAuthorizationProps = useOtherAuthorizations({ serviceRequest });

  const { data: lettersExpansionConfig } = useGetLettersExpansionConfigurationByPayer(
    serviceRequest?.healthPlanName || ""
  );

  const correctionLettersEnabled: boolean = Boolean(lettersExpansionConfig?.correctionLetters);

  const {
    isLoading: contentBlocksLoading,
    determinationTemplates,
    determinationDisplayTemplates,
    nonDeterminationDisplayTemplates,
    nonDeterminationTemplates,
    correctionTemplates,
    correctionDisplayTemplates,
    qioAppealTemplates,
    qioAppealDisplayTemplates,
  } = useFetchLetterTemplates(serviceRequest, { correctionLettersEnabled, qioAppealTemplatesOnly: isAppealLetter });

  const [selectedTemplate, setSelectedTemplate] = useState<DenialsTemplate | null | undefined>();

  const [selectTemplate, setSelectTemplate] = useState<boolean>(true);
  const [denialLanguage, setDenialLanguage] = useState<string>("");
  const [isBlob, setIsBlob] = useState<boolean>(false);
  const [parsedMarkupText, setParsedMarkupText] = useState<ParsedBlock[]>();

  const setCanSubmitForReview = useState<boolean>(false)[1];
  const setHasMadeChanges = useState<boolean>(false)[1];

  const [mdNote, setMdNote] = useState<string>("");

  const { patchDenialsTemplate } = usePatchDenialsLetterTemples(serviceRequest, currentReview, selectedTemplate);

  const handleSelectedTemplate = useCallback(async () => {
    if (selectTemplate) {
      if (
        !isPostLetterStatus(serviceRequest?.authStatus) &&
        selectedTemplate?.authStatus?.includes("DENIED") &&
        currentReview &&
        !["NOMNC", "QIO_APPEAL_DENC", "QIO_APPEAL_DND"].includes(selectedTemplate?.adhocNotificationType || "")
      ) {
        await patchDenialsTemplate();

        navigate(
          generatePath(routes.DENIAL_REVIEW, {
            serviceRequestId: serviceRequest?.id,
          })
        );
      } else {
        setSelectTemplate(false);
      }
    }
  }, [selectTemplate, currentReview, patchDenialsTemplate, selectedTemplate, serviceRequest, navigate]);

  const adhocNotificationType: AdhocNotificationType | undefined = selectedTemplate?.adhocNotificationType;
  const contentBlockTitle: string | undefined = selectedTemplate?.templateTitle;
  const correctionType: string | undefined = selectedTemplate?.contentType;

  const {
    fileUrl,
    hasFileError,
    showRecipientSelectModal,
    setShowRecipientSelectModal,
    submittedAdhocLetter,
    setSubmittedAdhocLetter,
    onSendAdhocLetter,
    getAdhocLetterPreview,
  } = useAdhocApiCalls({ serviceRequestId, denialLanguage, adhocNotificationType, contentBlockTitle, correctionType });

  const handlePreviewLetter = useCallback(() => {
    setShowRecipientSelectModal(true);
    getAdhocLetterPreview();
  }, [setShowRecipientSelectModal, getAdhocLetterPreview]);

  useEffect(() => {
    if (selectedTemplate) {
      setParsedMarkupText(parseMarkup(selectedTemplate.denialLanguage || ""));
    }
  }, [selectedTemplate]);

  const enabledRecipients: CheckBoxValueAndLabel[] = [
    {
      value: "PATIENT",
      label: "Member",
      checked: serviceRequest?.isMemberRequestedAuth,
      disabled: true,
    },
    { value: "ORDERING_PROVIDER", label: "Ordering Provider", checked: false, disabled: false },
    { value: "PERFORMING_PROVIDER", label: "Performing Provider", checked: false, disabled: false },
    { value: "PCP", label: "Primary Care Physician", checked: false, disabled: false },
  ];
  if (expandedBehavioralHealthNotifications) {
    enabledRecipients.push(
      {
        value: "PERFORMING_FACILITY",
        label: "Performing Facility",
        checked: false,
      },
      {
        value: "REQUESTOR",
        label: "Requestor (Will be sent as a fax)",
        checked: false,
      }
    );
  }

  return (
    <>
      {showRecipientSelectModal && (
        <AdHocLetterRecipientModal
          showModal={showRecipientSelectModal}
          setShowModal={setShowRecipientSelectModal}
          hasError={hasFileError}
          setSubmittedAdhocLetter={setSubmittedAdhocLetter}
          fileUrl={fileUrl}
          onSendAdhocLetter={onSendAdhocLetter}
          letterTemplateTitle={selectedTemplate?.templateTitle}
          enabledRecipients={isAppealLetter ? undefined : enabledRecipients}
          showFaxInput={isAppealLetter}
        />
      )}
      {submittedAdhocLetter && (
        <>
          <FullScreenDialog
            open={submittedAdhocLetter}
            iconSrc={DenialLetterQueuedForDelivery}
            headingText="Your letter has been sent"
            subHeaderText={<>You can close this tab</>}
            slideDirection="up"
            isAdhoc={true}
          />
        </>
      )}
      <ClinicalReviewShell
        attachmentView={leftColumnTab === "ATTACHMENTS"}
        header={<SlimHeader serviceRequest={serviceRequest} />}
        leftColumnTabs={
          <LeftReviewTabs
            leftColumnTab={leftColumnTab}
            setLeftColumnTab={setLeftColumnTab}
            onSwitchAttachmentTab={onSwitchAttachmentTab}
            newAttachmentsNumber={newAttachmentsNumber}
            showClaimsHistoryTab={claimHistoryFeature}
          />
        }
        leftColumn={
          <ReviewLeftPanel
            authorization={authorization}
            refreshAuthorization={fetchAuthorization}
            authorizationFetchLoading={authorizationFetchLoading}
            patient={patient}
            patientLoading={patientLoading}
            serviceRequest={serviceRequest}
            serviceRequestLoading={serviceRequestLoading}
            existingReviews={existingReviews}
            outreachOpportunities={outreachOpportunities}
            otherServiceRequests={otherServiceRequests}
            otherServiceRequestsLoading={otherServiceRequestsLoading}
            serviceRequestId={serviceRequestId}
            crrDenials
            serviceRequestRefetch={serviceRequestRefetch}
            attachments={attachments}
            previousAttachmentsExpanded={previousAttachmentsExpanded}
            setPreviousAttachmentsExpanded={setPreviousAttachmentsExpanded}
            attachmentIndexOpen={attachmentIndexOpen}
            currentPage={currentPage}
            zoomLevel={zoomLevel}
            currentRotate={currentRotate}
            attachmentsLoading={attachmentListFetchLoading}
            onViewAttachment={onViewAttachment}
            tabOpen={leftColumnTab}
            setTabOpen={setLeftColumnTab}
            serviceCases={serviceCases}
            trackingNumberSearch={trackingNumberSearch}
            caseId={caseId}
            canViewGenericNotes={canViewGenericNotes}
            attachmentsInfo={attachmentsInfo}
            setAttachmentsInfo={setAttachmentsInfo}
            canUseClaimHistoryFeature={claimHistoryFeature}
            {...useOtherAuthorizationProps}
            {...claimHistoryProps}
          />
        }
        rightColumn={
          <div style={{ maxHeight: "calc(100vh - 144px)" }}>
            <StyledTabs
              value={rightColumnTab}
              onChange={(_, index) => {
                setRightColumnTab(index);
              }}
              aria-label="auth status tab"
              style={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 1 }}
            >
              {isAppealLetter ? (
                <Tab label="Appeal notice" {...tabProps("APPEAL_LETTER_CONTENT")} className={tabClasses.panelTab} />
              ) : (
                <Tab
                  label={selectTemplate ? "Select a letter type" : "Non-determination letter"}
                  {...tabProps("ADHOC_LETTER_CONTENT")}
                  className={tabClasses.panelTab}
                />
              )}
            </StyledTabs>
            {serviceRequestLoading ? (
              <CircularProgress />
            ) : (
              <>
                <div style={{ padding: theme.spacing(4, 5, 4, 5), boxShadow: "none", borderRadius: "10px" }}>
                  <Grid container spacing={3}>
                    {selectTemplate && (
                      <>
                        <CategorizedSingleSelectDropdown
                          label="Letter Template"
                          style={isAppealLetter ? {} : { width: "75%", marginRight: "10px" }}
                          options={[
                            ...determinationDisplayTemplates,
                            ...nonDeterminationDisplayTemplates,
                            ...correctionDisplayTemplates,
                            ...qioAppealDisplayTemplates,
                          ]}
                          hideGroupHeaders={isAppealLetter}
                          value={selectedTemplate?.id || ""}
                          onChange={(selectedTemplate) => {
                            const denialsTemplate =
                              determinationTemplates?.find((template) => template.id === selectedTemplate) ||
                              nonDeterminationTemplates?.find((template) => template.id === selectedTemplate) ||
                              correctionTemplates?.find((template) => template.id === selectedTemplate) ||
                              qioAppealTemplates?.find((template) => template.id === selectedTemplate);
                            setSelectedTemplate(denialsTemplate);
                          }}
                        />

                        {!isAppealLetter && (
                          <InlineButton
                            onClick={() => {
                              setSelectedTemplate(undefined);
                              setSelectTemplate(false);
                              setDenialLanguage("");
                              setIsBlob(true);
                            }}
                            startIcon={<PencilIcon />}
                          >
                            Custom letter
                          </InlineButton>
                        )}
                      </>
                    )}
                    {!selectTemplate && (
                      <AdhocLetterContent
                        serviceRequest={serviceRequest}
                        isBlob={isBlob}
                        setIsBlob={setIsBlob}
                        selectedTemplate={selectedTemplate}
                        setSelectTemplate={setSelectTemplate}
                        parsedMarkupText={parsedMarkupText}
                        denialLanguage={denialLanguage}
                        setDenialLanguage={setDenialLanguage}
                        setCanSubmitForReview={setCanSubmitForReview}
                        mdNote={mdNote}
                        setMdNote={setMdNote}
                        setHasMadeChanges={setHasMadeChanges}
                        hideCustomLetterButton={isAppealLetter}
                      />
                    )}
                    <ReviewControlBoxOuterContainer matchesRight={matchesRight} denial>
                      <ReviewControlBoxInnerContainer>
                        <>
                          <TertiaryCustomButton
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              redirectToPatientSummaryFromReview({ serviceRequest, caseId, navigate });
                            }}
                          >
                            Discard and exit
                          </TertiaryCustomButton>
                          {selectTemplate ? (
                            <SecondaryButton onClick={handleSelectedTemplate} disableRipple style={{ width: 280 }}>
                              Confirm selection
                            </SecondaryButton>
                          ) : (
                            <PrimaryDeterminationLetterButton onClick={handlePreviewLetter} disableRipple>
                              Preview letter
                            </PrimaryDeterminationLetterButton>
                          )}
                        </>
                      </ReviewControlBoxInnerContainer>
                    </ReviewControlBoxOuterContainer>
                  </Grid>
                </div>
              </>
            )}
          </div>
        }
      />
      <LoadingDialog
        open={
          loadingExistingReviews || serviceRequestLoading || latestReviewLeadingToDenialLoading || contentBlocksLoading
        }
        headingText={"Loading your letter"}
      />
      <InformativeModal
        open={openAttachmentWarningModal}
        onClose={() => {
          setOpenAttachmentWarningModal(false);
        }}
        headerText={"New attachments uploaded"}
        additionalInfoText={`The submitter has uploaded ${newAttachmentsNumber} new attachments`}
        primaryButtonText={"View new attachments"}
        primaryButtonAction={() => {
          setLeftColumnTab("ATTACHMENTS");
          setOpenAttachmentWarningModal(false);
        }}
      />
      <InformativeModal
        headerText={"Are you licensed in " + licensedState + "?"}
        subHeaderText={"The author must be licenced in the applicable state before sending a letter."}
        icon={<ClipboardWarningIcon />}
        primaryButtonText="Confirm license"
        primaryButtonAction={() => {
          setLicensedModalOpen(false);
        }}
        tertiaryButtonText={"Not licensed, go back"}
        tertiaryButtonRoute={
          patient?.id && routeToPatientSummaryFromReview({ serviceRequestId, patientId: patient?.id })
        }
        showDivider
        open={licensedModalOpen}
        onClose={() => setLicensedModalOpen(false)}
      />
    </>
  );
}
