import { AuthorizationResponse, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { Dispatch, SetStateAction } from "react";
import { authorizationPatientStayDataMapper } from ".";
import { InformativeModal, TableWithPanels, TableWithPanelsColumn } from "@coherehealth/common";
import { PatientStayDateTableColumnData } from "./useFetchPatientStayDates";
import { makeStyles } from "@material-ui/core";

interface PatientStayModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  authorization?: AuthorizationResponse;
  currentServiceRequest?: ServiceRequestResponse;
  patientStaysHeader?: string;
}

const useStyles = makeStyles((theme) => ({
  tableWrapper: { display: "flex", justifyContent: "center", padding: "16px 0px" },
}));

export const PatientStayModalAuth = ({ open, setOpen, authorization, patientStaysHeader }: PatientStayModalProps) => {
  const serviceRequestsOnAuth = authorization?.serviceRequestsOnAuth || [];
  const data = authorizationPatientStayDataMapper(serviceRequestsOnAuth);
  const classes = useStyles();
  return (
    <InformativeModal
      headerText="Patient stay details"
      open={open}
      onClose={() => setOpen(false)}
      subHeaderElement={
        <div className={classes.tableWrapper}>
          <TableWithPanels columns={patientStayColumns} panels={data} />
        </div>
      }
      primaryButtonAction={() => setOpen(false)}
      primaryButtonText="Close"
      maxWidth="lg"
      showDivider={false}
    />
  );
};

const patientStayColumns: TableWithPanelsColumn<PatientStayDateTableColumnData>[] = [
  { columnName: "Dates of stay", value: (stay) => stay.dates },
  { columnName: "# of days", value: (stay) => stay.days },
  { columnName: "Requested LOC", value: (stay) => stay.requestedLoc },
  { columnName: "Approved LOC", value: (stay) => stay.approvedLoc },
  { columnName: "Status", value: (stay) => stay.status },
];
