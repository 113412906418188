import React from "react";
import { Box, useTheme } from "@mui/material";
import { Caption } from "@coherehealth/design-system";
import { AuthStatus } from "@coherehealth/core-platform-api";

const ApprovalBadge = ({ authStatus, cohereId }: { authStatus?: AuthStatus; cohereId?: string }) => {
  const theme = useTheme();

  const reviewOutcomes: Record<AuthStatus, { status: string; color: string; backgroundColor: string }> = {
    NO_LONGER_NEEDED: {
      status: "Approved",
      color: theme.palette.font.main,
      backgroundColor: theme.palette.gray.light,
    },
    APPROVED: { status: "Approved", color: theme.palette.success.dark, backgroundColor: theme.palette.success.light },
    PARTIALLY_APPROVED: {
      status: "Partially Approved",
      color: theme.palette.warning.dark,
      backgroundColor: theme.palette.warning.light,
    },
    DENIED: { status: "Denied", color: theme.palette.error.dark, backgroundColor: theme.palette.error.light },
    PENDING: { status: "Pending", color: theme.palette.warning.dark, backgroundColor: theme.palette.warning.light },
    PENDING_ASSESSMENT: {
      status: "Pending",
      color: theme.palette.warning.dark,
      backgroundColor: theme.palette.warning.light,
    },
    PENDING_EXTERNAL_DETERMINATION: {
      status: "Pending",
      color: theme.palette.warning.dark,
      backgroundColor: theme.palette.warning.light,
    },
    PENDING_FACILITY_VERIFICATION: {
      status: "Pending",
      color: theme.palette.warning.dark,
      backgroundColor: theme.palette.warning.light,
    },
    INTAKE: {
      status: "Pending",
      color: theme.palette.warning.dark,
      backgroundColor: theme.palette.warning.light,
    },
    WITHDRAWN: { status: "Withdrawn", color: theme.palette.font.main, backgroundColor: theme.palette.gray.light },
    VOIDED: { status: "Voided", color: theme.palette.font.main, backgroundColor: theme.palette.gray.light },
    DISMISSED: { status: "Dismissed", color: theme.palette.font.secondary, backgroundColor: theme.palette.gray.light },
    DRAFT: { status: "Draft", color: theme.palette.font.main, backgroundColor: theme.palette.gray.light },
    RECOMMENDED_PARTIAL_APPROVAL: {
      status: "Pending",
      color: theme.palette.warning.dark,
      backgroundColor: theme.palette.warning.light,
    },
    RECOMMENDED_DENIAL: {
      status: "Pending",
      color: theme.palette.warning.dark,
      backgroundColor: theme.palette.warning.light,
    },
    POST_DENIAL_PEER_TO_PEER: {
      status: "Peer-to-peer",
      color: theme.palette.warning.dark,
      backgroundColor: theme.palette.warning.light,
    },
  };
  const outcome =
    authStatus && authStatus in reviewOutcomes
      ? reviewOutcomes[authStatus]
      : { status: "Unknown", color: theme.palette.font.secondary, backgroundColor: theme.palette.gray.light };

  const captionStyles = {
    fontFamily: "Gilroy-SemiBold",
    color: outcome.color,
    marginRight: "4px",
    display: "inline",
  };

  const noteStyles = {
    fontFamily: "Gilroy-Regular",
    display: "inline",
  };

  return (
    <Box
      display="flex"
      bgcolor={outcome.backgroundColor}
      borderRadius={"4px"}
      paddingX={"8px"}
      paddingTop={"5px"}
      paddingBottom={"4px"}
      marginLeft={"12px"}
    >
      {authStatus === "NO_LONGER_NEEDED" ? (
        <>
          <Caption style={noteStyles}>
            <span style={captionStyles}>No longer needed</span>Tracking #{cohereId || " unknown"} • Note: Since this
            request was already decisioned, it's still considered approved but has been marked as no longer needed.
          </Caption>
        </>
      ) : (
        <>
          <Caption style={{ fontFamily: "Gilroy-SemiBold", color: outcome.color, marginRight: "4px" }} data-public>
            {outcome.status}
          </Caption>
          <Caption>Tracking #{cohereId || " unknown"}</Caption>
        </>
      )}
    </Box>
  );
};

export default ApprovalBadge;
