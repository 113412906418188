import React from "react";
import { Card, colorsLight, patientDisplayName, SecondaryButton } from "@coherehealth/common";
import { Patient } from "@coherehealth/core-platform-api";
import { Body1, Body2, H6 } from "@coherehealth/design-system";
import { Grid, makeStyles } from "@material-ui/core";
import MuiErrorIcon from "@material-ui/icons/Error";
import { generatePath, Link, NavLink } from "react-router-dom";
import routes from "routes";

interface Props {
  patient: Patient;
  showSummaryLink?: boolean;
  showCoverageEndDate?: boolean;
  selectLink?: string;
}

export const ReferralPatientSearchResultCard = ({ patient }: Props) => {
  const classes = useStyles();
  const canStartReferral = patient.referralEligibility?.delegated;

  return (
    <Card className={classes.cardContainer}>
      <Grid container>
        <Grid item xs={6}>
          <H6>{patientDisplayName(patient)}</H6>
          {Boolean(patient.coverage?.healthPlanName) && (
            <Body1 className={classes.patientDetails}>{patient.coverage?.healthPlanName}</Body1>
          )}
          <div className={classes.secondaryTextContainer}>
            <Link
              className={classes.patientSummaryLink}
              to={generatePath(routes.REFERRAL_PATIENT_SUMMARY, { patientId: patient.id })}
            >
              Patient summary
            </Link>
          </div>
        </Grid>
        {Boolean(canStartReferral) && (
          <Grid item xs={6}>
            <div className={classes.startReferralRequestButtonWrapper}>
              <SecondaryButton
                component={NavLink}
                to={generatePath(routes.REFERRAL_BUILDER, { patientId: patient.id })}
              >
                Start referral
              </SecondaryButton>
            </div>
          </Grid>
        )}
        {!canStartReferral && (
          <Grid item xs={6}>
            <div className={classes.redirectMessageContainer}>
              <MuiErrorIcon className={classes.errorIcon} />
              <div className={classes.redirectMessageText}>
                <Body2 className={classes.warningBody}>Cohere does not process referrals for this health plan</Body2>
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  patientDetails: {
    color: colorsLight.font.secondary,
    paddingTop: theme.spacing(0.5),
  },
  patientSummaryLink: {
    ...theme.typography.body1,
    textDecoration: "none",
    color: colorsLight.primary.main,
    "&:visited": {
      color: colorsLight.primary.main,
    },
  },
  secondaryTextContainer: {
    paddingTop: theme.spacing(0.25),
  },
  infoBar: {
    position: "absolute",
    width: "100%",
    height: 2,
    backgroundColor: colorsLight.info.light,
    top: 0,
    left: 0,
  },
  cardContainer: {
    padding: theme.spacing(2),
    width: "100%",
    background: colorsLight.white.highEmphasis,
    borderRadius: 8,
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1.5),
  },
  startReferralRequestButtonWrapper: {
    textAlign: "right",
    margin: 0,
    position: "relative",
    top: "50%",
    mstransform: "translateY(-50%)",
    transform: "translateY(-50%)",
  },
  redirectMessageContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  redirectMessageText: {
    padding: theme.spacing(1, 0, 1, 1),
  },
  errorIcon: {
    color: colorsLight.warning.main,
  },
  warningBody: {
    color: colorsLight.warning.main,
  },
}));
