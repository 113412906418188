import React, { Dispatch, FunctionComponent, ReactNode, SetStateAction, useEffect, useState } from "react";
import {
  OptionsDecorator,
  SelectOptionsHook,
  SingleSelectForProviderFacilitySelect,
  useLazyLoadingQueryOptionsHook,
  useFeature,
  MiddleTruncateLabelWithEndornment,
} from "@coherehealth/common";
import {
  Facility,
  Provider,
  useGetProvidersOrFacilities,
  useCreateFacility,
  Patient,
  Location,
  useUpdateFacility,
  useUpdateProvider,
  useUpsertManuallyEnteredProviderOrFacility,
  PracticeCommon,
  CareParticipantType,
  EntityType,
  useCreateProvider,
  FormConfigurationFieldSpecification,
  UseGetProvidersOrFacilitiesProps,
} from "@coherehealth/core-platform-api";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useAuthorized } from "authorization";
import { useSnackbar } from "notistack";
import listInsert from "util/listInsert";
import {
  canEditPractice,
  getAdditionalCareParticipantName,
  getFieldSpecForAdditionalCareParticipant,
} from "util/serviceRequest";

import {
  providerFacilityDropdownRender,
  providerFacilityAddressRenderer,
  providerFacilityTinRenderer,
} from "../../ProviderFacilityDropdownRender";
import ProviderFacilityModal from "../../ProviderFacilityModalManual";
import {
  AdditionalCareParticipantFormContent,
  ServiceRequestFormContent,
} from "common/SharedServiceRequestFormComponents";
import { getPatientHealthPlanName } from "util/patientUtils";
import { FormConfiguration } from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import { ModalStatus } from "util/providerUtils";
import { concat } from "lodash";

type OptionType = "noResults" | "someResults" | "noResultsOscar";
type AdditionalCareParticipantOption = AdditionalCareParticipantFormContent & {
  optionType?: OptionType;
};

type LocationOption = Location & {
  optionType?: OptionType;
  manuallyEditedAddress?: boolean;
};

type TinOption = {
  optionType?: OptionType;
  tinManuallyCreated?: boolean;
  id: string;
  val: string;
};

type PracticeUpsertPayload = PracticeCommon & {
  recordType?: "PROVIDER" | "FACILITY";
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiAutocomplete-inputRoot": {
      color: theme.palette.warning.dark,
      borderColor: theme.palette.warning.dark,
    },
    "& .MuiFormLabel-root:not(.Mui-disabled)": {
      color: theme.palette.warning.dark,
    },
    "& .MuiSelect-selectMenu:not(.Mui-disabled)": {
      border: `1px solid ${theme.palette.warning.dark}`,
      borderRadius: 4,
    },
    "& .MuiFormHelperText-root": {
      color: theme.palette.warning.dark,
    },
    "&:hover, &:active, &:focus": {
      "& .MuiAutocomplete-inputRoot": {
        color: theme.palette.warning.dark,
        borderColor: theme.palette.warning.dark,
      },
    },
  },
  helperMsgLine: {
    paddingBottom: theme.spacing(1),
    display: "block",
  },
}));

function getUseAdditionalCareParticipants(
  patient: Patient | undefined,
  useCMSProviders: string,
  careParticipantType: CareParticipantType | undefined,
  careParticipantConfiguredEntityType: EntityType | undefined,
  careParticipantFieldConfigurations:
    | {
        fieldName?: string;
        fieldSpecification?: FormConfigurationFieldSpecification;
      }[]
    | undefined,
  ignoreTin?: boolean
): SelectOptionsHook<AdditionalCareParticipantOption> {
  return function UseAdditionalCareParticipants(selectOptionsParams) {
    const today = new Date();
    const healthPlanName = patient ? getPatientHealthPlanName(patient, today) : undefined;

    const preventUserCreatedFacilities = false;
    const addManualCreationOption: OptionsDecorator<AdditionalCareParticipantOption> | undefined =
      healthPlanName === "Oscar"
        ? (loadedOptions, hasMoreOptions) => {
            if (!hasMoreOptions) {
              const manualOption: AdditionalCareParticipantOption = {
                name: "",
                id: "",
                dateCreated: "",
                lastUpdated: "",
                careParticipantType: careParticipantType,
                configuredEntityType: careParticipantConfiguredEntityType,
                fieldConfigurations: careParticipantFieldConfigurations,
              };
              if (!loadedOptions || loadedOptions.length === 0) {
                manualOption.optionType = "noResultsOscar";
                return listInsert(loadedOptions || [], 0, manualOption);
              }
            }
            return loadedOptions;
          }
        : !preventUserCreatedFacilities
        ? (loadedOptions, hasMoreOptions) => {
            if (!hasMoreOptions) {
              const manualOption: AdditionalCareParticipantOption = {
                name: "",
                id: "",
                dateCreated: "",
                lastUpdated: "",
                careParticipantType: careParticipantType,
                configuredEntityType: careParticipantConfiguredEntityType,
                fieldConfigurations: careParticipantFieldConfigurations,
              };
              if (!loadedOptions || loadedOptions.length === 0) {
                manualOption.optionType = "noResults";
                return listInsert(loadedOptions || [], 0, manualOption);
              } else {
                manualOption.optionType = "someResults";
                return listInsert(loadedOptions, loadedOptions.length, manualOption);
              }
            }
            return loadedOptions;
          }
        : undefined;

    const queryParams = {
      healthPlanName: healthPlanName,
      useCMSProviders: useCMSProviders,
      careParticipantType: careParticipantType,
      ignoreTin: ignoreTin,
    };

    const recordType = careParticipantConfiguredEntityType === "FACILITY" ? "FACILITY" : "PROVIDER";
    return useLazyLoadingQueryOptionsHook({
      useGetHook: (args: UseGetProvidersOrFacilitiesProps) => useGetProvidersOrFacilities({ ...args, debounce: 600 }),
      optionsDecorator: addManualCreationOption,
      additionalQueryParams: { ...queryParams, recordType: recordType },
      ...selectOptionsParams,
    });
  };
}

function getLocationsWithOptions(
  locations: Location[] | null,
  enableManualAddress: boolean,
  selectedTin?: string | null,
  ignoreTin?: boolean
): LocationOption[] {
  const locationsWithOptions: Array<LocationOption> = [];
  locations?.forEach((location) => {
    locationsWithOptions.push({ ...location, optionType: undefined });
  });
  const manualOption: LocationOption = {
    id: "",
    segments: undefined,
    tin: "",
    address: undefined,
    networks: undefined,
    phoneNumbers: undefined,
    faxNumbers: undefined,
    isOutOfNetwork: false,
    outOfNetworkExceptionReason: "",
    outOfNetworkExceptionComment: "",
    externalReferenceId: "",
  };
  if (locationsWithOptions.length === 0) {
    manualOption.optionType = "noResults";
  } else {
    manualOption.optionType = "someResults";
  }

  if (enableManualAddress && (selectedTin || ignoreTin)) {
    locationsWithOptions.push(manualOption);
  }
  return locationsWithOptions;
}

const getAdditionalCareParticipantForUpdate = (
  currCareParticipant: AdditionalCareParticipantFormContent,
  currentTin: string | null | undefined,
  currentAddress: Location | null | undefined,
  modalStatus: ModalStatus,
  payload: PracticeUpsertPayload,
  ignoreTin?: boolean
): AdditionalCareParticipantFormContent & { recordType: "PROVIDER" | "FACILITY" } => {
  const selLoc = payload.selectedLocation as LocationOption;
  selLoc.manuallyEditedAddress = true;
  if (!currCareParticipant.tinList) {
    currCareParticipant.tinList = [];
  }
  if (!currCareParticipant.locations) {
    currCareParticipant.locations = [];
  }
  currCareParticipant.lastUpdated = new Date().toISOString();
  switch (modalStatus) {
    case ModalStatus.AddTin:
      if (selLoc.tin) {
        currCareParticipant.tinList?.push(selLoc.tin);
        currCareParticipant.locations?.push(selLoc);
        currCareParticipant.selectedLocation = selLoc;
      }
      break;
    case ModalStatus.EditTin:
      // remove existing tin from list
      if (currCareParticipant && selLoc.tin && selLoc && currentTin) {
        let index = currCareParticipant.tinList.indexOf(currentTin, 0);
        if (index > -1) {
          currCareParticipant.tinList.splice(index, 1);
        }

        // change location associated with tin
        currCareParticipant.locations.forEach(function (x) {
          if (x.tin === currentTin) {
            x.tin = selLoc.tin;
          }
        });

        currCareParticipant.tinList.push(selLoc.tin);
        currCareParticipant.selectedLocation = selLoc;
      }

      break;
    case ModalStatus.AddAddress:
      if (selLoc.tin || ignoreTin) {
        currCareParticipant.locations.push(selLoc);
        currCareParticipant.selectedLocation = selLoc;
      }
      break;

    case ModalStatus.EditAddress:
      if (currCareParticipant && (selLoc.tin || ignoreTin) && selLoc) {
        // remove location associated with tin
        const index = currCareParticipant.locations.findIndex(
          (x) =>
            x.tin === currentTin &&
            x.address?.line1 === currentAddress?.address?.line1 &&
            x.address?.line2 === currentAddress?.address?.line2 &&
            x.address?.state === currentAddress?.address?.state &&
            x.address?.zipCode === currentAddress?.address?.zipCode &&
            x.phoneNumbers?.length === currentAddress?.phoneNumbers?.length &&
            JSON.stringify(x.phoneNumbers?.sort()) === JSON.stringify(currentAddress?.phoneNumbers?.sort()) &&
            x.faxNumbers?.length === currentAddress?.faxNumbers?.length &&
            JSON.stringify(x.faxNumbers?.sort()) === JSON.stringify(currentAddress?.faxNumbers?.sort())
        );
        if (index > -1) {
          currCareParticipant.locations.splice(index, 1);
        }
        currCareParticipant.locations.push(selLoc);
        currCareParticipant.selectedLocation = selLoc;
      }
      break;
  }
  return {
    ...currCareParticipant,
    recordType: payload.recordType || (payload.type as "PROVIDER" | "FACILITY"),
  };
};

const getLocationsForTin = (
  tin: string | null | undefined,
  additionalCareParticipant?: AdditionalCareParticipantFormContent | null,
  ignoreTin: boolean = false
): Location[] => {
  const locationsForTin: Location[] = [];
  if ((ignoreTin || tin) && additionalCareParticipant && additionalCareParticipant.locations) {
    for (const providerLoc of additionalCareParticipant.locations) {
      if (providerLoc.tin === tin || ignoreTin) {
        locationsForTin.push(providerLoc);
      }
    }
  }
  if (locationsForTin.length === 0 && additionalCareParticipant && additionalCareParticipant.locations) {
    if (
      additionalCareParticipant.source === "CMS" &&
      !additionalCareParticipant.manuallyCreated &&
      additionalCareParticipant.locations.length > 0
    ) {
      locationsForTin.push(additionalCareParticipant.locations[0]);
    }
  }
  return locationsForTin;
};

function getTinWithOptions(tin: string | undefined | null): TinOption | null {
  if (tin === "") {
    return {
      optionType: "noResults",
      tinManuallyCreated: false,
      id: "",
      val: "/TIN-undefined",
    };
  } else if (tin) {
    return { optionType: undefined, tinManuallyCreated: undefined, id: tin, val: tin };
  }
  return null;
}

function getTinListWithOptions(tinlist: string[] | undefined) {
  const tinListWithOtions: TinOption[] = [];
  if (tinlist) {
    for (const val of tinlist) {
      tinListWithOtions.push({ optionType: undefined, tinManuallyCreated: undefined, id: val, val: val });
    }
  }

  const manualOption: TinOption = {
    optionType: tinlist ? "someResults" : "noResults",
    tinManuallyCreated: false,
    id: "",
    val: "/TIN-undefined",
  };

  if (manualOption) {
    tinListWithOtions.push(manualOption);
  }
  return tinListWithOtions;
}

const AdditionalCareParticipantsSelect: FunctionComponent<{
  additionalCareParticipantError: boolean;
  npiError?: boolean;
  tinError?: boolean;
  addressError?: boolean;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  formContent: ServiceRequestFormContent;
  isSingleService?: boolean;
  patient?: Patient;
  showAddressField?: boolean;
  formConfiguration?: FormConfiguration;
  hideTinField?: boolean;
  additionalCareParticipant: AdditionalCareParticipantFormContent;
  rowIndex: number;
  disabled?: boolean;
}> = ({
  formContent,
  additionalCareParticipantError,
  npiError,
  tinError,
  addressError,
  setFormContent,
  patient,
  showAddressField,
  formConfiguration,
  hideTinField = false,
  additionalCareParticipant,
  rowIndex,
  disabled,
}) => {
  const classes = useStyles();
  type modalStatus = ModalStatus;

  const [modalOp, setModalOp] = useState<modalStatus>(ModalStatus.AddAddress);

  const enableManualProviderInfo: boolean = useFeature("manualProviderInfo");

  const { mutate: updateFacility, error: facilityUpdateError } = useUpdateFacility({
    id: additionalCareParticipant ? additionalCareParticipant.id : "",
  });

  const { mutate: updateProvider, error: providerUpdateError } = useUpdateProvider({
    id: additionalCareParticipant ? additionalCareParticipant.id : "",
  });

  const { mutate: upsertManuallyEnteredProviderOrFacility, error: upsertManuallyEnteredProviderOrFacilityError } =
    useUpsertManuallyEnteredProviderOrFacility({});

  const getAddress = (address: any): string => {
    let finalAddress: string = "";
    if (address) {
      if (address?.line1) {
        finalAddress = `${finalAddress}${address.line1}, `;
      }
      if (address?.line2) {
        finalAddress = `${finalAddress}${address.line2}, `;
      }
      if (address?.city) {
        finalAddress = `${finalAddress}${address.city}, `;
      }
      if (address?.state) {
        finalAddress = `${finalAddress}${address.state} - `;
      }
      if (address?.zipCode) {
        finalAddress = `${finalAddress}${address.zipCode}`;
      }
    }
    return finalAddress;
  };

  let helperMessages: ReactNode[] = [];
  let warningNeeded = false;
  if (additionalCareParticipantError) {
    helperMessages.push("Required");
  }
  let helperText;
  if (helperMessages.length === 1) {
    helperText = helperMessages[0];
  } else if (helperMessages.length > 1) {
    helperText = (
      <>
        {helperMessages.map((m, idx) => (
          <span key={idx} className={classes.helperMsgLine}>
            {m}
          </span>
        ))}
      </>
    );
  }

  const [careParticipantModalOpen, setcareParticipantModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const useCanEditPractice = useAuthorized("EDIT_SERVICE_REQUEST");

  const { mutate: createFacility, error: facilityCreationError } = useCreateFacility({});
  const { mutate: createProvider, error: providerCreationError } = useCreateProvider({});

  useEffect(() => {
    if (facilityCreationError) {
      enqueueSnackbar(facilityCreationError.message, { variant: "error" });
    }
  }, [facilityCreationError, enqueueSnackbar]);

  useEffect(() => {
    if (providerCreationError) {
      enqueueSnackbar(providerCreationError.message, { variant: "error" });
    }
  }, [providerCreationError, enqueueSnackbar]);
  useEffect(() => {
    if (facilityUpdateError || upsertManuallyEnteredProviderOrFacilityError) {
      enqueueSnackbar(facilityUpdateError?.message || upsertManuallyEnteredProviderOrFacilityError?.message, {
        variant: "error",
      });
    }
  }, [facilityUpdateError, enqueueSnackbar, upsertManuallyEnteredProviderOrFacilityError]);

  useEffect(() => {
    if (providerUpdateError || upsertManuallyEnteredProviderOrFacilityError) {
      enqueueSnackbar(providerUpdateError?.message || upsertManuallyEnteredProviderOrFacilityError?.message, {
        variant: "error",
      });
    }
  }, [providerUpdateError, enqueueSnackbar, upsertManuallyEnteredProviderOrFacilityError]);

  const healthPlanName = patient ? getPatientHealthPlanName(patient, new Date()) : undefined;

  function getUpdatedAdditionalCareParticipantList(
    additionalCareParticipants: AdditionalCareParticipantFormContent[] | undefined,
    currCareParticipant: AdditionalCareParticipantFormContent | undefined | null,
    rowIndex: number
  ) {
    const tempAdditionalCareParticipants = structuredClone(additionalCareParticipants);
    const arrayStart = tempAdditionalCareParticipants?.slice(0, rowIndex);
    const currAddtionalCareParticipantArray = currCareParticipant ? [currCareParticipant] : [];
    const arrayEnd = tempAdditionalCareParticipants?.slice(rowIndex + 1, additionalCareParticipants?.length);
    const updatedCareParticipants =
      arrayStart !== undefined && arrayEnd !== undefined
        ? concat(arrayStart, currAddtionalCareParticipantArray, arrayEnd)
        : [];

    return updatedCareParticipants;
  }

  function updateTinListInCareParticipant(
    additionalCareParticipants: AdditionalCareParticipantFormContent[] | undefined,
    tin: string | null,
    rowIndex: number
  ) {
    const tempAdditionalCareParticipants = structuredClone(additionalCareParticipants);
    const arrayStart = tempAdditionalCareParticipants?.slice(0, rowIndex);
    const currAdditionalCareParticipant = tempAdditionalCareParticipants?.at(rowIndex);
    if (currAdditionalCareParticipant !== undefined) {
      currAdditionalCareParticipant.careParticipantType =
        tempAdditionalCareParticipants?.at(rowIndex)?.careParticipantType;
      currAdditionalCareParticipant.configuredEntityType =
        tempAdditionalCareParticipants?.at(rowIndex)?.configuredEntityType;
      currAdditionalCareParticipant.fieldConfigurations =
        tempAdditionalCareParticipants?.at(rowIndex)?.fieldConfigurations;
      currAdditionalCareParticipant.selectedTin = tin;
    }
    const currAddtionalCareParticipantArray = currAdditionalCareParticipant ? [currAdditionalCareParticipant] : [];
    const arrayEnd = tempAdditionalCareParticipants?.slice(
      rowIndex + 1,
      formContent.additionalCareParticipants?.length
    );
    const updatedCareParticipantListWithTin =
      arrayStart !== undefined && arrayEnd !== undefined
        ? concat(arrayStart, currAddtionalCareParticipantArray, arrayEnd)
        : [];
    return updatedCareParticipantListWithTin;
  }

  const showEditOptionForAcp =
    formContent?.additionalCareParticipants?.at(rowIndex) &&
    formContent?.additionalCareParticipants?.at(rowIndex)?.selectedAddress &&
    formContent?.additionalCareParticipants?.at(rowIndex)?.selectedLocation?.tin;

  const isNameOptional = getFieldSpecForAdditionalCareParticipant(additionalCareParticipant, "name") === "OPTIONAL";
  const isNpiOptional = getFieldSpecForAdditionalCareParticipant(additionalCareParticipant, "npi") === "OPTIONAL";

  return (
    <>
      <Grid
        item
        style={{
          flex: `1 1 560px`,
          minWidth: "200px",
        }}
      >
        <SingleSelectForProviderFacilitySelect<AdditionalCareParticipantOption>
          disablePortal
          markSelectedOptions={false}
          error={additionalCareParticipantError || npiError}
          clearable={
            getFieldSpecForAdditionalCareParticipant(additionalCareParticipant, "npi") === "OPTIONAL" &&
            !formContent?.additionalCareParticipants?.at(rowIndex)?.isDisabled
          }
          helperText={additionalCareParticipantError ? "Required" : npiError ? "NPI Required" : ""}
          label={`${getAdditionalCareParticipantName(additionalCareParticipant.careParticipantType)}${
            isNameOptional && isNpiOptional ? " (optional)" : ""
          }`}
          reactLabel={
            <MiddleTruncateLabelWithEndornment
              leftLabel={getAdditionalCareParticipantName(additionalCareParticipant.careParticipantType)}
              rightLabel={isNameOptional && isNpiOptional ? "(optional)" : ""}
            />
          }
          emptyLabel={
            <MiddleTruncateLabelWithEndornment
              leftLabel={`Search for a ${getAdditionalCareParticipantName(
                additionalCareParticipant.careParticipantType
              ).toLowerCase()} by NPI${!hideTinField ? ", TIN," : ""} or name`}
              rightLabel={isNameOptional && isNpiOptional ? "(optional)" : ""}
            />
          }
          useOptions={getUseAdditionalCareParticipants(
            patient,
            "true",
            additionalCareParticipant.careParticipantType,
            additionalCareParticipant.configuredEntityType,
            additionalCareParticipant.fieldConfigurations,
            hideTinField
          )}
          disabled={additionalCareParticipant?.isDisabled || disabled || false}
          getOptionLabel={({ name, npi }) => `${name} / NPI - ${npi || (isNpiOptional ? "N/A" : "")}`}
          renderOption={({ name, tinList, npi, optionType }) =>
            providerFacilityDropdownRender(
              name,
              tinList,
              npi,
              optionType,
              (tin: string | null) => {
                setFormContent({
                  ...formContent,
                  additionalCareParticipants: updateTinListInCareParticipant(
                    formContent.additionalCareParticipants,
                    tin,
                    rowIndex
                  ),
                });
              },
              hideTinField,
              isNpiOptional
            )
          }
          withEditAdornment={
            canEditPractice(useCanEditPractice, formContent?.additionalCareParticipants?.at(rowIndex) || null) &&
            !!showEditOptionForAcp &&
            !formContent?.additionalCareParticipants?.at(rowIndex)?.isDisabled
          }
          withWarnAdornment={warningNeeded}
          editAction={() => {
            setModalOp(ModalStatus.Edit);
            setcareParticipantModalOpen(true);
          }}
          selectedValue={
            additionalCareParticipant.name || additionalCareParticipant.npi ? additionalCareParticipant : null
          }
          setSelectedValue={async (additionalCareParticipant) => {
            if (additionalCareParticipant !== undefined && additionalCareParticipant !== null) {
              additionalCareParticipant.careParticipantType =
                formContent.additionalCareParticipants?.at(rowIndex)?.careParticipantType;
              additionalCareParticipant.fieldConfigurations =
                formContent.additionalCareParticipants?.at(rowIndex)?.fieldConfigurations;
              additionalCareParticipant.configuredEntityType =
                formContent.additionalCareParticipants?.at(rowIndex)?.configuredEntityType;
            }
            const selectedTin =
              additionalCareParticipant?.tinList?.length === 1 ? additionalCareParticipant.tinList[0] : undefined;
            let locationsForTin = getLocationsForTin(selectedTin, additionalCareParticipant, hideTinField);
            let selectedLocation: Location = {};
            if (locationsForTin && locationsForTin?.length === 1) {
              selectedLocation = locationsForTin[0];
            }
            if (
              hideTinField ||
              (additionalCareParticipant?.source === "CMS" &&
                !additionalCareParticipant?.manuallyCreated &&
                !selectedLocation.address)
            ) {
              if (additionalCareParticipant?.locations && additionalCareParticipant.locations.length > 0) {
                selectedLocation = additionalCareParticipant.locations[0];
              }
            }
            if (["noResults", "someResults"].includes(additionalCareParticipant?.optionType || "")) {
              if (additionalCareParticipant !== null && additionalCareParticipant !== undefined) {
                additionalCareParticipant.selectedTin = undefined;
                additionalCareParticipant.selectedAddress = undefined;
              }
              setFormContent((prev) => ({
                ...prev,
                additionalCareParticipants: getUpdatedAdditionalCareParticipantList(
                  formContent.additionalCareParticipants,
                  additionalCareParticipant,
                  rowIndex
                ),
              }));
              setModalOp(ModalStatus.Add);
              setcareParticipantModalOpen(true);
            } else {
              const additionalCareParticipantCalc = {
                ...additionalCareParticipant,
                selectedLocation: selectedLocation.address ? selectedLocation : undefined,
                type: additionalCareParticipant ? additionalCareParticipant.configuredEntityType : "PROVIDER",
                careParticipantType: formContent.additionalCareParticipants?.at(rowIndex)?.careParticipantType,
                fieldConfigurations: formContent.additionalCareParticipants?.at(rowIndex)?.fieldConfigurations,
                configuredEntityType: formContent.additionalCareParticipants?.at(rowIndex)?.configuredEntityType,
                tinList: additionalCareParticipant?.tinList,
                selectedTin:
                  additionalCareParticipant?.tinList?.length === 1 ? additionalCareParticipant.tinList[0] : undefined,
              } as AdditionalCareParticipantFormContent;
              setFormContent((prev) => ({
                ...prev,
                additionalCareParticipants: getUpdatedAdditionalCareParticipantList(
                  formContent.additionalCareParticipants,
                  additionalCareParticipantCalc,
                  rowIndex
                ),
              }));
            }
          }}
          className={warningNeeded ? classes.root : undefined}
          data-testid="additionalCareParticipant"
        />
      </Grid>
      {(showAddressField || !hideTinField) && (
        <Grid
          item
          container
          spacing={2}
          style={{
            flex: `1 1 50%`,
          }}
        >
          {!hideTinField && (
            <Grid item style={{ flex: `1 1 auto`, minWidth: "167px" }}>
              <SingleSelectForProviderFacilitySelect<TinOption>
                label={"TIN"}
                clearable={
                  getFieldSpecForAdditionalCareParticipant(additionalCareParticipant, "tin") === "OPTIONAL" &&
                  !formContent?.additionalCareParticipants?.at(rowIndex)?.isDisabled
                }
                disabled={
                  additionalCareParticipant?.isDisabled ||
                  !(
                    (Boolean(formContent.additionalCareParticipants) &&
                      Boolean(formContent.additionalCareParticipants?.at(rowIndex)) &&
                      Boolean(formContent.additionalCareParticipants?.at(rowIndex)?.tinList)) ||
                    Boolean(formContent.additionalCareParticipants?.at(rowIndex)?.selectedLocation?.tin) ||
                    (formContent.additionalCareParticipants?.at(rowIndex)?.source === "CMS" &&
                      !formContent.additionalCareParticipants.at(rowIndex)?.manuallyCreated)
                  ) ||
                  disabled
                }
                markSelectedOptions={false}
                withEditAdornment={
                  Boolean(additionalCareParticipant.manuallyCreated) &&
                  Boolean(additionalCareParticipant.selectedTin) &&
                  !formContent?.additionalCareParticipants?.at(rowIndex)?.isDisabled
                }
                editAction={() => {
                  setModalOp(ModalStatus.EditTin);
                  setcareParticipantModalOpen(true);
                }}
                setSelectedValue={async (tin) => {
                  if (tin && tin.id !== "") {
                    setFormContent((prev) => ({
                      ...prev,
                      additionalCareParticipants: updateTinListInCareParticipant(
                        formContent.additionalCareParticipants,
                        tin.id,
                        rowIndex
                      ),
                    }));

                    let locationsForTin = getLocationsForTin(
                      tin.id,
                      formContent?.additionalCareParticipants?.at(rowIndex)
                    );

                    let selectedLocation: Location = {};
                    if (locationsForTin && locationsForTin?.length === 1) {
                      selectedLocation = locationsForTin[0];
                    }

                    const selectedCareParticipant: AdditionalCareParticipantFormContent = {
                      ...formContent?.additionalCareParticipants?.at(rowIndex),
                      selectedLocation: selectedLocation.address ? selectedLocation : undefined,
                      careParticipantType: additionalCareParticipant.careParticipantType,
                      configuredEntityType: additionalCareParticipant.configuredEntityType,
                      fieldConfigurations: additionalCareParticipant.fieldConfigurations,
                      tinList: additionalCareParticipant.tinList,
                      selectedTin: tin.id,
                    } as AdditionalCareParticipantFormContent;
                    setFormContent((prev) => ({
                      ...prev,
                      additionalCareParticipants: getUpdatedAdditionalCareParticipantList(
                        formContent.additionalCareParticipants,
                        selectedCareParticipant,
                        rowIndex
                      ),
                    }));
                  } else if (["noResults", "someResults"].includes(tin?.optionType || "")) {
                    const additionalCareParticipantCalc = {
                      ...additionalCareParticipant,
                      selectedLocation: undefined,
                      type: additionalCareParticipant.configuredEntityType,
                      careParticipantType: additionalCareParticipant.careParticipantType,
                      fieldConfigurations: additionalCareParticipant.fieldConfigurations,
                      tinList: additionalCareParticipant.tinList,
                      selectedTin: undefined,
                    } as AdditionalCareParticipantFormContent;
                    setFormContent((prev) => ({
                      ...prev,
                      additionalCareParticipants: getUpdatedAdditionalCareParticipantList(
                        formContent.additionalCareParticipants,
                        additionalCareParticipantCalc,
                        rowIndex
                      ),
                    }));
                    setModalOp(ModalStatus.AddTin);
                    setcareParticipantModalOpen(true);
                  }
                }}
                useOptions={() => ({
                  options: getTinListWithOptions(formContent?.additionalCareParticipants?.at(rowIndex)?.tinList),
                  optionsLoading: false,
                  filterOptions: (options, state) =>
                    options.filter((option) => option.val?.toLowerCase().includes(state.inputValue.toLowerCase())),
                })}
                data-testid="requesting--additionalCareParticipant-tin"
                selectedValue={getTinWithOptions(
                  additionalCareParticipant.selectedTin || additionalCareParticipant.selectedLocation?.tin
                )}
                error={tinError}
                getOptionLabel={({ val }) => val}
                renderOption={({ id, val, optionType }) => providerFacilityTinRenderer(id, val, optionType)}
                helperText={tinError && "Required"}
              />
            </Grid>
          )}
          {showAddressField && (
            <Grid item style={{ flex: `1 1 60%`, minWidth: "200px" }}>
              <SingleSelectForProviderFacilitySelect<LocationOption>
                label={"Address"}
                disabled={
                  additionalCareParticipant?.isDisabled ||
                  (!(
                    Boolean(formContent.additionalCareParticipants) &&
                    Boolean(formContent.additionalCareParticipants?.at(rowIndex)) &&
                    Boolean(formContent.additionalCareParticipants?.at(rowIndex)?.name)
                  ) &&
                    (hideTinField ||
                      Boolean(formContent.additionalCareParticipants?.at(rowIndex)?.selectedLocation?.address) ||
                      (formContent.additionalCareParticipants?.at(rowIndex)?.source === "CMS" &&
                        !formContent.additionalCareParticipants?.at(rowIndex)?.manuallyCreated))) ||
                  disabled
                }
                clearable={
                  getFieldSpecForAdditionalCareParticipant(additionalCareParticipant, "address") === "OPTIONAL" &&
                  !formContent?.additionalCareParticipants?.at(rowIndex)?.isDisabled
                }
                markSelectedOptions={false}
                withEditAdornment={
                  Boolean(additionalCareParticipant.manuallyCreated) &&
                  Boolean(additionalCareParticipant.selectedAddress) &&
                  Boolean(additionalCareParticipant.selectedAddress?.manuallyEditedAddress) &&
                  !formContent?.additionalCareParticipants?.at(rowIndex)?.isDisabled
                }
                useOptions={() => ({
                  options: getLocationsWithOptions(
                    getLocationsForTin(
                      formContent?.additionalCareParticipants?.at(rowIndex)?.selectedTin,
                      formContent?.additionalCareParticipants?.at(rowIndex),
                      hideTinField
                    ),
                    enableManualProviderInfo,
                    formContent?.additionalCareParticipants?.at(rowIndex)?.selectedTin,
                    hideTinField
                  ),
                  optionsLoading: false,
                  filterOptions: (options, state) =>
                    options.filter(
                      (option) =>
                        option?.address?.city?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                        option?.address?.line1?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                        option?.address?.line2?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                        option?.address?.state?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                        option?.address?.zipCode?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                        option?.optionType === "noResults" ||
                        option?.optionType === "someResults"
                    ),
                })}
                helperText={addressError && "Required"}
                selectedValue={additionalCareParticipant?.selectedLocation || null}
                getOptionLabel={({ address }) => getAddress(address)}
                editAction={() => {
                  setModalOp(ModalStatus.EditAddress);
                  setcareParticipantModalOpen(true);
                }}
                setSelectedValue={(location) => {
                  if (["noResults", "someResults"].includes(location?.optionType || "")) {
                    const additionalCareParticipantCalc = {
                      ...additionalCareParticipant,
                      selectedLocation: undefined,
                      type: additionalCareParticipant.configuredEntityType,
                      careParticipantType: additionalCareParticipant.careParticipantType,
                      fieldConfigurations: additionalCareParticipant.fieldConfigurations,
                    } as AdditionalCareParticipantFormContent;
                    setFormContent((prev) => ({
                      ...prev,
                      additionalCareParticipants: getUpdatedAdditionalCareParticipantList(
                        formContent.additionalCareParticipants,
                        additionalCareParticipantCalc,
                        rowIndex
                      ),
                    }));
                    setModalOp(ModalStatus.AddAddress);
                    setcareParticipantModalOpen(true);
                  } else {
                    const additionalCareParticipantCalc = {
                      ...additionalCareParticipant,
                      selectedLocation: location,
                      type: additionalCareParticipant.configuredEntityType,
                      careParticipantType: additionalCareParticipant?.careParticipantType,
                      fieldConfigurations: additionalCareParticipant.fieldConfigurations,
                    } as AdditionalCareParticipantFormContent;
                    setFormContent((prev) => ({
                      ...prev,
                      additionalCareParticipants: getUpdatedAdditionalCareParticipantList(
                        formContent.additionalCareParticipants,
                        additionalCareParticipantCalc,
                        rowIndex
                      ),
                    }));
                  }
                }}
                renderOption={({ address, optionType }) => providerFacilityAddressRenderer(address, optionType)}
                error={addressError}
                className={warningNeeded && Boolean(helperText) ? classes.root : undefined}
                data-testid="AdditionalCareParticipant-address"
              />
            </Grid>
          )}
        </Grid>
      )}

      <ProviderFacilityModal
        open={careParticipantModalOpen}
        modalType={additionalCareParticipant.configuredEntityType === "FACILITY" ? "facility" : "performingProvider"}
        patientHealthPlan={healthPlanName ? healthPlanName : undefined}
        modalStatus={modalOp}
        setSelection={async (params: PracticeUpsertPayload, practice: Provider | Facility) => {
          if (modalOp === ModalStatus.Edit) {
            let retVal: AdditionalCareParticipantFormContent = {
              id: "",
              dateCreated: "",
              lastUpdated: "",
              careParticipantType: additionalCareParticipant.careParticipantType,
              configuredEntityType: additionalCareParticipant.configuredEntityType,
              fieldConfigurations: additionalCareParticipant.fieldConfigurations,
              tinList: additionalCareParticipant.tinList,
            };
            if (params.type) {
              if (params.type === "Facility") {
                retVal = await updateFacility({
                  id: practice?.id,
                  dateCreated: practice?.dateCreated,
                  lastUpdated: practice?.lastUpdated,
                  ...params,
                });
              } else {
                retVal = await updateProvider({
                  id: practice?.id,
                  dateCreated: practice?.dateCreated,
                  lastUpdated: practice?.lastUpdated,
                  ...params,
                });
              }
            } else if (params.recordType) {
              // params is a ProviderFacilityCatalogue payload
              retVal = await upsertManuallyEnteredProviderOrFacility({
                npi: params.npi || "",
                tinList: params.tinList || [],
                locations: params.locations || [],
                recordType: params.recordType,
              });
            }
            retVal.type = params.type || params.recordType;
            retVal.selectedLocation = params.selectedLocation;
            retVal.configuredEntityType = additionalCareParticipant.configuredEntityType;
            retVal.careParticipantType = additionalCareParticipant.careParticipantType;
            retVal.fieldConfigurations = additionalCareParticipant.fieldConfigurations;
            retVal.selectedTin = retVal.tinList ? retVal.tinList[retVal.tinList.length - 1] : null;
            retVal.selectedAddress = retVal.locations ? retVal.locations[retVal.locations.length - 1] : null;
            setFormContent({
              ...formContent,
              additionalCareParticipants: getUpdatedAdditionalCareParticipantList(
                formContent.additionalCareParticipants,
                retVal,
                rowIndex
              ),
            });
          }
        }}
        savedPractice={additionalCareParticipant}
        setSelectedTin={undefined}
        onClose={() => {
          setcareParticipantModalOpen(false);
        }}
        onSave={async (careParticipant: PracticeUpsertPayload, setEntry: (practice: Provider | Facility) => void) => {
          if (
            modalOp === ModalStatus.AddAddress ||
            modalOp === ModalStatus.EditAddress ||
            modalOp === ModalStatus.AddTin ||
            modalOp === ModalStatus.EditTin
          ) {
            try {
              if (additionalCareParticipant) {
                const careParticipantForUpdate = getAdditionalCareParticipantForUpdate(
                  additionalCareParticipant,
                  additionalCareParticipant.selectedTin,
                  additionalCareParticipant.selectedLocation,
                  modalOp,
                  careParticipant,
                  hideTinField
                );
                //manual record add/update a tin/address
                if (additionalCareParticipant?.manuallyCreated) {
                  if (careParticipant.type) {
                    careParticipantForUpdate.lastUpdated = new Date().toISOString();
                    if (careParticipantForUpdate.configuredEntityType === "FACILITY") {
                      await updateFacility({ ...careParticipantForUpdate });
                    } else {
                      await updateProvider({ ...careParticipantForUpdate });
                    }
                  } else if (careParticipant.recordType) {
                    await upsertManuallyEnteredProviderOrFacility({
                      npi: careParticipantForUpdate.npi || "",
                      tinList: careParticipantForUpdate.tinList || [],
                      locations: careParticipantForUpdate.locations || [],
                      recordType: careParticipantForUpdate.recordType,
                    });
                  }
                } else {
                  //create a manual record and then switch
                  careParticipantForUpdate.manuallyCreated = true;
                  careParticipantForUpdate.createdByUser = false;
                  if (careParticipant.type) {
                    careParticipantForUpdate.dateCreated = new Date().toISOString();
                    if (careParticipantForUpdate.configuredEntityType === "FACILITY") {
                      const newCareParticipant = await createFacility({ ...careParticipantForUpdate });
                      careParticipantForUpdate.id = newCareParticipant.id;
                      careParticipantForUpdate.source = newCareParticipant.source;
                    } else {
                      const newCareParticipant = await createProvider({ ...careParticipantForUpdate });
                      careParticipantForUpdate.id = newCareParticipant.id;
                      careParticipantForUpdate.source = newCareParticipant.source;
                    }
                  } else if (careParticipant.recordType) {
                    const newCareParticipant = await upsertManuallyEnteredProviderOrFacility({
                      npi: careParticipantForUpdate.npi || "",
                      tinList: careParticipantForUpdate.tinList || [],
                      locations: careParticipantForUpdate.locations || [],
                      recordType: careParticipantForUpdate.recordType,
                    });
                    careParticipantForUpdate.id = newCareParticipant.id;
                    careParticipantForUpdate.source = newCareParticipant.source;
                  }
                  setFormContent((prev) => ({
                    ...prev,
                    additionalCareParticipants: getUpdatedAdditionalCareParticipantList(
                      formContent.additionalCareParticipants,
                      careParticipantForUpdate,
                      rowIndex
                    ),
                  }));
                }
                careParticipantForUpdate.selectedTin = careParticipantForUpdate.selectedLocation?.tin;
                careParticipantForUpdate.selectedAddress = careParticipantForUpdate.selectedLocation;
                setFormContent((prev) => ({
                  ...prev,
                  additionalCareParticipants: getUpdatedAdditionalCareParticipantList(
                    formContent.additionalCareParticipants,
                    careParticipantForUpdate,
                    rowIndex
                  ),
                }));
                setEntry(careParticipantForUpdate);
              }
            } catch (error) {
              enqueueSnackbar("error occured while updating facility entity", { variant: "error" });
            }
          } else if (modalOp === ModalStatus.Add) {
            let retVal: AdditionalCareParticipantFormContent = { id: "", dateCreated: "", lastUpdated: "" };
            if (careParticipant.type) {
              if (careParticipant.recordType === "FACILITY") {
                retVal = await createFacility({
                  ...careParticipant,
                });
              } else {
                retVal = await createProvider({
                  ...careParticipant,
                });
              }
            } else if (careParticipant.recordType) {
              retVal = await upsertManuallyEnteredProviderOrFacility({
                npi: careParticipant.npi || "",
                tinList: careParticipant.tinList || [],
                locations: careParticipant.locations || [],
                recordType: careParticipant.recordType,
              });
            }
            retVal.type = careParticipant.type;
            retVal.selectedLocation = careParticipant.selectedLocation;
            retVal.configuredEntityType = additionalCareParticipant.configuredEntityType;
            retVal.careParticipantType = additionalCareParticipant.careParticipantType;
            retVal.fieldConfigurations = additionalCareParticipant.fieldConfigurations;
            retVal.selectedTin = retVal.tinList ? retVal.tinList[0] : null;
            retVal.selectedAddress = !!careParticipant.locations ? careParticipant.locations[0] : null;
            setFormContent({
              ...formContent,
              additionalCareParticipants: getUpdatedAdditionalCareParticipantList(
                formContent.additionalCareParticipants,
                retVal,
                rowIndex
              ),
            });
            setEntry(retVal);
          }
        }}
        selectedTin={additionalCareParticipant.selectedTin ? additionalCareParticipant.selectedTin : undefined}
        selectedLocation={additionalCareParticipant?.selectedLocation || null}
        hideTinField={
          hideTinField &&
          additionalCareParticipant?.fieldConfigurations?.find((fieldConfig) => fieldConfig?.fieldName === "tin")
            ?.fieldSpecification !== "OMIT"
        }
      />
    </>
  );
};

export default AdditionalCareParticipantsSelect;
