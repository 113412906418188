import React, { useCallback, useMemo, Dispatch, SetStateAction } from "react";

import { Body1, colorsDark, colorsLight, InlineButton, useToolbarStyles, ToggleListButton } from "@coherehealth/common";
import { NotificationViewerConfig } from "components/NotificationViewer/Viewers";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled, IconButton, Box, Theme } from "@material-ui/core";
import clsx from "clsx";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RotateLeftOutlinedIcon from "@material-ui/icons/RotateLeftOutlined";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import Rotate90DegreesCcwIcon from "@material-ui/icons/Rotate90DegreesCcw";
import CloseIcon from "@material-ui/icons/Close";

interface Props {
  currentPage: number;
  totalPages: number;
  setPageNumber?: (arg0: number) => void;
  scale: number;
  setScale: (arg0: number) => void;
  rotate: number;
  rotateDegrees?: number[];
  setRotate: (arg0: number) => void;
  crrStyling?: boolean;
  openSidePanel?: boolean;
  setOpenSidePanel?: Dispatch<SetStateAction<boolean>>;
  viewerConfig?: NotificationViewerConfig;
}

const useIconButtonStyles = makeStyles({
  root: {
    "&.disabled": {
      color: colorsDark.gray.divider,
    },
  },
});

// As percentages
const SCALE_LEVELS = [25, 50, 75, 90, 100, 110, 125, 150, 200, 400];
const ROTATE_DEGREES = [0, 180, 90, 270];

export default function DocumentNavigator({
  currentPage,
  totalPages,
  setPageNumber,
  scale,
  setScale,
  rotate,
  rotateDegrees: inputRotateDegrees,
  setRotate,
  crrStyling,
  openSidePanel,
  setOpenSidePanel,
  viewerConfig,
}: Props) {
  const rotateDegrees = inputRotateDegrees || ROTATE_DEGREES;
  const iconButtonClasses = useIconButtonStyles();
  const toolbarClasses = useToolbarStyles({});

  const prevPageDisabled = currentPage <= 1;
  const goToPrevPage = useCallback(() => {
    if (!prevPageDisabled) {
      setPageNumber && setPageNumber(currentPage - 1);
    }
  }, [prevPageDisabled, setPageNumber, currentPage]);
  const nextPageDisabled = currentPage >= totalPages;
  const goToNextPage = useCallback(() => {
    if (!nextPageDisabled) {
      setPageNumber && setPageNumber(currentPage + 1);
    }
  }, [nextPageDisabled, setPageNumber, currentPage]);

  // Snap the given scale to one of the levels defined above
  const scaleIndex = useMemo(() => {
    // find the next largest scale level
    const scalePercentage = Math.round(scale * 100);
    let i;
    for (i = 0; i < SCALE_LEVELS.length; i++) {
      if (SCALE_LEVELS[i] >= scalePercentage) {
        return i;
      }
    }
    return i;
  }, [scale]);
  const zoomOutDisabled = scaleIndex <= 0;
  const zoomOut = useCallback(() => {
    if (!zoomOutDisabled) {
      setScale(SCALE_LEVELS[scaleIndex - 1] / 100);
    }
  }, [zoomOutDisabled, setScale, scaleIndex]);
  const zoomInDisabled = scaleIndex >= SCALE_LEVELS.length - 1;
  const zoomIn = useCallback(() => {
    if (!zoomInDisabled) {
      setScale(SCALE_LEVELS[scaleIndex + 1] / 100);
    }
  }, [zoomInDisabled, setScale, scaleIndex]);

  const doRotate = useCallback(() => {
    const currentIndex = rotateDegrees.indexOf(rotate);
    const newIndex = (currentIndex + 1) % rotateDegrees.length;
    setRotate(rotateDegrees[newIndex]);
  }, [setRotate, rotate, rotateDegrees]);

  return crrStyling ? (
    <ToolbarWrapper crrStyling style={{ borderBottom: "1px solid #E5E5E5" }}>
      {crrStyling && (
        <Box className={clsx(toolbarClasses.toolbarControls, toolbarClasses.toolbarLeftControls)}>
          <ToolbarItemWrapper>
            <ToggleListButton openSidePanel={openSidePanel} setOpenSidePanel={setOpenSidePanel} />
          </ToolbarItemWrapper>
        </Box>
      )}
      <Box className={clsx(toolbarClasses.toolbarControls, toolbarClasses.toolbarRightControls)}>
        <NavigatorItem style={{ marginRight: 30 }}>
          <ToolbarBody3>Page 1 of 1</ToolbarBody3>
        </NavigatorItem>
        <NavigatorItem style={{ marginLeft: 37 }}>
          <ToolbarTertiaryInlineButton classes={iconButtonClasses} color="inherit" onClick={doRotate}>
            <Rotate90DegreesCcwIcon />
          </ToolbarTertiaryInlineButton>
        </NavigatorItem>
        <NavigatorItem>
          <ToolbarTertiaryInlineButton
            classes={iconButtonClasses}
            disabled={zoomOutDisabled}
            color="inherit"
            onClick={zoomOut}
          >
            <ZoomOutIcon />
          </ToolbarTertiaryInlineButton>
          <ToolbarTertiaryInlineButton
            classes={iconButtonClasses}
            disabled={zoomInDisabled}
            color="inherit"
            onClick={zoomIn}
          >
            <ZoomInIcon />
          </ToolbarTertiaryInlineButton>
        </NavigatorItem>
      </Box>
      {viewerConfig?.closeDialogFunction && (
        <IconButton
          onClick={viewerConfig.closeDialogFunction}
          disableFocusRipple={true}
          size="small"
          style={{ marginRight: "4px", marginLeft: "16px" }}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      )}
    </ToolbarWrapper>
  ) : (
    <NavigatorWrapper>
      {Boolean(setPageNumber) && (
        <NavigatorItem>
          <IconButton classes={iconButtonClasses} disabled={prevPageDisabled} color="inherit" onClick={goToPrevPage}>
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Body1>
            {currentPage} of {totalPages}
          </Body1>
          <IconButton classes={iconButtonClasses} disabled={nextPageDisabled} color="inherit" onClick={goToNextPage}>
            <KeyboardArrowRightIcon />
          </IconButton>
        </NavigatorItem>
      )}
      <NavigatorItem>
        <IconButton classes={iconButtonClasses} disabled={zoomOutDisabled} color="inherit" onClick={zoomOut}>
          <RemoveCircleOutlineIcon />
        </IconButton>
        <Body1>{SCALE_LEVELS[scaleIndex]}%</Body1>
        <IconButton classes={iconButtonClasses} disabled={zoomInDisabled} color="inherit" onClick={zoomIn}>
          <AddCircleOutlineIcon />
        </IconButton>
      </NavigatorItem>
      <NavigatorItem>
        <Body1>{rotate}°</Body1>
        <IconButton classes={iconButtonClasses} color="inherit" onClick={doRotate}>
          <RotateLeftOutlinedIcon />
        </IconButton>
      </NavigatorItem>
    </NavigatorWrapper>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const NavigatorWrapper = styled("div")(({ theme }) => ({
  backgroundColor: colorsDark.background.mid,
  bottom: theme.spacing(6),
  color: theme.palette.common.white,
  display: "flex",
  justifyContent: "space-around",
  left: "50%",
  marginLeft: -200,
  position: "absolute",
  width: 400,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const NavigatorItem = styled("div")({
  display: "flex",
  alignItems: "center",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ToolbarTertiaryInlineButton = styled(InlineButton)(({ theme }) => ({
  color: colorsLight.font.light,
  padding: theme.spacing(0.75, 0.75, 0),
  minWidth: 20,
  "&:focus": {
    color: colorsLight.font.light,
  },
  "&:hover,&:active": {
    color: colorsLight.primary.main,
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ToolbarItemWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  paddingRight: theme.spacing(3),
  [theme.breakpoints.down("xl")]: {
    paddingRight: theme.spacing(1),
  },
  flexShrink: 2,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ToolbarWrapper = styled(({ crrStyling, ...other }) => <div {...other} />)<Theme, { crrStyling?: boolean }>(
  ({ theme, crrStyling }) => ({
    alignItems: "center",
    display: "flex",
    justifyContent: crrStyling ? "flex-start" : "center",
    backgroundColor: colorsLight.primary.contrastText,
    height: "100%",
    width: "100%",
  })
);

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ToolbarBody3 = styled("div")({
  color: colorsLight.font.light,
  fontFamily: "Gilroy-Medium",
  fontSize: "13px",
  fontWeight: 400,
});
