import { createContext } from "react";

export type AutocompleteOnChangeFn = (arg0: { fieldName: string; selectedValue: any }) => void;
interface IAutocompleteChangeSubscriberContext {
  onChange: AutocompleteOnChangeFn;
}

export const AutocompleteChangeSubscriberContext = createContext<IAutocompleteChangeSubscriberContext>({
  onChange: () => {},
});
