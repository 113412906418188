/* Generated by restful-react */

import React from "react";
import { Get, GetProps, useGet, UseGetProps } from "restful-react";
export const SPEC_VERSION = "1.0.0";
export interface Author {
  id?: string;
  name?: string;
  email?: string;
}

export interface MongoChangeEventEntity {
  _id?: {
    _data?: string;
  };
  clusterTime?: number;
  operationType?: string;
  documentKey?: {
    _id?: string;
  };
  ns?: {
    coll?: string;
    db?: string;
  };
  fullDocument?: { [key: string]: any };
  updateDescription?: { [key: string]: any };
  author?: Author;
}

/**
 * {
 *     "errorCode": "String",
 *     "errorDescription": "String"
 * }
 *
 */
export type ExampleErrorResponseResponse = void;

export interface GetServiceRequestAuditHistoryQueryParams {
  embed_users?: string;
}

export interface GetServiceRequestAuditHistoryPathParams {
  srId: string;
}

export type GetServiceRequestAuditHistoryProps = Omit<
  GetProps<
    MongoChangeEventEntity[],
    unknown,
    GetServiceRequestAuditHistoryQueryParams,
    GetServiceRequestAuditHistoryPathParams
  >,
  "path"
> &
  GetServiceRequestAuditHistoryPathParams;

export const GetServiceRequestAuditHistory = ({
  srId,
  ...props
}: GetServiceRequestAuditHistoryProps) => (
  <Get<
    MongoChangeEventEntity[],
    unknown,
    GetServiceRequestAuditHistoryQueryParams,
    GetServiceRequestAuditHistoryPathParams
  >
    path={`/auditHistory/serviceRequest/${srId}`}
    {...props}
  />
);

export type UseGetServiceRequestAuditHistoryProps = Omit<
  UseGetProps<
    MongoChangeEventEntity[],
    unknown,
    GetServiceRequestAuditHistoryQueryParams,
    GetServiceRequestAuditHistoryPathParams
  >,
  "path"
> &
  GetServiceRequestAuditHistoryPathParams;

export const useGetServiceRequestAuditHistory = ({
  srId,
  ...props
}: UseGetServiceRequestAuditHistoryProps) =>
  useGet<
    MongoChangeEventEntity[],
    unknown,
    GetServiceRequestAuditHistoryQueryParams,
    GetServiceRequestAuditHistoryPathParams
  >(
    (paramsInPath: GetServiceRequestAuditHistoryPathParams) =>
      `/auditHistory/serviceRequest/${paramsInPath.srId}`,
    { pathParams: { srId }, ...props }
  );

export interface GetAuthorizationAuditHistoryQueryParams {
  embed_users?: string;
}

export interface GetAuthorizationAuditHistoryPathParams {
  authId: string;
}

export type GetAuthorizationAuditHistoryProps = Omit<
  GetProps<
    MongoChangeEventEntity[],
    unknown,
    GetAuthorizationAuditHistoryQueryParams,
    GetAuthorizationAuditHistoryPathParams
  >,
  "path"
> &
  GetAuthorizationAuditHistoryPathParams;

export const GetAuthorizationAuditHistory = ({
  authId,
  ...props
}: GetAuthorizationAuditHistoryProps) => (
  <Get<
    MongoChangeEventEntity[],
    unknown,
    GetAuthorizationAuditHistoryQueryParams,
    GetAuthorizationAuditHistoryPathParams
  >
    path={`/auditHistory/authorization/${authId}`}
    {...props}
  />
);

export type UseGetAuthorizationAuditHistoryProps = Omit<
  UseGetProps<
    MongoChangeEventEntity[],
    unknown,
    GetAuthorizationAuditHistoryQueryParams,
    GetAuthorizationAuditHistoryPathParams
  >,
  "path"
> &
  GetAuthorizationAuditHistoryPathParams;

export const useGetAuthorizationAuditHistory = ({
  authId,
  ...props
}: UseGetAuthorizationAuditHistoryProps) =>
  useGet<
    MongoChangeEventEntity[],
    unknown,
    GetAuthorizationAuditHistoryQueryParams,
    GetAuthorizationAuditHistoryPathParams
  >(
    (paramsInPath: GetAuthorizationAuditHistoryPathParams) =>
      `/auditHistory/authorization/${paramsInPath.authId}`,
    { pathParams: { authId }, ...props }
  );

export interface GetReferralAuditHistoryQueryParams {
  embed_users?: string;
}

export interface GetReferralAuditHistoryPathParams {
  referralId: string;
}

export type GetReferralAuditHistoryProps = Omit<
  GetProps<
    MongoChangeEventEntity[],
    unknown,
    GetReferralAuditHistoryQueryParams,
    GetReferralAuditHistoryPathParams
  >,
  "path"
> &
  GetReferralAuditHistoryPathParams;

export const GetReferralAuditHistory = ({
  referralId,
  ...props
}: GetReferralAuditHistoryProps) => (
  <Get<
    MongoChangeEventEntity[],
    unknown,
    GetReferralAuditHistoryQueryParams,
    GetReferralAuditHistoryPathParams
  >
    path={`/auditHistory/referralRequest/${referralId}`}
    {...props}
  />
);

export type UseGetReferralAuditHistoryProps = Omit<
  UseGetProps<
    MongoChangeEventEntity[],
    unknown,
    GetReferralAuditHistoryQueryParams,
    GetReferralAuditHistoryPathParams
  >,
  "path"
> &
  GetReferralAuditHistoryPathParams;

export const useGetReferralAuditHistory = ({
  referralId,
  ...props
}: UseGetReferralAuditHistoryProps) =>
  useGet<
    MongoChangeEventEntity[],
    unknown,
    GetReferralAuditHistoryQueryParams,
    GetReferralAuditHistoryPathParams
  >(
    (paramsInPath: GetReferralAuditHistoryPathParams) =>
      `/auditHistory/referralRequest/${paramsInPath.referralId}`,
    { pathParams: { referralId }, ...props }
  );
