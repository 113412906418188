import { ClinicalAssessment, ClinicalAssessmentResponse } from "@coherehealth/core-platform-api";
import { GenericCode } from "@coherehealth/common";

export type State = ClinicalAssessment | undefined;
export type Action =
  | { type: "populate"; clinicalAssessment: ClinicalAssessment }
  | { type: "updateDateAnswer"; questionId: string; dateString: string }
  | { type: "updateMeasureAnswer"; questionId: string; userInput: string } // TODO unitType
  | { type: "updateFreeTextAnswer"; questionId: string; userInput: string }
  | {
      type: "updateSingleSelectAnswer";
      questionId: string;
      selectedOptionCodes: GenericCode[];
      selectedOptionId?: never;
    }
  | { type: "updateSingleSelectAnswer"; questionId: string; selectedOptionCodes?: never; selectedOptionId: string }
  | {
      type: "updateMultiSelectAnswer";
      questionId: string;
      selectedOptionCodesSelections: GenericCode[][];
      selectedOptionIds?: never;
    }
  | {
      type: "updateMultiSelectAnswer";
      questionId: string;
      selectedOptionCodesSelections?: never;
      selectedOptionIds: string[];
    };

// To satisfy typescript and not send null values
const genericCodeToQuestionAnswerCode = ({ code, system }: GenericCode) => ({
  code: code || "",
  system: system || "",
});

export const clinicalAssessmentReducer = (state: State, action: Action): State => {
  if (action.type === "populate") {
    return action.clinicalAssessment;
  }
  if (action.type === "updateDateAnswer") {
    const { questionId, dateString } = action;
    const codes = state?.questions?.find((q) => q.clinicalQuestion?.id === questionId)?.clinicalQuestion
      ?.observationCodes;
    return replaceAnswer(state, questionId, [
      { codes: codes?.map(genericCodeToQuestionAnswerCode), valueDate: dateString },
    ]);
  }
  if (action.type === "updateMeasureAnswer") {
    // TODO unitType
    const { questionId, userInput } = action;
    const codes = state?.questions?.find((q) => q.clinicalQuestion?.id === questionId)?.clinicalQuestion
      ?.observationCodes;
    if (userInput === "") {
      return replaceAnswer(state, questionId, []);
    }
    return replaceAnswer(state, questionId, [
      { codes: codes?.map(genericCodeToQuestionAnswerCode), valueString: userInput },
    ]);
  }
  if (action.type === "updateFreeTextAnswer") {
    const { questionId, userInput } = action;
    const codes = state?.questions?.find((q) => q.clinicalQuestion?.id === questionId)?.clinicalQuestion
      ?.observationCodes;
    if (userInput === "") {
      return replaceAnswer(state, questionId, []);
    }
    return replaceAnswer(state, questionId, [
      { codes: codes?.map(genericCodeToQuestionAnswerCode), valueString: userInput },
    ]);
  }
  if (action.type === "updateSingleSelectAnswer") {
    const { questionId, selectedOptionCodes, selectedOptionId } = action;
    return replaceAnswer(state, questionId, [
      { codes: selectedOptionCodes?.map(genericCodeToQuestionAnswerCode), optionId: selectedOptionId },
    ]);
  }

  if (action.type === "updateMultiSelectAnswer") {
    const { questionId, selectedOptionCodesSelections, selectedOptionIds } = action;
    return replaceAnswer(
      state,
      questionId,
      selectedOptionCodesSelections?.map((codes) => ({ codes: codes.map(genericCodeToQuestionAnswerCode) })) ||
        selectedOptionIds?.map((optionId) => ({ optionId })) ||
        []
    );
  }

  throw new Error("Unimplemented");
};

const replaceAnswer = (state: State, questionId: string, newAnswers: ClinicalAssessmentResponse["answers"]): State => {
  if (!state?.questions?.find((q) => q.clinicalQuestion?.id === questionId)) {
    return state;
  }
  const index = state.questions.findIndex((q) => q.clinicalQuestion?.id === questionId);
  return {
    ...state,
    questions: [
      ...state.questions.slice(0, index),
      {
        ...state.questions[index],
        answers: newAnswers,
      },
      ...state.questions.slice(index + 1),
    ],
  };
};
