import { useFeature } from "../components/FeatureFlag";
import { GEISINGER_NAME } from "../util/healthPlanUtils";

export function useGeneralAuthSubmissionWorflowOn(healthPlanName: string) {
  /**
   * Type hack: we don't want anyone to just use bare `useFeature('generalAuthSubmissionWorkflow')
   * EXCEPT RIGHT HERE
   * so it is excluded from the allowable types to pass into useFeature
   */
  type UseFeatureParamType = Parameters<typeof useFeature>[0];
  const fullFlow = useFeature("generalAuthSubmissionWorkflowFull" as UseFeatureParamType);
  const geisingerFlow = useFeature("generalAuthSubmissionWorkflow" as UseFeatureParamType);
  return (geisingerFlow && healthPlanName === GEISINGER_NAME) || fullFlow;
}
