import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MuiFormControl, { FormControlProps } from "@material-ui/core/FormControl";
import MuiFormControlLabel from "@material-ui/core/FormControlLabel";
import MuiFormHelperText from "@material-ui/core/FormHelperText";
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from "@material-ui/core/Checkbox";
import { colorsDark } from "../../themes/colors";

const useStylesCheckbox = makeStyles((theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  return createStyles({
    root: (props: CheckboxProps) => ({
      color: isDarkTheme ? colorsDark.gray.light : colorsDark.gray.dark,
      paddingBottom: props.padding || "9px", // Apply custom padding or default to 9px,
      paddingTop: props.padding || "9px", // Apply custom padding or default to 9px,,
    }),
    disabled: {},
  });
});

const useStylesHelper = makeStyles((theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  return createStyles({
    root: {
      "&$error": {
        color: isDarkTheme ? theme.palette.error.main : theme.palette.error.dark,
      },
    },
    error: {},
  });
});

export interface CheckboxProps {
  /** Text to display with the checkbox. */
  label?: React.ReactNode;
  /** Helper text for hint or error messages. */
  helperText?: React.ReactNode;
  /** Checkbox state. */
  checked?: boolean;
  /** Function: the 'checked' state is provided as the first argument. */
  onChange: (arg0: boolean) => any;
  /**
   * Display checkbox as indeterminate.
   * This is independent of the 'checked' state, which can still be either true or false.
   */
  indeterminate?: boolean;
  muiCheckboxProps?: MuiCheckboxProps;
  /** This is to control if the element is visible within LogRocket*/
  dataPublic?: boolean;
  /**Use custom padding if user desires */
  padding?: string;
}

// Temporary export for storybook documentation
export const CheckboxForProps = (props: CheckboxProps) => {};

// omit 'css' prop to avoid Typescript error making it required
const Checkbox = React.forwardRef((props: CheckboxProps & Omit<FormControlProps, "onChange" | "css">, ref: any) => {
  const {
    checked,
    onChange,
    label,
    indeterminate,
    helperText,
    muiCheckboxProps,
    dataPublic = false,
    ...formControlProps
  } = props;
  const handleChange = (event: any, newChecked: boolean) => {
    onChange(newChecked);
  };
  const checkboxClasses = useStylesCheckbox(props);
  const helperClasses = useStylesHelper();
  const dataPublicProps = dataPublic ? { "data-public": true } : {};
  return (
    <MuiFormControl {...formControlProps}>
      <MuiFormControlLabel
        checked={checked}
        control={
          <MuiCheckbox
            inputRef={ref}
            color={"primary"}
            indeterminate={indeterminate}
            classes={checkboxClasses}
            {...dataPublicProps}
            {...muiCheckboxProps}
          />
        }
        onChange={handleChange}
        label={label}
        {...dataPublicProps}
        data-public
      />
      {helperText && <MuiFormHelperText classes={helperClasses}>{helperText}</MuiFormHelperText>}
    </MuiFormControl>
  );
});

export default Checkbox;
