import { themeLight } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core";
import { GridProps, Grid } from "@mui/material";

export const SectionContainer = (props: GridProps) => (
  <Grid container xs={12} item sx={{ paddingTop: 1, marginLeft: 3 }}>
    {props.children}
  </Grid>
);

export const OptionsContainer = (props: GridProps) => (
  <Grid container xs={12} item marginRight={8}>
    {props.children}
  </Grid>
);

export const ButtonsContainer = (props: GridProps) => (
  <Grid
    container
    item
    spacing={4}
    flexDirection="row-reverse"
    sx={{ paddingTop: "16px", paddingRight: "100px", paddingBottom: "12px", paddingLeft: "56px" }}
  >
    {props.children}
  </Grid>
);

export const useAddNewPatientFormStyles = makeStyles((theme) => ({
  headerTextContainer: {
    marginTop: theme.spacing(4),
    justifySelf: "center",
    width: "100%",
  },
  footer: {
    top: "auto",
    bottom: 0,
    height: "80px",
    backgroundColor: "white",
    position: "fixed",
    width: "100%",
    borderTop: `1px solid ${themeLight.palette.divider}`,
  },
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    overflowY: "scroll",
    overflowX: "hidden",
    paddingTop: theme.spacing(5) + 70,
    paddingBottom: theme.spacing(5) + 70,
    height: "100%",
  },
  dependentCode: {
    border: 0,
    "& fieldset": { border: "none" },
    "& label": {
      marginLeft: "-3px",
      paddingBottom: "0px",
      paddingTop: "10px",
      pointerEvents: "none",
    },
    "& input": { paddingTop: "20px" },
  },
}));
export const coveragesGridStyles = {
  paddingRight: 2,
};

export const patientInfoGridStyles = {
  xs: 4,
};
export const contactInfoGridStyles = {
  xs: 3,
};
