import { Model, Factory, Server } from "miragejs";

const models = {
  patient: Model,
};

const factories = {
  patient: Factory.extend({
    memberId(i: number) {
      return `memberId${i}`;
    },
    name(i: number) {
      return `Test Patient${i}`;
    },
    gender(i: number) {
      return i % 2 ? "F" : "M";
    },
    dateOfBirth(i: number) {
      return "1950-01-01T00:00Z";
    },
    planStart(i: number) {
      return "2020-01-01T00:00Z";
    },
    planEnd(i: number) {
      return "2020-08-01T00:00Z";
    },
    firstName(i: number) {
      return `Patient First Name:${i}`;
    },
    lastName(i: number) {
      return `Patient Last Name:${i}`;
    },
    groupNumber(i: number) {
      return `groupNumber${i}`;
    },
    insurer(i: number) {
      return `insurer${i}`;
    },
    planType(i: number) {
      return `planType${i}`;
    },
    address(i: number) {
      return {
        zipCode: "35810-4008",
        state: "AL",
        latitude: 0.0,
        longitude: 0.0,
        line1: "4395 CALDWELL ROAD",
        city: "HUNTSVILLE",
      };
    },
    phones(i: number) {
      return [
        {
          number: "8933703968",
          extension: "12",
        },
        {
          number: "0000000000",
          extension: "1",
        },
      ];
    },
  }),
};

const emptyPatient = {
  memberId: "",
  name: "",
  gender: "",
  dateOfBirth: "",
  planStart: "",
  planEnd: "",
  firstName: "",
  lastName: "",
  groupNumber: "",
  insurer: "",
  planType: "",
  address: {},
  phones: [],
};

const examplePatient = {
  memberId: "memberIdExample",
  name: "Example Patient",
  gender: "F",
  dateOfBirth: "1950-01-01T00:00Z",
  planStart: "2020-01-01T00:00Z",
  planEnd: "2020-08-01T00:00Z",
  firstName: "Example",
  lastName: "Patient",
  groupNumber: "12345",
  insurer: "Humana",
  planType: "MED",
  address: {
    zipCode: "1234-5678",
    state: "KS",
    latitude: 0.0,
    longitude: 0.0,
    line1: "4395 CALDWELL Rd",
    city: "HUNTSVILLE",
  },
  phones: [
    {
      number: "8933703968",
      extension: "12",
    },
    {
      number: "0000000000",
      extension: "1",
    },
  ],
};

const scenarios = {
  standard(server: Server) {
    // Populated entry
    server.create("patient", {
      memberId: "MEMBERID1",
      name: "Lecca Thuney",
      firstName: "LECCA",
      lastName: "THUNEY",
      gender: "F",
      dateOfBirth: "1949-07-15",
      coverage: {
        healthPlanName: "Oscar",
      },
    } as any);

    // Empty entry
    server.create("patient", emptyPatient as any);

    // Long name
    server.create("patient", {
      ...examplePatient,
      name: "Long Name Long Name Long Name Long Name Long Name Long Name Long Name Long Name",
      firstName:
        "Long Name Long Name Long Name Long Name Long Name Long Name Long Name Long",
      lastName: "NAME",
      memberId: "LongName",
      coverage: {
        healthPlanName: "Oscar",
      },
    } as any);

    // Long name2
    server.create("patient", {
      ...examplePatient,
      name: "LongWordNameLongWordNameLongWordNameLongWordNameLongWordNameLongWordNameLongWordName",
      firstName:
        "LongWordNameLongWordNameLongWordNameLongWordNameLongWordNameLongWordNameLongWord",
      lastName: "NAME",
      memberId: "LongWordName",
      coverage: {
        healthPlanName: "Oscar",
      },
    } as any);

    // excluded patient based on rule results ('actions')
    server.create("patient", {
      ...examplePatient,
      name: "Excluded Example",
      firstName: "EXCLUDED",
      lastName: "EXAMPLE",
      memberId: "ExcludedMemberId",
      ruleActions: [{ actionType: "Redirect", title: "A title", message: "" }],
      coverage: {
        excluded: true,
        exclusionDescription: "Humana Medicaid",
      },
    } as any);

    // single coverage
    server.create("patient", {
      ...examplePatient,
      languagePreference: "Spanish",
      coverages: [
        {
          sourceSystem: "LV",
          lineOfBusinessType: "Commercial",
          productType: "HMO",
          planActive: true,
          planStart: `${new Date().getFullYear()}-08-01T00:00Z`,
          planEnd: `${new Date().getFullYear() + 1}-08-01T00:00Z`,
          primaryCareProvider: {
            name: "Dr. Dre",
            npi: "123456789",
            grouperId: "918401720",
          },
        },
      ],
    } as any);

    // multiple coverages
    server.create("patient", {
      ...examplePatient,
      languagePreference: {
        written: "Spanish",
        spoken: "Spanish",
      },
      coverages: [
        {
          sourceSystem: "HP",
          lineOfBusinessType: "Medicare",
          isMedicare: true,
          productType: "HMO",
          planActive: true,
          planStart: `${new Date().getFullYear()}-08-01T00:00Z`,
          planEnd: `${new Date().getFullYear() + 1}-08-01T00:00Z`,
        },
        {
          sourceSystem: "LV",
          lineOfBusinessType: "Commercial",
          productType: "HMO",
          isCommercial: true,
          planActive: true,
          planStart: `${new Date().getFullYear()}-08-01T00:00Z`,
          planEnd: `${new Date().getFullYear() + 1}-08-01T00:00Z`,
          primaryCareProvider: {
            name: "Dr. Dre",
            npi: "123456789",
            grouperId: "918401720",
          },
        },
      ],
    } as any);

    // More patients
    server.createList("patient", 20);
  },
  empty(server: Server) {},
};

const activeScenario = "standard";
//const activeScenario = "short";
//const activeScenario = "empty";

export { models, factories, scenarios, activeScenario };
