import Grid from "@mui/material/Grid";
import LabelDataPair from "./LabelDataPair";
import { getAdditionalCareParticipantName } from "util/serviceRequest";
import { getLatestServiceRequest } from "util/authorization";
import { AuthorizationResponse, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import useResponsiveMdWidth from "hooks/useResponsiveMdWidth";
import { useRef } from "react";

interface Props {
  facilityBasedWorkflow?: boolean;
  serviceRequest: ServiceRequestResponse;
  authorization?: AuthorizationResponse;
  showEdit?: boolean;
  editing: boolean;
  isOutPatientEncounterType?: boolean;
}

export default function ReadOnlyRequestDetailPage({
  authorization,
  serviceRequest,
  facilityBasedWorkflow,
  showEdit,
  editing,
  isOutPatientEncounterType,
}: Props) {
  const noFacilityBasedRequest = authorization ? getLatestServiceRequest(authorization)?.[1] : serviceRequest;
  const serviceRequestToRender = facilityBasedWorkflow ? serviceRequest : noFacilityBasedRequest;
  const elementRef = useRef<HTMLDivElement>(null);
  const maxWidthForResponsiveMdValue = 960;
  const { responsiveMdWidth } = useResponsiveMdWidth(elementRef, maxWidthForResponsiveMdValue);
  return (
    <Grid
      container
      spacing={2}
      style={{ paddingRight: (facilityBasedWorkflow || isOutPatientEncounterType) && showEdit ? "70px" : 0 }}
      ref={elementRef}
    >
      {!editing && (
        <Grid item style={{ paddingBottom: "8px", paddingTop: "16px" }} xs={12} md={6} lg={responsiveMdWidth ? 4 : 6}>
          <LabelDataPair
            label="Primary diagnosis"
            data={`${serviceRequestToRender?.primarySemanticDiagnosisCode?.code}
            ${
              serviceRequestToRender?.primarySemanticDiagnosisCode?.description
                ? " - " + serviceRequestToRender?.primarySemanticDiagnosisCode?.description
                : ""
            }`}
          />
        </Grid>
      )}
      {!editing && (
        <Grid item style={{ paddingBottom: "8px", paddingTop: "16px" }} xs={12} md={6} lg={responsiveMdWidth ? 4 : 6}>
          <LabelDataPair
            label="Secondary diagnosis"
            data={
              serviceRequestToRender?.secondarySemanticDiagnosisCodes
                ?.map((dx) => {
                  return `${dx.code} ${dx.description ? " - " + dx.description : ""}`;
                })
                .join(", ") || "--"
            }
          />
        </Grid>
      )}
      <Grid item style={{ paddingBottom: "8px", paddingTop: "16px" }} xs={12} md={6} lg={responsiveMdWidth ? 4 : 6}>
        <LabelDataPair label="Place of service" data={serviceRequestToRender?.placeOfService?.name || ""} />
      </Grid>
      <Grid item style={{ paddingBottom: "8px", paddingTop: "16px" }} xs={12} md={6} lg={responsiveMdWidth ? 4 : 6}>
        <LabelDataPair
          label="Performing or attending provider"
          data={
            serviceRequestToRender?.performingProvider?.npi
              ? `${serviceRequestToRender?.performingProvider?.name} / ${
                  serviceRequestToRender?.performingProvider?.npi
                    ? `NPI - ` + serviceRequestToRender.performingProvider?.npi
                    : ``
                }`
              : "--"
          }
        />
      </Grid>
      <Grid item style={{ paddingBottom: "8px", paddingTop: "16px" }} xs={12} md={6} lg={responsiveMdWidth ? 4 : 6}>
        {serviceRequestToRender?.orderingProvider && (
          <LabelDataPair
            label="Ordering provider"
            data={
              serviceRequestToRender.orderingProvider?.npi
                ? `${serviceRequestToRender.orderingProvider?.name} / ${
                    serviceRequestToRender.orderingProvider?.npi
                      ? `NPI - ` + serviceRequestToRender.orderingProvider?.npi
                      : ``
                  }`
                : "--"
            }
          />
        )}
      </Grid>
      <Grid item style={{ paddingBottom: "8px", paddingTop: "16px" }} xs={12} md={6} lg={responsiveMdWidth ? 4 : 6}>
        <LabelDataPair
          label="Performing facility or agency"
          data={
            serviceRequestToRender?.facility?.npi
              ? `${serviceRequestToRender?.facility?.name} / ${
                  serviceRequestToRender?.facility?.npi ? `NPI - ` + serviceRequestToRender.facility?.npi : ``
                }`
              : "--"
          }
        />
      </Grid>
      {serviceRequestToRender?.additionalCareParticipants &&
        serviceRequestToRender?.additionalCareParticipants?.length > 0 &&
        serviceRequestToRender?.additionalCareParticipants.map((careParticipant) => {
          return (
            <Grid
              item
              style={{ paddingBottom: "8px", paddingTop: "16px" }}
              xs={12}
              md={6}
              lg={responsiveMdWidth ? 4 : 6}
            >
              <LabelDataPair
                label={getAdditionalCareParticipantName(careParticipant?.careParticipantType)}
                data={
                  careParticipant
                    ? `${careParticipant.name} / ${careParticipant?.npi ? `NPI - ` + careParticipant?.npi : ``}`
                    : "--"
                }
              />
            </Grid>
          );
        })}
      <Grid item style={{ paddingBottom: "8px", paddingTop: "16px" }} xs={12} md={6} lg={responsiveMdWidth ? 4 : 6}>
        <LabelDataPair
          label="Care setting"
          data={serviceRequestToRender?.encounterType === "INPATIENT" ? "Inpatient" : "Outpatient"}
        />
      </Grid>
      {facilityBasedWorkflow && serviceRequestToRender?.authCategory?.name && (
        <Grid item style={{ paddingBottom: "8px", paddingTop: "16px" }} xs={12} md={6} lg={responsiveMdWidth ? 4 : 6}>
          <LabelDataPair
            label="Auth category"
            data={
              serviceRequestToRender.authSubcategory
                ? `${serviceRequestToRender.authSubcategory}`
                : `${serviceRequestToRender.authCategory.name}`
            }
          />
        </Grid>
      )}
    </Grid>
  );
}
