import React, { useState, useEffect, useCallback, useRef, useContext, useMemo, KeyboardEvent } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useTheme } from "@material-ui/core/styles";
import DoneIcon from "@material-ui/icons/Done";
import SearchIcon from "@material-ui/icons/Search";
import MUIAutocomplete, { AutocompleteProps } from "@material-ui/lab/Autocomplete";

import { colorsLight } from "../../themes";
import {
  useStylesAutocomplete,
  defaultFilterOptions,
  defaultGetOptionLabel,
  defaultGetOptionSelected,
  useStylesLookupEndAdornment,
} from "./commonAutocomplete";
import { Chip } from "../Chip";
import { TextField, TextFieldProps } from "../TextField";
import { AutoFillIcon } from "../images";
import { AutocompleteChangeSubscriberContext } from "./autocompleteContext";
import LazyLoadingOptions, { LOAD_MORE_BATCH_SIZE, useLoadMoreItems } from "./LazyLoadingOptions";
import { SelectOptionsHook } from "./SelectOptionsHook";
import Fade from "@material-ui/core/Fade";

type OnChangeParams<T> = Parameters<Required<AutocompleteProps<T, true, false, false>>["onChange"]>;
export interface MultipleSelectProps<T> {
  selectedValue: T[];
  setSelectedValue: (option: OnChangeParams<T>[1]) => void;
  useOptions: SelectOptionsHook<T>;
  omitSelectionChips?: boolean;
  reactLabel?: React.ReactNode;
  emptyLabel?: React.ReactNode;
  helperText?: React.ReactNode;
  /** Optional way to determine whether a given option is selected (likely comparing IDs). */
  customGetOptionSelected?: (option: T, value: T) => boolean;
  /** @Deprecated This is only supported in combination with the 'omitSelectionChips' option. */
  withIcon?: boolean;
  /** Maximum number of options a user can select. Defaults to 1000 */
  maxSelections?: number;
  /** Preserves the query and options after making a selection and/or after blur (for ease of usability) */
  disableClearOnSelect?: boolean;
  error?: boolean;
  autoFilledIcon?: boolean;
  TextFieldProps?: Partial<TextFieldProps>;
  /** If this is true then we will render a checkmark in the options list next to the selected option
   * Note: This behavior is buggy as currently implemented. If we use a rest-hook to dynamically fetch new options based
   *       on a query (which is true in most use-cases) then the selected option comparison doesn't really work and we
   *       don't mark selected options. TODO We should create a variant that properly marks selections (and dedupes for multi-select)
   */
  markSelectedOptions?: boolean;
  /**
   * Will focus on the text entry field on mount
   */
  autoFocus?: boolean;
  isAllSelected?: boolean;
  isDefaultAll?: boolean;
  /**
   * Only valid for string values. Enter causes onChange with current query appended
   */
  selectOnEnter?: boolean;
  isLazyLoaded?: boolean;
}
interface IProps<T>
  extends Omit<AutocompleteProps<T, true, false, false>, "value" | "onChange" | "renderInput" | "options">,
    MultipleSelectProps<T> {}

// Temporary export for storybook documentation
export function MultipleSelectForProps<T>(props: MultipleSelectProps<T>) {}

export type MultipleSelectAllProps<T> = IProps<T> & React.HTMLProps<HTMLDivElement>;

export function MultipleSelect<T>({
  selectedValue,
  setSelectedValue,
  useOptions,
  omitSelectionChips,
  reactLabel,
  label,
  emptyLabel,
  helperText,
  disableClearOnSelect,
  error,
  customGetOptionSelected,
  // MuiAutocomplete doesn't pass through `placeholder` as advertised:
  // https://github.com/mui-org/material-ui/issues/21304
  placeholder,
  // props we will process manually
  className,
  renderOption,
  renderTags,
  autoFilledIcon,
  maxSelections = 1000,
  TextFieldProps,
  markSelectedOptions = true,
  withIcon,
  autoFocus,
  isAllSelected,
  isDefaultAll,
  selectOnEnter,
  isLazyLoaded = true,
  ...props
}: MultipleSelectAllProps<T>) {
  const [isOpen, setIsOpen] = React.useState(false);

  // Autocomplete query is the search text the user has typed
  const [query, setQuery] = useState("");
  const handleInputChange = (event: any, newVal: any, description: string) => {
    if (query !== newVal && event) {
      // Optionally override Material's decision to clear search text after selection
      if (disableClearOnSelect && description === "reset") {
        return;
      } else {
        setQuery(newVal);
      }
    }
  };

  // Keep latest props in a ref.
  const latestProps = useRef(props);
  useEffect(() => {
    latestProps.current = props;
  });
  // make a stable onError function to avoid unnecessary options fetches
  const onError = useCallback((error) => {
    latestProps.current.onError?.(error);
    setIsOpen(false);
  }, []);

  const {
    options = [],
    optionsLoading,
    filterOptions = defaultFilterOptions,
    refetch: refetchOptions,
    hasMoreOptions,
  } = useOptions({
    query,
    offset: 0,
    max: LOAD_MORE_BATCH_SIZE,
    onError,
  });

  const { withoutLabel, withLabelAndSelections, validationErrorColors, ...autocompleteClasses } = useStylesAutocomplete(
    {
      markSelectedOptions,
      isAllSelected,
      isDefaultAll,
      isLazyLoaded,
    }
  );
  const extraClasses = [];
  if (!label) {
    extraClasses.push(`${withoutLabel}`);
  }
  if (label && selectedValue?.length && !omitSelectionChips) {
    extraClasses.push(`${withLabelAndSelections}`);
  }

  const { onChange: onChangeFromContext } = useContext(AutocompleteChangeSubscriberContext);

  const loadMoreItems = useLoadMoreItems(query, refetchOptions);

  const { spacing } = useTheme();
  const [focused, setFocused] = useState(TextFieldProps?.focused || false);

  // icon "order of preference" is loading -> model training -> search
  const showLoadingIcon = optionsLoading && isOpen;
  const showModelTrainingIcon = !showLoadingIcon && autoFilledIcon;
  const showSearchIcon = !showLoadingIcon && !showModelTrainingIcon;

  const endAdornmentClasses = useStylesLookupEndAdornment();
  const endAdornment = useMemo(
    () => (
      <>
        <Fade in={showLoadingIcon} style={{ transitionDelay: showLoadingIcon ? "75ms" : "0ms" }}>
          <CircularProgress className={endAdornmentClasses.loadingSpinner} color="inherit" size={20} />
        </Fade>
        <Fade in={showModelTrainingIcon} style={{ transitionDelay: showModelTrainingIcon ? "75ms" : "0ms" }}>
          <span className={endAdornmentClasses.autoFilledIcon}>
            <AutoFillIcon />
          </span>
        </Fade>
        <Fade in={showSearchIcon} style={{ transitionDelay: showSearchIcon ? "75ms" : "0ms" }}>
          <SearchIcon className={endAdornmentClasses.normalIcon} />
        </Fade>
      </>
    ),
    [
      endAdornmentClasses.autoFilledIcon,
      endAdornmentClasses.loadingSpinner,
      endAdornmentClasses.normalIcon,
      showLoadingIcon,
      showModelTrainingIcon,
      showSearchIcon,
    ]
  );
  const textFieldLabel = !selectedValue.length && !focused && emptyLabel ? emptyLabel : reactLabel ? reactLabel : label;
  const internalTextFieldProps: Partial<TextFieldProps> = {};

  if (selectOnEnter) {
    const onKeyPress = (evt: KeyboardEvent<HTMLInputElement>) => {
      if (evt.key === "Enter" && query) {
        setSelectedValue(selectedValue.concat(query as unknown as T));
        setQuery("");
        setIsOpen(false);
      }
    };
    internalTextFieldProps.onKeyPress = onKeyPress;
  }

  return (
    <MUIAutocomplete
      multiple
      className={`${extraClasses.join(" ")} ${className}`}
      classes={autocompleteClasses}
      disableClearable
      forcePopupIcon={false}
      open={isOpen && !(optionsLoading && !options.length)}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      options={options}
      filterOptions={filterOptions}
      getOptionSelected={customGetOptionSelected || defaultGetOptionSelected}
      getOptionLabel={defaultGetOptionLabel}
      ListboxComponent={
        isLazyLoaded
          ? (LazyLoadingOptions as unknown as React.ComponentType<React.HTMLAttributes<HTMLElement>>)
          : undefined
      }
      ListboxProps={{
        numOptions: options.length,
        loadMoreItems,
        isNextPageLoading: optionsLoading,
        hasNextPage: Boolean(hasMoreOptions),
        markSelectedOptions,
        query,
      }}
      renderTags={(value, getTagProps) =>
        renderTags
          ? renderTags(value, getTagProps)
          : omitSelectionChips
          ? null
          : value.map((option, index) => (
              <Chip
                size="small"
                label={(props.getOptionLabel || defaultGetOptionLabel)(option)}
                {...getTagProps({ index })}
              />
            ))
      }
      renderInput={(params) => (
        <TextField
          autoFocus={autoFocus}
          {...params}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          label={textFieldLabel}
          placeholder={placeholder}
          helperText={helperText}
          error={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end" className={endAdornmentClasses.endAdornment}>
                {endAdornment}
              </InputAdornment>
            ),
          }}
          {...internalTextFieldProps}
          {...TextFieldProps}
        />
      )}
      renderOption={(option, state) => (
        <>
          {markSelectedOptions && state.selected && (
            <DoneIcon style={{ margin: spacing("auto", 1, "auto", 0), color: colorsLight.primary.main }} />
          )}
          {(renderOption && renderOption(option, state)) || (props.getOptionLabel || defaultGetOptionLabel)(option)}
        </>
      )}
      {
        ...props
        /* value and onChange must be passed after passthrough props in order to satisfy the type inference gods */
      }
      inputValue={query}
      onInputChange={handleInputChange}
      value={selectedValue}
      onChange={(...[event, value, reason]: OnChangeParams<T>) => {
        // Prevent backspace from removing selections when chips are disabled
        if (omitSelectionChips && event.type === "keydown" && reason === "remove-option") {
          return;
        }
        if (selectedValue.length >= maxSelections && reason === "select-option") {
          return;
        }
        setSelectedValue(value);
        onChangeFromContext({
          fieldName: label || placeholder || props.name || props.id || "unknown",
          selectedValue: value,
        });
      }}
    />
  );
}
