import {
  FacilityBasedGeneralAuthSubmissionForm,
  FormConfiguration,
} from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import { Dispatch, SetStateAction, useEffect } from "react";
import { OutOfNetworkCheckConfiguration, Patient } from "@coherehealth/core-platform-api";
import {
  AdditionalCareParticipantFormContent,
  ServiceRequestFormContent,
} from "common/SharedServiceRequestFormComponents";
import { Grid } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import { PriorAuthRequirements, useCareParticipantOutOfNetworkCheck } from "components/AuthBuilder/common";
import OONCareParticipantStatusDisplay from "./OONCareParticipantStatusDisplay";
import NetworkCheckStatusDisplay from "./NetworkCheckStatusDisplay";
import {
  HUMANA_OON_FACILITY_EXCEPTION_REASONS,
  OonExceptionReasonOptions,
  getAdditionalCareParticipantName,
  getFieldSpecForAdditionalCareParticipant,
  isOonProviderOrFacility,
  isUnknownNetworkType,
} from "util/serviceRequest";
import { Checkbox } from "@coherehealth/common";
import { Body1 } from "@coherehealth/design-system";
import OonExceptionReason from "./OonExceptionReason";
interface Props {
  formFieldConfigurations: FormConfiguration;
  outOfNetworkCheckConfiguration: OutOfNetworkCheckConfiguration;
  formContent: ServiceRequestFormContent;
  careParticipant: AdditionalCareParticipantFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  patient?: Patient;
  workflowId?: string;
  delegatedVendorName?: string;
  rowIndex: number;
  canSkipOon: boolean;
  healthPlanName: string;
  setCareParticipantExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  careParticipantExceptionRequest?: boolean;
  setPriorAuthRequirements?: Dispatch<SetStateAction<PriorAuthRequirements>>;
  attemptedSubmit: boolean;
  VALIDATION_MAP: Record<FacilityBasedGeneralAuthSubmissionForm, boolean>;
  hideTinField?: boolean;
  setAdditionalCareOonCheckLoading?: Dispatch<SetStateAction<boolean>>;
}

export function CareParticipantsOonCheck({
  formFieldConfigurations,
  outOfNetworkCheckConfiguration,
  formContent,
  careParticipant,
  setFormContent,
  patient,
  workflowId,
  delegatedVendorName,
  rowIndex,
  canSkipOon,
  healthPlanName,
  careParticipantExceptionRequest,
  setCareParticipantExceptionRequest,
  setPriorAuthRequirements,
  attemptedSubmit,
  VALIDATION_MAP,
  hideTinField,
  setAdditionalCareOonCheckLoading,
}: Props) {
  const { careParticipantOonCheckData, careParticipantOonCheckLoading, performedCareParticipantOonCheck } =
    useCareParticipantOutOfNetworkCheck({
      formFieldConfigurations,
      outOfNetworkCheckConfiguration,
      content: formContent,
      careParticipant,
      setFormContent,
      patient: patient || undefined,
      workflowId,
      delegatedVendorName,
      rowIndex,
      hideTinField,
    });
  const isExceptionRequiredCheck = careParticipantOonCheckData?.isExceptionRequired || careParticipantOonCheckLoading;
  const showExceptionReasonBoxForCareParticipant =
    !careParticipantOonCheckLoading &&
    (isOonProviderOrFacility(careParticipant.selectedLocation) ||
      isUnknownNetworkType(careParticipant.selectedLocation?.networkType));
  const isCareParticipantOONCommentRequired =
    careParticipant?.selectedLocation?.isOutOfNetwork &&
    careParticipant?.selectedLocation?.outOfNetworkExceptionReason === "Other (comment is required)";
  const { spacing } = useTheme();

  //syncronize with footer
  useEffect(() => {
    if (!!setAdditionalCareOonCheckLoading) {
      setAdditionalCareOonCheckLoading(careParticipantOonCheckLoading);
    }
  }, [careParticipantOonCheckLoading, setAdditionalCareOonCheckLoading]);
  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item container xs={12} spacing={2}>
        {!careParticipant?.selectedLocation?.networkType ? (
          <OONCareParticipantStatusDisplay
            isCareParticipantOON={
              careParticipant?.selectedLocation?.isOutOfNetwork ||
              careParticipant.selectedAddress?.isOutOfNetwork ||
              false
            }
            // TODO: Probably want to make this come from a fieldConfig (see feature config named serviceRequestFormFieldConfiguration)
            oonExceptionConfig={
              careParticipantOonCheckData?.isExceptionRequired && !canSkipOon ? "REQUIRED" : "OPTIONAL"
            }
            oonLoading={careParticipantOonCheckLoading}
            inNetwork={
              performedCareParticipantOonCheck &&
              (!careParticipant?.selectedAddress?.isOutOfNetwork || !careParticipant?.selectedLocation?.isOutOfNetwork)
            }
            careParticipantType={getAdditionalCareParticipantName(careParticipant?.careParticipantType)}
          />
        ) : (
          // TODO: Refactor this
          <NetworkCheckStatusDisplay
            networkType={careParticipant?.selectedLocation?.networkType || undefined}
            oonLoading={careParticipantOonCheckLoading}
            entityType={"careParticipant"}
            careParticipantType={getAdditionalCareParticipantName(careParticipant?.careParticipantType)}
            oonExceptionConfig={
              careParticipantOonCheckData?.isExceptionRequired && !canSkipOon ? "REQUIRED" : "OPTIONAL"
            }
          />
        )}
      </Grid>
      {!isExceptionRequiredCheck &&
        (isOonProviderOrFacility(careParticipant.selectedLocation) ||
          isUnknownNetworkType(careParticipant.selectedLocation?.networkType)) && (
          <Grid item xs={12}>
            <Checkbox
              label={<Body1 color={"text.secondary"}>Ask for a network exception (request will pend for review)</Body1>}
              checked={careParticipantExceptionRequest}
              onChange={async (userSelectedOONExceptionValue) => {
                setPriorAuthRequirements?.((prev) => {
                  return { ...prev, userSelectedOutOfNetworkException: userSelectedOONExceptionValue };
                });
                if (!userSelectedOONExceptionValue) {
                  setFormContent({
                    ...formContent,
                    additionalCareParticipants: formContent.additionalCareParticipants?.map((careParticipant, i) =>
                      i === rowIndex
                        ? {
                            ...careParticipant,
                            selectedLocation: {
                              ...formContent.additionalCareParticipants?.at(rowIndex)?.selectedLocation,
                              outOfNetworkExceptionReason: "",
                            },
                            type: formContent.additionalCareParticipants?.at(rowIndex)?.type,
                          }
                        : careParticipant
                    ),
                  });
                }
                setCareParticipantExceptionRequest?.(userSelectedOONExceptionValue);
              }}
              style={{ marginTop: spacing(-0.5), marginBottom: spacing(-2) }}
            />
          </Grid>
        )}
      {(!isExceptionRequiredCheck && !careParticipantExceptionRequest) ||
        (showExceptionReasonBoxForCareParticipant &&
          (getFieldSpecForAdditionalCareParticipant(careParticipant, "outOfNetworkExceptionReason") === "REQUIRED" ||
            getFieldSpecForAdditionalCareParticipant(careParticipant, "outOfNetworkExceptionReason") ===
              "OPTIONAL") && (
            <Grid item container xs={12} spacing={2}>
              <OonExceptionReason
                exceptionPracticeCommon={getAdditionalCareParticipantName(careParticipant.careParticipantType)}
                exceptionReasons={OonExceptionReasonOptions(HUMANA_OON_FACILITY_EXCEPTION_REASONS)}
                isExceptionRequired={(careParticipantOonCheckData?.isExceptionRequired && !canSkipOon) || false}
                isCommentRequired={isCareParticipantOONCommentRequired}
                data-tracking-id="careParticipant-exception-reason"
                error={attemptedSubmit && !VALIDATION_MAP["careParticipantOutOfNetworkExceptionComment"]}
                commentError={attemptedSubmit && !VALIDATION_MAP["careParticipantOutOfNetworkExceptionComment"]}
                dropDownValue={careParticipant.selectedLocation?.outOfNetworkExceptionReason || ""}
                commentValue={careParticipant.selectedLocation?.outOfNetworkExceptionComment || ""}
                dropDownOnChange={(reason) => {
                  return setFormContent({
                    ...formContent,
                    additionalCareParticipants: formContent.additionalCareParticipants?.map((careParticipant, i) =>
                      i === rowIndex
                        ? {
                            ...careParticipant,
                            selectedLocation: {
                              ...formContent.additionalCareParticipants?.at(rowIndex)?.selectedLocation,
                              outOfNetworkExceptionReason: reason,
                            },
                            type: formContent.additionalCareParticipants?.at(rowIndex)?.type,
                          }
                        : careParticipant
                    ),
                  });
                }}
                commentOnChange={(comment) => {
                  return setFormContent({
                    ...formContent,
                    additionalCareParticipants: formContent.additionalCareParticipants?.map((careParticipant, i) =>
                      i === rowIndex
                        ? {
                            ...careParticipant,
                            selectedLocation: {
                              ...formContent.additionalCareParticipants?.at(rowIndex)?.selectedLocation,
                              outOfNetworkExceptionComment: comment,
                            },
                            type: formContent.additionalCareParticipants?.at(rowIndex)?.type,
                          }
                        : careParticipant
                    ),
                  });
                }}
                generalAuthStyling={true}
              />
            </Grid>
          ))}
    </Grid>
  );
}

export default CareParticipantsOonCheck;
