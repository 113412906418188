import HeaderAndProgressBar from "../HeaderAndProgressBar";
import { H1, H6 } from "@coherehealth/common";
import CircularProgress from "@material-ui/core/CircularProgress";
import FullScreenDisplay, { FullScreenDisplayProps } from "./FullScreenDisplay";

export default function OnboardingVerificationLoading() {
  const fullScreenDisplayProps: FullScreenDisplayProps = {
    headerAndProgressBar: <HeaderAndProgressBar stepNumber={3} />,
    visualComponent: <CircularProgress size={128} />,
    header: <H1>Verifying your access</H1>,
    subtext: <H6>This should only take a few seconds. Hang tight...</H6>,
  };

  return <FullScreenDisplay {...fullScreenDisplayProps} />;
}
