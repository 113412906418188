import React from "react";
import { H4, H1, FourOFour } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Grid } from "@material-ui/core";
import CohereLogo from "../images/CohereLogo.svg";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    fontSize: 50,
    lineHeight: "80px",
  },
  centeredContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
}));

export default function SmartOnFhirNotFoundPage() {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" justifyContent="center">
      <div style={{ marginTop: 12, marginBottom: 12 }}>
        <img src={CohereLogo} alt="Company Logo" />
      </div>
      <div style={{ width: "100%" }}>
        <Divider />
      </div>
      <div className={classes.centeredContainer} style={{ marginTop: 60 }}>
        <FourOFour />
      </div>
      <div className={classes.centeredContainer} style={{ marginTop: 50, textAlign: "center" }}>
        <H1 className={classes.title}>Sorry, this page is missing.</H1>
      </div>
      <div className={classes.centeredContainer} style={{ marginTop: 12, textAlign: "center" }}>
        <H4 color="textSecondary">Looks like we sent you down the wrong path.</H4>
      </div>
    </Grid>
  );
}
