import React, { useContext, useEffect } from "react";
import { colorsLight, H2, Modal, PrimaryButton, SingleSelectDropdown, formatDateToISODate } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core";
import ServiceRequestMoreInfoIcon from "common/ServiceRequestMoreInfoIcon";
import { OscarServiceType, ServiceDeterminationQuestionOption } from "@coherehealth/core-platform-api";
import { QuestionAnswers, QuestionOptions } from "./Footer";
import { TrackUserActivityProps, useTrackUserInteraction } from "util/userActivityTracker";
import { PriorAuthRequirements } from "../common";
import { FaxAttachmentContext } from "components/DocumentViewer/FaxAttachment/FaxAttachmentContext";

interface Props {
  open: boolean;
  onClose: () => void;
  patientId: string;
  priorAuthRequirements: PriorAuthRequirements;
  questionOptions: QuestionOptions | undefined;
  setQuestionOptions: (options: QuestionOptions | undefined) => void;
  questionAnswers: QuestionAnswers | undefined;
  setQuestionAnswers: (answers: QuestionAnswers | undefined) => void;
  defaultPlaceOfServiceId?: string;
  clearServiceDeterminationResults: () => void;
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  getServiceDeterminationResults: Function;
  loading: boolean;
  canContinue: boolean;
  onContinue: () => void;
  workflowId?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3, 0, 4, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  icon: {
    height: theme.spacing(16),
    width: theme.spacing(16),
    color: colorsLight.warning.dark,
    marginBottom: theme.spacing(4),
  },
  headerText: {
    paddingBottom: theme.spacing(2),
    textAlign: "center",
  },
  primaryButton: {
    width: 272,
    marginTop: theme.spacing(7),
  },
  singleSelect: {
    width: 496,
    marginTop: theme.spacing(2),
    "& .MuiFormHelperText-root": {
      color: theme.palette.success.main,
    },
  },
  loading: {
    marginTop: theme.spacing(2),
  },
  thickFont: {
    fontWeight: 600,
  },
  normalFont: {
    fontWeight: 500,
  },
  greenText: {
    fontWeight: "normal",
    color: theme.palette.success.dark,
    paddingLeft: theme.spacing(1),
  },
}));

export default function AuthBuilderQuestionModal({
  open,
  onClose,
  patientId,
  priorAuthRequirements,
  questionOptions,
  setQuestionOptions,
  questionAnswers,
  setQuestionAnswers,
  defaultPlaceOfServiceId,
  clearServiceDeterminationResults,
  getServiceDeterminationResults,
  loading,
  canContinue,
  onContinue,
  workflowId,
}: Props) {
  const classes = useStyles();

  const hasCarePathOptions = questionOptions?.carePathOptions && questionOptions?.carePathOptions.length > 0;
  const hasCategoryOptions = questionOptions?.categoryOptions && questionOptions?.categoryOptions.length > 0;
  const hasServiceOptions = questionOptions?.serviceOptions && questionOptions?.serviceOptions.length > 0;
  const hasServiceTypeOptions = questionOptions?.serviceTypeOptions && questionOptions?.serviceTypeOptions.length > 0;

  const noneOfTheseServiceOption: ServiceDeterminationQuestionOption = { id: "", name: "None of these" };
  const serviceOptionsWithNoneOfTheseOption: ServiceDeterminationQuestionOption[] = [
    ...(questionOptions?.serviceOptions || []),
    noneOfTheseServiceOption,
  ];
  const isSingleServiceQuestion =
    questionOptions?.serviceOptions && questionOptions.serviceOptions.find((option) => option.isSingleService);

  const displayNoneOfTheseOption = !isSingleServiceQuestion && hasServiceOptions;

  const closeModal = () => {
    onClose();
    clearServiceDeterminationResults();
  };

  useEffect(() => {
    if (open) {
      getServiceDeterminationResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionAnswers, open]);

  const trackInteraction = useTrackUserInteraction();
  const noneOfTheseOptionDisplayed: TrackUserActivityProps = {
    event: "AUTH_BUILDER_OTHER_SERVICE_DISPLAYED",
    stage: "AUTH_CREATION",
    activityContext: {
      patientId: patientId,
      workflowId: workflowId,
    },
    beforeSnapshot: {
      primaryDiagnosisCode: priorAuthRequirements?.primaryDiagnosis?.code,
      secondaryDiagnosisCodes: priorAuthRequirements?.secondaryDiagnoses?.map((dx) => dx.code),
      startDate: formatDateToISODate(priorAuthRequirements?.startDate),
      encounterType: priorAuthRequirements?.encounterType,
      procedureCodes: priorAuthRequirements?.desiredProcedureCodes?.map((px) => px.code),
    },
  };
  const noneOfTheseOptionSelected: TrackUserActivityProps = {
    event: "AUTH_BUILDER_OTHER_SERVICE_SELECTED",
    stage: "AUTH_CREATION",
    activityContext: {
      patientId: patientId,
      workflowId: workflowId,
    },
    beforeSnapshot: {
      primaryDiagnosisCode: priorAuthRequirements?.primaryDiagnosis?.code,
      secondaryDiagnosisCodes: priorAuthRequirements?.secondaryDiagnoses?.map((dx) => dx.code),
      startDate: formatDateToISODate(priorAuthRequirements?.startDate),
      encounterType: priorAuthRequirements?.encounterType,
      procedureCodes: priorAuthRequirements?.desiredProcedureCodes?.map((px) => px.code),
    },
  };

  const { faxDbId: defaultFaxId } = useContext(FaxAttachmentContext);

  return (
    <Modal open={open} onClose={closeModal}>
      <div className={classes.container}>
        <ServiceRequestMoreInfoIcon className={classes.icon} />
        <H2 className={classes.headerText}>Looks like we need a bit more info</H2>
        {hasCarePathOptions && (
          <SingleSelectDropdown
            className={classes.singleSelect}
            label="Which of these best describes the patient’s diagnosis?"
            value={questionAnswers?.carePathAnswer || ""}
            options={dropdownOptionsFromQuestionOptions(questionOptions?.carePathOptions)}
            onChange={(answer) => {
              clearServiceDeterminationResults();
              setQuestionOptions({
                ...questionOptions,
                categoryOptions: undefined,
                serviceOptions: undefined,
                serviceTypeOptions: undefined,
                placeOfServiceOptions: undefined,
              });
              setQuestionAnswers({
                carePathAnswer: answer,
                categoryAnswer: undefined,
                serviceAnswer: undefined,
                serviceTypeAnswer: undefined,
                placeOfServiceAnswer: undefined,
              });
            }}
            menuWidth={494}
            maxMenuHeight={400}
          />
        )}
        {hasCategoryOptions && (
          <SingleSelectDropdown
            className={classes.singleSelect}
            label="What stage of care is the patient currently in?"
            data-testid="patient-care-dropdown"
            value={questionAnswers?.categoryAnswer || ""}
            options={dropdownOptionsFromQuestionOptions(questionOptions?.categoryOptions)}
            onChange={(answer) => {
              clearServiceDeterminationResults();
              setQuestionOptions({
                ...questionOptions,
                serviceOptions: undefined,
                serviceTypeOptions: undefined,
                placeOfServiceOptions: undefined,
              });
              setQuestionAnswers({
                ...questionAnswers,
                categoryAnswer: answer,
                serviceAnswer: undefined,
                serviceTypeAnswer: undefined,
                placeOfServiceAnswer: undefined,
              });
            }}
            menuWidth={494}
            maxMenuHeight={400}
          />
        )}
        {hasServiceOptions && (
          <SingleSelectDropdown
            className={classes.singleSelect}
            label="What best describes the procedure?"
            data-testid="procedure-description-dropdown"
            value={questionAnswers?.serviceAnswer || ""}
            options={dropdownOptionsFromQuestionOptions(
              displayNoneOfTheseOption ? serviceOptionsWithNoneOfTheseOption : questionOptions?.serviceOptions
            )}
            onChange={(answer) => {
              clearServiceDeterminationResults();
              setQuestionOptions({
                ...questionOptions,
                serviceTypeOptions: undefined,
                placeOfServiceOptions: undefined,
              });
              setQuestionAnswers({
                ...questionAnswers,
                serviceAnswer: answer,
                serviceTypeAnswer: undefined,
                placeOfServiceAnswer: undefined,
              });
              if (displayNoneOfTheseOption) {
                trackInteraction(noneOfTheseOptionDisplayed);
              }
              if (answer === "None of these") {
                trackInteraction(noneOfTheseOptionSelected);
              }
            }}
            menuWidth={494}
            maxMenuHeight={400}
          />
        )}
        {hasServiceTypeOptions && (
          <SingleSelectDropdown
            className={classes.singleSelect}
            label="Which service type is being requested?"
            data-testid="service-type-dropdown"
            value={questionAnswers?.serviceTypeAnswer || ""}
            options={dropdownOptionsFromServiceTypeOptions(questionOptions?.serviceTypeOptions)}
            onChange={(answer) => {
              clearServiceDeterminationResults();
              setQuestionOptions({
                ...questionOptions,
                placeOfServiceOptions: undefined,
              });
              setQuestionAnswers({
                ...questionAnswers,
                serviceTypeAnswer: answer,
                placeOfServiceAnswer: undefined,
              });
            }}
            menuWidth={494}
            maxMenuHeight={400}
          />
        )}
        <PrimaryButton
          disabled={!defaultFaxId && (!canContinue || loading)}
          loading={loading}
          className={classes.primaryButton}
          onClick={onContinue}
        >
          Continue to next step
        </PrimaryButton>
      </div>
    </Modal>
  );
}

function dropdownOptionsFromQuestionOptions(options?: ServiceDeterminationQuestionOption[]) {
  return options?.map((option) => ({ id: option.id || option.name || "", label: option.name || "" })) || [];
}

function dropdownOptionsFromServiceTypeOptions(options?: OscarServiceType[]) {
  return options?.map((option) => ({ id: option, label: OSCAR_LABELS[option] }));
}

// todo find a more "common" place for this, it's also in the admin package.
// I don't want to put it in the @coherehealth/common b/c it has an import from core-platform-api
//
const OSCAR_LABELS: Record<OscarServiceType, string> = {
  ACUTE_ADMISSION: "Acute admission",
  APPLIED_BEHAVIOR_ANALYSIS: "Applied behavior analysis",
  DETOX_ADMISSION: "Detox admission",
  DIRECT_HOSPITAL_ADMISSION: "Direct hospital admission",
  DURABLE_MEDICAL_EQUIPMENT: "Durable medical equipment",
  ELECTIVE_SURGICAL_AND_NON_SURGICAL_PROCEDURES: "Elective surgical & non-surgical procedures",
  ELECTROCONVULSIVE_THERAPY: "Electroconvulsive therapy",
  EMERGENCY_ADMISSION: "Emergency admission",
  HOME_HEALTH_CARE: "Home health care",
  IMAGING_SERVICES: "Imaging services",
  IN_HOME_BEHAVIORAL_HEALTH_SERVICE: "In-home behavioral health service",
  INTENSIVE_CARE_COORDINATION: "Intensive care coordination",
  INTENSIVE_OUTPATIENT: "Intensive outpatient",
  LABORATORY_WORK: "Laboratory work",
  MEDICARE_PART_B_DETERMINED_DRUGS: "Medicare Part B determined drugs",
  NEURO_OR_PSYCHOLOGICAL_TESTING: "Neuro or psychological testing",
  OUTPATIENT_REHABILITATION_THERAPIES: "Outpatient rehabilitation therapies",
  PARTIAL_HOSPITALIZATION_PROGRAM: "Partial hospitalization program",
  PHYSICIAN_ADMINISTERED_SPECIALTY_DRUGS: "Physician-administered specialty drugs",
  POST_ACUTE_INPATIENT_ADMISSION: "Post-acute inpatient admission",
  RESIDENTIAL_TREATMENT_CENTER: "Residential treatment center",
  TRANSCRANIAL_MAGNETIC_SIMULATION: "Transcranial magnetic simulation",
  TRANSPORTATION: "Transportation",
  OTHER_SERVICE_TYPE: "Other service type",
};
