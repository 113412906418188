import { useState, useRef } from "react";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Dialog from "@material-ui/core/Dialog";
import { Attachment } from "@coherehealth/core-platform-api";
import { NotificationViewerConfig } from "components/NotificationViewer/Viewers";
import NotificationViewerShell from "components/NotificationViewer/NotificationViewerShell";
import SingleAttachmentViewerSidePanel from "./SingleAttachmentViewerSidePanel";
import { NotificationData } from "components/NotificationViewer/util/notificationUtil";
import { NotificationViewerItem } from "components/NotificationViewer/util/notificationUtil";
import { createDownloadPath, createFileName } from "components/NotificationViewer/util/notificationUtil";

/*
 * SingleAttachmentViewer is a special case of NotificationViewer, where the
 * data is an Array with a single Notification, of the ATTACHMENT variant.
 */
interface SingleAttachmentViewerProps {
  attachment: Attachment;
  disabled?: boolean;
}

export default function SingleAttachmentViewer({ attachment, disabled }: SingleAttachmentViewerProps) {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const handleOpenViewer = () => setIsViewerOpen(true);
  const handleCloseViewer = () => setIsViewerOpen(false);

  const notificationViewerItem = createNotificationForAttachment(attachment);

  const [notifications, setNotifications] = useState<NotificationViewerItem[]>([notificationViewerItem]);
  const [notificationIndexOpen] = useState<number>(0);
  const currentPage = useRef<number>(0);
  const zoomLevel = useRef<number>(1);
  const currentRotate = useRef<number>(0);

  const singleAttachmentViewerConfig: NotificationViewerConfig = {
    viewerType: "SingleAttachmentViewer",
    entityLabel: "attachments",
    sidePanelLabel: "attachment data",
    sidePanelComponent: SingleAttachmentViewerSidePanel,
    closeDialogFunction: handleCloseViewer,
  };

  return (
    <>
      <IconButton aria-label="View" onClick={handleOpenViewer} disabled={disabled}>
        <VisibilityIcon />
      </IconButton>
      <Dialog fullScreen open={isViewerOpen} onClose={handleCloseViewer}>
        <NotificationViewerShell
          id="singleAttachmentViewer"
          notifications={notifications}
          setNotifications={setNotifications}
          notificationIndexOpen={notificationIndexOpen}
          notificationsLoading={false}
          currentPage={currentPage}
          zoomLevel={zoomLevel}
          currentRotate={currentRotate}
          onViewNotification={() => {}}
          viewerConfig={singleAttachmentViewerConfig}
        />
      </Dialog>
    </>
  );
}

// Wraps an Attachment inside a NotificationViewerItem object
const createNotificationForAttachment = (attachment: Attachment): NotificationViewerItem => {
  const notification: NotificationData = {
    variant: "ATTACHMENT",
    data: attachment,
  };
  const notificationViewerItem: NotificationViewerItem = {
    ...notification,
    fileName: createFileName(notification),
    downloadPath: createDownloadPath(notification),
    contentType: notification.data.contentType,
  };
  return notificationViewerItem;
};
