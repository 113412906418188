import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Footer from "../Footer";
import { InformativeModal } from "@coherehealth/common";
import {
  AuthBuilderWorkflowStep,
  ServiceRequestCreatePayload,
  ServiceRequestResponse,
  useDeleteServiceRequest,
  useGetEpisodicAuthAutoApprovalCheck,
  useUpdateServiceRequest,
  useBatchSubmitServiceRequests,
  Requestor,
} from "@coherehealth/core-platform-api";
import { PriorAuthRequirements, navigateToPS } from "../common";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { payloadFromSRFormContent } from "util/serviceRequest";
import routes from "routes";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, useTheme } from "@material-ui/core";
import MuiErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useSnackbar } from "notistack";

interface Props {
  requestor?: Requestor;
  workflowStep: AuthBuilderWorkflowStep;
  editServiceRequest: (serviceRequestResponse: ServiceRequestResponse) => void;
  serviceRequests: ServiceRequestResponse[];
  attemptedServiceRequestFormsSubmit: boolean;
  serviceRequestFormContents: ServiceRequestFormContent[];
  setAttemptedServiceRequestFormsSubmit: (b: boolean) => void;
  oonLoading: boolean;
  providerDetailsFormsCanBeSubmitted: boolean[];
  workflowId?: string;
  setWorkflowStep: (step: AuthBuilderWorkflowStep) => void;
  priorAuthRequirements: PriorAuthRequirements;
  setPriorAuthRequirements: (priorAuthRequirements: PriorAuthRequirements) => void;
  setServiceRequestFormContents: Dispatch<SetStateAction<ServiceRequestFormContent[]>>;
  setFooterHeight: Dispatch<SetStateAction<number>>;
}

export default function PreApprovedCareFooter({
  requestor,
  serviceRequests,
  attemptedServiceRequestFormsSubmit,
  serviceRequestFormContents,
  setAttemptedServiceRequestFormsSubmit,
  oonLoading,
  providerDetailsFormsCanBeSubmitted,
  workflowStep,
  editServiceRequest,
  workflowId,
  setWorkflowStep,
  priorAuthRequirements,
  setPriorAuthRequirements,
  setServiceRequestFormContents,
  setFooterHeight,
}: Props) {
  //Hooks
  const theme = useTheme();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  //State management
  const [showPendModal, setShowPendModal] = useState(false);
  const [showAutoApprovalModal, setShowAutoApprovalModal] = useState(false);
  //Variables
  const sr = { ...serviceRequestFormContents[0], workflowStep: workflowStep };
  const currentServiceRequest = serviceRequests.find(({ patient }) => Boolean(patient?.id));
  const serviceRequestFormsSubmitLoading = serviceRequestFormContents.length === 0;
  const canSubmitServiceRequestForms = !providerDetailsFormsCanBeSubmitted.includes(false);
  const invalidForms = !canSubmitServiceRequestForms && attemptedServiceRequestFormsSubmit;
  const canContinue = !invalidForms && !serviceRequestFormsSubmitLoading && !oonLoading;
  const newServiceRequestFormConfig = {
    clinicalService: undefined,
    palCategory: null,
    isInpatient: sr?.isInpatient,
    placeOfService: sr?.placeOfService,
    primaryDiagnosisCode: null,
    secondaryDiagnosisCodes: [],
    procedureCodes: [],
    startDate: sr?.startDate,
    endDate: undefined,
    units: "0",
    unitType: undefined,
    orderingProvider: null,
    orderingProviderSelectedTin: null,
    performingProvider: null,
    performingProviderSelectedTin: null,
    facility: null,
    facilitySelectedTin: null,
    isExpedited: false,
    expeditedReason: "",
    facilitySelectedAddress: null,
    performingProviderSelectedAddress: null,
    orderingProviderSelectedAddress: null,
    approvedUnits: "",
  };

  //Restful hooks
  const {
    mutate: updateServiceRequest,
    loading: updateServiceRequestLoading,
    error: updateServiceRequestError,
  } = useUpdateServiceRequest({ id: "" });

  const {
    refetch: getAutoApprovalStatus,
    error: autoApprovalError,
    loading: autoApprovalStatusLoading,
  } = useGetEpisodicAuthAutoApprovalCheck({
    serviceRequestId: "",
    lazy: true,
  });

  const {
    mutate: submitServiceRequests,
    loading: submitServiceRequestLoading,
    error: submitServiceRequestsError,
  } = useBatchSubmitServiceRequests({});

  const { mutate: deleteServiceRequest, error: deleteError } = useDeleteServiceRequest({});

  // Error handling
  useEffect(() => {
    if (updateServiceRequestError) {
      enqueueSnackbar("Failed to update service request, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [updateServiceRequestError, enqueueSnackbar]);

  useEffect(() => {
    if (autoApprovalError) {
      enqueueSnackbar("Failed to submit, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [autoApprovalError, enqueueSnackbar]);

  useEffect(() => {
    if (submitServiceRequestsError) {
      enqueueSnackbar("Failed to submit, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [submitServiceRequestsError, enqueueSnackbar]);

  useEffect(() => {
    if (deleteError) {
      enqueueSnackbar(`Error canceling authorization request: ${deleteError?.message || ""}`, {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [enqueueSnackbar, deleteError]);

  const patchServiceRequest = async () => {
    const payload: ServiceRequestCreatePayload = {
      ...payloadFromSRFormContent(sr, true, undefined, workflowId, false),
    };
    if (sr?.id) {
      const response = await updateServiceRequest({ ...payload }, { pathParams: { id: sr.id } });

      if (response) {
        editServiceRequest(response);
      }
    }
  };

  const setRequestorAndSubmit = async (serviceRequests: ServiceRequestResponse[]) => {
    const serviceRequestsToSubmit = serviceRequests.map((sr): ServiceRequestResponse => {
      return {
        ...sr,
        requestor,
      };
    });
    await submitServiceRequests(serviceRequestsToSubmit);
  };

  const determineEpisodicAuthAutoApprovalStatus = async () => {
    const response = await getAutoApprovalStatus({
      pathParams: {
        serviceRequestId: sr.id,
      },
    });

    if (response?.willAutoApprove === true) {
      await setRequestorAndSubmit(serviceRequests);
      setShowAutoApprovalModal(true);
    } else if (response?.willAutoApprove === false) {
      setShowPendModal(true);
    }
  };

  const handleCompletionChecks = async () => {
    setAttemptedServiceRequestFormsSubmit(true);
    if (canSubmitServiceRequestForms) {
      await patchServiceRequest();
      await determineEpisodicAuthAutoApprovalStatus();
    }
  };

  const handlePatientSummaryNavigation = useCallback(async () => {
    if (currentServiceRequest?.patient?.id) {
      navigateToPS(currentServiceRequest.patient.id, navigate, currentServiceRequest.id);
    } else {
      navigate(routes.DASHBOARD);
    }
  }, [navigate, currentServiceRequest]);

  const onDeleteAndExit = async () => {
    if (sr.id) {
      await deleteServiceRequest(sr.id);
      cleanupFormContentsForNewServiceRequest();
      setWorkflowStep("GATHER_REQUIREMENTS_MEDICAL");
    } else {
      navigate(routes.DASHBOARD);
    }
  };

  const cleanupFormContentsForNewServiceRequest = () => {
    // when navigating back to gather requirements page, clear values other than those that should be prepopulated
    setServiceRequestFormContents([newServiceRequestFormConfig]);

    // the PAR start date will be set to the episodic auth start date.
    // If we navigate back to the gather requirements page, we should either input the users' original start date
    // which we don't have access to as it's overwritten by the episodic auth dates, or clear this value, as done below. The PAR startDate is used to determine if we should populate a start date
    setPriorAuthRequirements({ ...priorAuthRequirements, startDate: undefined });
  };

  return (
    <>
      <Footer
        primaryButtonText={"Complete"}
        onPrimaryButtonClick={() => {
          handleCompletionChecks();
        }}
        primaryButtonDisabled={
          !canContinue || updateServiceRequestLoading || autoApprovalStatusLoading || submitServiceRequestLoading
        }
        primaryButtonLoading={
          oonLoading ||
          serviceRequestFormsSubmitLoading ||
          updateServiceRequestLoading ||
          autoApprovalStatusLoading ||
          submitServiceRequestLoading
        }
        errorCaptionText={"There are some errors with the details above."}
        showError={invalidForms}
        showSaveAndExitButton={false}
        secondaryButtonText={"Cancel"}
        onSecondaryButtonClick={handlePatientSummaryNavigation}
        showSecondaryButton
        setFooterHeight={setFooterHeight}
      />
      <InformativeModal
        open={showPendModal}
        onClose={() => setShowPendModal(false)}
        headerText={
          <div style={{ marginBottom: theme.spacing(2), fontFamily: "Gilroy-Semibold" }}>
            The provider or facility information you entered does not qualify for this request. Do you want to try a
            different selection?
          </div>
        }
        icon={<WarningIcon />}
        primaryButtonText="Go back and make changes"
        primaryButtonAction={() => setShowPendModal(false)}
        tertiaryButtonText="I'd like to start a new request"
        showDivider={false}
        tertiaryButtonAction={onDeleteAndExit}
      />
      <InformativeModal
        open={showAutoApprovalModal}
        headerText="Successfully submitted"
        subHeaderText="Authorization is based on information provided; it is not a guarantee of payment. Billed services are subject to medical necessity, appropriate setting, billing/coding, plan limits, eligibility at time of
            service. Verify benefits online or call Customer Service."
        icon={<SuccessIcon />}
        showDivider={false}
        primaryButtonText="Done"
        primaryButtonAction={handlePatientSummaryNavigation}
        noCloseIcon
      />
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const WarningIcon = styled(MuiErrorIcon)(({ theme }) => ({
  color: theme.palette.warning.dark,
  transform: "scale(1.2)",
  maxWidth: theme.spacing(6),
  maxHeight: theme.spacing(6),
  marginBottom: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SuccessIcon = styled(CheckCircleIcon)(({ theme }) => ({
  color: theme.palette.success.main,
}));
