import React from "react";
import { FixedSideRail } from "../PageLayout";
import { headerHeight, appHeaderHeight } from "../../util/StyleConstants";
import { Grid } from "@material-ui/core";
import { DenialsTemplate, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  statesOrderer,
  statesLabelAbbreviator,
  SimulatedDenialLetterCaption,
  SimulatedDenialLetterDivider,
  SimulatedDenialLetterHeader,
  SimulatedDenialLetterInfoPill,
  DenialLetterInfoCard,
  parseEncounterTypesFromTemplate,
} from "./DenialLetterUtil";
import { TextOverflowBody, StandardLabelCapitalization } from "../../util";
import { Tooltip } from "../../components";

const useStyles = makeStyles((theme) => ({
  singlePill: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
}));

interface Props {
  serviceRequest?: ServiceRequestResponse;
  denialsTemplate?: DenialsTemplate;
  denialLetterSimulation?: boolean;
}

export default function DenialLetterSidePanel({ serviceRequest, denialsTemplate, denialLetterSimulation }: Props) {
  const classes = useStyles();

  const healthPlan = denialsTemplate?.healthPlans?.length
    ? denialsTemplate.healthPlans.map((item) => StandardLabelCapitalization(item))
    : ["--"];
  const authStatuses = denialsTemplate?.authStatus?.length
    ? denialsTemplate?.authStatus.map((item) => StandardLabelCapitalization(item))
    : ["--"];
  const states = denialsTemplate?.statesOfUnion?.length ? statesOrderer(denialsTemplate?.statesOfUnion) : ["--"];
  const denialsStates = denialsTemplate?.denialsStatesOfUnion?.length
    ? statesOrderer(denialsTemplate?.denialsStatesOfUnion)
    : ["--"];
  const statesLabel = denialsTemplate?.mapToPolicy
    ? statesLabelAbbreviator(states)
    : statesLabelAbbreviator(denialsStates);
  const encounterTypeLabel = StandardLabelCapitalization(
    parseEncounterTypesFromTemplate(denialsTemplate?.encounterTypes)
  );
  const adhocNotificationType = denialsTemplate?.adhocNotificationType
    ? StandardLabelCapitalization(denialsTemplate.adhocNotificationType)
    : "--";

  const theme = useTheme();

  return (
    <FixedSideRail
      sideRailWidth={400}
      columnSpacingRight={5}
      verticalHeightAroundSideRail={headerHeight + appHeaderHeight()}
      ignoreOverflowStyles
      hasHeader
    >
      <DenialLetterInfoCard>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SimulatedDenialLetterHeader>Service request info</SimulatedDenialLetterHeader>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <SimulatedDenialLetterDivider />
          </Grid>
          <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-start", paddingBottom: 0 }}>
            <SimulatedDenialLetterInfoPill>
              <SimulatedDenialLetterCaption>{`LOB: ${
                serviceRequest?.lobType ?? denialsTemplate?.linesOfBusiness?.join(", ") ?? "--"
              }`}</SimulatedDenialLetterCaption>
            </SimulatedDenialLetterInfoPill>
          </Grid>
          {denialLetterSimulation && (
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap", paddingTop: 0 }}
            >
              <SimulatedDenialLetterInfoPill className={classes.singlePill}>
                <SimulatedDenialLetterCaption>
                  {authStatuses.length > 1 ? "Auth statuses: " : "Auth status: "}
                  {authStatuses.map((status) => StandardLabelCapitalization(status)).join(", ")}
                </SimulatedDenialLetterCaption>
              </SimulatedDenialLetterInfoPill>
              <SimulatedDenialLetterInfoPill className={classes.singlePill}>
                <SimulatedDenialLetterCaption>
                  {healthPlan.length > 1 ? "Health plans: " : "Health plan: "}
                  {healthPlan.join(", ")}
                </SimulatedDenialLetterCaption>
              </SimulatedDenialLetterInfoPill>
              {denialsTemplate?.isNonDetermination && (
                <>
                  <SimulatedDenialLetterInfoPill className={classes.singlePill}>
                    <SimulatedDenialLetterCaption>{`Encounter type: ${encounterTypeLabel}`}</SimulatedDenialLetterCaption>
                  </SimulatedDenialLetterInfoPill>
                  <SimulatedDenialLetterInfoPill className={classes.singlePill}>
                    <SimulatedDenialLetterCaption>
                      {`Ad hoc notification type: ${adhocNotificationType}`}
                    </SimulatedDenialLetterCaption>
                  </SimulatedDenialLetterInfoPill>
                </>
              )}
              <SimulatedDenialLetterInfoPill className={classes.singlePill}>
                <SimulatedDenialLetterCaption>
                  {states.length > 1 ? "States: " : "State: "}
                  {statesLabel}
                </SimulatedDenialLetterCaption>
              </SimulatedDenialLetterInfoPill>
            </Grid>
          )}
          <Grid item xs={12} style={{ paddingTop: 8 }}>
            <SimulatedDenialLetterHeader>{serviceRequest?.clinicalService?.name ?? "--"}</SimulatedDenialLetterHeader>
          </Grid>
          <Grid item xs={12}>
            <SimulatedDenialLetterCaption>Requested procedures</SimulatedDenialLetterCaption>
            <div style={{ padding: theme.spacing(1, 0, 3) }}>
              <Grid container item xs={12} spacing={2}>
                {!!serviceRequest?.procedureCodes?.length
                  ? serviceRequest?.procedureCodes.map((item, index) => {
                      return (
                        <Grid item xs={12} key={index} style={{ marginBottom: -10 }}>
                          <SimulatedDenialLetterInfoPill style={{ marginRight: -16 }}>
                            <Tooltip title={item.description || ""} placement="top" arrow={false}>
                              <TextOverflowBody>{item.code + " - " + item.description}</TextOverflowBody>
                            </Tooltip>
                          </SimulatedDenialLetterInfoPill>
                        </Grid>
                      );
                    })
                  : !!denialsTemplate?.procedureCodes?.length &&
                    denialsTemplate?.procedureCodes.map((item, index) => {
                      return (
                        <Grid item xs={12} key={index} style={{ marginBottom: -10 }}>
                          <SimulatedDenialLetterInfoPill style={{ marginRight: -16 }}>
                            <Tooltip title={item.description || ""} placement="top" arrow={false}>
                              <TextOverflowBody>{item.code + " - " + item.description}</TextOverflowBody>
                            </Tooltip>
                          </SimulatedDenialLetterInfoPill>
                        </Grid>
                      );
                    })}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </DenialLetterInfoCard>
    </FixedSideRail>
  );
}
