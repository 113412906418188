import { useFeature } from "@coherehealth/common";
import { HighlightStateContext } from "@coherehealth/common";
import { usePutUserHighlights } from "@coherehealth/core-platform-api";
import { useContext } from "react";
import { error as logError } from "logger";

export default function usePersistAttachmentHighlights() {
  // Hook require Highlight Context.
  const { getSubmitPayload } = useContext(HighlightStateContext);
  const saveReviews = useFeature("saveReviews");
  const { mutate: putUserHighlights, loading: submittingDraftHighlights } = usePutUserHighlights({});

  const submitDraftHighlights = async () => {
    // Check if the saveReviews feature flag is active
    if (!saveReviews) {
      logError("Submission is not allowed as the saveReviews feature flag is inactive.");
      return;
    }

    if (getSubmitPayload) {
      const highlightPayload = getSubmitPayload();
      if (highlightPayload) {
        await putUserHighlights(highlightPayload);
      }
    }
  };

  return {
    submitDraftHighlights,
    submittingDraftHighlights,
  };
}
