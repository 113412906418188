import { makeStyles } from "@material-ui/core";

export const useAuthorizationSummaryCardAdornmentsStyles = makeStyles((theme) => ({
  tooltipText: {
    whiteSpace: "nowrap",
  },
  chipLabel: {
    textAlign: "center",
    padding: 0,
  },
  chip: {
    borderRadius: "4px",
    padding: "2px",
    height: "26px",
    backgroundColor: "#0000000A",
  },
}));
