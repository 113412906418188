import React, { useContext } from "react";
import { ClinicalAssessmentContext, EditWithAssessmentContext } from "components/ClinicalAssessment";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { Body1, Card, H4, H5 } from "@coherehealth/common";
import GreenClipboardCheckmark from "../images/GreenClipboardCheckmark.svg";

interface Props {
  displayPrefilledCard: boolean | null;
}
export default function CohereClinicalAssessmentStep({ displayPrefilledCard }: Props) {
  const { clinicalAssessment } = useContext(ClinicalAssessmentContext);

  const noQuestions = clinicalAssessment && clinicalAssessment?.questions?.length === 0;

  return (
    <>
      {displayPrefilledCard && (
        <>
          <Card>
            <CardContents>
              <img src={GreenClipboardCheckmark} alt="" />
              <Spacer>
                <TextWrapper>Some answers have been auto-filled from the patient’s previous authorization</TextWrapper>
              </Spacer>
            </CardContents>
          </Card>
        </>
      )}
      {!noQuestions && (
        <VerticalSpacer>
          <H4>Information needed based on the change to the primary diagnosis code and facility.</H4>
          <Body1>This information is needed so we can quickly evaluate your request</Body1>
        </VerticalSpacer>
      )}
      <EditWithAssessmentContext />
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const VerticalSpacer = styled("div")(({ theme }) => ({
  margin: theme.spacing(3, 0),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Spacer = styled("span")({
  padding: 12,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CardContents = styled("div")({
  display: "flex",
  alignItems: "center",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const TextWrapper = styled(H5)({
  padding: "16px",
});
