// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import { Caption, Card, H2, H6 } from "@coherehealth/common";
import { AuthStatus, Patient } from "@coherehealth/core-platform-api";
import ServiceRequestForm from "components/AuthBuilder/FillForms/ServiceRequestForm";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { Grid } from "@material-ui/core";
import { useServiceRequestConfigSpec } from "../ServiceRequest/ConfigurableServiceRequestForm";
import { Dispatch, SetStateAction } from "react";
import { getPatientHealthPlanName } from "../../util/patientUtils";

interface Props {
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  authStatus?: AuthStatus;
  patient?: Patient;
  setCanBeSubmitted: (b: boolean) => void;
  attemptedSubmitService: boolean;
  setServiceRequestEdited: (b: boolean) => void;
  workflowId?: string;
}

const useStyles = makeStyles((theme) => ({
  greyText: {
    color: theme.palette.text.secondary,
  },
}));

export default function WithdrawAndReplaceEditDetailsStep({
  formContent,
  setFormContent,
  authStatus,
  patient,
  setCanBeSubmitted,
  attemptedSubmitService,
  setServiceRequestEdited,
  workflowId,
}: Props) {
  const classes = useStyles();
  const { clinicalService } = formContent;

  const healthPlanName = getPatientHealthPlanName(patient, formContent.startDate) || "";
  const { formFieldConfigurations } = useServiceRequestConfigSpec({
    ...formContent,
    patientId: patient?.id || "",
    healthPlanName,
  });

  return (
    <ServiceRequestCard>
      <Grid container spacing={2}>
        {clinicalService ? (
          <>
            <Grid item xs={12}>
              <H2>{clinicalService?.name || "Other Procedures"}</H2>
            </Grid>
            {clinicalService?.description && (
              <Grid item xs={12}>
                <Caption className={classes.greyText}>{clinicalService.description}</Caption>
              </Grid>
            )}
          </>
        ) : (
          <Grid item xs={12}>
            <H6 style={{ paddingBottom: 8 }}>Start by adding a service</H6>
          </Grid>
        )}
        <Grid item xs={12}>
          <ServiceRequestForm
            formContent={formContent}
            setFormContent={setFormContent}
            formConfiguration={formFieldConfigurations}
            authStatus={authStatus || "DRAFT"}
            patient={patient}
            setCanBeSubmitted={setCanBeSubmitted}
            attemptedSubmit={attemptedSubmitService}
            onUserEdit={() => setServiceRequestEdited(true)}
            workflowId={workflowId}
          />
        </Grid>
      </Grid>
    </ServiceRequestCard>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ServiceRequestCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));
