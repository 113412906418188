import { Dispatch, SetStateAction, useState } from "react";
import ClinicalReviewCard from "common/ClinicalReviewCard";
import ListOfReviews from "./ListOfReviews";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Grid, styled } from "@material-ui/core";
import {
  H5,
  Body1,
  incrementClickCount,
  UserClickInfoClinicalReviewPage,
  useFeature,
  parseDateFromISOString,
} from "@coherehealth/common";
import { ServiceRequestResponse, ReviewType } from "@coherehealth/core-platform-api";
import { useTheme } from "@material-ui/core/styles";
import { useAuthorized } from "authorization";
interface Props {
  serviceRequest: ServiceRequestResponse | null;
  existingReviews: ReviewType[] | null;
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
  newDenialsWorkflow?: boolean;
  isDenialReviewPage?: boolean;
  currServiceRequestID?: string;
}

export default function ReviewInformation({
  serviceRequest,
  existingReviews,
  newDenialsWorkflow,
  setUserClickInfoTracking,
  isDenialReviewPage = false,
  currServiceRequestID,
}: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(isDenialReviewPage);
  const { spacing } = useTheme();
  const canEditOutOfNetworkReview = useAuthorized("EDIT_OUT_OF_NETWORK_REVIEW");
  const auditReviewsAllowed = useFeature("auditReviews");
  const canAuditReview = useAuthorized("AUDIT_REVIEW") && auditReviewsAllowed;
  const logrocketUnmasking = useFeature("logrocketUnmasking");

  const otherReviews =
    existingReviews?.filter(
      (review) =>
        review.reviewStatus !== "DRAFT" &&
        (canAuditReview || review.reviewType !== "AuditReview") &&
        (canEditOutOfNetworkReview || review.reviewType !== "OutOfNetworkReview")
    ) || [];
  const reviewQuantity = otherReviews.length;
  const sortedOtherReviews = [...otherReviews].sort(comp);
  const updateUserClickInfo = () => {
    setUserClickInfoTracking && incrementClickCount("ReviewsCardToggle", "", -1, setUserClickInfoTracking);
  };

  return (
    <ClinicalReviewCard
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      updateUserClickInfo={updateUserClickInfo}
      header={
        <Grid container>
          <Grid item xs={12} style={{ marginBottom: 6 }}>
            <H5 data-public={!!logrocketUnmasking ? true : undefined}>Reviews</H5>
          </Grid>
          <Grid item xs={12}>
            <StyledBody1>
              {reviewQuantity} previous review{reviewQuantity !== 1 && "s"}
            </StyledBody1>
          </Grid>
        </Grid>
      }
    >
      {isExpanded && (
        <Grid container>
          {!!reviewQuantity ? (
            <>
              {serviceRequest && (
                <ListOfReviews
                  serviceRequest={serviceRequest}
                  currServiceRequestID={currServiceRequestID}
                  reviews={sortedOtherReviews}
                  newDenialsWorkflow={newDenialsWorkflow}
                  buttonPadding={"8px 0px"}
                  reviewDetailsCollapseStyle={{ margin: 0 }}
                  authorizationReviewView={true}
                  clinicalReviewView
                  isDenialReviewPage={isDenialReviewPage}
                />
              )}
            </>
          ) : (
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center", marginTop: spacing(2), marginBottom: spacing(2.5) }}
            >
              <StyledBody1>No previous reviews for this Service Request</StyledBody1>
            </Grid>
          )}
        </Grid>
      )}
    </ClinicalReviewCard>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledBody1 = styled(Body1)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

function comp(a: ReviewType, b: ReviewType) {
  return (
    parseDateFromISOString(b.dateCompleted ?? b.dateCreated).getTime() -
    parseDateFromISOString(a.dateCompleted ?? b.dateCreated).getTime()
  );
}
