import React, { useCallback, useState } from "react";
import { ServiceRequestResponse, Patient, VatNotification, UserResponse } from "@coherehealth/core-platform-api";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DocumentViewer from "../DocumentViewer";
import ServiceRequestSummaryCard from "./ServiceRequestSummaryCard";

import DetailsPrintoutProvider from "../ServiceRequest/DetailsPrintoutProvider";
import CallLogLetter from "../ServiceRequest/CallLogLetter";
import { useAuthorized } from "authorization";
import useSearchAndLocation from "hooks/useSearchAndLocation";

interface Props {
  patient: Patient;
  serviceRequests: ServiceRequestResponse[];
  setServiceRequests?: React.Dispatch<React.SetStateAction<ServiceRequestResponse[]>>;
  refresh?: () => Promise<ServiceRequestResponse[] | null | void>;
  refreshServiceRequest?: (serviceRequestId: string) => Promise<ServiceRequestResponse[] | null | void>;
  user?: UserResponse | null;
}

export default function PatientSummaryForAuthorizations({
  serviceRequests,
  setServiceRequests,
  patient,
  refresh,
  refreshServiceRequest,
  user,
}: Props) {
  const { location, search } = useSearchAndLocation();

  const REVIEW_TO_SCROLL_TO_PARAM = "reviewId";
  const targetReviewId = search.get(REVIEW_TO_SCROLL_TO_PARAM);
  const [showSrDetailsPrintout, setShowSrDetailsPrintout] = useState(false);
  const [srForDetailsPrintout, setSrForDetailsPrintout] = useState<ServiceRequestResponse>();
  const [showCallLogDetailsPrintout, setShowCallLogDetailsPrintout] = useState(false);
  const [vatForDetailsPrintout, setVatForDetailsPrintout] = useState<VatNotification | null>();
  const [callDispositionMessage, setCallDispositionMessage] = useState<string>();
  const canSeeClaimsSRs = useAuthorized("EDIT_SERVICE_REQUEST_STATUS");
  const canViewFinalDeterminationLetter = useAuthorized("FINAL_DETERMINATION_LETTER_DISPLAY");

  const showSummaryPrintout = useCallback(
    (sr: ServiceRequestResponse) => {
      setShowSrDetailsPrintout(true);
      setSrForDetailsPrintout(sr);
    },
    [setShowSrDetailsPrintout, setSrForDetailsPrintout]
  );

  const showCallLogPrintout = useCallback(
    (sr: ServiceRequestResponse, vn?: VatNotification | null, callDispositionMessage?: string) => {
      setShowCallLogDetailsPrintout(true);
      setVatForDetailsPrintout(vn);
      setCallDispositionMessage(callDispositionMessage);
      setSrForDetailsPrintout(sr);
    },
    []
  );

  return (
    <>
      {!showCallLogDetailsPrintout && (
        <Dialog fullScreen keepMounted open={showSrDetailsPrintout} onClose={() => setShowSrDetailsPrintout(false)}>
          <DetailsPrintoutProvider serviceRequest={srForDetailsPrintout}>
            {({ url, loading }) => (
              <DocumentViewer
                documentInfo={{
                  name: `CohereServiceRequest_${srForDetailsPrintout?.cohereId}.pdf`,
                  contentType: "application/pdf",
                }}
                loading={loading}
                url={url || undefined}
                handleClose={() => setShowSrDetailsPrintout(false)}
                canDelete={false}
                location={location}
                search={search}
              />
            )}
          </DetailsPrintoutProvider>
        </Dialog>
      )}
      {canViewFinalDeterminationLetter && callDispositionMessage && !showSrDetailsPrintout && (
        <Dialog
          fullScreen
          keepMounted
          open={showCallLogDetailsPrintout}
          onClose={() => setShowCallLogDetailsPrintout(false)}
        >
          <CallLogLetter
            serviceRequest={srForDetailsPrintout}
            vatNotification={vatForDetailsPrintout}
            callDispositionMessage={callDispositionMessage}
            languagePreference={patient?.languagePreference}
          >
            {({ url, loading }) => (
              <DocumentViewer
                documentInfo={{
                  name: `CohereServiceRequest_${srForDetailsPrintout?.cohereId}.pdf`,
                  contentType: "application/pdf",
                }}
                loading={loading}
                url={url || undefined}
                handleClose={() => setShowCallLogDetailsPrintout(false)}
                canDelete={false}
                location={location}
                search={search}
              />
            )}
          </CallLogLetter>
        </Dialog>
      )}

      <Grid container spacing={2}>
        {serviceRequests
          .filter((ssr) => !ssr.reconClaim || canSeeClaimsSRs)
          .map((serviceRequest, idx) => (
            <Grid key={serviceRequest.id || `sr-fallback-${idx}`} item xs={12}>
              <ServiceRequestSummaryCard
                onEdit={async () => {
                  await refresh?.();
                  if (serviceRequest.id) {
                    await refreshServiceRequest?.(serviceRequest.id);
                  }
                }}
                serviceRequest={serviceRequest}
                setServiceRequests={setServiceRequests}
                onShowCallLog={showCallLogPrintout}
                onShowSummaryPrintout={showSummaryPrintout}
                targetReviewId={targetReviewId}
                user={user}
              />
            </Grid>
          ))}
      </Grid>
    </>
  );
}
