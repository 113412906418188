import React, { useState, useEffect, useContext } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Body1, H4, PrimaryButton, Checkbox, useMuiContainerStyles } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { headerHeight } from "../../util/StyleConstants";
import MuiAppBar from "@material-ui/core/AppBar";
import Markdown from "react-markdown";
import {
  useGetLicenseAgreement,
  useGetOrganizationById,
  useGetUserById,
  useSignLicenseAgreement,
  useUpdateOrganization,
} from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import { Link } from "@material-ui/core";
import { useAuthorized } from "authorization";
import { UserContext } from "UserContext";
import { error as logError } from "logger";

export default function LicenseAgreement() {
  const canOptInForELetters = useAuthorized("CAN_OPT_IN_FOR_E_LETTERS");
  const canEditELetterOptInStatus = useAuthorized("CAN_OPT_IN_FOR_E_LETTERS");

  const { enqueueSnackbar } = useSnackbar();

  const { getUser } = useContext(UserContext);
  const [userId, setUserId] = useState<string>();

  useEffect(() => {
    getUser()?.then((user) => setUserId(user.sub || ""));
  }, [getUser]);

  const [eulaPolicyChecked, setEulaPolicyChecked] = useState<boolean>(false);
  const [eLettersChecked, setELettersChecked] = useState<boolean>(true);
  const [orgId, setOrgId] = useState<string>("");

  const { data: user, refetch: refetchUser } = useGetUserById({
    lazy: true,
    id: userId || "",
  });

  useEffect(() => {
    if (userId && canEditELetterOptInStatus) {
      refetchUser({ pathParams: { id: userId } });
    }
  }, [userId, refetchUser, canEditELetterOptInStatus]);

  const {
    data: organization,
    error: getOrgError,
    refetch: refetchOrg,
  } = useGetOrganizationById({
    id: orgId,
    lazy: true,
  });

  useEffect(() => {
    if (user) {
      setOrgId(user?.organization || "");
    }
  }, [user, setOrgId]);

  useEffect(() => {
    //only call user endpoint if we are permitted (only necessary if we are changing e-letter opt-in status)
    if (orgId && canEditELetterOptInStatus) {
      refetchOrg({ pathParams: { id: orgId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  useEffect(() => {
    if (getOrgError) {
      enqueueSnackbar(`There was an error getting information about this user's organization: ${getOrgError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, getOrgError]);

  const {
    mutate: patchOrg,
    loading: submittingOrg,
    error: submitOrgError,
  } = useUpdateOrganization({
    id: orgId,
  });

  useEffect(() => {
    if (submitOrgError) {
      enqueueSnackbar(
        `There was an error saving the user's organization electronic mail preference ${submitOrgError.message}`,
        {
          variant: "error",
        }
      );
    }
  }, [enqueueSnackbar, submitOrgError]);

  const { data: licenseAgreement, error: licenseAgreementError } = useGetLicenseAgreement({});

  useEffect(() => {
    if (licenseAgreementError) {
      enqueueSnackbar(`Error loading license agreement: ${licenseAgreementError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, licenseAgreementError]);

  const { mutate: signLicenseAgreement, loading: submitLoading, error: submitError } = useSignLicenseAgreement({});

  useEffect(() => {
    if (submitError) {
      enqueueSnackbar(`Error signing license agreement: ${submitError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, submitError]);

  const showAndSubmitELetterOptIn = canOptInForELetters && organization && !organization?.electronicLettersOptedIn;

  const submitAgreement = async () => {
    try {
      if (showAndSubmitELetterOptIn) {
        await patchOrg({ electronicLettersOptedIn: eLettersChecked });
      }
      await signLicenseAgreement({ textHash: licenseAgreement?.textHash });
      // Need to reload the page in order to get the new user profile info
      window.location.reload();
    } catch (e) {
      logError(e);
    }
  };

  const containerClasses = useMuiContainerStyles();

  if (!licenseAgreement) {
    return null;
  }
  return (
    <>
      <MainContent>
        <Grid container direction="column" spacing={6} alignItems="center" wrap="nowrap" style={{ height: "100%" }}>
          <Grid item xs={12} style={{ flex: 0 }}>
            <H4>Please read and agree to our End User License Agreement and privacy policy before proceeding.</H4>
          </Grid>
          <Grid item xs={8} style={{ overflow: "hidden", flex: 1 }}>
            <CopyContainer>
              <Markdown>{licenseAgreement.text}</Markdown>
            </CopyContainer>
          </Grid>
        </Grid>
      </MainContent>
      <AppBar classes={containerClasses} component="footer" elevation={0}>
        <Container classes={containerClasses} maxWidth="lg">
          <Grid container direction="column" spacing={1} style={{ paddingTop: 24, paddingBottom: 24 }}>
            <Grid item xs={12}>
              <StyledCheckbox
                checked={eulaPolicyChecked}
                label={
                  <Body1>
                    By checking this box, you agree to the terms of the EULA policy as well as Cohere Health's Terms of
                    Use and{" "}
                    <Link href="https://www.coherehealth.com/privacy-policy" target="_blank" rel="noopener">
                      Privacy Policy
                    </Link>
                    .
                  </Body1>
                }
                onChange={setEulaPolicyChecked}
              />
            </Grid>
            {showAndSubmitELetterOptIn && (
              <Grid item xs={12}>
                <StyledCheckbox
                  checked={eLettersChecked}
                  label={
                    <Body1>
                      By checking this box you opt your organization in for{" "}
                      <Link href="https://coherehealth.com/electronic-letter-consent/" target="_blank" rel="noopener">
                        Electronic notices
                      </Link>{" "}
                      when permitted and receive your final determination letters instantly in the portal.
                    </Body1>
                  }
                  onChange={setELettersChecked}
                />
              </Grid>
            )}
            <Grid item style={{ margin: "auto" }}>
              <AgreeButton
                onClick={submitAgreement}
                loading={submitLoading || submittingOrg}
                disabled={!eulaPolicyChecked || submitLoading}
              >
                Agree and continue
              </AgreeButton>
            </Grid>
          </Grid>
        </Container>
      </AppBar>
    </>
  );
}

const appBarHeight = 190;
// eslint-disable-next-line cohere-react/no-mui-styled-import
const CopyContainer = styled("div")(({ theme }) => ({
  height: "100%",
  overflowY: "scroll",
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2),
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
const MainContent = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingTop: theme.spacing(5) + headerHeight,
  paddingBottom: theme.spacing(2) + appBarHeight,
  height: "100%",
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
const AppBar = styled(MuiAppBar)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.common.white,
  height: appBarHeight,
  top: "auto",
  bottom: 0,
  position: "fixed",
  width: "100%",
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  height: theme.spacing(3),
  marginLeft: theme.spacing(12),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const AgreeButton = styled(PrimaryButton)({ width: 400, marginTop: 16 });
