import IconButton from "@mui/material/IconButton";
import Alert, { CohereAlertProps } from "../Alert/Alert";
import React, { useState } from "react";
import { AlertProps } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

type Props = CohereAlertProps & Omit<AlertProps, "severity" | "css">;

interface ExpansionAlertProps extends Props {
  text: string;
}

const useStyles = makeStyles((theme) => ({
  rotatingIcon: ({ isExpanded }: { isExpanded: boolean }) => ({
    transition: theme.transitions.create("transform"),
    transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
    height: theme.spacing(3),
    width: theme.spacing(3),
  }),
}));

const splitTextBy = (text: string, separator: string | RegExp) => text.split(separator);

const ExpandableAlert = ({ text, message, severity, children, ...props }: ExpansionAlertProps) => {
  const [expanded, setExpanded] = useState(false);

  const classes = useStyles({ isExpanded: expanded });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const newLineSplit = splitTextBy(text, "\n");
  const wordSplit = splitTextBy(text, /\s+/);
  const showIconButtons =
    newLineSplit.length > 1 ? newLineSplit.some((t) => splitTextBy(t, /\s+/).length > 30) : wordSplit.length > 30;

  const getWhiteSpaceProperty = () => {
    if (newLineSplit.length > 1 && wordSplit.length < 30) {
      return "pre-wrap";
    } else if (expanded) {
      return "pre-wrap";
    } else {
      return "nowrap";
    }
  };

  return (
    <Alert
      severity={severity}
      message={message}
      action={
        showIconButtons ? (
          <IconButton
            aria-expanded={expanded}
            aria-label={expanded ? "show less" : "show more"}
            onClick={handleExpandClick}
          >
            <ExpandMoreIcon className={classes.rotatingIcon} />
          </IconButton>
        ) : null
      }
    >
      {!!text && (
        <div style={{ display: "grid", gridTemplateColumns: "auto 1fr" }}>
          <p
            style={{
              whiteSpace: getWhiteSpaceProperty(),
              textOverflow: "ellipsis",
              overflow: "hidden",
              margin: 0,
            }}
          >
            {text}
          </p>
        </div>
      )}
    </Alert>
  );
};

export default ExpandableAlert;
