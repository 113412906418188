import { ReferralRequestResponse } from "@coherehealth/core-platform-api";
import React, { useEffect, useState } from "react";
import { Author, useGetReferralAuditHistory } from "@coherehealth/audit-api";
import config from "../../../api/config";
import { convertEpochToEastern, convertEpochToTimeStamps, H6, InlineButton } from "@coherehealth/common";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { useTheme } from "@material-ui/core";

interface Props {
  referralRequest: ReferralRequestResponse;
}

const RRUpdateDescriptionEpochConv: string[] = [
  "updatedFields:lastUpdated",
  "updatedFields:autoApprovalSnapshot.timestamp",
  "updatedFields:intakeTimestamp",
  "updatedFields:integration:salesforce:lastUpdatedAt",
  "updatedFields:integration:welltok:lastUpdatedAt",
  "updatedFields:decisionTimestamp",
];

const RRFullDocumentEpochConv: string[] = [
  "lastUpdated",
  "autoApprovalSnapshot.timestamp",
  "intakeTimestamp",
  "integration:salesforce:lastUpdatedAt",
  "integration:welltok:lastUpdatedAt",
  "decisionTimestamp",
];

export default function ReferralRequestAuditHistory({ referralRequest }: Props) {
  const [showRRHistory, setShowRRHistory] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: referralData,
    error: referralDataError,
    loading: referralDataLoading,
  } = useGetReferralAuditHistory({
    base: `${config.AUDIT_SERVICE_API_URL}`,
    referralId: referralRequest.id || "",
    queryParams: { embed_users: "true" },
  });

  const theme = useTheme();

  useEffect(() => {
    if (referralDataError) {
      enqueueSnackbar(`Error retrieving audit history: ${referralDataError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, referralDataError]);

  if (referralDataLoading) {
    return <CircularProgress />;
  }

  return (
    <div style={{ marginTop: theme.spacing(3) }}>
      <H6>Audit history</H6>
      <ul>
        <InlineButton
          onClick={() => {
            setShowRRHistory(!showRRHistory);
          }}
        >
          {referralRequest.id}
        </InlineButton>
      </ul>
      <ul>
        {showRRHistory &&
          [...(referralData || [])].map((change) => (
            <li>
              {change.operationType} - {formatAuthor(change.author || ({ id: "unknown" } as Author))} at{" "}
              {convertEpochToEastern(change?.clusterTime || 0)}
              <pre>
                {JSON.stringify(
                  change.updateDescription
                    ? convertEpochToTimeStamps(change.updateDescription, RRUpdateDescriptionEpochConv)
                    : convertEpochToTimeStamps(change.fullDocument, RRFullDocumentEpochConv),
                  undefined,
                  2
                )}
              </pre>
            </li>
          ))}
      </ul>
    </div>
  );
}

function formatAuthor({ id, name, email }: Author): string {
  if (name || email) {
    return `${name || "unknown name"} (${email || "unknown email"})`;
  }

  if (id === "sub") {
    return "unknown";
  }
  return `Okta user with id ${id}`;
}
