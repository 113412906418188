import { Dispatch, useEffect, useMemo } from "react";
import { InformativeModal, queueMgmtBaseUrl, startNextCase } from "@coherehealth/common";
import ClipboardPenIcon from "components/images/ClipboardPenIcon";
import { useSnackbar } from "notistack";
import { useGetActiveQueueForUser, useGetNextCaseForQueue } from "@coherehealth/qm-api";
import config from "api/config";
import ClipboardWarningIcon from "components/images/ClipboardWarningIcon";
import { CaseType } from "@coherehealth/qm-api";

export default function UnsolvableCaseModal({
  open,
  setOpen,
  buttonsLoading,
  assignmentErrorVariant,
  caseType,
}: {
  open: boolean;
  setOpen: Dispatch<boolean>;
  buttonsLoading?: boolean;
  assignmentErrorVariant?: boolean;
  caseType?: CaseType;
}) {
  const { enqueueSnackbar } = useSnackbar();

  const modalText = useMemo(() => modalLabels(!!assignmentErrorVariant), [assignmentErrorVariant]);
  const headerText = useMemo(
    () => (assignmentErrorVariant && caseType ? modalText.header + caseTypeLabel(caseType) : modalText.header),
    [assignmentErrorVariant, caseType, modalText]
  );

  const {
    error: queueError,
    refetch: getActiveQueue,
    loading: loadingActiveQueue,
  } = useGetActiveQueueForUser({
    base: `${config.QM_SERVICE_API_URL}`,
    lazy: true,
  });

  const {
    loading: nextServiceCaseLoadingFromQueue,
    error: getNextServiceCaseErrorFromQueue,
    refetch: getNextServiceCaseFromQueue,
  } = useGetNextCaseForQueue({
    queueId: "",
    lazy: true,
    base: `${config.QM_SERVICE_API_URL}`,
  });

  const fetchCase = async () => {
    const queue = await getActiveQueue();
    const serviceCaseFromQueue = await getNextServiceCaseFromQueue({
      pathParams: { queueId: queue?.id },
      queryParams: { timeStamp: new Date().toISOString() },
    });

    if (serviceCaseFromQueue) {
      startNextCase(
        () => {},
        serviceCaseFromQueue,
        enqueueSnackbar,
        false,
        true,
        queue?.id,
        queue?.routeConfigByCaseType
      );
    }
  };

  useEffect(() => {
    if (queueError) {
      enqueueSnackbar("Error fetching queue", {
        variant: "error",
        preventDuplicate: true,
      });
    }
    if (getNextServiceCaseErrorFromQueue) {
      enqueueSnackbar("Error fetching next case", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [enqueueSnackbar, getNextServiceCaseErrorFromQueue, queueError]);

  const loading = nextServiceCaseLoadingFromQueue || buttonsLoading || loadingActiveQueue;

  return (
    <InformativeModal
      headerText={headerText}
      subHeaderText={modalText.subHeader}
      icon={assignmentErrorVariant ? <ClipboardWarningIcon /> : <ClipboardPenIcon />}
      primaryButtonText={modalText.primaryButton}
      primaryButtonAction={fetchCase}
      primaryButtonLoading={loading}
      primaryButtonDisabled={loading}
      tertiaryButtonText={modalText.tertiaryButtonText}
      tertiaryButtonAction={() => window.location.assign(`${queueMgmtBaseUrl()}/dashboard`)}
      tertiaryButtonLoading={buttonsLoading}
      tertiaryButtonDisabled={loading}
      showDivider
      open={open}
      onClose={() => setOpen(false)}
      hideX={loading}
    />
  );
}

const caseTypeLabel = (caseType: CaseType) => {
  return caseType === "RN_REVIEW"
    ? "clinical review"
    : caseType === "OUT_OF_NETWORK_REVIEW"
    ? "out-of-network review"
    : caseType === "MD_REVIEW"
    ? "MD review"
    : caseType === "P2P_REVIEW"
    ? "peer to peer review"
    : "letter writing queue";
};

const modalLabels = (assignmentErrorVariant: boolean) => {
  return {
    header: assignmentErrorVariant
      ? "Assignment error: this case is no longer in "
      : "You’ve been unassigned from this case",
    subHeader: assignmentErrorVariant ? "Please get next case" : "Your supervisor will assign it to another agent",
    primaryButton: assignmentErrorVariant ? "Get next case" : "Start next case",
    tertiaryButtonText: "Back to dashboard",
  };
};
