import { colorsLight as colors } from "./colors";
import { ThemeOptions } from "@material-ui/core/styles";

// https://material-ui.com/customization/default-theme/
const options: ThemeOptions = {
  palette: {
    primary: colors.primary,
    secondary: colors.secondary,
    error: colors.error,
    warning: colors.warning,
    info: colors.info,
    success: colors.success,
    action: colors.action,
    drawer: {
      main: colors.background.light,
      expanded: colors.background.card,
      button: colors.background.cardLight,
    },
    table: {
      panelBackground: colors.background.panel,
    },
  },
  zIndex: { appBar: 998 },
  typography: {
    fontFamily: "Gilroy, sans-serif",
    h1: {
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "35px",
      letterSpacing: "0.15px",
    },
    h2: {
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "26px",
      letterSpacing: "0.15px",
    },
    h3: {
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "27px",
      letterSpacing: "0.15px",
    },
    h4: {
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "22px",
      letterSpacing: "0.15px",
    },
    h5: {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "20px",
      letterSpacing: "0.15px",
    },
    h6: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "0.15px",
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "15px",
      letterSpacing: "0.25px",
      textTransform: "uppercase",
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: "13px",
      lineHeight: "16px",
      letterSpacing: "0.15px",
    },
    body1: {
      fontWeight: 500,
      fontSize: "15px",
      lineHeight: "19px",
      letterSpacing: "0px",
    },
    body2: {
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "19px",
      letterSpacing: "0px",
    },
    button: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "0.25px",
      textTransform: "none",
    },
    caption: {
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "15px",
      letterSpacing: "0.15px",
      textTransform: "none",
    },
    overline: {
      fontWeight: 500,
      fontSize: "11px",
      lineHeight: "20px",
      letterSpacing: "0.25px",
      textTransform: "uppercase",
    },
  },
  overrides: {
    // overrides for Datepicker https://v4-0-0-alpha-12.material-ui-pickers.dev/guides/css-overrides
    MuiPickersPopper: {
      root: {
        marginTop: 8,
        marginBottom: 8,
      },
    },
    MuiPickersDay: {
      today: {
        "&:not(.Mui-selected)": {
          border: "none",
        },
      },
    },
    MuiPickersCalendarHeader: {
      monthTitleContainer: {
        alignItems: "center",
      },
    },
    MuiPickersYear: {
      modeDesktop: {
        flexBasis: "100%",
      },
      yearButton: {
        "&:hover, &:focus": {
          fontSize: 24,
          color: colors.primary.main,
          backgroundColor: "unset",
        },
      },
    },
  },
};

export default options;
