import React, { useCallback, useEffect, useState } from "react";

// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import { TextFieldProps } from "@material-ui/core/TextField";
import ListSubheader from "@material-ui/core/ListSubheader";
import { SelectProps } from "@material-ui/core/Select";
import ExpandMore from "@material-ui/icons/ExpandMore";
import partition from "lodash/partition";

import { Caption } from "../Typography";
import { TextField } from "../TextField";
import CohereMenuItem from "./CohereMenuItem";
import { colorsLight } from "../../themes/colors";
import {
  DropdownOption,
  useStylesMenu,
  useStylesListSubheader,
  useStylesMenuList,
  useStylesSelect,
  Warning,
} from "./shared";
import { InputAdornment } from "@material-ui/core";

const useStylesTargetText = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(2),
    width: "25%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.5),
    },
  },
}));

const useStylesSelectedOptionWrapper = makeStyles((theme) => ({
  optionWrapper: {
    display: "inline-block",
    position: "relative",
    "& ~ &": {
      paddingLeft: theme.spacing(1),
      "&::before": {
        content: '","',
        marginTop: "auto",
        position: "absolute",
        left: 0,
        bottom: 0,
      },
    },
  },
  optionListWrapper: {
    overflow: "hidden",
  },
}));

const useStylesExpandMore = makeStyles((theme) => ({
  expandMoreIcon: {
    color: colorsLight.font.tertiary,
  },
}));

interface BaseOptionType {
  id: any;
  endAdornmentContent?: string;
}

type IdType<T extends BaseOptionType> = T extends { id: infer IdKey } ? IdKey : never;

type ValuesWithTargets<T extends BaseOptionType> = {
  value: IdType<T>;
  target: number | undefined;
};

function getDropdownId<T extends BaseOptionType>(option: T): IdType<T> {
  return option.id;
}

export interface MultiSelectDropdownProps<T extends DropdownOption> {
  /** Label for the dropdown. */
  label?: React.ReactNode;
  /** If the label is external, provide its HTML id here to associate it with this input. */
  labelId?: string;
  /** Alternative label for when items are selected */
  labelAltSelected?: React.ReactNode;
  /** Helper text for the dropdown. */
  helperText?: React.ReactNode;
  /** Array of options to display. `{ id: string, label?: ReactNode }` */
  options?: T[];
  /** array of 'id's of the selected options */
  selectedValues?: IdType<T>[];
  /** Function: the 'ids' of the selected options are provided as the first argument. */
  onChange: (arg0: IdType<T>[]) => any;

  // called when select menu is opened
  onMenuOpen?: (evt: React.ChangeEvent<any>) => void;

  // called when select menu is closed
  onMenuClose?: (evt: React.ChangeEvent<any>) => void;

  /** array of targets for each selected value. easier than modifying selectedValues for 1 use case */
  selectedValuesWithTargets?: ValuesWithTargets<T>[];
  /** Function: the 'ids' of the selected options are provided as the first argument. */
  updateTargets?: (arg0: ValuesWithTargets<T>[]) => any;
  /** Function: used to display the option as the selection and in the options list (unless `renderOptionInList` is also provided).
   * By default, the option 'label' will be displayed.
   */
  renderOption?: (arg0: T) => React.ReactNode;
  /** Function: used to display the option in the options list. By default, the option 'label' will be displayed. */
  renderOptionInList?: (arg0: T) => React.ReactNode;
  /** Display single text, instead of list of selected options */
  renderSingleSelection?: React.ReactNode;
  /** Optionally set a fixed width for the dropdown menu options list. */
  menuWidth?: number;
  /** Optionally set a max height for the dropdown menu options list. */
  maxMenuHeight?: number | string;

  showSelectAllOption?: boolean;

  optionIdsWithWarning?: Array<IdType<T>>;

  addWarningToSelections?: boolean;

  omitNumberSelectedInLabel?: boolean;

  warningTextForOptions?: string;

  warningTextForTextFieldSelections?: string;

  showAllSelectedWithLessValues?: boolean;

  showTarget?: boolean;
  isLazyLoaded?: boolean;
}

// Temporary export for storybook documentation
export function MultiSelectDropdownForProps<T extends DropdownOption>(props: MultiSelectDropdownProps<T>) {}

export default function MultiSelectDropdown<T extends DropdownOption>({
  classes = {},
  children,
  label,
  labelAltSelected,
  labelId,
  options = [],
  selectedValues = [],
  selectedValuesWithTargets = [],
  onChange,
  onMenuOpen,
  onMenuClose,
  updateTargets,
  renderOption: renderOptionOverride,
  renderOptionInList,
  renderSingleSelection,
  menuWidth,
  maxMenuHeight,
  showSelectAllOption,
  optionIdsWithWarning,
  addWarningToSelections,
  omitNumberSelectedInLabel,
  warningTextForOptions,
  warningTextForTextFieldSelections,
  showAllSelectedWithLessValues,
  showTarget,
  // ignore variant prop, the TextField doesn't want it
  variant,
  SelectProps: SelectPropsOverrides,
  ...props
}: MultiSelectDropdownProps<T> & Omit<TextFieldProps, "value" | "onChange" | "css">) {
  const requireSelectAllOption = showAllSelectedWithLessValues
    ? showAllSelectedWithLessValues
    : Boolean(showSelectAllOption) && Boolean(options.length > 3);

  const numSelected = selectedValues?.length || 0;
  const allOptionsSelected = hasSameItems(
    selectedValues,
    options.map((val) => val.id)
  );

  const selected: (IdType<T> | "SELECT_ALL_OPTIONS")[] = Boolean(allOptionsSelected && requireSelectAllOption)
    ? ["SELECT_ALL_OPTIONS", ...selectedValues]
    : selectedValues;
  const [isSelectAllOptionChecked, setIsSelectAllOptionChecked] = useState<boolean>(
    allOptionsSelected && requireSelectAllOption
  );

  useEffect(() => {
    if (requireSelectAllOption) {
      const sameItems = hasSameItems(
        selectedValues,
        options.map((val) => val.id)
      );
      setIsSelectAllOptionChecked(sameItems);
    }
  }, [options, requireSelectAllOption, selectedValues]);

  const internalOnChange = useCallback(
    (event: any) => {
      const newSelections: (IdType<T> | "SELECT_ALL_OPTIONS")[] = event.target.value.filter(
        (selection: IdType<T> | "SELECT_ALL_OPTIONS") => !!selection
      );
      const newSelectionsWithoutSelectAll = newSelections.filter(
        (val): val is IdType<T> => val !== "SELECT_ALL_OPTIONS"
      );
      if (updateTargets) {
        updateTargets(
          newSelectionsWithoutSelectAll.map(
            (s) => selectedValuesWithTargets.find((vwt) => vwt.value === s) || { value: s, target: undefined }
          )
        );
      }
      if (!isSelectAllOptionChecked && newSelections.includes("SELECT_ALL_OPTIONS")) {
        const allSelections: IdType<T>[] = options.map(getDropdownId);
        onChange(allSelections);
      } else if (isSelectAllOptionChecked && !newSelections.includes("SELECT_ALL_OPTIONS")) {
        onChange([]);
      } else {
        onChange(newSelectionsWithoutSelectAll);
      }
    },
    [isSelectAllOptionChecked, onChange, options, selectedValuesWithTargets, updateTargets]
  );

  const updateTargetsForValue = (id: string, target: number | undefined) => {
    if (updateTargets) {
      updateTargets(selectedValuesWithTargets.map((val) => (val.value === id ? { ...val, target: target } : val)));
    }
  };

  const getTargetsForValue = (id: string) => {
    return selectedValuesWithTargets?.find((vwt) => vwt.value === id)?.target;
  };

  const renderOption = useCallback(
    (option: T) => {
      if (renderOptionOverride) {
        return renderOptionOverride(option);
      } else if (option?.label && typeof option.label !== "string") {
        // if the label is a node (not a string), then just return that w/o a Typography wrapper
        return <>{option.label}</>;
      } else {
        return option.label || option.id;
      }
    },
    [renderOptionOverride]
  );
  const renderOptionInMenuItem = useCallback(
    (option: T) => {
      return (renderOptionInList || renderOption)(option);
    },
    [renderOptionInList, renderOption]
  );
  const { optionWrapper, optionListWrapper } = useStylesSelectedOptionWrapper();
  const renderSelectionsForTextField = useCallback(
    (selectedIds: unknown) => {
      if (!(selectedIds instanceof Array)) {
        return "";
      }
      const selectedOptionValues = selectedIds
        .map((selectedId) => {
          const selectedOption = options?.find((opt) => opt.id === selectedId);

          return !!selectedOption ? renderOption(selectedOption) : selectedId;
        })
        .filter((val) => val !== "SELECT_ALL_OPTIONS");
      let valueToRender;
      if (renderSingleSelection) {
        valueToRender = <div className={`${optionListWrapper} ${optionWrapper}`}>{renderSingleSelection}</div>;
      } else if (requireSelectAllOption && selectedOptionValues.length === options.length) {
        valueToRender = <>{"All selected"}</>;
      } else if (selectedOptionValues.length > 0 && typeof selectedOptionValues[0] === "string") {
        valueToRender = selectedOptionValues.join(", ");
      } else {
        // If the options are React nodes, wrap them with a div that comma separates itself
        valueToRender = (
          <div className={optionListWrapper}>
            {selectedOptionValues.map((opt, index) => (
              <div className={optionWrapper} key={index}>
                {opt}
              </div>
            ))}
          </div>
        );
      }
      if (addWarningToSelections) {
        return (
          <TextFieldSelectionsContainer>
            <TextFieldSelections>{valueToRender}</TextFieldSelections>
            <TextFieldWarning>
              {addWarningToSelections && <Warning warningText={warningTextForTextFieldSelections || ""} />}
            </TextFieldWarning>
          </TextFieldSelectionsContainer>
        );
      }
      return valueToRender;
    },
    [
      addWarningToSelections,
      optionListWrapper,
      optionWrapper,
      options,
      renderOption,
      renderSingleSelection,
      requireSelectAllOption,
      warningTextForTextFieldSelections,
    ]
  );

  const [selectedOptions, unselectedOptions] = partition(options, (option) =>
    selectedValues?.includes(getDropdownId(option))
  );

  const selectClasses = useStylesSelect({ variant });
  const menuClasses = useStylesMenu({ maxMenuHeight, menuWidth });
  const menuListClasses = useStylesMenuList();
  const targetTextFieldClasses = useStylesTargetText();
  const listSubheaderClasses = useStylesListSubheader();
  const expandMoreClasses = useStylesExpandMore();
  const selectProps: SelectProps = {
    classes: selectClasses,
    multiple: true,
    value: selected || [],
    onChange: internalOnChange,
    renderValue: renderSelectionsForTextField,
    IconComponent: () => <ExpandMore className={expandMoreClasses.expandMoreIcon} />,
    onOpen: (evt: React.ChangeEvent<any>) => {
      if (onMenuOpen) {
        onMenuOpen(evt);
      }
    },
    onClose: (evt: React.ChangeEvent<any>) => {
      if (onMenuClose) {
        onMenuClose(evt);
      }
    },
    MenuProps: {
      classes: menuClasses,
      anchorOrigin: { vertical: "bottom", horizontal: "left" },
      getContentAnchorEl: null,
      PaperProps: {
        elevation: 1,
      },
      MenuListProps: {
        classes: menuListClasses,
      },
    },
    ...SelectPropsOverrides,
  };

  if (labelId) {
    // Only set this prop if it's provided - otherwise breaks the internal label association
    selectProps.labelId = labelId;
  }

  const numSelectedDisplay = `(${numSelected} selected)`;

  // Set up label text:
  let labelActual: React.ReactNode;
  // Display alternative label if there are any options selected
  if (labelAltSelected && numSelected > 0) {
    labelActual = labelAltSelected;
  } else {
    labelActual = label;
  }
  // Stick on '(N selected)' to label
  if (numSelected > 0 && !omitNumberSelectedInLabel) {
    labelActual = (
      <>
        {labelActual} {numSelectedDisplay}
      </>
    );
  }

  return (
    <TextField
      fullWidth
      value={""}
      label={labelActual}
      {...props}
      select
      onChange={internalOnChange}
      SelectProps={selectProps}
    >
      {requireSelectAllOption && (
        <CohereMenuItem key={"SELECT_ALL_OPTIONS"} value={"SELECT_ALL_OPTIONS"} withCheckbox>
          Select all
        </CohereMenuItem>
      )}
      {selectedOptions.length && (
        <ListSubheader classes={listSubheaderClasses} aria-disabled disableSticky>
          <Caption color="textPrimary">Selected ({selectedOptions.length})</Caption>
          {showTarget && <Caption color="textPrimary">Target</Caption>}
        </ListSubheader>
      )}
      {selectedOptions.map((option: T) => (
        <SelectedMenuItem key={option.id} value={option.id} withCheckbox selected disableRipple={showTarget}>
          <OptionLabel>{renderOptionInMenuItem(option)}</OptionLabel>
          {optionIdsWithWarning?.includes(getDropdownId(option)) && (
            <Warning warningText={warningTextForOptions || ""} />
          )}
          {showTarget && (
            <TextField
              classes={targetTextFieldClasses}
              type="number"
              defaultValue={getTargetsForValue(option.id)}
              onChangeValue={(val) => {
                updateTargetsForValue(option.id, val ? Number(val) : undefined);
              }}
              onClick={(event) => event.stopPropagation()}
              InputProps={{
                endAdornment: <InputAdornment position="end">{option.endAdornmentContent || "%"}</InputAdornment>,
              }}
            />
          )}
        </SelectedMenuItem>
      ))}
      {selectedOptions.length && (
        <ListSubheader classes={listSubheaderClasses} aria-disabled disableSticky>
          <Caption color="textPrimary">Unselected ({unselectedOptions.length})</Caption>
        </ListSubheader>
      )}
      {unselectedOptions.map((option: T) => (
        <CohereMenuItem style={{ width: menuWidth }} key={option.id} value={option.id} withCheckbox>
          {renderOptionInMenuItem(option)}
        </CohereMenuItem>
      ))}
      {!options.length && (
        <CohereMenuItem key="NO_RESULTS" value="" dense>
          No options available.
        </CohereMenuItem>
      )}
    </TextField>
  );
}

function hasSameItems<T>(a: T[], b: T[]) {
  try {
    return JSON.stringify(a.sort()) === JSON.stringify(b.sort());
  } catch (e) {
    return false;
  }
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const TextFieldSelections = styled("div")({
  width: "90%",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const TextFieldWarning = styled("div")(({ theme }) => ({
  marginTop: -theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SelectedMenuItem = styled(CohereMenuItem)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const TextFieldSelectionsContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const OptionLabel = styled("div")({
  flexGrow: 2,
});
