import React, { useState, useCallback, useMemo } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { createStyles, makeStyles, styled, Theme } from "@material-ui/core/styles";
import MuiTextField, { TextFieldProps } from "@material-ui/core/TextField";
import { OutlinedInputProps } from "@material-ui/core/OutlinedInput";
import { colorsLight, colorsDark } from "../../themes/colors";
import uniqueId from "lodash/uniqueId";
import { IconButton, InputAdornment } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

export const useStylesLabel = makeStyles((theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  const color = isDarkTheme ? colorsDark.font.secondary : colorsLight.font.light;
  return createStyles({
    root: {
      ...theme.typography.body1,
      color: ({ warning }: TextFieldStyleProps) => (warning ? theme.palette.warning.dark : color),
      "&$focused": {
        color,
      },
      "&$disabled": {
        color: isDarkTheme ? colorsDark.gray.divider : colorsLight.font.light,
      },
      "&$error": {
        color: theme.palette.error.dark,
      },
      /* So label doesn't overflow container */
      whiteSpace: "nowrap",
      width: "90%",
      textAlign: "left",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    focused: {},
    error: {},
    disabled: {},
  });
});

const useStylesHelper = makeStyles((theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  const color = isDarkTheme ? colorsDark.font.secondary : colorsLight.black.mediumEmphasis;
  const errorColor = isDarkTheme ? theme.palette.error.main : theme.palette.error.dark;
  return createStyles({
    root: {
      color: ({ warning, warningHelperText }: TextFieldStyleProps) =>
        warning || warningHelperText ? theme.palette.warning.dark : color,
      marginTop: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
      "&$error": { color: errorColor },
      "&$disabled": { color },
    },
    error: {},
    disabled: {},
  });
});

interface TextFieldStyleProps {
  forceFocus?: boolean;
  warning?: boolean;
  warningHelperText?: boolean;
  noLabelSpace?: boolean;
  dataPublic?: boolean;
}

export const useStylesInput = makeStyles((theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  const colors = isDarkTheme ? colorsDark : colorsLight;
  const borderColor = colors.gray.divider;
  const borderColorEmphasis = isDarkTheme ? "rgba(3, 171, 195, 0.8)" : theme.palette.primary.main;
  const inputColor = isDarkTheme ? colors.white.highEmphasis : colors.font.main;
  const disabledBackgroundColor = isDarkTheme ? colors.background.input : colors.gray.light;
  return createStyles({
    root: {
      "&.MuiFilledInput-root": {
        border: ({ forceFocus }: TextFieldStyleProps) => `1px solid ${forceFocus ? borderColorEmphasis : borderColor}`,
        borderColor: ({ warning, forceFocus }: TextFieldStyleProps) =>
          warning ? theme.palette.warning.dark : forceFocus ? borderColorEmphasis : borderColor,
        overflow: "hidden",
        borderRadius: 4,
        backgroundColor: colors.background.input,
        transition: theme.transitions.create(["border-color"]),
        ...(isDarkTheme ? { caretColor: colors.primary.main } : {}),
        "&:hover": {
          backgroundColor: colors.background.input,
          borderColor: ({ warning }: TextFieldStyleProps) =>
            warning ? theme.palette.warning.dark : borderColorEmphasis,
        },
        "&$error": {
          backgroundColor: colors.background.input,
          borderColor: isDarkTheme ? theme.palette.error.main : theme.palette.error.dark,
        },
        // Placing focus styles after error so that it takes priority and current focus can be seen.
        "&$focused": {
          backgroundColor: colors.background.input,
          borderColor: borderColorEmphasis,
        },
        "&$disabled": {
          backgroundColor: disabledBackgroundColor,
          borderColor,
        },
        "&$disabled$error": {
          backgroundColor: disabledBackgroundColor,
        },
        "&.MuiFilledInput-multiline": {
          paddingTop: ({ noLabelSpace }: TextFieldStyleProps) => (noLabelSpace ? "10px" : "27px"),
        },
      },
      "&.MuiTypography": {
        width: "100%",
      },
    },
    focused: {},
    disabled: {
      "& input": {
        cursor: "not-allowed",
      },
    },
    error: {},
    input: {
      color: inputColor,
    },
  });
});

export interface CohereTextFieldProps extends TextFieldStyleProps {
  /** The value is provided as the first argument. */
  clearable?: boolean;
  onChangeValue?: (arg0: string) => any;
  /** Style overrides for label */
  labelClasses?: Record<string, string>;
  inputClasses?: Record<string, string>;
  noLabelSpace?: boolean;
  dataPublic?: boolean;
}

// Temporary export for storybook documentation
export function TextFieldForProps(props: CohereTextFieldProps) {}

export type Props = CohereTextFieldProps & Omit<TextFieldProps, "FormHelperTextProps" | "css">;

const TextField = React.forwardRef(
  (
    {
      children,
      onChange,
      onChangeValue,
      InputProps,
      forceFocus,
      warning,
      warningHelperText,
      labelClasses: additionalLabelClasses,
      inputClasses: additionalInputClasses,
      InputLabelProps = {},
      /**
       * Certain components such as SecondarySingleSelectDropdown rely on the 'standard' option of the `variant` prop.
       * No styling for Textfield 'standard' variant had been implemented at time of writing.
       * Consider how future implementation of Textfield 'standard' variant affects other components.
       */
      variant,
      noLabelSpace,
      dataPublic = false,
      clearable,
      ...props
    }: Props,
    ref: any
  ) => {
    const [fallbackId] = useState(() => uniqueId("TextField"));
    const onChangeCallback = useCallback(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange?.(e);
        onChangeValue?.(e.target.value);
      },
      [onChange, onChangeValue]
    );
    const labelClasses = { ...useStylesLabel({ warning }), ...additionalLabelClasses };
    const helperClasses = useStylesHelper({ warning, warningHelperText });
    const inputClasses = { ...useStylesInput({ forceFocus, warning, noLabelSpace }), ...additionalInputClasses };
    const dataPublicProps = dataPublic ? { "data-public": true } : {};
    const endAdornment = useMemo(
      () => (
        <>
          <CustomIconButton
            aria-label="Clear"
            title="Clear"
            disableRipple
            onClick={(event) => {
              event.stopPropagation();
              onChangeValue?.("");
            }}
          >
            <ClearIcon />
          </CustomIconButton>
        </>
      ),
      [onChangeValue]
    );
    return (
      <MuiTextField
        // ensure we specify an ID, so that accessibility attributes connecting label-input-helperText are generated.
        // A custom ID can still be passed in props and will override this.
        id={fallbackId}
        // if there is no label to display, adjust the styles to center the input text
        hiddenLabel={!props.label}
        {...props}
        onChange={onChangeCallback}
        FormHelperTextProps={{ classes: helperClasses }}
        InputLabelProps={{ ...InputLabelProps, classes: labelClasses }}
        InputProps={
          {
            classes: inputClasses,
            disableUnderline: true,
            endAdornment: clearable ? (
              <CustomInputAdornment position="end">{endAdornment}</CustomInputAdornment>
            ) : (
              <></>
            ),
            ...InputProps,
          } as Partial<OutlinedInputProps>
        }
        variant={variant || "filled"}
        ref={ref}
        {...dataPublicProps}
      >
        {children}
      </MuiTextField>
    );
  }
);

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CustomInputAdornment = styled(InputAdornment)(({ theme }) => ({
  position: "relative",
  width: theme.spacing(4),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.type === "dark" ? colorsDark.gray.light : colorsLight.font.secondary,
  width: theme.spacing(4),
  height: theme.spacing(4),
  position: "absolute",
}));

export default TextField;
