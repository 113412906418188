import React, { ReactNode, useEffect } from "react";
import { useAddUserActivity, UserActivity } from "@coherehealth/core-platform-api";

export type ImpressionTrackingProps = Omit<UserActivity, "type">;

interface Props extends ImpressionTrackingProps {
  children: ReactNode;
}

export default function ImpressionTracking({ children, ...props }: Props) {
  const { mutate } = useAddUserActivity({});

  useEffect(() => {
    mutate({ ...props, type: "IMPRESSION" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>{children}</div>;
}
