import { Server } from "miragejs";
import * as customDb from "./db";
import customRoutes from "./routes";
import { ApplicationSerializer } from "./server";

const models = {};
const factories = {};
const seedScenarios: Array<(server: Server) => void> = [];
const serializers = { application: ApplicationSerializer };
const loadDb = function () {
  Object.values(customDb).forEach((dbConfig: any) => {
    // mirage.db.ts exports an object containing models, factories, scenarios, and serializers
    if (dbConfig.models) {
      Object.assign(models, dbConfig.models);
    }
    if (dbConfig.factories) {
      Object.assign(factories, dbConfig.factories);
    }
    if (
      dbConfig.scenarios &&
      dbConfig.activeScenario &&
      dbConfig.scenarios.hasOwnProperty(dbConfig.activeScenario)
    ) {
      seedScenarios.push((dbConfig.scenarios as any)[dbConfig.activeScenario]);
    }
    if (dbConfig.serializers) {
      Object.assign(serializers, dbConfig.serializers);
    }
  });
};

const loadAllRoutes = function () {
  const originalEncodeURIComponent = window.encodeURIComponent;
  window.encodeURIComponent = function (input) {
    // special case: don't escape route params like ":id" when registering mirage routes
    if (typeof input === "string" && input?.indexOf(":") === 0) {
      return input;
    }
    return originalEncodeURIComponent.apply(window, [input]);
  };

  customRoutes.forEach((route) => {
    // each mirage.routes.ts exports a function to register the route
    route();
  });

  window.encodeURIComponent = originalEncodeURIComponent;
};

const startServer: (ops: {
  urlPrefix: string;
  passthrough: string[];
}) => Server = function ({ urlPrefix, passthrough }) {
  window.mirageServer = new Server({
    environment: process.env.NODE_ENV,
    models,
    factories,
    seeds(server) {
      seedScenarios.forEach((scenario) => {
        scenario(server);
      });
    },
    serializers,
    routes() {
      this.urlPrefix = urlPrefix;
      // Allow unhandled requests to pass through
      passthrough.forEach((p) => {
        this.passthrough(p);
      });
    },
  });
  window.mirageRouteConfigs = {};
  return window.mirageServer;
};

const getGlobalMirageRouteConfigs = function () {
  return window.mirageRouteConfigs;
};
const getGlobalMirageServer = function () {
  return window.mirageServer;
};

export {
  customDb,
  startServer,
  loadDb,
  loadAllRoutes,
  getGlobalMirageRouteConfigs,
  getGlobalMirageServer,
};
