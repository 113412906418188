import React, { useContext, useEffect, useState } from "react";

import { useAuthorized } from "authorization";
import { UserContext } from "UserContext";

import ManageSingleProviderOrg from "./OrganizationDetails/ManageSingleProviderOrg";
import { useGetOrganizations } from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";

export default function MyOrganizationPage() {
  const [userId, setUserId] = useState<string>();
  const canViewPage = useAuthorized("MY_ORGANIZATION_PAGE");
  const { getUser } = useContext(UserContext);

  // Makes a getOrganizations query, for a backOfficeAdmin user this will only return their own organization
  const {
    data: orgsInfo,
    loading: loadingOrgInfo,
    refetch: refetchOrgInfo,
    error: getOrgError,
  } = useGetOrganizations({});

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (getOrgError) {
      enqueueSnackbar(`There was an error getting information about this organization: ${getOrgError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, getOrgError]);

  useEffect(() => {
    getUser()?.then((user) => {
      setUserId(user.sub);
    });
  }, [getUser, setUserId]);

  const singleOrgInfo = orgsInfo && orgsInfo.length ? orgsInfo[0] : null;
  if (!canViewPage || !singleOrgInfo) {
    return null;
  }

  return (
    <ManageSingleProviderOrg
      userId={userId || ""}
      org={singleOrgInfo}
      loading={loadingOrgInfo}
      refetchOrg={refetchOrgInfo}
      internalUser={false}
    />
  );
}
