import { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import HeaderContainer from "../AppHeader/HeaderContainer";
import routes from "../../routes";
import {
  useMuiContainerStyles,
  queueMgmtBaseUrl,
  useFeature,
  H3,
  containerSmallBreakpoint,
  useGetServiceRequestByIdWithFallback,
} from "@coherehealth/common";
import { headerHeight, slimFaxHeaderHeight } from "util/StyleConstants";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles } from "@material-ui/core/styles";
import {
  ServiceRequestUpdatePayload,
  useCreateServiceRequestAttachment,
  useUpdateAuthorizationById,
  useUpdateServiceRequest,
  ServiceRequestResponse,
  FaxAttachment,
} from "@coherehealth/core-platform-api";
import { generatePath, useMatch } from "react-router-dom";
import { useSnackbar } from "notistack";
import RedesignServiceRequestSummary from "../PatientSummary/ServiceRequestSummaryCard";
import Dialog from "@material-ui/core/Dialog";
import DocumentViewer from "components/DocumentViewer";
import FaxServiceEditFooter from "./FaxServiceEditFooter";
import { useGetServiceCase, useCompleteServiceCase } from "@coherehealth/qm-api";
import config from "api/config";

import DetailsPrintoutProvider from "../ServiceRequest/DetailsPrintoutProvider";
import useGetFacilityBasedRequestConfigurationByPayer, {
  useSetScrubTinsContextByHealthPlanConfiguration,
} from "hooks/useGetFeatureConfigurations";
import { updateExternalReferenceIdsFromServiceCase } from "util/serviceRequest";

import {
  activityContextFromServiceRequest,
  activitySnapshotFromServiceRequest,
  useTrackUserInteraction,
} from "util/userActivityTracker";
import { FaxAttachmentContext } from "components/DocumentViewer/FaxAttachment/FaxAttachmentContext";
import { useGetFaxAttachmentFromUrl } from "components/AuthBuilder/common";
import ScrubTinsContextProvider from "components/ScrubTinsContext";
import useSearchAndLocation from "hooks/useSearchAndLocation";
import FaxHeader from "components/DocumentViewer/FaxAttachment/FaxAuthBuilderPage/FaxHeader";
import { useIsFaxEditSRWorkflow } from "util/attachmentUtil";
import { usePrevious } from "@react-hookz/web";
import { getWorkFlowStep } from "util/workflowUtils";
import { useAttachmentTatValidation } from "components/DocumentViewer/FaxAttachment/AttachToServiceRequest/hooks/useAttachmentTatValidation";
import TatConfirmationModal from "components/DocumentViewer/FaxAttachment/AttachToServiceRequest/TatConfirmationModal";

interface StyleProps {
  isFaxEditSRFlow?: boolean;
}

const useStyles = makeStyles((theme) => ({
  mainContent: {
    height: "100%",
    paddingTop: ({ isFaxEditSRFlow }: StyleProps) =>
      isFaxEditSRFlow ? theme.spacing(10) : theme.spacing(5) + headerHeight,
    paddingBottom: ({ isFaxEditSRFlow }: StyleProps) => (isFaxEditSRFlow ? theme.spacing(15.75) : theme.spacing(10)),
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
}));

interface HeaderProps {
  sideBySideEditSrFaxIntakeWorkflow?: boolean;
  serviceRequest?: ServiceRequestResponse;
}
function ServiceRequestPageHeader({ sideBySideEditSrFaxIntakeWorkflow, serviceRequest }: HeaderProps) {
  const classes = useStyles({});
  return sideBySideEditSrFaxIntakeWorkflow ? (
    <>
      <FaxHeader
        height={slimFaxHeaderHeight}
        width={containerSmallBreakpoint}
        title={"Edit service request"}
        patient={serviceRequest?.patient}
      />
    </>
  ) : (
    <HeaderContainer height={headerHeight} justifyContentVal="center">
      <div className={classes.title}>
        <H3>
          Authorization #{serviceRequest?.authNumber ?? "--"} • Tracking #{serviceRequest?.cohereId}
        </H3>
      </div>
    </HeaderContainer>
  );
}
function ServiceRequestPageContent() {
  const sideBySideEditSrFaxIntakeWorkflow = useFeature("sideBySideEditSrFaxIntakeWorkflow");
  const matchServiceRequest = useMatch(routes.SERVICE_REQUEST);
  const matchEditSideBySide = useMatch(routes.EDIT_SR_SIDE_BY_SIDE);
  const smartOnFhirMatch = useMatch(routes.SMART_ON_FHIR_SERVICE_REQUEST);
  const faxEditMatch = useMatch(routes.EDIT_SERVICE_REQUEST_FAX);
  const isInSideBySideWorkflow = sideBySideEditSrFaxIntakeWorkflow && Boolean(matchEditSideBySide);

  const { location, search } = useSearchAndLocation();
  const isFaxEditSRFlow = useIsFaxEditSRWorkflow(location);
  const queueId = search.get("queueId") ?? undefined;

  const serviceRequestId =
    matchServiceRequest?.params.serviceRequestId ||
    matchEditSideBySide?.params.serviceRequestId ||
    smartOnFhirMatch?.params.serviceRequestId ||
    faxEditMatch?.params.serviceRequestId ||
    "";
  const caseId = faxEditMatch?.params.caseId || matchEditSideBySide?.params.caseId;
  const faxData: FaxAttachment | null = useGetFaxAttachmentFromUrl();
  const faxId = faxData?.id || "";
  const [showDetailsPrintout, setShowDetailsPrintout] = useState(false);
  const [updatedServiceRequestPayload, setUpdatedServiceRequestPayload] = useState<ServiceRequestUpdatePayload>();
  const [isEditView, setIsEditView] = useState(getWorkFlowStep() === "EDIT_VIEW");
  const [serviceRequest, setServiceRequest] = useState<ServiceRequestResponse>();
  const trackUserActivityInteraction = useTrackUserInteraction();

  if (!serviceRequestId) {
    throw new Error("No serviceRequestId found in route");
  }

  const { enqueueSnackbar } = useSnackbar();

  const {
    data: serviceRequestData,
    loading: serviceRequestLoading,
    error: serviceRequestError,
    refetch: refetchServiceRequest,
  } = useGetServiceRequestByIdWithFallback({
    id: serviceRequestId,
  });

  const serviceRequestNoEdits = usePrevious(serviceRequestData);

  const {
    mutate: uploadFax,
    loading: uploadingFax,
    error: faxUploadError,
  } = useCreateServiceRequestAttachment({
    id: serviceRequest?.id ?? "",
    requestOptions: { headers: { Accept: "application/json" } },
  });

  const {
    refetch: refetchQmCase,
    loading: serviceCaseLoading,
    error: getServiceCaseError,
  } = useGetServiceCase({
    id: "",
    base: `${config.QM_SERVICE_API_URL}`,
    lazy: true,
  });

  const {
    mutate: updateServiceCase,
    loading: updatingServiceCase,
    error: updateCaseError,
  } = useCompleteServiceCase({
    id: caseId ?? "",
    base: `${config.QM_SERVICE_API_URL}`,
    onMutate: () => {
      window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${caseId}`);
    },
  });

  const {
    mutate: patch,
    loading: updateRequestLoading,
    error: updateRequestError,
  } = useUpdateServiceRequest({
    id: serviceRequest?.id ?? "",
  });

  const {
    mutate: patchAuthorization,
    loading: patchAuthorizationLoading,
    error: patchAuthorizationError,
  } = useUpdateAuthorizationById({ id: "" });

  const healthPlanName = serviceRequest?.healthPlanName ?? "";
  const encounterType = serviceRequest?.encounterType ?? undefined;
  const requestTiming = serviceRequest?.requestTiming ?? undefined;

  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName,
    encounterType,
    requestTiming,
    delegatedVendorName: serviceRequest?.delegatedVendor,
  });

  useSetScrubTinsContextByHealthPlanConfiguration({
    healthPlanName: healthPlanName ?? "",
  });

  useEffect(() => {
    if (serviceRequestData && !serviceRequestLoading) {
      setServiceRequest(serviceRequestData);
    }
  }, [serviceRequestData, serviceRequestLoading, setServiceRequest]);

  useEffect(() => {
    if (serviceRequestError) {
      enqueueSnackbar(`Error loading service request: ${serviceRequestError.message}`, { variant: "error" });
    }
  }, [serviceRequestError, enqueueSnackbar]);

  useEffect(() => {
    if (faxUploadError) {
      enqueueSnackbar(`Error attaching request to fax: ${faxUploadError.message}`, { variant: "error" });
    }
  }, [faxUploadError, enqueueSnackbar]);

  useEffect(() => {
    if (updateCaseError) {
      enqueueSnackbar(`Could not update the case: ${updateCaseError.message}`, { variant: "error" });
    } else if (getServiceCaseError) {
      enqueueSnackbar(`Could not get case: ${getServiceCaseError.message}`, { variant: "error" });
    }
  }, [updateCaseError, getServiceCaseError, enqueueSnackbar]);

  useEffect(() => {
    if (updateRequestError) {
      enqueueSnackbar(`Error updating service request: ${updateRequestError.message}`, { variant: "error" });
    }
  }, [updateRequestError, enqueueSnackbar]);

  useEffect(() => {
    if (patchAuthorizationError) {
      enqueueSnackbar(`Error updating service request: ${patchAuthorizationError.message}`, { variant: "error" });
    }
  }, [patchAuthorizationError, enqueueSnackbar]);

  const containerClasses = useMuiContainerStyles();
  const classes = useStyles({ isFaxEditSRFlow });

  const serviceRequests = serviceRequest ? [serviceRequest] : [];
  const { showTatConfirmationModal, handleCancelTatChange, handleConfirmTatChange, handleConfirmWithTatValidation } =
    useAttachmentTatValidation(serviceRequests);

  const handleConfirmTatChangeAndUpload = async () => {
    await handleConfirmWithTatValidation(onDoneButtonClicked, faxData?.dateCreated);
  };

  return (
    <FaxAttachmentContext.Provider value={{ faxDbId: faxId, caseId: caseId, queueId, faxAttachment: faxData }}>
      <ServiceRequestPageHeader
        sideBySideEditSrFaxIntakeWorkflow={isInSideBySideWorkflow}
        serviceRequest={serviceRequest}
      />
      <Container classes={containerClasses} data-testid={`service-request-summary-${serviceRequestId}`}>
        <div className={classes.mainContent}>
          {Boolean(serviceRequestError ?? serviceRequestLoading) && <CircularProgress />}
          {serviceRequest && (
            <RedesignServiceRequestSummary
              onEdit={refetchServiceRequest}
              serviceRequest={serviceRequest}
              onShowSummaryPrintout={() => {
                setShowDetailsPrintout(true);
              }}
              initialIsExpanded={true}
              isEditView={isEditView}
              setIsEditView={setIsEditView}
              setUpdatedServiceRequestPayload={setUpdatedServiceRequestPayload}
              setServiceRequest={setServiceRequest}
              isFaxEdit={!!faxId && !!caseId}
              isFaxEditSRFlow={isFaxEditSRFlow}
            />
          )}
        </div>
        {Boolean(showDetailsPrintout && serviceRequest) && (
          <Dialog fullScreen keepMounted open={showDetailsPrintout} onClose={() => setShowDetailsPrintout(false)}>
            <DetailsPrintoutProvider serviceRequest={serviceRequest!}>
              {({ url, loading }) => (
                <DocumentViewer
                  documentInfo={{
                    name: `CohereServiceRequest_${serviceRequest?.cohereId}.pdf`,
                    contentType: "application/pdf",
                  }}
                  loading={loading}
                  url={url || undefined}
                  handleClose={() => setShowDetailsPrintout(false)}
                  canDelete={false}
                  location={location}
                  search={search}
                />
              )}
            </DetailsPrintoutProvider>
          </Dialog>
        )}
      </Container>
      {Boolean(faxId) && (
        <FaxServiceEditFooter
          onSaveClick={handleConfirmTatChangeAndUpload}
          saveButtonDisabled={isEditView}
          cancelButtonDisabled={
            uploadingFax ||
            updateRequestLoading ||
            serviceCaseLoading ||
            updatingServiceCase ||
            patchAuthorizationLoading
          }
          onCancelClick={onCancelButtonClicked}
          showCancelButton={true}
          saveButtonLoading={
            uploadingFax ||
            updateRequestLoading ||
            serviceCaseLoading ||
            updatingServiceCase ||
            patchAuthorizationLoading
          }
          isFaxEditSRFlow={isFaxEditSRFlow}
        />
      )}
      <TatConfirmationModal
        open={showTatConfirmationModal}
        onConfirm={handleConfirmTatChange}
        onCancel={handleCancelTatChange}
      />
    </FaxAttachmentContext.Provider>
  );

  function onCancelButtonClicked() {
    //go back to the QM
    window.location.assign(
      generatePath(routes.FAX, {
        faxId: faxId,
      }).concat(`?caseId=${caseId}&queueId=${queueId}`)
    );
  }

  async function onDoneButtonClicked() {
    if (caseId) {
      const serviceCase = await refetchQmCase({
        pathParams: { id: caseId },
      });
      if (serviceCase?.caseStatus === "COMPLETE" || serviceCase?.caseStatus === "CANCELLED") {
        enqueueSnackbar("Can't update a completed case, fax not attached", { variant: "error" });
      } else {
        if (serviceRequest && updatedServiceRequestPayload) {
          const uniqueExternalReferenceIds = updateExternalReferenceIdsFromServiceCase(
            serviceRequest.externalReferenceIds,
            serviceCase
          );
          await updateServiceRequest({
            ...updatedServiceRequestPayload,
            externalReferenceIds: [...uniqueExternalReferenceIds],
          });
          if (serviceRequestNoEdits && serviceRequestNoEdits.authStatus !== "DRAFT") {
            trackUserActivityInteraction({
              event: "POST_SUBMISSION_EDIT",
              stage: "DECISIONED_AUTH",
              activityContext: activityContextFromServiceRequest(serviceRequestNoEdits),
              beforeSnapshot: activitySnapshotFromServiceRequest(serviceRequestNoEdits),
              afterSnapshot: activitySnapshotFromServiceRequest(serviceRequest),
            });
          }
        }

        //auto attach when done
        if (faxId) {
          const formData = new FormData();
          formData.set("faxId", faxId);
          await uploadFax(formData as unknown as void, { pathParams: { id: serviceRequest?.id ?? "" } });
        }

        if (caseId) {
          updateServiceCase({
            outcome: "EDIT_EXISTING_SR",
            dateCompleted: new Date().toISOString(),
            description: "Existing SR Edited",
            serviceRequests: [
              {
                serviceRequestId: serviceRequest?.id,
                status: serviceRequest?.authStatus,
                cohereId: serviceRequest?.cohereId,
              },
            ],
          });
        }
      }
    } else {
      enqueueSnackbar("Can't get the case to complete the fax attachment", { variant: "error" });
    }
  }

  async function updateServiceRequest(updatedServiceRequestPayload: ServiceRequestUpdatePayload) {
    await patch(
      { ...updatedServiceRequestPayload },
      {
        queryParams: {
          skipAuthorizationUpdate: false,
          integrateServiceRequest: true,
          integrateAttachments: true,
        },
      }
    );

    if (
      facilityBasedFeatureEnabled &&
      Boolean(updatedServiceRequestPayload?.dischargedTo) &&
      serviceRequest?.authorization?.id
    ) {
      await patchAuthorization(
        { dischargedTo: updatedServiceRequestPayload?.dischargedTo },
        { pathParams: { id: serviceRequest?.authorization?.id } }
      );
    }
  }
}

export default function ServiceRequestPage() {
  return (
    <ScrubTinsContextProvider>
      <ServiceRequestPageContent />
    </ScrubTinsContextProvider>
  );
}
