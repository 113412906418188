import { useGetServiceRequestAuditHistory } from "@coherehealth/audit-api";
import { Body2, Caption, Column } from "@coherehealth/common";
import config from "api/config";
import { useSnackbar } from "notistack";
import { ReactNode, useEffect, useState } from "react";
import { processAuditHistoryData } from "./AuthStatusHistoryUtil";
import { AbstractSort } from "@coherehealth/core-platform-api";

export type UseAuthStatusHistoryProps = {
  loadingAuthStatusHistory: boolean;
  authStatusHistory: string[][];
  setAuthStatusHistory: React.Dispatch<React.SetStateAction<string[][]>>;
  queryParams: AuthStatusHistoryQueryPayload;
  setQueryParams: React.Dispatch<React.SetStateAction<AuthStatusHistoryQueryPayload>>;
  tableColumns: Column<string[][]>[];
};

interface AuthStatusHistoryProps {
  serviceRequestId: string;
}

export interface AuthStatusHistoryQueryPayload {
  max?: number;
  offset?: number;
  sortBy?: AbstractSort[];
}

const useAuthStatusHistory = ({ serviceRequestId }: AuthStatusHistoryProps): UseAuthStatusHistoryProps => {
  const [queryParams, setQueryParams] = useState<AuthStatusHistoryQueryPayload>({
    max: 11,
    offset: 0,
    sortBy: [
      {
        sortOrder: "DESC",
        key: "changeDate",
      },
    ],
  });
  const [authStatusHistory, setAuthStatusHistory] = useState<string[][]>([]);

  const {
    data,
    error,
    loading: loadingAuthStatusHistory,
  } = useGetServiceRequestAuditHistory({
    base: `${config.AUDIT_SERVICE_API_URL}`,
    srId: serviceRequestId,
    queryParams: { embed_users: "true" },
  });

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (error) {
      enqueueSnackbar(`Error retrieving status change history: ${error.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, error]);

  let authHistoryTableData;
  useEffect(() => {
    if (!loadingAuthStatusHistory) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      authHistoryTableData = processAuditHistoryData(data || []);
      setAuthStatusHistory(authHistoryTableData);
    }
  }, [loadingAuthStatusHistory, data, setAuthStatusHistory]);

  const columnValueConfig = (columnName: string): ((authStatusHistory: string[][]) => ReactNode) => {
    switch (columnName) {
      case "changeDate":
        return (authStatusHistory: string[][]) => authStatusHistory[0];
      case "field":
        return (authStatusHistory: string[][]) => {
          return <Body2>{authStatusHistory[1]}</Body2>;
        };
      case "originalValue":
        return (authStatusHistory: string[][]) => authStatusHistory[2];
      case "newValue":
        return (authStatusHistory: string[][]) => authStatusHistory[3];
      case "changeReason":
        return (authStatusHistory: string[][]) => authStatusHistory[4];
      case "changedBy":
        return (authStatusHistory: string[][]) => authStatusHistory[5];
      default:
        return (authStatusHistory: string[][]) => authStatusHistory[0];
    }
  };

  const tableColumns: Column<string[][]>[] = [
    {
      label: "Change date",
      name: "changeDate",
      value: columnValueConfig("changeDate"),
      width: "30%",
      themedPaddingRight: 1,
      header: <Caption color="textSecondary">Change date</Caption>,
    },
    {
      name: "field",
      value: columnValueConfig("field"),
      width: "20%",
      unSortable: true,
      themedPaddingRight: 2,
      header: <Caption color="textSecondary">Field</Caption>,
    },
    {
      name: "originalValue",
      value: columnValueConfig("originalValue"),
      width: "20%",
      unSortable: true,
      themedPaddingRight: 1,
      header: <Caption color="textSecondary">Original value</Caption>,
    },
    {
      name: "newValue",
      value: columnValueConfig("newValue"),
      width: "25%",
      unSortable: true,
      themedPaddingRight: 1,
      header: <Caption color="textSecondary">New value</Caption>,
    },
    {
      name: "changeReason",
      value: columnValueConfig("changeReason"),
      width: "25%",
      unSortable: true,
      themedPaddingRight: 3,
      header: <Caption color="textSecondary">Change reason</Caption>,
    },
    {
      name: "changedBy",
      value: columnValueConfig("changedBy"),
      width: "20%",
      unSortable: true,
      themedPaddingRight: 2,
      header: <Caption color="textSecondary">Changed by</Caption>,
    },
  ];

  return {
    loadingAuthStatusHistory,
    authStatusHistory,
    setAuthStatusHistory,
    queryParams,
    setQueryParams,
    tableColumns,
  };
};

export default useAuthStatusHistory;
