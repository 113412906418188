import React from "react";
import { AuthStatus } from "@coherehealth/core-platform-api";
import PrintIcon from "@material-ui/icons/Print";
import { warn } from "logger";
import utilPrint from "util/print";
import { isAuthStatus } from "util/serviceRequest";
import { formatDateStr, pendingReasonMessage, isPendingReason, COHERE_NAME } from "@coherehealth/common";

import ApprovedServiceRequestImage from "../../images/ApprovedServiceRequestImage";
import WithdrawnServiceRequestImage from "./WithdrawnServiceRequestImage";
import DeniedServiceRequestImage from "./DeniedServiceRequestImage";
import PendingServiceRequestImage from "./PendingServiceRequestImage";
import { ServiceRequestStatus } from "../checkStatusApi";

import StatusSummaryPdfProvider from "../Printout/StatusSummaryPdfProvider";

interface Props {
  result: ServiceRequestStatus;
}

export default function ServiceRequestResult({ result }: Props) {
  const {
    authStatus,
    authNumber,
    intakeTimestamp,
    cohereId,
    pendingReason,
    procedureCodes,
    startDate,
    endDate,
    delegatedVendor,
    healthPlanName,
    withdrawnReason,
  } = result;

  if (!isAuthStatus(authStatus)) {
    warn(`Unable to display result card for status ${authStatus}`);
    return null;
  }

  return (
    <div className="UnauthorizedCheckStatusResultCard-card">
      {authStatus === "APPROVED" ? (
        <ApprovedServiceRequestImage aria-label="Request is approved" />
      ) : authStatus === "WITHDRAWN" ? (
        <WithdrawnServiceRequestImage aria-label="Request has been withdrawn" />
      ) : authStatus === "DENIED" ? (
        <DeniedServiceRequestImage aria-label="Request has been denied" />
      ) : authStatus === "PARTIALLY_APPROVED" ? (
        <DeniedServiceRequestImage aria-label="Request has only been partially approved" />
      ) : authStatus === "PENDING_EXTERNAL_DETERMINATION" && delegatedVendor && delegatedVendor !== COHERE_NAME ? (
        <PendingServiceRequestImage aria-label="In delegated vendor review" />
      ) : (
        <PendingServiceRequestImage aria-label="Request is pending" />
      )}
      <div className="UnauthorizedCheckStatusResultCard-textcontainer">
        <div className="UnauthorizedCheckStatusResultCard-header">
          <h1
            className={`UnauthorizedCheckStatusResultCard-title UnauthorizedCheckStatusResultCard-${
              authStatus === "APPROVED"
                ? "success"
                : authStatus === "WITHDRAWN" || authStatus === "DRAFT"
                ? "disabled"
                : authStatus === "DENIED" || authStatus === "PARTIALLY_APPROVED"
                ? "error"
                : "warning"
            }`}
          >
            {lessAggressiveStatusCopy(delegatedVendor, healthPlanName, withdrawnReason, pendingReason)[authStatus]}
            <PendingDetail authStatus={authStatus} pendingReason={pendingReason} />
          </h1>

          <StatusSummaryPdfProvider statusResult={result}>
            {({ url }) =>
              Boolean(url) && (
                <button className="UnauthorizedCheckStatusResultCard-print-button" onClick={() => print(url)}>
                  <PrintIcon className="UnauthorizedCheckStatusResultCard-print-icon" />
                  Print
                </button>
              )
            }
          </StatusSummaryPdfProvider>
        </div>

        <div className="UnauthorizedCheckStatusResultCard-text-table">
          <div className="UnauthorizedCheckStatusResultCard-text-column">
            <div className="UnauthorizedCheckStatusResultCard-text-columnheader">Authorization #</div>
            <div
              className={`UnauthorizedCheckStatusResultCard-text-columndata UnauthorizedCheckStatusResultCard-${getColorForAuthStatus(
                authStatus
              )}`}
            >
              {authNumber ? authNumber : "Not available"}
            </div>
          </div>

          <div className="UnauthorizedCheckStatusResultCard-text-column">
            <div className="UnauthorizedCheckStatusResultCard-text-columnheader">Procedure code(s)</div>
            <div className="UnauthorizedCheckStatusResultCard-text-columndata-dynamic">
              {formatProcedureCodes(procedureCodes)}
            </div>
          </div>

          <div className="UnauthorizedCheckStatusResultCard-text-column">
            <div className="UnauthorizedCheckStatusResultCard-text-columnheader">Date(s) of service</div>
            <div className="UnauthorizedCheckStatusResultCard-text-columndata">
              {datesOfService(startDate, endDate)}
            </div>
          </div>

          <div className="UnauthorizedCheckStatusResultCard-text-column">
            <div className="UnauthorizedCheckStatusResultCard-text-columnheader">Submission date</div>
            <div className="UnauthorizedCheckStatusResultCard-text-columndata">
              {!intakeTimestamp ? "Unknown" : formatDateStr(intakeTimestamp)}
            </div>
          </div>

          <div className="UnauthorizedCheckStatusResultCard-text-column">
            <div className="UnauthorizedCheckStatusResultCard-text-columnheader">Tracking #</div>
            <div className="UnauthorizedCheckStatusResultCard-text-columndata">{cohereId}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function PendingDetail({ authStatus, pendingReason }: { authStatus?: string; pendingReason?: string }) {
  if (!pendingReason || !isPendingReason(pendingReason) || authStatus !== "PENDING") {
    return null;
  }

  return (
    <span className="UnauthorizedCheckStatusResultCard-subtitle">:&nbsp;{pendingReasonMessage(pendingReason)}</span>
  );
}

export const print = (url: string | null) => {
  if (url) {
    utilPrint(url, "pdf");
  }
};

export const getColorForAuthStatus = (authStatus: AuthStatus) =>
  authStatus === "APPROVED"
    ? "success"
    : authStatus === "WITHDRAWN" || authStatus === "DRAFT"
    ? "disabled"
    : authStatus === "DENIED" || authStatus === "PARTIALLY_APPROVED"
    ? "error"
    : "warning";

const formatProcedureCodes = (procedureCodes?: string[]) => {
  if (procedureCodes) {
    return procedureCodes.join(", ");
  }
  return "--";
};

const datesOfService = (startDateStr?: string, endDateStr?: string) => {
  // Strip time from the date string if it's there
  const startDate = startDateStr?.split("T")?.[0];
  const endDate = endDateStr?.split("T")?.[0];

  if (startDate || endDate) {
    return [formatDateStr(startDate), formatDateStr(endDate)].filter((date) => Boolean(date)).join(" - ");
  } else {
    return "--";
  }
};

/**
 * We don't need to show end users the guts of our operations
 */
export function lessAggressiveStatusCopy(
  delegatedVendor?: string,
  healthPlanName?: string,
  withdrawnReason?: string,
  pendingReason?: string
): Record<AuthStatus, string> {
  delegatedVendor = delegatedVendor === "HealthHelpV2" ? "HealthHelp" : delegatedVendor;
  return {
    DRAFT: "Draft",
    PENDING_FACILITY_VERIFICATION: "Pending",
    PENDING_EXTERNAL_DETERMINATION: pendingReason
      ? "Pending: Missing information (clinical)"
      : `Pending review by ${delegatedVendor || healthPlanName}`,
    PENDING_ASSESSMENT: "Pending",
    INTAKE: "Pending",
    PENDING: "Pending",
    WITHDRAWN:
      withdrawnReason && withdrawnReason === "INTEGRATION_FAILURE"
        ? `Processed manually by ${healthPlanName ? healthPlanName : ""}`
        : "Withdrawn",
    APPROVED: "Approved",
    NO_LONGER_NEEDED: "Approved",
    PARTIALLY_APPROVED: "Partially Approved",
    RECOMMENDED_PARTIAL_APPROVAL: "Pending",
    RECOMMENDED_DENIAL: "Pending",
    DENIED: "Denied",
    VOIDED: "Voided",
    DISMISSED: "Dismissed",
    POST_DENIAL_PEER_TO_PEER: "Pending",
  };
}
