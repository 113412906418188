import { makeStyles } from "@material-ui/core/styles";
/**
 * Style overrides for fax intake footers
 */
const useFaxFooterStyles = makeStyles((theme) => ({
  secondaryContainer: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  caption: {
    color: theme.palette.error.dark,
  },
  primaryContainer: {
    minWidth: theme.spacing(25),
  },
  primaryWrappedButton: {
    width: "124px",
  },
  fullSizeFooter: {
    display: "flex",
    flexDirection: "row",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    justifyContent: "space-between",
  },
  wrappedFooter: {
    display: "flex",
    flexDirection: "column-reverse",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  cancelButton: {
    color: theme.palette.error.dark,
  },
}));

export default useFaxFooterStyles;
