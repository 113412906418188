import MuiErrorIcon from "@material-ui/icons/Error";
import { InformativeModal } from "@coherehealth/common";
import React from "react";
import { useTheme } from "@material-ui/core";

interface AutoVerifyInformativeModalProps {
  open: boolean;
  onClose: () => void;
  headerText: string | JSX.Element;
  subHeaderText: string | JSX.Element;
  primaryButtonText: string;
  tertiaryButtonText: string;
  primaryButtonAction: () => void;
  tertiaryButtonAction: () => void;
}

export default function AutoVerifyInformativeModal({
  open,
  onClose,
  headerText,
  subHeaderText,
  primaryButtonText,
  tertiaryButtonText,
  primaryButtonAction,
  tertiaryButtonAction,
}: AutoVerifyInformativeModalProps) {
  const theme = useTheme();

  return (
    <InformativeModal
      open={open}
      onClose={onClose}
      headerText={headerText}
      subHeaderText={subHeaderText}
      primaryButtonText={primaryButtonText}
      tertiaryButtonText={tertiaryButtonText}
      primaryButtonAction={primaryButtonAction}
      tertiaryButtonAction={tertiaryButtonAction}
      icon={<MuiErrorIcon style={{ height: "48px", width: "48px", color: theme.palette.warning.dark }} />}
      dataPublic
    />
  );
}
