import { useEffect, useState } from "react";

export function fileSizeString(num: number) {
  if (num < 1024) {
    return num + " bytes";
  } else if (num >= 1024 && num < 1048576) {
    return (num / 1024).toFixed(1) + " KB";
  } else if (num >= 1048576) {
    return (num / 1048576).toFixed(1) + " MB";
  }
}

// 10MB
export const MAX_FILE_SIZE_AGADIA = 10485760;

/*
 * Collection of utilities for dealing with binary response types from OpenAPI, which are not properly recognized:
 * https://github.com/contiamo/restful-react/issues/391
 *
 * In the openapi doc, we always represent these reponses as something like
          content:
            text/csv:
              schema:
                type: string
                format: binary
 *
 * but the return type always comes back from restful-react as a nullable void
 */
type RestfulReactBinaryResponseType = null | void;

/*
 * Given a request promise (like the return value of a `refetch` return prop from a `useGet` hook),
 * return a (nullable) https://developer.mozilla.org/en-US/docs/Web/API/Blob
 */
export async function blobFromRequest(requestPromise: Promise<RestfulReactBinaryResponseType>) {
  const response = await requestPromise;
  return blobFromResponse(response);
}

/*
 * Given a response (like the `data` return prop from a `useGet` hook),
 * return a (nullable) https://developer.mozilla.org/en-US/docs/Web/API/Blob
 */
export async function blobFromResponse(response: RestfulReactBinaryResponseType) {
  if (response) {
    const blob = await (response as unknown as Response).blob();
    return blob;
  }

  return null;
}

/*
 * Given a function that returns a promise (like the `refetch` return prop from a `useGet` hook),
 * return:
 *
 * fileBlob - a (nullable) https://developer.mozilla.org/en-US/docs/Web/API/Blob
 * fileUrl - a (nullable) URL string pointing to the file contents
 */
export function useBinaryFile(requestPromiseFn: (() => Promise<RestfulReactBinaryResponseType>) | undefined) {
  const [resp, setResp] = useState<RestfulReactBinaryResponseType>();
  useEffect(() => {
    requestPromiseFn?.().then((res) => {
      setResp(res);
    });
  }, [requestPromiseFn]);
  return useBinaryFileFromResponse(resp);
}

/*
 * Given a response (like the `data` return prop from a `useGet` hook),
 * return:
 *
 * fileBlob - a (nullable) https://developer.mozilla.org/en-US/docs/Web/API/Blob
 * fileUrl - a (nullable) URL string pointing to the file contents
 */
export function useBinaryFileFromResponse(response: RestfulReactBinaryResponseType) {
  const [fileUrl, setFileUrl] = useState<string>();
  const [fileBlob, setFileBlob] = useState<Blob>();
  useEffect(() => {
    const doTheFetch = async () => {
      const blob = await blobFromResponse(response);
      if (blob) {
        const url = URL.createObjectURL(blob);
        setFileBlob(blob);
        setFileUrl(url);
        return () => URL.revokeObjectURL(url);
      }
    };

    doTheFetch();
  }, [response]);

  return { fileUrl, fileBlob };
}

export function assertIsFile(ipt: File | string | null): asserts ipt is File {
  if (!ipt || typeof ipt === "string") {
    throw new Error("Not a file");
  }
}

export function isValidPdf(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const arr = new Uint8Array(reader.result as ArrayBuffer);
      // Check if the file starts with the PDF header "%PDF"
      // Convert Uint8Array to an array of numbers
      const numbersArray = Array.from(arr.subarray(0, 4));
      const header = String.fromCharCode.apply(null, numbersArray);
      if (header !== "%PDF") {
        reject(new Error("Invalid PDF: Missing PDF header"));
        return;
      }

      // Check for the existence of common PDF structures
      const trailerIndex = arr.indexOf(116, arr.length - 128); // Search for "t" in the last 128 bytes (trailer keyword)
      if (trailerIndex === -1) {
        reject(new Error("Invalid PDF: Trailer not found"));
        return;
      }
      // If all checks pass, the PDF is considered valid
      resolve("Valid PDF");
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
}

export function base64toBlob(b64Data: string, contentType = "application/pdf"): Blob {
  const sliceSize = 512;
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export async function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });
}

export function isBase64String(str: string): boolean {
  if (!str || typeof str !== "string") {
    return false;
  }

  if (str.length % 4 !== 0) {
    return false;
  }

  const base64Regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  return base64Regex.test(str);
}
