import { SVGProps } from "react";

export default function SummaryCardDrugIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="32px" height="32px" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect y="0.5" width="32px" height="32px" rx="16" fill="black" opacity="0.04" />
      <path
        d="M22.8831 15.7493C24.6279 13.9648 24.6279 11.0877 22.8831 9.30323C21.1382 7.51872 18.325 7.51872 16.5802 9.30323L12.7344 13.2364L19.0372 19.6825L22.8831 15.7493Z"
        fill="#0291B2"
      />
      <path
        d="M12.7345 13.2148L8.88872 17.1481C7.14386 18.9326 7.14386 21.8096 8.88872 23.5941C10.6336 25.3787 13.4467 25.3787 15.1916 23.5941L19.0374 19.6609L12.7345 13.2148Z"
        fill="#89D3DD"
      />
    </svg>
  );
}
