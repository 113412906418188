import React from "react";

const NewServiceRequestIcon = () => {
  return (
    <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="104" height="104" rx="52" fill="#039EC3" fill-opacity="0.1" />
      <path d="M72.2816 30.125H29.8999V79.5557H72.2816V30.125Z" fill="#A1AAB4" />
      <path d="M62.2873 33.125H32.8989V76.5588H69.2662V40.1214L62.2873 33.125Z" fill="white" />
      <path
        d="M60.6908 24.0065H55.0446C55.0446 21.5165 53.2736 19.5 51.0817 19.5C48.8899 19.5 47.1188 21.5165 47.1188 24.0065H41.4726L39.877 34.0013H62.2689L60.6908 24.0065Z"
        fill="#212936"
      />
      <path
        d="M44.0156 40.016H40.9997V37H38.9305V40.016H35.9146V42.0851H38.9305V45.1011H40.9997V42.0851H44.0156V40.016Z"
        fill="#EA1D63"
      />
      <path d="M60.5154 48.4531H41.6479V49.8734H60.5154V48.4531Z" fill="#89D3DD" />
      <path d="M65.7759 71.1875H58.2534V72.6078H65.7759V71.1875Z" fill="#89D3DD" />
      <path d="M65.7756 53.6562H36.3872V55.0766H65.7756V53.6562Z" fill="#C2C2C2" />
      <path d="M65.7756 57.9141H36.3872V59.3344H65.7756V57.9141Z" fill="#C2C2C2" />
      <path d="M65.7756 62.1797H36.3872V63.6H65.7756V62.1797Z" fill="#C2C2C2" />
      <path d="M49.5208 66.4375H36.3872V67.8578H49.5208V66.4375Z" fill="#C2C2C2" />
      <path d="M62.2861 40.1214H69.265L62.2861 33.125V40.1214Z" fill="#F5F5F5" />
      <g clip-path="url(#clip0_1925_19493)">
        <circle cx="71.5136" cy="35.1172" r="15.6136" transform="rotate(-45 71.5136 35.1172)" fill="white" />
        <path
          d="M60.4731 24.0767C54.3677 30.1821 54.3677 40.0523 60.4731 46.1577C66.5785 52.2631 76.4487 52.2631 82.5541 46.1577C88.6595 40.0523 88.6595 30.1821 82.5541 24.0767C76.4487 17.9713 66.5785 17.9713 60.4731 24.0767ZM80.9974 33.5605V36.6739L73.0703 36.6739L73.0703 44.601L69.9569 44.601L69.9569 36.6739H62.0298V33.5605H69.9569L69.9569 25.6334L73.0703 25.6334L73.0703 33.5605L80.9974 33.5605Z"
          fill="#039EC3"
        />
      </g>
      <defs>
        <clipPath id="clip0_1925_19493">
          <rect width="31.2272" height="31.2272" fill="white" transform="translate(49.4326 35.1172) rotate(-45)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NewServiceRequestIcon;
