export const convertToTitleCase = (inputString: string): string => {
  return inputString.toLowerCase().replace(/(?:^|\s)\w/g, (match) => match.toUpperCase());
};

export const replaceUnderscoresWithSpace = (inputString: string): string => {
  return inputString.replace(/_/g, " ");
};

// Specific to Auth Category i.e LONG_TERM_CARE -> Long Term Care
export const convertToProperCaseAndFormatAuthCategory = (inputString: string): string => {
  return convertToTitleCase(replaceUnderscoresWithSpace(inputString));
};

// converts a camel case to human-readable title string ("serviceRequest" -> "Service Request")
export const camelCaseToCapitalizedWithSpaces = (input: string): string => {
  return input
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space before capital letters
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
};
