import React, { SVGProps } from "react";

export default function NoResultsServiceRequestImage(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="40" cy="40" r="40" fill="#F5F5F5" />
      <path d="M53.987 23.3633H21.3857V61.3869H53.987V23.3633Z" fill="#A1AAB4" />
      <path d="M46.2988 25.668H23.6924V59.0786H51.6672V31.0498L46.2988 25.668Z" fill="white" />
      <path
        d="M45.0712 18.654H40.728C40.728 16.7387 39.3656 15.1875 37.6796 15.1875C35.9935 15.1875 34.6312 16.7387 34.6312 18.654H30.288L29.0605 26.3424H46.2851L45.0712 18.654Z"
        fill="#384358"
      />
      <path
        d="M32.2443 30.9684H29.9243V28.6484H28.3327V30.9684H26.0127V32.5601H28.3327V34.88H29.9243V32.5601H32.2443V30.9684Z"
        fill="#EA1D63"
      />
      <path d="M44.9363 37.457H30.4229V38.5496H44.9363V37.457Z" fill="#89D3DD" />
      <path d="M48.9828 54.9492H43.1963V56.0418H48.9828V54.9492Z" fill="#89D3DD" />
      <path d="M48.9824 41.4609H26.376V42.5535H48.9824V41.4609Z" fill="#C2C2C2" />
      <path d="M48.9824 44.7383H26.376V45.8308H48.9824V44.7383Z" fill="#C2C2C2" />
      <path d="M48.9824 48.0195H26.376V49.1121H48.9824V48.0195Z" fill="#C2C2C2" />
      <path d="M36.4787 51.2969H26.376V52.3894H36.4787V51.2969Z" fill="#C2C2C2" />
      <path d="M46.2988 31.0498H51.6672L46.2988 25.668V31.0498Z" fill="#E6E9EE" />
      <circle cx="56.6346" cy="26.9978" r="9.69314" fill="white" />
      <path
        d="M56.6972 17.0547C51.1724 17.0547 46.6885 21.5386 46.6885 27.0634C46.6885 32.5882 51.1724 37.0722 56.6972 37.0722C62.222 37.0722 66.7059 32.5882 66.7059 27.0634C66.7059 21.5386 62.222 17.0547 56.6972 17.0547ZM57.6981 32.0678H55.6963V30.066H57.6981V32.0678ZM57.6981 28.0643H55.6963V22.0591H57.6981V28.0643Z"
        fill="#6E7580"
      />
    </svg>
  );
}
