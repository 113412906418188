import React, { useRef, useState } from "react";
import { makeStyles, InputLabel, Grid, SelectProps } from "@material-ui/core";
import SingleSelectDropdown, { AllSingleSelectDropdownProps } from "./SingleSelectDropdown";
import { Body1, Body2, H5 } from "../Typography";
import { DropdownOption } from "./shared";

const useLabelStyles = makeStyles((theme) => ({
  root: {
    marginRight: "4px",
  },
}));

const SecondarySingleSelectDropdown = <T extends DropdownOption>({
  label,
  labelId,
  ...props
}: AllSingleSelectDropdownProps<T>) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);

  const internalOnOpen = () => {
    setAnchorEl(anchorRef.current);
  };
  const internalOnClose = () => {
    setAnchorEl(null);
  };

  const selectProps: SelectProps = {
    MenuProps: {
      anchorOrigin: { vertical: "top", horizontal: "left" },
      transformOrigin: { vertical: 10, horizontal: "left" },
      anchorEl: anchorEl,
    },
    onOpen: internalOnOpen,
    onClose: internalOnClose,
  };

  const labelClasses = useLabelStyles();
  return (
    <Grid container alignItems="center">
      {label && (
        <InputLabel id="miniDropdownLabel" classes={labelClasses}>
          <H5> {label} </H5>
        </InputLabel>
      )}
      <SingleSelectDropdown
        fullWidth={false}
        {...props}
        variant="standard"
        labelId={(label && "miniDropdownLabel") || labelId}
        renderOption={(option) => <H5>{option.label}</H5>}
        renderSelectedOptionInList={(option) => <Body2>{option.label}</Body2>}
        renderOptionInList={(option) => <Body1>{option.label}</Body1>}
        SelectProps={selectProps}
        menuWidth={237}
        selectRef={anchorRef}
      />
    </Grid>
  );
};

export default SecondarySingleSelectDropdown;
