import NoResults from "./NoResults";
import React from "react";
import ReferralRequestCard from "./ReferralRequestCard";
import { makeStyles } from "@material-ui/core";
import { ReferralRequestSearchResponse } from "@coherehealth/core-platform-api";

interface Props {
  error: boolean;
  viewableReferralRequests?: ReferralRequestSearchResponse[]; // TODO: implement
  loading: boolean;
  displayMax: number;
}

const useStyles = makeStyles((theme) => ({
  cardList: {
    // These styles mostly help with centering the loading spinner
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
}));

export default function ReferralRequestCardList({ error, viewableReferralRequests, loading, displayMax }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.cardList}>
      {loading ? (
        <NoResults heading={"Loading your referrals"} message={"This might take a few seconds..."} />
      ) : viewableReferralRequests?.length === 0 || error ? (
        <NoResults />
      ) : (
        viewableReferralRequests?.slice(0, displayMax).map((ref_request, idx) => (
          <div key={ref_request.id}>
            <ReferralRequestCard referralRequest={ref_request}></ReferralRequestCard>
          </div>
        ))
      )}
    </div>
  );
}
