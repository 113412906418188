import React, { FunctionComponent, ComponentProps, useState, useEffect, useRef, useCallback } from "react";
import {
  Provider,
  Facility,
  Address,
  PracticeCreatePayload,
  PhoneNumber,
  Location,
  PostgridAddressVerificationResponseData,
  useGetPostgridAddressVerification,
  PostgridAddressVerificationBody,
  PostgridAddressVerificationResponse,
  ProviderFacilityCatalogue,
} from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { TextField, PrimaryButton, Body1, colorsLight, H2 } from "@coherehealth/common";
import { ProviderFacilityModalContent } from "./ProviderFacilityModalManual";
import { DialogProps, Divider } from "@material-ui/core";
import PhoneInput from "common/PhoneInput";
import UnitedStatesStateSelection from "../ProviderOrganization/UnitedStatesStateSelection";
import { Label } from "components/PatientSummary/ServiceRequestSummaryCard/ServiceRequestFormReadOnly";
import { ModalStatus } from "util/providerUtils";
import MuiDialogContent from "@material-ui/core/DialogContent";
import PostGridAddressSearchResult from "./PostGridAddressSearchResult";
import { useSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiIconButton from "@material-ui/core/IconButton";

interface Props extends Pick<DialogProps, "onClose"> {
  practice: Provider | Facility | null;
  setPractice: (practice: Provider | Facility | null) => void;
  isProvider: boolean;
  isPerfomingProviderPractice?: boolean;
  isOrderingProvider?: boolean;
  setSelection?: (params: PracticeCreatePayload, practice: Provider | Facility) => Promise<void>;
  setSelectedTin?: (practice: Provider | Facility | null, tin: string | null) => void;
  setIsEditable: (b: boolean) => void;
  onSave?(payload: PracticeCreatePayload, setEntryId: (practice: Provider | Facility | null) => void): Promise<void>;
  modalStatus: ModalStatus;
  sameProviders?: boolean;
  setBothProviders?: (provider: Provider | null, tin: string | null) => void;
  selectedTin: string | null | undefined;
  selectedLocation: Location | null | undefined;
  hideTinField?: boolean;
}

const ProviderFacilityEditTinAndAddress: FunctionComponent<Props> = ({
  practice,
  setPractice,
  isProvider,
  onSave,
  modalStatus,
  onClose = () => {},
  selectedTin,
  selectedLocation,
  isPerfomingProviderPractice,
  hideTinField = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const prefillModalContent = convertEntryToContent(practice, isProvider, modalStatus, selectedTin, selectedLocation);

  const [providerFacilityModalContent, setProviderFacilityModalContent] = useState<ProviderFacilityModalContent>({
    name: prefillModalContent.name,
    lastName: prefillModalContent?.lastName,
    firstName: prefillModalContent?.firstName,
    npi: prefillModalContent?.npi,
    tin: prefillModalContent?.tin,
    address1: prefillModalContent?.address1,
    address2: prefillModalContent?.address2,
    city: prefillModalContent?.city,
    state: prefillModalContent?.state,
    zip: prefillModalContent?.zip,
    contactNumber: prefillModalContent?.contactNumber,
    faxNumber: prefillModalContent?.faxNumber,
  });

  // Postgrid configurations
  const [showAddressResults, setShowAddressResults] = useState<boolean>(false);
  const firstGridRef = useRef<HTMLDivElement>(null);
  const [postGridAddress, setPostGridAddress] = useState<PostgridAddressVerificationResponseData | null>(null);
  const [modifiedAddress, setModifiedAddress] = useState<boolean>(false);
  const [isLoadingSuggestedAddress, setIsLoadingSuggestedAddress] = useState<boolean>(false);
  const [tinExists, setTinExists] = useState(false);
  const { mutate: verify } = useGetPostgridAddressVerification({});
  const postGridValidation = async (): Promise<void> => {
    const requestBody: PostgridAddressVerificationBody = {
      //Get user entered address
      line1: providerFacilityModalContent.address1 ? providerFacilityModalContent.address1 : undefined,
      line2: providerFacilityModalContent.address2 ? providerFacilityModalContent.address2 : undefined,
      country: "us",
      postalOrZip: providerFacilityModalContent.zip ? providerFacilityModalContent.zip : undefined,
      provinceOrState: providerFacilityModalContent.state ? providerFacilityModalContent.state : undefined,
      city: providerFacilityModalContent.city ? providerFacilityModalContent.city : undefined,
    };
    //set loading to true
    setIsLoadingSuggestedAddress(true);
    //send request
    try {
      let result: PostgridAddressVerificationResponse = await verify(requestBody);
      //set loading to false after receiving result
      setIsLoadingSuggestedAddress(false);
      if (result && result.status === "success" && result.data) {
        //if verified, we dont do anything and proceed with save
        if (result.data.status && result.data.status.toLowerCase() === "verified") {
          setModifiedAddress(false);
          setPostGridAddress(result.data);
          setShowAddressResults(true);
          setTimeout(() => {
            if (firstGridRef.current) {
              firstGridRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
            }
          }, 0);
          return;
        }
        //if address was corrected or address verification failed, display suggestion or no results.
        setPostGridAddress(result.data);
        //Show results
        setShowAddressResults(true);
        //Timeout becauase I was having to double click to trigger scrolling
        setTimeout(() => {
          if (firstGridRef.current) {
            firstGridRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 0);
      }
    } catch (e) {
      let errorMessage = "";
      if (typeof e === "string") {
        errorMessage = e.toUpperCase();
      } else if (e instanceof Error) {
        errorMessage = e.message;
      }
      enqueueSnackbar(`Error occured during address verfication ${errorMessage}`, { variant: "error" });
      setIsLoadingSuggestedAddress(false);
      setModifiedAddress(false);
    }
  };

  useEffect(() => {
    if (practice && practice.id !== "") {
      setProviderFacilityModalContent(
        convertEntryToContent(practice, isProvider, modalStatus, selectedTin, selectedLocation)
      );
    }
  }, [isProvider, setProviderFacilityModalContent, practice, modalStatus, selectedTin, selectedLocation]);

  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  const NUM_REGEX = /^\d+$/;

  const contactNumberValid = (contactNumber?: PhoneNumber): boolean => {
    return Boolean(!!!contactNumber || (contactNumber && contactNumber.number && contactNumber?.number.length === 10));
  };

  const hasValidZip =
    providerFacilityModalContent.zip &&
    Number.isInteger(Number(providerFacilityModalContent.zip)) &&
    providerFacilityModalContent.zip.length === 5;

  const faxNumberValid = Boolean(
    !!!providerFacilityModalContent.faxNumber ||
      (providerFacilityModalContent.faxNumber && providerFacilityModalContent?.faxNumber.length === 10)
  );
  const hasValidAddress =
    providerFacilityModalContent.address1 &&
    providerFacilityModalContent.city &&
    providerFacilityModalContent.state &&
    hasValidZip;

  const hasValidTinLength = Boolean(
    providerFacilityModalContent.tin &&
      providerFacilityModalContent.tin.length === 9 &&
      Number.isInteger(Number(providerFacilityModalContent.tin)) && //todo maybe remove
      !providerFacilityModalContent.tin.includes(".")
  );

  const getParams = (): PracticeCreatePayload | ProviderFacilityCatalogue => {
    const address: Address = {
      line1: providerFacilityModalContent?.address1 || "",
      line2: providerFacilityModalContent?.address2 || "",
      city: providerFacilityModalContent?.city || "",
      state: providerFacilityModalContent?.state || "",
      zipCode: providerFacilityModalContent?.zip || "",
    };

    //todo : try to remove this
    let savedName = providerFacilityModalContent?.name || "";
    if (isProvider) {
      savedName = providerFacilityModalContent?.lastName + ", " + providerFacilityModalContent?.firstName;
      setProviderFacilityModalContent({ ...providerFacilityModalContent, name: savedName });
    }

    const location: Location = {
      address: address,
      tin: providerFacilityModalContent.tin ? providerFacilityModalContent.tin : undefined,
      manuallyEditedAddress: true,
      name: savedName,
    };

    if (providerFacilityModalContent.contactNumber) {
      location.phoneNumbers = addPhoneNumberIfNotDuplicate(practice, [providerFacilityModalContent.contactNumber]);
    }

    if (providerFacilityModalContent.faxNumber) {
      location.faxNumbers = addFaxNumberIfNotDuplicate(practice, [providerFacilityModalContent.faxNumber]);
    }

    const type: "PROVIDER" | "FACILITY" = isProvider ? "PROVIDER" : "FACILITY";
    return {
      npi: providerFacilityModalContent?.npi || "",
      name: savedName || "",
      addresses: [address],
      phoneNumbers: providerFacilityModalContent.contactNumber ? [providerFacilityModalContent.contactNumber] : [],
      tinList: providerFacilityModalContent.tin ? [providerFacilityModalContent.tin] : [],
      manuallyCreated: false,
      selectedLocation: location,
      createdByUser: false,
      type: undefined,
      recordType: type,
    };
  };

  const validateFields = () => {
    return hasValidAddress && hasValidContactOptions() && hasValidZip && faxNumberValid && !tinExists;
  };

  const hasValidContactOptions = (): boolean => {
    if (isProvider) {
      return (
        Boolean(providerFacilityModalContent.contactNumber && providerFacilityModalContent.contactNumber.number) &&
        contactNumberValid(providerFacilityModalContent.contactNumber) &&
        faxNumberValid
      );
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (
      providerFacilityModalContent.tin &&
      hasValidTinLength &&
      providerFacilityModalContent.tin !== selectedTin &&
      practice?.tinList?.includes(providerFacilityModalContent.tin)
    ) {
      setTinExists(true);
    }
  }, [hasValidTinLength, practice?.tinList, providerFacilityModalContent.tin, selectedTin]);

  const handleSavedAddressIndex = useCallback(() => {
    setModifiedAddress(false);
    setShowAddressResults(false);
    const selectedAddress: PostgridAddressVerificationResponseData | null = postGridAddress;
    if (selectedAddress) {
      setProviderFacilityModalContent({
        ...providerFacilityModalContent,
        address1: selectedAddress.line1,
        address2: !!selectedAddress.line2 ? selectedAddress.line2 : "",
        city: selectedAddress.city,
        state: selectedAddress.provinceOrState,
        zip: selectedAddress.postalOrZip,
      });
    }
  }, [postGridAddress, providerFacilityModalContent]);

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (validateFields() && onSave) {
      onSave(getParams(), setPractice);
    }
    onClose(e, "backdropClick");
  };
  return (
    <>
      <DialogTitle>
        <IconButton
          onClick={(e) => {
            onClose(e, "backdropClick");
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {isProvider ? (
            <>
              <Row>
                <H2>
                  {modalStatus === ModalStatus.EditTin || modalStatus === ModalStatus.EditAddress
                    ? "Edit provider information"
                    : "Add provider information"}
                </H2>
              </Row>
            </>
          ) : isPerfomingProviderPractice ? (
            <Row>
              <H2>
                {modalStatus === ModalStatus.EditTin || modalStatus === ModalStatus.EditAddress
                  ? "Edit performing provider practice information"
                  : "Add performing provider practice information"}
              </H2>
            </Row>
          ) : (
            <Row>
              <H2>
                {modalStatus === ModalStatus.EditTin || modalStatus === ModalStatus.EditAddress
                  ? "Edit facility information"
                  : "Add facility information"}
              </H2>
            </Row>
          )}
        </Grid>
      </DialogContent>
      <DialogContent>
        <Grid container spacing={2}>
          {isProvider ? (
            <>
              <Row style={{ float: "left" }}>
                <Label>Performing or attending provider</Label>
                <FieldValue>
                  {providerFacilityModalContent.firstName} {providerFacilityModalContent.lastName} {"/ NPI -"}
                  {providerFacilityModalContent.npi}
                </FieldValue>
              </Row>
            </>
          ) : isPerfomingProviderPractice ? (
            <>
              <Row style={{ float: "left" }}>
                <Label>Practice</Label>
                <FieldValue>{providerFacilityModalContent.name}</FieldValue>
              </Row>
            </>
          ) : (
            <>
              <Row style={{ float: "left" }}>
                <Label>Facility</Label>
                <FieldValue>{providerFacilityModalContent.name}</FieldValue>
              </Row>
            </>
          )}
          {!hideTinField &&
            (modalStatus === ModalStatus.AddTin || modalStatus === ModalStatus.EditTin ? (
              <Row style={{ float: "left" }}>
                <TextField
                  fullWidth
                  label={isProvider ? "Provider TIN" : isPerfomingProviderPractice ? "Practice TIN" : "Facility TIN"}
                  name={isProvider ? "providerTin" : isPerfomingProviderPractice ? "practiceTIN" : "facilityTin"}
                  value={providerFacilityModalContent.tin}
                  onChangeValue={(newTin) => {
                    setTinExists(false);
                    if (newTin === "" || NUM_REGEX.test(newTin)) {
                      setProviderFacilityModalContent({ ...providerFacilityModalContent, tin: newTin });
                    }
                  }}
                  error={!!providerFacilityModalContent.tin ? !hasValidTinLength || tinExists : false}
                  helperText={
                    providerFacilityModalContent.tin && !hasValidTinLength
                      ? "TIN should be 9 digits long."
                      : tinExists
                      ? "TIN already exists. Please enter a new TIN or go back."
                      : ""
                  }
                />
              </Row>
            ) : (
              <Row style={{ float: "left" }}>
                <Label>
                  {" "}
                  {isProvider
                    ? "Provider TIN"
                    : isPerfomingProviderPractice
                    ? "Practice TIN"
                    : "Facility or agency TIN"}
                </Label>
                <FieldValue>{providerFacilityModalContent.tin}</FieldValue>
              </Row>
            ))}
          <Row>
            <TextField
              fullWidth
              label="Address line 1"
              name="address1"
              value={providerFacilityModalContent.address1}
              onChangeValue={(newAddress1) => {
                setModifiedAddress(true);
                setShowAddressResults(false);
                setProviderFacilityModalContent({ ...providerFacilityModalContent, address1: newAddress1 });
              }}
              error={attemptedSubmit && !providerFacilityModalContent.address1}
            />
          </Row>
          <Row>
            <TextField
              fullWidth
              label="Address line 2 (optional)"
              name="address2"
              value={providerFacilityModalContent.address2}
              onChangeValue={(newAddress2) => {
                setModifiedAddress(true);
                setShowAddressResults(false);
                setProviderFacilityModalContent({ ...providerFacilityModalContent, address2: newAddress2 });
              }}
            />
          </Row>
          <HalfRow>
            <TextField
              fullWidth
              label="City"
              name="city"
              value={providerFacilityModalContent.city}
              onChangeValue={(newCity) => {
                setModifiedAddress(true);
                setShowAddressResults(false);
                setProviderFacilityModalContent({ ...providerFacilityModalContent, city: newCity });
              }}
              error={attemptedSubmit && !providerFacilityModalContent.city}
            />
          </HalfRow>
          <StateComponent>
            <UnitedStatesStateSelection
              error={attemptedSubmit && !providerFacilityModalContent.state}
              state={providerFacilityModalContent.state || ""}
              setState={(state) => {
                setModifiedAddress(true);
                setShowAddressResults(false);
                setProviderFacilityModalContent((prev) => {
                  return { ...prev, state };
                });
              }}
            />
          </StateComponent>
          <ZipComponent>
            <TextField
              fullWidth
              label="Zip"
              name="zip"
              value={providerFacilityModalContent.zip}
              onChangeValue={(newZip) => {
                setModifiedAddress(true);
                setShowAddressResults(false);
                setProviderFacilityModalContent({ ...providerFacilityModalContent, zip: newZip });
              }}
              error={!!providerFacilityModalContent.zip && !hasValidZip}
              helperText={!!providerFacilityModalContent.zip && !hasValidZip && "Zip must be 5 digits"}
            />
          </ZipComponent>
          <Row>
            <PhoneInput
              fullWidth
              label={`Phone number ${isProvider ? "" : "(optional)"}`}
              name="contactNumber"
              onChange={(phoneNumber: PhoneNumber) =>
                setProviderFacilityModalContent({
                  ...providerFacilityModalContent,
                  contactNumber: phoneNumber,
                })
              }
              value={providerFacilityModalContent.contactNumber || {}}
              error={!contactNumberValid(providerFacilityModalContent.contactNumber)}
              helperText={
                isProvider &&
                !contactNumberValid(providerFacilityModalContent.contactNumber) &&
                "Phone number must be 10 digits"
              }
            />
          </Row>
          <Row>
            <PhoneInput
              fullWidth
              label="Fax number (optional)"
              name="faxNumber"
              onChange={(faxNumber: PhoneNumber) =>
                setProviderFacilityModalContent({
                  ...providerFacilityModalContent,
                  faxNumber: faxNumber?.number,
                })
              }
              value={{
                number: providerFacilityModalContent.faxNumber || undefined,
                extension: "",
              }}
              hideExtensionField={true}
              error={!faxNumberValid}
              helperText={
                providerFacilityModalContent.faxNumber &&
                !!providerFacilityModalContent.faxNumber &&
                !faxNumberValid &&
                "Fax number must be 10 digits"
              }
            />
          </Row>
          <Row>
            {modifiedAddress && !isLoadingSuggestedAddress ? (
              <WideButton
                disabled={!validateFields()}
                onClick={() => {
                  postGridValidation();
                }}
              >
                Validate Address
              </WideButton>
            ) : modifiedAddress && isLoadingSuggestedAddress ? (
              <WideButton loading disabled={!validateFields()}>
                Loading Button
              </WideButton>
            ) : (
              <WideButton
                disabled={!validateFields()}
                onClick={(e) => {
                  setAttemptedSubmit(true);
                  if (validateFields()) {
                    if (onSubmit) {
                      onSubmit(e);
                    }
                    onClose(e, "backdropClick");
                  }
                }}
              >
                Save
              </WideButton>
            )}
          </Row>
        </Grid>
      </DialogContent>
      <DialogContent style={{ padding: "24px 0 0 0" }}>
        {showAddressResults && !isLoadingSuggestedAddress ? (
          <div ref={firstGridRef}>
            <Divider />
            <ResultContent>
              <PostGridAddressSearchResult
                addressData={postGridAddress}
                onSaveAddressFromSearchResults={handleSavedAddressIndex}
              />
            </ResultContent>
          </div>
        ) : null}
      </DialogContent>
    </>
  );
};

export default ProviderFacilityEditTinAndAddress;

const StateComponent = (props: ComponentProps<typeof Grid>) => (
  <Grid style={{ textAlign: "center" }} item xs={4} {...props} />
);

const ZipComponent = (props: ComponentProps<typeof Grid>) => (
  <Grid style={{ textAlign: "center" }} item xs={2} {...props} />
);

const HalfRow = (props: ComponentProps<typeof Grid>) => <Grid style={{ textAlign: "center" }} item xs={6} {...props} />;

const Row = (props: ComponentProps<typeof Grid>) => <Grid style={{ textAlign: "center" }} item xs={12} {...props} />;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const WideButton = styled(PrimaryButton)(({ theme }) => ({
  width: "40%",
  marginTop: theme.spacing(2),
  whiteSpace: "nowrap",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const FieldValue = styled((props) => <Body1 component="div" {...props} />)({
  wordWrap: "break-word",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ResultContent = styled(MuiDialogContent)(({ theme }) => ({
  background: colorsLight.background.light,
  padding: theme.spacing(3, 7),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: `${theme.spacing(3)}px ${theme.spacing(5)}px`,
  overflow: "visible",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  padding: 0,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const IconButton = styled(MuiIconButton)(({ theme }) => ({
  float: "right",
  right: theme.spacing(1),
  top: theme.spacing(1),
}));

function getPhoneNumber(entry: Provider | Facility | null, selectedLocation: Location | null | undefined) {
  if (entry?.selectedLocation?.phoneNumbers) {
    return entry.selectedLocation.phoneNumbers[0];
  } else if (selectedLocation?.phoneNumbers) {
    return selectedLocation.phoneNumbers[0];
  } else if (entry?.phoneNumbers) {
    return entry.phoneNumbers[0];
  } else {
    return undefined;
  }
}
function getFaxNumber(entry: Provider | Facility | null, selectedLocation: Location | null | undefined) {
  if (entry?.selectedLocation?.faxNumbers) {
    return entry.selectedLocation.faxNumbers[0];
  } else if (selectedLocation?.faxNumbers) {
    return selectedLocation.faxNumbers[0];
  } else {
    return undefined;
  }
}

function convertEntryToContent(
  entry: Provider | Facility | null,
  isProvider: boolean,
  modalOp: ModalStatus,
  tin: string | null | undefined,
  location: Location | null | undefined
): ProviderFacilityModalContent {
  const splicedProviderName = isProvider ? entry?.name?.split(", ") : undefined;
  const [firstName, lastName] = splicedProviderName || [undefined, undefined];

  let entryLocation;
  let address1;
  let address2;
  let city;
  let state;
  let zip;
  let phoneNumber;
  let faxNum;

  if (modalOp === ModalStatus.EditAddress || modalOp === ModalStatus.EditTin) {
    entryLocation = location;
    address1 = entryLocation?.address?.line1;
    address2 = entryLocation?.address?.line2;
    city = entryLocation?.address?.city;
    state = entryLocation?.address?.state;
    zip = entryLocation?.address?.zipCode;
    phoneNumber = getPhoneNumber(entry, location);
    faxNum = getFaxNumber(entry, location);
  }
  if (modalOp === ModalStatus.AddTin) {
    tin = "";
  }
  return {
    name: entry?.name,
    lastName,
    firstName,
    npi: entry?.npi || "",
    tin: !!tin ? tin : "",
    address1: address1 || "",
    address2: address2 || "",
    city: city || "",
    state: state || "",
    zip: zip || "",
    contactNumber: phoneNumber,
    faxNumber: faxNum,
  };
}

const addPhoneNumberIfNotDuplicate = (
  practice: Provider | Facility | null,
  contactNumber: PhoneNumber[] | undefined
): PhoneNumber[] | undefined => {
  if (!contactNumber) {
    return practice?.phoneNumbers;
  }

  if (!practice?.phoneNumbers) {
    return contactNumber;
  }

  const indexOfMatchingPhoneNumber = practice.phoneNumbers.findIndex(
    (phoneNumber) =>
      phoneNumber.number === contactNumber[0].number && phoneNumber.extension === contactNumber[0].extension
  );

  if (indexOfMatchingPhoneNumber === -1) {
    return [contactNumber[0], ...practice.phoneNumbers]; // If no duplicate found, return a new array with the additional phone number
  }

  return practice.phoneNumbers; // If a duplicate is found, return the original phone numbers array
};

const addFaxNumberIfNotDuplicate = (
  practice: Provider | Facility | null,
  faxNumber: string[] | undefined
): string[] | undefined => {
  if (!faxNumber) {
    return practice?.selectedLocation?.faxNumbers;
  }

  if (!practice?.selectedLocation?.faxNumbers) {
    return faxNumber;
  }

  const indexOfMatchingFaxNumber = practice.selectedLocation.faxNumbers.indexOf(faxNumber[0]);

  if (indexOfMatchingFaxNumber === -1) {
    return [faxNumber[0], ...practice.selectedLocation.faxNumbers]; // If no duplicate found, return a new array with the additional fax number
  }

  return practice.selectedLocation.faxNumbers; // If a duplicate is found, return the original fax numbers array
};
