import { registerRoute } from "../server";

export default function registerRoutes() {
  registerRoute({
    id: "carePathJourneyCreate",
    route: "carePathJourney",
    method: "post",
    generateResponse: function (config, schema, request) {
      const requestBody = JSON.parse(request.requestBody);
      return (schema as any).carePathJourneys.create({
        patientId: requestBody.patient,
        primaryDiagnosisId: requestBody.primaryDiagnosis,
        carePathId: requestBody.carePath,
      });
    },
  });

  registerRoute({
    id: "carePathJourney",
    route: "carePathJourney/:id",
    method: "get", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      const urlParams = request.params;
      return (schema as any).carePathJourneys.find(urlParams.id);
    },
  });

  registerRoute({
    id: "carePathJourneyUpdate",
    route: "carePathJourney/:id",
    method: "patch",
    generateResponse: function (config, schema, request) {
      const urlParams = request.params;
      const requestBody = JSON.parse(request.requestBody);
      const model = (schema as any).carePathJourneys.find(urlParams.id);
      if (model) {
        const { patient, carePath, primaryDiagnosis, ...newProperties } =
          requestBody;
        Object.assign(newProperties, {
          patientId: patient,
          carePathId: carePath,
          primaryDiagnosisId: primaryDiagnosis,
        });
        model.update(newProperties);
      }
      return (schema as any).carePathJourneys.find(urlParams.id);
    },
  });
}
