import React from "react";
import { Document, Page, StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import {
  CohereFaxFooter,
  CohereFaxPDFHeader,
  CohereFaxResourcesSection,
  CohereFaxEmptyForm,
  BULLET,
  CohereReferralFaxEmptyForm,
} from "util/PDFUtils";
import WarningIcon from "components/images/WarningIcon.jpg";
import { FaxbackRequestType, FaxNoticeMissingInformation } from "../../common";
import { GilroyBold, GilroyRegular, GilroySemiBold } from "util/PDFfonts";
interface Props {
  faxbackRequestType?: FaxbackRequestType | undefined;
  missingInformation: FaxNoticeMissingInformation;
  patientName: string;
  dateRequested: string;
  isGhpUser?: boolean;
}

export default function MissingInformationFaxPDF({
  faxbackRequestType,
  missingInformation,
  patientName,
  dateRequested,
  isGhpUser,
}: Props) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ position: "relative" }}>
          <CohereFaxPDFHeader faxBackType="MISSING_INFO" dateRequested={dateRequested} isGhpUser={isGhpUser} />
          <View style={styles.detailsSection}>
            <Text>
              <Text style={styles.patientNameRegularText}>Patient name: </Text>
              <Text style={styles.patientNameBoldText}>{patientName ? patientName : "--"}</Text>
            </Text>
            <Text>
              <Text style={styles.patientNameRegularText}>Start date requested: </Text>
              <Text style={styles.patientNameBoldText}>{dateRequested ? dateRequested : "--"}</Text>
            </Text>
          </View>
          <View style={styles.bodySection}>
            <Text style={[styles.bodySemiBoldText, { paddingBottom: 16 }]}>Hello,</Text>
            <Text style={[styles.bodySemiBoldText, { paddingBottom: 8 }]}>
              Thank you for submitting a request to Cohere Health. Unfortunately, we need more information.
            </Text>
            <View style={styles.bulletSection}>
              <Text style={{ paddingBottom: 4 }}>
                <Text style={styles.bullet}>{BULLET + "    "}</Text>
                <Text style={styles.bulletText}>Please gather the missing information </Text>
                <Text style={styles.bulletTextBold}>within 24 hours </Text>
                <Text style={styles.bulletText}>and</Text>
              </Text>
              <Text style={{ paddingBottom: 4, paddingLeft: 12 }}>
                <Text style={styles.bullet}>{BULLET + "    "}</Text>
                <Text style={styles.bulletText}>
                  Log into the portal at www.next.coherehealth.com and submit your request with the additional
                  information
                </Text>
              </Text>
              <Text style={{ paddingLeft: 12 }}>
                <Text style={styles.bullet}>{BULLET + "    "}</Text>
                <Text style={styles.bulletText}>
                  OR, fax us your filled fax form along with the additional clinical documents, if applicable
                </Text>
              </Text>
            </View>
            <Text style={[styles.bodySemiBoldText, { paddingBottom: 2 }]}>
              The following request details are missing:
            </Text>
            <View style={styles.missingSection}>
              {missingInformation?.patientInfoMissing && (
                <View style={styles.missingItem}>
                  <WarningNotification />
                  <Text style={[styles.bodySemiBoldText, { marginTop: 5 }]}>Patient information missing</Text>
                </View>
              )}
              {missingInformation?.diagnosisInfoMissing && (
                <View style={styles.missingItem}>
                  <WarningNotification />
                  <Text style={[styles.bodySemiBoldText, { marginTop: 5 }]}>Diagnosis information missing</Text>
                </View>
              )}

              {missingInformation?.practiceAuthContactMissing && (
                <View style={styles.missingItem}>
                  <WarningNotification />
                  <Text style={[styles.bodySemiBoldText, { marginTop: 5 }]}>Practice auth contact missing</Text>
                </View>
              )}
              {missingInformation?.servicesRequestedInfoMissing && (
                <View style={styles.missingItem}>
                  <WarningNotification />
                  <Text style={[styles.bodySemiBoldText, { marginTop: 5 }]}>
                    Service(s) requested information missing
                  </Text>
                </View>
              )}
              {missingInformation?.orderingPhysicianInfoMissing && (
                <View style={styles.missingItem}>
                  <WarningNotification />
                  <Text style={[styles.bodySemiBoldText, { marginTop: 5 }]}>
                    Ordering physician information missing
                  </Text>
                </View>
              )}
              {missingInformation?.facilityInfoMissing && (
                <View style={styles.missingItem}>
                  <WarningNotification />
                  <Text style={[styles.bodySemiBoldText, { marginTop: 5 }]}>Facility information missing</Text>
                </View>
              )}

              {missingInformation?.referringProviderInfoMissing && (
                <View style={styles.missingItem}>
                  <WarningNotification />
                  <Text style={[styles.bodySemiBoldText, { marginTop: 5 }]}>
                    Referring provider information missing
                  </Text>
                </View>
              )}
              {missingInformation?.specialtyMissing && (
                <View style={styles.missingItem}>
                  <WarningNotification />
                  <Text style={[styles.bodySemiBoldText, { marginTop: 5 }]}>Specialty missing</Text>
                </View>
              )}
              {missingInformation?.specialistInfoMissing && (
                <View style={styles.missingItem}>
                  <WarningNotification />
                  <Text style={[styles.bodySemiBoldText, { marginTop: 5 }]}>Specialist information missing</Text>
                </View>
              )}
              {missingInformation?.specialistPracticeInfoMissing && (
                <View style={styles.missingItem}>
                  <WarningNotification />
                  <Text style={[styles.bodySemiBoldText, { marginTop: 5 }]}>
                    Specialist practice information missing
                  </Text>
                </View>
              )}
              {missingInformation?.additionalNotes && (
                <View style={{ paddingTop: 8 }}>
                  <Text style={[styles.bodySemiBoldText, { paddingBottom: 2 }]}>Additional Notes</Text>
                  <Text style={styles.bulletText}>{missingInformation.additionalNotes}</Text>
                </View>
              )}
            </View>
          </View>
          <CohereFaxResourcesSection faxBackType="MISSING_INFO" isGhpUser={isGhpUser} />
        </View>
        <CohereFaxFooter />
      </Page>
      {faxbackRequestType === "SERVICE_REQUEST" ? (
        <CohereFaxEmptyForm isGhpUser={isGhpUser} />
      ) : (
        <CohereReferralFaxEmptyForm />
      )}
    </Document>
  );
}

function WarningNotification() {
  return (
    <View style={styles.warningNotification}>
      <Image src={WarningIcon} style={styles.warningIcon} />
      <Text style={styles.warningText}>Missing</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexWrap: "wrap",
    position: "relative",
    padding: 56,
  },
  bodySection: {
    minWidth: "100%",
    borderBottom: 1,
    paddingTop: 24,
    paddingBottom: 24,
    position: "relative",
  },
  patientNameRegularText: {
    fontSize: 12,
    fontFamily: GilroyRegular,
  },
  patientNameBoldText: {
    fontSize: 12,
    fontFamily: GilroyBold,
  },
  bodySemiBoldText: {
    fontSize: 10,
    fontFamily: GilroySemiBold,
  },
  bulletSection: {
    marginLeft: 12,
    paddingBottom: 16,
  },
  bullet: {
    fontSize: 10,
    fontFamily: GilroyBold,
  },
  bulletText: {
    fontSize: 10,
    fontFamily: GilroyRegular,
  },
  bulletTextBold: {
    fontSize: 10,
    fontFamily: GilroyBold,
  },
  bodySmallText: {
    fontSize: 7,
    fontFamily: GilroyRegular,
  },
  bodySmallBoldText: {
    fontSize: 7,
    fontFamily: GilroyBold,
  },
  missingSection: {
    padding: "12 16 0 0",
    width: "100%",
  },
  missingItem: {
    paddingBottom: 8,
    flexDirection: "row",
  },
  warningNotification: {
    padding: 4,
    paddingBottom: 3,
    borderWidth: 1,
    marginRight: 8,
    flexDirection: "row",
    height: 20,
  },
  warningIcon: {
    width: 12,
    height: 8,
    paddingRight: 4,
    marginTop: 1,
  },
  warningText: {
    fontSize: 8,
    fontFamily: GilroyRegular,
    marginTop: 1,
  },
  noteItem: {
    display: "flex",
    flexDirection: "column",
  },
  detailsSection: {
    minWidth: "100%",
    borderBottom: 1,
    paddingTop: 24,
    paddingBottom: 24,
    flexDirection: "row",
    gap: 80,
  },
});
