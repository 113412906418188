import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Alert as MuiAlert, AlertTitle as MuiAlertTitle, AlertProps } from "@material-ui/lab";
import { colorsLight as colors } from "@coherehealth/common";
import InfoIcon from "@material-ui/icons/Info";

const useStylesAlert = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: "5px",
  },
  customAlertClass: {
    backgroundColor: "#EAE9F4",
    "& .MuiAlertTitle-root": {
      color: colors.brand.purple,
    },
    "& $icon": {
      color: colors.brand.purple,
    },
  },
  message: {
    position: "relative",
    top: 1,
    color: colors.black.mediumEmphasis,
    ...theme.typography.body1,
  },
  icon: {},
}));

const useStylesAlertTitle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...theme.typography.body2,
    },
    onlyTitle: {
      marginTop: 1,
      marginBottom: 0,
    },
  })
);

interface CohereAlertProps {
  /** Provide the 'title' content in this prop (instead of in a Mui `AlertTitle`), to get the proper styling. */
  message?: React.ReactNode;
}

const InfoAlert = ({
  message,
  children,
  className,
  ...props
}: CohereAlertProps & Omit<AlertProps, "severity" | "css">) => {
  const { customAlertClass, ...alertClasses } = useStylesAlert();
  const alertTitleClasses = useStylesAlertTitle();
  const extraAlertTitleClass = !children ? alertTitleClasses.onlyTitle : "";
  return (
    <MuiAlert
      className={`${customAlertClass} ${className}`}
      classes={alertClasses}
      iconMapping={{
        info: <InfoIcon />,
      }}
      severity={"info"}
      {...props}
    >
      {message && (
        <MuiAlertTitle className={extraAlertTitleClass} classes={{ root: alertTitleClasses.root }}>
          {message}
        </MuiAlertTitle>
      )}
      {children}
    </MuiAlert>
  );
};

export default InfoAlert;
