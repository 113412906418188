import React from "react";

import { Body1, Caption, formatDateStr } from "@coherehealth/common";
import { Coverage, Patient } from "@coherehealth/core-platform-api";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { ageStringFromDobString, formatAnyPhone, patientGenderDisplay, singleLineAddress } from "util/demographicUtils";
import { getSortedListOfCoverages } from "../../util/patientUtils";
import { GridSize } from "@material-ui/core";

interface Props {
  patient: Patient;
}

export default function PatientInfoSection({ patient }: Props) {
  let coverages = getSortedListOfCoverages(patient);
  let activePrimaryCoverage = null;
  if (coverages?.[0]?.planActive) {
    // If the first coverage in the sorted list is active, then that is the primary coverage
    // we'll then set it aside from the other (non-primary) coverages and display them separately
    activePrimaryCoverage = coverages.shift();
  }

  return (
    <Grid container direction="row" spacing={2}>
      <PatientInfoField label="Sex" value={patientGenderDisplay(patient.gender)} xs={12} />
      <PatientInfoField label="Member ID" value={patient.memberId} xs={12} />
      <PatientInfoField label="DOB" value={formatDateStr(patient.dateOfBirth)} xs={12} />
      <PatientInfoField label="Age" value={ageStringFromDobString(patient.dateOfBirth)} xs={12} />
      <PatientInfoField label="Address" value={singleLineAddress(patient.address)} xs={12} />
      <PatientInfoField label="Phone" value={formatAnyPhone(patient.phones)} xs={12} />
      <PatientInfoField label="Preferred written language" value={patient.languagePreference?.written} xs={12} />
      <PatientInfoField label="PCP grouper ID" value={activePrimaryCoverage?.grouperId} xs={12} />
      {activePrimaryCoverage && (
        <>
          <DividerSection />
          <CoverageInfoSection coverage={activePrimaryCoverage} isPrimary />
        </>
      )}
      {coverages?.map(
        (additionalCoverage, idx) =>
          !!additionalCoverage && (
            <React.Fragment key={idx}>
              <DividerSection />
              <CoverageInfoSection coverage={additionalCoverage} />
            </React.Fragment>
          )
      )}
    </Grid>
  );
}

const DividerSection = () => (
  <Grid item xs={12}>
    <Divider />
  </Grid>
);

const PatientInfoField = ({ label, value, xs }: { label: string; value?: string; xs?: GridSize }) => (
  <Grid item xs={xs} style={xs === 12 ? {} : { paddingTop: 0, paddingBottom: 0 }}>
    <Caption>{label}</Caption>
    <Body1>{value || "-"}</Body1>
  </Grid>
);

const CoverageInfoSection = ({ coverage, isPrimary = false }: { coverage: Coverage; isPrimary?: boolean }) => (
  <>
    <PatientInfoField
      label={isPrimary ? "Primary plan" : "Additional plan"}
      value={
        // Author plans are saved as Humana
        coverage.healthPlanName === "Humana"
          ? (coverage?.platformCode || coverage?.sourceSystem) === "HP"
            ? "Author"
            : "Humana"
          : coverage.healthPlanDisplayName ?? coverage.healthPlanName
      }
      xs={12}
    />
    <PatientInfoField
      label="Membership type"
      value={coverage.coverageLineOfBusinessType ? coverage.coverageLineOfBusinessType : coverage.lineOfBusinessType}
      xs={12}
    />
    <PatientInfoField
      label="Plan type"
      value={coverage.coverageProductType ? coverage.coverageProductType : coverage.productType}
      xs={12}
    />
    <PatientInfoField
      label="Plan year"
      value={`${formatDateStr(coverage.planStart)} - ${formatDateStr(coverage.planEnd)}`}
      xs={12}
    />
    <PatientInfoField label="State of issue" value={coverage.stateOfIssue} xs={12} />
    {coverage.riskBearingEntity && (
      <PatientInfoField label="Risk bearing entity" value={coverage.riskBearingEntity} xs={12} />
    )}
  </>
);
