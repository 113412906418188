import React, { useCallback, useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import Cleave from "cleave.js/react";
import parse from "date-fns/parse";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import { useSnackbar } from "notistack";
import { DATE_FORMAT, formatDateToISODate } from "@coherehealth/common";
import { assertIsApiError } from "util/api";

import { checkSRStatus, StatusCheckResponse } from "./checkStatusApi";
import "./SearchFields.css";

interface Props {
  setStatusResults: (results: StatusCheckResponse | null) => void;
  onSearchClick: () => void;
}

const parseDateStr = (dateStr: string): Date | undefined => parse(dateStr, DATE_FORMAT, new Date());
const dateMin = "1900-01-01";
const dateMax = "2100-01-01";

export default function SearchByMemberInfo({ setStatusResults, onSearchClick }: Props) {
  const [memberId, setMemberId] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const validateDob = () => {
    const dobDate = parseDateStr(dateOfBirth);
    return !!dobDate && isBefore(dobDate, new Date(dateMax)) && isAfter(dobDate, new Date(dateMin));
  };

  const validateMemberId = () => !!memberId;

  const validate = () => validateDob() && validateMemberId();

  const searchWithMemberId = useCallback(
    async (evt) => {
      evt.preventDefault();

      try {
        onSearchClick();
        setSubmissionLoading(true);
        const response = await checkSRStatus({
          memberId,
          dateOfBirth: formatDateToISODate(parseDateStr(dateOfBirth)),
        });
        setSubmissionLoading(false);
        if (response) {
          setStatusResults(response);
        }
      } catch (e) {
        assertIsApiError(e);
        setSubmissionLoading(false);
        enqueueSnackbar(e.message, {
          variant: "error",
          preventDuplicate: true,
        });
      }
    },
    [onSearchClick, memberId, dateOfBirth, setStatusResults, enqueueSnackbar]
  );

  const clearAllFields = useCallback(
    (evt) => {
      evt.preventDefault();
      setMemberId("");
      setDateOfBirth("");
    },
    [setMemberId, setDateOfBirth]
  );

  return (
    <form onSubmit={searchWithMemberId} onReset={clearAllFields}>
      <label className="searchFieldLabel" htmlFor="cohere-tracking-number-search">
        Member ID *
      </label>
      <div className="searchFieldInputContainer">
        <input
          className="searchFieldInput"
          id="cohere-tracking-number-search"
          placeholder="e.g. H12345678"
          value={memberId}
          onChange={(e) => setMemberId(e.target.value)}
        />
        {validateMemberId() && <CheckIcon className="searchFieldsValidateIcon" />}
      </div>
      <label className="searchFieldLabel" htmlFor="cohere-tracking-number-search">
        Member DOB (MM/DD/YYYY) *
      </label>
      <div className="searchFieldInputContainer">
        <Cleave
          className="searchFieldInput"
          id="cohere-tracking-number-search"
          placeholder="MM/DD/YYYY"
          options={{
            date: true,
            datePattern: ["m", "d", "Y"],
            dateMin,
            dateMax,
          }}
          value={dateOfBirth}
          onChange={(e) => setDateOfBirth(e.target.value)}
        />
        {validateDob() && <CheckIcon className="searchFieldsValidateIcon" />}
      </div>
      <div className="buttonContainer">
        <button className="searchButton" disabled={!validate() || submissionLoading} type="submit">
          {submissionLoading ? <CircularProgress color="inherit" size={32} /> : "Search"}
        </button>
        <input className="resetButton" disabled={!memberId && !dateOfBirth} type="reset" value="Clear all fields" />
      </div>
    </form>
  );
}
