import React from "react";
import ProgressBarStep1 from "../images/ProgressBarStep1.svg";
import ProgressBarStep2 from "../images/ProgressBarStep2.svg";
import ProgressBarStep3 from "../images/ProgressBarStep3.svg";
import CohereLogo from "../images/CohereLogo.svg";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  logo: {
    paddingBottom: theme.spacing(8),
    width: theme.spacing(22),
  },
  progressBar: {
    marginBottom: theme.spacing(7.5),
    width: theme.spacing(33),
  },
}));
interface Props {
  stepNumber?: number;
  showProgressBar?: boolean;
  isAvailityUser?: boolean;
}

const stepToImage = (step: number): string => {
  switch (step) {
    case 1:
      return ProgressBarStep1;
    case 2:
      return ProgressBarStep2;
    case 3:
      return ProgressBarStep3;
    default:
      return ProgressBarStep1;
  }
};

export default function HeaderAndProgressBar({ stepNumber, showProgressBar = true, isAvailityUser = false }: Props) {
  const classes = useStyles();

  return (
    <HeaderContainer>
      <img src={CohereLogo} alt={""} className={classes.logo} />
      {showProgressBar && !isAvailityUser ? (
        <img src={stepToImage(stepNumber || 3)} alt={""} className={classes.progressBar} />
      ) : !isAvailityUser ? (
        <Box marginBottom={7.5} width={264} height={44} />
      ) : (
        <></>
      )}
    </HeaderContainer>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const HeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));
