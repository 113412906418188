import { H6, InlineButton, RadioGroup, Subtitle2 } from "@coherehealth/common";
import { useState, useEffect } from "react";

interface Option {
  id: string;
  label: string;
}

interface RadioOptionsWithExpandedButtonProps {
  options: Option[];
  selectedOption?: string;
  onOptionChange: (option: string) => void;
  heading: string;
  showAllInitially?: boolean;
  numberOfItemsToShow?: number;
}

export const RadioOptionsWithExpandedButton: React.FC<RadioOptionsWithExpandedButtonProps> = ({
  options,
  selectedOption,
  onOptionChange,
  heading,
  showAllInitially = false, // Default value for initially showing all options
  numberOfItemsToShow = 4,
}) => {
  // Function to get the initial value from sessionStorage or default to showAllInitially
  const getInitialShowAllOptions = () => {
    const storedValue = sessionStorage.getItem("showAllOptions");
    return storedValue ? JSON.parse(storedValue) : showAllInitially;
  };

  // State to control whether all options are shown or just a subset
  const [showAllOptions, setShowAllOptions] = useState(getInitialShowAllOptions);

  // Update sessionStorage whenever showAllOptions changes
  useEffect(() => {
    sessionStorage.setItem("showAllOptions", JSON.stringify(showAllOptions));
  }, [showAllOptions]);

  // Determine which options to display based on the state
  const visibleOptions = showAllOptions ? options : options.slice(0, numberOfItemsToShow);
  const remainingOptionsCount = options.length - visibleOptions.length; // Calculate the number of remaining options

  // Function to toggle the display of all options
  const toggleShowAllOptions = () => {
    setShowAllOptions((prevState: any) => !prevState);
  };

  return (
    <>
      {/* Heading for the radio options */}
      <H6 component="label">{heading}</H6>
      {/* Radio group displaying the visible options */}
      <RadioGroup
        options={visibleOptions}
        value={selectedOption}
        onChange={onOptionChange}
        data-testid={"dashboard-health-plan-filters-radioGroup"}
      />
      {/* Button to toggle between showing all options and a subset */}
      {options.length > numberOfItemsToShow && (
        <InlineButton onClick={toggleShowAllOptions}>
          <Subtitle2>
            {showAllOptions ? "Show less" : remainingOptionsCount > 0 ? `Show ${remainingOptionsCount} more` : null}
          </Subtitle2>
        </InlineButton>
      )}
    </>
  );
};
