import React, { useCallback, useEffect, useRef, useState } from "react";
import { ClassNameMap, useSnackbar } from "notistack";

import { Chip, colorsLight, H1, H4, Caption, Tooltip, PaginateProps, TertiaryButton } from "@coherehealth/common";
import {
  useCreateUser,
  useUpdateUser,
  useGetUsers,
  useDeleteUser,
  useUnlockUser,
  User,
  OrganizationStatus,
  useUpdateUserExtension,
  UserResponse,
} from "@coherehealth/core-platform-api";
import Grid from "@material-ui/core/Grid";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import EditMemberModal, { UserInput } from "./EditMemberModal";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import UnlockMemberModal from "./UnlockMemberModal";
import { Typography } from "@material-ui/core";
import { useTrackUserInteraction } from "util/userActivityTracker";
import listRemove from "util/listRemove";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { ProviderOrgUsersTable, UserQueryParams } from "./ProviderOrgUsersTable";
import { UnverifiedProviderOrgUsersTable } from "./UnverifiedProviderOrgUsersTable";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import useIsOverflow from "hooks/useIsOverflow";

const RESULTS_PER_PAGE = [
  { id: "10", label: "10" },
  { id: "25", label: "25" },
  { id: "50", label: "50" },
];

interface Props {
  userId: string;
  orgName: string;
  orgStatus?: OrganizationStatus;
  orgId?: string;
  isReadOnlyView?: boolean;
}

export default function ProviderOrgUsers({ userId, orgName, orgStatus, orgId, isReadOnlyView }: Props) {
  const [addMemberModalOpen, setAddMemberModalOpen] = useState(false);
  const [deleteMemberModalOpen, setDeleteMemberModalOpen] = useState(false);
  const [unlockMemberModalOpen, setUnlockMemberModalOpen] = useState(false);
  const [editingMember, setEditingMember] = useState<User>({});
  const { enqueueSnackbar } = useSnackbar();

  // Initializes default values for the form state.
  const query: PaginateProps = {
    offset: 0,
    max: Number(RESULTS_PER_PAGE[1].id) + 1,
  };

  const queryParams: UserQueryParams = {
    organizationName: orgName,
    organization: String(orgId),
    max: query?.max,
    offset: query?.offset,
    sort: "newlyAutoVerified",
  };
  const {
    data: users,
    refetch: refetchUsers,
    error: getUsersError,
  } = useGetUsers({
    lazy: true, // lazy load when FF is on. UPDATE: FF deprecated, always use lazy
    queryParams,
  });

  const numberOfAdminsInOrg = users?.filter(({ roles }) => roles?.includes("backOfficeAdmin"))?.length || 0;

  const {
    data: pendingUsers,
    refetch: refetchPendingUsers,
    loading: loadingPendingUsers,
    error: getPendingUsersError,
  } = useGetUsers({
    queryParams: {
      pendingOrganizationId: orgId,
    },
  });

  useEffect(() => {
    if (getPendingUsersError) {
      enqueueSnackbar(`There was an error fetching pending users ${getPendingUsersError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, getPendingUsersError]);

  const userResultsLength = users ? users.length - (users?.length === query?.max ? 1 : 0) : 0;
  const finalUsers = userResultsLength !== users?.length && users ? listRemove(users, userResultsLength) : users;

  useEffect(() => {
    if (getUsersError) {
      enqueueSnackbar(`There was an error retrieving users ${getUsersError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, getUsersError]);

  const { mutate: createUser, loading: creatingUser, error: userCreateError } = useCreateUser({});

  const createMember = useCallback(
    async (user: UserInput) => {
      await createUser({ ...user, organizationName: orgName });
      setAddMemberModalOpen(false);
      await Promise.all([refetchUsers(), refetchPendingUsers()]);
    },
    [createUser, orgName, refetchUsers, refetchPendingUsers]
  );

  useEffect(() => {
    if (userCreateError) {
      if (userCreateError.status === 409) {
        enqueueSnackbar("A user already exists for this email", { variant: "error" });
      } else if (userCreateError.status === 403) {
        enqueueSnackbar("You do not have permission to create this user", { variant: "error" });
      } else {
        enqueueSnackbar(`There was an error creating the user ${userCreateError.message}`, {
          variant: "error",
        });
      }
    }
  }, [enqueueSnackbar, userCreateError]);

  const {
    mutate: updateUser,
    loading: updatingUser,
    error: userUpdateError,
  } = useUpdateUser({
    id: editingMember?.id || "",
  });

  const {
    mutate: updateUserFaxExtension,
    loading: updatingUserFaxExtension,
    error: userUpdateFaxExtensionError,
  } = useUpdateUserExtension({
    id: editingMember?.id || "",
  });

  const updateMember = useCallback(
    async (user: User) => {
      await updateUser({ ...user }, { pathParams: { id: user.id || "" } });
      setAddMemberModalOpen(false);
      setEditingMember({});
      if (user.extension?.mostRecentlyUsedFaxNumber !== editingMember?.extension?.mostRecentlyUsedFaxNumber) {
        let mostRecentlyUsedFaxNumber = user.extension?.mostRecentlyUsedFaxNumber;
        await updateUserFaxExtension({ mostRecentlyUsedFaxNumber }, { pathParams: { id: user?.id || "" } });
      }
      await refetchUsers();
    },
    [updateUser, editingMember?.extension?.mostRecentlyUsedFaxNumber, refetchUsers, updateUserFaxExtension]
  );

  useEffect(() => {
    if (userUpdateError) {
      enqueueSnackbar(`There was an error updating the user ${userUpdateError.message}`, {
        variant: "error",
      });
    }
    if (userUpdateFaxExtensionError) {
      enqueueSnackbar(`There was an error updating the fax extension of user ${userUpdateFaxExtensionError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, userUpdateError, userUpdateFaxExtensionError]);

  const {
    mutate: unlockUser,
    loading: unlockingUser,
    error: userUnlockError,
  } = useUnlockUser({
    id: editingMember?.id || "",
  });
  const unlockMember = useCallback(
    async (user: User) => {
      await unlockUser({}, { pathParams: { id: user.id || "" } });
      refetchUsers();
    },
    [unlockUser, refetchUsers]
  );

  useEffect(() => {
    if (userUnlockError) {
      enqueueSnackbar(`There was an error unlocking the user ${userUnlockError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, userUnlockError]);

  const { mutate: deleteUser, loading: deletingUser, error: userDeletionError } = useDeleteUser({});
  const trackInteraction = useTrackUserInteraction();
  const deleteMember = useCallback(
    async (user: User) => {
      if (user.id) {
        await deleteUser(user.id);
        setDeleteMemberModalOpen(false);
        trackInteraction({
          event: "ORGANIZATION_DELETE_EMAIL_AUTO_VERIFIED_USER",
          stage: "ORGANIZATION_DASHBOARD",
          activityContext: {
            deletedUserName: user.name,
            deletedUserEmail: user.email,
            adminUserId: userId,
            organizationId: user.organization,
          },
        });
        await refetchUsers();
      }
    },
    [deleteUser, refetchUsers, trackInteraction, userId]
  );

  const disableDestructiveActionsForUser = (userToEdit: User) => {
    const userIsAdmin = userToEdit.roles?.includes("backOfficeAdmin");

    return (
      userId === userToEdit.id /* user is self */ ||
      (updatingUser &&
        updatingUserFaxExtension &&
        userToEdit.id === editingMember.id) /* A request is in flight to update this user */ ||
      (numberOfAdminsInOrg < 2 && userIsAdmin)
    ); /* User is the only admin */
  };

  useEffect(() => {
    if (userDeletionError) {
      enqueueSnackbar(`There was an error deactivating the user ${userDeletionError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, userDeletionError]);

  return (
    <Grid container>
      <Grid item container xs={12} justifyContent="space-between">
        <Grid item>
          <H1 data-public>Users & Permissions</H1>
        </Grid>
        {!isReadOnlyView && (
          <Grid item>
            <AddMemberButton
              startIcon={<AddCircleIcon />}
              onClick={() => {
                setAddMemberModalOpen(true);
                setEditingMember({});
              }}
              data-public
            >
              Add new user
            </AddMemberButton>
          </Grid>
        )}
        <EditMemberModal
          open={addMemberModalOpen}
          handleClose={() => {
            setAddMemberModalOpen(false);
            setEditingMember({});
          }}
          onSubmit={async (user: User) => {
            if (Boolean(user.id)) {
              await updateMember(user);
            } else {
              await createMember(user);
            }
          }}
          submitting={creatingUser || updatingUser}
          member={editingMember}
          requireAdmin={finalUsers?.length === 0}
          oktaUserStatus={editingMember.oktaStatus}
          disableAdminSwitch={disableDestructiveActionsForUser(editingMember)}
        />
        <DeleteConfirmationModal
          open={deleteMemberModalOpen}
          handleClose={() => {
            setDeleteMemberModalOpen(false);
          }}
          onDelete={() => deleteMember(editingMember)}
          deleting={deletingUser}
          subject="MEMBER"
        />
        <UnlockMemberModal
          open={unlockMemberModalOpen}
          handleClose={() => {
            setUnlockMemberModalOpen(false);
          }}
          onUnlock={() => unlockMember(editingMember)}
          unlocking={unlockingUser}
        />
      </Grid>
      {!loadingPendingUsers && Number(pendingUsers?.length) > 0 && (
        <>
          <Grid
            xs={12}
            container
            item
            alignItems="center"
            style={{ display: "flex", marginTop: "24px", marginBottom: "24px" }}
          >
            <MembersSubheader data-public>Unverified users</MembersSubheader>
            <Tooltip
              title={"These users can submit new auths but they do not have access to any existing or previous auths."}
              placement="top"
              data-public
            >
              <InfoIcon data-public />
            </Tooltip>
          </Grid>
          {!!pendingUsers && (
            <UnverifiedProviderOrgUsersTable
              users={pendingUsers}
              refetchPendingUsers={refetchPendingUsers}
              refetchUsers={refetchUsers}
              isReadOnlyView={isReadOnlyView}
            />
          )}
        </>
      )}
      {orgStatus === "PENDING" && (
        <Grid container alignItems="center">
          <GrayCaption data-public>
            New members cannot be added to an organization until the admin who has created the account has logged in and
            activated the organization
          </GrayCaption>
        </Grid>
      )}
      <ProviderOrgUsersTable
        orgName={orgName}
        orgId={orgId}
        userId={userId}
        isReadOnlyView={isReadOnlyView}
      ></ProviderOrgUsersTable>
    </Grid>
  );
}

export const GetNameWrapper = (
  user: UserResponse,
  styleClasses: ClassNameMap<"tableColumn" | "newUserChip" | "styledBodyContainer">
) => {
  const textElementRef = useRef<HTMLInputElement | null>(null);
  const { hoverStatus } = useIsOverflow(textElementRef);

  return (
    <div className={styleClasses.tableColumn}>
      <Tooltip title={user.name || ""} placement="top" disableHoverListener={!hoverStatus}>
        <Typography className={styleClasses.styledBodyContainer} ref={textElementRef}>
          {user.name}
        </Typography>
      </Tooltip>
      {user.isNewEmailAutoVerifiedUser && <Chip className={styleClasses.newUserChip} label="New" />}
    </div>
  );
};

export const GetEmailWrapper = (
  user: UserResponse,
  styleClasses: ClassNameMap<"tableColumn" | "styledBodyContainer">
) => {
  const textElementRef = useRef<HTMLInputElement | null>(null);
  const { hoverStatus } = useIsOverflow(textElementRef);
  return (
    <div className={styleClasses.tableColumn}>
      <Tooltip title={user.email || ""} placement="top" disableHoverListener={!hoverStatus}>
        <Typography className={styleClasses.styledBodyContainer} ref={textElementRef}>
          {user.email}
        </Typography>
      </Tooltip>
    </div>
  );
};

export const GetPhoneNumberColumnWrapper = (
  user: UserResponse,
  styleClasses: ClassNameMap<"tableColumn" | "styledBodyContainer">
) => {
  const textElementRef = useRef<HTMLInputElement | null>(null);
  const { hoverStatus } = useIsOverflow(textElementRef);
  const formattedPhone = user.phone
    ? `(${user.phone.slice(0, 3)}) ${user.phone.slice(3, 6)}-${user.phone.slice(6)}`
    : "";
  return (
    <div className={styleClasses.tableColumn}>
      <Tooltip title={formattedPhone} placement="top" disableHoverListener={!hoverStatus}>
        <Typography className={styleClasses.styledBodyContainer} ref={textElementRef}>
          {formattedPhone}
        </Typography>
      </Tooltip>
    </div>
  );
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const InfoIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  color: colorsLight.font.light,
  marginLeft: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const MembersSubheader = styled(H4)({
  color: colorsLight.font.light,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const AddMemberButton = styled(TertiaryButton)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(1.5),
  justifyContent: "center",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const GrayCaption = styled(Caption)(({ theme }) => ({
  color: colorsLight.font.light,
  marginBottom: theme.spacing(6),
}));
