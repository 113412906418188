import {
  formatDateToISODate,
  DATE_FORMAT,
  TextField,
  DateTextField,
  parseDateFromISOStringWithoutFallback,
  PrimaryButton,
} from "@coherehealth/common";
import { useVerifyPatientDetailsBeyondOrg } from "@coherehealth/core-platform-api";
import { Grid, makeStyles } from "@material-ui/core";
import { isWithinInterval, parse } from "date-fns";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState, ComponentProps } from "react";
import { useNavigate } from "react-router";
import { assertIsApiError } from "util/api";
import {
  routeToPatientSummaryFromReviewAfterDelete,
  routeToPatientSummaryFromReview,
} from "util/routeUtils/routeUtils";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { error as logError } from "logger";

import { useDashboardSearchUserImpressionTracking } from "util/userActivityTracker";

interface SearchBeyondProps {
  beyondOrgSearchResponseType?: string;
  queryString: string;
  setBeyondOrgSearchResponseType?: Dispatch<SetStateAction<string>>;
  attemptedSubmit: boolean;
  setAttemptedSubmit: Dispatch<SetStateAction<boolean>>;
  patientMatchValidated: boolean;
  setPatientMatchValidated: Dispatch<SetStateAction<boolean>>;
  setFormInputsValid: Dispatch<SetStateAction<boolean>>;
}

export const SearchBeyondOrgMatchFound = ({
  queryString,
  beyondOrgSearchResponseType,
  setBeyondOrgSearchResponseType,
  attemptedSubmit,
  setAttemptedSubmit,
  patientMatchValidated, // Maybe we don't need in this component
  setPatientMatchValidated,
  setFormInputsValid,
}: SearchBeyondProps) => {
  const [memberId, setMemberId] = useState<string>("");
  const [dobStr, setDobStr] = useState<string>("");
  const classes = useStyles();

  const dateOfBirth = formatDateToISODate(parse(dobStr, DATE_FORMAT, new Date()));

  const { mutate: validatePatient, loading } = useVerifyPatientDetailsBeyondOrg({});

  const trackUserEvents = useDashboardSearchUserImpressionTracking();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const updateSearch = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.stopPropagation();
    event.preventDefault();
    setAttemptedSubmit(true);
    if (Boolean(memberId) && isDobInValidRange(dateOfBirth)) {
      setFormInputsValid(true);
      try {
        const apiResponse = await validatePatient({
          memberId,
          dateOfBirth,
          searchQuery: queryString,
          responseType: beyondOrgSearchResponseType,
        });
        if (apiResponse?.patientId) {
          await trackUserEvents(beyondOrgSearchResponseType);

          if (beyondOrgSearchResponseType?.toLowerCase().includes("patient") && apiResponse.patientId) {
            routeToPatientSummaryFromReviewAfterDelete(apiResponse.patientId, navigate);
            enqueueSnackbar("Patient details verified", { variant: "success" });
          } else if (
            beyondOrgSearchResponseType === "serviceRequest" ||
            beyondOrgSearchResponseType === "authFirstServiceRequest"
          ) {
            if (apiResponse.serviceRequestId) {
              const link = routeToPatientSummaryFromReview({
                serviceRequestId: apiResponse.serviceRequestId,
                patientId: apiResponse.patientId,
              });
              navigate(link);
              enqueueSnackbar("Patient details verified", { variant: "success" });
            }
          }

          if (beyondOrgSearchResponseType && setBeyondOrgSearchResponseType) {
            setBeyondOrgSearchResponseType("");
          }
          setPatientMatchValidated(true);
        }
      } catch (e) {
        assertIsApiError(e);
        if (e.message !== "Failed to fetch: Aborted") {
          logError(e);
        }
      }
    } else {
      setPatientMatchValidated(false);
      setFormInputsValid(false);
      await trackUserEvents();
    }
  };

  return (
    <MuiDialogContent className={classes.dialogContent}>
      <Grid component="form" onSubmit={updateSearch}>
        <div>
          <Row>
            <TextField
              error={attemptedSubmit && !memberId}
              helperText={attemptedSubmit && !memberId ? "Required" : ""}
              fullWidth
              label="Health plan member ID"
              value={memberId}
              onChangeValue={setMemberId}
              onClick={(event) => event.stopPropagation()}
              style={{ maxWidth: 400 }}
            />
          </Row>
          <Row>
            <DateTextField
              error={attemptedSubmit && !isDobInValidRange(dateOfBirth)}
              helperText={attemptedSubmit && !isDobInValidRange(dateOfBirth) ? "Must have a valid date" : ""}
              fullWidth
              addDatePatternToLabel
              label={`Member date of birth`}
              value={dobStr}
              onChangeValue={setDobStr}
              onClick={(event) => event.stopPropagation()}
              style={{ maxWidth: 400 }}
            />
          </Row>
        </div>
        <Row style={{ paddingTop: "16px" }} data-public>
          {/* ensure error state works as intended when 500 returned */}
          <PrimaryButton
            disabled={attemptedSubmit && (!memberId || !isDobInValidRange(dateOfBirth))}
            loading={loading}
            type="submit"
          >
            Verify patient details
          </PrimaryButton>
        </Row>
      </Grid>
    </MuiDialogContent>
  );
};

const Row = (props: ComponentProps<typeof Grid>) => <Grid style={{ marginBottom: "16px" }} item xs={12} {...props} />;

const isDobInValidRange = (dateOfBirth: string) => {
  const date = parseDateFromISOStringWithoutFallback(dateOfBirth);
  return date && isWithinInterval(date, { start: new Date(1900, 1, 1), end: new Date(2100, 1, 1) });
};

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: `${theme.spacing(4)}px 0px ${theme.spacing(2)}px 0px`,
  },
}));
