import {
  FaxSidebarInnerControlPanelContainer,
  FaxSidebarOuterControlPanelContainer,
} from "./AttachToServiceRequestSidebar";
import Grid from "@material-ui/core/Grid";
import { PrimaryButton, SecondaryButton, InlineButton } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core/styles";
import { FaxSidebarView, backToMenuView } from "./common";
import { Dispatch } from "react";

interface Props {
  primaryButtonLoading?: boolean;
  cancelButtonLoading?: boolean;
  onPrimaryButtonClick: () => void;
  disabledFinish?: boolean;
  showBackButton?: boolean;
  onCancelButtonClick: () => void;
  setSidebarView?: Dispatch<FaxSidebarView>;
}

const useStyles = makeStyles((theme) => ({
  leftButton: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    "&:hover, &:focus, &:active": {
      borderColor: theme.palette.primary.light,
      boxShadow: `0 0 0 1px ${theme.palette.primary.light}`,
    },
    minWidth: theme.spacing(15.5),
    maxWidth: theme.spacing(18.75),
  },
  cancelButton: {
    alignItems: "flex-start",
    margin: "0px 8px 0px 12px",
    color: theme.palette.error.dark,
  },
  rightButton: {
    minWidth: theme.spacing(25),
  },
  secondaryContainer: {
    marginTop: "auto",
    marginBottom: "auto",
  },
}));
const CommonFaxFooter = ({
  setSidebarView,
  primaryButtonLoading,
  onPrimaryButtonClick,
  disabledFinish,
  showBackButton,
  onCancelButtonClick,
  cancelButtonLoading,
}: Props) => {
  const classes = useStyles();
  return (
    <FaxSidebarOuterControlPanelContainer>
      <FaxSidebarInnerControlPanelContainer>
        <Grid item xs={2} container spacing={4} alignItems={"flex-start"} justifyContent={"flex-start"}>
          <InlineButton
            className={classes.cancelButton}
            warning
            loading={cancelButtonLoading}
            onClick={() => {
              onCancelButtonClick();
            }}
            data-public
          >
            Cancel
          </InlineButton>
        </Grid>
        <Grid container justifyContent={"flex-end"} style={{ display: "flex", flexDirection: "row" }}>
          {showBackButton && setSidebarView && (
            <Grid item className={classes.secondaryContainer} style={{ paddingRight: 24 }}>
              <SecondaryButton
                className={classes.leftButton}
                warning
                onClick={() => setSidebarView(backToMenuView)}
                data-public
              >
                Back
              </SecondaryButton>
            </Grid>
          )}
          <PrimaryButton
            className={classes.rightButton}
            loading={primaryButtonLoading}
            onClick={() => {
              onPrimaryButtonClick();
            }}
            disabled={disabledFinish}
            data-public
          >
            Continue
          </PrimaryButton>
        </Grid>
      </FaxSidebarInnerControlPanelContainer>
    </FaxSidebarOuterControlPanelContainer>
  );
};

export default CommonFaxFooter;
