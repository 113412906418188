import React, { useContext } from "react";
import { OutOfNetworkReview, ServiceRequestResponse, ReviewType } from "@coherehealth/core-platform-api";
import Collapse from "@material-ui/core/Collapse";
import { useTheme } from "@material-ui/core/styles";
import OutOfNetworkReviewEdit from "./OutOfNetworkReviewEdit";
import OutOfNetworkReviewReadOnly from "./OutOfNetworkReviewReadonly";
import ReviewHeader from "../ReviewHeader/ReviewHeader";
import { InformativeModal, RadioGroup, useConfiguration } from "@coherehealth/common";
import { useOutOfNetworkReview, OutOfNetworkReviewUpdate } from "components/ClinicalReview/reviewUtils/useReviews";
import { Box, Grid } from "@material-ui/core";
import { OutreachAttemptProps } from "../useOutreachAttempt";
import { ReviewCommonProps, ReviewReadOnlyCommonProps } from "components/ClinicalReview/reviewUtils/utils";
import DetailedHardRedirectClinicalReviewModal from "../Modals/DetailedHardRedirectClinicalReviewModal";
import { routeToPatientSummaryFromReview } from "util/routeUtils/routeUtils";
import OutOfNetworkReviewSubmissionModal from "./OutOfNetworkReviewSubmissionModal";
import { NetworkStatusOverrideOptionType } from "./NetworkStatusOverride/NeworkStatusOverrideSingleSelect";
import { getNetworkStatusOverride, getNetworkStatusOverridePayload } from "./NetworkStatusOverride/utils";
import { ClinicalReviewContext } from "components/ClinicalReview/Review/ClinicalReviewPage";
import { OutOfNetworkReviewManagerProps } from "components/ClinicalReview/reviewUtils/useOutOfNetworkReviewManager";

interface OutOfNetworkReviewComponentProps extends ReviewCommonProps, OutOfNetworkReviewManagerProps {
  serviceRequest: ServiceRequestResponse;
  outOfNetworkReview: OutOfNetworkReview;
  existingReviews?: ReviewType[] | null;
  outreachAttemptProps?: Partial<OutreachAttemptProps>;
  hideHeader?: boolean;
  hideFooter?: boolean;
  showNeedsOONToggle?: boolean;
  needsOonReview?: boolean;
  handleNeedsOonUpdate?: (needsOonReview: boolean) => void;
}

export interface OutOfNetworkReviewEditableProps
  extends Omit<OutOfNetworkReviewComponentProps, "setOutOfNetworkReview" | "outOfNetworkReview"> {
  outOfNetworkReviewId: string;
}
interface OutOfNetworkReviewEditorProps extends Omit<OutOfNetworkReviewComponentProps, "setOutOfNetworkReview"> {
  setOutOfNetworkReview: OutOfNetworkReviewUpdate;
}

interface OutOfNetworkReviewReadonlyProps extends ReviewReadOnlyCommonProps {
  serviceRequest: ServiceRequestResponse;
  outOfNetworkReview: OutOfNetworkReview;
  clinicalReviewView?: boolean;
}

const OutOfNetworkReviewComponent = ({ outOfNetworkReview, ...otherProps }: OutOfNetworkReviewComponentProps) => {
  if (outOfNetworkReview?.reviewStatus === "DRAFT") {
    return <OutOfNetworkReviewEditable {...otherProps} outOfNetworkReviewId={outOfNetworkReview.id} />;
  } else if (outOfNetworkReview?.reviewStatus === "COMPLETE" || outOfNetworkReview?.reviewStatus === "DISCARDED") {
    return <OutOfNetworkReviewReadonly outOfNetworkReview={outOfNetworkReview} {...otherProps} />;
  } else {
    return null;
  }
};

export const OutOfNetworkReviewEditable = ({
  outOfNetworkReviewId,
  ...otherProps
}: OutOfNetworkReviewEditableProps) => {
  const [outOfNetworkReview, setOutOfNetworkReview] = useOutOfNetworkReview({ outOfNetworkReviewId });
  if (!outOfNetworkReview || !setOutOfNetworkReview) {
    return null;
  }
  return (
    <OutOfNetworkReviewEditor
      {...otherProps}
      outOfNetworkReview={outOfNetworkReview}
      setOutOfNetworkReview={setOutOfNetworkReview}
    />
  );
};

const OutOfNetworkReviewEditor = ({
  serviceRequest,
  outOfNetworkReview,
  setOutOfNetworkReview,
  clinicalReviewView,
  reviewRef,
  copiedAttachmentText,
  setPastedAttachmentTexts,
  outreachAttemptProps,
  hideHeader,
  showNeedsOONToggle,
  needsOonReview,
  handleNeedsOonUpdate,
  onFinishReview,
  errorStates,
  isSubmissionModalOpen,
  setIsSubmissionModalOpen,
  hideFooter,
  saveReviewDraft,
  savingReview,
  disableFinishButton,
  loadingDiscardOrSave,
  discardReview,
  discardingReview,
  submitOutOfNetworkReview,
  finishReviewLoading,
  errorDetails,
  setOpenHardRedirectOnSubmitModal,
  openHardRedirectOnSubmitModal,
  duplicateReviewModalOpen,
  setDuplicateReviewModalOpen,
  disableFinishModalButton,
}: OutOfNetworkReviewEditorProps) => {
  const { isOutOfNetworkFacility, isOutOfNetworkPerformingProvider } = outOfNetworkReview?.networkStatusOverride ?? {};
  const theme = useTheme();
  const facilityNetworkStatusOverride = getNetworkStatusOverride(isOutOfNetworkFacility);
  const performingProviderNetworkStatusOverride = getNetworkStatusOverride(isOutOfNetworkPerformingProvider);
  const setFacilityNetworkStatusOverride = (networkStatus: NetworkStatusOverrideOptionType | undefined) => {
    setOutOfNetworkReview({
      networkStatusOverride: getNetworkStatusOverridePayload(networkStatus, performingProviderNetworkStatusOverride),
    });
  };
  const setPerformingNetworkStatusOverride = (networkStatus: NetworkStatusOverrideOptionType | undefined) => {
    setOutOfNetworkReview({
      networkStatusOverride: getNetworkStatusOverridePayload(facilityNetworkStatusOverride, networkStatus),
    });
  };
  const oonReviewConfig = useConfiguration(
    "outOfNetworkReviewConfiguration",
    serviceRequest.healthPlanName,
    serviceRequest.delegatedVendor
  );

  const showOONEdit = !showNeedsOONToggle || needsOonReview;

  return (
    <div style={{ backgroundColor: theme.palette.background.paper, paddingBottom: theme.spacing(1) }} ref={reviewRef}>
      {!hideHeader && (
        <Grid container direction="row" justifyContent="space-between" alignItems="baseline" spacing={0}>
          <Grid item>
            <ReviewHeader
              reviewDateCreated={outOfNetworkReview.dateCreated}
              reviewLastUpdated={outOfNetworkReview.lastUpdated}
              reviewStatus={outOfNetworkReview.reviewStatus}
              reviewType={outOfNetworkReview.reviewType}
              reviewCreatedByName={outOfNetworkReview.createdByName}
              reviewCompletedByName={outOfNetworkReview.completedByName}
              reviewDateCompleted={outOfNetworkReview.dateCompleted}
              clinicalReviewView={clinicalReviewView}
            />
          </Grid>
        </Grid>
      )}
      {showNeedsOONToggle && (
        <>
          <Box ml="16px" my="24px">
            <RadioGroup
              row
              nowrap
              label="Does this request need an Out-of-Network Exception Review?"
              options={[
                { id: "false", label: "No" },
                { id: "true", label: "Yes" },
              ]}
              value={needsOonReview ? "true" : "false"}
              onChange={(option) => handleNeedsOonUpdate?.(option === "true")}
            />
          </Box>
        </>
      )}
      <Box style={{ margin: theme.spacing(0, 3, 3, 2) }}>
        {showOONEdit && (
          <OutOfNetworkReviewEdit
            serviceRequest={serviceRequest}
            outOfNetworkReview={outOfNetworkReview}
            setOutOfNetworkReview={setOutOfNetworkReview}
            copiedAttachmentText={copiedAttachmentText}
            setPastedAttachmentTexts={setPastedAttachmentTexts}
            outreachAttemptProps={outreachAttemptProps}
            facilityNetworkStatusOverride={facilityNetworkStatusOverride}
            performingProviderNetworkStatusOverride={performingProviderNetworkStatusOverride}
            setPerformingNetworkStatusOverride={setPerformingNetworkStatusOverride}
            setFacilityNetworkStatusOverride={setFacilityNetworkStatusOverride}
            networkStatusOverrideEnabled={oonReviewConfig?.networkStatusOverride}
            oonErrorStates={errorStates}
            discardingReview={discardingReview}
            loadingDiscardOrSave={loadingDiscardOrSave}
            discardReview={discardReview}
            saveReviewDraft={saveReviewDraft}
            savingReview={savingReview}
            onFinishReview={onFinishReview}
            disableFinishButton={disableFinishButton}
            finishReviewLoading={finishReviewLoading}
            hideFooter={hideFooter}
          />
        )}
        <OutOfNetworkReviewSubmissionModal
          loading={finishReviewLoading}
          isOpen={isSubmissionModalOpen}
          setIsOpen={setIsSubmissionModalOpen}
          onSubmit={submitOutOfNetworkReview}
          review={outOfNetworkReview}
          setReview={setOutOfNetworkReview}
          oonReviewConfig={oonReviewConfig}
          disableFinishOonModalButton={disableFinishModalButton}
        />
        <DetailedHardRedirectClinicalReviewModal
          isOpen={openHardRedirectOnSubmitModal}
          setIsOpen={setOpenHardRedirectOnSubmitModal}
          errorDetails={errorDetails}
        />

        <InformativeModal
          open={duplicateReviewModalOpen}
          onClose={() => {
            setDuplicateReviewModalOpen(false);
          }}
          headerText={"Review completed by another user"}
          additionalInfoText={"This review was completed by another user. Your review and notes will not be saved."}
          primaryButtonText={"Go to patient summary"}
          primaryButtonRoute={routeToPatientSummaryFromReview({
            serviceRequestId: serviceRequest.id,
            patientId: serviceRequest?.patient?.id,
          })}
        />
      </Box>
    </div>
  );
};

export const OutOfNetworkReviewReadonly = React.memo(
  ({
    serviceRequest,
    outOfNetworkReview,
    expanded,
    toggleExpanded,
    clinicalReviewView,
    reviewRef,
    expandableGuidelines,
    unexpandable,
    reviewDetailsCollapseStyle,
    ...otherProps
  }: OutOfNetworkReviewReadonlyProps) => {
    const theme = useTheme();
    const oonReviewConfig = useConfiguration(
      "outOfNetworkReviewConfiguration",
      serviceRequest?.healthPlanName,
      serviceRequest?.delegatedVendor
    );
    const { allowedReviewOutcomes } = useContext(ClinicalReviewContext);
    return (
      <div ref={reviewRef} data-testid={`out-of-network-review-read-only-${outOfNetworkReview.id}`}>
        <ReviewHeader
          toggleExpanded={toggleExpanded}
          expanded={expanded}
          reviewDateCreated={outOfNetworkReview.dateCreated}
          reviewLastUpdated={outOfNetworkReview.lastUpdated}
          reviewStatus={outOfNetworkReview.reviewStatus}
          reviewOutcome={outOfNetworkReview.reviewOutcome}
          reviewType={outOfNetworkReview.reviewType}
          reviewCreatedByName={outOfNetworkReview.createdByName}
          reviewCompletedByName={outOfNetworkReview.completedByName}
          reviewDateCompleted={outOfNetworkReview.dateCompleted}
          reviewText={outOfNetworkReview.oonExceptionReasoning}
          clinicalReviewView={clinicalReviewView}
          unexpandable={unexpandable}
          {...otherProps}
        />
        <Collapse
          in={expanded}
          timeout={100}
          unmountOnExit
          style={{ margin: theme.spacing(0, 3, 3, clinicalReviewView ? 2 : 0), ...reviewDetailsCollapseStyle }}
        >
          <OutOfNetworkReviewReadOnly
            outOfNetworkReview={outOfNetworkReview}
            oonReviewConfig={oonReviewConfig}
            allowedReviewOutcomes={allowedReviewOutcomes}
          />
        </Collapse>
      </div>
    );
  }
);

export default OutOfNetworkReviewComponent;
