import React from "react";
import { colorsLight, today, formatDateStr, isPendingReason, pendingReasonMessage } from "@coherehealth/common";
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { warn } from "logger";
import { CohereDisclaimerFooter, CohereServiceRequestPDFHeader, TextField } from "util/PDFUtils";
import { isAuthStatus } from "util/serviceRequest";

import { FaxAttachmentStatus, ServiceRequestStatus } from "../checkStatusApi";
import { lessAggressiveStatusCopy, getColorForAuthStatus } from "../ResultCard";
import { getHealthPlanContactInfo } from "../../../contact";

interface Props {
  statusResult: ServiceRequestStatus | FaxAttachmentStatus;
}

/**
 * Renders a pdf with @react-pdf package https://react-pdf.org/components
 * For some reason the logo image does not get imported with storybook, but works on the actual package.
 *
 * @param serviceRequest
 * @constructor
 */
export default function StatusSummaryPdf({ statusResult }: Props) {
  const todayDateDisplay = formatDateStr(today().toISOString());

  let statusDisplay, submissionDateDisplay, authorizationNumDisplay, statusColor, phone, contactName, email;

  if (statusResult.resultType === "serviceRequest") {
    const { authStatus, intakeTimestamp, authNumber, pendingReason, healthPlanName, delegatedVendor } = statusResult;
    if (!isAuthStatus(authStatus)) {
      warn(`Unable to display result card for status ${authStatus}`);
      return null;
    }
    statusDisplay = lessAggressiveStatusCopy(delegatedVendor, healthPlanName)[authStatus];
    if (authStatus === "PENDING" && pendingReason && isPendingReason(pendingReason)) {
      statusDisplay += `: ${pendingReasonMessage(pendingReason)}`;
    }
    submissionDateDisplay = formatDateStr(intakeTimestamp);
    authorizationNumDisplay = authNumber;
    statusColor = getColorForAuthStatus(authStatus);
    ({ email, phone, contactName } = getHealthPlanContactInfo(healthPlanName));
  } else {
    statusDisplay = "Fax Received";
    submissionDateDisplay = formatDateStr(statusResult.dateCreated);
    statusColor = "warning";
    ({ email, phone, contactName } = getHealthPlanContactInfo(undefined));
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <CohereServiceRequestPDFHeader phone={phone} contactName={contactName} email={email} />
        <View style={styles.headerSection}>
          <Text style={styles.heading}>Pre-authorization status summary</Text>
          <Text style={[styles.label, { paddingTop: 4 }]}>Created on {todayDateDisplay}</Text>
        </View>
        <View style={styles.bodySection}>
          <TextField
            styleOverrides={{ body: { ...styles.statusText, ...styles[statusColor as keyof typeof styles] } }}
            label="Status"
            value={statusDisplay}
          />
          <View style={styles.row}>
            <TextField
              styleOverrides={{ textField: styles.rowTextField }}
              label="Submission date"
              value={submissionDateDisplay}
            />
            <TextField
              styleOverrides={{ textField: styles.rowTextField }}
              label="Tracking #"
              value={statusResult.cohereId}
            />
            <TextField
              styleOverrides={{ textField: styles.rowTextField }}
              label="Authorization #"
              value={authorizationNumDisplay ? authorizationNumDisplay : "Not available"}
            />
          </View>
        </View>
        <View style={styles.footerSection}>
          <Text style={[styles.noPaymentGuarantee]}>
            Please note that this form does not represent a guarantee of payment.
          </Text>
          <CohereDisclaimerFooter />
        </View>
      </Page>
    </Document>
  );
}

/**
 * Styles used for the pdf.
 * The numeric values are in "pt" units, (not "px").
 * This is pretty similar to css, but there are some quirks, see https://react-pdf.org/styling
 */
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    padding: 56,
  },
  headerSection: {
    paddingBottom: 24,
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: colorsLight.gray.divider,
  },
  bodySection: {
    width: "100%",
    padding: "24pt 0",
    borderBottomWidth: 1,
    borderBottomColor: colorsLight.gray.divider,
  },
  statusText: {
    fontSize: 14,
    fontWeight: 600,
  },
  success: {
    color: "#04b86c",
  },
  disabled: {
    color: "#6f7580",
  },
  warning: {
    color: "#cb7f00",
  },
  error: {
    color: "#e53935",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  rowTextField: {
    marginRight: 24,
  },
  footerSection: {
    paddingTop: 24,
    width: "100%",
  },
  heading: {
    fontSize: 12,
    fontWeight: 600,
  },
  label: {
    color: colorsLight.font.secondary,
    fontSize: 10,
    paddingBottom: 4,
  },
  noPaymentGuarantee: {
    color: colorsLight.font.main,
    fontWeight: 600,
    fontSize: 10,
    paddingBottom: 16,
  },
});
