import React from "react";
import HeaderAndProgressBar from "../HeaderAndProgressBar";
import { H1, H6 } from "@coherehealth/common";
import ClipboardSuccessIcon from "../../images/ClipboardSuccessIcon";
import FullScreenDisplay, { FullScreenDisplayProps } from "./FullScreenDisplay";

export default function OnboardingVerificationEmailSent() {
  const fullScreenDisplayProps: FullScreenDisplayProps = {
    headerAndProgressBar: <HeaderAndProgressBar stepNumber={0} showProgressBar={false} />,
    visualComponent: <ClipboardSuccessIcon />,
    header: <H1>Please check your email to activate your account.</H1>,
    subtext: <H6>After activation, you'll be able to access Cohere and start submitting requests</H6>,
    widthOverrides: {
      headerWidth: 950,
      subtextWidth: 653,
    },
  };

  return <FullScreenDisplay {...fullScreenDisplayProps} />;
}
