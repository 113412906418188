import React from "react";

export default function FaxResultImage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="40" cy="40" r="40" fill="#FFF5E5" />
      <path d="M54.8468 23.1055H33.7715V26.6394H54.8468V23.1055Z" fill="#384358" />
      <path d="M48.8492 14H35.084V26.6253H53.5231V18.6739L48.8492 14Z" fill="white" />
      <path
        d="M56.1157 26.625H17.8694C17.3421 26.625 16.9004 27.0525 16.9004 27.5797V60.5251C16.9004 61.0523 17.3279 61.4798 17.8694 61.4798H56.1014C56.6286 61.4798 57.0704 61.0523 57.0704 60.5251V27.594C57.0704 27.0525 56.6429 26.625 56.1157 26.625Z"
        fill="#DEDEDE"
      />
      <path d="M53.7496 29.8438H33.7715V36.3274H53.7496V29.8438Z" fill="#89D3DD" />
      <path
        d="M36.9919 42.2694H33.857C33.8142 42.2694 33.7715 42.2267 33.7715 42.1839V39.0347C33.7715 38.992 33.8142 38.9492 33.857 38.9492H36.9919C37.0347 38.9492 37.0774 38.992 37.0774 39.0347V42.1839C37.0917 42.2267 37.0489 42.2694 36.9919 42.2694Z"
        fill="#A1AAB4"
      />
      <path
        d="M45.3284 42.2694H42.1792C42.1365 42.2694 42.0938 42.2267 42.0938 42.1839V39.0347C42.0938 38.992 42.1365 38.9492 42.1792 38.9492H45.3284C45.3712 38.9492 45.4139 38.992 45.4139 39.0347V42.1839C45.4139 42.2267 45.3854 42.2694 45.3284 42.2694Z"
        fill="#A1AAB4"
      />
      <path
        d="M53.6644 42.2694H50.5152C50.4724 42.2694 50.4297 42.2267 50.4297 42.1839V39.0347C50.4297 38.992 50.4724 38.9492 50.5152 38.9492H53.6644C53.7071 38.9492 53.7499 38.992 53.7499 39.0347V42.1839C53.7499 42.2267 53.7071 42.2694 53.6644 42.2694Z"
        fill="#A1AAB4"
      />
      <path
        d="M36.9919 48.4139H33.857C33.8142 48.4139 33.7715 48.3712 33.7715 48.3284V45.1792C33.7715 45.1365 33.8142 45.0938 33.857 45.0938H36.9919C37.0347 45.0938 37.0774 45.1365 37.0774 45.1792V48.3284C37.0917 48.3712 37.0489 48.4139 36.9919 48.4139Z"
        fill="#A1AAB4"
      />
      <path
        d="M45.3284 48.4139H42.1792C42.1365 48.4139 42.0938 48.3712 42.0938 48.3284V45.1792C42.0938 45.1365 42.1365 45.0938 42.1792 45.0938H45.3284C45.3712 45.0938 45.4139 45.1365 45.4139 45.1792V48.3284C45.4139 48.3712 45.3854 48.4139 45.3284 48.4139Z"
        fill="#A1AAB4"
      />
      <path
        d="M53.6644 48.4139H50.5152C50.4724 48.4139 50.4297 48.3712 50.4297 48.3284V45.1792C50.4297 45.1365 50.4724 45.0938 50.5152 45.0938H53.6644C53.7071 45.0938 53.7499 45.1365 53.7499 45.1792V48.3284C53.7499 48.3712 53.7071 48.4139 53.6644 48.4139Z"
        fill="#A1AAB4"
      />
      <path
        d="M36.9919 54.5546H33.857C33.8142 54.5546 33.7715 54.5118 33.7715 54.4691V51.3199C33.7715 51.2771 33.8142 51.2344 33.857 51.2344H36.9919C37.0347 51.2344 37.0774 51.2771 37.0774 51.3199V54.4691C37.0917 54.5118 37.0489 54.5546 36.9919 54.5546Z"
        fill="#A1AAB4"
      />
      <path
        d="M45.3284 54.5546H42.1792C42.1365 54.5546 42.0938 54.5118 42.0938 54.4691V51.3199C42.0938 51.2771 42.1365 51.2344 42.1792 51.2344H45.3284C45.3712 51.2344 45.4139 51.2771 45.4139 51.3199V54.4691C45.4139 54.5118 45.3854 54.5546 45.3284 54.5546Z"
        fill="#A1AAB4"
      />
      <path
        d="M53.6644 54.5546H50.5152C50.4724 54.5546 50.4297 54.5118 50.4297 54.4691V51.3199C50.4297 51.2771 50.4724 51.2344 50.5152 51.2344H53.6644C53.7071 51.2344 53.7499 51.2771 53.7499 51.3199V54.4691C53.7499 54.5118 53.7071 54.5546 53.6644 54.5546Z"
        fill="#A1AAB4"
      />
      <path
        d="M30.4368 58.26C26.7177 58.83 22.9985 58.83 19.2793 58.26C19.4645 46.9743 19.4645 35.6885 19.2793 24.3884C22.9985 23.8184 26.7177 23.8184 30.4368 24.3884C30.2516 35.6742 30.2516 46.9743 30.4368 58.26Z"
        fill="#384358"
      />
      <path d="M48.8477 18.6739H53.5216L48.8477 14V18.6739Z" fill="#C2C2C2" />
      <path d="M50.6857 19.8984H37.918V20.7107H50.6857V19.8984Z" fill="#C2C2C2" />
      <path d="M50.6857 22.6914H37.918V23.5036H50.6857V22.6914Z" fill="#C2C2C2" />
      <circle cx="56.6365" cy="26.9978" r="9.69314" fill="white" />
      <path
        d="M56.6982 17.0547C51.1734 17.0547 46.6895 21.5386 46.6895 27.0634C46.6895 32.5882 51.1734 37.0722 56.6982 37.0722C62.223 37.0722 66.7069 32.5882 66.7069 27.0634C66.7069 21.5386 62.223 17.0547 56.6982 17.0547ZM57.6991 32.0678H55.6973V30.066H57.6991V32.0678ZM57.6991 28.0643H55.6973V22.0591H57.6991V28.0643Z"
        fill="#CB7F00"
      />
    </svg>
  );
}
