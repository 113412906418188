import { createContext } from "react";
import { ReferralRequestWorflowStep } from "@coherehealth/core-platform-api";

interface ReferralsNavigationContextType {
  workflowStep?: ReferralRequestWorflowStep;
  navigateBack?: () => void;
  isFaxReferralsFlow?: boolean;
  handleCancel?: () => void;
}

export const ReferralsNavigationContext = createContext<ReferralsNavigationContextType>({});
