import {
  ServiceRequestResponse,
  PendingReason,
  ClinicalService,
  AuthCategoryResponse,
  PALCategory,
} from "@coherehealth/core-platform-api";
import { COHERE_NAME } from "./healthPlanUtils";
import { AuthStatus } from "@coherehealth/core-platform-api";
const pendingReasonCopy: Record<PendingReason, string> = {
  MISSING_CLINICAL_INFO: "Missing information (clinical)",
  MISSING_NON_CLINICAL_INFO: "Missing information (non-clinical)",
  RN_REVIEW: "In clinical review",
  MD_REVIEW: "In MD review",
  NEW_HIRE_AUDIT_REVIEW: "In new hire audit review",
  PEER_TO_PEER_REVIEW: "In peer-to-peer review",
  OSCAR: "Oscar",
  PENDING_ASSESSMENT_SUBMISSION: "Assessment ready",
  OTHER: "",
  AUDIT_REVIEW: "Audit Review",
  NUDGE_OUTREACH: "Nudge outreach",
  ADMIN_VOID: "Admin Void",
  OUT_OF_SCOPE: "In out of scope audit",
  SCHEDULING_OUTREACH: "Peer to peer scheduling",
  OUT_OF_NETWORK_REVIEW: "Out of network review",
  LETTER_WRITING: "Letter writing",
};

export function pendingReasonMessage(pendingReason?: PendingReason): string {
  return pendingReason ? pendingReasonCopy[pendingReason] : "";
}
export function isPendingReason(ipt: unknown): ipt is PendingReason {
  return typeof ipt === "string" && pendingReasonCopy[ipt as PendingReason] !== undefined;
}

export function statusCopy(delegatedVendor?: string | undefined, healthPlanName?: string): Record<AuthStatus, string> {
  delegatedVendor = delegatedVendor === "HealthHelpV2" ? "HealthHelp" : delegatedVendor;
  return {
    DRAFT: "Draft",
    PENDING_FACILITY_VERIFICATION: "Pending: Imaging facility verification",
    PENDING_EXTERNAL_DETERMINATION:
      delegatedVendor && delegatedVendor !== COHERE_NAME
        ? `In delegated vendor review: ${delegatedVendor}`
        : `Pending external review${healthPlanName ? `: ${healthPlanName}` : ""}`,
    INTAKE: "Intake",
    PENDING: "Pending",
    PENDING_ASSESSMENT: "Pending",
    NO_LONGER_NEEDED: "Approved",
    WITHDRAWN: "Withdrawn",
    APPROVED: "Approved",
    PARTIALLY_APPROVED: "Partially Approved",
    RECOMMENDED_PARTIAL_APPROVAL: "Recommended Partial Approval",
    RECOMMENDED_DENIAL: "Recommended Denial",
    DENIED: "Denied",
    VOIDED: "Voided",
    DISMISSED: "Dismissed",
    POST_DENIAL_PEER_TO_PEER: "In peer-to-peer review",
  };
}

export function getClinicalServicesText(clinicalServices?: ServiceRequestResponse["clinicalServices"]) {
  if (clinicalServices && clinicalServices?.length > 0) {
    return clinicalServices.map((cs) => cs?.name).join(", ");
  }
  return "";
}

export const extractClinicalServicesText = (
  clinicalServices: Array<Pick<ClinicalService, "serviceCategoryName" | "authCategory" | "name"> | null>,
  authCategory?: AuthCategoryResponse,
  authSubcategory?: string,
  palCategory?: Partial<Pick<PALCategory, "name">>,
  emptyText = "Request"
) => {
  const clinicalServicesNotEmpty = clinicalServices.filter(notEmpty);
  const serviceCategoryName =
    clinicalServicesNotEmpty.length > 0 ? clinicalServicesNotEmpty[0].serviceCategoryName : undefined;
  const authCategoryClinicalService = clinicalServicesNotEmpty?.find(
    (cs) => cs?.authCategory && authCategory && cs?.authCategory === authCategory?.enumName
  );
  const nonAuthCategoryClinicalServices = clinicalServicesNotEmpty?.filter(
    (cs) => !cs?.authCategory || cs?.authCategory !== authCategory?.enumName
  );
  if (authCategoryClinicalService) {
    return `${authCategoryClinicalService.name ?? ""}${
      nonAuthCategoryClinicalServices && nonAuthCategoryClinicalServices.length > 0
        ? `, ${getClinicalServicesText(nonAuthCategoryClinicalServices)}`
        : ""
    }`;
  } else if (authCategory || authSubcategory) {
    return `Inpatient ${authSubcategory ?? authCategory?.name ?? serviceCategoryName ?? ""}${
      clinicalServicesNotEmpty && clinicalServicesNotEmpty.length > 0
        ? `, ${getClinicalServicesText(clinicalServicesNotEmpty)}`
        : ""
    }`;
  }
  return getClinicalServicesText(clinicalServicesNotEmpty) || palCategory?.name || emptyText;
};

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
