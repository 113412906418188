import React, { HTMLAttributes, PropsWithChildren } from "react";

import { makeStyles } from "@material-ui/core/styles";

const useStylesPrimaryDrawerContent = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.drawer.main,
    padding: theme.spacing(9, 4, 4, 4),
    height: "100%",
  },
}));

export default function DoubleDrawerPrimaryContent({
  children,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  const { container } = useStylesPrimaryDrawerContent();

  return (
    <div className={container} {...props}>
      {children}
    </div>
  );
}
