import config from "api/config";
import { error as logError } from "logger";

// The load balancer will send the requests to the appropriate lambda function
const baseUrl = config.UNAUTH_STATUS_CHECK_URL;

const statusCheckPath = "status_check";

interface StatusCheckRequest {
  cohereId?: string;
  memberId?: string;
  dateOfBirth?: string;
}

export interface StatusCheckResponse {
  count?: number;
  results?: Array<ServiceRequestStatus | FaxAttachmentStatus>;
}

export interface ServiceRequestStatus {
  resultType: "serviceRequest";
  id: string;
  cohereId: string;
  authNumber?: string;
  authStatus?: string;
  pendingReason?: string;
  intakeTimestamp?: string;
  procedureCodes?: string[];
  startDate?: string;
  endDate?: string;
  healthPlanName?: string;
  delegatedVendor?: string;
  withdrawnReason?: string;
}

export interface FaxAttachmentStatus {
  resultType: "faxAttachment";
  cohereId: string;
  dateCreated?: string;
}

export const checkSRStatus = async (request: StatusCheckRequest): Promise<StatusCheckResponse> => {
  const { cohereId, memberId, dateOfBirth } = request;
  if (!(cohereId || (memberId && dateOfBirth))) {
    // need either a cohereId or memberId/dob pair
    throw Error("Must have cohereId or memberId and dob");
  }

  const url = new URL(statusCheckPath, baseUrl);
  const response = await fetch(url.toString(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  });

  if (response.ok) {
    const result = await response.json();
    if (typeof result === "object") {
      return result;
    }
    return JSON.parse(result);
  } else if (response.status === 404) {
    return { results: [], count: 0 };
  } else {
    let message = `${response.status} status from ${url} - `;
    if (cohereId) {
      message += `cohereId: ${cohereId}`;
    } else {
      // can't log memberId b/c that's PHI
      message += "memberId/dob search";
    }
    logError(message);
    throw Error(message);
  }
};
