import { AuthStatus, PendingReason, PhoneNumber, Recipient } from "@coherehealth/core-platform-api";
import { ReviewOutcome } from "@coherehealth/qm-api";

export function getReviewOutcomeForOutreachCase(
  authStatus: AuthStatus | undefined,
  pendingReason: PendingReason | undefined
): ReviewOutcome {
  if (authStatus === "WITHDRAWN") {
    return "WITHDRAWN";
  } else if (authStatus === "APPROVED") {
    return "APPROVED";
  } else if (authStatus === "RECOMMENDED_DENIAL") {
    return "RECOMMENDED_DENIAL";
  } else if (authStatus === "RECOMMENDED_PARTIAL_APPROVAL") {
    return "RECOMMENDED_PARTIAL_APPROVAL";
  } else {
    if (pendingReason === "MISSING_CLINICAL_INFO" || pendingReason === "MISSING_NON_CLINICAL_INFO") {
      return "PENDING_MISSING_CLINICAL_INFO";
    } else if (pendingReason === "RN_REVIEW") {
      return "PENDING_RN_REVIEW";
    } else if (pendingReason === "MD_REVIEW") {
      return "PENDING_MD_REVIEW";
    } else if (pendingReason === "PEER_TO_PEER_REVIEW") {
      return "PENDING_P2P_REVIEW";
    } else if (pendingReason === "SCHEDULING_OUTREACH") {
      return "PENDING_SCHEDULING_OUTREACH";
    } else {
      return "PENDING_NUDGE_OUTREACH";
    }
  }
}

export function getPhoneNumberForOutreachCase(
  providerPhoneNumber: PhoneNumber | undefined,
  memberPhoneNumber: PhoneNumber | undefined
): string | undefined {
  if (providerPhoneNumber) {
    return providerPhoneNumber.number;
  } else if (memberPhoneNumber) {
    return memberPhoneNumber.number;
  } else {
    return "";
  }
}

export const OUTREACH_CONTACT_OPTIONS: Array<{ id: Recipient; label: string }> = [
  { id: "ORDERING_PROVIDER", label: "Provider" },
  { id: "MEMBER", label: "Member" },
  { id: "MEMBER_REPRESENTATIVE", label: "Member's representative" },
];
