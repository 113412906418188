export * as diagnosisCode from "./diagnosisCode/mirage.db";
export * as procedureCode from "./procedureCode/mirage.db";
export * as observationCode from "./observationCode/mirage.db";
export * as placeOfService from "./placeOfService/mirage.db";
export * as facility from "./facility/mirage.db";
export * as provider from "./provider/mirage.db";
export * as palCategory from "./palCategory/mirage.db";
export * as carePath from "./carePath/mirage.db";
export * as patient from "./patient/mirage.db";
export * as carePathJourney from "./carePathJourney/mirage.db";
export * as serviceRequest from "./serviceRequest/mirage.db";
export * as licenseAgreement from "./licenseAgreement/mirage.db";
export * as tag from "./tag/mirage.db";
