import React, { Dispatch, SetStateAction, useEffect, forwardRef, useContext, useState } from "react";
import { Body3, Caption } from "../../components/Typography";
import { Card, InlineButton, Chip, Tooltip } from "../../components";
import { colorsLight } from "../../themes";
import {
  formatDateStr,
  dateAndTimeParsedFromDateStr,
  compareISODates,
  getTheLatestFeedbackSegment,
} from "../../util/DateUtils";
import {
  AppealResponse,
  Attachment,
  AttachmentGuidelineTextHighlightsSnapshot,
  DocSegmentation,
  Segment,
} from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import {
  CardActionArea,
  makeStyles,
  Box,
  Slide,
  ButtonBase,
  CircularProgress,
  styled,
  ClickAwayListener,
  Divider,
  IconButton,
  TableRow,
  Table,
  TableCell,
  TableBody,
} from "@material-ui/core";
import FlipMove from "react-flip-move";
import { HighlightStateContext } from "../../util";
import MuiErrorIcon from "@material-ui/icons/Error";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { DocTypeItem } from "./PdfViewer";
import { IAttachmentsMultiSearchHolder } from "./useAttachmentsMultiSearch";
import useAttachmentsSidePanelScroll, { ATTACHMENT_SIDE_PANEL_ITEM_ID_PREFIX } from "./useAttachmentsSidePanelScroll";

interface Props {
  attachments: Attachment[];
  handleAttachmentClick: (index: number, landingPage?: number) => void;
  appealsForAuth?: AppealResponse[];
  attachmentIndexOpen?: number;
  openSidePanel?: boolean;
  sidePanelRef?: (node: React.ReactNode) => void;
  fromImageViewer?: boolean;
  withDocSegmentedSidePanel?: boolean;
  attachmentsInfo?: AttachmentInfo[];
  numberOfPages?: number;
  setAttachmentsInfo?: Dispatch<SetStateAction<AttachmentInfo[]>>;
  jumpToPage?: (pageIndex: number) => void;
  currentPage?: number;
  segmentsUpdated?: boolean;
  docSegmentationInfo?: AugmentedDocSegmentation[] | null;
  savingFeedbackUpdates?: boolean;
  attachmentGuidelineTextHighlightsSnapshot?: AttachmentGuidelineTextHighlightsSnapshot;
  setAttachmentGuidelineTextHighlightsSnapshot?: Dispatch<SetStateAction<AttachmentGuidelineTextHighlightsSnapshot>>;
  previousAttachmentsExpanded?: boolean;
  setPreviousAttachmentsExpanded?: Dispatch<React.SetStateAction<boolean>>;
  isContinuationRequest?: boolean;
  docTypeOptions?: DocTypeItem[];
  isCurrentAttachmentActive?: boolean;
  attachmentsMultiSearchHolder?: IAttachmentsMultiSearchHolder;
}
export interface AttachmentInfo {
  attachmentId?: string;
  fileUrl?: string;
  convertedToPDF?: boolean;
  numberOfPages?: number;
  uploadId?: string;
  nonOcr?: boolean;
  isCurrent?: boolean;
  isNewAttachment?: boolean;
  showNewChip?: boolean;
  appealId?: string;
}

export interface AugmentedSegment extends Segment {
  uid?: string;
}

export interface AugmentedDocSegmentation extends DocSegmentation {
  latestSegments?: AugmentedSegment[];
  segmentsUpdated?: boolean;
}

export default function AttachmentViewerSidePanel({
  attachments,
  handleAttachmentClick,
  appealsForAuth,
  openSidePanel,
  sidePanelRef,
  attachmentIndexOpen,
  fromImageViewer,
  withDocSegmentedSidePanel,
  attachmentsInfo,
  numberOfPages,
  setAttachmentsInfo,
  jumpToPage,
  currentPage,
  segmentsUpdated,
  docSegmentationInfo,
  savingFeedbackUpdates,
  setAttachmentGuidelineTextHighlightsSnapshot,
  previousAttachmentsExpanded,
  setPreviousAttachmentsExpanded,
  isContinuationRequest,
  docTypeOptions,
  isCurrentAttachmentActive,
  attachmentsMultiSearchHolder,
}: Props) {
  const { sidePanelInnerDivRef } = useAttachmentsSidePanelScroll({
    attachmentIndexOpen,
    attachmentsMultiSearchHolder,
    isCurrentAttachmentActive,
  });
  const classes = useStyles({ fromImageViewer, withDocSegmentedSidePanel });
  const feedbacks = docSegmentationInfo?.map((docSeg) => getTheLatestFeedbackSegment(docSeg));
  const sortedFeedbacks = feedbacks?.slice().sort((feedbackSegmentA, feedbackSegmentB) => {
    return compareISODates(feedbackSegmentA?.dateCreated, feedbackSegmentB?.dateCreated) || 0;
  });
  let latestFeedback = sortedFeedbacks?.[0];
  const footerText_cases = [
    "Your organization changes are saved and will persist for the next reviewer",
    "Attachments were last organized by " + latestFeedback?.createdBy,
  ];
  const final_text = segmentsUpdated ? footerText_cases[0] : footerText_cases[1];
  const previousAttachmentFirstIndex = attachments.findIndex(
    (it, attachmentIndex) => attachmentsInfo?.[attachmentIndex]?.isCurrent === false
  );

  // Need to do this instead of "return null;"
  // Because sidePanelRef has to always be referenced from parent component PdfViewer
  // Otherwise cannot keep track of currently opened sidepanel when switching between attachments
  if (isCurrentAttachmentActive === false) {
    return (
      <Slide in={openSidePanel} direction="right" mountOnEnter unmountOnExit ref={sidePanelRef}>
        <div ref={sidePanelInnerDivRef} style={{ display: "none" }}></div>
      </Slide>
    );
  }

  return (
    <Slide in={openSidePanel} direction="right" mountOnEnter unmountOnExit ref={sidePanelRef}>
      <div className={classes.sidePanel} ref={sidePanelInnerDivRef}>
        <div>
          {attachments?.map((attachment, attachmentIndex) => (
            <React.Fragment key={`${attachment.id}_${attachmentIndex}`}>
              {isContinuationRequest && (
                <>
                  {attachmentIndex === 0 && (
                    <Table className={classes.panelMessage}>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            style={{
                              width: "202px",
                              textAlign: "left",
                              borderBottom: "none",
                              padding: "0px 0px 0px 22px",
                            }}
                          >
                            <Body3>Uploaded since last decision</Body3>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "66px",
                              textAlign: "left",
                              borderBottom: "none",
                              padding: "0px 22px 0px 0px",
                            }}
                          >
                            <Divider />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                  {attachmentIndex === 0 && previousAttachmentFirstIndex === 0 && (
                    <Body3 style={{ padding: "8px 20px 0px 20px", textAlign: "center", color: "#191F2980" }}>
                      There are no additional attachments uploaded since the last decision
                    </Body3>
                  )}
                  {attachmentIndex === previousAttachmentFirstIndex && (
                    <Table className={classes.panelMessage}>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            style={{
                              width: "178px",
                              textAlign: "left",
                              borderBottom: "none",
                              padding: "0px 0px 0px 22px",
                            }}
                          >
                            <Body3>Previously uploaded</Body3>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "96px",
                              textAlign: "left",
                              borderBottom: "none",
                              padding: "0px",
                            }}
                          >
                            <Divider />
                          </TableCell>
                          <TableCell style={{ borderBottom: "none", padding: "0px 22px 0px 0px" }}>
                            <IconButton
                              style={{ padding: "0px" }}
                              onClick={() => setPreviousAttachmentsExpanded?.(!previousAttachmentsExpanded)}
                            >
                              {previousAttachmentsExpanded ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                </>
              )}
              {(previousAttachmentFirstIndex === -1 ||
                attachmentIndex < previousAttachmentFirstIndex ||
                (attachmentIndex >= previousAttachmentFirstIndex && previousAttachmentsExpanded)) && (
                <>
                  {withDocSegmentedSidePanel ? (
                    <DocSegmentedSidePanelItem
                      attachment={attachment}
                      isOpen={attachmentIndex === attachmentIndexOpen}
                      handleAttachmentClick={handleAttachmentClick}
                      attachmentIndex={attachmentIndex}
                      attachmentInfo={attachmentsInfo ? attachmentsInfo?.[attachmentIndex] : undefined}
                      key={attachment.id}
                      numberOfPages={numberOfPages}
                      setAttachmentsInfo={setAttachmentsInfo}
                      jumpToPage={jumpToPage}
                      currentPage={currentPage}
                      docSegmentationInfo={docSegmentationInfo?.find(
                        (docSeg) => docSeg.attachmentId === attachmentsInfo?.[attachmentIndex].uploadId
                      )}
                      setAttachmentGuidelineTextHighlightsSnapshot={setAttachmentGuidelineTextHighlightsSnapshot}
                      docTypeOptions={docTypeOptions}
                    />
                  ) : (
                    <AttachmentViewerSidePanelItem
                      attachment={attachment}
                      isOpen={attachmentIndex === attachmentIndexOpen}
                      handleAttachmentClick={handleAttachmentClick}
                      attachmentIndex={attachmentIndex}
                      key={attachment.id}
                    />
                  )}
                </>
              )}
            </React.Fragment>
          ))}
        </div>
        <div>
          {savingFeedbackUpdates ? (
            <CircularProgress size={18} className={classes.savingIndicator} />
          ) : (
            (latestFeedback?.createdBy || segmentsUpdated) &&
            withDocSegmentedSidePanel && <Body3 className={classes.footerPanel}>{final_text}</Body3>
          )}
        </div>
      </div>
    </Slide>
  );
}

interface SidePanelItemProps {
  attachment: Attachment;
  handleAttachmentClick: (index: number, landingPage?: number) => void;
  attachmentIndex: number;
  isOpen: boolean;
  attachmentInfo?: AttachmentInfo;
  numberOfPages?: number;
  setAttachmentsInfo?: Dispatch<SetStateAction<AttachmentInfo[]>>;
  jumpToPage?: (pageIndex: number) => void;
  currentPage?: number;
  docSegmentationInfo?: AugmentedDocSegmentation;
  setAttachmentGuidelineTextHighlightsSnapshot?: Dispatch<SetStateAction<AttachmentGuidelineTextHighlightsSnapshot>>;
  docTypeOptions?: DocTypeItem[];
}

function AttachmentViewerSidePanelItem({
  attachment,
  handleAttachmentClick,
  attachmentIndex,
  isOpen,
}: SidePanelItemProps) {
  const classes = useStyles({ isOpen });
  const { linking } = useContext(HighlightStateContext);
  return (
    <CardActionArea
      id={`${ATTACHMENT_SIDE_PANEL_ITEM_ID_PREFIX}${attachmentIndex}`}
      className={classes.cardActionArea}
      onClick={() => {
        if (!((window.getSelection()?.toString().length || 0) > 0)) {
          handleAttachmentClick(attachmentIndex);
        }
      }}
      disabled={!!linking}
    >
      <Card className={classes.attachmentCard}>
        <Box>
          <Body3>{attachment.name}</Body3>
        </Box>
        <Box className={classes.typeAndDateContainer}>
          <Caption color="textSecondary">
            {`${attachment.type ? `${attachment.type} • ` : ""} ${formatDateStr(attachment.dateCreated)}`}
          </Caption>
        </Box>
      </Card>
    </CardActionArea>
  );
}

const sortSegments = (segmentA: Segment, segmentB: Segment) => {
  if (segmentA.docDate && !segmentB.docDate) {
    return -1;
  } else if (!segmentA.docDate && segmentB.docDate) {
    return 1;
  } else if (!segmentA.docDate && !segmentB.docDate) {
    return (segmentA.docType || "").localeCompare(segmentB.docType || "");
  } else {
    const dateA = new Date(segmentA.docDate || "");
    const dateB = new Date(segmentB.docDate || "");
    return dateB.valueOf() - dateA.valueOf();
  }
};

// converts date string of the format MM/DD/YYYY into MM/DD/YY
export const formatDateToTwoDigitYear = (date: string) => {
  return `${date.slice(0, 6)}${date.slice(-2)}`;
};

function DocSegmentedSidePanelItem({
  attachment,
  handleAttachmentClick,
  attachmentIndex,
  isOpen,
  attachmentInfo,
  numberOfPages,
  setAttachmentsInfo,
  jumpToPage,
  currentPage,
  docSegmentationInfo,
  setAttachmentGuidelineTextHighlightsSnapshot,
  docTypeOptions,
}: SidePanelItemProps) {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const { date: createdDate, time: createdTime } = dateAndTimeParsedFromDateStr(attachment?.dateCreated);
  const appealLabel = attachmentInfo?.appealId ? ` with Appeal #${attachmentInfo.appealId}` : "";
  const { numberOfPages: numberOfPagesState } = attachmentInfo || {};
  const latestSegments = docSegmentationInfo?.latestSegments;
  const sortedLatestSegments = latestSegments?.slice().sort(sortSegments);
  const [showingNewAttachmentInfo, setShowingNewAttachmentInfo] = useState<boolean>(
    attachmentInfo?.isNewAttachment ? attachmentInfo?.isNewAttachment : false
  );
  useEffect(() => {
    if (!numberOfPagesState && !!numberOfPages && isOpen) {
      setAttachmentsInfo?.((prevAttachmentsInfo) =>
        prevAttachmentsInfo.map((attachmentInfo, index) => {
          if (index === attachmentIndex) {
            return { ...attachmentInfo, numberOfPages };
          } else {
            return attachmentInfo;
          }
        })
      );
    }
  }, [attachmentIndex, numberOfPages, setAttachmentsInfo, numberOfPagesState, isOpen]);
  const ocrWarn = attachmentInfo?.nonOcr;
  useEffect(() => {
    if (ocrWarn && isOpen) {
      setTimeout(() => setTooltipOpen(true), 1000);
    }
  }, [ocrWarn, isOpen]);
  useEffect(() => {
    if (ocrWarn) {
      setAttachmentGuidelineTextHighlightsSnapshot?.((prevSnapshot) => {
        return {
          ...prevSnapshot,
          nonOcrAttachmentIds:
            prevSnapshot?.nonOcrAttachmentIds && prevSnapshot?.nonOcrAttachmentIds?.length > 0
              ? !prevSnapshot.nonOcrAttachmentIds.includes(attachment.id)
                ? [...prevSnapshot?.nonOcrAttachmentIds, attachment.id]
                : [...prevSnapshot.nonOcrAttachmentIds]
              : [attachment.id],
        };
      });
    }
  }, [attachment.id, ocrWarn, setAttachmentGuidelineTextHighlightsSnapshot]);
  const { linking } = useContext(HighlightStateContext);
  const classes = useStyles({ isOpen, disabled: !!linking });
  const clearNewAttachmentInfo = (prevAttachmentsInfo: AttachmentInfo[], index: number): AttachmentInfo[] =>
    prevAttachmentsInfo.map((attachmentInfo, i) =>
      index === i ? { ...attachmentInfo, showNewChip: false, isNewAttachment: false } : attachmentInfo
    );

  return (
    <Box id={`${ATTACHMENT_SIDE_PANEL_ITEM_ID_PREFIX}${attachmentIndex}`} className={classes.attachmentSidePanelBox}>
      {attachmentInfo?.showNewChip && (
        <Box className={classes.newAttachmentChipBox}>
          <Chip
            label={"New"}
            size={"small"}
            onDelete={() => {
              setAttachmentsInfo?.((prevAttachmentsInfo) =>
                clearNewAttachmentInfo(prevAttachmentsInfo, attachmentIndex)
              );
              setShowingNewAttachmentInfo(false);
            }}
          ></Chip>
        </Box>
      )}
      <Box style={{ display: "inline-flex", marginLeft: ocrWarn ? "4px" : "22px" }}>
        {ocrWarn && (
          <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
            <Tooltip title="Text in this attachment may not be highlightable" placement="top-start" open={tooltipOpen}>
              <div
                style={{ paddingTop: "8px", fontSize: "14px", marginRight: "4px" }}
                onMouseOver={() => setTooltipOpen(true)}
                onMouseLeave={() => setTooltipOpen(false)}
              >
                <WarningIcon fontSize="inherit" />
              </div>
            </Tooltip>
          </ClickAwayListener>
        )}
        <InlineButton
          className={`${showingNewAttachmentInfo ? classes.newAttachmentNameButton : undefined} ${
            classes.attachmentNameButton
          }`}
          disabled={!!linking}
          onClick={() => {
            if (!((window.getSelection()?.toString().length || 0) > 0)) {
              if (!isOpen) {
                handleAttachmentClick(attachmentIndex);
              } else {
                jumpToPage?.(0);
              }
            }
          }}
        >
          {attachment.name}
        </InlineButton>
      </Box>
      <Box style={{ marginLeft: "22px" }}>
        <Body3 className={classes.attachmentCaption}>
          {`${
            numberOfPagesState && numberOfPagesState > 0
              ? `${numberOfPagesState} page${numberOfPagesState > 1 ? "s" : ""} •`
              : ""
          } Uploaded on ${formatDateToTwoDigitYear(createdDate)} at ${createdTime}${appealLabel}`}
        </Body3>
      </Box>
      {sortedLatestSegments && sortedLatestSegments.length > 0 ? (
        <Box mt={2} style={{ marginLeft: "22px" }}>
          <div className={classes.headerRow}>
            <div className={classes.docTypeColumn}>
              <Caption className={classes.headerCaption}>Type</Caption>
            </div>
            <div className={classes.docReportDateColumn}>
              <Caption className={classes.headerCaption}>Report Date</Caption>
            </div>
          </div>
        </Box>
      ) : null}
      <Box style={{ marginLeft: "22px" }}>
        <FlipMove>
          {sortedLatestSegments?.map((segment) => {
            return (
              <SegmentDisplay
                segment={segment}
                key={segment.uid}
                handleSegmentClick={() => {
                  const landingPage = segment.docPages?.[0] !== undefined ? segment.docPages?.[0] - 1 : -1;
                  if (!isOpen) {
                    handleAttachmentClick(attachmentIndex, landingPage);
                    setTimeout(() => {
                      jumpToPage?.(landingPage);
                    }, 1000);
                  } else {
                    jumpToPage?.(landingPage);
                  }
                }}
                isOpenSegment={
                  isOpen &&
                  currentPage !== undefined &&
                  segment.docPages !== undefined &&
                  segment.docPages[0] <= currentPage + 1 &&
                  currentPage + 1 <= segment.docPages[1]
                }
                docTypeOptions={docTypeOptions}
              />
            );
          })}
        </FlipMove>
      </Box>
    </Box>
  );
}

interface SegmentDisplayProps {
  handleSegmentClick: () => void;
  segment: Segment;
  isOpenSegment?: boolean;
  docTypeOptions?: DocTypeItem[];
}

const SegmentDisplay = forwardRef<HTMLButtonElement, SegmentDisplayProps>(
  ({ segment, handleSegmentClick, isOpenSegment, docTypeOptions }, ref) => {
    const { linking } = useContext(HighlightStateContext);
    const classes = useStyles({ isOpenSegment, disabled: !!linking });
    return (
      <ButtonBase className={classes.docInfoRow} onClick={handleSegmentClick} ref={ref} disabled={!!linking}>
        <div className={classes.selectedIndicator} />
        <div className={classes.docTypeColumn}>
          <Body3 className={classes.docInfoCell}>{`${
            segment.docType
              ? docTypeOptions?.find(({ id }) => id === segment.docType)?.label || segment.docType
              : "Document Type"
          }`}</Body3>
        </div>
        <div className={classes.docReportDateColumn}>
          <Body3 className={classes.docInfoCell}>
            {formatDateToTwoDigitYear(formatDateStr(segment.docDate)) || "MM/DD/YY"}
          </Body3>
        </div>
      </ButtonBase>
    );
  }
);

type StyleProps = {
  isOpen?: boolean;
  fromImageViewer?: boolean;
  isOpenSegment?: boolean;
  disabled?: boolean;
  withDocSegmentedSidePanel?: boolean;
};

const useStyles = makeStyles((theme) => ({
  sidePanel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    overflowY: "scroll",
    width: "264px",
    justifyContent: "flex-start",
    borderRight: ({ fromImageViewer }: StyleProps) => (fromImageViewer ? `1px solid ${colorsLight.gray.divider}` : ""),
  },
  panelMessage: {
    color: colorsLight.font.secondary,
    margin: ({ withDocSegmentedSidePanel }: StyleProps) =>
      withDocSegmentedSidePanel ? theme.spacing(2, 2, 0, 0) : theme.spacing(1, 0),
    width: "100%",
  },
  attachmentCard: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: theme.spacing(1),
    boxShadow: "none",
  },
  attachmentCaption: {
    color: ({ disabled }: StyleProps) => (disabled ? colorsLight.gray.dark : "#000000"),
    opacity: 0.6,
  },
  cardActionArea: {
    display: "flex",
    width: "216px",
    userSelect: "text",
    margin: theme.spacing(1, 2.75),
    padding: 0,
    border: ({ isOpen }: StyleProps) =>
      isOpen ? `2px solid ${colorsLight.primary.light}` : `1px solid ${colorsLight.gray.divider}`,
    borderRadius: theme.spacing(0.5),
  },
  typeAndDateContainer: {
    display: "flex",
    marginTop: theme.spacing(2),
  },
  headerRow: {
    display: "flex",
    marginBottom: "8px",
    width: "100%",
  },
  docInfoRow: ({ isOpenSegment }: StyleProps) => ({
    display: "flex",
    marginBottom: "8px",
    width: "100%",
    "&:hover": isOpenSegment
      ? {
          "& $selectedIndicator": {
            backgroundColor: theme.palette.primary.main,
          },
        }
      : {
          "& $docInfoCell": {
            fontWeight: 600,
          },
          "& $selectedIndicator": {
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.08)",
          },
        },
  }),
  docTypeColumn: {
    display: "flex",
    width: "70%",
    justifyContent: "flex-start",
    paddingTop: "1px",
  },
  docReportDateColumn: {
    display: "flex",
    width: "30%",
    justifyContent: "flex-start",
    paddingTop: "1px",
  },
  headerCaption: {
    fontWeight: 600,
    fontSize: "10.5px",
    color: ({ disabled }: StyleProps) => (disabled ? colorsLight.gray.dark : colorsLight.font.tertiary),
  },
  docTypeInfo: {
    color: colorsLight.font.secondary,
  },
  attachmentNameButton: {
    color: colorsLight.font.main,
    "&:hover, &:focus": {
      color: colorsLight.font.main,
      textDecoration: "underline",
    },
    overflowWrap: "anywhere",
    textAlign: "left",
    paddingBottom: "2px",
    userSelect: "text",
    textDecoration: ({ isOpen }: StyleProps) => (isOpen ? "underline" : "none"),
  },
  docInfoCell: {
    color: ({ isOpenSegment, disabled }: StyleProps) =>
      disabled ? colorsLight.gray.dark : isOpenSegment ? theme.palette.text.primary : theme.palette.text.secondary,
    fontWeight: ({ isOpenSegment }: StyleProps) => (isOpenSegment ? 600 : 500),
    textAlign: "left",
  },
  selectedIndicator: {
    width: "4px",
    backgroundColor: theme.palette.primary.light,
    height: "16px",
    borderRadius: "1px",
    position: "absolute",
    left: "-7px",
    display: ({ isOpenSegment }: StyleProps) => (isOpenSegment ? "block" : "none"),
    opacity: ({ disabled }: StyleProps) => (disabled ? 0.75 : 1),
  },
  footerPanel: {
    color: theme.palette.text.secondary,
    width: "220px",
    marginLeft: "12px",
    fontFamily: "Gilroy-Medium",
    fontSize: "13px",
    lineHeight: "15px",
    display: "flex",
    alignItems: "center",
    paddingBottom: "24px",
  },
  savingIndicator: {
    color: colorsLight.font.secondary,
    marginBottom: "24px",
  },
  newAttachmentNameButton: {
    color: theme.palette.primary.dark,
    paddingTop: "0px",
    "&:hover, &:focus": {
      color: theme.palette.primary.dark,
    },
  },
  newAttachmentChipBox: {
    marginLeft: "22px",
    display: "inline-flex",
  },
  newAttachmentChip: {
    display: "flex",
    padding: "2px 7px 1px 7px",
    alignItems: "flex-start",
    borderRadius: "25px",
    background: colorsLight.primary.faint20,
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    border: "25px",
  },
  attachmentSidePanelBox: {
    marginRight: "22px",
    marginLeft: "0",
    marginTop: "16px",
    marginBottom: "24px",
    display: "flex",
    flexDirection: "column",
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const WarningIcon = styled(MuiErrorIcon)(({ theme }) => ({
  color: "#FFBA6A",
}));
