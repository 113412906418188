// There is a mismatch from QM -> CP on healthplans
// This is a backup to using the fax attachment health plan name
export const translateServiceCaseHealthPlan = (healthPlan: string | undefined) => {
  if (healthPlan === "HEALTH_PARTNERS") {
    return "HEALTHPARTNERS";
  }
  if (healthPlan === "AVERA_HEALTH_PLANS") {
    return "Avera Health Plans";
  }
  if (healthPlan === "BCBS_SOUTH_CAROLINA") {
    return "BCBS South Carolina";
  }
  return healthPlan;
};
