import React, { Dispatch, ForwardedRef, SetStateAction, useCallback, useEffect, useRef, useState } from "react";

import {
  useGetServiceRequestRuleActions,
  ServiceRequestUpdatePayload,
  ServiceRequestResponse,
} from "@coherehealth/core-platform-api";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import {
  InlineButton,
  Caption,
  useFeature,
  formatDateStrAsEastern,
  HEALTH_HELP_NAME,
  useGetAuthorizationByIdWithFallback,
} from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, useTheme } from "@material-ui/core/styles";
import { appHeaderHeight, headerHeight } from "util/StyleConstants";

import { ServiceSummaryCardProps } from "./index";
import AttachmentSection from "../../Attachments/AttachmentSection";
import ClinicalAssessmentSection from "./ClinicalAssessmentSection";
import ServiceRequestFormSection, { SRFormViewState } from "./ServiceRequestFormSection";
import ServiceRequestIntegrationSummary from "components/IntegrationManagement/ServiceRequestIntegrationSummary";
import ServiceRequestHistory from "../../ServiceRequest/ReadonlyDetail/ServiceRequestHistory";
import ServiceRequestAuditHistory from "../../ServiceRequest/ReadonlyDetail/ServiceRequestAuditHistory";
import AutoApproveAlertModal from "./AutoApproveAlertModal";

import { useAuthorized } from "authorization";

import loadable from "@loadable/component";
import {
  useIsDelegatedToVendor,
  isTerminalStatus,
  canBeWithdrawn,
  useServiceRequestWithdrawn,
} from "../../../util/serviceRequest";
import NudgeOpportunitySection from "./NudgeOpportunitySection";
import RequestedByInfo from "../AuthorizationPatientSummary/RequestedByInfo";
import WithdrawButton from "../../ServiceRequest/WithdrawButton";
import WithdrawModal from "./WithdrawModal";
import { evalValidAuthorization } from "@coherehealth/common";
import useOpsEditDisabled from "hooks/useOpsEditDisabled";

const ReviewSection = loadable(() => import("components/ServiceRequest/ReviewSection"));

interface Props extends Omit<ServiceSummaryCardProps, "initialIsExpanded" | "onShowSummaryPrintout"> {
  srFormViewState: SRFormViewState;
  setSRFormViewState: (newState: SRFormViewState) => void;
  submitEditsOnDraftSrsToIntake?: boolean;
  hideServiceRequestEditWithdrawButtons?: boolean;
  targetReviewId?: string | null;
  setTargetReview?: Dispatch<SetStateAction<HTMLElement | null>>;
  initialIsExpanded?: boolean;
  clinicalAssessmentSectionRef?: ForwardedRef<HTMLElement>;
  populateDefaults?: boolean;
  setUpdatedServiceRequestPayload?: Dispatch<SetStateAction<ServiceRequestUpdatePayload | undefined>>;
  setIsEditView?: Dispatch<SetStateAction<boolean>>;
  setServiceRequest?: Dispatch<SetStateAction<ServiceRequestResponse | undefined>>;
  isFaxEdit?: boolean;
  adhocLetter?: boolean;
  singleAttachmentViewerRedesign?: boolean;
}

/**
 * Expanded sections for a ServiceRequestSummary
 *
 * Has 4 parts:
 * - The ServiceRequestForm, which has the 278 fields
 * - The Attachments
 * - The Clinical Assessment
 * - The Outreach Opportunities and their corresponding Outreach Attempts (READ ONLY)
 *
 * Each part has its own READ_ONLY and EDIT states
 */
export default function ServiceRequestSummaryDetails({
  serviceRequest,
  srFormViewState,
  setSRFormViewState,
  onEdit,
  submitEditsOnDraftSrsToIntake,
  hideServiceRequestEditWithdrawButtons = false,
  targetReviewId,
  setTargetReview,
  initialIsExpanded,
  clinicalAssessmentSectionRef,
  populateDefaults,
  setUpdatedServiceRequestPayload,
  setIsEditView,
  serviceRequestRefetch,
  setServiceRequest,
  isFaxEdit = false,
  adhocLetter,
  singleAttachmentViewerRedesign,
}: Props) {
  // Here would probably be a good place to put state/rest hooks that need to be shared between the different sections
  const [caqSkippedAA, setCaqSkippedAA] = useState<boolean>(false);
  const showReview = useAuthorized("VIEW_REVIEW");
  const { data: authorizationData, refetch: refreshAuthorization } = useGetAuthorizationByIdWithFallback({
    id: serviceRequest?.authorization?.id || "",
  });

  const canViewServiceRequestIntegration = useAuthorized("VIEW_PATIENT_SUMMARY_INTEGRATIONS_UI");
  const canShowHistory = useAuthorized("VIEW_SERVICE_REQUEST_AUDIT_LOG");

  const canViewOutreachOpportunities = useAuthorized("VIEW_OUTREACH_OPPORTUNITIES");

  const isSingleService = !Boolean(serviceRequest?.carePathJourney?.id);
  const isDelegatedToHealthHelp = useIsDelegatedToVendor(serviceRequest, [HEALTH_HELP_NAME]);
  // There are some old service requests in the system with a PAL category; these have no assessment
  const hasNoAssessment =
    (isDelegatedToHealthHelp && !serviceRequest?.vendorIdentifier) || (isSingleService && serviceRequest.palCategory);

  const [showHistory, setShowHistory] = useState(false);
  const [showClinicalAssessment, setShowClinicalAssessment] = useState<boolean>(false);

  const { data: ruleActions, refetch: simulateRuleRun } = useGetServiceRequestRuleActions({
    id: serviceRequest.id,
    queryParams: {
      persistRuleRunDescription: false,
    },
    lazy: true,
  });

  const [showApproveAlertModal, setShowApproveAlertModal] = useState(false);
  const reviewSectionRef = useRef<HTMLDivElement | null>(null);
  const { spacing } = useTheme();
  const scrollToReview = useCallback(() => {
    // delay this a bit to let the modal close
    setTimeout(() => {
      if (reviewSectionRef.current) {
        const reviewSectionOffsetToViewport = reviewSectionRef.current.getBoundingClientRect().top;
        window.scrollTo({
          top: reviewSectionOffsetToViewport + window.scrollY - appHeaderHeight() - headerHeight - spacing(2),
          behavior: "smooth",
        });
      }
    }, 200);
  }, [reviewSectionRef, spacing]);

  // used as a key for refetching nudge opportunities after updating a service request
  const [nudgeOpKey, setNudgeOpKey] = useState(0);
  const incrementNudgeOpKey = () => setNudgeOpKey(nudgeOpKey + 1);
  // used as a key for refetching reviews after updating a service request
  const [reviewKey, setReviewKey] = useState<number>(0);
  const incrementReviewKey = () => {
    setReviewKey(reviewKey + 1);
  };

  const internalOnEdit = () => {
    onEdit?.(); // refreshes authorizations list
    incrementNudgeOpKey();
  };

  const refreshAuthorizationAndEdit = useCallback(async () => {
    if (!isFaxEdit) {
      await onEdit?.();
      await refreshAuthorization();
    }
  }, [onEdit, refreshAuthorization, isFaxEdit]);

  useEffect(() => {
    if (ruleActions?.length && ruleActions[0].actionType === "APPROVE") {
      setShowApproveAlertModal(true);
    }
  }, [ruleActions]);

  const hasRelationships = serviceRequest.serviceRequestRelations?.length;
  const opsEditEnabled = !useOpsEditDisabled(serviceRequest);
  const auditServiceHistory = useFeature("auditServiceHistory");
  const {
    withdrawnReason,
    withdrawModalOpen,
    setWithdrawModalOpen,
    withdrawRequestor,
    setWithdrawRequestor,
    setWithdrawnReason,
    onWithdraw,
    submitting,
  } = useServiceRequestWithdrawn(serviceRequest, undefined, internalOnEdit);
  const isAuthViewOnlyUser = useAuthorized("AUTH_VIEW_ONLY");
  return (
    <Grid container spacing={3}>
      {!hasRelationships && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
      <Grid item xs={12}>
        <ServiceRequestFormSection
          authorization={evalValidAuthorization(serviceRequest, authorizationData)}
          refreshAuthorization={refreshAuthorizationAndEdit}
          serviceRequest={serviceRequest}
          viewState={srFormViewState}
          setViewState={setSRFormViewState}
          simulateRuleRun={showReview && !isTerminalStatus(serviceRequest) ? simulateRuleRun : undefined}
          submitEditsOnDraftSrsToIntake={submitEditsOnDraftSrsToIntake}
          hideEditWithdrawButtons={hideServiceRequestEditWithdrawButtons}
          populateDefaults={populateDefaults}
          incrementNudgeOpKey={incrementNudgeOpKey}
          incrementReviewKey={incrementReviewKey}
          setUpdatedServiceRequestPayload={setUpdatedServiceRequestPayload}
          setIsEditView={setIsEditView}
          serviceRequestRefetch={serviceRequestRefetch}
          setServiceRequest={setServiceRequest}
          isFaxEdit={isFaxEdit}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <AttachmentSection
          serviceRequest={serviceRequest}
          onEdit={internalOnEdit}
          singleAttachmentViewerRedesign={singleAttachmentViewerRedesign}
        />
      </Grid>

      <>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={10}>
          <RequestedByInfo
            serviceRequest={serviceRequest}
            serviceRequestRefetch={async () => {
              serviceRequestRefetch?.();
            }}
          />
        </Grid>
        {opsEditEnabled && canBeWithdrawn(serviceRequest) && !isAuthViewOnlyUser ? (
          <Grid item xs={2} style={{ display: "flex", justifyContent: "right" }}>
            <WithdrawButton
              serviceRequest={serviceRequest}
              buttonText={"Withdraw"}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setWithdrawModalOpen(true);
                event.stopPropagation();
              }}
              styles={{ padding: 0 }}
            />
          </Grid>
        ) : (
          <></>
        )}
      </>
      {canViewServiceRequestIntegration && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ServiceRequestIntegrationSummary serviceRequest={serviceRequest} onEdit={internalOnEdit} />
          </Grid>
        </>
      )}
      {canShowHistory && (
        <Grid item xs={12}>
          <InlineButton
            title="Toggle audit history"
            onClick={() => {
              if (canShowHistory) {
                setShowHistory(!showHistory);
              }
            }}
          >
            <Caption color="primary">
              {serviceRequest.authStatus === "DRAFT"
                ? "Draft"
                : "Submitted " + formatDateStrAsEastern(serviceRequest.intakeTimestamp)}
            </Caption>
          </InlineButton>
        </Grid>
      )}
      {showHistory && canShowHistory && (
        <Grid item xs={12}>
          {auditServiceHistory ? (
            <ServiceRequestAuditHistory serviceRequestId={serviceRequest.id} />
          ) : (
            <ServiceRequestHistory serviceRequestId={serviceRequest.id} />
          )}
        </Grid>
      )}
      {!hasNoAssessment && !caqSkippedAA && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <InlineButton
              onClick={() => {
                setShowClinicalAssessment(!showClinicalAssessment);
              }}
              disabled={serviceRequest?.isRestricted}
            >
              {showClinicalAssessment ? "Hide clinical assessment" : "Show clinical assessment"}
            </InlineButton>
          </Grid>
          {showClinicalAssessment && (
            <Grid container item xs={12} ref={clinicalAssessmentSectionRef as ForwardedRef<HTMLDivElement>}>
              <ClinicalAssessmentSection
                serviceRequest={serviceRequest}
                showReadOnlyVendorSummary={isDelegatedToHealthHelp}
                simulateRuleRun={showReview ? simulateRuleRun : undefined}
                initialIsExpanded={initialIsExpanded}
                setCaqSkippedAA={setCaqSkippedAA}
              />
            </Grid>
          )}
          {caqSkippedAA && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={10}>
                <RequestedByInfo
                  serviceRequest={serviceRequest}
                  serviceRequestRefetch={async () => {
                    serviceRequestRefetch?.();
                  }}
                />
              </Grid>
              {opsEditEnabled && canBeWithdrawn(serviceRequest) && !isAuthViewOnlyUser ? (
                <Grid item style={{ paddingLeft: 25 }}>
                  <WithdrawButton
                    serviceRequest={serviceRequest}
                    buttonText={"Withdraw"}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      setWithdrawModalOpen(true);
                      event.stopPropagation();
                    }}
                    styles={{ padding: 0 }}
                  />
                </Grid>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}

      {showReview && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <StyledReviewSectionContainer>
              <ReviewSection
                key={reviewKey}
                ref={reviewSectionRef}
                onFinishEditing={internalOnEdit}
                serviceRequest={serviceRequest}
                willAutoApprove={Boolean(ruleActions?.length && ruleActions[0].actionType === "APPROVE")}
                targetReviewId={targetReviewId}
                setTargetReview={setTargetReview}
                adhocLetter={adhocLetter}
              />
            </StyledReviewSectionContainer>
          </Grid>
          <AutoApproveAlertModal
            open={showApproveAlertModal && !isFaxEdit}
            handleClose={() => setShowApproveAlertModal(false)}
            scrollToReview={scrollToReview}
          />
        </>
      )}
      {canViewOutreachOpportunities && <NudgeOpportunitySection serviceRequest={serviceRequest} key={nudgeOpKey} />}
      {opsEditEnabled && canBeWithdrawn(serviceRequest) && (
        <WithdrawModal
          withdrawnReason={withdrawnReason}
          setWithdrawnReason={setWithdrawnReason}
          withdrawRequestor={withdrawRequestor}
          setWithdrawRequestor={setWithdrawRequestor}
          serviceRequestId={serviceRequest.id}
          serviceRequest={serviceRequest}
          withdrawnReasonOptionsList={serviceRequest.reviewOutcomeWithdrawOptions}
          open={withdrawModalOpen}
          onClose={() => {
            setWithdrawModalOpen(false);
            setWithdrawnReason("");
          }}
          onWithdraw={onWithdraw}
          submitting={submitting}
        />
      )}
    </Grid>
  );
}

const REVIEW_MARGIN_OFFSET_TO_LEAVE_SECTION_FLUSH_TO_CONTAINER = -25;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledReviewSectionContainer = styled("div")({
  marginLeft: REVIEW_MARGIN_OFFSET_TO_LEAVE_SECTION_FLUSH_TO_CONTAINER,
  marginRight: REVIEW_MARGIN_OFFSET_TO_LEAVE_SECTION_FLUSH_TO_CONTAINER,
});
// eslint-disable-next-line cohere-react/no-mui-styled-import
export const SectionHeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: theme.spacing(3),
  width: "100%",
}));
