import { H4, H6, RadioGroup } from "@coherehealth/common";
import { PalCheckFormSectionProps } from "./HealthPlanPalCheckFormSectionConfigs";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import { FacilityCategory } from "@coherehealth/core-platform-api";

export default function EncounterTypeSection({
  priorAuthRequirements,
  setPriorAuthRequirements,
  attemptedSubmit,
  resetPalResults,
  facilityBased,
  generalAuthSubmissionStyling,
  formContent,
  setFormContent,
}: PalCheckFormSectionProps) {
  const showErrorAndHelperText = (validEntry?: boolean) => {
    return attemptedSubmit && !validEntry;
  };

  const classes = useStyles();
  const headerText = generalAuthSubmissionStyling
    ? "Request details"
    : "Are any of the procedures you’re requesting inpatient?";

  return (
    <div style={{ marginTop: "-4px" }}>
      {generalAuthSubmissionStyling && <CustomSectionHeaderH4 data-public>{headerText}</CustomSectionHeaderH4>}
      {!generalAuthSubmissionStyling && <CustomSectionHeaderH6 data-public>{headerText}</CustomSectionHeaderH6>}
      <RadioGroup
        row
        error={showErrorAndHelperText(Boolean(priorAuthRequirements.encounterType))}
        helperText={showErrorAndHelperText(Boolean(priorAuthRequirements.encounterType)) && "Required"}
        className={classes.encounterTypeSelect}
        value={priorAuthRequirements.encounterType ? priorAuthRequirements.encounterType : ""}
        options={!generalAuthSubmissionStyling ? encounterTypeOptions : generalAuthEncounterTypeOptions}
        onChange={(type) => {
          const previousCareType = priorAuthRequirements.encounterType;
          const isInpatient = type === "INPATIENT";
          setPriorAuthRequirements({
            ...priorAuthRequirements,
            desiredProcedureCodes: !!previousCareType ? undefined : priorAuthRequirements.desiredProcedureCodes,
            encounterType: isInpatient ? "INPATIENT" : "OUTPATIENT",
            showPxCheckbox: isInpatient ? !priorAuthRequirements.showPxCheckbox : false,
            noPxServiceRequired: isInpatient ? priorAuthRequirements.showPxCheckbox === true : false,
            authCategory: undefined,
            authSubcategory: "",
          });
          resetPalResults();
          if (setFormContent && formContent) {
            setFormContent((prev) => ({ ...prev, placeOfService: null }));
          }
        }}
        data-public
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  encounterTypeSelect: {
    color: theme.palette.text.secondary,
  },
}));

type EncounterTypeOptions = {
  id: FacilityCategory;
  label: string;
};

const encounterTypeOptions: EncounterTypeOptions[] = [
  { id: "OUTPATIENT", label: "No" },
  { id: "INPATIENT", label: "Yes" },
];

const generalAuthEncounterTypeOptions: EncounterTypeOptions[] = [
  { id: "OUTPATIENT", label: "Outpatient" },
  { id: "INPATIENT", label: "Inpatient" },
];

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CustomSectionHeaderH4 = styled(H4)(({ theme }) => ({
  marginBottom: "7px",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CustomSectionHeaderH6 = styled(H6)(({ theme }) => ({
  marginBottom: "7px",
}));
