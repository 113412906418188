import { Dispatch, SetStateAction } from "react";
import { AuthFlowType, PriorAuthRequirements } from "./common";
import { AuthBuilderWorkflowStep, Patient, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import {
  H3,
  NavigationButton,
  MedicalIcon,
  H6,
  Caption,
  colorsLight,
  DrugIcon,
  containerSmallBreakpoint,
} from "@coherehealth/common";
import { Box, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import BackButton from "./BackButton";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import HeaderContainer from "components/AppHeader/HeaderContainer";
import { generateInitialPriorAuthRequirementsWithSuggestions } from "util/suggestionUtils";
import { slimFaxHeaderHeight } from "util/StyleConstants";
import FaxHeader from "components/DocumentViewer/FaxAttachment/FaxAuthBuilderPage/FaxHeader";
import ClipboardIcon from "components/images/ClipboardIcon";

interface HeaderProps {
  headerHeightWithNavButtons: number;
  authFlowType: AuthFlowType;
  setAuthFlowType: Dispatch<SetStateAction<AuthFlowType>>;
  setServiceRequestFormContents: Dispatch<SetStateAction<ServiceRequestFormContent[]>>;
  setPriorAuthRequirements: Dispatch<SetStateAction<PriorAuthRequirements>>;
  workflowStep: AuthBuilderWorkflowStep;
  setWorkflowStep: (workflowStep: AuthBuilderWorkflowStep) => void;
  pharmacyAuthBuilderFlowEnabled: boolean;
  hasPharmacy: boolean;
  atTopOfPage: boolean;
  draftServiceRequest: ServiceRequestResponse | null;
  navigateBack: () => void;
  navigateBackContinuations: () => void;
  isFaxAuthBuilderFlow: boolean;
  patient: Patient | null;
}

export default function AuthBuilderHeader({
  headerHeightWithNavButtons,
  authFlowType,
  setAuthFlowType,
  setServiceRequestFormContents,
  setPriorAuthRequirements,
  workflowStep,
  setWorkflowStep,
  pharmacyAuthBuilderFlowEnabled,
  hasPharmacy,
  atTopOfPage,
  draftServiceRequest,
  navigateBack,
  navigateBackContinuations,
  isFaxAuthBuilderFlow,
  patient,
}: HeaderProps) {
  const theme = useTheme();
  const onSelectMedical = () => {
    setAuthFlowType("MEDICAL");
    setWorkflowStep("GATHER_REQUIREMENTS_MEDICAL");
    setServiceRequestFormContents([]);
    setPriorAuthRequirements(generateInitialPriorAuthRequirementsWithSuggestions());
  };

  const onSelectPharmacy = () => {
    setWorkflowStep("GATHER_REQUIREMENTS_PHARMACY");
    setAuthFlowType("PHARMACY");
    setServiceRequestFormContents([
      {
        expeditedReason: "",
        facility: null,
        facilitySelectedTin: null,
        isExpedited: false,
        isInpatient: false,
        orderingProvider: null,
        orderingProviderSelectedTin: null,
        palCategory: null,
        performingProvider: null,
        performingProviderSelectedTin: null,
        placeOfService: null,
        primaryDiagnosisCode: null,
        procedureCodes: [],
        secondaryDiagnosisCodes: [],
        startDate: new Date(),
        unitType: undefined,
        units: "",
        approvedUnits: "",
        drugSelection: { drug: null, genericAllowed: true },
        facilitySelectedAddress: null,
        possibleAttachmentNudgeReasons: [],
        performingProviderSelectedAddress: null,
        orderingProviderSelectedAddress: null,
      },
    ]);
    setPriorAuthRequirements(generateInitialPriorAuthRequirementsWithSuggestions());
  };

  const HEADER_MAP: Record<AuthBuilderWorkflowStep, string> = {
    GATHER_REQUIREMENTS_MEDICAL: "Tell us about your request",
    GATHER_REQUIREMENTS_PHARMACY: "Tell us about your request",
    SELECT_SERVICE: "Add additional procedures",
    SELECT_SERVICES: "Select services",
    FILL_FORMS_CONTINUATION: "Fill in the details",
    FILL_FORMS_GENERAL_AUTH_SUBMISSION: "Fill in the details",
    //TODO: clean up by June 1, 2023 -- COH - 3422
    FILL_FORMS_FAX: "Tell us about your request",
    CLINICAL_ASSESSMENT: "Answer assessment questions",
    CLINICAL_ASSESSMENT_CONTINUATION: "Answer assessment questions",
    VENDOR_CLINICAL_ASSESSMENT: "Vendor clinical assessment",
    ADD_ATTACHMENTS: "Add attachments",
    ADD_ATTACHMENTS_CONTINUATION: "Add attachments",
    REVIEW_NUDGES: "Review recommendation",
    REVIEW: "Review before submitting",
    REVIEW_CONTINUATION: "Review services before submitting",
    PROVIDER_DETAILS: "Enter provider details",
    SERVICE_DETAILS: "Authorization check results",
    PRE_APPROVED_CARE_DETAILS: "Enter your details to claim care",
  };

  const headerTitle = HEADER_MAP[workflowStep];

  return isFaxAuthBuilderFlow ? (
    <>
      <FaxHeader height={slimFaxHeaderHeight} width={containerSmallBreakpoint} title={headerTitle} patient={patient} />
    </>
  ) : (
    <HeaderContainer height={headerHeightWithNavButtons}>
      {["GATHER_REQUIREMENTS_MEDICAL", "GATHER_REQUIREMENTS_PHARMACY", "PRE_APPROVED_CARE_DETAILS"].includes(
        workflowStep
      ) ? (
        <div style={{ marginTop: theme.spacing(4), justifySelf: "center", width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ marginRight: theme.spacing(1) }}>
              <ClipboardIcon width="32" height="32" viewBox="0 0.5 41 41" />
            </div>
            <H3 align="center" data-public>
              {HEADER_MAP[workflowStep]}
            </H3>
          </div>
          {pharmacyAuthBuilderFlowEnabled && hasPharmacy && (
            <div
              style={{
                marginTop: "24px",
                transition: " opacity .5s ease, height .5s ease",
                height: atTopOfPage ? "115px" : 0,
                overflow: atTopOfPage ? "visible" : "hidden",
                opacity: atTopOfPage ? 1 : 0,
              }}
            >
              {
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <>
                    <NavigationButton
                      icon={<MedicalIcon style={{ width: 40, height: 40 }} />}
                      selected={workflowStep === "GATHER_REQUIREMENTS_MEDICAL"}
                      onClick={onSelectMedical}
                    >
                      <div style={{ textAlign: "left" }}>
                        <H6 style={{ marginBottom: theme.spacing(0.5) }}>Medical</H6>
                        <Caption style={{ color: colorsLight.font.secondary }}>
                          Submit an auth for a medical service, procedure, or physician-administered medication.
                        </Caption>
                      </div>
                    </NavigationButton>
                    <Box width={"30px"} />
                    <NavigationButton
                      icon={<DrugIcon />}
                      selected={workflowStep === "GATHER_REQUIREMENTS_PHARMACY"}
                      onClick={onSelectPharmacy}
                    >
                      <div style={{ textAlign: "left" }}>
                        <H6 style={{ marginBottom: theme.spacing(0.5) }}>Pharmacy</H6>
                        <Caption style={{ color: colorsLight.font.secondary }}>
                          Submit an auth for a pharmacy-dispensed medication.
                        </Caption>
                      </div>
                    </NavigationButton>
                  </>
                </div>
              }
            </div>
          )}
        </div>
      ) : (
        <Grid container style={{ marginTop: theme.spacing(4) }}>
          <Grid item xs={3}>
            <BackButton
              workflowStep={workflowStep}
              authFlowType={authFlowType}
              draftServiceRequest={draftServiceRequest}
              navigateBack={navigateBack}
              navigateBackContinuations={navigateBackContinuations}
            />
          </Grid>
          <Grid item xs={6}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ marginRight: theme.spacing(1) }}>
                <ClipboardIcon width="32" height="32" viewBox="0 0.5 41 41" />
              </div>
              <H3 align="center" data-public>
                {headerTitle}
              </H3>
            </div>
          </Grid>
        </Grid>
      )}
    </HeaderContainer>
  );
}
