import { FiltersPopover, FilterValues, initialFilterValues } from "@coherehealth/common";
import { UseOtherAuthorizationProps } from "./useOtherAuthorization";

export const OtherServiceRequestsFilter = ({ otherAuthsProps }: UseOtherAuthorizationProps) => {
  const { setOtherSRsFilterValues, authFilterConfigs } = otherAuthsProps;
  const initialValues = initialFilterValues(authFilterConfigs, true);
  const buildQueryParams = (filterValues: FilterValues) => {
    let params = {};
    const authFilterValues = filterValues["authStatus"] as any[];
    const cptCodeFilterValues = filterValues["cptCodes"] as any[];
    const startDateAfterValues = filterValues["serviceDateAfter"] as any[];
    if (authFilterValues) {
      params = { ...params, authStatus: authFilterValues.map((i) => i.id) };
    }
    if (cptCodeFilterValues) {
      params = { ...params, cptCodes: cptCodeFilterValues.map((i) => i.code) };
    }
    if (startDateAfterValues) {
      params = { ...params, serviceDateAfter: startDateAfterValues };
    }
    setOtherSRsFilterValues(params);
  };
  return (
    <FiltersPopover
      filterConfigs={authFilterConfigs}
      initialValues={initialValues}
      onFiltersChange={(filterValues: FilterValues) => {
        buildQueryParams(filterValues);
      }}
      datatest-id="other-srs-filters"
      id="other-srs-filters"
    />
  );
};
