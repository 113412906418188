import {
  Body1,
  MultipleSelect,
  RadioGroup,
  RichTextEditor,
  SingleSelectDropdown,
  TextField,
} from "@coherehealth/common";
import {
  OutreachAttempt,
  OutreachOutcome,
  ServiceRequestResponse,
  OutreachType,
  NudgeInfo,
  PhoneNumber,
  AttemptChannel,
} from "@coherehealth/core-platform-api";
import { useTheme, Grid } from "@material-ui/core";
import PhoneInput from "common/PhoneInput";
import {
  nudgeTypeChangeQuestionText,
  NudgeTypeOption,
  NudgeTypeOptions,
  NudgeTypes,
} from "components/ServiceRequest/ReviewSection/NurseReview/NurseReviewEdit";
import React from "react";
import { isPhoneNumberValid } from "util/phoneUtils";
import { OutreachFormFields } from "components/ServiceRequest/ReviewSection/useOutreachAttempt";
import { OUTREACH_CONTACT_OPTIONS } from "./outreachUtil";

interface Props {
  serviceRequest: ServiceRequestResponse;
  outreachAttempt: OutreachAttempt;
  setOutreachAttempt: React.Dispatch<React.SetStateAction<OutreachAttempt>>;
  displayFieldConfig: OutreachFormFields;
  errorStates: OutreachFormFields;
  setErrorStates: React.Dispatch<React.SetStateAction<OutreachFormFields>>;
  fromClinicalReview?: boolean;
  setNurseReviewNudgeValue?: (fieldName: keyof NudgeInfo, index: number, value: string | boolean) => void;
  setNurseReviewNudges?: (selectedNudges: NudgeInfo[]) => void;
}

const OutreachForm = ({
  serviceRequest,
  outreachAttempt,
  setOutreachAttempt,
  displayFieldConfig,
  errorStates,
  setErrorStates,
  fromClinicalReview,
  setNurseReviewNudgeValue,
  setNurseReviewNudges,
}: Props) => {
  const theme = useTheme();

  const isNotification = outreachAttempt.outreachType === "NOTIFICATION";
  const isInBoundContact = outreachAttempt.contactType === "INBOUND";
  const memberAttempt = outreachAttempt.recipient === "MEMBER" || outreachAttempt.recipient === "MEMBER_REPRESENTATIVE";

  const getOutreachOutcomeOptions = () => {
    if (isNotification) {
      if (memberAttempt) {
        return memberOutreachOutcomeOptions;
      } else {
        return providerOutreachOutcomeOptions;
      }
    } else {
      return nonNotificationOutreachOutcomes;
    }
  };

  const getOutreachTypeOptions = () => {
    if (isInBoundContact) {
      return inBoundOutreachTypeOptions;
    } else {
      if (fromClinicalReview) {
        const reviewOutreachTypes = outboundOutreachTypeOptions.filter(
          (outreachType) => outreachType.id !== "SCHEDULING_OUTREACH"
        );
        return reviewOutreachTypes;
      } else {
        return outboundOutreachTypeOptions;
      }
    }
  };

  const updateNudgeInfo = (option: string, index: number, fieldName: keyof NudgeInfo) => {
    const isTrue = option === "true";
    setOutreachAttempt((prev) => {
      const newNudgeInfo = prev.nudgeInfo?.map((info, idx) => {
        if (idx === index) {
          return {
            ...info,
            [fieldName]: isTrue,
          };
        } else {
          return info;
        }
      });
      return {
        ...prev,
        nudgeInfo: newNudgeInfo,
      };
    });
    setNurseReviewNudgeValue?.(fieldName, index, isTrue);
  };

  const updateTypeOfNudge = (option: NudgeTypeOption[]) => {
    const selectedNudgeIds = option.map((x) => x.id);
    const { nudgeInfo: currentNudges } = outreachAttempt;
    const selectedNudges = selectedNudgeIds.map((nudgeId) => {
      const currValue = currentNudges?.find(({ nudgeType }) => nudgeType === nudgeId);

      return {
        nudgeType: nudgeId,
        nudgeReachedCorrectContact: !!currValue?.nudgeReachedCorrectContact,
        nudgeResultedInChange: !!currValue?.nudgeResultedInChange,
      };
    });
    setOutreachAttempt((prev) => {
      return {
        ...prev,
        nudgeInfo: selectedNudges,
      };
    });
    setErrorStates((errorStates) => ({
      ...errorStates,
      nudgeInfo: false,
    }));
    setNurseReviewNudges?.(selectedNudges);
  };

  const updateOutreachValueAndErrorStates = (
    outreachUpdate: Partial<OutreachAttempt>,
    errorUpdate: Partial<OutreachFormFields>
  ) => {
    setOutreachAttempt((prev) => {
      return {
        ...prev,
        ...outreachUpdate,
      };
    });
    setErrorStates((errorStates) => ({ ...errorStates, ...errorUpdate }));
  };

  return (
    <Grid
      container
      spacing={2}
      style={{
        padding: fromClinicalReview ? theme.spacing(2, 2) : theme.spacing(3, 3),
      }}
    >
      {displayFieldConfig.contactType && (
        <Grid item xs={12}>
          <RadioGroup
            row
            nowrap
            label="Contact type"
            options={[
              { id: "OUTBOUND", label: "Outbound" },
              { id: "INBOUND", label: "Inbound" },
            ]}
            value={outreachAttempt.contactType}
            onChange={(opt) => {
              updateOutreachValueAndErrorStates({ contactType: opt, outreachType: undefined }, { contactType: false });
            }}
            error={errorStates.contactType}
            helperText={errorStates.contactType && "Required"}
          />
        </Grid>
      )}

      {displayFieldConfig.outreachType && (
        <Grid item xs={12}>
          <SingleSelectDropdown
            label="Outreach type"
            value={outreachAttempt.outreachType || ""}
            onChange={(outReachtype) => {
              if (isValidOutreachType(outReachtype)) {
                updateOutreachValueAndErrorStates(
                  {
                    outreachType: outReachtype,
                    outreachOutcome: undefined,
                  },
                  { outreachType: false }
                );
              }
            }}
            options={getOutreachTypeOptions()}
            error={errorStates.outreachType}
            helperText={errorStates.outreachType && "Required"}
          />
        </Grid>
      )}

      {displayFieldConfig.attemptNumber && (
        <Grid item xs={12}>
          <SingleSelectDropdown
            label={"Select attempt number"}
            onChange={(attempt) => {
              updateOutreachValueAndErrorStates({ attemptNumber: parseInt(attempt) }, { attemptNumber: false });
            }}
            value={outreachAttempt?.attemptNumber?.toString() || ""}
            options={attemptNumberOptions}
            error={errorStates.attemptNumber}
            helperText={errorStates.attemptNumber && "Required"}
          />
        </Grid>
      )}

      {displayFieldConfig.recipient && (
        <Grid item xs={12}>
          <RadioGroup
            row
            nowrap
            label="Contact"
            options={OUTREACH_CONTACT_OPTIONS}
            value={outreachAttempt.recipient}
            onChange={(opt) => {
              updateOutreachValueAndErrorStates({ recipient: opt, outreachOutcome: undefined }, { recipient: false });
            }}
            error={errorStates.recipient}
            helperText={errorStates.recipient && "Required"}
          />
        </Grid>
      )}

      {displayFieldConfig.channel && (
        <Grid item xs={12}>
          <RadioGroup
            row
            nowrap
            label={fromClinicalReview ? "Outreach channel" : "Contact channel"}
            options={channelOptions}
            value={outreachAttempt.channel}
            onChange={(channel) => {
              updateOutreachValueAndErrorStates({ channel }, { channel: false });
            }}
          />
        </Grid>
      )}

      {displayFieldConfig.providerPhoneNumber && (
        <Grid item xs={12}>
          <PhoneInput
            type="text"
            label="Phone number"
            value={outreachAttempt.providerPhoneNumber || {}}
            error={
              (outreachAttempt.providerPhoneNumber &&
                !isPhoneNumberValid(outreachAttempt.providerPhoneNumber.number) &&
                outreachAttempt?.providerPhoneNumber.number !==
                  serviceRequest?.orderingProvider?.phoneNumbers?.[0]?.number) ||
              errorStates.providerPhoneNumber
            }
            onChange={(providerPhoneNumber) => {
              updateOutreachValueAndErrorStates({ providerPhoneNumber }, { providerPhoneNumber: false });
            }}
            helperText={getPhoneNumberHelperText(
              errorStates.providerPhoneNumber,
              outreachAttempt.providerPhoneNumber,
              outreachAttempt?.providerPhoneNumber?.number ===
                serviceRequest?.orderingProvider?.phoneNumbers?.[0]?.number
            )}
          />
        </Grid>
      )}

      {displayFieldConfig.memberPhoneNumber && (
        <Grid item xs={12}>
          <PhoneInput
            type="text"
            label="Phone number"
            value={outreachAttempt.memberPhoneNumber || {}}
            helperText={getPhoneNumberHelperText(errorStates.memberPhoneNumber, outreachAttempt.memberPhoneNumber)}
            error={
              (outreachAttempt.memberPhoneNumber && !isPhoneNumberValid(outreachAttempt.memberPhoneNumber.number)) ||
              errorStates.memberPhoneNumber
            }
            onChange={(memberPhoneNumber) => {
              updateOutreachValueAndErrorStates({ memberPhoneNumber }, { memberPhoneNumber: false });
            }}
          />
        </Grid>
      )}

      {displayFieldConfig.providerEmail && (
        <Grid item xs={12}>
          <TextField
            label="Email"
            value={outreachAttempt.providerEmail || ""}
            onChange={(input) => {
              updateOutreachValueAndErrorStates({ providerEmail: input.target.value }, { providerEmail: false });
            }}
            fullWidth
            error={errorStates.providerEmail}
            helperText={errorStates.providerEmail ? "Required" : ""}
          />
        </Grid>
      )}

      {displayFieldConfig.memberEmail && (
        <Grid item xs={12}>
          <TextField
            label="Email"
            value={outreachAttempt.memberEmail || ""}
            onChange={(input) => {
              updateOutreachValueAndErrorStates({ memberEmail: input.target.value }, { memberEmail: false });
            }}
            error={errorStates.memberEmail}
            helperText={errorStates.memberEmail ? "Required" : "Please provide a valid email address"}
            fullWidth
          />
        </Grid>
      )}

      {displayFieldConfig.providerFaxNumber && (
        <Grid item xs={12}>
          <PhoneInput
            type="text"
            label="Fax number"
            value={outreachAttempt.providerFaxNumber || {}}
            error={
              errorStates.providerFaxNumber ||
              (outreachAttempt.providerFaxNumber && !isPhoneNumberValid(outreachAttempt.providerFaxNumber?.number))
            }
            onChange={(providerFaxNumber) => {
              updateOutreachValueAndErrorStates({ providerFaxNumber }, { providerFaxNumber: false });
            }}
            helperText={getPhoneNumberHelperText(
              errorStates.providerFaxNumber,
              outreachAttempt.providerFaxNumber,
              false,
              true
            )}
          />
        </Grid>
      )}

      {displayFieldConfig.memberFaxNumber && (
        <Grid item xs={12}>
          <PhoneInput
            type="text"
            label="Fax number"
            value={outreachAttempt.memberFaxNumber || {}}
            helperText={getPhoneNumberHelperText(
              errorStates.memberFaxNumber,
              outreachAttempt.memberFaxNumber,
              false,
              true
            )}
            error={
              errorStates.memberFaxNumber ||
              (outreachAttempt.memberFaxNumber && !isPhoneNumberValid(outreachAttempt.memberFaxNumber?.number))
            }
            onChange={(memberFaxNumber) => {
              updateOutreachValueAndErrorStates({ memberFaxNumber }, { memberFaxNumber: false });
            }}
          />
        </Grid>
      )}

      {displayFieldConfig.nudgeInfo && (
        <>
          <Grid item xs={12}>
            <MultipleSelect<NudgeTypeOption>
              label="Type of nudge"
              fullWidth
              useOptions={() => ({
                optionsLoading: false,
                options: NudgeTypeOptions,
              })}
              selectedValue={NudgeTypeOptions.filter(({ id }) =>
                outreachAttempt.nudgeInfo?.map((nudge) => nudge.nudgeType)?.includes(id)
              )}
              getOptionLabel={(opt) => opt.label}
              setSelectedValue={updateTypeOfNudge}
              error={errorStates.nudgeInfo}
              helperText={errorStates.nudgeInfo ? "Required" : undefined}
            />
          </Grid>
          {outreachAttempt.nudgeInfo?.map((nudge, idx) => (
            <React.Fragment key={`${idx}-${nudge.nudgeType}-${nudge.nudgeDescription}`}>
              <Grid item xs={12}>
                <Body1>{nudge.nudgeType ? NudgeTypes[nudge.nudgeType] : ""}</Body1>
              </Grid>
              <Grid item xs={6}>
                <RadioGroup
                  row
                  nowrap
                  label={nudgeTypeChangeQuestionText(nudge.nudgeType)}
                  options={nudgeInfoOptions}
                  value={nudge.nudgeResultedInChange ? "true" : "false"}
                  onChange={(opt) => {
                    updateNudgeInfo(opt, idx, "nudgeResultedInChange");
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <RadioGroup
                  row
                  nowrap
                  label="Correct point of contact reached?"
                  options={nudgeInfoOptions}
                  value={nudge.nudgeReachedCorrectContact ? "true" : "false"}
                  onChange={(opt) => {
                    updateNudgeInfo(opt, idx, "nudgeReachedCorrectContact");
                  }}
                />
              </Grid>
            </React.Fragment>
          ))}
        </>
      )}

      {displayFieldConfig.outreachNotes && (
        <Grid item xs={12}>
          <RichTextEditor
            readonly={false}
            label="Outreach notes"
            htmlValue={outreachAttempt.outreachNotes || ""}
            setHtmlValue={(outreachNotes: string) => {
              updateOutreachValueAndErrorStates({ outreachNotes }, { outreachNotes: false });
            }}
            error={errorStates.outreachNotes}
            helperText={errorStates.outreachNotes ? "Required" : undefined}
          />
        </Grid>
      )}
      {displayFieldConfig.outreachOutcome && (
        <Grid item xs={12}>
          <SingleSelectDropdown
            label={"Outreach outcome"}
            value={outreachAttempt.outreachOutcome || ""}
            onChange={(outreachOutcome) => {
              if (isValidOutreachOutcome(outreachOutcome)) {
                updateOutreachValueAndErrorStates({ outreachOutcome }, { outreachOutcome: false });
              }
            }}
            options={getOutreachOutcomeOptions()}
            error={errorStates.outreachOutcome}
            helperText={getOutcomeHelperText(
              errorStates.outreachOutcome,
              outreachAttempt.channel,
              outreachAttempt.outreachOutcome
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

const providerOutreachOutcomeOptions = [
  { id: "OFFICE_STAFF_MEMBER_NOTIFIED", label: "Office staff member notified" },
  { id: "LEFT_SCRIPTED_MESSAGE", label: "Left scripted message" },
  { id: "P2P_RIGHTS_GIVEN", label: "Peer to peer rights given" },
  { id: "UNABLE_TO_REACH", label: "Unable to reach - Office closed" },
];

const memberOutreachOutcomeOptions = [
  { id: "MEMBER_NOTIFIED", label: "Member notified" },
  { id: "AUTHORIZED_REP_NOTIFIED", label: "Parent/guardian/authorized representative notified" },
  { id: "LEFT_SCRIPTED_MESSAGE", label: "Left scripted message" },
  { id: "UNABLE_TO_REACH_NO_ANSWER", label: "Unable to reach - No answer" },
];

const nonNotificationOutreachOutcomes = [
  { id: "SUCCESS", label: "Success" },
  { id: "FAILED", label: "Failed" },
];

const nudgeInfoOptions = [
  { id: "false", label: "No" },
  { id: "true", label: "Yes" },
];

const outboundOutreachTypeOptions = [
  { id: "MISSING_INFO_CLINICAL", label: "Missing information (clinical)" },
  { id: "MISSING_INFO_NON_CLINICAL", label: "Missing information (non-clinical)" },
  { id: "NOTIFICATION", label: "Notification" },
  { id: "SCHEDULING_OUTREACH", label: "Peer to peer scheduling" },
  { id: "NUDGES", label: "Nudges" },
];

const inBoundOutreachTypeOptions = [
  { id: "CREATE_A_NEW_SUBMISSION", label: "Create a new submission" },
  { id: "EXISTING_AUTH_STATUS_INQUIRY", label: "Existing auth status inquiry" },
  { id: "EXISTING_AUTH_UPDATE", label: "Existing auth update" },
  { id: "SCHEDULING_OUTREACH", label: "Peer to peer scheduling" },
  { id: "OTHER", label: "Other" },
];

const attemptNumberOptions = [
  { id: "1", label: "1st" },
  { id: "2", label: "2nd" },
  { id: "3", label: "3rd" },
  { id: "4", label: "4th+" },
];

const channelOptions: { id: AttemptChannel; label: string }[] = [
  { id: "PHONE", label: "Phone" },
  { id: "EMAIL", label: "Email" },
  { id: "FAX", label: "Fax" },
];

const getOutcomeHelperText = (outcomeError: boolean, channel: string, outreachOutcome: string | undefined) => {
  if (outcomeError) {
    return "Required";
  } else if (channel && channel === "PHONE" && outreachOutcome) {
    if (outreachOutcome === "SUCCESS") {
      return "You spoke with someone or left a voicemail";
    } else if (outreachOutcome === "FAILED") {
      return "You were unable to reach anyone or to leave a voicemail";
    }
  }
  return undefined;
};

const getPhoneNumberHelperText = (
  phoneError: boolean,
  phoneNumber?: PhoneNumber,
  isSameAsOrderingProvider: boolean = false,
  isFaxNumber: boolean = false
) => {
  if (isSameAsOrderingProvider) {
    return "Auto-filled with provider's phone number";
  } else if (phoneNumber && !isPhoneNumberValid(phoneNumber.number)) {
    return `You must provide a valid 10-digit ${isFaxNumber ? "fax" : "phone"} number`;
  } else if (phoneError) {
    return "Required";
  } else {
    return "";
  }
};

const isValidOutreachOutcome = (option: string): option is OutreachOutcome => {
  const validOutreachOutcomes: OutreachOutcome[] = [
    "SUCCESS",
    "FAILED",
    "OFFICE_STAFF_MEMBER_NOTIFIED",
    "LEFT_SCRIPTED_MESSAGE",
    "P2P_RIGHTS_GIVEN",
    "UNABLE_TO_REACH",
    "MEMBER_NOTIFIED",
    "AUTHORIZED_REP_NOTIFIED",
    "UNABLE_TO_REACH_NO_ANSWER",
  ];
  return validOutreachOutcomes.includes(option as OutreachOutcome);
};

const isValidOutreachType = (option: string): option is OutreachType => {
  const validOutreachTypes: OutreachType[] = [
    "MISSING_INFO_CLINICAL",
    "MISSING_INFO_NON_CLINICAL",
    "NOTIFICATION",
    "SCHEDULING_OUTREACH",
    "NUDGES",
    "CREATE_A_NEW_SUBMISSION",
    "EXISTING_AUTH_STATUS_INQUIRY",
    "EXISTING_AUTH_UPDATE",
    "OTHER",
  ];
  return validOutreachTypes.includes(option as OutreachType);
};

export default OutreachForm;
