import React, { useContext } from "react";

import { Body1, PdfViewer } from "@coherehealth/common";
import { AttachedTo } from "@coherehealth/core-platform-api";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import SideBar from "./SideBar";

import FaxAttachmentSidebar from "./FaxAttachment/FaxAttachmentSidebar";
import SendFaxSidebar from "./FaxAttachment/CreateFaxNotice/SendFaxSidebar";
import {
  FaxSidebarView,
  INCOMING_FAX_LEFT_SIDEBAR_WIDTH,
  INCOMING_FAX_SIDEBAR_WIDTH,
  OUTGOING_FAX_SIDEBAR_WIDTH,
} from "./FaxAttachment/common";
import { Location, PathMatch } from "react-router";
import { FaxAttachmentContext } from "./FaxAttachment/FaxAttachmentContext";
import FaxAuthBuilderSidebar from "./FaxAttachment/FaxAuthBuilderSidebar";
import FaxEditSRSidebar from "./FaxAttachment/FaxEditSRSidebar";
import FaxReferralsSideBar from "./FaxAttachment/FaxReferralsSidebar";

export interface DocumentInfo {
  name: string;
  size: number;
  dateCreated: string;
  createdByName: string;
  contentType: string;
  usesCohereTemplate?: string;
  createdByFaxNumber?: string;
  cohereId?: string;
  rotation?: number[];
  attachedTo?: AttachedTo[];
  id: string;
  healthPlanName: string;
  externalReferenceId: string;
}

export enum SupportedFileType {
  JPG,
  PDF,
}

type FaxType = "INCOMING_FAX" | "OUTGOING_FAX";

interface Props {
  /**
   * Meta data about the file to display
   */
  documentInfo: Partial<DocumentInfo>;
  /**
   * Callback that refreshes the document info
   */
  refreshDocumentInfo?: () => void;
  /**
   * Url to the document
   */
  url?: string;
  /**
   * If the document is still loading
   */
  loading?: boolean;
  /**
   * Called when the close button is pressed
   */
  handleClose?: () => void;
  /**
   * If the document can be deleted
   */
  canDelete: boolean;
  /**
   * Called when the delete button is pressed
   */
  onDelete?: () => void;

  hideSidebar?: boolean;

  faxType?: FaxType;

  setFaxSidebarView?: (view: FaxSidebarView) => void;

  blob?: Blob | null;

  convertedToPDF?: boolean;

  loadError?: boolean;

  isFaxAuthBuilderFlow?: boolean;

  match?: PathMatch<string> | null;

  location: Location;

  search: URLSearchParams;

  isFaxEditSRFlow?: boolean;

  isFaxReferralsBuilderFlow?: boolean;
}

const SIDEBAR_WIDTH = 400;

type StyleProps = Pick<Props, "hideSidebar" | "faxType">;

const useStyles = makeStyles((theme) => ({
  image: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
  },
  documentViewArea: {
    marginRight: ({ hideSidebar, faxType }: StyleProps) =>
      hideSidebar
        ? 0
        : faxType === "INCOMING_FAX"
        ? INCOMING_FAX_LEFT_SIDEBAR_WIDTH
        : faxType === "OUTGOING_FAX"
        ? OUTGOING_FAX_SIDEBAR_WIDTH
        : SIDEBAR_WIDTH,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
}));

export default function DocumentViewer({
  documentInfo,
  refreshDocumentInfo,
  url,
  loading,
  handleClose,
  canDelete,
  onDelete,
  hideSidebar,
  faxType,
  setFaxSidebarView,
  blob,
  convertedToPDF,
  loadError,
  isFaxAuthBuilderFlow,
  match,
  location,
  search,
  isFaxEditSRFlow,
  isFaxReferralsBuilderFlow,
}: Props) {
  const classes = useStyles({ hideSidebar, faxType });
  const { faxDbId: faxId, queueId, caseId } = useContext(FaxAttachmentContext);

  /*
   todo This is a lame hack. Sometimes the contentType field is missing and it breaks this.
   Attachment data is super weird, because it's on the serviceRequestResponse object, but also has its own endpoint
   We need to figure that out/simplify and hopefully that'll fix the issue (CP-1864)
   */
  const fileExtension = documentInfo.name?.split(".")[1];
  const isFaxAttachmentSideBarFlow = !isFaxAuthBuilderFlow && !isFaxEditSRFlow && !isFaxReferralsBuilderFlow;
  let documentDisplay;
  let fileType;
  if (loading || !url) {
    documentDisplay = <CircularProgress />;
  } else if (
    (documentInfo.contentType === "image/jpeg" ||
      (!documentInfo.contentType && (fileExtension === "jpg" || fileExtension === "jpeg"))) &&
    !convertedToPDF
  ) {
    documentDisplay = <img className={classes.image} src={url} alt={documentInfo.name || "unknown"} />;
    fileType = SupportedFileType.JPG;
  } else if (
    documentInfo.contentType === "application/pdf" ||
    (!documentInfo.contentType && fileExtension === "pdf") ||
    convertedToPDF
  ) {
    documentDisplay = <PdfViewer fileName={documentInfo.name || ""} pdfUrl={url} crrStyling={false} />;
    fileType = SupportedFileType.PDF;
  } else {
    // todo verify error display with design
    documentDisplay = <Body1>Viewing this document type is not supported, please click download.</Body1>;
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.documentViewArea}>
        {loadError ? <Body1>The selected attachment cannot be opened</Body1> : documentDisplay}
      </div>
      {!hideSidebar &&
        (Boolean(faxType) ? (
          <>
            {isFaxAuthBuilderFlow && faxType === "INCOMING_FAX" && (
              <FaxAuthBuilderSidebar
                faxId={faxId}
                caseId={caseId}
                queueId={queueId}
                match={match}
                search={search}
                location={location}
              />
            )}
            {isFaxEditSRFlow && faxType === "INCOMING_FAX" && (
              <FaxEditSRSidebar
                faxId={faxId}
                caseId={caseId}
                queueId={queueId}
                match={match}
                search={search}
                location={location}
              />
            )}
            {isFaxReferralsBuilderFlow && faxType === "INCOMING_FAX" && (
              <FaxReferralsSideBar
                faxId={faxId}
                caseId={caseId}
                queueId={queueId}
                match={match}
                search={search}
                location={location}
              />
            )}
            {isFaxAttachmentSideBarFlow && faxType === "INCOMING_FAX" && (
              <FaxAttachmentSidebar
                width={INCOMING_FAX_SIDEBAR_WIDTH}
                blob={blob}
                fileData={documentInfo}
                refreshFileData={refreshDocumentInfo}
                url={url}
              />
            )}
            {faxType === "OUTGOING_FAX" && (
              <SendFaxSidebar
                width={OUTGOING_FAX_SIDEBAR_WIDTH}
                fileData={documentInfo}
                onClose={handleClose}
                blob={blob}
              />
            )}
          </>
        ) : (
          <SideBar
            width={SIDEBAR_WIDTH}
            fileData={documentInfo}
            fileType={fileType}
            onClose={handleClose}
            url={url}
            canDelete={canDelete}
            onDelete={onDelete}
            convertedToPDF={convertedToPDF}
          />
        ))}
    </Paper>
  );
}
