import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  OutreachOpportunity,
  AttemptStatus,
  OpportunityStatus,
  ServiceRequestResponse,
} from "@coherehealth/core-platform-api";
import {
  colorsLight,
  H5,
  Pill,
  Body1,
  PillVariant,
  Caption,
  Sanitized,
  formatDateStrWithTz,
  parseDateFromISOStringWithoutFallback,
  parseDateFromISOString,
} from "@coherehealth/common";
import PrimaryArrowIcon from "common/PrimaryArrow";
import PrintIcon from "@material-ui/icons/Print";
import MailIcon from "@material-ui/icons/Mail";
import {
  oldValueText,
  newValueText,
  procedureCodesText,
  convertFromShoutyCase,
  noOnAcceptAttributeText,
} from "util/NudgeUtils";
import { Autorenew, DeleteOutline, AddCircleOutline } from "@material-ui/icons";

interface Props {
  nudgeOpportunity: OutreachOpportunity;
  serviceRequest: ServiceRequestResponse;
}

export default function NudgeOpportunity({ nudgeOpportunity, serviceRequest }: Props) {
  const classes = useStyles();

  const procedureCodeNudgeIcon = () => {
    switch (nudgeOpportunity?.recommendChangeAction?.onAcceptAction) {
      case "REPLACE_VALUE":
        return <Autorenew className={classes.acceptCPT} />;
      case "LIMIT_TO_VALUES":
        return <DeleteOutline className={classes.acceptCPT} />;
      case "ADD_VALUES":
        return <AddCircleOutline className={classes.acceptCPT} />;
      case "REMOVE_VALUES":
        return <DeleteOutline className={classes.acceptCPT} />;
    }
  };

  return (
    <Accordion elevation={0} className={classes.root}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.container}>
        <Pill
          label={opportunityStatusDisplayName[nudgeOpportunity.status]}
          variant={opportunityStatusPillVariant[nudgeOpportunity.status]}
          className={classes.leftPill}
        />
        <Pill
          label={
            isHardCodedMI(nudgeOpportunity)
              ? "Attachments"
              : nudgeOpportunity?.recommendChangeAction?.onAcceptAttribute
              ? nudgeTypeToDisplayName[nudgeOpportunity.recommendChangeAction.onAcceptAttribute]
              : nudgeOpportunity?.recommendChangeAction?.onAcceptAction
              ? nudgeTypeToDisplayName[nudgeOpportunity.recommendChangeAction.onAcceptAction]
              : ""
          }
          variant="info"
          className={classes.rightPill}
        />
        <div className={classes.container}>
          {nudgeOpportunity?.recommendChangeAction?.onAcceptAttribute === "procedureCodes" ? (
            <>
              {procedureCodeNudgeIcon()}
              <H5>{procedureCodesText(nudgeOpportunity?.recommendChangeAction, serviceRequest)}</H5>
            </>
          ) : (
            <>
              {nudgeOpportunity?.recommendChangeAction?.onAcceptAttribute ? (
                <>
                  <H5 className={classes.nonBoldH5}>
                    {oldValueText(
                      nudgeOpportunity?.recommendChangeAction,
                      nudgeOpportunity?.srBeforeSnapshot || serviceRequest
                    )}
                  </H5>
                  <PrimaryArrowIcon className={classes.arrow} />
                  <H5>{newValueText(nudgeOpportunity?.recommendChangeAction, serviceRequest)}</H5>
                </>
              ) : (
                <>
                  <H5 className={classes.nonBoldH5}>
                    {isHardCodedMI(nudgeOpportunity)
                      ? "Add attachments to this service request"
                      : noOnAcceptAttributeText(nudgeOpportunity?.recommendChangeAction)}
                  </H5>
                </>
              )}
            </>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.attemptsContainer}>
        {nudgeOpportunity?.outreachAttempts
          ?.sort(
            (a, b) =>
              parseDateFromISOString(a.scheduledSend).getTime() - parseDateFromISOString(b.scheduledSend).getTime()
          )
          .map((outreachAttempt) => (
            <div key={outreachAttempt.id}>
              <Divider className={classes.divider} />
              <Accordion elevation={0} className={classes.attemptContainer}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Pill
                    label={attemptStatusDisplayName[outreachAttempt?.status || "ACCEPTED"]}
                    variant={attemptStatusPillVariant[outreachAttempt?.status || "ACCEPTED"]}
                    className={classes.leftPill}
                  />
                  {outreachAttempt.channel === "EMAIL" ? (
                    <MailIcon className={classes.icon} />
                  ) : (
                    <PrintIcon className={classes.icon} />
                  )}
                  <div className={classes.attemptDetails}>
                    <Caption className={classes.captionSpacing}>
                      {convertFromShoutyCase(outreachAttempt.channel)}
                    </Caption>
                    <Caption>
                      {dateIsFuture(outreachAttempt?.scheduledSend) ? "Will be sent at " : "Sent at "}
                      {formatDateStrWithTz(outreachAttempt?.scheduledSend)} by Cohere bot
                    </Caption>
                  </div>
                </AccordionSummary>
                <AccordionDetails style={{ display: "block" }}>
                  {isHardCodedMI(nudgeOpportunity) && outreachAttempt.channel === "EMAIL" ? (
                    <>
                      <Body1 className={classes.subjectText}>Internal Note</Body1>
                      <Caption>
                        Automated missing information email sent through Braze. See Braze portal for additional details.
                      </Caption>
                    </>
                  ) : (
                    <>
                      <Body1 className={classes.subjectText}>{outreachAttempt.subject}</Body1>
                      <Caption>
                        <Sanitized __html={outreachAttempt?.message || ""} />
                      </Caption>
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
      </AccordionDetails>
    </Accordion>
  );
}

const opportunityStatusPillVariant: Record<OpportunityStatus, PillVariant> = {
  READY: "warning",
  COMPLETE: "success",
  FAILED: "error",
  WITHDRAWN: "info",
};

const opportunityStatusDisplayName: Record<OpportunityStatus, string> = {
  READY: "In progress",
  COMPLETE: "Success",
  FAILED: "Failed",
  WITHDRAWN: "Canceled",
};

const attemptStatusPillVariant: Record<AttemptStatus, PillVariant> = {
  READY: "info",
  QUEUED: "info",
  PROCESSING: "warning",
  FAILED: "error",
  ACCEPTED: "success",
  REQUEST_ERROR: "error",
  UNDELIVERABLE: "error",
  WITHDRAWN: "info",
};

const attemptStatusDisplayName: Record<AttemptStatus, string> = {
  QUEUED: "Pending send",
  READY: "Pending send",
  PROCESSING: "Pending response",
  FAILED: "Failed",
  ACCEPTED: "Success",
  REQUEST_ERROR: "Error",
  UNDELIVERABLE: "Failed",
  WITHDRAWN: "Canceled",
};

const nudgeTypeToDisplayName: Record<string, string> = {
  units: "Units",
  encounterType: "Facility",
  placeOfService: "Place of Service",
  "urgency.isExpedited": "Urgency",
  NONE: "No Action",
  WITHDRAW: "Withdraw",
  procedureCodes: "Procedure Codes",
  ADD_ATTACHMENTS: "Attachments",
};

const dateIsFuture = (dateString: string | undefined) => {
  const dateParsed = parseDateFromISOStringWithoutFallback(dateString);
  if (!dateParsed) {
    return false;
  }
  return dateParsed > new Date();
};

const isHardCodedMI = (nudgeOpportunity: OutreachOpportunity) => {
  return nudgeOpportunity.manualOpportunityTrigger && nudgeOpportunity.manualOpportunityTrigger === "MISSING_INFO";
};

const useStyles = makeStyles((theme) => ({
  nonBoldH5: {
    fontFamily: "Gilroy-Light",
  },
  captionSpacing: {
    marginRight: theme.spacing(1),
  },
  noNudgeText: {
    color: colorsLight.font.secondary,
  },
  attemptContainer: {
    width: "100%",
    alignItems: "center",
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
      display: "none",
    },
    "&.MuiAccordion-root.Mui-expanded": {
      margin: 0,
    },
  },
  attemptsContainer: {
    width: "100%",
    paddingRight: 0,
    display: "block",
    alignItems: "center",
    paddingLeft: 0,
  },
  root: {
    "&.MuiAccordion-root:before": {
      display: "none",
    },
    "&.MuiAccordion-root.Mui-expanded": {
      margin: 0,
    },
  },
  componentContainer: {
    width: "100%",
    paddingRight: 0,
    display: "block",
    alignItems: "center",
    paddingLeft: 0,
  },
  subjectText: {
    paddingBottom: 10,
  },
  container: {
    display: "flex",
    paddingLeft: 0,
    flexDirection: "row",
    maxHeight: 100,
    width: "100%",
    alignItems: "center",
    "&.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 0,
    },
  },
  divider: {
    marginLeft: 24,
    marginRight: 24,
  },
  arrow: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    margin: theme.spacing(0, 1, 0, 1),
    paddingTop: theme.spacing(0.5),
  },
  leftPill: {
    maxHeight: theme.spacing(3),
    margin: theme.spacing(0, 1, 0, 1),
  },
  rightPill: {
    maxHeight: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  icon: {
    color: colorsLight.font.secondary,
    margin: theme.spacing(0, 1, 0, 1),
  },
  acceptCPT: {
    marginRight: theme.spacing(1),
    color: colorsLight.primary.main,
    "&.accepted": {
      color: colorsLight.success.main,
      transition: theme.transitions.create("opacity"),
    },
  },
  attemptDetails: {
    marginTop: theme.spacing(0.25),
  },
}));
