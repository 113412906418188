import React from "react";

// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";

import QuestionHeader, { Props as QuestionHeaderProps } from "./QuestionHeader";
import QuestionCard, { Props as QuestionCardProps } from "./QuestionCard";
import { RadioGroup } from "../RadioGroup";

interface SingleSelectAnswer {
  id: string;
  label: React.ReactNode;
}

interface Props extends QuestionHeaderProps, QuestionCardProps {
  /** The list of options for this question */
  options: SingleSelectAnswer[];
  /** The selected option for this question */
  selectedOption: string;
  /** A function which updates the selected option when a new option is clicked */
  setSelectedOption: (arg0: string) => any;
}

const useLabelStyles = makeStyles({
  asterisk: {
    display: "none",
  },
});

export default function SingleSelectQuestion({
  options,
  selectedOption,
  setSelectedOption,
  relevantLinks,
  required,
  ordinal,
  questionText,
  questionType,
  subtext,
  hideQuestionAnswerDivider,
  ...questionCardProps
}: Props) {
  const labelClasses = useLabelStyles();

  return (
    <QuestionCard {...questionCardProps}>
      <FullWidthRadioGroup
        labelClasses={labelClasses}
        label={
          <QuestionHeader
            hideQuestionAnswerDivider={hideQuestionAnswerDivider}
            relevantLinks={relevantLinks}
            required={required}
            ordinal={ordinal}
            questionText={questionText}
            questionType={questionType}
            subtext={subtext}
          />
        }
        options={options}
        value={selectedOption}
        onChange={setSelectedOption}
        required={required}
      />
    </QuestionCard>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const FullWidthRadioGroup = styled(RadioGroup)({
  width: "100%",
});
