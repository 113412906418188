import { AuthStatus, ClinicalService, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { H4 } from "@coherehealth/design-system";
import { Grid, makeStyles } from "@material-ui/core";
import { FormConfiguration } from "components/ServiceRequest/ConfigurableServiceRequestForm";
import { formContentToContinuation } from "components/ServiceRequest/ServiceRequestForm/ServiceRequestFormRedirectModal";
import {
  PrimaryDiagnosisCodeSelect,
  SecondaryDiagnosisCodeSelect,
} from "components/ServiceRequest/ServiceRequestForm/components/DiagnosisCodeSelect";
import { ContinuationFormContent } from "common/SharedServiceRequestFormComponents";
import { Dispatch, SetStateAction, useState } from "react";
import { useLocation } from "react-router";
import { useIsFaxAuthBuilderWorkflow, useIsFaxEditSRWorkflow } from "util/attachmentUtil";
import { useGetServiceRequestStateConfigurationByPayerAndAuthStatus } from "hooks/useGetFeatureConfigurations";

interface DiagnosisCardProps {
  authStatus: AuthStatus;
  error: boolean;
  formConfiguration: FormConfiguration;
  formContent: ContinuationFormContent;
  isCohereFaxForm?: boolean;
  setFormContent: Dispatch<SetStateAction<ContinuationFormContent>>;
  workflowId?: string;
  isEdit?: boolean;
  serviceRequest?: Partial<ServiceRequestResponse>;
  healthPlanName: string;
  delegatedVendorName?: string;
  isContinuation?: boolean;
}

export default function DiagnosisCard(props: DiagnosisCardProps): JSX.Element {
  const {
    authStatus,
    error,
    formConfiguration,
    formContent,
    isCohereFaxForm,
    setFormContent,
    workflowId,
    isEdit,
    serviceRequest,
    healthPlanName,
    delegatedVendorName,
    isContinuation,
  } = props;

  const [clinicalService] = useState<ClinicalService | undefined>(formContent?.clinicalService);
  const [initialServiceRequest] = useState(() => ({
    ...formContentToContinuation(formContent),
    authStatus,
    workflowId,
  }));
  const classes = styles();
  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);
  const isFaxEditSRFlow = useIsFaxEditSRWorkflow(location);

  const { isFieldDisabled } =
    useGetServiceRequestStateConfigurationByPayerAndAuthStatus({
      healthPlanName: healthPlanName || "",
      authStatus: authStatus,
      delegatedVendorName: delegatedVendorName,
      requestType: isContinuation ? "CONTINUATION" : "INITIAL",
    }) || {};

  return (
    <Grid container spacing={2}>
      {!isEdit && serviceRequest?.workflowStep !== "SERVICE_DETAILS" && (
        <Grid item xs={12}>
          <H4 data-public>Diagnosis details</H4>
        </Grid>
      )}
      <Grid
        item
        container
        xs={12}
        spacing={2}
        className={isEdit ? classes.editRequest : ""}
        style={{ paddingRight: isFaxAuthBuilderFlow || isFaxEditSRFlow ? 0 : 8 }}
      >
        <Grid item xs={12} style={{ paddingRight: isFaxAuthBuilderFlow || isFaxEditSRFlow ? 0 : 8 }}>
          <PrimaryDiagnosisCodeSelect
            isAutoFilled={false}
            isCohereFaxForm={Boolean(isCohereFaxForm && initialServiceRequest.primaryDiagnosis)}
            initialPrimaryDiagnosisCode={initialServiceRequest.primaryDiagnosis}
            error={error}
            onContinuationPatientSummary={!formContent?.isInpatient}
            primaryDiagnosisCode={formContent?.primaryDiagnosisCode || null}
            setPrimaryDiagnosisCode={(code) => setFormContent({ ...formContent, primaryDiagnosisCode: code })}
            singleService={clinicalService?.isSingleService}
            dataPublic={true}
            disabled={isFieldDisabled("primaryDiagnosis")}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingRight: isFaxAuthBuilderFlow || isFaxEditSRFlow ? 0 : 8 }}>
          <SecondaryDiagnosisCodeSelect
            isAutoFilled={false}
            isCohereFaxForm={Boolean(
              isCohereFaxForm && typeof initialServiceRequest?.secondaryDiagnoses?.length === "number"
            )}
            isOptional={formConfiguration.secondaryDiagnoses.fieldSpec === "OPTIONAL"}
            error={error}
            secondaryDiagnosisCodes={formContent?.secondaryDiagnosisCodes || []}
            setSecondaryDiagnosisCodes={(codes) =>
              setFormContent({
                ...formContent,
                secondaryDiagnosisCodes: codes,
              })
            }
            dataPublic={true}
            disabled={isFieldDisabled("secondaryDiagnoses")}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

const styles = makeStyles((theme) => ({
  editRequest: {
    paddingTop: "0px",
  },
}));
