// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import ServiceRequestPage from "components/ServiceRequest/ServiceRequestPage";

export default function SmartOnFhirServiceRequestPageWrapper() {
  return (
    <AppBody>
      <ServiceRequestPage />
    </AppBody>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const AppBody = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: "100%",
}));
