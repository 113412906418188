import { Body2, colorsLight } from "@coherehealth/common";
import { AuthStatus, ReviewOutcomeWithdrawOption } from "@coherehealth/core-platform-api";
import { useTheme } from "@material-ui/core";
import { ModalMessage } from "components/ClinicalReview/reviewUtils/utils";
import { Row } from "components/ServiceRequestStatusDisplay/utils";

export interface WithdrawnModalFooterProps {
  reviewerWithdrawalModalEnhanced: boolean;
  nextState: AuthStatus | undefined;
  canViewAllWithdrawnReasons: boolean;
  withdrawRequestorOption?: ReviewOutcomeWithdrawOption[] | undefined;
}

export const WithdrawnModalFooter = ({
  reviewerWithdrawalModalEnhanced,
  nextState,
  canViewAllWithdrawnReasons,
  withdrawRequestorOption,
}: WithdrawnModalFooterProps) => {
  const theme = useTheme();
  return (
    <>
      {!reviewerWithdrawalModalEnhanced ? (
        <Row style={{ textAlign: "start", paddingTop: 4, paddingBottom: 0 }}>
          <ModalMessage state={nextState} />
        </Row>
      ) : (
        <>
          {canViewAllWithdrawnReasons && (
            <Row
              style={{
                textAlign: "start",
                paddingTop: withdrawRequestorOption ? theme.spacing(3) : theme.spacing(0),
                color: colorsLight.font.main,
              }}
            >
              <Body2 style={{ display: "inline", overflow: "visible" }}>
                Setting or changing the withdrawal reason will automatically send any applicable letters.
              </Body2>
            </Row>
          )}
        </>
      )}
    </>
  );
};
