import React, { forwardRef, PropsWithChildren } from "react";

import MuiCheckbox from "@material-ui/core/Checkbox";
import MuiMenuItem, { MenuItemProps as MuiMenuItemProps } from "@material-ui/core/MenuItem";
import { makeStyles, Theme } from "@material-ui/core/styles";
import MuiCheckIcon from "@material-ui/icons/Check";

import { colorsDark, colorsLight } from "../../themes";

interface StyleProps {
  /** Optionally set a fixed width for the dropdown menu item. */
  menuWidth?: number;
  /** If a checkbox is used */
  withCheckbox?: boolean;
  hasSublabel?: boolean;
  menuItemHeight?: string;
  fontSize?: string;
  menuItemColor?: string;
  noIcon?: boolean;
}

const useStylesMenuOption = makeStyles((theme) => {
  const borderRadius = "5px";
  const isDarkTheme = theme.palette.type === "dark";
  const colors = isDarkTheme ? colorsDark : colorsLight;
  return {
    root: {
      height: ({ menuItemHeight }: StyleProps) => menuItemHeight,
      borderTop: `1px solid ${colors.gray.divider}`,
      padding: ({ hasSublabel }: StyleProps) =>
        hasSublabel ? theme.spacing(1, 1, 1, 1.5) : theme.spacing(2, 2, 2, 1.5),
      whiteSpace: "normal",
      fontSize: ({ fontSize }: StyleProps) => fontSize,
      color: ({ menuItemColor }: StyleProps) => menuItemColor,
      width: ({ menuWidth }: StyleProps) => menuWidth,
      paddingLeft: ({ withCheckbox, noIcon }: StyleProps) => (withCheckbox || noIcon ? undefined : theme.spacing(5)),
      "&:first-child": {
        borderTop: "none",
      },
      "&:last-child": {
        marginBottom: `-${theme.spacing(1)}px`,
        borderBottom: "none",
        borderBottomLeftRadius: borderRadius,
        borderBottomRightRadius: borderRadius,
      },
      "&$selected": {
        fontWeight: theme.typography.body2.fontWeight,
      },
    },
    selected: {},
    focusVisible: {},
    icon: {
      position: "absolute",
      left: 8,
    },
    dontAutoHighlightSelections: {
      "&$root$selected:not($focusVisible):not(:hover)": {
        // Don't highlight selections by default (must be focused or hovered)
        backgroundColor: "inherit",
      },
    },
  };
});

const useStylesCheckbox = makeStyles((theme: Theme) => ({
  root: {
    height: 20,
    marginRight: theme.spacing(2),
    marginLeft: -3, // line up check box w/ Select TextField label/value
    padding: 0,
  },
}));

interface Props extends StyleProps, Omit<MuiMenuItemProps, "button"> {
  /** If the option is selected */
  selected?: boolean;
  /** Shows a checkbox with the option, intended for the MultiSelectDropdown */
  withCheckbox?: boolean;
  hasSublabel?: boolean;
  disableRipple?: boolean;
}

export const CohereMenuItem = forwardRef<HTMLLIElement | null, PropsWithChildren<Props>>(
  (
    {
      selected,
      withCheckbox,
      menuItemHeight,
      menuWidth,
      fontSize,
      menuItemColor,
      noIcon,
      hasSublabel,
      disableRipple,
      children,
      ...listItemProps
    },
    ref
  ) => {
    const {
      icon: iconClass,
      dontAutoHighlightSelections,
      focusVisible,
      ...classes
    } = useStylesMenuOption({
      menuWidth,
      menuItemHeight,
      fontSize,
      menuItemColor,
      noIcon,
      withCheckbox,
      hasSublabel,
    });
    const checkboxClasses = useStylesCheckbox();

    return (
      <MuiMenuItem
        ref={ref}
        disableRipple={disableRipple}
        classes={classes}
        className={withCheckbox ? dontAutoHighlightSelections : ""}
        // @ts-ignore https://github.com/mui-org/material-ui/issues/21801
        ListItemClasses={{ focusVisible }}
        selected={selected}
        data-public
        {...listItemProps}
      >
        <>
          {withCheckbox && <MuiCheckbox checked={selected} classes={checkboxClasses} color="primary" />}
          {!withCheckbox && selected && !noIcon && <MuiCheckIcon className={iconClass} color="primary" />}
          {children}
        </>
      </MuiMenuItem>
    );
  }
);

export default CohereMenuItem;
