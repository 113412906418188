import React, { SVGProps } from "react";

export default function PurpleClipboardCheckIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40Z"
        fill="#D1C7E4"
      />
      <path d="M55.6013 23.1758H23V61.1994H55.6013V23.1758Z" fill="#A1AAB4" />
      <path d="M47.9131 25.4785H25.3066V58.8891H53.2814V30.8604L47.9131 25.4785Z" fill="white" />
      <path
        d="M46.6845 18.4665H42.3412C42.3412 16.5512 40.9789 15 39.2929 15C37.6068 15 36.2445 16.5512 36.2445 18.4665H31.9013L30.6738 26.1549H47.8984L46.6845 18.4665Z"
        fill="#212936"
      />
      <path
        d="M33.8586 30.7829H31.5386V28.4629H29.9469V30.7829H27.627V32.3745H29.9469V34.6945H31.5386V32.3745H33.8586V30.7829Z"
        fill="#EA1D63"
      />
      <path d="M46.5506 37.2715H32.0371V38.364H46.5506V37.2715Z" fill="#89D3DD" />
      <path d="M50.597 54.7617H44.8105V55.8543H50.597V54.7617Z" fill="#89D3DD" />
      <path d="M50.5967 41.2754H27.9902V42.3679H50.5967V41.2754Z" fill="#C2C2C2" />
      <path d="M50.5967 44.5527H27.9902V45.6453H50.5967V44.5527Z" fill="#C2C2C2" />
      <path d="M50.5967 47.834H27.9902V48.9265H50.5967V47.834Z" fill="#C2C2C2" />
      <path d="M38.093 51.1074H27.9902V52.2H38.093V51.1074Z" fill="#C2C2C2" />
      <path d="M47.9121 30.8604H53.2805L47.9121 25.4785V30.8604Z" fill="#F5F5F5" />
      <circle cx="55" cy="27" r="12" fill="white" />
      <path
        d="M55 15C48.376 15 43 20.376 43 27C43 33.624 48.376 39 55 39C61.624 39 67 33.624 67 27C67 20.376 61.624 15 55 15ZM52.6 33L46.6 27L48.292 25.308L52.6 29.604L61.708 20.496L63.4 22.2L52.6 33Z"
        fill="#55358F"
      />
    </svg>
  );
}
