import React, { ReactNode } from "react";
import { BlobProvider } from "@react-pdf/renderer";
import MissingInformationPDF from "./PDF";
import { DocumentParams, FaxbackRequestType, FaxNoticeMissingInformation } from "../../common";

interface Props {
  faxbackRequestType?: FaxbackRequestType | undefined;
  missingInformation: FaxNoticeMissingInformation | undefined;
  patientName: string;
  dateRequested: string;
  children: (params: DocumentParams) => ReactNode;
  isGhpUser?: boolean;
}

export default function MissingInformationPDFProvider({
  faxbackRequestType,
  missingInformation,
  patientName,
  dateRequested,
  children,
  isGhpUser,
}: Props) {
  if (!missingInformation) {
    return null;
  }
  return (
    <BlobProvider
      document={
        <MissingInformationPDF
          faxbackRequestType={faxbackRequestType}
          missingInformation={missingInformation}
          patientName={patientName}
          dateRequested={dateRequested}
          isGhpUser={isGhpUser}
        />
      }
    >
      {children}
    </BlobProvider>
  );
}
