import {
  Body1,
  Caption,
  H2,
  Modal,
  ModalHeader,
  PrimaryButton,
  RadioGroup,
  RichTextEditor,
  SecondaryButton,
  SingleSelectDropdown,
} from "@coherehealth/common";
import {
  OutreachAttempt,
  ServiceRequestResponse,
  usePostOutreachAttempt,
  usePostOutreachOpportunity,
} from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { useTheme, Tab, Grid, styled, useMediaQuery, Theme, makeStyles } from "@material-ui/core";
import { StyledTabs } from "components/ClinicalReview/Review/ClinicalReviewPage";
import { RIGHT_HAND_PANEL_SIZE, tabProps } from "components/ClinicalReview/reviewUtils/utils";
import ChangeStatusForm from "components/ServiceRequestStatusDisplay/ChangeStatusForm";
import { ServiceRequestStatusDisplay } from "components/ServiceRequestStatusDisplay/StatusBanner/ServiceRequestStatusDisplay";
import { useSnackbar } from "notistack";
import { FormEvent, useContext, useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { User, UserContext } from "UserContext";
import { formatPhone } from "util/demographicUtils";
import { redirectToPatientSummaryFromReview } from "util/routeUtils/routeUtils";

interface Props {
  serviceRequest: ServiceRequestResponse;
  outreachAttempts: string | null;
}

const CurrentNewOutreach = ({ serviceRequest, outreachAttempts }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const search = useMemo(() => new URLSearchParams(location.search), [location]);
  const caseId = search.get("caseId");
  const matchesRight = useMediaQuery(theme.breakpoints.up(RIGHT_HAND_PANEL_SIZE));
  const { getUser } = useContext(UserContext);
  const [user, setUser] = useState<User>();
  const [useEnhancedWithdrawalModal, setUseEnhancedWithdrawalModal] = useState<boolean>(false);
  getUser()?.then((currentUser) => {
    setUser(currentUser);
  });
  const isFinallyDetermined = Boolean(
    ["DENIED", "APPROVED", "PARTIALLY_APPROVED"].includes(serviceRequest.authStatus || "")
  );
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const [outreachAttempt, setOutreachAttempt] = useState<OutreachAttempt>({
    scheduledSend: new Date().toISOString(),
    serviceRequestId: serviceRequest?.id,
    channel: "PHONE",
    attemptNumber: outreachAttempts ? parseInt(outreachAttempts) : undefined,
    manual: true,
    healthPlanName: serviceRequest?.healthPlanName || "",
    contactType: "OUTBOUND",
    outreachType: "MISSING_INFO_CLINICAL",
  });
  const [attemptNumberError, setAttemptNumberError] = useState<boolean>(false);
  const [outcomeError, setOutcomeError] = useState<boolean>(false);
  const [notesError, setNotesError] = useState<boolean>(false);
  const classes = useStyles();

  const {
    loading: outreachAttemptLoading,
    error: outreachAttemptError,
    mutate: postOutreachAttempt,
  } = usePostOutreachAttempt({ outreachOpportunityId: "" });

  const {
    loading: outreachOpportunityLoading,
    error: outreachOpportunityError,
    mutate: postOutreachOpportunity,
  } = usePostOutreachOpportunity({});

  useEffect(() => {
    if (outreachOpportunityError) {
      enqueueSnackbar("Failed to upload outreach opportunity, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [outreachAttemptError, outreachOpportunityError, enqueueSnackbar]);

  const onSubmit = async () => {
    const opportunity = await postOutreachOpportunity({
      serviceRequest: serviceRequest,
      status: "READY",
      manualOpportunityTrigger: "MISSING_INFO",
      healthPlanName: serviceRequest.healthPlanName || "",
    });
    await postOutreachAttempt(
      {
        ...outreachAttempt,
        outreachOpportunity: opportunity,
        completedDate: new Date().toISOString(),
        completedBy: user?.sub,
        completedByName: user?.name,
      },
      { pathParams: { outreachOpportunityId: opportunity.id || "" } }
    );
    redirectToPatientSummaryFromReview({ serviceRequest, navigate });
  };

  const getMaxWidth = (): "lg" | "md" => {
    if (useEnhancedWithdrawalModal) {
      return "lg";
    }
    return "md";
  };

  return (
    <>
      <StyledTabs
        value={"NEW_OUTREACH_ATTEMPT"}
        aria-label="guideline tabs"
        style={{ margin: "0px -24px", position: "sticky", top: 0, backgroundColor: "white", zIndex: 2 }}
      >
        <Tab
          style={{ width: "250px" }}
          label={"New outreach attempt"}
          {...tabProps("NEW_OUTREACH_ATTEMPT")}
          id="new-outreach-attempt"
          className={classes.panelTab}
        />
      </StyledTabs>
      <Grid
        container
        component="form"
        onSubmit={(event: FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          !outreachAttempt.attemptNumber && setAttemptNumberError(true);
          !outreachAttempt.outreachOutcome && setOutcomeError(true);
          (!outreachAttempt.outreachNotes || outreachAttempt.outreachNotes.trim() === "") && setNotesError(true);
          outreachAttempt.attemptNumber &&
            outreachAttempt.outreachOutcome &&
            outreachAttempt.outreachNotes &&
            ((!isFinallyDetermined && setOpen(true)) || (isFinallyDetermined && onSubmit()));
        }}
        spacing={2}
        style={{ padding: theme.spacing(3, 3), marginBottom: theme.spacing(12) }}
      >
        <Grid item xs={12}>
          <ServiceRequestStatusDisplay
            serviceRequest={serviceRequest}
            actionConfiguration={{
              editStatusActionConfiguration: {
                disabled: true,
                hidden: true,
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Caption>Name</Caption>
          <Body1>{serviceRequest.orderingProvider?.name || "- -"}</Body1>
        </Grid>
        <Grid item xs={3}>
          <Caption>Phone</Caption>
          <Body1>
            {serviceRequest.orderingProvider?.phoneNumbers
              ?.map((p) => formatPhone(p) + ", ")
              .filter((phoneString) => !!phoneString) || "- -"}
          </Body1>
        </Grid>
        <Grid item xs={3}>
          <Caption>NPI</Caption>
          <Body1>{serviceRequest.orderingProvider?.npi || "- -"}</Body1>
        </Grid>
        <Grid item xs={3}>
          <Caption>TIN</Caption>
          <Body1>{serviceRequest.orderingProvider?.tinList || "- -"}</Body1>
        </Grid>
        <Grid item xs={12}>
          <SingleSelectDropdown
            label={"Select attempt number"}
            onChange={(attempt) => {
              setOutreachAttempt((prev) => {
                return {
                  ...prev,
                  attemptNumber: parseInt(attempt),
                };
              });
              setAttemptNumberError(false);
            }}
            value={outreachAttempt?.attemptNumber?.toString()}
            options={[
              { id: "1", label: "1st" },
              { id: "2", label: "2nd" },
              { id: "3", label: "3rd" },
              { id: "4", label: "4th+" },
            ]}
            error={attemptNumberError}
            helperText={attemptNumberError && "required"}
          />
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            row
            nowrap
            label="Outreach channel"
            options={[
              { id: "PHONE", label: "Phone" },
              { id: "EMAIL", label: "Email" },
              { id: "FAX", label: "Fax" },
            ]}
            value={outreachAttempt.channel}
            onChange={(opt) => {
              setOutreachAttempt((prev) => {
                return {
                  ...prev,
                  channel: opt,
                };
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RichTextEditor
            readonly={false}
            label="Outreach notes"
            htmlValue={outreachAttempt.outreachNotes || ""}
            setHtmlValue={(arg0: string) => {
              setOutreachAttempt((prev) => {
                return {
                  ...prev,
                  outreachNotes: arg0,
                };
              });
              setNotesError(false);
            }}
            error={notesError}
            helperText={notesError ? "required" : undefined}
          />
        </Grid>
        <Grid item xs={12}>
          <SingleSelectDropdown
            label={"Outreach outcome"}
            value={outreachAttempt.outreachOutcome}
            onChange={(outcome) => {
              const outreachOutcome = outcome === "SUCCESS" ? "SUCCESS" : "FAILED";
              setOutreachAttempt((prev) => {
                return {
                  ...prev,
                  outreachOutcome: outreachOutcome,
                };
              });
              setOutcomeError(false);
            }}
            options={[
              { id: "SUCCESS", label: "Success" },
              { id: "FAILED", label: "Failed" },
            ]}
            error={outcomeError}
            helperText={outcomeError && "required"}
          />
        </Grid>
        <NavigationControl
          item
          xs={12}
          container
          alignItems="flex-end"
          justifyContent="center"
          matchesRight={matchesRight}
        >
          <SecondaryButton
            warning
            style={{ width: theme.spacing(32.25), marginRight: theme.spacing(2), height: theme.spacing(6) }}
            onClick={() => {
              redirectToPatientSummaryFromReview({ serviceRequest, caseId, navigate });
            }}
          >
            Discard
          </SecondaryButton>
          <PrimaryButton
            type="submit"
            disabled={outreachAttemptLoading || outreachOpportunityLoading}
            loading={outreachAttemptLoading || outreachOpportunityLoading}
            style={{ width: theme.spacing(32.25), height: theme.spacing(6) }}
          >
            Save
          </PrimaryButton>
        </NavigationControl>
      </Grid>
      <Modal
        open={open}
        scroll="body"
        fullWidth
        maxWidth={getMaxWidth()}
        onClose={() => setOpen(false)}
        className={classes.root}
      >
        <ModalHeader>
          <H2>Change service status</H2>
        </ModalHeader>
        <ChangeStatusForm
          onEdit={() => {
            setOpen(false);
            onSubmit();
          }}
          onCancel={() => setOpen(false)}
          authServiceRequests={serviceRequest.authorization?.serviceRequestsOnAuth || [serviceRequest]}
          setUseEnhancedWithdrawalModal={setUseEnhancedWithdrawalModal}
        />
      </Modal>
    </>
  );
};

const useStyles = makeStyles(() => ({
  panelTab: {
    "&:hover span": {
      opacity: 0.7,
    },
  },
  root: {
    "& .MuiDialog-paperWidthLg": {
      maxWidth: 944,
      minWidth: 944,
    },
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const NavigationControl = styled(({ matchesRight, ...other }) => <Grid {...other} />)<
  Theme,
  { matchesRight?: boolean }
>(({ theme, matchesRight }) => ({
  position: "fixed",
  height: theme.spacing(9),
  bottom: "0px",
  right: "0px",
  boxShadow: "0px -5px 10px -5px rgba(0, 0, 0, 0.2)",
  backgroundColor: theme.palette.background.paper,
  width: matchesRight ? 712 : "calc(100vw - 728px)",
  zIndex: 10,
}));

export default CurrentNewOutreach;
