export const isString = (x: unknown): x is string => typeof x == "string";

/*
 * A function that takes a single type parameter; if that type does not
 * resolve to `true`, throws a type error
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function typeAssert<T extends true>(): void {}

// https://github.com/Microsoft/TypeScript/issues/23182#issuecomment-379091887
export type IsNeverType<T> = [T] extends [never] ? true : false;

/*
 * When you have an array (must be declared `as const`)
 * and want to get a union of the individual elements
 */
export type ArrayElements<U> = U extends ReadonlyArray<infer T> ? T : never;

/*
 * When you have an array of strings (declared `as const`)
 * and want to get a type-safe enum-like object;
 *
 * const items = ["Table", "Chair"] as const
 * const Furniture = enumify(items);
 * console.log(Furniture.Table, Furniture.Chair) // prints ["Table", "Chair"]
 * console.log(Furniture.Spaceman) // Type error!
 */
export function enumifyList<U extends ReadonlyArray<string>>(input: U): Record<ArrayElements<U>, ArrayElements<U>> {
  return Object.freeze(
    input.reduce((acc, next) => ({ ...acc, [next]: next }), {} as Record<ArrayElements<U>, ArrayElements<U>>)
  );
}

/*
 * Suppose you have a union of strings A, and an array theArray, and you want to make sure
 * that array contains all the elements of the type union.
 * Assign the value `true` to a variable of type
 * IsExhaustiveArray<A, typeof theArray>
 * because that will give you a type error if you've missed a spot.
 *
 * BEWARE! This won't tell you _anything_ if you haven't defined the array as const
 *
 * Another side note: we don't need this for Record types, because
 * Record<StringUnionType, any> will complain if any of StringUnionType is not a key.
 */
export type IsExhaustiveArray<A, B> = IsNeverType<Exclude<A, ArrayElements<B>>>;
