import { colorsLight, H4 } from "@coherehealth/common";
import { CircularProgress, Divider } from "@material-ui/core";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import CohereLogo from "../images/CohereLogo.svg";

const useStyles = makeStyles((theme) => ({
  largeErrorFont: {
    fontSize: "200px",
    fontFamily: "Gilroy-Light",
    color: colorsLight.secondary.main,
    letterSpacing: "0.15px",
    fontWeight: 400,
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(5),
  },
  titleFont: {
    fontSize: "50px",
    fontFamily: "Gilroy-SemiBold",
    letterSpacing: "0.15px",
    fontWeight: 400,
    paddingBottom: theme.spacing(4),
  },
  subtitleFont: {
    color: "#6D6F71",
    opacity: 0.7,
    paddingBottom: theme.spacing(10),
    fontSize: "20px",
    fontFamily: "Gilroy-SemiBold",
    letterSpacing: "0.15px",
  },
}));

export default function SmartOnFhirLoadingPage() {
  const classes = useStyles();
  return (
    <CenteredContainer>
      <a href="/" style={{ marginTop: 12, marginBottom: 12 }}>
        <img src={CohereLogo} alt="Company Logo" />
      </a>
      <div style={{ width: "100%" }}>
        <Divider />
      </div>
      <ContentContainer>
        <div className={classes.largeErrorFont}></div>
        <div className={classes.titleFont}>Loading...</div>
        <H4 className={classes.subtitleFont}>Connecting to EHR server</H4>
        <CircularProgress />
      </ContentContainer>
    </CenteredContainer>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CenteredContainer = styled("div")({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ContentContainer = styled(CenteredContainer)({
  backgroundColor: colorsLight.background.light,
  padding: 60,
});
