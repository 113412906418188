import { InlineButton, Tooltip } from "@coherehealth/common";
import { ProcedureCode } from "@coherehealth/core-platform-api";
import { NonCohereCodes } from "components/AuthBuilder/common";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import ProcedureCodeSelect from "./ProcedureCodeSelect";
import { ServiceRequestFormContent, ContinuationFormContent } from "common/SharedServiceRequestFormComponents";
import { Caption } from "@coherehealth/design-system";
import { Add } from "@material-ui/icons";

interface Props {
  formContent: ServiceRequestFormContent | ContinuationFormContent;
  patientId?: string;
  palProcedureCodes: ProcedureCode[];
  setPalProcedureCodes: (pxs: ProcedureCode[]) => void;
  setNonPalPDFOpen: Dispatch<SetStateAction<boolean>>;
  showApprovedSrEditWarning?: boolean | undefined;
  nonPalProcedureCodes: ProcedureCode[];
  setNonPalProcedureCodes: Dispatch<SetStateAction<ProcedureCode[]>>;
  nonCohereProcedureCodes: NonCohereCodes[];
  setNonCohereProcedureCodes: Dispatch<SetStateAction<NonCohereCodes[]>>;
  procedureCodeSelectionActive: boolean;
  setProcedureCodeSelectionActive: (b: boolean) => void;
  procedureCodes: ProcedureCode[];
  setProcedureCodes: (pxs: ProcedureCode[]) => void;
  error?: boolean;
  healthPlanName?: string | null;
  attemptedSubmit?: boolean;
  isPxCodesValid?: boolean;
  canAddMoreProcedureCodes?: boolean;
  allowedPxCodePerPayer?: number;
  disabled?: boolean;
  doPalOrCrdCheck?: (payload: { desiredProcedureCodes: ProcedureCode[] }) => Promise<void>;
  onPatientSummary?: boolean;
}

export default function ProcedureCodeSelector({
  formContent,
  patientId,
  palProcedureCodes,
  nonCohereProcedureCodes,
  setPalProcedureCodes,
  setNonPalProcedureCodes,
  nonPalProcedureCodes,
  setNonCohereProcedureCodes,
  setNonPalPDFOpen,
  showApprovedSrEditWarning,
  procedureCodeSelectionActive,
  setProcedureCodeSelectionActive,
  procedureCodes,
  setProcedureCodes,
  error,
  healthPlanName,
  attemptedSubmit,
  isPxCodesValid,
  canAddMoreProcedureCodes,
  allowedPxCodePerPayer,
  disabled,
  doPalOrCrdCheck,
  onPatientSummary,
}: Props) {
  const decisionedTooltipText = (
    <div>
      {`${healthPlanName} allows up to ${allowedPxCodePerPayer} codes.`}
      <br />
      {"You can't add any more."}
    </div>
  );

  useEffect(() => {
    if (!canAddMoreProcedureCodes) {
      setProcedureCodeSelectionActive(false);
    }
  }, [canAddMoreProcedureCodes, setProcedureCodeSelectionActive]);

  return (
    <>
      {procedureCodeSelectionActive ? (
        <ProcedureCodeSelect
          error={error || false}
          procedureCodes={procedureCodes}
          palProcedureCodes={palProcedureCodes}
          setPalProcedureCodes={setPalProcedureCodes}
          nonPalProcedureCodes={nonPalProcedureCodes}
          nonCohereProcedureCodes={nonCohereProcedureCodes}
          setProcedureCodes={(codes) => setProcedureCodes(codes)}
          setNonPalProcedureCodes={setNonPalProcedureCodes}
          setNonCohereProcedureCodes={setNonCohereProcedureCodes}
          isInpatient={formContent.isInpatient}
          setNonPalPDFOpen={setNonPalPDFOpen}
          patientId={patientId || ""}
          label="Procedure code(s)"
          onBlur={(e) => {
            if (procedureCodes.length > 0) {
              setProcedureCodeSelectionActive(false);
            }
          }}
          omitSelectionChips
          autoFocus
          formContent={formContent}
          hidePalWarnings={true}
          disabled={disabled}
          blockDuplicateSelections
          doPalOrCrdCheck={doPalOrCrdCheck}
          onPatientSummary={onPatientSummary}
        />
      ) : (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              {allowedPxCodePerPayer && allowedPxCodePerPayer > 0 && !canAddMoreProcedureCodes ? (
                <Tooltip title={decisionedTooltipText} placement="top" arrow>
                  <span>
                    <InlineButton
                      disabled={!canAddMoreProcedureCodes}
                      onClick={() => setProcedureCodeSelectionActive(true)}
                      startIcon={<Add />}
                    >
                      {"Add a procedure code"}
                    </InlineButton>
                  </span>
                </Tooltip>
              ) : (
                <span>
                  <InlineButton onClick={() => setProcedureCodeSelectionActive(true)} startIcon={<Add />}>
                    {"Add a procedure code"}
                  </InlineButton>
                </span>
              )}
            </div>
            <div>
              {procedureCodes.length === 0 && attemptedSubmit && !isPxCodesValid && (
                <Caption color="error.dark">At least one procedure code must be added</Caption>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
