import { Caption, Subtitle1, dateAndTimeParsedFromDateStr } from "@coherehealth/common";
import { PeerToPeerOutreachOutcome } from "@coherehealth/core-platform-api";
import { Card, makeStyles } from "@material-ui/core";
import WarningFlagIcon from "components/images/WarningFlagIcon";
import { BULLET } from "util/PDFUtils";

interface Props {
  dateCreated?: string;
  attemptedTimestamp?: string;
  outcome?: PeerToPeerOutreachOutcome;
}

const useStyles = makeStyles((theme) => ({
  banner: {
    backgroundColor: theme.palette.warning.light,
    marginLeft: 1,
    marginRight: 1,
  },
  contentContainer: {
    padding: theme.spacing(2, 3),
    display: "flex",
    flexDirection: "row",
  },
  iconContainer: {
    height: "100%",
    paddingRight: theme.spacing(1),
  },
  icon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
  },
  topText: {
    marginTop: 3,
    marginBottom: theme.spacing(0.5),
    color: theme.palette.warning.dark,
  },
  bottomTextContainer: {
    display: "inline-block",
    flexDirection: "row",
  },
  thinFont: {
    color: theme.palette.text.secondary,
  },
  thickFont: {
    fontFamily: "Gilroy-Medium",
  },
}));

export default function PeerToPeerOuteachBanner({ dateCreated, attemptedTimestamp, outcome }: Props) {
  const classes = useStyles();
  const { date: attemptedDate, time: attemptedTime } = dateAndTimeParsedFromDateStr(attemptedTimestamp);
  const { date: createdDate, time: createdTime } = dateAndTimeParsedFromDateStr(dateCreated);
  return (
    <Card className={classes.banner} elevation={0}>
      <div className={classes.contentContainer}>
        <div className={classes.iconContainer}>
          <WarningFlagIcon className={classes.icon} />
        </div>
        <div className={classes.textContainer}>
          <Subtitle1 className={classes.topText}>PEER TO PEER {PEER_TO_PEER_COPY[outcome || "DECLINED"]}</Subtitle1>
          <div className={classes.bottomTextContainer}>
            <Caption>
              <span className={classes.thinFont}>Last attempted on </span>
              <span className={classes.thickFont}>{attemptedDate}</span>
              <span className={classes.thinFont}> at </span>
              <span className={classes.thickFont}>{attemptedTime}</span>
              <span className={classes.thinFont}> {BULLET} Finalized on </span>
              <span className={classes.thickFont}>{createdDate}</span>
              <span className={classes.thinFont}> at </span>
              <span className={classes.thickFont}>{createdTime}</span>
            </Caption>
          </div>
        </div>
      </div>
    </Card>
  );
}

const PEER_TO_PEER_COPY: Record<PeerToPeerOutreachOutcome, string> = {
  UNRESPONSIVE: "UNRESPONSIVE",
  DECLINED: "DECLINED",
  SUPPLIED_ADDITIONAL_INFO: "OFFERED - ADDITIONAL DOCUMENTATION SUPPLIED",
};
