import React from "react";
import { makeStyles, Slide, Divider, Grid } from "@material-ui/core";
import { Body1, Caption, H5 } from "@coherehealth/common";
import { formatDateStrWithTime, fileSizeString } from "@coherehealth/common";
import SingleAttachmentFileActions from "./SingleAttachmentFileActions";
import { NotificationViewerItem } from "components/NotificationViewer/util/notificationUtil";
import { isAttachmentNotification } from "components/NotificationViewer/util/notificationUtil";

interface SingleAttachmentViewerSidePanelProps {
  notifications: NotificationViewerItem[];
  setNotifications?: React.Dispatch<React.SetStateAction<NotificationViewerItem[]>>;
  handleNotificationClick: (index: number, landingPage?: number) => void;
  notificationIndexOpen?: number;
  openSidePanel?: boolean;
  sidePanelRef?: (node: React.ReactNode) => void;
  jumpToPage?: (pageIndex: number) => void;
  closeDialogFunction?: () => void;
}

export default function SingleAttachmentViewerSidePanel({
  notifications,
  openSidePanel,
  sidePanelRef,
  closeDialogFunction,
}: SingleAttachmentViewerSidePanelProps) {
  const classes = useStyles({});

  let name = "",
    size = 0,
    dateCreated = "",
    createdByName = "";

  // Using a type predicate so TypeScript knows this is an Attachment
  if (isAttachmentNotification(notifications[0])) {
    const attachment = notifications[0].data;
    name = attachment.name || "";
    size = attachment.size || 0;
    dateCreated = attachment.dateCreated || "";
    createdByName = attachment.createdByName || "";
  }

  return (
    <Slide in={openSidePanel} direction="right" mountOnEnter unmountOnExit ref={sidePanelRef}>
      <div className={classes.sidePanel}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <H5>{name || "Unknown File"}</H5>
          </Grid>
          {size && (
            <Grid item xs={12}>
              <Caption data-public>Size</Caption>
              <Body1 data-public>{fileSizeString(size)}</Body1>
            </Grid>
          )}
          {dateCreated && (
            <Grid item xs={12}>
              <Caption data-public>Added</Caption>
              <Body1 data-public>{formatDateStrWithTime(dateCreated)}</Body1>
            </Grid>
          )}
          {createdByName && (
            <Grid item xs={12}>
              <Caption data-public>Uploaded by</Caption>
              <Body1 data-public>{createdByName}</Body1>
            </Grid>
          )}
        </Grid>
        <Divider style={{ alignSelf: "stretch", marginTop: "36px" }} />
        <div className={classes.fileActionsContainer}>
          <SingleAttachmentFileActions
            selectedNotifications={notifications}
            closeDialogFunction={closeDialogFunction}
          />
        </div>
      </div>
    </Slide>
  );
}

const useStyles = makeStyles((theme) => ({
  sidePanel: {
    height: "100%",
    width: "400px",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingTop: "48px",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "start",
    overflowY: "scroll",
  },

  fileActionsContainer: {
    marginTop: "auto",
    marginBottom: "30px",
    paddingTop: "12px",
  },
}));
