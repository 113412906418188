import { Theme } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import merge from "lodash/merge";
import { colorsDark as colors } from "./colors";
import common from "./common";

// https://material-ui.com/customization/default-theme/
const themeDark: Theme = createTheme(
  merge({}, common, {
    palette: {
      type: "dark",
      divider: colors.gray.divider,
      text: {
        primary: colors.white.highEmphasis,
        secondary: colors.white.mediumEmphasis,
        tertiary: colors.font.tertiary,
        disabled: colors.white.disabled,
        hint: colors.white.disabled,
      },
      background: {
        paper: colors.background.card,
        default: colors.background.dark,
      },
      action: colors.action,
      drawer: {
        main: colors.background.light,
        expanded: colors.background.card,
        button: colors.background.cardLight,
      },
    },
    overrides: {
      // overrides for Datepicker
      MuiPickersYear: {
        yearButton: {
          "&:hover, &:focus": {
            color: colors.primary.main,
          },
        },
      },
      MuiIconButton: {
        root: {
          color: colors.gray.light,
        },
      },
    },
  })
);
export { themeDark };
