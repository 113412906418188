import { ServiceRequestSearchResponse, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { Dialog } from "@material-ui/core";
import DocumentViewer from "components/DocumentViewer";
import DetailsPrintoutProvider from "components/ServiceRequest/DetailsPrintoutProvider";
import useSearchAndLocation from "hooks/useSearchAndLocation";

interface ServiceRequestPrintDialogProps {
  showSrDetailsPrintout: boolean;
  serviceRequest: ServiceRequestSearchResponse | ServiceRequestResponse;
  setShowSrDetailsPrintout: (value: React.SetStateAction<boolean>) => void;
}

export default function ServiceRequestPrintDialog(props: ServiceRequestPrintDialogProps): JSX.Element {
  const { serviceRequest, setShowSrDetailsPrintout, showSrDetailsPrintout } = props;
  const { location, search } = useSearchAndLocation();

  return (
    <Dialog
      TransitionProps={{ unmountOnExit: true }}
      fullScreen
      open={showSrDetailsPrintout}
      onClose={() => setShowSrDetailsPrintout(false)}
    >
      <DetailsPrintoutProvider serviceRequest={serviceRequest}>
        {({ url, loading }) => (
          <DocumentViewer
            documentInfo={{
              name: `CohereServiceRequest_${serviceRequest.cohereId}.pdf`,
              contentType: "application/pdf",
            }}
            loading={loading}
            url={url || undefined}
            handleClose={() => setShowSrDetailsPrintout(false)}
            canDelete={false}
            location={location}
            search={search}
          />
        )}
      </DetailsPrintoutProvider>
    </Dialog>
  );
}
