import React from "react";
import ReactPDF, { StyleSheet, Image, Link, Text, View, Page } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";
import CohereLogo from "components/images/CohereLogo.jpg";
import CohereLogoGrayscale from "components/images/CohereLogoGrayscale.jpg";
import LightbulbOutlineIcon from "components/images/LightbulbOutlineIcon.jpg";
import { colorsLight } from "@coherehealth/common";
import PayerAgnosticFaxBackEmptyFormFirstPage from "components/images/PayerAgnosticFaxBackEmptyFormPart1.jpg";
import PayerAgnosticFaxBackEmptyFormSecondPage from "components/images/PayerAgnosticFaxBackEmptyFormPart2.jpg";
import PayerAgnosticFaxBackEmptyFormThirdPage from "components/images/PayerAgnosticFaxBackEmptyFormPart3.jpg";
import PayerAgnosticReferralFaxBackEmptyFormFirstPage from "components/images/PayerAgnosticReferralFaxBackEmptyPage1.jpg";
import PayerAgnosticReferralFaxBackEmptyFormSecondPage from "components/images/PayerAgnosticReferralFaxBackEmptyPage2.jpg";
import { GilroyBold, GilroyRegular } from "./PDFfonts";

interface TextFieldProps extends ReactPDF.ViewProps {
  label: string;
  value: string;
  styleOverrides?: {
    textField?: Style;
    label?: Style;
    body?: Style;
  };
}

export function TextField({ label, value, styleOverrides }: TextFieldProps) {
  return (
    <View style={styleOverrides?.textField || styles.textField}>
      <Text style={styleOverrides?.label || styles.label}>{label}</Text>
      <Text style={styleOverrides?.body || styles.body}>{value}</Text>
    </View>
  );
}

interface CohereServiceRequestPDFHeaderProps {
  email?: string;
  phone: string;
  contactName: string;
}

export function CohereServiceRequestPDFHeader({ email, phone, contactName }: CohereServiceRequestPDFHeaderProps) {
  return (
    <View style={styles.logoContactRow}>
      <Image src={CohereLogo} style={styles.logo} />
      <View style={styles.contactInfo}>
        <Text style={[styles.label, { paddingBottom: 16 }]}>Questions about this service?</Text>
        <Text style={styles.body}>Contact {contactName}</Text>
        {email ? (
          <Link style={styles.contactLink} src={`mailto:${email}`}>
            {email}
          </Link>
        ) : null}
        <Link style={styles.contactLink} src={`tel:${phone}`}>
          {phone}
        </Link>
      </View>
    </View>
  );
}

export function CohereDisclaimerFooter() {
  return (
    <View style={styles.fullWidthSection}>
      <Text style={styles.disclaimer}>
        This document contains confidential information and is protected by the Health Insurance Portability and
        Accountability Act (HIPAA), the Health Information Technology for Economic and Clinical Health Act (HITECH) and
        a number of other federal and state privacy laws. This document and its contents may only be accessed, used or
        disclosed by duly authorized individuals in the course of the subject’s treatment, claims processing or as
        otherwise required or permitted by law. Any other access, use or disclosure is strictly prohibited and may
        result in civil or criminal penalties.
      </Text>
    </View>
  );
}

type FaxBackType = "MISSING_INFO" | "OUT_OF_SCOPE" | "FINAL_DETERMINATION";

interface FaxProps {
  faxBackType: FaxBackType;
  dateRequested?: string | undefined;
  isGhpUser?: boolean;
}

export function CohereFaxPDFHeader({ faxBackType, dateRequested, isGhpUser }: FaxProps) {
  return (
    <View style={{ position: "relative" }}>
      <View style={faxStyles.logoRow}>
        <View>
          <Image src={CohereLogoGrayscale} style={faxStyles.logo} />
        </View>
        <View>
          <View style={faxStyles.headerSection}>
            <Text style={{ paddingBottom: 6 }}>
              <Text style={faxStyles.regularMediumHeadingText}>From: </Text>
              {!isGhpUser && <Text style={faxStyles.boldMediumHeadingText}>Cohere Health</Text>}
              {isGhpUser && <Text style={faxStyles.boldMediumHeadingText}>Geisinger Health Plan</Text>}
            </Text>
            {faxBackType === "FINAL_DETERMINATION" ? (
              <Text style={faxStyles.subjectInfoText}>We have finished processing your service request.</Text>
            ) : (
              <Text style={faxStyles.subjectInfoText}>Unable to process request</Text>
            )}
          </View>
        </View>
      </View>
      <View style={[styles.fullWidthSection, { flexDirection: "row" }]}>
        <View style={{ padding: "8 2 2 2" }}>
          <Image src={LightbulbOutlineIcon} style={faxStyles.infoIcon} />
        </View>
        <Text style={{ margin: "8 8 8 8" }}>
          <Text style={faxStyles.smallBoldText}>Don't want to fax anymore? </Text>
          <Text style={faxStyles.smallText}>
            You could be getting time-saving benefits, including automatic approvals and guided submissions by using the
            portal. Registration only takes a few minutes, and unlocks access for all users at your practice
            organization.
          </Text>
          <Text style={faxStyles.smallBoldText}>Visit www.coherehealth.com/register to begin. </Text>
        </Text>
      </View>
      <View style={[styles.fullWidthSection, { borderBottomWidth: 1 }]}></View>
    </View>
  );
}

export function CohereFaxResourcesSection({ faxBackType, isGhpUser }: FaxProps) {
  return (
    <View style={faxStyles.bodySection}>
      {faxBackType === "FINAL_DETERMINATION" && !isGhpUser && (
        <>
          <Text style={{ paddingBottom: 4 }}>
            <Text style={faxStyles.smallBoldText}>Please note: </Text>
            <Text style={faxStyles.smallText}>
              Physical therapy, occupational therapy, and speech therapy are not considered “urgent” services as defined
              in the Medicare Managed
            </Text>
          </Text>
          <Text style={[faxStyles.smallText, { paddingBottom: 24 }]}>
            Care Manual. Therefore, Cohere Health will process all such requests according to standard timeframes.
          </Text>
        </>
      )}
      <Text>
        <Text style={faxStyles.smallText}>
          For more provider resources, including user guide, fax forms, codes delegated to Cohere and more, please visit
        </Text>
      </Text>
      <Text>
        <Text style={faxStyles.smallBoldText}> https://coherehealth.com/provider/resources/</Text>
      </Text>
    </View>
  );
}

export function CohereFaxFooter() {
  return (
    <>
      <View style={faxStyles.footerSection}>
        <Text style={faxStyles.smallTextVersion}>
          The information transmitted is intended only for the person or entity to which it is addressed and may contain
          CONFIDENTIAL material. If you receive this
        </Text>
        <Text style={faxStyles.smallTextVersion}>
          material/information in error, please contact the sender and delete or destroy the material/information
        </Text>
        <Text style={faxStyles.smallTextVersion}>© 2024 Cohere Health, Inc.. All Rights Reserved.</Text>
      </View>
      <View style={faxStyles.versionSection}>
        <Text style={faxStyles.smallText}>Version 2 (September 2024)</Text>
      </View>
      <View style={faxStyles.pageNoSection}>
        <Text style={faxStyles.smallText}>Page 1 of 1</Text>
      </View>
    </>
  );
}

interface FaxEmptyFormProps {
  isGhpUser?: boolean;
}

export function CohereFaxEmptyForm({ isGhpUser }: FaxEmptyFormProps) {
  return !isGhpUser ? (
    <>
      <Page size={[650, 841.89]} style={faxStyles.emptyFormPage}>
        <Image src={PayerAgnosticFaxBackEmptyFormFirstPage} style={faxStyles.emptyForm} />
      </Page>
      <Page size={[650, 841.89]} style={faxStyles.emptyFormPage}>
        <Image src={PayerAgnosticFaxBackEmptyFormSecondPage} style={faxStyles.emptyForm} />
      </Page>
      <Page size={[650, 841.89]} style={faxStyles.emptyFormPage}>
        <Image src={PayerAgnosticFaxBackEmptyFormThirdPage} style={faxStyles.emptyForm} />
      </Page>
    </>
  ) : null;
}

export function CohereReferralFaxEmptyForm() {
  return (
    <>
      <Page size={[650, 841.89]} style={faxStyles.emptyFormPage}>
        <Image src={PayerAgnosticReferralFaxBackEmptyFormFirstPage} style={faxStyles.emptyForm} />
      </Page>
      <Page size={[650, 841.89]} style={faxStyles.emptyFormPage}>
        <Image src={PayerAgnosticReferralFaxBackEmptyFormSecondPage} style={faxStyles.emptyForm} />
      </Page>
    </>
  );
}

const styles = StyleSheet.create({
  logoContactRow: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  logo: {
    height: 66,
    paddingBottom: 16,
  },
  contactInfo: {
    alignItems: "flex-end",
  },
  label: {
    color: colorsLight.font.secondary,
    fontSize: 10,
    paddingBottom: 4,
  },
  contactLink: {
    color: colorsLight.font.main,
    fontSize: 10,
    paddingTop: 4,
  },
  textField: {
    paddingBottom: 12,
  },
  body: {
    color: colorsLight.font.main,
    fontWeight: 600,
    fontSize: 10,
  },
  disclaimer: {
    color: colorsLight.font.light,
    fontSize: 10,
  },
  fullWidthSection: {
    width: "100%",
  },
});

const faxStyles = StyleSheet.create({
  logoRow: {
    width: "100%",
    flexDirection: "row",
    borderBottomWidth: 1,
  },
  logo: {
    height: 48,
    paddingRight: 12,
    paddingBottom: 8,
    paddingTop: 12,
  },
  headerSection: {
    flexDirection: "column",
    width: "85%",
    paddingLeft: 12,
    paddingBottom: 16,
    borderLeftWidth: 1,
  },
  subjectInfoText: {
    fontSize: 18,
    fontFamily: GilroyBold,
  },
  regularMediumHeadingText: {
    fontSize: 8,
    fontFamily: GilroyRegular,
  },
  boldMediumHeadingText: {
    fontSize: 8,
    fontFamily: GilroyBold,
  },
  smallText: {
    fontSize: 7,
    fontFamily: GilroyRegular,
  },
  smallBoldText: {
    fontSize: 7,
    fontFamily: GilroyBold,
  },
  responseText: {
    fontSize: 8,
    paddingLeft: 4,
    paddingBottom: 1,
    height: 15,
    marginLeft: 24,
  },
  infoIcon: {
    width: 16,
    height: 16,
    color: "#9A9A9A",
  },
  footerSection: {
    alignItems: "center",
    flexDirection: "column",
    position: "absolute",
    bottom: 80,
    left: 56,
    paddingTop: 4,
  },
  bodySection: {
    minWidth: "100%",
    borderBottom: 1,
    paddingTop: 24,
    paddingBottom: 24,
    position: "relative",
  },
  emptyFormPage: {
    display: "flex",
    flexWrap: "wrap",
    position: "relative",
    padding: 32,
  },
  emptyForm: {
    width: "100%",
    height: "100%",
  },
  versionSection: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    bottom: 56,
    left: 56,
    paddingTop: 24,
    marginTop: 48,
  },
  pageNoSection: {
    alignItems: "flex-end",
    flexDirection: "row",
    justifyContent: "flex-end",
    position: "absolute",
    bottom: 56,
    left: 510,
    paddingTop: 24,
    marginTop: 48,
  },
  smallTextVersion: {
    fontSize: 7,
    fontFamily: GilroyRegular,
    color: "#5E6572",
  },
});

export const BULLET = "\u2022";
