import Drawer from "@material-ui/core/Drawer";
import Container from "@material-ui/core/Container";
import FaxNotificationIcon from "components/images/FaxNotificationIcon.svg";

import { H2, H3, SecondaryButton } from "@coherehealth/common";

import { makeStyles } from "@material-ui/core/styles";
import { FaxSidebarView, INCOMING_FAX_SIDEBAR_WIDTH, backToMenuView } from "../common";

interface Props {
  setFaxSidebarView: (view: FaxSidebarView) => void;
}

const useStyles = makeStyles((theme) => ({
  drawer: {
    "& .MuiDrawer-paperAnchorDockedRight": {
      borderBottomRightRadius: 4,
    },
  },
  button: {
    float: "right",
    marginTop: theme.spacing(2),
  },
  container: {
    width: INCOMING_FAX_SIDEBAR_WIDTH,
    height: "100%",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  item: {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    display: "block",
  },
  image: {
    width: 150,
    height: 150,
    display: "block",
    marginTop: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: theme.spacing(4),
  },
  backButton: {
    marginBottom: theme.spacing(3),
  },
}));

export default function DownloadConfirmationSidebar({ setFaxSidebarView }: Props) {
  const classes = useStyles();
  return (
    <Drawer variant="permanent" anchor="right" className={classes.drawer}>
      <Container className={classes.container}>
        <img src={FaxNotificationIcon} className={classes.image} alt="FaxSentConfirmationIcon" />
        <H3 style={{ paddingBottom: 8 }} className={classes.item}>
          Great work!
        </H3>
        <H2 className={classes.item} style={{ marginBottom: "auto" }}>
          Go to Vonage to fax the PDF you just downloaded.
        </H2>
        <SecondaryButton fullWidth onClick={() => setFaxSidebarView(backToMenuView)} className={classes.backButton}>
          Back
        </SecondaryButton>
      </Container>
    </Drawer>
  );
}
