import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { withId } from "@coherehealth/common";
import { AuthorizationResponse, AuthStatus, ProcedureCode } from "@coherehealth/core-platform-api";
import { ServiceRequestFormContent, ContinuationFormContent } from "common/SharedServiceRequestFormComponents";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { constructProcedureCodes } from "util/clinicalAssessment";
import listReplace from "util/listReplace";
import { NonCohereCodes } from "components/AuthBuilder/common";
import { useTrackUserImpression } from "util/userActivityTracker";
import ProcedureCodeSelector from "./ProcedureCodeSelector";
import ProcedureCodeTable from "./ProcedureCodeTable";
import { useDebouncedCallback, usePrevious } from "@react-hookz/web";

interface Props {
  formContent: ServiceRequestFormContent | ContinuationFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent | ContinuationFormContent>>;
  patientId?: string;
  clinicalServiceId?: string;
  palProcedureCodes: ProcedureCode[];
  setPalProcedureCodes: (pxs: ProcedureCode[]) => void;
  setNonPalPDFOpen: Dispatch<SetStateAction<boolean>>;
  showApprovedSrEditWarning?: boolean | undefined;
  attemptedSubmit: boolean;
  onBlurServiceLevelUnits: number;
  nonPalProcedureCodes: ProcedureCode[];
  setNonPalProcedureCodes: Dispatch<SetStateAction<ProcedureCode[]>>;
  nonCohereProcedureCodes: NonCohereCodes[];
  setNonCohereProcedureCodes: Dispatch<SetStateAction<NonCohereCodes[]>>;
  isContinuation?: boolean;
  onPatientSummary?: boolean;
  authorization?: AuthorizationResponse;
  authorizationProcedureCodes?: ProcedureCode[];
  setSemanticProcedureCodes?: Dispatch<SetStateAction<ProcedureCode[]>>;
  requestType?: string;
  healthPlanName: string;
  authStatus: AuthStatus;
  disabled?: boolean;
}

export default function ProcedureCodeSelectTableWithUnits({
  formContent,
  setFormContent,
  patientId,
  clinicalServiceId,
  palProcedureCodes,
  setPalProcedureCodes,
  setNonPalPDFOpen,
  showApprovedSrEditWarning,
  attemptedSubmit,
  onBlurServiceLevelUnits,
  nonPalProcedureCodes,
  setNonPalProcedureCodes,
  nonCohereProcedureCodes,
  setNonCohereProcedureCodes,
  isContinuation,
  onPatientSummary,
  authorization,
  authorizationProcedureCodes,
  setSemanticProcedureCodes,
  requestType,
  healthPlanName,
  authStatus,
  disabled,
}: Props) {
  const initialRender = useRef(true);
  const [serviceLevelUnits, setServiceLevelUnits] = useState(formContent.units);
  const [procedureCodeSelectionActive, setProcedureCodeSelectionActive] = useState(false);
  const prevServiceLevelUnits = usePrevious(serviceLevelUnits);
  const prevOnBlurServiceLevelUnits = usePrevious(onBlurServiceLevelUnits);
  const previousCareType = usePrevious(formContent.isInpatient);
  const [isSinglePxUpdate, setIsSinglePxUpdate] = useState(false);
  const canAddMoreUnits = onPatientSummary ? !formContent?.isInpatient && isContinuation : true;

  const trackUserActivityImpression = useTrackUserImpression();

  const procedureCodes = formContent.procedureCodes.map(withId);
  const setProcedureCodes: Dispatch<SetStateAction<ProcedureCode[]>> = useCallback(
    (update) => {
      if (typeof update === "function") {
        setFormContent((prevFormContent) => ({
          ...prevFormContent,
          procedureCodes: update(prevFormContent.procedureCodes),
        }));
      } else {
        setFormContent((prevFormContent) => ({ ...prevFormContent, procedureCodes: update }));
      }
    },
    [setFormContent]
  );
  const prevProcedureCodes = usePrevious(procedureCodes);

  useEffect(() => {
    if (procedureCodes) {
      let calculatedUnits = procedureCodes.reduce((a, b) => a + (b.units ? b.units : 0), 0).toString();
      if (calculatedUnits !== formContent.units) {
        setFormContent((prev) => {
          return { ...prev, units: calculatedUnits };
        });
      }
    }
  }, [procedureCodes, setFormContent, formContent.units]);

  const updateProcedureCodes = useCallback(() => {
    const newProcedureCodes = constructProcedureCodes({
      procedureCodes: procedureCodes,
      prevIsInpatient: previousCareType,
      isInpatient: formContent.isInpatient,
      prevServiceLevelUnits: prevServiceLevelUnits,
      serviceLevelUnits: formContent.units,
      clinicalServiceId: clinicalServiceId || "",
      isUnitsOnPx: true,
      isContinuation: isContinuation,
    }).map(withId);

    setProcedureCodes(newProcedureCodes);
  }, [
    procedureCodes,
    previousCareType,
    formContent.isInpatient,
    formContent.units,
    prevServiceLevelUnits,
    clinicalServiceId,
    isContinuation,
    setProcedureCodes,
  ]);

  useEffect(() => {
    /** Check if serviceLevelUnits has not been set yet and serviceLevelUnits has lost focus, then set serviceLevelUnits **/
    if (serviceLevelUnits !== formContent.units && prevOnBlurServiceLevelUnits !== onBlurServiceLevelUnits) {
      setServiceLevelUnits(formContent.units);
      updateProcedureCodes();
    }
  }, [
    formContent.units,
    onBlurServiceLevelUnits,
    prevOnBlurServiceLevelUnits,
    serviceLevelUnits,
    updateProcedureCodes,
  ]);

  useEffect(() => {
    /** Check if care type was switched **/
    if (formContent.isInpatient !== previousCareType) {
      setServiceLevelUnits(formContent.units);
      updateProcedureCodes();
    }
  }, [formContent.isInpatient, formContent.units, previousCareType, serviceLevelUnits, updateProcedureCodes]);

  useDebouncedCallback(
    () => {
      const exceedsMax = procedureCodes.filter(
        (pxCode) => pxCode.units && pxCode.maxUnits && pxCode.units > (pxCode.maxUnits ?? 1) * Number(serviceLevelUnits)
      );
      if (isSinglePxUpdate && exceedsMax && exceedsMax.length > 0) {
        trackUserActivityImpression({
          event: "EXCEEDED_MAX_UNITS_FOR_PXCODES",
          stage: "AUTH_CREATION",
          activityContext: {
            procedureCodes: exceedsMax,
            serviceRequestId: formContent?.id,
            cohereAuthId: formContent?.cohereId,
          },
        });
      }
      setIsSinglePxUpdate(false);
    },
    [procedureCodes, isSinglePxUpdate],
    1000
  );

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    }
  }, [formContent.isInpatient, prevProcedureCodes, procedureCodes, isSinglePxUpdate]);

  const updatePxCodeUnits = (rowIndex: number, units: string) => {
    const updatedProcedureCodes = [...procedureCodes];
    setIsSinglePxUpdate(true);
    setProcedureCodes(
      listReplace(updatedProcedureCodes, rowIndex, {
        ...updatedProcedureCodes[rowIndex],
        units: /^\d+$/.test(units)
          ? Number(units)
          : units === "" || units === undefined
          ? undefined
          : updatedProcedureCodes[rowIndex]?.units,
      })
    );
  };

  const addPxCodeToFormContent = (codes: ProcedureCode[]) => {
    //close the selection dropdown after the user has made a selection
    setProcedureCodeSelectionActive(false);
    /**
     * if new codes are added then update construct and update state,
     * this is to make sure if the palCheck doesn't add the code we don't add the last code again
     */
    if (codes.length > procedureCodes.length) {
      const newProcedureCodes = constructProcedureCodes({
        procedureCodes: [codes[codes.length - 1]],
        prevIsInpatient: previousCareType,
        isInpatient: formContent.isInpatient,
        prevServiceLevelUnits: prevServiceLevelUnits,
        serviceLevelUnits: serviceLevelUnits,
        clinicalServiceId: clinicalServiceId || "",
        isUnitsOnPx: true,
      }).map(withId);

      setProcedureCodes((prev) => {
        return [...prev, ...newProcedureCodes];
      });
    }
  };

  return (
    <>
      <Grid container spacing={0}>
        <ProcedureCodeTable
          formContent={formContent}
          setNonPalPDFOpen={setNonPalPDFOpen}
          nonPalProcedureCodes={nonPalProcedureCodes}
          nonCohereProcedureCodes={nonCohereProcedureCodes}
          procedureCodes={procedureCodes}
          setProcedureCodes={setProcedureCodes}
          setProcedureCodeSelectionActive={setProcedureCodeSelectionActive}
          serviceLevelUnits={serviceLevelUnits}
          isUnitsOnPx={true}
          isContinuation={isContinuation}
          autoFilled={false}
          updatePxCodeUnits={updatePxCodeUnits}
          attemptedSubmit={attemptedSubmit}
          onPatientSummary={onPatientSummary}
          authorizationProcedureCodes={authorizationProcedureCodes}
          setSemanticProcedureCodes={setSemanticProcedureCodes}
          authStatus={authStatus}
          requestType={requestType}
          healthPlanName={healthPlanName}
          disabled={disabled}
        />
        {canAddMoreUnits && (
          <>
            <Spacer />
            <ProcedureCodeSelector
              error={procedureCodes.length < 1}
              formContent={formContent}
              palProcedureCodes={palProcedureCodes}
              setPalProcedureCodes={setPalProcedureCodes}
              setNonPalPDFOpen={setNonPalPDFOpen}
              nonPalProcedureCodes={nonPalProcedureCodes}
              setNonPalProcedureCodes={setNonPalProcedureCodes}
              nonCohereProcedureCodes={nonCohereProcedureCodes}
              setNonCohereProcedureCodes={setNonCohereProcedureCodes}
              procedureCodeSelectionActive={procedureCodeSelectionActive}
              setProcedureCodeSelectionActive={setProcedureCodeSelectionActive}
              procedureCodes={procedureCodes}
              setProcedureCodes={(codes) => addPxCodeToFormContent(codes)}
              patientId={patientId}
              disabled={disabled}
            />
          </>
        )}
      </Grid>
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Spacer = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 0),
  width: "100%",
}));
