import React from "react";
import { makeStyles } from "tss-react/mui";
import { Alert as MuiAlert, AlertTitle as MuiAlertTitle, AlertProps } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import UndoIcon from "@mui/icons-material/Undo";
import InfoIcon from "@mui/icons-material/Info";
import SuccessIcon from "@mui/icons-material/CheckCircle";
import PushPinIcon from "./PushPinIcon";
import Timer from "@mui/icons-material/Timer";

interface StyleProps {
  subtext?: boolean;
  message?: boolean;
}

const useStylesAlert = makeStyles<StyleProps>()((theme, props) => ({
  root: {
    "& .MuiAlert-message": {
      padding: props.subtext && props.message ? "0px" : "11px 0px 10px 0px",
    },
  },
  // TODO change these hard-coded colors to theme.palette.font.<color>
  standardDraft: {
    backgroundColor: "#F5F5F5",
    "& .MuiAlertTitle-root": {
      color: "#5E6572",
    },
    "& .MuiAlert-icon > .MuiSvgIcon-root": {
      color: "#5E6572",
    },
  },
  standardWithdrawn: {
    backgroundColor: "#F5F5F5",
    "& .MuiAlertTitle-root": {
      color: "#212936",
    },
    "& .MuiAlert-icon > .MuiSvgIcon-root": {
      color: "#5E6572",
    },
  },
}));

const useStylesAlertTitle = makeStyles<StyleProps>()((theme, props) => ({
  root: {
    margin: "0px",
    marginBottom: props.subtext && props.message ? "2px" : "",
    fontWeight: theme.typography.body2.fontWeight,
    lineHeight: theme.typography.body2.lineHeight,
    fontSize: theme.typography.body2.fontSize,
    letterSpacing: theme.typography.body2.letterSpacing,
  },
  onlyTitle: {},
}));

export interface CohereAlertProps {
  /** Provide the 'title' content in this prop (instead of in a Mui `AlertTitle`), to get the proper styling. */
  message?: React.ReactNode;
  severity?: AlertProps["severity"] | "draft" | "withdrawn" | "integrationFailure";
}
// Temporary export for storybook documentation
export function AlertForProps(props: CohereAlertProps) {}

const Alert = ({
  message,
  severity,
  children,
  className,
  ...props
}: CohereAlertProps & Omit<AlertProps, "severity" | "css">) => {
  const styleProps = { subtext: !!children, message: !!message };
  const { standardDraft, standardWithdrawn, ...alertClasses } = useStylesAlert({ ...styleProps }).classes;
  const extraClasses =
    severity === "draft"
      ? standardDraft
      : severity === "withdrawn" || severity === "integrationFailure"
      ? standardWithdrawn
      : "";
  const alertTitleClasses = useStylesAlertTitle({ ...styleProps }).classes;
  const extraAlertTitleClass = !children ? alertTitleClasses.onlyTitle : "";
  const infoIconMaps =
    severity === "info" ? (
      <InfoIcon />
    ) : message === "Assessment not ready yet" ? (
      <Timer />
    ) : message === "Action required: Complete assessment" ? (
      <ErrorIcon />
    ) : severity === "draft" ? (
      <PushPinIcon />
    ) : severity === "integrationFailure" ? (
      <ErrorIcon />
    ) : (
      <UndoIcon />
    );
  return (
    <MuiAlert
      className={`${extraClasses} ${className}`}
      classes={alertClasses}
      iconMapping={{
        error: <CancelIcon />,
        warning: <ErrorIcon />,
        success: <SuccessIcon />,
        info: infoIconMaps,
      }}
      severity={
        severity === "draft" || severity === "withdrawn" || severity === "integrationFailure" ? "info" : severity
      }
      {...props}
    >
      {message && (
        <MuiAlertTitle className={extraAlertTitleClass} classes={{ root: alertTitleClasses.root }}>
          {message}
        </MuiAlertTitle>
      )}
      {children}
    </MuiAlert>
  );
};

export default Alert;
