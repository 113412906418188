import {
  H2,
  Modal,
  MultiSelectDropdown,
  PrimaryButton,
  RichTextEditor,
  SingleSelectDropdown,
  Subtitle2,
  queueMgmtBaseUrl,
} from "@coherehealth/common";
import {
  ClinicalReviewChangeRequestPayload,
  MdReview,
  NurseReview,
  PeerToPeerReview,
  ReturnRequestTo,
  ReturnReviewReason,
  ReviewOutcome,
  useSendReviewBackToReviewer,
} from "@coherehealth/core-platform-api";
import { useCompleteServiceCase } from "@coherehealth/qm-api";
import { Grid, makeStyles } from "@material-ui/core";
import config from "api/config";
import { navigateToPS } from "components/AuthBuilder/common";
import { useSnackbar } from "notistack";
import { ComponentProps, Dispatch, SetStateAction, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ReturnRequestToOption,
  ReturnToReviewReasonOption,
  reasonOptions,
  returnRequestToOptions,
} from "./ReturnRequestDetailsUtils";

interface ReturnRequestDetailsModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  patientId: string;
  review: MdReview | NurseReview | PeerToPeerReview | null;
  returnReviewReasons?: ReturnReviewReason[];
  correctionDetails?: string;
}

export default function ReturnRequestDetailsModal(props: ReturnRequestDetailsModalProps): JSX.Element {
  const { isOpen, setIsOpen, patientId, review } = props;

  const reviewType = getReturnRequestTo(review?.reviewType ?? null);
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const search = new URLSearchParams(location.search);

  const [returnRequestTo, setReturnRequestTo] = useState<ReturnRequestTo>(reviewType);
  const [returnReviewReasons, setReturnReviewReasons] = useState<ReturnReviewReason[]>(props.returnReviewReasons ?? []);
  const [correctionDetails, setCorrectionDetails] = useState<string>(props.correctionDetails ?? "");

  const caseId = search.get("caseId");
  const createAndReturnToOriginLbl = !!caseId ? "Return to review queue" : "Return to patient summary";

  const navigateBackToQM = (caseId: string | null): void => {
    window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${caseId}`);
  };

  const {
    mutate: sendReviewBackToReviewer,
    loading: sendReviewBackToReviewerLoading,
    error: sendReviewBackToReviewerError,
  } = useSendReviewBackToReviewer({ reviewId: review?.id! });

  const { mutate: completeServiceCase, loading: completingServiceCase } = useCompleteServiceCase({
    id: caseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
    onMutate: () => {
      navigateBackToQM(caseId);
    },
  });

  const arePendingOpsRunning = sendReviewBackToReviewerLoading || completingServiceCase;
  const areMissingRequiredFields = !returnRequestTo || !returnReviewReasons.length || !correctionDetails;

  const handleSendReviewBackToReviewer = async (): Promise<void> => {
    const payload: ClinicalReviewChangeRequestPayload = {
      details: correctionDetails,
      returnRequestTo: returnRequestTo,
      reasons: returnReviewReasons,
      healthPlanName: review?.healthPlanName ?? "",
      resolved: false,
      review: { id: review?.id },
    };

    await sendReviewBackToReviewer(payload).then(async () => {
      setIsOpen(false);
      if (!caseId && review) {
        navigateToPS(patientId, navigate, review.serviceRequest?.id);
      } else if (!!review) {
        await completeServiceCase({
          reviewOutcome: getReviewOutcome(review?.reviewType),
          dateCompleted: new Date().toISOString(),
          reviewId: review.id,
        }).catch(() => {
          navigateBackToQM(caseId);
        });
      }
    });
  };

  if (sendReviewBackToReviewerError) {
    enqueueSnackbar(`Failed to return service request back to the Clinical Reviewer`, { variant: "error" });
  }

  return (
    <Modal data-testid="return-request-details-modal" open={isOpen} onClose={() => setIsOpen(false)}>
      <Grid container spacing={2} component="div">
        <Row centered={true}>
          <H2>Return request details</H2>
        </Row>
        <Row centered={true}>
          <Subtitle2 className={classes.modalDescription}>
            Fill out the details below to re-route this request to a reviewer for corrections
          </Subtitle2>
        </Row>
        <Row>
          <SingleSelectDropdown<ReturnRequestToOption>
            fullWidth
            label="Return request to"
            data-testid="return-to-return-sr-ssd"
            options={returnRequestToOptions}
            value={returnRequestTo}
            onChange={setReturnRequestTo}
            maxMenuHeight={200}
            menuWidth={470}
          />
        </Row>
        <Row>
          <MultiSelectDropdown<ReturnToReviewReasonOption>
            label="Reason"
            data-testid="reason-return-sr-msd"
            options={reasonOptions}
            selectedValues={returnReviewReasons}
            onChange={setReturnReviewReasons}
          />
        </Row>
        <Row>
          <RichTextEditor
            label="Additional details for correction"
            data-testid="additional-details-return-sr-rte"
            htmlValue={correctionDetails}
            setHtmlValue={setCorrectionDetails}
            readonly={false}
            showTextAlignButtons
          />
        </Row>
        <Row centered={true}>
          <PrimaryButton
            data-testid="return-to-review-btn"
            loading={arePendingOpsRunning}
            disabled={arePendingOpsRunning || areMissingRequiredFields}
            onClick={() => handleSendReviewBackToReviewer()}
          >
            {createAndReturnToOriginLbl}
          </PrimaryButton>
        </Row>
      </Grid>
    </Modal>
  );
}

type ReviewTypes = "MdReview" | "NurseReview" | "PeerToPeerReview" | null;

function validateUnsupportedReviewTypes(reviewType: ReviewTypes): void {
  if (reviewType === null) {
    throw new Error("The review cannot be null");
  }
}

function getReviewOutcome(reviewType: ReviewTypes): ReviewOutcome {
  validateUnsupportedReviewTypes(reviewType);

  return reviewType === "MdReview" ? "PENDING_MD_REVIEW" : "PENDING_RN_REVIEW";
}

function getReturnRequestTo(reviewType: ReviewTypes): ReturnRequestTo {
  validateUnsupportedReviewTypes(reviewType);

  return reviewType === "MdReview" ? "MD_REVIEW" : "NURSE_REVIEW";
}

const Row = (props: ComponentProps<typeof Grid> & { centered?: boolean }) => {
  const { centered, ...gridProps } = props;
  return <Grid style={{ textAlign: centered ? "center" : "left" }} item xs={12} {...gridProps} />;
};

const useStyles = makeStyles((theme) => ({
  modalDescription: {
    color: theme.palette.text.tertiary,
    fontFamily: "Gilroy-SemiBold",
    fontWeight: 400,
    lineHeight: 16 / 13,
    letterSpacing: "0.15px",
    margin: 0,
    paddingBottom: theme.spacing(2),
  },
}));
