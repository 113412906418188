import React, { ReactNode } from "react";
import BlankDashboard from "../images/BlankDashboard";
import { H1, H3 } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";

interface Props {
  heading?: ReactNode;
  message?: ReactNode;
}

export default function NoResults({
  heading = "No services yet",
  message = "Adjust your filters or add an auth request.",
}: Props) {
  return (
    <NoResultsContainer>
      <div>
        <BlankDashboard />
      </div>
      <div>
        <HeroHeading>{heading}</HeroHeading>
        <HeroText>{message}</HeroText>
      </div>
    </NoResultsContainer>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const NoResultsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  border: "1px solid #E5E5E5",
  boxSizing: "border-box",
  borderRadius: 5,
  alignItems: "center",
  justifyContent: "center",
  "& div": {
    margin: theme.spacing(3),
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const HeroHeading = styled(H1)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const HeroText = styled(H3)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
