import { useCallback } from "react";
import { Grid } from "@material-ui/core";
import GetApp from "@material-ui/icons/GetApp";
import Print from "@material-ui/icons/Print";
import { PrimaryButton } from "@coherehealth/common";
import {
  downloadSingleNotification,
  printSingleNotification,
} from "components/NotificationViewer/util/fileManagementUtil";
import { NotificationViewerItem } from "components/NotificationViewer/util/notificationUtil";

interface Props {
  selectedNotifications: NotificationViewerItem[];
  closeDialogFunction?: () => void;
}

export default function SingleAttachmentFileActions({ selectedNotifications, closeDialogFunction }: Props) {
  const notification = selectedNotifications[0];

  const downloadAttachment = useCallback(() => {
    downloadSingleNotification(notification);
    closeDialogFunction?.();
  }, [closeDialogFunction, notification]);

  const printAttachment = useCallback(() => {
    printSingleNotification(notification);
  }, [notification]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12}>
        <PrimaryButton fullWidth onClick={printAttachment} startIcon={<Print />} disabled={!notification}>
          Print
        </PrimaryButton>
      </Grid>
      <Grid item xs={12}>
        <PrimaryButton fullWidth onClick={downloadAttachment} startIcon={<GetApp />} disabled={!notification}>
          Download
        </PrimaryButton>
      </Grid>
    </Grid>
  );
}
