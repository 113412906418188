import { SVGProps } from "react";

export default function SummaryCardMedicalIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_3426_44504)">
        <path
          d="M16 32.3125C24.733 32.3125 31.8125 25.233 31.8125 16.5C31.8125 7.767 24.733 0.6875 16 0.6875C7.267 0.6875 0.1875 7.767 0.1875 16.5C0.1875 25.233 7.267 32.3125 16 32.3125Z"
          fill="black"
          opacity="0.04"
        />
        <path
          d="M22.7125 22.7852V12.3727C22.7125 10.8227 22.2062 10.5477 21.5812 10.5477C20.9562 10.5477 20.45 11.0539 20.45 11.6789V17.8227C20.45 19.8102 18.8313 21.4289 16.8438 21.4289H14.725C12.8 21.4289 11.225 19.9164 11.125 18.0102H12.075C12.175 19.3852 13.325 20.4789 14.725 20.4789H16.8438C18.3063 20.4789 19.5 19.2852 19.5 17.8227V11.6789C19.5 10.5289 20.4312 9.59766 21.5812 9.59766C22.7312 9.59766 23.6625 10.2977 23.6625 12.3727V22.7852H22.7125Z"
          fill="#56657F"
          stroke="#56657F"
          strokeWidth="0.5"
        />
        <path
          d="M9.49414 17.5992V16.3242H13.6941V17.5992C13.6941 18.0742 13.3129 18.4555 12.8379 18.4555H10.3504C9.87539 18.4555 9.49414 18.0742 9.49414 17.5992Z"
          fill="#F83275"
        />
        <path
          d="M23.1867 25.9594C24.4052 25.9594 25.393 24.9716 25.393 23.7531C25.393 22.5346 24.4052 21.5469 23.1867 21.5469C21.9682 21.5469 20.9805 22.5346 20.9805 23.7531C20.9805 24.9716 21.9682 25.9594 23.1867 25.9594Z"
          fill="#00A9D1"
          stroke="#00A9D1"
          strokeWidth="0.5"
        />
        <path
          d="M23.1883 24.7359C23.7302 24.7359 24.1695 24.2966 24.1695 23.7547C24.1695 23.2128 23.7302 22.7734 23.1883 22.7734C22.6464 22.7734 22.207 23.2128 22.207 23.7547C22.207 24.2966 22.6464 24.7359 23.1883 24.7359Z"
          fill="#56657F"
        />
        <path
          d="M16.3695 12.1675V8.42999V8.42374C16.3695 8.41749 16.3695 8.41124 16.3695 8.41124C16.3695 7.84249 15.4445 7.36749 14.207 7.24874C13.8883 7.21749 13.6133 7.46749 13.6133 7.78624V9.02999C13.6133 9.34874 13.8883 9.59874 14.207 9.56749C14.6633 9.52374 15.0758 9.42999 15.4133 9.30499V12.1675C15.4133 12.305 15.407 12.4487 15.3883 12.5862H16.3383C16.357 12.4487 16.3695 12.3112 16.3695 12.1675Z"
          fill="#56657F"
          stroke="#56657F"
          strokeWidth="0.5"
        />
        <path
          d="M7.76875 12.1675V9.30499C8.10625 9.42999 8.525 9.52374 8.975 9.56749C9.29375 9.59874 9.56875 9.34874 9.56875 9.02999V7.78624C9.56875 7.46749 9.29375 7.21749 8.975 7.24874C7.83125 7.35499 6.95 7.77374 6.83125 8.28624C6.81875 8.31124 6.8125 8.32999 6.8125 8.36124V12.1737C6.8125 12.3112 6.81875 12.455 6.83125 12.5925H7.78125C7.775 12.4487 7.76875 12.3112 7.76875 12.1675Z"
          fill="#56657F"
          stroke="#56657F"
          strokeWidth="0.5"
        />
        <path
          d="M15.3938 12.5859C15.1875 14.5047 13.5625 15.9922 11.5938 15.9922C9.625 15.9922 8 14.4984 7.79375 12.5859H6.84375C7.05625 15.0297 9.1 16.9422 11.6 16.9422C14.0938 16.9422 16.1438 15.0234 16.3563 12.5859H15.3938Z"
          fill="#56657F"
          stroke="#56657F"
          strokeWidth="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_3426_44504">
          <rect width="32" height="32" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
