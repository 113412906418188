import React, { useState } from "react";
import { Card } from "../Card";
import { Checkbox } from "../Checkbox";
import { Caption } from "../Typography";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import { HeaderProps } from "./DefaultHeader";
import TableCell from "./TableCell";

interface IProps<T> {
  allChecked: boolean;
  onCheckAll: (checked: boolean) => void;
  onDeleteAll: () => void;
  tableColumns: HeaderProps<T>["tableColumns"];
  kebabNeeded?: boolean;
}

export default function SecondaryHeader<T>({
  allChecked,
  onCheckAll,
  onDeleteAll,
  tableColumns,
  kebabNeeded,
}: IProps<T>) {
  const [anchorKebab, setAnchorKebab] = useState(null);
  return (
    <TableHeaderCard>
      {tableColumns.map((column, index) => (
        <TableCell width={column.width} themedPaddingRight={column.themedPaddingRight} key={index}>
          {column.name === "Selected" ? (
            <Checkbox checked={allChecked} onChange={() => onCheckAll(!allChecked)} />
          ) : (
            <Caption>{column.name}</Caption>
          )}
        </TableCell>
      ))}
      {kebabNeeded ? (
        <IconButtonTableCell onClick={(event: any) => setAnchorKebab(event.currentTarget)}>
          <MoreVertIcon />
        </IconButtonTableCell>
      ) : (
        <></>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorKebab}
        keepMounted
        open={Boolean(anchorKebab)}
        onClose={() => setAnchorKebab(null)}
      >
        <MenuItem onClick={() => onDeleteAll()}>Delete</MenuItem>
      </Menu>
    </TableHeaderCard>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const TableHeaderCard = styled(Card)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: "inherit",
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2, 3, 2, 3),
  borderWidth: 0,
  borderBottomWidth: 1,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const IconButtonTableCell = styled(IconButton)(({ theme }) => ({
  alignItems: "center",
}));
