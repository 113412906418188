import React, { useCallback, useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { DesktopDatePicker } from "@material-ui/pickers";
import { TextField, TextFieldProps } from "../TextField";
import { Box } from "@material-ui/core";

export const MAX_DATE = new Date(2050, 0, 1);

// The TextField already has styles to display when a startAdornment is present,
// which the SingleSelect also relies on to display selection chips.
// But in this case we want the label to line up with the input field text,
// so override the styles just in this component.
const useStylesTextField = makeStyles((theme: Theme) => ({
  root: {
    width: ({ width }: { width?: number }) => width,
    "& > .MuiInputBase-root": {
      paddingLeft: 5,
      "& > .MuiInputAdornment-root": {
        marginTop: 1, // manual override to middle-align the start icon vertically
        "& > .MuiIconButton-root": {
          marginLeft: 0,
          color: theme.palette.primary.main,
          "&.Mui-disabled": {
            color: theme.palette.text.disabled,
          },
        },
      },
    },
    "& > .MuiInputLabel-root": {
      marginLeft: 51, // manual tweak to line up with the input field
    },
  },
}));

export interface DateSelectProps {
  /** displays this text if the value is null */
  emptyLabel?: string;

  /** string format for the date to appear */
  format?: string;

  /** label to display in or above the date selected */
  label?: string;

  /** do not allow selection of dates before this */
  minDate?: any;

  /** display this message if date is before min date */
  minDateMessage?: string;

  /** do not allow selection of dates after this */
  maxDate?: any;

  /** display this message if date is after max date */
  maxDateMessage?: string;

  /** callback when the date changes */
  onDateChange: (arg0: any) => void;

  /** the date value */
  value: Date | null;

  disabled?: boolean;

  error?: boolean;

  warning?: boolean;

  helperText?: React.ReactNode;

  /** props to pass to the input */
  inputProps?: any;

  TextFieldProps?: Partial<TextFieldProps>;

  disablePortal?: boolean;

  attemptedSubmit?: boolean;

  showInternalErrorsOnBlur?: boolean;

  allowedInternalErrors?: "all" | string[];

  onBlur?: () => void;

  isContinuation?: boolean;

  testId?: string;
}

/**
 * Style overrides for the DateSelect are in common/src/themes/common.ts
 * It seems that the material-ui pickers library we're using doesn't accept a classes prop,
 * so we have to override the necessary classes at the theme level
 */
const DateSelect = (props: DateSelectProps) => {
  const {
    emptyLabel,
    format = "MM/dd/yyyy",
    label,
    minDate,
    // minDate message should always be "Invalid date"
    minDateMessage = "Invalid date",
    maxDate = MAX_DATE,
    onDateChange,
    value,
    disabled,
    error,
    warning,
    helperText,
    inputProps,
    TextFieldProps,
    disablePortal,
    attemptedSubmit,
    showInternalErrorsOnBlur = false,
    allowedInternalErrors = "all",
    maxDateMessage = "Date too far in the future",
    onBlur,
    isContinuation,
    testId,
  } = props;

  const handleChange = (date: any) => {
    onDateChange(date);
    setBlurred(false);
  };

  const [pickerOpened, setPickerOpened] = useState(false);
  const textFieldRef = useRef<HTMLElement>();
  const [blurred, setBlurred] = useState(true);
  const [internalErrorText, setInternalErrorText] = useState<string | null>(null);
  const [internalErrorReason, setInternalErrorReason] = useState<string>("");

  const setTextFieldRef = useCallback(
    (elem: HTMLElement | null) => {
      if (elem) {
        textFieldRef.current = elem;
      }
    },
    [textFieldRef]
  );

  const textFieldClasses = useStylesTextField({ width: inputProps?.width });

  let helperTextDisplay = helperText;
  if (internalErrorText) {
    helperTextDisplay = (
      <>
        <Box component="span" display="block" mb={1}>
          {internalErrorText}
        </Box>
        <span>{helperText}</span>
      </>
    );
  }
  const handleBlur = () => {
    setBlurred(true);
  };

  const handleFocus = () => {
    setBlurred(false);
  };

  const errorReasonAllowed = (internalErrorReason: string) =>
    allowedInternalErrors === "all" || allowedInternalErrors.includes(internalErrorReason);

  const showError = (internalError?: boolean) => {
    if (showInternalErrorsOnBlur && internalError && blurred && allowedInternalErrors === "all") {
      return true;
    } else if (showInternalErrorsOnBlur && internalError && blurred && errorReasonAllowed(internalErrorReason)) {
      // Only for patient stay date range
      return true;
    } else if (error && attemptedSubmit) {
      return true;
    } else if (internalError && !showInternalErrorsOnBlur) {
      // base condition
      return true;
    }

    return false;
  };

  return (
    <DesktopDatePicker
      reduceAnimations
      onChange={handleChange}
      value={value}
      clearable
      inputFormat={format}
      label={label}
      maxDate={maxDate}
      minDate={minDate}
      onOpen={() => setPickerOpened(true)}
      onClose={() => setPickerOpened(false)}
      onError={(reason) => {
        let errorText: string | null = null;
        if (reason === "minDate" && errorReasonAllowed("minDate")) {
          errorText = minDateMessage;
        } else if (reason === "maxDate" && errorReasonAllowed("maxDate")) {
          errorText = !isContinuation ? maxDateMessage : "";
        } else if (reason === "invalidDate" && errorReasonAllowed("invalidDate")) {
          errorText = "Invalid date";
        } else if (errorReasonAllowed(reason || "")) {
          errorText = reason;
        }

        setInternalErrorText(errorText);
        setInternalErrorReason(reason ?? "");
      }}
      disabled={disabled}
      PopperProps={{
        anchorEl: textFieldRef.current,
        placement: "bottom-start",
        disablePortal: disablePortal,
        onBlur: () => onBlur?.(),
      }}
      renderInput={({ error: internalError, inputProps: internalInputProps, ...props }) => {
        return (
          <TextField
            {...TextFieldProps}
            {...props}
            ref={setTextFieldRef}
            forceFocus={pickerOpened}
            inputProps={{ ...internalInputProps, ...inputProps, ...(emptyLabel ? { placeholder: emptyLabel } : {}) }}
            classes={textFieldClasses}
            error={showError(internalError)}
            warning={warning}
            helperText={
              !attemptedSubmit
                ? internalError || error
                  ? blurred && helperTextDisplay
                  : helperTextDisplay
                : helperTextDisplay
            }
            onBlur={handleBlur}
            data-public
            onFocus={handleFocus}
            data-testid={testId}
          />
        );
      }}
      InputAdornmentProps={{ position: "start" }}
    />
  );
};

export default DateSelect;
