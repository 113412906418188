import { useGetPatient } from "@coherehealth/core-platform-api";
import { useEffect } from "react";
import { useHealthPlanDisplayNameContext } from "util/context/HealthPlanDisplayNameContext";
import { usePatientContext } from "util/context/PatientContext";
import { getPatientHealthPlanName } from "util/patientUtils";

export default function useSetCobrandingHealthPlanName(patientId?: string) {
  const { setPatientData } = usePatientContext();
  const { setHealthPlanDisplayNameContext } = useHealthPlanDisplayNameContext();

  const { data: patientData } = useGetPatient({
    id: patientId || "",
  });
  const healthPlan = getPatientHealthPlanName(patientData || undefined);
  useEffect(() => {
    //set patient data to context
    if (patientData) {
      setPatientData(patientData);
    }
    //set setHealthPlanDisplayNameContext after patient data has been set to ensure only single request is made
    if (healthPlan) {
      setHealthPlanDisplayNameContext(healthPlan);
    }
  }, [patientData, healthPlan, setPatientData, setHealthPlanDisplayNameContext]);
}
