import { GEISINGER_NAME } from "@coherehealth/common";
import { fieldIsValid } from "common/FormConfigUtils";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { FormConfiguration } from "components/ServiceRequest/ConfigurableServiceRequestForm";
import { ContinuationConfiguration } from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";

export const validatePerformingProviderAddress = (
  formConfiguration: FormConfiguration | ContinuationConfiguration,
  formContent: ServiceRequestFormContent,
  healthPlan: string,
  hasPerformingProviderAddress: boolean
) => {
  if (validatePerformingProviderSharedConditions(formConfiguration, formContent)) {
    return true;
  }
  if (formConfiguration.performingProviderAddress.fieldSpec === "OMIT") {
    return true;
  }
  if (formContent.performingProvider && healthPlan === GEISINGER_NAME) {
    if (formContent.performingProviderSelectedAddress?.address) {
      return fieldIsValid(formConfiguration.performingProviderAddress, hasPerformingProviderAddress);
    }
    return false;
  }
  return fieldIsValid(formConfiguration.performingProviderAddress, hasPerformingProviderAddress);
};

export const validatePerformingProviderTin = (
  formConfiguration: FormConfiguration | ContinuationConfiguration,
  formContent: ServiceRequestFormContent,
  performingProviderTinLength: number | undefined,
  hasPerformingProviderTin: boolean,
  userTinValidation?: boolean
) => {
  if (validatePerformingProviderSharedConditions(formConfiguration, formContent)) {
    return true;
  }
  if (!performingProviderTinLength) {
    if (userTinValidation) {
      return false;
    } else {
      return true;
    }
  }
  if (!!formContent.performingProvider && performingProviderTinLength > 0) {
    return !!formContent.performingProviderSelectedTin;
  }
  return fieldIsValid(formConfiguration.performingProviderTIN, hasPerformingProviderTin);
};

export const validatePerformingProviderNPI = (
  formConfiguration: FormConfiguration | ContinuationConfiguration,
  formContent: ServiceRequestFormContent,
  hasPerformingProviderNpi: boolean
) => {
  if (validatePerformingProviderSharedConditions(formConfiguration, formContent)) {
    return true;
  }
  return fieldIsValid(formConfiguration?.performingProviderNPI, hasPerformingProviderNpi);
};

export const validatePerformingProviderSharedConditions = (
  formConfiguration: FormConfiguration | ContinuationConfiguration,
  formContent: ServiceRequestFormContent
) => {
  if (formConfiguration.performingProvider.fieldSpec === "OMIT") {
    return true;
  }
  if (formConfiguration.performingProvider.fieldSpec === "OPTIONAL" && !formContent.selectedPerformingProvider) {
    return true;
  }
  return false;
};
