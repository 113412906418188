import React from "react";

import QuestionHeader, { Props as QuestionHeaderProps } from "./QuestionHeader";
import QuestionCard, { Props as QuestionCardProps } from "./QuestionCard";
import Grid from "@material-ui/core/Grid";
import { useStableUniqueId } from "../../hooks";
import { colorsLight } from "../../themes";
import { Caption } from "../Typography";
import { DateSelect } from "../DateSelect";

interface Props extends QuestionHeaderProps, QuestionCardProps {
  /** The selected answers for this question */
  dateSelected: Date | null;
  /** A function which updates the selected answer when a new option is clicked */
  setDateSelected: (arg0: Date) => any;
}

export default function DateSelectQuestion({
  dateSelected,
  setDateSelected,
  relevantLinks,
  required,
  questionText,
  ordinal,
  subtext,
  hideQuestionAnswerDivider,
  ...questionCardProps
}: Props) {
  const labelId = useStableUniqueId("DateSelectQuestion");

  return (
    <QuestionCard {...questionCardProps}>
      <label id={labelId}>
        <QuestionHeader
          hideQuestionAnswerDivider={hideQuestionAnswerDivider}
          relevantLinks={relevantLinks}
          required={required}
          questionText={questionText}
          ordinal={ordinal}
        />
      </label>
      {subtext && (
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "left", alignItems: "center", paddingBottom: "20px" }}
        >
          <Caption style={{ color: colorsLight.font.secondary }}>{subtext}</Caption>
        </Grid>
      )}
      <DateSelect
        inputProps={{ "aria-labelledby": labelId, required }}
        onDateChange={setDateSelected}
        value={dateSelected}
        emptyLabel="Choose Date"
      />
    </QuestionCard>
  );
}
