import { useState, useCallback } from "react";
import { PrimaryButton } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Grid, CircularProgress, styled } from "@material-ui/core";
import { Tooltip } from "@coherehealth/common";
import GetApp from "@material-ui/icons/GetApp";
import Print from "@material-ui/icons/Print";
import { downloadSingleNotification, printSingleNotification } from "./util/fileManagementUtil";
import { NotificationViewerItem } from "./util/notificationUtil";

interface NotificationFileActionsProps {
  selectedNotifications: NotificationViewerItem[];
  closeDialogFunction?: () => void;
  fetchMissingFileUrls?: (notificationsToCheck: NotificationViewerItem[]) => Promise<NotificationViewerItem[]>;
}

export default function NotificationFileActions({
  selectedNotifications,
  fetchMissingFileUrls,
}: NotificationFileActionsProps) {
  const [isFetchingData, setIsFetchingData] = useState(false);

  const someNotificationsSelected = selectedNotifications.length > 0;
  const multipleNotificationsSelected = selectedNotifications.length > 1;
  // Chrome limits the number of downloads at once to 10
  const tooManySelectedForDownload = selectedNotifications.length > 10;

  const areFileUrlsMissing = selectedNotifications?.some(
    (notification) => !notification.fileUrl && notification.downloadPath
  );
  const shouldDisableDownloadButton =
    !someNotificationsSelected || (someNotificationsSelected && isFetchingData) || tooManySelectedForDownload;
  const shouldDisablePrintButton = shouldDisableDownloadButton || multipleNotificationsSelected;

  // Fetch any missing files, and filter to the selected ones.
  const fetchUpdatedNotifications = useCallback(async () => {
    let updatedSelectedNotifications: NotificationViewerItem[] = selectedNotifications || [];
    if (areFileUrlsMissing) {
      setIsFetchingData(true);
      updatedSelectedNotifications = (await fetchMissingFileUrls?.(selectedNotifications)) || [];
      setIsFetchingData(false);
    }
    return updatedSelectedNotifications;
  }, [areFileUrlsMissing, fetchMissingFileUrls, selectedNotifications]);

  const downloadNotifications = useCallback(async () => {
    const updatedSelectedNotifications = await fetchUpdatedNotifications();
    updatedSelectedNotifications.forEach((notification) => {
      downloadSingleNotification(notification);
    });
  }, [fetchUpdatedNotifications]);

  const printNotifications = useCallback(async () => {
    const updatedSelectedNotifications = await fetchUpdatedNotifications();

    if (updatedSelectedNotifications.length === 1) {
      updatedSelectedNotifications.forEach((notification) => {
        printSingleNotification(notification);
      });
    } else if (selectedNotifications.length > 1) {
      // Todo: Concatenate all PDFs into one, and print them
      alert(
        `PLACEHOLDER - Printing documents: \n${updatedSelectedNotifications
          ?.map((a) => `${a.fileName} (${a.data.id})`)
          .join("\n")}`
      );
    }
  }, [fetchUpdatedNotifications, selectedNotifications]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12}>
        {shouldDisablePrintButton ? (
          <Tooltip
            title={
              multipleNotificationsSelected
                ? "Please print one at a time"
                : isFetchingData
                ? "Loading notifications"
                : "No notifications selected"
            }
          >
            <ButtonTooltipContainer>
              <PrimaryButton
                disabled
                fullWidth
                startIcon={isFetchingData ? <CircularProgress size={16} color="inherit" /> : <Print />}
              >
                Print selected
              </PrimaryButton>
            </ButtonTooltipContainer>
          </Tooltip>
        ) : (
          <PrimaryButton fullWidth onClick={printNotifications} startIcon={<Print />}>
            Print selected
          </PrimaryButton>
        )}
      </Grid>
      <Grid item xs={12}>
        {shouldDisableDownloadButton ? (
          <Tooltip
            title={
              tooManySelectedForDownload
                ? "Please select 10 or fewer"
                : isFetchingData
                ? "Loading notifications"
                : "No notifications selected"
            }
          >
            <ButtonTooltipContainer>
              <PrimaryButton
                disabled
                fullWidth
                startIcon={isFetchingData ? <CircularProgress size={16} color="inherit" /> : <GetApp />}
              >
                Download selected
              </PrimaryButton>
            </ButtonTooltipContainer>
          </Tooltip>
        ) : (
          <PrimaryButton fullWidth onClick={downloadNotifications} startIcon={<GetApp />}>
            Download selected
          </PrimaryButton>
        )}
      </Grid>
    </Grid>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ButtonTooltipContainer = styled("span")({ display: "block" });
