import { SVGProps } from "react";

export default function AutoFillIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="40" height="40" rx="20" fill="black" opacity="0.04" />
      <path
        d="M28.6028 19.0646C30.7839 16.8339 30.7839 13.2376 28.6028 11.007C26.4218 8.77632 22.9053 8.77632 20.7243 11.007L15.917 15.9235L23.7956 23.9811L28.6028 19.0646Z"
        fill="#0291B2"
      />
      <path
        d="M15.9167 15.8965L11.1094 20.813C8.92836 23.0436 8.92836 26.64 11.1094 28.8706C13.2905 31.1013 16.8069 31.1013 18.988 28.8706L23.7953 23.9541L15.9167 15.8965Z"
        fill="#89D3DD"
      />
    </svg>
  );
}
