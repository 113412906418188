import { Container, useTheme } from "@material-ui/core";
import Footer from "components/AuthBuilder/Footer";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { queueMgmtBaseUrl, useMuiContainerStyles } from "@coherehealth/common";
import { ReferralSubmissionModal } from "./ReferralSubmissionModal";
import {
  ReferralRequestResponse,
  Requestor,
  useCreateReferralRequestAttachment,
  useSubmitReferralRequest,
} from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import { SubmissionStatus } from "components/ServiceRequest/Submission/SubmissionModal";
import { navigateToReferralsPatientSummary } from "../common";
import { useNavigate } from "react-router";
import { useCompleteServiceCase } from "@coherehealth/qm-api";
import config from "api/config";
import { FaxAttachmentContext } from "components/DocumentViewer/FaxAttachment/FaxAttachmentContext";
import { ReferralsNavigationContext } from "components/DocumentViewer/FaxAttachment/FaxReferralsBuilderPage/ReferralsNavigationContext";
import { error as logError } from "logger";
import { activityContextFromReferralRequest, useTrackUserInteraction } from "util/userActivityTracker";

interface ReferralReviewFooterProps {
  setCancelModalOpen: (open: boolean) => void;
  setFooterHeight: Dispatch<SetStateAction<number>>;
  referralRequest: ReferralRequestResponse;
  requestor?: Requestor;
  attemptedSubmitServices: boolean;
  setAttemptedReferralRequestFormsSubmit: (b: boolean) => void;
}

export default function ReferralReviewFooter({
  referralRequest,
  requestor,
  setFooterHeight,
  attemptedSubmitServices,
  setAttemptedReferralRequestFormsSubmit,
  setCancelModalOpen,
}: ReferralReviewFooterProps) {
  const [submitError, setSubmitError] = useState(false);
  const [faxReferralSubmitting, setFaxReferralSubmitting] = useState(false);
  const [showSubmissionModal, setShowSubmissionModal] = useState(false);
  const containerClasses = useMuiContainerStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { isFaxReferralsFlow } = useContext(ReferralsNavigationContext);
  const { caseId: qmCaseId, faxDbId: faxId } = useContext(FaxAttachmentContext);
  const trackUserActivityInteraction = useTrackUserInteraction();

  let submissionStatus: SubmissionStatus;
  if (submitError) {
    submissionStatus = SubmissionStatus.Failure;
  } else if (attemptedSubmitServices) {
    submissionStatus = SubmissionStatus.Loading;
  } else {
    submissionStatus = SubmissionStatus.Success;
  }

  const { mutate: submitReferralRequest, loading: submitReferralRequestLoading } = useSubmitReferralRequest({});
  const { mutate: uploadReferralAttachment } = useCreateReferralRequestAttachment({ id: "" });
  const { mutate: updateServiceCase } = useCompleteServiceCase({
    id: qmCaseId ?? "",
    base: `${config.QM_SERVICE_API_URL}`,
  });

  const referralRequestSubmission = async () => {
    let submissionError = false;
    try {
      const response = await submitReferralRequest({ ...referralRequest, requestor: requestor });

      if (Boolean(isFaxReferralsFlow) && response?.id) {
        if (faxId) {
          setFaxReferralSubmitting(true);
          await trackUserActivityInteraction({
            event: "REFERRAL_FROM_FAX",
            stage: "REFERRAL_CREATION",
            activityContext: {
              ...(response && activityContextFromReferralRequest(response)),
              faxId,
            },
          });

          try {
            const formData = new FormData();
            formData.set("faxId", faxId);
            await uploadReferralAttachment(formData as unknown as void, {
              pathParams: { id: response?.id || "" },
            });

            if (qmCaseId) {
              try {
                await updateServiceCase({
                  outcome: "NEW_REFERRAL",
                  dateCompleted: new Date().toISOString(),
                  description: "New referral created",
                  referralRequest: {
                    referralRequestId: response?.id,
                    status: response?.referralStatus,
                    cohereId: response?.cohereId,
                  },
                });
                window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${qmCaseId}`);
              } catch (error) {
                logError(new Error(`Unable to update the case ${qmCaseId}: ${error}`));
                enqueueSnackbar("Failed to update case", { variant: "error" });
                throw error;
              }
            }
          } catch (faxAttachError) {
            logError(new Error(`Unable to auto attach fax to new referral request: ${faxAttachError}`));
            enqueueSnackbar("Failed to auto attach fax to new referral", { variant: "error" });
            throw faxAttachError;
          }
        }
      }
    } catch (e) {
      submissionError = true;
    } finally {
      if (Boolean(isFaxReferralsFlow) && faxReferralSubmitting) {
        setFaxReferralSubmitting(false);
      }
    }
    setSubmitError(submissionError);
  };

  /*  followed this pattern from auth submission; this function will be used to deal with a "save and exit" flow when implemented in the future */
  const onSubmitReferral = async () => {
    await referralRequestSubmission();

    setAttemptedReferralRequestFormsSubmit(true);

    if (!Boolean(isFaxReferralsFlow)) {
      setShowSubmissionModal(true);
    }
    setAttemptedReferralRequestFormsSubmit(false);
  };

  const navigateUponCompletion = () => {
    navigateToReferralsPatientSummary(referralRequest.patient?.id || "", navigate, referralRequest.id);
  };

  useEffect(() => {
    if (submitError) {
      enqueueSnackbar("Failed to submit service requests", { variant: "error" });
    }
  }, [submitError, enqueueSnackbar]);

  if (Boolean(isFaxReferralsFlow)) {
    //Container component messes up alignment of the fax intake workflow buttons
    return (
      <>
        <Footer
          onPrimaryButtonClick={() => {
            onSubmitReferral();
          }}
          primaryButtonText="Submit"
          primaryButtonDisabled={submitReferralRequestLoading || faxReferralSubmitting}
          primaryButtonLoading={submitReferralRequestLoading || faxReferralSubmitting}
          showSaveAndExitButton={false}
          setFooterHeight={setFooterHeight}
          showSecondaryButton={true}
          secondaryButtonText="Cancel"
          onSecondaryButtonClick={() => {
            setCancelModalOpen(true);
          }}
        />
      </>
    );
  }

  return (
    <Container classes={containerClasses} maxWidth="lg" style={{ marginRight: theme.spacing(-3) }}>
      <Footer
        onPrimaryButtonClick={onSubmitReferral}
        primaryButtonText="Submit"
        primaryButtonDisabled={submitReferralRequestLoading}
        showSaveAndExitButton={false}
        setFooterHeight={setFooterHeight}
        showSecondaryButton={true}
        secondaryButtonText="Cancel"
        onSecondaryButtonClick={() => {
          setCancelModalOpen(true);
        }}
      />
      <ReferralSubmissionModal
        open={showSubmissionModal}
        handleClose={() => setShowSubmissionModal(false)}
        onSuccessAction={navigateUponCompletion}
        onFailureAction={onSubmitReferral}
        status={submissionStatus}
      />
    </Container>
  );
}
