import TextField from "@material-ui/core/TextField";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, Theme, styled, useTheme } from "@material-ui/core/styles";
import { useCallback, useEffect, useState } from "react";
import { AutoFillIcon } from "../images";
import { displayInputFormatter, DefaultValueFormatter, valueFormatter } from "../../util";

export interface FaxInputProps {
  id?: string;
  label?: string;
  value?: string;
  setValue?: (number: string | undefined) => void;
  defaultValue?: string;
  validateNumbersOnly?: boolean;
  error?: boolean;
}

export default function FaxInput({
  id,
  label,
  value,
  setValue,
  defaultValue,
  validateNumbersOnly,
  error,
}: FaxInputProps) {
  const [autoFilledStatus, setAutoFilledStatus] = useState(defaultValue ? true : false);
  const [displayValue, setDisplayValue] = useState(
    defaultValue ? DefaultValueFormatter(defaultValue) : value ? DefaultValueFormatter(value) : ""
  );
  const FaxInputClasses = useStylesFaxInput();
  const [focused, setFocused] = useState(false);
  const theme = useTheme();
  useEffect(() => {
    if (defaultValue) {
      if (setValue) {
        setValue(defaultValue);
      }
    }
  }, [defaultValue, setValue]);
  const handleChange = useCallback(
    (input: string) => {
      //handling edits when input is initially auto-filled
      if (autoFilledStatus && defaultValue) {
        //if input is a substring of defaultValue, user has hit the delete button, want to blow away input entirely
        if (defaultValue.includes(valueFormatter(input))) {
          input = "";
          //else user is supplying a number, in which case we want to blow away default value but capture that number input
        } else {
          input = input.substring(input.length - 1);
        }
        setAutoFilledStatus(false);
      }
      if (validateNumbersOnly) {
        input = input.substring(0, input.length - 1) + input.substring(input.length - 1).replace(/[^0-9]/g, "");
      }
      input = displayInputFormatter(input);
      setValue && setValue(valueFormatter(input));
      setDisplayValue(input);
    },
    [autoFilledStatus, defaultValue, setValue, validateNumbersOnly]
  );
  return (
    <TextField
      id={id}
      label={label}
      value={displayValue}
      onChange={(e) => {
        handleChange(e.target.value);
      }}
      classes={FaxInputClasses}
      InputProps={{
        endAdornment: autoFilledStatus && !error && (
          <StyledEndAdornmentContainer>
            <AutoFillIcon />
          </StyledEndAdornmentContainer>
        ),
        disableUnderline: true,
      }}
      error={error}
      style={{
        borderColor: error ? theme.palette.error.main : autoFilledStatus || focused ? theme.palette.primary.main : "",
      }}
      helperText={error && "Fax number must be 10 digits"}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    />
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledEndAdornmentContainer = styled("div")(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  marginTop: -13,
  marginRight: theme.spacing(2),
}));

const useStylesFaxInput = makeStyles((theme: Theme) => {
  return {
    root: {
      border: 1,
      borderStyle: "solid",
      borderRadius: 4,
      borderColor: theme.palette.divider,
      "&:hover": {
        borderColor: theme.palette.primary.main,
      },
      backgroundColor: theme.palette.background.paper,
      width: "100%",
      minWidth: 170,
      maxWidth: 280,
      height: 56,
      "& .MuiInput-input": {
        marginLeft: 15,
        color: theme.palette.primary,
        marginTop: 5,
        ...theme.typography.body1,
      },
      "& .MuiInputLabel-shrink": {
        transform: "translate(0, 15px) scale(0.75)",
      },
      "& .Mui-error": {
        color: theme.palette.error,
      },
      "& .MuiInputLabel-formControl": {
        marginLeft: 15,
        top: -5,
        ...theme.typography.body1,
      },
      "& .MuiFormLabel-root.Mui-focused": {
        color: theme.palette.text.secondary,
      },
      "& .MuiFormHelperText-root": {
        marginTop: 5,
        color: theme.palette.error,
      },
    },
  };
});
