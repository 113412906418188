import { RecommendChangeRuleAction, RuleBlock, RuleCondition, WithAcceptAction } from "@coherehealth/core-platform-api";
import { TARGET_FIELD_OPTIONS_MAP, TargetFieldLabel } from "./RuleConstants";

export interface DynamicRuleCondition extends RuleCondition {
  dynamicOutcome?: DynamicOutcome;
  block?: DynamicRuleBlock;
}

export type DynamicOutcome = "PASS" | "FAIL" | "INDETERMINATE";

interface DynamicRuleBlock extends RuleBlock {
  dynamicOutcome?: DynamicOutcome;
  conditions?: DynamicRuleCondition[];
  hasIndication?: boolean;
}

// takes a list of TARGET_FIELD_OPTIONS_MAP labels and a RecommendChangeRuleAction and returns whether the action is included in the list of option labels
export const isTargetForActionAmongOptions = (targetOptions: TargetFieldLabel[], action: RecommendChangeRuleAction) => {
  const filteredTargetsForAction = Object.keys(TARGET_FIELD_OPTIONS_MAP).filter((option) =>
    targetOptions.includes(TARGET_FIELD_OPTIONS_MAP[option].label)
  );
  const acceptActionOptions = ["WITHDRAW", "NONE", "ADD_ATTACHMENTS"];
  if (action.onAcceptAction && acceptActionOptions.includes(action.onAcceptAction)) {
    return !!filteredTargetsForAction.filter(
      (option) => TARGET_FIELD_OPTIONS_MAP[option].onAcceptAction === action.onAcceptAction
    ).length;
  } else {
    return !!filteredTargetsForAction.filter(
      (option) =>
        TARGET_FIELD_OPTIONS_MAP[option].onAcceptAction === action.onAcceptAction &&
        TARGET_FIELD_OPTIONS_MAP[option].onAcceptAttribute === action.onAcceptAttribute
    ).length;
  }
};

export const extractOnAcceptValueArray = (action: WithAcceptAction) => {
  const onAcceptValueArray = action?.onAcceptValue?.value;
  return Array.isArray(onAcceptValueArray) ? onAcceptValueArray : [];
};
