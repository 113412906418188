import { useEffect, useCallback, useRef, EffectCallback } from "react";
import isEqual from "lodash/isEqual";

// Approach inspired by
// https://stackoverflow.com/questions/54095994/react-useeffect-comparing-objects
//

function useMemoized(value: any) {
  const ref = useRef();

  if (!isEqual(ref.current, value)) {
    ref.current = value;
  }

  return ref.current;
}

export function useEffectDeepEquality(callback: EffectCallback, dependencies: any[]) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(callback, dependencies.map(useMemoized));
}

export function useCallbackDeepEquality(callback: EffectCallback, dependencies: any[]) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(callback, dependencies.map(useMemoized));
}
