import { RenderSearchProps } from "@react-pdf-viewer/search";
import { AttachmentInfo } from "./AttachmentViewerSidePanel";
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";

interface Props {
  handleAttachmentClick?: (index: number) => void;
  attachmentsInfo?: AttachmentInfo[];
  loadingRemainingAttachments?: boolean;
}

interface IAttachmentsMultiSearchStateHolder {
  searchText: string;
  isSearchTextChanged: boolean;
  showSearchResults: boolean;
}

export interface IMultiSearchJumpArgs {
  activeAttachmentId: string;
  autoFocusInput: boolean;
}

interface IAttachmentsMultiSearchUtilityHolder {
  doSearch: (activeAttachmentId: string) => void;
  doJumpToNextMatch: (args: IMultiSearchJumpArgs) => void;
  doJumpToPreviousMatch: (args: IMultiSearchJumpArgs) => void;
  doChangeSearchValue: (text: string) => void;
  doClearSearchValue: () => void;
  registerAttachmentSearchProps: (attachmentId: string, props: RenderSearchProps) => void;
  registerAttachmentSearchInputRef: (
    attachmentId: string,
    inputRef: MutableRefObject<HTMLInputElement | undefined>
  ) => void;
  setSidePanelScrollOffset: (scrollOffset: number) => void;
  getSidePanelScrollOffset: () => number;
}

interface IAttachmentsMultiSearchStatsHolder {
  totalMatchesNumber: number;
  currentMatchNumber: number;
}

export interface IAttachmentsMultiSearchHolder {
  state: IAttachmentsMultiSearchStateHolder;
  utilities: IAttachmentsMultiSearchUtilityHolder;
  stats: IAttachmentsMultiSearchStatsHolder;
}

type ISearchMatchesByAttachmentIds = { attachmentId: string; sidePanelIndex: number; matches: number }[];
type ICurrentSearchMatch = { attachmentId: string; sidePanelIndex: number; currentMatch: number } | undefined;

function useAttachmentsMultiSearch({
  handleAttachmentClick,
  attachmentsInfo,
  loadingRemainingAttachments,
}: Props): IAttachmentsMultiSearchHolder {
  const [attachmentsMultiSearchStateHolder, setAttachmentsMultiSearchStateHolder] =
    useState<IAttachmentsMultiSearchStateHolder>({
      isSearchTextChanged: false,
      searchText: "",
      showSearchResults: false,
    });

  const [currentSearchMatch, setCurrentSearchMatch] = useState<ICurrentSearchMatch>(undefined);
  const [searchMatchesByAttachmentIds, setSearchMatchesByAttachmentIds] = useState<ISearchMatchesByAttachmentIds>([]);

  const searchPropsByAttachmentIds = useRef<{ [key: string]: RenderSearchProps }>({});
  const textInputRefByAttachmentIds = useRef<{ [key: string]: MutableRefObject<HTMLInputElement | undefined> }>({});
  const hasAlreadySyncedRemainingAttachmentsKeywords = useRef<boolean>(true);
  const sidePanelScrollOffset = useRef<number>(0);

  const sortedSearchMatchesByAttachmentIds = useMemo(
    () => searchMatchesByAttachmentIds.sort((a, b) => a.sidePanelIndex - b.sidePanelIndex),
    [searchMatchesByAttachmentIds]
  );

  const totalMatchesNumber = useMemo(
    () => searchMatchesByAttachmentIds.reduce((prev, curr) => prev + curr.matches, 0),
    [searchMatchesByAttachmentIds]
  );

  const currentMatchNumber = useMemo(() => {
    if (!currentSearchMatch) {
      return 0;
    }
    let acc = currentSearchMatch.currentMatch ?? 1;
    for (const ele of sortedSearchMatchesByAttachmentIds) {
      if (ele.sidePanelIndex < currentSearchMatch.sidePanelIndex) {
        acc += ele.matches;
      }
    }

    return acc;
  }, [sortedSearchMatchesByAttachmentIds, currentSearchMatch]);

  // This function syncs keywords for latest loaded attachments.
  // Syncing keywords cannot to be done synchronously, and the .setKeyword(...) doesn't return a Promise,
  // therefore as a workaround we need to call setKeyword(...) and let it rerender, and only then we'll be sure that keywords
  // are up-to-date across all attachments.
  function resyncKeywordsAfterRemainingAttachmentsLoad() {
    return new Promise((resolve, _) => {
      hasAlreadySyncedRemainingAttachmentsKeywords.current = true;
      Object.keys(searchPropsByAttachmentIds.current).forEach((key) => {
        searchPropsByAttachmentIds.current[key].setKeyword(attachmentsMultiSearchStateHolder.searchText);
      });
      setTimeout(() => {
        resolve(1);
      }, 1);
    });
  }

  useEffect(() => {
    if (loadingRemainingAttachments) {
      hasAlreadySyncedRemainingAttachmentsKeywords.current = false;
    }
  }, [loadingRemainingAttachments]);

  async function doSearch(activeAttachmentId: string) {
    if (loadingRemainingAttachments) {
      return;
    }
    if (!hasAlreadySyncedRemainingAttachmentsKeywords.current) {
      await resyncKeywordsAfterRemainingAttachmentsLoad();
    }

    const searchPromises = Object.keys(searchPropsByAttachmentIds.current).map((key) => {
      return searchPropsByAttachmentIds.current[key].search().then((matches) => {
        return { key, matches };
      });
    });

    const responses = await Promise.all(searchPromises);

    const searchMatchesByAttachmentIds: ISearchMatchesByAttachmentIds = responses.map((response) => {
      const sidePanelIndex = attachmentsInfo?.map((att) => att.attachmentId).indexOf(response.key) ?? 0;
      return {
        attachmentId: response.key,
        sidePanelIndex,
        matches: response.matches.length,
      };
    });

    const activeAttachmentMatches = searchMatchesByAttachmentIds.find((ele) => ele.attachmentId === activeAttachmentId);
    if (!activeAttachmentMatches) {
      // Impossible Case
      return;
    }

    setSearchMatchesByAttachmentIds(searchMatchesByAttachmentIds);
    setAttachmentsMultiSearchStateHolder((prev) => ({
      ...prev,
      showSearchResults: true,
      isSearchTextChanged: false,
    }));

    if (activeAttachmentMatches.matches > 0) {
      // Current active attachment contains matches
      setCurrentSearchMatch({
        attachmentId: activeAttachmentId,
        sidePanelIndex: activeAttachmentMatches.sidePanelIndex,
        currentMatch: 1,
      });
    } else {
      const eligibleSearchMatches = searchMatchesByAttachmentIds
        .sort((a, b) => a.sidePanelIndex - b.sidePanelIndex)
        .filter((ele) => ele.matches > 0);
      if (eligibleSearchMatches.length === 0) {
        // No Matches across all attachments
        setCurrentSearchMatch(undefined);
      } else {
        // Next Match is in another attachment
        setCurrentSearchMatch({
          attachmentId: eligibleSearchMatches[0].attachmentId,
          sidePanelIndex: eligibleSearchMatches[0].sidePanelIndex,
          currentMatch: 1,
        });
        handleAttachmentClick?.(eligibleSearchMatches[0].sidePanelIndex);
        __tryToRetrapFocus(eligibleSearchMatches[0].attachmentId);
      }
    }
  }

  function doJumpToNextMatch({ activeAttachmentId, autoFocusInput }: IMultiSearchJumpArgs) {
    if (totalMatchesNumber === 0 || !currentSearchMatch) {
      return;
    }
    const { attachmentId, sidePanelIndex, currentMatch } = currentSearchMatch;
    const totalMatchesForAttachmentId =
      searchMatchesByAttachmentIds.find((ele) => ele.attachmentId === attachmentId)?.matches ?? 0;

    let computedAttachmentId = attachmentId;
    const computedNextMatch = totalMatchesForAttachmentId > currentMatch ? currentMatch + 1 : 1;

    const manuallyChangedAttachment = attachmentId !== activeAttachmentId;

    let computedSidePanelIndex = sidePanelIndex;

    if (computedNextMatch === 1 || manuallyChangedAttachment) {
      if (computedNextMatch === 1) {
        const eligibleSearchMatchesByAttachmentIds = sortedSearchMatchesByAttachmentIds.filter(
          (ele) => ele.matches > 0
        );

        if (eligibleSearchMatchesByAttachmentIds.length === 1) {
          // This means matches were found on only one attachment
          computedAttachmentId = eligibleSearchMatchesByAttachmentIds[0].attachmentId;
          computedSidePanelIndex = eligibleSearchMatchesByAttachmentIds[0].sidePanelIndex;
        } else {
          const currentMatchSidePanelIndex = eligibleSearchMatchesByAttachmentIds
            .map((ele) => ele.attachmentId)
            .indexOf(attachmentId);
          const indexOfNextMatch = (currentMatchSidePanelIndex + 1) % eligibleSearchMatchesByAttachmentIds.length;
          computedAttachmentId = eligibleSearchMatchesByAttachmentIds[indexOfNextMatch].attachmentId;
          computedSidePanelIndex = eligibleSearchMatchesByAttachmentIds[indexOfNextMatch].sidePanelIndex;
        }
      }

      handleAttachmentClick?.(computedSidePanelIndex);
      if (autoFocusInput) {
        __tryToRetrapFocus(computedAttachmentId);
      }
    }

    setCurrentSearchMatch({
      attachmentId: computedAttachmentId,
      sidePanelIndex: computedSidePanelIndex,
      currentMatch: computedNextMatch,
    });
    searchPropsByAttachmentIds.current[computedAttachmentId].jumpToMatch(computedNextMatch);
  }

  function doJumpToPreviousMatch({ activeAttachmentId, autoFocusInput }: IMultiSearchJumpArgs) {
    if (totalMatchesNumber === 0 || !currentSearchMatch) {
      return;
    }
    const { attachmentId, sidePanelIndex, currentMatch } = currentSearchMatch;

    let computedAttachmentId = attachmentId;
    let computedPrevMatch = currentMatch - 1;

    const manuallyChangedAttachment = attachmentId !== activeAttachmentId;

    let computedSidePanelIndex = sidePanelIndex;

    if (computedPrevMatch === 0 || manuallyChangedAttachment) {
      if (computedPrevMatch === 0) {
        const eligibleSearchMatchesByAttachmentIds = sortedSearchMatchesByAttachmentIds.filter(
          (ele) => ele.matches > 0
        );

        if (eligibleSearchMatchesByAttachmentIds.length === 1) {
          // This means matches were found on only one attachment
          computedAttachmentId = eligibleSearchMatchesByAttachmentIds[0].attachmentId;
          computedSidePanelIndex = eligibleSearchMatchesByAttachmentIds[0].sidePanelIndex;
          computedPrevMatch = eligibleSearchMatchesByAttachmentIds[0].matches;
        } else {
          const currentMatchSidePanelIndex = eligibleSearchMatchesByAttachmentIds
            .map((ele) => ele.attachmentId)
            .indexOf(attachmentId);
          const indexOfPrevMatch =
            currentMatchSidePanelIndex - 1 >= 0
              ? currentMatchSidePanelIndex - 1
              : eligibleSearchMatchesByAttachmentIds.length - 1;
          computedAttachmentId = eligibleSearchMatchesByAttachmentIds[indexOfPrevMatch].attachmentId;
          computedSidePanelIndex = eligibleSearchMatchesByAttachmentIds[indexOfPrevMatch].sidePanelIndex;
          computedPrevMatch = eligibleSearchMatchesByAttachmentIds[indexOfPrevMatch].matches;
        }
      }

      handleAttachmentClick?.(computedSidePanelIndex);
      if (autoFocusInput) {
        __tryToRetrapFocus(computedAttachmentId);
      }
    }

    setCurrentSearchMatch({
      attachmentId: computedAttachmentId,
      sidePanelIndex: computedSidePanelIndex,
      currentMatch: computedPrevMatch,
    });
    searchPropsByAttachmentIds.current[computedAttachmentId].jumpToMatch(computedPrevMatch);
  }

  function doChangeSearchValue(text: string) {
    Object.keys(searchPropsByAttachmentIds.current).forEach((key) => {
      searchPropsByAttachmentIds.current[key].setKeyword(text);
    });
    setAttachmentsMultiSearchStateHolder((prev) => ({
      ...prev,
      isSearchTextChanged: true,
      searchText: text,
    }));
  }

  function doClearSearchValue() {
    Object.keys(searchPropsByAttachmentIds.current).forEach((key) => {
      searchPropsByAttachmentIds.current[key].clearKeyword();
    });
    setAttachmentsMultiSearchStateHolder((prev) => ({
      ...prev,
      isSearchTextChanged: false,
      searchText: "",
    }));
  }

  function registerAttachmentSearchProps(attachmentId: string, props: RenderSearchProps) {
    searchPropsByAttachmentIds.current[attachmentId] = props;
  }

  function registerAttachmentSearchInputRef(
    attachmentId: string,
    inputRef: MutableRefObject<HTMLInputElement | undefined>
  ) {
    textInputRefByAttachmentIds.current[attachmentId] = inputRef;
  }

  function setSidePanelScrollOffset(scrollOffset: number) {
    sidePanelScrollOffset.current = scrollOffset;
  }

  function getSidePanelScrollOffset(): number {
    return sidePanelScrollOffset.current;
  }

  // Private functions
  function __tryToRetrapFocus(attachmentId: string) {
    const nextTextInputRef = textInputRefByAttachmentIds.current[attachmentId];

    // Defer to next tick
    // Need to do this because the attachment switching is immediate, but rendering the search bar is not
    setTimeout(() => {
      nextTextInputRef.current?.focus();
    }, 1);
  }

  return {
    state: attachmentsMultiSearchStateHolder,
    utilities: {
      doSearch,
      doJumpToNextMatch,
      doJumpToPreviousMatch,
      doChangeSearchValue,
      doClearSearchValue,
      registerAttachmentSearchProps,
      registerAttachmentSearchInputRef,
      setSidePanelScrollOffset,
      getSidePanelScrollOffset,
    },
    stats: {
      currentMatchNumber,
      totalMatchesNumber,
    },
  };
}

export default useAttachmentsMultiSearch;
