export const auditOutcomes = [
  { id: "APPROVED", label: "Approved" },
  { id: "PENDING_MISSING_INFO", label: "Pending missing info" },
  { id: "PENDING_MD_REVIEW", label: "Pending MD review" },
  { id: "WITHDRAWN", label: "Withdrawn" },
];

export const getAuditReviewOutcomes = (newAuditReviewOutcomes: boolean, reviewType: string) => {
  if (newAuditReviewOutcomes && reviewType === "AuditReview") {
    return auditOutcomes;
  } else {
    return [
      { id: "PASS", label: "Pass" },
      { id: "FAIL", label: "Fail" },
    ];
  }
};
