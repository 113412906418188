import React, { ReactNode } from "react";
import { colorsLight, H1, H3 } from "@coherehealth/common";
import { makeStyles, useTheme } from "@material-ui/core";
import NoReferralsDashboardIcon from "../../images/NoReferralsDashboardIcon";

interface Props {
  heading?: ReactNode;
  message?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  noResultsContainer: {
    display: "flex",
    border: `1px solid ${colorsLight.gray.divider}`,
    boxSizing: "border-box",
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    "& div": {
      margin: theme.spacing(3),
    },
    minHeight: theme.spacing(59.125),
  },
  heroHeading: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  heroText: {
    color: theme.palette.text.secondary,
  },
}));

export default function NoResults({
  heading = "No referrals yet",
  message = "Get started by adding a referral above.",
}: Props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.noResultsContainer} style={{ padding: theme.spacing(11.125, 0, 11, 7.875) }}>
      <div>
        <NoReferralsDashboardIcon />
      </div>
      <div>
        <H1 className={classes.heroHeading}>{heading}</H1>
        <H3 className={classes.heroText}>{message}</H3>
      </div>
    </div>
  );
}
