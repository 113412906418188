import React, { FunctionComponent, useRef, useState, useEffect } from "react";
import { ClassNameMap } from "notistack";
import Container from "@material-ui/core/Container";
import {
  FeatureFlagCurrentValue,
  FeatureFlagStatus,
  FeatureFlagAdminView,
  LocalFeatureFlagProps,
} from "../FeatureFlag";
import { Body1, H1, H4, H6 } from "../Typography";
import { InlineButton } from "../InlineButton";
import { makeStyles, Typography } from "@material-ui/core";
import { colorsLight, colorsDark } from "../../themes/colors";
import Tooltip from "../Tooltip/Tooltip";
import { useIsOverflow } from "../../hooks/useIsOverflow";
import FeatureFlagTable from "./FeatureFlagTable";
import { downloadUrl } from "../../util/downloadUrl";
import FeatureFlagEditModal from "./FeatureFlagEditModal";
import MultiSelectDropdown from "../Dropdown/MultiSelectDropdown";
import TextSearch from "../TextSearch/TextSearch";
import debounce from "lodash/debounce";

// categories of feature flags that can be selected in the dropdown
const categoryOptions = [
  { id: "ADMIN", label: "Admin" },
  { id: "AUTH", label: "Auth" },
  { id: "QM", label: "Queue management" },
  { id: "SHARED", label: "Shared" },
  { id: "MISC", label: "Misc" },
];

const categorizeAndUpdateFeatureFlags = (
  flagCurrentValues: FeatureFlagCurrentValue[],
  flagAdditionalData: FeatureFlagAdminView[],
  selectedCategories: string[],
  flagQuery: string
) => {
  const categorizedFlags: Map<string, FeatureFlagCurrentValue[]> = new Map();

  flagCurrentValues.forEach((flag) => {
    const category = flag.belongingPackage || "MISC";

    // filter by FF category -- when no filters are selected, show all categories
    if (selectedCategories.length === 0 || selectedCategories.includes(category)) {
      // override existing FF values with DB values
      const currentFlagValues = { ...flag };
      const additionalData = flagAdditionalData.find((flagData) => flagData.id === flag.flagName);

      currentFlagValues.setting = additionalData?.setting ? additionalData.setting : currentFlagValues.setting;

      if (additionalData?.description) {
        currentFlagValues.description = additionalData?.description;
      }

      if (additionalData?.dateCreated) {
        currentFlagValues.dateCreated = additionalData?.dateCreated;
      }

      if (additionalData?.lastUpdated) {
        currentFlagValues.lastUpdated = additionalData?.lastUpdated;
      }

      if (additionalData?.limitedOrgsCount) {
        currentFlagValues.limitedOrgsCount = additionalData?.limitedOrgsCount;
      }

      // filter by query on FF name or description -- case insensitive
      if (
        !flagQuery ||
        currentFlagValues.flagName.toLowerCase().includes(flagQuery.toLowerCase()) ||
        currentFlagValues.description?.toLowerCase().includes(flagQuery.toLowerCase())
      ) {
        if (!categorizedFlags.has(category)) {
          categorizedFlags.set(category, [currentFlagValues]);
        } else {
          categorizedFlags.get(category)?.push(currentFlagValues);
        }
      }
    }
  });

  return categorizedFlags;
};

interface Props {
  flagCurrentValues: FeatureFlagCurrentValue[];
  dropServiceRequestCollectionComponent?: React.ReactNode;
  flagAdditionalData: FeatureFlagAdminView[];
  downloadReleaseLimitedOrganizations: (data: { id?: string }) => Promise<{ ok?: boolean; blob?: () => Promise<Blob> }>;
  downloadReleaseLimitedOrganizationsError: { message?: string } | null;
  updateFeatureFlag: (data: {
    id?: string;
    enabled?: boolean;
    setting?: FeatureFlagStatus;
    description?: string;
    whitelistedOrganizationList?: { id?: string; name?: string }[];
  }) => Promise<{ validated?: boolean }>;
  updateFeatureFlagError: { message?: string } | null;
  refetchFeatureFlagsAdminView: () => Promise<unknown>;
  localFeatureFlags: LocalFeatureFlagProps | undefined;
  updateLocalFeatureFlags: (update: LocalFeatureFlagProps) => void;
}

const FeatureFlagPageComponent: FunctionComponent<Props> = ({
  flagCurrentValues,
  dropServiceRequestCollectionComponent,
  flagAdditionalData,
  downloadReleaseLimitedOrganizations,
  downloadReleaseLimitedOrganizationsError,
  updateFeatureFlag,
  updateFeatureFlagError,
  refetchFeatureFlagsAdminView,
  localFeatureFlags,
  updateLocalFeatureFlags,
}) => {
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [flagQuery, setFlagQuery] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingFlag, setEditingFlag] = useState<FeatureFlagCurrentValue>();
  const categorizedFlagValues = categorizeAndUpdateFeatureFlags(
    flagCurrentValues,
    flagAdditionalData,
    selectedCategories,
    flagQuery
  );

  const onFileDownload = async (flagName: string) => {
    if (!flagName) {
      return;
    }

    const response = await downloadReleaseLimitedOrganizations({ id: flagName });

    if (response.ok && response.blob) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      downloadUrl(url, `${flagName}_releaseLimitedOrganizations.csv`);
    }
  };

  return (
    <Container>
      {process.env.REACT_APP_ALLOW_LOCAL_FF_OVERRIDES === "true" && (
        <PageHeader
          localFeatureFlags={localFeatureFlags}
          updateLocalFeatureFlags={updateLocalFeatureFlags}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          flagQuery={flagQuery}
          setFlagQuery={setFlagQuery}
        />
      )}

      {editModalOpen && editingFlag && (
        <FeatureFlagEditModal
          isOpen={editModalOpen}
          setOpen={setEditModalOpen}
          featureFlag={editingFlag}
          updateFeatureFlag={updateFeatureFlag}
          refetchFeatureFlagsAdminView={refetchFeatureFlagsAdminView}
          downloadReleaseLimitedOrganizationsError={downloadReleaseLimitedOrganizationsError}
          updateFeatureFlagError={updateFeatureFlagError}
        />
      )}

      {categorizedFlagValues.size > 0 ? (
        <>
          {categorizedFlagValues?.get("ADMIN") && (
            <>
              <GroupHeader title="Admin feature flags" />
              <FeatureFlagTable
                featureFlagValues={categorizedFlagValues.get("ADMIN") || []}
                localFeatureFlags={localFeatureFlags}
                updateLocalFeatureFlags={updateLocalFeatureFlags}
                onFileDownload={onFileDownload}
                setEditModalOpen={setEditModalOpen}
                setEditingFlag={setEditingFlag}
              />
            </>
          )}

          {categorizedFlagValues?.get("AUTH") && (
            <>
              <GroupHeader title="Auth feature flags" />
              <FeatureFlagTable
                featureFlagValues={categorizedFlagValues.get("AUTH") || []}
                localFeatureFlags={localFeatureFlags}
                updateLocalFeatureFlags={updateLocalFeatureFlags}
                onFileDownload={onFileDownload}
                setEditModalOpen={setEditModalOpen}
                setEditingFlag={setEditingFlag}
              />
            </>
          )}

          {categorizedFlagValues?.get("QM") && (
            <>
              <GroupHeader title="Queue management feature flags" />
              <FeatureFlagTable
                featureFlagValues={categorizedFlagValues.get("QM") || []}
                localFeatureFlags={localFeatureFlags}
                updateLocalFeatureFlags={updateLocalFeatureFlags}
                onFileDownload={onFileDownload}
                setEditModalOpen={setEditModalOpen}
                setEditingFlag={setEditingFlag}
              />
            </>
          )}

          {categorizedFlagValues?.get("SHARED") && (
            <>
              <GroupHeader title="Shared feature flags" />
              <FeatureFlagTable
                featureFlagValues={categorizedFlagValues.get("SHARED") || []}
                localFeatureFlags={localFeatureFlags}
                updateLocalFeatureFlags={updateLocalFeatureFlags}
                onFileDownload={onFileDownload}
                setEditModalOpen={setEditModalOpen}
                setEditingFlag={setEditingFlag}
              />
            </>
          )}

          {categorizedFlagValues?.get("MISC") && (
            <>
              <GroupHeader title="Misc feature flags" />
              <FeatureFlagTable
                featureFlagValues={categorizedFlagValues.get("MISC") || []}
                localFeatureFlags={localFeatureFlags}
                updateLocalFeatureFlags={updateLocalFeatureFlags}
                onFileDownload={onFileDownload}
                setEditModalOpen={setEditModalOpen}
                setEditingFlag={setEditingFlag}
              />
            </>
          )}

          {dropServiceRequestCollectionComponent}
          <div style={{ paddingBottom: 16 }}>
            <Body1>Changing the setting of a feature flag creates an override for all users. Be careful!</Body1>
          </div>
        </>
      ) : (
        <GroupHeader title="No results found" />
      )}
    </Container>
  );
};

const useStyles = makeStyles((theme) => {
  const isDarkTheme = theme.palette.type === "dark";

  return {
    header: { marginTop: theme.spacing(4) },
    titleAndSearch: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: theme.spacing(3),
    },
    localOverrides: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: theme.spacing(12),
    },
    instructionsContainer: { width: "66%", paddingRight: theme.spacing(1) },
    instructionsText: {
      fontWeight: 400,
      fontSize: "15px",
      marginTop: theme.spacing(1),
      color: isDarkTheme ? colorsDark.font.secondary : colorsLight.font.secondary,
    },
    overridesInfo: {
      width: "34%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "space-between",
      paddingLeft: theme.spacing(1),
    },
    localFFDisplay: {
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      ...theme.typography.caption,
      color: isDarkTheme ? colorsDark.font.secondary : colorsLight.font.secondary,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
    },
    groupHeader: {
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(5),
      color: isDarkTheme ? colorsDark.font.secondary : colorsLight.font.secondary,
    },
    search: {
      width: theme.spacing(50),
      marginLeft: theme.spacing(1),
    },
    dropdown: {
      width: theme.spacing(30),
      marginRight: theme.spacing(1),
    },
    filters: {
      display: "flex",
    },
  };
});

interface PageHeaderProps {
  localFeatureFlags: LocalFeatureFlagProps | undefined;
  updateLocalFeatureFlags: (update: LocalFeatureFlagProps) => void;
  selectedCategories: string[];
  setSelectedCategories: React.Dispatch<React.SetStateAction<string[]>>;
  flagQuery: string;
  setFlagQuery: React.Dispatch<React.SetStateAction<string>>;
}
const PageHeader = ({
  localFeatureFlags,
  updateLocalFeatureFlags,
  selectedCategories,
  setSelectedCategories,
  flagQuery,
  setFlagQuery,
}: PageHeaderProps) => {
  const classes = useStyles();
  const [query, setQuery] = useState(flagQuery);

  // debounce query to reduce lag and parent re-render on each key stroke
  useEffect(() => {
    const debouncedSetFlagQuery = debounce((q) => {
      setFlagQuery(q);
    }, 800);

    debouncedSetFlagQuery(query);

    return () => {
      debouncedSetFlagQuery.cancel();
    };
  }, [query, setFlagQuery]);

  return (
    <div className={classes.header}>
      <div className={classes.titleAndSearch}>
        <H1>Feature flags</H1>
        <div className={classes.filters}>
          <MultiSelectDropdown
            className={classes.dropdown}
            label="Filter by category"
            options={categoryOptions}
            selectedValues={selectedCategories}
            onChange={setSelectedCategories}
          />

          <TextSearch
            className={classes.search}
            label="Search by name or description"
            value={query}
            onChangeValue={setQuery}
          />
        </div>
      </div>
      <div className={classes.localOverrides}>
        <div className={classes.instructionsContainer}>
          <H4>Local overrides</H4>
          <Body1 className={classes.instructionsText}>
            Overrides affect only your local environment, letting you change the status without impacting other users.
          </Body1>
        </div>
        {localFeatureFlags && Object.keys(localFeatureFlags).length > 0 && (
          <div className={classes.overridesInfo}>
            <div>
              <H6>
                {Object.keys(localFeatureFlags).length} override
                {Object.keys(localFeatureFlags).length === 1 ? "" : "s"} applied
              </H6>
            </div>
            <FeatureFlagCaption caption={Object.keys(localFeatureFlags).join(", ")} classes={classes} />
            <InlineButton
              onClick={() => {
                updateLocalFeatureFlags({});
              }}
            >
              Clear local overrides
            </InlineButton>
          </div>
        )}
      </div>
    </div>
  );
};
interface FeatureFlagCaptionProps {
  caption: string;
  classes: ClassNameMap<
    | "header"
    | "localFFDisplay"
    | "titleAndSearch"
    | "localOverrides"
    | "instructionsContainer"
    | "overridesInfo"
    | "groupHeader"
  >;
}

const FeatureFlagCaption = ({ caption, classes }: FeatureFlagCaptionProps) => {
  const textElementRef = useRef<HTMLInputElement | null>(null);
  const { hoverStatus } = useIsOverflow(textElementRef);

  // dynamically size the tooltip width depending on screen size and caption
  const [tooltipMaxWidth, setTooltipMaxWidth] = useState(460);

  const updateTooltipMaxWidth = () => {
    if (textElementRef.current) {
      const textElementWidth = textElementRef.current.offsetWidth;
      const maxWidth = Math.min(460, textElementWidth);
      setTooltipMaxWidth(maxWidth);
    }
  };

  useEffect(() => {
    updateTooltipMaxWidth();

    window.addEventListener("resize", updateTooltipMaxWidth);
    return () => {
      window.removeEventListener("resize", updateTooltipMaxWidth);
    };
  }, [caption]);

  return (
    <Tooltip maxWidth={`${tooltipMaxWidth}px`} title={caption} disableHoverListener={!hoverStatus} placement="top">
      <Typography className={classes.localFFDisplay} ref={textElementRef}>
        {caption}
      </Typography>
    </Tooltip>
  );
};

interface GroupHeaderProps {
  title: string;
}

const GroupHeader = ({ title }: GroupHeaderProps) => {
  const classes = useStyles();
  return <H4 className={classes.groupHeader}>{title}</H4>;
};

export default FeatureFlagPageComponent;
