import { FormConfiguration } from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import { isExceptionCommentRequired } from "util/providerUtils";
import {
  validateExceptionReasonOnSRforCareParticipants,
  validateOONExceptionCommentFieldForCareParticipants,
} from "util/serviceRequest";
import {
  validatePerformingProviderAddress,
  validatePerformingProviderNPI,
  validatePerformingProviderTin,
} from "../validationUtil";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { GetOutofNetworkCheckResponse } from "@coherehealth/core-platform-api";
import { isEmpty } from "lodash";
import { fieldIsValid } from "common/FormConfigUtils";

interface Props {
  formContent: ServiceRequestFormContent;
  formConfiguration: FormConfiguration;
  providerOonCheckData?: GetOutofNetworkCheckResponse | undefined;
  performingProviderPracticeOonCheckData?: GetOutofNetworkCheckResponse | undefined;
  facilityOonCheckData?: GetOutofNetworkCheckResponse | undefined;
  orderingProviderOonCheckData?: GetOutofNetworkCheckResponse | undefined;
  careParticipantOonCheckData?: GetOutofNetworkCheckResponse | undefined;
  patientHealthPlanName: string;
  canSkipValidationForNpiTin: boolean;
  canSkipOon: boolean;
  hideTinField: boolean;
}
export function generateValidationMap({
  formContent,
  formConfiguration,
  patientHealthPlanName,
  providerOonCheckData,
  performingProviderPracticeOonCheckData,
  facilityOonCheckData,
  orderingProviderOonCheckData,
  careParticipantOonCheckData,
  canSkipValidationForNpiTin,
  canSkipOon,
  hideTinField,
}: Props) {
  // Logic and other helper functions related to validation
  // In most cases we don't want to default to showing an error state unless if the user tried to submit
  const hasPerformingProvider = Boolean(formContent?.performingProvider);
  const hasOrderingProvider = Boolean(formContent.orderingProvider);
  const hasFacility = Boolean(formContent.facility);
  const hasFacilityNpi =
    formContent?.facility?.manuallyCreated && canSkipValidationForNpiTin && !formContent?.facility?.npi
      ? canSkipValidationForNpiTin
      : Boolean(formContent?.facility?.npi);
  const hasPerformingProviderNpi =
    formContent?.performingProvider?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.performingProvider?.npi
      ? canSkipValidationForNpiTin
      : Boolean(formContent?.performingProvider?.npi);
  const hasOrderingProviderNpi =
    formContent?.orderingProvider?.manuallyCreated && canSkipValidationForNpiTin && !formContent?.orderingProvider?.npi
      ? canSkipValidationForNpiTin
      : Boolean(formContent?.orderingProvider?.npi);
  const hasFacilityTin =
    formContent?.facility?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.selectedFacility?.selectedLocation?.tin
      ? canSkipValidationForNpiTin
      : formContent?.facility?.tinList?.length === 0
      ? true
      : Boolean(formContent?.selectedFacility?.selectedLocation?.tin);
  const performingProviderTinLength = formContent?.performingProvider?.tinList?.length;
  const hasPerformingProviderTin =
    formContent?.performingProvider?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.selectedPerformingProvider?.selectedLocation?.tin
      ? canSkipValidationForNpiTin
      : performingProviderTinLength === 0
      ? true
      : Boolean(formContent?.selectedPerformingProvider?.selectedLocation?.tin);
  const hasFacilityAddress = !isEmpty(formContent?.selectedFacility?.selectedLocation?.address);
  const hasPerformingProviderAddress = !isEmpty(formContent?.selectedPerformingProvider?.selectedLocation?.address);
  const hasOrderingProviderTin =
    formContent?.orderingProvider?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.selectedOrderingProvider?.selectedLocation?.tin
      ? canSkipValidationForNpiTin
      : formContent?.orderingProvider?.tinList?.length === 0
      ? true
      : Boolean(formContent?.selectedOrderingProvider?.selectedLocation?.tin);
  const hasOrderingProviderAddress = !isEmpty(formContent?.selectedOrderingProvider?.selectedLocation?.address);
  const hasFacilityOutOfNetworkExceptionReason =
    !!facilityOonCheckData?.isExceptionRequired && !canSkipOon
      ? Boolean(formContent?.facilitySelectedAddress?.outOfNetworkExceptionReason) &&
        facilityOonCheckData?.isExceptionRequired
      : true;
  const hasPPOutOfNetworkExceptionReason =
    !!providerOonCheckData?.isExceptionRequired && !canSkipOon
      ? Boolean(formContent?.performingProviderSelectedAddress?.outOfNetworkExceptionReason) &&
        providerOonCheckData?.isExceptionRequired
      : true;
  const hasPPPracticeOutOfNetworkExceptionReason =
    !!performingProviderPracticeOonCheckData?.isExceptionRequired && !canSkipOon
      ? Boolean(formContent?.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionReason) &&
        performingProviderPracticeOonCheckData?.isExceptionRequired
      : true;
  const hasOrderingProviderOutOfNetworkExceptionReason =
    !!orderingProviderOonCheckData?.isExceptionRequired && !canSkipOon
      ? Boolean(formContent?.orderingProviderSelectedAddress?.outOfNetworkExceptionReason) &&
        orderingProviderOonCheckData?.isExceptionRequired
      : true;

  const requiresFacilityOONExceptionComment =
    formContent.selectedFacility?.selectedLocation?.isOutOfNetwork &&
    formContent.selectedFacility?.selectedLocation?.outOfNetworkExceptionReason === "Other (comment is required)";

  const requiresPerformingProviderOONExceptionComment =
    formContent.selectedPerformingProvider?.selectedLocation?.isOutOfNetwork &&
    formContent.selectedPerformingProvider?.selectedLocation?.outOfNetworkExceptionReason ===
      "Other (comment is required)";

  const requiresPerformingProviderPracticeOONExceptionComment =
    formContent.selectedPerformingProviderPractice?.selectedLocation?.isOutOfNetwork &&
    formContent.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionReason ===
      "Other (comment is required)";

  const requiresOrderingProviderOONExceptionComment = isExceptionCommentRequired(formContent);

  const hasFacilityOONExceptionComment = formContent.selectedFacility?.selectedLocation?.outOfNetworkExceptionComment;

  const hasPerformingProviderOONExceptionComment =
    formContent.selectedPerformingProvider?.selectedLocation?.outOfNetworkExceptionComment;

  const hasPerformingProviderPracticeOONExceptionComment =
    formContent.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionComment;

  const hasOrderingProviderOONExceptionComment =
    formContent.selectedOrderingProvider?.selectedLocation?.outOfNetworkExceptionComment;

  return {
    selectedDetails: true,
    expeditedTatUpdateTimestamp: true,
    singleClinicalService: true,
    encounterType: true,
    placeOfService: true,
    primaryDiagnosis: true,
    secondaryDiagnoses: true,
    procedureCodes: true,
    recurring: true,
    startEndDate: true,
    units: true,
    orderingProvider: fieldIsValid(formConfiguration.orderingProvider, hasOrderingProvider),
    performingProvider: fieldIsValid(formConfiguration.performingProvider, hasPerformingProvider),
    facility: fieldIsValid(formConfiguration.facility, hasFacility),
    urgency: true,
    behavioralHealthAdmissionType: true,
    behavioralHealthReviewType: true,
    admissionDischargeDate: true,
    faxInputField: true,
    procedureCodeWithUnits: true,
    prescribedDrug: true,
    facilityAddress: fieldIsValid(formConfiguration.facilityAddress, hasFacilityAddress),
    facilityNPI: fieldIsValid(formConfiguration.facilityNPI, hasFacilityNpi),
    facilityTIN:
      hideTinField ||
      (formContent.facility?.source === "CMS" && !formContent?.facility?.manuallyCreated) ||
      fieldIsValid(formConfiguration?.facilityTIN, hasFacilityTin),
    performingProviderAddress: validatePerformingProviderAddress(
      formConfiguration,
      formContent,
      patientHealthPlanName,
      hasPerformingProviderAddress
    ),
    performingProviderTIN: validatePerformingProviderTin(
      formConfiguration,
      formContent,
      performingProviderTinLength,
      hasPerformingProviderTin
    ),
    performingProviderNPI: validatePerformingProviderNPI(formConfiguration, formContent, hasPerformingProviderNpi),
    orderingProviderAddress: fieldIsValid(formConfiguration.orderingProviderAddress, hasOrderingProviderAddress),
    orderingProviderTIN:
      hideTinField ||
      (formContent.orderingProvider?.source === "CMS" && !formContent?.orderingProvider?.manuallyCreated) ||
      fieldIsValid(formConfiguration.orderingProviderTIN, hasOrderingProviderTin),
    orderingProviderNPI: fieldIsValid(formConfiguration.orderingProviderNPI, hasOrderingProviderNpi),
    outOfNetworkCheck: true,
    facilityOutOfNetworkStatusDisplay: true,
    performingProviderOutOfNetworkStatusDisplay: true,
    performingProviderPracticeOutOfNetworkStatusDisplay: true,
    orderingProviderOutOfNetworkStatusDisplay: true,
    careParticipantOutOfNetworkStatusDisplay: true,
    outOfNetworkOrderingProvider: true,
    facilityOutOfNetworkExceptionReason:
      fieldIsValid(formConfiguration.facilityOutOfNetworkExceptionReason) && hasFacilityOutOfNetworkExceptionReason,
    performingProviderOutOfNetworkExceptionReason:
      fieldIsValid(formConfiguration.performingProviderOutOfNetworkExceptionReason) && hasPPOutOfNetworkExceptionReason,
    performingProviderPracticeOutOfNetworkExceptionReason:
      fieldIsValid(formConfiguration.performingProviderPracticeOutOfNetworkExceptionReason) &&
      hasPPPracticeOutOfNetworkExceptionReason,
    orderingProviderOutOfNetworkExceptionReason:
      fieldIsValid(formConfiguration.orderingProviderOutOfNetworkExceptionReason) &&
      hasOrderingProviderOutOfNetworkExceptionReason,
    facilityOutOfNetworkExceptionComment:
      (requiresFacilityOONExceptionComment && !!hasFacilityOONExceptionComment) || !requiresFacilityOONExceptionComment,
    performingProviderOutOfNetworkExceptionComment:
      (requiresPerformingProviderOONExceptionComment && !!hasPerformingProviderOONExceptionComment) ||
      !requiresPerformingProviderOONExceptionComment,
    performingProviderPracticeOutOfNetworkExceptionComment:
      (requiresPerformingProviderPracticeOONExceptionComment && !!hasPerformingProviderPracticeOONExceptionComment) ||
      !requiresPerformingProviderPracticeOONExceptionComment,
    orderingProviderOutOfNetworkExceptionComment:
      (requiresOrderingProviderOONExceptionComment && !!hasOrderingProviderOONExceptionComment) ||
      !requiresOrderingProviderOONExceptionComment,
    careParticipantOutOfNetworkExceptionReason: formContent.additionalCareParticipants
      ? validateExceptionReasonOnSRforCareParticipants(
          formContent.additionalCareParticipants,
          canSkipOon,
          careParticipantOonCheckData
        )
      : true,
    careParticipantOutOfNetworkExceptionComment: formContent.additionalCareParticipants
      ? validateOONExceptionCommentFieldForCareParticipants(formContent.additionalCareParticipants)
      : true,
    userDeclaredOONException: true,
    patientStayDateRanges: true,
    authCategory: true,
    authSubCategory: true,
    admissionDate: true,
    admissionTime: true,
    dischargeTime: true,
    dischargeDate: true,
    dischargedTo: true,
    expectedAdmissionDate: true,
    expectedDischargeDate: true,
    additionalCareParticipants: true,
    nonPalCheckbox: true,
    blockUserIfExceedsRecommendedEndDate: true,
    blockUserIfLessThanRecommendedEndDate: true,
    userSelectedOONException: true,
    performingProviderPracticeSelectedTIN: true,
    performingProviderPracticeSelectedAddress: true,
    performingProviderPractice: true,
    performingProviderPracticeSelectedNPI: true,
    admissionSource: true,
    patientStatus: true,
  };
}
