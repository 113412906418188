import React, { ComponentProps, useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { FeatureFlagCurrentValue, CsvFile, CsvOrganizationList } from "../FeatureFlag";
import Modal from "../Modal/Modal";
import { H2 } from "../Typography";
import { TextField } from "../TextField";
import { useTheme } from "@material-ui/core";
import { PrimaryButton } from "../PrimaryButton";
import FeatureFlagStatusDisplay from "./FeatureFlagStatusDisplay";
import { FeatureFlagStatus } from "@coherehealth/core-platform-api";
import csvParse from "csv-parse/lib/browser/sync";
import { fileSizeString } from "../../util/fileUtils";
import { useSnackbar } from "notistack";

const DEFAULT_ALLOWED_FILE_EXTENSIONS = [".csv"];
// Max file size of 15MB
const MAX_FILE_SIZE = 15728640;

interface UpdateFeatureFlagData {
  id?: string;
  enabled?: boolean;
  setting?: FeatureFlagStatus;
  description?: string;
  whitelistedOrganizationList?: { id?: string; name?: string }[];
}

interface FeatureFlagEditModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  featureFlag: FeatureFlagCurrentValue;
  updateFeatureFlag: (data: {
    id?: string;
    enabled?: boolean;
    setting?: FeatureFlagStatus;
    description?: string;
    whitelistedOrganizationList?: { id?: string; name?: string }[];
  }) => Promise<{ validated?: boolean }>;
  refetchFeatureFlagsAdminView: () => Promise<unknown>;
  downloadReleaseLimitedOrganizationsError: { message?: string } | null;
  updateFeatureFlagError: { message?: string } | null;
}
const FeatureFlagEditModal = ({
  isOpen,
  setOpen,
  featureFlag,
  updateFeatureFlag,
  refetchFeatureFlagsAdminView,
  downloadReleaseLimitedOrganizationsError,
  updateFeatureFlagError,
}: FeatureFlagEditModalProps) => {
  const [description, setDescription] = useState(featureFlag?.description || "");
  const [status, setStatus] = useState<FeatureFlagStatus>(featureFlag?.setting || "OFF");
  const [csvFile, setCsvFile] = useState<CsvFile>();
  const [isSaveEnabled, setIsSaveEnabled] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const allowedFileExtensions = DEFAULT_ALLOWED_FILE_EXTENSIONS;

  const resetFormStates = async () => {
    setCsvFile(undefined);
    setLoading(false);
    setIsSaveEnabled(true);
  };

  useEffect(() => {
    if (downloadReleaseLimitedOrganizationsError) {
      enqueueSnackbar(`Error downloading: ${downloadReleaseLimitedOrganizationsError.message}`, { variant: "error" });
    }
  }, [downloadReleaseLimitedOrganizationsError, enqueueSnackbar]);

  // reset states when opening edit modal
  useEffect(() => {
    setDescription(featureFlag?.description || "");
    setStatus(featureFlag?.setting || "OFF");
    setCsvFile(undefined);
    setIsSaveEnabled(true);
    setLoading(false);
  }, [featureFlag]);

  useEffect(() => {
    if (updateFeatureFlagError) {
      enqueueSnackbar(`Error updating: ${updateFeatureFlagError.message}`, { variant: "error" });
    }
  }, [updateFeatureFlagError, enqueueSnackbar]);

  const setFeatureFlag = async () => {
    setIsSaveEnabled(false);
    setLoading(true);

    if (status !== "LIMITED") {
      let updateFeatureFlagData: UpdateFeatureFlagData = {
        id: featureFlag.flagName,
        // 'enabled' is deprecated. Use 'setting' instead.
        enabled: status === "OFF" ? false : true,
        setting: status,
      };
      if (description) {
        updateFeatureFlagData.description = description;
      }
      await updateFeatureFlag(updateFeatureFlagData);
    } else {
      /** If csv file does not exist, add error toast **/
      if (!csvFile) {
        enqueueSnackbar(`Upload a CSV to define the limited release segment`, {
          variant: "error",
        });
        setLoading(false);
        return;
      }

      const response = await updateFeatureFlag({
        id: featureFlag.flagName,
        // 'enabled' is deprecated. Use 'setting' instead.
        enabled: false,
        setting: status,
        whitelistedOrganizationList: csvFile?.organizations,
      });

      /** If organization list sent was not valid, add error toast **/
      if (response.validated !== undefined && !response.validated) {
        enqueueSnackbar(`Upload failed: one or more organizations is invalid`, {
          variant: "error",
        });
        resetFormStates();
        return;
      }

      enqueueSnackbar(`CSV uploaded`, {
        variant: "success",
      });
    }
    await refetchFeatureFlagsAdminView();
    resetFormStates();
    setStatus(status);
    setOpen(false);
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    if (!(input && input.files)) {
      enqueueSnackbar(`Upload failed: Issue with file, please try again.`, {
        variant: "error",
      });
      return;
    }

    const file = input.files[0];
    assertIsFile(file);
    if (file.size > MAX_FILE_SIZE) {
      enqueueSnackbar(
        `Upload failed: ${file.name} is ${fileSizeString(file.size)}, but maximum allowed is ${fileSizeString(
          MAX_FILE_SIZE
        )}`,
        { variant: "warning" }
      );
      setIsSaveEnabled(false);
      return;
    }

    if (!allowedFileExtensions.some((e) => file.name.toLowerCase().endsWith(e))) {
      enqueueSnackbar(
        `Upload failed: ${file.name} must have one of the following extensions: ${allowedFileExtensions.join(", ")}`,
        { variant: "warning" }
      );
      setIsSaveEnabled(false);
      return;
    }
    try {
      const contents = await file.text();

      if (!contents) {
        enqueueSnackbar(`Upload failed: Issue with file, please try again.`, {
          variant: "error",
        });
        return;
      }

      const parsedContent = csvParse(contents, {
        skipEmptyLines: true,
        columns: (header) => header.map((column: string) => column.toLowerCase()),
      });

      /** Error handling for file criteria, to make sure file includes 'id' and 'name' fields **/
      if (!Object.keys(parsedContent[0]).includes("id") && !Object.keys(parsedContent[0]).includes("name")) {
        enqueueSnackbar(`Upload failed: "ID" and "Name" headers are missing or incorrectly formatted`, {
          variant: "error",
        });
        setIsSaveEnabled(false);
        return;
      }
      if (!Object.keys(parsedContent[0]).includes("id")) {
        enqueueSnackbar(`Upload failed: "ID" header is missing or incorrectly formatted`, { variant: "error" });
        setIsSaveEnabled(false);
        return;
      }
      if (!Object.keys(parsedContent[0]).includes("name")) {
        enqueueSnackbar(`Upload failed: "Name" header is missing or incorrectly formatted`, { variant: "error" });
        setIsSaveEnabled(false);
        return;
      }

      /** Remove rows that have missing id or name field **/
      const parsedOrganizationContent = parsedContent
        .filter((item: CsvOrganizationList) => item.id !== "" && item.name !== "")
        .map((item: CsvOrganizationList) => {
          return { id: item.id, name: item.name };
        });

      /** Check if both the file and the actual extracted csv list fit the criteria **/
      if (parsedOrganizationContent.length === parsedContent.length) {
        const organizationCsvFile = {
          fileName: file.name,
          organizations: parsedOrganizationContent,
        };
        setCsvFile(organizationCsvFile);
        setIsSaveEnabled(true);
      } else {
        enqueueSnackbar(`Upload failed: one or more organizations is invalid`, {
          variant: "error",
        });
        setIsSaveEnabled(false);
      }
    } catch (e: any) {
      enqueueSnackbar(`Upload failed: ${e.message}`, { variant: "error" });
      setIsSaveEnabled(false);
    } finally {
      // Clear out the input: otherwise, if the user selects the same file
      // a second time, we don't trigger an onChange event
      input.value = "";
    }
  };

  function assertIsFile(ipt: File | string | null): asserts ipt is File {
    if (!ipt || typeof ipt === "string") {
      throw new Error("Not a file");
    }
  }

  return featureFlag ? (
    <Modal
      open={isOpen}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Grid container spacing={4}>
        <Row>
          <H2>Edit feature flag</H2>
        </Row>
        <Row>
          <TextField disabled fullWidth label="Name" value={featureFlag?.flagName} name="Flag name" />
          <RowDivider />
          <TextField
            multiline
            fullWidth
            label={`Description ${description.length === 0 ? "(optional)" : ""}`}
            value={description}
            onChangeValue={setDescription}
            name="Flag description"
          />
          <RowDivider />
          <FeatureFlagStatusDisplay
            selectedStatus={status}
            setSelectedStatus={setStatus}
            onFileChange={onFileChange}
            allowedFileExtensions={allowedFileExtensions}
            csvName={csvFile?.fileName}
            setIsSaveEnabled={setIsSaveEnabled}
          />
        </Row>
        <Row>
          <PrimaryButton
            disabled={!isSaveEnabled}
            onClick={async () => {
              /* If clicked save for the first time without a csvFile attached, disable save, add error toast */
              if (status === "LIMITED" && !csvFile) {
                setIsSaveEnabled(false);
                enqueueSnackbar(`Upload a CSV to define the limited release segment`, {
                  variant: "error",
                });
                return;
              }
              await setFeatureFlag();
            }}
            loading={loading}
          >
            Save
          </PrimaryButton>
        </Row>
      </Grid>
    </Modal>
  ) : null;
};

const Row = (props: ComponentProps<typeof Grid>) => {
  const theme = useTheme();
  return <Grid style={{ textAlign: "center", width: theme.spacing(120) }} item xs={12} {...props} />;
};

const RowDivider = () => {
  const theme = useTheme();
  return <div style={{ width: "100%", height: theme.spacing(2) }}></div>;
};

export default FeatureFlagEditModal;
