import { registerRoute } from "../server";

export default function registerRoutes() {
  registerRoute({
    id: "getPolicy",
    route: "policy/:policyId",
    method: "get",
    generateResponse: function (config, schema, request) {
      return {
        id: "60886ea2607dfa0001806dec",
        active: true,
        policyUrl: "www.ggggo",
        policyTitle: "New Policy Testing",
        nextInternalReviewDate: "2021-04-29",
        documentVersion: 3,
        policyExpirationDate: "2021-04-29",
        filterIds: [],
        policyEffectiveDate: "2021-04-24",
        policyType: "COHERE",
        createdBy: "00ud4x7oqe7OmfvUs4x6",
        filters: [],
        guidelines: [],
      };
    },
  });
  registerRoute({
    id: "getPolicyGuideline",
    route: "guideline/:guidelineId",
    method: "get",
    generateResponse: function (config, schema, request) {
      return {
        id: "608880a1c6cbc8000161e694",
        guidelineTitle: "GuidelineTestingForJest",
        dateCreated: "2021-04-27T21:22:41.478Z",
        isCurrentDraft: true,
        isCurrentPublished: false,
        active: true,
        updatedBy: "00u1zzh29vWOwUn2Q4x7",
        documentVersion: 1,
        guidelineHtml:
          '<div><div style="background-color: rgba(255, 255, 255, 0.16);">here is some made up html</div></div>',
        versionOfId: "608880a1c6cbc8000161e694",
        originalVersionId: "608880a1c6cbc8000161e694",
        lastUpdated: "2021-04-27T21:22:41.478Z",
        deleted: false,
        policyHtmlIndices: [1, 2, 3, 4, 5, 6, 7, 8, 9],
        createdBy: "00u1zzh29vWOwUn2Q4x7",
        versions: [
          {
            id: "608880a1c6cbc8000161e694",
            originalVersionId: "608880a1c6cbc8000161e694",
            documentVersion: 1,
            published: null,
            isCurrentDraft: true,
            lastUpdated: "2021-04-27T21:22:41.478Z",
            publishedBy: null,
          },
        ],
        filters: [],
      };
    },
  });
  registerRoute({
    id: "getGuidelineRule",
    route: "serviceRequestRule/:ruleId",
    method: "get",
    generateResponse: function (config, schema, request) {
      return {
        id: "61255bf71fa9f7c8655b5280",
        dateCreated: "2021-08-24T20:52:07.756Z",
        isCurrentDraft: true,
        isCurrentPublished: false,
        active: true,
        updatedBy: "00u3ve6m0lRABeCyT4x7",
        ruleLabel: {
          id: "5fd79b9119fdbc0001a7a3ed",
          dateCreated: "2020-12-14T17:06:25.892Z",
          updatedBy: "00uq4d86iQGZvyjiO4x6",
          name: "Cohere Policy",
          lastUpdated: "2020-12-15T15:40:36.207Z",
          deleted: false,
          createdBy: "00uq4d86iQGZvyjiO4x6",
          description:
            "Clinical Program Policy (e.g., Surgical Site of Service)",
        },
        actions: [],
        documentVersion: 1,
        versionOfId: "61255bf71fa9f7c8655b5280",
        skipPatientSearch: false,
        originalVersionId: "61255bf71fa9f7c8655b5280",
        condition: {
          block: {
            logicalOperator: "ALL",
            logicalOperatorValue: 0,
            outcome: false,
            conditions: [
              {
                statement: {
                  operandA: {
                    value: "patientAge",
                  },
                  operator: "equal_to",
                  operandB: {
                    value: 21,
                  },
                  outcome: false,
                },
                isStatement: true,
                outcome: false,
              },
              {
                block: {
                  logicalOperator: "ALL",
                  logicalOperatorValue: 0,
                  outcome: false,
                  conditions: [
                    {
                      statement: {
                        operandA: {
                          value: "patientGender",
                        },
                        operator: "equal_to",
                        operandB: {
                          value: "F",
                        },
                        outcome: false,
                      },
                      isStatement: true,
                      outcome: false,
                    },
                  ],
                },
                isStatement: false,
                outcome: false,
              },
            ],
          },
          isStatement: false,
          outcome: false,
          indication: {
            indicationHtml:
              '<div><div class="" data-block="true" data-editor="87e5p" data-offset-key="b5ftp-0-0"><div data-offset-key="b5ftp-0-0" class="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span data-offset-key="b5ftp-0-0"><span data-text="true">Percutaneous thermal intradiscal procedures (TIPs) involve the insertion of a catheter(s)/probe(s) in the spinal disc under fluoroscopic guidance for the purpose of producing or applying heat and/or disruption within the disc to relieve low back pain.</span></span></div></div></div>',
            guidelineHtmlIndices: [0],
          },
        },
        lastUpdated: "2021-08-30T21:02:32.954Z",
        deleted: false,
        filterIds: [],
        title: "Guideline null rule",
        type: "SERVICE_REQUEST_RULE",
        createdBy: "00u3ve6m0lRABeCyT4x7",
        searchableStatements: {
          patientAge: ["21"],
          patientGender: ["F"],
        },
        filters: [],
        versions: [
          {
            id: "61255bf71fa9f7c8655b5280",
            originalVersionId: "61255bf71fa9f7c8655b5280",
            documentVersion: 1,
            published: null,
            isCurrentDraft: true,
            lastUpdated: "2021-08-30T21:02:32.954Z",
            publishedBy: null,
          },
        ],
      };
    },
  });
  registerRoute({
    id: "getRuleLabelPriority",
    route: "/serviceRequestRuleLabelPriority",
    method: "get",
    generateResponse: function (config, schema, request) {
      return [
        {
          id: "60fec55194b075000114bdb6",
          dateCreated: "2021-07-26T14:23:13.067Z",
          isCurrentDraft: true,
          published: true,
          isCurrentPublished: true,
          updatedBy: "00ums8ykxLzD7zFvO4x6",
          documentVersion: 11,
          ruleLabelIds: [
            "60118eebbfae1300019ef3b7",
            "5fd79aca19fdbc0001a7a3dc",
            "5ff7a59c371cab0001e8845b",
            "60e8a32123692300015bd64e",
            "5fd79af4b4a18d0001d5286c",
            "5fd79b8219fdbc0001a7a3ec",
            "5fd79b9119fdbc0001a7a3ed",
            "604a9524768b3e000140f4f6",
            "5fd8d9006352e600013ec3ca",
            "5fd8d90c173ef6000182475f",
            "5fd8d91b6352e600013ec3cb",
            "60f1b915432d7d00014e7d5f",
            "60aea461032913000115c041",
          ],
          versionOfId: "60f1b90bc59b300001376ca7",
          publishedBy: "00ums8ykxLzD7zFvO4x6",
          originalVersionId: "5fd770f219fdbc0001a79d4c",
          lastUpdated: "2021-07-26T14:23:37.096Z",
          deleted: false,
          publishedAt: "2021-07-26T14:23:37.086Z",
          type: "SERVICE_REQUEST_RULE_LABEL_PRIORITY_TYPE",
          createdBy: "00ums8ykxLzD7zFvO4x6",
          ruleLabels: [
            {
              id: "60118eebbfae1300019ef3b7",
              dateCreated: "2021-01-27T16:03:55.993Z",
              updatedBy: "00uq4d86iQGZvyjiO4x6",
              name: "Humana Exclusions",
              lastUpdated: "2021-01-27T16:03:55.993Z",
              deleted: false,
              createdBy: "00uq4d86iQGZvyjiO4x6",
            },
            {
              id: "5fd79aca19fdbc0001a7a3dc",
              dateCreated: "2020-12-14T17:03:06.548Z",
              updatedBy: "00uq4d86iQGZvyjiO4x6",
              name: "Exclusions",
              lastUpdated: "2020-12-14T17:03:06.548Z",
              deleted: false,
              createdBy: "00uq4d86iQGZvyjiO4x6",
            },
            {
              id: "5ff7a59c371cab0001e8845b",
              dateCreated: "2021-01-08T00:21:48.889Z",
              updatedBy: "00uq4d86iQGZvyjiO4x6",
              name: "Retro Auto-Approved",
              lastUpdated: "2021-01-08T00:21:48.889Z",
              deleted: false,
              createdBy: "00uq4d86iQGZvyjiO4x6",
            },
            {
              id: "60e8a32123692300015bd64e",
              dateCreated: "2021-07-09T19:27:29.422Z",
              updatedBy: "00u399ojswKWTciRl4x7",
              name: "Billing Guideline",
              lastUpdated: "2021-07-09T19:27:29.422Z",
              deleted: false,
              createdBy: "00u399ojswKWTciRl4x7",
            },
            {
              id: "5fd79af4b4a18d0001d5286c",
              dateCreated: "2020-12-14T17:03:48.784Z",
              updatedBy: "00uq4d86iQGZvyjiO4x6",
              name: "Big Red Button",
              lastUpdated: "2020-12-14T17:03:48.784Z",
              deleted: false,
              createdBy: "00uq4d86iQGZvyjiO4x6",
            },
            {
              id: "5fd79b8219fdbc0001a7a3ec",
              dateCreated: "2020-12-14T17:06:10.074Z",
              updatedBy: "00uq4d86iQGZvyjiO4x6",
              name: "Plan Policy",
              lastUpdated: "2020-12-15T15:40:11.660Z",
              deleted: false,
              createdBy: "00uq4d86iQGZvyjiO4x6",
            },
            {
              id: "5fd79b9119fdbc0001a7a3ed",
              dateCreated: "2020-12-14T17:06:25.892Z",
              updatedBy: "00uq4d86iQGZvyjiO4x6",
              name: "Cohere Policy",
              lastUpdated: "2020-12-15T15:40:36.207Z",
              deleted: false,
              createdBy: "00uq4d86iQGZvyjiO4x6",
              description:
                "Clinical Program Policy (e.g., Surgical Site of Service)",
            },
            {
              id: "604a9524768b3e000140f4f6",
              dateCreated: "2021-03-11T22:09:40.907Z",
              updatedBy: "00uq4d86iQGZvyjiO4x6",
              name: "Cohere Policy (Secondary)",
              lastUpdated: "2021-03-11T22:09:40.907Z",
              deleted: false,
              createdBy: "00uq4d86iQGZvyjiO4x6",
            },
            {
              id: "5fd8d9006352e600013ec3ca",
              dateCreated: "2020-12-15T15:40:48.143Z",
              updatedBy: "00uq4d86iQGZvyjiO4x6",
              name: "Provider Tagging (Primary)",
              lastUpdated: "2020-12-15T15:40:48.143Z",
              deleted: false,
              createdBy: "00uq4d86iQGZvyjiO4x6",
            },
            {
              id: "5fd8d90c173ef6000182475f",
              dateCreated: "2020-12-15T15:41:00.320Z",
              updatedBy: "00uq4d86iQGZvyjiO4x6",
              name: "Big Red Button (Secondary)",
              lastUpdated: "2021-03-11T17:03:24.206Z",
              deleted: false,
              createdBy: "00uq4d86iQGZvyjiO4x6",
            },
            {
              id: "5fd8d91b6352e600013ec3cb",
              dateCreated: "2020-12-15T15:41:15.171Z",
              updatedBy: "00uq4d86iQGZvyjiO4x6",
              name: "Clinical Criteria",
              lastUpdated: "2020-12-15T15:41:15.171Z",
              deleted: false,
              createdBy: "00uq4d86iQGZvyjiO4x6",
            },
            {
              id: "60f1b915432d7d00014e7d5f",
              dateCreated: "2021-07-16T16:51:33.311Z",
              updatedBy: "00uq4d86iQGZvyjiO4x6",
              name: "Queue Management",
              lastUpdated: "2021-07-16T16:51:33.311Z",
              deleted: false,
              createdBy: "00uq4d86iQGZvyjiO4x6",
            },
            {
              id: "60aea461032913000115c041",
              dateCreated: "2021-05-26T19:41:21.631Z",
              updatedBy: "00uq4d86iQGZvyjiO4x6",
              name: "Post-Submission Nudge",
              lastUpdated: "2021-05-26T19:41:21.631Z",
              deleted: false,
              createdBy: "00uq4d86iQGZvyjiO4x6",
            },
          ],
        },
      ];
    },
  });
}
