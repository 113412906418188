import { UserClickInfoClinicalReviewPage } from "@coherehealth/common";
import {
  Note,
  NoteConfigurationResponse,
  ReviewType,
  ServiceRequestResponse,
  AuthorizationResponse,
  OutreachAttempt,
  NurseReview,
  OutOfNetworkReview,
  MdReview,
  PeerToPeerReview,
  AuditReview,
  NewHireAuditReview,
  OutOfScopeAuditReview,
  FaxAuditReview,
  OutreachType,
  OutreachOutcome,
  AppealResponse,
} from "@coherehealth/core-platform-api";
import { LeftPanelTabs } from "components/ClinicalReview/reviewUtils/utils";

import { Dispatch, SetStateAction } from "react";

export interface NotesProps {
  notes: Note[] | null;
  serviceRequest: ServiceRequestResponse | null | undefined;
  notesLoading: boolean;
  existingReviews?: ReviewType[] | null;
  existingClaimsPaymentNoteData?: Note[] | undefined;
  reviewClaimsPaymentNotes?: Note[] | undefined;
  view: "REVIEW" | "NOTES";
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
  noteConfig?: NoteConfigurationResponse;
  isInFilteredState?: boolean;
}

export type HistoryReview = (
  | NurseReview
  | OutOfNetworkReview
  | MdReview
  | PeerToPeerReview
  | AuditReview
  | NewHireAuditReview
  | OutOfScopeAuditReview
  | FaxAuditReview
) & {
  objType: "REVIEW";
  isNewServiceRequest?: boolean;
  serviceRequestId?: string;
  lastUpdated?: string | number | Date;
  srDateCreated?: string | number | Date;
};

export type HistoryNote = Note & {
  serviceRequest?: Partial<ServiceRequestResponse> | null | undefined;
  objType: "NOTES";
  isNewServiceRequest?: boolean;
  serviceRequestId?: string;
  lastUpdated?: string | number | Date;
  srDateCreated?: string | number | Date;
};

export type HistoryOutreach = OutreachAttempt & {
  objType: "CONTACT";
  isNewServiceRequest?: boolean;
  lastUpdated?: string | number | Date;
  dateCreated?: string | undefined;
  outreachType?: OutreachType;
  outreachOutcome?: OutreachOutcome;
  rawMessageTemplate?: string;
  serviceRequestId?: string;
  srDateCreated?: string | number | Date;
  outreachNotes?: string;
  attemptNumber?: number;
  integrationRetryCount?: number;
  manual?: boolean;
};

export type HistoryAppeal = AppealResponse & {
  objType: "APPEAL";
  serviceRequestId?: string;
  isNewServiceRequest?: boolean;
};

export type HistoryData = HistoryReview | HistoryNote | HistoryOutreach | HistoryAppeal;

export type NoteDisplay = {
  note?: Note;
  serviceRequest: ServiceRequestResponse | null | undefined;
  view: "REVIEW" | "NOTES";
  index: number | string;
  noteConfig?: NoteConfigurationResponse;
  hasContinuations?: boolean;
  isNewServiceRequest?: boolean;
  isInFilteredState?: boolean;
};

export interface ReviewTimelineNotesProps {
  allHistoryData: (HistoryData | undefined)[];
  loading: boolean;
  existingReviews?: ReviewType[] | null;
  noteConfig?: NoteConfigurationResponse;
  hasContinuations?: boolean;
  authorization?: AuthorizationResponse;
  serviceRequest: ServiceRequestResponse;
  setTabOpen?: React.Dispatch<React.SetStateAction<LeftPanelTabs>>;
}

export interface NoteProps {
  note: Note;
}
export const sortByDateCreatedDesc = (a: Note | undefined, b: Note | undefined): number => {
  if (!a?.dateCreated && !b?.dateCreated) {
    return 0;
  }
  if (!a?.dateCreated) {
    return 1;
  }
  if (!b?.dateCreated) {
    return -1;
  }
  const aDate = new Date(a?.dateCreated);
  const bDate = new Date(b?.dateCreated);
  if (aDate > bDate) {
    return -1;
  }
  if (aDate < bDate) {
    return 1;
  }
  return 0;
};
