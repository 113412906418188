import { useEffect } from "react";
import { error as logError } from "logger";
import { useFeature } from "@coherehealth/common";

/* SF documentation is annoying to read, so I will write comments to explain */

// Defining a global interface for the Salesforce chatbot, typescript doesn't like it if it isn't
declare global {
  interface Window {
    embeddedservice_bootstrap: any;
  }
}

// Defining the Salesforce environment variables, they are defined in the .env.development file
const orgId = process.env.REACT_APP_SALESFORCE_ORG_ID;
const domain = process.env.REACT_APP_SALESFORCE_DOMAIN;
const scrt2URL = process.env.REACT_APP_SALESFORCE_SCRT2_URL;
const scriptUrl = process.env.REACT_APP_SALESFORCE_SCRIPT;
const chatbotEnv = process.env.REACT_APP_SALESFORCE_CHATBOT_ENV;

export const useChatWidget = (): void => {
  const isSalesForceBotEnabled = useFeature("enableSalesForceBotForOnboarding");

  useEffect(() => {
    // Declare the script variable here so it's accessible in both the effect and cleanup function
    let script: HTMLScriptElement | null = null;

    // Function to initialize the Salesforce chatbot
    function initEmbeddedMessaging() {
      try {
        /* this initialization code is from the Salesforce documentation */
        window.embeddedservice_bootstrap.settings.language = "en_US"; // Set language
        window.embeddedservice_bootstrap.init(orgId, chatbotEnv, domain, {
          scrt2URL: scrt2URL,
        });
        /* Chatbot will automatically display upon initialization */
      } catch (err) {
        logError(err);
      }
    }

    if (isSalesForceBotEnabled) {
      // Dynamically load the Salesforce script and initialize the chatbot
      script = document.createElement("script");
      script.src = scriptUrl || "";
      script.onload = initEmbeddedMessaging;
      script.type = "text/javascript";
      document.body.appendChild(script);
    }

    // Cleanup function to remove the script when the component unmounts
    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [isSalesForceBotEnabled]); // Add the feature flag to the dependency array
};
