import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Caption } from "../Typography";

export type Variant = "info" | "primary" | "success" | "warning" | "error";
export type FontVariant = "caption" | "subtitle1";

export interface Props {
  label: string;
  variant: Variant;
  fontVariant?: FontVariant;
  className?: string;
  style?: React.CSSProperties;
  dataPublic?: boolean;
}

const useStyles = makeStyles((theme) => {
  const backgroundColors: Record<Variant, string> = {
    info: "#f5f5f5", // Gray / Neutral
    primary: "#e0f4fc",
    success: theme.palette.success.light,
    warning: theme.palette.warning.light,
    error: theme.palette.error.light,
  };
  const textColors: Record<Variant, string> = {
    info: theme.palette.text.secondary,
    primary: theme.palette.primary.main,
    success: theme.palette.success.dark,
    warning: theme.palette.warning.dark,
    error: theme.palette.error.dark,
  };

  return {
    highlightedCaption: {
      backgroundColor: ({ variant }: Omit<Props, "label">) => backgroundColors[variant],
      color: ({ variant }: Omit<Props, "label">) => textColors[variant],
      padding: theme.spacing(0.5),
      borderRadius: theme.spacing(0.5),
      whiteSpace: "nowrap",
    },
  };
});

/**
 * Like a chip, but smaller and just has a label, no click-ability
 */
export default function Pill({ label, variant, fontVariant, className: additionalClasses, style, dataPublic }: Props) {
  const classes = useStyles({ variant });
  const dataPublicProps = dataPublic ? { "data-public": true } : {};
  return (
    <Caption
      className={`${classes.highlightedCaption} ${additionalClasses} ${
        fontVariant === "subtitle1" ? "MuiTypography-subtitle1" : ""
      }`}
      style={style}
      {...dataPublicProps}
    >
      {label}
    </Caption>
  );
}
