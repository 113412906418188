import React, { SVGProps } from "react";

export default function ServiceRequestWarning(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 52C0 23.2812 23.2812 0 52 0C80.7188 0 104 23.2812 104 52C104 80.7188 80.7188 104 52 104C23.2812 104 0 80.7188 0 52Z"
        fill="black"
        opacity="0.04"
      />
      <path d="M72.2821 30.1274H29.9004V79.5581H72.2821V30.1274Z" fill="#A1AAB4" />
      <path d="M62.2878 33.1228H32.8994V76.5566H69.2667V40.1192L62.2878 33.1228Z" fill="white" />
      <path
        d="M60.6908 24.0065H55.0446C55.0446 21.5165 53.2736 19.5 51.0817 19.5C48.8899 19.5 47.1188 21.5165 47.1188 24.0065H41.4726L39.877 34.0013H62.2689L60.6908 24.0065Z"
        fill="#212936"
      />
      <path
        d="M44.0161 40.0172H41.0001V37.0012H38.931V40.0172H35.915V42.0863H38.931V45.1023H41.0001V42.0863H44.0161V40.0172Z"
        fill="#EA1D63"
      />
      <path d="M60.5159 48.4521H41.6484V49.8725H60.5159V48.4521Z" fill="#89D3DD" />
      <path d="M65.7764 71.1912H58.2539V72.6115H65.7764V71.1912Z" fill="#89D3DD" />
      <path d="M65.7761 53.6575H36.3877V55.0778H65.7761V53.6575Z" fill="#C2C2C2" />
      <path d="M65.7761 57.9177H36.3877V59.3381H65.7761V57.9177Z" fill="#C2C2C2" />
      <path d="M65.7761 62.1833H36.3877V63.6037H65.7761V62.1833Z" fill="#C2C2C2" />
      <path d="M49.5213 66.4409H36.3877V67.8613H49.5213V66.4409Z" fill="#C2C2C2" />
      <path d="M62.2871 40.1192H69.266L62.2871 33.1228V40.1192Z" fill="#F5F5F5" />
      <g clipPath="url(#clip0)">
        <ellipse cx="71.514" cy="35.1136" rx="15.6136" ry="15.6136" fill="white" />
        <path
          d="M71.514 19.5C62.8953 19.5 55.9004 26.4949 55.9004 35.1136C55.9004 43.7323 62.8953 50.7273 71.514 50.7273C80.1327 50.7273 87.1276 43.7323 87.1276 35.1136C87.1276 26.4949 80.1327 19.5 71.514 19.5ZM73.0754 42.9204H69.9527V39.7977H73.0754V42.9204ZM73.0754 36.675H69.9527V27.3068H73.0754V36.675Z"
          fill="#F83275"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="31.2273" height="31.2273" fill="white" transform="translate(55.9004 19.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
