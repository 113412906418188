import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { parse } from "qs";

export default function usePostOnboardingRedirectUrl(): { returnTo: string; canSkipSplash: boolean } {
  const location = useLocation();
  return useMemo(() => {
    const searchWithoutLeadingQuestionMark = location.search.replace(/^[?]/, "");
    const { returnTo, skipSplash } = parse(searchWithoutLeadingQuestionMark);
    const canSkipSplash = skipSplash === "true";

    // Because qs can parse complicated parameter types, and this only works when it's a simple string
    if (typeof returnTo === "string") {
      return { returnTo, canSkipSplash };
    } else {
      return { returnTo: "", canSkipSplash };
    }
  }, [location.search]);
}
