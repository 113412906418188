import { useCallback, useRef, useMemo, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";
import lodashDebounce from "lodash/debounce";
import { store } from "./store";
import { environmentAbbreviation } from "./envUtils";

function debounce<T extends (...any: any) => any>(func: T, wait: number | undefined) {
  return lodashDebounce(func, wait, { leading: true, trailing: false });
}

export function useGetOktaTokenWithoutRefresh(): string | undefined {
  const auth = useOktaAuth();
  if (auth?.authState?.isAuthenticated) {
    const token = auth.oktaAuth?.getAccessToken();
    const tokenClaim = auth.authState?.accessToken;
    /* If a user is using multiple tabs, gets inactive and logged out
     * then it will be redirected to the Okta login. However the tokens
     * from the rest of the open tabs are going to be staled and will
     * need to be refreshed because those will case that the backend
     * calls get rejected without redirecting the end user to the Okta login.
     */
    if (tokenClaim && !auth.oktaAuth?.tokenManager?.hasExpired(tokenClaim)) {
      return token;
    }
  }
}

// Can turn on debug logging by setting a local storage value, and turn it off by removing
const SHOULD_LOG_DEBUG = Boolean(store.get("__cohere_common_access_token_debug") ?? false);
const debugLog: typeof console.debug = (...args) => {
  if (SHOULD_LOG_DEBUG) {
    console.debug("cohereCommonAcessToken:", ...args);
  }
};

/*
 Use a mixed strategy for keeping track of activity:
 * Record key/mouse events in-tab (debounce but on a small time scale)
   * Without debouncing, every little mouse movement would fire tons of events - hard to debug
   * With a long period, an expired access token might get used for a long time, leading to confusion
 * Store them in local storage (debounce on a longer time scale)
   * Without longer time frame, local storage activity is really hard to debug and reason about
   * Activity in a separate tab doesn't need to be recorded constantly for session liveness - just <<< timeout
 */
const ACTIVITY_TRACK_DEBOUNCE_MS = 0.5 * 1000; // half second
const ACTIVITY_STORE_DEBOUNCE_MS = 60 * 1000; // sixty seconds

const ACTIVITY_TIMEOUT_MS = ((): number => {
  // In some environments, optionally can override the default for testing.
  // Don't allow this in prod-like environments.
  if (["local", "dev", "uat"].includes(environmentAbbreviation())) {
    const overrideActivityTimeout = Number(store.get("__cohere_common_access_token_activity_timeout_ms") ?? 0);
    if (overrideActivityTimeout) {
      return overrideActivityTimeout;
    }
  }

  // Default: used no matter what in all prod-like environments
  return 60 * 60 * 1000; // 60 minutes: could be shortened to 10m, but rolling this out with an excessive timeout to reduce risk
})();
debugLog(`Activity timeout set to ${ACTIVITY_TIMEOUT_MS} ms`);

/*
 * useGetAccessToken - gets access token async, replacing autoRenew behavior.
 * https://github.com/okta/okta-auth-js/blob/64a548b9f0037887d1bf8908a76bf9f3848ee0d6/docs/autoRenew-notice.md
 * explains that autoRenew does not work as well as it should and recommends getting tokens with renew as needed.
 * However, some of our pages automatically poll certain endpoints, and those should not keep the session alive.
 *
 * The returned function will get access token with renewal, but will _not_ renew if there's been no user activity
 * on the page, like mouse or keyboard activity.
 */
export function useGetAccessToken(oktaAuth: OktaAuth): () => Promise<string | undefined> {
  // Keep track of activity x-tab,
  // where activity is saved into storage periodically, and a local ref, periodically.
  // The ref is just here to avoid having to use the browser storage API as frequently; it's an optimization
  const lastActivityRef = useRef<number>(getNowTimestamp());

  const debouncedUpdateActivity = useMemo(() => {
    const updateActivity = (event?: Event) => {
      const eventType = event ? event.type : "page load";
      const newTimestamp = getNowTimestamp();
      lastActivityRef.current = newTimestamp;
      setLastActivityFromStorage(newTimestamp);
      debugLog("Update activity tracking", newTimestamp, eventType);
    };
    return debounce(updateActivity, ACTIVITY_TRACK_DEBOUNCE_MS);
  }, []);
  useEffect(() => {
    // On page load, set activity timestamp, and for any interaction
    debouncedUpdateActivity();

    const activityEvents = ["mousemove", "mousedown", "mouseup", "click", "dblclick", "keydown", "keypress", "keyup"];
    activityEvents.forEach((eventName) => {
      window.addEventListener(eventName, debouncedUpdateActivity);
    });
    return () => {
      activityEvents.forEach((eventName) => {
        window.removeEventListener(eventName, debouncedUpdateActivity);
      });
    };
  }, [debouncedUpdateActivity]);

  const getAccessToken = useCallback(async () => {
    const nowTimestamp = getNowTimestamp();
    let activityIsCurrent = false;
    if (nowTimestamp - lastActivityRef.current < ACTIVITY_TIMEOUT_MS) {
      activityIsCurrent = true;
    } else if (nowTimestamp - getLastActivityFromStorage() < ACTIVITY_TIMEOUT_MS) {
      debugLog("Activity timestamp current from storage (cross tab), not locally");
      activityIsCurrent = true;
    } else {
      debugLog("No valid last activity timestamp was found");
    }
    if (activityIsCurrent) {
      const accessToken = await oktaAuth.getOrRenewAccessToken();
      return accessToken ?? undefined;
    } else {
      // Get access token without renewing - mostly this should actually return nothing and fail
      debugLog("Activity is not current: just returning (possibly stale?) access token");
      const tokens = oktaAuth.tokenManager.getTokensSync();
      const accessTokenClaim = tokens?.accessToken;
      if (accessTokenClaim) {
        if (oktaAuth.tokenManager.hasExpired(accessTokenClaim)) {
          debugLog("Access token is stale, but user has not interacted with page: not returning access token");
          throw new Error("Expired token");
        }
        return accessTokenClaim.accessToken;
      }
      return undefined;
    }
  }, [oktaAuth]);

  return getAccessToken;
}

function getNowTimestamp(): number {
  return new Date().valueOf();
}

const ACTIVITY_TIMESTAMP_KEY = "last_page_activity";
function getLastActivityFromStorage(): number {
  return store.get(ACTIVITY_TIMESTAMP_KEY) ?? 0;
}
function _setLastActivityFromStorage(ipt: number): void {
  store.set(ACTIVITY_TIMESTAMP_KEY, ipt);
}
const setLastActivityFromStorage = debounce(_setLastActivityFromStorage, ACTIVITY_STORE_DEBOUNCE_MS);
