import { useAuthorized } from "authorization";

export const useClinicalReviewPageUserAuth = () => {
  const canUseClinicalReviewRedesign = useAuthorized("USE_CLINICAL_REVIEW_REDESIGN_GENERAL");
  const canUseOutreachPage = useAuthorized("ACCESS_LOG_OUTREACH_PAGE");
  const viewReview = useAuthorized("VIEW_REVIEW");
  const canUseMissingInfoPage = useAuthorized("MISSING_INFO");
  const canEditMdReview = useAuthorized("EDIT_MD_REVIEW");
  const canEditPeerToPeerReview = useAuthorized("EDIT_P2P_REVIEW");
  const canEditFinalizedMDReview = useAuthorized("EDIT_FINALIZED_MD_REVIEW");
  const canSubmitDenial = useAuthorized("SUBMIT_DENIAL");
  const canUseNewDenialsWorkflowBeta = useAuthorized("USE_NEW_DENIALS_WORKFLOW");
  const addNoteAuthorized = useAuthorized("EDIT_NOTES");
  const viewNoteAuthorized = useAuthorized("VIEW_NOTES");
  const userCanAuditReview = useAuthorized("AUDIT_REVIEW");

  return {
    canUseClinicalReviewRedesign,
    canUseOutreachPage,
    viewReview,
    canUseMissingInfoPage,
    canEditMdReview,
    canEditPeerToPeerReview,
    canEditFinalizedMDReview,
    canSubmitDenial,
    canUseNewDenialsWorkflowBeta,
    addNoteAuthorized,
    viewNoteAuthorized,
    userCanAuditReview,
  };
};
