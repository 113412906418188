import React, { PropsWithChildren } from "react";
import { CommonProvider, FeatureFlagsProvider } from "@coherehealth/common";
import config from "api/config";
import { SnackbarProvider } from "notistack";
import { HelmetProvider } from "react-helmet-async";
import { error as logError, scrubUrl, warn as logWarning } from "logger";
import MonitoringRestfulProvider from "MonitoringRestfulProvider";
import { RestfulReactProviderProps } from "restful-react";

// HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
// eslint-disable-next-line @typescript-eslint/ban-types
export default function UnauthenticatedProviders({ children }: PropsWithChildren<{}>) {
  return <Providers>{children}</Providers>;
}

const onError: NonNullable<RestfulReactProviderProps["onError"]> = (error, retry, response) => {
  if (response && error) {
    // The fetch successfully executed, but the server returned us a failure
    const { message, data, status } = error;
    const { url } = response;

    // The reason for splitting these into multiple lines is that it causes
    // different stack traces, and different stack traces cause different errors
    // in sentry. This is sort of a sentry hack.
    if (status === 403) {
      // 403 should never happen for logged-in users: this suggests a bug
      logError(new Error(`HTTP status ${status} from ${scrubUrl(url)}: [${message}] [${JSON.stringify(data)}]`));
    } else if (status === 404) {
      // 404 _may_ happen but may indicate an application issue
      logWarning(`HTTP status ${status} from ${scrubUrl(url)}: [${message}] [${JSON.stringify(data)}]`);
    } else if (status === 500) {
      logError(new Error(`HTTP status ${status} from ${scrubUrl(url)}: [${message}] [${JSON.stringify(data)}]`));
    } else {
      // All other errors can group together
      logError(new Error(`HTTP status ${status} from ${scrubUrl(url)}: [${message}] [${JSON.stringify(data)}]`));
    }
  } else {
    // The fetch call itself failed: this will happen if the network is unavailable
    // or if the URL fails to resolve, etc.
    // Generally, these _wouldn't_ be coding errors, but instead
    // network unavailability or connection reset, etc
    logWarning(`error executing fetch: [${JSON.stringify(error)}]`);
  }
};

// HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
// eslint-disable-next-line @typescript-eslint/ban-types
const Providers = ({ children }: PropsWithChildren<{}>) => (
  <MonitoringRestfulProvider onError={onError} base={config.SERVICE_API_URL}>
    <CommonProvider>
      <FeatureFlagsProvider fetchSimpleFlags={() => fetch(`${config.SERVICE_API_URL}simpleFeatureFlag`)}>
        <SnackbarProvider>
          <HelmetProvider>{children}</HelmetProvider>
        </SnackbarProvider>
      </FeatureFlagsProvider>
    </CommonProvider>
  </MonitoringRestfulProvider>
);
