import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { formatDateToISODate, InformativeModal } from "@coherehealth/common";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { useTheme } from "@material-ui/core/styles";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { PriorAuthRequirements } from "../common";
import {
  Patient,
  ServiceRequestResponse,
  AuthBuilderWorkflowStep,
  useCreatePharmacyServiceRequest,
} from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import { formContentFromResponse } from "../../../util/serviceRequest";
import { useGetReturnToUrl } from "util/queryParams";
import Footer from "../Footer";

interface Props {
  setAttemptedGatherRequirementsSubmit: Dispatch<SetStateAction<boolean>>;
  serviceRequestFormContents: ServiceRequestFormContent[];
  priorAuthRequirements: PriorAuthRequirements;
  workflowId?: string;
  patient?: Patient;
  setWorkflowStep: (step: AuthBuilderWorkflowStep) => void;
  addServiceRequest: (sr: ServiceRequestResponse) => void;
  setServiceRequestFormContents: Dispatch<SetStateAction<ServiceRequestFormContent[]>>;
  setFooterHeight: Dispatch<SetStateAction<number>>;
}
export default function GatherRequirementsPharmacyFooter({
  setAttemptedGatherRequirementsSubmit,
  serviceRequestFormContents,
  priorAuthRequirements,
  workflowId,
  patient,
  setWorkflowStep,
  addServiceRequest,
  setServiceRequestFormContents,
  setFooterHeight,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const canBeSubmitted: boolean =
    !!serviceRequestFormContents &&
    !!serviceRequestFormContents[0] &&
    !!serviceRequestFormContents[0].drugSelection &&
    !!serviceRequestFormContents[0].drugSelection.drug &&
    !!serviceRequestFormContents[0].drugSelection.strengthId &&
    !!serviceRequestFormContents[0].drugSelection.days &&
    !!serviceRequestFormContents[0].drugSelection.quantity &&
    !!priorAuthRequirements.primaryDiagnosis &&
    !!serviceRequestFormContents[0].orderingProvider;

  const {
    mutate: createPharmacyServiceRequest,
    loading: createPharmacyServiceRequestLoading,
    error: createPharmacyServiceRequestError,
  } = useCreatePharmacyServiceRequest({});

  useEffect(() => {
    if (createPharmacyServiceRequestError) {
      enqueueSnackbar("Failed to create service request, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [createPharmacyServiceRequestError, enqueueSnackbar]);

  const onContinue = async () => {
    setAttemptedGatherRequirementsSubmit(true);
    try {
      const response = await createPharmacyServiceRequest({
        startDate: formatDateToISODate(new Date()),
        primarySemanticDiagnosisCode: serviceRequestFormContents[0].primaryDiagnosisCode || undefined,
        secondarySemanticDiagnosisCodes: serviceRequestFormContents[0].secondaryDiagnosisCodes || undefined,
        orderingProvider: serviceRequestFormContents[0].orderingProvider || undefined,
        prescribedDrug: serviceRequestFormContents[0].drugSelection?.drug
          ? {
              ...serviceRequestFormContents[0].drugSelection?.drug,
              ...serviceRequestFormContents[0].drugSelection?.strengthDetails,
              drugDescription: serviceRequestFormContents[0].drugSelection?.strengthDetails?.dosageLabel,
              daysSupply: serviceRequestFormContents[0].drugSelection?.days,
              quantity: serviceRequestFormContents[0].drugSelection?.quantity,
              genericAllowed: serviceRequestFormContents[0].drugSelection?.genericAllowed,
            }
          : undefined,
        semanticProcedureCodes: serviceRequestFormContents[0].procedureCodes || undefined,
        patient: patient,
        authStatus: "DRAFT",
        workflowId,
        urgency: {
          isExpedited: serviceRequestFormContents[0].isExpedited,
        },
      });

      if (response) {
        addServiceRequest(response);
        setServiceRequestFormContents((prev) => [{ ...prev[0], ...formContentFromResponse(response) }]);
        setWorkflowStep("ADD_ATTACHMENTS");
      }
    } catch (e) {
      enqueueSnackbar("Failed to save service request", { variant: "error" });
    }
  };

  const returnToUrl = useGetReturnToUrl();

  return (
    <>
      <Footer
        onPrimaryButtonClick={onContinue}
        primaryButtonDisabled={!canBeSubmitted || createPharmacyServiceRequestLoading}
        primaryButtonLoading={createPharmacyServiceRequestLoading}
        primaryButtonText={"Continue"}
        showSaveAndExitButton={false}
        secondaryButtonText={"Cancel"}
        onSecondaryButtonClick={() => setCancelModalOpen(true)}
        showSecondaryButton={true}
        showError={false}
        setFooterHeight={setFooterHeight}
      />
      <InformativeModal
        open={cancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        icon={<HelpOutlineIcon style={{ color: theme.palette.warning.dark }} />}
        headerText="Are you sure you want to cancel this authorization?"
        subHeaderText="Your progress will not be saved."
        primaryButtonText="Yes, cancel"
        primaryButtonRoute={returnToUrl}
        tertiaryButtonText="Continue with authorization"
        tertiaryButtonAction={() => setCancelModalOpen(false)}
      />
    </>
  );
}
