import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Alert, Body3, Chip, InlineButton, Caption, colorsLight } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import { NonCohereCodes } from "components/AuthBuilder/common";
import { ProcedureCode } from "@coherehealth/core-platform-api";
import GetAppIcon from "@material-ui/icons/GetApp";
import { AlertTitle } from "@material-ui/lab";
import { trackUserActivity } from "util/userActivityTracker";

interface Props {
  nonPalProcedureCodes?: ProcedureCode[];
  nonCohereProcedureCodes?: NonCohereCodes[];
  message: string;
  setNonPalPDFOpen: Dispatch<SetStateAction<boolean>>;
}

export default function ProcedureCodeWarning({
  nonPalProcedureCodes,
  nonCohereProcedureCodes,
  setNonPalPDFOpen,
  message,
}: Props) {
  const classes = useStyles();
  const nonPalNotCohere = nonPalProcedureCodes && nonCohereProcedureCodes === undefined;

  // Pendo tracking (not inhouse analytics)
  useEffect(() => {
    if (nonPalProcedureCodes) {
      trackUserActivity("display pal alert");
    }
  }, [nonPalProcedureCodes]);
  return (
    <PaddedAlert severity="warning">
      <AlertTitle style={{ fontWeight: "bold" }}>{message}</AlertTitle>
      <div className={classes.card}>
        {nonPalNotCohere ? (
          <>
            <div className={classes.chipContainer}>
              {nonPalProcedureCodes &&
                nonPalProcedureCodes?.length > 0 &&
                nonPalProcedureCodes?.map((code) => (
                  <div className={classes.chipAndLabel} key={code.code}>
                    <Chip
                      label={code?.code}
                      type="disabled"
                      size="small"
                      style={{ backgroundColor: "rgba(0,0,0,0.04)" }}
                    />
                    <div className={classes.pxCodeDescription}>
                      <Caption className={classes.caption}>{code?.description}</Caption>
                    </div>
                  </div>
                ))}
            </div>
            <div className={classes.button}>
              <InlineButton startIcon={<GetAppIcon />} onClick={() => setNonPalPDFOpen(true)}>
                Download PDF
              </InlineButton>
            </div>
          </>
        ) : (
          <div>
            {nonCohereProcedureCodes &&
              nonCohereProcedureCodes.map((codesAndVendor, index) => (
                <div className={classes.vendorChipAndLabel} key={codesAndVendor.authSubmissionVendor}>
                  <div className={index === 0 ? classes.vendorLabel : classes.chipAndVendor}>
                    <Body3>
                      {codesAndVendor?.authSubmissionVendor === "Unknown Auth Submission Vendor"
                        ? "Organization Unknown"
                        : codesAndVendor?.authSubmissionVendor}
                    </Body3>
                  </div>
                  <div>
                    {codesAndVendor?.procedureCode.map((code) => (
                      <div className={classes.chipAndLabel} key={code.code}>
                        <Chip
                          label={code?.code}
                          type="disabled"
                          size="small"
                          style={{ backgroundColor: "rgba(0,0,0,0.04)" }}
                        />
                        <div className={classes.pxCodeDescription}>
                          <Caption className={classes.caption}>{code?.description}</Caption>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </PaddedAlert>
  );
}

/*
    This padding overrides the Design System's defaults because the alert's
    'subtext' are buttons, which take up lots of space and would look bad
    without extra padding
 */
// eslint-disable-next-line cohere-react/no-mui-styled-import
const PaddedAlert = styled(Alert)(({ theme }) => ({
  paddingLeft: "14px",
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingTop: "10px",
  "& .MuiAlert-icon": {
    marginRight: "14px",
    "& .MuiSvgIcon-root": {
      fontSize: "28.8px",
      marginTop: "4px",
    },
  },
  "& .MuiAlert-message": {
    width: "100%",
    paddingBottom: "0px",
  },
}));

const useStyles = makeStyles((theme) => ({
  chipContainer: {
    display: "flex",
    flexDirection: "column",
    width: "88%",
  },
  chipAndLabel: {
    display: "flex",
    paddingTop: theme.spacing(1),
  },
  caption: {
    marginLeft: theme.spacing(1),
    color: colorsLight.font.secondary,
  },
  pxCodeDescription: {
    minHeight: "24px",
    alignItems: "center",
    maxWidth: "86.4%",
    display: "flex",
  },
  button: {
    display: "flex",
    minInlineSize: "fit-content",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: "27px",
  },
  card: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    minWidth: "100%",
  },
  vendorChipAndLabel: {
    alignItems: "baseline",
    display: "flex",
    flexDirection: "column",
  },
  chipAndVendor: {
    paddingTop: "14px",
    alignItems: "baseline",
    color: colorsLight.font.secondary,
  },
  vendorLabel: {
    paddingTop: theme.spacing(1),
    alignItems: "baseline",
    color: colorsLight.font.secondary,
  },
}));
