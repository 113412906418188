import React, { createContext, ReactNode } from "react";

interface PotentialDuplicateContextProviderProps {
  children: ReactNode;
  workflowId?: string | null;
}

const PotentialDuplicateContext = createContext<string | null>(null);

const PotentialDuplicateContextProvider: React.FC<PotentialDuplicateContextProviderProps> = ({
  children,
  workflowId,
}) => {
  return <PotentialDuplicateContext.Provider value={workflowId || null}>{children}</PotentialDuplicateContext.Provider>;
};

export { PotentialDuplicateContext, PotentialDuplicateContextProvider };
