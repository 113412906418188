import { Dispatch, SetStateAction, useState, useCallback, useEffect, useContext } from "react";
import ProvidersCard from "../FillFormsGeneralAuthSubmission/ProvidersCard";
import { Container, Box } from "@material-ui/core";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { AuthStatus, Patient, useGetPlaceOfServices } from "@coherehealth/core-platform-api";
import {
  ServiceRequestFieldName,
  useServiceRequestConfigSpec,
} from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import useGetFacilityBasedRequestConfigurationByPayer, {
  useGetCareParticipantsConfigByHealthPlan,
} from "hooks/useGetFeatureConfigurations";
import { useServiceRequestFormRedirectDispatch } from "components/ServiceRequest/ServiceRequestForm/ServiceRequestFormRedirectModal";
import { useFeature, useConfiguration, useMuiContainerStyles } from "@coherehealth/common";
import { PriorAuthRequirements, getSortedClinicalServices } from "../common";
import listReplace from "util/listReplace";
import { useAuthorized } from "authorization";
import RequestorCard, { Props as RequestorProps } from "components/Requestor/RequestorCard";
import {
  isAnyCareParticipantOONExceptionRequired,
  isOonProviderOrFacility,
  isUnknownNetworkType,
  validateCareParticipants,
  validateOONExceptionCommentFieldForCareParticipants,
  validateOONExceptionReasonFieldForCareParticipants,
} from "util/serviceRequest";
import { isEmpty } from "lodash";
import {
  validatePerformingProviderAddress,
  validatePerformingProviderNPI,
  validatePerformingProviderTin,
} from "../validationUtil";
import { isExceptionCommentRequired } from "util/providerUtils";
import { ScrubTinsContext } from "../../ScrubTinsContext";
import { useLocation } from "react-router";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";
import { fieldIsValid } from "common/FormConfigUtils";
import { error as logError } from "logger";

interface Props extends RequestorProps {
  attemptedSubmit: boolean;
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  authStatus: AuthStatus;
  patient?: Patient | null;
  workflowId?: string;
  delegatedVendorName?: string;
  onPatientSummary?: boolean;
  isContinuation?: boolean;
  patientHealthPlanName: string;
  userFaxExtension?: string;
  priorAuthRequirements?: PriorAuthRequirements;
  requestorProps?: RequestorProps;
  showRequestorCard?: boolean;
  setPriorAuthRequirements?: Dispatch<SetStateAction<PriorAuthRequirements>>;
  setProviderDetailsFormsCanBeSubmitted: Dispatch<SetStateAction<boolean[]>>;
  setPerformingProviderExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  performingProviderExceptionRequest?: boolean;
  setFacilityExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  setPerformingProviderPracticeExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  facilityExceptionRequest?: boolean;
  performingProviderPracticeExceptionRequest?: boolean;
  orderingProviderExceptionRequest?: boolean;
  setOrderingProviderExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  setCareParticipantExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  careParticipantExceptionRequest?: boolean;
  onProviderDetailsPage?: boolean;
  setFacilityOonCheckLoading?: Dispatch<SetStateAction<boolean>>;
  setPerformingProviderPracticeOonCheckLoading?: Dispatch<SetStateAction<boolean>>;
  setProviderOonCheckLoading?: Dispatch<SetStateAction<boolean>>;
  setOrderingProviderOonCheckLoading?: Dispatch<SetStateAction<boolean>>;
  setAdditionalCareOonCheckLoading?: Dispatch<SetStateAction<boolean>>;
}

export default function ProviderDetailsContainer({
  attemptedSubmit,
  formContent,
  setFormContent,
  authStatus,
  patient,
  workflowId,
  delegatedVendorName,
  patientHealthPlanName,
  userFaxExtension,
  requestorProps,
  showRequestorCard,
  setProviderDetailsFormsCanBeSubmitted,
  setPriorAuthRequirements,
  setPerformingProviderExceptionRequest,
  performingProviderExceptionRequest,
  setFacilityExceptionRequest,
  facilityExceptionRequest,
  setOrderingProviderExceptionRequest,
  orderingProviderExceptionRequest,
  setCareParticipantExceptionRequest,
  careParticipantExceptionRequest,
  onProviderDetailsPage,
  setFacilityOonCheckLoading,
  setPerformingProviderPracticeOonCheckLoading,
  setProviderOonCheckLoading,
  setOrderingProviderOonCheckLoading,
  setAdditionalCareOonCheckLoading,
  performingProviderPracticeExceptionRequest,
  setPerformingProviderPracticeExceptionRequest,
}: Props) {
  const [sameProviders, setSameProviders] = useState(false);
  const { formFieldConfigurations, outOfNetworkCheckConfiguration } = useServiceRequestConfigSpec({
    ...formContent,
    patientId: patient?.id || "",
    healthPlanName: patientHealthPlanName,
  });

  const { scrubTinsEnabled: hideTinField } = useContext(ScrubTinsContext);

  const formConfiguration = formFieldConfigurations;

  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName: patientHealthPlanName,
    encounterType: formContent?.isInpatient ? "INPATIENT" : "OUTPATIENT",
    skipRequestTimingCheck: true,
  });

  const tinConfiguration = useConfiguration("tinConfiguration", patientHealthPlanName) ?? {};
  const userTinValidation = tinConfiguration?.userTinValidation;

  const authCategoryClinicalService = formContent?.clinicalServices?.find((clinicalService) =>
    Boolean(clinicalService.authCategory)
  );

  const clinicalServiceId = authCategoryClinicalService
    ? authCategoryClinicalService.id
    : formContent?.clinicalServices && formContent?.clinicalServices.length > 0
    ? getSortedClinicalServices(formContent.clinicalServices)[0].id
    : undefined;

  const { data: placeOfServiceData, loading: placeOfServiceLoading } = useGetPlaceOfServices({
    queryParams: {
      healthPlanName: patientHealthPlanName || undefined,
      encounterType: formContent?.isInpatient ? "INPATIENT" : "OUTPATIENT",
      clinicalServiceId: clinicalServiceId || undefined,
    },
  });

  const { dispatchRedirectRuleCheck } = useServiceRequestFormRedirectDispatch({
    workflowId,
    formContent,
    clinicalService: formContent?.clinicalServices?.[0],
    healthPlanName: patientHealthPlanName,
    patient,
  });

  const requiresFacilityOONExceptionComment =
    formContent?.selectedFacility?.selectedLocation?.isOutOfNetwork &&
    formContent?.selectedFacility?.selectedLocation?.outOfNetworkExceptionReason === "Other (comment is required)";

  const requiresPerformingProviderOONExceptionComment =
    formContent?.selectedPerformingProvider?.selectedLocation?.isOutOfNetwork &&
    formContent?.selectedPerformingProvider?.selectedLocation?.outOfNetworkExceptionReason ===
      "Other (comment is required)";

  const requiresPerformingProviderPracticeOONExceptionComment =
    formContent?.selectedPerformingProviderPractice?.selectedLocation?.isOutOfNetwork &&
    formContent?.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionReason ===
      "Other (comment is required)";

  const requiresOrderingProviderOONExceptionComment = isExceptionCommentRequired(formContent);

  const hasFacilityOONExceptionComment = formContent?.selectedFacility?.selectedLocation?.outOfNetworkExceptionComment;

  const hasPerformingProviderOONExceptionComment =
    formContent?.selectedPerformingProvider?.selectedLocation?.outOfNetworkExceptionComment;

  const hasPerformingProviderPracticeOONExceptionComment =
    formContent?.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionComment;

  const hasOrderingProviderOONExceptionComment =
    formContent?.selectedOrderingProvider?.selectedLocation?.outOfNetworkExceptionComment;

  const skipManualNpiTin = useFeature("skipManualNpiTin");
  const canSkipManualNpiTin = useAuthorized("CAN_SKIP_MANUAL_NPI_TIN");
  const isGeisingerPatient = patientHealthPlanName === "Geisinger";
  const canSkipValidationForNpiTin = skipManualNpiTin && isGeisingerPatient && canSkipManualNpiTin;

  const hasPlaceOfService = Boolean(formContent?.placeOfService);
  const hasOrderingProvider = Boolean(formContent?.orderingProvider);
  const hasOrderingProviderTin =
    formContent?.orderingProvider?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.orderingProviderSelectedTin
      ? canSkipValidationForNpiTin
      : Boolean(formContent?.orderingProviderSelectedTin);
  const hasPerformingProviderPractice = Boolean(formContent?.selectedPerformingProviderPractice);
  const hasPerformingProviderPracticeTIN =
    formContent?.selectedPerformingProviderPractice?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.selectedPerformingProviderPractice?.selectedLocation?.tin
      ? canSkipValidationForNpiTin
      : Boolean(formContent?.selectedPerformingProviderPractice?.selectedLocation?.tin);

  const hasPerformingProviderPracticeAddress = !isEmpty(
    formContent?.selectedPerformingProviderPractice?.selectedLocation?.address
  );
  const hasPerformingProviderPracticeNpi =
    formContent?.selectedPerformingProviderPractice?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.selectedPerformingProviderPractice?.npi
      ? canSkipValidationForNpiTin
      : Boolean(formContent?.selectedPerformingProviderPractice?.npi);

  const hasOrderingProviderNpi =
    formContent?.orderingProvider?.manuallyCreated && canSkipValidationForNpiTin && !formContent?.orderingProvider?.npi
      ? canSkipValidationForNpiTin
      : Boolean(formContent?.orderingProvider?.npi);
  const hasOrderingProviderAddress = !isEmpty(formContent?.selectedOrderingProvider?.selectedLocation?.address);
  const hasPerformingProvider = Boolean(formContent?.performingProvider);
  const hasPerformingProviderNpi =
    formContent?.performingProvider?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.performingProvider?.npi
      ? canSkipValidationForNpiTin
      : Boolean(formContent?.performingProvider?.npi);
  const performingProviderTinLength = formContent?.performingProvider?.tinList?.length;
  const shouldValidatePerformingProviderTin = formConfiguration.performingProviderTIN.fieldSpec === "REQUIRED";
  const hasPerformingProviderTin =
    formContent?.performingProvider?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.selectedPerformingProvider?.selectedLocation?.tin
      ? canSkipValidationForNpiTin
      : Boolean(formContent?.selectedPerformingProvider?.selectedLocation?.tin);
  const hasFacility = Boolean(formContent?.facility);
  const hasFacilityNpi =
    formContent?.facility?.manuallyCreated && canSkipValidationForNpiTin && !formContent?.facility?.npi
      ? canSkipValidationForNpiTin
      : Boolean(formContent?.facility?.npi);
  const hasFacilityTin =
    formContent?.facility?.manuallyCreated &&
    canSkipValidationForNpiTin &&
    !formContent?.selectedFacility?.selectedLocation?.tin
      ? canSkipValidationForNpiTin
      : Boolean(formContent?.selectedFacility?.selectedLocation?.tin);
  const hasFacilityAddress = !isEmpty(formContent?.selectedFacility?.selectedLocation?.address);
  const hasPerformingProviderOONOrUnknown = Boolean(
    isOonProviderOrFacility(formContent.performingProviderSelectedAddress) ||
      isUnknownNetworkType(formContent.performingProviderSelectedAddress?.networkType)
  );
  const hasPerformingProviderPracticeOONOrUnknown = Boolean(
    isOonProviderOrFacility(formContent.selectedPerformingProviderPractice?.selectedLocation) ||
      isUnknownNetworkType(formContent.selectedPerformingProviderPractice?.selectedLocation?.networkType)
  );
  const hasFacilityOONOrUnknown = Boolean(
    isOonProviderOrFacility(formContent.facilitySelectedAddress) ||
      isUnknownNetworkType(formContent.facilitySelectedAddress?.networkType)
  );
  const hasOrderingProviderOONOrUnknown = Boolean(
    isOonProviderOrFacility(formContent.orderingProviderSelectedAddress) ||
      isUnknownNetworkType(formContent.orderingProviderSelectedAddress?.networkType)
  );
  const hasOrderingProviderExceptionRequestOnContinuation = formContent?.orderingProviderSelectedAddress?.isOutOfNetwork
    ? orderingProviderExceptionRequest
    : false;
  const hasFollowUpFaxNumber = Boolean(formContent?.followUpFaxNumber?.number);
  const followUpFaxNumberHasAcceptableDigits =
    !hasFollowUpFaxNumber || formContent?.followUpFaxNumber?.number?.length === 10;
  const hasAuthCategory = Boolean(formContent?.authCategory);
  const hasAuthSubcategory = Boolean(formContent?.authSubcategory);
  const hasSubCategories = formContent?.authCategory?.subcategories?.length
    ? formContent?.authCategory?.subcategories?.length > 0
    : false;
  const checkAuthSubCategory = hasSubCategories ? hasAuthSubcategory : true;
  const hasPerformingProviderAddress = !isEmpty(formContent?.selectedPerformingProvider?.selectedLocation?.address);
  const { careParticipantEnabled } = useGetCareParticipantsConfigByHealthPlan({
    healthPlanName: patientHealthPlanName,
  });

  const isCareParticipantsValid = validateCareParticipants(
    careParticipantEnabled,
    formContent?.additionalCareParticipants
  );

  const setCanBeSubmitted = useCallback(
    (canBeSubmitted: boolean) => setProviderDetailsFormsCanBeSubmitted((prev) => listReplace(prev, 0, canBeSubmitted)),
    [setProviderDetailsFormsCanBeSubmitted]
  );

  //Validating fields that are displayed on this page and other fields will be validated by serviceDetails
  const VALIDATION_MAP: Record<ServiceRequestFieldName, boolean> = {
    selectedDetails: true,
    expeditedTatUpdateTimestamp: true,
    placeOfService: fieldIsValid(formConfiguration?.placeOfService, hasPlaceOfService),
    authCategory:
      facilityBasedFeatureEnabled && formContent?.isInpatient
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasAuthCategory)
        : fieldIsValid(formConfiguration?.authCategory, hasAuthCategory),
    authSubCategory:
      facilityBasedFeatureEnabled && formContent?.isInpatient && hasSubCategories
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, checkAuthSubCategory)
        : fieldIsValid(formConfiguration?.authSubCategory, hasAuthSubcategory),
    orderingProvider: fieldIsValid(formConfiguration?.orderingProvider, hasOrderingProvider),
    orderingProviderTIN:
      hideTinField ||
      (!userTinValidation &&
        formContent.orderingProvider?.source === "CMS" &&
        !formContent?.orderingProvider?.manuallyCreated) ||
      fieldIsValid(formConfiguration.orderingProviderTIN, hasOrderingProviderTin),
    orderingProviderNPI: fieldIsValid(formConfiguration?.orderingProviderNPI, hasOrderingProviderNpi),
    orderingProviderAddress: fieldIsValid(formConfiguration?.orderingProviderAddress, hasOrderingProviderAddress),
    performingProviderPractice: fieldIsValid(
      formConfiguration?.performingProviderPractice,
      hasPerformingProviderPractice
    ),
    performingProviderPracticeSelectedTIN:
      hideTinField ||
      (!userTinValidation &&
        formContent.selectedPerformingProviderPractice?.source === "CMS" &&
        !formContent?.selectedPerformingProviderPractice?.manuallyCreated) ||
      fieldIsValid(formConfiguration.performingProviderPracticeSelectedTIN, hasPerformingProviderPracticeTIN),
    performingProviderPracticeSelectedAddress: fieldIsValid(
      formConfiguration?.performingProviderPracticeSelectedAddress,
      hasPerformingProviderPracticeAddress
    ),
    performingProviderPracticeSelectedNPI: fieldIsValid(
      formConfiguration?.performingProviderPracticeSelectedNPI,
      hasPerformingProviderPracticeNpi
    ),

    performingProvider: fieldIsValid(formConfiguration?.performingProvider, hasPerformingProvider),
    performingProviderNPI: validatePerformingProviderNPI(formConfiguration, formContent, hasPerformingProviderNpi),
    performingProviderTIN: shouldValidatePerformingProviderTin
      ? validatePerformingProviderTin(
          formConfiguration,
          formContent,
          performingProviderTinLength,
          hasPerformingProviderTin,
          userTinValidation
        )
      : true,
    performingProviderAddress: validatePerformingProviderAddress(
      formConfiguration,
      formContent,
      patientHealthPlanName,
      hasPerformingProviderAddress
    ),
    facility: fieldIsValid(formConfiguration?.facility, hasFacility),
    facilityNPI: fieldIsValid(formConfiguration?.facilityNPI, hasFacilityNpi),
    facilityTIN:
      hideTinField ||
      (!userTinValidation && formContent.facility?.source === "CMS" && !formContent?.facility?.manuallyCreated) ||
      fieldIsValid(formConfiguration?.facilityTIN, hasFacilityTin),
    facilityAddress: fieldIsValid(formConfiguration?.facilityAddress, hasFacilityAddress),
    facilityOutOfNetworkExceptionReason:
      (facilityExceptionRequest && !formContent?.facilityOONExceptionRequired) ||
      (formContent?.facilityOONExceptionRequired && hasFacilityOONOrUnknown)
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, !!formContent?.facilitySelectedAddress?.outOfNetworkExceptionReason)
        : fieldIsValid(formConfiguration?.facilityOutOfNetworkExceptionReason),

    performingProviderOutOfNetworkExceptionReason:
      (performingProviderExceptionRequest && !formContent?.performingProviderOONExceptionRequired) ||
      (formContent?.performingProviderOONExceptionRequired && hasPerformingProviderOONOrUnknown)
        ? fieldIsValid(
            { fieldSpec: "REQUIRED" },
            !!formContent?.performingProviderSelectedAddress?.outOfNetworkExceptionReason
          )
        : fieldIsValid(formConfiguration?.performingProviderOutOfNetworkExceptionReason),

    performingProviderPracticeOutOfNetworkExceptionReason:
      (performingProviderPracticeExceptionRequest &&
        !formContent?.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionReason) ||
      (formContent?.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionReason &&
        hasPerformingProviderPracticeOONOrUnknown)
        ? fieldIsValid(
            { fieldSpec: "REQUIRED" },
            !!formContent?.selectedPerformingProviderPractice?.selectedLocation?.outOfNetworkExceptionReason
          )
        : fieldIsValid(formConfiguration?.performingProviderPracticeOutOfNetworkExceptionReason),

    orderingProviderOutOfNetworkExceptionReason:
      (hasOrderingProviderExceptionRequestOnContinuation && !formContent?.orderingProviderOONExceptionRequired) ||
      (formContent?.orderingProviderOONExceptionRequired && hasOrderingProviderOONOrUnknown)
        ? fieldIsValid(
            { fieldSpec: "REQUIRED" },
            !!formContent?.orderingProviderSelectedAddress?.outOfNetworkExceptionReason
          )
        : fieldIsValid(formConfiguration?.orderingProviderOutOfNetworkExceptionReason),

    facilityOutOfNetworkExceptionComment:
      (requiresFacilityOONExceptionComment && !!hasFacilityOONExceptionComment) || !requiresFacilityOONExceptionComment,
    performingProviderOutOfNetworkExceptionComment:
      (requiresPerformingProviderOONExceptionComment && !!hasPerformingProviderOONExceptionComment) ||
      !requiresPerformingProviderOONExceptionComment,
    performingProviderPracticeOutOfNetworkExceptionComment:
      (requiresPerformingProviderPracticeOONExceptionComment && !!hasPerformingProviderPracticeOONExceptionComment) ||
      !requiresPerformingProviderPracticeOONExceptionComment,
    orderingProviderOutOfNetworkExceptionComment:
      (requiresOrderingProviderOONExceptionComment && !!hasOrderingProviderOONExceptionComment) ||
      !requiresOrderingProviderOONExceptionComment,

    careParticipantOutOfNetworkExceptionReason: formContent.additionalCareParticipants
      ? (careParticipantExceptionRequest &&
          !isAnyCareParticipantOONExceptionRequired(formContent.additionalCareParticipants)) ||
        validateOONExceptionReasonFieldForCareParticipants(formContent.additionalCareParticipants, false)
      : true,
    careParticipantOutOfNetworkExceptionComment: formContent.additionalCareParticipants
      ? validateOONExceptionCommentFieldForCareParticipants(formContent.additionalCareParticipants)
      : true,
    singleClinicalService: true,
    encounterType: true,
    primaryDiagnosis: true,
    secondaryDiagnoses: true,
    procedureCodes: true,
    recurring: true,
    startEndDate: true,
    units: true,
    urgency: true,
    behavioralHealthReviewType: true,
    behavioralHealthAdmissionType: true,
    admissionDischargeDate: true,
    faxInputField: fieldIsValid(
      formConfiguration?.faxInputField,
      hasFollowUpFaxNumber,
      followUpFaxNumberHasAcceptableDigits
    ),
    procedureCodeWithUnits: true,
    patientStayDateRanges: true,
    prescribedDrug: true,
    outOfNetworkCheck: true,
    facilityOutOfNetworkStatusDisplay: true,
    performingProviderOutOfNetworkStatusDisplay: true,
    orderingProviderOutOfNetworkStatusDisplay: true,
    performingProviderPracticeOutOfNetworkStatusDisplay: true,
    careParticipantOutOfNetworkStatusDisplay: true,
    outOfNetworkOrderingProvider: true,
    userDeclaredOONException: true,
    admissionDate: true,
    admissionTime: true,
    dischargeDate: true,
    dischargeTime: true,
    dischargedTo: true,
    expectedAdmissionDate: true,
    expectedDischargeDate: true,
    additionalCareParticipants: fieldIsValid(formConfiguration?.additionalCareParticipants, isCareParticipantsValid),
    nonPalCheckbox: true,
    blockUserIfExceedsRecommendedEndDate: true,
    blockUserIfLessThanRecommendedEndDate: true,
    userSelectedOONException: true,
    admissionSource: true,
    patientStatus: true,
  };

  const faxNumberIsAutofilled =
    (userFaxExtension?.length || 0) > 0 && userFaxExtension === formContent?.followUpFaxNumber?.number;

  const containerClasses = useMuiContainerStyles();

  const validateFields = () => {
    const formConfig = formConfiguration;
    if (formConfig) {
      const formConfigKeys = Object.keys(formConfig) as ServiceRequestFieldName[];
      for (const key of formConfigKeys) {
        if (!VALIDATION_MAP[key]) {
          return false;
        }
      }
      return true;
    }
    //should never happen, but if it does we should block the user from trying to submit
    return false;
  };

  const isValid = validateFields();
  const isAuthorizedForRequestorForm = useAuthorized("REQUESTOR_FORM");

  const handleFormValidation = useCallback(
    (isFormValid: boolean) => {
      setCanBeSubmitted(isFormValid);
    },
    [setCanBeSubmitted]
  );

  useEffect(() => {
    handleFormValidation(isValid);
  }, [isValid, handleFormValidation]);

  useEffect(() => {
    if (formConfiguration?.additionalCareParticipants?.fieldSpec === "OMIT" && !!careParticipantEnabled) {
      const errorMsg = "additionalCareParticipants config enabled but fieldSpec is OMIT";
      logError(`${errorMsg} sr:${formContent.id}`);
    }
  }, [careParticipantEnabled, formConfiguration?.additionalCareParticipants?.fieldSpec, formContent.id]);

  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);

  return (
    <Container classes={containerClasses} maxWidth="lg">
      <Box paddingTop={isFaxAuthBuilderFlow ? 3 : 5}>
        {isAuthorizedForRequestorForm && showRequestorCard && requestorProps && <RequestorCard {...requestorProps} />}
        <ProvidersCard
          authStatus={authStatus}
          formContent={formContent}
          isInpatient={formContent?.isInpatient}
          sameProviders={sameProviders}
          setSameProviders={setSameProviders}
          placeOfService={formContent?.placeOfService || null}
          setFormContent={setFormContent}
          attemptedSubmit={attemptedSubmit}
          facilityIsOptional={formConfiguration?.facility.fieldSpec === "OPTIONAL"}
          performingIsOptional={formConfiguration?.performingProvider.fieldSpec === "OPTIONAL"}
          placeOfServiceLoading={placeOfServiceLoading}
          availablePlacesOfService={placeOfServiceData || undefined}
          setPlaceOfService={(pos) => setFormContent((prev) => ({ ...prev, placeOfService: pos }))}
          dispatchRedirectRuleCheck={dispatchRedirectRuleCheck}
          VALIDATION_MAP={VALIDATION_MAP}
          formFieldConfigurations={formFieldConfigurations}
          outOfNetworkCheckConfiguration={outOfNetworkCheckConfiguration}
          patient={patient || undefined}
          delegatedVendorName={delegatedVendorName}
          workflowId={workflowId}
          faxNumberIsAutofilled={faxNumberIsAutofilled}
          isFacilityOutOfNetworkCommentRequired={requiresFacilityOONExceptionComment}
          isPerformingProviderOutOfNetworkCommentRequired={requiresPerformingProviderOONExceptionComment}
          isPerformingProviderPracticeOutOfNetworkCommentRequired={
            requiresPerformingProviderPracticeOONExceptionComment
          }
          isOrderingProviderOutOfNetworkCommentRequired={requiresOrderingProviderOONExceptionComment}
          setPriorAuthRequirements={setPriorAuthRequirements}
          performingProviderExceptionRequest={performingProviderExceptionRequest || false}
          setPerformingProviderExceptionRequest={setPerformingProviderExceptionRequest}
          facilityExceptionRequest={facilityExceptionRequest || false}
          setFacilityExceptionRequest={setFacilityExceptionRequest}
          performingProviderPracticeExceptionRequest={performingProviderPracticeExceptionRequest || false}
          setPerformingProviderPracticeExceptionRequest={setPerformingProviderPracticeExceptionRequest}
          orderingProviderExceptionRequest={orderingProviderExceptionRequest || false}
          setOrderingProviderExceptionRequest={setOrderingProviderExceptionRequest}
          careParticipantExceptionRequest={careParticipantExceptionRequest || false}
          setCareParticipantExceptionRequest={setCareParticipantExceptionRequest}
          onProviderDetailsPage={onProviderDetailsPage}
          setFacilityOonCheckLoading={setFacilityOonCheckLoading}
          setPerformingProviderPracticeOonCheckLoading={setPerformingProviderPracticeOonCheckLoading}
          setProviderOonCheckLoading={setProviderOonCheckLoading}
          setOrderingProviderOonCheckLoading={setOrderingProviderOonCheckLoading}
          setAdditionalCareOonCheckLoading={setAdditionalCareOonCheckLoading}
        />
      </Box>
    </Container>
  );
}
