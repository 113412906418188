import { Dispatch, SetStateAction, useCallback } from "react";

import { NurseReview, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import NurseReviewSubmissionOutcomes from "./NurseReviewSubmissionOutcomes";
import { ExtendedRnReviewOutcome, ReviewOutcomeOption } from "../util/QueueManagementReviewUtil";
import Grid from "@material-ui/core/Grid";
import { NurseReviewUpdate } from "components/ClinicalReview/reviewUtils/useReviews";
import listReplace from "util/listReplace";
import { H6, SingleSelectDropdownCancel } from "@coherehealth/common";
import { Divider } from "@material-ui/core";
import { Caption } from "@coherehealth/common";
import { useTheme } from "@material-ui/core/styles";

interface Props {
  serviceRequest: ServiceRequestResponse;
  nurseReview: NurseReview;
  allowedReviewOutcomes: ReviewOutcomeOption[];
  setNurseReview: NurseReviewUpdate;
  caseId: string;
  isCppCase: boolean;
  isUnplannedInpatient: boolean;
  displayCaseOutcomes: ExtendedRnReviewOutcome[];
  setDisplayCaseOutcomes: Dispatch<SetStateAction<ExtendedRnReviewOutcome[]>>;
  attemptedSubmit: boolean;
}

export default function NurseReviewMultiCoverageSubmissionOutcomes({
  serviceRequest,
  nurseReview,
  allowedReviewOutcomes,
  setNurseReview,
  caseId,
  isCppCase,
  isUnplannedInpatient,
  displayCaseOutcomes,
  setDisplayCaseOutcomes,
  attemptedSubmit,
}: Props) {
  const theme = useTheme();
  const { notAllWithdrawn, notAllSamePendingReason } = useNurseReviewOutcomeValidator(displayCaseOutcomes);

  const fieldSetter = useCallback(
    (nurseReviewUpdate: Partial<NurseReview>, index: number) => {
      const coverageLevelDetailsPayload = nurseReview.coverageLevelDetails?.slice();
      const previousDetail = coverageLevelDetailsPayload?.[index];
      const updatedDetail = { ...previousDetail, ...nurseReviewUpdate };
      coverageLevelDetailsPayload?.splice(index, 1, updatedDetail);

      const topLevelOutcome = nurseReviewOutcomeRollup(
        coverageLevelDetailsPayload?.map((detail) => detail.reviewOutcome || undefined) || []
      );

      setNurseReview({ coverageLevelDetails: coverageLevelDetailsPayload, reviewOutcome: topLevelOutcome });
    },
    [nurseReview.coverageLevelDetails, setNurseReview]
  );

  const hasMissingOutcomeSelection = !nurseReview.coverageLevelDetails?.every((detail) =>
    Boolean(detail.reviewOutcome)
  );

  const withdrawnError = notAllWithdrawn && attemptedSubmit;
  const pendingError = notAllSamePendingReason && attemptedSubmit;

  const hasError = attemptedSubmit && Boolean(withdrawnError || pendingError);
  return (
    <Grid container item xs={12}>
      <Divider style={{ width: "100%", marginBottom: 16 }} />
      {nurseReview.coverageLevelDetails?.map((detail, index) => (
        <Grid item container xs={12} key={`${detail.coverage?.lineOfBusinessType} - ${index}`}>
          <Grid item xs={12}>
            <H6 style={{ paddingBottom: 16 }}>{detail.coverage?.lineOfBusinessType}</H6>
          </Grid>
          <Grid item xs={5} style={{ paddingBottom: 32 }}>
            <NurseReviewSubmissionOutcomes
              serviceRequest={serviceRequest}
              previousServiceRequest={null}
              firstDeniedDay={undefined}
              caseId={caseId}
              allowedReviewOutcomes={allowedReviewOutcomes}
              isUnplannedInpatient={isUnplannedInpatient}
              isCppCase={isCppCase}
              setReview={(outcome) => {
                fieldSetter(outcome, index);
              }}
              setDisplayCaseOutcome={(outcome) =>
                setDisplayCaseOutcomes(listReplace(displayCaseOutcomes, index, outcome))
              }
              displayCaseOutcome={displayCaseOutcomes[index]}
              error={
                attemptedSubmit &&
                (isDropdownInEmptySubmissionError(hasMissingOutcomeSelection, displayCaseOutcomes[index]) ||
                  isDropdownInPendingError(pendingError, displayCaseOutcomes[index]) ||
                  withdrawnError)
              }
            />
          </Grid>
          {allowedReviewOutcomes?.find((outcome) => outcome.id === detail?.reviewOutcome)?.submenu && (
            <Grid item xs={5} style={{ paddingBottom: 32, paddingLeft: 16 }}>
              <SingleSelectDropdownCancel
                label="Decision reason"
                options={allowedReviewOutcomes?.find((outcome) => outcome.id === detail?.reviewOutcome)?.submenu}
                value={detail.serviceStatusCode || ""}
                onChange={(opt) => {
                  fieldSetter(
                    {
                      serviceStatusCode: opt,
                      serviceStatusLabel: allowedReviewOutcomes
                        ?.find((outcome) => outcome.id === detail?.reviewOutcome)
                        ?.submenu?.find((item) => item.id === opt)?.label,
                    },
                    index
                  );
                }}
                canCancel={!!detail?.serviceStatusCode}
                onCancel={() => fieldSetter({ serviceStatusCode: undefined }, index)}
                error={attemptedSubmit && !detail?.serviceStatusCode}
              />
            </Grid>
          )}
        </Grid>
      ))}
      {hasError && (
        <Grid item xs={12}>
          <Caption style={{ color: theme.palette.error.main }}>
            {withdrawnError
              ? "Withdrawn must be set for all coverages or no coverages"
              : "All pending coverages must have the same pending reason"}
          </Caption>
        </Grid>
      )}
    </Grid>
  );
}

const AVAILABLE_PENDING_REVIEW_OUTCOMES = [
  "PENDING_MISSING_CLINICAL_INFO",
  "PENDING_NUDGE_OUTREACH",
  "PENDING_RN_REVIEW",
  "PENDING_MD_REVIEW",
  "PENDING_ADMIN_VOID",
];

const nurseReviewOutcomeRollup = (rnReviewOutcomes: ExtendedRnReviewOutcome[]) => {
  const allDecisioned = rnReviewOutcomes.every((item) => Boolean(item));
  const allWithdrawn = rnReviewOutcomes.every((item) => item === "WITHDRAWN");
  const allApproved = rnReviewOutcomes.every((item) => item === "APPROVED");

  const uniquePendingEntries = findUniquePendingOutcomes(rnReviewOutcomes);

  const pendingStatus = uniquePendingEntries?.[0];
  const multiplePendingStatuses = uniquePendingEntries?.length > 1;

  if (allDecisioned) {
    if (allWithdrawn) {
      return "WITHDRAWN";
    } else if (allApproved) {
      return "APPROVED";
    } else {
      if (multiplePendingStatuses) {
        return undefined;
      } else {
        return pendingStatus;
      }
    }
  } else {
    return undefined;
  }
};

const useNurseReviewOutcomeValidator = (rnReviewOutcomes: ExtendedRnReviewOutcome[]) => {
  const uniquePendingEntries = findUniquePendingOutcomes(rnReviewOutcomes);
  const notAllSamePendingReason = uniquePendingEntries?.length > 1;
  const notAllWithdrawn = checkOutcomeUniformity("WITHDRAWN", rnReviewOutcomes);
  const notAllApproved = checkOutcomeUniformity("APPROVED", rnReviewOutcomes);

  return { notAllWithdrawn, notAllApproved, notAllSamePendingReason };
};

const checkOutcomeUniformity = (outome: string, rnReviewOutcomes: ExtendedRnReviewOutcome[]) => {
  if (rnReviewOutcomes.includes(outome)) {
    return !rnReviewOutcomes.every((item) => item === outome);
  } else {
    return false;
  }
};

const findUniquePendingOutcomes = (rnReviewOutcomes: ExtendedRnReviewOutcome[]) =>
  Array.from(new Set(rnReviewOutcomes.filter((outcome) => AVAILABLE_PENDING_REVIEW_OUTCOMES.includes(outcome || ""))));

const isDropdownInPendingError = (errorState: boolean, outcome: ExtendedRnReviewOutcome) =>
  errorState && AVAILABLE_PENDING_REVIEW_OUTCOMES.includes(outcome || "");

const isDropdownInEmptySubmissionError = (errorState: boolean, outcome: ExtendedRnReviewOutcome) =>
  errorState && !outcome;
