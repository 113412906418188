import React from "react";
import { useEffect, useState } from "react";
import { InlineButton } from "../InlineButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled, Theme } from "@material-ui/core/styles";
import { ReactNode } from "react-markdown";
import MuiAccordion, { AccordionProps } from "@material-ui/core/Accordion";
import Divider from "@material-ui/core/Divider";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { ClassNameMap } from "@material-ui/styles";

type Props = AccordionProps & {
  styleOverrides?: Partial<
    ClassNameMap<"rotatingCarrot" | "expanded" | "card" | "summary" | "summaryRoot" | "contentRoot" | "contentDivider">
  >;
  children?: ReactNode;
  header?: ReactNode;
  hideDivider?: boolean;
  onExpansion?: () => void;
  onContraction?: () => void;
  expansionOverrideFromParent?: boolean;
};

const useDefaultStyles = makeStyles((theme) => ({
  rotatingCarrot: ({ isExpanded }: { isExpanded: boolean }) => ({
    transition: theme.transitions.create("transform"),
    transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
    height: theme.spacing(4),
    width: theme.spacing(4),
    marginRight: 0,
    marginTop: 2,
  }),
  card: {
    padding: 0,
    position: "relative",
  },
  summary: {
    display: "inline-block",
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
  summaryRoot: {
    cursor: "pointer",
    userSelect: "text",
    padding: theme.spacing(1.5, 2),
  },
  contentRoot: {
    margin: 0,
  },
  contentDivider: {},
  expanded: {},
}));

export default function Accordion({
  styleOverrides,
  children,
  header,
  hideDivider,
  defaultExpanded,
  onExpansion,
  onContraction,
  expansionOverrideFromParent,
  ...props
}: Props) {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded || false);
  const defaultClasses = useDefaultStyles({ isExpanded: expanded });
  useEffect(() => {
    if (expansionOverrideFromParent !== undefined) {
      setExpanded(expansionOverrideFromParent);
    }
  }, [expansionOverrideFromParent, setExpanded]);
  return (
    <MuiAccordion
      style={{ margin: 0 }}
      className={styleOverrides?.card || defaultClasses.card}
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      TransitionProps={{ unmountOnExit: true }}
      elevation={0}
      {...props}
    >
      <AccordionSummary
        classes={{
          root: styleOverrides?.summaryRoot || defaultClasses.summaryRoot,
          content: styleOverrides?.summary || defaultClasses.summary,
          expanded: styleOverrides?.expanded || defaultClasses.expanded,
        }}
        onClick={() => {
          if (expanded && onContraction) {
            onContraction();
          } else if (!expanded && onExpansion) {
            onExpansion();
          }
          setExpanded(!expanded);
        }}
      >
        <RelativeContainer>
          <div style={{ width: "100%" }}>{header}</div>
          <CarrotContainer>
            <CarrotIcon>
              <ExpandMoreIcon className={styleOverrides?.rotatingCarrot || defaultClasses.rotatingCarrot} />
            </CarrotIcon>
          </CarrotContainer>
        </RelativeContainer>
      </AccordionSummary>
      <RegionContainer className={styleOverrides?.contentRoot || defaultClasses.contentRoot}>
        {!hideDivider && (
          <DividerContainer className={styleOverrides?.contentDivider || defaultClasses.contentDivider}>
            <Divider />
          </DividerContainer>
        )}
        {children}
      </RegionContainer>
    </MuiAccordion>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RegionContainer = styled(({ padding, ...other }) => <div {...other} />)<Theme, { padding?: string }>(
  ({ theme, padding }) => ({
    padding: padding ? padding : theme.spacing(0, 2, 1.5),
  })
);

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CarrotIcon = styled(InlineButton)({
  position: "absolute",
  right: 0,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RelativeContainer = styled("div")({
  display: "flex",
  position: "relative",
  justifyContent: "space-between",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CarrotContainer = styled("div")({
  position: "relative",
  right: -10,
  top: -14,
  marginLeft: 60,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const DividerContainer = styled(({ margin, ...other }) => <div {...other} />)<Theme, { margin?: string }>(
  ({ theme, margin }) => ({
    width: "100%",
    margin: margin ? margin : theme.spacing(0, 0, 2),
  })
);
