import { makeStyles } from "@material-ui/core/styles";

const useSRFormRadioGroupHeaderStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
    "&.Mui-disabled": {
      color: theme.palette.text.primary,
    },
    "&$focused": {
      color: theme.palette.text.primary,
    },
  },
  focused: {},
  tooltipIcon: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
  },
}));

export default useSRFormRadioGroupHeaderStyles;
