import React from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import HeaderAndProgressBar from "./HeaderAndProgressBar";
import { H1, useFeature } from "@coherehealth/common";

import UserInfoForm, { Props as UserInfoFormProps } from "./UserInfoForm";
import { Grid } from "@material-ui/core";
import SpecialtiesMultiSelect from "./SpecialtiesMultiSelect";

interface Props extends UserInfoFormProps {
  isAvailityUser: boolean;
}

export default function ExistingOrgUserInfoPage({ isAvailityUser, ...userInfoFormProps }: Props) {
  const onboardingRedesign = useFeature("onboardingRedesign");
  const setSpecialties = (specialties: string[]) => {
    userInfoFormProps.setUserInfo((prev) => ({ ...prev, userSpecialties: specialties }));
  };

  return (
    <Container>
      <HeaderAndProgressBar stepNumber={2} isAvailityUser={isAvailityUser} />
      <Grid container spacing={2}>
        <Title>Share more about you</Title>
        <Grid item xs={12}>
          <UserInfoForm {...userInfoFormProps} />
        </Grid>
        {onboardingRedesign && (
          <Grid item xs={12}>
            <SpecialtiesMultiSelect
              questionTitle={
                "Which specialties do you personally submit auths for (to any vendor or payer, not just Cohere)?"
              }
              setSpecialties={setSpecialties}
              highlightInfoFormErrors={userInfoFormProps.highlightInfoFormErrors}
              specialties={userInfoFormProps.userInfo.userSpecialties || []}
              userInfo={userInfoFormProps.userInfo}
              setUserInfo={userInfoFormProps.setUserInfo}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Container = styled("div")({ paddingBottom: 200 });

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Title = styled(H1)(({ theme }) => ({
  margin: theme.spacing(3, 0, 6),
}));
