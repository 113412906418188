import { createContext, ReactNode, useCallback, useMemo, useState } from "react";

export interface ScrubTinsContextInterface {
  scrubTinsEnabled: boolean;
  setScrubTinsContextState: (scrubTinsEnabled: boolean) => void;
}

const defaultState = {
  scrubTinsEnabled: false,
  setScrubTinsContextState: (scrubTinsEnabled: boolean) => {},
} as ScrubTinsContextInterface;

export const ScrubTinsContext = createContext(defaultState);

type ScrubTinsContextProviderProps = {
  children: ReactNode;
};

export default function ScrubTinsContextProvider({ children }: ScrubTinsContextProviderProps) {
  const [scrubTinsEnabled, setScrubTinsEnabled] = useState(false);

  const setScrubTinsContextState = useCallback((scrubTinsEnabled: boolean) => {
    setScrubTinsEnabled(scrubTinsEnabled);
  }, []);

  const value = useMemo(
    () => ({ scrubTinsEnabled, setScrubTinsContextState }),
    [scrubTinsEnabled, setScrubTinsContextState]
  );

  return <ScrubTinsContext.Provider value={value}>{children}</ScrubTinsContext.Provider>;
}
