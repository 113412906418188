import { Switch, useFeature } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core";
import { useTheme } from "@mui/material";
import { useEffect, useCallback, useRef, Dispatch, SetStateAction } from "react";
import { AuthorizationResponse, UserResponse, useUpdateRestrictedDataSettings } from "@coherehealth/core-platform-api";

interface IProps {
  authorization?: AuthorizationResponse | null;
  renderedFromPatientSummary?: boolean;
  user?: UserResponse | null;
  visibilityToggleStatus: boolean;
  setVisibilityToggleStatus: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
  toggleContainer: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: "16px",
    fontFamily: "Gilroy-Medium",
    fontSize: "15px",
  },
  switchContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "24px",
  },
}));

export default function AuthVisibilityToggle({
  authorization,
  renderedFromPatientSummary,
  user,
  visibilityToggleStatus,
  setVisibilityToggleStatus,
}: IProps) {
  const classes = useStyles();
  const theme = useTheme();
  const relaxAuthVisibilityFF = useFeature("relaxAuthVisibility");

  const hasMounted = useRef(false);

  const { mutate: restrictedDataSettingsUpdate } = useUpdateRestrictedDataSettings({
    authorizationId: authorization?.id || "",
  });

  const handleToggle = useCallback(
    async (status: boolean) => {
      try {
        await restrictedDataSettingsUpdate({
          allowEditForAuthorizationParticipants: status,
        });
      } catch (error) {
        // couldn't update restrictedDataSettings
      }
    },
    [restrictedDataSettingsUpdate]
  );

  useEffect(() => {
    if (hasMounted.current) {
      if (visibilityToggleStatus !== null || visibilityToggleStatus !== undefined) {
        handleToggle(visibilityToggleStatus);
      }
    } else {
      hasMounted.current = true;
    }
  }, [visibilityToggleStatus, handleToggle]);

  return relaxAuthVisibilityFF ? (
    <div
      className={classes.toggleContainer}
      style={renderedFromPatientSummary ? { padding: theme.spacing(1.5, 2, 0.5, 2) } : { paddingTop: "8px" }}
    >
      <div style={{ color: theme.palette.font.main }}>Allow the care team to view details</div>
      <div className={classes.switchContainer}>
        <Switch
          checked={visibilityToggleStatus}
          onChange={async () => {
            setVisibilityToggleStatus(!visibilityToggleStatus);
          }}
        />
      </div>
    </div>
  ) : (
    <></>
  );
}
