import { useEffect, useState } from "react";
import { ServiceCase } from "@coherehealth/qm-api";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { GetDataError } from "restful-react";
import { OutreachAttempt } from "@coherehealth/core-platform-api";
import CaseEventHistoryCard, { CaseEventHistoryCardProps } from "./CaseEventHistoryCard";
import { parseDateFromISOString } from "@coherehealth/common";
import useAuthStatusHistory from "components/AuthStatusHistory/useAuthStatusHistory";
import AuthStatusHistory from "components/AuthStatusHistory/AuthStatusHistory";
import { useAuthorized } from "authorization";

interface CaseHistoryProps {
  serviceCase: ServiceCase | null;
  trackingNumberSearch?: boolean;
  serviceCases: ServiceCase[] | null;
  serviceCasesLoading: boolean;
  getServiceRequestCasesError: GetDataError<unknown> | null;
  outreachAttempts?: OutreachAttempt[];
  id: string;
  serviceRequestId: string;
}

const CaseHistoryContent = ({
  serviceCase,
  trackingNumberSearch,
  serviceCases,
  serviceCasesLoading,
  getServiceRequestCasesError,
  outreachAttempts,
  id,
  serviceRequestId,
}: CaseHistoryProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [historyEvents, setHistoryEvents] = useState<CaseEventHistoryCardProps[]>([]);

  useEffect(() => {
    if (getServiceRequestCasesError) {
      enqueueSnackbar(`Error fetching service cases`, { variant: "error" });
    }
  }, [enqueueSnackbar, getServiceRequestCasesError]);

  useEffect(() => {
    const isOutreachCase = (sc: ServiceCase) =>
      !!sc.caseType &&
      ["MISSING_INFORMATION", "NUDGE_OUTREACH", "SCHEDULING_OUTREACH", "REQUIRES_VERBAL_NOTIFICATIONS"].includes(
        sc.caseType
      );

    const { caseCompletionEvents, serviceCaseOutreachAttemptMap } = serviceCases
      ? serviceCases.reduce(
          (acc, sc) => {
            if (sc.outcome?.outreachCaseOutcome?.outreachAttemptId) {
              acc.serviceCaseOutreachAttemptMap.set(
                sc.outcome?.outreachCaseOutcome?.outreachAttemptId,
                sc.caseNumber || "--"
              );
            }
            if (!isOutreachCase(sc)) {
              acc.caseCompletionEvents.push({
                caseId: sc.caseNumber || "--",
                completedInfo: {
                  completedOn: sc.outcome?.dateCompleted || "",
                  completedBy: sc.outcome?.completedByName || "",
                },
                eventType: "CASE_COMPLETION",
                serviceCase: sc,
              });
            }

            return acc;
          },
          {
            caseCompletionEvents: [] as CaseEventHistoryCardProps[],
            serviceCaseOutreachAttemptMap: new Map<string, string>(),
          }
        )
      : {
          caseCompletionEvents: [],
          serviceCaseOutreachAttemptMap: new Map<string, string>(),
        };

    const { inboundOutreachAttemptEvents, outboundOutreachAttemptEvents } = outreachAttempts
      ? outreachAttempts.reduce(
          (accum, attempt) => {
            const outreachServiceCaseNumber = serviceCaseOutreachAttemptMap.get(attempt.id || "--") || "--";
            const outreachCaseCompletion = serviceCases?.find(
              (sc) => sc.outcome?.outreachCaseOutcome?.outreachAttemptId === attempt.id
            );
            const baseEventInfo = {
              caseId: outreachServiceCaseNumber,
              completedInfo: {
                completedBy: attempt.completedByName || "",
                completedOn: attempt.completedDate || "",
              },
              outreachAttempt: {
                ...attempt,
                memberInfo: {
                  memberPhoneNumber: attempt.memberPhoneNumber,
                  memberFaxNumber: attempt.memberFaxNumber,
                  memberEmail: attempt.memberEmail,
                },
                providerInfo: {
                  providerPhoneNumber: attempt.providerPhoneNumber,
                  providerFaxNumber: attempt.providerFaxNumber,
                  providerEmail: attempt.providerEmail,
                },
                workInfo: {
                  workCompleted: outreachCaseCompletion?.outcome?.dateCompleted,
                  workStarted: outreachCaseCompletion?.dateCreated,
                },
              },
            };

            if (attempt.contactType === "INBOUND") {
              accum.inboundOutreachAttemptEvents.push({
                ...baseEventInfo,
                eventType: "INBOUND_OUTREACH",
              });
            } else if (attempt.contactType === "OUTBOUND") {
              accum.outboundOutreachAttemptEvents.push({
                ...baseEventInfo,
                eventType: "OUTBOUND_OUTREACH",
              });
            }

            return accum;
          },
          {
            inboundOutreachAttemptEvents: [] as CaseEventHistoryCardProps[],
            outboundOutreachAttemptEvents: [] as CaseEventHistoryCardProps[],
          }
        )
      : {
          inboundOutreachAttemptEvents: [],
          outboundOutreachAttemptEvents: [],
        };

    setHistoryEvents(
      inboundOutreachAttemptEvents
        .concat(outboundOutreachAttemptEvents)
        .concat(caseCompletionEvents)
        .sort(
          (a, b) =>
            parseDateFromISOString(b.completedInfo.completedOn).getTime() -
            parseDateFromISOString(a.completedInfo.completedOn).getTime()
        )
    );
  }, [serviceCase, serviceCases, outreachAttempts, trackingNumberSearch]);

  const combinedCaseHistoryEvents = () => {
    return historyEvents.map((historyEvent, idx) => <CaseEventHistoryCard key={idx} {...historyEvent} />);
  };

  const canViewAuthStatusHistory = useAuthorized("CAN_VIEW_AUTH_STATUS_HISTORY");
  const authStatusHistoryHistoryProps = useAuthStatusHistory({ serviceRequestId: serviceRequestId });

  return (
    <>
      <div
        id={id}
        data-testid={id}
        style={serviceCase ? { paddingBottom: 60, maxHeight: "calc(100vh - 218px)" } : { height: "100%" }}
      >
        {serviceCasesLoading ? (
          <CircularProgress />
        ) : (
          <>
            {canViewAuthStatusHistory && <AuthStatusHistory {...authStatusHistoryHistoryProps} />}
            {combinedCaseHistoryEvents()}
          </>
        )}
      </div>
    </>
  );
};

export default CaseHistoryContent;
