import React, { useState } from "react";
import { colorsLight, H6 } from "@coherehealth/common";
import PurpleClipboardBulb from "../images/PurpleClipboardBulb";
import PurpleClipboardCheckIcon from "../images/PurpleClipboardCheckIcon";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import isEqual from "lodash/isEqual";

interface Props {
  amountOfNudges: number;
  loadingActions: boolean;
  originalRequest: ServiceRequestResponse;
  updatedRequest: ServiceRequestResponse;
  hasMessageBanners: boolean;
}

const useStyles = makeStyles((theme) => ({
  header: {
    textAlign: "center",
  },
  headerText: {
    color: colorsLight.font.primary,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: "11px",
  },
  shortHeaderText: {
    color: colorsLight.font.primary,
    paddingLeft: theme.spacing(9),
    paddingRight: theme.spacing(8),
    paddingBottom: theme.spacing(1),
    paddingTop: "11px",
  },
  iconGrid: {
    textAlign: "center",
  },
}));

export default function NudgeHeader({
  amountOfNudges,
  loadingActions,
  originalRequest,
  updatedRequest,
  hasMessageBanners,
}: Props) {
  const classes = useStyles();
  const [staticServiceRequest] = useState(originalRequest);

  const nudgeText = loadingActions
    ? "Searching for evidence-based suggestions to improve your request."
    : amountOfNudges > 0
    ? `${amountOfNudges} evidence-based suggestion${amountOfNudges > 1 ? "s" : ""} to improve your request:`
    : !hasMessageBanners && isEqual(staticServiceRequest, updatedRequest)
    ? "No suggested changes. You're on track for evidence-based care!"
    : !hasMessageBanners
    ? "All suggestions accepted. You're on track for the best possible care!"
    : hasMessageBanners && isEqual(staticServiceRequest, updatedRequest)
    ? null
    : "All suggestions accepted.";

  return (
    <div className={classes.header}>
      {loadingActions ? (
        <CircularProgress style={{ width: "80px", height: "80px", padding: "8px" }} />
      ) : !hasMessageBanners && amountOfNudges === 0 ? (
        <PurpleClipboardCheckIcon />
      ) : (
        <PurpleClipboardBulb />
      )}
      <H6
        className={
          !(hasMessageBanners && isEqual(staticServiceRequest, updatedRequest))
            ? classes.shortHeaderText
            : classes.headerText
        }
        data-public
      >
        {nudgeText}
      </H6>
    </div>
  );
}
