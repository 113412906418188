import React, { useEffect, useState } from "react";
import {
  ServiceRequestResponse,
  usePayerProcedureCodesFromCPTCodes,
  PayerProcedureCode,
  RuleActions,
  AuthorizationResponse,
} from "@coherehealth/core-platform-api";
import { Caption, useGeneralAuthSubmissionWorflowOn } from "@coherehealth/common";
import Grid from "@material-ui/core/Grid";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSnackbar } from "notistack";

import ProcedureCodeList from "./ProcedureCodeList";
import RequestDetails from "components/ServiceRequest/ReadonlyDetail/RequestDetails";
import RequestDetailsContinuation from "components/ServiceRequest/ReadonlyDetail/RequestDetailsContinuation";
import ExpandedServiceRequestCard from "components/ServiceRequest/ExpandedServiceRequestCard/ExpandedServiceRequestCard";
import useDraftContinue from "components/ServiceRequest/DraftContinue";
import { getLatestServiceRequest } from "util/authorization";
import { useAuthorized } from "authorization";
import { ClinicalServicesWithProcedures } from "./ClinicalServicesWithProcedures";

interface Props {
  authorization?: AuthorizationResponse;
  refreshAuthorization?: () => Promise<void>;
  serviceRequest: ServiceRequestResponse;
  serviceRequests?: ServiceRequestResponse[];
  hideFacilityState?: boolean;
  ruleActions?: RuleActions;
  isContinuationSR?: boolean;
  clinicalReviewView?: boolean;
  useNewPxTable?: boolean;
  isFacilityBasedAuth?: boolean;
  onReviewPage?: boolean;
  isFaxEdit?: boolean;
  showServiceChangeBanner?: boolean;
}

const useStyles = makeStyles((theme) => ({
  pxCodeSection: {
    width: "100%",
  },
}));

export default function ServiceRequestFormReadOnly({
  authorization,
  refreshAuthorization,
  serviceRequest,
  serviceRequests,
  hideFacilityState,
  ruleActions,
  isContinuationSR,
  clinicalReviewView,
  isFacilityBasedAuth,
  onReviewPage,
  isFaxEdit = false,
  showServiceChangeBanner,
}: Props) {
  const generalAuthSubmissionEnabled = useGeneralAuthSubmissionWorflowOn(serviceRequest.healthPlanName || "");
  const {
    mutate: getPayerProcedureCodes,
    loading: procedureCodesLoading,
    error: procedureCodesError,
  } = usePayerProcedureCodesFromCPTCodes({});
  const [payerProcedureCodes, setPayerProcedureCodes] = useState<PayerProcedureCode[]>([]);
  const classes = useStyles();
  // Might be any authStatus
  const latestSr = getLatestServiceRequest(authorization)?.[1];

  // Continue Draft logic
  const draftContinue = useDraftContinue(latestSr);

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (procedureCodesError) {
      enqueueSnackbar(procedureCodesError.message, { variant: "error" });
    }
  }, [procedureCodesError, enqueueSnackbar]);

  useEffect(() => {
    const serviceRequestCardId = `ExpandedServiceRequestCard-` + serviceRequest?.id;
    const element = document.getElementById(serviceRequestCardId);
    element?.scrollIntoView();
  }, [serviceRequest?.id, authorization]);
  const isAuthViewOnlyUser = useAuthorized("AUTH_VIEW_ONLY");

  return (
    <Grid container item xs={12} spacing={3} style={{ margin: 0 }}>
      {isContinuationSR ? (
        //below is the pre-submission review page for continuations
        <RequestDetailsContinuation
          authorization={authorization}
          serviceRequest={serviceRequest}
          hideFacilityState={hideFacilityState}
          ruleActions={ruleActions || []}
          isFacilityBasedAuth={isFacilityBasedAuth}
        />
      ) : (
        <RequestDetails
          authorization={authorization}
          serviceRequest={serviceRequest}
          hideFacilityState={hideFacilityState}
          ruleActions={ruleActions || []}
          isFacilityBasedAuth={isFacilityBasedAuth}
          clinicalReviewView={clinicalReviewView}
          isFaxEdit={isFaxEdit}
        />
      )}
      {procedureCodesLoading && !payerProcedureCodes && <CircularProgress />}
      <div className={classes.pxCodeSection}>
        {generalAuthSubmissionEnabled ? (
          <ClinicalServicesWithProcedures
            showServiceChangeBanner={showServiceChangeBanner}
            serviceRequest={serviceRequest}
            authorization={authorization}
            onReviewPage={onReviewPage}
          />
        ) : (
          <ProcedureCodeList
            authorization={authorization}
            serviceRequest={serviceRequest}
            getPayerProcedureCodes={getPayerProcedureCodes}
            setPayerProcedureCodes={setPayerProcedureCodes}
            payerProcedureCodes={payerProcedureCodes}
          />
        )}
      </div>
      {clinicalReviewView &&
        serviceRequests &&
        serviceRequests?.length > 1 &&
        serviceRequests
          .reverse()
          .filter((sr) => (isAuthViewOnlyUser ? sr.authStatus !== "DRAFT" : true)) //if authViewOnly then filter Draft AuthStatus SR's
          .map((sr, idx) => {
            return (
              <ExpandedServiceRequestCard
                positionInSrList={idx}
                id={`ExpandedServiceRequestCard-` + sr.id}
                key={sr.id}
                sr={sr}
                priorSr={idx < serviceRequests.length ? serviceRequests[idx + 1] : undefined}
                clinicalReviewView={clinicalReviewView}
                continueDraft={() => {
                  sr.workflowStep && draftContinue(sr.workflowStep);
                }}
                onEdit={() => {
                  refreshAuthorization?.();
                }}
              />
            );
          })}
    </Grid>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const Label = styled(Caption)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: "inline-block",
  paddingBottom: theme.spacing(0.5),
}));
