import {
  LOOKUP_SEARCH,
  DATE_PICKER,
  MULTI_SELECT,
  SINGLE_SELECT,
  TEXT_FIELD,
  DATE_RANGE,
  FilterValues,
  dateFilterValue,
} from "./shared";
import { FilterConfig } from "./FiltersPopover";
import { DateRange, DateRangeFilterConfig, apiParams as dateRangeApiParams } from "./DateRangeFilter";
import { DropdownOption } from "../Dropdown";

export function initialFilterValue(filterConfig: FilterConfig, isHardReset?: boolean) {
  switch (filterConfig.filterType) {
    case LOOKUP_SEARCH: {
      return isHardReset ? [] : filterConfig.initialFilterValues || [];
    }
    case DATE_PICKER: {
      return isHardReset ? null : filterConfig.initialFilterValue || null;
    }
    case MULTI_SELECT: {
      return isHardReset ? [] : filterConfig.initialFilterValues || [];
    }
    case SINGLE_SELECT: {
      return isHardReset ? null : filterConfig.initialFilterValue || null;
    }
    case TEXT_FIELD: {
      return isHardReset ? [] : filterConfig.initialFilterValue || [];
    }
    case DATE_RANGE: {
      return isHardReset ? {} : filterConfig.initialFilterValue || {};
    }
    default:
      return null;
  }
}

export function isFilterEmpty(filterConfig: FilterConfig, filterValue: unknown) {
  switch (filterConfig.filterType) {
    case LOOKUP_SEARCH: {
      return !filterValue || (filterValue as unknown[]).length === 0;
    }
    case DATE_PICKER: {
      return !filterValue;
    }
    case MULTI_SELECT: {
      return !filterValue || (filterValue as unknown[]).length === 0;
    }
    case SINGLE_SELECT: {
      return !filterValue;
    }
    case TEXT_FIELD: {
      return !filterValue || (filterValue as string[]).length === 0;
    }
    case DATE_RANGE: {
      if (!filterValue) {
        return true;
      } else {
        const { start, end } = filterValue as DateRange;
        return !start && !end;
      }
    }
    default: {
      // Unknown filters considered empty so they do not show up in UI
      return true;
    }
  }
}

export function areFiltersEmpty(filterConfigs: FilterConfig[], filterValues: FilterValues) {
  return countNumActive(filterConfigs, filterValues) === 0;
}

export function initialFilterValues(filterConfigs: FilterConfig[], isHardReset?: boolean): FilterValues {
  return filterConfigs.reduce(
    (res: FilterValues, config: FilterConfig) =>
      Object.assign(res, { [config.key]: initialFilterValue(config, isHardReset) }),
    {}
  );
}

function identity<T>(x: T) {
  return x;
}

export function isNonNullable<T>(value: T | undefined | null): value is T {
  return value !== null && value !== undefined;
}

export function asParamValue(filterConfig: FilterConfig, filterValue: unknown): string | string[] | null {
  // asParamValue not called on DateRange filters since they have
  // multiple param/value pairs and are handled separately in
  // filterApiParams
  switch (filterConfig.filterType) {
    case LOOKUP_SEARCH: {
      if (!filterValue) {
        return null;
      } else {
        const transformValue = filterConfig.optionToParamValue || (identity as (val: unknown | null) => string | null);
        return (filterValue as unknown[]).map((item: unknown) => transformValue(item || null)).filter(isNonNullable);
      }
    }
    case DATE_PICKER: {
      return dateFilterValue(filterValue as Date | string | null);
    }
    case MULTI_SELECT: {
      return ((filterValue || []) as DropdownOption[]).map(({ id }) => id);
    }
    case SINGLE_SELECT: {
      return (filterValue as DropdownOption)?.id || null;
    }
    case TEXT_FIELD: {
      return (filterValue || []) as string[];
    }
    default: {
      return null;
    }
  }
}

export type FilterParams = { [key: string]: string[] | string | Date };

export function filterApiParams(filterConfigs: FilterConfig[], filterValues: FilterValues): FilterParams {
  const dateRangeFilters = filterConfigs.filter(
    (config: FilterConfig): config is DateRangeFilterConfig => config.filterType === DATE_RANGE
  );
  const nonNullValues: { paramName: string; apiValue: string[] | string | Date }[] = filterConfigs
    .filter((config) => config.filterType !== DATE_RANGE)
    .map((config: FilterConfig): { paramName: string; apiValue: string[] | string | Date } | null => {
      const { key, apiParamName } = config;
      const value = filterValues[key];
      const apiValue = asParamValue(config, value) as string[] | string | Date | null;
      const paramName = apiParamName || key;
      if (apiValue) {
        if (Array.isArray(apiValue)) {
          return apiValue.length === 0 ? null : { paramName, apiValue };
        } else {
          return { paramName, apiValue };
        }
      } else {
        return null;
      }
    })
    .filter(isNonNullable);
  const result: { [key: string]: string[] | string | Date } = {};
  nonNullValues.forEach(({ paramName, apiValue }) => {
    result[paramName] = apiValue;
  });

  const dateRangeParams = dateRangeFilters.map((config: DateRangeFilterConfig) => {
    const value = filterValues[config.key] as DateRange | null;
    return dateRangeApiParams(config, value);
  });

  return Object.assign.apply(Object, [{}, ...dateRangeParams, result]);
}

export function optionToLabelFn(optionToLabel: ((option: unknown) => string) | undefined): (option: unknown) => string {
  return optionToLabel || ((x: unknown) => x as string);
}

export function countNumActive(filterConfigs: FilterConfig[], filterValues: FilterValues): number {
  return filterConfigs.filter((config: FilterConfig) => {
    const value = filterValues[config.key];
    return !isFilterEmpty(config, value);
  }).length;
}

export function filterApiParamNames(filterConfigs: FilterConfig[]): string[] {
  const dateRangeParams = filterConfigs
    .filter(isDateRangeFilter)
    .flatMap((config) => [config.startApiParamName, config.endApiParamName]);
  const otherParams = filterConfigs
    .filter((config) => config.filterType !== DATE_RANGE)
    .map(({ key, apiParamName }) => apiParamName || key);
  return dateRangeParams.concat(otherParams);
}

export function isDateRangeFilter(config: FilterConfig): config is DateRangeFilterConfig {
  return config.filterType === DATE_RANGE;
}
