import { Grid } from "@material-ui/core";
import { H5 } from "@coherehealth/common";
import IntegrationStatusSummary from "./IntegrationStatusSummary";
import { ReferralRequestResponse } from "@coherehealth/core-platform-api";
import EditIntegrationStatus from "../ReferralRequestIntegrationSummary/EditIntegrationStatus";
import { useAuthorized } from "authorization";
import AuthNumber from "../common/AuthNumber";

interface Props {
  referralRequest: ReferralRequestResponse;
  onEdit: (id: string) => void;
}

export default function ReferralRequestIntegrationSummary({ referralRequest, onEdit }: Props) {
  const canViewIntegrationDetails = useAuthorized("VIEW_SERVICE_REQUEST_INTEGRATION");

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <H5 data-public>Integrations</H5>
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
        <AuthNumber
          request={referralRequest}
          onEdit={() => {
            onEdit(referralRequest.id);
          }}
          referralManagement={true}
        />
      </Grid>
      {canViewIntegrationDetails && (
        <>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <IntegrationStatusSummary referralRequest={referralRequest} />
          </Grid>
          <Grid item xs={12}>
            <EditIntegrationStatus referralRequest={referralRequest} onEdit={onEdit} />
          </Grid>
        </>
      )}
    </Grid>
  );
}
