import React from "react";
import { Typography, TypographyTypeMap, makeStyles } from "@material-ui/core";
import { Body1, Caption } from "../Typography";
import { Tooltip } from "../Tooltip";

const useStyles = makeStyles((theme) => ({
  labelWrapper: {
    marginBottom: theme.spacing(0.5),
  },
  fieldLabel: {
    display: "inline-block",
    marginBottom: theme.spacing(0.5),
  },
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  singleLine: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
}));

export interface CardTextFieldProps {
  label: string;
  labelColor?: TypographyTypeMap["props"]["color"];
  labelClassName?: string;
  content?: string | JSX.Element;
  contentClassName?: string;
  singleLine?: boolean;
}

const CardTextField = React.forwardRef(
  (props: CardTextFieldProps, ref: React.ForwardedRef<HTMLDivElement>): JSX.Element => {
    const { label, labelColor, labelClassName, content, contentClassName, singleLine } = props;
    const classes = useStyles();
    let bodyContent = content;
    if ((typeof content === "string" || !content) && singleLine) {
      bodyContent = (
        <Tooltip interactive title={content || "--"}>
          <Typography
            variant="body1"
            className={`${classes?.fieldLabel ?? ""} ${singleLine ? classes.singleLine : ""} ${contentClassName ?? ""}`}
            {...props}
          >
            {content || "--"}
          </Typography>
        </Tooltip>
      );
    } else {
      bodyContent = (
        <Body1
          className={`${classes?.fieldLabel ?? ""} ${singleLine ? classes.singleLine : ""} ${contentClassName ?? ""}`}
          {...props}
        >
          {content || "--"}
        </Body1>
      );
    }
    return (
      <div ref={ref} className={classes.mainWrapper}>
        <Caption
          className={`${classes.labelWrapper} ${labelClassName || ""}`}
          color={labelColor ?? "textSecondary"}
          {...props}
        >
          {label}
        </Caption>
        {bodyContent}
      </div>
    );
  }
);

export default CardTextField;
