import { useState, useContext, useEffect, useCallback } from "react";
import { H5, SingleSelectDropdown, TextField, queueMgmtBaseUrl } from "@coherehealth/common";
import { makeStyles, Grid, Divider, CircularProgress } from "@material-ui/core";
import { useFeature } from "@coherehealth/common";
import { useSendFax, SendFaxResponse, useGetFaxCloseOptions, CloseOption } from "@coherehealth/core-platform-api";
import { CommonFaxSidebarViewProps, INCOMING_FAX_SIDEBAR_WIDTH, backToMenuView } from "../common";
import FaxMetaDataDisplay from "../FaxMetaDataDisplay";
import UnsolvableCaseModal from "./UnsolvableCaseModal";
import { FaxAttachmentContext } from "../FaxAttachmentContext";
import { useCompleteServiceCase, useUpdateServiceCase } from "@coherehealth/qm-api";
import config from "api/config";
import { useBelongsToOpsGroup } from "authorization";
import { useSnackbar } from "notistack";
import CommonFaxFooter from "../CommonFaxFooter";
import { translateServiceCaseHealthPlan } from "util/serviceCaseUtils";

type ClaimSolved = "TRUE" | "FALSE";
const claimSolvedOptions: { id: ClaimSolved; label: string }[] = [
  { id: "TRUE", label: "Yes, I resolved it" },
  { id: "FALSE", label: "No, I did not resolve it" },
];

const unassignmentLabel: Record<string, string> = {
  CLAIM: "Claims",
  MISSING_NPI: "Missing NPI",
  DONT_KNOW: "Unable to work",
  SEND_TO_ESSETTE: "Sent to essette for completion",
};
const UnworkableFaxOptions = [
  "HEALTH_HELP",
  "CLAIM",
  "FAXBACK",
  "MISSING_NPI",
  "DONT_KNOW",
  "SEND_TO_ESSETTE",
  "ANOTHER_SYSTEM",
  "OUT_OF_SCOPE",
] as const;
type UnworkableFaxOption = typeof UnworkableFaxOptions extends ReadonlyArray<infer T> ? T : never;
let hardCodedReasonForUnworkableFaxOptions: { id: UnworkableFaxOption; label: string }[] = [
  // legacy, remove with removal of useFaxCloseOptionConfiguration
  { id: "HEALTH_HELP", label: "This is a Health Help case and I have resolved it" },
  { id: "CLAIM", label: "This is a claims case" },
  { id: "MISSING_NPI", label: "Missing NPI" },
  { id: "DONT_KNOW", label: "I don’t know how to work this case" },
];

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "column",
  },
  buttonContainer: {
    marginTop: "auto",
    marginLeft: 1,
    marginBottom: theme.spacing(3),
    width: "100%",
  },
  leftButton: {
    alignItems: "flex-start",
    margin: "0px 8px 0px 12px",
  },
  rightButton: {
    minWidth: theme.spacing(25),
    margin: "0px 12px 0px 8px",
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
}));

export default function UnworkableFax({ setSidebarView, url, blob, fileData, serviceCase }: CommonFaxSidebarViewProps) {
  const { enqueueSnackbar } = useSnackbar();
  const isGhpUser = useBelongsToOpsGroup("GEISINGER");
  const faxCloseOptionConfigurationEnabled = useFeature("useFaxCloseOptionConfiguration");
  const [reasonForUnworkableFaxOptions, setReasonForUnworkableFaxOptions] = useState<CloseOption[]>([]);

  // legacy, remove with removal of useFaxCloseOptionConfiguration
  if (isGhpUser) {
    hardCodedReasonForUnworkableFaxOptions = [
      { id: "HEALTH_HELP", label: "This is a Health Help case and I have resolved it" },
      { id: "SEND_TO_ESSETTE", label: "To be processed in Essette" },
      { id: "MISSING_NPI", label: "Missing NPI" },
      { id: "DONT_KNOW", label: "I don’t know how to work this case" },
    ];
  } else if (serviceCase?.healthPlan === "HIGHMARK") {
    hardCodedReasonForUnworkableFaxOptions = [
      { id: "HEALTH_HELP", label: "This is a Health Help case and I have resolved it" },
      { id: "CLAIM", label: "This is a claims case" },
      { id: "MISSING_NPI", label: "Missing NPI" },
      { id: "DONT_KNOW", label: "I don’t know how to work this case" },
      { id: "ANOTHER_SYSTEM", label: "Completed in another system" },
      { id: "OUT_OF_SCOPE", label: "Out of scope" },
      { id: "FAXBACK", label: "Faxed back to provider" },
    ];
  }

  const classes = useStyles();
  const [reasonUnworkableFax, setReasonForUnworkableFax] = useState<string>();
  const [claimSolved, setClaimSolved] = useState<ClaimSolved | undefined>();
  const [workNotes, setWorkNotes] = useState<string | undefined>();
  const [unworkableModalOpen, setUnworkableModalOpen] = useState<boolean>(false);
  const { caseId, faxAttachment } = useContext(FaxAttachmentContext);
  const { mutate: completeServiceCase, loading: serviceCaseLoading } = useCompleteServiceCase({
    id: caseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
    onMutate: () => {
      window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${caseId}`);
    },
  });

  const { mutate: getFaxCloseOptions, loading: faxCloseOptionsLoading } = useGetFaxCloseOptions({
    queryParams: {
      featureConfigurationLevel: "HEALTH_PLAN",
      healthPlanName: faxAttachment?.healthPlanName || translateServiceCaseHealthPlan(serviceCase?.healthPlan) || "",
    },
  });

  const fetchFaxCloseOptions = useCallback(async () => {
    if (faxAttachment?.healthPlanName || serviceCase?.healthPlan) {
      const options = await getFaxCloseOptions();
      setReasonForUnworkableFaxOptions(options);
    }
  }, [getFaxCloseOptions, faxAttachment?.healthPlanName, serviceCase?.healthPlan]);

  useEffect(() => {
    if (faxCloseOptionConfigurationEnabled) {
      fetchFaxCloseOptions();
    }
  }, [
    faxCloseOptionConfigurationEnabled,
    fetchFaxCloseOptions,
    faxAttachment?.healthPlanName,
    serviceCase?.healthPlan,
  ]);

  const { mutate: sendFax, loading: sendFaxLoading } = useSendFax({});
  const sendFaxToEssette = async () => {
    const formData = new FormData();
    if (blob) {
      formData.set("file", blob);
      formData.set("recipientName", "ESSETTE");
      formData.set("serviceCaseId", caseId || "");
    }

    const resp = await sendFax(formData as unknown as void);
    const response = resp as SendFaxResponse[];
    if (response[0]?.isSuccess) {
      return response[0]?.isSuccess;
    } else {
      enqueueSnackbar("Failed to send fax", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  };

  const { mutate: updateServiceCase, loading: updatingServiceCase } = useUpdateServiceCase({
    id: caseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
  });

  const requiresMoreAction =
    !reasonUnworkableFax ||
    ((reasonUnworkableFax === "HEALTH_HELP" || (reasonUnworkableFax === "CLAIM" && claimSolved === "TRUE")) &&
      !workNotes) ||
    (reasonUnworkableFax === "CLAIM" && !claimSolved);

  const shouldBeUnassigned = reasonUnworkableFax === "DONT_KNOW";

  async function finishCase() {
    if (!requiresMoreAction && isGhpUser && reasonUnworkableFax === "SEND_TO_ESSETTE") {
      try {
        const isSuccess = await sendFaxToEssette();
        if (isSuccess) {
          completeServiceCase({
            outcome: reasonUnworkableFax,
            dateCompleted: new Date().toISOString(),
            description: "Sent to Essette",
            caseNotes: "No case Notes",
          });
        }
      } catch {
        enqueueSnackbar("Failed to complete case", {
          variant: "error",
          preventDuplicate: true,
        });
      }
    } else if (shouldBeUnassigned) {
      updateServiceCase({
        caseStatus: "OPEN",
        assignee: {
          name: `Unassigned - ${unassignmentLabel[reasonUnworkableFax] || "Unknown"}`,
          userName: `Unassigned - ${unassignmentLabel[reasonUnworkableFax] || "Unknown"}`,
          salesforceId: "",
        },
      });
      setUnworkableModalOpen(true);
    } else if (!requiresMoreAction) {
      completeServiceCase({
        outcome: reasonUnworkableFax,
        dateCompleted: new Date().toISOString(),
        description: reasonForUnworkableFaxOptions.find((option) => option.identifier === reasonUnworkableFax)?.label,
        caseNotes: workNotes,
      });
    } else {
      enqueueSnackbar("Failed to complete case - outcome unknown", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }

  const getFaxCloseDropdownOptions = () => {
    if (faxCloseOptionConfigurationEnabled) {
      return reasonForUnworkableFaxOptions.map((option) => {
        return { id: option.identifier, label: option.label };
      });
    } else {
      return hardCodedReasonForUnworkableFaxOptions;
    }
  };
  return (
    <div className={classes.container}>
      <FaxMetaDataDisplay fileData={fileData} url={url} serviceCase={serviceCase} />
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <H5 data-public>Select one of the options below</H5>
        </Grid>
        <Grid item xs={12}>
          <>
            {!(reasonForUnworkableFaxOptions.length === 0 && faxCloseOptionsLoading) ? (
              <SingleSelectDropdown
                label="Select one of the options below"
                options={getFaxCloseDropdownOptions()}
                value={reasonUnworkableFax}
                onChange={(val) => {
                  setWorkNotes(undefined);
                  setClaimSolved(undefined);
                  setReasonForUnworkableFax(val);
                }}
                menuWidth={INCOMING_FAX_SIDEBAR_WIDTH - 48}
                dataPublic
              />
            ) : (
              <CircularProgress />
            )}
          </>
        </Grid>
        {reasonUnworkableFax === "CLAIM" && (
          <Grid item xs={12}>
            <SingleSelectDropdown
              label="Did you resolve the case?"
              options={claimSolvedOptions}
              value={claimSolved}
              onChange={(val) => {
                assertIsValidClaimReason(val);
                setClaimSolved(val);
              }}
              menuWidth={INCOMING_FAX_SIDEBAR_WIDTH - 48}
              dataPublic
            />
          </Grid>
        )}
        {(reasonUnworkableFax === "HEALTH_HELP" || claimSolved === "TRUE") && (
          <Grid item xs={12}>
            <TextField
              label={workNotes ? "Work notes" : "Add work notes here"}
              value={workNotes}
              onChangeValue={setWorkNotes}
              minRows={6}
              multiline
              fullWidth
            />
          </Grid>
        )}
      </Grid>
      <CommonFaxFooter
        onPrimaryButtonClick={finishCase}
        disabledFinish={requiresMoreAction || sendFaxLoading}
        primaryButtonLoading={serviceCaseLoading || updatingServiceCase || sendFaxLoading}
        onCancelButtonClick={() => {
          setSidebarView(backToMenuView);
        }}
        setSidebarView={setSidebarView}
      />
      <UnsolvableCaseModal open={unworkableModalOpen} setOpen={setUnworkableModalOpen} />
    </div>
  );
}
function assertIsValidClaimReason(reason: string): asserts reason is ClaimSolved {
  if (!["TRUE", "FALSE"].includes(reason)) {
    throw new Error("Invalid claim resolution");
  }
}
