import { ReturnRequestTo, ReturnReviewReason } from "@coherehealth/core-platform-api";

/**
 * Interface to describe the fields used for <SingleSelectDropdown /> and <MultiSelectDropdown />
 * Components for the field that describes the Clinical Reviewer to return the request to.
 */
export interface ReturnRequestToOption {
  id: NonNullable<ReturnRequestTo>;
  label: string;
}

/**
 * Interface to describe the fields used for <SingleSelectDropdown /> and <MultiSelectDropdown />
 * Components for the field that describes the reasons why a Service Request could be returned to
 * the Clinical Reviewer after it got previously denied.
 */
export interface ReturnToReviewReasonOption {
  id: NonNullable<ReturnReviewReason>;
  label: string;
}

/**
 * Labels/Enum mapping for the Return Review Reasons sets.
 */
export const returnReviewReasonsLabels: Map<ReturnReviewReason, string> = new Map([
  ["ADD_OR_REMOVE_GUIDELINE_USED_FOR_REVIEW", "Add or remove guideline used for review"],
  ["REVISIT_THE_GUIDELINE_CRITERIA", "Revisit the guideline criteria"],
  ["ADD_OR_AMEND_DENIAL_REASONING_EXPLANATION", "Add or amend denial reasoning explanation"],
  ["REVISIT_PARTIALLY_APPROVED_DENIED_CPT_CODES", "Revisit partially approved denied CPT codes"],
  ["OTHER", "Other"],
]);

/**
 * Set of Reasons why a case is returned to Clinical Review after previously being denied.
 */
export const reasonOptions: ReturnToReviewReasonOption[] = [
  {
    id: "ADD_OR_REMOVE_GUIDELINE_USED_FOR_REVIEW",
    label: returnReviewReasonsLabels.get("ADD_OR_REMOVE_GUIDELINE_USED_FOR_REVIEW") ?? "",
  },
  {
    id: "REVISIT_THE_GUIDELINE_CRITERIA",
    label: returnReviewReasonsLabels.get("REVISIT_THE_GUIDELINE_CRITERIA") ?? "",
  },
  {
    id: "ADD_OR_AMEND_DENIAL_REASONING_EXPLANATION",
    label: returnReviewReasonsLabels.get("ADD_OR_AMEND_DENIAL_REASONING_EXPLANATION") ?? "",
  },
  {
    id: "REVISIT_PARTIALLY_APPROVED_DENIED_CPT_CODES",
    label: returnReviewReasonsLabels.get("REVISIT_PARTIALLY_APPROVED_DENIED_CPT_CODES") ?? "",
  },
  {
    id: "OTHER",
    label: returnReviewReasonsLabels.get("OTHER") ?? "",
  },
];

/**
 * Set of Clinical Reviewers that a Service Case could be return to for a re-review after being
 * previously denied.
 */
export const returnRequestToOptions: ReturnRequestToOption[] = [
  {
    id: "MD_REVIEW",
    label: "MD",
  },
  {
    id: "NURSE_REVIEW",
    label: "Clinical",
  },
];
