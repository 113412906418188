import React, { FunctionComponent } from "react";
import { FlexGridItem } from "common/SharedServiceRequestFormComponents";
import OutOfNetworkBanner from "components/ServiceRequest/ServiceRequestForm/components/OutOfNetworkBanner";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import { Body2, colorsLight } from "@coherehealth/common";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { CircularProgress } from "@material-ui/core";

interface Props {
  isFacilityOON: boolean;
  oonLoading?: boolean;
  inNetwork?: boolean;
  oonExceptionConfig: "REQUIRED" | "OPTIONAL" | "OMIT";
}

const OONFacilityStatusDisplay: FunctionComponent<Props> = ({
  oonLoading,
  inNetwork,
  isFacilityOON,
  oonExceptionConfig,
}) => {
  const classes = customStyles();
  if (!oonLoading) {
    if (isFacilityOON) {
      return (
        <FlexGridItem container>
          <OutOfNetworkBanner
            orderingProviderOON={false}
            performingProviderOON={false}
            performingProviderPracticeOON={false}
            facilityOON={isFacilityOON}
            careParticipantOON={false}
            oonExceptionConfig={oonExceptionConfig}
          ></OutOfNetworkBanner>
        </FlexGridItem>
      );
    } else if (inNetwork) {
      return (
        <FlexGridItem>
          <OONStatusContainer>
            <IconContainer>
              <SuccessIcon />
            </IconContainer>
            <VerticallyCenteredContainer>
              <VerticallyCenteredItem>
                <OONStatusText>Network check complete</OONStatusText>
              </VerticallyCenteredItem>
            </VerticallyCenteredContainer>
          </OONStatusContainer>
        </FlexGridItem>
      );
    } else {
      return <></>;
    }
  } else {
    return (
      <FlexGridItem>
        <OONStatusContainer>
          <div className={classes.runningIconContainer}>
            <CircularProgress size={24} />
          </div>
          <VerticallyCenteredContainer>
            <VerticallyCenteredItem>
              <OONRunningNetworkCheck>Running network check</OONRunningNetworkCheck>
            </VerticallyCenteredItem>
          </VerticallyCenteredContainer>
        </OONStatusContainer>
      </FlexGridItem>
    );
  }
};

export default OONFacilityStatusDisplay;

const customStyles = makeStyles((theme) => ({
  runningIconContainer: {
    marginRight: "8px",
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const OONStatusContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: theme.spacing(-1),
  marginTop: theme.spacing(-0.25),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const IconContainer = styled("div")(({ theme }) => ({
  justifyContent: "center",
  width: "24px",
  height: "24px",
  marginRight: "2px",
}));

// Centered vertically next to a 24px block
// eslint-disable-next-line cohere-react/no-mui-styled-import
const VerticallyCenteredContainer = styled("div")(({ theme }) => ({
  display: "table",
  height: "18px",
  overflow: "hidden",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const VerticallyCenteredItem = styled("div")(({ theme }) => ({
  display: "table-cell",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const OONStatusText = styled(Body2)(({ theme }) => ({
  fontFamily: "Gilroy-SemiBold",
  color: theme.palette.success.dark,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SuccessIcon = styled(CheckCircleIcon)(({ theme }) => ({
  color: theme.palette.success.main,
  fontSize: "20px",
  marginRight: "8px",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const OONRunningNetworkCheck = styled(Body2)(() => ({
  fontFamily: "Gilroy-SemiBold",
  color: colorsLight.font.secondary,
}));
