import { makeStyles } from "@material-ui/core";

export const useSRFormStyles = makeStyles((theme) => ({
  caption: {
    color: theme.palette.text.secondary,
  },
  card: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(3),
  },
}));
