import React from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Link, styled } from "@material-ui/core";
import { Body1, colorsLight, H4 } from "@coherehealth/common";
import { error as logError } from "logger";

interface Props {
  delegatedVendor?: string;
  delegatedVendorDisplay: (dv: string | undefined) => string | undefined;
}

export default function VendorAssessmentFailed({ delegatedVendor, delegatedVendorDisplay }: Props) {
  logError(new Error(`Vendor assessment has failed: user has been completely blocked`));
  const vendorNameText = delegatedVendor ? delegatedVendorDisplay(delegatedVendor) : "our third party vendor";
  return (
    <SpacingContainer>
      <TitleText>Clinical assessment questions from our vendor</TitleText>
      <Message>
        An issue has occurred with {vendorNameText}’s questionnaire. Please submit your imaging request at
        <Link href="https://portal.healthhelp.com/cohere" target="_blank" rel="noopener noreferrer">
          {" "}
          https://portal.healthhelp.com/cohere
        </Link>
      </Message>

      <Message>
        If you have non-imaging service requests, please first remove your imaging codes before continuing by navigating
        back to the "Fill in the Details" page of the Service Request.
      </Message>
    </SpacingContainer>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SpacingContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 0, 1, 0),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Message = styled(Body1)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: colorsLight.font.main,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const TitleText = styled(H4)(({ theme }) => ({
  color: colorsLight.font.main,
}));
