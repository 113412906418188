import { Add } from "@material-ui/icons";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";

import { Chip, colorsLight } from "@coherehealth/common";

// * File tag RECENT_PROVIDERS_8B12CDB1

interface RecentPillProps {
  onClick: () => void;
  label: string;
}

export default function RecentPill({ onClick, label }: RecentPillProps) {
  return label ? (
    <RecentProvidersChip
      size="small"
      onClick={onClick}
      label={label}
      Icon={() => {
        return <Add style={{ fontSize: "1.3rem", marginRight: "4px" }} />;
      }}
      data-public
    />
  ) : null;
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RecentProvidersChip = styled(Chip)(({ theme }) => ({
  "&.MuiChip-root": {
    paddingLeft: theme.spacing(1),
    backgroundColor: colorsLight.primary.transparent,
    "&:hover": {
      backgroundColor: colorsLight.primary.transparentDark,
    },
  },
}));
