import { Grid } from "@material-ui/core";
import { RadioGroup } from "@coherehealth/common";
import { ChangeStatusFormState } from "../utils";
import { helperTextForTATExtension } from "components/ServiceRequest/ReviewSection/util/QueueManagementReviewUtil";
import { ServiceRequestResponse, ServiceRequestSearchResponse } from "@coherehealth/core-platform-api";

interface PendingMissingInfoFormProps {
  setFormState: (value: React.SetStateAction<ChangeStatusFormState>) => void;
  formState: ChangeStatusFormState;
  selectedServiceRequest: ServiceRequestResponse | ServiceRequestSearchResponse;
}

export default function PendingMissingInfoForm(props: PendingMissingInfoFormProps): JSX.Element {
  const { formState, selectedServiceRequest, setFormState } = props;
  return (
    <>
      <Grid item xs={12}>
        <RadioGroup
          row
          label="Do you want to extend the due date of this request?"
          options={[
            { id: "YES", label: "Yes" },
            { id: "NO", label: "No" },
          ]}
          disabled={!formState.tatExtension?.eligible}
          helperText={helperTextForTATExtension(
            formState.tatExtension?.decision || "",
            !!formState.tatExtension?.eligible,
            selectedServiceRequest
          )}
          value={formState.tatExtension?.decision}
          onChange={(opt) => {
            setFormState({
              ...formState,
              tatExtension: {
                ...formState.tatExtension,
                decision: opt,
              },
            });
          }}
          data-testid={"pending-missing-info-radio-group"}
        />
      </Grid>
    </>
  );
}
