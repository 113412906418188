import { HighlightStateContext } from "@coherehealth/common";
import { Box, BoxProps, useTheme, makeStyles, useMediaQuery } from "@material-ui/core";
import { RIGHT_HAND_PANEL_SIZE } from "components/ClinicalReview/reviewUtils/utils";
import { ForwardedRef, PropsWithChildren, ReactNode, forwardRef, useContext } from "react";

interface Props {
  header: ReactNode;
  leftColumnTabs?: ReactNode;
  leftColumn: ReactNode;
  rightColumn: ReactNode;
  attachmentView: boolean;
}

const ClinicalReviewShell = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const { header, leftColumnTabs, leftColumn, rightColumn, attachmentView } = props;
  const theme = useTheme();
  const matchesLeft = useMediaQuery(theme.breakpoints.up(1681));
  const matchesRight = useMediaQuery(theme.breakpoints.up(RIGHT_HAND_PANEL_SIZE));
  const { linking } = useContext(HighlightStateContext);
  return (
    <div style={{ overflow: "hidden", height: "100vh" }}>
      {header}
      <Box display="flex" height="calc(100% - 107px)" style={{ justifyContent: "center" }}>
        <FullWidthLeftColumn left>
          <div
            style={{
              width: "100%",
              overflowY: attachmentView ? "hidden" : "auto",
              position: "relative",
              overflowX: "hidden",
            }}
            ref={ref}
          >
            {leftColumnTabs}
            <div style={linking ? { marginTop: "8px" } : {}}>
              {attachmentView ? (
                <FullWidthLeftColumn left>{leftColumn}</FullWidthLeftColumn>
              ) : (
                <DynamicWidthLeftColumn
                  left
                  marginLeft={matchesLeft ? "auto" : "32px"}
                  marginRight={matchesLeft ? "auto" : 0}
                >
                  {leftColumn}
                </DynamicWidthLeftColumn>
              )}
            </div>
          </div>
        </FullWidthLeftColumn>
        <RightColumn right width={matchesRight ? 712 : "calc(100vw - 728px)"} style={{ background: "white" }}>
          <Box
            style={{
              width: "100%",
              overflowY: "auto",
              position: "relative",
              overflowX: "hidden",
              height: "100%",
            }}
          >
            {rightColumn}
          </Box>
        </RightColumn>
      </Box>
    </div>
  );
});

interface ColumnProps {
  left?: boolean;
  right?: boolean;
}

const useStyle = makeStyles((theme) => ({
  leftColumnBoxStyle: {
    width: 680,
    [theme.breakpoints.up(RIGHT_HAND_PANEL_SIZE)]: {
      width: "calc(100vw - 760px)",
    },
    [theme.breakpoints.up(1681)]: {
      width: 920,
    },
  },
  leftColumnTabsStyle: {
    width: "calc(100vw - 712px)",
    [theme.breakpoints.down(RIGHT_HAND_PANEL_SIZE)]: {
      width: 728,
    },
  },
}));

const DynamicWidthLeftColumn = ({ left, right, ...other }: PropsWithChildren<ColumnProps | BoxProps>) => {
  const classes = useStyle();
  return (
    <Box className={classes.leftColumnBoxStyle} component="div" display="flex" flexDirection="column" {...other} />
  );
};

const FullWidthLeftColumn = ({ left, right, ...other }: PropsWithChildren<ColumnProps | BoxProps>) => {
  const classes = useStyle();
  return (
    <Box className={classes.leftColumnTabsStyle} component="div" display="flex" flexDirection="column" {...other} />
  );
};

const RightColumn = ({ left, right, ...other }: PropsWithChildren<ColumnProps | BoxProps>) => (
  <Box component="div" display="flex" flexDirection="column" {...other} />
);

export default ClinicalReviewShell;
