import { ClausalCondition } from "@coherehealth/core-platform-api";

const processPatientFilter = (patientId: string): ClausalCondition => {
  return {
    clause: "MUST",
    conditions: [{ key: "cohereEntityId", operator: "eq", value: patientId }],
  } as ClausalCondition;
};

const processProcedureCodeFilter = (procedureCodes?: string[]): ClausalCondition | undefined => {
  if (procedureCodes && procedureCodes.length > 0) {
    return {
      clause: "MUST",
      conditions: [
        {
          key: "findingType.extensions.name",
          operator: "eq",
          value: "ProcedureCodes",
        },
        {
          key: "findingType.extensions.valueListString",
          operator: "inlist",
          value: procedureCodes,
        },
      ],
    } as ClausalCondition;
  }
};

const processDiagnosisCodeFilter = (diagnosisCodes?: string[]): ClausalCondition | undefined => {
  if (diagnosisCodes && diagnosisCodes.length > 0) {
    return {
      clause: "MUST",
      conditions: [
        {
          key: "findingType.value.valueListString",
          operator: "inlist",
          value: diagnosisCodes,
        },
      ],
    } as ClausalCondition;
  }
};

const processServiceDateStartFilter = (serviceDateStart?: Date): ClausalCondition | undefined => {
  if (serviceDateStart) {
    return {
      clause: "MUST",
      conditions: [
        {
          key: "findingInstant",
          operator: "gt",
          value: serviceDateStart.toString(),
        },
      ],
    } as ClausalCondition;
  }
};

const processServiceDateEndFilter = (serviceDateEnd?: Date): ClausalCondition | undefined => {
  if (serviceDateEnd) {
    return {
      clause: "MUST",
      conditions: [
        {
          key: "findingInstant",
          operator: "lt",
          value: serviceDateEnd.toString(),
        },
      ],
    } as ClausalCondition;
  }
};

export {
  processPatientFilter,
  processProcedureCodeFilter,
  processDiagnosisCodeFilter,
  processServiceDateStartFilter,
  processServiceDateEndFilter,
};
