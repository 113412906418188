import {
  useCallback,
  useMemo,
  useState,
  useContext,
  ReactNode,
  CSSProperties,
  forwardRef,
  Dispatch,
  SetStateAction,
  useRef,
  useEffect,
  RefObject,
} from "react";
import { AttachmentGuidelineTextHighlight, Guideline, Indication } from "@coherehealth/core-platform-api";
import { Body3, Subtitle1 } from "../Typography";
import { Checkbox } from "../Checkbox";
import { Sanitized } from "../Sanitized";
import { Alert } from "../Alert";
import {
  DynamicRuleCondition,
  HighlightStateContext,
  DocumentedSpan,
  IndicationId,
  IndicationHighlight,
  DynamicIndicationOutcome,
  getIndicationText,
  DynamicIndication,
} from "../../util";
import {
  useFeature,
  UnmetCheckboxIcon,
  UndecidedCheckboxIcon,
  TextField,
  PrimaryButton,
  SecondaryButton,
} from "../../components";
import { colorsLight, colorsDark } from "../../themes";
import { useGuidelineIndication } from "../../hooks";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled, Theme, useTheme } from "@material-ui/core/styles";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckboxIcon from "@material-ui/icons/CheckBox";
import UncheckedIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseIcon from "@material-ui/icons/Close";
import PencilIcon from "@material-ui/icons/Create";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import CheckIcon from "@material-ui/icons/Check";
import UndoIcon from "@material-ui/icons/Undo";
import { ButtonBase, IconButton } from "@material-ui/core";
import { Label } from "@material-ui/icons";
import FlipMove from "react-flip-move";
import { useSnackbar } from "notistack";
import { DynamicElemBlock, generateDynamicElementBlocks } from "../../util/guidelineIndicationOperatorParser";

export interface ToggleResults {
  checkedIndications: Indication[];
  unmetIndications: Indication[];
}

export interface IndicationsReviewProps {
  guideline: Guideline | null;
  checkedIndications: Indication[];
  unmetIndications: Indication[];
  readonly?: boolean;
  hideDynamicRuleIcons?: boolean;
  hideVerticalLines?: boolean;
  onCheckIndication?: (
    checkedIndication: Indication,
    /** helper function to remove or add checked/unchecked indication to list of indications */
    toggleFunc: (metIndications: Indication[], unmetIndications: Indication[], met: boolean) => ToggleResults,
    met: boolean
  ) => void;
  /**if the first child of an indication is also an indication, this means there's no static
   * description text to display for the indication. setting hideHeadlessIndications to true
   * will hide the "empty" indication wrapper & dynamic rule icon.
   */
  hideHeadlessIndications?: boolean;
  viewOnlyHighlights?: AttachmentGuidelineTextHighlight[] | null;
  openAttachment?: (attachmentId: string) => void;
  isCPP?: boolean;
}

interface RecursiveProps {
  indication: DynamicIndication;
  hideDynamicRuleIcons?: boolean;
  checkedIndications: Indication[];
  unmetIndications: Indication[];
  readonly?: boolean;
  depth: number;
  hideVerticalLines?: boolean;
  onCheckIndication?: (
    checkedIndication: Indication,
    /** helper function to remove or add checked/unchecked indication to list of indications */
    toggleFunc: (metIndications: Indication[], unmetIndications: Indication[], met: boolean) => ToggleResults,
    met: boolean
  ) => void;
  hideHeadlessIndications?: boolean;
  indicationOutcome?: DynamicIndicationOutcome;
  guidelineElemRefs: Element[];
  last?: boolean;
  viewOnlyIndicationDocumentations?: Record<IndicationId, DocumentedSpan[]> | null;
  openAttachment?: (attachmentId: string) => void;
  isCPP?: boolean;
}

interface BaseIndicationProps {
  elementBlock: DynamicElemBlock;
  indication?: DynamicIndication;
  checkedIndications?: Indication[];
  unmetIndications?: Indication[];
  onCheckIndication?: (
    checkedIndication: Indication,
    /** helper function to remove or add checked/unchecked indication to list of indications */
    toggleFunc: (metIndications: Indication[], unmetIndications: Indication[], met: boolean) => ToggleResults,
    met: boolean
  ) => void;
  readonly?: boolean;
  indicationOutcome?: DynamicRuleCondition;
  hasChildIndication?: boolean;
  hideDynamicRuleIcons?: boolean;
  last?: boolean;
  viewOnlyDocumentedSpans?: DocumentedSpan[] | null;
  openAttachment?: (attachmentId: string) => void;
  isCPP?: boolean;
}

interface DocumentedHighlightContainerProps {
  citedHighlights?: DocumentedSpan[];
  suggestedHighlights?: DocumentedSpan[];
  indication?: DynamicIndication;
  readonly?: boolean;
  openAttachment?: (attachmentId: string) => void;
  doubleCheckbox?: boolean;
  isCPP?: boolean;
  indicationExpanded?: boolean;
  isHighlightImprovementsEnabled?: boolean;
}

interface DocumentedHighlightProps {
  span: DocumentedSpan;
  indicationId?: string;
  readOnly?: boolean;
  openAttachment?: (attachmentId: string) => void;
  doubleCheckbox?: boolean;
  isCPP?: boolean;
  isCited?: boolean;
  isHighlightImprovementsEnabled?: boolean;
  parentRef?: RefObject<HTMLDivElement>;
}

interface EditHighlightProps {
  span: DocumentedSpan;
  setEditing: Dispatch<SetStateAction<IndicationHighlight | undefined>> | undefined;
  saveEdit:
    | ((attachmentId: string, editText: string, editStartPage: string, editEndPage: string | undefined) => void)
    | undefined;
  isCPP?: boolean;
  isHighlightImprovementsEnabled?: boolean;
  parentRef?: RefObject<HTMLDivElement>;
}

export default function IndicationsOperatorReview({
  guideline,
  checkedIndications,
  unmetIndications,
  readonly,
  hideDynamicRuleIcons,
  hideVerticalLines,
  onCheckIndication,
  hideHeadlessIndications,
  viewOnlyHighlights,
  openAttachment,
  isCPP,
}: IndicationsReviewProps) {
  const { indicationBlocks, isCriteriaMet, guidelineElemRefs } = useGuidelineIndication({
    guideline,
    checkedIndications,
    unmetIndications,
  });

  const viewOnlyIndicationDocumentations: Record<IndicationId, DocumentedSpan[]> =
    readonly && !!viewOnlyHighlights ? transformIndicationHighlights(viewOnlyHighlights) : {};
  return (
    <div style={{ marginLeft: 0 }}>
      {!hideDynamicRuleIcons && (
        <AlertContainer>
          {!guideline?.indications ||
          (guideline?.indications && guideline?.indications.length === 0) ? null : isCriteriaMet ? (
            <Alert message="Guideline criteria met" />
          ) : (
            <Alert severity="error" message="Guideline criteria not met" />
          )}
        </AlertContainer>
      )}
      <BaseContainer>
        {indicationBlocks &&
          indicationBlocks.map((block: DynamicElemBlock, index: number) =>
            block.indication && block.indicationIndex !== undefined ? (
              <RecursiveIndicationComponent
                key={block.indication.uid}
                indication={block.indication}
                hideDynamicRuleIcons={hideDynamicRuleIcons}
                checkedIndications={checkedIndications}
                unmetIndications={unmetIndications}
                readonly={readonly}
                depth={0}
                hideVerticalLines={hideVerticalLines}
                onCheckIndication={onCheckIndication}
                hideHeadlessIndications={hideHeadlessIndications}
                indicationOutcome={block.indication.dynamicOutcome}
                guidelineElemRefs={guidelineElemRefs}
                viewOnlyIndicationDocumentations={viewOnlyIndicationDocumentations}
                openAttachment={openAttachment}
                isCPP={isCPP}
              />
            ) : (
              <StaticComponent elementBlock={block} key={block.htmlString} />
            )
          )}
      </BaseContainer>
    </div>
  );
}

function RecursiveIndicationComponent({
  indication,
  hideDynamicRuleIcons,
  checkedIndications,
  unmetIndications,
  readonly,
  depth,
  hideVerticalLines,
  onCheckIndication,
  hideHeadlessIndications,
  indicationOutcome,
  guidelineElemRefs,
  last,
  viewOnlyIndicationDocumentations,
  openAttachment,
  isCPP,
}: RecursiveProps) {
  const blocks = generateDynamicElementBlocks(
    guidelineElemRefs,
    indication.guidelineHtmlIndices ?? [],
    indication.indications ?? []
  );
  const theme = useTheme();
  if (blocks.length === 1) {
    return (
      <StaticComponent
        elementBlock={blocks[0]}
        checkedIndications={checkedIndications}
        unmetIndications={unmetIndications}
        indication={indication}
        readonly={readonly}
        onCheckIndication={onCheckIndication}
        last={last}
        viewOnlyDocumentedSpans={
          viewOnlyIndicationDocumentations && indication?.uid && viewOnlyIndicationDocumentations[indication.uid]
            ? viewOnlyIndicationDocumentations[indication.uid]
            : []
        }
        openAttachment={openAttachment}
        isCPP={isCPP}
      />
    );
  }

  const firstBlockIsIndication = blocks.length > 0 && !!blocks[0].indication;
  const shouldCollapse = hideHeadlessIndications && firstBlockIsIndication;
  const dynamicOutcome = indicationOutcome;
  return (
    <div
      style={{
        display: "flex",
        marginLeft: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        boxSizing: "border-box",
      }}
    >
      {indication.indications && indication.indications.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "14px",
          }}
        >
          {dynamicOutcome === "PASS" ? (
            <SuccessIcon depth={depth} />
          ) : dynamicOutcome === "FAIL" ? (
            <CrossIcon depth={depth} />
          ) : (
            <QuestionMark depth={depth} />
          )}
          <div
            style={{
              display: "flex",
              width: theme.spacing(0.5),
              background: colorsLight.gray.divider,
              flexGrow: 11,
              marginRight: 2,
              marginTop: hideDynamicRuleIcons ? theme.spacing(1) : 0,
              visibility: hideVerticalLines || shouldCollapse ? "hidden" : "visible",
            }}
          />
        </div>
      )}
      <div
        style={{
          marginTop: firstBlockIsIndication && !shouldCollapse ? theme.spacing(2.5) : 0,
        }}
      >
        {blocks.map((block, index) =>
          block.indication && block.indicationIndex !== undefined ? (
            <RecursiveIndicationComponent
              indication={block.indication}
              hideDynamicRuleIcons={hideDynamicRuleIcons}
              checkedIndications={checkedIndications}
              unmetIndications={unmetIndications}
              readonly={readonly}
              key={block.indication.uid}
              depth={depth + 1}
              hideVerticalLines={hideVerticalLines}
              onCheckIndication={onCheckIndication}
              hideHeadlessIndications={hideHeadlessIndications}
              indicationOutcome={block.indication.dynamicOutcome}
              guidelineElemRefs={guidelineElemRefs}
              last={false}
              viewOnlyIndicationDocumentations={viewOnlyIndicationDocumentations}
              openAttachment={openAttachment}
              isCPP={isCPP}
            />
          ) : (
            <StaticComponent
              elementBlock={block}
              hasChildIndication={index === 0}
              key={block.htmlString}
              last={index + 1 === blocks.length}
              isCPP={isCPP}
              readonly={readonly}
            />
          )
        )}
      </div>
    </div>
  );
}

const StaticComponent = ({
  elementBlock,
  checkedIndications,
  unmetIndications,
  indication,
  readonly,
  onCheckIndication,
  hasChildIndication,
  last,
  viewOnlyDocumentedSpans,
  openAttachment,
  isCPP,
}: BaseIndicationProps) => {
  const isTextHighlightImprovement = useFeature("textHighlightImprovements");
  const isUnmetIndications = useFeature("unmetIndications");
  const { linking, addLink, getIndicationMatches, toggleExpandIndication, isExpanded } =
    useContext(HighlightStateContext);
  const [indicationExpanded, setIndicationExpanded] = useState<boolean>(
    isTextHighlightImprovement ? !!isExpanded?.(indication?.uid) : false
  );
  const classes = useStyles();
  const htmlString = useMemo(() => getIndicationText(elementBlock.htmlString), [elementBlock.htmlString]);
  const isChecked = !!checkedIndications?.find((checkedIndication) => checkedIndication.uid === indication?.uid);
  const isUnmet = !!unmetIndications?.find((unmetIndication) => unmetIndication.uid === indication?.uid);
  const theme = useTheme();

  const toggleFunc = useCallback(
    (metIndications: Indication[], unmetIndications: Indication[], met: boolean): ToggleResults => {
      if (!hasChildIndication && indication) {
        const isChecked = !!metIndications.find((metIndication) => metIndication.uid === indication.uid);
        const isUnmet = !!unmetIndications.find((unmetIndication) => unmetIndication.uid === indication.uid);
        if (met) {
          if (isChecked) {
            return {
              checkedIndications: metIndications.filter((metIndication) => metIndication.uid !== indication.uid),
              unmetIndications,
            };
          } else {
            return {
              checkedIndications: [...metIndications, indication],
              unmetIndications: unmetIndications.filter((unmetIndication) => unmetIndication.uid !== indication.uid),
            };
          }
        } else {
          if (isUnmet) {
            return {
              checkedIndications: metIndications,
              unmetIndications: unmetIndications.filter((unmetIndication) => unmetIndication.uid !== indication.uid),
            };
          } else {
            return {
              checkedIndications: metIndications.filter((metIndication) => metIndication.uid !== indication.uid),
              unmetIndications: [...unmetIndications, indication],
            };
          }
        }
      } else {
        return { checkedIndications: [], unmetIndications: [] };
      }
    },
    [hasChildIndication, indication]
  );
  const indicationMatches = getIndicationMatches?.(indication?.uid);
  const handleClick = () => {
    if (linking && !indicationMatches?.find((indicationMatch) => indicationMatch.hash === linking)) {
      const added = addLink?.(indication?.uid);
      if (!added) {
        setIndicationExpanded(!indicationExpanded);
        toggleExpandIndication?.(indication?.uid, !indicationExpanded);
      } else {
        setIndicationExpanded(true);
      }
    } else {
      setIndicationExpanded(!indicationExpanded);
      toggleExpandIndication?.(indication?.uid, !indicationExpanded);
    }
  };
  const textContainerStyle: CSSProperties = {
    fontFamily: "Gilroy-Medium",
    display: "flex",
    alignItems: "center",
    marginBottom: last ? 0 : theme.spacing(0.5),
    minHeight: hasChildIndication ? theme.spacing(3) : theme.spacing(2),
    marginLeft: hasChildIndication ? theme.spacing(0.5) : 0,
    color: colorsLight.font.main,
    width: "calc(100% - 43px)",
    justifyContent: "flex-start",
  };

  const hasHighlightMatches =
    !hasChildIndication && indication && indicationMatches !== undefined && indicationMatches?.length > 0;
  const doubleCheckbox = isUnmetIndications && !readonly;
  const citedHighlights = indicationMatches?.filter(
    (indicationMatch) => indicationMatch.isCited || indicationMatch.isCited === undefined || !isTextHighlightImprovement
  );
  const suggestedHighlights = indicationMatches?.filter(
    (indicationMatch) => indicationMatch.isCited === false && isTextHighlightImprovement
  );
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          width: isTextHighlightImprovement ? "6px" : theme.spacing(0.5),
          borderRadius: isTextHighlightImprovement ? "2px 0px 0px 2px" : "",
          background: isTextHighlightImprovement ? "#FCAF3C" : "#FFC960",
          position: "absolute",
          height:
            ((isTextHighlightImprovement && suggestedHighlights && suggestedHighlights.length > 0) ||
              (!isTextHighlightImprovement && hasHighlightMatches)) &&
            !readonly
              ? "97%"
              : 0,
          left: isTextHighlightImprovement ? "-12px" : "-10px",
          transition: "height 0.2s",
        }}
      />
      <div
        style={{
          display: "flex",
          marginLeft: theme.spacing(0.5),
          marginBottom: last && !hasHighlightMatches ? 0 : theme.spacing(0.5),
          width: "calc(100% - 4px)",
          flexWrap: "nowrap",
        }}
      >
        {!hasChildIndication && indication && (
          <div
            style={{
              marginLeft: theme.spacing(doubleCheckbox ? 1 : 0),
              marginRight: theme.spacing(doubleCheckbox ? 0 : 0.5),
              width: theme.spacing(doubleCheckbox ? 4 : 2),
              height: theme.spacing(2),
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(0.25),
            }}
          >
            <StyledCheckbox
              unmetIndications={isUnmetIndications}
              readonly={readonly}
              checked={readonly && isUnmetIndications ? false : isChecked}
              disabled={readonly}
              muiCheckboxProps={{
                icon: isUnmetIndications ? (
                  readonly ? (
                    isChecked ? (
                      <CheckboxIcon style={{ color: colorsLight.primary.light }} />
                    ) : isUnmet ? (
                      <UnmetCheckboxIcon
                        className="MuiSvgIcon-root"
                        style={{ color: colorsDark.background.cardLight }}
                      />
                    ) : (
                      <UndecidedCheckboxIcon className="MuiSvgIcon-root" style={{ color: colorsLight.gray.divider }} />
                    )
                  ) : (
                    <CheckboxIcon style={{ color: colorsLight.gray.divider }} />
                  )
                ) : (
                  <UncheckedIcon />
                ),
                disableRipple: true,
              }}
              onChange={() => {
                onCheckIndication?.(indication, toggleFunc, true);
              }}
            />
            {doubleCheckbox && (
              <StyledCheckbox
                unmetIndications
                checked={isUnmet}
                muiCheckboxProps={{
                  icon: <UnmetCheckboxIcon className="MuiSvgIcon-root" style={{ color: colorsLight.gray.divider }} />,
                  checkedIcon: (
                    <UnmetCheckboxIcon className="MuiSvgIcon-root" style={{ color: colorsDark.background.cardLight }} />
                  ),
                  disableRipple: true,
                }}
                onChange={() => {
                  onCheckIndication?.(indication, toggleFunc, false);
                }}
              />
            )}
          </div>
        )}
        <ButtonOrDiv
          style={textContainerStyle}
          asButton={
            ((!!suggestedHighlights && suggestedHighlights.length > 0) || !!linking) && !readonly && !hasChildIndication
          }
          onClick={() =>
            !hasChildIndication &&
            ((!!suggestedHighlights && suggestedHighlights.length > 0) || !!linking) &&
            !readonly &&
            handleClick()
          }
        >
          <Body3 component="div">
            <Sanitized __html={htmlString || "- -"} className={classes.lists} />
          </Body3>
        </ButtonOrDiv>
        {!isTextHighlightImprovement && hasHighlightMatches && !readonly && (
          <IconButton
            style={{ padding: 0, height: "15px" }}
            onClick={() => {
              setIndicationExpanded(!indicationExpanded);
            }}
          >
            <Label style={{ fontSize: 15, color: "#FFC960" }} />
          </IconButton>
        )}
      </div>
      {!readonly ? (
        <div>
          <DocumentedHighlightContainer
            citedHighlights={citedHighlights}
            suggestedHighlights={suggestedHighlights}
            indication={indication}
            readonly={readonly}
            openAttachment={openAttachment}
            doubleCheckbox={doubleCheckbox}
            isCPP={isCPP}
            indicationExpanded={indicationExpanded}
            isHighlightImprovementsEnabled={isTextHighlightImprovement}
          />
        </div>
      ) : (
        !!viewOnlyDocumentedSpans &&
        !!indication?.uid &&
        viewOnlyDocumentedSpans.map((span) => {
          return (
            ((isTextHighlightImprovement && span.isCited) || !isTextHighlightImprovement) && (
              <DocumentedHighlight span={span} indicationId={indication?.uid} key={span.hash} readOnly={readonly} />
            )
          );
        })
      )}
    </div>
  );
};

const DocumentedHighlightContainer = ({
  citedHighlights,
  suggestedHighlights,
  indication,
  readonly,
  openAttachment,
  doubleCheckbox,
  isCPP,
  indicationExpanded,
  isHighlightImprovementsEnabled,
}: DocumentedHighlightContainerProps) => {
  const parentRef = useRef<HTMLDivElement>(null);
  return (
    <div ref={parentRef} style={{ transition: "height 0.2s", overflow: "hidden" }}>
      <>
        {(indicationExpanded || isHighlightImprovementsEnabled) &&
          citedHighlights?.map((documentedSpan) => {
            const reactKey = `${documentedSpan.attachmentId}-${documentedSpan.hash}`;
            return (
              <FlipMove
                appearAnimation="fade"
                enterAnimation="none"
                leaveAnimation="none"
                maintainContainerHeight
                key={reactKey}
              >
                <DocumentedHighlight
                  span={documentedSpan}
                  indicationId={indication?.uid}
                  key={reactKey}
                  readOnly={readonly}
                  openAttachment={openAttachment}
                  doubleCheckbox={doubleCheckbox}
                  isCPP={isCPP}
                  isHighlightImprovementsEnabled={isHighlightImprovementsEnabled}
                  isCited
                />
              </FlipMove>
            );
          })}
        {indicationExpanded && (
          <>
            {suggestedHighlights && suggestedHighlights.length > 0 && (
              <SuggestedLabel>Suggested highlights</SuggestedLabel>
            )}
            {suggestedHighlights?.map((documentedSpan) => {
              const reactKey = `${documentedSpan.attachmentId}-${documentedSpan.hash}`;
              return (
                <FlipMove
                  appearAnimation="fade"
                  enterAnimation="none"
                  leaveAnimation="none"
                  maintainContainerHeight
                  key={reactKey}
                >
                  <DocumentedHighlight
                    span={documentedSpan}
                    indicationId={indication?.uid}
                    key={reactKey}
                    readOnly={readonly}
                    openAttachment={openAttachment}
                    doubleCheckbox={doubleCheckbox}
                    isCPP={isCPP}
                    isCited={false}
                    parentRef={parentRef}
                    isHighlightImprovementsEnabled
                  />
                </FlipMove>
              );
            })}
          </>
        )}
      </>
    </div>
  );
};

const DocumentedHighlight = forwardRef<HTMLDivElement, DocumentedHighlightProps>(
  (
    {
      span,
      indicationId,
      readOnly,
      openAttachment,
      doubleCheckbox,
      isCPP,
      isCited,
      isHighlightImprovementsEnabled,
      parentRef,
    },
    ref
  ) => {
    const theme = useTheme();
    const { removeLink, attachmentId, linking, jumpToHighlight, editing, setEditing, saveEdit, promoteHighlight } =
      useContext(HighlightStateContext);
    const edit = editing?.hash === span.hash && editing.indicationId === indicationId;
    const { enqueueSnackbar } = useSnackbar();

    return (
      <IndicationHighlightContainer
        isCited={isCited}
        isHighlightImprovementsEnabled={isHighlightImprovementsEnabled}
        doubleCheckbox={doubleCheckbox}
        edit={edit}
        linking={!!linking}
        readOnly={readOnly}
        ref={ref}
        onClick={() => {
          if (!linking && !readOnly && !edit && span.attachmentId !== attachmentId) {
            openAttachment?.(span.attachmentId);
            setTimeout(() => jumpToHighlight?.(span?.hash, span.attachmentId), 2000);
          } else if (!linking && !readOnly && !edit) {
            jumpToHighlight?.(span?.hash, span.attachmentId);
          }
        }}
      >
        {edit ? (
          <EditHighlightComponent
            span={span}
            setEditing={setEditing}
            saveEdit={saveEdit}
            isCPP={isCPP}
            isHighlightImprovementsEnabled={isHighlightImprovementsEnabled}
            parentRef={parentRef}
          />
        ) : (
          <>
            <StyledBody3 style={{ marginRight: theme.spacing(0.5) }} readonly={readOnly}>
              {span.text.length > 250 ? span.text.substring(0, 247) + "..." : span.text}
            </StyledBody3>
            {!readOnly &&
              ((span.hash && isHighlightImprovementsEnabled && (
                <div style={{ marginLeft: "auto", display: "flex", flexDirection: "row", gap: "4px" }}>
                  {isCited && (
                    <HoverButtonBase
                      onClick={(event) => {
                        event.stopPropagation();
                        if (editing) {
                          enqueueSnackbar(`Save open highlight to continue working`, { variant: "error" });
                        } else {
                          setEditing?.({ hash: span.hash, indicationId: indicationId || "" });
                        }
                      }}
                      style={{ background: "none" }}
                    >
                      <EditIcon />
                    </HoverButtonBase>
                  )}
                  <HoverButtonBase
                    onClick={(event) => {
                      event.stopPropagation();
                      !isCited && promoteHighlight?.(indicationId, span.hash, span.attachmentId);
                    }}
                    style={{ background: "none" }}
                  >
                    <PromoteIcon isCited={isCited} />
                  </HoverButtonBase>
                  <HoverButtonBase
                    onClick={(event) => {
                      event.stopPropagation();
                      removeLink?.(indicationId, span.hash, span.attachmentId);
                    }}
                    style={{ background: "none" }}
                  >
                    <DownvoteIcon />
                  </HoverButtonBase>
                </div>
              )) || (
                <div style={{ marginLeft: "auto", display: "flex", flexDirection: "column" }}>
                  <HoverButtonBase
                    style={{ fontSize: "16px", height: "10px", marginBottom: "8px" }}
                    onClick={(event) => {
                      event.stopPropagation();
                      removeLink?.(indicationId, span.hash, span.attachmentId);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </HoverButtonBase>
                  <HoverButtonBase
                    style={{ fontSize: "16px", height: "10px" }}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (editing) {
                        enqueueSnackbar(`Save open highlight to continue working`, { variant: "error" });
                      } else {
                        setEditing?.({ hash: span.hash, indicationId: indicationId || "" });
                      }
                    }}
                  >
                    <PencilIcon fontSize="inherit" />
                  </HoverButtonBase>
                </div>
              ))}
          </>
        )}
      </IndicationHighlightContainer>
    );
  }
);

const EditHighlightComponent = ({
  span,
  setEditing,
  saveEdit,
  isCPP,
  isHighlightImprovementsEnabled,
  parentRef,
}: EditHighlightProps) => {
  const [editText, setEditText] = useState<string>(span.text);
  const [editStartPage, setEditStartPage] = useState<string>(span.startPage);
  const [editEndPage, setEditEndPage] = useState<string | undefined>(
    span.startPage === span.endPage ? undefined : span.endPage
  );
  const [attemptedSubmit, setAttemptedSubmit] = useState<boolean>(false);
  const isStartPageOutOfRange = () => {
    return !editStartPage || parseInt(editStartPage) < 1;
  };
  const isEndPageOutOfRange = () => {
    return !!editEndPage && parseInt(editEndPage) < 1;
  };
  const outOfRangeText = "Enter a page # in range";
  const isEndPageInvalid = () => {
    return !!editEndPage && parseInt(editEndPage) < parseInt(editStartPage);
  };
  const invalidEndPageText = "End can’t be before start";
  const isTextInvalid = () => {
    return !editText;
  };
  const invalidText = "Text is required";
  const theme = useTheme();
  useEffect(() => {
    if (parentRef?.current) {
      // Calculate the combined height of all child elements
      const childElements = parentRef.current.children;
      let totalHeight = 0;
      for (let i = 0; i < childElements.length; i++) {
        totalHeight += childElements[i].clientHeight + theme.spacing(1);
      }
      parentRef.current.style.height = `${totalHeight}px`;
    }
  }, [parentRef, theme]);
  return (
    <div
      style={isHighlightImprovementsEnabled ? { display: "flex", flexGrow: 1, alignItems: "start" } : { width: "100%" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginRight: isHighlightImprovementsEnabled ? theme.spacing(0.5) : "0px",
        }}
      >
        <TextField
          value={editText}
          onChangeValue={setEditText}
          fullWidth
          multiline
          noLabelSpace
          style={{
            marginBottom: theme.spacing(1),
            border:
              isHighlightImprovementsEnabled && !(attemptedSubmit && isTextInvalid()) ? "1px solid #DE7800" : undefined,
            borderRadius: isHighlightImprovementsEnabled ? "4px" : undefined,
          }}
          error={attemptedSubmit && isTextInvalid()}
          helperText={attemptedSubmit && isTextInvalid() && invalidText}
        />
        {isCPP && (
          <div style={{ marginBottom: theme.spacing(1) }}>
            <TextField
              value={editStartPage}
              onChangeValue={setEditStartPage}
              label="Page start"
              type="number"
              style={{
                width: "calc(50% - 12px)",
                marginRight: theme.spacing(1),
              }}
              inputProps={{ min: 1 }}
              error={attemptedSubmit && isStartPageOutOfRange()}
              helperText={attemptedSubmit && isStartPageOutOfRange() && outOfRangeText}
            />
            <div
              style={{ fontSize: "23px", paddingTop: "10px", width: "8px", display: "inline-block", height: "56px" }}
            >
              -
            </div>
            <TextField
              value={editEndPage}
              onChangeValue={setEditEndPage}
              label="Page end (optional)"
              type="number"
              style={{
                width: "calc(50% - 12px)",
                marginLeft: theme.spacing(1),
              }}
              inputProps={{ min: 1 }}
              error={attemptedSubmit && (isEndPageOutOfRange() || isEndPageInvalid())}
              helperText={
                attemptedSubmit && (isEndPageOutOfRange() ? outOfRangeText : isEndPageInvalid() && invalidEndPageText)
              }
            />
          </div>
        )}
      </div>
      {isHighlightImprovementsEnabled ? (
        <div style={{ marginLeft: "auto", display: "flex", flexDirection: "row", gap: "4px" }}>
          <HoverButtonBase
            onClick={() => {
              if (!(isStartPageOutOfRange() || isEndPageInvalid() || isEndPageOutOfRange() || isTextInvalid())) {
                saveEdit?.(span.attachmentId, editText, editStartPage, editEndPage);
              } else {
                setAttemptedSubmit(true);
              }
            }}
            style={{ background: "none" }}
          >
            <SaveIcon />
          </HoverButtonBase>
          <HoverButtonBase
            onClick={() => {
              setEditing?.(undefined);
              setEditText(span.text);
              setEditStartPage(span.startPage);
              setEditEndPage(span.endPage);
            }}
            style={{ background: "none" }}
          >
            <RevertIcon />
          </HoverButtonBase>
        </div>
      ) : (
        <div style={{ justifyContent: "end", display: "flex" }}>
          <SecondaryButton
            style={{ width: "86px", height: "32px", marginRight: theme.spacing(1) }}
            onClick={() => {
              setEditing?.(undefined);
              setEditText(span.text);
              setEditStartPage(span.startPage);
              setEditEndPage(span.endPage);
            }}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            style={{ width: "70px", height: "32px" }}
            onClick={() => {
              if (!(isStartPageOutOfRange() || isEndPageInvalid() || isEndPageOutOfRange() || isTextInvalid())) {
                saveEdit?.(span.attachmentId, editText, editStartPage, editEndPage);
              } else {
                setAttemptedSubmit(true);
              }
            }}
          >
            Save
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};

const ButtonOrDiv = ({
  asButton,
  children,
  style,
  onClick,
}: {
  asButton: boolean;
  children: ReactNode;
  style: CSSProperties;
  onClick: () => void;
}) => {
  if (asButton) {
    return (
      <HoverButtonBase style={style} onClick={onClick}>
        {children}
      </HoverButtonBase>
    );
  } else {
    return <div style={style}>{children}</div>;
  }
};

const useStyles = makeStyles((theme) => ({
  lists: {
    "& ul": {
      padding: 0,
      marginBottom: 0,
      marginTop: 0,
    },
    "& ol": {
      padding: 0,
      marginBottom: 0,
      marginTop: 0,
    },
  },
}));

const transformIndicationHighlights = (
  highlights: AttachmentGuidelineTextHighlight[]
): Record<IndicationId, DocumentedSpan[]> => {
  const transformedHighlights: Record<IndicationId, DocumentedSpan[]> = {};
  highlights?.forEach((highlight) => {
    highlight.matches
      ?.filter(
        (match) =>
          match.highlightAreas &&
          match.highlightAreas.length > 0 &&
          match.status !== "REMOVED" &&
          match.status !== "UNLINKED"
      )
      .forEach((match) => {
        if (!transformedHighlights[match.indicationUid]) {
          transformedHighlights[match.indicationUid] = [];
        }
        const text = match.editText || match.text;
        transformedHighlights[match.indicationUid].push({
          text: text.length > 250 ? text.substring(0, 247) + "..." : text,
          attachmentId: highlight?.attachmentId,
          hash: highlight?.attachmentId + highlight?.guidelineId + match.indicationUid,
          startPage: match.editStartPage?.toString() || ((match.highlightAreas?.[0].pageIndex || 0) + 1).toString(),
          endPage:
            match.editEndPage?.toString() ||
            match.editStartPage?.toString() ||
            ((match.highlightAreas?.at(-1)?.pageIndex || 0) + 1).toString(),
          isCited: match.isCited,
        });
      });
  });
  return transformedHighlights;
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const BaseContainer = styled("div")(({ theme }) => ({
  display: "flex",
  margin: 0,
  width: "100%",
  flexWrap: "wrap",
  boxSizing: "border-box",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const AlertContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: "100%",
}));

const getIconSize = (depth?: number) => `${depth === 0 ? 24 : 16}px`;

type IconProps = {
  depth?: number;
};

type CitedProps = {
  isCited?: boolean;
};

type IndicationHighlightContainerProps = {
  isCited?: boolean;
  isHighlightImprovementsEnabled?: boolean;
  doubleCheckbox?: boolean;
  edit?: boolean;
  linking?: boolean;
  readOnly?: boolean;
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const IndicationHighlightContainer = styled(
  ({ isCited, isHighlightImprovementsEnabled, doubleCheckbox, edit, linking, readOnly, ...other }) => <div {...other} />
)<Theme, IndicationHighlightContainerProps>(
  ({ theme, isCited, isHighlightImprovementsEnabled, doubleCheckbox, edit, linking, readOnly }) => ({
    background: isCited && isHighlightImprovementsEnabled ? "#FFDFA1" : "#FFF1D4",
    display: "flex",
    flexGrow: 1,
    marginLeft: theme.spacing(doubleCheckbox ? 5.5 : 3),
    marginBottom: theme.spacing(1),
    padding: edit ? theme.spacing(1) : theme.spacing(0.5, 0.5, 0.5, 1),
    borderRadius: "4px",
    alignItems: isHighlightImprovementsEnabled ? "start" : "center",
    cursor: linking || readOnly || edit ? "default" : "pointer",
    "&:hover": {
      background: isCited && isHighlightImprovementsEnabled ? "#FFDFA180" : "#FFF1D480",
    },
  })
);

// eslint-disable-next-line cohere-react/no-mui-styled-import
const QuestionMark = styled(({ depth, ...other }) => <HelpOutlineIcon {...other} />)<Theme, IconProps>(({ depth }) => {
  return {
    color: colorsLight.font.secondary,
    width: getIconSize(depth),
    height: getIconSize(depth),
    marginRight: 2,
  };
});
// eslint-disable-next-line cohere-react/no-mui-styled-import
const SuccessIcon = styled(({ depth, ...other }) => <CheckCircleIcon {...other} />)<Theme, IconProps>(
  ({ theme, depth }) => ({
    color: theme.palette.success.main,
    width: getIconSize(depth),
    height: getIconSize(depth),
    marginRight: 2,
  })
);

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CrossIcon = styled(({ depth, ...other }) => <CancelIcon {...other} />)<Theme, IconProps>(({ theme, depth }) => ({
  color: theme.palette.error.main,
  width: getIconSize(depth),
  height: getIconSize(depth),
  marginRight: 2,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PromoteIcon = styled(({ isCited, ...other }) => <ThumbUpIcon {...other} />)<Theme, CitedProps>(
  ({ theme, isCited }) => ({
    color: isCited ? "#DE7800" : "#DE780050",
    width: "16px",
    height: "16px",
    "&:hover": {
      color: isCited ? "#DE7800" : "#FFCF98",
      background: "none",
    },
  })
);

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DownvoteIcon = styled(ThumbDownIcon)(({ theme }) => ({
  color: "#DE780050",
  width: "16px",
  height: "16px",
  "&:hover": {
    color: "#FFCF98",
    background: "none",
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const EditIcon = styled(PencilIcon)(({ theme }) => ({
  color: "#DE780050",
  width: "16px",
  height: "16px",
  "&:hover": {
    color: "#FFCF98",
    background: "none",
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SaveIcon = styled(CheckIcon)(({ theme }) => ({
  color: "#DE7800",
  width: "16px",
  height: "16px",
  "&:hover": {
    background: "none",
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RevertIcon = styled(UndoIcon)(({ theme }) => ({
  color: "#DE7800",
  width: "16px",
  height: "16px",
  "&:hover": {
    background: "none",
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SuggestedLabel = styled(Subtitle1)(({ theme }) => ({
  color: "#DE7800",
  textTransform: "uppercase",
  marginLeft: theme.spacing(6),
  marginBottom: theme.spacing(0.5),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const HoverButtonBase = styled(ButtonBase)({
  "&:hover": {
    backgroundColor: colorsLight.gray.light,
  },
});
interface StyledCheckboxProps {
  unmetIndications?: boolean;
  readonly?: boolean;
}
// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledCheckbox = styled(({ unmetIndications, readonly, ...passthrough }) => <Checkbox {...passthrough} />)<
  Theme,
  StyledCheckboxProps
>(({ unmetIndications, readonly }) => ({
  "& .MuiSvgIcon-root": {
    width: "16px",
    height: "16px",
    transform: unmetIndications ? "scale(" + 7 / 6 + ")" : "none",
  },
  "& .MuiButtonBase-root": {
    padding: unmetIndications && !readonly ? 0 : unmetIndications ? 8 : 12,
  },
  // enforce colors so convene's dark theme is ignored
  "& .MuiCheckbox-root": {
    color: colorsDark.gray.dark,
  },
  "& .Mui-checked": {
    color: unmetIndications ? colorsLight.primary.light : colorsLight.primary.main,
  },
  "& .MuiIconButton-root.Mui-disabled": {
    color: colorsLight.gray.dark,
  },
}));

interface StyledBodyProps {
  readonly?: boolean;
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledBody3 = styled(({ readonly, ...passthrough }) => <Body3 {...passthrough} />)<Theme, StyledBodyProps>(
  ({ readonly }) => ({
    color: colorsLight.font.primary,
    fontWeight: readonly ? 600 : 500,
  })
);
