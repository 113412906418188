import React, { FunctionComponent, useEffect, useState } from "react";
import { ServiceRequestSearchResponse, useUpdateServiceRequest } from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import { PrimaryButton, TextField } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Grid, styled } from "@material-ui/core";

interface Props {
  serviceRequest: ServiceRequestSearchResponse;
  onEdit: () => void;
}

const AuthNumberEntryForm: FunctionComponent<Props> = ({ serviceRequest, onEdit }) => {
  const { mutate, error, loading } = useUpdateServiceRequest({ id: serviceRequest.id });
  const { enqueueSnackbar } = useSnackbar();
  const [authNumber, setAuthNumber] = useState<string>(serviceRequest?.authNumber || "");

  useEffect(() => {
    if (error) {
      enqueueSnackbar(`Error saving changes: ${error.message}`, { variant: "error" });
    }
  }, [error, enqueueSnackbar]);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await mutate({
      authNumber,
      manualAuthNumberEntry: true,
    });
    onEdit();
  };

  return (
    <form onSubmit={onSubmit}>
      <CenteredGridContainer container direction="row" spacing={6}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Auth number"
            value={authNumber}
            onChangeValue={(num) => setAuthNumber(num)}
            data-testid="auth-number-input"
          />
        </Grid>
        <Grid item xs={12}>
          <Centered>
            <PrimaryButton
              type="submit"
              disabled={loading || authNumber === ""}
              loading={loading}
              data-testid="auth-number-submit"
            >
              Save changes
            </PrimaryButton>
          </Centered>
        </Grid>
      </CenteredGridContainer>
    </form>
  );
};

export default AuthNumberEntryForm;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CenteredGridContainer = styled(Grid)({
  alignItems: "center",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Centered = styled("div")({ textAlign: "center" });
