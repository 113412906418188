import React from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { withStyles, Theme, styled } from "@material-ui/core/styles";
import Button, { ButtonProps, ButtonTypeMap } from "@material-ui/core/Button";
import MuiCircularProgress from "@material-ui/core/CircularProgress";
import { ExtendButtonBase } from "@material-ui/core/ButtonBase";
import { colorsLight, colorsDark } from "../../themes/colors";

const styles = (theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  return {
    root: {
      padding: theme.spacing(2, 5),
      "&$disabled": {
        backgroundColor: isDarkTheme ? colorsDark.gray.inactive : colorsLight.gray.dark,
        color: isDarkTheme ? colorsDark.white.disabled : colorsLight.white.disabled,
      },
    },
    disabled: {},
    containedPrimary: {
      boxShadow: "none",
      "&:hover, &:focus": {
        boxShadow: "none",
        backgroundColor: theme.palette.primary.light,
      },
      "&:active": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  };
};

export interface ExtraProps {
  loading?: boolean;
}
const PrimaryButton = React.forwardRef((props: ButtonProps & ExtraProps, ref: any) => {
  // pull out external props that we hardcode, only pass through otherProps
  const { classes, children, color, variant, loading, ...otherProps } = props;
  return (
    <Button ref={ref} classes={classes} variant="contained" color="primary" {...otherProps} data-public>
      {Boolean(loading) && <CircularProgress color="inherit" size={32} />}
      <div style={loading ? { visibility: "hidden" } : {}}>{children}</div>
    </Button>
  );
});
PrimaryButton.displayName = "PrimaryButton";

export default withStyles(styles)(PrimaryButton) as ExtendButtonBase<ButtonTypeMap<ExtraProps>>;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CircularProgress = styled(MuiCircularProgress)({
  position: "absolute",
});
