import {
  Checkbox,
  DropdownOption,
  H2,
  Modal,
  PrimaryButton,
  SingleSelectDropdown,
  TextField,
  useFeature,
} from "@coherehealth/common";
import { OutOfScopeAuditReview, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { Body1 } from "@coherehealth/design-system";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Grid, styled } from "@material-ui/core";
import { useAuthorized } from "authorization";
import EnhancedWithdrawalReasonForm from "components/ServiceRequestStatusDisplay/EnhancedWithdrawalReasonForm/EnhancedWithdrawalReasonForm";
import { ComponentProps, useState } from "react";
import { withdrawnReasonOptions } from "util/serviceRequest";

export type OOSAuditReviewOutcome = OutOfScopeAuditReview["reviewOutcome"];
interface AuditReviewModalProp {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reviewOutcome: OOSAuditReviewOutcome;
  setReviewOutcome: (reviewOutcome: NonNullable<OOSAuditReviewOutcome>) => void;
  authorizationNote?: string;
  setAuthorizationNote?: (authNote: string) => void;
  loading: boolean;
  onSubmitReview: () => void;
  withdrawnReason?: string;
  setWithdrawnReason?: (withdrawnReason: string) => void;
  withdrawOptions?: DropdownOption[];
  healthPlanName: string;
  serviceRequest: ServiceRequestResponse;
}

type ReviewOutcomeOption = { id: NonNullable<OOSAuditReviewOutcome>; label: string };

const AuditReviewModal = ({
  open,
  setOpen,
  reviewOutcome,
  setReviewOutcome,
  loading,
  onSubmitReview,
  authorizationNote,
  setAuthorizationNote,
  withdrawnReason,
  setWithdrawnReason,
  withdrawOptions,
  healthPlanName,
  serviceRequest,
}: AuditReviewModalProp) => {
  const [hasSentEmail, setHasSentEmail] = useState(false);
  const isHumanaSr = healthPlanName === "Humana";
  const canViewEnhancedWithdrawalModal = useAuthorized("VIEW_ENHANCED_WITHDRAWAL_MODAL");
  const reviewerWithdrawalModalEnhanced = useFeature("reviewerWithdrawalModalEnhanced");

  const canSeeReviewerWithdrawalModalEnhanced = canViewEnhancedWithdrawalModal && reviewerWithdrawalModalEnhanced;

  const disableFinishButton = () => {
    if (!reviewOutcome || loading) {
      return true;
    } else if (reviewOutcome === "WITHDRAWN") {
      if (isHumanaSr) {
        return !withdrawnReason || !hasSentEmail;
      } else {
        return !withdrawnReason;
      }
    }
    return false;
  };

  const getOptionsList = (): DropdownOption[] => {
    return withdrawOptions || withdrawnReasonOptions(true);
  };

  const onWithdrawnReasonChange = (reason: string) => {
    setWithdrawnReason?.(reason);
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Grid container spacing={4}>
        <Row>
          <H2>Select review outcome</H2>
        </Row>
        <Row>
          <SingleSelectDropdown
            label="Outcome"
            options={OOSOutcomeOptions}
            value={reviewOutcome || ""}
            onChange={(reviewOutcome: string) => {
              if (isOOSReviewOutcome(reviewOutcome)) {
                setReviewOutcome(reviewOutcome);
              }
            }}
          />
        </Row>

        {reviewOutcome === "WITHDRAWN" && !canSeeReviewerWithdrawalModalEnhanced && (
          <>
            <Row style={{ paddingTop: 0 }}>
              <SingleSelectDropdown
                fullWidth
                label="Select reason for withdrawal"
                options={getOptionsList()}
                value={withdrawnReason || ""}
                onChange={onWithdrawnReasonChange}
                maxMenuHeight={200}
                menuWidth={470}
              />
            </Row>
            {isHumanaSr && (
              <Row alignItems="center" container justifyContent="center">
                <StyledCheckbox checked={hasSentEmail} onChange={() => setHasSentEmail(!hasSentEmail)} />
                <StyledBody1 style={{ paddingBottom: "2px" }}>I've sent an email to Humana</StyledBody1>
              </Row>
            )}
          </>
        )}

        {reviewOutcome === "WITHDRAWN" && canSeeReviewerWithdrawalModalEnhanced && (
          <>
            <Row>
              <EnhancedWithdrawalReasonForm
                value={withdrawnReason}
                onChange={onWithdrawnReasonChange}
                serviceRequest={serviceRequest}
              />
            </Row>
            {isHumanaSr && (
              <Row alignItems="center" container justifyContent="center">
                <StyledCheckbox checked={hasSentEmail} onChange={() => setHasSentEmail(!hasSentEmail)} />
                <StyledBody1 style={{ paddingBottom: "2px" }}>I've sent an email to Humana</StyledBody1>
              </Row>
            )}
          </>
        )}

        {reviewOutcome === "PENDING_RN_REVIEW" && (
          <Row>
            <TextField
              label={"Reason for Pend"}
              fullWidth
              hiddenLabel
              multiline
              value={authorizationNote}
              onChangeValue={setAuthorizationNote}
            />
          </Row>
        )}
        <Row>
          <PrimaryButton disabled={disableFinishButton()} loading={loading} onClick={onSubmitReview}>
            Finish review
          </PrimaryButton>
        </Row>
      </Grid>
    </Modal>
  );
};

const Row = (props: ComponentProps<typeof Grid>) => <Grid style={{ textAlign: "center" }} item xs={12} {...props} />;

const isOOSReviewOutcome = (reviewOutcome: string): reviewOutcome is NonNullable<OOSAuditReviewOutcome> => {
  return OOSOutcomeOptions.some((option) => option.id === reviewOutcome);
};

const OOSOutcomeOptions: ReviewOutcomeOption[] = [
  {
    id: "PENDING_RN_REVIEW",
    label: "Pending clinical review",
  },
  { id: "WITHDRAWN", label: "Withdrawn" },
];

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledCheckbox = styled(Checkbox)({
  "& .MuiButtonBase-root": {
    padding: 0,
  },
  "& .MuiFormControlLabel-root": {
    marginRight: "8px",
  },
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledBody1 = styled(Body1)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export default AuditReviewModal;
