import React, { Dispatch, useContext, useCallback } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, useTheme } from "@material-ui/core/styles";
import {
  mapClinicalResponseWithSubQuestion,
  nonSelectAnswerForQuestion,
  updateSubQuestionsVisibility,
  incompleteRequiredQuestions,
  clearNotShownClinicalQuestions,
} from "util/clinicalAssessment";
import {
  Caption,
  colorsLight,
  DateSelectQuestion,
  decodeCodes,
  encodeCodesAsString,
  formatDateToISODate,
  singleAnswerIsValid,
  H2,
  MultiSelectQuestion,
  parseDateFromISOString,
  ShortAnswerQuestion,
  SingleSelectQuestion,
  Subtitle2,
  filterClinicalQuestionsByDisplayCondition,
  SubQuestion,
  selectAnswersForQuestion,
  ClinicalAssessmentResponseWithSubQuestions,
} from "@coherehealth/common";
import ClinicalAssessmentContext from "./ClinicalAssessmentContext";
import { ClinicalQuestion, ClinicalAssessmentResponse } from "@coherehealth/core-platform-api";
import { Action as ClinicalAssessmentUpdateAction } from "./ClinicalAssessmentReducer";
import { Collapse, Fade } from "@material-ui/core";
import { ClinicalAssessmentState } from ".";
import NoItemsToDisplay from "common/NoItemsToDisplay";
import { useLocation } from "react-router";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";

interface Props {
  doNotWrapQuestionsWithCard?: boolean;
}

const ADDITIONAL_QUESTION_TEXT = "Additional questions may be asked based on your answers";
const ALL_QUESTION_ANSWERED_TEXT = "You’ve answered all required questions for this service!";

export default function EditWithAssessmentContext({ doNotWrapQuestionsWithCard = false }: Props) {
  const theme = useTheme();
  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);
  let {
    subQuestions: subQuestionsVisibility,
    updateSubQuestions,
    clinicalAssessment: assessment,
    updateClinicalAssessment,
  } = useContext(ClinicalAssessmentContext);

  const questionsByService = SortQuestionsByService(assessment);

  const serviceNamesAndQuestions = Array.from(questionsByService.values());
  const serviceQuestionsNonEmpty = serviceNamesAndQuestions.filter((v) => v[1]?.length > 0);
  // Display the service name if there's more than 1 service
  const displayServiceNames = serviceNamesAndQuestions.length > 1;
  // Display the no-questions banner if there's no services with questions
  const displayNoQuestions = serviceQuestionsNonEmpty.length === 0;

  interface DynamicQuestionIds {
    originalVersionId?: string;
    questionId?: string;
  }

  /*
    creates a map of clinical questions that depend on a selected source 
    clinicalQuestion for render. The top-level questionId is needed for the state update.
  */
  const createMemoizedDynamicQuestionDependencyMap = useCallback(
    (clinicalAssessmentQuestions: ClinicalAssessmentResponseWithSubQuestions[]) => {
      // map of {originalVersionId: questionId} needed for state update
      const originalVersionIdMap = new Map<string, string>();
      clinicalAssessmentQuestions.forEach((question) => {
        if (question.clinicalQuestion?.originalVersionId && question.clinicalQuestion?.id) {
          originalVersionIdMap.set(question.clinicalQuestion?.originalVersionId, question.clinicalQuestion?.id);
        }
      });
      // question dependency map object
      const dependencyMap: Map<string, DynamicQuestionIds[]> = new Map<string, DynamicQuestionIds[]>();
      clinicalAssessmentQuestions.forEach((question) => {
        if (question.clinicalQuestion?.originalVersionId) {
          const dependentId = question.clinicalQuestion?.originalVersionId;
          if (question.clinicalQuestion?.displayCondition) {
            question.clinicalQuestion?.displayCondition?.block?.conditions?.forEach((condition) => {
              if (condition?.statement?.operandA?.value) {
                const targetId: string = condition.statement.operandA.value.toString().split(".")[1];
                if (dependencyMap.has(targetId)) {
                  dependencyMap
                    .get(targetId)
                    ?.push({ originalVersionId: dependentId, questionId: originalVersionIdMap.get(dependentId) });
                } else {
                  dependencyMap.set(targetId, [
                    { originalVersionId: dependentId, questionId: originalVersionIdMap.get(dependentId) },
                  ]);
                }
              }
            });
          }
        }
      });
      return dependencyMap;
    },
    []
  );

  if (displayNoQuestions) {
    return (
      <NoItemsToDisplay
        mainText="No clinical questions are needed at this time."
        subText="Keep up the great work by continuing to the next step."
      />
    );
  }

  return (
    <>
      {Array.from(questionsByService.keys()).map((k) => {
        const serviceName = questionsByService.get(k)?.[0];
        const serviceQuestions = questionsByService.get(k)?.[1];
        if (!serviceQuestions || serviceQuestions.length === 0) {
          return null;
        }
        let questionsForClinicalAssessment: ClinicalAssessmentResponseWithSubQuestions[] = [];
        if (assessment?.type === "AGADIA") {
          questionsForClinicalAssessment = mapClinicalResponseWithSubQuestion(serviceQuestions);
        } else {
          questionsForClinicalAssessment = clearNotShownClinicalQuestions(serviceQuestions);
        }

        const updateAffectedQuestions = (index: number, newAnswers: any) => {
          // Fetch correct question id off of clinical assessment state based on Pharmacy/Medical clinical response
          let id =
            serviceQuestions && serviceQuestions[index]?.id
              ? serviceQuestions[index]?.id
              : serviceQuestions && serviceQuestions[index]?.clinicalQuestion?.id
              ? serviceQuestions[index]?.clinicalQuestion?.id
              : "";
          let mainQuestion = questionsForClinicalAssessment.find((iq) => iq.id === id);
          let shouldUpdate = shouldUpdateSubQuestions(mainQuestion, mainQuestion?.answers, newAnswers);
          if (mainQuestion && shouldUpdate) {
            //if the answer no longer shows subQuestions then clear subQuestions
            //then it is a main questions
            mainQuestion?.subQuestions?.forEach((sq) => {
              updateSubQuestionsClinicalAssessment(sq, updateClinicalAssessment);
              updateSubQuestionsVisibility({
                sq,
                subQuestions: subQuestionsVisibility,
                updateSubQuestions,
                setIsVisible: false,
              });
            });
          } else {
            //part of a subQuestion array
            mainQuestion = questionsForClinicalAssessment.find((iq) => iq?.subQuestions?.find((s) => s.id === id));
            const subQuestion = mainQuestion?.subQuestions?.find((s) => s.id === id);
            shouldUpdate = shouldUpdateSubQuestions(subQuestion, subQuestion?.answers, newAnswers);
            let foundSubQuestion = false;
            mainQuestion?.subQuestions?.forEach((sq) => {
              if (foundSubQuestion && shouldUpdate) {
                updateSubQuestionsClinicalAssessment(sq, updateClinicalAssessment);
              } else {
                foundSubQuestion = sq.id === id;
              }
            });
          }
        };

        const alphabet = Array.from(Array(26)).map((e, i) => String.fromCharCode(i + 98));

        const filteredQuestionsForClinicalAssessment =
          filterClinicalQuestionsByDisplayCondition(questionsForClinicalAssessment);
        const isDynamicClinicalQuestions =
          questionsForClinicalAssessment &&
          questionsForClinicalAssessment.some((q) => q.clinicalQuestion?.displayCondition);
        // TODO: measurement

        // populate question dependency map used for clearing dependent quesion responses
        const dynamicQuestionDependencyMap = createMemoizedDynamicQuestionDependencyMap(questionsForClinicalAssessment);

        // checks whether a question has dependent questions that show/hide depending on response
        const hasDependentDynamicQuestions = (originalVersionId?: string) => {
          return originalVersionId && dynamicQuestionDependencyMap.has(originalVersionId);
        };
        /* 
        Recursively clears the selections of questions whose displayConditions are dependent on a
        question whose selection has been changed
        */
        const pruneDependentQuestionResponses = (questionId?: string) => {
          if (!hasDependentDynamicQuestions(questionId)) {
            return;
          } else if (questionId) {
            const dependencies = dynamicQuestionDependencyMap.get(questionId);
            if (dependencies) {
              dependencies.forEach((dependency) => {
                const answers = questionsForClinicalAssessment.find(
                  (question) => question.clinicalQuestion?.id === dependency.questionId
                )?.answers;
                if (answers && answers.length > 0) {
                  updateClinicalAssessment({
                    type: "updateSingleSelectAnswer",
                    selectedOptionCodes: [],
                    questionId: dependency.questionId || "",
                  });
                }
                if (hasDependentDynamicQuestions(dependency.originalVersionId)) {
                  if (dependency.originalVersionId) {
                    pruneDependentQuestionResponses(dependency.originalVersionId);
                  }
                }
              });
            }
          }
        };

        return (
          <div
            style={{ width: "100%", marginBottom: !!isFaxAuthBuilderFlow ? theme.spacing(0) : theme.spacing(6) }}
            key={serviceName}
          >
            {displayServiceNames && <ServiceHeader>{serviceName}</ServiceHeader>}
            {filteredQuestionsForClinicalAssessment.map((currentQuestion, newIndex) => {
              const idx = currentQuestion.originalIndex;
              // For non-select Qs
              const answerWithCodeMatch = nonSelectAnswerForQuestion(currentQuestion, assessment?.type || "");
              // For multi- and single- select
              const answersMatchingResponseOptions = selectAnswersForQuestion(currentQuestion, assessment?.type || "");

              const measurementAnswerIsValid = singleAnswerIsValid(answerWithCodeMatch?.valueString);
              const sequenceNumber =
                +(currentQuestion?.clinicalQuestion?.externalAssessmentProperties?.sequenceNumber || -1) - 1;

              const selectOptions =
                currentQuestion.answers !== undefined && currentQuestion?.clinicalQuestion?.type !== "FREE_TEXT"
                  ? currentQuestion.answers.some((oA: any) => {
                      let option = currentQuestion.clinicalQuestion?.responseOptions?.find(
                        (o) => o.optionId === oA.optionId
                      );
                      return (
                        option?.externalAssessmentProperties?.nextQuestionId !==
                        currentQuestion?.clinicalQuestion?.externalAssessmentProperties?.defaultNextQuestionId
                      );
                    })
                  : false;

              const showingSubQuestion =
                currentQuestion?.subQuestions &&
                currentQuestion.subQuestions.length >= 1 &&
                ((currentQuestion?.clinicalQuestion?.type === "FREE_TEXT" &&
                  (currentQuestion?.answers ? currentQuestion?.answers[0]?.valueString || "" : "").length > 0) ||
                  selectOptions);
              return (
                <div key={idx}>
                  <Question
                    clinicalQuestion={currentQuestion.clinicalQuestion}
                    idx={showingSubQuestion ? (idx + 1).toString() + "a" : (idx + 1).toString()}
                    ordinal={showingSubQuestion ? (newIndex + 1).toString() + "a" : (newIndex + 1).toString()}
                    doNotWrapQuestionsWithCard={doNotWrapQuestionsWithCard}
                    answerWithCodeMatch={answerWithCodeMatch}
                    answersMatchingResponseOptions={answersMatchingResponseOptions}
                    measurementAnswerIsValid={measurementAnswerIsValid}
                    sequenceNumber={sequenceNumber === -2 ? idx : sequenceNumber}
                    updateClinicalAssessment={updateClinicalAssessment}
                    type={assessment?.type || "COHERE"}
                    updateSubQuestion={updateAffectedQuestions}
                    bottomMarginStyling={idx === questionsForClinicalAssessment.length - 1 && !isFaxAuthBuilderFlow}
                    showingSubQuestion={showingSubQuestion}
                    isDynamicClinicalQuestions={isDynamicClinicalQuestions}
                    pruneDependentQuestionResponses={pruneDependentQuestionResponses}
                  />
                  {currentQuestion.subQuestions &&
                    currentQuestion.subQuestions.map((subQuestion: SubQuestion, i: number) => {
                      let letter = alphabet[i];

                      let sequenceNumber =
                        +(subQuestion?.clinicalQuestion?.externalAssessmentProperties?.sequenceNumber || -1) - 1;

                      let mainQuestion = questionsForClinicalAssessment.find(
                        (q) => q.id === subQuestion.questionToRenderOn
                      );
                      if (mainQuestion === undefined) {
                        mainQuestion = questionsForClinicalAssessment
                          .find((q) => q.subQuestions?.find((sq) => sq.id === subQuestion.questionToRenderOn))
                          ?.subQuestions?.find((s) => s.id === subQuestion.questionToRenderOn);
                      }

                      const multiSelectOptions =
                        mainQuestion !== undefined
                          ? selectAnswersForQuestion(mainQuestion, assessment?.type || "").some(
                              (a: { optionId?: string }) => a.optionId === subQuestion.answerToRenderOn
                            )
                          : false;

                      const showSubQuestion =
                        (mainQuestion?.clinicalQuestion?.type === "FREE_TEXT" &&
                          (mainQuestion !== undefined && mainQuestion.answers !== undefined
                            ? mainQuestion?.answers[0]?.valueString || ""
                            : ""
                          ).length > 0) ||
                        multiSelectOptions;

                      updateSubQuestionsVisibility({
                        sq: subQuestion,
                        subQuestions: subQuestionsVisibility,
                        updateSubQuestions,
                        setIsVisible: showSubQuestion,
                      });

                      const selectOptions =
                        subQuestion.answers !== undefined && subQuestion?.clinicalQuestion?.type !== "FREE_TEXT"
                          ? subQuestion.answers.some((oA: any) => {
                              let option = subQuestion.clinicalQuestion?.responseOptions?.find(
                                (o) => o.optionId === oA.optionId
                              );
                              return (
                                option?.externalAssessmentProperties?.nextQuestionId !==
                                subQuestion?.clinicalQuestion?.externalAssessmentProperties?.defaultNextQuestionId
                              );
                            })
                          : false;

                      const showingSubQuestion =
                        currentQuestion?.subQuestions &&
                        currentQuestion.subQuestions.length >= 1 &&
                        ((subQuestion?.clinicalQuestion?.type === "FREE_TEXT" &&
                          (subQuestion?.answers ? subQuestion?.answers[0]?.valueString || "" : "").length > 0) ||
                          selectOptions);

                      return (
                        <Collapse in={showSubQuestion} timeout={300} key={i}>
                          <div>
                            <Fade in={showSubQuestion} timeout={300}>
                              <div>
                                <Question
                                  clinicalQuestion={subQuestion.clinicalQuestion}
                                  idx={(idx + 1).toString() + letter}
                                  ordinal={(newIndex + 1).toString() + letter}
                                  doNotWrapQuestionsWithCard={doNotWrapQuestionsWithCard}
                                  answerWithCodeMatch={nonSelectAnswerForQuestion(subQuestion, assessment?.type || "")}
                                  measurementAnswerIsValid={singleAnswerIsValid(
                                    nonSelectAnswerForQuestion(subQuestion, assessment?.type || "")?.valueString
                                  )}
                                  answersMatchingResponseOptions={selectAnswersForQuestion(
                                    subQuestion,
                                    assessment?.type || ""
                                  )}
                                  sequenceNumber={sequenceNumber === -2 ? idx : sequenceNumber}
                                  updateClinicalAssessment={updateClinicalAssessment}
                                  type={assessment?.type || "COHERE"}
                                  updateSubQuestion={updateAffectedQuestions}
                                  isSubQuestion={true}
                                  showingSubQuestion={showingSubQuestion}
                                  isDynamicClinicalQuestions={isDynamicClinicalQuestions}
                                  pruneDependentQuestionResponses={pruneDependentQuestionResponses}
                                />
                              </div>
                            </Fade>
                          </div>
                        </Collapse>
                      );
                    })}
                </div>
              );
            })}
            {isDynamicClinicalQuestions && (
              <ClinicalAssessmentNote>
                {incompleteRequiredQuestions(assessment, subQuestionsVisibility).length === 0 ? (
                  <Subtitle2 style={{ color: theme.palette.success.dark }}>{ALL_QUESTION_ANSWERED_TEXT}</Subtitle2>
                ) : (
                  <Caption style={{ color: colorsLight.font.secondary }}>{ADDITIONAL_QUESTION_TEXT}</Caption>
                )}
              </ClinicalAssessmentNote>
            )}
          </div>
        );
      })}
    </>
  );
}
// eslint-disable-next-line cohere-react/no-mui-styled-import
const ClinicalAssessmentNote = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: theme.spacing(3),
}));

const Question = ({
  clinicalQuestion,
  idx,
  ordinal,
  doNotWrapQuestionsWithCard,
  measurementAnswerIsValid,
  answerWithCodeMatch,
  updateClinicalAssessment,
  answersMatchingResponseOptions,
  sequenceNumber,
  bottomMarginStyling,
  updateSubQuestion,
  isSubQuestion,
  showingSubQuestion,
  type,
  isDynamicClinicalQuestions,
  pruneDependentQuestionResponses,
}: {
  clinicalQuestion: ClinicalQuestion | undefined;
  idx: string;
  ordinal: string;
  doNotWrapQuestionsWithCard: boolean;
  measurementAnswerIsValid: boolean;
  answerWithCodeMatch: any;
  updateClinicalAssessment: Dispatch<ClinicalAssessmentUpdateAction>;
  answersMatchingResponseOptions: any;
  sequenceNumber: number;
  bottomMarginStyling?: boolean;
  type: string;
  isSubQuestion?: boolean;
  updateSubQuestion?: any;
  showingSubQuestion?: boolean;
  isDynamicClinicalQuestions: boolean;
  pruneDependentQuestionResponses: (clinicalQuestionId?: string) => void;
}) => {
  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);
  return (
    <QuestionWrapper
      bottomMarginStyling={bottomMarginStyling || false}
      topMarginStyling={isSubQuestion || idx === "1a" || idx === "1" || false}
      isSubQuestion={isSubQuestion || false}
    >
      {clinicalQuestion?.type === "SINGLE_SELECT" && (
        <SingleSelectQuestion
          hideQuestionAnswerDivider={doNotWrapQuestionsWithCard}
          noWrapWithCard={doNotWrapQuestionsWithCard || isFaxAuthBuilderFlow}
          noTopBorders={isSubQuestion}
          noBottomBorders={showingSubQuestion}
          questionText={clinicalQuestion.questionText || ""}
          questionType={clinicalQuestion.type}
          ordinal={ordinal}
          options={
            clinicalQuestion.responseOptions?.map(({ responseOptionText, observationCodes, optionId }) => ({
              id: optionId || encodeCodesAsString(observationCodes),
              label: responseOptionText,
            })) || []
          }
          selectedOption={
            answersMatchingResponseOptions.length > 0
              ? type === "AGADIA"
                ? answersMatchingResponseOptions[answersMatchingResponseOptions.length - 1].optionId
                : encodeCodesAsString(answersMatchingResponseOptions[answersMatchingResponseOptions.length - 1].codes)
              : ""
          }
          setSelectedOption={(opt) => {
            updateSubQuestion(sequenceNumber, [{ codes: decodeCodes(opt) }]);
            if (type === "AGADIA") {
              updateClinicalAssessment({
                type: "updateSingleSelectAnswer",
                selectedOptionId: opt,
                questionId: clinicalQuestion?.id || "",
              });
            } else {
              updateClinicalAssessment({
                type: "updateSingleSelectAnswer",
                selectedOptionCodes: decodeCodes(opt),
                questionId: clinicalQuestion?.id || "",
              });
              // pruneDependentQuestionResponses when the CA is dynamic
              if (isDynamicClinicalQuestions) {
                pruneDependentQuestionResponses(clinicalQuestion.originalVersionId);
              }
            }
          }}
          relevantLinks={clinicalQuestion.relevantLinks}
          required={clinicalQuestion.required}
        />
      )}
      {clinicalQuestion?.type === "DATE" && (
        <DateSelectQuestion
          hideQuestionAnswerDivider={doNotWrapQuestionsWithCard}
          noWrapWithCard={doNotWrapQuestionsWithCard || isFaxAuthBuilderFlow}
          noTopBorders={isSubQuestion}
          noBottomBorders={showingSubQuestion}
          questionText={clinicalQuestion.questionText || ""}
          ordinal={ordinal}
          dateSelected={
            (answerWithCodeMatch?.valueDate && parseDateFromISOString(answerWithCodeMatch.valueDate)) || null
          }
          setDateSelected={(opt) => {
            updateClinicalAssessment({
              questionId: clinicalQuestion?.id || "",
              type: "updateDateAnswer",
              dateString: formatDateToISODate(opt),
            });
          }}
          relevantLinks={clinicalQuestion.relevantLinks}
          required={clinicalQuestion.required}
        />
      )}
      {clinicalQuestion?.type === "MULTI_SELECT" && (
        <MultiSelectQuestion
          hideQuestionAnswerDivider={doNotWrapQuestionsWithCard}
          noWrapWithCard={doNotWrapQuestionsWithCard || isFaxAuthBuilderFlow}
          noTopBorders={isSubQuestion}
          noBottomBorders={showingSubQuestion}
          questionText={clinicalQuestion.questionText || ""}
          ordinal={ordinal}
          options={
            clinicalQuestion.responseOptions?.map(({ responseOptionText, observationCodes, optionId }) => ({
              id: optionId || encodeCodesAsString(observationCodes),
              label: responseOptionText,
            })) || []
          }
          relevantLinks={clinicalQuestion.relevantLinks}
          required={clinicalQuestion.required}
          selections={
            type === "AGADIA"
              ? answersMatchingResponseOptions.map((a: any) => a.optionId) || []
              : answersMatchingResponseOptions.map((a: any) => encodeCodesAsString(a.codes)) || []
          }
          setSelections={(opts: string[]) => {
            updateSubQuestion(
              sequenceNumber,
              opts.map((encoded) => decodeCodes(encoded)).map((codes) => ({ codes }))
            );
            if (type === "AGADIA") {
              updateClinicalAssessment({
                type: "updateMultiSelectAnswer",
                selectedOptionIds: opts,
                questionId: clinicalQuestion?.id || "",
              });
            } else {
              updateClinicalAssessment({
                type: "updateMultiSelectAnswer",
                selectedOptionCodesSelections: opts.map((encoded) => decodeCodes(encoded)),
                questionId: clinicalQuestion?.id || "",
              });
            }
          }}
        />
      )}
      {clinicalQuestion?.type === "MEASUREMENT" && (
        <ShortAnswerQuestion
          hideQuestionAnswerDivider={doNotWrapQuestionsWithCard}
          noWrapWithCard={doNotWrapQuestionsWithCard || isFaxAuthBuilderFlow}
          noTopBorders={isSubQuestion}
          noBottomBorders={showingSubQuestion}
          error={!measurementAnswerIsValid}
          helperText={!measurementAnswerIsValid ? "Answer must be a number" : ""}
          questionText={clinicalQuestion.questionText || ""}
          ordinal={ordinal}
          relevantLinks={clinicalQuestion.relevantLinks}
          required={clinicalQuestion.required}
          answerText={answerWithCodeMatch?.valueString || ""}
          setAnswerText={(ipt) => {
            updateClinicalAssessment({
              type: "updateMeasureAnswer",
              userInput: ipt,
              questionId: clinicalQuestion?.id || "",
            });
          }}
        />
      )}
      {clinicalQuestion?.type === "FREE_TEXT" && (
        <ShortAnswerQuestion
          hideQuestionAnswerDivider={doNotWrapQuestionsWithCard}
          noWrapWithCard={doNotWrapQuestionsWithCard || isFaxAuthBuilderFlow}
          noTopBorders={isSubQuestion}
          noBottomBorders={showingSubQuestion}
          questionText={clinicalQuestion.questionText || ""}
          ordinal={ordinal}
          relevantLinks={clinicalQuestion.relevantLinks}
          required={clinicalQuestion.required}
          answerText={answerWithCodeMatch?.valueString || ""}
          setAnswerText={(ipt) => {
            updateSubQuestion(sequenceNumber, ipt);
            updateClinicalAssessment({
              type: "updateFreeTextAnswer",
              userInput: ipt,
              questionId: clinicalQuestion?.id || "",
            });
          }}
        />
      )}
    </QuestionWrapper>
  );
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const QuestionWrapper = styled(({ bottomMarginStyling, topMarginStyling, isSubQuestion, ...props }) => (
  <div {...props} />
))(
  ({
    bottomMarginStyling,
    topMarginStyling,
    isSubQuestion,
    theme,
  }: {
    bottomMarginStyling: boolean;
    topMarginStyling: boolean;
    isSubQuestion: boolean;
    theme: any;
  }) => ({
    marginTop: topMarginStyling ? theme.spacing(0) : theme.spacing(2),
    borderTop: isSubQuestion ? `1px solid ${colorsLight.gray.divider}` : "",
    marginBottom: bottomMarginStyling ? theme.spacing(2) : theme.spacing(0),
  })
);

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ServiceHeader = styled(H2)(({ theme }) => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(2),
}));

const updateSubQuestionsClinicalAssessment = (
  sq: SubQuestion,
  updateClinicalAssessment: React.Dispatch<ClinicalAssessmentUpdateAction>
) => {
  if (sq.clinicalQuestion?.type === "FREE_TEXT" || sq.clinicalQuestion?.type === "MEASUREMENT") {
    updateClinicalAssessment({
      type: sq.clinicalQuestion?.type === "FREE_TEXT" ? "updateFreeTextAnswer" : "updateMeasureAnswer",
      userInput: "",
      questionId: sq.clinicalQuestion?.id || "",
    });
  } else if (sq.clinicalQuestion?.type === "SINGLE_SELECT") {
    updateClinicalAssessment({
      type: "updateSingleSelectAnswer",
      selectedOptionCodes: [],
      questionId: sq.clinicalQuestion?.id || "",
    });
  } else if (sq.clinicalQuestion?.type === "MULTI_SELECT") {
    updateClinicalAssessment({
      type: "updateMultiSelectAnswer",
      selectedOptionCodesSelections: [],
      questionId: sq.clinicalQuestion?.id || "",
    });
  } else {
    updateClinicalAssessment({
      questionId: sq.clinicalQuestion?.id || "",
      type: "updateDateAnswer",
      dateString: "",
    });
  }
};

const shouldUpdateSubQuestions = (
  q: SubQuestion | ClinicalAssessmentResponseWithSubQuestions | undefined,
  oldAnswers: any,
  newAnswers: any
) => {
  if (q?.clinicalQuestion?.type === "FREE_TEXT" || q?.clinicalQuestion?.type === "MEASUREMENT") {
    return newAnswers.toString().length === 0;
  } else if (q?.clinicalQuestion?.type === "MULTI_SELECT" || q?.clinicalQuestion?.type === "SINGLE_SELECT") {
    let defaultNextQuestion = q.clinicalQuestion?.externalAssessmentProperties?.defaultNextQuestionId;
    let subQuestionAnswerWasPresentInOldAnswers =
      oldAnswers !== undefined && Array.isArray(oldAnswers)
        ? oldAnswers.some((oA: any) => {
            let option = q.clinicalQuestion?.responseOptions?.find((o) => o.optionId === oA.optionId);
            return option?.externalAssessmentProperties?.nextQuestionId !== defaultNextQuestion;
          })
        : false;
    let subQuestionAnswerIsNotPresentInNewAnswers =
      newAnswers !== undefined && Array.isArray(newAnswers)
        ? newAnswers?.every((oA: any) => {
            let option = q.clinicalQuestion?.responseOptions?.find((o) => o.optionId === oA.system);
            return option?.externalAssessmentProperties?.nextQuestionId === defaultNextQuestion;
          })
        : false;
    return subQuestionAnswerWasPresentInOldAnswers && subQuestionAnswerIsNotPresentInNewAnswers;
  }
  return true;
};

// Sorts all clinical questions by clinical service
// Returns a Map of: {clinicalServiceId: [clinicalServiceName, [questions]]}
export const SortQuestionsByService = (
  assessment: ClinicalAssessmentState
): Map<string, [string, ClinicalAssessmentResponse[]]> => {
  const questionsByService: Map<string, [string, ClinicalAssessmentResponse[]]> = new Map<
    string,
    [string, ClinicalAssessmentResponse[]]
  >();
  assessment?.questions?.forEach((q) => {
    if (q.clinicalQuestion?.clinicalServiceName && q.clinicalQuestion.parentServiceId) {
      // Use parentServiceId (.clinicalService.id gets jumbled by versioning)
      const currentServiceId: string = q.clinicalQuestion.parentServiceId;
      const currentServiceName: string = q.clinicalQuestion.clinicalServiceName;
      const currentServiceQuestions = questionsByService.get(currentServiceId);
      if (
        !currentServiceQuestions ||
        (currentServiceQuestions && currentServiceQuestions[1] && currentServiceQuestions[1].length === 0)
      ) {
        // This service has no questions. Start an array.
        questionsByService.set(currentServiceId, [currentServiceName, [q]]);
      } else if (currentServiceQuestions && currentServiceQuestions[1] && currentServiceQuestions[1].length > 0) {
        // This service has questions. Add the current question to the back.
        questionsByService.set(currentServiceId, [currentServiceName, currentServiceQuestions[1].concat([q])]);
      }
    }
  });
  return questionsByService;
};
