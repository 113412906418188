import {
  Body2,
  Caption,
  Column,
  DATE_RANGE,
  FilterConfig,
  FilterParams,
  FilterValues,
  LOOKUP_SEARCH,
  SelectOptionsHook,
  formatDateWithCustomFormat,
  isNonNullable,
  useFeature,
  useLazyLoadingQueryOptionsHook,
} from "@coherehealth/common";
import {
  FindingRecord,
  useFindClaimHistory,
  FindingRecordSearchPayload,
  ClausalCondition,
  DiagnosisCode,
  ProcedureCode,
  useGetDiagnosisCodes,
  useGetProcedureCodes,
  FindClaimHistoryResponse,
} from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";
import { utcToZonedTime } from "date-fns-tz";
import { ReactNode, useCallback, useEffect, useState } from "react";
import {
  processDiagnosisCodeFilter,
  processPatientFilter,
  processProcedureCodeFilter,
  processServiceDateEndFilter,
  processServiceDateStartFilter,
} from "./ClaimHistoryUtil";

interface FilterConfigInjectedDeps {
  useProcedures: SelectOptionsHook<ProcedureCode | null>;
  useDiagnosisCodeOptions: SelectOptionsHook<DiagnosisCode>;
}

export type UseClaimHistoryProps = {
  claimHistoryProps: {
    loadingClaimHistory: boolean;
    claimsHistory: FindClaimHistoryResponse;
    setClaimsHistory: React.Dispatch<React.SetStateAction<FindClaimHistoryResponse>>;
    getClaimsData: () => Promise<void>;
    filterConfigs: FilterConfig[];
    queryParams: FindingRecordSearchPayload;
    setQueryParams: React.Dispatch<React.SetStateAction<FindingRecordSearchPayload>>;
    setFilterParams: React.Dispatch<React.SetStateAction<FilterParams>>;
    filterValues: FilterValues;
    setFilterValues: React.Dispatch<React.SetStateAction<FilterValues>>;
    tableColumns: Column<FindingRecord>[];
    rootCompId?: string;
  };
};

interface ClaimHistoryProps {
  patientId?: string;
}

const useClaimHistory = ({ patientId }: ClaimHistoryProps): UseClaimHistoryProps => {
  const claimHistoryFeatureOn = useFeature("claimHistoryFeature");
  const [claimsHistory, setClaimsHistory] = useState<FindClaimHistoryResponse>({});
  const { loading: loadingClaimHistory, mutate: fetchClaimHistory } = useFindClaimHistory({});
  const [filterParams, setFilterParams] = useState<FilterParams>({});
  const [filterValues, setFilterValues] = useState<FilterValues>({});
  const [queryParams, setQueryParams] = useState<FindingRecordSearchPayload>({
    max: 11,
    offset: 0,
    sortBy: [
      {
        sortOrder: "DESC",
        key: "findingInstant",
      },
    ],
  });

  const columnValueConfig = (columnName: string): ((findingRecord: FindingRecord) => ReactNode) => {
    switch (columnName) {
      case "findingInstant":
        return (findingRecord: FindingRecord) => {
          const dateString = findingRecord.findingInstant;
          const date = dateString ? utcToZonedTime(new Date(dateString), "UTC") : undefined;
          return formatDateWithCustomFormat(date, "MM/dd/yyyy");
        };
      case "procedureCodes":
        return (findingRecords: FindingRecord) => {
          const procedureCodes = findingRecords.findingType?.extensions?.find(
            (extension) => extension.name === "ProcedureCodes"
          )?.valueListString;
          const procedureCodesDescription = findingRecords.findingType?.extensions?.find(
            (extension) => extension.name === "ProcedureCodesDescription"
          )?.valueListString;
          return (
            <>
              {procedureCodes?.map((procedureCode, index) => {
                return (
                  <Body2Unbold>
                    <strong>{procedureCode}</strong>
                    {procedureCodesDescription?.[index] && " - " + procedureCodesDescription?.[index]}
                  </Body2Unbold>
                );
              })}
            </>
          );
        };
      case "diagnosisCodes":
        return (findingRecords: FindingRecord) => {
          const diagnosisCodes = findingRecords.findingType?.value?.valueListString;
          const diagnosisCodesDescription = findingRecords.findingType?.extensions?.find(
            (extension) => extension.name === "DiagnosisCodesDescription"
          )?.valueListString;
          return (
            <>
              {diagnosisCodes?.map((procedureCode, index) => {
                return (
                  <Body2Unbold>
                    <strong>{procedureCode}</strong>
                    {diagnosisCodesDescription?.[index] && " - " + diagnosisCodesDescription?.[index]}
                  </Body2Unbold>
                );
              })}
            </>
          );
        };
      default:
        return (findingRecord: FindingRecord) => findingRecord.findingInstant;
    }
  };

  const tableColumns: Column<FindingRecord>[] = [
    {
      label: "Service date",
      name: "findingInstant",
      value: columnValueConfig("findingInstant"),
      width: "138px",
      themedPaddingRight: 2,
      alignItems: "start",
      header: <Caption color="textSecondary">Service date</Caption>,
    },
    {
      name: "procedureCodes",
      value: columnValueConfig("procedureCodes"),
      width: "492px",
      themedPaddingRight: 2,
      unSortable: true,
      alignItems: "start",
      header: <Caption color="textSecondary">Procedure(s)</Caption>,
    },
    {
      name: "diagnosisCodes",
      value: columnValueConfig("diagnosisCodes"),
      width: "300px",
      alignItems: "start",
      themedPaddingRight: 0,
      unSortable: true,
      header: <Caption color="textSecondary">Diagnosis code(s)</Caption>,
    },
  ];

  const useProcedures: SelectOptionsHook<ProcedureCode | null> = (selectOptionsParams) =>
    useLazyLoadingQueryOptionsHook<any, unknown>({
      useGetHook: useGetProcedureCodes,
      ...selectOptionsParams,
    });

  const useDiagnosisCodeOptions: SelectOptionsHook<DiagnosisCode> = (selectOptionsParams) =>
    useLazyLoadingQueryOptionsHook<DiagnosisCode, unknown>({
      useGetHook: useGetDiagnosisCodes,
      additionalQueryParams: { isBillable: true },
      ...selectOptionsParams,
    });

  const filterConfigDeps: FilterConfigInjectedDeps = {
    useProcedures,
    useDiagnosisCodeOptions,
  };

  const tableColumnToFilterConfig = (
    deps: FilterConfigInjectedDeps,
    column: Column<FindingRecord>
  ): FilterConfig | null => {
    const useProcedures = deps.useProcedures as SelectOptionsHook<unknown>;
    const useDiagnosis = deps.useDiagnosisCodeOptions as SelectOptionsHook<unknown>;
    switch (column.name) {
      case "findingInstant":
        return {
          key: column.name,
          label: "Service date",
          filterType: DATE_RANGE,
          startApiParamName: "serviceDateStart",
          endApiParamName: "serviceDateEnd",
          initialFilterValue: filterValues["findingInstant"],
        } as FilterConfig;
      case "procedureCodes":
        return {
          key: column.name,
          label: "Procedure code or name",
          filterType: LOOKUP_SEARCH,
          useOptions: useProcedures,
          optionToLabel: ({ code }: ProcedureCode) => code || "--",
          renderOption: ({ code, description }: ProcedureCode) => {
            return (
              <Body2Unbold>
                <strong>{code}</strong> - {description}
              </Body2Unbold>
            );
          },
          apiParamName: "procedureCodes",
          optionToParamValue: ({ code }: ProcedureCode) => code,
          initialFilterValues: filterValues["procedureCodes"],
        } as FilterConfig;
      case "diagnosisCodes":
        return {
          key: column.name,
          label: "Diagnosis code or name",
          filterType: LOOKUP_SEARCH,
          useOptions: useDiagnosis,
          optionToLabel: ({ code }: DiagnosisCode) => code || "--",
          renderOption: ({ code, description }: DiagnosisCode) => {
            return (
              <Body2Unbold>
                <strong>{code}</strong> - {description}
              </Body2Unbold>
            );
          },
          apiParamName: "diagnosisCodes",
          optionToParamValue: ({ searchableCode }: DiagnosisCode) => searchableCode,
          initialFilterValues: filterValues["diagnosisCodes"],
        } as FilterConfig;
    }
    return null;
  };

  const filterConfigs: FilterConfig[] = tableColumns
    .map(tableColumnToFilterConfig.bind(null, filterConfigDeps))
    .filter(isNonNullable);

  const getClaimsData = useCallback(async () => {
    if (!patientId) {
      return;
    }
    let filterOn: ClausalCondition[] = [];
    const patientFilter = processPatientFilter(patientId);
    const procedureCodeFilter = processProcedureCodeFilter(filterParams["procedureCodes"] as string[] | undefined);
    const diagnosisCodeFilter = processDiagnosisCodeFilter(filterParams["diagnosisCodes"] as string[] | undefined);
    const serviceDateStart = processServiceDateStartFilter(filterParams["serviceDateStart"] as Date | undefined);
    const serviceDateEnd = processServiceDateEndFilter(filterParams["serviceDateEnd"] as Date | undefined);
    [patientFilter, procedureCodeFilter, diagnosisCodeFilter, serviceDateStart, serviceDateEnd].forEach((filter) => {
      if (filter) {
        filterOn = [...filterOn, filter];
      }
    });
    const claimHIstoryResponse = await fetchClaimHistory({
      max: queryParams.max ? queryParams.max - 1 : 10,
      offset: queryParams.offset,
      filterOn: filterOn,
      sortBy: queryParams.sortBy,
    });
    setClaimsHistory(claimHIstoryResponse);
  }, [fetchClaimHistory, patientId, filterParams, queryParams.max, queryParams.offset, queryParams.sortBy]);

  useEffect(() => {
    if (claimHistoryFeatureOn && patientId) {
      getClaimsData();
    }
  }, [claimHistoryFeatureOn, patientId, getClaimsData]);

  return {
    claimHistoryProps: {
      loadingClaimHistory,
      claimsHistory,
      setClaimsHistory,
      getClaimsData,
      filterConfigs,
      filterValues,
      setFilterValues,
      setFilterParams,
      queryParams,
      setQueryParams,
      tableColumns,
    },
  };
};

export default useClaimHistory;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Body2Unbold = styled(Body2)({
  fontWeight: "normal",
});
