import React, { Dispatch } from "react";
import Grid from "@material-ui/core/Grid";
import { RadioGroup, RichTextEditor, useFeature } from "@coherehealth/common";
import { AuditReview, CopyPasteInfo } from "@coherehealth/core-platform-api";
import { useTheme } from "@material-ui/core/styles";
import { ReviewControlBoxOuterContainer, ReviewControlBoxInnerContainer } from "../MDReview/MDReviewEdit";
import { useMediaQuery } from "@material-ui/core";
import { GenericAuditReview, GenericAuditReviewUpdate } from "components/ClinicalReview/reviewUtils/useReviews";
import { RIGHT_HAND_PANEL_SIZE, usePasteTracker } from "components/ClinicalReview/reviewUtils/utils";
import { ReviewControlPanel } from "../ReviewControlPanel/ReviewControlPanel";
import { getAuditReviewOutcomes } from "./auditReviewUtils";

interface Props<T extends GenericAuditReview> {
  auditReview: T;
  setAuditReview: GenericAuditReviewUpdate<T>;
  onDiscard: () => void;
  discarding: boolean;
  onUpdatingDraft: () => void;
  updating: boolean;
  onSubmit: () => void;
  submitting: boolean;
  copiedAttachmentText?: CopyPasteInfo;
  setPastedAttachmentTexts?: Dispatch<React.SetStateAction<CopyPasteInfo[] | undefined>>;
}

export default function AuditReviewEdit<T extends GenericAuditReview>({
  auditReview,
  setAuditReview,
  onDiscard,
  discarding,
  onUpdatingDraft,
  updating,
  onSubmit,
  submitting,
  copiedAttachmentText,
  setPastedAttachmentTexts,
}: Props<T>) {
  const theme = useTheme();
  const newAuditReviewOutcomesEndabled = useFeature("newAuditReviewOutcomes");
  const matchesRight = useMediaQuery(theme.breakpoints.up(RIGHT_HAND_PANEL_SIZE));
  const setValue = (fieldName: keyof AuditReview) => (val: string) => {
    setAuditReview({ [fieldName]: val });
  };
  usePasteTracker(copiedAttachmentText, setPastedAttachmentTexts);
  const validFields = !!(
    ((auditReview.auditNotes && auditReview.auditNotes !== "<p><br></p>") ||
      auditReview.reviewType === "FaxAuditReview") &&
    auditReview.auditOutcome
  );

  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: "48px" }} component="form">
        <Grid item xs={12}>
          <RichTextEditor
            readonly={false}
            label="Audit notes"
            htmlValue={auditReview.auditNotes || ""}
            setHtmlValue={setValue("auditNotes")}
            testid="audit-review-editor"
          />
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            nowrap
            label="Audit outcome"
            options={getAuditReviewOutcomes(newAuditReviewOutcomesEndabled, auditReview.reviewType)}
            value={auditReview.auditOutcome}
            onChange={setValue("auditOutcome")}
          />
        </Grid>
      </Grid>

      <ReviewControlBoxOuterContainer matchesRight={matchesRight}>
        <ReviewControlBoxInnerContainer>
          <ReviewControlPanel
            reviewType={auditReview?.reviewType}
            discardAndExitDisabled={updating}
            discarding={discarding}
            handleDiscardAndExit={onDiscard}
            handleSaveAndExit={onUpdatingDraft}
            saveAndExitLoading={updating}
            saveAndExitDisabled={discarding}
            handleFinishReview={onSubmit}
            finishButtonDisabled={!validFields || submitting || updating || discarding}
            submitting={submitting}
            isAuditReview
          />
        </ReviewControlBoxInnerContainer>
      </ReviewControlBoxOuterContainer>
    </>
  );
}
