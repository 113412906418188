import React, { PropsWithChildren } from "react";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import classnames from "classnames";
import { Transition } from "react-transition-group";

const useStylesHorizontalCollapse = makeStyles((theme) => ({
  /* Styles applied to the container element. */
  container: {
    overflow: "hidden",
    height: "auto",
    width: 0,
    transition: theme.transitions.create("width"),
  },
  /* Styles applied to the container element when the transition has entered. */
  entered: {
    height: "auto",
    overflow: "visible",
    width: "auto",
  },
  /* Styles applied to the container element when the transition has exited. */
  hidden: {
    visibility: "hidden",
  },
  /* Styles applied to the outer wrapper element. */
  wrapper: {
    display: "flex",
    width: "auto",
    height: "100%",
  },
  /* Styles applied to the inner wrapper element. */
  wrapperInner: {
    width: "auto",
    height: "100%",
  },
}));

interface Props {
  in: boolean;
}

/**
 * The material-ui Collapse component in v4 (our current version) only works vertically, however v5 introduces
 * a horizontal direction support for Collapse (https://github.com/mui-org/material-ui/pull/20619/files)
 * This file is basically a stripped down version of that implementation linked above. Once v5 is released and we
 * upgrade to it, then we should remove this in favor of using the actual material-ui Collapse.
 */
export default function HorizontalCollapse({ children, in: inProp }: PropsWithChildren<Props>) {
  const classes = useStylesHorizontalCollapse();
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const getWrapperWidth = () => `${wrapperRef.current?.clientWidth || 0}px`;

  const theme = useTheme();

  const handleEnter = (node: HTMLElement) => {
    if (wrapperRef.current) {
      // Set absolute position to get the size of collapsed content
      wrapperRef.current.style.position = "absolute";
    }
    node.style.width = "0px";
  };

  const handleEntering = (node: HTMLElement) => {
    const wrapperWidth = getWrapperWidth();

    if (wrapperRef.current) {
      // After the size is read reset the position back to default
      wrapperRef.current.style.position = "";
    }

    node.style.transitionDuration = `${theme.transitions.duration.enteringScreen}ms`;
    node.style.width = wrapperWidth;
  };

  const handleEntered = (node: HTMLElement) => {
    node.style.width = "auto";
  };

  const handleExit = (node: HTMLElement) => {
    node.style.width = getWrapperWidth();
  };

  const handleExiting = (node: HTMLElement) => {
    node.style.transitionDuration = `${theme.transitions.duration.leavingScreen}ms`;
    node.style.width = "0px";
  };

  return (
    <Transition
      in={inProp}
      onEnter={handleEnter}
      onEntered={handleEntered}
      onEntering={handleEntering}
      onExit={handleExit}
      onExiting={handleExiting}
      addEndListener={() => {}}
    >
      {(state: string, childProps: any) => (
        <div
          className={classnames(classes.container, {
            [classes.entered]: state === "entered",
            [classes.hidden]: state === "exited" && !inProp,
          })}
          style={{
            minWidth: "0px",
          }}
          {...childProps}
        >
          <div className={classes.wrapper} ref={wrapperRef}>
            <div className={classes.wrapperInner}>{children}</div>
          </div>
        </div>
      )}
    </Transition>
  );
}
