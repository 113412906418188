import { useMemo } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Divider, Grid, styled, useTheme } from "@material-ui/core";
import { Body1, Caption, Card, colorsLight, formatDateStr, H6, Tooltip, withId } from "@coherehealth/common";
import MuiInfoIcon from "@material-ui/icons/InfoOutlined";
import PreApprovedCareProcedureCodeList from "./PreApprovedCareProcedureCodeList";
import { ClinicalService, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { procedureBucketDataModel } from "../common";
import { convertToTitleCase } from "util/textUtil";
import { convertToSentenceCase } from "util/providerUtils";
import { isTerminalStatus } from "util/serviceRequest";

interface Props {
  serviceRequest: ServiceRequestResponse;
}

export default function PreApprovedCareReadOnly({ serviceRequest }: Props) {
  const theme = useTheme();
  const clinicalServiceWithPxs = useMemo(() => {
    return procedureBucketDataModel(serviceRequest.semanticProcedureCodes?.map(withId) || []);
  }, [serviceRequest]);
  const clinicalServiceIds = Array.from(clinicalServiceWithPxs.keys());

  return (
    <Card style={{ padding: theme.spacing(3) }}>
      <Grid container>
        <Grid item xs={12}>
          <Header>
            Pre-approved care details
            <Tooltip
              title={
                "Pre-approved care details cannot be altered. If they don't meet your requirements, initiate a new request."
              }
              placement="bottom"
            >
              <InfoIcon />
            </Tooltip>
          </Header>
        </Grid>
        <Grid item xs={3}>
          <SecondaryCaption>Primary diagnosis</SecondaryCaption>
          <Row>
            <Body>
              {`${convertToSentenceCase(
                serviceRequest.primarySemanticDiagnosisCode?.code || "- -"
              )} - ${convertToSentenceCase(serviceRequest.primarySemanticDiagnosisCode?.description || "- -")}`}
            </Body>
          </Row>
        </Grid>
        <Grid item xs={3}>
          <SecondaryCaption>Care setting</SecondaryCaption>
          <Row>
            <Body>{convertToTitleCase(serviceRequest.encounterType || "- -")}</Body>
          </Row>
        </Grid>
        <Grid item xs={3}>
          <SecondaryCaption>Place of service</SecondaryCaption>
          <Row>
            <Body>{convertToTitleCase(serviceRequest.placeOfService?.name || "- -")}</Body>
          </Row>
        </Grid>
        <Grid item xs={3}>
          <SecondaryCaption>Dates of service</SecondaryCaption>
          <Row>
            <Body>
              {formatDateStr(serviceRequest.startDate)}
              {serviceRequest.endDate && ` - ${formatDateStr(serviceRequest.endDate)}`}
            </Body>
          </Row>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ marginTop: theme.spacing(3) }} />
        </Grid>
        {/* For each clinical service, generate title, and px code table */}
        {clinicalServiceIds.sort().map((csId) => {
          const clinicalService = serviceRequest.clinicalServices?.find((cs): cs is ClinicalService => {
            return cs?.id === csId;
          });
          const units =
            clinicalService && !clinicalService.isUnitsOnPx && clinicalServiceWithPxs
              ? clinicalServiceWithPxs
                  ?.get(csId)
                  ?.reduce((a, b) => (a > (b.units ? b.units : 0) ? a : b?.units || 0), 0)
                  .toString()
              : "";

          const approvedUnits =
            clinicalService && !clinicalService.isUnitsOnPx && clinicalServiceWithPxs
              ? clinicalServiceWithPxs
                  ?.get(csId)
                  ?.reduce((a, b) => (a > (b.approvedUnits ? b.approvedUnits : 0) ? a : b?.approvedUnits || 0), 0)
                  .toString()
              : "";

          const showNumberOfVisits = clinicalService && !clinicalService.isUnitsOnPx;

          return (
            <Grid item xs={12} key={csId}>
              <ClinicalServiceTitle>{clinicalService?.name || "Uncategorized Service"}</ClinicalServiceTitle>

              {showNumberOfVisits && (
                <Grid item xs={12} style={{ paddingBottom: 0 }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <StyledCaption>Number of visits</StyledCaption>
                    <StyledBody>{isTerminalStatus(serviceRequest) ? approvedUnits : units || "--"}</StyledBody>
                  </div>
                </Grid>
              )}
              <PreApprovedCareProcedureCodeList
                serviceRequest={serviceRequest}
                procedureCodes={clinicalServiceWithPxs.get(csId) || []}
                clinicalService={clinicalService}
              />
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Row = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SecondaryCaption = styled(Caption)(() => ({
  color: colorsLight.font.secondary,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Header = styled(H6)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: colorsLight.font.main,
  fontFamily: "Gilroy-SemiBold",
  paddingBottom: theme.spacing(2),
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
const Body = styled(Body1)(() => ({
  color: colorsLight.font.main,
  fontFamily: "Gilroy-Medium",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const InfoIcon = styled(MuiInfoIcon)(({ theme }) => ({
  color: colorsLight.font.light,
  marginLeft: theme.spacing(1),
  height: 20,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ClinicalServiceTitle = styled(H6)(({ theme }) => ({
  padding: theme.spacing(3, 0, 1, 0),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledCaption = styled(Caption)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: "inline-block",
  padding: theme.spacing(1, 0),
  minWidth: "170px",
  marginRight: theme.spacing(5),
  verticalAlign: "top",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledBody = styled(Body1)(() => ({
  display: "inline-block",
  wordWrap: "break-word",
}));
