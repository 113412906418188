import { useContext, useEffect, useMemo, useState } from "react";

import {
  appHeaderHeight,
  Body1,
  Card,
  FixedSideRail,
  FullPageLayout,
  H2,
  H3,
  H4,
  InlineButton,
  patientDisplayName,
  PrimaryButton,
  ScrollableMainPanel,
  TertiaryButton,
  useFeature,
  useMuiContainerStyles,
} from "@coherehealth/common";
import { useGetPatient, useGetUserById } from "@coherehealth/core-platform-api";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import { useTheme } from "@material-ui/core/styles";
import PatientSummaryIcon from "../../images/PatientSummaryIcon";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { useSnackbar } from "notistack";
import { useMatch, Link, generatePath, useLocation } from "react-router-dom";
import routes from "routes";
import { headerHeight } from "util/StyleConstants";

import { useAuthorized } from "authorization";
import { Box, Divider, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { getPatientHealthPlanName } from "util/patientUtils";
import HeaderContainer from "components/AppHeader/HeaderContainer";
import AuthorizationPatientSummary from "./AuthorizationPatientSummary";
import PatientSummaryForAuthorizations from "../PatientSummaryForAuthorizations";
import { PatientInfoSection } from "components/PatientInfo";
import { trackUserActivity } from "../../../util/userActivityTracker";
import { User, UserContext } from "UserContext";
import useAuthorizationPatientSummary from "./useAuthorizationPatientSummary";
import { TemporaryPatientChip } from "common/TemporaryPatientChip/TemporaryPatientChip";
import { isInternalUser } from "util/user";
import ScrubTinsContextProvider from "../../ScrubTinsContext";
import { Helmet } from "react-helmet-async";
import { usePatientContext } from "../../../util/context/PatientContext";
import { useHealthPlanDisplayNameContext } from "../../../util/context/HealthPlanDisplayNameContext";

export default function AuthorizationPatientSummaryPaginationPage() {
  const match = useMatch(routes.PATIENT_SUMMARY);
  const patientId = match?.params.patientId || "";

  const location = useLocation();
  const search = useMemo(() => new URLSearchParams(location.search), [location]);
  const reviewServiceRequestId = search.get("reviewServiceRequestId") || undefined;

  const canCreateCarePathJourney = useAuthorized("START_AUTH_REQUEST");

  const { getUser } = useContext(UserContext);
  const [user, setUser] = useState<User>();

  const { setPatientData } = usePatientContext();
  const { setHealthPlanDisplayNameContext } = useHealthPlanDisplayNameContext();

  const { data: userResponse, refetch: refetchUser } = useGetUserById({
    id: user?.sub || "",
    lazy: true,
    queryParams: {
      includeOrganizationName: true,
    },
  });

  useEffect(() => {
    getUser()?.then((user) => setUser(user));
  }, [getUser]);

  useEffect(() => {
    if (user) {
      refetchUser();
    }
  }, [user, refetchUser]);

  const { data: patientSummaryData, actions: patientSummaryActions } = useAuthorizationPatientSummary(
    patientId,
    reviewServiceRequestId
  );

  const isInternalCohereUser = user && isInternalUser(user);

  if (!patientId) {
    throw new Error("No patientId found in route");
  }

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (
      reviewServiceRequestId &&
      patientSummaryData.authorizationsData &&
      !patientSummaryData.authorizationsLoading &&
      !patientSummaryData.authorizationsData.find((auth) =>
        auth.serviceRequestsOnAuth?.some((sr) => sr.id === reviewServiceRequestId)
      ) &&
      patientSummaryData.serviceRequestsData &&
      !patientSummaryData.serviceRequestsLoading &&
      !patientSummaryData.serviceRequestsData.find((sr) => sr.id === reviewServiceRequestId)
    ) {
      enqueueSnackbar("We're sorry. Drafts are only visible on this page for the person who created it.");
    }
  });

  useEffect(() => {
    if (patientSummaryData.serviceRequestsData && reviewServiceRequestId) {
      const reviewServiceRequest = patientSummaryData.serviceRequestsData?.filter(
        (data) => data.id === reviewServiceRequestId
      )[0];
      if (reviewServiceRequest?.isRestricted) {
        enqueueSnackbar("You do not have access to this request.", { variant: "error" });
      }
    }
  }, [enqueueSnackbar, reviewServiceRequestId, patientSummaryData.serviceRequestsData]);

  const useStyles = makeStyles((theme) => ({
    workflowButton: {
      marginLeft: theme.spacing(2),
    },
    mainPageContent: {
      height: "100%",
      paddingTop: theme.spacing(5) + headerHeight,
      paddingBottom: theme.spacing(10),
    },
  }));

  const {
    data: patient,
    loading: patientLoading,
    error: patientError,
  } = useGetPatient({ id: patientId, queryParams: { includeReferralEligibility: true } });
  useEffect(() => {
    if (patientError) {
      enqueueSnackbar(`Error loading patient: ${patientError.message}`, { variant: "error" });
    }
  }, [patientError, enqueueSnackbar]);

  useEffect(() => {
    if (patientSummaryData.authorizationsError) {
      enqueueSnackbar(`Error loading authorizations: ${patientSummaryData.authorizationsError.message}`, {
        variant: "error",
      });
    }
  }, [patientSummaryData.authorizationsError, enqueueSnackbar]);

  useEffect(() => {
    if (patientSummaryData.serviceRequestsError) {
      enqueueSnackbar(`Error loading serviceRequests: ${patientSummaryData.serviceRequestsError.message}`, {
        variant: "error",
      });
    }
  }, [patientSummaryData.serviceRequestsError, enqueueSnackbar]);

  const containerClasses = useMuiContainerStyles();
  const classes = useStyles();
  const theme = useTheme();
  const referralUXImprovements = useFeature("referralUXImprovements");

  const today = new Date();
  const healthPlanName = patient ? getPatientHealthPlanName(patient, today) : undefined;

  //set patient and healthPlanDisplayName contexts
  useEffect(() => {
    // Update the patient data state
    if (patient) {
      setPatientData(patient);
    }
    //set healthPlanDisplayNameContext here after patient data is set to ensure only single call to cobranding logo endpoint only fires once
    if (healthPlanName) {
      setHealthPlanDisplayNameContext(healthPlanName);
    }
  }, [patient, healthPlanName, setPatientData, setHealthPlanDisplayNameContext]);

  return (
    <>
      <Helmet>
        <title>Cohere | Patient Authorizations</title>
      </Helmet>
      <Container classes={containerClasses} maxWidth="lg" data-testid={`patient-summary-${patientId}`}>
        <HeaderContainer height={headerHeight}>
          <Grid container alignItems="center">
            {patient ? (
              <>
                <Grid item>
                  <PatientSummaryIcon style={{ paddingTop: theme.spacing(1) }} />
                </Grid>
                <Grid item>
                  <H2 style={{ marginLeft: theme.spacing(1) }}>
                    {patientDisplayName(patient)} • Authorizations
                    {patient.referralEligibility?.delegated && (
                      <InlineButton
                        className={classes.workflowButton}
                        onClick={() =>
                          (window.location.href = generatePath(routes.REFERRAL_PATIENT_SUMMARY, {
                            patientId: patient.id,
                          }))
                        }
                      >
                        View referrals
                      </InlineButton>
                    )}
                  </H2>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={4}>
                  <InlineButton
                    to={routes.DASHBOARD}
                    startIcon={<NavigateBeforeIcon fontSize="large" />}
                    component={Link}
                  >
                    Back
                  </InlineButton>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <H3 style={{ marginLeft: "auto", marginRight: "auto" }} data-public>
                      Patient summary
                    </H3>
                  </div>
                </Grid>
              </>
            )}
            {referralUXImprovements && patient?.referralEligibility?.delegated ? (
              <Grid
                item
                style={{
                  display: "flex",
                  marginLeft: "auto",
                  gap: theme.spacing(2),
                }}
              >
                <PrimaryButton
                  component={Link}
                  to={generatePath(routes.REFERRAL_BUILDER, { patientId })}
                  style={{ width: theme.spacing(23.5) }}
                >
                  Start referral
                </PrimaryButton>
                {canCreateCarePathJourney && (
                  <PrimaryButton
                    data-pendo-health-plan={healthPlanName ? healthPlanName + "-patient" : "noHealthPlan"}
                    onClick={() => {
                      healthPlanName
                        ? trackUserActivity(healthPlanName + "-patient")
                        : trackUserActivity(healthPlanName + "-noHealthPlan");
                    }}
                    to={generatePath(routes.AUTH_BUILDER, { patientId })}
                    component={Link}
                    style={{ padding: theme.spacing(2, 2.5, 2, 2.5), width: theme.spacing(23.5) }}
                  >
                    Start auth request
                  </PrimaryButton>
                )}
              </Grid>
            ) : (
              <Grid item style={{ marginLeft: "auto" }}>
                {canCreateCarePathJourney && (
                  <PrimaryButton
                    data-pendo-health-plan={healthPlanName ? healthPlanName + "-patient" : "noHealthPlan"}
                    to={generatePath(routes.AUTH_BUILDER, { patientId })}
                    component={Link}
                    style={{ padding: theme.spacing(2, 2.5, 2, 2.5), width: theme.spacing(23.5) }}
                  >
                    Start auth request
                  </PrimaryButton>
                )}
              </Grid>
            )}
          </Grid>
        </HeaderContainer>
        <div className={classes.mainPageContent}>
          {Boolean(patientLoading || patientSummaryData.authorizationsLoading) && <CircularProgress />}
          <FullPageLayout>
            <FixedSideRail
              sideRailWidth={314}
              columnSpacingRight={12}
              verticalHeightAroundSideRail={headerHeight + appHeaderHeight()}
              hasHeader
            >
              {patient && (
                <Card style={{ padding: theme.spacing(3) }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <H4>{patientDisplayName(patient)}</H4>
                      <Body1>Member ID {patient.memberId}</Body1>
                    </Grid>
                    {patient.replacesMemberId && isInternalCohereUser && (
                      <Grid item xs={12} style={{ paddingTop: 0 }}>
                        <TemporaryPatientChip temporaryMemberId={patient.replacesMemberId} />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <PatientInfoSection patient={patient} />
                    </Grid>
                  </Grid>
                </Card>
              )}
            </FixedSideRail>
            <ScrollableMainPanel sideRailWidth={314}>
              {patient && patientSummaryData.filteredAuthorizations && (
                <ScrubTinsContextProvider>
                  <AuthorizationPatientSummary
                    patient={patient}
                    refreshAuthorization={patientSummaryActions.refreshAuthorization}
                    refreshServiceRequest={patientSummaryActions.refreshServiceRequest}
                    authorizations={patientSummaryData.filteredAuthorizations}
                    setAuthorizations={patientSummaryActions.setAuthorizations}
                    selectedServiceRequest={patientSummaryData.selectedServiceRequestData}
                    user={userResponse}
                    healthPlanName={healthPlanName}
                  />
                </ScrubTinsContextProvider>
              )}
              {patient && patientSummaryData.filteredServiceRequests && (
                <PatientSummaryForAuthorizations
                  patient={patient}
                  refresh={patientSummaryActions.serviceRequestsRefetch}
                  refreshServiceRequest={patientSummaryActions.refreshServiceRequest}
                  serviceRequests={patientSummaryData.filteredServiceRequests}
                  setServiceRequests={patientSummaryActions.setServiceRequests}
                  user={userResponse}
                />
              )}
              {patientSummaryData.showLoadMoreButton && (
                <Box display="flex" justifyContent="center" marginTop={3}>
                  <TertiaryButton
                    onClick={patientSummaryActions.loadMorePatientSummary}
                    disabled={!patientSummaryData.isLoadMoreButtonEnabled}
                    loading={!patientSummaryData.isLoadMoreButtonEnabled}
                  >
                    Load more
                  </TertiaryButton>
                </Box>
              )}
            </ScrollableMainPanel>
          </FullPageLayout>
        </div>
      </Container>
    </>
  );
}
