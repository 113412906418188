import React, { SVGProps } from "react";

export default function HourglassIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="132" height="132" viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <path
          d="M66 128C101.346 128 130 99.3462 130 64C130 28.6538 101.346 0 66 0C30.6538 0 2 28.6538 2 64C2 99.3462 30.6538 128 66 128Z"
          fill="white"
        />
      </g>
      <path
        d="M96.46 33.4111H35.53C33.58 33.4111 32.01 31.8311 32.01 29.8911C32.01 27.9411 33.59 26.3711 35.53 26.3711H96.46C98.41 26.3711 99.98 27.9511 99.98 29.8911C99.99 31.8411 98.41 33.4111 96.46 33.4111Z"
        fill="#324A5E"
      />
      <path
        d="M70.69 68.1302C69.82 67.7402 69.24 66.8101 69.24 65.7601C69.24 64.7201 69.81 63.7901 70.69 63.3901C80.14 59.1201 87.1499 44.6902 87.2899 33.4102H44.7C44.84 44.6902 51.85 59.1201 61.3 63.3901C62.17 63.7801 62.75 64.7101 62.75 65.7601C62.75 66.8001 62.18 67.7302 61.3 68.1302C51.85 72.4002 44.84 86.8301 44.7 98.1101H87.2899C87.1499 86.8301 80.14 72.4002 70.69 68.1302Z"
        fill="#E6F6F9"
      />
      <path
        d="M62.5798 59.9299C64.0998 60.6199 65.2398 61.9599 65.7798 63.5899C65.8698 63.8599 65.9398 72.8899 65.9998 73.1699C66.0498 72.9199 66.1098 63.9199 66.1898 63.6799C66.7198 61.9999 67.8698 60.6199 69.4198 59.9199C73.9998 57.8499 77.8598 52.9699 80.4398 47.4199H51.5698C54.1498 52.9799 58.0098 57.8599 62.5798 59.9299Z"
        fill="#00A9D1"
      />
      <path
        d="M82.8898 90.9596C80.8298 82.9096 75.8198 74.4896 69.4098 71.5896C67.6398 70.7896 66.3798 69.0996 65.9898 67.0996C65.5998 69.0996 64.3398 70.7896 62.5698 71.5896C56.1598 74.4896 51.1498 82.8996 49.0898 90.9596H82.8898Z"
        fill="#00A9D1"
      />
      <path
        d="M96.46 101.63H35.53C33.58 101.63 32.01 100.05 32.01 98.1098C32.01 96.1598 33.59 94.5898 35.53 94.5898H96.46C98.41 94.5898 99.98 96.1698 99.98 98.1098C99.99 100.05 98.41 101.63 96.46 101.63Z"
        fill="#324A5E"
      />
      <path d="M95.14 33.4102H90.75V94.5801H95.14V33.4102Z" fill="#A1AAB4" />
      <path d="M41.2499 33.4102H36.8599V94.5801H41.2499V33.4102Z" fill="#A1AAB4" />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="132"
          height="132"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.809983 0 0 0 0 0.886017 0 0 0 0 0.904167 0 0 0 0.8 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
