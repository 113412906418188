import React, { useEffect, useState, Dispatch, SetStateAction, useContext } from "react";
import { ReviewType, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import NurseReviewComponent from "../../ServiceRequest/ReviewSection/NurseReview/NurseReview";
import { OutOfNetworkReviewReadonly } from "../../ServiceRequest/ReviewSection/OutOfNetworkReview/OutOfNetworkReview";
import AuditReviewComponent from "../../ServiceRequest/ReviewSection/AuditReview/AuditReview";
import MDReviewComponent from "../../ServiceRequest/ReviewSection/MDReview/MDReview";
import PeerToPeerReviewComponent from "../../ServiceRequest/ReviewSection/MDReview/PeerToPeerReview";
import PeerToPeerOutreachBanner from "../../ServiceRequest/ReviewSection/PeerToPeerOutreachBanner";
import { makeStyles } from "@material-ui/core/styles";
import { ClinicalReviewContext, PeerToPeerOutreachReviewType } from "../Review/ClinicalReviewPage";
import { Grid } from "@material-ui/core";
import {
  getIsGHPPreDenialP2PUnder21,
  getIsMedicaidUnder21,
} from "components/ServiceRequest/ReviewSection/util/ReviewSectionUtil";

interface ListOfReviewsProps {
  reviews: (ReviewType | PeerToPeerOutreachReviewType)[];
  serviceRequest: ServiceRequestResponse;
  onFinishEditing?: () => void;
  clinicalReviewView?: boolean;
  willAutoApprove?: boolean;
  newDenialsWorkflow?: boolean;
  targetReviewId?: string | null;
  setTargetReview?: Dispatch<SetStateAction<HTMLElement | null>>;
  allReviews?: ReviewType[];
  buttonPadding?: string;
  authorizationReviewView?: boolean;
  reviewDetailsCollapseStyle?: React.CSSProperties;
  isDenialReviewPage?: boolean;
  currServiceRequestID?: string;
}

const ListOfReviews = ({
  reviews,
  serviceRequest,
  clinicalReviewView,
  onFinishEditing,
  willAutoApprove,
  newDenialsWorkflow,
  targetReviewId,
  setTargetReview,
  allReviews,
  isDenialReviewPage = false,
  currServiceRequestID,
  ...otherProps
}: ListOfReviewsProps) => {
  const [expansionByReviewId, setExpansionByReviewId] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const findEarliestMdP2PReview = (): string | null => {
      // filter to only MdReview and PeerToPeerReview
      const MdP2PReviews: (ReviewType | PeerToPeerOutreachReviewType)[] = reviews.filter((review) => {
        return (
          (review.reviewType === "MdReview" || review.reviewType === "PeerToPeerReview") &&
          review.serviceRequest !== undefined &&
          review.serviceRequest.id === currServiceRequestID
        );
      });

      if (MdP2PReviews.length === 0) {
        return null;
      }

      // find the most recent MdReview or PeerToPeerReview
      let { dateCreated: mostRecentDate, id: mostRecentReviewId } = MdP2PReviews[0];

      MdP2PReviews.forEach((review) => {
        if (
          (review.reviewType === "MdReview" || review.reviewType === "PeerToPeerReview") &&
          review.dateCreated > mostRecentDate
        ) {
          mostRecentDate = review.dateCreated;
          mostRecentReviewId = review.id;
        }
      });

      return mostRecentReviewId;
    };
    const mostRecentReviewId: string | null = findEarliestMdP2PReview();
    if (isDenialReviewPage && mostRecentReviewId != null) {
      setExpansionByReviewId((prevExpansion) => ({
        ...prevExpansion,
        [mostRecentReviewId]: true,
      }));
    }
  }, [isDenialReviewPage, reviews, serviceRequest.requestType, currServiceRequestID]);

  const classes = useStyles();
  const toggleExpanded = (review: ReviewType | PeerToPeerOutreachReviewType) => {
    if (!((window.getSelection()?.toString().length || 0) > 0)) {
      setExpansionByReviewId((prev) => ({ ...prev, [review.id]: !prev[review.id] }));
    }
  };
  const { patientPrimaryCoverage } = useContext(ClinicalReviewContext);
  const isUsingClinicalConversation =
    getIsGHPPreDenialP2PUnder21(serviceRequest) && getIsMedicaidUnder21(serviceRequest.patient, patientPrimaryCoverage);

  return (
    <Grid container>
      {reviews.map((review) => (
        <Grid item key={review.id} className={classes.reviewContainer}>
          {review.reviewType === "NurseReview" ? (
            <NurseReviewComponent
              nurseReview={review}
              serviceRequest={serviceRequest}
              expanded={expansionByReviewId[review.id]}
              toggleExpanded={() => toggleExpanded(review)}
              onFinishEditing={onFinishEditing}
              willAutoApprove={willAutoApprove}
              reviewRef={(newRef) => {
                if (!!targetReviewId) {
                  if (review.id === targetReviewId) {
                    setTargetReview?.(newRef);
                  }
                }
              }}
              clinicalReviewView={clinicalReviewView}
              expandableGuidelines
              {...otherProps}
            />
          ) : review.reviewType === "OutOfNetworkReview" ? (
            <OutOfNetworkReviewReadonly
              outOfNetworkReview={review}
              serviceRequest={serviceRequest}
              expanded={expansionByReviewId[review.id]}
              toggleExpanded={() => toggleExpanded(review)}
              reviewRef={(newRef) => {
                if (!!targetReviewId) {
                  if (review.id === targetReviewId) {
                    setTargetReview?.(newRef);
                  }
                }
              }}
              clinicalReviewView={clinicalReviewView}
              {...otherProps}
            />
          ) : review.reviewType === "MdReview" ? (
            <MDReviewComponent
              mdReview={review}
              serviceRequest={serviceRequest}
              expanded={expansionByReviewId[review.id]}
              toggleExpanded={() => toggleExpanded(review)}
              onFinishEditing={onFinishEditing}
              willAutoApprove={willAutoApprove}
              newDenialsWorkflow={newDenialsWorkflow}
              allReviews={allReviews}
              reviewRef={(newRef) => {
                if (!!targetReviewId) {
                  if (review.id === targetReviewId) {
                    setTargetReview?.(newRef);
                  }
                }
              }}
              clinicalReviewView={clinicalReviewView}
              expandableGuidelines
              {...otherProps}
            />
          ) : review.reviewType === "PeerToPeerReview" ? (
            <PeerToPeerReviewComponent
              p2pReview={review}
              serviceRequest={serviceRequest}
              expanded={expansionByReviewId[review.id]}
              toggleExpanded={() => toggleExpanded(review)}
              onFinishEditing={onFinishEditing}
              willAutoApprove={willAutoApprove}
              newDenialsWorkflow={newDenialsWorkflow}
              reviewRef={(newRef) => {
                if (!!targetReviewId) {
                  if (review.id === targetReviewId) {
                    setTargetReview?.(newRef);
                  }
                }
              }}
              clinicalReviewView={clinicalReviewView}
              expandableGuidelines
              isUsingClinicalConversation={isUsingClinicalConversation}
              {...otherProps}
            />
          ) : review.reviewType === "P2POutreach" ? (
            <PeerToPeerOutreachBanner
              dateCreated={review.dateCreated}
              attemptedTimestamp={review.attemptedTimestamp}
              outcome={review.outcome}
            />
          ) : review.reviewType === "AuditReview" ||
            review.reviewType === "NewHireAuditReview" ||
            review.reviewType === "OutOfScopeAuditReview" ? (
            <AuditReviewComponent
              auditReview={review}
              serviceRequest={serviceRequest}
              expanded={expansionByReviewId[review.id]}
              toggleExpanded={() => toggleExpanded(review)}
              onFinishEditing={onFinishEditing}
              reviewRef={(newRef) => {
                if (!!targetReviewId) {
                  if (review.id === targetReviewId) {
                    setTargetReview?.(newRef);
                  }
                }
              }}
              expandableGuidelines
              {...otherProps}
            />
          ) : null}
        </Grid>
      ))}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  reviewContainer: {
    width: "100%",
  },
}));

export default ListOfReviews;
