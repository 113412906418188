import React from "react";
import { Body1, Body2, Caption } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Button, styled } from "@material-ui/core";
import { OptionType } from "./ServiceRequestForm/components/FacilitySelectManual";
import { Address } from "@coherehealth/core-platform-api";

export function providerFacilityDropdownRender(
  name: string | undefined,
  tinList: string[] | undefined,
  npi: string | undefined,
  optionType: OptionType | undefined,
  setSelectedTin?: (tin: string | null) => void,
  hideTinField?: boolean,
  isNpiOptional?: boolean,
  disableManualOption?: boolean
) {
  if (optionType === "noResults") {
    return (
      <div
        onClick={() => {
          if (setSelectedTin) {
            setSelectedTin(null);
          }
        }}
      >
        <Body2Unbold>No results found</Body2Unbold>
        {!disableManualOption && (
          <LinkButton>
            <Body2Unbold color="primary">Enter information manually</Body2Unbold>
          </LinkButton>
        )}
      </div>
    );
  } else if (!disableManualOption && optionType === "someResults") {
    return (
      <div
        onClick={() => {
          if (setSelectedTin) {
            setSelectedTin(null);
          }
        }}
      >
        <Body2Unbold>Not finding what you are looking for?</Body2Unbold>
        <LinkButton>
          <Body2Unbold color="primary">Enter information manually</Body2Unbold>
        </LinkButton>
      </div>
    );
  } else {
    return (
      <div>
        <Body2 component="div">{name}</Body2>
        <Caption component="div" color="textSecondary">
          NPI - {npi || (isNpiOptional && "N/A")}&nbsp;&nbsp;{!hideTinField && "TIN - " + tinList?.join(", ")}
        </Caption>
      </div>
    );
  }
}

export function providerFacilityAddressRenderer(
  address: Address | undefined,
  optionType: OptionType | undefined,
  disableManualOption?: boolean
) {
  if (optionType === "noResults") {
    return (
      <div>
        <Body2Unbold>No results found</Body2Unbold>
        {!disableManualOption && (
          <LinkButton>
            <Body2Unbold color="primary">Enter information manually</Body2Unbold>
          </LinkButton>
        )}
      </div>
    );
  } else if (!disableManualOption && optionType === "someResults") {
    return (
      <div>
        <LinkButton>
          <Body2Unbold color="primary">Enter information manually</Body2Unbold>
        </LinkButton>
      </div>
    );
  } else {
    return (
      <div>
        <Body1 component="div">
          {address?.line1} {address?.line2}
        </Body1>
        <Caption component="div" color="textSecondary">
          {address?.city}, {address?.state} {address?.zipCode}
        </Caption>
      </div>
    );
  }
}

export function providerFacilityTinRenderer(
  id: string,
  val: string,
  optionType?: OptionType,
  disableManualOption?: boolean
) {
  if (optionType === "noResults" || optionType === "someResults") {
    return !disableManualOption ? (
      <LinkButton>
        <Body2Unbold color="primary">Enter information manually</Body2Unbold>
      </LinkButton>
    ) : (
      <Body2Unbold>No results found</Body2Unbold>
    );
  } else {
    return <Body1 component="div">{id}</Body1>;
  }
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Body2Unbold = styled(Body2)(({ theme }) => ({
  fontWeight: "normal",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const LinkButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  border: "none",
  display: "inline",
  margin: 0,
  padding: 0,
  "&:hover": {
    textDecoration: "none",
    backgroundColor: "transparent",
  },
  "&:focus": {
    textDecoration: "none",
  },
}));
