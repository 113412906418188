import React, { Dispatch, useEffect } from "react";

import { Patient } from "@coherehealth/core-platform-api";
import { makeStyles } from "@material-ui/core/styles";

import { CommonFaxSidebarViewProps } from "./common";
import FaxMetaDataDisplay from "./FaxMetaDataDisplay";
import Grid from "@material-ui/core/Grid";
import { H4, SecondaryButton, useGetServiceRequestByIdWithFallback } from "@coherehealth/common";
import Divider from "@material-ui/core/Divider";
import OCRDetectionCard from "./OCRDetectionCard";
import { first } from "lodash";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "inherit",
  },
  header: {
    padding: theme.spacing(2, 0, 1),
  },
  searchHeader: {
    marginBottom: theme.spacing(3),
  },
  cancelButton: {
    margin: theme.spacing(3, 0),
  },
  actionHeader: {
    paddingBottom: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
}));

interface Props extends CommonFaxSidebarViewProps {
  selectedPatient?: Patient;
  setSelectedPatient: Dispatch<Patient | undefined>;
  url?: string;
}

export default function OCRSidebar({ fileData, url, serviceCase, ...passthrough }: Props) {
  const classes = useStyles();
  const serviceRequestId = first(fileData.attachedTo)?.id;

  const { data: serviceRequest, refetch: getServiceRequest } = useGetServiceRequestByIdWithFallback({
    id: serviceRequestId || "",
    lazy: true,
  });

  useEffect(() => {
    if (serviceRequestId) {
      getServiceRequest();
    }
  }, [getServiceRequest, serviceRequestId]);

  useEffect(() => {
    if (!passthrough.selectedPatient) {
      if (serviceRequest && serviceRequest.patient) {
        passthrough.setSelectedPatient(serviceRequest.patient);
      }
    }
    // Only want to this to depend on the service request
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceRequest]);

  return (
    <div className={classes.container}>
      <FaxMetaDataDisplay fileData={fileData} url={url} serviceCase={serviceCase} />
      <Divider className={classes.divider} />
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <OCRDetectionCard fileData={fileData} {...passthrough} />
        </Grid>
        <Grid item xs={12}>
          <H4 color="textSecondary" className={classes.actionHeader}>
            Are you unable to process this fax?
          </H4>
          <SecondaryButton fullWidth onClick={() => passthrough.setSidebarView("CREATE_FAX_NOTICE")}>
            Create fax notice
          </SecondaryButton>
        </Grid>
      </Grid>
    </div>
  );
}
