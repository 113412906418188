import React, { useEffect, useState } from "react";
import { Typography, Grid, Card } from "@mui/material";
import { Grid as MainContent } from "@material-ui/core";
import {
  DATE_FORMAT,
  DateTextField,
  formatDateToISODate,
  NotFoundPage,
  parseDateFromISOStringWithoutFallback,
  PrimaryButton,
  Subtitle2,
  TextField,
  useFeature,
} from "@coherehealth/common";
import { Patient } from "@coherehealth/core-platform-api";
import parse from "date-fns/parse";
import { error as logError } from "../../logger";
import isWithinInterval from "date-fns/isWithinInterval";
import PatientManagementSearchResult from "./PatientManagementSearchResult";
import HeaderContainer from "components/AppHeader/HeaderContainer";
import routes from "routes";
import { generatePath } from "react-router";
import { HeaderTextContainer, Row, SearchInputContainer, usePatientManagementStyles } from "./patientManagementStyles";
import { useAuthorized } from "authorization";
import { useFFPatientSearch } from "components/ReferralManagement/common/PatientSearchUtils";

const isDobInValidRange = (dateOfBirth: string) => {
  const date = parseDateFromISOStringWithoutFallback(dateOfBirth);
  return date && isWithinInterval(date, { start: new Date(1900, 1, 1), end: new Date(2100, 1, 1) });
};

export default function PatientManagementPage() {
  const canViewPatientManagementPage = useAuthorized("PATIENT_MANAGEMENT_PAGE");
  const [showResults, setShowResults] = useState<boolean>(false);
  const [patients, setPatients] = useState<Patient[]>([]);
  const [memberId, setMemberId] = useState<string>("");
  const [dobStr, setDobStr] = useState<string>("");
  const [memberLastName, setMemberLastName] = useState<string>("");
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const patientManagementPageStyles = usePatientManagementStyles({});
  let searchEnabled = false;
  // This validates the dobStr, if it is an invalid date this value will be an empty string
  const dateOfBirth = formatDateToISODate(parse(dobStr, DATE_FORMAT, new Date()));
  const validDateOfBirth = Boolean(dateOfBirth) && attemptedSubmit && !isDobInValidRange(dateOfBirth);
  const unifyPatientSearch = useFeature("unifyPatientSearch");

  const {
    mutate: patientSearch,
    loading: patientSearchLoading,
    error: patientSearchError,
  } = useFFPatientSearch(unifyPatientSearch, {});

  useEffect(() => {
    if (patientSearchError) {
      if (patientSearchError.message !== "Failed to fetch: Aborted") {
        logError(patientSearchError);
      }
    }
  }, [patientSearchError]);

  if (!canViewPatientManagementPage) {
    return <NotFoundPage />;
  }

  const searchPatients = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    setAttemptedSubmit(true);
    let patientSearchResults: Patient[] = [];

    // Validate if the search parameter matches the 2 out 3 specs
    if (searchEnabled) {
      patientSearchResults = await patientSearch({ memberId, dateOfBirth, lastName: memberLastName });
    }

    setPatients(patientSearchResults);
    setShowResults(true);
  };

  if (Boolean(memberId) && isDobInValidRange(dateOfBirth)) {
    searchEnabled = true;
  } else if (Boolean(memberLastName) && isDobInValidRange(dateOfBirth)) {
    searchEnabled = true;
  } else if (Boolean(memberLastName) && Boolean(memberId)) {
    searchEnabled = true;
  } else {
    searchEnabled = false;
  }

  return (
    <>
      <HeaderContainer height={96}>
        <HeaderTextContainer>
          <Typography variant="h3" align="center" textAlign="center">
            Patient Search
          </Typography>
        </HeaderTextContainer>
      </HeaderContainer>
      <MainContent className={patientManagementPageStyles.pageContainer}>
        <Card className={patientManagementPageStyles.searchInputCard}>
          <Grid
            container
            item
            xs={12}
            justifyContent="space-evenly"
            rowGap={3}
            component="form"
            onSubmit={searchPatients}
          >
            <Subtitle2>2 out of 3 fields are required</Subtitle2>

            <SearchInputContainer>
              <TextField fullWidth label="Health plan member ID" value={memberId} onChangeValue={setMemberId} />
            </SearchInputContainer>
            <SearchInputContainer>
              <DateTextField
                error={validDateOfBirth}
                helperText={validDateOfBirth ? "Must have a valid date" : ""}
                fullWidth
                label={`Member date of birth`}
                value={dobStr}
                onChangeValue={setDobStr}
              />
            </SearchInputContainer>
            <SearchInputContainer>
              <TextField fullWidth label="Member last name" value={memberLastName} onChangeValue={setMemberLastName} />
            </SearchInputContainer>
            <Row>
              <PrimaryButton
                loading={patientSearchLoading}
                disabled={!searchEnabled || patientSearchLoading}
                type="submit"
                className={patientManagementPageStyles.searchButton}
              >
                Search
              </PrimaryButton>
            </Row>
          </Grid>
        </Card>
        {showResults && !patientSearchLoading ? (
          <Grid className={patientManagementPageStyles.resultsWrapper}>
            <PatientManagementSearchResult
              patients={patients}
              error={patientSearchError}
              showSummaryLink
              selectOnClick={(patient: Patient) => {
                window.location.assign(
                  generatePath(routes.PATIENT_MANAGEMENT_EDIT_PATIENT, { patientId: patient?.id })
                );
              }}
            />
          </Grid>
        ) : null}
      </MainContent>
    </>
  );
}
