// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Container, Grid, styled } from "@material-ui/core";
import RequestorCard from "../../Requestor/RequestorCard";
import { AuthBuilderRequestorProps } from "../index";
import React, { Dispatch, SetStateAction } from "react";
import DiagnosisCodesSection from "../GatherRequirements/DiagnosisCodesSection";
import { PriorAuthRequirements } from "../common";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import DrugSelect from "./DrugSelect";
import Box from "@material-ui/core/Box";
import { H6, useMuiContainerStyles } from "@coherehealth/common";
import { useTheme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { PharmacyExpedited } from "../../ServiceRequest/ServiceRequestForm/Expedited";
import { Patient } from "@coherehealth/core-platform-api";
import { OrderingProviderSelectManual } from "components/ServiceRequest/ServiceRequestForm/components/ProviderSelectManual";

interface Props extends AuthBuilderRequestorProps {
  priorAuthRequirements: PriorAuthRequirements;
  setPriorAuthRequirements: (reqs: PriorAuthRequirements) => void;
  attemptedGatherRequirementsSubmit: boolean;
  serviceRequestFormContents: ServiceRequestFormContent[];
  setServiceRequestFormContents: Dispatch<SetStateAction<ServiceRequestFormContent[]>>;
  patient: Patient | null;
}
export default function GatherRequirementsPharmacyContainer({
  attemptedGatherRequirementsSubmit,
  priorAuthRequirements,
  setPriorAuthRequirements,
  serviceRequestFormContents,
  setServiceRequestFormContents,
  patient,
  ...requestorProps
}: Props) {
  const containerClasses = useMuiContainerStyles();
  const theme = useTheme();

  const formContent = serviceRequestFormContents[0];
  return (
    <FormBackground>
      <Container classes={containerClasses} maxWidth="lg">
        <Grid container spacing={5}>
          {requestorProps.requestorFormAuthorized && (
            /* negate marginBottom in RequestorCard with negative value here */
            <Grid item xs={12} style={{ marginBottom: -40 }}>
              <RequestorCard {...requestorProps} />
            </Grid>
          )}
        </Grid>

        <DrugSelect
          setDrugSelection={(drugSelection) =>
            setServiceRequestFormContents([{ ...serviceRequestFormContents[0], drugSelection }])
          }
          drugSelection={serviceRequestFormContents[0]?.drugSelection || { drug: null }}
        />

        <Box height={theme.spacing(5)} />
        <DiagnosisCodesSection
          attemptedSubmit={attemptedGatherRequirementsSubmit}
          priorAuthRequirements={priorAuthRequirements}
          resetPalResults={() => {}}
          setPriorAuthRequirements={(par) => {
            setPriorAuthRequirements(par);
            setServiceRequestFormContents((prev) => {
              return [
                {
                  ...prev[0],
                  primaryDiagnosisCode: par.primaryDiagnosis || null,
                  secondaryDiagnosisCodes: par.secondaryDiagnoses || [],
                },
              ];
            });
          }}
        />

        <H6 style={{ margin: theme.spacing(5, 0, 2, 0) }}>Add provider details</H6>
        <OrderingProviderSelectManual
          error={false}
          formContent={serviceRequestFormContents[0]}
          setFormContent={(
            x: ServiceRequestFormContent | ((prev: ServiceRequestFormContent) => ServiceRequestFormContent)
          ) => {
            if (typeof x === "function") {
              setServiceRequestFormContents((prevState) => [x(prevState[0])]);
            } else {
              setServiceRequestFormContents([x]);
            }
          }}
          sameProviders={false}
          dispatchRedirectRuleCheck={() => Promise.resolve()}
          setSameProviders={() => {}}
        />
        <Box height={"40px"} />
        <Divider />
        <Box height={"32px"} />
        <PharmacyExpedited
          isExpedited={formContent?.isExpedited || false}
          setIsExpedited={(isExpedited) => {
            setServiceRequestFormContents([{ ...serviceRequestFormContents[0], isExpedited }]);
          }}
        />
      </Container>
    </FormBackground>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const FormBackground = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  width: "100%",
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(5, 0),
}));
