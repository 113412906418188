import React from "react";
import { Chip } from "@coherehealth/common";
import { OrganizationStatus } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";

interface ChipAlertProps {
  orgStatus?: OrganizationStatus;
}

const OrgStatusChip = ({ orgStatus }: ChipAlertProps) => {
  if (orgStatus === "ACTIVE") {
    return <StyledChip type="success" label={"Active"} />;
  }
  if (orgStatus === "PENDING") {
    return <StyledChip type="warning" label={"Pending"} />;
  }
  return null;
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledChip = styled(Chip)(({ theme }) => ({ padding: theme.spacing(0, 3) }));

export default OrgStatusChip;
