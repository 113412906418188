import { Body1, formatDateStr, TableWithPanels, TableWithPanelsColumn } from "@coherehealth/common";
import { Coverage } from "@coherehealth/core-platform-api";

interface Props {
  coverages: Coverage[];
}

export default function CoveragesTable({ coverages }: Props) {
  return (
    <>
      <Body1>Coordination of Benefits</Body1>
      <TableWithPanels columns={coverageTableColumns} panels={coverages} noSpacing />
    </>
  );
}

const coverageTableColumns: TableWithPanelsColumn<Coverage>[] = [
  {
    columnName: "Plan name",
    value: (coverage) => coverage.healthPlanName,
  },
  {
    columnName: "Plan type",
    value: (coverage) =>
      coverage?.coverageProductType ? coverage?.coverageProductType : coverage?.productType || " - ",
  },
  {
    columnName: "Membership type",
    value: (coverage) =>
      coverage?.coverageLineOfBusinessType ? coverage?.coverageLineOfBusinessType : coverage?.lineOfBusinessType,
  },
  {
    columnName: "Member ID",
    value: (coverage) => coverage.memberId,
  },
  {
    columnName: "Plan year",
    value: (coverage) => formatDateStr(coverage?.planStart) + " - " + formatDateStr(coverage?.planEnd),
  },
];
