import {
  DenialsInfo,
  DenialsTemplate,
  NurseReview,
  ReviewType,
  ServiceRequestResponse,
  useUpdateMdReview,
  useUpdateNurseReview,
  useUpdatePeerToPeerReview,
} from "@coherehealth/core-platform-api";
import { useCallback } from "react";

export function usePatchDenialsLetterTemples(
  serviceRequest?: ServiceRequestResponse | null,
  review?: ReviewType | null,
  selectedTemplate?: DenialsTemplate | null
) {
  const { mutate: updateMd } = useUpdateMdReview({
    reviewId: review?.id || "",
    serviceRequestId: serviceRequest?.id || "",
    queryParams: { denialsWorkflow: true },
  });

  const { mutate: updatePeerToPeer } = useUpdatePeerToPeerReview({
    reviewId: review?.id || "",
    serviceRequestId: serviceRequest?.id || "",
    queryParams: { denialsWorkflow: true },
  });

  const { mutate: updateNurse } = useUpdateNurseReview({
    reviewId: review?.id || "",
    serviceRequestId: serviceRequest?.id || "",
    queryParams: { denialsWorkflow: true },
  });

  const mapToDenialInfo = (selectedTemplate?: DenialsTemplate | null): DenialsInfo => {
    return {
      denialsTemplateId: selectedTemplate?.id || "",
      formattedDenialsLanguage: selectedTemplate?.denialLanguage || "",
      templateTitle: selectedTemplate?.templateTitle || "",
    } as DenialsInfo;
  };

  const patchDenialsTemplate = useCallback(async () => {
    const denialsTemplate = mapToDenialInfo(selectedTemplate);
    if (denialsTemplate) {
      if (review?.reviewType === "MdReview") {
        await updateMd({
          ...review,
          denialsTemplate,
          lastUpdated: new Date().toISOString(),
        });
      } else if (review?.reviewType === "PeerToPeerReview") {
        await updatePeerToPeer({
          ...review,
          denialsTemplate,
          lastUpdated: new Date().toISOString(),
        });
      } else {
        await updateNurse({
          ...(review as NurseReview),
          denialsTemplate,
          lastUpdated: new Date().toISOString(),
        });
      }
    }
  }, [selectedTemplate, review, updateMd, updatePeerToPeer, updateNurse]);

  return { patchDenialsTemplate };
}
