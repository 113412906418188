import { useCallback } from "react";
import { generatePath, useSearchParams } from "react-router-dom";
import routes from "../routes";

export const CREATE_NURSE_REVIEW_PARAM = "createNewNurseReview";
export const CREATE_OUT_OF_NETWORK_REVIEW_PARAM = "createNewOutOfNetworkReview";
export const CREATE_AUDIT_REVIEW_PARAM = "createNewAuditReview";
export const CREATE_MD_REVIEW_PARAM = "createNewMdReview";
export const CREATE_P2P_REVIEW_PARAM = "createNewPeerToPeerReview";
export const CREATE_P2P_POST_DECISION_REVIEW_PARAM = "createNewPeerToPeerReview&isPostDenialP2P";
export const CREATE_NEW_HIRE_AUDIT_PARAM = "createNewHireAuditReview";
export const CREATE_CPP_REVIEW_PARAM = "createNewCPPReview";
export const CREATE_DX_OOS_REVIEW_PARAM = "createNewOOSReview";
export const CREATE_FAX_AUDIT_REVIEW_PARAM = "createNewFaxAuditReview";

export const APPEAL_LETTER_PARAM = "appealLetter";

export const WORKFLOW_REFERER_PARAM = "workflowReferer";
export const FAX_INTAKE = "faxId";
export const CASE_FAX_INTAKE = "caseId";
export const QUEUE_FAX_INTAKE = "queueId";
export function useGetReturnToUrl() {
  const [searchParams] = useSearchParams();
  const faxId = searchParams.get(FAX_INTAKE);
  const caseId = searchParams.get(CASE_FAX_INTAKE) ?? undefined;
  const queueId = searchParams.get(QUEUE_FAX_INTAKE) ?? undefined;
  //only caseId and queueId are passed as search parameters to fax intake main page
  const getOtherFaxParametersUrl = useCallback(
    () => getParametersFaxIntake(undefined, undefined, caseId, queueId),
    [caseId, queueId]
  );
  if (faxId) {
    return generatePath(routes.FAX, {
      faxId: searchParams.get(FAX_INTAKE),
    }).concat(`?${getOtherFaxParametersUrl()}`);
  } else {
    return searchParams.get(WORKFLOW_REFERER_PARAM) || routes.DASHBOARD;
  }
}

/** gets Fax related parameters as URLSearchParams string if on Fax intake workflow */
export function getParametersFaxIntake(
  previousSearchString?: string,
  faxId?: string,
  caseId?: string,
  queueId?: string
) {
  const searchParams = !!previousSearchString ? new URLSearchParams(previousSearchString) : new URLSearchParams();

  if (!!faxId) {
    searchParams.append(FAX_INTAKE, faxId);
  }
  if (!!caseId) {
    searchParams.append(CASE_FAX_INTAKE, caseId);
  }
  if (!!queueId) {
    searchParams.append(QUEUE_FAX_INTAKE, queueId);
  }

  return searchParams.toString();
}

// Helper function to create path with optional authBuilderStep
export function usePathWithAuthBuilderStep(workflowStep: string) {
  const [searchParams] = useSearchParams();

  const path = useCallback(() => {
    const params = new URLSearchParams(searchParams);

    params.set("authBuilderStep", workflowStep);

    const searchPart = params.toString();
    return `?${searchPart}`;
  }, [searchParams, workflowStep]);
  return path();
}

/**
 * Full path, including the pathname, search string, and fragment - just not the protocol and domain part , removing faxStep as well
 */
export function useFullLocationPath() {
  const { pathname, search } = window.location;

  const searchParams = new URLSearchParams(search);
  if (searchParams.has("faxStep")) {
    searchParams.delete("faxStep");
  }
  const modifiedSearch = searchParams.toString();
  const searchPart = modifiedSearch ? `?${modifiedSearch}` : "";

  // Construct the full path without the origin and the hash
  const fullPath = `${pathname}${searchPart}`;
  return fullPath;
}

/**
 * to set param step
 */
export function setParams(step: string, search: string, workflowStep: string) {
  const params = new URLSearchParams(search);
  params.set(step, workflowStep);
  return params.toString();
}
