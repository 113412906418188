import { useEffect, useState, Dispatch, SetStateAction, MutableRefObject } from "react";
import { Attachment, useGetFaxAttachmentDownload } from "@coherehealth/core-platform-api";
import { ServiceCase } from "@coherehealth/qm-api";
import { useBinaryFileFromResponse, colorsLight, H4, UserClickInfoClinicalReviewPage } from "@coherehealth/common";
import AttachmentViewer from "./AttachmentViewer";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CenteredTextContainer } from "./AttachmentViewerShell";
import { useSnackbar } from "notistack";
import { useAuthorized } from "authorization";

interface Props {
  serviceCase: ServiceCase;
  userClickInfoTracking?: UserClickInfoClinicalReviewPage;
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
  attachmentIndexOpen: number;
  currentPage: MutableRefObject<number>;
  zoomLevel: MutableRefObject<number>;
  currentRotate: MutableRefObject<number>;
  onViewAttachment: (index: number, landingPage?: number) => void;
  attachments?: Attachment[];
  attachmentsLoading: boolean | undefined;
  id?: string;
}

export default function ServiceCaseAttachmentViewerShell({
  serviceCase,
  setUserClickInfoTracking,
  attachmentIndexOpen,
  currentPage,
  zoomLevel,
  currentRotate,
  onViewAttachment,
  attachments,
  attachmentsLoading,
  id = "tabpanel-ATTACHMENTS",
}: Props) {
  const [openSidePanel, setOpenSidePanel] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const canViewNurseReviewerCase = useAuthorized("VIEW_NURSE_REVIEWER_CASE");

  const {
    data: faxDownload,
    loading: faxDownloadLoading,
    error: faxDownloadError,
  } = useGetFaxAttachmentDownload({
    id: serviceCase?.faxDetail?.attachmentId || "",
  });

  useEffect(() => {
    if (faxDownloadError && !canViewNurseReviewerCase) {
      enqueueSnackbar(`Error downloading fax: ${faxDownloadError.message}`, { variant: "error" });
    }
  }, [faxDownloadError, enqueueSnackbar, canViewNurseReviewerCase]);

  const { fileUrl } = useBinaryFileFromResponse(faxDownload);

  return (
    <div id={id} data-testid={id} style={{ width: "100%", height: "100%", margin: "-1px" }}>
      {faxDownloadLoading && attachmentsLoading && <CircularProgress />}
      {attachments && attachments.length > 0 ? (
        attachmentIndexOpen !== -1 ? (
          <>
            <AttachmentViewer
              fileUrl={fileUrl}
              file={attachments[attachmentIndexOpen]}
              attachments={attachments}
              handleAttachmentClick={onViewAttachment}
              attachmentIndexOpen={attachmentIndexOpen}
              convertedToPDF={false}
              currentPage={currentPage}
              zoomLevel={zoomLevel}
              currentRotate={currentRotate}
              openSidePanel={openSidePanel}
              setOpenSidePanel={setOpenSidePanel}
              setUserClickInfoTracking={setUserClickInfoTracking}
            />
          </>
        ) : null
      ) : (
        <CenteredTextContainer>
          <H4 style={{ color: colorsLight.font.light }}>There are no attachments for this service request.</H4>
        </CenteredTextContainer>
      )}
    </div>
  );
}
