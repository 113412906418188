import React, { useEffect, useState } from "react";

export default function TestErrorPage() {
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => {
      setIsError(true);
    }, 1000);
  }, []);
  const throwError = () => {
    throw new Error("Woopsie daisie");
  };
  return <>{isError && throwError()}Hi</>;
}
