import React, { SVGProps } from "react";

export default function EnvironmentallyFriendlyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="104" height="104" rx="52" fill="#ECF9F3" />
      <g clipPath="url(#clip0)">
        <path
          d="M53.9306 57.793H51.9996C51.9996 52.4606 56.3223 48.1379 61.6547 48.1379H63.5857C63.5857 53.4703 59.263 57.793 53.9306 57.793Z"
          fill="#6A9923"
        />
        <path
          d="M53.9306 44.2759H51.9996C51.9996 38.9435 56.3223 34.6208 61.6547 34.6208H63.5857C63.5857 39.9532 59.263 44.2759 53.9306 44.2759Z"
          fill="#6A9923"
        />
        <path
          d="M51.9996 30.7589H53.9306C57.6633 30.7589 60.6892 27.733 60.6892 24.0004H58.7582C55.0255 24.0004 51.9996 27.0263 51.9996 30.7589Z"
          fill="#6A9923"
        />
        <path
          d="M40.4725 60.4578L40.4136 60.424L33.6425 52.9008C32.6378 51.7878 30.921 51.7001 29.8079 52.7048C29.8029 52.7093 29.798 52.7139 29.793 52.7183V38.647C29.8277 37.0642 28.629 35.7258 27.052 35.5864C25.4545 35.5006 24.09 36.7259 24.0042 38.3232C24.0013 38.3763 24 38.4297 24 38.4829V56.757C23.9996 58.0697 24.3343 59.3607 24.9723 60.508L33.655 76.1376V79.9996H47.1721V72.0101C47.1718 67.2366 44.6155 62.8288 40.4725 60.4578Z"
          fill="#F2BB80"
        />
        <path
          d="M51.9997 67.4481C51.4665 67.4481 51.0342 67.0158 51.0342 66.4826V29.7934C51.0342 29.2602 51.4665 28.8279 51.9997 28.8279C52.5329 28.8279 52.9652 29.2602 52.9652 29.7934V66.4826C52.9652 67.0158 52.5329 67.4481 51.9997 67.4481Z"
          fill="#88B337"
        />
        <path
          d="M40.4136 48.1379H42.3446C47.6769 48.1379 51.9996 52.4606 51.9996 57.793H50.0686C44.7363 57.793 40.4136 53.4703 40.4136 48.1379Z"
          fill="#88B337"
        />
        <path
          d="M40.4136 34.6208H42.3446C47.6769 34.6208 51.9996 38.9435 51.9996 44.2759H50.0686C44.7363 44.2759 40.4136 39.9532 40.4136 34.6208Z"
          fill="#88B337"
        />
        <path
          d="M51.9996 30.7589H50.0686C46.336 30.7589 43.3101 27.733 43.3101 24.0004H45.2411C48.9737 24.0004 51.9996 27.0263 51.9996 30.7589Z"
          fill="#88B337"
        />
        <path
          d="M37.4456 66.2171C37.1735 66.2172 36.9142 66.1026 36.7311 65.9014L28.8207 57.2003C27.495 55.7451 27.5482 53.5047 28.9414 52.114C29.3249 51.7435 29.9361 51.7541 30.3066 52.1376C30.6681 52.5118 30.6681 53.1051 30.3066 53.4792C29.6434 54.1418 29.6183 55.2087 30.2497 55.9017L38.1591 64.6018C38.518 64.9962 38.4892 65.6069 38.0948 65.9657C37.9171 66.1273 37.6857 66.217 37.4456 66.2171Z"
          fill="#E8A16D"
        />
        <path
          d="M56.8271 72.0101V79.9996H70.3442V76.1376L79.027 60.508C79.6649 59.3607 79.9996 58.0697 79.9993 56.757V38.647C80.0339 37.0642 78.8352 35.7258 77.2582 35.5864C75.6608 35.5006 74.2963 36.7259 74.2104 38.3232C74.2076 38.3763 74.2062 38.4297 74.2062 38.4829V52.7183C73.1007 51.7054 71.3833 51.7803 70.3703 52.886C70.3657 52.8909 70.3612 52.8958 70.3568 52.9008L63.5857 60.424L63.5268 60.4578C59.3838 62.8288 56.8275 67.2366 56.8271 72.0101Z"
          fill="#F2BB80"
        />
        <path
          d="M66.5537 66.2171C66.8258 66.2172 67.0851 66.1026 67.2682 65.9014L75.1786 57.2003C76.5043 55.7451 76.4511 53.5047 75.0579 52.114C74.6743 51.7435 74.0632 51.7541 73.6927 52.1376C73.3312 52.5118 73.3312 53.1051 73.6927 53.4792C74.3558 54.1418 74.3809 55.2087 73.7496 55.9017L65.8402 64.6018C65.4813 64.9962 65.5101 65.6069 65.9045 65.9657C66.0822 66.1273 66.3136 66.217 66.5537 66.2171Z"
          fill="#E8A16D"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="56" height="56" fill="white" transform="translate(24 24)" />
        </clipPath>
      </defs>
    </svg>
  );
}
