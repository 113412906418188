import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const PushPinIcon: typeof SvgIcon = function (props: SvgIconProps) {
  return (
    <SvgIcon viewBox="-5 -2 24 24" {...props}>
      <path
        d="M11 7V2H12C12.55 2 13 1.55 13 1C13 0.45 12.55 0 12 0H2C1.45 0 1 0.45 1 1C1 1.55 1.45 2 2 2H3V7C3 8.66 1.66 10 0 10V12H5.97V19L6.97 20L7.97 19V12H14V10C12.34 10 11 8.66 11 7Z"
        fill="#5E6572"
      />
    </SvgIcon>
  );
};
export default PushPinIcon;
