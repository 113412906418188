import { Caption, Body1 } from "@coherehealth/common";

interface Props {
  label: string;
  data: string;
}

export default function LabelDataPair({ label, data }: Props) {
  return (
    <>
      <Caption>{label}</Caption>
      <Body1>{data}</Body1>
    </>
  );
}
