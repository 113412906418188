import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { themeDark, themeLight } from "../../themes";
import { SnackbarProvider } from "../../components";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider } from "@material-ui/pickers";

// N.B. side effect of importing this is to load the font css
// amacleay note: unsure if this eslint-disable is necessary
// eslint-disable-next-line no-restricted-imports
import "@coherehealth/common/assets/themeFont.css";

export interface CommonProviderProps {
  /** Common theme to use. Defaults to "light" */
  theme?: "light" | "dark";
  children: React.ReactNode;
}

/** Common providers and context setup required for common components to work correctly. */
const CommonProvider = ({ theme, children }: CommonProviderProps) => {
  const themeToUse = theme === "dark" ? themeDark : themeLight;
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={themeToUse}>
        <SnackbarProvider theme={themeToUse}>
          <LocalizationProvider dateAdapter={DateFnsAdapter}>
            {/* Use a react fragment to avoid invalid proptype error for the children*/}
            <>{children}</>
          </LocalizationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
};

export default CommonProvider;
