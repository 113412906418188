import React, { useState } from "react";
import MuiChipInput, { ChipRendererArgs, Props as MuiChipInputProps } from "material-ui-chip-input";
import { Chip, colorsLight, useTextFieldLabelStyles } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core";

interface IProps {
  chipList: string[];
  onAdd: (value: string) => void;
  handleDeleteChip: (value: string) => void;
  shouldAutoAdd?: (val: string, userPressedEnter: boolean) => boolean;
  valueFormatter?: (val: string) => string;
  chipRenderer?: (props: ChipRendererArgs, key: any) => React.ReactNode;
}

const useChipInputStyles = makeStyles((theme) => ({
  root: {
    "& .MuiChip-label": {
      color: colorsLight.primary.dark,
    },
    "& .MuiChip-root": {
      marginRight: theme.spacing(1),
    },
    "& .MuiFilledInput-root": {
      backgroundColor: colorsLight.background.input,
      borderRadius: 4,
      border: `1px solid ${theme.palette.divider}`,
      paddingBottom: ({ hasChips }: { hasChips: boolean }) => (hasChips ? 10 : 0),
      "&.Mui-error": {
        border: `1px solid ${theme.palette.error.dark}`,
      },
      "&:hover": {
        backgroundColor: colorsLight.background.input,
        borderColor: theme.palette.primary.main,
      },
    },
    "& .MuiFilledInput-underline:before": {
      display: "none",
    },
    "& .MuiFilledInput-underline:before, .MuiFilledInput-underline:after": {
      display: "none",
    },
  },
  helperText: {
    padding: theme.spacing(1, 0, 2),
    marginLeft: 0,
    "&.Mui-error": {
      color: `${theme.palette.error.dark} !important`,
    },
  },
  chip: {
    paddingBottom: theme.spacing(1),
  },
  input: {
    "input&": {
      marginBottom: theme.spacing(-1),
    },
  },
}));

export default function ChipInput({
  allowDuplicates,
  chipList,
  shouldAutoAdd,
  onAdd,
  label,
  handleDeleteChip,
  valueFormatter = (val: string) => val,
  chipRenderer,
  ...props
}: IProps & Omit<MuiChipInputProps, "variant" | "values">) {
  const classes = useChipInputStyles({ hasChips: chipList.length > 0 });
  const chipInputLabelClasses = useTextFieldLabelStyles({});
  const [chipInputValue, setChipInputValue] = useState("");
  return (
    <MuiChipInput
      {...props}
      chipRenderer={(chipProps, key) =>
        chipRenderer ? (
          chipRenderer(chipProps, key)
        ) : (
          <Chip key={key} label={valueFormatter(chipProps.value)} onDelete={() => handleDeleteChip(chipProps.value)} />
        )
      }
      value={chipList}
      allowDuplicates={allowDuplicates}
      label={label}
      disableUnderline
      fullWidth
      InputLabelProps={label ? { classes: chipInputLabelClasses } : {}}
      variant="filled"
      classes={classes}
      onAdd={onAdd}
      onBeforeAdd={(value: string) => {
        if (shouldAutoAdd && !shouldAutoAdd(value, true)) {
          return false;
        }
        return true;
      }}
      inputValue={chipInputValue}
      onUpdateInput={(inputChangeEvt) => {
        const inputVal = inputChangeEvt.target?.value || "";
        if (shouldAutoAdd && shouldAutoAdd(inputVal, false)) {
          setChipInputValue("");
          if (!allowDuplicates && chipList.indexOf(inputVal) < 0) {
            onAdd(inputVal);
          }
        } else {
          setChipInputValue(inputVal);
        }
      }}
    />
  );
}
