import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { ServiceCase } from "@coherehealth/qm-api";
import { useClinicalReviewNext } from "components/ClinicalReview/next/clinical-review-store";
import {
  Body2,
  H5,
  formatDateStr,
  patientDisplayName,
  formatDateStrWithTime,
  colorsLight,
  useConfiguration,
} from "@coherehealth/common";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import routes from "routes";
import CohereLogo from "components/images/CohereLogo.svg";
import { MyAccountButton, DropdownMenuButtonContainer } from "components/AppHeader/AppHeader";
import { EnvironmentIndicator, environmentAbbreviation, shouldDisplayEnvironmentIndicator } from "@coherehealth/common";
import { isFacilityBasedSR } from "util/serviceRequest";
import parsePhoneNumber from "libphonenumber-js";

interface Props {
  hideEnvIndicator?: boolean;
  viewOnly?: boolean;
}

export default function SlimHeader({ hideEnvIndicator, viewOnly }: Props) {
  const { state } = useClinicalReviewNext();
  const { serviceRequest, serviceCase } = state;
  const facilityBasedRequestConfiguration = useConfiguration(
    "facilityBasedRequestConfiguration",
    serviceRequest?.healthPlanName
  );
  const isInpatient = serviceRequest?.encounterType === "INPATIENT";
  const facilityBasedFeatureEnabled = facilityBasedRequestConfiguration?.enabled && isInpatient;
  const env = environmentAbbreviation();
  const displayEnvIndicator = !hideEnvIndicator && shouldDisplayEnvironmentIndicator(env);

  return (
    <>
      {displayEnvIndicator && <EnvironmentIndicator env={env} />}
      <div style={relativeContainerStyle}>
        <div style={logoContainerStyle}>
          <Button to={routes.DASHBOARD} component={Link} aria-label="Cohere Health home" style={bannerIconStyle}>
            <img src={CohereLogo} alt="" />
          </Button>
        </div>
        <div style={trackingContainerStyle}>
          {serviceCase?.caseType === "FAX_AUDIT_REVIEW" && serviceCase?.faxDetail?.faxNumber ? (
            <Body2 style={{ color: colorsLight.font.light, fontFamily: "Gilroy-SemiBold" }}>
              {"Fax from " +
                parsePhoneNumber(serviceCase?.faxDetail?.faxNumber, "US")?.formatNational() +
                " \u2022 Added " +
                formatDateStrWithTime(serviceCase.dateCreated)}
            </Body2>
          ) : (
            <Body2 style={trackingTextStyle}>
              {headerSubtext(serviceRequest, viewOnly, facilityBasedFeatureEnabled)}
            </Body2>
          )}

          <div style={serviceRequestTextWrapperStyle}>
            <span style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
              <H5 style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {serviceCase?.caseType === "FAX_AUDIT_REVIEW"
                  ? faxAuditHeaderText(serviceCase)
                  : serviceRequest
                  ? (serviceRequest.patient && patientDisplayName(serviceRequest.patient)) +
                    " \u2022 " +
                    formatDateStr(serviceRequest?.patient?.dateOfBirth)
                  : "No service request created"}
              </H5>
            </span>
          </div>
        </div>
        <div style={myAccountContainerStyle}>
          <DropdownMenuButtonContainer>
            <MyAccountButton />
          </DropdownMenuButtonContainer>
        </div>
      </div>
    </>
  );
}

const headerSubtext = (
  serviceRequest?: ServiceRequestResponse | null,
  viewOnly?: boolean,
  canUseFacilityBasedWorkflow?: boolean
) => {
  const isFacilityBasedContinuation = serviceRequest ? isFacilityBasedSR(serviceRequest) : false;
  const useFacilityBasedContinuationLanguage = isFacilityBasedContinuation && canUseFacilityBasedWorkflow;

  const verb = viewOnly ? `Viewing ` : `Reviewing `;
  const request =
    useFacilityBasedContinuationLanguage && serviceRequest?.requestType === "CONTINUATION"
      ? "continued stay  "
      : serviceRequest?.requestType === "CONTINUATION"
      ? `continuation request  `
      : `initial request  `;
  const requestIdentifier = serviceRequest ? `#` + serviceRequest.cohereId : "N / A";

  return `${verb + request + requestIdentifier}`;
};

const faxAuditHeaderText = (serviceCase: ServiceCase) => {
  let header = "Case ID " + serviceCase.caseNumber;
  if (serviceCase?.faxDetail?.cohereId) {
    header += " \u2022 Fax ID " + serviceCase.faxDetail?.cohereId;
  }
  if (serviceCase?.externalSources?.[0].externalReferenceId) {
    header += " \u2022 External ID " + serviceCase?.externalSources?.[0].externalReferenceId;
  }
  return header;
};

const trackingContainerStyle = {
  position: "absolute" as const,
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
};

const serviceRequestTextWrapperStyle = {
  alignSelf: "flex-start",
  width: "calc(100vw - 400px)",
  overflow: "hidden",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const relativeContainerStyle = {
  height: "72px",
  backgroundColor: "#fff",
  borderBottom: "1px solid #E5E5E5",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative" as const,
  width: "100%",
};

const logoContainerStyle = {
  position: "absolute" as const,
  left: 0,
};

const myAccountContainerStyle = {
  position: "absolute" as const,
  right: "32px",
  width: "100%",
};

const trackingTextStyle = {
  marginBottom: "4px",
  color: "#6e6e6e",
};

const bannerIconStyle = {
  padding: "10px 0px 16px 32px",
};
