import { Accordion, Body2, Caption, formatDateStrWithTz } from "@coherehealth/common";
import { Grid, IconButton, makeStyles, useTheme } from "@material-ui/core";
import MuiErrorIcon from "@material-ui/icons/Error";
import NotificationViewer from "components/NotificationViewer";
import {
  hasAssociatedPDFTemplate,
  isLetterNotification,
  isLettersExpansionNotification,
  NotificationData,
} from "components/NotificationViewer/util/notificationUtil";
import { NotificationsSummaryProps } from "components/PatientSummary/ServiceRequestSummaryCard/NotificationsSummary";
import VisibilityIcon from "@material-ui/icons/Visibility";
interface NotificationSummaryProps extends NotificationsSummaryProps {
  notification: NotificationData;
}

export function NotificationSummary({
  notification,
  serviceRequest,
  authorization,
  serviceRequestsOnAuth,
}: NotificationSummaryProps) {
  const theme = useTheme();
  const accordionStyles = useConfigurationAccordionProps();
  const viewPDFButtonDisabled = hasAssociatedPDFTemplate(notification) && !notification.data.pdfUrl;
  if (!isLettersExpansionNotification(notification)) {
    return (
      <Grid container alignItems="center" wrap="nowrap">
        <Grid item xs="auto">
          <Body2>Notification not supported in this view</Body2>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Accordion
        header={
          <Grid container>
            <Grid item xs="auto">
              <Body2>{label(notification)}</Body2>
            </Grid>
            {notification.data.errorReason ? (
              <Grid item xs="auto" style={{ paddingLeft: theme.spacing(1), color: theme.palette.error.dark }}>
                <MuiErrorIcon fontSize="small" />
              </Grid>
            ) : undefined}
            {hasAssociatedPDFTemplate(notification) && (
              <Grid item xs="auto" style={{ display: "flex", alignItems: "right", marginLeft: "auto" }}>
                <NotificationViewer
                  authorization={authorization}
                  serviceRequest={serviceRequest}
                  serviceRequestsOnAuth={serviceRequestsOnAuth}
                  renderButton={(handleOpenViewer) => (
                    <>
                      <IconButton
                        aria-label="View"
                        size="small"
                        disabled={viewPDFButtonDisabled}
                        style={{ padding: 0 }}
                        onClick={() => {
                          handleOpenViewer();
                        }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </>
                  )}
                />
              </Grid>
            )}
          </Grid>
        }
        styleOverrides={accordionStyles}
      >
        <Grid item xs="auto">
          <Grid container style={{ padding: theme.spacing(0.5, 1.5) }}>
            <Grid item xs={12}>
              <Caption>Status list: {notification.data.statusList?.join(", ")?.toString()}</Caption>
            </Grid>
            {hasAssociatedPDFTemplate(notification) && (
              <Grid item xs={12}>
                <Caption
                  style={{ color: notification.data.letterTemplateTitle ? undefined : theme.palette.error.main }}
                >
                  Template: {notification.data.letterTemplateTitle || "Template not found"}
                </Caption>
              </Grid>
            )}
            {notification.data.errorReason && (
              <Grid item xs={12}>
                <Caption style={{ color: theme.palette.error.main }}>
                  Error reason: {notification.data.errorReason}
                </Caption>
              </Grid>
            )}
            <Grid item xs={12}>
              <Caption>Recipient: {notification.data.recipient?.toString()}</Caption>
            </Grid>
            {isLetterNotification(notification) && notification.data.statusList?.includes("INBOUND_COMPLETE") && (
              <Grid item xs={12}>
                <Caption>
                  Time delivered by {notification.data.mailVendor || "vendor"}:{" "}
                  {formatDateStrWithTz(notification.data.deliveryInfo?.mailingDateTime)}
                </Caption>
              </Grid>
            )}
            <Grid item xs={12}>
              <Caption>Created: {formatDateStrWithTz(notification.data.dateCreated?.toString())}</Caption>
            </Grid>
          </Grid>
        </Grid>
      </Accordion>
    );
  }
}

const label = (notification: NotificationData) => {
  if (!isLettersExpansionNotification(notification)) {
    return "Notification not supported in this view";
  }
  let prefix = "";
  if (notification.variant === "INTERNAL_LETTER" || notification.variant === "EXTERNAL_LETTER") {
    prefix = `${formatEnumToLabel(notification.data.purpose?.toString())} letter for ${formatEnumToLabel(
      notification.data.recipient?.toString(),
      true
    )}`;
  } else if (notification.variant === "INTERNAL_FAX") {
    prefix = `${formatEnumToLabel(notification.data.purpose?.toString())} fax for ${formatEnumToLabel(
      notification.data.recipient?.toString(),
      true
    )}`;
  } else if (notification.variant === "WELLTOK_VAT_LETTER") {
    prefix = `Externally generated ${formatEnumToLabel(
      notification.data.purpose?.toString()
    )} VAT notification for ${formatEnumToLabel(notification.data.recipient?.toString(), true)}`;
  } else {
    prefix = `Unknown source and type of notification`;
  }
  if (notification.data.dryRun) {
    prefix += " (dry run)";
  }
  return prefix;
};

const formatEnumToLabel = (enumValue: string | undefined, lowercase?: boolean) => {
  if (enumValue) {
    enumValue = enumValue.toLocaleLowerCase();
    enumValue = enumValue.replaceAll("_", " ");
    if (!lowercase) {
      enumValue = enumValue.charAt(0).toLocaleUpperCase() + enumValue.substring(1);
    }
    return enumValue;
  } else {
    return "unknown";
  }
};

const useConfigurationAccordionProps = makeStyles((theme) => ({
  card: {
    padding: 0,
    position: "relative",
    boxShadow: "none",
    border: "none",
    borderRadius: "none",
    backgroundColor: "transparent",
    "&:hover": {
      border: "none",
      borderRadius: "none",
      boxShadow: "none",
    },
  },
  contentRoot: {
    padding: 0,
  },
  summaryRoot: {
    "&.Mui-expanded": {
      minHeight: theme.spacing(6),
    },
  },
  contentDivider: {
    opacity: 0,
    margin: 0,
  },
  "& $rotatingCarrot": {
    color: theme.palette.secondary.dark,
  },
}));
