import React, { useMemo } from "react";

import { Body1, colorsLight, H2, Modal, PrimaryButton, useStableUniqueId } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { createStyles, makeStyles, styled, Theme } from "@material-ui/core/styles";
import HighlightOff from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {
      padding: theme.spacing(0, 1, 4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    title: {
      paddingTop: theme.spacing(3),
    },
    details: {
      textAlign: "center",
      padding: theme.spacing(2, 0, 3),
    },
    cancel: {
      backgroundColor: colorsLight.gray.dark,
      "&:hover, &:focus": {
        backgroundColor: colorsLight.gray.dark,
      },
      width: 200,
      marginRight: theme.spacing(3),
    },
    deactivate: {
      backgroundColor: theme.palette.error.main,
      "&:hover, &:focus": {
        backgroundColor: theme.palette.error.main,
      },
      width: 200,
    },
  })
);
interface Props {
  open: boolean;
  handleClose: () => void;
  onDelete: () => void;
  deleting?: boolean;
  subject: "MEMBER" | "ORGANIZATION";
}

export default function DeleteConfirmationModal({ open, handleClose, onDelete, deleting, subject }: Props) {
  const classes = useStyles();
  const titleId = useStableUniqueId("deactivate");
  const instructionsId = useStableUniqueId("deactivate-member-instructions");

  const detailsText = useMemo(() => {
    if (subject === "MEMBER") {
      return "Do you really want to delete this account? This process cannot be undone.";
    } else if (subject === "ORGANIZATION") {
      return "Do you really want to delete this organization? This process cannot be undone.";
    }
  }, [subject]);

  return (
    <Modal
      onClose={handleClose}
      fullWidth
      aria-labelledby={titleId}
      aria-describedby={instructionsId}
      open={open}
      disableBackdropClick
    >
      <div className={classes.modalContent}>
        <DeleteWarningIcon data-public />
        <H2 className={classes.title} id={titleId} data-public>
          Are you sure?
        </H2>
        <Body1 color="textSecondary" className={classes.details} id={instructionsId} data-public>
          {detailsText}
        </Body1>
        <div>
          <PrimaryButton className={classes.cancel} onClick={handleClose} disableFocusRipple={true} data-public>
            Cancel
          </PrimaryButton>
          <PrimaryButton className={classes.deactivate} loading={deleting} onClick={onDelete} data-public>
            Delete
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DeleteWarningIcon = styled(HighlightOff)(({ theme }) => ({
  fontSize: 60,
  color: theme.palette.error.main,
  marginBotton: theme.spacing(6),
}));
