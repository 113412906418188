import React, { useCallback, useState, useEffect, useMemo, SetStateAction, Dispatch } from "react";
import { Container, Grid } from "@material-ui/core";
import { Box } from "@mui/material";
import { useMuiContainerStyles } from "@coherehealth/common";
import {
  useGetClinicalServiceDetermination,
  ProcedureCode,
  DiagnosisCode,
  ServiceDeterminationResponse,
  ProcedureCodeGroupOptions,
  ProcedureCodeGroupSelections,
  SemanticCode,
  ServiceDeterminationProcedureCodeBucket,
  ServiceDeterminationQuestionOption,
} from "@coherehealth/core-platform-api";
import { Banner, ButtonSelectGroup } from "@coherehealth/design-system";
import CompassIcon from "components/images/CompassIcon";
import SelectionCard from "./SelectionCard";
import { useSnackbar } from "notistack";
import listReplace from "util/listReplace";
import isEqual from "lodash/isEqual";
import RequestorCard, { Props as RequestorProps } from "components/Requestor/RequestorCard";
import { useAuthorized } from "authorization";
import debounce from "lodash/debounce";
import { PriorAuthRequirements } from "../common";
import { useLocation } from "react-router";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";

const getClinicalServiceDeterminationDebounced = debounce(
  async (payload, getClinicalServiceDetermination, handleDeterminationResponse, newSelections, pxCodeGroupOptions) => {
    const response = await getClinicalServiceDetermination(payload);
    handleDeterminationResponse(response, newSelections, pxCodeGroupOptions);
  },
  2000
);

interface Props extends RequestorProps {
  patientId: string;
  procedureCodes: ProcedureCode[];
  nonPalProcedureCodes: ProcedureCode[];
  primaryDiagnosisCode?: DiagnosisCode;
  secondaryDiagnosisCodes: DiagnosisCode[];
  initialResponse?: ServiceDeterminationResponse;
  setServiceDeterminationResponse: Dispatch<ServiceDeterminationResponse>;
  setBuckets: Dispatch<SetStateAction<ServiceDeterminationProcedureCodeBucket[]>>;
  setServiceDeterminationLoading: Dispatch<boolean>;
  setSelectedClinicalServiceIds: Dispatch<SetStateAction<Record<string, string[]>>>;
  selectedClinicalServiceIds: Record<string, string[]>;
  setSelectedCarePathId: Dispatch<SetStateAction<string | undefined>>;
  selectedCarePathId: string | undefined;
  priorAuthRequirements: PriorAuthRequirements;
}

export default function ServiceSelectionFlexibleIntake({
  patientId,
  procedureCodes,
  nonPalProcedureCodes,
  primaryDiagnosisCode,
  secondaryDiagnosisCodes,
  initialResponse,
  setServiceDeterminationResponse,
  setBuckets,
  setServiceDeterminationLoading,
  setSelectedClinicalServiceIds,
  selectedClinicalServiceIds,
  setSelectedCarePathId,
  selectedCarePathId,
  priorAuthRequirements,
  ...requestorProps
}: Props) {
  const containerClasses = useMuiContainerStyles();
  const isAuthorizedForRequestorForm = useAuthorized("REQUESTOR_FORM");

  //state
  const [pxCodeGroupOptions, setPxCodeGroupOptions] = useState<ProcedureCodeGroupOptions[]>([
    ...((initialResponse?.procedureCodeGroupOptions || []).sort(sortGroupOptions) || []),
    ...(createOptionsFromBuckets(initialResponse?.buckets).sort(sortGroupOptions) || []),
  ]);
  const [pxCodeGroupSelections, setPxCodeGroupSelections] = useState<ProcedureCodeGroupSelections[]>(
    initializeSelectionsFromOptions(
      selectedClinicalServiceIds,
      [
        ...(initialResponse?.procedureCodeGroupOptions || []),
        ...createOptionsFromBuckets(initialResponse?.buckets),
      ].sort(sortGroupOptions)
    )
  );

  const {
    mutate: getClinicalServiceDetermination,
    error: serviceDeterminationError,
    loading: serviceDeterminationLoading,
  } = useGetClinicalServiceDetermination({});

  useEffect(() => {
    setServiceDeterminationLoading(serviceDeterminationLoading);
  }, [setServiceDeterminationLoading, serviceDeterminationLoading]);

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (serviceDeterminationError) {
      enqueueSnackbar(`There was an error while searching for services: ${serviceDeterminationError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, serviceDeterminationError]);

  const baseServiceDeterminationPayload = useMemo(
    () => ({
      patientId,
      encounterType: priorAuthRequirements.encounterType,
      authCategory: priorAuthRequirements.authCategory?.enumName,
      semanticProcedureCodes: priorAuthRequirements?.desiredProcedureCodes,
      nonPalSemanticProcedureCodes: nonPalProcedureCodes,
      primarySemanticDiagnosisCode: primaryDiagnosisCode,
      secondarySemanticDiagnosisCodes: secondaryDiagnosisCodes,
      groupProcedureCodes: true,
    }),
    [
      patientId,
      priorAuthRequirements?.desiredProcedureCodes,
      nonPalProcedureCodes,
      primaryDiagnosisCode,
      secondaryDiagnosisCodes,
      priorAuthRequirements.authCategory?.enumName,
      priorAuthRequirements.encounterType,
    ]
  );

  function updateProcedureCodeGroupSelections(
    groupToUpdate: ProcedureCodeGroupOptions,
    selectedCategoryName: string,
    selectedServicesIds: string[]
  ) {
    const groupIndex = pxCodeGroupSelections.findIndex((grp) => pxCodeGroupsAreEqual(grp, groupToUpdate));
    const prevSelection = pxCodeGroupSelections[groupIndex] || { codes: groupToUpdate.codes };
    const newSelection = { ...prevSelection, selectedCategoryName, selectedServicesIds };
    const updatedSelections = listReplace(pxCodeGroupSelections, groupIndex, newSelection);
    setPxCodeGroupSelections(updatedSelections);
    return updatedSelections;
  }
  const clearServiceOptions = useCallback(
    (groupToUpdate: ProcedureCodeGroupOptions) => {
      const groupIndex = pxCodeGroupOptions.findIndex((grp) => pxCodeGroupsAreEqual(grp, groupToUpdate));
      const groupOptions = pxCodeGroupOptions[groupIndex];
      const clearedServiceOptions: ProcedureCodeGroupOptions = { ...groupOptions, serviceOptions: [] };

      setPxCodeGroupOptions((prevOptions) => listReplace(prevOptions, groupIndex, clearedServiceOptions));
    },
    [pxCodeGroupOptions]
  );

  const handleDeterminationResponse = useCallback(
    (
      response: ServiceDeterminationResponse,
      /* Cannot use the current states b/c they may not be updated, will have to pass them in here */
      currentSelections: ProcedureCodeGroupSelections[],
      currentOptions: ProcedureCodeGroupOptions[]
    ) => {
      if (response?.procedureCodeGroupOptions || response?.buckets) {
        // set the procedureCode group states
        // we need to set the careful so as not to overwrite previous options
        const newOptions: ProcedureCodeGroupOptions[] = [];
        const newSelections: ProcedureCodeGroupSelections[] = [];

        // For the UI purposes we need to make a unified list of both:
        // - "options" (undetermined px code groups) and
        // - "buckets" (determined px code groups)
        // To do this we're just going to make the "buckets" into "options" to show in the UI, and only render the "options".
        // If there are no _actual_ "options" to still select then we'll set the buckets state (callback into this component)
        // which will indicate that we have determined and bucketed all of the codes.

        const pxGroups = [...(response.procedureCodeGroupOptions || []), ...createOptionsFromBuckets(response.buckets)];
        if (
          initialResponse?.carePathOptions &&
          !initialResponse?.procedureCodeGroupOptions &&
          response?.procedureCodeGroupOptions
        ) {
          setServiceDeterminationResponse({ carePathOptions: initialResponse?.carePathOptions, ...response });
        }

        pxGroups.forEach((updatedPxCodeGroupOptions) => {
          // Update existing option if it exists, then add to newOptions/newSelections array
          const currentPxGroupOptions = currentOptions.find((currentGroup) =>
            pxCodeGroupsAreEqual(currentGroup, updatedPxCodeGroupOptions)
          );
          const currentSelection = currentSelections.find((currentSelections) =>
            pxCodeGroupsAreEqual(currentSelections, updatedPxCodeGroupOptions)
          );

          // If selecting a service option made a determination, make sure we don't lose the old options
          const updatedServiceOptions =
            updatedPxCodeGroupOptions.serviceOptions &&
            updatedPxCodeGroupOptions.serviceOptions.length >= 1 &&
            currentPxGroupOptions?.serviceOptions &&
            currentPxGroupOptions.serviceOptions.length > 1
              ? currentPxGroupOptions.serviceOptions
              : updatedPxCodeGroupOptions.serviceOptions || [];

          const newOption = {
            codes: updatedPxCodeGroupOptions.codes,
            categoryOptions:
              (updatedPxCodeGroupOptions.categoryOptions || []).length > 0
                ? updatedPxCodeGroupOptions.categoryOptions
                : currentPxGroupOptions?.categoryOptions || [],
            serviceOptions: updatedServiceOptions,
          };
          newOptions.push(newOption);

          // If the current selections still apply to the new option then include them, otherwise reset to unselected state
          newSelections.push({
            codes: updatedPxCodeGroupOptions.codes,
            selectedCategoryName: !!newOption.categoryOptions?.find(
              ({ name }) => name === currentSelection?.selectedCategoryName
            )
              ? currentSelection?.selectedCategoryName
              : "",
            selectedServicesIds:
              newOption.serviceOptions?.length === 1
                ? [newOption.serviceOptions[0].id || ""]
                : currentSelection?.selectedServicesIds?.filter(
                    (selectedId) => !!newOption.serviceOptions.find(({ id }) => id === selectedId)
                  ) || [],
          });
        });

        setPxCodeGroupOptions(newOptions.sort(sortGroupOptions));

        setPxCodeGroupSelections(newSelections);

        const servicesIds: Record<string, string[]> = {};
        // this sets up servicesIds with key:value pair. with key being the code and value being clinicalServiceIds associates with the code
        // incase the the selection.codes == undefined or selection.codes?.length == 0 we set the key to be ""
        // this can happen when clicalService is not associated with any code (associated to authCategory)
        currentSelections.forEach((selection) => {
          if (selection.selectedServicesIds) {
            const code = selection.codes?.length ? selection.codes[0].code : "";
            servicesIds[code] = selection.selectedServicesIds;
          }
        });
        setSelectedClinicalServiceIds(servicesIds);
      }
      if (!!response?.buckets && !response?.procedureCodeGroupOptions) {
        // There are buckets and no (undetermined) options in the response, so we're "done"!
        // Set the buckets
        setBuckets(response.buckets);
      } else {
        // we're not done yet...
        setBuckets([]);
      }
    },
    [
      initialResponse?.carePathOptions,
      initialResponse?.procedureCodeGroupOptions,
      setSelectedClinicalServiceIds,
      setServiceDeterminationResponse,
      setBuckets,
    ]
  );
  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);

  return (
    <Container classes={containerClasses} maxWidth="lg">
      {isAuthorizedForRequestorForm && (
        <Box paddingTop={isFaxAuthBuilderFlow ? 3 : 5}>
          <RequestorCard {...requestorProps} />
        </Box>
      )}
      <Box marginTop={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ paddingBottom: isFaxAuthBuilderFlow ? "32px" : undefined }}>
            <Banner
              icon={<CompassIcon />}
              color="info"
              title="For faster approval, let us know which services fit best"
              subtitle="We found a few matches for the care you're requesting"
            />
          </Grid>
          {(initialResponse?.carePathOptions || []).length > 0 && (
            <Grid item xs={12}>
              <SelectionCard
                title="Which of the following best describes the patient’s primary diagnosis?"
                codes={primaryDiagnosisCode ? [primaryDiagnosisCode] : []}
              >
                <ButtonSelectGroup
                  options={
                    initialResponse?.carePathOptions
                      ?.sort(({ name: name0 }, { name: name1 }) => ((name0 || "") < (name1 || "") ? -1 : 1))
                      ?.map(({ id, name }) => ({ id: id || "", label: name })) || []
                  }
                  type="singleSelect"
                  value={selectedCarePathId}
                  onChangeValue={async (carePathId) => {
                    setPxCodeGroupOptions([]);
                    setPxCodeGroupSelections([]);
                    setSelectedCarePathId(carePathId);
                    const response = await getClinicalServiceDetermination({
                      ...baseServiceDeterminationPayload,
                      selectedCarePathId: carePathId,
                    });
                    handleDeterminationResponse(response, [], []);
                  }}
                />
              </SelectionCard>
            </Grid>
          )}
          {pxCodeGroupOptions.map((group, groupIndex) => {
            const selectionForGroup: ProcedureCodeGroupSelections | undefined = pxCodeGroupSelections?.find(
              (selectionGroup) => pxCodeGroupsAreEqual(selectionGroup, group)
            );
            const selectedCategoryName = selectionForGroup?.selectedCategoryName || "";
            const selectedServicesIds = selectionForGroup?.selectedServicesIds || [];
            const showCategoryOptions = (group?.categoryOptions || []).length > 0;
            const showServiceOptions =
              (group?.serviceOptions?.length || 0) > 0 || (group?.categoryOptions?.length || 0) < 1;

            return (
              <Grid item xs={12} key={groupIndex}>
                <SelectionCard
                  title={
                    (group.categoryOptions?.length || 0) > 0
                      ? "What best describes the care this patient needs?"
                      : undefined
                  }
                  authCategory={priorAuthRequirements.authCategory?.enumName}
                  codes={group.codes ? (group.codes as SemanticCode[]) : []}
                >
                  {showCategoryOptions && (
                    <ButtonSelectGroup
                      options={
                        group?.categoryOptions
                          ?.sort(({ name: name0 }, { name: name1 }) => ((name0 || "") < (name1 || "") ? -1 : 1))
                          ?.map(({ name }) => ({
                            id: name || "",
                            label: name || "",
                          })) || []
                      }
                      type="singleSelect"
                      value={selectedCategoryName || ""}
                      onChangeValue={async (newSelectedCategoryNameForGroup) => {
                        const newSelections = updateProcedureCodeGroupSelections(
                          group,
                          newSelectedCategoryNameForGroup,
                          []
                        );
                        clearServiceOptions(group);
                        const response = await getClinicalServiceDetermination({
                          ...baseServiceDeterminationPayload,
                          selectedCarePathId: selectedCarePathId,
                          procedureCodeGroupSelections: newSelections,
                        });
                        handleDeterminationResponse(response, newSelections, pxCodeGroupOptions);
                      }}
                    />
                  )}
                  {showServiceOptions && (
                    <ButtonSelectGroup
                      marginTop={showCategoryOptions && showServiceOptions ? 3 : 0}
                      options={
                        group?.serviceOptions
                          ?.sort(({ name: name0 }, { name: name1 }) => ((name0 || "") < (name1 || "") ? -1 : 1))
                          ?.map(({ id, name }) => ({
                            id: id || "",
                            label: name || "",
                            disabled: (group?.serviceOptions?.length || 0) < 2,
                          })) || []
                      }
                      type="multiSelect"
                      values={selectedServicesIds}
                      onChangeValues={async (selectedServicesIds) => {
                        const newSelections = updateProcedureCodeGroupSelections(
                          group,
                          selectedCategoryName || "",
                          selectedServicesIds
                        );
                        const servicesIds: Record<string, string[]> = {};
                        newSelections.forEach((selection) => {
                          if (selection.codes?.length && selection.selectedServicesIds) {
                            const code = selection.codes[0].code;
                            servicesIds[code] = selection.selectedServicesIds;
                          }
                        });
                        setSelectedClinicalServiceIds(servicesIds);
                        const payload = {
                          ...baseServiceDeterminationPayload,
                          selectedCarePathId: selectedCarePathId,
                          procedureCodeGroupSelections: newSelections,
                        };
                        setServiceDeterminationLoading(true);
                        getClinicalServiceDeterminationDebounced(
                          payload,
                          getClinicalServiceDetermination,
                          handleDeterminationResponse,
                          newSelections,
                          pxCodeGroupOptions
                        );
                      }}
                    />
                  )}
                </SelectionCard>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Container>
  );
}

function pxCodeGroupsAreEqual(
  group0: ProcedureCodeGroupOptions | ProcedureCodeGroupSelections,
  group1: ProcedureCodeGroupOptions | ProcedureCodeGroupSelections
) {
  const { codes: codes0 } = group0;
  const { codes: codes1 } = group1;

  // check if code arrays are equal (order doesn't matter so we sort)
  return isEqual(codes0?.map((px) => px.code)?.sort(), codes1?.map((px) => px.code)?.sort());
}

export function createOptionsFromBuckets(
  buckets?: ServiceDeterminationProcedureCodeBucket[]
): ProcedureCodeGroupOptions[] {
  if (!buckets) {
    return [];
  }
  const bucketsAsPxCodeGroupOptions = buckets.map((bucket) => ({
    codes: bucket.procedureCodes,
    categoryOptions: [],
    serviceOptions: [
      {
        id: bucket.clinicalService?.id || "Uncategorized Service",
        name: bucket.clinicalService?.name || "Uncategorized Service",
        description: bucket.clinicalService?.description,
        isSingleService: bucket.clinicalService?.isSingleService,
      },
    ],
  }));
  let newBuckets: ProcedureCodeGroupOptions[] = [];
  bucketsAsPxCodeGroupOptions.forEach((bucket) => {
    const groupIndex = newBuckets.findIndex((grp) => pxCodeGroupsAreEqual(grp, bucket));

    if (groupIndex > -1) {
      const prevGroup = newBuckets[groupIndex];
      let serviceOps: ServiceDeterminationQuestionOption[] = [];
      if (prevGroup.serviceOptions) {
        serviceOps.push(...prevGroup.serviceOptions);
      }
      if (bucket.serviceOptions) {
        serviceOps.push(...bucket.serviceOptions);
      }
      const newGroup: ProcedureCodeGroupOptions = { ...prevGroup, serviceOptions: serviceOps };
      newBuckets = listReplace(newBuckets, groupIndex, newGroup);
    } else {
      newBuckets.push(bucket);
    }
  });

  return newBuckets;
}

function sortGroupOptions(option1: ProcedureCodeGroupOptions, option2: ProcedureCodeGroupOptions) {
  if (
    option1.categoryOptions &&
    option1.categoryOptions.length > 0 &&
    (!option2.categoryOptions || option2.categoryOptions.length === 0)
  ) {
    return -1;
  }

  if (
    option2.categoryOptions &&
    option2.categoryOptions.length > 0 &&
    (!option1.categoryOptions || option1.categoryOptions.length === 0)
  ) {
    return 1;
  }

  const sortCodes = (px0?: ProcedureCode, px1?: ProcedureCode) => ((px0?.code || "") < (px1?.code || "") ? -1 : 1);

  const option1LowestCode: ProcedureCode | undefined = option1.codes?.sort(sortCodes)[0];
  const option2LowestCode: ProcedureCode | undefined = option2.codes?.sort(sortCodes)[0];

  return sortCodes(option1LowestCode, option2LowestCode);
}

export function initializeSelectionsFromOptions(
  selectedClinicalServiceIds: Record<string, string[]>,
  options: ProcedureCodeGroupOptions[]
): ProcedureCodeGroupSelections[] {
  // if option length is 1 and selectedClinicalServiceIds with key "" (empty string) has an array with length !==0 then selections are associated with authCategory
  if (
    options?.length === 1 &&
    options[0].codes?.length === 0 &&
    selectedClinicalServiceIds[""] &&
    selectedClinicalServiceIds[""]?.length !== 0 &&
    options[0].serviceOptions
  ) {
    // check options have same ids as selections
    const areAllSelectionPresentInOptions = areAllIdsPresent(options[0].serviceOptions, selectedClinicalServiceIds[""]);
    if (areAllSelectionPresentInOptions) {
      return [
        {
          codes: [],
          selectedCategoryName: "",
          selectedServicesIds: selectedClinicalServiceIds[""],
        },
      ];
    } else {
      return [];
    }
  }
  let value = options.map(({ codes, serviceOptions }) => {
    const codeIncludedInIds = codeIncludedInCodes(codes, selectedClinicalServiceIds);
    const selectedServicesIds = codeIncludedInIds
      ? selectedClinicalServiceIds[codeIncludedInIds.code]
      : serviceOptions?.length === 1
      ? [serviceOptions[0].id || "Uncategorized Service"]
      : [];
    return {
      codes,
      selectedCategoryName: "",
      selectedServicesIds,
    };
  });

  return value;
}

function areAllIdsPresent(serviceOptions: ServiceDeterminationQuestionOption[], selectedOptions: string[]): boolean {
  const objectIdsSet = new Set(serviceOptions.map((serviceOption) => serviceOption.id));
  return selectedOptions.every((selectedOption) => objectIdsSet.has(selectedOption));
}

function codeIncludedInCodes(codes: ProcedureCode[] | undefined, selectedClinicalServiceIds: Record<string, string[]>) {
  const keys = Object.keys(selectedClinicalServiceIds) as Array<string>;
  return codes ? codes?.find((code) => keys.includes(code.code)) : undefined;
}
