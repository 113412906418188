import React from "react";
import { H2, Subtitle2 } from "@coherehealth/common";
import AutoVerifyInformativeModal from "./AutoVerifyInformativeModal";

const AUTO_VERIFICATION_MODAL = {
  on: {
    headerText: "Are you sure you want to turn on auto-verify by email domain?",
    subHeaderText:
      "By turning on this feature, you are confirming that new users with pre-specified email domains will be able to join and access PHI related to existing auths of the organization without needing to be manually verified by an administrator.",
    primaryButtonText: "Yes, turn on auto-verify by email domain",
    tertiaryButtonText: "No, I want to manually review each new account",
    primaryButtonAction: () => {},
    tertiaryButtonAction: () => {},
  },
  off: {
    headerText: "Are you sure you want to turn off auto-verify by email domain?",
    subHeaderText:
      "By turning off this feature, new users will need to be manually verified by an organization administrator before they have access to any existing or previous auths submitted by your organization.",
    primaryButtonText: "No, keep auto-verify by email domain on",
    tertiaryButtonText: "Yes, I want to manually review each new account",
    primaryButtonAction: () => {},
    tertiaryButtonAction: () => {},
  },
};

interface AutoVerifyInformativeModalEditProps {
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  setAutoVerifyEmail: React.Dispatch<React.SetStateAction<boolean>>;
  autoVerifyModal: boolean;
  setAutoVerifyModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AutoInformativeModalEdit({
  checked,
  setChecked,
  autoVerifyModal,
  setAutoVerifyModal,
  setAutoVerifyEmail,
}: AutoVerifyInformativeModalEditProps) {
  const onClose = () => {
    setAutoVerifyModal(false);
    setChecked(false);
    setAutoVerifyEmail(false);
  };

  return (
    <>
      <AutoVerifyInformativeModal
        open={autoVerifyModal}
        onClose={onClose}
        headerText={
          <H2>{checked ? AUTO_VERIFICATION_MODAL["on"].headerText : AUTO_VERIFICATION_MODAL["off"].headerText}</H2>
        }
        subHeaderText={
          <Subtitle2>
            {checked ? AUTO_VERIFICATION_MODAL["on"].subHeaderText : AUTO_VERIFICATION_MODAL["off"].subHeaderText}
          </Subtitle2>
        }
        primaryButtonText={
          checked ? AUTO_VERIFICATION_MODAL["on"].primaryButtonText : AUTO_VERIFICATION_MODAL["off"].primaryButtonText
        }
        tertiaryButtonText={
          checked ? AUTO_VERIFICATION_MODAL["on"].tertiaryButtonText : AUTO_VERIFICATION_MODAL["off"].tertiaryButtonText
        }
        primaryButtonAction={() => {
          if (checked) {
            setAutoVerifyEmail(true);
            setAutoVerifyModal(false);
          } else {
            setAutoVerifyModal(false);
            setChecked((prevState) => !prevState);
          }
        }}
        tertiaryButtonAction={onClose}
      />
    </>
  );
}
