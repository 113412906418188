import React, { useState } from "react";
import { ProcedureCode } from "@coherehealth/core-platform-api";
import { Body2, Card, Checkbox, Caption, H6, Subtitle1, colorsLight } from "@coherehealth/common";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled, Theme } from "@material-ui/core/styles";

interface Props {
  isReadonly?: false;
  requestedProcedureCodes: ProcedureCode[];
  approvedProcedureCodes: ProcedureCode[];
  setApprovedProcedureCodes: (procedureCodes: ProcedureCode[]) => void;
}

interface ReadonlyProps {
  isReadonly: true;
  requestedProcedureCodes: ProcedureCode[];
  approvedProcedureCodes: ProcedureCode[];
}

const useStyles = makeStyles({
  root: {
    "& .MuiCheckbox-root": {
      color: colorsLight.error.main,
    },
  },
});

export default function PartialProcedureCodeApproval({
  requestedProcedureCodes,
  approvedProcedureCodes,
  ...otherProps
}: Props | ReadonlyProps) {
  const [checkedProcedureCodes, setCheckedProcedureCodes] = useState<Set<ProcedureCode>>(
    new Set(approvedProcedureCodes || [])
  );
  const classes = useStyles();
  return (
    <>
      <DescriptionHeader>
        <Grid item container>
          <Grid item xs={8}>
            {Boolean(!otherProps.isReadonly) && <H6>Uncheck the services you want to deny</H6>}
          </Grid>
          <Grid item container direction="row" justifyContent="flex-end" alignItems="flex-start" xs={4}>
            <Subtitle1
              style={{ lineHeight: "25px" }}
            >{`${checkedProcedureCodes.size} of ${requestedProcedureCodes.length} services will approve`}</Subtitle1>
          </Grid>
        </Grid>
      </DescriptionHeader>
      <ProcedureCodesContainer>
        {requestedProcedureCodes.map(({ code, description }, index) => (
          <React.Fragment key={code}>
            <ProcedureCodeCard
              showWarning={
                !Array.from(checkedProcedureCodes)
                  .map((cpc) => cpc.code)
                  .includes(code)
              }
            >
              <CardContent style={{ padding: 0 }}>
                <Grid item container spacing={3}>
                  <Checkbox
                    key={code}
                    disabled={otherProps.isReadonly}
                    style={{ paddingLeft: 8 }}
                    checked={Array.from(checkedProcedureCodes)
                      .map((cpc) => cpc.code)
                      .includes(code)}
                    classes={
                      !Array.from(checkedProcedureCodes)
                        .map((cpc) => cpc.code)
                        .includes(code)
                        ? classes
                        : undefined
                    }
                    onChange={() => {
                      if (otherProps.isReadonly) {
                        return;
                      }

                      if (
                        Array.from(checkedProcedureCodes)
                          .map((cpc) => cpc.code)
                          .includes(code)
                      ) {
                        checkedProcedureCodes.delete(approvedProcedureCodes.filter((rpc) => rpc.code === code)[0]);
                      } else {
                        checkedProcedureCodes.add(requestedProcedureCodes.filter((rpc) => rpc.code === code)[0]);
                      }
                      setCheckedProcedureCodes(new Set(checkedProcedureCodes));
                      otherProps.setApprovedProcedureCodes(Array.from(checkedProcedureCodes));
                    }}
                  />
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    xs={10}
                    style={{ paddingLeft: 0 }}
                  >
                    <Grid item container xs={10}>
                      <Grid item>
                        <Body2>{code}</Body2>
                      </Grid>
                      <Grid item style={{ paddingLeft: 8 }}>
                        {!Array.from(checkedProcedureCodes)
                          .map((cpc) => cpc.code)
                          .includes(code) && <WarningMessage>- Service will be denied</WarningMessage>}
                      </Grid>
                    </Grid>
                    <Grid style={{ textAlign: "left" }} item xs={10}>
                      <ProcedureCodeDescription
                        showWarning={
                          !Array.from(checkedProcedureCodes)
                            .map((cpc) => cpc.code)
                            .includes(code)
                        }
                      >
                        {description}
                      </ProcedureCodeDescription>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </ProcedureCodeCard>
            {index < requestedProcedureCodes.length - 1 && <RowDivider />}
          </React.Fragment>
        ))}
      </ProcedureCodesContainer>
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ProcedureCodesContainer = styled("div")(({ theme }) => ({
  height: theme.spacing(48),
  width: "100%",
  overflowY: "auto",
  border: "1px solid #E5E5E5",
  boxSizing: "border-box",
  borderRadius: "3px",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DescriptionHeader = styled("div")({
  width: "100%",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RowDivider = styled(Divider)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ProcedureCodeCard = styled(({ showWarning, ...other }) => <Card {...other} />)<Theme, { showWarning: boolean }>(
  ({ theme, showWarning }) => ({
    border: "none",
    boxShadow: "none",
    background: showWarning ? colorsLight.error.transparent : "transparent",
    color: "textSecondary",
    margin: theme.spacing(2),
  })
);

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ProcedureCodeDescription = styled(({ showWarning, ...other }) => <Caption {...other} />)<
  Theme,
  { showWarning: boolean }
>(({ theme, showWarning }) => ({
  color: showWarning ? colorsLight.font.secondary : colorsLight.font.main,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const WarningMessage = styled(Subtitle1)({
  lineHeight: "18px",
  color: colorsLight.error.main,
});
