import { useFeature } from "@coherehealth/common";
import {
  Attachment,
  AttachmentUpdatePayload,
  useDeleteServiceRequestAttachment,
  useUpdateServiceRequestAttachment,
} from "@coherehealth/core-platform-api";
import { TableContainer, Table } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { UploadFile } from "../FileUploadItem";
import AttachmentTableBody from "./AttachmentTableBody";
import { TableContainerProps as MuiTableContainerProps } from "@material-ui/core";

interface Props extends MuiTableContainerProps {
  files: UploadFile[];
  setFiles: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  canUserEditViewable: boolean;
  carePathJourneyId?: string;
  serviceRequestIds?: string[];
  stage?: string;
  onUpdateAttachments?: (arg0: Attachment[]) => void;
  setRemovedAttachments: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function AttachmentsTable({
  files,
  canUserEditViewable,
  setFiles,
  stage,
  carePathJourneyId,
  serviceRequestIds,
  onUpdateAttachments,
  setRemovedAttachments,
  ...muiTableContainerProps
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const multiSingleService = useFeature("multiSingleService");

  const getUniqueFiles = () => {
    const attachmentGroupIds: string[] = [];
    return files.filter((file) => {
      if (file.attachmentGroupId) {
        if (attachmentGroupIds.includes(file.attachmentGroupId)) {
          return false;
        } else {
          attachmentGroupIds.push(file.attachmentGroupId);
          return true;
        }
      } else {
        return true;
      }
    });
  };

  const {
    mutate: deleteAttachment,
    loading: deleteLoading,
    error: deleteError,
  } = useDeleteServiceRequestAttachment({
    id: "",
  });

  useEffect(() => {
    if (deleteError) {
      enqueueSnackbar(`Failed to delete attachment: ${deleteError.message}`, { variant: "error" });
    }
  }, [deleteError, enqueueSnackbar]);

  const {
    mutate: updateAttachment,
    loading: updateLoading,
    error: updateError,
  } = useUpdateServiceRequestAttachment({
    id: "",
    attachmentId: "",
  });

  useEffect(() => {
    if (updateError) {
      enqueueSnackbar(`Failed to update attachment: ${updateError.message}`, { variant: "error" });
    }
  }, [updateError, enqueueSnackbar]);

  // Given one attachment, delete all identical attachments (across all SRs)
  // If feature flag off, just delete the one file
  const deleteAttachmentGroup = async (fileId: string): Promise<void> => {
    const attachmentGroupId = files.find((f) => f.id === fileId)?.attachmentGroupId;
    if (multiSingleService && attachmentGroupId) {
      for (const file of files || []) {
        if (attachmentGroupId === file.attachmentGroupId) {
          const fileId = file.id;
          const serviceRequestId = file?.serviceRequest?.id;
          await deleteAttachment(fileId, {
            pathParams: { id: serviceRequestId || "" },
          });
        }
      }
    } else {
      await deleteAttachment(fileId, {
        pathParams: { id: files.find((f) => f.id === fileId)?.serviceRequest?.id || "" },
      });
    }
  };

  // Given one attachment, update all identical attachments (across all SRs)
  // If feature flag off, just delete the one file
  const updateAttachmentGroup = async (
    fileId: string,
    attachmentUpdatePayload: AttachmentUpdatePayload
  ): Promise<void> => {
    const attachmentGroupId = files.find((f) => f.id === fileId)?.attachmentGroupId;
    if (multiSingleService && attachmentGroupId) {
      for (const file of files || []) {
        if (attachmentGroupId === file.attachmentGroupId) {
          const fileId = file.id;
          const serviceRequestId = file?.serviceRequest?.id;
          const response = await updateAttachment(attachmentUpdatePayload, {
            pathParams: {
              id: serviceRequestId || "",
              attachmentId: fileId,
            },
          });
          onUpdateFile(response);
        }
      }
    } else {
      const response = await updateAttachment(attachmentUpdatePayload, {
        pathParams: { id: files.find((f) => f.id === fileId)?.serviceRequest?.id || "", attachmentId: fileId },
      });
      onUpdateFile(response);
    }
  };

  // Called after the file deletion http requests finish
  const onRemoveFile = ({ id }: { id: string }) => {
    const attachmentGroupId = files.find((f) => f.id === id)?.attachmentGroupId;
    if (attachmentGroupId) {
      setFiles((orig) => [...orig.filter((f) => f.attachmentGroupId !== attachmentGroupId)]);
      setRemovedAttachments(files.filter((f) => f.attachmentGroupId === attachmentGroupId).map((f) => f.id));
    } else {
      setFiles((orig) => [...orig.filter((f) => f.id !== id)]);
      setRemovedAttachments([id]);
    }
  };

  const onUpdateFile = (file: UploadFile) => {
    setFiles((orig) => {
      const existingIdx = orig.findIndex((f) => f.id === file.id);
      if (existingIdx >= 0) {
        // Return a copy of the array with the existing entry merged with `file` and all other elements untouched
        return [...orig.slice(0, existingIdx), { ...orig[existingIdx], ...file }, ...orig.slice(existingIdx + 1)];
      } else {
        // This probably shouldn't happen... oh well, just append it
        return [...orig, file];
      }
    });
  };

  return (
    <TableContainer {...muiTableContainerProps}>
      <Table>
        <AttachmentTableBody
          files={getUniqueFiles()}
          setFiles={setFiles}
          canUserEditViewable={canUserEditViewable}
          stage={stage}
          serviceRequestIds={serviceRequestIds}
          carePathJourneyId={carePathJourneyId}
          onUpdateAttachments={onUpdateAttachments}
          deleteAttachment={deleteAttachmentGroup}
          deleteLoading={deleteLoading}
          onRemoveFile={onRemoveFile}
          onUpdateFile={onUpdateFile}
          updateAttachmentGroup={updateAttachmentGroup}
          updateLoading={updateLoading}
          data-testid="attachment-table-body"
        />
      </Table>
    </TableContainer>
  );
}
