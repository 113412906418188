import React from "react";
import { withStyles, Theme } from "@material-ui/core/styles";
import MUITooltip from "@material-ui/core/Tooltip";

const styles = (theme: Theme) => ({
  arrow: {
    color: theme.palette.text.primary,
  },
  tooltip: {
    boxShadow: "0px 2px 4px rgba(33, 36, 41, 0.05)",
    borderRadius: 8, // border-radius > 8px starts to look disconnected in start/end placements
    padding: theme.spacing(2, 3),
    backgroundColor: "#2B3648",
    ...theme.typography.body1,
  },
});

const Tooltip = React.forwardRef((props: any, ref) => {
  const { classes, children, ...otherProps } = props;

  return (
    <MUITooltip ref={ref} classes={classes} arrow {...otherProps}>
      {children}
    </MUITooltip>
  );
});
Tooltip.displayName = "Tooltip";

export default withStyles(styles)(Tooltip) as unknown as typeof MUITooltip;
