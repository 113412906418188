import { Sanitized } from "@coherehealth/common";
import { ClinicalReviewChangeRequest, useGetUserById } from "@coherehealth/core-platform-api";
import { Grid, makeStyles } from "@material-ui/core";
import { returnReviewReasonsLabels } from "components/ClinicalReview/ReturnRequestDetails/ReturnRequestDetailsUtils";
import { format } from "date-fns";
import { useEffect } from "react";

interface ReturnedServiceRequestCardProps {
  clinicalReviewChangeRequest: ClinicalReviewChangeRequest;
}

export function ReturnedServiceRequestCard(props: ReturnedServiceRequestCardProps): JSX.Element {
  const { clinicalReviewChangeRequest } = props;

  const classes = useStyles();

  const { data: user, refetch: refetchUser } = useGetUserById({
    id: clinicalReviewChangeRequest.createdBy ?? "",
    lazy: true,
    queryParams: {
      includeOrganizationName: true,
    },
  });

  useEffect(() => {
    if (clinicalReviewChangeRequest.createdBy) {
      refetchUser();
    }
  }, [clinicalReviewChangeRequest.createdBy, refetchUser]);

  const dateCreated = clinicalReviewChangeRequest.dateCreated
    ? format(new Date(clinicalReviewChangeRequest.dateCreated), "MM/dd/yyyy hh:mma")
    : "";

  const reasonsCaption = clinicalReviewChangeRequest.reasons
    ?.map((reason, index) =>
      index !== 0 ? returnReviewReasonsLabels.get(reason)?.toLowerCase() : returnReviewReasonsLabels.get(reason)
    )
    ?.join(", ");

  return (
    <Grid container className={classes.cardWrapper}>
      <Grid item xs={12} className={classes.header}>
        <p>Please correct the following so a letter writer can finalize the determination letter:</p>
      </Grid>
      <Grid item xs={12} className={classes.reasons}>
        {reasonsCaption}
      </Grid>
      <Grid item xs={12} className={classes.details}>
        <Sanitized __html={clinicalReviewChangeRequest.details} />
      </Grid>
      <Grid item xs={12} className={classes.byLine}>
        {`${user?.name} (${user?.organizationName}) on ${dateCreated}`}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    fontFamily: "Gilroy-Regular",
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 17 / 15,
    letterSpacing: "0.15px",

    "& p": {
      margin: "4px 0 0 0",
    },
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  reasons: {
    fontFamily: "Gilroy-SemiBold",
    fontSize: 15,
    fontStyle: "normal",
    fontWeight: 400,
  },
  details: {
    "& ul": {
      paddingLeft: theme.spacing(2.5),
    },
  },
  byLine: {
    fontFamily: "inherit",
    fontSize: 13,
    fontWeight: 400,
    lineHeight: 15 / 13,
    letterSpacing: "0.15px",
    color: "#5E6572",
    marginTop: theme.spacing(1.5),
  },
}));
