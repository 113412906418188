import React, { useCallback, useEffect } from "react";
import {
  Attachment,
  ServiceRequestResponse,
  useGetServiceRequestAttachments,
  useGetFaxAttachment,
  useGetFaxAttachmentDownload,
  AttachmentType,
} from "@coherehealth/core-platform-api";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { Card, H4, H6, InlineButton } from "@coherehealth/common";
import { attachmentTypes } from "components/AddAttachments/FileUploadItem";
import { FaxStep } from ".";
import { AttachmentTypeLabel } from "components/ServiceRequest/ReadonlyDetail/FormElements";
import { useBinaryFileFromResponse, Body1, Caption, formatDateStrWithTz } from "@coherehealth/common";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, useTheme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DocumentViewer from "components/DocumentViewer";
import Dialog from "@material-ui/core/Dialog";
import config from "api/config";
import useSearchAndLocation from "hooks/useSearchAndLocation";

interface AttachmentSectionProps {
  step: FaxStep;
  docType: string;
  setDocType: React.Dispatch<React.SetStateAction<string>>;
  attachment: Attachment | undefined;
  loading: boolean;
}

interface CohereFlowAttachmentSectionProps extends AttachmentSectionProps {
  serviceRequest: ServiceRequestResponse;
  setAttachment: React.Dispatch<React.SetStateAction<Attachment | undefined>>;
}

export function CohereFaxAttachmentSection({
  serviceRequest,
  step,
  docType,
  setDocType,
  attachment,
  setAttachment,
  loading,
}: CohereFlowAttachmentSectionProps) {
  const { data: serviceRequestAttachments, loading: attachmentsLoading } = useGetServiceRequestAttachments({
    id: serviceRequest.id,
  });
  const srAttachment: Attachment | undefined =
    serviceRequestAttachments && serviceRequestAttachments?.length > 0 ? serviceRequestAttachments[0] : undefined;

  const onChangeType = async (type: string) => {
    assertIsValidAttachmentType(type);
    setDocType(type);
  };

  useEffect(() => {
    if (!attachment && srAttachment) {
      setAttachment(srAttachment);
    }
  }, [attachment, setAttachment, srAttachment]);

  const handleChange = (val: string) => {
    onChangeType(val);
  };

  return (
    <AttachmentCard>
      {!serviceRequestAttachments && attachmentsLoading ? (
        <CircularProgress />
      ) : step === "SUBMIT_REQUEST" ? (
        <AttachmentSummary attachment={attachment} loading={loading} />
      ) : (
        <AttachmentEdit
          attachment={attachment}
          handleChange={handleChange}
          docType={docType}
          fileUrl={`${config.SERVICE_API_URL}serviceRequest/${serviceRequest.id}/attachment/${attachment?.id}/download`}
        />
      )}
    </AttachmentCard>
  );
}

interface NonCohereFlowAttachmentSectionProps extends AttachmentSectionProps {
  faxId: string;
  setUpdateAttachment: React.Dispatch<React.SetStateAction<boolean>>;
}

export function NonCohereFaxAttachmentSection({
  faxId,
  docType,
  setDocType,
  step,
  attachment,
  setUpdateAttachment,
  loading,
}: NonCohereFlowAttachmentSectionProps) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: faxDownload,
    loading: faxDownloadLoading,
    error: faxDownloadError,
  } = useGetFaxAttachmentDownload({
    id: faxId,
  });

  const {
    data: faxInfo,
    loading: faxInfoLoading,
    error: faxInfoError,
  } = useGetFaxAttachment({
    id: faxId,
  });

  useEffect(() => {
    if (faxDownloadError) {
      enqueueSnackbar(`Error downloading fax: ${faxDownloadError.message}`, { variant: "error" });
    }
  }, [faxDownloadError, enqueueSnackbar]);

  useEffect(() => {
    if (faxInfoError) {
      enqueueSnackbar(`Error downloading fax information: ${faxInfoError.message}`, { variant: "error" });
    }
  }, [faxInfoError, enqueueSnackbar]);

  const { fileUrl } = useBinaryFileFromResponse(faxDownload);

  const onChangeType = async (type: string) => {
    assertIsValidAttachmentType(type);
    setDocType(type);
  };

  const handleChange = (val: string) => {
    onChangeType(val);
    setUpdateAttachment(true);
  };

  return (
    <AttachmentCard>
      {faxDownloadLoading || faxInfoLoading ? (
        <CircularProgress />
      ) : step === "SAVE_AND_REVIEW" ? (
        <AttachmentEdit
          attachment={faxInfo || undefined}
          handleChange={handleChange}
          docType={docType}
          fileUrl={fileUrl}
        />
      ) : (
        <AttachmentSummary attachment={attachment} loading={loading} />
      )}
    </AttachmentCard>
  );
}

function AttachmentEdit({
  attachment,
  handleChange,
  docType,
  fileUrl,
}: {
  attachment: Attachment | undefined;
  handleChange: ((arg0: string) => any) | undefined;
  docType: string;
  fileUrl: string | undefined;
}) {
  const theme = useTheme();
  const { location, search } = useSearchAndLocation();

  const [fileViewOpen, setFileViewOpen] = React.useState(false);

  const handleClose = useCallback(() => {
    setFileViewOpen(false);
  }, [setFileViewOpen]);
  return (
    <>
      {attachment ? (
        <>
          <Grid container item justify="space-between" xs={12}>
            <H4 style={{ paddingTop: 0, paddingBottom: 16 }}>Attachments {<TextSeparatorIcon />} 1</H4>
            <Grid container item xs={12}>
              <Grid item xs={5}>
                <StyledCaption>File name</StyledCaption>
              </Grid>
              <Grid item xs={7}>
                <StyledCaption>Document type</StyledCaption>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ padding: theme.spacing(2, 0, 2, 0) }}>
              <Divider />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={5} style={{ paddingTop: 16 }}>
                {attachment?.name}
              </Grid>
              <Grid item xs={2} style={{ paddingLeft: theme.spacing(1), paddingTop: 12 }}>
                <InlineButton
                  onClick={() => {
                    setFileViewOpen(true);
                  }}
                  style={{ paddingLeft: theme.spacing(2) }}
                  disabled={false}
                  startIcon={<VisibilityIcon />}
                >
                  View Attachment
                </InlineButton>
                <Dialog fullScreen open={fileViewOpen} onClose={handleClose}>
                  <DocumentViewer
                    documentInfo={attachment}
                    url={fileUrl}
                    handleClose={handleClose}
                    canDelete={false}
                    location={location}
                    search={search}
                  />
                </Dialog>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid item xs={12} style={{ padding: 0 }}>
          <H6>No attachments</H6>
        </Grid>
      )}
    </>
  );
}

function AttachmentSummary({ attachment, loading }: { attachment: Attachment | undefined; loading: boolean }) {
  const theme = useTheme();
  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {attachment ? (
            <>
              <H4 style={{ paddingTop: 0, paddingBottom: 16 }}>Attachments {<TextSeparatorIcon />} 1</H4>
              <div
                key={attachment.id}
                style={{ border: "1px solid #E5E5E5", borderRadius: 5, padding: theme.spacing(2) }}
              >
                <Grid item xs={12}>
                  <Grid container justify="space-between" alignItems="center">
                    {Boolean(attachment.type) && (
                      <Grid item>
                        <AttachmentTypeLabel color="textSecondary">{attachment.type}</AttachmentTypeLabel>
                      </Grid>
                    )}
                    <Grid item>
                      {attachment.createdByName ? (
                        <Caption>
                          Uploaded on {formatDateStrWithTz(attachment.dateCreated)} by {attachment.createdByName}
                        </Caption>
                      ) : (
                        <Caption>Uploaded on {formatDateStrWithTz(attachment.dateCreated)}</Caption>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Body1>{attachment.name}</Body1>
                </Grid>
              </div>
            </>
          ) : (
            <Grid item xs={12} style={{ padding: 0 }}>
              <H6>No attachments</H6>
            </Grid>
          )}
        </>
      )}
    </>
  );
}

function assertIsValidAttachmentType(ipt: string): asserts ipt is AttachmentType {
  if (!attachmentTypes.map((x) => x.toString()).includes(ipt)) {
    throw new Error("Invalid attachment type");
  }
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const AttachmentCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(-3),
  padding: theme.spacing(0, 3, 3, 3),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledCaption = styled(Caption)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const TextSeparatorIcon = styled(FiberManualRecordIcon)(({ theme }) => ({
  fontSize: 6,
  marginBottom: 2,
}));
