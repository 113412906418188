import {
  AppealPayload,
  AppealResponse,
  ServiceRequestResponse,
  useCreateOrUpdateAppeal,
} from "@coherehealth/core-platform-api";
import { useContext, useEffect, useState } from "react";
import { Checkbox, colorsLight, InformativeModal, SingleSelectDropdown, useConfiguration } from "@coherehealth/common";
import usePatientStayDateOnCRR from "components/ServiceRequest/PatientStay/usePatientStayDateOnCRR";
import { useProcedureCodesState } from "components/ServiceRequest/ReviewSection/util/useProcedureCodesState";
import { Box, Grid } from "@material-ui/core";
import PartialApprovalFlexibleCodes from "components/ServiceRequestStatusDisplay/PartialApproval/PartialApprovalFlexibleCodes";
import PatientStays, { deAggregateStayDates } from "components/ServiceRequest/PatientStay/PatientStays";
import elementIsNonNull from "util/elementIsNonNull";
import { ClinicalReviewContext } from "components/ClinicalReview/Review/ClinicalReviewPage";
import { ReviewOutcomeOption } from "components/ServiceRequest/ReviewSection/util/QueueManagementReviewUtil";
import { useSnackbar } from "notistack";
import { error as logError } from "logger";
import { navigateToPS } from "components/AuthBuilder/common";
import { useNavigate } from "react-router";
import { AppealNoteFormAction, AppealNoteFormState } from "./AppealNoteReducer";
import { UserContext } from "UserContext";

interface AppealNoteSubmissionModalProps {
  currentAppeal?: AppealResponse;
  openSubmissionModal: boolean;
  setOpenSubmissionModal: React.Dispatch<React.SetStateAction<boolean>>;
  serviceRequest: ServiceRequestResponse;
  appealNoteFormState: AppealNoteFormState;
  updateAppealNoteFormState: React.Dispatch<AppealNoteFormAction>;
  appeals: AppealResponse[] | null;
}

const validateAppealNoteSubmissionModal = (
  state: AppealNoteFormState,
  isStayDatesValid: boolean,
  isPxCodesValid: boolean
) => {
  const validStayDates = "PARTIALLY_APPROVED" === state.outcome ? isStayDatesValid : true;
  const validPxCodes = "PARTIALLY_APPROVED" === state.outcome ? isPxCodesValid : true;
  return Boolean(state.outcome) && validStayDates && validPxCodes;
};

export const AppealNoteSubmissionModal = ({
  currentAppeal,
  openSubmissionModal,
  setOpenSubmissionModal,
  serviceRequest,
  appealNoteFormState,
  updateAppealNoteFormState,
  appeals,
}: AppealNoteSubmissionModalProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { getUser } = useContext(UserContext);
  const user = getUser();
  const [userId, setUserId] = useState<string>();
  useEffect(() => {
    if (user) {
      getUser()?.then((user) => setUserId(user.sub || ""));
    }
  }, [getUser, user]);
  const { startDate, healthPlanName, encounterType, patientStatus, clinicalServices, units, delegatedVendor } =
    serviceRequest;
  const { enabled: facilityBasedConfigEnabled, levelOfCareIsMandatoryOnReviews } =
    useConfiguration("facilityBasedRequestConfiguration", healthPlanName, delegatedVendor) ?? {};
  const isConfigEnabledAndInpatient = facilityBasedConfigEnabled && encounterType === "INPATIENT";
  const { allowedReviewOutcomes } = useContext(ClinicalReviewContext);
  const { patientStayDateRanges, setPatientStayDateRanges, patientStaysErrorStates, isPatientStayDatesError } =
    usePatientStayDateOnCRR({
      version: "DecisionStay",
      serviceStartDate: startDate,
      patientStayDates: serviceRequest.patientStayDates,
      checkEmptyRequestedLoc: levelOfCareIsMandatoryOnReviews,
    });
  const showPatientStays =
    isConfigEnabledAndInpatient &&
    appealNoteFormState.outcome === "PARTIALLY_APPROVED" &&
    patientStayDateRanges &&
    setPatientStayDateRanges;
  const showFlexibleCodes = appealNoteFormState.outcome === "PARTIALLY_APPROVED";
  const [attemptedSubmit, setAttemptedSubmit] = useState<boolean>(false);

  const {
    approvedProcedureCodes,
    pxCodesByClinicalService,
    approvedUnitsByClinicalService,
    updateApprovedUnitsByCS,
    updateSinglePxCodeUnit,
    approvedUnits,
    approvedPxCodesAreInvalid,
  } = useProcedureCodesState({ serviceRequest, existingReviews: null, currentReviewId: "" });

  const isApprovedPxCodesInvalid = approvedPxCodesAreInvalid();
  const isValidAppealNote = validateAppealNoteSubmissionModal(
    appealNoteFormState,
    !isPatientStayDatesError,
    !isApprovedPxCodesInvalid
  );

  const upholdOrOverturn = (currentAuthStatus?: string, optionAuthStatus?: string) => {
    return currentAuthStatus === optionAuthStatus ? "Uphold initial" : "Overturn to";
  };

  const getReviewOutcomeOptions = (serviceRequest: ServiceRequestResponse): ReviewOutcomeOption[] => {
    if (!serviceRequest.authStatus) {
      return [];
    }
    if (allowedReviewOutcomes && allowedReviewOutcomes.length > 0) {
      return allowedReviewOutcomes;
    }
    return [
      { id: "APPROVED", label: `${upholdOrOverturn(serviceRequest.authStatus, "APPROVED")} approval` },
      {
        id: "PARTIALLY_APPROVED",
        label: `${upholdOrOverturn(serviceRequest.authStatus, "PARTIALLY_APPROVED")} partial approval`,
      },
      { id: "DENIED", label: `${upholdOrOverturn(serviceRequest.authStatus, "DENIED")} denied` },
      { id: "NOT_COMPLETED", label: "Not completed" },
      { id: "WITHDRAWN", label: "Withdrawn" },
    ];
  };

  const { mutate: createOrUpdateAppeal, loading: createOrUpdateAppealLoading } = useCreateOrUpdateAppeal({
    serviceRequestId: serviceRequest.id,
    appealId: currentAppeal?.id ?? "",
  });

  const appealDecisionTimestamp = new Date().toISOString();
  const initialAppeal = appeals?.find(
    (appeal) => appeal.appealStatus === "IN_PROGRESS" && appeal.appealType === "APPEAL_INITIATION"
  );
  const initialAppealTime = initialAppeal?.appealInitiationTimestamp;
  const previousAuthStatus = serviceRequest?.authStatus;

  const getAppealTurnAroundTimeInMilliseconds = () => {
    if (initialAppealTime && appealDecisionTimestamp) {
      const initialAppealDate = new Date(initialAppealTime);
      const completionDate = new Date(appealDecisionTimestamp);
      if (isNaN(initialAppealDate.getTime()) || isNaN(completionDate.getTime())) {
        return undefined;
      }
      if (initialAppealDate > completionDate) {
        return 0;
      }
      const timeDifferenceMs = completionDate.getTime() - initialAppealDate.getTime();
      return timeDifferenceMs;
    }
  };
  const handleModalSubmission = async () => {
    setAttemptedSubmit(true);

    if (!isValidAppealNote) {
      return;
    }
    const { attachments, outcome, postAppealNote } = appealNoteFormState;
    let appealPayload: AppealPayload = {
      attachments: attachments,
      isManualAppeal: true,
      outcome,
      // Post appeals should have COMPLETE status, while initiations will be IN_PROGRESS
      appealStatus: "COMPLETE",
      healthPlanName: serviceRequest.healthPlanName,
      serviceRequest: {
        id: serviceRequest.id,
      },
      postAppealNote,
      appealType: currentAppeal?.appealType ?? "APPEAL_LOG", //if a appeal exist with appeal type then pass it on over here.
      appealDecisionTimestamp,
      previousAuthStatus,
      nextAuthStatus: outcome !== "NOT_COMPLETED" ? outcome : undefined,
      appealTurnAroundTimeInMilliseconds: getAppealTurnAroundTimeInMilliseconds(),
      appealDecisionedBy: userId,
    };
    if (appealNoteFormState.outcome === "PARTIALLY_APPROVED") {
      appealPayload = {
        ...appealPayload,
        approvedSemanticProcedureCodes: approvedProcedureCodes,
        approvedUnits: approvedUnits,
        patientStayDates: deAggregateStayDates(patientStayDateRanges),
      };
    }
    try {
      await createOrUpdateAppeal(appealPayload);
      enqueueSnackbar("Successfully logged post appeal note", { variant: "success" });
      navigateToPS(serviceRequest?.patient?.id ?? "", navigate, serviceRequest.id);
    } catch (error) {
      const errorAny: any = error;
      const message = errorAny?.data?.message;
      const embeddedError = errorAny?.data?._embedded?.errors?.map((error: any) => error?.message).join("\n");
      const errorMessage = (
        <Box>
          <Box>Error while saving appeal information</Box>
          {embeddedError ? <Box>{embeddedError}</Box> : message && <Box>{message}</Box>}
        </Box>
      );
      logError("Error while saving appeal information");
      logError(error);
      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
    }
  };

  const primaryButtonDisabled = !isValidAppealNote || createOrUpdateAppealLoading;

  return (
    <InformativeModal
      open={openSubmissionModal}
      onClose={() => {
        setOpenSubmissionModal(false);
      }}
      maxWidth="lg"
      hideX={createOrUpdateAppealLoading}
      headerText="Select post-appeal outcome"
      additionalInfoElement={
        <Grid container direction="column" alignItems="center" style={{ marginBlock: "32px" }}>
          <SingleSelectDropdown
            label="Post-appeal outcome"
            options={getReviewOutcomeOptions(serviceRequest) as any}
            value={appealNoteFormState.outcome}
            onChange={(postAppealOutcome) => {
              updateAppealNoteFormState({
                type: "updatePostAppealOutcome",
                value: postAppealOutcome as AppealNoteFormState["outcome"],
              });
            }}
            data-testid={"appeal-note-outcome-options"}
            error={attemptedSubmit && !Boolean(appealNoteFormState.outcome)}
            helperText={attemptedSubmit && !Boolean(appealNoteFormState.outcome) && "Required"}
          />
          {showFlexibleCodes && (
            <Grid style={{ marginTop: "16px" }}>
              <PartialApprovalFlexibleCodes
                data-testid={"modal-flexible-codes"}
                pxCodesByClinicalService={pxCodesByClinicalService}
                approvedUnitsByClinicalService={approvedUnitsByClinicalService}
                updateApprovedUnitsByCS={updateApprovedUnitsByCS}
                clinicalServices={clinicalServices?.filter(elementIsNonNull) ?? []}
                serviceRequestUnits={units ?? 0}
                approvedServiceRequestUnits={approvedUnits}
                healthPlanName={healthPlanName || ""}
                updateSinglePxCodeUnit={updateSinglePxCodeUnit}
              />
            </Grid>
          )}
          {showPatientStays && (
            <Grid style={{ marginTop: "32px", width: "100%" }}>
              <PatientStays
                data-testid={"review-modal-patient-stays"}
                version="DecisionStay"
                minimumFromDate={undefined}
                admissionDate={null}
                healthPlanName={healthPlanName || ""}
                patientStayDateRanges={patientStayDateRanges}
                setPatientStayDateRanges={setPatientStayDateRanges}
                patientStaysErrorStates={patientStaysErrorStates}
                isDischarged={patientStatus === "DISCHARGED"}
                attemptedSubmit={attemptedSubmit}
              />
            </Grid>
          )}
          <Checkbox
            style={{ marginTop: "32px", color: colorsLight.font.secondary }}
            checked={appealNoteFormState.attestationOfRequiredFileUpload}
            label="I attest that I have uploaded the required documentation for this post-appeal note"
            onChange={() => {
              updateAppealNoteFormState({ type: "updateAttestationOfRequiredFileUpload" });
            }}
          />
        </Grid>
      }
      showDivider={false}
      primaryButtonTestid="appeal-note-submission-save-button"
      primaryButtonText="Finish note"
      primaryButtonAction={handleModalSubmission}
      primaryButtonDisabled={primaryButtonDisabled}
      primaryButtonLoading={createOrUpdateAppealLoading}
    />
  );
};
