import React from "react";
import { H2 } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core";
import FullScreenDisplay, { FullScreenDisplayProps } from "./FullScreenDisplay";
import HeaderAndProgressBar from "../HeaderAndProgressBar";
import ServiceRequestWarning from "../../images/ServiceRequestWarning";

const useStyles = makeStyles((theme) => ({
  link: {
    marginTop: 0,
    padding: 0,
    ...theme.typography.subtitle2,
  },
  headerStyle: {
    width: "641px",
  },
}));

export default function OutOfScopeWarning() {
  const classes = useStyles();
  const fullScreenDisplayProps: FullScreenDisplayProps = {
    headerAndProgressBar: <HeaderAndProgressBar showProgressBar={false} />,
    visualComponent: <ServiceRequestWarning />,
    header: (
      <H2 className={classes.headerStyle}>
        Based on your answers, Cohere Health is not your delegated authorization portal
      </H2>
    ),
  };

  return <FullScreenDisplay {...fullScreenDisplayProps} />;
}
