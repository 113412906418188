import React, { Dispatch } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import VendorAssessmentQuestions from "./VendorAssessmentQuestions";
import VendorAssessmentCompleteCard from "./VendorAssessmentCompleteCard";
import { HealthHelpPASRequestResponse } from "@coherehealth/core-platform-api";
import VendorAssessmentFailed from "./VendorAssessmentFailed";

interface Props {
  delegatedVendorName?: string;
  cohereId?: string;
  healthHelpPASRequestResponse: HealthHelpPASRequestResponse | undefined;
  setWorkflowStep: () => void;
  questionnaireComplete: boolean;
  setQuestionnaireComplete: Dispatch<boolean>;
  serviceRequestId: string;
  vendorAssessmentError: boolean;
  setVendorAssessmentError: Dispatch<boolean>;
}
export default function VendorAssessmentContainer({
  delegatedVendorName,
  cohereId,
  healthHelpPASRequestResponse,
  setWorkflowStep,
  questionnaireComplete,
  setQuestionnaireComplete,
  serviceRequestId,
  vendorAssessmentError,
  setVendorAssessmentError,
}: Props) {
  const delegatedVendorDisplay = (delegatedVendor: string | undefined) => {
    return delegatedVendor === "HealthHelpV2" ? "HealthHelp" : delegatedVendor;
  };

  return (
    <PurpleContainer>
      {vendorAssessmentError || !healthHelpPASRequestResponse ? (
        <VendorAssessmentFailed delegatedVendor={delegatedVendorName} delegatedVendorDisplay={delegatedVendorDisplay} />
      ) : questionnaireComplete ? (
        <VendorAssessmentCompleteCard trackingNumber={cohereId} />
      ) : (
        <VendorAssessmentQuestions
          delegatedVendorName={delegatedVendorName}
          healthHelpPASRequestResponse={healthHelpPASRequestResponse}
          setQuestionnaireComplete={setQuestionnaireComplete}
          setWorkflowStep={setWorkflowStep}
          setQuestionnaireError={setVendorAssessmentError}
          serviceRequestId={serviceRequestId}
          delegatedVendorDisplay={delegatedVendorDisplay}
        />
      )}
    </PurpleContainer>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PurpleContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
  backgroundColor: "#EAE9F4",
  maxWidth: "902px",
  display: "block",
  alignSelf: "center",
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: "8px",
}));
