import { makeStyles, Theme } from "@material-ui/core/styles";
import { colorsDark, colorsLight } from "../../themes";

export interface CommandPaletteDropdownOption {
  label: string;
  id?: string;
  subCategory?: boolean;
  onClick?: () => void;
  system?: string;
  children?: CommandPaletteDropdownOption[];
  description?: string;
  parent?: string;
  color?: string;
  isEvaluator?: boolean;
}

export const paletteDropdownOrderer = (options: CommandPaletteDropdownOption[]) => {
  let result: CommandPaletteDropdownOption[] = [];
  const recursiveOrderer = (options: CommandPaletteDropdownOption[], parent?: CommandPaletteDropdownOption) => {
    options.forEach((item) => {
      if (!item.children?.length) {
        if (parent) {
          item.parent = parent.label;
          item.color = parent.color;
        }
        result.push(item);
      } else {
        recursiveOrderer(item.children, item);
      }
    });
  };
  recursiveOrderer(options);
  return result;
};

export const paletteSubCategoryDirectory = (options: CommandPaletteDropdownOption[]) => {
  let result: CommandPaletteDropdownOption[] = [];
  const recursiveOrderer = (options: CommandPaletteDropdownOption[]) => {
    options.forEach((item) => {
      item.subCategory && result.push(item);
      item.children?.length && recursiveOrderer(item.children);
    });
  };
  recursiveOrderer(options);
  return result;
};

export const useStylesCommandPalette = makeStyles((theme: Theme) => {
  const isDarkTheme = theme.palette.type === "dark";
  const colors = isDarkTheme ? colorsDark : colorsLight;
  return {
    inputRoot: {
      cursor: "default",
      zIndex: 9050,
      height: theme.spacing(5),
      "&.MuiFilledInput-root.MuiInputBase-root": {
        backgroundColor: isDarkTheme ? colors.background.card : colors.background.dark,
        paddingTop: 0,
        paddingBottom: 0,
        "&:hover,&.Mui-focused": {
          backgroundColor: isDarkTheme ? colors.background.card : colors.background.dark,
        },
      },
    },
    input: {
      "&.MuiInputBase-input::placeholder": {
        color: isDarkTheme ? "#7B8FAD" : colors.font.main,
        opacity: 1,
      },
    },
    paper: {
      margin: "-5px 0px 0",
      borderColor: isDarkTheme ? colors.background.card : colors.background.dark,
      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)",
      backgroundColor: isDarkTheme ? colors.background.cardLight : colors.white.highEmphasis,
    },
    listbox: {
      padding: 0,
      transition: theme.transitions.create("height"),
    },
    groupLabel: {
      border: "none",
      height: "34px",
      lineHeight: "34px",
      paddingLeft: theme.spacing(3),
      backgroundColor: isDarkTheme ? colors.background.card : colors.background.dark,
      color: isDarkTheme ? "#7B8FAD" : colors.font.main,
    },
    option: {
      height: "34px",
      overflow: "hidden",
      borderTop: `1px solid ${colors.gray.divider}`,
      "&:first-child": {
        borderTop: "none",
      },
      "&:last-child": {
        borderBottom: "none",
      },
    },
    popupIndicator: {
      "&.MuiAutocomplete-popupIndicatorOpen": {
        transform: "none",
      },
    },
  };
});
