import { colorsLight } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core";

export const usePatientSearchResultCardStyles = makeStyles((theme) => ({
  infoBar: {
    position: "absolute",
    width: "100%",
    height: 2,
    backgroundColor: theme.palette.info.light,
    top: 0,
    left: 0,
  },
  cardContainer: {
    padding: theme.spacing(2),
    width: "100%",
    background: colorsLight.white.highEmphasis,
    borderRadius: 8,
    display: "flex",
    justifyContent: "space-between",
  },
  paddedAlert: {
    flex: 1,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));
