import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { Checkbox, Sanitized, TextField, useFeature } from "@coherehealth/common";
import { Body1 } from "@coherehealth/design-system";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles(() => {
  return {
    box: {
      padding: "24px",
      background: "white",
      border: "1px solid transparent",
      borderRadius: "4px",
      boxShadow: "0px 2px 2px rgba(207, 226, 231, 0.8)",
      marginTop: "16px",
    },
    titleText: {
      fontSize: "18px",
      lineHeight: "20px",
      letterSpacing: "0.15px",
      fontFamily: "Gilroy-SemiBold",
    },
    bodyText: {
      color: "#5E6572",
      fontFamily: "Gilroy-Medium",
      fontSize: "15px",
      padding: "16px 0px",
    },
    hrSizing: {
      height: "1px",
      background: "#E5E5E5",
      marginBottom: "16px",
    },
    missingInfoCheckboxSection: {
      marginTop: "16px",
    },
    checkBoxMarginChange: {
      paddingRight: "8px",
    },
  };
});

type AttachmentNudgeReason = { ruleId?: string; reasonText?: string };

type RequiredDocsNudgeProps = {
  nudgeReasonText?: string;
  nudgeReasonTitle?: string;
  nudgeReasonID?: string;
  setReasons: (object: AttachmentNudgeReason, isChecked: boolean, childId?: string | undefined) => void;
  existingNudgeReason?: string;
  reasonAlreadyExists: boolean;
};

function RequiredDocsNudge({
  nudgeReasonText,
  nudgeReasonTitle,
  nudgeReasonID,
  setReasons,
  existingNudgeReason,
  reasonAlreadyExists,
}: RequiredDocsNudgeProps) {
  const classes = useStyles();
  const [infoMissing, setInfoMissing] = useState(reasonAlreadyExists);
  const [missingInfoReason, setMissingInfoReason] = useState(existingNudgeReason ?? "");
  const missingAttachmentInfoReasons = useFeature("missingAttachmentInfoReasons");
  const { spacing } = useTheme();
  return (
    <section className={classes.box}>
      <div className={classes.titleText}>
        {Boolean(nudgeReasonTitle) ? nudgeReasonTitle : "To avoid delays in decisioning, attachments should include"}
      </div>
      <Sanitized className={classes.bodyText} __html={nudgeReasonText} />
      {missingAttachmentInfoReasons && (
        <>
          <div className={classes.hrSizing}></div>
          <Checkbox
            style={{ marginTop: spacing(-1), marginBottom: spacing(-1) }}
            checked={infoMissing}
            onChange={() => {
              setInfoMissing((previousInfoMissingValue) => {
                const newBooleanValue = !Boolean(previousInfoMissingValue);
                setReasons({ ruleId: nudgeReasonID, reasonText: missingInfoReason }, newBooleanValue, nudgeReasonID);
                return newBooleanValue;
              });
            }}
            label={<Body1 color={"text.secondary"}>Some of this info is missing</Body1>}
          />
          {infoMissing && (
            <TextField
              className={classes.missingInfoCheckboxSection}
              multiline
              fullWidth
              label="Let us know why it's missing (optional)"
              value={missingInfoReason}
              onChange={async (event) => {
                setMissingInfoReason(event.target.value);
                setReasons({ ruleId: nudgeReasonID, reasonText: event.target.value }, true, nudgeReasonID);
              }}
            />
          )}
        </>
      )}
    </section>
  );
}

export default RequiredDocsNudge;
