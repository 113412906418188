import FullScreenDialog from "./FullScreenDialog";
import DenialLetterQueuedForDelivery from "components/images/DenialLetterQueuedForDelivery.svg";
import { routeToPatientSummaryFromReview } from "util/routeUtils/routeUtils";
import { useLocation } from "react-router-dom";
import { queueMgmtBaseUrl } from "@coherehealth/common";

interface Props {
  open: boolean;
  serviceRequestId: string;
  patientId: string;
}

const DenialQueuedForDeliveryDialog = ({ open, serviceRequestId, patientId }: Props) => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const urlCaseId = search.get("caseId");

  const redirectBackToOrigin = () => {
    const targetURL = urlCaseId
      ? `${queueMgmtBaseUrl()}/case_complete/${urlCaseId}`
      : routeToPatientSummaryFromReview({ serviceRequestId, patientId });
    window.location.assign(targetURL);
  };

  const redirectButtonLbl = urlCaseId ? "Back to My Work" : "Back to patient summary";

  return (
    <FullScreenDialog
      open={open}
      iconSrc={DenialLetterQueuedForDelivery}
      headingText="Denial letter queued for delivery"
      subHeaderText={
        <>
          Your denial letter will be sent to the <br></br>
          appropriate recipients shortly.
        </>
      }
      onPrimaryBottomButtonClick={redirectBackToOrigin}
      primaryBottomButtonText={redirectButtonLbl}
      slideDirection="up"
    />
  );
};

export default DenialQueuedForDeliveryDialog;
