import React, { SVGProps } from "react";

export default function PurpleClipboardBulb(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 40C0 17.9086 17.9086 0 40 0V0C62.0914 0 80 17.9086 80 40V40C80 62.0914 62.0914 80 40 80V80C17.9086 80 0 62.0914 0 40V40Z"
        fill="#D1C7E4"
      />
      <path d="M55.6013 23.175H23V61.1986H55.6013V23.175Z" fill="#A1AAB4" />
      <path d="M47.9131 25.4792H25.3066V58.8898H53.2814V30.861L47.9131 25.4792Z" fill="white" />
      <path
        d="M46.685 18.4665H42.3417C42.3417 16.5512 40.9794 15 39.2934 15C37.6073 15 36.245 16.5512 36.245 18.4665H31.9018L30.6743 26.1549H47.8989L46.685 18.4665Z"
        fill="#212936"
      />
      <path
        d="M33.8581 30.7825H31.5381V28.4625H29.9465V30.7825H27.6265V32.3741H29.9465V34.6941H31.5381V32.3741H33.8581V30.7825Z"
        fill="#EA1D63"
      />
      <path d="M46.5506 37.2708H32.0371V38.3634H46.5506V37.2708Z" fill="#89D3DD" />
      <path d="M50.597 54.7625H44.8105V55.8551H50.597V54.7625Z" fill="#89D3DD" />
      <path d="M50.5967 41.275H27.9902V42.3676H50.5967V41.275Z" fill="#C2C2C2" />
      <path d="M50.5967 44.5521H27.9902V45.6446H50.5967V44.5521Z" fill="#C2C2C2" />
      <path d="M50.5967 47.8333H27.9902V48.9259H50.5967V47.8333Z" fill="#C2C2C2" />
      <path d="M38.093 51.1083H27.9902V52.2009H38.093V51.1083Z" fill="#C2C2C2" />
      <path d="M47.9126 30.861H53.281L47.9126 25.4792V30.861Z" fill="#F5F5F5" />
      <circle cx="55" cy="27" r="12" fill="#55358F" />
      <path
        d="M52.75 33.75C52.75 34.1625 53.0875 34.5 53.5 34.5H56.5C56.9125 34.5 57.25 34.1625 57.25 33.75V33H52.75V33.75ZM55 19.5C52.105 19.5 49.75 21.855 49.75 24.75C49.75 26.535 50.6425 28.1025 52 29.055V30.75C52 31.1625 52.3375 31.5 52.75 31.5H57.25C57.6625 31.5 58 31.1625 58 30.75V29.055C59.3575 28.1025 60.25 26.535 60.25 24.75C60.25 21.855 57.895 19.5 55 19.5ZM57.1375 27.825L56.5 28.275V30H53.5V28.275L52.8625 27.825C51.85 27.12 51.25 25.9725 51.25 24.75C51.25 22.68 52.93 21 55 21C57.07 21 58.75 22.68 58.75 24.75C58.75 25.9725 58.15 27.12 57.1375 27.825Z"
        fill="white"
      />
    </svg>
  );
}
