import { Vendor } from "@coherehealth/core-platform-api";

export const OSCAR_HEALTH_PLAN_NAME: Vendor = "Oscar";
export const HUMANA_HEALTH_PLAN_NAME: Vendor = "Humana";
export const HEALTH_HELP_NAME: Vendor = "HealthHelp";
export const AGADIA_NAME: Vendor = "Agadia";
export const COHERE_NAME: Vendor = "Cohere";
export const GEISINGER_NAME: Vendor = "Geisinger";
export const HEALTH_HELP_V2_NAME: Vendor = "HealthHelpV2";
export const MEDICAL_MUTUAL_OF_OHIO_NAME: Vendor = "Medical Mutual of Ohio";
export const AVERA_HEALTH_PLANS: Vendor = "Avera Health Plans";
export const BLUECROSS_BLUESHIELD_OF_TENNESSEE: Vendor = "BlueCross BlueShield of Tennessee";

export const healthPlanNameMapping: Record<string, string> = {
  HUMANA: "Humana",
  GEISINGER: "Geisinger",
  HIGHMARK: "Highmark",
  BCBS_SOUTH_CAROLINA: "BCBS South Carolina",
  MEDICAL_MUTUAL_OF_OHIO: "Medical Mutual of Ohio",
  OSCAR: "Oscar",
  AVERA_HEALTH_PLANS: "Avera Health Plans",
  BLUECROSS_BLUESHIELD_OF_TENNESSEE: "BlueCross BlueShield of Tennessee",
};

// Health plan names are in SCREAMING_SNAKE_CASE, while SR and
// Auth names are space-separated. This converts the name so it can be used to
// query feature configs and other data from core-platform.
export const convertHealthPlanName = (name: string): string => {
  if (name in healthPlanNameMapping) {
    return healthPlanNameMapping[name];
  } else {
    return name.replace(/_/g, " ");
  }
};

export function healthPlanSupportsFaxes(healthPlanName: string | undefined) {
  return healthPlanName !== OSCAR_HEALTH_PLAN_NAME;
}

export const GEISINGER = "Geisinger";
export const HUMANA = "Humana";
export const OSCAR = "Oscar";
export const BCBS_SC = "BCBS South Carolina";
export const HIGHMARK = "Highmark";
export const MEDICAL_MUTUAL_OF_OHIO = "Medical Mutual of Ohio";
export const HEALTH_PARTNERS = "HealthPartners";
export const BCBS_TN = "BlueCross BlueShield of Tennessee";
export const HealthPlans = [
  GEISINGER,
  HUMANA,
  OSCAR,
  HIGHMARK,
  BCBS_SC,
  MEDICAL_MUTUAL_OF_OHIO,
  HEALTH_PARTNERS,
  AVERA_HEALTH_PLANS,
  BCBS_TN,
];

export const OPS_GROUP_TO_HEALTH_PLAN_NAME: Record<string, string[]> = {
  COHERE: [HUMANA, BCBS_SC, HIGHMARK, GEISINGER, HEALTH_PARTNERS, AVERA_HEALTH_PLANS, BCBS_TN],
  COHERE_ADMIN: [
    GEISINGER,
    HUMANA,
    OSCAR,
    HIGHMARK,
    BCBS_SC,
    MEDICAL_MUTUAL_OF_OHIO,
    HEALTH_PARTNERS,
    AVERA_HEALTH_PLANS,
    BCBS_TN,
  ],
  GEISINGER: [GEISINGER],
  HUMANA: [HUMANA],
  COHERE_CLINICAL_OPS: [HUMANA, BCBS_SC, HIGHMARK, GEISINGER, BCBS_TN],
  AVERA_HEALTH_PLANS: [AVERA_HEALTH_PLANS],
  HEALTHPARTNERS: [HEALTH_PARTNERS],
  BCBS_SC: [BCBS_SC],
  BCBS_TN: [BCBS_TN],
  MMO: [MEDICAL_MUTUAL_OF_OHIO],
};
