import React from "react";

import routes from "routes";
import { useMatch } from "react-router-dom";

import FaxAttachmentPageContent from "../FaxAttachmentPageContent";
import { NotFoundPage, useFeature } from "@coherehealth/common";

export default function FaxAuthBuilderPage() {
  const match = useMatch(routes.FAX_AUTH_BUILDER);

  const sideBySideInitialSrFaxIntakeWorkflowFeature = useFeature("sideBySideInitialSrFaxIntakeWorkflow");

  if (!sideBySideInitialSrFaxIntakeWorkflowFeature) {
    return <NotFoundPage />;
  }

  return <FaxAttachmentPageContent match={match} />;
}
