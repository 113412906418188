import {
  Attachment,
  IntegrationLog,
  ReferralRequestResponse,
  useGetReferralRequestIntegrationLogs,
} from "@coherehealth/core-platform-api";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SingleIntegration } from "../common/utils";
import { InlineButton, Modal } from "@coherehealth/common";
import { useSnackbar } from "notistack";
import { CircularProgress, useTheme } from "@material-ui/core";
import {
  IntegrationExecution,
  IntegrationType,
  IntegrationStatus as ObservabilityIntegrationStatus,
  useGetBulkAllIntegrationExecutionLogs,
  useGetIntegrationExecutionLogByIdAndIntegrationType,
} from "@coherehealth/int-obs-api";
import IntegrationLogDetailView from "components/ServiceRequest/ReadonlyDetail/IntegrationLogDetailView";
import config from "api/config";

interface Props {
  referralRequest: ReferralRequestResponse;
}

export default function IntegrationStatusSummary({ referralRequest }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [referralOutboundStatus, setReferralOutboundStatus] = useState<ObservabilityIntegrationStatus | undefined>(
    referralRequest.integrationStatus
  );
  const [attachmentStatus, setAttachmentStatus] = useState<ObservabilityIntegrationStatus | undefined>();
  const [attachments, setAttachments] = useState<Attachment[]>();

  const theme = useTheme();

  const { refetch: getIntegrationExecutionByIdAndIntegrationType } =
    useGetIntegrationExecutionLogByIdAndIntegrationType({
      base: `${config.INT_OBS_SERVICE_API_URL}`,
      objectId: referralRequest.id,
      queryParams: {
        integrationType: "REFERRALS_OUTBOUND",
      },
      lazy: true,
    });

  useEffect(() => {
    let attachmentCollection: Attachment[] = [];
    if (referralRequest.attachments && referralRequest.attachments.length > 0) {
      attachmentCollection = attachmentCollection.concat(referralRequest.attachments);
    }
    setAttachments(attachmentCollection);
  }, [referralRequest.attachments]);

  let attachmentsMap: { [key: string]: Attachment[] } = useMemo(() => {
    return {};
  }, []);

  attachments?.forEach((attachment) => {
    if (attachment?.integrationStatus) {
      if (attachmentsMap.hasOwnProperty(attachment.integrationStatus)) {
        attachmentsMap[attachment.integrationStatus].push(attachment);
      } else {
        attachmentsMap[attachment.integrationStatus] = [attachment];
      }
    }
  });

  /**
   * checkAttachmentMapAndUpdateAttachmentStatus: Checks and updates attachment status based on Attachment Map values
   */
  const checkAttachmentMapAndUpdateAttachmentStatus = useCallback(
    (
      attachmentsMap: {
        [key: string]: Attachment | IntegrationExecution[];
      },
      setAttachmentStatus: (value: React.SetStateAction<ObservabilityIntegrationStatus | undefined>) => void
    ): string | undefined => {
      let completeAttachments: any[] = [];
      if (attachmentsMap.hasOwnProperty("COMPLETE")) {
        completeAttachments = completeAttachments.concat(attachmentsMap["COMPLETE"]);
      }
      if (attachmentsMap.hasOwnProperty("INTERNALLY_COMPLETE")) {
        completeAttachments = completeAttachments.concat(attachmentsMap["INTERNALLY_COMPLETE"]);
      }

      let updatedAttachmentStatus: ObservabilityIntegrationStatus | undefined = undefined;
      if (attachmentsMap.hasOwnProperty("READY")) {
        updatedAttachmentStatus = "READY";
        setAttachmentStatus("READY");
      }

      if (attachmentsMap.hasOwnProperty("PROCESSING") || attachmentsMap.hasOwnProperty("REQUEST_ERROR")) {
        updatedAttachmentStatus = "PROCESSING";
        setAttachmentStatus("PROCESSING");
      }

      if (attachmentsMap.hasOwnProperty("FAILED")) {
        updatedAttachmentStatus = "FAILED";
        setAttachmentStatus("FAILED");
      }

      if (attachments?.length === completeAttachments.length) {
        updatedAttachmentStatus = "COMPLETE";
        setAttachmentStatus("COMPLETE");
      }

      if (attachmentsMap.hasOwnProperty("UNDELIVERABLE")) {
        updatedAttachmentStatus = undefined;
        setAttachmentStatus(undefined);
      }
      return updatedAttachmentStatus;
    },
    [attachments?.length]
  );

  /**
   * fetchAndSetIntegrationStatusForAttachments: Fetches from int observability service attachment status based on their ids and Sets Attachment Integration Status
   */
  const fetchAndSetIntegrationStatusForAttachments = useCallback(
    async (
      integrationType: IntegrationType,
      setIntegrationStatus: React.Dispatch<React.SetStateAction<ObservabilityIntegrationStatus | undefined>>
    ) => {
      let promises: Promise<IntegrationExecution | null>[] = [];
      attachments?.forEach((attachment) => {
        promises.push(
          getIntegrationExecutionByIdAndIntegrationType({
            pathParams: {
              objectId: attachment?.id,
            },
            queryParams: {
              integrationType: integrationType,
            },
          })
        );
      });
      const attachmentsResponseData = await Promise.all(promises);
      let intObsAttachmentsMap: { [key: string]: IntegrationExecution[] } = {};
      attachmentsResponseData.forEach((attachmentResponseData) => {
        if (attachmentResponseData?.status && intObsAttachmentsMap.hasOwnProperty(attachmentResponseData.status)) {
          intObsAttachmentsMap[attachmentResponseData.status].push(attachmentResponseData);
        } else if (attachmentResponseData?.status) {
          intObsAttachmentsMap[attachmentResponseData.status] = [attachmentResponseData];
        }
      });
      checkAttachmentMapAndUpdateAttachmentStatus(intObsAttachmentsMap, setIntegrationStatus);
    },
    [attachments, checkAttachmentMapAndUpdateAttachmentStatus, getIntegrationExecutionByIdAndIntegrationType]
  );

  const fetchAndSetIntegrationStatus = useCallback(
    async (
      integrationType: IntegrationType,
      setIntegrationStatus: React.Dispatch<React.SetStateAction<ObservabilityIntegrationStatus | undefined>>,
      objectId: string
    ) => {
      try {
        if (integrationType === "ATTACHMENTS") {
          fetchAndSetIntegrationStatusForAttachments(integrationType, setIntegrationStatus);
        } else {
          const responseData = await getIntegrationExecutionByIdAndIntegrationType({
            pathParams: {
              objectId: referralRequest.id,
            },
            queryParams: {
              integrationType: integrationType,
            },
          });
          setIntegrationStatus(responseData?.status);
        }
      } catch (error) {
        setIntegrationStatus("REQUEST_ERROR");
        enqueueSnackbar(`Failed to get ${integrationType} from int observability service`, {
          variant: "error",
          preventDuplicate: true,
        });
      }
    },
    [
      enqueueSnackbar,
      getIntegrationExecutionByIdAndIntegrationType,
      referralRequest.id,
      fetchAndSetIntegrationStatusForAttachments,
    ]
  );

  useEffect(() => {
    if (attachments) {
      const updatedAttachmentStatus = checkAttachmentMapAndUpdateAttachmentStatus(attachmentsMap, setAttachmentStatus);
      if (!updatedAttachmentStatus) {
        fetchAndSetIntegrationStatus("ATTACHMENTS", setAttachmentStatus, referralRequest?.id);
      }
    }
  }, [
    attachments,
    attachmentsMap,
    checkAttachmentMapAndUpdateAttachmentStatus,
    fetchAndSetIntegrationStatus,
    referralRequest?.id,
  ]);

  useEffect(() => {
    if (referralRequest?.id && !referralOutboundStatus) {
      fetchAndSetIntegrationStatus("REFERRALS_OUTBOUND", setReferralOutboundStatus, referralRequest?.id);
    }
  }, [fetchAndSetIntegrationStatus, referralRequest?.id, referralOutboundStatus]);

  const [expanded, setIsExpanded] = useState<boolean>();

  return (
    <>
      <SingleIntegration status={referralOutboundStatus} name="Payor" />
      <SingleIntegration status={referralRequest.integration?.matrix?.status} name="Mail vendor" />
      <SingleIntegration status={referralRequest.integration?.salesforce?.status} name="Salesforce" />
      <SingleIntegration status={attachmentStatus} name="Attachments" />
      <br />
      <InlineButton
        onClick={() => {
          setIsExpanded((prev) => !prev);
        }}
        style={{ paddingTop: theme.spacing(2), paddingBottom: 0 }}
      >
        Integration log details
      </InlineButton>
      <Modal
        open={Boolean(expanded)}
        onClose={() => {
          setIsExpanded(false);
        }}
      >
        {!!expanded && (
          <IntegrationLogDetails
            referralRequestId={referralRequest.id}
            referralRequestAttachmentIds={referralRequest?.attachments?.map((attachment) => attachment?.id) ?? []}
          />
        )}
      </Modal>
    </>
  );
}
interface LogsComponentProps {
  referralRequestId: string;
  referralRequestAttachmentIds: string[];
}
function IntegrationLogDetails({ referralRequestId, referralRequestAttachmentIds }: LogsComponentProps) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: integrationLogsData,
    loading: integrationLogsLoading,
    error: integrationLogsError,
  } = useGetReferralRequestIntegrationLogs({ id: referralRequestId });

  const {
    data: intObservabilityLogsData,
    loading: intObservabilityLogsLoading,
    error: intObservabilityLogsError,
  } = useGetBulkAllIntegrationExecutionLogs({
    base: `${config.INT_OBS_SERVICE_API_URL}`,
    queryParams: {
      objectIds: [referralRequestId, ...referralRequestAttachmentIds].join(","),
    },
  });
  useEffect(() => {
    if (integrationLogsError) {
      enqueueSnackbar(`Failed to log integration log details: ${integrationLogsError.message}`, { variant: "error" });
    }
    if (intObservabilityLogsError) {
      enqueueSnackbar(`Failed to log integration log details: ${intObservabilityLogsError.message}`, {
        variant: "error",
      });
    }
  }, [intObservabilityLogsError, enqueueSnackbar, integrationLogsError]);

  if (integrationLogsLoading || intObservabilityLogsLoading) {
    return <CircularProgress />;
  }
  let logs: (IntegrationExecution | IntegrationLog)[] = [];
  if (integrationLogsData && integrationLogsData.logs) {
    logs = [...logs, ...integrationLogsData.logs];
  }
  if (intObservabilityLogsData) {
    logs = [...logs, ...intObservabilityLogsData];
  }
  return <IntegrationLogDetailView logs={logs} />;
}
