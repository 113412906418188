import {
  KafkaMessageDocumentResponse,
  ReferralRequestResponse,
  useMarkReferralIntegrationStatus,
} from "@coherehealth/core-platform-api";
import { H6 } from "@coherehealth/design-system";
import { useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { PrimaryButton, SingleSelectDropdown } from "@coherehealth/common";
import React, { useEffect, useState } from "react";
import {
  IntegrationStatuses,
  IntObsIntegrationStatuses,
} from "../ServiceRequestIntegrationSummary/EditIntegrationStatus";
import { useRetryKafkaBasedIntegration as useObsRetryKafkaBasedIntegration } from "@coherehealth/int-obs-api";
import config from "../../../api/config";
import { useSnackbar } from "notistack";

interface Props {
  referralRequest: ReferralRequestResponse;
  onEdit: (id: string) => void;
  mailVendorKafkaLogs?: KafkaMessageDocumentResponse[];
}

export default function ReferralRequestEditIntegrationStatus({ referralRequest, onEdit, mailVendorKafkaLogs }: Props) {
  const [integrationType, setIntegrationType] = useState<IntegrationType>();
  const [integrationStatus, setIntegrationStatus] = useState<string>("");
  const [showHelperTextForIntegrationType, setShowHelperTextForIntegrationType] = useState<boolean>(false);

  const salesforceIntegrationWhiteList = ["Humana"];
  const shouldNotIntegratedWithSalesforce = !salesforceIntegrationWhiteList.includes(
    referralRequest.healthPlanName ?? ""
  );
  const helperTextForIntegrationType =
    "Referrals for this health plan are not usually integrated to Salesforce. If you reset the Salesforce integration, this auth will be added to Salesforce";

  const theme = useTheme();

  const {
    mutate: submitCorePlatformRetry,
    loading: submittingCorePlatformRetry,
    error: submitCorePlatformRetryError,
  } = useMarkReferralIntegrationStatus({
    id: referralRequest.id,
  });

  const { mutate: obsRetryKafkaBasedIntegration } = useObsRetryKafkaBasedIntegration({
    base: `${config.INT_OBS_SERVICE_API_URL}`,
    objectId: "",
  });

  const getOptions = () => {
    if (integrationType === "payor" && !referralRequest?.integrationStatus) {
      return Object.entries(IntObsIntegrationStatuses).map(([id, label]) => ({ id, label }));
    } else {
      return Object.entries(IntegrationStatuses).map(([id, label]) => ({ id, label }));
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (submitCorePlatformRetryError) {
      enqueueSnackbar("Error updating the integration status, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [enqueueSnackbar, submitCorePlatformRetryError]);

  const onSubmitCorePlatformRetry =
    integrationType && integrationStatus
      ? async (referralsRequest: ReferralRequestResponse) => {
          if (integrationType === "attachments") {
            referralRequest.attachments?.forEach(async (file) => {
              await obsRetryKafkaBasedIntegration(
                {
                  integrationType: "ATTACHMENTS",
                  integrationStatus: "READY",
                },
                { pathParams: { objectId: file.id } }
              );
            });
          } else if (integrationType === "payor" && !referralsRequest?.integrationStatus) {
            await obsRetryKafkaBasedIntegration(
              {
                integrationStatus: "READY",
                integrationType: "REFERRALS_OUTBOUND",
              },
              { pathParams: { objectId: referralsRequest?.id } }
            );
          } else {
            await submitCorePlatformRetry({
              integrationType: integrationType,
              integrationStatus: integrationStatus,
            });
          }
          onEdit(referralRequest.id);
        }
      : undefined;

  return (
    <div>
      <H6 style={{ paddingTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>Set the Integration Status</H6>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <SingleSelectDropdown
            label="Integration Type"
            value={integrationType || ""}
            onChange={(type) => {
              setShowHelperTextForIntegrationType(shouldNotIntegratedWithSalesforce && type === "salesforce");
              assertIsIntegrationType(type);
              setIntegrationType(type);
            }}
            options={integrationTypeOptionsList}
            optionIdsWithWarning={shouldNotIntegratedWithSalesforce ? ["salesforce"] : []}
            warningTextForOptions={helperTextForIntegrationType}
            helperText={
              showHelperTextForIntegrationType && (
                <div style={{ color: theme.palette.error.main }}>{helperTextForIntegrationType}</div>
              )
            }
            dataPublic={true}
          />
        </Grid>
        <Grid item xs={4}>
          <SingleSelectDropdown
            label="Set status to"
            value={integrationStatus}
            onChange={setIntegrationStatus}
            options={getOptions()}
            dataPublic={true}
          />
        </Grid>
        <Grid item xs={4}>
          <PrimaryButton
            onClick={() => onSubmitCorePlatformRetry?.(referralRequest)}
            loading={submittingCorePlatformRetry}
          >
            submit
          </PrimaryButton>
        </Grid>
      </Grid>
    </div>
  );
}

type IntegrationType =
  | keyof NonNullable<ReferralRequestResponse["integration"]>
  | "payor"
  | "salesforce"
  | "attachments";

type IntegrationInfrastructure = "Core-Platform" | "Kafka";

const IntegrationInfrastructureMapping: Record<IntegrationType, IntegrationInfrastructure> = {
  payor: "Core-Platform",
  salesforce: "Core-Platform",
  matrix: "Core-Platform",
  attachments: "Kafka",
};

const IntegrationTypeOptions: Record<IntegrationType, string> = {
  payor: "Payor",
  salesforce: "Salesforce",
  matrix: "Mail vendor",
  attachments: "Attachments",
};

let integrationTypeOptionsList = Object.entries(IntegrationTypeOptions).map(([id, label]) => ({ id, label }));

function assertIsIntegrationType(type: string): asserts type is IntegrationType {
  if (!Object.keys(IntegrationInfrastructureMapping).includes(type)) {
    throw new Error("Invalid integration type");
  }
}
