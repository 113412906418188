import React, { useEffect, useState, useContext, SetStateAction, Dispatch } from "react";

import { Body2, SelectOptionsHook, SingleSelect, leftRailWidth } from "@coherehealth/common";
import { User, UserContext } from "UserContext";

import {
  ServiceRequestSearchRequestBody,
  ServiceRequestUserInfo,
  useGetServiceRequestUsers,
} from "@coherehealth/core-platform-api";
import { useTheme } from "@material-ui/core";

interface Props {
  queryParams: ServiceRequestSearchRequestBody;
  setQueryParams: Dispatch<SetStateAction<ServiceRequestSearchRequestBody>>;
}

interface UserOption extends ServiceRequestUserInfo {
  id: string | undefined;
  label: string | undefined;
  desc: string | undefined;
}

function formatServiceRequestUsers(
  data: ServiceRequestUserInfo[] | null,
  currUser: User | undefined,
  query: string | undefined
): UserOption[] | undefined {
  let serviceRequestCount = 0;

  const currentUser = data?.find((user) => user?.userId === currUser?.sub);
  let formattedData = data
    ?.map((user) => {
      serviceRequestCount += user?.serviceRequestCount || 0;
      return {
        id: user?.userId || "",
        label: `${user?.userName} (${currUser?.sub === user.userId ? "You" : user?.serviceRequestCount})`,
        desc: `${user?.userName} (${user?.serviceRequestCount}) ${currUser?.sub === user.userId ? "(You)" : ""}`,
      };
    })
    .filter((user) => {
      if (!query) {
        return user?.id !== currUser?.sub;
      } else {
        return user;
      }
    });

  if (!query) {
    formattedData?.unshift({
      id: currUser?.sub || "",
      label: `${currUser?.name} (You)`,
      desc: `${currUser?.name} (${currentUser ? currentUser?.serviceRequestCount : 0}) (You)`,
    });
    formattedData?.unshift({
      id: "View All Id",
      label: `View All (${serviceRequestCount})`,
      desc: `View All (${serviceRequestCount})`,
    });
  }

  return formattedData;
}

const useServiceRequestUsers: SelectOptionsHook<UserOption> = ({ query, onError }) => {
  const { data, loading, error } = useGetServiceRequestUsers({
    debounce: 600,
    queryParams: {
      query: query,
      max: 50,
    },
  });
  const { getUser } = useContext(UserContext);
  const [currUser, setCurrentUser] = useState<User>();

  useEffect(() => {
    getUser()?.then((currentUser) => {
      setCurrentUser(currentUser);
    });
  }, [getUser]);

  useEffect(() => {
    if (error) {
      onError(error);
    }
  }, [error, onError]);

  return {
    options: formatServiceRequestUsers(data, currUser, query) || undefined,
    optionsLoading: loading,
  };
};

export default function ServiceRequestSearchByUser({ queryParams, setQueryParams }: Props) {
  let options: ReturnType<typeof useServiceRequestUsers>["options"] = [];
  const useServiceRequestUsersWrapper: typeof useServiceRequestUsers = (params) => {
    const returnVal = useServiceRequestUsers(params);

    // when options are loaded, set the matching option as userSelected
    options = returnVal["options"];
    if (options && options.length > 0 && !userSelected && typeof queryParams?.createdBy === "string") {
      setUserSelected(options.find(({ id }) => id === (queryParams?.createdBy as string).substring(3)) || null);
    }
    return returnVal;
  };
  const [userSelected, setUserSelected] = useState<UserOption | null>(null);
  const { spacing } = useTheme();

  return (
    <SingleSelect<UserOption>
      label={"Filter by user"}
      renderOption={(option: any) => <Body2>{option.desc}</Body2>}
      markSelectedOptions
      useOptions={useServiceRequestUsersWrapper}
      getOptionLabel={(user) => user?.label || "No Name"}
      disablePortal
      clearable
      clearOnBlur
      handleHomeEndKeys
      selectedValue={userSelected || null}
      setSelectedValue={(val) => {
        setUserSelected(val);
        const offsetOrNull = { offset: 0 };
        let newQueryParams = { ...queryParams, ...offsetOrNull };
        if (val?.id) {
          if (val.id === "View All Id") {
            delete newQueryParams.createdBy;
          } else {
            newQueryParams.createdBy = `eq:${val.id}`;
          }
        } else {
          delete newQueryParams.createdBy;
        }
        setQueryParams(newQueryParams);
      }}
      data-public
      style={{ width: leftRailWidth, marginTop: spacing(4) }}
    />
  );
}
