import React from "react";
import {
  MdReview,
  NurseReview,
  PeerToPeerReview,
  PreferredLabelFeedback,
  ProcedureCode,
  ServiceRequestResponse,
} from "@coherehealth/core-platform-api";
import { DividerContainer } from "../Accordion/Accordion";
import { SimulatedDenialLetterDivider, displayProcedureCodePreferredLabel } from "./DenialLetterUtil";
import { ReadOnlyLetterText } from "./DenialLetterHeader";
import { ButtonBase, makeStyles, useTheme } from "@material-ui/core";
import { Body1, Body2, Caption } from "../Typography";
import PencilIcon from "@material-ui/icons/Create";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { PrimaryButton, TertiaryButton, TextField } from "../../components";

interface DenialLetterProcedureCodePopulateProps {
  serviceRequest: ServiceRequestResponse | null;
  review: MdReview | PeerToPeerReview | NurseReview | null;
  setIsValidDenialLetterProcedureCodePopulate: Dispatch<SetStateAction<boolean>>;
  isPreviewLetterButtonClicked: boolean;
  setIsPreviewLetterButtonClicked: Dispatch<SetStateAction<boolean>>;
  setPreferredLabelFeedback: Dispatch<SetStateAction<PreferredLabelFeedback[]>>;
}

interface ProcedureCodeLetterDisplayProps {
  serviceRequest: ServiceRequestResponse | null;
  review: MdReview | PeerToPeerReview | NurseReview | null;
  setIsValidDenialLetterProcedureCodePopulate: Dispatch<SetStateAction<boolean>>;
  isPreviewLetterButtonClicked: boolean;
  setIsPreviewLetterButtonClicked: Dispatch<SetStateAction<boolean>>;
  setPreferredLabelFeedback: Dispatch<SetStateAction<PreferredLabelFeedback[]>>;
}

interface EachProcedureCodeLetterDisplayProps {
  item: ProcedureCode;
  index: number;
  review: MdReview | PeerToPeerReview | NurseReview | null;
  isPreviewLetterButtonClicked: boolean;
  setIsPreviewLetterButtonClicked: Dispatch<SetStateAction<boolean>>;
  handleProcedureCodeLabelValidation: (procedureCodeIndex: number, isProcedureCodeLabelValid: boolean) => void;
  handleEditingProcedureCodeLabelValidation: (procedureCodeIndex: number, isEditingLabel: boolean) => void;
  handleCurrentPreferredLabelFeedbacksUpdate: (
    procedureCodeIndex: number,
    preferredLabelFeedback: PreferredLabelFeedback
  ) => void;
}

interface EditProcedureCodeLetterDescriptionProps {
  preferredLabel: string | undefined;
  setCurrentPreferredLabel: Dispatch<SetStateAction<string | undefined>>;
  validateProcedureCodeLabel: (val: boolean) => void;
  isEditingProcedureCodeLabel: (val: boolean) => void;
}

export function DenialLetterProcedureCodePopulate({
  serviceRequest,
  review,
  setIsValidDenialLetterProcedureCodePopulate,
  isPreviewLetterButtonClicked,
  setIsPreviewLetterButtonClicked,
  setPreferredLabelFeedback,
}: DenialLetterProcedureCodePopulateProps) {
  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "column", width: "100%", marginTop: "-12px", marginBottom: "12px" }}
      >
        <ReadOnlyLetterText style={{ fontSize: "13px" }}>PROCEDURE CODES</ReadOnlyLetterText>
        <ProcedureCodeLetterDisplay
          serviceRequest={serviceRequest}
          review={review}
          setIsValidDenialLetterProcedureCodePopulate={setIsValidDenialLetterProcedureCodePopulate}
          isPreviewLetterButtonClicked={isPreviewLetterButtonClicked}
          setIsPreviewLetterButtonClicked={setIsPreviewLetterButtonClicked}
          setPreferredLabelFeedback={setPreferredLabelFeedback}
        />
      </div>
      <DividerContainer>
        <SimulatedDenialLetterDivider />
      </DividerContainer>
    </>
  );
}

export const ProcedureCodeLetterDisplay = ({
  serviceRequest,
  review,
  setIsValidDenialLetterProcedureCodePopulate,
  isPreviewLetterButtonClicked,
  setIsPreviewLetterButtonClicked,
  setPreferredLabelFeedback,
}: ProcedureCodeLetterDisplayProps) => {
  // Set current preferred label feedback
  const [currentPreferredLabelFeedbacks, setCurrentPreferredLabelFeedbacks] = useState<PreferredLabelFeedback[]>([]);
  const handleCurrentPreferredLabelFeedbacksUpdate = useCallback((procedureCodeIndex, preferredLabelFeedback) => {
    setCurrentPreferredLabelFeedbacks((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates[procedureCodeIndex] = preferredLabelFeedback;
      return updatedStates;
    });
  }, []);
  setPreferredLabelFeedback(currentPreferredLabelFeedbacks);

  // Validation: if non-empty procedure code labels
  const [validProcedureCodeLabels, setValidProcedureCodeLabels] = useState<boolean[] | undefined>(
    serviceRequest?.semanticProcedureCodes?.map((item) =>
      !item?.preferredLabel || item?.preferredLabel?.trim().length === 0 ? false : true
    )
  );
  const handleProcedureCodeLabelValidation = (procedureCodeIndex: number, isProcedureCodeLabelValid: boolean) => {
    setValidProcedureCodeLabels((prevStates) => {
      if (prevStates === undefined) {
        prevStates = [];
      }
      const updatedStates = [...prevStates];
      updatedStates[procedureCodeIndex] = isProcedureCodeLabelValid;
      return updatedStates;
    });
  };

  // Validation: if procedure code label(s) is being edited
  const [isEditingProcedureCodeLabels, setIsEditingProcedureCodeLabels] = useState<boolean[]>(
    new Array(serviceRequest?.semanticProcedureCodes?.length).fill(false)
  );
  const handleEditingProcedureCodeLabelValidation = (procedureCodeIndex: number, isEditingLabel: boolean) => {
    setIsEditingProcedureCodeLabels((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates[procedureCodeIndex] = isEditingLabel;
      return updatedStates;
    });
  };

  // Check if at least one procedure code label is invalid
  const isAtLeastOneProcedureCodeLabelInvalid = validProcedureCodeLabels?.some((isValid) => !isValid);
  // Check if at least one procedure code label is being edited
  const isAtLeastOneProcedureCodeLabelBeingEdited = isEditingProcedureCodeLabels.some(
    (isEditingLabel) => isEditingLabel
  );

  // If at least 1 procedure code label is invalid or being edited,
  // meaning the denial letter procedure code populate is invalid
  setIsValidDenialLetterProcedureCodePopulate(
    !(isAtLeastOneProcedureCodeLabelInvalid || isAtLeastOneProcedureCodeLabelBeingEdited)
  );
  return (
    <div>
      {serviceRequest?.semanticProcedureCodes?.map((item, index) => (
        <EachProcedureCodeLetterDisplay
          key={index}
          item={item}
          index={index}
          review={review}
          isPreviewLetterButtonClicked={isPreviewLetterButtonClicked}
          setIsPreviewLetterButtonClicked={setIsPreviewLetterButtonClicked}
          handleProcedureCodeLabelValidation={handleProcedureCodeLabelValidation}
          handleEditingProcedureCodeLabelValidation={handleEditingProcedureCodeLabelValidation}
          handleCurrentPreferredLabelFeedbacksUpdate={handleCurrentPreferredLabelFeedbacksUpdate}
        />
      ))}
    </div>
  );
};

export const EachProcedureCodeLetterDisplay = ({
  item,
  index,
  review,
  isPreviewLetterButtonClicked,
  setIsPreviewLetterButtonClicked,
  handleProcedureCodeLabelValidation,
  handleEditingProcedureCodeLabelValidation,
  handleCurrentPreferredLabelFeedbacksUpdate,
}: EachProcedureCodeLetterDisplayProps) => {
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentPreferredLabel, setCurrentPreferredLabel] = useState<string | undefined>(item.preferredLabel);
  const [isThisProcedureCodeLabelValid, setIsThisProcedureCodeLabelValid] = useState<boolean>(
    !!item?.preferredLabel && item.preferredLabel?.trim().length !== 0
  );

  const validateProcedureCodeLabel = (val: boolean) => {
    setIsThisProcedureCodeLabelValid(val);
    handleProcedureCodeLabelValidation(index, val);
  };

  const isEditingProcedureCodeLabel = (val: boolean) => {
    setIsEditing(val);
    handleEditingProcedureCodeLabelValidation(index, val);
  };

  useEffect(() => {
    const data = {
      code: item.code,
      system: item.system,
      serviceRequestId: review?.serviceRequest?.id,
      reviewId: review?.id,
      description: item.description,
      preferredLabel: item.preferredLabel,
      submittedPreferredLabel: currentPreferredLabel,
      wasEdited: currentPreferredLabel !== item.preferredLabel,
      username: "",
    };
    handleCurrentPreferredLabelFeedbacksUpdate(index, data);
  }, [currentPreferredLabel, handleCurrentPreferredLabelFeedbacksUpdate, index, item, review]);

  return (
    <>
      <div
        key={index}
        style={{
          background: "#EAE9F4",
          display: "flex",
          flexGrow: 1,
          marginBottom: theme.spacing(1),
          marginTop: theme.spacing(1),
          padding: theme.spacing(1, 1, 1, 1),
          borderRadius: "4px",
          alignItems: "flex-start",
        }}
      >
        <div style={{ display: isEditing ? "block" : "inline-flex", width: "100%" }}>
          <Body2 style={{ color: "#55358F" }}>{item.code}</Body2>
          {isEditing ? (
            <EditProcedureCodeLetterDescription
              preferredLabel={currentPreferredLabel}
              setCurrentPreferredLabel={setCurrentPreferredLabel}
              validateProcedureCodeLabel={validateProcedureCodeLabel}
              isEditingProcedureCodeLabel={isEditingProcedureCodeLabel}
            />
          ) : (
            <Body1 style={{ marginLeft: "10px", marginRight: "10px" }}>
              {displayProcedureCodePreferredLabel(currentPreferredLabel)}
            </Body1>
          )}
        </div>
        {!isEditing && (
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ButtonBase
              data-testid="editing-Px-code"
              style={{ fontSize: "16px", height: "10px", color: "#55358F" }}
              onClick={(event) => {
                event.stopPropagation();
                setIsPreviewLetterButtonClicked(false);
                isEditingProcedureCodeLabel(true);
              }}
            >
              <PencilIcon fontSize="inherit" />
            </ButtonBase>
          </div>
        )}
      </div>
      {(!isThisProcedureCodeLabelValid || isEditing) && isPreviewLetterButtonClicked && (
        <Caption style={{ color: "#E53935" }}>
          {isEditing ? "Description must be saved to preview letter" : "Procedure code description is required"}
        </Caption>
      )}
    </>
  );
};

export const EditProcedureCodeLetterDescription = ({
  preferredLabel,
  setCurrentPreferredLabel,
  validateProcedureCodeLabel,
  isEditingProcedureCodeLabel,
}: EditProcedureCodeLetterDescriptionProps) => {
  const theme = useTheme();
  const [editedPreferredLabel, setEditedPreferredLabel] = useState(preferredLabel);
  const useStyles = makeStyles((theme) => ({
    tertiaryButton: {
      width: "86px",
      height: "32px",
      marginRight: theme.spacing(1),
      color: "#55358F",
      "&:hover": {
        backgroundColor: "rgba(129, 98, 185, 0.08)",
      },
    },
  }));
  const classes = useStyles({});

  return (
    <div style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(0.5) }}>
      <TextField
        fullWidth={true}
        value={editedPreferredLabel}
        onChangeValue={(label) => setEditedPreferredLabel(label.replaceAll("\n", ""))}
        multiline
        noLabelSpace
        style={{ marginBottom: theme.spacing(1), width: "100%" }}
      />
      <div style={{ justifyContent: "end", display: "flex" }}>
        <TertiaryButton
          className={classes.tertiaryButton}
          onClick={() => {
            setEditedPreferredLabel(preferredLabel);
            isEditingProcedureCodeLabel(false);
          }}
        >
          Cancel
        </TertiaryButton>
        <PrimaryButton
          style={{ width: "70px", height: "32px", backgroundColor: "#55358F" }}
          onClick={() => {
            setCurrentPreferredLabel(editedPreferredLabel);
            if (!editedPreferredLabel || editedPreferredLabel?.trim().length === 0) {
              validateProcedureCodeLabel(false);
            } else {
              validateProcedureCodeLabel(true);
            }
            isEditingProcedureCodeLabel(false);
          }}
        >
          Save
        </PrimaryButton>
      </div>
    </div>
  );
};
