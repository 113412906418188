import {
  Checkbox,
  DateSelect,
  RichTextEditor,
  SingleSelectDropdown,
  TextField,
  themeLight,
  colorsLight,
} from "@coherehealth/common";
import { Stack } from "@mui/material";
import { Box } from "@material-ui/core";
import { NoteConfigurationResponse, NoteType } from "@coherehealth/core-platform-api";
import { NoteEditState } from "./NewNote";
import { configToDropDownOption } from "components/ClinicalReview/reviewUtils/utils";
import { useState } from "react";

interface Props {
  currentNote: NoteEditState;
  setCurrentNote: React.Dispatch<React.SetStateAction<NoteEditState>>;
  savingNote?: boolean;
  noteConfig?: NoteConfigurationResponse;
}

export type AppealsType = "MEMBER" | "PROVIDER";
export type LivantaType = "GEISINGER" | "PROVIDER";
type AppealTypeOptions = { id: AppealsType; label: string }[];
type LivantaTypeOptions = { id: LivantaType; label: string }[];

type NoteTypeOptions = { id: NoteType; label: string }[];

const NewNoteForm = ({ currentNote, setCurrentNote, savingNote, noteConfig }: Props) => {
  const { noteTypeOptions, dSnpChCodeOptions, dSnpMcoConfirmationOptions } = noteConfig || {};

  const getNoteTypeOptions = () => {
    if (noteTypeOptions) {
      const configAsDropdownOptions = noteTypeOptions.map((noteType) => {
        return { id: noteType, label: noteTypeLabel(noteType) };
      });
      return configAsDropdownOptions;
    }

    return defaultNoteTypeOptions;
  };

  const getAppealTypeOptions = () => {
    return defaultAppealTypeOptions;
  };

  const getLivantaTypeOptions = () => {
    return defaultLivantaTypeOptions;
  };

  const getDSnpChCodeOptions = () => {
    if (dSnpChCodeOptions) {
      return configToDropDownOption(dSnpChCodeOptions);
    }
  };

  const getDSnpMcoConfirmationOptions = () => {
    if (dSnpMcoConfirmationOptions) {
      return configToDropDownOption(dSnpMcoConfirmationOptions);
    }
  };

  const [startDate, setStartDate] = useState<Date>();

  const handleDateChange = (date: Date) => {
    setStartDate(date);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" padding="1.5em">
      <Box width="100%" mb="16px">
        <SingleSelectDropdown
          label="Note type"
          options={getNoteTypeOptions()}
          value={currentNote.noteType}
          onChange={(newNoteType) =>
            setCurrentNote({
              ...currentNote,
              noteType: newNoteType,
            })
          }
          data-testid="new-note-form--note-type-dropdown"
        />
      </Box>

      {currentNote.noteType === "ADMINISTRATIVE" && (
        <Box width="100%">
          <RichTextEditor
            readonly={!!savingNote}
            htmlValue={currentNote.noteContent || ""}
            setHtmlValue={(newNoteContent) =>
              setCurrentNote((currentNote) => ({ ...currentNote, noteContent: newNoteContent }))
            }
            label="Administrative note"
            testid="new-note-form--admin-text-editor"
          />
        </Box>
      )}
      {currentNote.noteType === "SNF_THREE_DAY_WAIVER_NOTE" && (
        <Box width="100%">
          <Checkbox
            onChange={(e) =>
              setCurrentNote((currentNote) => ({
                ...currentNote,
                hasSNFWaiver: e.valueOf(),
              }))
            }
            label="This request has a SNF 3-day waiver"
            style={{ color: themeLight.palette.text.secondary }}
            data-testid="new-note-form--snf-3-day-waiver-checkbox"
          />
        </Box>
      )}
      {currentNote.noteType === "D_SNP" && (
        <>
          <Box width="100%" mb="16px">
            <SingleSelectDropdown
              label="D-SNP CH Code"
              options={getDSnpChCodeOptions()}
              value={currentNote.dSnpChCode}
              onChange={(dSnpChCode) => setCurrentNote((currentNote) => ({ ...currentNote, dSnpChCode }))}
              data-testid="new-note-form--dsnp-ch-code"
            />
          </Box>
          <Box width="100%" mb="16px">
            <SingleSelectDropdown
              label="D-SNP MCO Confirmation"
              options={getDSnpMcoConfirmationOptions()}
              value={currentNote.dSnpMcoConfirmation}
              onChange={(dSnpMcoConfirmation) =>
                setCurrentNote((currentNote) => ({ ...currentNote, dSnpMcoConfirmation }))
              }
              data-testid="new-note-form--dsnp-mco-confirmation"
            />
          </Box>
          <Box width="100%">
            <TextField
              label="D-SNP Medicaid Recipient Number"
              value={currentNote.dSnpMedicaidRecipientNumber}
              onChange={(event) =>
                setCurrentNote((currentNote) => ({ ...currentNote, dSnpMedicaidRecipientNumber: event.target.value }))
              }
              fullWidth
              data-testid="new-note-form--dsnp-text-field"
              disabled={currentNote.dSnpMedicaidRecipientNumberOptional}
            />
          </Box>
          <Box width="100%">
            <Checkbox
              style={{ color: colorsLight.font.secondary }}
              label="D-SNP Medicaid Recipient Number is not available"
              checked={currentNote.dSnpMedicaidRecipientNumberOptional}
              onChange={(checked) => {
                if (checked) {
                  setCurrentNote((currentNote) => ({
                    ...currentNote,
                    dSnpMedicaidRecipientNumber: "",
                    dSnpMedicaidRecipientNumberOptional: true,
                  }));
                } else {
                  setCurrentNote((currentNote) => ({
                    ...currentNote,
                    dSnpMedicaidRecipientNumberOptional: false,
                  }));
                }
              }}
              data-testid="new-note-form--dsnp-check-box"
            />
          </Box>
        </>
      )}
      {currentNote.noteType === "CLAIMS_PAYMENT" && (
        <Box width="100%">
          <TextField
            label="Claims payment note"
            fullWidth
            value={currentNote.claimsPaymentNote}
            onChangeValue={(value) => setCurrentNote((currentNote) => ({ ...currentNote, claimsPaymentNote: value }))}
            data-testid="new-note-form--claims-payment-text-field"
          />
        </Box>
      )}
      {currentNote.noteType === "APPEALS" && (
        <Box width="100%">
          <Stack spacing={2}>
            <SingleSelectDropdown
              label="Requestor"
              options={getAppealTypeOptions()}
              value={currentNote.requestor}
              onChange={(requestor) => setCurrentNote((currentNote) => ({ ...currentNote, requestor }))}
            />
            <DateSelect
              label="Appeal requested on"
              onDateChange={(startDate) => {
                handleDateChange(startDate);
                setCurrentNote((currentNote) => ({ ...currentNote, appealRequestedOn: startDate }));
              }}
              value={startDate || null}
            />
            <RichTextEditor
              htmlValue={currentNote.noteContent || ""}
              setHtmlValue={(newNoteContent) =>
                setCurrentNote((currentNote) => ({ ...currentNote, noteContent: newNoteContent }))
              }
              label="Appeals note"
              testid="new-note-form--appeals-text-editor"
              readonly={false}
            />
          </Stack>
        </Box>
      )}
      {currentNote.noteType === "CONTINUITY_OF_CARE" && (
        <Box width="100%">
          <RichTextEditor
            readonly={!!savingNote}
            htmlValue={currentNote.noteContent || ""}
            setHtmlValue={(newNoteContent) =>
              setCurrentNote((currentNote) => ({ ...currentNote, noteContent: newNoteContent }))
            }
            label="Continuity of care note"
            testid="new-note-form--continuity-of-care-text-editor"
          />
        </Box>
      )}
      {currentNote.noteType === "LIVANTA" && (
        <Box width="100%">
          <Stack spacing={2}>
            <SingleSelectDropdown
              label="Coverage ended by"
              options={getLivantaTypeOptions()}
              value={currentNote.coverageEndedBy}
              onChange={(coverageEndedBy) => setCurrentNote((currentNote) => ({ ...currentNote, coverageEndedBy }))}
            />
            <DateSelect
              label="Appeal requested on (optional)"
              onDateChange={(startDate) => {
                handleDateChange(startDate);
                setCurrentNote((currentNote) => ({ ...currentNote, appealRequestedOn: startDate }));
              }}
              value={startDate || null}
            />
            <RichTextEditor
              htmlValue={currentNote.noteContent || ""}
              setHtmlValue={(newNoteContent) =>
                setCurrentNote((currentNote) => ({ ...currentNote, noteContent: newNoteContent }))
              }
              label="Appeals note"
              readonly={false}
            />
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default NewNoteForm;

const noteTypeLabel = (noteType: NoteType) => {
  switch (noteType) {
    case "ADMINISTRATIVE":
      return "Administrative note";
    case "D_SNP":
      return "Dual Special Needs Program (D-SNP) note";
    case "SNF_THREE_DAY_WAIVER_NOTE":
      return "SNF 3-day waiver note";
    case "CLAIMS_PAYMENT":
      return "Claims payment note";
    case "APPEALS":
      return "Appeals note";
    case "CONTINUITY_OF_CARE":
      return "Continuity of care note";
    case "LIVANTA":
      return "Livanta note";
  }
};

const appealTypeLabel = (appealType: AppealsType) => {
  switch (appealType) {
    case "MEMBER":
      return "Member";
    case "PROVIDER":
      return "Provider";
  }
};

const livantaTypeLabel = (livantaType: LivantaType) => {
  switch (livantaType) {
    case "GEISINGER":
      return "Geisinger";
    case "PROVIDER":
      return "Provider";
  }
};

const defaultAppealTypeOptions: AppealTypeOptions = [
  { id: "MEMBER", label: appealTypeLabel("MEMBER") },
  { id: "PROVIDER", label: appealTypeLabel("PROVIDER") },
];

const defaultLivantaTypeOptions: LivantaTypeOptions = [
  { id: "GEISINGER", label: livantaTypeLabel("GEISINGER") },
  { id: "PROVIDER", label: appealTypeLabel("PROVIDER") },
];

const defaultNoteTypeOptions: NoteTypeOptions = [{ id: "ADMINISTRATIVE", label: noteTypeLabel("ADMINISTRATIVE") }];
