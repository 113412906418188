import { createContext, Dispatch, SetStateAction, useState } from "react";

// * File tag RECENT_PROVIDERS_8B12CDB1

type OrderingProviderType = "RECENT_OP_CLICKED_NO_CHANGE" | "RECENT_OP_CLICKED_THEN_CHANGED" | "RECENT_OP_NOT_CLICKED";
type ReferringProviderType = "RECENT_RP_CLICKED_NO_CHANGE" | "RECENT_RP_CLICKED_THEN_CHANGED" | "RECENT_RP_NOT_CLICKED";

type PerformingSpecialistType =
  | "RECENT_PS_CLICKED_NO_CHANGE"
  | "RECENT_PS_CLICKED_THEN_CHANGED"
  | "RECENT_PS_NOT_CLICKED";

type PerformingProviderType =
  | "RECENT_PP_CLICKED_NO_CHANGE"
  | "RECENT_PP_CLICKED_THEN_CHANGED"
  | "RECENT_PP_NOT_CLICKED";

type FacilityType = "RECENT_F_CLICKED_NO_CHANGE" | "RECENT_F_CLICKED_THEN_CHANGED" | "RECENT_F_NOT_CLICKED";

type PerformingProviderPracticeType =
  | "RECENT_PPP_CLICKED_NO_CHANGE"
  | "RECENT_PPP_CLICKED_THEN_CHANGED"
  | "RECENT_PPP_NOT_CLICKED";

type SpecialistFacilityType =
  | "RECENT_SF_CLICKED_NO_CHANGE"
  | "RECENT_SF_CLICKED_THEN_CHANGED"
  | "RECENT_SF_NOT_CLICKED";

type stateTypeType =
  | "OrderingProvider"
  | "PerformingProvider"
  | "Facility"
  | "ReferringProvider"
  | "SpecialistFacility"
  | "PerformingSpecialist"
  | "PerformingProviderPractice";

interface RecentProvidersContextType {
  orderingProviderTrackingState?: OrderingProviderType;
  setOrderingProviderTrackingState?: Dispatch<SetStateAction<OrderingProviderType>>;

  performingProviderTrackingState?: PerformingProviderType;
  setPerformingProviderTrackingState?: Dispatch<SetStateAction<PerformingProviderType>>;

  facilityTrackingState?: FacilityType;
  setFacilityTrackingState?: Dispatch<SetStateAction<FacilityType>>;

  referringProviderTrackingState?: ReferringProviderType;
  setReferringProviderTrackingState?: Dispatch<SetStateAction<ReferringProviderType>>;

  performingSpecialistTrackingState?: PerformingSpecialistType;
  setPerformingSpecialistTrackingState?: Dispatch<SetStateAction<PerformingSpecialistType>>;

  specialistFacilityTrackingState?: SpecialistFacilityType;
  setSpecialistFacilityTrackingState?: Dispatch<SetStateAction<SpecialistFacilityType>>;

  performingProviderPracticeTrackingState?: PerformingProviderPracticeType;
  setPerformingProviderPracticeTrackingState?: Dispatch<SetStateAction<PerformingProviderPracticeType>>;

  trackingStateClickChangeCheck?(stateType: stateTypeType): void;
}

export const RecentProvidersContext = createContext<RecentProvidersContextType>({});

export function RecentProvidersProvider({ children }: { children: any }) {
  const [referringProviderTrackingState, setReferringProviderTrackingState] =
    useState<ReferringProviderType>("RECENT_RP_NOT_CLICKED");

  const [performingSpecialistTrackingState, setPerformingSpecialistTrackingState] =
    useState<PerformingSpecialistType>("RECENT_PS_NOT_CLICKED");

  const [specialistFacilityTrackingState, setSpecialistFacilityTrackingState] =
    useState<SpecialistFacilityType>("RECENT_SF_NOT_CLICKED");

  const [orderingProviderTrackingState, setOrderingProviderTrackingState] =
    useState<OrderingProviderType>("RECENT_OP_NOT_CLICKED");

  const [performingProviderTrackingState, setPerformingProviderTrackingState] =
    useState<PerformingProviderType>("RECENT_PP_NOT_CLICKED");

  const [performingProviderPracticeTrackingState, setPerformingProviderPracticeTrackingState] =
    useState<PerformingProviderPracticeType>("RECENT_PPP_NOT_CLICKED");

  const [facilityTrackingState, setFacilityTrackingState] = useState<FacilityType>("RECENT_F_NOT_CLICKED");

  function trackingStateClickChangeCheck(stateType: stateTypeType) {
    if (stateType === "OrderingProvider") {
      if (orderingProviderTrackingState === "RECENT_OP_CLICKED_NO_CHANGE") {
        if (setOrderingProviderTrackingState) {
          setOrderingProviderTrackingState("RECENT_OP_CLICKED_THEN_CHANGED");
        }
      }
    } else if (stateType === "PerformingProvider") {
      if (performingProviderTrackingState === "RECENT_PP_CLICKED_NO_CHANGE") {
        if (setPerformingProviderTrackingState) {
          setPerformingProviderTrackingState("RECENT_PP_CLICKED_THEN_CHANGED");
        }
      }
    } else if (stateType === "Facility") {
      if (facilityTrackingState === "RECENT_F_CLICKED_NO_CHANGE") {
        if (setFacilityTrackingState) {
          setFacilityTrackingState("RECENT_F_CLICKED_THEN_CHANGED");
        }
      }
    } else if (stateType === "PerformingProviderPractice") {
      if (performingProviderPracticeTrackingState === "RECENT_PPP_CLICKED_NO_CHANGE") {
        if (setPerformingProviderPracticeTrackingState) {
          setPerformingProviderPracticeTrackingState("RECENT_PPP_CLICKED_THEN_CHANGED");
        }
      }
    } else if (stateType === "ReferringProvider") {
      if (referringProviderTrackingState === "RECENT_RP_CLICKED_NO_CHANGE") {
        if (setReferringProviderTrackingState) {
          setReferringProviderTrackingState("RECENT_RP_CLICKED_THEN_CHANGED");
        }
      }
    } else if (stateType === "PerformingSpecialist") {
      if (performingSpecialistTrackingState === "RECENT_PS_CLICKED_NO_CHANGE") {
        if (setPerformingSpecialistTrackingState) {
          setPerformingSpecialistTrackingState("RECENT_PS_CLICKED_THEN_CHANGED");
        }
      }
    } else if (stateType === "SpecialistFacility") {
      if (specialistFacilityTrackingState === "RECENT_SF_CLICKED_NO_CHANGE") {
        if (setSpecialistFacilityTrackingState) {
          setSpecialistFacilityTrackingState("RECENT_SF_CLICKED_THEN_CHANGED");
        }
      }
    }
  }

  return (
    <RecentProvidersContext.Provider
      value={{
        orderingProviderTrackingState,
        setOrderingProviderTrackingState,
        performingProviderTrackingState,
        setPerformingProviderTrackingState,
        facilityTrackingState,
        setFacilityTrackingState,
        referringProviderTrackingState,
        setReferringProviderTrackingState,
        specialistFacilityTrackingState,
        setSpecialistFacilityTrackingState,
        performingSpecialistTrackingState,
        setPerformingSpecialistTrackingState,
        trackingStateClickChangeCheck,
        performingProviderPracticeTrackingState,
        setPerformingProviderPracticeTrackingState,
      }}
    >
      {children}
    </RecentProvidersContext.Provider>
  );
}
