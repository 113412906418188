import React, { useMemo, useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import classnames from "classnames";

import { TextField, TextFieldProps } from "../TextField";
import { colorsDark, colorsLight } from "../../themes";

const useStyles = makeStyles((theme) => ({
  searchIcon: {
    // todo add the dark font.light to the dark theme color palette, #56657F is same as colorsDark.gray.divider, but seems weird to use that constant here
    color: theme.palette.type === "dark" ? "#6F7580" : colorsLight.font.light,
    width: theme.spacing(4),
    height: 20,
    position: "absolute",
  },
  clearIcon: {
    color: theme.palette.type === "dark" ? colorsDark.gray.light : colorsLight.font.secondary,
    width: theme.spacing(4),
    height: theme.spacing(4),
    position: "absolute",
  },
  loadingSpinner: {
    position: "absolute",
    margin: "0 6px", // centers the spinner icon
  },
  textField: {
    /* https://medium.com/@rion.mrk/how-to-remove-x-icon-from-search-input-field-or-input-type-search-db3c808405fb */
    /* clears the ‘X’ from Internet Explorer */
    "& input[type=search]::-ms-clear": { display: "none", width: 0, height: 0 },
    "& input[type=search]::-ms-reveal": { display: "none", width: 0, height: 0 },

    /* clears the ‘X’ from Chrome */
    '& input[type="search"]::-webkit-search-decoration': { display: "none" },
    '& input[type="search"]::-webkit-search-cancel-button': { display: "none" },
    '& input[type="search"]::-webkit-search-results-button': { display: "none" },
    '& input[type="search"]::-webkit-search-results-decoration': { display: "none" },
  },
  endAdornment: {
    position: "relative",
    width: theme.spacing(4),
  },
}));

interface Props extends TextFieldProps {
  loading?: boolean;
  value: string;
}

/**
 * A text search is a fancy TextField with some icon adornments to indicate that this is field has a "Search"
 * behavior
 *
 * This component should be "controlled" (pass in a value on onChangeValue function), so that the "clear" behavior
 * works
 */
export default function TextSearch({
  className,
  loading,
  InputProps,
  onChangeValue,
  value,
  ...passThroughProps
}: Props) {
  const classes = useStyles();

  // "targeted" = this text field is either focused or hovered on
  const [focused, setFocused] = useState(passThroughProps.focused || false);
  const [hovered, setHovered] = useState(false);

  const showClearIcon = !!value && (focused || hovered);
  const endAdornment = useMemo(
    () => (
      <>
        <Fade in={loading} style={{ transitionDelay: loading ? "75ms" : "0ms" }}>
          <CircularProgress className={classes.loadingSpinner} color="inherit" size={20} />
        </Fade>
        <Fade in={!loading && showClearIcon} style={{ transitionDelay: !loading && showClearIcon ? "75ms" : "0ms" }}>
          <IconButton
            className={classes.clearIcon}
            aria-label="Clear"
            title="Clear"
            disableRipple
            onClick={(event) => {
              event.stopPropagation();
              onChangeValue?.("");
            }}
          >
            <ClearIcon />
          </IconButton>
        </Fade>
        <Fade in={!loading && !showClearIcon} style={{ transitionDelay: !loading && !showClearIcon ? "75ms" : "0ms" }}>
          <SearchIcon className={classes.searchIcon} />
        </Fade>
      </>
    ),
    [classes.clearIcon, classes.loadingSpinner, classes.searchIcon, loading, onChangeValue, showClearIcon]
  );

  return (
    <TextField
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      type="search"
      className={classnames(classes.textField, className)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" className={classes.endAdornment}>
            {endAdornment}
          </InputAdornment>
        ),
        ...InputProps,
      }}
      onChangeValue={onChangeValue}
      value={value}
      {...passThroughProps}
    />
  );
}
