export const DEFAULT_SPECIALTY_OPTIONS: { id: string; label: string }[] = [
  {
    id: "Acute inpatient care (direct & emergency admits)",
    label: "Acute inpatient care (direct & emergency admits)",
  },
  {
    id: "Ambulance service provider",
    label: "Ambulance service provider",
  },
  { id: "Behavioral health", label: "Behavioral health" },
  { id: "Cardiology", label: "Cardiology" },
  { id: "Cardiothoracic surgery", label: "Cardiothoracic surgery" },
  { id: "Chiropractic", label: "Chiropractic" },
  { id: "Dermatology", label: "Dermatology" },
  { id: "Diagnostic", label: "Diagnostic" },
  { id: "Durable medical equipment & medical supplies", label: "Durable medical equipment & medical supplies" },
  { id: "Family medicine", label: "Family medicine" },
  { id: "Gastroenterology", label: "Gastroenterology" },
  { id: "General surgery", label: "General surgery" },
  { id: "Head and neck surgery", label: "Head and neck surgery" },
  { id: "Home Health/Hospice Care", label: "Home Health/Hospice Care" },
  { id: "Internal medicine", label: "Internal medicine" },
  { id: "Neurology", label: "Neurology" },
  { id: "Neurosurgery", label: "Neurosurgery" },
  { id: "OB/GYN", label: "OB/GYN" },
  { id: "Oncology", label: "Oncology" },
  { id: "Ophthalmology", label: "Ophthalmology" },
  { id: "Orthopedics", label: "Orthopedics" },
  { id: "Pain management", label: "Pain management" },
  { id: "Physical medicine and rehabilitation", label: "Physical medicine and rehabilitation" },
  { id: "Plastic surgery", label: "Plastic surgery" },
  { id: "Podiatry", label: "Podiatry" },
  { id: "Urology", label: "Urology" },
  { id: "Other", label: "Other" },
];
