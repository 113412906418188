import { CohereCard, H4 } from "@coherehealth/design-system";
import { SemanticCode } from "@coherehealth/core-platform-api";
import { Chip, Tooltip } from "@coherehealth/common";
import { Box } from "@mui/material";
import { Grid, Typography, makeStyles, useTheme } from "@material-ui/core";
import { PropsWithChildren, useRef } from "react";
import { convertToProperCaseAndFormatAuthCategory } from "../../../util/textUtil";
import useIsOverflow from "hooks/useIsOverflow";
import { useLocation } from "react-router";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";

interface Props {
  title?: string;
  codes: SemanticCode[];
  authCategory?: string;
}

interface CodeCaptionWrapperProps {
  description: string;
  captionStyle: string;
}

const useStyles = makeStyles((theme) => ({
  caption: {
    paddingLeft: theme.spacing(1),
    width: "100%",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "15px",
    letterSpacing: "0.15px",
  },
}));

export default function SelectionCard({ title, codes, children, authCategory }: PropsWithChildren<Props>) {
  const theme = useTheme();
  const styleClasses = useStyles();
  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);

  const selectionCardContent = (
    <Grid container spacing={3} style={{ paddingBottom: isFaxAuthBuilderFlow ? theme.spacing(3) : undefined }}>
      {title && (
        <Grid item xs={12}>
          <H4 flexBasis="100%" data-public>
            {title}
          </H4>
        </Grid>
      )}
      {codes && codes?.length > 0 && (
        <Box flex={1} padding="12px" width="fit-content" minWidth="150px">
          {codes?.map(({ code, description }) => (
            <Box key={code} display="flex" alignItems="center" marginBottom="12px" style={{ minWidth: 0 }}>
              <Chip label={code} size="small" data-public />
              <CodeCaptionWrapper description={description ?? ""} captionStyle={styleClasses.caption} />
            </Box>
          ))}
        </Box>
      )}
      {((!codes && authCategory) || (codes && codes?.length === 0 && authCategory)) && (
        <Box flex={1} padding="12px" width="fit-content" minWidth="150px">
          <Box display="flex" alignItems="center" marginBottom="12px">
            <Chip label={convertToProperCaseAndFormatAuthCategory(authCategory)} size="small" data-public />
          </Box>
        </Box>
      )}
      <Grid item style={{ flex: "1 1 560px" }}>
        {children}
      </Grid>
    </Grid>
  );

  if (!!isFaxAuthBuilderFlow) {
    return <div style={{ display: "flex" }}>{selectionCardContent}</div>;
  }
  return <CohereCard display="flex">{selectionCardContent}</CohereCard>;
}

const CodeCaptionWrapper = ({ description, captionStyle }: CodeCaptionWrapperProps) => {
  const textElementRef = useRef<HTMLInputElement | null>(null);
  const { hoverStatus } = useIsOverflow(textElementRef);
  return (
    <Tooltip title={description} placement="top" disableHoverListener={!hoverStatus}>
      <Typography ref={textElementRef} className={captionStyle} data-public>
        {description}
      </Typography>
    </Tooltip>
  );
};
