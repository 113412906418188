import { Model, Factory, Server } from "miragejs";

const models = {
  facility: Model,
};
const factories = {
  facility: Factory.extend({
    npi(i: number) {
      return `FacNPI${i}`;
    },
    name(i: number) {
      return `Facility Name ${i}`;
    },
    addresses(i: number) {
      return [];
    },
    phoneNumbers(i: number) {
      return [];
    },
    tinList(i: number) {
      return [];
    },
    isPar(i: number) {
      return false;
    },
    preferredFacility(i: number) {
      return false;
    },
  }),
};

const scenarios = {
  standard(server: Server) {
    server.create("facility", {
      npi: "1234567890",
      name: "Sector 12 General Hospital",
      addresses: [
        {
          line1: "Address Line 1",
          line2: "Address Line 2",
          city: "City",
          state: "AB",
          zipCode: "12345",
          latitude: 0,
          longitude: 0,
        },
      ],
      phoneNumbers: [],
      tinList: ["123456789", "111111111"],
      isPar: true,
      preferredFacility: true,
    } as any);

    server.create("facility", {
      npi: "NPIlongName",
      name: "Test Long Name Long Name Long Name Long Name Long Name Long Name Long Name Long Name Long Name Long Name Long Name Long Name.",
    } as any);

    server.createList("facility", 100);
  },
};

const activeScenario = "standard";
//const activeScenario = "short";
//const activeScenario = "empty";

export { models, factories, scenarios, activeScenario };
