import { PropsWithChildren } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Subtitle2 } from "../Typography";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    paddingBottom: theme.spacing(4),
    textAlign: "center",
  },
  modalDescription: {
    paddingTop: theme.spacing(2),
  },
}));

// HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
// eslint-disable-next-line @typescript-eslint/ban-types
export default function ModalHeader({ children }: PropsWithChildren<{}>) {
  const classes = useStyles();

  return <div className={classes.headerContainer}>{children}</div>;
}

interface ModalDescriptionProps {
  id?: string;
}

export function ModalDescription({ id, children }: PropsWithChildren<ModalDescriptionProps>) {
  const classes = useStyles();

  return (
    <Subtitle2 className={classes.modalDescription} color="textSecondary" id={id}>
      {children}
    </Subtitle2>
  );
}
