export const displayInputFormatter = (input: string) => {
  //adds phone number formatting dashes, after user adds character that would appear after a dash, so that dashes are non-intrusive
  if (input.length === 4) {
    return input.substring(0, 3) + "-" + input.substring(input.length - 1);
  } else if (input.length === 8) {
    return input.substring(0, 7) + "-" + input.substring(input.length - 1);
  } else if (input.length === 13) {
    return input.substring(0, input.length - 1);
  } else {
    return input;
  }
};

export const DefaultValueFormatter = (defaultValue: string) => {
  return defaultValue.substring(0, 3) + "-" + defaultValue.substring(3, 6) + "-" + defaultValue.substring(6, 11);
};

export const valueFormatter = (input: string) => {
  return input.replace(/[^0-9]/g, "");
};
