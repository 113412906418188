import { containerSmallBreakpoint } from "@coherehealth/common";
import { Drawer, makeStyles } from "@material-ui/core";
import { ReferralBuilderContent } from "components/ReferralManagement/RequestBuilder";
import { warn } from "logger";
import { PathMatch, Location } from "react-router";

interface Props {
  faxId: string;
  queueId: string | undefined;
  caseId: string | undefined;
  match?: PathMatch<string> | null;
  location: Location;
  search: URLSearchParams;
}
const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: containerSmallBreakpoint,
    "& footer, & header": {
      right: "auto",
    },
  },
}));
export default function FaxReferralsSideBar({ caseId, queueId, faxId, match, location, search }: Props) {
  const classes = useStyles();
  if (!caseId) {
    warn(`Missing case id corresponding to faxId: ${faxId}`);
  }

  return (
    <Drawer variant="permanent" anchor="right" disableEnforceFocus>
      <div className={classes.wrapper}>
        <ReferralBuilderContent
          match={match ?? null}
          faxId={faxId}
          location={location}
          qmCaseId={caseId ?? null}
          queueId={queueId ?? ""}
          search={search}
        />
      </div>
    </Drawer>
  );
}
