import { generatePath, NavigateOptions, To } from "react-router";
import routes from "routes";
import { stringify as stringifyQueryString } from "qs";

const patientSummaryPath = (patientId: string): string => {
  return `${generatePath(routes.REFERRAL_PATIENT_SUMMARY, {
    patientId: patientId,
  })}`;
};

const patientSummarySearch = (referralRequestId: string): string => {
  return `?${stringifyQueryString({ reviewReferralRequestId: referralRequestId })}`;
};

// Navigates to the patient summary page (/referrals/patient_summary/:id)
export function navigateToReferralsPatientSummary(
  patientId: string,
  navigate: (to: To, options?: NavigateOptions | undefined) => void,
  referralRequestId?: string
) {
  const pathname = patientSummaryPath(patientId);
  const search = referralRequestId ? patientSummarySearch(referralRequestId) : undefined;
  navigate({
    pathname,
    search,
  });
}
