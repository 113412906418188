function listRemove<T>(arr: T[], index: number) {
  if (index > -1 && index < arr.length) {
    const newList = [...arr];
    newList.splice(index, 1);
    return newList;
  }
  return arr;
}

export default listRemove;
