import {
  FormTable,
  formatDateStr,
  Body1,
  Pill,
  useGeneralAuthSubmissionWorflowOn,
  Tooltip,
  useFeature,
  extractClinicalServicesText,
} from "@coherehealth/common";
import { generatePath } from "react-router";
import {
  ServiceRequestResponse,
  PendingReason,
  AuthStatus,
  AutoApprovalSnapshot,
} from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import routes from "routes";
import compact from "lodash/compact";
import { Link } from "@material-ui/core";
import { useRef } from "react";
import useIsOverflow from "../../../hooks/useIsOverflow";
import { useAuthorized } from "authorization";
import { isNegationAuthStatus } from "util/serviceRequest";
const M_DASH = "\u2014";

const useStyles = makeStyles((theme) => ({
  styledBodyContainer: {
    width: "100%",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  hover: {
    "&:hover": {
      background: "rgba(0, 0, 0, 0.04)",
      "background-blend-mode": "multiply",
    },
  },
}));

export default function ServiceRequestTable({ serviceRequests }: { serviceRequests: ServiceRequestResponse[] }) {
  const classes = useStyles();

  const generalAuthSubmissionWorkflowEnabled = useGeneralAuthSubmissionWorflowOn(
    serviceRequests[0]?.healthPlanName || ""
  );

  const textElementRef = useRef<HTMLInputElement | null>(null);

  const { hoverStatus } = useIsOverflow(textElementRef);
  const showPreviouslyApprovedRequestFeatureFlag = useFeature("showPreviouslyApprovedRequest");
  const canViewPreviouslyApprovedRequestUsers = useAuthorized("CAN_VIEW_PREVIOUSLY_APPROVED_REQUESTS");
  const showPreviouslyApprovedRequest =
    showPreviouslyApprovedRequestFeatureFlag && canViewPreviouslyApprovedRequestUsers;

  return (
    <FormTable<ServiceRequestResponse>
      tableData={serviceRequests}
      headers={["Tracking #", "Service Name", "Service date(s)", "CPT Codes", "Status"]}
      noPadding
      withDivider
      lightHeaderText
    >
      {({
        rowData: {
          id,
          cohereId,
          clinicalService,
          palCategory,
          startDate,
          endDate,
          procedureCodes,
          authStatus,
          pendingReason,
          autoApprovalSnapshot,
          initialDecisionDisposition,
          isSrNoLongerNeeded,
        },
        rowIndex,
      }) => [
        {
          contents: (
            <TableItem>
              <Link
                style={{ fontSize: 15, paddingTop: 0, textDecoration: "underline" }}
                href={generatePath(routes.VIEW_ONLY_REVIEW).concat(`?serviceRequestId=${id}`)}
                target="_blank"
              >
                #{cohereId}
              </Link>
            </TableItem>
          ),
          width: 104,
        },
        {
          contents: (
            <>
              {generalAuthSubmissionWorkflowEnabled ? (
                <div>
                  <Tooltip
                    title={extractClinicalServicesText(
                      serviceRequests[rowIndex].clinicalServices || [],
                      serviceRequests[rowIndex].authCategory,
                      serviceRequests[rowIndex].authSubcategory,
                      serviceRequests[rowIndex].palCategory,
                      "--"
                    )}
                    placement="top"
                    arrow
                    disableHoverListener={!hoverStatus}
                  >
                    <TableItem
                      className={
                        hoverStatus ? `${classes.styledBodyContainer} ${classes.hover}` : classes.styledBodyContainer
                      }
                      ref={textElementRef}
                    >
                      <Body1>
                        {extractClinicalServicesText(
                          serviceRequests[rowIndex].clinicalServices || [],
                          serviceRequests[rowIndex].authCategory,
                          serviceRequests[rowIndex].authSubcategory,
                          serviceRequests[rowIndex].palCategory,
                          "--"
                        )}
                      </Body1>
                    </TableItem>
                  </Tooltip>
                </div>
              ) : (
                <div>
                  <TableItem>
                    <Body1>{clinicalService?.name || palCategory?.name || "--"}</Body1>
                  </TableItem>
                </div>
              )}
            </>
          ),
          width: 430,
        },
        {
          contents: (
            <TableItem>
              <Body1>{compact([startDate, endDate]).map(formatDateStr).join(` ${M_DASH} `) || "--"}</Body1>
            </TableItem>
          ),
          width: 112,
        },
        {
          contents: (
            <TableItem>
              <Body1>{procedureCodes?.map((px) => px.code).join(", ") || "--"}</Body1>
            </TableItem>
          ),
          width: 88,
        },
        {
          contents: (
            <TableItem>
              <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                <Pill
                  variant={getVariant(authStatus)}
                  label={getAuthStatusText(authStatus, pendingReason, autoApprovalSnapshot)}
                />
                {isNegationAuthStatus(authStatus) &&
                  initialDecisionDisposition === "APPROVED" &&
                  showPreviouslyApprovedRequest && <SecondaryText>Previously approved</SecondaryText>}
                {isSrNoLongerNeeded && <SecondaryText>No longer needed</SecondaryText>}
              </div>
            </TableItem>
          ),
          width: "fit-content",
        },
      ]}
    </FormTable>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const TableItem = styled("div")(({ theme }) => ({
  paddingRight: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SecondaryText = styled(Body1)(({ theme }) => ({
  fontFamily: "Gilroy-Regular",
  fontSize: "13px",
  marginLeft: "4px",
  maxWidth: "210px",
}));

export const getVariant = (authStatus?: AuthStatus) => {
  if (authStatus === "APPROVED") {
    return "success";
  }
  if (authStatus === "DENIED") {
    return "error";
  }
  if (authStatus === "DRAFT" || authStatus === "WITHDRAWN") {
    return "info";
  }
  return "warning";
};

export const getAuthStatusText = (
  authStatus?: AuthStatus,
  pendingReason?: PendingReason,
  autoApprovalSnapshot?: AutoApprovalSnapshot
) => {
  const authStatusToAuthText: Record<AuthStatus, string> = {
    DRAFT: "Draft",
    INTAKE: "Intake",
    WITHDRAWN: "Withdrawn",
    APPROVED: "Approved",
    NO_LONGER_NEEDED: "Approved",
    PARTIALLY_APPROVED: "Partially Approved",
    DENIED: "Denied",
    RECOMMENDED_DENIAL: "Recommended Denial",
    RECOMMENDED_PARTIAL_APPROVAL: "Recommended Partial Approval",
    PENDING_EXTERNAL_DETERMINATION: "Pending External Review",
    PENDING_FACILITY_VERIFICATION: "Pending Facility Verification",
    PENDING_ASSESSMENT: "Pending Assessment",
    PENDING: `Pending ${pendingReason && pendingReasonMessage[pendingReason]}`,
    VOIDED: "Voided",
    DISMISSED: "Dismissed",
    POST_DENIAL_PEER_TO_PEER: "Post-Denial Peer-to-Peer",
  };
  return authStatus
    ? autoApprovalSnapshot && authStatus === "APPROVED"
      ? "Auto Approved"
      : authStatusToAuthText[authStatus]
    : "";
};

const pendingReasonMessage: Record<PendingReason, string> = {
  MISSING_CLINICAL_INFO: "MI (Clinical)",
  MISSING_NON_CLINICAL_INFO: "MI (Non-clinical)",
  RN_REVIEW: "Clinical Review",
  MD_REVIEW: "MD Review",
  NEW_HIRE_AUDIT_REVIEW: "New Hire Audit Review",
  PEER_TO_PEER_REVIEW: "Peer to Peer Review",
  OSCAR: "Oscar Review",
  PENDING_ASSESSMENT_SUBMISSION: "assessment submission",
  OTHER: "",
  AUDIT_REVIEW: "Audit Review",
  NUDGE_OUTREACH: "Nudge outreach",
  ADMIN_VOID: "Admin Void",
  OUT_OF_SCOPE: "Out of scope audit",
  SCHEDULING_OUTREACH: "Peer to peer scheduling",
  OUT_OF_NETWORK_REVIEW: "Out of network review",
  LETTER_WRITING: "Letter writing",
};
