import { useMemo } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Grid, makeStyles, styled } from "@material-ui/core";
import { ProcedureCode } from "@coherehealth/core-platform-api";
import { Body3, Chip, TextField, Tooltip } from "@coherehealth/common";
import MuiErrorIcon from "@material-ui/icons/Error";

interface Props {
  procedureCode: ProcedureCode;
  approvedServiceLevelUnits: number;
  serviceLevelUnits: boolean;
  updateProcedureCodeUnits: (unitsUpdate: string) => void;
}

export default function ProcedureCodeRow({
  procedureCode,
  serviceLevelUnits,
  approvedServiceLevelUnits,
  updateProcedureCodeUnits,
}: Props) {
  const pxUnitsInvalid = useMemo(() => {
    const approvedUnits = procedureCode.approvedUnits || 0;
    const requestedUnits = procedureCode.units || 0;
    if (serviceLevelUnits) {
      return approvedUnits > approvedServiceLevelUnits;
    } else {
      return approvedUnits > requestedUnits;
    }
  }, [serviceLevelUnits, procedureCode.approvedUnits, procedureCode.units, approvedServiceLevelUnits]);
  const numberFieldStyles = useNumberFieldStyle();

  return (
    <Grid item container spacing={2} style={{ display: "flex", padding: 16, alignItems: "center" }}>
      <Grid item xs={2} style={{ paddingLeft: 12 }}>
        <Chip label={procedureCode.code} />
      </Grid>
      {!serviceLevelUnits && (
        <Grid item xs={2}>
          <TextField disabled value={procedureCode.units} />
        </Grid>
      )}
      <Grid item xs={2}>
        <TextField
          InputProps={{
            endAdornment: pxUnitsInvalid && (
              <Tooltip title={`Cannot exceed ${serviceLevelUnits ? "approved" : "requested"}  units`} placement="top">
                <ErrorIcon />
              </Tooltip>
            ),
          }}
          error={pxUnitsInvalid}
          value={`${procedureCode.approvedUnits}`}
          className={numberFieldStyles.hideNumberArrows}
          onChange={(e) => {
            if (!isNaN(Number(e.target.value))) {
              updateProcedureCodeUnits(e.target.value);
            }
          }}
          onWheel={(e) => {
            (e.target as HTMLElement).blur();
          }}
        />
      </Grid>
      <Grid item xs={serviceLevelUnits ? 8 : 6}>
        <Body3>{procedureCode.description}</Body3>
      </Grid>
    </Grid>
  );
}

const useNumberFieldStyle = makeStyles({
  hideNumberArrows: {
    "& input[type=number]": {
      appearance: "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      appearance: "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      appearance: "none",
      margin: 0,
    },
  },
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ErrorIcon = styled(MuiErrorIcon)(({ theme }) => ({
  color: theme.palette.error.main,
}));
