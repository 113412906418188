import { queueMgmtBaseUrl } from "@coherehealth/common";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { generatePath } from "react-router";
import routes from "routes";

interface RedirectParams {
  serviceRequest?: ServiceRequestResponse | null | undefined;
  caseId?: string | null | undefined;
  reviewId?: string | null | undefined;
  navigate: (path: string) => void;
}

export interface RouteParams {
  serviceRequestId?: string | undefined;
  patientId?: string | undefined;
}

export const redirectToPatientSummaryFromReview = ({ serviceRequest, caseId, reviewId, navigate }: RedirectParams) => {
  if (!!caseId) {
    window.location.assign(`${queueMgmtBaseUrl()}/dashboard/`);
  } else if (!!reviewId) {
    navigate(
      generatePath(routes.PATIENT_SUMMARY, { patientId: serviceRequest?.patient?.id }).concat(
        `?reviewServiceRequestId=${serviceRequest?.id}`.concat(`&reviewId=${reviewId}`)
      )
    );
  } else {
    navigate(
      generatePath(routes.PATIENT_SUMMARY, { patientId: serviceRequest?.patient?.id }).concat(
        `?reviewServiceRequestId=${serviceRequest?.id}`
      )
    );
  }
};

export const routeToPatientSummaryFromReview = ({ serviceRequestId, patientId }: RouteParams) => {
  return generatePath(routes.PATIENT_SUMMARY, {
    patientId: patientId,
  }).concat(`?reviewServiceRequestId=${serviceRequestId}`);
};

export const routeToPatientSummaryFromReviewAfterDelete = (
  patientId: string | undefined,
  navigate: (path: string) => void
) => {
  if (patientId) {
    navigate(generatePath(routes.PATIENT_SUMMARY, { patientId: patientId }));
  }
};
