import React from "react";
import { ServiceDeterminationProcedureCodeBucket, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { H2 } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core/styles";
import ServiceCard from "./ServiceCard";

interface Props {
  buckets: ServiceDeterminationProcedureCodeBucket[];
  serviceRequests: ServiceRequestResponse[];
  removeBucket: (bucket: ServiceDeterminationProcedureCodeBucket) => void;
  addBucket: (bucket: ServiceDeterminationProcedureCodeBucket) => void;
}

export default function BucketedServiceContainer({ buckets, serviceRequests, removeBucket, addBucket }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.sectionMargins}>
      <H2 className={classes.h2}>Procedures selected based on your codes</H2>
      {buckets.map((bucket, idx) => (
        <ServiceCard
          key={idx}
          bucket={bucket}
          patientAuthHistory={serviceRequests.filter((sr) => sr.clinicalService?.id === bucket.clinicalService?.id)}
          isSelected
          isOffCarePathService={!bucket?.clinicalService}
          removeBucket={removeBucket}
          addBucket={addBucket}
        />
      ))}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  h2: {
    marginBottom: theme.spacing(2),
  },
  sectionMargins: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));
