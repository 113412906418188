import React from "react";
import { SingleSelectDropdown } from "@coherehealth/common";
import { AuthorizationType } from "@coherehealth/core-platform-api";

interface IProps {
  authType: AuthorizationType | undefined;
  requestUpdateAuthType: (updateAuthType: AuthorizationType) => void;
  label?: string;
  fullWidth?: boolean;
  error?: boolean;
}
//TODO: clean up by June 1, 2023 -- COH - 3422
export default function AuthTypeSelect({
  authType,
  requestUpdateAuthType,
  label,
  fullWidth,
  style,
  error,
}: IProps & React.HTMLProps<HTMLDivElement>) {
  const getHelperTextIfNull = () => error && "Required";

  const authTypeOptions: { id: AuthorizationType; label: string }[] = [
    { id: "MEDICAL", label: "Medical" },
    { id: "BEHAVIORAL", label: "Behavioral" },
  ];

  return (
    <SingleSelectDropdown
      error={error}
      helperText={getHelperTextIfNull}
      label={label || "Auth category"}
      fullWidth={!!fullWidth}
      value={authType || ""}
      onChange={(val) => {
        requestUpdateAuthType(val as AuthorizationType);
      }}
      options={authTypeOptions}
      style={{ width: !fullWidth ? "50%" : "", ...style }}
    />
  );
}
