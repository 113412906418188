import { InformativeModal, onCloseBackDrop } from "@coherehealth/common";
import BuildingIcon from "components/images/BuildingIcon.svg";
import { Dispatch, SetStateAction } from "react";
import { generatePath } from "react-router-dom";
import routes from "routes";

interface Props {
  newMembers: number;
  newMembersModalOpen: boolean;
  setNewMembersModalOpen: Dispatch<SetStateAction<boolean>>;
}

export default function NewMembersModal({ newMembers, newMembersModalOpen, setNewMembersModalOpen }: Props) {
  return (
    <InformativeModal
      headerText="There are new members in your organization"
      subHeaderText={
        "Since you last logged in, " + newMembers + " new members have been added through email auto-verification."
      }
      noCloseIcon
      icon={<img src={BuildingIcon} alt="" style={{ height: 100 }} />}
      primaryButtonText="View members"
      primaryButtonRoute={generatePath(routes.MY_ORGANIZATION)}
      open={newMembersModalOpen}
      onClose={onCloseBackDrop(() => setNewMembersModalOpen(false))}
    />
  );
}
