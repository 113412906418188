import { Model, Factory, Server } from "miragejs";

const models = {
  procedureCode: Model,
};
const factories = {
  procedureCode: Factory.extend({
    code(i: number) {
      return `PCODE${i}`;
    },
    description(i: number) {
      return `Description ${i}`;
    },
    system(i: number) {
      return "SYSTEM";
    },
  }),
};

const scenarios = {
  standard(server: Server) {
    server.create("procedureCode", {
      code: "00100",
      description: "Anesthesia for procedure on salivary gland with biopsy",
    } as any);
    server.create("procedureCode", {
      code: "PCODEemptyDesc",
      description: "",
    } as any);
    server.create("procedureCode", {
      code: "PCODElongDesc",
      description:
        "Test Long Desc Long Desc Long Desc Long Desc Long Desc Long Desc Long Desc Long Desc Long Desc Long Desc Long Desc Long Desc.",
    } as any);
    server.createList("procedureCode", 300);

    let i;
    for (i = 0; i < 50; i++) {
      server.create("procedureCode", {
        code: String(i).padStart(5, "0"),
        description: i % 2 === 0 ? "This code is even!" : "This code is odd!",
      } as any);
    }
  },
};

const activeScenario = "standard";
//const activeScenario = "short";
//const activeScenario = "empty";

export { models, factories, scenarios, activeScenario };
