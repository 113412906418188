import CurrentNewOutreach from "components/NewOutreach/CurrentNewOutreach";
import LogOutreachPage from "components/NewOutreach/LogOutreachPage";
import NewNote from "components/Notes/NewNote";
import { useClinicalReviewNext } from "./clinical-review-store";
import { AttachmentGuidelineTextHighlightsSnapshot } from "@coherehealth/core-platform-api";
import { useSRRuleResultsForClinicalReviewReturn } from "../reviewUtils/useServiceRequestRuleResultsForClinicalReview";
import { Dispatch, SetStateAction } from "react";
import { UserClickInfoClinicalReviewPage } from "@coherehealth/common";
import { useSearchParams } from "react-router-dom";
import { ReviewPageVersion } from "../reviewUtils/utils";
import { AppealNote } from "components/AppealNote/AppealNote";
import CurrentClinicalReview from "../Review/CurrentClinicalReview";

export type RightColumnProps = {
  reviewPageVersion?: ReviewPageVersion;
  attachmentGuidelineTextHighlightsSnapshot?: AttachmentGuidelineTextHighlightsSnapshot;
  userClickInfoTracking?: UserClickInfoClinicalReviewPage;
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
};

export const RightColumn = ({
  reviewPageVersion,
  attachmentGuidelineTextHighlightsSnapshot,
  userClickInfoTracking,
  setUserClickInfoTracking,
}: RightColumnProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state, dispatch } = useClinicalReviewNext();
  const [search] = useSearchParams();
  const attemptNumber = search.get("outreach_attempts");
  const {
    serviceRequest,
    existingReviews,
    featureFlagData,
    clinicalReviewPageUserAuths,
    ruleResultsForClinicalReview,
    currentReview,
  } = state;
  const { genericNotes } = featureFlagData;
  const { addNoteAuthorized } = clinicalReviewPageUserAuths;
  const { showAutoApprovalModal, showAutoApprovalInfoBanner, setAutoApprovalModalDismissed } =
    (ruleResultsForClinicalReview || {}) as useSRRuleResultsForClinicalReviewReturn;
  const canAddGenericNote = genericNotes && addNoteAuthorized;
  if (reviewPageVersion === "NewOutReachPage" && serviceRequest) {
    return <CurrentNewOutreach serviceRequest={serviceRequest} outreachAttempts={attemptNumber} />;
  } else if (reviewPageVersion === "LogOutreachPage" && serviceRequest) {
    return <LogOutreachPage serviceRequest={serviceRequest} outreachAttempts={attemptNumber} />;
  } else if (reviewPageVersion === "NewNotePage" && canAddGenericNote && serviceRequest) {
    return <NewNote serviceRequest={serviceRequest} />;
  } else if (reviewPageVersion === "AppealNotePage" && serviceRequest) {
    return <AppealNote serviceRequest={serviceRequest} />;
  } else if (currentReview && serviceRequest) {
    return (
      <CurrentClinicalReview
        review={currentReview}
        existingReviews={existingReviews}
        serviceRequest={serviceRequest}
        showAutoApprovalModal={showAutoApprovalModal}
        setAutoApprovalModalDismissed={setAutoApprovalModalDismissed}
        showAutoApprovalInfoBanner={showAutoApprovalInfoBanner}
      />
    );
  } else {
    return null;
  }
};
