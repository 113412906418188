import isEqual from "lodash/isEqual";
import { useRef } from "react";

/**
 * See https://bit.dev/speedlo/hooks/use-deep-memo/~code
 */
export default function useDeepMemo<TKey, TValue>(factory: () => TValue, key: TKey): TValue {
  const ref = useRef<{ key: TKey; value: TValue }>();

  if (!ref.current || !isEqual(key, ref.current.key)) {
    ref.current = { key, value: factory() };
  }

  return ref.current.value;
}
