import { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  AttemptStatus,
  OutreachOutcome,
  OutreachOpportunity,
  OutreachAttempt,
  AuthStatus,
} from "@coherehealth/core-platform-api";
import {
  colorsLight,
  Pill,
  Body1,
  PillVariant,
  Caption,
  Sanitized,
  formatDateStrWithTz,
  parseDateFromISOStringWithoutFallback,
  parseDateFromISOString,
  CopyToClipboard,
} from "@coherehealth/common";
import { convertFromShoutyCase, convertNumberToString, determineMIClinicalStatusChip } from "util/NudgeUtils";
import { Grid } from "@material-ui/core";
import {
  nudgeTypeChangeQuestionText,
  NudgeTypes,
} from "components/ServiceRequest/ReviewSection/NurseReview/NurseReviewEdit";
import React from "react";
import { getP2PReviewLink } from "components/NewOutreach/LogOutreachPage";
import { useSnackbar } from "notistack";
import { useSearchParams } from "react-router-dom";

interface Props {
  outreachOpportunities: OutreachOpportunity[] | null;
  serviceRequestId: string;
  authStatus?: AuthStatus;
  fromNudgeOpportunitySection?: boolean;
}

export default function OutreachOpportunities({
  outreachOpportunities,
  fromNudgeOpportunitySection,
  serviceRequestId,
  authStatus,
}: Props) {
  const classes = useStyles({ fromNudgeOpportunitySection });
  const theme = useTheme();
  const [outreachAttempts, setOutreachAttempts] = useState<OutreachAttempt[]>();
  const [search] = useSearchParams();
  const caseId = search.get("caseId");
  const queueId = search.get("queueId");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const attempts = outreachOpportunities?.flatMap((opportunity) => opportunity?.outreachAttempts || []);
    setOutreachAttempts(attempts);
  }, [outreachOpportunities]);

  return (
    <>
      {outreachAttempts
        ?.filter(
          (outreachAttempt) =>
            !outreachAttempt.outreachType ||
            outreachAttempt.outreachType === "MISSING_INFO_CLINICAL" ||
            outreachAttempt.outreachType === "NUDGES" ||
            outreachAttempt.outreachType === "SCHEDULING_OUTREACH"
        )
        ?.sort(
          (a, b) =>
            parseDateFromISOString(a.scheduledSend).getTime() - parseDateFromISOString(b.scheduledSend).getTime()
        )
        .map((outreachAttempt, index) => (
          <div key={outreachAttempt.id} style={{ width: "100%" }}>
            <Accordion elevation={0} className={classes.root}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.container}>
                <Grid container xs={12} direction="row" justifyContent="space-evenly" alignItems="center">
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center" xs={2}>
                    {!outreachAttempt.outreachType ? (
                      <Pill
                        label={
                          displayName[
                            outreachAttempt?.manual
                              ? outreachAttempt.outreachOutcome || "SUCCESS"
                              : outreachAttempt?.status || "ACCEPTED"
                          ]
                        }
                        variant={
                          pillVariant[
                            outreachAttempt?.manual
                              ? outreachAttempt.outreachOutcome || "SUCCESS"
                              : outreachAttempt?.status || "ACCEPTED"
                          ]
                        }
                        className={classes.leftPill}
                      />
                    ) : (
                      <>
                        {outreachAttempt.outreachType === "MISSING_INFO_CLINICAL" && (
                          <Pill
                            label={displayName[determineMIClinicalStatusChip(outreachAttempt)]}
                            variant={pillVariant[determineMIClinicalStatusChip(outreachAttempt)]}
                            className={classes.leftPill}
                          />
                        )}
                        {outreachAttempt.outreachType === "NUDGES" &&
                          outreachAttempt.nudgeInfo &&
                          outreachAttempt.nudgeInfo.map((nudge, index) => (
                            <Pill
                              label={(nudge.nudgeType && NudgeTypes[nudge.nudgeType]) || ""}
                              variant="info"
                              className={classes.leftPill}
                            />
                          ))}
                        {outreachAttempt.outreachType === "SCHEDULING_OUTREACH" && (
                          <Pill
                            label={
                              "Peer to peer scheduling - " +
                              convertNumberToString(
                                outreachAttempt?.manual
                                  ? outreachAttempt.attemptNumber
                                  : outreachAttempt.integrationRetryCount
                              ) +
                              " attempt"
                            }
                            variant="info"
                            className={classes.leftPill}
                          />
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid xs={4} container justifyContent="flex-start">
                    {(!outreachAttempt.outreachType || outreachAttempt.outreachType === "MISSING_INFO_CLINICAL") && (
                      <Pill
                        label={
                          `Missing information - ` +
                          convertNumberToString(
                            outreachAttempt?.manual
                              ? outreachAttempt.attemptNumber
                              : outreachAttempt.integrationRetryCount
                          ) +
                          ` attempt`
                        }
                        variant={"info"}
                        className={classes.leftPill}
                      />
                    )}
                  </Grid>
                  <Grid xs={6} container direction="row" justifyContent="flex-end" alignItems="center">
                    <div className={classes.attemptDetails}>
                      <Caption className={classes.captionSpacing}>
                        {dateIsFuture(outreachAttempt?.scheduledSend) && "Will be "}
                        {convertFromShoutyCase(outreachAttempt.channel)}
                        {outreachAttempt.channel === "PHONE" ? "d" : "ed"}
                      </Caption>
                      <Caption>
                        {formatDateStrWithTz(outreachAttempt?.scheduledSend)}{" "}
                        {outreachAttempt?.manual ? `by ` + outreachAttempt.completedByName : `(Automated)`}
                      </Caption>
                    </div>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  display: "block",
                  padding: fromNudgeOpportunitySection
                    ? theme.spacing(0, 0, 2, 0)
                    : outreachAttempt.manual
                    ? theme.spacing(0, 2, 2, 2)
                    : "",
                }}
              >
                {isHardCodedMI(outreachAttempt.outreachOpportunity) &&
                outreachAttempt.channel === "EMAIL" &&
                !outreachAttempt.manual ? (
                  <>
                    <Body1 className={classes.subjectText}>Internal Note</Body1>
                    <Caption>
                      Automated missing information email sent through Braze. See Braze portal for additional details.
                    </Caption>
                  </>
                ) : (
                  <>
                    {outreachAttempt.manual ? (
                      <>
                        {outreachAttempt.nudgeInfo && (
                          <>
                            {outreachAttempt.nudgeInfo?.map((nudge, idx) => (
                              <React.Fragment key={`${idx}-${nudge.nudgeType}-${nudge.nudgeDescription}`}>
                                <Grid item xs={12}>
                                  <Body1>{NudgeTypes[nudge.nudgeType || ""]}</Body1>
                                </Grid>
                                <Grid
                                  container
                                  xs={12}
                                  direction="row"
                                  justifyContent="space-evenly"
                                  alignItems="center"
                                  style={{ marginBottom: 24 }}
                                >
                                  <Grid item xs={6}>
                                    <Caption>{nudgeTypeChangeQuestionText(nudge.nudgeType)}</Caption>
                                    <Body1>{nudge.nudgeResultedInChange ? "Yes" : "No"}</Body1>
                                  </Grid>

                                  <Grid item xs={6}>
                                    <Caption>Correct point of contact reached?</Caption>
                                    <Body1>{nudge.nudgeReachedCorrectContact ? "Yes" : "No"}</Body1>
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            ))}
                          </>
                        )}
                        <Caption>
                          <Sanitized style={{ marginTop: -5 }} __html={outreachAttempt.outreachNotes} />
                          {outreachAttempt.outreachType === "SCHEDULING_OUTREACH" && (
                            <CopyToClipboard
                              text={getP2PReviewLink({
                                serviceRequestId,
                                authStatus,
                                caseId,
                                queueId,
                              })}
                              message={"Copy P2P review link"}
                              onClick={() => {
                                enqueueSnackbar("Copied to clipboard", { variant: "success" });
                              }}
                            />
                          )}
                        </Caption>
                      </>
                    ) : (
                      <>
                        <Body1 className={classes.subjectText}>{outreachAttempt.subject}</Body1>
                        <Caption>
                          <Sanitized __html={outreachAttempt?.message || ""} />
                        </Caption>
                      </>
                    )}
                  </>
                )}
              </AccordionDetails>
            </Accordion>
            {index !== outreachAttempts.length - 1 && <Divider className={classes.divider} />}
          </div>
        ))}
    </>
  );
}

const pillVariant: Record<AttemptStatus | OutreachOutcome, PillVariant> = {
  READY: "info",
  QUEUED: "info",
  PROCESSING: "warning",
  FAILED: "error",
  ACCEPTED: "success",
  SUCCESS: "success",
  REQUEST_ERROR: "error",
  UNDELIVERABLE: "error",
  WITHDRAWN: "info",
  OFFICE_STAFF_MEMBER_NOTIFIED: "success",
  LEFT_SCRIPTED_MESSAGE: "info",
  P2P_RIGHTS_GIVEN: "success",
  UNABLE_TO_REACH: "error",
  MEMBER_NOTIFIED: "success",
  AUTHORIZED_REP_NOTIFIED: "success",
  UNABLE_TO_REACH_NO_ANSWER: "error",
};

const displayName: Record<AttemptStatus | OutreachOutcome, string> = {
  QUEUED: "Pending send",
  READY: "Pending send",
  PROCESSING: "Pending response",
  FAILED: "Failed",
  SUCCESS: "Success",
  ACCEPTED: "Success",
  REQUEST_ERROR: "Error",
  UNDELIVERABLE: "Failed",
  WITHDRAWN: "Canceled",
  OFFICE_STAFF_MEMBER_NOTIFIED: "Success",
  LEFT_SCRIPTED_MESSAGE: "Pending response",
  P2P_RIGHTS_GIVEN: "Success",
  UNABLE_TO_REACH: "Failed",
  MEMBER_NOTIFIED: "Success",
  AUTHORIZED_REP_NOTIFIED: "Success",
  UNABLE_TO_REACH_NO_ANSWER: "Error",
};

const dateIsFuture = (dateString: string | undefined) => {
  const dateParsed = parseDateFromISOStringWithoutFallback(dateString);
  if (!dateParsed) {
    return false;
  }
  return dateParsed > new Date();
};

const isHardCodedMI = (nudgeOpportunity: OutreachOpportunity | undefined) => {
  return nudgeOpportunity?.manualOpportunityTrigger && nudgeOpportunity?.manualOpportunityTrigger === "MISSING_INFO";
};

const useStyles = makeStyles((theme) => ({
  nonBoldH5: {
    fontFamily: "Gilroy-Light",
  },
  captionSpacing: {
    marginRight: theme.spacing(1),
  },
  noNudgeText: {
    color: colorsLight.font.secondary,
  },
  attemptContainer: {
    width: "100%",
    alignItems: "center",
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
      display: "none",
    },
    "&.MuiAccordion-root.Mui-expanded": {
      margin: 0,
    },
  },
  attemptsContainer: {
    width: "100%",
    paddingRight: 0,
    display: "block",
    alignItems: "center",
    paddingLeft: 0,
  },
  root: {
    "&.MuiAccordion-root:before": {
      display: "none",
    },
    "&.MuiAccordion-root.Mui-expanded": {
      margin: 0,
    },
  },
  componentContainer: {
    width: "100%",
    paddingRight: 0,
    display: "block",
    alignItems: "center",
    paddingLeft: 0,
  },
  subjectText: {
    paddingBottom: 10,
  },
  container: {
    display: "flex",
    padding: ({ fromNudgeOpportunitySection }: StyleProps) =>
      fromNudgeOpportunitySection ? theme.spacing(0) : theme.spacing(0, 2, 0, 2),
    flexDirection: "row",
    maxHeight: 100,
    width: "100%",
    alignItems: "center",
    "&.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 0,
    },
  },
  divider: {
    margin: 0,
  },
  arrow: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    margin: theme.spacing(0, 1, 0, 1),
    paddingTop: theme.spacing(0.5),
  },
  leftPill: {
    maxHeight: theme.spacing(3),
    margin: theme.spacing(0, 1, 0, 0),
  },
  rightPill: {
    maxHeight: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  icon: {
    color: colorsLight.font.secondary,
    margin: theme.spacing(0, 1, 0, 1),
  },
  acceptCPT: {
    marginRight: theme.spacing(1),
    color: colorsLight.primary.main,
    "&.accepted": {
      color: colorsLight.success.main,
      transition: theme.transitions.create("opacity"),
    },
  },
  attemptDetails: {
    marginTop: theme.spacing(0.25),
  },
}));

type StyleProps = {
  fromNudgeOpportunitySection?: boolean;
};
