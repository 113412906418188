import React, { SVGProps } from "react";

export default function ClipboardSuccessIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="128" height="128" viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <path
          d="M2 64C2 28.6538 30.6538 0 66 0C101.346 0 130 28.6538 130 64C130 99.3462 101.346 128 66 128C30.6538 128 2 99.3462 2 64Z"
          fill="white"
        />
        <path d="M90.9621 37.0801H38.8V97.9179H90.9621V37.0801Z" fill="#A1AAB4" />
        <path d="M78.6613 40.7676H42.491V94.2245H87.2507V49.3785L78.6613 40.7676Z" fill="white" />
        <path
          d="M76.6961 29.5464H69.747C69.747 26.4819 67.5672 24 64.8696 24C62.1719 24 59.9922 26.4819 59.9922 29.5464H53.043L51.0791 41.8478H78.6385L76.6961 29.5464Z"
          fill="#212936"
        />
        <path
          d="M56.1732 49.2511H52.4612V45.5391H49.9146V49.2511H46.2026V51.7977H49.9146V55.5096H52.4612V51.7977H56.1732V49.2511Z"
          fill="#EA1D63"
        />
        <path d="M76.4808 59.6328H53.2593V61.3809H76.4808V59.6328Z" fill="#89D3DD" />
        <path d="M82.9552 87.6191H73.6968V89.3672H82.9552V87.6191Z" fill="#89D3DD" />
        <path d="M82.9545 66.0391H46.7842V67.7872H82.9545V66.0391Z" fill="#C2C2C2" />
        <path d="M82.9545 71.2832H46.7842V73.0313H82.9545V71.2832Z" fill="#C2C2C2" />
        <path d="M82.9545 76.5332H46.7842V78.2813H82.9545V76.5332Z" fill="#C2C2C2" />
        <path d="M62.9486 81.7734H46.7842V83.5215H62.9486V81.7734Z" fill="#C2C2C2" />
        <path d="M78.6602 49.3785H87.2495L78.6602 40.7676V49.3785Z" fill="#F5F5F5" />
        <g clipPath="url(#clip0)">
          <circle cx="90.0168" cy="43.2168" r="19.2168" fill="white" />
          <path
            d="M90.0168 24C79.4092 24 70.8 32.6091 70.8 43.2168C70.8 53.8244 79.4092 62.4335 90.0168 62.4335C100.624 62.4335 109.234 53.8244 109.234 43.2168C109.234 32.6091 100.624 24 90.0168 24ZM86.1735 52.8251L76.5651 43.2168L79.2746 40.5072L86.1735 47.3868L100.759 32.8013L103.469 35.5301L86.1735 52.8251Z"
            fill="#04B86C"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="132"
          height="132"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.809983 0 0 0 0 0.886017 0 0 0 0 0.904167 0 0 0 0.8 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <clipPath id="clip0">
          <rect width="38.4335" height="38.4335" fill="white" transform="translate(70.8 24)" />
        </clipPath>
      </defs>
    </svg>
  );
}
