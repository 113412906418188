import {
  UserActivity,
  ClickType,
  UserClickInfo,
  ActivityStage,
  SearchInfo,
  CopyPasteInfo,
} from "@coherehealth/core-platform-api";
import { Dispatch, SetStateAction } from "react";

export type UserClickInfoClinicalReviewPage = Record<ClickType, UserClickInfo | UserClickInfo[]>;

/**
 * This function is created to convert mapping of all clicks provided into UserClickInfoClinicalReviewPage to
 * UserActivity[] Array. Array will be used to batch save on the backend.
 * @author rahul sharma
 * @date 11 Feb 2022
 * @param userClickInfoTracking
 * @returns UserActivity[]
 */
export const createBatchActivities = (
  event: UserActivity["event"],
  stage: ActivityStage,
  type: UserActivity["type"],
  userClickInfoTracking: UserClickInfoClinicalReviewPage,
  searchInfos?: SearchInfo[],
  pastedAttachmentTexts?: CopyPasteInfo[],
  userId?: string,
  reviewId?: string,
  cohereId?: string,
  serviceRequestId?: string
) => {
  let activities: Array<UserActivity> = [];
  for (const item of Object.values(userClickInfoTracking)) {
    if (Array.isArray(item)) {
      item.forEach((userClickInfo: UserClickInfo) => {
        activities.push(
          createActivity(
            event,
            stage,
            type,
            userClickInfo,
            undefined,
            undefined,
            userId,
            reviewId,
            cohereId,
            serviceRequestId
          )
        );
      });
    } else {
      item.clickCount &&
        activities.push(
          createActivity(event, stage, type, item, undefined, undefined, userId, reviewId, cohereId, serviceRequestId)
        );
    }
  }
  searchInfos?.forEach((searchInfo: SearchInfo) => {
    activities.push(
      createActivity(event, stage, type, undefined, searchInfo, undefined, userId, reviewId, cohereId, serviceRequestId)
    );
  });
  pastedAttachmentTexts?.forEach((pastedAttachmentText) => {
    activities.push(
      createActivity(
        event,
        stage,
        type,
        undefined,
        undefined,
        pastedAttachmentText,
        userId,
        reviewId,
        cohereId,
        serviceRequestId
      )
    );
  });
  return activities;
};

export const createActivity = (
  event: UserActivity["event"],
  stage: ActivityStage,
  type: UserActivity["type"],
  userClickInfo?: UserClickInfo,
  searchInfo?: SearchInfo,
  pastedAttachmentText?: CopyPasteInfo,
  userId?: string,
  reviewId?: string,
  cohereId?: string,
  serviceRequestId?: string
) => {
  let userActivity: UserActivity;
  userActivity = {
    event: event,
    stage: stage,
    type: type,
    activityContext: {
      userClickInfo: userClickInfo,
      searchInfo: searchInfo,
      copyPasteInfo: pastedAttachmentText,
      userId: userId,
      reviewId: reviewId,
      cohereAuthId: cohereId,
      serviceRequestId: serviceRequestId,
    },
  };
  return userActivity;
};

/**
 * By judging click type & fileName & pageNumber this function increase the click count of
 * UserClickInfoClinicalReviewPage type object by one. For pdf or docs fileName & pageNumber
 * is Required.
 * @author rahul sharma
 * @date 15 Feb 2022
 * @param clickType User Click Type
 * @param fileName File which is seen by user
 * @param pageNumber File page number
 * @param setUserClickInfoTracking UserActivityTracker setState
 */
export const incrementClickCount = (
  clickType: ClickType,
  fileName: string,
  pageNumber: number,
  setUserClickInfoTracking: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>
) => {
  setUserClickInfoTracking((prevUserClickInfoTracking) => {
    if (Array.isArray(prevUserClickInfoTracking[clickType])) {
      return {
        ...prevUserClickInfoTracking,
        [clickType]: incrementUserClickCountInArrays(
          clickType,
          fileName,
          pageNumber,
          prevUserClickInfoTracking[clickType]
        ),
      };
    } else {
      return {
        ...prevUserClickInfoTracking,
        [clickType]: incrementUserClickCountInObject(clickType, prevUserClickInfoTracking[clickType]),
      };
    }
  });
};

export const incrementUserClickCountInArrays = (
  clickType: ClickType,
  fileName: string,
  pageNumber: number,
  userClickInfos: UserClickInfo | UserClickInfo[]
) => {
  if (!Array.isArray(userClickInfos)) {
    return userClickInfos;
  }
  const clickInfo = userClickInfos.find(
    (item: UserClickInfo) => item.fileName === fileName && item.pageNumber === pageNumber
  );
  const index = userClickInfos.findIndex((item: UserClickInfo) => item === clickInfo);
  if (index > -1) {
    return userClickInfos.map((userClickInfo, arrayIndex) => {
      if (index !== arrayIndex) {
        return userClickInfo;
      } else {
        const updatedClickCount = userClickInfo.clickCount ? userClickInfo.clickCount + 1 : 0;
        return {
          ...userClickInfo,
          clickCount: updatedClickCount,
        };
      }
    });
  } else {
    const newUserClickInfo: UserClickInfo = {
      clickType: clickType,
      clickCount: 1,
      fileName: fileName,
      pageNumber: pageNumber,
    };
    return [...userClickInfos, newUserClickInfo];
  }
};

export const incrementUserClickCountInObject = (
  clickType: ClickType,
  userClickInfo: UserClickInfo | UserClickInfo[]
) => {
  if (Array.isArray(userClickInfo)) {
    return userClickInfo;
  }
  return {
    ...userClickInfo,
    clickCount: userClickInfo.clickCount ? userClickInfo.clickCount + 1 : 1,
    clickType: clickType,
  };
};

export const getEmptyUserClickTracking = () => {
  const emptyUserClickTracking: UserClickInfoClinicalReviewPage = {
    PageRotation: [],
    ZoomIn: [],
    ZoomOut: [],
    ZoomToFit: [],
    OpenPatientSummaryHyperLink: {},
    PatientInformationCardToggle: {},
    ServiceRequestInformationCardToggle: {},
    ClinicalAssessmentCardToggle: {},
    OtherServiceRequestsCardToggle: {},
    ReviewsCardToggle: {},
    NudgesCardToggle: {},
    CarewebQICardToggle: {},
    NotesCardToggle: {},
  };
  return emptyUserClickTracking;
};
