import { colorsLight } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core";

export const useSuccesfulAuthorizationTransferModalStyles = makeStyles((theme) => ({
  sucessIcon: {
    transform: "scale(0.6)",
    color: theme.palette.success.main,
  },
  // Override some MUI styles to match the mockups' styling
  modal: {
    "& .MuiButton-root": {
      minWidth: 195,
    },
    "& div:first-child": {
      paddingBottom: theme.spacing(0),
      borderRadius: 16,
    },
  },
  // Override some MUI styles to match the mockups' styling
  divider: {
    border: "none",
    height: 1,
    width: "100%",
    margin: "32px 0 16px 0",
    flexShrink: 0,
    backgroundColor: colorsLight.gray.divider,
  },
}));
