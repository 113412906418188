import { useMemo } from "react";

import {
  PlaceOfService,
  ProcedureCode,
  ServiceRequestResponse,
  ServiceRequestOutreachOpportunitySnapshot,
  OutreachOpportunity,
  OutreachAttempt,
  OutreachType,
  RecommendChangeRuleAction,
  RuleActions,
  ServiceChangeOption,
} from "@coherehealth/core-platform-api";
import { unitTypeLabel } from "./serviceRequest";
import { ValidDisplayMessageAction } from "./rule";
import difference from "lodash/difference";
import { TARGET_FIELD_OPTIONS_MAP, TargetFieldLabel } from "@coherehealth/common";

export const oldValueText = (
  action: ValidDisplayMessageAction | undefined,
  beforeSnapshot: ServiceRequestOutreachOpportunitySnapshot | undefined
) => {
  if (action?.onAcceptAttribute === "units") {
    let serviceDatesMessage = "";
    const groupId = action?.groupId;
    return generateMessageForGroupedServices(groupId, beforeSnapshot, serviceDatesMessage);
  } else if (
    action?.onAcceptAttribute &&
    action?.onAcceptAttribute !== "rangeOfOptions?SERVICE_CHANGE" &&
    beforeSnapshot
  ) {
    validateAttributeOnServiceRequest(action?.onAcceptAttribute, beforeSnapshot);
    let oldValue: string;
    if (action.onAcceptAttribute === "urgency.isExpedited") {
      oldValue = beforeSnapshot.urgency?.isExpedited ? "Expedited" : "Not expedited";
    } else if (typeof beforeSnapshot[action.onAcceptAttribute] === "object") {
      oldValue = (beforeSnapshot[action.onAcceptAttribute] as PlaceOfService)?.name;
    } else {
      oldValue = beforeSnapshot[action.onAcceptAttribute]?.toString() || "";
    }
    return convertFromShoutyCase(oldValue) || "";
  } else {
    return "";
  }
};

const generateMessageForGroupedServices = (
  groupId: string | undefined,
  beforeSnapshot: ServiceRequestOutreachOpportunitySnapshot | undefined,
  serviceDatesMessage: string
) => {
  if (groupId && groupId !== "parent") {
    const clinicalService = beforeSnapshot?.clinicalServices?.find((cs) => cs?.id === groupId);
    if ((clinicalService && clinicalService.isUnitsOnPx) || !clinicalService) {
      const unitsAggregate = beforeSnapshot?.semanticProcedureCodes
        ?.filter((px) => px.groupId === groupId)
        ?.reduce((a, b) => a + (b.units ? b.units : 0), 0);
      return unitsAggregate + " " + (unitTypeLabel(beforeSnapshot?.unitType || "") || serviceDatesMessage);
    } else {
      const unitsMax = beforeSnapshot?.semanticProcedureCodes
        ?.filter((px) => px.groupId === groupId)
        ?.reduce((a, b) => (a > (b.units ? b.units : 0) ? a : b?.units || 0), 0);
      return unitsMax + " " + (unitTypeLabel(beforeSnapshot?.unitType || "") || serviceDatesMessage);
    }
  }
};
export const getRuleNudgeProcedureCode = (
  action: ValidDisplayMessageAction | RecommendChangeRuleAction | undefined,
  serviceRequest: ServiceRequestResponse
) => {
  const ruleNudgeProcedureCode = serviceRequest?.procedureCodes?.find((code) => code.groupId === action!.groupId);
  return ruleNudgeProcedureCode;
};
export const newValueText = (action: ValidDisplayMessageAction | undefined, serviceRequest: ServiceRequestResponse) => {
  let newValue: string;
  const ruleNudgeProcedureCode = getRuleNudgeProcedureCode(action!, serviceRequest);
  if (action?.onAcceptAttribute === "units") {
    let serviceDatesMessage: string = "";
    if (
      ruleNudgeProcedureCode &&
      ruleNudgeProcedureCode.unitType === "Number of visits" &&
      action?.onAcceptValue?.value?.toString() === "1"
    ) {
      serviceDatesMessage = "visit";
    } else if (ruleNudgeProcedureCode && ruleNudgeProcedureCode.unitType === "Number of visits") {
      serviceDatesMessage = "visits";
    } else if (
      ruleNudgeProcedureCode &&
      ruleNudgeProcedureCode.unitType === "Units" &&
      action?.onAcceptValue?.value?.toString() === "1"
    ) {
      serviceDatesMessage = "unit";
    } else {
      serviceDatesMessage = "units";
    }
    return action?.onAcceptValue?.value + " " + (unitTypeLabel(serviceRequest?.unitType || "") || serviceDatesMessage);
  } else if (action?.onAcceptAttribute === "urgency.isExpedited") {
    newValue = action?.onAcceptValue?.value ? "Expedited" : "Not expedited";
  } else if (action?.onAcceptAttribute === "placeOfService") {
    newValue = (action?.onAcceptValue?.value as PlaceOfService)?.name || "";
  } else if (action?.onAcceptAttribute === "encounterType") {
    newValue = (action?.onAcceptValue?.value as string) || "";
  } else if (
    action?.onAcceptAttribute === "rangeOfOptions?SERVICE_CHANGE" &&
    typeof action.onAcceptValue?.value === "object" &&
    "clinicalService" in action.onAcceptValue?.value
  ) {
    newValue = action.onAcceptValue?.value?.clinicalService.name || "";
  } else {
    newValue = action?.onAcceptValue?.value?.toString() || "";
  }
  return convertFromShoutyCase(newValue) || "";
};

type SRKey = keyof ExtendedServiceRequestResponse;
//necessary for embedded fields
interface ExtendedServiceRequestResponse extends ServiceRequestOutreachOpportunitySnapshot {
  "urgency.isExpedited"?: string;
}

interface IndexableServiceRequestResponse extends ExtendedServiceRequestResponse {
  [key: string]: any;
}

function validateAttributeOnServiceRequest(
  ipt: string | undefined,
  sr: IndexableServiceRequestResponse
): asserts ipt is SRKey {
  if (ipt === "urgency.isExpedited") {
    if (sr.urgency?.isExpedited === undefined) {
      throw new Error(`Invalid ServiceRequestResponse attribute on nudge: isExpedited`);
    }
  } else if (ipt === undefined || sr[ipt] === undefined) {
    throw new Error(`Invalid ServiceRequestResponse attribute on nudge: ${ipt ? ipt : "undefined"}`);
  }
}

export const procedureCodesText = (
  action: ValidDisplayMessageAction | undefined,
  serviceRequest: ServiceRequestResponse
) => {
  const procedureCodesList: string[] | undefined = (action?.onAcceptValue?.value as ProcedureCode[])?.map(
    (cpt) => cpt.code
  );

  const diffCodes: string[] | undefined = difference(
    serviceRequest.procedureCodes?.map((cpt) => cpt.code),
    procedureCodesList
  );

  let newValue = "";

  switch (action?.onAcceptAction) {
    case "REPLACE_VALUE":
      newValue = `Replace all CPT codes with ${procedureCodesList?.join(", ")}`;
      break;
    case "LIMIT_TO_VALUES":
      newValue = `Remove ${diffCodes?.length} CPT codes: ${diffCodes?.join(", ")}`;
      break;
    case "ADD_VALUES":
      newValue = `Add ${procedureCodesList?.length} procedure code${
        procedureCodesList?.length > 1 ? "s" : ""
      }: ${procedureCodesList?.join(", ")}`;
      break;
    case "REMOVE_VALUES":
      newValue = `Remove ${procedureCodesList?.length} procedure code${
        procedureCodesList?.length > 1 ? "s" : ""
      }: ${procedureCodesList?.join(", ")}`;
      break;
  }
  return newValue;
};

export const noOnAcceptAttributeText = (action: ValidDisplayMessageAction | undefined) => {
  let retVal = "";
  switch (action?.onAcceptAction) {
    case "NONE":
      retVal = "Action needed outside of this service request";
      break;
    case "WITHDRAW":
      retVal = "Withdraw this service request";
      break;
    case "ADD_ATTACHMENTS":
      retVal = "Add attachments to this service request";
      break;
    default:
      retVal = "";
  }
  return retVal;
};

export const convertFromShoutyCase = (word: string) => word?.substring(0, 1) + word?.substring(1)?.toLowerCase();

export const convertNumberToString = (number?: number) => {
  if (!number) {
    return "";
  }
  switch (number) {
    case 1:
      return "1st";
    case 2:
      return "2nd";
    case 3:
      return "3rd";
    case 4:
      return "4th+";
    default:
      return "";
  }
};

export const countSuccessfulAttempts = (opportunities: OutreachOpportunity[] | null) => {
  let count = 0;
  opportunities?.forEach((opportunity) => {
    opportunity.outreachAttempts?.forEach((attempt) => {
      if (
        ((attempt.status === "ACCEPTED" || attempt.outreachOutcome === "SUCCESS") && !attempt.outreachType) ||
        ((attempt.status === "ACCEPTED" || attempt.outreachOutcome === "SUCCESS") &&
          attempt.outreachType === "MISSING_INFO_CLINICAL")
      ) {
        count++;
      }
    });
  });
  return count;
};

export const countFailedAttempts = (opportunities: OutreachOpportunity[] | null) => {
  let count = 0;
  opportunities?.forEach((opportunity) => {
    opportunity.outreachAttempts?.forEach((attempt) => {
      if (
        ((attempt.status === "ACCEPTED" || attempt.outreachOutcome === "FAILED") && !attempt.outreachType) ||
        ((attempt.status === "ACCEPTED" || attempt.outreachOutcome === "FAILED") &&
          attempt.outreachType === "MISSING_INFO_CLINICAL")
      ) {
        count++;
      }
    });
  });
  return count;
};

export const countOutreachTypeAttempts = (opportunities: OutreachOpportunity[] | null, outreachType: OutreachType) => {
  let count = 0;
  opportunities?.forEach((opportunity) => {
    opportunity.outreachAttempts?.forEach((attempt) => {
      if (attempt.outreachType === outreachType) {
        count++;
      }
    });
  });
  return count;
};

export const determineMIClinicalStatusChip = (outreachAttempt: OutreachAttempt) => {
  return outreachAttempt?.manual ? outreachAttempt.outreachOutcome || "SUCCESS" : outreachAttempt?.status || "ACCEPTED";
};

export const DEDICATED_NUDGE_STEP_TARGETS_FOR_ACTION: TargetFieldLabel[] = [
  "Site of service change",
  "Place of service change",
  "Unit reduction",
  "Swap service",
  "Urgency change",
  "Does not submit / withdraw",
  "Add attachments",
];

export const DEDICATED_NUDGE_STEP_TARGETS_FOR_ACTION_WITHOUT_ADD_ATTACHMENTS =
  DEDICATED_NUDGE_STEP_TARGETS_FOR_ACTION.filter((targetForAction) => targetForAction !== "Add attachments");

export const DEDICATED_NUDGE_STEP_TARGETS_FOR_ACTION_WITHOUT_SERVICE_CHANGE_ADD_ATTACHMENTS =
  DEDICATED_NUDGE_STEP_TARGETS_FOR_ACTION.filter((targetForAction) => {
    return targetForAction !== "Swap service" && targetForAction !== "Add attachments";
  });

export const getTargetsForAction = (addAttachmentDedicatedNudgeFF: boolean): TargetFieldLabel[] => {
  if (addAttachmentDedicatedNudgeFF) {
    return DEDICATED_NUDGE_STEP_TARGETS_FOR_ACTION;
  } else {
    return DEDICATED_NUDGE_STEP_TARGETS_FOR_ACTION_WITHOUT_ADD_ATTACHMENTS;
  }
};

export function useHasAcceptedAllNudges(dedicatedNudgeResponses: boolean[]) {
  const hasAcceptedAllDnsNudges = useMemo(
    () => !!dedicatedNudgeResponses.length && !dedicatedNudgeResponses.includes(false),
    [dedicatedNudgeResponses]
  );

  return hasAcceptedAllDnsNudges;
}

export const nudgeUnitType = (pxCode: ProcedureCode | undefined) => {
  if (pxCode?.unitType === "Number of visits") {
    return "visit";
  } else {
    return "unit";
  }
};

export const updatedUnits = (option: ServiceChangeOption): string => {
  const unitType = nudgeUnitType(option.procedureCode);
  return `${option.units?.toString()} ${unitType}${option.units?.toString() !== "1" ? "s" : ""}`;
};

// Determine which nudges have been accepted,
// currently used only for "Service change" to drive
// banner display logic on review page.
// This function returns every accepted nudge
// allowing to scale this to other use cases.
export const generateAcceptedNudgeMessages = (ruleActions: RuleActions, acceptedDedicatedNudges: string[]) => {
  const acceptedNudgeOnAcceptAttributes = ruleActions
    .filter((action): action is RecommendChangeRuleAction => "groupId" in action)
    .filter(({ groupId }) => acceptedDedicatedNudges.some((nudgeId) => nudgeId === groupId))
    .map(({ onAcceptAttribute }) => onAcceptAttribute);

  const messageMapping = acceptedNudgeOnAcceptAttributes
    .map((attribute) => {
      const fieldOptions = Object.values(TARGET_FIELD_OPTIONS_MAP);
      return fieldOptions.find((option) => option.onAcceptAttribute === attribute)?.label || "";
    })
    .filter((label) => label);

  return messageMapping;
};

// Function that determines if a given service request has more than one clinical service, i.e. fits into the flex-code criteria
export const isNotFlexCodeServiceRequest = (procedureCodes: ProcedureCode[] | undefined): boolean => {
  let servicesSet = new Set<string>();

  procedureCodes &&
    procedureCodes.forEach((px) => {
      if (px.groupId) {
        if (!servicesSet.has(px.groupId)) {
          servicesSet.add(px.groupId);
        }
      }
    });
  return servicesSet.size === 1;
};

// For range options only. Helps determine the badge text
export const getAdditionalBadgeText = (index: number): string => {
  if (index === 0) {
    return "Most recommended";
  } else {
    return "Recommended";
  }
};
