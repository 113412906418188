import React from "react";
import { GetDataError } from "restful-react";

import { Subtitle1 } from "@coherehealth/common";
import { Patient } from "@coherehealth/core-platform-api";
import Grid from "@material-ui/core/Grid";

import { generatePath } from "react-router-dom";
import routes from "routes";
import EmptyResultsPatientLookup from "components/DashboardPage/EmptyResultsPatientLookup";
import { ReferralPatientSearchResultCard } from "./ReferralPatientSearchResultCard";

interface Props {
  error: GetDataError<unknown> | null;
  patients: Patient[] | null;
  showSummaryLink?: boolean;
  showCoverageEndDate?: boolean;
}

export const ReferralPatientSearchResult = ({ error, patients, showSummaryLink, showCoverageEndDate }: Props) => {
  return (
    <>
      {error ? (
        <p>{error.message}</p>
      ) : !patients || patients.length === 0 ? (
        // reusing the existing component from auth
        <EmptyResultsPatientLookup />
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Subtitle1 color="textSecondary">
              {patients.length > 1 ? `${patients.length} results` : "1 result"}
            </Subtitle1>
          </Grid>
          {patients.map((patient) => (
            <Grid item xs={12} key={patient.id}>
              <ReferralPatientSearchResultCard
                patient={patient}
                selectLink={generatePath(routes.PATIENT_SUMMARY, { patientId: patient.id })}
                showSummaryLink={showSummaryLink}
                showCoverageEndDate={showCoverageEndDate}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};
