import { useFeature } from "@coherehealth/common";

export const useClinicalReviewFeatureFlags = () => {
  const updatedClinicalReviewNudges = useFeature("updatedClinicalReviewNudges");
  const genericNotes = useFeature("genericNotes");
  const claimHistoryFeature = useFeature("claimHistoryFeature");
  const auditReviews = useFeature("auditReviews");
  const postDecisionP2PExpanded = useFeature("postDecisionP2PExpanded");
  const reviewHistoryAboveCurrentReviewNote = useFeature("reviewHistoryAboveCurrentReviewNote");
  const expandedReviewStayDatesEdits = useFeature("expandedReviewStayDatesEdits");

  return {
    updatedClinicalReviewNudges,
    genericNotes,
    claimHistoryFeature,
    auditReviews,
    postDecisionP2PExpanded,
    reviewHistoryAboveCurrentReviewNote,
    expandedReviewStayDatesEdits,
  };
};
