import React, { useCallback, useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import { useSnackbar } from "notistack";
import { assertIsApiError } from "util/api";

import { checkSRStatus, StatusCheckResponse } from "./checkStatusApi";
import "./SearchFields.css";

interface Props {
  setStatusResults: (results: StatusCheckResponse | null) => void;
  onSearchClick: () => void;
}

export default function SearchByMemberInfo({ setStatusResults, onSearchClick }: Props) {
  const [trackingNumber, setTrackingNumber] = useState("");
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const validate = () => {
    return trackingNumber.length === 8;
  };

  const searchWithTrackingNumber = useCallback(
    async (evt) => {
      evt.preventDefault();

      try {
        onSearchClick();
        setSubmissionLoading(true);
        const response = await checkSRStatus({ cohereId: trackingNumber.toUpperCase() });
        setSubmissionLoading(false);
        if (response) {
          setStatusResults(response);
        }
      } catch (e) {
        assertIsApiError(e);
        setSubmissionLoading(false);
        enqueueSnackbar(e.message, {
          variant: "error",
          preventDuplicate: true,
        });
      }
    },
    [enqueueSnackbar, onSearchClick, setStatusResults, trackingNumber]
  );

  return (
    <form onSubmit={searchWithTrackingNumber}>
      <label className="searchFieldLabel" htmlFor="cohere-tracking-number-search">
        Tracking number *
      </label>
      <div className="searchFieldInputContainer">
        <input
          className="searchFieldInput"
          id="cohere-tracking-number-search"
          placeholder="e.g. AAAA0000"
          value={trackingNumber}
          maxLength={8}
          onChange={(e) => setTrackingNumber(e.target.value)}
        />
        {validate() && <CheckIcon className="searchFieldsValidateIcon" />}
      </div>
      <div className="buttonContainer">
        <button className="searchButton" disabled={!validate() || submissionLoading} type="submit">
          {submissionLoading ? <CircularProgress color="inherit" size={32} /> : "Search"}
        </button>
      </div>
    </form>
  );
}
