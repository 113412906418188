import { SVGProps } from "react";

export default function MagnifyingGlassIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="202" height="202" viewBox="0 0 202 202" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M101.366 201.367C156.595 201.367 201.366 156.596 201.366 101.367C201.366 46.1387 156.595 1.36719 101.366 1.36719C46.1377 1.36719 1.36621 46.1387 1.36621 101.367C1.36621 156.596 46.1377 201.367 101.366 201.367Z"
        fill="black"
        fill-opacity="0.04"
      />
      <path d="M107.578 99.5739L99.8538 107.388L121.855 129.135L129.579 121.32L107.578 99.5739Z" fill="#6948A6" />
      <path
        d="M110.505 55.9998C95.3738 41.0434 70.9563 41.1855 56 56.3169C41.0436 71.4483 41.1856 95.8658 56.3171 110.822C71.4485 125.779 95.866 125.636 110.822 110.505C125.779 95.3736 125.637 70.9561 110.505 55.9998ZM102.792 102.682C92.2072 113.391 74.8488 113.492 64.1404 102.907C53.432 92.3227 53.331 74.9645 63.9155 64.2561C74.5001 53.5477 91.8584 53.4467 102.567 64.0312C113.391 74.6151 113.491 91.8576 102.792 102.682Z"
        fill="#6948A6"
      />
      <path
        d="M122.38 112.311L112.747 122.053C111.486 123.328 111.498 125.404 112.774 126.549L141.313 154.749C142.589 156.01 144.665 155.998 145.811 154.723L155.443 144.981C156.705 143.705 156.693 141.63 155.417 140.484L126.877 112.169C125.717 111.023 123.641 111.035 122.38 112.311Z"
        fill="#3E276A"
      />
    </svg>
  );
}
