import { H2, H4 } from "@coherehealth/common";
import { ReferralRequestResponse } from "@coherehealth/core-platform-api";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ReferralPreReviewDetails from "./ReferralPreReviewDetails";
import { splitCamelToString } from "./../../common/stringutils";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    padding: theme.spacing(2, 0, 1, 0),
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  requestHeader: {
    paddingBottom: theme.spacing(1),
  },
  requestDetailsSubheader: {
    paddingLeft: theme.spacing(1),
    whiteSpace: "nowrap",
    alignSelf: "center",
  },
}));

interface Props {
  referralRequest: ReferralRequestResponse;
  isAdministrative: boolean;
}

export default function ReferralPreReviewContent({ referralRequest, isAdministrative }: Props) {
  const classes = useStyles();
  const referralTitle = `${
    referralRequest.specialty ? `${splitCamelToString(referralRequest.specialty)} ` : ""
  }Referral Request`;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <H2 className={classes.requestHeader}>{referralTitle}</H2>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <div className={classes.headerContainer}>
        <H4 className={classes.requestDetailsSubheader}>Details</H4>
      </div>
      <Grid item xs={12}>
        <Grid container direction="column" spacing={3} style={{ padding: 0 }}>
          <Grid item container>
            <ReferralPreReviewDetails referralRequest={referralRequest} isAdministrative={isAdministrative} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
