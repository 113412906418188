import { SingleSelectDropdown } from "@coherehealth/common";
import { isAuthStatus } from "util/serviceRequest";
import { ChangeStatusFormState, getAuthStatusDropdownLabel } from "../utils";
import { AuthStatusWithLabel } from "@coherehealth/core-platform-api";

interface AuthStatusSelectDropdownProps {
  formState: ChangeStatusFormState;
  nextAllowedAuthStatuses?: AuthStatusWithLabel[];
  postDecisionP2PExpanded: boolean;
  setFormState: (value: React.SetStateAction<ChangeStatusFormState>) => void;
}

export default function AuthStatusSelectDropdown(props: AuthStatusSelectDropdownProps): JSX.Element {
  const { formState, nextAllowedAuthStatuses, postDecisionP2PExpanded, setFormState } = props;
  return (
    <SingleSelectDropdown
      label={getAuthStatusDropdownLabel({
        isPostDenialEligible: formState.peerToPeer?.isPostDenialEligible,
        postDecisionP2PExpanded,
      })}
      value={formState.authStatus}
      options={nextAllowedAuthStatuses}
      onChange={(status) => {
        if (isAuthStatus(status)) {
          setFormState((prevFormState) => ({
            ...prevFormState,
            authStatus: status,
          }));
        }
      }}
      data-testid="auth-status-select-dropdown"
    />
  );
}
