import React, { FunctionComponent } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Box, Container, styled, Theme } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import {
  Body2,
  Body3,
  Caption,
  EnvironmentIndicator,
  Tooltip,
  environmentAbbreviation,
  formatDateStrAsEastern,
  shouldDisplayActuatorInfo,
  shouldDisplayEnvironmentIndicator,
  useMuiContainerStyles,
} from "@coherehealth/common";
import { useGetActuatorInfo } from "@coherehealth/core-platform-api";
import MuiInfoIcon from "@material-ui/icons/InfoOutlined";
import config from "api/config";

interface StyleProps {
  height: number;
}

interface Props extends StyleProps {
  height: number;
  children: NonNullable<React.ReactNode>;
  hasEnvIndicator?: boolean;
  justifyContentVal?: string;
  isDesktopScreen?: boolean;
  isVerySmallScreen?: boolean;
}

/**
 * A wrapper for any fixed headers we want to show at the top of a parent component.
 *
 * This creates an MUI app bar with a border bottom and no drop-shadow (elevation:0)
 * It also surrounds the content with a container that has a maxWidth="lg" set,
 * so it should match the maxWidth for the auth page content.
 *
 * The goal here is to abstract away some boiler plate code and allow us to set the
 * height of each fixed header in its parent once, and account for any padding there.
 * It may be nice to move this into common eventually, but we should make sure it's stable
 * first.
 */
const HeaderContainer: FunctionComponent<Props> = (props: Props) => {
  const appBarClasses = useAppBarStyles(props);
  const containerClasses = useMuiContainerStyles();
  const env = environmentAbbreviation();
  const { data: actuatorInfo } = useGetActuatorInfo({
    requestOptions: () => ({ headers: { Accept: "application/json" } }),
  });

  const displayEnvIndicator = props?.hasEnvIndicator && shouldDisplayEnvironmentIndicator(env);
  const centerContent = props?.justifyContentVal || "space-between";
  const ActuatorInfoTooltip = () => (
    <Tooltip
      title={
        <>
          <Box marginBottom={1}>
            <Body2 style={{ marginBottom: 4 }}>core-platform</Body2>
            <ActuatorInfoRow>
              <Caption>Branch</Caption>
              <Body3>{actuatorInfo?.git?.branch}</Body3>
            </ActuatorInfoRow>
            <ActuatorInfoRow>
              <Caption>Commit</Caption>
              <Body3>{actuatorInfo?.git?.commit?.id}</Body3>
            </ActuatorInfoRow>
            <ActuatorInfoRow>
              <Caption>Time</Caption>
              <Body3>{formatDateStrAsEastern(actuatorInfo?.git?.commit?.time)}</Body3>
            </ActuatorInfoRow>
          </Box>

          <Box>
            <Body2 style={{ marginBottom: 4 }}>frontend</Body2>
            <ActuatorInfoRow>
              <Caption>Branch</Caption>
              <Body3>{config.CURRENT_BRANCH}</Body3>
            </ActuatorInfoRow>
            <ActuatorInfoRow>
              <Caption>Commit</Caption>
              <Body3>{config.CURRENT_GIT_HASH.substring(0, 7)}</Body3>
            </ActuatorInfoRow>
            <ActuatorInfoRow>
              <Caption>Time</Caption>
              <Body3>{formatDateStrAsEastern(config.CURRENT_GIT_HASH_TIMESTAMP)}</Body3>
            </ActuatorInfoRow>
          </Box>
        </>
      }
      placement="bottom"
    >
      <InfoIcon fontSize="small" />
    </Tooltip>
  );

  return (
    <AppBar classes={appBarClasses} elevation={0}>
      {displayEnvIndicator && (
        <EnvironmentIndicator env={env}>
          {shouldDisplayActuatorInfo(env) && <ActuatorInfoTooltip />}
        </EnvironmentIndicator>
      )}
      <CenteredContainer
        classes={containerClasses}
        style={{
          height: displayEnvIndicator ? (props.isVerySmallScreen && !props.isDesktopScreen ? 84 : 74) : undefined,
          justifyContent: centerContent,
        }}
        maxWidth="lg"
      >
        {props.children}
      </CenteredContainer>
    </AppBar>
  );
};

export default HeaderContainer;

const useAppBarStyles = makeStyles<Theme, StyleProps>((theme) => ({
  colorPrimary: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
  },
  positionFixed: {
    top: "auto", // allows us to stack headers
  },
  root: (props) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: props.height,
    transition: "height .5s ease",
  }),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CenteredContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  height: "100%",
  width: "100%",
  maxWidth: "100%",
  justifyContent: "space-between",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ActuatorInfoRow = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(0.5),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const InfoIcon = styled(MuiInfoIcon)(({ theme }) => ({
  color: theme.palette.common.white,
}));
