import { registerRoute } from "../server";
import { Facility } from "../../index";

export default function registerRoutes() {
  registerRoute({
    id: "facilitySearch",
    route: "facility",
    method: "get", // the OPTIONS request won't be sent when Mirage is handling the route
    generateResponse: function (config, schema, request) {
      const queryParams = request.queryParams;
      let result = (schema as any).facilities.all();
      if (queryParams) {
        if (queryParams.query) {
          result = (schema as any).facilities.where((item: Facility) => {
            return (
              item.name?.match(new RegExp(queryParams.query, "i")) ||
              item.npi?.match(new RegExp(queryParams.query, "i"))
            );
          });
        }
      }
      // Limit to first 10
      result.models = result.models.slice(0, 10);
      return result;
    },
  });
}
