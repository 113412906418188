// Cohere Health Contact info
const CONTACT_PHONE = "1-833-283-0033";
export const CONTACT_PHONE_US_FORMAT = "(833) 283-0033";
const CONTACT_EMAIL = "support@coherehealth.com";

interface contactInfo {
  email?: string;
  phone: string;
  contactName: string;
}

// Fetches the contact info for the given health plan.
export const getHealthPlanContactInfo = (healthPlanName: string | undefined): contactInfo => {
  return { email: CONTACT_EMAIL, phone: CONTACT_PHONE, contactName: "us" };
};
