import { Box, Grid, Tab, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { StyledTabs } from "components/ClinicalReview/Review/ClinicalReviewPage";
import { useEffect, useState } from "react";
import NewNoteForm from "./NewNoteForm";
import {
  ReviewControlBoxInnerContainer,
  ReviewControlBoxOuterContainer,
} from "components/ServiceRequest/ReviewSection/MDReview/MDReviewEdit";
import { InformativeModal, PrimaryButton, SecondaryButton, stripHTMl, useConfiguration } from "@coherehealth/common";
import { NoteSubmitPayload, NoteType, useSaveNote } from "@coherehealth/core-platform-api";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import { extractErrorDetails } from "components/ServiceRequest/ReviewSection/util/ReviewSectionUtil";
import { useNavigate } from "react-router";
import { RIGHT_HAND_PANEL_SIZE } from "components/ClinicalReview/reviewUtils/utils";
import { navigateToPS } from "components/AuthBuilder/common";
import { NextReviewDateSelect } from "components/ServiceRequest/ReviewSection/common/NextReviewDateSelect";
import { shouldShowNextReviewDate } from "components/ClinicalReview/ClinicalReviewInfoPanel/NotesCard/noteUtils";

type Tabs = "NOTES";

interface NewNoteProps {
  serviceRequest: ServiceRequestResponse;
}

export interface NoteEditState extends Omit<NoteSubmitPayload, "noteType"> {
  noteType: NoteType | "";
}

const NewNote = ({ serviceRequest }: NewNoteProps) => {
  const [rightTabIndex, setRightTabIndex] = useState<Tabs>("NOTES");
  const initialNote: NoteEditState = {
    noteContent: "",
    noteType: "",
    dSnpChCode: "",
    dSnpMcoConfirmation: "",
    dSnpMedicaidRecipientNumber: "",
    hasSNFWaiver: false,
    claimsPaymentNote: "",
    dSnpMedicaidRecipientNumberOptional: false,
    coverageEndedBy: "",
    requestor: "",
  };
  const [currentNote, setCurrentNote] = useState<NoteEditState>(initialNote);
  const [openNoteSaveModal, setOpenNoteSaveModal] = useState(false);
  const [nextReviewDate, setNextReviewDate] = useState<Date | null>(null);
  const [isUpdatingOtherFields, setIsUpdatingOtherFields] = useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const {
    mutate: saveNote,
    loading: savingNote,
    error: saveNoteError,
  } = useSaveNote({ serviceRequestId: serviceRequest.id });

  const onClickSaveNote = () => {
    if (
      shouldShowNextReviewDate({
        encounterType: serviceRequest.encounterType,
        authStatus: serviceRequest.authStatus,
        patientStatus: serviceRequest.patientStatus,
      })
    ) {
      setOpenNoteSaveModal(true);
    } else {
      onSave();
    }
  };

  const generateSavePayload = (saveOtherFields = false): NoteSubmitPayload => {
    const notePayload: NoteSubmitPayload = { ...currentNote, noteType: currentNote.noteType || "ADMINISTRATIVE" };
    if (saveOtherFields) {
      if (nextReviewDate) {
        notePayload.nextReviewDate = nextReviewDate.toISOString();
      }
    }
    return notePayload;
  };
  const onSave = async (saveOtherFields = false) => {
    setIsUpdatingOtherFields(saveOtherFields);
    const notePayload = generateSavePayload(saveOtherFields);
    await saveNote(notePayload);
    navigateToPS(serviceRequest?.patient?.id || "", navigate, serviceRequest.id);
  };

  const noteConfig = useConfiguration("noteConfiguration", serviceRequest.healthPlanName);

  useEffect(() => {
    if (saveNoteError) {
      enqueueSnackbar(`Error saving note: ${extractErrorDetails(saveNoteError).message}`, { variant: "error" });
    }
  }, [saveNoteError, enqueueSnackbar]);

  useEffect(() => {
    const firstNoteTypeOption = noteConfig?.noteTypeOptions?.[0];
    if (firstNoteTypeOption) {
      setCurrentNote((prevCurrentNote) => ({ ...prevCurrentNote, noteType: firstNoteTypeOption }));
      return;
    }
    setCurrentNote((prevCurrentNote) => ({ ...prevCurrentNote, noteType: "ADMINISTRATIVE" }));
  }, [noteConfig?.noteTypeOptions]);

  const isNoteValid = () => {
    const {
      noteType,
      noteContent,
      dSnpChCode,
      dSnpMcoConfirmation,
      dSnpMedicaidRecipientNumber,
      claimsPaymentNote,
      dSnpMedicaidRecipientNumberOptional,
      requestor,
      appealRequestedOn,
      coverageEndedBy,
    } = currentNote;
    if (["ADMINISTRATIVE", "CONTINUITY_OF_CARE"].includes(noteType)) {
      if (stripHTMl(noteContent || "")) {
        return true;
      }
    }
    if (noteType === "D_SNP") {
      if (dSnpChCode && dSnpMcoConfirmation) {
        if (dSnpMedicaidRecipientNumber) {
          return true;
        } else {
          return dSnpMedicaidRecipientNumberOptional;
        }
      }
    }
    if (noteType === "SNF_THREE_DAY_WAIVER_NOTE") {
      return true;
    }

    if (noteType === "CLAIMS_PAYMENT") {
      return !!claimsPaymentNote;
    }
    if (noteType === "APPEALS") {
      if (requestor && stripHTMl(noteContent || "") && appealRequestedOn) {
        return true;
      }
    }

    if (noteType === "LIVANTA") {
      if (coverageEndedBy && stripHTMl(noteContent || "")) {
        return true;
      }
    }
    return false;
  };

  const saveNoteDisabled = () => {
    if (savingNote) {
      return true;
    }
    if (!isNoteValid()) {
      return true;
    }
    return false;
  };

  const closeModal = () => {
    setOpenNoteSaveModal(false);
    setNextReviewDate(null);
  };

  const modalPrimaryButtonDisabled = () => {
    if (savingNote) {
      return true;
    }
    return !nextReviewDate;
  };

  const theme = useTheme();
  const matchesRight = useMediaQuery(theme.breakpoints.up(RIGHT_HAND_PANEL_SIZE));

  return (
    <Grid container direction="column">
      <StyledTabs
        value={rightTabIndex}
        aria-label="note-tabs"
        onChange={(_, index) => {
          setRightTabIndex(index);
        }}
        style={{ margin: "0px -24px", position: "sticky", top: 0, backgroundColor: "white", zIndex: 2 }}
      >
        <Tab key={"notes"} style={{ width: "150px" }} label={"Notes"} value={"NOTES"} className={classes.panelTab} />
      </StyledTabs>
      {rightTabIndex === "NOTES" && (
        <NewNoteForm
          currentNote={currentNote}
          setCurrentNote={setCurrentNote}
          savingNote={savingNote}
          noteConfig={noteConfig}
        />
      )}
      <InformativeModal
        open={openNoteSaveModal}
        onClose={closeModal}
        headerText="Do you want to update Next Review Date?"
        additionalInfoElement={
          <Box width="100%">
            <Box mt="32px" mb="16px">
              <NextReviewDateSelect nextReviewDate={nextReviewDate} setNextReviewDate={setNextReviewDate} />
            </Box>
          </Box>
        }
        showDivider={false}
        primaryButtonText="Update"
        primaryButtonAction={() => onSave(true)}
        customButtonStyle={classes.modalPrimaryButton}
        primaryButtonDisabled={modalPrimaryButtonDisabled()}
        primaryButtonLoading={savingNote && isUpdatingOtherFields}
        primaryButtonTestid="new-note--modal--save-button"
        tertiaryButtonText="Save note without updating"
        tertiaryButtonAction={() => onSave(false)}
        tertiaryButtonDisabled={savingNote}
        tertiaryButtonLoading={savingNote && !isUpdatingOtherFields}
      />
      <ReviewControlBoxOuterContainer matchesRight={matchesRight} note>
        <ReviewControlBoxInnerContainer center>
          <SecondaryButton
            className={classes.discardButton}
            warning
            disabled={savingNote}
            onClick={() => {
              navigateToPS(serviceRequest?.patient?.id || "", navigate, serviceRequest.id);
            }}
            data-testid="notes--discard-changes-button"
          >
            Discard changes
          </SecondaryButton>
          <PrimaryButton
            className={classes.finishButton}
            onClick={onClickSaveNote}
            disabled={saveNoteDisabled()}
            loading={savingNote}
            data-testid="notes--save-button"
          >
            Save
          </PrimaryButton>
        </ReviewControlBoxInnerContainer>
      </ReviewControlBoxOuterContainer>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  panelTab: {
    "&:hover span": {
      opacity: 0.7,
    },
  },
  modalPrimaryButton: {
    width: "138px",
    padding: theme.spacing(2, 10),
    marginTop: theme.spacing(2),
  },
  discardButton: {
    width: 258,
    height: 48,
    padding: theme.spacing(0),
    marginRight: theme.spacing(3),
  },
  finishButton: {
    width: 258,
    height: 48,
    marginLeft: theme.spacing(3),
  },
}));

export default NewNote;
