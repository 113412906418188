import { DropdownOption, SingleSelect } from "@coherehealth/common";
import { Row } from "./OutOfNetworkReviewSubmissionModal";
import {
  OutOfNetworkReviewOutcomeOption,
  ReviewOutcomeOption,
  ReviewReasonOutcomeOption,
} from "../util/QueueManagementReviewUtil";
import { OutOfNetworkReviewConfigurationResponse, ReviewOutcome } from "@coherehealth/core-platform-api";
import { configToDropDownOption } from "components/ClinicalReview/reviewUtils/utils";
import { OutOfNetworkReviewUpdate } from "components/ClinicalReview/reviewUtils/useReviews";

interface OutOfNetworkReviewModalFormProps {
  allowedReviewOutcomes: ReviewOutcomeOption[];
  reviewOutcome: ReviewOutcome | undefined;
  outcomeReason: string | undefined;
  setReview: OutOfNetworkReviewUpdate | undefined;
  oonReviewConfig: OutOfNetworkReviewConfigurationResponse | undefined;
}

const reviewOutcomeOptions: OutOfNetworkReviewOutcomeOption[] = [
  { id: "APPROVED", label: "Approved" },
  { id: "RECOMMENDED_DENIAL", label: "Recommended denial" },
];

const OutOfNetworkReviewModalForm = ({
  allowedReviewOutcomes,
  reviewOutcome,
  setReview,
  oonReviewConfig,
  outcomeReason,
}: OutOfNetworkReviewModalFormProps) => {
  const outcomeReasonOptionsFromOONConfig =
    oonReviewConfig?.exceptionReasonOptions && configToDropDownOption(oonReviewConfig?.exceptionReasonOptions);

  const reviewOutcomeMap = new Map(allowedReviewOutcomes?.map((outcome) => [outcome.id, outcome]));

  const getOutcomeReasonMap = () => {
    const selectedOutcome = reviewOutcomeMap.get(reviewOutcome);
    return selectedOutcome ? new Map(selectedOutcome?.submenu?.map((reason) => [reason.id, reason])) : new Map();
  };
  const outcomeReasonMap = getOutcomeReasonMap();

  const getSelectedOONReviewOutcomeValue = () => {
    const valueFromConfig = reviewOutcomeMap.get(reviewOutcome) || null;
    const fallbackValues = reviewOutcomeOptions.find(({ id }) => reviewOutcome === id) || null;
    if (valueFromConfig) {
      return valueFromConfig;
    } else {
      return fallbackValues;
    }
  };

  const getOONOutcomeReasoningOptions = () => {
    const reasoningOptionsFromStatusTransitionConfig = allowedReviewOutcomes?.find(
      (option) => option.id === reviewOutcome
    )?.submenu;
    if (reasoningOptionsFromStatusTransitionConfig) {
      return reasoningOptionsFromStatusTransitionConfig;
    } else {
      return outcomeReasonOptionsFromOONConfig;
    }
  };
  const getSelectedOONOutcomeReasonValue = () => {
    const outcomeReasonFromStatusTransitionConfig = outcomeReasonMap.get(outcomeReason) || null;
    const outcomeReasonFromOONConfig =
      outcomeReasonOptionsFromOONConfig?.find(({ id }) => outcomeReason === id) || null;
    if (outcomeReasonFromStatusTransitionConfig) {
      return outcomeReasonFromStatusTransitionConfig;
    } else {
      return outcomeReasonFromOONConfig;
    }
  };

  return (
    <>
      <Row>
        <SingleSelect<OutOfNetworkReviewOutcomeOption | ReviewOutcomeOption>
          data-testid="oon-review-submission-modal-review-outcome"
          label="Out-of-Network Review outcome"
          useOptions={() => ({
            optionsLoading: false,
            options: allowedReviewOutcomes?.length ? allowedReviewOutcomes : reviewOutcomeOptions,
          })}
          selectedValue={getSelectedOONReviewOutcomeValue()}
          getOptionLabel={(opt) => opt.label}
          setSelectedValue={(opt) => {
            setReview?.({
              reviewOutcome: opt?.id as ReviewOutcome,
            });
          }}
        />
      </Row>
      <Row>
        <SingleSelect<DropdownOption | ReviewReasonOutcomeOption>
          data-testid="oon-review-submission-modal-outcome-reason"
          label="Out-of-Network Review outcome reasoning"
          useOptions={() => ({
            optionsLoading: !allowedReviewOutcomes,
            options: getOONOutcomeReasoningOptions(),
          })}
          selectedValue={getSelectedOONOutcomeReasonValue()}
          getOptionLabel={(opt) => (opt.label || opt.id) as string}
          setSelectedValue={(opt) => {
            setReview?.({
              outcomeReason: opt?.id,
            });
          }}
        />
      </Row>
    </>
  );
};

export default OutOfNetworkReviewModalForm;
