import { Dispatch, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Bundle, Endpoint, Questionnaire } from "@coherehealth/fhir-schema-types";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled, Theme } from "@material-ui/core/styles";
import {
  H5,
  PrimaryButton,
  H4,
  Body1,
  SingleSelectQuestion,
  MultiSelectQuestion,
  HEALTH_HELP_NAME,
} from "@coherehealth/common";
import CircularProgress from "@material-ui/core/CircularProgress";
import config from "api/config";
import { useGet, useMutate } from "restful-react";
import { useSnackbar } from "notistack";
import {
  buildUserAnswerResponseBundle,
  checkForFinalSubmit,
  extractAndUpdateHeadersAndPathFromBundle,
  fhirBundleOperationOutcomeErrorHandler,
  headersFromEndpoint,
  questionnaireToQuestionBundle,
  questionnaireToQuestionBundleForQuestionRendering,
} from "util/DelegatedVendorAssessmentUtils";
import { HealthHelpPASRequestResponse, useWithdrawHealthHelpV2Case } from "@coherehealth/core-platform-api";
import { error as logError } from "logger";
import { assertIsApiError } from "util/api";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: theme.spacing(20),
  },
  loadingSpinner: {
    display: "block",
    alignSelf: "center",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  centeredText: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
}));

interface Props {
  delegatedVendorName: string | undefined;
  healthHelpPASRequestResponse: HealthHelpPASRequestResponse;
  setQuestionnaireComplete: Dispatch<boolean>;
  setWorkflowStep: () => void;
  setQuestionnaireError: Dispatch<boolean>;
  serviceRequestId: string;
  delegatedVendorDisplay: (dv: string | undefined) => string | undefined;
}

export default function VendorAssessmentQuestions({
  delegatedVendorName,
  healthHelpPASRequestResponse,
  setQuestionnaireComplete,
  setWorkflowStep,
  setQuestionnaireError,
  serviceRequestId,
  delegatedVendorDisplay,
}: Props) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [userAnswers, setUserAnswers] = useState<Map<string, string>>(new Map());
  const [unansweredRequiredQuestions, setUnansweredRequiredQuestions] = useState<boolean>(false);

  if (checkForFinalSubmit(healthHelpPASRequestResponse?.responseBundle as unknown as Bundle)) {
    setWorkflowStep();
    setQuestionnaireComplete(true);
  }

  const endpointFromHeathHelpPAS: Endpoint = healthHelpPASRequestResponse?.responseBundle?.entry?.find(
    (e: any) => e?.resource?.resourceType === "Endpoint"
  )?.resource;

  const [questionnaireRequestHeaders, setQuestionnaireRequestHeaders] = useState<HeadersInit>(
    headersFromEndpoint(endpointFromHeathHelpPAS, healthHelpPASRequestResponse?.accessToken)
  );
  const [questionnaireRequestPath, setQuestionnaireRequestPath] = useState<string>(
    `QuestionnaireCors/COHR/V1/${healthHelpPASRequestResponse.vendorIdentifier}`
  );

  const [responseRequestHeaders, setResponseRequestHeaders] = useState<HeadersInit>([]);
  const [responseRequestPath, setResponseRequestPath] = useState<string>(
    `QuestionnaireCors/COHR/V1/${healthHelpPASRequestResponse.vendorIdentifier}`
  );

  const { mutate: withdrawHealthHelpV2Case } = useWithdrawHealthHelpV2Case({
    id: "",
  });

  const [shouldFetchQuestionnaire, setShouldFetchQuestionnaire] = useState<boolean>(true);
  const [getQuestionnaireLoading, setGetQuestionnaireLoading] = useState<boolean>(true);
  const [getQuestionnaireError, setGetQuestionnaireError] = useState<any>(undefined);

  const [questionnaireData, setQuestionnaireData] = useState<any>();

  const {
    mutate: sendQuestionnaireResponse,
    error: sendQuestionnaireResponseError,
    loading: sendQuestionnaireResponseLoading,
  } = useMutate({
    base: config.HEALTH_HELP_URL,
    verb: "POST",
    path: responseRequestPath,
  });

  const withdrawCaseFromHealthHelpV2 = useCallback(async () => {
    const integrationStage = getQuestionnaireError ? "Get Question from HealthHelp" : "Send Answer to HealthHelp";
    const requestType = getQuestionnaireError ? "GET" : "POST";
    const errorMessage = String(getQuestionnaireError) || String(sendQuestionnaireResponseError);
    await withdrawHealthHelpV2Case(
      {
        integrationStage: integrationStage,
        requestType: requestType,
        errorMessage: errorMessage,
      },
      { pathParams: { id: serviceRequestId || "" } }
    ).then(() => {
      setWorkflowStep();
    });
  }, [
    getQuestionnaireError,
    sendQuestionnaireResponseError,
    serviceRequestId,
    setWorkflowStep,
    withdrawHealthHelpV2Case,
  ]);

  const handleAssessmentFailure = useCallback(() => {
    if (delegatedVendorName === "HealthHelpV2") {
      withdrawCaseFromHealthHelpV2().then(() => {
        setQuestionnaireError(true);
        setWorkflowStep();
      });
    } else {
      setQuestionnaireError(true);
    }
  }, [delegatedVendorName, setQuestionnaireError, setWorkflowStep, withdrawCaseFromHealthHelpV2]);

  const safeJsonParse = useCallback(
    (text: string) => {
      if (text !== undefined) {
        try {
          const bundle = JSON.parse(text);
          //check the bundle for operation outcome errors/fatals
          fhirBundleOperationOutcomeErrorHandler(bundle, setQuestionnaireError, serviceRequestId);
          return bundle;
        } catch (e) {
          assertIsApiError(e);
          logError(new Error(`Failed to parse response: ${e.message}`));
          setQuestionnaireError(true);
          return {};
        }
      } else {
        return {};
      }
    },
    [serviceRequestId, setQuestionnaireError]
  );

  const parsedQuestionnaireData = useMemo(() => safeJsonParse(questionnaireData), [questionnaireData, safeJsonParse]);

  const questionnaire: Questionnaire | undefined = useMemo(
    () =>
      safeJsonParse(questionnaireData)?.entry?.find((e: any) => e.resource.resourceType === "Questionnaire")?.resource,
    [safeJsonParse, questionnaireData]
  );
  const questions = useMemo(
    () => (questionnaire ? questionnaireToQuestionBundle(questionnaire, handleAssessmentFailure) : undefined),
    [questionnaire, handleAssessmentFailure]
  );

  const questionsForQuestionRendering = useMemo(
    () =>
      questionnaire
        ? questionnaireToQuestionBundleForQuestionRendering(
            questionnaire,
            setQuestionnaireError,
            getQuestionnaireLoading
          )
        : undefined,
    [questionnaire, setQuestionnaireError, getQuestionnaireLoading]
  );

  useEffect(() => {
    extractAndUpdateHeadersAndPathFromBundle(
      parsedQuestionnaireData,
      healthHelpPASRequestResponse.accessToken,
      healthHelpPASRequestResponse.vendorIdentifier,
      setResponseRequestHeaders,
      setResponseRequestPath
    );
  }, [
    healthHelpPASRequestResponse.accessToken,
    healthHelpPASRequestResponse.vendorIdentifier,
    parsedQuestionnaireData,
  ]);

  useEffect(() => {
    if (getQuestionnaireError || sendQuestionnaireResponseError) {
      handleAssessmentFailure();
      logError(
        new Error(`${
          ("GET Questionnaire error:" && getQuestionnaireError?.message) ||
          ("POST QuestionnaireResponse error: " && sendQuestionnaireResponseError?.message)
        }
        Service Request ID: [${serviceRequestId}]`)
      );
      if (delegatedVendorName === HEALTH_HELP_NAME) {
        enqueueSnackbar(
          `Failed to communicate with ${delegatedVendorName || "our third party vendor"}, please try again`,
          {
            variant: "error",
            preventDuplicate: true,
          }
        );
      }
    }
  }, [
    getQuestionnaireError,
    sendQuestionnaireResponseError,
    sendQuestionnaireResponseLoading,
    getQuestionnaireLoading,
    enqueueSnackbar,
    delegatedVendorName,
    handleAssessmentFailure,
    serviceRequestId,
  ]);

  useEffect(() => {
    // if the response is done loading and we dont have questions
    if (
      (!questionsForQuestionRendering || questionsForQuestionRendering.length === 0) &&
      !sendQuestionnaireResponseLoading &&
      !getQuestionnaireLoading
    ) {
      handleAssessmentFailure();
      logError(
        new Error(`No questions loaded into Vendor Assessment! 
      Service Request ID: [${serviceRequestId}]
      Health Help Case Token: [${healthHelpPASRequestResponse.vendorIdentifier}]`)
      );
    }
  }, [
    delegatedVendorName,
    getQuestionnaireLoading,
    healthHelpPASRequestResponse.vendorIdentifier,
    questionsForQuestionRendering,
    sendQuestionnaireResponseLoading,
    serviceRequestId,
    handleAssessmentFailure,
  ]);

  /**
   * Check that all required questions are answered
   */
  const checkRequiredQuestions = useCallback(() => {
    const requiredQuestions = questions?.filter((q) => q.required);

    let unansweredQuestionExists = false;
    requiredQuestions?.forEach((question) => {
      const answer = userAnswers.get(question.id);
      if (!answer || answer === "") {
        unansweredQuestionExists = true;
        return;
      }
    });
    setUnansweredRequiredQuestions(unansweredQuestionExists);
  }, [questions, userAnswers]);

  //will disable submit button on first load of questions (if there are required questions unanswered)
  useEffect(() => {
    if (questions) {
      checkRequiredQuestions();
    }
  }, [checkRequiredQuestions, questions]);

  //will update disable state on submit button after user answers questions
  const updateUserAnswer = (questionId: string, selectedAnswerId: string) => {
    setUserAnswers(new Map(userAnswers.set(questionId, selectedAnswerId)));
    //React wont rerender if the reference to the map doesnt change :(
    checkRequiredQuestions();
  };

  const submitVendorAssessment = async () => {
    const questionnaireResponseBundle = buildUserAnswerResponseBundle(userAnswers, questions, setQuestionnaireError);

    const response = await sendQuestionnaireResponse(questionnaireResponseBundle, { headers: responseRequestHeaders });
    const responseParsed = safeJsonParse(response);
    if (checkForFinalSubmit(responseParsed)) {
      setWorkflowStep();
      setQuestionnaireComplete(true);
    } else {
      extractAndUpdateHeadersAndPathFromBundle(
        responseParsed,
        healthHelpPASRequestResponse.accessToken,
        healthHelpPASRequestResponse.vendorIdentifier,
        setQuestionnaireRequestHeaders,
        setQuestionnaireRequestPath
      );
      setShouldFetchQuestionnaire(true);
    }
    setUserAnswers(new Map());
  };

  return (
    <>
      <VendorAssessmentHeader delegatedVendorName={delegatedVendorDisplay(delegatedVendorName)} />
      {shouldFetchQuestionnaire ? (
        <FetchQuestionnaireComponent
          setQuestionnaireData={setQuestionnaireData}
          setShouldFetchQuestionnaire={setShouldFetchQuestionnaire}
          setGetQuestionnaireError={setGetQuestionnaireError}
          setGetQuestionnaireLoading={setGetQuestionnaireLoading}
          questionnaireRequestHeaders={questionnaireRequestHeaders}
          questionnaireRequestPath={questionnaireRequestPath}
        />
      ) : (
        <></>
      )}
      {getQuestionnaireLoading || sendQuestionnaireResponseLoading ? (
        <VendorAssessmentLoading />
      ) : (
        questionsForQuestionRendering?.map((question, index) => {
          if (question.type === "choice") {
            return (
              <Fragment key={index}>
                <SingleSelectQuestion
                  options={question.options!.map((option) => {
                    return { id: option.id, label: option.responseOptionText };
                  })}
                  selectedOption={userAnswers.get(question.id) || ""}
                  setSelectedOption={(selectedOpt) => {
                    updateUserAnswer(question.id, selectedOpt);
                  }}
                  ordinal={(index + 1).toString()}
                  questionText={question.questionText}
                  subtext={question?.subQuestionText}
                  key={index}
                  required={question?.required}
                />

                <QuestionSpacer />
              </Fragment>
            );
          } else if (question.type === "boolean") {
            return (
              <Fragment key={index}>
                <SingleSelectQuestion
                  required={question?.required}
                  options={[
                    { id: "true", label: "True" },
                    { id: "false", label: "False" },
                  ]}
                  selectedOption={userAnswers.get(question.id) || ""}
                  setSelectedOption={(selectedOpt) => {
                    updateUserAnswer(question.id, selectedOpt);
                  }}
                  ordinal={(index + 1).toString()}
                  questionText={question.questionText}
                  subtext={question?.subQuestionText}
                  key={index}
                />
                <QuestionSpacer />
              </Fragment>
            );
          } else if (question.type === "multi-choice") {
            return (
              <Fragment key={index}>
                <MultiSelectQuestion
                  required={question?.required}
                  options={question.options!.map((option) => {
                    return { id: option.id, label: option.responseOptionText };
                  })}
                  selections={question.options!.filter(({ id }) => userAnswers.get(id) === "true").map(({ id }) => id)}
                  setSelections={(selectedOptions) => {
                    // yuck :/
                    question.options!.forEach(({ id }) => {
                      updateUserAnswer(id, selectedOptions.includes(id) ? "true" : "false");
                    });
                  }}
                  ordinal={(index + 1).toString()}
                  questionText={question.questionText}
                  subtext={question?.subQuestionText}
                  key={index}
                />
                <QuestionSpacer />
              </Fragment>
            );
          } else {
            return <Fragment key={index} />;
          }
        })
      )}
      <ButtonWrapper>
        {!sendQuestionnaireResponseLoading && !getQuestionnaireLoading && (
          <PrimaryButton
            disabled={unansweredRequiredQuestions}
            fullWidth
            className={classes.button}
            onClick={submitVendorAssessment}
          >
            Submit
          </PrimaryButton>
        )}
      </ButtonWrapper>
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const QuestionSpacer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ButtonWrapper = styled("div")(({ theme }) => ({
  margin: theme.spacing(-1, 0, 0, 2),
  paddingRight: theme.spacing(2),
  width: "100%",
  height: theme.spacing(6),
  display: "flex",
  justifyContent: "flex-end",
}));

const VendorAssessmentHeader = ({ delegatedVendorName }: { delegatedVendorName: string | undefined }) => {
  return (
    <>
      <H4 style={{ paddingTop: "8px" }}>Clinical assessment questions from our vendor</H4>
      <Body1 style={{ paddingTop: "8px" }}>
        Cohere partners with {delegatedVendorName || "third party vendors"} for some authorization requests. There may
        be further questions based on your responses.
      </Body1>
      <QuestionSpacer />
    </>
  );
};

const VendorAssessmentLoading = () => {
  const classes = useStyles();
  return (
    <>
      <CircularProgress className={classes.loadingSpinner} size={100} />
      <H5 className={classes.centeredText}>
        Our vendor is processing your responses and may have additional questions
      </H5>
    </>
  );
};

interface FetchQuestionnaireComponentProps {
  setQuestionnaireData: Dispatch<any>;
  setShouldFetchQuestionnaire: Dispatch<boolean>;
  setGetQuestionnaireError: Dispatch<any>;
  setGetQuestionnaireLoading: Dispatch<boolean>;
  questionnaireRequestHeaders: HeadersInit;
  questionnaireRequestPath: string;
}

function FetchQuestionnaireComponent({
  setShouldFetchQuestionnaire,
  setQuestionnaireData,
  setGetQuestionnaireError,
  setGetQuestionnaireLoading,
  questionnaireRequestHeaders,
  questionnaireRequestPath,
}: FetchQuestionnaireComponentProps) {
  const {
    data: questionnaireData,
    error: getQuestionnaireError,
    loading: getQuestionnaireLoading,
    // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
    // eslint-disable-next-line @typescript-eslint/ban-types
  } = useGet<any, any, {}>({
    base: config.HEALTH_HELP_URL,
    requestOptions: {
      headers: questionnaireRequestHeaders,
    },
    path: questionnaireRequestPath,
  });

  useEffect(() => {
    if (questionnaireData) {
      setShouldFetchQuestionnaire(false);
      setQuestionnaireData(questionnaireData);
    }
    setGetQuestionnaireError(getQuestionnaireError);
    setGetQuestionnaireLoading(getQuestionnaireLoading);
  }, [
    setQuestionnaireData,
    setGetQuestionnaireError,
    setGetQuestionnaireLoading,
    questionnaireData,
    getQuestionnaireError,
    getQuestionnaireLoading,
    setShouldFetchQuestionnaire,
  ]);

  return <></>;
}
