import React, { Dispatch } from "react";

import { DropdownOption, SingleSelectDropdown } from "@coherehealth/common";
import { useTheme } from "@material-ui/core";

interface Props {
  onSelectAuthSubCategory: Dispatch<string>;
  selectedAuthSubCategory: string | null;
  authSubCategoryOptions: string[];
  attemptedSubmit: boolean;
  isLoading: boolean;
  onPatientSummary: boolean | undefined;
  disabled?: boolean;
}

interface AuthSubCategoryOption extends DropdownOption {
  id: string;
  label: string;
}

export function AuthSubCategorySelect({
  selectedAuthSubCategory,
  onSelectAuthSubCategory,
  authSubCategoryOptions,
  attemptedSubmit,
  isLoading,
  onPatientSummary,
  disabled,
}: Props) {
  const hasError = attemptedSubmit && !selectedAuthSubCategory;
  const helperText = hasError ? "Required" : "";
  const sortedAuthSubCategories = authSubCategoryOptions
    .map((subCategory) => ({ id: subCategory, label: subCategory }))
    .sort((a, b) => a.label.localeCompare(b.label));
  const theme = useTheme();

  const authCategorySpacing = hasError ? 10 : 7;

  return (
    <SingleSelectDropdown<AuthSubCategoryOption>
      error={hasError}
      helperText={helperText}
      label="Subcategory"
      options={isLoading ? undefined : sortedAuthSubCategories}
      value={selectedAuthSubCategory ?? ""}
      onChange={onSelectAuthSubCategory}
      showLoadingIcon={isLoading}
      style={{ marginTop: "-4px", height: theme.spacing(authCategorySpacing) }}
      disabled={disabled || onPatientSummary}
      data-public
    />
  );
}
