import React, { Dispatch, SetStateAction } from "react";
import { SecondarySingleSelectDropdown } from "@coherehealth/common";
import { ServiceRequestSearchRequestBody } from "@coherehealth/core-platform-api";
import isMatch from "lodash/isMatch";

interface Props {
  queryParams: ServiceRequestSearchRequestBody;
  setQueryParams: Dispatch<SetStateAction<ServiceRequestSearchRequestBody>>;
}

const sortByOptions: Record<string, ServiceRequestSearchRequestBody> = {
  "Most recent": { sort: "lastUpdated:desc" },
  "Date of service": { sort: "startDate:desc" },
};

const ServiceRequestSort = ({ queryParams, setQueryParams }: Props) => {
  const sortByOptionLabels = Object.keys(sortByOptions);

  const value =
    sortByOptionLabels.find((optionLabel) => isMatch(queryParams, sortByOptions[optionLabel])) || "Most recent";

  return (
    <SecondarySingleSelectDropdown
      options={sortByOptionLabels.map((optionLabel) => ({ id: optionLabel, label: optionLabel }))}
      label="Sort by:"
      value={value}
      isDashboardSortComponent
      onChange={(id) => {
        const { order, sort, ...restOfQueryParams } = queryParams;
        setQueryParams({
          ...restOfQueryParams,
          ...sortByOptions[id],
        });
      }}
    />
  );
};

export default ServiceRequestSort;
