import React, { useCallback, useEffect, useState, MutableRefObject } from "react";
import { colorsLight, H4 } from "@coherehealth/common";
import NotificationViewerSelector from "./NotificationViewerSelector";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { CircularProgress, styled } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import { NotificationViewerConfig } from "./Viewers";
import { NotificationViewerItem } from "./util/notificationUtil";
import { isAttachmentNotification } from "./util/notificationUtil";
import { convertPathToOcrEnhanced } from "./util/fileManagementUtil";
import { useBearerToken } from "hooks/useBearerToken";

interface Props {
  id: string;
  notifications: NotificationViewerItem[];
  setNotifications: React.Dispatch<React.SetStateAction<NotificationViewerItem[]>>;
  notificationIndexOpen: number;
  notificationsLoading?: boolean;
  currentPage: MutableRefObject<number>;
  zoomLevel: MutableRefObject<number>;
  currentRotate: MutableRefObject<number>;
  onViewNotification: (index: number, landingPage?: number) => void;
  viewerConfig?: NotificationViewerConfig;
}

export default function NotificationViewerShell({
  id,
  notifications,
  setNotifications,
  notificationIndexOpen,
  notificationsLoading,
  currentPage,
  zoomLevel,
  currentRotate,
  onViewNotification,
  viewerConfig,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [downloadError, setDownloadError] = useState<Error>();
  const [openSidePanel, setOpenSidePanel] = useState<boolean>(!!notifications);

  const currentNotification = notifications[notificationIndexOpen];
  let currentNotificationDownloadPath = currentNotification?.downloadPath;
  if (currentNotificationDownloadPath && isAttachmentNotification(currentNotification)) {
    currentNotificationDownloadPath = convertPathToOcrEnhanced(currentNotificationDownloadPath);
  }

  const handleError = useCallback(
    (error: Error) => {
      setDownloadError(error);
      enqueueSnackbar(error.message || "Error downloading notification", {
        variant: "error",
        preventDuplicate: true,
      });
    },
    [enqueueSnackbar]
  );

  const bearerToken = useBearerToken();

  const fetchNotification = useCallback(
    async (index: number) => {
      setDownloadError(undefined);
      if (notifications && currentNotificationDownloadPath) {
        try {
          const response = await fetch(currentNotificationDownloadPath, {
            headers: {
              Authorization: bearerToken,
            },
          });

          if (response.ok) {
            const notification = notifications[index];
            const blob = await response.blob();
            const fileUrl = window.URL.createObjectURL(blob);
            const isOcr = response.headers.get("content-disposition")?.endsWith('ml-ocrenhance.pdf"') || false;
            const convertedToPDF = blob.type === "application/pdf" && notification.contentType !== "application/pdf";

            // Update notification with new metadata
            setNotifications((prevNotifications) =>
              prevNotifications.map((notification, index) => {
                if (index === notificationIndexOpen) {
                  return { ...notification, fileUrl, convertedToPDF, isOcr };
                } else {
                  return notification;
                }
              })
            );
          } else {
            handleError({ name: "UnexpectedResponseException", message: "Error downloading notification" });
          }
        } catch (error) {
          if (error instanceof Error) {
            handleError(error);
          }
        }
      } else if (!currentNotificationDownloadPath) {
        // Ad hoc letters may fail internal letter generation and therefore have
        // no associated error. This message will display in place of the PDF.
        setDownloadError({ name: "NoFileException", message: "Notification does not have a file to display" });
      }
    },
    [notifications, currentNotificationDownloadPath, setNotifications, notificationIndexOpen, handleError, bearerToken]
  );

  // Download the file for the notification we currently have open, if we haven't already done so.
  useEffect(() => {
    if (!currentNotification?.fileUrl) {
      fetchNotification(notificationIndexOpen);
    }
  }, [currentNotification?.fileUrl, fetchNotification, notificationIndexOpen]);

  useEffect(() => {
    setOpenSidePanel(!!notifications);
  }, [notifications]);

  return (
    <div id={id} style={{ width: "100%", height: "100%", margin: "-1px" }}>
      {notifications?.length ? (
        <NotificationViewerSelector
          notification={currentNotification}
          notifications={notifications}
          setNotifications={setNotifications}
          handleNotificationClick={onViewNotification}
          notificationIndexOpen={notificationIndexOpen}
          currentPage={currentPage}
          zoomLevel={zoomLevel}
          currentRotate={currentRotate}
          openSidePanel={openSidePanel}
          setOpenSidePanel={setOpenSidePanel}
          downloadError={downloadError}
          viewerConfig={viewerConfig}
        />
      ) : (
        <CenteredTextContainer>
          {notificationsLoading ? (
            <CircularProgress size={144} />
          ) : (
            <H4 style={{ color: colorsLight.font.light }}>{`There are no ${
              viewerConfig?.entityLabel || "notifications"
            }.`}</H4>
          )}
          {viewerConfig?.closeDialogFunction && (
            <div style={{ position: "absolute", top: "8px", right: "8px" }}>
              <IconButton onClick={viewerConfig.closeDialogFunction} aria-label="close">
                <CloseIcon />
              </IconButton>
            </div>
          )}
        </CenteredTextContainer>
      )}
    </div>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const CenteredTextContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "calc(100vh - 200px)",
});
