import React, { HTMLAttributes, useMemo } from "react";
import { sanitize } from "dompurify";

interface Props extends HTMLAttributes<HTMLElement> {
  __html?: string;
  forceBody?: boolean; //allows script, style tags in html
}

export default function Sanitized({ __html, forceBody, ...rest }: Props) {
  const sanitizedHtml = useMemo(() => {
    return htmlWithFixedLinks(
      sanitize(__html || "", {
        FORCE_BODY: forceBody,
      })
    );
  }, [__html, forceBody]);

  /* eslint-disable-next-line cohere-react/no-danger-anywhere, react/no-danger */
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} {...rest} />;
}

function htmlWithFixedLinks(htmlStr: string) {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(htmlStr, "text/html");
  const anchorTags = htmlDoc.getElementsByTagName("a");
  for (let i = 0; i < anchorTags.length; i++) {
    anchorTags[i].setAttribute("target", "_blank");
    anchorTags[i].setAttribute("rel", "noopener");
  }
  return htmlDoc.documentElement.outerHTML;
}
