import React, { Dispatch } from "react";

import { Patient } from "@coherehealth/core-platform-api";
import { makeStyles } from "@material-ui/core/styles";

import { CommonFaxSidebarViewProps } from "./common";
import Grid from "@material-ui/core/Grid";
import { Card, colorsLight, H4, PrimaryButton } from "@coherehealth/common";
import ModelTrainingIconGrey from "../../images/ModelTrainingIconGrey.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    border: "none",
    boxShadow: "none",
    backgroundColor: colorsLight.background.light,
  },
  header: {
    color: colorsLight.font.secondary,
    padding: theme.spacing(0, 0, 2, 1),
  },
  icon: {
    height: "65%",
  },
  iconGrid: {
    textAlign: "center",
  },
}));

interface Props extends CommonFaxSidebarViewProps {
  selectedPatient?: Patient;
  setSelectedPatient: Dispatch<Patient | undefined>;
}

export default function OCRDetectionCard({ fileData, ...passthrough }: Props) {
  const classes = useStyles();

  return (
    <Card className={classes.container}>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={1} className={classes.iconGrid}>
          <img alt="" src={ModelTrainingIconGrey} className={classes.icon} />
        </Grid>
        <Grid item xs={11}>
          <H4 className={classes.header}>
            We detected a Cohere service request form and automatically prepared a draft service request
          </H4>
        </Grid>
        <Grid item xs={12}>
          <PrimaryButton fullWidth onClick={() => passthrough.setSidebarView("FINISH_OCR_SR")}>
            Start Request
          </PrimaryButton>
        </Grid>
      </Grid>
    </Card>
  );
}
