import { StartContinuationButton } from "@coherehealth/common";
import { useTheme, IconButton } from "@material-ui/core";
import { FaxAttachmentContext } from "components/DocumentViewer/FaxAttachment/FaxAttachmentContext";
import { SmartOnFhirContext } from "components/SmartOnFhir/SmartOnFhirSecurityProvider";
import { useContext } from "react";
import { getLatestServiceRequest, isAuthorizationValidForContinuations } from "util/authorization";
import {
  internalFinalDeterminationLetterToPrint,
  finalDeterminationLetterToPrint,
  isTerminalStatus,
} from "util/serviceRequest";
import PrintableStatusAlert from "../PrintableStatusAlert";
import PrintIcon from "@material-ui/icons/Print";
import EditIcon from "@material-ui/icons/Edit";
import {
  ActionButtonT,
  actionIconStyle,
  animatedHiddenStyle,
  animatedShownStyle,
} from "../StatusAlert/statusAlertUtils";

export const InlineIconButton = ({
  serviceRequest,
  limitVoidsAfterApprovalFF,
  actionConfiguration,
  setEditModalOpen,
  setHover,
  onShowPrintSummary,
  authorization,
  isFinalDeterminationLetterPrintable,
  allowContinuations,
  canCreateContinuation = true,
  isAuthViewOnlyUser,
  onContinuationClick,
  createNewAuthorizationLoading,
  loadingContinuation,
  isInAuthBuilder,
  isDraftSr,
  isStatusEditable,
}: ActionButtonT) => {
  const theme = useTheme();
  const { id, finalDeterminationLetterAttachments } = serviceRequest;
  const shouldDisplayInlineIconButton =
    isStatusEditable && (!serviceRequest.isSrNoLongerNeeded || !limitVoidsAfterApprovalFF) && !isDraftSr;

  const hasInternalLetters = !!serviceRequest.internalFinalDeterminationLetterAttachments?.length;
  const surfaceInternalLetters = hasInternalLetters;

  const defaultInternalFinalDeterminationLetterAttachment = surfaceInternalLetters
    ? internalFinalDeterminationLetterToPrint(serviceRequest.internalFinalDeterminationLetterAttachments)
    : undefined;
  const defaultExternalFinalDeterminationLetterAttachment = finalDeterminationLetterToPrint(
    finalDeterminationLetterAttachments
  );
  const attachmentId =
    defaultInternalFinalDeterminationLetterAttachment?.id ?? defaultExternalFinalDeterminationLetterAttachment?.id;
  const showingInternalDefaultLetter = attachmentId === defaultInternalFinalDeterminationLetterAttachment?.id;
  const { faxAttachment, caseId } = useContext(FaxAttachmentContext);
  const smartClient = useContext(SmartOnFhirContext);
  const inSmartOnFhirWorkflow = Boolean(smartClient);
  const latestSr = authorization && getLatestServiceRequest(authorization)?.[1];
  const isFaxEdit = Boolean(faxAttachment?.id && caseId);
  const showCreateContinuation = isFaxEdit
    ? false
    : canCreateContinuation && allowContinuations && isAuthorizationValidForContinuations(serviceRequest, latestSr);
  // If true, don't render the "Start Continuation" button at all.
  // Normally the `noRender` property alone would control this, but since the button has no disabled state, I'm preventing rendering when it's configured as `disabled` as well.
  const doNotRenderStartContinuation =
    inSmartOnFhirWorkflow ||
    actionConfiguration.startContinuationActionConfiguration?.noRender ||
    actionConfiguration.startContinuationActionConfiguration?.disabled;
  const shouldDisplayPrintableStatusAlert =
    isFinalDeterminationLetterPrintable &&
    !!attachmentId &&
    (!limitVoidsAfterApprovalFF || !serviceRequest.isSrNoLongerNeeded);
  const optionallyDisplayPrintableStatusAlert = (): JSX.Element => {
    if (
      isFinalDeterminationLetterPrintable &&
      !!attachmentId &&
      (!serviceRequest.isSrNoLongerNeeded || !limitVoidsAfterApprovalFF)
    ) {
      return (
        <PrintableStatusAlert
          serviceRequestId={id}
          attachmentId={attachmentId}
          showingInternalLetter={showingInternalDefaultLetter}
        />
      );
    } else {
      return <></>;
    }
  };
  const optionallyDisplayStartContinuationButton = (): JSX.Element => {
    if (showCreateContinuation && !isAuthViewOnlyUser && !doNotRenderStartContinuation) {
      return (
        <StartContinuationButton
          onClick={onContinuationClick}
          loading={createNewAuthorizationLoading || loadingContinuation}
        />
      );
    } else {
      return <></>;
    }
  };

  if (shouldDisplayInlineIconButton) {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        {!actionConfiguration.editStatusActionConfiguration?.noRender && (
          <IconButton
            data-testid="edit-auth-status-btn"
            onClick={() => {
              setEditModalOpen(true);
              if (setHover) {
                setHover(false);
              }
            }}
            style={{
              marginRight: isTerminalStatus(serviceRequest) ? 4 : undefined,
              ...actionIconStyle,
              ...(actionConfiguration.editStatusActionConfiguration?.hidden ? animatedHiddenStyle : animatedShownStyle),
            }}
            disabled={actionConfiguration.editStatusActionConfiguration?.disabled}
            title="Edit Auth status"
            aria-label="Edit Auth status"
          >
            <EditIcon />
          </IconButton>
        )}
        {!actionConfiguration.printActionConfiguration?.noRender && isTerminalStatus(serviceRequest) && (
          <IconButton
            data-testid="display-determination-letter-btn"
            onClick={() => {
              onShowPrintSummary();
              if (setHover) {
                setHover(false);
              }
            }}
            style={{
              ...actionIconStyle,
              ...(actionConfiguration.printActionConfiguration?.hidden ? animatedHiddenStyle : animatedShownStyle),
            }}
            disabled={actionConfiguration.printActionConfiguration?.disabled}
            title="Display Determination Letter"
            aria-label="Display Determination Letter"
          >
            <PrintIcon />
          </IconButton>
        )}
        {optionallyDisplayPrintableStatusAlert()}
        {optionallyDisplayStartContinuationButton()}
      </div>
    );
  } else if (shouldDisplayPrintableStatusAlert) {
    return (
      <>
        <PrintableStatusAlert
          serviceRequestId={id}
          attachmentId={attachmentId}
          showingInternalLetter={showingInternalDefaultLetter}
        />
        {optionallyDisplayStartContinuationButton()}
      </>
    );
  } else if (isTerminalStatus(serviceRequest)) {
    return (
      <>
        {!actionConfiguration.printActionConfiguration?.noRender && (
          <IconButton
            data-testid="print-service-summary-btn"
            onClick={() => {
              onShowPrintSummary();
              if (setHover) {
                setHover(false);
              }
            }}
            style={{
              marginRight:
                canCreateContinuation && allowContinuations && !isInAuthBuilder ? theme.spacing(2) : undefined,
              ...actionIconStyle,
              ...(actionConfiguration.printActionConfiguration?.hidden ? animatedHiddenStyle : animatedShownStyle),
            }}
            disabled={actionConfiguration.printActionConfiguration?.disabled}
            title="Print Service Summary"
            aria-label="Print Service Summary"
          >
            <PrintIcon />
          </IconButton>
        )}
        {optionallyDisplayStartContinuationButton()}
      </>
    );
  } else {
    return <></>;
  }
};
