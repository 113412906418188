import { FaxAttachment } from "@coherehealth/core-platform-api";
import { createContext } from "react";

// todo add more fax page specific stuff here so we don't have to pass it everywhere and through the DocumentViewer
// as props
interface FaxAttachmentContextType {
  faxDbId: string;
  caseId?: string;
  origin?: string;
  queueId?: string;
  faxAttachment: FaxAttachment | null;
  healthPlanName?: string;
}

const faxAttachmentInitialState: FaxAttachmentContextType = {
  faxDbId: "",
  faxAttachment: null,
};

export const FaxAttachmentContext = createContext<FaxAttachmentContextType>(faxAttachmentInitialState);
