import { createContext } from "react";

import { AuthBuilderWorkflowStep, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { AuthFlowType } from "./common";

interface NavigationContextType {
  workflowStep?: AuthBuilderWorkflowStep;
  authFlowType?: AuthFlowType;
  draftServiceRequest?: ServiceRequestResponse | null;
  navigateBack?: () => void;
  navigateBackContinuations?: () => void;
  isFaxAuthBuilderFlow?: boolean;
}

export const NavigationContext = createContext<NavigationContextType>({});
