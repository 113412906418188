import React, { ReactNode } from "react";

import StatusSummaryPdf from "./StatusSummaryPdf";
import { BlobProvider } from "@react-pdf/renderer";
import { FaxAttachmentStatus, ServiceRequestStatus } from "../checkStatusApi";
import useMemoDeepCompare from "util/useDeepMemo";

interface Error {
  name: string;
  message: string;
}

interface PrintoutParams {
  error: Error | null;
  url: string | null;
  loading: boolean;
}

interface Props {
  statusResult?: ServiceRequestStatus | FaxAttachmentStatus;
  children: (params: PrintoutParams) => ReactNode;
}

/**
 * Given a status result render a pdf document
 *
 * @param status The service request to convert to a print-out
 * @param children A consumer of that printout
 */
export default function StatusSummaryPdfProvider({ statusResult, children }: Props) {
  if (!statusResult) {
    return null;
  }
  const Memoed = () => {
    return useMemoDeepCompare(
      () => (
        <BlobProvider key={Math.random()} document={<StatusSummaryPdf statusResult={statusResult} />}>
          {children}
        </BlobProvider>
      ),
      [children, statusResult]
    );
  };

  return <Memoed />;
}
