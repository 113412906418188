import { Indication } from "@coherehealth/core-platform-api";
import cloneDeep from "lodash/cloneDeep";

export type DynamicIndicationOutcome = "PASS" | "FAIL" | "INDETERMINATE";

export interface DynamicIndication extends Indication {
  dynamicOutcome?: DynamicIndicationOutcome;
  indications?: DynamicIndication[];
  iconCheck?: "GreenCheck" | "CrossMark" | "QuestionMark";
}

const calculateIndicationsResults = (indications: DynamicIndication[]): Record<DynamicIndicationOutcome, number> => {
  let results: Record<DynamicIndicationOutcome, number> = { PASS: 0, FAIL: 0, INDETERMINATE: 0 };
  indications.forEach((innerIndication) => {
    switch (innerIndication.dynamicOutcome) {
      case "FAIL":
        results.FAIL += 1;
        break;
      case "INDETERMINATE":
        results.INDETERMINATE += 1;
        break;
      case "PASS":
        results.PASS += 1;
        break;
    }
  });
  return results;
};

export const evaluateIndication = (
  indications: DynamicIndication[],
  checkedIndicationsRecord: Record<string, boolean>,
  unmetIndicationsRecord: Record<string, boolean>,
  updateIndicationState?: (indicationId: string, outcome: DynamicIndicationOutcome) => void
): DynamicIndication[] => {
  const dynamicIndications: DynamicIndication[] = indications?.map((outerIndication) => {
    let dynamicOutcome: DynamicIndicationOutcome = "INDETERMINATE";
    let innerIndications: DynamicIndication[] = [];
    if (!outerIndication?.indications || outerIndication.indications.length === 0) {
      //it is a leaf indication
      if (Object.keys(checkedIndicationsRecord).length === 0 && Object.keys(unmetIndicationsRecord).length === 0) {
        dynamicOutcome = "INDETERMINATE";
      } else if (checkedIndicationsRecord[outerIndication?.uid || ""]) {
        dynamicOutcome = "PASS";
      } else if (unmetIndicationsRecord[outerIndication?.uid || ""]) {
        dynamicOutcome = "FAIL";
      }
    } else {
      //need to traverse the children of outerIndication
      innerIndications = evaluateIndication(
        outerIndication.indications,
        checkedIndicationsRecord,
        unmetIndicationsRecord,
        updateIndicationState
      );
      const results: Record<DynamicIndicationOutcome, number> = calculateIndicationsResults(innerIndications);
      dynamicOutcome = getLogicalOutcome(outerIndication, results, outerIndication.logicalOperator);
      if (outerIndication?.uid) {
        updateIndicationState?.(outerIndication.uid, dynamicOutcome);
      }
    }
    const updatedOuterIndication: DynamicIndication = {
      ...outerIndication,
      dynamicOutcome,
      indications: innerIndications,
    };
    return updatedOuterIndication;
  });
  return dynamicIndications;
};

export const runLogicForCheckedIndications = (
  indication: DynamicIndication[],
  checkedIndications: DynamicIndication[],
  unmetIndications: DynamicIndication[],
  updateIndicationState?: (indicationId: string, outcome: DynamicIndicationOutcome) => void
): DynamicIndication[] => {
  const clonedIndication = cloneDeep(indication);
  return evaluateIndication(
    clonedIndication,
    checkedIndications.reduce((record, indication) => {
      return indication.uid ? { ...record, [indication.uid]: true } : record;
    }, {} as Record<string, boolean>),
    unmetIndications.reduce((record, indication) => {
      return indication.uid ? { ...record, [indication.uid]: true } : record;
    }, {} as Record<string, boolean>),
    updateIndicationState
  );
};

export const getLogicalOutcome = (
  indication: DynamicIndication,
  results: Record<DynamicIndicationOutcome, number>,
  logicalOperator?: string
): DynamicIndicationOutcome => {
  switch (logicalOperator) {
    case "ALL":
      return all(indication, results);
    case "ANY":
      return any(indication, results);
    case "AT_LEAST":
      return atLeast(indication, results);
    case "LESS_THAN":
      return lessThan(indication, results);
    case "NOT":
      return not(indication);
    case "NOT_ALL":
      return notAll(indication, results);
    case "NOT_ANY":
      return notAny(results);
    default:
      return "FAIL";
  }
};

const all = (
  indication: DynamicIndication,
  results: Record<DynamicIndicationOutcome, number>
): DynamicIndicationOutcome => {
  const indications = indication.indications || [];
  return results["PASS"] === indications.length
    ? "PASS"
    : results["INDETERMINATE"] === indications.length
    ? "INDETERMINATE"
    : "FAIL";
};

const any = (
  indication: DynamicIndication,
  results: Record<DynamicIndicationOutcome, number>
): DynamicIndicationOutcome => {
  const indications = indication.indications || [];
  return results["PASS"] > 0 ? "PASS" : results["INDETERMINATE"] === indications.length ? "INDETERMINATE" : "FAIL";
};

const atLeast = (
  indication: DynamicIndication,
  results: Record<DynamicIndicationOutcome, number>
): DynamicIndicationOutcome => {
  const indications = indication.indications || [];
  const logicalOperatorValue = indication.logicalOperatorValue || 0;
  return results["PASS"] >= logicalOperatorValue
    ? "PASS"
    : results["INDETERMINATE"] === indications.length
    ? "INDETERMINATE"
    : "FAIL";
};

const lessThan = (
  indication: DynamicIndication,
  results: Record<DynamicIndicationOutcome, number>
): DynamicIndicationOutcome => {
  const indications = indication.indications || [];
  const logicalOperatorValue = indication.logicalOperatorValue || 0;
  return results["INDETERMINATE"] === indications.length
    ? "INDETERMINATE"
    : results["PASS"] < logicalOperatorValue
    ? "PASS"
    : "FAIL";
};

const notAll = (
  indication: DynamicIndication,
  results: Record<DynamicIndicationOutcome, number>
): DynamicIndicationOutcome => {
  const indications = indication.indications || [];
  return results["INDETERMINATE"] === indications.length ? "INDETERMINATE" : results["PASS"] > 0 ? "PASS" : "FAIL";
};

const notAny = (results: Record<DynamicIndicationOutcome, number>): DynamicIndicationOutcome => {
  return results["PASS"] === 0 ? "PASS" : "FAIL";
};

const not = (indication: DynamicIndication): DynamicIndicationOutcome => {
  return indication.dynamicOutcome === "PASS"
    ? "FAIL"
    : indication.dynamicOutcome === "FAIL"
    ? "PASS"
    : "INDETERMINATE";
};
