import React from "react";
import { Document, Page, StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import { CohereFaxPDFHeader, CohereFaxFooter, CohereFaxResourcesSection } from "util/PDFUtils";
import { FaxNoticeOutOfScopeInformation } from "../../common";
import DoNotDisturbIcon from "components/images/DoNotDisturbIcon.jpg";
import { GilroyRegular } from "util/PDFfonts";

interface Props {
  outOfScopeInformation: FaxNoticeOutOfScopeInformation;
  patientName: string;
  dateRequested: string;
  isGhpUser?: boolean;
}

export default function OutOfScopeFaxPDF({ outOfScopeInformation, patientName, dateRequested, isGhpUser }: Props) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ position: "relative" }}>
          <CohereFaxPDFHeader faxBackType="OUT_OF_SCOPE" dateRequested={dateRequested} isGhpUser={isGhpUser} />
          <View style={styles.detailsSection}>
            <Text>
              <Text style={styles.patientNameRegularText}>Patient name: </Text>
              <Text style={styles.patientNameBoldText}>{patientName ? patientName : "--"}</Text>
            </Text>
            <Text>
              <Text style={styles.patientNameRegularText}>Start date requested: </Text>
              <Text style={styles.patientNameBoldText}>{dateRequested ? dateRequested : "--"}</Text>
            </Text>
          </View>
          <View style={styles.bodySection}>
            <Text style={[styles.bodySemiBoldText, { paddingBottom: 16 }]}>Hello,</Text>
            <Text style={[styles.bodySemiBoldText, { paddingBottom: 8 }]}>
              Thank you for submitting a request to Cohere Health. Unfortunately, your request is out of scope for
              Cohere. Please contact the member's health plan if you have additional questions or concerns.
            </Text>
            <View style={styles.reasonSection}>
              {outOfScopeInformation?.ineligiblePatient && (
                <View style={styles.outOfScopeItem}>
                  <OutOfScopeNotification />
                  <Text style={[styles.bodySemiBoldText, { marginTop: 5, paddingBottom: 2 }]}>
                    Patient is not eligible
                  </Text>
                </View>
              )}
              {outOfScopeInformation?.ineligibleProvider && (
                <View style={styles.outOfScopeItem}>
                  <OutOfScopeNotification />
                  <Text style={[styles.bodySemiBoldText, { marginTop: 5, paddingBottom: 2 }]}>
                    Provider is not eligible
                  </Text>
                </View>
              )}
              {outOfScopeInformation?.mustBePCPSubmitted && (
                <View style={styles.outOfScopeItem}>
                  <OutOfScopeNotification />
                  <Text style={[styles.bodySemiBoldText, { marginTop: 5, paddingBottom: 2 }]}>
                    Prior Authorization must be submitted by PCP
                  </Text>
                </View>
              )}
              {outOfScopeInformation?.additionalNotes && (
                <View style={{ paddingTop: 8 }}>
                  <Text style={[styles.bodySemiBoldText, { paddingBottom: 2 }]}>Additional Notes</Text>
                  <Text style={styles.bulletText}>{outOfScopeInformation.additionalNotes}</Text>
                </View>
              )}
            </View>
          </View>
          <CohereFaxResourcesSection faxBackType="OUT_OF_SCOPE" isGhpUser={isGhpUser} />
        </View>
        <CohereFaxFooter />
      </Page>
    </Document>
  );
}

function OutOfScopeNotification() {
  return (
    <View style={styles.outOfScopeNotification}>
      <Image src={DoNotDisturbIcon} style={styles.dndIcon} />
      <Text style={styles.warningText}>Out-of-Scope</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexWrap: "wrap",
    position: "relative",
    padding: 56,
  },
  bodySection: {
    minWidth: "100%",
    borderBottom: 1,
    paddingTop: 24,
    paddingBottom: 24,
    position: "relative",
  },
  patientNameRegularText: {
    fontSize: 12,
    fontFamily: "GilroyRegular",
  },
  patientNameBoldText: {
    fontSize: 12,
    fontFamily: "GilroyBold",
  },
  bodySemiBoldText: {
    fontSize: 10,
    fontFamily: "GilroySemiBold",
  },
  bulletSection: {
    marginLeft: 12,
    paddingBottom: 16,
  },
  bullet: {
    fontSize: 10,
    fontFamily: "GilroyBold",
  },
  bulletText: {
    fontSize: 10,
    fontFamily: "GilroyRegular",
  },
  bodySmallText: {
    fontSize: 7,
    fontFamily: "GilroyRegular",
  },
  bodySmallBoldText: {
    fontSize: 7,
    fontFamily: "GilroyBold",
  },
  reasonSection: {
    width: "100%",
    paddingTop: 16,
  },
  outOfScopeItem: {
    paddingBottom: 8,
    flexDirection: "row",
  },
  outOfScopeNotification: {
    padding: 4,
    paddingBottom: 3,
    borderWidth: 1,
    marginRight: 8,
    flexDirection: "row",
    height: 20,
  },
  dndIcon: {
    width: 12,
    height: 8,
    paddingRight: 4,
    marginTop: 1,
  },
  warningText: {
    fontSize: 8,
    fontFamily: GilroyRegular,
    marginTop: 1,
  },
  detailsSection: {
    minWidth: "100%",
    borderBottom: 1,
    paddingTop: 24,
    paddingBottom: 24,
    flexDirection: "row",
    gap: 80,
  },
});
