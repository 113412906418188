import { useState, useCallback, Dispatch, SetStateAction } from "react";
import {
  useGenerateAdhocLetterPreview,
  useCreateAdhocNotifications,
  AdhocNotificationType,
  PhoneNumber,
} from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";

interface AdhocLetterHookProps {
  serviceRequestId: string;
  denialLanguage: string;
  adhocNotificationType?: AdhocNotificationType;
  correctionType?: string;
  contentBlockTitle?: string;
}

interface AdhocLetterHookResult {
  fileUrl: string;
  hasFileError: boolean;
  showRecipientSelectModal: boolean;
  setShowRecipientSelectModal: Dispatch<SetStateAction<boolean>>;
  submittedAdhocLetter: boolean;
  setSubmittedAdhocLetter: Dispatch<SetStateAction<boolean>>;
  onSendAdhocLetter: (
    sendLetterButtonDisabled: boolean,
    setHasClickedSend: Dispatch<SetStateAction<boolean>>,
    recipientsChecked: { [key: string]: boolean },
    customFaxNumber?: PhoneNumber
  ) => Promise<void>;
  getAdhocLetterPreview: () => Promise<void>;
}

const useAdhocApiCalls = ({
  serviceRequestId,
  denialLanguage,
  adhocNotificationType,
  correctionType,
  contentBlockTitle,
}: AdhocLetterHookProps): AdhocLetterHookResult => {
  const [fileUrl, setFileUrl] = useState<string>("");
  const [hasFileError, setHasFileError] = useState(false);
  const [showRecipientSelectModal, setShowRecipientSelectModal] = useState(false);
  const [submittedAdhocLetter, setSubmittedAdhocLetter] = useState(false);

  const { mutate: generateAdhocLetterPreview } = useGenerateAdhocLetterPreview({});
  const { mutate: createAdhocNotifications } = useCreateAdhocNotifications({});
  const { enqueueSnackbar } = useSnackbar();

  const getAdhocLetterPreview = useCallback(async () => {
    try {
      const response = await generateAdhocLetterPreview({
        serviceRequestId,
        adhocNotificationType: adhocNotificationType,
        correctionType: correctionType,
        contentBlockTitle: contentBlockTitle,
        content: denialLanguage,
        recipient: "PATIENT",
      });

      const resultStream = response as Response;
      if (resultStream?.ok) {
        const blob = await resultStream.blob();
        const url = window.URL.createObjectURL(blob);
        setFileUrl(url);
      } else {
        setHasFileError(true);
      }
    } catch (error) {
      setHasFileError(true);
    }
  }, [
    generateAdhocLetterPreview,
    serviceRequestId,
    denialLanguage,
    adhocNotificationType,
    correctionType,
    contentBlockTitle,
  ]);

  const onSendAdhocLetter = useCallback(
    async (
      sendLetterButtonDisabled: boolean,
      setHasClickedSend: Dispatch<SetStateAction<boolean>>,
      recipientsChecked: { [key: string]: boolean },
      customFaxNumber?: PhoneNumber
    ) => {
      if (!sendLetterButtonDisabled) {
        setHasClickedSend(true);
        const recipientListPayload: string[] = Object.entries(recipientsChecked)
          .filter(([key, value]) => value)
          .map(([key, value]) => key);

        try {
          await createAdhocNotifications({
            serviceRequestId,
            adhocNotificationType,
            correctionType,
            contentBlockTitle,
            content: denialLanguage,
            recipients: recipientListPayload,
            customFaxNumber,
          });

          setShowRecipientSelectModal(false);
          setSubmittedAdhocLetter(true);
          setHasClickedSend(false);
        } catch (error) {
          enqueueSnackbar(`There was an error processing your request. Please try again.`, { variant: "error" });
        } finally {
          setHasClickedSend(false);
        }
      } else {
        setHasClickedSend(false);
      }
    },
    [
      serviceRequestId,
      denialLanguage,
      adhocNotificationType,
      correctionType,
      contentBlockTitle,
      enqueueSnackbar,
      createAdhocNotifications,
    ]
  );

  return {
    fileUrl,
    hasFileError,
    showRecipientSelectModal,
    setShowRecipientSelectModal,
    submittedAdhocLetter,
    setSubmittedAdhocLetter,
    getAdhocLetterPreview,
    onSendAdhocLetter,
  };
};

export default useAdhocApiCalls;
