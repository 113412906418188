import React, { ReactNode } from "react";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import MuiIconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { colorsLight } from "../../themes";
import { H2, Body1, Subtitle2 } from "../Typography";
import { PrimaryButton } from "../PrimaryButton";
import { TertiaryButton } from "../TertiaryButton";
import { Switch } from "../Switch";
import { Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import { InlineButton } from "../InlineButton";

export interface ModalProps extends DialogProps {
  hideX?: boolean;
  icon?: JSX.Element;
  headerText: string | JSX.Element;
  subHeaderText?: string | JSX.Element;
  subHeaderElement?: JSX.Element;
  noCloseIcon?: boolean;
  //TODO: implement additional text to accommodate for rich text
  additionalInfoText?: string;
  additionalInfoElement?: JSX.Element;
  primaryButtonText?: string;
  primaryButtonAction?: (event?: MouseEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  primaryButtonLoading?: boolean;
  primaryButtonDisabled?: boolean;
  //if the primary button routes you to a different page, use primaryButtonRoute instead of primaryButtonAction
  primaryButtonRoute?: string | { pathname: string };
  primaryButtonRouteNewTab?: boolean;
  tertiaryButtonText?: string;
  customButtonStyle?: any;
  tertiaryButtonAction?: (event?: MouseEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  tertiaryButtonLoading?: boolean;
  tertiaryButtonDisabled?: boolean;
  //same with the tertiary button
  tertiaryButtonRoute?: string;
  tertiaryButtonWarning?: boolean;
  switchLeftText?: string;
  switchRightText?: string;
  switchValue?: boolean;
  switchAction?: (b: boolean) => void;
  showDivider?: boolean;
  children?: ReactNode;
  dialogWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  customContentStyle?: any;
  iconSize?: string;
  backgroundColor?: string;
  leftAlignText?: boolean;
  inlineButtonText?: string;
  inlineButtonAction?: (event?: MouseEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  inlineButtonRoute?: string;
  inlineButtonWarning?: boolean;
  primaryButtonTestid?: string;
  customDialogResults?: JSX.Element;
  noSubHeaderElementPadding?: boolean;
  dataPublic?: boolean;
}

interface StyleProps {
  icon?: JSX.Element | undefined;
  iconSize?: string;
  leftAlignText?: boolean;
  noSubHeaderElementPadding?: boolean;
  backgroundColor?: string;
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paper ": {
      boxShadow: "0px 15px 12px rgba(0, 0, 0, 0.22), 0px 19px 38px rgba(0, 0, 0, 0.3)",
      borderRadius: "16px",
      "& .MuiDialog-paperWidthXl": { minWidth: "1024px" },
      backgroundColor: ({ backgroundColor }: StyleProps) => {
        if (backgroundColor) {
          return backgroundColor;
        }
      },
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  content: {
    minWidth: 600,
    padding: theme.spacing(7),
    paddingTop: ({ icon }: StyleProps) => (icon ? theme.spacing(6) : theme.spacing(6, 7)),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  icon: {
    "& .MuiSvgIcon-root": {
      height: ({ iconSize }: StyleProps) => iconSize || theme.spacing(12),
      width: ({ iconSize }: StyleProps) => iconSize || theme.spacing(12),
    },
    "& svg": {
      height: theme.spacing(12),
      width: theme.spacing(12),
    },
    paddingBottom: theme.spacing(3),
  },
  headerText: {
    textAlign: "center",
  },
  subHeaderText: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: ({ leftAlignText }: StyleProps) => (leftAlignText ? "left" : "center"),
    color: theme.palette.text.tertiary,
  },
  subHeaderElement: {
    paddingTop: ({ noSubHeaderElementPadding }: StyleProps) =>
      noSubHeaderElementPadding ? undefined : theme.spacing(2),
    textAlign: "center",
    color: colorsLight.black.mediumEmphasis,
    width: "100%",
  },
  additionalInfoText: {
    paddingTop: theme.spacing(4),
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  primaryButton: {
    minWidth: 272,
    padding: theme.spacing(2, 10),
    marginTop: theme.spacing(2),
  },
  tertiaryButton: {
    marginTop: theme.spacing(2),
  },
  switchContainer: {
    paddingTop: theme.spacing(1.5),
    display: "flex",
    flexDirection: "row",
  },
  switch: {
    marginLeft: theme.spacing(0.5),
    "&.disabled": {
      color: colorsLight.gray.dark,
    },
    width: 50,
  },
  disabledText: {
    color: colorsLight.font.light,
    marginTop: theme.spacing(1.25),
    marginRight: theme.spacing(1),
    "&.disabled": {
      color: colorsLight.error.dark,
    },
  },
  enabledText: {
    color: colorsLight.font.main,
    marginTop: theme.spacing(1.25),
    paddingRight: theme.spacing(1),
    "&.disabled": {
      color: colorsLight.font.light,
    },
  },
  inlineButton: {
    marginTop: theme.spacing(3),
  },
}));

const InformativeModal = ({
  hideX,
  open,
  onClose,
  icon,
  headerText,
  subHeaderText,
  subHeaderElement,
  additionalInfoText,
  additionalInfoElement,
  primaryButtonText,
  primaryButtonAction,
  primaryButtonLoading,
  primaryButtonDisabled,
  primaryButtonRoute,
  primaryButtonRouteNewTab,
  tertiaryButtonText,
  tertiaryButtonAction,
  tertiaryButtonLoading,
  tertiaryButtonDisabled,
  tertiaryButtonRoute,
  inlineButtonText,
  inlineButtonAction,
  inlineButtonRoute,
  inlineButtonWarning,
  switchLeftText,
  switchRightText,
  switchValue,
  switchAction,
  tertiaryButtonWarning,
  showDivider = true,
  children,
  noCloseIcon,
  dialogWidth,
  customButtonStyle,
  customContentStyle,
  customDialogResults,
  iconSize,
  backgroundColor,
  leftAlignText,
  noSubHeaderElementPadding,
  primaryButtonTestid,
  dataPublic,
  ...props
}: ModalProps) => {
  const classes = useStyles({ icon, iconSize, backgroundColor, leftAlignText, noSubHeaderElementPadding });

  const shouldHaveDivider = (primaryButtonAction || primaryButtonRoute || switchAction) && showDivider;

  return (
    <Dialog
      maxWidth={dialogWidth ? dialogWidth : "sm"}
      className={classes.dialog}
      open={open}
      onClose={onClose}
      {...props}
    >
      <MuiDialogTitle>
        {!hideX && (
          <MuiIconButton
            className={classes.closeButton}
            aria-label="Dismiss modal"
            onClick={(event) => {
              onClose?.(event, "backdropClick");
            }}
          >
            {!noCloseIcon && <CloseIcon />}
          </MuiIconButton>
        )}
      </MuiDialogTitle>
      <MuiDialogContent className={customContentStyle ? customContentStyle : classes.content}>
        {icon && <div className={classes.icon}>{icon}</div>}
        <H2 className={classes.headerText} data-public>
          {headerText}
        </H2>
        {subHeaderText ? (
          <Subtitle2 className={classes.subHeaderText} data-public={dataPublic}>
            {subHeaderText}
          </Subtitle2>
        ) : (
          ""
        )}
        {subHeaderElement ? (
          <Subtitle2 className={classes.subHeaderElement} data-public={dataPublic}>
            {subHeaderElement}
          </Subtitle2>
        ) : (
          ""
        )}
        {additionalInfoText && (
          <Body1 className={classes.additionalInfoText} data-public={dataPublic}>
            {additionalInfoText}
          </Body1>
        )}
        {additionalInfoElement ? additionalInfoElement : ""}
        {shouldHaveDivider && <Divider className={classes.divider} />}
        {(primaryButtonAction || primaryButtonRoute) && (
          <>
            {primaryButtonRoute ? (
              <PrimaryButton
                component={Link}
                to={primaryButtonRoute}
                target={primaryButtonRouteNewTab ? "_blank" : undefined}
                rel={primaryButtonRouteNewTab ? "noopener noreferrer" : undefined}
                className={classes.primaryButton}
                data-testid={primaryButtonTestid}
                data-public={dataPublic}
              >
                {primaryButtonText || "Continue"}
              </PrimaryButton>
            ) : (
              <PrimaryButton
                className={customButtonStyle ? customButtonStyle : classes.primaryButton}
                onClick={(event) => primaryButtonAction?.(event)}
                loading={primaryButtonLoading}
                disabled={primaryButtonDisabled}
                data-testid={primaryButtonTestid}
                data-public={dataPublic}
              >
                {primaryButtonText || "Continue"}
              </PrimaryButton>
            )}
          </>
        )}
        {inlineButtonRoute ? (
          <InlineButton
            to={inlineButtonRoute}
            component={Link}
            classes={{ root: classes.inlineButton }}
            warning={inlineButtonWarning}
            data-public={dataPublic}
          >
            {inlineButtonText || "Go back"}
          </InlineButton>
        ) : (
          (inlineButtonText || inlineButtonAction) && (
            <InlineButton
              onClick={(event) => inlineButtonAction?.(event)}
              classes={{ root: classes.inlineButton }}
              warning={inlineButtonWarning}
              data-public={dataPublic}
            >
              {inlineButtonText || "Go back"}
            </InlineButton>
          )
        )}
        {(tertiaryButtonAction || tertiaryButtonRoute) && (
          <>
            {tertiaryButtonRoute ? (
              <TertiaryButton
                component={Link}
                to={tertiaryButtonRoute}
                className={classes.tertiaryButton}
                warning={tertiaryButtonWarning}
                loading={tertiaryButtonLoading}
                disabled={tertiaryButtonDisabled}
                data-public={dataPublic}
              >
                {tertiaryButtonText || "Go back"}
              </TertiaryButton>
            ) : (
              <TertiaryButton
                onClick={(event) => tertiaryButtonAction?.(event)}
                className={classes.tertiaryButton}
                warning={tertiaryButtonWarning}
                disabled={tertiaryButtonDisabled}
                loading={tertiaryButtonLoading}
                data-public={dataPublic}
              >
                {tertiaryButtonText || "Go back"}
              </TertiaryButton>
            )}
          </>
        )}
        {switchAction && (
          <div className={classes.switchContainer}>
            <Body1 className={`${classes.disabledText}${switchValue ? "" : " disabled"}`} data-public={dataPublic}>
              {switchLeftText || "Off"}
            </Body1>
            <Switch
              checked={switchValue || false}
              onChange={switchAction}
              className={`${classes.switch}${switchValue ? "" : " disabled"}`}
              data-public={dataPublic}
            />
            <Body1 className={`${classes.enabledText}${switchValue ? "" : " disabled"}`} data-public={dataPublic}>
              {switchRightText || "On"}
            </Body1>
          </div>
        )}
        {children && <>{children}</>}
      </MuiDialogContent>
      {customDialogResults ? customDialogResults : <></>}
    </Dialog>
  );
};
InformativeModal.displayName = "Modal";

export default InformativeModal;
