import { useCallback, useEffect, useState, useContext, Dispatch, SetStateAction } from "react";
import {
  AuthBuilderWorkflowStep,
  AuthorizationResponse,
  BulkUpdateCandidateFeedbackRequestBody,
  Requestor,
  RuleActions,
  ServiceRequestResponse,
  useBatchSubmitServiceRequests,
  useBulkUpdateCandidateFeedback,
  useGetPatient,
  useUpdateServiceRequest,
  useGetNewServiceFaxNoticePDF,
} from "@coherehealth/core-platform-api";
import { useCompleteServiceCase, useGetServiceCase } from "@coherehealth/qm-api";
import { addBreadcrumb } from "@sentry/react";
import { queueMgmtBaseUrl, useFeature } from "@coherehealth/common";

import { useAuthorized } from "authorization";
import { requestorValid } from "components/Requestor/RequestorEdit";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router";
import routes from "routes";
import config from "api/config";
import SubmissionModal, { SubmissionStatus } from "components/ServiceRequest/Submission/SubmissionModal";

import { getPatientHealthPlanName } from "util/patientUtils";
import { useMatch } from "react-router-dom";
import Footer from "../Footer";
import {
  activitySnapshotFromServiceRequest,
  TrackUserActivityProps,
  useTrackUserInteraction,
} from "util/userActivityTracker";

import { FaxAttachmentContext } from "components/DocumentViewer/FaxAttachment/FaxAttachmentContext";
import { SuggestionContext } from "components/AuthBuilder/SuggestionContext";
import { compareSuggestionsToFinalSR } from "../../../util/suggestionUtils";
import { SmartOnFhirContext } from "components/SmartOnFhir/SmartOnFhirSecurityProvider";
import { navigateToPS, navigateToSRSummary } from "../common";
import { updateExternalReferenceIdsFromServiceCase } from "util/serviceRequest";
import { useIsFaxAuthBuilderWorkflow, useIsFaxEditSRWorkflow } from "util/attachmentUtil";
import { error as logError } from "logger";

interface Props {
  hasDuplicates: boolean;
  requestor?: Requestor;
  serviceRequests: ServiceRequestResponse[];
  preSubmissionRedirectActions: RuleActions | undefined;
  attemptedSubmitServices: boolean;
  setAttemptedSubmitServices: (b: boolean) => void;
  loadingActions: boolean | undefined;
  reviewEdit: boolean;
  workflowStep: AuthBuilderWorkflowStep;
  authFlowType: string;
  importedEhrOrder: ServiceRequestResponse | undefined;
  displayClearerDraftComponents: boolean;
  facilityBasedFeatureEnabled: boolean;
  disableContinueButtonOnContinuation: boolean;
  workflowId?: string;
  setFooterHeight: Dispatch<SetStateAction<number>>;
  authorization?: AuthorizationResponse | null;
  visibilityToggleStatus?: boolean;
}

export default function ReviewFooter({
  hasDuplicates,
  requestor,
  serviceRequests,
  preSubmissionRedirectActions,
  attemptedSubmitServices,
  setAttemptedSubmitServices,
  loadingActions,
  reviewEdit,
  workflowId,
  workflowStep,
  authFlowType,
  importedEhrOrder,
  displayClearerDraftComponents,
  facilityBasedFeatureEnabled,
  disableContinueButtonOnContinuation,
  setFooterHeight,
  authorization,
  visibilityToggleStatus,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: submitServiceRequests, loading: submitServiceRequestLoading } = useBatchSubmitServiceRequests({});
  const [showSubmissionModal, setShowSubmissionModal] = useState(false);
  const [authSubmittingFromFax, setAuthSubmittingFromFax] = useState(false);
  const sendNewSRFaxNoticeFF = useFeature("sendNewSRFaxNotice");

  const requestorFormPermitted = useAuthorized("REQUESTOR_FORM");

  const requestorFormValid = (requestorFormPermitted && requestorValid(requestor)) || !requestorFormPermitted;

  const navigate = useNavigate();

  const { mutate: updateServiceRequest, loading: updateServiceRequestLoading } = useUpdateServiceRequest({ id: "" });
  const [submitError, setSubmitError] = useState(false);

  const trackUserActivityInteraction = useTrackUserInteraction();

  const {
    refetch: refetchQmCase,
    loading: serviceCaseLoading,
    error: getServiceCaseError,
  } = useGetServiceCase({
    id: "",
    base: `${config.QM_SERVICE_API_URL}`,
    lazy: true,
  });

  const { caseId: qmCaseId, faxAttachment: faxData } = useContext(FaxAttachmentContext);
  const attachmentId = faxData?.id || "";
  const designatedServiceRequest = serviceRequests.find((sr) => Boolean(sr.patient?.id));

  const { error: sendingError, mutate: fetchNewServiceFaxNoticePDF } = useGetNewServiceFaxNoticePDF({});

  const { mutate: updateServiceCase, error: updateCaseError } = useCompleteServiceCase({
    id: qmCaseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
    onMutate: async () => {
      if (sendNewSRFaxNoticeFF) {
        try {
          await fetchNewServiceFaxNoticePDF({
            srId: designatedServiceRequest?.id || "",
            attachmentId: attachmentId || "",
          });
        } catch (error) {
          logError(`
            Error in sending Fax Confirmation:
            ${sendingError}
          
            Request error:
            ${error}
          `);
        }
      }
      window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${qmCaseId}`);
    },
  });

  const smartClient = useContext(SmartOnFhirContext);
  const inSmartOnFhirWorkflow = Boolean(smartClient);
  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);
  const sideBySideInitialSrFaxIntakeWorkflow = useFeature("sideBySideInitialSrFaxIntakeWorkflow");
  const isFaxEditSRFlow = useIsFaxEditSRWorkflow(location);
  const sideBySideEditSrFaxIntakeWorkflow = useFeature("sideBySideEditSrFaxIntakeWorkflow");
  const isFaxAuthBuilderOrFaxEditSRFlow =
    (isFaxAuthBuilderFlow && sideBySideInitialSrFaxIntakeWorkflow) ||
    (isFaxEditSRFlow && sideBySideEditSrFaxIntakeWorkflow);
  const goToPatientSummaryOrCaseComplete = useCallback(async () => {
    const designatedServiceRequest = serviceRequests.find((sr) => Boolean(sr.patient?.id));
    if (designatedServiceRequest?.patient?.id) {
      if (inSmartOnFhirWorkflow) {
        navigateToSRSummary(designatedServiceRequest.id, navigate, true);
      } else {
        navigateToPS(designatedServiceRequest.patient.id, navigate, designatedServiceRequest.id);
      }
    } else {
      // Should not be possible, but to be safe...
      navigate(routes.DASHBOARD);
    }
  }, [serviceRequests, navigate, inSmartOnFhirWorkflow]);

  const submitServices = async (saveAndExit: boolean) => {
    let submissionError = false;
    if (saveAndExit) {
      const failedServiceRequests: ServiceRequestResponse[] = [];
      for (const sr of serviceRequests) {
        if (sr.id) {
          try {
            await saveDraftAndExit(sr);
          } catch (e) {
            failedServiceRequests.push(sr);
          }
        }
      }
      for (const sr of failedServiceRequests) {
        if (sr.id) {
          try {
            await saveDraftAndExit(sr);
          } catch (e) {
            submissionError = true;
          }
        }
      }
    } else {
      try {
        await setRequestorAndSubmit(serviceRequests);
      } catch (e) {
        try {
          await setRequestorAndSubmit(serviceRequests);
        } catch (e) {
          submissionError = true;
        }
      }
    }
    setSubmitError(submissionError);
  };

  useEffect(() => {
    if (submitError) {
      enqueueSnackbar("Failed to submit service requests", { variant: "error" });
    }
  }, [submitError, enqueueSnackbar]);

  useEffect(() => {
    if (updateCaseError) {
      enqueueSnackbar(`Could not update the case: ${updateCaseError.message}`, { variant: "error" });
    } else if (getServiceCaseError) {
      enqueueSnackbar(`Could not get case: ${getServiceCaseError.message}`, { variant: "error" });
    }
  }, [updateCaseError, getServiceCaseError, enqueueSnackbar]);

  const saveDraftAndExit = async (sr: ServiceRequestResponse) => {
    await updateServiceRequest(
      {
        requestor: requestor,
        authStatus: "DRAFT",
        workflowStep: workflowStep,
      },
      { pathParams: { id: sr.id } }
    );
  };

  const { suggestedRequestor, suggestedPriorAuthRequirements, suggestedFormContent } = useContext(SuggestionContext);
  const { mutate: batchUpdateCandidateFeedback } = useBulkUpdateCandidateFeedback({});

  // helper function which batch submits service requests
  const setRequestorAndSubmit = async (serviceRequests: ServiceRequestResponse[]) => {
    let externalReferenceIds: string[] = [];
    if (qmCaseId) {
      const serviceCase = await refetchQmCase({
        pathParams: { id: qmCaseId },
      });
      externalReferenceIds = updateExternalReferenceIdsFromServiceCase(externalReferenceIds, serviceCase);
    }
    const feedbackRequestBodies: BulkUpdateCandidateFeedbackRequestBody[] = [];
    const serviceRequestsToSubmit = serviceRequests.map((sr): ServiceRequestResponse => {
      const batchUpdateCandidateFeedbackRequestPayload: BulkUpdateCandidateFeedbackRequestBody =
        compareSuggestionsToFinalSR(
          sr,
          requestor,
          suggestedRequestor,
          suggestedPriorAuthRequirements,
          suggestedFormContent
        );
      feedbackRequestBodies.push(batchUpdateCandidateFeedbackRequestPayload);
      return {
        ...sr,
        facility: undefined, // facility can be cleared, setting to undefined won't update
        performingProvider: undefined, // performing provider can be cleared, setting to undefined won't update
        orderingProvider: undefined, // ordering provider can be cleared, setting to undefined won't update
        facilitySelectedTin: undefined,
        performingProviderSelectedTin: undefined,
        orderingProviderSelectedTin: undefined,
        facilityLocation: undefined,
        performingProviderLocation: undefined,
        orderingProviderLocation: undefined,
        //if the patientStayDates review status is not already set then set to pending
        patientStayDates: sr.patientStayDates?.map((date) => {
          return { ...date, reviewStatus: date.reviewStatus || "PENDING" };
        }),
        additionalCareParticipants:
          sr.additionalCareParticipants?.filter((careParticipant) => {
            return careParticipant.name !== undefined && careParticipant.npi !== undefined;
          }) || [],
        requestor,
        tatStartTimestamp: requestor?.tatStartTimestamp,
        workflowStep: workflowStep,
        externalReferenceIds,
      };
    });
    feedbackRequestBodies.forEach(async (requestPayload) => await batchUpdateCandidateFeedback(requestPayload));
    const response = await submitServiceRequests(serviceRequestsToSubmit);
    const designatedServiceRequest = response.find((sr) => Boolean(sr.patient?.id));
    if (isFaxAuthBuilderOrFaxEditSRFlow && designatedServiceRequest?.patient?.id) {
      setAuthSubmittingFromFax(true);
      const outcome = designatedServiceRequest.requestType === "CONTINUATION" ? "NEW_CONTINUATION_SR" : "NEW_SR";
      const description =
        outcome === "NEW_CONTINUATION_SR" ? "New continuation request created" : "New request created";
      if (qmCaseId) {
        try {
          updateServiceCase({
            outcome: outcome,
            dateCompleted: new Date().toISOString(),
            description: description,
            serviceRequests: [
              {
                serviceRequestId: designatedServiceRequest?.id,
                status: designatedServiceRequest?.authStatus,
                cohereId: designatedServiceRequest?.cohereId,
              },
            ],
          });
        } catch (error) {
          logError(new Error(`Unable to update the case ${qmCaseId}: ${error}`));
          enqueueSnackbar("Failed to update case", { variant: "error" });
          navigateToPS(designatedServiceRequest.patient?.id, navigate, designatedServiceRequest.id);
          throw error;
        } finally {
          setAuthSubmittingFromFax(false);
        }
      } else {
        enqueueSnackbar("Failed to update case", { variant: "error" });
        setAuthSubmittingFromFax(false);
        navigateToPS(designatedServiceRequest.patient?.id, navigate, designatedServiceRequest.id);
      }
    }
  };

  const onSubmitServices = async () => {
    addBreadcrumb({
      category: "AuthBuilder",
      message: "Clicked on Submit Services",
      level: "info",
    });

    const serviceRequestId = serviceRequests && serviceRequests[0] ? serviceRequests[0].id : "";
    setAttemptedSubmitServices(true);

    if (!isFaxAuthBuilderOrFaxEditSRFlow) {
      setShowSubmissionModal(true);
    }

    await submitServices(false);

    hasDuplicates &&
      (await trackUserActivityInteraction({
        event: "SUBMIT_DUPLICATE",
        stage: "DUPLICATE_SUBMISSION_REVIEW",
        activityContext: {
          cohereAuthId: serviceRequests && serviceRequests[0] ? serviceRequests[0].cohereId : "",
          serviceRequestId: serviceRequestId,
          workflowId: workflowId,
        },
      }));

    const userActivityInteractionPayload: TrackUserActivityProps = {
      event: "EHR_SR_POPULATION",
      stage: "AUTH_CREATION",
      activityContext: {
        workflowId: serviceRequests && serviceRequests[0] ? serviceRequests[0].workflowId : "",
        serviceRequestId: serviceRequestId,
      },
      beforeSnapshot: importedEhrOrder ? activitySnapshotFromServiceRequest(importedEhrOrder) : undefined,
      afterSnapshot:
        serviceRequests && serviceRequests[0] ? activitySnapshotFromServiceRequest(serviceRequests[0]) : undefined,
    };
    if (importedEhrOrder !== undefined && userActivityInteractionPayload) {
      trackUserActivityInteraction({ ...userActivityInteractionPayload });
    }
    setAttemptedSubmitServices(false);
  };

  const canBypassRedirects = useAuthorized("CAN_BYPASS_REDIRECTS");

  const cannotSubmitServices =
    attemptedSubmitServices ||
    !requestorFormValid ||
    !preSubmissionRedirectActions ||
    (preSubmissionRedirectActions.length > 0 && !canBypassRedirects) ||
    reviewEdit;

  let submissionStatus: SubmissionStatus;
  if (submitError) {
    submissionStatus = SubmissionStatus.Failure;
  } else if (attemptedSubmitServices) {
    submissionStatus = SubmissionStatus.Loading;
  } else {
    submissionStatus = SubmissionStatus.Success;
  }
  const match = useMatch(routes.AUTH_BUILDER);
  const patientId = match?.params.patientId || "";
  const { data: patient } = useGetPatient({ id: patientId });
  const today = new Date();
  const healthPlanName = patient ? getPatientHealthPlanName(patient, today) : undefined;
  const onSaveAndExitClickButton = async () => {
    await submitServices(true);
    goToPatientSummaryOrCaseComplete();
  };
  const containsSensitiveInformation = serviceRequests.some((sr) => sr.hasRestrictedCodes);
  return (
    <>
      <Footer
        onPrimaryButtonClick={onSubmitServices}
        primaryButtonDisabled={
          cannotSubmitServices ||
          updateServiceRequestLoading ||
          submitServiceRequestLoading ||
          serviceCaseLoading ||
          disableContinueButtonOnContinuation ||
          authSubmittingFromFax
        }
        primaryButtonLoading={updateServiceRequestLoading || loadingActions || authSubmittingFromFax}
        primaryButtonText={"Submit"}
        showSaveAndExitButton={displayClearerDraftComponents}
        dataPendoHealthPlan={healthPlanName ? healthPlanName + "-patient" : "noHealthPlan"}
        onSaveAndExitClick={onSaveAndExitClickButton}
        saveAndExitButtonDisabled={
          cannotSubmitServices ||
          updateServiceRequestLoading ||
          submitServiceRequestLoading ||
          serviceCaseLoading ||
          disableContinueButtonOnContinuation
        }
        setFooterHeight={setFooterHeight}
      />
      <SubmissionModal
        open={showSubmissionModal}
        handleClose={() => setShowSubmissionModal(false)}
        onSuccessAction={goToPatientSummaryOrCaseComplete}
        onFailureAction={onSubmitServices} /* allow a resubmit on failure */
        status={submissionStatus}
        containsSensitiveInformation={containsSensitiveInformation}
        visibilityToggleStatus={visibilityToggleStatus}
      />
    </>
  );
}
