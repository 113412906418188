import React, { useEffect, useState } from "react";

import { TextField } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Clear from "@material-ui/icons/Clear";
import Search from "@material-ui/icons/Search";

import { GetOrganizationsQueryParams } from "@coherehealth/core-platform-api";

interface Props {
  setSearchParams(f: GetOrganizationsQueryParams): void;
  setDebounce(f: number): void;
}

export default function OrganizationSearchBar({ setSearchParams, setDebounce }: Props) {
  const [searchText, setSearchText] = useState("");

  const onClearSearch = () => {
    setSearchText("");
  };

  const searchOrganizations = async () => {
    setSearchParams({
      query: `${searchText}`,
    });
  };

  useEffect(() => {
    // only perform search after user has finished entering input, or do immediately on clear
    setDebounce(searchText !== "" ? 800 : 0);
    searchOrganizations();
    // eslint-disable-next-line
  }, [searchText]);

  return (
    <SearchServiceRequests
      placeholder="Search by name, TIN, or location"
      value={searchText}
      onChange={(e) => {
        setSearchText(e.target.value);
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          searchOrganizations();
        }
      }}
      InputProps={{
        startAdornment: (
          <SearchAdornment position="start">
            <IconButton aria-label="search" onClick={searchOrganizations}>
              <Search />
            </IconButton>
          </SearchAdornment>
        ),
        endAdornment: (
          <ClearAdornment position="end">
            {searchText && (
              <IconButton aria-label="clear" onClick={onClearSearch}>
                <Clear />
              </IconButton>
            )}
          </ClearAdornment>
        ),
      }}
    />
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SearchServiceRequests = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SearchAdornment = styled(InputAdornment)({
  marginLeft: -9,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ClearAdornment = styled(InputAdornment)({
  marginRight: -9,
});
