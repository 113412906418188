import { Caption } from "@coherehealth/common";
import { Grid, makeStyles } from "@material-ui/core";
import { useCallback } from "react";

interface Props {
  selectedRequestType: AvailableRequestTypes;
  onHandleTabChange: (tab: AvailableRequestTypes) => void;
  totalAuthorizations: number;
  totalReferrals: number;
}

export type AvailableRequestTypes = "service" | "referral";

const useStyles = makeStyles((theme) => ({
  container: {
    gap: theme.spacing(4),
    margin: theme.spacing(4, 0, 0),
    justifyContent: "start",
    alignItems: "center",
  },
  tab: {
    padding: theme.spacing(1),
    cursor: "pointer",
    borderRadius: "none",
  },
  tabButton: {
    borderBottom: "none",
    color: "#6F7580",
  },
  tabButtonActive: {
    borderBottom: "4px solid #039EC3",
    color: "#212936",
  },
}));

export function RequestTypeSelectionTab({
  onHandleTabChange,
  selectedRequestType,
  totalAuthorizations,
  totalReferrals,
}: Props) {
  const classes = useStyles();

  const handleTabChange = useCallback(
    (tab: AvailableRequestTypes) => {
      onHandleTabChange(tab);
    },
    [onHandleTabChange]
  );

  const handleServiceTabclick = () => {
    handleTabChange("service");
  };

  const handleReferralTabclick = () => {
    handleTabChange("referral");
  };

  return (
    <Grid container spacing={2} className={classes.container}>
      <Caption
        variant="button"
        color="textPrimary"
        className={`${classes.tab} ${selectedRequestType === "service" ? classes.tabButtonActive : classes.tabButton}`}
        onClick={handleServiceTabclick}
      >
        Authorizations ({totalAuthorizations})
      </Caption>
      <Caption
        variant="button"
        color="textPrimary"
        className={`${classes.tab} ${selectedRequestType === "referral" ? classes.tabButtonActive : classes.tabButton}`}
        onClick={handleReferralTabclick}
      >
        Referrals ({totalReferrals})
      </Caption>
    </Grid>
  );
}
