import { createTheme } from "@mui/material";
import type {} from "@mui/lab/themeAugmentation";

const cohereBaseTheme = createTheme({
  palette: {
    primary: {
      light: "#00A9D1",
      main: "#039EC3",
      dark: "#0291B2",
    },
    info: {
      dark: "#55358F",
      main: "#6948A6",
      light: "#EAE9F4",
    },
    success: {
      dark: "#04A15F",
      main: "#04B86C",
      light: "#ECF9F3",
    },
    warning: {
      dark: "#CB7F00",
      main: "#FFC107",
      light: "#FAF2E6",
    },
    error: {
      dark: "#C62828",
      main: "#E53935",
      light: "#F9EAEA",
    },
    neutral: {
      light: "#E8E8E8",
      main: "#C2C2C2",
      dark: "#9E9E9E",
    },
    font: {
      main: "#212936",
      secondary: "#5E6572",
      light: "#6F7580",
      tertiary: "#7B8FAD",
    },
    action: {
      hoverOpacity: 0.08,
      activatedOpacity: 0.16,
      disabledBackground: "rgba(0, 0, 0, 0.04)",
      disabledBackgroundDark: "#D7D5D5",
    },
    table: {
      panelBackground: "#F5F5F5",
    },
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& .MuiFormHelperText-root": {
            color: theme.palette.neutral.dark,
            marginLeft: "0px",
            marginRight: "0px",
            "&.Mui-error": {
              color: theme.palette.error.dark,
            },
          },
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: ({ theme }) => ({
          "&.Mui-disabled": {
            color: theme.palette.neutral.dark,
          },
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& .MuiButtonBase-root.Mui-checked + .MuiSwitch-track": {
            opacity: 1,
            backgroundColor: theme.palette.switch.trackBackground,
          },
          "& .MuiButtonBase-root.Mui-disabled + .MuiSwitch-track": {
            opacity: 1,
            backgroundColor: theme.palette.gray.inactive,
          },
          "& .Mui-disabled .MuiSwitch-thumb": {
            opacity: 1,
            boxShadow: "none",
            backgroundColor: theme.palette.gray.dark,
          },
          "& .Mui-error": {
            color: theme.palette.error.dark,
          },
        }),
        switchBase: ({ theme }) => ({
          /* The font color is set on the base and inherits through to the thumb background */
          color: theme.palette.background.paper,
          "& .Mui-checked": {
            color: theme.palette.primary.main,
          },
          "& .Mui-disabled": {
            color: theme.palette.gray.inactive,
          },
        }),
        thumb: {
          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)",
        },
        track: ({ theme }) => ({
          opacity: 1,
          background: theme.palette.gray.dark,
        }),
        checked: {},
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          "&.MuiLoadingButton-loading": {
            "&.MuiButton-contained": {
              backgroundColor:
                !ownerState.disabled &&
                theme.palette[ownerState.color && ownerState.color !== "inherit" ? ownerState.color : "primary"].main,
              "& .MuiLoadingButton-loadingIndicator": {
                color:
                  !ownerState.disabled &&
                  theme.palette.getContrastText(
                    theme.palette[ownerState.color && ownerState.color !== "inherit" ? ownerState.color : "primary"]
                      .main
                  ),
              },
            },
            "&.MuiButton-outlined": {
              borderColor:
                !ownerState.disabled &&
                theme.palette[ownerState.color && ownerState.color !== "inherit" ? ownerState.color : "primary"].main,
              "& .MuiLoadingButton-loadingIndicator": {
                color:
                  !ownerState.disabled &&
                  theme.palette[ownerState.color && ownerState.color !== "inherit" ? ownerState.color : "primary"].main,
              },
            },
            "&.MuiButton-text": {
              ".MuiLoadingButton-loadingIndicator": {
                color:
                  !ownerState.disabled &&
                  theme.palette[ownerState.color && ownerState.color !== "inherit" ? ownerState.color : "primary"].main,
              },
            },
          },
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(1.5, 7),
          boxShadow: "none",
          "&:hover,&:focus": {
            boxShadow: "none",
          },
        }),
        contained: ({ theme, ownerState }) => ({
          "&.Mui-disabled": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.action.disabledBackgroundDark,
          },
          "&:hover,&:focus": {
            backgroundColor:
              theme.palette[ownerState.color && ownerState.color !== "inherit" ? ownerState.color : "primary"].light,
          },
          "&:active": {
            backgroundColor:
              theme.palette[ownerState.color && ownerState.color !== "inherit" ? ownerState.color : "primary"].dark,
          },
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingX: theme.spacing(3),
          paddingY: theme.spacing(4),
        }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(1, 2),
          borderRadius: "5px",
          minHeight: theme.spacing(7),
          "& .MuiAlert-icon": {
            padding: "0px",
            alignSelf: "center",
          },
          "& .MuiAlert-icon  > .MuiSvgIcon-root": {
            fontSize: theme.spacing(3),
          },
        }),
        standardSuccess: ({ theme }) => ({
          backgroundColor: theme.palette.success.light,
          "& .MuiAlertTitle-root": {
            backgroundColor: "#ECF9F3",
            color: theme.palette.success.main,
          },
          "& .MuiAlert-icon > .MuiSvgIcon-root": {
            color: theme.palette.success.main,
          },
        }),
        standardWarning: ({ theme }) => ({
          backgroundColor: theme.palette.warning.light,
          "& .MuiAlertTitle-root": {
            color: theme.palette.warning.dark,
          },
          "& .MuiAlert-icon > .MuiSvgIcon-root": {
            color: theme.palette.warning.dark,
          },
        }),
        standardError: ({ theme }) => ({
          backgroundColor: theme.palette.error.light,
          "& .MuiAlertTitle-root": {
            color: theme.palette.error.main,
          },
          "& .MuiAlert-icon > .MuiSvgIcon-root": {
            color: theme.palette.error.main,
          },
        }),
        standardInfo: () => ({
          backgroundColor: "#EAE9F4",
          "& .MuiAlertTitle-root": {
            color: "#55358F",
          },
          "& .MuiAlert-icon > .MuiSvgIcon-root": {
            color: "#55358F",
          },
        }),
        message: ({ theme }) => ({
          position: "relative",
          color: "rgba(0, 0, 0, 0.6)",
          ...theme.typography.body1,
        }),
        icon: {
          marginRight: "16px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 8,
        },
      },
    },
  },
  typography: {
    fontFamily: "Gilroy, sans-serif",
    h1: {
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "35px",
      letterSpacing: "0.15px",
    },
    h2: {
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "26px",
      letterSpacing: "0.15px",
    },
    h3: {
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "27px",
      letterSpacing: "0.15px",
    },
    h4: {
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "22px",
      letterSpacing: "0.15px",
    },
    h5: {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "20px",
      letterSpacing: "0.15px",
    },
    h6: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "0.15px",
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "15px",
      letterSpacing: "0.25px",
      textTransform: "uppercase",
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: "13px",
      lineHeight: "16px",
      letterSpacing: "0.15px",
    },
    body1: {
      fontWeight: 500,
      fontSize: "15px",
      lineHeight: "19px",
      letterSpacing: "0px",
    },
    body2: {
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "19px",
      letterSpacing: "0px",
    },
    button: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "0.25px",
      textTransform: "none",
    },
    caption: {
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "15px",
      letterSpacing: "0.15px",
      textTransform: "none",
    },
    overline: {
      fontWeight: 500,
      fontSize: "11px",
      lineHeight: "20px",
      letterSpacing: "0.25px",
      textTransform: "uppercase",
    },
  },
});

export default cohereBaseTheme;
