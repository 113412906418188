import nameCase from "namecase";

interface Nameable {
  name?: string;
  firstName?: string;
  lastName?: string;
}

export function patientDisplayName({ firstName, lastName, name }: Nameable): string {
  if (firstName && lastName) {
    return `${nameCase(lastName, { individualFields: true })}, ${nameCase(firstName, { individualFields: true })}`;
  }

  if (name) {
    return nameCase(name);
  }
  return "";
}
