import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useMuiContainerStyles } from "@coherehealth/common";
import { Card, Container } from "@material-ui/core";
import { ServiceRequestFormContent } from "components/ServiceRequest";
import { AuthBuilderRequestorProps } from "..";
import { ServiceRequestFormStateSetters } from "../common";
import { Patient, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import listReplace from "util/listReplace";
import { useSRFormStyles } from "../FillForms";
import ServiceRequestFaxForm from "./ServiceRequestFaxForm";

//TODO: clean up by June 1, 2023 -- COH - 3422
interface Props extends AuthBuilderRequestorProps, ServiceRequestFormStateSetters {
  serviceRequestFormContents: ServiceRequestFormContent[];
  serviceRequestsCanBeSubmitted: boolean[];
  attemptedSubmit: boolean;
  patient?: Patient;
  workflowId?: string;
  serviceRequest?: ServiceRequestResponse;
  patientHealthPlanName?: string | null;
}

export default function FillFormsFaxContainer({
  serviceRequestFormContents,
  setServiceRequestFormContents,
  serviceRequestsCanBeSubmitted,
  setServiceRequestFormsCanBeSubmitted,
  setServiceRequestFormsHaveNewEdits,
  patientHealthPlanName,
  patient,
  workflowId,
  serviceRequest,
  attemptedSubmit,
}: Props) {
  const containerClasses = useMuiContainerStyles();

  return (
    <Container classes={containerClasses} maxWidth="lg">
      {serviceRequestFormContents?.map((content, index) => (
        <FaxWrapper
          key={index}
          content={content}
          setServiceRequestFormContents={setServiceRequestFormContents}
          attemptedSubmit={attemptedSubmit}
          setServiceRequestFormsCanBeSubmitted={setServiceRequestFormsCanBeSubmitted}
          setServiceRequestFormsHaveNewEdits={setServiceRequestFormsHaveNewEdits}
          patient={patient}
          patientHealthPlanName={patientHealthPlanName}
          index={index}
          workflowId={workflowId}
        />
      ))}
    </Container>
  );
}

interface FaxWrapProps
  extends Omit<
    Props,
    | "buckets"
    | "requestorFormAuthorized"
    | "setRequestor"
    | "serviceRequestsCanBeSubmitted"
    | "serviceRequestFormContents"
  > {
  index: number;
  content: ServiceRequestFormContent;
  healthPlan?: string | null;
  workflowId?: string;
  patientHealthPlanName?: string | null;
}

function FaxWrapper({
  index,
  content,
  healthPlan,
  workflowId,
  setServiceRequestFormContents,
  setServiceRequestFormsCanBeSubmitted,
  setServiceRequestFormsHaveNewEdits,
  patientHealthPlanName,
  attemptedSubmit,
  patient,
}: FaxWrapProps) {
  const classes = useSRFormStyles();
  const setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>> = useCallback(
    (setStateAction: SetStateAction<ServiceRequestFormContent>) => {
      if (typeof setStateAction === "function") {
        return setServiceRequestFormContents((prev) => {
          const newSr = setStateAction(prev[index]);
          return listReplace(prev, index, newSr);
        });
      } else {
        return setServiceRequestFormContents((prev) => listReplace(prev, index, setStateAction));
      }
    },
    [setServiceRequestFormContents, index]
  );
  const setCanBeSubmitted = useCallback(
    (canBeSubmitted: boolean) =>
      setServiceRequestFormsCanBeSubmitted((prev) => listReplace(prev, index, canBeSubmitted)),
    [setServiceRequestFormsCanBeSubmitted, index]
  );
  const setHasNewEdits = useCallback(
    (hasNewEdits) => setServiceRequestFormsHaveNewEdits((prev) => listReplace(prev, index, hasNewEdits)),
    [setServiceRequestFormsHaveNewEdits, index]
  );

  const onUserEdit = useCallback(() => setHasNewEdits(true), [setHasNewEdits]);

  const [defaultStartDate, setDefaultStartDate] = useState<Date | null>(null);
  const [defaultIsInpatient, setDefaultIsInpatient] = useState<boolean | undefined>(undefined);

  return (
    <Card className={classes.card}>
      <ServiceRequestFaxForm
        content={content}
        key={index}
        setFormContent={setFormContent}
        onUserEdit={onUserEdit}
        setCanBeSubmitted={setCanBeSubmitted}
        attemptedSubmit={attemptedSubmit}
        patientHealthPlanName={patientHealthPlanName}
        patient={patient || undefined}
        defaultStartDate={defaultStartDate}
        setDefaultStartDate={setDefaultStartDate}
        defaultIsInpatient={defaultIsInpatient}
        setDefaultIsInpatient={setDefaultIsInpatient}
      />
    </Card>
  );
}
