import config from "../../api/config";
import FileUploadItem, { UploadFile } from "./FileUploadItem";
import {} from "notistack";
import { AttachmentUpdatePayload } from "@coherehealth/core-platform-api";
import { MutateMethod } from "restful-react";

interface UploadAttachmentProps {
  file: UploadFile;
  referralRequestId: string;
  afterDelete: (f: UploadFile) => void;
  afterUpdate: (f: UploadFile) => void;
  canEditViewable: boolean;
  stage?: string;
  noDivider?: boolean;
  hideDownloadIcon?: boolean;
  deleteAttachment: MutateMethod<void | void[], string, void, any>;
  deleteLoading: boolean;
  updateAttachment: (fileId: string, attachmentUpdatePayload: AttachmentUpdatePayload) => Promise<void>;
  updateLoading: boolean;
}

export default function UploadReferralRequestAttachment({
  file,
  referralRequestId,
  afterDelete,
  afterUpdate,
  canEditViewable,
  stage,
  noDivider,
  hideDownloadIcon,
  deleteAttachment,
  deleteLoading,
  updateAttachment,
  updateLoading,
}: UploadAttachmentProps) {
  const downloadAttachmentPath = `${config.SERVICE_API_URL}/serviceRequest/${referralRequestId}/attachment/${file.id}/download`;

  return FileUploadItem({
    file,
    updateAttachment,
    updateLoading,
    deleteAttachment,
    deleteLoading,
    downloadAttachmentPath,
    afterDelete,
    afterUpdate,
    canEditViewable,
    referralRequestId,
    stage,
    noDivider,
    hideDownloadIcon,
  });
}
