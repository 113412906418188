import React, { PropsWithChildren, ComponentProps, useState } from "react";

import { H6, TextField, Card } from "@coherehealth/common";
import { IntegrationLog } from "@coherehealth/core-platform-api";
import uniq from "lodash/uniq";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import { IntegrationExecution } from "@coherehealth/int-obs-api";

interface Props {
  logs?: (IntegrationExecution | IntegrationLog)[];
}

export default function IntegrationLogDetailView({ logs }: Props) {
  const [integrationFilter, setIntegrationFilter] = useState<string>();

  if (!logs || logs.length === 0) {
    return <H6>No logs available</H6>;
  }

  let allIntegrationNames: (string | undefined)[] = [];
  logs.forEach((element) => {
    if (instanceOfIntegrationLog(element)) {
      allIntegrationNames.push(element.integrationName);
    } else {
      allIntegrationNames.push(element.displayName ? element.displayName : element.integrationName);
    }
  });

  allIntegrationNames = uniq(allIntegrationNames).sort();
  const itemsToShow = integrationFilter
    ? logs.filter(
        (l) =>
          l.integrationName === integrationFilter ||
          (instanceOfIntegrationExecution(l) && l.displayName === integrationFilter)
      )
    : logs;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          label="Choose integration"
          select
          fullWidth
          value={integrationFilter || ""}
          onChangeValue={(val) => {
            setIntegrationFilter(val);
          }}
        >
          {allIntegrationNames.map((name) => (
            <MenuItem value={name} key={name}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {itemsToShow.map((log) => (
        <Grid item xs={12} id={instanceOfIntegrationLog(log) ? log.id : log.eventId}>
          <Card>
            <Grid container spacing={1}>
              {instanceOfIntegrationLog(log) && <FieldValue label="id">{log.id}</FieldValue>}

              {instanceOfIntegrationExecution(log) && <FieldValue label="eventId">{log.eventId}</FieldValue>}

              {instanceOfIntegrationLog(log) && <FieldValue label="url">{log.url}</FieldValue>}

              {instanceOfIntegrationExecution(log) && <FieldValue label="sourceTopic">{log.sourceTopic}</FieldValue>}

              <FieldValue label="integrationName">{log.integrationName}</FieldValue>

              {instanceOfIntegrationExecution(log) && <FieldValue label="displayName">{log.displayName}</FieldValue>}

              {instanceOfIntegrationExecution(log) && <FieldValue label="integrationStage">{log.stage}</FieldValue>}

              <FieldValue label="requestJson">{formatAsJsonString(log.requestJson)}</FieldValue>

              <FieldValue label="responseJson">{formatAsJsonString(log.responseJson)}</FieldValue>

              {instanceOfIntegrationLog(log) && (
                <FieldValue label="responseTimedOut">{log.responseTimedOut}</FieldValue>
              )}

              {instanceOfIntegrationLog(log) && <FieldValue label="responseCode">{log.responseCode}</FieldValue>}

              <FieldValue label="status">{log.status}</FieldValue>

              <FieldValue label="retryCount">{log.retryCount}</FieldValue>

              {instanceOfIntegrationLog(log) && (
                <FieldValue label="operationOutcomeId">{log.operationOutcomeId}</FieldValue>
              )}

              {instanceOfIntegrationExecution(log) && <FieldValue label="errorDetails">{log?.errorDetails}</FieldValue>}
              {instanceOfIntegrationLog(log) && <FieldValue label="errorMessage">{log?.errorMessage}</FieldValue>}

              {instanceOfIntegrationLog(log) && <FieldValue label="requestDate">{log.requestDate}</FieldValue>}
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

function formatAsJsonString(ipt: string | undefined): string {
  if (ipt) {
    try {
      return JSON.stringify(JSON.parse(ipt), undefined, 2);
    } catch {
      return `${ipt}`;
    }
  }
  return "-";
}

const FieldValue = ({ children, ...rest }: PropsWithChildren<ComponentProps<typeof TextField>>) => (
  <Grid item xs={12}>
    <TextField fullWidth multiline disabled value={children || "--"} {...rest} />
  </Grid>
);

function instanceOfIntegrationLog(ipt: IntegrationLog | IntegrationExecution): ipt is IntegrationLog {
  return "id" in ipt;
}

function instanceOfIntegrationExecution(ipt: IntegrationLog | IntegrationExecution): ipt is IntegrationExecution {
  return "eventId" in ipt;
}
