import { RadioGroup } from "@coherehealth/common";
import { Grid, Divider } from "@material-ui/core";
import { H6 } from "@coherehealth/design-system";
import OutreachForm from "components/NewOutreach/OutreachForm";
import { OutreachFormFields } from "components/ServiceRequest/ReviewSection/useOutreachAttempt";
import { NudgeInfo, OutreachAttempt, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { Dispatch, SetStateAction } from "react";
import { ReviewUpdate } from "./useReviews";

interface ReviewOutreachFormProps {
  serviceRequest: ServiceRequestResponse;
  outreachAttempt?: OutreachAttempt;
  setOutreachAttempt?: Dispatch<SetStateAction<OutreachAttempt>>;
  outreachFormConfig?: OutreachFormFields;
  outreachFormErrors?: OutreachFormFields;
  setOutreachFormErrors?: React.Dispatch<React.SetStateAction<OutreachFormFields>>;
  hasOutreachAttempt?: boolean;
  setHasOutreachAttempt?: React.Dispatch<React.SetStateAction<boolean>>;
  setReview?: ReviewUpdate;
  setNurseReviewNudgeValue?: (fieldName: keyof NudgeInfo, index: number, value: string | boolean) => void;
  setNurseReviewNudges?: (selectedNudges: NudgeInfo[]) => void;
  showOutreachToggle: boolean;
}

const ReviewOutreachForm = ({
  serviceRequest,
  outreachAttempt,
  setOutreachAttempt,
  hasOutreachAttempt,
  outreachFormConfig,
  outreachFormErrors,
  setOutreachFormErrors,
  setHasOutreachAttempt,
  setReview,
  setNurseReviewNudgeValue,
  setNurseReviewNudges,
  showOutreachToggle,
}: ReviewOutreachFormProps) => {
  return (
    <>
      {showOutreachToggle && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <H6 sx={{ pb: 1 }}>Outreach</H6>
            <RadioGroup
              row
              nowrap
              label="Outreach attempted?"
              options={[
                { id: "false", label: "No" },
                { id: "true", label: "Yes" },
              ]}
              value={hasOutreachAttempt ? "true" : "false"}
              onChange={(opt) => {
                if (opt === "true") {
                  setHasOutreachAttempt?.(true);
                  setReview?.({ nudgeAttempted: true });
                } else {
                  setHasOutreachAttempt?.(false);
                  setReview?.({ nudgeAttempted: false });
                }
              }}
            />
          </Grid>
        </>
      )}
      {hasOutreachAttempt &&
        outreachAttempt &&
        setOutreachAttempt &&
        outreachFormConfig &&
        outreachFormErrors &&
        setOutreachFormErrors && (
          <OutreachForm
            serviceRequest={serviceRequest}
            outreachAttempt={outreachAttempt}
            setOutreachAttempt={setOutreachAttempt}
            displayFieldConfig={outreachFormConfig}
            errorStates={outreachFormErrors}
            setErrorStates={setOutreachFormErrors}
            fromClinicalReview
            setNurseReviewNudges={setNurseReviewNudges}
            setNurseReviewNudgeValue={setNurseReviewNudgeValue}
          />
        )}
    </>
  );
};

export { ReviewOutreachForm };
