import { Modal, ModalHeader, H2 } from "@coherehealth/common";
import ChangeStatusForm from "../ChangeStatusForm";
import {
  AuthorizationResponse,
  ServiceRequestResponse,
  ServiceRequestSearchResponse,
} from "@coherehealth/core-platform-api";
import { makeStyles } from "@material-ui/core";
import { useState } from "react";

interface ChangeServiceStatusModalProps {
  authorization?: AuthorizationResponse;
  authorizationRefetch?: () => Promise<AuthorizationResponse | null>;
  open: boolean;
  onEdit?: () => void;
  onSubmission?: (sr: ServiceRequestResponse) => void;
  serviceRequest: ServiceRequestSearchResponse | ServiceRequestResponse;
  setEditModalOpen: (value: React.SetStateAction<boolean>) => void;
}

export default function ChangeServiceStatusModal(props: ChangeServiceStatusModalProps): JSX.Element {
  const { authorization, authorizationRefetch, onEdit, onSubmission, open, serviceRequest, setEditModalOpen } = props;
  const classes = useStyles();
  const [useEnhancedWithdrawalModal, setUseEnhancedWithdrawalModal] = useState<boolean>(false);

  const getMaxWidth = (): "lg" | "md" => {
    if (useEnhancedWithdrawalModal) {
      return "lg";
    }
    return "md";
  };

  return (
    <Modal
      open={open}
      scroll="body"
      fullWidth
      maxWidth={getMaxWidth()}
      onClose={() => setEditModalOpen(false)}
      data-testid="change-service-status-modal"
      className={classes.root}
    >
      <ModalHeader>
        <H2>Change service status</H2>
      </ModalHeader>
      <ChangeStatusForm
        onEdit={() => {
          onEdit?.();
          setEditModalOpen(false);
        }}
        onCancel={() => setEditModalOpen(false)}
        authServiceRequests={authorization?.serviceRequestsOnAuth || [serviceRequest]}
        onSubmission={onSubmission}
        authorizationRefetch={authorizationRefetch}
        isLatestServiceRequestMemberRequested={authorization?.isMemberRequestedAuth}
        setUseEnhancedWithdrawalModal={setUseEnhancedWithdrawalModal}
      />
    </Modal>
  );
}

const useStyles = makeStyles({
  root: {
    "& .MuiDialog-paperWidthLg": {
      maxWidth: 944,
      minWidth: 944,
    },
  },
});
