import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { ServiceCase } from "@coherehealth/qm-api";
import {
  Body2,
  H5,
  formatDateStr,
  patientDisplayName,
  formatDateStrWithTime,
  colorsLight,
  useFeature,
} from "@coherehealth/common";
import { Link } from "react-router-dom";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import routes from "routes";
import CohereLogo from "../images/CohereLogo.svg";
import { MyAccountButton, DropdownMenuButtonContainer } from "../AppHeader/AppHeader";
import { EnvironmentIndicator, environmentAbbreviation, shouldDisplayEnvironmentIndicator } from "@coherehealth/common";
import { isFacilityBasedSR } from "util/serviceRequest";
import parsePhoneNumber from "libphonenumber-js";

interface Props {
  serviceRequest?: ServiceRequestResponse | null;
  hideEnvIndicator?: boolean;
  viewOnly?: boolean;
  facilityBasedFeatureEnabled?: boolean;
  serviceCase?: ServiceCase;
}

export default function SlimHeader({
  serviceRequest,
  hideEnvIndicator,
  viewOnly,
  facilityBasedFeatureEnabled,
  serviceCase,
}: Props) {
  const env = environmentAbbreviation();

  const displayEnvIndicator = !hideEnvIndicator && shouldDisplayEnvironmentIndicator(env);
  const logrocketUnmasking = useFeature("logrocketUnmasking");

  return (
    <>
      {displayEnvIndicator && <EnvironmentIndicator env={env} />}
      <RelativeContainer>
        <LogoContainer>
          <BannerIcon to={routes.DASHBOARD} component={Link} aria-label="Cohere Health home">
            <img src={CohereLogo} alt="" />
          </BannerIcon>
        </LogoContainer>
        <TrackingContainer>
          {serviceCase?.caseType === "FAX_AUDIT_REVIEW" && serviceCase?.faxDetail?.faxNumber ? (
            <Body2 style={{ color: colorsLight.font.light, fontFamily: "Gilroy-SemiBold" }}>
              {"Fax from " +
                parsePhoneNumber(serviceCase?.faxDetail?.faxNumber, "US")?.formatNational() +
                " \u2022 Added " +
                formatDateStrWithTime(serviceCase.dateCreated)}
            </Body2>
          ) : (
            <TrackingText data-public={!!logrocketUnmasking ? true : undefined}>
              {headerSubtext(serviceRequest, viewOnly, facilityBasedFeatureEnabled)}
            </TrackingText>
          )}

          <ServiceRequestTextWrapper>
            <span style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
              <H5 style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {serviceCase?.caseType === "FAX_AUDIT_REVIEW"
                  ? faxAuditHeaderText(serviceCase)
                  : serviceRequest
                  ? (serviceRequest.patient && patientDisplayName(serviceRequest.patient)) +
                    " \u2022 " +
                    formatDateStr(serviceRequest?.patient?.dateOfBirth)
                  : "No service request created"}
              </H5>
            </span>
          </ServiceRequestTextWrapper>
        </TrackingContainer>
        <MyAccountContainer>
          <DropdownMenuButtonContainer>
            <MyAccountButton />
          </DropdownMenuButtonContainer>
        </MyAccountContainer>
      </RelativeContainer>
    </>
  );
}

const headerSubtext = (
  serviceRequest?: ServiceRequestResponse | null,
  viewOnly?: boolean,
  canUseFacilityBasedWorkflow?: boolean
) => {
  const isFacilityBasedContinuation = serviceRequest ? isFacilityBasedSR(serviceRequest) : false;
  const useFacilityBasedContinuationLanguage = isFacilityBasedContinuation && canUseFacilityBasedWorkflow;

  const verb = viewOnly ? `Viewing ` : `Reviewing `;
  const request =
    useFacilityBasedContinuationLanguage && serviceRequest?.requestType === "CONTINUATION"
      ? "continued stay  "
      : serviceRequest?.requestType === "CONTINUATION"
      ? `continuation request  `
      : `initial request  `;
  const requestIdentifier = serviceRequest ? `#` + serviceRequest.cohereId : "N / A";

  return `${verb + request + requestIdentifier}`;
};

const faxAuditHeaderText = (serviceCase: ServiceCase) => {
  let header = "Case ID " + serviceCase.caseNumber;
  if (serviceCase?.faxDetail?.cohereId) {
    header += " \u2022 Fax ID " + serviceCase.faxDetail?.cohereId;
  }
  if (serviceCase?.externalSources?.[0].externalReferenceId) {
    header += " \u2022 External ID " + serviceCase?.externalSources?.[0].externalReferenceId;
  }
  return header;
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const TrackingContainer = styled("div")({
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ServiceRequestTextWrapper = styled("div")({
  alignSelf: "flex-start",
  width: "calc(100vw - 400px)",
  overflow: "hidden",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RelativeContainer = styled("div")(({ theme }) => ({
  height: theme.spacing(9),
  backgroundColor: theme.palette.background.paper,
  borderBottom: "1px solid #E5E5E5",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  width: "100%",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const LogoContainer = styled("div")({
  position: "absolute",
  left: 0,
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const MyAccountContainer = styled("div")({
  position: "absolute",
  right: 32,
  width: "100%",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const TrackingText = styled(Body2)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  color: theme.palette.text.secondary,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const BannerIcon = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0, 0, 2, 4),
})) as typeof Button;
