import { Body1 } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core";
import { NoticationBanner } from "common/NotificationBanner";
import PurpleClipboard from "components/images/PurpleClipboard";

interface ClinicalConversationsReviewBannerProps {
  notificationBannerClass?: string;
}

export default function ClinicalConversationsReviewBanner(props: ClinicalConversationsReviewBannerProps): JSX.Element {
  const { notificationBannerClass } = props;
  const classes = useStyles();
  const bodyText = (
    <Body1 className={classes.bodyText}>
      This authorization is for a Geisinger Medicaid under 21 member, so this is for a clinical conversation instead of
      a peer to peer review.
    </Body1>
  );

  return (
    <NoticationBanner
      header="This review is for a clinical conversation"
      icon={<PurpleClipboard />}
      contentComponent={bodyText}
      bannerClassName={notificationBannerClass}
      iconClassName={classes.iconWrapper}
    />
  );
}

const useStyles = makeStyles(() => ({
  bodyText: {
    fontFamily: "Gilroy-Medium",
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 17 / 15,
    letterSpacing: "0.15px",
  },
  iconWrapper: {
    maxWidth: 60,
    paddingRight: 20,
  },
}));
