import { Font } from "@react-pdf/renderer";

/**
 *
 * Why are we registering fonts?
 * Registering fonts for the PDF renderer (pdf-react): In order to get the proper fonts
 * we use for our app we have to "register" the fonts that we use in the Font react-pdf
 * component. The "src" parameter only excepts .ttf files, which is a different type than
 * our main font files (.woff/.woff2). Because of this we have to find new .ttf files to
 * import them for use in this library (I also tried a conversion from .woff/.woff2 to .ttf
 * and it wasn't reliable). If you need to find additional Gilroy fonts, you can download
 * them from https://gist.github.com/mfd/09b70eb47474836f25a21660282ce0fd.
 * - The file from the link above gave me a unknown format error in the browser console. I downloaded a text file from
 *   here: https://fontsfree.net/gilroy-medium-font-download.html which worked fine
 *
 * Then, to register them properly, you must:
 *
 * 1. Move the file to the proper src/common/assets/webFonts directory and name the file accordingly
 * Ex: "Gilroy-Medium.ttf" - when you `yarn build` the resultant file will be prefixed with the name you set here
 * "Gilroy-Medium.ttf" -> "Gilroy-Medium.abcdefghijklmnop.ttf"
 *
 * 2. Import the .ttf file in the src/common/assets/themeFont.css file. You will see a
 * couple examples of .ttf files that have already been imported.
 *
 * 3. Run yarn build in the auth directory and find the new .ttf file in the
 * auth/build/static/media directory. It will be in the format font-file-name.XXXXXXXX.ttf (whatever filename
 * you used earlier)
 *
 * 4. Add a new "Font.register" line below with the accurate url. You should only have to
 * register it here and not on every component you create using the font. Then, you can
 * reference this font with a css "fontFamily" property using the name you give the font.
 *
 * 5. Import the name of the font so that this imported file is run and the Font.register is called.
 */

/**
 * Why are we exporting font names?
 * The purpose of exporting the font names is purely to have them imported so that this file is run so that
 * `Font.register` is called. We technically only need to import one of the font names in each PDF because
 * all of the fonts will be registered when this file is imported.
 */

export const GilroyMedium = "GilroyMedium";
Font.register({
  family: GilroyMedium,
  src: `${window.location.origin}/static/media/Gilroy-Medium.1b4da6582f83f45ea039.ttf`,
});

export const GilroyRegular = "GilroyRegular";
Font.register({
  family: GilroyRegular,
  src: `${window.location.origin}/static/media/Gilroy-Regular.14731479ece1f4a6264e.ttf`,
});

export const GilroySemiBold = "GilroySemiBold";
Font.register({
  family: GilroySemiBold,
  src: `${window.location.origin}/static/media/font.d48bad5c14ea05523e41.ttf`,
});

export const GilroyBold = "GilroyBold";
Font.register({
  family: GilroyBold,
  src: `${window.location.origin}/static/media/Gilroy-Bold.6884672270cb49a41306.ttf`,
});
