import { PhoneNumber } from "@coherehealth/core-platform-api";

export function isPhoneNumberValid(phoneNumber?: string) {
  return phoneNumber?.length === 10;
}
export function formatPhoneNumber(phoneNumber: string) {
  let cleaned = phoneNumber.replace(/\D/g, "");
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = match[1] ? "+1 " : "";
    return [intlCode, match[2], "-", match[3], "-", match[4]].join("");
  }
  return phoneNumber;
}

export const strToPhoneNumber = (phone: string | undefined) => {
  if (phone !== undefined) {
    let num = phone.substring(0, 10);
    let ext = phone.length >= 16 ? phone.substring(16) : "";
    return { number: num, extension: ext };
  }
  return { number: "", extension: "" };
};

export const phoneNumberToString = (phoneNumber?: PhoneNumber) => {
  const strippedPhoneNumber = phoneNumber?.number?.replace("-", "");
  if (!strippedPhoneNumber || strippedPhoneNumber.length !== 10) {
    return "";
  }
  const areaCode = strippedPhoneNumber.substring(0, 3);
  const telephonePrefix = strippedPhoneNumber.substring(3, 6);
  const lineNumber = strippedPhoneNumber.substring(6);
  const formattedPhoneNumberString = `(${areaCode}) ${telephonePrefix}-${lineNumber}`;
  return phoneNumber?.extension
    ? `${formattedPhoneNumberString} ext: ${phoneNumber.extension}`
    : formattedPhoneNumberString;
};
