import React, { FunctionComponent } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { SelectOptionsHook, SingleSelect, Body2, useLazyLoadingQueryOptionsHook } from "@coherehealth/common";
import { ClinicalService, Patient, ProcedureCode, useGetClinicalServices } from "@coherehealth/core-platform-api";
import { getPatientHealthPlanName } from "util/patientUtils";

const SingleClinicalServiceSelect: FunctionComponent<{
  error: boolean;
  clinicalService: ClinicalService | null;
  setClinicalService: (clinicalService: ClinicalService | null) => void;
  patient: Patient | undefined;
  startDate: Date;
  disableHelperText?: boolean;
  codes: ProcedureCode[];
  disabled?: boolean;
}> = ({ error, clinicalService, setClinicalService, patient, disableHelperText, startDate, codes, disabled }) => {
  const getHelperTextIfNull = () => error && "Required";

  const useClinicalServices: SelectOptionsHook<ClinicalService> = (selectOptionsParams) =>
    useLazyLoadingQueryOptionsHook({
      useGetHook: useGetClinicalServices,
      additionalQueryParams: {
        isSingleService: true,
        healthPlanName: getPatientHealthPlanName(patient, startDate) || undefined,
      },
      ...selectOptionsParams,
    });
  return (
    <SingleSelect<ClinicalService>
      error={error}
      helperText={disableHelperText ? "" : getHelperTextIfNull() || clinicalService?.description}
      label="Service Category"
      useOptions={useClinicalServices}
      getOptionLabel={({ name }) => `${name}`}
      renderOption={({ name, description }) => (
        <Body2Unbold>
          <b>{name}</b> - {description}
        </Body2Unbold>
      )}
      selectedValue={clinicalService}
      setSelectedValue={setClinicalService}
      disabled={disabled}
    />
  );
};

export default SingleClinicalServiceSelect;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Body2Unbold = styled(Body2)(({ theme }) => ({
  fontWeight: "normal",
}));
