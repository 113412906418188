import { useEffect, useRef } from "react";

const useOnUnmount = (callback: () => Promise<void>, dependencies: any[] = []) => {
  const fn = useRef(callback);
  fn.current = callback;

  useEffect(() => {
    const handleTabClose = (event: Event) => {
      // Even if the page is redirected we need to trigger the unsuscriptions
      // so we need to call this process using event loop to avoid that those
      // get cancelled because "onbeforeunload" events are unsecure.
      setTimeout(async () => {
        await fn.current();
      }, 1);
    };

    window.addEventListener("beforeunload", handleTabClose, true);

    return () => window.removeEventListener("beforeunload", handleTabClose, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, callback]);
};

export default useOnUnmount;
