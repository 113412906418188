import { useContext, useState } from "react";
import { HighlightStateContext, HighlightTooltip } from "../../util";
import { Body3 } from "../../components/Typography";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Popover, ButtonBase } from "@material-ui/core";
import { colorsLight } from "../../themes";
import InformativeModal from "../Modal/InformativeModal";
import ClipboardWarningIcon from "../images/ClipboardWarningIcon";
import { useFeature } from "../../components";

const HighlightEditTooltip = () => {
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const { clicked, setClicked, getClickedLinked, setLinking, deleteClicked } = useContext(HighlightStateContext);
  const isHighlightImprovementsEnabled = useFeature("textHighlightImprovements");
  return clicked ? (
    <>
      <Popover
        id={"" + clicked.hash}
        anchorReference="anchorPosition"
        anchorPosition={{ top: clicked.mouseY || 0, left: clicked.mouseX || 0 }}
        open={!!clicked}
        onClose={() => setClicked?.(undefined)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <HighlightTooltip>
          <Body3 style={{ paddingTop: "5px" }}>
            {clicked.isCited || !isHighlightImprovementsEnabled ? getClickedLinked?.() : "Suggested highlight"}
          </Body3>
          <div
            style={{ width: "1px", height: "25px", background: colorsLight.primary.contrastText, borderRadius: "1px" }}
          />
          {(clicked.isCited || !isHighlightImprovementsEnabled) && (
            <ButtonBase
              onClick={() => {
                setClicked?.(undefined);
                setLinking?.(clicked.hash);
              }}
            >
              <InsertLinkIcon />
            </ButtonBase>
          )}
          <ButtonBase
            onClick={() => {
              setDeleteModalOpen(true);
            }}
          >
            <DeleteOutlineIcon />
          </ButtonBase>
        </HighlightTooltip>
      </Popover>
      <InformativeModal
        headerText="Do you want to delete this highlight?"
        subHeaderText="This will also remove all links this highlight has to indications"
        icon={<ClipboardWarningIcon />}
        primaryButtonText="Delete highlight"
        primaryButtonAction={() => {
          setDeleteModalOpen(false);
          deleteClicked?.();
        }}
        tertiaryButtonText={"Cancel"}
        tertiaryButtonAction={() => {
          setDeleteModalOpen(false);
          setClicked?.(undefined);
        }}
        showDivider
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setClicked?.(undefined);
        }}
      />
    </>
  ) : null;
};

export default HighlightEditTooltip;
